import {TDate} from '../../../../common/dates';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {ETipoCategoriaDesc} from '../../../datasources/tipocategoriadesc/tipoCategoriaDesc.datasource.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IJsonDGEMP} from '../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonDepto} from '../../../entities/depto/jsonDepto.entity.interface';
import {IJsonCCusto} from '../../../entities/ccustos/jsonCCusto.entity.interface';

export const MODULE_NAME_RH_ESTATISTICAS = 'rhestatisticas';

export enum ERHEstatisticasFilterType {
  YEAR_MONTH,
  CODEMP,
  DEPTO,
  CCUSTO
}

export enum ERHEstatisticasNavTabs {
  ESTATISTICAS = 'estatisticas',
  EXTRATO_REMUNERACOES = 'extratoRemuneracoes',
  ANALISE_ANUAL = 'analiseAnual',
  ANALISE_SEGSOCIAL = 'analiseSegSocial',
  ANALISE_AT = 'analiseAT',
  ANALISE_SINDICATO = 'analiseSindicato',
  ANALISE_CGA = 'analiseCGA',
  ANALISE_SEGURO = 'analiseSeguro',
  LISTAGENS_RU = 'listagensRU'
}

export enum ERHEstatisticasEstatisticasTabs {
  RESUMO = 'tabEstatisticasResumo',
  DETALHADA_AD = 'tabEstatisticasDetalhadoAbonoDesconto'
}

export enum ERHTipoRegisto {
  Abono,
  Desconto,
  Impostos,
  CustosEmpresa
}

export enum ERHAnaliseMensalRecordType {
  Abono,
  Desconto,
  Impostos,
  Recibo,
  CustosEmpresa
}

export interface IRHEstatisticasExclusionButtonState {
  yearMonthEnabled: boolean;
  codEmpEnabled: boolean;
  deptoEnabled: boolean;
  ccustoEnabled: boolean;
}

export interface IRHEstatisticasNavTabsModelBase {
  id: ERHEstatisticasNavTabs;
  caption: string;
}

export interface IRHEstatisticasNavTabsModelEstatisticas extends IRHEstatisticasNavTabsModelBase {
  activeTab: ERHEstatisticasEstatisticasTabs;
  resumoSource: Array<IRHEstatisticasFolhaSalarioResumoItem>;
  resumoDataGridDefinition: IDevExpressDataGrid<IRHEstatisticasFolhaSalarioResumoItem, number>;
  resumoDataGrid: dxDataGrid;
  resumoDetailSource: Array<IRHEstatisticasFolhaSalarioResumoItem>;
  resumoDetailDataGridDefinition: IDevExpressDataGrid<IRHEstatisticasFolhaSalarioResumoItem, number>;
  resumoDetailDataGrid: dxDataGrid;
}

export interface IRHEstatisticasNavTabsModelExtratoRemuneracoes extends IRHEstatisticasNavTabsModelBase {
  dataGridSource: Array<IRHEstatisticasAnaliseMensalItem>;
  dataGrid: dxDataGrid;
  dataGridDefinition: IDevExpressDataGrid<IRHEstatisticasAnaliseMensalItem, number>;
}

export interface IRHEstatisticasNavTabsModelAnaliseAnual extends IRHEstatisticasNavTabsModelBase {
  dataGridSource: Array<IRHEstatisticasAnaliseAnualItem>;
  dataGrid: dxDataGrid;
  dataGridDefinition: IDevExpressDataGrid<IRHEstatisticasAnaliseAnualItem, string>;
}

export interface IRHEstatisticasNavTabsModelAnaliseSegSocial extends IRHEstatisticasNavTabsModelBase {
  dataGridSource: Array<IJsonRHEstatisticasSegSocialResumoItem>;
  dataGrid: dxDataGrid;
  dataGridDefinition: IDevExpressDataGrid<IJsonRHEstatisticasSegSocialResumoItem, number>;
}

export interface IRHEstatisticasNavTabsModelAnaliseAT extends IRHEstatisticasNavTabsModelBase {
  dataGridSource: Array<IRHEstatisticasMapaATItem>;
  dataGrid: dxDataGrid;
  dataGridDefinition: IDevExpressDataGrid<IRHEstatisticasMapaATItem, number>;
}

export interface IRHEstatisticasNavTabsModelAnaliseSindicato extends IRHEstatisticasNavTabsModelBase {
  dataGridSource: Array<IRHEstatisticasSindicatoItem>;
  dataGrid: dxDataGrid;
  dataGridDefinition: IDevExpressDataGrid<IRHEstatisticasSindicatoItem, string>;
}

export interface IRHEstatisticasNavTabsModelAnaliseCGA extends IRHEstatisticasNavTabsModelBase {}

export interface IRHEstatisticasNavTabsModelAnaliseSeguro extends IRHEstatisticasNavTabsModelBase {
  dataGridSource: Array<IJsonRHEstatisticasSeguroResumoItem>;
  dataGrid: dxDataGrid;
  dataGridDefinition: IDevExpressDataGrid<IJsonRHEstatisticasSeguroResumoItem, number>;
}

export interface IRHEstatisticasNavTabsModelListagensRU extends IRHEstatisticasNavTabsModelBase {}

export interface IRHEstatisticasModelUI {
  activeItem: ERHEstatisticasNavTabs;
  estatisticas: IRHEstatisticasNavTabsModelEstatisticas;
  extratoRemuneracoes: IRHEstatisticasNavTabsModelExtratoRemuneracoes;
  analiseAnual: IRHEstatisticasNavTabsModelAnaliseAnual;
  analiseSegSocial: IRHEstatisticasNavTabsModelAnaliseSegSocial;
  analiseAT: IRHEstatisticasNavTabsModelAnaliseAT;
  analiseSindicato: IRHEstatisticasNavTabsModelAnaliseSindicato;
  analiseCGA: IRHEstatisticasNavTabsModelAnaliseCGA;
  analiseSeguro: IRHEstatisticasNavTabsModelAnaliseSeguro;
  listagensRU: IRHEstatisticasNavTabsModelListagensRU;
}

export interface IJsonRHEstatisticasFilters {
  anoDe: number;
  mesDe: number;
  anoAte: number;
  mesAte: number;
  codEmpDe: number;
  codEmpAte: number;
  deptoDe: number;
  deptoAte: number;
  ccustoDe: string;
  ccustoAte: string;
  exclusaoAnoMes: Array<string>;
  exclusaoCodEmp: Array<number>;
  exclusaoDepto: Array<number>;
  exclusaoCCusto: Array<string>;
}

export interface IRHEstatisticasFiltersHelper {
  anoMesDe: TDate;
  anoMesAte: TDate;
  codEmpDe: IJsonDGEMP | number;
  codEmpAte: IJsonDGEMP | number;
  deptoDe: IJsonDepto | number;
  deptoAte: IJsonDepto | number;
  ccustoDe: IJsonCCusto | string;
  ccustoAte: IJsonCCusto | string;
  exclusaoAnoMesUI: Array<IRHEstatisticasExclusionSourceItem>;
  exclusaoCodEmpUI: Array<IRHEstatisticasExclusionSourceItem>;
  exclusaoDeptoUI: Array<IRHEstatisticasExclusionSourceItem>;
  exclusaoCCustoUI: Array<IRHEstatisticasExclusionSourceItem>;
}

export interface IRHEstatisticasExclusionSourceItem {
  key: string | number;
  caption: string;
}

export interface IRHEstatisticasFolhaSalarioResumoDetalheItem {
  rhTipoRegisto: ERHTipoRegisto;
  nCodAbdesc: string;
  nomeCodAbdesc: string;
  categoria: ETipoCategoriaDesc;
  valor: number;
  valorUnit: number;
  qtd: number;
  ano: number;
  mes: number;
}

export interface IJsonRHEstatisticasFolhaSalarioResumoReciboMesTotaisItem {
  ano: number;
  mes: number;
  totalEspecie: number;
  totalRecibo: number;
  totalAPagar: number;
  totalEncargos: number;
  totalCustosEmpresa: number;
}

export interface IRHEstatisticasFolhaSalarioResumoItem {
  codEmp: number;
  nome: string;
  totalAbonos: number;
  totalDescontos: number;
  totalIliquido: number;
  totalIRS: number;
  totalSS: number;
  totalSindicato: number;
  totalCGA: number;
  totalRecibo: number;
  totalEspecie: number;
  totalEncargos: number;
  totalCustosEmpresa: number;
  totalAPagar: number;
  departamento: number;
  ccusto: string;
  departamentoNome: string;
  ccustoNome: string;
  detalhes: Array<IRHEstatisticasFolhaSalarioResumoDetalheItem>;
  reciboMesTotais: Array<IJsonRHEstatisticasFolhaSalarioResumoReciboMesTotaisItem>;
}

export interface IRHEstatisticasAnaliseMensalItem {
  recordType: ERHAnaliseMensalRecordType;
  recordTypeDesc: string;
  nCodAbdesc: string;
  nomeCodAbdesc: string;
  totalGlobal: number;
}

export interface IRHEstatisticasAnaliseAnualItem {
  rowKey: string;
  recordType: ERHAnaliseMensalRecordType;
  recordTypeDesc: string;
  nCodAbdesc: string;
  nomeCodAbdesc: string;
  totalAnoAnterior: number;
  totalAnoFilter: number;
}

export interface IRHEstatisticasSegSocialResumoValColumns {
  valPRemperman: number;
  valRSubrefeicao: number;
  valFSubferias: number;
  valNSubnatal: number;
  valAAjudascusto: number;
  valBPremmensal: number;
  valCComissoes: number;
  valDCompcessacao: number;
  valHHonorarios: number;
  valMSubsregmensal: number;
  valOPrnaomensal: number;
  valSRsuplementar: number;
  valTTrabnocturno: number;
  valXOutsubsidios: number;
  val2Ferpagascess: number;
  val6Difvencim: number;
  valICtrintermit: number;
}

export interface IJsonRHEstatisticasSegSocialResumoItem extends IRHEstatisticasSegSocialResumoValColumns {
  ano: number;
  mes: number;
  numSegSoc: string;
  codEmp: number;
  nome: string;
  dtNascimento: TDate;
  taxaAplicar: number;
  nomeRegime: string;
  totalSegSocialRemun: number;
  encargosTrabalhador: number;
  encargosEmpresa: number;
  totalEncargos: number;
}

export interface IJsonRHEstatisticasSindicatoItem {
  codEmp: number;
  nome: string;
  nSindicato: number;
  sindicatoNome: string;
  sindicatoPerc: number;
  valorSujeito: number;
  valorDesconto: number;
}

export interface IRHEstatisticasSindicatoItem extends IJsonRHEstatisticasSindicatoItem {
  uid: string;
}

export interface IJsonRHEstatisticasSeguroResumoItem {
  codEmp: number;
  nome: string;
  numSegSoc: string;
  seguradora: string;
  valPRemperman: number;
  valRSubrefeicao: number;
  valFSubferias: number;
  valNSubnatal: number;
  valAAjudascusto: number;
  valBPremmensal: number;
  valCComissoes: number;
  valDCompcessacao: number;
  valHHonorarios: number;
  valMSubsregmensal: number;
  valOPrnaomensal: number;
  valSRsuplementar: number;
  valTTrabnocturno: number;
  valXOutsubsidios: number;
  val2Ferpagascess: number;
  val6Difvencim: number;
  valICtrintermit: number;
}

export interface IJsonRHEstatisticasMapaAnual {
  anoAnterior: Array<IRHEstatisticasFolhaSalarioResumoItem>;
  data: Array<IRHEstatisticasFolhaSalarioResumoItem>;
}

export interface IRHEstatisticasAnaliseAnualReciboTotais {
  totalEspecie: number;
  totalRecibo: number;
  totalAPagar: number;
  totalEncargos: number;
  totalCustosEmpresa: number;
}

export interface IRHEstatisticaParams {
  nEmpresa: string;
  ano: number;
  mes: number;
}

export interface IRHEstatisticasMapaATItem {
  codEmp: number;
  nome: string;
  nContribuinte: string;
  rendimentosAno: number;
  localRendimento: string;
  rendimentosSujeito: number;
  rendimentosNaoSujeito: number;
  rendimentosRetencoesSobreTaxa: number;
  rendimentosRetencaoIrs: number;
  rendimentosContibuicoesObrigatorias: number;
  rendimentosAnoAnterior: number;
  rendimentosContibuicoesSindicais: number;
  rendNaoSujeitosArt2A25: number;
  rendIsentoSujEnglobA81: number;
  rendIsentoSujEnglobA18: number;
  rendIsentosA40: number;
  rendIsentoSujEnglobA19: number;
  rendIsentoSujEnglobA12: number;
  rendTrabDependenteA68: number;
  rendIsentoSujEnglobA13: number;
  rendTrabDependenteA69: number;
  rendIsentoSujEnglobA11: number;
  rendIsentoSujEnglobA16: number;
  rendIsentoSujEnglobA17: number;
  rendIsentoSujEnglobA14: number;
  rendTrabDependenteA62: number;
  rendIsentoSujEnglobA15: number;
  rendTrabDependenteA63: number;
  rendTrabDependenteA61: number;
  rendTrabDependenteA66: number;
  rendTrabDependenteA67: number;
  rendTrabDependenteA2: number;
  rendTrabDependenteA64: number;
  rendTrabDependenteA3: number;
  rendTrabDependenteA65: number;
  rendNaoSujeitosArt12A32: number;
  rendNaoSujeitosArt12A33: number;
  rendNaoSujeitosArt12A30: number;
  rendNaoSujeitosArt12A31: number;
  rendTrabDependenteA4: number;
  rendTrabDependenteA5: number;
  rendNaoSujeitosArt2A22: number;
  rendNaoSujeitosArt2A23: number;
  rendTrabDependenteA: number;
  rendNaoSujeitosArt2A20: number;
  rendNaoSujeitosArt2A21: number;
  rendNaoSujeitosArt2A26: number;
  rendIsentoSujEnglobA82: number;
  rendNaoSujeitosArt2A27: number;
  rendNaoSujeitosArt2A24: number;
}
