<div class="ccustos-new">
  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [formInstance]="formInstance"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form (formInstanceChange)="formInstance = $event">
        <pl-group>
          <label [translate]="'ccustos.fields.nCCusto'"></label>
          <edit>
            <pl-edit
              type="text"
              attrName="nCCusto"
              [model]="model.nCCusto"
              (modelChange)="model.nCCusto = $event; nCCustoChanged()"
              [properties]="{modelOptions: {updateOn: 'blur'}, validators: {required: {value: true}, maxlength: {value: nccustoMaxLength}}}">
            </pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'ccustos.fields.nome'"></label>
          <edit>
            <pl-edit type="text" attrName="nome" [(model)]="model.nome" [properties]="{validators: {required: {value: true}, maxlength: {value: 50}}}"> </pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'ccustos.fields.encerrado'"></label>
          <edit>
            <pl-edit type="boolean" attrName="encerrado" [(model)]="model.encerrado" [properties]="{trueValue: -1, falseValue: 0}"> </pl-edit>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </entity-detail>
</div>
