<div class="entity-detail-form">
  <pl-form>
    <pl-group plAutoFocus>
      <label [translate]="'components.contabilidade.listagens.title'"></label>
      <edit>
        <pl-edit type="reports_movimentosemaberto" attrName="listagem" [(model)]="reportModel"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'components.contabilidade.listagens.fromAccount'"></label>
        <edit>
          <entity-autocomplete entity="pocs" attrName="daConta" [filter]="filter" [(selectedKey)]="deConta" output="key"> </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'components.contabilidade.listagens.toAccount'"></label>
        <edit>
          <entity-autocomplete entity="pocs" attrName="ateConta" [filter]="filter" [(selectedKey)]="ateConta" output="key"> </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'components.contabilidade.listagens.toPeriod'"></label>
        <edit>
          <entity-autocomplete entity="periodos" attrName="atePeriodo" [(model)]="periodo" [filter]="'loadAllAnos=1'" [properties]="{events: {keydown: fnKeydownProcessar}}"> </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>
  </pl-form>
</div>

<br />

<cg-pdf-viewer [pdfSrc]="pdfUrl" [toolbarInstanceId]="instanceName" [hideToolbar]="true"></cg-pdf-viewer>
