import {Subscription} from 'rxjs';
import {Component, OnDestroy} from '@angular/core';
import {AppService} from '../../../services/app/app.service';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {CGStateService} from '../../../components/state/cg.state.service';
import {EAppLaunchMode} from '../../../../common/site';
import {IAppStatus} from '../../../services/app/app.service.interface';
import {STATE_NAME_LOGIN} from '../login/login.state.interface';

@Component({
  selector: 'disconnected',
  templateUrl: './disconnected.state.component.html'
})
export class DisconnectedComponent implements OnDestroy {
  public readonly stateNameLogin: string;
  public partnerMode: boolean;
  public hybridMode: boolean;

  private readonly _subscriptionStatus: Subscription;

  constructor(
    private readonly _cgStateService: CGStateService,
    private readonly _appService: AppService,
    private readonly _cgModalService: CGModalService
  ) {
    this.back = this.back.bind(this);
    this.stateNameLogin = STATE_NAME_LOGIN;
    this.partnerMode = false;
    this.hybridMode = false;
    this._cgModalService.dismissAll('Disconnected');
    this._subscriptionStatus = this._appService.status().subscribe((status: IAppStatus) => {
      this.partnerMode = status.launchMode === EAppLaunchMode.Partner;
      this.hybridMode = status.launchMode === EAppLaunchMode.Hybrid || status.launchMode === EAppLaunchMode.HybridPartial;
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionStatus.unsubscribe();
  }

  public async back(): Promise<void> {
    await this._cgStateService.goBack();
  }
}
