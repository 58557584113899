<div class="extratosdt">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" (ngFormChange)="form = $event" [properties]="{validators: {required: {value: true}}}">
        <div class="row">
          <div class="col-sm-12 col-md-6" *ngIf="!hideNConta">
            <pl-group>
              <label [translate]="'extratosDT.filterFields.nConta'"></label>
              <edit>
                <entity-autocomplete
                  *ngIf="moduleType === moduleTypes.Geral"
                  entity="pocs"
                  attrName="nConta"
                  [model]="conta"
                  (modelChange)="changedConta($event)"
                  [filter]="filter"
                  outputKey="nConta"
                  [helperMode]="true"
                  [properties]="{readonly: readonlyConta}"
                  plAutoFocus>
                </entity-autocomplete>

                <entity-autocomplete
                  *ngIf="moduleType !== moduleTypes.Geral"
                  [entity]="moduleType === moduleTypes.Clientes ? entityNameClientes : entityNameFornecedores"
                  attrName="nConta"
                  [model]="conta"
                  (selectedKeyChange)="changedClifo($event)"
                  [properties]="{allowInvalid: false, readonly: readonlyConta}"
                  outputKey="nConta"
                  [output]="pocOutput"
                  plAutoFocus>
                </entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3">
            <pl-group>
              <label [translate]="'extratosDT.filterFields.deData'"></label>
              <edit>
                <pl-edit-datepicker [(model)]="deData" attrName="deData"></pl-edit-datepicker>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3">
            <pl-group>
              <label [translate]="'extratosDT.filterFields.ateData'"></label>
              <edit>
                <pl-edit-datepicker [(model)]="ateData" attrName="ateData"></pl-edit-datepicker>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3 align-self-end">
            <pl-group>
              <edit>
                <button type="button" class="btn btn-sm btn-primary" id="btnPesquisar" [disabled]="(!conta.nConta && !hideNConta) || !deData || !ateData" [click]="fnPesquisar" plPromise data-focus>
                  <i class="fa fa-search fa-fw"></i>&nbsp;<span [translate]="'global.btn.search'"></span>
                </button>
              </edit>
            </pl-group>
          </div>
        </div>
      </pl-form>
    </div>
  </cg-card-panel>

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="contabExtratosDT"
    [dataSource]="dataGridDefinition.dataSource"
    (onInitialized)="onInitialized($event)"
    (onCellClick)="onCellClick($event)">
    <div *dxTemplate="let item of 'detailTemplateDocContabilidade'">
      <ng-container *ngTemplateOutlet="templateDocContabilidade; context: {extrato: item.data}"></ng-container>
    </div>

    <div *dxTemplate="let item of 'detailTemplateDocComercial'">
      <ng-container *ngTemplateOutlet="templateDocComercial; context: {extrato: item.data}"></ng-container>
    </div>

    <div *dxTemplate="let item of 'detailTemplateDocComercialContab'">
      <pl-tabs [destroyOnHide]="false" *ngIf="hasContabilidade || item.data.faccbId || item.data._recibos?.length">
        <pl-tab id="tabDocContabilidade" caption="portals.items.contabilidade" *ngIf="hasContabilidade">
          <div *plTabContent>
            <ng-container *ngTemplateOutlet="templateDocContabilidade; context: {extrato: item.data}"></ng-container>
          </div>
        </pl-tab>

        <pl-tab id="tabDocComercial" caption="portals.items.erp" *ngIf="item.data.faccbId">
          <div *plTabContent>
            <ng-container *ngTemplateOutlet="templateDocComercial; context: {extrato: item.data}"></ng-container>
          </div>
        </pl-tab>

        <pl-tab id="tabDocRecibo" caption="extratosDT.caption.recibos" *ngIf="item.data._recibos?.length">
          <div *plTabContent>
            <ng-container *ngTemplateOutlet="templateRecibo; context: {extrato: item.data}"></ng-container>
          </div>
        </pl-tab>
      </pl-tabs>
      <pl-alert type="error" *ngIf="!item.data._recibos?.length && !item.data.faccbId && !hasContabilidade" [closeable]="false">
        <span [translate]="'extratosDT.msg.naoMostraDetalhe'"></span>
      </pl-alert>
    </div>
  </dx-data-grid>
</div>

<ng-template #templateDocContabilidade let-extrato="extrato">
  <split-viewer-doccontab-digital
    [(docContabilidade)]="extrato._docContabilidade"
    [contabDigitalDoc]="extrato._docContabDigital"
    [showToolbar]="true"
    [maintenanceMode]="true"
    [readonlyDigital]="true"
    [highlightNLanc]="extrato.nLanc">
  </split-viewer-doccontab-digital>
</ng-template>

<ng-template #templateDocComercial let-extrato="extrato">
  <documento-faturacao *ngIf="extrato?._docComercial" [doc]="extrato._docComercial" [actionPdf]="fnGetPdf"></documento-faturacao>
</ng-template>

<ng-template #templateRecibo let-extrato="extrato">
  <dx-data-grid [cgDxDataGrid]="gridDefinitionRecibos" cgDxDataGridInstanceName="recibosTable" [dataSource]="extrato._recibos">
    <div *dxTemplate="let item of 'templateDetailRecibo'">
      <recibo [recibo]="item.data"></recibo>
    </div>
  </dx-data-grid>
</ng-template>
