<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="'components.cgmodal.choosecountry.title'"></h5>
</div>

<div class="modal-body cg-choosecountry">
  <dx-data-grid [cgDxDataGrid]="dataGridDefinition" [dataSource]="countries" (onCellClick)="select($event.data)" (onCellDblClick)="close($event.data)"> </dx-data-grid>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary action-save" [disabled]="!selected" (evtClicked)="close(selected)"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span> </pl-button>

  <pl-button klass="btn-light action-close" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
</div>
