<div class="balancetes-grid contabilidade-balancetes-grid">
  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="contabilidadeBalancetesGrid"
    [dataSource]="dynamicInterface"
    (onInitialized)="onInitialized($event)"
    (onContentReady)="onContentReady()"
    (onCellPrepared)="onCellPrepared($event)"
    (onContextMenuPreparing)="onDataGridContextMenuPreparing($event)">
    <dxi-column [caption]="titleTableEmpresa" alignment="center" [fixed]="fixedColumns">
      <dxi-column [caption]="'balancetes.modal.table.conta' | translate" alignment="center">
        <dxi-column [dataField]="'nConta'" [dataType]="'string'" [caption]="'balancetes.modal.table.header.nconta' | translate" [width]="150" alignment="left" cellTemplate="cellTemplate">
        </dxi-column>
        <dxi-column
          [dataField]="'nomeConta'"
          [dataType]="'string'"
          [caption]="'balancetes.modal.table.header.nome' | translate"
          [width]="300"
          [calculateFilterExpression]="normalizeFilterExpressionAccentsFn">
        </dxi-column>
      </dxi-column>
    </dxi-column>

    <dxi-column *ngFor="let ano of yearListBalancete" [caption]="ano.caption | translate" alignment="center">
      <dxi-column *ngFor="let item of ano.headerFields" [caption]="item.caption | translate" alignment="center">
        <dxi-column
          *ngFor="let field of item.fields"
          [dataField]="field._dataField"
          [dataType]="field._dataField"
          [caption]="field._caption | translate"
          [allowSearch]="false"
          [allowHeaderFiltering]="false"
          format="#,##0.##00"
          alignment="right">
        </dxi-column>
      </dxi-column>
    </dxi-column>

    <div *dxTemplate="let cell of 'cellTemplate'" class="balancetes-dropdown-cell-nconta">
      <div>{{ cell.text }}</div>
      <div
        class="item-group-dropdown ms-1"
        tabindex="-1"
        ngbDropdown
        container="body"
        [placement]="['bottom-right', 'right']"
        *ngIf="(!myAccounting && !planoContasAlt) || (planoContasAlt && cell.data.tipo === tipoContaMovimento)">
        <button type="button" class="btn btn-xs btn-link dropdown-toggle" ngbDropdownToggle>
          <span class="caret"></span>
        </button>
        <div class="dropdown-menu" ngbDropdownMenu>
          <button type="button" class="dropdown-item" *ngIf="!planoContasAlt" (click)="openMaintenanceInstancePocs(cell.data.nConta)">
            <span>{{ 'balancetes.btn.dropdown.planocontas' | translate }}</span>
          </button>
          <button type="button" class="dropdown-item" *ngIf="!planoContasAlt && cell.data.tipo === tipoContaMovimento" (click)="openMaintenanceInstanceExtratosGrid(cell.data.nConta)">
            <span>{{ 'balancetes.btn.dropdown.extratosgrid' | translate }}</span>
          </button>
          <button
            type="button"
            class="dropdown-item"
            *ngIf="!planoContasAlt && cell.data.tipo === tipoContaMovimento"
            (click)="openMaintenanceInstanceHistoricoExtratos(cell.data.nConta, cell.data.nomeConta, cell.data.temCC)">
            <span>{{ 'balancetes.btn.dropdown.historicoextratos' | translate }}</span>
          </button>
          <button type="button" class="dropdown-item" *ngIf="!planoContasAlt && cell.data.temCC" (click)="openMaintenanceInstanceClifos(cell.data.nConta)">
            <span>{{ 'balancetes.btn.dropdown.clifos' | translate }}</span>
          </button>
          <button type="button" class="dropdown-item" *ngIf="!planoContasAlt && cell.data.temCC" (click)="openMaintenanceInstanceMovAberto(cell.data.nConta)">
            <span>{{ 'balancetes.btn.dropdown.movaberto' | translate }}</span>
          </button>
          <button type="button" class="dropdown-item" *ngIf="planoContasAlt && cell.data.tipo === tipoContaMovimento" (click)="openExtratoPlanoAlt(cell.data.nConta, false)">
            <span>{{ 'balancetes.btn.dropdown.extratoPlanoAlt' | translate }}</span>
          </button>
          <button type="button" class="dropdown-item" *ngIf="planoContasAlt && cell.data.tipo === tipoContaMovimento" (click)="openExtratoPlanoAlt(cell.data.nConta, true)">
            <span>{{ 'balancetes.btn.dropdown.acumuPlanoAlt' | translate }}</span>
          </button>
        </div>
      </div>
    </div>

    <div *dxTemplate="let cell of 'templatePerfilBalancete'" class="balancetes-dropdown-cell-nconta">
      <div class="balancetes-grid-tipo-perfil">
        <label [translate]="'balancetes.modal.tipoperfil'" class="me-2"></label>
        <pl-edit type="tipoPerfilBalancetes" attrName="tipoPerfil" [model]="tipoPerfil" (modelChange)="tipoPerfilChanged($event)"></pl-edit>
      </div>
    </div>

    <dxo-summary>
      <dxi-total-item *ngFor="let columnName of totalCollumnName" [showInColumn]="columnName.column" [displayFormat]="columnName.value" [skipEmptyValues]="true"></dxi-total-item>
    </dxo-summary>
  </dx-data-grid>

  <div class="me-auto mt-2">
    <ul class="table-legend">
      <li *ngFor="let key of keysTipoContaContab">
        <div class="table-legend-badge" [ngClass]="classLegend + '-' + tipoContaContab[key]"></div>
        <div class="table-legend-caption" [translate]="'balancetes.modal.' + classLegend + '.' + tipoContaContab[key]"></div>
      </li>
    </ul>
  </div>
</div>
