<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="'gestaodgemps.printmodal.title'"></h5>
</div>

<div class="modal-body" [plPromise]="promise">
  <pl-form>
    <pl-group>
      <pl-group>
        <label [translate]="'gestaodgemps.fields.report'"></label>
        <edit>
          <pl-edit [type]="reportType" attrName="listagem" [model]="reportModel" (modelChange)="reportModelChange($event)"> </pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label><span [translate]="'gestaodgemps.fields.activeOnly'"></span>:</label>
        <edit>
          <pl-edit-checkbox [(model)]="printFaltasFilters.soAtivos"></pl-edit-checkbox>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label><span [translate]="'gestaodgemps.fields.dataDe'"></span>:</label>
        <edit>
          <pl-edit-datepicker [(model)]="printFaltasFilters.deData"></pl-edit-datepicker>
        </edit>
      </pl-group>

      <pl-group>
        <label><span [translate]="'gestaodgemps.fields.dataAte'"></span>:</label>
        <edit>
          <pl-edit-datepicker [(model)]="printFaltasFilters.ateData"></pl-edit-datepicker>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label><span [translate]="'gestaodgemps.fields.codEmpDe'"></span>:</label>
        <edit>
          <entity-autocomplete entity="dgempsfull" attrName="codEMP" [(selectedKey)]="printFaltasFilters.codEmpDe" output="key" [helperMode]="true"> </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label><span [translate]="'gestaodgemps.fields.codEmpAte'"></span>:</label>
        <edit>
          <entity-autocomplete entity="dgempsfull" attrName="codEMP" [(selectedKey)]="printFaltasFilters.codEmpAte" output="key" [helperMode]="true"> </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <label></label>
      <edit>
        <button type="button" class="btn btn-outline btn-sm btn-primary" (click)="getPDFUrl()" data-focus>
          <i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span>
        </button>
      </edit>
    </pl-group>
  </pl-form>

  <hr />

  <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-light action-close" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
</div>
