<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'fct.strings.confirmTitle'"></h4>
</div>

<div class="modal-body">
  <label [translate]="promptMessage"></label>

  <pl-group>
    <edit>
      <pl-edit type="checkbox" [(model)]="model.sendEmail" [properties]="{label: 'fct.strings.sendEmail'}"></pl-edit>
    </edit>
  </pl-group>

  <div *ngIf="model.sendEmail" style="margin-top: -20px; margin-left: 25px; font-size: 12px" class="mb-2 text-warning">
    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;
    <span [translate]="'fct.messages.verifiqueEmails'"></span>
  </div>

  <pl-group>
    <edit>
      <pl-edit type="checkbox" [(model)]="model.downloadDoc" [properties]="{label: 'fct.strings.downloadDoc'}"></pl-edit>
    </edit>
  </pl-group>
</div>

<div class="modal-footer" plNavigation>
  <button type="submit" class="btn btn-primary" (click)="close(model)"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.yes'"></span></button>

  <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'users.actions.cancel'"></span></button>
</div>
