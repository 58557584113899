// DO NOT EDIT THIS FILE, EDIT THE GULP TASK ``NGCONSTANT`` SETTINGS INSTEAD, WHICH GENERATES THIS FILE
/* eslint-disable */
export const VERSION = '';
export const ENV = 'CG_ENV_PROD';
export const PRODUCTION = true;
export const API_URL = '';
export const DEV_EXTREME_LICENSE_KEY = 'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogImUzZTMyYzIxLTQ4NTUtNDcxMy04MmYwLWUwZjFjNjI0ZTAyMCIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjQxCn0=.mv+ZZSufIZ567QzO1iXVD6wlqQSU3yWJrDJMFY3wGMpzUmeWzyRqF4B2whSxgQdaQ+WXP+mbNII3xrf3uyiZg6btnk7ni+VZXZW0YqrCi8q9lIhtHPji1QN26a0WfK3gyIerjw==';
export const DOCKER = false;
export const FEATURE_FLAGS_API_TOKEN = 'default:production.4b7257487f4ffbff8211c8b907e9468013912acce4c52616a1cc2ba8';
/* eslint-enable */
