<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="getModalTitle()"></h4>
</div>

<div class="modal-body">
  <pl-tabs>
    <pl-tab caption="debDiretosSEPA.strings.historico">
      <div *plTabContent>
        <dx-data-grid [cgDxDataGrid]="dataGridLogsDefinition" [dataSource]="dataGridLogsDefinition.dataSource" cgDxDataGridInstanceName="ddSEPADataGridLogs"> </dx-data-grid>
      </div>
    </pl-tab>
    <pl-tab caption="debDiretosSEPA.strings.anexos">
      <div *plTabContent>
        <gdocs-viewer [folderId]="gdocFolderId" [showTreeViewer]="false" [treeCollapsed]="false" [params]="gdocParams" [tipoDocEntity]="tipoDocEntity.DebitosDiretosSEPA"> </gdocs-viewer>
      </div>
    </pl-tab>
  </pl-tabs>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
</div>
