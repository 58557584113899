import {NgModule} from '@angular/core';
import {CGCBindHtmlPipe} from './bind.html.pipe';
import {CGCCurrencyPipe} from './currency.pipe';
import {CGCDatePipe} from './date.pipe';
import {CGCDateTimePipe} from './datetime.pipe';
import {CGCDecimalPipe} from './decimal.pipe';
import {CGCFiltersTaxPipe} from './tax.pipe';
import {CGCHighlightPipe} from './highlight.pipe';
import {CGCMomentDurationFormatPipe} from './moment.duration.format.pipe';
import {CGCNewLinePipe} from './newline.pipe';
import {CGCNumberPipe} from './edit.number.pipe';
import {CGCPercentPipe} from './percent.pipe';
import {CGCTimePipe} from './time.pipe';

export * from './bind.html.pipe';
export * from './currency.pipe';
export * from './date.pipe';
export * from './datetime.pipe';
export * from './decimal.pipe';
export * from './edit.number.pipe';
export * from './filter';
export * from './highlight.pipe';
export * from './moment.duration.format.pipe';
export * from './newline.pipe';
export * from './percent.pipe';
export * from './tax.pipe';
export * from './time.pipe';

const DECLARATIONS = [
  CGCBindHtmlPipe,
  CGCCurrencyPipe,
  CGCDatePipe,
  CGCDateTimePipe,
  CGCDecimalPipe,
  CGCFiltersTaxPipe,
  CGCHighlightPipe,
  CGCMomentDurationFormatPipe,
  CGCNewLinePipe,
  CGCNumberPipe,
  CGCPercentPipe,
  CGCTimePipe
];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlPipesModule {}
