import {merge} from 'lodash-es';
import moment from 'moment';
import {BehaviorSubject, fromEvent, lastValueFrom, merge as rxMerge, Observable, of, Subject, Subscription} from 'rxjs';
import {distinctUntilChanged, map, mergeMap} from 'rxjs/operators';
import {Inject, Injectable, Injector, OnDestroy, Optional} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {StateService, Transition, UIRouterGlobals} from '@uirouter/core';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  IPlCookiesConsent,
  IPlFormatConfig,
  isArray,
  isBoolean,
  isMobile,
  isString,
  LoadingQueue,
  Logger,
  PlCookiesConsentService,
  PlDocumentService,
  PlFormatService,
  PlLocaleService,
  PlPageWrapperService
} from 'pl-comps-angular';
import {APP_LAUNCH_MODE} from '../../../common/api';
import {APP_LOCALE, EStatusCode, isProduction, LOCAL_STORAGE_CONSENT, LOCAL_STORAGE_LOCALE, LOCAL_STORAGE_REFRESHED, LOCAL_STORAGE_SIDEBAR_TOGGLED} from '../../../config/constants';
import {APP_LOCALES} from '../../../common/i18n/locales';
import {CGLocalStorageService} from '../storage/localstorage.service';
import {CGStateService} from '../../components/state/cg.state.service';
import {DEFAULT_COOKIES_CONSENT, SCHEMA_CONSENT} from '../../components/cg/cookieconsent/cg.cookies.consent.interface';
import {EAppLaunchMode} from '../../../common/site';
import {FUTURE_STATE_NAME_PORTAL, ICGStateDeclaration, ICGStateDeclarationData} from '../portals/portals.service.interface';
import {handleFirstPageLoad} from '../../../config/pageload';
import {IAppStatus} from './app.service.interface';
import {SCHEMA_BOOLEAN} from '../../../common/schemas';
import {STATE_NAME_DISCONNECTED} from '../../states/account/disconnected/disconnected.state.interface';
import {STATE_NAME_LOGIN} from '../../states/account/login/login.state.interface';

const globalPageTitle = 'CentralGest Cloud';
const cssClassDocumentDragging = 'cg-document-dragging';

@Injectable({
  providedIn: 'root'
})
export class AppService implements OnDestroy {
  private readonly _document: Document;
  private readonly _subjectStatus: BehaviorSubject<IAppStatus>;
  private readonly _subjectCookiesConsentOpen: BehaviorSubject<boolean>;
  private readonly _subjectPageUnload: Subject<void>;
  private readonly _loadingQueue: LoadingQueue;
  private readonly _subscriptionPageHide: Subscription;
  private readonly _subscriptionAppLaunchMode: Subscription;
  private readonly _subscriptionOnStart: Subscription;
  private readonly _subscriptionOnSuccess: Subscription;
  private readonly _subscriptionOnError: Subscription;
  private readonly _subscriptionDocumentDragging: Subscription;
  private _ngbModal: NgbModal;
  private _firstLoad: boolean;
  private _observableStatus: Observable<IAppStatus>;
  private _observableCookiesConsentOpen: Observable<boolean>;
  private _observableGlobalLoading: Observable<boolean>;
  private _observablePageHide: Observable<void>;
  private _observablePageUnload: Observable<void>;
  private _observableLanguage: Observable<string>;
  private _subscriptionSidebarToggled: Subscription;
  private _subscriptionCookiesConsent: Subscription;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(DOCUMENT) @Optional() document: any,
    private readonly _injector: Injector,
    private readonly _uiRouterGlobals: UIRouterGlobals,
    private readonly _stateService: StateService,
    private readonly _translateService: TranslateService,
    private readonly _logger: Logger,
    private readonly _plDocumentService: PlDocumentService,
    private readonly _plLocaleService: PlLocaleService,
    private readonly _plFormatService: PlFormatService,
    private readonly _plPageWrapperService: PlPageWrapperService,
    private readonly _plCookiesConsentService: PlCookiesConsentService,
    private readonly _cgLocalStorageService: CGLocalStorageService,
    private readonly _cgStateService: CGStateService
  ) {
    this._document = document;
    this._subjectStatus = new BehaviorSubject<IAppStatus>({
      launchMode: APP_LAUNCH_MODE.value,
      refreshed: false,
      disconnected: false,
      maintenance: false
    });
    this._subjectCookiesConsentOpen = new BehaviorSubject<boolean>(false);
    this._subjectPageUnload = new Subject<void>();
    this._loadingQueue = new LoadingQueue(this._logger);
    this._firstLoad = true;

    this._initPageWrapper();

    this._subscriptionAppLaunchMode = APP_LAUNCH_MODE.subscribe((launchMode: EAppLaunchMode) => {
      this.setStatus({launchMode: launchMode});
    });

    this._subscriptionPageHide = this._onPageHide().subscribe(() => {
      if (this._document) {
        this._document.defaultView.sessionStorage.setItem(LOCAL_STORAGE_REFRESHED, JSON.stringify(true));
      }
    });

    this._subscriptionOnStart = this._cgStateService.routerTransitionOnStart().subscribe((transition: Transition) => {
      const toState: ICGStateDeclaration = <ICGStateDeclaration>transition.to();
      this.setGlobalLoading(isArray(toState.resolve));
      if (toState.data?.disableRecover) {
        if (!this._ngbModal) {
          // Avoid "Circular dependency in DI" error when injected by constructor declaration
          this._ngbModal = this._injector.get<NgbModal>(NgbModal);
        }
        this._ngbModal.dismissAll(`DISMISS_ALL_STATE_NAME-${toState.name}`);
      }
    });

    this._subscriptionOnSuccess = this._cgStateService.routerTransitionOnSuccess().subscribe(() => {
      this.setGlobalLoading(false);
      this._checkFirstLoad();
    });

    this._subscriptionOnError = this._cgStateService.routerTransitionOnError().subscribe((transition: Transition) => {
      this.setGlobalLoading(false);
      this._checkFirstLoad();

      if (transition.to().name === FUTURE_STATE_NAME_PORTAL && !transition.from().name) {
        const detail: unknown = transition.error()?.detail;
        if (detail && detail instanceof HttpErrorResponse) {
          if (detail.status === EStatusCode.NoResponse) {
            this._stateService.go(STATE_NAME_DISCONNECTED);
          } else {
            this._stateService.go(STATE_NAME_LOGIN);
          }
        }
      }
    });

    this._subscriptionDocumentDragging = this._plDocumentService.dragging().subscribe((documentDragging: boolean) => {
      if (!this._document) {
        return;
      }
      if (documentDragging) {
        this._document.body.classList.add(cssClassDocumentDragging);
      } else {
        this._document.body.classList.remove(cssClassDocumentDragging);
      }
    });
  }

  public ngOnDestroy(): void {
    this._subjectStatus.complete();
    this._subjectCookiesConsentOpen.complete();
    this._subscriptionAppLaunchMode.unsubscribe();
    this._subscriptionAppLaunchMode.unsubscribe();
    this._subscriptionPageHide.unsubscribe();
    this._subscriptionOnStart.unsubscribe();
    this._subscriptionOnSuccess.unsubscribe();
    this._subscriptionOnError.unsubscribe();
    this._subscriptionDocumentDragging.unsubscribe();
    if (this._loadingQueue) {
      this._loadingQueue.dispose();
    }
    if (this._subscriptionSidebarToggled) {
      this._subscriptionSidebarToggled.unsubscribe();
    }
    if (this._subscriptionCookiesConsent) {
      this._subscriptionCookiesConsent.unsubscribe();
    }
  }

  public status(): Observable<IAppStatus> {
    if (!this._observableStatus) {
      this._observableStatus = this._subjectStatus.asObservable();
    }
    return this._observableStatus;
  }

  public cookiesConsentOpen(): Observable<boolean> {
    if (!this._observableCookiesConsentOpen) {
      this._observableCookiesConsentOpen = this._subjectCookiesConsentOpen.asObservable().pipe(distinctUntilChanged());
    }
    return this._observableCookiesConsentOpen;
  }

  public globalLoading(): Observable<boolean> {
    if (!this._observableGlobalLoading) {
      this._observableGlobalLoading = this._loadingQueue.loading();
    }
    return this._observableGlobalLoading;
  }

  public triggerPageUnload(): void {
    this._subjectPageUnload.next();
  }

  public onPageUnload(): Observable<void> {
    if (!this._observablePageUnload) {
      this._observablePageUnload = rxMerge(this._subjectPageUnload.asObservable(), this._onPageHide()).pipe(map(() => undefined));
    }
    return this._observablePageUnload;
  }

  public setStatus(value: Partial<IAppStatus>): void {
    this._subjectStatus.next(
      Object.freeze<IAppStatus>({
        ...this._subjectStatus.value,
        ...value
      })
    );
  }

  public setCookiesConsentOpen(value: boolean): void {
    this._subjectCookiesConsentOpen.next(value);
  }

  public setGlobalLoading(value: boolean | Promise<unknown> | Observable<unknown>, clearLoadingQueue: boolean = false): void {
    this._loadingQueue.setLoading(value, clearLoadingQueue);
  }

  public updateTitle(value?: string, params?: object): void {
    const data: ICGStateDeclarationData = <ICGStateDeclarationData>this._uiRouterGlobals.$current.data;
    if (!isString(value) && data?.pageTitle) {
      value = data.pageTitle;
    }
    if (value) {
      value = this._translateService.instant(value, params);
    }
    window.document.title = value ? `${globalPageTitle} - ${value}` : globalPageTitle;
  }

  public locale(): Observable<string> {
    if (!this._observableLanguage) {
      this._observableLanguage = APP_LOCALE.asObservable();
    }
    return this._observableLanguage;
  }

  public async loadLocale(locale: string): Promise<void> {
    if (!APP_LOCALES.includes(locale)) {
      this._logger.error(new Error(`Invalid locale: ${locale}`));
      return;
    }
    if (locale.startsWith('pt-')) {
      moment.locale('pt');
    } else {
      moment.locale(locale);
    }
    this._plLocaleService.applyLocale(locale);
    await lastValueFrom(this._translateService.use(locale));
  }

  public async setLocale(locale: string): Promise<void> {
    if (!APP_LOCALES.includes(locale)) {
      throw new Error(`Invalid locale: ${locale}`);
    }
    if (this._document) {
      this._document.defaultView.localStorage.setItem(LOCAL_STORAGE_LOCALE, locale);
      this._document.location.reload();
    } else {
      await this.loadLocale(locale);
      await this._cgStateService.reload();
    }
  }

  public format(): Observable<IPlFormatConfig> {
    return this._plFormatService.format;
  }

  public sendBeacon(url: string, data?: BodyInit | null): boolean {
    if (!this._document?.defaultView) {
      return false;
    }
    return this._document.defaultView.navigator.sendBeacon(url, data);
  }

  private _initPageWrapper(): void {
    this._cgLocalStorageService.getItem<boolean>(LOCAL_STORAGE_SIDEBAR_TOGGLED, SCHEMA_BOOLEAN).subscribe((value: boolean) => {
      this._subscriptionSidebarToggled = this._plPageWrapperService.toggled().subscribe((toggled: boolean) => {
        if (!isMobile()) {
          this._cgLocalStorageService.setItem(LOCAL_STORAGE_SIDEBAR_TOGGLED, toggled, SCHEMA_BOOLEAN).subscribe();
        }
      });
      if (isMobile() || !isBoolean(value)) {
        value = false;
      }
      this._plPageWrapperService.setToggled(value);
    });
    if (isProduction()) {
      this._cgLocalStorageService
        .has(LOCAL_STORAGE_CONSENT)
        .pipe(
          mergeMap<boolean, Observable<IPlCookiesConsent>>((hasConsent: boolean) => {
            return hasConsent ? this._cgLocalStorageService.getItem<IPlCookiesConsent>(LOCAL_STORAGE_CONSENT, SCHEMA_CONSENT) : of(undefined);
          })
        )
        .subscribe((storageConsent: IPlCookiesConsent) => {
          this._subscriptionCookiesConsent = this._plCookiesConsentService.consent.subscribe((consent: IPlCookiesConsent) => {
            this._cgLocalStorageService.setItem(LOCAL_STORAGE_CONSENT, consent, SCHEMA_CONSENT).subscribe();
            this.setCookiesConsentOpen(!consent.consented);
          });
          this._plCookiesConsentService.setConfig(merge({}, DEFAULT_COOKIES_CONSENT, {consent: storageConsent}));
        });
    }
  }

  private _checkFirstLoad(): void {
    if (this._firstLoad) {
      this._firstLoad = false;
      handleFirstPageLoad();
    }
  }

  private _onPageHide(): Observable<void> {
    if (!this._observablePageHide) {
      this._observablePageHide = fromEvent<PageTransitionEvent>(this._document.defaultView, 'pagehide', {capture: true, passive: true}).pipe(map(() => undefined));
    }
    return this._observablePageHide;
  }
}
