import {IJsonDocumFac, IJsonIntFac, IJsonListaInteg} from './jsonIntFac.module.interface';
import {IJsonDocContabilidade} from '../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';

export interface IIntFac extends IJsonIntFac {
  _errorList: Array<IListInteg>;
  _docToolbarId: string;
  _hasDetail: boolean;
  _docContab?: IJsonDocContabilidade;
}

export interface IListInteg extends IJsonListaInteg {
  _hasDetail: boolean;
  _index: number;
}

export interface IDocumFac extends IJsonDocumFac {
  _label: string;
  _selected: boolean;
}

export const MODULE_NAME_INTEGRACAO_DOCUMENTOS = 'intfac';
