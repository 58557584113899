<div class="planoscontasalternativos">
  <entity-detail entityName="planoscontasalternativos" [(model)]="model" [type]="type" [maintenanceMode]="maintenanceMode" [callback]="callback" (evtUpdate)="onUpdate($event)"></entity-detail>

  <pl-group *ngIf="model.tipoValidacao === typePOCAltTipoValidacao.ValidaRadicais" [properties]="{readonly: type === stateTypes.DETAIL}">
    <label [translate]="'planoscontasalternativos.fields.radicaisValidacao'"></label>
    <edit>
      <pl-edit type="string" attrName="radicaisValidacao" [(model)]="model.radicaisValidacao"></pl-edit>
    </edit>
  </pl-group>

  <pl-group [properties]="{readonly: type === stateTypes.DETAIL}">
    <edit>
      <pl-edit type="checkbox" attrName="inverteSinal" [(model)]="model.inverteSinal" [properties]="{label: 'planoscontasalternativos.fields.inverteSinal'}"></pl-edit>
      <div class="form-control-info">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
        <span class="btn" [translate]="'planoscontasalternativos.fields.inverteSinalInfo'"></span>
      </div>
    </edit>
  </pl-group>

  <fieldset *ngIf="type !== stateType.NEW">
    <legend [translate]="'planoscontasalternativos.fields.contas'"></legend>
    <dx-tree-list
      id="treeListPlanoContAlt"
      [cacheEnabled]="false"
      [dataSource]="model.linhas"
      [showBorders]="true"
      [autoExpandAll]="true"
      [columnAutoWidth]="true"
      [rootValue]="-1"
      [toolbar]="toolbarTreeList"
      [editing]="{allowUpdating: type === stateType.EDIT, selectTextOnEditStart: true, mode: 'cell', startEditAction: 'click'}"
      (onCellPrepared)="onTreeListCellPrepared($event)"
      keyExpr="nconta"
      parentIdExpr="ncontaPai">
      <dxi-column dataField="nconta" [caption]="'planoscontasalternativos.fields.nconta' | translate" cellTemplate="cellTemplateNConta" [allowEditing]="false"></dxi-column>
      <dxi-column dataField="contas" [caption]="'planoscontasalternativos.fields.contas' | translate" [allowEditing]="type === stateType.EDIT"></dxi-column>
      <dxi-column type="buttons" cellTemplate="cellTemplateActions" headerCellTemplate="headerCellTemplateActions"></dxi-column>

      <div *dxTemplate="let item of 'templateToolbarBefore'">
        <div ngbDropdown container="body">
          <button type="button" class="btn btn-primary btn-sm" ngbDropdownToggle><i class="fa fa-file-excel-o fa-fw"></i>&nbsp;<span [translate]="'global.btn.importExport'"></span></button>
          <div ngbDropdownMenu>
            <button type="button" ngbDropdownItem [click]="modeloImportContasAlt" plPromise>
              <i class="fa fa-fw fa-files-o"></i>&nbsp;<span [translate]="'planoscontasalternativos.actions.getModImportPAlt'"></span>
            </button>
            <button type="button" ngbDropdownItem [click]="importPlanoAlt" plPromise>
              <i class="fa fa-fw fa-file-excel-o"></i>&nbsp;<span [translate]="'planoscontasalternativos.actions.importPAlt'"></span>
            </button>
            <button type="button" ngbDropdownItem [click]="exportPlanoAtual" plPromise>
              <i class="fa fa-fw fa-file-excel-o"></i>&nbsp;<span [translate]="'planoscontasalternativos.actions.exportPlano'"></span>
            </button>
          </div>
        </div>
      </div>

      <div *dxTemplate="let item of 'cellTemplateNConta'">{{ item.data.nconta }} - {{ item.data.nome }}</div>

      <div *dxTemplate="let item of 'headerCellTemplateActions'">
        <button type="button" class="btn btn-primary btn-xs" [title]="'global.btn.add' | translate" (click)="openNewConta()">
          <i class="fa fa-fw fa-plus"></i>
        </button>
      </div>

      <div *dxTemplate="let item of 'cellTemplateActions'">
        <div ngbDropdown container="body" class="col-main-table-actions">
          <button type="button" class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle><i class="fa fa-cog"></i></button>
          <div ngbDropdownMenu>
            <button type="button" ngbDropdownItem (click)="openEditConta(item.data)"><i class="fa fa-fw fa-pencil-square-o"></i>&nbsp;<span [translate]="'global.btn.edit'"></span></button>
            <button type="button" ngbDropdownItem (click)="openDeleteConta(item.data)"><i class="fa fa-fw fa-trash"></i>&nbsp;<span [translate]="'global.btn.delete'"></span></button>
          </div>
        </div>
      </div>
    </dx-tree-list>

    <ul class="table-legend mt-3">
      <li *ngFor="let key of keysTipoContaContab">
        <div class="table-legend-badge" [ngClass]="classLegend + '-' + tipoContaContab[key]"></div>
        <div class="table-legend-caption" [translate]="'planoscontasalternativos.' + classLegend + '.' + tipoContaContab[key]"></div>
      </li>
    </ul>
  </fieldset>
</div>
