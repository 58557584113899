import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonAtivos} from '../../interfaces/jsonAtivos.entity.interface';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {AtivosMudarContaService} from '../../services/ativosMudarConta.service';

@Component({
  selector: 'modal-ativos-mudar-conta-ativo',
  templateUrl: './ativosMudarContaAtivo.modal.component.html'
})
export class AtivosMudarContaAtivoModalComponent extends CGModalComponent<IJsonAtivos> {
  @Input() public ativo: IJsonAtivos;
  public readonly pocsOutput: string;
  public readonly filter: string;
  public nContaDoAtivo: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosMudarContaService: AtivosMudarContaService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.pocsOutput = '{{nConta}} - {{nome}}';
    this.filter = `tipo=${ETipoContaContabilidade.Movimento}`;
    this.nContaDoAtivo = '';
  }

  public close(): Promise<void> {
    return this._cgModalService.showOkCancel('ativosmudarcontaativo.confirmacao', 'ativosmudarcontaativo.temcerteza').then(() => {
      return this._ativosMudarContaService.postRegistaMudancaDeContaDoAtivo(this.ativo, this.nContaDoAtivo).then((response: HttpResponse<IJsonAtivos>) => {
        this._plAlertService.success('ativosmudarcontaativo.message.success');
        super.close(response.body);
      });
    });
  }
}
