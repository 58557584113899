import {merge} from 'lodash-es';
import type {Subscription} from 'rxjs';
import {Component, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ValidatorFn} from '@angular/forms';
import {ColorPickerDirective} from 'ngx-color-picker';
import type {IPlEditColorPickerCallback, IPlEditColorPickerEventInput, IPlEditColorPickerEventSlider, IPlEditComponentOptionsInputColorPicker} from './edit.colorpicker.component.interface';
import type {IPlLocale} from '../../../common/locale/locales.interface';
import {isDefinedNotNull, isFunction, isObject} from '../../../common/utilities/utilities';
import {PlEditInputComponent} from '../../generic/input/edit.input.component';
import {PlLocaleService} from '../../../common/locale/locale.service';

@Component({
  selector: 'pl-colorpicker',
  templateUrl: './edit.colorpicker.component.html'
})
export class PlEditColorPickerComponent extends PlEditInputComponent<string, IPlEditComponentOptionsInputColorPicker<string>> implements OnInit, OnDestroy {
  @Input() public label: string;
  @Input() public callback: IPlEditColorPickerCallback;

  public currentLabel: string;

  @ViewChild(ColorPickerDirective) private readonly _colorPicker: ColorPickerDirective;
  private readonly _subscriptionLocale: Subscription;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plLocaleService: PlLocaleService
  ) {
    super(_injector);
    this._defaultOptions = Object.freeze<IPlEditComponentOptionsInputColorPicker<string>>(
      merge({}, this._defaultOptions, {
        width: '230px',
        height: 'auto',
        toggle: false,
        colorMode: 'color',
        outputFormat: 'auto',
        alphaChannel: 'enabled',
        fallbackColor: '#000000',
        position: 'right',
        positionOffset: 0,
        positionRelativeToArrow: false,
        presetLabel: undefined,
        presetColors: [],
        disableInput: false,
        dialogDisplay: 'popup',
        ignoredElements: [],
        saveClickOutside: true,
        okButton: false,
        okButtonText: undefined,
        okButtonClass: 'btn btn-primary action-ok',
        cancelButton: false,
        cancelButtonText: undefined,
        cancelButtonClass: 'btn btn-light action-cancel',
        addColorButton: false,
        addColorButtonText: undefined,
        addColorButtonClass: '',
        removeColorButtonClass: '',
        maxPresetColorsLength: undefined,
        presetEmptyMessage: undefined,
        presetEmptyMessageClass: '',
        useRootViewContainer: false,
        events: {
          open: undefined,
          close: undefined,
          change: undefined,
          cancel: undefined,
          select: undefined,
          toggleChange: undefined,
          inputChange: undefined,
          sliderChange: undefined,
          sliderDragStart: undefined,
          sliderDragEnd: undefined,
          presetColorsChange: undefined
        }
      })
    );
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this._defaultOptions = Object.freeze<IPlEditComponentOptionsInputColorPicker<string>>(
        merge({}, this._defaultOptions, {
          presetLabel: locale.plColorPicker.presetLabel,
          okButtonText: locale.plColorPicker.okButtonText,
          cancelButtonText: locale.plColorPicker.cancelButtonText,
          addColorButtonText: locale.plColorPicker.addColorButtonText
        })
      );
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._handleOptions();
    this.setValidators(this._requiredValidator());
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionLocale.unsubscribe();
  }

  public updateComponent(properties: IPlEditComponentOptionsInputColorPicker<string>): void {
    super.updateComponent(properties);
    this._handleOptions();
  }

  public render(value: string): Promise<void> {
    this.value = value;
    return super.render();
  }

  public eventColorPickerValue(type: 'open' | 'close' | 'select', value: string): void {
    if (this.options.events && isFunction(this.options.events[type])) {
      this.options.events[type](value);
    }
  }

  public eventColorPickerCancel(): void {
    if (this.options.events && isFunction(this.options.events.cancel)) {
      this.options.events.cancel();
    }
  }

  public eventColorPickerToggleChange(open: boolean): void {
    if (this.options.events && isFunction(this.options.events.toggleChange)) {
      this.options.events.toggleChange(open);
    }
  }

  public eventColorPickerInputChange(value: IPlEditColorPickerEventInput): void {
    if (this.options.events && isFunction(this.options.events.inputChange)) {
      this.options.events.inputChange(value);
    }
  }

  public eventColorPickerSlider(event: 'sliderChange' | 'sliderDragStart' | 'sliderDragEnd', value: IPlEditColorPickerEventSlider): void {
    if (this.options.events && isFunction(this.options.events[event])) {
      this.options.events[event](value);
    }
  }

  public eventColorPickerPresetsColorsChange(value: Array<string>): void {
    if (this.options.events && isFunction(this.options.events.presetColorsChange)) {
      this.options.events.presetColorsChange(value);
    }
  }

  private _handleOptions(): void {
    this.currentLabel = this.label || this.options.label;
    if (this.callback) {
      this.options.callback = this.callback;
    }
    if (this._colorPicker && isObject(this.options.callback)) {
      this.options.callback.openDialog = () => {
        this._colorPicker.openDialog();
      };
      this.options.callback.closeDialog = () => {
        this._colorPicker.closeDialog();
      };
    }
  }

  private _requiredValidator(): ValidatorFn {
    return () => {
      if (this.validate && this.options.validators.required?.value === true) {
        return isDefinedNotNull(this.value) ? undefined : {required: true};
      }
      return undefined;
    };
  }
}
