import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService, PlToolbarService, PlTranslateService} from 'pl-comps-angular';
import {
  EGTOCOPYFOR,
  IJsonGTOCheckLicenseEmpresas,
  IJsonGTOCopyEmpresa,
  IJsonGTOLicenseInfo,
  IJsonGTOTarefa,
  IJsonGTOTarefaCopyAnoData,
  IJsonGTOTarefaCopyEmpresasData
} from '../../jsonGTO.module.interface';
import {IGTOCopyForItem, IGTOCopyModalModel} from '../../gto.module.interface';
import {GtoService} from '../../gto.module.service';
import {IJsonEmpresaErpBasic} from '../../../../../interfaces/jsonEmpresa.interface';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import moment from 'moment';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import ArrayStore from 'devextreme/data/array_store';
import CustomStore from 'devextreme/data/custom_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';

const YEAR_SOURCE_SUBTRACT_NUM = 5;

@Component({
  selector: 'gto-copy-tasks-modal',
  templateUrl: './gto.copyTasks.modal.component.html'
})
export class GtoCopyTasksModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public licenseInfo: IJsonGTOLicenseInfo;
  @Input() public nempresa: string;
  @Input() public nomeEmpresa: string;

  public readonly dataGridDefinitionTasks: IDevExpressDataGrid;
  public readonly dataGridDefinitionCompanies: IDevExpressDataGrid;
  public readonly copyForEnum: typeof EGTOCOPYFOR;
  public readonly empresasTemplate: string;
  public readonly model: IGTOCopyModalModel;
  public readonly anosSource: Array<number>;
  public nEmpresaSource: Array<IJsonEmpresaErpBasic>;
  public copyForSource: Array<IGTOCopyForItem>;
  public selectedRowKeysTasks: Array<string>;
  public selectedRowKeysCompanies: Array<string>;

  private _dataGridInstanceCompanies: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _gtoService: GtoService,
    protected readonly _plToolbarService: PlToolbarService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
    this.selectedRowKeysTasks = [];
    this.selectedRowKeysCompanies = [];
    this.copyForEnum = EGTOCOPYFOR;
    this.empresasTemplate = '{{nempresa}} - {{nome}}';
    this.nEmpresaSource = [];
    this.model = {
      fullOverride: false,
      ano: moment().year(),
      copyAno: moment().year() + 1,
      copyFor: EGTOCOPYFOR.EMPRESA,
      nempresa: '',
      nome: ''
    };

    this.anosSource = [];
    for (let i = this.model.ano + 1; i > this.model.ano - YEAR_SOURCE_SUBTRACT_NUM; i--) {
      this.anosSource.push(i);
    }
    this.dataGridDefinitionTasks = {
      columnHidingEnabled: false,
      columns: [{dataField: 'nomeTarefa', dataType: 'string', caption: 'gto.fields.nomeTarefa'}],
      dataSource: [],
      export: {filename: 'gto.tarefas'},
      headerFilter: {visible: false},
      height: '60vh',
      pager: {visible: false},
      paging: {enabled: false, pageSize: 100},
      scrolling: {rowRenderingMode: 'virtual'},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      remoteOperations: false
    };
    this.dataGridDefinitionCompanies = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nEmpresa', dataType: 'string', caption: 'gto.fields.nEmpresa'},
        {dataField: 'nomeEmpresa', dataType: 'string', caption: 'gto.fields.nomeEmpresa'},
        {dataField: 'regimeIRCIRS', dataType: 'string', caption: 'gto.fields.regimeIRCIRS'},
        {dataField: 'periodoIVA', dataType: 'string', caption: 'gto.fields.periodoIVA'}
      ],
      dataSource: new CustomStore({
        key: 'nEmpresa',
        load: () => this._getEmpresasTableSourceMain()
      }),
      export: {filename: 'gto.paraEmpresas'},
      headerFilter: {visible: false},
      height: '60vh',
      pager: {visible: false},
      paging: {enabled: false, pageSize: 100},
      scrolling: {rowRenderingMode: 'virtual'},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      remoteOperations: false
    };

    this.copyForSource = [
      {value: EGTOCOPYFOR.EMPRESA, label: 'gto.empresa'},
      {value: EGTOCOPYFOR.ANO, label: 'gto.ano'}
    ];
  }

  public ngOnInit(): void {
    this._loadEmpresas();
    this.model.nempresa = this.nempresa;
    this.model.nome = this.nomeEmpresa;
    this._loadCompanyTasks();
  }

  public onYearChange(year: number): Promise<void> {
    this.model.ano = year;
    return this._loadCompanyTasks();
  }

  public nEmpresaChanged(item: IJsonEmpresaErpBasic): Promise<void> {
    this.model.nempresa = item.nempresa;
    this.model.nome = item.nome;
    return this._loadCompanyTasks().then(() => {
      if (this.model.copyFor === EGTOCOPYFOR.EMPRESA) {
        return this._dataGridInstanceCompanies.refresh();
      }
      return Promise.resolve();
    });
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstanceCompanies = component;
  }

  public readonly fnCopyTasks: () => Promise<void> = () => this._copyTasks();

  private async _copyTasks(): Promise<void> {
    try {
      const msg = this.model.fullOverride ? 'gto.iraSubstituirTarefasNoDestino' : 'gto.iraCriarAtualizarTarefasNoDestino';
      await this._cgModalService.showOkCancel('gto.confirmationModalTitle', msg, {
        size: 'md',
        btnOkText: 'global.btn.yes',
        btnCancelText: 'global.btn.no',
        backdrop: 'static',
        showCloseBtn: false,
        keyboard: false
      });

      if (this.model.copyFor === EGTOCOPYFOR.EMPRESA) {
        const data: IJsonGTOTarefaCopyEmpresasData = {
          fullOverride: this.model.fullOverride,
          empresas: this.selectedRowKeysCompanies,
          tarefas: this.selectedRowKeysTasks
        };

        if (!data.tarefas.length) {
          this._plAlertService.error('gto.temQueSelecionarUmaTarefa');
          return;
        }

        if (!data.empresas.length) {
          this._plAlertService.error('gto.temQueSelecionarUmaEmpresa');
          return;
        }

        if (!this.licenseInfo.haveContabDigitalLicense && !this.licenseInfo.haveGTOLicense) {
          this._plAlertService.error('gto.aSuaLicencaNaoTemGTO');
          return;
        }

        if (!this.licenseInfo.haveContabDigitalLicense) {
          const response: HttpResponse<IJsonGTOCheckLicenseEmpresas> = await this._gtoService.checkLicenseEmpresas(data.empresas);
          if (response.body.empresasToLicense.length > 0) {
            if (response.body.empresasToLicense.length <= response.body.nEmpresasPorConsumir) {
              const message = this._plTranslateService.translate('gto.temACertezaQuePertendeAddEmpresas', {nempresas: response.body.empresasToLicense.length});
              await this._cgModalService.showOkCancel('gto.promptTitle', message, {
                size: 'md',
                btnOkText: 'global.btn.yes',
                btnCancelText: 'global.btn.no',
                backdrop: 'static',
                showCloseBtn: false,
                keyboard: false
              });
              await this._gtoService.licenseAddEmpresas(response.body.empresasToLicense);
            } else {
              const message = this._plTranslateService.translate('gto.naoTemEmpresasSuficientes', {
                totalToConsume: response.body.nEmpresasPorConsumir,
                totalToAdd: response.body.empresasToLicense.length
              });
              await this._cgModalService.showOkCancel('gto.infoTitle', message, {
                size: 'md',
                showCancelBtn: false,
                backdrop: 'static',
                showCloseBtn: false,
                keyboard: false
              });
              return;
            }
          }
        }

        await this._gtoService.copyToCompanies(data);

        this._plAlertService.success('gto.tarefasCopiadasComSucesso');

        this.close();
      } else {
        const data: IJsonGTOTarefaCopyAnoData = {
          fullOverride: this.model.fullOverride,
          ano: this.model.copyAno,
          tarefas: this.selectedRowKeysTasks
        };

        if (this.model.ano === this.model.copyAno) {
          this._plAlertService.error('gto.naoPodeCopiarParaMesmoAno');
          return;
        }

        if (!data.tarefas.length) {
          this._plAlertService.error('gto.temQueSelecionarUmaTarefa');
          return;
        }

        await this._gtoService.copyToYear(data);

        this._plAlertService.success('gto.tarefasCopiadasComSucesso');

        this.close();
      }
    } catch (reason: unknown) {
      this._logger.error(reason);
    }
  }

  private _loadCompanyTasks(): Promise<void> {
    return this._gtoService.getCompanyTasks(this.model.nempresa, this.model.ano).then((response: HttpResponse<Array<IJsonGTOTarefa>>) => {
      this.selectedRowKeysTasks = [];
      this.dataGridDefinitionTasks.dataSource = new ArrayStore({
        key: 'gtoTarefaID',
        data: response.body
      });
      this.selectedRowKeysTasks = response.body.filter((item: IJsonGTOTarefa) => item.isActive).map((value: IJsonGTOTarefa) => value.gtoTarefaID);
    });
  }

  private _loadEmpresas(): void {
    this._gtoService.getAllEmpresasEE().then((response: HttpResponse<Array<IJsonEmpresaErpBasic>>) => {
      this.nEmpresaSource = response.body;
    });
  }

  private _getEmpresasTableSourceMain(): Promise<Array<IJsonGTOCopyEmpresa>> {
    return this._gtoService.getCompaniesForCopy(this.model.nempresa).then((response: HttpResponse<Array<IJsonGTOCopyEmpresa>>) => response.body);
  }
}
