import {HttpResponse} from '@angular/common/http';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import ArrayStore from 'devextreme/data/array_store';
import CustomStore from 'devextreme/data/custom_store';
import {Properties as ButtonProperties} from 'devextreme/ui/button';
import dxDataGrid, {ToolbarItem} from 'devextreme/ui/data_grid';
import {copy, isDefinedNotNull, isEmpty, isObject, isUndefinedOrNull, PlAlertService, TPlToolbarItem} from 'pl-comps-angular';
import {TDate} from '../../../../common/dates';
import {IRadioGroup} from '../../../../common/interfaces/interfaces';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellDblClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventRowDraggingOnAdd
} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {ModuloComponent} from '../../../components/module/module.component';
import {TTableLegend} from '../../../components/tablelegend/tablelegend.component.interface';
import {EGroupName} from '../../../../config/constants';
import moment from 'moment';
import {EConfigOptionsInstanceName} from '../../../services/config/options/config.options.service.interface';
import {
  EFatEletronicaConfCertificadoPill,
  EFatEletronicaConfCertificadoSafeTab,
  EFatEletronicaConfTab,
  ETipoDocumentoStatus,
  IFatEletronicaConfDocfa,
  IFatEletronicaConfStateParams,
  IFaturacaoEletronicaConfig
} from '../faturacaoEletronicaConfiguracoes.module.interface';
import {
  EModoFuncionamentoClientes,
  EModoFuncionamentoDocfas,
  ETipoCertificadoFaturaEletronica,
  IJsonFatEletronicaConfCliente,
  IJsonFatEletronicaConfDigitalSign,
  IJsonFatEletronicaConfSafe,
  IJsonFatEletronicaConfSafeCertificado,
  RADIO_GROUP_CHECK_SAFE,
  RADIO_GROUP_MODO_FUNCIONAMENTO_CLIENTES,
  RADIO_GROUP_MODO_FUNCIONAMENTO_DOCFAS
} from '../jsonFaturacaoEletronicaConfiguracoes.module.interface';
import {FaturacaoEletronicaConfiguracoesAddSafeCertificadoModalComponent} from '../modals/addsafecertificado/faturacaoEletronicaConfiguracoes.addSafeCertificado.modal.component';
import {FaturacaoEletronicaConfiguracoesClientModalComponent} from '../modals/client/faturacaoEletronicaConfiguracoes.client.modal.component';
import {FaturacaoEletronicaConfiguracoesGuideModalComponent} from '../modals/guide/faturacaoEletronicaConfiguracoes.guide.modal.component';
import {FaturacaoEletronicaConfiguracoesPostAddSafeCertificadoModalComponent} from '../modals/postaddsafecertificado/faturacaoEletronicaConfiguracoes.postAddSafeCertificado.modal.component';
import {FaturacaoEletronicaConfiguracoesUIService} from '../service/faturacaoEletronicaConfiguracoes.module.ui.service';
import {ETipoCidadao} from '../modals/addsafecertificado/faturacaoEletronicaConfiguracoes.addSafeCertificado.modal.interface';

const MAX_LENGTH_FIRSTNAME = 15;
const DIGITAL_SIGN_AUTH_ERROR = 'Necessita fazer autenticação';

@Component({
  selector: 'module-faturacao-eletronica-configuracoes',
  templateUrl: './faturacaoEletronicaConfiguracoes.module.component.html'
})
export class FaturacaoEletronicaConfiguracoesModuleComponent extends ModuloComponent implements OnInit {
  @Input() public fatEletronicaConfig: IFaturacaoEletronicaConfig;

  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public readonly tabs: typeof EFatEletronicaConfTab;
  public readonly certificadoPills: typeof EFatEletronicaConfCertificadoPill;
  public readonly certificadoSafeTabs: typeof EFatEletronicaConfCertificadoSafeTab;
  public readonly tiposCertificado: typeof ETipoCertificadoFaturaEletronica;
  public readonly statuses: typeof ETipoDocumentoStatus;
  public readonly dataGridDefinitionTiposDocumento: IDevExpressDataGrid;
  public readonly dataGridDefinitionColumnsTiposDocumentoSemFatEletronica: Array<IDevExpressDataGridColumn>;
  public readonly dataGridDefinitionColumnsTiposDocumentoComFatEletronica: Array<IDevExpressDataGridColumn>;
  public readonly dataGridDefinitionClientes: IDevExpressDataGrid;
  public readonly dataGridDefinitionSafeCertificados: IDevExpressDataGrid<IJsonFatEletronicaConfSafeCertificado, string>;
  public readonly tableLegend: TTableLegend;
  public readonly radioGroupModoFuncDocfas: IRadioGroup<EModoFuncionamentoDocfas>;
  public readonly radioGroupModoFuncClientes: IRadioGroup<EModoFuncionamentoClientes>;
  public readonly radioGroupCheckSAFE: IRadioGroup<boolean>;
  public allowAmbientePreProducao: boolean;
  public ambientePreProducao: boolean;
  public selectedSemFaturacaoEletronica: IFatEletronicaConfDocfa;
  public selectedComFaturacaoEletronica: IFatEletronicaConfDocfa;
  public emptyAtivosSemFaturacaoEletronicaTable: boolean;
  public emptyAtivosComFaturacaoEletronicaTable: boolean;
  public tabActiveId: EFatEletronicaConfTab;
  public certificadoPillActiveId: EFatEletronicaConfCertificadoPill;
  public tabCertificadoSafeActiveId: EFatEletronicaConfCertificadoSafeTab;
  public totalSafeCertificados: number;
  public totalSafePendentes: number;
  public faturacaoEletronicaDigitalSignConfig: IJsonFatEletronicaConfDigitalSign;
  public authorizerSecretDigitalSign: string;
  public hasDigitalSignUser: boolean;
  public isUpdatingDigitalSign: boolean;
  public checkSAFE: boolean;
  public optionAmbientePreProducao: boolean;
  public navPillPromise: Promise<unknown>;
  public mainLockPromise: Promise<unknown>;

  private readonly _btnGravar: TPlToolbarItem;
  private _dataGridInstanceSemFaturacaoEletronica: dxDataGrid;
  private _dataGridInstanceComFaturacaoEletronica: dxDataGrid;
  private _dataGridInstanceSafeCertificados: dxDataGrid<IJsonFatEletronicaConfSafeCertificado, string>;
  private _dataGridStoreTiposDocumentos: ArrayStore;
  private _initialFaturacaoEletronicaTiposDoc: Array<IFatEletronicaConfDocfa>;
  private _removedClients: Array<string>;
  private _promiseSafeCertificates: Promise<Array<IJsonFatEletronicaConfSafeCertificado>>;
  private _promiseDigitalSignAuthorizers: Promise<void>;
  private _promisePostAddSAFECertificate: Promise<void>;
  private _promisePostAddDigitalSignAuthorizer: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _faturacaoEletronicaConfiguracoesService: FaturacaoEletronicaConfiguracoesUIService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.hasDigitalSignUser = false;
    this.configOptionsInstanceName = this._faturacaoEletronicaConfiguracoesService.configOptionsInstanceName;
    this.configOptionsGroupName = this._faturacaoEletronicaConfiguracoesService.configOptionsGroupName;
    this.tabs = EFatEletronicaConfTab;
    this.certificadoPills = EFatEletronicaConfCertificadoPill;
    this.certificadoSafeTabs = EFatEletronicaConfCertificadoSafeTab;
    this.tiposCertificado = ETipoCertificadoFaturaEletronica;
    this.statuses = ETipoDocumentoStatus;
    this.dataGridDefinitionTiposDocumento = {
      columnHidingEnabled: false,
      export: {filename: 'faturacaoeletronicaconfiguracoes.tabs.tiposdocumento'},
      headerFilter: {visible: false},
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      remoteOperations: false,
      columnAutoWidth: true,
      scrolling: {rowRenderingMode: 'virtual'}
    };
    this.dataGridDefinitionColumnsTiposDocumentoSemFatEletronica = [
      {dataField: 'nDocFa', dataType: 'number', caption: 'faturacaoeletronicaconfiguracoes.fields.nDocFa', width: 150, alignment: 'left'},
      {dataField: 'nome', dataType: 'string', caption: 'faturacaoeletronicaconfiguracoes.fields.nome'},
      {dataField: 'estado', dataType: 'string', caption: 'faturacaoeletronicaconfiguracoes.fields.estado', width: 100, visible: true},
      {dataField: 'status', dataType: 'string', visible: false, showInColumnChooser: false, allowSearch: false}
    ];
    this.dataGridDefinitionColumnsTiposDocumentoComFatEletronica = [
      {dataField: 'nDocFa', dataType: 'number', caption: 'faturacaoeletronicaconfiguracoes.fields.nDocFa', width: 150, alignment: 'left'},
      {dataField: 'nome', dataType: 'string', caption: 'faturacaoeletronicaconfiguracoes.fields.nome'},
      {dataField: 'estado', dataType: 'string', caption: 'faturacaoeletronicaconfiguracoes.fields.estado', width: 100, visible: true},
      {dataField: 'reportDefault', dataType: 'string', caption: this._translateService.instant('faturacaoeletronicaconfiguracoes.fields.reportDefault')},
      {dataField: 'status', dataType: 'string', visible: false, showInColumnChooser: false, allowSearch: false}
    ];
    this.dataGridDefinitionClientes = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'faturacaoeletronicaconfiguracoes.fields.nConta'},
        {dataField: 'nome', dataType: 'string', caption: 'faturacaoeletronicaconfiguracoes.fields.nome'},
        {dataField: 'estado', dataType: 'string', caption: 'faturacaoeletronicaconfiguracoes.fields.estado', width: 80},
        {dataField: 'emails', dataType: 'string', caption: 'faturacaoeletronicaconfiguracoes.fields.emails'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnClientes', headerCellTemplate: 'headerCellTemplateBtnClientes', showInColumnChooser: false}
      ],
      dataSource: [],
      export: {filename: 'faturacaoeletronicaconfiguracoes.tabs.clientes'},
      keyExpr: 'nConta',
      remoteOperations: false
    };
    const dataGridToolbarBtnRefreshSAFE: ToolbarItem = {
      name: 'refreshSAFE',
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        hint: this._translateService.instant('entity.action.refresh'),
        onClick: () => {
          this._dataGridInstanceSafeCertificados.refresh();
        }
      } satisfies ButtonProperties
    };
    this.dataGridDefinitionSafeCertificados = {
      columns: [
        {dataField: 'nomeDaConta', dataType: 'string', caption: 'faturacaoeletronicaconfiguracoes.safe.nomeDaConta'},
        {dataField: 'nomeUtilizador', dataType: 'string', caption: 'faturacaoeletronicaconfiguracoes.safe.nomeUtilizador'}
      ],
      dataSource: new CustomStore({
        key: 'nomeDaConta',
        loadMode: 'raw',
        cacheRawData: false,
        load: (): Promise<Array<IJsonFatEletronicaConfSafeCertificado>> => this._getSAFECertificates(),
        remove: (nomeDaConta: string) => this._deleteSAFECertificado(nomeDaConta)
      }),
      columnHidingEnabled: false,
      editing: {allowDeleting: true},
      export: {filename: 'faturacaoeletronicaconfiguracoes.text.certificados'},
      remoteOperations: false,
      searchPanel: {visible: true},
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbarBtnAddSAFECertificado',
            locateInMenu: 'auto'
          },
          dataGridToolbarBtnRefreshSAFE,
          {name: 'exportButton'},
          {name: 'columnChooserButton'},
          {name: 'searchPanel'}
        ]
      }
    };

    this.tableLegend = [
      {caption: 'faturacaoeletronicaconfiguracoes.legend.gravado', badgeCSSClass: 'bg-success'},
      {caption: 'faturacaoeletronicaconfiguracoes.legend.porgravar', badgeCSSClass: 'bg-danger'}
    ];
    this.allowAmbientePreProducao = false;
    this.emptyAtivosSemFaturacaoEletronicaTable = true;
    this.emptyAtivosComFaturacaoEletronicaTable = true;
    this.totalSafeCertificados = -1;
    this.totalSafePendentes = -1;
    this._removedClients = [];
    this.optionAmbientePreProducao = undefined;
    this.ambientePreProducao = false;

    // let firstTimeConfigOptions = true;
    // this._subscriptionIdentity = this._authService.identityAsObservable().subscribe((session: TUserSession) => {
    //   this.allowAmbientePreProducao = isObject(session) && session.roles.findIndex((role: IJsonUserRole) => role.role === ROLE.API || role.role === ROLE.ADMIN) > -1;
    //   this._subscriptionConfigOptions = this._faturacaoEletronicaConfiguracoesService
    //     .getOptions()
    //     .subscribe((configOptions: TConfigOptions<boolean, IFaturacaoEletronicaConfiguracoesConfigOptions>) => {
    //       this.optionAmbientePreProducao = configOptions.get('ambientePreProducao').value;

    //       if (firstTimeConfigOptions) {
    //         firstTimeConfigOptions = false;
    //         this._evaluateInitialPill();
    //       }
    //     });
    // });

    this.radioGroupModoFuncDocfas = RADIO_GROUP_MODO_FUNCIONAMENTO_DOCFAS;
    this.radioGroupModoFuncClientes = RADIO_GROUP_MODO_FUNCIONAMENTO_CLIENTES;
    this.radioGroupCheckSAFE = RADIO_GROUP_CHECK_SAFE;

    this._clearFaturacaoEletronicaDigitalSignConfig();
    this.authorizerSecretDigitalSign = '';
    this.isUpdatingDigitalSign = false;
    this.checkSAFE = false;
    this._btnGravar = {
      id: 'save',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-save fa-fw"></i>',
      class: 'btn-primary',
      caption: 'global.btn.save',
      click: () => this._save()
    };

    this.authenticateDigitalSignAuthorizer = this.authenticateDigitalSignAuthorizer.bind(this);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.toolbar.addButton(this._btnGravar);
    this._btnGravar.visible = this.fatEletronicaConfig.faturacaoEletronicaAtiva;
    this.fatEletronicaConfig = {
      acessoClientes: true,
      acessoTiposDocumento: true,
      tiposDocumento: [],
      clientes: [],
      ...this.fatEletronicaConfig
    };

    this.dataGridDefinitionTiposDocumento.dataSource = this.fatEletronicaConfig.tiposDocumento;
    this._initialFaturacaoEletronicaTiposDoc = copy(this.fatEletronicaConfig.tiposDocumento);
    this._applyDataSources();

    const params: IFatEletronicaConfStateParams = <IFatEletronicaConfStateParams>this._transition.params();
    if (!isEmpty(params.tab)) {
      this.tabActiveId = params.tab;
      if (this.tabActiveId === EFatEletronicaConfTab.TiposDocumento) {
        if (!this.fatEletronicaConfig.acessoTiposDocumento) {
          this.tabActiveId = undefined;
        }
      } else if (this.tabActiveId === EFatEletronicaConfTab.Clientes) {
        if (!this.fatEletronicaConfig.acessoClientes) {
          this.tabActiveId = undefined;
        }
      }
    }
    if (!this.tabActiveId) {
      this.tabActiveId = EFatEletronicaConfTab.Certificado;
    }
    if (this.tabActiveId === EFatEletronicaConfTab.Certificado && !isEmpty(params.certificadoPill)) {
      this.certificadoPillActiveId = params.certificadoPill;
    }

    this._evaluateInitialPill();

    if (isObject(params.safe) || isObject(params.safeTestes)) {
      const ambientePreProducao = !isObject(params.safe);
      this.tabCertificadoSafeActiveId = EFatEletronicaConfCertificadoSafeTab.Pendentes;
      const modalInstance = this._cgModalService.showVanilla(FaturacaoEletronicaConfiguracoesPostAddSafeCertificadoModalComponent, {size: 'md'});
      const componentInstance: FaturacaoEletronicaConfiguracoesPostAddSafeCertificadoModalComponent = modalInstance.componentInstance;
      componentInstance.nomeDaConta = params.safe.nomeDaConta;
      componentInstance.accessToken = params.safe.accessToken;
      componentInstance.safetyKey = params.safe.safetyKey;
      componentInstance.expiresIn = params.safe.expiresIn;
      componentInstance.ambientePreProducao = ambientePreProducao;
      this._promisePostAddSAFECertificate = modalInstance.result
        .then(() => {
          this.certificadoPillActiveId = this._getCertificadoPillByTipo(this.fatEletronicaConfig.tipoCertificado);
          this._dataGridInstanceSafeCertificados.refresh();
          if (!params.safe.obterAtributos) {
            this._plAlertService.success('faturacaoeletronicaconfiguracoes.messages.successAddSafeCertificate');
            return Promise.resolve();
          }
          const validade: TDate = moment().add(params.safe.expiresIn, 'seconds');
          return this._faturacaoEletronicaConfiguracoesService.safeObterAtributos(params.safe.nomeDaConta, validade, ambientePreProducao).then((response: HttpResponse<string>) => {
            if (!response.body) {
              this._plAlertService.success('faturacaoeletronicaconfiguracoes.messages.successSAFEObterAtributos');
              this.tabCertificadoSafeActiveId = EFatEletronicaConfCertificadoSafeTab.Certificados;
            } else {
              this._plAlertService.error('faturacaoeletronicaconfiguracoes.errors.safeObterAtributos');
            }
          });
        })
        .finally(() => {
          this._promisePostAddSAFECertificate = undefined;
        });
    } else if (isObject(params.digitalSign) || isObject(params.digitalSignTestes)) {
      this._promisePostAddDigitalSignAuthorizer = (async () => {
        try {
          await this._faturacaoEletronicaConfiguracoesService.registerDigitalSignAuthorizer(
            params.digitalSign.nomeAutorizador,
            params.digitalSign.idAutorizador,
            params.digitalSign.authorizerSecretDigitalSign,
            true,
            params.digitalSign.ambientePreProducao
          );

          await this._faturacaoEletronicaConfiguracoesService.postAddDigitalSignAuthorizer(
            params.digitalSign.nomeAutorizador,
            params.digitalSign.code,
            params.digitalSign.safetyKey,
            params.digitalSign.ambientePreProducao
          );

          this._plAlertService.success(
            !params.digitalSign.authenticate
              ? 'faturacaoeletronicaconfiguracoes.messages.successAddDigitalSignAuthorizer'
              : 'faturacaoeletronicaconfiguracoes.messages.authenticateDigitalSignAuthorizer'
          );

          this.certificadoPillActiveId = this._getCertificadoPillByTipo(this.fatEletronicaConfig.tipoCertificado);

          this._promisePostAddDigitalSignAuthorizer = undefined;

          this._getDigitalSignAuthorizers(params.digitalSign.ambientePreProducao);
        } finally {
          this._promisePostAddDigitalSignAuthorizer = undefined;
        }
      })();
    }
  }

  public changedTab(activeId: string): void {
    this.tabActiveId = <EFatEletronicaConfTab>activeId;
  }

  public addClient(): void {
    const modalRef = this._cgModalService.showVanilla(FaturacaoEletronicaConfiguracoesClientModalComponent);
    const componentInstance: FaturacaoEletronicaConfiguracoesClientModalComponent = modalRef.componentInstance;
    componentInstance.fatEletronicaConfClienteList = this.fatEletronicaConfig.clientes;
    modalRef.result.then((response: Array<IJsonFatEletronicaConfCliente>) => {
      this._plAlertService.success('faturacaoeletronicaconfiguracoes.messages.clientAddedSucess');
      this.fatEletronicaConfig.clientes = response;
      this.dataGridDefinitionClientes.dataSource = this.fatEletronicaConfig.clientes;
    });
  }

  public editClient(item: IJsonFatEletronicaConfCliente): void {
    const modalRef = this._cgModalService.showVanilla(FaturacaoEletronicaConfiguracoesClientModalComponent);
    const componentInstance: FaturacaoEletronicaConfiguracoesClientModalComponent = modalRef.componentInstance;
    componentInstance.fatEletronicaConfCliente = item;
    componentInstance.fatEletronicaConfClienteList = copy(this.fatEletronicaConfig.clientes);
    modalRef.result.then((response: Array<IJsonFatEletronicaConfCliente>) => {
      this._plAlertService.success('faturacaoeletronicaconfiguracoes.messages.clientEditedSucess');
      this.fatEletronicaConfig.clientes = response;
      this.dataGridDefinitionClientes.dataSource = this.fatEletronicaConfig.clientes;
    });
  }

  public removeClient(item: IJsonFatEletronicaConfCliente): void {
    const index = this.fatEletronicaConfig.clientes.findIndex((value: IJsonFatEletronicaConfCliente) => value.nConta === item.nConta);
    if (index !== -1) {
      this.fatEletronicaConfig.clientes.splice(index, 1);
      this._removedClients.push(item.nConta);
    }
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized, semFaturacaoEletronica: boolean): void {
    if (this.tabActiveId === EFatEletronicaConfTab.TiposDocumento) {
      if (semFaturacaoEletronica) {
        this._dataGridInstanceSemFaturacaoEletronica = component;
      } else {
        this._dataGridInstanceComFaturacaoEletronica = component;
      }
    }
  }

  public onContentReady(semFaturacaoEletronica: boolean): void {
    if (this.tabActiveId === EFatEletronicaConfTab.TiposDocumento) {
      this.emptyAtivosSemFaturacaoEletronicaTable = this._dataGridInstanceSemFaturacaoEletronica.totalCount() === 0;
      this.emptyAtivosComFaturacaoEletronicaTable = this._dataGridInstanceComFaturacaoEletronica.totalCount() === 0;
      this._dataGridInstanceSemFaturacaoEletronica.endCustomLoading();
      this._dataGridInstanceComFaturacaoEletronica.endCustomLoading();
      if (semFaturacaoEletronica) {
        this._updateTiposDocumentos(this._dataGridInstanceSemFaturacaoEletronica.getDataSource().items());
      } else {
        this._updateTiposDocumentos(this._dataGridInstanceComFaturacaoEletronica.getDataSource().items());
      }
    }
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IFatEletronicaConfDocfa | IJsonFatEletronicaConfCliente>): void {
    if (event.rowType === 'data') {
      if (event.column.dataField === 'estado') {
        let applySuccess = false;
        if (this.tabActiveId === EFatEletronicaConfTab.TiposDocumento) {
          applySuccess =
            this._initialFaturacaoEletronicaTiposDoc.find((item: IFatEletronicaConfDocfa) => item.nDocFa === (<IFatEletronicaConfDocfa>event.data).nDocFa).sujeitoFaturaDigital ===
            (<IFatEletronicaConfDocfa>event.data).sujeitoFaturaDigital;
        }

        if (this.tabActiveId === EFatEletronicaConfTab.Clientes) {
          applySuccess = (<IJsonFatEletronicaConfCliente>event.data).faturacaoElectronica;
        }

        if (applySuccess) {
          event.cellElement.classList.add('bg-success');
        } else {
          event.cellElement.classList.add('bg-danger');
        }
      }
    }
  }

  public onCellClick({rowType, data}: IDevExpressDataGridEventOnCellClick<IFatEletronicaConfDocfa>): void {
    if (rowType !== 'data') {
      return;
    }
    if (this.tabActiveId === 'tiposdocumento') {
      if (data.status === ETipoDocumentoStatus.SemFaturacaoEletronica) {
        this.selectedSemFaturacaoEletronica = data;
      } else {
        this.selectedComFaturacaoEletronica = data;
      }
    }
  }

  public onCellDblClick({rowType, data}: IDevExpressDataGridEventOnCellDblClick<IFatEletronicaConfDocfa>): void {
    if (rowType !== 'data') {
      return;
    }
    if (this.tabActiveId === 'tiposdocumento') {
      if (data.status === ETipoDocumentoStatus.SemFaturacaoEletronica) {
        this.selectedSemFaturacaoEletronica = data;
        this.selectedRight();
      } else {
        this.selectedComFaturacaoEletronica = data;
        this.selectedLeft();
      }
    }
  }

  public allRight(): void {
    this._dataGridInstanceSemFaturacaoEletronica.beginCustomLoading(undefined);
    this._dataGridInstanceComFaturacaoEletronica.beginCustomLoading(undefined);
    const dataSourceSemFaturacaoEletronica: Array<IFatEletronicaConfDocfa> = this._dataGridInstanceSemFaturacaoEletronica.getDataSource().items();
    const values: Partial<IFatEletronicaConfDocfa> = {status: ETipoDocumentoStatus.ComFaturacaoEletronica, sujeitoFaturaDigital: true};
    for (const semFaturacaoEletronica of dataSourceSemFaturacaoEletronica) {
      this._updateDataGridStoreStatus(semFaturacaoEletronica.nDocFa, values);
    }
    this.selectedSemFaturacaoEletronica = undefined;
  }

  public selectedRight(): void {
    if (this.selectedSemFaturacaoEletronica) {
      this._dataGridInstanceSemFaturacaoEletronica.beginCustomLoading(undefined);
      this._dataGridInstanceComFaturacaoEletronica.beginCustomLoading(undefined);
      const values: Partial<IFatEletronicaConfDocfa> = {status: ETipoDocumentoStatus.ComFaturacaoEletronica, sujeitoFaturaDigital: true};
      this._updateDataGridStoreStatus(this.selectedSemFaturacaoEletronica.nDocFa, values);
      this.selectedSemFaturacaoEletronica = undefined;
    }
  }

  public selectedLeft(): void {
    if (this.selectedComFaturacaoEletronica) {
      this._dataGridInstanceSemFaturacaoEletronica.beginCustomLoading(undefined);
      this._dataGridInstanceComFaturacaoEletronica.beginCustomLoading(undefined);
      const values: Partial<IFatEletronicaConfDocfa> = {status: ETipoDocumentoStatus.SemFaturacaoEletronica, sujeitoFaturaDigital: false};
      this._updateDataGridStoreStatus(this.selectedComFaturacaoEletronica.nDocFa, values);
      this.selectedComFaturacaoEletronica = undefined;
    }
  }

  public allLeft(): void {
    this._dataGridInstanceSemFaturacaoEletronica.beginCustomLoading(undefined);
    this._dataGridInstanceComFaturacaoEletronica.beginCustomLoading(undefined);
    const dataSourceComFaturacaoEletronica: Array<IFatEletronicaConfDocfa> = this._dataGridInstanceComFaturacaoEletronica.getDataSource().items();
    const values: Partial<IFatEletronicaConfDocfa> = {status: ETipoDocumentoStatus.SemFaturacaoEletronica, sujeitoFaturaDigital: false};
    for (const comFaturacaoEletronica of dataSourceComFaturacaoEletronica) {
      this._updateDataGridStoreStatus(comFaturacaoEletronica.nDocFa, values);
    }
    this.selectedComFaturacaoEletronica = undefined;
  }

  public onDataGridInitializedSafeCertificados(event: IDevExpressDataGridEventOnInitialized<IJsonFatEletronicaConfSafeCertificado, string>): void {
    this._dataGridInstanceSafeCertificados = event.component;
  }

  public async onRowRemovedSafeCertificados(): Promise<void> {
    if (this._dataGridInstanceSafeCertificados.totalCount() < 1) {
      await this.setTipoCerficadoAtivo(ETipoCertificadoFaturaEletronica.SAFE);
    }
  }

  public authenticateDigitalSignAuthorizer(nomeAutorizador: string): () => Promise<void> {
    return () => {
      if (this.isUpdatingDigitalSign && this.hasDigitalSignUser) {
        return this._faturacaoEletronicaConfiguracoesService
          .updateDigitalSignCertificate(
            this.faturacaoEletronicaDigitalSignConfig.nomeAutorizador,
            {
              nomeAutorizador: this.faturacaoEletronicaDigitalSignConfig.nomeAutorizador,
              idAutorizador: this.faturacaoEletronicaDigitalSignConfig.idAutorizador,
              secret: this.authorizerSecretDigitalSign,
              usoGlobal: true
            },
            this.ambientePreProducao
          )
          .then(() => {
            return this._faturacaoEletronicaConfiguracoesService
              .authenticateDigitalSignAuthorizerUI(nomeAutorizador, this.authorizerSecretDigitalSign, this.faturacaoEletronicaDigitalSignConfig.idAutorizador, this.ambientePreProducao)
              .catch((reason: unknown) => {
                this._logger.error(reason);
              });
          });
      } else if (!this.hasDigitalSignUser) {
        return this._faturacaoEletronicaConfiguracoesService
          .authenticateDigitalSignAuthorizerUI(nomeAutorizador, this.authorizerSecretDigitalSign, this.faturacaoEletronicaDigitalSignConfig.idAutorizador, this.ambientePreProducao)
          .catch((reason: unknown) => {
            this._logger.error(reason);
          });
      } else if (!this.isUpdatingDigitalSign && this.hasDigitalSignUser) {
        return this._faturacaoEletronicaConfiguracoesService
          .authenticateDigitalSignAuthorizerUI(nomeAutorizador, this.authorizerSecretDigitalSign, this.faturacaoEletronicaDigitalSignConfig.idAutorizador, this.ambientePreProducao)
          .catch((reason: unknown) => {
            this._logger.error(reason);
          });
      }
      return Promise.resolve();
    };
  }

  public async setTipoCerficadoAtivo(tipoCertificado: ETipoCertificadoFaturaEletronica, force: boolean = false): Promise<unknown> {
    if (
      !(await this._checkDigitalAuthValid()) &&
      (tipoCertificado === ETipoCertificadoFaturaEletronica.DigitalSign || tipoCertificado === ETipoCertificadoFaturaEletronica.DigitalSignTestes) &&
      force
    ) {
      this._plAlertService.error('faturacaoeletronicaconfiguracoes.errors.noAuthFound');
      return Promise.resolve();
    }

    if (
      this._dataGridInstanceSafeCertificados.totalCount() < 1 &&
      (tipoCertificado === ETipoCertificadoFaturaEletronica.SAFE || tipoCertificado === ETipoCertificadoFaturaEletronica.SAFETestes) &&
      force
    ) {
      this._plAlertService.error('faturacaoeletronicaconfiguracoes.safe.errors.semItems');
      return Promise.resolve();
    }

    const isUncheck = this.fatEletronicaConfig.faturacaoEletronicaAtiva && this.fatEletronicaConfig.tipoCertificado === tipoCertificado;
    this.mainLockPromise = Promise.resolve();
    if (!this.fatEletronicaConfig.faturacaoEletronicaAtiva || isUncheck) {
      if (isUncheck) {
        tipoCertificado = undefined;
      }
      this.mainLockPromise = this._ativar(!isUncheck, tipoCertificado);
    }

    this.mainLockPromise.then(async () => {
      const previousTipoCertificado: ETipoCertificadoFaturaEletronica = this.fatEletronicaConfig.tipoCertificado;
      this.fatEletronicaConfig.tipoCertificado = tipoCertificado;
      if (isDefinedNotNull(this.fatEletronicaConfig.tipoCertificado)) {
        try {
          await this._faturacaoEletronicaConfiguracoesService.updateTipoCertificadoActive(tipoCertificado);
          this._plAlertService.success('faturacaoeletronicaconfiguracoes.messages.successSetTipoCerficadoAtivo');
        } catch (reason) {
          this._logger.error(reason);
          this.fatEletronicaConfig.tipoCertificado = previousTipoCertificado;
        }
      }
      return undefined;
    });
    return this.mainLockPromise;
  }

  public onChangeModoFuncDocfas(): Promise<void> {
    switch (this.fatEletronicaConfig.modoFuncionamentoDocfas) {
      case EModoFuncionamentoDocfas.TodosOsDocfasSaftFtFrNdNc:
        this.dataGridDefinitionTiposDocumento.dataSource = [];
        break;
      case EModoFuncionamentoDocfas.SoDocfasSelecionados:
        this.dataGridDefinitionTiposDocumento.dataSource = this.fatEletronicaConfig.tiposDocumento;
    }
    this._applyDataSourceTipoDocumento();
    return Promise.resolve();
  }

  public onChangeModoFuncClientes(): Promise<void> {
    switch (this.fatEletronicaConfig.modoFuncionamentoClientes) {
      case EModoFuncionamentoClientes.TodosOsClientes:
        this.dataGridDefinitionClientes.dataSource = [];
        break;
      case EModoFuncionamentoClientes.SoClientesSelecionados:
        this.dataGridDefinitionClientes.dataSource = this.fatEletronicaConfig.clientes;
    }
    return Promise.resolve();
  }

  public openInfoModal(type: 'faq' | 'guia'): void {
    if (type === 'faq') {
      const message: string = [
        this._translateService.instant('faturacaoeletronicaconfiguracoes.faq.faq1'),
        this._translateService.instant('faturacaoeletronicaconfiguracoes.faq.faq2'),
        this._translateService.instant('faturacaoeletronicaconfiguracoes.faq.faq3'),
        this._translateService.instant('faturacaoeletronicaconfiguracoes.faq.faq4'),
        this._translateService.instant('faturacaoeletronicaconfiguracoes.faq.faq5'),
        this._translateService.instant('faturacaoeletronicaconfiguracoes.faq.faq6'),
        this._translateService.instant('faturacaoeletronicaconfiguracoes.faq.faq7'),
        this._translateService.instant('faturacaoeletronicaconfiguracoes.faq.faq8')
      ].join('');
      this._cgModalService.showOkCancel('global.text.faq', message, {showOkBtn: false, showCancelBtn: false, showCloseBtn: true});
    } else if (type === 'guia') {
      this._cgModalService.showVanilla(FaturacaoEletronicaConfiguracoesGuideModalComponent);
    }
  }

  public hasDigitalSignAuthError(): boolean {
    return this.faturacaoEletronicaDigitalSignConfig.error && this.faturacaoEletronicaDigitalSignConfig.errorDescription.includes(DIGITAL_SIGN_AUTH_ERROR);
  }

  public readonly fnAddSAFECertificate: () => Promise<void> = () => this._addSAFECertificate();

  public readonly fnDeactivateCertificate: () => Promise<void> = () => this._deactivateCertificate();

  public readonly fnActivateCertificate: (tipo: ETipoCertificadoFaturaEletronica) => () => Promise<void> = (tipo: ETipoCertificadoFaturaEletronica) => (): Promise<void> =>
    this._activateCertificate(tipo);

  public readonly fnOnAddTipoDocumentoTable = (event: IDevExpressDataGridEventRowDraggingOnAdd<ETipoDocumentoStatus, IFatEletronicaConfDocfa>): void => {
    this._onAddTipoDocumentoTable(event);
  };

  public readonly fnDeleteDigitalSignAuthData = (): Promise<void> => this._deleteDigitalSignAuthData();

  private _addSAFECertificate(): Promise<void> {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(FaturacaoEletronicaConfiguracoesAddSafeCertificadoModalComponent, {size: 'md'});
    const componentInstance: FaturacaoEletronicaConfiguracoesAddSafeCertificadoModalComponent = modalInstance.componentInstance;
    componentInstance.ambientePreProducao = this.ambientePreProducao;
    componentInstance.nomeConta = `${this._configService.configurations.empresa.ncontribuemp}-${this.session.firstName.slice(0, MAX_LENGTH_FIRSTNAME)}`;
    componentInstance.email = this.session.email;
    componentInstance.tipoCidadao = ETipoCidadao.NACIONAL;

    return modalInstance.result;
  }

  private _evaluateAmbientePreProducao(): void {
    this._getAmbientePreProducao();
    if (this._dataGridInstanceSafeCertificados) {
      this._dataGridInstanceSafeCertificados.refresh();
    }
    this._getDigitalSignAuthorizers();
  }

  private _evaluateInitialPill(): void {
    if (!this.certificadoPillActiveId && isObject(this.fatEletronicaConfig) && this.fatEletronicaConfig.tipoCertificado !== ETipoCertificadoFaturaEletronica.CertificadoPFX) {
      this.certificadoPillActiveId = this._getCertificadoPillByTipo(this.fatEletronicaConfig.tipoCertificado);
      this._evaluateAmbientePreProducao();
    }
  }

  private _getCertificadoPillByTipo(tipo: ETipoCertificadoFaturaEletronica): EFatEletronicaConfCertificadoPill {
    switch (tipo) {
      case ETipoCertificadoFaturaEletronica.SAFE:
        return EFatEletronicaConfCertificadoPill.Safe;
      case ETipoCertificadoFaturaEletronica.DigitalSign:
        return EFatEletronicaConfCertificadoPill.DigitalSign;
      case ETipoCertificadoFaturaEletronica.DigitalSignTestes:
        return EFatEletronicaConfCertificadoPill.DigitalSignTestes;
      case ETipoCertificadoFaturaEletronica.SAFETestes:
        return EFatEletronicaConfCertificadoPill.SAFETestes;
      default:
        return EFatEletronicaConfCertificadoPill.DigitalSign;
    }
  }

  private _applyDataSources(): void {
    this._removedClients = [];
    this._applyDataSourceTipoDocumento();
    this.dataGridDefinitionClientes.dataSource = this.fatEletronicaConfig.modoFuncionamentoClientes === EModoFuncionamentoClientes.SoClientesSelecionados ? this.fatEletronicaConfig.clientes : [];
  }

  private _applyDataSourceTipoDocumento(): void {
    if (this.fatEletronicaConfig.modoFuncionamentoDocfas === EModoFuncionamentoDocfas.TodosOsDocfasSaftFtFrNdNc) {
      return;
    }
    this.selectedSemFaturacaoEletronica = undefined;
    this.selectedComFaturacaoEletronica = undefined;
    if (this._dataGridInstanceSemFaturacaoEletronica) {
      this._dataGridInstanceSemFaturacaoEletronica.deselectAll();
    }
    if (this._dataGridInstanceComFaturacaoEletronica) {
      this._dataGridInstanceComFaturacaoEletronica.deselectAll();
    }
    const tiposDocumento: Array<IFatEletronicaConfDocfa> = this.fatEletronicaConfig.tiposDocumento.map((item: IFatEletronicaConfDocfa) => {
      return {
        nDocFa: item.nDocFa,
        sujeitoFaturaDigital: item.sujeitoFaturaDigital,
        nome: item.nome,
        estado: item.estado,
        reportDefault: item.reportDefault,
        status: !item.sujeitoFaturaDigital ? ETipoDocumentoStatus.SemFaturacaoEletronica : ETipoDocumentoStatus.ComFaturacaoEletronica
      };
    });
    this._dataGridStoreTiposDocumentos = new ArrayStore({
      key: 'nDocFa',
      data: tiposDocumento
    });
    this.dataGridDefinitionTiposDocumento.dataSource = {
      store: this._dataGridStoreTiposDocumentos,
      reshapeOnPush: true
    };
  }

  private _onAddTipoDocumentoTable(event: IDevExpressDataGridEventRowDraggingOnAdd<ETipoDocumentoStatus, IFatEletronicaConfDocfa>): void {
    const key: number = event.itemData.nDocFa;
    const values: Partial<IFatEletronicaConfDocfa> = {
      status: event.toData,
      sujeitoFaturaDigital: event.toData === ETipoDocumentoStatus.ComFaturacaoEletronica
    };
    this._updateDataGridStoreStatus(key, values);
  }

  private _updateDataGridStoreStatus(key: number, values: Partial<IFatEletronicaConfDocfa>): void {
    this._dataGridStoreTiposDocumentos.update(key, values).then(() => {
      this._dataGridStoreTiposDocumentos.push([{type: 'update', key: key, data: values}]);
    });
  }

  private _updateTiposDocumentos(tiposDocumento: Array<IFatEletronicaConfDocfa>): void {
    for (const doc of tiposDocumento) {
      const index = this.fatEletronicaConfig.tiposDocumento.findIndex((item: IFatEletronicaConfDocfa) => item.nDocFa === doc.nDocFa);
      if (index !== -1) {
        this.fatEletronicaConfig.tiposDocumento[index] = doc;
      }
    }
  }

  private _getSAFECertificates(): Promise<Array<IJsonFatEletronicaConfSafeCertificado>> {
    if (!this._promiseSafeCertificates) {
      this.totalSafeCertificados = -1;
      this.totalSafePendentes = -1;
      this._promiseSafeCertificates = Promise.resolve(this._promisePostAddSAFECertificate).then(() =>
        this._faturacaoEletronicaConfiguracoesService
          .getSAFECertificates(this.ambientePreProducao)
          .then((response: HttpResponse<IJsonFatEletronicaConfSafe>) => {
            this.totalSafeCertificados = response.body.certificados.length + response.body.pendentes.length;
            const certificatesList: Array<IJsonFatEletronicaConfSafeCertificado> = response.body.certificados;
            for (const certificate of response.body.pendentes) {
              certificate.state = 'Pendente';
              certificatesList.push(certificate);
            }
            return certificatesList;
          })
          .finally(() => {
            this._promiseSafeCertificates = undefined;
          })
      );
    }
    return this._promiseSafeCertificates;
  }

  private _getDigitalSignAuthorizers(isAmbientePreProducao: boolean = undefined): Promise<void> {
    if (isUndefinedOrNull(isAmbientePreProducao)) {
      isAmbientePreProducao = this.ambientePreProducao;
    }
    if (!this._promiseDigitalSignAuthorizers) {
      this._promiseDigitalSignAuthorizers = Promise.resolve(this._promisePostAddDigitalSignAuthorizer).then(() =>
        this._faturacaoEletronicaConfiguracoesService
          .getDigitalSignAuthorizers(isAmbientePreProducao)
          .then((response: HttpResponse<Array<IJsonFatEletronicaConfDigitalSign>>) => {
            if (response.body.length > 0) {
              this.hasDigitalSignUser = true;
              this.faturacaoEletronicaDigitalSignConfig = response.body[0];
              this.authorizerSecretDigitalSign = this.authorizerSecretDigitalSign !== '' ? this.authorizerSecretDigitalSign : '*********';
            } else {
              this._clearFaturacaoEletronicaDigitalSignConfig();
              this.authorizerSecretDigitalSign = '';
              this.hasDigitalSignUser = false;
            }
          })
          .finally(() => {
            this._promiseDigitalSignAuthorizers = undefined;
          })
      );
    }
    return this._promiseDigitalSignAuthorizers;
  }

  private async _deleteSAFECertificado(nomeDaConta: string): Promise<void> {
    const safeCertificado: IJsonFatEletronicaConfSafeCertificado = await this._dataGridInstanceSafeCertificados.getDataSource().store().byKey(nomeDaConta);
    return this._faturacaoEletronicaConfiguracoesService.deleteSAFECertificate(safeCertificado.nomeDaConta, safeCertificado.authorizationCode, this.ambientePreProducao).then(() => undefined);
  }

  private _save(): Promise<void> {
    if (this._removedClients.length) {
      const dissmissRemoveClient: Array<string> = [];
      for (const removed of this._removedClients) {
        const index = this.fatEletronicaConfig.clientes.findIndex((value: IJsonFatEletronicaConfCliente) => value.nConta === removed);
        if (index !== -1) {
          dissmissRemoveClient.push(removed);
        }
      }
      for (const client of dissmissRemoveClient) {
        const index = this._removedClients.findIndex((value: string) => value === client);
        if (index !== -1) {
          dissmissRemoveClient.splice(index, 1);
        }
      }
    }

    return this._faturacaoEletronicaConfiguracoesService.updateConfiguracoes(this._removedClients.join(','), this.fatEletronicaConfig).then(() => {
      this._plAlertService.success('faturacaoeletronicaconfiguracoes.messages.successConfigSaved');
      for (const tipoDocumento of this.fatEletronicaConfig.tiposDocumento) {
        tipoDocumento.sujeitoFaturaDigital = tipoDocumento.status === ETipoDocumentoStatus.ComFaturacaoEletronica;
      }
      for (const cliente of this.fatEletronicaConfig.clientes) {
        cliente.faturacaoElectronica = true;
      }
      this._initialFaturacaoEletronicaTiposDoc = copy(this.fatEletronicaConfig.tiposDocumento);
      this._applyDataSources();
    });
  }

  private _getAmbientePreProducao(): boolean {
    this.ambientePreProducao =
      this.allowAmbientePreProducao &&
      this.optionAmbientePreProducao &&
      (this.certificadoPillActiveId === EFatEletronicaConfCertificadoPill.DigitalSignTestes || this.certificadoPillActiveId === EFatEletronicaConfCertificadoPill.SAFETestes);
    return this.ambientePreProducao;
  }

  private async _deleteDigitalSignAuthData(): Promise<void> {
    await this._cgModalService.showOkCancel('faturacaoeletronicaconfiguracoes.text.confirmation', 'faturacaoeletronicaconfiguracoes.messages.promptDeleteDigitalSignAuthData', {
      size: 'md',
      backdrop: 'static',
      btnOkText: 'global.btn.yes',
      btnCancelText: 'global.btn.no',
      keyboard: false,
      icon: 'warning'
    });
    await this._faturacaoEletronicaConfiguracoesService.deleteDigitalSignAuthorizer(this.faturacaoEletronicaDigitalSignConfig.nomeAutorizador, this.ambientePreProducao);
    this._plAlertService.success('faturacaoeletronicaconfiguracoes.messages.successDeleteDigitalSignAuthorizer');
    this._clearFaturacaoEletronicaDigitalSignConfig();
    await this.setTipoCerficadoAtivo(ETipoCertificadoFaturaEletronica.DigitalSign);
    return this._getDigitalSignAuthorizers();
  }

  private _clearFaturacaoEletronicaDigitalSignConfig(): void {
    this.faturacaoEletronicaDigitalSignConfig = {
      nomeAutorizador: '',
      idAutorizador: '',
      nUtilizador: 0,
      nomeUtilizador: '',
      accountExpiration: moment(),
      usoGlobal: true,
      error: false,
      errorDescription: ''
    };
  }

  private _ativar(ativar: boolean, tipoCertificado: ETipoCertificadoFaturaEletronica): Promise<void> {
    if (ativar && !this._validoAtivar(tipoCertificado)) {
      return undefined;
    }

    this._btnGravar.visible = ativar;
    return this._faturacaoEletronicaConfiguracoesService
      .ativarFaturacaoEletronica(ativar)
      .then(() => {
        this.fatEletronicaConfig.faturacaoEletronicaAtiva = ativar;
        if (!ativar) {
          this.fatEletronicaConfig.faturacaoEletronicaEstadoStr = 'faturacaoeletronicaconfiguracoes.text.faturacaoEletronicaNaoAtiva';
        }
      })
      .catch((reason: unknown) => {
        this._logger.error(reason);
      });
  }

  private _validoAtivar(tipo: ETipoCertificadoFaturaEletronica): boolean {
    if (tipo === ETipoCertificadoFaturaEletronica.DigitalSign) {
      if (this.faturacaoEletronicaDigitalSignConfig.nomeAutorizador === '') {
        this._plAlertService.error('faturacaoeletronicaconfiguracoes.digitalsign.errors.nomeAutorizadorNulo');
        return false;
      }

      if (this.faturacaoEletronicaDigitalSignConfig.idAutorizador === '') {
        this._plAlertService.error('faturacaoeletronicaconfiguracoes.digitalsign.errors.idAutorizadorNulo');
        return false;
      }

      if (this.authorizerSecretDigitalSign === '') {
        this._plAlertService.error('faturacaoeletronicaconfiguracoes.digitalsign.errors.secretNula');
        return false;
      }
    } else if (tipo === ETipoCertificadoFaturaEletronica.SAFE) {
      if (this._dataGridInstanceSafeCertificados.totalCount() === 0) {
        this._plAlertService.error('faturacaoeletronicaconfiguracoes.safe.errors.semItems');
        return false;
      }
    }
    return true;
  }

  private async _checkDigitalAuthValid(): Promise<boolean> {
    this._clearFaturacaoEletronicaDigitalSignConfig();
    await this._getDigitalSignAuthorizers();
    return this.hasDigitalSignUser;
  }

  private _activateCertificate(tipo: ETipoCertificadoFaturaEletronica): Promise<void> {
    this._getAmbientePreProducao();
    if (tipo === ETipoCertificadoFaturaEletronica.SAFE) {
      this.navPillPromise = this._getSAFECertificates();
      return this.setTipoCerficadoAtivo(ETipoCertificadoFaturaEletronica.SAFE, true).then(() => undefined);
    }
    this._clearFaturacaoEletronicaDigitalSignConfig();
    this.navPillPromise = this._getDigitalSignAuthorizers();
    return this.setTipoCerficadoAtivo(ETipoCertificadoFaturaEletronica.DigitalSign, true).then(() => undefined);
  }

  private _deactivateCertificate(): Promise<void> {
    return this.setTipoCerficadoAtivo(this.fatEletronicaConfig.tipoCertificado, true).then(() => undefined);
  }
}
