import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {ModuloComponent} from '../../../components/module/module.component';
import {IExtratosClifos, IExtratosClifosGrid} from '../extratosClifos.module.interface';
import {ExtratosClifosService} from '../extratosClifos.module.service';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IPlToolbarItem, isObject} from 'pl-comps-angular';
import {ENTITY_NAME_CLIENTES, ENTITY_NAME_FORNECEDORES} from '../../../entities/clifos/clifos.entity.interface';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import CustomStore from 'devextreme/data/custom_store';
import {devExpressDataGridExpandDetailHandler} from '../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {IJsonDocComercial} from '../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {IDocsComerciaisEntityService} from '../../../entities/docscomerciais/docsComerciais.entity.interface';
import {DocumentosService} from '../../../entities/docscomerciais/service/documentos.entity.service';
import {HttpResponse} from '@angular/common/http';
import {IApiQueryResponse} from '../../../services/api/api.service.interface';

@Component({
  selector: 'extratos-clifo',
  templateUrl: './extratosClifos.module.component.html'
})
export class ExtratosClifosComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public tipo: string;
  public readonly definition: IDevExpressDataGrid;
  public radical: string;

  private readonly _btnProcessar: IPlToolbarItem;
  private readonly _docsComerciaisService: IDocsComerciaisEntityService;
  private _firstRefresh: boolean;
  private _dataGridInstance: dxDataGrid<IExtratosClifos, IExtratosClifos>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _extratosClifosService: ExtratosClifosService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _documentosService: DocumentosService
  ) {
    super(_injector);
    this._firstRefresh = true;
    this._docsComerciaisService = this._entityServiceBuilder.build<IJsonDocComercial, IDocsComerciaisEntityService>('docscomerciais');

    this.definition = {
      columns: [
        {dataField: 'periodo', dataType: 'string', caption: 'extratosClifos.table.fields.periodo'},
        {dataField: 'nDiario', dataType: 'string', caption: 'extratosClifos.table.fields.nDiario'},
        {dataField: 'nDocumento', dataType: 'string', caption: 'extratosClifos.table.fields.nDocumento'},
        {dataField: 'nDocExterno', dataType: 'string', caption: 'extratosClifos.table.fields.nDocExterno'},
        {dataField: 'dataVenc', dataType: 'date', caption: 'extratosClifos.table.fields.dataVenc'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'extratosClifos.table.fields.dataDoc'},
        {dataField: 'descricao', dataType: 'string', caption: 'extratosClifos.table.fields.descricao'},
        {dataField: 'descritivo', dataType: 'string', caption: 'extratosClifos.table.fields.descritivo'},
        {dataField: 'valorDebito', dataType: 'double', caption: 'extratosClifos.table.fields.valorDebito'},
        {dataField: 'valorCredito', dataType: 'double', caption: 'extratosClifos.table.fields.valorCredito'},
        {dataField: 'saldo', dataType: 'double', caption: 'extratosClifos.table.fields.saldo'},
        {dataField: 'porPagar', dataType: 'number', caption: 'extratosClifos.table.fields.porPagar', visible: false},
        {dataField: 'temDocDigital', dataType: 'boolean', caption: 'docscontabilidade.fields.temDocDigital', visible: false}
      ],
      dataSource: new CustomStore({
        load: () => this._extratos('periodo,nDiario,nDocInterno')
      }),
      hoverStateEnabled: true,
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplate'},
      pager: {visible: false},
      paging: {enabled: false},
      remoteOperations: false,
      height: '70vh',
      scrolling: {rowRenderingMode: 'virtual'}
    };

    this._btnProcessar = {
      id: 'processar',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-gear"></i>',
      class: 'btn-success',
      caption: 'extratosClifos.btn.processar',
      click: () => this._dataGridInstance.refresh().then(() => undefined)
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (this.tipo === ENTITY_NAME_CLIENTES) {
      this.radical = this._configService.configurations.clientesFornecedores.radicalClientes;
    } else if (this.tipo === ENTITY_NAME_FORNECEDORES) {
      this.radical = this._configService.configurations.clientesFornecedores.radicalFornecedores;
    }
    this.toolbar.addButton(this._btnProcessar);
  }

  public onInitialized(event: IDevExpressDataGridEventOnInitialized<IExtratosClifos, IExtratosClifos>): void {
    this._dataGridInstance = event.component;
  }

  public onContentReady(): void {
    this._dataGridInstance.columnOption('temDocDigital', {visible: this._configService.configurations.empresa.temContabilidadeDigital});
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IExtratosClifos, IExtratosClifos>): void {
    if (event.row.isExpanded) {
      this._dataGridInstance.collapseAll(-1);
    } else {
      this._dataGridInstance.collapseAll(-1);
      if (!isObject(event.row)) {
        return;
      }
      devExpressDataGridExpandDetailHandler(event, () => this._onDetail(event.data));
    }
  }

  public readonly fnGetPdf = (doc: IJsonDocComercial): Promise<void> => this._getPdf(doc);

  private _extratos(order: string): Promise<Array<IExtratosClifos>> {
    if (this._firstRefresh) {
      this._firstRefresh = false;
      return Promise.resolve([]);
    }
    return new Promise<Array<IExtratosClifos>>((resolve, reject) => {
      this._extratosClifosService
        .extratosClifos(this.radical, order)
        .then((response: HttpResponse<IApiQueryResponse<IExtratosClifos>>) => {
          resolve(response.body.list);
        })
        .catch(reject);
    });
  }

  private _onDetail(extrato: IExtratosClifosGrid): Promise<void> {
    return this._docsComerciaisService.get<IJsonDocComercial>({id: extrato.faccbId}).then((response: HttpResponse<IJsonDocComercial>) => {
      extrato._thedoc = response.body;
    });
  }

  private _getPdf(doc: IJsonDocComercial): Promise<void> {
    return this._documentosService.getPdf(doc, doc.cab.nDocumento === 0 || !doc.cab.terminado);
  }
}
