import {IRadioGroup} from '../../../common/interfaces/interfaces';
import {deepFreeze} from 'pl-comps-angular';
import {IJsonDemFinSNCRubricaMain, IJsonDemFinSNCVarApuramento} from './jsonDemFinSNC.module.interface';

export enum ESNCMicroEntidades {
  SNCNCRF,
  MicroEntidades
}

export interface IDemFinSNCRubricaSaved extends IJsonDemFinSNCRubricaMain {
  ano: number;
  obs: string;
  isProcessaIntercalares: boolean;
  periodoIntercalares: string;
}

export interface IDemFinSNCHeaderProcessed {
  ano: number;
  processaFluxos: boolean;
  verComDados: boolean;
  processaIntercalares: boolean;
  periodoIntercalares: string;
  idioma: number;
  variaveisApuramento: IJsonDemFinSNCVarApuramento;
}

export interface ICaptionDataFieldDemFinSNC {
  rubrica?: string;
  notas?: string;
  capitalRealizado?: string;
  accoesQuotasProprias?: string;
  outrosInstrumentosCapProprio?: string;
  premiosDeEmissao?: string;
  reservasLegais?: string;
  outrasReservas?: string;
  resultadosTransitados?: string;
  ajustamentosEmActivosFinanceirosOutrasVariacoes?: string;
  excedentesDeRevalorizacao?: string;
  resultadoLiquidoDoPeriodo?: string;
  total?: string;
}

export const RADIO_GROUP_SNC_MICRO_ENTIDADES: IRadioGroup<ESNCMicroEntidades> = deepFreeze<IRadioGroup<ESNCMicroEntidades>>({
  groupItems: [
    {value: ESNCMicroEntidades.SNCNCRF, label: 'demfinsnc.radio.sncncrf'},
    {value: ESNCMicroEntidades.MicroEntidades, label: 'demfinsnc.radio.microentidade'}
  ]
});

export const MODULE_NAME_DEM_FIN_SNC = 'demfinsnc';
