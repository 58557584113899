<pl-form (formInstanceChange)="form = $event" [submit]="close" plPromise>
  <div class="modal-header">
    <button type="button" class="movab-header-close-btn btn-close" (click)="dismiss()"></button>

    <pl-toolbar [instanceId]="toolbarInstanceName"></pl-toolbar>

    <keybindings-helper class="ms-auto me-4 fs-6" [actions]="keyboardShortcut"></keybindings-helper>
  </div>

  <div class="modal-body mov-aberto-contab">
    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinition"
      cgDxDataGridInstanceName="movabTable"
      [dataSource]="dataGridDefinition.dataSource"
      [(selectedRowKeys)]="selectedLines"
      (onInitialized)="onInitialized($event)"
      (onContentReady)="onContentReady()"
      (onCellClick)="onCellClick($event)"
      (onEditorPreparing)="onEditorPreparing($event)"
      (onRowUpdated)="onRowUpdated()"
      (onKeyDown)="onKeyDown($event.event)">
      <div *dxTemplate="let item of 'toolbarTemplate'">
        <h6 [translate]="'docscontabilidade.text.conta'" [translateParams]="{nConta: linha.poc.nConta, nome: linha.poc.nome}"></h6>
      </div>

      <div *dxTemplate="let item of 'cellHeaderTemplateBtns'">
        <pl-button klass="btn-xs btn-primary action-imputar-tudo" [attr.title]="'docscontabilidade.movab.imputarTudo' | translate" (evtClicked)="imputarTudo()">
          <i class="fa fa-fw fa-check"></i>
        </pl-button>

        <pl-button klass="btn-xs btn-primary action-limpar-valor" [attr.title]="'docscontabilidade.movab.limparValores' | translate" (evtClicked)="limpaValoresImputados()">
          <i class="fa fa-fw fa-eraser"></i>
        </pl-button>
      </div>

      <div *dxTemplate="let item of 'cellTemplateBtns'">
        <pl-button klass="btn-xs btn-primary action-imputar-tudo-linha" [attr.title]="'docscontabilidade.movab.imputarTudoLinha' | translate" (evtClicked)="imputarTudoLinha(item.data)">
          <i class="fa fa-fw fa-check"></i>
        </pl-button>

        <pl-button klass="btn btn-xs btn-primary action-limpar-valor-linha" [attr.title]="'docscontabilidade.movab.limparValorLinha' | translate" (evtClicked)="limpaLinha(item.data)">
          <i class="fa fa-fw fa-eraser"></i>
        </pl-button>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <div *ngIf="showTableLegend" class="me-auto">
      <cg-table-legend [legend]="tableLegend"></cg-table-legend>
    </div>

    <div class="docscontabilidade-modal-contas-corrente-totais">
      <div class="d-flex align-items-center">
        <div class="ms-auto">
          <div class="table-responsive">
            <table class="table table-sm table-docscontabilidade-modal-contas-corrente-totais">
              <thead>
                <tr class="tr-docscontabilidade-modal-contas-corrente-totais">
                  <th></th>
                  <th class="doccontabilidade-totais-debito text-right" [translate]="'docscontabilidade.doc.linhas.dcDebito'"></th>
                  <th class="doccontabilidade-totais-credito text-right" [translate]="'docscontabilidade.doc.linhas.dcCredito'"></th>
                  <th class="doccontabilidade-totais-saldo text-right" [translate]="'docscontabilidade.doc.totais.saldo'"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="fw-bold" [translate]="'docscontabilidade.movab.totalImputado'"></td>
                  <td class="doccontabilidade-totais-total-debito-total-imputado text-right">{{ totalDebitoImputado | cgcCurrency }}</td>
                  <td class="doccontabilidade-totais-total-credito-total-imputado text-right">{{ totalCreditoImputado | cgcCurrency }}</td>
                  <td class="doccontabilidade-totais-empty text-right">{{ valorTotalImputado | cgcCurrency }}</td>
                </tr>
                <tr>
                  <td class="fw-bold" [translate]="'docscontabilidade.movab.totalConta'"></td>
                  <td class="doccontabilidade-totais-total-debito-total-imputado text-right">{{ totalDebitoConta | cgcCurrency }}</td>
                  <td class="doccontabilidade-totais-total-credito-total-imputado text-right">{{ totalCreditoConta | cgcCurrency }}</td>
                  <td class="doccontabilidade-totais-empty text-right">{{ valorTotalConta | cgcCurrency }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <pl-button type="submit" klass="btn-primary action-save" #elementSubmit><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.apply'"></span></pl-button>

    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</pl-form>
