<div class="modelo22-rosto-quadro14-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'modelo22.modal.rosto.titles.quadro14'"></h5>
  </div>

  <div class="modal-body">
    <pl-form>
      <pl-group>
        <pl-group>
          <label [translate]="'modelo22.rosto.quadro14.table.fields.codpais'"></label>
          <edit>
            <pl-edit type="modelo22paises" attrName="codPais" [(model)]="codPais" plAutoFocus></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label> </label>
          <edit>
            <pl-edit type="checkbox" attrName="paisComCDT" [(model)]="paisComCDT" [properties]="{label: 'modelo22.rosto.quadro14.table.fields.paisComCDT'}"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'modelo22.rosto.quadro14.table.fields.tiprend'"></label>
          <edit>
            <pl-edit type="modelo22tiporend" attrName="tipoRendimentos" [(model)]="tipoRendimentos"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.rosto.quadro14.table.fields.saldnaored'"></label>
          <edit>
            <pl-edit type="number" attrName="saldoNaoDeduzido" [(model)]="saldoNaoDeduzido"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'modelo22.rosto.quadro14.table.fields.imppagestr'"></label>
          <edit>
            <pl-edit type="number" attrName="impostoPago" [(model)]="impostoPago"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'modelo22.rosto.quadro14.table.fields.credpreio'"></label>
          <edit>
            <pl-edit type="number" attrName="creditoDeImposto" [(model)]="creditoDeImposto"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <label [translate]="'modelo22.rosto.quadro14.table.fields.facimprendest'"></label>
        <edit>
          <pl-edit type="number" attrName="fraccaoImposto" [(model)]="fraccaoImposto"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'modelo22.rosto.quadro14.table.fields.dedperio'"></label>
          <edit>
            <pl-edit type="number" attrName="deducaoEfetuada" [(model)]="deducaoEfetuada"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'modelo22.rosto.quadro14.table.fields.saldotrans'"></label>
          <edit>
            <pl-edit type="number" attrName="saldoTransita" [(model)]="saldoTransita"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'modelo22.rosto.quadro14.table.fields.periodo'"></label>
          <edit>
            <pl-edit type="cginteger" attrName="periodo" [(model)]="periodo" [properties]="{validate: false}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'modelo22.rosto.quadro14.table.fields.salcaducado'"></label>
          <edit>
            <pl-edit type="number" attrName="saldoCaducado" [(model)]="saldoCaducado"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span> </pl-button>

    <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
