import {Directive, TemplateRef} from '@angular/core';

@Directive({
  selector: 'ng-template[plCookiesConsentHeader]'
})
export class PlCookiesConsentHeaderDirective {
  constructor(
    public readonly templateRef: TemplateRef<void>
  ) {
  }
}
