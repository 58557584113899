<div class="contabilidade-contabAdvancedOperations entity-detail-form">
  <pl-form>
    <div class="card">
      <div class="card-header fw-bold pesquisa-avançada" (click)="collapsedPesqAvancada = !collapsedPesqAvancada">
        <button type="button" class="btn btn-link" [translate]="'global.text.searchfilter'"></button>
        <i class="fa fa-caret-right" aria-hidden="true" [plAnimationRotate]="!collapsedPesqAvancada"></i>
      </div>
      <pl-animation-collapse [collapsed]="collapsedPesqAvancada">
        <div class="card-body">
          <pl-group *ngIf="tipoOperacao === tiposOperacao.Anulacao">
            <pl-group>
              <label [translate]="'contabAdvancedOperations.fields.dePeriodo'"></label>
              <edit>
                <entity-autocomplete
                  entity="periodos"
                  attrName="dePeriodo"
                  [model]="model"
                  [(selectedKey)]="model.dePeriodo"
                  [fieldsMap]="{periodo: 'dePeriodo'}"
                  [helperMode]="true"
                  output="key"
                  plAutoFocus>
                </entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'contabAdvancedOperations.fields.atePeriodo'"></label>
              <edit>
                <entity-autocomplete entity="periodos" attrName="atePeriodo" [model]="model" [(selectedKey)]="model.atePeriodo" [fieldsMap]="{periodo: 'atePeriodo'}" [helperMode]="true" output="key">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
          <pl-group *ngIf="tipoOperacao !== tiposOperacao.Anulacao">
            <label [translate]="'contabAdvancedOperations.fields.dePeriodo'"></label>
            <edit>
              <entity-autocomplete
                entity="periodos"
                attrName="dePeriodo"
                [model]="model"
                [selectedKey]="model.dePeriodo"
                (selectedKeyChange)="dePeriodoChanged($event)"
                [fieldsMap]="{periodo: 'dePeriodo'}"
                [helperMode]="true"
                output="key"
                plAutoFocus>
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <pl-group>
              <label [translate]="'contabAdvancedOperations.fields.deNDiario'"></label>
              <edit>
                <entity-autocomplete entity="diarios" attrName="diario" [model]="model" [(selectedKey)]="model.deNDiario" [fieldsMap]="{nDiario: 'deNDiario'}" [helperMode]="true" output="key">
                </entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'contabAdvancedOperations.fields.ateNDiario'"></label>
              <edit>
                <entity-autocomplete entity="diarios" attrName="diario" [model]="model" [(selectedKey)]="model.ateNDiario" [fieldsMap]="{nDiario: 'ateNDiario'}" [helperMode]="true" output="key">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <div *ngIf="tipoOperacao !== tiposOperacao.Anulacao" class="card">
            <div class="card-body">
              <pl-group *ngIf="tipoOperacao === tiposOperacao.Periodo">
                <label [translate]="'contabAdvancedOperations.fields.periodoDestino'"></label>
                <edit>
                  <entity-autocomplete
                    entity="periodos"
                    attrName="periodoDestino"
                    [model]="model"
                    [(selectedKey)]="model.periodoDestino"
                    [fieldsMap]="{periodo: 'periodoDestino'}"
                    [helperMode]="true"
                    output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group *ngIf="tipoOperacao === tiposOperacao.Diario">
                <label [translate]="'contabAdvancedOperations.fields.diarioDestino'"></label>
                <edit>
                  <entity-autocomplete
                    entity="diarios"
                    attrName="diarioDestino"
                    [model]="model"
                    [(selectedKey)]="model.diarioDestino"
                    [fieldsMap]="{nDiario: 'diarioDestino'}"
                    [helperMode]="true"
                    output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </div>
          </div>

          <pl-group>
            <pl-group>
              <label [translate]="'contabAdvancedOperations.fields.deNDoc'"></label>
              <edit>
                <pl-edit type="text" [(model)]="model.deNDoc"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'contabAdvancedOperations.fields.ateNDoc'"></label>
              <edit>
                <pl-edit type="text" [(model)]="model.ateNDoc" [properties]="{events: {keydown: fnKeydownToPesquisar}}"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>
        </div>
      </pl-animation-collapse>
    </div>
    <hr />
    <br />
    <div>
      <h5 [translate]="'contabAdvancedOperations.fields.listaDocsPermitidos'"></h5>
      <dx-data-grid
        [cgDxDataGrid]="dataGridDefinitionDocsPermitidos"
        cgDxDataGridInstanceName="contabAdvancedOperationsDocsPermitidos"
        [dataSource]="dataGridDefinitionDocsPermitidos.dataSource"
        [(selectedRowKeys)]="selectedKeys"
        (onInitialized)="onInitialized($event, 'docsPermitidos')"
        (onContentReady)="onContentReady('docsPermitidos')"
        (onRowExpanding)="onRowExpanding($event, 'docsPermitidos')">
        <div *dxTemplate="let item of 'detailTemplateDocsPermitidosDocContabilidade'">
          <documento-contabilidade [(doc)]="item.data._docContabilidade" [maintenanceMode]="true"> </documento-contabilidade>
        </div>
      </dx-data-grid>
    </div>
    <hr />
    <br />
    <div>
      <h5 [translate]="'contabAdvancedOperations.fields.listaDocsNaoPermitidos'"></h5>
      <dx-data-grid
        [cgDxDataGrid]="dataGridDefinitionDocsNaoPermitidos"
        cgDxDataGridInstanceName="contabAdvancedOperationsDocsNaoPermitidos"
        [dataSource]="dataGridDefinitionDocsNaoPermitidos.dataSource"
        (onInitialized)="onInitialized($event, 'docsNaoPermitidos')"
        (onContentReady)="onContentReady('docsNaoPermitidos')"
        (onRowExpanding)="onRowExpanding($event, 'docsNaoPermitidos')">
        <div *dxTemplate="let item of 'detailTemplateDocsNaoPermitidosDocContabilidade'">
          <documento-contabilidade [(doc)]="item.data._docContabilidade" [maintenanceMode]="true"> </documento-contabilidade>
        </div>
      </dx-data-grid>
    </div>
  </pl-form>
</div>
