<config-options [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="instanceName"></config-options>

<div class="entity-detail-form">
  <pl-form>
    <pl-group plAutoFocus>
      <label [translate]="'components.contabilidade.listagens.title'"></label>
      <edit>
        <pl-edit type="reports_movimentosemaberto" attrName="listagem" [(model)]="reportModel"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label>Conta {{ tipo }}:</label>
      <edit>
        <entity-autocomplete entity="pocs" (modelChange)="onCCSelected($event)" [filter]="filtro" [placeholder]="'Pesquisar conta ' + tipo"></entity-autocomplete>
      </edit>
    </pl-group>

    <pl-group *ngIf="optionShowGrupoConta">
      <label [translate]="'movimentosaberto.label.grupoConta'"></label>
      <edit>
        <entity-autocomplete entity="idide" attrName="idIdeChav" [outputKey]="'idIdeChav'" [(selectedKey)]="grupoConta" [output]="'{{idIdeChav}} - {{descricao}}'"></entity-autocomplete>
      </edit>
    </pl-group>
  </pl-form>
</div>

<br />

<cg-pdf-viewer [pdfSrc]="pdfUrl" [toolbarInstanceId]="instanceName" [hideToolbar]="true"></cg-pdf-viewer>
