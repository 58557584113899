import {HttpResponse} from '@angular/common/http';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {isObject, PlAlertService} from 'pl-comps-angular';
import {IJsonBoolean} from '../../../../common/interfaces/json';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {ModuloComponent} from '../../../components/module/module.component';
import {IJsonDocfa} from '../../../entities/docfas/jsonDocFa.entity.interface';
import {IDocsComerciaisGroup} from '../../../entities/docscomerciais/docsComerciais.entity.interface';
import {IApiQueryResponse} from '../../../services/api/api.service.interface';
import {
  EExportMaskNameFilesFields,
  EExportMaskNameFilesType,
  IExportMaskNameFilesTypeField,
  IMascaraNomeFicheiroItem,
  IObjExportMaskNameFiles
} from '../docsComerciaisConfigNomeFileExport.module.interface';
import {DocsComerciaisConfigNomeFileExportService} from '../docsComerciaisConfigNomeFileExport.module.service';
import {IJsonExportMaskNameFilesType, IJsonExportMaskNameFilesTypeField, IJsonMascaraNomeFicheirosExportConfig} from '../jsonDocsComerciaisConfigNomeFileExport.module.interface';

@Component({
  selector: 'module-docsComerciaisConfigNomeFileExport',
  templateUrl: './docsComerciaisConfigNomeFileExport.module.component.html'
})
export class DocsComerciaisConfigNomeExportFileModuleComponent extends ModuloComponent implements OnInit {
  @Input() public exportMaskNameFilesTypeList: Array<IJsonExportMaskNameFilesType>;
  @Input() public dataSourceConfig: Array<IJsonMascaraNomeFicheirosExportConfig>;

  public readonly maskTiposEnum: typeof EExportMaskNameFilesType;
  public readonly maskFieldsEnum: typeof EExportMaskNameFilesFields;
  public readonly outputExportMaskNameFilesTypeList: string;
  public readonly outputExportMaskNameFilesTypeFieldList: string;
  public readonly rowExportMaskNameFilesTypeFieldList: string;

  public dataGridDefinitionMasks: IDevExpressDataGrid<IMascaraNomeFicheiroItem, number>;
  public dataSourceMasks: Array<IMascaraNomeFicheiroItem>;
  public dataGridDefinitionConfig: IDevExpressDataGrid<IJsonMascaraNomeFicheirosExportConfig, number>;
  public model: IObjExportMaskNameFiles;
  public modelDocfa: Partial<IJsonDocfa>;
  public exportMaskNameFilesTypeFieldList: Array<IExportMaskNameFilesTypeField>;
  public previewStr: string;
  public collapsedMasks: boolean;
  public group: IDocsComerciaisGroup;
  public nDocfaFilter: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _docsComerciaisConfigExportService: DocsComerciaisConfigNomeFileExportService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.group = <IDocsComerciaisGroup>this._transition.params().group;
    this.previewStr = '';
    this.maskTiposEnum = EExportMaskNameFilesType;
    this.maskFieldsEnum = EExportMaskNameFilesFields;
    this.collapsedMasks = false;
    this.outputExportMaskNameFilesTypeFieldList = '{{nome}} - {{tag}}';
    this.rowExportMaskNameFilesTypeFieldList = '{{nome}} - {{tagRow}}';
    this.outputExportMaskNameFilesTypeList = '{{descricao}}';
    this.exportMaskNameFilesTypeList = [];
    this.dataSourceConfig = [];

    this.model = {
      tipoConfig: {
        tipo: EExportMaskNameFilesType.None,
        tipoStr: '',
        nDocfa: 0,
        key: '',
        value: '',
        nomeDocfa: ''
      },
      tipo: {
        tipo: 0,
        descricao: ''
      },
      tipoCampo: {
        tipo: 0,
        tipoCampo: 0,
        nome: '',
        tag: ''
      }
    };

    this.modelDocfa = {
      nDocFa: 0,
      descricao: ''
    };

    this.dataSourceMasks = [];
    this.dataGridDefinitionMasks = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'ordem', dataType: 'number', caption: 'configNomeExportFile.grids.ordem', allowEditing: false, allowReordering: false},
        {dataField: 'nome', dataType: 'string', caption: 'configNomeExportFile.grids.tipo', allowEditing: false, allowReordering: false},
        {dataField: 'valor', dataType: 'string', caption: 'configNomeExportFile.grids.valor', allowEditing: false, allowReordering: false},
        {type: 'buttons', cellTemplate: 'actionsTemplate', allowReordering: false}
      ],
      dataSource: this.dataSourceMasks,
      toolbar: {visible: false}
    };

    this.dataSourceConfig = [];
    this.dataGridDefinitionConfig = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'tipoStr', dataType: 'string', caption: 'configNomeExportFile.grids.tipo', allowEditing: false, allowReordering: false},
        {dataField: 'nomeDocfa', dataType: 'string', caption: 'configNomeExportFile.grids.doc', allowEditing: false, allowReordering: false},
        {dataField: 'value', dataType: 'string', caption: 'configNomeExportFile.grids.valor', allowEditing: false, allowReordering: false},
        {type: 'buttons', cellTemplate: 'actionsTemplate', allowReordering: false}
      ],
      dataSource: this.dataSourceConfig,
      toolbar: {visible: false}
    };

    this.adicionarMascara = this.adicionarMascara.bind(this);
    this.editarConfig = this.editarConfig.bind(this);
    this.save = this.save.bind(this);
    this.deleteConfig = this.deleteConfig.bind(this);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.collapsedMasks = this.dataSourceConfig.length > 0;
    if (this.group) {
      this.nDocfaFilter = this.group.grupo ? `grupoDocfa=${this.group.grupo}` : undefined;
    }
  }

  public exportMaskNameFilesTypeChanged(exportMaskNameFilesType: IJsonExportMaskNameFilesType): void {
    this.dataSourceMasks = [];
    this.exportMaskNameFilesTypeFieldList = [];
    this.previewStr = '';
    this.modelDocfa = {...this.modelDocfa, nDocFa: 0, nome: ''};
    if (isObject(exportMaskNameFilesType)) {
      this.model.tipo = exportMaskNameFilesType;
      this._docsComerciaisConfigExportService.getTiposCampos(exportMaskNameFilesType.tipo).then((response: HttpResponse<IApiQueryResponse<IJsonExportMaskNameFilesTypeField>>) => {
        if (response.body) {
          this._applyTipoCampoSource(response.body.list);
          if (exportMaskNameFilesType.tipo !== this.maskTiposEnum.Faturacao) {
            for (const item of this.dataSourceConfig) {
              if (item.tipo === exportMaskNameFilesType.tipo) {
                this._preencheItemsByMascara(item.value);
                break;
              }
            }
          }
        }
      });
    }
  }

  public exportMaskNameFilesTypeFieldChanged(value: IExportMaskNameFilesTypeField): void {
    if (isObject(value)) {
      this.model.tipoConfig.value = '';
      this.model.tipoCampo = value;
    } else {
      this.model.tipoCampo = {
        tipoCampo: this.maskFieldsEnum.None,
        nome: '',
        tag: '',
        tipo: this.model.tipo.tipo
      };
    }
  }

  public nDocfaChanged(nDocfa: IJsonDocfa): void {
    this.dataSourceMasks = [];
    this.previewStr = '';
    if (isObject(nDocfa)) {
      this.modelDocfa = nDocfa;
      this.model.tipoConfig.nDocfa = nDocfa.nDocFa;
      this.model.tipoConfig.nomeDocfa = nDocfa.nome;
      if (this.model.tipo.tipo === EExportMaskNameFilesType.Faturacao) {
        for (const item of this.dataSourceConfig) {
          if (item.nDocfa === this.model.tipoConfig.nDocfa) {
            this._preencheItemsByMascara(item.value);
            break;
          }
        }
      }
    } else {
      this.model.tipoConfig.nDocfa = 0;
      this.modelDocfa = {...this.modelDocfa, nDocFa: 0, nome: ''};
    }
  }

  public toggleCollapse(): void {
    this.collapsedMasks = !this.collapsedMasks;
  }

  public adicionarMascara(): Promise<void> {
    return this._validaTypeField().then(() => {
      this.dataSourceMasks.push({
        ...this.model.tipoCampo,
        ordem: this.dataSourceMasks.length,
        valor: this.model.tipoCampo.tipoCampo === EExportMaskNameFilesFields.CaracterFixo ? `<"${this.model.tipoConfig.value}">` : this.model.tipoCampo.tag
      });

      this._updateMask();
    });
  }

  public deleteMascara(index: number): void {
    if (index > -1) {
      this.dataSourceMasks.splice(index, 1);
      this._updateMask();
    }
  }

  public save(): Promise<void> {
    if (!this.dataSourceMasks.length) {
      this._plAlertService.error('configNomeExportFile.messages.semConfiguracao');
      return Promise.reject();
    }

    if (this.model.tipoConfig.tipo === EExportMaskNameFilesType.Faturacao && this.model.tipoConfig.nDocfa === 0) {
      this._plAlertService.error('configNomeExportFile.messages.erroNDocFa');
      return Promise.reject();
    }

    let maskStr = '';
    for (const item of this.dataSourceMasks) {
      maskStr += item.valor;
    }

    const config: IJsonMascaraNomeFicheirosExportConfig = {
      tipo: this.model.tipo.tipo,
      tipoStr: this.model.tipo.tipo === EExportMaskNameFilesType.Faturacao ? `${this.model.tipo.descricao}_${this.model.tipoConfig.nDocfa}` : this.model.tipo.descricao,
      nDocfa: this.model.tipo.tipo !== EExportMaskNameFilesType.Faturacao ? 0 : this.model.tipoConfig.nDocfa,
      nomeDocfa: this.model.tipo.tipo !== EExportMaskNameFilesType.Faturacao ? '' : this.model.tipoConfig.nomeDocfa,
      key: this.model.tipoConfig.tipoStr,
      value: maskStr
    };

    for (const [index, item] of this.dataSourceConfig.entries()) {
      if (item.tipo === config.tipo) {
        if (item.tipo === EExportMaskNameFilesType.Faturacao && item.nDocfa !== config.nDocfa) {
          continue;
        }
        return this._docsComerciaisConfigExportService.update(config.tipoStr, config).then((response: HttpResponse<IJsonMascaraNomeFicheirosExportConfig>) => {
          if (response.body) {
            this.dataSourceConfig[index] = response.body;
          }
        });
      }
    }

    return this._docsComerciaisConfigExportService.save(config).then((response: HttpResponse<IJsonMascaraNomeFicheirosExportConfig>) => {
      if (response.body) {
        this.dataSourceConfig.push(response.body);
      }
    });
  }

  public editarConfig(item: IJsonMascaraNomeFicheirosExportConfig): void {
    this.model.tipo = {
      tipo: item.tipo,
      descricao: item.tipo === EExportMaskNameFilesType.Faturacao ? item.tipoStr.split('_')[0] : item.tipoStr
    };
    this.model.tipoConfig = {...this.model.tipoConfig, nDocfa: item.nDocfa, nomeDocfa: item.nomeDocfa};
    this.modelDocfa = {...this.modelDocfa, nDocFa: item.nDocfa, nome: item.nomeDocfa};

    this._docsComerciaisConfigExportService.getTiposCampos(this.model.tipo.tipo).then((response: HttpResponse<IApiQueryResponse<IJsonExportMaskNameFilesTypeField>>) => {
      if (response.body) {
        this._applyTipoCampoSource(response.body.list);
        this._preencheItemsByMascara(item.value);
      }
    });
    if (this.collapsedMasks) {
      this.collapsedMasks = false;
    }
  }

  public deleteConfig(index: number): Promise<void> {
    return this._cgModalService
      .showOkCancel(
        this._translateService.instant('configNomeExportFile.apagarModal.title'),
        this._translateService.instant('configNomeExportFile.apagarModal.message', {configuracao: this.dataSourceConfig[index].tipoStr})
      )
      .then(() => {
        return this._docsComerciaisConfigExportService.delete(this.dataSourceConfig[index].key).then((response: HttpResponse<void>) => {
          if (response.ok) {
            this.dataSourceConfig.splice(index, 1);
            this._plAlertService.success('configNomeExportFile.messages.deleteSucesso');
          }
        });
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  private _validaTypeField(): Promise<void> {
    if (this.model.tipo.tipo === EExportMaskNameFilesType.Faturacao && this.model.tipoConfig.nDocfa === 0) {
      this._plAlertService.error('configNomeExportFile.messages.erroNDocFa');
      return Promise.reject();
    }

    if (this.model.tipoCampo.tipoCampo === this.maskFieldsEnum.None) {
      this._plAlertService.error('configNomeExportFile.messages.erroFieldType');
      return Promise.reject();
    }

    if (this.model.tipoCampo.tipoCampo === EExportMaskNameFilesFields.CaracterFixo) {
      if (!this.model.tipoConfig.value) {
        this._plAlertService.error('configNomeExportFile.messages.erroValorNulo');
        return Promise.reject();
      }
      return this._docsComerciaisConfigExportService.getCaracteresValidos(this.model.tipoConfig.value).then((response: HttpResponse<IJsonBoolean>) => {
        if (!response.body.value) {
          this._plAlertService.error('configNomeExportFile.messages.erroValorInvalido');
          return Promise.reject();
        }
        return Promise.resolve();
      });
    }
    return Promise.resolve();
  }

  private _preencheItemsByMascara(maskConfig: string): void {
    if (maskConfig) {
      this.dataSourceMasks = [];

      const itemsConfig: Array<string> = maskConfig.split('>');
      for (const item of itemsConfig) {
        if (!item) {
          continue;
        }
        const mask = `${item}>`;
        const maskItem: IMascaraNomeFicheiroItem = {
          ordem: this.dataSourceMasks.length,
          tipo: this.model.tipoConfig.tipo,
          tipoCampo: 0,
          tag: '',
          nome: '',
          valor: mask
        };

        const idxCampo: number = mask.startsWith('<"') ? this._findCampoByTag() : this._findCampoByTag(mask);

        maskItem.tipoCampo = this.exportMaskNameFilesTypeFieldList[idxCampo].tipoCampo;
        maskItem.tag = this.exportMaskNameFilesTypeFieldList[idxCampo].tag;
        maskItem.nome = this.exportMaskNameFilesTypeFieldList[idxCampo].nome;

        this.dataSourceMasks.push(maskItem);
      }
      this._updateMask();
    }
  }

  private _updateMask(): void {
    this.previewStr = '';
    for (const item of this.dataSourceMasks) {
      this.previewStr += item.valor;
    }
  }

  private _findCampoByTag(tag?: string): number {
    if (!tag) {
      for (const [index, item] of this.exportMaskNameFilesTypeFieldList.entries()) {
        if (item.tipoCampo === this.maskFieldsEnum.CaracterFixo) {
          return index;
        }
      }
    }
    for (const [index, item] of this.exportMaskNameFilesTypeFieldList.entries()) {
      if (item.tag === tag) {
        return index;
      }
    }
    return -1;
  }

  private _applyTipoCampoSource(list: Array<IJsonExportMaskNameFilesTypeField>): void {
    this.exportMaskNameFilesTypeFieldList = list.map((item: IJsonExportMaskNameFilesTypeField): IExportMaskNameFilesTypeField => {
      let cut = item.tag.replace('<', '< ');
      cut = cut.replace('>', ' >');
      return {...item, tagRow: cut};
    });
  }
}
