<div class="ativosdivisao-modal">
  <pl-form>
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'ativosdivisao.title.divisao'"></h5>
    </div>

    <div class="modal-body entity-detail-form">
      <pl-alert type="info" [closeable]="false">
        <div [innerHTML]="info | translate"></div>
      </pl-alert>

      <section class="d-flex flex-row">
        <section class="flex-column w-50">
          <div>
            <label class="me-2" [translate]="'ativosdivisao.header.unidades'"></label>
            <span>{{ ativo.aquisicao.quantidade }}</span>
          </div>
        </section>

        <section class="flex-column w-50">
          <div>
            <label class="me-2" [translate]="'ativosdivisao.header.valoraquisicao'"></label>
            <span>{{ ativo.valorAquisicaoContab }}</span>
          </div>
          <div>
            <label class="me-2" [translate]="'ativosdivisao.header.depacumu'"></label>
            <span>{{ ativo.valorDepreciacaoContab }}</span>
          </div>
          <div>
            <label class="me-2" [translate]="'ativosdivisao.header.valorliquido'"></label>
            <span>{{ valorLiquidoContab }}</span>
          </div>
        </section>
      </section>

      <hr />

      <pl-group>
        <label [translate]="'ativosdivisao.fields.datadivisao'"></label>
        <edit>
          <pl-edit type="date" attrName="dataDivisao" [(model)]="ativosDivisao.dataDivisao" plAutoFocus></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'ativosdivisao.fields.designacao'"></label>
        <edit>
          <pl-edit type="text" attrName="designacao" [(model)]="ativosDivisao.designacao"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'ativosdivisao.fields.unidades'"></label>
          <edit>
            <pl-edit type="number" attrName="unidades" [model]="ativosDivisao.unidadesQtd" (modelChange)="unidadesQtdChanged($event)"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'ativosdivisao.fields.valoraquisicao'"></label>
          <edit>
            <pl-edit type="number" attrName="valorAquisicao" [model]="ativosDivisao.valorAquisicao" (modelChange)="valorAquisicaoChanged($event)"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button klass="btn-success action-close" [onClick]="close" data-focus> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span> </pl-button>

      <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
    </div>
  </pl-form>
</div>
