import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonClasseContrato} from '../../../classescontratos/jsonClasseContrato.entity.interface';
import {IJsonContratoTipo} from '../../../contratostipos/jsonContratoTipo.entity.interface';
import {isEmpty, isObject, isString, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {HttpResponse} from '@angular/common/http';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {ENTITY_NAME_CLASSES_CONTRATOS_AVENCAS} from '../../../classescontratos/classesContratos.entity.interface';

import {ENTITY_NAME_CONTRATOS_TIPO_AVENCAS} from '../../../contratostipoavencas/contratosTipoAvencas.entity.interface';

@Component({
  selector: 'avencas-selecionar-tipo-modal',
  templateUrl: './avencas.selecionar.tipo.modal.component.html'
})
export class AvencasSelecionarTipoModalComponent extends CGModalComponent<string> implements OnInit {
  @Input() public nClasseCtr: string;
  @Input() public nTipoContrato: string;

  public classes: IJsonClasseContrato;
  public tipos: IJsonContratoTipo;
  public temParamClasseContrato: boolean;
  public filterTipoContrato: string;

  private readonly _serviceClassesContrato: IEntityService<IJsonClasseContrato>;
  private readonly _serviceTipoContratoAvenca: IEntityService<IJsonContratoTipo>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);

    this._serviceClassesContrato = this._entityServiceBuilder.build<IJsonClasseContrato, IEntityService<IJsonClasseContrato>>(ENTITY_NAME_CLASSES_CONTRATOS_AVENCAS);
    this._serviceTipoContratoAvenca = this._entityServiceBuilder.build<IJsonContratoTipo, IEntityService<IJsonContratoTipo>>(ENTITY_NAME_CONTRATOS_TIPO_AVENCAS);
  }

  public ngOnInit(): void {
    this.temParamClasseContrato = this.nClasseCtr === '';
    if (this.temParamClasseContrato) {
      this._serviceClassesContrato.get({id: this.nClasseCtr, reportExceptions: false}).then((response: HttpResponse<IJsonClasseContrato>) => {
        if (response) {
          this.classes = response.body;
        } else {
          this.nClasseCtr = '';
          this.temParamClasseContrato = false;
        }
      });
    }
  }

  public close(): void {
    if (!this.nClasseCtr) {
      this._plAlertService.error(this._plTranslateService.translate('Erro'));
      return;
    }

    if (!this.nTipoContrato) {
      this._plAlertService.error(this._plTranslateService.translate('Erro'));
      return;
    }

    const classe = this.nClasseCtr;
    const tipo = this.nTipoContrato;
    const id = `${classe}_${tipo}`;
    super.close(id);
  }

  public onClasseContratoChanged(classecontratoavenca: IJsonClasseContrato): void {
    this.classes = classecontratoavenca;
    this.nClasseCtr = classecontratoavenca.nClasseCtr;
    this.filterTipoContrato = classecontratoavenca ? `nClasseCtr=${classecontratoavenca.nClasseCtr}&classetipo=${classecontratoavenca.classeTipo}` : '';
  }

  public onContratoTipoAvenca(item: string | IJsonContratoTipo): void {
    if (isEmpty(item)) {
      return;
    }

    if (isObject(item)) {
      this.tipos = <IJsonContratoTipo>item;
      this.nTipoContrato = this.tipos.nTipoContrato;
      return;
    }

    if (isString(item)) {
      this.nTipoContrato = item;
    }
  }

  public readonly fnOnTipoContratoValidate = (id: string, item: string | IJsonContratoTipo): Promise<IJsonContratoTipo> => this._onTipoContratoValidate(id, item);

  private _onTipoContratoValidate(id: string, item: string | IJsonContratoTipo): Promise<IJsonContratoTipo> {
    if (isEmpty(item)) {
      return Promise.resolve(undefined);
    }
    id = isObject(item) ? (<IJsonContratoTipo>item).id : isString(item) ? `${this.nClasseCtr}_${item}` : `${this.nClasseCtr}_${id}`;
    return this._serviceTipoContratoAvenca.get({id: id, reportExceptions: false}).then((response: HttpResponse<IJsonContratoTipo>) => {
      this.tipos = response.body;
      return response.body;
    });
  }
}
