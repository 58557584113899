import {Injectable} from '@angular/core';
import {
  IJsonRHEstatisticasFilters,
  IJsonRHEstatisticasMapaAnual,
  IJsonRHEstatisticasSegSocialResumoItem,
  IJsonRHEstatisticasSeguroResumoItem,
  IJsonRHEstatisticasSindicatoItem,
  IRHEstatisticasFolhaSalarioResumoItem,
  IRHEstatisticasMapaATItem,
  MODULE_NAME_RH_ESTATISTICAS
} from './rhEstatisticas.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {IJsonDGEMP} from '../../../entities/dgemps/jsonDGEMP.entity.interface';
import {ApiService} from '../../../services/api/api.service';
import {IJsonDepto} from '../../../entities/depto/jsonDepto.entity.interface';
import {IJsonCCusto} from '../../../entities/ccustos/jsonCCusto.entity.interface';
import {IJsonRHEstatisticasMultiItem, IJsonRHEstatisticasUserConfig} from '../rhestatisticasMulti/rhEstatisticasMulti.module.interface';

@Injectable({
  providedIn: 'root'
})
export class RhEstatisticasModuleService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/${MODULE_NAME_RH_ESTATISTICAS}`;
  }

  public getEmpregados(nEmpresa: string, codEmpDe: number, codEmpAte: number): TServiceResponse<Array<IJsonDGEMP>> {
    return this._apiService.get<Array<IJsonDGEMP>>({
      url: `${this._path}/empregados`,
      params: {
        codEmpDe: codEmpDe,
        codEmpAte: codEmpAte,
        nEmpresa: nEmpresa
      }
    });
  }

  public getDepartamentos(nEmpresa: string, deptoDe: number, deptoAte: number): TServiceResponse<Array<IJsonDepto>> {
    return this._apiService.get<Array<IJsonDepto>>({
      url: `${this._path}/departamentos`,
      params: {
        deptoDe: deptoDe,
        deptoAte: deptoAte,
        nEmpresa: nEmpresa
      }
    });
  }

  public getCCustos(nEmpresa: string, ccustoDe: string, ccustoAte: string): TServiceResponse<Array<IJsonCCusto>> {
    return this._apiService.get<Array<IJsonCCusto>>({
      url: `${this._path}/ccustos`,
      params: {
        ccustoDe: ccustoDe,
        ccustoAte: ccustoAte,
        nEmpresa: nEmpresa
      }
    });
  }

  public getFolhaSalarios(nEmpresa: string, filters: IJsonRHEstatisticasFilters, includeDetails: boolean): TServiceResponse<Array<IRHEstatisticasFolhaSalarioResumoItem>> {
    return this._apiService.post<Array<IRHEstatisticasFolhaSalarioResumoItem>, IJsonRHEstatisticasFilters>({
      url: `${this._path}/folhaSalarios`,
      params: {includeDetails: includeDetails, nEmpresa: nEmpresa},
      body: filters
    });
  }

  public getSegSocial(nEmpresa: string, filters: IJsonRHEstatisticasFilters): TServiceResponse<Array<IJsonRHEstatisticasSegSocialResumoItem>> {
    return this._apiService.post<Array<IJsonRHEstatisticasSegSocialResumoItem>, IJsonRHEstatisticasFilters>({
      url: `${this._path}/segSocial`,
      params: {nEmpresa: nEmpresa},
      body: filters
    });
  }

  public getSindicato(nEmpresa: string, filters: IJsonRHEstatisticasFilters): TServiceResponse<Array<IJsonRHEstatisticasSindicatoItem>> {
    return this._apiService.post<Array<IJsonRHEstatisticasSindicatoItem>, IJsonRHEstatisticasFilters>({
      url: `${this._path}/sindicato`,
      params: {nEmpresa: nEmpresa},
      body: filters
    });
  }

  public getSeguro(nEmpresa: string, filters: IJsonRHEstatisticasFilters): TServiceResponse<Array<IJsonRHEstatisticasSeguroResumoItem>> {
    return this._apiService.post<Array<IJsonRHEstatisticasSeguroResumoItem>, IJsonRHEstatisticasFilters>({
      url: `${this._path}/seguro`,
      params: {nEmpresa: nEmpresa},
      body: filters
    });
  }

  public getMapaAnual(nEmpresa: string, filters: IJsonRHEstatisticasFilters): TServiceResponse<IJsonRHEstatisticasMapaAnual> {
    return this._apiService.post<IJsonRHEstatisticasMapaAnual, IJsonRHEstatisticasFilters>({
      url: `${this._path}/mapaAnual`,
      params: {nEmpresa: nEmpresa},
      body: filters
    });
  }

  public getDataMultiEmpresas(ano: number, mes: number): TServiceResponse<Array<IJsonRHEstatisticasMultiItem>> {
    return this._apiService.get<Array<IJsonRHEstatisticasMultiItem>>({
      url: `${this._path}/dataMultiEmpresas`,
      params: {ano: ano, mes: mes}
    });
  }

  public getMultiConfig(): TServiceResponse<IJsonRHEstatisticasUserConfig> {
    return this._apiService.get<IJsonRHEstatisticasUserConfig>({
      url: `${this._path}/multiConfig`
    });
  }

  public saveMultiConfig(config: IJsonRHEstatisticasUserConfig): TServiceResponse<void> {
    return this._apiService.post<void, IJsonRHEstatisticasUserConfig>({
      url: `${this._path}/multiConfig`,
      body: config
    });
  }

  public getMapaAT(nEmpresa: string, filters: IJsonRHEstatisticasFilters): TServiceResponse<Array<IRHEstatisticasMapaATItem>> {
    return this._apiService.post<Array<IRHEstatisticasMapaATItem>, IJsonRHEstatisticasFilters>({
      url: `${this._path}/mapaAT`,
      params: {nEmpresa: nEmpresa},
      body: filters
    });
  }
}
