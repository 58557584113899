import {Component, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EDelphiNumberTypes, IPlToolbarItem, PlAlertService} from 'pl-comps-angular';
import {ModuloComponent} from '../../../../components/module/module.component';
import moment from 'moment';
import {
  CONTA_CLIENTES_MAX,
  CONTA_CLIENTES_MIN,
  CONTA_FORNECEDORES_MAX,
  CONTA_FORNECEDORES_MIN,
  EDocsComercisaisDocsGridLegend,
  EModuleTipo,
  IDocComercialEstatisticaDocItem,
  IDocsComerciasEstatisticasDocFilters,
  MAX_NCCUSTO,
  MAX_STRING
} from '../docsComerciaisEstatisticas.module.interface';
import {IJsonDocfa} from '../../../../entities/docfas/jsonDocFa.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {ENTITY_NAME_DOC_FAS, IDocFasEntityService} from '../../../../entities/docfas/docFas.entity.interface';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {DocsComerciaisEstatisticasDocPrintModalComponent} from './modals/docsComerciaisEstatisticasDoc.print.modal';
import {EConfigOptionsInstanceName, IDocsComerciaisEstatisticasDocConfigOptions, TConfigOptions} from '../../../../services/config/options/config.options.service.interface';
import {Subscription} from 'rxjs';
import {EGroupName} from '../../../../../config/constants';
import {DocsComerciaisEstatisticasDocService} from '../docsComerciaisEstatisticasDoc.module.service';
import {EGrupoDoc} from '../../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressPivotGridEventOnExporting
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressPivotGrid} from '../../../../components/devexpress/pivotgrid/devexpress.pivotgrid.interface';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import {devExpressGenerateFormatDouble} from '../../../../components/devexpress/widget/devexpress.widget.utilities';
import {DocumentosService} from '../../../../entities/docscomerciais/service/documentos.entity.service';
import {IJsonDocComercial} from '../../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {IDocComercialCabWithData} from '../../../../entities/docscomerciais/docsComerciais.entity.interface';
import {HttpResponse} from '@angular/common/http';
import {IApiQueryResponse} from '../../../../services/api/api.service.interface';
import {IJsonGrandeFamilia} from '../../../../entities/grandesfamilias/jsonGrandeFamilia.entity.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonFamilia} from '../../../../entities/familias/jsonFamilia.entity.interface';
import {ENTITY_NAME_FAMILIAS} from '../../../../entities/familias/familias.entity.interface';
import {IJsonSubFamilia} from '../../../../entities/subfamilias/jsonSubFamilia.entity.interface';
import {IJsonTipoArtigo} from '../../../../entities/tiposartigo/jsonTipoArtigo.entity.interface';
import {ENTITY_NAME_SUB_FAMILIAS} from '../../../../entities/subfamilias/subFamilias.entity.interface';
import {ENTITY_NAME_GRANDES_FAMILIAS} from '../../../../entities/grandesfamilias/grandesFamilias.entity.interface';
import {ENTITY_NAME_TIPOS_ARTIGO} from '../../../../entities/tiposartigo/tiposArtigo.entity.interface';
import {devExpressPivotGridExportToExcel} from '../../../../components/devexpress/pivotgrid/export/devexpress.pivotgrid.export';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';

@Component({
  selector: 'module-docscomerciais-estatisticasdoc',
  templateUrl: './docsComerciaisEstatisticasDoc.module.component.html'
})
export class DocsComerciaisEstatisticasDocComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public moduleType: EModuleTipo;

  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly pivotGridDefinition: IDevExpressPivotGrid;
  public readonly docFaTemplate: string;
  public readonly docFaOutput: string;

  public pivotGridDataSource: PivotGridDataSource;
  public filters: IDocsComerciasEstatisticasDocFilters;
  public showMoreFilters: boolean;
  public docFaSource: Array<IJsonDocfa>;
  public docfalist: Array<number>;
  public optionShowFilterConta: boolean;
  public optionShowFilterVendedor: boolean;
  public optionShowFilterZona: boolean;
  public optionShowFilterCentroCusto: boolean;
  public optionShowFilterProcesso: boolean;
  public optionShowFilterDocfaList: boolean;
  public optionShowFilterNArtigo: boolean;
  public optionShowFilterTipoArtigo: boolean;
  public optionShowFilterGrandeFamilia: boolean;
  public optionShowFilterFamilia: boolean;
  public optionShowFilterSubFamilia: boolean;
  public optionShowFilterGrupoConta: boolean;
  public optionShowFilterClasse: boolean;
  public nContaFilter: string;
  public qtdDocsPesquisa: number;
  public promise: Promise<void>;
  public tipoArtigoSource: Array<IJsonTipoArtigo>;
  public tipoArtigoList: Array<number>;
  public tipoArtigoOutput: string;
  public tipoArtigoTemplate: string;
  public grandeFamiliaSource: Array<IJsonGrandeFamilia>;
  public grandeFamiliaList: Array<number>;
  public grandeFamiliaOutput: string;
  public grandeFamiliaTemplate: string;
  public familiaSource: Array<IJsonFamilia>;
  public familiaList: Array<number>;
  public familiaOutput: string;
  public familiaTemplate: string;
  public subFamiliaSource: Array<IJsonSubFamilia>;
  public subFamiliaList: Array<number>;
  public subFamiliaOutput: string;
  public subFamiliaTemplate: string;

  private readonly _btnPrintToolbar: IPlToolbarItem;
  private readonly _docFaService: IDocFasEntityService;
  private readonly _tipoArtigoService: IEntityService<IJsonTipoArtigo>;
  private readonly _grandeFamiliaService: IEntityService<IJsonGrandeFamilia>;
  private readonly _familiaService: IEntityService<IJsonFamilia>;
  private readonly _subFamiliaService: IEntityService<IJsonSubFamilia>;

  private _firstLoad: boolean;
  private _docsComerciaisEstatisticasDocOptions: TConfigOptions<boolean, IDocsComerciaisEstatisticasDocConfigOptions>;
  private _subscription: Subscription;
  private _dataGridInstance: dxDataGrid;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _docsComerciasEstatisticasService: DocsComerciaisEstatisticasDocService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _cgModalService: CGModalService,
    private readonly _documentosService: DocumentosService
  ) {
    super(_injector);
    this.configOptionsInstanceName = EConfigOptionsInstanceName.DOCS_COMERCIAIS_ESTATISTICAS_DOC;
    this.configOptionsGroupName = EGroupName.ERP;
    this.docFaTemplate = '{{nDocFa}} - {{nome}}';
    this.docFaOutput = 'nDocFa';
    this.docFaSource = [];
    this.docfalist = [];
    this._docFaService = this._entityServiceBuilder.build(ENTITY_NAME_DOC_FAS);
    this._tipoArtigoService = this._entityServiceBuilder.build<IJsonTipoArtigo>(ENTITY_NAME_TIPOS_ARTIGO);
    this.tipoArtigoSource = [];
    this.tipoArtigoList = [];
    this.tipoArtigoOutput = 'nome';
    this.tipoArtigoTemplate = '{{codTpArt}} - {{nome}}';
    this._grandeFamiliaService = this._entityServiceBuilder.build<IJsonGrandeFamilia>(ENTITY_NAME_GRANDES_FAMILIAS);
    this.grandeFamiliaSource = [];
    this.grandeFamiliaList = [];
    this.grandeFamiliaOutput = 'nome';
    this.grandeFamiliaTemplate = '{{nDepart}} - {{nome}}';
    this._familiaService = this._entityServiceBuilder.build<IJsonFamilia>(ENTITY_NAME_FAMILIAS);
    this.familiaSource = [];
    this.familiaList = [];
    this.familiaOutput = 'nome';
    this.familiaTemplate = '{{nFamilia}} - {{nome}}';
    this._subFamiliaService = this._entityServiceBuilder.build<IJsonSubFamilia>(ENTITY_NAME_SUB_FAMILIAS);
    this.subFamiliaSource = [];
    this.subFamiliaList = [];
    this.subFamiliaOutput = 'nome';
    this.subFamiliaTemplate = '{{nSubFa}} - {{nome}}';
    this._firstLoad = true;
    this.qtdDocsPesquisa = 0;
    this.dataGridDefinition = {
      columns: [
        {dataField: 'nDocStr', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.nDocStr'},
        {dataField: 'docfaNome', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.docfaNome', visible: false},
        {dataField: 'nnumer', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.nnumer', visible: false},
        {dataField: 'nConta', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.nConta'},
        {dataField: 'contaNome', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.contaNome'},
        {dataField: 'nif', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.nif', visible: false},
        {dataField: 'dataDoc', dataType: 'date', caption: 'docsComerciaisEstatisticas.fields.dataDoc'},
        {dataField: 'nFactFornec', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.nFactFornec', visible: false},
        {dataField: 'dataDocExterno', dataType: 'date', caption: 'docsComerciaisEstatisticas.fields.dataDocExterno', visible: false},
        {dataField: 'totalDocumento', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.totalDocumento', visible: false},
        {dataField: 'totalLiquido', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.totalLiquido', visible: false},
        {dataField: 'totalIva', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.totalIva', visible: false},
        {dataField: 'totalDesconto', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.totalDesconto', visible: false},
        {dataField: 'taxaRet', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.taxaRet', visible: false},
        {dataField: 'retencaoIncidencia', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.retencaoIncidencia', visible: false},
        {dataField: 'retencaoValor', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.retencaoValor', visible: false},
        {dataField: 'nRefProcesso', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.nRefProcesso', visible: false},
        {dataField: 'nomeProcesso', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.nomeProcesso', visible: false},
        {dataField: 'totalLiquidoMovimento', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.totalLiquidoMovimento'},
        {dataField: 'totalIvaMovimento', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.totalIvaMovimento'},
        {dataField: 'totalDocumentoMovimento', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.totalDocumentoMovimento'},
        {dataField: 'totalDescontoMovimento', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.totalDescontoMovimento'},
        {dataField: 'valorPorPagar', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.valorEmAberto', visible: false},
        {dataField: 'estado', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.estado', visible: false},
        {dataField: 'sectorId', dataType: 'number', caption: 'docsComerciaisEstatisticas.fields.sectorId', visible: false},
        {dataField: 'nomeSector', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.nomeSector', visible: false}
      ],
      dataSource: [],
      export: {filename: 'docscomerciais.estatisticas.documentos'},
      remoteOperations: false,
      columnHidingEnabled: false,
      pager: {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        allowedPageSizes: [10],
        displayMode: 'full',
        visible: true
      },
      summary: {
        totalItems: [
          {column: 'totalLiquidoMovimento', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'totalIvaMovimento', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'totalDocumentoMovimento', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'}
        ]
      },
      masterDetail: {enabled: true, template: 'detailTemplateDocumento'}
    };

    const storageKeyStr = this.moduleType === EModuleTipo.VENDAS ? 'vol-negocio-venda-doc-pivotgrid-storing' : 'vol-negocio-compra-doc-pivotgrid-storing';

    this.pivotGridDefinition = {
      allowSortingBySummary: true,
      allowFiltering: true,
      showBorders: true,
      showRowGrandTotals: true,
      showRowTotals: false,
      showColumnGrandTotals: true,
      showColumnTotals: false,
      fieldChooser: {
        enabled: true,
        allowSearch: true,
        layout: 1
      },
      export: {enabled: true},
      scrolling: {
        mode: 'virtual'
      },
      stateStoring: {
        enabled: true,
        type: 'localStorage',
        storageKey: storageKeyStr
      },
      texts: {
        grandTotal: 'Total'
      }
    };
    this.filters = {
      ncontade: '',
      ncontaate: '',
      datade: moment().subtract(1, 'months').startOf('month'),
      dataate: moment().subtract(1, 'months').endOf('month'),
      nvendedorde: 0,
      nvendedorate: EDelphiNumberTypes.MaxSmallInt,
      codzonade: 0,
      codzonaate: EDelphiNumberTypes.MaxSmallInt,
      ccustode: '',
      ccustoate: MAX_NCCUSTO,
      nrefprocessode: '',
      nrefprocessoate: MAX_STRING,
      docfalist: '',
      resumoiva: false,
      nartigo: '',
      ididechavde: '',
      ididechavate: MAX_STRING,
      cliclde: '',
      cliclate: MAX_STRING
    };
    this._btnPrintToolbar = {
      id: 'docsComerciaisEstatisticas-print-toolbar',
      order: 1,
      type: 'button',
      class: 'btn-primary',
      iconLeft: '<i class="fa fa-fw fa-print"></i>',
      caption: 'global.btn.print',
      click: () => this._printModal()
    };
    this.showMoreFilters = false;
    this.fnPesquisar = this.fnPesquisar.bind(this);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.filters.ncontade = this._getNContaMinMaxConst('MIN');
    this.filters.ncontaate = this._getNContaMinMaxConst('MAX');

    this.toolbar.addButton(this._btnPrintToolbar);
    this.nContaFilter = this.moduleType !== EModuleTipo.TODOS ? `nConta=%${this._getNContaMinMaxConst('MIN')}%` : '';

    this._docFaService.query({pesquisa: this._getGrupoDocfa()}).then((response) => {
      this.docFaSource = response.body.list;
      this.docfalist = this.docFaSource
        .filter((item) => {
          return this.moduleType !== EModuleTipo.TODOS
            ? this.moduleType === EModuleTipo.VENDAS
              ? item.grupoDocfa === EGrupoDoc.VendasEfectivas
              : item.grupoDocfa === EGrupoDoc.ComprasEfectivas
            : true;
        })
        .map((item) => item.nDocFa);
    });

    this._tipoArtigoService.query().then((response: HttpResponse<IApiQueryResponse<IJsonTipoArtigo>>) => {
      if (response) {
        this.tipoArtigoSource = response.body.list;
      }
    });

    this._grandeFamiliaService.query().then((response: HttpResponse<IApiQueryResponse<IJsonGrandeFamilia>>) => {
      if (response) {
        this.grandeFamiliaSource = response.body.list;
      }
    });

    this._familiaService.query().then((response: HttpResponse<IApiQueryResponse<IJsonFamilia>>) => {
      if (response) {
        this.familiaSource = response.body.list;
      }
    });

    this._subFamiliaService.query().then((response: HttpResponse<IApiQueryResponse<IJsonSubFamilia>>) => {
      if (response) {
        this.subFamiliaSource = response.body.list;
      }
    });

    this._subscription = this._docsComerciasEstatisticasService.getOptions().subscribe((value: TConfigOptions<boolean, IDocsComerciaisEstatisticasDocConfigOptions>) => {
      this._docsComerciaisEstatisticasDocOptions = value;
      this.optionShowFilterConta = this._docsComerciaisEstatisticasDocOptions.get('showFilterConta').value;
      this.optionShowFilterVendedor = this._docsComerciaisEstatisticasDocOptions.get('showFilterVendedor').value;
      this.optionShowFilterZona = this._docsComerciaisEstatisticasDocOptions.get('showFilterZona').value;
      this.optionShowFilterCentroCusto = this._docsComerciaisEstatisticasDocOptions.get('showFilterCentroCusto').value;
      this.optionShowFilterProcesso = this._docsComerciaisEstatisticasDocOptions.get('showFilterProcesso').value;
      this.optionShowFilterDocfaList = this._docsComerciaisEstatisticasDocOptions.get('showFilterDocfaList').value;
      this.optionShowFilterNArtigo = this._docsComerciaisEstatisticasDocOptions.get('showFilterNArtigo').value;
      this.optionShowFilterTipoArtigo = this._docsComerciaisEstatisticasDocOptions.get('showFilterTipoArtigo').value;
      this.optionShowFilterGrandeFamilia = this._docsComerciaisEstatisticasDocOptions.get('showFilterGrandeFamilia').value;
      this.optionShowFilterFamilia = this._docsComerciaisEstatisticasDocOptions.get('showFilterFamilia').value;
      this.optionShowFilterSubFamilia = this._docsComerciaisEstatisticasDocOptions.get('showFilterSubFamilia').value;
      this.optionShowFilterGrupoConta = this._docsComerciaisEstatisticasDocOptions.get('showFilterGrupoConta').value;
      this.optionShowFilterClasse = this._docsComerciaisEstatisticasDocOptions.get('showFilterClasse').value;
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  public nvendedordeChanged(value: number): void {
    this.filters.nvendedorde = !value || value.toString() === '0' ? 0 : value;
    this.filters.nvendedorate = !value || value.toString() === '0' ? EDelphiNumberTypes.MaxSmallInt : value;
  }

  public nvendedorateChanged(value: number): void {
    this.filters.nvendedorate = !value ? EDelphiNumberTypes.MaxSmallInt : value;
  }

  public codzonadeChanged(value: number): void {
    this.filters.codzonade = !value || value.toString() === '0' ? 0 : value;
    this.filters.codzonaate = !value || value.toString() === '0' ? EDelphiNumberTypes.MaxSmallInt : value;
  }

  public codzonaateChanged(value: number): void {
    this.filters.codzonaate = !value ? EDelphiNumberTypes.MaxSmallInt : value;
  }

  public ccustodeChanged(value: string): void {
    this.filters.ccustode = !value || value === '' ? '' : value;
    this.filters.ccustoate = !value || value === '' ? MAX_NCCUSTO : value;
  }

  public ccustoateChanged(value: string): void {
    this.filters.ccustoate = !value ? MAX_NCCUSTO : value;
  }

  public nrefprocessodeChanged(value: string): void {
    this.filters.nrefprocessode = !value || value === '' ? '' : value;
    this.filters.nrefprocessoate = !value || value === '' ? MAX_STRING : value;
  }

  public nrefprocessoateChanged(value: string): void {
    this.filters.nrefprocessoate = !value ? MAX_STRING : value;
  }

  public idIdeChavDeChanged(value: string): void {
    this.filters.ididechavde = !value || value === '' ? '' : value;
    this.filters.ididechavate = !value || value === '' ? MAX_STRING : value;
  }

  public idIdeChavAteChanged(value: string): void {
    this.filters.ididechavate = !value ? MAX_STRING : value;
  }

  public cliclDeChanged(value: string): void {
    this.filters.cliclde = !value || value === '' ? '' : value;
    this.filters.cliclate = !value || value === '' ? MAX_STRING : value;
  }

  public cliclAteChanged(value: string): void {
    this.filters.cliclate = !value ? MAX_STRING : value;
  }

  public nartigoChanged(value: string): void {
    this.filters.nartigo = !value || value === '' ? '' : value;
  }

  public fnPesquisar(): Promise<void> {
    this._firstLoad = false;
    this.qtdDocsPesquisa = 0;
    return this._getTableSource();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IDocComercialEstatisticaDocItem, number>): void {
    if (event.rowType === 'data' && event.column.dataField === 'valorPorPagar') {
      if (event.cellElement.classList.contains(EDocsComercisaisDocsGridLegend.DocsComerciaisDocsPago)) {
        event.cellElement.classList.remove(EDocsComercisaisDocsGridLegend.DocsComerciaisDocsPago);
      }
      if (event.cellElement.classList.contains(EDocsComercisaisDocsGridLegend.DocsComerciaisDocsPorPagar)) {
        event.cellElement.classList.remove(EDocsComercisaisDocsGridLegend.DocsComerciaisDocsPorPagar);
      }

      if (event.data.valorPorPagar === 0) {
        event.cellElement.classList.add(EDocsComercisaisDocsGridLegend.DocsComerciaisDocsPago);
      } else {
        event.cellElement.classList.add(EDocsComercisaisDocsGridLegend.DocsComerciaisDocsPorPagar);
      }
    }
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IDocComercialCabWithData>): void {
    devExpressDataGridExpandDetailHandler(event, () => {
      const {data} = event;
      this._getDoc(event.data.faccbId).then((response) => {
        if (response) {
          data._doc = response;
        }
      });
    });
  }

  public onExporting(event: IDevExpressPivotGridEventOnExporting): void {
    devExpressPivotGridExportToExcel(event, this._translateService);
  }

  public readonly fnGetDoc: (id: number) => Promise<IJsonDocComercial> = (id: number) => this._getDoc(id);
  public readonly fnGetPdf = (doc: IJsonDocComercial): Promise<void> => this._getPdf(doc);
  public readonly fnAnexarGDocComercial = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this.anexarGDocComercial(doc);

  public anexarGDocComercial(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.anexarGDocComercial(doc);
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _getTableSource(): Promise<void> {
    this.promise = null;

    if (this._firstLoad) {
      return Promise.resolve();
    }
    if (this.docfalist.length === 0) {
      this._plAlertService.error('docsComerciaisEstatisticas.errors.naoExisteTipoDocSelecionadoCheckOpcoes');
      return Promise.resolve();
    }
    const filters: IDocsComerciasEstatisticasDocFilters = {
      ...this.filters,
      docfalist: this.docfalist.join(','),
      tipoartigolist: this.tipoArtigoList.join(','),
      grandefamilialist: this.grandeFamiliaList.join(','),
      familialist: this.familiaList.join(','),
      subfalist: this.subFamiliaList.join(',')
    };
    this.promise = this._docsComerciasEstatisticasService.getEstatisticasDocs(filters, this.moduleType).then((response: IApiQueryResponse<IDocComercialEstatisticaDocItem>) => {
      if (response) {
        if (response.list.length > 0) {
          this.qtdDocsPesquisa = response.total;
          this._cardPanel.collapse();
        }
        for (const item of response.list) {
          if (item.valorPorPagar === 0) {
            item.estado = this._translateService.instant('docsComerciaisEstatisticas.info.docPago');
          } else if (item.valorPorPagar === item.totalDocumento) {
            item.estado = this._translateService.instant('docsComerciaisEstatisticas.info.docPorPagar');
          } else if (item.valorPorPagar > 0 && item.valorPorPagar < item.totalDocumento) {
            item.estado = this._translateService.instant('docsComerciaisEstatisticas.info.docPagoParcial');
          }
        }
        this.dataGridDefinition.dataSource = response.list;
        this._applyPivotGrid(response.list);
      } else {
        this.dataGridDefinition.dataSource = [];
        this._applyPivotGrid([]);
      }
    });
    return this.promise;
  }

  private _printModal(): Promise<void> {
    const filters: IDocsComerciasEstatisticasDocFilters = {
      ...this.filters,
      docfalist: this.docfalist.join(','),
      tipoartigolist: this.tipoArtigoList.join(','),
      grandefamilialist: this.grandeFamiliaList.join(','),
      familialist: this.familiaList.join(','),
      subfalist: this.subFamiliaList.join(',')
    };
    const modalInstance = this._cgModalService.showVanilla(DocsComerciaisEstatisticasDocPrintModalComponent, {size: 'xl'});
    const componentInstance: DocsComerciaisEstatisticasDocPrintModalComponent = modalInstance.componentInstance;
    componentInstance.filters = filters;
    componentInstance.moduleType = this.moduleType;
    return modalInstance.result;
  }

  private _getNContaMinMaxConst(c: 'MIN' | 'MAX'): string {
    return c === 'MIN'
      ? this.moduleType === EModuleTipo.VENDAS || this.moduleType === EModuleTipo.TODOS
        ? CONTA_CLIENTES_MIN
        : CONTA_FORNECEDORES_MIN
      : this.moduleType === EModuleTipo.COMPRAS || this.moduleType === EModuleTipo.TODOS
      ? CONTA_FORNECEDORES_MAX
      : CONTA_CLIENTES_MAX;
  }

  private _getGrupoDocfa(): string {
    const filters: Array<string> = [];
    if (this.moduleType === EModuleTipo.VENDAS) {
      filters.push(`grupoDocfa=${EGrupoDoc.VendasEfectivas}`);
      filters.push(`grupoDocfa=${EGrupoDoc.EncomendasClientes}`);
      filters.push(`grupoDocfa=${EGrupoDoc.PropostasAClientes}`);
      filters.push(`grupoDocfa=${EGrupoDoc.GuiasTransporteRemessa}`);
    } else if (this.moduleType === EModuleTipo.COMPRAS) {
      filters.push(`grupoDocfa=${EGrupoDoc.ComprasEfectivas}`);
      filters.push(`grupoDocfa=${EGrupoDoc.EncomendasFornecedores}`);
      filters.push(`grupoDocfa=${EGrupoDoc.GuiasTransporteFornecedores}`);
    }
    return filters.join('|');
  }

  private _applyPivotGrid(store: Array<IDocComercialEstatisticaDocItem>): void {
    const format: string = devExpressGenerateFormatDouble(2);
    this.pivotGridDataSource = new PivotGridDataSource({
      fields: [
        {
          dataField: 'nConta',
          area: 'row',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.nConta'),
          width: 100,
          expanded: true
        },
        {
          dataField: 'contaNome',
          area: 'row',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.contaNome'),
          width: 200
        },
        {
          dataField: 'docfaNome',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.dataDoc'),
          area: 'column',
          expanded: true
        },
        {
          dataField: 'totalLiquido',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.totalLiquido')
        },
        {
          dataField: 'dataDoc',
          dataType: 'date',
          format: 'shortDate',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.dataDoc')
        },
        {
          dataField: 'totalIva',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.totalIva')
        },
        {
          dataField: 'nDocStr',
          dataType: 'string',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.nDocStr')
        },
        {
          dataField: 'nFactFornec',
          dataType: 'string',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.nFactFornec')
        },
        {
          dataField: 'dataDocExterno',
          dataType: 'date',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.dataDocExterno')
        },
        {
          dataField: 'totalDocumento',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.totalDocumento')
        },
        {
          dataField: 'taxaRet',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.taxaRet')
        },
        {
          dataField: 'retencaoIncidencia',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.retencaoIncidencia')
        },
        {
          dataField: 'retencaoValor',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.retencaoValor')
        },
        {
          dataField: 'nCCusto',
          dataType: 'string',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.nCCusto')
        },
        {
          dataField: 'centroCusto',
          dataType: 'string',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.centroCusto')
        },
        {
          dataField: 'nRefProcesso',
          dataType: 'string',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.nRefProcesso')
        },
        {
          dataField: 'nomeProcesso',
          dataType: 'string',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.nomeProcesso')
        },
        {
          dataField: 'totalDocumentoMovimento',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.totalDocumentoMovimento')
        },
        {
          dataField: 'totalLiquidoMovimento',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.totalLiquidoMovimento'),
          area: 'data'
        },
        {
          dataField: 'totalIvaMovimento',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.totalIvaMovimento')
        },
        {
          dataField: 'totalDescontoMovimento',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.totalDescontoMovimento')
        }
      ],
      store: store,
      retrieveFields: false
    });
  }

  private _getDoc(id: number): Promise<IJsonDocComercial> {
    return this._documentosService.getDoc(id).then((response: HttpResponse<IJsonDocComercial>) => response.body);
  }

  private _getPdf(doc: IJsonDocComercial): Promise<void> {
    return this._documentosService.getPdf(doc);
  }
}
