import type {AbstractControl} from '@angular/forms';
import type {IPlEditBaseComponent} from '../../component/edit.component.interface';
import type {IPlEditGroup, IPlEditGroupOptions} from './edit.group.interface';
import {TOrientation} from '../../../common/interface';

export abstract class PlEditGroupToken implements IPlEditGroup {
  public attrName: string;
  public labelClass: string;
  public editClass: string;
  public actionsClass: string;
  public subGroupClass: string;
  public orientation: TOrientation;
  public hasError: boolean;
  public properties: IPlEditGroupOptions | any;
  public options: IPlEditGroupOptions;

  public abstract addEdit(plEdit: IPlEditBaseComponent<any>): void;

  public abstract removeEdit(name: string): void;

  public abstract updateGroup(properties: Partial<IPlEditGroupOptions>): void;

  public abstract valueChanged(value: unknown, field: string): void;

  public abstract addControl(name: string, control: AbstractControl): void;

  public abstract removeControl(name: string): void;

  public abstract setHasError(hasError: boolean): void;

  public abstract evaluateKlass(): void;
}
