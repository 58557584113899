import {Component, Injector, Input, OnInit} from '@angular/core';
import {IPlNavPillEventSelected, PlAlertService} from 'pl-comps-angular';
import {ModuloComponent} from '../../../../components/module/module.component';
import {IJsonAtivosGeraMapasAuto} from '../jsonAtivosGeraMapasAuto.module.interface';
import {AtivosGeraMapasAutoService} from '../ativosGeraMapasAuto.module.service';
import {HttpResponse} from '@angular/common/http';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IGDocsCallback} from '../../../../components/gdocs/gdocs.component.interface';
import {EFolderDoc} from '../../../../entities/gdoc/gdoc.entity.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {EDocTipoEntity} from '../../../../entities/gdoc/JsonGDoc.entity.interface';

@Component({
  selector: 'module-ativos-gera-mapas-auto',
  templateUrl: './ativosGeraMapasAuto.module.component.html'
})
export class AtivosGeraMapasAutoModuleComponent extends ModuloComponent implements OnInit {
  @Input() public ativosGeraMapas: Array<IJsonAtivosGeraMapasAuto>;
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly folderId: string;
  public readonly callbackGDocs: IGDocsCallback;
  public readonly errorFolderMessage: string;

  public tipoDocEntity: typeof EDocTipoEntity;
  public selectedGeraMapa: IJsonAtivosGeraMapasAuto;
  public navPillActiveId: string;
  public promise: Promise<void>;

  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosGeraMapasAutoService: AtivosGeraMapasAutoService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'codAtivo', dataType: 'number', caption: 'ativosgeramapasauto.tableDefinition.codAtivo'},
        {dataField: 'designacao', dataType: 'string', caption: 'ativosgeramapasauto.tableDefinition.designacao', width: 150},
        {dataField: 'estadoStr', dataType: 'string', caption: 'ativosgeramapasauto.tableDefinition.estadoStr'},
        {dataField: 'dataAquisicao', dataType: 'date', caption: 'ativosgeramapasauto.tableDefinition.dataAquisicao'},
        {dataField: 'dataAbateVenda', dataType: 'date', caption: 'ativosgeramapasauto.tableDefinition.dataAbateVenda'},
        {dataField: 'nMapaAmortizacao', dataType: 'string', caption: 'ativosgeramapasauto.tableDefinition.nMapaAmortizacao'},
        {dataField: 'naturezaDosAtivosStr', dataType: 'string', caption: 'ativosgeramapasauto.tableDefinition.naturezaDosAtivosStr', width: 150}
      ],
      dataSource: [],
      export: {filename: 'global.menu.ativosgeramapasauto'},
      keyExpr: 'codAtivo',
      remoteOperations: false
    };
    this.folderId = EFolderDoc.DefaultFolderIMBEMMapas;
    this.callbackGDocs = {};
    this.errorFolderMessage = 'ativosgeramapasauto.message.errorFolderMessage';
    this.selectedGeraMapa = {mapa: '', bensNormais: []};
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'pesquisar',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-search fa-fw"></i>',
      class: 'btn-success',
      caption: 'ativosgeramapasauto.btn.pesquisar',
      click: () => this._pesquisaBens()
    });
    this.toolbar.addButton({
      id: 'geraativos',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-search fa-fw"></i>',
      class: 'btn-primary',
      caption: 'ativosgeramapasauto.btn.geraativos',
      click: () => this._generateMaps()
    });
    if (this.ativosGeraMapas.length) {
      this.selectedGeraMapa = this.ativosGeraMapas[0];
      this.navPillActiveId = this.selectedGeraMapa.mapa;
      this._evaluateDxDataGridData();
    }
    this.tipoDocEntity = EDocTipoEntity;
  }

  public changedNavPill(event: IPlNavPillEventSelected): void {
    if (event.activeId !== event.nextId) {
      this.selectedGeraMapa = this.ativosGeraMapas.find((geraMapa: IJsonAtivosGeraMapasAuto) => geraMapa.mapa === event.nextId);
      this.navPillActiveId = this.selectedGeraMapa.mapa;
      this._evaluateDxDataGridData();
    }
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  private _pesquisaBens(): Promise<void> {
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    this.promise = this._ativosGeraMapasAutoService
      .getBens()
      .then((response: HttpResponse<Array<IJsonAtivosGeraMapasAuto>>) => {
        this.ativosGeraMapas = response.body;
        this.selectedGeraMapa = undefined;
        if (this.ativosGeraMapas.length) {
          this.selectedGeraMapa = this.ativosGeraMapas[0];
          this.navPillActiveId = this.selectedGeraMapa.mapa;
          this._evaluateDxDataGridData();
        }
        this._plAlertService.success('ativosgeramapasauto.message.benspesquisados');
      })
      .finally(() => {
        if (this._dataGridInstance) {
          this._dataGridInstance.endCustomLoading();
        }
      });
    return this.promise;
  }

  private async _generateMaps(): Promise<void> {
    const response: HttpResponse<boolean> = await this._ativosGeraMapasAutoService.getExistGeneratedMaps();
    if (response.body) {
      await this._cgModalService.showOkCancel('ativosgeramapasauto.modal.jaexistemmapas', 'ativosgeramapasauto.modal.jaexistemmapastext');
    }
    await this._gerarMapasAutomaticos();
  }

  private async _gerarMapasAutomaticos(): Promise<void> {
    await this._ativosGeraMapasAutoService.gerarMapasAutomaticos();
    this._plAlertService.success('ativosgeramapasauto.message.mapasgerados');
    await this.callbackGDocs.refresh();
  }

  private _evaluateDxDataGridData(): void {
    this.dataGridDefinition.dataSource = this.selectedGeraMapa.bensNormais;
  }
}
