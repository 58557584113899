<div class="apuramentoresultados">
  <div class="d-flex flex-wrap">
    <div class="order-lg-2">
      <label [translate]="'apuramentoresultados.anoapuramento'" [translateParams]="{ano: anoCursoIRC}"></label>
    </div>
    <div class="me-auto order-lg-1">
      <pl-group>
        <label [translate]="'apuramentoresultados.tipoinvpermanente'"></label>
        <edit>
          <pl-edit
            type="tipoInventario"
            attrName="tipoinvpermanente"
            [model]="apuramento.tipoinventario"
            (modelChange)="tipoInventarioChanged($event)"
            [properties]="{allowEmpty: false, disallowClear: true}">
          </pl-edit>
        </edit>
      </pl-group>
    </div>
  </div>

  <pl-nav-wizard
    [(model)]="selectedStep"
    [definition]="definitionNavWizard"
    [callback]="callbackNavWizard"
    [beforeStepChange]="fnBeforeChangedStep"
    [onFinalize]="fnFinalize"
    [properties]="stepOptions">
    <pl-nav-wizard-step [stepId]="steps.Variables" caption="apuramentoresultados.steps.variables" icon="fa-list-alt">
      <dx-data-grid
        *plNavWizardStepContent
        [cgDxDataGrid]="definitionVariaveisApuramento"
        cgDxDataGridInstanceName="variaveisApuramento"
        [dataSource]="definitionVariaveisApuramento.dataSource"
        (onCellClick)="onDataGridVariaveisApuramentoCellClick($event)">
        <div *dxTemplate="let item of 'detail'; let itemIndex = index">
          <dx-data-grid [cgDxDataGrid]="definitionVariaveisApuramentoDetail" [dataSource]="item.data.valoresInventario" (onRowUpdated)="changeValorParentRow(item.data)"> </dx-data-grid>
        </div>
      </dx-data-grid>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step [stepId]="steps.Documents" caption="apuramentoresultados.steps.documents" icon="fa-search">
      <div *plNavWizardStepContent class="entity-detail-form">
        <pl-form [properties]="{validators: {required: {value: true}}}">
          <div class="row">
            <pl-group class="col-sm-3">
              <label [translate]="'docscontabilidade.fields.nDiario'"></label>
              <edit>
                <entity-autocomplete
                  entity="diarios"
                  attrName="diario"
                  [model]="apuramento"
                  (selectedKeyChange)="apuramento.ndiario = $event"
                  (evtSelectedDescriptionChanged)="apuramento.nomeDiario = $event"
                  [fieldsMap]="{nDiario: 'ndiario', nome: 'nomeDiario'}"
                  plAutoFocus>
                </entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="row">
            <pl-group class="col-sm-3">
              <label [translate]="'apuramentoresultados.fields.descritivo'"></label>
              <edit>
                <entity-autocomplete
                  entity="descritivos"
                  attrName="descritivo"
                  [model]="apuramento"
                  (selectedKeyChange)="apuramento.descritivo = $event"
                  (evtSelectedDescriptionChanged)="apuramento.nomeDescritivo = $event"
                  [fieldsMap]="{nDescrit: 'descritivo', nome: 'nomeDescritivo'}">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="row">
            <pl-group class="col-sm-3">
              <label [translate]="'apuramentoresultados.fields.dataDoc'"></label>
              <edit>
                <pl-edit type="date" attrName="dataDoc" [(model)]="apuramento.datadoc"></pl-edit>
              </edit>
            </pl-group>
          </div>

          <pl-group>
            <label [translate]="'apuramentoresultados.fields.description'"></label>
            <edit>
              <pl-edit type="text" attrName="descricao" [(model)]="apuramento.descricao"></pl-edit>
            </edit>
          </pl-group>
        </pl-form>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step [stepId]="steps.Preview" caption="apuramentoresultados.steps.preview" icon="fa-file">
      <dx-data-grid *plNavWizardStepContent [cgDxDataGrid]="definitionPreVisualDocumentos" cgDxDataGridInstanceName="preVisualDocumentos" [dataSource]="valores"> </dx-data-grid>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step [stepId]="steps.Finished" caption="apuramentoresultados.steps.finished" icon="fa-window-close" [properties]="{footerOffset: ''}">
      <div *plNavWizardStepContent class="row">
        <div class="col-sm-12">
          <h5 class="mb-2">
            <strong [translate]="'apuramentoresultados.titles.generatedDocs'"></strong>
          </h5>
          <ul>
            <li *ngFor="let doc of generatedDocs">
              <a (click)="goToDoc(doc.extPocCabID)">{{ doc.caption }}</a>
            </li>
          </ul>
        </div>
      </div>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
