import moment from 'moment';
import {orderBy} from 'lodash-es';
import {firstValueFrom} from 'rxjs';
import type dxDataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import {Component, Injector, OnInit, TemplateRef} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {copy, downloadStream, EDateMonth, IPlToolbarItem, IPlTooltipConfig, isNumber, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ConfigLoginWsSSModalComponent} from '../../../segsocial/modals/loginWsSS.modal.component';
import {DATA_SOURCE_MESES} from '../../../../datasources/meses/meses.datasource';
import {EProceSegSocialViewInfoServiceType, ProceSegSocialViewInfoComponent} from '../../../colaboradores/procesegsocial/modals/viewinfo/proceSegSocial.viewInfo.modal.component';
import {ESSEstadoFicheiro, IRHProcSegSocialConsultEnvios, IRHProcSegSocialConsultEnviosParams} from '../rhProcCegCocialConsultEnvios.module.interface';
import {IDataSourceItem} from '../../../../components/datasource/datasources.interface';
import {IJsonRHProcSegSocialConsultEnvios, IJsonRHProcSSConsultEnvComSemErro, IJsonString} from '../jsonRHProcCegCocialConsultEnvios.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {RhProcSegSocialConsultEnviosService} from '../rhProcCegCocialConsultEnvios.module.service';
import {ConsultEnviosImpResumoModalComponent} from '../modals/impresumo/consultEnvios.impResumo.modal.component';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {Writeable} from '../../../../../common/interfaces/interfaces';

const SUBSTRACT_YEARS = 10;

@Component({
  selector: 'module-rhprocsegsocialconsultenvios',
  templateUrl: './rhProcCegCocialConsultEnvios.module.component.html'
})
export class RHProcSegSocialConsultEnviosModuleComponent extends ModuloComponent implements OnInit {
  public readonly dataGridDefinition: IDevExpressDataGrid<IRHProcSegSocialConsultEnvios, string>;
  public readonly tooltipProcFicheir: IPlTooltipConfig;
  public readonly tooltipConfig: IPlTooltipConfig;
  public readonly estadoFicheiro: typeof ESSEstadoFicheiro;
  public readonly yearsSource: Array<number>;
  public readonly mesesSource: Array<Writeable<IDataSourceItem<EDateMonth>>>;
  public selectedRowKeys: Array<string>;
  public promise: Promise<void>;
  public year: number;
  public month: number;
  public havefichSel: boolean;

  private readonly _btnGerarFicheiro: IPlToolbarItem;
  private readonly _btnReprocessarSegSocial: IPlToolbarItem;
  private readonly _btnEnviarWebService: IPlToolbarItem;
  private readonly _btnImpResumo: IPlToolbarItem;
  private readonly _btnGerarEstorno: IPlToolbarItem;
  private readonly _btnApagarEstorno: IPlToolbarItem;
  private readonly _params: IRHProcSegSocialConsultEnviosParams;

  private _dataGridInstance: dxDataGrid<IRHProcSegSocialConsultEnvios, string>;
  private _rhprocSegSocialConsultEnviosList: Array<IRHProcSegSocialConsultEnvios>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhprocSegSocialConsultEnviosService: RhProcSegSocialConsultEnviosService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._params = <IRHProcSegSocialConsultEnviosParams>this._transition.params();
    this.estadoFicheiro = ESSEstadoFicheiro;
    this.yearsSource = [];
    const minYear = moment().subtract(SUBSTRACT_YEARS, 'years').year();
    const maxYear = moment().add(1, 'years').year();
    for (let i = minYear; i <= maxYear; i++) {
      this.yearsSource.push(i);
    }
    this.mesesSource = copy(<Array<Writeable<IDataSourceItem<EDateMonth>>>>DATA_SOURCE_MESES.data);
    for (const mes of this.mesesSource) {
      mes.name = this._translateService.instant(mes.name);
    }
    this._rhprocSegSocialConsultEnviosList = [];
    this.tooltipProcFicheir = {
      placement: ['top', 'left'],
      container: 'body',
      autoClose: 'outside',
      triggers: 'manual',
      disabled: false
    };
    this.tooltipConfig = {text: 'rhprocsegsocialconsultenvios.tooltip.configWsSS', placement: 'top', container: 'body'};
    this._btnGerarFicheiro = {
      id: 'gerarficheiro',
      order: 3,
      type: 'button',
      iconLeft: '<i class="fa fa-bolt fa-fw"></i>',
      class: 'btn-light',
      caption: 'rhprocsegsocialconsultenvios.btn.gerarficheiro',
      disabled: true,
      tooltip: {
        text: 'rhprocsegsocialconsultenvios.tooltip.naotemlinhasselecionadas',
        placement: 'bottom-right',
        container: 'body',
        disabled: false
      },
      click: () => this._gerarFicheiro()
    };
    this._btnReprocessarSegSocial = {
      id: 'reprocessarsegsocial',
      order: 4,
      type: 'button',
      iconLeft: '<i class="fa fa-bolt fa-fw"></i>',
      class: 'btn-light',
      caption: 'rhprocsegsocialconsultenvios.btn.reprocessarSegSocial',
      disabled: true,
      tooltip: {
        text: 'rhprocsegsocialconsultenvios.tooltip.naotemlinhasselecionadas',
        placement: 'bottom-right',
        container: 'body',
        disabled: false
      },
      click: () => this._reprocessarSegSocial()
    };
    this._btnEnviarWebService = {
      id: 'enviarwebservice',
      order: 5,
      type: 'button',
      iconLeft: '<i class="fa fa-upload fa-fw"></i>',
      class: 'btn-light',
      caption: 'rhprocsegsocialconsultenvios.btn.reenviarwebservice',
      disabled: true,
      tooltip: {
        text: 'rhprocsegsocialconsultenvios.tooltip.naotemlinhasselecionadas',
        placement: 'bottom-right',
        container: 'body',
        disabled: false
      },
      click: () => this._enviarWebservice()
    };
    this._btnImpResumo = {
      id: 'impresumo',
      order: 6,
      type: 'button',
      iconLeft: '<i class="fa fa-file-pdf-o fa-fw"></i>',
      class: 'btn-light',
      caption: 'rhprocsegsocialconsultenvios.btn.impresumo',
      disabled: true,
      tooltip: {
        text: 'rhprocsegsocialconsultenvios.tooltip.naotemlinhasselecionadas',
        placement: 'bottom-right',
        container: 'body',
        disabled: false
      },
      click: () => {
        this._impResumo();
      }
    };
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {
          caption: 'rhprocsegsocialconsultenvios.table.dadosEmpresa',
          fixed: true,
          fixedPosition: 'left',
          columns: [
            {dataField: 'nEmpresa', dataType: 'string', caption: 'rhprocsegsocialconsultenvios.table.empresa'},
            {dataField: 'nomeEmpresa', dataType: 'string', caption: 'rhprocsegsocialconsultenvios.table.nomeempresa', width: 200}
          ]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.dados',
          columns: [
            {dataField: 'ssMes', dataType: 'number', caption: 'rhprocsegsocialconsultenvios.table.mes'},
            {dataField: 'ssAno', dataType: 'number', caption: 'rhprocsegsocialconsultenvios.table.ano'},
            {dataField: 'totalRemuneracoes', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.totalRemuneracoes', format: {suppressZeros: true}, width: 150},
            {dataField: 'totalContribuicoes', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.totalContribuicoes', format: {suppressZeros: true}, width: 150},
            {dataField: 'totalVencRemun', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.totalVencRemun', format: {suppressZeros: true}, width: 150},
            {caption: 'rhprocsegsocialconsultenvios.table.procficheiro', cellTemplate: 'cellTemplateProcFicheiro', alignment: 'center'},
            {caption: 'rhprocsegsocialconsultenvios.table.info', cellTemplate: 'cellTemplateInfo', alignment: 'center'},
            {caption: 'rhprocsegsocialconsultenvios.table.erros', cellTemplate: 'cellTemplateErros', alignment: 'center'}
          ]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.infoficheiro',
          columns: [
            {dataField: 'efetuadoReproceMes', dataType: 'boolean', caption: 'rhprocsegsocialconsultenvios.table.reprocessado'},
            {dataField: 'ficheiroGeradoMes', dataType: 'boolean', caption: 'rhprocsegsocialconsultenvios.table.gerado'},
            {dataField: 'descricaoErro', dataType: 'string', caption: 'rhprocsegsocialconsultenvios.table.infoerros'}
          ]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.infowebservice',
          columns: [
            {caption: 'rhprocsegsocialconsultenvios.table.config', cellTemplate: 'cellTemplateConfig', alignment: 'center'},
            {dataField: 'estadoFicheiroSTR', dataType: 'string', caption: 'rhprocsegsocialconsultenvios.table.estado', width: 150},
            {dataField: 'infoErroAlertaFich', dataType: 'string', caption: 'rhprocsegsocialconsultenvios.table.alertaserros', width: 200},
            {dataField: 'dataEntrega', dataType: 'date', caption: 'rhprocsegsocialconsultenvios.table.dataentrega'},
            {dataField: 'dataLimiteSubstituir', dataType: 'date', caption: 'rhprocsegsocialconsultenvios.table.datalimitesub'},
            {dataField: 'nomeFicheiro', dataType: 'string', caption: 'rhprocsegsocialconsultenvios.table.nome'},
            {caption: 'rhprocsegsocialconsultenvios.table.comprovativo', cellTemplate: 'cellTemplateComprovativo', alignment: 'center'}
          ]
        }
      ],
      dataSource: new CustomStore({
        key: 'ssDecREmunID',
        load: () => this._getConsultEnviosList()
      }),
      export: {filename: 'global.menu.rhprocsegsocialconsultenvios'},
      headerFilter: {visible: false},
      height: '70vh',
      pager: {visible: false},
      paging: {enabled: false, pageSize: 100},
      remoteOperations: false,
      scrolling: {rowRenderingMode: 'virtual'},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplate',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this._btnGerarEstorno = {
      id: 'estorno',
      order: 7,
      type: 'button',
      caption: 'rhprocsegsocialconsultenvios.btn.gerarEstorno',
      class: 'btn-light',
      iconLeft: '<i class="fa fa-fw fa-plus"></i>',
      disabled: true,
      tooltip: {
        text: 'rhprocsegsocialconsultenvios.tooltip.gerarEstorno',
        placement: 'bottom-right',
        container: 'body',
        disabled: false
      },
      click: () => this._gerarEstorno()
    };

    this._btnApagarEstorno = {
      id: 'delete-estorno',
      order: 8,
      type: 'button',
      caption: 'rhprocsegsocialconsultenvios.btn.apagarEstorno',
      class: 'btn-danger',
      iconLeft: '<i class="fa fa-fw fa-trash"></i>',
      disabled: true,
      tooltip: {
        text: 'rhprocsegsocialconsultenvios.tooltip.apagarEstorno',
        placement: 'bottom-right',
        container: 'body',
        disabled: false
      },
      click: () => this._apagarEstorno()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.year = isNumber(this._params.ano) && this._params.ano !== -1 ? this._params.ano : moment().year();
    this.month = isNumber(this._params.mes) && this._params.mes !== -1 ? this._params.mes : moment().month() + 1;
    this.toolbar
      .addButton(this._btnGerarFicheiro)
      .addButton(this._btnReprocessarSegSocial)
      .addButton(this._btnEnviarWebService)
      .addButton(this._btnImpResumo)
      .addButton(this._btnGerarEstorno)
      .addButton(this._btnApagarEstorno);
    if (isNumber(this._params.ano) && isNumber(this._params.mes) && this._params.ano !== -1 && this._params.mes !== -1) {
      this.pesquisaSSDecRemunList();
    }
  }

  public selChanged(): void {
    this.havefichSel = this.selectedRowKeys.length > 0;

    this._btnReprocessarSegSocial.tooltip.disabled = this.havefichSel;
    this._btnReprocessarSegSocial.disabled = !this.havefichSel;

    this._btnEnviarWebService.tooltip.disabled = this.havefichSel;
    this._btnEnviarWebService.disabled = !this.havefichSel;

    this._btnGerarFicheiro.tooltip.disabled = this.havefichSel;
    this._btnGerarFicheiro.disabled = !this.havefichSel;

    this._btnImpResumo.tooltip.disabled = this.havefichSel;
    this._btnImpResumo.disabled = !this.havefichSel;

    this._btnGerarEstorno.tooltip.disabled = this.havefichSel;
    this._btnGerarEstorno.disabled = !this.havefichSel;

    this._btnApagarEstorno.tooltip.disabled = this.havefichSel;
    this._btnApagarEstorno.disabled = !this.havefichSel;
  }

  public viewInfoSSClick(item: IJsonRHProcSegSocialConsultEnvios): void {
    const modalInstance = this._cgModalService.showVanilla(ProceSegSocialViewInfoComponent, {size: 'xl'});
    const componentInstance: ProceSegSocialViewInfoComponent = modalInstance.componentInstance;
    componentInstance.serviceType = EProceSegSocialViewInfoServiceType.ProcSegSocialConsultService;
    componentInstance.nEmpresa = item.nEmpresa;
    componentInstance.ano = item.ssAno;
    componentInstance.mes = item.ssMes;
    componentInstance.idOrdemFichGerado = item.idOrdemFichGerado;
  }

  public pesquisaSSDecRemunList(): Promise<void> {
    this.selectedRowKeys = [];
    this.promise = this._rhprocSegSocialConsultEnviosService
      .getSSDecRemunList(this.year, this.month)
      .then((response: HttpResponse<Array<IJsonRHProcSegSocialConsultEnvios>>) => {
        this._rhprocSegSocialConsultEnviosList = response.body.map<IRHProcSegSocialConsultEnvios>((value: IJsonRHProcSegSocialConsultEnvios) => {
          (<IRHProcSegSocialConsultEnvios>value).tooltipCallback = {};
          return <IRHProcSegSocialConsultEnvios>value;
        });
        this._rhprocSegSocialConsultEnviosList = orderBy(this._rhprocSegSocialConsultEnviosList, ['nEmpresa']);
        if (this._dataGridInstance) {
          this._dataGridInstance.refresh();
        }
      })
      .finally(() => {
        this.selChanged();
        this.promise = undefined;
      });
    return this.promise;
  }

  public configWsSSLogin(nEmpresa: string): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ConfigLoginWsSSModalComponent, {size: 'md'});
    const componentInstance: ConfigLoginWsSSModalComponent = modalInstance.componentInstance;
    componentInstance.nEmpresa = nEmpresa;
    return modalInstance.result;
  }

  public verificarEnvios(): Promise<void> {
    const consultEnviosList: Array<IJsonRHProcSegSocialConsultEnvios> = this._getSelectedConsultEnvios();
    this.promise = this._rhprocSegSocialConsultEnviosService
      .postVerificarEnvios(consultEnviosList)
      .then((response: HttpResponse<IJsonRHProcSSConsultEnvComSemErro>) => {
        this._setConsultEnviosList(response.body.rhprocSegSocialConsultEnviosList);
        if (response.body.haveComErros) {
          this._plAlertService.warning('rhprocsegsocialconsultenvios.messages.empresasSelecionasHouveEmpresnaoGeradas');
        } else {
          this._plAlertService.success('rhprocsegsocialconsultenvios.messages.verificadosuccess');
        }
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IRHProcSegSocialConsultEnvios, string>): void {
    this._dataGridInstance = component;
  }

  public readonly fnProcFicheiro = (line: IRHProcSegSocialConsultEnvios, templateErrors: TemplateRef<void>) => (): void => {
    this._procFicheiro(line, templateErrors);
  };

  public readonly fnComprovativo = (line: IRHProcSegSocialConsultEnvios) => (): Promise<void> => this._comprovativo(line);

  private _procFicheiro(line: IRHProcSegSocialConsultEnvios, templateErrors: TemplateRef<void>): void {
    this.tooltipProcFicheir.templateRef = templateErrors;
    this._rhprocSegSocialConsultEnviosService.postProcFicheiroMsg(line).then((response: HttpResponse<Array<IJsonString>>) => {
      const errors: Array<string> = response.body.map((error: IJsonString) => error.value);
      line.tooltipCallback.open({errors: errors});
    });
  }

  private async _comprovativo(line: IRHProcSegSocialConsultEnvios): Promise<void> {
    const url = await firstValueFrom(this._rhprocSegSocialConsultEnviosService.getComprovativo(line.ssDecREmunID));
    await this._cgModalService.showPdf('rhprocsegsocialconsultenvios.comprovativo', url);
  }

  private _gerarFicheiro(): Promise<void> {
    const consultEnviosList: Array<IJsonRHProcSegSocialConsultEnvios> = this._getSelectedConsultEnvios();
    this.promise = this._cgModalService
      .showOkCancel('rhprocsegsocialconsultenvios.prompt.confTitle', 'rhprocsegsocialconsultenvios.prompt.geraFich', {
        btnCancelText: 'global.btn.no',
        btnOkText: 'global.btn.yes'
      })
      .then(() =>
        this._rhprocSegSocialConsultEnviosService.postGerarFicheiroDRI(consultEnviosList).then((response: HttpResponse<Blob>) => {
          const doc: Blob = response.body;
          if (!doc?.size) {
            this._plAlertService.success('rhprocsegsocialconsultenvios.prompt.semAlteracaoParaGerar');
          } else {
            downloadStream(response);
          }
        })
      )
      .finally(() => {
        this.pesquisaSSDecRemunList();
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }

  private _impResumo(): void {
    const consultIDS: Array<string> = this.selectedRowKeys;
    const modalRef = this._cgModalService.showVanilla(ConsultEnviosImpResumoModalComponent, {size: 'xl'});
    const componentInstance: ConsultEnviosImpResumoModalComponent = modalRef.componentInstance;
    componentInstance.consultIDS = consultIDS;
  }

  private _reprocessarSegSocial(): Promise<void> {
    const consultEnviosList: Array<IJsonRHProcSegSocialConsultEnvios> = this._getSelectedConsultEnvios();
    this.promise = this._rhprocSegSocialConsultEnviosService
      .postReprocessarSegSocial(consultEnviosList)
      .then((response: HttpResponse<IJsonRHProcSSConsultEnvComSemErro>) => {
        this._setConsultEnviosList(response.body.rhprocSegSocialConsultEnviosList);
        if (response.body.haveComErros) {
          this._plAlertService.error('rhprocsegsocialconsultenvios.messages.empresasSelecionasHouveEmpresnaoGeradas');
        } else {
          this._plAlertService.success('rhprocsegsocialconsultenvios.messages.reprocessamentoSuccess');
        }
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }

  private _enviarWebservice(): Promise<void> {
    const consultEnviosList: Array<IJsonRHProcSegSocialConsultEnvios> = this._getSelectedConsultEnvios();
    if (consultEnviosList.length === 0) {
      this._plAlertService.error('rhprocsegsocialconsultenvios.messages.semRegistosSelecionadas');
      return Promise.resolve();
    }
    return this._rhprocSegSocialConsultEnviosService.postEnviarFicheiroWebService(consultEnviosList).then((response: HttpResponse<IJsonRHProcSSConsultEnvComSemErro>) => {
      this._setConsultEnviosList(response.body.rhprocSegSocialConsultEnviosList);
      if (response.body.haveComErros) {
        this._plAlertService.error('rhprocsegsocialconsultenvios.messages.empresasSelecionasHouveEmpres');
      } else {
        this._plAlertService.success('rhprocsegsocialconsultenvios.messages.enviowebserviceSuccess');
      }
    });
  }

  private _setConsultEnviosList(list: Array<IJsonRHProcSegSocialConsultEnvios>): void {
    let changed = false;
    for (const item of list) {
      const index: number = this._rhprocSegSocialConsultEnviosList.findIndex((procSSConsultEnvios: IRHProcSegSocialConsultEnvios) => procSSConsultEnvios.ssDecREmunID === item.ssDecREmunID);
      if (index > -1) {
        this._rhprocSegSocialConsultEnviosList[index] = {...item, tooltipCallback: {}};
        if (!changed) {
          changed = true;
        }
      }
    }
    if (changed) {
      this.selChanged();
      if (this._dataGridInstance) {
        this._dataGridInstance.refresh();
      }
    }
  }

  private _getConsultEnviosList(): Array<IJsonRHProcSegSocialConsultEnvios> {
    return this._rhprocSegSocialConsultEnviosList;
  }

  private _getSelectedConsultEnvios(): Array<IJsonRHProcSegSocialConsultEnvios> {
    const consultEnviosList: Array<IJsonRHProcSegSocialConsultEnvios> = !this._dataGridInstance ? [] : this._dataGridInstance.getSelectedRowsData();
    for (const item of consultEnviosList) {
      item.sel = true;
    }
    return consultEnviosList;
  }

  private _gerarEstorno(): Promise<unknown> {
    const sel = this._getSelectedConsultEnvios();
    if (sel.length === 0) {
      this._plAlertService.error('rhprocsegsocialconsultenvios.messages.semRegistosSelecionadas');
      return Promise.resolve();
    }

    if (sel.length > 1) {
      this._plAlertService.error('rhprocsegsocialconsultenvios.messages.soPodeSelecionarUmRegisto');
      return Promise.resolve();
    }

    const item: IJsonRHProcSegSocialConsultEnvios = sel[0];
    return this._rhprocSegSocialConsultEnviosService.gerarEstorno(item.nEmpresa, item.ssAno, item.ssMes, item.idOrdemFichGerado).then(() => {
      this.pesquisaSSDecRemunList();
    });
  }

  private _apagarEstorno(): Promise<unknown> {
    const sel = this._getSelectedConsultEnvios();
    if (sel.length === 0) {
      this._plAlertService.error('rhprocsegsocialconsultenvios.messages.semRegistosSelecionadas');
      return Promise.resolve();
    }

    if (sel.length > 1) {
      this._plAlertService.error('rhprocsegsocialconsultenvios.messages.soPodeSelecionarUmRegisto');
      return Promise.resolve();
    }

    const item: IJsonRHProcSegSocialConsultEnvios = sel[0];
    return this._rhprocSegSocialConsultEnviosService.apagarEstorno(item.nEmpresa, item.ssAno, item.ssMes, item.idOrdemFichGerado).then(() => {
      this.pesquisaSSDecRemunList();
    });
  }
}
