import {Component, Injector, Input, OnInit} from '@angular/core';
import {isEmpty, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {ICRABDPerfil} from '../../abdes.entity.interface';

@Component({
  selector: 'editar-cruzamentos-perfil-abdes',
  templateUrl: './editarCruzamentosPerfiL.modal.component.html'
})
export class EditarCruzamentosPerfilModalComponent extends CGModalComponent<ICRABDPerfil> implements OnInit {
  @Input() public cruzamento: ICRABDPerfil;

  public readonly outputAbdes: string;
  public filterIRS: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.outputAbdes = '{{nCodABDESC}} - {{designacaoBreve}}';
  }

  public ngOnInit(): void {
    this.filterIRS = `grupoDescontoCategoria=${this.cruzamento.tipoCategoria}`;
  }

  public close(): void {
    if (!this.cruzamento.entSuporteEmpregado && !this.cruzamento.entSuportePatronato) {
      this._plAlertService.error('generateAbdes.editarcruzamentos.temseleentidade');
      return;
    }
    if (isEmpty(this.cruzamento.descricao) || isEmpty(this.cruzamento.nCodDesconto)) {
      this._plAlertService.error('generateAbdes.editarcruzamentos.teminserirdesc');
      return;
    }
    super.close(this.cruzamento);
  }
}
