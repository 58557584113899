<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'about.title'"></h4>
</div>

<div class="modal-body" [plPromise]="promise">
  <div class="row">
    <div class="col-sm-12 col-md-5 text-center">
      <img class="img-responsive logo-about" src="../../../../../../assets/images/logo.webp" alt="logo-empresa" />
    </div>

    <div class="col-sm-12 col-md-7">
      <div class="row">
        <div class="col-sm-12 col-md-3">
          <div class="about-modal-block">
            <div class="about-aligner">
              <label class="about-title" [translate]="'about.licenca.licId'"></label>
            </div>
            <div class="about-aligner">
              <div class="about-val">{{ configurations.licenca.licId }}</div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-9">
          <div class="about-modal-block">
            <div class="about-aligner">
              <label class="about-title" [translate]="'about.licenca.nome'"></label>
            </div>
            <div class="about-aligner">
              <div class="about-val">{{ configurations.licenca.nome }}</div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-12">
          <div class="about-modal-block">
            <div class="about-aligner">
              <label class="about-title" [translate]="'about.licenca.descricao'"></label>
            </div>
            <div class="about-aligner">
              <div class="about-val">{{ configurations.licenca.descricao }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <hr />
    </div>

    <div class="col-12 col-sm-6 col-md-3">
      <div class="about-modal-block">
        <div class="about-aligner">
          <label class="about-title" [translate]="'about.erp.versao'"></label>
        </div>

        <div class="about-aligner">
          <div class="about-val">{{ configurations.erp.versao }}</div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-3">
      <div class="about-modal-block">
        <div class="about-aligner">
          <label class="about-title" [translate]="'about.erp.dataVersao'"></label>
        </div>

        <div class="about-aligner">
          <div class="about-val">{{ configurations.erp.dataversao }}</div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-3">
      <div class="about-modal-block">
        <div class="about-aligner">
          <label class="about-title" [translate]="'about.licenca.nUtilizadores'"></label>
        </div>

        <div class="about-aligner">
          <div class="about-val">{{ licenca.nUtilizadores }} / {{ licenca.totalNUtilizadores }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-12 col-sm-6 col-md-3">
      <div class="about-modal-block">
        <div class="about-aligner">
          <label class="about-title" [translate]="'about.licenca.portal'" [translateParams]="{name: 'portals.items.erp' | translate}"></label>
        </div>

        <div class="about-aligner">
          <div class="about-val">{{ licenca.portalErp }} / {{ licenca.totalPortalErp }}</div>
        </div>
      </div>
    </div>

    <div class="col-12 col-12 col-sm-6 col-md-3">
      <div class="about-modal-block">
        <div class="about-aligner">
          <label class="about-title" [translate]="'about.licenca.portal'" [translateParams]="{name: 'portals.items.pca' | translate}"></label>
        </div>

        <div class="about-aligner">
          <div class="about-val">{{ licenca.portalPca }} / {{ licenca.totalPortalPca }}</div>
        </div>
      </div>
    </div>

    <div class="col-12 col-12 col-sm-6 col-md-3">
      <div class="about-modal-block">
        <div class="about-aligner">
          <label class="about-title" [translate]="'about.licenca.portal'" [translateParams]="{name: 'portals.items.colaborador' | translate}"></label>
        </div>

        <div class="about-aligner">
          <div class="about-val">{{ licenca.portalColaborador }} / {{ licenca.totalPortalColaborador }}</div>
        </div>
      </div>
    </div>

    <div class="col-12 col-12 col-sm-6 col-md-3">
      <div class="about-modal-block">
        <div class="about-aligner">
          <label class="about-title" [translate]="'about.licenca.portal'" [translateParams]="{name: 'portals.items.contabilidade' | translate}"></label>
        </div>

        <div class="about-aligner">
          <div class="about-val">{{ licenca.portalContabilidade }} / {{ licenca.totalPortalContabilidade }}</div>
        </div>
      </div>
    </div>

    <div class="col-12 col-12 col-sm-6 col-md-3">
      <div class="about-modal-block">
        <div class="about-aligner">
          <label class="about-title" [translate]="'about.licenca.portal'" [translateParams]="{name: 'portals.items.rh' | translate}"></label>
        </div>

        <div class="about-aligner">
          <div class="about-val">{{ licenca.portalRH }} / {{ licenca.totalPortalRH }}</div>
        </div>
      </div>
    </div>

    <div class="col-12 col-12 col-sm-6 col-md-3">
      <div class="about-modal-block">
        <div class="about-aligner">
          <label class="about-title" [translate]="'about.licenca.portal'" [translateParams]="{name: 'portals.items.ativos' | translate}"></label>
        </div>

        <div class="about-aligner">
          <div class="about-val">{{ licenca.portalAtivos }} / {{ licenca.totalPortalAtivos }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="about-modal-block">
        <div class="about-aligner">
          <label class="about-title" [translate]="'about.user.roles'"></label>
        </div>

        <div class="about-aligner">
          <div class="about-val">
            <span class="badge rounded-pill bg-secondary role" *ngFor="let item of userRoles">{{ item.description }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
