<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'docscomerciais.auxiliares.documento'"></h4>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <documento-faturacao [doc]="doc" [actionAnularDoc]="fnAnularDocumento" [actionPdf]="fnGetPdf" [actionAnexarGDocComercial]="fnAnexarGDocComercial"> </documento-faturacao>
    </div>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-light btn-sm action-dismiss" [disabled]="closeDisabled" (click)="dismiss()">
      <i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span>
    </button>
  </div>
</pl-form>
