<div class="recibosVencimentos-email-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'recibosvencimentos.modal.enviaremail'"></h5>
  </div>

  <div class="modal-body">
    <pl-form>
      <pl-group>
        <pl-group>
          <label [translate]="'recibosvencimentos.todos'"></label>
          <edit>
            <pl-edit type="boolean" [(model)]="todosColaboradores"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'recibosvencimentos.modal.byEmailAlternativo'"></label>
          <edit>
            <pl-edit type="boolean" [model]="byTargetEmail" (modelChange)="byTargetEmail = $event; setEmailAlternativo()"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <label [translate]="'recibosvencimentos.colaborador'"></label>
        <edit>
          <entity-autocomplete entity="dgemps" [(model)]="dgemp" [properties]="{disabled: todosColaboradores}"> </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'recibosvencimentos.modal.emailAlternativo'"></label>
        <edit>
          <pl-edit-email [(model)]="targetEmail" [properties]="{disabled: !byTargetEmail}"> </pl-edit-email>
        </edit>
      </pl-group>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-primary" (evtClicked)="close()" [promise]="promise"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.send'"></span> </pl-button>

    <pl-button klass="btn-light" (evtClicked)="dismiss()" [promise]="promise"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
