import {TDate} from '../../../../common/dates';
import type dxDataGrid from 'devextreme/ui/data_grid';

export enum ERHProceSalariosProceType {
  None = 'rhpsptNone',
  Single = 'rhpsptSingle',
  Multi = 'rhpsptMulti'
}
export interface IRHProceSalariosEmpresa {
  selected: boolean;
  nEmpresa: string;
  nomeEmpresa: string;
  erro: string;
}

export interface IRHProceSalariosMEmpData {
  mes: number;
  ano: number;
  selected: boolean;
  descricao: string;
}

export interface IRHProceSalariosMEmpErro {
  nEmpresa: string;
  mesAno: string;
  erro: string;
}

export interface IRHProceSalariosProcResultItem {
  psNomeEmpresa: string;
  psNEmpresa: string;
  mes: number;
  ano: number;
  dataProce: TDate;
  descricao: string;
  ateDataProc: TDate;
  dADataProc: TDate;
  nDiasUteis: number;
  temErro: boolean;
  nProcessamento: number;
  errorList: Array<IRHProceSalariosMEmpErro>;
}

export interface IRHProceSalariosProcMultiData {
  empresas: Array<string>;
  ano: number;
  mes: number;
  tipoProceMensal: boolean;
  tipoProceFerias: boolean;
  tipoProceNatal: boolean;
  podeReprocessarSalarios: boolean;
  descricao: string;
}

export interface IRHProceSalariosProcSingleData {
  empList?: Array<number>;
  tipoProcessamento: number;
  dataProcessamento: TDate;
  dataInicio: TDate;
  dataFim: TDate;
  calendario: number;
  nDiasUteis: number;
  descricao: string;
  anoParaSegSocial: number;
  mesParaSegSocial: number;
  nDiasTotalMes: number;
  nEmpresa: string;
  calenCabDesc?: string;
  descricaoTipoProc?: string;
}

export interface IRHProceSalariosDgEmp {
  codEmp: number;
  nome: string;
  apelido: string;
  nBIdentidade: string;
  nContribuinte: string;
  dataNascimento: TDate;
  fullName: string;
}

export enum ERHProceSalariosSteps {
  INTRO = 'RHINTRO',
  EMPRESAS_SEL = 'RHEMPRESA_SEL',
  PROC_CONFIG = 'RHPROC_CONFIG',
  PROC = 'RHPROC',
  PROC_RESULTS = 'RHPROC_RESULTS'
}

export interface IRHProceSalariosResultSingleProcItem {
  codEmp: number;
  nome: string;
  strEstadoProcEmpregado: string;
  nProcessamento: number;
  nRecibo: number;
  dataProcessamento: TDate;
  identificacaoEmpregado: string;
}

export interface IJsonRHProceSalariosResultSingleProcError {
  codEmpregado: number;
  nomeEmpregado: string;
  nProcessamento: number;
  dataProcessamento: TDate;
  tipoErroEstado: number;
  descTipoErro: string;
  descricaoErro: string;
}

export interface IJsonRHProceSalariosResultSingleProcData {
  errorList: Array<IJsonRHProceSalariosResultSingleProcError>;
  data: Array<IRHProceSalariosResultSingleProcItem>;
}

export interface IRHProceSalariosProcExistente {
  nProcessamento: number;
  dataProcessamento: TDate;
  tipoProcessamento: number;
  descricao: string;
  dataInicoProc: TDate;
  dataFimProce: TDate;
  calenCabID: number;
  estadoIntegracao: number;
  nDiasUteis: number;
  nDiasParaProcessamen: number;
  nDiasTotalMes: number;
  anoParaSegSocial: number;
  mesParaSegSocial: number;
  proceImportado: boolean;
  tipoProcessamentoDescricao: string;
  mesesEncerrados: boolean;
  listEmpregados?: Array<IRHProceSalariosProcExistenteEmpregado>;
  listEmpregadosTableInstance?: dxDataGrid<IRHProceSalariosProcExistenteEmpregado, number>;
  selectedKeysProcExistente?: Array<number>;
}

export interface IRHProceSalariosProcExistenteEmpregado {
  nProcessamento: number;
  codEmpregado: number;
  nomeEmpregado: string;
  dataProcessamento: TDate;
  strEstadoProcEmpregado: string;
  identificacaoEmpregado: string;
  nRecibo: number;
  existeTransfBancaria: boolean;
  fichCriadoSegSocial: boolean;
  rhPodeProcessarSalarioscomTransfBancariaGerado: boolean;
  rhPodeProcessarSalarioscomSegSocialGerado: boolean;
  integradoContab: boolean;
  reProcValidationResult?: IRHEmpregadoReProcValidationResult;
}

export interface IRHEmpregadoReProcValidationResult {
  result: ERHEmpregadoReProcValidationResult;
  errorMessage: string;
}

export interface IRHProceSalariosReProcError {
  codEmpregado: number;
  tipoErroEstado: number;
  descTipoErro: string;
  descricaoErro: string;
}

export interface IRHProceSalariosReProcResult {
  errors: Array<IRHProceSalariosReProcError>;
}

export interface IRHCacheHelper {
  lastSelectedSingleNEmpresa: string;
  lastTipoProcessamento: number;
  lastDataInicio: TDate;
  lastDataFim: TDate;
}

export enum ERHEmpregadoReProcValidationResult {
  VALID,
  VALID_PROMPT,
  INVALID
}

export const MODULE_NAME_PROCE_SALARIOS = 'proceSalarios';
