<div class="rhassistentetrabalhador entity-detail-form">
  <pl-form>
    <pl-tabs (evtSelected)="changedTab($event.nextId)" [plPromise]="promise" [callback]="rhassistentetrabalhadorTabCallback" [activeId]="activeTab">
      <pl-tab [id]="'integracaosalarios'">
        <div *plTabTitle>
          <span [translate]="'rhintegracaosalarios.tab.integracaosalarios'"></span>
        </div>

        <div *plTabContent>
          <div class="row mb-1">
            <div class="col-sm-6">
              <div class="card h-100">
                <div class="card-header">
                  <div class="card-title" [translate]="'rhintegracaosalarios.titles.dadosadicionaisint'"></div>
                </div>
                <div class="card-body">
                  <h5 class="mb-3 fw-bold" [translate]="'rhintegracaosalarios.fields.integraSalarios'"></h5>

                  <pl-group>
                    <edit>
                      <pl-edit type="checkbox" [model]="model.pocRemuLiq" (modelChange)="integrachanged($event)" [properties]="{label: 'rhintegracaosalarios.fields.pocRemuLiq'}"> </pl-edit>
                    </edit>
                  </pl-group>

                  <pl-group>
                    <pl-group>
                      <edit>
                        <pl-edit
                          type="checkbox"
                          [model]="model.classeTrab"
                          (modelChange)="model.classeTrab = $event; dadosadicionaisChanged($event)"
                          [properties]="{label: 'rhintegracaosalarios.fields.classeTrab'}">
                        </pl-edit>
                      </edit>
                    </pl-group>

                    <pl-group>
                      <edit>
                        <pl-edit
                          type="checkbox"
                          [model]="model.funcao"
                          (modelChange)="model.funcao = $event; dadosadicionaisChanged($event)"
                          [properties]="{label: 'rhintegracaosalarios.fields.funcao'}">
                        </pl-edit>
                      </edit>
                    </pl-group>
                  </pl-group>

                  <pl-group>
                    <pl-group>
                      <edit>
                        <pl-edit
                          type="checkbox"
                          [model]="model.operacaoSeccao"
                          (modelChange)="model.operacaoSeccao = $event; dadosadicionaisChanged($event)"
                          [properties]="{label: 'rhintegracaosalarios.fields.operacaoSeccao'}"></pl-edit>
                      </edit>
                    </pl-group>

                    <pl-group>
                      <edit>
                        <pl-edit
                          type="checkbox"
                          [model]="model.codZona"
                          (modelChange)="model.codZona = $event; dadosadicionaisChanged($event)"
                          [properties]="{label: 'rhintegracaosalarios.fields.codZona'}">
                        </pl-edit>
                      </edit>
                    </pl-group>
                  </pl-group>

                  <pl-group>
                    <pl-group>
                      <edit>
                        <pl-edit
                          type="checkbox"
                          [model]="model.departamento"
                          (modelChange)="model.departamento = $event; dadosadicionaisChanged($event)"
                          [properties]="{label: 'rhintegracaosalarios.fields.departamento'}">
                        </pl-edit>
                      </edit>
                    </pl-group>

                    <pl-group>
                      <edit>
                        <pl-edit
                          type="checkbox"
                          [model]="model.subDepartamento"
                          (modelChange)="model.subDepartamento = $event; dadosadicionaisChanged($event)"
                          [properties]="{label: 'rhintegracaosalarios.fields.subDepartamento'}">
                        </pl-edit>
                      </edit>
                    </pl-group>
                  </pl-group>

                  <pl-group>
                    <pl-group>
                      <edit>
                        <pl-edit
                          type="checkbox"
                          [model]="model.centroCusto"
                          (modelChange)="model.centroCusto = $event; dadosadicionaisChanged($event)"
                          [properties]="{label: 'rhintegracaosalarios.fields.centroCusto'}">
                        </pl-edit>
                      </edit>
                    </pl-group>

                    <pl-group>
                      <edit>
                        <pl-edit
                          type="checkbox"
                          [model]="model.reparticaoCentroCusto"
                          (modelChange)="model.reparticaoCentroCusto = $event; dadosadicionaisChanged($event)"
                          [properties]="{label: 'rhintegracaosalarios.fields.reparticaoCentroCusto'}">
                        </pl-edit>
                      </edit>
                    </pl-group>
                  </pl-group>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="card mb-1">
                <div class="card-header">
                  <div class="card-title" [translate]="'rhintegracaosalarios.titles.confgintegracaosalarios'"></div>
                </div>
                <div class="card-body">
                  <pl-group>
                    <edit>
                      <pl-edit
                        type="checkbox"
                        [model]="model.integraSalariosPorDefeito"
                        (modelChange)="integraSalPorDefChanged($event)"
                        [properties]="{label: 'rhintegracaosalarios.fields.integraSalariosPorDefeito'}">
                      </pl-edit>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <edit>
                      <pl-edit
                        type="checkbox"
                        [model]="model.integraSalariosPorOrgaoSocial"
                        (modelChange)="integraSalPorOrgSocChanged($event)"
                        [properties]="{label: 'rhintegracaosalarios.fields.integraSalariosPorOrgaoSocial'}">
                      </pl-edit>
                    </edit>
                  </pl-group>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <div class="card-title" [translate]="'rhintegracaosalarios.titles.mascaraanalitica'"></div>
                </div>
                <div class="card-body">
                  <pl-group>
                    <edit>
                      <pl-edit type="checkbox" [(model)]="model.validaMascara" [properties]="{label: 'rhintegracaosalarios.fields.validamascara'}"> </pl-edit>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'reparticoesccustos.fields.mascara'"></label>
                    <edit>
                      <entity-autocomplete
                        entity="mascarasanalitica"
                        attrName="mascaraAnalitica"
                        [model]="model"
                        [filter]="'tipo=1'"
                        (selectedKeyChange)="mascaraAnaliticaChanged($event)"
                        [properties]="{allowInvalid: false}"
                        [fieldsMap]="{mascara: 'mascaraAnalitica'}"
                        [output]="'mascara'"
                        [outputKey]="'mascara'">
                      </entity-autocomplete>
                    </edit>
                  </pl-group>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-1">
            <div class="card-header">
              <div class="card-title" [translate]="'rhintegracaosalarios.titles.dadosadicionaisuni'"></div>
            </div>

            <div class="card-body">
              <pl-group>
                <edit>
                  <pl-edit
                    type="checkbox"
                    [(model)]="model.integraRU"
                    (modelChange)="model.integraRU = $event; integraRUChanged($event)"
                    [ngClass]="'fw-bold'"
                    [properties]="{label: 'rhintegracaosalarios.fields.integraRU'}">
                  </pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <pl-group>
                  <edit>
                    <pl-edit type="checkbox" [(model)]="model.profissao" [properties]="{disabled: true, label: 'rhintegracaosalarios.fields.profissao'}"> </pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <edit>
                    <pl-edit type="checkbox" [(model)]="model.situacaoProfissao" [properties]="{disabled: true, label: 'rhintegracaosalarios.fields.situacaoProfissao'}"> </pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <edit>
                    <pl-edit type="checkbox" [(model)]="model.codCCT" [properties]="{disabled: true, label: 'rhintegracaosalarios.fields.codCCT'}"> </pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <edit>
                    <pl-edit type="checkbox" [(model)]="model.nivelQualif" [properties]="{disabled: true, label: 'rhintegracaosalarios.fields.nivelQualif'}"> </pl-edit>
                  </edit>
                </pl-group>
              </pl-group>
            </div>
          </div>
          <div class="card mb-1">
            <div class="card-header">
              <div class="card-title" [translate]="'rhintegracaosalarios.titles.dadosadicionaisuniint'"></div>
            </div>

            <div class="card-body">
              <pl-group>
                <edit>
                  <pl-edit type="checkbox" [(model)]="model.categoria" [properties]="{label: 'rhintegracaosalarios.fields.categoria'}"> </pl-edit>
                </edit>
              </pl-group>
            </div>
          </div>
        </div>
      </pl-tab>

      <pl-tab [id]="'mascaraanaliticaabono'" *ngIf="model.validaMascara">
        <div *plTabTitle>
          <span [translate]="'rhintegracaosalarios.tab.mascaraanaliticaabono'"></span>
        </div>

        <div *plTabContent>
          <div class="flex-container">
            <div *ngFor="let mascaraAbono of assistenteTrabalhadorMascaraAbono" class="checkbox-mascaras">
              <pl-group>
                <edit>
                  <pl-edit type="checkbox" [(model)]="mascaraAbono.temMascaraAnalitica" (modelChange)="mascaraAbono.temMascaraAnalitica = $event" [properties]="{label: mascaraAbono.descCategoria}">
                  </pl-edit>
                </edit>
              </pl-group>
            </div>
          </div>
        </div>
      </pl-tab>

      <pl-tab [id]="'mascaraanaliticadesconto'" *ngIf="model.validaMascara">
        <div *plTabTitle>
          <span [translate]="'rhintegracaosalarios.tab.mascaraanaliticadesconto'"></span>
        </div>

        <div *plTabContent>
          <div class="flex-container">
            <div *ngFor="let mascaraDesconto of assistenteTrabalhadorMascaraDesconto" class="checkbox-mascaras">
              <pl-group>
                <edit>
                  <pl-edit
                    type="checkbox"
                    [(model)]="mascaraDesconto.temMascaraAnalitica"
                    (modelChange)="mascaraDesconto.temMascaraAnalitica = $event"
                    [properties]="{label: mascaraDesconto.descCategoria}">
                  </pl-edit>
                </edit>
              </pl-group>
            </div>
          </div>
        </div>
      </pl-tab>
    </pl-tabs>
  </pl-form>
</div>
