import {Component, Injector, OnInit, Renderer2} from '@angular/core';
import {ICGTableDataReceived, IPlToolbarItem, isObject} from 'pl-comps-angular';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {IDevExpressDataGridEventOnRowPrepared} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonPOC} from '../../jsonPOC.entity.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {MODULE_NAME_POCS_COPY} from '../../pocs.entity.interface';

const CLASS_LEGEND = 'tipocontacontab';

@Component({
  selector: 'pocs-list',
  templateUrl: './pocs.entity.list.component.html'
})
export class PocsListComponent extends ModuloEntityListComponent<IJsonPOC> implements OnInit {
  public readonly classLegend: string;
  public readonly tipoContaContab: typeof ETipoContaContabilidade;
  public readonly keysTipoContaContab: ReadonlyArray<keyof ETipoContaContabilidade>;

  private readonly _btnGenerateXls: IPlToolbarItem;
  private readonly _btnPocsEntreEmpresas: IPlToolbarItem;
  private readonly _maintenancePocsEntreEmpresas: IModuleMaintenanceInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _renderer: Renderer2,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService
  ) {
    super(_injector);
    this._maintenancePocsEntreEmpresas = this._moduleMaintenanceService.build(MODULE_NAME_POCS_COPY);
    this.classLegend = CLASS_LEGEND;
    this.tipoContaContab = ETipoContaContabilidade;
    this.keysTipoContaContab = Object.freeze<Array<keyof ETipoContaContabilidade>>(<Array<keyof ETipoContaContabilidade>>Object.keys(this.tipoContaContab));
    this._btnGenerateXls = {
      id: 'btnGenerateXls',
      type: 'download',
      order: 101,
      iconLeft: '<i class="fa fa-fw fa-file-excel-o"></i>',
      class: 'btn-success',
      caption: 'pocs.exportXls',
      visible: true,
      download: {
        url: ''
      }
    };
    this._btnPocsEntreEmpresas = {
      id: 'btn-controlo-iva-entre-empresas',
      order: 126,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-copy"></i>',
      class: 'btn-primary',
      caption: 'pocs.btns.copiaContasPlanoEmpresa',
      click: () => this._openPocsEntreEmpresas()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._btnGenerateXls.download.url = `${this.service.entityUrl()}/export/xls`;
    this.toolbar.addButton(this._btnGenerateXls);
    this.toolbar.addButton(this._btnPocsEntreEmpresas);
  }

  public onDataGridRowPrepared({data, rowElement, rowType}: IDevExpressDataGridEventOnRowPrepared<IJsonPOC>): void {
    if (rowType === 'data' && data?.tipo) {
      this._renderer.addClass(rowElement, `${this.classLegend}-${data.tipo}`);
    }
  }

  public readonly fnOnDataReceived = (event: ICGTableDataReceived<IJsonPOC>): void => {
    this._onDataReceived(event);
  };

  private _onDataReceived(event: ICGTableDataReceived<IJsonPOC>): void {
    const {data} = event;
    if (isObject(data)) {
      for (const item of data.list) {
        item.rowClass = `${CLASS_LEGEND}-${item.tipo}`;
      }
    }
  }

  private _openPocsEntreEmpresas(): Promise<void> {
    return this._maintenancePocsEntreEmpresas.maintenance();
  }
}
