<div class="action-contapocsalda-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="close()"></button>
    <h4 class="modal-title" [translate]="title"></h4>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'taxonomias.modalContaSalda.conta'"></label>
      <edit>
        <entity-autocomplete entity="pocs" [model]="contaPoc" (selectedKeyChange)="contaPoc.nConta = $event" (evtSelectedDescriptionChanged)="contaPoc.nome = $event" [filter]="filterPoc">
        </entity-autocomplete>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-primary action-submit" [disabled]="!contaPoc.nConta" [onClick]="close">
      <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span>
    </pl-button>

    <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
