import fscreen from 'fscreen';
import {from, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {InvalidPDFException, PDFDataRangeTransport} from 'pdfjs-dist';
import {Component, ContentChild, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {PDFDocumentProxy, PDFProgressData, PdfViewerComponent, RenderTextMode} from 'ng2-pdf-viewer';
import {extractContentDispositionFilename, extractContentType, newFile} from '../common/files/files';
import type {IPlLocale, IPlLocalePdf} from '../common/locale/locales.interface';
import {
  IPlPdfCallback,
  IPlPdfEventPageRendered,
  IPlPdfEvtConfigureToolbar,
  IPlPdfSource,
  PL_PDF_TOOLBAR_GROUP_ID,
  PL_PDF_TOOLBAR_ID_BTN_DOWNLOAD,
  PL_PDF_TOOLBAR_ID_BTN_NEXT,
  PL_PDF_TOOLBAR_ID_BTN_PRESENTATION_MODE,
  PL_PDF_TOOLBAR_ID_BTN_PREVIOUS,
  PL_PDF_TOOLBAR_ID_BTN_PRINT,
  PL_PDF_TOOLBAR_ID_BTN_ZOOM_FIT,
  PL_PDF_TOOLBAR_ID_BTN_ZOOM_IN,
  PL_PDF_TOOLBAR_ID_BTN_ZOOM_OUT,
  PL_PDF_TOOLBAR_ID_GROUP_NAV,
  PL_PDF_TOOLBAR_ID_GROUP_ZOOM,
  PL_PDF_TOOLBAR_ID_MENU_ZOOM,
  PL_PDF_TOOLBAR_ID_PAGES,
  PL_PDF_TOOLBAR_ORDER_BASE,
  PL_PDF_TOOLBAR_ORDER_STEP,
  TPlPdfSource,
  TPlPdfZoomLevel
} from './pdf.component.interface';
import type {IPlToolbarInstance, IPlToolbarItem, IPlToolbarMenuItem} from '../toolbar/toolbar.interface';
import {isArrayBuffer, isBlob, isBoolean, isEmpty, isFile, isFunction, isNumber, isObject, isString, isTypedArray, isUndefinedOrNull, timeout} from '../common/utilities/utilities';
import {Logger} from '../logger/logger';
import {PlCompsService} from '../common/service/comps.service';
import {IPlCompsServiceConfig, PlFileReader} from '../common/common';
import {PlLocaleService} from '../common/locale/locale.service';
import {PlPdfErrorHandler} from './pdf.errorhandler';
import {PlPdfFetchService} from './pdf.fetch.service';
import {PlPdfHeaderDirective} from './pdf.header.directive';
import {PlPdfOptionsService} from './pdf.component.options.service';
import {PlToolbarService} from '../toolbar/toolbar.service';

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const ZOOM_LEVELS: ReadonlyArray<TPlPdfZoomLevel> = Object.freeze(['auto', 'page-actual', 'page-fit', 'page-width', 0.5, 0.75, 1, 1.25, 1.5, 2, 3, 4]);
const STEP_ZOOM = 0.25;

@Component({
  selector: 'pl-pdf',
  templateUrl: './pdf.component.html'
})
export class PlPdfComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public pdfSrc: TPlPdfSource;
  @Input() public errorMessage: string;
  @Input() public externalLinkTarget: string;
  @Input() public filename: string;
  @Input() public contentType: string;
  @Input() public page: number;
  @Input() public renderText: boolean;
  @Input() public renderTextMode: RenderTextMode;
  @Input() public rotation: number;
  @Input() public showAll: boolean;
  @Input() public showBorders: boolean;
  @Input() public stickToPage: boolean;
  @Input() public toolbarInstanceId: string;
  @Input() public zoomLevel: TPlPdfZoomLevel;
  @Input() public callback: IPlPdfCallback;
  @Output() public readonly originalSizeChange: EventEmitter<boolean>;
  @Output() public readonly pageChange: EventEmitter<number>;
  @Output() public readonly zoomLevelChange: EventEmitter<TPlPdfZoomLevel>;
  @Output() public readonly evtConfigureToolbar: EventEmitter<IPlPdfEvtConfigureToolbar>;
  @Output() public readonly evtAfterLoadComplete: EventEmitter<PDFDocumentProxy>;
  @Output() public readonly evtPageRendered: EventEmitter<IPlPdfEventPageRendered>;
  @Output() public readonly evtTextLayerRendered: EventEmitter<CustomEvent>;
  @Output() public readonly evtError: EventEmitter<any>;
  @Output() public readonly evtOnProgress: EventEmitter<PDFProgressData>;
  @Output() public readonly evtStatus: EventEmitter<boolean>;
  @ContentChild(PlPdfHeaderDirective, {read: TemplateRef}) public readonly templateHeader: TemplateRef<PlPdfHeaderDirective>;

  public locale: IPlLocalePdf;
  public src: IPlPdfSource;
  // This has to be `any` because ng2-pdf-viewer only accepts zoom as number but PDFJS accepts more values
  public zoom: any;
  public isLoading: boolean;
  public hasError: boolean;
  public renderNotSupported: boolean;
  public localErrorMessage: string;
  public pdf: PDFDocumentProxy;
  public debugUrl: string;

  @ViewChild('pdfViewer', {read: ElementRef}) private readonly _pdfViewerElement: ElementRef<HTMLElement>;
  @ViewChild('pdfViewerComponent', {read: PdfViewerComponent}) private readonly _pdfViewerComponent: PdfViewerComponent;
  private readonly _destroyed: Subject<void>;
  private readonly _mnuBtnPrevious: IPlToolbarMenuItem;
  private readonly _mnuBtnNext: IPlToolbarMenuItem;
  private readonly _mnuNav: IPlToolbarItem;
  private readonly _mnuPages: IPlToolbarItem;
  private readonly _mnuZoom: IPlToolbarItem;
  private readonly _mnuBtnZoomIn: IPlToolbarMenuItem;
  private readonly _mnuBtnZoomFit: IPlToolbarMenuItem;
  private readonly _mnuBtnZoomOut: IPlToolbarMenuItem;
  private readonly _groupZoom: IPlToolbarItem;
  private readonly _btnPresentationMode: IPlToolbarItem;
  private readonly _btnDownload: IPlToolbarItem;
  private readonly _btnPrint: IPlToolbarItem;
  private readonly _subscriptionLocale: Subscription;
  private readonly _subscriptionConfig: Subscription;
  private _configurations: IPlCompsServiceConfig;
  private _toolbarInstance: IPlToolbarInstance;
  private _subscriptionToolbarPageChanged: Subscription;
  private _filename: string;
  private _contentType: string;
  private _objectURL: string;
  private _originalZoomLevel: TPlPdfZoomLevel;
  private _fetchDocumentTask: Subscription;

  constructor(
    private readonly _domSanitizer: DomSanitizer,
    private readonly _logger: Logger,
    private readonly _plPdfOptionsService: PlPdfOptionsService,
    private readonly _plPdfFetchService: PlPdfFetchService,
    private readonly _plLocaleService: PlLocaleService,
    private readonly _plCompsService: PlCompsService,
    private readonly _plToolbarService: PlToolbarService,
    private readonly _plPdfErrorHandler: PlPdfErrorHandler
  ) {
    this.originalSizeChange = new EventEmitter<boolean>();
    this.pageChange = new EventEmitter<number>();
    this.zoomLevelChange = new EventEmitter<TPlPdfZoomLevel>();
    this.evtConfigureToolbar = new EventEmitter<IPlPdfEvtConfigureToolbar>();
    this.evtAfterLoadComplete = new EventEmitter<any>();
    this.evtPageRendered = new EventEmitter<IPlPdfEventPageRendered>();
    this.evtTextLayerRendered = new EventEmitter<CustomEvent>();
    this.evtError = new EventEmitter<any>();
    this.evtOnProgress = new EventEmitter<any>();
    this.evtStatus = new EventEmitter<boolean>();
    this.zoom = 'page-width';
    this.hasError = false;
    this.renderNotSupported = false;
    this._destroyed = new Subject<void>();

    this._mnuBtnPrevious = {
      groupId: PL_PDF_TOOLBAR_GROUP_ID,
      id: PL_PDF_TOOLBAR_ID_BTN_PREVIOUS,
      type: 'button',
      iconLeft: '<i class="fa fa-chevron-left fa-fw"></i>',
      class: 'btn-primary',
      click: () => {
        this._goPrevious();
      }
    };

    this._mnuBtnNext = {
      groupId: PL_PDF_TOOLBAR_GROUP_ID,
      id: PL_PDF_TOOLBAR_ID_BTN_NEXT,
      type: 'button',
      iconLeft: '<i class="fa fa-chevron-right fa-fw"></i>',
      class: 'btn-primary',
      click: () => {
        this._goNext();
      }
    };

    this._mnuNav = {
      groupId: PL_PDF_TOOLBAR_GROUP_ID,
      id: PL_PDF_TOOLBAR_ID_GROUP_NAV,
      order: PL_PDF_TOOLBAR_ORDER_BASE,
      type: 'button-group',
      items: [this._mnuBtnPrevious, this._mnuBtnNext]
    };

    this._mnuPages = {
      groupId: PL_PDF_TOOLBAR_GROUP_ID,
      id: PL_PDF_TOOLBAR_ID_PAGES,
      order: this._mnuNav.order + PL_PDF_TOOLBAR_ORDER_STEP,
      type: 'page',
      class: 'btn-primary',
      caption: undefined,
      page: {num: 0, total: 0}
    };

    this._mnuBtnZoomIn = {
      groupId: PL_PDF_TOOLBAR_GROUP_ID,
      id: PL_PDF_TOOLBAR_ID_BTN_ZOOM_IN,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-search-plus"></i>',
      class: 'btn-primary',
      click: () => {
        this._zoomIn();
      }
    };

    this._mnuBtnZoomFit = {
      groupId: PL_PDF_TOOLBAR_GROUP_ID,
      id: PL_PDF_TOOLBAR_ID_BTN_ZOOM_FIT,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-arrows-h"></i>',
      class: 'btn-primary',
      visible: false,
      click: () => {
        this._zoom('page-width');
      }
    };

    this._mnuBtnZoomOut = {
      groupId: PL_PDF_TOOLBAR_GROUP_ID,
      id: PL_PDF_TOOLBAR_ID_BTN_ZOOM_OUT,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-search-minus"></i>',
      class: 'btn-primary',
      click: () => {
        this._zoomOut();
      }
    };

    this._groupZoom = {
      groupId: PL_PDF_TOOLBAR_GROUP_ID,
      id: PL_PDF_TOOLBAR_ID_GROUP_ZOOM,
      order: this._mnuPages.order + PL_PDF_TOOLBAR_ORDER_STEP,
      type: 'button-group',
      items: [this._mnuBtnZoomIn, this._mnuBtnZoomFit, this._mnuBtnZoomOut]
    };

    this._mnuZoom = {
      groupId: PL_PDF_TOOLBAR_GROUP_ID,
      id: PL_PDF_TOOLBAR_ID_MENU_ZOOM,
      order: this._groupZoom.order + PL_PDF_TOOLBAR_ORDER_STEP,
      type: 'dropdown',
      class: 'btn-primary',
      caption: undefined,
      menu: ZOOM_LEVELS.map<IPlToolbarMenuItem<void>>((zoomLevel: TPlPdfZoomLevel) => {
        const caption: string = isNumber(zoomLevel) ? this._evaluateZoomLevelCaption(zoomLevel) : undefined;
        return {
          id: String(zoomLevel),
          caption: caption,
          click: () => {
            this._zoom(zoomLevel);
          }
        };
      })
    };

    this._btnPresentationMode = {
      groupId: PL_PDF_TOOLBAR_GROUP_ID,
      id: PL_PDF_TOOLBAR_ID_BTN_PRESENTATION_MODE,
      order: this._mnuZoom.order + PL_PDF_TOOLBAR_ORDER_STEP,
      type: 'button',
      iconLeft: '<i class="fa fa-arrows-alt fa-fw"></i>',
      class: 'btn-primary',
      title: undefined,
      visible: fscreen.fullscreenEnabled,
      click: () => {
        this._switchToPresentationMode();
      }
    };

    this._btnDownload = {
      groupId: PL_PDF_TOOLBAR_GROUP_ID,
      id: PL_PDF_TOOLBAR_ID_BTN_DOWNLOAD,
      order: this._btnPresentationMode.order + PL_PDF_TOOLBAR_ORDER_STEP,
      type: 'download',
      iconLeft: '<i class="fa fa-download fa-fw"></i>',
      class: 'btn-primary',
      caption: undefined,
      download: {url: '', filename: undefined}
    };

    this._btnPrint = {
      groupId: PL_PDF_TOOLBAR_GROUP_ID,
      id: PL_PDF_TOOLBAR_ID_BTN_PRINT,
      order: this._btnDownload.order + PL_PDF_TOOLBAR_ORDER_STEP,
      type: 'button',
      iconLeft: '<i class="fa fa-print fa-fw"></i>',
      class: 'btn-primary',
      caption: undefined,
      click: () => {
        this._print();
      }
    };

    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      if (!locale) {
        return;
      }
      const firstTime = !this.locale;
      this.locale = locale.plPdf;
      this._mnuPages.caption = `${this.locale.pages}:`;
      this._btnDownload.caption = this.locale.download;
      this._btnPrint.caption = this.locale.print;
      if (isString(this.zoomLevel)) {
        this._evaluateZoomLevelCaption(this.zoomLevel);
      }
      for (const zoomLevel of ZOOM_LEVELS) {
        if (!isString(zoomLevel)) {
          continue;
        }
        const mnuZoomLevel = this._mnuZoom.menu.find((toolbarMenuItem: IPlToolbarMenuItem<void>) => toolbarMenuItem.id === zoomLevel);
        if (mnuZoomLevel) {
          mnuZoomLevel.caption = this._evaluateZoomLevelCaption(zoomLevel);
        }
      }
      if (!firstTime) {
        this._configureToolbar();
      }
    });

    this._subscriptionConfig = this._plCompsService.config().subscribe((config: IPlCompsServiceConfig) => {
      this._configurations = config;
    });

    this._disableToolbar();
  }

  public ngOnInit(): void {
    (<any>window).disableWorker = true;
    if (isObject((<any>window).PDFJS)) {
      (<any>window).PDFJS.disableWorker = true;
    }
    this._handleChanges();
  }

  public ngOnChanges({
    pdfSrc,
    errorMessage,
    externalLinkTarget,
    filename,
    page,
    renderText,
    renderTextMode,
    rotation,
    showAll,
    showBorders,
    stickToPage,
    toolbarInstanceId,
    zoomLevel,
    callback
  }: SimpleChanges): void {
    if (callback) {
      const cb: IPlPdfCallback = callback.currentValue;
      if (isObject(cb)) {
        cb.zoom = (zoom: TPlPdfZoomLevel) => {
          this._zoom(zoom);
        };
        cb.zoomIn = () => {
          this._zoomIn();
        };
        cb.zoomOut = () => {
          this._zoomOut();
        };
        cb.print = () => {
          this._print();
        };
        cb.refresh = (refetch: boolean) => this._refresh(refetch);
        cb.updateSize = () => {
          this._updateSize();
        };
      }
    }
    if (toolbarInstanceId && !toolbarInstanceId.isFirstChange()) {
      this._changedToolbarInstanceId(toolbarInstanceId.currentValue);
    }
    if (errorMessage && !errorMessage.isFirstChange()) {
      this._changedErrorMessage(errorMessage.currentValue);
    }
    if (externalLinkTarget && !externalLinkTarget.isFirstChange()) {
      this._changedExternalLinkTarget(externalLinkTarget.currentValue);
    }
    if (filename && !filename.isFirstChange()) {
      this._changedFilename(filename.currentValue);
    }
    if (page && !page.isFirstChange()) {
      this._changedPage(page.currentValue);
    }
    if (renderText && !renderText.isFirstChange()) {
      this._changedRenderText(renderText.currentValue);
    }
    if (renderTextMode && !renderTextMode.isFirstChange()) {
      this._changedRenderTextMode(renderTextMode.currentValue);
    }
    if (rotation && !rotation.isFirstChange()) {
      this._changedRotation(rotation.currentValue);
    }
    if (showAll && !showAll.isFirstChange()) {
      this._changedShowAll(showAll.currentValue);
    }
    if (showBorders && !showBorders.isFirstChange()) {
      this._changedShowBorders(showBorders.currentValue);
    }
    if (stickToPage && !stickToPage.isFirstChange()) {
      this._changedStickToPage(stickToPage.currentValue);
    }
    if (zoomLevel && !zoomLevel.isFirstChange()) {
      this._changedZoomLevel(zoomLevel.currentValue);
    }
    if (pdfSrc && !pdfSrc.isFirstChange()) {
      this._changedPdfSrc(pdfSrc.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
    this._subscriptionLocale.unsubscribe();
    this._subscriptionConfig.unsubscribe();
    this._clearSubscriptionToolbarPageChanged();
    this._clearObjectURL();
    this._clearOnFullscreenChange();
    if (this._toolbarInstance) {
      this._toolbarInstance.removeGroupId(PL_PDF_TOOLBAR_GROUP_ID);
    }
  }

  public afterLoadComplete(pdf: PDFDocumentProxy): void {
    if (this._pdfViewerComponent) {
      let firstTime = true;
      const pdfViewer: any = this._pdfViewerComponent.pdfViewer;
      if (pdfViewer) {
        this._zoom(this.zoomLevel);
        const self = this;
        const fnScrollIntoView = pdfViewer._scrollIntoView;
        pdfViewer._scrollIntoView = function (...args: Array<unknown>) {
          if (firstTime) {
            firstTime = false;
            return;
          }
          if (isFunction(fnScrollIntoView)) {
            try {
              fnScrollIntoView.apply(pdfViewer, args);
            } catch (error: unknown) {
              self._logger.error(error);
            }
          }
        };
      }
    }
    this.pdf = pdf;
    this._mnuPages.page.total = this.pdf.numPages;
    this.isLoading = false;
    this.hasError = false;
    this.renderNotSupported = false;
    this._enableToolbar();
    this.evtAfterLoadComplete.emit(pdf);
  }

  public pageRendered(event: IPlPdfEventPageRendered | CustomEvent<IPlPdfEventPageRendered>): void {
    const eventPageRendered: IPlPdfEventPageRendered = <IPlPdfEventPageRendered>event;
    if (!this.toolbarPage) {
      this.toolbarPage = 1;
    }
    this.evtPageRendered.emit(eventPageRendered);
  }

  public onError(error: unknown | HttpErrorResponse): void {
    this.isLoading = false;
    this._disableToolbar();
    if (error instanceof InvalidPDFException || (<{name: string}>error)?.name === 'InvalidPDFException' || (<{message: string}>error)?.message?.includes('Invalid PDF structure')) {
      this.hasError = false;
      this.renderNotSupported = true;
      this._btnDownload.disabled = false;
    } else {
      this.hasError = true;
      this._btnDownload.download.url = '';
      this._btnDownload.download.filename = undefined;
      Promise.resolve(this._plPdfErrorHandler.parseError(error))
        .then((message: string) => {
          this.localErrorMessage = message;
          this.hasError = !isEmpty(this.localErrorMessage);
          this.evtError.emit(error);
        })
        .catch((reason: unknown) => {
          this._logger.error(reason);
          this.evtError.emit(error);
        });
    }
  }

  public textLayerRendered(event: CustomEvent): void {
    this.evtTextLayerRendered.emit(event);
  }

  public onProgress(event: PDFProgressData): void {
    this.evtOnProgress.emit(event);
  }

  public get toolbarPage(): number {
    return this.page;
  }

  public set toolbarPage(page: number) {
    if (!page || page < 0) {
      page = this._mnuPages.page.total ? 1 : 0;
    } else if (page > this._mnuPages.page.total) {
      page = this._mnuPages.page.total;
    }
    this.page = page;
    this.pageChange.emit(this.page);
    this._mnuPages.page.num = this.page;
    this._mnuBtnPrevious.disabled = this.page === 0 || this.page === 1;
    this._mnuBtnNext.disabled = this.page === this._mnuPages.page.total;
  }

  private _handleChanges(): void {
    this._changedToolbarInstanceId();
    this._changedErrorMessage();
    this._changedExternalLinkTarget();
    this._changedFilename();
    this._changedPage();
    this._changedRenderText();
    this._changedRenderTextMode();
    this._changedRotation();
    this._changedShowAll();
    this._changedShowBorders();
    this._changedStickToPage();
    this._changedZoomLevel();
    this._changedPdfSrc();
  }

  private _changedPdfSrc(value: TPlPdfSource = this.pdfSrc): Promise<void> {
    const oldSource = this.src;
    this.hasError = false;
    this.renderNotSupported = false;
    return this._parseSrc(value).then((pdfSource: IPlPdfSource) => {
      this._filename = '';
      this._contentType = '';
      this._clearObjectURL();

      if (!pdfSource || (!pdfSource.url && !pdfSource.data && !pdfSource.range)) {
        this.src = undefined;
        return Promise.resolve();
      }

      this.isLoading = true;

      if (pdfSource !== oldSource) {
        this.pdf = undefined;
        this.page = 0;
        this._mnuPages.page.num = 0;
        this._mnuPages.page.total = 0;
        this._btnDownload.download.url = '';
        this._btnDownload.download.filename = undefined;
        this._disableToolbar();
        this.evtStatus.emit(false);
      }

      if (this._fetchDocumentTask) {
        this._fetchDocumentTask.unsubscribe();
      }

      let promise: Promise<void>;
      if (pdfSource.url) {
        const url = String(pdfSource.url);
        if (this._configurations.debug) {
          this.debugUrl = url;
        }
        promise = this._plPdfFetchService
          .fetchDocument(url)
          .then((response: HttpResponse<Uint8Array>) => {
            delete pdfSource.url;
            pdfSource.data = response.body || undefined;
            this._filename = this.filename || (pdfSource.data ? extractContentDispositionFilename(response) : undefined) || '';
            this._contentType = this.contentType || (pdfSource.data ? extractContentType(response) : undefined) || '';
          })
          .catch((reason: unknown) => {
            this.onError(reason);
            pdfSource = undefined;
          });
      } else {
        this._filename = this.filename || '';
        this._contentType = this.contentType || '';
        promise = Promise.resolve();
      }

      this._fetchDocumentTask = from(promise)
        .pipe(takeUntil(this._destroyed))
        .subscribe(() => {
          this.src = pdfSource;
          if (this._filename.toLowerCase().endsWith('.fr3')) {
            this._filename += '.pdf';
          }
          if (this.src?.data && (isTypedArray(this.src.data) || isArrayBuffer(this.src.data))) {
            const type: string = this._contentType || 'application/pdf';
            const pdfBlob: Blob = newFile(this.src.data, this._filename, {type: type});
            this._objectURL = URL.createObjectURL(pdfBlob);
          }
          this._btnDownload.download.filename = this._filename;
          const url = this._objectURL || this.src?.url;
          this._btnDownload.download.url = url ? <string>this._domSanitizer.bypassSecurityTrustUrl(this._objectURL) : undefined;
          const status: boolean = this._evaluateToolbarBtnDownloadAndPrint();
          this.evtStatus.emit(status);
        });

      return promise;
    });
  }

  private _changedErrorMessage(value: string = this.errorMessage): void {
    this.errorMessage = value || '';
  }

  private _changedExternalLinkTarget(value: string = this.externalLinkTarget): void {
    this.externalLinkTarget = value || 'blank';
  }

  private _changedFilename(value: string = this.filename): void {
    this.filename = value || '';
  }

  private _changedPage(value: number = this.page): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = 0;
    }
    this.toolbarPage = val;
  }

  private _changedRenderText(value: boolean = this.renderText): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = true;
    }
    this.renderText = val;
  }

  private _changedRenderTextMode(value: RenderTextMode = this.renderTextMode): void {
    let val: RenderTextMode = value;
    if (!isNumber(val)) {
      val = RenderTextMode.ENHANCED;
    }
    this.renderTextMode = val;
  }

  private _changedRotation(value: number = this.rotation): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = 0;
    }
    this.rotation = val;
  }

  private _changedShowAll(value: boolean = this.showAll): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = false;
    }
    this.showAll = val;
  }

  private _changedShowBorders(value: boolean = this.showBorders): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = false;
    }
    this.showBorders = val;
  }

  private _changedStickToPage(value: boolean = this.stickToPage): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.showAll;
    }
    this.stickToPage = val;
  }

  private _changedToolbarInstanceId(value: string = this.toolbarInstanceId): void {
    this.toolbarInstanceId = value;
    this._setupToolbar();
  }

  private _changedZoomLevel(value: TPlPdfZoomLevel = this.zoomLevel): void {
    let val: TPlPdfZoomLevel = value;
    if ((!isString(val) && !isNumber(val)) || !val) {
      val = 'page-width';
    }
    if (val !== this.zoomLevel) {
      this._zoom(val);
    }
  }

  private _setupToolbar(): void {
    this._clearSubscriptionToolbarPageChanged();
    if (this._toolbarInstance) {
      this._toolbarInstance.removeGroupId(PL_PDF_TOOLBAR_GROUP_ID);
    }
    if (!this.toolbarInstanceId) {
      return;
    }
    this._toolbarInstance = this._plToolbarService.getInstance(this.toolbarInstanceId);
    if (!this._toolbarInstance) {
      return;
    }

    this._subscriptionToolbarPageChanged = this._toolbarInstance.onPageChanged().subscribe((pageNumber: number) => {
      this.toolbarPage = pageNumber;
    });

    if (this.pdf) {
      if (!this._mnuPages.page.num) {
        this._mnuPages.page.num = this.pdf.numPages ? 1 : 0;
      }
      if (!this._mnuPages.page.total) {
        this._mnuPages.page.total = this.pdf.numPages;
      }
    }

    this._toolbarInstance
      .removeGroupId(PL_PDF_TOOLBAR_GROUP_ID)
      .addButton(this._mnuNav)
      .addButton(this._mnuPages)
      .addButton(this._mnuZoom)
      .addButton(this._groupZoom)
      .addButton(this._btnPresentationMode)
      .addButton(this._btnDownload)
      .addButton(this._btnPrint);
    this._configureToolbar();
  }

  private _configureToolbar(): void {
    this.evtConfigureToolbar.emit({
      toolbarInstance: this._toolbarInstance,
      mnuBtnPrevious: this._mnuBtnPrevious,
      mnuBtnNext: this._mnuBtnNext,
      mnuNav: this._mnuNav,
      mnuPages: this._mnuPages,
      mnuZoom: this._mnuZoom,
      mnuBtnZoomIn: this._mnuBtnZoomIn,
      mnuBtnZoomFit: this._mnuBtnZoomFit,
      mnuBtnZoomOut: this._mnuBtnZoomOut,
      groupZoom: this._groupZoom,
      btnPresentationMode: this._btnPresentationMode,
      btnDownload: this._btnDownload,
      btnPrint: this._btnPrint
    });
  }

  private _disableToolbar(): void {
    this._mnuBtnPrevious.disabled = true;
    this._mnuBtnNext.disabled = true;
    this._mnuPages.disabled = true;
    this._mnuZoom.disabled = true;
    this._mnuBtnZoomIn.disabled = true;
    this._mnuBtnZoomFit.disabled = true;
    this._mnuBtnZoomOut.disabled = true;
    this._btnPresentationMode.disabled = true;
    this._btnDownload.disabled = true;
    this._btnPrint.disabled = true;
  }

  private _enableToolbar(): void {
    this._mnuBtnPrevious.disabled = false;
    this._mnuBtnNext.disabled = false;
    this._mnuPages.disabled = false;
    this._mnuZoom.disabled = false;
    this._mnuBtnZoomIn.disabled = false;
    this._mnuBtnZoomFit.disabled = false;
    this._mnuBtnZoomOut.disabled = false;
    this._btnPresentationMode.disabled = false;
    this._evaluateToolbarBtnDownloadAndPrint();
  }

  private _evaluateToolbarBtnDownloadAndPrint(): boolean {
    const status = Boolean(this._btnDownload.download.url);
    this._btnDownload.disabled = !status;
    this._btnPrint.disabled = this._btnDownload.disabled;
    return status;
  }

  private _goPrevious(): void {
    this.toolbarPage--;
  }

  private _goNext(): void {
    this.toolbarPage++;
  }

  private _zoom(zoomLevel: TPlPdfZoomLevel): void {
    this._mnuZoom.caption = this._evaluateZoomLevelCaption(zoomLevel);
    if (this._pdfViewerComponent?.pdfViewer) {
      this._pdfViewerComponent.pdfViewer.currentScaleValue = String(zoomLevel);
    }
    if (zoomLevel !== this.zoomLevel) {
      this.zoomLevel = zoomLevel;
      this.zoom = this.zoomLevel;
      this.zoomLevelChange.emit(this.zoomLevel);
    }
  }

  private _zoomIn(): void {
    const zoom: number = this._pdfViewerCurrentScale() ?? this.zoom;
    const zoomLevel: number = this._getClosestMultipleOf((zoom + STEP_ZOOM) * 100, STEP_ZOOM * 100, true) / 100;
    this._zoom(zoomLevel);
  }

  private _zoomOut(): void {
    const zoom: number = this._pdfViewerCurrentScale() ?? this.zoom;
    const zoomLevel: number = this._getClosestMultipleOf((zoom - STEP_ZOOM) * 100, STEP_ZOOM * 100, false) / 100;
    if (zoomLevel > 0) {
      this._zoom(zoomLevel);
    }
  }

  private _switchToPresentationMode(): void {
    const element: HTMLElement = this._pdfViewerElement?.nativeElement;
    if (!fscreen.fullscreenEnabled || fscreen.fullscreenElement || !element) {
      return;
    }
    this._clearOnFullscreenChange();
    this._originalZoomLevel = this.zoomLevel;
    this._zoom('page-actual');
    fscreen.addEventListener('fullscreenchange', this._fnOnFullscreenChange, {passive: true});
    fscreen.addEventListener('fullscreenerror', this._fnOnFullscreenError, {passive: true});
    fscreen.requestFullscreen(element);
  }

  private _print(): void {
    if (!isObject(this.src)) {
      return;
    }
    const url: string = !isEmpty(this.src.url) ? String(this.src.url) : this._objectURL ? this._objectURL : undefined;
    if (!url) {
      return;
    }
    const iframeElement: HTMLIFrameElement = window.document.createElement<'iframe'>('iframe');
    let closedIFrame = false;
    let calledFallbackMethod = false;
    const closeIFrame = (): void => {
      if (!closedIFrame) {
        window.document.body.removeChild(iframeElement);
        closedIFrame = true;
      }
    };
    const filename: string = this._filename;
    const fallbackMethod = (): void => {
      if (calledFallbackMethod) {
        return;
      }
      calledFallbackMethod = true;
      if (!closedIFrame) {
        closeIFrame();
      }
      const newWindow = window.open(url, undefined, 'noopener,noreferrer');
      // This method only works in production mode
      newWindow.onload = function () {
        newWindow.document.title = filename;
        newWindow.print();
      };
    };
    try {
      iframeElement.onload = () => {
        try {
          iframeElement.contentWindow.onbeforeunload = closeIFrame;
          iframeElement.contentWindow.onafterprint = closeIFrame;
          iframeElement.contentWindow.focus();
          iframeElement.contentDocument.title = filename;
          iframeElement.contentWindow.print();
        } catch (exception: unknown) {
          this._logger.error(exception);
          fallbackMethod();
        }
      };
      iframeElement.classList.add('invisible');
      iframeElement.src = url;
      window.document.body.appendChild(iframeElement);
    } catch (exception: unknown) {
      this._logger.error(exception);
      fallbackMethod();
    }
  }

  private _clearSubscriptionToolbarPageChanged(): void {
    if (!this._subscriptionToolbarPageChanged) {
      return;
    }
    this._subscriptionToolbarPageChanged.unsubscribe();
    this._subscriptionToolbarPageChanged = undefined;
  }

  private _clearObjectURL(): void {
    if (this._objectURL) {
      URL.revokeObjectURL(this._objectURL);
      this._objectURL = undefined;
    }
  }

  private _parseSrc(value: TPlPdfSource): Promise<IPlPdfSource> {
    if (isUndefinedOrNull(value)) {
      return Promise.resolve(undefined);
    }
    const pdfSource: IPlPdfSource = {withCredentials: this._plPdfOptionsService.withCredentials};
    if (isString(value)) {
      if (value.length) {
        pdfSource.url = value;
        return Promise.resolve(pdfSource);
      }
    } else if (isTypedArray(value)) {
      pdfSource.data = value;
      return Promise.resolve(pdfSource);
    } else if (isArrayBuffer(value)) {
      pdfSource.data = new Uint8Array(value);
      return Promise.resolve(pdfSource);
    } else if (isFile(value) || isBlob(value)) {
      if (isFile(value) && !this.filename) {
        this.filename = value.name;
      }
      const fileReader: PlFileReader = new PlFileReader(value);
      return fileReader.readAsArrayBuffer().then((valueAsArrayBuffer: ArrayBuffer) => {
        pdfSource.data = new Uint8Array(valueAsArrayBuffer);
        return pdfSource;
      });
    } else if (value instanceof PDFDataRangeTransport) {
      pdfSource.range = value;
      return Promise.resolve(pdfSource);
    }
    return Promise.resolve(undefined);
  }

  private _refresh(refetch: boolean = false): Promise<void> {
    const src: IPlPdfSource = this.src;
    this.src = undefined;
    return timeout().then((): Promise<void> => {
      if (!refetch) {
        this.src = src;
        return Promise.resolve();
      }
      return this._changedPdfSrc(this.pdfSrc).catch((reason: unknown) => {
        this._logger.error(reason);
        this.isLoading = false;
      });
    });
  }

  private _evaluateZoomLevelCaption(zoomLevel: TPlPdfZoomLevel): string {
    if (isString(zoomLevel)) {
      switch (zoomLevel) {
        case 'auto':
          return this.locale.zoomAuto;
        case 'page-actual':
        case 'page-height':
          return this.locale.zoomPageActual;
        case 'page-fit':
          return this.locale.zoomPageFit;
        case 'page-width':
          return this.locale.zoomPageWidth;
      }
    } else if (isNumber(zoomLevel)) {
      return `${zoomLevel * 100}%`;
    }
    return '';
  }

  private _getClosestMultipleOf(value: number, step: number, upward: boolean): number {
    if (value % step === 0) {
      return value;
    }
    value = upward ? Math.ceil(value) : Math.floor(value);
    while (value % step !== 0) {
      if (upward) {
        value++;
      } else {
        value--;
      }
    }
    return value;
  }

  private _pdfViewerCurrentScale(): number {
    return this._pdfViewerComponent?.pdfViewer?.currentScale;
  }

  private _updateSize(): void {
    this._zoom(this.zoomLevel ?? 'page-width');
  }

  private _onFullscreenChange(): void {
    // Exited fullscreen
    if (!fscreen.fullscreenElement) {
      this._zoom(this._originalZoomLevel);
      this._originalZoomLevel = undefined;
      this._clearOnFullscreenChange();
    }
  }

  private _onFullscreenError(reason: unknown): void {
    this._logger.error(reason);
    this._zoom(this._originalZoomLevel);
    this._originalZoomLevel = undefined;
  }

  private _clearOnFullscreenChange(): void {
    fscreen.removeEventListener('fullscreenchange', this._fnOnFullscreenChange);
    fscreen.removeEventListener('fullscreenerror', this._fnOnFullscreenError);
  }

  private readonly _fnOnFullscreenChange = (): void => {
    this._onFullscreenChange();
  };

  private readonly _fnOnFullscreenError = (reason: unknown): void => {
    this._onFullscreenError(reason);
  };
}
