<pl-form class="entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()" [disabled]="closeDisabled"></button>
    <h4 class="modal-title" [translate]="'movimentosaberto.label.movimentosEmAberto'" [translateParams]="{tipo: tipo}"></h4>
  </div>

  <div class="modal-body">
    <pl-group plAutoFocus>
      <label [translate]="'components.contabilidade.listagens.title'"></label>
      <edit>
        <pl-edit type="reports_movimentosemaberto" attrName="listagem" [model]="reportModel" (modelChange)="onReportChange($event)"></pl-edit>
      </edit>
    </pl-group>
    <pl-group *ngIf="showNconta">
      <label>Conta {{ tipo }}:</label>
      <edit>
        <entity-autocomplete entity="pocs" [model]="model" (modelChange)="onNContaChange($event)" [fieldsMap]="{nome: 'nomeConta'}" [filter]="filtro" outputKey="nConta" [output]="pocOutput">
        </entity-autocomplete>
      </edit>
    </pl-group>
    <br />

    <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
  </div>
</pl-form>
