<pl-form class="rend-habit-perm-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'rendhabitperm.modal.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <pl-group>
        <label [translate]="'rendhabitperm.modal.fields.tipoProcessamento'"></label>
        <edit>
          <entity-autocomplete
            entity="rhtipoprocess"
            attrName="tipoProcessamento"
            [model]="rendHabitPerm"
            (selectedKeyChange)="rendHabitPerm.tipoProcessamento = $event"
            (evtSelectedDescriptionChanged)="rendHabitPerm.tipoProcessamentoStr = $event"
            [fieldsMap]="{tipoProcessamento: 'tipoProcessamento', descricao: 'tipoProcessamentoStr'}"
            [properties]="{validators: {required: {value: true}}}"
            [output]="rhtipoprocessOutput"
            plAutoFocus>
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'rendhabitperm.modal.fields.codAbdes'"></label>
        <edit>
          <entity-autocomplete
            entity="abonos"
            attrName="codAbdes"
            [model]="rendHabitPerm"
            (selectedKeyChange)="rendHabitPerm.codAbdes = $event"
            (evtSelectedDescriptionChanged)="rendHabitPerm.abdesNome = $event"
            [filter]="filterAbonoCategOutput"
            [fieldsMap]="{codAbDesc: 'codAbdes', designacaoBreve: 'abdesNome'}"
            [properties]="{validators: {required: {value: true}}}">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <label [translate]="'rendhabitperm.modal.fields.codEmp'"></label>
      <edit>
        <entity-autocomplete
          entity="dgempsfull"
          attrName="codEmp"
          [model]="rendHabitPerm"
          (selectedKeyChange)="rendHabitPerm.codEmp = $event"
          (evtSelectedDescriptionChanged)="rendHabitPerm.nome = $event"
          [properties]="{validators: {required: {value: true}}}"
          [filter]="'ativo=true'"
          output="nome">
        </entity-autocomplete>
      </edit>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'rendhabitperm.modal.fields.anoProcessamento'"></label>
        <edit>
          <pl-edit
            type="cginteger"
            attrName="anoProcessamento"
            [model]="rendHabitPerm.anoProcessamento"
            (modelChange)="rendHabitPerm.anoProcessamento = $event"
            [properties]="{validators: {required: {value: true}}}">
          </pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'rendhabitperm.modal.fields.mesInicioProce'"></label>
        <edit>
          <pl-edit type="meses" attrName="mesInicioProce" [model]="rendHabitPerm.mesInicioProce" (modelChange)="rendHabitPerm.mesInicioProce = $event"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'rendhabitperm.modal.fields.suspende'"></label>
        <edit>
          <pl-edit type="boolean" attrName="suspende" [model]="rendHabitPerm.suspende" (modelChange)="suspendeChanged($event)"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'rendhabitperm.modal.fields.dataSuspensao'"></label>
        <edit>
          <pl-edit type="date" attrName="dataSuspensao" [(model)]="rendHabitPerm.dataSuspensao" [properties]="{disabled: !rendHabitPerm.suspende}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'rendhabitperm.modal.fields.obsSupencao'"></label>
        <edit>
          <pl-edit type="text" attrName="obsSupencao" [(model)]="rendHabitPerm.obsSuspensao" [properties]="{disabled: !rendHabitPerm.suspende, validators: {maxlength: {value: 100}}}"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <div style="padding-top: 10px">
      <div class="card">
        <div class="card-header inline-block">
          <span [translate]="'rendhabitperm.modal.fields.valorRenda'"></span>
        </div>
        <div class="card-body">
          <pl-group>
            <label [translate]="'rendhabitperm.modal.fields.valor'"></label>
            <edit>
              <pl-edit type="number" attrName="valorRenda" [(model)]="rendHabitPerm.valorRenda" [properties]="{validators: {required: {value: true}}}"></pl-edit>
            </edit>
          </pl-group>
        </div>
      </div>
    </div>

    <div style="padding-top: 10px">
      <div class="card">
        <div class="card-header inline-block">
          <span [translate]="'rendhabitperm.modal.fields.valorRendaMaxMuni'"></span>
        </div>
        <div class="card-body">
          <pl-group>
            <pl-group>
              <label [translate]="'rendhabitperm.modal.fields.valor'"></label>
              <edit>
                <pl-edit type="number" attrName="valorRendaMaxMuni" [(model)]="rendHabitPerm.valorRendaMaxMuni" [properties]="{validators: {required: {value: true}}}"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <pl-alert type="info" [closeable]="false">
                <span [innerHTML]="'rendhabitperm.modal.message.msgRendaMaxMuni' | translate"></span>
              </pl-alert>
            </pl-group>
          </pl-group>
        </div>
      </div>
    </div>

    <pl-group>
      <label [translate]="'rendhabitperm.modal.fields.obs'"></label>
      <edit>
        <pl-edit type="text" attrName="observacoes" [(model)]="rendHabitPerm.observacoes" [properties]="{validators: {maxlength: {value: 100}}}"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</pl-form>
