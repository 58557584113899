import {Injectable} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IPlToolbarItem} from 'pl-comps-angular';
import {CGModalService} from '../cg/modal/cgmodal.service';
import {EGenericImporterType, IGenericImporterExecutor} from './generic.importer.component.interface';
import {ENTITY_NAME_ARTIGOS, IArtigosEntityService} from '../../entities/artigos/artigos.entity.interface';
import {ENTITY_NAME_CLIENTES, ENTITY_NAME_FORNECEDORES, ENTITY_NAME_OUTROS_DEVEDORES_CREDORES, IClifosEntityService} from '../../entities/clifos/clifos.entity.interface';
import {ENTITY_NAME_DGEMPS, IDGEMPSEntityService} from '../../entities/dgemps/dgemps.entity.interface';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {GenericImporterModalComponent} from './modal/generic.importer.modal.component';
import {ICGModalOptions} from '../cg/modal/cgmodal.interface';
import {IJsonArtigo} from '../../entities/artigos/jsonArtigo.entity.interface';
import {IRefreshList} from '../../../common/interfaces/tables.interface';
import {IJsonDGEMP} from '../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonClifo} from '../../entities/clifos/jsonClifo.entity.interface';
import {PortalClientAccountService} from '../../services/portalclientaccount/portalclientaccount.service';

export const TOOLBAR_BUTTON_ORDER = 101;

@Injectable({
  providedIn: 'root'
})
export class GenericImporterService {
  private readonly _services: Map<EGenericImporterType, IGenericImporterExecutor>;

  constructor(
    private readonly _cgModalService: CGModalService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _portalclientaccountsFactory: PortalClientAccountService
  ) {
    this._services = new Map<EGenericImporterType, IGenericImporterExecutor>();
    this._services.set(EGenericImporterType.Artigos, this._entityServiceBuilder.build<IJsonArtigo, IArtigosEntityService>(ENTITY_NAME_ARTIGOS));
    this._services.set(EGenericImporterType.Clientes, this._entityServiceBuilder.build<IJsonClifo, IClifosEntityService>(ENTITY_NAME_CLIENTES));
    this._services.set(EGenericImporterType.Fornecedores, this._entityServiceBuilder.build<IJsonClifo, IClifosEntityService>(ENTITY_NAME_FORNECEDORES));
    this._services.set(EGenericImporterType.OutrosDevedoresCredores, this._entityServiceBuilder.build<IJsonClifo, IClifosEntityService>(ENTITY_NAME_OUTROS_DEVEDORES_CREDORES));
    this._services.set(EGenericImporterType.Dgemps, this._entityServiceBuilder.build<IJsonDGEMP, IDGEMPSEntityService>(ENTITY_NAME_DGEMPS));
    this._services.set(EGenericImporterType.CCClientes, this._portalclientaccountsFactory);
    this._services.set(EGenericImporterType.CCFornecedores, this._portalclientaccountsFactory);
  }

  public getExecutor(type: EGenericImporterType): IGenericImporterExecutor {
    return this._services.get(type);
  }

  public toolbarButton(type: EGenericImporterType): IPlToolbarItem {
    return {
      id: 'genericImporter',
      type: 'button',
      order: TOOLBAR_BUTTON_ORDER,
      caption: 'components.genericImporter.actions.import',
      iconLeft: '<i class="fa fa-fw fa-download"></i>',
      class: 'btn-info',
      click: () => this.modal(type)
    };
  }

  public moduleToolbarButton<T = unknown>(type: EGenericImporterType, module: IRefreshList<T>): IPlToolbarItem {
    const btn: IPlToolbarItem = this.toolbarButton(type);
    const fnClick = btn.click.bind(this);
    btn.click = (item: IPlToolbarItem, event: MouseEvent | KeyboardEvent) => Promise.resolve(fnClick(item, event)).then(() => module.refreshList());
    return btn;
  }

  public modal(type: EGenericImporterType, options?: ICGModalOptions<void>): Promise<void> {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(GenericImporterModalComponent, {size: 'lg', ...options});
    const componentInstance: GenericImporterModalComponent = modalInstance.componentInstance;
    componentInstance.type = type;
    return modalInstance.result;
  }
}
