<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'gto.alertsModalTitle'"></h4>
</div>

<div class="modal-body">
  <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="gtoAlerts" [dataSource]="dataGridDefinition.dataSource">
    <div *dxTemplate="let item of 'detailTemplate'">
      <dx-data-grid [cgDxDataGrid]="dataGridDefinitionSubTable" [dataSource]="item.data.items"> </dx-data-grid>
    </div>
  </dx-data-grid>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
