import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IInfoDiferimentos, IInfoDiferimentosDetalhes, IInfoDiferimentosTotais} from '../../diferimentos.module.interface';
import {IDevExpressDataGridEventOnCellClick} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {HttpResponse} from '@angular/common/http';
import {IJsonDocContabilidade} from '../../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService} from '../../../portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';

@Component({
  selector: 'calculo-diferimentos-info-modal',
  templateUrl: './calculoDiferimentos.info.modal.component.html'
})
export class CalculoDiferimentosInfoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public infoDiferimentos: IInfoDiferimentos;

  public readonly dataGridDefinitionDetalhes: IDevExpressDataGrid;

  public dataGridDefinitionTotais: IDevExpressDataGrid;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this.dataGridDefinitionDetalhes = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'ano', dataType: 'string', caption: 'calculodiferimentos.modals.info.fields.ano'},
        {dataField: 'mesStr', dataType: 'string', caption: 'calculodiferimentos.modals.info.fields.mesStr'},
        {dataField: 'nDocumento', dataType: 'string', caption: 'calculodiferimentos.modals.info.fields.nDocumento'},
        {dataField: 'nConta', dataType: 'string', caption: 'calculodiferimentos.modals.info.fields.nConta'},
        {dataField: 'nomeConta', dataType: 'string', caption: 'calculodiferimentos.modals.info.fields.nomeConta'},
        {dataField: 'valorDebito', dataType: 'double', caption: 'calculodiferimentos.modals.info.fields.valorDebito', format: {suppressZeros: true}},
        {dataField: 'valorCredito', dataType: 'double', caption: 'calculodiferimentos.modals.info.fields.valorCredito', format: {suppressZeros: true}},
        {dataField: 'descricao', dataType: 'string', caption: 'calculodiferimentos.modals.info.fields.descricao'}
      ],
      dataSource: [],
      export: {filename: 'calculodiferimentos.modals.info.exportDetalhes'},
      masterDetail: {enabled: true, template: 'masterDetailTemplateDetalhes'},
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    this.dataGridDefinitionTotais = {
      columnHidingEnabled: false,
      columnChooser: {enabled: false},
      columns: [
        {dataField: 'ano', dataType: 'string', caption: 'calculodiferimentos.modals.info.fields.ano'},
        {dataField: 'mesStr', dataType: 'string', caption: 'calculodiferimentos.modals.info.fields.mesStr'},
        {dataField: 'valor', dataType: 'double', caption: 'calculodiferimentos.modals.info.fields.valor', format: {suppressZeros: true}},
        {
          dataField: 'valorAcrescimoNoMes',
          dataType: 'double',
          caption: 'calculodiferimentos.modals.info.fields.valorAcrescimoNoMes',
          visible: this.infoDiferimentos.temAcrescimo && this.infoDiferimentos.temDiferimento,
          format: {suppressZeros: true}
        },
        {
          dataField: 'valorDiferimentoNoMes',
          dataType: 'double',
          caption: 'calculodiferimentos.modals.info.fields.valorDiferimentoNoMes',
          visible: this.infoDiferimentos.temAcrescimo && this.infoDiferimentos.temDiferimento,
          format: {suppressZeros: true}
        },
        {
          dataField: 'saldoAcrescimoNoMes',
          dataType: 'double',
          caption: !this.infoDiferimentos.temDiferimento ? 'calculodiferimentos.modals.info.fields.saldo' : 'calculodiferimentos.modals.info.fields.saldoAcrescimoNoMes',
          visible: !this.infoDiferimentos.temDiferimento || (this.infoDiferimentos.temAcrescimo && this.infoDiferimentos.temDiferimento),
          format: {suppressZeros: true}
        },
        {
          dataField: 'saldoDiferimentoNoMes',
          dataType: 'double',
          caption: !this.infoDiferimentos.temAcrescimo ? 'calculodiferimentos.modals.info.fields.saldo' : 'calculodiferimentos.modals.info.fields.saldoDiferimentoNoMes',
          visible: !this.infoDiferimentos.temAcrescimo || (this.infoDiferimentos.temAcrescimo && this.infoDiferimentos.temDiferimento),
          format: {suppressZeros: true}
        }
      ],
      export: {filename: 'calculodiferimentos.modals.info.exportTotais'},
      masterDetail: {enabled: true, template: 'masterDetailTemplateTotais'},
      remoteOperations: false,
      summary: {
        totalItems: [
          {column: 'valor', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'valorAcrescimoNoMes', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'valorDiferimentoNoMes', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'}
        ]
      }
    };
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IInfoDiferimentosTotais | IInfoDiferimentosDetalhes>): void {
    devExpressDataGridExpandDetailHandler(event, () => {
      if (!event.data._docContabilidade) {
        return this._serviceDocsContabilidade.get({id: event.data.extPocCabID}).then((response: HttpResponse<IJsonDocContabilidade>) => {
          event.data._docContabilidade = response.body;
        });
      }
      return Promise.resolve();
    }).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }
}
