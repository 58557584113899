<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="title"></h5>
</div>

<div class="modal-body datavalor">
  <pl-form [model]="model" [definition]="formDefinition" [template]="formTemplate"></pl-form>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary action-close" (evtClicked)="close()"> <i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="captionSave"></span> </pl-button>

  <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
</div>
