<div class="rhgestaoficheirosdmrat">
  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="gestaoFicheirosDMRAT"
    [dataSource]="dataGridDefinition.dataSource"
    [selectedRowKeys]="selectedRowKeys"
    (selectedRowKeysChange)="selectedRowKeys = $event; selectedRowKeysChange()"
    (onInitialized)="onInitialized($event)">
    <div *dxTemplate="let item of 'toolbarTemplatePesquisa'">
      <div class="table-toolbar">
        <div class="table-toolbar-btn">
          <label [translate]="'rhgestaoficheirosdmrat.header.ano'"></label>
          <pl-edit type="select" [properties]="{select: {list: yearsSource}, validators: {required: {value: true}}, disallowClear: true}" [(model)]="year"></pl-edit>
        </div>

        <div class="table-toolbar-btn">
          <label [translate]="'rhgestaoficheirosdmrat.header.mes'"></label>
          <pl-edit type="select" [properties]="{select: {list: mesesSource}, validators: {required: {value: true}}, disallowClear: true}" [(model)]="month"></pl-edit>
          <pl-button type="button" klass="btn-primary btn-sm" (evtClicked)="pesquisaSSDecRemunList()"> <i class="fa fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span> </pl-button>
        </div>
      </div>
    </div>

    <div *dxTemplate="let item of 'cellTemplateInfo'">
      <pl-button type="button" klass="btn btn-info btn-xs" (evtClicked)="btnDadosInfoClick(item.data)">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
      </pl-button>
    </div>

    <div *dxTemplate="let item of 'cellTemplateErros'">
      <i class="fa fa-circle" [ngClass]="{'text-danger': item.data.infoErro !== 0, 'text-success': item.data.infoErro === 0}" aria-hidden="true"></i>
    </div>

    <div *dxTemplate="let item of 'cellTemplateDocPagamento'">
      <pl-button
        *ngIf="item.data.docPag"
        type="button"
        klass="btn btn-xs"
        [ngClass]="{
          'btn-secondary': item.data.docPag === '0',
          'btn-danger': item.data.docPag === '1',
          'btn-success': item.data.docPag === '2'
        }"
        [onClick]="fnDocPagamento(item.data)"
        [properties]="{disabled: item.data.idFicheiro === '' || item.data.idFicheiro === '0'}">
        <i class="fa fa-file-text-o" aria-hidden="true"></i>
      </pl-button>
    </div>

    <div *dxTemplate="let item of 'cellTemplateComprovativo'">
      <pl-button type="button" klass="btn btn-secondary btn-xs" [onClick]="fnComprovativo(item.data)" [properties]="{disabled: item.data.estadoFicheiro !== estadoFicheiro.SSEF_EnviadoAceite}">
        <i class="fa fa-file-text-o" aria-hidden="true"></i>
      </pl-button>
    </div>

    <div *dxTemplate="let item of 'editCellTemplateEmail'">
      <div class="email-column">
        <pl-button type="button" klass="btn btn-secondary btn-xs me-2" [onClick]="fnEnviarEmail(item.data)">
          <i class="fa fa-envelope" aria-hidden="true"></i>
        </pl-button>
        <pl-edit-text [(model)]="item.data.email" [properties]="{modelOptions: {updateOn: 'blur'}}" [attr.title]="'rhgestaoficheirosdmrat.emaildest'"> </pl-edit-text>
      </div>
    </div>
  </dx-data-grid>
</div>
