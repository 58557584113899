import {EArquivoDigitalGestaoDocsDigitalizadosTypeAction, IDocDigitalizado} from '../../../../modules/contabilidadedigital/documentosdigitais/contabDigital.documentosDigitais.module.interface';

export enum EGestaoDocumentosDigitalizadosMode {
  Contabilidade,
  Comercial,
  Conciliacao
}

export interface IArquivoDigitalGestaoDocumentosDigitalizadosEvtCalledAction {
  action: EArquivoDigitalGestaoDocsDigitalizadosTypeAction;
  selectedLine: IDocDigitalizado;
  selectedLines: Array<IDocDigitalizado>;
}
