<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="'users.steps.portaisApi'"></h5>
</div>

<div class="modal-body">
  <div>
    <pl-multiselect
      [source]="roles"
      [model]="rolesSelected"
      template="description"
      key="role"
      (evtSelectedRight)="selectedRole($event.selected)"
      (evtSelectedLeft)="removeRole($event.selected)"
      [properties]="{leftCaption: 'components.multiselect.available', rightCaption: 'components.multiselect.selected'}">
    </pl-multiselect>
  </div>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary action-save" [disabled]="rolesSelected.length < 1" (evtClicked)="close(rolesSelected)">
    <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span>
  </pl-button>

  <pl-button klass="btn-light action-close" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
</div>
