import {Subscription} from 'rxjs';
import {Component, Injector, Input, OnDestroy} from '@angular/core';
import {EMouseEventButton} from 'pl-comps-angular';
import {EEntityMaintenanceListModalResult} from '../../entity/entity.maintenance.interface';
import {EEntityStateListQueryParam, entityStateListQueryParam} from '../../../../../../common/utils/entity.state.utils';
import {EntityMaintenanceModalComponent} from '../entity.maintenance.modal.component';
import {IModuleEntityListOnDataGridCellClick} from '../../../../module/entitylist/module.entitylist.interface';
import {ModuloEntityListComponent} from '../../../../module/entitylist/module.entitylist.component';

@Component({
  selector: 'entity-maintenance-list-modal',
  templateUrl: './entity.maintenance.list.modal.component.html'
})
export class EntityMaintenanceListModalComponent<T extends object, S extends ModuloEntityListComponent<T> = ModuloEntityListComponent<T>>
  extends EntityMaintenanceModalComponent<T | EEntityMaintenanceListModalResult, S>
  implements OnDestroy
{
  @Input() public filter: string;
  @Input() public initialFilter: string;

  private _subscriptionOnClose: Subscription;
  private _subscriptionOnDataGridCellClick: Subscription;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._clearSubscription();
  }

  public close(result?: T | EEntityMaintenanceListModalResult): void {
    super.close(result || EEntityMaintenanceListModalResult.UNDEFINED);
  }

  protected _transitionParams(): object {
    const params: object = super._transitionParams() || {};
    if (this.filter) {
      const paramFilter: string = entityStateListQueryParam(this.entityMaintenanceInstance.entityName, EEntityStateListQueryParam.Filter);
      params[paramFilter] = this.filter;
    }
    if (this.initialFilter) {
      const paramInitialFilter: string = entityStateListQueryParam(this.entityMaintenanceInstance.entityName, EEntityStateListQueryParam.InitialFilter);
      params[paramInitialFilter] = this.initialFilter;
    }
    return params;
  }

  protected _buildTemplate(resolvedValues: Map<string, unknown>): void {
    super._buildTemplate(resolvedValues);
    this._componentRefInstance.setDisableNavigation(true);
  }

  protected _configToolbar(): void {
    super._configToolbar();
    this._componentRefInstance.btnRefresh.visible = true;
    this._componentRefInstance.btnNovo.visible = this.entityMaintenanceInstance.entity.actions.new;
    this._componentRefInstance.toolSearch.visible = this.entityMaintenanceInstance.entity.actions.search;
    if (this._componentRefInstance.btnNovo.visible) {
      this._componentRefInstance.btnNovo.click = () => {
        this._new();
      };
    }
  }

  protected _observe(): void {
    this._clearSubscription();
    this._subscriptionOnClose = this._componentRefInstance.evtOnCloseMaintenance().subscribe((value: unknown) => {
      this._subscriptionOnClose.unsubscribe();
      this._subscriptionOnClose = undefined;
      this.close(<T>value);
    });
    this._subscriptionOnDataGridCellClick = this._componentRefInstance.evtOnDataGridCellDblClick().subscribe(({data, event, rowType, column}: IModuleEntityListOnDataGridCellClick<T>) => {
      if (!event.defaultPrevented && rowType === 'data' && (!column || !column.type) && (<MouseEvent>event).button === EMouseEventButton.Main) {
        event.preventDefault();
        this._subscriptionOnDataGridCellClick.unsubscribe();
        this._subscriptionOnDataGridCellClick = undefined;
        this.close(data);
      }
    });
  }

  protected _clearSubscription(): void {
    if (this._subscriptionOnClose) {
      this._subscriptionOnClose.unsubscribe();
      this._subscriptionOnClose = undefined;
    }
    if (this._subscriptionOnDataGridCellClick) {
      this._subscriptionOnDataGridCellClick.unsubscribe();
      this._subscriptionOnDataGridCellClick = undefined;
    }
  }

  private _new(): void {
    this.close(EEntityMaintenanceListModalResult.ACTION_NEW);
  }
}
