<div class="reembolsosiva">
  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form>
        <pl-group>
          <label [translate]="'reembolsosiva.fields.periodoRelacao'"></label>
          <edit>
            <div [plPromise]="promise">
              <pl-edit-select attrName="periodoRelacao" [source]="selectOptions" [model]="periodoRelacao" (modelChange)="changedPeriodoRelacao($event)" [properties]="{disabled: shouldDisable()}">
              </pl-edit-select>
              <h5 class="text-danger" *ngIf="temErros" [translate]="'reembolsosiva.disabledHasErrors'"></h5>
            </div>
          </edit>
        </pl-group>
      </pl-form>

      <pl-tabs attrName="type" [activeId]="idTab" (evtSelected)="changeTab($event.nextId)" [destroyOnHide]="false">
        <pl-tab *ngFor="let tab of tabs" [id]="tab.name">
          <div *plTabTitle><i class="fa fa-fw" [ngClass]="tab.icon"></i>&nbsp;<span [plTranslate]="tab.title"></span></div>

          <div *plTabContent>
            <div class="reembolsosiva-tab">
              <pl-navpill attrName="{{ tab.name }}_doc" [activeId]="idPill" (evtSelected)="changePanel($event)" [destroyOnHide]="false">
                <pl-navpill-panel [id]="reembolsosIvaPill.Documents" class="reembolsosiva-panel-documents">
                  <div *plNavPillPanelTitle><i class="fa fa-fw" [ngClass]="pills[0].icon"></i>&nbsp;<span [plTranslate]="pills[0].title"></span></div>

                  <div *plNavPillPanelContent>
                    <div [plPromise]="promise">
                      <dx-data-grid
                        [cgDxDataGrid]="dataGridDefinitionDocuments"
                        cgDxDataGridInstanceName="{{ tab.name }}_documents"
                        [dataSource]="dataGridDefinitionDocuments.dataSource"
                        (onInitialized)="onInitialized()"
                        (onContentReady)="onContentReady($event)">
                        <div *dxTemplate="let item of 'detailTemplateDocuments'">
                          <div class="documento-contabilidade">
                            <div class="card">
                              <div class="card-header d-flex align-items-center gap-2">
                                <h6><strong [translate]="'reembolsosiva.document'"></strong> {{ item.data.periodo + '.' + item.data.nDiario + '.' + item.data.nDocInterno.trim() }}</h6>

                                <button type="button" class="btn btn-sm btn-warning action-edit" *ngIf="item.data.extPocCabID" [click]="fnEditDocument(item.data)" plPromise>
                                  <i class="fa fa-fw fa-pencil"></i><span [translate]="'reembolsosiva.action.editDoc'"></span>
                                </button>

                                <button type="button" class="btn btn-sm btn-danger action-delete" *ngIf="item.data.ivaReDocsID" [click]="fnDeleteDocument(item.data)" plPromise>
                                  <i class="fa fa-fw fa-trash-o"></i><span [translate]="'reembolsosiva.action.deleteLine'"></span>
                                </button>
                              </div>

                              <div class="card-body panel-reembolsosiva">
                                <div *ngIf="!item.data.error" [translate]="'reembolsosiva.documentNoErrors'"></div>
                                <div *ngIf="item.data.error">
                                  <span [translate]="'reembolsosiva.documentHasErrors'"></span> <span class="text-danger">{{ item.data.error }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div *dxTemplate="let item of 'templateToolbarDocuments'">
                          <pl-edit
                            type="boolean"
                            [model]="showDocsWithError[idTab]['documents']"
                            (modelChange)="showDocsWithErrorSwitchChange($event, idTab, 'documents')"
                            [properties]="{label: 'reembolsosiva.showDocsWithErrorOnly'}">
                          </pl-edit>
                        </div>

                        <div *dxTemplate="let item of 'cellTemplateError'">
                          <span *ngIf="item.data.error && item.data.error.length" class="text-center text-danger"><i class="fa fa-exclamation-circle"></i></span>
                        </div>
                      </dx-data-grid>
                    </div>
                  </div>
                </pl-navpill-panel>

                <pl-navpill-panel [id]="reembolsosIvaPill.Relations" class="reembolsosiva-panel-relations">
                  <div *plNavPillPanelTitle><i class="fa fa-fw" [ngClass]="pills[1].icon"></i>&nbsp;<span [plTranslate]="pills[1].title"></span></div>

                  <div *plNavPillPanelContent class="reembolsosiva-relacoes">
                    <ng-container [ngSwitch]="tab.name">
                      <ng-container *ngSwitchCase="reembolsosIvaTab.Clients">
                        <div class="card pb-4">
                          <h5 class="card-header"><span class="number-title-box">2</span>&nbsp;<span class="nav-item-title" [translate]="'reembolsosiva.clientes.title'"></span></h5>
                          <div class="card-body">
                            <div class="inputs-currency-inline">
                              <section class="mt-2">
                                <div class="box-title">
                                  <div class="input-box-numbers-title">0</div>
                                  <label [translate]="'reembolsosiva.clientes.0.periodo'" class="input-box-numbers-label"></label>
                                </div>
                                <div class="ms-3 mt-2">
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <div class="d-flex">
                                        <pl-edit type="text" attrName="campoPeriodoRelacao" [model]="model.periodoRelacao" [properties]="{disabled: true}"></pl-edit>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <section class="mt-4">
                                <div class="box-title">
                                  <div class="input-box-numbers-title">1</div>
                                  <label [translate]="'reembolsosiva.clientes.1.title'" class="input-box-numbers-label"></label>
                                </div>
                                <dx-data-grid
                                  [cgDxDataGrid]="dataGridClientCampo1"
                                  cgDxDataGridInstanceName="reembolsosIvaDataGridClientCampo1"
                                  [dataSource]="dataGridClientCampo1Source"
                                  (onInitialized)="onInitializedClientCampo1($event)"
                                  (onContentReady)="onContentReadyCamposClient()">
                                  <div *dxTemplate="let item of 'dataGridTemplateIndex'">
                                    {{ item.rowIndex + 1 }}
                                  </div>
                                </dx-data-grid>
                              </section>

                              <section class="mt-5">
                                <div class="box-title">
                                  <div class="input-box-numbers-title">3</div>
                                  <label [translate]="'reembolsosiva.clientes.3.title'" class="input-box-numbers-label"></label>
                                </div>
                                <dx-data-grid
                                  [cgDxDataGrid]="dataGridClientCampo3"
                                  cgDxDataGridInstanceName="reembolsosIvaDataGridClientCampo3"
                                  [dataSource]="dataGridClientCampo3Source"
                                  (onInitialized)="onInitializedClientCampo3($event)"
                                  (onContentReady)="onContentReadyCamposClient()">
                                  <div *dxTemplate="let item of 'dataGridTemplateIndex'">
                                    {{ item.rowIndex + 1 }}
                                  </div>
                                </dx-data-grid>
                              </section>

                              <section class="mt-5">
                                <div class="box-title">
                                  <div class="input-box-numbers-title">4</div>
                                  <label [translate]="'reembolsosiva.clientes.4.title'" class="input-box-numbers-label"></label>
                                </div>
                                <div class="row">
                                  <div class="col"></div>
                                  <div class="col-lg-3"><label [translate]="'reembolsosiva.clientes.4.valor'"></label></div>
                                </div>
                                <div class="row">
                                  <div class="col"></div>
                                  <div class="col-lg-3">
                                    <div class="d-flex">
                                      <pl-edit type="currency" attrName="clientCampo4" [model]="totalClientCampo4" [properties]="{disabled: true}"></pl-edit>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <section class="mt-5">
                                <div class="box-title">
                                  <div class="input-box-numbers-title">5</div>
                                  <label [translate]="'reembolsosiva.clientes.5.title'" class="input-box-numbers-label"></label>
                                </div>
                                <div class="row">
                                  <div class="col"></div>
                                  <div class="col-lg-3"><label [translate]="'reembolsosiva.clientes.5.valor'"></label></div>
                                </div>
                                <div class="row">
                                  <div class="col"></div>
                                  <div class="col-lg-3">
                                    <div class="d-flex">
                                      <pl-edit type="currency" attrName="clientCampo5" [model]="totalClientCampo5" [properties]="{disabled: true}"></pl-edit>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <section class="mt-5">
                                <div class="box-title">
                                  <div class="input-box-numbers-title">6</div>
                                  <label [translate]="'reembolsosiva.clientes.6.total'" class="input-box-numbers-label"></label>
                                </div>
                                <div class="row">
                                  <div class="col"></div>
                                  <div class="col-lg-3"><label [translate]="'reembolsosiva.clientes.6.valor'"></label></div>
                                </div>
                                <div class="row">
                                  <div class="col"></div>
                                  <div class="col-lg-3">
                                    <div class="d-flex">
                                      <pl-edit type="currency" attrName="clientCampo5" [model]="totalClientCampo6" [properties]="{disabled: true}"></pl-edit>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngSwitchCase="reembolsosIvaTab.Suppliers">
                        <div class="card pb-4">
                          <h5 class="card-header"><span class="number-title-box">2</span>&nbsp;<span class="nav-item-title" [translate]="'reembolsosiva.fornecedores.title'"></span></h5>
                          <div class="card-body">
                            <div class="inputs-currency-inline">
                              <section class="mt-2">
                                <div class="box-title">
                                  <div class="input-box-numbers-title">0</div>
                                  <label [translate]="'reembolsosiva.fornecedores.0.periodo'" class="input-box-numbers-label"></label>
                                </div>
                                <div class="ms-3 mt-2">
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <div class="d-flex">
                                        <pl-edit type="text" attrName="campoPeriodoRelacao" [model]="model.periodoRelacao" [properties]="{disabled: true}"></pl-edit>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <section class="mt-4">
                                <div class="box-title">
                                  <div class="input-box-numbers-title">1</div>
                                  <label [translate]="'reembolsosiva.fornecedores.1.campo20'" class="input-box-numbers-label"></label>
                                </div>
                                <dx-data-grid
                                  [cgDxDataGrid]="dataGridCampos"
                                  cgDxDataGridInstanceName="reembolsosIvaDataGridCampo20"
                                  [dataSource]="dataGridCampo20Source"
                                  (onInitialized)="onInitializedCampo20($event)"
                                  (onContentReady)="onContentReadyCampos()">
                                  <div *dxTemplate="let item of 'dataGridTemplateIndex'">
                                    {{ item.rowIndex + 1 }}
                                  </div>
                                </dx-data-grid>
                              </section>

                              <section class="mt-5">
                                <div class="box-title">
                                  <div class="input-box-numbers-title">2</div>
                                  <label [translate]="'reembolsosiva.fornecedores.2.campo21'" class="input-box-numbers-label"></label>
                                </div>
                                <dx-data-grid
                                  [cgDxDataGrid]="dataGridCampos"
                                  cgDxDataGridInstanceName="reembolsosIvaDataGridCampo21"
                                  [dataSource]="dataGridCampo21Source"
                                  (onInitialized)="onInitializedCampo21($event)"
                                  (onContentReady)="onContentReadyCampos()">
                                  <div *dxTemplate="let item of 'dataGridTemplateIndex'">
                                    {{ item.rowIndex + 1 }}
                                  </div>
                                </dx-data-grid>
                              </section>

                              <section class="mt-5">
                                <div class="box-title">
                                  <div class="input-box-numbers-title">3</div>
                                  <label [translate]="'reembolsosiva.fornecedores.3.campo22'" class="input-box-numbers-label"></label>
                                </div>
                                <dx-data-grid
                                  [cgDxDataGrid]="dataGridCampos"
                                  cgDxDataGridInstanceName="reembolsosIvaDataGridCampo22"
                                  [dataSource]="dataGridCampo22Source"
                                  (onInitialized)="onInitializedCampo22($event)"
                                  (onContentReady)="onContentReadyCampos()">
                                  <div *dxTemplate="let item of 'dataGridTemplateIndex'">
                                    {{ item.rowIndex + 1 }}
                                  </div>
                                </dx-data-grid>
                              </section>

                              <section class="mt-5">
                                <div class="box-title">
                                  <div class="input-box-numbers-title">4</div>
                                  <label [translate]="'reembolsosiva.fornecedores.4.campo23'" class="input-box-numbers-label"></label>
                                </div>
                                <dx-data-grid
                                  [cgDxDataGrid]="dataGridCampos"
                                  cgDxDataGridInstanceName="reembolsosIvaDataGridCampo23"
                                  [dataSource]="dataGridCampo23Source"
                                  (onInitialized)="onInitializedCampo23($event)"
                                  (onContentReady)="onContentReadyCampos()">
                                  <div *dxTemplate="let item of 'dataGridTemplateIndex'">
                                    {{ item.rowIndex + 1 }}
                                  </div>
                                </dx-data-grid>
                              </section>

                              <section class="mt-5">
                                <div class="box-title">
                                  <div class="input-box-numbers-title">5</div>
                                  <label [translate]="'reembolsosiva.fornecedores.5.campo24'" class="input-box-numbers-label"></label>
                                </div>
                                <dx-data-grid
                                  [cgDxDataGrid]="dataGridCampos"
                                  cgDxDataGridInstanceName="reembolsosIvaDataGridCampo24"
                                  [dataSource]="dataGridCampo24Source"
                                  (onInitialized)="onInitializedCampo24($event)"
                                  (onContentReady)="onContentReadyCampos()">
                                  <div *dxTemplate="let item of 'dataGridTemplateIndex'">
                                    {{ item.rowIndex + 1 }}
                                  </div>
                                </dx-data-grid>
                              </section>

                              <section class="mt-5">
                                <div class="box-title">
                                  <div class="input-box-numbers-title">7</div>
                                  <label [translate]="'reembolsosiva.fornecedores.7.total'" class="input-box-numbers-label"></label>
                                </div>
                                <div class="row">
                                  <div class="col"></div>
                                  <div class="col-lg-3"><label [translate]="'reembolsosiva.fornecedores.7.totalValorAquisicoes'"></label></div>
                                  <div class="col-lg-3"><label [translate]="'reembolsosiva.fornecedores.7.totalIvaDeduzido'"></label></div>
                                </div>
                                <div class="row">
                                  <div class="col"></div>
                                  <div class="col-lg-3">
                                    <div class="d-flex">
                                      <pl-edit type="currency" attrName="anexorQuadro6Campo10" [model]="totalValorAquisicoes" [properties]="{disabled: true}"></pl-edit>
                                    </div>
                                  </div>

                                  <div class="col-lg-3">
                                    <div class="d-flex">
                                      <pl-edit type="currency" attrName="anexorQuadro6Campo11" [model]="totalIvaDeduzido" [properties]="{disabled: true}"></pl-edit>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </pl-navpill-panel>
              </pl-navpill>
            </div>
          </div>
        </pl-tab>
      </pl-tabs>
    </div>
  </entity-detail>
</div>
