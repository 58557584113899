import {firstValueFrom} from 'rxjs';
import {Component, Injector, OnInit} from '@angular/core';
import {isEmpty, KEYCODES} from 'pl-comps-angular';
import {ContabilidadeEstatisticaService} from '../../contabilidadeEstatistica.module.service';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {ETipoContaContabilidade} from '../../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {IContabilidadeMovimentosEmAbertoStateParams} from '../contabilidade.movimentosEmAberto.module.interface';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IJsonPeriodo} from '../../../../../entities/periodos/jsonPeriodo.entity.interface';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';
import {ENTITY_NAME_PERIODOS} from '../../../../../entities/periodos/periodos.entity.interface';
import {IJsonPOC} from '../../../../../entities/pocs/jsonPOC.entity.interface';
import {ENTITY_NAME_POCS} from '../../../../../entities/pocs/pocs.entity.interface';

const BTN_ID_PROCESSAR = 'processar';

@Component({
  selector: 'contabilidade-movimentos-em-aberto',
  templateUrl: './contabilidade.movimentosEmAberto.module.component.html'
})
export class ContabilidadeMovimentosEmAbertoComponent extends ModuloComponent implements OnInit {
  public readonly filter: string = `tipo=${ETipoContaContabilidade.Movimento}`;
  public reportModel: IJsonReport;
  public deConta: string;
  public ateConta: string;
  public periodo: IJsonPeriodo;
  public pdfUrl: string;

  private readonly _entityPeriodos: IEntityService<IJsonPeriodo>;
  private readonly _servicePocs: IEntityService<IJsonPOC>;
  private readonly _params: IContabilidadeMovimentosEmAbertoStateParams;
  private readonly _isFromParamsAi: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _contabilidadeEstatisticaService: ContabilidadeEstatisticaService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._entityPeriodos = this._entityServiceBuilder.build<IJsonPeriodo>(ENTITY_NAME_PERIODOS);
    this._servicePocs = this._entityServiceBuilder.build<IJsonPOC>(ENTITY_NAME_POCS);
    this._params = <IContabilidadeMovimentosEmAbertoStateParams>this._transition.params();
    this._isFromParamsAi = !isEmpty(this._params.query) || !isEmpty(this._params.nConta);
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.deConta = this._configService.configurations.clientesFornecedores.radicalClientes;
    this.ateConta = `${this._configService.configurations.clientesFornecedores.radicalClientes}9999999`;

    this.periodo = await this._entityPeriodos.get({id: this._configService.configurations.empresa.periodoDaData}).then((response) => response.body);
    await this._loadDefaultReport();

    this.toolbar.addButton({
      id: BTN_ID_PROCESSAR,
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-gear"></i>',
      class: 'btn-success',
      caption: 'Processar',
      click: () => this.processar()
    });

    if (!this._isFromParamsAi && (this._params.deConta || this._params.ateConta)) {
      if (this._params.deConta) {
        this.deConta = this._params.deConta;
      }
      if (this._params.ateConta) {
        this.ateConta = this._params.ateConta;
      }
      await this.processar();
    }
    await this._loadFromAi();
  }

  public async processar(): Promise<void> {
    this.pdfUrl = await firstValueFrom(this._contabilidadeEstatisticaService.getMovimentosEmAbertoUrl(this.deConta, this.ateConta, this.periodo.periodo, this.reportModel.name));
  }

  public readonly fnKeydownProcessar = (value: string, event: KeyboardEvent): void => {
    this._keydownProcessar(event);
  };

  private async _loadDefaultReport(): Promise<void> {
    try {
      const reportModel = this._configService.configurations.contabilidade.movimentosemaberto.reportImpressao;
      const reports: Array<IJsonReport> = await this._reportsRegistryService.get(EReport.MovimentosEmAberto).query();

      if (reports.length) {
        let foundDefault = false;
        for (const report of reports) {
          if (report.name === reportModel) {
            this.reportModel = report;
            foundDefault = true;
            break;
          }
        }
        if (!foundDefault) {
          this.reportModel = reports[0];
        }
      }
    } catch (error) {
      console.error('Error loading default report:', error);
    }
  }

  private _keydownProcessar(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.preventDefault();
      event.stopPropagation();
      this.toolbar.focusItem(BTN_ID_PROCESSAR);
    }
  }

  private async _loadFromAi(): Promise<void> {
    if (this._isFromParamsAi) {
      if (!isEmpty(this._params.nConta)) {
        const responsePoc = await this._servicePocs.get({id: this._params.nConta}).then((response) => response.body);
        this.deConta = this.ateConta = responsePoc.nConta;
        await this.processar();
      }

      // if (!isEmpty(this._params.query)) {
      //   const responsePocsFound = await this._servicePocs.query({pesquisa: this._params.query}).then((response) => response.body);
      //   if (responsePocsFound.total === 1) {
      //     this.deConta = this.ateConta = responsePocsFound.list[0].nConta;
      //     await this.processar();
      //   }
      // }
    }
  }
}
