import {Directive, HostBinding} from '@angular/core';
import {ATTR_DATA_DOWNWARDS_CLICK} from './delegate.event.downwards.click.directive.interface';

@Directive({
  selector: '[plDelegateEventDownwardsClick]'
})
export class PlDelegateEventDownwardsClickDirective {
  @HostBinding(`attr.${ATTR_DATA_DOWNWARDS_CLICK}`)
  public readonly attrDataDownwardsClick = 'capture';
}
