<div class="bancos-extrato-module-predefdesc-modal">
  <pl-form [properties]="{disabled: !!promise}" [submit]="close" plPromise>
    <div class="modal-header">
      <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="!simpleMode ? 'bancosextrato.predefdescmodal.title' : 'bancosextrato.predefdescmodal.titlesimple'"></h5>
    </div>

    <div class="modal-body" [plPromise]="promise">
      <pl-group>
        <label [translate]="'bancosextrato.predefdescmodal.fields.descriptionKey'"></label>
        <edit>
          <pl-edit-text attrName="descricao" [(model)]="model.descriptionKey"></pl-edit-text>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'bancosextrato.predefdescmodal.fields.conta'"></label>
        <edit>
          <entity-autocomplete
            entity="pocs"
            attrName="conta"
            [model]="model"
            (selectedKeyChange)="model.nConta = $event"
            (evtSelectedDescriptionChanged)="model.nomeConta = $event"
            [filter]="filterConta"
            [fieldsMap]="{nome: 'nomeConta'}">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group *ngIf="!simpleMode">
        <label [translate]="'bancosextrato.predefdescmodal.fields.predefinido'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="predefinido"
            [model]="model"
            (selectedKeyChange)="model.preDefinidosID = $event"
            (evtSelectedDescriptionChanged)="model.preDefinidosNome = $event"
            [fieldsMap]="{descricao: 'preDefinidosNome'}"
            [filter]="filterPredefinido"
            [customActions]="customActionPreDefinido">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button type="submit" klass="btn-primary action-submit"> <i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'global.btn.save'"></span> </pl-button>

      <pl-button klass="btn-light action-cancel" [disabled]="closeDisabled" (evtClicked)="dismiss()">
        <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
      </pl-button>
    </div>
  </pl-form>
</div>
