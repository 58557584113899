import {merge} from 'lodash-es';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Inject, Injectable, OnDestroy, Optional, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {generateUniqueID, interpolate, isEmpty, isError, isFunction, isObject, isString} from '../common/utilities/utilities';
import type {IPlRecaptchaInstance, IPlRecaptchaParameters} from './recaptcha.interface';
import {loadScript} from '../common/scriptloader/scriptloader';
import {RECAPTCHA_BASE_URL, RECAPTCHA_ENTERPRISE, RECAPTCHA_LANGUAGE, RECAPTCHA_NONCE, RECAPTCHA_PARAMETERS, RECAPTCHA_SITE_KEY, RECAPTCHA_SITE_KEY_V3} from './recaptcha.tokens';
import type {TValueOrPromise} from '../common/utilities/utilities.interface';

const BASE_URL = 'https://www.recaptcha.net/recaptcha/api.js';
const BASE_URL_ENTERPRISE = 'https://www.recaptcha.net/recaptcha/enterprise.js';
const PARAMS = '?render={{renderMode}}&onload={{onLoadCallback}}&hl={{language}}';

@Injectable({
  providedIn: 'root'
})
export class PlRecaptchaService implements OnDestroy {
  private readonly _isBrowser: boolean;
  private readonly _subjectParameters: BehaviorSubject<IPlRecaptchaParameters>;
  private readonly _onLoadFnName: string;
  private readonly _promiseInitialConfiguration: Promise<void>;
  private _instances: Map<number, IPlRecaptchaInstance>;
  private _subjectsOnSuccess: Map<number, Subject<string>>;
  private _subjectsOnExpired: Map<number, Subject<void>>;
  private _subjectsOnError: Map<number, Subject<void>>;
  private _recaptcha: ReCaptchaV2.ReCaptcha;
  private _needsReload: boolean;
  private _promiseGreCaptcha: Promise<ReCaptchaV2.ReCaptcha>;
  private _observableParameters: Observable<IPlRecaptchaParameters>;

  constructor(
    @Inject(PLATFORM_ID) platformId: any,
    @Inject(RECAPTCHA_SITE_KEY) @Optional() siteKey: TValueOrPromise<string>,
    @Inject(RECAPTCHA_SITE_KEY_V3) @Optional() siteKeyV3: TValueOrPromise<string>,
    @Inject(RECAPTCHA_ENTERPRISE) @Optional() enterprise: TValueOrPromise<boolean>,
    @Inject(RECAPTCHA_BASE_URL) @Optional() baseUrl: TValueOrPromise<string>,
    @Inject(RECAPTCHA_NONCE) @Optional() nonce: TValueOrPromise<string>,
    @Inject(RECAPTCHA_LANGUAGE) @Optional() language: TValueOrPromise<string>,
    @Inject(RECAPTCHA_PARAMETERS) @Optional() parameters: TValueOrPromise<IPlRecaptchaParameters>
  ) {
    this._isBrowser = isPlatformBrowser(platformId);
    this._instances = new Map<number, IPlRecaptchaInstance>();
    this._subjectsOnSuccess = new Map<number, Subject<string>>();
    this._subjectsOnExpired = new Map<number, Subject<void>>();
    this._subjectsOnError = new Map<number, Subject<void>>();
    this._subjectParameters = new BehaviorSubject<IPlRecaptchaParameters>(undefined);
    this._onLoadFnName = generateUniqueID('plRecaptchaServiceOnLoadFn');
    this._needsReload = true;
    this._promiseInitialConfiguration = Promise.all([
      Promise.resolve(siteKey),
      Promise.resolve(siteKeyV3),
      Promise.resolve(enterprise),
      Promise.resolve(baseUrl),
      Promise.resolve(nonce),
      Promise.resolve(language),
      Promise.resolve(parameters)
    ]).then(
      ([resolvedSiteKey, resolvedSiteKeyV3, resolvedEnterprise, resolvedBaseUrl, resolvedNonce, resolvedLanguage, resolvedParameters]: [
        string,
        string,
        boolean,
        string,
        string,
        string,
        IPlRecaptchaParameters
      ]) => {
        this.setParameters({
          ...parameters,
          sitekey: resolvedSiteKey || resolvedParameters?.sitekey || '',
          sitekeyV3: resolvedSiteKeyV3 || resolvedParameters?.sitekeyV3 || 'explicit',
          enterprise: resolvedEnterprise ?? resolvedParameters?.enterprise ?? false,
          baseUrl: resolvedBaseUrl || resolvedParameters?.baseUrl,
          nonce: resolvedNonce || resolvedParameters?.nonce,
          language: resolvedLanguage || resolvedParameters?.language
        });
        this._needsReload = true;
      }
    );
  }

  public ngOnDestroy(): void {
    this._subjectParameters.complete();
    if (this._subjectsOnSuccess) {
      this._subjectsOnSuccess.forEach((subject: Subject<string>) => {
        subject.complete();
      });
    }
    if (this._subjectsOnExpired) {
      this._subjectsOnExpired.forEach((subject: Subject<void>) => {
        subject.complete();
      });
    }
    if (this._subjectsOnError) {
      this._subjectsOnError.forEach((subject: Subject<void>) => {
        subject.complete();
      });
    }
    if (this._instances) {
      this._instances.clear();
    }
  }

  public getRecaptcha(): Promise<ReCaptchaV2.ReCaptcha> {
    if (this._needsReload) {
      this._needsReload = false;
      this._recaptcha = undefined;
    }
    if (!this._recaptcha) {
      if (!this._promiseGreCaptcha) {
        this._promiseGreCaptcha = new Promise<ReCaptchaV2.ReCaptcha>((resolve, reject) => {
          this._promiseInitialConfiguration.finally(() => {
            const parameters: IPlRecaptchaParameters = this._subjectParameters.value;

            if (!this._isBrowser) {
              reject(new Error('Recaptcha is only available in a browser context.'));
              return;
            }
            window[this._onLoadFnName] = () => {
              delete window[this._onLoadFnName];
              this._recaptcha = (<any>window).grecaptcha;
              resolve(!parameters.enterprise ? this._recaptcha : (<ReCaptchaV2.ReCaptcha & {enterprise: ReCaptchaV2.ReCaptcha}>this._recaptcha).enterprise);
            };

            // '.then()' is not required because 'window[this._onLoadFnName]' will be called in its stead by recaptcha itself
            const url: string = this._getLoadUrl(parameters);
            loadScript({
              id: this._onLoadFnName,
              src: url,
              async: true,
              defer: true,
              nonce: parameters.nonce
            }).catch((reason: unknown) => {
              delete window[this._onLoadFnName];
              this._recaptcha = undefined;
              if (isError(reason)) {
                reject(reason);
              } else {
                reject(new Error(String(reason)));
              }
            });
          });
        });
      }
      return this._promiseGreCaptcha;
    }
    const parameters: IPlRecaptchaParameters = this._subjectParameters.value;
    return Promise.resolve(!parameters.enterprise ? this._recaptcha : (<ReCaptchaV2.ReCaptcha & {enterprise: ReCaptchaV2.ReCaptcha}>this._recaptcha).enterprise);
  }

  public newInstance(container: string | HTMLElement, properties?: IPlRecaptchaParameters): Promise<IPlRecaptchaInstance> {
    return this.getRecaptcha().then((greCaptcha: ReCaptchaV2.ReCaptcha) => {
      const containerNode: HTMLElement = isString(container) ? window.document.getElementById(container) : container;
      if (!containerNode) {
        throw new Error(`Failed to create a new reCaptcha instance. Element with id "${<string>container}" not found or provided container is invalid.`);
      }

      // Build instance
      const subjectOnSuccess: Subject<string> = new Subject<string>();
      const subjectOnExpired: Subject<void> = new Subject<void>();
      const subjectOnError: Subject<void> = new Subject<void>();

      properties = merge({}, this._subjectParameters.value, properties);

      const instanceProperties: IPlRecaptchaParameters = {
        sitekey: properties.sitekey,
        theme: properties.theme,
        type: properties.type,
        size: properties.size,
        tabindex: properties.tabindex,
        badge: properties.badge,
        isolated: properties.isolated,
        language: properties.language,
        callback: (response: string) => {
          subjectOnSuccess.next(response);
          if (isFunction(properties.callback)) {
            properties.callback(response);
          }
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'expired-callback': () => {
          subjectOnExpired.next();
          if (isFunction(properties['expired-callback'])) {
            properties['expired-callback']();
          }
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'error-callback': () => {
          subjectOnError.next();
          if (isFunction(properties['error-callback'])) {
            properties['error-callback']();
          }
        }
      };

      const widgetId: number = greCaptcha.render(containerNode, instanceProperties);

      if (!this._subjectsOnSuccess) {
        this._subjectsOnSuccess = new Map<number, Subject<string>>();
      }
      this._subjectsOnSuccess.set(widgetId, subjectOnSuccess);

      if (!this._subjectsOnExpired) {
        this._subjectsOnExpired = new Map<number, Subject<void>>();
      }
      this._subjectsOnExpired.set(widgetId, subjectOnExpired);

      if (!this._subjectsOnError) {
        this._subjectsOnError = new Map<number, Subject<void>>();
      }
      this._subjectsOnError.set(widgetId, subjectOnError);

      const newInstance: IPlRecaptchaInstance = {
        container: containerNode,
        widgetId: () => widgetId,
        parameters: () => {
          return {...instanceProperties};
        },
        reset: () => {
          greCaptcha.reset(widgetId);
        },
        getResponse: () => greCaptcha.getResponse(widgetId),
        execute: (action?: ReCaptchaV2.Action) => {
          if (isObject(action)) {
            return greCaptcha.execute(properties.sitekeyV3, action);
          }
          greCaptcha.execute(widgetId);
          return Promise.resolve('');
        },
        onSuccess: () => subjectOnSuccess.asObservable(),
        onExpired: () => subjectOnExpired.asObservable(),
        onError: () => subjectOnError.asObservable()
      };

      if (!this._instances) {
        this._instances = new Map<number, IPlRecaptchaInstance>();
      }
      this._instances.set(widgetId, newInstance);

      return newInstance;
    });
  }

  public deleteInstance(widgetId: number): void {
    let subject: Subject<any> = this._subjectsOnSuccess.get(widgetId);
    if (subject) {
      subject.complete();
      this._subjectsOnSuccess.delete(widgetId);
    }
    subject = this._subjectsOnExpired.get(widgetId);
    if (subject) {
      subject.complete();
      this._subjectsOnExpired.delete(widgetId);
    }
    subject = this._subjectsOnError.get(widgetId);
    if (subject) {
      subject.complete();
      this._subjectsOnError.delete(widgetId);
    }
    this._instances.delete(widgetId);
  }

  public setParameters(parameters: IPlRecaptchaParameters): void {
    const needsSetLanguage: boolean = isObject(parameters) && !isEmpty(parameters.language) && parameters.language !== this._subjectParameters.value?.language;
    this._subjectParameters.next(
      Object.freeze<IPlRecaptchaParameters>({
        size: 'normal',
        tabindex: 0,
        theme: 'light',
        language: 'en',
        ...this._subjectParameters.value,
        ...parameters
      })
    );
    if (needsSetLanguage) {
      this._needsReload = true;
    }
  }

  public get parameters(): Observable<IPlRecaptchaParameters> {
    if (!this._observableParameters) {
      this._observableParameters = this._subjectParameters.asObservable();
    }
    return this._observableParameters;
  }

  private _getLoadUrl(parameters: IPlRecaptchaParameters): string {
    let url = !parameters.enterprise ? BASE_URL : BASE_URL_ENTERPRISE;
    const indexParams: number = url.indexOf('?');
    if (indexParams > -1) {
      url = url.substring(0, indexParams);
    }
    url += PARAMS;
    return interpolate(url)({
      renderMode: parameters.sitekeyV3,
      onLoadCallback: this._onLoadFnName,
      language: parameters.language
    });
  }
}
