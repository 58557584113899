{
	"abdes": {
		"title_detail": "Allowance/Discount {{id}}",
		"title_new": "New Allowance/Discount",
		"title_plural": "Allowances/Discounts",
		"pesquisa": "To look for",
		"saved": "Allowance/Discount {{id}}, saved successfully.",
		"error": "Allowance/Discount {{id}}, cannot be saved.",
		"deleted": "Allowance/Discount {{id}}, successfully deleted.",
		"fields": {
			"abonoDesc": "Allowance/Discount",
			"codAbDesc": "Code",
			"designacaoBreve": "Name",
			"designacaoCompleta": "Description",
			"valorFixo": "Unitary value",
			"valorLimIncidencia": "Incidence Limit",
			"tipoArredondamento": "Rounding",
			"procNatal": "Retention Rate",
			"procFerias": "",
			"mapaSeguro": "Safe Map",
			"mapaSindic": "Union Map",
			"colQuadroP": "",
			"colMapaCX": "",
			"nCodABDESC": "",
			"entiSuporte": "Entity",
			"contaMovDeb": "Employee Allowances and Discounts - Debit Account",
			"contaMovCred": "Employee Allowances and Discounts - Credit Account",
			"calcCustHora": "Contributes Price/Hour Cost Calculation",
			"coefHExtra": "% Increase in Overtime",
			"horaDiaValor": "",
			"tipoHoraExtr": "",
			"diaHoraExtra": "",
			"grupoAbono": "Group",
			"grupoDesconto": "Group",
			"naoImpDecIRS114": "Does not print declaration",
			"tipoValorUnitRecolha": "Collection Type",
			"percLimValorHExtra": "% Limit Value H. Extras Report.",
			"codCGASit": "Status Code",
			"contaEncDEB": "Employer/Company Charges - Debit Account",
			"contaEncCRE": "Employer/Company Charges - Credit Account",
			"percInciSegSocial": "(%) Incidence",
			"tipoRendimento": "Income Type.",
			"codSSCol": "Rem. Code",
			"codUnidade": "Unit Defect",
			"codRubrOrcAbon": "",
			"codRubrOrcEnc": "",
			"abonoDescDesativado": "Inactive",
			"tipoDescontoProporcionalABDES": "Type Proportional Discount",
			"nHorasDescontoProporcional": "No. Hours",
			"sPReducaoRemunatoria": "Has a salary reduction",
			"nHorasDiaProvDesc": "Number of hours per day for loss of Meal Subsidy",
			"perctDescSeguro": "Percentage paid for insurance",
			"usaSPReducaoRemuneratoria": "Public service workers - Have pay reduction",
			"tem": "He has"
		},
		"tabIntegracaoCruzamento": "Integration/Crosses",
		"tabOutrosDados": "Other data",
		"naotem": "It does not have"
	},
	"descontos": {
		"title_detail": "Discount {{id}}",
		"title_new": "New Discount",
		"title_plural": "Discounts",
		"pesquisa": "To look for",
		"saved": "Discount {{id}}, saved successfully.",
		"error": "Discount {{id}}, cannot be saved.",
		"deleted": "Discount {{id}}, successfully deleted.",
		"actions": "Generate new Discount"
	},
	"abonos": {
		"title_detail": "Allowance {{id}}",
		"title_new": "New Allowance",
		"title_plural": "Allowances",
		"pesquisa": "To look for",
		"saved": "Allowance {{id}}, saved successfully.",
		"error": "Allowance {{id}}, cannot be saved.",
		"deleted": "Allowance {{id}}, successfully deleted.",
		"actions": "Generate new Allowance"
	},
	"about": {
		"title": "About CentralGest Cloud",
		"licenca": {
			"licId": "License No.",
			"nome": "Name",
			"descricao": "Licensed to",
			"nUtilizadores": "Number of users",
			"portal": "Portal {{name}}"
		},
		"erp": {
			"versao": "ERP version",
			"dataVersao": "Version date",
			"emManutencao": "Under maintenance"
		},
		"user": {
			"roles": "User access"
		}
	},
	"account": {
		"messages": {
			"error": {
				"invalidRecaptcha": "Please check the checkbox to confirm that it is not a machine.",
				"invalidSecurePassword": "The password is not valid, it must contain at least 8 characters, a number, lowercase and uppercase letters.",
				"notAuthorized": "Does not have access to the platform."
			}
		}
	},
	"login": {
		"title": "Authenticate",
		"header": "Log in to start using CentralGest Cloud.",
		"form": {
			"password": "Password",
			"password.placeholder": "Password",
			"button": "Authenticate",
			"resetpassword": "I forgot my password"
		},
		"messages": {
			"error": {
				"authentication": "Authentication failed!",
				"invalidCredentials": "Enter your username and password."
			}
		}
	},
	"resetpassword": {
		"success": "Please check your email box",
		"title": "Recover your password",
		"message": "Please enter your email or username.<br/>You will soon receive a message in your email with information to change your password.",
		"email.placeholder": "Email or username",
		"action": {
			"back": "To go back",
			"resetpassword": "Recover your password"
		}
	},
	"changepassword": {
		"title": "Change your password",
		"message": "Please type your new password.",
		"success": "Your password has been changed.",
		"action": {
			"back": "To go back",
			"changepassword": "Change password"
		},
		"erro": {
			"minlength": "The password must be 5 characters or more!",
			"repetirinvalido": "Password does not match"
		},
		"placeholder": {
			"password": "Password",
			"confirmPassword": "Repeat password"
		}
	},
	"changefirm": {
		"fields": {
			"nEmpresa": "Company No.",
			"nomeEmpresa": "Company name"
		}
	},
	"acessos": {
		"erpcloud": {
			"addons": {
				"bankingAutomation": {
					"acesso": "Access to banking automation"
				}
			},
			"ativos": {
				"fichas": {
					"visualizarFichas": "View asset sheets",
					"criarFichas": "Create asset sheets",
					"editarFichas": "Edit asset sheets",
					"apagarFichas": "Delete asset sheets",
					"operacoesAbatesVendas": "Slaughter and sales operations",
					"calcularAnularDepreciacoes": "Calculate and write off depreciation"
				}
			},
			"contabilidade": {
				"documentos": {
					"criarDocumentos": "Create documents",
					"editarDocumentos": "Edit documents",
					"anularDocumentos": "Cancel documents",
					"visualizarDocumentos": "View documents"
				}
			},
			"gestaocomercial": {
				"editarContaArtigoFamilia": "You can edit the account for the sale and purchase of items and families",
				"editarMeioPagamento": "You can edit payment methods",
				"documentos": {
					"comprasefetivas": {
						"title": "Actual purchases"
					},
					"encomendasclientes": {
						"title": "Customer orders"
					},
					"encomendasfornecedores": {
						"title": "Supplier orders"
					},
					"guiastransporte": {
						"title": "Transport guides"
					},
					"guiastransportefornecedores": {
						"title": "Transport guides suppliers"
					},
					"propostasclientes": {
						"title": "Client proposals"
					},
					"vendasefetivas": {
						"title": "Effective sales"
					},
					"entradasdiversas": {
						"title": "Miscellaneous Entries"
					},
					"saidasdiversas": {
						"title": "Miscellaneous Outputs"
					}
				}
			}
		},
		"manager": {
			"generic": {
				"view": "To view",
				"add": "To create",
				"edit": "To edit",
				"delete": "Cancel"
			},
			"addons": {
				"title": "Addons"
			},
			"ativos": {
				"title": "Active"
			},
			"contabilidade": {
				"title": "Accounting"
			},
			"gestaoComercial": {
				"title": "Commercial"
			}
		}
	},
	"acrescimosencargosferias": {
		"porEmpresa": "By Company",
		"multiEmpresa": "Multi Company",
		"fields": {
			"empregado": "Employee",
			"totaisEncargos": "Total Charges",
			"codEmpregado": "Employee",
			"nomeEmpregado": "Name",
			"dataAdmissao": "Admission date",
			"nMesesTrabalhados": "No. of Working Months.",
			"ccusto": "C. Cost",
			"nCodRepCC": "Breakdown",
			"estadoID": "state",
			"vBaseEncargosFerias": "Holiday charge base",
			"valorAcertoManual": "Manual adjustment",
			"valorSubFerias": "Sub.",
			"valorFerias": "Vacation",
			"valorEncargosSS": "Social Mon",
			"valorEncargoAcTrab": "B.C.",
			"valorOutrosEncargos": "Others",
			"descrBaseEncFerias": "Info",
			"total": "Total",
			"dados": "Data",
			"sel": "Sel.",
			"nEmpresa": "Company",
			"nomeEmpresa": "Company name",
			"anoEncargo": "Year",
			"tipoID": "Type",
			"infoErro": "Error",
			"descricaoErro": "Info",
			"dataIntegracao": "Date integration"
		},
		"cab": {
			"totalEncAnuais": "Total annual charges per employee",
			"tipo": "Type",
			"acTrabalho": "%Ac.",
			"estado": "state",
			"dataInt": "Date integration",
			"tiposProce": {
				"tipoproce0": "",
				"tipoproce1": "Yearly",
				"tipoproce2": "Monthly"
			}
		},
		"modals": {
			"config": {
				"title": "Configuring holiday charges accruals",
				"contasDebito": "Debit accounts",
				"contasCredito": "Credit accounts",
				"percActTrabalho": "% Sec. accidents at work",
				"diario": "Daily",
				"descritivo": "Descriptive",
				"pocSubFerias": "Sub.",
				"pocFerias": "Vacation",
				"pocEncargosSegSocial": "Charges sec.",
				"pocEncargosActTrabalho": "Acid charges.",
				"pocOutrosEncargos": "Other charges",
				"anoEncargo": "Year",
				"tipo": "Type",
				"percSeguradora": "% Sec. accidents at work",
				"dadosProce": "Data for processing",
				"intContab": "Accounting integration"
			},
			"resumoAnual": {
				"title": "Annual charges",
				"totaisAnual": "Annual totals",
				"encargos": "Charges",
				"total": "Total",
				"acertoManual": "Manual adjustment",
				"subferias": "Sub.",
				"ferias": "Vacation",
				"encSegSocial": "Charges sec.",
				"encAcTrab": "Ac Charges",
				"segSocial": "Social Mon.",
				"acTrab": "B.C.",
				"outrosEnc": "Other charges",
				"custosEmp": "Company costs",
				"totaisAno": "Year totals"
			},
			"empresas": {
				"title": "Increased charges for multi-company holidays",
				"nEmpresa": "Company",
				"nomeEmpresa": "Company name",
				"anoEncargo": "Year",
				"tipo": "Type",
				"percSeguradora": "%Ac."
			},
			"multiempresadetail": {
				"title": "Additional holiday charges"
			},
			"print": {
				"title": "Printing Accruals for Holiday Charges",
				"anoEncargo": "Year",
				"deCodEmp": "From code.",
				"ateCodEmp": "until",
				"deCCusto": "Cost center",
				"ateCCusto": "until",
				"deReparticao": "Breakdown",
				"ateReparticao": "until"
			}
		},
		"tipoAcrescimoEncFeriasSource": {
			"anual": "Yearly",
			"mensal": "Monthly"
		},
		"estadoRegEmp": {
			"novo": "New",
			"ok": "OK",
			"alterado": "Changed data",
			"apagado": "Left the company"
		},
		"estadoAcrescimoEncFerias": {
			"processado": "Processed",
			"integrado": "Integrated",
			"comInconsistencias": "With inconsistencies",
			"proceInteg": "Processed and integrated",
			"proceNotInteg": "Processed and not integrated"
		},
		"integContabMultiErrors": {
			"title": "Accounting integration report",
			"naoInteg": "Not integrated",
			"integ": "Integrated"
		},
		"btns": {
			"geraEncargos": "Generate charges",
			"removeEncargos": "Remove charges",
			"resumoAnual": "Annual summary",
			"editCab": "Edit header",
			"empregado": "Employee",
			"assistente": "Assistant",
			"lancarDocumento": "Launch document",
			"apagarDocumento": "Delete document",
			"lancarDocumentoMulti": "Launch document",
			"apagarDocumentoMulti": "Delete document",
			"verificarInconsistenciasMulti": "Check for inconsistencies",
			"apagaEncargosMulti": "To remove",
			"details": "Details",
			"integraContab": "Accounting Integration",
			"consultarLanc": "Consult releases"
		},
		"messages": {
			"naotemencargos": "There are no charges",
			"naoSelEmp": "You must select at least one company.",
			"docsIntegSuccess": "Successfully integrated documents",
			"docsDeletedSuccess": "Integrated documents deleted successfully"
		}
	},
	"adiantamentos": {
		"cliente": "Client",
		"fornecedor": "Supplier",
		"tabs": {
			"porRegularizar": "To be regularized",
			"regularizado": "Regularized",
			"fields": {
				"documentoStr": "Document",
				"data": "Date",
				"origemDocStr": "Origin",
				"nClifo": "Account",
				"nomeClifo": "Account name",
				"liquido": "Liquid",
				"valorIva": "VAT",
				"total": "Total",
				"porRegularizar": "To Regularize",
				"estadoStr": "state",
				"valorRegularizar": "To be regularized",
				"dateDocTo": "Doc date.",
				"dataRef": "Reference date",
				"intervalo": "Interval",
				"datarefradio": "Reference date",
				"modofunc": "Operating mode",
				"dataUltRegular": "Last date",
				"valorRegularizadoAData": "Value adjusted to date ref.",
				"estadoNaDataRefStr": "State on date ref."
			},
			"pesquisaDatas": "Search by dates",
			"pesquisaIntervalo": "Search for advances between dates",
			"pesquisaNaData": "Search status of advances to date"
		},
		"modals": {
			"familia": "Family",
			"nArtigoAdiantamento": "Advance article",
			"nArtigoRegAdiantamento": "Advance payment regularization article",
			"autoConfig": {
				"title": "Advance Management - Automatic Configuration",
				"steps": {
					"intro": {
						"title": "Introduction",
						"desc": "Advances Management automatic configuration wizard"
					},
					"familiaBase": {
						"title": "Base family",
						"desc": "Base family for creating down payments",
						"assistente1": "This wizard will create:",
						"assistente2": "Article family",
						"assistente3": "Advance Regularization Article",
						"assistente4": "Advance Article",
						"familiaEmpty": "You must select a family."
					},
					"familiaArtigos": {
						"title": "Family and articles",
						"desc": "Family and articles that will be created"
					}
				}
			},
			"config": {
				"title": "Advance Settings",
				"desc": "It is necessary to configure the items to be used by the Advances Management module"
			},
			"registar": {
				"title": "Register Advance",
				"emitAdiant": "Advance issuance",
				"emitAdiantDoc": "Issuance of advance payment relating to document {{doc}}",
				"taxaIva": "VAT rate",
				"valorSIva": "Value without VAT",
				"valorCIva": "Value with VAT",
				"codiva": "VAT code"
			},
			"print": {
				"title": "Printing of Advances",
				"listagem": "Listing"
			}
		},
		"messages": {
			"notConfigured": "The system is not yet configured to use Advances Management!",
			"criaConfigTooltip": "The necessary settings will be created.",
			"noDocSel": "You did not select any documents to regularize.",
			"configDocFields": "To create a new document you must configure the fields.",
			"valueInvalid": "The value must be greater than zero.",
			"clifoNaoSel": "You must insert a {{clip}} to regularize advances.",
			"clifoNaoSelReg": "You must insert a {{clip}} to record the advance.",
			"sistemNotConfigured": "You must configure the system to use Advance Management.",
			"docHasAdiant": "The document already contains a down payment line.",
			"docHasRegAdiant": "The document already contains an advance payment regularization line."
		},
		"btns": {
			"autoConfig": "Configure automatically",
			"regularizar": "Regularize",
			"rastreabilidade": "Traceability"
		}
	},
	"amalia": {
		"title": "Amalia",
		"text": {
			"promptPlaceholder": "Ask me anything...",
			"openFaq": "View on website",
			"unsupported": "Not yet supported: {{message}}"
		},
		"actions": {
			"premade": {
				"balanceteMesAnterior": "Balance sheet from the previous month",
				"possoUsarTelemovel": "Can I use it on my cell phone?",
				"currentTime": "What time is it?",
				"configurarProRata": "How to configure pro rata?",
				"comoAtribuirPaisCliente": "How to assign parents to a client?"
			}
		}
	},
	"amortizacao": {
		"add": "To add",
		"title_detail": "Depreciation code {{id}}",
		"title_new": "New Depreciation Code",
		"title_plural": "Depreciation Codes",
		"pesquisa": "To look for",
		"saved": "Depreciation code {{id}}, saved successfully.",
		"error": "Depreciation code {{id}}, cannot be saved.",
		"deleted": "Depreciation code {{id}}, successfully deleted",
		"fields": {
			"nImtab": "Code.",
			"nImtabPlaceholder": "Amortization Code",
			"nome": "Name:",
			"nomePlaceholder": "Amortization Name",
			"tipo": "Type",
			"tabela": "Table",
			"divisao": "Division",
			"grupo": "Group",
			"alinea": "Point",
			"artigo": "Article",
			"keyValue": "Key Value",
			"valor1": "Tax Limit",
			"valor2": "Accounting Limit",
			"valor3": "Rate",
			"dataInicio": "Start Date"
		}
	},
	"analisadorTes": {
		"btns": {
			"toolbarShortcuts": "Shortcuts",
			"toolbarConfig": "Settings",
			"toolbarPocMenu": "Customers and Suppliers",
			"syncToolbar": "Synchronize",
			"collapse": "To collapse",
			"expand": "Expand",
			"collapseAll": "Collapse all",
			"expandAll": "Expand all",
			"pesquisar": "To look for",
			"editarPrevisaoRecebimento": "Edit receipt forecast",
			"alterarDataTesouraria": "Change Treasury Date",
			"removerDocumentoTesouraria": "Remove document from treasury",
			"toolbarPreviTes": "Manual launch",
			"toolbarPreviTesAuto": "Automatic Launch",
			"extratoConta": "Account Statement",
			"toolbarPrevTesObras": "Importer of financial schedules for works",
			"criarTesRubricas": "Create Rubric",
			"editarRubrica": "Edit Rubric",
			"pagamento": "Payment",
			"recebimento": "Receipt",
			"verDocumentoContabilidade": "View Document in Accounting",
			"verDocumentoGestComercial": "View Document in Commercial Management"
		},
		"messages": {
			"syncDados": "Syncing data...",
			"deleteTesLancoMessage": "Are you sure you want to delete the treasury record?",
			"datasAlteradasComSucesso": "The new treasury dates have been saved successfully."
		},
		"strings": {
			"de": "In:",
			"ate": "Until:",
			"saldoInicial": "Opening balance:",
			"configModalTitle": "Settings",
			"tabSaldoInicialTitle": "Opening balance",
			"tabLotesTitle": "Batch Payments",
			"manual": "Manual",
			"contabilidade": "Accounting",
			"cgBanking": "CG Banking",
			"origemSaldoInicial": "Origin of the Opening Balance",
			"intervaloContas": "Account range",
			"contaDe": "Account",
			"contaAte": "Count Until",
			"intervaloContasBancarias": "Bank Account Range",
			"importaAutomaticamente": "Imports automatically",
			"rubricaParaRecEmLote": "Heading for batch receipts",
			"rubricaParaPagEmLote": "Heading for batch payments",
			"periodicidadeAnalise": "Frequency of Analysis:",
			"periodicidadeAnaliseMobile": "Frequency:",
			"updateSaldoInicial": "Update Opening Balance",
			"data": "Date",
			"valor": "Value",
			"nota": "Note",
			"dataUltimaAtualizacaoNota": "Note last updated date",
			"confirmacao": "Confirmation",
			"rubricas": "Headings",
			"valores": "Values",
			"diaria": "Daily",
			"semanal": "Weekly",
			"mensal": "Monthly",
			"rubrica": "Heading",
			"descricao": "Description",
			"documento": "Document",
			"nConta": "No.",
			"nomeConta": "Account Name",
			"novaData": "New date",
			"aplicar": "To apply",
			"valoresAnt": "Values ​​with previous treasury date",
			"novaDataTesParaTodos": "New Treasury Date for everyone"
		}
	},
	"analiticaconfig": {
		"radicaisList": "List of Radicals",
		"radicaisExcluidoList": "List of Excluded Radicals",
		"novoRadical": "New Radical",
		"novoRadicalPlaceholder": "Enter general ledger accounts with analytics for example:31",
		"novoRadicalExcluido": "New Deleted Radical",
		"novoRadicalExcluidoPlaceholder": "Enter general ledger accounts without analytics e.g. 3112",
		"messages": {
			"radicalJaExistente": "The inserted radical already exists in the list.",
			"radicalExcluidoJaExistente": "The inserted deleted stem already exists in the list.",
			"apagarItem": "Delete line",
			"desejaApagarItem": "Do you want to delete the line?",
			"successfullySaved": "Changes saved successfully.",
			"campoRadicalVazio": "Radical field cannot be empty.",
			"campoRadicalExcluidoVazio": "Deleted radical field cannot be empty."
		}
	},
	"anosFaturacao": {
		"pesquisa": "To look for",
		"fields": {
			"ano": "Year"
		}
	},
	"aplicabilidadeIRCT": {
		"data": {
			"filiacao": "Membership",
			"portariaExt": "Extension Ordinance",
			"escolha": "Choice",
			"actoGest": "Management Act",
			"sem": "No applicability",
			"auto": "Automatic"
		}
	},
	"apurainventperma": {
		"title": "Monthly calculation of permanent inventory",
		"module": {
			"steps": {
				"inventario": "End of period inventory",
				"documents": "Clearance documents to be generated",
				"preview": "Document preview"
			},
			"datagrids": {
				"infoApuramento": {
					"periodo": "Period",
					"apuramentoEfetuado": "Clearance carried out",
					"existeDocumento": "There is a document",
					"temErros": "It has errors"
				},
				"contas": {
					"codConta": "Account code",
					"designacao": "Designation",
					"total": "Total",
					"masterDetail": {
						"nConta": "Account no.",
						"nome": "Name",
						"valor": "Value"
					}
				},
				"previsualDocumentos": {
					"extPocCabID": "PocCabID",
					"periodo": "Period",
					"nContaDebito": "Debt",
					"nContaCredito": "Credit",
					"valor": "value",
					"descricao": "Description"
				}
			}
		},
		"modal": {
			"configContas": {
				"title": "Configuring clearance accounts",
				"mercadorias": {
					"title": "goods",
					"contaDeCompras": "Transfer Purchase Accounts for Goods",
					"contaDeMercadorias": "Purchase Transfer Merchandise Accounts",
					"contaDeConsumo": "Goods consumption account",
					"contaDeTransferencia": "Goods Settlement Transfer Account"
				},
				"materiaPrima": {
					"title": "Raw material",
					"contaComprasTransferenciaParaMateriasPrimas": "Transfer purchasing account for raw materials",
					"contaMateriasPrimasParaTransferenciaCompras": "Raw materials account for transfer of purchases",
					"contaConsumoMateriasPrimas": "Raw materials consumption account",
					"contaMateriasPrimasParaRegularizacao": "Raw materials account for regularization"
				},
				"materiasSubsidiarias": {
					"title": "Subsidiary matters",
					"contaComprasTransferenciaParaMateriasSubsidiarias": "Transfer Purchase Account for Subsidiary Matters",
					"contaMateriasSubsidiariasParaTransferenciaCompras": "Subsidiary materials account for transfer of purchases",
					"contaConsumoMateriasSubsidiarias": "Subsidiary materials consumption account",
					"contaMateriasSubsidiariasParaRegularizacao": "Subsidiary matters account for regularization"
				},
				"embalagens": {
					"title": "Packaging materials",
					"contaComprasTransferenciaParaMateriaisEmbalagens": "Purchase accounts transfers for packaging materials",
					"contaMateriaisEmbalagensParaTransferenciaCompras": "Packaging materials account for purchase transfers",
					"contaConsumoEmbalagensParaConsumo": "Consumer packaging consumption account",
					"contaMateriaisEmbalagensParaRegularizacao": "Account of packaging materials for regularization"
				},
				"materiaisDiversos": {
					"title": "Miscellaneous materials",
					"contaComprasTransferenciaParaMateriaisDiversos": "Purchase account transfers for miscellaneous materials",
					"contaMateriaisDiversosParaTransferenciaCompras": "Miscellaneous materials account for purchase transfers",
					"contaConsumoMateriaisDiversosParaConsumo": "Account for consumption of miscellaneous materials",
					"contaMateriaisDiversosParaRegularizacao": "Bill of miscellaneous materials for regularization"
				},
				"materiasTransito": {
					"title": "Matters in transit",
					"contaComprasTransferenciaParaMateriasTransito": "Purchase account of transfers for miscellaneous materials",
					"contaMateriasTransitoParaTransferenciaCompras": "Materials in transit account for purchase transfers",
					"contaConsumoMateriasTransitoParaConsumo": "Consumption account for materials in transit",
					"contaMateriasTransitoParaRegularizacao": "Account of materials in transit for regularization"
				},
				"produtosAcabados": {
					"title": "Finished products",
					"contaVariacaoProducaoParaProdutosAcabados": "Production Variance Account for Finished Goods",
					"contaRegularizacaoExistenciaParaProdutosAcabados": "Existence regularization account for finished products"
				},
				"subProdutos": {
					"title": "By-products",
					"contaVariacaoProducaoParaSubprodutos": "Production variance account for by-products",
					"contaRegularizacaoExistenciaParaSubprodutos": "Existence regularization account for by-products"
				},
				"produtosTrabalhosEmCurso": {
					"title": "Products and work in progress",
					"contaVariacaoProducaoParaProdutosTrabalhosEmCurso": "Account for variation in production of products and work in progress",
					"contaRegularizacaoExistenciaParaProdutosTrabalhosEmCurso": "Account regularization of existence of products and work in progress"
				},
				"activosBiologicosCompras": {
					"title": "Purchase biological assets",
					"contaComprasTransferenciaParaActivosBiologicosConsumo": "Account for purchases of transfers for biological consumer assets",
					"contaActivosBiologicosConsumoParaTransferenciaCompras": "Account biological consumer assets for purchase transfers",
					"contaConsumoActivosBiologicosConsumoParaConsumo": "Account consumption of biological consumer assets",
					"contaActivosBiologicosConsumoParaRegularizacao": "Account for biological consumption assets for regularization"
				},
				"activosBiologicosProducao": {
					"title": "Biological production assets",
					"contaExistenciaActivosBiologicosProducao": "Existence account for biological production assets",
					"contaVariacaoProducaoParaActivosBiologicosProducao": "Accounts for production variation for biological assets production",
					"contaRegularizacaoExistenciaParaActivosBiologicosProducao": "Account regularization of existence of biological production assets"
				}
			}
		},
		"btns": {
			"configContas": "Account setup"
		},
		"actions": {
			"verDocumentoApuramento": "See clearance document for this period",
			"apagarDocumentoDeApuramento": "Delete clearance document"
		},
		"menagens": {
			"valoresOriginaisRepostos": "Original values ​​successfully reset",
			"savedSuccess": "Account configuration saved successfully",
			"apuramentoJaExiste": "Clearance has already been carried out",
			"periodoAlreadyHasApuramento": "You have already carried out the monthly calculation of the permanent inventory for the period '{{period}}'.",
			"documentosGravadosSucesso": "Documents saved successfully",
			"apagarDocumentoApuramentoTitle": "Delete clearance document",
			"apagarDocumentoApuramentoMessage": "Do you really want to delete the calculation document for the period {{period}}?",
			"naoDeveFazerOApuramentoDeInventario": "The monthly calculation of the permanent inventory in period 12 must be done using the results calculation application."
		}
	},
	"apuraiva": {
		"success": "VAT clearance completed successfully",
		"promptAlreadyExistsTitle": "VAT calculation has already been carried out",
		"promptAlreadyExistsMessage": "The VAT calculation for the period \"{{period}}\" has already been carried out.",
		"errorShortValue": "Has {{value}} more than the calculated value",
		"errorExtraValue": "There are {{value}} left to complete the count",
		"saveTitle": "VAT clearance",
		"saveMessage": "Are you sure you want to complete the VAT calculation?",
		"docsporintegrar": "There are documents to be included in the accounts relating to the VAT period being calculated.",
		"saveSeeDoc": "Save and view generated document",
		"errorInvalidPeriodoMensal": "The period {{period}} is invalid in the monthly period list",
		"errorInvalidPeriodoTrimestral": "The period {{period}} is invalid in the list of quarterly periods",
		"apurado": "determined",
		"steps": {
			"filter": "VAT Clearance Filter",
			"apuramento": "Calculation of VAT in the Period",
			"fimperiodo": "End of Accounting Period"
		},
		"fields": {
			"descriptive": "Descriptive",
			"period": "Period",
			"periodCurrent": "Current period",
			"periodNext": "New period",
			"diarios": "Diaries to close",
			"ivaDedutivel": "Deductible VAT amount",
			"ivaLiquidado": "VAT amount paid",
			"ivaApurado": "Amount calculated",
			"aFavorDaEmpresa": "Recipient",
			"aFavorDaEmpresa0": "In favor of the company",
			"aFavorDaEmpresa1": "In favor of the state",
			"ivaRegFavEmpresa": "Monthly/quarterly payments in favor of the company",
			"ivaRegFavEstado": "Monthly/quarterly in favor of the state",
			"ivaRegCalculoProRata": "Annually based on calculation of the definitive pro rata",
			"ivaVariacoesProRata": "Annual due to variations in the definitive pro rata",
			"ivaRegComunicadasPeloSIVA": "In favor of the Company Communicated by SIVA",
			"ivaRegOutrasAnuais": "Other annual regularizations",
			"saldoAnterApuramento": "Previous clearance",
			"saldoAnterIVAAPagar": "VAT payable",
			"saldoAnterIVAARecuperar": "VAT to be recovered",
			"saldoAnterIVAAReembolsar": "VAT to be refunded",
			"ivaAPagar": "VAT payable",
			"ivaARecuperar": "VAT to be recovered",
			"ivaAReembolsar": "VAT to be refunded"
		},
		"titles": {
			"regularizations": "Regularizations",
			"details": "Detailed clearance information",
			"information": "Information on previous balances",
			"destination": "Destination of calculated values",
			"tabDestination": "Calculation destination and previous balances",
			"tabDetails": "VAT clearance details",
			"change": "Change of accounting period"
		}
	},
	"apuramentoresultados": {
		"title_complex": "Calculation of net results - {{year}}",
		"title_complex_apurado": "Calculation of net results - {{year}} (calculated)",
		"pesquisa": "To look for",
		"success": "Results calculation carried out successfully",
		"deleted": "Results calculation successfully deleted",
		"beforeDeleteModalMessage": "Are you sure you want to eliminate the tabulation of results?",
		"beforeDeleteModalTitle": "Confirmation",
		"promptAlreadyExistsTitle": "Calculation of results has already been carried out",
		"promptAlreadyExistsMessage": "The calculation of results for the year \"{{year}}\" has already been carried out.",
		"anoapuramento": "Year of calculation: {{year}}",
		"tipoinvpermanente": "Perpetual inventory type",
		"steps": {
			"variables": "Clearance variables",
			"documents": "Clearance documents to be generated",
			"preview": "Document preview",
			"finished": "Finished"
		},
		"fields": {
			"codConta": "Account Code",
			"nConta": "Account No.",
			"nomeConta": "Designation",
			"nome": "Name",
			"total": "Total",
			"valor": "Value",
			"descritivo": "Descriptive",
			"dataDoc": "Doc Date",
			"description": "Description"
		},
		"titles": {
			"generatedDocs": "Generated documents"
		},
		"errorInvalidData": "Please fill in the fields to process",
		"errorMissingProcess": "Please process the data before saving",
		"toolbar": {
			"analise": "Analysis",
			"infoSaldosInv": "Information about inventory account balances",
			"docsSaldosErrados": "Documents Inconsistent with Centralgest Permanent Inv.",
			"docsContasInventario": "Documents with manual entries in inventory accounts",
			"delete": "Delete clearance"
		},
		"modal": {
			"saldoscontinv": {
				"title": "Information about inventory account balances",
				"periodo": "Period",
				"compras": "Shopping",
				"produtos": "Products",
				"fields": {
					"nomeConta": "",
					"saldoInicial": "Opening balance",
					"compras": "Illiquid Purchases",
					"devolucao": "Devolution",
					"descontos": "Discounts",
					"totCompras": "Total purchases",
					"comprasTrf": "Shopping trf.",
					"difCompras": "Diff.",
					"saldoFinal": "Final balance",
					"regularizacoesRegistadas": "Registered records",
					"regularizacoesTrfConsumo": "trf reg.",
					"difRegularizacoes": "Diff.",
					"consumo": "Consumption",
					"consCalculado": "Cons.",
					"difConsumo": "Diff.",
					"regularizacoesTrfVariacao": "trf reg.",
					"difRegularizacoesProducao": "Diff.",
					"variacaoProducao": "Production variation",
					"varProducaoCalc": "Calculated production variation",
					"difProducao": "Diff."
				}
			},
			"docscontasinventario": {
				"naoExistemDocumentosComMovimenta": "There are no documents with movement of inventory accounts.",
				"naoExistemDocumentosComSaldosDeC": "There are no documents with wrong inventory account balances.",
				"title": "Documents with manual entries in inventory accounts",
				"titleIcons": "Documents Inconsistent with Centralgest Permanent Inv.",
				"fields": {
					"docMovConta": "Information",
					"inconsistenciasDocApu": "Inconsistency",
					"periodo": "Period",
					"nDiario": "Daily",
					"nDocinterno": "Document No."
				}
			}
		}
	},
	"arclis": {
		"title_detail": "Customer/Supplier Article",
		"title_new": "New Customer/Supplier Article",
		"title_plural": "Customer/Supplier Items",
		"pesquisa": "To look for",
		"saved": "Customer/supplier item, saved successfully.",
		"error": "Customer/supplier item cannot be saved.",
		"deleted": "Customer/supplier item, successfully deleted.",
		"deleteModalText": "Delete record \"{{nClifoArtigo}}\"?",
		"fields": {
			"nClifoArtigo": "Item code from cli./forn.",
			"nArtigo": "Article Code",
			"nConta": "Customer supplier",
			"descricaoFornecedor": "Item description from cli./forn."
		},
		"btn": {
			"newArtigo": "New"
		},
		"list": {
			"actions": {
				"editar": "To edit"
			}
		}
	},
	"areaRegionalTaxa": {
		"pesquisa": "Regional tax areas",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"continent": "Continent",
			"madeira": "Wood",
			"acores": "Azores"
		}
	},
	"areasRegionais": {
		"data": {
			"0": "Continent",
			"1": "Wood",
			"2": "Azores",
			"continente": "Continent",
			"madeira": "Wood",
			"acores": "Azores"
		}
	},
	"areasRegionaisControloIVA": {
		"data": {
			"0": "AT",
			"1": "Continent",
			"2": "Wood",
			"3": "Azores",
			"notApplicable": "AT",
			"continente": "Continent",
			"madeira": "Wood",
			"acores": "Azores"
		}
	},
	"armazens": {
		"title_detail": "Warehouse {{id}}",
		"title_new": "New Warehouse",
		"title_plural": "Warehouses",
		"pesquisa": "To look for",
		"saved": "Warehouse {{id}}, saved successfully.",
		"error": "Storage {{id}}, cannot be stored.",
		"deleted": "Warehouse {{id}}, successfully deleted.",
		"fields": {
			"nArmazem": "Code.",
			"nArmazemPlaceholder": "Warehouse code",
			"nome": "Name",
			"nomePlaceholder": "Warehouse name",
			"morada1": "Household",
			"morada1Placeholder": "Warehouse address",
			"morada2": "Household",
			"codPostal": "Postcode",
			"codPostalPlaceholder": "Warehouse zip code",
			"nomeCPostal": "Location",
			"nomeCPostalPlaceholder": "Warehouse location",
			"codPais": "Country Code",
			"codPaisPlaceholder": "Warehouse country",
			"nomePais": "Country",
			"nomePaisPlaceholder": "Warehouse country",
			"nTelefone": "Telephone",
			"nTelefonePlaceholder": "Warehouse telephone",
			"nFax": "Fax",
			"nFaxPlaceholder": "Warehouse fax"
		}
	},
	"arquivodigital": {
		"errors": {
			"licenseNotActivated": "The current company does not have the digital file license activated.",
			"licenseNotActivatedCGOn": "The current company does not have the digital archive license activated.<br/>Please go to the <a href=\"{{href}}\" target=\"_blank\" rel=\"noopener noreferrer\">store</a> CentralGest Cloud and activate or renew the digital accounting license."
		},
		"gestaodocsdigitalizados": {
			"actions": {
				"obterDadosEFatura": "Get data from the selected document from eFatura (needs NIF and document date filled in)",
				"atribuirTipoDocumento": "Assign document type",
				"unirDocs": "Merge selected documents",
				"separarDoc": "Separate document pages",
				"copiarDoc": "Copy document",
				"removePages": "Remove page(s) from document",
				"apagar": "Delete selected document(s)",
				"removeassociation": "Remove association with accounting document(s)",
				"verDoc": "View accounting document",
				"novaContaCC": "Create new checking account",
				"exportarArquivo": "Export digital file",
				"adicionardoc": "Add document(s)",
				"mudardocpasta": "Change folder selected document(s)",
				"reaplicarocr": "Reapply OCR to the document",
				"predefinidos": "Predefined Configuration for the entity",
				"docsContabSemContabDig": "Accounting Documents without Digital Document",
				"robo": "Launch documents using CentralGest AI Robot",
				"extratoConta": "View account statement",
				"associardocsauto": "Automatically associate all documents",
				"possiveisDocsRepetidos": "View possible duplicate documents"
			},
			"pesqavancada": {
				"title": "Advanced Search",
				"nifDe": "From NIF",
				"nifAte": "Until NIF",
				"docExternoDe": "From External Doc.",
				"docExternoAte": "Even External Doc",
				"dataDocExternoDe": "Date External Doc.",
				"dataDocExternoAte": "Until date External Doc.",
				"dataRececaoDe": "From date of receipt",
				"dataRececaoAte": "Until date of reception",
				"mostrarDocClassificados": "Also show documents already classified",
				"mostraApenasLancRobot": "Show only documents already classified by the robot"
			},
			"table": {
				"fields": {
					"folder": "Folder",
					"nif": "NIF",
					"entidade": "Entity",
					"temNIFEmpresa": "Has Company NIF",
					"invoiceTypeStr": "Document Type",
					"dataDoc": "Date",
					"nDocumento": "External Doc",
					"totalBase": "Total Base",
					"totalIVA": "Total VAT",
					"total": "Total",
					"pasta": "Folder",
					"ficheiro": "File",
					"fiscalmenteRelevante": "Fiscally Relevant",
					"dataRececao": "Receipt Date",
					"classficadoPor": "Sorted By",
					"ndocContabilidade": "Accounting Doc. No.",
					"ndocumentoSAFT": "SAFT Document No.",
					"temNIFEmpresamin": "Has Company NIF",
					"invoiceTypeStrmin": "Type Doc.",
					"fiscalmenteRelevantemin": "Fiscally Relevant",
					"classficadoPormin": "Class.",
					"ndocContabilidademin": "Accounting Doc. No.",
					"ndocumentoSAFTmin": "SAFT Doc. No.",
					"preDefenidosID": "Pre-Defined",
					"actions": {
						"adicionardoc": "Add document(s)",
						"mudardocpasta": "Change folder selected document(s)",
						"reaplicarocr": "Reapply OCR to the document",
						"obterDadosEFatura": "Get data from eFatura document(s)",
						"atribuirTipoDocumento": "Assign document type",
						"unirDocs": "Merge selected documents",
						"separarDoc": "Separate document",
						"copiarDoc": "Copy document",
						"removePages": "Remove page(s)",
						"apagar": "Delete selected document(s)",
						"verDoc": "View accounting document",
						"novaContaCC": "Create new checking account"
					},
					"docSemelhante": {
						"nDocumento": "Document No.",
						"nDocExterno": "External Doc. No.",
						"nContrib": "VAT Number",
						"descricao": "Description",
						"dataDocExt": "Document date",
						"docspossiveis": "Documents possible to associate"
					}
				},
				"havechanges": "There are changes not yet saved."
			},
			"legend": {
				"error": "Value not obtained or wrong",
				"warning": "Value obtained but must be reviewed",
				"waitingocr": "Document without OCR performed",
				"comdocparaassociar": "With accounting document to associate",
				"docassociado": "Already associated with accounting document"
			},
			"messages": {
				"confirm": "Confirmation",
				"confirmDelete": "Are you sure you want to delete the selected document?",
				"confirmDeletePlural": "Are you sure you want to delete the selected documents?",
				"exitTitle": "Do you want to exit without saving the changes?",
				"exitMessage": "Clicking OK will exit without saving and all changes will be deleted.",
				"updateWithChangesTitle": "Do you want to refresh the data without saving the changes?",
				"updateWithChangesMessage": "Clicking OK will refresh the data without saving and all changes will be erased.",
				"naotemalteracoes": "There are no changes to save.",
				"docvalidadoefatura": "Document found and validated by eFatura.",
				"docQR": "Document uploaded by QR Code",
				"contabDigitalLancadoRoboSuccess": "Selected documents successfully released",
				"lancrobotdoc": "Document {{filename}}.",
				"deleteAttachment": {
					"titleDelete": "Delete digital document",
					"titleRemoveAssociation": "Remove accounting document association",
					"messageRemoveAssociation": "Do you want to remove the association of this digital document with the accounting document?<br/>When you remove the association, the document becomes available again in digital document management as unclassified."
				},
				"modal": {
					"treedocsnaoclassificados": {
						"escolhapastaadd": "Choose the folder where you want to add the documents",
						"errorMoveDocSameFolder": "You cannot move the document to the same folder where it is located."
					},
					"adicionardocumentos": {
						"escolhadocumentos": "Choose the document(s)",
						"success": "Documents added successfully!"
					},
					"export": {
						"title": "Exporting the digital file",
						"ano": "Year",
						"periodoDe": "Period of",
						"periodoAte": "until",
						"diarioDe": "Diary of",
						"diariosAte": "until",
						"nifDe": "NIF of",
						"nifAte": "until",
						"exportaNaoClassificados": "Exports only unclassified documents",
						"exportaApenasRelevantes": "Exports only fiscally relevant documents",
						"exportar": "Export",
						"folder": "Folder:",
						"searchAllFolders": "Search all folders",
						"searchExportFolder": "Search folder to export"
					},
					"exportfolder": {
						"documentosClassificados": "Classified documents",
						"documentosNaoClassificados": "Unclassified documents"
					}
				},
				"docsassociados": "Document/s successfully associated.",
				"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmation",
				"leavePromptMessage": "Clicking YES will exit and discard the generated file."
			},
			"alerts": {
				"deletesuccess": "Document(s) deleted successfully!",
				"associacaoremoved": "Document(s) association successfully removed!",
				"selsamefolder": "You must select documents from the same folder."
			},
			"btn": {
				"alterapasta": "Change folder",
				"refresh": "Refresh Data",
				"associar": "Connect"
			},
			"export": {
				"processLabel": "Export: Preparing the file...",
				"processDownloadTooltip": "Download the file",
				"downloadText": "Download",
				"zipReadyText": "The file is ready.",
				"downloadError": "An error occurred while downloading the file!",
				"jobTimeoutModalTitle": "Task",
				"jobTimeoutModalMessage": "The task has timed out.",
				"fileNotProcessed": "We're sorry, but for some reason the file was not generated."
			},
			"errors": {
				"addFilesDisallowed": "Your digital archive module license does not currently allow you to add more digital documents.<br/>Please go to <a href=\"{{href}}\" target=\"_blank\" rel=\"noopener noreferrer\"> CentralGest Cloud store</a> and activate or renew the digital file license."
			},
			"docscontabsemdocdigital": {
				"title": "Accounting Documents without Digital Document",
				"header": {
					"periodo": "Period",
					"diario": "Daily",
					"ate": "Until"
				},
				"table": {
					"periodo": "Period",
					"nDiario": "Daily",
					"nDocInterno": "Internal Doc. No.",
					"debito": "Debt",
					"credito": "Credit",
					"nDocExterno": "External Doc. No.",
					"dataDoc": "Date Doc.",
					"dataDocExterno": "External Doc Date",
					"descritivo": "Descriptive"
				}
			}
		},
		"genericviewer": {
			"text": {
				"currentFolder": "Current folder",
				"root": "Source"
			}
		},
		"treeviewer": {
			"actions": {
				"addDoc": "Add documents to this folder",
				"captureDoc": "Capture document and add to current folder",
				"deleteDocs": "Delete all documents in this folder",
				"deleteDoc": "Delete document"
			}
		},
		"flatviewer": {
			"titles": {
				"folders": "Folders",
				"files": "Files",
				"empty": "(Empty)"
			}
		},
		"docviewer": {
			"actions": {
				"zoomIn": "Zoom in",
				"zoomOut": "Zoom out",
				"rotateLeft": "Rotate left",
				"rotateRight": "Rotate right"
			}
		},
		"docviewerrecolha": {
			"title": {
				"location": "Location",
				"attachment": "Attachment",
				"addDocs": "Add documents",
				"addDocsCurrentFolder": "Add documents to current folder",
				"addDocsWithPath": "Add documents to current folder ({{folderPath}})",
				"attachDoc": "Attach digital document",
				"chooseFolder": "Choose folder",
				"scannedDocumentManagement": "Scanned document management",
				"config": "Settings",
				"deleteAttachment": "Remove attachment",
				"download": "Transfer document",
				"noAttachment": "No digital document",
				"attachmentNotFound": "Digital document not found",
				"attachmentFound": "Digital document found",
				"attachmentPossiblyFound": "We have found a possible digital document, please check if the suggested digital document matches the document you are posting",
				"attachmentMultipleFound": "We have found several possible digital documents, please check if the suggested digital document matches the document you are posting",
				"linhasIVA": {
					"valorBase": "Incidence",
					"valorIVA": "VAT",
					"tipoTaxa": "Type of fee",
					"areaRegional": "Regional area",
					"taxa": "Rate"
				},
				"totais": {
					"baseTributavel": "Taxable base",
					"totalIVA": "Total VAT",
					"valorRetencao": "Retention value",
					"total": "Total"
				}
			},
			"navigation": {
				"selectFirstAttachment": "Go to first attachment",
				"selectPreviousAttachment": "Previous",
				"selectNextAttachment": "Following",
				"selectLastAttachment": "Go to last attachment"
			},
			"actions": {
				"attachExistingDoc": "Attach an existing document in digital accounting to the current document",
				"attachNewDoc": "Add new document in digital accounting and attach to current document",
				"attachVerbete": "Add entry to digital document",
				"deleteAttachment": {
					"titleDelete": "Delete digital document",
					"titleRemoveAssociation": "Remove accounting document association",
					"messageDelete": "Are you sure you want to delete the document?<br/><span class=\"text-danger\">Attention:</span> if the document was sent by your accounting services client when deleting it you will have to ask this be resent.",
					"messageRemoveAssociation": "Do you want to remove the association of this digital document with the accounting document?<br/>When you remove the association, the document becomes available again in digital document management as unclassified."
				}
			},
			"text": {
				"warningHasDigitalDoc": "The document \"{{periodo}}.{{nDiario}}.{{nDocInterno}}\" already has at least one associated digital document.<br/>Are you sure you also want to attach another digital document?",
				"successAttachDoc": "Digital document successfully attached",
				"successAttachVerbete": "Entry successfully added to the digital document",
				"warningDateMismatch": "You are attaching a digital document that has been set with the date \"{{dataDoc}}\" and your accounting document has the date \"{{dataDocDigital}}\".<br/>Are you sure you want to continue?",
				"valuesSource": {
					"0": "OCR Reading",
					"1": "Barcode reading",
					"2": "e-Invoice"
				},
				"documento": "Document: {{nDocument}}",
				"emptyLocation": "Click here to choose the digital accounting document folders.",
				"emptyAttachments1": "No documents in folder \"{{location}}\".",
				"emptyAttachments2": "Click here to change folders."
			},
			"choosefoldermodal": {
				"title": "Choosing document folders",
				"titleFileRequired": "Choosing a digital document"
			},
			"attachexistingdocmodal": {
				"title": "Attach new document"
			},
			"helptopicsmodal": {
				"faq": {
					"basicConcepts": "Basic concepts of digital archive",
					"addDocuments": "Add digital documents to the digital archive",
					"showHideColumns": "Show and hide columns in the digital document grid",
					"setFileFolders": "Define digital file folders",
					"moveAttachmentToAnotherFolder": "Move the digital document to another folder",
					"createCheckingAccountFromAttachment": "Create a current account from the digital document",
					"speedUpManualAccountingRecordingWithAttachmentData": "Speed ​​up manual accounting recording with digital document data",
					"addDocumentsThroughPreDefined": "Post digital documents to accounting through pre-defined"
				}
			}
		},
		"clientconnect": {
			"actions": {
				"addDocs": "Add documents to current folder",
				"captureDoc": "Capture document and add to current folder",
				"deleteDoc": {
					"btn": "Delete document",
					"title": "Delete document \"{{name}}\"?",
					"message": "Are you sure you want to delete the selected document?"
				}
			},
			"tooltips": {
				"addDocs": "Please select a valid folder to add documents",
				"captureDoc": "Please select a valid folder to capture a document",
				"deleteDoc": "Please select a document to delete it"
			},
			"text": {
				"successDeleteDoc": "Document deleted successfully"
			},
			"adddocsmodal": {
				"title": "Add documents",
				"sendDocs": "Send files",
				"success": "Documents added successfully"
			},
			"deletedocsmodal": {
				"title": "Delete documents",
				"message": "Are you sure you want to delete documents from the \"{{path}}\" folder?"
			}
		},
		"activatelicensemodal": {
			"text": {
				"total": "Total number of companies: {{total}}",
				"totalConsumed": "Total number of companies already added: {{totalConsumed}}",
				"totalToConsume": "Total number of companies you can still add: {{totalToConsume}}"
			},
			"types": {
				"0": {
					"title": "Activate digital file at company \"{{nEmpresa}} - {{nomeEmpresa}}\"",
					"license1": "If you click \"Yes\", the system will add a new company to your digital file license, and it will not be possible to reverse the situation later.",
					"license2": "At this time, you can still add {{totalToConsume}} companies to your digital archive license.",
					"dadosLicenca": "Digital Archive license data:",
					"limitReached": "You cannot add more companies to your digital archive license, because you already have \"{{consumed}}\" \"{{total}}\" companies registered.",
					"errorCGOnMode": "You cannot activate the digital file, because this is a CGOn company."
				},
				"1": {
					"title": "Activate client connect at company \"{{nCompany}} - {{nomeEmpresa}}\"",
					"license1": "If you click \"Yes\", the system will add a new company to your client connect license, and it will not be possible to reverse the situation later.",
					"license2": "At this time, you can still add {{totalToConsume}} companies to your client connect license.",
					"dadosLicenca": "Client Connect license details:",
					"limitReached": "You cannot add more companies to your client connect license, because you already have \"{{consumed}}\" \"{{total}}\" companies registered.",
					"errorCGOnMode": "You cannot activate client connect, because this is a CGOn company."
				}
			}
		},
		"atribuirtipodocumentomodal": {
			"title": "Indicate the type of document you want to assign",
			"label": "Document Type",
			"required": "You must indicate the type of document you want.",
			"success": "Document type assigned successfully."
		},
		"checkfolderscreatedmodal": {
			"title": "Initializing digital file",
			"message": "Please wait while the digital archive is being initialized."
		},
		"copiardocmodal": {
			"title": "Copy document",
			"body": "Are you sure you want to create a copy of the document \"{{name}}\"?",
			"success": "Copy of document created successfully.<br/>File name: \"{{name}}\"."
		},
		"novacontaccmodal": {
			"title": "Create new checking account",
			"fields": {
				"radical": "Radical",
				"nConta": "Account number to create",
				"nome": "Name"
			},
			"successModal": {
				"title": "View the {{type}} profile",
				"message": "{{type}} current account successfully created.<br/>Do you want to edit its account?",
				"types": {
					"fornecedor": "supplier",
					"cliente": "client",
					"outroDevedorCredor": "another debtor/creditor"
				}
			}
		},
		"removepagesmodal": {
			"title": "Indicate the page(s) you want to remove",
			"label": "Page(s) to remove",
			"help1": "If you want to remove more than one page, separate them with a comma or indicate a range.",
			"help2": "For example: 1,4,5 - removes pages 1, 4 and 5.",
			"help3": "For example: 6-10 - removes pages 6, 7, 8, 9 and 10.",
			"success": "Page(s) removed successfully."
		},
		"separardocmodal": {
			"title": "Separate document",
			"body1": "Do you want to keep the original document?",
			"success": "Document separated successfully!"
		},
		"unirdocsmodal": {
			"title": "Merge documents",
			"body1": "By carrying out this operation you will merge the document(s):",
			"body2": "To the document:",
			"body3": "Are you sure you want to continue?",
			"success": "Documents merged successfully."
		},
		"predefinidosconfig": {
			"title": "Predefined settings by NIF/Document type",
			"fatura": "Invoice",
			"faturaSimplificada": "Simplified Invoice",
			"faturaRecibo": "Invoice/Receipt",
			"notaDebito": "Debit Note",
			"notaCredito": "Credit Note",
			"vendaDinheiro": "Sell ​​for Cash",
			"talaoVenda": "Sales Receipt",
			"talaoDevolucao": "Return Slip",
			"alienacaoAtivos": "Asset Disposal",
			"devolucaoAtivos": "Return of Assets",
			"premio": "Prize or prize receipt",
			"estorno": "Refund or refund receipt",
			"imputacaoSeguradoras": "Imputation to co-insurers",
			"imputacaoSegLider": "Imputation to co-sec.",
			"recibo": "Receipt",
			"pagamentoEstado": "Payment to the State",
			"levantamento": "Survey",
			"pagamento": "Payment",
			"deposito": "Deposit",
			"cheque": "Check",
			"transferencia": "Transfer",
			"efaturainfo": "This value was obtained from the eFatura settings for this taxpayer.",
			"success": "Configuration saved successfully.",
			"predefnotacredito": "The default for credit notes must not be the same as for other document types.",
			"warningEFatura": "*If you want to post documents via eFatura, you do not need to configure pre-defined ones",
			"lancfaturahint": "Click for help with this feature",
			"lancfaturahinttitle": "Automatic launches by Robot",
			"lancfaturahintinfo": "If you activate robot postings in this entity, the system will post the documents to accounting automatically after they are added."
		},
		"configs": {
			"tabs": {
				"defgerais": {
					"title": "General settings",
					"carddefgerais": "General settings",
					"cardverbete": "Document entry",
					"cardconfigpastas": "Folder configuration"
				},
				"efatura": {
					"title": "eInvoice",
					"comprasefatura": "eInvoice Purchases",
					"defoticocaracteres": "Optical Character Recognition (OCR) Settings"
				},
				"lancautorobo": {
					"title": "Automatic launches by Robot",
					"lancaDocsAuto": "Performs robot postings for all documents automatically after they are added",
					"origemPeriodoLancAuto": "Origin of the period in launches by robot or suggestion",
					"tabelaempresa": "Company table",
					"datadoc": "Date of document",
					"message1": "If you activate robot postings for all documents, the system will post the documents to accounting automatically after they are added.",
					"message2": "The system will be able to post documents with a barcode or that exist in eFatura and for which it finds a posting model previously made in accounting for the document's NIF and with the same VAT rates.",
					"message3": "As you release digital accounting documents, the system learns to make the entries for you.",
					"message4": "You can also activate this entity-by-entity functionality in the digital document grid by clicking the right mouse button on the desired entity line and then entity configuration."
				},
				"opcoesocr": {
					"titleSimple": "OCR Options",
					"title": "OCR (Optical Character Recognition) options",
					"cardtitle": "Taxpayers to separate documents using QRCode (for example EDP or Via Verde invoices)",
					"removePaginasBrancoAuto": "Automatically removes blank pages (this feature should only be used if you use a scanner recommended by CentralGest)",
					"table": {
						"nif": "VAT Number",
						"descricao": "Name",
						"addedpviaverde": "Add EDP/Via Verde",
						"addedp": "Add EDP",
						"addviaverde": "Add Via Verde"
					}
				}
			},
			"title": "Digital accounting configuration",
			"success": "Configuration saved successfully",
			"fields": {
				"anexaVerbete": "Add entry to scanned documents",
				"reportVerbete": "Listing",
				"dataLancamIgualDataDoc": "On collection posting date equal to document date when getting doc data.",
				"abreSempreVisualizador": "When opening a collection, it shows digital accounting",
				"ordenacaoVisualizador": "Ordering of documents",
				"usaEFaturaOCR": "Uses eFatura data (obtains better results when filling out totals)",
				"recolhaAnexaAuto": "When collecting, it automatically attaches a document.",
				"pasta": "Folder",
				"nomePasta": "Folder name",
				"nDiario": "Daily",
				"nDescritivo": "Descriptive",
				"visivel": "Visible",
				"searchEFaturaSoNdocParcial": "When searching for documents in eFatura mode it only shows possible documents with similar document no.",
				"searchEFaturaSoMesmoDiario": "When searching for documents in eFatura mode it only shows possible documents with the same journal between Pre-Defined and document folder",
				"entidadeHabitual": "Usual entity",
				"nCodRepcc": "C. Cost Breakdown",
				"usaDiarioConfigSugestLancam": "In the launch suggestion, use diaries configured in the folders",
				"eFaturaOCR": "Use eFatura OCR",
				"fazOCR": "Performs OCR",
				"sugereBanking": "Suggest in Banking",
				"nDocfaFT": "Credit invoice",
				"nDocfaFR": "Invoice/Receipt",
				"nDocfaNC": "Credit note",
				"nDocfaNCPagaNoAto": "No.",
				"nCCusto": "cost center",
				"nRefProcesso": "Process"
			},
			"actions": {
				"obterChaveSincro": "Get client connect sync key from this company",
				"ativarClientConnect": "Activate client connect in this company"
			},
			"messages": {
				"clientConnectAlreadyActivated": "This company already has client connect active",
				"activatedClientConnect": "Client connect successfully activated in your company",
				"opcocraddednif": "Taxpayer number successfully added to the list",
				"opcocraddednifedp": "Number of EDP taxpayers successfully added to the list",
				"opcocraddednifviaverde": "Via Verde Taxpayer Number successfully added to the list",
				"opcocranifalreadyexists": "Taxpayer number already exists in the list",
				"repccinfo": "Cost center distribution code (can be used to automatically post cost center based on robot launch suggestion)"
			},
			"configPastas": {
				"title": "Folder configuration"
			},
			"licencas": {
				"title": "Confirmation",
				"message": "'If you click \"Yes\", the system will add a new company to your client connect digital accounting license, and it will not be possible to reverse the situation later.<br/>At this time, you can still add to your client connect digital accounting license {{nCompaniesAdd}} companies."
			},
			"synchronizationKey": {
				"title": "Sync key for Client Connect",
				"key": "Sync Key"
			},
			"tipoOrdenacaoVisualizador": {
				"0": "Taxpayer No. |",
				"1": "Date |",
				"2": "Receipt date",
				"3": "Name |",
				"4": "Date |",
				"5": "File numbering",
				"nifData": "Taxpayer No. |",
				"dataNif": "Date |",
				"dataRececao": "Receipt date",
				"nomeData": "Name |",
				"dataNome": "Date |",
				"nomeFicheiro": "File numbering"
			}
		}
	},
	"artars": {
		"title_detail": "Warehouse item",
		"title_new": "New Warehouse Item",
		"title_plural": "Warehouse Items",
		"pesquisa": "To look for",
		"saved": "Warehouse item, successfully stored.",
		"error": "Warehouse item, cannot be stored.",
		"deleted": "Warehouse item, successfully deleted.",
		"fields": {
			"nArtigo": "Article No.",
			"nArmazem": "Warehouse No.",
			"stockMinimo": "Minimum stock",
			"stockMaxIMO": "Maximum stock",
			"stockReposic": "Stock replenishment",
			"qtdMinEncome": "Qty. min.",
			"qtdMaxEncome": "Max qty.",
			"qtdRepEncome": "Qty. rep.",
			"despPorEncom": "Expense",
			"txCustoPosse": "Ownership cost rate",
			"nClifoHabitual": "Usual clifo no.",
			"nDiasValidade": "Number of days validity",
			"nSectorLocal": "Local Sector No.",
			"qtdLimArmazenamento": "Lim qty.",
			"localExcedentes": "Surplus location",
			"stockAlerta": "Stock alert"
		}
	},
	"artigoclasses": {
		"title_detail": "Article class {{id}}",
		"title_new": "New article class",
		"title_plural": "Article classes",
		"pesquisa": "To look for",
		"saved": "Article class {{id}}, saved successfully.",
		"error": "Article class {{id}}, cannot be saved.",
		"fields": {
			"classe": "Code.",
			"classePlaceholder": "Article class code",
			"descricao": "Description",
			"descricaoPlaceholder": "Article class description"
		}
	},
	"artigos": {
		"title_detail": "Article {{id}}",
		"title_new": "New Article",
		"title_plural": "Articles",
		"pesquisa": "To look for",
		"saved": "Article {{id}}, saved successfully",
		"error": "Article {{id}}, cannot be saved.",
		"deleted": "Article {{id}}, successfully deleted",
		"fields": {
			"nArtigo": "Article code",
			"nArtigoPlaceholder": "Article code, if not filled in, will be generated automatically.",
			"nome": "Article name",
			"nomePlaceholder": "Article name",
			"nFamilia": "N. Family",
			"nFamiliaPlaceholder": "family code",
			"nomeFamilia": "Family",
			"nomeFamiliaPlaceholder": "Family name",
			"nTpArt": "Type",
			"nTpArtPlaceholder": "Article type",
			"nomeTpArt": "Article type",
			"nomeTpArtPlaceholder": "Article type name",
			"nGrFamilia": "Gr. family",
			"nGrFamiliaPlaceholder": "Big family",
			"nomeGrFamilia": "Gr. family",
			"nomeGrFamiliaPlaceholder": "Big family name",
			"nSubFa": "Subfamily",
			"nSubFaPlaceholder": "subfamily",
			"nomeSubFa": "Subfamily",
			"nomeSubFaPlaceholder": "Subfamily name",
			"nomeUnidadeMov": "Unit",
			"nomeUnidadeMovPlaceholder": "Motion unit",
			"qtdPorUnid1": "Qty.",
			"qtdPorUnid1Placeholder": "Quantity per unit",
			"movimStock": "Move stock",
			"movimStockPlaceholder": "Move stock",
			"codvaloriz": "Valuation",
			"codvalorizPlaceholder": "Valuation",
			"nomeIvaVenda": "VAT sale",
			"nomeIvaVendaPlaceholder": "VAT name",
			"taxaIvaVenda": "VAT rate",
			"taxaIvaVendaPlaceholder": "VAT rate",
			"nomeIvaCompra": "VAT purchase",
			"nomeIvaCompraPlaceholder": "Name VAT purchase",
			"taxaIvaCompra": "Purchase VAT rate",
			"taxaIvaCompraPlaceholder": "Purchase VAT rate",
			"precoSemIva": "Price without VAT",
			"precoSemIvaPlaceholder": "Price without VAT",
			"precoComIva": "Price with VAT",
			"precoComIvaPlaceholder": "Price with VAT",
			"qtdTotal": "Stock Qty",
			"qtdTotalPlaceholder": "Total quantity of stock",
			"temRetencaoNaFonte": "Has withholding tax",
			"ncontapoc": "Sales account number",
			"nContaPocCompra": "Purchase account number",
			"grupoartigo": "Property",
			"artDesactivado": "Article deactivated",
			"artBloqueado": "Article blocked",
			"categoria": "Category",
			"precoBaseCusto": "Cost base price",
			"precoBaseCustoPlaceholder": "Cost base price",
			"nArmazem": "Warehouse code",
			"nomeArmazem": "Warehouse name",
			"qtd": "Current stock",
			"qtdStockPotencial": "Potential stock",
			"contaExt": "External account",
			"artigoExt": "External article code",
			"descContaExt": "External account description",
			"precoCompUlt": "Last purchase value",
			"precoCompUltPlaceholder": "Last purchase value",
			"prVendaUlti": "Last sale value",
			"prVendaUltiPlaceholder": "Last sale value",
			"dataPrCompra": "Last purchase date",
			"dataPrVendUl": "Last sale date",
			"naoPermiteExistNeg": "Does not allow negative stock",
			"temLote": "There is a lot",
			"temDataValidade": "Expiration date",
			"diasValidade": "Number of days validity",
			"diasValidadePlaceholder": "Number of days validity",
			"segNArtigo": "Bar codes",
			"segNome": "Alternative description",
			"qtdStockEncCliente": "Order",
			"qtdStockEncForn": "Order",
			"qtdTotalStockPotencial": "Potential stock",
			"qtdTotalStockEncCliente": "Order",
			"qtdTotalStockEncForn": "Order",
			"taxaIvaTip": "{{fee}}% fee",
			"qtdStockMinimo": "Minimum stock",
			"qtdStockMaximo": "Maximum stock",
			"qtdAdd": "Qty."
		},
		"btn": {
			"buttonOptions": "options",
			"verContas": "Shows Sales and Purchase accounts",
			"verPrecoBaseCusto": "Shows cost base price",
			"verCategoria": "Show category",
			"esconderContas": "Hide accounts",
			"mostraTodas": "All",
			"extratoArtigo": "Article extract",
			"verPropriedade": "Show ownership",
			"verApenasDesativado": "See disabled only",
			"verApenasBloqueado": "See blocked only"
		},
		"message": {
			"diferefamilia": "The Account Number in this article differs from the Account Number in the family.",
			"mostraTodas": "View all possible integration accounts",
			"contaPocFormadaVenda": "The integration account to be formed will possibly be {{nAccount}}.",
			"contaPocFormadaCompra": "The integration account to be formed will possibly be {{nAccount}}.",
			"contaPocFormadaMovimento": "The integration account to be formed will be {{nAccount}}.",
			"infofield": "This field is for information purposes only.",
			"extratoArtigoTooltip": "View the article extract"
		},
		"actions": {
			"familias": "Family Maintenance"
		},
		"datasource": {
			"grupoartigo": {
				"Geral": "General",
				"Mercadoria": "Merchandise",
				"Produto": "Product",
				"MateriaPrima": "Feedstock",
				"Servico": "Service",
				"ServicoExterno": "External Service",
				"Componente": "Component",
				"Equipamento": "Equipment",
				"Ferramenta": "Tool",
				"MaoDeObra": "Labor",
				"ArtigoReferencia": "Article Reference",
				"MaterialSubsidiario": "Subsidiary Material",
				"Embalagem": "Packaging",
				"Veiculo": "Vehicle",
				"Motorista": "Driver",
				"TipoTarefa": "Task Type",
				"TarefaEspecifica": "Specific Task",
				"TarefaGeral": "General Task",
				"Transportadora": "Shipping company",
				"MaquinaMotriz": "Driving Machine",
				"Configuracao": "Settings",
				"ArtigoTara": "Tara Article",
				"Infraestrutura": "Infrastructure",
				"Utensilio": "Utensil",
				"ParteInfraestrutura": "Infrastructure Part",
				"Adiantamentos": "Advances",
				"AlienacaoDeImobilizado": "Disposal of Fixed Assets"
			}
		},
		"navigator": {
			"tabs": {
				"ficha": "Token",
				"extrato": "Extract",
				"anexos": "Attachments",
				"lotes": "Lots"
			},
			"groups": {
				"indentificacao": "Identification",
				"classificadores": "Classifiers",
				"precos": "Pricing",
				"stocks": "stocks",
				"classificacao": "Taxes and others",
				"clifo": "Customer supplier",
				"contab": "Accounting"
			}
		},
		"text": {
			"detalheArmazem": "Detail by Warehouse",
			"tipoprod": "Product type (Inventory): {{cod}} - {{desc}}",
			"codBarras": "EAN13 - Unique identifier read using a scanner suitable for reading.",
			"artigoBloqueado": "Normally used to block temporarily.",
			"artigoDesativado": "Normally used to block permanently.",
			"familia": "Classifier that allows you to initialize various fields on the article form.",
			"stockPotencial": "Obtained through the following way: Stock - Enc.",
			"lotesBloqueadosMovStock": "Lots of item blocked due to not moving stock",
			"lotesBloqueadosLicencaPremium": "Lot blocked due to item not moving stock"
		}
	},
	"assistenteconfigportais": {
		"pages": {
			"empresa": "Company configuration",
			"utilizador": "User configuration",
			"empregado": "Employee setup",
			"email": "Email settings per company"
		},
		"fields": {
			"portal": "Select Portal",
			"operacao": "Select Operation",
			"novoutilizador": "New user",
			"nomeutilizador": "Username",
			"nome": "Name",
			"apelido": "Surname",
			"email": "Email",
			"activo": "Active",
			"enviaEmail": "Send email with password",
			"codempregado": "Employee code",
			"nomeempregado": "Employee name",
			"addempregado": "Associate employee",
			"contaemail": "Account",
			"password": "Password",
			"smtp": "SMTP",
			"portasmtp": "SMTP port",
			"ssl": "SSL",
			"empresa": "Company",
			"addempresa": "Add company",
			"removeempresa": "Remove company",
			"utilizador": "User"
		},
		"opcoes": {
			"selecionarportal": "Select a portal",
			"operacoesportal": "Select an operation"
		},
		"outros": {
			"empresa": "Company",
			"utilizador": "User",
			"gabinete": "Cabinet"
		},
		"info": {
			"naocriautilizador": "Will not create new user",
			"savesucess": "User created successfully",
			"saveerror": "Error creating user",
			"empresagabinetenaoselec": "There is no cabinet company selected!",
			"empresaclientenaoselec": "There is no selected client company!",
			"empresanaoselec": "There is no company to select or none selected!",
			"associateempsucess": "Successfully associated employee",
			"associateemperror": "Error associating employee with user",
			"utilizadornaosel": "There is no user selected",
			"empresanaosel": "There is no company selected",
			"empregadonaosel": "There are no employees selected",
			"naotemempresasparaconfigurar": "The user already has the employee(s) associated with the company(ies)",
			"empresaClienteConfigurada": "The client company has been successfully configured",
			"empresaClienteConfigError": "Error configuring client company",
			"contaemailnula": "The email account must be filled in",
			"passwordnula": "The email account password must be filled in",
			"smtpnulo": "SMTP must be completed",
			"portasmtpnula": "The SMTP port must be populated",
			"emailconfigsavesucess": "Email configured successfully",
			"emailconfigsaveerror": "Error configuring email",
			"utilizadorexistentesuccess": "User updated successfully"
		},
		"operations": {
			"myAccounting": {
				"addUser": "Add user to the portal",
				"addEmpresa": "Make your company available on the portal",
				"associarEmpregado": "Associate employee with user on the portal"
			},
			"portalclientconnect": {
				"createUser": "Create new user on the portal",
				"addUser": "Add existing user to portal"
			}
		},
		"steps": {
			"stepGabinete": "Cabinet",
			"stepEmpresa": "Company",
			"stepUtilizador": "User",
			"stepEmpregado": "Employee",
			"stepEmail": "Email"
		},
		"tiposEmail": {
			"gmail": "Gmail",
			"hotmail": "Hotmail",
			"outro": "Other"
		}
	},
	"atFRV": {
		"tabDocsEFaturaCaption": "Green Receipts Issued",
		"tabJaLancadosCaption": "Receipts already entered in accounting or ignored",
		"viewDocModalTitle": "Document view {{ndoc}}",
		"viewDocModalTitleSimple": "Document preview",
		"extratosModalTitle": "Account statements",
		"expandAllBtn": "Expand all",
		"cantSelectRowDueInvalidPredefinido": "You cannot select the record because you do not have the Pre-Defined!",
		"selectAll": "Select all",
		"lancdocserie": "Launch Documents in series",
		"messages": {
			"docIgnoradoSuccessMsg": "Receipt {{ndoc}} has been added to the ignore list.",
			"nifIgnoradoSuccessMsg": "{{nif}} - {{name}} has been added to the ignore list.",
			"docRemIgnoringSuccessMsg": "Receipt {{ndoc}} has been removed from the ignore list.",
			"docRemIgnoringFornecedorSuccessMsg": "Contributor {{nif}} has been removed from the ignore list."
		},
		"fields": {
			"documentoLancado": "Document No.",
			"nifAdquirente": "NIF",
			"nomeAdquirente": "Name",
			"numDocumento": "Receipt No.",
			"dataEmissao": "Issuance date",
			"tipoDocumento": "Type",
			"situacao": "Situation",
			"atividadePrestador": "Provider Activity",
			"observacoes": "Comments",
			"importanciaRecebida": "Importance Received",
			"valorBase": "Base value",
			"valorIVA": "VAT value",
			"valorIRS": "IRS value",
			"valorImpostoSelo": "Stamp Tax Value",
			"taxaIVA": "VAT rate",
			"preDefinido": "Pre-Defined"
		},
		"btn": {
			"accao": "Actions",
			"dropdown": {
				"extratosConta": "Account statements",
				"ignorarSempreTodosDocumentos": "Always ignore all receipts from this taxpayer",
				"ignorarDocumento": "Ignore this receipt",
				"nifConfig": "Configure NIF",
				"gerarNovaCC": "Generate new current account"
			}
		},
		"tabJaLancadosCaptionContainer": {
			"table": {
				"badges": {
					"naoIgnorar": "Don't Ignore",
					"remover": "To remove"
				}
			},
			"legends": {
				"documentoIgnorado": "Ignored receipt",
				"documentoFornecedorIgnorado": "Ignored taxpayer document"
			}
		},
		"formFields": {
			"dataDe": "Date of:",
			"dataAte": "Date Until:",
			"tipo": "Type",
			"situacao": "Situation",
			"placeholder": {
				"contribuinte": "VAT Number"
			}
		},
		"configModalTitle": "Preset configuration",
		"reciboVerdeLabel": "",
		"novacontaccmodal": {
			"title": "Create new checking account",
			"fields": {
				"radical": "Radical",
				"nConta": "Account number to create",
				"nome": "Name"
			},
			"successModal": {
				"title": "View the supplier's profile",
				"message": "Supplier current account created successfully.<br/>Do you want to edit the account?"
			}
		}
	},
	"ativos": {
		"title_detail": "Active {{id}}",
		"title_new": "New Asset",
		"title_plural": "Active",
		"pesquisa": "To look for",
		"saved": "Active {{id}}, saved successfully",
		"error": "Active {{id}}, cannot be saved.",
		"deleted": "Active {{id}}, deleted successfully",
		"errotitle": "Error",
		"avisotitle": "Notice",
		"fields": {
			"codAtivo": "Code",
			"refExterna": "External Ref.",
			"designacao": "Designation",
			"estado": "state",
			"dataEstado": "Status date",
			"viatLigArtigo11DR": "Light vehicle - Article 11 DR",
			"codAtivoPai": "Parent Active Code",
			"documentoDeAquisicao": "Acquisition Document",
			"origem": "Origin",
			"ignoraImpostosDiferidos": "Ignores accounting for deferred taxes.",
			"header": {
				"estado": "State:",
				"valorAquisicao": "Acquisition Value",
				"depAcumulada": "Accumulated Dept.",
				"valorLiquido": "Net value",
				"diario": "Daily",
				"periodo": "Period",
				"ndocinterno": "Internal Doc. No.",
				"contabTaxaAmortiza": "Accounting amortization rate",
				"fiscalTaxaAmortiza": "Tax amortization rate",
				"contabBaseCalculo": "Basis for accounting calculation",
				"fiscalBaseCalculo": "Basis for tax calculation"
			},
			"totals": {
				"estado": "state",
				"qtdEstado": "",
				"totalValAquisicao": "",
				"totalDepAcumulada": "",
				"totalValLiquido": ""
			}
		},
		"modal": {
			"btn": {
				"marcarGR": "To mark",
				"desmarcarGR": "Mark off"
			},
			"abatedoativo": "Asset write-off",
			"registodevendaativo": "Asset sale registration",
			"radicalAbate": "Slaughter Account No.",
			"dataAbate": "Slaughter Date",
			"ativo": "Active:",
			"grMarcar": "Mark asset as a major repair",
			"grDesmarcar": "Deselecting asset from being a major repair"
		},
		"header": {
			"estado": "State:",
			"valorAquisicao": "Acquisition Value:",
			"depAcumulada": "Accumulated Dept.:",
			"valorLiquido": "Net value:"
		},
		"estado": {
			"0": "Normal",
			"1": "Shot down ({{date}})",
			"2": "Sold ({{date}})",
			"3": "Split ({{date}})"
		},
		"titles": {
			"dadosDoAtivo": "Asset Data",
			"dadosDoFornecedor": "Supplier Data",
			"dadosContabilisticos": "Accounting Data",
			"dadosFiscais": "Tax Data",
			"contas": "Accounts",
			"outrosDados": "Other data",
			"impostosDiferidos": "Deferred taxes",
			"classificadores": "Classifiers",
			"grandeReparacao": "Parent asset"
		},
		"total": "Total",
		"toolbar": {
			"acontecimentos": "Operations",
			"dropdownDeprPlan": "Depreciation and Planning",
			"dropdownRegContab": "Accounting",
			"tooltipAtivoAbateVendido": "Asset already written off/sold",
			"dropdownConsulta": "Query",
			"tooltipAtivoEstadoNormal": "The Asset must be in the normal state.",
			"tooltipOpJustoValorABP": "The Asset must be biologically produced and in a normal state."
		},
		"actions": {
			"abate": "Register Slaughter",
			"venda": "Register Sale",
			"planning": "Planning",
			"depreciacoes": "Depreciations",
			"depreciacoesDtl": "Detailed depreciations",
			"regcontab": "Accounting records",
			"mudarcontaativo": "Change Asset Account",
			"mudarcontadepexerc": "Change account dept.",
			"mudarcontadepacumu": "Change account dept.",
			"mudastaxasdep": "Change depreciation rates",
			"notacredito": "Discount (Credit note)",
			"divisao": "Asset Division",
			"ajustedepantigas": "Adjust accumulated depreciation in previous years",
			"imprimeativo": "Print asset sheet",
			"reavaliacoesNaoLegais": "Non-legal revaluations",
			"aumentoifrs16": "Value increase in IFRS16",
			"opjustovalorabp": "Fair Value Op. in ABP",
			"grandereparacao": "Major repair (Mark/Uncheck)"
		},
		"steps": {
			"aquisicaoAtivo": "Asset Acquisition",
			"dadosContabilisticosDadosFiscais": "Accounting Data and Tax Data",
			"contasPlanoOutrosDados": "Plan Accounts and Other Data",
			"classificadoresDoAtivo": "Asset Classifiers",
			"planningAmortizacoes": "Amortization Planning",
			"ativoDepreciacoesDetalhadas": "Detailed Depreciations",
			"ativoDepreciacoes": "Depreciations",
			"pesquisaContabilidade": "Accounting Records"
		},
		"enum": {
			"origem": {
				"0": "Acquisition",
				"1": "Conversion",
				"2": "Accounting",
				"3": "Excel import",
				"4": "Manual Import",
				"5": "Asset Division",
				"6": "Non-Legal Revaluations",
				"7": "IFRS 16 Value Increase",
				"8": "Fair Value Operation",
				"9": "Ongoing investments"
			}
		},
		"table": {
			"dadosContabilisticos": "Accounting Data",
			"dadosFiscais": "Tax Data",
			"depreciacaocontabilistica": "Accounting depreciation",
			"depreciacaofiscalaceite": "Tax depreciation accepted",
			"taxascontab": "Accounting Fees",
			"taxasfiscal": "Tax Fees",
			"limiteminimofiscal": "Minimum tax limit",
			"limitemaximofiscal": "Maximum tax limit",
			"perdasimparidadecontabilisticas": "Accounting impairment losses",
			"perdasimparidadefiscais": "Tax impairment losses",
			"valoresperdidosfiscais": "Tax Lost Values",
			"taxasperdidasfiscais": "Tax Lost Fees",
			"depreciacoesnaoaceites": "Unaccepted depreciation",
			"imparidadesnaoaceites": "Unaccepted impairments",
			"depreciacoesarecuperar": "Depreciation to be recovered",
			"depreciacoesrecuperadas": "Recovered depreciations",
			"imparidadesrecuperadasnoperiodo": "Impairments recovered in the period",
			"acrescemodelo22": "Adds to Model 22",
			"deduzmodelo22": "Deducts to Model 22",
			"valortributavelativosimpostosdiferidos": "Taxable value of deferred tax assets",
			"valortributaveldopassivoimpostosdiferidos": "Taxable value of deferred tax liabilities",
			"valornaoaceitefiscalmenteporreavaliacoes": "Value not accepted for tax purposes due to revaluations",
			"impostosdiferidos": "Deferred taxes",
			"rendimentos": "Subsidy Income",
			"impostosdifSubsidios": "Deferred Subsidy Taxes",
			"tipoOperacao": "Operation",
			"ndiario": "Daily",
			"ndocInterno": "Internal Doc. No.",
			"dataDoc": "Release date",
			"dataDocExterno": "Doc date.",
			"periodo": "Period",
			"ano": "Year",
			"depreciacoes": "Depreciations",
			"body": {
				"mesde": "Month of",
				"mesate": "Month until",
				"anoEconomico": "Economic year",
				"dataDe": "Date of",
				"dataAte": "Date until",
				"valorAquisicao": "Acquisition Value",
				"valorResidual": "Residual value",
				"taxa": "Rate",
				"redTaxa": "%red.",
				"depreciacao": "Depreciation",
				"depreciacoes": {
					"anodoubledot": "Year:",
					"ano": "Year",
					"mes": "Month",
					"contabtaxamedia": "Average accounting rate",
					"fiscaltaxamedia": "Average tax rate",
					"periodo": "Period",
					"periodoano": "Year Period",
					"periodomes": "Month Period",
					"depmes": "In the month",
					"depmesaquisicao": "In the month about acquisition",
					"depmesreavalicao": "In the month about revaluation",
					"depacumano": "In the year",
					"depacumant": "In the previous year",
					"depacumtot": "Total",
					"taxa": "Rate",
					"percreducao": "% Reduction",
					"reforco": "Reinforcement",
					"taxairc": "IRC rate"
				}
			}
		},
		"messages": {
			"existemmaisativoscontab": "The Asset originated in Accounting.<br/>By deleting it, you will eliminate all others that resulted from the same document.<br/>Are you sure you want to continue?",
			"existemmaisativosdivididos": "Are you sure you want to delete the Asset?<br/>This is an Asset that resulted from a division.<br/>Deleting this Asset will eliminate all Assets that resulted from the division.<br/>The Asset that was divided will return to normal state."
		},
		"configuracoes": {
			"tab": {
				"title": {
					"aquisicao": "Acquisition",
					"impostosDiferidos": "Deferred Taxes",
					"depreciacoes": "Depreciations",
					"ajustes": "Settings",
					"errosTitle": "Depreciation errors from previous years",
					"vendaTitle1": "Sales Invoice",
					"vendaTitle2": "Gross Value and Accumulated Depreciation",
					"abate": "Slaughter",
					"contabilidadeanaliticatitle": "Radicals subject to Analytical Accounting",
					"reavaliacoesnaolegais": "Non-legal revaluations",
					"impdifreavaliacoesnaolegais": "Deferred Taxes from Non-legal Revaluations",
					"operacoesjustovalor": "Fair value transactions",
					"impdifoperacaojustovalor": "Deferred Taxes from Fair Value Transaction"
				},
				"diversos": "Several",
				"contasDepreciacao": "Depreciation Accounts",
				"aquisicao": "Acquisition",
				"depreciacoes": "Depreciations",
				"ajustes": "Settings",
				"erros": "Errors",
				"venda": "Sale",
				"abate": "Slaughter",
				"regraAbateVenda": "Slaughter/Sale Rules",
				"contabilidadeAnalitica": "Analytical accounting",
				"classificadores": "Classifiers",
				"reavaliacoesnaolegais": "Non-legal revaluations",
				"ativosbioprod": "Biological production assets (ABP)",
				"contasInvestCurso": "Ongoing investment accounts"
			},
			"btn": {
				"adicionar": "To add",
				"guardar": "Save",
				"cancelar": "Cancel"
			},
			"messages": {
				"radicalRequired": "The radical cannot be empty.",
				"radicalInvalid": "Radical only allows numeric characters.",
				"radicalJaExistente": "The inserted radical is already configured.",
				"contaImobilizadoRequired": "The radical of the asset account cannot be empty.",
				"contaImobilizadoInvalid": "The asset account stem only allows numeric characters.",
				"contaImobilizadoJaExiste": "The entered asset account already exists.",
				"pocInvalid": "The depreciation or amortization account does not exist.",
				"successfullySaved": "Changes saved successfully.",
				"ativoguardadosuccess": "Asset saved successfully.",
				"contaTransicaoInvalid": "The transition account is not valid."
			},
			"radiooptions": {
				"ativoperiodo": "Launches a document for each Asset, in each period",
				"ativoperiodoult": "Launches a document for each Asset, in a single period (last)",
				"ativosperiodo": "Launch a document with all Assets, in each period",
				"ativosperiodoult": "Launch a document with all Assets, in a single period (last)"
			},
			"trataAtivosNoCentralGest": "Treats Assets in CentralGest",
			"radicaisContasImobilizado": "Asset Radicals",
			"radicalIVA": "Radical VAT",
			"taxaIRCEstimada": "Estimated IRC rate",
			"codDiario": "Journal Code",
			"codDescritivo": "Descriptive Code",
			"radicaisFornecedorAquisicao": "Supplier Radicals",
			"impostosDiferidosContaPassivo": "Liability Account Code",
			"impostosDiferidosContaAjustamentos": "Adjustments Account Code",
			"impostosDiferidosContaImpostoDiferido": "Imp Account Code.",
			"mostraContabilidadeConversao": "In Asset maintenance, shows the Conversion relationships of the old structure",
			"depreciacaoDataEncerramento": "Closing date",
			"contaContrapartida": "Counterpart Account Code",
			"contaMaisValia": "Surplus Value Account",
			"contaMenosValia": "Loss-Value Account",
			"vendaRadicalMaisValiaFatura": "Radical Added Value",
			"vendaRadicalMenosValiaFatura": "Radical Less-Value",
			"abateRadical": "Radical Slaughter",
			"ccusto": "Code C. Cost",
			"zona": "Zone",
			"departamento": "Department",
			"subdepartamento": "Sub-Department",
			"tipoartigo": "Type Article",
			"grandefamilia": "Big family",
			"familia": "Family",
			"subfamilia": "Subfamily",
			"contabilidade": "Accounting",
			"classe": "Class",
			"categoria": "Category",
			"vendedor": "Seller",
			"processo": "Process",
			"dataLimiteParaCalculoContabilisticoIgualFiscalTrue": "Deadline date for accounting calculation, same as tax date (last day of the previous month)",
			"dataLimiteParaCalculoContabilisticoIgualFiscalFalse": "Deadline for accounting calculation, is the day before Slaughter/Sale",
			"contabilidadeAnaliticaUsa": "Use Analytical Accounting to integrate Assets",
			"contabilidadeAnaliticaMascara": "Analytics Mask",
			"analiticaContaContraPartida": "Counterpart Account",
			"radicaisSujeitosList": "List of radicals",
			"novoRadicalSujeito": "New radical",
			"contaImobilizado": "Fixed Asset Account",
			"contaDepreciacao": "Depreciation Account",
			"contaDepreciacaoAcumulada": "Accumulated Depreciation Account",
			"infodep": "There are already depreciations made for the current year.",
			"depreciacaoSoRegistaNaContabilidadeEmPeriodoAposAquisicao": "Only recorded in Accounting in a period equal to or greater than the acquisition",
			"depreciacoesDoAnoPesquisaPeloEstadoDepreciacoes": "Search annual depreciation by Depreciation status",
			"nContaContrapartida": "Counterpart account number",
			"nContaPassivo": "Passive Account No.",
			"nContaAjustamentos": "Adjustments Account No.",
			"radicalabp": "Radical ABP",
			"nContaParaReducao": "Account number for reduction",
			"nContaParaAumento": "Account number for increase",
			"nContaAtivo": "Active Account No.",
			"cursoRadicalInvestimentos": "Radical investment underway",
			"cursoRadicalAtivo": "Asset account radical",
			"cursoContaTransicao": "Transition account"
		},
		"verificacaoDeConsistenciaVenda": {
			"error": "There are Asset sale documents in Accounting, unrelated to the Assets.<br>Record the respective sales.",
			"btn": {
				"redirectToVendaSerie": "Click here to be redirected to Asset Sales."
			}
		},
		"listTotals": {
			"totalativos": "Total number of assets",
			"totalvalaquisicao": "Total acquisition value",
			"totaldepacumulada": "Total Accumulated Dep.",
			"totalvalliquido": "Total net value",
			"resumo": "Summary",
			"estado": "state"
		},
		"aumentoifrs16": {
			"title": "Value increase in IFRS16",
			"initdescription": "[Value increase] -",
			"info": "This action makes it possible to carry out the increase in IFRS 16.",
			"data": "Increase date",
			"valor": "Increase value",
			"designacao": "Designation of the new Asset",
			"messages": {
				"descempty": "The designation cannot be null!",
				"invalidvalue": "The increase amount is not valid!",
				"invaliddate": "The date of the value increase must be greater than the beginning of the current year!",
				"registedsuccess": "Increase in registered value."
			}
		},
		"ativosopjustovalorabp": {
			"title": "Fair value operation in Biological Production Assets (ABP)",
			"dataOperacao": "Operation Date",
			"valorOperacao": "Operation Value",
			"temcertezaOPJustoValor": "Do you intend to carry out the Fair Value operation?",
			"successOPJustoValor": "Fair Value Operation carried out successfully"
		},
		"confirmacao": "Confirmation"
	},
	"ativosreavaliacoesnaolegais": {
		"titleList": "Non-legal revaluations",
		"title": "Non-legal reassessment",
		"ativoparcial": "Asset {{codAtivo}} partially depreciated.",
		"ativototaldep": "Asset {{codAtivo}} fully depreciated.",
		"confirmacao": "Confirmation",
		"vidaUtilDefinidaEDiferente": "The defined useful life is different from that of the current Asset.",
		"temACertezaQuePretendeRealizarAR": "Are you sure you want to carry out the non-legal Revaluation of the Asset?",
		"success": "Non-legal reassessment carried out successfully.",
		"jahadepreciacoes": "There are already depreciations made in the year for the Asset!",
		"fields": {
			"dataReavNaoLegal": "Revaluation Date",
			"vidaUtilMeses": "Useful life (months)",
			"vidaUtilAnos": "Useful life (years)",
			"aumentoValor": "Value increase"
		}
	},
	"ativosAquisicao": {
		"title_detail": "Acquisition {{id}}",
		"title_new": "New Acquisition",
		"title_plural": "Acquisitions",
		"pesquisa": "To look for",
		"saved": "Acquisition {{id}}, saved successfully",
		"error": "Acquisition {{id}}, cannot be saved.",
		"deleted": "Acquisition {{id}}, deleted successfully",
		"warn": {
			"tooltipTaxa": "Rate different from that of the Regulatory Decree!",
			"baseFiscDifContab": "Basis for tax calculation other than accounting",
			"confirmBaseFiscDifContab": "Base for tax calculation other than accounting!<br>Do you want to continue?"
		},
		"fields": {
			"nMapaAmortizacao": "Amortiz Map No.",
			"codAmortizacao": "Amortization Code",
			"dataAquisicao": "Acquisition Date",
			"dataUtilizacao": "Use Date",
			"quantidade": "Number of units (Qty)",
			"grandeReparacao": "It's a big repair (of another Asset)",
			"usado": "Used",
			"nDocExterno": "External Doc No.",
			"dataDocExterno": "External Doc Date",
			"nContaFornecedor": "Supplier Account No.",
			"nomeFornecedor": "Supplier Name",
			"contabValorAquisicao": "Acquisition Value",
			"contabTaxaAmortiza": "Amortization Rate",
			"contabNumAnosUteis": "Number of working years",
			"contabPercReducao": "Perc.",
			"contabValorResidual": "Residual value",
			"contabDataDispUso": "Date of Availability for use",
			"contabBaseCalculo": "Basis for Calculation",
			"contabMetodoCalculo": "Method for Calculation",
			"fiscalValorAquisicao": "Acquisition Value",
			"fiscalTaxaAmortiza": "Amortization Rate",
			"fiscalNumAnosUteis": "Number of working years",
			"fiscalPercReducao": "Perc.",
			"fiscalValorResidual": "Residual value",
			"fiscalDataInicioUtil": "Start date of use",
			"fiscalBaseCalculo": "Basis for Calculation",
			"fiscalMetodoCalculo": "Method for Calculation",
			"nContaDoAtivo": "Asset Account No.",
			"nContaAmortExerc": "Amortization Account No.",
			"nContaAmortAcumu": "Amortization Account No.",
			"nContaIVADedutivel": "Deductible VAT Account Number:",
			"codIVAAquisicao": "Acquisition VAT Code:",
			"iVADeduzido": "VAT Deducted:",
			"codRubOrcam": "Budget Line Code",
			"nCodRepCC": "Distribution Code",
			"nCCusto": "Number of Cost Cent",
			"nZona": "Zone",
			"nDepto": "Department",
			"nSubde": "Sub-Department",
			"tipoArtigo": "Article Type",
			"nGrFamilia": "Big family",
			"nFamilia": "Family",
			"nSubfa": "Subfamily",
			"codContab": "Accounting Code",
			"classe": "Class",
			"categoria": "Category",
			"nVendedor": "Seller",
			"nRefProcesso": "Process",
			"nSeccao": "Section"
		},
		"radioOptions": {
			"baseAnual": "Annual Basis",
			"inicioUtilizacao": "From the start of use",
			"quotasConstantes": "Constant Quotas",
			"quotasDecrescentes": "Decreasing Shares"
		},
		"tooltip": {
			"contabDataDispUso": "Enter day 1 of the month of start of use"
		}
	},
	"ativosabate": {
		"ativosporabater": "Assets to be written off",
		"dataAbate": "Slaughter Date",
		"radicalAbate": "Slaughter Account No.",
		"selecionarativos": "Select the Assets to write off",
		"message": {
			"success": "Recorded slaughter!"
		},
		"table": {
			"codAtivo": "Active Code",
			"designacao": "Designation",
			"refExterna": "External Ref.",
			"dataAquisicao": "Dt.",
			"valorAtivoBrutoContab": "Gross assets",
			"valorDepreciacoesAcumuladasContab": "Accumulated Dept.",
			"ativosporabater": "Assets to be written off",
			"ativosaabater": "Assets to write off"
		},
		"btn": {
			"realizarabates": "Perform Kills"
		}
	},
	"ativosajustedepantigas": {
		"title": "Adjustment of Depreciation accumulated in previous years",
		"confirmacao": "Confirmation",
		"temcerteza": "Are you sure you need to record the missing depreciation from previous years and record the value as adjustments in Accounting?",
		"infoajustes": "This functionality will record missing depreciation from previous years and post the value as adjustments in Accounting",
		"message": {
			"success": "Depreciation adjustment registered successfully!",
			"haveDepEmFalta": "There are no missing depreciations from previous years."
		},
		"fields": {
			"periodo": "Release period for adjustments",
			"valoremfalta": "Missing value"
		}
	},
	"ativosaquisicaocontab": {
		"prompt": {
			"exitTitle": "<i class='fa fa-exclamation-triangle text-warning' style='color: orange;'",
			"exitMessage": "Clicking OK will exit without saving the document and all changes you made to this document will be deleted."
		},
		"header": {
			"fornecedor": "Supplier:",
			"totalIva": "Total document VAT:",
			"valorAssociado": "Acquisition Value already associated with existing Assets:",
			"total": "Total:"
		},
		"btn": {
			"pesquisa": "Search accounting document",
			"adiciona": "Add another accounting document",
			"guardarAtivos": "Save"
		},
		"message": {
			"ativoguardado": "Asset saved successfully!",
			"confirmacao": "Asset acquisition registration",
			"desejaregistaraquisicao": "Do you want to register the acquisition of the Asset?"
		},
		"table": {
			"nLanc": "nLanc",
			"nConta": "Account No.",
			"nome": "Name",
			"valor": "Acquisition value",
			"valorCalculado": "Already associated",
			"valorCalculado2": "In new Assets",
			"periodo": "Period",
			"nDiario": "Daily No.",
			"nDocInterno": "Internal Doc. No.",
			"dCDescription": "Deb./Cré",
			"nContaDebito": "Debt",
			"nContaCredito": "Credit",
			"descricao": "Description",
			"designacao": "Designation",
			"codAtivo": "Code.",
			"nContaDoAtivo": "Asset Account No.",
			"contabValorAquisicao": "Value"
		},
		"tooltip": {
			"novoativo": "New Asset",
			"removedoc": "Remove Document",
			"valorEsgotado": "The Acquisition value for this account has already been exhausted!",
			"removeAtivo": "Remove Asset"
		},
		"error": {
			"contabValorAquisicaoSup": "The acquisition value cannot be higher than the remaining value!",
			"fiscalValorAquisicaoSup": "The fiscal acquisition value cannot be higher than the accounting value!",
			"invalidStep": "There are fields that are not filled in or contain an error!",
			"somainvalida": "The sum of the acquisition values ​​{{valorAssociado}} does not coincide with the total in the doc.",
			"temdeselecionarum": "You have to select an acquisition!",
			"naoMesmosRadicais": "The selected lines must have the same radical!"
		},
		"half": {
			"contasAquisicao": "Acquisition Accounts",
			"ativos": "Active"
		},
		"modal": {
			"ativoaquisicaocontabmodaltitle": "Acquisition research in Accounting"
		}
	},
	"ativosaquisicaoinvestemcurso": {
		"tabs": {
			"aquisicaoPorConta": "Acquisition on account",
			"aquisicaoPorDocumento": "Acquisition by document"
		},
		"infocontainvest": "Current investment account information",
		"contasAquisicaoCurso": "Ongoing Acquisition Accounts",
		"fields": {
			"nconta": "Account",
			"saldo": "Balance {{year}}",
			"saldo2": "Balance {{next year}}",
			"transferido": "Transferred",
			"codAtivo": "Code.",
			"designacao": "Description",
			"nContaDoAtivo": "Asset Account No.",
			"contabValorAquisicao": "Value"
		},
		"prompt": {
			"exitTitle": "<i class='fa fa-exclamation-triangle text-warning' style='color: orange;'",
			"exitMessage": "Clicking OK will exit without saving the document and all changes you made to this document will be deleted.",
			"desejaregistaraquisicao": "Do you want to register the acquisition of the Asset?",
			"ativoguardado": "Asset/s saved successfully!"
		},
		"messages": {
			"invalidDateNewAtivo": "The acquisition date must be in the year: {{year}} or {{next year}}",
			"invalidvalue": "The value cannot be greater than: {{value}}",
			"nContaEmpty": "You have to research and select an acquiring account!",
			"saldoTranferidoCompleto": "Account balance already associated with assets.",
			"desejaApagarDados": "Changing tabs will change the search method and clear the entered data"
		},
		"tooltip": {
			"novoativo": "New Asset",
			"removedoc": "Remove Document",
			"valorEsgotado": "The Acquisition value for this account has already been exhausted!",
			"removeAtivo": "Remove Asset"
		},
		"modal": {
			"title": "Ongoing investment account search",
			"fields": {
				"nConta": "Account No.",
				"nome": "Name",
				"virtualDouble01": "Balance {{year}}",
				"virtualDouble02": "Balance {{next year}}"
			}
		},
		"btns": {
			"search": "Search ongoing investment accounts"
		}
	},
	"ativosaquisicaoinvestemcursopordoc": {
		"infocontainvest": "Current investment account information per document",
		"listadocs": "List documents",
		"documentos": "Documents",
		"ativos": "Active",
		"ativosFieldset": "List of assets to create",
		"fields": {
			"radical": "Radical",
			"valor": "Value",
			"transferido": "Amount transferred",
			"codAtivo": "Code.",
			"designacao": "Description",
			"nContaDoAtivo": "Asset Account No.",
			"contabValorAquisicao": "Value"
		},
		"prompt": {
			"desejaregistaraquisicao": "Do you want to register the acquisition of the Asset?",
			"ativoguardado": "Asset's saved successfully!"
		},
		"messages": {
			"invalidvalue": "The value cannot be greater than: {{value}}",
			"docsEmpty": "You must add at least one document!",
			"nextStepAtivosList": "To add assets, click on the '+' button in the 'List of assets to create' grid"
		},
		"tooltip": {
			"novoativo": "New Asset",
			"removeAtivo": "Remove Asset"
		},
		"btn": {
			"search": "Search ongoing investment documents",
			"searchMobile": "Search documents"
		}
	},
	"ativoscoeficientecm": {
		"header": {
			"anoPesquisa": "Year to update"
		},
		"btn": {
			"pesquisa": "Search",
			"grava": "To record"
		},
		"table": {
			"ativo": "Asset Data",
			"coeficientes": "Coefficients",
			"maismenosvalias": "More or less value",
			"sel": "Sel.",
			"codativo": "Active Code",
			"designacao": "Designation",
			"dataaquisicao": "Acquisition Date",
			"dataavenda": "Sale date",
			"valor": "Value",
			"coefatual": "Coef.",
			"coefcorrigido": "Coef.",
			"contab": "Accounting",
			"fiscal": "Supervisor",
			"fiscalcorrig": "Corrected Tax",
			"emptyline": "No data"
		},
		"message": {
			"confirmacao": "Confirmation",
			"temcerteza": "Do you want to update the coefficient on the selected assets?",
			"success": "Assets have been updated successfully!"
		}
	},
	"ativosconsistencia": {
		"tab": {
			"ativosconsistenciaContab": "Accounting without connection to Assets",
			"ativosconsistenciaResumo": "Summary per account",
			"ativosconsistenciaAnaliseConta": "Analysis by account",
			"ativosconsistenciaPeriodoDiferente": "Assets with different period",
			"ativosconsistenciaAnosErrados": "Assets with the wrong year",
			"ativosconsistenciaVendaDiferente": "Sales in different years",
			"ativosconsistenciaDetalhe": "Detail by Asset",
			"ativosconsistenciaIgnorados": "Documents ignored from the relationship with Assets",
			"ativosajustesgerados": "Generated adjustments",
			"resumoerros": "Error summary"
		},
		"table": {
			"codErro": "Code.",
			"erroStr": "Error",
			"nConta": "Account No.",
			"nomeConta": "Account Name",
			"periodo": "Period",
			"nDiario": "Daily",
			"nDocInterno": "Internal Doc. No.",
			"valorContabilidade": "Value Accounting",
			"dCAsStr": "Debit credit",
			"erro": "Error",
			"valorAtivos": "Value in Assets",
			"diferenca": "Difference",
			"contabValorAquisicao": "Value Accounting",
			"debito": "Debt",
			"credito": "Credit",
			"nContaDebito": "Debt",
			"nContaCredito": "Credit",
			"descricao": "Description",
			"valor": "Value",
			"tipo0": "Type: Gross Assets",
			"tipo1": "Type: Accumulated Depreciation",
			"tipo2": "Type: Depreciation Exercise",
			"tipo3": "Type: Others"
		},
		"analiseconta": {
			"ano": "Economic year",
			"registoMes": "Until period"
		},
		"btn": {
			"lancadifajustes": "Launch differences as Adjustments",
			"pesquisa": "To look for",
			"ignorardocumentossel": "Ignore selected documents",
			"retiradocignoradossel": "Remove selected documents",
			"tipo": {
				"ativobruto": "Type: Gross Assets",
				"depreciacaoAcumulada": "Type: Accumulated Depreciation",
				"depreciacaoExercicio": "Type: Depreciation Exercise",
				"outros": "Type: Others"
			},
			"accao": "Actions",
			"dropdown": {
				"listaativos": "Account assets",
				"ignorardocumento": "Ignore Document",
				"apagarIgnorados": "Remove document from the ignored",
				"planocontas": "Chart of Accounts",
				"extratocontas": "Account Statement",
				"aquisicaobydocumento": "New Acquisition from document"
			}
		},
		"modal": {
			"listaativostitle": "Assets belonging to the Account",
			"periodotitle": "Period for launching Adjustments",
			"periodoinfo": "Indicate the Period in which you want the Adjustments entries to be made:",
			"periodo": "Period",
			"table": {
				"codAtivo": "Code",
				"designacao": "Designation",
				"dataAquisicao": "Acquisition Date",
				"virtualValor": "Value"
			}
		},
		"message": {
			"aviso": "Notice",
			"temcertezaignora": "Are you sure you want to ignore the documents selected from the Assets relationship?",
			"temcertezaretira": "Are you sure you want to remove the selected documents from those ignored in the relationship with Assets?",
			"temcertezaajustes": "Are you sure you want to post the differences as Adjustments?",
			"successLanca": "Differences launched successfully!",
			"semerros": "No mistakes",
			"comerros": "It has errors"
		}
	},
	"ativosdepreciacao": {
		"header": {
			"anoDeCalculo": "Economic year to which the calculation refers",
			"codAtivoDe": "Active Code",
			"codAtivoAte": "Active Code until",
			"dataAquisicaoDe": "Acquisition Date",
			"dataAquisicaoAte": "Acquisition date by",
			"pesquisaAnosAnteriores": "Search Depreciated assets in previous years",
			"alertativosdataposterior": "There are Assets with a depreciation start date later than the acquisition date."
		},
		"btn": {
			"pesquisar": "To look for",
			"calcular": "Record depreciation",
			"anulaCalculo": "Undo selected depreciations",
			"consultar": "Consult"
		},
		"tab": {
			"ativosDepreciadosEmAnoAnterior": "Assets depreciated in previous years",
			"ativosDepreciadosNoAno": "Assets depreciated in the year",
			"ativosPorDepreciarNoAno": "Assets to be depreciated in the year",
			"registoMes": "Register by period"
		},
		"table": {
			"title": {
				"ativos": "Active",
				"ultimaDepreciacao": "Last Depreciation",
				"depreciacaoContabilisticos": "Accounting Depreciation",
				"depreciacaoFiscal": "Tax Depreciation"
			},
			"subtitle": {
				"sel": "Sel.",
				"atCodAtivo": "Active Code",
				"atDesignacao": "Designation",
				"atDataAquisicao": "Acquisition Date",
				"atEstadoSTR": "state",
				"atDataAbate": "Slaughter Date",
				"atRefExterna": "External Ref.",
				"atErro": "Error",
				"ultAno": "Year",
				"ultMes": "Month",
				"ultPeriodo": "Period",
				"valorAquisicaoComResidual": "Acquisition Value",
				"taxaAmortiza": "Amortization Rate",
				"percReducao": "% Reduction",
				"valorAnualJaRegistado": "Already Registered",
				"valorAnual": "Value to Depreciate"
			},
			"total": "Total"
		},
		"message": {
			"calculado": "Undepreciated assets calculated successfully.",
			"calculoAnulado": "Assets to be depreciated successfully written off.",
			"percentAbove": "There are reduction percentages of 100%.",
			"jaforamdepreciadostodosano": "All Assets for the year have already been depreciated.",
			"hasujeitosadepreciacaoano": "There are Assets subject to depreciation for the year.",
			"naohanenhumsujeitodepreciacaoano": "There are no Assets subject to depreciation for the year."
		},
		"title": {
			"titlePercentAbove": "Calculation of assets to be depreciated"
		},
		"tooltip": {
			"selecionarumadep": "You must select at least one depreciation!"
		},
		"modal": {
			"dataspostetitle": "Assets with a depreciation start date much higher than the acquisition date",
			"table": {
				"codAtivo": "Code",
				"designacao": "Designation",
				"contabBaseCalculoStr": "Calculation method",
				"dataAquisicao": "Acquisition date",
				"contabDataDispUso": "Availability Date"
			}
		}
	},
	"ativosdivisao": {
		"message": {
			"confirmacao": "Confirmation",
			"temcerteza": "Are you sure you want to divide the Asset?",
			"success": "Asset divided successfully!",
			"info": "This action allows you to divide an Asset into 2. <br> The existing Asset will be decommissioned, and 2 new ones will be created, descendants of the current one, which inherit all the characteristics (dates, acquisition, depreciation)."
		},
		"title": {
			"divisao": "Asset Division"
		},
		"header": {
			"unidades": "Number of units/Qty:",
			"valoraquisicao": "Acquisition Value:",
			"depacumu": "Accumulated Dept.:",
			"valorliquido": "Net value"
		},
		"fields": {
			"unidades": "Number of units/Qty",
			"valoraquisicao": "Acquisition Value",
			"designacao": "Designation",
			"datadivisao": "Division date"
		}
	},
	"ativosestado": {
		"data": {
			"normal": "Normal",
			"abatido": "Shot down",
			"vendido": "Sold",
			"dividido": "Divided"
		}
	},
	"ativosgeramapasauto": {
		"tableDefinition": {
			"codAtivo": "Active Code",
			"designacao": "Designation",
			"estadoStr": "state",
			"dataAquisicao": "Acquisition Date",
			"dataAbateVenda": "Slaughter Date",
			"nMapaAmortizacao": "Map",
			"naturezaDosAtivosStr": "Nature"
		},
		"btn": {
			"pesquisar": "To look for",
			"geraativos": "Generate Maps"
		},
		"tab": {
			"bens": "Assets",
			"mapasarquivados": "Archived Maps"
		},
		"nav": {
			"mapa": "Map"
		},
		"modal": {
			"jaexistemmapas": "There are already maps generated for the year.",
			"jaexistemmapastext": "Do you want to continue and replace existing maps?"
		},
		"message": {
			"mapasgerados": "Generated Maps",
			"benspesquisados": "Goods searched successfully.",
			"errorFolderMessage": "There are no archived maps or they have not yet been generated."
		}
	},
	"ativoshistorico": {
		"table": {
			"tipoAcontecimentoSTR": "Type",
			"dataReferencia": "Reference Date",
			"nomeUtilizador": "User",
			"stampInsert": "Registration Date/Time",
			"observacoes": "Comments"
		},
		"title": {
			"historico": "Historic"
		}
	},
	"ativosimport": {
		"title_detail": "Imported Asset \"{{id}}\"",
		"title_new": "New Import",
		"title_plural": "Imports",
		"saved": "Import {{id}}, saved successfully.",
		"error": "Import {{id}}, cannot be saved.",
		"deleted": "Import {{id}}, deleted successfully",
		"deletedsuccess": "Asset deleted successfully",
		"calculoaceite": "Calculations accepted successfully",
		"calculobaseanual": "Annual base calculations carried out successfully",
		"calculoinicioutilizacao": "Start-of-use calculations carried out successfully",
		"valoreslimposcomsucesso": "Manually assigned values ​​cleared successfully",
		"atpercred": "Manually assigned values ​​cleared successfully",
		"valsemarredondanual": "Amounts without annual rounding successfully distributed",
		"valoranosanterior": "Value attributed by previous years successfully distributed",
		"pesquisa": "Search",
		"onlyByImportar": "Shows only Assets to be imported",
		"table": {
			"semdados": "No data",
			"header": {
				"ativo": "Active",
				"aquisicao": "Acquisition",
				"depacumucontab": "Accounting Accumulated Depreciation",
				"depacumufiscais": "Tax Accumulated Depreciation",
				"decregulamentar": "Regulatory Doc.",
				"contas": "Accounts",
				"associacaocontab": "Accounting Association",
				"sel": "Sel."
			},
			"estado0": "Normal",
			"estado1": "Shot down",
			"estado2": "Sold",
			"estado3": "Share",
			"baseCalculo0": "Annual Basis",
			"baseCalculo1": "From the start of use",
			"metodoCalculo0": "Constant Quotas",
			"metodoCalculo1": "Decreasing Shares"
		},
		"fields": {
			"importado": "Imported",
			"codAtivo": "Active Code",
			"designacao": "Designation",
			"refExterna": "External Ref.",
			"usado": "Used",
			"estado": "state",
			"dataAbate": "Slaughter Date",
			"valorVenda": "Sales Value",
			"dataAquisicao": "Acquisition date",
			"valorAquisicao": "Accounting acquisition value.",
			"valorAquisicaoFiscal": "Tax acquisition value",
			"mapa": "Map",
			"dtInicioUtilizacao": "Dt.",
			"baseParaCalculo": "Basis for calculation",
			"metodoParaCalculo": "Method for calculation",
			"dtUltProcessamento": "Dt.",
			"valoresManuais": "Manual values",
			"depAcumulada": "Accumulated dep.",
			"depExercicio": "Exercise Dept.",
			"percReducao": "% Reduction",
			"depCalculada": "Calculated dep.",
			"diferenca": "Difference",
			"codDR": "DR Code",
			"viatLigArtigo11DR": "Light vehicle - Art. 11.º DR",
			"taxaContab": "Accounting Fee",
			"taxaFiscal": "Tax Rate",
			"ncontaAtivo": "Active Account",
			"nomeDaContaDoAtivo": "Asset account name",
			"ncontaFornecedor": "Supplier Account",
			"nomeDaContaDoFornecedor": "Supplier account name",
			"periodo": "Period",
			"diario": "Daily",
			"nDocInterno": "Internal Doc. No.",
			"quantidade": "Amount"
		},
		"form": {
			"designacao": "Designation",
			"refExterna": "External Ref.",
			"dataAquisicao": "Acquisition date",
			"dataInicioUtiliza": "Start of use date",
			"valorAquisicao": "Acquisition Value",
			"valorAquisicaoFiscal": "Tax Acquisition Value",
			"codAmortizacao": "Amortization Code",
			"valorResidual": "Residual value",
			"nMapaAmortizacao": "Map",
			"viatLigArtigo11DR": "Light vehicle - Art. 11.º DR",
			"usado": "Used",
			"taxaAmortizacao": "Accounting Fee",
			"percReducao": "% Accounting Reduction.",
			"fiscalTaxaAmortiza": "Tax Rate",
			"fiscalPercReducao": "% Tax Reduction",
			"nContaDoAtivo": "Asset Account No.",
			"nContaFornecedor": "Supplier Account No.",
			"baseCalculo": "Basis for calculation",
			"metodoCalculo": "Method for calculation",
			"estado": "state",
			"dataUltProcessamento": "Last processing",
			"depreciacaoAcumulada": "Accounting Accumulated Depreciation",
			"depreciacaoExercicio": "Exercise Dept.",
			"fiscalDepAcumulada": "Tax Accumulated Depreciation",
			"fiscalDepExercicio": "Dept. Exercise.",
			"dataAbate": "Slaughter/Sale Date",
			"valorVenda": "Sales Value",
			"quantidade": "Amount"
		},
		"radioOptions": {
			"normal": "Normal",
			"abatido": "Shot down",
			"vendido": "Sold",
			"quotasConstantes": "Constant quotas",
			"quotasDecrescentes": "Decreasing shares",
			"inicioUtilizacao": "From the start of use",
			"baseAnual": "Annual basis"
		},
		"steps": {
			"aquisicaoAtivo": "Acquisition",
			"contas": "Accounts",
			"depreciacoes": "Accumulated Depreciation"
		},
		"tabs": {
			"porimportar": "Why Import",
			"importados": "Imported"
		},
		"btn": {
			"accao": "Actions",
			"excel": "Excel",
			"apagasel": "Delete selected",
			"delativossel": "Delete imp.",
			"delativosselimportados": "Delete Imported Assets sel.",
			"dropdown": {
				"editar": "To edit",
				"aceitarcalculos": "Accept calculations",
				"alterarmetodocalculo": "Change calculation method",
				"atribuivaloranualmanual": "Assign annual values ​​manually",
				"distribuivalorsemarredond": "Distribute values ​​without annual rounding",
				"distribuivaloranosanterior": "Distribute value assigned to previous years",
				"atribuipercredu": "Assign reduction percentage",
				"limpavaloratribuid": "Clears manually assigned values",
				"abrirdoccontab": "Open accounting document",
				"apagaativo": "Delete Asset import",
				"associarativos": "Associate Assets",
				"alterarmetodocalculoanual": "Change calculation method - Annual Base",
				"alterarmetodocalculoinicio": "Change calculation method - Start of use",
				"apagaativoimport": "Delete Imported Asset"
			},
			"actions": {
				"criafolhaimportar": "Create sheet for import",
				"importarficheiro": "Import file"
			}
		},
		"modal": {
			"promptCancelProcessTitle": "Confirmation",
			"contasinconsistencias": "Accounts with differences between Accounting and Assets",
			"tipo": "Type",
			"ajustescontastitle": "Generated adjustment documents",
			"criaficheiroexcel": "Excel file creation",
			"importaficheiro": "Excel file import",
			"ficheirocriadocomsucesso": "Excel file created successfully",
			"atpercreducao": "Assign reduction percentage",
			"dadoscontab": "Accounting data",
			"dadosfiscais": "Tax data",
			"atvaloranualman": "Assign values ​​manually",
			"valoresemcadano": "Values ​​in each year",
			"difcontab": "Accounting difference:",
			"diffiscal": "Tax difference:",
			"folhaimport": "Excel file creation",
			"colunasfich": "Columns for the file",
			"reavaliacoes": "Reassessments",
			"filesuccess": "File created successfully!",
			"fields": {
				"taxaamort": "Amortization Rate:",
				"amortacumu": "Accumulated Amortization:",
				"percred": "% Reduction:",
				"valoraquisicao": "Acquisition Value:",
				"inseridomanual": "(Manually entered)",
				"aplicarred": "Apply % reduction",
				"dadosfiscaisiguais": "Tax data equal to accounting data",
				"todosanos": "Every year",
				"ano": "Year",
				"perc": "Reduction percentage",
				"erro": "Error",
				"nConta": "Account No.",
				"nomeConta": "Account Name",
				"valorContabilidade": "Value Accounting",
				"valorAtivos": "Value in Assets",
				"diferenca": "Difference",
				"periodo": "Period",
				"ndiario": "Daily",
				"ndocInterno": "Internal Doc. No."
			},
			"btn": {
				"aplicarpercred": "Apply % reduction",
				"excel": "Excel",
				"importarativos": "Import Assets",
				"inconsistenciabtn": "Post Differences as Adjustments",
				"fechar": "To close",
				"actions": {
					"criafolhaimportar": "Create sheet for import",
					"importarficheiro": "Import file"
				}
			},
			"table": {
				"ano": "Year",
				"depcontab": "Accounting depreciation",
				"ajuste": "Adjustment",
				"total": "Total",
				"depfiscal": "Tax depreciation"
			},
			"msg": {
				"ativosimportados": "Imported assets!",
				"avisoinconsistenciasfirst": "There are accounts with differences between Accounting and Assets.",
				"avisoinconsistenciassecond": "If you wish, you can post the differences as adjustments.",
				"ajustesnascontas": "Imported assets and adjustments to registered accounts.",
				"desejaimportar": "Do you want to import the Assets?",
				"haativosdataaquisicao": "There are Assets with an Acquisition date in the current year.",
				"haativoserro": "There are Assets in error.",
				"aprepararimportacao": "Preparing the import process..."
			},
			"camposexport": {
				"basecalculo": "Calculation Base",
				"designacao": "Designation",
				"mapa": "Map",
				"CodAmortizacao": "Amortization Code",
				"ViatLigArt11DR": "Light vehicle - Art. 11.º DR",
				"TaxaAmortizacao": "Dep rate.",
				"FiscalTaxaAmortizacao": "Dep rate.",
				"DataAquisicao": "Acquisition date",
				"DataInicioUtiliza": "Start date of use",
				"NContaFornecedor": "Supplier Account No.",
				"ValorAquisicao": "Acquisition Value",
				"ValorAquisicaoFiscal": "Tax acquisition value",
				"NContaDoAtivo": "Active Account No.",
				"PercReducao": "Perc.",
				"FiscalPercReducao": "Perc.",
				"BaseCalculo": "Calculation basis",
				"MetodoCalculo": "Calculation method",
				"DepreciacaoAcumulada": "Accumulated depreciation",
				"DepreciacaoExercicio": "Depreciation for the year",
				"FiscalDepreciacaoAcumulada": "Accumulated tax department",
				"FiscalDepreciacaoExercicio": "Fiscal Year Dept.",
				"DataUltProcessamento": "Last date",
				"Usado": "Used",
				"Qtd": "Amount",
				"RefExterna": "External Ref.",
				"Estado": "state",
				"DataAbate": "Slaughter/sale date",
				"ValorVenda": "Sale value",
				"ValorResidual": "Residual value",
				"NCCusto": "C. Cost",
				"NZona": "Zone",
				"NDepto": "Department",
				"NSubde": "Sub-Department",
				"TipoArtigo": "Type Article",
				"NGrFamilia": "Big family",
				"NFamilia": "Family",
				"NSubfa": "Subfamily",
				"CodContab": "Accounting Code",
				"Classe": "Class",
				"Categoria": "Category",
				"NVendedor": "Seller",
				"NRefProcesso": "Process",
				"NSeccao": "Section",
				"DL126": "DL 126/77",
				"DL24": "DL 24/82",
				"DL219": "DL 219/82",
				"DL399G": "DL 399G/84",
				"DL118B": "DL 118B/86",
				"DL111": "DL 111/88",
				"DL49": "DL 49/91",
				"DL264": "DL 264/92",
				"DL31": "DL 31/98"
			},
			"importProgressTitle": "Import"
		},
		"messages": {
			"temQueTerValorAtribuido": "The year {{ultYear}} must have a value assigned.",
			"temqueselecionarativo": "You must select at least one asset."
		},
		"jobImportTimeoutModalTitle": "Import",
		"jobImportTimeoutModalMessage": "The task has timed out."
	},
	"ativosimpressao": {
		"header": {
			"codAtivoDe": "Active Code: From",
			"codAtivoAte": "until",
			"nContaDe": "Account No.",
			"nContaAte": "up to Account No.",
			"nCCustoDe": "From Cost Center No.",
			"nCCustoAte": "up to Cost Center No.",
			"codAmortDe": "Amortization Code",
			"codAmortAte": "until Amortization Code",
			"codMapaDe": "From Map Code",
			"codMapaAte": "to Map Code",
			"dataAquisicaoDe": "Acquisition Date: From",
			"dataAquisicaoAte": "until",
			"dataAbateDe": "From Slaughter Date",
			"dataAbateAte": "until Slaughter Date",
			"anoEconomico": "Economic year",
			"situacao": "Situation",
			"datareferencia": "Reference Date (For Asset status and depreciation analysis)",
			"pesqavancada": "Advanced Filter",
			"estadoUso": "Usage status"
		},
		"radioOptions": {
			"todos": "Normal and slaughtered/sold in the year",
			"dividido": "Divided",
			"normal": "Normal",
			"abatido": "Shot down",
			"vendido": "Sold",
			"indiferente": "Any state",
			"usado": "Used",
			"novo": "New"
		},
		"table": {
			"ativo": "Active",
			"aquisicao": "Acquisition",
			"classificadores": "Classifiers",
			"contas": "Accounts",
			"dadoscontabilisticos": "Accounting Data",
			"dadosfiscais": "Tax Data",
			"abatevenda": "Slaughter/Sale",
			"limiteminimofiscal": "Minimum tax limit",
			"perdasimparidadecontabilistica": "Accounting impairment losses",
			"perdasimparidadefiscais": "Tax impairment losses",
			"valoresperdidosfiscais": "Tax Lost Values",
			"taxasperdidasfiscais": "Tax Lost Fees",
			"depreciacoesnaoaceites": "Unaccepted depreciations",
			"imparidadesnaoaceites": "Unaccepted impairments",
			"depreciacoesarecuperar": "Depreciation to be recovered",
			"depreciacoesrecuperadas": "Recovered depreciations",
			"imparidadesrecuperadasnoperiodo": "Impairments recovered in the period",
			"acrescemodelo22": "Adds to model 22",
			"deduzmodelo22": "Deducts to model 22",
			"valortributavelativosporimpos": "Taxable value of the Asset for deferred taxes",
			"valortributaveldopassivoporimpos": "Taxable value of deferred tax liabilities",
			"valornaoaceitefiscalmenteporreavaliacoes": "Value not accepted for tax purposes due to revaluations",
			"impostosdiferidos": "Deferred taxes",
			"rendimentosdossubsidios": "Subsidy income",
			"impostosdifSubsidios": "Deferred Taxes on Subsidies",
			"semdados": "No data",
			"sub": {
				"codativo": "Active Code",
				"designacao": "Designation",
				"estado": "state",
				"refexterna": "External Ref.",
				"usado": "Used",
				"valoraquisicaocontabilisticocomr": "Accounting Acquisition Value, with residual",
				"dataaquisicao": "Acquisition Date",
				"mapa": "Map",
				"coddecregulamentar": "Regulatory Decree Code",
				"nomeDecRegulamentar": "Name Regulatory Decree",
				"taxaDoDecRegulamentar": "Regulatory Decree Fee",
				"viatLigArtigo11": "Viat.",
				"codAtivoPai": "Parent Active Code",
				"numContaAtivo": "Active Account No.",
				"numContaDepAcum": "Accumulation Dept. Account No.",
				"numContaDepExerc": "Dept. Account No. Exercise.",
				"valAquisicao": "Val. Acquisition",
				"valResidual": "Residual Val.",
				"taxaDepreciacaoAdoptada": "Adopted Depreciation Rate",
				"reducao": "Reduction",
				"depNoAno": "Dept. in the year",
				"depAcumNoAnoAnterior": "Accum. Dept.",
				"depAcumTotal": "Accum. Dept.",
				"data": "Date",
				"valor": "Value",
				"coefCorrecaoMonetaria": "Coef.",
				"contaVenda": "Sales Account",
				"maisMenosValiaContabilistica": "More/Less accounting value",
				"maisMenosValiaFiscal": "More/Less tax value",
				"noAno": "In the year",
				"acumNoAnoAnterior": "Accum.",
				"acumTotal": "Accum.",
				"taxaIRC": "IRC rate",
				"noAnoAnterior": "In the previous year",
				"total": "Total",
				"situacaoa": "Situation to",
				"situacaoAtDataSTR": "Situation at {{dataReferencia}}",
				"doccontabilidade": "Accounting Doc (acquisition)",
				"codProcesso": "Process Code",
				"nomeProcesso": "Process Name",
				"codCCusto": "Code C. Cost",
				"nomeCCusto": "Name C. Cost"
			}
		},
		"btn": {
			"pesquisar": "View in grid",
			"previsualizar": "Preview",
			"processar": "Process",
			"ok": "To close",
			"dropdown": {
				"depreciacoes": "Consult depreciations",
				"fichaativo": "Asset Sheet",
				"extaquisicao": "Acquisition account statement",
				"extexercicio": "Extract from the Financial Dept. account",
				"extacumulada": "Accumulated Dept. Account Statement",
				"doccontab": "Accounting Doc"
			}
		},
		"modal": {
			"ativoimpressaomodaltitle": "Asset Printing",
			"listagem": "Listing",
			"ativoimpressaodepmodaltitle": "Detail of Asset depreciation",
			"depresumo": "Summary",
			"depDetalhe": "Detail",
			"ativoimpressaodocmodaltitle": "Accounting Doc"
		}
	},
	"ativosmapasoficiais": {
		"header": {
			"codAtivoDe": "Active Code",
			"codAtivoAte": "Active Code",
			"nContaDe": "Account No.",
			"nContaAte": "Account No.",
			"nCCustoDe": "Cost Center No.",
			"nCCustoAte": "Cost Center No.",
			"codAmortDe": "Amortization",
			"codAmortAte": "Amortization Code",
			"nMapa": "Map",
			"dataAquisicaoDe": "Date of acquisition",
			"dataAquisicaoAte": "Date of acquisition",
			"dataAbateDe": "Slaughter Date",
			"dataAbateAte": "Slaughter Date",
			"anoEconomico": "Economic year",
			"situacao": "Situation",
			"registoMes": "Month",
			"natureza": "Nature"
		},
		"radioOptions": {
			"todos": "Normal and slaughtered/sold in the year",
			"normal": "Normal",
			"abatido": "Shot down",
			"vendido": "Sold",
			"qualquer": "Any method",
			"quotasConstantes": "Constant quotas",
			"quotasDecrescentes": "Decreasing shares",
			"indiferente": "Any state",
			"usado": "Used",
			"novo": "New"
		},
		"btn": {
			"previsualizar": "Preview",
			"processar": "Process",
			"ok": "To close"
		},
		"print": {
			"ativoimpressaomodaltitle": "Asset Printing",
			"listagem": "Listing",
			"metodos": "Methods",
			"estadouso": "state"
		},
		"natureza": {
			"qualquer": "Any nature",
			"tangiveis": "Tangible fixed assets",
			"intangiveis": "Intangible Assets",
			"bioNaoConsumiveis": "Non-consumable biological assets",
			"propInvetimento": "Investment properties"
		},
		"card": {
			"selecao": "Selection",
			"de": "In:",
			"ate": "Until:"
		},
		"modal": {
			"title": "Assets - Official Map"
		}
	},
	"ativosmudarcontaativo": {
		"confirmacao": "Notice",
		"temcerteza": "Are you sure you want to change the asset account?",
		"title": {
			"mudarcontaativo": "Change asset account"
		},
		"fields": {
			"ncontanovo": "New Account No.",
			"ncontaatual": "Current Account Number:"
		},
		"message": {
			"success": "Asset Account changed successfully!"
		}
	},
	"ativosmudarcontadep": {
		"confirmacao": "Notice",
		"temcerteza": "Are you sure you want to change the account?",
		"title": {
			"mudarcontadepexerc": "Change fiscal year depreciation account",
			"mudarcontadepacumu": "Change fiscal year depreciation account"
		},
		"fields": {
			"ncontanovo": "New Account No.",
			"ncontaatual": "Current Account Number:"
		},
		"message": {
			"successExercicio": "Depreciation account for the year successfully changed!",
			"successAcumulado": "Accumulated depreciation account successfully changed!",
			"jahadep": "There are already depreciations recorded for the current year, it is not possible to change the account!",
			"contaVazio": "The Account Number cannot be empty."
		}
	},
	"ativosmudartaxasdep": {
		"confirmacao": "Notice",
		"temcerteza": "Are you sure you want to change the depreciation rates?",
		"title": {
			"mudartaxasdep": "Change depreciation rates",
			"depcontab": "Accounting depreciations",
			"depfiscal": "Tax depreciations"
		},
		"fields": {
			"taxanovo": "New rate",
			"taxaatual": "Current rate:"
		},
		"message": {
			"success": "Depreciation rates changed successfully!"
		}
	},
	"ativosnotacredito": {
		"title": "Asset Discount",
		"header": {
			"fornecedor": "Supplier:",
			"valoraquisicao": "Acquisition value:",
			"valornotacredito": "Credit Note Value:",
			"novovaloraquisicao": "New Acquisition value:"
		},
		"btn": {
			"pesquisanota": "Credit note search"
		},
		"table": {
			"nContaDebito": "Debt",
			"nContaCredito": "Credit",
			"valor": "Value",
			"descricao": "Description"
		},
		"modaltable": {
			"nConta": "Account No.",
			"nome": "Name",
			"valor": "Acquisition value",
			"valorCalculado": "Already associated",
			"valorCalculado2": "In new Assets",
			"periodo": "Period",
			"nDiario": "Daily No.",
			"nDocInterno": "Internal Doc. No.",
			"dCDescription": "Deb./Cré",
			"nContaDebito": "Debt",
			"nContaCredito": "Credit",
			"descricao": "Description",
			"designacao": "Designation",
			"codAtivo": "Code.",
			"nContaDoAtivo": "Asset Account No.",
			"contabValorAquisicao": "Value"
		},
		"message": {
			"temdeselecionarum": "You must select a discount!",
			"confirmacao": "Confirmation",
			"temcerteza": "Are you sure you want to associate the Credit Note and reduce the Net Asset Value?",
			"success": "Discount associated successfully!"
		},
		"submodal": {
			"title": "Credit Note Search"
		}
	},
	"ativosvenda": {
		"ativosporvender": "Assets to be sold",
		"datavenda": "Date of Sale",
		"selecionarativos": "Select the Assets to sell",
		"distribuicaovalor": "Distribution of the sale value across Assets",
		"radiooptions": {
			"liquido": "Proportional by net value",
			"bruto": "Proportional by gross value",
			"manual": "Assign manually"
		},
		"message": {
			"success": "Registered sale!",
			"selecionarfatura": "You must select a sale",
			"docnaosaldado": "The selected accounting document is not balanced!"
		},
		"steps": {
			"tabeladocumentos": "Search for new sales in Accounting",
			"detalhesparavenda": "Asset sale values ​​and accounts"
		},
		"card": {
			"valorsemiva": "Value without VAT:",
			"valordoiva": "VAT value:",
			"total": "Total:",
			"docliente": "Of the customer:",
			"maismenosvalia": "More/Less worth:",
			"iva": "VAT:",
			"contas": "Accounts",
			"valores": "Values",
			"coef": "Coef."
		},
		"table": {
			"codAtivo": "Active Code",
			"designacao": "Designation",
			"refExterna": "External Ref.",
			"dataAquisicao": "Dt.",
			"valorAtivoBrutoContab": "Gross assets",
			"valorDepreciacoesAcumuladasContab": "Accumulated Dept.",
			"ativosporvender": "Assets to be sold",
			"ativosavender": "Assets to sell",
			"valorVenda": "Sales Value",
			"total": "Total:"
		},
		"tooltip": {
			"valorNaoValido": "The sum of the values ​​from the sale of Assets is not equal to the value of the document!"
		},
		"btn": {
			"realizarvenda": "Make Sale",
			"pesquisafaturavenda": "Search sales invoice"
		},
		"error": {
			"invalidStep": "You must select a document to register the sale!"
		}
	},
	"auditlogin": {
		"fields": {
			"username": "username",
			"centralGestName": "Installation",
			"cgId": "Installation code",
			"ip": "IP",
			"info": "Information",
			"action": "Action",
			"stampInsert": "Date",
			"nEmpresa": "company no.",
			"nomeEmpresa": "Company name"
		}
	},
	"contratosservicos": {
		"title_detail": "Covenant {{id}}",
		"title_new": "New Covenant",
		"title_plural": "Covenants",
		"pesquisa": "To look for",
		"saved": "Covenant {{id}}, saved successfully",
		"error": "Covenant {{id}}, cannot be saved.",
		"deleted": "Covenant {{id}}, successfully deleted",
		"fields": {
			"cab": {
				"refCtrCab": "Covenant Code",
				"nClasseCtr": "Class",
				"classeCtrNome": "Class name",
				"nClasseCtrPlaceholder": "Class of allowance",
				"nTipoContrato": "Type",
				"tipoContratoNome": "Type name",
				"nTipoContratoPlaceholder": "Type of Covenant",
				"nContrato": "No.",
				"nContratoPlaceholder": "Covenant Number",
				"verContrato": "Version",
				"verContratoPlaceHolder": "Avença version",
				"nContratoAsString": "Covenant",
				"nContratoSemVersaoAsString": "Covenant",
				"descricao": "Description",
				"descricaoPlaceHolder": "Covenant Description",
				"nClifo": "Client",
				"nClifoPlaceHolder": "Client",
				"clifoNome": "Customer Name",
				"idIdeChav": "Account Group",
				"idIdeChavPlaceHolder": "Account Group",
				"dataCtr": "Agreement Date",
				"dataCtrPlaceHolder": "Agreement Date",
				"dataInicio": "Start Date",
				"dataInicioPlaceHolder": "Start Date",
				"dataFim": "End Date",
				"dataFimPlaceHolder": "End Date",
				"estado": "state",
				"estadoPlaceHolder": "State Code",
				"ccusto": "Cost center",
				"ccustoPlaceHolder": "Cost Center Code",
				"nRefProcesso": "Process",
				"nRefProcessoPlaceHolder": "Process Code",
				"codMoeda": "Coin",
				"codMoedaPlaceHolder": "Currency Code",
				"refExterna": "External Reference",
				"terminado": "Finished",
				"totalLiquido": "Value",
				"valorGlobal": "Overall Value",
				"codCondComerciais": "Commercial Condition",
				"codCondComerciaisPlaceHolder": "Commercial Condition Code",
				"estadoContrato": "state",
				"estadoContratoPlaceHolder": "Contract status"
			},
			"linhas": {
				"nArtigo": "Article Code",
				"nomeArtigo": "Description",
				"qtd1": "Qty",
				"prVenda": "Pr. Unit.",
				"d1": "D1",
				"d2": "D2",
				"d3": "D3",
				"desconto": "Discount",
				"d4": "D4",
				"d5": "D5",
				"d6": "D6",
				"codIva": "VAT Code",
				"taxaIva": "VAT rate",
				"nArmazem": "Storage",
				"cunimo": "Items.",
				"qtdPUnid": "Qty./Unit.",
				"nVendedor": "Seller",
				"ccusto": "Cost center",
				"nZona": "Zone",
				"nDepto": "Department",
				"nSubde": "Sub-department",
				"qtd1Fact": "Invoiced Qty",
				"nRefProcesso": "Process",
				"estado": "state",
				"obs": "Comments",
				"valorLiquido": "Liquid"
			}
		},
		"detail": {
			"cab": {
				"dataCtr": "Agreement Date:",
				"nClifo": "Client:",
				"dataFim": "End Date:",
				"dataInicio": "Start date:"
			}
		},
		"tabs": {
			"linhas": "Lines"
		},
		"config": {
			"verGrupoConta": "Show Account Group",
			"verMoeda": "Show Currency",
			"verRefExterna": "Show External Reference",
			"verCondComerciais": "Show Commercial Conditions",
			"verVendedor": "Show Seller",
			"verNCCusto": "Show cost center",
			"verD1": "Show D1",
			"verArmazem": "Show Warehouse",
			"verUnidadeMovimento": "Show Moving unit"
		},
		"totais": {
			"resumo": "Summary",
			"total": "Total"
		},
		"print": {
			"config": {
				"showClasses": "Show class filter"
			},
			"filters": {
				"listagem": "Listing",
				"declifo": "Customer of",
				"ateclifo": "Until",
				"denContrato": "Covenant No. of",
				"atenContrato": "Until",
				"classes": "Classes",
				"tipos": "Types of Covenant",
				"dtdeini": "Start date of",
				"dtateini": "Until",
				"dtdefim": "End date of",
				"dtatefim": "Until",
				"dtdedatactr": "Due date of",
				"dtatedatactr": "Until"
			}
		},
		"errors": {
			"notSelected": "There is no contract selected",
			"terminado": "The agreement {{avenca}} is already finished",
			"notTerminado": "The agreement {{avenca}} is not finished"
		},
		"btn": {
			"actions": "Actions",
			"editar": "To edit",
			"reabrir": "Reopen",
			"terminar": "finish",
			"apagar": "To switch off",
			"configurar": "To set up"
		},
		"info": {
			"terminado": "Finished",
			"reabrir": "Reopen Avença",
			"terminar": "Finish Covenant",
			"apagar": "Delete Covenant"
		},
		"message": {
			"temCertezaReabrir": "Are you sure you want to reopen the agreement {{avenca}}?",
			"temCertezaTerminar": "Are you sure you want to end the agreement {{avenca}}?",
			"temCertezaApagar": "Are you sure you want to delete the avenca {{avenca}}?"
		},
		"modulos": {
			"classes": "Covenant classes",
			"tipos": "Types of covenants",
			"faturacao": "Billing"
		},
		"modal": {
			"faturacaoAuto": {
				"title": "Automatic billing",
				"cards": {
					"configurar": "Type of contract to configure"
				}
			}
		}
	},
	"avisoscobranca": {
		"filtropesq": "Advanced Search",
		"fields": {
			"listagem": "Listing",
			"nContaDe": "Account number",
			"nContaAte": "Until",
			"dataDocDe": "Doc date.",
			"dataDocAte": "Until",
			"dataVencimentoDe": "Due date of",
			"dataVencimentoAte": "Until",
			"nVendedorDe": "Seller number of",
			"nVendedorAte": "Until",
			"nCondPagamDe": "Payment condition number",
			"nCondPagamAte": "Until",
			"nConta": "Account no.",
			"nContaNome": "Name",
			"valor": "Value",
			"dataUltimoAvisoProc": "Last Date/Time",
			"dataUltimoRecebimento": "Last date",
			"dataPrimeiroVencimento": "1st due date",
			"emailDestino": "Email",
			"nDocsPorRegularizar": "Number of documents to be regularized",
			"nDiasUltimoRecebimento": "Last number of days",
			"dividaVencida": "Overdue debt",
			"clifoNaoPodeFacturar": "Can't invoice",
			"ultimoComentario": "Last comment",
			"dataUltimoComentario": "Last date",
			"observacoes": "Comments",
			"nDocExterno": "Doc no.",
			"nDocInterno": "Document number",
			"nDiario": "Daily",
			"periodo": "Period",
			"dataVencimento": "Due date",
			"valorPorPagar": "Amount to be paid",
			"valorPago": "Amount paid",
			"valorCredito": "Credit",
			"valorDebito": "Debt",
			"grupoConta": "Account Group",
			"grupoContaStr": "Account Gr. Description"
		},
		"btn": {
			"previsualizar": "Preview",
			"sendMail": "Send email",
			"pesqinterrompido": "Search with interrupted processing"
		},
		"modal": {
			"avisoscobranca": "Billing notices",
			"avisoscobrancaemail": "Email selected billing notices",
			"avisoscobrancaAlterarEmailProntual": "Change email occasionally",
			"erros": {
				"title": "List of errors",
				"noEmails": "No account was found with pending transactions that have an email configured"
			},
			"emissora": {
				"title": "Configuring billing notices",
				"emissaorefmb": "Multibanco reference issuance",
				"emissora": "Broadcaster",
				"easypay": "EasyPayOnline",
				"desativado": "Disabled",
				"emitirAvisosCobranca": "Do you want to issue MB references in billing notices?",
				"configuradaEasyPay": "The MB Reference issuer is configured: EasyPay",
				"naoExisteConfig": "There is no active MB Reference broadcaster",
				"defaultReport": "Default report"
			}
		},
		"messages": {
			"erroRadicais": "You can only search for customers in this module (Accounts starting with 22).",
			"naoselconta": "You have not selected any account",
			"estadochangedsuccess": "Status changed successfully!",
			"procseminterrompido": "There are records with interrupted processing.",
			"contaSemMail1": "The following selected accounts do not have an email assigned:",
			"contaSemMail2": "Do you intend to continue?",
			"allContasSemMail": "There are no account emails configured",
			"savedsuccessfully": "Data saved successfully!",
			"emailSentSuccessfully": "Email successfully sent."
		},
		"actions": {
			"cliente": "Client",
			"extratoconta": "Account statement",
			"movaberto": "Open moves",
			"previsualiza": "Preview listing",
			"naopodefaturar": "Change customer status to \"Unable to invoice\"",
			"podefaturar": "Change customer status to \"Can invoice\"",
			"doccontab": "Show document.",
			"doccomercial": "Show document.",
			"alterarEmailPontual": "Change email occasionally"
		},
		"config": {
			"showVendedor": "Show seller",
			"showCondPagamento": "Show payment terms"
		},
		"datagrid": {
			"legend": {
				"campoAlterado": "Field occasionally changed"
			}
		}
	},
	"balancetes": {
		"title": "Balance Sheets",
		"fields": {
			"doPeriodo": "From the period",
			"atePeriodo": "Until period",
			"deConta": "From the account",
			"ateConta": "Even count",
			"deCCusto": "From C.Cost",
			"ateCCusto": "Up to C.Cost",
			"planoAlt": "Alternative plan"
		},
		"naoTemTipoConta": "You must select at least one type of account to process!",
		"btn": {
			"processar": "Process to PDF",
			"processarexcel": "Process and export excel",
			"processargrelha": "Process",
			"dropdown": {
				"planocontas": "Chart of accounts",
				"extratosgrid": "Account statements",
				"historicoextratos": "Account statement history",
				"clifos": "Customers and suppliers",
				"movaberto": "Open moves",
				"extratoPlanoAlt": "Alternative plan extracts",
				"acumuPlanoAlt": "Accumulated from the alternative plan"
			}
		},
		"messages": {
			"withoutData": "No data to present!"
		},
		"modal": {
			"title": "Accounting trial balance",
			"tipoperfil": "Profile",
			"pesq": "Search",
			"pesquisar": "To look for",
			"table": {
				"empresa": "{{nempresa}} - {{descempresa}}",
				"descano": "{{year}} - {{periodDesc}} ({{fromperiod}} to {{untilperiod}})",
				"conta": "Account",
				"movperiodo": "Movements of the period",
				"movacumu": "Accumulated movements",
				"saldo": "Balance",
				"total": "Totals",
				"semdados": "No data",
				"header": {
					"nconta": "N. Account",
					"nome": "Name",
					"debito": "Debt",
					"credito": "Credit",
					"saldo": "Balance",
					"saldodebito": "Debit balance",
					"saldocredito": "Credit balance",
					"saldogeral": "General balance"
				}
			},
			"tipocontacontab": {
				"0": "Movement",
				"1": "Subtotal",
				"2": "Reason",
				"3": "Class"
			}
		},
		"tipoperfil": {
			"data": {
				"todas": "All columns",
				"saldo": "Balance columns"
			}
		},
		"tab": {
			"reportpdf": "PDF",
			"reportgrelha": "Grill",
			"grelhaprocessar": "You have to process the grill!"
		}
	},
	"balancetesanalitica": {
		"list": "Listing",
		"periodoDe": "From the period",
		"periodoAte": "Until period",
		"nContaCGeralDe": "From the account",
		"nContaCGeralAte": "Even count",
		"cCustoDe": "From C.Cost",
		"cCustoAte": "Up to C.Cost",
		"zonaDe": "From the Zone",
		"zonaAte": "Up to Zone",
		"departamentoDe": "By Department",
		"departamentoAte": "Until Department",
		"subDepartDe": "From Sub.",
		"subDepartAte": "Until Sub.",
		"familiaDe": "Family's",
		"familiaAte": "Even Family",
		"grFamiliaDe": "Big Family",
		"grFamiliaAte": "Even Big Family",
		"subFamiliaDe": "From Sub.",
		"subFamiliaAte": "Until Sub.",
		"codContabDe": "From Accounting Code.",
		"codContabAte": "Until Accounting Code.",
		"tipoArtigoDe": "Article Type",
		"tipoArtigoAte": "Up to Article Type",
		"classeDe": "Class",
		"classeAte": "Up to Class",
		"categoriaDe": "By Category",
		"categoriaAte": "Up to Category",
		"vendedorDe": "From Seller",
		"vendedorAte": "Until Seller",
		"processoDe": "Process",
		"processoAte": "Until Process"
	},
	"bancos": {
		"title_detail": "Bank {{id}}",
		"title_new": "New Bank",
		"title_plural": "Bank Identification",
		"pesquisa": "To look for",
		"saved": "Bank Identification {{id}}, saved successfully.",
		"error": "Bank Identification {{id}}, cannot be saved.",
		"deleted": "Bank ID {{id}}, successfully deleted",
		"fields": {
			"codBanco": "Code",
			"nome": "Name",
			"designaBreve": "Brief designation",
			"idInterBancaria": "Interbank identification",
			"nomeInterBancaria": "Name of interbank identification",
			"codSwift": "Swift Code"
		}
	},
	"bancosextrato": {
		"config": {
			"autoSuggestDoc": "Automatically suggest release"
		},
		"fields": {
			"bankAccount": "Bank account",
			"startDate": "Date of",
			"endDate": "Date until",
			"date": "Date",
			"description": "Description",
			"amount": "Value",
			"accountBalance": "Balance",
			"predefinido": "Pre-Defined",
			"observacoes": "Comments",
			"saldo": "Current balance",
			"dataSyncDados": "Sync date",
			"nConta": "Account No.",
			"nomeBanco": "Bank name",
			"iban": "IBAN",
			"visivel": "Visible",
			"diarioRecebimentos": "Receipts journal",
			"diarioPagamentos": "Daily payments"
		},
		"actions": {
			"refreshAccount": "Make a request to update your balance and bank account statement (done automatically every 6 hours)",
			"editBankAccount": "Edit bank account",
			"addBankAccount": "Add bank accounts from a new bank",
			"importByReconciliation": "Import bank reconciliation statement",
			"search": "To look for",
			"viewDocument": "View document",
			"ignoreDocuments": "Ignore selected statement lines",
			"stopIgnoreDocuments": "Stop ignoring selected statement line",
			"addPredefDesc": "Assign predefined or account per statement description",
			"editPredefDesc": "Edit predefined assignment or account by statement description",
			"removePredefDesc": "Remove predefined assignment or account by statement description",
			"mergeMode": "Join statement lines in full mode",
			"fullExtract": "See full extract (also shows already launched and ignored)",
			"doReceipt": "Make receipt",
			"doPayment": "Make the payment",
			"configurations": "General configuration",
			"suggestDoc": "Suggest release",
			"lancarDocs": "Post documents",
			"lancarDocsEmSerie": "Launch documents in series",
			"selectAll": "Select all",
			"selectAllMesmoPredefinido": "Select lines with the same predefined",
			"selectAllComPredefinido": "Select all lines that have predefined or account configuration",
			"selectAllComDocDigital": "Selects all docs with matching digital doc",
			"selectAllMesmaConfgPorDesc": "Selects all rows with the same configuration by description",
			"deselectAll": "Clear selection",
			"seeSimilarPostedDocs": "See similar documents already released"
		},
		"actionssimple": {
			"addPredefDesc": "Assign account by statement description",
			"editPredefDesc": "Edit account assignment by statement description",
			"removePredefDesc": "Remove account assignment by statement description"
		},
		"text": {
			"noLicense": "The current company does not have the banking automation license activated.",
			"accessDenied": "You do not have permissions to access banking automation.",
			"accessDeniedStore": "You do not have permissions to access banking automation, please contact your manager to manage access.",
			"activate": "Click here to activate.",
			"selectedTransactions": "Selected Transactions",
			"confirmEditBankAccount": "This bank account does not yet have the chart of accounts bank account assigned.<br/>Do you want to assign it now?",
			"confirmLancarDocsEmSerie": "Are you sure you want to release the selected documents?",
			"confirmLancarDocsEmSerieContabilidadeDigital": "You have chosen to post documents in series in a company with a digital archive.<br/>The system will automatically attach the suggested digital documents, please check if they are correctly classified.<br/>Are you sure you want to post the selected documents ?",
			"successRefreshBankAccount": "The request to refresh balance and bank account statement was made successfully.",
			"successUpdateBankAccount": "Bank account updated successfully.",
			"successAddBankAccount": "Bank account added successfully.",
			"successLancarDocsEmSerie": "Documents launched successfully.",
			"successIgnoreDocuments": "Statement lines skipped successfully.",
			"successStopIgnoreDocuments": "Statement line successfully skipped.",
			"confirmIgnoreDocumentsTitle": "Ignore selected statement lines",
			"confirmIgnoreDocumentsMessage": "Are you sure you want to ignore the selected statement lines?",
			"confirmDeletePredefDescTitle": "Remove configuration by statement description",
			"confirmDeletePredefDescMessage": "Are you sure you want to remove the configuration by statement description?",
			"deletePredefDesc": "Configuration by extract description successfully removed.",
			"promptPrintReceipt": "Do you want to print the receipt?",
			"promptPrintPayment": "Do you want to print the payment?",
			"bankAccountConsentExpiredTitle": "Credential to access the bank is expired",
			"bankAccountConsentExpiredMessage": "The credential to access the bank has expired.",
			"infoEditBankAccountDiarioInheritance": "*If you do not configure the journals, the journals from the general configuration will be used"
		},
		"legend": {
			"lancado": "Already released",
			"ignorado": "Ignored",
			"comDocumentoSemelhante": "There is a document already released on that date with the same value",
			"comConfig": "With preset or account configuration"
		},
		"legendsimple": {
			"comConfig": "With account setup"
		},
		"estado": {
			"0": "Statement to be released",
			"1": "Extract released",
			"2": "Extract ignored",
			"3": "Extract to be released with doc.",
			"naoLancado": "Statement to be released",
			"lancado": "Extract released",
			"ignorado": "Extract ignored",
			"naoLancadoComDocPossivel": "Extract to be released with doc."
		},
		"docDigital": {
			"0": "No documents possible",
			"1": "With a possible document",
			"2": "With several possible documents",
			"naoTem": "No documents possible",
			"tem": "With a possible document",
			"varios": "With several possible documents"
		},
		"dataRegistoRecebimentosPagamentos": {
			"label": "Release date for receipts and payments",
			"dataAtual": "Current date",
			"dataMovimentoBancario": "Bank movement date"
		},
		"errors": {
			"licenseNotActivatedCGOn": "The current company does not have the banking automation license activated.<br/>Please go to the <a href=\"{{href}}\" target=\"_blank\" rel=\"noopener noreferrer\">store</a> CentralGest Cloud and activate or renew the banking automation license.",
			"licenseCGOnExpired": "Your banking automation license has expired.",
			"activateCGBankingLimitReached": "You cannot add more companies to your banking automation license, because you already have \"{{consumed}}\" \"{{total}}\" companies registered.",
			"activateCGBankingCGOnMode": "You cannot activate banking automation, because this is a CGOn company.",
			"emptyNewBankAccountUrl": "Unable to obtain address to add a new bank account.",
			"emptyRenewBankAccountUrl": "Unable to obtain address to renew bank account credential.",
			"newBankAccount": "Unable to add bank account: \"{{error}}\".",
			"emptyBankAccount": "You must indicate a bank account.",
			"emptyDocs": "You have not selected any documents to post."
		},
		"errorsTinkLink": {
			"BAD_REQUEST": "Tink Link URL is incorrectly configured",
			"AUTHENTICATION_ERROR": "You have not successfully authenticated with the financial institution",
			"INTERNAL_ERROR": "Internal error in the Tink service",
			"TEMPORARY_ERROR": "Temporary error in the Tink service."
		},
		"configmodal": {
			"title": "General configuration",
			"success": "Configuration saved successfully",
			"fields": {
				"descricao": "Default description",
				"movAbAuto": "Automatically opens pending transactions when moving to the next record",
				"showDescritivo": "Visible description field"
			}
		},
		"activatelicensemodal": {
			"title": "Activate banking automation in the company \"{{nEmpresa}} - {{nomeEmpresa}}\"",
			"message1": "If you click \"Yes\", the system will add a new company to your banking automation license, and it will not be possible to reverse the situation later.",
			"message2": "At this time, you can still add {{totalToConsume}} companies to your banking automation license.",
			"dadosLicenca": "Banking automation license details:",
			"total": "Total number of companies: {{total}}",
			"totalConsumed": "Total number of companies already added: {{totalConsumed}}",
			"totalToConsume": "Total number of companies you can still add: {{totalToConsume}}"
		},
		"authorizationsmodal": {
			"title": "Consents",
			"fields": {
				"externalId": "Identifier",
				"accountsId": "Account ID",
				"credentialsId": "Credentials ID",
				"providerName": "Provider name",
				"status": "Status",
				"errorMessage": "Message",
				"errorType": "Type",
				"errorReason": "Reason",
				"statusUpdated": "Date",
				"sessionExpiryDate": "Expiration date"
			},
			"text": {
				"deleteConsent": "Delete consent",
				"deleteConsentMessage": "Are you sure you want to delete this consent?<br/>You will have to provide a new consent after carrying out this operation.",
				"successDeleteConsent": "Consent deleted successfully"
			}
		},
		"docssemelhantesmodal": {
			"title": "Similar documents already posted to accounting",
			"fields": {
				"periodo": "Period",
				"diario": "Daily",
				"nDocInterno": "Document No.",
				"nDocExterno": "External Doc. No.",
				"dataLancamento": "Release date",
				"dataDocExterno": "Document date",
				"dc": "A.D",
				"valor": "Value",
				"descricao": "Description",
				"contaCorrente": "Current account"
			},
			"text": {
				"mostrarOutrasDatas": "Show documents with the same value but posted with different dates"
			},
			"actions": {
				"connect": "Connect"
			}
		},
		"editbankaccountmodal": {
			"title": "Editing bank account \"{{nameBank}}\"",
			"fields": {
				"descricao": "Description",
				"nomeBanco": "Bank name",
				"iban": "IBAN",
				"visivel": "Visible",
				"conta": "Bank account"
			},
			"text": {
				"mostrarOutrasDatas": "Show documents with the same value but posted with different dates"
			},
			"actions": {
				"connect": "Connect"
			}
		},
		"importbyreconciliationmodal": {
			"title": "Choosing which bank reconciliation to import"
		},
		"movabmodal": {
			"titleReceipt": "Receipts to be made to the customer",
			"titlePayment": "Payments to be made to the supplier",
			"save": "Register",
			"fields": {
				"nContaClient": "Customer account",
				"nContaSupplier": "Supplier account",
				"valorExtrato": "Bank movement value",
				"valorSelecionado": "Selected value",
				"nDocumento": "Document",
				"descricao": "Description",
				"nDocExterno": "External Doc. No.",
				"dataDoc": "Date Doc.",
				"dataVencimento": "Expiration Date",
				"dc": "Debit credit",
				"valor": "Value",
				"valorPago": "Amount paid",
				"valorPorPagar": "Amount to be paid",
				"valorAReceber": "Amount to be received",
				"valorAPagar": "Amount to pay",
				"valorDesconto": "Discount",
				"data": "Date:"
			},
			"text": {
				"receberTudoReceipt": "Receive all (shortcut \"+\" or \"F9\")",
				"receberTudoPayment": "Pay all (shortcut \"+\" or \"F9\")",
				"limparValor": "Clear value",
				"successReceipt": "Receipt registered successfully",
				"successPayment": "Payment registered successfully"
			},
			"errors": {
				"recPagAmmountNoMatch": "The selected value \"{{valorSelected}}\" is different from the bank transaction value \"{{amount}}\"."
			}
		},
		"predefdescmodal": {
			"title": "Assign predefined or account per statement description",
			"titlesimple": "Assign account by statement description",
			"fields": {
				"descriptionKey": "Description contains",
				"conta": "Counterpart Account",
				"predefinido": "Predefined"
			}
		},
		"simplemodefilter": {
			"filter": "Filter:",
			"onlyentradas": "See only entries",
			"onlysaidas": "See outputs only",
			"onlynaoLancado": "View only unreleased documents",
			"onlylancado": "See only documents already released"
		}
	},
	"blockedPlugin": {
		"text": {
			"noLicense": "The current company does not have the {{portal}} premium license active.",
			"noLicenseStoreMode": "The current company does not have the {{portal}} premium license active.<br/>Please go to <a href=\"{{href}}\" target=\"_blank\" rel=\"noopener noreferrer\">store</ a> from CentralGest Cloud and activate the premium license.",
			"expiredLicenseStoreMode": "Your {{portal}} premium license has expired.",
			"requiredRolesSingle": "This module requires access to the {{portals}} Portal.",
			"requiredRolesMulti": "This module requires access to the Portals {{portals}}."
		}
	},
	"cae": {
		"fields": {
			"codCAE": "Code",
			"nome": "Name",
			"codSubSec": "Subsection Code"
		}
	},
	"calcPrVenda": {
		"pesquisa": "Calculation types",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"marginOnSellingPrice": "Margin on Sales Price",
			"marginOnPurchasePrice": "Margin on Purchase Price"
		}
	},
	"calculodiferimentos": {
		"intervalo": "Interval",
		"fields": {
			"valor": "Value",
			"dataini": "Initial date",
			"datafim": "Final date",
			"tipodiferimento": "Method",
			"tipoDataRefDiferimento": "Reference date for calculation"
		},
		"metodos": {
			"anual": "Yearly",
			"mensal": "Monthly"
		},
		"tipoDataRef": {
			"dataDoDocumento": "Date of document",
			"dataDoLancamento": "Release date"
		},
		"success": "Calculation carried out successfully",
		"linhas": {
			"dataDoc": "Date Doc.",
			"ano": "Civil year",
			"numeroDias": "No. Days",
			"valor": "Value"
		},
		"btn": {
			"lancacontabilidade": "Apply to document"
		},
		"messages": {
			"naobalanceado": "The document is not balanced.",
			"semcontaparadif": "It was not possible to find an account with a value to be added/deferred."
		},
		"modals": {
			"config": {
				"title": "Setting up accruals and deferrals",
				"cardrendimentos": "Income",
				"cardgastos": "Spending",
				"fields": {
					"novoRadical": "New Radical",
					"radicalrendimentos": "Radical",
					"contaAcrescimos": "Account Accruals",
					"contaDiferimentos": "Deferrals Account",
					"radicalgastos": "Radical",
					"contadif": "Deferral account",
					"contaacresc": "Accruals account",
					"calculo": "Calculation",
					"nDiario": "Journal number",
					"descritivoAnulacaoInicial": "Description for canceling the initial release",
					"descritivo": "Description of the value(s) to differ",
					"descritivoAcrescimos": "Description of the value(s) to be added"
				},
				"messages": {
					"radicalJaExistente": "The inserted radical already exists in the list.",
					"apagarItem": "Delete line",
					"desejaApagarItem": "Do you want to delete the line?",
					"successfullySaved": "Changes saved successfully.",
					"campoRadicalVazio": "Radical field cannot be empty.",
					"nContaDiferimentosEmpty": "The deferral account cannot be empty.",
					"nContaAcrescimosEmpty": "The accruals account cannot be empty.",
					"nDiarioEmpty": "The Diary cannot be empty.",
					"descritivoEmpty": "Descriptive cannot be empty."
				}
			},
			"info": {
				"title": "Accruals and deferrals information",
				"infodetalhada": "Detailed information",
				"infomes": "Information per month",
				"exportDetalhes": "Detailed deferral information",
				"exportTotais": "Deferral information per month",
				"fields": {
					"ano": "Year",
					"mesStr": "Month",
					"nDocumento": "Document No.",
					"nConta": "Account No.",
					"nomeConta": "Name",
					"valorDebito": "Debt",
					"valorCredito": "Credit",
					"descricao": "Description",
					"valor": "Value",
					"valorAcrescimoNoMes": "Addition",
					"valorDiferimentoNoMes": "Deferral",
					"saldoAcrescimoNoMes": "Accrual Balance",
					"saldoDiferimentoNoMes": "Deferral Balance",
					"saldo": "Balance"
				}
			}
		}
	},
	"diferimentosglobal": {
		"diferimentos": "Deferrals",
		"acrescimos": "Additions",
		"docsselmes": "Documents in the selected month",
		"docscontribuem": "Documents that contribute to the balance in the selected month",
		"fields": {
			"ano": "Year",
			"mesStr": "Month",
			"nDocumento": "Document No.",
			"valor": "Value",
			"saldo": "Balance",
			"descricao": "Description"
		},
		"infoAction": "Accruals and deferrals information"
	},
	"calendarios": {
		"title_detail": "Calendar {{id}}",
		"title_plural": "Calendars",
		"pesquisa": "To look for",
		"fields": {
			"calenCabID": "Code.",
			"nome": "Name",
			"sabadosDiaUtil": "Saturdays as a working day",
			"domingosDiaUtil": "Sundays as a working day",
			"naoTemFeriados": "There are no holidays"
		}
	},
	"cargveic": {
		"title_detail": "Vehicle",
		"title_new": "New Vehicle",
		"title_plural": "Vehicles",
		"pesquisa": "To look for",
		"saved": "Vehicle successfully stored.",
		"error": "Vehicle cannot be stored.",
		"deleted": "Vehicle erased successfully.",
		"deleteModalText": "Delete vehicle with registration number \"{{matricula}}\" ?",
		"reboqueJaExiste": "The trailer with registration number \"{{matricula}}\" already exists.",
		"reboques": "Trailers",
		"adicionarReboque": "Add Trailer",
		"fields": {
			"matricula": "Registration",
			"reboque": "Trailer",
			"desativado": "Disabled",
			"nConta": "No.",
			"pesoTara": "Vehicle Tare",
			"nomeTranspor": "Carrier Name",
			"nomeViatura": "Vehicle Name",
			"modelo": "Model",
			"motor": "Motor",
			"marca": "Brand",
			"chassi": "Chassis",
			"kilometros": "Kilometers",
			"matriculaReboque": "Trailer registration"
		},
		"btn": {
			"newArtigo": "New"
		},
		"list": {
			"actions": {
				"editar": "To edit"
			}
		},
		"messages": {
			"temAlteracoes": "There are changes not yet saved.",
			"apagarReboque": "Delete Trailer"
		}
	},
	"categoriaartigo": {
		"title_detail": "Category Article {{id}}",
		"title_new": "New Category Article",
		"title_plural": "Categories Article",
		"pesquisa": "To look for",
		"saved": "Category Article {{id}}, saved successfully",
		"error": "Category Article {{id}}, cannot be saved.",
		"deleted": "Category Article {{id}}, successfully deleted",
		"fields": {
			"categoria": "Category:",
			"descricao": "Description:",
			"categoriaPlaceholder": "Category:",
			"descricaoPlaceholder": "Description:"
		}
	},
	"categoriaProdutos": {
		"pesquisa": "Category Products",
		"fields": {
			"value": "Code",
			"name": "Description"
		},
		"data": {
			"mercadorias": "M - Goods",
			"materiasPrimas": "P - Raw, subsidiary and consumable materials",
			"produtosAcabados": "A - Finished and intermediate products",
			"subprodutos": "S - By-products, waste and scrap",
			"produtosTrabalhosEmCurso": "T - Products and work in progress",
			"naoAplicaveis": "N/A - Not applicable",
			"ativosBiologicos": "B - Biological assets"
		}
	},
	"categorias": {
		"title_detail": "Category {{id}}",
		"title_new": "New Category",
		"title_plural": "Categories",
		"pesquisa": "search",
		"deleted": "Category {{id}}, deleted successfully",
		"saved": "Category {{id}}, saved successfully",
		"error": "Category {{id}}, not saved.",
		"fields": {
			"nCateg": "Category No.",
			"designaBreve": "Designation",
			"designaCompl": "Full Designation",
			"codCategoriaMT": "IRCT/DGEEP Category Code",
			"nomeCategoria": "Name Category IRCT/DGEEP"
		}
	},
	"cct": {
		"title_detail": "Collective Labor Agreement {{id}}",
		"title_new": "New Collective Labor Agreement",
		"title_plural": "Collective Labor Contracts",
		"pesquisa": "To look for",
		"saved": "Collective Labor Agreement {{id}}, saved successfully.",
		"error": "Collective Labor Agreement {{id}}, cannot be saved.",
		"deleted": "Collective Labor Agreement {{id}}, successfully deleted",
		"fields": {
			"ncct": "CCT Code",
			"designaBreve": "Brief Description",
			"nome": "Design.",
			"numBolTrab": "Job Bulletin No.",
			"dtBolTrab": "Work Bulletin Date",
			"mediaTabelas": "Table Average",
			"dtIniValidad": "Start Date Validity",
			"codIRCT": "IRCT Code for Personnel",
			"aplicabilidadeIRCT": "IRCT applicability"
		},
		"actions": {
			"taboficialirct": "Official IRCT Personnel Tables"
		}
	},
	"ccustos": {
		"title_detail": "Cost Center {{id}}",
		"title_new": "New Cost Center",
		"title_plural": "Cost center",
		"pesquisa": "To look for",
		"saved": "Cost Center {{id}}, saved successfully",
		"deleted": "Cost Center {{id}}, successfully deleted",
		"error": "Cost Center {{id}}, cannot be saved.",
		"fields": {
			"nCCusto": "C. Cost",
			"nCCustoPlaceholder": "Cost center code",
			"nome": "Name",
			"nomePlaceholder": "Cost center name",
			"encerrado": "Closed"
		}
	},
	"cgasi": {
		"title_detail": "Caixa Geral de Aposentações Situation Code {{id}}",
		"title_new": "New Situation Code for Caixa Geral de Aposentações",
		"title_plural": "Caixa Geral de Aposentações Situation Codes",
		"pesquisa": "To look for",
		"saved": "Caixa Geral de Aposentações Status Code {{id}}, saved successfully.",
		"error": "Caixa Geral de Aposentações Status Code {{id}}, cannot be saved.",
		"deleted": "Caixa Geral de Aposentações Status Code {{id}}, successfully deleted.",
		"fields": {
			"codCGASit": "CGA Status Code",
			"nomeCGASit": "Description",
			"tipoCodCGASit": "Code Type",
			"obs": "Comments"
		}
	},
	"classescontratos": {
		"title_detail": "Contract Class {{id}}",
		"title_new": "New Class of Contract",
		"title_plural": "Classes of Contracts",
		"pesquisa": "To look for",
		"saved": "Contract Class {{id}}, saved successfully",
		"error": "Contract Class {{id}}, cannot be saved.",
		"deleted": "Contract Class {{id}}, successfully deleted",
		"fields": {
			"nClasseCtr": "Code",
			"nClasseCtrPlaceholder": "Contract Class Code",
			"nomeClasseCtr": "Name",
			"nomeClasseCtrPlaceholder": "Contract Class Name",
			"classeTipo": "Class Type",
			"classeTipoPlaceholder": "Contract Type Class"
		}
	},
	"classescontratosservicos": {
		"title_detail": "Covenant Class {{id}}",
		"title_new": "New Class of Agreement",
		"title_plural": "Covenant Classes",
		"pesquisa": "To look for",
		"saved": "Covenant Class {{id}}, saved successfully",
		"error": "Covenant Class {{id}}, cannot be saved.",
		"deleted": "Covenant Class {{id}}, successfully deleted",
		"fields": {
			"nClasseCtr": "Code",
			"nClasseCtrPlaceholder": "Covenant Class Code, if not filled in, it will be generated automatically.",
			"nomeClasseCtr": "Name",
			"nomeClasseCtrPlaceholder": "Name of the Covenant Class"
		}
	},
	"classescaixa": {
		"title_detail": "Box Class {{id}}",
		"title_new": "New Cash Class",
		"title_plural": "Cash Classes",
		"pesquisa": "To look for",
		"saved": "Box Class {{id}}, saved successfully",
		"error": "Box Class {{id}}, cannot be saved.",
		"deleted": "Box Class {{id}}, deleted successfully",
		"fields": {
			"nClasseCaixa": "Box class code",
			"designacaoBreve": "Brief designation",
			"percentPatr": "Employer percentage",
			"percentEmpr": "Percentage employed",
			"naoProcSubNatal": "Does not process subs.",
			"naoProcSubFerias": "Does not process subs.",
			"ncrss": "N. RSS center",
			"tipoRegime": "Regime type",
			"descricao": "Description",
			"taxas": "Fees",
			"config": "settings",
			"naoVaiSegSocial": "Does not send data to Social Security",
			"percentPATR": "Employer",
			"percentEMPR": "Employee",
			"percenttitle": "% of contributions",
			"codIntegracaotitle": "Accounting integration",
			"limites": "Limits on monthly contributions in N. of minimum wages",
			"codIntegracao": "Integration code",
			"temLimiteMaximo": "Has a maximum limit",
			"limMaxNSalariosMin": "Salt No. Max.",
			"temLimiteMinimo": "Has a minimum limit",
			"limMinNSalariosMin": "Salt No. Min."
		}
	},
	"tipoRegime": {
		"empregados": "Employees",
		"gerenteMOE": "Managers/MOE",
		"primeiroEmprego": "1st Job",
		"pensionistas": "Pensioners",
		"deficientes": "Disabled",
		"trabEntSFimLuc": "Work.",
		"trabOutros": "Other Jobs",
		"praticantesDesportivos": "Professional sports practitioners",
		"servicoDomesticoConvencional": "Domestic service - Conventional remuneration",
		"servicoDomesticoReal": "Domestic service - Real pay"
	},
	"clicls": {
		"title_detail": "Customer/Supplier Class {{id}}",
		"title_new": "New Customer/Supplier Class",
		"title_plural": "Customer/Supplier Classes",
		"pesquisa": "To look for",
		"saved": "Customer/supplier class {{id}}, saved successfully.",
		"error": "Customer/supplier class {{id}}, cannot be saved.",
		"deleted": "Customer/vendor class {{id}}, successfully deleted.",
		"fields": {
			"codigo": "Code",
			"descricao": "Description"
		}
	},
	"clifoExtraValueTipos": {
		"fields": {
			"tipo": "Type",
			"descricao": "Description",
			"natureza": "Nature",
			"codigo": "Code"
		}
	},
	"clifoExtraValues": {
		"save": "Saved successfully!",
		"errorEmailVazio": "The email cannot be null.",
		"errorEmailJaExiste": "The email already exists.",
		"errorEmailInvalid": "The email must be valid.",
		"modal": {
			"title": "Add email",
			"editTitle": "Edit emails ({{title}})",
			"table": {
				"fields": {
					"email": "Email"
				}
			}
		},
		"modalConfirmDelete": {
			"title": "Attention!",
			"message": "Are you sure you want to delete this record?"
		},
		"fields": {
			"valor": "Email",
			"codigo": "Type"
		},
		"table": {
			"descricao": "Email type",
			"valor": "Value"
		},
		"btn": {
			"adicionar": "To add",
			"remover": "To remove",
			"addEmail": "Add email",
			"new": "New"
		},
		"tooltips": {
			"addNewTypeEmail": "Add email type",
			"editTypeEmail": "Edit email type",
			"removeTypeEmail": "Remove email type"
		}
	},
	"clifos": {
		"pesquisa": "To look for",
		"tab": {
			"ficha": "Token",
			"dadosconta": "Account Data",
			"extratos": "Extracts",
			"artigos": "Articles",
			"anexos": "Attachments",
			"encomendas": "Orders"
		},
		"title_detail": "Customer/supplier {{id}}",
		"title_new": "New Customer/supplier",
		"title_plural": "Customers/suppliers",
		"saved": "Customer/supplier {{id}}, saved successfully",
		"error": "Customer/supplier {{id}}, cannot be saved.",
		"deleted": "Third {{id}}, deleted successfully",
		"navigation": {
			"tooltipFirst": "First",
			"tooltipPrevious": "Previous",
			"tooltipNext": "Following",
			"tooltipLast": "Last"
		},
		"groups": {
			"indentificacao": "Identification",
			"estadoAtVies": "Status in VIES",
			"dadosprincipais": "Main data",
			"dadosfinan": "Financial data",
			"condECaract": "Characteristics",
			"classificadores": "Classifiers",
			"moradasAlt": "Alternative addresses",
			"tiposEmail": "Email types"
		},
		"fields": {
			"nConta": "Account no.",
			"nContaPlaceholder": "Account number",
			"nIdAltern": "Alternate ID.",
			"nIdAlternPlaceholder": "Alternative identification number",
			"nome": "Name",
			"nomePlaceholder": "Name",
			"nContribuint": "No.",
			"nContribuintPlaceholder": "Taxpayer number",
			"rua": "Road",
			"ruaPlaceholder": "Road",
			"codPostal": "Postal code",
			"codPostalPlaceholder": "Postal Code",
			"localidade": "Location",
			"localidadePlaceholder": "Location",
			"telefone": "Telephone",
			"telefonePlaceholder": "Telephone",
			"fax": "Fax",
			"faxPlaceholder": "Fax",
			"nomePais": "Country",
			"nomePaisPlaceholder": "Country",
			"email": "Email",
			"emailPlaceholder": "Email",
			"codRet": "Retention code",
			"codRetPlaceholder": "Retention rate code",
			"situacaoActual": "Current situation",
			"desactivado": "Disabled",
			"naoPodeFacturar": "Can't invoice",
			"codCondComerciais": "Cond.",
			"codCondComerciaisPlaceholder": "Commercial condition code",
			"codPagamento": "Cond.",
			"codPagamentoPlaceholder": "Payment condition code",
			"observacoes": "Comments",
			"observacoes2": "Observations (Cont.)",
			"idSubGrContaCorrente": "Nature counts",
			"idSubGrContaCorrentePlaceholder": "Selected record generates account number according to nature",
			"iban": "IBAN",
			"ibanPlaceholder": "IBAN",
			"swift": "SWIFT",
			"swiftPlaceholder": "SWIFT",
			"nomeMoeda": "Billing currency code",
			"nomeMoedaPlaceholder": "Billing currency code",
			"facPublicaModelo": "Public billing model",
			"idideChav": "Account group",
			"idideChavPlacholder": "Account group",
			"nTxtCabeca": "Billing notice header",
			"nTxtCabecaPlacholder": "Billing notice header",
			"nTxtPE": "Billing notice footer",
			"nTxtPEPlacholder": "Billing notice footer",
			"codZona": "Zone",
			"codVendedor": "Seller",
			"ruaCont": "Street (Continued)",
			"telemovel": "Cell phone",
			"telemovelPlaceholder": "Cell phone",
			"wwwSite": "Web page",
			"wwwSitePlaceholder": "Web page",
			"utilizaIVAFichaClifo": "Apply VAT rate on the form",
			"codIva": "VAT rate",
			"obrigaNumRequisicao": "Requires \"Request\"",
			"classeClifo": "Class",
			"situacaoComercial": "Commercial situation",
			"nifSituacaoAtDescricao": "AT",
			"nifSituacaoViesDescricao": "BIAS",
			"autoFaturacao": "Self-billing",
			"textoFaturacao": "Billing Notes"
		},
		"errors": {
			"contaNaoExiste": "The account does not exist"
		},
		"avisodadosrepetidosmodal": {
			"title": "Customer/Supplier with repeated data",
			"bodyMessage": "There are already Customer(s)/Supplier(s) with the same taxpayer number or name."
		},
		"anexos": "Attachments",
		"selectNavTipoEmail": "Go to email types",
		"text": {
			"iban": "Mandatory for SEPA payments to Suppliers",
			"modeloFatPublic": "The billing behavior changes depending on the model used: Manual - CIUS-PT / Webservice - Saphety and FEAP",
			"aplicaTaxaIva": "Ignores the VAT rate of the Item.",
			"codRetencao": "Additional billing information",
			"codMoeda": "Additional information on billing.",
			"textoFaturacao": "Visible text when issuing documents."
		},
		"btn": {
			"validaNif": "Validate NIF in VIES",
			"btnNewMoral": "New"
		}
	},
	"clientes": {
		"pesquisa": "To look for",
		"tab": "Token",
		"title_detail": "Customer {{id}}",
		"title_new": "New customer",
		"title_plural": "Customers",
		"title_singular": "Client",
		"saved": "Client {{id}}, saved successfully",
		"error": "Customer {{id}}, cannot be saved.",
		"deleted": "Client {{id}}, deleted successfully",
		"showZona": "Show zone field",
		"showDesativados": "Show disabled only",
		"showVendedor": "Show seller field"
	},
	"fornecedores": {
		"pesquisa": "To look for",
		"tab": "Token",
		"title_detail": "Supplier {{id}}",
		"title_new": "New supplier",
		"title_plural": "Suppliers",
		"title_singular": "Supplier",
		"saved": "Supplier {{id}}, saved successfully",
		"error": "Supplier {{id}}, cannot be saved.",
		"deleted": "Supplier {{id}}, successfully deleted",
		"showDesativados": "Show disabled only"
	},
	"outrosdevedorescredores": {
		"pesquisa": "To look for",
		"title_detail": "Other Debtor/Creditor {{id}}",
		"title_new": "New Debtor/Creditor",
		"title_plural": "Debtors/creditors",
		"title_singular": "Debtor/creditor",
		"saved": "Debtor/Creditor {{id}}, saved successfully",
		"error": "Debtor/Creditor {{id}}, cannot be saved.",
		"deleted": "Debtor/Creditor {{id}}, successfully deleted"
	},
	"modal": {
		"txtFaturacao": {
			"title": "Billing notes"
		}
	},
	"cltrb": {
		"title_detail": "Job Class {{id}}",
		"title_new": "New Job Class",
		"title_plural": "Job Classes",
		"pesquisa": "To look for",
		"saved": "Job Class {{id}}, saved successfully.",
		"error": "Job Class {{id}}, cannot be saved.",
		"deleted": "Job Class {{id}}, deleted successfully.",
		"fields": {
			"nclasTrab": "Class Code",
			"designaBreve": "Brief Designation",
			"designaCompl": "Full Designation"
		}
	},
	"codigoscontabilisticos": {
		"title_detail": "Accounting code {{id}}",
		"title_new": "New accounting code",
		"title_plural": "Accounting codes",
		"pesquisa": "To look for",
		"saved": "Accounting code {{id}}, saved successfully.",
		"error": "Accounting code {{id}}, cannot be saved.",
		"deleted": "Accounting code {{id}}, successfully deleted.",
		"fields": {
			"codcontab": "Code",
			"nome": "Name"
		},
		"placeholders": {
			"codcontab": "Accounting code",
			"nome": "Accounting code name"
		}
	},
	"codpostais": {
		"title_detail": "Postcode {{id}}",
		"title_new": "New Postal Code",
		"title_plural": "ZIP codes",
		"pesquisa": "To look for",
		"saved": "Postcode {{id}}, saved successfully",
		"error": "Postcode {{id}}, cannot be saved.",
		"deleted": "Postcode {{id}}, deleted successfully",
		"fields": {
			"codPostal": "Postcode",
			"codPostalPlaceholder": "Postal Code",
			"localidade": "Location",
			"localidadePlaceholder": "Zip code locality",
			"codPais": "Country Code",
			"nomePais": "Country"
		}
	},
	"colaboradores": {
		"pesquisa": "To look for",
		"title": "Collaborators",
		"uploadSuccess": "File uploaded successfully",
		"uploadError": "An error occurred when trying to load the file",
		"comunicacoesinternas": {
			"titlenew": "New internal communication",
			"title": "Internal communication"
		},
		"docsplata": {
			"title": "Docs.",
			"text": {
				"servicos": "services",
				"servico": "Service",
				"papeis": "Papers",
				"papel": "Paper"
			},
			"modal": {
				"title": "New HR platform document",
				"titulo": "Title",
				"dataValidade": "Expiration date",
				"errorFiles": "You must upload at least one file.",
				"errorServicosSelected": "You must choose at least one service to which the document will be associated.",
				"errorPapeisSelected": "You must choose at least one role to which the document will be associated."
			}
		},
		"board": {
			"fields": {
				"servico": "Service",
				"dadosColaborador": "Collaborator data",
				"colaborador": "collaborator",
				"saldoFerias": "Holiday Balance",
				"feriasGozadas": "Enjoyed Holidays",
				"faltas": "Fouls",
				"horasSemanais": "Week hours",
				"localTrabalho": "Workplace",
				"tipoHorario": "Type of schedule",
				"tipoContrato": "Type of contract",
				"validadeContrato": "Contract validity",
				"centroCusto": "cost center",
				"reparticao": "Breakdown",
				"ferias": "Vacation",
				"abonos": "Allowances"
			},
			"msg": {
				"nenhumColabSelected": "No collaborators selected"
			}
		}
	},
	"companystatus": {
		"header": "The company \"<em>{{nCompany}} - {{nomeEmpresa}}\"</em> is unreachable due to the scheduling or execution of the following task:",
		"status": "state",
		"tasks": {
			"ECGOnFimAno": {
				"title": "End of the year",
				"state": {
					"fasInactive": "Inactive",
					"fasScheduled": "Scheduled",
					"fasStarted": "Running...",
					"fasEnded": "Finished"
				}
			}
		},
		"text": {
			"back": "To go back",
			"description": "Task requested in <em>\"{{requested}}\"</em> by user <em>\"{{user}}\"</em> for company <em>\"{{company}}\"</em> .",
			"scheduled": "Scheduled to start at <em>\"{{scheduled}}\"</em>."
		},
		"cancel": {
			"title": "Cancel appointment",
			"message": "Are you sure you want to unschedule the task \"{{taskName}}\"?"
		}
	},
	"components": {
		"usernav": {
			"name": "Hello, {{name}}!",
			"store": "Manage your plans and companies"
		},
		"cgmodal": {
			"choosecountry": {
				"title": "Select Country",
				"btn": "Copy template",
				"codigo": "Code.",
				"designacaoPT": "Designation (PT)",
				"codigoAlpha2": "ISO3166 Alpha-2",
				"codigoAlpha3": "ISO3166 Alpha-3",
				"designacao": "First name language"
			}
		},
		"filter": {
			"advancedFilter": "Advanced filter"
		},
		"multiselect": {
			"available": "Available",
			"selected": "Selected"
		},
		"devextreme": {
			"datagrid": {
				"preferences": "settings",
				"statestoring": {
					"clear": "Clear settings"
				}
			}
		},
		"contabilidade": {
			"listagens": {
				"title": "Listing",
				"account": "Account",
				"fromAccount": "From the account",
				"toAccount": "Even count",
				"fromPeriod": "Of period",
				"toPeriod": "Until period",
				"fromContrib": "From the Taxpayer",
				"toContrib": "Even Taxpayer",
				"total": "Total:",
				"fromdiary": "From diary",
				"todiario": "Even diary",
				"to": "Until"
			},
			"balancetes": {
				"list": "Listing",
				"accountTypes": "Account types",
				"yearsToCompare": "Years to compare",
				"hideAccountsZeroBalance": "Hide accounts with zero balance",
				"calculaSaldosIniciaisAnoNMaisUm": "Calculate beginning balances of {{nextYear}} with year {{year}} open"
			},
			"extratosgrid": {
				"contaInvalida": "Invalid account field.",
				"linhas": {
					"nConta": "Account",
					"nomeConta": "Account Name",
					"nDocExterno": "External Doc",
					"dataVenc": "Expiration Date",
					"descricao": "Description",
					"valorDebito": "Debit Amount",
					"valorCredito": "Credit Value",
					"porPagar": "Unpaid",
					"dataDoc": "Date Doc.",
					"moeda": "Coin",
					"nCCusto": "NCCost",
					"valorDebitoSegMoeda": "Debit Amount According to Currency",
					"valorCreditoSegMoeda": "Credit Value According to Currency",
					"cambioSegMoeda": "Currency Exchange",
					"saldo": "Balance",
					"descritivo": "Descriptive",
					"perioDiarioDoc": "Document No.",
					"temDocDigital": "Digital Doc",
					"stampInsert": "Date Insert",
					"periodo": "Period",
					"diario": "Daily",
					"ndocInterno": "Internal Doc"
				},
				"options": {
					"showDiarios": "Show diaries"
				},
				"btn": {
					"processar": "Process",
					"showpdf": "Process PDF",
					"sendMail": "Send email"
				}
			}
		},
		"keybindingshelper": {
			"header": "Hotkeys"
		},
		"configOptions": {
			"title": "options",
			"revertToDefault": "Revert to default options"
		},
		"genericImporter": {
			"title": "{{type}} import",
			"steps": {
				"downloadModel": {
					"title": "Download template",
					"content": {
						"1": "Welcome to the {{type}} import wizard.",
						"2": "Please click the button below to download the {{type}} import template.",
						"3": "After downloading the file, fill it in with the data you want to import.",
						"4": "Attention: You must have spreadsheet editing software."
					}
				},
				"uploadModel": {
					"title": "Upload completed template",
					"content": {
						"1": "Fill in the file with the data you want to import.",
						"2": "After filling in the file, upload the file to continue importing."
					}
				},
				"analyzeErrors": {
					"title": "Analyze errors",
					"content": {
						"2": "To analyze the file with errors, download it by clicking the button below.",
						"1.1": "At least one error occurred",
						"1.2": "while importing {{type}}."
					}
				},
				"success": {
					"title": "Finish",
					"content": {
						"1": "Import of {{type}} completed successfully.",
						"2": "Click \"Finish\" to finish the {{type}} import process."
					}
				}
			},
			"types": {
				"0": "articles",
				"1": "customers",
				"2": "suppliers",
				"3": "other debtors/creditors",
				"4": "collaborators",
				"5": "customer current accounts",
				"6": "supplier current accounts",
				"7": "conversion matrix"
			},
			"actions": {
				"downloadModel": "Download import template",
				"downloadErrors": "Download file with import errors",
				"retry": "Try again",
				"import": "import"
			},
			"errors": {
				"requiredUploadModel": "To continue the import, you must upload the file with the data you want to import."
			}
		},
		"scheduler": {
			"text": {
				"colaborador": "collaborator"
			}
		},
		"refeicoesscheduler": {
			"header": {
				"previous": "Previous",
				"today": "Today",
				"next": "Next"
			}
		},
		"panelmultipleselectionexecute": {
			"labels": {
				"selectedDocs": "Selected documents"
			},
			"actions": {
				"lancarDocs": "Post documents",
				"lancarDoc": "Post document"
			}
		}
	},
	"concelhos": {
		"title_detail": "Council {{id}}",
		"title_new": "New Municipality",
		"title_plural": "Counties",
		"pesquisa": "To look for",
		"saved": "Municipality {{id}}, saved successfully",
		"error": "Council {{id}}, cannot be saved.",
		"deleted": "Council {{id}}, successfully deleted",
		"fields": {
			"codConcelho": "County Code",
			"nomeConcelho": "Name of the Municipality",
			"codDistrito": "District Code",
			"nomeDistrito": "District",
			"taxaDerrama": "Rate Spills",
			"codConcelhoAT": "AT County",
			"ano": "Year"
		},
		"cardderrama": "Calculation of pour rate",
		"modal": {
			"title": "Spill Rate Calculation",
			"editardados": "edit data",
			"fields": {
				"tipoDadosSTR": "Type",
				"valor": "Value",
				"condicaoCumprida": "OK",
				"tipoTaxaSTR": "Rate Type",
				"taxa": "Rate",
				"ambito": "Scope"
			}
		}
	},
	"conciliacaoBancaria": {
		"mostrarLinhasConciliadasLabel": "Show matched lines",
		"addDocNaoConciliadosTitle": "Add unreconciled document(s) from previous periods",
		"addExtradoBancario": "To add",
		"addLinhaExtradoBancario": "Add entry in accounting and bank",
		"addLancPrimeiraConciliacaoExtradoBancario": "Add bank first reconciliation entry",
		"addExtradoBancarioBtn": "To add",
		"novaConciliacao": "New conciliation",
		"printConciliacao": "Print reconciliation",
		"print": "Print out",
		"editarConciliacao": "Edit reconciliation",
		"refreshConciliacao": "Update reconciliation",
		"eliminaConciliacao": "To eliminate",
		"terminarConcilidacao": "finish",
		"terminadaConcilidacao": "Conciliation completed.",
		"terminarConcilidacaoModalMsg": "Are you sure you want to end the conciliation?",
		"eliminaConciliacaoModalMsg": "Are you sure you want to delete the match \"{{name}}\"?",
		"eliminaTodasConciliacoesModalMsg": "Are you sure you want to delete all reconciliations for the account \"{{conta}}\"?",
		"selectConciliacaoMessage": "Select the bank you want to reconcile or create a new bank reconciliation.",
		"seraLancadaNaConta": "The new line will be posted to the account:",
		"seraCriadaNovaConta": "New account will be created:",
		"searchByValue": "Search by value...",
		"addDocsNaoConciliadosPeriodoAnt": "Add unreconciled document(s) from previous periods",
		"addDocsNaoConciliadosPeriodoAntBtn": "Add document(s)",
		"naoExistemDocs": "There are no unreconciled document(s) from previous periods",
		"totalSelecionado": "Total selected",
		"hideTreeText": "Hide tree",
		"showTreeText": "Show tree",
		"removeTodasLinhasExtratoBancoTitle": "To switch off",
		"removeTodasLinhasExtratoBancoMsg": "Are you sure you want to delete all lines?",
		"extratoContabilidadeTitle": "Accounting extract",
		"extratoBancoTitle": "Bank statement",
		"viewDocTooltip": "See release",
		"viewDocModalTitle": "View document",
		"associarExtratoTooltip": "Associate statement",
		"associarLinhasDosExtratosEmAmbosLadosTooltip": "Associates the selected extract lines on both sides",
		"removeLinhaExtratoBancoTooltip": "Remove line",
		"eliminarTodasConciliacoesBanco": "Delete all bank reconciliations",
		"lancamentoContab": "Entry into accounting",
		"printModalTitle": "Print out",
		"deNConta": "Account No.",
		"ateNConta": "Up to Account No.",
		"listagem": "Listing",
		"dePeriodo": "Period",
		"atePeriodo": "Until Period",
		"applyFilters": "Apply filters",
		"saldoExtratoBancarioPerspetivaBancoTitle": "Bank statement balance from the bank's perspective",
		"periodoNaoEValidoDeveIndicarOPeriodpSeg": "The period is not valid, you must indicate the period following the last reconciliation",
		"legendaLabel": "Subtitle:",
		"adicionarLinhaPrimeiraConcilExtratoCG": "Add first reconciliation line to accounting statement",
		"adicionarDocsPrimeiraConcilExtratoCG": "Add first reconciliation documents",
		"importaDocsPendentesConcilAntiga": "Import pending documents in old reconciliation accounting",
		"extratoContaPendentes": "Pending account statement",
		"importaDocsPendentesBancoConcilAntiga": "Import pending documents in the old conciliation bank",
		"importarExtratoBancario": "Import bank statement",
		"adicionarLancamentoBanco": "Add bank entry",
		"conciliarAutomaticamente": "Automatically reconcile",
		"addLinhaPrimeiraConcilExtratoCGModalTitle": "Add first reconciliation line to Accounting account",
		"editLinhaPrimeiraConcilExtratoCGModalTitle": "Edit first reconciliation line to accounting account",
		"lancNaContaContabilidadeNaPerspetivaEmpresaTitle": "Entry into the accounting account from the company's perspective",
		"tipoMovimentoNaPerspectivaEmpresaTitle": "Type of movement from the company's perspective",
		"oValorMovimentoNaoPodeSer0ouInferior": "The movement value cannot be 0 or less.",
		"editLanc": "Edit release",
		"removeLinha": "Remove line",
		"desassociarLinha": "Disassociate line",
		"editLinhaExtradoBancarioModalTitle": "Edit bank statement line",
		"extPocItemNotFound": "Accounting entry not found",
		"adicionarDocsExtratoBancarioAContabilidadeModalTitle": "Add bank statement documents to accounting",
		"maisInfoSobreSaldos": "Click to see more details about balances",
		"viewSaldoDetalhesModalTitle": "Reconciliation balance details",
		"saldoContabilidade": "Accounting balances",
		"saldoBanco": "Bank balances",
		"associarTodoExtratoCGAoExtradoDoBanco": "Lines that are not reconciled in the company statement will be created in the bank statement and automatically reconciled",
		"removerTodasLinhasExtrato": "Remove all lines from bank statement",
		"temQueSelecionarLinhas": "You have to select lines to carry out the operation.",
		"confirmacao": "Confirmation",
		"sAntesDeProsseguirDeveVerificarSe": "Before proceeding, you must check whether the last reconciliation (Old Module) is correct.",
		"s01Contrapartida2": "{{radical}}{{nconta}} - Unclassified pendants.",
		"adicionarLinhaExtratoBancario": "Add line to bank statement",
		"adicionarDocsDoExtratoBancarioAContabilidade": "Add bank statement documents to accounting",
		"saldosNaPerspetivaDoBanco": "Balances from the bank's perspective",
		"import": "Import extract",
		"noFiles": "Select the file to process...",
		"importTab": "Importer",
		"importResultsTab": "Results",
		"osSaldosDoExtratoBancarioImporta": "The balances on the imported bank statement do not match the balances indicated in the reconciliation.",
		"adicionarAContabilidade": "Add to Accounting",
		"ordenarPorDataDocExt": "Sort by Ext Doc Date.",
		"ordenarPorValor": "Sort by value",
		"ordenarPorData": "Sort by date",
		"ordenarPorDescricao": "Sort by description",
		"ordenarPorNDoc": "Sort by N.Doc",
		"sMsgOQueFazercomVariaslinhas": "Click Yes to reconcile the selected lines and show only one summation line on the bank side.",
		"conciliadoLabel": "Reconciled",
		"configImportModalTitle": "Statement Import Template Configuration",
		"configDebitoCreditoNaPerspectivaBancoTitle": "Debit and Credit Configuration from the bank's perspective",
		"comoEObtidoValorMovimentoNoExtrato": "How is the Movement Value obtained in the statement",
		"configImportTooltip": "Configure Import",
		"estaNaPropriaColunaLabel": "It's in the column itself",
		"estaEmColunasSeparadasLabel": "It's in separate columns",
		"estEmUmaColunaENoutraColunaLabel": "It is in one column and the ident is in another column.",
		"removeTemplatePromptTitle": "Confirmation",
		"removeTemplatePromptMsg": "Are you sure you want to delete the template?",
		"templateSavedMsg": "The model was saved successfully",
		"templateRemovedMsg": "The template was successfully removed",
		"outrosanos": "Other years",
		"contaPendentesBanco": "Bank Pending Account",
		"configContaPendentesBanco": "Set Up Bank Pending Account",
		"radicalContaGuardadoComSucesso": "Radical da Conta was successfully saved",
		"fields": {
			"nconta": "Account",
			"periodo": "Period",
			"dataInicio": "Start date",
			"dataFim": "End date",
			"saldoInicialBanco": "Opening balance in the bank",
			"saldoFinalBanco": "Final balance in the bank",
			"saldoInicial": "Opening balance",
			"saldoFinal": "Final balance",
			"saldoFinalCalc": "Final balance calculated",
			"data": "Date",
			"dc": "A.D",
			"valor": "Value",
			"descricao": "Description",
			"conciliado": "Reconciled",
			"tipo": "Type",
			"radicalConta": "Radical Account",
			"contaBanco": "Bank Account",
			"diario": "Daily",
			"descritivo": "Descriptive",
			"tipoMovimento": "Type of movement from the bank's perspective",
			"ndoc": "Doc No.",
			"nDocExterno": "Ext Doc No.",
			"dataLacam": "Launch date",
			"dataDocExt": "Ext. Doc. Date",
			"saldo": "Balance",
			"nSeq": "Seq.",
			"stampUpdate": "Council Hour.",
			"diferencaConciliacao": "Difference from conciliation",
			"valorSemDC": "Unsigned Value",
			"paramImport": "Import parameters",
			"message": "Message",
			"template": "Model",
			"numPrimeiraLinhaDados": "No.",
			"colunaDataMovimento": "Movement Date Column",
			"colunaDataValor": "Date Value Column",
			"colunaDescrição": "Column Description",
			"colunaSaldo": "Balance Column",
			"colunaValorDoMovimento": "Movement Value Column",
			"seValorNegativoInverteSinalELancaA": "If a negative value inverts the sign and launches the",
			"seValorPositivoNaoInverteSinalELancaA": "If a positive value does not invert the sign and launch the",
			"colunaValorADebito": "Debit Amount Column",
			"colunaValorACredito": "Credit Amount Column",
			"inverteSinalDoValorADebito": "Inverts the sign of the Debit Amount",
			"colunaDoTipoDeMovimento": "Movement Type Column",
			"caraterParaDebito": "Debit Character",
			"caraterParaCredito": "Character for Credit",
			"nota": "Note"
		},
		"nota": "Note",
		"config": {
			"showColumnDescricao": "Show \"Description\" column in the accounting statement grid",
			"showColumnNDocExt": "Show \"External Doc No.\" column in the accounting statement grid",
			"showColumnTipo": "Show \"Type\" column in bank statement grid",
			"showColumnSaldo": "Show \"Balance\" column in bank statement grid",
			"showColumnStampUpdate": "Show \"Conciliation Time\" column in the bank statement grid",
			"showColumnDataLanc": "Show \"Launch Date\" column in the accounting statement grid",
			"showColumnData": "Show \"Date\" column in the accounting statement grid",
			"showColumnValorSemDC": "Show \"Value Without Sign\" column in the accounting statement grid",
			"showColumnBancoValorSemDC": "Show \"Amount Without Sign\" column in the bank statement grid",
			"showColumnDC": "Show \"DC\" column in the accounting statement grid",
			"showColumnBancoDC": "Show \"DC\" column in bank statement grid"
		},
		"btns": {
			"toolbarConciliacaoBtn": "Conciliation Actions",
			"selecionados": "Selected...",
			"toolbarShortcuts": "Shortcuts",
			"toolbarBancoPoc": "View bank account plan",
			"toolbarPesqDocs": "Document Search"
		},
		"messages": {
			"deleteSelectedLine": "Do you want to delete the selected line?"
		},
		"tabMainTitle": "Conciliation",
		"tabAttachmentsTitle": "Attachments"
	},
	"concilmovab": {
		"title": "Justification of open movements",
		"conta": "Account",
		"deconta": "account",
		"ate": "Until",
		"table": {
			"periodo": "Period",
			"nDiario": "Daily No.",
			"nDoc": "Document",
			"nDocExterno": "Ext Doc.",
			"descricao": "Description",
			"dataDocExt": "Date Doc.",
			"isDocumentoOrigemRegimeDeIvaDeCaixa": "Cash VAT regime",
			"valor": "Value",
			"porPagar": "Unpaid",
			"dc": "A.D",
			"aImputar": "To Justify",
			"dataVenc": "Maturity",
			"moeda": "Coin",
			"valorActualAImputar": "Current value account",
			"montanteAReter": "Amount to withhold"
		},
		"totals": {
			"valajustificar": "Values ​​to justify",
			"totconta": "Account totals"
		},
		"messages": {
			"success": "Recorded slaughter!",
			"saldomovinvalido": "The balance of movements must be 0 to carry out this operation!",
			"jobTimeoutModalTitle": "Automatically justify values ​​in series",
			"jobTimeoutModalMessage": "The task has timed out.",
			"aprepararjustvalautoserie": "Preparing the value justification process automatically in series...",
			"aefetuarjustvalautoserie": "Processing value justifications automatically in series...",
			"successjustvalautoserie": "Automatically serial value justifications processed successfully!",
			"valjustautosuccess": "Values ​​automatically justified!",
			"savjustautosuccess": "Automatically justified movements saved successfully!",
			"desejaefetuarjustmovi": "Do you want to justify open movements for the values ​​entered?",
			"valimputnaopodsermaior": "The amount to be imputed cannot be higher than the amount to be paid!"
		},
		"modal": {
			"justemseriecontas": "Automatically justify values ​​in series",
			"limiteperiodomaximo": "Limits the maximum period."
		},
		"btn": {
			"justDropdownTitle": "Justify values",
			"justvalauto": "Automatically justify values",
			"justvalautoserie": "Automatically justify values ​​in series",
			"justificarvalor": "Justify value",
			"justserie": "Justify in series"
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmation",
		"leavePromptMessage": "Clicking YES will exit and discard all processing data.",
		"searchlimiteperiodomaximo": "Limits the maximum search period."
	},
	"condicoescomerciais": {
		"title_detail": "Commercial Condition {{id}}",
		"title_new": "New Commercial Condition",
		"title_plural": "Commercial Conditions",
		"pesquisa": "search",
		"saved": "Commercial Condition {{id}}, saved successfully.",
		"error": "Commercial Condition {{id}}, cannot be saved.",
		"deleted": "Commercial Condition {{id}}, successfully deleted.",
		"fields": {
			"codCondComerciais": "Code",
			"codCondComerciaisPlaceholder": "commercial condition code",
			"descricao": "Description",
			"descricaoPlaceholder": "description",
			"nomeCondPagamento": "Payment terms",
			"nomeCondPagamentoPlaceholder": "payment terms",
			"numDiasDataVenc": "N. Dias",
			"numDiasDataVencPlaceholder": "number of days",
			"tipoResumoMensal": "Type Monthly Summary",
			"tipoResumoMensalPlaceholder": "monthly summary type",
			"origemCondPagamento": "Payment Condition, originates from the Customer",
			"origemDataVencimento": "Number of Days and Type of Monthly Summary, originates from the Payment Condition"
		}
	},
	"condicoespagamento": {
		"title_detail": "Payment Terms {{id}}",
		"title_new": "New Payment Condition",
		"title_plural": "Payment conditions",
		"pesquisa": "search",
		"saved": "Payment Condition {{id}}, saved successfully.",
		"error": "Payment Condition {{id}}, cannot be saved.",
		"deleted": "Payment Condition {{id}}, successfully deleted.",
		"fields": {
			"nCondPag": "Code.",
			"nCondPagPlaceholder": "payment condition code",
			"descricao": "Description",
			"descricaoPlaceholder": "description",
			"nDiasPP": "Number of Days PP",
			"nDiasPPPlaceholder": "number of PP days",
			"percDesconto": "Perc.",
			"percDescontoPlaceholder": "discount percentage",
			"numDiasDataVenc": "Number of Days (Expiration)",
			"numDiasDataVencholder": "number of days due",
			"tipoResumoMensal": "Monthly Summary Type",
			"tipoResumoMensalPlaceholder": "monthly summary type"
		}
	},
	"configEfaturaDocPorLanc": {
		"success": "The settings have been saved successfully",
		"fields": {
			"fatura": "Invoice",
			"faturaRecibo": "Invoice/Receipt",
			"notaDebito": "Debit note",
			"notaCredito": "Credit note",
			"reciboVerde": "Electronic Green Receipt",
			"faturaSimplificada": "Simplified Invoice",
			"docConferencia": "Conference Doc.",
			"faturaEstrangeiro": "Invoice Issued Foreign"
		},
		"text": {
			"mesmoPredefinido": {
				"title": "Possible configuration error",
				"message": "The default for <strong>credit notes</strong> must not be the same as for other document types."
			},
			"maintenancePreDefinidos": "Maintenance of pre-defined accounting"
		},
		"global": {
			"title": "Document configuration for posting E-Invoice",
			"fields": {
				"autoFixATDocWithDiffOneCent": "Automatically corrects documents with a difference of 1 cent",
				"criterioOrdenacao": "Grid Sort",
				"mostraDocsFornIgnorados": "Shows documents from suppliers ignored in reconciliation",
				"posicionaAutoNextDoc": "Automatically positions in the next document after posting document"
			},
			"criterioOrdenacao": {
				"dataNome": "Invoice Date |",
				"nomeData": "Supplier Name |"
			}
		},
		"supplier": {
			"title": "Vendor-specific settings",
			"fields": {
				"contaCorrente": "Current account to use"
			}
		}
	},
	"configsERPCheck": {
		"state": {
			"load": "Loading validations",
			"genDefaultData": "Validating and generating default data",
			"validate": "Validations",
			"validating": "To be validated",
			"repair": "To be repaired",
			"finished": "Validation finished"
		},
		"text": {
			"correction": "Correction"
		},
		"actions": {
			"validate": "Validate",
			"validateAll": "Validate all",
			"repair": "To repair",
			"repairAll": "Repair everything"
		},
		"validateAllModal": {
			"title": "Generate data by company default",
			"message": "I want to generate company data by default (types of documents, families, VAT, types of articles, etc.)"
		}
	},
	"configsErp": {
		"title_detail": "Value {{id}}",
		"title_plural": "ERP Settings",
		"pesquisa": "To look for",
		"fields": {
			"name": "Name",
			"description": "Description",
			"value": "Value",
			"readOnly": "Read only"
		},
		"items": {
			"updated": "Updated value",
			"clientesFornecedoresTitle": "Customers/Suppliers",
			"clientesFornecedores": {
				"contaIdentificacaoAlternativaTitle": "Alternative Customer Identification Account",
				"contaIdentificacaoAlternativaFornecedoresTitle": "Alternative Supplier Identification Account"
			},
			"contabilidadeTitle": "Accounting",
			"contabilidade": {
				"recibosTitle": "Receipts",
				"recibos": {
					"aplicaPercDescontoSobreValorIVATitle": "Apply % discount on the value with VAT",
					"reportImpressaoTitle": "Print report",
					"diarioLancamentosTitle": "N. Diary",
					"descritivoLancamentosTitle": "N. Descriptive",
					"contaPocDescontProntoPagamentoTitle": "POC Discount P.P. account number.",
					"meioPagamentoOmissaoTitle": "Default payment method"
				},
				"pagamentosTitle": "Payments",
				"pagamentos": {
					"reportImpressaoTitle": "Print report",
					"diarioLancamentosTitle": "N. Diary",
					"descritivoLancamentosTitle": "N. Descriptive",
					"contaPocDescontProntoPagamentoTitle": "POC Discount P.P. account number.",
					"meioPagamentoOmissaoTitle": "Default payment method"
				},
				"decimaisTitle": "Decimals",
				"decimais": {
					"valorTitle": "Decimal numbers for value",
					"descontoTitle": "Decimal numbers for discount",
					"percDescontoTitle": "Decimal numbers for discount percentage"
				},
				"balancetesTitle": "Balance Sheets",
				"balancetes": {
					"reportImpressaoTitle": "Print report"
				},
				"extratosTitle": "Extracts",
				"extratos": {
					"reportImpressaoTitle": "Print report"
				},
				"movimentosemabertoTitle": "Open moves",
				"movimentosemaberto": {
					"reportImpressaoTitle": "Print report"
				},
				"apuraivaTitle": "VAT clearance",
				"documentosTitle": "Documents",
				"reparticaoccTitle": "Breakdowns for Cost Center",
				"reparticaocc": {
					"usadataTitle": "Use breakdowns by date"
				},
				"listagemdiariosTitle": "List of diaries"
			},
			"documentosComerciaisTitle": "Commercial documents",
			"documentosComerciais": {
				"tiposTitle": "Types of documents",
				"tipos": {
					"outrosTitle": "Others",
					"outros": {
						"documentoPorDefeitoTitle": "Default document",
						"visivelTitle": "Visible"
					},
					"comprasEfectivasTitle": "Effective Purchases",
					"comprasEfectivas": {
						"documentoPorDefeitoTitle": "Default document"
					},
					"consultasaFornecedoresTitle": "Supplier Inquiries",
					"consultasaFornecedores": {
						"documentoPorDefeitoTitle": "Default document"
					},
					"encomendasClientesTitle": "Customer Orders",
					"encomendasClientes": {
						"documentoPorDefeitoTitle": "Default document"
					},
					"encomendasFornecedoresTitle": "Orders Suppliers",
					"encomendasFornecedores": {
						"documentoPorDefeitoTitle": "Default document"
					},
					"folhasdeObraTitle": "Work Sheets",
					"folhasdeObra": {
						"documentoPorDefeitoTitle": "Default document"
					},
					"guiasTransporteRemessaTitle": "Transport Shipping Guides",
					"guiasTransporteRemessa": {
						"documentoPorDefeitoTitle": "Default document"
					},
					"propostasaClientesTitle": "Proposals to Customers",
					"propostasaClientes": {
						"documentoPorDefeitoTitle": "Default document"
					},
					"reservasClientesTitle": "Customer Reservations",
					"reservasClientes": {
						"documentoPorDefeitoTitle": "Default document"
					},
					"trfArmazensEntradasTitle": "Trf.",
					"trfArmazensEntradas": {
						"documentoPorDefeitoTitle": "Default document"
					},
					"trfArmazensSaidasTitle": "Trf.",
					"trfArmazensSaidas": {
						"documentoPorDefeitoTitle": "Default document"
					},
					"vendasEfectivasTitle": "Effective Sales",
					"vendasEfectivas": {
						"documentoPorDefeitoTitle": "Default document"
					},
					"consignacoesClientesTitle": "Customer Consignments",
					"consignacoesClientes": {
						"documentoPorDefeitoTitle": "Default document"
					},
					"consignacoesFornecedoresTitle": "Consignments Suppliers",
					"consignacoesFornecedores": {
						"documentoPorDefeitoTitle": "Default document"
					},
					"recibosClientesTitle": "Customer Receipts",
					"recibosClientes": {
						"documentoPorDefeitoTitle": "Default document"
					},
					"pagamentosFornecedoresTitle": "Supplier Payments",
					"pagamentosFornecedores": {
						"documentoPorDefeitoTitle": "Default document"
					},
					"noneTitle": "No Info",
					"none": {
						"documentoPorDefeitoTitle": "Default document"
					},
					"planoProducaoTitle": "Production Plan",
					"planoProducao": {
						"documentoPorDefeitoTitle": "Default document"
					},
					"preEncomendasClientesTitle": "Pre-Orders Customers",
					"preEncomendasClientes": {
						"documentoPorDefeitoTitle": "Default document"
					},
					"custosAdicionaisTitle": "Additional Costs",
					"custosAdicionais": {
						"documentoPorDefeitoTitle": "Default document"
					},
					"notaCreditoTitle": "Credit note",
					"notaCredito": {
						"documentoPorDefeitoTitle": "Default document"
					},
					"guiasTransporteFornecedoresTitle": "Transport Guides Suppliers",
					"guiasTransporteFornecedores": {
						"documentoPorDefeitoTitle": "Default document"
					}
				}
			},
			"validacaoDasConfiguracoesTitle": "Validation of settings",
			"validacaoDasConfiguracoes": {
				"state": {
					"Load": "Loading validations",
					"GenDefaultData": "Validating and generating default data",
					"Validate": "Validations",
					"Validating": "To be validated",
					"Repair": "To be repaired",
					"Finished": "Validation finished"
				},
				"errors": {
					"GenDefaultDataError": "Error generating default data"
				}
			},
			"empresaTitle": "Company",
			"empresa": {
				"nomeTitle": "Company Name",
				"islockedTitle": "Company blocked",
				"motivoislockedTitle": "Reason Company Blocked",
				"verificacaoEfetuadaTitle": "Verified configuration",
				"verificacaoNaoEfetuadaError": "This company's settings have not yet been verified.",
				"periodoIvaTrimestralTitle": "Quarterly VAT period"
			},
			"licencaTitle": "License",
			"licenca": {
				"licIdTitle": "CentralGest License Number",
				"nomeTitle": "License Name",
				"descricaoTitle": "License Description",
				"nempresasTitle": "Maximum number of companies",
				"nutilizadoresTitle": "Maximum number of users",
				"portalcolaboradorTitle": "Collaborative portal"
			},
			"erpTitle": "CentralGest",
			"erp": {
				"versaoTitle": "CentralGest Cloud version",
				"dataversaoTitle": "CentralGest Cloud version date",
				"emManutencaoTitle": "ERP in maintenance"
			},
			"rhTitle": "HR",
			"rh": {
				"refeicoesTitle": "Meals",
				"refeicoes": {
					"numMaxTitle": "Maximum number"
				}
			},
			"pcaTitle": "My Accounting"
		},
		"check": {
			"disabledValidate": "The repair process is ongoing, please wait.",
			"disabledRepair": "The validation process is ongoing, please wait."
		},
		"groups": {
			"comercial": {
				"requiredDefaultDoc": "It is mandatory to have a default document selected"
			},
			"pca": {
				"optionsPeriodo": {
					"todos": "All periods",
					"atePeriodoAtivoEmpresa": "Until the company's active period",
					"ateUltimoPeriodoEncerrado": "Until the last period closed"
				}
			}
		}
	},
	"configsSite": {
		"title_detail": "Value {{id}}",
		"title_plural": "Email settings",
		"pesquisa": "To look for",
		"fields": {
			"configId": "Name",
			"value": "Value"
		},
		"saved": "Recorded configuration.",
		"error": "Email settings cannot be saved.",
		"testMail": "Test mail",
		"configs": {
			"database.version": "Database version",
			"mail.smtp.adminemail": "Administrator email",
			"mail.smtp.siteemail": "Email website",
			"mail.smtp.smtppassword": "SMTP password",
			"mail.smtp.smtpport": "SMTP port",
			"mail.smtp.smtpserver": "SMTP server",
			"mail.smtp.smtpuser": "SMTP user",
			"mail.smtp.smtpusessl": "SMTP uses ssl",
			"termsRGPDVersion": "Version of GDPR terms"
		}
	},
	"contabAdvancedOperations": {
		"fields": {
			"dePeriodo": "Of period",
			"atePeriodo": "Until",
			"deNDiario": "From diary",
			"ateNDiario": "Until",
			"deNDoc": "From Document No.",
			"ateNDoc": "Until",
			"periodoDestino": "Target period",
			"diarioDestino": "Target journal",
			"listaDocsNaoPermitidos": "Documents that cannot be changed",
			"listaDocsPermitidos": "Documents that can be changed"
		},
		"list": {
			"fields": {
				"nPeriodo": "Period",
				"nDiario": "Daily",
				"nDoc": "Document No.",
				"tipoErroNaoPermitido": "Reason",
				"seleccionado": "Selection",
				"processado": "Processed",
				"tipoAviso": "Notice"
			}
		},
		"messages": {
			"warning": "When carrying out this operation, all selected documents will be canceled and copies of them will be created in the period {{period}}.",
			"warningAnular": "When carrying out this operation, all selected documents will be cancelled.",
			"temQueSeleccionar": "You must select at least one document",
			"success": "Operation carried out successfully"
		},
		"fieldsAnulado": {
			"listaDocsNaoPermitidos": "Documents that cannot be canceled",
			"listaDocsPermitidos": "Documents that can be changed"
		}
	},
	"continentes": {
		"pesquisa": "Continents",
		"fields": {
			"codContinente": "Mainland Code",
			"nomeContinente": "Continent Name"
		},
		"data": {
			"portugal": "Continent",
			"acores": "Azores Region",
			"madeira": "Madeira Region",
			"estrangeiro": "Foreigner",
			"outros": "Others"
		}
	},
	"contratos": {
		"title_detail": "Contract {{id}}",
		"title_new": "New Contract",
		"title_plural": "Contracts",
		"pesquisa": "To look for",
		"saved": "Contract {{id}}, saved successfully",
		"error": "Contract {{id}}, cannot be saved.",
		"deleted": "Contract {{id}}, successfully deleted",
		"fields": {
			"cab": {
				"refCtrCab": "Contract Code",
				"nClasseCtr": "Class",
				"nClasseCtrPlaceholder": "Contract Class Code",
				"nTipoContrato": "Type",
				"nTipoContratoPlaceholder": "Type of contract",
				"contrato": "No.",
				"nContratoPlaceholder": "Contract Number",
				"verContrato": "Version",
				"verContratoPlaceHolder": "Contract Version",
				"nContratoAsString": "Contract",
				"nContratoSemVersaoAsString": "Contract",
				"descricao": "Description",
				"descricaoPlaceHolder": "Contract Description",
				"nClifo": "Customer supplier",
				"nClifoPlaceHolder": "Customer supplier",
				"clifoNome": "Customer Name",
				"idIdeChav": "Account Group",
				"idIdeChavPlaceHolder": "Account Group",
				"dataCtr": "Contract Date",
				"dataCtrPlaceHolder": "Contract Date",
				"dataInicio": "Start Date",
				"dataInicioPlaceHolder": "Start Date",
				"dataFim": "End Date",
				"dataFimPlaceHolder": "End Date",
				"estado": "state",
				"estadoPlaceHolder": "State Code",
				"ccusto": "Cost center",
				"ccustoPlaceHolder": "Cost Center Code",
				"nRefProcesso": "Process",
				"nRefProcessoPlaceHolder": "Process Code",
				"codMoeda": "Coin",
				"codMoedaPlaceHolder": "Currency Code",
				"refExterna": "External Reference",
				"terminado": "Finished",
				"totalLiquido": "Value",
				"valorGlobal": "Overall Value",
				"codCondComerciais": "Commercial Condition",
				"codCondComerciaisPlaceHolder": "Commercial Condition Code"
			},
			"linhas": {
				"nArtigo": "Article Code",
				"nomeArtigo": "Description",
				"qtd1": "Qty",
				"prVenda": "Pr. Unit.",
				"d1": "D1",
				"d2": "D2",
				"d3": "D3",
				"desconto": "Discount",
				"d4": "D4",
				"d5": "D5",
				"d6": "D6",
				"codIva": "VAT Code",
				"nArmazem": "Storage",
				"cunimo": "Items.",
				"qtdPUnid": "Qty./Unit.",
				"nVendedor": "Seller",
				"ccusto": "Cost center",
				"nZona": "Zone",
				"nDepto": "Department",
				"nSubde": "Sub-department",
				"qtd1Fact": "Invoiced Qty",
				"nRefProcesso": "Process",
				"estado": "state",
				"obs": "Comments",
				"valorLiquido": "Liquid",
				"taxaIva": "VAT rate"
			}
		},
		"detail": {
			"cab": {
				"dataCtr": "Contract Date:",
				"nClifo": "Customer supplier:",
				"dataFim": "End Date:",
				"dataInicio": "Start date:",
				"nContribuinte": "VAT Number:",
				"codPostal": "Postal Code:"
			}
		},
		"tabs": {
			"linhas": "Lines"
		},
		"config": {
			"verGrupoConta": "Show Account Group",
			"verMoeda": "Show Currency",
			"verRefExterna": "Show External Reference",
			"verCondComerciais": "Show Commercial Conditions",
			"verVendedor": "Show Seller",
			"verNCCusto": "Show cost center",
			"verD1": "Show D1",
			"verArmazem": "Show Warehouse",
			"verUnidadeMovimento": "Show Moving unit"
		},
		"totais": {
			"resumo": "Summary",
			"total": "Total"
		},
		"print": {
			"config": {
				"showClasses": "Show class filter"
			},
			"filters": {
				"listagem": "Listing",
				"declifo": "Customer of",
				"ateclifo": "Until",
				"dencontrato": "contract number",
				"atencontrato": "Until",
				"classes": "Classes",
				"tipos": "Types of contract",
				"dtdeini": "Start date of",
				"dtateini": "Until",
				"dtdefim": "End date of",
				"dtatefim": "Until",
				"dtdedatactr": "Contract date",
				"dtatedatactr": "Until"
			}
		},
		"errors": {
			"artigoBloqueadoDesativado": "The article {{nArtigo}} is blocked or disabled!",
			"notSelected": "There is no contract selected",
			"terminado": "The contract {{contract}} has already ended",
			"notTerminado": "The contract {{contract}} is not terminated",
			"reabrir": "Reopening canceled",
			"terminar": "Finish action canceled",
			"apagar": "Erase action canceled"
		},
		"btn": {
			"actions": "Actions",
			"editar": "To edit",
			"reabrir": "Reopen",
			"terminar": "finish",
			"apagar": "To switch off"
		},
		"info": {
			"terminado": "Finished",
			"reabrir": "Reopen Contract",
			"terminar": "Terminate Contract",
			"apagar": "Delete Contract"
		},
		"list": {
			"config": {
				"showNaoAtivosOnly": "See only Ended and Expired"
			}
		}
	},
	"contratostiposervicos": {
		"title_detail": "Covenant Type {{id}}",
		"title_new": "New Type of Agreement",
		"title_plural": "Types of Covenants",
		"pesquisa": "To look for",
		"saved": "Covenant Type {{id}}, saved successfully",
		"error": "Covenant Type {{id}}, cannot be saved.",
		"deleted": "Covenant Type {{id}}, successfully deleted",
		"fields": {
			"id": "Class Contract Type Code",
			"nClasseCtr": "Code",
			"nClasseCtrPlaceholder": "Covenant Class Code",
			"classeTipo": "Class Type",
			"classeTipoPlaceholder": "Covenant Type Class",
			"nTipoContrato": "Covenant Type Number",
			"nTipoContratoPlaceholder": "Type of Covenant, if not filled in, it will be generated automatically.",
			"nome": "Name",
			"nomePlaceholder": "Name Type of Agreement",
			"periocidade": "Frequency",
			"periocidadePlaceholder": "Frequency of the Agreement",
			"nDocfa": "Document Type",
			"nDocfaPlaceholder": "Document Type",
			"nNumer": "Numbering",
			"nNumerPlaceholder": "Document Type Numbering",
			"nomeClasse": "Class",
			"nomeClassePlaceholder": "Avença Class",
			"processaDiferimento": "Process deferral",
			"faturacaomes": "Monthly billing",
			"docComercialAssinar": "To sign",
			"docComercialEncerrar": "Sign/Close",
			"txtContrato": "Comments",
			"variables": "Variables",
			"proceAuto": "Automatic processing",
			"procAutoTipo": "Scheduling",
			"procAutoDiaFixo": "Fixed day",
			"procAutoEnviaMailTitle": "Processing will run during the specified day",
			"procAutoEnviaMail": "Automatically send e-mail to the customer",
			"proAutoEmailResponsavel": "Responsible email"
		},
		"showFaturacaoMes": "Shows billing for the month",
		"actions": {
			"nnumer": "Automatically generate a new number",
			"decrementafaturacaomes": "Decrease the billing month",
			"incrementafaturacaomes": "Increase the billing month"
		},
		"infoFaturacaoMes": {
			"info": "Billing and service provision refer to the same month.",
			"infoPositiveValue": "Example: billing in August relates to a service already provided in the month of {{month}}.",
			"infoNegativeValue": "Example: billing in August concerns a service to be provided in the next month of {{month}}."
		},
		"messages": {
			"emailNotification": "Sending notification with the Processing Report",
			"txtContrato": "Visible notes on the invoice"
		},
		"datasource": {
			"none": "Inactive",
			"primeiroDiaUtil": "1st business day",
			"ultimoDiaUtil": "Last business day",
			"primeiroDiaDoPeriodo": "1st day of the period",
			"ultimoDiaPeriodo": "Last day of the period",
			"diaFixo": "Fixed day"
		}
	},
	"contratostipos": {
		"title_detail": "Contract Type {{id}}",
		"title_new": "New Type of Contract",
		"title_plural": "Types of Contracts",
		"pesquisa": "To look for",
		"saved": "Contract Type {{id}}, saved successfully",
		"error": "Contract Type {{id}}, cannot be saved.",
		"deleted": "Contract Type {{id}}, successfully deleted",
		"fields": {
			"id": "Class Contract Type Code",
			"nClasseCtr": "Code",
			"nClasseCtrPlaceholder": "Contract Class Code",
			"classeTipo": "Class Type",
			"classeTipoPlaceholder": "Contract Type Class",
			"nTipoContrato": "Type of contract",
			"nTipoContratoPlaceholder": "Type of contract",
			"nome": "Name",
			"nomePlaceholder": "Name Type of Contract",
			"periocidade": "Periodicity",
			"periocidadePlaceholder": "Contract Type Periodicity",
			"nDocfa": "Document Type",
			"nDocfaPlaceholder": "Document Type",
			"nNumer": "Numbering",
			"nNumerPlaceholder": "Document Type Numbering",
			"nomeClasse": "Class",
			"nomeClassePlaceholder": "Contract Type Class"
		}
	},
	"controloIVA": {
		"title_detail": "VAT control {{id}}",
		"title_new": "New code.",
		"title_plural": "VAT control",
		"pesquisa": "To look for",
		"saved": "VAT control code {{id}}, saved successfully.",
		"error": "VAT control code {{id}}, cannot be saved.",
		"deleted": "VAT control code {{id}}, successfully deleted.",
		"fields": {
			"codControloIVA": "VAT Control Code",
			"descAbreviada": "Description",
			"descCompleta": "Full description",
			"tipoBem": "Type of Good",
			"tipoTaxa": "Rate Type",
			"tipoOperacao": "Operation type",
			"tipoLiquidacao": "Type of Settlement",
			"tipoTransmissaoAct": "Active Transmission Type",
			"tipoTransmissaoPas": "Transm. Type",
			"mercado": "Market",
			"areaRegional": "Regional Area",
			"inversaoSujPassivo": "Dirt Inversion",
			"tipoDeducao": "Deduction Type",
			"decPERBASETRIBUT": "Periodic Dec. Code",
			"decANUBASETRIBUT": "Annual Dec. Code",
			"codIVA": "VAT code",
			"nContaIVADEDLIQREG": "Net VAT Account.",
			"nContaVTNAODEDUT": "Non-deductible Vt Account",
			"nContaIVANAODEDUT": "Non-deductible VAT account",
			"nContaVTNDEDPROR": "Vt Account Not Prorated",
			"nContaIVANDEDPROR": "Non-Ded VAT Account.",
			"nContaVTLIQUIDRCH": "Account 99 Val.Trib.",
			"nContaIVALIQUIDRCH": "Account 24 Net VAT.",
			"decPERIVADEDLIQREG": "",
			"decPERVTNAODEDUT": "",
			"decPERIVANAODEDUT": "",
			"decPERVTNDEDPROR": "",
			"decPERIVANDEDPROR": "",
			"decPERVTLIQUIDRCH": "Code Dec Per.",
			"decPERIVALIQUIDRCH": "",
			"decANUIVADEDLIQREG": "",
			"decANUVTNAODEDUT": "",
			"decANUIVANAODEDUT": "",
			"decANUVTNDEDPROR": "",
			"decANUIVANDEDPROR": "",
			"decANUVTLIQUIDRCH": "",
			"decANUIVALIQUIDRCH": "",
			"tipoValidacaoNIF": "NIF Validation Type",
			"usaVTNAODEDUTOrigem": "Use origin account",
			"usaIVANAODEDUTOrigem": "Use origin account",
			"usaVTNDEDPROROrigem": "Use origin account",
			"usaIVANDEDPROROrigem": "Use origin account",
			"isRegimeIvaCaixa": "Cash VAT Regime",
			"regularizacoesC40": "Field 40 Regularization Type",
			"regularizacoesC41": "Field 41 Regularization Type",
			"regularizacoesC40RCH": "Field 40 Regularization Type",
			"regularizacoesC41RCH": "Field 41 Regularization Type",
			"codIVANome": "VAT code",
			"decPERBASETRIBUTNome": "Periodic Dec. Code",
			"decANUBASETRIBUTNome": "Annual Dec. Code",
			"nCONTAIVADEDLIQREGNome": "Net VAT Account.",
			"nCONTAVTNAODEDUTNome": "Non-deductible Vt Account",
			"nCONTAIVANAODEDUTNome": "Non-deductible VAT account",
			"nCONTAVTNDEDPRORNome": "Vt Account Not Prorated",
			"nCONTAIVANDEDPRORNome": "Non-Ded VAT Account.",
			"nCONTAVTLIQUIDRCHNome": "Account 99 Val.Trib.",
			"nCONTAIVALIQUIDRCHNome": "Account 24 Net VAT.",
			"tipoRegularizacaoCampo40": "Field 40 Regularization Type",
			"tipoRegularizacaoCampo41": "Field 41 Regularization Type",
			"nContaIvaCativo": "Captive VAT Account",
			"tipoRegularizacao": "Type of Regularization",
			"declaracaoPerio": "The periodic declaration field: {{info}}"
		},
		"steps": {
			"operacaoIVA": "Type of VAT transaction",
			"camposIVA": "VAT Fields and Accounts Related to the Tax Base"
		},
		"helper": {
			"taxaAtual": "Current Rate",
			"percDedGasoleo": "Perc.",
			"percDedProRata": "Perc.",
			"decPeriodicaIva": "Dec. Periodic VAT",
			"decAnualIva": "Annual VAT Dec."
		},
		"btn": {
			"controloIVAEntreEmpresas": "Copy VAT control for companies"
		}
	},
	"controlosIvaEntreEmpresas": {
		"navwizard": {
			"controlosIva": "VAT controls",
			"empresas": "Companies",
			"erros": "Errors/Warnings",
			"final": "Final"
		},
		"grid": {
			"fields": {
				"nEmpresa": "Company Code",
				"nomeEmpresa": "Company name",
				"codControloIVA": "VAT Control Code",
				"nomeControloIVA": "Name VAT Control",
				"erros": "Errors/Warnings",
				"copiar": "Ignore warnings and copy"
			}
		},
		"confirmacaoOkCancel": {
			"title": "Confirmation",
			"message": "Are you sure you want to copy the selected VAT Control codes for companies?"
		},
		"success": "Copy made successfully"
	},
	"conttrabbaselegal": {
		"title_detail": "Legal basis of Employment Contract {{id}}",
		"title_new": "New legal basis for employment contracts",
		"title_plural": "Legal bases of Employment Contracts",
		"pesquisa": "To look for",
		"saved": "Legal basis of Employment Contract {{id}}, saved successfully.",
		"error": "Legal basis of Employment Contract {{id}}, cannot be saved.",
		"deleted": "Legal basis of Employment Contract {{id}}, successfully deleted.",
		"fields": {
			"contTrabBaseLegal": "Legal Base Code",
			"tipoContrato": "Type of contract",
			"descricao": "Description",
			"duracaoMinima": "Number of days of minimum duration",
			"duracaoMaxima": "Number of days of maximum duration (Ind. Renov)",
			"duracaoMaxPrimEmpreg": "Number of days of maximum duration first emp.",
			"dilacaoNovoContrato": "Number of days of extension for new contract",
			"numRenovNormal": "Renewal No.",
			"numRenovExcepcionais": "Renewal No.",
			"passagemSemTermo": "No. Days after Date for Den., becomes effective",
			"contTBLNovaSeq": "New legal code after renewals",
			"periodoExpNormalInd": "Normal",
			"periodoExpTecnicoInd": "Technical indeterminate term",
			"periodoExpSuperioInd": "Upper indeterminate term",
			"periodoExpTermoCerto": "Right term",
			"preAvisoDenunNormal": "Normal",
			"preAvisoDenunTecnico": "Technical indeterminate term",
			"preAvisoDenunSuperio": "Technical indeterminate term",
			"preAvisoDenunTermoC": "Right term",
			"feriasPorMesCompl": "Vacation per full month",
			"feriasMaximo": "Maximum vacation",
			"aviDesvincTrab": "Work termination notice.",
			"aviCaducidadeTrab": "Work expiry notice",
			"aviCaducidadeEmpresa": "Company expiry notice",
			"compCaducidadeEmpre": "Compensation for expiry"
		}
	},
	"conttrabcab": {
		"title_detail": "Employment Contract {{id}}",
		"title_new": "New employment contract",
		"title_plural": "Employment Contracts",
		"pesquisa": "To look for",
		"saved": "Employment Contract {{id}}, saved successfully.",
		"error": "Employment Contract {{id}}, cannot be saved.",
		"deleted": "Employment Contract {{id}}, successfully deleted.",
		"fields": {
			"nContrato": "Contract No.",
			"codEmp": "Employee Code",
			"descricao": "Description",
			"nCodBaseLegalContr": "Legal Base Code",
			"codEstado": "state",
			"vigor": "In effect",
			"dataCelebracaoContr": "Celebration Date",
			"dataInicioTrab": "Work start date",
			"dataCessacao": "Termination Date",
			"actContratada": "Contracted Activity",
			"retribuicao": "Retribution",
			"localTrab": "Workplace",
			"perioNormalTrab": "Normal working period",
			"termo": "Indication of stipulated terms",
			"motivoTermo": "Justifying reason for the term",
			"codContratoMotivo": "Reason Code",
			"observacoes": "Comments"
		}
	},
	"conttrabmotivo": {
		"title_detail": "Reason for Employment Contract {{id}}",
		"title_new": "New reason for employment contract",
		"title_plural": "Reasons for Employment Contract",
		"pesquisa": "To look for",
		"saved": "Reason for Employment Contract {{id}}, saved successfully.",
		"error": "Reason for Employment Contract {{id}}, cannot be saved.",
		"deleted": "Reason for Employment Contract {{id}}, successfully deleted.",
		"fields": {
			"codContTrabMotivo": "Employment contract reason code",
			"descrBreve": "Brief Description",
			"descrCompleta": "Full Description",
			"propriedadeMotivo": "Property"
		}
	},
	"cookies": {
		"consent": {
			"header": "<p>CentralGest Cloud uses cookies to personalize the content of the application.</p><p>Before you begin <strong>we need your consent</strong> to store cookies on your device.</p>",
			"about": {
				"1": "Cookies are small text files that are used by the application to make the user experience more efficient.",
				"2": "The law establishes that we can store cookies on your device if they are strictly necessary for the normal functioning of the application.",
				"3": "CentralGest Cloud uses different types of cookies, some of which are placed by external services that appear on our pages."
			}
		},
		"purpose": {
			"x-auth-token": "Identifies the user and allows them to authenticate them on the server",
			"reCaptcha": "reCAPTCHA protects the application against spam and other types of automated abuse"
		}
	},
	"crabd": {
		"title_detail": "Allowance/Discount Cross {{id}}",
		"title_new": "New Allowance/Discount Crossover",
		"title_plural": "Crossing of Allowances/Discounts",
		"pesquisa": "To look for",
		"deleted": "Crossing successfully deleted.",
		"saved": "Crossing saved successfully.",
		"error": "Crossing cannot be saved.",
		"updated": "Crossover updated successfully.",
		"fields": {
			"tipoAbDescOrigem": "Type",
			"codOrigem": "Allowance/Discount",
			"nCodOrigem": "Code",
			"tipoAbDescDestino": "Allowance/Discount",
			"codDestino": "Allowance/Discount",
			"nCodDestino": "Code",
			"entSuporteEmpregado": "Employee",
			"entSuportePatronato": "Patronage",
			"incidenciaEmpregado": "(%) Incidence",
			"incidenciaPatronato": "(%) Incidence",
			"tipoExcecao": "Exception Type",
			"categoriaAbDescDestino": "Category",
			"excecoes": "Exceptions"
		},
		"tableFields": {
			"crabdList": "List of Crossings",
			"categoria": "Category",
			"cruzamento": "Crossing",
			"empregado": "Employee",
			"patronato": "Patronage",
			"abonoDesconto": "A/D",
			"desconto": "Discount",
			"cod": "Code",
			"nome": "Name",
			"cat": "cat",
			"excecao": "There is an exception"
		},
		"removeCruzamento": "Are you sure you want to remove the intersection?",
		"cruzdelsuccess": "Crossover successfully removed."
	},
	"crabonos": {
		"title_detail": "Crossing of Abono {{id}}",
		"title_new": "New Allowance Crossing",
		"title_plural": "Allowance Crossings",
		"pesquisa": "To look for",
		"deleted": "Crossing successfully deleted.",
		"saved": "Crossing saved successfully.",
		"error": "Crossing cannot be saved."
	},
	"crdescontos": {
		"title_detail": "Discount Crossing {{id}}",
		"title_new": "New Discount Crossover",
		"title_plural": "Discount Crosses",
		"pesquisa": "To look for",
		"deleted": "Crossing successfully deleted.",
		"saved": "Crossing saved successfully.",
		"error": "Crossing cannot be saved."
	},
	"criterioDisponiveis": {
		"pesquisa": "Available criteria",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"weightedAverageCost": "Weighted Average Cost",
			"lifo": "Lifo (Last In First Out)",
			"fifo": "Fifo (First In First Out)",
			"standardCost": "Standard Cost",
			"averageCost": "Average Cost",
			"specificLot": "Specific Lot"
		}
	},
	"crneg": {
		"title_detail": "Employee {{id}} Crossing Exception",
		"title_new": "New Crossing Exception",
		"title_plural": "Crossing Exceptions",
		"pesquisa": "To look for",
		"deleted": "Crossing Exception cleared successfully.",
		"saved": "Crossing exception saved successfully.",
		"error": "Crossing Exception cannot be saved.",
		"fields": {
			"nCodEmp": "Employed Code",
			"nomeEmp": "Employee Name",
			"nCodOrigem": "Source Code",
			"nCodDestino": "Destiny Code",
			"entSuporte": "Support Entity"
		},
		"modalCRNEGTitle": "Indicate the employees to include"
	},
	"crss": {
		"title_detail": "Social Security Scheme {{id}}",
		"title_new": "New social security regime",
		"title_plural": "Social Security",
		"listRegimes": "Social Security Schemes",
		"pesquisa": "To look for",
		"deleted": "Social Security Scheme successfully deleted.",
		"saved": "Social Security Scheme {{id}}, saved successfully.",
		"error": "Social Security Scheme {{id}}, cannot be saved.",
		"nifentidades": "NIF of entities",
		"fields": {
			"ncrss": "CRSS Code",
			"nome": "Name",
			"designaBreve": "Brief Designation:",
			"morada": "Household",
			"localidade": "Location",
			"npostal": "Postal Code",
			"telefone": "Telephone",
			"fax": "Fax",
			"nbenefCaixa": "Identification No.",
			"crssSedeSocial": "Crss of HEADQUARTERS",
			"nifSegSocial": "NIF Social Security",
			"nifCGA": "NIF CGA",
			"nifADSE": "NIF ADSE"
		},
		"table": {
			"nclcx": "Box Class",
			"nomeregime": "Regime Name",
			"percentPatr": "% contribution.",
			"percentEmpr": "% contribution.",
			"codIntegracao": "Integration Code",
			"naoVaiSegSocial": "Send data to SS"
		}
	},
	"dashboards": {
		"erp": {
			"pageTitle": {
				"vendas": "Volume of business",
				"tesourariaLiquida": "Net Treasury",
				"rendimentos": "Income",
				"gastosCompras": "Shopping expenses"
			},
			"vendas": {
				"ano": "{{year}} sales",
				"dia": "Sales of the day",
				"mes": "Sales of the month",
				"vendasAno": "Sales of the year",
				"anoTop": "Top 5 items sold in {{year}}"
			},
			"clientes": {
				"title": "Customers",
				"ano": "New customers from {{year}}",
				"dia": "New customers of the day",
				"mes": "New customers of the month",
				"clientesAno": "New customers of the year",
				"anoTop": "Top 5 biggest clients of {{year}}",
				"legendaClienteAno": "{{year}} customers"
			},
			"table": {
				"nome": "Name",
				"valor": "Value",
				"percentagem": "Percentage",
				"legends": {
					"vendasAno": "Sales per month",
					"tesourariaLiquidaDisponibilidade": "Availability",
					"tesourariaLiquidaFinanciamento": "Financing"
				}
			},
			"compras": {
				"title": "Shopping",
				"ano": "Purchases from {{year}}",
				"dia": "Shopping of the day",
				"mes": "Month shopping",
				"anoTop": "Top 5 items purchased in {{year}}"
			},
			"fornecedores": {
				"title": "Suppliers",
				"ano": "New suppliers from {{year}}",
				"dia": "New suppliers of the day",
				"mes": "New suppliers of the month",
				"anoTop": "Top 5 largest suppliers of {{year}}"
			},
			"comprasVendas": {
				"title": "Sales and Purchasing",
				"titleParam": "Sales and Purchases for {{year}}",
				"legendas": {
					"vendas": "Sales",
					"compras": "Shopping",
					"diference": "Difference"
				}
			}
		},
		"financeiro": {
			"tableContasAOrdem": {
				"title": "Connect to banks in real time",
				"descricao": "Description",
				"nomeBanco": "Bank name",
				"iban": "IBAN",
				"saldoAtual": "Current Balance"
			},
			"tableValoresEmAberto": {
				"title": "Total open amounts",
				"descricao": "Description",
				"valorEmAberto": "Open value",
				"prazoMedio": "Average Term (days)"
			},
			"receberPagarTables": {
				"titleReceber": "Amounts to be received from customers",
				"titlePagar": "Amounts to be paid to suppliers",
				"titleTableReceber": "Top 5 customers with receivables",
				"titleTablePagar": "Top 5 suppliers with amounts to pay",
				"naoVencido": "Not Expired",
				"vencido": "Overdue",
				"total": "Total"
			}
		},
		"rh": {
			"tiles": {
				"funcionariosAtivos": "Active employees",
				"entradas": "Appetizer",
				"saidas": "Outputs",
				"subtitleMeses": "Last {{months}} months",
				"tempoInteiro": "Fulltime",
				"tempoParcial": "Part time",
				"outros": "Others"
			},
			"graficos": {
				"funcionariosPorGenero": "Employees by gender",
				"funcionariosPorDepartamento": "Employees by department",
				"funcionariosPorIdade": "Employees by age",
				"funcionariosPorTipoContrato": "Employees by contract type",
				"custosFuncionarios": {
					"title": "Monthly expenses",
					"vencimentosFaltas": "Salaries - Absences",
					"subRefeicao": "Sub.",
					"impostos": "Taxes",
					"outros": "Others"
				}
			}
		},
		"ativos": {
			"bensAtivos": "Active assets",
			"nFichasAtivos": "Files",
			"nAquisicoesAno": "Acquisitions",
			"nVendasAbatesAno": "Sales and slaughters",
			"valorAquisicao": "Acquisition value",
			"depreciacoesAcumuladas": "Accumulated depreciation",
			"valorAtual": "Current value",
			"pie": {
				"ativosTangiveis": "Tangible fixed assets",
				"ativosInTangiveis": "Intangible assets / Prop.",
				"nFichas": "Number of chips: {{chips}}"
			},
			"table": {
				"topAtivos": "Top 10 - Acquisition value",
				"codAmortizacao": "Dec. code.",
				"nomeAmortizacao": "Description",
				"nFichas": "Number of tokens",
				"valorAq": "Acquisition value",
				"valorDep": "Depreciation value",
				"valorLiq": "Net value",
				"percentDep": "% Depreciated"
			},
			"bar": {
				"title": "Monthly depreciations",
				"depMonth": "Depreciations per month",
				"depAcumulado": "Accumulated depreciation"
			},
			"radical": {
				"rdl431": "Land and natural resources",
				"rdl432": "Buildings and other constructions",
				"rdl433": "Basic equipment",
				"rdl434": "Transport equipment",
				"rdl435": "Office equipment",
				"rdl436": "Biological equipment",
				"rdl437": "Other tangible fixed assets",
				"rdl441": "Goodwill",
				"rdl442": "Development projects",
				"rdl443": "Computer programs",
				"rdl444": "Industrial property",
				"rdl445": "Other intangible assets",
				"rdl446": "Other intangible assets",
				"rdl447": "Other intangible assets",
				"rdl421": "Land and natural resources",
				"rdl422": "Buildings and other constructions",
				"rdl423": "Other investment properties",
				"rdl424": "Other investment properties",
				"rdl425": "Other investment properties",
				"rdl426": "Other investment properties",
				"rdl427": "Other investment properties"
			},
			"messages": {
				"contab": "There are acquisitions made in accounting that are unrelated to assets!",
				"contabLink": "Create form from accounting record",
				"investEmCurso": "There are ongoing investments without a created record.",
				"investEmCursoLink": "Create a form based on ongoing investments"
			}
		}
	},
	"datavalor": {
		"title_detail": "Date Value {{id}}",
		"title_new": "New Value Date",
		"title_plural": "Value Dates",
		"pesquisa": "To look for",
		"saved": "Data Value {{id}}, saved successfully.",
		"error": "Date Value {{id}}, cannot be saved.",
		"deleted": "Date Value {{id}}, deleted successfully",
		"fields": {
			"tipo": "Type",
			"keyValue": "Key Value",
			"valor1": "Tax Limit",
			"valor2": "Accounting Limit",
			"valor3": "Rate",
			"dataInicio": "Start Date"
		}
	},
	"debDiretosSEPA": {
		"buttons": {
			"toolbarEmissao": "Emission",
			"toolbarImportar": "import",
			"clientesAderentes": "Subscribing Customers",
			"notificar": "Notify",
			"imprimir": "Print out",
			"consultaFicheirosSEPA": "SEPA File Consultation"
		},
		"fields": {
			"tipo": "Type",
			"valor": "Value",
			"digitos": "Number of Digits",
			"estado": "state",
			"meioPagamento": "Half payment",
			"iban": "IBAN",
			"idDebitoDireto": "direct debit ID",
			"exampleIdDebito": "Ex: PTPTXXZZZXXXXXXXXXX",
			"nConta": "Account no.",
			"contaBanco": "Bank account",
			"nDiario": "Daily number",
			"diario": "Daily",
			"nDescritivo": "Descriptive no.",
			"descritivo": "Descriptive",
			"nome": "Name",
			"emailDD": "Email Direct Debits",
			"nAutorizacao": "Authorization number",
			"inicio": "Start",
			"fim": "End",
			"contrato": "Contract",
			"firstEnviado": "FRST Sent",
			"codMeioPagamento": "Code.",
			"dataInicio": "Start date",
			"contaIntermedia": "Intermediate account",
			"calendario": "Calendar",
			"docFaturacao": "Billing Doc",
			"dataDoc": "Date Doc.",
			"docContabilidade": "Accounting Doc",
			"docContabilidadeLiquidacao": "Settlement Accounting Doc.",
			"valorDD": "Value Deb.",
			"erroLiquidacao": "Settlement Error",
			"dataHora": "Date/Time",
			"utilizador": "User",
			"operacao": "Operation",
			"observacao": "Observation",
			"nDocFa": "Type Doc.",
			"nNumer": "Series No.",
			"nDocumento": "Document No.",
			"documento": "Document",
			"valorEmAberto": "Open value",
			"enviadoEmail": "Email notification",
			"xmlGerado": "Generated XML",
			"titularIBAN": "IBAN holder",
			"tipoFicheiro": "File Type",
			"erro": "Error",
			"aviso": "Notice",
			"nLoteFicheiro": "File Batch",
			"nLotePagamento": "Payment Batch",
			"firstAsSTR": "Seq.",
			"nRegistos": "No. of Records",
			"fileName": "File",
			"campo": "Field",
			"mensagem": "Message",
			"notificado": "Notified",
			"liquidacao": "Sale off",
			"devolucao": "Devolution",
			"comErro": "With error",
			"valorTotal": "Amount",
			"nErroCG": "No. CG Errors",
			"importValorImputado": "Imputed value",
			"valorLiquidacao": "Settlement Value",
			"recibo": "Receipt",
			"autorizacao": "Authorization",
			"dataLiquidacao": "Settlement date",
			"valorDoc": "Doc Value"
		},
		"strings": {
			"tabConfigGerais": "Config.",
			"tabContasCredoras": "Creditor Accounts",
			"tabTiposDocumento": "Document Types",
			"tabClientes": "Customers",
			"naoTemAcessoEstaConfiguracao": "You do not have access to this configuration.",
			"dataVencimento": "Due date",
			"nDiasPrimeiraComunicacao": "Number of days 1st communication",
			"seguintes": "Next",
			"dataLiquidacao": "Settlement date",
			"chaveNumeroAutorizacao": "Authorization Number Key",
			"exemploChave": "Key Example:",
			"modalTitleConfiguracao": "SEPA Direct Debits - Setup",
			"semDebitosDiretos": "No direct debits",
			"comDebitosDiretos": "With direct debits",
			"modalTitleContaCredora": "SEPA Direct Debits - Creditor Account",
			"modalTitleConfigClientes": "SEPA Direct Debits - Subscribing Customers",
			"integracaoEmContabilidade": "Accounting integration",
			"oFormatoIDDebitoDiretoErrado": "The format of the \"Direct Debit ID\" does not follow the example.",
			"haCamposNaoPreenchidos": "There are unfilled fields that may cause errors in the future.",
			"titularConta": "Owner of the account",
			"nomeFichaCliente": "Customer record name",
			"outroNome": "Another name",
			"ibanCredor": "IBAN (Creditor)",
			"contratos": "Contracts",
			"contrato": "Contract",
			"autorizacao": "Authorization",
			"consultar": "Consult",
			"temSelecionarConta": "You have to select the account",
			"jaFoiEnviadaPrimeiraComunicacaoFRST": "The first communication has already been sent (FRST)",
			"emailDebitosDiretosObrigatorio": "Email for Direct Debits is mandatory.",
			"ibanClienteObrigatorio": "The Customer's IBAN is mandatory.",
			"nContaObrigatorio": "The Account Number is mandatory.",
			"nAutorizacaoDDObrigatorioGerarAutomaticamente": "The Direct Debit Authorization Number is mandatory.",
			"dataInicioNaoPodeSerSuperiorDataFim": "The Start Date cannot be greater than the End Date",
			"naoAnexouAutorizacaoPertendeContinuar": "Did not attach the Direct Debit authorization.",
			"manual": "Manual",
			"auto": "Self",
			"descricao": "Description",
			"documento": "Document",
			"documentoAutorizacao": "Authorization Document",
			"dataParaLiquidacaoSeraADataVencDocumento": "The settlement date will be the due date of the document.",
			"dataParaLiquidacaoSeraCalcPelosDiasConfig": "The settlement date will be calculated according to the configured days.",
			"dataParaLiquidacaoSeraCalcPelosDiasConfig2": "Attention: The days indicated are recommended by banking institutions.",
			"contaIntermediaParaLiquidacao": "Intermediate account for settlement",
			"configSaved": "The SEPA Direct Debit settings have been saved successfully.",
			"configClientesSaved": "Client settings have been saved successfully.",
			"configClienteDeleted": "The client has been removed successfully.",
			"codMeioPagamentoExisteNaLista1": "The selected half-payment code already exists in the configuration.",
			"codMeioPagamentoExisteNaLista2": "If you save, it will update the existing record.",
			"ficheirosEmitidos": "Issued Files",
			"porLiquidar": "Unsettled",
			"liquidado": "Settled",
			"emitido": "Issued",
			"estado": "state",
			"valor": "Value",
			"disponivelParaNovaEmissao": "Available for new issue",
			"documentoFaturacao": "Document (Invoicing)",
			"reciboLiquidacao": "Receipt (Settlement)",
			"cliente": "Client",
			"historico": "Historic",
			"logModalTitle": "Document Log {{ndoc}}",
			"anexos": "Attachments",
			"comentarios": "Comments",
			"ddsepaEmissaoPorContaCorrente": "SEPA Direct Debits - Issuance via current account",
			"gerarFicheiro": "Generate file",
			"gerar": "To generate",
			"resumo": "Summary",
			"notificacao": "Notification",
			"resumoPorContaCorrente": "Summary by current account",
			"registos": "Records",
			"dataVencimentoAte": "Due Date By",
			"dataLiquidacaoFirst": "Settlement date (1st time)",
			"ficheirosGerados": "Generated files",
			"errosEAvisos": "Errors and warnings",
			"notificarDevedores": "Notify Debtors",
			"dadosAvancados": "Advanced Data",
			"apagado": "Wiped out",
			"alterado": "Changed",
			"novo": "New",
			"contaCredora": "credit account",
			"ate": "Until",
			"impressaoEmissoesModalTitle": "Printing of Direct Debit Issuances - SEPA",
			"importadorModalTitle": "SEPA Direct Debits - Import",
			"processarFicheiro": "Processing the file...",
			"importarFicheiro": "Importing the file...",
			"dados": "Data",
			"resumoLiquidacao": "Settlement Summary",
			"processadosComSucesso": "Successfully processed",
			"dadosDoFicheiro": "File data",
			"errosImportacao": "Import Errors",
			"importadosComSucesso": "Imported successfully",
			"importadosComErro": "Imported with error",
			"ficheiroProcessadoComSucesso": "File processed successfully",
			"resumoProcessamento": "Summary Processing",
			"liquidados": "Settled",
			"devolvidos": "Returned",
			"naoLiquidados": "Unsettled",
			"imputado": "Imputed",
			"liquidadoSemImputacao": "Settled without imputation",
			"devolvido": "Returned",
			"naoLiquidado": "Unliquidated",
			"total": "Total",
			"valores": "Values",
			"nDeRegistos": "Number of records",
			"erroLiquidacao": "Settlement Error",
			"nDocumentos": "# Documents",
			"dataEmissao": "Issuance date",
			"porNotificar": "By notifying",
			"mostrar": "Show",
			"xNaoTemAutorizacaoAnexada": "{{count}} client(s) do not have authorization attached.",
			"obterAutorizacao": "Obtain Authorization",
			"mostrarTodosOsRegistos": "{{count}} configured client(s)."
		},
		"messages": {
			"temCertezaMarcarRegistosSelDisponiveisParaEmissao": "Are you sure you want to mark the selected records as available for new issuance?",
			"temCertezaMarcarRegistoDisponiveisParaEmissao": "Are you sure you want to mark the record as available for new issuance?",
			"semRegistosSelecionados": "You must select at least one record",
			"contaCredoraObrigatorio": "A Creditor Account is mandatory",
			"desejaImprimir": "Do you want to print?",
			"devedoresNotificadosSucesso": "{{count}} debtors have been successfully notified.",
			"noFiles": "Select the file to import",
			"existemNdocumentosDisponiveisParaEmissao": "There are {{count}} documents available for issuance.",
			"naoTemAutorizacaoAnexada": "There is no authorization attached.",
			"alteracaoMeioPagamentoMsg": "All customers with Direct Debit associated with the payment method {{codMeioPagamento}} ({{nomeMeioPagamento}}) are now associated with the new indicated payment method and respective IBAN.",
			"alteracaoIbanMsg": "The payment method {{codMeioPagamento}} ({{nomeMeioPagamento}}) with IBAN \"{{iban}}\" will be updated to the new IBAN indicated.",
			"clienteComDDAlterados": "{{countClientes}} customer(s) with Direct Debit to the creditor account with IBAN: \"{{iban}}\" will now have IBAN as creditor account: \"{{newIban}}\".",
			"emailEnviadoComSucesso": "Email sent successfully.",
			"emailEnviadoComErro": "An error occurred while sending the email.",
			"temACertezaQuePertendeEnviarEmail": "Are you sure you want to send the email with the Direct Debit Membership Form?",
			"temDocumentosPorLiquidarAteDataIni": "You have {{count}} documents to be settled by the start date.",
			"filtarRegistosQueNaoTemAutorizacao": "Show customers that do not have authorization attached.",
			"temACertezaQuePertendeAnularFichEmitido": "Are you sure you want to cancel the issued file: {{loteFicheiro}} ?",
			"haErrosNosDocumentos": "There are errors in the import documents.",
			"naoHaNenhumDadoParaImportar": "There is no data to import.",
			"soPodeAnexarPdf": "The file type is not allowed.",
			"mostrarTodosOsClientes": "Show all customers with and without authorization attached.",
			"naoPodeGerarFichComValorTotalZero": "You cannot generate the file with a total value of 0."
		},
		"tooltips": {
			"geradoAutomatico": "Automatically generated",
			"atencaoSoFicaraoDisponiveisDataDocSuperiorADataIni": "Attention: Only debts whose document date is higher than the start date will be available.",
			"ibanDiffDoMeioPagamento": "The IBAN \"{{iban}}\" is different from the IBAN \"{{ibanMeioPagamento}}\" of the payment method {{codMeioPagamento}} ({{nomeMeioPagamento}})."
		},
		"tipoChaveAutorizacao": {
			"texto": "Text",
			"conta": "Account",
			"classeContrato": "Contract Class",
			"tipoContrato": "Contract Type",
			"nContrato": "Contract No.",
			"ibanCredor": "IBAN Creditor",
			"ibanCliente": "Customer IBAN",
			"ano": "Year",
			"mes": "Month",
			"dia": "Day",
			"hora": "Hour",
			"minutos": "Minutes",
			"sequencial": "Sequential"
		}
	},
	"declaracaoiva": {
		"configured": "<i class='fa fa-check' aria-hidden='true'></i> The service is already configured.",
		"notConfigured": "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> The service is not configured.",
		"btns": {
			"anular": "Cancel Declaration",
			"getXML": "Get file",
			"getRecapitulativaXML": "Get summary file",
			"btnValidarNaAT": "Validate on AT",
			"btnConfiguracao": "Settings",
			"repor": "Reset Declaration",
			"previsualizar": "Preview",
			"balancete": "Balance sheet",
			"entregarDeclaracao": "Deliver",
			"configATLogin": "AT Configuration"
		},
		"tooltips": {
			"btnGuardarNotValid": "To save table 4 it must be duly completed",
			"btnGuardar": "The declaration must be canceled to be able to record again",
			"btnGetXML": "The declaration must be saved to obtain the declaration xml",
			"btnGetRecapitulativaXML": "The declaration must be saved to obtain the summary declaration xml",
			"validarNaAT": "The declaration must be saved in order to carry out operations on the AT"
		},
		"messages": {
			"successValidarNaAt": "The Declaration does not contain Errors and/or Alerts.",
			"successSubmeterNaAtTitle": "Attention",
			"successSubmeterNaAt": "The Periodic VAT Declaration was successfully submitted via the AT webservice.",
			"help": "<strong>If it is necessary to make changes to the VAT declaration, you must:</strong></br>1 - Export the XML file by clicking on the \"Get xml file\" button;</br>2 - Access the Authority's website Tax: <a class=\"text-white\" href=\"https://iva.portaldasfinancas.gov.pt/dpiva/portal/entregar-declaracao\"><strong>access link</strong></a>; </br>3 - Import the xml file previously downloaded in the space indicated.</br>Now you can make all the necessary changes and submit the file.",
			"submitAtPromptTitle": "Confirmation",
			"submitAtPromptMessage": "Are you sure you want to submit the declaration",
			"anuladasuccess": "Declaration annulled!",
			"temDeGuardar": "You must save the data to be able to create the file",
			"savedsucessfully": "Statement saved",
			"errorTextDataGridRowNotOk": "All line data must be filled in",
			"entreguesuccess": "The VAT Recapitulative Declaration was successfully submitted",
			"validateOperacoesSedeValoresIguais": "Values ​​in fields 63 and 64 of {{nomeAnexo}} do not match the values ​​of fields {{campo1}} and {{campo2}} of the headquarters annex."
		},
		"showmessage": {
			"title": "Don't show again",
			"message": "\"Ok\" if you don't want to see this message again!"
		},
		"text": {
			"attachment": "Attachment"
		},
		"tabs": {
			"anexos": "Attachments",
			"rosto": "Face",
			"anexoRContinente": "Annex R Continente",
			"anexoRAcores": "Annex R Azores",
			"anexoRMadeira": "Annex R Wood",
			"anexoCampo40": "Annex Field 40",
			"anexoCampo41": "Annex Field 41",
			"adicionarAnexo": "Add Attachment",
			"sede": "(Thirst)"
		},
		"anexos": {
			"comum": {
				"sim": "Yes",
				"nao": "No"
			},
			"rosto": {
				"title": "Start",
				"quadro0": {
					"desc1": "Identification of the Taxable Subject",
					"nif": "Tax Identification Number",
					"locSede": "Headquarters Location",
					"desc2": "Declaration Identification",
					"periodo": "Current period",
					"prazodecl": "Declaration deadline",
					"dentroprazo": "Within the time limit",
					"foraprazo": "Out of time",
					"desc3": "Operations in a space other than the headquarters",
					"continente": "Continent",
					"acores": "Azores",
					"madeira": "Wood",
					"desc4A": "Recapitulative statement",
					"check4A": "Indicate whether, during the reference period, you submitted any recapitulative declaration",
					"desc5": "No operations",
					"check5": "If during the period to which the declaration relates, you did not carry out passive or active operations that should be included in table 06, mark in this table and go to table 20",
					"desc20": "Certified Accountant",
					"nifcert": "NIF of the Certified Accountant"
				}
			},
			"anexoR": {
				"extratodecperio": "Field extract from the periodic declaration.",
				"valorReembolsoSolicitado": "You have requested a VAT refund, but have not yet processed the refund relationships.",
				"quadro6": {
					"title": "Clearance",
					"desc": "Did you carry out transactions of this nature in which, as purchaser, you paid the tax?",
					"point1": "What do paragraphs a), b) and c) of article 42 of the CIVA refer to?",
					"point2": "What do subparagraphs f) and g) of no. 3 of article 3 and subparagraphs a) and b) of no. 2 of article 4 of CIVA refer to",
					"desc1": "Transfer of goods and provision of services carried out on which tax has been paid",
					"basetribut": "Taxable base",
					"impfavorestado": "Tax in favor of the State",
					"field1e2": "At reduced rate",
					"field5e6": "At the intermediate rate",
					"field3e4": "At normal rate",
					"isentaounaotrib": "Exempt or not taxed",
					"field7": "Intra-community transfers of goods and services mentioned in the summary statements",
					"field8": "Operations that give the right to deduction",
					"field9": "Operations that do not grant the right to deduction",
					"desc2": "Intra-community acquisitions of goods and assimilated operations",
					"field12e13": "whose tax was paid by the declarant",
					"field14": "Covered by articles 15 of CIVA or RITI",
					"field15": "Covered by paragraphs 3, 4 and 5 of article 22 of the RITI",
					"field10e11": "Totals",
					"desc3": "Services carried out by taxpayers from other member states whose tax was paid by the declarant",
					"desc4": "Imports of goods whose tax was paid by the declarant",
					"field62": "Total tax base",
					"desc5": "Tax deductible",
					"impostfavSujPassivo": "Tax in favor of Suj.",
					"field20": "Non-current assets (Fixed assets)",
					"inventarios": "Inventories (Stocks)",
					"field21": "At reduced rate",
					"field23": "At the intermediate rate",
					"field22": "At normal rate",
					"field24": "Other goods and services",
					"desc6": "Monthly/quarterly and annual regularizations",
					"desc6NotSedeAnexo": "Total",
					"desc6NotSedeAnexoTotalPassivo": "Total tax in favor of the taxpayer",
					"desc6NotSedeAnexoTotalImposto": "Total tax in favor of the State",
					"desc7": "Excess to be reported from the previous period",
					"field61": "Field 96 of the previous declaration - paragraph 4 of article 22.",
					"desc8": "Annex R (1) Operations in a space other than the headquarters",
					"impdedutivel": "Tax deductible",
					"impliquidado": "Tax paid",
					"desc9": "Annex R (2) Operations in a space other than the headquarters",
					"desc10": "Regularizations in favor of the Taxable Person communicated by DS Cobranças (Mod. - BH008)",
					"desc11": "Totals",
					"field90": "Total tax base",
					"field91": "Total tax in favor of the taxpayer",
					"field92": "Total tax in favor of the State",
					"field93": "Tax to be paid to the State",
					"field94": "Recoverable tax credit",
					"field95": "I request a refund",
					"field96": "Excess to be reported"
				},
				"quadro6A": {
					"title": "Development",
					"descA": "Operations located in Portugal in which, as purchaser, you paid the VAT due",
					"efetuadentresident": "Carried out by entities residing in:",
					"basetrib": "Taxable base",
					"field97": "Community countries",
					"field98": "Third territories",
					"descB": "Operations in which you paid the VAT due by applying the taxable person inversion rule",
					"field99": "Gold",
					"field100": "Acquisitions of properties without exemption from exemption",
					"field101": "Scraps",
					"field102": "Civil construction services",
					"field105": "Greenhouse gas emissions",
					"field107": "Acquisition of cork and other products of forestry origin",
					"descC": "Operations referred to in subparagraphs f) and g) of no. 3 of article 3 and subparagraphs a) and b) of no. 2 of article 4 of the CIVA",
					"field103": "If you carried out operations of this nature, indicate their value",
					"descD": "Operations referred to in paragraphs a), b) and c) of article 42 of the CIVA",
					"field104": "If you carried out operations of this nature, indicate their value",
					"soma6A": "Sum of table 06-A",
					"desc10": "Operations referred to in subparagraphs f) and g) of no. 3 of Article 3 and in subparagraphs a) and b) of no. 2 of Art. 4",
					"field264": "If yes, indicate the amount of tax paid, without prejudice to it appearing in fields 2, 6 or 4 of Q.06"
				},
				"modal": {
					"title": "Periodic declaration field extract - Field {{field}}",
					"fields": {
						"periodo": "Period",
						"nDiario": "Daily No.",
						"nDocInterno": "Document No.",
						"nConta": "Account No.",
						"nContrib": "VAT Number",
						"valorDebito": "Debit Amount",
						"valorCredito": "Credit Value",
						"codIva": "VAT Code",
						"areaRegionalStr": "Regional Area VAT",
						"nCampoOficial": "Official Field",
						"contaOrigem": "Taxable Base Account",
						"nomeContaOrigem": "Taxable Base Name",
						"valorBase": "Base value"
					}
				}
			},
			"anexoCampo40": {
				"title": "Regularizations",
				"desc1": "Regularizations in favor of the taxpayer",
				"desc2": "Regularizations whose credit does not exceed €750, VAT included",
				"baseincidenciareg": "Regularization tax base",
				"ivareg": "Regularized VAT",
				"field3e4": "Credit value does not exceed €750",
				"desc3": "Other regularizations",
				"field3Ae4A": "Regularizations covered by articles 23 to 26",
				"field3Be4B": "Other regularizations (Ex: merger)",
				"desc4": "Total Value of Field 40",
				"desc5": "Certification by Official Auditor (ROC)",
				"certirevisoroficialcontas": "Certification by Official Auditor",
				"nifroc": "ROC Tax Identification Number",
				"modal": {
					"roc": {
						"title": "ROC Tax Identification Number",
						"naopodeservazio": "The ROC Tax Identification Number cannot be empty.",
						"jaexiste": "The ROC Tax Identification Number is already entered."
					}
				}
			},
			"anexoCampo41": {
				"title": "Regularizations",
				"desc1": "Regularizations in favor of the State",
				"desc2": "Other regularizations",
				"baseincidenciareg": "Regularization tax base",
				"ivareg": "Regularized VAT",
				"field3Ae4A": "Regularizations covered by articles 23 to 26",
				"field3Be4B": "Other regularizations",
				"field3Ce4C": "Article 6 of Decree-Law no. 198/90, of June 19",
				"field3De4D": "Decree-Law No. 19/2017, of February 14",
				"desc3": "Total Value of Field 41"
			},
			"table40e41": {
				"fields": {
					"tipoRegularizacaoCampo": "Type of Regularization",
					"nif": "VAT Number",
					"nPedido": "Order No.",
					"baseIncidencia": "Regularization tax base",
					"ivaRegularizado": "Regularized VAT",
					"erro": "Error",
					"dataEmissao": "Issuance date"
				}
			}
		},
		"recapitulativa": {
			"title": "Recapitulative VAT Declaration",
			"table": {
				"paisDestinoStr": "Destination country (2)",
				"nContribuinte": "Purchaser's NIF (3)",
				"valorOperacoes": "Value in EURO (4)",
				"tipoOperacaoStr": "Type of operation (5)"
			},
			"quadros": {
				"quadro1": {
					"title": "Frame 01",
					"desc1": "Identification of the taxpayer",
					"nif": "Tax Identification Number"
				},
				"quadro2": {
					"title": "Frame 02",
					"desc": "Declaration type",
					"primeira": "First",
					"substituicao": "Replacement",
					"desc1": "Was there a change in the shipping frequency from quarterly to monthly?",
					"field21": "Changing shipping frequency from quarterly to monthly",
					"desc21": "Does the monthly amount completely replace the previous declared quarterly amount or not?",
					"field22": "No intra-community operations in the respective period",
					"field23": "Other changes to intra-community operations",
					"field24": "Transfers of goods on consignment",
					"field241": "No changes in the respective period",
					"field2411": "No transfers of declared consignment goods",
					"field2412": "With transfers of goods on consignment declared",
					"field242": "With changes in the respective period",
					"field243": "Declare for the first time in the respective period"
				},
				"quadro3": {
					"title": "Table 03",
					"desc": "Period to which it relates",
					"ano": "Year",
					"mensal": "Monthly",
					"trimestral": "Quarterly",
					"mesincltrimestre": "Month(s) included in the quarter"
				},
				"quadro45": {
					"title": "Table 04/05",
					"desc4": "Description of intra-community operations",
					"modalTitle": "Description of intra-community operations",
					"modalmessages": {
						"tipoopvazio": "The Operation Type cannot be empty.",
						"paisdestvazio": "The Destination Country cannot be empty."
					},
					"table": {
						"prefpaisdest": "Destination Country Prefix (2)",
						"nif": "Purchaser's NIF (3)",
						"valor": "Value (4)",
						"tipoop": "Type of Operation (5)"
					},
					"desc5": "Sum of values ​​declared in table 4 (grouped by type of operation)",
					"field10": "Sum (only of values ​​that relate to the operations typified in column 5 with 1)",
					"field11": "Total sales of new means of transport to individuals and similar means from other member states",
					"field17": "Sum (only of the values ​​that relate to the operations indicated in column 5 with 4)",
					"field18": "Sum (only of the values ​​that relate to the operations indicated in column 5 with 5)",
					"field19": "Total (10+11+18)"
				},
				"quadro6": {
					"title": "Frame 06",
					"desc6": "Description of transfers of goods on consignment",
					"table": {
						"prefpaisdest": "Destination Country Prefix (2)",
						"nif": "Recipient's NIF (3)",
						"nifOriginal": "NIF of the Original Recipient (4)",
						"codigo": "Code (5)"
					}
				}
			}
		}
	},
	"demfinsnc": {
		"fields": {
			"listagem": "Listing",
			"ano": "Year",
			"processafluxo": "Process cash flows",
			"vercomdados": "See only items with values",
			"processaIntercalares": "Process interim demos",
			"variaveisapuramento": "Interim clearance variables",
			"periointercalartable": "Final inventory of the interim period of the year \"{{year}}\""
		},
		"tabs": {
			"balancoTitle": "Balance",
			"demresultNatTitle": "DR by Naturezas",
			"demresultFuncTitle": "DR by Functions",
			"demfluxocaixaTitle": "Cash Flow Statement",
			"demAltCPTitle": "DACP {{dataDemAltCP}}",
			"demAltCP2Title": "DACP {{dataDemAltCP2}}",
			"intContaTitle": "Account Ranges",
			"balanceteTitle": "Balance sheet",
			"fluxosCaixaTitle": "Cash flows",
			"table": {
				"descricao": "Rubric",
				"notas": "Grades",
				"parent": {
					"parent0": "Active",
					"parent1": "Equity",
					"parent2": "Passive"
				},
				"capitalRealizado": "capital",
				"accoesQuotasProprias": "Actions",
				"outrosInstrumentosCapProprio": "Others",
				"premiosDeEmissao": "Awards",
				"reservasLegais": "Reservations",
				"outrasReservas": "Others",
				"resultadosTransitados": "Results",
				"ajustamentosEmActivosFinanceirosOutrasVariacoes": "Adjustments",
				"excedentesDeRevalorizacao": "Surplus",
				"resultadoLiquidoDoPeriodo": "Result",
				"total": "Total",
				"nContaDe": "Account No.",
				"nContaAte": "Number of counts up to",
				"mesPeriodo": "Month Period",
				"nConta": "Account No.",
				"nomeConta": "Name"
			}
		},
		"radio": {
			"sncncrf": "SNC - NCRF",
			"microentidade": "Microentities"
		},
		"btn": {
			"notas": "Grades",
			"valdefeito": "Default values",
			"previsualizargrid": "Process on a grill",
			"demfinsncsaved": "Dem. Fin. Versions.",
			"repvaloriginal": "Reset all original values",
			"anos": "Year",
			"periodo": "Interim period:",
			"anexoRelatorioGestao": "Annex / Management Report"
		},
		"modal": {
			"title": "Financial Statement Notes",
			"titlelistsaved": "Saved Financial Statement Versions",
			"titleobs": "Indicate observations",
			"titlepreviewpdf": "SNC financial statements",
			"obs": "Comments",
			"badgecaptionsaved": "Selected financial statement",
			"tabs": {
				"table": {
					"rubrica": "Rubrics",
					"notas": "Grades"
				},
				"balanco": {
					"title": "Balance",
					"tab": {
						"titles": {
							"ativo": "Active",
							"passivo": "Passive"
						},
						"ativonaocorrente": "Non-current asset",
						"ativocorrente": "Current asset",
						"capitalproprio": "Equity",
						"passivonaocorrente": "Non-current liabilities",
						"passivocorrente": "Current liabilities"
					}
				},
				"demresult": {
					"title": "Income Statement",
					"pornaturezas": "By natures",
					"porfuncoes": "By functions"
				},
				"capproprioperio": {
					"title": "Equity for the Period",
					"demaltcptproprio": "Dem. of changes in equity for the period"
				},
				"fluxocaixa": {
					"title": "Cash flows",
					"atvop": "Operational activities",
					"atvinvest": "Investment activities",
					"atvfin": "Financing activities"
				}
			},
			"listsaved": {
				"ano": "Year",
				"data": "Date",
				"hora": "Hour",
				"obs": "Comments"
			},
			"message": {
				"valdef": "<b>Attention:</b> If you continue with this operation, you will reset the default values.",
				"delidsaved": "Delete previously saved financial statement?",
				"badgecaptioncellmanuallyedited": "Cell manually edited by the user",
				"delversaodemfinant": "Financial statement version successfully deleted!",
				"savedemfin": "SNC financial statement saved successfully!"
			}
		},
		"messages": {
			"periodoempty": "Period cannot be empty.",
			"notastemdeserumnumero": "Notes must be a number"
		},
		"language": {
			"language0": "Portuguese",
			"language1": "English",
			"idioma": "Language: {{language}}"
		}
	},
	"depto": {
		"title_detail": "Department {{id}}",
		"title_new": "New Department",
		"title_plural": "Departments",
		"pesquisa": "To look for",
		"saved": "Department {{id}}, saved successfully.",
		"error": "Department {{id}}, cannot be saved.",
		"fields": {
			"nDepto": "Code.",
			"nDeptoPlaceholder": "Department code",
			"nome": "Name",
			"nomePlaceholder": "Department name"
		}
	},
	"descritivos": {
		"title_detail": "Accounting description {{id}}",
		"title_new": "New accounting description",
		"title_plural": "Accounting Descriptions",
		"pesquisa": "To look for",
		"saved": "Accounting description {{id}}, saved successfully.",
		"error": "Accounting description {{id}}, cannot be saved.",
		"deleted": "Accounting description {{id}}, successfully deleted.",
		"fields": {
			"nDescrit": "Code.",
			"nDescritPlaceholder": "Accounting description code",
			"nome": "Name",
			"nomePlaceholder": "Accounting description name",
			"descricao": "Description",
			"descricaoPlaceholder": "Description of the accounting description",
			"tipoDescritivo": "Type",
			"tipoDescritivoPlaceholder": "Type of accounting description"
		}
	},
	"dgemps": {
		"title_detail": "Contributor {{id}}",
		"title_new": "New Collaborator",
		"title_plural": "Collaborators",
		"pesquisa": "To look for",
		"saved": "Employee {{id}} saved successfully",
		"error": "Employee {{id}} cannot be saved.",
		"fields": {
			"codEmp": "Collaboration.",
			"codEmpPlaceholder": "Employee code",
			"nome": "Name",
			"nomePlaceholder": "Collaborator name",
			"apelido": "Surname",
			"apelidoPlaceholder": "Employee's last name",
			"morada": "Household",
			"moradaPlaceholder": "Employee's address",
			"codPostal": "Postcode",
			"codPostalPlaceholder": "Employee zip code",
			"nomeLocalida": "Location",
			"nomelocalidaPlaceholder": "Employee location",
			"email": "Email",
			"emailPlaceholder": "Employee email",
			"nTelefone": "Telephone No.",
			"nTelefonePlaceholder": "Employee telephone number",
			"telemovelPart1": "Cell phone",
			"telemovelpart1Placeholder": "Employee's cell phone",
			"codservicoprh": "Service",
			"codservicoprhPlaceholder": "Service code",
			"rua": "Road",
			"ativo": "Active",
			"ruaPlaceholder": "Employee Street",
			"nomeCategoria": "Category",
			"nomeCategoriaPlaceHolder": "Category",
			"nomeCompleto": "Full name",
			"formRemun": "Form of Remuneration",
			"formRemunPlaceholder": "Form of Remuneration",
			"masterDetail": {
				"campo": "Field",
				"antigo": "Old value",
				"novo": "New value"
			}
		},
		"recibos": {
			"title": "Pay slip",
			"fields": {
				"nrecibo": "Receipt No.",
				"nprocessamento": "Processing no.",
				"dataprocessamento": "Processing date",
				"datarecibo": "Receipt date"
			}
		},
		"sexes": {
			"0": "Feminine",
			"1": "Masculine",
			"2": "AT"
		},
		"civilStatus": {
			"0": "AT",
			"1": "Married",
			"2": "Single",
			"3": "Divorced",
			"4": "Widower",
			"5": "de facto union"
		},
		"taxResidence": {
			"0": "Resident in Portuguese territory",
			"1": "Non-resident who earns income from a single employer",
			"2": "Non-resident who earns income from more than one employer"
		},
		"workRegime": {
			"0": "Private",
			"1": "Public function"
		},
		"salaryCalculationMethod": {
			"0": "AT",
			"1": "By base salary",
			"2": "For working hours"
		},
		"twelfthsCalculationType": {
			"0": "Subs.",
			"1": "Christmas subsidy",
			"2": "Holiday allowance",
			"3": "Does not perform processing"
		},
		"twelfthsValuesType": {
			"0": "0",
			"50": "50",
			"100": "100"
		}
	},
	"dgempsfull": {
		"title_detail": "Contributor {{id}}",
		"title_new": "New Collaborator",
		"title_plural": "Collaborators",
		"pesquisa": "To look for",
		"saved": "Employee {{id}} saved successfully",
		"error": "Employee {{id}} cannot be saved.",
		"deleted": "Collaborator {{id}} successfully deleted",
		"btn": {
			"configWSS": "WSS Configuration"
		},
		"fields": {
			"morada": "Road",
			"moradaPlaceholder": "Employee Street",
			"nTelefone": "Telephone",
			"nTelefonePlaceholder": "Employee telephone number",
			"dtMotMov": "Last registration update",
			"dtMotMovPlaceholder": "Employee registration date",
			"situacao": "Situation",
			"situacaoPlaceholder": "Employee status",
			"estadoCivil": "Marital status",
			"sexo": "Sex",
			"dtNascimento": "Date of birth",
			"dtNascimentoPlaceholder": "Employee's date of birth",
			"nacionalidad": "Nationality",
			"nacionalidadPlaceholder": "Nationality of the employee",
			"concelho": "County",
			"concelhoPlaceholder": "Collaborator's municipality",
			"freguesia": "Parish",
			"freguesiaPlaceholder": "Collaborator's parish",
			"nbIdentidade": "Citizen card number",
			"nbIdentidadePlaceholder": "Employee's citizen card number",
			"dtBiValidade": "Citizen card validity",
			"dtBiValidadePlaceholder": "Expiry date of the citizen card/identity card",
			"numSegSoc": "NISS",
			"numSegSocPlaceholder": "Employee social security identification number",
			"numContrib": "NIF",
			"numContribPlaceholder": "Employee tax identification number",
			"nCartaoTicketRef": "Meal card number",
			"nCartaoTicketRefPlaceholder": "Employee meal card number",
			"banco": "Employee bank",
			"iban": "Employee IBAN",
			"swift": "Employee SWIFT",
			"codNHABQPessoal": "Qualification level",
			"codNHABQPessoalPlaceholder": "Employee qualification level",
			"dtAdmEmpresa": "Admission date",
			"dtAdmEmpresaPlaceholder": "Employee admission date",
			"vencimBase": "Base salary",
			"vencimBasePlaceholder": "Employee base salary",
			"nDiuturnidad": "Number of seniority periods",
			"valorUnDiutu": "Value of each seniority payment",
			"horasDia": "Working hours per day",
			"horasDiaPlaceholder": "Working hours per day",
			"horasSemana": "Working hours per week",
			"horasSemanaPlaceholder": "Working hours per week",
			"horasMes": "Working hours per month",
			"horasMesPlaceholder": "Working hours per month",
			"remunHora": "Hourly pay",
			"temSubAlim": "Has a subsidy",
			"valorSubAlim": "Daily Value",
			"temPreProcessamentoSubAlimen": "Has pre-processing",
			"valorSFerias": "Vacation allowance amount",
			"valorSFeriasPlaceholder": "Value of the employee's vacation allowance",
			"valorSNatal": "Christmas allowance amount",
			"valorSNatalPlaceholder": "Value of the employee's Christmas bonus",
			"metodPaga": "Salary calculation method",
			"divisaoTrab": "Division (duration) of work",
			"divisaoTrabPlaceholder": "Division (duration) of employee work",
			"tipoContrato": "Type of contract",
			"tipoContratoPlaceholder": "Type of employee contract",
			"dtIniContrat": "Signature Date",
			"dtIniContratPlaceholder": "Employee signature date",
			"dtFimContrat": "Contract renewal date",
			"dtFimContratPlaceholder": "Employee contract renewal date",
			"dataEfetividade": "Effective date",
			"dataEfetividadePlaceholder": "Effective date of the employee's contract",
			"temSegurAcTr": "Do you have work accident insurance?",
			"nSeguradAcTr": "Work accident insurer",
			"nSeguradAcTrPlaceholder": "Employee accident insurance company",
			"caixaPrev": "Social security registration center",
			"caixaPrevPlaceholder": "Employee social security registration center",
			"classeCaixa": "Social security regime",
			"classeCaixaPlaceholder": "Employee social security regime",
			"nTabIrs": "IRS table",
			"nTabIrsPlaceholder": "Employee IRS table",
			"numDepend": "Number of dependents without disabilities",
			"numDependPlaceholder": "Total number of dependents without disabilities of the employee",
			"numDependDef": "Number of dependents with disabilities",
			"numDependDefPlaceholder": "Total number of disabled dependents with disability of the employee",
			"numDependDefLabel": "Number of disabled dependents with permanent disability equal to or greater than 60%",
			"regimeTrabalho": "Work regime",
			"tipoCalcDuodecimos": "Value calculation",
			"calcDuodecVariavel": "Calculation of twelfths over variable values",
			"eExResidente": "Taxation on former residents",
			"eExResidenteLabel": "Taxable persons who become tax residents pursuant to paragraphs 1 and 2 of article 16 of the CIRS",
			"nPocRemuLiq": "Accounting account where the amount receivable is entered",
			"classeTrab": "Work class",
			"classeTrabPlaceholder": "Employee work class",
			"funcao": "Function",
			"funcaoPlaceholder": "Role of the collaborator",
			"seccao": "Operation/section",
			"seccaoPlaceholder": "Operation/employee section",
			"nZona": "Zone",
			"nZonaPlaceholder": "Employee zone",
			"departamento": "Department",
			"departamentoPlaceholder": "Employee department",
			"nSubDe": "Subdepartment",
			"nSubDePlaceholder": "Employee subdepartment",
			"ccusto": "cost center",
			"ccustoPlaceholder": "Employee cost center",
			"nCodRepCC": "Personal point breakdown",
			"nCodRepCCPlaceholder": "Employee personal point breakdown",
			"categoria": "Category",
			"categoriaPlaceholder": "Employee category",
			"profissao": "Profession",
			"profissaoPlaceholder": "Employee's profession",
			"sitProfissao": "Professional situation",
			"sitProfissaoPlaceholder": "Professional status of the employee",
			"codcct": "Collective labor contract",
			"codcctPlaceholder": "Employee collective labor contract",
			"nivQualific": "Qualification level",
			"nivQualificPlaceholder": "Employee qualification level",
			"motivoRegisto": "Reason for last update",
			"situacaoEmprego": "Employee status",
			"residenciaFiscal": "Tax residence",
			"nCodABVencimento": "Maturity",
			"nCodABSubAlimentacao": "Food allowance",
			"nCodABDiuturnidades": "Seniority payments",
			"nCodABSubFerias": "Holiday allowance",
			"nCodABSubNatal": "Christmas subsidy",
			"nCodABFerias": "Vacation",
			"nCodDescIRS": "IRS",
			"codRepFinancas": "Finance department",
			"codRepFinancasPlaceholder": "Finance department",
			"conjugeIncapacidad60": "Spouse with disability equal to or greater than 60%",
			"temDescIRSTxFixa": "Apply IRS flat rate discount",
			"percDescTaxaFixaIRS": "IRS Fixed Rate (Marginal Rate)",
			"deficienteForcasArmadas": "Disabled armed forces",
			"naoEfetuaFCT": "Compensation Funds",
			"naoEfetuaFCTLabel": "Does not calculate Compensation Funds",
			"sindicato": "Syndicate",
			"ncartsindicato": "Union Card Number",
			"temRendJovensTrab": "Has partial income exemption",
			"temRendResNaoHabit": "Non-habitual residents are exempt",
			"dtInicio": "Start date",
			"crlCalcLiquido": "Calculates net income",
			"crlValorLiquido": "Net value",
			"crlValorLiquidoEmMe": "Net value in foreign currency",
			"crlAbonoLiquido": "Allowance",
			"nDiasFeriasAnoAnteri": "Vacation days last year",
			"duodecimosConfigEmpresa": "Twelfths configured by company",
			"usaDuodecimosPorEmp": "Use twelfths per employee",
			"duodecimosSubNatal": "Sub.",
			"duodecimosSubFerias": "Sub.",
			"temRedIrsCredHabitac": "Applies reduction to individuals with housing credit for their own and permanent housing (measure only in force during 2023)",
			"temRedCtrArrendameHP": "Apply subject reduction for holders of lease/credit contracts for permanent housing"
		},
		"groups": {
			"estadoSS": {
				"title": "State Social Security",
				"naoVerificado": "Not verified",
				"verificar": "To check",
				"registar": "Register",
				"consultar": "Consult"
			},
			"addressContacts": {
				"title": "Address and contacts"
			},
			"identification": {
				"title": "Identification"
			},
			"bankData": {
				"title": "Bank data"
			},
			"taxData": {
				"title": "Tax data",
				"sections": {
					"irs": "IRS",
					"socialSecurity": "Social Security",
					"financas": "Finance",
					"rendJovensTrab": "Partial exemption for income from work earned by taxpayers aged between 18 and 26",
					"titCredHab": "Reduction of withholding taxes for contract holders",
					"temRendResNaoHabit": "Non-habitual resident - Income exemption from tax regime for 10 years"
				}
			},
			"contractualData": {
				"title": "Contractual data",
				"sections": {
					"timeAndPlaceOfWork": "Working hours and location",
					"remuneration": "Remuneration",
					"calcPorRendLiq": "Calculation by Net Income"
				}
			},
			"professionalData": {
				"title": "Professional details"
			},
			"subsidies": {
				"title": "Subsidies and Insurance",
				"sections": {
					"food": "Food allowance",
					"vacations": "Holiday allowance",
					"christmas": "Christmas subsidy",
					"workAccidentInsurance": "Work accident insurance"
				}
			},
			"twelfths": {
				"title": "Twelfths",
				"sections": {
					"rulesProcessingTwelfths": "Rules for processing twelfths"
				}
			},
			"integration": {
				"title": "Integration",
				"sections": {
					"integrationGeneralAccounting": "Integration into general accounting",
					"integrationAnalyticalAccounting": "Integration into analytical accounting"
				}
			},
			"otherData": {
				"title": "Other data",
				"sections": {
					"allowanceCodesEmployeeWageProcessing": "Allowance codes to be used in employee salary processing",
					"discountCodeEmployeeWage": "Discount code to use in employee salary processing",
					"comments": "Comments"
				}
			}
		},
		"actions": {
			"calculateRemunHora": "Calculate employee hourly pay",
			"applySuggestedRemunHora": "Apply the suggested hourly wage",
			"simula": "Simulate"
		},
		"text": {
			"datasHistoricoDadosCaption": "Registration history",
			"datasHistoricoDadosCurrent": "in force",
			"fileNamePicture": "Photo_{{codeEmp}}",
			"configurarOutrosDados1": "It appears that you have not yet configured the default data in the employee's file.",
			"configurarOutrosDados2": "do it now",
			"oldRecordWarning": "<strong>Attention:</strong> you are viewing a data history record.",
			"total": "Total: {{value}} (suggested hourly pay)",
			"configWSS": "Configuring Access to the Social Security Webservice System"
		},
		"titles": {
			"abono": "{{designation}}: {{calculatedvalue}} = ({{value}} × {{months}}) / ({{hoursWeek}} × {{weeks}})",
			"abonoCruzamento": "{{designation}}: {{calculated value}} = (({{value}} × ({{percentage}} / 100) × {{months}}) / ({{hoursWeek}} × {{weeks}})",
			"valorCalculado": "Calculated value",
			"valorDesignacao": "Value {{designation}}",
			"nDiuturnidades": "Number of seniority periods",
			"percentagem": "Percentage",
			"meses": "Months",
			"horasSemana": "Number of hours per week",
			"semanas": "Number of weeks per year"
		},
		"errors": {
			"invalidNumSegSoc": "Invalid Social Security Identification Number",
			"invalidDtFimContrat": "Contract end date is before the contract start date",
			"invalidDTNascimento": "The Date of Birth is not valid!"
		},
		"deletePrompt": {
			"title": "Delete collaborator {{id}}?",
			"message": "How do you intend to delete the collaborator?",
			"lastOnly": "Only the current registration (the most recent)",
			"all": "Your entire history",
			"invalid": "Please select how you want to delete the collaborator"
		},
		"saveCadastro": {
			"title": "Save registration",
			"message": "Registration data has been changed.",
			"newCadastro": "Create new record in the registry",
			"updateLatestCadastro": "Update last record in the registry",
			"dataRegisto": "Select the registration date"
		},
		"prompt": {
			"exitTitle": "Confirmation",
			"exitMessage": "Are you sure you want to exit without saving the changed data?"
		},
		"comments": {
			"modalTitle": "Comment maintenance",
			"title": "Title",
			"text": "Text",
			"date": "Date",
			"user": "User"
		},
		"modalSimularTaxaFixaIrs": {
			"modalTitle": "IRS Marginal Rate Simulator",
			"indiqueTaxaPretendida": "Indicate the desired effective monthly rate:",
			"taxaEfetiva": "Effective monthly rate",
			"infoModal": "When calculating, the marginal rate will be displayed, taking into account the different types of remuneration defined for the employee (salary, seniority payments, meal allowance, fixed data).",
			"taxaMarginal": "Marginal rate",
			"semDados": "No data to present",
			"infoCalculos": "Calculations information",
			"taxaNaoValida": "Effective monthly rate must be between 0% and 100%"
		}
	},
	"impdadosemp": {
		"filtropesq": "Research",
		"previsualizar": "Preview",
		"deleteFoto": "Delete Photo",
		"fields": {
			"listagem": "Listing",
			"ate": "Until",
			"deCod": "From Employee Code",
			"deNome": "By Name",
			"deIdade": "Deity",
			"deSituacao": "Situation",
			"deCategoria": "By Category",
			"deDepartamento": "By Department",
			"deDtNasc": "Date of Birth",
			"deDtValCt": "Date Val. BI",
			"deDtValBI": "Contract Valid Date"
		}
	},
	"diarios": {
		"title_detail": "Accounting journal {{id}}",
		"title_new": "New accounting journal",
		"title_plural": "Accounting journals",
		"pesquisa": "To look for",
		"saved": "Accounting journal {{id}}, saved successfully.",
		"error": "Accounting journal {{id}}, cannot be saved.",
		"deleted": "Accounting journal {{id}}, successfully deleted.",
		"fields": {
			"nDiario": "Code.",
			"nDiarioPlaceholder": "Accounting journal code",
			"nome": "Name",
			"nomePlaceholder": "Accounting journal name",
			"formaIncrementoNDoc": "Increment form Doc.",
			"formaIncrementoNDocPlaceholder": "Increment form",
			"aberturaContab": "Accounting opening journal (does not post VAT)",
			"aberturaContabPlaceHolder": "Accounting opening journal"
		}
	},
	"distritos": {
		"title_detail": "District {{id}}",
		"title_new": "New District",
		"title_plural": "Districts",
		"pesquisa": "To look for",
		"saved": "District {{id}}, saved successfully",
		"error": "District {{id}}, cannot be saved.",
		"deleted": "District {{id}}, deleted successfully",
		"fields": {
			"codDistrito": "District Code",
			"nomeDistrito": "District Name",
			"localObtencaoRendimentos": "Location of Earning Income",
			"codContinente": "Continent",
			"codPais": "Country code",
			"nomePais": "Country"
		}
	},
	"divisoesTrabalho": {
		"title_detail": "Division of Labor {{id}}",
		"title_new": "New Division of Labor",
		"title_plural": "Divisions of Labor",
		"pesquisa": "To look for",
		"saved": "Division of Work {{id}}, saved successfully.",
		"error": "Division of Labor {{id}}, cannot be saved.",
		"deleted": "Division of Labor {{id}}, successfully deleted.",
		"fields": {
			"nDivisao": "Code.",
			"designacaoBreve": "Brief Designation",
			"designacaoCompleta": "Full Designation",
			"codDuracaoQPessoal": "Code.",
			"tipo": "Time Type"
		}
	},
	"divisTipo": {
		"0": "Unknown",
		"1": "Full Time",
		"2": "Part time"
	},
	"dmrf": {
		"frestran": "Withholdings from non-residents",
		"edit": "To edit",
		"delete": "To switch off",
		"newModalTitle": "Create record",
		"editModalTitle": "Edit record",
		"summaryTitle": "Filling out the IRC/IRS Withholding Tax Form",
		"fields": {
			"nContrib": "VAT Number",
			"nDocExterno": "External Doc. No.",
			"zona": "Zone",
			"rubrica": "Heading",
			"retencao": "Retention"
		},
		"messages": {
			"contribrequired": "You must enter a Taxpayer Number!",
			"savedSuccess": "Registration saved successfully",
			"promptConfirmTitle": "Confirmation",
			"promptDeleteRecord": "Are you sure you want to delete the record?",
			"promptDeleteDeclaracao": "Are you sure you want to delete the declaration?",
			"erroExport": "An error occurred while exporting the declaration file.",
			"promptSendMessage": "This operation will record the data and submit the IRC/IRS Withholding Statement on the Finance Portal (AT).<br/>Are you sure you want to continue?",
			"sendSuccess": "The declaration file was sent successfully.",
			"sending": "Sending... please wait a moment.",
			"exporting": "Exporting... please wait a moment.",
			"saving": "Saving... please wait a moment.",
			"deleting": "Deleting... please wait a moment.",
			"deletingRecord": "Deleting registration... please wait a moment."
		},
		"toolbar": {
			"deletePeriodo": "Cancel declaration",
			"refresh": "Get data",
			"save": "Record statement",
			"export": "Generate file",
			"send": "Send declaration"
		},
		"rubrica": {
			"102": "102: IRS - Business and Professionals",
			"103": "103: IRS - Pensions",
			"104": "104: IRS - Property",
			"105": "105: IRS - Capital - Securities - Issuing entities",
			"106": "106: IRS - Capitals - Securities - Registration, depository and other entities",
			"107": "107: IRS - Capital - Interest on demand or time deposits",
			"108": "108: IRS - Capital - Other income",
			"109": "109: IRS - Prizes for games, lotteries, raffles, mutual bets, sweepstakes, competitions",
			"110": "110: IRS - Compensation and other asset increases",
			"112": "112: IRS - Extraordinary surcharge",
			"114": "114: IRS - Extraordinary surcharge - Cat. H",
			"115": "115: IRS - Other capital income subject to the rates provided for in article 71 of the CIRS",
			"116": "116: IRS - Income.",
			"117": "117: IRS - Income.",
			"118": "118: IRS - Capital-Securities representing debt (D.L. 193/2005 of November 7)",
			"199": "199: IRS - Compensatory Interest",
			"201": "201: IRC - Buildings",
			"202": "202: IRC - Commissions for intermediation in any contracts and services provided",
			"203": "203: IRC - Capital - Securities - Issuing entities",
			"204": "204: IRC - Capital - Securities - Registration or depository entities",
			"205": "205: IRC - Capital - Interest on current or time deposits",
			"206": "206: IRC - Capital - Other income",
			"207": "207: IRC - Game prizes, lotteries, raffles, mutual bets, sweepstakes, competitions",
			"208": "208: IRC - Remuneration of members of statutory bodies of Legal Entities",
			"209": "209: IRC - Investment Funds - Income.",
			"210": "210: IRC - Rescue Uni.",
			"211": "211: IRC - Collective Inv. Organization-Reg.",
			"212": "212: IRC - Org. Inv. Collective determined duration - Reg. Tran.",
			"213": "213: IRC - Org. Inv. Collective determined duration - Reg. Tran.",
			"299": "299: IRC - Compensatory Interest",
			"000": ""
		},
		"zona": {
			"desconhecido": "Unknown",
			"continente": "Continent",
			"madeira": "Wood",
			"acores": "Azores"
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmation",
		"leavePromptMessage": "Clicking YES will exit and discard all processing data."
	},
	"docfas": {
		"title_detail": "Document type {{id}}",
		"title_new": "New document type",
		"title_plural": "Document Type",
		"pesquisa": "To look for",
		"saved": "Document type {{id}}, saved successfully",
		"error": "Document type {{id}}, cannot be saved.",
		"documentoGlobal": "Global Document",
		"entregaEfectivaSobreDocumentoGlobal": "Effective Delivery on Global Document",
		"loginAT": "AT Webservice - Series Communication",
		"accoes": "Actions",
		"autofaturacaoComAcordo": "Self-invoicing for suppliers with Agreement",
		"autofaturacaoSemAcordo": "Self-invoicing for suppliers without an Agreement",
		"fields": {
			"nDocFa": "Number",
			"nome": "Name",
			"descricao": "Description",
			"codigoSAFTTipoDoc": "SAFT - Document Type",
			"identificaCarga": "Identifies cargo",
			"faturaComIvaIncluido": "Invoice with VAT included",
			"integraStocks": "Integrates stocks",
			"integraNaContabilidade": "Integrates into accounting",
			"integraEmContasCorrentes": "Integrates into current accounts",
			"reportDefeito": "Default report",
			"tipoMovimentoStock": "Stock movement",
			"grupoDocfa": "Group",
			"fluxoMonetario": "Money Flow",
			"nDocfaDestino": "Destination Doc Type",
			"faturacaoPublica": "Public billing",
			"obrigatorioMeiosPagam": "Use payment methods",
			"descricaoSAFTTipoDoc": "Description SAFT - Doc Type.",
			"codigoSAFTTipoDocDescStr": "SAFT - Document Type",
			"entregaEfetivaSobreDocGlobal": "Effective delivery on global document",
			"sujeitoAHASHStr": "Signature"
		},
		"list": {
			"actions": {
				"new": "New based on this"
			}
		},
		"captions": {
			"definicoes": "Business and integration settings",
			"numeracoes": "Series",
			"dadosAT": "AT data",
			"reports": "Reports"
		},
		"warnings": {
			"canceladaNovaSerie": "Creation of the new canceled series",
			"novaserie": "New series",
			"addnovaserie": "Add new series",
			"novaSerieAdd": "New series added successfully",
			"temAlteracoes": "There are changes not yet saved."
		},
		"errors": {
			"tipoDocNotExist": "Document type does not exist",
			"erroNovaSerie": "Error creating new series",
			"autofaturacaoNome": "The type of document is self-invoicing, which requires the term \"self-invoicing or self-invoicing\" to be included in the name"
		},
		"modal": {
			"new": {
				"cardBase": "Basis for new document type",
				"cardNew": "New document type",
				"title": "New document type creation wizard",
				"nDocfaBase": "Base document",
				"nDocFaPlaceHolder": "If not filled in, it automatically generates",
				"saftTipoDoc": "SAFT - Document Type",
				"fatura": "Invoice",
				"faturaRecibo": "Invoice-Receipt"
			}
		},
		"modals": {
			"comunicacaoSeriesAT": "Serial communication to AT"
		},
		"reports": {
			"fields": {
				"visible": "Available",
				"defaultReport": "By default",
				"nome": "Name",
				"descricao": "Description",
				"tipoDescricao": "Type"
			},
			"descricao": {
				"system": "System",
				"user": "User",
				"userEmpresa": "User/Company"
			}
		}
	},
	"docfasNums": {
		"title_detail": "Series Document type Id. {{id}}",
		"title_new": "New document type series",
		"title_plural": "Document Type Series",
		"pesquisa": "To look for",
		"saved": "Document Type Series {{id}}, saved successfully",
		"error": "Document Type Series {{id}}, cannot be saved.",
		"fields": {
			"idDocfaNum": "Series Id.",
			"nDocfa": "Document Type",
			"nNumer": "No.",
			"nNumerPlaceholder": "No.",
			"num": "No.",
			"descNumer": "Description",
			"data": "Date",
			"numDraft": "No.",
			"nConta": "Account no.",
			"encerrado": "Closed",
			"atcud": "AT - Validation Code",
			"visivelERPCloud": "Visible cloud",
			"codLocalEmissao": "Place of issue",
			"atEstadoSerieDescricao": "Status in AT",
			"paraTodosDoGrupo": "Create for all document types in the group {{GroupName}}"
		},
		"auxiliares": {
			"novaserie": "New series",
			"addnovaserie": "Add new series",
			"novaSerieAdd": "New series added successfully"
		},
		"errors": {
			"SerietipoDocNotExist": "Document type series does not exist",
			"serieNaoPodeSerNula": "Document type series cannot be null",
			"seriesTipoDocumentoNaoDaParaComunicarAT": "The type of document is not subject to signature and therefore cannot be communicated to AT",
			"seriesTipoDocumentoNaoDaParaVerLogAT": "The type of document is not subject to signature and therefore does not have a communication log to the AT",
			"seriesTipoDocumentoNaoDaParaConsultarComunicacaoAT": "The type of document is not subject to signature, so it is not possible to consult the communication to AT",
			"atAFEnquadramentoRequired": "Framing is mandatory",
			"nContaRequired": "Account number is mandatory"
		},
		"btn": {
			"comunicarSeries": "Communicate billing series to AT",
			"logSeries": "LOG of communication from series to AT",
			"consultarSeries": "Consult series on AT"
		},
		"modal": {
			"comunicacao": {
				"title": "Communication of billing series to AT",
				"selecionar": "Select",
				"enquadramentoDesperdicios": "Line i) of paragraph 1 of article 2 of CIVA - Waste, waste and recyclable scrap sector",
				"enquadramentoCortica": "Line m) of paragraph 1 of article 2 of CIVA - Purchasers of cork, wood, pine cones and pine nuts in shell",
				"atSeriesLinkTooltip": "Registration of series on the finance portal",
				"fields": {
					"serie": "AT Series",
					"tipoSerie": "Series type",
					"classeDoc": "Document Class",
					"tipoDoc": "Document Type",
					"numInicialSeq": "Start of sequence",
					"dataInicioPrevUtiliz": "Expected start of use",
					"seqUltimoDocEmitido": "Last doc.",
					"codValidacaoSerie": "Validation code (ATCUD)",
					"estado": "State of the series",
					"meioProcessamento": "Processing medium",
					"numCertificado": "Certificate number",
					"nifFornecedor": "Supplier's NIF",
					"enquadramento": "Framework"
				},
				"btn": {
					"finalizar": "End series",
					"registarWebService": "Communicate via webservice",
					"registarManual": "Communicate manually"
				},
				"finalizar": {
					"title": "Finalize series communication",
					"justificacao": "Justification",
					"erroJustificacaoVazia": "Justification cannot be null."
				},
				"autoFaturacaoComAcordoMessage1": "Self-billing series with agreement",
				"autoFaturacaoComAcordoMessage2": "To activate a self-invoicing series with an agreement on behalf of your national or foreign supplier, you must first do so on the finance portal in the appropriate area, entering the data we present.",
				"autoFaturacaoComAcordoMessage3": "After registering on the portal, you must enter the supplier's ATCUD and NIF in this form, and save.",
				"autoFaturacaoSemAcordoMessage1": "No-deal self-billing series",
				"autoFaturacaoSemAcordoMessage2": "To activate a self-invoicing series without an agreement on behalf of your supplier according to the intended framework, you must first do so on the finance portal in the appropriate area, entering the data we present.",
				"autoFaturacaoSemAcordoMessage3": "After registering on the portal, you must enter the ATCUD and the framework in this form, and make the recording."
			},
			"consulta": {
				"fields": {
					"serie": "Series",
					"tipoSerie": "Series type",
					"classeDoc": "Doc class.",
					"tipoDoc": "Doc type.",
					"numInicialSeq": "Initial number",
					"dataInicioPrevUtiliz": "Initial date",
					"seqUltimoDocEmitido": "Last no.",
					"meioProcessamento": "Processing medium",
					"numCertSWFatur": "SW Certificate No.",
					"codValidacaoSerie": "Validation code (ATCUD)",
					"dataRegisto": "Registration date",
					"estado": "state",
					"dataEstado": "Status date",
					"nifComunicou": "NIF communication"
				}
			},
			"logs": {
				"title": "",
				"fields": {
					"atDataInicioPrev": "Date/Time",
					"operacaoSTR": "Operation",
					"erro": "Error",
					"respostaCodigo": "Response code",
					"respostaMensagem": "Response message",
					"codValidacaoSerie": "Serial validation code",
					"atTipoSerie": "Series type",
					"atTipoSerieDescricao": "Series type (description)",
					"atClasseDoc": "Doc class.",
					"atClasseDocDescricao": "Doc class.",
					"atTipoDocSTR": "Doc type.",
					"atEstadoSerie": "State series",
					"atEstadoSerieDescricao": "Series status (description)"
				}
			},
			"seriesPorComunicarAT": {
				"title": "Communication of Documentary Series to AT",
				"fields": {
					"nDocfa": "Doc type.",
					"nNumer": "No.",
					"nome": "Doc type name.",
					"tipoDocSaft": "SAFT type",
					"numAtual": "Current numbering",
					"numInicial": "Initial numbering",
					"dataInicio": "Start date"
				},
				"btn": {
					"comunicarseriesAT": "Communicates via Web Service"
				}
			}
		}
	},
	"docfasnumscomuncicacaologin": {
		"title": "Login Series Communication",
		"saveTitle": "Save",
		"fields": {
			"username": "Username (NIF/UserID)",
			"password": "Password"
		},
		"messages": {
			"success": "Login successfully.",
			"errorUsernameEmpty": "Empty username.",
			"errorPasswordEmpty": "Empty password.",
			"noPermissions": "Warning: To change values ​​you must have administrator permissions!"
		}
	},
	"docsComerciaisEstatisticas": {
		"title_plural": "Sales Statements",
		"pesquisa": "To look for",
		"verMaisFiltros": "See more filters",
		"verMaisFiltrosHide": "Hide filters",
		"tabs": {
			"geral": "General",
			"cubo": "Cube"
		},
		"fields": {
			"nDocStr": "Doc.",
			"nArtigo": "Article",
			"artigo": "Article",
			"nArtigoPivot": "Article No.",
			"nomeArtigo": "Name Article",
			"nConta": "No.",
			"contaNome": "Name",
			"nif": "Nif",
			"dataDoc": "Doc date.",
			"liquido": "Liquid",
			"valorIva": "VAT value",
			"totalLin": "Total",
			"sinal": "Signal",
			"liquidoMovimento": "Liquid Mov.",
			"valorIvaMovimento": "VAT Mov.",
			"totalMovimento": "Total Mov.",
			"qtd1": "Qty.",
			"prVendaQtd1": "Unit price.",
			"d1": "Desc.",
			"totalDocumento": "Total Doc.",
			"totalLiquido": "Net Total",
			"totalIliquido": "Total Illiq.",
			"totalIva": "Total VAT",
			"totalDesconto": "Total Discount",
			"taxaRet": "Retention Rate",
			"retencaoIncidencia": "Ret Incidence.",
			"retencaoValor": "Ret Value.",
			"nCCusto": "Code C. Cost",
			"centroCusto": "Cost center",
			"nFactFornec": "External Doc",
			"dataDocExterno": "External Doc Date",
			"nVendedor": "Seller Code",
			"nomeVendedor": "Seller",
			"nDocfa": "Doc Type Code.",
			"docfaNome": "Document Type",
			"nDepart": "Family Gr. Code",
			"nomeDepart": "Big family",
			"nFamil": "Family Code",
			"nomeFamilia": "Family",
			"nSubfa": "Sub-family code",
			"nomeSubFamilia": "Subfamily",
			"tipoArtigo": "Article Type Code",
			"nomeTipoArtigo": "Article Type",
			"ano": "Year",
			"mes": "Month",
			"nRefProcesso": "Process Code",
			"nomeProcesso": "Process",
			"totalDocumentoMovimento": "Total Doc. Mov.",
			"totalLiquidoMovimento": "Net Total",
			"totalIliquidoMovimento": "Total Illiq.",
			"totalIvaMovimento": "Total VAT Mov.",
			"totalDescontoMovimento": "Total Discount",
			"valorEmAberto": "Open value",
			"estado": "state"
		},
		"caption": {
			"ncontade": "From no.",
			"nartigode": "From code.",
			"tipoartigode": "By article type",
			"ngrfamiliade": "From a big family",
			"nfamiliade": "Family's",
			"nsubfamiliade": "From sub-family",
			"classede": "Class",
			"categoriade": "By category",
			"datade": "From date doc.",
			"nvendedorde": "From seller",
			"codzonade": "By zone",
			"ccustode": "From c.",
			"nrefprocessode": "From no.",
			"artigoCaption": "Article",
			"clienteCaption": "Client",
			"docCaption": "Document",
			"socommovimstock": "Only items that move stock",
			"docFa": "Types of documents",
			"obter": "To obtain",
			"printModalTitle": "Commercial Management Statistics",
			"ididechavde": "Account group",
			"cliclde": "Class"
		},
		"info": {
			"erroDataDeMaiorQueAte": "The From date is greater than the To date",
			"docPago": "Paid out",
			"docPagoParcial": "Partially paid",
			"docPorPagar": "Unpaid"
		},
		"configs": {
			"showFilterTipoArtigo": "Show filter by article type",
			"showFilterGrFamilia": "Show filter by large family",
			"showFilterFamilia": "Show filter by family",
			"showFilterSubFamilia": "Show filter by subfamily",
			"showFilterClasseArtigo": "Show filter by article class",
			"showFilterCategoriaArtigo": "Show filter by article category",
			"showFilterSoComMovimStock": "Show filter only items that move stock",
			"showFilterConta": "Show filter by account",
			"showFilterVendedor": "Show filter by seller",
			"showFilterZona": "Show filter by zone",
			"showFilterCentroCusto": "Show filter by cost center",
			"showFilterProcesso": "Show filter by process",
			"showFilterDocfaList": "Show filter by document type",
			"showFilterNArtigo": "Show filter for article",
			"showFilterClasse": "Show filter by class",
			"showFilterGrupoConta": "Show filter by account group"
		},
		"errors": {
			"naoExisteTipoDocSelecionado": "There is no document type selected."
		},
		"resumo": {
			"ndocs": "No.",
			"totalLiquido": "Total Net:",
			"totaliva": "Total VAT:",
			"total": "Total:"
		}
	},
	"docsPartilhados": {
		"title_detail": "HR platform document {{id}}",
		"title_new": "New HR platform document",
		"title_plural": "HR Platform Documents",
		"pesquisa": "To look for",
		"saved": "HR platform document {{id}}, saved successfully",
		"error": "HR platform document {{id}}, cannot be saved.",
		"fields": {
			"cab": {
				"docId": "Doc. Id.",
				"nome": "Name",
				"dataCriacaoReg": "Date",
				"horaCriacaoReg": "Hour",
				"visualizado": "Viewed",
				"tamanho": "Size",
				"extensao": "Extension"
			}
		},
		"docs": {
			"title": "Document"
		},
		"uploadmodal": {
			"titlenew": "Send files to the accountant",
			"dataRef": "File reference date"
		},
		"info": {
			"apagardocumentos": "Delete documents",
			"temCertezaApagarDocumentos": "Are you sure you want to delete the selected documents?",
			"emailParaGabSucess": "Email sent to office successfully",
			"fileUploadSuccess": "File uploaded successfully"
		},
		"toolbar": {
			"all": "All",
			"year": "Year: {{year}}",
			"month": "Month: {{month}}"
		},
		"btn": {
			"enviarFicheiros": "Send files"
		}
	},
	"guias": {
		"title_detail": "Guide {{id}}",
		"title_new": "New tab",
		"title_plural": "Guides",
		"saved": "Guide {{id}} saved successfully.",
		"error": "The Guide cannot be saved.",
		"toolbar": {
			"btnConfigAt": ""
		}
	},
	"vendas": {
		"title_detail": "Sell ​​{{id}}",
		"title_new": "New Sale",
		"title_plural": "Sales",
		"saved": "Sell ​​{{id}} successfully saved.",
		"error": "The Sale cannot be saved."
	},
	"compras": {
		"title_detail": "Purchase {{id}}",
		"title_new": "New buy",
		"title_plural": "Shopping",
		"saved": "Purchase {{id}} saved successfully.",
		"error": "Purchase cannot be saved."
	},
	"entradasdiversas": {
		"title_detail": "Miscellaneous Entry {{id}}",
		"title_new": "New Miscellaneous Entry",
		"title_plural": "Miscellaneous Entries",
		"saved": "Miscellaneous entry {{id}} saved successfully.",
		"error": "Miscellaneous Entry cannot be saved."
	},
	"saidasdiversas": {
		"title_detail": "Miscellaneous Output {{id}}",
		"title_new": "New Diverse Output",
		"title_plural": "Miscellaneous Outputs",
		"saved": "Miscellaneous Output {{id}} saved successfully.",
		"error": "Miscellaneous Output cannot be saved."
	},
	"propostasaclientes": {
		"title_detail": "Proposal/budget {{id}}",
		"title_new": "New proposal/budget",
		"title_plural": "Proposals to Customers",
		"saved": "Proposal to Client {{id}} saved successfully.",
		"error": "The Client Proposal cannot be saved."
	},
	"encomendasclientes": {
		"title_detail": "Customer order {{id}}",
		"title_new": "New customer order",
		"title_plural": "Customer Orders",
		"saved": "Customer order {{id}} successfully saved.",
		"error": "The Customer Order cannot be saved."
	},
	"encomendasfornecedores": {
		"title_detail": "Order supplier {{id}}",
		"title_new": "New supplier order",
		"title_plural": "Orders Suppliers",
		"saved": "Order Supplier {{id}} saved successfully.",
		"error": "The Supplier Order cannot be saved."
	},
	"guiasfornecedores": {
		"title_detail": "Guide {{id}}",
		"title_new": "New tab",
		"title_plural": "Guides",
		"saved": "Guide {{id}} saved successfully.",
		"error": "The Guide cannot be saved.",
		"toolbar": {
			"btnConfigAt": "AT Login for Communication of Transport Documents"
		}
	},
	"comprasautofaturacao": {
		"title_detail": "Self-billing Doc {{id}}",
		"title_new": "New doc.",
		"title_plural": "Self-billing",
		"saved": "Self-billing document {{id}} saved successfully.",
		"error": "The doc."
	},
	"docscomerciais": {
		"addLine": "Add line",
		"title_detail": "Document {{id}}",
		"title_new": "New Document",
		"title_plural": "Commercial Documents",
		"pesquisa": "To look for",
		"saved": "Commercial Document {{id}} saved successfully.",
		"error": "The Commercial Document cannot be saved.",
		"fields": {
			"nDocFa": "Doc type.",
			"nNumer": "Series",
			"nDocumento": "No.",
			"nDocumentoDraft": "No.",
			"nDoc": "Doc no.",
			"nDocFilter": "Doc number (Ex.: 1.23.10)",
			"nome": {
				"clifos": "Name",
				"clientes": "Client",
				"fornecedores": "Supplier"
			},
			"nConta": "Account",
			"nif": "Taxpayer",
			"dataDoc": "Date",
			"estado": "state",
			"porPagar": "Unpaid",
			"porReceber": "For receiving",
			"totalDocumento": "Total",
			"stampUpdate": "Last.",
			"modoEnvio": "Send mode",
			"estadoEnvioStr": "Shipping status",
			"nomeMoeda": "Coin",
			"transformado": "Transformed",
			"nFactFornec": "External doc.",
			"dataDocExterno": "Doc date.",
			"observacoes": "Comments",
			"anulado": "Voided",
			"dataVenc": "Expiration date"
		},
		"toolbar": {
			"importDoc": "Import Document",
			"meiosPagamento": "Payment options",
			"calcular": "Calculate",
			"arredondamentoManual": "Manual Rounding",
			"alterarCamposSerie": "Change fields in series",
			"rastreabilidade": "Traceability",
			"preView": "Preview/Draft",
			"salvageLastDoc": "Retrieve last document",
			"guardarRascunho": "Save Draft",
			"finalizarDocumento": "Finalize Document",
			"transformacaoDocumentos": "Document Transformation",
			"arquivoDigital": "Digital Archive"
		},
		"doc": {
			"cab": {
				"nDocFa": "Document type:",
				"nNumer": "Series:",
				"dataDoc": "Document date:",
				"nDocumento": "No.",
				"nDocumentoDraft": "No.",
				"nConta": "Account:",
				"nome": "Name:",
				"morada": "Household:",
				"codPostal": "Postal code:",
				"nif": "Contributor:",
				"totalLiquido": "Incidence:",
				"totalIva": "VAT:",
				"total": "Total document:",
				"totalDesconto": "Discounts:",
				"totalIliquido": "Subtotal:",
				"taxaRet": "Rate:",
				"retencaoIncidencia": "Incidence:",
				"retencaoValor": "Value:",
				"observacoes": "Comments:",
				"nFactFornec": "External Doc.:",
				"dataDocExterno": "External Doc. Date:",
				"dataInicTran": "Transport start date",
				"horaInicTran": "Transport start time",
				"modoEnvio": "Shipping mode:",
				"estadoEnvio": "Shipping status:",
				"estadoEnvioStr": "Shipping status:",
				"dataEstado": "Status date:",
				"horaEstado": "Status time:",
				"returnCode": "AT response code:",
				"returnMessage": "AT response message:",
				"atDocCodeID": "AT Code:",
				"podeFazerDevolucao": "You can make a return",
				"isDevolucao": "It's a return",
				"nCCusto": "No.",
				"dataVenc": "Expiration date:",
				"codMoeda": "Currency code:",
				"nomeMoeda": "Coin",
				"cambio": "Exchange:",
				"nRefProcesso": "Process:",
				"comunicadoAoEFatura": "Shipping status",
				"tipoComunicacao": "Send mode",
				"ctRefExterna": "Contract",
				"acordoQuadro": "Framework agreement",
				"compromisso": "Commitment",
				"nRequisicao": "No.",
				"nDocGlobalOrigem": "Global Doc:",
				"nCondPagam": "Payment conditions:"
			},
			"linhas": {
				"nArtigo": "Article",
				"nome": "Name",
				"qtd": "Qty.",
				"qtdStock": "Stock qty",
				"preco": "Price",
				"codIva": "VAT code",
				"taxaIva": "VAT %",
				"desconto": "Discount %",
				"d1": "Desc.",
				"d2": "Desc.",
				"d3": "Desc.",
				"d4": "Desc.",
				"d5": "Desc.",
				"d6": "Desc.",
				"valorComIva": "Value including VAT",
				"nCCusto": "C. cost",
				"valorIva": "VAT value",
				"nRefProcesso": "Process",
				"nArmazem": "Storage",
				"nVendedor": "Seller",
				"codUnidMov": "Items.",
				"valorSemIVA": "Value excluding VAT",
				"unidMovAbrev": "Items.",
				"liquido": "Liquid",
				"descEmValor": "Desc.",
				"dataEntregaPrev": "Expected delivery date",
				"nSeq": "No."
			},
			"tabela": {
				"tooltipCellNomeArtigo": "Add description"
			},
			"iva": {
				"taxa": "Rate",
				"incidencia": "Incidence",
				"valorIva": "VAT value"
			},
			"clifo": {
				"nConta": "No.",
				"nIdAltern": "Alternate ID:",
				"nome": "Name:",
				"nContribuint": "NIF:",
				"rua": "Road:",
				"codPostal": "Postal Code:",
				"localidade": "Location:",
				"telefone": "Telephone:",
				"fax": "Fax:",
				"nomePais": "Country:",
				"email": "Email:",
				"situacao": "Situation: {{situation}}"
			},
			"moradaCarga": {
				"rua": "Road",
				"codPostal": "Postcode",
				"localidade": "Location",
				"observacoes": "Note.",
				"morada": "Street (Continued)",
				"codPais": "Country Code"
			},
			"moradaDescarga": {
				"rua": "Road",
				"codPostal": "Postcode",
				"localidade": "Location",
				"observacoes": "Note.",
				"morada": "Street (Continued)",
				"codPais": "Country Code",
				"moralId": "Alternative address"
			},
			"transportador": {
				"nGuiaTransporte": "No.",
				"dataGuiaTransporte": "Date Guide",
				"matricula": "Registration",
				"matriculaReboque": "Trailer registration",
				"nomeTransportador": "Carrier Name",
				"nomeViatura": "Vehicle Name",
				"nomeMotorista": "Driver Name",
				"moradaMotorista": "Driver Address",
				"codPostalMotorista": "Driver Postcode",
				"codPaisMotorista": "Driver Country Code"
			},
			"novo": {
				"cliente": "Client",
				"observacoes": "Comments",
				"docExterno": "External Doc"
			}
		},
		"info": {
			"comunicacaoFaturaAT": "Invoice communicated to AT successfully.",
			"comunicacaoDocTransporteAT": "Doc. Transport successfully communicated to AT.",
			"inserirCodComunicacaoAT": "Telephone code entered successfully.",
			"anularDocumento": "Document canceled successfully.",
			"notaCreditoSucesso": "Return made successfully.",
			"comunicadoAoEFatura": "Document communicated to AT, cancellation not permitted using Return",
			"comunicadoAT": "AT statement",
			"transformarDocumento": "Document transformed successfully.",
			"duplicarDocumento": "Document duplicated successfully.",
			"copiarLinhas": "Document lines copied successfully.",
			"meiosPagamentoVazios": "Document value not assigned to payment methods",
			"meiosPagamentoOk": "Assigned payment methods",
			"encerrarDocumento": "Document completed successfully",
			"desmarcadoDocTransformado": "Document marked as untransformed",
			"transformarLinhasDocumento": "Document lines transformed successfully."
		},
		"erros": {
			"comunicacaoAT": "Error communicating to AT.",
			"notselected": "No documents selected.",
			"emptycodAT": "Empty AT code.",
			"anularDocumento": "Error canceling the document.",
			"emptyMotivo": "Reason for cancellation empty.",
			"inserirCodComunicacaoAT": "Error entering the AT code.",
			"notaCreditoErro": "Error when making a return.",
			"emptyMotivoNotaCredito": "Reason for return empty.",
			"anulacaoFisica": "Physical cancellation",
			"transformarDocumento": "Error transforming the document.",
			"docJaTransformado": "The document has already been transformed.",
			"docAnulado": "The document is cancelled.",
			"naoCriouPastaDoc": "Error creating the document folder in the Commercial Management folder",
			"anexarGDoc": "Error attaching file(s)",
			"duplicarDocumentoErro": "Error duplicating document",
			"copiarLinhas": "Error copying lines",
			"duplicarDocumentoNDocFaNulo": "Document type cannot be null",
			"duplicarDocumentoNContaNulo": "The account number cannot be null",
			"docSemLinhas": "The document has no lines",
			"docSemValorLinhasMP": "The document has no value to distribute through payment methods",
			"meiosPagam": "Error showing payment methods",
			"editarDocumento": "Error editing document",
			"encerrarDocumento": "Error finalizing document",
			"desmarcadoDocTransformado": "Error marking document as not transformed",
			"moralInvalidSelected": "You have not selected an existing alternative address.",
			"tipoDocNaoIntegraStock": "Document type is not included in stocks.",
			"noSelArtigos": "You must select at least one item."
		},
		"auxiliares": {
			"motivoNotaCredito": "Reason for return",
			"motivo": "Reason",
			"motivoTitle": "Reason for cancellation",
			"anular": "Cancel",
			"anexarDocumento": "Attach document",
			"duplicarDocumento": "Duplicate document",
			"copiarLinhasDocumento": "Copy lines",
			"meiosPagamento": "Payment options",
			"documento": "Document",
			"copiaDocumentoLinhas": "Document/Lines Copy",
			"importarLinhasTemplate": "Import Lines – Template",
			"calcular": "Calculate",
			"arredondamentoManual": "Manual Rounding",
			"alterarCamposSerie": "Change fields in series",
			"rastreabilidade": "Traceability",
			"gestaoEncomendas": "Pending Orders",
			"selecionarDocumento": "Select Document",
			"saldoEmAberto": "Outstanding balance:",
			"anularDocumento": "Cancel Document",
			"tooltips": {
				"addDescription": "Add description",
				"copiaDocumentoLinhasTooltip": "Allows you to duplicate a document or copy just the lines",
				"importarLinhasTemplateTooltip": "Allows you to import a template prepared for a spreadsheet",
				"pesquisaDocsGlobais": "Global document search",
				"limparCampo": "Clear field",
				"obterUltimoDocumentoGlobalCriado": "Get the last global document created",
				"atualizarCambio": "Update exchange rate for document date",
				"comprasDataDoc": "Date of Registration in the System",
				"comprasNDocExterno": "External Reference printed in the Document",
				"comprasDataDocExterno": "External Date printed on the Document"
			}
		},
		"codATPerPhone": "AT document communication",
		"markAsCompleted": "Mark as \"Completed\"",
		"configs": {
			"showObservacoes": "Show observations",
			"showDocExterno": "Show doc.",
			"showDataDoc": "Show date doc.",
			"showDataDocExterno": "Show date doc.",
			"showDataVenc": "Show due date",
			"showCCusto": "Show cost center",
			"showMoeda": "Show currency",
			"showNRefProcesso": "Show process",
			"showDescontos": "Show discount columns",
			"showNDoc": "Show document number",
			"showNArmazem": "Show warehouse",
			"showCodUnidMov": "Show movement unit",
			"showValorCIva": "Show value including VAT",
			"showValorSIva": "Show value excluding VAT",
			"showNVendedor": "Show seller",
			"showNNumer": "Show series",
			"showNRequisicao": "Show request number",
			"showLiquido": "Show liquid",
			"showNDocExterno": "Show doc no.",
			"showD2": "Show discount 2",
			"showD3": "Show discount 3",
			"showDescEmValor": "Show desc.",
			"showDiferimentos": "Show deferrals",
			"showPerguntaSeFinalizaDoc": "Show completion confirmation",
			"obtemDadosDocDigital": "Gets data from the digital document"
		},
		"actions": {
			"emitirRecibo": "Issue Receipt",
			"pdf": "PDF",
			"duplicarDoc": "Duplicate Doc.",
			"comunicarDocAt": "Communicate AT",
			"editarDocumento": "To edit",
			"encerrarDocumento": "Finish",
			"artigos": "Open article",
			"rastreabilidade": "Traceability",
			"lotes": "View lots",
			"adiantRegDocAtual": "Advances - Register in doc.",
			"adiantRegDocNew": "Advances - Register in new document.",
			"adiantRegDoc": "Register Advance",
			"adiantConsult": "Regularize Advances",
			"movimentosEmAberto": "Open Moves",
			"extratoConta": "Account Statement",
			"txtFaturacao": "Billing Notes",
			"emitirPagamento": "Issue Payment"
		},
		"btn": {
			"importDoc": "Import Document",
			"meiosPagam": "Payment options",
			"addMeiosPagam": "Add Payment Methods",
			"closeMeiosPagam": "Close Payment Methods",
			"calculate": "Calculate",
			"arredManual": "Manual Rounding",
			"altCamposSerie": "Change fields in series",
			"rastreabilidade": "Traceability",
			"save": "Save",
			"saveDraft": "Sketch",
			"savePdf": "PDF",
			"anexarDoc": "Attach",
			"cancelar": "Cancel",
			"gestaoDocsEditaveis": "Editable document management",
			"encerrarDocumentos": "Finalize documents",
			"anularDocumentos": "Cancel documents",
			"diferimentos": "Deferrals",
			"finalizar": "Finish",
			"anular": "Cancel",
			"gestaoRascunhos": "Draft management"
		},
		"tabs": {
			"carga": "Charge",
			"descarga": "Discharge",
			"transportadora": "Shipping company",
			"faturacaoPublica": "Public Billing",
			"linhas": "Lines",
			"documentos": "Documents",
			"docsEditaveis": "Editable Documents",
			"gestaoenc": "Pendants",
			"porProcessar": "To be processed",
			"processado": "Processed",
			"transporte": "Transport",
			"anexos": "Attachments",
			"finalizados": "Finalized",
			"rascunho": "In Draft"
		},
		"modals": {
			"saveDoc": "Save document",
			"gestaoDocsEditaveis": "Editable document management",
			"saveTitleOkCancel": "Information",
			"saveMessageOkCancel": "This action is irreversible.",
			"info": {
				"naoMostraSaveMsgOkCancel": "Do not show this question again"
			},
			"anexosPromptSaveDraft": "To attach the file, you need to save the document."
		},
		"questions": {
			"confirmSave": "Are you sure you want to save the document?"
		},
		"legend": {
			"anulado": "Voided"
		},
		"importadorLinhas": {
			"modalTitle": "Row Importer",
			"extraFields": {
				"nomeartigo": "Description Article",
				"cunimo": "Motion Unit",
				"narmazem": "Storage",
				"nvendedor": "Seller",
				"nccusto": "Cost center",
				"nrefprocesso": "Process"
			},
			"steps": {
				"welcome": {
					"title": "Welcome",
					"text1": "Welcome to the row import wizard.",
					"text2": "Can select extra fields for export"
				},
				"downloadModel": {
					"title": "Download template",
					"text1": "Please click the button below to download the line import template.",
					"text2": "After downloading the file, fill it in with the data you want to import.",
					"text3": "Attention: You must have spreadsheet editing software.",
					"downloadButton": "Download import template"
				},
				"uploadModel": {
					"title": "Upload completed template",
					"text1": "Fill in the file with the data you want to import.",
					"text2": "After filling in the file, upload the file to continue importing."
				},
				"resumo": {
					"title": "Summary",
					"text1": "Line import finished.",
					"text2": "{{total}} rows were imported.",
					"text3": "At least one error occurred while importing rows",
					"listaErros": "List of errors"
				}
			},
			"promptTitle": "Confirmation",
			"promptMsg": "It has import data in memory."
		},
		"artigosAdvancedSearch": {
			"title": "Advanced article search"
		},
		"clifo": {
			"action": {
				"newContaCorrente": "A new Current Account will be created",
				"newContaOcasional": "A new Occasional Account will be created",
				"contaCorrente": "Current Account",
				"contaOcasional": "Occasional Account"
			}
		}
	},
	"encomendas": {
		"title": "Orders",
		"btn": {
			"initProce": "Process"
		},
		"header": {
			"clientes": "Customers",
			"artigos": "Articles",
			"dataDoc": "Document date",
			"dataPrevEnt": "Expected delivery date",
			"ate": "Until",
			"clifoPlaceHolder": "Select a customer/supplier to process",
			"fornecedorPlaceHolder": "Select a supplier to process",
			"clientePlaceHolder": "Select a customer to process"
		},
		"table": {
			"nDoc": "Order no.",
			"dataDoc": "Doc date.",
			"nArtigo": "Article code",
			"nomeArtigo": "Article",
			"nClifo": "Account no.",
			"nomeClifo": "Account",
			"nArmaz": "Storage",
			"nomeArmaz": "Warehouse name",
			"prVendaQtd1": "Price",
			"qtdStockNoArmazemOrigem": "Stock",
			"qtd1": "Commissioned",
			"qtd1Fact": "Processed",
			"ligacaoQtd": "Processing",
			"dataEntregaPrev": "Expected delivery date",
			"nRequisicao": "Request",
			"nRefProcesso": "Process",
			"nCCusto": "cost center"
		},
		"badges": {
			"partSatif": "Partially processed",
			"totalSatif": "Fully processed",
			"linhaTextLivre": "Line with free text",
			"satisfMais": "Over processed"
		},
		"actions": {
			"satTotal": "Total quantity (F9)",
			"satTotalArtigo": "Total quantity in all lines (Article)",
			"satTotalDocumento": "Total quantity on all lines (Document)",
			"satLinhaSemFat": "Process line without invoicing (F7)",
			"limparQtdSat": "Clear quantity to process",
			"limparQtdSatTodas": "Clear quantity to process from all lines",
			"selecionarClifo": "Select {{cliph}}",
			"verDocumento": "View document",
			"satisfClifo": "Process {{clip}}",
			"showArtigos": "Show articles",
			"showArmazens": "Shows warehouses",
			"showDocFas": "Show document types",
			"verDadosClifo": "View processed from {{clifo}}",
			"showTextoLivre": "Show free text"
		},
		"modal": {
			"btns": {
				"finalize": "Finish",
				"finalizePreviewDoc": "Finish and view document",
				"finalizeEditarDoc": "Finalize and edit document",
				"editDoc": "Edit document",
				"preencherAuto": "Auto fill batches",
				"lotesOk": "OK"
			},
			"processamento": {
				"title": "Order processing",
				"steps": {
					"ident": "Identification",
					"carga": "Charge",
					"descarga": "Discharge",
					"lote": "Lots",
					"preview": "Summary"
				},
				"fields": {
					"nDocFa": "Document type",
					"nNumer": "Series",
					"cliente": "Client",
					"fornecedor": "Supplier",
					"clifos": "Customers/Suppliers",
					"dataDoc": "Document date",
					"dataInicTran": "Home transport",
					"data": "Date",
					"morada": "Household",
					"codPostal": "Postal Code",
					"localidade": "Location",
					"codPais": "Country",
					"moradaAlt": "Alternative address",
					"observacoes": "Comments",
					"horaInicTran": "Transport start time"
				},
				"messages": {
					"proceSuccess": "Processing completed successfully.",
					"atribuirLotes": "You must assign batches to lines with items with batches in order to simulate.",
					"notAutoSelLotesEntradaStock": "It is not possible to automatically select lots in a stock entry.",
					"naoTemLinhas": "There are no lines to process.",
					"naoTemLinhasComLotes": "There are no rows with lots to auto-populate."
				}
			},
			"documento": {
				"title": "Document"
			},
			"impressao": {
				"title": "Print",
				"fields": {
					"nContaDe": "From account number",
					"dataDocDe": "From date doc.",
					"dataEntregaPrevDe": "From expected date.",
					"estadoEncomendas": "Order status",
					"armazens": "Warehouses",
					"docfaclist": "Document types"
				},
				"estado": {
					"soPendentes": "To be processed",
					"soSatisfeitas": "Processed",
					"todas": "All"
				},
				"filtros": {
					"todos": "All"
				}
			},
			"simulacao": {
				"title": "Simulation",
				"fields": {
					"nConta": "Account no.",
					"nome": "Name",
					"totalIva": "VAT",
					"totalLiquido": "Liquid",
					"totalDocumento": "Total"
				}
			},
			"satisfazerclifo": {
				"title": "Pending orders"
			},
			"lotes": {
				"title": "Lot selection",
				"messages": {
					"existemLinhasSemLotesAtribuidos": "There are lot(s) without quantity to be processed filled for the order(s)",
					"qtdLotesNaoEIgualLinha": "The quantity ({{qtdLotes}}) of the article {{nArtigo}} distributed among the lots is not the same as that of the line: {{ligacaoQtd}}."
				}
			}
		},
		"messages": {
			"semLinhasSati": "There is no quantity to process filled for the order(s).",
			"atribuicaoDeLotesCancelada": "Batch assignment in order processing cancelled.",
			"processamentoLotesEncomendaFalhou": "Batch processing on order(s) failed.",
			"infoProcessamentoClifo": "It is necessary to search and then select a {{clifo}} below or in the grid to process orders",
			"linhasComValorNulo": "There are lines with a null value in the quantity to be processed"
		},
		"errors": {
			"clientenaoselecionado": "Enter {{clifo}} to process pending orders",
			"valorAProcessarNulo": "The value to be processed cannot be null"
		},
		"keyboardShortcuts": {
			"processarTotalmente": "Enter total quantity to process from the order line",
			"processarLinhaSemFaturar": "Process order line without invoicing"
		}
	},
	"documentoFaturacao": {
		"btn": {
			"communicateFaturaAT": "Communicate AT",
			"communicateDocTransporteAT": "Communicate Doc. Transp.",
			"insertATCode": "Enter AT Code",
			"notaCredito": "Devolution",
			"anular": "Cancel",
			"transformarDoc": "Transform Doc.",
			"transformarLinhasDoc": "Transform Lines",
			"pdf": "PDF",
			"opcoes": "options",
			"actions": "Actions",
			"anexos": "Attachments",
			"duplicarDoc": "Duplicate Doc.",
			"copiarLinhas": "Copy Doc Lines",
			"meiosPagam": "Payment options",
			"editarDocumento": "To edit",
			"encerrarDocumento": "Finish",
			"desmarcadoDocTransformado": "Deselect Transformed"
		},
		"modals": {
			"encerrar": {
				"title": "Finalize document",
				"caracteres": "Characters: {{numChar}}/50"
			},
			"questions": {
				"confirmEncerrar": "Are you sure you want to end the document with no."
			}
		},
		"summary": "Summary",
		"retention": "Retention"
	},
	"grupoDoc": {
		"outros": "Others",
		"comprasEfetivas": "Effective Purchases",
		"consultasAFornecedores": "Supplier Inquiries",
		"encomendasClientes": "Customer Orders",
		"encomendasFornecedores": "Orders Suppliers",
		"folhasDeObra": "Work Sheets",
		"guiasTransporteRemessa": "Transport Shipping Guides",
		"propostasAClientes": "Proposals to Customers",
		"reservasClientes": "Customer Reservations",
		"trfArmazensEntradas": "Trf.",
		"trfArmazensSaidas": "Trf.",
		"vendasEfectivas": "Effective Sales",
		"consignacoesClientes": "Customer Consignments",
		"consignacoesFornecedores": "Consignments Suppliers",
		"recibosClientes": "Customer Receipts",
		"pagamentosFornecedores": "Supplier Payments",
		"none": "No Info",
		"planoProducao": "Production Plan",
		"preEncomendasClientes": "Pre-Orders Customers",
		"custosAdicionais": "Additional Costs",
		"notaCredito": "Credit Note",
		"guiasTransporteFornecedores": "Transport Guides Suppliers",
		"entradasDiversas": "Miscellaneous Entries",
		"saidasDiversas": "Miscellaneous Outputs"
	},
	"arredondamentoManual": {
		"title": "Commercial Document - Manual Rounding",
		"gridHeader": {
			"documento": "Document",
			"arredondamento": "Rounding"
		},
		"gridFields": {
			"codIva": "VAT code",
			"taxaIva": "Rate",
			"prVenda1": "Incidence",
			"valorIva": "VAT",
			"prVendaIvaInc": "Total",
			"arredLiquidoManualEditavel": "Incidence",
			"arredIVAManualEditavel": "VAT Arred.",
			"incidenciaTotalEditavel": "Incidence",
			"ivaTotalEditavel": "Total VAT"
		},
		"messages": {
			"warningMaxValue": "The intended rounding absolutely exceeds the maximum allowed ({{value}})",
			"totalIvaLiquido": "Manual rounding: Net {{totalLiquido}} and VAT {{totalIva}}",
			"totalLiquido": "Manual rounding: Net {{totalNet}}",
			"totalIva": "Manual rounding: VAT {{totalIva}}"
		},
		"resumo": {
			"totalIva": "Total VAT",
			"totalIncidencia": "Total Incidence",
			"total": "Total"
		}
	},
	"alterarCamposSerie": {
		"title": "Select fields to change"
	},
	"gestaoDocsComerciais": {
		"title": "Draft document management",
		"filters": {
			"nDocFa": "Type Doc.",
			"deDataDoc": "Doc. Date of",
			"ateDataDoc": "Until"
		},
		"fields": {
			"mensagemErro": "Message"
		},
		"messages": {
			"encerrarFullSuccess": "The documents were all completed successfully.",
			"encerrarSuccessWithErrors": "{{nDocsSuccess}} of the selected {{nDocs}} were finalized.",
			"encerrarError": "An error occurred while finalizing the documents, no documents were closed.",
			"anularFullSuccess": "The documents were all successfully annulled.",
			"anularSuccessWithErrors": "{{nDocsSuccess}} of the selected {{nDocs}} were cancelled.",
			"anularError": "An error occurred while canceling the documents, no documents were closed.",
			"anularExistemDocumentosEncerrados": "There are closed documents, do you intend to proceed with their annulment?",
			"existemDocumentosEncerrados": "There are selected documents that are already closed.",
			"naoExistemDocsSelecionados": "There are no documents selected.",
			"artigoNaoExiste": "Article does not exist.",
			"anularAviso": "This action is irreversible."
		},
		"legend": {
			"documentoComErro": "Document with error"
		}
	},
	"pesquisaDocumentos": {
		"title": "Document search"
	},
	"selecaoLotes": {
		"title": "Lot Selection",
		"tooltips": {
			"lote": "Lots"
		}
	},
	"devolucao": {
		"caption": {
			"artigoQuantidade": "Item Return",
			"artigoValor": "Article Discount",
			"documentoValor": "Document Discount",
			"documentoExterno": "External",
			"stepTipo": "Type",
			"documentoDestino": "Target document",
			"emQuantidade": "In Quantity",
			"emValor": "In Value",
			"tipoDocOrigem": "Source Document Type",
			"docExternoOrigem": "External Document (Source)",
			"dataDocExternoOrigem": "External Document Date (Source)",
			"tipoDocumento": "Document Type",
			"devolucao": "Devolution",
			"deArtigo": "From article",
			"ateArtigo": "Until article",
			"docExternoPlaceholder": "Example: FT 001/1",
			"artigoDesconto": "Discount item",
			"valorDocExterno": "Value (€)",
			"adicionar": "To add",
			"dataDoc": "Doc date.",
			"desdeDataDoc": "From the Document Date"
		},
		"messages": {
			"sucesso": "Return document generated successfully.",
			"escolherTipo": "Choose the type of return you want to make:",
			"erroTipoDoc": "You must select a source document type.",
			"erroDocExterno": "External Document (Origin) is mandatory.",
			"erroMotivo": "You must indicate the reason for the return.",
			"erroDocExternoValor": "The amount to be returned must be greater than zero.",
			"erroLinhas": "There are no lines selected.",
			"erroArtigoDevolucao": "You must indicate a return item.",
			"erroValor": "The value to be returned cannot be 0.",
			"erroValorSuperior": "The value inserted to regularize exceeds the value to be regularized (To be regularized: {{byRevolver}} | Inserted: {{aRevolver}}).",
			"devolucaoEditar": "This document already contains return lines.<br>Choose the option you want to perform."
		},
		"table": {
			"facliId": "FacliId",
			"nDoc": "Document",
			"porDevolver": "For returning",
			"aDevolver": "To be returned"
		},
		"tooltip": {
			"devolveTudo": "Return everything",
			"limpaTudo": "Clear all returned values",
			"limpaDevolvido": "Clear returned amount",
			"adicionarMotivo": "Add to reason list"
		},
		"placeholder": {
			"motivo": "Type or Search",
			"artigoParaDesconto": "Discount Article"
		}
	},
	"docscontabilidade": {
		"title_new": "New Document",
		"title_edit": "Document {{id}}",
		"title_plural": "Accounting Documents",
		"title_singular": "Accounting document",
		"pesquisa": "To look for",
		"goSearch": "Document search",
		"goSearchMobile": "Search",
		"clearForm": "Clear Fields",
		"saved": "Accounting document {{id}} saved successfully.",
		"error": "The accounting document cannot be saved.",
		"tooltipNDocumento": "This identifier is only suggestive, it may change after saving the document",
		"tooltipNextDocumento": "Next document for this period/diary<br>(Ctrl + Right arrow)",
		"tooltipPreviousDocumento": "Previous document from this period/diary<br>(Ctrl + Left arrow)",
		"tooltipFirstDocumento": "First document of this period/diary<br>(Ctrl + Shift + Left arrow)",
		"tooltipLastDocumento": "Last document of this period/diary<br>(Ctrl + Shift + Right arrow)",
		"fields": {
			"extPocCabID": "Document identifier",
			"periodo": "Period",
			"nomePeriodo": "Period",
			"nDiario": "Daily",
			"nomeDiario": "Daily",
			"nDocInterno": "Internal Doc. No.",
			"totalDebitoGeral": "Total Debit",
			"totalCreditoGeral": "Total Credit",
			"dataLancamento": "Accounting release date",
			"dataVencimento": "Due date",
			"dataDoc": "Document date",
			"codDescritivo": "Descriptive code",
			"nomeDescritivo": "Descriptive",
			"descricao": "Description",
			"codMoeda": "Coin",
			"nomeMoeda": "Coin",
			"nContribuinte": "VAT Number",
			"documentoExterno": "External doc.",
			"campoErroInformativo": "Error",
			"stampUpdate": "Last.",
			"nDocumento": "Document No.",
			"nDocumentoFilter": "Document No. (ex: 2024011.1.345)",
			"preDefinido": "Predefined",
			"anulado": "Voided",
			"nconta": "Account No.",
			"valor": "Value",
			"temDocDigital": "Digital Doc"
		},
		"doc": {
			"cab": {
				"extPocCabID": "Document identifier",
				"periodo": "Period",
				"nomePeriodo": "Period",
				"nDiario": "Daily",
				"nomeDiario": "Daily",
				"nDocInterno": "Internal Doc. No.",
				"totalDebitoGeral": "Total Debit",
				"totalCreditoGeral": "Total Credit",
				"dataLancamento": "Release date",
				"dataVencimento": "Due date",
				"dataDoc": "Document date",
				"codDescritivo": "Descriptive code",
				"nomeDescritivo": "Descriptive",
				"descricao": "Description",
				"codMoeda": "Coin",
				"nomeMoeda": "Coin",
				"nContribuinte": "Taxpayer",
				"documentoExterno": "External doc.",
				"campoErroInformativo": "Error",
				"stampUpdate": "Last.",
				"nDocumento": "Document No.",
				"totalCredidoBaseTributavel": "Total taxable base credit",
				"totalDebitoBaseTributavel": "Total taxable base debt",
				"totalCredidoIva": "Full VAT credit",
				"totalDebitoIva": "Total VAT debt",
				"cambioRef": "Exchange reference",
				"moeda": "Coin",
				"anulado": "Voided"
			},
			"linhas": {
				"nLanc": "Line identifier",
				"nLancImput": "Imputation identifier",
				"extPocCabID": "Document identifier",
				"nConta": "Account no.",
				"periodo": "Period",
				"nDiario": "Daily",
				"nDocInterno": "Internal Doc. No.",
				"nSeq": "Sequence",
				"dc": "Debit credit",
				"valor": "Value",
				"valorME": "ME Value",
				"codMovAberto": "Open movement code",
				"valorPago": "Amount paid",
				"valorPagoME": "Amount paid ME",
				"codMoeda": "Currency Code",
				"cambio": "Exchange",
				"nDescr": "Descriptive code",
				"dataLancamento": "Accounting release date",
				"dataDoc": "Document date",
				"dataVencimento": "Due date",
				"nDocExterno": "External doc.",
				"dataDocExt": "Doc date.",
				"descricao": "Description",
				"nContrib": "VAT Number",
				"codIva": "VAT code",
				"valorTaxa": "VAT value",
				"valorExistente": "{{type}} current",
				"valorOriginal": "{{type}} original",
				"valorTaxaME": "ME fee value",
				"cDecPer": "Periodic Dec.",
				"cDecAnual": "Annual Dec.",
				"codControloIva": "VAT Control Code",
				"nGrupo": "Group",
				"classificControlo": "Class.",
				"valorDesconto": "Discount value",
				"valorRetencao": "Retention value",
				"nUtilizador": "User number",
				"nLancOrigemIva": "nLancOrigemIva",
				"nLancDestinoIva": "nLancDestinoIva",
				"valorOrigemIva": "valueOriginIva",
				"valorDestinoIva": "valueDestinationVAT",
				"nLancOrigemRetencao": "nLancOrigemRetencao",
				"nLancDestinoRetencao": "nLancDestinoRetencao",
				"valorOrigemRetencao": "valueOriginRetention",
				"valorDestinoRetencao": "valueDestinationRetention",
				"codRetencao": "Retention code",
				"taxaRetencaoUsada": "Retention rate used",
				"nGrupoRetencao": "Retention grip",
				"classificControloRetencao": "Classification.",
				"tipoRetServNIF": "NIF retention type",
				"extPocLigaIDOrigemRetNaoDispo": "extPocLigaIDOrigemRetNaoDispo",
				"nLancOrigemImputacaoCCusto": "nLancOrigemImputacaoCCusto",
				"nLancDestinoImputacaoCCusto": "nLancDestinoImputacaoCCusto",
				"nContaDebito": "Debit Account",
				"nContaCredito": "Credit Account",
				"temMovAberto": "There are open moves",
				"poc": {
					"nConta": "Account No.",
					"nContaPlaceholder": "Account code",
					"nome": "Name",
					"nomePlaceholder": "Account name",
					"tipo": "Account type",
					"cc": "Have a current account",
					"nif": "VAT Number",
					"temCCusto": "Has analytics",
					"temIVA": "Has VAT control",
					"registaRetencao": "Register retention",
					"temRetencao": "Has retention",
					"bloqueada": "Blocked",
					"dpq06NCampo": "Periodic VAT Decree",
					"dpq06NCampoNome": "Periodic VAT Decree",
					"daq08NCampo": "Annual VAT Decree - Table 03 and 05",
					"daq08NCampoNome": "Annual VAT Decree - Table 03 and 05",
					"daq09NCampo": "Annual VAT Dec. - Table 04 and 05",
					"daq09NCampoNome": "Annual VAT Dec. - Table 04 and 05",
					"codControloIVADebito": "Debit VAT Control Code",
					"codControloIVADebitoNome": "Debit VAT Control Code",
					"codControloIVACredito": "VAT credit control code",
					"codControloIVACreditoNome": "VAT credit control code"
				},
				"dcDebito": "Debt",
				"dcCredito": "Credit"
			},
			"totais": {
				"saldo": "Balance",
				"baseTributavel": "Taxable Base",
				"totalIVA": "Total VAT",
				"moedaCorrente": "Currency",
				"moedaEstrangeira": "Foreign currency",
				"totalGeral": "Grand total",
				"porPagar": "Unpaid"
			},
			"tooltipDataLancamento": "3.4.3.8.",
			"tooltipDataDoc": "3.4.3.3."
		},
		"erros": {
			"notselected": "No documents selected!",
			"optionsNotAvailable": "Cannot change options when a preset is active",
			"changeMoedaNotAvailable": "To change the currency you must first delete all lines",
			"initDoc": "The document has not been initialized.",
			"notSaldado": "The document is not balanced!",
			"periodoFinalInferiorInicial": "The final period is shorter than the initial one!",
			"nifAlreadyRegistered": "The taxpayer number \"{{nContribuinte}}\" is already registered."
		},
		"configs": {
			"valorComIvaIncluido": "Value with VAT included",
			"obtemDadosDocDigital": "Gets data from the digital document",
			"showFieldPredefinido": "Show predefined field (header)",
			"showFieldDataVencimento": "Show due date field (header)",
			"showFieldMoeda": "Show Currency field (header)",
			"showFieldLinhaNif": "Show Taxpayer number field (lines)",
			"showFieldLinhaValorTaxa": "Show VAT Amount field (lines)",
			"showFieldLinhaDataDoc": "Show document date field (lines)",
			"showFieldLinhaDescricao": "Show Description field (lines)",
			"showFieldLinhaCDecPerAnual": "Show Periodic/Annual Declaration fields (lines)",
			"showFieldLinhaNomeConta": "Show Account Name field (rows)",
			"seletorObtemDadosDocDigital": "Show button to obtain digital document data in the lines section",
			"seletorValorComIvaIncluido": "Show button value with VAT included in the lines section",
			"skipDescricao": "Skip description field in lines",
			"retainDescription": "Maintain description between documents",
			"showFormMoeda": "Show foreign currency form",
			"skipPromptSave": "Do not ask for confirmation again when saving the document"
		},
		"changeValorTitle": "Change {{type}}",
		"changeValorTaxaTitle": "Change VAT amount",
		"changeCCustoTitle": "Assigning Analytics Values",
		"changeTitleInvalidLines": "You must register at least one value",
		"valorValidatorMessage": "The {{type}} has a maximum difference limit of {{value}}{{currency}} compared to the original {{type}}",
		"contaTemLigacaoContasCorrentes": "The {{nConta}} account is linked to current accounts.",
		"desejaEfetuarPagamentos": "Do you want to make document payments to current accounts?",
		"contaAImputarJaExiste": "The analytics account to be imputed {{nConta}} already exists in the imputation list!",
		"valorTemDeSerSuperior0": "The value must be greater than 0.",
		"disponibilidadeRendimento": "Income availability",
		"contaCorrenteTemRetencaoDeduzida": "The amount in the current account already has the retention deducted",
		"montanteTributavel": "Taxable Amount",
		"montanteRetido": "Withheld Amount",
		"nDocumento": "Document No.",
		"changeregularizacaocampo40Title": "Regularizations in favor of the taxpayer - Covered by Article 78",
		"nDocumentoRegularicacaoJaExiste": "Document number {{nDocument}} already exists",
		"valorSomadoJaExistente": "The value {{valor}} added to the existing value {{ValueExisting}} exceeds the value of the tax base {{ValueBase}}.",
		"documentoEliminadoComSucesso": "Document deleted successfully.",
		"diferimentosAnuladosComSucesso": "Deferrals/accruals successfully cancelled.",
		"viewDocImputado": "View imputed document",
		"viewDocTitle": "Document {{nDocument}}",
		"keyboardShortcuts": {
			"save": "Save the document",
			"saveAndAttach": "Save and attach the document",
			"toggleIva": "Toggle \"Value including VAT\"",
			"obterSaldoConta": "Get account balance (only in the \"Amount\" column of the selected line)",
			"firstDocumento": "First document of this period/diary",
			"lastDocumento": "Last document of this period/diary",
			"previousDocumento": "Previous document from this period/diary",
			"nextDocumento": "Next document for this period/diary"
		},
		"ccusto": {
			"badTotalTitle": "Confirmation of the Assignment of Analytics Values",
			"badTotalMessage": "The value allocated to the cost center is different from the total value to be allocated."
		},
		"movab": {
			"title": "Payments for documents in current accounts",
			"nDocumento": "Document No.",
			"dc": "A.D",
			"nDocExterno": "External doc.",
			"dataDoc": "Doc date.",
			"valor": "Value",
			"porPagar": "Unpaid",
			"valorPagar": "Amount to pay",
			"imputarTudo": "Impute all (all rows)",
			"imputarTudoLinha": "Impute all (line)",
			"limparValores": "Clear all imputed values",
			"limparValorLinha": "Clear value",
			"dcDebito": "D",
			"dcCredito": "W",
			"totalImputar": "Total to impute",
			"totalJaImputado": "Total already imputed",
			"totalImputado": "Total Imputed:",
			"descricao": "Description",
			"descritivo": "Descriptive",
			"totalConta": "Account Totals",
			"options": {
				"showDocExt": "Show External Document field",
				"showDataDoc": "Show Document Date field",
				"showDescricao": "Show field Description",
				"showDescritivo": "Show Description field"
			},
			"movmesmoid": "These entries belong to the same digital document.",
			"movmesmoidval": "These entries belong to the same digital document with the value {{value}}.",
			"valorPagarValidationInferior": "The value cannot be less than 0.",
			"valorPagarValidationSuperior": "The value cannot be higher than the amount to be paid."
		},
		"regularizacaoCampo40": {
			"title": "Cancel regularizations in favor of the taxpayer",
			"message": "Failure to indicate the regularization documents will create inconsistencies in the VAT declaration.",
			"validateMessage": "To continue, you must activate the option \"I have noticed and intend to continue\"",
			"label": "I found out and I intend to continue"
		},
		"retencao": {
			"title": "Retention to {{value}}",
			"imediata": "Immediate",
			"noPagamento": "On payment",
			"disabledRetencaoDeduzida": "To change this value, you must have a previously opened current account.",
			"modelo30": {
				"title": "Model 30",
				"nGuiaPagamento": "Payment Tab"
			},
			"messages": {
				"needCCBefore": "To make income available at the time of payment, you must first register the current account."
			}
		},
		"clearPreDefinido": "Remove predefined",
		"clearSuggestion": "Cancel suggestion",
		"clearSuggestionTitle": "Cancel launch suggestion",
		"moedaestrangeira": {
			"title": "Release in foreign currency",
			"conta": "Account",
			"moeda": "Foreign currency",
			"cambioME": "ME Exchange",
			"valorME": "ME Value",
			"valorNacional": "National value"
		},
		"toolbar": {
			"saveAndAttach": "Save and attach",
			"saveWithoutAttach": "Save without attaching",
			"contabilidadeDigital": "Accounting",
			"criarPreDefinido": "Create Predefined",
			"criarPreDefinidoTooltip": "Create a predefined accounting document based on this",
			"infoDiferimentos": "Deferral information",
			"infoGlobalDiferimentos": "Global deferral information",
			"infoGlobalAcrescimos": "Global addition information",
			"infoDiferimentosHint": "Accrual/deferral information",
			"anularDiferimento": "Cancel accrual/deferral"
		},
		"nDocSeq": "Seq. Doc. No.",
		"text": {
			"maintenancePreDefinidos": "Maintenance of pre-defined accounting",
			"maintenanceExtratos": "View account statements",
			"maintenanceMovab": "View open movements",
			"maintenanceClifos": "Customer/supplier maintenance",
			"recolha": "Collection",
			"conta": "Account: {{nAccount}} - {{name}}",
			"saldoConta": "Balance: {{saldoConta}}",
			"obterSaldoConta": "Get account balance",
			"modificarRegularizacoesSujeitoPassivo": "Regularizations in favor of the taxpayer",
			"calculodiferimentos": "Accruals and deferrals",
			"registarNif": "Register taxpayer number",
			"marcadoComoConsistente": "Document integrated with error and marked as consistent by {{name}} on {{date}}",
			"extratosconta": "Account statements",
			"mudarperiodocontab": "Change company accounting period",
			"confirmMudaPerio": "Are you sure you want to change the company's accounting period to \"{{period}}\"",
			"periodoempmudadosuccesso": "Company's accounting period successfully changed!",
			"docativosconfirm": "You have registered an asset in accounting.<br>Do you want to register the Asset Acquisition in the Asset Management module?",
			"editValorIva": "Edit VAT amount",
			"incValorIva": "Increase VAT value",
			"decValorIva": "Decrease VAT value",
			"calculouDiferimentos1": "The calculation of additions/deferrals was carried out and for this reason the document was blocked.",
			"calculouDiferimentos2": "click here to cancel the calculation",
			"calculouDiferimentos3": "You must click \"Save\" to apply the deferral to the document.",
			"calculouDiferimentosLocked": "Document with associated increase/deferral.",
			"confirmAnulaDiferimento": "Are you sure you want to cancel the deferral/addition?",
			"docImputacao": "Imputed to the document: {{ndocImput}}"
		},
		"prompt": {
			"exitTitle": "<i class='fa fa-exclamation-triangle text-warning' style='color: orange;'",
			"exitMessage": "Clicking OK will exit without saving the accounting document and all changes you made to this document will be deleted.",
			"doNotAskAgain": "Don't ask again",
			"perguntaCC": "Do not show again for this account when the signal is at {{sign}}."
		},
		"saveprompt": {
			"saveDocAndAttach": "Save document and attach digital documents",
			"saveDocIdAndAttach": "Save document {{id}} and attach digital documents"
		}
	},
	"docscontabilidadeimprimir": {
		"label": {
			"email": "Email",
			"enviar": "To send"
		},
		"email": {
			"emailEnviado": "Email sent to {{email}}",
			"emailErro": "Error sending email to {{email}}."
		}
	},
	"docsnaosaldados": {
		"title": "Unbalanced documents",
		"fields": {
			"doPeriodo": "From the period",
			"atePeriodo": "Until period",
			"deConta": "From the account",
			"ateConta": "Even count",
			"doNCampo": "From N. Campo",
			"ateNCampo": "Until N. Campo"
		},
		"alertNaoHaDocs": "No unsettled documents were found."
	},
	"documentosFiscais": {
		"tabIva": "VAT",
		"tabIRC": "IRC",
		"tabRecibos": "Receipts",
		"tabIES": "HEI",
		"tabIRS": "IRS",
		"tabIvaDp": "Periodic VAT Declarations",
		"tabIvaDr": "VAT Recapitulative Declarations",
		"tabIRCPagAnt": "Advance Payments",
		"tabIRCMod22": "Model 22",
		"tabRecibosRVClient": "Green Receipts as a customer",
		"tabRecibosRR": "Income Receipts",
		"tabIESConsult": "Consult",
		"tabIRSMod10": "Model 10",
		"tabRetencoes": "Withholdings",
		"tabRetencoesIRCIRS": "IRC/IRS withholdings and I. Seal",
		"tabIUC": "IUC",
		"tabVeiculos": "Vehicles",
		"tabCertidoes": "Certificates",
		"tabImoveis": "Properties",
		"tabImoveisPP": "Building Heritage",
		"tabImoveisNC": "Billing Notes",
		"tabPenhoras": "Liens",
		"tabSSSituacaoContrib": "Contribution Status",
		"tabViaCTTInbox": "Mail box",
		"tabIUCDocumentos": "Documents",
		"tabIUCEmitir": "Issue",
		"tabConsultarCertidoes": "Consult Certificates",
		"tabEmitirCertidoes": "Issue Certificate",
		"buttons": {
			"get": "To obtain",
			"search": "To look for",
			"efetuarPedido": "Place Order",
			"mb": "ATM"
		},
		"fields": {
			"periodo": "Period",
			"identificacao": "Identification",
			"dataRececao": "Receipt Date",
			"valor1": "Tax Deliver",
			"valor2": "Tax Recover",
			"comprovativo": "Proof",
			"certidao": "Certificate",
			"docPagamento": "Payment Doc",
			"tipo": "Type",
			"situacao": "Situation",
			"numero": "Number",
			"dataSubmissao": "Submission Date",
			"submissaoValor": "Submission Value",
			"dataPagamento": "Payment Date",
			"pagamentoValor": "Payment Amount",
			"obterDetalhes": "Document Details",
			"numDocumento": "Doc No.",
			"dataEmissao": "Issuance date",
			"nif": "NIF",
			"nome": "Name",
			"domicilioFiscal": "Tax Domicile",
			"atividadePrestador": "Activity Performed",
			"titulo": "Title",
			"dataRef": "Date Ref",
			"descricao": "Description",
			"valorBase": "Base value",
			"ivaDesc": "VAT Description",
			"valorIVA": "VAT value",
			"valorImpostoSelo": "Stamp Tax",
			"valorIRS": "IRS value",
			"importanciaRecebida": "Importance Received",
			"reciboEco": "Economic Receipt",
			"reciboNormal": "Normal receipt",
			"periodoFull": "Period",
			"dataRecebimento": "Receiving date",
			"tipoImportancia": "Type Importance",
			"valor": "Value",
			"retencao": "Retention",
			"document": "Document",
			"recRefContrato": "Contract Reference",
			"numeroRecibo": "Receipt No.",
			"importancia": "Importance",
			"anexos": "Attachments",
			"declaracao": "Declaration",
			"ano": "Year",
			"dataRecepcao": "Reception Date",
			"via2comprovativo": "2nd copy",
			"aPagar": "Amount to Pay",
			"tipoDocumento": "Type Doc.",
			"dataSituacao": "Date Status",
			"guiaPagamento": "Payment Tab",
			"matricula": "Registration",
			"catIUC": "IUC Cat.",
			"dataLimitePagamento": "Deadline Pag.",
			"valorImposto": "Tax Value",
			"valorJuros": "Interest Value",
			"situacaoNota": "Situation Note",
			"refPagamento": "Ref. Page",
			"emissao": "Emission",
			"dataMatricula": "Registration date",
			"marca": "Brand",
			"modelo": "Model",
			"propLoc": "Owner/Renter",
			"tipoNota": "Type Note",
			"valorEmitido": "Value Issued",
			"valorPago": "Amount paid",
			"qtdVeiculosLiquidados": "Qty. Vehicles",
			"categoria": "Category",
			"mesMatricula": "Month Enrollment",
			"adicionalIUC": "Additional IUC",
			"dataPedido": "Order Date",
			"tipoCertidao": "Certificate Type",
			"estado": "state",
			"nPedidoPenhora": "Attachment Request No.",
			"descricaoBem": "Description of the asset",
			"codigoBem": "code of good",
			"qtdReconhecida": "Recognized Qty",
			"unidade": "Unit",
			"valorUnit": "Unit Value.",
			"niss": "NISS",
			"dataResposta": "Response Date",
			"resposta": "Response",
			"entidade": "Entity",
			"conta": "Account",
			"dataEntrega": "Delivery date",
			"lido": "Read",
			"tratado": "Treated",
			"freguesia": "Parish",
			"artigo": "Article",
			"parte": "Part",
			"valorInicial": "Initial value",
			"idNotaCobranca": "No.",
			"numPredios": "No. of Buildings",
			"cadernetaPredial": "Predial Booklet",
			"guia": "Guide",
			"data": "Date",
			"pagamento": "Payment",
			"dataRegisto": "Registration Date",
			"pedidoPor": "Request By"
		},
		"strings": {
			"headerAT": "Tax Authority (AT)",
			"headerSS": "Social Security",
			"headerViaCTT": "Via CTT",
			"comprovativo": "{{title}} - Proof: {{id}}",
			"via2comprovativo": "{{title}} - 2nd Copy: {{id}}",
			"docPagamento": "{{title}} - Payment Document: {{id}}",
			"primeiraDeclaracao": "First Declaration",
			"declaracaoSubstituicao": "Replacement Declaration",
			"detalhesDoc": "{{title}} - Document Details: {{id}}",
			"recibo": "{{title}} - Receipt: {{id}}",
			"naoDisponivel": "Not available",
			"modalTitleGuiaPagamento": "{{title}} - Payment Tab: {{id}}",
			"iucEmitirLigeiros": "Cars and Motorcycles",
			"iucEmitirMercadorias": "Mixed goods and light goods with a gross weight exceeding 2500 kg",
			"emitido": "Issued",
			"modalTitleConfirmation": "Confirmation",
			"modalMessageEmitirIUC": "Are you sure you want to issue the payment document for registration: {{matricula}}?",
			"emitidoComSucesso": "Payment document issued successfully!",
			"emitir": "Issue",
			"todos": "All",
			"liquidacaoIRS": "IRS settlement",
			"renunciaIsencaoIvaImovel": "Waiver of VAT Exemption - Property",
			"residenciaFiscal": "Tax Residence",
			"dividaNaoDivida": "Debt and Non-Debt",
			"sujPassivoRecupIVAEstrangeiro": "Liabilities/Foreign VAT Recovery",
			"residenteNaoHabitual": "Non-habitual resident",
			"domicilioFiscal": "Tax Domicile",
			"predialNegativa": "Negative Building",
			"predial": "Building",
			"activa": "Active",
			"pendente": "Pending",
			"pendenteConfirmacao": "Pending Confirmation",
			"expirada": "Expired",
			"anulada": "Canceled",
			"modalMessagePedirCertidao": "Are you sure you want to request the certificate: {{name}}?",
			"comprovativoSimpleTtile": "Proof",
			"ssSitContribInfoText": "Declarations of contribution status issued after 10/24/2020",
			"viaCTTInfoText": "The application only shows the last 20 records.",
			"alterarCredenciais": "Change Credentials",
			"portalAT": "Finance Portal (AT)",
			"portalSSD": "Direct Social Security (SSD)",
			"portalViaCTT": "Via CTT",
			"cadernetaPredial": "{{title}} - Building Booklet - Article: {{article}} - Year: {{year}}",
			"emptyDocs": "You have not selected any documents.",
			"associarDocumentoContabDigital": "Add document to digital accounting",
			"associarDocumentosContabDigital": "Add documents to digital accounting",
			"successAssociarComprovativoContabDigital": "Document added successfully",
			"verDocumento": "View document"
		},
		"progress": {
			"message": "Associate documents with digital accounting"
		},
		"modules": {
			"iuc": {
				"emitir": {
					"catA": "Category A - Light Passenger and Mixed Vehicles with a gross weight not exceeding 2500 kg (purchased before 30 June 2007)",
					"catB": "Category B - Light Passenger and Mixed Vehicles with a gross weight not exceeding 2500 kg (acquired from 1 July 2007)",
					"catE": "Category E - Motorcycles and equivalents",
					"catC": "Category C - Mixed Goods and Light Vehicles with a gross weight exceeding 2500 kg for Own Account",
					"catD": "Category D - Mixed Goods and Light Vehicles with a gross weight exceeding 2500 kg for Outsourced Account"
				}
			}
		}
	},
	"efatura": {
		"showComunicacaoInexistencia": "No Billing (TEST)",
		"summary": "File summary",
		"summaryErros": "File Warnings/Errors",
		"invoiceTotal": "Number of invoices",
		"totalCredit": "Full credit",
		"totalDebit": "Total debt",
		"total": "Total",
		"summaryByDoc": "Summary by document type",
		"summaryByType": "Summary by VAT code",
		"btnComunicaDocs": "Communicate Documents",
		"btnSaftGlobal": "SAF-T Global",
		"btnSaftAutofaturacao": "SAF-T Self-billing",
		"btnLoginWebserviceAT": "AT Webservice - Billing Communication",
		"btnGenerateDoc": "Generate eInvoice",
		"btnDownload": "Download",
		"btnComunicar": "To communicate",
		"btnComunicaInexistenciaFaturacao": "Report No Billing",
		"btnMonthsJan": "January",
		"btnPesquisar": "To look for",
		"naoGeradoString": "(not generated)",
		"geradoString": "(generated)",
		"inexistenciaFaturacaoModalTitle": "Report No Billing",
		"btnRemoverInexistencia": "Remove No Billing",
		"table": {
			"tipoSAFT": "Type",
			"docNumber": "Doc No.",
			"nif": "NIF",
			"nomeClifo": "Name",
			"valorIVA": "Total tax",
			"valorTributavel": "Taxable value",
			"valorParaSAFT": "SAFT Value",
			"valorTotal": "Total amount",
			"estado": "state",
			"comunicado": "Announcement",
			"erros": "Errors/Warnings",
			"name": "Name",
			"vatValue": "VAT value",
			"rate": "Rate",
			"dataDoc": "Date",
			"tipoComunicacao": "Type of Communication"
		},
		"tipoComunicacao": {
			"saft": "SAFT file",
			"webService": "WebService"
		},
		"resumoFicheiro": {
			"comunicados": "Announcements",
			"porComunicar": "By Communicate",
			"todos": "All"
		},
		"notices": "Warnings",
		"errors": "Errors",
		"docState": {
			"normal": "N - Normal",
			"nullified": "A - Canceled",
			"billed": "F - Invoiced"
		},
		"messageConfigLoginAt": "Sales documents will be configured for communication via webservice to eFatura.",
		"titleConfigLoginAt": "Confirmation",
		"nDocsNComunicados": "Number of documents not communicated:",
		"messagePermissions": "Warning: To change the fields you must have administrator permissions!",
		"comunicacaoRealizadaSucesso": "Communication carried out successfully.",
		"comunicaDocsModal": {
			"title": "Documents list"
		},
		"geraDocModal": {
			"title": "Generate e-Invoice document",
			"soDocsPorComunicar": "Only documents to be communicated",
			"messageTodosComunicados": "When continuing, the generated file will contain all those already communicated.",
			"messageSoDocsPorComunicarFalse": "Generates a file with all documents for the period",
			"messageSoDocsPorComunicarTrue": "Generates a file with only the documents to be communicated"
		},
		"mesDeclaracao": "Declaration Month",
		"confirmComunicacaoCheckLabel": "I confirm the intention to communicate No Invoicing",
		"confirmAnulacaoComunicacaoCheckLabel": "I confirm the intention to Remove Non-Billing",
		"comunicacaoSuccessMsg": "The Communication of Non-existence of Billing was made successfully.",
		"anulacaoComunicacaoSuccessMsg": "The Cancellation of the Non-Invoicing Communication was carried out successfully."
	},
	"efaturacirculacaologin": {
		"title": "AT-Doc login.",
		"saveTitle": "Save",
		"fields": {
			"username": "Username (NIF/UserID)",
			"password": "Password"
		},
		"messages": {
			"success": "Login successfully.",
			"errorUsernameEmpty": "Empty username.",
			"errorPasswordEmpty": "Empty password.",
			"noPermissions": "Warning: To change values ​​you must have administrator permissions!"
		}
	},
	"efaturalogin": {
		"title": "E-Invoice Login",
		"saveTitle": "Save",
		"fields": {
			"tipoComunicacao": "AT communication type",
			"username": "Username (NIF/UserID)",
			"password": "Password"
		},
		"messages": {
			"success": "Login successfully.",
			"errorUsernameEmpty": "Empty username.",
			"errorPasswordEmpty": "Empty password.",
			"noPermissions": "Warning: To change values ​​you must have administrator permissions!"
		}
	},
	"emailclifos": {
		"sendMail": {
			"title": "Send email: {{nAccount}} - {{nameAccount}}",
			"messageSuccess": "The email was successfully sent to {{emailList}}",
			"sendAllCheckbox": "I want to automatically send the remaining emails",
			"sendAllMessage": "Sending emails",
			"sendAllSuccess": "Emails sent successfully",
			"btn": {
				"ignore": "Ignore",
				"sendMail": "Send email",
				"send": "To send"
			},
			"tooltips": {
				"ignore": "When ignored, it proceeds to the next email without sending it",
				"cancel": "When unsubscribing, this and the following emails will be unsubscribed",
				"cancelCheck": "When you cancel, you will not be able to continue and previous emails will be canceled"
			},
			"messages": {
				"success": "Email successfully sent"
			},
			"email": "Email",
			"assunto": "Subject",
			"anexos": "Attachments",
			"texto": "Text"
		}
	},
	"empresabloqueada": {
		"title": "Company is not available",
		"message": "<strong>Company is not available</strong>, contact your system administrator for more information.",
		"changeCompany": "Change company",
		"reasons": {
			"companyEmptyRoles": "Company not available, because it does not have access to any portal."
		}
	},
	"empresas": {
		"title_detail": "Company {{id}}",
		"title_new": "New company",
		"title_plural": "Companies",
		"pesquisa": "To look for",
		"saved": "Company {{id}}, saved successfully",
		"error": "Company {{id}}, cannot be saved.",
		"fields": {
			"primeiroMesContab": "First accounting month",
			"nempresa": "company no.",
			"nempresaPlaceholder": "Company number",
			"nome": "Name",
			"nomePlaceholder": "Company Name",
			"nomeAlternativo": "Commercial name",
			"morada": "Household",
			"codpostal": "Postcode",
			"localidade": "Location",
			"nif": "VAT Number",
			"pais": "Country",
			"moeda": "Coin",
			"tipoEmpresa": "Company Type",
			"alvara": "Permit",
			"textoRelatorio": "Report text",
			"textoRelatorioPlaceholder": "(Insert the company's IBAN here for example)",
			"capitalSocial": "Share capital",
			"telefone": "Telephone",
			"email": "Email",
			"web": "Web address",
			"repFinancas": "Finance Service",
			"tipoContab": "Type Accounting",
			"regimeIRC": "IRC/IRS Regime",
			"regimeIva": "VAT regime",
			"periodoIva": "VAT period",
			"anoCursoIRC": "IRC Course Year",
			"periodoContabilistico": "Accounting Period",
			"tipoAnalitica": "Analytics",
			"capPublico": "Public Capital",
			"capPrivado": "National Private Capital",
			"capEstrangeiro": "Foreign capital",
			"capPublicoPerc": "% Public Capital",
			"capPrivadoPerc": "% Private Capital",
			"capEstrangeiroPerc": "% Foreign capital",
			"nomeComarca": "County Name",
			"nomeConservatoria": "Name of the Conservatory",
			"registoConservatoria": "Registration at the Conservatory",
			"nifCC": "Certified Accountant Taxpayer Number",
			"nOCC": "Accountant Registration Number at OCC",
			"nifAdmin": "Taxpayer Number of the Legal Representative",
			"nContribSegSocial": "Taxpayer Number in Social Security",
			"cae": "CAE",
			"atividadeprincipal": "Main activity",
			"seMesCivilDifPerioComecaAnoSeg": "If calendar month is different from period, year starts in the following year",
			"concelhoEmp": "County",
			"telemovel": "Cell phone",
			"mostraTarifasNasListagens": "Show type of telephone line tariff in listings (for consumer contact)",
			"codNatJuridica": "National Legal Code",
			"codAssocPatronal": "Employer Association Code",
			"codddccff": "District Code"
		},
		"steps": {
			"dadosGerais": "General data",
			"contactos": "Contacts",
			"dadosContab": "Accounting and Tax Data",
			"logotipo": "Logo",
			"dadosRH": "Data for HR"
		},
		"messages": {
			"success": {
				"save": "Successfully recorded",
				"saveLogotipo": "Logo loaded successfully.",
				"deleteLogoTipo": "Logo deleted successfully."
			},
			"error": {
				"save": "Error to record"
			},
			"logoAlt": "Company Logo",
			"changeLogo": "Change Logo",
			"uploadLogo": "Upload Logo",
			"deleteLogo": "Delete Logo",
			"changedperiocontabfirstmonth": "Changes saved successfully.",
			"configDivulgacaoTiposTarifa": "Configure rate type disclosure"
		},
		"changePerioContabTooltip": "Setting the calendar year different from the fiscal year",
		"modal": {
			"confirmTitle": "Analytics: Always launch analytics",
			"confirmMessage": "Do you want to configure the chart of accounts analytics accounts?",
			"configanocivildiftitle": "Accounting Period",
			"tarifas": {
				"modalTitle": "Disclosure of tariff types",
				"title1": "Publication of telephone lines for consumer contact",
				"title2": "Decree-Law No. 59/2021 (in force since 01-11-2021)",
				"redeFixaNacional": "(National fixed network)",
				"redeMovelNacional": "(National mobile network)"
			}
		},
		"maintenance": {
			"analiticaconfig": "Setting up analytics accounts"
		},
		"storewelcome": {
			"title": "Welcome to CentralGest Cloud",
			"message1": "Before starting to use the application, you <strong>need to fill in the company's identification details</strong>.",
			"message2": "Please fill in the following fields:",
			"required": {
				"nif": "Company Taxpayer No."
			},
			"errors": {
				"nif": "Please enter the company's tax number"
			}
		}
	},
	"entidadeexterna": {
		"title_detail": "External Entity",
		"title_new": "New external entity",
		"title_plural": "External Entities",
		"pesquisa": "To look for",
		"deleted": "External Entity deleted successfully.",
		"saved": "External Entity saved successfully.",
		"error": "External Entity cannot be saved.",
		"fields": {
			"entidadeExternaID": "",
			"codigo": "Code",
			"nome": "Name",
			"morada": "Address 1",
			"morada2": "Address 2",
			"nContribuinte": "VAT Number",
			"contacto": "Contact",
			"email": "Email",
			"tipoEmpresa": "Company Type",
			"tipoContabilidade": "Type Accounting",
			"nEmpresaGabinete": "Cabinet Company",
			"periodoIVA": "VAT period",
			"regimeIRC": "IRC/IRS Regime"
		}
	},
	"entidadehabitual": {
		"desconhecido": "Unknown",
		"clientes": "Customers",
		"fornecedores": "Suppliers"
	},
	"erps": {
		"title_detail": "Erp {{id}}",
		"title_new": "New ERP",
		"title_plural": "ERPs",
		"pesquisa": "To look for",
		"saved": "Erp {{id}}, saved successfully",
		"error": "Erp {{id}}, cannot be saved.",
		"fields": {
			"apiUrl": "API Url",
			"apiUrlPlaceholder": "API Url",
			"name": "Erp name",
			"nomePlaceholder": "Erp name"
		}
	},
	"erputilizadores": {
		"title_detail": "User {{id}}",
		"title_new": "New user",
		"pesquisa": "To look for",
		"saved": "User {{id}}, saved successfully",
		"error": "User {{id}}, cannot be saved.",
		"removecompany": "Remove company",
		"fields": {
			"erp": "ERP",
			"nutilizador": "User number",
			"nutilizadorPlaceholder": "User number",
			"nomeUtilizador": "Username",
			"nomeUtilizadorPlaceholder": "Username",
			"nomeCompleto": "Full name",
			"nomeCompletoPlaceholder": "Full name"
		},
		"companies": {
			"title": "Companies",
			"available": "Available",
			"selected": "Selected"
		},
		"addMultiEmpresa": "Add multiple companies",
		"options": {
			"showApi": "Show API hits"
		},
		"editRow": {
			"title": "Edit ERP",
			"erp": "ERP",
			"nEmpresa": "Company No.",
			"nUtilizador": "User No.",
			"ativo": "Active",
			"codEmpregado": "Employee Code",
			"disponiveis": "Available",
			"temAcesso": "Has access",
			"naoTemAcesso": "Do not have access"
		}
	},
	"estabelecimento": {
		"title_detail": "Establishment {{id}}",
		"title_new": "New Establishment",
		"title_plural": "Establishments",
		"pesquisa": "To look for",
		"saved": "Establishment {{id}}, saved successfully.",
		"error": "Establishment {{id}}, cannot be saved.",
		"deleted": "Establishment {{id}}, successfully deleted",
		"fields": {
			"nEstabElec": "Code.",
			"nEstabElecPlaceholder": "Establishment Code",
			"nome": "Name:",
			"nomePlaceholder": "Name of the establishment",
			"designaBreve": "Designates Brief",
			"designaBrevePlaceholder": "Designates Brief",
			"morada": "Household",
			"moradaPlaceholder": "Household",
			"localidade": "Location",
			"localidadePlaceholder": "Location",
			"nPostal": "Postcode",
			"nPostalPlaceholder": "Postcode",
			"distrito": "District",
			"concelho": "County",
			"freguesia": "Parish",
			"freguesiaPlaceholder": "Parish",
			"caeaCTPrinc": "caeaCTPrinc",
			"caeaCTPrincPlaceholder": "CAE No.",
			"codDdCcFf": "District Code, Conc., Freg.",
			"codDdCcFfPlaceholder": "District Code, Conc., Freg.",
			"nEstabQPessoal": "N. Estab.",
			"nEstabQPessoalPlaceholder": "N. Estab.",
			"nEstabSegSocial": "N. Estab.",
			"nEstabSegSocialPlaceholder": "N. Estab.",
			"activPrincip": "Main activity",
			"activPrincipPlaceholder": "Main activity",
			"nContRib": "VAT Number",
			"nContRibPlaceholder": "VAT Number",
			"nCaixaPrev": "Pension Fund No.",
			"codCCt": "CCT Code",
			"telefone": "Telephone",
			"telefonePlaceholder": "Telephone",
			"calenCABId": "Calendar",
			"sede": "Thirst"
		}
	},
	"estadoconta": {
		"ativa": "Active",
		"desactivada": "Disabled",
		"bloqueada": "Blocked"
	},
	"estadoemp": {
		"ativo": "Active",
		"inativo": "Inactive",
		"ambos": "All"
	},
	"estadoregisto": {
		"ativo": "Active",
		"inativo": "Inactive"
	},
	"estados": {
		"title_detail": "Status {{id}}",
		"title_plural": "States",
		"pesquisa": "To look for",
		"fields": {
			"codEstado": "State Code",
			"nome": "Name",
			"nomeAbr": "Abbreviation",
			"tipoEstado": "State Type"
		}
	},
	"estadosValidacao": {
		"data": {
			"naoPossivel": "Unable to create releases",
			"criadoNaoValidado": "Release created, but not yet validated",
			"criadoValidado": "Release created, but has already been validated"
		}
	},
	"exportacaoCompras": {
		"fields": {
			"dataDe": "Date of",
			"ate": "Until",
			"documentosPorExportar": "Send only unexported documents"
		},
		"dataGrid": {
			"doc": "Doc.",
			"nDocExterno": "External Doc. No.",
			"data": "Date",
			"nFornecedor": "Supplier No.",
			"nomeFornecedor": "Supplier Name",
			"exportado": "Exported",
			"exportId": "Export ID"
		},
		"config": {
			"title": "Configuration - Purchase Export",
			"familiasDataGrid": {
				"title": "Families",
				"nFamCG": "CG Family No.",
				"nomeFamCG": "Name Family CG",
				"idFamGeo": "Geofolia Family",
				"nomeFamGeo": "Geofolia Family Name",
				"operacao": "Operation",
				"legend": {
					"naoConfiged": "Not configured",
					"campoEdit": "Editable field"
				}
			},
			"docsDataGrid": {
				"titleDocsCompras": "Purchasing Documents",
				"titleDocsNotaCredito": "Credit Note Documents",
				"id": "Document ID",
				"name": "Name",
				"desc": "Description",
				"contectMenu": {
					"desmarcaDocsExported": "Deselect exported (selected) documents",
					"abrirDoc": "Open document"
				}
			}
		},
		"mensagens": {
			"configSuccess": "Configuration saved successfully",
			"okModalTitle": "Deselect export of selected documents",
			"okModalMensagem": "Are you sure you want to deselect the export of selected documents?",
			"documentosDupsNotAllowed": "The document '{{docName}}' is already configured",
			"linhaObrigatoria": "Mandatory to select a document to add successfully",
			"familiasDupsNotAllowed": "A CentralGest family is already configured with the id '{{id}}'"
		}
	},
	"extractosartigos": {
		"title_plural": "Article Extracts",
		"pesquisa": "To look for",
		"fields": {
			"nArtigo": "Article Code",
			"nomeArtigo": "Name Article",
			"dataDoc": "Doc date.",
			"nDocAsString": "No.",
			"nDocfa": "Type Doc.",
			"descricaoDocfa": "Document type description.",
			"nArmazem": "Warehouse code",
			"nomeArmazem": "Warehouse name",
			"nLoteEspecifico": "Specific batch",
			"preco": "Price",
			"valorEntrada": "Entry value",
			"valorSaida": "Exit value",
			"saldoValor": "Balance value",
			"qtdEntrada": "Input amount",
			"qtdSaida": "Output quantity",
			"saldoQtd": "Balance qty.",
			"nConta": "No.",
			"nomeClifo": "Customer/supplier name",
			"unidadeMov": "Motion Unit."
		},
		"btn": {
			"options": {
				"showNArtigo": "Show Article Code",
				"showNomeArtigo": "Show Article Name",
				"showDataDoc": "Shows Doc Date.",
				"showNDocAsString": "Show No.",
				"showNDocfa": "Shows Doc Type.",
				"showDescricaoDocfa": "Shows Document type description.",
				"showNArmazem": "Show warehouse code",
				"showNomeArmazem": "Show warehouse name",
				"showNLoteEspecifico": "Shows specific Lot",
				"showPreco": "Show Price",
				"showValorEntrada": "Show input value",
				"showValorSaida": "Show output value",
				"showSaldoValor": "Shows balance value",
				"showQtdEntrada": "Shows input quantity",
				"showQtdSaida": "Shows output quantity",
				"showSaldoQtd": "Shows Balance qty.",
				"showNConta": "Show No.",
				"showNomeClifo": "Shows customer/supplier name",
				"showUnidadeMov": "Shows Motion Unit."
			}
		},
		"caption": {
			"deCodArtigo": "From Article Code:",
			"ateCodArtigo": "Until:",
			"deNArmazem": "From Warehouse Code:",
			"ateNArmazem": "Until:",
			"deData": "From Date:",
			"ateData": "Until:"
		},
		"info": {
			"erroDataDeMaiorQueAte": "The From date is greater than the To date",
			"valormaximonarmazem": "The maximum value for the Warehouse Code is \"32767\"."
		},
		"contextmenu": {
			"openDocCcomercial": "Open document",
			"openArtigo": "Open article sheet"
		}
	},
	"extratosClifos": {
		"radical": "Radical of accounts",
		"table": {
			"fields": {
				"nConta": "Account",
				"nomeConta": "Account Name",
				"nDocExterno": "External Doc",
				"dataVenc": "Expiration Date",
				"descricao": "Description",
				"valorDebito": "Debit Amount",
				"valorCredito": "Credit Value",
				"porPagar": "Unpaid",
				"dataDoc": "Date Doc.",
				"moeda": "Coin",
				"nCCusto": "NCCost",
				"valorDebitoSegMoeda": "Debit Amount According to Currency",
				"valorCreditoSegMoeda": "Credit Value According to Currency",
				"cambioSegMoeda": "Currency Exchange",
				"saldo": "Balance",
				"descritivo": "Descriptive",
				"nDocumento": "Document No.",
				"periodo": "Period",
				"nDiario": "Daily"
			}
		},
		"btn": {
			"processar": "Process"
		}
	},
	"extratosDT": {
		"fields": {
			"periodo": "Period",
			"diario": "Daily",
			"nDocInterno": "Doc.",
			"nDocExterno": "External doc.",
			"dataVencimento": "Due date",
			"descricao": "Description",
			"valorDebito": "Debt",
			"valorCredito": "Credit",
			"porPagar": "Unpaid",
			"saldo": "Balance",
			"dataDoc": "Date Doc.",
			"moeda": "Coin",
			"debitoEmDivisa": "Foreign currency debt",
			"creditoEmDivisa": "Currency credit",
			"porPagarEmDivisa": "For paying in currency",
			"cCusto": "C. Cost",
			"cambioSegMoeda": "Exchange in Mon. Currency",
			"debitoSegMoeda": "Debit in Mon Currency",
			"creditoSegMoeda": "Credit in Mon Currency",
			"nLanc": "N. Lanc.",
			"dataDocExterno": "External Doc Date"
		},
		"config": {
			"showMoeda": "Show Currency column",
			"showDebitoEmDivisa": "Show currency debit column",
			"showCreditoEmDivisa": "Show Currency Credit column",
			"showPorPagarEmDivisa": "Show Unpaid in currency column",
			"showCCusto": "Show C. Cost column",
			"showCambioSegMoeda": "Show Exchange column in Mon Currency",
			"showDebitoSegMoeda": "Show Debit column in Mon. Currency",
			"showCreditoSegMoeda": "Show Credit column in Mon Currency",
			"showPorPagar": "Show Unpaid column",
			"showDataVencimento": "Show Due Date column"
		},
		"filterFields": {
			"nConta": "Account",
			"deData": "Of Date",
			"ateData": "Until Date"
		},
		"btn": {
			"processar": "Process"
		},
		"msg": {
			"contaInvalida": "Invalid account field.",
			"naoMostraDetalhe": "It is not possible to present the details of this document, as it is an accounting document."
		},
		"caption": {
			"recibos": "Receipts"
		},
		"modal": {
			"title": "Account statement {{type}}"
		}
	},
	"familias": {
		"title_detail": "Family {{id}}",
		"title_new": "New family",
		"title_plural": "Families",
		"pesquisa": "To look for",
		"deleted": "Family deleted successfully.",
		"saved": "Family {{id}}, saved successfully.",
		"error": "Family {{id}}, cannot be saved.",
		"fields": {
			"nFamilia": "Code.",
			"nFamiliaPlaceholder": "family code",
			"nome": "Name",
			"nomePlaceholder": "Family name",
			"codtipoartigo": "Article Type Code",
			"grandesfamilias": "Family Gr. Code",
			"subfamilia": "Sub-Family Code",
			"unimovimento": "Un.",
			"contaVenda": "Sales Account No.",
			"contaCompra": "Purchase Account No.",
			"metodoCalcPrecVend": "Calc Method.",
			"nomeIvaVenda": "VAT on Sale",
			"nomeIvaCompra": "VAT on Purchase",
			"movimentaStock": "Stock Movement",
			"criterio": "Valorimetric Criterion"
		},
		"metodos": {
			"margemprecovenda": "Margin on Sales Price",
			"margemprecocompra": "Margin on Purchase Price",
			"customedioponderado": "Weighted Average Cost",
			"lifo": "Lifo",
			"fifo": "Fifo",
			"custopadrao": "Standard Cost",
			"customedio": "Average Cost",
			"loteespecifico": "Specific Lot"
		},
		"btn": {
			"buttonOptions": "options",
			"verContas": "Shows Sales and Purchase accounts",
			"esconderContas": "Hide accounts"
		}
	},
	"faturacaoContratos": {
		"btn": {
			"search": "To look for",
			"processar": "Process",
			"gestaoAvencas": "Invoiced Covenants Management",
			"faturar": "Invoice"
		},
		"fields": {
			"sel": "Sel.",
			"classeContrato": "Class",
			"tipoContrato": "Type",
			"nContrato": "No.",
			"versao": "Version",
			"descricao": "Description",
			"nClifo": "No.",
			"nomeClifo": "Name",
			"totalLiquido": "Net total",
			"nFaturasEmAberto": "No.",
			"dataUltimoPagamento": "Last Payment Date",
			"dataUltimaFatura": "Last Invoice Date",
			"obsUltimaFatura": "Notes on the Last Invoice",
			"temDebitoDirectoStr": "Customer with Direct Debit",
			"temErro": "With Error",
			"erroStr": "Error",
			"contratoAsString": "Covenant"
		},
		"caption": {
			"classeContrato": "Class",
			"tipoClasseContrato": "Type",
			"nZonaDe": "By Zone Code",
			"nContaDe": "From No.",
			"ateCaption": "Until",
			"dataDoc": "Date Doc.",
			"dataVenc": "Expiration Date",
			"dataInicTransp": "Start Date.",
			"horaInicTransp": "Start Time.",
			"txtContrato": "Comments",
			"txtCtrNoCabecalho": "Place in the footer",
			"txtCtrNoCabecalhoTooltip": "Fill in the Notes field in the document header",
			"txtCtrNaLinha": "Put it on the body",
			"txtCtrNaLinhaTooltip": "After the last line of the document",
			"origemTxtContrato": "Text origin",
			"localizacao": "Location"
		},
		"config": {
			"verDataInicTransp": "Show start date.",
			"verHoraInicTransp": "Show start time.",
			"verZonaDeAte": "Show filter by zone",
			"verErrosContratos": "Show agreement errors",
			"verContasDeAte": "Show filter by account",
			"verDataVenc": "Show expiration date."
		},
		"table": {
			"semdados": "No data"
		},
		"messages": {
			"success": "Covenants successfully invoiced.",
			"sucessContratosFaturados": "Successfully invoiced {{nregistos}} agreement(s).",
			"semClasseSelecionada": "No subscription class selected.",
			"semTiposSelecionado": "You must select at least one type of contract",
			"semDataDocDeSelecionado": "You must fill in the initial document date",
			"semDataDocAteSelecionado": "You must fill in the final document date",
			"semNContaDeSelecionado": "Must complete the initial account",
			"semNContaAteSelecionado": "Must complete the final bill",
			"infoFaturacaoAuto": "To use automatic invoicing, configure the type of payment here"
		},
		"errors": {
			"classeContratoNaoPodeSerNula": "The contract class cannot be null.",
			"naoExisteTipoContratoSelecionado": "There is no type of subscription selected.",
			"naoExisteContratoSelecionadoParaFaturar": "There is no contract selected to invoice.",
			"contratosAFaturarComErros": "There are {{nregistrations}} agreement(s) to invoice in error."
		},
		"legend": {
			"contratoComErro": "Agreement with error"
		},
		"info": {
			"dadosProcessamento": "Data processing"
		},
		"data": {
			"tipoDeAvenca": "Type of contract",
			"manual": "Manual"
		},
		"text": {
			"dataParaProc": "Processing date"
		}
	},
	"gestaoContratos": {
		"title": "Management of invoiced payments",
		"filters": {
			"nClasseContrato": "Class",
			"nTiposContrato": "Type",
			"dataDocDe": "From Date Doc.",
			"dataDocAte": "Until",
			"nContaDe": "Account No.",
			"nContaAte": "Until",
			"estadoDoc": "state",
			"estadosDoc": {
				"todos": "All",
				"encerrados": "Closed",
				"naoEncerrados": "Not closed"
			}
		}
	},
	"faturacaoContratosLog": {
		"title": "Covenant Processing",
		"tabs": {
			"faturados": {
				"title": "Billed",
				"fields": {
					"contratoAsString": "Covenant",
					"nClasseCtr": "Class",
					"nTipoContrato": "Type",
					"nContrato": "No.",
					"descricao": "Description",
					"nConta": "Client",
					"contaNome": "Customer Name",
					"dataDoc": "Date Doc.",
					"nDocAsString": "Document",
					"email": "Email",
					"emailEnviado": "Sent",
					"docJaImprimido": "Printed Doc"
				},
				"btn": {
					"previsualizar": "Preview",
					"encerrarImprimir": "Close and Print/Send",
					"encerrarTudo": "Close Documents",
					"enviarFatEletronica": "Send Invoices",
					"editarDocumento": "Edit Document",
					"visualizarDocumento": "View Document"
				}
			},
			"erros": {
				"title": "Not Billed",
				"fields": {
					"contratoAsString": "Covenant",
					"nClasseCtr": "Class",
					"nTipoContrato": "Type",
					"nContrato": "No.",
					"descricao": "Description",
					"nConta": "Client",
					"contaNome": "Customer Name",
					"erro": "Error"
				},
				"warningMsg": {
					"naoFaturados": "There are payments that have not been invoiced",
					"consulteNaoFaturados": "There are payments that have not been invoiced, see the Not Invoiced tab"
				}
			}
		},
		"success": {
			"documentosEncerrados": "The invoiced agreements were successfully closed.",
			"encerradosNumDocumentos": "Successfully closed {{value}} document(s).",
			"emailsEnviados": "Successfully sent {{value}} email(s)."
		},
		"errors": {
			"documentoJaEncerrado": "The document {{nDoc}} is now closed.",
			"emailsNaoEnviados": "No email was sent.",
			"naoConfigurados": "There are no emails configured or documents are not closed.",
			"erroAEncerrarDoc": "Error closing document",
			"erroAEncerrarDocs": "Error closing documents",
			"erroAEnviarEmail": "Error sending email",
			"naoExistemItensSelecionados": "There are no items selected",
			"documentosEnvioEmailNaoEncerrados": "The agreement documents selected to be sent by email are not closed"
		},
		"info": {
			"emailsEnviados": "The selected documents have already been sent by email",
			"documentosEncerrados": "The selected documents are closed.",
			"docsNaoEncerradosSelecionadosParaEnvioEmail": "There are unclosed documents for the selected agreements and no email will be sent to these customers"
		},
		"confirm": {
			"emailsEnviados": "The documents have already been sent by email, do you want to send them again?"
		},
		"okCancel": {
			"encerrardocs": {
				"title": "Close documents",
				"message": "Are you sure you want to close the selected documents?"
			},
			"enviardocs": {
				"title": "Send contract invoices by email",
				"message": "Are you sure you want to send the documents for the selected agreements?",
				"message2": "There are selected covenant documents that have already been sent."
			}
		}
	},
	"faturacaoeletronicaconfiguracoes": {
		"fields": {
			"nDocFa": "Type Doc.",
			"nome": "Name",
			"estado": "state",
			"reportDefault": "Printed by default",
			"nConta": "Account No.",
			"emails": "Emails",
			"email": "Email",
			"emailgeral": "General email"
		},
		"legend": {
			"gravado": "Recorded",
			"porgravar": "For recording"
		},
		"tabs": {
			"tiposdocumento": "Document Types",
			"clientes": "Customers",
			"certificado": "Certificate"
		},
		"text": {
			"certificados": "Certificates",
			"certificadosCount": "Certificates ({{total}})",
			"pendentes": "Pendants",
			"pendentesCount": "Pending ({{total}})",
			"semFaturacaoEletronica": "No electronic invoice",
			"comFaturacaoEletronica": "With electronic invoice",
			"obterAtributos": "Get attributes",
			"authenticate": "Authenticate",
			"register": "Register",
			"tipoCertificadoNotSelected": "The electronic document signature platform is not defined, define it in one of the check boxes available below.",
			"selectedTipoCertificado": "Active electronic invoicing, the electronic signature of documents defined is: \"{{platform}}\".",
			"setTipoCerficadoAtivo": "Set as subscription platform",
			"tipoCerficadoAtivo": "Defined as a subscription platform",
			"faturacaoEletronicaNaoAtiva": "Electronic invoicing without a configured subscription platform",
			"infoDigitalSignMessage": "In order to use the Digital Sign provider, you need to obtain the Authorizer's data so that you can enable the signature.",
			"infoSAFEMessage": "In order to use the SAFE supplier, you need to have access to the Autentication.GOV Portal and must have Professional Attributes.",
			"faq": "Access our FAQ for any questions.",
			"allTiposDocs": "All SAF-T document types (FT,FR,ND,NC) are selected for digital document signing.",
			"allClientes": "All customers are selected to sign digital documents.",
			"savedInfoIsAnonimous": "Note: This information will be stored anonymously",
			"checkAcessoSAFEMsg": "The Electronic Invoice Signing Service (SAFE) allows the administrator, manager or director of a company to sign electronic invoices in integration software integrated with SAFE.",
			"checkAcessoSAFE": "Do you have access to the Autenticação.GOV portal and do you have Professional Attributes?",
			"confirmation": "Confirmation",
			"apagar": "To switch off",
			"dataValidade": "Expiration date"
		},
		"messages": {
			"clientAddedSucess": "Customer added!",
			"clientEditedSucess": "Client edited!",
			"emailsEmpty": "You must assign at least one email.",
			"warningAddDigitalSign": "There are already defined subscription accounts.<br/>Are you sure you want to create a new account?",
			"successAddSafeCertificate": "SAFE certificate successfully added.",
			"successSAFEObterAtributos": "SAFE certificate attributes successfully obtained.",
			"successAddDigitalSignAuthorizer": "Digital Sign Certificate added successfully.",
			"successSetTipoCerficadoAtivo": "Electronic signature platform successfully defined.",
			"authenticateDigitalSignAuthorizer": "Digital Sign certificate successfully authenticated.",
			"safependentinfo": "You must have assigned the professional attributes that allow the signing of electronic invoices.",
			"promptDeleteDigitalSignAuthData": "Are you sure you want to delete your access data?",
			"successDeleteDigitalSignAuthorizer": "Digital Sign certificate successfully removed.",
			"successConfigSaved": "Configuration saved successfully."
		},
		"errors": {
			"emptyNewSafeCertificateUrl": "Unable to obtain the address to add a SAFE certificate.",
			"emptyNewDigitalSignUrl": "Unable to obtain the address to add a Digital Sign certificate.",
			"safeObterAtributos": "An unknown error occurred while trying to obtain the SAFE certificate attributes.",
			"invalidNomeConta": "Invalid account name, can only contain characters and numbers and cannot contain spaces.",
			"noAuthFound": "No valid authentication found"
		},
		"safe": {
			"nomeDaConta": "Name",
			"nomeUtilizador": "Owner",
			"accountExpiration": "Validity",
			"autenticacaoGov": "Open Authentication.Gov",
			"state": "state",
			"errors": {
				"semItems": "Does not have any professional attributes holder registered"
			}
		},
		"digitalsign": {
			"nomeAutorizador": "Authorizer name",
			"idAutorizador": "Authorizer ID",
			"nomeUtilizador": "Owner",
			"secret": "Secret",
			"accountExpiration": "Validity",
			"usoGlobal": "Global use",
			"error": "Error",
			"dropdown": {
				"cliente": "Customer area",
				"certificado": "Open certificate",
				"gestao": "Certificate management"
			},
			"errors": {
				"nomeAutorizadorNulo": "Authorizer name cannot be null",
				"idAutorizadorNulo": "Authorizer ID cannot be null",
				"secretNula": "Secret cannot be null"
			}
		},
		"addsafecertificadomodal": {
			"title": "Add SAFE certificate",
			"titlePreProduction": "Add SAFE certificate (pre-production)",
			"fields": {
				"nomeConta": "Account name",
				"email": "Email",
				"validade": "Valid until"
			}
		},
		"digitalsignauthorizermodal": {
			"add": "Add Digital Sign certificate",
			"addPreProduction": "Add Digital Sign certificate (pre-production)",
			"update": "Edit Digital Sign certificate",
			"updatePreProduction": "Edit Digital Sign certificate (pre-production)"
		},
		"configemailsclientemodal": {
			"title": "Configure account email"
		},
		"postAddSafeCertificadoModal": {
			"message": "Please wait while we complete the process of adding a SAFE certificate...",
			"error": "An error occurred while completing the process of adding a SAFE certificate."
		},
		"certificadoAvailable": {
			"safe": "SAFE (Electronic Invoice Signing Service)",
			"safeTestes": "SAFE (Electronic Invoice Signing Service) Tests",
			"digitalSign": "Digital Sign - Qualified Electronic Seal",
			"digitalSignTestes": "Digital Sign - Qualified Electronic Seal Tests",
			"safeShort": "SAFE",
			"safeFullname": "Electronic Invoice Signing Service",
			"digitalSignShort": "Digital Sign",
			"digitalSignFullname": "Qualified Electronic Seal"
		},
		"configOptions": {
			"ambientePreProducao": "Pre-production environment"
		},
		"faq": {
			"dropdowntitle": "FAQ - Help",
			"faq1": "<h3>What is an electronic invoice and what is it for?</h3><br><p>Processing paper invoices is an expensive process for companies, with costs for citizens and companies consuming resources for the economy .",
			"faq2": "<h3>What should I do if the application is compromised (security-wise)?</h3><br><p>An application is considered compromised whenever SAFE tokens have been exposed.</p> <p>If the application is compromised, you must inform AMA within less than 4 hours (after becoming aware of the security incident), and cancel all accounts within the same period.</p><p>To do this, simply select the account that was compromised, and right-click to select \"cancel subscription account\", and follow the instructions.</p>",
			"faq3": "<h3>How should I proceed to create a new subscription account?</h3><br><p>To create a new subscription account, proceed as explained in the \"Guide for creating a new account\", in the button at the bottom side</p>",
			"faq4": "<h3>What is Article 12 of Decree-Law 28/2019 of February 15?</h3><br><p>Article 12 refers to the issuance of invoices electronically.",
			"faq5": "<h3>What is Decree-Law 12/2021 of February 9?</h3><br><p>This Decree-Law ensures the implementation in the domestic legal order of Regulation (EU) 910/2014, relating to electronic identification and trust services for electronic transactions in the internal market.</p>",
			"faq6": "<h3>How will SAFE tokens be used to make subscriptions?</h3><br><p>To respond to the aforementioned legislation, CentralGest needs to store the token (private key) of the subscription account created to be able to request the signature of a billing document.",
			"faq7": "<h3>Are the tokens stored securely?</h3><br><p>Yes, the tokens are encrypted using a secure algorithm before being stored, following SAFE standards, so they are not decipherable in a query to the database.</p>",
			"faq8": "<h3>When does CentralGest sign an invoice?</h3><br><p>After correct parameterization, CentralGest signs an invoice whenever it is sent by email, or whenever it is exported in the document printing module.</p><p>Whenever a document is to be signed, the user is informed in advance.</p>"
		},
		"guide": {
			"dropdowntitle": "Guide for creating a new account",
			"title": "Account creation flow",
			"atributosproftitle": "Professional Attributes",
			"atributosprof": "Before starting the account creation process, you must have assigned the professional attributes that allow you to sign electronic invoices<br>To do this, you must access the Autenticação.gov website and in the private area, access \"my professional attributes\".",
			"novacontatitle": "New account",
			"novaconta": "You must click on \"New\" to begin the process of creating a new account.",
			"autenticacaotitle": "Authentication",
			"autenticacao": "After the previous step, you will be directed to an authentication page at Authentication.gov.<br>In this step you must select the authentication method.",
			"autorizacaotitle": "Authorization",
			"autorizacao": "You must authorize the collection of some personal data in order to continue the process.",
			"autenticacaomovel": "In this case, authenticating with the Digital Mobile Key, you must enter the data necessary for authentication.",
			"autenticacaomovelcod": "You must enter the security code that was sent to your cell phone.",
			"obteratributostitle": "Get Attributes",
			"obteratributos": "After correct authentication, the process will be pending, so it will be necessary to obtain the attributes for the certificate.",
			"certificadotitle": "Certificate",
			"certificado": "Once the process is complete, we can verify that we have the active certificate."
		},
		"btn": {
			"ativar": "Activate",
			"desativar": "Disable"
		},
		"radioOptions": {
			"caption": "Signing Commercial Documents for:",
			"docfas": {
				"todos": "All Types of SAF-T Documents (FT,FR,ND,NC)",
				"selecionados": "Only Selected Document Types"
			},
			"clientes": {
				"todos": "All Customers",
				"selecionados": "Only Selected Customers"
			}
		}
	},
	"faturacaopublica": {
		"tab": {
			"porComunicarManual": "Docs.",
			"porComunicarWebServ": "Docs.",
			"comunicadoEmValidacao": "Docs.",
			"comunicadoComErro": "Docs.",
			"saphety": "Saphety",
			"feap": "FEAP-eSPAP",
			"comunicados": "Announcements"
		},
		"btn": {
			"config": "settings",
			"comunicarWebServ": "Communicate via web service",
			"comunicarManual": "Communicate manually",
			"enviarEmail": "Send email",
			"exportarAnexos": "Export attachments"
		},
		"menu": {
			"historico": "Historic",
			"comunicamanual": "Mark as reported manually",
			"comunicacaomanual": "Manual communication",
			"consutCliente": "Consult customer",
			"consultDoc": "Consult document"
		},
		"table": {
			"sel": "Sel.",
			"ndocAsStringDraftSufixo": "Document",
			"datadoc": "Doc Date.",
			"nClifo": "Account Code",
			"nomeClifo": "Account Name",
			"facPublicaModeloStr": "Face template.",
			"estadoDescricao": "Comments",
			"semdados": "No data"
		},
		"historico": {
			"title": "Historic",
			"data": "Date/Time",
			"documento": "Document",
			"estado": "state",
			"tipo": "Type",
			"codigo": "Code",
			"descricao": "Description",
			"nConta": "Account No.",
			"nomeConta": "Account Name",
			"nomeUtilizador": "Username"
		},
		"observacoes": {
			"title": "Comments",
			"observacoes": "Comments"
		},
		"doccomercial": {
			"title": "Commercial Doc."
		},
		"config": {
			"title": "Setting up public electronic invoicing",
			"utilizador": "User",
			"senha": "Password",
			"endereco": "URL",
			"estados": "States",
			"estadosConta": "By Default",
			"table": {
				"estado": "state",
				"conta": "Account"
			}
		},
		"messages": {
			"refreshsuccess": "Updated successfully!",
			"observacoesvazia": "You must enter a note",
			"contavazia": "You have to create an account",
			"estadodesc": "Invalid state"
		},
		"modal": {
			"comunicacaomanual": {
				"title": "Manual communication - Generated attachments",
				"anexos": "Attachments",
				"btn": {
					"sendmail": "Send by email",
					"export": "Export"
				}
			}
		},
		"estado": {
			"porComunicar": "By Communicate",
			"comunicadoEmValidacao": "Announcement in validation",
			"comunicadoValidado": "Validated statement",
			"comunicadoComErro": "Error statement",
			"erroTratado": "Error handled",
			"received": "Received",
			"rejected": "Rejected",
			"paid": "Paid out",
			"comunicadoEstruturaEmValidacao": "Announcement structure under validation"
		},
		"errors": {
			"faturacaoeletronicanaoconfigurada": "To communicate files via CIUS-PT, Electronic Invoicing must be configured",
			"errogerarficheiros": "Error generating files, confirm the configuration of Public Invoicing and Electronic Invoicing"
		}
	},
	"fct": {
		"tabEmpresas": "Companies",
		"tabDocumentos": "Documents",
		"tableDefinition": {
			"nempresa": "N. Company",
			"nome": "Company name",
			"emitido": "Doc. Issued",
			"valor": "Amount",
			"valorMesAnterior": "Previous Month Value",
			"mensagem": "Message",
			"dataEmissao": "Issuance date",
			"documento": "Document",
			"dataPagamento": "Payment Date",
			"tipoPagamento": "Payment Type",
			"emitirTipoDocumento": "Issue",
			"pdf": "PDF",
			"valorFCTNoCG": "Total Value in CG",
			"email": "Email",
			"valorEmAtraso": "Overdue amount",
			"valorAPagar": "Amount to pay",
			"identificacao": "Identification",
			"contrato": "Contract",
			"rendimentos": "Income",
			"totalRetidoFundos": "Total Retained Funds",
			"fctAno": "FCT Year",
			"fctMes": "FCT Month",
			"codemp": "Emp. Code.",
			"nomeEmp": "Name",
			"niss": "NISS",
			"iban": "IBAN",
			"modalidadeContrato": "Contract",
			"dataEntrada": "Date Entry",
			"dataInicioContrato": "Start Date",
			"dataFimContrato": "End Date",
			"valorRetribuicaoMensal": "Monthly Remuneration",
			"valorDiuturnidade": "Diuturnity Value",
			"qtdFaltasInjustificadas": "Quantity of unexcused absences",
			"valorFaltasInjustificadas": "Value Fouls",
			"totalFCT": "Total FCT",
			"totalFGCT": "Total FGCT",
			"valorPago": "Amount paid",
			"dataCessacaoContrato": "Contract Termination Date",
			"motivo": "Reason",
			"entregasPagas": "Paid Deliveries",
			"numeroUPs": "UP's number",
			"valorEstimadoReembolso": "Estimated Refund Amount",
			"numTrabalhadores": "Num. Workers"
		},
		"toolbar": {
			"obterValores": "Get FCT Value",
			"emitirDocumento": "Issue Payment Document",
			"downloadDoc": "Download Document",
			"viewTodosTrabComFct": "All Workers with CF",
			"comSelecionados": "Operation with selected...",
			"pedirReembolso": "Request Refund"
		},
		"messages": {
			"selectRecords": "You must select at least one company.",
			"erroDownload": "Error downloading the document.",
			"emitidoComSucesso": "The Payment Document was issued successfully.",
			"configurarCredentials": "Configure FCT credentials",
			"valorObtidoComSucesso": "Value obtained from the FCT portal successfully",
			"porPagar": "Unpaid",
			"emailEnviado": "Email successfully sent!",
			"emailNaoEnviado": "Email was not sent.",
			"modalPromptMessage": "Are you sure you want to issue payment documents to selected {{numEmpSelected}}?",
			"verificaEmail": "You must fill in the email",
			"enviarEmailTooltip": "Send the payment document by email",
			"verifiqueEmails": "Check that the emails are completed and correct.",
			"httpErrorTenteNovamente": "An unexpected error has occurred.",
			"selecionaEmpresa": "You have to select the company.",
			"selecionarCriterio": "To carry out the search you must select at least one criterion.",
			"selectAtLeastOneRecord": "You must select at least one record.",
			"operacaoConcluidaVerifiqueMsgs": "Operation completed successfully.",
			"naoFoiPossivelPedirReembolsoParaAlgunsTrabalhadores": "It was not possible to make a refund request for some workers.",
			"dataFimIgualPosteriorDataInicio": "The search end date must be equal to or later than the search start date",
			"dataCessInicialEmpty": "You must fill in the start date of the contract termination",
			"dataCessFinalEmpty": "You must fill in the end date of the contract termination",
			"pedidoReembolsoEfetuadoComSucesso": "The refund request was made successfully.",
			"visualizarPedidosEfetuados": "View orders placed."
		},
		"strings": {
			"de": "In",
			"ate": "Until",
			"tipoSearchDoc": "Type Doc.",
			"empresa": "Company",
			"pesquisar": "To look for",
			"searchTipoDocTodos": "ALL",
			"searchTipoDocADEF": "FCT ACCESSION DOCUMENT",
			"searchTipoDocADEG": "FGCT ACCESSION DOCUMENT",
			"searchTipoDocDOCP": "PAYMENT DOCUMENT",
			"detalhes": "Details",
			"valorTotal": "Amount",
			"valorMesAnterior": "Previous Month Value",
			"loginFCT": "FCT Login",
			"niss": "NISS",
			"password": "Password",
			"confirmTitle": "Confirmation",
			"sendEmail": "Send email with payment document",
			"downloadDoc": "Download the payment document",
			"enviarEmail": "Send Email",
			"accessoFCT": "FCT Access",
			"viewTodosTrabComFctModalTitle": "All workers with a compensation fund",
			"todos": "All",
			"CDCI": "TERMINATED DUE TO DISMISSAL WITH THE RIGHT TO COMPENSATION",
			"CDSI": "TERMINATED DUE TO DISMISSAL WITHOUT THE RIGHT TO COMPENSATION",
			"CRV": "CEASED BY VOLUNTARY TERMINATION",
			"efetuarPedido": "Place Order",
			"efetuarPedidos": "Place Orders",
			"pedidosReembolsos": "Refund Requests",
			"pesquisarTrabalhadores": "Search Workers",
			"dataCessInicial": "Contract Termination Date",
			"a": "The",
			"estadoContrato": "Contract Status",
			"selecionarTodos": "Select all",
			"deselecionarTodos": "Clear selection",
			"pedidosReembolsosEfetuados": "{{nCompany}} - Refund requests made"
		},
		"tooltips": {
			"obterValores": "Get value from the Compensation Funds portal",
			"emitirDocumento": "Issue the payment document",
			"enviarEmail": "Send the email with the payment document",
			"downloadDoc": "Download the payment document",
			"viewTodosTrabComFct": "View data for all workers with a compensation fund",
			"comSelecionados": "Select the operation you want to perform with selected records",
			"pedirReembolso": "Make a refund request",
			"selecionarTodos": "Select all workers",
			"deselecionarTodos": "Clear selection",
			"expandAllHaveEmployees": "Expand everyone who has workers",
			"collapseAll": "Collapse all",
			"efetuarPedidosDestaEmpresa": "Make a reimbursement request for selected employees of this company"
		}
	},
	"fileviewer": {
		"table": {
			"nome": "Name",
			"tamanho": "Size",
			"datamod": "Mod date",
			"horamod": "Mod time.",
			"datacriacao": "Creation date",
			"horacriacao": "Creation time",
			"utilizador": "User",
			"docexterno": "External Doc",
			"classificador": "Classifier",
			"pasta": "Folder"
		},
		"header": {
			"descBtn": "See how:"
		},
		"tooltip": {
			"visualizar": "To view",
			"download": "Download",
			"onlyPDF": "Viewer for PDF format only.",
			"delete": "To remove",
			"upload": "To load"
		}
	},
	"fimano": {
		"steps": {
			"welcome": "Welcome",
			"data": "Data",
			"validations": "Validations",
			"execute": "To execute"
		},
		"text": {
			"welcome": {
				"warning0": "The year-end operation must be carried out by an administrator!",
				"warning1": "Make sure you have made backup copies of your current company.",
				"warning2": "Click \"Next\" to continue the year-end operation.",
				"errorNext": "It is not possible to proceed with the year end due to the following error(s):"
			},
			"data": {
				"company": "Company",
				"accountingPeriod": "Accounting Period",
				"ircYear": "IRC Year",
				"yearToClose": "Year ending",
				"nDiario": "Diary (Opening Balances)",
				"nDescritivo": "Description (Opening Balances)",
				"scheduleFor": "Schedule for",
				"saldaContasClasse0": "Pay Class 0 accounts",
				"saldaContasClasse9": "Pay Class 9 accounts",
				"fazTratamentoME": "Handles Balances in Foreign Currency",
				"passaApenasSaldoCC": "Pass only current account balance"
			},
			"validations": {
				"beforeExecute0": "Before executing the year-end it is necessary to carry out some validations.",
				"beforeExecute1": "Note: This process may take some time.",
				"btnValidate": "Validate",
				"executing": "Running validations...",
				"noErrors": "There are no errors."
			},
			"execute": {
				"text0": "Everything is ready to begin the process of closing the year.",
				"text1": "Take into account that after scheduling it, the current company will be <strong>blocked</strong> and <strong>inaccessible</strong> until the process is completed.",
				"doExecute": "Click \"Finish\" to close the year."
			}
		}
	},
	"fimperiodoenc": {
		"title_detail": "Period {{id}}",
		"title_new": "End of Period",
		"title_plural": "Periods",
		"pesquisa": "To look for",
		"saved": "Period {{id}}, saved successfully.",
		"error": "Period {{id}}, cannot be saved.",
		"fields": {
			"periodo": "Period",
			"periodos": "Periods:",
			"nomePeriodo": "Name",
			"observacoes": "Comments",
			"flagRigorAbsoluto": "Closed"
		},
		"placeholders": {
			"periodo": "Period Code",
			"observacoes": "Comments"
		},
		"messages": {
			"successfullychanged": "Period changed successfully",
			"docsnaosaldados": "There are unsettled documents!"
		},
		"btn": {
			"applyperio": "Change company period",
			"wontchangeperio": "Do not change company period"
		},
		"titleListaEncerrados": "List of closed diaries",
		"titleDiariosDisponiveis": "Available",
		"titleDiariosSelecionados": "Selected",
		"changePeriodo": "Change Company Accounting Period",
		"fazerVerificacaoDocsNSaldTitle": "Check Unbalanced Documents",
		"fazerVerificacaoDocsNSald": "Press Ok if you want to check for unsettled documents, or Cancel if you want to proceed without checking.",
		"temDocsNSald": "There are unpaid documents in the period."
	},
	"fluxoMonetario": {
		"pesquisa": "Money flows",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"undefined": "Undefined",
			"doesNotHave": "It does not have",
			"paymentInTheAct": "Payment on the spot",
			"credit": "Credit"
		}
	},
	"formaIncrementoNDoc": {
		"pesquisa": "Document number increment forms",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"numberingRestartsEachPeriod": "Numbering restarts in each Period",
			"sequentialNumbering": "Sequential numbering (regardless of the Period)"
		}
	},
	"fregVig": {
		"data": {
			"AGUEDA": "AGUEDA",
			"ALBERGARIA_A_VELHA": "ALBERGARIA-A-VELHA",
			"ANADIA": "ANADIA",
			"AROUCA": "AROUCA",
			"AVEIRO_1": "AVEIRO-1.",
			"AVEIRO_2": "AVEIRO-2.",
			"CASTELO_DE_PAIVA": "PAIVA CASTLE",
			"ESPINHO": "THORN",
			"ESTARREJA": "ESTARREJA",
			"FEIRA_2": "FAIR-2",
			"FEIRA_1": "FAIR-1",
			"FEIRA_4": "FAIR-4",
			"FEIRA_3": "FAIR-3",
			"ILHAVO": "ILHAVO",
			"MEALHADA": "MEALHADA",
			"MURTOSA": "MURTOSA",
			"OLIVEIRA_AZEMEIS": "OLIVEIRA AZEMEIS",
			"OLIVEIRA_DO_BAIRRO": "OLIVEIRA DO BAIRRO",
			"OVAR": "OVAR",
			"S_JOAO_DA_MADEIRA": "S. JOAO DA MADEIRA",
			"SEVER_DO_VOUGA": "SEVER DO VOUGA",
			"VAGOS": "VACANCIES",
			"VALE_DE_CAMBRA": "CAMBRA VALLEY",
			"ALJUSTREL": "ALJUSTREL",
			"ALMODOVAR": "ALMODOVAR",
			"ALVITO": "ALVITO",
			"BARRANCOS": "BARRANCOS",
			"BEJA": "BEJA",
			"CASTRO_VERDE": "CASTRO VERDE",
			"CUBA": "CUBA",
			"FERREIRA_DO_ALENTEJO": "FERREIRA DO ALENTEJO",
			"MERTOLA": "MERTOLA",
			"MOURA": "MOURA",
			"ODEMIRA": "ODEMIRA",
			"OURIQUE": "OURIQUE",
			"SERPA": "SERPA",
			"VIDIGUEIRA": "VIDIGUEIRA",
			"AMARES": "AMARES",
			"BARCELOS": "BARCELOS",
			"BRAGA_1": "BRAGA-1.",
			"BRAGA_2": "BRAGA-2.",
			"CABECEIRAS_DE_BASTO": "BASTO HEADBOARDS",
			"CELORICO_DE_BASTO": "CELORICO DE BASTO",
			"ESPOSENDE": "ESPOSENDE",
			"FAFE": "FAFE",
			"GUIMARAES_2": "GUIMARAES-2.",
			"GUIMARAES_1": "GUIMARAES-1.",
			"POVOA_DE_LANHOSO": "PEOPLE OF LANHOSO",
			"TERRAS_DE_BOURO": "LANDS OF BOURO",
			"VIEIRA_DO_MINHO": "VIEIRA DO MINHO",
			"VILA_N_FAMALICAO_1": "VILA N.FAMALICAO-1.",
			"VILA_N_FAMALICA0_2": "VILA N.FAMALICAO 2.",
			"VILA_VERDE": "GREEN VILLE",
			"VIZELA": "VIZELA",
			"ALFANDEGA_DA_FE": "FE CUSTOMS",
			"BRAGANCA": "BRAGANCA",
			"CARRAZEDA_DE_ANSIAES": "CARRAZEDA DE ANSIAES",
			"FR_DE_ESPADA_A_CINTA": "FR FROM SWORD TO BELT",
			"MACEDO_DE_CAVALEIROS": "MACEDO DE CAVALEIROS",
			"MIRANDA_DO_DOURO": "MIRANDA DO DOURO",
			"MIRANDELA": "MIRANDELA",
			"MOGADOURO": "MOGADOURO",
			"TORRE_DE_MONCORVO": "MONCORVO TOWER",
			"VILA_FLOR": "VILA FLOR",
			"VIMIOSO": "VIMIOUS",
			"VINHAIS": "VINEYARDS",
			"BELMONTE": "BELMONTE",
			"CASTELO_BRANCO_2": "WHITE CASTLE-2.",
			"CASTELO_BRANCO_1": "WHITE CASTLE-1.",
			"COVILHA": "COVILHA",
			"FUNDAO": "FUNDAO",
			"IDANHA_A_NOVA": "IDANHA-A-NOVA",
			"OLEIROS": "POTTERS",
			"PENAMACOR": "PENAMACOR",
			"PROENCA_A_NOVA": "PROENCA-A-NOVA",
			"SERTA": "SERTA",
			"VILA_DE_REI": "VILA DE REI",
			"VILA_VELHA_DE_RODAO": "VILA VELHA DE RODAO",
			"ARGANIL": "ARGANILE",
			"CANTANHEDE": "CANTANHEDE",
			"COIMBRA_1": "COIMBRA-1.",
			"COIMBRA_2": "COIMBRA-2.",
			"CONDEIXA_A_NOVA": "CONDEIXA-A-NOVA",
			"FIGUEIRA_DA_FOZ_1": "FIGUEIRA DA FOZ-1.",
			"FIGUEIRA_DA_FOZ_2": "FIGUEIRA DA FOZ 2.",
			"GOIS": "GOIS",
			"LOUSA": "BLACKBOARD",
			"MIRA": "AIM",
			"MIRANDA_DO_CORVO": "MIRANDA DO CORVO",
			"MONTEMOR_O_VELHO": "MONTEMOR-O-VELHO",
			"OLIVEIRA_DO_HOSPITAL": "OLIVEIRA DO HOSPITAL",
			"PAMPILHOSA_DA_SERRA": "PAMPILHOSA DA SERRA",
			"PENACOVA": "ONE FOOT IN THE GRAVE",
			"PENELA": "PENELA",
			"SOURE": "SOUR",
			"TABUA": "BOARD",
			"VILA_NOVA_DE_POIARES": "VILA NOVA DE POIARES",
			"ALANDROAL": "ALANDROAL",
			"ARRAIOLOS": "ARRAIOLOS",
			"BORBA": "BORBA",
			"ESTREMOZ": "ESTREMOZ",
			"EVORA": "EVORA",
			"MONTEMOR_O_NOVO": "MONTEMOR-O-NOVO",
			"MORA": "LIVES",
			"MOURAO": "MOURAO",
			"PORTEL": "PORTEL",
			"REDONDO": "ROUND",
			"REGUENGOS_DE_MONSARAZ": "REGUENGOS DE MONSARAZ",
			"VENDAS_NOVAS": "NEW SALES",
			"VIANA_DO_ALENTEJO": "VIANA DO ALENTEJO",
			"VILA_VICOSA": "VILA VICOSA",
			"ALBUFEIRA": "ALBUFEIRA",
			"ALCOUTIM": "ALCOUTIM",
			"ALJEZUR": "ALJEZUR",
			"CASTRO_MARIM": "CASTRO MARIM",
			"FARO": "FARO",
			"LAGOA_ALGARVE": "LAGOA (ALGARVE)",
			"LAGOS": "LAGOS",
			"LOULE_1": "LOULE-1",
			"LOULE_2": "LOULE-2",
			"MONCHIQUE": "MONCHIQUE",
			"OLHAO": "OLHÃO",
			"PORTIMAO": "PORTIMAO",
			"S_BRAS_DE_ALPORTEL": "S.BRAS DE ALPORTEL",
			"SILVES": "SILVES",
			"TAVIRA": "TAVIRA",
			"VILA_DO_BISPO": "VILA DO BISPO",
			"VILA_REAL_S_ANTONIO": "VILA REAL S.ANTONIO",
			"AGUIAR_DA_BEIRA": "AGUIAR DA BEIRA",
			"ALMEIDA": "ALMEIDA",
			"CELORICO_DA_BEIRA": "CELORICO DA BEIRA",
			"FIG_CASTELO_RODRIGO": "FIG.",
			"FORNOS_DE_ALGODRES": "COTTON FURNACES",
			"GOUVEIA": "GOUVEIA",
			"GUARDA": "GUARD",
			"MANTEIGAS": "BUTTERS",
			"MEDA": "ME OF",
			"PINHEL": "PINHEL",
			"SABUGAL": "SABUGAL",
			"SEIA": "SEIA",
			"TRANCOSO": "TRANCOSO",
			"VILA_NOVA_DE_FOZ_COA": "VILA NOVA DE FOZ COA",
			"ALCOBACA": "ALCOBACA",
			"ALVAIAZERE": "ALVAIAZERE",
			"ANSIAO": "ANXIETY",
			"BATALHA": "BATTLE",
			"BOMBARRAL": "BOMBARRAL",
			"CALDAS_DA_RAINHA": "QUEEN SYRUP",
			"CASTANHEIRA_DE_PERA": "PEAR CHESTNUT TREE",
			"FIGUEIRO_DOS_VINHOS": "FIGUEIRO DOS VINOS",
			"LEIRIA_2": "LEIRIA-2.",
			"LEIRIA_1": "LEIRIA-1.",
			"MARINHA_GRANDE": "LARGE NAVY",
			"NAZARE": "NAZARE",
			"OBIDOS": "OBIDOS",
			"PEDROGAO_GRANDE": "PEDROGAO GRANDE",
			"PENICHE": "PENICHE",
			"POMBAL": "POMBAL",
			"PORTO_DE_MOS": "PORT OF MOS",
			"ALENQUER": "ALENQUER",
			"ARRUDA_DOS_VINHOS": "ARRUDA DOS VINOS",
			"AZAMBUJA": "AZAMBUJA",
			"CADAVAL": "CADAVAL",
			"CASCAIS_1": "CASCAIS-1.",
			"CASCAIS_2": "CASCAIS-2.",
			"LISBOA_7": "LISBON-7",
			"LISBOA_9": "LISBON-9",
			"LISBOA_5": "LISBON-5",
			"LISBOA_10": "LISBON-10",
			"LISBOA_11": "LISBON-11",
			"LISBOA_6": "LISBON-6",
			"LISBOA_8": "LISBON-8",
			"LISBOA_1": "LISBON-1",
			"LISBOA_4": "LISBON-4",
			"LISBOA_2": "LISBON-2",
			"LISBOA_3": "LISBON-3",
			"LOURES_1": "LOURES-1",
			"LOURES_3": "LOURES-3",
			"LOURES_4": "LOURES-4.",
			"LOURINHA": "LOURINHA",
			"MAFRA": "MAFRA",
			"OEIRAS_2": "OEIRAS-2.",
			"OEIRAS_3_ALGES": "OEIRAS-3.ALGES",
			"OEIRAS_1": "OEIRAS-1.",
			"SINTRA_2_ALGUEIRAO": "SINTRA-2.",
			"SINTRA_1": "SINTRA-1",
			"SINTRA_4_QUELUZ": "SINTRA-4.",
			"SINTRA_3_CACEM": "SINTRA-3.",
			"SOBRAL_MONTE_AGRACO": "SOBRAL MONTE AGRACO",
			"TORRES_VEDRAS": "TORRES VEDRAS",
			"VILA_FRANCA_XIRA_2": "VILA FRANCA XIRA-2.",
			"VILA_FRANCA_XIRA_1": "VILA FRANCA XIRA-1",
			"AMADORA_3": "AMATEUR-3.",
			"AMADORA_2": "AMATEUR-2.",
			"AMADORA_1": "AMATEUR-1.",
			"ODIVELAS": "ODIVELAS",
			"ALTER_DO_CHAO": "CHANGING THE FLOOR",
			"ARRONCHES": "ARRONCHES",
			"AVIS": "AVIS",
			"CAMPO_MAIOR": "BIGGER FIELD",
			"CASTELO_DE_VIDE": "VIDE CASTLE",
			"CRATO": "CRATO",
			"ELVAS": "ELVAS",
			"FRONTEIRA": "BORDER",
			"GAVIAO": "HAWK",
			"MARVAO": "MARVAO",
			"MONFORTE": "MONFORTE",
			"NISA": "NISA",
			"PONTE_DE_SOR": "PONTE DE SOR",
			"PORTALEGRE": "PORTALEGRE",
			"SOUSEL": "SOUSEL",
			"AMARANTE": "AMARANTE",
			"BAIAO": "BAIAO",
			"FELGUEIRAS": "FELGUEIRAS",
			"GONDOMAR_2": "GONDOMAR-2",
			"GONDOMAR_1": "GONDOMAR-1",
			"LOUSADA": "LOUSADA",
			"MAIA": "MAIA",
			"MARCO_DE_CANAVESES": "MARCO DE CANAVESES",
			"MATOSINHOS_2": "MATOSINHOS-2.",
			"MATOSINHOS_1": "MATOSINHOS-1",
			"PACOS_DE_FERREIRA": "PACOS DE FERREIRA",
			"PAREDES": "WALLS",
			"PENAFIEL": "PENAFIEL",
			"PORTO_1": "PORTO-1",
			"PORTO_3": "PORTO-3",
			"PORTO_4": "PORTO-4",
			"PORTO_2": "PORTO-2",
			"PORTO_5": "PORTO-5",
			"POVOA_DE_VARZIM": "POVOA DE VARZIM",
			"SANTO_TIRSO": "SANTO TIRSO",
			"VALONGO_2_ERMESINDE": "VALONGO-2.",
			"VALONGO_1": "VALONGO-1.",
			"VILA_DO_CONDE": "VILA DO CONDE",
			"VILA_NOVA_DE_GAIA_3": "VILA NOVA DE GAIA-3",
			"VILA_NOVA_DE_GAIA_1": "VILA NOVA DE GAIA-1.",
			"VILA_NOVA_DE_GAIA_2": "VILA NOVA DE GAIA-2.",
			"TROFA": "TROPHY",
			"ABRANTES": "COMPREHENSIVE",
			"ALCANENA": "ALCANENA",
			"ALMEIRIM": "ALMEIRIM",
			"ALPIARCA": "ALPIARCA",
			"BENAVENTE": "BENAVENTE",
			"CARTAXO": "CARTAXO",
			"CHAMUSCA": "SINGE",
			"CONSTANCIA": "CONSTANCY",
			"CORUCHE": "CORUCHE",
			"ENTRONCAMENTO": "ENTRANCE",
			"FERREIRA_DO_ZEZERE": "FERREIRA DO ZEZERE",
			"GOLEGA": "GOLEGA",
			"MACAO": "MACAO",
			"RIO_MAIOR": "RIO MAIOR",
			"SALVATERRA_DE_MAGOS": "SAVETERRA OF MAGIC",
			"SANTAREM": "SANTAREM",
			"SARDOAL": "SARDOAL",
			"TOMAR": "TO TAKE",
			"TORRES_NOVAS": "NEW TOWERS",
			"VILA_N_DA_BARQUINHA": "VILA N. DA BARQUINHA",
			"OUREM": "OUREM",
			"ALCACER_DO_SAL": "ALCACER DO SAL",
			"ALCOCHETE": "ALCOCHETE",
			"ALMADA_1": "ALMADA-1.",
			"ALMADA_3_C_CAPARICA": "ALMADA-3.",
			"ALMADA_2_C_PIEDADE": "ALMADA-2.",
			"BARREIRO": "BARREIRO",
			"GRANDOLA": "GRANDOLA",
			"MOITA": "MOITA",
			"MONTIJO": "MONTIJO",
			"PALMELA": "PALMELA",
			"SANTIAGO_DO_CACEM": "SANTIAGO DO CACEM",
			"SEIXAL_2": "SEIXAL-2.",
			"SEIXAL_1": "SEIXAL 1.",
			"SESIMBRA": "SESIMBRA",
			"SETUBAL_1": "SETUBAL-1.",
			"SETUBAL_2": "SETUBAL 2.",
			"SINES": "SINES",
			"ARCOS_DE_VALDEVEZ": "ARCOS DE VALDEVEZ",
			"CAMINHA": "WALK",
			"MELGACO": "MELGACO",
			"MONCAO": "MONSOON",
			"PAREDES_DE_COURA": "LEATHER WALLS",
			"PONTE_DA_BARCA": "BARCA BRIDGE",
			"PONTE_DE_LIMA": "PONTE DE LIMA",
			"VALENCA": "VALENCIA",
			"VIANA_DO_CASTELO": "VIANA DO CASTELO",
			"VILA_NOVA_CERVEIRA": "VILA NOVA CERVEIRA",
			"ALIJO": "ALIJO",
			"BOTICAS": "BOTICAS",
			"CHAVES": "KEYS",
			"MESAO_FRIO": "COLD MESAO",
			"MONDIM_DE_BASTO": "MONDIM DE BASTO",
			"MONTALEGRE": "MONTALEGRE",
			"MURCA": "MURCA",
			"PESO_DA_REGUA": "WEIGHT OF THE RULE",
			"RIBEIRA_DE_PENA": "RIBEIRA DE PENA",
			"SABROSA": "TASTY",
			"SANTA_MARTA_PENAGUIAO": "SANTA MARTA PENAGUIAO",
			"VALPACOS": "VALPACOS",
			"VILA_POUCA_DE_AGUIAR": "VILA POUCA DE AGUIAR",
			"VILA_REAL": "REAL VILLAGE",
			"ARMAMAR": "ARM",
			"CARREGAL_DO_SAL": "SALT CARREGAL",
			"CASTRO_DAIRE": "CASTRO DAIRE",
			"CINFAES": "CINFAES",
			"LAMEGO": "LAMEGO",
			"MANGUALDE": "MANGUALDE",
			"MOIMENTA_DA_BEIRA": "MOIMENTA DA BEIRA",
			"MORTAGUA": "MORTAGUA",
			"NELAS": "IN THEM",
			"OLIVEIRA_DE_FRADES": "OLIVEIRA DE FRADES",
			"PENEDONO": "PENEDONO",
			"RESENDE": "RESENDE",
			"SANTA_COMBA_DAO": "SANTA COMBA DAO",
			"S_JOAO_DA_PESQUEIRA": "S.JOAO DA PESQUEIRA",
			"S_PEDRO_DO_SUL": "S.PEDRO DO SUL",
			"SATAO": "SATAO",
			"SERNANCELHE": "SERNANCELHE",
			"TABUACO": "TABUACO",
			"TAROUCA": "TAROUCA",
			"TONDELA": "TONDELA",
			"VILA_NOVA_DE_PAIVA": "VILA NOVA DE PAIVA",
			"VISEU": "VISEU",
			"VOUZELA": "VOUZELA",
			"ANGRA_DO_HEROISMO": "ANGRA DO HEROISM",
			"CALHETA_S_JORGE": "CALHETA (S.JORGE)",
			"S_CRUZ_DA_GRACIOSA": "S.CRUZ DA GRACIOSA",
			"VELAS": "CANDLES",
			"PRAIA_DA_VITORIA": "VICTORIA BEACH",
			"CORVO": "CROW",
			"HORTA": "VEGETABLE GARDEN",
			"LAJES_DAS_FLORES": "FLOWER SLABS",
			"LAGES_DO_PICO": "LAGES DO PICO",
			"MADALENA": "MADALENA",
			"S_CRUZ_DAS_FLORES": "S.CRUZ DAS FLORES",
			"S_ROQUE_DO_PICO": "S.ROQUE DO PICO",
			"LAGOA_S_MIGUEL": "LAGOA (S. MIGUEL)",
			"NORDESTE": "NORTH EAST",
			"PONTA_DELGADA": "THIN TIP",
			"POVOACAO": "VILLAGE",
			"RIBEIRA_GRANDE": "RIBEIRA GRANDE",
			"VILA_FRANCA_DO_CAMPO": "VILA FRANCA DO CAMPO",
			"VILA_DO_PORTO": "VILA DO PORTO",
			"CALHETA_MADEIRA": "CALHETA - WOOD",
			"CAMARA_DE_LOBOS": "CHAMBER OF WOLVES",
			"FUNCHAL_2": "FUNCHAL-2.",
			"FUNCHAL_1": "FUNCHAL-1.",
			"MACHICO": "MACHICO",
			"PONTA_DO_SOL": "PONTA DO SOL",
			"PORTO_MONIZ": "PORTO MONIZ",
			"RIBEIRA_BRAVA": "RIBEIRA BRAVA",
			"SANTA_CRUZ_MADEIRA": "SANTA CRUZ (MADEIRA)",
			"SANTANA": "SANTANA",
			"S_VICENTE_MADEIRA": "S.VICENTE (MADEIRA)"
		}
	},
	"freguesias": {
		"title_detail": "Parish {{id}}",
		"title_new": "New Parish",
		"title_plural": "Parishes",
		"pesquisa": "To look for",
		"saved": "Parish {{id}}, saved successfully",
		"error": "Parish {{id}}, cannot be saved.",
		"deleted": "Parish {{id}}, successfully deleted",
		"fields": {
			"nFreguesia": "Parish Code",
			"designacaoBreve": "Brief Designation",
			"designacaoCompleta": "Full Designation"
		}
	},
	"fremun": {
		"title_detail": "Form of Remuneration {{id}}",
		"title_new": "New Form of Remuneration",
		"title_plural": "Forms of Remuneration",
		"pesquisa": "To look for",
		"saved": "Remuneration Form {{id}}, saved successfully",
		"error": "Remuneration Form {{id}}, cannot be saved.",
		"deleted": "Remuneration Form {{id}}, successfully deleted",
		"fields": {
			"nFRemun": "Remuneration Form Code",
			"designacaoBreve": "Brief Designation",
			"designacaoCompleta": "Full Designation"
		}
	},
	"funcoes": {
		"title_detail": "Function {{id}}",
		"title_new": "New function",
		"title_plural": "Functions",
		"pesquisa": "To look for",
		"saved": "Function {{id}}, saved successfully",
		"error": "Function {{id}}, cannot be saved.",
		"deleted": "Function {{id}}, deleted successfully",
		"fields": {
			"nFuncao": "Function code",
			"designaBreve": "Brief designation",
			"designaComp": "Full designation"
		}
	},
	"generateAbdes": {
		"title": "Generate new {{abndesc}} automatically",
		"gerar": "To generate",
		"errorcateg": "You must select a category.",
		"abono": "Allowance",
		"desconto": "Discount",
		"fields": {
			"categoria": "Category",
			"grupo": "Group",
			"nome": "Name",
			"descricao": "Description",
			"tipoArredondamento": "Rounding",
			"tipoRecolha": "Collection Type",
			"contribuiCalculoCustoPrecoH": "Contributes Price/Hour Cost Calculation",
			"codRem": "Social Security Remuneration Code",
			"codUnidade": "Unit Defect",
			"tipoRendimento": "Yield Type",
			"contaMovDeb": "Debit Account No.",
			"contaMovCred": "Credit Account No.",
			"contaEncDEB": "Debit Account No.",
			"contaEncCRE": "Credit Account No.",
			"ausencia": "Absence",
			"percAumentHExtra": "% Increase in Overtime",
			"nHorasDiaProvDesc": "Number of hours per day for loss of Meal Subsidy"
		},
		"crabdTable": {
			"fields": {
				"categoria": "Category",
				"empregado": "Ent.",
				"patronato": "Ent.",
				"desconto": "Discount",
				"nomeCategoria": "Category",
				"temIncidenciaEmpregado": "He has",
				"incidenciaEmpregado": "Incidence",
				"temIncidenciaPatronato": "He has",
				"incidenciaPatronato": "Incidence",
				"nDesconto": "Code.",
				"nomeDesconto": "Description"
			}
		},
		"stepCategoria": "Category",
		"stepPerfil": "Profile",
		"editarcruzamentos": {
			"title": "Crosses Edit",
			"subtitle": "Discount {{category}}",
			"desconto": "Discount",
			"temcruzent": "Has Crossing with Entity",
			"empregado": "Employee",
			"incidencia": "(%) Incidence:",
			"patronato": "Patronage",
			"temseleentidade": "You must select at least one entity to perform the crossing!",
			"teminserirdesc": "You have to introduce a discount!"
		},
		"abdesemp": "Employee Allowances and Discounts",
		"encpatronempresa": "Employer/Company Charges"
	},
	"objscandoctextinterpreter": {
		"invoiceType": {
			"none": "Unknown",
			"fatura": "Invoice",
			"faturaSimplificada": "Simplified Invoice",
			"faturaRecibo": "Invoice Receipt",
			"notaDebito": "Credit note",
			"notaCredito": "Debit note",
			"vendaDinheiro": "Sell ​​for Cash",
			"talaoVenda": "Sales Receipt",
			"talaoDevolucao": "Return Slip",
			"alienacaoAtivos": "Asset Disposal",
			"devolucaoAtivos": "Return of Assets",
			"premio": "Prize or prize receipt",
			"estorno": "Chargeback or refund receipt",
			"imputacaoCoSeguradoras": "Imputation to co-insurers",
			"imputacaoCoSeguradoraLider": "Imputation to lead co-insurer",
			"resseguroAceite": "Reinsurance accepted",
			"recibo": "Receipt",
			"notaLancamento": "Release note",
			"extratoBancario": "Bank statement",
			"pagamentoAoEstado": "Payment to the state",
			"levantamento": "Survey",
			"pagamento": "Payment",
			"deposito": "Deposit",
			"cheque": "Check",
			"transferencia": "Transfer"
		}
	},
	"gestaoFicheirosSepa": {
		"grid": {
			"fields": {
				"nContaForn": "Account No.",
				"nomeBeneficiario": "Name",
				"ibanContaDest": "IBAN",
				"swiftDest": "SWIFT",
				"valor": "Value",
				"dataLanc": "Creation date",
				"nomeFicheiro": "File name",
				"estadoNotificacao": "Supplier notification"
			},
			"actions": {
				"imprimirNotificar": "Print/Notify",
				"download": "Download SEPA file",
				"retrocederPorEnviar": "Change status to \"Unsend\" of the SEPA file"
			},
			"toolbar": {
				"meioPagamento": "Bank (Payment method)",
				"dataDe": "Payment date",
				"dataAte": "Until"
			}
		},
		"tabs": {
			"title": {
				"enviados": "Sent",
				"porEnviar": "For Submit"
			}
		},
		"btn": {
			"gerarFicheiroSEPA": "Generate SEPA file"
		},
		"error": {
			"pesquisar": "All search parameters must be filled in"
		}
	},
	"gestaodgemps": {
		"text": {
			"abonos": "Allowances",
			"faltas": "Fouls",
			"ferias": "Vacation",
			"servico": "Service",
			"view": "View",
			"yearly": "Yearly",
			"monthly": "Monthly",
			"month": "Month",
			"finsSemanaFeriados": "Weekends/holidays",
			"pendentes": "Pendants",
			"marcadas": "Marked",
			"aprovadas": "Approved",
			"integradas": "Integrated",
			"rejeitadas": "Rejected",
			"gozadas": "Cumshots",
			"restantes": "Remaining",
			"conflito": "Conflict",
			"attachments": "Attachments",
			"fromDate": "of",
			"toDate": "to the",
			"addNew": "Add new event",
			"motivoRejeicao": "Reason for rejection",
			"day": "Day {{date}}",
			"allDay": "{{date}}, all day",
			"fromTo": "From {{start}} to {{end}}",
			"fromToHours": "{{date}}, from {{start}} to {{end}}",
			"recordedWageProcessing": "Registered in salary processing",
			"details": "Details",
			"integraAll": "Integrate selected events",
			"integraAllGroup": "Integrate employee events",
			"approveAll": "Approve selected events",
			"approveAllGroup": "Approve employee events",
			"rejectAll": "Reject selected events",
			"rejectAllGroup": "Reject employee events",
			"setMotivoAll": "Set reason for selected events",
			"setMotivoAllGroup": "Define reason for employee events",
			"rewind": "Revert state",
			"rewindAll": "Revert state of selected events",
			"rewindAllGroup": "Revert employee event status",
			"markOff": "Mark off",
			"markOffAll": "Deselect selected events",
			"markOffAllGroup": "Unselect employee events",
			"addAttachment": "Add attachment",
			"downloadAttachment": "Download attachment",
			"replaceAttachment": "Replace attachment",
			"deleteAttachment": "Remove attachment",
			"history": "Event history",
			"noEvents": "There are no events on the selected date",
			"integrar": "To integrate",
			"prettyFeriasMarcadas": "{{marked}} Marked / {{total}} Total",
			"confirm": {
				"integraAll": "Are you sure you want to integrate all selected events?",
				"integraAllGroup": "Are you sure you want to integrate all events from the collaborator \"{{codeEmp}} - {{nomeEmp}}\"?",
				"approveAll": "Are you sure you want to approve all selected events?",
				"approveAllGroup": "Are you sure you want to approve all events for the collaborator \"{{codeEmp}} - {{nomeEmp}}\"?",
				"rejectAll": "Are you sure you want to reject all selected events?",
				"rejectAllGroup": "Are you sure you want to reject all events from the collaborator \"{{codeEmp}} - {{nomeEmp}}\"?",
				"rewindAll": "Are you sure you want to revert the status of all selected events?",
				"rewindAllGroup": "Are you sure you want to revert the status of all events for the collaborator \"{{codeEmp}} - {{nomeEmp}}\"?",
				"markOffAll": "Are you sure you want to deselect all selected events?",
				"markOffAllGroup": "Are you sure you want to deselect all events for the collaborator \"{{codeEmp}} - {{nomeEmp}}\"?",
				"deleteAttachment": "Are you sure you want to remove the selected attachment?"
			},
			"success": {
				"changed": "Successfully changed",
				"integrado": "Successfully integrated",
				"rejeitado": "Successfully rejected",
				"aprovado": "Successfully approved",
				"retrocede": "Successfully rolled back",
				"addAttachment": "Attachment added successfully",
				"replaceAttachment": "Attachment replaced successfully",
				"deleteAttachment": "Attachment removed successfully"
			},
			"warning": {
				"rewindAllPosicao": "Attention!"
			},
			"error": {
				"aprovado": "An error occurred while trying to approve"
			},
			"motivos": {
				"default": "reason",
				"retrocede": "Move back position"
			},
			"status": {
				"male": {
					"marcado": "MARKED (pending approval)",
					"aprovado": "APPROVED",
					"aprovadoParcialmente": "APPROVED (partially)",
					"rejeitado": "REJECTED",
					"integrado": "INTEGRATED",
					"gozado": "ENJOYED"
				},
				"female": {
					"marcado": "MARKED (pending approval)",
					"aprovado": "APPROVED",
					"aprovadoParcialmente": "APPROVED (partially)",
					"rejeitado": "REJECTED",
					"integrado": "INTEGRATED",
					"gozado": "CUM"
				}
			}
		},
		"errors": {
			"invalidTipoAbono": "You must define a type of allowance.",
			"invalidTipoFalta": "You must define a type of absence.",
			"invalidTipoFeria": "You must define a vacation type.",
			"invalidQuantidade": "You must define a quantity.",
			"invalidTipoProcessamento": "You must define a processing type.",
			"invalidDesconto": "You must define a discount."
		},
		"fields": {
			"codEmp": "Code.",
			"nomeCategoria": "Category",
			"data": "Date",
			"motivo": "Reason",
			"nomeEmpregado": "collaborator",
			"tipo": "Type",
			"tipoFalta": "Foul type",
			"tipoProcessamento": "Type of processing",
			"designacaoAbDes": "Discount",
			"dataTarefa": "Day",
			"datasTarefas": "Days",
			"alteraFaltas": "Change fouls",
			"novoTipo": "New type",
			"anexaFicheiro": "Attach file",
			"historico": "Historic",
			"quantidade": "Amount",
			"novaQuantidade": "New quantity",
			"allDay": "All day",
			"horaInicio": "Start time",
			"horaFim": "End time.",
			"valor": "Value",
			"dadosFixos": "Fixed data",
			"dataDe": "Date of",
			"dataAte": "Date until",
			"anexo": "Attachment",
			"report": "Listing",
			"daData": "From date",
			"ateData": "Until date",
			"codEmpDe": "Employee code",
			"codEmpAte": "Code employed until",
			"activeOnly": "Active only",
			"valorUnitario": "Unitary value",
			"dataProcessamento": "Processing date",
			"marcacoes": "Appointments"
		},
		"header": {
			"previous": "Previous",
			"today": "Today",
			"next": "Next",
			"previousYear": "Last year",
			"nextYear": "Next year",
			"previousMonth": "Last month",
			"nextMonth": "Next month"
		},
		"abonos": {
			"pageTitle": "Allowance management",
			"sidebarTitle": "Allowances",
			"motivos": {
				"marcar": "Scheduling allowances",
				"aprovar": "Approval of allowances",
				"rejeitar": "Rejection of allowances",
				"integrar": "Integration of allowances"
			},
			"marcarmodal": {
				"title": "Allowance booking request"
			},
			"cancelarmodal": {
				"title": "Unselect allowance",
				"message": "Are you sure you want to uncheck allowances?"
			}
		},
		"faltas": {
			"pageTitle": {
				"colaborador": "Marking of fouls",
				"gestor": "Absence management"
			},
			"sidebarTitle": {
				"colaborador": "Mark fouls",
				"gestor": "Fouls"
			},
			"motivos": {
				"marcar": "Marking of fouls",
				"aprovar": "Approval of absences",
				"rejeitar": "Rejection of fouls",
				"integrar": "Fault integration"
			},
			"marcarmodal": {
				"title": "Request for marking absences"
			},
			"cancelarmodal": {
				"title": "Clear fouls",
				"message": "Are you sure you want to cancel fouls?"
			}
		},
		"ferias": {
			"pageTitle": {
				"colaborador": "Vacation booking",
				"gestor": "Vacation management"
			},
			"sidebarTitle": {
				"colaborador": "Book a vacation",
				"gestor": "Vacation"
			},
			"motivos": {
				"marcar": "Vacation booking",
				"aprovar": "Vacation approval",
				"rejeitar": "Vacation rejection",
				"integrar": "Vacation integration"
			},
			"titles": {
				"marcadas": "Scheduled vacations",
				"rejeitadas": "Rejected vacation",
				"integradas": "Integrated holidays"
			},
			"marcarmodal": {
				"title": "Vacation booking request",
				"message": "Are you sure you want to book a vacation?",
				"marcarPara": "Mark for collaborators:"
			},
			"cancelarmodal": {
				"title": "Unbook vacation",
				"message": "Are you sure you want to cancel a vacation?"
			}
		},
		"details": {
			"titleAbonos": "Allowances {{date}}",
			"titleRangeAbonos": "Allowances {{start}} until {{end}}",
			"titleFaltas": "Fouls {{date}}",
			"titleRangeFaltas": "Fouls {{start}} to {{end}}",
			"titleFerias": "Vacation {{date}}",
			"titleRangeFerias": "Vacation {{start}} until {{end}}"
		},
		"summary": {
			"title": "Summary",
			"integradas": "integrated {{value}}"
		},
		"historymodal": {
			"title": "Historic"
		},
		"motivomodal": {
			"title": "Set reason",
			"titleGlobal": "Set the reason for selected events",
			"titleGroup": "Set the reason for employee events: \"{{EmpName}}\""
		},
		"printmodal": {
			"title": "Event printing"
		}
	},
	"gestorrh": {
		"servicos": {
			"fields": {
				"colaborador": "collaborator",
				"nome": "Name",
				"papel": "Paper"
			},
			"errors": {
				"unicoServico": "There is already a collaborator associated with the selected role in this service"
			}
		}
	},
	"global": {
		"centralgestLoginName": "CentralGest - Software Production, SA",
		"title": "CentralGest Cloud",
		"browsehappy": "You are using an <strong>outdated</strong> browser.",
		"menu": {
			"developer": "Developer",
			"empresa": "Company",
			"home": "Home",
			"demo": "Demo",
			"artigos": "Articles",
			"recibos": "Receipts",
			"pagamentos": "Payments",
			"clifos": "The 3rd",
			"clientes": "Customer records",
			"fornecedores": "Supplier sheets",
			"outrosdevedorescredores": "Records of other debtors and creditors",
			"options": "options",
			"configs": "settings",
			"configsSite": "Email configuration",
			"configsErp": "ERP Configuration",
			"erpCheck": "Validation of settings",
			"docscomerciais": "Documents",
			"docscontabilidade": "Collection of releases",
			"vendas": "Sales",
			"compras": "Shopping",
			"guias": "Guides",
			"docfas": "Document types",
			"familias": "Families",
			"grandesfamilias": "Big families",
			"processos": "Law Suit",
			"ccustos": "Cost centers",
			"armazens": "Warehouses",
			"codpostais": "ZIP codes",
			"subfamilias": "Sub-families",
			"tiposartigo": "Article types",
			"unidadesmovimento": "Motion Units",
			"unece": "International trade measurement units",
			"ivas": "VAT tables",
			"condicoescomerciais": "Commercial conditions",
			"condicoespagamento": "Payment conditions",
			"meiosPagamento": "Payment options",
			"naturezasContab": "Accounting natures",
			"moradasFaturacao": "Billing addresses",
			"listagensMovimentosPendentesClientes": "Pending customer movements",
			"listagensMovimentosPendentesFornecedores": "Pending supplier movements",
			"listagensMovimentosPendentesOutrosDevedoresCredores": "Pending movements of other debtors and creditors",
			"taxasretencao": "Retention Rates",
			"users": "Users",
			"tokens": "Tokens",
			"roles": "Permissions",
			"templates": "Templates",
			"auditlogin": "Login audit",
			"pocs": "Chart of accounts",
			"descritivos": "Accounting descriptions",
			"diarios": "Accounting journals",
			"anosFaturacao": "Number of years of billing",
			"portals": "Portals",
			"periodos": "Accounting Periods",
			"qivaps": "Periodic VAT declaration fields",
			"qivaas": "Annual VAT declaration fields",
			"controloIVA": "VAT control",
			"vendedores": "Sellers",
			"artigoclasses": "Article Classes",
			"mascarasanalitica": "Analytics masks",
			"amortizacao": "Depreciation Codes",
			"datavalor": "Date value",
			"estabelecimentos": "Establishments",
			"estabelecimento": "Establishments",
			"localidades": "places",
			"local": "places",
			"qpcae": "Economic activity",
			"qpdcf": "District/Municipality/Parish",
			"calendarios": "Calendars",
			"nacionalidades": "Nationalities",
			"tirs": "IRS Table",
			"irsdt": "IRS table date",
			"classescaixa": "Cash Classes",
			"classescontratos": "Classes of contracts",
			"classescontratosservicos": "Covenant classes",
			"ativos": "Asset tokens",
			"ativosAquisicao": "Acquisitions",
			"categoriaartigo": "Article category",
			"reparticoesccustosfaturacao": "Breakdowns by cost center",
			"reparticoesccustossalarios": "Breakdowns by cost center",
			"account": {
				"main": "profile",
				"login": "Authenticate",
				"logout": "To go out",
				"changeEmpresa": "Change company",
				"changeUser": "Change user",
				"pesquisarEmpresa": "Search company:"
			},
			"language": "Language",
			"prhlocais": "Locations",
			"prhmenus": "Menus",
			"dgemps": "Collaborators",
			"dgempsfull": "Collaborator sheet",
			"prhrelementas": "Relationship menus",
			"prhrefeicoes": "Meals",
			"prhtiporefeicoes": "Types of meals",
			"prhservicos": "services",
			"prhpapeis": "Papers",
			"prhservicoempregados": "Service/employment report",
			"prhfluxos": "Streams",
			"prhcomunicacoesinternas": "Internal com.",
			"prhcomunicacoesinternasList": "Internal communication",
			"paises": "Countries",
			"prheventosconfig": "Event configuration",
			"prhdocsplata": "Docs.",
			"categorias": "Categories",
			"efatura": "SAF-T eFatura",
			"configefaturadocporlancar": "Document configuration for posting eFatura",
			"mercados": "Markets",
			"criterioDisponiveis": "Available Criteria",
			"calcPrVenda": "Sales price calculation",
			"tipoResumoMensal": "Monthly summary type",
			"origemCondPagamento": "Origin condition payment",
			"origemDataVencimento": "Origin due date",
			"tipoOperacao": "Operation type",
			"areaRegional": "Regional area",
			"inversaoSujPassivo": "Taxable subject inversion",
			"tipoLiquidacao": "Type of settlement",
			"tipoDeducao": "Type of deduction",
			"tipoTransmissaoActiva": "Active transmission type",
			"tipoTransmissaoPassiva": "Passive transmission type",
			"tipoBem": "Type of good",
			"tipoTaxa": "Type of fee",
			"tipoValidacaoNif": "NIF validation type",
			"tipoRegularizacaoCampo40": "Type of field 40 regularizations",
			"tipoRegularizacaoCampo41": "Type of field 41 regularizations",
			"tipoDescritivo": "Type of description",
			"formaIncrementoNDoc": "Document number increment form",
			"fluxoMonetario": "Money flow",
			"tipoTaxaIva": "VAT rate type",
			"areaRegionalTaxa": "Regional area rate",
			"tipoDeducaoTaxa": "Tax deduction type",
			"tipoConta": "Account type",
			"tiposRefeicoes": "Meal types",
			"tiposMovimento": "Movement types",
			"zonas": "Zones",
			"codigoscontabilisticos": "Accounting codes",
			"reembolsosiva": "VAT refund",
			"fimperiodoenc": "Closing of periods",
			"depto": "Department",
			"subde": "Sub-department",
			"predefinidoscontabilidade": "Pre-defined accounting",
			"moeda": "Coins",
			"moedaNormasIso": "ISO coin standards",
			"paisesISO3166": "ISO 3166 Countries",
			"prhgruporefeicoes": "Meal group",
			"reconstrucaoacumulados": "Reconstruction of accruals",
			"verificacaoacumulados": "Checking accruals",
			"erpusers": "ERP users",
			"terceiros": "The 3rd",
			"concelhos": "Counties",
			"distritos": "Districts",
			"freguesias": "Parishes",
			"continentes": "Continents",
			"repfinancas": "Tax offices",
			"assistenteConfigPortais": "Configuration wizard",
			"mapasamortizacao": "Amortization maps",
			"tiposcontrato": "Types of contract",
			"divisoesTrabalho": "Divisions of work",
			"qppaises": "Personnel countries",
			"qpdur": "Personal framework durations",
			"seccoes": "Sections",
			"nifs": "Tax identification numbers",
			"taxasiva": "VAT rates",
			"fimano": "End of the year",
			"calculodiferimentos": "Calculation of deferrals",
			"extratos": "Account statements",
			"extratosgrid": "Statement on account",
			"impdecpe": "Periodic statement field extract",
			"ativosconfiguracoes": "settings",
			"intfac": "Document integration",
			"listagemdiarios": "Accounting journals",
			"ativosdepreciacao": "Asset depreciation",
			"ativosimpressao": "Print from assets",
			"ativosmapasoficiais": "Print official maps",
			"ativosgeramapasauto": "Tax file",
			"analiticaconfig": "Chart of Accounts Analytics Account Setup",
			"ativosaquisicaocontab": "Acquisition from accounting document",
			"ativosabate": "Register serial kills",
			"ativosvenda": "Register serial sales",
			"entradasdiversas": "Miscellaneous inputs",
			"saidasdiversas": "Various outputs",
			"extractosartigos": "Article extract",
			"grabonos": "Allowance groups",
			"grdescontos": "Discount groups",
			"ativosimport": "Asset import",
			"rhtipoprocess": "Types of Human Resources Processing",
			"rhconfiguracoes": "Human resources settings",
			"rhdadosconfigficha": "Card data configuration",
			"tiposregistocadastro": "Registration record types",
			"situacaoempregado": "Employee status",
			"tipohorario": "Type of Schedule",
			"rhassistentetrabalhador": "Configure salary integration",
			"rhtransferenciasbancarias": "Bank transfers",
			"bancos": "Banks",
			"idbancos": "Bank identification",
			"sitpr": "Situation of professions",
			"funcoes": "Functions",
			"nivql": "Qualification levels",
			"qpsit": "Search situations",
			"segur": "Insurers",
			"crss": "Social Security Scheme Centers",
			"prof": "Profession",
			"profissao": "Profession",
			"conttrabcab": "Employment contract",
			"qpcnp": "Profession search",
			"cltrb": "Job Classes",
			"conttrabbaselegal": "Legal basis of employment contract",
			"conttrabmotivo": "Reasons for employment contract",
			"rhunidades": "Human resources units",
			"abdes": "Allowances/Discounts",
			"qphab": "Qualifications",
			"qptco": "Personnel contract type",
			"abonos": "Allowances",
			"descontos": "Discounts",
			"cgasi": "C.G.A. situation codes.",
			"remun": "Data collection",
			"remfx": "Fixed data collection",
			"rhpenhoras": "Liens",
			"rhprocsegsocialconsultenvios": "Social mon. - shipping consultation",
			"crdescontos": "Discount crossovers",
			"crabonos": "Allowance crossovers",
			"procseguradorafolhaferias": "Vacation payroll processing for insurance company",
			"pcaRecibosVencimentos": "Payslips",
			"rhgestaoficheirosdmrat": "DMR AT file management",
			"estados": "States",
			"rendis": "Types of income",
			"situacaoprofissional": "Professional situation",
			"qpmte": "Reasons for employee entry",
			"cct": "Collective labor contracts",
			"qpirt": "IRCT Research",
			"qpisp": "ISP code",
			"trfLancContas": "Account transfer",
			"usersbasic": "Users",
			"ativoscoeficientecm": "Update monetary correction coefficient",
			"propostasaclientes": "Budgets",
			"encomendas": "Orders",
			"encomendasclientes": "Customer orders",
			"encomendasfornecedores": "Supplier orders",
			"ativosconsistencia": "Consistency of values ​​between assets and accounting",
			"docsComerciaisEstatisticasVendas": "Business Vol - Sales per article",
			"docsComerciaisEstatisticasDocVendas": "Business Vol - Sales per document",
			"docsComerciaisEstatisticasCompras": "Business Vol - Purchases by item",
			"docsComerciaisEstatisticasDocCompras": "Business Vol - Purchases per document",
			"clientconnect": "Client Connect",
			"assistenteConfigPortaisPortalClientConnectUtilizador": "Client Connect Portal - New user configuration",
			"assistenteConfigPortaisPortalClientConnectUtilizadorExistente": "Client Connect Portal - Existing user configuration",
			"documentosdigitais": "Digital documents",
			"documentosdigitaisConciliacao": "Reconciliation of digital documents",
			"documentosDigitaisClientConnect": "Digital documents",
			"rhcessacontratotrabalho": "Generates termination of contract",
			"rhmanutencao": "Maintenance of human resources",
			"rhcalculorendimentoliquido": "Calculation by net income",
			"rhrecolhaseriesubsidiosferias": "Serial payment of vacation allowance",
			"rhrecolhaseriesubsidiosnatal": "Serial payment of Christmas allowance",
			"rhrecolhaseriesubsidiosalimentacao": "Serial preprocessing of food allowance",
			"rhqpirct": "Official IRCT staff tables",
			"rhprocessamentosalarios": "Configuring Payroll Processing",
			"rhfolfer": "Salary sheet",
			"modelo10": "Model 10",
			"retencoes": "Viewing holds",
			"modelo22": "Model 22",
			"modelo25": "Model 25",
			"modelo30": "Model 30",
			"modelo39": "Model 39",
			"rhimpvenct": "Expiration statistics listings",
			"impctrirs": "IRS control listings",
			"rhagencias": "Maintenance of banks, agencies and accounts",
			"impfferpoc": "Salary sheet classification",
			"rhtranssal": "Salary transfers",
			"trfba": "Payments by bank transfer",
			"trfbarh": "Payments by bank transfer",
			"trfbacomercial": "Payments by bank transfer",
			"contabAdvancedOperationsPeriodo": "Change of document period",
			"contabAdvancedOperationsDiario": "Changing the document journal",
			"contabAdvancedOperationsAnular": "Cancel documents",
			"faturacaopublica": "Management of public electronic invoicing documents",
			"idide": "Account groups",
			"impencar": "List of charges",
			"impAvisosCobranca": "Printing of billing notices",
			"declaracaoiva": "Periodic VAT declaration",
			"declaracaoivarecapitulativa": "VAT summary declaration",
			"vendascliente": "Summary by customer",
			"vendasartigo": "Summary per article",
			"resumoiva": "VAT Summary",
			"definicoesemail": "Definition of E-Mails",
			"dashboard": "Dashboard",
			"marcarRefeicoes": "Book meals",
			"visualizarRefeicoes": "View meals",
			"extratosFornecedores": "Supplier statements",
			"extratosClientes": "Customer statements",
			"gto": "Task and obligation management",
			"entidadeexterna": "External entities",
			"solic": "Charge request notice texts",
			"impdadosemp": "List of employee data",
			"dmrf": "Monthly withholding statement",
			"simulacaovencimento": "Receipt simulation",
			"contratostipos": "Types of contracts",
			"contratostiposervicos": "Types of covenants",
			"concilmovab": "Justification of open movements",
			"faturacaocontratos": "Receipt invoicing",
			"conciliacaobancaria": "Bank reconciliation",
			"bancosextrato": "Banking automation",
			"bancosextratosimple": "Banking automation",
			"sind": "Union file",
			"utlviatpatronal": "Use of employer vehicles",
			"demfinsnc": "SNC financial statements",
			"contratos": "Contracts",
			"contratosservicos": "Covenants",
			"transferenciasarmazens": "Transfer of articles between warehouses",
			"rhrelatoriounico": "Single report",
			"contratosprint": "Printing of contracts",
			"impde119": "Declaration Artº 119 CIRS",
			"tesrubrica": "Treasury forecast validation states",
			"prevites": "Treasury forecasts",
			"previtesauto": "Automatic launch of treasury forecasts",
			"prevtesobras": "Importer of financial schedules for works",
			"qpmts": "Reasons for employee departure",
			"officereporting": "Business evolution",
			"ativosaquisicaoinvestemcurso": "Acquisition based on ongoing investments",
			"faturacaoeletronicaconfiguracoes": "Electronic invoicing configuration",
			"segsocialfolhaferias": "Social security/vacation payroll",
			"integracaoSalarios": "Salary integration",
			"proceSalarios": "Salary processing",
			"proceDMRAT": "DMR Processing - AT",
			"proceSegSocial": "Social security processing",
			"integracaoSalariosMulti": "Salary integration (multi-company)",
			"gestaoFicheirosSepa": "SEPA file management",
			"rhgozoferias": "I enjoy vacation",
			"mbconfigs": "Configuration of ATM references",
			"rhatualizaferiasemp": "Update employee vacation records",
			"rhmapassindicatos": "Map for unions",
			"extratosDT": "Account statement history",
			"extratosDTClientes": "Customer account statement",
			"extratosDTFornecedores": "Supplier account statement",
			"infoglobaldiferimentosacrescimos": "Global information deferrals/additions",
			"infoglobaldiferimentos": "Global deferrals information",
			"infoglobalacrescimos": "Global information additions",
			"rhregistarempregadosss": "Consult/Register employees with social security",
			"rhmanutencaocampos": "Update salary fields",
			"analisadorTes": "Treasury planning",
			"importxls": "Imports",
			"pocscopy": "Copy plan accounts for companies",
			"controloivaentreempresas": "Copy VAT control for companies",
			"avisoscobrancagrid": "Billing Notices",
			"saftcontab": "SAF-T accounting importer",
			"tesestados": "Treasury forecast validation states",
			"guiasfornecedores": "Supplier guides",
			"lotes": "Lots",
			"morals": "Alternative addresses",
			"arclis": "Customer/Supplier Article",
			"clicls": "Class",
			"mapaPagamentos": "Payment map",
			"exportacaocompras": "geofolia export",
			"planoscontasalternativos": "Alternative account plans",
			"balancetes": "Accounting balance sheets",
			"balancetesalternativos": "Alternative plan balance sheets",
			"balancetesanalitica": "Analytical accounting balance sheets",
			"comprasautofaturacao": "Self-billing",
			"acrescimosencargoscomferias": "Additional holiday charges",
			"adiantamentosclientes": "Customer advances",
			"adiantamentosfornecedores": "Supplier advances",
			"rhCopiaAbonosDescontos": "Copy allowances/discounts",
			"trdocs": "Document transformation",
			"cargveic": "Vehicle Settings",
			"ativosaquisicaoinvestemcursopordoc": "Acquisition based on ongoing investments per document",
			"manuttributautonomas": "Autonomous taxation maintenance",
			"tributautonomas": "Autonomous taxation",
			"rendHabitPerm": "Use of employer housing",
			"mapaExploracao": "Exploration map",
			"colaboradoresAlteracoesEntidades": "Data to change",
			"colaboradoresAprovacoesRefeicoes": "Meals to be approved",
			"colaboradorboard": "Summary",
			"colaboradoresassociaservicos": "Associate with service",
			"colaboradoreshome": "Home",
			"colaboradoresservicos": "As. roles to services",
			"colaboradorescomunicacoesinternas": "Communications",
			"colaboradoresdgemps": "Users",
			"dgempsficha": "personal data",
			"documentosPartilhados": "Shared documents",
			"dossierAlteracoesCapitalProprio": "Changes in equity",
			"dossierAnexoDemonstracoesFinanceiras": "Attachment",
			"dossierBalanco": "Balance",
			"dossierDemonstracaoResultados": "Demons.",
			"dossierFluxoCaixa": "Cash flows",
			"dossierResultadosPorNatureza": "Results by functions",
			"empresas": "Company",
			"fct": "Compensation Fund",
			"colaboradoresfluxos": "Streams",
			"ies": "Export IES-DA file",
			"modules": "Modules",
			"conciliacaoEfaturas": "eInvoice Reconciliation",
			"pcaBalancetes": "Balance Sheets",
			"pcaExtratos": "Extracts",
			"pcaIdadeSaldosClientes": "Customer balances",
			"pcaIdadeSaldosFornecedores": "Supplier balances",
			"documentosFiscais": "Fiscal documents",
			"movimentosemaberto": "Open accounting movements",
			"svat": "SVAT financial statements",
			"pcaContas": "Identification",
			"pcaDocumentosComerciais": "Billing",
			"pcaExtratosConta": "Extract",
			"pcaMovimentosAbertoCliente": "Open moves",
			"pcaMapaFaltas": "Fouls map",
			"pcaMapaFerias": "Vacation map",
			"pcaFundosCompensacao": "Compensation Fund",
			"pcaMapaSeguradora": "insurance map",
			"pcaSegurancaSocial": "Social Security",
			"pcaMapaSindicatos": "Unions map",
			"colaboradoresrecibosvencimento": "Receipts",
			"colaboradoresAssociarMenus": "Associate menus",
			"colaboradoresListagemMenus": "Menu listings",
			"colaboradoresListagemRefeicoes": "Meal listings",
			"rptconfigs": "Report configuration",
			"inventarios": "Inventories",
			"listagensVendasAnoMes": "Sales per month",
			"saft": "Export SAF-T file",
			"artars": "Warehouse Items",
			"crneg": "Crossover Exceptions",
			"docfasnums": "Series of document types",
			"docfasnumscomunicacaologin": "Login series communication",
			"docspartilhados": "HR platform documents",
			"erputilizadores": "CentralGest Users",
			"erps": "installations",
			"fremun": "Forms of remuneration",
			"gdoc": "Attachment management",
			"indicefluxos": "Flow indices",
			"ivamotivostaxa": "Reasons for VAT exemption",
			"perfilCategoriaAbono": "Profiles categories allowances",
			"perfilCategoriaDesconto": "Profiles categories discounts",
			"sscol": "Social security payments",
			"taxonomias": "SVAT taxonomies and validations",
			"importadorEfatura": "Documents to be released eFatura",
			"importadorSaftDirect": "Importer SAF-T PT invoicing for accounting",
			"atFaturasRecibosVerdes": "AT - Green invoices and receipts",
			"apuraiva": "VAT calculation and end of period",
			"apuramentoresultados": "Calculation of net results",
			"pcaContabilidadeMovimentosAbertoCliente": "Open customer transactions",
			"pcaContabilidadeMovimentosAbertoFornecedor": "Open supplier movements",
			"qpapa": "Employers' Association",
			"qpnj": "Legal Nature"
		},
		"form": {
			"username": "User",
			"username.placeholder": "Your name for authentication",
			"usernameemail.placeholder": "Username or email",
			"newpassword": "new password",
			"newpassword.placeholder": "your new password",
			"confirmpassword": "Password confirmation",
			"confirmpassword.placeholder": "Password confirmation",
			"email": "Email",
			"email.placeholder": "your email",
			"notApplicable": "AT"
		},
		"text": {
			"notDefined": "N/A",
			"note": "Note",
			"filters": "Filters",
			"errorNum": "Error #{{error}}.",
			"contactAdmin": "Please contact the administrator.",
			"success": "Success",
			"info": "Information",
			"warning": "Notice",
			"error": "Error",
			"confirmation": "Confirmation",
			"help": "Help",
			"faq": "Common questions",
			"other": "Others",
			"year": "Year",
			"month": "Month",
			"fromDate": "Date of",
			"toDate": "Date until",
			"to": "Until",
			"total": "Total",
			"saldo": "Balance",
			"advancedSearch": "Advanced Search",
			"listing": "Listing",
			"searchfilter": "Search filters",
			"keeppanel": "Keep panel open",
			"all": "All",
			"general": "General",
			"actions": "Actions",
			"attention": "Attention",
			"preview": "Preview",
			"movimentosPendentes": "Pending movements",
			"active": "Active"
		},
		"language": {
			"pt": "Portuguese",
			"en": "English"
		},
		"btn": {
			"apply": "To apply",
			"cancel": "Cancel",
			"ok": "OK",
			"previous": "Previous",
			"next": "Following",
			"goBack": "Go back",
			"goForward": "Advance",
			"finalize": "Finish",
			"refresh": "To update",
			"edit": "To edit",
			"save": "Save",
			"update": "To update",
			"process": "Process",
			"processGrelha": "Process on a grill",
			"processPDF": "Process PDF",
			"processExcel": "Process in Excel",
			"search": "To look for",
			"new": "New",
			"delete": "To switch off",
			"register": "Register",
			"clone": "Clone",
			"close": "To close",
			"pdf": "PDF",
			"downloadPdf": "Download PDF",
			"yes": "Yes",
			"no": "No",
			"changeEmpresa": "Change company",
			"store": "Store",
			"create": "To create",
			"createFile": "Create File",
			"add": "To add",
			"email": "Email",
			"send": "To send",
			"remove": "To remove",
			"help": "Help",
			"visualize": "To view",
			"back": "To go back",
			"change": "To alter",
			"approve": "To approve",
			"reject": "Reject",
			"revert": "To reverse",
			"mark": "To mark",
			"attach": "Attach",
			"print": "Print out",
			"confirm": "Confirm",
			"import": "import",
			"export": "Export",
			"preview": "Preview",
			"load": "To load",
			"addLine": "Add line",
			"exportXls": "Export excel",
			"exportPdf": "Export PDF",
			"replenish": "Reset",
			"replenishOriginalValues": "Reset original values",
			"retry": "Try again",
			"config": "Settings",
			"calculate": "Calculate",
			"sendMail": "Send Email",
			"options": "options",
			"submeterAt": "Submit AT",
			"validarAt": "Validate on AT",
			"configWS": "WS Settings",
			"validarFicheiro": "Validate File",
			"simulate": "Simulate",
			"importExport": "Import/Export",
			"nullify": "Cancel",
			"actions": "Actions",
			"excel": "Excel"
		},
		"saveDoc": "Save document",
		"saveDocId": "Save document {{id}}",
		"saveDocSimulation": "Save document (simulation)",
		"saveDocMessage": "Are you sure you want to save the document?",
		"saveDocMessageSimulation": "Are you sure you want to simulate saving the document?",
		"advancedFilter": "Advanced filter",
		"debitCredit": {
			"debit": "Debt",
			"credit": "Credit"
		},
		"states": {
			"admin": {
				"title": "Administration"
			}
		},
		"allRightsReserved": "All rights reserved",
		"export": {
			"toExcel": "Export to Excel",
			"toPDF": "Export to PDF"
		},
		"keyboard": {
			"arrowLeft": "Left arrow",
			"arrowRight": "Right arrow"
		},
		"prompt": {
			"exitTitle": "<i class='fa fa-exclamation-triangle text-warning' style='color: orange;'",
			"exitMessage": "Clicking OK will exit without saving and all changes you made will be deleted."
		},
		"months": {
			"january": "January",
			"february": "February",
			"march": "March",
			"april": "April",
			"may": "May",
			"june": "June",
			"july": "July",
			"august": "August",
			"september": "September",
			"october": "October",
			"november": "November",
			"december": "December"
		},
		"monthsShort": {
			"january": "Jan",
			"february": "Feb",
			"march": "Sea",
			"april": "Apr",
			"may": "May",
			"june": "June",
			"july": "Jul",
			"august": "Aug",
			"september": "Set",
			"october": "Oct",
			"november": "Nov",
			"december": "Ten"
		}
	},
	"disconnected": {
		"title": "Disconnected from the Server",
		"message": "Please check your connection and try again."
	},
	"entity": {
		"action": {
			"addblob": "Add blob",
			"addimage": "Add image",
			"back": "To go back",
			"cancel": "Cancel",
			"delete": "To switch off",
			"edit": "To edit",
			"save": "Save",
			"view": "To see",
			"new": "New",
			"filter": "Filter",
			"refresh": "To update",
			"retrieveBackup": "Retrieve last document",
			"duplicate": "Duplicate",
			"actions": "Actions",
			"config": "Settings",
			"tooltip": {
				"duplicate": "Create new based on this"
			}
		},
		"state": {
			"saved": "Saved successfully",
			"error": "Unable to save, please check the data entered"
		},
		"list": {
			"withoutdata": "no data",
			"loading": "reading...",
			"secondaryClick": {
				"menu": {
					"openAsModal": "To view",
					"openAsNewTabOrWindow": "Open in new window or tab"
				}
			}
		},
		"placeholder": {
			"search": "Search"
		},
		"detail": {
			"field": "Field",
			"value": "Value"
		},
		"delete": {
			"title": "Delete record {{id}}?",
			"message": "Are you sure you want to delete?"
		},
		"validation": {
			"required": "This field is required",
			"minlength": "This field must have at least {{min}} characters.",
			"maxlength": "This field cannot be longer than {{max}} characters.",
			"min": "This field must be greater than {{min}}.",
			"max": "This field must be smaller than {{max}}.",
			"minbytes": "This field should be more than {{min}} bytes.",
			"maxbytes": "This field cannot be more than {{max}} bytes.",
			"pattern": "This field should follow pattern {{pattern}}.",
			"datetimelocal": "This field should be a date and time.",
			"email": "The email is not valid",
			"patern": "The value is not correct {{info}}",
			"number": "Not a valid number",
			"fieldMatch": "Does not match",
			"vat": "Tax number is not valid"
		},
		"autocomplete": {
			"errorAllowInvalid": "The value entered is not valid",
			"newItem": "New {{attrName}}",
			"createNewItem": "{{attrName}} does not exist."
		},
		"maintenance": {
			"headerSearch": "Search for {{entityName}}",
			"headerMaintenance": "Maintenance of {{entityName}}"
		}
	},
	"error": {
		"server.not.reachable": "Server not accessible",
		"NotNull": "The field {{fieldName}} cannot be empty!",
		"Size": "Field {{fieldName}} does not meet minimum/maximum size requirements!",
		"userexists": "Login name is already in use!",
		"emailexists": "The email is already in use!",
		"server": {
			"exception": "Internal server error, please try again later",
			"authorized": "Without authorization!",
			"unavailable": "The server is not reachable, please try again later.",
			"noResponse": "No access to the server or it is down.",
			"badRequest": "The server was unable to understand or rejected your request.",
			"unauthorized": "You are not authorized to make this request.",
			"forbidden": "You are not authorized to make this request.",
			"notFound": "Page or order not found.",
			"internalServerError": "Internal server error, please try again later or contact support."
		},
		"invalidForm": "Unable to save, please check the data entered"
	},
	"toolbar": {
		"year": "Year: {{value}}",
		"month": "Month: {{value}}",
		"period": "Period: {{value}}"
	},
	"grdescontos": {
		"title_detail": "Discount Group {{id}}",
		"title_new": "New Discount Group",
		"title_plural": "Discount Groups",
		"pesquisa": "To look for",
		"saved": "Discount Group {{id}}, saved successfully",
		"error": "Discount Group {{id}}, cannot be saved.",
		"deleted": "Discount Group {{id}}, successfully deleted",
		"fields": {
			"codGrDesconto": "Code",
			"descricao": "Description",
			"categoria": "Category",
			"assiduidadeSegSocial": "Attendance for Social Security",
			"absentismo": "Absenteeism",
			"suspensaoContrato": "Contract Suspension",
			"remunerado": "Paid",
			"recibo": "Receipt",
			"entidadeSuporta": "Supporting Entity",
			"ausencias": "Absences",
			"justificacaoAusencia": "Justification for Absence",
			"balancoSocial": "Social Balance",
			"horasNaoTrabalhadas": "Hours not worked",
			"contaDiasFeriasNorm": "Account for Normal Vacation Days",
			"contaDiasFeriasAcima": "Account for Holiday Days Above Normal",
			"contaDiasSubNatal": "Account for Sub Christmas Days",
			"contaDiasSubAlimen": "Account for Sub-Food Days"
		}
	},
	"grabonos": {
		"title_detail": "Allowance Group {{id}}",
		"title_new": "New Allowance Group",
		"title_plural": "Allowance Groups",
		"pesquisa": "To look for",
		"saved": "Allowance Group {{id}}, saved successfully",
		"error": "Allowance Group {{id}}, cannot be saved.",
		"deleted": "Allowance Group {{id}}, successfully deleted",
		"fields": {
			"codGrAbono": "Code",
			"descricao": "Description",
			"categoria": "Category",
			"assiduidadeSegSocial": "Attendance for Social Security",
			"presencas": "Attendance",
			"remunerado": "Paid",
			"recibo": "Receipt",
			"entidadeSuporta": "Supporting Entity",
			"tipoTrabalho": "Kind of work",
			"balancoSocial": "Social Balance",
			"contaDiasFeriasNorm": "Account for Normal Vacation Days",
			"contaDiasFeriasAcima": "Account for Holiday Days Above Normal",
			"contaDiasSubNatal": "Account for Sub Christmas Days",
			"contaDiasSubAlimen": "Account for Sub-Food Days",
			"formacao": "Training"
		}
	},
	"grandesfamilias": {
		"title_detail": "Big Family {{id}}",
		"title_new": "New Big Family",
		"title_plural": "Big Families",
		"pesquisa": "To look for",
		"deleted": "Grande Família {{id}}, successfully deleted.",
		"saved": "Big Family {{id}}, saved successfully.",
		"error": "Big Family {{id}}, cannot be saved.",
		"fields": {
			"nDepart": "Code.",
			"nDepartPlaceholder": "Big family code",
			"nome": "Name",
			"nomePlaceholder": "Big family name"
		}
	},
	"gto": {
		"title": "Task and Obligation Management",
		"tabs": {
			"title": {
				"porEmpresa": "By Company",
				"multiEmpresa": "Multi-Company",
				"ativarTarefas": "Activate Tasks",
				"configuracoes": "settings",
				"configuracoesMulti": "Multicompany Settings"
			},
			"filters": {
				"empresa": "Company",
				"ano": "Year",
				"tipotarefa": "Task type",
				"responsavel": "Responsible",
				"mostrarfilter": "Show:",
				"periodoiva": "VAT period",
				"mes": "Month",
				"regimeirc": "IRC/IRS Regime",
				"nomeTarefa": "Task Name"
			},
			"table": {
				"fields": {
					"nometarefa": "Task name",
					"nempresa": "Company No.",
					"sel": "Sel."
				},
				"semdados": "No data"
			},
			"btn": {
				"checkstatedmratss": "Check DMR AT and SS status",
				"checkstatedmrss": "Check DMR SS status",
				"checkstatedmrat": "Check DMR AT status",
				"dmrat": "DMR AT",
				"dmrss": "DMR SS",
				"alertas": "Alerts",
				"filtroavanc": "Advanced Filter"
			},
			"actions": {
				"executartarefa": "Run task",
				"concluirtarefa": "Complete task",
				"conctarefdentroprazo": "Complete task within the deadline",
				"editartarefa": "Edit task",
				"desativartarefa": "Disable task",
				"estatisticas": "Statistics"
			}
		},
		"toolbar": {
			"createNewTask": "Create Task",
			"config": "To set up",
			"copyTasks": "Copy Tasks"
		},
		"ano": "Year",
		"nomeTarefa": "Task Name",
		"tipoTarefa": "Task Type",
		"obrigacoes": "Obligations",
		"pagamentos": "Payments",
		"outra": "Other",
		"dataInicioPeriodo": "Period start date",
		"mes": "Month",
		"mes1anterior": "Month - 1",
		"mes2anterior": "Month - 2",
		"ano1anterior": "Year - 1",
		"entidadeExterna": "External Entity",
		"createNewTaskTitle": "Create new task",
		"editTaskTitle": "Edit task",
		"applyAllEmpresas": "Updates the calendar in all companies that have this task",
		"tarefa": "Task",
		"tarefas": "Tasks",
		"config": "Settings",
		"empresa": "Company",
		"ativarGTONaEmpresa": "Activate Task and Obligation Management",
		"alertasNaApp": "In-app alerts",
		"inicio": "Start",
		"fields": {
			"ativar": "Activate",
			"nomeTarefa": "Task Name",
			"responsavel": "Responsible",
			"nEmpresa": "N. Company",
			"nomeEmpresa": "Name",
			"regimeIRCIRS": "IRC/IRS Regime",
			"periodoIVA": "VAT period",
			"sel": "Sel.",
			"data": "Date"
		},
		"invalidSelectedDay": "Invalid day",
		"saveConfig": "Save Configuration",
		"legends": {
			"concluidoDentroPrazo": "Completed on time",
			"guardarValidacao": "Under validation",
			"emAtrasoJustificado": "In justified delay",
			"concluidoForaPrazo": "Completed after deadline",
			"emAtraso": "Overdue",
			"prazoADecorrer": "Deadline to run",
			"naoAplicavel": "Not applicable",
			"pendente": "Pending"
		},
		"datasources": {
			"tipotarefa": {
				"obrigacoes": "Obligations",
				"pagamentos": "Payments",
				"outra": "Other",
				"todos": "All"
			},
			"mostrarfilter": {
				"datalimite": "Deadline",
				"datalimitePlusNotes": "Deadline and Notes",
				"values": "Value",
				"valuesPlusNotes": "Value and Notes",
				"dias": "Days",
				"diasPlusNotes": "Days and Notes",
				"dataexecucao": "Execution date",
				"dataexecucaoPlusNotes": "Execution date and notes"
			}
		},
		"copyTasksModalTitle": "Copy Tasks",
		"copiar": "Copy",
		"copyFor": "Copy to",
		"iraSubstituirTarefasNoDestino": "The procedure will create or replace the tasks on the target.",
		"iraCriarAtualizarTarefasNoDestino": "The procedure will create or update the tasks on the target.",
		"confirmationModalTitle": "Confirmation",
		"temQueSelecionarUmaTarefa": "You must select at least one task!",
		"temQueSelecionarUmaEmpresa": "You must select at least one company!",
		"tarefasCopiadasComSucesso": "The selected tasks were copied successfully.",
		"paraAno": "For Year",
		"paraEmpresas": "For businesses)",
		"naoPodeCopiarParaMesmoAno": "You cannot copy tasks to the same year.",
		"executarTarefa": "Execute Task",
		"estado": "state",
		"estados": {
			"semAlteracao": "No Change",
			"concluir": "Conclude",
			"adicionarJustificacao": "Add delay justification",
			"naoAplicavel": "Not applicable",
			"new": "Not Completed",
			"guardarValidacao": "In Validation"
		},
		"messages": {
			"taskfromotheruser": "This task belongs to the other person responsible.",
			"validacaosuccess": "Verification completed successfully!",
			"selempreforcheck": "Select companies to check shipments!",
			"temACertezaQuePertendeConcluirTarefasSelecionadas": "Are you sure you want to complete selected tasks?"
		},
		"alertsModalTitle": "Alerts",
		"expireDate": "Date",
		"valor": "Value",
		"justificacao": "Justification",
		"nota": "Note",
		"dataExecucao": "Execution Date",
		"estaTarefaPertenceAoOutroResponsavel": "This task belongs to the other person responsible.",
		"operacaoConcluidaComSucesso": "Operation completed successfully.",
		"temACertezaQuePertendeApagarEstaTarefa": "Are you sure you want to delete this task?",
		"tarefaEliminadaComSucesso": "Task successfully deleted!",
		"licensemodal": {
			"modalTitle": "Attention",
			"total": "Total number of companies: {{total}}",
			"totalConsumed": "Total number of companies already added: {{totalConsumed}}",
			"totalToConsume": "Total number of companies you can still add: {{totalToConsume}}",
			"license1": "If you click \"Yes\", the system will add a new company to your task and obligation management license, and it will not be possible to reverse the situation later.",
			"license2": "At this time, you can still add {{totalToConsume}} companies to your task and obligation management license.",
			"license3": "Task and Obligation Management license details:",
			"license4": "At this time, you cannot add companies to your task and obligation management license."
		},
		"aSuaLicencaNaoTemGTO": "Your license does not have Task and Obligation Management.",
		"selectAll": "Select all",
		"unSelectAll": "Clear Selection",
		"loadingLicenseData": "Loading license data...",
		"naoTemEmpresasSuficientes": "You cannot proceed with the operation because your license does not have enough companies to consume.<br/><br/>Total number of companies you can still add: {{totalToConsume}}<br/>Total number of companies you will add: {{totalToAdd}}",
		"promptTitle": "Confirmation",
		"infoTitle": "Information",
		"temACertezaQuePertendeAddEmpresas": "Are you sure you want to add {{nempresas}} company(ies) to your task and obligation management license?",
		"hints": {
			"concluidoDentroPrazo": "The task was run on {{dataExec}} (on time) by {{user}}",
			"concluidoForaPrazo": "The task was run on {{dataExec}} (out of time) by {{user}}",
			"emAtrasoJustificado": "The overdue task.",
			"valor": "<b>Value</b>: {{value}}",
			"nota": "<b>Note</b>: {{note}}",
			"selecaoMultiplaText": "Press Ctrl and left-click an item for non-consecutive selection, or press the left mouse button and drag over items."
		},
		"selecaoMultipla": "Multiple Selection"
	},
	"helperLinks": {
		"global": {
			"all": "See all videos",
			"faqs": "See frequently asked questions"
		}
	},
	"helptopics": {
		"titles": {
			"tutorialVideos": "Tutorial - Videos",
			"formationsVideos": "Trainings - Videos",
			"forumCG": "CentralGest Forum",
			"facebookUserGroup": "CentralGest user group on Facebook"
		}
	},
	"tiposCategoriasNaoTrabalhadas": {
		"data": {
			"tDhntNA": "AT",
			"tDhntPorFormacaoProf": "by Professional Training",
			"tDhntPorReducaoLegalActividade": "due to Legal Reduction of Activity",
			"tDhntPorDesempregoInterno": "by Internal Unemployment",
			"tDhntPorDescansoSuplementar": "by Supplemental Rest"
		}
	},
	"idadesaldos": {
		"label": {
			"grupoConta": "Account group",
			"showGrupoConta": "Show account group"
		}
	},
	"idbancos": {
		"title_detail": "Bank ID {{id}}",
		"title_new": "New bank identification",
		"title_plural": "Bank identification",
		"pesquisa": "to look for",
		"saved": "Bank identification {{id}} saved successfully.",
		"error": "Bank ID {{id}} cannot be saved.",
		"deleted": "Bank ID {{id}} successfully deleted",
		"fields": {
			"idinterBancario": "InterBank Code",
			"nomeInterBancario": "InterBank Name",
			"descricao": "Description",
			"formatoTRFAG": "TrfPag format",
			"formatoTRFREC": "TrfRec format",
			"inativo": "Inactive"
		}
	},
	"idide": {
		"title_detail": "Account Group {{id}}",
		"title_new": "New Account Group",
		"title_plural": "Account Groups",
		"pesquisa": "To look for",
		"saved": "Account Group {{id}}, saved successfully",
		"error": "Account Group {{id}}, cannot be saved.",
		"deleted": "Account Group {{id}}, successfully deleted",
		"fields": {
			"idIdeChav": "Code",
			"descricao": "Description",
			"tipoCred": "Credit Type",
			"credito1": "Credit 1",
			"credito2": "Credit 2",
			"credito3": "Credit 3",
			"credito4": "Credit 4",
			"credito5": "Credit 5"
		}
	},
	"ies": {
		"tabProcess": "Export",
		"tabDownload": "IES-DA file",
		"generation": "File generation",
		"fileNotProcessed": "We're sorry, but for some reason the file was not generated.",
		"jobTimeoutModalTitle": "Task",
		"jobTimeoutModalMessage": "The task has timed out.",
		"contabilidade": "Accounting",
		"faturacao": "Billing",
		"processar": "Process",
		"processingMessage": "Processing, please wait a moment.",
		"erros": "Errors",
		"fileReady": "The IES-DA file is ready.",
		"promptReprocessTitle": "There is already a previously generated IES-DA file",
		"promptReprocessMessage": "If you continue, the system will generate the file again.",
		"warningMessage": "Attention: After starting the process, you have 60 minutes to download the generated file.",
		"promptCancelProcessTitle": "Confirmation",
		"promptCancelProcessMessage": "Are you sure you want to cancel the process?",
		"dataCriacao": "Creation date",
		"tamanho": "Size",
		"utilizador": "User",
		"documentos": "Documents",
		"ano": "Year",
		"mes": "Month",
		"buttons": {
			"reiniciar": "Restart",
			"download": "Download",
			"yes": "Yes",
			"no": "No",
			"fechar": "To close"
		},
		"erroDownload": "An error occurred while downloading the file!",
		"exportInProcessModalMessageByOtherUser": "You cannot start the export process in this company because it has already been started by the other user:",
		"enviarDeclaracao": "Send Declaration",
		"fields": {
			"anexo": "Attachment",
			"campo": "Field",
			"descricao": "Description",
			"mensagem": "Message"
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmation",
		"leavePromptMessage": "Clicking YES will exit and discard all processing data."
	},
	"impctrirs": {
		"fields": {
			"listagem": "Listing",
			"deCodEmp": "Employee Code",
			"ateCodEmp": "Until",
			"deData": "Date of",
			"ateData": "Until"
		},
		"filtropesq": "Advanced Search",
		"btn": {
			"previsualizar": "Preview"
		}
	},
	"impde119": {
		"filters": {
			"listagem": "Listing",
			"dedata": "By date",
			"atedata": "Until date",
			"decodemp": "From employee",
			"atecodemp": "Even employed",
			"dencontribuinte": "Taxpayer number",
			"atencontribuinte": "Up to taxpayer number",
			"filtrarPor": "Filter by:",
			"empregado": "Collaborators",
			"outros": "Service Providers",
			"ambos": "Both"
		}
	},
	"impdecpe": {
		"listagem": "Listing",
		"doPeriodo": "From the period",
		"atePeriodo": "Until period",
		"doNCampo": "From N. Campo",
		"ateNCampo": "Until N. Campo",
		"labelCriterios": "Query criteria"
	},
	"impdoc": {
		"title": "Business document printing",
		"modalTitle": "Document {{nDoc}} - {{clifo}}",
		"label": {
			"emailcliente": "Email",
			"enviar": "To send",
			"documento": "Document",
			"nvias": "Number of lanes",
			"segundaVia": "Second copy",
			"rptName": "Report"
		},
		"email": {
			"emailEnviado": "Email sent to {{email}}",
			"emailErro": "Error sending email to {{email}}."
		},
		"placeholder": {
			"email": "\"mail@example.com\" or \"mail@example.com; mail2@example.com\""
		},
		"faturaDigital": {
			"seraEmitidaPor": "Electronic invoice will be issued by {{certificate}}",
			"emitida": "Document with electronic invoice issued",
			"clienteSemFatDigital": "Customer without electronic invoice",
			"tipoDocSaftNaoConfigurada": "SAF-T document type (FT,FR,ND,NC) not configured to issue electronic invoice",
			"semConfiguracao": "No electronic invoice configured",
			"hintSemConfiguracao": "Product subject to licensing, find information at www.centralgestcloud.com"
		},
		"btn": {
			"faturacaoPublica": "Public Billing"
		}
	},
	"impencar": {
		"fields": {
			"listagem": "Listing",
			"dataDe": "Date of",
			"dataAte": "Until",
			"codEmpDe": "Employee Code",
			"codEmpAte": "Until",
			"codEncargoDe": "Charge Code",
			"codEncargoAte": "Until"
		},
		"filtropesq": "Advanced Search",
		"btn": {
			"previsualizar": "Preview"
		},
		"infos": {
			"tooltipExcepcoesProcessamento": "Exceptions Processing"
		}
	},
	"impfferpoc": {
		"btn": {
			"previsualizar": "Preview"
		},
		"filtropesq": "Advanced Search",
		"fields": {
			"listagem": "Listing",
			"dataProcessamento": "Processing Date"
		}
	},
	"importadorEfatura": {
		"configPreDefinidosTitle": "Settings",
		"showLastDocs": "Show Latest Documents",
		"hideLastDocs": "Hide Latest Documents",
		"importarEmSerieModalTitle": "Launching documents in series...",
		"importarEmSerieModalMessage": "Launching documents in series.",
		"definirPredefinidoModalTitle": "Set Presets",
		"cantSelectRowDueInvalidPredefinido": "You cannot select the record because you do not have the Pre-Defined!",
		"fields": {
			"nifEmitente": "NIF",
			"nomeEmitente": "Name",
			"numerodocumento": "External Doc. No.",
			"dataEmissaoDocumento": "Issuance date",
			"actividadeDesc": "Activity",
			"tipoDocumentoDesc": "Type",
			"fornecedor": "Supplier",
			"registadoPor": "Registered By",
			"actividadeProfDesc": "Activity Scope",
			"valorTotalBaseTributavel": "Taxable Base",
			"valorTotalIva": "VAT value",
			"valorTotal": "Amount",
			"preDefinido": "Pre-Defined",
			"error": "Error",
			"baseTributavel": "Taxable Base",
			"taxa": "Base Rate",
			"impostoSelo": "Stamp Tax",
			"total": "Amount"
		},
		"configs": {
			"showColumnActividade": "Show <Activity> column",
			"showColumnAmbitoActividade": "Show <Activity Scope> column",
			"showColumnRegistadoPor": "Show <Registered By> column"
		},
		"itemShowDetalhesToolbarBtn": "Show details",
		"itemHideDetalhesToolbarBtn": "Hide details",
		"docSearchTabCaption": "Document search",
		"criarDoc": "Create Document",
		"actions": {
			"configSupplier": "Configure supplier",
			"newAccount": "Generate new current account",
			"extratosConta": "Account statements"
		},
		"modal": {
			"createNewContaCorrente": {
				"title": "Create new checking account",
				"confirmModal": {
					"confirmTitle": "View the supplier's profile",
					"confirmMessage": "Supplier current account <strong>successfully created</strong>.<br/>Do you want to view the created supplier file?"
				},
				"body": {
					"radical": "Radical",
					"ncontacriar": "Account No. to create",
					"nome": "Name",
					"contrib": "No."
				}
			}
		},
		"tooltip": {
			"criarNovaContaCorrente": "Create new checking account",
			"extratoCorrente": "Account statements"
		},
		"warningImportarEmSerieContabilidadeDigital": "You have chosen to post documents in series in a company with a digital archive.<br/>The system will automatically attach the suggested digital documents, please check that they are correctly classified.<br/>Are you sure you want to continue?",
		"docDigital": {
			"0": "No documents possible",
			"1": "With a possible document",
			"2": "With several possible documents",
			"naoTem": "No documents possible",
			"tem": "With a possible document",
			"temSemelhante": "With several possible documents"
		},
		"atMessagePrefix": "The Finance Portal (AT) returned the following message:"
	},
	"importadorSaftDirect": {
		"strings": {
			"modalTitleEdit": "To edit",
			"modalSave": "To apply",
			"modalCancel": "Cancel",
			"modalTitleConfirm": "Confirmation",
			"correspContasContaSaftLabel": "SAFT Account",
			"correspContasContaCGLabel": "CentralGest Account",
			"taxasIvaSaftLabel": "SAFT Fees",
			"taxasIvaCGLabel": "CentralGest Fees",
			"artigosConfigModalTitle": "Article(s) configuration",
			"artigosNewConfigModalTitle": "Configuring new article(s)",
			"correspCCAssociarModalTitle": "Associate current account",
			"modalAssociar": "Connect",
			"modalAttribuir": "To assign",
			"configArtigosSaftLabel": "SAFT Articles",
			"configArtigosCGLabel": "Data for creating a CentralGest tax base account",
			"jobTimeoutModalTitle": "Task",
			"jobTimeoutModalMessage": "The task has timed out.",
			"verContasFormadasPeloArtigo": "See tax base accounts to be formed by article",
			"verGenDoc": "View Document",
			"applyProductConfig": "Applying configuration...",
			"savingProductsConfig": "Applying article configuration...",
			"noDocsPaymentsToImports": "The documents contained in the SAFT file have already been imported into accounting previously",
			"modalErrorTitle": "Error",
			"timeoutModalMessage": "We are sorry, but the time limit for importing the SAF-T PT file has expired.",
			"porTipoDeDocumento": "Cost Center by document type",
			"porTipoDocumentoReparticao": "Breakdown by document type",
			"porCliente": "Cost Center per customer",
			"porClienteReparticao": "Breakdown by customer",
			"porArtigo": "Cost Center per item",
			"porArtigoReparticao": "Breakdown by article"
		},
		"steps": {
			"common": {
				"semDados": "No data to show!",
				"semValoresImportar": "You must select at least one of the values ​​to import"
			},
			"welcome": {
				"wizardCaption": "Welcome",
				"contentText1": "Welcome to the SAF-T PT importer invoicing assistant for direct accounting.",
				"contentText2": "This wizard will guide you through the import process.",
				"contentText3": "Click \"Next\" to continue the import.",
				"contentText4": "Attention: After starting the process, you have 120 minutes to finish the import.",
				"ficheirosMaiores": "Files over 20 MB must be processed after 6pm."
			},
			"escolhaTipoImportacao": {
				"wizardCaption": "Import Type",
				"text1": "Please choose the type of import you wish to carry out",
				"text2": "If you choose to import CentralGest Cloud, please ask your client to create a user on the CentralGest cloud platform.",
				"tiposImportacao": {
					"title": "Import Type",
					"ficheiroSaft": "SAF-T Billing File",
					"centralGestCloud": "CentralGest Cloud",
					"centralGestPOS": "ZonSoft POS",
					"centralGestPOSWinTouch": "POS WinTouch"
				}
			},
			"upload": {
				"wizardCaption": "SAFT file",
				"title": "Please choose the file you want to import",
				"processarFicheiroLabel": "Processing SAF-T file...",
				"cancel": "Cancel"
			},
			"processaFileCloud": {
				"wizardCaption": "CentralGest Cloud",
				"text1": "Please fill in the following details",
				"periodo": "Period",
				"user": "User",
				"password": "Password",
				"valoresAImportar": "Values ​​to Import",
				"importarVendas": "Import Sales",
				"importarRecibos": "Import Receipts",
				"importarCompras": "Import Purchases",
				"importarPagamentos": "Import Payments",
				"processarNaCentralGestCloud": "Processing..."
			},
			"processaFilePOS": {
				"wizardCaption": "ZonSoft POS",
				"text1": "Please fill in the following details",
				"periodo": "Period",
				"user": "User",
				"password": "Password",
				"valoresAImportar": "Values ​​to Import",
				"importarVendas": "Import Sales",
				"importarRecibos": "Import Receipts",
				"importarCompras": "Import Purchases",
				"importarPagamentos": "Import Payments",
				"processarNaCentralGestPOS": "Processing...",
				"contactPOS": "Please contact our commercial department to learn about this feature.",
				"emailSubject": "Request for information on ZonSoft POS data import",
				"emailComercial": "comercial@centralgest.com"
			},
			"processaFilePOSWinTouch": {
				"wizardCaption": "POS WinTouch",
				"text1": "Please fill in the following details",
				"periodo": "Period",
				"user": "User",
				"password": "Password",
				"valoresAImportar": "Values ​​to Import",
				"importarVendas": "Import Sales",
				"importarRecibos": "Import Receipts",
				"importarCompras": "Import Purchases",
				"importarPagamentos": "Import Payments",
				"processarNaCentralGestPOS": "Processing...",
				"contactPOS": "Please contact our commercial department to learn about this feature.",
				"emailSubject": "Request for information on importing WinTouch POS data",
				"emailComercial": "comercial@centralgest.com"
			},
			"viewDocsToImport": {
				"wizardCaption": "Documents",
				"title": "Documents and receipts to import",
				"helpText": "Please check the documents and receipts that will be imported",
				"valoresNoFicheiroSAFT": "Values ​​in the SAF-T File",
				"nTotalFaturas": "No. of Invoices",
				"totalBaseTributavel": "Total Taxable Base",
				"totalIva": "Total VAT",
				"total": "Total",
				"legendError": "Error documents",
				"legendWarning": "Documents with difference in totals automatically corrected",
				"validatorErrorMessage": "There are documents with errors.",
				"showDocsWithErrorOrWarningOnly": "Show only documents with warnings or errors",
				"tabDocsTitle": "Documents",
				"tabPaymentsTitle": "Receipts",
				"existemDocsComErroDesejaContinuar": "There are documents with errors."
			},
			"viewRecibosToImport": {
				"legendError": "Error receipts",
				"legendWarning": "Receipts with difference in totals automatically corrected",
				"showRecibosWithErrorOrWarningOnly": "Only show receipts with warnings or errors",
				"nTotalRecibos": "No. of Receipts",
				"totalCredito": "Total on Credit",
				"totalDebito": "Total Debit",
				"total": "Value"
			},
			"cfgTiposDocs": {
				"wizardCaption": "Document Types",
				"title": "Configuring document types",
				"analiticaTitle": "Analytics",
				"usaAnalitica": "Use analytics",
				"criaDiariosEDescritivos": "Automatically creates and assigns journals and transcripts to new document types",
				"mascaraLabel": "Analytical Mask",
				"configMascaraAnalitica": "Analytics Setup"
			},
			"cfgMercados": {
				"wizardCaption": "Markets",
				"title": "Setting up markets",
				"helpText": "Please indicate the amount that will be used to form current accounts and taxable accounts depending on the market.",
				"valorMercadoNacionalLabel": "National market character for account formation",
				"valorOutrosMercadosLabel": "Character other markets for account formation",
				"valorMercadoComunitarioLabel": "Community market character for account formation"
			},
			"contasCorrentes": {
				"wizardCaption": "Current accounts",
				"title": "Formation of current accounts",
				"helpText": "Please indicate how you want current accounts to be created.",
				"tipoFormacaoContaTitle": "Account creation",
				"criterioFormacaoTitle": "Criteria for account formation",
				"radicalLabel": "Radical",
				"nDigitosLabel": "Number of Digits",
				"nDigitosHelperText1": "(number of digits in the sequence)",
				"nDigitosHelperText2": "(includes market digit + sequence)",
				"nContaDiversos": "Account",
				"usaDigitosIdentExterna": "Use number of digits in external identification"
			},
			"correspContasCorrentes": {
				"wizardCaption": "Current Account Matching",
				"title": "Current Account Matching",
				"helpText": "The system automatically suggests an account number based on the previously entered current account formation settings.",
				"filterLabel": "Filter",
				"selectAll": "Select all",
				"clearAll": "Clear selection",
				"associarContaCorrente": "Associate current account",
				"removerAssociarContaCorrente": "Remove association c.",
				"mostrarContasComErro": "Show accounts with errors",
				"mostrarNIFInvalid": "Show invalid NIFs",
				"pbSaveText": "Saving correspondence from current accounts...",
				"pbLoadCorrespTaxas": "Loading VAT rate correspondence...",
				"atribuirCCusto": "Associate cost center",
				"removerCCusto": "Remove cost center association",
				"atribuirRepCC": "Associate cost center breakdown",
				"removerRepCC": "Remove cost center breakdown association",
				"selecionados": "With selected..."
			},
			"correspTaxasIva": {
				"wizardCaption": "VAT rates",
				"title": "Matching VAT rates",
				"helpText": "Please indicate the corresponding VAT rate in CentralGest for the VAT rate in the SAFT file."
			},
			"cfgArtigos": {
				"wizardCaption": "Articles",
				"title": "Article configuration",
				"helpText": "Please indicate the corresponding CentralGest account per article.",
				"selectAll": "Select all",
				"selectNews": "Select new",
				"clearAll": "Clear selection",
				"configArtigosSelected": "Configure selected articles",
				"configTodosArtigosTipoProduto": "Configure all articles of the product type",
				"configTodosArtigosTipoServicos": "Configure all services type articles",
				"configTodosArtigosTipoOutros": "Configure all articles of type others",
				"configTodosArtigosTipoImpostosTaxasEncargosParafiscais": "Configure all items of type taxes, fees and parafiscal charges",
				"configTodosArtigosTipoImpostosEspeciaisConsumo": "Configure all excise type items",
				"toolbarRefresh": "Refresh",
				"toolbarMostrarApenasComErro": "Show only articles with errors",
				"toolbarPlanoContas": "Chart of accounts",
				"filterLabel": "Filter"
			},
			"dataDocs": {
				"wizardCaption": "Document Dates",
				"title": "Document Dates",
				"dataLancContabTitle": "Date of accounting entries"
			},
			"genDocsContab": {
				"wizardCaption": "Documents created",
				"title": "Accounting documents created",
				"legendError": "Error documents",
				"legendWarning": "Documents with a rounding value greater than €1",
				"valoresNoFicheiroSAFT": "SAFT file values",
				"valoresCG": "CentralGest Values",
				"selectAll": "Select all",
				"clearAll": "Clear selection",
				"viewDoc": "View document",
				"deleteDocSelected": "Delete selected documents",
				"toolbarMostrarApenasComErro": "Show only documents with errors",
				"gravarDocsMessage": "Saving documents"
			}
		},
		"messages": {
			"confirmation": "Confirmation",
			"temACertezaQuePertendeContinuar": "Are you sure you want to continue operating?",
			"obgEscolherTipoImportacao": "You must select an import type to continue",
			"noFiles": "Select the file to process...",
			"errorProcessFile": "An unexpected error occurred while processing the SAFT file.",
			"importInProcessModalTitle": "SAF-T PT Importer",
			"importInProcessModalMessageByOtherUser": "You cannot start the import process in this company because it has already been started by the other user:",
			"importInProcessModalMessageCancelJob": "The import process has already started.",
			"importInProcessModalLoadProcess": "Load Process",
			"importInProcessModalCancelProcess": "Cancel Process",
			"jobCancelled": "The import process was successfully cancelled.",
			"nContaDestinoDiffNContrib": "It is not possible to assign the account \"{{conta}}\" to the external identification \"{{customerID}}\" because the contributor of the accounts is different.",
			"contasCorrentesNoSelection": "You must select at least one line",
			"datasDocumentosDataLancInvalid": "The date \"{{date}}\" must belong to the year \"{{year}}\" and month \"{{month}}\"",
			"configTiposDocsTemSelLancaCaixaOuLancaCC": "You must select at least one option: Box Launch or C. Chain Launch.",
			"configTiposDocsTemIndicarContaCaixa": "You must indicate a cash account",
			"SoPodeAgruparDocsTipoFaturaSimple": "You can only group documents of the type Invoice|Simplified invoice|Receipt invoice|Cash sale|Sales receipt.",
			"configFormacaoContasNContaDiversosInvalid": "The Customer/Supplier does not exist!",
			"configFormacaoContasRadicalInvalid": "You must indicate a radical to form current accounts.",
			"configFormacaoContasNDigitosInvalid": "You must indicate the number of digits for forming current accounts.",
			"correspTaxasIvaCodIvaInvalid": "You must define a VAT Code for the SAFT VAT rate \"{{rate}}\"",
			"correspTaxasIvaDigitosIvaEmpty": "You must define the VAT digits for the SAFT VAT rate \"{{rate}}\"",
			"finalizedMessageModal": "Operation completed successfully.",
			"finalizedCloseModal": "To close",
			"importHaveErrorsOrWarningsModalTitle": "Notice",
			"importHaveErrorsOrWarningsModalMessage": "There are documents with warnings or errors in the SAFT file.",
			"correspCCHaveInvalidNifsModalMessage": "There are current accounts with invalid taxpayer numbers.",
			"importPaymentHaveErrorsOrWarningsPromptTitle": "Notice",
			"temIndicarMascaraAnalitica": "You must specify an analytics mask.",
			"temIndicarCentroCusto": "You must indicate a cost center",
			"temIndicarCodRepCC": "You must indicate a distribution code per cost center",
			"error": "Error"
		},
		"fields": {
			"jaImportado": "Imported",
			"invoiceNo": "Invoice No.",
			"invoiceDate": "Date",
			"invoiceTypeStr": "Type",
			"totalBase": "Base value",
			"totalIVA": "VAT value",
			"total": "Total",
			"invoice": "Series",
			"nDiario": "Daily",
			"descritivo": "Descriptive",
			"lancaCaixa": "Launch Box",
			"contaCaixa": "Cash Account",
			"lancaContaCorrente": "Boom C. Chain",
			"nCCusto": "C. Cost",
			"agrupamentoDocumentos": "Group Documents",
			"tipoRetencao": "Type of Retention",
			"loja": "Store",
			"isNew": "New",
			"customerID": "External ID",
			"customerTaxID": "VAT Number",
			"name": "Name",
			"country": "Country",
			"mercado": "Market",
			"jaExiste": "Exist",
			"contaDestino": "Suggested Account No.",
			"codRetencao": "Retention Code",
			"nomeContaCG": "CentralGest Account Name",
			"taxCountryRegion": "Region",
			"taxCode": "Type",
			"taxPercentage": "Rate",
			"codIva": "VAT Code",
			"taxaIva": "Rate",
			"motivoTaxa": "Reason for exemption",
			"digitosIva": "VAT characters for account formation",
			"productCode": "Article",
			"productDescription": "Name",
			"productType": "Type",
			"nConta": "Invoice Account",
			"nContaNC": "Credit Notes Account",
			"acresceDigMercado": "Adds market character",
			"acresceDigIVA": "Add VAT characters",
			"retencao": "Retention",
			"stateContasFormadas": "Error",
			"ano": "Year",
			"mes": "Month",
			"dataUltimoDoc": "Last doc.",
			"dataLancamento": "Release date",
			"select": "Selection",
			"nDocumento": "Document No.",
			"invoices": "Invoice No.",
			"valorBase": "Base value",
			"valorIVA": "VAT value",
			"valor": "Value",
			"valorBaseCG": "CG Base Value",
			"valorIVACG": "CG VAT value",
			"valorCG": "CG Value",
			"valorArredBase": "Arred.",
			"valorArredIVA": "Arred.",
			"paymentRefNo": "Receipt number",
			"nomeCliente": "Client",
			"valorLinhasDebito": "Debit amount",
			"valorLinhasCredito": "Credit value",
			"importa": "It matters",
			"nContaClassificada": "No. Classified account",
			"contaFormadaDigitosMercado": "Market character",
			"contaFormadaDigitosIVA": "VAT characters",
			"nContaFormada": "Account No. formed",
			"contaFormadaNome": "Name",
			"estado": "Error",
			"selectNode": "Sel.",
			"settlementAmount": "Discount",
			"contaDescRecibo": "Account Discounts Receipts",
			"anulado": "Voided",
			"nCodRepCC": "Breakdown"
		},
		"configMascaraAnalitica": {
			"modalTitle": "Analytics Setup",
			"comoPretendeAtribuirAnalitica": "How do you want to assign analytics"
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmation",
		"leavePromptMessage": "Clicking YES will exit and discard all processing data."
	},
	"importxls": {
		"headerinfo": "This application allows you to import different information into the CentralGest Software.",
		"fields": {
			"descricao": "Import",
			"fichasClientes": "Importing customer records",
			"fichasFornecedores": "Import of supplier records",
			"artigos": "Article import",
			"contasCorrentesClientes": "Importing customer current accounts",
			"contasCorrentesFornecedores": "Importing supplier current accounts"
		}
	},
	"indicefluxos": {
		"title_detail": "Index flows {{id}}",
		"title_new": "New Index flows",
		"title_plural": "Flow Indexes",
		"pesquisa": "To look for",
		"saved": "Flow index {{id}}, saved successfully",
		"error": "Flows index {{id}}, cannot be saved.",
		"fields": {
			"indice": "Index",
			"indicePlaceholder": "flow index",
			"tipo": "Type",
			"tipoPlaceholder": "flow index type",
			"nome": "Name",
			"nomePlaceholder": "stream index name"
		}
	},
	"integracaoSalarios": {
		"tabIntegrarPorEmpregadoCaption": "Integrate By Employee",
		"tabIntegrarPorProcessamentoCaption": "Integrate By Processing",
		"tabRemoverIntegracao": "Remove Integration",
		"selectAll": "Select all",
		"unSelectAll": "Clear Selection",
		"config": "To set up",
		"labelTodosProcs": "All types of processing",
		"dataDe": "Processing Date:",
		"dataAte": "Date Until:",
		"ate": "Until:",
		"codEmpDe": "From the Employee",
		"pesquisar": "To look for",
		"erroTitle": "Error",
		"configModalTitle": "Configuring Salary Integration",
		"configModalTipoIntegTabCaption": "Type Integration",
		"configModalConfigAcertosSegSocTabCaption": "Config.",
		"configModalTipoIntegRadioGroupLabel": "Result of Integration in Accounting",
		"tipoIntegUmDocPorEmpregado": "One Document Per Employee",
		"tipoIntegUmDocPorProcessamento": "One Document Per Processing",
		"diarioLabel": "Daily",
		"descritivoLabel": "Descriptive",
		"codEstadoLabel": "State Code",
		"contasContrapartidaAcertoSSTitle": "Social Security Settlement Counterpart Accounts",
		"contaGastoAcertoLabel": "Adjustment expense account",
		"contaRendimentoAcertoLabel": "Hit Income Account",
		"processMessage": "Processing... please wait a moment.",
		"jobTimeoutModalTitle": "Task",
		"jobTimeoutModalMessage": "We're sorry, but the task has timed out.",
		"integrarPorEmpregadoMainTitle": "Integrate Salaries Per Employee",
		"integrarPorProcessamentoMainTitle": "Integrate Salaries by Processing",
		"descricaoLabel": "Description",
		"simularInteg": "Simulate Integration",
		"execInteg": "Run Integration",
		"removeInteg": "Remove Integration",
		"nDocExtLabel": "External Doc. No.",
		"canceladoPeloUtiliz": "Operation canceled by the user.",
		"processDoneModalTitle": "Salary Integration",
		"processDoneModalMsg": "Salary Integration has been completed.",
		"simulatorModalTitle": "Salary Integration Simulation: {{company}}",
		"integModalTitle": "Salary Integration: {{company}}",
		"totalDebitoLabel": "Total Debit",
		"totalCreditoLabel": "Total Credit",
		"onlyErrorsLabel": "Only with errors",
		"integRemovidaComSucesso": "Salary Integration has been successfully removed.",
		"integrar": "To integrate",
		"temSelecionarRegistos": "You have to select records.",
		"visualizarErros": "Do you want to view the errors?",
		"errosIntegSalariosModalTitle": "Salary Integration Errors",
		"verErrosModal": "View Errors",
		"verSimuladorModal": "View Simulator",
		"fields": {
			"selected": "Sel",
			"dataProcessamento": "Date",
			"nProcessamento": "Proc No.",
			"nomeProcessamento": "Description",
			"tipoProcessamento": "Type",
			"tipoProcessamentoDescricao": "Type Desc.",
			"totalVencAbonos": "Total Allowances",
			"totalVencDescontos": "Total Discounts",
			"totalEncargos": "Total Charges",
			"erro": "Error",
			"codEmp": "Emp Code.",
			"nomeEmpregado": "Name",
			"nConta": "Account No.",
			"periodo": "Period",
			"nDiario": "Daily No.",
			"nDescritivo": "Descriptive No.",
			"nDocInterno": "Internal Doc. No.",
			"nDocExterno": "External Doc. No.",
			"descricao": "Description",
			"dataDoc": "Date Doc.",
			"dataValor": "Date value",
			"dataVencimento": "Expiration Date",
			"valor": "Value",
			"nContaCredito": "Credit Account No.",
			"nContaDebito": "Deb Account No.",
			"nomeConta": "Account Name",
			"temErro": "Error",
			"integId": "Integration",
			"cambio": "Exchange",
			"codMoeda": "Currency Code",
			"nCCusto": "Cost No.",
			"nContrib": "NIF",
			"nRefProcesso": "Ref. No. Proc.",
			"contaOrigem": "Origin Account",
			"mascaraOrigem": "Origin Mask",
			"valorME": "ValorME",
			"tipoVencimentosEncargos": "Type",
			"nCodAbdesc": "Allowance/Discount"
		}
	},
	"integracaoSalariosMulti": {
		"promptViewResultsTitle": "Salary Integration (Multi-Company)",
		"promptViewResultsMessage": "Salary Salaries integration has ended.",
		"selMesIntegrarTitle": "Select the Month",
		"viewResultBtn": "To view",
		"initNewProc": "Start",
		"jobTimeoutModalTitle": "Task",
		"jobTimeoutModalMessage": "The task has timed out.",
		"comecar": "To start",
		"erro": "Error",
		"temSeleccionarUmaEmpresa": "You must select at least one company.",
		"tabIntroCaption": "Introduction",
		"tabIntroTitle": "Welcome to the wizard to perform Multi-Company Salary Integration.",
		"tabIntroSubTitle": "This wizard will guide you through the process of automatically integrating salaries for all companies that are selected and with the settings configured correctly.",
		"tabIntroSubTitle2": "At the end, you are shown the documents that were entered in Accounting.",
		"tabIntroSubTitle3": "Click \"Next\" to continue.",
		"tabEmpresasCaption": "Companies",
		"tabProcCaption": "Processing",
		"tabResultsCaption": "Report",
		"configLabel": "Settings",
		"fields": {
			"selected": "Sel.,",
			"nEmpresa": "Company",
			"nomeEmpresa": "Company name",
			"ano": "Year",
			"mes": "Month",
			"codEmpresa": "Company No.",
			"nDocInterno": "Internal Doc. No.",
			"descricaoErro": "Report"
		}
	},
	"intfac": {
		"title": {
			"vendas": "Sales",
			"compras": "Shopping",
			"outros": "Others",
			"selectAll": "Select all",
			"docnaointcontab": "Documents not integrated into accounting"
		},
		"btn": {
			"encerrar": "Close",
			"pesquisar": "To look for",
			"editDoc": "Edit accounting document",
			"simular": "Simulate"
		},
		"tooltip": {
			"doccomerro": "Document with error."
		},
		"table": {
			"selected": "Sel.",
			"nDoc": "Document",
			"dataDoc": "Date of document",
			"dataVenci": "Due Date",
			"estado": "state",
			"nConta": "Account",
			"nDocExt": "External Doc. No.",
			"erro": "Error",
			"nDocumentoFacturacao": "Billing Document No.",
			"periodo": "Period",
			"nDiario": "Daily",
			"nDocInterno": "Document No.",
			"nContaDebito": "Debt",
			"nContaCredito": "Credit",
			"valor": "Value",
			"nomeConta": "Name",
			"codMoeda": "Currency Code",
			"valorME": "ME Value",
			"valorTaxa": "Rate Value",
			"valorTaxaME": "ME Rate Value",
			"codIva": "VAT Code",
			"valorRetencao": "Retention Value",
			"nContrib": "VAT Number",
			"dataDocExterno": "External Doc Date",
			"codIntegra": "Integration Code",
			"descricao": "Description Code."
		},
		"tooltips": {
			"cantBtnPesquisar": "Select at least one document type to search",
			"cantBtnEncerrar": "Select at least one document to close"
		},
		"messages": {
			"successfullySaved": "Completed successfully.",
			"docscomerro": "Selected documents contain error.",
			"semgruposelecionado": "Select document types.",
			"dataNotFound": "The selected types do not contain documents to close.",
			"simErro": "Error processing the simulation."
		},
		"errors": {
			"integradoMasComErro": "Integrated but with error."
		},
		"modal": {
			"title": "Simulation Results",
			"legend": {
				"legendErro": "With Error"
			}
		}
	},
	"invec": {
		"pesquisa": "To look for",
		"messages": {
			"reprocessaSuccess": "Inventory reprocessed successfully!",
			"errorName": "The inventory name must be filled in.",
			"saveSuccess": "Inventory saved successfully!",
			"deleteSuccess": "Inventory deleted successfully!",
			"revalorizaSuccess": "Inventory revalued successfully!",
			"confirmation": "Confirmation",
			"deletePrompt": "Are you sure you want to delete inventory?",
			"sistemaNaoDevolveuFicheiro": "The system did not return the file.",
			"dataRequired": "The date is mandatory"
		},
		"fields": {
			"nome": "Name",
			"descricao": "Description",
			"data": "Date",
			"deNArmazem": "From warehouse",
			"ateNArmazem": "Even warehouse",
			"deTipoArtigo": "Article type",
			"ateTipoArtigo": "Even article type",
			"deGrandeFamilia": "From a big family",
			"ateGrandeFamilia": "Even big family",
			"deFamilia": "Family's",
			"ateFamilia": "Even family",
			"deSubFamilia": "From sub.",
			"ateSubFamilia": "Even sub.",
			"deClasse": "Class",
			"ateClasse": "Even class",
			"deCategoria": "By category",
			"ateCategoria": "Up to category",
			"deValorimetria": "Valuation",
			"ateValorimetria": "Even valuation",
			"deNArtigo": "From article",
			"ateNArtigo": "Until article",
			"stockNulo": "Null stock",
			"desactivados": "Disabled",
			"porLote": "By batch",
			"dataRef": "Reference date"
		},
		"valorizar": {
			"modal": {
				"title": "Inventories - Valuation",
				"valorizacao": "Valuation"
			}
		},
		"btn": {
			"carregarDados": "Load data",
			"save": "Save",
			"mostrar": "Show",
			"exportAT": "Export/Communicate AT Inventory",
			"exportATHint": "Export Inv. Stocks AT file",
			"retroceder": "Go back",
			"pesquisarArtigos": "Search Articles",
			"criar": "Create Inventory",
			"ficheiroCSV": "CSV file",
			"ficheiroXML": "XML file"
		},
		"newModalTitle": "Record Inventory",
		"pesquisarArtigosTooltip": "Search articles",
		"criarTooltip": "Create Inventory with items from the table",
		"options": {
			"showTipoArtigo": "Show article type",
			"showGrandeFamilia": "Show big family",
			"showSubFamilia": "Show sub.",
			"showClasse": "Show class",
			"showCategoria": "Show category",
			"showValorimetria": "Show valuation"
		}
	},
	"inven": {
		"fields": {
			"nArtigo": "Article Code",
			"nomeArtigo": "Name Article",
			"nomeArmazem": "Warehouse Name",
			"nArmazem": "Storage",
			"lote": "Batch",
			"qtd": "Amount",
			"precoValor": "Unit price",
			"valor": "Value",
			"nLoteEspecifico": "Specific Lot"
		},
		"filename": "Stock_inventory",
		"tooltips": {
			"fichaArtigo": "Article sheet",
			"extratoArtigo": "Article extract"
		}
	},
	"inventarios": {
		"title": "Inventories",
		"print": {
			"modalTitle": "Print Inventory"
		}
	},
	"inversaoSujPassivo": {
		"pesquisa": "Types of passive subject inversion",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"notApplicable": "AT",
			"withInversion": "With Inversion",
			"noInversion": "No Inversion"
		}
	},
	"invoiceType": {
		"fields": {
			"None": "Unknown",
			"Fatura": "Invoice",
			"FaturaSimplificada": "Simplified Invoice",
			"FaturaRecibo": "Invoice Receipt",
			"NotaDebito": "Debit note",
			"NotaCredito": "Credit note",
			"VendaDinheiro": "Sell ​​for Cash",
			"TalaoVenda": "Sales Receipt",
			"TalaoDevolucao": "Return Slip",
			"AlienacaoAtivos": "Asset Disposal",
			"DevolucaoAtivos": "Return of Assets",
			"Premio": "Prize or prize receipt",
			"Estorno": "Chargeback or refund receipt",
			"ImputacaoCoSeguradoras": "Imputation to co-insurers",
			"ImputacaoCoSeguradoraLider": "Imputation to lead co-insurer",
			"ResseguroAceite": "Reinsurance accepted",
			"Recibo": "Receipt",
			"NotaLancamento": "Release note",
			"ExtratoBancario": "Bank statement",
			"PagamentoAoEstado": "Payment to the state",
			"Levantamento": "Survey",
			"Pagamento": "Payment",
			"Deposito": "Deposit",
			"Cheque": "Check",
			"Transferencia": "Transfer"
		}
	},
	"irsdt": {
		"title_detail": "IRS Table Effective Date",
		"title_new": "New Effective Date of the IRS Table",
		"title_plural": "IRS Table Effective Dates",
		"pesquisa": "To look for",
		"saved": "Effective Date of the IRS Table, saved successfully.",
		"error": "Effective Date of the IRS Table, cannot be saved.",
		"deleted": "Effective Date of the IRS Table, successfully deleted.",
		"fields": {
			"tabIRSDT": "IRS Rate Date"
		}
	},
	"ivamotivostaxa": {
		"title_detail": "Reason for VAT Exemption {{id}}",
		"title_new": "New Reason for VAT Exemption",
		"title_plural": "Reasons for VAT Exemption",
		"pesquisa": "To look for",
		"saved": "Reason for VAT Exemption {{id}}, saved successfully.",
		"error": "VAT Exemption Reason {{id}}, cannot be saved.",
		"deleted": "VAT Exemption Reason {{id}}, successfully deleted",
		"fields": {
			"ivaMotivosTaxaId": "Code",
			"ivaMotivosTaxaIdPlaceholder": "VAT Exemption Code",
			"ivaMotivosTaxaCod": "Reason Code",
			"ivaMotivosTaxaCodPlaceholder": "VAT Exemption Reason Code",
			"descricao": "Description",
			"descricaoPlaceholder": "Description",
			"normaAplicavel": "Applicable Standard",
			"normaAplicavelPlaceholder": "Applicable Standard"
		},
		"novaTaxaIVA": "New Reason for VAT Exemption"
	},
	"ivas": {
		"title_detail": "VAT code {{id}}",
		"title_new": "New VAT Code",
		"title_plural": "VAT codes",
		"pesquisa": "To look for",
		"saved": "VAT code {{id}}, saved successfully.",
		"error": "VAT code {{id}}, cannot be saved.",
		"deleted": "VAT code {{id}}, successfully deleted",
		"fields": {
			"codIva": "Code",
			"codIvaPlaceholder": "VAT code",
			"nome": "Name",
			"nomePlaceholder": "Name VAT code",
			"taxaActual": "Rate",
			"taxaActualPlaceholder": "Rate",
			"dataTaxaActual": "Date ref.",
			"percDedGasoleo": "Perc.",
			"percDedGasoleoPlaceholder": "Diesel deductible percentage",
			"percDedProRata": "Perc.",
			"percDedProRataPlaceholder": "Pro-rata deductible percentage",
			"codAlternInfac": "Alternate Code",
			"codAlternInfacPlaceholder": "Alternate Code",
			"tipoTaxa": "Rate Type",
			"areaRegional": "Regional Area",
			"tipoDeducao": "Type of Deduction",
			"ivaMotivosTaxaId": "Reason for Exemption"
		},
		"novaTaxaIVA": "New VAT Rate",
		"tooltip": {
			"codAlternInfacTooltip": "Completes the integration of the Tax Base."
		}
	},
	"justificacaoAusencia": {
		"data": {
			"tjaNA": "AT",
			"tjaSim": "Yes",
			"tjaNao": "No"
		}
	},
	"listagemdiarios": {
		"btn": {
			"previsualizar": "Preview"
		},
		"header": {
			"list": "Listing",
			"fromAccount": "Account",
			"toAccount": "Until Account",
			"doPeriodo": "Period",
			"atePeriodo": "Until Period",
			"doDiario": "Daily",
			"ateDiario": "Until Diary",
			"docInterDo": "Internal Doc",
			"docInterAte": "Even Internal Doc",
			"datadocde": "Date Doc.",
			"datadocate": "Until Doc Date.",
			"nutilizadorde": "User",
			"nutilizadorate": "Up to User"
		}
	},
	"listagemMenus": {
		"table": {
			"almoco": "Lunch",
			"jantar": "To have lunch",
			"sopa": "Soup",
			"geral": "General",
			"ligeira": "Light",
			"sobremesa": "Dessert"
		},
		"form": {
			"local": "Local:",
			"de": "In:",
			"btns": {
				"pesquisar": "To look for",
				"imprimir": "Print out"
			}
		},
		"title": {
			"ementaSemanal": "WEEKLY MENU",
			"local": "Local:"
		}
	},
	"loadmodule": {
		"title": "Loading..."
	},
	"localObtencaoRendimentos": {
		"data": {
			"continente": "Continent",
			"regiaoAcores": "Autonomous Region of the Azores",
			"regiaoMadeira": "Autonomous Region of Madeira",
			"estrangeiro": "Foreigner"
		}
	},
	"locale": {
		"descriptive": {
			"en": "English",
			"es": "Español (Spanish)",
			"fr": "Français (French)",
			"pt-PT": "Português (Portuguese)"
		}
	},
	"local": {
		"title_detail": "Location {{id}}",
		"title_new": "New Location",
		"title_plural": "places",
		"pesquisa": "To look for",
		"saved": "Location {{id}}, saved successfully.",
		"error": "Location {{id}}, cannot be saved.",
		"deleted": "Location {{id}}, deleted successfully",
		"fields": {
			"nLocalidade": "Code.",
			"nLocalidadePlaceholder": "Locality Code",
			"designaBreve": "Brief Designation",
			"designaBrevePlaceholder": "Brief Designation",
			"designaCompl": "Full Designation",
			"designaComplPlaceholder": "Full Designation"
		}
	},
	"lockedcontract": {
		"title": "Your contract is blocked",
		"titleTemporary": "Your contract is temporarily blocked",
		"changeCompany": "Change company",
		"reasons": {
			"0": "N/A",
			"1": "Your contract is under maintenance, this process may take some time, please wait."
		}
	},
	"loginAT": {
		"title": "AT Configuration",
		"success": "Login details saved successfully",
		"panels": {
			"efatura": "E-Invoice AT Login",
			"efaturaCirculacao": "AT Webservice Login for Communication of Transport Documents",
			"nifAt": "AT Company Login",
			"docfasNumsComunicacaoLogin": "Series Communication Login"
		},
		"fields": {
			"campo": "Field",
			"valor": "Value",
			"usernif": "(NIF/User)",
			"password": "Password",
			"nif": "VAT Number"
		}
	},
	"loginWsSS": {
		"title": "Social Security Authentication - SSD",
		"success": "Login details saved successfully",
		"dadosEmpresa": "Company data",
		"dadosMandatario": "Mandatory Data",
		"fields": {
			"niss": "NISS",
			"password": "Password"
		},
		"messages": {
			"credentialsSaved": "The credentials were saved successfully.",
			"errorNISSEmpresaEmpty": "The company's NISS is empty.",
			"errorPasswordEmpresaEmpty": "The company password is empty.",
			"errorPasswordMandatarioEmpty": "The agent's password is empty."
		},
		"nissAutenticacaoDiferenteFichaEmpresa": "The NISS of the authentication \"{{nissAuth}}\" is different from the company record \"{{nissEmpresa}}\"."
	},
	"lotes": {
		"title_detail": "Lot {{id}}",
		"title_new": "New Lot",
		"title_plural": "Lots",
		"pesquisa": "To look for",
		"saved": "Lot {{id}}, saved successfully",
		"error": "Lot {{id}}, cannot be saved.",
		"deleted": "Lot {{id}}, deleted successfully",
		"fields": {
			"nLote": "Batch code",
			"nArtigo": "Article code",
			"nLoteEspecifico": "Specific batch",
			"loteFornecedor": "Supplier batch",
			"nConta": "Supplier",
			"dataFabrico": "Manufacturing date",
			"dataValidade": "Expiration date",
			"qtdTotal": "Total quantity",
			"terminado": "Finished",
			"nomeArtigo": "Article name",
			"nomeConta": "Supplier name"
		},
		"errors": {
			"artigoDaLinhaNaoTemLote": "There is no batch selected for the article {{nartigo}}.",
			"artigoNaoTemLote": "The article code {{nArtigo}} does not have a batch."
		},
		"btn": {
			"new": "New"
		},
		"list": {
			"config": {
				"showSoTerminados": "See only Finished"
			},
			"actions": {
				"editar": "To edit",
				"terminar": "finish",
				"teminado": "Finished",
				"verSoLotesTerminados": "See only finished batches",
				"verSoLotesAtivos": "See only active batches"
			}
		},
		"okCancel": {
			"terminar": {
				"title": "Finish batch",
				"message": "Are you sure you want to finish the batch {{lot}} of the article {{nartigo}}?"
			}
		}
	},
	"maintenance": {
		"pageTitle": "Scheduled maintenance",
		"title": "We are carrying out scheduled maintenance",
		"message": "Sorry for the inconvenience.<br/>We will get back to you as soon as possible."
	},
	"mapaexploracao": {
		"title": "Exploration Map",
		"table": {
			"header": {
				"meses": "Months"
			}
		},
		"fields": {
			"periodoDe": "Period of",
			"conta": "Account",
			"descricao": "Description",
			"saldo01": "January",
			"saldo02": "February",
			"saldo03": "March",
			"saldo04": "April",
			"saldo05": "May",
			"saldo06": "June",
			"saldo07": "July",
			"saldo08": "August",
			"saldo09": "September",
			"saldo10": "October",
			"saldo11": "November",
			"saldo12": "December",
			"total": "Total"
		},
		"summary": {
			"rendimentos": "Income",
			"compras": "Purchases and expenses",
			"totalrendimentos": "Total income",
			"totalcompras": "Total purchases and expenses",
			"totalexploracao": "Exploration result"
		},
		"btn": {
			"dropdown": {
				"balancete": "Balance sheet"
			}
		},
		"modal": {
			"config": {
				"title": "Exploration Map Configuration",
				"label": {
					"tipoarredondamento": "Rounding type"
				},
				"tipoarredondamento": {
					"semArredondamento": "No rounding",
					"arredondamentoAoEuro": "Round to the Euro"
				}
			}
		}
	},
	"mapapagam": {
		"texts": {
			"comSelecionados": "Selected...",
			"carregarPagamentos": "To load",
			"empresas": "Companies",
			"pagamentos": "Payments",
			"pagamentosManuais": "Manual Payments",
			"adiconarPagamentoManual": "Create manual payment",
			"criarPagamento": "Create payment",
			"editarPagamento": "Edit payment",
			"periocidade": "Periodicity",
			"dadosPagamento": "Payment data",
			"editarPagamentoManual": "Edit Manual Payment",
			"verDocumento": "View Document(s)",
			"enviarPagamento": "To send",
			"reEnviarPagamento": "Re-Send Payment(s)",
			"marcarComoPago": "Mark as paid",
			"confirmation": "Confirmation",
			"visualizacaoDocumento": "Document preview",
			"documento": "Document",
			"selecionarTodosPagamentos": "Select all payments",
			"selecionarEstePagEmTodasEmpresa": "Select this payment in all companies",
			"enviarPagamentos": "Send Payments",
			"confirmacao": "Confirmation",
			"continuar": "Continue",
			"configurarCredentials": "Configure credentials",
			"resumoEnvioErros": "Submission Summary - Errors",
			"existemEmpresasComErros": "There are companies with shipping errors.",
			"listaDePagamentosAEnviar": "List of payments to send",
			"viewDocumentoUnico": "Preview single document with selected payments",
			"verDetalhesPagamento": "see details",
			"apagarPagamento": "Delete Payment",
			"detalhesPagamento": "Payment details",
			"semDetalhesPagamento": "No payment details.",
			"naoPossivelVisualizarDocNaoEmitido": "Unable to view the document.",
			"podeEmitirDocNosDocsFiscais": "You can issue the document on Tax Documents or on the Finance Portal (AT)",
			"selecionarEmpresasSemErro": "Select companies without errors",
			"portalAT": "Finance Portal (AT)",
			"portalFCT": "Compensation Fund",
			"portalSSD": "Direct Social Security",
			"enviarSelecionados": "Send selected",
			"enviarApenas": "Select and Send only {{name}}",
			"mostrarApenasSelecionadas": "Show Selected Only",
			"mostrarComFalhaLogin": "Show login failed",
			"pagEstado": "Pag.",
			"configuracao": "Settings",
			"pagamentosDisponiveis": "Available Payments",
			"pagamentosSelecionados": "Selected Payments",
			"disponivelBrevemente": "This functionality will be available soon.",
			"regimeIva": {
				"all": "All",
				"normal": "Normal",
				"isencao": "Exemption",
				"pequenosRetalhistas": "Small Retailers",
				"regimeNormalBens2Mao": "Normal Regime + 2nd Hand Goods",
				"regimeNormalRevendaCombustíveis": "Normal Regime + Fuel Resale",
				"regimeNormalIvaCaixa": "Normal Regime + Cash VAT"
			},
			"periodoIva": {
				"all": "All",
				"mensal": "Monthly",
				"trimestral": "Quarterly"
			},
			"limparFiltros": "Clear filters",
			"aplicar": "To apply",
			"alertMessage": "We do not recommend using the Payment Map between 12pm and 2pm, as these times serve as maintenance for some public entities",
			"criarPagamentoManual": "Create Manual Payment",
			"pagamentoManual": "Manual Payment",
			"todasEmpresas": "All Companies",
			"todosMeses": "Every Month",
			"durante": "During",
			"anos": "years)",
			"selecioneOQuePertendeApagar": "Select what you want to delete:",
			"apagarApenasMesCorrente": "Delete only from the current month",
			"apagarTodosMesesNoAnoCurrente": "Delete all months of the current year",
			"apagarPorCompletoEmTodasEmpresas": "Delete completely and across all companies",
			"confirmar": "Confirm",
			"documentosFiscais": "Fiscal documents",
			"anexo": "Attachment",
			"removerAnexo": "Remove Attachment",
			"accoes": "Actions",
			"documentoUnicoPdfName": "{{year}}{{month}}_DU_{{nCompany}}_{{now}}.pdf",
			"pagamentoPdfName": "{{year}}{{month}}_{{nCompany}}_{{name}}_{{now}}.pdf",
			"aPreparar": "Preparing...please wait a moment.",
			"recarregar": "Recharge"
		},
		"fields": {
			"nEmpresa": "N. Company",
			"nomeEmpresa": "Name",
			"nomePagamento": "Name Payment",
			"descricaoPagamento": "Description Payment",
			"refPagDescricao": "Ref. Payment (Description)",
			"dataLimitePag": "Payment deadline",
			"montante": "Amount",
			"email": "Email",
			"pago": "Paid out",
			"errorMessage": "Error",
			"status": "state",
			"nif": "NIF",
			"regimeIva": "VAT Regime",
			"periodoIva": "VAT Period",
			"responsavel": "Responsible",
			"iva": "VAT",
			"ircMod22": "IRC MOD 22",
			"imi": "IMI",
			"iuc": "IUC",
			"dmr": "DMR AT",
			"retencaoNaFonte": "Ret.",
			"pagAntIRC": "Pag.",
			"dmrSS": "DMR SS"
		},
		"messages": {
			"temSeleccionarEmpresa": "You must select at least one company.",
			"temQuePreencherNomePagamento": "You have to fill in the name of the payment",
			"temQueSelecionarPeloMenosUmMes": "You must select at least one month",
			"marcarComoPagoPrompt": "Are you sure you want to mark this payment as paid?",
			"empresaNaoTemPagamentosSelecionados": "Company \"{{company}}\" has no payments selected",
			"empresaNaoTemEmailPreenchido": "The company \"{{company}}\" does not have an email address filled in",
			"empresaNaoTemPagamentosCarregados": "The company \"{{company}}\" has no payments loaded.",
			"processMessage": "Processing... please wait a moment.",
			"empresaNaoTemEmailInvalido": "The company \"{{company}}\" has an invalid email address",
			"existemPagamentoEnviados": "There are selected payments that have already been sent.",
			"documentoUnicoEnviadoSucesso": "The single document with payments was sent successfully",
			"carregarEmpresas": "Loading companies...",
			"pagManGuardadoSucesso": "Manual payment was saved successfully"
		},
		"tooltips": {
			"emailLido": "The email with this payment was read on {{lidoDataHora}}",
			"emailEnviado": "The email with this payment was sent on {{sentDateTime}}",
			"emailNaoEnviado": "The email with this payment was not sent.",
			"pago": "Payment has already been paid",
			"obterDadosPortaisATeSSD": "Obtain data from the AT and SSD portals",
			"enviarPagamentosSelParaEmpresasSel": "Send selected payments to selected companies",
			"emailEnviadoELido": "The email with this payment was sent on {{enviadoDataHora}} and read on {{lidoDataHora}}",
			"enviarPagamentosSelecionadosGrelha": "Send payments selected in the grid",
			"naoEPossivelObterEstadoPagamento": "It is not possible to obtain the payment status."
		},
		"btn": {
			"resend": "Re-send",
			"resendSkipErrors": "Re-submit (Ignore Errors)"
		}
	},
	"mapasamortizacao": {
		"title_detail": "Amortization Map {{id}}",
		"title_new": "New Amortization Map",
		"title_plural": "Amortization Maps",
		"pesquisa": "To look for",
		"saved": "Amortization Map {{id}}, saved successfully.",
		"error": "Amortization Map {{id}}, cannot be saved.",
		"deleted": "Amortization Map {{id}}, successfully deleted",
		"fields": {
			"nImmap": "Code.",
			"nImmapPlaceholder": "Amortization Map Code",
			"nome": "Name",
			"nomePlaceholder": "Amortization Map Name"
		}
	},
	"mascarasanalitica": {
		"title_detail": "Analytics mask {{id}}",
		"title_new": "New Analytics Mask",
		"title_plural": "Analytics masks",
		"pesquisa": "To look for",
		"saved": "Analytical mask {{id}}, saved successfully.",
		"error": "Analytics mask {{id}}, cannot be saved.",
		"errorAdd": "This type of character already exists in a previous position of the mask",
		"deleted": "Analytics mask {{id}}, successfully deleted.",
		"fields": {
			"manalID": "Code.",
			"descricao": "Description",
			"mascara": "Mask",
			"tipo": "Mask Type",
			"nCaracteresGeral": "No. characters",
			"isDefault": "Default mask",
			"subTotaisDaGeral": "Creates subtotals based on the overall accumulator structure",
			"indicesSubTotais": "Subtotals indices",
			"contaContrapartida": "Offset account"
		},
		"placeholders": {
			"descricao": "Analytics Mask Description",
			"mascara": "Mask",
			"tipo": "Mask type",
			"nCaracteresGeral": "Number of characters in the general account",
			"isDefault": "Default mask",
			"subTotaisDaGeral": "Creates subtotals based on the overall accumulator structure",
			"indicesSubTotais": "Subtotals indices"
		},
		"tipos": {
			"invoicing": "Billing",
			"salary": "Salary",
			"fixed": "Immobilized"
		},
		"tabs": {
			"definition": "Mask Definition",
			"definitionTitle": "Mask",
			"definitionNames": "Defining account names to generate",
			"definitionNamesTitle": "Available",
			"nameTitle": "Created Account Name",
			"nameItemsTitle": "Available fields"
		},
		"table": {
			"description": "Description",
			"char": "Character",
			"subTotal": "Create Sub-Total",
			"type": "Type",
			"field": "Field by definition",
			"text": "Text"
		},
		"enum": {
			"definition": {
				"0": "Constant 0",
				"1": "Constant 1",
				"2": "Constant 2",
				"3": "Constant 3",
				"4": "Constant 4",
				"5": "Constant 5",
				"6": "Constant 6",
				"7": "Constant 7",
				"8": "Constant 8",
				"9": "Constant 9"
			},
			"nomes": {
				"poc": "Source General Account Name"
			},
			"definitionFaturacao": {
				"ccusto": "Cost center",
				"zone": "Zone",
				"department": "Department",
				"subDepartment": "Sub Department",
				"family": "Family",
				"bigFamily": "Big family",
				"subFamily": "Sub Family",
				"accountingCode": "Accounting Code",
				"itemType": "Type Article",
				"class": "Class",
				"category": "Category",
				"seller": "Seller",
				"process": "Process"
			},
			"definitionSalarios": {
				"ccusto": "Cost center",
				"accountingCode": "Accounting Code",
				"workerClass": "Worker Class Code",
				"roleName": "Function Code",
				"employeeCCusto": "Employee Cost Center",
				"categoryFamily": "Category Family Code",
				"zone": "Zone",
				"department": "Department"
			},
			"definitionImobilizado": {
				"ccusto": "Cost center",
				"department": "Department",
				"bigFamily": "Big family",
				"itemType": "Type Article",
				"accountingCode": "Accounting Code"
			},
			"nomesFaturacao": {
				"ccusto": "Cost Center Name",
				"zone": "Zone Name",
				"department": "Department Name",
				"subDepartment": "Sub Department Name",
				"family": "Family Name",
				"bigFamily": "Big Family Name",
				"subFamily": "Sub Family Name",
				"accountingCode": "Accounting Code Name",
				"itemType": "Article Type Name",
				"class": "Class Name",
				"category": "Category Name",
				"seller": "Seller's Name",
				"process": "Process Name"
			},
			"nomesSalarios": {
				"ccusto": "Cost Center Name",
				"accountingCode": "Accounting Code Name",
				"workerClass": "Worker Class Name",
				"roleName": "Function Name",
				"employeeCCusto": "Employee Cost Center Name",
				"categoryFamily": "Category Family Name",
				"zone": "Zone Name",
				"department": "Department Name"
			},
			"nomesImobilizado": {
				"ccusto": "Cost Center Name",
				"department": "Department Name",
				"family": "Family Name",
				"bigFamily": "Big Family Name",
				"itemType": "Article Type Name",
				"accountingCode": "Accounting Code Name"
			}
		},
		"changeTypePrompt": {
			"title": "Are you sure you want to change the mask type?",
			"message": "Changing the mask type will clear the changes made to it."
		},
		"textPrompt": {
			"title": "Change field text"
		}
	},
	"mbconfigs": {
		"titles": {
			"pagRec": "Payments/Receipts",
			"addtipodoc": "Add document type"
		},
		"fields": {
			"entidade": "Entity",
			"accountID": "API ID",
			"apiKey": "Key API",
			"contaTransf": "Transfer account",
			"contaTransitoriaTransf": "Transitory account",
			"transferenciaLancaSaldo": "Launch transfer with balance amount",
			"contaComissao": "Commission account",
			"diario": "Daily",
			"descritivo": "Descriptive",
			"codEstadoDocPago": "Doc status.",
			"enviarrecibo": "Send customer receipt via email",
			"enviarreciboTooltip": "Validate emails in customer files",
			"enviarrecibotitle": "Payment import notification",
			"enviarreciboinfo": "The system will automatically import",
			"dataInicioRef": "Create references from",
			"geraRefMovimentoAberto": "I intend to generate a second reference with the customer's outstanding balance",
			"internalDesc": "Possible document types to configure",
			"ativo": "Active",
			"inativo": "Inactive",
			"estado": "state",
			"advancedMode": "Advanced mode",
			"tipoDoc": "Document type"
		},
		"messages": {
			"configsavedsucess": "Configuration of ATM references saved successfully!",
			"fornecedorRefMult": "ATM Ref Supplier: EASYPAY",
			"advancedModeInfo": "You should only use this mode if you want to configure the accounts in the chart of accounts.",
			"ndocfaempty": "You must select a document type.",
			"ndocfasuccess": "The document type has been added successfully.",
			"infogeral": "In order to use the EasyPay platform integrated with Centralgest CLOUD, you must obtain a new CIN from EasyPay. This applies to both new customers and those who already have an account created with EasyPay.<br>This does not mean that You will have to create a new account, just a new CIN.",
			"info": "Information",
			"loginempty": "You must fill in the \"API ID\" and \"API key\" fields.",
			"functioning": "As soon as EASYPAY notifies the system, we will post the respective receipt.",
			"dataEasyPay": "The above data is provided by EASYPAY.",
			"selectDocFas": "Select the document types in which you want to configure References: MB"
		},
		"btn": {
			"acessobackend": "Backoffice access",
			"addTiposDoc": "Add more document types",
			"criareasypay": "Create an EASYPAY account",
			"faq": "FAQ - Help",
			"testConnection": "Test connection"
		},
		"tooltips": {
			"contaTransferencia": "Final account for transferring the amount",
			"contaTransitoria": "Temporary account, used after actual receipt",
			"contaComissao": "Account where processing fees will be deposited"
		}
	},
	"meiosPagamento": {
		"title_detail": "Payment method {{id}}",
		"title_new": "New payment method",
		"title_plural": "Payment options",
		"pesquisa": "To look for",
		"saved": "Payment method {{id}}, saved successfully",
		"error": "Payment method {{id}}, cannot be saved.",
		"deleted": "Payment method {{id}}, successfully deleted",
		"fields": {
			"nCaixa": "Number",
			"nCaixaPlaceholder": "If not filled in, it will be generated automatically.",
			"nome": "Name",
			"tipoMovimento": "Type",
			"nConta": "Account No.",
			"moeda": "Currency Code",
			"tipo": "Type of Payment Method",
			"nib": "IBAN",
			"swift": "SWIFT",
			"porDefeito": "By default"
		},
		"data": {
			"indiferente": "Indifferent",
			"recebimento": "Receipt",
			"pagamento": "Payment",
			"dinheiro": "Money",
			"multibanco": "ATM",
			"cartaoCredito": "Credit card",
			"cheque": "Check",
			"valorAdicional": "Additional Value",
			"pontos": "Points",
			"contraReemsolso": "Against reimbursement",
			"trfBancaria": "Bank transfer",
			"payPal": "PayPal"
		},
		"btn": {
			"verContas": "View Accounts",
			"buttonOptions": "options",
			"esconderContas": "Hide accounts"
		},
		"info": {
			"entregue": "Delivered",
			"troco": "Change"
		},
		"mensagens": {
			"meioPagSemMoedaEmpresaParaTroco": "Unable to find a cash payment method in company currency to put change",
			"montanteMeioPagNaoIgualTotalDoc": "The amount in payment methods {{amount}} is not equal to the document total {{total}}",
			"adicionarMeiosPagCancelado": "Add canceled payment methods",
			"meioPagamentoAlteradoPorOmissao": "This payment method is set by default to {{type}}, when changing the type of movement it will no longer be eligible and another will be automatically selected.",
			"meioPagamentoAlteradoPorOmissaoTitle": "Confirm change of default value for payment type",
			"erroTipoMeioPagamentoOmissao": "The chosen payment method does not have the same payment type as the configuration"
		},
		"table": {
			"actions": {
				"porDefeitoPara": "By default for",
				"alterarPorDefeito": "Change default payment methods",
				"recibos": "Receipts",
				"pagamentos": "Payments",
				"errorDesactivate": "You cannot leave a payment type without a default payment method"
			}
		}
	},
	"mercados": {
		"pesquisa": "Markets",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"notApplicable": "AT",
			"national": "National",
			"cee": "HUH",
			"thirdPartyCountries": "Third countries"
		}
	},
	"modelo10": {
		"groups": {
			"atencao": {
				"title": "Attention",
				"p1": "Frame 00",
				"p2": "When filling out model declaration 10, some declarative aspects must be taken into account, highlighting the following:",
				"p3": {
					"p3-1": "For declarations for the year 2019 and subsequent years, if they have been",
					"p3-2": "paid or made available",
					"p3-3": "relative income",
					"p3-4": "to previous years",
					"p3-5": ", table 05 field 02 must be completed in accordance with the help provided in this table/field, highlighting the need to indicate the year to which the income refers instead of the number of years."
				},
				"p4": "Persons or entities required to submit the Monthly Remuneration Declaration (DMR) cannot declare income from dependent work (category A) in the Model 10 declaration.",
				"p5": "Individuals owing income from dependent employment who are not required to submit the DMR may choose to submit the Model 10 declaration, as long as this income has not been subject to any withholding tax.",
				"p6": {
					"p6-1": "The Model 10 declaration is intended to declare income subject to tax, exempt and not subject to tax received by IRS taxpayers",
					"p6-2": "residents",
					"p6-3": "in the national territory, as well as the respective withholding taxes."
				},
				"p7": "Property income must always be declared, with codes F, even if it will be taxed as category B income because it is attributable to business or professional activities, in accordance with subparagraphs a) of paragraph 2 of article 3 of the IRS Code.",
				"p8": {
					"p8-1": "The geographic space to be indicated in table 5, field 05 (",
					"p8-2": "Mainland, Autonomous Region of the Azores, Autonomous Region of Madeira or Abroad",
					"p8-3": ") must correspond to the place where the income was obtained."
				}
			},
			"financAreaDomi": {
				"title": "Household Area Finance Service",
				"codservfinan": "Finance Service Code"
			},
			"nif": {
				"title": "Tax Identification Number",
				"nomepassivo": "Name of the Taxable Subject",
				"nif": "NIF"
			},
			"ano": {
				"title": "Year",
				"ano": "Year"
			},
			"importanciaretidas": {
				"title": "Retained Importances",
				"quadro4": {
					"campo1": "A - DEPENDENT WORK",
					"campo2": "B - BUSINESS AND PROFESSIONAL INCOME",
					"campo3": "E - OTHER CAPITAL INCOME",
					"campo4": "EE - CREDITOR BALANCES A/C (Article 12-A, no. 3, paragraph a) of Decree-Law 41/91, of 22 January)",
					"campo5": "F - BUILDINGS",
					"campo6": "G - EQUITY INCREASES",
					"campo7": "H - PENSIONS",
					"campo8": "IRC WITHHOLDINGS (Article 94 of the CIRC)",
					"campo9": "SUM (01 TO 08)",
					"campo10": "WITHHOLDS ON RELEASE FEES",
					"campo11": "IRS/IRC COMPENSATIONS",
					"campo12": "TOTAL (09 + 10 - 11)",
					"campo13": "SURCHARGE RETENTION (applicable from 2013 to 2017)"
				}
			},
			"reltitrendimentos": {
				"title": "List of Income Holders",
				"quadro5": {
					"table": {
						"sujpassivo": "Passive subject",
						"rendAnoAnt": "Income from previous years",
						"rendAno": "Income for the year",
						"tipoRend": "Type of income",
						"locobtrend": "Place of obtaining income",
						"retIrsIrc": "IRS/IRC withholdings",
						"contribObr": "Mandatory contributions",
						"quotSindicais": "Union dues",
						"retSobreTaxa": "Surcharge retention (years 2013 to 2017)",
						"valores": "Values",
						"nrAnos": "Number of years",
						"ano": "Year",
						"semdados": "No data",
						"addlinha": "Add line"
					},
					"tableTot": {
						"rendAnoAnt": "Income from Previous Years",
						"rendAno": "Income for the Year",
						"retIrsIrc": "IRS/IRC withholdings",
						"contribObr": "Mandatory Contributions",
						"quotSindicais": "Union dues",
						"retSobreTaxa": "Surcharge Withholding (years 2013 to 2017)",
						"somacontr": "Control Sum"
					}
				}
			},
			"tipodec": {
				"title": "Type of Declaration",
				"campo1": "1st Declaration of the Year",
				"campo2": "Replacement Declaration",
				"campo3": "Declaration presented in accordance with paragraph d), no. 1, art.",
				"campo4": "Date of the event that determined the obligation to declare or change income already declared"
			},
			"identSujPass": {
				"title": "Identification of the Taxable Subject or Legal Representation and the CC",
				"campo1": "NIF of the Certified Accountant",
				"campo2": "Number of the Taxable Subject or Legal Representative"
			}
		},
		"modal": {
			"config": {
				"title": "Setting up Radical Accounts Yield Accounting Template 10",
				"radicais": "Account radicals"
			},
			"retencaomod10": {
				"title": "Income maintenance",
				"nif": "Taxable subject (NIF)",
				"rendAnoAnt": "Income from previous years",
				"rendimentosAnterioresValores": "Values",
				"rendimentosAnterioresNAnos": "Number of years",
				"rendimentosAnterioresAno": "Year",
				"rendimentosDoAno": "Income for the year",
				"tipoRendimentos": "Type of income",
				"localObtencao": "Place of obtaining income",
				"retencoesIRSIRC": "IRS/IRC withholdings",
				"contribuicoes": "Mandatory contributions",
				"quotizacoes": "Union dues",
				"retencaoSobretaxa": "Surcharge retention (years 2013 to 2017)"
			},
			"configws": {
				"title": "WebService Settings",
				"username": "Contributor username",
				"password": "Taxpayer password",
				"servicoInvCC": "Service invoked by Certified Accountant (CC)",
				"ccPowers": "CC with full declarative powers for Contrib.",
				"usernameCC": "CC username",
				"passwordCC": "CC password",
				"configured": "<i class='fa fa-check' aria-hidden='true'></i> The service is already configured.",
				"notConfigured": "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> The service is not configured.",
				"configsaved": "Configuration saved successfully."
			}
		},
		"btn": {
			"config": "settings",
			"limpo": "Clean",
			"valorescarre": "Load saved values",
			"valoresauto": "Load automatic values",
			"criarficheiro": "Create File",
			"retencoes": "View Holds",
			"btnSubmeterAt": "Submit AT",
			"btnValidarAt": "Validate on AT",
			"btnConfigWS": "WS Settings"
		},
		"message": {
			"registosdel": "Records deleted successfully.",
			"dadoscarregados": "Data loaded successfully!",
			"dadoscarregadosClean": "Successfully cleared fields!",
			"dadosguardados": "Data saved successfully!",
			"temDeGuardar": "You must save the data to be able to create the file",
			"q6c4NotNull": "You must fill in field 4 of table 6",
			"servicoAT": {
				"validarAtSuccess": "Declaration validated successfully",
				"validarAtFailed": "Error validating the declaration in AT",
				"submeterAtSuccess": "Successfully submitted statement",
				"submeterAtFailed": "Error when submitting the declaration on AT"
			}
		}
	},
	"modelo22": {
		"tabs": {
			"anexos": "Attachments",
			"rosto": "Face",
			"anexoA": "Annex A",
			"anexoB": "Annex B",
			"anexoC": "Annex C",
			"anexoD": "Annex D",
			"anexoE": "Attachment is",
			"anexoF": "Annex F",
			"anexoG": "Annex G",
			"anexoAIMI": "AIMI Annex",
			"adicionarAnexo": "Add Attachments"
		},
		"btn": {
			"gravar": "To record",
			"validar": "Validate",
			"criarficheiro": "File",
			"entregar": "Deliver",
			"anular": "Cancel all",
			"calcular": "Calculate",
			"repor": "Reset all",
			"contasdefall": "Accounts defined for the fields",
			"contasdefcopy": "Copy accounts defined for fields to another company",
			"limpaValores": "Clear values",
			"atalhos": "Shortcuts",
			"balancetes": "Balance Sheets",
			"extratos": "Account statement",
			"calcDerrama": "Calculation of spill",
			"tributAutonomas": "Autonomous Taxes",
			"prejFiscais": "Tax losses",
			"retencoes": "Withholdings",
			"documentosFiscais": "Fiscal documents"
		},
		"comum": {
			"de": "In",
			"a": "The",
			"ate": "until",
			"periodo": "Period",
			"semdados": "No data",
			"addline": "Add line",
			"sim": "Yes",
			"nao": "No",
			"tooltipContasDef": "Definition of accounts for the field"
		},
		"rosto": {
			"quadro00": {
				"title": "Attention",
				"subtitle": "Face",
				"p1": "These instructions MUST BE STRICTLY OBSERVED, in order to eliminate filling deficiencies that often lead to <b>central errors and incorrect settlements</b>.",
				"p2": {
					"p2_desc": "Model 22 declaration must be presented by the following taxpayers:",
					"p2_1": "resident entities, whether or not they primarily carry out commercial, industrial or agricultural activities;",
					"p2_2": "non-resident entities with a permanent establishment in Portuguese territory;",
					"p2_3": "entities that do not have their headquarters or effective management in Portuguese territory and obtain income there that is not attributable to a permanent establishment located there, provided that, in relation to them, there is no permanent withholding tax."
				},
				"p3": {
					"p3_desc": "Pursuant to paragraphs 6 and 8 of article 117 of the IRC Code (CIRC), the following are <b>only</b> exempt from submitting model declaration 22:",
					"p3_1": "Entities exempt under article 9 of the Code, except when they are subject to any autonomous taxation or when they obtain income from capital that has not been subject to definitive withholding tax;",
					"p3_2": "Entities that only earn income not subject to IRC, except when they are subject to any autonomous taxation;",
					"p3_3": "Non-resident entities without a permanent establishment in Portuguese territory that only earn, in this territory, income that is exempt or subject to definitive withholding tax."
				},
				"p4": "The declaration is sent, <b>annually</b>, by electronic data transmission, until the last day of May, regardless of whether that day is useful or not, and for taxpayers with a special tax period, until the last day of the 5th month following the end date of that period, regardless of whether that day is working or not, in accordance with paragraphs 1 and 2 of article 120 of the CIRC.",
				"p5": "In relation to entities that are not resident in Portuguese territory and that obtain income here that is not attributable to a permanent establishment located there, the obligation to submit the model 22 declaration only occurs in cases where there is no definitive withholding tax, and it must then be observed that the deadlines set out in paragraph 5 of article 120 of the CIRC.",
				"p6": "Taxable persons with a tax period different from the calendar year and dominant companies covered by the special taxation regime for groups of companies when sending the group declaration, non-resident taxpayers without a permanent establishment and taxpayers who submit special declarations before or after the dissolution, they must indicate the type of declaration they will submit in the pre-filling table prior to submitting the declaration.",
				"p7": {
					"p7_desc": "For the declaration to be correctly received (centrally correct) you must:",
					"p7_1": "Fill out the declaration directly on the Portal or open the previously formatted file;",
					"p7_2": "Validate information and correct detected errors (local validations);",
					"p7_3": "Submit the declaration;",
					"p7_4": "Check the final status of the declaration from the following day onwards."
				},
				"p8": "Regarding the procedures to be adopted to correct central errors, help is available on the Finance Portal at: <b>taxpayer support → useful information → manuals → manual for correcting central errors</b>.",
				"p9": "The declaration is considered presented on the date on which it is submitted, under the condition that any errors are corrected within a period of 30 days, after which, without these being corrected, the declaration is considered as <b>not presented< /b>, according to no. 5 of Ordinance no. 1339/2005, of December 30th.",
				"p10": "Before checking coherence with the central databases, the declaration is in a provisional reception situation, in accordance with the sending rules set out in paragraph 4 of the aforementioned decree.",
				"p11": "If the declaration contains central errors, it must be corrected through the electronic declaration submission system, <b>not</b> sending a new declaration to correct the errors.",
				"p12": "<b>proof of delivery</b> can be obtained directly from the Finance Portal, by printing the declaration in the option Services → IRC Model 22 → obtain proof.",
				"p13": "Taxable persons must keep their address and other elements of their registration up to date, namely the NIB used for reimbursement purposes, and must make the necessary changes, if necessary, by presenting the respective declaration of changes or in the manner provided for in article 119. of the CIRC."
			},
			"quadro01": {
				"title": "Tax period"
			},
			"quadro02": {
				"title": "Headquarters area, effective management or permanent establishment",
				"campo01": "Headquarters or Permanent Establishment Finance Service Code",
				"campo02": "Effective Management Finance Service Code"
			},
			"quadro03": {
				"title": "Identification and characterization of the taxpayer",
				"sujPass": "Designation",
				"campo02": "Tax Identification Number (NIF)",
				"campo03": {
					"title": "Type of taxpayer",
					"radio1": "Resident who primarily carries out commercial, industrial or agricultural activity",
					"radio2": "Resident who does not carry out, as a main activity, commercial, industrial or agricultural activity",
					"radio3": "Non-resident with a permanent establishment",
					"radio4": "Non-resident without permanent establishment"
				},
				"campo3A": {
					"title": "Qualification of the company under the terms of the Annex to Decree-Law No. 372/2007, of 6 November or as a cooperative",
					"desc": "If you checked boxes 1 or 3 in Table 03 - 3, indicate how you qualify under the terms set out in the Annex to Decree-Law no. 372/2007, of November 6",
					"radio1": "Medium company",
					"radio2": "Non-SME",
					"radio3": "Micro enterprise",
					"radio4": "Small business",
					"check5": "Cooperative",
					"check6": "Indicate whether it qualifies as a small-mid cap company [(Small Mid Cap (SMC)]"
				},
				"campo3B": {
					"title": "Collective investment bodies",
					"field1": "Indicate whether it is a Collective Investment Organization taxed under article 22 of the EBF"
				},
				"campo3C": {
					"title": "Income imputation (Article 5, paragraph 9)",
					"desc": "Is it considered a permanent establishment for the purposes of the imputation provided for in paragraph 9 of article 5?",
					"field1": "Yes"
				},
				"campo4": {
					"title": "Income taxation regimes",
					"field1": "General",
					"field3": "Definitive exemption",
					"field4": "Temporary exemption",
					"field5": "Rate reduction",
					"field6": "Simplified",
					"field7": "Tax transparency",
					"field8": "Groups of companies",
					"field9": "NIF of the controlling company / Responsible (article 69.º-A, paragraphs 3 and 4)",
					"field10": "Do you intend to exercise the option for the rates in article 87, no. 1?",
					"field11": "Did any of the situations referred to in ex-article 87, paragraph 7, occur?",
					"field12": "Article 36-A of the EBF",
					"field13": "Special regime for maritime transport activities (Decree-law no. 92/2019, of 13 November)"
				},
				"campo4A": {
					"title": "Transfer of residence/cessation of permanent establishment activity/allocation of assets (articles 83, 84 and 54-A, no. 11)",
					"desc": "If during the tax period there was a transfer of residence, allocation of assets and a permanent establishment located outside Portuguese territory, cessation of activity or transfer of assets of a permanent establishment located in Portuguese territory, indicate the place of destination",
					"field1": "EU/EEA countries",
					"field2": "Others"
				}
			},
			"quadro04": {
				"title": "Features of the declaration",
				"campo1": {
					"title": "Declaration type",
					"radio1": "1st Declaration of the period",
					"radio2": "Substitution declaration (article 122, paragraphs 1 and 2)",
					"radio3": "Substitution declaration (art. 64, no. 4)",
					"radio4": "Substitution declaration (article 120, paragraphs 8 and 9)",
					"radio5": "Substitution declaration (article 64, no. 4) after the legal deadline",
					"radio6": "Substitution declaration (art. 122, no. 3)",
					"data": "Decision/sentence notification date"
				},
				"campo2": {
					"title": "Special declarations",
					"check1": "Group statement",
					"check2": "Settlement period statement",
					"check3": "Declaration of cessation period",
					"check4": "Before the change",
					"check5": "After the change",
					"check7": "Declaration of the period of beginning of activity",
					"check9": "Before the dissolution",
					"check10": "After dissolution",
					"data6": "Date of cessation",
					"data8": "Transmission/acquisition date (non-resident entities without a permanent establishment)",
					"data11": "Date of dissolution",
					"subtitle1": "Declaration with special tax period"
				}
			},
			"quadro05": {
				"title": "Identification of the legal representative and certified accountant",
				"campo1": "Legal representative's TIN",
				"campo2": "Certified accountant's TIN",
				"desc": "If there was a fair impediment (article 12.º-A of DL no. 452/99, of November 5th), indicate:",
				"campo4": "Fact that determined the fair impediment",
				"campo5": "Date of the beginning of the occurrence of the fact",
				"campo6": "Date of cessation of the event"
			},
			"quadro07": {
				"title": "(Period 2010 and later) Calculation of taxable profit",
				"acrescer": "TO BE ADDED",
				"deduzir": "TO DEDUCE",
				"campo701": "NET INCOME FOR THE PERIOD",
				"campo702": "Positive equity changes not reflected in the net profit for the period (art. 21) and share of the subsidy relating to non-current, non-depreciable/non-amortizable assets [art. 22 no. 1, al.",
				"campo703": "Positive asset variations (transitional regime provided for in article 5, paragraphs 1, 5 and 6 of DL no. 159/2009, of 13/7)",
				"campo704": "Negative equity changes not reflected in the net profit for the period (art. 24)",
				"campo705": "Negative equity variations (transitional regime provided for in article 5, paragraphs 1, 5 and 6 of DL no. 159/2009, of 13/7)",
				"campo706": "Change to the tax regime for construction contracts (positive corrections)",
				"campo707": "Change in the tax regime for construction contracts (negative corrections)",
				"campo708": "SUM (fields 701 + 702 + 703 - 704 - 705 + 706 - 707)",
				"campo709": "Taxable income/taxable profit imputed by transparent companies, ACE or AEIE (art. 6)",
				"campo710": "Corrections relating to previous tax periods (art. 18, no. 2)",
				"campo711": "Sales and provision of services with deferred payment: difference between the nominal amount of consideration and the fair value (art. 18, no. 5)",
				"campo782": "Expenses relating to inventories and external supplies and services with deferred payment: interest expenses (art. 18, no. 5)",
				"campo712": "Annulment of the effects of the equity equivalence method and the proportional consolidation method in the case of joint ventures that are subject to IRC (article 18, paragraph 8)",
				"campo713": "Non-deductible adjustments arising from the application of fair value (art. 18, no. 9)",
				"campo714": "Share-based payments (art. 18, no. 11)",
				"campo715": "Expenditure on termination benefits, retirement benefits and other post-employment or long-term employee benefits (art. 18, no. 12)",
				"campo717": "Expenses incurred with the costly transfer of shares of capital (ex-article 23, paragraphs 3, 4 and 1st part of paragraph 5)",
				"campo721": "Provisions not deductible or beyond legal limits (articles 19, no. 4 and 39) and fiscally non-deductible impairment losses of financial assets",
				"campo724": "IRC, including autonomous taxation, and other taxes that directly or indirectly affect profits [art. 23.º-A, no. 1, al.",
				"campo725": "Deferred taxes [art. 23.º-A, no. 1, al.",
				"campo716": "Undocumented expenses [art. 23.º-A, no. 1, al.",
				"campo731": "Charges not properly documented [art. 23.º-A, no. 1, al.",
				"campo726": "Charges evidenced in documents issued by taxpayers with non-existent or invalid NIF or by taxpayers terminated ex officio [art. 23.º-A, no. 1, al.",
				"campo783": "Illicit expenses [art. 23.º-A, no. 1, al.",
				"campo728": "Fines, fines and other charges, including compensatory and moratorium interest, for committing infractions [art. 23.º-A, no. 1, al.",
				"campo727": "Taxes, fees and other taxes levied on third parties that the taxpayer is not legally obliged to bear [art. 23.º-A, no. 1, al.",
				"campo729": "Compensation for insurable events [art. 23.º-A, no. 1, al.",
				"campo730": "Subsistence allowances and compensation charges for traveling in the worker's own vehicle [art. 23.º-A, no. 1, al.",
				"campo732": "Charges for renting vehicles without a driver [art. 23.º-A, no. 1, al.",
				"campo733": "Fuel charges [art. 23.º-A, no. 1, al.",
				"campo784": "Charges relating to pleasure boats and passenger aircraft [art. 23.º-A, no. 1, al.",
				"campo734": "Interest and other forms of remuneration on supplies and loans made by partners to the company [art. 23.º-A, no. 1, al.",
				"campo735": "Non-deductible expenses relating to profit sharing by members of corporate bodies [art. 23.º-A, no. 1, al.",
				"campo780": "Contribution to the banking sector [art. 23.º-A, no. 1, al.",
				"campo785": "Extraordinary contribution to the energy sector [art. 23.º-A, no. 1, al.",
				"campo802": "Extraordinary contribution to the pharmaceutical industry [art. 23.º-A, no. 1, al.",
				"campo746": "Amounts paid or owed to non-resident entities subject to a privileged tax regime [art. 23.º-A, no. 1, al.",
				"campo737": "50% of other losses relating to shares of capital or other components of equity (ex-art. 45, no. 3, final part)",
				"campo786": "Other losses relating to equity instruments and expenses incurred with the onerous transfer of equity instruments from non-resident entities subject to a privileged tax regime (article 23-A, paragraphs 2 and 3)",
				"campo718": "Impairment losses in inventories beyond legal limits (article 28) and in non-tax deductible credits or beyond legal limits (articles 28 -A to 28 -C)",
				"campo719": "Losses due to impairment of non-current assets (art. 31-B) and depreciation and amortization (art. 34, no. 1), not accepted as expenses",
				"campo720": "40% of the increase in depreciation of tangible fixed assets as a result of tax revaluation (art. 15, no. 2 of DR 25/2009, of 14/9)",
				"campo722": "Bad debts not accepted as expenses (art. 41)",
				"campo723": "Non-deductible social utility achievements (art. 43)",
				"campo736": "Accounting losses",
				"campo738": "Tax added value resulting from changes in the valuation model [art. 46, no. 5, al.",
				"campo739": "Positive difference between tax gains and tax losses without reinvestment intention (art. 46)",
				"campo740": "50% of the positive difference between tax gains and tax losses with the express intention of reinvestment (art. 48, no. 1)",
				"campo741": "Accruals due to non-reinvestment or non-maintenance of assets held by the acquirer (art. 48, no. 6)",
				"campo742": "Tax gains - transitional regime [art. 7, no. 7, al.",
				"campo743": "Corrections relating to derivative financial instruments (art. 49)",
				"campo787": "Losses from permanent establishments located outside Portuguese territory (art. 54 -A)",
				"campo744": "Corrections relating to transfer pricing (art. 63, no. 8)",
				"campo745": "Positive difference between the definitive tax asset value of the property and the value contained in the contract [art. 64, no. 3, al.",
				"campo747": "Attribution of income from non-resident entities subject to a privileged tax regime (art. 66)",
				"campo748": "Limitation on the deductibility of net financing expenses (art. 67)",
				"campo749": "Corrections in cases of tax credit due to international legal double taxation (art. 68, no. 1)",
				"campo788": "Corrections in cases of tax credit for international economic double taxation (art. 68, no. 3)",
				"campo750": "Corrections resulting from the option for the special regime applicable to mergers, divisions, transfers of assets and exchanges of shares (articles 74, 76 and 77)",
				"campo789": "Transfer of residence, allocation of assets to a permanent establishment located outside Portuguese territory, cessation of activity or transfer of assets from a permanent establishment located in Portuguese territory: positive balance referring to assets transferred to another EU or EEA member state or allocated to a permanent establishment located there (articles 83, 84 and 54 -A, paragraph 11)",
				"campo790": "Transfer of residence, allocation of assets to a permanent establishment located outside Portuguese territory, cessation of activity or transfer of assets from a permanent establishment located in Portuguese territory: positive balance referring to assets transferred to countries outside the EU or EEA or attached a permanent establishment located there (articles 83, 84 and 54 -A, no. 11)",
				"campo751": "Unforeseen donations or donations beyond legal limits (articles 62, 62-A and 62-B of the EBF)",
				"campo803": "Hybrid asymmetries and tax residence asymmetries (articles 68.º-B, no. 1 and 68.º-D, no. 1)",
				"campo779": "Non-deductible financial charges (ex-art. 32, no. 2 of the EBF)",
				"campo797": "Additional to Municipal Property Tax (article 135.º-J of the IMI Code)",
				"campo799": "Expenses and losses related to maritime transport activities to which the special regime for determining the tax base applies (article 6 of the Annex to Decree-Law no. 92/2018, of 13 November)",
				"campo804": "Temporary solidarity contributions in the energy and food distribution sectors (art. 16 of Law no. 24-B/2022, of 30 December)",
				"campo752": "Other additions",
				"campo753": "SUM (fields 708 to 752)",
				"campo754": "Expenses or charges for multi-year economic projection recorded as expenses during the POC and not yet accepted for tax purposes [art. 22, al.",
				"campo755": "Tax loss imputed by ACE or AEIE (art. 6)",
				"campo756": "Corrections relating to previous tax periods (art. 18, no. 2)",
				"campo757": "Sales and provision of services with deferred payment: interest income (art. 18, no. 5)",
				"campo791": "Expenses relating to inventories and external supplies and services with deferred payment: difference between the nominal amount of the consideration and the fair value (art. 18, no. 5)",
				"campo758": "Annulment of the effects of the equity equivalence method and the proportional consolidation method in the case of joint ventures that are subject to IRC (article 18, paragraph 8)",
				"campo759": "Non-taxable adjustments arising from the application of fair value (art. 18, no. 9)",
				"campo760": "Share-based payments (art. 18(11))",
				"campo761": "Payment or making available to beneficiaries of employment termination benefits, retirement benefits and other post-employment or long-term employee benefits (art. 18, no. 12)",
				"campo762": "Reversal of taxed impairment losses (articles 28, paragraph 3 and 28 -A, paragraph 3)",
				"campo763": "Depreciation and amortization taxed in previous tax periods (art. 20 of DR 25/2009, of 14/9)",
				"campo781": "Impairment losses taxed in previous tax periods (articles 28, 28 -A, no. 1 and 31 -B, no. 7)",
				"campo764": "Reversal of taxed provisions (articles 19, no. 4 and 39, no. 4)",
				"campo765": "Refund of non-deductible taxes and excess tax estimate",
				"campo766": "Deferred taxes [art. 23.º-A, no. 1, al.",
				"campo792": "Tax expenditure relating to intangible assets, investment properties and non-consumable biological assets (art. 45-A)",
				"campo767": "Accounting gains",
				"campo768": "50% of the tax loss resulting from changes in the valuation model [art. 46, no. 5, al.",
				"campo769": "Negative difference between tax capital gains and capital losses (art. 46)",
				"campo770": "Corrections relating to derivative financial instruments (art. 49)",
				"campo793": "Deduction of income from copyright and industrial property rights within the limit of paragraph 8 of article 50-A",
				"campo771": "Elimination of double economic taxation of distributed profits and reserves (art. 51 and 51 -D)",
				"campo794": "Profits from permanent establishments located outside Portuguese territory (art. 54 -A)",
				"campo772": "Correction by the purchaser of the property when adopting the definitive tax asset value to determine the taxable result in the respective transfer [art. 64, no. 3, al.",
				"campo795": "Reporting of net financing expenses from previous tax periods (art. 67)",
				"campo773": "Corrections resulting from the option for the special regime applicable to mergers, divisions, transfers of assets and exchanges of corporate parts (articles 74, 76 and 77)",
				"campo796": "Transfer of residence, allocation of assets to a permanent establishment located outside Portuguese territory, cessation of activity or transfer of assets from a permanent establishment located in Portuguese territory: negative balance referring to assets transferred outside Portuguese territory or assigned to a permanent establishment located there (articles 83, 84 and 54 -A, no. 11)",
				"campo774": "Tax benefits",
				"campo800": "Revenue and income related to maritime transport activities to which the special regime for determining the tax base is applicable (article 6 of the Annex to Decree-Law no. 92/2018, of 13 November)",
				"campo801": "Increase in depreciation or amortization resulting from revaluations carried out in accordance with Decree-Law no. 66/2016, of November 3 (art. 8 of the Decree-Law)",
				"campo798": "Losses due to impairment in credits and post-employment or long-term employee benefits (art. 4 of the annex to Law no. 61/2014, of 26 August)",
				"campo775": "Other deductions",
				"campo776": "SUM (fields 754 to 798 + 775 + 801)",
				"campo777": "LOSS FOR TAX EFFECTS (If 776 > 753)",
				"campo778": "TAXABLE PROFIT (If 753 >= 776) (To be carried to table 09)"
			},
			"quadro07Desativado": {
				"title": "(Period 2009 and previous) Calculation of taxable profit"
			},
			"quadro08": {
				"title": "Fee regimes",
				"sect81": {
					"title": "Rate reduction schemes",
					"taxas": "Taxation rates",
					"check242": "Private educational establishments (ex-art. 56 of the EBF)",
					"check245": "Benefits related to interiority (art. 41.º-B and ex-art. 43.º of the EBF)",
					"check269": "Tax benefits applicable to interior territories (R.A. Madeira) (article 19.º-A of Regional Legislative Decree no. 28.º-A/2021/M, of December 30)",
					"check270": "Tax benefits applicable to interior territories (R. A. Azores) (article 38 of Regional Legislative Decree no. 1/2023/A, of January 5)",
					"check248": "Former Cooperative Tax Statute (article 7, paragraph 3 of Law nº 85/98, of 16/12)",
					"check260": "Entities licensed in the Madeira Free Trade Zone (ex-art. 35 of the EBF)",
					"check265": "Entities licensed in the Madeira Free Trade Zone (articles 36 and 36-A of the EBF)",
					"check3": "Benefits related to interiority (art. 41.º-B and ex-art. 43.º of the EBF)"
				},
				"sect82": {
					"title": "General regime",
					"taxas": "Taxation rates",
					"check246": "Autonomous Region of the Azores (Regional Leg. Decree no. 2/1999/A, of 20/1)",
					"check249": "Autonomous Region of Madeira (Regional Leg. Decree no. 2/2001/M, of 20/2)",
					"check262": "Property income from non-resident entities without a permanent establishment (art. 87, no. 4)",
					"check263": "Real estate gains/asset increases obtained by non-resident entities without a permanent establishment (art. 87, no. 4)",
					"check266": "Capital gains on securities obtained by non-resident entities without a permanent establishment (art. 87, no. 4)",
					"check267": "Income arising from the sale of participation units in FII and shareholdings in SII, earned by non-resident entities without a permanent establishment (art. 22.º-A, no. 1, subparagraph c) of the EBF)",
					"check268": "Capital income not subject to definitive withholding tax",
					"check264": "Other income obtained by non-resident entities without a permanent establishment"
				}
			},
			"quadro09": {
				"title": "Determination of taxable income",
				"transq7": "(Q.07 transport)",
				"reggeral": "General regime",
				"redtaxa": "With rate reduction",
				"isencao": "With exemption",
				"regsimp": "Simplified regime (in force until 2010)",
				"prejFiscal": "1. TAX LOSS",
				"lucrotrib": "2. TAXABLE PROFIT",
				"regespecial": "Special regime for groups of companies",
				"field380": "Algebraic sum of fiscal results",
				"field381": "Distributed profits (ex-art. 70, no. 2)",
				"field395": "Net financing expenses (option provided for in article 67, no. 5)",
				"field500": "REAID adjustment (art. 5, no. 1 al. b) of the Annex to Law no. 61/2014, 26 August)",
				"field376": "Internal results eliminated under the previous RTLC, to be included in taxable profit for the period",
				"field382": "Group fiscal result",
				"table396": {
					"title": "Individual losses deducted, verified in periods prior to the start of application of the regime:",
					"fields": {
						"prej": "Losses",
						"periodo": "Period",
						"nif": "NIF"
					}
				},
				"table398": {
					"title": "Shares of tax losses deducted in the event of acquisition of groups of companies (article 71, paragraphs 4 and 5)",
					"fields": {
						"prej": "Losses",
						"periodo": "Period",
						"nif": "NIF"
					}
				},
				"prejfiscded": "Deductible tax losses",
				"prejfiscaut": "Authorized/transmitted tax losses (art. 75, paragraphs 1 and 3)",
				"prejfiscaut2": "Authorized/transmitted tax losses [art. 15, no. 1, al.",
				"alttitularidade": "Change in ownership of more than 50% of the share capital or majority of voting rights - Non-deductible tax losses (art. 52, no. 8)",
				"deducoes": "3. DEDUCTIONS",
				"subtitledesc": "Breakdown of tax losses deducted, by calculation period and amount",
				"subtitleregesp": "Special regime applicable to acquirers of entities considered companies in difficulty - article 15 of Law no. 27-A/2020, of 24 July",
				"table309": {
					"fields": {
						"periodo": "309.1 - Period",
						"montante": "309.2 - Amount"
					}
				},
				"table320": {
					"fields": {
						"periodo": "320.1 - Period",
						"montante": "320.2 - Amount"
					}
				},
				"table331": {
					"fields": {
						"periodo": "331.1 - Period",
						"montante": "331.2 - Amount"
					}
				},
				"benfisc": "Tax benefits",
				"metcoletavel": "4. COLLECTABLE MATERIAL (2-3)",
				"field336": "ZFM - Taxable amount that exceeds the maximum ceilings (articles 36, no. 3 and 36-A, no. 4 of the EBF)",
				"field399": "SPORTS COLLECTIVES - Deduction of amounts invested up to 50% of the taxable amount (art. 54, no. 2 of the EBF)",
				"existprej": "If there are authorized/transmitted tax losses, indicate:",
				"field397": "Total amount used in the period (397-A + 397-B)",
				"table397a": {
					"title": "Value used in the period [art. 15, no. 1 al.",
					"fields": {
						"valor": "397.A - Value used in the period",
						"nif": "NIF",
						"periodo": "397.C - Period"
					}
				},
				"table397b": {
					"title": "Value used in the period (article 75, paragraphs 1 and 3)",
					"fields": {
						"valor": "397.B - Value used in the period",
						"nif": "NIF",
						"periodo": "397.D - Period"
					}
				},
				"table309B": {
					"fields": {
						"montante": "309.4 - Amount",
						"nif": "309.5 - NIF of the company considered a company in difficulty",
						"periodo": "309.3 - Period"
					}
				},
				"field300": "Taxable income for the special regime (field 11 of table 04 of annex G)",
				"field346": "NON-EXEMPT COLLECTABLE MATERIAL [(311 - 399) + 322 + 336] or 409 or field 42 of Annex E, except field 300"
			},
			"quadro10": {
				"title": "Tax calculation",
				"field347A": "Tax at the normal rate (art. 87, no. 2, 1st €50,000.00 of tax base for SMEs or SMCs) (c. 311 of Q.09 of M22 or C. 42 of Annex E) x 17 %",
				"field347B": "Tax at the normal rate (art. 87, no. 1) (c. 311 of q.09 of m22 or c. 42 of annex E) x 21%",
				"impoutrataxas": "Tax at other rates",
				"field350": "Tax attributable to the Autonomous Region of the Azores",
				"field370": "Tax attributable to the Autonomous Region of Madeira",
				"field351": "COLLECTION (347-A + 347-B + 349 + 350 + 370)",
				"field373": "State spill (art. 87 - A)",
				"field378": "TOTAL COLLECTION (351 +373)",
				"deducoes": "Deductions",
				"field353": "International legal double taxation (DTJI - art. 91)",
				"field375": "Double international economic taxation (art. 91 -A)",
				"field355": "Tax benefits",
				"field470": "Additional to Municipal Property Tax (article 135.º-J of CIMI)",
				"field356": "Special payment on account (art. 93)",
				"field357": "TOTAL DEDUCTIONS (353 + 375 + 355 + 356 + 470) <= 378",
				"field358": "TOTAL CIT PAID (378 - 357) >= 0",
				"field371": "Result of liquidation (art. 92)",
				"field359": "Withholdings at source",
				"field360": "Payments on account (art. 105) and Autonomous payment on account (Law no. 7-A/2016, of March 30, art. 136, no. 2)",
				"field374": "Additional payments on account (art. 105 - A)",
				"field361": "IRC PAYABLE (358 + 371 - 359 - 360 - 374) > 0",
				"field362": "IRC TO RECOVER (358 + 371 - 359 - 360 - 374) < 0",
				"field363": "IRC from previous periods",
				"field372": "Replacement of tax benefits",
				"field364": "Municipal spill",
				"field379": "International legal double taxation (art. 91) - Countries with CDT and when DTJI > 378",
				"field365": "Autonomous taxation",
				"field366": "Compensatory interest",
				"field369": "Late payment interest",
				"field367": "TOTAL TO PAY [361 or (-362) + 363 + 372 + 364 - 379 + 365 + 366 + 369] > 0",
				"field368": "TOTAL TO RECOVER [(-362) + 363 + 372 + 364 - 379 + 365 + 366 + 369] < 0",
				"campo10A": {
					"title": "Compensatory interest",
					"desc": "Breakdown of the value indicated in field 366 of table 10:",
					"field366A": "Compensatory interest declared for delay in filing the declaration",
					"field366B": "Compensatory interest declared for other reasons"
				},
				"campo10b": {
					"title": "Transfer of residence/cessation of permanent establishment activity/allocation of assets (articles 83, 84 and 54-A, no. 11)",
					"data4": "Date of occurrence",
					"desc": "Method of payment of the corresponding tax (art. 83, no. 2)",
					"radio1": "immediate [al.",
					"radio2": "deferred [al.",
					"radio3": "fractionated [al.",
					"valpagdiffracio": "Deferred or fractional payment amount",
					"field377A": "IRC + State surcharge",
					"field377B": "Municipal spill"
				},
				"field377": "Total deferred or split payments (377-A + 377-B)",
				"field430": "TOTAL PAYABLE (367 -377) > 0",
				"field431": "TOTAL TO RECOVER [367 or (- 368) - 377] < 0",
				"modal": {
					"newtaxa": {
						"title": "Change pour rate",
						"field": "Rate pours",
						"tooltip": "Rate pours"
					}
				},
				"consideraPrejuizoFiscal": "Tax loss",
				"tributAutonomas": "Autonomous taxes"
			},
			"quadro11": {
				"title": "Other information",
				"field410": "Total income for the period",
				"field411": "Turnover for the period (to be broken down in table 11-B, if applicable)",
				"field416": "Positive difference between the value considered for the purposes of IMT settlement and the value contained in the contract, in cases where the procedure provided for in article 139 was used.",
				"data418": "Date on which the shares were transferred (art. 51, no. 9 and art. 88, no. 11)",
				"check423": "In the case of a micro entity, indicate whether, as an alternative to the accounting standards for micro entities (NC-ME), you choose to apply the accounting and financial reporting standards for small entities (NCRF-PE) or the accounting and financial reporting standards ( NCRF) [article 9.º-D of DL no. 158/2009, of July 13]",
				"check429": "Did a merger with retroactive effect occur during the tax period (no. 11 of article 8) of which you are the beneficiary company?",
				"check455": "Did a merger occur during 2020 under the special regime provided for in articles 73 et seq. of the IRC Code?",
				"sim": "Yes?"
			},
			"quadro11A": {
				"title": "Deferred tax assets (AID) - Law no. 61/2014, of August 26",
				"AID": "Breakdown of AIDs included in the financial statements referred to in Mod. 22",
				"field460": "AID of impairment losses on credits covered by REAID",
				"field461": "AID of post-employment or long-term benefits of employees covered by REAID",
				"field462": "Other AIDs",
				"field463": "Equity",
				"field464": "Tax Credit",
				"data465": "Date of entry into liquidation",
				"infoadd": "Additional information"
			},
			"quadro11B": {
				"title": "Breakdown of annual turnover for the period by districts (Mainland, Azores and Madeira)",
				"desc": "Does the company have branches, delegations, agencies, offices, facilities or any form of permanent representation without its own legal personality in more than one district?",
				"radio6": "Yes",
				"radio7": "No",
				"desc2": "If you answered yes, indicate which circumscriptions:",
				"radio8": "Continent",
				"radio9": "Wood",
				"radio10": "Azores",
				"field1": "Non-exempt global turnover",
				"field2": "Turnover, non-exempt, attributable to facilities located in the Autonomous Region of Madeira (RAM)",
				"field3": "Turnover, non-exempt, attributable to facilities located in the Autonomous Region of the Azores (RAA)",
				"field4": "Ratio 1 (RAM) = (field 2: field 1)",
				"field5": "Ratio 2 (RAA) = (field 3 : field 1)",
				"field22": "Ratio 3 (CONTINENT) = 1 - (ratio 1 + ratio 2)"
			},
			"quadro12": {
				"title": "Withholdings at source",
				"table": {
					"fields": {
						"nif": "Tax identification number (TIN)",
						"retfonte": "Withholding"
					}
				}
			},
			"quadro13": {
				"title": "Autonomous taxation",
				"desc": "Description",
				"basetrib": "Taxable base",
				"field414": "Representation expenses (art. 88, no. 7)",
				"field415": "Charges incurred or borne with subsistence allowances and compensation for travel in the employee's own vehicle (art. 88, no. 9)",
				"field417": "Profits distributed by entities subject to IRC to taxpayers who benefit from total or partial exemption (article 88, paragraph 11)",
				"field420": "Vehicle charges (old wording of article 88, no. 3) (regime in force until 31/12/2013)",
				"field421": "Vehicle charges (ex-art. 88, no. 4) (regime in force until 12/31/2013)",
				"field422": "Compensation for termination of duties as a manager, administrator or manager [art. 88, no. 13, al.",
				"field424": "Expenses or charges relating to bonuses and other variable remuneration paid to managers, administrators or managers [art. 88, no. 13, al.",
				"field425": "Charges not deductible under the terms of art.",
				"field426": "Charges for light passenger and freight vehicles - If CA < €27,500.00 [art. 88, no. 3, al.",
				"field427": "Charges for light passenger and freight vehicles - If CA >= € 27,500.00 and < € 35,000.00 [art. 88, no. 3, al.",
				"field428": "Charges for light passenger and freight vehicles - If CA >= €35,000.00 [art. 88, no. 3, al.",
				"field432": "Charges for plug-in hybrid passenger cars, whose battery can be charged via connection to the electricity grid and which have a minimum range, in electric mode, of 50 km and official emissions of less than 50 gCO(index 2)/km, and light passenger vehicles powered by vehicular natural gas (CNG) - If CA < €27,500.00 [art. 88, no. 3, al.",
				"field433": "Charges for plug-in hybrid passenger cars, whose battery can be charged via connection to the electricity grid and which have a minimum range, in electric mode, of 50 km and official emissions of less than 50 gCO(index 2)/km, and light passenger vehicles powered by vehicular natural gas (CNG) - If CA >= € 27,500.00 and < € 35,000.00 [art. 88, no. 3, al.",
				"field434": "Charges for plug-in hybrid passenger cars, whose battery can be charged via connection to the electricity grid and which have a minimum range, in electric mode, of 50 km and official emissions of less than 50 gCO(index 2)/km, and light passenger vehicles powered by vehicular natural gas (CNG) - If CA >= €35,000.00 [art. 88, no. 3, al.",
				"field435": "Charges for light passenger vehicles powered by CNG - If CA < € 27,500.00 [art. 88, no. 3, al.",
				"field436": "Charges for light passenger vehicles powered by CNG - If CA >= € 27,500.00 and < € 35,000.00 [art. 88, no. 3, al.",
				"field437": "Charges for light passenger vehicles powered by CNG - If CA >= €35,000.00 [art. 88, no. 3, al.",
				"field438": "Undocumented expenses [art. )",
				"field439": "Amounts paid or owed to non-resident entities subject to a privileged tax regime [art. by article 22, no. 8 of the EBF)",
				"field456": "Charges related to vehicles powered exclusively by electricity with an acquisition cost exceeding €62,500.00 [art. 88, no. 20]",
				"campoIniciouAtividade": "Did you start the activity in the tax period prior to the one to which the declaration relates?",
				"field13e3": "If you started your activity in one of the tax periods of 2018, 2019, 2020 or 2021, indicate the date you started your activity"
			},
			"quadro13A": {
				"title": "Autonomous taxation - Madeira Free Trade Zone (art. 36.º-A, no. 14 of the EBF)",
				"desc": "Description",
				"basetrib": "Taxable base",
				"field440": "Representation expenses (art. 88, no. 7)",
				"field441": "Charges incurred or borne with subsistence allowances and compensation for travel in the employee's own vehicle (art. 88, no. 9)",
				"field442": "Profits distributed by entities subject to IRC to taxpayers who benefit from total or partial exemption (article 88, paragraph 11)",
				"field443": "Compensation for termination of duties as a manager, administrator or manager [art. 88, no. 13, al.",
				"field444": "Expenses or charges relating to bonuses and other variable remuneration paid to managers, administrators or managers [art. 88, no. 13, al.",
				"field445": "Charges not deductible under the terms of art.",
				"field446": "Charges for light passenger and freight vehicles - If CA < €27,500.00 [art. 88, no. 3, al.",
				"field447": "Charges for light passenger and freight vehicles - If CA >= € 27,500.00 and < € 35,000.00 [art. 88, no. 3, al.",
				"field448": "Charges for light passenger and freight vehicles - If CA >= €35,000.00 [art. 88, no. 3, al.",
				"field449": "Charges for plug-in hybrid passenger cars, whose battery can be charged via connection to the electricity grid and which have a minimum range, in electric mode, of 50 km and official emissions of less than 50 gCO(index 2)/km, and light passenger vehicles powered by vehicular natural gas (CNG) - If CA < €27,500.00 [art. 88, no. 3, al.",
				"field450": "Charges for plug-in hybrid passenger cars, whose battery can be charged via connection to the electricity grid and which have a minimum range, in electric mode, of 50 km and official emissions of less than 50 gCO(index 2)/km, and light passenger vehicles powered by vehicular natural gas (CNG) - If CA >= € 27,500.00 and < € 35,000.00 [art. 88, no. 3, al.",
				"field451": "Charges for plug-in hybrid passenger cars, whose battery can be charged via connection to the electricity grid and which have a minimum range, in electric mode, of 50 km and official emissions of less than 50 gCO(index 2)/km, and light passenger vehicles powered by vehicular natural gas (CNG) - If CA >= €35,000.00 [art. 88, no. 3, al.",
				"field452": "Charges for light passenger vehicles powered by CNG - If CA < € 27,500.00 [art. 88, no. 3, al.",
				"field453": "Charges for light passenger vehicles powered by CNG - If CA >= € 27,500.00 and < € 35,000.00 [art. 88, no. 3, al.",
				"field454": "Charges for light passenger vehicles powered by CNG - If CA >= €35,000.00 [art. 88, no. 3, al.",
				"field457": "Charges related to vehicles powered exclusively by electricity with an acquisition cost exceeding €62,500.00 [art. 88, no. 20]"
			},
			"quadro14": {
				"title": "Tax credit for international legal double taxation (CIDTJI)",
				"table": {
					"fields": {
						"codpais": "Country code",
						"tiprend": "Type of income",
						"periodo": "CIDTJI period",
						"salcaducado": "Expired balance",
						"saldnaored": "Balance not deducted",
						"apurapreio": "Calculation in the period",
						"dedperio": "Deduction made in the period",
						"saldotrans": "Balance carried forward",
						"imppagestr": "Tax paid abroad [art. 91, no. 1, al.",
						"facimprendest": "Fraction of tax relating to income obtained abroad [art. 91, no. 1, al.",
						"credpreio": "Period tax credit",
						"paisComCDT": "Country with CDT"
					}
				},
				"totCIDTJIcomCDT": "TOTAL CIDTJI with CDT",
				"totCIDTJIsemCDT": "TOTAL CIDTJI without CDT",
				"totCIDTJIJI": "CIDTJI TOTAL"
			}
		},
		"anexoA": {
			"quadro3": {
				"title": "Relevant information",
				"dadosgerais": "General data",
				"dadosesp": "Specific data - Electricity Production Centers/Mine",
				"field1": "Total taxable profit (field 302 + 313 of Q. 09)",
				"field2": "Taxable profit in the Madeira Free Trade Zone (field 313 of Q. 09) (art. 36-A of the EBF)",
				"field10": "Algebraic sum of the taxable income under the special regime and the taxable profit under the general regime (field 300 + field 302)",
				"field3": "Total wage bill",
				"check4": "Is this the 1st year of application of the scheme?",
				"field5": "Total installation or exploration area (ha2)",
				"field6": "Total installed power (MW)",
				"field7": "Total electricity produced (GWh)",
				"field8": "Total value of production at the mine mouth (in euros)",
				"field9": "Total wage bill + service provision"
			},
			"quadro4": {
				"title": "Calculation of the municipal surcharge",
				"massasaltot": "Total Wage Mass",
				"somacontr": "Control Sum",
				"massasalprestmun": "Salary bill + provision of municipal services",
				"areintexplomun": "Installation area.",
				"poteinstmun": "Installed power in the municipality",
				"toteletrprodmun": "Total electric.",
				"prodbocaminamun": "Production at the mouth of the mine in the municipality",
				"sect04-A": {
					"title": "General Criteria",
					"table": {
						"fields": {
							"field1": "District/municipality code (1)",
							"field2": "Spill rate (2)",
							"field3": "Municipal wage bill (3)",
							"field4": "Distribution ratio (4) = (3) / (Q.03, C3)",
							"field5": "CALCULATED SPILL (5) = (Q.03, C1 x (2) x (4)) or (Q.03, C10) x (2) x (4)"
						}
					}
				},
				"sect04-B": {
					"title": "General criteria - Madeira Free Trade Zone (Art. 36.º -A, no. 12 of the EBF)",
					"table": {
						"fields": {
							"field1": "District/municipality code (1)",
							"field2": "Spill rate (2)",
							"field3": "Municipal wage bill at ZFM (3)",
							"field4": "Distribution ratio (4) = (3) / (Q.03, C3)",
							"field5": "Calculated spill (5) = [(Q.03, C2 x (2) x (4))] x 0.2"
						}
					}
				},
				"sect04-C": {
					"title": "Specific criterion - electrical production centers",
					"table": {
						"fields": {
							"field10": "Indicate whether this is the 1st year of applying the criterion (10)",
							"field1": "District/municipality code (1)",
							"field2": "Spill rate (2)",
							"field3": "Salary bill + provision of municipal services (MSPSMunic) (3)",
							"field4": "Installation area.",
							"field5": "Installed power in the municipality (PIMunic) (5)",
							"field6": "Total electricity.",
							"field7": "Municipality ratio (7)",
							"field8": "Calculated spill (8) = (Q03 C1) x (2) x (7)"
						}
					}
				},
				"sect04-D": {
					"title": "Specific criteria - Minas",
					"table": {
						"fields": {
							"field9": "Indicate whether this is the 1st year of applying criterion (9)",
							"field1": "District/municipality code (1)",
							"field2": "Spill rate (2)",
							"field3": "Salary bill + provision of municipal services (MSPSMunic) (3)",
							"field4": "Installation area.",
							"field5": "Production at the mine mouth in the municipality (PBMunic) (5)",
							"field6": "Municipality ratio (6)",
							"field7": "Calculated spill (7) = (Q03 C1) x (2) x (6)"
						}
					}
				}
			}
		},
		"anexoC": {
			"quadro04": {
				"title": "General regime and simplified regime with application of regional rates",
				"varglob": "Global Values",
				"field6": "COLLECTABLE MATERIAL (fields 311 + 336, except field 300, in table 09 of the declaration or field 42 of Annex E)",
				"field6A": "COLLECTABLE AMOUNT SPECIAL REGIME (Decree-law no. 92/2018, of 13/11) - (field 300 of table 09 of the declaration)",
				"field7A": "COLLECTION: If SME or SMC - up to €50,000.00 (amount up to €50,000.00 from field 6 x 17%)",
				"field7B": "COLLECTION: If SME or SMC - greater than €50,000.00 [(field 6 - €50,000.00) x 21%] or if Large company (field 6 x 21%)",
				"field7C": "COLLECTION of the SPECIAL REGIME (Decree-law no. 92/2018, of 13/11) - (field 6-A x 21%)",
				"ram": "RAM",
				"field8A": "RAM COLLECTION: If SME or SMC - up to €50,000.00 (field 4 of table 11-B of the declaration x amount up to €50,000.00 x 11.9%) - to be transported to field 370 of the declaration",
				"field8B": "RAM COLLECTION: If SME or SMC - greater than €50,000.00 [field 4 of table 11-B of declaration x (field 6 - €50,000.00) x 14.7%] or if Large company [field 4 of table 11-B of the declaration x field 6 x 14.7%] - to be transferred to field 370 of the declaration",
				"field8C": "COLLECTION of the SPECIAL REGIME (Decree-law no. 92/2018, of 13/11) in RAM - (field 4 of table 11-B of the declaration x field 6-A x 14.7%) - to be transported to the field 370 of the declaration",
				"raa": "RAA",
				"field9A": "RAA COLLECTION: If SME or SMC - up to €50,000.00 [field 5 of table 11-B of declaration x (amount up to €50,000.00 of field 6) x 11.9%] - to be transported to field 350 of declaration",
				"field9B": "RAA COLLECTION: If SME or SMC - greater than €50,000.00 [field 5 of table 11-B of declaration x (field 6 - €50,000.00) x 14.7%] or if Large Company (field 5 of table 11-B of the declaration x field 6 x 14.7%) - to be transferred to field 350 of the declaration",
				"field9C": "COLLECTION of the SPECIAL REGIME (Decree-law no. 92/2018, of 13/11) in the RAA - (field 5 of table 11-B of the declaration x field 6-A x 14.7%) - to be transported to the field 350 of the declaration",
				"continente": "Continent",
				"field10A": "MAINLAND COLLECTION: If SME or SMC - up to €50,000.00 (field 22 of table 11-B of the declaration x field 7-A) - to be transported to field 347-A of the declaration",
				"field10B": "MAINLAND COLLECTION: If SME or SMC - greater than €50,000.00 (field 22 of table 11-B of the declaration x field 7-B) or if Large company (field 22 of table 11-B of the declaration x field 7-B ) - to be transferred to field 347-B of the declaration",
				"field10C": "COLLECTION of the SPECIAL REGIME (Decree-law no. 92/2018, of 13/11) on the CONTINENT (field 22 of table 11-B of the declaration x field 7-C) - to be transported to field 347-B of the declaration"
			},
			"quadro05": {
				"title": "Old simplified regime (ex-article 58 of the CIRC), rate reduction regimes, entities that do not carry out commercial, industrial or agricultural activities as their main activity",
				"field11": "Taxable material (fields 311-399 or field 322 or field 409 of table 09 of the declaration)",
				"coleta": "Collection: (field 11 x fee)",
				"field13": "RAM COLLECTION: (field 4 of table 11-B of the declaration x field 12) - to be transported to field 370 of the declaration",
				"field14": "RAA COLLECTION: (field 5 of table 11-B of the declaration x field 12 x 0.7) - to be transported to field 350 of the declaration",
				"field15": "COLLECTION from the CONTINENT: (field 22 of table 11-B of the declaration x field 12) - to be transported to field 349 of the declaration"
			},
			"quadro06": {
				"title": "General regime without application of regional fees",
				"varglob": "Global Values",
				"field16": "Taxable material (field 311 of table 09 of the declaration)",
				"field17A": "COLLECTION: If SME or SMC - up to €50,000.00 (amount up to €50,000.00 from field 16 x 17%)",
				"field17B": "COLLECTION: If SME or SMC - greater than €50,000.00 [(field 16 - €50,000.00) x 21%] or if Large company (field 16 x 21%)",
				"ram": "RAM",
				"field18A": "RAM COLLECTION: If SME or SMC - up to €50,000.00 (field 4 of table 11-B of the declaration x field 17-A) - to be transferred to field 370 of the declaration",
				"field18B": "RAM COLLECTION: If SME or SMC - greater than €50,000.00 (field 4 of table 11-B of the declaration x field 17-B) or if Large company (field 4 of table 11-B of the declaration x field 17-B ) - to be transferred to field 370 of the declaration",
				"raa": "RAA",
				"field19A": "RAA COLLECTION: If SME or SMC - up to €50,000.00 (field 5 of table 11-B of the declaration x field 17-A) to be transferred to field 350 of the declaration",
				"field19B": "RAA COLLECTION: If SME or SMC - greater than €50,000.00 (field 5 of table 11-B of the declaration x field 17-B) or if Large company (field 5 of table 11-B of the declaration x field 17-B ) - to be transferred to field 350 of the declaration",
				"continente": "Continent",
				"field20A": "MAINLAND COLLECTION: If SME or SMC - up to €50,000.00 (field 22 of table 11-B of the declaration x field 17-A) - to be transported to field 347-A of the declaration",
				"field20B": "MAINLAND COLLECTION: If SME or SMC - greater than €50,000.00 (field 22 of table 11-B of the declaration x field 17-B) or if Large company (field 22 of table 11-B of the declaration x field 17-B ) - to be transferred to field 347-B of the declaration"
			},
			"quadro06A": {
				"title": "Regime for groups of companies with application of regional rates",
				"varglob": "Global Values",
				"field22": "Taxable material (Field 311 of Table 09 of the declaration)",
				"field22A": "COLLECTION: If SME or SMC - up to €50,000.00 (amount up to €50,000.00 from field 22 x 17%)",
				"field22B": "COLLECTION: If SME or SMC - greater than €50,000.00 [(field 22 - €50,000.00) x 21%] or if Large company (field 22 x 21%)",
				"ram": "RAM",
				"field23A": "RAM COLLECTION: If SME or SMC - up to €50,000.00 (field 4 of table 11-B of the declaration x amount up to 50,000.00 of field 22 x 11.9%) - to be transported to field 370 of the declaration",
				"field23B": "RAM COLLECTION: If SME or SMC - greater than €50,000.00 (field 4 of table 11-B of declaration x (field 22 - 50,000.00) x 14.7%) or if Large company (field 4 of table 11 -B of the declaration x field 22 x 14.7%) - to be transferred to field 370 of the declaration",
				"raa": "RAA",
				"field24A": "RAA COLLECTION: If SME or SMC - up to €50,000.00 (field 5 of table 11-B of the declaration x amount up to 50,000.00 of field 22 x 11.9%) to be transported to field 350 of the declaration",
				"field24B": "RAA COLLECTION: If SME or SMC - greater than €50,000.00 (field 5 of table 11-B of declaration x (field 22 - 50,000.00) x 14.7%) or if Large company (field 5 of table 11 -B of the declaration x field 22 x 14.7%) - to be transferred to field 350 of the declaration"
			},
			"quadro07": {
				"title": "Tax Reduction Benefit Applicable to Interior Territories within the Scope of R. A. Madeira",
				"varglob": "Global Values",
				"ram": "RAM",
				"raa": "RAA",
				"continente": "Continent",
				"field1": "COLLECTABLE MATERIAL (Field 42 of Annex E or Field 322 of table 09)",
				"field2A": "COLLECTION: SME or SMC - up to €50,000.00 (amount up to €50,000.00 from field 1 x 17%)",
				"field2B": "COLLECTION: SME or SMC - more than €50,000.00 [(field 1 - €50,000.00) x 21%]",
				"field3A": "RAM COLLECTION: SME or SMC - up to €50,000.00 (field 4 of table 11-B of the declaration x amount up to €50,000.00 x 8.75%) - to be transferred to field 370 of the declaration",
				"field3B": "RAM COLLECTION: SME or SMC - greater than €50,000.00 [field 4 of table 11-B of the declaration x (field 1 - €50,000.00) x 14.7%] - to be transferred to field 370 of the declaration",
				"field4A": "RAA COLLECTION: SME - up to €50,000.00 [field 5 of table 11-B of the declaration x (amount up to €50,000.00 of field 1) x 8.75%] - to be transferred to field 350 of the declaration",
				"field4B": "RAA COLLECTION: SME - greater than €50,000.00 [field 5 of table 11-B of the declaration x (field 1 - €50,000.00) x 14.7%] - to be transferred to field 350 of the declaration",
				"field5A": "MAINLAND COLLECTION: SME or SMC - up to €50,000.00 (field 22 of table 11-B of the declaration x field 2-A) - to be transported to field 349 of the declaration",
				"field5B": "MAINLAND COLLECTION: SME or SMC - greater than €50,000.00 (field 22 of table 11-B of the declaration x field 2-B) - to be transported to field 347-B of the declaration"
			}
		},
		"anexoD": {
			"quadro03": {
				"title": "Exempt Income",
				"desc31": "Definitive exemption",
				"rendliqu": "Net Income",
				"field301": "Legal entities of public utility and social solidarity (article 10 of the CIRC)",
				"field302": "Cultural, recreational and sporting activities (art. 11 of the CIRC and art. 54, no. 1 of the EBF)",
				"field303": "Cooperatives (art. 66-A of the EBF)",
				"field313": "Contractors or bidders, in relation to profits derived from works and works on common NATO infrastructures (art. 14, no. 2 of the CIRC)",
				"field314": "Pension and similar funds (art. 16, no. 1 of the EBF) and other definitively exempt funds",
				"field316": "Central storage entity: net results for the period recorded in the management of strategic oil reserves (art. 25.º-A of Decree-Law no. 165/2013, of 16 December)",
				"field304": "Other definitive exemptions",
				"desc31A": "Field 314 - Pension and similar funds (art. 16, no. 1 of the EBF) and other definitively exempt funds",
				"desc31B": "Field 304 - Other definitive exemptions",
				"desc32": "Temporary exemption",
				"field305": "Free Zone of Madeira and Santa Maria Island (art. 33, no. 1 of the EBF)",
				"field306": "Entities managing designations of origin and geographical indications (art. 52 of the EBF)",
				"field307": "Entities managing integrated systems for managing specific waste flows (art. 53 of the EBF)",
				"field308": "Public associations, confederations, trade unions and employers' associations and parents' associations (art. 55 of the EBF)",
				"field309": "International scientific societies or associations (ex-art. 57 of the EBF)",
				"field310": "Wastelands and local communities (art. 59 of the EBF)",
				"field311": "Measures to support road transport of passengers and goods [exempt capital gains (art. 70 of the EBF)]",
				"field315": "Share savings funds (art. 26 of the EBF) and other temporarily exempt funds",
				"field317": "Income obtained by forest management entities (EGF) and forest management units (UGF) (art. 59.º-G of the EBF)",
				"field312": "Other temporary exemptions",
				"desc32A": "Field 315 - Share savings funds (art. 26 of the EBF) and other temporarily exempt funds",
				"desc32B": "Field 312 - Other temporary exemptions",
				"table": {
					"fields": {
						"codben": "Benefit code",
						"montante": "Amount"
					}
				}
			},
			"quadro04": {
				"title": "Income deductions (to be deducted in field 774 in table 07 of the declaration)",
				"normalegal": "Legal regulations",
				"dedefetuada": "Deduction made",
				"field401": "Increase to job creation (art. 19 of the EBF)",
				"field402": "Investment funds [art. 22, no. 14, al.",
				"field403": "Elimination of double economic taxation of profits distributed by companies resident in the PALOP and Timor-Leste (ex-art. 42 of the EBF)",
				"field404": "Increases applied to tax benefits for interior areas [ex-art. 43, no. 1, al.",
				"field405": "National merchant marine shipping companies (art. 51 of the EBF)",
				"field406": "Increases applied to donations provided for in articles 62, 62-A and 62-B of the EBF",
				"field426": "Increases applied to donations provided for in articles 62 of the EBF - Social, sporting and environmental patronage",
				"field427": "Increases applied to donations provided for in article 62-A of the EBF - Scientific patronage",
				"field428": "Increases applied to donations provided for in article 62-B of the EBF - Cultural Patronage",
				"field432": "Increases applied to donations relating to extraordinary cultural patronage for 2021 and 2022 (art. 397.º of Law no.75-B/2020, of 31 December and art. June 27)",
				"field407": "Increase in corporate contributions (article 44 of the CIRC)",
				"field408": "Increase applied to expenses incurred with the acquisition, in Portuguese territory, of fuels for refueling vehicles (art. 70, no. 4 of the EBF)",
				"field409": "Conventional remuneration of share capital (art. 136 of Law no. 55-A/2010, of 31/12 and art. 41.-A of the EBF)",
				"field412": "Increased expenses related to daycare centers, lactations and kindergartens (art. 43, no. 9 of the CIRC)",
				"field413": "Increase in expenses incurred by cooperatives in application of the reserve for education and training (art. 66-A, no. 7 of the EBF)",
				"field414": "Profits made available and interest income obtained by partners or shareholders of companies licensed at ZFM (article 36-A, paragraphs 10 and 11, of the EBF)",
				"field415": "Increase in expenses incurred with the acquisition of electricity, CNG for fueling vehicles (art. 59.º-A of the EBF)",
				"field416": "Increased expenses with car-sharing and bike-sharing systems (art. 59.º-B of the EBF)",
				"field417": "Increased expenses with bicycle fleets (art. 59.º-C of the EBF)",
				"field418": "Increase in expenditure borne by forest owners and producers adhering to the forest intervention zone with financial contributions allocated to the common fund and forest defense charges (art. 59-D, no. 12 of the EBF)",
				"field419": "Increased expenses with organic farm certification (art. 59-E of the EBF)",
				"field420": "Increased expenses and losses within the scope of social impact bond partnerships (art. 19.º-A of the EBF)",
				"field421": "Increased expenses and losses related to conservation and maintenance works on buildings or parts of buildings allocated to stores with history recognized by the municipality (art. 59-I of the EBF)",
				"field422": "Increase in the increase in depreciation and amortization, provided for in article 8, no. 3 of Decree-Law no. 66/2016, of 3 November",
				"field423": "Increase in fiscally accepted depreciation of tangible fixed asset elements corresponding to electrosolar or exclusively electric vessels (art. 59.º-J of the EBF)",
				"field424": "Income and gains that are not tax capital gains referred to in paragraphs 1 and 2 of article 268 of the Insolvency and Business Recovery Code (CIRE), approved by Decree-Law no. 53/2004, March 18th",
				"field425": "Property income resulting from housing rental or sublease contracts included in the Affordable Rental Program (art. 20, no. 1 of Decree-Law no. 68/2019, of May 22)",
				"field429": "Property income obtained within the scope of municipal programs offering affordable housing rentals (art. 71, no. 27 of the EBF)",
				"field430": "Increases in expenses incurred with the acquisition of social passes for the benefit of staff (art. 43, no. 15 of the CIRC)",
				"field431": "Expenses with the acquisition of goods and services directly necessary for the implementation of developments that ensure the prior submission of the SAFT-PT file relating to accounting within the scope of the IES and the unique document code (ATCUD), under the terms set out in paragraph 1 of article 316 of Law no. 12/2022, of 27 June.",
				"field433": "Increase in eligible expenses, incurred in the periods of 2021 and 2022, within the scope of joint participation in external promotion projects (art. 400, no. 1 of Law no. 75.º-B/2020, of 31 from December)",
				"field434": "Increases in expenses related to electricity and natural gas consumption, in the part in which they exceed those of the previous period, deducted from possible support under the terms of Decree-Law no. 30-B/2022, of April 18 (art. 231 24-D/2022, of December 30)",
				"field435": "Increases in expenses related to the extraordinary support regime for charges incurred in agricultural production (art. 232 of Law no. 24-D/2022, of December 30)",
				"field436": "Increases in charges corresponding to the increase determined by a dynamic collective labor regulation instrument relating to workers with an employment contract for an indefinite period (art. 19.º-B of the EBF)",
				"field437": "Tax regime to encourage the capitalization of companies [(ICE) art. 43.º-D of the EBF]",
				"field438": "Increase in charges corresponding to the net creation of jobs (art. 41.º-B, no. 6 of the EBF)",
				"field439": "Differential of the extraordinary support coefficient for renting (art. 3, no. 3 of Law no. 19/2022, of 21 October)",
				"field410": "Other income deductions",
				"field411": "TOTAL DEDUCTIONS (401 + ... + 409 + 412 + ... + 435 + ... + 439 + 410)",
				"desc4A": "Field 410 - Other income deductions",
				"desc4B": "Additional information (art. 268 of CIRE)",
				"radio1-2": "Did one of the events provided for in article 268 of the CIRE occur during the tax period?",
				"casoafirma": "If so, please indicate:",
				"field3": "Tax capital gains exempt under the terms of article 268, no. 1",
				"field4": "Exempt positive equity variations not reflected in the net profit for the period (art. 268, paragraphs 1 and 2)",
				"field5": "Deductible expenses or losses determined by the creditor as a result of the reduction of credits (art. 268, no. 3)",
				"desc41": "Transmission of tax benefits from the merged or split company or the contributing company (article 75-A of the CIRC)",
				"table": {
					"fields": {
						"codben": "Benefit code",
						"montante": "Amount",
						"nifsoc": "NIF soc."
					}
				}
			},
			"quadro05": {
				"title": "Soc. social participation managers (SGPS), soc.",
				"field501": "Untaxed capital gains (ex-art. 32.º, no. 2 and ex-art. 32.º-A, no. 1 of the EBF)",
				"field502": "Non-deductible tax losses (ex-art. 32.º, no. 2 and ex-art. 32.º-A, no. 1 of the EBF)"
			},
			"quadro06": {
				"title": "Licensed Entities in the Madeira Free Zone",
				"ano": "603.1 - Year of investment",
				"montante": "603.2 - Amount invested",
				"field601": "Licensing date",
				"desc603": "Discrimination",
				"desc604": "NACE Rev. 1 code (art. 36, no. 6 EBF)",
				"desc605": "NACE Rev. 2 code (art. 36-A, no. 7 of the EBF)",
				"field602": "Number of jobs created in the first six months of activity and maintained during the period",
				"nrpostocriadmantidos": "Number of jobs created/maintained:",
				"field606": "At the beginning of the tax period",
				"field607": "At the end of the tax period",
				"field603": "Investment made in the acquisition of tangible fixed assets and intangible assets, in the first two years of activity",
				"desc06-1": "Determination of the maximum limit applicable to tax benefits for the period (to be completed in the case of application of the regime in article 36.º-A of the EBF)",
				"benefdif": "Benefit corresponding to the difference:",
				"field608": "IRC rate (article 36-A, no. 1 of the EBF)",
				"field609": "Regional spill (article 36-A, no. 12 of the EBF)",
				"field610": "Municipal surcharge (article 36-A, no. 12 of the EBF)",
				"field611": "Autonomous taxation rates (article 36-A, paragraph 14 of the EBF)",
				"field612": "Deduction of 50% of IRC collection (article 36-A, no. 6 of the EBF)",
				"field613": "Other expected benefits (article 36-A, paragraph 12 of the EBF)",
				"field614": "TOTAL TAX BENEFITS (608 + 609 + 610 + 611 + 612 + 613)",
				"field615": "Gross value added in the period and in the Madeira Free Zone x 20.1% [art. 36.-ºA, no. 3, a) of the EBF]",
				"field616": "Annual labor costs incurred in the Madeira Free Zone x 30.1% [art. 36.º-A, no. 3, b) of the EBF]",
				"field617": "Turnover for the period in the Madeira Free Zone x 15.1% [art. 36.º-A, no. 3, c) of the EBF]",
				"field618": "Excess to be regularized (art. 36.º-A, no. 3 of the EBF) (to be transferred to field 372 of table 10 of the declaration)"
			},
			"quadro07": {
				"title": "Tax deductions (to be deducted in field 355 of table 10 of the declaration)",
				"somacontrolo": "Control Sum",
				"desc71": "Contractual tax benefits for investment (ex-article 41, paragraph 1 of the EBF, articles 15 to 21 of the CFI (repealed) and articles 2 to 21 of the CFI approved by Decree-Law no. 162/2014, of 31/10 and articles 2 to 21 of the CFI in RAM approved by Regional Decree no. 24/2016/M, of 28. /06",
				"table71": {
					"fields": {
						"field700": "NIF of the company.",
						"field1": "University Degree",
						"field2": "Period to which the benefit relates",
						"field3": "Expired balance",
						"field701": "Balance not deducted in the previous period",
						"field702": "Period allocation",
						"field703": "Period deduction",
						"field704": "Balance carried over to the next period",
						"tot1": "Total - Expired balance",
						"tot2": "Total - Balance not deducted in the previous period",
						"tot3": "Total - Period allocation",
						"tot4": "Total - Period deduction",
						"tot5": "Total - Balance carried over to the next period"
					}
				},
				"desc71A": "RETGS - ADDITIONAL INFORMATION (to be completed by the companies that make up the group) - use of the benefit within the group",
				"table71A": {
					"fields": {
						"field1": "University Degree",
						"field2": "Period to which the benefit relates",
						"field3": "Actual expired balance in the group declaration",
						"field4": "Balance not deducted in the period prior to group collection",
						"field5": "Allocation of the period in the group declaration",
						"field6": "Deduction used in the group declaration",
						"field7": "Balance carried over to the next period in the group declaration",
						"tot1": "Total - Actual expired balance in the group declaration",
						"tot2": "Total - Balance not deducted in the period prior to group collection",
						"tot3": "Total - Period allocation in the group declaration",
						"tot4": "Total - Deduction used in the group declaration",
						"tot5": "Total - Balance carried over to the next period in the group declaration"
					}
				},
				"desc72": "Investment projects for internationalization (ex-art. 41, no. 4 of the EBF and art. 22 of the CFI revoked by Law no. 83-C/2013, of 31/12)",
				"field705": "Balance not deducted in the previous period",
				"field706": "Period allocation",
				"field707": "Period deduction",
				"field708": "Balance carried over to the next period",
				"desc73": "SIFIDE - System of tax incentives in research and business development (Law no. 40/2005, of 3/8) and SIFIDE II (art. 133 of Law 55-A/2010, of 31/12, art. 33.º to 40.º of the CFI (repealed) and articles 35.º to 42.º of the CFI approved by Decree-Law no. 162/2014, of 31/10 and articles 35.º to 42nd of the CFI in RAM approved by Regional Decree no. 24/2016/M, of 28/06",
				"table73": {
					"fields": {
						"field743": "NIF of the company.",
						"field1": "University Degree",
						"field2": "Period to which the benefit relates",
						"field3": "Expired balance",
						"field709": "Balance not deducted in the previous period",
						"field710": "Period allocation",
						"field711": "Period deduction",
						"field712": "Balance carried over to the next period",
						"tot1": "Total - Expired balance",
						"tot2": "Total - Balance not deducted in the previous period",
						"tot3": "Total - Period allocation",
						"tot4": "Total - Period deduction",
						"tot5": "Total - Balance carried over to the next period"
					}
				},
				"desc73A": "RETGS - ADDITIONAL INFORMATION (to be completed by the companies that make up the group) - use of the benefit within the group",
				"table73A": {
					"fields": {
						"field1": "University Degree",
						"field2": "Period to which the benefit relates",
						"field3": "Actual expired balance in the group declaration",
						"field4": "Balance not deducted in the period prior to group collection",
						"field5": "Allocation of the period in the group declaration",
						"field6": "Deduction used in the group declaration",
						"field7": "Balance carried over to the next period in the group declaration",
						"tot1": "Total - Actual expired balance in the group declaration",
						"tot2": "Total - Balance not deducted in the period prior to group collection",
						"tot3": "Total - Period allocation",
						"tot4": "Total - Period deduction",
						"tot5": "Total - Balance carried over to the next period"
					}
				},
				"desc74": "Investment support tax regime (Law no. 10/2009, of 10/3 (successively extended), articles 26 to 32 of the CFI (repealed) and articles 22 to 26 of the CFI approved by the Decree-Law no. 162/2014, of 31/10 and articles 22 to 26 of the CFI in RAM approved by Regional Decree no. 24/2016/M, of 28/06.",
				"table74": {
					"fields": {
						"field744": "NIF of the company.",
						"field1": "University Degree",
						"field2": "Period to which the benefit relates",
						"field3": "Expired balance",
						"field713": "Balance not deducted in the previous period",
						"field714": "Period allocation",
						"field715": "Period deduction",
						"field716": "Balance carried over to the next period",
						"tot1": "Total - Expired balance",
						"tot2": "Total - Balance not deducted in the previous period",
						"tot3": "Total - Period allocation",
						"tot4": "Total - Period deduction",
						"tot5": "Total - Balance carried over to the next period"
					}
				},
				"desc74A": "RETGS - ADDITIONAL INFORMATION (to be completed by the companies that make up the group) - use of the benefit within the group",
				"table74A": {
					"fields": {
						"field1": "University Degree",
						"field2": "Period to which the benefit relates",
						"field3": "Actual expired balance in the group declaration",
						"field4": "Balance not deducted in the period prior to group collection",
						"field5": "Allocation of the period in the group declaration",
						"field6": "Deduction used in the group declaration",
						"field7": "Balance carried over to the next period in the group declaration",
						"tot1": "Total - Actual expired balance in the group declaration",
						"tot2": "Total - Balance not deducted in the period prior to group collection",
						"tot3": "Total - Period allocation in the group declaration",
						"tot4": "Total - Deduction used in the group declaration",
						"tot5": "Total - Balance carried over to the next period in the group declaration"
					}
				},
				"desc76": "Extraordinary tax credit for investment - CFEI II (article 16 of Law no. 27-A/2020, of 24 July)",
				"table76": {
					"field1": "NIF of the company.",
					"field2": "Period to which the benefit relates",
					"field722": "Balance not deducted in the previous period",
					"field723": "Period allocation",
					"field724": "Period deduction",
					"field725": "Balance carried over to the next period",
					"tot1": "Total - Balance not deducted in the previous period",
					"tot2": "Total - Period allocation",
					"tot3": "Total - Period deduction",
					"tot4": "Total - Balance carried over to the next period"
				},
				"desc76A": "RETGS - ADDITIONAL INFORMATION (to be completed by all companies that make up the group) - use of the benefit within the group",
				"table76A": {
					"field1": "Period to which the benefit relates",
					"field2": "Balance not deducted in the period prior to group collection",
					"field3": "Allocation of the period in the group declaration",
					"field4": "Deduction used in the group declaration",
					"field5": "Balance carried over to the next period in the group declaration",
					"tot1": "Total - Balance not deducted in the period prior to group collection",
					"tot2": "Total - Period allocation in the group declaration",
					"tot3": "Total - Deduction used in the group declaration",
					"tot4": "Total - Balance carried over to the next period in the group declaration"
				},
				"desc79": "IFPC - Tax incentive for cinematographic and audiovisual production (Article 59.º-F of the EBF and Ordinance no. 89.º-A/2017, of April 19)",
				"table79": {
					"fields": {
						"field790": "Work identification number",
						"field791": "Start date of work",
						"field792": "Work completion date",
						"field793": "Balance not deducted in the previous period",
						"field794": "Incentive value in the period",
						"field795": "Period deduction",
						"field796": "Balance carried over to the next period",
						"field797": "Amount to be refunded",
						"field798": "Value to be restored (to be transported to C.372 of Q.10 of M.22)",
						"tot1": "Total - Balance not deducted in the previous period",
						"tot2": "Total - Incentive value in the period",
						"tot3": "Total - Period deduction",
						"tot4": "Total - Balance carried over to the next period",
						"tot5": "Total - Amount to be refunded",
						"tot6": "Total - Value to be replaced (to be transported to C.372 of Q.10 of M.22)"
					}
				},
				"desc79A": "IFPC - Tax incentive for cinematographic and audiovisual production - Charges incurred with light passenger vehicles, light goods vehicles, motorbikes and motorcycles, excluded from autonomous taxation under the terms of Art. 59.º-H of the EBF",
				"desc710": "Tax Incentive for Recovery - IFR (article 307 of Law no. 12/2022, of June 27)",
				"field710A": "Amount of eligible investment expenses in the period",
				"field710B": "Amount of the simple arithmetic average of eligible investment expenditure in previous tax periods (Article 3(3) of Annex III)",
				"table710": {
					"fields": {
						"field71001": "NIF of the company.",
						"field71002": "Balance not deducted in the previous period",
						"field71003": "Amount of tax benefit",
						"field71004": "Period deduction",
						"field71005": "Balance carried over to the next period",
						"tot1": "Total - Balance not deducted in the previous period",
						"tot2": "Total - Amount of tax benefit",
						"tot3": "Total - Period deduction",
						"tot4": "Total - Balance carried over to the next period"
					}
				},
				"desc710A": "RETGS - ADDITIONAL INFORMATION (to be completed by all companies that make up the group) - use of the benefit within the group",
				"table710A": {
					"fields": {
						"field71001A": "Balance not deducted in the period prior to group collection",
						"field71002A": "Amount of tax benefit",
						"field71003A": "Period deduction",
						"field71004A": "Balance carried over to the next period",
						"tot1": "Total - Balance not deducted in the previous period",
						"tot2": "Total - Amount of tax benefit",
						"tot3": "Total - Period deduction",
						"tot4": "Total - Balance carried over to the next period"
					}
				},
				"tipoviat": "Type of vehicles",
				"montencarg": "Amount of charges",
				"field1": "Charges for light passenger and freight vehicles - If CA < €27,500.00 [art. 88, no. 3, al.",
				"field2": "Charges for light passenger and freight vehicles - If CA >= € 27,500.00 and < € 35,000.00 [art. 88, no. 3, al.",
				"field3": "Charges for light passenger and freight vehicles - If CA >= €35,000.00 [art. 88, no. 3, al.",
				"field4": "Charges for plug-in hybrid passenger cars, whose battery can be charged via connection to the electricity grid and which have a minimum range, in electric mode, of 50 km and official emissions of less than 50 gCO(index 2)/km, and light passenger vehicles powered by vehicular natural gas (CNG) - If CA < €27,500.00 [art. 88, no. 3, al.",
				"field5": "Charges for plug-in hybrid passenger cars, whose battery can be charged via connection to the electricity grid and which have a minimum range, in electric mode, of 50 km and official emissions of less than 50 gCO(index 2)/km, and light passenger vehicles powered by vehicular natural gas (CNG) - If CA >= € 27,500.00 and < € 35,000.00 [art. 88, no. 3, al.",
				"field6": "Charges for plug-in hybrid passenger cars, whose battery can be charged via connection to the electricity grid and which have a minimum range, in electric mode, of 50 km and official emissions of less than 50 gCO(index 2)/km, and light passenger vehicles powered by vehicular natural gas (CNG) - If CA >= €35,000.00 [art. 88, no. 3, al.",
				"field7": "Charges for light passenger vehicles powered by CNG - If CA < € 27,500.00 [art. 88, no. 3, al.",
				"field8": "Charges for light passenger vehicles powered by CNG - If CA >= € 27,500.00 and < € 35,000.00 [art. 88, no. 3, al.",
				"field9": "Charges for light passenger vehicles powered by CNG - If CA >= €35,000.00 [art. 88, no. 3, al.",
				"desc75": "Other tax deductions",
				"normlegal": "Legal regulations",
				"dedefet": "Deduction made",
				"field717": "Tax incentives for reinvested profits in the Autonomous Region of Madeira (Regional Leg. Decree no. 2/2009/M, of 22/1)",
				"field726": "Tax incentives for profits reinvested in the Autonomous Region of the Azores (art. 6 of Regional Leg. Decree no. 2/99/A. of 20/1)",
				"field718": "Entities licensed in the Madeira Free Trade Zone (art. 35, no. 6 and 36, no. 5 and 36-A, no. 6 of the EBF)",
				"field719": "Venture capital companies and venture capital investors (art. 32-A, no. 4 of the EBF)",
				"field727": "Deduction for profits retained and reinvested by SMEs (articles 27 to 34 of the CFI) approved by Decree-Law no. 162/2014, of 31/10 and articles 27 to 34 of the CFI in RAM approved by Dec. Leg.",
				"field728": "50% deduction from collection by entities licensed to operate in the Madeira Industrial Free Zone (art. 36-A, no. 6 of the EBF)",
				"field720": "",
				"field721": "TOTAL DEDUCTIONS [(703 + 707 + 711 + 715 + 724 + 795 + 04(Q.0710) + 717 + 726 + 718 + 719 + 727 + 728 + 720)]",
				"desc77": "Transmission of tax benefits from the merged or cindicated company or the contributing company (Article 75.º-A of the CIRC)",
				"table77": {
					"fields": {
						"field729": "NIF merged, split or contributing company",
						"field1": "University Degree",
						"field2": "Period to which the benefit relates",
						"field730": "Balance of benefit transmitted",
						"field731": "Period allocation",
						"tot1": "Total - Balance of benefit transmitted",
						"tot2": "Total - Period allocation"
					}
				},
				"desc78": "Incentives subject to maximum regional aid rates (CFI approved by Decree-Law no. 162/2014, of October 31) (For tax periods 2015 and 2016)",
				"table78": {
					"fields": {
						"field746": "Benefit code",
						"field735": "Eligible region (article 43 of the CFI)",
						"field736": "CAE code of the activity for which the investment is intended (article 2 of Port. no. 282/2014, of 31/12)",
						"field737": "Amount of relevant applications (articles 11, 22 and 30 of the CFI)",
						"incenfisc": "Tax breaks",
						"field738": "IRC",
						"field739": "IMI, IMT and SELO",
						"field740": "Non-Tax",
						"field741": "Total"
					}
				},
				"radio1-2": "Indicate whether it qualifies as a micro-entity under the terms set out in the Annex to Decree-Law No. 372/2007, of November 6",
				"desc78A": "Incentives subject to maximum regional aid rates (CFI approved by Decree-Law no. 162/2014, of October 31) (For tax periods 2017 and following)",
				"desc78-A1": "Information regarding regional investment projects",
				"table78A1": {
					"fields": {
						"field782": "Line No.",
						"projinvinc": "Investment project/Incentive",
						"field750": "Type",
						"field751": "Project No./Incentive Code",
						"field752": "Investment start date",
						"field753": "Investment end date",
						"field754": "Investment typology",
						"field755": "Official identification of the financial incentive",
						"appreleprev": "Relevant anticipated applications",
						"field756": "Eligible region",
						"field757": "CAE Code",
						"field758": "Total amount",
						"field759": "Updated total amount"
					}
				},
				"desc78-A2": "Financial incentives enjoyed and tax used - Values ​​for the tax period",
				"table78A2": {
					"fields": {
						"field760": "Project No./Incentive Code",
						"apprelreali": "Relevant applications carried out",
						"field761": "Amount",
						"field762": "Updated amount",
						"financeiro": "Financial",
						"field763": "Amount enjoyed",
						"field764": "Updated amount used",
						"irc": "IRC",
						"field765": "Amount used",
						"field766": "Updated amount",
						"imi": "IMI",
						"field767": "Amount used",
						"field768": "Updated amount",
						"imt": "IMT",
						"field769": "Amount used",
						"selo": "STAMP",
						"field770": "Amount used",
						"field771": "Updated total amount of benefits enjoyed/used"
					}
				},
				"desc78-A3": "Financial incentives enjoyed and tax used - Accumulated updated values",
				"table78A3": {
					"fields": {
						"field772": "Project No./Incentive Code",
						"apprelreali": "Relevant applications carried out",
						"field773": "Updated accumulated amount",
						"financeiro": "Financial",
						"field774": "Updated amount used",
						"irc": "IRC",
						"field775": "Updated amount",
						"imi": "IMI",
						"field776": "Updated amount",
						"imt": "IMT",
						"field777": "Amount used",
						"selo": "STAMP",
						"field778": "Amount used",
						"field779": "Updated total amount of benefits enjoyed/used",
						"field780": "Accumulated aid intensity (in %)",
						"field781": "Amount to be entered in field 372 of Q. 10 of M. 22"
					}
				}
			},
			"quadro08": {
				"title": "Donations (articles 62, 62-A and 62-B of the EBF)",
				"table": {
					"fields": {
						"tipdona": "Donation type",
						"nif": "NIF of the donee entity",
						"donativo": "Donation value"
					}
				}
			},
			"quadro09": {
				"title": "Tax incentives subject to the de minimis rule",
				"desc1": "Total Incentives from previous years (fiscal and non-fiscal nature)",
				"field901": "N-2",
				"field902": "N-1",
				"desc2": "Incentives of the year",
				"field903": "Non-fiscal incentive",
				"desc3": "Fiscal incentives",
				"field904A": "Conventional remuneration of share capital (Law no. 55-A/2010, of 31/12 and art. 41.º-A of the EBF) x IRC rate",
				"field904B": "Reduction in the IRC rate applicable to SMEs and small-medium capitalization companies (Small Mid Cap), for the first €50,000.00 of taxable income (art. 87, no. 2 of the CIRC)",
				"field904C": "Rate reduction - Tax benefits applicable to interior territories (ex-art. 43 and art. 41-B of the EBF)",
				"field904D": "Expenses with productive investment project (art. 18, no. 1, al. b) and no. 5 of the CFI, revoked by Decree-Law no. 162/2014, of 31/10) x IRC rate",
				"field904E": "Municipal tax (art. 18, no. 25 of Law no. 73/2013, of September 3)",
				"field904F": "20% increase to the maximum deduction for retained and reinvested profits (DLRR) by SMEs (art. 41.º-B, no. 4 of the EBF)",
				"field904G": "Increase in investment expenses related to the promotion of internationalization (art. 400. 7 and 8 of Law 75.º-B/2020, of December 31)",
				"field904H": "Increase in investment expenses related to the promotion of internationalization in the fishing and aquaculture sectors (art. 400.º no. 9 of Law no. 75.º-B/2020, of December 31st) x tax rate IRC",
				"field904I": "Increase in investment expenses related to the promotion of internationalization in the primary agricultural production sector (art. 400.º no. 10 of Law no. 75.º-B/2020, of 31 December) x IRC rate",
				"field904J": "Rate reduction - Tax benefits applicable to interior territories (article 19.º-A of Regional Legislative Decree no. 28.º-A/2021/M, of December 30 (R. A. Madeira) and article 38.º of the Decree Regional Legislature No. 1/2023/A, of January 5th (R.A.Açores)",
				"field904K": "Increases in expenses related to the extraordinary support regime for charges incurred in agricultural production x IRC rate",
				"field904": "Total incentives for the year of a fiscal nature (904-A + ... + 904-K)",
				"field905": "Total incentives for the three-year period (901 + 902 + 903 + 904)",
				"field906": "IRC to be regularized (to be indicated in field 372 of table 10 of the declaration)",
				"desc4": "Identification of associated companies (single company concept for the purposes of the de minimis limit)",
				"table": {
					"field": "NIF"
				}
			},
			"quadro10": {
				"title": "Inland Tax Incentives linked to investment subject to maximum regional aid rates (ex-art. 43 of the EBF) - to be indicated in field 372 of Q. 10 of the declaration",
				"invelegiveis": "Eligible Investments",
				"field1001": "Tangible",
				"field1002": "Intangible",
				"field1003": "TOTAL",
				"desc1": "Investment aid",
				"field1004": "Reduction in social security charges x (1 - IRC rate)",
				"majoracaodep": "Increase in depreciation",
				"field1005": "Majorization",
				"field1006": "IRC rate",
				"field1007": "Aid value",
				"majoracaoencsegsoc": "Increase in social security costs",
				"field1008": "Majorization",
				"field1009": "IRC rate",
				"field1010": "Aid value",
				"field1011": "Increase in investment tax credit",
				"field1012": "Others",
				"field1013": "TOTAL AID (1004 + 1007 + 1010 + 1011 + 1012)",
				"field1014": "Aid fee",
				"field1015": "Maximum legal rate applicable",
				"field1016": "EXCESS TO REGULARIZE (to be transferred to field 372 of table 10 of the declaration)"
			},
			"quadro11": {
				"title": "Deductions from the Taxable Income (to be deducted in field 399 of table 09 of the declaration)",
				"desc111": "Sports collectives (art. 54, no. 2 of the EBF)",
				"field1111": "Balance not deducted in the previous period",
				"field1112": "Period allocation",
				"field1113": "Period deduction",
				"field1114": "Balance carried over to the following period(s)"
			},
			"quadro11A": {
				"title": "Additional information regarding the regime applicable to entities licensed in the ZFM and state aid for regional purposes",
				"desc": "If you have partner or associated company(ies) as defined in paragraphs 2 and 3 of article 3 of the Annex to Decree-Law no. 372/2007, of 6 November , indicate the respective NIF(s)",
				"table": {
					"field": "NIF"
				}
			},
			"quadro12": {
				"title": "Special Tax Loss Transferability Regime Applicable to Acquirers until December 31, 2020, of Shareholdings in Entities Considered Companies in Difficulty (Article 15 of Law No. 27-A/2020, of July 24)",
				"desc121": "Information to be communicated by the acquiring company",
				"table121": {
					"fields": {
						"field1": "NIF of the company in difficulty",
						"field2": "Date of acquisition of participation",
						"field3": "Average percentage of direct ownership of the acquiring company in the voting capital of the company in difficulty",
						"field4": "Calculation period for current tax losses transmitted",
						"field5": "Current tax losses (balance)",
						"field6": "Amount of current tax losses transmitted (Column 03 x column 05)"
					}
				},
				"desc122": "Information to be communicated by the company considered a company in difficulty",
				"campo122": "I authorize the transfer of tax losses to the acquiring company (no. 3 of article 3 of annex IV of Law no. 27-A/2020, of 24 July)",
				"table122": {
					"fields": {
						"field1": "NIF of the company acquiring the shareholding",
						"field2": "Average percentage of direct participation of the acquiring company in the voting capital of the company in difficulty",
						"field3": "Period for calculating current tax losses",
						"field4": "Current tax losses (balance)",
						"field5": "Amount of current tax losses transmitted (item b of no. 1 of article 3 of annex IV) (Column 02 x column 04)"
					}
				}
			}
		},
		"anexoE": {
			"quadro03": {
				"title": "Determination of taxable income",
				"rend": "Income",
				"matcole": "Collectible Material",
				"field1-16": "Sales of goods and products",
				"field2-17": "Provision of services within the scope of restaurant and beverage activities and hotel and similar activities, with the exception of those carried out within the scope of the activity of operating local accommodation establishments in the form of a house or apartment",
				"field3-18": "Provision of services within the scope of professional activities specifically provided for in the list attached to the CIRS",
				"field4-19": "Other services provided",
				"field5-20": "Exploration subsidies",
				"table": {
					"desc": "Subsidies not intended for exploration",
					"rend": "Income",
					"matcole": "Collectible material = [(6) x 0.30 x t]",
					"totrend": "Total - Income",
					"totmatcol": "Total - Taxable Material",
					"soma": "Control Sum"
				},
				"field7-22": "Temporary assignment or use of intellectual or industrial property",
				"field8-23": "Provision of information regarding experience acquired in the industrial, commercial or scientific sector",
				"field9-24": "Other capital income",
				"field10-25": "Positive result of property income",
				"field11-26": "Positive balance of tax gains and losses",
				"field12-27": "Remaining equity increases",
				"field13-28": "Acquisition value of equity increments obtained free of charge",
				"field32-33": "Income from the operation of local accommodation establishments in the form of a house or apartment (item h of paragraph 1 of article 86-B)",
				"field35-36": "Income from the operation of local accommodation establishments in the form of a house or apartment, located in a containment area (item g of paragraph 1 of article 86-B)",
				"field14-29": "Positive adjustment under the terms of article 64, no. 3, al.",
				"field15-30": "Positive adjustment under the terms of article 64, no. 3, al.",
				"field37-38": "Income from cryptoactive mining and other income provided for in subparagraph e) of paragraph 1 of article 86-B.",
				"field39-46": "Income related to crypto-assets, excluding those arising from mining, which are not considered capital income, nor result from the positive balance of capital gains and losses and other equity increases (item i) of paragraph 1 of article 86. º-B).",
				"field40": "TOTAL INCOME",
				"field41": "SUBTOTAL COLLECTION MATERIAL (sum of fields 16 to 30 + 33)",
				"field34": "Increase in financial contributions from forest owners and producers participating in a forestry intervention zone allocated to the fund set up by the respective managing entity (art. 59-D, no. 14 of the EBF)",
				"field31": "Increase due to non-reinvestment (art. 86-B, no. 11 of the CIRC)",
				"field42": "TOTAL COLLECTABLE MATERIAL (Fields 41 + 31 - 34)",
				"field3142desc": "(to be transported to field 346 of table 09 of mod.22)",
				"tdesc": "t - Corresponding to the minimum depreciation/amortization rate of subsidized assets."
			},
			"quadro04": {
				"title": "Other information",
				"campo43": "Date you started activity"
			}
		},
		"anexoF": {
			"quadro03": {
				"title": "Calculation of taxable profit (article 22, paragraphs 2 and 3 of the EBF)",
				"field1": "NET INCOME FOR THE PERIOD",
				"acrescer": "TO BE ADDED",
				"field2": "Capital losses (realized or potential) provided for in article 10 of the IRS Code are not deductible",
				"field3": "Expenses arising from the application of fair value to non-deductible financial instruments and properties",
				"field4": "Losses arising from non-deductible exchange rate variations",
				"field5": "Other expenses and losses associated with obtaining income excluded from taxation",
				"field6": "Expenses or losses not deductible under article 23-A of the CIRC",
				"field19": "Expenses on management fees and others",
				"field7": "",
				"field8": "SUM (fields 2 to 7 + 19)",
				"deduzir": "TO DEDUCE",
				"field9": "Capital income provided for in article 5 of the IRS Code excluded from taxation",
				"field10": "Property income provided for in article 8 of the IRS Code excluded from taxation",
				"field11": "Capital gains (realized or potential) provided for in article 10 of the IRS Code excluded from taxation",
				"field12": "Income arising from the application of fair value to financial instruments and properties excluded from taxation",
				"field13": "Gains arising from exchange rate variations excluded from taxation",
				"field14": "Income from management fees and others",
				"field15": "",
				"field16": "SUM (fields 9 to 15)",
				"field17": "LOSS FOR TAX EFFECTS [if (1+8-16) < 0]",
				"field18": "TAXABLE PROFIT [if (1+8-16) >= 0]"
			},
			"quadro04": {
				"title": "Determination of taxable income",
				"field1": "TAX LOSS (transport from field 17 of Q. 03)",
				"field2": "TAXABLE PROFIT (carryover from field 18 of Q. 03)",
				"field3": "Deductible tax losses (art. 22, no. 4 of the EBF)",
				"field4": "TAX LOSS DEDUCTED",
				"field5": "COLLECTABLE MATERIAL (2-4)"
			},
			"quadro05": {
				"title": "Collection clearance",
				"field1": "Tax at normal rate (art. 22, no. 5 of the EBF) (field 5 of Q.04) x 21%",
				"desc": "Transitional regime (art. 7 of DL no. 7/2015, of January 13):",
				"field2": "Tax relating to the positive balance between the capital gains and capital losses of real estate (transport from field 10 of table 06-A)",
				"field3": "Tax relating to capital gains on other assets (transport from field 6 of table 06-B)",
				"field4": "COLLECTION (adds fields 1 to 3) (to be transported to the C347-B, C350 or C370 of Q. 10 of Mod. 22)"
			},
			"quadro06": {
				"title": "Capital gains realized covered by the transitional regime provided for in article 7, no. 6, of DL no. 7/2015, of 13/01)",
				"descA": "Capital gains and losses resulting from the sale of properties acquired under the previous wording of article 22 of the EBF",
				"descB": "Capital gains resulting from the sale of other assets",
				"tableA": {
					"fields": {
						"identmatimoveis": "Matrix Identification of Properties",
						"codfreg": "Parish code",
						"tipo": "Type",
						"artigo": "Article",
						"faccaoseccao": "Fraction/Section",
						"dataaqui": "Acquisition date (year/month/day)",
						"dataalienacao": "Sale date (year/month/day)",
						"montantemaismenosvalia": "Amount of realized surplus value and loss",
						"maismenosvaiaabrangida": "Gain and loss of value covered by the transitional regime"
					}
				},
				"tableB": {
					"fields": {
						"designacao": "Designation",
						"dataaqui": "Acquisition date (year/month/day)",
						"dataalienacao": "Sale date (year/month/day)",
						"montantemaisvalia": "Amount of the added value determined by reference to the date 2015-06-30",
						"impcorrespondente": "Corresponding tax"
					}
				},
				"field9": "BALANCE",
				"field10": "Tax corresponding to the positive balance between realized capital gains and capital losses (to be carried forward to C2 of Q.05) [C9 balance x 50%] x 25%",
				"field6": "SUM (to be carried to field 3 of Q.05)"
			}
		},
		"anexoG": {
			"quadro03": {
				"title": "Tax losses determined before the special regime deductible under the general regime (art. 7, no. 2 of the Annex to DL 92/2018, of November 13)",
				"field1": "Turnover of planned activities (art. 3, no. 1 of the Annex to the DL) and carried out by eligible ships/vessels (art. 4.º of the Annex to the DL)",
				"field2": "Turnover of activities not foreseen or carried out by ineligible ships/vessels",
				"field3": "Ratio to be applied in the period to deductible tax losses calculated before the special regime: (Turnover of activities not foreseen or carried out by ineligible ships/vessels / Total turnover)",
				"table": {
					"fields": {
						"perio": "Period to which the loss relates",
						"montprejregespecial": "Amount of deductible tax losses before entry into the special regime",
						"montprejperio": "Amount of tax losses deducted in the period",
						"saldo": "Balance carried over to the following period(s)"
					}
				},
				"field10": "Amount to be added in field 309 of table 09 of the declaration"
			},
			"quadro04": {
				"title": "Determination of taxable income - Special regime",
				"desc": "Ships / Vessels",
				"table": {
					"fields": {
						"field1": "Identification number (IMO)",
						"field2": "Country of Registration (article 9 of the DL)",
						"field3": "Ship/vessel operating regime (art. 4, no. 8 of the Annex to the DL)",
						"field4": "Country of strategic and commercial management (art. 4, no. 2, subparagraph b) of the Annex to the DL)",
						"field5": "Percentage of eligible crew members (art. 3, no. 3 of the DL)",
						"field6": "Net tonnage (article 5 of the Annex to the DL)",
						"field7": "Percentage of income from auxiliary activities in total income (art. 3, no. 3 of the Annex to the DL)",
						"field8": "Number of days (art. 5 of the Annex to the DL)",
						"field9": "Reduction Taxable amount (art. 5, no. 6 of the Annex to the DL)",
						"field10": "Taxable material (article 5 of the Annex to the DL)"
					}
				},
				"field11": "Taxable amount determined (to be transported to field 300 of table 09 of the declaration)"
			},
			"quadro05": {
				"title": "Additional Information",
				"desc": "In the case of chartering to third parties, indicate:",
				"field1": "The net tonnage of ships/vessels taken under charter",
				"field2": "The net tonnage of the entire fleet (art. 4, no. 8 of the Annex to the DL)",
				"field3": "The income of ships/vessels taken under charter",
				"field4": "The income of the remaining ships/vessels owned or similar (art. 4, no. 8 of the Annex to the DL)"
			},
			"quadro06": {
				"title": "Other information",
				"field1": "Date on which the IRC activity began (article 5, paragraph 4 of the Annex to the DL)",
				"field2-3": "There was a cessation of VAT less than five years ago (art. 5, no. 5 of the Annex to the DL)",
				"field2": "Yes",
				"field3": "No"
			}
		},
		"modal": {
			"prejuizos": "Losses",
			"nif": "NIF",
			"periodo": "Period",
			"montante": "Amount",
			"valPerio": "Value used in the period",
			"retFonte": "Withholding",
			"rosto": {
				"titles": {
					"309": "General regime",
					"320": "Rate reduction",
					"331": "Exemption",
					"396": "Individual losses deducted, verified in periods prior to the start of application of the regime",
					"398": "Shares of tax losses deducted in the event of acquisition of groups of companies (article 71, paragraphs 4 and 5)",
					"397A": "Value used in the period [art. 15, no. 1 al.",
					"397B": "Value used in the period (article 75, paragraphs 1 and 3)",
					"309B": "Special regime applicable to acquirers of entities considered companies in difficulty",
					"quadro12List": "Withholdings at source",
					"quadro14": "Tax credit for international legal double taxation (CIDTJI)"
				},
				"contasdef": {
					"title": "Defining accounts for fields",
					"campo": "Field {{field}}",
					"nContaDe": "Account No.",
					"nContaAte": "Until",
					"perio": "Period until",
					"deznormal": "Normal December",
					"primeiroence": "1st closing",
					"segence": "2nd closing",
					"sinal": "Signal",
					"positivo": "Positive",
					"negativo": "Negative",
					"addline": "Add Line",
					"table": {
						"nContaDe": "Account No.",
						"nContaAte": "Number of counts up to",
						"perioAte": "Period until",
						"sinal": "Positive",
						"valor": "Value"
					}
				},
				"contasdefall": {
					"title": "Accounts defined for the fields",
					"table": {
						"campo": "Field"
					},
					"btn": {
						"copiarparaempresas": "Copy to other companies",
						"reporcontas": "Reset settings"
					}
				},
				"contasdefcopy": {
					"title": "Copy accounts defined for fields to another company",
					"checkbox": {
						"deletedestino": "Deletes existing settings in the target company"
					}
				}
			},
			"anexoa": {
				"titles": {
					"A": "General Criteria",
					"B": "General criteria - Madeira Free Trade Zone (Art. 36.º -A, no. 12 of the EBF)",
					"C": "Specific criterion - electrical production centers",
					"D": "Specific criteria - Minas"
				},
				"distmuni": "District/municipality code",
				"taxaderra": "Spill rate",
				"massasalA": "Municipal wage bill",
				"massasalB": "Municipal wage bill at ZFM",
				"massasalC": "Wage bill + provision of municipal services (MSPSMunic)",
				"massasalD": "Wage bill + provision of municipal services (MSPSMunic)",
				"racioA": "Distribution ratio (4) = (3) / (Q.03, C3)",
				"racioB": "Distribution ratio (4) = (3) / (Q.03, C3)",
				"racioC": "Municipality ratio",
				"racioD": "Municipality ratio",
				"areainstexpl": "Installation area.",
				"totalProduzido": "Total electricity.",
				"producao": "Production at the mouth of the mine in the municipality (PBMunic)",
				"derramaA": "CALCULATED SPILL (5) = (Q.03, C1 x (2) x (4)) or (Q.03, C10) x (2) x (4)",
				"derramaB": "Calculated spill (5) = [(Q.03, C2 x (2) x (4))] x 0.2",
				"derramaC": "Calculated spill (8) = (Q03 C1) x (2) x (7)",
				"derramaD": "Calculated spill (7) = (Q03 C1) x (2) x (6)",
				"primeiroAno": "Indicate whether this is the 1st year of applying the criterion",
				"potencia": "Installed power in the municipality"
			},
			"anexod": {
				"titles": {
					"71": "Contractual tax benefits for investment (ex-article 41, paragraph 1 of the EBF, articles 15 to 21 of the CFI (repealed) and articles 2 to 21 of the CFI approved by Decree-Law no. 162/2014, of 31/10 and articles 2 to 21 of the CFI in RAM approved by Regional Decree no. 24/2016/M, of 28. /06",
					"73": "SIFIDE - System of tax incentives in research and business development (Law no. 40/2005, of 3/8) and SIFIDE II (art. 133 of Law 55-A/2010, of 31/12, art. 33.º to 40.º of the CFI (repealed) and articles 35.º to 42.º of the CFI approved by Decree-Law no. 162/2014, of 31/10 and articles 35.º to 42nd of the CFI in RAM approved by Regional Decree no. 24/2016/M, of 28/06",
					"74": "Investment support tax regime (Law no. 10/2009, of 10/3 (successively extended), articles 26 to 32 of the CFI (repealed) and articles 22 to 26 of the CFI approved by the Decree-Law no. 162/2014, of 31/10 and articles 22 to 26 of the CFI in RAM approved by Regional Decree no. 24/2016/M, of 28/06.",
					"77": "Transmission of tax benefits from the merged or cindicated company or the contributing company (Article 75.º-A of the CIRC)",
					"78": "Incentives subject to maximum regional aid rates (CFI approved by Decree-Law no. 162/2014, of October 31) (For tax periods 2015 and 2016)",
					"79": "IFPC - Tax incentive for cinematographic and audiovisual production (Article 59.º-F of the EBF and Ordinance no. 89.º-A/2017, of April 19)",
					"121": "Information to be communicated by the acquiring company",
					"122": "Information to be communicated by the company considered a company in difficulty",
					"604": "NACE Rev. 1 code (art. 36, no. 6 EBF)",
					"605": "NACE Rev. 2 code (art. 36-A, no. 7 of the EBF)",
					"710": "Tax Incentive for Recovery - IFR (article 307 of Law no. 12/2022, of June 27)",
					"31A": "Pension and similar funds (art. 16, no. 1 of the EBF) and other definitively exempt funds",
					"31B": "Other definitive exemptions",
					"32A": "Share savings funds (art. 26 of the EBF) and other temporarily exempt funds",
					"32B": "Other temporary exemptions",
					"04A": "Other income deductions",
					"041": "Transmission of tax benefits from the merged or split company or the contributing company (article 75-A of the CIRC)",
					"quadro8": "Donations (articles 62, 62-A and 62-B of the EBF)",
					"quadro9": "Identification of associated companies (single company concept for the purposes of the de minimis limit)",
					"quadro11A": "Partner or associated company(ies) as defined in paragraphs 2 and 3 of article 3 of the Annex to Decree-Law no. 372/2007, of 6 November, indicate the respective NIF(s)",
					"71A": "RETGS - ADDITIONAL INFORMATION (to be completed by the companies that make up the group) - use of the benefit within the group",
					"73A": "RETGS - ADDITIONAL INFORMATION (to be completed by the companies that make up the group) - use of the benefit within the group",
					"74A": "RETGS - ADDITIONAL INFORMATION (to be completed by the companies that make up the group) - use of the benefit within the group",
					"710A": "RETGS - ADDITIONAL INFORMATION (to be completed by all companies that make up the group) - use of the benefit within the group",
					"78A1": "Information regarding regional investment projects",
					"78A2": "Financial incentives enjoyed and tax used - Values ​​for the tax period",
					"78A3": "Financial incentives enjoyed and tax used - Accumulated updated values"
				},
				"quadro7": {
					"nif71": "NIF of the company.",
					"nif73": "NIF of the company.",
					"nif74": "NIF of the company.",
					"nif76": "NIF of the company.",
					"nif77": "NIF merged, split or contributing company",
					"nIdentificacao": "Work identification number",
					"diploma": "University Degree",
					"periodo": "Period to which the benefit relates",
					"saldoCaducado71": "Expired balance",
					"saldoCaducado73": "Expired balance",
					"saldoCaducado74": "Expired balance",
					"saldoCaducado71A": "Actual expired balance in the group declaration",
					"saldoCaducado73A": "Actual expired balance in the group declaration",
					"saldoCaducado74A": "Actual expired balance in the group declaration",
					"saldoNaoDeduzido71": "Balance not deducted in the previous period",
					"saldoNaoDeduzido73": "Balance not deducted in the previous period",
					"saldoNaoDeduzido74": "Balance not deducted in the previous period",
					"saldoNaoDeduzido76": "Balance not deducted in the previous period",
					"saldoNaoDeduzido71A": "Balance not deducted in the period prior to group collection",
					"saldoNaoDeduzido73A": "Balance not deducted in the period prior to group collection",
					"saldoNaoDeduzido74A": "Balance not deducted in the period prior to group collection",
					"saldoNaoDeduzido76A": "Balance not deducted in the period prior to group collection",
					"saldoNaoDeduzido79": "Balance not deducted in the previous period",
					"dotacaoDoPeriodo71": "Period allocation",
					"dotacaoDoPeriodo73": "Period allocation",
					"dotacaoDoPeriodo74": "Period allocation",
					"dotacaoDoPeriodo76": "Period allocation",
					"dotacaoDoPeriodo71A": "Allocation of the period in the group declaration",
					"dotacaoDoPeriodo73A": "Allocation of the period in the group declaration",
					"dotacaoDoPeriodo74A": "Allocation of the period in the group declaration",
					"dotacaoDoPeriodo76A": "Allocation of the period in the group declaration",
					"deducaoNoPeriodo": "Period deduction",
					"deducaoUtilizada": "Deduction used in the group declaration",
					"saldoQueTransita71": "Balance carried over to the next period",
					"saldoQueTransita73": "Balance carried over to the next period",
					"saldoQueTransita74": "Balance carried over to the next period",
					"saldoQueTransita76": "Balance carried over to the next period",
					"saldoQueTransita71A": "Balance carried over to the next period in the group declaration",
					"saldoQueTransita73A": "Balance carried over to the next period in the group declaration",
					"saldoQueTransita74A": "Balance carried over to the next period in the group declaration",
					"saldoQueTransita76A": "Balance carried over to the next period in the group declaration",
					"saldoQueTransita79": "Balance carried over to the next period",
					"dataInicio": "Start date of work",
					"dataConclusao": "Work completion date",
					"valorIncentivo": "Incentive value in the period",
					"deducaoDoPeriodo": "Period deduction",
					"valorAReembolsar": "Amount to be refunded",
					"valorARepor": "Value to be restored (to be transported to C.372 of Q.10 of M.22)",
					"saldo": "Balance of benefit transmitted",
					"dotacao": "Period allocation",
					"montanteBeneficio": "Tax benefit amount",
					"dsdiploma": {
						"1": "Tax Benefits Statute (ex-art. 41) and Decree-Law no. 409/99, of October 15",
						"2": "Investment Tax Code, approved by Decree-Law no. 249/2009, of September 23, amended by Law no. 20/2012, of May 14, by Decree-Law no. 82/2013, of 17 of June, and by Law no. 83-C/2013, of 31 December and revoked by Decree-Law no. 162/2014, of 31 October",
						"3": "Investment Tax Code, approved by Decree-Law no. 162/2014, of October 31",
						"4": "Regional Legislative Decree No. 18/99/M, of June 28",
						"5": "Regional Legislative Decree no. 2/99/A, of January 20th (art. 9.º)",
						"6": "Investment Tax Code in RAM approved by Regional Legislative Decree No. 24/2016/M, of June 28",
						"720": "720",
						"724": "Extraordinary tax credit for investment (Law no. 49/2013, of 16 July)",
						"731": "Law No. 40/2005, of August 3",
						"732": "Investment Tax Code, approved by Decree-Law No. 249/2009, of September 23rd and revoked by Decree-Law No. 162/2014, of October 31st",
						"733": "Investment Tax Code, approved by Decree-Law no. 162/2014, of October 31",
						"734": "Investment Tax Code in the R. A. of Madeira approved by Regional Legislative Decree no. 24/2016/M, of June 28",
						"741": "RFAI - Fiscal regime to support investment [Law no. 10/2009, of March 10 (successively extended) and arts.",
						"742": "RFAI - Tax regime to support investment (arts. 22 to 26 of the CFI approved by Decree-Law no. 162/2014, of October 31)",
						"743": "RFAI - Fiscal regime to support investment in the Autonomous Region of Madeira - Regional Legislative Decree no. 24/2016/M, of 28 June)"
					},
					"tabelas78": {
						"regiao78": "Eligible region (article 43 of the CFI)",
						"regiao78A1": "Eligible region",
						"cae78": "CAE code of the activity for which the investment is intended (article 2 of Port. no. 282/2014, of 31/12)",
						"cae78A1": "CAE Code",
						"montanteDasAplicacoes": "Amount of relevant applications (articles 11, 22 and 30 of the CFI)",
						"irc": "IRC",
						"imiIMTSelo": "IMI, IMT and SELO",
						"naoFiscais": "Non-Tax",
						"total": "Total",
						"numeroLinha": "Line No.",
						"numProjecto": "Project No./Incentive Code",
						"dataInicio": "Investment start date",
						"dataFim": "Investment end date",
						"tipologia": "Investment typology",
						"identificacao": "Official identification of the financial incentive",
						"montante": "Total amount",
						"montanteActualizado": "Updated total amount",
						"aplicacoesMontante78A2": "Relevant applications carried out - Amount",
						"aplicacoesMontante78A3": "Relevant applications made - Accumulated amount updated",
						"aplicacoesActualizado": "Updated amount",
						"financeiroUsufruido": "Financial - Amount enjoyed",
						"financeiroActualizado": "Financial - Updated amount used",
						"ircUtilizado": "IRC - Amount used",
						"ircActualizado": "IRC - Updated amount",
						"imiUtilizado": "IMI - Amount used",
						"imiActualizado": "IMI - Updated amount",
						"imtUtilizado": "IMT - Amount used",
						"seloUtilizado": "SEAL - Amount used",
						"financeiroMontante": "Financial - Updated amount used",
						"ircMontante": "IRC - Updated amount",
						"imiMontante": "IMI - Updated amount",
						"imtMontante": "IMT - Amount used",
						"seloMontante": "SEAL - Amount used",
						"intensidade": "Accumulated aid intensity (in %)",
						"montanteAInscrever": "Amount to be entered in field 372 of Q. 10 of M. 22",
						"montanteTotal": "Updated total amount of benefits enjoyed/used",
						"tipo": "Type",
						"datasources": {
							"beneficio": {
								"71": "Contractual tax benefits for investment (ex-article 41, paragraph 1 of the EBF, articles 15 to 21 of the CFI (repealed), articles 2 to 21 of the CFI approved by Decree-Law no. 162/2014, of 31/10 and articles 2 to 21 of the CFI in RAM approved by Regional Decree no. 24/2016/M, of 28. /06)",
								"727": "DLRR - Deduction for profits retained and reinvested by SMEs (articles 27 to 34 of the CFI approved by Decree-Law no. 162/2014, of 31/10 and articles 27 to 34. of the CFI in RAM approved by Regional Decree no. 24/2016/M, of 28/06)",
								"741": "RFAI - Fiscal regime to support investment [Law no. 10/2009, of March 10 (successively extended) and arts.",
								"742": "RFAI - Tax regime to support investment (arts. 22 to 26 of the CFI approved by Decree-Law no. 162/2014, of October 31)",
								"743": "RFAI - Fiscal regime to support investment in the Autonomous Region of Madeira - Regional Legislative Decree no. 24/2016/M, of 28 June)"
							},
							"regiao78": {
								"1": "North",
								"2": "center",
								"3": "Alentejo",
								"4": "Autonomous Region of the Azores",
								"5": "Autonomous Region of Madeira",
								"6": "Algarve",
								"7": "Mafra",
								"8": "Loures",
								"9": "Setúbal Peninsula",
								"10": "Vila Franca de Xira",
								"11": "S. João das Lampas and Terrugem (Sintra)"
							},
							"regiao78A1": {
								"PT111": "North - Alto Minho",
								"PT112": "North - Cávado",
								"PT119": "North - Ave",
								"PT11A": "North - Porto Metropolitan Area",
								"PT11B": "North - Alto Tâmega",
								"PT11C": "North - Tâmega e Sousa",
								"PT11D": "North - Douro",
								"PT11E": "North - Lands of Trás-os-Montes",
								"PT16B": "Midwest",
								"PT16D": "Center - Aveiro Region",
								"PT16E": "Center - Coimbra Region",
								"PT16F": "Center - Leiria Region",
								"PT16G": "Center - Viseu Dão Lafões",
								"PT16H": "Center - Beira Baixa",
								"PT16I": "Center - Middle Tagus",
								"PT16J": "Center - Beiras and Serra da Estrela",
								"PT1109": "Greater Lisbon - Mafra",
								"PT1107": "Greater Lisbon - Loures",
								"PT1114": "Greater Lisbon - Vila Franca de Xira",
								"PT111127": "Greater Lisbon - S. João das Lampas and Terrugem (Sintra)",
								"PT172": "Setúbal Peninsula",
								"PT181": "Alentejo - Alentejo Coast",
								"PT184": "Alentejo - Baixo Alentejo",
								"PT185": "Alentejo - Lezíria do Tejo",
								"PT186": "Alentejo - Alto Alentejo",
								"PT187": "Alentejo - Central Alentejo",
								"PT150": "Algarve",
								"PT200": "Autonomous Region of the Azores",
								"PT300": "Autonomous Region of Madeira"
							},
							"tipo": {
								"D": "Distinct",
								"U": "Single"
							},
							"tipologia": {
								"001": "Creation of a new establishment",
								"002": "Increasing the capacity of an existing establishment",
								"003": "Diversification of the production of an establishment with regard to products not previously manufactured in that establishment",
								"004": "Fundamental change to the overall production process of an existing establishment"
							},
							"identificacao": {
								"10": "NORTH 2020",
								"11": "PDR 2020",
								"12": "PO SEUR",
								"13": "POAT 2020",
								"14": "PRODERAM 2020",
								"15": "PRORURAL+",
								"99": "OTHERS",
								"01": "AZORES 2020",
								"02": "ALENTEJO 2020",
								"03": "CENTER 2020",
								"04": "COMMERCE INVESTS",
								"05": "COMPETE 2020",
								"06": "CRESC ALGARVE 2020",
								"07": "LISBON 2020",
								"08": "WOOD 14-20",
								"09": "MAR 2020"
							}
						}
					}
				},
				"codBeneficio": "Benefit code",
				"montante": "Amount",
				"nif041": "NIF soc.",
				"nifquadro8": "NIF of the donee entity",
				"nifquadro9": "907 - NIF",
				"nifquadro11A": "3 - NIF",
				"nif121": "NIF column of the company in difficulty",
				"nif122": "NIF column of the company acquiring the shareholding",
				"nace604": "NACE Rev. 1 code (art. 36, no. 6 EBF)",
				"nace605": "NACE Rev. 2 code (art. 36-A, no. 7 of the EBF)",
				"valorDonativo": "Donation value",
				"tipoDonativo": "Donation type",
				"ano603": "Year of investment",
				"data": "Date",
				"percentagem": "Average percentage",
				"periodo": "Clearance period",
				"prejuizosMontante": "Amount of losses",
				"prejuizosSaldo": "Current tax losses"
			},
			"anexoe": {
				"title": "Subsidies not intended for exploration",
				"rendimento": "Performance",
				"materiacol": "Collectible material = [(6) x 0.30 x t]"
			},
			"anexof": {
				"titles": {
					"A": "Capital gains and losses resulting from the sale of properties acquired under the previous wording of article 22 of the EBF",
					"B": "Capital gains resulting from the sale of other assets"
				},
				"identificacao": "Matrix Identification of Properties",
				"codFreguesia": "Parish code",
				"tipo": "Type",
				"artigo": "Article",
				"fraccao": "Fraction/Section",
				"dataAquisicao": "Date of acquisition",
				"dataAlienacao": "Date of disposal",
				"montanteA": "Amount of realized surplus value and loss",
				"montanteB": "Amount of the added value determined by reference to the date 2015-06-30",
				"montanteRegimeTransitorio": "Gain and loss of value covered by the transitional regime",
				"designacao": "Designation",
				"imposto": "Corresponding tax"
			},
			"anexog": {
				"titles": {
					"quadro3": "",
					"quadro4": ""
				},
				"periodo": "Period to which the loss relates",
				"montanteDedutiveis": "Amount of deductible tax losses before entry into the special regime",
				"montanteDeduzidos": "Amount of tax losses deducted in the period",
				"saldo": "Balance carried over to the following period(s)",
				"nIdentificacao": "Identification number (IMO)",
				"paisDeRegisto": "Country of Registration (article 9 of the DL)",
				"regime": "Ship/vessel operating regime (art. 4, no. 8 of the Annex to the DL)",
				"paisDeGestao": "Country of strategic and commercial management (art. 4, no. 2, subparagraph b) of the Annex to the DL)",
				"percTripulantes": "Percentage of eligible crew members (art. 3, no. 3 of the DL)",
				"arqueacao": "Net tonnage (article 5 of the Annex to the DL)",
				"percRendimentos": "Percentage of income from auxiliary activities in total income (art. 3, no. 3 of the Annex to the DL)",
				"numeroDias": "Number of days (art. 5 of the Annex to the DL)",
				"reducaoMateria": "Reduction Taxable amount (art. 5, no. 6 of the Annex to the DL)",
				"materiaColectavel": "Taxable material (article 5 of the Annex to the DL)"
			},
			"prejuizosfiscais": {
				"title": "Tax losses",
				"anoprej": "Year of loss",
				"valprej": "Value of tax loss",
				"anoded": "Year of deduction",
				"valded": "Deduction amount",
				"adicionarprej": "Add loss",
				"adicionarded": "Add deduction",
				"table": {
					"ano": "Year of loss",
					"valorPrejuizoFiscal": "Loss value",
					"valorDeduzido": "Deducted amount",
					"valorPorDeduzir": "Amount to be deducted",
					"anoDeducao": "Year of deduction",
					"valorDeducao": "Deduction amount"
				}
			},
			"derrama": {
				"title": "Additional data for calculating the spill",
				"concelho": "County: {{cod}} - {{name}}",
				"ano": "Year: {{year}}",
				"postosTrabalhoCriados": "Number of jobs created",
				"volumeNegocios": "Business volume in the previous year",
				"investimentoNoAno": "Investment in the year",
				"temSedeNoConcelho": "Company is headquartered in the municipality",
				"anoFixacaoDaSede": "Year of establishment of headquarters",
				"microEmpresa": "Micro enterprise",
				"pequenaEmpresa": "Small business",
				"mediaEmpresa": "Medium company",
				"acrescimoTrabalhadores": "Increase in number of workers (%)",
				"postosTrabalhoCriados2019": "Number of jobs created in 2019",
				"postosTrabalhoCriados2020": "Number of jobs created in 2020"
			},
			"configws": {
				"title": "WebService Settings",
				"username": "Contributor username",
				"password": "Taxpayer password",
				"servicoInvCC": "Service invoked by Certified Accountant (CC)",
				"ccPowers": "CC with full declarative powers for Contrib.",
				"usernameCC": "CC username",
				"passwordCC": "CC password",
				"configured": "<i class='fa fa-check' aria-hidden='true'></i> The service is already configured.",
				"notConfigured": "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> The service is not configured.",
				"configsaved": "Configuration saved successfully."
			}
		},
		"datasources": {
			"tiporend": {
				"lucrosestav": "Establishment profits.",
				"outrosrend": "Other income"
			},
			"beneficio": {
				"100": "Interiority regime - article 43 of the EBF - Transitional regime",
				"119": "Other income deductions",
				"120": "Madeira Free Trade Zone Concessionaire - Exemption until 2017 (art. 33, no. 12 of the EBF)",
				"121": "Profits derived from works at the Lajes Base and support facilities - article XI of Annex I of the Technical Agreement, approved by Resolution of the Assembly of the Republic 38/95, of October 11 - Cooperation and Defense Agreement between the Portuguese Republic and the USA",
				"122": "Decree-Law No. 43 335/1960 of 11/19",
				"129": "Other temporary exemptions",
				"140": "Pension and similar funds (art. 16, no. 1 of the EBF)",
				"141": "Retirement savings, education savings and retirement/education savings funds (art. 21, no. 1 of the EBF)",
				"142": "Venture capital funds (art. 23 of the EBF)",
				"143": "Real estate investment funds in forest resources (art. 24, no. 1 of the EBF)",
				"149": "Other definitively exempt funds",
				"150": "Share savings funds (art. 26, no. 1 of the EBF)",
				"151": "Real estate investment funds - urban rehabilitation (art. 71, no. 1 of the EBF)",
				"159": "Other temporarily exempt funds",
				"401": "Increase to job creation (art. 19 of the EBF)",
				"409": "Conventional renumbering of share capital - SME (art. 136 of Law no. 55-A/2010, of 31/12 and art. 41-A of the EBF)",
				"410": "Other income deductions",
				"040": "Maritime and air navigation entities (article 13 of the CIRC)",
				"049": "Other definitive exemptions"
			},
			"tipodonativo": {
				"10": "Social patronage (art. 62)",
				"11": "Social patronage - special support (art. 62)",
				"12": "Family patronage (art. 62)",
				"13": "Cultural patronage (art. 62.º-B)",
				"14": "Cultural patronage - multi-year contracts (art. 62-B)",
				"15": "Patronage of associative bodies (art. 62)",
				"16": "Patronage for the information society (art. 65).",
				"17": "Patronage - information society - multi-annual contracts (art. 65).",
				"18": "State - scientific patronage (art. 62-A)",
				"19": "State - scientific patronage - multi-year contracts (art. 62-A)",
				"20": "Scientific patronage - private entities (art. 62-A)",
				"21": "Scientific patronage - private entities - multi-year contracts (art. 62-A)",
				"22": "Special regimes (separate legislation)",
				"23": "Cultural patronage - others (art. 62.º-B)",
				"24": "Donations in kind (no. 11 of article 62, no. 5 of article 62-A of the EBF and no. 7 of article 62-B)",
				"25": "Donations allocated to the Mission Structure for the Celebrations of the 5th centenary of Circum-Navigation, created by Resolution of the Council of Ministers no. 24/2017, of January 26th – cultural patronage (art. 294 of Law no. 71/2018, of December 31)",
				"01": "State - social patronage (art. 62)",
				"02": "State - cultural patronage (art. 62.º-B)",
				"03": "State - environmental patronage (art. 62)",
				"04": "State - educational patronage (art. 62)",
				"06": "State - cultural patronage - multi-year contracts (art. 62-B)",
				"07": "State - environmental patronage - multi-year contracts (art. 62)",
				"08": "State - sports patronage - multi-year contracts (art. 62)",
				"09": "State - educational patronage - multi-year contracts (art. 62)"
			},
			"tipoidentmatricial": {
				"u": "Urban",
				"r": "Rustic",
				"o": "Omitted"
			},
			"paises": {
				"4": "AFGHANISTAN",
				"8": "ALBANIA",
				"10": "ANTARCTICA",
				"12": "ALGERIA",
				"16": "AMERICAN SAMOA",
				"20": "ANDORRA",
				"24": "ANGOLA",
				"28": "ANTIGUA AND BARBUDA",
				"31": "AZERBAIJAN",
				"32": "ARGENTINA",
				"36": "AUSTRALIA",
				"40": "AUSTRIA",
				"44": "BAHAMAS",
				"48": "BAREM",
				"50": "BANGLADESH",
				"51": "ARMENIA",
				"52": "BARBADOS",
				"56": "BELGIUM",
				"60": "BERMUDA",
				"64": "BUTAO",
				"68": "BOLIVIA",
				"70": "BOSNIA AND HERZEGOVINA",
				"72": "BOTSWANA",
				"74": "BOUVET ISLANDS",
				"76": "BRAZIL",
				"84": "BELIZE",
				"86": "BRITISH INDIAN OCEAN TERRITORY",
				"90": "SOLOMON ISLANDS",
				"92": "VIRGIN ISLANDS (BRITISH)",
				"96": "BRUNEI DARUSSALAM",
				"100": "BULGARIA",
				"104": "MYANMAR",
				"108": "BURUNDI",
				"112": "BELARUS",
				"116": "CAMBODIA",
				"120": "CAMEROON",
				"124": "CANADA",
				"132": "CAPE GREEN",
				"136": "CAYMAO ISLANDS",
				"140": "CENTRAL AFRICAN (REPUBLIC)",
				"144": "SRI LANKA",
				"148": "CHAD",
				"152": "CHILE",
				"156": "CHINA",
				"158": "TAIWAN",
				"162": "CHRISTMAS ISLANDS",
				"166": "COCOS OR KEELING ISLANDS",
				"170": "COLOMBIA",
				"174": "COMOROS",
				"175": "MAYOTTE",
				"178": "CONGO",
				"180": "CONGO (DEMOCRATIC REPUBLIC OF)",
				"184": "COOK ISLANDS",
				"188": "COSTA RICA",
				"191": "CROATIA",
				"192": "CUBA",
				"196": "CYPRUS",
				"203": "CZECH REPUBLIC",
				"204": "BENIN",
				"208": "DENMARK",
				"212": "DOMINICA",
				"214": "DOMINICAN REPUBLIC",
				"218": "ECUADOR",
				"222": "EL SALVADOR",
				"226": "EQUATORIAL GUINEA",
				"231": "ETHIOPIA",
				"232": "ERITREA",
				"233": "ESTONIA",
				"234": "FAROE ISLANDS",
				"238": "FALKLAND ISLANDS (MALVINAS)",
				"239": "SOUTH GEORGIA AND SANDWICH ISLANDS",
				"242": "FIJI ISLANDS",
				"246": "FINLAND",
				"248": "ALAND ISLANDS",
				"250": "FRANCE",
				"254": "FRENCH GUIANA",
				"258": "FRENCH POLYNESIAN",
				"260": "SOUTHERN FRENCH TERRITORIES",
				"262": "Djibouti",
				"266": "GABON",
				"268": "GEORGIA",
				"270": "GAMBIA",
				"275": "OCCUPIED PALESTINIAN TERRITORY",
				"276": "GERMANY",
				"278": "GERMAN DEMOCRATIC REPUBLIC",
				"288": "GHANA",
				"292": "GIBRALTAR",
				"296": "KIRIBATI",
				"300": "GREECE",
				"304": "GREENLAND",
				"308": "GRENADE",
				"312": "GUADELOUPE",
				"316": "GUAM",
				"320": "GUATEMALA",
				"324": "GUINEA",
				"328": "GUYANA",
				"332": "HAITI",
				"334": "HEARD ISLANDS AND MCDONALD ISLANDS",
				"336": "SANTA SE (VATICAN STATE CITY)",
				"340": "HONDURAS",
				"344": "HONG KONG",
				"348": "HUNGARY",
				"352": "ICELAND",
				"356": "INDIA",
				"360": "INDONESIA",
				"364": "IRAN (ISLAMIC REPUBLIC)",
				"368": "IRAQ",
				"372": "IRELAND",
				"376": "ISRAEL",
				"380": "ITALY",
				"384": "COSTA DO MARFIM",
				"388": "JAMAICA",
				"392": "JAPAN",
				"398": "KAZAKHSTAN",
				"400": "JORDAN",
				"404": "KENYA",
				"408": "KOREA (DEMOCRATIC PEOPLE'S REPUBLIC OF)",
				"410": "KOREA, REPUBLIC OF)",
				"414": "KUWAIT",
				"417": "KYRGYZ",
				"418": "LAOS (DEMOCRATIC PEOPLE'S REPUBLIC OF)",
				"422": "LEBANON",
				"426": "LESOTHO",
				"428": "LATVIA",
				"430": "LIBERIA",
				"434": "LIBYA (JAMAHIRIYA ARAB DA)",
				"438": "LIECHTENSTEIN",
				"440": "LITHUANIA",
				"442": "LUXEMBOURG",
				"446": "MACAO",
				"450": "MADAGASCAR",
				"454": "MALAWI",
				"458": "MALAYSIA",
				"462": "MALDIVES",
				"466": "MALI",
				"470": "MALTA",
				"474": "MARTINIQUE",
				"478": "MAURITANIA",
				"480": "MAURICIAS",
				"484": "MEXICO",
				"492": "MONACO",
				"496": "MONGOLIA",
				"498": "MOLDOVA (REPUBLIC OF)",
				"499": "MONTENEGRO",
				"500": "MONSERRATE",
				"504": "MOROCCO",
				"508": "MOZAMBIQUE",
				"512": "OMAN",
				"516": "NAMIBIA",
				"520": "NAURU",
				"524": "NEPAL",
				"528": "NETHERLANDS",
				"530": "NETHERLANDS ANTILLES",
				"531": "HEALING",
				"533": "ARUBA",
				"534": "SINT MAARTEN",
				"535": "BONAIRE, SAINT EUSTATIUS AND SABA",
				"540": "NEW CALEDONIA",
				"548": "VANUATU",
				"554": "NEW ZEALAND",
				"558": "NICARAGUA",
				"562": "NIGER",
				"566": "NIGERIA",
				"570": "NIUE",
				"574": "NORFOLK ISLANDS",
				"578": "NORWAY",
				"580": "NORTHERN MARIAN ISLANDS",
				"581": "SMALLER FAR ISLANDS OF THE UNITED STATES",
				"583": "MICRONESIA (FEDERATE STATES OF)",
				"584": "MARSHALL ISLANDS",
				"585": "PALAU",
				"586": "PAKISTAN",
				"591": "PANAMA",
				"598": "PAPUASIA - NEW GUINEA",
				"600": "PARAGUAY",
				"604": "PERU",
				"608": "PHILIPPINES",
				"612": "PITCAIRN",
				"616": "POLAND",
				"620": "PORTUGAL",
				"624": "GUINEA BISSAU",
				"626": "EAST TIMOR",
				"630": "PUERTO RICO",
				"634": "QATAR",
				"638": "MEETING",
				"642": "ROMANIA",
				"643": "RUSSIA (FEDERATION OF)",
				"646": "RWANDA",
				"652": "S.O BARTHOLOMEU",
				"654": "SAINT HELEN",
				"659": "SAINT KITTS AND NEVIS",
				"660": "ANGUILA",
				"662": "SANTA LUCIA",
				"666": "SAINT PETER AND MIQUELON",
				"670": "SAINT VINCENT AND THE GRENADINES",
				"674": "SAN MARINO",
				"678": "SAO TOME AND PRINCIPE",
				"682": "SAUDI ARABIA",
				"686": "SENEGAL",
				"688": "SERBIA",
				"690": "SEYCHELLES",
				"694": "SIERRA LEONE",
				"702": "SINGAPORE",
				"703": "SLOVAK (REPUBLIC)",
				"704": "VIETNAM",
				"705": "SLOVENIA",
				"706": "SOMALIA",
				"710": "SOUTH AFRICA",
				"716": "ZIMBABWE",
				"724": "SPAIN",
				"728": "SOUTH SUDAN",
				"729": "SUDAN",
				"732": "WESTERN SARAH",
				"736": "SUDAN",
				"740": "SURINAME",
				"744": "SVALBARD AND THE ISLAND OF JAN MAYEN",
				"748": "SWAZILAND",
				"752": "SWEDEN",
				"756": "SWITZERLAND",
				"760": "SYRIA (ARAB REPUBLIC OF)",
				"762": "TAJIQUISTAO",
				"764": "THAILAND",
				"768": "TOGO",
				"772": "TOKELAU",
				"776": "TONGA",
				"780": "TRINITY AND TOBAGO",
				"784": "UNITED ARAB EMIRATES",
				"788": "TUNISIA",
				"792": "Türkiye",
				"795": "TURKMENIST",
				"796": "TURKS AND CAICOS (ISLANDS)",
				"798": "TUVALU",
				"800": "UGANDA",
				"804": "UKRAINE",
				"807": "MACEDONIA (FORMER YUGOSLAV REPUBLIC OF)",
				"810": "SOVIET UNION",
				"818": "EGYPT",
				"826": "UK",
				"830": "CANAL ISLANDS",
				"831": "GUERNSEY",
				"832": "JERSEY",
				"833": "ISLE OF MAN",
				"834": "TANZANIA, UNITED REPUBLIC OF",
				"840": "U.S",
				"850": "VIRGIN ISLANDS (UNITED STATES)",
				"854": "BURKINA FASO",
				"858": "URUGUAY",
				"860": "USBEQUISTAO",
				"862": "VENEZUELA",
				"876": "WALLIS AND FUTUNA (ISLANDS)",
				"882": "SAMOA",
				"887": "YEMEN",
				"891": "JUGOSL.VIA",
				"892": "SERVIA AND MONTENEGRO",
				"894": "ZAMBIA",
				"900": "OLIVEN.A",
				"901": "REPUBLIC OF KOSOVO",
				"956": "ALDERNE"
			},
			"regimeexploracao": {
				"1": "Direct acquisition",
				"2": "Long term rental",
				"3": "Leasing",
				"4": "Charter to third parties"
			},
			"factoimpedimento": {
				"naoaplica": "Not applicable",
				"falecimentoconjuge": "Death of a spouse not separated from persons and property, of a person with whom they live in conditions similar to those of the spouses, or of a relative or similar in the 1st degree of the straight line (item a) of paragraph 1 of article 12. -A of Decree-Law No. 452/99, of November 5)",
				"falecimentooutro": "Death of another relative or similar in the direct line or in the 2nd degree of the collateral line (item b) of no. 1 of article 12.º-A of Decree-Law no. 452/99, of November 5 )",
				"doencagrave": "Serious and sudden illness or hospitalization, which makes it absolutely impossible for the certified accountant to fulfill his obligations, as well as in situations of childbirth (paragraph c) of paragraph 1 of article 12-A of Decree-Law no. 452/99, of November 5)",
				"sitparentalidade": "Parenting situations (paragraph d) of no. 1 of article 12.º-A of Decree-Law no. 452/99)"
			}
		},
		"messages": {
			"nifAlredyExists": "The taxpayer number already exists.",
			"nifEmpty": "You must indicate your taxpayer number.",
			"periodoAlredyExists": "The year indicated already has a loss attributed.",
			"paisTipoRendAlredyExists": "There is already a country with an income type.",
			"codbenefvazioAlredyExists": "The code already exists.",
			"codbenefvazio": "You did not select a code.",
			"naceAlredyExists": "The code already exists.",
			"naceVazio": "You did not select a code.",
			"tipoDonativovazio": "You have not selected a donation type",
			"niftipoDonativoAlredyExists": "There is already a taxpayer number with the type of donation selected",
			"diplomavazio": "Did not select a degree",
			"nIdentificacaovazio": "You did not enter the identification number of the work.",
			"datavazia": "The date cannot be empty.",
			"regiaovazio": "You must select a region.",
			"caevazio": "You must select a CAE code.",
			"regeiaocaeAlredyExists": "A region with the CAE code already exists.",
			"tipovazio": "You must select a Type.",
			"numProjectovazio": "You must indicate a project number/incentive code.",
			"rendimentovalido": "You must enter an income.",
			"tvalido": "The number of years must be greater than zero.",
			"freguesiavazio": "You must indicate a parish code.",
			"designacaovazio": "The designation cannot be empty.",
			"montantevazio": "The amount of surplus value must be greater than zero.",
			"prejuizoAlredyExists": "The year indicated already has a tax loss attributed.",
			"dadosguardados": "Data saved successfully!",
			"registosdel": "Model22 deleted successfully.",
			"temDeGuardar": "You must save the data to be able to create the file",
			"avisoAnexos": "Are you sure you want to delete the attachment <strong>\"{{attachment}}\"</strong>?",
			"mod22calculado": "Calculated model",
			"containicialAlredyExists": "The initial account is already set up!",
			"derramaErrorMessage": "Municipal Spill.",
			"anoAlredyExists": "The year indicated in the breakdown already exists",
			"anoVazio": "You must indicate the year of investment",
			"prejEmpty": "Damage cannot be null or 0.",
			"cleanValuesInfo": "Do you want to cancel the values ​​entered manually?",
			"cleanValuesTooltip": "Cancels manually entered values ​​so you can start the process over again",
			"anoDedInv": "The year of the deduction does not have to be greater than the year of the tax loss.",
			"servicoAT": {
				"validarAtSuccess": "Declaration validated successfully",
				"validarAtFailed": "Error validating the declaration in AT",
				"submeterAtSuccess": "Successfully submitted statement",
				"submeterAtFailed": "Error when submitting the declaration on AT"
			},
			"modalSubmeterAT": {
				"title": "Confirm sending the declaration again ignoring the warnings",
				"message": "The declaration only presents warnings, do you intend to send the declaration again ignoring the warnings?"
			},
			"contasdef": {
				"reposicaocomsucesso": "The account definitions for the fields were answers",
				"contascopiadascomsucesso": "The account definitions for the fields have been successfully copied to the selected companies"
			}
		},
		"errors": {
			"campo346": "Negative tax base!"
		},
		"prompt": {
			"exitTitle": "<i class='fa fa-exclamation-triangle text-warning' style='color: orange;'",
			"exitMessage": "Clicking OK will exit without saving and all changes you made will be deleted."
		}
	},
	"modelo25": {
		"title": "Model 25",
		"config": {
			"modalTitle": "Configuration — Accounts for Donations Received",
			"titleTableDonativosNumerarios": "Cash Donations — Income / Equity Accounts",
			"titleTableDonativosEspecie": "Donations in Kind — Income / Equity Accounts",
			"contaDe": "account",
			"contaAte": "Count up to",
			"alerts": {
				"success": "Configuration saved successfully!",
				"error": "There was a problem!",
				"hasEditData": "Note: There is still data to save!"
			}
		},
		"groups": {
			"q0": {
				"title": "Introduction",
				"p1": "Frame 00",
				"p2": "Donations Received",
				"p3": "Within the scope of the ancillary obligations of entities benefiting from donations, this model serves to comply with the legal provisions contained in paragraph c) of paragraph 1 of art.",
				"p4": "Compliance with this tax obligation must be completed by completing and sending this form by electronic transmission, by the end of February of each year, referring to donations received in the previous year."
			},
			"q1": {
				"title": "Declarant's tax identification number",
				"campoNifDeclarante": "Declarant's tax identification number",
				"campoNomeDeclarante": "Name of the taxpayer"
			},
			"q2": {
				"title": "Years of donations",
				"ano": "Year"
			},
			"q3": {
				"title": "Code of the financial service of the headquarters or domicile",
				"codDeServico": "Finance service code"
			},
			"q4": {
				"title": "Declaration type",
				"radioTipoDeclaracao": {
					"primeira": "First",
					"sub": "Replacement"
				}
			},
			"q5": {
				"title": "List of donor entities and donations",
				"dataGridRegistoList": {
					"colunas": {
						"entidade": "Donor entity",
						"codigoDonativo": "Donation code",
						"valorNum": "Cash donation value",
						"valorEsp": "Value of donation in kind",
						"donativoStr": "Donation type",
						"linhas": {
							"periodo": "Period",
							"nDiario": "Daily",
							"nDocInterno": "Doc no.",
							"nConta": "Account no.",
							"nomePOC": "Account name",
							"valor": "Value"
						}
					}
				}
			}
		},
		"errors": {
			"errorTipo": "Error",
			"errorHeader": "The current model 25 has the following errors.",
			"errorCodigoDonMensagem": "Error in the donation code field for entity {{entidade}}",
			"errorCodigoDonRequired": "Field is mandatory",
			"errorCodigoDonName": "Donation code",
			"errorNoDataList": "* It is mandatory to contain data to create the file.",
			"errorValoresMensagem": "Error in one of the value fields of entity {{entidade}}",
			"errorValoresRequired": "At least one of the fields is mandatory",
			"errorDadosInseridos": "There is at least one error in the data inserted into table {{datagrid}}",
			"errorContasOverlapping": "There is an overlap between the 'accounts from' and 'accounts to' in the grids",
			"errorOnlyDigitsAllowed": "Only digits are allowed as values ​​in the 'account from' and 'count to' fields"
		},
		"helpers": {
			"naoExistemContasConfiguradas": "There are no accounts configured to upload data.",
			"clickConfig": "<a>Click here to configure.</a>"
		},
		"servicoAT": {
			"validarAtSuccess": "Declaration validated successfully",
			"validarAtFailed": "Error validating the declaration in AT",
			"submeterAtSuccess": "Successfully submitted statement",
			"submeterAtFailed": "Error when submitting the declaration on AT"
		},
		"global": {
			"btns": {
				"btnRefresh": "To update",
				"btnCriarFicheiro": "Create File",
				"btnSubmeterAt": "Submit AT",
				"btnValidarAt": "Validate on AT",
				"btnConfigWS": "WS Settings"
			}
		},
		"modal": {
			"configws": {
				"title": "WebService Settings",
				"username": "Contributor username",
				"password": "Taxpayer password",
				"servicoInvCC": "Service invoked by Certified Accountant (CC)",
				"ccPowers": "CC with full declarative powers for Contrib.",
				"usernameCC": "CC username",
				"passwordCC": "CC password",
				"configured": "<i class='fa fa-check' aria-hidden='true'></i> The service is already configured.",
				"notConfigured": "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> The service is not configured.",
				"configsaved": "Configuration saved successfully."
			}
		}
	},
	"modelo30": {
		"title": "Model 30",
		"groups": {
			"q1": {
				"title": "Tax Identification Number",
				"c01": "Tax identification number of the reporting entity"
			},
			"q2": {
				"title": "Tax identification number of the certified accountant / Fair impediment",
				"c02": "Certified accountant's tax identification number",
				"casoJustoImpedimento": "If there was a fair impediment (article 124 of DL nº 452/99, of November 5th), indicate:",
				"c02a": "Fact that determined the fair impediment",
				"c02b": "Date of occurrence of the fact"
			},
			"q3": {
				"title": "Year month",
				"c03": "Year",
				"c03a": "Month"
			},
			"q4": {
				"title": "Code of the finance service of the headquarters or tax domicile",
				"c04": "Code of the finance service of the headquarters or fiscal domicile"
			},
			"q5": {
				"title": "Declaration type",
				"c05": "Declaration data / declaration type",
				"primeira": "First",
				"sub": "Replacement"
			},
			"q6": {
				"title": "Summary of amounts withheld",
				"c06": "Dividends or Profits Derived from Shareholdings",
				"c07": "Interest or Income Derived from the Investment of Capital",
				"c08": "Royalties",
				"c09": "Work dependent",
				"c10": "Independent Work",
				"c11": "Commissions",
				"c12": "Buildings",
				"c13": "Pensions",
				"c14": "Service Provisions",
				"c40": "Public Remunerations",
				"c41": "Public Pensions",
				"c16": "Others",
				"c17": "Total",
				"dataGrid": {
					"nomeImportancia": "Importance name",
					"total": "Total"
				}
			},
			"q7": {
				"title": "List of payment slips",
				"dataGrid": {
					"nGuia": "Payment guide no.",
					"valorTotal": "Total value of the guide"
				}
			},
			"q8": {
				"title": "List of income beneficiaries",
				"dataGrid": {
					"nif": "Portuguese NIF",
					"nifPais": "Nif country residence",
					"codPais": "Country code of residence",
					"partCapD": "Part.",
					"partCapS": "Part.",
					"rendTipo": "Yield",
					"rendValor": "Yield",
					"tribCod": "Trib.",
					"tribTaxa": "Trib.",
					"guia": "Payment guide number",
					"montante": "Imp. amount",
					"nifEntidade": "Nif ent.",
					"legends": {
						"existeLinhasParaMesmoContribuinte": "There are multiple lines for the same taxpayer"
					}
				}
			}
		},
		"verificarLancamentos": {
			"title": "Check possible releases",
			"contribuintesNaoPTComRetencao": "Non-PT taxpayers with withholding",
			"contribuintesNaoPTSemRetencao": "Non-PT taxpayers without withholding",
			"mensagens": {
				"success": "Launches successfully applied in table 8",
				"semDados": "No records of possible launches were found"
			}
		},
		"errors": {
			"errorTipo": "Error",
			"errorHeader": "The current model 30 has the following errors.",
			"q08MontandeSemGuias": "There are lines with retained value where the guide number was not filled in!",
			"q08LinhasDuplicadas": "There are lines with the same contributor, when the file is generated they will be grouped together.",
			"q08": "Table 8",
			"q08WithoutData": "It is not properly filled out.",
			"validarFailed": "Error validating declaration",
			"linhaSemNif": "Selected line does not contain a NIF to edit",
			"pais": "Country",
			"paisMessage": "Country code must contain 3 digits.",
			"sf": "Finance service",
			"sfMessage": "You must indicate the finance service code"
		},
		"servicoAT": {
			"validarAtSuccess": "Declaration validated successfully",
			"validarAtFailed": "Error validating the declaration in AT",
			"submeterAtSuccess": "Successfully submitted statement",
			"submeterAtFailed": "Error when submitting the declaration on AT"
		},
		"btns": {
			"verificarLancamentos": "Check possible releases",
			"btnRepor": "Load data by default",
			"btnRepFinancas": "Finance department"
		}
	},
	"modelo39": {
		"title": "Model 39",
		"initModal": {
			"title": "Confirmation",
			"message": "Do you want to generate the Model 39 file with salary processing data for the year '{{year}}' of the capital income type?"
		},
		"groups": {
			"q0": {
				"title": "Start",
				"p2": "General Indications",
				"p3": "Model declaration no. 39 is mandatory for debtor entities and entities that pay or make available to their respective holders natural persons resident in Portuguese territory and who do not benefit from exemption, exemption from withholding or tax reduction, income to which refers to article 71 of the Personal Income Tax Code or any income subject to definitive withholding at source.",
				"p4": "The identification of taxpayers whose total income, for all codes, is equal to or less than €25.00 is not required."
			},
			"q1": {
				"title": "Declarant's tax identification number",
				"campoNifDeclarante": "Tax Identification Number"
			},
			"q2": {
				"title": "CC tax identification number / Fair impediment",
				"q02C02": "Tax Identification Number",
				"q02Caso": "If there was a fair impediment (article 12-A of DL no. 452/99, of November 5), indicate:",
				"q02C06": "Fact that determined the fair impediment",
				"q02C07": "Date of occurrence of the fact",
				"q02C08": "Date of cessation of the event"
			},
			"q3": {
				"title": "Year",
				"q03C03": "Year"
			},
			"q4": {
				"title": "Code of the financial service of the headquarters or domicile",
				"q04C04": "Finance service code"
			},
			"q5": {
				"title": "Declaration type",
				"radioTipoDeclaracao": {
					"primeira": "First",
					"sub": "Replacement"
				}
			},
			"q6": {
				"title": "List of income holders",
				"dataGridRegistoList": {
					"colunas": {
						"nif": "6.1 - NIF of the Income Holder",
						"codRend": "6.2 - Income Code",
						"rendimento": "6.3 - Amount of Income",
						"retido": "6.4 - Amount of IRS Withheld",
						"nifEmitente": "6.5 - NIF of Issuing Entity"
					},
					"extras": {
						"ajuda": "Consult income code help",
						"ajudaTitle": "Income code help",
						"cod": "Code"
					}
				}
			}
		},
		"codigosJustoImpedimento": {
			"01": "Death of a spouse not separated from persons and property, of a person with whom they live in conditions similar to those of their spouses, or of a relative or similar in the 1st degree of the straight line",
			"02": "Death of another relative or related person in the direct line or in the 2nd degree of the collateral line",
			"03": "Serious and sudden illness or hospitalization of the accountant, which makes it absolutely impossible for him to fulfill his obligations, or situations of childbirth or urgent and essential assistance to a spouse or person living in a civil union or common economy and a relative or similar in the 1st degree of the straight line, in case of illness or accident of these",
			"04": "Parenting Situations"
		},
		"codigosRendimentos": {
			"10": {
				"1001": "Income paid by retirement savings funds that do not benefit from any exclusion - paragraph 5 of article 21 of the Tax Benefits Statute (1st part)."
			},
			"11": {
				"1101": "Income from participation units in venture capital funds, real estate investment funds or shareholdings in real estate investment companies - forestry resources - and in urban rehabilitation real estate investment funds - articles 23, 24 and 71 of the Tax Benefits Statute ."
			},
			"12": {
				"1201": "Transitional Regime (before January 1, 1991 and after this date until December 31, 1994) - Positive difference between the amounts paid as redemption, advance or maturity of insurance and operations in the \"Life\" sector and complementary regimes that benefit exclusion from taxation of all income for contracts concluded before 1/1/91 and for contracts concluded between 1 January 1991 and 31 December 1994 - paragraph b) of paragraph 3 of article 5 of the IRS Code - drafting of Decree-Law no. 267/91, of 6 August."
			},
			"13": {
				"1301": "Transitional Regime (January 1, 1991 to December 31, 1994) - Positive difference between the amounts paid as redemption, advance or maturity of insurance and operations in the \"Life\" sector and complementary regimes that benefit from the exclusion of taxation of 1 /2 - subparagraph a) of no. 3 of article 5 of the IRS Code (as amended by Decree-Law no. 267/91, of August 6)."
			},
			"14": {
				"1401": "Transitional Regime (January 1, 1995 to December 31, 2000) - Positive difference between the amounts paid as redemption, advance or maturity of insurance and operations in the \"Life\" sector and complementary regimes that benefit from the exclusion of 2 /5 - subparagraph a) of no. 3 of article 5 of the IRS Code (as amended by Law no. 39-B/94, of December 27)."
			},
			"15": {
				"1501": "Transitional Regime (January 1, 1995 to December 31, 2000) - Positive difference between the amounts paid as redemption, advance or maturity of insurance and operations in the \"Life\" sector and complementary regimes that benefit from the exclusion of 4 /5 - paragraph b) of paragraph 3 of article 5 of the IRS Code (as amended by Law no. 39-B/1994, of December 27)."
			},
			"16": {
				"1601": "Transitional Regime (Plans concluded until 31.12.2005) - The amounts paid by retirement savings funds, PPE and PPR/E that benefit from the exclusion of 4/5 - article 21, no. 3, paragraph b) no. 1 of the Tax Benefits Statute, as set out in article 55, no. 3 of Law no. 60-A/2005, of 30 December."
			},
			"17": {
				"1701": "Income referred to in codes 01, 03, 19 to 31 and 33 when paid or made available in accounts opened in the name of one or more holders but on behalf of unidentified third parties (except when the beneficial owner is identified) - paragraph a ) of paragraph 12 of article 71 of the IRS Code."
			},
			"18": {
				"1801": "Income paid or made available to the respective holders, resident in Portuguese territory, owed by non-resident entities without a permanent establishment in Portuguese territory and which are domiciled in a country, territory or region subject to a clearly more favorable tax regime, through entities that are mandated by debtors or holders or act on behalf of one or the other - subparagraph c) of no. 12 of article 71 of the IRS Code (previous no. 13 of the same article)."
			},
			"19": {
				"1901": "Interest and other forms of remuneration arising from loan agreements, credit opening, repo and others that provide, for consideration, the temporary availability of money or other fungible things - subparagraph a) of paragraph 2 of article 5 of the IRS Code - applicable to 2015 and subsequent years."
			},
			"20": {
				"2001": "Income from temporary assignment contracts, when not earned by the original holder, of intellectual and industrial property rights, or the provision of information based on experience acquired in the industrial, commercial or scientific sector, as well as those derived from technical assistance - paragraph m) of the No. 2, article 5 of the IRS Code - applicable to 2015 and subsequent years."
			},
			"21": {
				"2101": "Income derived from the use or concession of the use of industrial, commercial or scientific agricultural equipment, when they do not constitute property income, as well as income from the provision, sporadic or continuous, of computer equipment and networks, including the transmission of data or provision of capacity IT installed in any of its possible forms - paragraph n) of paragraph 2, article 5 of the IRS Code - applicable to 2015 and following years."
			},
			"22": {
				"2201": "Interest balances calculated in a contract or posted to a current account - paragraphs f) and o), paragraph 2 of article 5 of the IRS Code - applicable to 2015 and following years."
			},
			"23": {
				"2301": "Interest, due to delay or delay in payment, with the exception of those owed to the State and other public entities - paragraph g) of paragraph 2 of article 5 of the IRS Code - applicable to 2015 and following years."
			},
			"24": {
				"2401": "Gains arising from interest rate swap operations - paragraph q) of paragraph 2 of article 5 of the IRS Code - applicable to 2015 and subsequent years."
			},
			"25": {
				"2501": "Remuneration for certificates that guarantee the holder the right to receive a minimum value higher than the subscription value - paragraph r) of paragraph 2 of article 5 of the IRS Code - applicable to 2015 and following years."
			},
			"26": {
				"2601": "Compensation aimed at compensating for losses in category E income - paragraph s) of paragraph 2 of article 5 of the IRS Code - applicable to 2015 and following years."
			},
			"27": {
				"2701": "Amounts paid or made available to the taxpayer by fiduciary structures, when such amounts are not associated with their liquidation, revocation or extinction, and have not already been taxed - paragraph t) of paragraph 2 of article 5 of the Code of IRS - applicable to 2015 and subsequent years."
			},
			"28": {
				"2801": "Income distributed from participation units in securities investment funds or shareholdings in securities investment companies to which the regime provided for in subparagraph i) of paragraph a) of no. 1 of article 22-A of the Tax Benefits Statute is applicable-- applicable from 1 July 2015."
			},
			"29": {
				"2901": "Income distributed from participation units in real estate investment funds or shareholdings in real estate investment companies to which the regime provided for in paragraph i) of paragraph a) of paragraph 1 and paragraph 13 of article 22 applies. º-A of the Tax Benefits Statute -- applicable from July 1, 2015."
			},
			"30": {
				"3001": "Redemption and liquidation of participation units in securities investment funds or shares in securities investment companies, to which the regime provided for in paragraph b) of paragraph 1 of article 22-A of the Tax Benefits Statute applies - applicable from July 1, 2015."
			},
			"31": {
				"3101": "Redemption and liquidation of participation units in real estate investment funds or shares in real estate investment companies, to which the regime provided for in paragraph b) of paragraph 1 of article 22-A of the Tax Benefits Statute applies - applicable from July 1, 2015."
			},
			"32": {
				"3201": "Income from wasteland - paragraph 4 of article 59 of the Tax Benefits Statute."
			},
			"33": {
				"3301": "Other income derived from the simple investment of capital not included in the previous paragraphs - paragraph p) of paragraph 2 of article 5 of the IRS Code - applicable to 2015 and following years."
			},
			"34": {
				"3401": "Gross profits made available to taxpayers who hold a shareholding in companies that meet the condition set out in article 35 of the Commercial Companies Code, in favor of which they made capital contributions in cash (article 43-B of the Tax Benefits Statute)."
			},
			"35": {
				"3501": "Income distributed within the scope of forest resource management by forest management entities (EGF) and forest management units (UGF) - paragraphs 2 and 14 of article 59-G of the Tax Benefits Statute."
			},
			"desc": "See help for details",
			"01": {
				"0101": "Profits and reserves made available to members or holders and advances on account of profits owed by resident entities (includes dividends) - paragraph h) of paragraph 2 of article 5 of the IRS Code, with the exception of profits identified with code 34.",
				"0102": "Income resulting from sharing qualified as investment of capital (applicable to 2013 and previous years).",
				"0103": "The value attributed to associates in the amortization of shares without capital reduction - paragraph i) of paragraph 2 of article 5 of the IRS Code.",
				"0104": "The income earned by the member in the participation association and in the quota association, as well as, in the latter, the income referred to in subparagraphs h) and i) of no. 1 of article 5 of the IRS Code earned by the member after deduction the benefit owed to the member."
			},
			"02": {
				"0201": "Income from securities paid or made available to their respective holders, resident in Portuguese territory, owed by entities that are not domiciled in Portuguese territory to which the payment can be attributed, through entities that are mandated by debtors or holders or act on behalf of one or another - paragraph b) of paragraph 1 of article 71 of the IRS Code."
			},
			"03": {
				"0301": "Interest and other forms of remuneration on current or time deposits, as well as certificates of deposits and price-guaranteed securities accounts or other similar or related operations - paragraph b) of paragraph 2 of article 5 of the IRS Code .",
				"0302": "Interest, amortization or reimbursement premiums and other remuneration on debt securities, bonds, participation securities, consignment certificates, cash bonds or other similar securities and other financial investment instruments - paragraph c) of paragraph 2 of article 5 of the Code from the IRS.",
				"0303": "Interest and other forms of remuneration for supplies, allowances or capital advances made by partners to the company - paragraph d) of paragraph 2 of article 5 of the IRS Code.",
				"0304": "Interest and other forms of remuneration due due to the fact that partners do not withdraw the profits or remuneration made available to them - paragraph e) of paragraph 2 of article 5 of the IRS Code.",
				"0305": "Income from repo operations and credit assignments - applicable to 2014 and previous years.",
				"0306": "Gains arising from swap operations or forward foreign exchange operations - applicable to 2014 and previous years."
			},
			"04": {
				"0401": "Positive difference between the amounts paid as redemption, advance or maturity of insurance and operations in the \"Life\" sector and other complementary schemes that do not benefit from exclusion - paragraph 3 of article 5 of the IRS Code.",
				"0402": "Income from long-term savings or share savings plans that do not benefit from exclusion - paragraph 3 of article 5 of the IRS Code (by reference to articles 20-A and 26 of the Tax Benefits Statute ) and article 25 of the Tax Benefits Statute."
			},
			"05": {
				"0501": "Positive difference between the amounts paid as redemption, advance or maturity of insurance and operations in the \"Life\" sector and other complementary regimes that benefit from the exclusion of 1/5 taxation - paragraph a) of paragraph 3 of article 5. º of the IRS Code.",
				"0502": "Income from long-term savings or share savings plans that benefit from exclusion from taxation of 1/5 - paragraph a) of paragraph 3 of article 5 of the IRS Code (by reference to articles 20-A and 26 of the Tax Benefits Statute) and article 25 of the Tax Benefits Statute."
			},
			"06": {
				"0601": "Positive difference between the amounts paid as redemption, advance or maturity of insurance and operations in the \"Life\" sector and complementary schemes that benefit from the exclusion of 3/5 taxation - paragraph b) of paragraph 3 of article 5. of the IRS Code.",
				"0602": "Income from long-term savings or share savings plans that benefit from exclusion from taxation of 3/5 - paragraph b) of paragraph 3 of article 5 of the IRS Code (by reference to articles 20-A and 26 of the Tax Benefits Statute) and article 25 of the Tax Benefits Statute."
			},
			"07": {
				"0701": "Income paid by retirement savings funds, including those made on an installment basis, over a period of more than ten years, which benefit from a 3/5 exclusion - subparagraph b) of paragraph 3 of article 21 of the Tax Benefits Statute."
			},
			"08": {
				"0801": "Income paid by retirement savings funds that benefit from exclusion from income taxation of 1/5 - no. 5 of article 21 of the Tax Benefits Statute and subparagraph a) of no. 3 of article 5 of the IRS Code."
			},
			"09": {
				"0901": "Income paid by retirement savings funds that benefit from the exclusion of 3/5 income taxation - no. 5 of article 21 of the Tax Benefits Statute and subparagraph b) of no. 3 of article 5 of the IRS Code."
			}
		},
		"errors": {
			"errorTipo": "Error",
			"errorNoDataList": "* It is mandatory to contain data to create the file.",
			"errorHeader": "The current model 39 has the following errors."
		},
		"servicoAT": {
			"validarAtSuccess": "Declaration validated successfully",
			"validarAtFailed": "Error validating the declaration in AT",
			"submeterAtSuccess": "Successfully submitted statement",
			"submeterAtFailed": "Error when submitting the declaration on AT"
		}
	},
	"moeda": {
		"title_new": "New Currency",
		"title_detail": "Currency {{id}}",
		"title_edit": "Currency {{id}}",
		"title_plural": "Coins",
		"pesquisa": "To look for",
		"saved": "Currency {{id}}, saved successfully.",
		"error": "Currency {{id}}, cannot be saved.",
		"errorOnNew": "New Currency cannot be saved.",
		"deleted": "Currency {{id}}, successfully deleted.",
		"fields": {
			"codMoeda": "Currency code",
			"nomeMoeda": "Currency name",
			"abreviaturaMoeda": "Currency abbreviation",
			"extenso1Unidade": "Full unit (singular)",
			"extensoNUnidades": "Full unit (plural)",
			"extensoCentimos": "Cents in words",
			"nDecimais": "Number of decimal places",
			"dataReferencia": "Reference date",
			"exchange": "Exchange"
		},
		"tabs": {
			"activeExchange": "Active currency exchange",
			"otherExchange": "Other currency exchanges"
		},
		"titles": {
			"cambios": "Currency exchanges",
			"active": "Active",
			"cambioMoedaEmpresaEmMoedaEstrangeira": "Exchange company currency for foreign currency",
			"cambioMoedaEstrangeiraEmMoedaEmpresa": "Foreign currency exchange for company currency"
		},
		"errors": {
			"moedaNaoExiste": "The currency does not exist.",
			"naoPodeAlterarMoedaSemRemoverLinhas": "You cannot change the currency without first removing all lines from the business document."
		},
		"chooseTemplate": "Choose template",
		"invalidTemplate": "The chosen country does not contain a valid currency model.",
		"editCambio": "Edit currency exchange {{date}}",
		"choosecounty": "Copy template",
		"updateCambio": "Update exchange rate"
	},
	"moedaNormasIso": {
		"title": "ISO Currency Standards",
		"pesquisa": "Search ISO currency standards",
		"fields": {
			"codigo": "Code",
			"paisNomeMoeda": "Country (currency)",
			"nomeMoeda": "Currency name",
			"extensoUnidade": "Full unit (singular)",
			"extensoNUnidades": "Full unit (plural)",
			"extensoSubUnidade": "Full unit (sub)"
		}
	},
	"moradasFaturacao": {
		"title_detail": "Billing address {{id}}",
		"title_new": "New billing address",
		"title_plural": "Billing addresses",
		"pesquisa": "to look for",
		"saved": "Billing address {{id}}, saved successfully",
		"error": "Billing address {{id}}, cannot be saved.",
		"fields": {
			"nConta": "No.",
			"nome": "Name",
			"rua": "Road",
			"codPostal": "Postal code",
			"localidade": "Location",
			"nipc": "NIPC",
			"id": "Index",
			"observacoes": "Note",
			"morada": "Household",
			"codPais": "Country Code",
			"moralId": "Moral Id.",
			"nIdAltern": "Alternative ID"
		}
	},
	"morals": {
		"title_detail": "Alternative address",
		"title_new": "New alternative address",
		"title_plural": "Alternative addresses",
		"pesquisa": "To look for",
		"saved": "Alternative address, saved successfully.",
		"error": "Alternative address, cannot be saved.",
		"deleted": "Alternative address, successfully deleted.",
		"deleteModalText": "Delete record \"{{name}}\"?",
		"fields": {
			"nConta": "Account no.",
			"nome": "Name",
			"rua": "Road",
			"cPost": "Postal code",
			"localidade": "Location",
			"observacoes": "Comments",
			"morada": "Household",
			"codPais": "Country code",
			"desativado": "Disabled",
			"contactoNome": "Contact name",
			"contactoTelefone": "Contact phone",
			"codigoPontoPickup": "Pickup point code"
		}
	},
	"motivoContrato": {
		"data": {
			"semmotivo": "Without a reason",
			"temporariamenteimpedidoprestarserviço": "Temporarily prevented from providing service",
			"impendaaccaojuizoapreciacaolicitudedespedimento": "In relation to which action is required in court to assess the lawfulness of the dismissal",
			"situacaolicencasemretribuicao": "Unpaid leave situation",
			"substituicaotrabalhadorportempoindeterminado": "Replacement of a full-time worker who starts working part-time for an indefinite period",
			"cctividadessazonais": "Seasonal activities",
			"acrescimoexcepcionalactividadeempresa": "Exceptional increase in the company's activity",
			"execucaotarefaserviçodefinidonaoduradouro": "Execution of an occasional task or specific service that is precisely defined and not lasting",
			"execucaoprojectoactividadedefinidatemporaria": "Execution of a work, project or other defined and temporary activity",
			"lancamentonovaactividadeduraçãoincerta": "Launch of a new activity of uncertain duration, as well as the start of operation of a company or establishment",
			"contratacaotrabalhadoresprimeiroemprego": "Hiring workers looking for their first job or long-term unemployed",
			"actividadecontinuidadeousemtermo": "Continuity or open-ended activity",
			"outros": "Others"
		}
	},
	"movimentosaberto": {
		"btn": {
			"sendMail": "Send email",
			"processarexcel": "Process and export excel"
		},
		"modal": {
			"sendMail": {
				"title": "Send email: {{nAccount}} - {{nameAccount}}",
				"fields": {
					"email": "Email",
					"subject": "Subject",
					"body": "Text"
				}
			}
		},
		"label": {
			"grupoConta": "Account Group",
			"showGrupoConta": "Show account group",
			"movimentosEmAberto": "Open moves {{type}}"
		}
	},
	"movimentospendentes": {
		"clientes": {
			"nConta": "Account",
			"nome": "Name",
			"tipoDocumento": "Document Type",
			"numeroDocumento": "Document number",
			"dataDoc": "Date Doc.",
			"dataVencimento": "Due date",
			"valor": "Document's value",
			"valorRecebido": "Amount already received",
			"valorPorReceber": "Value to receive",
			"nif": "NIF",
			"nDocExterno": "External Doc. No."
		},
		"fornecedores": {
			"nConta": "Account",
			"nome": "Name",
			"tipoDocumento": "Document Type",
			"numeroDocumento": "Document number",
			"dataDoc": "Date Doc.",
			"dataVencimento": "Due date",
			"valor": "Document's value",
			"valorRecebido": "Amount already paid",
			"valorPorReceber": "Amount to be paid",
			"nif": "NIF",
			"nDocExterno": "External Doc. No."
		},
		"outrosDevedoresCredores": {
			"nConta": "Account",
			"nome": "Name",
			"tipoDocumento": "Document Type",
			"numeroDocumento": "Document number",
			"dataDoc": "Date Doc.",
			"dataVencimento": "Due date",
			"valor": "Document's value",
			"valorRecebido": "Amount already paid",
			"valorPorReceber": "Amount to be paid",
			"nif": "NIF",
			"nDocExterno": "External Doc. No."
		}
	},
	"nacionalidades": {
		"title_detail": "Nationality {{id}}",
		"title_new": "New Nationality",
		"title_plural": "Nationalities",
		"pesquisa": "To look for",
		"saved": "Nationality {{id}}, saved successfully.",
		"error": "Nationality {{id}}, cannot be saved.",
		"deleted": "Nationality {{id}}, successfully deleted.",
		"fields": {
			"nNacionalidade": "Code.",
			"codNacionQPessoal": "Q. Personal Nationality Code",
			"nomeNacionQPessoal": "Name Nationality Q. Personal",
			"designacaoBreve": "Brief Designation",
			"designacaoCompleta": "Full Designation"
		}
	},
	"naturezasContab": {
		"title_detail": "Accounting nature {{id}}",
		"title_new": "New accounting nature",
		"title_plural": "Accounting natures",
		"pesquisa": "To look for",
		"saved": "Accounting nature {{id}}, saved successfully.",
		"error": "Accounting nature {{id}}, cannot be saved.",
		"deleted": "Accounting nature {{id}}, successfully deleted.",
		"fields": {
			"idGrContaCorrente": "Accounting nature code.",
			"idGrContaCorrentePlaceholder": "accounting nature code",
			"idSubGrContaCorrente": "Accounting sub-nature code.",
			"idSubGrContaCorrentePlaceholder": "accounting sub-nature code",
			"descricao": "Description",
			"descricaoPlaceholder": "description of the accounting nature",
			"radical": "Radical",
			"radicalPlaceholder": "radical of the accounting nature e.g.: 21111",
			"nDigitosSequencia": "No.",
			"nDigitosSequenciaPlaceholder": "no.",
			"tipo": "Type"
		}
	},
	"nifs": {
		"title_detail": "NIF {{id}}",
		"title_new": "New NIF",
		"title_plural": "Tax Identification Numbers",
		"pesquisa": "To look for",
		"saved": "NIF {{id}}, saved successfully",
		"error": "NIF {{id}}, cannot be saved.",
		"deleted": "NIF {{id}}, successfully deleted",
		"fields": {
			"codPais": "Country",
			"nContribuinte": "VAT Number",
			"codRet": "Retention rate",
			"tipoRetServNIF": "Entity usually",
			"temCativo": "Has captive VAT",
			"taxaCativo": "Captive VAT rate"
		},
		"modelo30": {
			"title": "Model 30",
			"fields": {
				"nifPt": "Portuguese NIF",
				"nifEmitente": "NIF ent.",
				"partCapD": "Part.",
				"partCapS": "Part."
			}
		},
		"registar": "Register NIF",
		"registado": "NIF {{nif}} was successfully registered",
		"naoregistado": "The NIF is not registered",
		"desejaregistar": "Do you want to register the NIF?"
	},
	"nivql": {
		"title_detail": "Qualification level {{id}}",
		"title_new": "New qualification level",
		"title_plural": "Qualification levels",
		"pesquisa": "To look for",
		"saved": "Qualification level {{id}}, saved successfully",
		"error": "Qualification level {{id}}, cannot be saved.",
		"deleted": "Qualification level {{id}}, successfully deleted",
		"fields": {
			"nNivQualific": "Qualification level code",
			"designaBreve": "Brief designation",
			"designaComp": "Full designation",
			"ruNivQualific": "Qualification level"
		},
		"niveisqualificacao": {
			"0": "Without definition",
			"1": "Superior frames",
			"2": "Medium Frames",
			"3": "Supervisors, foremen, masters and team leaders",
			"4": "Highly qualified professionals",
			"5": "Qualified professionals",
			"6": "Semi-qualified (specialized) professionals",
			"7": "Unqualified (undifferentiated) professionals",
			"8": "Interns, practitioners and apprentices"
		}
	},
	"noauthority": {
		"title": "Without authorization!",
		"message": "Contact your system administrator for more information."
	},
	"notificationcenter": {
		"title": "Notifications",
		"openAll": "See all",
		"new": "News",
		"old": "Previous",
		"image": "Image",
		"newNotification": "New ({{count}} notification)",
		"newNotifications": "New ({{count}} notifications)",
		"noNotifications": "There are no notifications",
		"categories": {
			"0": "All",
			"1": "News",
			"2": "Scheduled maintenance",
			"all": "All",
			"news": "News",
			"maintenance": "Scheduled maintenance"
		},
		"actions": {
			"readAll": "Mark all as read"
		}
	},
	"obrigacoesAcessorias": {
		"title": "Accessory obligations",
		"modal": {
			"configws": {
				"title": "WebService Settings",
				"username": "Contributor username",
				"password": "Taxpayer password",
				"servicoInvCC": "Service invoked by Certified Accountant (CC)",
				"ccPowers": "CC with full declarative powers for Contrib.",
				"usernameCC": "CC username",
				"passwordCC": "CC password",
				"configured": "<i class='fa fa-check' aria-hidden='true'></i> The service is already configured.",
				"notConfigured": "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> The service is not configured.",
				"configsaved": "Configuration saved successfully."
			}
		}
	},
	"officereporting": {
		"todos": "All",
		"ano": "Year to analyze: {{value}}",
		"mes": "Month to analyze: {{nameMonth}}",
		"fields": {
			"pagina": "Maps"
		},
		"messages": {
			"erroDownload": "An error occurred while downloading the file!"
		}
	},
	"origemCondPagamento": {
		"pesquisa": "Origins of payment condition",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"tabela": "Table",
			"cliente": "Client"
		}
	},
	"origemContasRetencao": {
		"data": {
			"ambos": "Both",
			"porTerceiros": "By Third Parties (Clients)",
			"aTerceiros": "To Third Parties (Suppliers)"
		}
	},
	"origemDataVencimento": {
		"pesquisa": "Due date origins",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"tabela": "Table",
			"condicaoPagamento": "Cond."
		}
	},
	"pagamentoimprimir": {
		"label": {
			"emailfornecedor": "Supplier email",
			"enviar": "To send"
		},
		"email": {
			"required": "To send, please fill in the field \"<em>Supplier email</em>\"",
			"emailEnviado": "Email sent to {{email}}",
			"emailErro": "Error sending email to {{email}}."
		},
		"placeholder": {
			"email": "\"mail@example.com\" or \"mail@example.com; mail2@example.com\""
		},
		"btn": {
			"downloadSEPA": "Download SEPA file"
		}
	},
	"pagamentos": {
		"title_new": "New payment",
		"title_detail": "New payment {{id}}",
		"title_plural": "Payments",
		"pesquisa": "To look for",
		"saved": "Payment saved successfully.",
		"error": "Payment cannot be saved.",
		"fields": {
			"extPocCabID": "Id.",
			"numero": "Payment no.",
			"nome": "Supplier",
			"nconta": "Account",
			"data": "Date",
			"valor": "Total",
			"stampUpdate": "Last.",
			"origem": "Document origin",
			"abreviaturaMoeda": "Coin",
			"nDocExterno": "External Doc. No."
		},
		"pagamento": {
			"nCaixaAux": "Payment method",
			"nomeCaixaAux": "Name means payment",
			"tipoCaixaAux": "Half payment type",
			"valorNCaixa": "Value",
			"totalCaixa": "Delivered",
			"troco": "Change",
			"fornecedor": "Supplier",
			"data": "Pay day",
			"cab": {
				"extPocCabID": "header ID",
				"numero": "Number",
				"nConta": "Account",
				"descricao": "Description",
				"nome": "Name",
				"nif": "NIF",
				"data": "Date",
				"valor": "Value",
				"valorPlaceHolder": "Amount to pay",
				"stampUpdate": "Change date",
				"totalDescontos": "Full discounts",
				"totalPagar": "Total payable",
				"total": "Total",
				"temRetencao": "Has retention",
				"totalRetencao": "Full retention",
				"abreviaturaMoeda": "Coin"
			},
			"linhas": {
				"summary": "Summary",
				"nlanc": "line ID",
				"tipoDocumento": "Document Type",
				"numeroDocumento": "Document",
				"nDocExterno": "No.",
				"dataVencimento": "Expiration date",
				"valor": "Value",
				"valorPorPagar": "Amount to be paid",
				"percDesconto": "Discount %",
				"valorDesconto": "Discount value",
				"valorAPagar": "Amount to pay",
				"valorIVA": "VAT value",
				"montanteAReter": "Retention",
				"montanteRetido": "Retention value",
				"abreviaturaMoeda": "Coin",
				"cambio": "Exchange",
				"cambioOrigem": "Origin exchange rate",
				"cambioPagam": "Exchange payment",
				"dataDoc": "Doc date.",
				"dataDocExterno": "Doc date.",
				"descricao": "Description"
			},
			"meiosPagamento": {
				"nCaixa": "Payment method",
				"nomeCaixa": "Payment method name",
				"valor": "Value",
				"tipo:": "Type of payment method"
			},
			"disableRevoke": "The payment cannot be canceled because it was not made in CentralGest Cloud",
			"pdf": "PDF",
			"revoke": "Cancel",
			"date": "Date",
			"summary": "Summary",
			"totalPago": "Total paid",
			"downloadSepa": "Download SEPA",
			"generateAndDownloadSepa": "Generate SEPA"
		},
		"info": {
			"anular": "Payment canceled successfully!",
			"anularpagamento": "Cancel payment",
			"temCertezaAnularpagamento": "Are you sure you want to delete the payment?",
			"pagamentoPorMeioPagamSepa": "Payment made via SEPA payment.",
			"avisoAnularPagamSepa": "Please note that this will cancel a payment made via SEPA payment and continuing will delete the SEPA transfer file which may contain other payments.",
			"semMovimentos": "No open moves!"
		},
		"erros": {
			"notselected": "No payment selected!",
			"anular": "Error canceling payment!",
			"valorDescontoMaiorValor": "Discount value higher than the amount received",
			"percentagemDescontoMaior100": "The discount percentage cannot exceed 100%",
			"moedasdiferentesnosrecibos": "It is not possible to have different currencies in the payments to be posted",
			"errodesconhecido": "Unknown error",
			"invalidHeaderDate": "The payment date cannot be more than 1 year from the current date"
		},
		"text": {
			"autoDistribute": "Automatically distribute value",
			"amountToPay": "Amount to pay:"
		},
		"list": {
			"gestaoFicheirosSepa": "SEPA file management",
			"notifyMessage": "Do you want to notify suppliers regarding selected payments?",
			"notifyTitle": "Send notifications"
		},
		"saveModal": {
			"title": "Save payment",
			"paymentMean": "Payment method",
			"paymentValue": "Payment method value",
			"payInFull": "Pay in full",
			"clearValue": "Clear value",
			"document": "Document",
			"save": "Save",
			"saveCheck": "Save and view PDF",
			"saveGenerateSEPA": "Save and create SEPA",
			"saveGestaoSEPA": "Save in SEPA Management",
			"erros": {
				"naoDefiniuMeioPagamento": "You have not defined the payment method.",
				"naoSatisfazValorDoc": "The value delivered does not satisfy the value of the document.",
				"trfSepaSoPodeTerUmMeio": "\"Bank Transfer\" payment via SEPA file can only have one defined payment method.",
				"trfSepaMeioPagamentoNaoValido": "The defined payment method is not of the \"Bank Transfer\" type.",
				"trfSepaMeioPagamentoSemIban": "The payment method does not have an IBAN defined.",
				"trfSepaMeioPagamentoSemSwift": "The payment method does not have SWIFT defined."
			},
			"success": {
				"saveGestaoSepa": "Saved in SEPA File Management, you can generate it later."
			}
		},
		"btn": {
			"pagaTudo": "Pay everything",
			"limpaTudo": "Clear all paid amounts",
			"distribuirValores": "Automatically distribute value",
			"limpaValor": "Clear amount paid"
		}
	},
	"paises": {
		"title_detail": "Country {{id}}",
		"title_new": "New Country",
		"title_plural": "Countries",
		"pesquisa": "To look for",
		"saved": "Country {{id}}, saved successfully",
		"error": "Country {{id}}, cannot be saved.",
		"deleted": "Country {{id}}, deleted successfully",
		"fields": {
			"codPais": "Country Code",
			"nomePais": "Name",
			"abrevFiscal": "Tax abbreviation",
			"abrevPais": "Country Abbreviation",
			"nomePaisPropriaLingua": "Name of the country in your own language",
			"codMoeda": "Currency Code",
			"codLingua": "Language Code",
			"mercado": "Market",
			"codISO": "International Code"
		},
		"mercado": {
			"data": {
				"intraComuni": "Intra Community",
				"extraComuni": "Community Extra"
			}
		}
	},
	"paisesISO3166": {
		"title": "ISO 3166 Countries",
		"pesquisa": "Search ISO 3166 countries",
		"fields": {
			"codigo": "Code.",
			"designacaoPT": "Designation (PT)",
			"codigoAlpha2": "ISO3166 Alpha-2",
			"codigoAlpha3": "ISO3166 Alpha-3",
			"designacao": "First name language"
		}
	},
	"partners": {
		"millennium": {
			"title": "Millennium",
			"erros": {
				"invalidTargetOrigin": "An error occurred loading the settings.",
				"invalidUsernameOrPassword": "Please fill in the authentication fields.",
				"invalidRecaptcha": "Please check the checkbox to confirm that it is not a machine.",
				"partnerAssociateInvalidToken": "An error occurred when trying to associate your company.",
				"partnerAssociateUserNotFound": "Authentication failed.",
				"noCompaniesWereFoundWithTheProvi": "No company was found with the NIF provided.",
				"partnerIsActivePleaseSetUnactive": "There is already an association for this company."
			}
		}
	},
	"pca": {
		"contabilidade": {
			"service": {
				"anos": "Years",
				"periodo": "Period",
				"encerrado": "closed"
			}
		},
		"empresagabinete": {
			"extratosconta": {
				"fields": {
					"nDiario": "Code.",
					"nomeDiario": "Daily",
					"nConta": "Customer code",
					"nome": "Customer name",
					"periodo": "Period",
					"nDocumento": "No.",
					"nDescritivo": "Descriptive code",
					"nomeDescritivo": "Descriptive name",
					"nDocExterno": "No.",
					"dataLancamento": "Release date",
					"valorDebito": "Debt",
					"valorCredito": "Credit",
					"valor": "Value",
					"dataDoc": "Doc date.",
					"saldo": "Balance"
				}
			},
			"movimentosaberto": {
				"fields": {
					"tipoDocumento": "Doc type.",
					"numeroDocumento": "No.",
					"dataVencimento": "Expiration Date",
					"valorPorReceber": "Amount to be received",
					"valorRecebido": "Amount received",
					"valorDesconto": "Discount value",
					"valorIVA": "VAT value",
					"valor": "Value",
					"nConta": "Customer code",
					"nome": "Customer name",
					"nif": "No."
				}
			}
		}
	},
	"perfilCategoriaAbono": {
		"title": "Profiles categories allowances",
		"fields": {
			"tipoArredondamento": "Rounding",
			"tipoRecolha": "Collection Type",
			"contribuiCalculoCustoPrecoH": "Contributes Price/Hour Cost Calculation",
			"codRem": "Rem. Code",
			"codUnidade": "Unit Defect",
			"tipoRendimento": "IRS Income Type",
			"contaMovDeb": "Allowance - Debit Account",
			"contaMovCred": "Allowance - Credit Account",
			"contaEncDEB": "Employer/Company Charges - Debit Account",
			"contaEncCRE": "Employer/Company Charges - Credit Account"
		},
		"crabdTable": {
			"fields": {
				"categoria": "Category",
				"empregado": "Ent.",
				"patronato": "Ent.",
				"desconto": "Discount",
				"nomeCategoria": "Category",
				"temIncidenciaEmpregado": "He has",
				"incidenciaEmpregado": "Incidence",
				"temIncidenciaPatronato": "He has",
				"incidenciaPatronato": "Incidence",
				"nDesconto": "Code.",
				"nomeDesconto": "Description"
			}
		}
	},
	"perfilCategoriaDesconto": {
		"title": "Profiles categories discounts",
		"fields": {
			"categoria": "Category",
			"tipoArredondamento": "Rounding",
			"tipoRecolha": "Collection Type",
			"contribuiCalculoCustoPrecoH": "Contributes Price/Hour Cost Calculation",
			"codRem": "Rem Code",
			"codUnidade": "Unit Defect",
			"tipoRendimento": "Yield Type",
			"contaMovDeb": "Discount - Debit Account",
			"contaMovCred": "Discount - Credit Account",
			"contaEncDEB": "Employer/Company Charges - Debit Account",
			"contaEncCRE": "Employer/Company Charges - Credit Account"
		},
		"crabdTable": {
			"fields": {
				"categoria": "Category",
				"empregado": "Ent.",
				"patronato": "Ent.",
				"desconto": "Discount",
				"nomeCategoria": "Category",
				"temIncidenciaEmpregado": "He has",
				"incidenciaEmpregado": "Incidence",
				"temIncidenciaPatronato": "He has",
				"incidenciaPatronato": "Incidence",
				"nDesconto": "Code.",
				"nomeDesconto": "Description"
			}
		}
	},
	"periocidadeContratoTipo": {
		"data": {
			"sempre": "Ever",
			"diario": "Daily",
			"semanal": "Weekly",
			"quinzenal": "Fortnightly",
			"mensal": "Monthly",
			"bimensal": "Bimonthly",
			"trimestral": "Quarterly",
			"semestral": "Semiannual",
			"anual": "Yearly"
		}
	},
	"periodos": {
		"title_detail": "Accounting period {{id}}",
		"title_plural": "Accounting Periods",
		"pesquisa": "To look for",
		"fields": {
			"periodo": "Period",
			"periodoPlaceholder": "Accounting period",
			"nome": "Name",
			"nomePlaceholder": "Accounting period name"
		}
	},
	"periodosIVA": {
		"data": {
			"mensal": "Monthly",
			"trimestral": "Quarterly"
		}
	},
	"planoscontasalternativos": {
		"title_detail": "Alternative account plan \"{{id}}\"",
		"title_new": "New alternative account plan",
		"title_plural": "Alternative account plans",
		"pesquisa": "To look for",
		"saved": "Alternative chart of accounts, saved successfully.",
		"error": "Alternative account plan cannot be saved.",
		"deleted": "Alternative account plan, successfully deleted",
		"fields": {
			"nome": "Name",
			"tipoValidacao": "Validation type",
			"contas": "Accounts",
			"nconta": "Account No.",
			"ncontaEdit": "Alternative account number",
			"nomeEdit": "Alternative account description",
			"tipoConta": {
				"title": "Account type",
				"movimento": "Movement",
				"subtotal": "Subtotal",
				"razao": "Reason",
				"classe": "Class"
			},
			"contasradicais": "Accounts/Radicals",
			"nradical": "Account No./Radical",
			"incluiexclui": "Includes/Excludes",
			"inclui": "Includes",
			"exclui": "Excludes",
			"contasalt": "Alternative accounts",
			"radicaisValidacao": "Radical validation",
			"escondeRubricasSemMovimento": "Hide non-moving headings",
			"inverteSinal": "Invert accounting sign",
			"inverteSinalInfo": "When activating this option, the values ​​will be displayed with a negative sign and the credit values ​​with a positive sign."
		},
		"actions": {
			"importPAlt": "Import alternative plan",
			"exportPlano": "Export this plan",
			"getModImportPAlt": "Get Alternative Plan Import Template"
		},
		"datasource": {
			"data": {
				"validaGeral": "General validation",
				"naoValida": "Not valid",
				"validaAnalitica": "Validates analytics",
				"validaRadicais": "Validates radicals"
			}
		},
		"tipoconta": {
			"0": "Movement",
			"1": "Subtotal",
			"2": "Reason",
			"3": "Class"
		},
		"messages": {
			"exportedsuccess": "Alternative plan exported successfully!",
			"modeloImportContasAlt": "Successful alternative plan import model obtained!",
			"errorFiles": "You must upload at least one file.",
			"importPlanoAlt": "Alternative plan imported successfully!",
			"notSelectPlanoContAlt": "You have not selected any alternative accounting plan."
		},
		"modals": {
			"conta": {
				"title": "Alternative account plan account",
				"ncontaemprty": "Account number cannot be empty.",
				"radicalInvalid": "Account No./radical value is invalid.",
				"radicalempty": "The Account No./radical cannot be empty."
			},
			"import": {
				"title": "Import alternative chart of accounts"
			},
			"cubo": {
				"btn": "Cube",
				"title": "Alternative account plan cube",
				"layoutCubo": {
					"caption": "Cube preview style",
					"saldoGeral": "General balance",
					"saldoCredDeb": "Credit/debit balance"
				},
				"fields": {
					"ncontaNome": "Account number - name",
					"nmesNome": "Number of month - name",
					"acumuladoDebito": "Accumulated debt",
					"acumuladoCredito": "Accumulated credit",
					"periodo": "Period",
					"nconta": "Account no.",
					"nome": "Name",
					"ano": "Year",
					"mes": "Month",
					"periodoDebito": "Debit period",
					"periodoCredito": "Credit period",
					"saldoDebito": "Debit balance",
					"saldoCredito": "Credit balance",
					"saldoGeral": "General balance",
					"nomeNConta": "Name - Account number"
				}
			},
			"extrato": {
				"titleExtrato": "Alternative chart of accounts statement",
				"titleAcumu": "Accruals from the alternative chart of accounts",
				"fields": {
					"nconta": "Account No.",
					"nome": "Name",
					"data": "Date",
					"periodo": "Period",
					"nDiario": "Daily No.",
					"nDocInterno": "Internal Doc. No.",
					"nDocExterno": "External Doc. No.",
					"documento": "Document",
					"debito": "Debt",
					"credito": "Credit",
					"saldo": "Balance"
				}
			}
		}
	},
	"pocs": {
		"title_detail": "Chart of accounts account {{id}}",
		"title_new": "New chart of accounts account",
		"title_plural": "Chart of Accounts",
		"pesquisa": "To look for",
		"saved": "Chart of accounts account {{id}}, saved successfully",
		"error": "Account from chart of accounts {{id}}, cannot be saved.",
		"deleted": "Chart of accounts account {{id}}, successfully deleted",
		"actions": {
			"maintenance": {
				"new": "Create new account",
				"edit": "Edit account"
			}
		},
		"fields": {
			"nConta": "Account No.",
			"nContaPlaceholder": "Account code",
			"nome": "Name",
			"nomePlaceholder": "Account name",
			"tipo": "Account type",
			"cc": "Have a current account",
			"nif": "VAT Number",
			"temCCusto": "Has analytics",
			"temIVA": "Has VAT control",
			"registaRetencao": "Register retention",
			"temRetencao": "Has retention",
			"bloqueada": "Blocked",
			"dpq06NCampo": "Periodic VAT Decree",
			"dpq06NCampoNome": "Periodic VAT Decree",
			"daq08NCampo": "Annual VAT Decree - Table 03 and 05",
			"daq08NCampoNome": "Annual VAT Decree - Table 03 and 05",
			"daq09NCampo": "Annual VAT Dec. - Table 04 and 05",
			"daq09NCampoNome": "Annual VAT Dec. - Table 04 and 05",
			"codControloIVADebito": "Debit VAT Control Code",
			"codControloIVADebitoNome": "Debit VAT Control Code",
			"codControloIVACredito": "VAT credit control code",
			"codControloIVACreditoNome": "VAT credit control code",
			"codTaxonomia": "Taxonomy Code"
		},
		"text": {
			"maintenancePocs": "Maintenance of Taxpayer numbers",
			"avisoAlteracaoCC1": "The account already has entries made in accounting.",
			"avisoAlteracaoCC2": "When carrying out this operation, all entries will remain open.",
			"avisoAlteracaoCC3": "Are you sure you want to continue?",
			"pretendeContinuar": "I found out and I intend to continue"
		},
		"list": {
			"dpq06": "Periodic Dec.",
			"daqAnual": "Annual Dec."
		},
		"tipocontacontab": {
			"0": "Movement",
			"1": "Subtotal",
			"2": "Reason",
			"3": "Class"
		},
		"validations": {
			"deConta": "The account entered cannot be greater than the account in the \"Until Account\" field",
			"ateConta": "The account entered cannot be smaller than the account in the \"From Account\" field"
		},
		"alerts": {
			"contaCorrente": "The account you are creating is a current account, this type of account must be created in the customer/supplier or other debtors and creditors records maintenance module."
		},
		"exportXls": "Export",
		"btns": {
			"copiaContasPlanoEmpresa": "Copy plan accounts for companies"
		}
	},
	"pocscopy": {
		"exportcontas": "Accounts_plan_accounts",
		"exportempresas": "available_companies",
		"exporterror": "Errors_accounts",
		"fields": {
			"nconta": "Account No.",
			"nome": "Name",
			"tipo": "Type",
			"temccusto": "There is C. Cost",
			"temiva": "Has VAT",
			"ncontade": "Account No.",
			"ncontaate": "until",
			"nEmpresaNome": "Company",
			"nContaNome": "Account",
			"erro": "Error"
		},
		"steps": {
			"intro": {
				"step": "Introduction",
				"title": "Welcome to the Intercompany Cons Plan Bill Copy Wizard.",
				"description": "This wizard will guide you through the process of copying accounts.",
				"description2": "You can cancel the operation at any time."
			},
			"contas": {
				"step": "Accounts",
				"title": "Choosing accounts to copy.",
				"descriptions": "Please select the accounts you want to copy."
			},
			"empresas": {
				"step": "Companies",
				"title": "Choice of companies.",
				"descriptions": "Please choose the companies you want to copy the account(s) to."
			},
			"relatorio": {
				"step": "Report",
				"title": "Configuration completed.",
				"descriptions": "Account(s) copied successfully.",
				"titleerror": "Errors when copying accounts",
				"descriptionserror": "There were errors copying the accounts, no changes were made."
			},
			"errorcontassel": "You have not selected any accounts to copy.",
			"errorempresassel": "You have not selected any company where you want to copy the account(s).",
			"success": ""
		},
		"messages": {
			"pesqsuccess": "Search carried out successfully."
		}
	},
	"portals": {
		"title_detail": "Portal {{id}}",
		"title_new": "New Portal",
		"title_plural": "Portals",
		"pesquisa": "To look for",
		"saved": "Portal {{id}}, saved successfully",
		"deleted": "Portal {{id}}, successfully deleted",
		"error": "Portal {{id}}, cannot be saved.",
		"fields": {
			"id": "id",
			"name": "Name",
			"description": "Description",
			"icon": "icon",
			"url": "Url",
			"data": "Portal configuration data",
			"sticky": "Fixed",
			"sortOrder": "Ordering",
			"roles": "Permissions",
			"includes": "Includes"
		},
		"actions": {
			"sort": "Sort portals"
		},
		"text": {
			"geral": "General",
			"noAccess": "You do not have access to this portal.",
			"editNotAllowed": "Cannot edit system portals",
			"deleteNotAllowed": "Cannot delete portals from the system",
			"forbiddenMessage": "There are users with access to this portal, if you delete it, existing access will also be deleted.",
			"showModuleName": "Show module name",
			"tooltipIncludes": "Users with access to this portal will also have access to the portals included",
			"sidebarTitle": "Title in sidebar"
		},
		"btn": {
			"copy": "Copy module name",
			"remove": "To remove",
			"edit": "To edit",
			"toggleVisibility": "Visible"
		},
		"items": {
			"erp": "Commercial",
			"financeiro": "Financial",
			"contabilidade": "Accounting",
			"ativos": "Active",
			"rh": "Human Resources",
			"pca": "My Accounting",
			"clientconnect": "Client Connect",
			"gestorrh": "HR Manager",
			"gestorservico": "Service Manager",
			"colaborador": "collaborator",
			"gestorementas": "Menu Manager",
			"configuracoes": "settings"
		},
		"module": {
			"newMenu": "New Menu",
			"menu": "Menu",
			"modulosdisponiveis": "Available modules"
		},
		"menu": {
			"erp": {
				"clientes": "Customers",
				"fornecedores": "Suppliers",
				"consultasvendas": "Sales Inquiries",
				"consultascompras": "Purchasing Inquiries",
				"contascorrentes": "Current Accounts",
				"stocks": "stocks",
				"tesouraria": "Treasury",
				"integracoes": "Integrations",
				"configuracoes": "settings",
				"manutencoes": "Maintenance"
			},
			"pca": {
				"contabilidade": "Accounting",
				"abonosdescontos": "Allowances and Discounts",
				"mapassalarios": "Salary Maps",
				"prestacaocontas": "Accountability",
				"minhaconta": "My account"
			},
			"gestorementas": {
				"colaborador": "collaborator",
				"menus": "Menus",
				"refeicoes": "Meals"
			},
			"gestorrh": {
				"colaborador": "collaborator",
				"servicos": "services",
				"tabelas": "Tables",
				"marcacaoeventos": "Event booking"
			},
			"gestorservico": {
				"colaborador": "collaborator",
				"marcacaoeventos": "Event booking"
			},
			"contabilidade": {
				"lancamentos": "Releases",
				"consultas": "Queries",
				"manutencaoContas": "Account maintenance",
				"declaracoesFiscais": "Tax declarations",
				"fechoContas": "Closing of accounts",
				"ferramentas": "Tools",
				"manutencoes": "Maintenance"
			},
			"configuracoes": {
				"atalhos": "Configuration Assistant Shortcuts",
				"configuracaoerp": "ERP Configuration"
			},
			"ativos": {
				"manutencoesTitle": "Maintenance",
				"manutencoes": "Maintenance",
				"consultarTitle": "Consult",
				"consultar": "Consult",
				"atalhoscontabilidadeTitle": "Shortcuts to accounting",
				"atalhoscontabilidade": "Shortcuts to accounting",
				"tabelasTitle": "Tables",
				"tabelas": "Tables"
			},
			"rh": {
				"transferencias": "Transfers",
				"recolhadados": "Data collection",
				"marcacaocalendario": "Calendar marking",
				"processamento": "Processing",
				"obrigacoeslegaisfiscais": "Legal and tax obligations",
				"integracaocontabilidade": "Accounting integration",
				"consultas": "Queries",
				"abonosdescontos": "Allowances/Discounts",
				"ferramentas": "Tools",
				"configuracoes": "settings",
				"manutencoes": "Maintenance"
			},
			"financeiro": {
				"clientes": "Customers",
				"fornecedores": "Suppliers",
				"manutencoes": "Maintenance"
			}
		},
		"sidebar": {
			"modules": {
				"adiantamentos": "Advances",
				"analiticaconfig": "Setting up analytics accounts",
				"apuraiva": "VAT clearance",
				"apuramentoresultados": "Calculation of results",
				"atFaturasRecibosVerdes": "Green sales receipts",
				"ativosaquisicaocontab": "Register assets based on accounting",
				"ativosaquisicaoinvestemcurso": "Ongoing investments",
				"ativoscoeficientecm": "Monetary correction coefficients",
				"ativosconsistencia": "Consistency with accounting",
				"balancetes": "Balance Sheets",
				"balancetesalternativos": "Alternative flat balance sheets",
				"balancetesanalitica": "Analytical balance sheets",
				"controloivaentreempresas": "Copy VAT control for companies",
				"declaracaoiva": "VAT periodic decree",
				"declaracaoivarecapitulativa": "VAT Recapitulative Decree",
				"demfinsnc": "SNC financial dem.",
				"descritivos": "Descriptives",
				"diarios": "Daily",
				"dmrf": "Dec. withholdings at source",
				"docsComerciaisEstatisticas": "Statistics per article",
				"docsComerciaisEstatisticasDoc": "Statistics per document",
				"docscontabilidade": "Collection",
				"documentosdigitaisConciliacao": "Conciliation documents.",
				"extratosDT": "Historical extract",
				"faturacaoeletronicaconfiguracoes": "Electronic invoicing",
				"faturacaopublica": "Public billing",
				"gto": "Task management",
				"impdecpe": "Extracts dec.",
				"importadorEfatura": "eInvoice Purchases",
				"importadorSaftDirect": "Importer sales",
				"infoglobalacrescimos": "Info.",
				"infoglobaldiferimentos": "Info.",
				"infoglobaldiferimentosacrescimos": "Info.",
				"listagemdiarios": "Daily",
				"listagensVendasAnoMes": "Summary by month",
				"manuttributautonomas": "Maintenance",
				"mapasamortizacao": "Official maps",
				"mbconfigs": "MB References",
				"meiosPagamento": "Payment methods",
				"movimentosemaberto": "Open moves",
				"pcaContabilidadeMovimentosAbertoCliente": "Open moves",
				"pcaContabilidadeMovimentosAbertoFornecedor": "Open moves",
				"pcaMovimentosAbertoCliente": "Move.",
				"periodos": "Periods",
				"pocscopy": "Copy plan accounts for companies",
				"predefinidoscontabilidade": "Predefined",
				"prheventosconfig": "Config.",
				"proceDMRAT": "DMR AT",
				"proceSalarios": "Salary",
				"proceSegSocial": "DMR SS (DRI)",
				"procseguradorafolhaferias": "Vacation sheet for insurance company",
				"qivaas": "Campos dec.",
				"qivaps": "Campos dec.",
				"remfx": "Fixed data",
				"remun": "Manual data",
				"reparticoesccustosfaturacao": "Divisions c.",
				"reparticoesccustossalarios": "Divisions c.",
				"rhassistentetrabalhador": "Wage integration",
				"rhdadosconfigficha": "Card data",
				"rhmanutencao": "Maintenance",
				"rhprocessamentosalarios": "Salary processing",
				"rhrecolhaseriesubsidiosalimentacao": "Food allowance",
				"rhrecolhaseriesubsidiosferias": "Holiday allowance",
				"rhrecolhaseriesubsidiosnatal": "Christmas subsidy",
				"rhsaltotrf": "Salary",
				"saft": "SAF-T Complete",
				"solic": "Billing notice texts",
				"svat": "SVAT financial dem.",
				"tesrubrica": "Treasury items",
				"trfba": "SEPA Payments",
				"trfbacomercial": "SEPA Payments",
				"trfbarh": "SEPA Payments"
			},
			"contabilidade": {
				"clientes": "Customers",
				"fornecedores": "Suppliers",
				"outrosdevedorescredores": "Other debtors and creditors"
			},
			"rh": {
				"integracaoSalarios": "Company",
				"integracaoSalariosMulti": "Multi-companies"
			}
		},
		"modals": {
			"editnode": {
				"title": "Change menu entry data",
				"fields": {
					"menuTitle": "Menu title",
					"pageTitle": "Module title",
					"sidebarTitle": "Module title in sidebar",
					"sidebarTitleTooltip": "If this field is not filled in, the value will be the same as the \"Module title\" field",
					"icon": "icon"
				}
			},
			"sort": {
				"title": "Define ordering of portals",
				"success": "Portals ordered successfully"
			}
		}
	},
	"predefinidoscontabilidade": {
		"title_plural": "Pre-defined accounting",
		"pesquisa": "To look for",
		"success": {
			"save": "Accounting preset saved successfully",
			"edit": "Accounting predefined \"{{id}}\" saved successfully",
			"delete": "Predefined accounting \"{{id}}\" deleted successfully",
			"simulate": "Accounting document saved successfully (simulation)"
		},
		"steps": {
			"operation": "Operation to be carried out",
			"basic": "Data configuration",
			"header": "Configuring header data",
			"lines": "Row data configuration",
			"preview": "Preset Preview",
			"companies": "Choice of companies",
			"finalize": "Finish"
		},
		"fields": {
			"preDefinidosID": "Code",
			"description": "Description",
			"data": "Data",
			"revision": "Revision",
			"cgBanking": "To use in CentralGest Banking",
			"document": "Document",
			"copyExisting": "Preset to copy"
		},
		"titles": {
			"chooseOperation": "Please choose the operation you want to perform",
			"notAvailable": "This option is not yet available",
			"suggestCodigo": "Suggest code",
			"suggestCodigoToolTip": "Suggest unique code for all companies",
			"legend": "Subtitle:",
			"verified": "Field checked",
			"notVerified": "Field not verified",
			"availableCompanies": "Available companies",
			"companiesToSave": "Companies where the pre-defined will be recorded",
			"companiesToRemove": "Companies where the predefined will be deleted",
			"selectColumns": "Please choose the companies where you want to create the predefined",
			"selectColumnsEdit": "Please choose the companies where you want to change the default",
			"selectColumnsDelete": "Please choose the companies where you want to delete the predefined"
		},
		"operations": {
			"new": "Create new preset",
			"newBasedOnDoc": "Create new preset based on an accounting document",
			"newBasedOnExisting": "Create new preset based on an existing one",
			"newAdvanced": "Create New Advanced Preset",
			"edit": "Change preset",
			"delete": "Delete preset"
		},
		"errors": {
			"invalidDocContab": "Accounting document \"{{doc}}\" does not exist",
			"invalidPreDefinido": "The predefined \"{{predefined}}\" does not exist"
		},
		"btn": {
			"simulate": "Simulate recording",
			"clear": "Clear document"
		}
	},
	"predefinidocontabcab": {
		"periodo": "Period",
		"diario": "Daily",
		"nDocInterno": "Internal Doc. No.",
		"dataLancamento": "Accounting release date",
		"dataVencimento": "Due date",
		"dataDoc": "Document date",
		"nContribuinte": "VAT Number",
		"nDocExterno": "External Doc. No.",
		"descricao": "Description",
		"descritivo": "Descriptive",
		"dataTransacaoBanco": "Bank transaction date",
		"titles": {
			"fieldProperties": "Field Properties",
			"visualProperties": "Visual properties"
		},
		"fields": {
			"readonly": "Read only",
			"tabStop": "Cursor stops in the field",
			"visible": "Visible",
			"tipoValorPeriodo": "Origin of value",
			"tipoValorDiario": "Origin of value",
			"valorFixo": "Fixed value",
			"valorFixoDiario": "Fixed diary",
			"valorFixoNDocExterno": "Fixed External Doc. No.",
			"valorFixoDescricao": "Fixed description",
			"valorFixoDescritivo": "Fixed description",
			"valorFixoConta": "Fixed account",
			"origem": "Origin",
			"incrementaAutomaticamente": "Auto increment",
			"origemValor": "Origin of value"
		},
		"errorInvalid": "You must check all header fields to continue",
		"enums": {
			"tipoValorPeriodo": {
				"0": "Company table",
				"1": "System date",
				"2": "Date of the document.",
				"3": "Bank transaction date"
			},
			"tipoValorDiario": {
				"0": "Undefined",
				"1": "Fixed"
			},
			"origemDataDoc": {
				"0": "From the period of the doc.",
				"1": "Date of the document."
			},
			"origemDataVencimento": {
				"0": "From the period of the doc.",
				"1": "Cond."
			},
			"origemDataLancamento": {
				"0": "Last day of the period",
				"1": "Current date",
				"2": "Date of the document."
			},
			"valorDescricao": {
				"0": "Undefined",
				"1": "Fixed",
				"2": "Formula"
			},
			"valorDescritivo": {
				"0": "Undefined",
				"1": "Same as last doc.",
				"2": "Fixed"
			}
		}
	},
	"predefinidocontablinhas": {
		"addLine": "Add line",
		"duplicateLine": "Duplicate selected line",
		"moveLineUp": "Move line up",
		"moveLineDown": "Move line down",
		"removeLine": "Remove selected line",
		"reorder": "Reorder columns",
		"errorInvalid": "Did not define any lines for the predefined",
		"titles": {
			"fieldProperties": "Field properties \"{{field}}\" ({{cell}})",
			"visualProperties": "Visual properties",
			"emptyLines": "The row table is empty.",
			"baseTributavelProperties": "Taxable base properties",
			"contaCorrenteProperties": "Current account properties",
			"ask": "Question",
			"salda": "Salda",
			"arredEFatura": "Arred.",
			"hintFormula": "To edit, click on the calculator on the right"
		},
		"fields": {
			"conta": "Account",
			"valor": "Value",
			"valorIva": "VAT value",
			"nContribuinte": "VAT Number",
			"descricao": "Description",
			"nDocExterno": "External Doc. No.",
			"descritivo": "Descriptive",
			"dataLancamento": "Accounting release date",
			"dataVencimento": "Due date",
			"dataDoc": "Document date",
			"nomeConta": "Account name",
			"dC": "Debit credit",
			"radical": "Radical",
			"tipoConta": "Account Type",
			"temImputacao": "Select amounts to settle",
			"vaiImputarALinha": "Will impute to the line",
			"codRepCC": "Distribution code C.Automatic cost",
			"tipoValor": "Value type",
			"valorFormula": "Value per formula",
			"colocaValorEFaturaDocDigital": "Enter value eFatura or Digital Doc.",
			"incrementaAutomaticamente": "Auto increment",
			"tipoValorBaseTributavel": "Price with/without VAT",
			"importEFaturaSuportaTaxaIsenta": "Supports Tax Exempt Tax.",
			"importEFaturaValorImpSelo": "Places Imp stamp tax.",
			"importEFaturaContaUnicaSemDeducao": "Supports Single Account and Invoice",
			"importFRVSuportaTaxaIsenta": "Supports Exempt Fee Green Rec. Issued",
			"importFRVValorImpSelo": "Places Green Rec. Issued stamp tax"
		},
		"enums": {
			"tipoConta": {
				"0": "Undefined",
				"1": "Current account",
				"2": "Taxable base",
				"3": "CG Banking bank account"
			},
			"tipoValor": {
				"0": "Question",
				"1": "Fixed",
				"2": "Formula",
				"3": "Balance document",
				"4": "eInvoice Rounding"
			},
			"tipoValorBaseTributavel": {
				"0": "Question",
				"1": "Base",
				"2": "Base + VAT"
			},
			"tipoValorDescritivo": {
				"0": "Undefined",
				"1": "Same as last doc.",
				"2": "Fixed"
			}
		}
	},
	"predefinidocontabformula": {
		"title": "Formula for the field {{field}} of line {{line}}",
		"operators": "Operators",
		"variables": "Variables",
		"formula": "Formula",
		"validator": "Haven't defined a formula for the field yet",
		"extraVariables": {
			"valorPorPagar": "ValuePerPayLine"
		},
		"headerVariables": {
			"periodo": "Header.Period",
			"nomePeriodo": "Header.NamePeriod"
		}
	},
	"prevites": {
		"manual": {
			"new": {
				"title": "Treasury Forecast",
				"previtessaved": "Treasury forecast saved successfully.",
				"rubrica": "Rubric",
				"data": "Date",
				"descricao": "Description",
				"valor": "Value",
				"cardtitle": "Successive occurrences",
				"periodicas": "Periodicals",
				"ocrrfimperio": "Occurrence at the end of each period",
				"ocorrencias": "Number of Occurrences",
				"periodicidade": "Frequency",
				"temdefnocurr": "You have defined the Periodicity but have not yet defined the Number of Occurrences!",
				"temdefocurr": "You have defined the Number of Occurrences but have not yet defined the Periodicity!",
				"rubricanull": "The rubric cannot be null!",
				"valornull": "The value cannot be null!",
				"datainf": "The date cannot be less than the current date!"
			},
			"tooltip": {
				"btnDelete": "Delete registration",
				"btnDeleteMultiple": "Delete selected records"
			},
			"messages": {
				"certezaeliminar": "Are you sure you want to delete the record(s)?"
			},
			"btnDeleteMulti": "Delete selected records"
		},
		"automatica": {
			"modalTitle": "Treasury Heading",
			"legenda": "AutoCorrect - Current Date",
			"valselecionado": "Selected Value:",
			"clientefornecedor": "Customers / Suppliers",
			"clientes": "Customers",
			"fornecedores": "Suppliers",
			"datatitle": "Select Treasury date",
			"data": "Date",
			"datade": "Expiration Date",
			"contade": "account",
			"ate": "Until",
			"selecaoAutomatica": "Select Automatic",
			"selecaoAutomaticaTooltip": "When selecting, automatically selects all lines in the document",
			"fields": {
				"seleccionado": "Selected",
				"nConta": "No.",
				"nome": "Name",
				"nDocString": "Doc No.",
				"nDocExterno": "External Doc. No.",
				"descricao": "Description",
				"debito": "Debt",
				"credito": "Credit",
				"porPagar": "Unpaid",
				"dataDocExt": "Date.",
				"dataVencimento": "Due date",
				"dataTes": "Treasury Date"
			},
			"messages": {
				"procesuccess": "Data processed successfully!",
				"temselecionar": "You must select the entries you want to process."
			},
			"actions": {
				"verdoc": "View document",
				"aplicardata": "Apply Treasury date",
				"selecionaDocConta": "Select account documents",
				"removeSeleconta": "Clear account documents selection",
				"docfaturacao": "View billing document"
			}
		},
		"datasource": {
			"tesourariaperiodicidade": {
				"none": "None",
				"diaria": "Daily",
				"semanal": "Weekly",
				"quinzenal": "Fortnightly",
				"mensal": "Monthly",
				"bimensal": "Bimonthly",
				"trimestral": "Quarterly",
				"quadrimestral": "Quarterly",
				"semestral": "Semiannual",
				"anual": "Yearly"
			}
		}
	},
	"prevtesobras": {
		"codTipoPlaneamentoDe": "Planning Type Code",
		"codEstadoObraDe": "Work Status Code",
		"ate": "Until",
		"cabCodigo": "Work Code",
		"cabDescricao": "Name Work",
		"codPlaneamenTipo": "Planning Code",
		"descricao": "Desc.",
		"codEstado": "State Code",
		"codEstadoDesc": "Desc.",
		"modaltitle": "Treasury item",
		"rubrica": "Rubric Code",
		"rubricanull": "Rubric code cannot be null",
		"importsuccess": "Data processed successfully!"
	},
	"prhcomunicacoesinternas": {
		"title_detail": "Internal communication {{id}}",
		"title_new": "New internal communication",
		"title_plural": "Internal Communications",
		"pesquisa": "To look for",
		"saved": "Internal communication {{id}}, saved successfully",
		"error": "Internal communication {{id}}, cannot be saved.",
		"fields": {
			"comIntCabID": "Code.",
			"comIntCabIDPlaceholder": "Internal communication code",
			"titulo": "Title",
			"tituloPlaceholder": "Title of internal communication",
			"data": "Date",
			"estadoLido": "Read",
			"de": "In",
			"texto": "Text",
			"ficheiro": "File"
		},
		"masterDetail": {
			"emp": "Employee Code",
			"nome": "Name",
			"lido": "Read",
			"dateHora": "Reading date/time"
		}
	},
	"prhdocsplata": {
		"title_detail": "HR platform document {{id}}",
		"title_new": "New HR platform document",
		"title_plural": "HR Platform Documents",
		"pesquisa": "To look for",
		"saved": "HR platform document {{id}}, saved successfully",
		"error": "HR platform document {{id}}, cannot be saved.",
		"fields": {
			"cab": {
				"codprhdocsplata": "Code.",
				"codprhdocsplataPlaceholder": "HR Platform Document Code",
				"titulo": "Title",
				"tituloPlaceholder": "HR Platform Document Title",
				"data": "Date",
				"dataPlaceholder": "HR platform Document Date",
				"dataValidade": "Expiration date",
				"dataValidadePlaceholder": "Expiration date"
			}
		},
		"steps": {
			"documento": "Document Data",
			"papeis": "Papers",
			"servicos": "services"
		}
	},
	"prheventosconfig": {
		"title_detail": "{{id}} event configuration",
		"title_new": "New event configuration",
		"title_plural": "HR Event Settings",
		"title_small": "Config.",
		"pesquisa": "To look for",
		"saved": "Event configuration {{id}}, saved successfully",
		"error": "Event configuration {{id}}, cannot be saved.",
		"fields": {
			"codPRHEventosConfig": "Event configuration",
			"codPRHEventosConfigPlaceholder": "Event configuration",
			"tipoEvento": "Kind of event",
			"tipoEventoPlaceholder": "Kind of event",
			"tipo": "Type",
			"tipoPlaceholder": "Type",
			"nCodAbdes": "Allowance/Discount Code",
			"nCodAbdesPlaceholder": "Allowance/Discount Code",
			"descricao": "Description",
			"descricaoPlaceholder": "Description",
			"tipoProcessamento": "Type of processing",
			"tipoProcessamentoPlaceholder": "Type of processing",
			"tipofalta": "Type of fault",
			"desconto": "Discount",
			"abono": "Allowance"
		},
		"tipos": {
			"abonos": "Allowances",
			"faltas": "Fouls",
			"ferias": "Vacation"
		},
		"messages": {
			"error": "Event configuration cannot be saved.",
			"addsuccess": "Added successfully",
			"editsuccess": "Successfully changed",
			"deleteSuccess": "Deleted successfully",
			"deleteTitle": "Attention!",
			"deleteConfirm": "Do you really want to delete this record?",
			"fieldsUnfiled": "There are fields that are not filled in!"
		}
	},
	"prhfluxos": {
		"title_detail": "Flow {{id}}",
		"title_new": "New Flow",
		"title_plural": "HR Flows",
		"pesquisa": "To look for",
		"saved": "Flow {{id}}, saved successfully",
		"error": "Flow {{id}}, cannot be saved.",
		"fields": {
			"idFluxo": "Flow",
			"idFluxoPlaceholder": "Flow code",
			"posicao": "Position",
			"posicaoPlaceholder": "Flow position",
			"indice": "Index",
			"indicePlaceholder": "Flow Index",
			"codPapel": "Paper code",
			"codPapelPlaceholder": "active role code",
			"integraCG": "Integra CentralGest",
			"integraCGPlaceholder": "Integra CentralGest",
			"papel": "Paper",
			"papelCGPlaceholder": "Paper",
			"tipo": "Type",
			"tipoPlaceholder": "Type"
		}
	},
	"prhgruporefeicoes": {
		"title_detail": "Meals group {{id}}",
		"title_new": "New meal group",
		"title_plural": "Meal groups",
		"pesquisa": "To look for",
		"saved": "Meal group {{id}}, saved successfully",
		"error": "Meal group {{id}}, cannot be saved.",
		"fields": {
			"grupo": "Group",
			"grupoPlaceholder": "Group",
			"nome": "Name",
			"nomePlaceholder": "Meal group name",
			"permiteAgendamento": "Allows scheduling",
			"permiteAgendamentoPlaceholder": "Allows scheduling",
			"horaInicio": "Start time",
			"horaInicioPlaceholder": "Start time",
			"horaFim": "End Time",
			"horaFimPlaceholder": "End Time"
		}
	},
	"prhlocais": {
		"title_detail": "Location {{id}}",
		"title_new": "New Location",
		"title_plural": "HR Locations",
		"pesquisa": "To look for",
		"saved": "Location {{id}}, saved successfully",
		"error": "Location {{id}}, cannot be saved.",
		"fields": {
			"codLocal": "Local",
			"codLocalPlaceholder": "Location code",
			"nome": "Name",
			"nomePlaceholder": "Location name"
		}
	},
	"prhmenus": {
		"title_detail": "Menu {{id}}",
		"title_new": "New Menu",
		"title_plural": "HR Menus",
		"pesquisa": "To look for",
		"saved": "Menu {{id}}, saved successfully",
		"error": "Menu {{id}}, cannot be saved.",
		"fields": {
			"codMenu": "Menu",
			"codMenuPlaceholder": "Menu code",
			"titulo": "Title",
			"tituloPlaceholder": "Menu title",
			"descricao": "Description",
			"descricaoPlaceholder": "Menu description",
			"activo": "Active",
			"activoPlaceholder": "Active"
		}
	},
	"prhpapeis": {
		"title_detail": "Paper {{id}}",
		"title_new": "New Role",
		"title_plural": "HR Papers",
		"pesquisa": "To look for",
		"saved": "Role {{id}}, saved successfully",
		"error": "Paper {{id}}, cannot be saved.",
		"fields": {
			"codPapel": "Paper",
			"codPapelPlaceholder": "Paper Code",
			"nome": "Name",
			"nomePlaceholder": "Paper Name",
			"unicoServico": "Single per service",
			"unicoServicoPlaceholder": "Single per service"
		}
	},
	"prhrefeicoes": {
		"title_detail": "Meal {{id}}",
		"title_new": "Snack",
		"title_plural": "RH Meals",
		"pesquisa": "To look for",
		"saved": "Meal {{id}}, saved successfully",
		"error": "Meal {{id}}, cannot be saved.",
		"fields": {
			"CodRefeicao": "Snack",
			"CodRefeicaoPlaceholder": "Meal Code",
			"CODEMP": "collaborator",
			"CODEMPPlaceholder": "Employee Code",
			"codPRHRelEmentas": "Relament",
			"codPRHRelEmentasPlaceholder": "RelEmenta Code",
			"consumiu": "Consumed",
			"local": "Local",
			"tipoRefeicao": "Type of meal",
			"cor": "Color",
			"menu": "Menu",
			"ementa": "Menu"
		}
	},
	"prhrelementas": {
		"title_detail": "RelEmenta {{id}}",
		"title_new": "Relament",
		"title_plural": "HR Menu Relations",
		"pesquisa": "To look for",
		"saved": "RelEmenta {{id}}, saved successfully",
		"error": "RelEmenta {{id}}, cannot be saved.",
		"menusAssociados": "The menu cannot be deleted as there are already scheduled meals.",
		"fields": {
			"codPRHRelEmentas": "Rel. Menu",
			"codPRHRelEmentasPlaceholder": "menus relationship code",
			"codMenu": "Menu",
			"codMenuPlaceholder": "Menu code",
			"codLocal": "Local",
			"codLocalPlaceholder": "Location code",
			"data": "Date",
			"dataPlaceholder": "Date",
			"tipoRefeicao": "Type of meal",
			"tipoRefeicaoPlaceholder": "Type of meal"
		}
	},
	"prhservicoempregados": {
		"title_detail": "Service/Employee Report {{id}}",
		"title_new": "New Service/Employee Report",
		"title_plural": "HR Service/Employee Report",
		"pesquisa": "To look for",
		"saved": "Service/Employee Report {{id}}, saved successfully",
		"error": "Service/Employee Report {{id}}, cannot be saved.",
		"fields": {
			"codServicoEmpregado": "Service/Employee Report Code",
			"codServicoEmpregadoPlaceholder": "Service/Employee Report Code",
			"codPapel": "Paper",
			"codPapelPlaceholder": "Paper Code",
			"codServico": "Service",
			"codServicoPlaceholder": "Service code",
			"codEmp": "Employee",
			"codEmpPlaceholder": "Employee Code"
		}
	},
	"prhservicos": {
		"title_detail": "Service {{id}}",
		"title_new": "New Service",
		"title_plural": "HR Services",
		"pesquisa": "To look for",
		"saved": "Service {{id}}, saved successfully",
		"error": "Service {{id}}, cannot be saved.",
		"deleted": "Service {{id}}, successfully deleted.",
		"fields": {
			"codServico": "Service",
			"codServicoPlaceholder": "Service code",
			"nome": "Name",
			"nomePlaceholder": "Service Name"
		}
	},
	"prhtiporefeicoes": {
		"title_detail": "Meal type {{id}}",
		"title_new": "New Meal Type",
		"title_plural": "RH Meal Types",
		"pesquisa": "Search",
		"saved": "Meal type {{id}}, saved successfully",
		"error": "Meal type {{id}}, not saved.",
		"fields": {
			"tipoRefeicao": "Meal type",
			"tipoRefeicaoPlaceholder": "Type of meal",
			"nome": "Name",
			"nomePlaceholder": "type of meal name",
			"horaRefeicao": "Meal time",
			"horaRefeicaoPlaceholder": "Meal time",
			"numMinutosMarcacao": "Number of minutes to dial",
			"numMinutosMarcacaoPlaceholder": "Number of minutes to dial",
			"corPrimaria": "Primary Color",
			"corPrimariaPlaceholder": "Primary Color",
			"corSecundaria": "Secondary Color",
			"corSecundariaPlaceholder": "Secondary Color",
			"grupoRefeicao": "Meal group",
			"grupoRefeicaoPlaceholder": "Meal group"
		}
	},
	"proceDMRAT": {
		"tabIntroCaption": "Introduction",
		"tabIntroTitle": "Welcome to the Monthly Remuneration Statement processing wizard - AT",
		"tabIntroSubTitle": "The Assistant will help you generate the Monthly Remuneration Statement file - AT",
		"tabEmpresasCaption": "Companies",
		"tabConfigCaption": "Settings",
		"tabProcCaption": "Processing",
		"tabResultsCaption": "Report",
		"temSeleccionarUmaEmpresa": "You must select at least one company to continue.",
		"temSeleccionarUmMesParaProcessar": "You must select a month to process",
		"vizProcExistentes": "View Existing Processes",
		"procAlertMsg1": "By default it will generate DMR-AT for all processing of the month.",
		"procAlertMsg2": "If you want to check existing processing, select the field below",
		"temSeleccionarDatas": "You must select at least one date.",
		"confirmTitle": "Confirmation",
		"procExistentesReprocMessage": "There are companies with the processing of the Monthly Remuneration Declaration - AT.",
		"geracaoImpressao": "Generation and Printing",
		"promptViewResultsTitle": "Monthly Remuneration Statement",
		"promptViewResultsMessage": "The processing of the Monthly Remuneration Statement has ended.",
		"viewResultBtn": "To view",
		"initNewProc": "Start new",
		"erros": "Errors",
		"printMapPerEmp": "Print Map",
		"genDRIFile": "Generate File",
		"sendFile": "Send via WebService",
		"viewSends": "Evidence / Payment Document",
		"listagem": "Listing",
		"printMapaModalTitle": "Printing of maps Monthly Remuneration Declaration - AT",
		"semAlteracaoParaGerar": "The system did not generate a file, because it was already generated previously.",
		"viewInfoModalTitle": "Year: {{year}} Month: {{month}} - Shows Information Data Remuneration",
		"obterPdf": "Get PDF",
		"promptConfirmModalTitle": "Confirmation",
		"promptSendModalMessage": "You will send the Monthly Remuneration Statement file(s) via WebService.",
		"promptViewFilesMessage": "The files have been communicated.",
		"enviadoComSuccess": "The file(s) sent successfully.",
		"enviadoComErros": "There were errors in the files sent.",
		"decSubstituicao": "Replacement Declaration",
		"viewInfoDadosAutoTab": "Automatic Data",
		"viewInfoDadosManuaisTab": "Manual Data",
		"viewInfoDadosGlobaisTab": "Global Data",
		"viewInfoDadosGlobaisControloTab": "Control",
		"viewInfoDadosGlobaisValorRendimentosTab": "Income Value",
		"viewInfoDadosGlobaisRetencaoIRSTab": "IRS Withholding",
		"viewInfoDadosGlobaisContribObrigTab": "Mandatory Contributions",
		"viewInfoDadosGlobaisQuotizSindicaisTab": "Union dues",
		"viewInfoDadosGlobaisRetencaoSobretaxaTab": "Withholding Surcharge",
		"viewInfoDadosGlobaisRendimAnosAntTab": "Income from Previous Years",
		"resumoDadosFuncionario": "Summary Employee Data",
		"jobTimeoutModalTitle": "Task",
		"jobTimeoutModalMessage": "The task has timed out.",
		"comecar": "To start",
		"fields": {
			"selected": "Sel",
			"mesAno": "Month year",
			"tipoProcessamento": "Process Type.",
			"tipoProcessamentoDescricao": "Description",
			"nProcessamento": "Proc No.",
			"dataProcessamento": "Process Date",
			"empresa": "Company",
			"nomeEmpresa": "Company name",
			"dmrAno": "Year Proc.",
			"dmrMes": "Month Proc.",
			"totalValorRendimentos": "Total - Income Value",
			"totalValorRetencaoIRS": "Total - IRS Withholding",
			"totalValorContribuicoesObrigatorias": "Total - Mandatory Contributions",
			"totalValorQuotizacaoSindicais": "Total - Value of Union Dues",
			"totalValorRetencaoSobretaxa": "Total - Surcharge Retention Amount",
			"totalValores": "Total",
			"wsEstadoFicheiroStr": "AT Shipping Status",
			"erro": "Error",
			"queFazer": "How to solve",
			"statusImage": "state",
			"codEmp": "Emp. Code.",
			"nome": "Name",
			"nif": "VAT Number",
			"totalDMRATRemun": "Total - Social Security Remuneration",
			"totalVencRemun": "Total - Remuneration Proc.",
			"abonosRendimentosSujeitos": "Subject Income",
			"abonosRendimentosNaoSujeitos": "Non-Subject Income",
			"descontosContribuicoesObrigatorias": "Mandatory Contributions",
			"descontosIRS": "IRS Withholding",
			"descontosSindicatos": "Unions",
			"descontosSobretaxa": "Withholding Surcharge",
			"abonosOutros": "Other Allowances",
			"descontosOutros": "Other Discounts",
			"liquidoCalculado": "Calculated Liquid",
			"liquidoVencimentos": "Net Salaries",
			"descricaoAbono": "Desc.",
			"origemRendiDescr": "Origin",
			"dataVencimen": "Date",
			"tipRendiment": "Tip.",
			"descrInsManual": "Insertion Type",
			"valorInciden": "Income Value",
			"irsRetido": "IRS Withholding",
			"descObrigSSocial": "Contribution",
			"quotizSindical": "Union dues",
			"sobretaxaExtraord": "Surcharge Withholdings",
			"rendimentosAnoAnterior": "Income Value (Previous Years)",
			"rendAnoAnteriorAno": "Yield",
			"rendAnoAnteriorValores": "Yield",
			"rendimentodoano": "Yield",
			"tipoRendimento": "Income Type.",
			"localObtencaoRend": "Place of Origin Income.",
			"retencaoIRS": "IRS Withholding",
			"contribuicosObrigatoriasValores": "Contribution",
			"contribuicosObrigatoriasNIF1": "Contribution",
			"contribuicosObrigatoriasNIF2": "Contribution",
			"contribuicosObrigatoriasNIF3": "Contribution",
			"quotizacaoSindical": "Union Dues",
			"retencaoSobretaxa": "Withholding Surcharge",
			"enviaparaDMR": "Send to DMR",
			"rendSujeitos": "Yield",
			"valorNaoSujeito": "Yield",
			"rendNaoSujeitos": "Yield",
			"outrosDescontos": "Other Discounts",
			"outrosAbonos": "Other Allowances",
			"anoAntRend": "Previous Year Income",
			"nCodAbdes": "Allowance/Discount"
		},
		"btn": {
			"ordenar": "Sort by: {{orderby}}",
			"dropdown": {
				"sortEmpresasByNEmpresa": "Company Code",
				"sortEmpresasByName": "Company name"
			}
		}
	},
	"proceSalarios": {
		"tabIntroCaption": "Introduction",
		"tabIntroTitle": "Welcome to the Salary Processing Wizard",
		"tabIntroSubTitle": "The Assistant will help you Process Salaries",
		"tabEmpresasCaption": "Companies",
		"tabConfigCaption": "Settings",
		"tabProcCaption": "Processing",
		"tabResultsCaption": "Report",
		"jobTimeoutModalTitle": "Task",
		"jobTimeoutModalMessage": "The task has timed out.",
		"processar": "Process",
		"processingMessage": "Processing, please wait a moment.",
		"erro": "Error",
		"modalConfirmTitle": "Confirmation",
		"modalRemoveSelEmpConfirmMsg": "Are you sure you want to remove the selected employee(s) from this processing?",
		"ano": "Year",
		"mes": "Month",
		"comecar": "To start",
		"tipoProcessamentoGerarTitle": "Type of Processing to Generate",
		"tipoProceMensalCaption": "Monthly Processing",
		"tipoProceFeriasCaption": "Holiday Processing",
		"tipoProceNatalCaption": "Christmas Processing",
		"dataParaProcessamentoCaption": "Processing Date",
		"dia": "Day",
		"podeReprocessarSalariosCaption": "Salary Reprocessing (With Processes in the month)",
		"podeReprocessarSalariosHelpText1": "You can only do Salary Reprocessing:",
		"podeReprocessarSalariosHelpText2": "- If there is only one Processing in the Selected month.",
		"podeReprocessarSalariosHelpText3": "- The existing Processing Type cannot be changed.",
		"procExistentesTitle": "Proc.",
		"processModalMessage": "Processing...please wait a moment.",
		"estimativa": "I estimated",
		"avisosErros": "Warnings/Errors",
		"fields": {
			"selected": "Sel",
			"mesAno": "Month year",
			"nEmpresa": "Company",
			"nomeEmpresa": "Company name",
			"mes": "Month",
			"ano": "Year",
			"dataProce": "Processing Date",
			"dADataProc": "Start Date",
			"ateDataProc": "End Date",
			"nDiasUteis": "Number of working days",
			"descricao": "Description",
			"erro": "Error",
			"nDiasTotalMes": "Number of Days Total Month",
			"calendar": "Calendar",
			"tipoProcessamento": "Processing Type",
			"codEmp": "Code.",
			"nome": "Name",
			"nBIdentidade": "BI Number / Citizen Card",
			"nContribuinte": "NIF",
			"dataNascimento": "Birth date",
			"strEstadoProcEmpregado": "state",
			"nProcessamento": "Process No.",
			"nRecibo": "Receipt No.",
			"dataProcessamento": "Date Process",
			"identificacaoEmpregado": "Employee",
			"dataInicoProc": "Start Date",
			"dataFimProce": "End Date",
			"nDiasParaProcessamen": "No. Business Days for Process.",
			"anoParaSegSocial": "Year (SS)",
			"mesParaSegSocial": "Month (SS)",
			"proceImportado": "Proc.",
			"tipoProcessamentoDescricao": "Process Type."
		},
		"temSeleccionarUmMesParaProcessar": "You must select a month to process",
		"temSeleccionarPeloMenosTipoProcessamento": "You must select at least one Processing Type",
		"promptViewResultsTitle": "Salary Processing",
		"promptViewResultsMessage": "Payroll processing has ended.",
		"viewResultBtn": "To view",
		"initNewProc": "Start new",
		"mostrarSoComErros": "Show only with errors",
		"segSocialRelativaTitle": "Social Security Relative to",
		"novoProcessamentoTitle": "New Processing",
		"empregadosTitle": "Employees",
		"selectAll": "Select all",
		"unSelectAll": "Clear selection",
		"temSeleccionarUmEmpregado": "You must select at least one employee to process.",
		"singleProcDescDef": "Proc.",
		"temSeleccionarUmaEmpresa": "You must select at least one company to continue.",
		"addEmpregado": "Add Employee(s)",
		"removeSelEmpregado": "Remove Selected Employee(s)",
		"addSelected": "Add Selected",
		"pesqEmpregados": "Employee Search",
		"reprocEmpregadoTooltip": "Reprocesses Employee",
		"reprocEncargosTooltip": "Reprocess Charges",
		"confirmReProcMsg": "Are you sure you want to Reprocess the employee {{codEmp}} from this processing?",
		"confirmReProcEncargoMsg": "Are you sure you want to reprocess employee {{codEmp}}'s charges for this processing?",
		"confirmReProcSelMsg": "Are you sure you want to reprocess the employees selected from this processing?",
		"empregadoReprocessado": "The employee {{codEmp}} has been reprocessed.",
		"encargosReprocessados": "Employee {{codEmp}} charges were reprocessed.",
		"empregadoSelReprocessado": "The selected employees were reprocessed.",
		"deleteProcess": "Eliminate processing",
		"messages": {
			"empEliminadoSucesso": "Employee removed successfully!",
			"empAdicionadoSucesso": "Employee added successfully!",
			"naoContemEmpSelecionado": "You must select at least one employee!"
		},
		"btn": {
			"ordenar": "Sort by: {{orderby}}",
			"dropdown": {
				"sortEmpresasByNEmpresa": "Company Code",
				"sortEmpresasByName": "Company name"
			}
		},
		"consultarRecibo": "Consult receipts",
		"consultarReciboEmpregado": "Consult employee receipts",
		"existemCamposComErros": "There are fields with errors.",
		"promptDeleteProcTitle": "Delete Processing",
		"promptDeleteProcMessage": "Are you sure you want to eliminate processing?",
		"todos": "All",
		"reprocSelected": "Reproc."
	},
	"proceSegSocial": {
		"tabIntroCaption": "Introduction",
		"tabIntroTitle": "Welcome to the Social Security Processing Assistant",
		"tabIntroSubTitle": "The Assistant will help you generate the Social Security file",
		"tabEmpresasCaption": "Companies",
		"tabConfigCaption": "Settings",
		"tabProcCaption": "Processing",
		"tabResultsCaption": "Report",
		"temSeleccionarUmaEmpresa": "You must select at least one company to continue.",
		"temSeleccionarUmMesParaProcessar": "You must select a month to process",
		"vizProcExistentes": "View Existing Processes",
		"procAlertMsg1": "By default it will generate Social Seg for all processing of the month",
		"procAlertMsg2": "If you want to check existing processing, select the field below",
		"temSeleccionarDatas": "You must select at least one date.",
		"confirmTitle": "Confirmation",
		"procExistentesReprocMessage": "There are companies with processing generated for Social Security.",
		"reprocEmpSelected": "Yes",
		"loadProcSavedAndGenNewsForNotProccessed": "No",
		"geracaoImpressao": "Generation and Printing",
		"promptViewResultsTitle": "Processing for Social Security",
		"promptViewResultsMessage": "Social Security processing has ended.",
		"viewResultBtn": "To view",
		"initNewProc": "Start new",
		"erros": "Errors",
		"printMapPerEmp": "Print Map Per Employee",
		"printSummary": "Print Summary",
		"genDRIFile": "Generate File for DRI",
		"sendFile": "Send WebService File",
		"viewSends": "Consult Shipping WebService",
		"listagem": "Listing",
		"printMapPerEmpModalTitle": "Printing of Social Security maps",
		"printSummaryModalTitle": "Printing maps Social Security File Summary",
		"semAlteracaoParaGerar": "The system did not generate a file, because it was already generated previously.",
		"viewInfoSSModalTitle": "Year: {{year}} Month: {{month}} - Shows Social Security Data Information",
		"codPTitle": "Code P",
		"codITitle": "Code I",
		"nDiasCodPLabel": "Code P - Number of Days",
		"nDias2Label": "Code 2 - Number of Days",
		"nDiasILabel": "Code I - Number of Days",
		"valLabel": "Value",
		"valRLabel": "Code R - Value",
		"valFLabel": "Code F - Value",
		"valNLabel": "Code N - Value",
		"valALabel": "Code A - Value",
		"valBLabel": "Code B - Value",
		"valCLabel": "Code C - Value",
		"valDLabel": "Code D - Value",
		"valHLabel": "H Code - Value",
		"valMLabel": "Code M - Value",
		"valOLabel": "Code O - Value",
		"valSLabel": "Code S - Value",
		"valTLabel": "T Code - Value",
		"valXLabel": "Code X - Value",
		"val6Label": "Code 6 - Value",
		"val2Label": "Code 2 - Value",
		"valILabel": "Code I - Value",
		"valPLabel": "Code P - Value",
		"infoSSSaved": "Data saved successfully.",
		"promptInfoSSSave": "You will change the data in the file to be sent to Social Security.",
		"nibADebitar": "IBAN to Debit",
		"obterPdf": "Get PDF",
		"promptConfirmModalTitle": "Confirmation",
		"promptAlertModalTitle": "Attention!",
		"promptSendModalMessage": "You will send the files to Social Security via WebService.",
		"enviadoComSuccess": "The file(s) sent successfully.",
		"enviadoComSuccesso": "The file(s) were sent successfully.",
		"enviadoComErros": "There were errors in the files sent.",
		"jobTimeoutModalTitle": "Task",
		"jobTimeoutModalMessage": "The task has timed out.",
		"comecar": "To start",
		"fields": {
			"selected": "Sel",
			"mesAno": "Month year",
			"tipoProcessamento": "Process Type.",
			"tipoProcessamentoDescricao": "Description",
			"dataProcessamento": "Process Date",
			"empresa": "Company",
			"nomeEmpresa": "Company name",
			"ssAno": "Year Social Mon",
			"ssMes": "Month Social Mon.",
			"fichGeradoPodeReprocessarFicheiro": "Can Reprocess",
			"estadoFicheiroStr": "Status File",
			"ssInfoEstadoProcessamentoStr": "SS State",
			"totalRemuneracaoSegSocial": "Total - Remuneration",
			"totalContribuicaoSegSocial": "Total - Social Security Contribution",
			"totalRemuneracaoVencimento": "Total - Remuneration",
			"erro": "Error",
			"queFazer": "How to solve",
			"statusImage": "Error",
			"codEmp": "Emp. Code.",
			"nome": "Name",
			"numSS": "SS No.",
			"totalSegSocialRemun": "Total - Social Security Remuneration",
			"totalVencRemun": "Total - Remuneration Proc.",
			"taxaAAplicar": "Tx (%)"
		},
		"configWsSSTooltip": "Social Security WebService Configuration",
		"btn": {
			"ordenar": "Sort by: {{orderby}}",
			"dropdown": {
				"sortEmpresasByNEmpresa": "Company Code",
				"sortEmpresasByName": "Company name"
			}
		}
	},
	"processos": {
		"title_detail": "Process {{id}}",
		"title_new": "New Process",
		"title_plural": "Law Suit",
		"pesquisa": "To look for",
		"saved": "Process {{id}}, saved successfully.",
		"error": "Process {{id}}, cannot be saved.",
		"deleted": "Process {{id}}, deleted successfully",
		"fields": {
			"nRefProcesso": "Process",
			"nRefProcessoPlaceholder": "Process number",
			"nome": "Name",
			"nomePlaceholder": "Process name",
			"nCCusto": "N.C. Cost",
			"nomeCCusto": "Name C. Cost",
			"nomeCCustoPlaceholder": "cost center",
			"nClifo": "Client",
			"nomeClifo": "Customer Name",
			"nomeClifoPlaceholder": "cost center"
		}
	},
	"procseguradorafolhaferias": {
		"steps": {
			"introducao": "Introduction",
			"empresas": "Companies",
			"configuracao": "Settings",
			"relatorio": "Report",
			"title": {
				"titleintro": "Welcome to the Insurance Vacation Sheet processing wizard",
				"subtitleintro": "The Wizard will help you generate the Vacation Sheet file for the Insurance Company",
				"titleempresas": "Companies with processing",
				"subtitleempresas": "Choose the Companies to generate file",
				"titleconfig": "Processing dates",
				"subtitleconfig": "Selection of due date processing dates",
				"titlerelatorio": "Generation and printing of the Vacation Sheet for the Insurance Company",
				"errossegSocial": "Insurer Errors",
				"erros": "Errors"
			}
		},
		"fields": {
			"selected": "Sel.",
			"mesAno": "Month year",
			"tipoProcessamento": "Process type.",
			"tipoProcessamentoDescricao": "Description",
			"dataProcessamento": "Processing Date",
			"empresa": "Company",
			"nomeEmpresa": "Company name",
			"ssAno": "Year",
			"ssMes": "Month",
			"temFichGerado": "There is a generated file",
			"nempresa": "Company",
			"erro": "Error",
			"queFazer": "How to solve",
			"totalRemuneracaoSegSocial": "Total - Social Security Remuneration"
		},
		"messages": {
			"temSeleccionarDatas": "You must select at least one date.",
			"invalidStepEmp": "You must select at least one company to process.",
			"invalidStepConf": "There is no processing for the selected year/month.",
			"empresascomerros": "There are companies that make mistakes!"
		},
		"prompt": {
			"confTitle": "Confirmation",
			"confMessage": "There are companies with processing generated with the Insurer's Vacation sheet.",
			"geraFich": "It will generate the file for the Insurance Company's Vacation Sheet."
		},
		"btn": {
			"printmap": "Print Map by Employee",
			"genfichsegu": "Generate File for Insurance Company",
			"previsualizar": "Preview",
			"ordenar": "Sort by: {{orderby}}",
			"dropdown": {
				"sortEmpresasByNEmpresa": "Company Code",
				"sortEmpresasByName": "Company name"
			}
		},
		"modal": {
			"impressaomapas": "Printing of Vacation Sheet maps for Insurance Company",
			"listagem": "Listing"
		}
	},
	"profissao": {
		"title_detail": "Profession {{id}}",
		"title_new": "Profession",
		"title_plural": "Professions",
		"pesquisa": "To look for",
		"deleted": "Profession successfully deleted.",
		"saved": "Profession {{id}}, saved successfully.",
		"error": "Profession {{id}}, cannot be saved.",
		"fields": {
			"nProfissao": "Profession Code",
			"designaBreve": "Designation",
			"designaCompl": "Full Designation",
			"codClassNaCProf": "National Profession Code"
		}
	},
	"protecaoPassword": {
		"modal": {
			"save": "The chosen password type was saved successfully",
			"tipoPassword": "Do you password-protect receipts to be sent by email?",
			"title": "Select type of protection"
		}
	},
	"qivaas": {
		"title_detail": "Annual VAT declaration field {{id}}",
		"title_new": "New Field of the annual VAT declaration",
		"title_plural": "Annual VAT Declaration Fields",
		"pesquisa": "To look for",
		"saved": "Annual VAT declaration field {{id}}, saved successfully.",
		"error": "Annual VAT declaration field {{id}}, cannot be saved.",
		"deleted": "Annual VAT declaration field {{id}}, successfully deleted.",
		"fields": {
			"nCampo": "Field Code",
			"nCampoPlaceholder": "Annual VAT declaration field code",
			"nome": "Name",
			"nomePlaceholder": "Field name of the annual VAT return",
			"nQuadro": "Frame No.",
			"nQuadroPlaceholder": "Table No. of the annual VAT declaration field"
		}
	},
	"qivaps": {
		"title_detail": "Periodic VAT declaration field {{id}}",
		"title_new": "New periodic VAT declaration field",
		"title_plural": "Periodic VAT Declaration Fields",
		"pesquisa": "To look for",
		"saved": "Periodic VAT declaration field {{id}}, saved successfully.",
		"error": "Periodic VAT declaration field {{id}}, cannot be saved.",
		"deleted": "Periodic VAT declaration field {{id}}, successfully deleted.",
		"fields": {
			"nCampo": "Field Code",
			"nCampoPlaceholder": "Periodic VAT declaration field code",
			"nomeCampo": "Name",
			"nomeCampoPlaceholder": "Field name of the periodic VAT return",
			"nQuadro": "Frame No.",
			"nQuadroPlaceholder": "Table No. of the periodic VAT declaration field",
			"nCampoOficial": "Official Field Code",
			"nCampoOficialPlaceholder": "Official code for the periodic VAT declaration field"
		}
	},
	"qpapa": {
		"title_detail": "Employers Association {{id}}",
		"title_new": "New Employers Association",
		"title_plural": "Employers' Associations",
		"pesquisa": "To look for",
		"saved": "Employer Association {{id}}, saved successfully.",
		"error": "Employer Association {{id}}, cannot be saved.",
		"deleted": "Employer Association {{id}}, successfully deleted",
		"fields": {
			"codAssocPatronal": "Code.",
			"codAssocPatronalPlaceholder": "Employers' Association Code",
			"nomeAssocPatronal": "Name",
			"nomeAssocPatronalPlaceholder": "Name",
			"morada": "Household",
			"moradaPlaceholder": "Household",
			"codPostal": "Postal Code",
			"codPostalPlaceholder": "Postal Code",
			"localidade": "Location",
			"localidadePlaceholder": "Location"
		}
	},
	"qpcae": {
		"title_detail": "Economic Activity Code {{id}}",
		"title_new": "New Economic Activity",
		"title_plural": "Economic Activity Codes",
		"pesquisa": "To look for",
		"saved": "Economic activity {{id}}, saved successfully.",
		"error": "Economic activity {{id}}, cannot be saved.",
		"deleted": "Economic activity {{id}}, successfully deleted",
		"fields": {
			"codcae": "Code.",
			"codcaePlaceholder": "CAE Code",
			"nome": "Name",
			"nomePlaceholder": "Name",
			"codSubSec": "Sub-Section",
			"codSubSecPlaceholder": "Sub-Section"
		}
	},
	"qpcnp": {
		"title_detail": "Profession {{id}}",
		"title_new": "New profession",
		"title_plural": "Professions",
		"pesquisa": "To look for",
		"saved": "Profession {{id}}, saved successfully.",
		"error": "Profession {{id}}, cannot be saved.",
		"deleted": "Profession {{id}}, successfully deleted.",
		"fields": {
			"codNacProfissao": "Code",
			"nome": "Name",
			"tipo": "Type"
		}
	},
	"qpdcf": {
		"title_detail": "District/Municipality/Parish Code {{id}}",
		"title_new": "New District/Municipality/Parish Code",
		"title_plural": "District/County/Parish Codes",
		"pesquisa": "To look for",
		"saved": "District/Municipality/Parish Code {{id}}, saved successfully.",
		"error": "District/Municipality/Parish Code {{id}}, cannot be saved.",
		"deleted": "District/Municipality/Parish Code {{id}}, successfully deleted",
		"fields": {
			"codDistConcFreg": "Code.",
			"codDistConcFregPlaceholder": "District/County/Parish Code",
			"nome": "Name",
			"nomePlaceholder": "Name",
			"codDistrito": "District Code",
			"codDistritoPlaceholder": "District Code",
			"codConcelho": "Council Code",
			"codConcelhoPlaceholder": "Council Code",
			"codFreguesia": "Parish Code",
			"codFreguesiaPlaceholder": "Parish Code"
		}
	},
	"qpdur": {
		"title_detail": "Personal Board Duration {{id}}",
		"title_new": "New Personal Framework Duration",
		"title_plural": "Personal Boards Duration",
		"pesquisa": "To look for",
		"saved": "Personal Profile Duration {{id}}, saved successfully.",
		"error": "Personal Profile Duration {{id}}, cannot be saved.",
		"deleted": "Personal Profile Duration {{id}}, successfully deleted.",
		"fields": {
			"codDuracao": "Duration Code",
			"nome": "Name"
		}
	},
	"qphab": {
		"title_plural": "Qualifications",
		"pesquisa": "To look for",
		"fields": {
			"codHabilitacao": "Qualification code",
			"nome": "Name"
		}
	},
	"qpirt": {
		"title_detail": "IRCT search {{id}}",
		"title_new": "New IRCT Research",
		"title_plural": "IRCT",
		"pesquisa": "To look for",
		"saved": "IRCT search {{id}}, saved successfully.",
		"error": "IRCT search {{id}}, cannot be saved.",
		"deleted": "IRCT search {{id}}, deleted successfully",
		"fields": {
			"codIRCT": "IRCT Code",
			"nome": "IRCT name"
		}
	},
	"qpisp": {
		"title_detail": "ISP code {{id}}",
		"title_plural": "ISP codes",
		"pesquisa": "To look for",
		"fields": {
			"codISP": "ISP code",
			"nome": "Name"
		}
	},
	"qpmte": {
		"title_detail": "Employee Entry Reason {{id}}",
		"title_new": "New Reason for Employee Entry",
		"title_plural": "Reasons for Employee Entry",
		"pesquisa": "To look for",
		"saved": "Employee Entry Reason {{id}}, saved successfully.",
		"error": "Employee Entry Reason {{id}}, cannot be saved.",
		"deleted": "Employee Entry Reason {{id}}, successfully deleted",
		"fields": {
			"codmte": "MTE Code",
			"nome": "Name"
		}
	},
	"qpmts": {
		"title_detail": "Reason for Employee Leaving {{id}}",
		"title_new": "New Reason for Employee Leaving",
		"title_plural": "Reasons for Employee Leaving",
		"pesquisa": "To look for",
		"saved": "Reason for Leaving Employee {{id}}, saved successfully.",
		"error": "Reason for Leaving Employee {{id}}, cannot be saved.",
		"deleted": "Reason for Leaving Employee {{id}}, successfully deleted",
		"fields": {
			"codmts": "MTS code",
			"nome": "Name"
		}
	},
	"qpnj": {
		"title_detail": "Legal Nature {{id}}",
		"title_new": "New Legal Nature",
		"title_plural": "Legal Natures",
		"pesquisa": "To look for",
		"saved": "Legal Nature {{id}}, saved successfully.",
		"error": "Legal Nature {{id}}, cannot be saved.",
		"deleted": "Legal Nature {{id}}, successfully deleted",
		"fields": {
			"codNatJuridica": "Code.",
			"codNatJuridicaPlaceholder": "Legal Nature Code",
			"nome": "Name",
			"nomePlaceholder": "Name"
		}
	},
	"qppaises": {
		"title_detail": "Country {{id}}",
		"title_new": "New Country",
		"title_plural": "Personnel Countries",
		"pesquisa": "To look for",
		"saved": "Country {{id}}, saved successfully.",
		"error": "Country {{id}}, cannot be saved.",
		"deleted": "Country {{id}}, successfully deleted.",
		"fields": {
			"codPais": "Code.",
			"name": "Name",
			"abrev": "Abbreviation."
		}
	},
	"qpsit": {
		"title_detail": "Situation {{id}}",
		"title_new": "New Situation",
		"title_plural": "Situations",
		"pesquisa": "to look for",
		"saved": "Situation {{id}}, saved successfully.",
		"error": "Situation {{id}}, cannot be saved.",
		"deleted": "Status {{id}}, deleted successfully",
		"fields": {
			"codsituacao": "Status Code",
			"nome": "Name"
		}
	},
	"qptco": {
		"title_detail": "Personnel Contract Type {{id}}",
		"title_new": "New Staff Contract Type",
		"title_plural": "Personnel Contract Type",
		"pesquisa": "To look for",
		"saved": "Personal Profile Contract Type {{id}}, saved successfully",
		"error": "Personal Profile Contract Type {{id}}, cannot be saved.",
		"deleted": "Personal Profile Contract Type {{id}}, successfully deleted",
		"fields": {
			"codTContrato": "Personnel Code Type of Contract",
			"nome": "Name"
		}
	},
	"rastreabilidade": {
		"title": "Traceability",
		"export": {
			"svg": "Export SVG",
			"jpg": "Export JPG",
			"png": "Export PNG"
		}
	},
	"recaptcha": {
		"branding": {
			"1": "This site is protected by reCAPTCHA and applies to",
			"2": "from Google.",
			"privacy": "Privacy Policy",
			"and": "and the",
			"terms": "Service Terms"
		},
		"v2modal": {
			"title": "Please check that you are not a robot"
		}
	},
	"reciboimprimir": {
		"label": {
			"emailcliente": "Customer email",
			"enviar": "To send"
		},
		"email": {
			"required": "To send, please fill in the field \"<em>Customer email",
			"emailEnviado": "Email sent to {{email}}",
			"emailErro": "Error sending email to {{email}}."
		}
	},
	"recibos": {
		"title_new": "New receipt",
		"title_detail": "New receipt {{id}}",
		"title_plural": "Receipts",
		"pesquisa": "To look for",
		"saved": "Receipt saved successfully.",
		"error": "The receipt cannot be saved.",
		"fields": {
			"extPocCabID": "Id.",
			"numero": "Receipt No.",
			"nome": "Client",
			"nconta": "Account",
			"data": "Date",
			"valor": "Total",
			"stampUpdate": "Last.",
			"origem": "Document origin",
			"descricao": "Description",
			"abreviaturaMoeda": "Coin",
			"deData": "Of Date",
			"ateData": "Until Date",
			"nDocExterno": "External Doc. No."
		},
		"text": {
			"autoDistribute": "Automatically distribute value",
			"amountToReceive": "Amount to be received"
		},
		"recibo": {
			"title": "Receipt",
			"nCaixaAux": "Payment method",
			"nomeCaixaAux": "Name means payment",
			"tipoCaixaAux": "Half payment type",
			"valorNCaixa": "Value",
			"totalCaixa": "Delivered",
			"troco": "Change",
			"cliente": "Client:",
			"data": "Date:",
			"percDescSobreValorIVA": "Discount on VAT value",
			"cab": {
				"extPocCabID": "header ID",
				"numero": "Number",
				"nConta": "Account",
				"nome": "Name",
				"nif": "NIF",
				"data": "Date",
				"valor": "Value",
				"valorPlaceHolder": "Amount to be received",
				"stampUpdate": "Change date",
				"totalDescontos": "Full discounts",
				"totalReceber": "Total receivable",
				"totalRecebido": "Total received",
				"total": "Total",
				"temRetencao": "Has retention",
				"totalRetencao": "Full retention",
				"descricao": "Description",
				"resumo": "Summary",
				"valorEntregueCredito": "Total delivered on credit"
			},
			"linhas": {
				"nlanc": "line ID",
				"tipoDocumento": "Document Type",
				"numeroDocumento": "Document",
				"dataVencimento": "Expiration date",
				"valor": "Value",
				"valorPorReceber": "Value to receive",
				"percDesconto": "% Discount",
				"valorDesconto": "Discount value",
				"valorRecebido": "Amount received",
				"valorIVA": "VAT value",
				"montanteAReter": "Retention",
				"montanteRetido": "Retention value",
				"cambio": "Exchange",
				"cambioOrigem": "Origin exchange rate",
				"cambioPagam": "Exchange payment",
				"dataDoc": "Doc date.",
				"dataDocExterno": "Doc date.",
				"nDocExterno": "Doc no.",
				"descricao": "Description"
			},
			"meiosPagamento": {
				"nCaixa": "Payment method",
				"nomeCaixa": "Payment method name",
				"valor": "Value",
				"tipo:": "Type of payment method"
			}
		},
		"saveModal": {
			"title": "Save receipt",
			"paymentMeans": "Payment options",
			"paymentMean": "Payment method",
			"paymentValue": "Value delivered",
			"payInFull": "Pay in full",
			"clearValue": "Clear value",
			"document": "Document",
			"save": "Save",
			"saveCheck": "Save and view PDF"
		},
		"imprimirModal": {
			"title": "Receipt - {{accountname}}",
			"fields": {
				"numeroDeVias": "Number of lanes",
				"emailCliente": "Email",
				"relatorio": "Report",
				"listagem": "Listing"
			},
			"actions": {
				"send": "To send"
			},
			"placeholder": {
				"email": "\"mail@example.com\" or \"mail@example.com; mail2@example.com\""
			}
		},
		"info": {
			"anular": "Receipt canceled successfully!",
			"anularRecibo": "Cancel receipt",
			"temCertezaAnularRecibo": "Are you sure you want to delete the receipt?",
			"naoefetuadoCG": "The receipt cannot be canceled because it was not made in CentralGest Cloud",
			"semMovimentos": "No open moves!"
		},
		"erros": {
			"notselected": "No receipt selected!",
			"anular": "Error canceling receipt!",
			"valorDescontoMaiorValor": "Discount value higher than the amount received",
			"percentagemDescontoMaior100": "The discount percentage cannot exceed 100%",
			"invalidValorAPagar": "The value delivered ({{valorEntregue}}) must be equal to or greater than the value of the document ({{valorDocumento}}).",
			"moedasdiferentesnosrecibos": "It is not possible to have different currencies in the receipts to be posted",
			"errodesconhecido": "Unknown error",
			"cambiocomvalorerrado": "Wrong exchange rate",
			"invalidHeaderDate": "The receipt date cannot be more than 1 year from the current date"
		},
		"resumo": {
			"title": "Summary",
			"somatorio": "Total"
		},
		"btn": {
			"receberTudo": "Receive everything",
			"limpaTudo": "Clear all received values",
			"distribuirValores": "Automatically distribute value",
			"limpaValor": "Clear received amount"
		}
	},
	"recibosvencimentos": {
		"todos": "All collaborators:",
		"colaborador": "Collaborator:",
		"gravado": "Recorded",
		"reciboimpresso": "Printed Receipt",
		"processadosegsocial": "Processed by Social Security",
		"integradocontabilidade": "Integrated into Accounting",
		"email": {
			"emailEnviado": "Email successfully sent!",
			"emailNaoEnviado": "An error occurred while sending the email.",
			"insiraemail": "You must enter an email."
		},
		"modal": {
			"enviaremail": "Email",
			"byEmailAlternativo": "Alternative email",
			"emailAlternativo": "Email"
		},
		"listagem": "Listing"
	},
	"reconstrucaoacumulados": {
		"title": "Accumulated Reconstruction",
		"pesquisa": "To look for",
		"steps": {
			"info": "Start of Accumulated Reconstruction",
			"variables": "Data to reconstruct the accumulated",
			"finish": "Finish"
		},
		"fields": {
			"tipoPoc": "Account Type",
			"nConta": "Account No.",
			"contaEspecifica": "Only accumulated from the account",
			"todasAsContas": "All Accounts"
		},
		"description": {
			"firstParagraph": "This operation will reconstruct all accumulated accounts in the chart of accounts.",
			"secondParagraph": "This operation is exclusive.",
			"finishMessage": "This operation may take some time, to continue press finish.",
			"atencao": "Attention!"
		},
		"alerts": {
			"reconstrucaoSucesso": "Accumulation reconstruction completed successfully"
		}
	},
	"reembolsosiva": {
		"title_detail": "Refund for declaration period \"{{id}}\"",
		"title_detail_relacao": "Refund for relationship period \"{{id}}\"",
		"title_new": "New Refund",
		"title_plural": "VAT Refunds",
		"pesquisa": "To look for",
		"saved": "VAT refund with declaration period \"{{id}}\" processed successfully.",
		"savedAlt": "VAT refund with declaration period \"{{id}}\" successfully reprocessed.",
		"error": "VAT refund with declaration period \"{{id}}\" cannot be processed.",
		"errorEmptyPeriodosRelacao": "There are no relationship periods available for the selected statement period",
		"errorClosed": "Attention: the selected declaration period belongs to an accounting year that has already ended",
		"pristineEmptyPeriodosRelacao": "Please select a reporting period to choose a reporting period",
		"deleteReembolsoTitle": "Erase VAT refund?",
		"deleteReembolsoMessage": "Are you sure you want to delete the VAT refund with the declaration period \"{{declaracao}}\"?",
		"deleteReembolsoComplexMessage": "Are you sure you want to delete the VAT refund with the declaration period \"{{declaracao}}\" and relationship period \"{{relacao}}\"?",
		"deleteDocumentTitle": "Delete the document?",
		"deleteDocumentMessage": "Are you sure you want to delete the document with the declaration period \"{{declaracao}}\" and relationship period \"{{relacao}}\"?",
		"process": "Process",
		"reProcess": "Reprocess refund",
		"disabledHasErrors": "It is not possible to change the relationship period if there are errors in the documents",
		"document": "Document No.:",
		"documentNoErrors": "The document has no errors",
		"documentHasErrors": "The document has errors:",
		"fields": {
			"periodoDeclaracao": "Declaration period",
			"periodoRelacao": "Relationship period",
			"periodosRelacao": "Relationship periods",
			"nCampoDeducao": "Deduction Field No.",
			"mercado": "Market",
			"periodo": "Period",
			"nDiario": "Daily",
			"nDocInterno": "Document No.",
			"codPais": "Country Code",
			"abrevFiscal": "Prefix",
			"nContribuinte": "Contribution No.",
			"dC": "Debit credit",
			"valorTransacaoAbsoluto": "Acquisition value without VAT",
			"valorIvaDeduzidoAbsoluto": "Value of VAT Deducted",
			"nDocExterno": "External Doc",
			"dataDocExterno": "External Doc Date",
			"nDeclarImportExport": "Liquid No.",
			"nConta": "Account No.",
			"nomeConta": "Name",
			"anoDocExterno": "External Doc Year",
			"mesDocExterno": "External Doc Month",
			"all": "All relationship periods",
			"error": "Document error"
		},
		"tabs": {
			"clients": "Customers",
			"suppliers": "Suppliers",
			"regularizations": "Regularizations",
			"documents": "Documents",
			"relations": "Relations",
			"clientDoc": "Customers_Documents_{{nreembolso}}",
			"clientRel": "Customers_Relacoes_{{nreembolso}}",
			"suppliersDoc": "Customers_Documents_{{nreembolso}}",
			"suppliersRel": "Customers_Relacoes_{{nreembolso}}"
		},
		"messages": {
			"successDeleteDocument": "Successfully deleted VAT refund"
		},
		"clientes": {
			"0": {
				"periodo": "Period"
			},
			"1": {
				"title": "Operations carried out with national customers",
				"datagrid": {
					"nif": "Tax Identification Number",
					"valor": "Value"
				}
			},
			"3": {
				"title": "Export of goods",
				"datagrid": {
					"nIdnDeclaracaoExportacao": "Export Declaration Identification Number",
					"valor": "Value"
				}
			},
			"4": {
				"title": "Operations carried out abroad",
				"valor": "Value"
			},
			"5": {
				"title": "Other transactions exempt or without VAT assessment that give the right to deduction",
				"valor": "Value"
			},
			"6": {
				"total": "Total",
				"valor": "Value"
			},
			"title": "List of customers whose operations resulted in tax deducted for the period"
		},
		"fornecedores": {
			"0": {
				"periodo": "Period"
			},
			"1": {
				"campo20": "Field 20"
			},
			"2": {
				"campo21": "Field 21"
			},
			"3": {
				"campo22": "Field 22"
			},
			"4": {
				"campo23": "Field 23"
			},
			"5": {
				"campo24": "Field 24"
			},
			"7": {
				"total": "Total",
				"totalValorAquisicoes": "Total Value of Acquisitions",
				"totalIvaDeduzido": "Total VAT Deducted"
			},
			"title": "List of suppliers whose operations resulted in tax deducted for the period",
			"datagrid": {
				"identificacao": "Identification",
				"periodoEmissao": "Issuance period",
				"prefixo": "Prefix",
				"nif": "NIF",
				"nLiquidacao": "Settlement No.",
				"ano": "Year",
				"mes": "Month",
				"valorAquisicoes": "Value of Acquisitions",
				"ivaDeduzido": "VAT Deducted"
			}
		},
		"mercadoControloIva": {
			"notApplicable": "AT",
			"national": "National",
			"cee": "HUH",
			"thirdPartyCountries": "Third countries"
		},
		"action": {
			"editDoc": "Edit document",
			"deleteLine": "Delete line"
		},
		"showDocsWithErrorOnly": "Show only documents with errors"
	},
	"refeicoes": {
		"text": {
			"local": "Local",
			"menu": "Menu",
			"marcar": "To mark",
			"desmarcar": "Mark off",
			"refeicoes": "Meals"
		},
		"clonarmodal": {
			"title": "Clone menus",
			"fields": {
				"localDestino": "Destination location",
				"dataInicio": "Start date",
				"dataFim": "End date"
			}
		}
	},
	"regIRC": {
		"data": {
			"geral": "General",
			"naoSujeicao": "Non-Subjection",
			"isencaoDefinitiva": "Definitive Exemption",
			"isencaoTemporaria": "Temporary Exemption",
			"reducaoTaxa": "Rate Reduction",
			"transicaoBenificiosFiscais": "Transition Tax Benefits",
			"transparenciaFiscal": "Fiscal Transparency",
			"lucroConsolidado": "Consolidated Profit",
			"simplificado": "Simplified",
			"normal": "Normal",
			"isencao": "Exemption",
			"pequenosRetalhistas": "Small Retailers",
			"regimeNormalBensSegMao": "Normal Regime + 2nd Hand Goods",
			"regimeNormalRevendaCombustiveis": "Normal Regime + Fuel Resale",
			"regimeNormalIvaCaixa": "Normal Regime + Cash VAT"
		}
	},
	"rendhabitperm": {
		"fields": {
			"codEmp": "Employee",
			"codAbdes": "Allowance",
			"anoProcessamento": "Year of processing",
			"valorRend": "Income value",
			"rhTipoProce": "Processing"
		},
		"filename": "Income from permanent housing",
		"tipoRendHabitPerm": {
			"todas": "All",
			"ativas": "Active",
			"inativas": "Inactive"
		},
		"toolbar": {
			"tipoRendHabitPerm": "View Yield."
		},
		"modal": {
			"title": "Maintenance of income through the use of permanent housing",
			"fields": {
				"tipoProcessamento": "Type of processing",
				"codAbdes": "Allowance",
				"codEmp": "Employee Code",
				"anoProcessamento": "Year of processing",
				"mesInicioProce": "Start month",
				"suspende": "Suspended",
				"dataSuspensao": "Date",
				"valor": "Value",
				"obs": "Comments",
				"obsSupencao": "Observations Suspension",
				"valorRenda": "Income value",
				"valorRendaMaxMuni": "Maximum Income Value (By Municipality Typology)"
			},
			"message": {
				"msgRendaMaxMuni": "Maximum income allowed for the year 2024 in accordance with the provisions of decree-law no. 90-c/2022, of December 30th (ordered by municipality)"
			}
		}
	},
	"rendis": {
		"title_detail": "Income type {{id}}",
		"title_new": "New type of income",
		"title_plural": "Types of income",
		"pesquisa": "To look for",
		"saved": "Saved successfully.",
		"error": "Income type {{id}}, cannot be saved.",
		"deleted": "Income type {{id}}, successfully deleted.",
		"deleteModalText": "Delete record \"{{tipRendiment}}\" ?",
		"fields": {
			"tipRendiment": "Type of Income",
			"nome": "Yield Name",
			"tipRendimentId": "Income Type Code",
			"tipoDeclaracao": "Declaration type"
		}
	},
	"tiposDeclaracao": {
		"data": {
			"modelo10": "Model 10",
			"modelo22": "Model 22",
			"modelo30": "Model 30",
			"modelo39": "Model 39"
		}
	},
	"repfinancas": {
		"title_detail": "Finance Department {{id}}",
		"title_new": "New Finance Department{{id}}",
		"title_plural": "Tax Offices",
		"pesquisa": "To look for",
		"saved": "Tax Office {{id}}, saved successfully",
		"error": "Tax Office {{id}}, cannot be saved.",
		"deleted": "Tax Office {{id}}, successfully deleted",
		"fields": {
			"codFiscal": "Division Code",
			"nome": "Name of the Department",
			"tipo": "Type of Finance",
			"morada": "Household",
			"codPostal": "Postal Code",
			"codDistrito": "District Code",
			"nomeDistrito": "District Name",
			"codPais": "Country code",
			"nomePais": "Name of the country",
			"telefone": "Telephone",
			"fax": "Fax",
			"codFinancasAT": "AT Finance Code"
		},
		"tipo": {
			"bairroFiscal": "Tax District",
			"reparticao": "Breakdown"
		}
	},
	"reparticoesccustos": {
		"title_detail": "Breakdown by Cost Center {{id}}",
		"title_new": "New Breakdown by Cost Center",
		"title_plural": "Breakdowns by Cost Center",
		"pesquisa": "To look for",
		"add": "To add",
		"saved": "Breakdown by Cost Center {{id}}, saved successfully",
		"error": "Breakdown by Cost Center {{id}}, cannot be saved.",
		"deleted": "Breakdown by Cost Center {{id}}, successfully deleted",
		"fields": {
			"nCodRepCC": "Table:",
			"descricao": "Description:",
			"designacao": "Designation:",
			"nCodRepCCPlaceholder": "Table:",
			"descricaoPlaceholder": "Designation:",
			"valorMascara": "Cost Center",
			"perc": "Percentage",
			"dataReferencia": "Reference Date",
			"mascara": "Mascara C. Cost"
		},
		"mascara": {
			"cCusto": "Center Cost:",
			"zona": "Zone:",
			"departamento": "Department:",
			"SubDepartamento": "Sub-Department:",
			"familia": "Family:",
			"grandeFamilia": "Gr. Family:",
			"subFamilia": "Subfamily:",
			"codContab": "Accounting Code:",
			"tipoArtigo": "Article Type:",
			"classe": "Class:",
			"categoria": "Category:",
			"vendedor": "Seller:",
			"nRefProcesso": "Process:"
		},
		"title": {
			"apagarDatadeReferencia": "Delete reference date",
			"apagarItemPerDate": "Remove Date",
			"apagarItem": "Remove Breakdown by Cost Center"
		},
		"message": {
			"desejaApagarDataReferencia": "Do you want to delete the reference date?",
			"reparticaoccustoEliminadoComSucesso": "Breakdown by Cost Center Successfully Eliminated",
			"desejaApagarItemPerDate": "If you remove the last line of the selected date, it will be deleted.",
			"desejaApagarItem": "If you remove the last line from the Cost Center Breakdown, it will be deleted."
		},
		"text": {
			"addDate": "Add date",
			"selectDate": "Select date"
		}
	},
	"reparticoesccustosfaturacao": {
		"title_detail": "Breakdown by Cost Center {{id}}",
		"title_new": "New Breakdown by Cost Center",
		"title_plural": "Breakdowns by Cost Center",
		"pesquisa": "To look for",
		"saved": "Breakdown by Cost Center {{id}}, saved successfully",
		"error": "Breakdown by Cost Center {{id}}, cannot be saved.",
		"deleted": "Breakdown by Cost Center {{id}}, successfully deleted"
	},
	"reparticoesccustossalarios": {
		"title_detail": "Breakdown by Cost Center {{id}}",
		"title_new": "New Breakdown by Cost Center",
		"title_plural": "Breakdowns by Cost Center",
		"pesquisa": "To look for",
		"saved": "Breakdown by Cost Center {{id}}, saved successfully",
		"error": "Breakdown by Cost Center {{id}}, cannot be saved.",
		"deleted": "Breakdown by Cost Center {{id}}, successfully deleted"
	},
	"reports": {
		"pesquisa": "To look for",
		"fields": {
			"name": "Name",
			"namePlaceholder": "Report name",
			"title": "Title",
			"titlePlaceholder": "Report title"
		},
		"messages": {
			"noReports": "No reports found"
		}
	},
	"resumoiva": {
		"fields": {
			"codigo": "Code",
			"nome": "Name",
			"infoString": "NIF",
			"valor": "Value",
			"percentagem": "%",
			"tipodocumento": "Document Type",
			"iva": "VAT",
			"basetributavel": "Taxable base",
			"total": "Total",
			"totais": "Totals"
		},
		"totalvendas": "Sales amount",
		"btn": {
			"ano": "Year",
			"mes": "Month",
			"todosmeses": "All",
			"primeiroTrimestre": "1st Quarter",
			"segundoTrimestre": "2nd Trimester",
			"terceiroTrimestre": "3rd Trimester",
			"quartoTrimestre": "4th Trimester"
		},
		"toolbar": {
			"month": "Month: {{nameMonth}}"
		}
	},
	"retencoes": {
		"header": {
			"tipoVisualizacao": "View Type",
			"nifDe": "VAT Number",
			"nifAte": "Until",
			"todos": "All",
			"dataDe": "Date of",
			"dataAte": "Date until",
			"tipRetServNif": "Entity usually",
			"estadoRet": "Retention status",
			"origemContas": "Account origin",
			"radio": {
				"retEfeutadas": "Withholdings made",
				"docsSujeitosRet": "Docs.",
				"sujeitoRetencao": "To be done",
				"retido": "Performed",
				"ambos": "Both"
			}
		},
		"table": {
			"fields": {
				"isContaPocOrigemFornecedor": "Origin",
				"nContribuinte": "VAT Number",
				"conta": "Account",
				"codRetencao": "Retention",
				"taxaRetUsada": "Rate",
				"tipoRendimento": "Yield Type",
				"periodo": "Period",
				"nDiario": "Daily",
				"nDocInterno": "Document No.",
				"descricaoDC": "Debit credit",
				"nDocExterno": "External Doc. No.",
				"valorOrigem": "Incidence Base Value",
				"valorDestino": "Value to Retain",
				"valorDestDispo": "Retained Value",
				"dataDoc": "Document Date"
			}
		},
		"doc": "View Document",
		"config": {
			"showColumnIsContaPocOrigemFornecedor": "Show origin",
			"showColumnNContribuinte": "Show Taxpayer Number",
			"showColumnConta": "Show account",
			"showColumnCodRetencao": "Show retention",
			"showColumnTaxaRetUsada": "Show rate",
			"showColumnTipoRendimento": "Show income type",
			"showColumnPeriodo": "Show Period",
			"showColumnNDiario": "Show Daily No.",
			"showColumnNDocInterno": "Show Internal Doc. No.",
			"showColumnDescricaoDC": "Show Debit/Credit",
			"showColumnDataDoc": "Show Date",
			"showColumnNDocExterno": "Show External Doc No.",
			"showColumnValorOrigem": "Show base incidence value",
			"showColumnValorDestino": "Show value to retain",
			"showColumnValorDestDispo": "Show withheld amount"
		}
	},
	"rhMapasSindicatos": {
		"btn": {
			"previsualizar": "Preview"
		},
		"filters": {
			"listagem": "Listing",
			"dedata": "By date",
			"atedata": "Until date",
			"deSindicato": "By union",
			"ateSindicato": "Even union"
		}
	},
	"rhtipoprocess": {
		"title_detail": "Processing Type {{id}}",
		"title_new": "New Processing Type",
		"title_plural": "Types of Processing",
		"pesquisa": "To look for",
		"saved": "Type Processing {{id}}, saved successfully.",
		"error": "Processing Type {{id}}, cannot be saved.",
		"deleted": "Type Processing {{id}}, deleted successfully.",
		"fields": {
			"tipoProcessamento": "Processing Type",
			"descricao": "Description",
			"dadosVencimento": "Expiration Data",
			"dadosManuais": "Manual Data",
			"dadosFixos": "Fixed Data",
			"subsidioRefeicao": "Meal Subsidy",
			"diuturnidades": "Seniority payments",
			"subsidioNatal": "Christmas allowance",
			"subsidioFerias": "Vacation Allowance",
			"valorCaducidadeCTR": "Expiry Value Term Contract",
			"tipoPeriodicidade": "Periodicity",
			"desativado": "Processing Type Disabled",
			"func": "Function"
		}
	},
	"rhagencias": {
		"onlyativos": "Show only assets",
		"onlyiban": "Show only with IBAN",
		"fields": {
			"codAgencia": "Agency code",
			"nome": "Name",
			"iban": "IBAN",
			"descricao": "Description"
		},
		"btn": {
			"addAgencia": "Add agency",
			"addBanco": "Add bank",
			"addConta": "Add account",
			"editAgencia": "Edit agency",
			"editBanco": "Edit bank",
			"editConta": "Edit account",
			"deleteAgencia": "Delete agency",
			"deleteBanco": "Delete bank",
			"deleteConta": "Delete account"
		},
		"messages": {
			"delAgencia": "Are you sure you want to delete the agency?",
			"delBanco": "Are you sure you want to delete the bank?",
			"delConta": "Are you sure you want to delete the account?",
			"delsuccAgencia": "Agency successfully deleted!",
			"delsuccConta": "Account deleted successfully!",
			"newSuccAgencia": "Agency added successfully!",
			"newSuccConta": "Account added successfully!",
			"updateSuccAgencia": "Agency updated successfully!",
			"updateSuccConta": "Account updated successfully!",
			"confirm": "Confirmation"
		},
		"modal": {
			"agencia": {
				"title": "Agencies",
				"fields": {
					"banco": "Bank",
					"codAgencia": "Agency code",
					"nome": "Name",
					"descBreve": "Brief description",
					"morada": "Household",
					"codPostal": "Postal Code",
					"localidade": "Location",
					"telefone": "Telephone",
					"contacto": "Contact",
					"contaagencia": "Interb ID.",
					"fax": "Fax",
					"estado": "state",
					"tipoInstal": "Installation type",
					"sede": "Thirst",
					"agencia": "Agency"
				}
			},
			"conta": {
				"title": "Accounts",
				"fields": {
					"banco": "Bank",
					"agencia": "Agency",
					"nib": "NIB",
					"nContaPoc": "Account no.",
					"gestorBanco": "Manager",
					"descricao": "Description",
					"nProjecto": "Project number",
					"iban": "IBAN",
					"swift": "SWIFT",
					"debitoDirectoID": "Direct debit ID",
					"nContaEmprestimos": "No. C. loans",
					"codPais": "Country code",
					"status": "Account status",
					"validaIBAN": "Validates IBAN"
				}
			}
		}
	},
	"rhintegracaosalarios": {
		"titles": {
			"dadosadicionaisint": "Additional data for Salary Integration",
			"dadosadicionaisuni": "Additional data for the Single Report",
			"dadosadicionaisuniint": "Additional data for the Single Report and Salary Integration",
			"confgintegracaosalarios": "Salary Integration Setup",
			"mascaraanalitica": "Analytical Mask"
		},
		"fields": {
			"integraSalarios": "Salary Integration",
			"pocRemuLiq": "Net receivable account",
			"classeTrab": "Work class",
			"funcao": "Function",
			"operacaoSeccao": "Operation/Section",
			"codZona": "Zone Code",
			"departamento": "Department",
			"subDepartamento": "Sub.",
			"centroCusto": "cost center",
			"reparticaoCentroCusto": "Cost center breakdown",
			"integraRU": "Integrates into the single report",
			"profissao": "Profession",
			"situacaoProfissao": "Profession situation",
			"codCCT": "CCT Code",
			"nivelQualif": "Qualification Level",
			"categoria": "Category",
			"integraSalariosPorDefeito": "Normal Integration",
			"integraSalariosPorOrgaoSocial": "Integration by Social Bodies/Staff",
			"validamascara": "It has configuration by Analytical Mask",
			"mascara": "Mask C. Cost"
		},
		"tab": {
			"integracaosalarios": "Salary Integration",
			"mascaraanaliticaabono": "Analytical Mask Allowances",
			"mascaraanaliticadesconto": "Analytical Mask Discounts"
		},
		"message": {
			"savedsuccessfully": "Data saved successfully!",
			"resetsuccessfully": "Data responded successfully!",
			"info": "Information",
			"retiraropcaointegsal": "By removing this option you will no longer have Salary Integration."
		},
		"btn": {
			"repor": "Reset"
		},
		"configApoiosCOVID19": "Configuration Support to be received Social Security - COVID-19",
		"configApoiosCOVID19Fields": {
			"card": {
				"apoioexcepcionalfamilia": "Exceptional family support for employees (COVID-19)",
				"apoioLayoffSimplificadoPSuspensaoTrabalho": "Simplified Lay Off Support due to work suspension (COVID-19)",
				"apoioLayoffSimplificadoPReducaoTrabalho": "Simplified Lay Off Support due to work reduction (COVID-19)"
			},
			"nContaCovid19ApoioFamilia": "Account No.",
			"nContaCovid19ApoioFamiliaPerc": "Perc.",
			"nContaCovid19LayoffSimplSuspencao": "Account No.",
			"nContaCovid19LayoffSimplSuspencaoPerc": "Perc.",
			"nContaCovid19LayoffSimplParcial": "Account No.",
			"nContaCovid19LayoffSimplParcialPerc": "Perc.",
			"mensagemPercDeApoioAtribuidoSS": "% of Support Granted by Social Security"
		}
	},
	"rhatualizaferiasemp": {
		"atualizadatacadastro": "Update registration date",
		"fields": {
			"empregado": "Employee",
			"feriasano": "Number of vacation days in the year {{year}}",
			"temregano": "Has registration in the year {{year}}",
			"codEmp": "Employee",
			"nomeEmpregado": "Name",
			"anoAnteriorNDiasFeriasAnoAnterior": "Last year",
			"anoAnteriorNDiasFeriasAnoAtual": "Year",
			"anoAnteriorGozadasNDiasFeriasAnoAnterior": "Cumshots last year",
			"anoAnteriorGozadasNDiasFeriasAnoAtual": "Cumshots in the year",
			"nDiasFeriasAnoAnterior": "Last year",
			"nDiasFeriasAnoAtual": "Year",
			"dataMotivoCadastro": "Date register",
			"livre": "Info"
		},
		"messages": {
			"temselecionaremp": "Did not select any employee.",
			"savedsuccess": "Recording completed successfully.",
			"datainferior": "The date cannot be less than the date already registered.",
			"valNegativo": "The value cannot be negative."
		}
	},
	"rhcalculorendimentoliquido": {
		"success": "Calculation by income saved successfully!",
		"header": {
			"ano": "Year"
		},
		"fields": {
			"codEmp": "Employee Code",
			"valorLiquido": "Net value",
			"nCodABDESC": "Allowance",
			"adicionaRecolha": "Add calculated value of the Allowance in Data Collection"
		},
		"steps": {
			"processos": "Law Suit",
			"empregados": "Employees",
			"calcular": "Calculate"
		},
		"titles": {
			"empregados": "Processing Employees",
			"processamentos": "Processing List"
		},
		"empregado": {
			"codEmpregado": "Code.",
			"nomeEmpregado": "Name",
			"strEstadoProcEmpregado": "state",
			"nProcessamento": "Process No.",
			"nRecibo": "Receipt No.",
			"dataProcessamento": "Process Date.",
			"identificacaoEmpregado": "Employee"
		},
		"processo": {
			"nProcessamento": "Process No.",
			"dataProcessamento": "Process Date.",
			"tipoProcessamento": "Process Type.",
			"proceDescricao": "Description",
			"dataInicoProc": "Start Date",
			"dataFimProce": "End Date"
		},
		"messages": {
			"invalidStepProc": "You have to select a process!",
			"invalidStepEmp": "You must select an Employee!"
		},
		"btn": {
			"finalizar": "Calculate"
		}
	},
	"rhcessacontratotrabalho": {
		"fields": {
			"datacessacao": "Termination Date",
			"entidade": "Entity that terminated the contract:",
			"tipoContrato": "Type of contract",
			"datainicio": "Start date",
			"horasSemana": "Hours per week",
			"dataPrimeiraRenovacaoExtra": "Date of 1st extraordinary renewal",
			"retribuicaoBase": "Base Remuneration",
			"diuturnidades": "Seniority payments",
			"complementos": "Add-ons",
			"feriasGozadas": "Vacation already taken",
			"subsidioFerias": "Holiday allowance already received",
			"subSidioNatalJaRecebido": "Christmas allowance already received",
			"codEmp": "Employee Code",
			"duracao": "Duration:",
			"situacao": "New code.",
			"motivoCessacao": "New registration reason",
			"duracaodata": "{{years}} year(s) {{months}} month(s) {{days}} day(s)",
			"tipoProcessamento": "Processing Type:",
			"radio": {
				"empregador": "Employer",
				"trabalhador": "Worker",
				"justacausa": "With just cause?"
			},
			"calculado": {
				"compensacao": "Compensation:",
				"valorFeriasVencidas": "Untaken vacations:",
				"valorSubsidioFerias": "Vacation allowance to be received:",
				"feriasPropValor": "Vacation:",
				"subsidioFeriasPropValor": "Holiday Allowance:",
				"subsidioNatalPropValor": "Christmas subsidy:",
				"compensacaoTotal": "Global amount:"
			}
		},
		"card": {
			"confirmedados": "Confirm the following data",
			"valorescalc": "Calculation Values",
			"valoremfalta": "Vacations - Missing Value",
			"proporcionais": "Proportional in the year of cessation",
			"retribuicao": "Retribution",
			"ferias": "Vacation"
		},
		"btn": {
			"simularcessacao": "Calculate Value",
			"dadosemp": "Employee Data Maintenance",
			"validar": "Validate/Edit Values",
			"manutencaocessacontrato": "Contract Termination Configuration"
		},
		"infos": {
			"tooltipRetribuicaoBase": "Base remuneration corresponds to the worker's activity during the normal working period.",
			"tooltipComplementos": "Regular periodic installments.",
			"tooltipTipoContrato": "A contract for an indefinite period is commonly called effective.",
			"tooltipJustCausa": "Just cause can only be assessed by a court.",
			"tooltipFeriasPropValor": "For calculation purposes, the simulator considers: <br> - the value of the holidays and the respective proportions: basic remuneration, seniority payments and remuneration benefits indicated by the user as being compensation for the specific way in which the work is provided.",
			"tooltipExcecpoes": "Exceptions",
			"tooltipReCalcular": "Calculate Complements",
			"temcalcularvalor": "You must perform the calculation to validate the values.",
			"temcorrigiremp": "You have to correct the employee's mistakes.",
			"insiraemp": "You must enter an employee.",
			"situacaoandmotivodefault": "The default settings for the Cessation Status and Registration Reason are not filled in.",
			"calculadocomplementossuccess": "Complements calculated successfully!",
			"calcsuccess": "Values ​​calculated successfully!",
			"gravadocomsucessonotipoproc": "Expiration data was successfully written to Data Collection in processing type '{{processingtype}}'.",
			"abonodescsuccess": "Allowance/Discount added successfully!"
		},
		"modal": {
			"titleconf": "Information",
			"defsitandmot": "Do you want to set the 'Situation' and 'Reason' codes by default?",
			"encerrar": {
				"cessacaocontrat": "Termination of Employment Contract",
				"table": {
					"nCodABDESC": "Allowance/Desc Code",
					"descricaoAbono": "Description",
					"valor": "Value",
					"observacoes": "Note.",
					"semdados": "No data"
				}
			},
			"complementosexcepcoes": {
				"title": "Exceptions",
				"indiqueexcluidos": "Indicate the items you want to exclude from the selection",
				"possiveis": "Possible:",
				"excluidos": "Excluded:",
				"fields": {
					"codABDESC": "Code",
					"designaBreve": "Name"
				}
			},
			"addabonodesconto": {
				"title": "Add new Allowance/Discount to Termination of Contract",
				"fields": {
					"abonoDesc": "Type",
					"codABDESC": "Code",
					"valor": "Value",
					"observacoes": "Note."
				}
			}
		}
	},
	"rhcopiaabonosdescontos": {
		"fmt": {
			"relatorioLoadABDESCopia": "{{CodAbdes}}-{{NameCodAbdes}}",
			"abonoDescontoACopiar": "Allowance/Discount to be copied: {{abdesCopia}}",
			"empresaDeOrigem": "Company of Origin: {{codeCompanyOrigem}}-{{nomeEmpresaOrigem}}",
			"empresaDeDestino": "Destination Company: {{codeDestinoCompany}}-{{nomeEmpresaDestino}}"
		},
		"titles": {
			"copiaDeAbonosDescontosMultiEmpresa": "Copy of Multi-Company Allowances/Discounts",
			"selecaoDeEmpresas": "Company Selection",
			"confirmacaoDasOperacoes": "Confirmation of Operations",
			"estadoDaCopia": "Copy Status",
			"informacao": "Information"
		},
		"text": {
			"1-1": "This wizard will guide you through the process of automatically copying or updating the Allowance/Discount for all selected companies.",
			"1-2": "It will also be possible to copy the Crossings, but it does not allow updating them in the destination company.",
			"1-3": "Finally, a report will be shown with detailed information on success or errors, so that they can be corrected.",
			"2-1": "Find yourself",
			"3-1": "Confirm if you want to replace companies that already have Allowances/Discounts configured"
		},
		"fields": {
			"tipo": "Type:",
			"codigo": "Allowance/Discount:",
			"abono": "Allowance",
			"desconto": "Discount",
			"copiaCruzamentos": "Copy Crosses",
			"copiaIntSalarios": "Copy Salary Integration"
		},
		"modal": {
			"title": "Information about the Copy of Allowances/Discounts",
			"radio": {
				"gerarNovo": "Generate new Group code based on Origin",
				"utilizarExistente": "Use an existing Group in the Destination with all the characteristics of the Origin"
			}
		},
		"dataGrid": {
			"caption": {
				"empresa": "Company Code",
				"nomeEmpresa": "Company Name",
				"copiouABDES": "Cop.",
				"copiouGrupo": "Cop.",
				"copiouCruzamento": "Cop.",
				"copiouIntegracaoSalarios": "Cop.",
				"permiteCopia": "Info.",
				"codAbdesDestino": "Destination Code",
				"nomeAbdesDestino": "Destination Name",
				"temCruzamento": "There's a Crossing",
				"mensagem": {
					"resultado": "Result",
					"descricao": "Description"
				}
			}
		},
		"errors": {
			"semCodAbDesc": "You must select an Allowance/Discount",
			"semEmpresas": "You must select at least one company",
			"selecionarLinhaComErro": "The line cannot be selected because it contains an error",
			"selecionarLinhaComAviso": "Choose an action before selecting the line"
		}
	},
	"rhdadosconfigficha": {
		"titles": {
			"registo": "Registration",
			"situacaoemprego": "Employment situation",
			"dadosrenumeracao": "Remuneration data",
			"outrosdados": "Other data",
			"subsidioferias": "Holiday allowance",
			"subsidionatal": "Christmas subsidy",
			"residenciafiscal": "Tax residence"
		},
		"fields": {
			"motivoRegisto": "Reason for registration",
			"situacaoEmprego": "Situation",
			"nCodABVencimento": "Salary allowance",
			"nCodABSubAlimentacao": "Food allowance allowance",
			"nCodABSubFerias": "Vacation allowance allowance",
			"nCodABSubNatal": "Christmas allowance allowance",
			"nCodABDiuturnidades": "Seniority allowance",
			"nCodDescIRS": "IRS discount",
			"localTrabalho": "Location or establishment",
			"tipoHorarioTrabalho": "Time type",
			"nMaxDiasUteisFerias": "Maximum number of working days",
			"nDiasTemDireitoFerias": "Number of working days you are entitled to",
			"nMaxDiasUteisNatal": "Maximum number of days",
			"nDiasTemDireitoNatal": "Number of days you are eligible",
			"nCodABFerias": "Holiday bonus",
			"localObtencaoRendimentos": "Place earning income"
		},
		"text": {
			"suggestion1": "It appears that you have not yet configured the default data in the employee's file.",
			"suggestion2": "apply the recommended configuration"
		}
	},
	"rhfolfer": {
		"fields": {
			"listagem": "Listing",
			"doCodNum": "Employee Code",
			"ateCodNum": "Until",
			"deZona": "Zone",
			"ateZona": "Until",
			"deDepto": "Department of",
			"ateDepto": "Until",
			"deSubDepto": "Sub-Department",
			"ateSubDepto": "Until",
			"deCCusto": "Cost Center",
			"ateCCusto": "Until",
			"deAbono": "Allowance of",
			"ateAbono": "Until",
			"deDesconto": "Discount from",
			"ateDesconto": "Until",
			"deSeccao": "Section",
			"ateSeccao": "Until",
			"mostraAbonosVirtuais": "Shows Virtual Allowances",
			"dataProcesDE": "Processing Date",
			"dataProcesAte": "Until"
		},
		"infos": {
			"tooltipExcecpoesAbono": "Exceptions Allowances",
			"tooltipExcecpoesDesconto": "Exceptions Discounts",
			"tooltipExcepcoesProcessamento": "Exceptions Processing"
		},
		"filtropesq": "Advanced Search",
		"btn": {
			"previsualizar": "Preview"
		},
		"messages": {
			"exclprocessamentoemp": "You can only have the current company selected!"
		}
	},
	"rhgestaoficheirosdmrat": {
		"comprovativo": "Proof",
		"docpagamento": "Payment document",
		"emaildest": "Recipient email",
		"semdados": "No data",
		"header": {
			"dataAquisicaoDe": "Month year",
			"ano": "Year",
			"mes": "Month"
		},
		"btn": {
			"pesquisar": "To look for",
			"verificarenvios": "Check Submissions",
			"gerarficheiro": "Generate Files",
			"reenviarwebservice": "Resend via WebService"
		},
		"table": {
			"dados": "Data",
			"infoficheiro": "File Info",
			"sel": "Sel.",
			"empresa": "Company",
			"nomeempresa": "Company name",
			"contribemp": "Contribution",
			"mes": "Month",
			"ano": "Year",
			"info": "Info",
			"erros": "Errors",
			"infoerros": "Info Errors",
			"estado": "state",
			"docpagamento": "Payment Doc",
			"comprovativo": "Proof",
			"email": "Email"
		},
		"tab": {
			"dadosAutomaticos": "Automatic Data",
			"dadosManuais": "Manual Data",
			"dadosGlobais": "Global Data",
			"controlo": "Control",
			"valorRendimentos": "Income Value",
			"retencaoIRS": "IRS Withholding",
			"contribObrigatorias": "Mandatory Contributions",
			"quotizacaoSindicais": "Union dues",
			"retencaoSobretaxa": "Withholding Surcharge",
			"rendiAnosAnt": "Income from Previous Years"
		},
		"modal": {
			"rhgestaoficheirosdmrattitle": "Information Data Remuneration",
			"table": {
				"erro": "Error",
				"ok": "OK",
				"aviso": "Notice",
				"nif": "VAT Number",
				"codemp": "Employee No.",
				"nome": "Name",
				"abonosRendimentosSujeitos": "Subject Income",
				"abonosRendimentosNaoSujeitos": "Non-Subject Income",
				"abonosOutros": "Other Allowances",
				"descontosContribuicoesObrigatorias": "Mandatory Contributions",
				"descontosIRS": "IRS Withholding",
				"descontosSindicatos": "Unions",
				"descontosSobretaxa": "Withholding Surcharge",
				"descontosOutros": "Other Discounts",
				"rendimentosAnoAnterior": "Previous year Income",
				"liquidoCalculado": "Calculated Net",
				"liquidoVencimentos": "Net Salaries",
				"erroDescricao": "Error/Warning Description",
				"origemRendiDescr": "Origin",
				"dataVencimen": "Date",
				"tipRendiment": "Income Type.",
				"valorInciden": "Income Value",
				"descrInsManual": "Insertion Type",
				"quotizSindical": "Union dues",
				"irsRetido": "IRS Withholding",
				"descobrigSSocial": "Mandatory Contributions",
				"sobretaxaExtraord": "Withholding Surcharge",
				"rendimentosAnoAnteriorTable": "Income Value (Previous Years)",
				"rendianosanteriores": "Income from Previous Years",
				"ano": "Year",
				"valores": "Values",
				"rendimentosano": "Income for the Year",
				"tipoRendi": "Yield Type",
				"localorigemrendi": "Location Origin Income",
				"nipc1": "NIPC Entity 1",
				"nipc2": "NIPC Entity 2",
				"nipc3": "NIPC Entity 3",
				"enviaparaDMR": "Send to DMR",
				"valorNaoSujeito": "Non-Subject Income",
				"outrosDescontos": "Other Discounts",
				"outrosAbonos": "Other Allowances",
				"ncodAbdes": "Allowance/Discount",
				"descricaoAbono": "Description Allowance/Discount"
			}
		},
		"email": {
			"enviado": "Email successfully sent!",
			"naoenviado": "Email was not sent."
		},
		"messages": {
			"envioscomsucesso": "Submissions verified successfully!"
		},
		"tooltip": {
			"naotemlinhasselecionadas": "There are no lines selected."
		},
		"prompt": {
			"confTitle": "Confirmation",
			"geraFich": "It will generate the file for the Monthly Remuneration Statement - AT.",
			"errogerarficheiro": "File was not generated successfully!",
			"ficheirosenviadosconfirmcolum": "Files sent."
		}
	},
	"gozoferias": {
		"usaMarcacaoGozoFerias": "Use holiday enjoyment marking",
		"motivoRegistoFerias": "Reason for change",
		"nCodAbdesDescontoGozoFeriasAnoAtual": "Current year discount",
		"nCodAbdesDescontoGozoFeriasAnoAnterior": "Previous year discount"
	},
	"rhimpvenct": {
		"fields": {
			"listagem": "Listing",
			"doCodNum": "Employee Code",
			"ateCodNum": "Until",
			"deZona": "Zone",
			"ateZona": "Until",
			"deDepto": "Department of",
			"ateDepto": "Until",
			"deSubDepto": "Sub-Department",
			"ateSubDepto": "Until",
			"deCCusto": "Cost Center",
			"ateCCusto": "Until",
			"deAbono": "Allowance of",
			"ateAbono": "Until",
			"deDesconto": "Discount from",
			"ateDesconto": "Until",
			"deSeccao": "Section",
			"ateSeccao": "Until",
			"mostraAbonosVirtuais": "Shows Virtual Allowances",
			"dataProcesDE": "Processing Date",
			"dataProcesAte": "Until"
		},
		"infos": {
			"tooltipExcecpoesAbono": "Exceptions Allowances",
			"tooltipExcecpoesDesconto": "Exceptions Discounts",
			"tooltipExcepcoesProcessamento": "Exceptions Processing"
		},
		"filtropesq": "Advanced Search",
		"btn": {
			"previsualizar": "Preview"
		},
		"messages": {
			"exclprocessamentoemp": "You can only have the current company selected!"
		}
	},
	"rhmanutencao": {
		"tabs": {
			"vencimentos": "Salaries"
		},
		"message": {
			"savedsuccessfully": "Data saved successfully!"
		},
		"fields": {
			"situacaoCessacao": "Cessation Status",
			"motivoCessacao": "Reason for Registration",
			"nCodAbonoIndemnizacaoCaducidade": "Forfeiture compensation not subject to taxes",
			"nCodAbonoIndemnizacaoDespedimento": "Redundancy compensation",
			"nCodAbonoIndemnizacaoGlobal": "Comprehensive compensation",
			"nCodAbonoSubFeriasProp": "Proportional vacation allowance",
			"nCodAbonoFeriasProp": "Proportional holidays"
		},
		"titles": {
			"abcessaconttrab": "Definitions of Allowances/Deductions for Termination of Employment Contract",
			"abpropanoatual": "Definitions of Proportional Allowances/Deductions in the Current Year"
		},
		"steps": {
			"cessacaocontrato": "Termination of Contract"
		},
		"segsocialfolhaferias": {
			"title": "Vacation Sheet (Insurance Company)",
			"cardseguradora": {
				"usaTipodeRemunEspecificaFichSeguradora": "Use specific remuneration type for insurance file (\"A\", \"B\", \"C\", \"F\", \"M\", \"N\", \"O\", \"P\", \"R\", \"S\", \" T\", \"X\", \"SR\")",
				"fichSeguradoraEnvioFichPelaExclAbDescCampo": "Send insurance file - Send allowances/discounts by confirming the \"Insurance Map\" field"
			}
		},
		"rmmg": {
			"title": "Guaranteed Minimum Monthly Remuneration (RMMG)",
			"info": "The RMMG update is carried out automatically by CentralGest",
			"saved": "Configuration of the Guaranteed Minimum Monthly Remuneration (RMMG) updated successfully",
			"tooltip": {
				"needSave": "You must save the settings to carry out processing",
				"needCheck": "You must select the manager field to carry out processing",
				"needSaveAndCheck": "You must select the manager field and save it to be able to carry out processing"
			},
			"fields": {
				"dataAtualizacao": "Update date",
				"gerente": "Manager",
				"funcionario": "Employee"
			}
		}
	},
	"rhmanutencaocampos": {
		"pesquisar": "To look for",
		"camposModificar": "Fields to modify",
		"processamentos": "Processing list",
		"valorCampoNaoPodeSerNulo": "The Value of the field {{campo}} cannot be null or empty!",
		"nenhumCampoSelecionadoParaAtualizar": "No fields selected to update",
		"nenhumProceSelecionadoParaAtualizar": "No processing selected to update",
		"naoFoiEncontradoValorNoModel": "The value was not found in the model: {{field}}",
		"atualizarLista": "Update list",
		"confirmation": "Confirmation",
		"promptMessage": "Are you sure you want to change the fields in the selected processes?",
		"dataProcFinalNaoPodeInferiorInicial": "The final Processing Date cannot be less than the Start Date.",
		"infoHistoricoEmpregado": "Employee History Information",
		"dadosProcessamento": "Processing Data",
		"processamento": "Processing",
		"fields": {
			"dateProc": "Processing Date",
			"ate": "until",
			"codEmp": "Emp. Code.",
			"nome": "Name",
			"nProcessamento": "Proc No.",
			"dataProcessamento": "Process Date",
			"funcao": "Function",
			"nZona": "Zone Code",
			"seccao": "Section",
			"ccusto": "Cost Center",
			"nPOCRemuLiq": "Liq POC account.",
			"departamento": "Department",
			"classeTrab": "Job Class",
			"nSubDe": "SubDepartment",
			"descricaoErro": "Report"
		},
		"wizard": {
			"stepOne": {
				"wizardCaption": "Welcome",
				"text1": "This wizard will allow you to correct fields that were incorrectly recorded in payroll processing.",
				"text2": "You will only make these corrections in processes that have not yet been integrated into Accounting.",
				"text3": "Click \"Next\" to continue."
			},
			"stepTwo": {
				"wizardCaption": "Update fields",
				"text1": "Search for (non-integrated) processing carried out in the date range.",
				"text2": "Modifying records can be done field by field, or select several and change them all at once."
			},
			"stepThree": {
				"wizardCaption": "Report"
			}
		}
	},
	"rhpenhoras": {
		"title_detail": "Garnishment",
		"title_new": "New Attachment",
		"title_plural": "Liens",
		"pesquisa": "To look for",
		"saved": "Pledge, successfully saved.",
		"error": "Attachment cannot be kept.",
		"deleted": "Attachment, successfully deleted.",
		"apagarregisto": "Delete registration",
		"card": {
			"catabo": "Select the allowances for calculating the net value of the salary to allocate the attachment.",
			"calcLiqTodasCateg": "All Allowance Categories"
		},
		"toolbar": {
			"tipoEscolha": "View Pledges: {{nameTipoEscolha}}"
		},
		"fields": {
			"ncodABDESC": "Discount",
			"codEMP": "Employee",
			"tipoValor": "Value type",
			"valorTotalPenhora": "Total attachment value",
			"dataInicio": "Start Date",
			"tipoProcessamento": "Processing",
			"todosProcessamentos": "All processing",
			"penhoraPorInsolvenci": "Attachment due to insolvency",
			"suspende": "Suspended",
			"dataSuspensao": "Suspension Date",
			"obsSupencao": "Suspension description",
			"totalValorPenhoraPaga": "Attachment amount paid",
			"tipoValorPenhora": "Attachment value type",
			"valor": "Attachment type value",
			"temValorLimiteMinimoImpenhoravel": "It has a minimum unseizable limit value",
			"valorLimImpenhoravel": "Unseizable limit value",
			"valorExternoPenhora": "External net value: (Adds to the calculated net value)",
			"obsPenhora": "Comments",
			"referenciaPagamento": "Payment reference description"
		},
		"maintenance": {
			"descontos": "Discount Configuration"
		}
	},
	"rhprocessamentosalariosconfig": {
		"tab": {
			"procesalarios": "Salary Processing",
			"duodecimos": "Twelfths"
		},
		"procesalarios": {
			"usaFiltroFuncaoNosTiposProcessamento": "Use filter function in salary processing",
			"calcNDiasParaAdmissaoEmpEmDiasBase30": "Calculates salary processing value for employee admission on a 30-day basis"
		},
		"duodecimos": {
			"acertoDuodecimosFaltouMesInteiro": "Remove twelfths if employee was absent for the entire month",
			"efetuaAcertoDuodecimos": "Make twelfths adjustment in the last payment in December",
			"percentagemEmSubFerias": "Percentage of Sub.",
			"percentagemEmSubNatal": "Percentage of Sub."
		},
		"card": {
			"percduodecimos": "Twelfth Percentage on Holiday and Christmas Allowance"
		},
		"errors": {
			"invalidpercentage": "Invalid percentage, must be 0, 50, 100!",
			"verificarFerias": "Check that the percentage of twelfths entered in Sub.",
			"verificarNatal": "Check that the percentage of twelfths entered in Sub."
		}
	},
	"rhprocsegsocialconsultenvios": {
		"semdados": "No data",
		"comprovativo": "Proof",
		"header": {
			"dataAquisicaoDe": "Month year",
			"ano": "Year",
			"mes": "Month"
		},
		"btn": {
			"pesquisar": "To look for",
			"gerarficheiro": "Generate Files",
			"reenviarwebservice": "Resend via WebService",
			"reprocessarSegSocial": "Reprocess Social Security",
			"configWsSS": "Social Security WebService Configuration",
			"impresumo": "Print summary",
			"gerarEstorno": "Generate Chargeback",
			"apagarEstorno": "Delete Chargeback"
		},
		"table": {
			"dados": "Data",
			"dadosEmpresa": "Company Data",
			"infoficheiro": "File Info",
			"infowebservice": "Info Web Service",
			"sel": "Sel.",
			"empresa": "Company",
			"nomeempresa": "Company name",
			"mes": "Month",
			"ano": "Year",
			"totalRemuneracoes": "Total - Social Security Remuneration",
			"totalContribuicoes": "Total - Social Security Contribution",
			"totalVencRemun": "Total - Remuneration Proc.",
			"procficheiro": "Proc.",
			"info": "Info",
			"erros": "Errors",
			"reprocessado": "Reprocessed",
			"gerado": "Generated",
			"infoerros": "Info Errors",
			"estado": "state",
			"alertaserros": "Alert/Errors",
			"dataentrega": "Delivery date",
			"datalimitesub": "Deadline Replace",
			"nome": "Name",
			"comprovativo": "Proof",
			"modal": {
				"title": "Shows information from the Vacation Sheet file",
				"identificacao": "Identification",
				"codp": "Code P",
				"codr": "Code R",
				"codsr": "SR Code",
				"codf": "Code F",
				"codn": "Code N",
				"coda": "Code A",
				"codb": "Code B",
				"codc": "Code C",
				"codd": "Code D",
				"codh": "Code H",
				"codm": "Code M",
				"codo": "Code",
				"cods": "Code S",
				"codt": "T Code",
				"codx": "Code X",
				"cod2": "Code 2",
				"cod6": "Code 6",
				"codi": "Code I",
				"nss": "SS No.",
				"nfuncionario": "Employee number",
				"nome": "Name",
				"ndias": "No. Days",
				"valor": "Value"
			},
			"config": "Config."
		},
		"messages": {
			"infosuccess": "Data recorded successfully!",
			"infoerror": "An error occurred while saving the data, please confirm the data entered.",
			"empresasSelecionasHouveEmpresnaoGeradas": "Of the Selected Companies, there were companies that were not generated.",
			"reprocessamentoSuccess": "Social Security reprocessing carried out successfully.",
			"empresasSelecionasHouveEmpres": "Of the Selected Companies, there were companies with errors.",
			"enviowebserviceSuccess": "Shipping made to all companies.",
			"enviadosSucess": "The files were sent successfully",
			"semRegistosSelecionadas": "You must select at least one record.",
			"verificadosuccess": "Submissions verified successfully",
			"soPodeSelecionarUmRegisto": "You can only select one record."
		},
		"prompt": {
			"confTitle": "Confirmation",
			"geraFich": "It will generate the file for the Monthly Remuneration Statement - AT.",
			"errogerarficheiro": "File was not generated successfully!",
			"ficheirosenviadosconfirmcolum": "Files sent.",
			"semAlteracaoParaGerar": "The system did not generate a file, because it was already generated previously."
		},
		"tooltip": {
			"naotemlinhasselecionadas": "There are no lines selected.",
			"configWsSS": "Social Security WebService Configuration",
			"gerarEstorno": "Generate chargeback",
			"apagarEstorno": "Delete chargeback"
		},
		"verificarEnvios": "Check Submissions",
		"modal": {
			"title": "Printing maps Social Security File Summary"
		}
	},
	"rhqpirct": {
		"card": {
			"irct": "IRCT",
			"irctcateg": "IRCT \\ Category",
			"codirct": "IRCT Code",
			"nomeirct": "IRCT name",
			"codcategoria": "Category Code",
			"nomecategoria": "Name Category"
		},
		"btn": {
			"addirct": "Add IRCT",
			"addcateg": "Add Category",
			"infooficialirct": "Obtain official information about the codes to be assigned to the IRCT and Categories",
			"importlistacateg": "Import of the list of Professional Categories (CSV file)"
		},
		"messages": {
			"filesuccess": "File imported successfully!",
			"importedsucess": "Imported successfully!",
			"aviso": "Notice",
			"irctexists": "The IRCT you intend to import already exists!"
		},
		"modal": {
			"title": "Import of professional categories",
			"table": {
				"codIRCT": "Code",
				"nome": "Name"
			}
		}
	},
	"rhrecolhadados": {
		"apagarregisto": "Delete registration",
		"radio": {
			"dadosmanual": "Manual Data Collection",
			"dadosfixos": "Collect Fixed Data",
			"byabondesc": "Data collection by Allowance/Discount",
			"byemp": "Data collection by Employee"
		},
		"error": {
			"invalidStep": "There are fields that are not filled in or contain an error!",
			"invalidStepTodosProcessamentos": "You must select a valid processing type!"
		},
		"fields": {
			"todosProcessamentos": "All Processing",
			"nomeEmp": "Employee",
			"tipoProcessamento": "Processing Type",
			"abonoDesc": "Allowance/Discount",
			"codABDESC": "Allowance/Discount Code",
			"codEMP": "Employee Code",
			"dataDados": "Event Date",
			"dataDadosFixos": "Processing Date",
			"dataAte": "Until",
			"dataProcess": "Processing Date",
			"dataParaProcess": "Processing Date",
			"quantidade": "Amount",
			"valorUNIT": "Unitary value",
			"valor": "Value",
			"vALINCIDESC": "Incidence",
			"descricao": "Note.",
			"cambioMEParaMC": "Exchange",
			"valorME": "ME Value",
			"codMOEDA": "Coin",
			"tipoALUNIT": "Unit",
			"ccusto": "C. Cost",
			"nsubde": "Sub-Department.",
			"departamento": "Department",
			"nzona": "Zone",
			"contador": "Registration No. in C. Cost",
			"htrabDia": "Work per day",
			"htrabSemana": "Work per week",
			"htrabMes": "Work per Month",
			"nomeAbonoDesconto": "Allowance/Discount Code",
			"nomeTipoProcessamento": "Processing Type"
		},
		"tabs": {
			"infoemp": "Employee Information",
			"outrosdados": "Other data",
			"title": {
				"horasempregado": "Employee Hours",
				"ndiasempregado": "Number of Days Employed",
				"valoresuniemp": "Unit Values ​​Employee"
			}
		},
		"table": {
			"sel": "Sel",
			"tipo": "Type",
			"valor": "Value",
			"valorme": "ME Value",
			"horaextra": "% Extra hour",
			"pagaseguro": "% Pays Insurance"
		},
		"modal": {
			"aviso": "Notice",
			"confirma": "Confirmation",
			"continuar": "Are you sure you want to change the recorded record?",
			"avisodesc": "The Allowance/Discount '{{abanodesc}}' that you are introducing has a loss of Meal subsidy.",
			"labelnhoras": "Number of absences marked for the discount {{abodesc}}",
			"labelndias": "Number of days for meal allowance",
			"nhoras": "No. Hours",
			"ndias": "No. Days",
			"title": "Meal allowance"
		},
		"toolbar": {
			"month": "Month: {{nameMonth}}",
			"stateemp": "Employees: {{nameState}}"
		},
		"steps": {
			"tiporecolha": "Collection Type",
			"dadosrecolha": "Collection Data"
		}
	},
	"remfx": {
		"title_detail": "Collect Fixed Data",
		"title_new": "New Fixed Data Collection",
		"title_plural": "Fixed Data Collections",
		"pesquisa": "To look for",
		"saved": "Fixed Data Collection, saved successfully.",
		"error": "Fixed Data Collection, cannot be saved.",
		"deleted": "Fixed Data Collection, deleted successfully."
	},
	"remun": {
		"title_detail": "Collect Data",
		"title_new": "New Data Collection",
		"title_plural": "Data Collections",
		"pesquisa": "To look for",
		"saved": "Data Collection, saved successfully.",
		"error": "Data Collection, cannot be saved.",
		"deleted": "Data Collection, successfully deleted."
	},
	"rhrecolhaseriesubsidios": {
		"empTable": {
			"codEmp": "Employee Code",
			"nomeEmp": "Name",
			"nBI": "BI No.",
			"contribuinte": "VAT Number",
			"dataNascimento": "Date of birth"
		},
		"steps": {
			"pesquisa": "Search",
			"processar": "Process"
		},
		"titles": {
			"pesquisa": "Search",
			"processar": "Select payment day"
		},
		"fields": {
			"codEmpDe": "Emp. Code.",
			"codEmpAte": "Emp. Code.",
			"situacaoEmprego": "Employment Status",
			"tipoProcessamento": "Processing Type",
			"dataReferencia": "Reference Date",
			"dataParaPagarSubsidio": "Date for calculating the amount payable for Subsidy",
			"nDiasSubsidioAlimentacao": "Number of Defect Days",
			"radio": {
				"ativo": "Active",
				"inativo": "Inactive",
				"todos": "All"
			}
		},
		"messages": {
			"selEmp": "You must select at least one employee!",
			"tipoProcEmpty": "You must enter a processing type!",
			"semdadossave": "No Data to Record.",
			"dadossaved": "Data Recorded Successfully."
		},
		"modal": {
			"subssavedtitlenatal": "Serial payment of Christmas Subsidy",
			"subssavedtitleferias": "Serial payment of Holiday Allowance",
			"subssavedtitlealimentacao": "Food Subsidy Serial Pre-Processing",
			"semdados": "No data",
			"btn": {
				"options": {
					"showCodEmpregado": "Shows Emp. Code.",
					"showNomeEmpregado": "Show Name",
					"showDataAdmissao": "Shows Admission Date",
					"showNDiasAPagar": "Shows number of days to pay",
					"showValorAPagar": "Shows Amount to Pay",
					"showNMAxDiasUteisSubsidio": "Shows Max. number of days Subsidy",
					"showNDiasPagosAnoAnterior": "Shows number of days paid in the previous year",
					"showValorPagoAnoAnterior": "Shows amount paid last year",
					"showNDiasPorPagarAnoAnterior": "Shows number of days unpaid in the previous year",
					"showNDiasTrabalhados": "Shows number of days worked",
					"showNDiasDescontarPorFaltasEmpregado": "Shows Number of Days deducted for absences",
					"showNDiasPagos": "Shows number of days already paid",
					"showInfo": "Show Info"
				}
			},
			"fields": {
				"codEmpregado": "Emp. Code.",
				"nomeEmpregado": "Name",
				"dataAdmissao": "Admission date",
				"nMAxDiasUteisSubsidio": "Max number of days Subsidy",
				"nDiasPagosAnoAnterior": "Number of days paid in the previous year",
				"valorPagoAnoAnterior": "Amount paid previous year",
				"nDiasPorPagarAnoAnterior": "Number of days unpaid in the previous year",
				"nDiasTrabalhados": "Number of days worked",
				"nDiasDescontarPorFaltasEmpregado": "Number of days deducted for absences",
				"nDiasPagos": "Number of days already paid",
				"nDiasAPagar": "Number of days to pay",
				"valorAPagar": "Amount to Pay",
				"info": "Info",
				"valorEmMoedaEstrangeira": "Foreign currency value",
				"valorCambio": "Exchange value",
				"moedaCambio": "Currency exchange"
			}
		}
	},
	"rhregistarempregadosss": {
		"consultarMainTabTitle": "Consult Employees",
		"registarMainTabTitle": "Register Employees",
		"verificarEmpregadosTabTitle": "Verify Employees",
		"verificarRegistarContratosTabTitle": "Check/Register old contracts",
		"registarEmpregadosRegistarTabTitle": "Register Employee",
		"registarEmpregadosCessarTabTitle": "Terminate Employee",
		"registarEmpregadosPerioRendTabTitle": "Income Period",
		"confirmTitle": "Confirmation",
		"promptRegistarEmpregado": "Are you sure you want to register the employee?",
		"promptCessarEmpregado": "Are you sure you want to terminate the employee?",
		"promptRegPeriodoRendimento": "Are you sure you want to record the income period?",
		"editarContratoModalTitle": "Edit Contract",
		"configWS": {
			"modalTitle": "Configuration for Registering Workers in Social Security",
			"instruction1Title": "Access to Direct Social Security",
			"instruction1Text": "To join the Interoperability Services Platform, you must access Direct Social Security (SSD), through the NISS and the password you have for that purpose.",
			"instruction2Title": "Join the Interoperability Services Platform",
			"instruction2Text": "After accessing the SSD, you will find the conditions for joining the Interoperability Services Platform in the Profile menu option.",
			"saveButton": "To record",
			"seguinte": "Following",
			"voltar": "To go back",
			"utilizadorNiss": "User (NISS)",
			"senha": "Password",
			"warnignMsg": "The Username and Password are those of the company, and not those entered as an agent"
		},
		"strings": {
			"data": "Date",
			"ate": "until",
			"empregado": "Employee",
			"consultarEmpregados": "Consult Employees",
			"verificarContratos": "Check Contracts",
			"dados": "Data",
			"taxas": "Fees",
			"vinculo": "Bond",
			"localTrabalho": "Work Location",
			"codemp": "Emp. Code.",
			"niss": "Social Sec No.",
			"nome": "Name",
			"datanascimento": "Birth date",
			"vinculocomunicadoem": "Link communicated in",
			"iniciodoVinculo": "Beginning of the Bond",
			"fimdoVinculo": "End of the Bond",
			"inicioaplicacaoTaxa": "Home application Fee",
			"fimaplicacaotaxa": "End Application Fee",
			"valortaxa": "Rate Value",
			"contratos": "Contracts",
			"isContratoEnviadoParaSS": "There's a New Contract at SS",
			"modalidade": "Modality",
			"temErros": "There are errors",
			"prestacaoTrabalho": "Work provision",
			"inicioContrato": "Home Contract",
			"fimContrato": "End of Contract",
			"inicioInformacao": "Home Information",
			"fimInformacao": "End Information",
			"profissao": "Profession",
			"remuneracaoBase": "Base Pay",
			"diuturnidades": "Seniority payments",
			"percentagemTrabalho": "Perc.",
			"horasTrabalho": "Working Hours",
			"diasTrabalho": "Work Days",
			"motivoTrabalho": "Reason for work",
			"nissTrabalhador": "NISS Worker",
			"nomeTrabalhador": "Worker Name",
			"descErros": "Errors",
			"atualizarEstadoEmpregados": "Update Employees Status",
			"registarContratos": "Register Contracts",
			"codempregado": "Employee Code",
			"dadosEmpregado": "Employee Data",
			"dataAdmissao": "Admission date",
			"numEstabelecimentoSS": "Establishment No. Social Security",
			"tipoContratoTrabalho": "Type of Employment Contract",
			"assinadoEm": "Signed in",
			"validaAte": "Valid until",
			"vencimentoBase": "Base Salary",
			"naoVerificado": "Not verified",
			"contratoSegSocial": "Social Security Contract",
			"motivo": "Reason",
			"tempoParcial": "Part time",
			"codEmpSubstituir": "Emp. Code.",
			"dataFimVinculo": "End date Bond",
			"comunicaDesemprego": "Communication for unemployment purposes",
			"fundamentacao": "Rationale",
			"registarPerioRendSS": "Register Period Income Social Security",
			"dataNovoRegistoPeriodo": "New Period Date",
			"vencimentoRegistoPeriodo": "New Base Salary",
			"diuturnidadeRegistoPeriodo": "New Diuturnity Value",
			"registar": "Register",
			"confiWsSaved": "The configuration was saved successfully",
			"empregadosAtualizadosComSucesso": "Successfully updated employees",
			"editarContrato": "Edit Contract",
			"authenticationError": "Authentication failed."
		},
		"enums": {
			"modalidade": {
				"A": "A - NON-TERM, FULL TIME",
				"B": "B - NON-TERM, PART-TIME",
				"C": "C - INTERMITTENT WORK, FULL TIME",
				"D": "D - INTERMITTENT WORK, PART-TIME",
				"E": "E - RIGHT TERM, FULL TIME",
				"EA": "EA - WITH TERM, RIGHT, FULL TIME, SERVICE COMMISSION",
				"EB": "EB - WITH TERM, RIGHT, FULL TIME, TELEWORK",
				"F": "F - FIXED-TERM, PART-TIME",
				"FA": "FA - WITH TERM, RIGHT, PART-TIME, SERVICE COMMISSION",
				"FB": "FB - WITH TERM, RIGHT, PART-TIME, TELEWORK",
				"G": "G - UNCERTAIN TERM, FULL TIME",
				"H": "H - UNCERTAIN TERM, PART-TIME",
				"I": "I - VERY SHORT DURATION WORK",
				"J": "J - TELEWORKING, FULL TIME",
				"K": "K - TELEWORKING, PART-TIME",
				"L": "L - SERVICE COMMISSION, FULL TIME",
				"M": "M - SERVICE COMMISSION, PART-TIME",
				"N": "N - THE RIGHT TERM, JOB.",
				"O": "O - THE RIGHT TERM, WORK.",
				"P": "P - UNCERTAIN TERM, JOB.",
				"Q": "Q - UNCERTAIN TERM, WORK.",
				"R": "A - FOR AN INDETERMINED TIME, WORK.",
				"S": "S - FOR AN INDETERMINED TIME, WORK.",
				"AA": "AA - No term, full time, service commission",
				"AB": "AB - No term, full time, teleworking",
				"BA": "BA - No term, part-time, service commission",
				"BB": "BB - No term, part-time, teleworking",
				"GA": "GA - Uncertain term, full time, service commission",
				"GB": "GB - Uncertain term, full-time, teleworking",
				"HA": "HA - Uncertain term, part-time, service commission",
				"HB": "HB - Uncertain term, part-time telework"
			},
			"motivoContrato": {
				"AEAT": "AEAT - Exceptional increase in activity",
				"ATSA": "ATSA - Seasonal activity",
				"CTSD": "CTSD - Hiring worker very long-term unemployment situation",
				"EOPA": "EOPA - Execution of defined and temporary work, project or activity",
				"EXTO": "EXTO - Occasional task execution",
				"IFEE": "IFEE - Start of operation of a company/establishment with less than 250 employees, within the next 2 years",
				"LNAT": "LNAT - Launch of new activity of uncertain duration in a company/establishment with less than 250 workers, in the subsequent 2 years",
				"STAJ": "STAJ - Replacement worker with dismissal lawsuit",
				"STAT": "STAT - Replacement of absent or temporarily prevented workers",
				"STLR": "STLR - Replacement of workers on unpaid leave",
				"STTC": "STTC - Replacement of full-time workers for part-time work for a specified period"
			},
			"prestacaoTrabalhoContrato": {
				"P": "P - In-person",
				"T": "T - Teleworking"
			},
			"motivoCessar": {
				"CCAI": "CCAI - Dismissal by the insolvency administrator",
				"CCCT": "CCCT - Expiry of fixed-term contract",
				"CCEE": "CCEE - Termination of legal entity / closure of the company / death of employer",
				"CCFM": "CCFM - Expiry of military contract",
				"CCMT": "CCMT - Expiry of the contract due to the death of the worker",
				"CCRI": "CCRI - Expiry of contract due to retirement due to disability",
				"CCRV": "CCRV - Expiry of contract due to old age retirement",
				"CDT": "CDT - Permanent assignment of worker (Assignment of contractual position)",
				"IECC": "IECC - Termination of service commission or similar situation at the employer's initiative",
				"IEDC": "IEDC - Collective dismissal at the initiative of the employer",
				"IEEX": "IEEX - Dismissal due to termination of the job at the initiative of the employer",
				"IEIN": "IEIN - Dismissal due to unsuitability at the initiative of the employer",
				"IEJC": "IEJC - Just cause at the initiative of the employer",
				"IEPE": "IEPE - Termination of contract during the trial period at the employer's initiative",
				"IIAT": "IIAT - Abandonment from work",
				"IIDD": "IIDD - Termination of the employment contract/dismissal at the initiative of the worker",
				"IIDE": "IIDE - Termination of contract during the trial period at the initiative of the worker",
				"IIJC": "IIJC - Just cause on the initiative of the worker",
				"IISA": "IISA - Just cause for unpaid wages at the initiative of the worker",
				"RADC": "RADC - Revocation agreement under the terms of paragraph 4 of article 10",
				"RANE": "RANE - Revocation agreement - without reducing the level of employment, to reinforce the company's technical qualifications",
				"RAOT": "RAOT - Revocation agreement not provided for in previous numbers",
				"RARC": "RARC - Revocation agreement - company in recovery process",
				"RARD": "RARD - Revocation agreement - company undergoing restructuring by order",
				"RARE": "RARE - Revocation agreement - company in difficult economic situation",
				"RARR": "RARR - Revocation agreement - company undergoing restructuring belonging to a specific sector",
				"TE": "TE - Company transfer"
			}
		},
		"messages": {
			"selectRecords": "Please select at least one record",
			"existemEmpregadosComErro": "There are employees who make mistakes.",
			"empregadoRegistadoComSucesso": "Successfully registered employee",
			"empregadoCessadoComSucesso": "Employee successfully terminated",
			"erroInesperado": "An unexpected error has occurred.",
			"periodoRendimentoRegistadoComSucesso": "The income period was registered successfully"
		}
	},
	"rhrelatoriounico": {
		"title": "Single Report",
		"titleExport": "Exporting the {{year}} Single Report File",
		"tabProcess": "Export",
		"tabDownload": "Single Report File",
		"generation": "File generation",
		"fileNotProcessed": "We're sorry, but for some reason the file was not generated.",
		"jobTimeoutModalTitle": "Task",
		"jobTimeoutModalMessage": "The task has timed out.",
		"processar": "Process",
		"processingMessage": "Processing, please wait a moment.",
		"erros": "The following errors occurred during processing (the file may still be available for download in the Single Report File tab):",
		"fileReady": "The single report file is ready.",
		"promptReprocessTitle": "A previously generated single report file already exists",
		"promptReprocessMessage": "If you continue, the system will generate the file again.",
		"warningMessage": "Attention: After starting the process, you have 60 minutes to download the generated file.",
		"promptCancelProcessTitle": "Confirmation",
		"promptCancelProcessMessage": "Are you sure you want to cancel the process?",
		"dataCriacao": "Creation date",
		"tamanho": "Size",
		"utilizador": "User",
		"buttons": {
			"reiniciar": "Restart",
			"download": "Download",
			"yes": "Yes",
			"no": "No",
			"fechar": "To close"
		},
		"erroDownload": "An error occurred while downloading the file!",
		"modal": {
			"config": {
				"title": "Single Report - Configuration",
				"nestabelec": "Code",
				"nome": "Name",
				"nestabQPessoal": "ID",
				"savesuccess": "Configuration saved successfully!",
				"idEntidadeEmpty": "It is necessary to indicate the Entity ID.",
				"idEntidade": "entity ID"
			}
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmation",
		"leavePromptMessage": "Clicking YES will exit and discard all processing data."
	},
	"rhsaltotrf": {
		"fields": {
			"codEmpDe": "Employee of",
			"dataProcesDE": "Processing date",
			"bancoDe": "Bank of",
			"fRemuDe": "Form of remuneration"
		},
		"tabs": {
			"0": "Salaries",
			"1": "Meal Card/Ticket",
			"vencimentos": "Salaries",
			"cartaoTicketRefeicao": "Meal Card/Ticket"
		},
		"table": {
			"sel": "Sel.",
			"estado": "state",
			"emp": "Employee",
			"nome": "Name",
			"valor": "Value",
			"datalanc": "Release date",
			"iban": "IBAN",
			"swift": "SWIFT",
			"erro": "Error",
			"semdados": "No data"
		},
		"messages": {
			"pesqsuccess": "Search completed successfully",
			"pesqsemdados": "There are no expiries for the selected filter",
			"tooltipExcepcoesProcessamento": "Exceptions processing",
			"linesnotselected": "There are no selected lines to process",
			"corrigecartao": "Fix card",
			"removetrans": "Remove transfer",
			"temcertezaremtransf": "Are you sure you want to remove the transfer line?",
			"ncontaRequired": "You must select a D/O account number",
			"listatransfsuccess": "Successfully saved transfer list"
		},
		"modal": {
			"title": "Generate transfer data",
			"fields": {
				"dataTransf": "Transfer date",
				"nContaPoc": "D/O account number"
			}
		},
		"config": {
			"title": "Configuration of banks, branches and accounts"
		},
		"imprimir": {
			"title": "Payment by bank",
			"titleWithTab": "Payment by bank - {{activeTab}}"
		}
	},
	"rhtransferenciasbancarias": {
		"tab": {
			"listabancos": "Banks with Meal Tickets",
			"exclusaoabonos": "Exclusion of allowances/discounts",
			"transfBancarias": {
				"title": "Bank transfers",
				"formatoPS2": {
					"title": "PS2 File Format",
					"ficheiroFormatoPS2": "File transfers PS2 format"
				},
				"cgd": {
					"title": "Banco Caixa Geral de Depósitos",
					"separacaoNibs": "CGD Exportação TXT - Separates Nibs"
				},
				"dgt": {
					"title": "General Directorate of the Treasury",
					"dgtFormatoSEPA": "DGT transfer file in SEPA format"
				}
			},
			"codigo": "Code"
		},
		"options": {
			"ticketFormatSEPA": "Transfer file"
		},
		"fields": {
			"codBanco": "Bank Code",
			"codOpTrfBa": "Tansf Op. Code.",
			"nClienteBanco": "Customer Number at the Bank",
			"nCartaoTrfBaIBAN": "Transfer Card No.",
			"cartaoDifBanco": "Card ref.",
			"configTrfBaTicket": "Transfer.",
			"tipoFichTrf": "Transfer file type.",
			"tipo": "Type",
			"abonodesconto": "Allowance/Discount",
			"designacao": "Designation"
		},
		"modal": {
			"configransfticket": "Ticket Bank Transfer Setup",
			"codBanco": "Bank",
			"codOpTrfBa": "Bank Transfer Operation Code (BCO = '48')",
			"nClienteBanco": "Customer number at the bank",
			"nCartaoTrfBaIBAN": "Bank transfer card number is IBAN",
			"cartaoDifBanco": "The Meal card bank is different from the payment bank",
			"configTrfBaTicket": "You have a bank transfer ticket set up",
			"tipoFichTrf": "Transfer file type ('0 - PS2')",
			"bancoValidator": "Enter a valid bank"
		},
		"message": {
			"addsuccess": "Bank added successfully!",
			"editsuccess": "Bank updated successfully!",
			"abdescExclusionRepeated": "Exclusion already entered!",
			"abdescempty": "You must select an Allowance/Discount!",
			"savedSuccess": "Settings saved successfully!"
		}
	},
	"rhunidades": {
		"title_detail": "Human Resources Unit {{id}}",
		"title_new": "New Human Resources Unit",
		"title_plural": "Human Resources Units",
		"pesquisa": "To look for",
		"saved": "Human Resources Unit {{id}}, saved successfully.",
		"error": "Human Resources Unit {{id}}, cannot be saved.",
		"deleted": "Human Resources Unit {{id}}, successfully deleted.",
		"fields": {
			"codUnidade": "Code",
			"nome": "Name",
			"nomeAbreviado": "Short name",
			"tipoUnidade": "Unit Type"
		}
	},
	"roles": {
		"title_detail": "Role {{id}}",
		"title_plural": "Roles",
		"pesquisa": "To look for",
		"fields": {
			"role": "Name",
			"description": "Description"
		}
	},
	"rptconfigs": {
		"fields": {
			"modulo": "Module",
			"nome": "Formula name",
			"descricao": "Description",
			"valor": "Value"
		},
		"modulos": "Modules",
		"variaveisRelatorio": "Report Variables",
		"saveSuccess": "Settings saved successfully."
	},
	"saft": {
		"tabProcess": "Export",
		"tabDownload": "SAF-T file",
		"generation": "File generation",
		"fileNotProcessed": "We're sorry, but for some reason the file was not generated.",
		"jobTimeoutModalTitle": "Task",
		"jobTimeoutModalMessage": "The task has timed out.",
		"contabilidade": "Accounting",
		"faturacao": "Billing",
		"processar": "Process",
		"processingMessage": "Processing, please wait a moment.",
		"erros": "Errors",
		"fileReady": "The SAF-T file is ready.",
		"promptReprocessTitle": "There is already a previously generated SAF-T file",
		"promptReprocessMessage": "If you continue, the system will generate the file again.",
		"warningMessage": "Attention: After starting the process, you have 60 minutes to download the generated file.",
		"promptCancelProcessTitle": "Confirmation",
		"promptCancelProcessMessage": "Are you sure you want to cancel the process?",
		"dataCriacao": "Creation date",
		"tamanho": "Size",
		"utilizador": "User",
		"documentos": "Documents",
		"ano": "Year",
		"mes": "Month",
		"buttons": {
			"reiniciar": "Restart",
			"download": "Download",
			"yes": "Yes",
			"no": "No",
			"fechar": "To close"
		},
		"erroDownload": "An error occurred while downloading the file!",
		"recibos": "Receipts",
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmation",
		"leavePromptMessage": "Clicking YES will exit and discard all processing data.",
		"contabilidadeFaturacaoRecibos": "Accounting, Billing and Receipts",
		"autofaturacao": "Self-billing",
		"nConta": "Supplier",
		"title": {
			"normal": "Export SAF-T file",
			"autofaturacao": "Export SAF-T Self-billing file"
		}
	},
	"saftcontab": {
		"steps": {
			"welcome": {
				"step": "Welcome",
				"title": "Welcome to the SAF-T PT import wizard for accounting.",
				"desc2": "Click \"Next\" to continue the import.",
				"desc": "This importer is intended to import accounting movements through a SAF-T PT file into accounting.",
				"contentText4": "Attention: After starting the process, you have 120 minutes to finish the import."
			},
			"importtype": {
				"step": "Import type",
				"importtypes": "Types of imports available",
				"fields": {
					"importextratocomsi": "Import Statement (With import of opening balances)",
					"importextratosemsi": "Import Statement (Without Importing opening balances)",
					"importsaldosiniciais": "Import opening balances only",
					"importterceiros": "Import only third-party accounts"
				}
			},
			"upload": {
				"step": "SAFT file",
				"title": "Please choose the file you want to import",
				"import": "Import Import configuration file",
				"export": "Export Import configuration file"
			},
			"fields": {
				"nDiario": "Daily No.",
				"nome": "Name",
				"diarioOriem": "Source journal",
				"diarioDestino": "Target journal",
				"contaOrigem": "Source Account",
				"contaDestino": "Target Account",
				"nConta": "Account No.",
				"criaConta": "To create",
				"codControlIvaDebito": "VAT Debit Control",
				"codControlIvaCredito": "VAT Credit Control",
				"erro": "Error",
				"nif": "Taxpayer",
				"documento": "Document",
				"valorBase": "Base value",
				"valorIvaCalculado": "Probable VAT value",
				"taxaCalculada": "Probable rate",
				"nContasBase": "Number of base accounts",
				"nContasIVA": "No. of VAT accounts",
				"valorBaseDoc": "Base value doc.",
				"valorIVADoc": "VAT value doc.",
				"taxaSobreTotalIVA": "Rate excluding total VAT",
				"contaCredito": "Credit account",
				"contaDebito": "Debit account",
				"descricao": "Name",
				"valor": "Value",
				"contaExistente": "Existing accounts",
				"convertConta": "Accounts with conversion",
				"contasSemConversao": "Accounts without conversion"
			},
			"associacaoDiarios": {
				"step": "Journal Association",
				"title": "Accounting Journal Association",
				"desc": "Please indicate whether you want to associate journals with existing journals."
			},
			"planoContas": {
				"step": "Chart of accounts",
				"title": "Chart of accounts",
				"filters": {
					"caption": "Filters",
					"contasComErro": "See only accounts with errors",
					"contasComConversao": "See only accounts with conversion",
					"contasSemConversao": "See only non-converting accounts"
				},
				"tooltip": {
					"importMatriz": "Import conversion matrix"
				},
				"btn": {
					"importMatriz": "Import matrix"
				}
			},
			"convertContas": {
				"step": "Account conversion",
				"title": "Account conversion",
				"desc": "Please indicate the conversion matrix you want for the accounts in the chart of accounts."
			},
			"contasExist": {
				"step": "Existing accounts",
				"title": "Accounts that already exist in the chart of accounts",
				"desc": "Please check the accounts that will be imported and that already exist in the chart of accounts.",
				"desc1": "If you wish, you can replace the target account."
			},
			"contasSemConvert": {
				"step": "Accounts without conversion",
				"title": "Accounts without defined conversion",
				"desc": "Please indicate the non-converting accounts you want to create."
			},
			"contasCorrentes": {
				"step": "Current accounts",
				"title": "Current accounts to be created",
				"apenasContasMovimentadas": "Only imports active accounts."
			},
			"configImport": {
				"step": "settings",
				"title": "Import Settings",
				"fields": {
					"diario": "Daily No.",
					"periodo": "Period",
					"ndescritivo": "Description of releases",
					"descritivosaldosiniciais": "Descriptive No.",
					"saldosiniciais": "Opening balances",
					"diarioapura": "Diary of results in the SAF-T file",
					"transactiondate": "Perform the import in which the period is formed from <TransactionDate> (attention, only use when the SAF-T file has changed dates)",
					"diaroAbertura": "Opening",
					"descritivoSaldosIniciais": "Opening balance",
					"justificaContasCorrenteMovAb": "Justify open movements in current accounts"
				},
				"messages": {
					"nDescritivoError": "Descriptive entries cannot be empty.",
					"periodoSaldosIniciaisError": "Period cannot be empty.",
					"nDiarioSaldosIniciaisError": "Journal No. cannot be empty.",
					"nDescritivoSaldosIniciaisError": "Descriptive No. cannot be empty.",
					"justificaContasCorrenteMovAbInfo": "Current account movements will be automatically justified up to the last period in which the SAFT file presents entries.",
					"importarSaftContab": "Processing the import of SAF-T accounting..."
				}
			},
			"finalized": {
				"step": "Result",
				"successfully": "Operation completed successfully!"
			}
		},
		"modals": {
			"processing": {
				"title": "SAF-T Accounting Importer",
				"processModalLoad": "Load Process",
				"processModalCancel": "Cancel Process"
			},
			"planos": {
				"title": "Framework of the chart of accounts for the account \"{{nConta}}\"",
				"hint": "Account chart of accounts framework"
			},
			"convertcontas": {
				"title": "Account conversion",
				"contaorigem": "Origin account",
				"contadestino": "Destination account"
			},
			"balanceteNaoSaldado": "The trial balance in the SAFT file is not balanced.",
			"import": {
				"title": "import",
				"configimportedsuccess": "Configuration imported successfully."
			},
			"importMatriz": {
				"title": "Conversion Matrix Import",
				"content": {
					"1": "Welcome to the conversion matrix import wizard.",
					"2": "Please select the model you want to download.",
					"3": "After downloading the file, fill it in with the data you want to import."
				},
				"btn": {
					"modClean": "Download Blank Template",
					"modFilled": "Download Completed Template"
				}
			}
		},
		"messages": {
			"timeoutModalMessage": "We are sorry, but the time limit for importing the SAF-T Contabilidade file has passed.",
			"importProcessModalMessageCancelJob": "The import process has already started.",
			"jobCancelled": "The import process was successfully cancelled.",
			"alertTimerEndProcess": "Attention: After starting the process, you have 60 minutes to finish the import.",
			"jobTimeoutModalTitle": "SAF-T Accounting import",
			"jobTimeoutModalMessage": "The task has timed out.",
			"processarFicheiroLabel": "Processing the SAF-T Accounting file...",
			"noFiles": "Select the file to process...",
			"noTypeImport": "You did not select the import type.",
			"accountsWithError": "There are accounts that contain errors.",
			"accountsWithoutSel": "There are accounts that are not marked to be created.",
			"errorocur": "An internal error has occurred.",
			"contaOrigemVazia": "You did not select a source account.",
			"contaDestinoVazia": "You have not selected a target account.",
			"erroDownload": "An error occurred while downloading the file!",
			"importsuccess": "Import completed successfully!",
			"operationsuccess": "Operation completed successfully!",
			"aprepararImportSaft": "Preparing the SAF-T Contabilidade import process..."
		},
		"btns": {
			"processLoad": "",
			"processReset": ""
		},
		"strings": {
			"jobTimeoutModalTitle": "Task",
			"planoSaft": "SAFT Plan",
			"planoCG": "CentralGest Plan"
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmation",
		"leavePromptMessage": "Clicking YES will exit and discard all processing data.",
		"importTypePromptMessage": "Changing the import type will discard all processing data."
	},
	"scss": {
		"or": "or",
		"and": "It is",
		"locked-portal": "You do not have access to this portal",
		"locked-portal-cgstore": "You do not have access to this portal, please go to the shopping portal by clicking on the \"Store\" button.",
		"locked-portal-expired": "This portal has been blocked because the associated plan has expired."
	},
	"seccoes": {
		"title_detail": "Section {{id}}",
		"title_new": "New Section",
		"title_plural": "Sections",
		"pesquisa": "To look for",
		"saved": "Section {{id}}, saved successfully",
		"error": "Section {{id}} cannot be saved.",
		"deleted": "Section {{id}}, successfully deleted",
		"fields": {
			"nSeccao": "Section Code",
			"designacaoBreve": "Brief Designation",
			"designacaoCompleta": "Full Designation"
		}
	},
	"segur": {
		"title_detail": "Insurance company {{id}}",
		"title_new": "New Insurance Company",
		"title_plural": "Insurers",
		"pesquisa": "to look for",
		"saved": "Insurance company {{id}}, saved successfully.",
		"error": "Insurance company {{id}}, cannot be saved.",
		"deleted": "Insurance company {{id}}, successfully deleted",
		"fields": {
			"nsegur": "Insurance Code",
			"designaBreve": "Brief Description",
			"nome": "Full Description",
			"morada": "Household",
			"npostal": "Postal Code",
			"localidade": "Location",
			"telefone": "Telephone",
			"napolice": "Policy",
			"codSeguradoraISP": "Insurance Code (ISP)"
		},
		"errors": {
			"invalidApolice": "The vacation sheet file format requires the policy number to be 20 characters long."
		}
	},
	"simpleLogin": {
		"at": {
			"userNameFieldLabel": "VAT Number"
		},
		"ssd": {
			"userNameFieldLabel": "NISS"
		},
		"viactt": {
			"userNameFieldLabel": "User"
		},
		"saveTitle": "Save",
		"passwordFieldLabel": "Password",
		"messages": {
			"successLogin": "Login successfully.",
			"credentialsEmpty": "The username or password is empty."
		}
	},
	"simulacaoVencimento": {
		"fields": {
			"dgemp": "collaborator",
			"nDiasUteis": "Number of working days",
			"valorLiquido": "Net value to be obtained"
		},
		"text": {
			"simularValor": "Simulate net worth",
			"reciboSimulado": "Simulated receipt",
			"vencimento": "Gross simulated maturity value",
			"totalIliquido": "Total gross",
			"totalDesconto": "Full discount",
			"totalLiquido": "Net total",
			"totalEspecie": "Total species",
			"totalAPagar": "Total payable"
		},
		"table": {
			"tipoAbono": "Allowance Type",
			"codAbDesc": "Allowance Code",
			"descricao": "Description",
			"qtd": "Qty.",
			"valorUnit": "Unit Value.",
			"valor": "Value",
			"incidencia": "Incidence",
			"percentagem": "Percentage"
		}
	},
	"sinalTaxaRetencao": {
		"pesquisa": "Signal Movement Retention Rate",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"negativo": "-",
			"positivo": "+"
		}
	},
	"sind": {
		"title_detail": "Union File {{id}}",
		"title_new": "New Union file",
		"title_plural": "Union Files",
		"pesquisa": "To look for",
		"saved": "Union File {{id}}, saved successfully.",
		"error": "Union File {{id}}, cannot be saved.",
		"deleted": "Union File {{id}}, deleted successfully",
		"fields": {
			"nSindicato": "Union No.",
			"nome": "Name",
			"designaBreve": "Brief Designation",
			"morada": "Household",
			"npostal": "Postal Code",
			"localidade": "Location",
			"telefone": "Telephone",
			"fax": "Fax",
			"nContrib": "VAT Number",
			"nBanco": "Bank Code",
			"nAgencia": "Agency Code",
			"contaGenBanc": "Bank Account No.",
			"bairroFiscal": "Finance department",
			"valorMinQuot": "Minimum Quota Value",
			"nomeLocalidade": "Location",
			"nomeBairroFiscal": "Finance department"
		}
	},
	"situacaoprofissional": {
		"title_detail": "Profession status {{id}}",
		"title_new": "New profession situation",
		"title_plural": "Situation of professions",
		"pesquisa": "To look for",
		"saved": "Profession status {{id}}, saved successfully",
		"error": "Profession status {{id}}, cannot be saved.",
		"deleted": "Profession status {{id}}, successfully deleted",
		"fields": {
			"nSitProfiss": "Profession status code",
			"designaBreve": "Brief designation",
			"designaComp": "Full designation",
			"codQuadroPessoal": "Personnel table"
		}
	},
	"situacaoempregado": {
		"title_detail": "Employee status {{id}}",
		"title_new": "New employee status",
		"title_plural": "Employee Situations",
		"pesquisa": "To look for",
		"saved": "Employee status {{id}}, saved successfully",
		"error": "Employee status {{id}}, cannot be saved.",
		"deleted": "Employee status {{id}}, successfully deleted",
		"fields": {
			"nSitEmpreg": "Situation Code",
			"designaBreve": "Brief designation",
			"designaCompl": "Full designation",
			"processaVenc": "Process expiration"
		}
	},
	"solic": {
		"title_detail": "Billing request notice text {{id}}",
		"title_new": "New Billing Request Notice Text",
		"title_plural": "Charge request notice texts",
		"pesquisa": "To look for",
		"saved": "Billing request notice text {{id}}, saved successfully.",
		"error": "Billing request notice text {{id}}, cannot be saved.",
		"deleted": "Billing request notice text {{id}}, successfully deleted.",
		"fields": {
			"tipoTexto": "Type",
			"nTexto": "Number",
			"tituloTexto": "Title",
			"nDiasAtraso": "Number of days late",
			"note": "Description"
		},
		"datasource": {
			"0": "Header",
			"1": "Baseboard",
			"cabecalho": "Header",
			"rodape": "Baseboard"
		}
	},
	"sscol": {
		"title_detail": "Social Security Compensation {{id}}",
		"title_new": "New Social Security Remuneration",
		"title_plural": "Social Security Remunerations",
		"pesquisa": "To look for",
		"saved": "Social Security Remuneration {{id}}, saved successfully.",
		"error": "Social Security Remuneration {{id}}, cannot be saved.",
		"deleted": "Social Security Remuneration {{id}}, successfully deleted.",
		"fields": {
			"codSSCol": "Code",
			"designacao": "Designation",
			"dias": "Days",
			"valor": "Value"
		}
	},
	"subde": {
		"title_detail": "Sub-Department {{id}}",
		"title_new": "New Sub-Department",
		"title_plural": "Sub-Departments",
		"pesquisa": "To look for",
		"saved": "Sub-Department {{id}}, saved successfully.",
		"error": "Sub-Department {{id}}, cannot be saved.",
		"deleted": "Sub-Department {{id}}, successfully deleted",
		"fields": {
			"nSubDe": "Code.",
			"nSubDePlaceholder": "Sub-department code",
			"nome": "Name",
			"nomePlaceholder": "Sub-department name"
		}
	},
	"subfamilias": {
		"title_detail": "Subfamily {{id}}",
		"title_new": "New Subfamily",
		"title_plural": "Sub-families",
		"pesquisa": "To look for",
		"saved": "Sub-family {{id}}, saved successfully.",
		"error": "Sub-family {{id}}, cannot be saved.",
		"deleted": "Subfamily {{id}}, deleted successfully",
		"fields": {
			"nSubFa": "Code.",
			"nSubFaPlaceholder": "Subfamily code",
			"nome": "Name",
			"nomePlaceholder": "Subfamily name"
		}
	},
	"suspensaoContrato": {
		"data": {
			"tscNA": "AT",
			"tscSim": "Yes",
			"tscNao": "No"
		}
	},
	"svat": {
		"messages": {
			"noData": "Please choose the year and press the \"Process\" button to view the data."
		},
		"modal": {
			"title": "SVAT Financial Statements - Year: {{currentyear}}",
			"titleBalancete": "Balance sheet"
		},
		"fields": {
			"descricao": "Rubric",
			"notas": "Grades",
			"anoN": "Year N",
			"anoN1": "Year N-1",
			"nContaDe": "Account No.",
			"nContaAte": "Number of Counts Until",
			"taxonomyCode": "Taxonomy",
			"taxonomyDescription": "Description",
			"taxonomyClasse": "Class",
			"taxonomySaldoEsperado": "Expected Balance",
			"mesPeriodo": "Month Period",
			"tipoSaldo": "Balance Type",
			"nomeCliente": "Name",
			"nConta": "Account No."
		},
		"strings": {
			"mainTabBalanco": "Balance",
			"mainTabDemoResultadosPorNaturezas": "Statement of Income by Natures",
			"rubricaSubTabIntervaloContas": "Account Ranges",
			"rubricaSubTabBalancete": "Balance sheet",
			"verBalanceteTitulo": "View trial balance"
		}
	},
	"taxasiva": {
		"title_detail": "Rate {{id}}",
		"title_new": "New Rate",
		"title_plural": "VAT rates",
		"pesquisa": "To look for",
		"saved": "Rate {{id}}, saved successfully",
		"error": "Rate{{id}}, cannot be saved.",
		"deleted": "Rate {{id}}, deleted successfully",
		"fields": {
			"codIva": "VAT code",
			"dataAltLegal": "Legal Change Date",
			"taxa": "Rate",
			"observacao": "Observation"
		},
		"criar": "VAT Rate Maintenance",
		"criada": "VAT rate created successfully.",
		"codIvaNull": "The VAT code cannot be null"
	},
	"taxasretencao": {
		"title_new": "New Retention Rate",
		"title_detail": "{{id}} retention rate",
		"title_plural": "Retention Rates",
		"saved": "{{id}} retention rate, saved successfully",
		"error": "Retention rate {{id}}, cannot be saved.",
		"deleted": "Retention rate {{id}}, deleted successfully",
		"pesquisa": "To look for",
		"fields": {
			"codRet": "Retention code",
			"nomeRet": "Name",
			"taxaRet": "Rate (%)",
			"tipoCodRet": "Code type",
			"sinal": "Motion signal",
			"tipRendiment": "Income type",
			"nContaRetencao": "Retention account",
			"nomeTipRendiment": "Income type",
			"nomeContaRetencao": "Retention account"
		},
		"tipoRendimento": {
			"title": "Type of income",
			"fields": {
				"declaracao": "Declaration",
				"tipoRendimento": "Income type",
				"regTrib": "Tax regime"
			}
		},
		"declaracaoRetFonte": {
			"title": "Withholding tax declaration",
			"fields": {
				"zona": "Zone",
				"rubrica": "Rubric"
			}
		}
	},
	"taxonomias": {
		"exportXls": "Export Grid",
		"autoCorrect": "Apply automatic fixes",
		"autoCorrectSuccess": "Successfully applied fixes",
		"autoCorrectDatas": "Apply automatic corrections to dates",
		"autoCorrectDatasMessageOkCancel": "When applying automatic correction to document dates, invalid dates will be changed to the last day of the period to which the document refers.<br/>Are you sure you want to continue?",
		"autoCorrectDatasTitleOkCancel": "Attention!",
		"autoCorrectDoc0": "Apply automatic corrections to documents with just one entry and with a value of 0",
		"autoCorrectClasse9": "Apply automatic corrections to documents with unsettled class 9",
		"autoCorrectClasse0": "Apply automatic corrections to documents with unsettled class 0",
		"autoCorrectMessageClasse0": "When applying automatic correction to documents with unpaid class 0, the system will automatically post the account {{nconta}} in order to pay off class 0.<br/>Are you sure you want to continue?",
		"autoCorrectMessageClasse9": "When applying automatic correction to documents with unsettled class 9, the system will automatically post the account {{nconta}} in order to settle the analysis.<br/>Are you sure you want to continue?",
		"autoCorrectMessageClasseDocValor": "When applying automatic correction to documents with only one line of value 0, the system will post the same account with the opposite sign in all documents in this situation.<br/>Are you sure you want to continue?",
		"relacaoContas": {
			"nContaDe": "Account No.",
			"nContaAte": "Number of Counts Until",
			"codTaxonomia": "Taxonomy Code",
			"descricao": "Description"
		},
		"informacaoLegal": {
			"codTaxonomia": "Taxonomy Code",
			"codSNC": "SNC Code",
			"descricao": "Description",
			"observacoes": "Comments",
			"classe": "Class",
			"saldoEsperado": "Expected Balance",
			"demFinanCampDeb": "Financial Statements Debit Field",
			"demFinanCampCred": "Financial Statements Credit Field"
		},
		"planoOficial": {
			"nConta": "Account No.",
			"nome": "Name"
		},
		"validacoesPlano": {
			"fase": "Phase",
			"conta": "Account No.",
			"mensagem": "Message",
			"mensagemCorrecaoAutomatica": "AutoCorrect Message",
			"contaterceirosuccess": "Third-party current account successfully created.<br/>Do you want to edit the third-party account?"
		},
		"validacoesSvat": {
			"showOnlyErrors": "Only view accounts with errors",
			"tableFirstHeader": {
				"contasComSaldo": "Accounts with Balance",
				"valSVAT": "SVAT validations for accounting type - \"Organized w/SNC\""
			},
			"tableSecondHeader": {
				"contas": "Accounts",
				"saldos": "Balances - No results calculation"
			},
			"tableThirdHeader": {
				"nConta": "Account No.",
				"nome": "Name",
				"tipo": "Type",
				"conta": "Account",
				"saldo": "Balance",
				"codTaxonomia": "Taxonomy Code",
				"descricao": "Description",
				"classe": "Class",
				"saldoEsperado": "Expected Balance",
				"dezembroNormal": "December (Normal)",
				"dezembroFirst": "December (1st Closing)",
				"dezembroSecond": "December (2nd Closing)"
			}
		},
		"validacoesLancamentos": {
			"tipo": "Type",
			"fase": "Phase",
			"conta": "Account",
			"periodo": "Period",
			"diario": "Daily",
			"numeroDocInterno": "Internal Doc No."
		},
		"relacaocontasTab": "Relationship of Accounts with Taxonomies",
		"informacaoLegalTab": "Legal Information",
		"planoOficialTab": "Official Published Plan",
		"validacoesSvatTab": "SVAT Validations",
		"validacoesPlanoTab": "Plan Validations",
		"validacoesLancamentosTab": "Release Validations",
		"confirmValidacoesLancamentos": "Do you want to validate the Entries?<br/>This operation may take a few minutes.",
		"actions": {
			"criarContaTerceiro": "Create third party account",
			"trfConta": "Account transfer",
			"manutencaoPoc": "Maintenance of the Chart of Accounts"
		},
		"modalContaSalda": {
			"titleAnalitica": "Please choose the account with which you want to pay the analytics in the documents",
			"titleClasse0": "Please choose the account with which you want to pay class 0 in the documents",
			"conta": "Account"
		},
		"grids": {
			"title": "Taxonomies {{typeTaxonomy}} - {{typeAccounting}}"
		}
	},
	"templates": {
		"title_detail": "Template {{id}}",
		"title_new": "New template",
		"title_plural": "Templates",
		"pesquisa": "To look for",
		"saved": "Template {{id}}, saved successfully",
		"error": "Template {{id}}, cannot be saved.",
		"fields": {
			"name": "Name",
			"subject": "Subject",
			"body": "Body",
			"variables": "Variables"
		},
		"text": {
			"sendEmail": "Send email"
		}
	},
	"terceiros": {
		"clientes": "Customers",
		"fornecedores": "Suppliers",
		"outros": "Other Debtors and Creditors"
	},
	"terms": {
		"title": "Terms, conditions and privacy policy CentralGest Cloud",
		"version": "Version <strong>{{version}}</strong>",
		"introduction": "Before using CentralGest Cloud, you must accept our terms and conditions for using the service and our privacy policy.",
		"titleTerms": "Terms and conditions",
		"titlePrivacyPolicy": "Privacy Policy",
		"changedTerms": "The privacy policy or terms and conditions of use of the CentralGest Cloud service have <strong>been updated</strong> since the last time you accepted them, please review and accept them.",
		"acceptLabel": "I have read and accept the terms and conditions of use of the service and the privacy policy",
		"acceptTooltip": "To continue, you must accept the terms and conditions of use of the service and the privacy policy",
		"errorRequired": "You must accept CentralGest Cloud's terms, conditions and privacy policy to access the application."
	},
	"tesestados": {
		"title_detail": "Treasury forecast validation status {{id}}",
		"title_new": "New treasury forecast validation status",
		"title_plural": "Cash Forecast Validation States",
		"pesquisa": "To look for",
		"saved": "Treasury forecast validation status {{id}}, saved successfully.",
		"error": "Treasury forecast validation status {{id}}, cannot be saved.",
		"deleted": "Treasury forecast validation status {{id}}, successfully deleted",
		"fields": {
			"codEstado": "State Code",
			"codEstadoPlaceholder": "Cash Forecast Validation Status Code",
			"descricao": "Description",
			"descricaoPlaceholder": "State description",
			"estadoValidacao": "Validation status",
			"estadoValidacaoPlaceholder": "Choose validation status"
		}
	},
	"tesrubrica": {
		"title_detail": "Validation status of Treasury Forecasts {{id}}",
		"title_new": "New Treasury Forecast validation status",
		"title_plural": "Treasury forecast validation states",
		"pesquisa": "To look for",
		"deleted": "Treasury Forecast validation status successfully deleted.",
		"saved": "Validation status of Treasury Forecasts {{id}}, saved successfully.",
		"error": "Validation status of Treasury Forecasts {{id}}, cannot be saved.",
		"fields": {
			"tesRubricaID": "Rubric Code",
			"nome": "Name",
			"receitaDespesa": "Income/Expense"
		},
		"tipoReceitaDespesa": {
			"receita": "Revenue",
			"despesa": "Expense"
		}
	},
	"tipoAbonoDesc": {
		"data": {
			"abono": "Allowance",
			"desconto": "Discount"
		}
	},
	"tipoAnalitica": {
		"data": {
			"lancaConformeDefinidoIntegracao": "Launches as defined in integration",
			"naoLancaAnalitica": "Does not launch analytics",
			"lancaSempreAnalitica": "Always launch analytics"
		}
	},
	"tipoAssiduidadeSegSocial": {
		"data": {
			"taaNA": "AT",
			"taaSim": "Yes",
			"taaNao": "No"
		}
	},
	"tiposAusencias": {
		"data": {
			"na": "AT",
			"porFerias": "by Vacation",
			"porDoencaImprevista": "due to unforeseen illness",
			"porDoencaProf": "due to occupational illness",
			"porDoencaProlongada": "due to prolonged illness",
			"porTribunal": "by Court",
			"porServicoMilitar": "by Military Service",
			"porAssistenciaFamiliar": "by Family Assistance",
			"porPaternidadeMaternidade": "by Paternity/Maternity",
			"porLicencaAurorizada": "by Authorized License",
			"porPenaDisciplinar": "for Disciplinary Penalty",
			"porLicencaSabatica": "by Sabbatical Leave",
			"porCasamento": "by Marriage",
			"porNascimentoFamiliar": "by Birth of Family",
			"porFalecimentoFamiliar": "due to death of family member",
			"porTrabalhadorEstudante": "by Student Worker",
			"porGreveParalizacao": "due to Strike/Shutdown",
			"porConsultaMedico": "by Medical Consultation, Diagnostic Exams",
			"outras": "Others",
			"porFaltaInjustificada": "due to unjustified absence",
			"actividadeSindical": "by Union Activity",
			"acidenteTrabalho": "due to Work Accident"
		}
	},
	"tiposBalancoSocial": {
		"data": {
			"tabsNA": "AT",
			"tabsSim": "Yes",
			"tabsNao": "No"
		}
	},
	"tipoBem": {
		"pesquisa": "Types of good",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"notApplicable": "AT",
			"stocks": "Stocks",
			"otherGoodsServices": "Other Goods and Services",
			"immobilized": "Immobilized"
		}
	},
	"tiposCategoria": {
		"data": {
			"notApplicable": "No Group",
			"vencimento": "Maturity",
			"diuturnidade": "Seniority",
			"premios": "Awards",
			"comissoes": "Commissions",
			"subRefeicao": "Sub.",
			"ajudaCusto": "Subsistence allowance",
			"horaExtra": "Extra hour",
			"ferias": "Vacation",
			"subFerias": "Sub.",
			"feriasPagas": "Paid vacation",
			"subNatal": "Sub.",
			"trabNocturno": "Night Work",
			"insencaoHorario": "Exemption schedule",
			"subTurno": "Sub.",
			"faltasPagas": "Paid Fouls",
			"indemnizacaoCaducidade": "Forfeiture Compensation",
			"indemnizacaoDespedimento": "Redundancy Compensation",
			"indemnizacaoGlobal": "Global Compensation",
			"deslocacao": "Travel",
			"remProfLivre": "Free Professional Remuneration",
			"iva": "VAT",
			"virtual": "Virtual",
			"suplementoRemuneratorio": "Remuneration Supplement (Allowance for Failures)",
			"ticketValesRefeicao": "Ticket/Meal Vouchers",
			"pagamentosEmEspecie": "Payment in Kind",
			"valesEducacao": "Education Vouchers",
			"utlViaturaPatronal": "Use of Employer's Vehicle",
			"gratificacoesBombeiros": "Firefighter Bonuses",
			"cartaoOferta": "Gift Card",
			"apoioRegimeLayoff": "Layoff Regime Support",
			"despesasTeletrabalho": "Teleworking Expenses",
			"utilizHabitaPropriaPermanente": "Use of Housing Provided by the Employer",
			"gratificacaoBalancoArt236": "Balance Sheet Bonus Art. 236 OE - 2024"
		}
	},
	"tipoClasseContrato": {
		"data": {
			"contratoServico": "Service Agreement",
			"contratoPrecoCliente": "Customer Price List Contract",
			"contratoPrecoFornecedor": "Supplier Price List Contract",
			"contratoSubContratacao": "Subcontracting Agreement",
			"contratoFornecimentoCliente": "Customer Supply Contract",
			"contratoFornecimentoFornecedor": "Supplier Supply Contract",
			"contratoAlocacaoRecurso": "Resource Allocation Agreement"
		}
	},
	"tipoConta": {
		"pesquisa": "Account types",
		"fields": {
			"value": "Value",
			"label": "Name"
		},
		"data": {
			"movement": "Movement",
			"subtotal": "Subtotal",
			"reason": "Reason",
			"class": "Class",
			"cc": "Current account"
		}
	},
	"tipoContaDiasSubAlimentacao": {
		"data": {
			"notApplicable": "AT",
			"yes": "Yes",
			"no": "No"
		}
	},
	"tipoContabilidade": {
		"data": {
			"none": "It does not have",
			"organizadaPOCDL35": "Organized with POC DL 35",
			"naoOrganizadaPOCDL35": "Not organized w/ POC DL 35",
			"organizadaSNC": "Organized with SNC",
			"naoOrganizadaSNC": "Not organized with SNC",
			"organizadaPOCIPSS": "Organized with POC IPSS",
			"organizadaPOCAL": "Organized with POC AL",
			"organizadaPOCP": "Organized with POC P",
			"organizadaPOCMZ": "Organized with POC MZ",
			"naoOrganizadaPOCMZ": "Not organized with POC MZ",
			"organizadaPGCAN": "Organized with PGC AN",
			"naoOrganizadaPGCAN": "Not organized with PGC AN",
			"planoGeralContasNIRFMZ": "General Plan NIRF MZ Accounts",
			"organizadaSNCIPSS": "Organized with SNC IPSS",
			"organizadaSNCPE": "Organized with SNC PE",
			"organizadaSNCME": "Organized with SNC ME",
			"organizadaEntSectorNLucrativo": "Organized - Non-Profit Sector Entities",
			"naoOrganizadaArt50CodIVA": "Not Organized - Article 50 of the VAT Code",
			"organizadaSNCAP": "Organized with SNC AP"
		}
	},
	"tipoContrato": {
		"data": {
			"semtipocontrato": "No contract type",
			"contratosemtermo": "Termless (permanent) contract",
			"contratoatermo": "Term contract",
			"contratotrabtempoindeterm": "Employment contract.",
			"contratotrabalhotermocedtemp": "Fixed-term employment contract for temporary assignment",
			"contratoprestoserviço": "Provision of service contract"
		}
	},
	"tipoContratotrab": {
		"data": {
			"tempoIndeterminado": "Contract for an indefinite period",
			"termoCerto": "Fixed-term contract",
			"termoIncerto": "Uncertain term contract"
		}
	},
	"tipoDeducao": {
		"pesquisa": "Types of deduction",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"notApplicable": "AT",
			"withDeduction": "With Deduction",
			"noDeduction": "No Deduction",
			"withCountryRefundOrigin": "With Reimbursement from the Country of Origin"
		}
	},
	"tipoDeducaoTaxa": {
		"pesquisa": "Types of tax deduction",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"normal": "Normal",
			"intraCommunity": "Intra Community",
			"passiveSubjectInversion": "Passive Subject Inversion",
			"notApplicable": "AT"
		}
	},
	"tipoDescritivo": {
		"pesquisa": "Types of description",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"receipt": "Receipt",
			"acceptedLetter": "Aceite Lyrics",
			"acceptedDiscounted": "Discount Accepted",
			"acceptedPaymentDiscounted": "Payment Accepted Discounted",
			"acceptedReturnDiscounted": "Return Accepted Discounted",
			"acceptedReformDiscounted": "Retirement Accepted Discounted",
			"acceptedPaymentNotDiscounted": "Payment Accepted Not Discounted",
			"acceptedReturnNotDiscounted": "Return Accepted Not Discounted",
			"acceptedReformNotDiscounted": "Retirement Accepted not Discounted",
			"preDatedCheck": "Post-dated Check",
			"preDatedCheckDiscount": "Post-dated Check Discount",
			"preDatedCheckPaymentDiscounted": "Pre-dated Check Payment Discounted",
			"preDatedCheckReturnDiscounted": "Post-Dated Check Return Cashed",
			"preDatedCheckRetirementDiscounted": "Pre-dated check discounted reform",
			"preDatedCheckPaymentNotDiscounted": "Post-dated check payment not cashed",
			"preDatedCheckReturnNotDiscounted": "Post-Dated Check Return Not Cashed",
			"preDatedCheckRetirementNotDiscounted": "Non-Discounted Post-Dated Check Reform",
			"saqueSemAceite": "Withdrawal Without Acceptance",
			"DescontoSaqueSemAceite": "Unaccepted Withdrawal Discount",
			"pagamentoSaqueSemAceiteDiscounted": "Payment Withdrawal Without Acceptance Discounted",
			"devoluçãoSaqueSemAceiteDiscounted": "Return of Unaccepted Withdrawal Discounted",
			"reformaSaqueSemAceiteDiscounted": "Discounted Unaccepted Withdrawal Reform",
			"pagamentoSaqueSemAceiteNotDiscounted": "Payment Withdrawal Without Acceptance Not Discounted",
			"devoluçãoSaqueSemAceiteNotDiscounted": "Return of Unaccepted Withdrawal not Discounted",
			"reformaSaqueSemAceiteNotDiscounted": "Unaccepted Non-Discounted Withdrawal Reform",
			"doesNotControlVAT": "Does not control VAT",
			"documentManagement": "Document management",
			"firstAdoptionNewAccountingReference": "First Adoption of the New Accounting Reference",
			"accountingPolicyChanges": "Accounting Policy Changes",
			"differencesTranslationFinancialStatements": "Financial Statement Conversion Differences",
			"realizationExcessesRevaluationTangibleIntangibleAssets": "Realization of Surplus Revaluation of Tangible and Intangible Assets",
			"excessesRevaluationTangibleIntangibleAssets": "Surplus Revaluation of Tangible and Intangible Assets",
			"deferredTaxAdjustments": "Deferred Tax Adjustments",
			"otherChangesShareholdersEquity": "Other Changes in Equity",
			"netIncome": "Net income",
			"capitalRealizations": "Capital Realizations",
			"performanceEmissionPremiums": "Issue Premium Achievements",
			"distributions": "Distributions",
			"lossCoverageEntries": "Entries for Loss Coverage",
			"otherOperations": "Other Operations"
		}
	},
	"tipoEntSuporteABDES": {
		"data": {
			"None": "AT",
			"Empregado": "Employee",
			"Patronato": "Patronage",
			"EmpregadoPatronato": "Employee and Employer"
		}
	},
	"tipoEntidadeSuporta": {
		"data": {
			"taesNA": "AT",
			"taesTrabalhador": "Worker",
			"taesEntidadePatronal": "Employer",
			"taesSegSocial": "Social Mon",
			"taesSeguro": "Safe",
			"taesSindicato": "Syndicate",
			"taesOutros": "Others"
		}
	},
	"tipoExcecaoCRABD": {
		"data": {
			"SemExcecoes": "No Exceptions",
			"ExcluirEmpregados": "Exclusion",
			"IncluirEmpregados": "Inclusion"
		}
	},
	"tipoInventario": {
		"data": {
			"naotemInvPerm": "No monthly perpetual inventory",
			"temInvPermMensalCG": "There is a monthly permanent inventory carried out in CentralGest",
			"temInvPermMensalManual": "Has monthly permanent inventory done manually"
		}
	},
	"tipoLiquidacao": {
		"pesquisa": "Types of settlement",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"notApplicable": "AT",
			"withSettlement": "With Settlement",
			"noSettlement": "No Settlement"
		}
	},
	"tipoOperacao": {
		"pesquisa": "Types of operation",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"notApplicable": "AT",
			"active": "Active",
			"passive": "Passive",
			"regularizationFavorState": "Regularization in favor of the State",
			"regularizationFavorCompany": "Regularization in favor of the Company"
		}
	},
	"tipoOrdDocumentos": {
		"data": {
			"dataNContribuinte": "Date |",
			"nContribuinteData": "Taxpayer No. |",
			"dataRececao": "Receipt date",
			"nomeData": "Name |",
			"dataNome": "Date |"
		}
	},
	"tipoPasswordRecibo": {
		"data": {
			"nao": "No",
			"igualNIF": "Yes, with a password equal to the NIF",
			"igualNumeroCC": "Yes, with a password equal to your citizen card number",
			"igualNumeroSS": "Yes, with a password equal to your social security number",
			"igualCodigoFuncionario": "Yes, with a password equal to the employee code"
		}
	},
	"tipoRecibo": {
		"data": {
			"taSim": "Yes",
			"taNao": "No"
		}
	},
	"tipoValorUnitarioRecolher": {
		"data": {
			"Indiferente": "By Default",
			"ValorVariavel": "Variable Value",
			"ValorFixo": "Fixed value",
			"REmuneracaoHora": "Hourly Pay",
			"REmuneracaoHoraBase": "Base Hour Pay",
			"VencimentoBase": "Base Salary",
			"REmuneracaoMensalTotal": "Total Monthly Remuneration",
			"RemuneracaoPorDiaUtil": "Remuneration Per Working Day",
			"REmuneracaoPorDiaBase30": "Remuneration Per Day /30",
			"REmuneracaoMensalSubsidioBase22": "Base Monthly Salary/22",
			"REmuneracaoMensalSubsidioBase30": "Base Monthly Salary/30",
			"REmuneracaoHora2": "Hour 2 Pay"
		}
	},
	"tipoRegularizacaoCampo40": {
		"pesquisa": "Types of Field 40 regularizations",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"notApplicable": "AT",
			"art78N2": "Art.º 78 nº 2 - Reduction or cancellation of the value of a transaction",
			"art78N3": "Art.º 78 nº 3 - Rectification of overpaid tax",
			"art78N6": "Art.º 78 nº 6 - Correction of additional material or calculation errors",
			"art78N7Pre2013ItemA": "Art.º 78 nº 7 uncollectible credits before 2013 paragraph a) in execution process",
			"art78N7Pre2013ItemB": "Art.º 78 nº 7 uncollectible credits before 2013 paragraph b) in Insolvency proceedings",
			"art78N7Pre2013ItemC": "Art.º 78 nº 7 uncollectible credits before 2013 paragraph c) in special revitalization process",
			"art78N7Pre2013ItemD": "Art.º 78 nº 7 uncollectible credits before 2013 paragraph d) in the Extrajudicial Business Recovery System (SIREVE)",
			"art78N7Post2013ItemA": "Art.º 78 nº 7 uncollectible credits after 2013 paragraph a) in execution process",
			"art78N7Post2013ItemB": "Art.º 78 nº 7 uncollectible credits after 2013 paragraph b) in Insolvency proceedings",
			"art78N7Post2013ItemC": "Art.º 78 nº 7 uncollectible credits after 2013 paragraph c) in special revitalization process",
			"art78N7Post2013ItemD": "Art.º 78 nº 7 uncollectible credits after 2013 paragraph d) in special revitalization process",
			"art78N8ItemD": "Art.º 78 nº 8 paragraph d) - Credits, SP, are less than €6000 recognized in Judicial proceedings - ROC",
			"art78N8ItemB": "Art.º 78 nº 8 paragraph b) - Credits, other than SP, are greater than 750 and less than €8000 recognized in Judicial proceedings, without assets - ROC",
			"art78N8ItemC": "Art.º 78 nº 8 paragraph c) - Credits, other than SP, are greater than 750 and less than €8000 recognized in Judicial proceedings, with assets - ROC",
			"art78N8ItemE": "Art.º 78 nº 8 paragraph e) - Credits, other than SP, are greater than 750 and less than €8000 recognized in judicial proceedings, on the bankrupt list - ROC",
			"art78AN4ItemA": "Art.º 78 - Paragraph 4 (a) - Credits in execution proceedings, without assets",
			"art78AN4ItemB": "Art.º 78 - Paragraph 4 b) - Credits in insolvency proceedings after being decreed",
			"art78AN4ItemC": "Art. 78 - No. 4 (c) - Special revitalization process credits, after approval",
			"art78AN4ItemD": "Art.º 78 - No. 4 paragraph d) - In the Extrajudicial Business Recovery System (SIREVE), after signing the agreement",
			"art78AN2ItemA": "Art.º 78 - Paragraph 2 (a)",
			"art78BN4": "Art.º 78 - B nº 4 - In doubtful debts, with tacit approval of deduction",
			"adjustmentsWhoseCreditDoesNotExceed750VATIncluded": "Regularizations whose credit does not exceed €750, VAT included",
			"otherRegularizationsNotCoveredArt78RegularizationCoveredArticles23to26": "Other regularizations not covered by article 78 - Regularizations covered by articles 23 to 26",
			"otherRegularizationsNotCoveredByArt78": "Other regularizations not covered by article 78 - Other Regularizations (e.g. merger)"
		}
	},
	"tipoRegularizacaoCampo41": {
		"pesquisa": "Types of Field 41 regularizations",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"notApplicable": "AT",
			"art78N3": "Art.º 78 nº 3 - Rectification of underpaid tax",
			"art78N4": "Art.º 78 nº 4 - Rectification of tax deducted in excess",
			"art78N6": "Art.º 78 nº 6 - Correction of material or calculation errors unless",
			"art78N7ItemA": "Art.",
			"art78N7ItemB": "Art.º 78 no. 7 paragraph b) - Art.º 78, no. 11, by communication of regularization, in Insolvency proceedings",
			"art78N7ItemC": "Art.º 78 nº 7 paragraph c) - Art.º 78, nº 11, by communication of regularization, in a special revitalization process",
			"art78N7ItemD": "Art.",
			"art78N8ItemD": "Art.º 78 nº 8 paragraph d) - Art.º 78, nº 11, by communication of regularization, SP, are less than €6000 recognized in Judicial proceedings",
			"art78N12": "Art.º 78 nº 12 - In recovery of credits in which VAT has already been deducted",
			"art78CN1": "Art.º 78 - C nº1 - By communication of regularization, SP, in doubtful debts, with tacit approval of deduction",
			"art78CN3": "Art.º 78 - C nº3 - In recovery of doubtful debts in which VAT has already been deducted",
			"otherRegularizationsNotCoveredByArt78RegularizationCoveredArticles23to26": "Other regularizations not covered by article 78 - Regularizations covered by articles 23 to 26",
			"otherRegularizationsNotCoveredByArticle78": "Other regularizations not covered by article 78 - Other Regularizations (e.g. merger)",
			"article6OfDecreeLawN198/90of19June": "Article 6 of Decree-Law No. 198/90 of June 19",
			"decreeLawNo19/2017OfFebruary14": "Decree-Law No. 19/2017 of February 14"
		}
	},
	"tipoResumoMensal": {
		"pesquisa": "Monthly summary types",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"naoUsa": "Does not use",
			"aPartirDoUltimoDia": "From the last day",
			"aPartirDoUltimoDiaUtil": "From the last business day",
			"aPartirDoPrimeiroDiaDoMesSeguinte": "From the first day of the following month",
			"aPartirDoPrimeiroDiaUtilDoMesSeguinte": "From the first business day of the following month"
		}
	},
	"tipoTaxa": {
		"pesquisa": "Fee Types",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"notApplicable": "AT",
			"exempt": "Free",
			"reduced": "Reduced",
			"intermediate": "Intermediate",
			"normal": "Normal"
		}
	},
	"tipoTaxaIva": {
		"pesquisa": "VAT rate types",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"exempt": "Exempt",
			"reduced": "Reduced",
			"normal": "Normal",
			"intermediate": "Intermediate",
			"doesNotDeduct": "Do not deduct",
			"diesel50Percent": "Diesel 50%",
			"exemptEU": "EU Exempt",
			"exemptNotEU": "Exempt Outside EU"
		}
	},
	"tipoTaxaRetencao": {
		"data": {
			"rendimentos": "Income",
			"subNatal": "Sub.",
			"subFerias": "Sub."
		}
	},
	"tipoTransmissaoActiva": {
		"pesquisa": "Types of active transmission",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"notApplicable": "AT",
			"transmissionSubject": "Subject Transmission",
			"advanceReceiptsYear": "Advance Receipts for the Year",
			"opLocalizedForeignProviderReverseCharge": "Op. Located here by Fornec.",
			"transmitFreeWithoutDeductible": "Exempt Transmission No right to deduction",
			"civilConstructionServicesReverseCharge": "Civil Construction Services With Reverse Charge (VAT Due By Purchaser)",
			"transmissionScrapReverseCharge": "Scrap Transmission With Reverse Charge (VAT Due By Purchaser)",
			"transmissionExemptOrNotSubjectDeduction": "Exempt or Non-Exempt Transmission with Right to Deduction - art. 21, al.",
			"transmissionExemptTourismVehicles": "Exempt Transmission of Tourist Vehicles art. 9, no. 32 (which VAT was not deducted)",
			"transmissionExemptVAT": "Transmission Exempt from VAT subject to IMT art. 9, no. 30",
			"priorYearReceiptsWithDefinitiveBillingYear": "Advance Receipts from the Previous Year with Final Invoicing in the Year",
			"establishmentTransmission": "Transmission of Establishment art. 3, no. 4",
			"otherActiveOperationsOutsideCIVAField": "Other active operations outside the CIVA field",
			"intraCommunityTransmission": "Intra-Community Transmission - Art.º 14 RITI",
			"transmissionExport": "Transmission Export",
			"opMNLocalizedProviderEUResidentsReverseCharge": "Op. Located in MN by Supplier.",
			"transmissionGoldReverseCharge": "Gold Transmission (DL 362/99) With Reverse Charge (VAT Due By Purchaser)",
			"transmissionRealEstateWaiverExemptionReverseCharge": "Transfer of Properties with Waiver of Exemption With Reverse Charge (VAT Due by the Purchaser)",
			"opFromAllocationOwnUseFreeTransmissionsAllocationSectorExemptActivity": "Op. Of Allocation to Own Use, Free Transmissions and Allocation to an Exempt Activity Sector",
			"opBankingActivity": "Banking Activity Op.",
			"insuranceBusinessOpportunity": "Insurance Activity Op.",
			"opportunityRentalRealEstate": "Real Estate Leasing Activity Op.",
			"triangularIntraCommunityTransmission": "Triangular Intra-Community Transmission - Art. 8th and 15th RITI",
			"intraCommunityService": "Provision of Intra-Community Services - Art.º 6 CIVA",
			"transmissionNewMeansTransportEUIndividuals": "Transmission of New Means of Transport to EU Individuals",
			"greenhouseGasEmissionReverseCharge": "Greenhouse Gas Emission With Reverse Charge",
			"operacoesEfetuadasNoEstrangeiro": "Operations carried out abroad",
			"outrasOperacoesIsentasOuSemLiquidacaoDeIVAQueConferemDireitoADeducao": "Other Operations Exempt or without VAT assessment that give the right to deduction"
		}
	},
	"tipoTransmissaoPassiva": {
		"pesquisa": "Types of passive transmission",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"notApplicable": "AT",
			"acquisitionTotalTaxDeductionOthers": "Acquisition with Full VAT Deduction - Others",
			"acquisitionPartialDeductionVATOthers": "Acquisition with Partial VAT Deduction - Others",
			"acquisitionPartialDeductionVAT": "Acquisition with Partial VAT Deduction - (article 21 of CIVA - Diesel)",
			"acquisitionPartiallyTotallyDeductibleDeductionTourismVehicles": "Acquisition with Partial or Fully Deductible - Tourist Cars",
			"acquisitionGasolineFullyNonDeductibleTax": "Purchase of Gasoline With Totally Non-Deductible VAT",
			"acquisitionTourismTravelTotallyNonDeductibleTaxAcquisition": "Viat acquisition.",
			"acquisitionTourismTravelFullyNonDeductibleTaxRentOtherExpenses": "Viat acquisition.",
			"acquisitionGoodsServicesTotallyNonDeductibleTaxOther": "Acquisition of Goods and Services With Totally Non-Deductible VAT - Others",
			"acquisitionTaxExemptGoodsServices": "Acquisition of Goods and Services Exempt from VAT",
			"acquisitionGoodsServicesCoveredSchemeRetailingPetitioners": "Acquisition of Goods and Services Covered by the Exemption and Peq Regime.",
			"acquisitionExemptionVATSubmissionIMT": "Acquisition Exempt from VAT subject to IMT art. 9, no. 30",
			"acquisitionEstablishment": "Acquisition of Establishment art. 3, no. 4",
			"otherCIVAOutFieldLiabilities": "Other Passive Operations Outside the CIVA Field",
			"acquisitionCivilConstructionServices": "Acquisition of Civil Construction services (VAT payable by the purchaser)",
			"acquisitionScrap": "Acquisition of Scrap (VAT payable by the purchaser)",
			"intraCommunityAcquisitionExemptOrigin": "Intra-Community Acquisition - Exempt at Origin",
			"acquisitionCEESubjectOrigin": "Acquisition in the CEE - Subject to Origin",
			"importAcquisition": "Acquisition Import",
			"mNAcquisitionForeignSupplierReverseCharge": "Acquisition MN by Supplier.",
			"mNAcquisitionEUResidentsReverseCharge": "Acquisition MN by Supplier.",
			"goldAcquisitionReverseCharge": "Gold Acquisition (DL 362/99) With Reverse Charge (VAT Due By the Purchaser)",
			"acquisitionPropertiesWaiverExemptionReverseCharge": "Acquisition of Properties with Waiver of Exemption With Reverse Charge (VAT Due by the Purchaser)",
			"emissionGreenhouseGases": "Emission of Greenhouse Gases",
			"aquisicaoDeCorticaEOutrosProdutosDeOrigemSilvicola": "Acquisition of cork and other products of forestry origin"
		}
	},
	"tipoUnidadesValue": {
		"data": {
			"valor": "Value",
			"hora": "Hour",
			"minuto": "Minute",
			"segundo": "Second",
			"dia": "Day",
			"mes": "Month",
			"ano": "Year",
			"horautil": "Business Hour",
			"horabase30": "Base Time 30",
			"diautil": "Business day",
			"diabase30": "Base Day 30"
		}
	},
	"tipoNif": {
		"pesquisa": "NIF validation types",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"validoseminformacaodeActividade": "Valid without Activity information",
			"nifInvalidoInexistente": "Invalid NIF Non-existent",
			"validoSemActividade": "Valid without Activity",
			"validoComActividade": "Valid with Activity",
			"validoComActividadeCessada": "Valid with Activity Ceased"
		}
	},
	"tipoValorPenhora": {
		"emfraccao": "In fraction",
		"empercentagem": "In Percentage",
		"valorabsoluto": "Absolute value",
		"porinsolvencia": "Due to Insolvency"
	},
	"tipoValorizacao": {
		"data": {
			"custoMedioPonderado": "Weighted Average Cost",
			"precoUltimaCompra": "Last Purchase Price",
			"precoBaseCusto": "Cost Base Price",
			"custoMedio": "Average Cost",
			"custoPadrao": "Standard Cost"
		}
	},
	"tipofacpublicamodelo": {
		"data": {
			"nd": "Not applicable",
			"cius": "CIUS-PT",
			"ciusip": "Infrastructures in Portugal",
			"ciussaphety": "Saphety CIUS-PT",
			"ciusfeap": "FE-AP eSPAP"
		}
	},
	"tipohorario": {
		"title_detail": "Time type {{id}}",
		"title_new": "New type of schedule",
		"title_plural": "Types of schedules",
		"pesquisa": "To look for",
		"saved": "Schedule type {{id}}, saved successfully",
		"error": "Schedule type {{id}}, cannot be saved.",
		"deleted": "Time type {{id}}, deleted successfully",
		"fields": {
			"nTipoHorario": "Type of Schedule",
			"designaBreve": "Brief designation",
			"designaCompl": "Full designation"
		}
	},
	"tiposArredondamentoABDES": {
		"data": {
			"NaoArredonda": "Does not round",
			"UnidMaisProxima": "To Unit, nearest",
			"DezenaMaisProxima": "To Dezena, nearest",
			"Baixo1": "Bass 1",
			"Cima1": "Up 1",
			"Baixo10": "Low 10",
			"Cima10": "Up 10",
			"ParaDecimaMaisProxima": "To Décima, nearest",
			"ParaCentesimaMaisProxima": "To Centésima, nearest",
			"ParaMilesimaMaisProxima": "To Milésima, nearest",
			"ParaDezenaMaisInferior": "For Tens, Lower",
			"ParaDezenaMaisSuperior": "For Ten, more Superior",
			"ParaDecimaMaisInferior": "To Tenth, Lower",
			"ParaDecimaMaisSuperior": "To Tenth, more Higher",
			"ParaCentesimaMaisInferior": "To hundredth, lowest",
			"ParaCentesimaMaisSuperior": "To Centésima, most Superior",
			"ParaMilesimaMaisInferior": "To Milésima, lower",
			"ParaMilesimaMaisSuperior": "For Milésima, more Superior",
			"ParaCentenaMaisProxima": "To Centena, nearest"
		}
	},
	"tiposCategoriaDesc": {
		"data": {
			"segGrupo": "No Group",
			"irs": "IRS",
			"segSocial": "Social Security",
			"cga": "CGA",
			"impostoSelo": "Stamp tax",
			"sindicato": "Syndicate",
			"seguro": "Safe",
			"falta": "Lack",
			"ferias": "Vacation",
			"punicao": "Punishment",
			"retImpTerceiros": "Withholdings imposed by Third Parties",
			"subsidioRefeicao": "Meal Subsidy",
			"outra": "Other",
			"sobretaxaExtra": "Extraordinary Surcharge",
			"adse": "ADSE",
			"penhora": "Garnishment",
			"fct": "FCT - Work Compensation Fund",
			"fgct": "FGCT - Work Compensation Guarantee Fund",
			"penhoraPensaoAlimentos": "Food Pension Attachment",
			"indemnizacaoFaltaAvisoPrevio": "Compensation Lack of Prior Notice"
		}
	},
	"tipoComunicacaoAt": {
		"data": {
			"manual": "by File",
			"emTempoReal": "by Web-Service"
		}
	},
	"tipoCredito": {
		"data": {
			"credito1": "Credit 1",
			"credito2": "Credit 2",
			"credito3": "Credit 3",
			"credito4": "Credit 4",
			"credito5": "Credit 5"
		}
	},
	"tiposDescontoProporcionalABDES": {
		"data": {
			"naoProcessa": "Does not process",
			"porQuantidade": "By Quantity",
			"porNHorasDia": "By Number of Hours Day"
		}
	},
	"tiposEmpresa": {
		"data": {
			"nomeIndividual": "Individual Company",
			"sociedadeQuotas": "Quota Company",
			"sociedadeAnonima": "Anonymous society",
			"sociedadeCooperativa": "Cooperative Society",
			"associacao": "Association",
			"servicosMunicipalizados": "Municipalized Services",
			"sociedadeUnipessoal": "Unipersonal Company",
			"agrupComplementarEmrpesas": "Complementary Group of Companies",
			"camaraMunicipal": "Town hall",
			"empresaMunicipal": "Municipal Company",
			"institutoPublico": "Public Institute",
			"federacaoDesportiva": "Sports Federation",
			"sociedadeAnonimaDesportiva": "Sports Anonymous Society",
			"escolaSuperior": "College",
			"estabelecimentoEstavelSocEstrangeira": "Stable Establishment Foreign Company"
		}
	},
	"tipoFormacao": {
		"data": {
			"tfNA": "AT",
			"tfSim": "Yes",
			"tfnao": "No"
		}
	},
	"tiposIVA": {
		"data": {
			"0": "Exempt",
			"1": "Reduced",
			"2": "Normal",
			"3": "Intermediate",
			"4": "Does not deduct",
			"5": "Diesel 50%",
			"6": "Exempt CEE (European Economic Commission)",
			"7": "Exempt outside EEC (European Economic Commission)",
			"isenta": "Exempt",
			"reduzida": "Reduced",
			"normal": "Normal",
			"intermedia": "Intermediate",
			"naoDeduz": "Does not deduct",
			"gasoleo50": "Diesel 50%",
			"isentoCEE": "Exempt CEE (European Economic Commission)",
			"isentoForaCEE": "Exempt outside EEC (European Economic Commission)"
		}
	},
	"tiposMovimento": {
		"pesquisa": "Movement types",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"notDefined": "N/A",
			"default": "By Default",
			"squareMeters": "Square meters",
			"cubicMeters": "Cubic meters",
			"linearMeters": "Linear meters",
			"grams": "Grams",
			"kilos": "kilos",
			"tons": "Tons",
			"liters": "Liters",
			"hectoliters": "Hectoliters",
			"perimeter": "Perimeter",
			"packing": "Packaging",
			"hours": "Hours"
		}
	},
	"tipoValidacaoNif": {
		"pesquisa": "Search for NIF Situation Types",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"notApplicable": "AT",
			"requiresValidNIF": "Requires valid NIF",
			"requiresValidNIFAllowsInexistent": "Requires valid NIF but allows non-existent",
			"doesNotRequireNIF": "Does not require NIF"
		}
	},
	"tipoPeriocidade": {
		"data": {
			"Sempre": "Ever",
			"Mensal": "Monthly",
			"Anual": "Yearly",
			"Quinzenal": "Fortnightly",
			"Semanal": "Weekly"
		}
	},
	"tiposPresenca": {
		"data": {
			"tapNA": "AT",
			"tapNao": "No",
			"tapSim": "Yes"
		}
	},
	"tiposRefeicoes": {
		"pesquisa": "Account types",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"notDefined": "N/A",
			"launch": "Lunch",
			"lightLaunch": "Light Lunch",
			"dinner": "To have lunch",
			"lightDinner": "Light Dinner"
		}
	},
	"tipoRemunerado": {
		"data": {
			"notApplicable": "AT",
			"yes": "Yes",
			"no": "No"
		}
	},
	"tiposRetServNIF": {
		"data": {
			"adquirenteDeServicos": "Service Purchaser",
			"prestadoraDeServicos": "Service provider"
		}
	},
	"tipoTrabalho": {
		"data": {
			"tatNA": "AT",
			"tatTrabalhoNormal": "Normal Work",
			"tatTrabEXTRANormal": "Normal EXTRAordinary work",
			"tatTrabEXTRACompPeriodoNormaTrabalho": "Overtime work compensated for normal working periods",
			"tatTrabEXTRACompPeriodoFerias": "EXTRAordinary Work Compensated for Vacation Period",
			"tatTrabDiasDescansoComplementar": "Work On Complementary Rest Days','Work On Weekly Rest Days'",
			"tatTrabDiasDescansoSemanal": "Work on Weekly Rest Days",
			"tatTrabDiasFeriado": "Work on Holidays"
		}
	},
	"tiposanexo": {
		"continente": "Continent",
		"madeira": "Wood",
		"acores": "Azores",
		"decRecapitulativa": "Recapitulative Statement",
		"campo40": "Field 40",
		"campo41": "Field 41"
	},
	"tiposartigo": {
		"title_detail": "Article Type {{id}}",
		"title_new": "New Article Type",
		"title_plural": "Types of Articles",
		"pesquisa": "To look for",
		"saved": "Article Type {{id}}, saved successfully.",
		"error": "Article Type {{id}}, cannot be saved.",
		"deleted": "Article Type {{id}} successfully deleted",
		"fields": {
			"codTpArt": "Code.",
			"CodPlaceholder": "Article type code",
			"nome": "Name",
			"nomePlaceholder": "Article type name",
			"productCategory": "Product type (Inventory)",
			"productCategoryPlaceholder": "Type of product in inventory"
		}
	},
	"tiposcontrato": {
		"title_detail": "Contract Type {{id}}",
		"title_new": "New Type of Contract",
		"title_plural": "Types of Contract",
		"pesquisa": "To look for",
		"saved": "Contract Type {{id}}, saved successfully.",
		"error": "Contract Type {{id}}, cannot be saved.",
		"deleted": "Contract Type {{id}}, successfully deleted.",
		"fields": {
			"nTipoContrato": "Code.",
			"designacaoBreve": "Brief Designation",
			"designacaoCompleta": "Full Designation",
			"codTipCtQPessoal": "Contract Type Q. Personal",
			"naoEnviaparaDMR": "Does not send to DMR"
		}
	},
	"tiposregistocadastro": {
		"title_detail": "Registration Type {{id}}",
		"title_new": "New registration type",
		"title_plural": "Registration Types",
		"pesquisa": "To look for",
		"deleted": "Registration Type deleted successfully.",
		"saved": "Registration type {{id}}, saved successfully.",
		"error": "Registration Type {{id}}, cannot be saved.",
		"fields": {
			"nMotivoMov": "Registration Types",
			"designaBreve": "Brief Description",
			"designaCompl": "Full Description",
			"codQPMOT": "Reason Code Q. Personal",
			"infobalancosoc": "Information for the Social Balance:"
		},
		"infobalancosoc": {
			"movimentopessoas": {
				"movimentopessoas": "Movement of people",
				"entradas": {
					"entradas": "Appetizer",
					"contratosemtermo": "Non-Term Contract (Effective)",
					"contratocomtermocerto": "Contract WITH Certain Term",
					"contratocomtermoincerto": "Contract WITH Uncertain Term"
				},
				"saidas": {
					"saidas": "Outputs",
					"contratosemtermo": {
						"contratosemtermo": "Non-Term Contract (Effective)",
						"iniciativadotrabalhador": "Worker Initiative",
						"mutuoacordo": "Mutual Agreement",
						"iniciativaempresa": "Company Initiative",
						"despedimentocoletivo": "Collective Dismissal",
						"despedimento": {
							"despedimento": "Dismissal",
							"comjustacausa": "With Just Cause",
							"semjustacausa": "Without Just Cause"
						},
						"reformaporinvalidez": "Retirement Due to Disability",
						"reformaporvelhice": "Old Age Retirement",
						"reformaantecipada": "Early Retirement",
						"prereforma": "Pre-Reformation",
						"falecimento": "Death",
						"impedimentoprolongado": "Prolonged_Impediment"
					},
					"contratocomtermo": {
						"contratocomtermo": "Contract WITH Term",
						"termocerto": {
							"termocerto": "Right Term",
							"cessacao": "Cessation",
							"antecipacaodacessacao": "Anticipation of Termination",
							"impedimentoprolongado": "Prolonged Offside"
						},
						"termoincerto": {
							"termmoincerto": "Uncertain Term",
							"cessacao": "Cessation",
							"antecipacaodacessacao": "Anticipation of Termination",
							"impedimentoprolongado": "Prolonged Offside"
						}
					}
				}
			},
			"alteracaodoregimedecontrato": {
				"alteracaodoregimedecontrato": "Change to the Contract Regime",
				"contratocomtermo": {
					"contratocomtermo": "Contract WITH Term",
					"passouasemtermo": "Passed the SEM Term"
				}
			},
			"promocoes": {
				"promocoes": "Promotions",
				"porantiguidade": "By Antiquity",
				"pormerito": "By Merit",
				"poroutromotivo": "For Another Reason"
			},
			"outros": {
				"reconversoesreclassificacao": "Reconversions/Reclassification",
				"aumentodevencimento": "Salary Increase",
				"outros": "Others"
			}
		}
	},
	"tipotempo": {
		"pesquisa": "Types of Time",
		"fields": {
			"value": "Value",
			"name": "Name"
		},
		"data": {
			"completo": "Full Time",
			"parcial": "Part time"
		}
	},
	"tirs": {
		"title_detail": "IRS tax {{id}}",
		"title_new": "New IRS Rate",
		"title_plural": "IRS fees",
		"pesquisa": "To look for",
		"saved": "IRS rate {{id}}, saved successfully.",
		"error": "IRS rate {{id}}, cannot be saved.",
		"deleted": "IRS tax {{id}}, successfully deleted.",
		"fields": {
			"nTabIrs": "IRS Table Code",
			"designaBreve": "Brief Designation",
			"designaCompl": "Full Designation",
			"dtulTabela": "Entry Date",
			"datasTaxasIrs": "IRS tax dates",
			"anoVigor": "Effective year"
		},
		"table": {
			"title": "Levels - IRS",
			"ateRemunMes": "See you at Remun.",
			"maiorigual5": ">=5",
			"vabater5": "Value.",
			"vabater4": "Value.",
			"vabater3": "Value.",
			"vabater2": "Value.",
			"vabater1": "Value.",
			"vabater0": "Value.",
			"taxaMarginalMaxima": "Maximum marginal rate",
			"parcelaAbaterValor": "Installment discount value",
			"parcelaAbaterFormula": "Parcel slaughter formula",
			"parcelaAbaterDepende": "Portion slaughter depends",
			"txEfetivaMensalEscal": "Effective monthly rate scale",
			"parcelaAbaterFormulaInfo": "Parcel slaughter formula info"
		},
		"btn": {
			"novoEscalao": "New Tier",
			"apagarEscalao": "Delete Tier"
		},
		"messages": {
			"naoPodeRemoverTabelasSistema": "You cannot remove IRS tables from the system.",
			"naoPodeAtualizarTabelasSistema": "You cannot change system IRS tables."
		}
	},
	"tokens": {
		"title_detail": "Token {{id}}",
		"title_plural": "Tokens",
		"pesquisa": "To look for",
		"fields": {
			"token": "token",
			"validUntil": "Valid until"
		}
	},
	"trDocsCabsWizard": {
		"captions": {
			"transformacaoDocumentos": "Document Transformation",
			"documento": "Document",
			"dataDoc": "Document Date",
			"nConta": "Account No.",
			"conta": "Account",
			"nif": "NIF",
			"liquido": "Liquid",
			"iva": "VAT",
			"totalDocumento": "Total Document",
			"nCCusto": "Cost No.",
			"nRefProcesso": "Process",
			"nFactFornec": "External Doc No.",
			"onlyPossibleToTransform": "Shows only possible to transform",
			"regraTransformacao": "Transformation Rule",
			"agruparPorCliente": "Group by {{name}}",
			"naoAgrupar": "Do not group",
			"dataDocExterno": "External Doc Date",
			"texto": "Text",
			"processar": "Process",
			"avancar": "Advance",
			"encerrarDocumentos": "Finalize Documents",
			"erro": "Error",
			"docsGerados": "Generated Documents",
			"erros": "Errors",
			"transformacaoDocumentosErros": "Document Transformation - Errors",
			"existemErrosATranformDocs": "There are {{count}} error(s) in document transformation.",
			"verErros": "View Errors",
			"tipoDocumento": "Document Type",
			"ateDataDoc": "Until Document Date",
			"cliente": "Client",
			"fornecedor": "Supplier"
		},
		"steps": {
			"pesquisa": "Search"
		},
		"tooltips": {
			"documentoTransformado": "Transformed Document",
			"editarDocumento": "Edit Document",
			"finalizarDocumento": "Finalize Document",
			"existemErrosATranformDocs": "Click here to see errors in document transformation."
		}
	},
	"transferenciasarmazens": {
		"title_detail": "New transfer of articles between warehouses",
		"title_list": "Transfers of items between warehouses",
		"fields": {
			"origemNArtigo": "Article Code",
			"origemNArmazem": "Origin warehouse",
			"origemQtd": "Amount",
			"destinoNArmazem": "Destination warehouse",
			"destinoQtd": "Destination quantity"
		},
		"modal": {
			"newlinetitle": "Article transfers",
			"titleorigem": "Source document (Output)",
			"titledestino": "Target document (Input)",
			"fields": {
				"data": "Document date",
				"tipodoc": "Type Doc.",
				"nnumer": "No.",
				"ndoc": "Doc No.",
				"clientfor": "Customer/For.",
				"armazemorigem": "Origin Warehouse",
				"armazemdestino": "Destination warehouse",
				"armazem": "Storage",
				"codartigo": "Article Code",
				"qtd": "Amount",
				"stock": "Stock:"
			}
		},
		"messages": {
			"transfarmsuccess": "Transfer/s of items between warehouses processed successfully.",
			"origemartigonulo": "The source Article cannot be null.",
			"destinoartigonulo": "The target Article cannot be null.",
			"origemarmazemnulo": "Source Warehouse cannot be null.",
			"destinoarmazemnulo": "The destination Warehouse cannot be null.",
			"qtdnula": "The quantity cannot be zero.",
			"qtdsup": "The value of the quantity entered cannot be greater than the value of available stock.",
			"qtdtotalsup": "The value of the quantity entered plus the quantity of lines already added from the article {{article}} is greater than the value of available stock.",
			"configGuardadasSucesso": "The warehouse transfer settings have been saved successfully."
		},
		"tooltip": {
			"addNewLine": "New transfer line"
		},
		"detail": {
			"table": {
				"fields": {
					"artigo": "Article",
					"qtd": "Amount",
					"armazemorigem": "Origin warehouse",
					"armazemdestino": "Destination warehouse"
				}
			},
			"cab": {
				"data": "Date",
				"documentos": "Documents"
			}
		},
		"list": {
			"fields": {
				"faccbIdOrigem": "ID",
				"nDocfaOrigem": "Source Doc Type",
				"nDocOrigem": "Doc No. Origin",
				"nNumerOrigem": "Origin Series No.",
				"nDocfaDestino": "Destination Doc Type",
				"nDocDestino": "Destination Doc. No.",
				"nNumerDestino": "Destination Series No.",
				"numeroOrigem": "Source Document",
				"numeroDestino": "Target Document",
				"dataDoc": "Doc date."
			}
		}
	},
	"trdoc": {
		"title": "Document transformation",
		"saveTitle": "Process",
		"fields": {
			"nDocfaOrigem": "Source Doc Type",
			"nDocfaDestino": "Destination Doc Type",
			"nNumerDestino": "Destiny Series",
			"dataDoc": "Date.",
			"dataDocOrigem": "Doc. Date is the Origin",
			"dataVenc": "Due Date",
			"dataVencCondVenda": "Expiration Date by Commercial Condition",
			"nFactFornec": "No.",
			"nFactFornecOrigem": "No.",
			"dataDocExterno": "External Doc Date",
			"dataDocExternoOrigem": "External Doc. Date is that of the Origin",
			"nRefProcesso": "Process",
			"nRefProcessoOrigem": "Process is that of the Origin",
			"nArmazem": "Storage",
			"nArmazemOrigem": "Warehouse is the Origin",
			"encerraDocumentoOrigem": "Closes Source document",
			"encerraDocumentoDestino": "Closes Target document"
		},
		"actions": {
			"cancel": "Cancel"
		},
		"modal": {
			"linhas": {
				"title": "Transformation of document lines",
				"steps": {
					"linhasDoc": "Lines",
					"lotes": "Lots",
					"ident": "Identification",
					"resumo": "Summary",
					"carga": "Charge",
					"descarga": "Discharge"
				},
				"info": {
					"transformado": "The document has already been transformed",
					"linhasTransformadas": "The document already has transformed lines"
				},
				"label": {
					"continuarTransformacao": "I intend to continue with the transformation",
					"naoContinuarTransformacao": "I don't intend to continue",
					"aposTransformarEncerrarDoc": "After transforming, close document(s)",
					"origem": "Origin",
					"destino": "Destiny"
				},
				"btn": {
					"editDoc": "Edit document",
					"preencherAuto": "Auto fill batches"
				},
				"messages": {
					"proceSuccess": "Processing completed successfully.",
					"atribuirLotes": "You must assign batches to lines with items with batches in order to simulate.",
					"naoTemLinhasSelecionadas": "There are no lines to process, select the lines to transform from the document.",
					"qtdLotesDifQtdLinha": "The quantity ({{qty}}) of the article {{nArtigo}} distributed among the lots is not the same as that of the line {{nSeq}} ({{qty1}}).",
					"naoTransformou": "No documents were generated.",
					"proceFalhou": "Error processing the transformation."
				}
			}
		},
		"legend": {
			"linhaTransformada": "Line already transformed"
		}
	},
	"trdocconfig": {
		"title": "Document transformation",
		"saveTitle": "Process",
		"fields": {
			"nDocfaOrigem": "Source Doc Type",
			"nDocfaDestino": "Destination Doc Type",
			"nNumerDestino": "Destiny Series",
			"dataDoc": "Date.",
			"dataDocOrigem": "Doc. Date is the Origin",
			"dataVenc": "Due Date",
			"dataVencCondVenda": "Expiration Date by Commercial Condition",
			"nFactFornec": "No.",
			"nFactFornecOrigem": "No.",
			"dataDocExterno": "External Doc Date",
			"dataDocExternoOrigem": "External Doc. Date is that of the Origin",
			"nRefProcesso": "Process",
			"nRefProcessoOrigem": "Process is that of the Origin",
			"nArmazem": "Storage",
			"nArmazemOrigem": "Warehouse is the Origin",
			"encerraDocumentoOrigem": "Closes Source document",
			"encerraDocumentoDestino": "Closes Target document",
			"referenciaTextoBase": "Reference text"
		},
		"actions": {
			"cancel": "Cancel",
			"variables": "Variables",
			"variaveis": {
				"nomeDoc": "@DocName - Document Name",
				"nDoc": "@NDoc - No.",
				"dtDoc": "@DtDoc - Document Date",
				"docExt": "@DocExt - External Document",
				"processo": "@Processo - Process",
				"contrato": "@Contract - Contract",
				"obs1": "@OBS1 - Observations"
			}
		},
		"messages": {
			"success": "Document transformed successfully.",
			"tipoDocNulo": "The document type cannot be null."
		},
		"tooltips": {
			"nDocExterno": "External Reference printed in the Document",
			"dataDocExterno": "External Date printed on the Document",
			"aposTransformarEncerrarDocsFinalizar": "These actions are irreversible."
		}
	},
	"trfLancContas": {
		"title": "Account transfer",
		"success": "Account transfer completed successfully",
		"fields": {
			"nContaOrigem": "Origin Account No.",
			"nContaDestino": "Destination Account No.",
			"nomeContaDestino": "Destination Account Name",
			"manterContaOrigem": "Maintain the originating account",
			"dependencias": "Dependencies"
		},
		"fieldsTableDependencias": {
			"nConta": "Origin Account No.",
			"nome": "Name Origin",
			"nContaGeralReal": "General Account No. Origin",
			"valorMascara": "Mask Value",
			"radicalMascara": "Mask"
		},
		"stepIntroCaption": "Introduction",
		"stepContasCaption": "Transfer",
		"stepIntroMessage": "This program allows you to convert a source account to another destination account.",
		"stepFinalizeCaption": "Conclusion",
		"stepFinalizeMessage": "Conversion completed.",
		"messages": {
			"contadestjaexiste": "Attention: the destination account already exists in the current chart of accounts."
		}
	},
	"trfba": {
		"pagreg": "Registered Payments",
		"documentos": "Documents",
		"tree": {
			"docporenviar": "Documents to be sent",
			"docenviados": "Documents sent",
			"operacao": {
				"op0": "Not defined",
				"op1": "Payments to suppliers",
				"op2": "Ordained",
				"op3": "Transfer",
				"op4": "House Income",
				"op5": "Meal Card/Ticket"
			}
		},
		"table": {
			"semdados": "No data",
			"sel": "Sel.",
			"nContaCaixa": "No.",
			"nContaForn": "Supply Account No.",
			"valor": "Value",
			"nomeBeneficiario": "Beneficiary Name",
			"nDocInternoLanc": "Internal Doc",
			"dataLanc": "Launch date",
			"nDocPago": "Paid Doc",
			"codBancoEMI": "Emi Bank Code.",
			"codagenciaEMI": "Emi Agency Code.",
			"ibanContaEMI": "IBAN Emi.",
			"swiftEmi": "SWIFT Emi.",
			"codBancoDest": "Dest Bank Code.",
			"codAgenciaDest": "Dest Agency Code.",
			"ibanContaDest": "IBAN Dest.",
			"swiftDest": "SWIFT Dest.",
			"nomeFicheiro": "File Name",
			"trfBaID": "Trf.",
			"chaveToPS2": "Key To PS2",
			"operacao": "Operation",
			"codEmpregado": "Employee",
			"nomeFuncionario": "Employee Name",
			"formatoTransferencia": "Transfer Format",
			"efectuada": "Performed"
		},
		"radio": {
			"ps2xml": "PS2/XML",
			"cgd": "CGD",
			"rgFormato": "Format of the file to be generated",
			"igcp": "IGCP",
			"geral": "General"
		},
		"btn": {
			"geraficheiro": "Generate File",
			"alterarestado": "Change Status to Unsent",
			"editarregisto": "Edit record",
			"eliminartransf": "Delete selected",
			"configSEPA": "SEPA Settings"
		},
		"modal": {
			"config": {
				"steps": {
					"outraidprivadas": "Other private IDs",
					"template": "SEPA Templates"
				},
				"fields": {
					"iban": "IBAN",
					"outraIDPrivada": "Other private ID",
					"regTempSal": "Salary"
				}
			},
			"geraficheiro": {
				"titleData": "Transfer date",
				"titlePrioridade": "Transfer date and priority",
				"titleDGT": "Data for the DGT upload file",
				"fields": {
					"nomeFicheiroUploadDGT": "File Name",
					"numeroSequencial": "Sequential number",
					"dataLanc": "Make transfer on date",
					"prioridade": "Priority",
					"normal": "Normal",
					"alta": "High"
				}
			},
			"erros": {
				"dataTrfbaNaoPodeSerInferiorDataHoje": "The transfer date cannot be less than today's date.",
				"erroGerarFicheiro": "Error generating bank transfer file."
			}
		},
		"messages": {
			"temselecionar": "There is no bank transfer selected.",
			"confaltestado": "Do you want to change the status of selected records?",
			"deltransf": "Do you want to delete the selected records?",
			"deltransfsuccess": "Successfully deleted records",
			"fichformPS2": "It will generate a file in PS2 format.",
			"fichformSEPAXML": "It will generate a file in SEPA XML format.",
			"fichformCGD": "It will generate a file in CGD (.txt) format.",
			"fichHomeBanking": "UPLOAD file for Home Banking was generated successfully.",
			"ibanjaconfig": "The IBAN is already configured.",
			"linhaaddsuccess": "Added successfully",
			"linharemsuccess": "IBAN successfully removed",
			"templatealterado": "Template changed successfully",
			"removeline": "Remove line",
			"ibannulo": "The IBAN cannot be null"
		}
	},
	"tributAutonomas": {
		"header": {
			"modeloTributAutonoma": "Taxation applied",
			"ano": "Year",
			"prejuizoFiscal": "Tax loss",
			"contasDe": "Account number"
		},
		"fields": {
			"nConta": "Account no.",
			"contaDescricao": "Account",
			"taxa": "Rate",
			"taxaAgravada": "Aggravated rate",
			"descricao": "Classifier",
			"nome": "Name",
			"saldoGeral": "Accumulated balance",
			"valorTributAutonoma": "Autonomous Taxation",
			"fromPerio": "Of period"
		},
		"modeloTributAutonoma": {
			"modelo0": "IRC",
			"modelo1": "IRC - Madeira Free Trade Zone",
			"modelo3": "IRC - Azores",
			"modelo2": "IRS"
		},
		"messages": {
			"desejaAtribuirOClassificadorAsCo": "Do you want to assign the classifier to the marked accounts?",
			"configuracoesDaTributacaoAutonom": "Autonomous Taxation settings copied to the selected companies.",
			"modeloChangedsuccess": "The Tax model was changed to \"{{modelTribut}}\" successfully.",
			"changedModeloTriubt": "Are you sure you want to change the Tax model applied to \"{{modelTribut}}\" to \"{{modelTributAfter}}\"?",
			"modeloTributAutonomaInfo": "To change the Taxation Regime, you must access the Maintenance of autonomous taxation"
		},
		"modals": {
			"empresas": {
				"title": "Copy for other companies"
			},
			"print": {
				"title": "Printing of autonomous taxes"
			}
		},
		"btn": {
			"copiaParaOutraEmpresas": "Copy for other companies",
			"manutTributAutonoma": "Maintenance of autonomous taxation"
		},
		"actions": {
			"poc": "Chart of accounts"
		}
	},
	"compilerOptions": {
		"module": "commonjs",
		"target": "es5",
		"sourceMap": "true"
	},
	"exclude": {
		"0": "node_modules"
	},
	"unidadesMedidaComercioInternacional": {
		"title_detail": "International trade unit of measure {{id}}",
		"title_plural": "Units of measurement for international trade",
		"pesquisa": "To look for",
		"fields": {
			"codigo": "Code",
			"designacao": "Designation",
			"designacaoEN": "EN designation",
			"simbolo": "Symbol",
			"tipoUnidadeCG": "Unit type"
		}
	},
	"unidadesmovimento": {
		"title_detail": "Motion Unit {{id}}",
		"title_new": "New Motion Unit",
		"title_plural": "Motion Units",
		"pesquisa": "To look for",
		"saved": "Motion Unit {{id}}, saved successfully.",
		"error": "Movement Unit {{id}}, cannot be saved.",
		"deleted": "Motion Unit {{id}}, successfully eliminated.",
		"fields": {
			"codUni": "Code",
			"codUniPlaceholder": "Motion unit code",
			"nome": "Name",
			"nomePlaceholder": "Motion unit name",
			"nomeAbrev": "Abbreviated Name",
			"nomeAbrevPlaceholder": "Short name of the movement unit",
			"tipo": "Type",
			"tipoPlaceholder": "Motion unit type",
			"qtdRefUnidad": "Qty per unit",
			"qtdRefUnidadPlaceholder": "Quantity per unit",
			"codUnece": "Code unites",
			"codUnecePlaceholder": "International trade measurement unit code"
		}
	},
	"users": {
		"title_detail": "User {{id}} (advanced)",
		"title_new": "New User (advanced)",
		"title_plural": "Users (advanced configuration)",
		"pesquisa": "To look for",
		"saved": "User \"{{id}}\" saved successfully",
		"error": "User \"{{id}}\" cannot be saved.",
		"deleted": "User deleted successfully",
		"passwordchanged": "Password changed successfully!",
		"instrucoes.email": "Instructions for changing the password have been sent to the user's email.",
		"instrucoes.emailerro": "Unable to send email to create user password",
		"acessosPortaisApi": "There are no selected accesses",
		"actions": {
			"changepassword": "Change password",
			"cancel": "Cancel",
			"change": "To alter",
			"adicionarEmpresas": "Add Companies",
			"adicionarEmpresa": "Add Company",
			"empresas": "Change companies",
			"sendEmail": "Send password recovery email"
		},
		"fields": {
			"userId": "ID",
			"username": "Authentication",
			"firstName": "Name",
			"lastName": "Surname",
			"password": "Password",
			"email": "Email",
			"active": "Active",
			"nEmpresa": "company no.",
			"nUtilizador": "User number",
			"admin": "Administrator",
			"apiv1": "User",
			"colaborador": "collaborator",
			"erp": "ERP User",
			"gestorRH": "HR Manager",
			"gestorServico": "Service Manager",
			"gestorEmentas": "Menu Manager",
			"regex": "API Filter",
			"administrator": "Administrator",
			"formUsername": "Authentication (field used for the user to log in to the platform)",
			"formEmail": "Email (field used for the user to log in to the platform)"
		},
		"password": {
			"title": "Change password",
			"newpassword": "Password:",
			"confirmpassword": "Confirm:",
			"errorEqualsPassword": "Passwords do not match"
		},
		"delete": {
			"title": "Do you want to delete the user {{id}}?"
		},
		"steps": {
			"utilizador": "User",
			"portaisApi": "Access to portals/api",
			"empresas": "Access to Companies"
		},
		"emailSuccess": "Email successfully sent"
	},
	"usersbasic": {
		"title_detail": "User {{id}}",
		"title_new": "New user",
		"title_plural": "Users",
		"pesquisa": "To look for",
		"saved": "User \"{{id}}\" saved successfully",
		"error": "User \"{{id}}\" cannot be saved.",
		"deleted": "User deleted successfully",
		"fields": {
			"instalacao": "Installation",
			"utilizador": "CentralGest User"
		},
		"text": {
			"deleteSameUserDisabled": "You cannot delete your own user",
			"createUtilizador": "Create CentralGest user and automatically associate",
			"acessos": "Accesses",
			"selectInstallation": "Please select an installation above to configure access to companies",
			"emptyCompanies": "There are no companies available for this installation.",
			"allRoles": "All portals",
			"filterCompanies": "Search companies",
			"nEmpresa": "Company No.",
			"nomeEmpresa": "Company Name",
			"selectAll": "All",
			"configuracaoEmpresa": "Company configuration",
			"configuracaoGeral": "General configuration",
			"acessosEmpresa": "Company access",
			"allPolicies": "All companies",
			"config": "Settings",
			"loadingEmpresas": "Loading installation companies...",
			"blockedAcessosEmpresa": "Please, in the table above, give access to at least one company to configure access for companies in this installation.",
			"loadPolicies": "To view and/or edit company access, please click here.",
			"loadingPolicies": "Loading company access...",
			"loadPoliciesError": "The following errors occurred when loading company accesses:",
			"portalInclude": "Companies with access to this portal will also have access to the portal",
			"portalIncludes": "Companies with access to this portal will also have access to the following portals"
		},
		"errors": {
			"requiredUtilizador": "It is mandatory to select a CentralGest User for association"
		},
		"accesses": {
			"generic": {
				"view": "To view",
				"add": "To create",
				"edit": "To edit",
				"delete": "Cancel"
			},
			"addons": {
				"title": "Addons"
			},
			"ativos": {
				"title": "Active"
			},
			"contabilidade": {
				"title": "Accounting"
			},
			"comercial": {
				"title": "Commercial management"
			}
		},
		"noRoles": {
			"title": "Confirm save user without access",
			"message": "<p>You have not selected any access for the user, <b>the user will not be able to enter the platform</b>.</p><p>Are you sure you want to continue?</p>"
		}
	},
	"utlviatpatronal": {
		"fields": {
			"codEmp": "Employee",
			"codABDESC": "Allowance",
			"anoProcessamento": "Data",
			"vAquisicaoViatura": "Acquisition value",
			"vMercadoViatura": "Market value",
			"rhTipoProce": "Processing"
		},
		"tipoUtlViatPatronal": {
			"todas": "All",
			"ativas": "Active",
			"inativas": "Inactive"
		},
		"toolbar": {
			"tipoUtlViatPatronal": "View Usage."
		},
		"modal": {
			"title": "Maintenance Use Employer’s vehicle",
			"fields": {
				"tipoProcessamento": "Type of processing",
				"codABDESC": "Allowance",
				"codEmp": "Employee Code",
				"anoProcessamento": "Year of processing",
				"mesInicioProce": "Start month",
				"suspende": "Suspended",
				"dataSuspensao": "Date",
				"valor": "Value",
				"vAquisicaoViatura": "Purchase value of the vehicle or reference value in the rental contract (VAT included)",
				"vMercadoViatura": "Vehicle market value",
				"valorIncidenciaSS": "Social Mon",
				"valorIncidenciaIRS": "IRS",
				"valincidencia": "Incidence Values",
				"simvalmensal": "Simulation of the Monthly Values ​​of the amount of income in kind for the use of a company vehicle",
				"obs": "Comments",
				"obsSupencao": "Observations Suspension",
				"simulacaodesc": "Simulation of monthly values ​​of the amount of income in kind for use of a company vehicle"
			}
		}
	},
	"datagrid": {
		"column": {
			"required": "This column is mandatory",
			"dupsNotAllowed": "No duplicates allowed",
			"digitsOnly": "Only numbers are allowed"
		}
	},
	"vendasanomes": {
		"chart": {
			"title": "Sales per month",
			"diference": "Difference"
		},
		"table": {
			"mes": "Month",
			"valorVariacao": "Variation value",
			"percentVariacao": "% change",
			"footer": {
				"total": "Total"
			}
		},
		"campos": {
			"checkboxAnoAnterior": "See full previous year"
		}
	},
	"vendasartigo": {
		"fields": {
			"codigo": "Code",
			"nome": "Name",
			"infoString": "NIF",
			"valor": "Value",
			"percentagem": "%"
		},
		"totalvendas": "Sales amount",
		"btn": {
			"ano": "Year",
			"mes": "Month",
			"todosmeses": "All"
		},
		"toolbar": {
			"month": "Month: {{nameMonth}}"
		}
	},
	"vendascliente": {
		"fields": {
			"codigo": "Code",
			"nome": "Name",
			"infoString": "NIF",
			"valor": "Amount",
			"valorIva": "VAT value",
			"valorLiquido": "Net value",
			"percentagem": "%"
		},
		"totalvendas": "Sales amount",
		"btn": {
			"ano": "Year",
			"mes": "Month",
			"todosmeses": "All"
		},
		"toolbar": {
			"month": "Month: {{nameMonth}}"
		}
	},
	"vendedores": {
		"title_detail": "Seller {{id}}",
		"title_new": "New Seller",
		"title_plural": "Sellers",
		"pesquisa": "To look for",
		"saved": "Seller {{id}}, saved successfully.",
		"error": "Seller {{id}}, cannot be saved.",
		"fields": {
			"nVendedor": "Code.",
			"nVendedorPlaceholder": "Seller code",
			"nome": "Name",
			"nomePlaceholder": "Seller's name"
		}
	},
	"verificaEFaturas": {
		"tabDocsEFaturaCaption": "Documents in e-Fatura not related to accounting",
		"tabJaLancadosCaption": "Documents already entered into accounting or ignored",
		"viewDocModalTitle": "Document view {{ndoc}}",
		"viewDocModalTitleSimple": "Document preview",
		"extratosModalTitle": "Account statements",
		"pesquisaDocPossivelModalTitle": "Accounting Document Search",
		"expandAllBtn": "Expand all",
		"associarTodosPossiveis": "Associate documents with 1 possible document",
		"associarTodosPossiveisPromptTitle": "Attention",
		"associarTodosPossiveisPromptMsg": "This operation will automatically associate the e-Fatura documents with a possible document.<br>You should always check document by document whether the associated possible document corresponds to the e-Fatura document.<br><br>Are you sure you want to continue?",
		"associarTodosPossiveisNoSourceTitle": "Automatic association...",
		"associarTodosPossiveisNoSourceMsg": "You have no documents or you have documents with more than 1 possible document.",
		"associarDocSemAlterarDocExterno": "Associate document without changing external document",
		"associarDocComAlteracaoDocExterno": "Associate document with external document change",
		"verDocumento": "View Document",
		"deixarIgnorarDocumento": "Stop ignoring this document",
		"deixarIgnorarPorFornecedor": "Stop ignoring documents from this supplier",
		"removeAssociacao": "Remove association from this document",
		"filtropesq": "Search",
		"messages": {
			"docAssociadoSuccessMsg": "The document {{ndoc}} has been linked.",
			"docsAssociadoSuccessMsg": "All documents with 1 possible document were successfully associated.",
			"docIgnoradoSuccessMsg": "The document {{ndoc}} has been added to the ignore list.",
			"fornecedorIgnoradoSuccessMsg": "Vendor {{nif}} - {{name}} has been added to the ignore list.",
			"docRemAssociacaoSuccessMsg": "The association of the document {{ndoc}} has been removed",
			"docRemIgnoringSuccessMsg": "The document {{ndoc}} has been removed from the ignore list.",
			"docRemIgnoringFornecedorSuccessMsg": "Vendor {{nif}} has been removed from the ignore list.",
			"docDigital1": "With doc.",
			"docDigital2": "With doc."
		},
		"fields": {
			"numPossiveisDocs": "No. of Possible Docs",
			"numPossiveisDocsAbbr": "Possible",
			"numSemelhantesDocs": "No. of Similar Docs",
			"numSemelhantesDocsAbbr": "Similar",
			"documentoLancado": "Document No.",
			"nifEmitente": "NIF",
			"nomeEmitente": "Name",
			"numerodocumento": "External Doc. No.",
			"dataEmissaoDocumento": "Issuance date",
			"tipoDocumentoDesc": "Type",
			"estadoBeneficioDesc": "Situation",
			"actividadeProfDesc": "Activity Scope",
			"valorTotalBaseTributavel": "Taxable Base",
			"valorTotalIva": "VAT value",
			"valorTotal": "Amount",
			"baseTributavel": "Taxable Base",
			"taxa": "Base Rate",
			"impostoSelo": "Stamp Tax",
			"total": "Amount",
			"nDocInterno": "Document No.",
			"nDocExterno": "External Doc. No.",
			"valor": "Value",
			"dataDocExterno": "External Doc Date",
			"docDigitalAbbr": "Digital Doc"
		},
		"btn": {
			"accao": "Actions",
			"dropdown": {
				"extratosConta": "Account statements",
				"ignorarSempreTodosDocumentos": "Always ignore all documents from this supplier",
				"ignorarDocumento": "Ignore this document"
			}
		},
		"tabJaLancadosCaptionContainer": {
			"table": {
				"badges": {
					"ligacaoDireta": "Direct link",
					"naoIgnorar": "Don't Ignore",
					"remover": "To remove"
				}
			},
			"legends": {
				"documentoIgnorado": "Ignored document",
				"associadoDocExtDiferente": "Associated with doc no.",
				"documentoFornecedorIgnorado": "Supplier document ignored"
			}
		},
		"tabDocsEFaturaCaptionContainer": {
			"mostradocpossiveis": "Show only no documents possible",
			"tooltip": {
				"associarAutTodosPossiveis": "Automatically associate all documents with 1 possible document."
			}
		},
		"formFields": {
			"dataDe": "Date of:",
			"dataAte": "Date Until:",
			"tipo": "Type",
			"fornecedor": "Supplier",
			"placeholder": {
				"contribuinte": "VAT Number"
			}
		},
		"modal": {
			"config": {
				"title": "E-invoice reconciliation setup",
				"fields": {
					"nCaracteresAValidar": "Number of characters to validate"
				}
			}
		}
	},
	"verificacaoacumulados": {
		"title": "Accumulation Check",
		"periodo": "Accumulated per period with movement.",
		"movimentos": "Accumulated with movement.",
		"extratos": "Accumulated with extracts",
		"inconsistencias": "All inconsistencies",
		"consistencias": "Current account consistency",
		"fields": {
			"deNconta": "From the account:",
			"ateNconta": "Until the count:",
			"periodo": "Period Until:"
		},
		"btn": {
			"verificar": "To check",
			"corrigir": "Fix selected"
		},
		"tooltip": {
			"corrigir": "To correct it, you must select at least one item from the list"
		},
		"alerts": {
			"reconstrucaoSucesso": "Account reconstruction was successful",
			"reconstrucaoErro": "An error occurred while creating the account",
			"correcaoContasCorrentesSucesso": "The selected accounts have been successfully fixed",
			"correcaoContasCorrentesErro": "An error occurred while correcting the selected accounts"
		},
		"tables": {
			"nConta": "Account number",
			"nome": "Name",
			"saldoAcumulado": "Accumulated balance",
			"saldoMovAberto": "Moving Balance",
			"tipo": "Type",
			"saldoAcumuladoAte": "Accumulated Balance Until Period",
			"saldoExtratoAte": "Statement Balance Until Period",
			"saldoExtrato": "Statement Balance",
			"fase": "Phase",
			"conta": "Account",
			"periodo": "Period",
			"diario": "Daily",
			"docInterno": "Internal Doc. No.",
			"sel": "Sel.",
			"origem": "Origin",
			"informacao": "Information"
		},
		"actions": {
			"reconstruir": "Rebuild"
		}
	},
	"zonas": {
		"title_detail": "Zone {{id}}",
		"title_new": "New Zone",
		"title_plural": "Zones",
		"pesquisa": "To look for",
		"saved": "Zone {{id}}, saved successfully.",
		"error": "Zone {{id}}, cannot be saved.",
		"deleted": "Zone {{id}}, successfully deleted",
		"fields": {
			"nZona": "Code.",
			"nZonaPlaceholder": "Zone code",
			"nome": "Name",
			"nomePlaceholder": "Zone name"
		}
	}
}