import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {EDGEMPSEstado} from '../../../dgemps/dgemps.module.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {ENTITY_NAME_DGEMPS, IDGEMPSEntityService} from '../../../../../entities/dgemps/dgemps.entity.interface';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IJsonDGEMPRefeicoesPorAprovar} from '../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHRefAprova} from '../../../../../entities/prhrefaprova/jsonPRHRefAprova.entity.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import moment from 'moment';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {ENTITY_NAME_PRH_REF_APROVA} from '../../../../../entities/prhrefaprova/pRHRefAprova.entity.interface';

@Component({
  selector: 'colaboradores-aprovacoes-refeicoes',
  templateUrl: './aprovacoesRefeicoes.module.component.html'
})
export class AprovacoesRefeicoesComponent extends ModuloComponent implements OnInit {
  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonDGEMPRefeicoesPorAprovar>;

  private readonly _serviceDGEMPS: IDGEMPSEntityService;
  private readonly _servicePRHRefAprova: IEntityService<IJsonPRHRefAprova>;

  private _dataGridInstance: dxDataGrid<IJsonDGEMPRefeicoesPorAprovar>;
  private _codEmp: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._serviceDGEMPS = this._entityServiceBuilder.build(ENTITY_NAME_DGEMPS);
    this._servicePRHRefAprova = this._entityServiceBuilder.build(ENTITY_NAME_PRH_REF_APROVA);
    this.dataGridDefinition = {
      columns: [
        {dataField: 'codEmp', dataType: 'number', caption: 'dgemps.fields.codEmp', width: 50, sortOrder: 'asc'},
        {dataField: 'nomeEmp', dataType: 'string', caption: 'dgemps.fields.nome'},
        {dataField: 'operacao', dataType: 'string', caption: 'Operação', width: 100},
        {dataField: 'dataRefeicao', dataType: 'date', caption: 'Data'},
        {dataField: 'nomeLocal', dataType: 'string', caption: 'Local'},
        {dataField: 'nomeTipoRefeicao', dataType: 'string', caption: 'Tipo de Refeição', width: 100},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn'}
      ],
      dataSource: new CustomStore({
        load: () => this._source()
      }),
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._codEmp = this.session.erp.codEmp;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonDGEMPRefeicoesPorAprovar>): void {
    this._dataGridInstance = component;
  }

  public readonly fnAprovaRefeicao = (item: IJsonDGEMPRefeicoesPorAprovar) => (): Promise<void> => this._aprovaRefeicao(item);

  public readonly fnRejeitaRefeicao = (item: IJsonDGEMPRefeicoesPorAprovar) => (): Promise<void> => this._rejeitaRefeicao(item);

  private _source(): Promise<Array<IJsonDGEMPRefeicoesPorAprovar>> {
    const codPapelDiretor = 1; // Director
    return this._serviceDGEMPS.getListaRefeicoesPorAprovar(this._codEmp, codPapelDiretor).then((response: HttpResponse<Array<IJsonDGEMPRefeicoesPorAprovar>>) => response.body);
  }

  private _aprovaRefeicao(item: IJsonDGEMPRefeicoesPorAprovar): Promise<void> {
    return this._updatePRHRefAprova(item, EDGEMPSEstado.Aprovado); // Não houve tempo para mais
  }

  private _rejeitaRefeicao(item: IJsonDGEMPRefeicoesPorAprovar): Promise<void> {
    return this._updatePRHRefAprova(item, EDGEMPSEstado.Rejeitado); // Não houve tempo para mais
  }

  private _updatePRHRefAprova(item: IJsonDGEMPRefeicoesPorAprovar, estado: EDGEMPSEstado): Promise<void> {
    const updatedPRHRefAprova: IJsonPRHRefAprova = {
      codPRHRefAprova: item.codPRHRefAprova,
      codPRHRelEmentasOld: item.codPRHRelEmentasOld,
      codPRHRelEmentas: item.codPRHRelEmentas,
      estado: estado,
      codEmpAprova: this._codEmp,
      codEmp: item.codEmp,
      dataAprova: moment()
    };
    return this._servicePRHRefAprova.put({id: item.codPRHRefAprova, body: updatedPRHRefAprova}).then(() => {
      this._plAlertService.success(estado === EDGEMPSEstado.Aprovado ? 'gestaodgemps.text.success.aprovado' : 'gestaodgemps.text.success.rejeitado');
      return this._dataGridInstance.refresh();
    });
  }
}
