<div class="rh-estatisticas">
  <cg-card-panel #cardPanel="cgCardPanel" class="d-block mb-4" [caption]="'global.text.searchfilter'">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" formInstanceName="formSearch" (formInstanceChange)="formSearch = $event">
        <div class="row">
          <div class="col-md-6">
            <pl-group>
              <pl-group>
                <label [translate]="model.activeItem === navItems.ANALISE_ANUAL ? 'rhEstatisticas.filters.anoProce' : 'rhEstatisticas.filters.mesProce'"></label>
                <edit>
                  <pl-edit-datepicker [kind]="anoMesDeKind" [model]="modelFiltersUI.anoMesDe" (modelChange)="anoMesDeChange($event)"></pl-edit-datepicker>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'rhEstatisticas.filters.ate'"></label>
                <edit>
                  <pl-edit-datepicker
                    [kind]="datePickerKind.Month"
                    [model]="modelFiltersUI.anoMesAte"
                    [minimumDate]="modelFiltersUI.anoMesDe"
                    (modelChange)="modelFiltersUI.anoMesAte = $event; checkExclusionButton(filterExclusionField.YEAR_MONTH)"
                    [properties]="{disabled: isFilterMesAteDisabled()}"></pl-edit-datepicker>
                </edit>
                <actions>
                  <pl-button
                    [disabled]="isFilterExclusionButtonDisabled(filterExclusionField.YEAR_MONTH)"
                    klass="btn btn-sm btn-outline-danger"
                    [onClick]="fnShowProceExclusionModal(filterExclusionField.YEAR_MONTH)">
                    <i class="fa fa-fw fa-minus-circle"></i>
                    <span
                      *ngIf="modelFiltersUI.exclusaoAnoMesUI.length > 0"
                      class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary"
                      [translate]="modelFiltersUI.exclusaoAnoMesUI.length.toString()"></span>
                  </pl-button>
                </actions>
              </pl-group>
            </pl-group>
            <pl-group>
              <pl-group>
                <label [translate]="'rhEstatisticas.filters.codEmpregado'"></label>
                <edit>
                  <entity-autocomplete
                    entity="dgemps"
                    [output]="'key'"
                    [selectedKey]="modelFiltersUI.codEmpDe"
                    (selectedKeyChange)="modelFiltersUI.codEmpDe = $event; checkExclusionButton(filterExclusionField.CODEMP)"
                    [helperMode]="true"
                    [properties]="{allowInvalid: true, disabled: isFilterCodEmpDeDisabled()}"></entity-autocomplete>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'rhEstatisticas.filters.ate'"></label>
                <edit>
                  <entity-autocomplete
                    entity="dgemps"
                    [output]="'key'"
                    [selectedKey]="modelFiltersUI.codEmpAte"
                    (selectedKeyChange)="modelFiltersUI.codEmpAte = $event; checkExclusionButton(filterExclusionField.CODEMP)"
                    [helperMode]="true"
                    [properties]="{allowInvalid: true, disabled: isFilterCodEmpAteDisabled()}"></entity-autocomplete>
                </edit>
                <actions>
                  <pl-button
                    [disabled]="isFilterExclusionButtonDisabled(filterExclusionField.CODEMP)"
                    klass="btn btn-sm btn-outline-danger"
                    [onClick]="fnShowProceExclusionModal(filterExclusionField.CODEMP)">
                    <i class="fa fa-fw fa-minus-circle"></i>
                    <span
                      *ngIf="modelFiltersUI.exclusaoCodEmpUI.length > 0"
                      class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary"
                      [translate]="modelFiltersUI.exclusaoCodEmpUI.length.toString()"></span>
                  </pl-button>
                </actions>
              </pl-group>
            </pl-group>
          </div>
          <div class="col-md-6">
            <pl-group>
              <pl-group>
                <label [translate]="'rhEstatisticas.filters.depto'"></label>
                <edit>
                  <entity-autocomplete
                    entity="depto"
                    [output]="'key'"
                    [selectedKey]="modelFiltersUI.deptoDe"
                    (selectedKeyChange)="modelFiltersUI.deptoDe = $event; checkExclusionButton(filterExclusionField.DEPTO)"
                    [helperMode]="true"
                    [properties]="{allowInvalid: true, disabled: isFilterDeptoDeDisabled()}"></entity-autocomplete>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'rhEstatisticas.filters.ate'"></label>
                <edit>
                  <entity-autocomplete
                    entity="depto"
                    [output]="'key'"
                    [selectedKey]="modelFiltersUI.deptoAte"
                    (selectedKeyChange)="modelFiltersUI.deptoAte = $event; checkExclusionButton(filterExclusionField.DEPTO)"
                    [helperMode]="true"
                    [properties]="{allowInvalid: true, disabled: isFilterDeptoAteDisabled()}"></entity-autocomplete>
                </edit>
                <actions>
                  <pl-button
                    [disabled]="isFilterExclusionButtonDisabled(filterExclusionField.DEPTO)"
                    klass="btn btn-sm btn-outline-danger"
                    [onClick]="fnShowProceExclusionModal(filterExclusionField.DEPTO)">
                    <i class="fa fa-fw fa-minus-circle"></i>
                    <span
                      *ngIf="modelFiltersUI.exclusaoDeptoUI.length > 0"
                      class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary"
                      [translate]="modelFiltersUI.exclusaoDeptoUI.length.toString()"></span>
                  </pl-button>
                </actions>
              </pl-group>
            </pl-group>
            <pl-group>
              <pl-group>
                <label [translate]="'rhEstatisticas.filters.ccusto'"></label>
                <edit>
                  <entity-autocomplete
                    entity="ccustos"
                    [output]="'key'"
                    [selectedKey]="modelFiltersUI.ccustoDe"
                    (selectedKeyChange)="modelFiltersUI.ccustoDe = $event; checkExclusionButton(filterExclusionField.CCUSTO)"
                    [helperMode]="true"
                    [properties]="{allowInvalid: true, disabled: isFilterCCustoDeDisabled()}"></entity-autocomplete>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'rhEstatisticas.filters.ate'"></label>
                <edit>
                  <entity-autocomplete
                    entity="ccustos"
                    [output]="'key'"
                    [selectedKey]="modelFiltersUI.ccustoAte"
                    (selectedKeyChange)="modelFiltersUI.ccustoAte = $event; checkExclusionButton(filterExclusionField.CCUSTO)"
                    [helperMode]="true"
                    [properties]="{allowInvalid: true, disabled: isFilterCCustoAteDisabled()}"></entity-autocomplete>
                </edit>
                <actions>
                  <pl-button
                    [disabled]="isFilterExclusionButtonDisabled(filterExclusionField.CCUSTO)"
                    klass="btn btn-sm btn-outline-danger"
                    [onClick]="fnShowProceExclusionModal(filterExclusionField.CCUSTO)">
                    <i class="fa fa-fw fa-minus-circle"></i>
                    <span
                      *ngIf="modelFiltersUI.exclusaoCCustoUI.length > 0"
                      class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary"
                      [translate]="modelFiltersUI.exclusaoCCustoUI.length.toString()"></span>
                  </pl-button>
                </actions>
              </pl-group>
            </pl-group>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <pl-button type="button" [attrName]="'searchButton'" klass="btn btn-sm btn-primary" [onClick]="fnSearch">
              <i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span>
            </pl-button>
          </div>
        </div>
      </pl-form>
    </div>
  </cg-card-panel>

  <div class="row">
    <div class="col-md-12">
      <div class="d-flex" style="gap: 15px">
        <div style="flex: 0 0 200px">
          <ul class="rh-estatisticas-nav">
            <li [ngClass]="{active: model.activeItem === navItems.ESTATISTICAS}">
              <a (click)="setActiveItem(navItems.ESTATISTICAS)" href="javascript:void(0)" [translate]="model.estatisticas.caption"></a>
            </li>
            <li [ngClass]="{active: model.activeItem === navItems.EXTRATO_REMUNERACOES}">
              <a (click)="setActiveItem(navItems.EXTRATO_REMUNERACOES)" href="javascript:void(0)" [translate]="model.extratoRemuneracoes.caption"></a>
            </li>
            <li [ngClass]="{active: model.activeItem === navItems.ANALISE_ANUAL}">
              <a (click)="setActiveItem(navItems.ANALISE_ANUAL)" href="javascript:void(0)" [translate]="model.analiseAnual.caption"></a>
            </li>
            <li [ngClass]="{active: model.activeItem === navItems.ANALISE_SEGSOCIAL}">
              <a (click)="setActiveItem(navItems.ANALISE_SEGSOCIAL)" href="javascript:void(0)" [translate]="model.analiseSegSocial.caption"></a>
            </li>
            <li [ngClass]="{active: model.activeItem === navItems.ANALISE_AT}">
              <a (click)="setActiveItem(navItems.ANALISE_AT)" href="javascript:void(0)" [translate]="model.analiseAT.caption"></a>
            </li>
            <li [ngClass]="{active: model.activeItem === navItems.ANALISE_SINDICATO}">
              <a (click)="setActiveItem(navItems.ANALISE_SINDICATO)" href="javascript:void(0)" [translate]="model.analiseSindicato.caption"></a>
            </li>
            <!--            <li [ngClass]="{active: model.activeItem === navItems.ANALISE_CGA}">-->
            <!--              <a (click)="setActiveItem(navItems.ANALISE_CGA)" href="javascript:void(0)" [translate]="model.analiseCGA.caption"></a>-->
            <!--            </li>-->
            <li [ngClass]="{active: model.activeItem === navItems.ANALISE_SEGURO}">
              <a (click)="setActiveItem(navItems.ANALISE_SEGURO)" href="javascript:void(0)" [translate]="model.analiseSeguro.caption"></a>
            </li>
            <!--            <li [ngClass]="{active: model.activeItem === navItems.LISTAGENS_RU}">-->
            <!--              <a (click)="setActiveItem(navItems.LISTAGENS_RU)" href="javascript:void(0)" [translate]="model.listagensRU.caption"></a>-->
            <!--            </li>-->
          </ul>
        </div>
        <div style="flex: 1; min-width: 0">
          <h4 class="rh-estatisticas-tab-title mb-3" [translate]="getTabTitle()"></h4>

          <ng-container *ngIf="model.activeItem === navItems.ESTATISTICAS">
            <pl-tabs [(activeId)]="model.estatisticas.activeTab">
              <pl-tab caption="rhEstatisticas.string.vistaResumo" [id]="estatisticasTabs.RESUMO">
                <div *plTabContent>
                  <dx-data-grid
                    [cgDxDataGrid]="model.estatisticas.resumoDataGridDefinition"
                    [dataSource]="model.estatisticas.resumoDataGridDefinition.dataSource"
                    (onInitialized)="onResumoDataGridInitialized($event)"
                    cgDxDataGridInstanceName="rhEstatisticasFolhaSalarioResumoDataGrid">
                  </dx-data-grid>
                </div>
              </pl-tab>
              <pl-tab caption="rhEstatisticas.string.vistaDetalhadaPorAbonoDesconto" [id]="estatisticasTabs.DETALHADA_AD">
                <div *plTabContent>
                  <dx-data-grid
                    [cgDxDataGrid]="model.estatisticas.resumoDetailDataGridDefinition"
                    [dataSource]="model.estatisticas.resumoDetailDataGridDefinition.dataSource"
                    (onInitialized)="onResumoDetailDataGridInitialized($event)"
                    cgDxDataGridInstanceName="rhEstatisticasFolhaSalarioResumoDetailDataGrid">
                  </dx-data-grid>
                </div>
              </pl-tab>
            </pl-tabs>
            <div class="rh-legends">
              <div><span class="rh-col-abonos"></span><span [translate]="'rhEstatisticas.fields.abonos'"></span></div>
              <div><span class="rh-col-descontos"></span><span [translate]="'rhEstatisticas.fields.descontos'"></span></div>
              <div><span class="rh-col-impostos-colaborador"></span><span [translate]="'rhEstatisticas.fields.impostos'"></span></div>
              <div><span class="rh-col-custos-empresa"></span><span [translate]="'rhEstatisticas.fields.custoEmpresa'"></span></div>
            </div>
          </ng-container>

          <ng-container *ngIf="model.activeItem === navItems.EXTRATO_REMUNERACOES">
            <dx-data-grid
              [cgDxDataGrid]="model.extratoRemuneracoes.dataGridDefinition"
              [dataSource]="model.extratoRemuneracoes.dataGridDefinition.dataSource"
              (onInitialized)="onAnaliseMensalDataGridInitialized($event)"
              (onCellPrepared)="onAnaliseMensalCellPrepared($event)">
            </dx-data-grid>
            <div class="rh-legends">
              <div><span class="rh-col-abonos"></span><span [translate]="'rhEstatisticas.fields.abonos'"></span></div>
              <div><span class="rh-col-descontos"></span><span [translate]="'rhEstatisticas.fields.descontos'"></span></div>
              <div><span class="rh-col-impostos-colaborador"></span><span [translate]="'rhEstatisticas.fields.impostos'"></span></div>
              <div><span class="rh-col-custos-empresa"></span><span [translate]="'rhEstatisticas.fields.custoEmpresa'"></span></div>
            </div>
          </ng-container>

          <ng-container *ngIf="model.activeItem === navItems.ANALISE_SEGSOCIAL">
            <dx-data-grid
              [cgDxDataGrid]="model.analiseSegSocial.dataGridDefinition"
              [dataSource]="model.analiseSegSocial.dataGridDefinition.dataSource"
              (onContentReady)="onContentReadyAnaliseSegSocial()"
              (onInitialized)="onAnaliseSegSocialDataGridInitialized($event)">
            </dx-data-grid>
          </ng-container>

          <ng-container *ngIf="model.activeItem === navItems.ANALISE_SINDICATO">
            <dx-data-grid
              [cgDxDataGrid]="model.analiseSindicato.dataGridDefinition"
              [dataSource]="model.analiseSindicato.dataGridDefinition.dataSource"
              (onInitialized)="onAnaliseSindicatoDataGridInitialized($event)">
            </dx-data-grid>
          </ng-container>

          <ng-container *ngIf="model.activeItem === navItems.ANALISE_SEGURO">
            <dx-data-grid
              [cgDxDataGrid]="model.analiseSeguro.dataGridDefinition"
              [dataSource]="model.analiseSeguro.dataGridDefinition.dataSource"
              (onInitialized)="onAnaliseSeguroDataGridInitialized($event)">
            </dx-data-grid>
          </ng-container>

          <ng-container *ngIf="model.activeItem === navItems.ANALISE_AT">
            <dx-data-grid [cgDxDataGrid]="model.analiseAT.dataGridDefinition" [dataSource]="model.analiseAT.dataGridDefinition.dataSource" (onInitialized)="onAnaliseATDataGridInitialized($event)">
            </dx-data-grid>
          </ng-container>

          <ng-container *ngIf="model.activeItem === navItems.ANALISE_ANUAL">
            <dx-data-grid
              [cgDxDataGrid]="model.analiseAnual.dataGridDefinition"
              [dataSource]="model.analiseAnual.dataGridDefinition.dataSource"
              (onCellPrepared)="onAnaliseMensalCellPrepared($event)"
              (onInitialized)="onAnaliseAnualDataGridInitialized($event)">
            </dx-data-grid>
            <div class="rh-legends">
              <div><span class="rh-col-abonos"></span><span [translate]="'rhEstatisticas.fields.abonos'"></span></div>
              <div><span class="rh-col-descontos"></span><span [translate]="'rhEstatisticas.fields.descontos'"></span></div>
              <div><span class="rh-col-impostos-colaborador"></span><span [translate]="'rhEstatisticas.fields.impostos'"></span></div>
              <div><span class="rh-col-custos-empresa"></span><span [translate]="'rhEstatisticas.fields.custoEmpresa'"></span></div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
