<div class="ativosimport-edit">
  <entity-detail
    entityName="ativosimport"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-nav-wizard [definition]="definition" [onFinalize]="finalize" (evtStepChange)="stepChanged($event)" [plPromise]="promise">
        <pl-nav-wizard-step caption="ativosimport.steps.aquisicaoAtivo" icon="fa-file-text-o">
          <pl-form *plNavWizardStepContent [properties]="{readonly: type === stateTypes.DETAIL || !!model.importado, validators: {required: {value: true}}}">
            <pl-group>
              <pl-group>
                <label [translate]="'ativosimport.form.designacao'"></label>
                <edit>
                  <pl-edit type="text" [(model)]="model.designacao" [properties]="{validators: {maxlength: {value: 150}, required: {value: true}}}" plAutoFocus></pl-edit>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'ativosimport.form.refExterna'"></label>
                <edit>
                  <pl-edit type="text" [(model)]="model.refExterna" [properties]="{validators: {required: {value: false}}}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosimport.form.dataAquisicao'"></label>
                <edit>
                  <pl-edit type="date" [(model)]="model.dataAquisicao" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosimport.form.dataInicioUtiliza'"></label>
                <edit>
                  <pl-edit type="date" [(model)]="model.dataInicioUtiliza" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosimport.form.valorAquisicao'"></label>
                <edit>
                  <pl-edit
                    type="cgnumber"
                    attrName="valorAquisicao"
                    [model]="model.valorAquisicao"
                    (modelChange)="model.valorAquisicao = $event; model.valorAquisicaoFiscal = $event"
                    [properties]="{validators: {required: {value: true}}}">
                  </pl-edit>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'ativosimport.form.valorAquisicaoFiscal'"></label>
                <edit>
                  <pl-edit type="number" [(model)]="model.valorAquisicaoFiscal" [properties]="{validators: {required: {value: false}}}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosimport.form.codAmortizacao'"></label>
                <edit>
                  <entity-autocomplete
                    entity="amortizacao"
                    attrName="amortizacao"
                    [model]="model"
                    [selectedKey]="model.codAmortizacao"
                    (selectedKeyChange)="model.codAmortizacao = $event; calculaTaxasCodAmortizacao($event)"
                    (evtSelectedDescriptionChanged)="model.nomeCodAmortizacao = $event"
                    [properties]="{validators: {required: {value: true}}, modelOptions: {updateOn: 'blur'}}"
                    [fieldsMap]="{nImtab: 'codAmortizacao', nome: 'nomeCodAmortizacao'}"
                    [output]="amortizacaoOutput">
                  </entity-autocomplete>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'ativosimport.form.valorResidual'"></label>
                <edit>
                  <pl-edit type="number" [(model)]="model.valorResidual" [properties]="{validators: {required: {value: false}}}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosimport.form.nMapaAmortizacao'"></label>
                <edit>
                  <entity-autocomplete
                    entity="mapasamortizacao"
                    attrName="mapasAmortizacao"
                    [model]="model"
                    [properties]="{validators: {required: {value: true}}, disabled: type === stateTypes.DETAIL || model.importado === true}"
                    [(selectedKey)]="model.nMapaAmortizacao"
                    (evtSelectedDescriptionChanged)="model.nomeMapaAmortizacao = $event"
                    [fieldsMap]="{nImmap: 'nMapaAmortizacao', nome: 'nomeMapaAmortizacao'}">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosimport.form.quantidade'"></label>
                <edit>
                  <pl-edit type="number" attrName="quantidade" [(model)]="model.quantidade" [properties]="{readonly: type === stateTypes.DETAIL, validators: {required: {value: false}}}"> </pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'ativosimport.form.usado'"></label>
                <edit>
                  <pl-edit type="boolean" [properties]="{readonly: type === stateTypes.DETAIL || model.importado === true, validators: {required: {value: false}}}" [(model)]="model.usado"> </pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosimport.form.taxaAmortizacao'"></label>
                <edit>
                  <pl-edit type="number" [model]="model.taxaAmortizacao" (modelChange)="model.taxaAmortizacao = $event" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'ativosimport.form.percReducao'"></label>
                <edit>
                  <pl-edit type="number" [(model)]="model.percReducao" [properties]="{validators: {required: {value: false}}}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosimport.form.fiscalTaxaAmortiza'"></label>
                <edit>
                  <pl-edit type="number" [model]="model.fiscalTaxaAmortiza" (modelChange)="model.fiscalTaxaAmortiza = $event" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'ativosimport.form.fiscalPercReducao'"></label>
                <edit>
                  <pl-edit type="number" [(model)]="model.fiscalPercReducao" [properties]="{validators: {required: {value: false}}}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>
          </pl-form>
        </pl-nav-wizard-step>

        <pl-nav-wizard-step caption="ativosimport.steps.contas" icon="fa-file-text-o">
          <pl-form *plNavWizardStepContent [properties]="{readonly: type === stateTypes.DETAIL || !!model.importado, validators: {required: {value: true}}}">
            <pl-group>
              <label [translate]="'ativosimport.form.nContaDoAtivo'"></label>
              <edit>
                <entity-autocomplete
                  entity="pocs"
                  attrName="nContaDoAtivo"
                  [model]="model"
                  [(selectedKey)]="model.nContaDoAtivo"
                  (evtSelectedDescriptionChanged)="model.nomeContaAtivo = $event"
                  [properties]="{validators: {maxlength: {value: 75}, required: {value: true}}}"
                  [fieldsMap]="{nConta: 'nContaDoAtivo', nome: 'nomeContaAtivo'}"
                  [filter]="filterContaDoAtivo"
                  [output]="pocsOutput">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'ativosimport.form.nContaFornecedor'"></label>
              <edit>
                <entity-autocomplete
                  entity="pocs"
                  attrName="nContaFornecedor"
                  [model]="model"
                  [properties]="{validators: {maxlength: {value: 75}, required: {value: true}}}"
                  [(selectedKey)]="model.nContaFornecedor"
                  (evtSelectedDescriptionChanged)="model.nomeContaFornecedor = $event"
                  [fieldsMap]="{nConta: 'nContaFornecedor', nome: 'nomeContaFornecedor'}"
                  [filter]="filterMovimento"
                  [output]="pocsOutput">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-form>
        </pl-nav-wizard-step>

        <pl-nav-wizard-step caption="ativosimport.steps.depreciacoes" icon="fa-file-text-o" [properties]="{hideFinalize: type === stateTypes.DETAIL}">
          <pl-form *plNavWizardStepContent [properties]="{readonly: type === stateTypes.DETAIL || !!model.importado, validators: {required: {value: true}}}">
            <pl-group>
              <pl-group>
                <label [translate]="'ativosimport.form.baseCalculo'"></label>
                <edit>
                  <pl-edit type="radiogroup" attrName="baseCalculo" [(model)]="model.baseCalculo" [properties]="{groupItems: baseCaclAtivosSource}"> </pl-edit>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'ativosimport.form.metodoCalculo'"></label>
                <edit>
                  <pl-edit type="radiogroup" attrName="metodoCalculo" [(model)]="model.metodoCalculo" [properties]="{groupItems: metodoCaclAtivosSource}"> </pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosimport.form.estado'"></label>
                <edit>
                  <pl-edit type="radiogroup" attrName="estado" [(model)]="model.estado" [properties]="{groupItems: estadoAtivosSource}"> </pl-edit>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'ativosimport.form.dataUltProcessamento'"></label>
                <edit>
                  <pl-edit type="date" [(model)]="model.dataUltProcessamento" [properties]="{validators: {required: {value: true}}, disabled: model.estado === 1 || model.estado === 2}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosimport.form.depreciacaoAcumulada'"></label>
                <edit>
                  <pl-edit type="number" attrName="depreciacaoAcumulada" [(model)]="model.depreciacaoAcumulada" [properties]="{validators: {required: {value: false}}}" plAutoFocus> </pl-edit>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'ativosimport.form.fiscalDepAcumulada'"></label>
                <edit>
                  <pl-edit type="number" attrName="fiscalDepAcumulada" [(model)]="model.fiscalDepAcumulada" [properties]="{validators: {required: {value: false}}}"> </pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'ativosimport.form.dataAbate'"></label>
                <edit>
                  <pl-edit type="date" [(model)]="model.dataAbate" [properties]="{validators: {required: {value: false}}, disabled: model.estado === enumAtivosEstado.Normal}"> </pl-edit>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'ativosimport.form.valorVenda'"></label>
                <edit>
                  <pl-edit type="number" attrName="valorVenda" [properties]="{validators: {required: {value: false}}, disabled: model.estado === 0 || model.estado === 2}" [(model)]="model.valorVenda">
                  </pl-edit>
                </edit>
              </pl-group>
            </pl-group>
          </pl-form>
        </pl-nav-wizard-step>
      </pl-nav-wizard>
    </div>
  </entity-detail>
</div>
