import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({
  name: 'cgcBindHtml'
})
export class CGCBindHtmlPipe implements PipeTransform {
  constructor(
    private readonly _domSanitizer: DomSanitizer
  ) {
  }

  public transform(value: string = ''): SafeHtml {
    return this._domSanitizer.bypassSecurityTrustHtml(value);
  }
}
