import {firstValueFrom} from 'rxjs';
import {take} from 'rxjs/operators';
import {LazyLoadResult, StateRegistry, TargetState, TargetStateDef, Transition, UIRouter, UrlService} from '@uirouter/core';
import {Ng2StateDeclaration, RootModule} from '@uirouter/angular';
import {copy} from 'pl-comps-angular';
import {APP_API_KEY} from '../common/app';
import {AppService} from '../core/services/app/app.service';
import {configUIRouterStateAdmin} from '../core/states/admin/admin.portal';
import {EAppLaunchMode, ISiteStateParams} from '../common/site';
import {FUTURE_STATE_NAME_PORTAL, STATE_NAME_PORTAL} from '../core/services/portals/portals.service.interface';
import {IAppStatus} from '../core/services/app/app.service.interface';
import {PortalsService} from '../core/services/portals/portals.service';
import {STATE_NAME_LOAD_MODULE} from '../core/states/loadmodule/loadmodule.state.interface';
import {STATE_SITE, UI_ROUTER_URL_TYPE_BOOLEAN, UI_ROUTER_URL_TYPE_CG_DATE, UI_ROUTER_URL_TYPE_GUID} from './uirouter/uirouter.configs';
import {UI_ROUTER_URL_TYPE_NAME_BOOLEAN, UI_ROUTER_URL_TYPE_NAME_CG_DATE, UI_ROUTER_URL_TYPE_NAME_GUID} from './uirouter/uirouter.configs.interface';

import {STATE_ACCOUNT} from '../core/states/account/account.state';
import {STATE_CHANGE_PASSWORD} from '../core/states/account/changepassword/changepassword';
import {STATE_COMPANY_STATUS} from '../core/states/account/companystatus/companystatus';
import {STATE_DISCONNECTED} from '../core/states/account/disconnected/disconnected.state';
import {STATE_EMPRESA_BLOQUEADA} from '../core/states/account/empresabloqueada/empresabloqueada.state';
import {STATE_LOAD_MODULE} from '../core/states/loadmodule/loadmodule.state';
import {STATE_LOCKED_CONTRACT} from '../core/states/account/lockedcontract/lockedcontract.state';
import {STATE_LOGIN} from '../core/states/account/login/login.state';
import {STATE_MAINTENANCE} from '../core/states/account/maintenance/maintenance.state';
import {STATE_NAME_LOGIN} from '../core/states/account/login/login.state.interface';
import {STATE_NO_AUTHORITY} from '../core/states/account/noauthority/noauthority.state';
import {STATE_OAUTH2_DIGITAL_SIGN} from '../core/states/oauth2/digitalsign/oauth2.digitalsign.state';
import {STATE_OAUTH2_SAFE} from '../core/states/oauth2/safe/oauth2.safe.state';
import {STATE_OAUTH2} from '../core/states/oauth2/oauth2.state';
import {STATE_PARTNER_MILLENNIUM} from '../core/states/partners/millennium/millennium.state';
import {STATE_PARTNER} from '../core/states/partners/partners.state';
import {STATE_RESET_PASSWORD} from '../core/states/account/resetpassword/resetpassword.state';
import {STATE_ENTIDADE_BANCARIA} from '../core/states/oauth2/entidadebancaria/oauth2.entidadebancaria.state';

export const uiRouterStates: Array<Ng2StateDeclaration> = [
  STATE_SITE,
  STATE_ACCOUNT,
  STATE_CHANGE_PASSWORD,
  STATE_COMPANY_STATUS,
  STATE_DISCONNECTED,
  STATE_EMPRESA_BLOQUEADA,
  STATE_LOAD_MODULE,
  STATE_LOCKED_CONTRACT,
  STATE_LOGIN,
  STATE_MAINTENANCE,
  STATE_NO_AUTHORITY,
  STATE_OAUTH2,
  STATE_OAUTH2_DIGITAL_SIGN,
  STATE_OAUTH2_SAFE,
  STATE_PARTNER,
  STATE_PARTNER_MILLENNIUM,
  STATE_RESET_PASSWORD,
  STATE_ENTIDADE_BANCARIA
];

export const UI_ROUTER_MODULE_CONFIG: RootModule = {
  states: uiRouterStates,
  config: configUIRouter,
  useHash: true
};

export function configUIRouter(uiRouter: UIRouter): void {
  const urlService: UrlService = uiRouter.urlService;
  urlService.deferIntercept();

  const targetState: TargetStateDef = {state: STATE_NAME_PORTAL};
  urlService.rules.initial(targetState);
  urlService.rules.otherwise(targetState);

  // Boolean
  urlService.config.type(UI_ROUTER_URL_TYPE_NAME_BOOLEAN, UI_ROUTER_URL_TYPE_BOOLEAN);

  // CG Date
  urlService.config.type(UI_ROUTER_URL_TYPE_NAME_CG_DATE, UI_ROUTER_URL_TYPE_CG_DATE);

  // GUID
  urlService.config.type(UI_ROUTER_URL_TYPE_NAME_GUID, UI_ROUTER_URL_TYPE_GUID);

  // Main portals state (lazy loads all portals and modules states)
  uiRouter.stateRegistry.register({
    name: FUTURE_STATE_NAME_PORTAL,
    url: `/${STATE_NAME_PORTAL}`,
    lazyLoad: async (transition: Transition): Promise<LazyLoadResult> => {
      const injector = transition.injector();
      const portalsService: PortalsService = await injector.getAsync<PortalsService>(PortalsService);
      const appService: AppService = await injector.getAsync<AppService>(AppService);
      const resultPromise: Promise<LazyLoadResult> = portalsService.loadStates();
      appService.setGlobalLoading(resultPromise);
      return resultPromise;
    }
  });

  const handleLaunchModeFn = (transition: Transition): undefined | TargetState => {
    let params: ISiteStateParams = <ISiteStateParams>transition.params();
    if (params?.launchMode?.mode) {
      if (params.launchMode.apiKey) {
        APP_API_KEY.next(params.launchMode.apiKey);
      }
      params = copy(params);
      delete params.launchMode;
      return uiRouter.stateService.target(transition.to(), params, {inherit: false, source: 'redirect'});
    }
    return undefined;
  };

  // Handle launch mode initialization
  uiRouter.transitionService.onCreate({}, handleLaunchModeFn);

  // handle launch mode re-initialization (E.g: expired apikey/token)
  uiRouter.transitionService.onStart({}, handleLaunchModeFn);

  // Disallow `login` state if in partner mode or hybrid mode
  uiRouter.transitionService.onEnter(
    {to: STATE_NAME_LOGIN},
    (transition: Transition): Promise<boolean | TargetState> =>
      transition
        .injector()
        .getAsync<AppService>(AppService)
        .then((appService: AppService) => firstValueFrom(appService.status().pipe(take(1))))
        .then((status: IAppStatus) => {
          if (status.launchMode !== EAppLaunchMode.Default) {
            if (status.launchMode === EAppLaunchMode.Partner) {
              return false;
            }
            if (status.launchMode === EAppLaunchMode.Hybrid || status.launchMode === EAppLaunchMode.HybridPartial) {
              return transition.router.stateService.target(STATE_NAME_LOAD_MODULE);
            }
          }
          return true;
        })
  );
}

export function configUIRouterApp(stateRegistry: StateRegistry, portalsService: PortalsService): void {
  configUIRouterStateAdmin(stateRegistry, portalsService);
}
