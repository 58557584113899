import {merge} from 'lodash-es';
import {JSONSchema} from '@ngx-pwa/local-storage';
import {EPlCookiesConsentItemType, IPlCookiesConsentConfig} from 'pl-comps-angular';
import moment, {Moment} from 'moment';
import {SCHEMA_BOOLEAN, SCHEMA_STRING} from '../../../../common/schemas';

export const SCHEMA_CONSENT_GROUP_ITEM: JSONSchema = {
  type: 'object',
  required: ['name', 'provider', 'purpose', 'type'],
  properties: {
    name: SCHEMA_STRING,
    provider: SCHEMA_STRING,
    purpose: SCHEMA_STRING,
    type: {
      type: 'string',
      enum: ['HTTP', 'HTML']
    },
    providerHyperlink: SCHEMA_STRING,
    expires: SCHEMA_BOOLEAN,
    expiresOn: SCHEMA_STRING
  }
};

export const SCHEMA_CONSENT_GROUP_ITEMS: JSONSchema = {
  type: 'array',
  items: SCHEMA_CONSENT_GROUP_ITEM
};

export const SCHEMA_CONSENT_GROUP_MANDATORY: JSONSchema = {
  type: 'object',
  required: ['items'],
  properties: {
    items: SCHEMA_CONSENT_GROUP_ITEMS
  }
};

export const SCHEMA_CONSENT_GROUP: JSONSchema = merge({}, SCHEMA_CONSENT_GROUP_MANDATORY, {
  properties: {
    accepted: SCHEMA_BOOLEAN
  }
});

export const SCHEMA_CONSENT: JSONSchema = {
  type: 'object',
  properties: {
    consented: SCHEMA_BOOLEAN,
    lastUpdatedOn: SCHEMA_STRING,
    necessary: SCHEMA_CONSENT_GROUP_MANDATORY,
    preferences: SCHEMA_CONSENT_GROUP,
    statistics: SCHEMA_CONSENT_GROUP,
    marketing: SCHEMA_CONSENT_GROUP,
    unclassified: SCHEMA_CONSENT_GROUP
  }
};

// Update whenever cookies change
const lastUpdatedOn: Moment = moment('11/12/2019', 'DD/MM/YYYY');

const providerCentralGest = 'CentralGest';
const providerGoogle = 'Google';
export const DEFAULT_COOKIES_CONSENT: IPlCookiesConsentConfig = Object.freeze<IPlCookiesConsentConfig>({
  hideHeader: false,
  hideNecessary: false,
  hidePreferences: true,
  hideStatistics: true,
  hideMarketing: true,
  hideUnclassified: true,
  hideDetails: false,
  hideFooter: false,
  consent: {
    consented: false,
    lastUpdatedOn: lastUpdatedOn,
    necessary: {
      items: [
        {
          name: 'x-auth-token',
          provider: providerCentralGest,
          purpose: 'cookies.purpose.x-auth-token',
          expires: true,

          expireTime: moment.duration(2, 'hours').asMilliseconds(),
          type: EPlCookiesConsentItemType.HTTP
        },
        {
          name: 'reCaptcha',
          provider: providerGoogle,
          purpose: 'cookies.purpose.reCaptcha',
          expires: false,
          type: EPlCookiesConsentItemType.HTTP
        }
      ]
    },
    preferences: {
      accepted: false,
      items: []
    },
    statistics: {
      accepted: false,
      items: []
    },
    marketing: {
      accepted: false,
      items: []
    },
    unclassified: {
      accepted: false,
      items: []
    }
  }
});
