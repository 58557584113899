import {Directive, TemplateRef} from '@angular/core';

@Directive({
  selector: 'ng-template[plTabTitle]'
})
export class PlTabTitleDirective {
  constructor(
    public readonly templateRef: TemplateRef<void>
  ) {
  }
}
