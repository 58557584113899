<div class="faturacaopublica-config-novoestado-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'faturacaopublica.config.table.conta'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'faturacaopublica.config.table.conta'"></label>
      <edit>
        <entity-autocomplete
          entity="clientes"
          [model]="estadoConta"
          (selectedKeyChange)="estadoConta.conta = $event"
          [properties]="{validators: {required: {value: true}}, disabled: isEdit}"
          [fieldsMap]="{nConta: 'conta'}"
          output="nConta"
          plAutoFocus>
        </entity-autocomplete>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'faturacaopublica.config.estadosConta'"></label>
      <edit>
        <pl-autocomplete
          [source]="metodoSourceEstadosConta"
          attrName="estadosConta"
          [model]="nomeEstadosConta"
          (evtSelected)="metodoEstadosContaChanged($event)"
          [rowTemplate]="mtdOutput"
          output="nameMetodo">
        </pl-autocomplete>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button" plPromise>
    <pl-button klass="btn-success action-save" [onClick]="close"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span> </pl-button>

    <pl-button klass="btn-light" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
  </div>
</div>
