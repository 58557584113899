<div class="ativosmudarcontadepexerc-modal">
  <pl-form>
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="acumulada ? 'ativosmudarcontadep.title.mudarcontadepacumu' : 'ativosmudarcontadep.title.mudarcontadepexerc'"></h5>
    </div>

    <div class="modal-body">
      <pl-alert *ngIf="!canChange" type="warning" [closeable]="false">
        <div [translate]="'ativosmudarcontadep.message.jahadep'"></div>
      </pl-alert>

      <pl-group>
        <edit>
          <label [translate]="'ativosmudarcontadep.fields.ncontaatual'" class="pe-2"></label>
          <span
            >{{ acumulada ? ativo.aquisicao.nContaAmortAcumu : ativo.aquisicao.nContaAmortExerc }} - {{ acumulada ? ativo.aquisicao.nomeContaAmortAcumu : ativo.aquisicao.nomeContaAmortExerc }}</span
          >
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'ativosmudarcontadep.fields.ncontanovo'"></label>
        <edit>
          <entity-autocomplete
            entity="pocs"
            attrName="nConta"
            [selectedKey]="nConta"
            (selectedKeyChange)="nConta = $event"
            [properties]="{validators: {required: {value: true}}, disabled: !canChange}"
            [fieldsMap]="{nConta: 'nConta'}"
            [filter]="filter"
            [output]="pocsOutput"
            plAutoFocus>
          </entity-autocomplete>
        </edit>
      </pl-group>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button klass="btn btn-success action-dismiss" [onClick]="close" [disabled]="!canChange" data-focus
        ><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span>
      </pl-button>

      <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
    </div>
  </pl-form>
</div>
