import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable, Injector, OnDestroy} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../api/api.service';
import {ETestsTokenRoles} from './tests.service.interface';
import {TServiceResponse} from '../api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class TestsService implements OnDestroy {
  private readonly _path: string;
  private readonly _subjectPendingRequests: BehaviorSubject<number>;
  private _observablePendingRequests: Observable<number>;

  constructor(
    private readonly _injector: Injector,
    private readonly _apiService: ApiService
  ) {
    this._path = `${this._apiService.path.restapi}/testes`;
    this._subjectPendingRequests = new BehaviorSubject<number>(0);
  }

  public ngOnDestroy(): void {
    this._subjectPendingRequests.complete();
  }

  public pendingRequests(): Observable<number> {
    if (!this._observablePendingRequests) {
      this._observablePendingRequests = this._subjectPendingRequests.asObservable();
    }
    return this._observablePendingRequests;
  }

  public incrementPendingRequests(): void {
    const next: number = this._subjectPendingRequests.value + 1;
    this._subjectPendingRequests.next(next);
  }

  public decrementPendingRequests(): void {
    const next: number = this._subjectPendingRequests.value - 1;
    this._subjectPendingRequests.next(next);
  }

  public authenticate(tokenRole: ETestsTokenRoles): TServiceResponse<void> {
    return this._apiService.post<void>({url: `${this._path}/authenticate/${tokenRole}`});
  }

  public dismissAllModals(): void {
    const ngbModal: NgbModal = this._injector.get<NgbModal>(NgbModal);
    ngbModal.dismissAll('CG_TESTES_DISMISS_ALL');
  }
}
