import {merge} from 'lodash-es';
import type {Subscription} from 'rxjs';
import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import type {IPlLocale} from '../common/locale/locales.interface';
import {interpolate, isArray, isDefinedNotNull, isEvent, isFunction, isObject, isString} from '../common/utilities/utilities';
import {IPlEditComponentOptionsInputNgSelect} from './select.component.interface';
import {PlDocumentService} from '../common/document/document.service';
import {PlLocaleService} from '../common/locale/locale.service';
import {PlTranslateService} from '../translate/translate.module';

const RESIZE_DEBOUNCE_TIME = 100;

@Component({
  selector: 'pl-select',
  templateUrl: './select.component.html'
})
export class PlSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public attrName: string;
  @Input() public source: any;
  @Input() public model: any;
  @Input() public rowTemplate: string;
  @Input() public output: string;
  @Input() public multiple: boolean;
  @Input() public readonly: boolean;
  @Input() public disabled: boolean;
  @Input() public groupBy: any;
  @Input() public filterBy: string;
  @Input() public placeholder: string;
  @Input() public labelField: string;
  @Input() public valueField: string;
  @Input() public searchFn: (...args: Array<any>) => any;
  @Input() public clearable: boolean;
  @Input() public dropdownPosition: 'bottom' | 'top' | 'auto';
  @Input() public maxSelectedItems: number;
  @Input() public hideSelected: boolean;
  @Input() public searchable: boolean;
  @Input() public clearAllText: string;
  @Input() public loadingText: string;
  @Input() public notFoundText: string;
  @Input() public multipleCheckbox: boolean;
  @Input() public selectableGroup: boolean;
  @Input() public closeOnSelect: boolean;
  @Input() public selectableGroupAsModel: boolean;
  @Input() public openOnEnter: boolean;
  @Input() public appendTo: string;
  @Input() public properties: IPlEditComponentOptionsInputNgSelect | any;
  @Output() public readonly modelChange: EventEmitter<any>;
  @Output() public readonly evtChanged: EventEmitter<any>;

  public options: IPlEditComponentOptionsInputNgSelect;
  public name: string;

  private readonly _element: HTMLElement;
  private readonly _defaultOptions: any;
  private readonly _subscriptionLocale: Subscription;
  private readonly _subscriptionOnWindowResize: Subscription;
  private _value: object | Array<object>;
  private _rowTemplate: string;
  private _output: string;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plLocaleService: PlLocaleService,
    private readonly _plDocumentService: PlDocumentService
  ) {
    this.labelField = 'label';
    this.modelChange = new EventEmitter<any>();
    this.evtChanged = new EventEmitter<any>();
    this.clearable = true;
    this.dropdownPosition = 'auto';
    this.maxSelectedItems = this.maxSelectedItems || undefined;
    this.hideSelected = false;
    this.searchable = true;
    this.multipleCheckbox = false;
    this.closeOnSelect = true;
    this.selectableGroupAsModel = true;
    this.openOnEnter = false;
    this.appendTo = 'body';
    this.model = this.multiple ? [] : {};
    this._element = this._elementRef.nativeElement;
    this._defaultOptions = {
      readonly: false,
      disabled: false,
      validate: true,
      raw: false,
      validators: {},
      events: {}
    };
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this.clearAllText = locale.plSelect.clearAllText;
      this.loadingText = locale.plSelect.loadingText;
      this.notFoundText = locale.plSelect.notFoundText;
    });
    this._subscriptionOnWindowResize = this._plDocumentService.windowResizeDebounced(RESIZE_DEBOUNCE_TIME).subscribe(() => {
      this._onWindowResize();
    });
  }

  public ngOnInit(): void {
    this.handleOptions();
    this._handleModelChanged();
  }

  public ngOnChanges({properties, model}: SimpleChanges): void {
    if (properties && !properties.isFirstChange()) {
      this.handleOptions(properties.currentValue);
    }
    if (model && !model.isFirstChange()) {
      this._handleModelChanged();
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionLocale.unsubscribe();
    this._subscriptionOnWindowResize.unsubscribe();
  }

  public handleOptions(properties: IPlEditComponentOptionsInputNgSelect | any = this.properties): void {
    if (!properties) {
      properties = {};
    }
    if (properties.select2) {
      merge(properties, properties.select2);
    }
    this.options = merge({}, this._defaultOptions, properties);

    if (this.options.validate) {
      this.options.validate = this.options.readonly === false && this.options.disabled === false;
    }
    if (!this.multiple && !this.options.multiple) {
      this.options.raw = true;
    }

    this._rowTemplate = this.rowTemplate || this.options.rowTemplate || '';
    this._output = this.output || this.options.output || '';

    this.labelField = this._output || this.labelField;

    if (this.multipleCheckbox || this.options.multipleCheckbox) {
      this.closeOnSelect = false;
      this.selectableGroupAsModel = false;
    }
  }

  public updateComponent(properties: IPlEditComponentOptionsInputNgSelect | any): void {
    if (properties && properties !== this.options) {
      this.handleOptions(properties);
    }
  }

  public getRow(item: any): string {
    if (!isObject(item) || !this._rowTemplate) {
      return item;
    }
    if (Object.keys(item).length === 1 && item.description) {
      return this._plTranslateService.translate(item.description);
    }
    for (const property of Object.keys(item)) {
      if (isString(item[property])) {
        item[property] = this._plTranslateService.translate(item[property]);
      }
    }
    if (this._rowTemplate.includes('{{')) {
      return interpolate(this._rowTemplate)(item);
    }
    return item[this._rowTemplate];
  }

  public render(item: any): void {
    if (!item || isEvent(item)) {
      return;
    }
    let model: any;
    if (!isArray(item)) {
      model = this.getModelValue(item);
    } else {
      model = [];
      for (const value of item) {
        model.push(this.getModelValue(value));
      }
    }
    this.model = model;
    this.modelChange.emit(this.model);
    this.evtChanged.emit({item: item, model: model, source: this.source});
    if (this.options.events && isFunction(this.options.events.change)) {
      (<any>this.options.events.change)(this.model, model, this.source);
    }
  }

  public getGroupByItem(item: unknown): string {
    return isFunction(this.groupBy) ? this.groupBy(item) : item[this.groupBy];
  }

  public getModelValue(item: unknown): string {
    if (isDefinedNotNull(this.valueField)) {
      return item[this.valueField];
    }
    return this.getRow(item);
  }

  public get value(): object | Array<object> {
    return this._value;
  }

  public set value(value: object | Array<object>) {
    this._value = value;
  }

  private _onWindowResize(): void {
    if (this.multiple) {
      window.setTimeout(() => {
        const input: HTMLInputElement = this._element.querySelector<HTMLInputElement>('.ui-select-search');
        if (input?.parentElement) {
          const parent: HTMLElement = input.parentElement;
          const selectMatch: HTMLSelectElement = parent.querySelector<HTMLSelectElement>('.ui-select-match');
          const width: number = parent.offsetWidth - selectMatch.offsetWidth;
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          input.style.width = `${width - 10}px`;
        }
      });
    }
  }

  private _handleModelChanged(): void {
    if (this.model) {
      let model = this.model;
      if (this.multiple && !isArray(model)) {
        model = [model];
      }
      this._value = model;
    }
  }
}
