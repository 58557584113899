import {ENTITY_NAME_AUDIT_LOGIN} from './auditLogin.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonAuditLogin} from './jsonAuditLogin.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_AUDIT_LOGIN: IEntityDefinition<IJsonAuditLogin> = {
  name: ENTITY_NAME_AUDIT_LOGIN,
  url: 'manager/auditlogin',
  roles: [ROLE.ADMIN],
  icon: 'fa-history',
  pageTitle: 'global.menu.auditlogin',
  metadata: {
    keyName: 'auditLoginId',
    fields: [
      {name: 'stampInsert', type: 'datetime', caption: 'auditlogin.fields.stampInsert'},
      {name: 'actionStr', caption: 'auditlogin.fields.action'},
      {name: 'username', caption: 'auditlogin.fields.username'},
      {name: 'nempresa', caption: 'auditlogin.fields.nEmpresa'},
      {name: 'nomeempresa', caption: 'auditlogin.fields.nomeEmpresa'},
      {name: 'ip', caption: 'auditlogin.fields.ip'},
      {name: 'centralGestName', caption: 'auditlogin.fields.centralGestName'},
      {name: 'info', caption: 'auditlogin.fields.info'}
    ],
    order: 'stampInsert desc',
    searchFields: 'stampInsert,username,nEmpresa,nomeEmpresa,action,ip,name,info'
  },
  actions: {
    new: false,
    edit: false,
    detail: false,
    delete: false,
    search: true,
    filter: true
  }
};
