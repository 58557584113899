import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ConciliacaoBancariaService} from '../../conciliacaoBancaria.module.service';
import {isDefinedNotNull, isObject, PlAlertService} from 'pl-comps-angular';
import {IConciliacaoBancariaConfigImportValorMovimItem, IConciliacaoBancariaImportTemplateData} from '../../conciliacaoBancaria.module.interface';
import {EDebitoCredito} from '../../../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {DATA_SOURCE_DEBITO_CREDITO} from '../../../../../datasources/debitocredito/debitoCredito.datasource';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ICGExceptionError} from '../../../../../components/exceptions/exceptions.service.interface';
import {CGExceptionService} from '../../../../../components/exceptions/exceptions.service';
import {IDataSourceItem} from '../../../../../components/datasource/datasources.interface';

@Component({
  selector: 'conciliacao-bancaria-config-import-modal',
  templateUrl: './conciliacaoBancaria.configImportExtrato.modal.component.html'
})
export class ConciliacaoBancariaConfigImportExtratoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public nConta: string;
  @Input() public periodo: string;
  @Input() public templateName: string;

  public readonly valorMovPropColuna: number = 0;
  public readonly valorMovColunasSeparadas: number = 1;
  public readonly valorMovUmaColunaENoutra: number = 2;
  public readonly debitoCreditoItems: ReadonlyArray<IDataSourceItem<EDebitoCredito>>;

  public valorMovimItems: Array<IConciliacaoBancariaConfigImportValorMovimItem>;
  public model: IConciliacaoBancariaImportTemplateData;
  public mainPromise: Promise<void>;
  public templateSource: Array<string>;
  public cboSource: Array<unknown>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _conciliacaoBancariaService: ConciliacaoBancariaService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService
  ) {
    super(_injector);

    this.debitoCreditoItems = DATA_SOURCE_DEBITO_CREDITO.data;

    this.templateSource = [];
    this.cboSource = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

    this.valorMovimItems = [
      {value: 0, label: 'conciliacaoBancaria.estaNaPropriaColunaLabel'},
      {value: 1, label: 'conciliacaoBancaria.estaEmColunasSeparadasLabel'},
      {value: 2, label: 'conciliacaoBancaria.estEmUmaColunaENoutraColunaLabel'}
    ];
    this.model = {
      caraterCredito: '',
      caraterDebito: '',
      colunaDataMovimento: '',
      colunaDataValor: '',
      colunaDescricao: '',
      colunaSaldo: '',
      colunaTipoMovimento: '',
      colunaValor: '',
      colunaValorCredito: '',
      colunaValorDebito: '',
      inverteSinal: false,
      linhaInicial: 0,
      tipoMovimento: 0,
      valorNegativoLancaCredito: false,
      valorPositivoLancaCredito: false
    };
  }

  public ngOnInit(): void {
    this._loadTemplateData(true);
  }

  public saveTemplate(): void {
    this._conciliacaoBancariaService
      .saveTemplateImportExtrato(this.templateName, this.model, this.nConta, this.periodo)
      .then(() => {
        this._plAlertService.success('conciliacaoBancaria.templateSavedMsg');
        this.close();
      })
      .catch((reason) => {
        this._handleRequestError(reason);
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public templateChange(obj: any): void {
    this.templateName = isObject(obj) ? obj.item : obj;
    this._loadTemplateData(false);
  }

  public onValorNegativoChange(value: number): void {
    this.model.valorNegativoLancaCreditoIndex = value;
    this.model.valorNegativoLancaCredito = value === EDebitoCredito.Credito;
  }

  public onValorPositivoChange(value: number): void {
    this.model.valorPositivoLancaCreditoIndex = value;
    this.model.valorPositivoLancaCredito = value === EDebitoCredito.Credito;
  }

  public removeTemplate(): Promise<void> {
    return this._cgModalService
      .showOkCancel('conciliacaoBancaria.removeTemplatePromptTitle', 'conciliacaoBancaria.removeTemplatePromptMsg', {
        size: 'md',
        showCancelBtn: false,
        btnOkText: 'integracaoSalariosMulti.comecar',
        backdrop: 'static',
        keyboard: false
      })
      .then(() => {
        this._plAlertService.success('conciliacaoBancaria.templateRemovedMsg');
        return this._conciliacaoBancariaService
          .removeTemplateImportExtrato(this.templateName, this.nConta, this.periodo)
          .then(() => {
            this.templateName = '';
            this._loadTemplateData(true);
          })
          .catch((reason) => {
            this._handleRequestError(reason);
          });
      });
  }

  private _loadTemplateData(loadList: boolean): void {
    this.mainPromise = this._conciliacaoBancariaService
      .loadTemplateImportExtrato(this.templateName, loadList, this.nConta, this.periodo)
      .then((response) => {
        if (loadList) {
          this.templateSource = response.list;
        }
        this.model = {...this.model, ...response.template};
        this.model.valorNegativoLancaCreditoIndex = this.model.valorNegativoLancaCredito ? EDebitoCredito.Credito : EDebitoCredito.Debito;
        this.model.valorPositivoLancaCreditoIndex = this.model.valorPositivoLancaCredito ? EDebitoCredito.Credito : EDebitoCredito.Debito;
      })
      .catch((reason) => {
        this._handleRequestError(reason);
      });
  }

  private _handleRequestError(reason: HttpErrorResponse): void {
    if (isDefinedNotNull(reason)) {
      const exception: ICGExceptionError = this._cgExceptionService.get(reason);
      this._plAlertService.error(exception.message);
    }
  }
}
