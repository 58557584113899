import {lastValueFrom} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {isBoolean, isDefined, isNumber, isObject, isString, isUndefined, toJson} from 'pl-comps-angular';
import {API_PATH, apiActivateRuntimeProperties, IApiPath} from '../../../common/api';
import {CGExceptionService} from '../../components/exceptions/exceptions.service';
import {DOCKER} from '../../../../environments/constants';
import {httpParamsEncoder} from '../../../common/http.params.encoder';
import {
  IApiQueryRequestConfig,
  IApiQueryResponse,
  IApiRequestConfig,
  IApiRequestConfigWithBody,
  IApiService,
  IApiUploadRequestConfig,
  THttpBodyRequestMethod,
  THttpRequestMethod,
  THttpSimpleRequestMethod,
  TServiceQueryResponse,
  TServiceResponse
} from './api.service.interface';
import {IAppRuntimeProperties} from '../../../common/interfaces/interfaces';
import {IJsonResponse} from '../../../common/interfaces/json';
import {isProduction} from '../../../config/constants';

@Injectable({
  providedIn: 'root'
})
export class ApiService implements IApiService {
  private _evaluateRuntimeProperties: boolean;

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _cgExceptionService: CGExceptionService
  ) {
    this._evaluateRuntimeProperties = DOCKER;
  }

  public query<TResponse>(config?: IApiQueryRequestConfig): TServiceQueryResponse<TResponse> {
    return this._request<IApiQueryResponse<TResponse>>('GET', config);
  }

  public get<TResponse>(config: IApiRequestConfig): TServiceResponse<TResponse> {
    return this._request<TResponse>('GET', config);
  }

  public delete<TResponse, TRequest = TResponse>(config: IApiRequestConfigWithBody<TRequest>): TServiceResponse<TResponse> {
    return this._request<TResponse>('DELETE', config);
  }

  public post<TResponse, TRequest = TResponse>(config: IApiRequestConfigWithBody<TRequest>): TServiceResponse<TResponse> {
    return this._request<TResponse>('POST', config);
  }

  public put<TResponse, TRequest = TResponse>(config: IApiRequestConfigWithBody<TRequest>): TServiceResponse<TResponse> {
    return this._request<TResponse>('PUT', config);
  }

  public patch<TResponse, TRequest = TResponse>(config: IApiRequestConfigWithBody<TRequest>): TServiceResponse<TResponse> {
    return this._request<TResponse>('PATCH', config);
  }

  public upload<TResponse, TRequest extends FormData = FormData>(config: IApiUploadRequestConfig<TRequest>): TServiceResponse<TResponse> {
    const method = config.method || 'POST';
    return this._request<TResponse>(method, config);
  }

  public get path(): IApiPath {
    return API_PATH;
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  private async _request<TResponse, TRequest = TResponse>(method: THttpRequestMethod, config: IApiRequestConfig): TServiceResponse<TResponse> {
    if (this._evaluateRuntimeProperties) {
      await this._loadRuntimeProperties();
      this._evaluateRuntimeProperties = false;
    }
    if (DOCKER && config.url.startsWith('/')) {
      config.url = this.path.host + config.url;
    }
    const request: HttpRequest<TRequest> = this._buildRequest<TRequest>(method, config);
    if (config.reportExceptions === false) {
      this._cgExceptionService.excludeFromExceptionReporting(request.url);
    }
    return new Promise<HttpResponse<TResponse>>((resolve, reject) => {
      let response: HttpResponse<TResponse>;
      this._httpClient.request<TResponse>(request).subscribe({
        next: (requestResponse: HttpResponse<TResponse>) => {
          if (requestResponse.type === HttpEventType.Response) {
            response = requestResponse;
          }
        },
        error: (error: HttpErrorResponse) => {
          reject(error);
        },
        complete: () => {
          if (
            isObject(response) &&
            isObject(response.body) &&
            Object.prototype.hasOwnProperty.call(response.body, 'status') &&
            Object.prototype.hasOwnProperty.call(response.body, 'message') &&
            Object.prototype.hasOwnProperty.call(response.body, 'data') &&
            isNumber((<IJsonResponse<TResponse>>response.body).status) &&
            (<IJsonResponse<TResponse>>response.body).status !== 0
          ) {
            reject(response);
          } else {
            resolve(response);
          }
        }
      });
    });
  }

  private _buildRequest<TRequest>(method: THttpRequestMethod, config: IApiRequestConfig | IApiRequestConfigWithBody<TRequest>): HttpRequest<TRequest> {
    if (isDefined(config.headers) && !(config.headers instanceof HttpHeaders)) {
      let headers = new HttpHeaders();
      this._parseParams(config.headers, (value: string, key: string) => {
        headers = headers.set(key, value);
      });
      config.headers = headers;
    }
    if (isDefined(config.params) && !(config.params instanceof HttpParams)) {
      let params = new HttpParams({encoder: httpParamsEncoder});
      this._parseParams(config.params, (value: string, key: string) => {
        params = params.set(key, value);
      });
      config.params = params;
    }
    switch (method) {
      case 'GET':
      case 'HEAD':
      case 'JSONP':
      case 'OPTIONS':
        return this._buildSimpleRequest<TRequest>(method, config);
      default:
        return this._buildBodyRequest<TRequest>(method, <IApiRequestConfigWithBody<TRequest>>config);
    }
  }

  private _buildSimpleRequest<TRequest>(method: THttpSimpleRequestMethod, data: IApiRequestConfig): HttpRequest<TRequest> {
    const withCredentials: boolean = isBoolean(data.withCredentials) ? data.withCredentials : data.url.startsWith(this.path.host) ? (!isProduction() || DOCKER ? true : undefined) : false;
    return new HttpRequest<TRequest>(<'GET'>method, data.url, {
      headers: <HttpHeaders>data.headers,
      params: <HttpParams>data.params,
      responseType: data.responseType,
      withCredentials: withCredentials,
      reportProgress: data.reportProgress
    });
  }

  private _buildBodyRequest<TRequest>(method: THttpBodyRequestMethod, data: IApiRequestConfigWithBody<TRequest>): HttpRequest<TRequest> {
    const withCredentials: boolean = isBoolean(data.withCredentials) ? data.withCredentials : data.url.startsWith(this.path.host) ? (!isProduction() || DOCKER ? true : undefined) : false;
    return new HttpRequest<TRequest>(method, data.url, data.body, {
      headers: <HttpHeaders>data.headers,
      params: <HttpParams>data.params,
      responseType: data.responseType,
      withCredentials: withCredentials,
      reportProgress: data.reportProgress
    });
  }

  private _parseParams(value: object, callback: (value: string, key: string) => void): void {
    for (const prop of Object.keys(value)) {
      let val: string = value[prop];
      if (isUndefined(val)) {
        continue;
      }
      if (isObject(val)) {
        val = toJson(value[prop]);
      }
      if (!isString(val)) {
        val = String(val);
      }
      callback(val, prop);
    }
  }

  private async _loadRuntimeProperties(): Promise<void> {
    const url = `${window.location.origin}/config/properties.json`;
    const properties: IAppRuntimeProperties = await lastValueFrom(this._httpClient.request<IAppRuntimeProperties>('GET', url, {responseType: 'json'}));
    apiActivateRuntimeProperties(properties);
  }
}
