import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {IPlToolbarInstance, IPlToolbarItem, IPlToolbarMenuItem, isNumber, isObject, PlToolbarService} from 'pl-comps-angular';
import {IDevExpressDataGrid, IDevExpressDataGridColumnCustomizeTextCellInfo, TDevExpressDataGridColumnCustomizeTextFn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  EEstadoAcrescimoEncFerias,
  ERHFEEstadoRegEmp,
  ETipoAcrescimoEncFerias,
  IJsonAcrescimoEncargoComFerias,
  IJsonAcrescimoEncargoComFeriasAnuais,
  IJsonAcrescimoEncargoComFeriasCab,
  IJsonAcrescimoEncargoComFeriasCabConfig,
  IJsonAcrescimoEncargoComFeriasLin,
  IJsonAcrescimosEncFeriasDocContabIntegrado
} from '../../jsonAcrescimosEncargosFerias.module.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnEditorPreparing,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnSaved
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {minDateCG, round} from '../../../../../common/utils/utils';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {ENTITY_NAME_DGEMPS_FULL} from '../../../portalrh/dgempsfull/dgempsFull.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {HttpResponse} from '@angular/common/http';
import {IJsonBoolean} from '../../../../../common/interfaces/json';
import {AcrescimosEncargosFeriasResumoanualModalComponent} from '../../modals/resumoanual/acrescimosEncargosFerias.resumoanual.modal.component';
import ArrayStore from 'devextreme/data/array_store';
import {TranslateService} from '@ngx-translate/core';
import {AcrescimosEncargosFeriasService} from '../../acrescimosEncargosFerias.module.service';
import moment from 'moment';
import {IAcrescimoEncFeriasSingleCallback} from '../../acrescimosEncargosFerias.module.interface';
import {AcrescimosEncargosFeriasPrintModalComponent} from '../../modals/print/acrescimosEncargosFerias.print.modal.component';
import {ENTITY_NAME_DOCS_CONTABILIDADE} from '../../../portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {AcrescimosEncargosFeriasConfigModalComponent} from '../../modals/config/acrescimosEncargosFerias.config.modal.component';
import {ConfigService} from '../../../../services/config/config.service';

const TOOLBAR_GROUP_ID_CONTAB = 'acrescimosencargosferias-contab';
const TOOLBAR_GROUP_ID_CONSULT_LANC = 'acrescimosencargosferias-consult-lanc';
const TOOLBAR_INSTANCE_ID_ACRESCIMOS_ENCARGOS_SINGLE = 'TOOLBAR_ACRESCIMOS_ENCARGOS_SINGLE';
const NUMBER_12 = 12;
const NUMBER_3 = 3;

@Component({
  selector: 'acrescimos-enc-ferias-single',
  templateUrl: './acrescimosEncargosFerias.single.component.html'
})
export class AcrescimosEncargosFeriasSingleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public anoEncargo: number;
  @Input() public nEmpresa: string;
  @Input() public instanceNameToolbar: string;
  @Input() public fromMultiEmpresa: boolean;
  @Input() public callback: IAcrescimoEncFeriasSingleCallback;
  @Output() public readonly evtChangedDataSource: EventEmitter<boolean>;

  public readonly dataGridDefinitionSingle: IDevExpressDataGrid;

  public encargosCab: IJsonAcrescimoEncargoComFeriasCab;
  public toolbarAcrescimoEncargosSingleId: string;

  private readonly _btnResumoAnual: IPlToolbarMenuItem;
  private readonly _btnSaveEncargos: IPlToolbarMenuItem;
  private readonly _btnAnularEncargos: IPlToolbarMenuItem;
  private readonly _btnPrintEncargos: IPlToolbarMenuItem;
  private readonly _btnLancarDocumento: IPlToolbarMenuItem;
  private readonly _btnApagarDocumento: IPlToolbarMenuItem;
  private readonly _menuBtnConsultarLancamento: IPlToolbarMenuItem;
  private readonly _menuBtnContabilidade: IPlToolbarItem;

  private readonly _docsContabilidadeMaintenanceInstance: IEntityMaintenanceInstance;
  private readonly _maintenanceDGEMPFull: IEntityMaintenanceInstance;

  private _dataGridInstance: dxDataGrid;
  private _changedField: string;

  constructor(
    private readonly _cgModalService: CGModalService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _translateService: TranslateService,
    private readonly _acrescimosEncargosService: AcrescimosEncargosFeriasService,
    private readonly _plToolbarService: PlToolbarService,
    private readonly _configService: ConfigService
  ) {
    this.evtChangedDataSource = new EventEmitter<boolean>();
    this._maintenanceDGEMPFull = this._entityMaintenanceService.build(ENTITY_NAME_DGEMPS_FULL);
    this._docsContabilidadeMaintenanceInstance = this._entityMaintenanceService.build(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._btnSaveEncargos = {
      id: `${TOOLBAR_INSTANCE_ID_ACRESCIMOS_ENCARGOS_SINGLE}-save-encargos`,
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-save fa-fw"></i>',
      caption: 'global.btn.save',
      class: 'btn-primary',
      click: () => this._saveEncargos(),
      disabled: true
    };
    this._btnAnularEncargos = {
      id: `${TOOLBAR_INSTANCE_ID_ACRESCIMOS_ENCARGOS_SINGLE}-anular-encargos`,
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-times fa-fw"></i>',
      caption: 'global.btn.nullify',
      class: 'btn-danger',
      click: () => this._apagaAcrescimosEncargosFerias(),
      disabled: true
    };
    this._btnPrintEncargos = {
      id: `${TOOLBAR_INSTANCE_ID_ACRESCIMOS_ENCARGOS_SINGLE}-print-encargos`,
      order: 3,
      type: 'button',
      iconLeft: '<i class="fa fa-print fa-fw"></i>',
      caption: 'global.btn.print',
      class: 'btn-light',
      click: () => {
        this._printEncargos();
      },
      disabled: true
    };
    this._btnResumoAnual = {
      id: `${TOOLBAR_INSTANCE_ID_ACRESCIMOS_ENCARGOS_SINGLE}-resumo-anual`,
      order: 4,
      type: 'button',
      iconLeft: '<i class="fa fa-files-o fa-fw"></i>',
      caption: 'acrescimosencargosferias.btns.resumoAnual',
      class: 'btn-info',
      click: () => this._openModalResumoAnual(),
      disabled: true
    };
    this._btnLancarDocumento = {
      groupId: TOOLBAR_GROUP_ID_CONTAB,
      id: `${TOOLBAR_INSTANCE_ID_ACRESCIMOS_ENCARGOS_SINGLE}-lancar-documento`,
      iconLeft: '<i class="fa fa-file-o fa-fw"></i>&nbsp;',
      caption: 'acrescimosencargosferias.btns.lancarDocumento',
      disabled: true,
      click: () => this._lancarDocumento()
    };
    this._btnApagarDocumento = {
      groupId: TOOLBAR_GROUP_ID_CONTAB,
      id: `${TOOLBAR_INSTANCE_ID_ACRESCIMOS_ENCARGOS_SINGLE}-apagar-documento`,
      iconLeft: '<i class="fa fa-trash fa-fw"></i>&nbsp;',
      caption: 'acrescimosencargosferias.btns.apagarDocumento',
      disabled: true,
      click: () => this._apagarDocumento()
    };
    this._menuBtnContabilidade = {
      groupId: TOOLBAR_GROUP_ID_CONTAB,
      id: `${TOOLBAR_INSTANCE_ID_ACRESCIMOS_ENCARGOS_SINGLE}-menu-contab`,
      order: 6,
      type: 'dropdown',
      caption: 'acrescimosencargosferias.btns.integraContab',
      class: 'btn-success',
      disabled: true,
      menu: [this._btnLancarDocumento, this._btnApagarDocumento]
    };
    this._menuBtnConsultarLancamento = {
      groupId: TOOLBAR_GROUP_ID_CONSULT_LANC,
      id: `${TOOLBAR_INSTANCE_ID_ACRESCIMOS_ENCARGOS_SINGLE}-menu-consultar-lanc`,
      order: 7,
      type: 'dropdown',
      caption: 'acrescimosencargosferias.btns.consultarLanc',
      class: 'btn-light',
      menu: [],
      disabled: true
    };
    this.dataGridDefinitionSingle = {
      columnHidingEnabled: false,
      columns: [
        {
          caption: 'acrescimosencargosferias.fields.empregado',
          columns: [
            {dataField: 'codEmpregado', dataType: 'number', caption: 'acrescimosencargosferias.fields.codEmpregado', allowEditing: false, cellTemplate: 'cellTemplateCodEmpregado'},
            {dataField: 'nomeEmpregado', dataType: 'string', caption: 'acrescimosencargosferias.fields.nomeEmpregado', width: 200, allowEditing: false},
            {dataField: 'dataAdmissao', dataType: 'date', caption: 'acrescimosencargosferias.fields.dataAdmissao', allowEditing: false},
            {dataField: 'nMesesTrabalhados', dataType: 'number', caption: 'acrescimosencargosferias.fields.nMesesTrabalhados', allowEditing: false},
            {dataField: 'ccusto', dataType: 'string', caption: 'acrescimosencargosferias.fields.ccusto', allowEditing: false},
            {dataField: 'nCodRepCC', dataType: 'number', caption: 'acrescimosencargosferias.fields.nCodRepCC', allowEditing: false},
            {dataField: 'estadoID', dataType: 'number', caption: 'acrescimosencargosferias.fields.estadoID', allowEditing: false, customizeText: this._fnCustomizeTextEstado, alignment: 'center'}
          ]
        },
        {
          caption: 'acrescimosencargosferias.fields.totaisEncargos',
          columns: [
            {dataField: 'vBaseEncargosFerias', dataType: 'double', caption: 'acrescimosencargosferias.fields.vBaseEncargosFerias', allowEditing: false},
            {dataField: 'valorAcertoManual', dataType: 'double', caption: 'acrescimosencargosferias.fields.valorAcertoManual'},
            {dataField: 'valorSubFerias', dataType: 'double', caption: 'acrescimosencargosferias.fields.valorSubFerias', allowEditing: false},
            {dataField: 'valorFerias', dataType: 'double', caption: 'acrescimosencargosferias.fields.valorFerias', allowEditing: false},
            {dataField: 'valorEncargosSS', dataType: 'double', caption: 'acrescimosencargosferias.fields.valorEncargosSS', allowEditing: false},
            {dataField: 'valorEncargoAcTrab', dataType: 'double', caption: 'acrescimosencargosferias.fields.valorEncargoAcTrab', allowEditing: false},
            {dataField: 'valorOutrosEncargos', dataType: 'double', caption: 'acrescimosencargosferias.fields.valorOutrosEncargos'},
            {
              dataField: 'descrBaseEncFerias',
              dataType: 'double',
              caption: 'acrescimosencargosferias.fields.descrBaseEncFerias',
              allowEditing: false,
              cellTemplate: 'cellTemplateInfo',
              alignment: 'center'
            },
            {dataField: 'total', dataType: 'double', caption: 'acrescimosencargosferias.fields.total', allowEditing: false}
          ]
        }
      ],
      dataSource: [],
      editing: {mode: 'cell', startEditAction: 'click', selectTextOnEditStart: true, allowUpdating: true, refreshMode: 'repaint'},
      export: {filename: 'global.menu.acrescimosencargoscomferias'},
      remoteOperations: false,
      summary: {
        totalItems: [
          {column: 'vBaseEncargosFerias', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorAcertoManual', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorSubFerias', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorFerias', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorEncargosSS', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorEncargoAcTrab', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorOutrosEncargos', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'total', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'}
        ]
      }
    };
    this.instanceNameToolbar = 'toolbar';
    this.anoEncargo = this._configService.configurations.empresa.anoEmCursoIRC;
    this.fromMultiEmpresa = false;
  }

  public ngOnInit(): void {
    this.toolbarAcrescimoEncargosSingleId = `${this.instanceNameToolbar}-${TOOLBAR_INSTANCE_ID_ACRESCIMOS_ENCARGOS_SINGLE}`;
    const toolBarInstance: IPlToolbarInstance = this._plToolbarService.registerInstance(this.toolbarAcrescimoEncargosSingleId);
    toolBarInstance.setItems([this._btnSaveEncargos, this._btnAnularEncargos, this._btnPrintEncargos, this._btnResumoAnual, this._menuBtnContabilidade, this._menuBtnConsultarLancamento]);
    this.encargosCab = this._emptyEncargosCab();
    if (this.fromMultiEmpresa) {
      this._carregarEncargos();
    }
    this._evaluateToolbarButtons();
  }

  public ngOnChanges({anoEncargo, callback}: SimpleChanges): void {
    if (anoEncargo && !anoEncargo.isFirstChange()) {
      this.anoEncargo = anoEncargo.currentValue;
      this.encargosCab.anoEncargo = this.anoEncargo;
    }
    if (callback) {
      const cb: IAcrescimoEncFeriasSingleCallback = callback.currentValue;
      if (isObject(cb)) {
        cb.carregar = (anoEncargoChanged: number) => {
          this.anoEncargo = anoEncargoChanged;
          this.encargosCab.anoEncargo = this.anoEncargo;
          return this._carregarEncargos();
        };
        cb.anular = () => this._anularEncargos();
      }
    }
  }

  public ngOnDestroy(): void {
    this._plToolbarService.unRegisterInstance(this.toolbarAcrescimoEncargosSingleId);
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonAcrescimoEncargoComFeriasLin>): void {
    if (event.rowType === 'data') {
      if (event.column.dataField === 'valorAcertoManual' || event.column.dataField === 'valorOutrosEncargos') {
        event.cellElement.classList.add('datagrid-editable-cell');
      }
    }
  }

  public onEditorPreparing(event: IDevExpressDataGridEventOnEditorPreparing<IJsonAcrescimoEncargoComFeriasLin>): void {
    this._changedField = event.dataField;
  }

  public onSaved(event: IDevExpressDataGridEventOnSaved<IJsonAcrescimoEncargoComFeriasLin>): void {
    if (event.changes[0]) {
      if (this._changedField === 'valorAcertoManual' || this._changedField === 'valorOutrosEncargos') {
        this._changedField = '';
        const dataItem: IJsonAcrescimoEncargoComFeriasLin = <IJsonAcrescimoEncargoComFeriasLin>event.changes[0].data;

        dataItem.valorSubFerias = round((dataItem.vBaseEncargosFerias * dataItem.nMesesTrabalhados) / NUMBER_12 + dataItem.valorAcertoManual, NUMBER_3);
        dataItem.valorFerias = round((dataItem.vBaseEncargosFerias * dataItem.nMesesTrabalhados) / NUMBER_12 + dataItem.valorAcertoManual, NUMBER_3);
        dataItem.valorEncargosSS = round((dataItem.valorSubFerias + dataItem.valorFerias) * (dataItem.percentagemSegSocial / 100), NUMBER_3);
        dataItem.valorEncargoAcTrab = round((dataItem.valorSubFerias + dataItem.valorFerias) * (dataItem.percentagemAcTrab / 100), NUMBER_3);
        dataItem.total = round(dataItem.valorSubFerias + dataItem.valorFerias + dataItem.valorEncargosSS + dataItem.valorEncargoAcTrab + dataItem.valorOutrosEncargos, NUMBER_3);
        dataItem.estadoRegisto = ERHFEEstadoRegEmp.Alterado;
        dataItem.estadoID = ERHFEEstadoRegEmp.Alterado;

        event.component.getDataSource().store().update(dataItem.rhEncFeriasLinID, dataItem);
        event.component.refresh();
      }
    }
  }

  public showMessageInfo(info: string): void {
    this._cgModalService.showOkCancel('global.text.info', info, {size: 'md', showCancelBtn: false, showCloseBtn: true, showOkBtn: false});
  }

  public openDGEMP(codEmp: number): void {
    this._maintenanceDGEMPFull.maintenanceEdit(codEmp);
  }

  private _carregarEncargos(): Promise<void> {
    return this._acrescimosEncargosService.existeEncargosNoAno(this.encargosCab.anoEncargo, this.nEmpresa).then((response: HttpResponse<IJsonBoolean>) => {
      if (response.body.value) {
        this._dataGridInstance.beginCustomLoading(undefined);
        return this._acrescimosEncargosService
          .getEncargosList(this.encargosCab.anoEncargo, this.nEmpresa)
          .then((responseAcrescimos: HttpResponse<IJsonAcrescimoEncargoComFerias>) => {
            this._applyAcrescimosEncargos(responseAcrescimos.body);
          })
          .finally(() => {
            this._dataGridInstance.endCustomLoading();
          });
      }

      this._applyCab(this._emptyEncargosCab());
      this.dataGridDefinitionSingle.dataSource = [];
      this._evaluateToolbarButtons(false);

      return this._acrescimosEncargosService.acrescimoEncargoCabConfig(this.nEmpresa, this.anoEncargo).then((responseConfig: HttpResponse<IJsonAcrescimoEncargoComFeriasCabConfig>) => {
        const modalInstance = this._cgModalService.showVanilla(AcrescimosEncargosFeriasConfigModalComponent);
        const componentInstance: AcrescimosEncargosFeriasConfigModalComponent = modalInstance.componentInstance;
        componentInstance.nEmpresa = this.nEmpresa;
        componentInstance.anoEncargo = this.anoEncargo;
        componentInstance.cabEncargos = responseConfig.body.cab;
        componentInstance.configEncargos = responseConfig.body.config;
        return modalInstance.result.then((needFresh: boolean) => {
          if (needFresh) {
            this._carregarEncargos();
          }
        });
      });
    });
  }

  private async _anularEncargos(): Promise<void> {
    this._dataGridInstance.beginCustomLoading(undefined);
    try {
      await this._acrescimosEncargosService.removeEncargos(this.encargosCab.anoEncargo, this.nEmpresa);
      const emptyCab: IJsonAcrescimoEncargoComFeriasCab = this._emptyEncargosCab();
      emptyCab.anoEncargo = this.encargosCab.anoEncargo;
      this._applyAcrescimosEncargos({cab: emptyCab, linhas: []});
      this.evtChangedDataSource.emit(true);
    } finally {
      this._dataGridInstance.endCustomLoading();
    }
  }

  private async _apagaAcrescimosEncargosFerias(): Promise<void> {
    this._dataGridInstance.beginCustomLoading(undefined);
    try {
      const responseAcrescimos: HttpResponse<IJsonAcrescimoEncargoComFerias> = await this._acrescimosEncargosService.apagarAcrescimosEncargosComFerias(this.encargosCab.anoEncargo, this.nEmpresa);
      this._applyAcrescimosEncargos(responseAcrescimos.body);
      this.evtChangedDataSource.emit(true);
    } finally {
      this._dataGridInstance.endCustomLoading();
    }
  }

  private _saveEncargos(): Promise<void> {
    return this._dataGridInstance
      .getDataSource()
      .store()
      .load()
      .then((linhas: Array<IJsonAcrescimoEncargoComFeriasLin>) => {
        this._dataGridInstance.beginCustomLoading(undefined);
        const linhasAlteradas: Array<IJsonAcrescimoEncargoComFeriasLin> = linhas.filter((linha: IJsonAcrescimoEncargoComFeriasLin) => linha.estadoID === ERHFEEstadoRegEmp.Alterado);
        return this._acrescimosEncargosService
          .saveAcrescimosEncargosComFerias(this.encargosCab.anoEncargo, this.nEmpresa, linhasAlteradas)
          .then((response: HttpResponse<IJsonAcrescimoEncargoComFerias>) => {
            this._applyAcrescimosEncargos({cab: response.body.cab, linhas: response.body.linhas});
            this.evtChangedDataSource.emit(true);
          })
          .finally(() => {
            this._dataGridInstance.endCustomLoading();
          });
      });
  }

  private async _lancarDocumento(): Promise<void> {
    this._dataGridInstance.beginCustomLoading(undefined);
    try {
      const response: HttpResponse<IJsonAcrescimoEncargoComFerias> = await this._acrescimosEncargosService.lancaContabilidadeAnual(this.encargosCab.anoEncargo, this.nEmpresa);
      this._applyAcrescimosEncargos({cab: response.body.cab, linhas: response.body.linhas});
      this.evtChangedDataSource.emit(true);
    } finally {
      this._dataGridInstance.endCustomLoading();
    }
  }

  private async _apagarDocumento(): Promise<void> {
    this._dataGridInstance.beginCustomLoading(undefined);
    try {
      const response: HttpResponse<IJsonAcrescimoEncargoComFerias> = await this._acrescimosEncargosService.apagaLancamentoContabilidade(this.encargosCab.anoEncargo, this.nEmpresa);
      this._applyAcrescimosEncargos({cab: response.body.cab, linhas: response.body.linhas});
      this.evtChangedDataSource.emit(true);
    } finally {
      this._dataGridInstance.endCustomLoading();
    }
  }

  private async _openModalResumoAnual(): Promise<void> {
    const response: HttpResponse<IJsonAcrescimoEncargoComFeriasAnuais> = await this._acrescimosEncargosService.encargosAnuais(this.encargosCab.anoEncargo, this.nEmpresa);
    const modalInstance = this._cgModalService.showVanilla(AcrescimosEncargosFeriasResumoanualModalComponent, {size: 'xl'});
    const componentInstance: AcrescimosEncargosFeriasResumoanualModalComponent = modalInstance.componentInstance;
    componentInstance.resumoAnual = response.body;
  }

  private _printEncargos(): void {
    const modalInstance = this._cgModalService.showVanilla(AcrescimosEncargosFeriasPrintModalComponent, {size: 'xl'});
    const componentInstance: AcrescimosEncargosFeriasPrintModalComponent = modalInstance.componentInstance;
    componentInstance.nEmpresa = this.nEmpresa;
    componentInstance.anoEncargo = this.encargosCab.anoEncargo;
  }

  private _applyAcrescimosEncargos(data: IJsonAcrescimoEncargoComFerias): void {
    this._applyCab(data.cab);
    this._applyDataSource(data.linhas);
  }

  private _applyDataSource(list: Array<IJsonAcrescimoEncargoComFeriasLin>): void {
    this.dataGridDefinitionSingle.dataSource = new ArrayStore({key: 'rhEncFeriasLinID', data: list});
    this._evaluateToolbarButtons(list.length > 0);
  }

  private async _applyCab(cab: IJsonAcrescimoEncargoComFeriasCab): Promise<void> {
    if (cab?.dataIntegracao && moment(cab.dataIntegracao).isSameOrBefore(minDateCG(), 'date')) {
      cab.dataIntegracao = undefined;
    }
    this.encargosCab = cab;

    this._btnLancarDocumento.disabled = this.encargosCab.estado !== EEstadoAcrescimoEncFerias.Processado;
    this._btnApagarDocumento.disabled = this.encargosCab.estado !== EEstadoAcrescimoEncFerias.Integrado;
    this._menuBtnContabilidade.disabled = this._btnLancarDocumento.disabled && this._btnApagarDocumento.disabled;

    this._menuBtnConsultarLancamento.disabled = this.encargosCab.estado !== EEstadoAcrescimoEncFerias.Integrado;

    this._menuBtnConsultarLancamento.menu = [];
    if (this.encargosCab.estado === EEstadoAcrescimoEncFerias.Integrado) {
      await this._acrescimosEncargosService.docsContabInteg(this.encargosCab.anoEncargo, this.nEmpresa).then((response: HttpResponse<Array<IJsonAcrescimosEncFeriasDocContabIntegrado>>) => {
        for (const item of response.body) {
          this._menuBtnConsultarLancamento.menu.push({
            groupId: TOOLBAR_GROUP_ID_CONSULT_LANC,
            id: `${TOOLBAR_INSTANCE_ID_ACRESCIMOS_ENCARGOS_SINGLE}-${item.periodo}-${item.documento}`,
            iconLeft: '<i class="fa fa-file fa-fw"></i>&nbsp;',
            caption: item.caption,
            click: () => {
              this._previewDocContab(item);
            }
          });
        }
      });
    }
  }

  private _previewDocContab(item: IJsonAcrescimosEncFeriasDocContabIntegrado): void {
    this._docsContabilidadeMaintenanceInstance.maintenanceEdit(item.extPocCabID, {preventDocumentKeyup: false});
  }

  private _emptyEncargosCab(): IJsonAcrescimoEncargoComFeriasCab {
    return {
      anoEncargo: this.anoEncargo,
      tipo: ETipoAcrescimoEncFerias.None,
      percSeguradora: 0,
      estado: EEstadoAcrescimoEncFerias.None,
      dataIntegracao: undefined,
      tipoDesc: '',
      descricao: '',
      estadoDesc: ''
    };
  }

  private _evaluateToolbarButtons(enableButton: boolean = false): void {
    this._btnSaveEncargos.disabled = !enableButton;
    this._btnAnularEncargos.disabled = !enableButton;
    this._btnPrintEncargos.disabled = !enableButton;
    this._btnResumoAnual.disabled = !enableButton;
  }

  private _customizeTextEstado(cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string {
    if (cellInfo.target === 'row' && isNumber(cellInfo.value)) {
      switch (Number(cellInfo.value)) {
        case ERHFEEstadoRegEmp.Novo:
          return this._translateService.instant('acrescimosencargosferias.estadoRegEmp.novo');
        case ERHFEEstadoRegEmp.OK:
          return this._translateService.instant('acrescimosencargosferias.estadoRegEmp.ok');
        case ERHFEEstadoRegEmp.Alterado:
          return this._translateService.instant('acrescimosencargosferias.estadoRegEmp.alterado');
        case ERHFEEstadoRegEmp.Apagado:
          return this._translateService.instant('acrescimosencargosferias.estadoRegEmp.apagado');
      }
    }
    return cellInfo.valueText;
  }

  private readonly _fnCustomizeTextEstado: TDevExpressDataGridColumnCustomizeTextFn = (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => this._customizeTextEstado(cellInfo);
}
