<div class="mapa-pagamentos-pagamento-modal">
  <div class="modal-header">
    <h5 class="modal-title" [translate]="modalTitle"></h5>
  </div>

  <div [plPromise]="promise">
    <div class="modal-body">
      <div *ngIf="isEditMode" class="mapa-pagamentos-pagamento-modal-empresa" [translate]="empresaTop"></div>
      <div class="row">
        <div class="col-md-4" *ngIf="onEditPagMan">
          <pl-group>
            <label>Ano:</label>
            <edit>
              <pl-select [source]="anosSource" [model]="pagManYear" (modelChange)="pagManYearChanged($event)" [clearable]="false"> </pl-select>
            </edit>
          </pl-group>
        </div>
        <div class="col-md-4" *ngIf="onEditPagMan">
          <pl-group>
            <label [translate]="'mapapagam.texts.pagamentoManual'"></label>
            <edit>
              <pl-autocomplete [model]="selectedPagMan" (modelChange)="selectedPagManChange($event)" [allowInvalid]="false" rowTemplate="nomePagamento" output="nomePagamento" [source]="pagManSource">
              </pl-autocomplete>
            </edit>
          </pl-group>
        </div>
        <ng-container *ngIf="!onEditPagMan">
          <div class="col-md-4">
            <pl-group>
              <label [translate]="'mapapagam.fields.nomePagamento'"></label>
              <edit>
                <pl-edit type="text" [(model)]="model.nome" [properties]="{disabled: onEditPagMan, validators: {maxlength: {value: 20}, required: {value: true}}}"></pl-edit>
              </edit>
            </pl-group>

            <ng-container>
              <pl-group>
                <label [translate]="'mapapagam.fields.refPagDescricao'"></label>
                <edit>
                  <pl-edit type="text" [(model)]="model.dadosPagamento.refPag" [properties]="{disabled: onEditPagMan, validators: {maxlength: {value: 30}}}"></pl-edit>
                </edit>
              </pl-group>

              <pl-group *ngIf="isEditMode">
                <pl-edit type="checkbox" [(model)]="model.dadosPagamento.pago" [properties]="{disabled: onEditPagMan, label: 'mapapagam.fields.pago'}"></pl-edit>
              </pl-group>
            </ng-container>
          </div>
          <div class="col-md-4">
            <pl-group>
              <label [translate]="'mapapagam.fields.descricaoPagamento'"></label>
              <edit>
                <pl-edit type="text" [(model)]="model.descricao" [properties]="{disabled: onEditPagMan, validators: {maxlength: {value: 100}}}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group *ngIf="isEditMode">
              <label [translate]="'mapapagam.fields.dataLimitePag'"></label>
              <edit>
                <pl-edit-datepicker [(model)]="model.dadosPagamento.dataLimitePag" [properties]="{disabled: onEditPagMan}"></pl-edit-datepicker>
              </edit>
            </pl-group>
          </div>
          <div class="col-md-4">
            <pl-group>
              <label [translate]="'mapapagam.fields.responsavel'"></label>
              <edit>
                <pl-autocomplete
                  [properties]="{disabled: onEditPagMan}"
                  [source]="sourceResponsaveis"
                  [model]="model.nResponsavel"
                  (modelChange)="userSelect($event)"
                  [rowTemplate]="usersTemplate"
                  [output]="usersTemplate"></pl-autocomplete>
              </edit>
            </pl-group>

            <pl-group *ngIf="isEditMode">
              <label [translate]="'mapapagam.fields.montante'"></label>
              <edit>
                <pl-edit type="currency" [(model)]="model.dadosPagamento.montante"></pl-edit>
              </edit>
            </pl-group>
          </div>
        </ng-container>

        <ng-container *ngIf="isEditMode">
          <div class="col-md-12">
            <div class="card mb-3">
              <div class="card-header">
                <span [translate]="'mapapagam.texts.anexo'"></span>
              </div>
              <div class="card-body">
                <ng-container *ngIf="!haveAttachment">
                  <pl-upload
                    [url]="uploadUrl"
                    [autoProcessQueue]="false"
                    [maxFiles]="1"
                    [callback]="plUploadCallback"
                    [hideActionCancel]="true"
                    [hideActionRemoveAll]="true"
                    [hideActionRetry]="true"
                    [hideActionUploadAll]="true"
                    [hideActionUpload]="true"
                    [uploadMultiple]="false"
                    [maxFileSize]="10"
                    acceptedFiles=".pdf">
                  </pl-upload>
                </ng-container>

                <ng-container *ngIf="haveAttachment">
                  <button type="button" class="btn btn-sm btn-light" [click]="fnVerAnexo" plPromise>
                    <i class="fa fa-fw fa-file-pdf-o" aria-hidden="true"></i>&nbsp;<span [translate]="'mapapagam.texts.documento'"></span>
                  </button>
                  <button type="button" class="btn btn-sm btn-danger ms-2" (click)="removerAnexo()">
                    <i class="fa fa-fw fa-file-pdf-o" aria-hidden="true"></i>&nbsp;<span [translate]="'mapapagam.texts.removerAnexo'"></span>
                  </button>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!isEditMode && (!onEditPagMan || (onEditPagMan && selectedPagMan))">
          <div class="col-md-12">
            <div class="card mb-3">
              <div class="card-header" *ngIf="!onEditPagMan">
                <pl-edit-switch [(model)]="todasEmpresasFlag" [properties]="{label: 'mapapagam.texts.todasEmpresas'}"></pl-edit-switch>
              </div>
              <div class="card-body" *ngIf="!todasEmpresasFlag">
                <pl-multiselect [(model)]="empresasSel" [source]="empresasList" key="nEmpresa" [template]="msEmpresasTemplate"></pl-multiselect>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="card mb-3">
              <div class="card-header" *ngIf="!onEditPagMan">
                <pl-edit-switch [(model)]="todosMesesFlag" [properties]="{label: 'mapapagam.texts.todosMeses'}"></pl-edit-switch>
              </div>
              <div class="card-body" *ngIf="!todosMesesFlag">
                <div class="mapa-pagamentos-meses-grid">
                  <ng-container *ngFor="let mesItem of mesesSource">
                    <pl-edit-switch [(model)]="modelMeses[mesItem.month].checked" [properties]="{label: mesItem.name}"></pl-edit-switch>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6" *ngIf="!onEditPagMan">
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text" [translate]="'mapapagam.texts.durante'"></span>
              </div>
              <pl-edit type="select" [(model)]="duracaoAnos" [properties]="{select: {list: duracaoAnosSource}, disallowClear: true}"></pl-edit>
              <div class="input-group-append">
                <span class="input-group-text" [translate]="'mapapagam.texts.anos'"></span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <button *ngIf="isEditMode" type="button" class="btn btn-sm btn-danger me-auto" (click)="promptDelete()">
      <i class="fa fa-fw fa-trash"></i>&nbsp;<span [translate]="'global.btn.delete'"></span>
    </button>
    <pl-button type="button" klass="btn-sm btn-primary action-submit" [onClick]="fnSaveModal" [disabled]="onEditPagMan && !selectedPagMan">
      <i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'global.btn.save'"></span>
    </pl-button>
    <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'users.actions.cancel'"></span></button>
  </div>
</div>
