import {PlEditRegistryService} from 'pl-comps-angular';
import {COMPONENT_NAME_INPUT_ENTITY} from '../core/components/entity/component/input.entity.component.interface';
import {dataSourceInputComponentName} from '../core/components/datasource/input/datasource.input.component.interface';
import {DataSourceInputComponent} from '../core/components/datasource/input/datasource.input.component';
import {InputEntityComponent} from '../core/components/entity/component/input.entity.component';
import {predefinidoContabFormulaComponentName} from '../core/modules/portalcontabilidade/manutencao/predefinidos/components/formula/predefinidocontabformula.component.interface';
import {PredefinidoContabFormulaComponent} from '../core/modules/portalcontabilidade/manutencao/predefinidos/components/formula/predefinidocontabformula.component';
import {reportInputComponentName} from '../core/components/reports/input/reports.input.component.interface';
import {ReportsInputComponent} from '../core/components/reports/input/reports.input.component';

export function configEdits(plEditRegistryService: PlEditRegistryService): void {
  // Components
  plEditRegistryService.register(dataSourceInputComponentName, DataSourceInputComponent);
  plEditRegistryService.register(reportInputComponentName, ReportsInputComponent);
  plEditRegistryService.register(COMPONENT_NAME_INPUT_ENTITY, InputEntityComponent);
  plEditRegistryService.register(predefinidoContabFormulaComponentName, PredefinidoContabFormulaComponent);

  // Aliases
  plEditRegistryService.map('number', 'cgnumber', {allowNegative: false, validators: {min: {value: 1}}});
  plEditRegistryService.map('integer', 'cginteger', {allowNegative: false, thousandsSeparator: '', validators: {min: {value: 1}}});
  plEditRegistryService.map('smallint', 'cgsmallint', {allowNegative: false, thousandsSeparator: '', validators: {min: {value: 1}}});
}
