import {orderBy} from 'lodash-es';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {copy, IPlToolbarItem, IPlToolbarMenuItem, isEmpty, ngClassAdd, ngClassRemove, PlAlertService} from 'pl-comps-angular';
import {CGCardPanelComponent} from '../../../components/cg/cardpanel/cardpanel.component';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {DemFinSNCListSavedModalComponent} from '../modals/listsaved/demFinSNC.listSaved.modal.component';
import {DemFinSNCNotasModalComponent} from '../modals/notas/demFinSNC.notas.modal.component';
import {DemFinSNCObsModalComponent} from '../modals/obs/demFinSNC.obs.modal.component';
import {DemFinSNCPreviewPDFModalComponent} from '../modals/previewpdf/demFinSNC.previewPDF.modal.component';
import {DemFinSNCService} from '../demFinSNC.module.service';
import {devExpressDataGridExpandDetailHandler} from '../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {
  EDFRubricaTipo,
  IJsonDemFinSNCInitialData,
  IJsonDemFinSNCLanguages,
  IJsonDemFinSNCNotas,
  IJsonDemFinSNCPeriodo,
  IJsonDemFinSNCRubrica,
  IJsonDemFinSNCRubricaEX,
  IJsonDemFinSNCRubricaFC,
  IJsonDemFinSNCRubricaMain,
  IJsonDemFinSNCSaved,
  IJsonDemFinSNCTranslatedHeader,
  IJsonDemFinSNCVarApuramento,
  TJsonDemFinSNCRubricaValidationCallbackData
} from '../jsonDemFinSNC.module.interface';
import {ICaptionDataFieldDemFinSNC, IDemFinSNCHeaderProcessed, IDemFinSNCRubricaSaved} from '../demFinSNC.module.interface';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnContentReady,
  IDevExpressDataGridEventOnEditorPreparing,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowUpdated
} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonNumber} from '../../../../common/interfaces/json';
import {IJsonVariaveisApuramento} from '../../portalcontabilidade/manutencao/apuramentoresultados/jsonApuramentoResultados.module.interface';
import {ModuloComponent} from '../../../components/module/module.component';

const DEFAULT_LANGUAGE_PT = 0;
const DEFAULT_LANGUAGE_DESC_PT = 'Português';
const INITIAL_TAB = 'dembalanco';
const TIPO3 = 3;
const TIPO5 = 5;
const TOOLBAR_GROUP_RESPONSIVE = 'module-dem-fin-snc-responsive';

@Component({
  selector: 'module-dem-fin-snc',
  templateUrl: './demFinSNC.module.component.html'
})
export class DemFinSNCModuleComponent extends ModuloComponent implements OnInit {
  @Input() public defaultData: IJsonDemFinSNCInitialData;

  public readonly dataGridDefinitionVarApuramento: IDevExpressDataGrid;
  public readonly dataGridDefinitionVarApuramentoDetail: IDevExpressDataGrid;
  public readonly periodoSourceTemplate: string;
  public readonly anoEmCursoIRC: number;

  public dataGridDemBalancoDefinition: IDevExpressDataGrid;
  public dataGridDemDRNatDefinition: IDevExpressDataGrid;
  public dataGridDemDRFncDefinition: IDevExpressDataGrid;
  public dataGridDemDRFluxCaixaDefinition: IDevExpressDataGrid;
  public dataGridDemAltCPDefinition: IDevExpressDataGrid;
  public dataGridDemAltCP2Definition: IDevExpressDataGrid;
  public dataGridIntContaDefinition: IDevExpressDataGrid;
  public dataGridDetailDefinition: IDevExpressDataGrid;
  public dataGridFluxoCaixaDefinition: IDevExpressDataGrid;

  public variaveisApuramentoN: Array<IJsonVariaveisApuramento>;
  public variaveisApuramentoN1: Array<IJsonVariaveisApuramento>;
  public periodosSource: Array<IJsonDemFinSNCPeriodo>;
  public ano: number;
  public processaFluxos: boolean;
  public processaIntercalares: boolean;
  public verComDados: boolean;
  public periodoItem: IJsonDemFinSNCPeriodo;
  public periodoIntercalares: string;
  public demAltCPCaption: string;
  public demAltCP2Caption: string;
  public tabActiveId: string;
  public showDACP: boolean;
  public canSaveData: boolean;

  private readonly _btnImprimirDemFinanceiras: IPlToolbarItem;
  private readonly _btnProcessar: IPlToolbarItem;
  private readonly _btnAnexoRelatorioGestao: IPlToolbarItem;
  private readonly _btnNotas: IPlToolbarItem;
  private readonly _btnDemFinSncSaved: IPlToolbarItem;
  private readonly _dropdownLanguage: IPlToolbarItem;
  private _initialDemFinSNCRubrica: IJsonDemFinSNCRubricaMain;
  private _demFinSNCRubricaMain: IJsonDemFinSNCRubricaMain;
  private _demFinSNCHeaderProcessed: IDemFinSNCHeaderProcessed;
  private _cardPanel: CGCardPanelComponent;
  private _dataGridInstance: dxDataGrid;
  private _dataGridInstanceVarApuramentoN: dxDataGrid;
  private _dataGridInstanceVarApuramentoN1: dxDataGrid;
  private _captionDataFieldDemFinSNC: ICaptionDataFieldDemFinSNC;
  private _observacoes: string;
  private _tipoLingua: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _demFinSNCService: DemFinSNCService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.defaultData = {anosSource: [], empresaME: false, languages: []};
    this.periodosSource = [];
    this.variaveisApuramentoN = [];
    this.variaveisApuramentoN1 = [];
    this.periodoSourceTemplate = '{{periodo}} - {{nome}}';
    this.processaFluxos = false;
    this.processaIntercalares = false;
    this.periodoIntercalares = '';
    this.verComDados = false;
    this.tabActiveId = INITIAL_TAB;
    this.canSaveData = true;
    this.dataGridDefinitionVarApuramento = {
      allowColumnReordering: false,
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'apuramentoresultados.fields.codConta'},
        {dataField: 'nomeConta', dataType: 'string', caption: 'apuramentoresultados.fields.nomeConta'},
        {dataField: 'total', dataType: 'double', caption: 'apuramentoresultados.fields.total'}
      ],
      dataSource: [],
      filterRow: {visible: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true,
      masterDetail: {
        enabled: true,
        template: 'masterDetailTemplate',
        autoExpandAll: false
      },
      paging: {pageSize: 50},
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbar',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.dataGridDefinitionVarApuramentoDetail = {
      keyExpr: 'nConta',
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'apuramentoresultados.fields.nConta', allowEditing: false},
        {dataField: 'nomeConta', dataType: 'string', caption: 'apuramentoresultados.fields.nome', allowEditing: false},
        {dataField: 'valor', dataType: 'double', caption: 'apuramentoresultados.fields.valor'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      editing: {
        allowUpdating: true,
        mode: 'cell',
        selectTextOnEditStart: true
      },
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {contextMenuEnabled: false},
      groupPanel: {visible: false},
      headerFilter: {visible: false},
      paging: {enabled: false},
      pager: {visible: false},
      repaintChangesOnly: true,
      sorting: {mode: 'none'}
    };
    this.anoEmCursoIRC = this.configurations.empresa.anoEmCursoIRC;
    this.ano = this.anoEmCursoIRC;
    this.showDACP = false;
    this.periodoItem = {
      periodo: '',
      nome: ''
    };
    this._btnImprimirDemFinanceiras = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'imprimirDemonstracaesFinanceiras',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-file-pdf-o"></i>',
      class: undefined,
      caption: 'global.btn.processPDF',
      click: () => this._modalDemFinPreviewPDF(true)
    };
    this._btnProcessar = {
      id: 'processar',
      order: this._btnImprimirDemFinanceiras.order + 1,
      type: 'dropdown-split',
      iconLeft: '<i class="fa fa-table fa-fw"></i>',
      class: 'btn-success',
      caption: 'global.btn.processGrelha',
      menu: [this._btnImprimirDemFinanceiras],
      click: () => this._previsualizarDemFinSNCGrid()
    };
    this._btnAnexoRelatorioGestao = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'previsualizar',
      order: this._btnProcessar.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-file-pdf-o"></i>',
      class: 'btn-success',
      caption: 'demfinsnc.btn.anexoRelatorioGestao',
      click: () => this._modalDemFinPreviewPDF(false)
    };
    this._btnNotas = {
      id: 'notas',
      order: this._btnAnexoRelatorioGestao.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-file-archive-o fa-fw"></i>',
      class: 'btn-secondary',
      caption: 'demfinsnc.btn.notas',
      visible: !this.defaultData.empresaME,
      click: () => this._modalNotasSNC()
    };
    this._btnDemFinSncSaved = {
      id: 'demfinsncsaved',
      order: this._btnNotas.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-history fa-fw"></i>',
      class: 'btn-secondary',
      caption: 'demfinsnc.btn.demfinsncsaved',
      click: () => this._modalDemFinSaved()
    };
    this._dropdownLanguage = {
      id: 'language',
      order: this._btnDemFinSncSaved.order + 1,
      type: 'dropdown',
      class: 'btn-secondary',
      iconLeft: '<i class="fa fa-fw fa-language"></i>',
      caption: 'global.menu.language',
      menu: []
    };
    this._initialDemFinSNCRubrica = {
      me: false,
      loadedID: undefined,
      anoNCaption: '',
      anoN1Caption: '',
      rubricasBalanco: [],
      rubricasDRNat: [],
      rubricasDRFnc: [],
      rubricasFluxosCaixa: [],
      rubricasCapPropAnoN: [],
      rubricasCapPropAnoN1: [],
      demFinSNCVarApuramento: {apResultN: [], apResultN1: []},
      demFinSNCHeaderByLang: []
    };
    this._demFinSNCRubricaMain = {
      me: false,
      loadedID: undefined,
      anoNCaption: '',
      anoN1Caption: '',
      rubricasBalanco: [],
      rubricasDRNat: [],
      rubricasDRFnc: [],
      rubricasFluxosCaixa: [],
      rubricasCapPropAnoN: [],
      rubricasCapPropAnoN1: [],
      demFinSNCVarApuramento: {apResultN: [], apResultN1: []},
      demFinSNCHeaderByLang: []
    };
    this._demFinSNCHeaderProcessed = {
      ano: this.ano,
      processaFluxos: false,
      processaIntercalares: false,
      verComDados: false,
      periodoIntercalares: '',
      variaveisApuramento: {apResultN: [], apResultN1: []},
      idioma: DEFAULT_LANGUAGE_PT
    };
    this._observacoes = '';
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._tipoLingua = DEFAULT_LANGUAGE_PT;
    this._generateDataGridDefinitions('', '');
    if (!this.defaultData.anosSource.includes(this.anoEmCursoIRC)) {
      this.defaultData.anosSource.push(this.anoEmCursoIRC);
    }
    if (!this.defaultData.anosSource.includes(this.anoEmCursoIRC + 1)) {
      this.defaultData.anosSource.push(this.anoEmCursoIRC + 1);
    }
    for (const language of this.defaultData.languages) {
      this._dropdownLanguage.menu.push({
        caption: language.nome,
        data: language,
        click: (menuItem: IPlToolbarMenuItem<IJsonDemFinSNCLanguages>) => {
          this.languageChanged(menuItem.data);
        }
      });
    }
    this._dropdownLanguage.caption = this._captionLanguage(DEFAULT_LANGUAGE_DESC_PT);
    this.toolbar.addButton(this._btnProcessar).addButton(this._btnAnexoRelatorioGestao).addButton(this._dropdownLanguage);
    this.dropdownActions.order = this._dropdownLanguage.order + 1;
    this.setModuleActions([this._btnNotas, this._btnDemFinSncSaved]);
    this._evaluateToolbar();
  }

  public setIsMobile(value: boolean): void {
    super.setIsMobile(value);
    if (this.toolbar) {
      this._evaluateToolbar();
    }
  }

  public languageChanged(value: IJsonDemFinSNCLanguages): void {
    this._tipoLingua = value.tipoLingua;
    this._dropdownLanguage.caption = this._captionLanguage(value.nome);
  }

  public reporValoresOriginais(): void {
    this._demFinSNCRubricaMain = copy(this._initialDemFinSNCRubrica);
    this._generateDataGridData(this._initialDemFinSNCRubrica.anoNCaption, this._initialDemFinSNCRubrica.anoN1Caption);
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onTabChanged(): void {
    this.canSaveData = true;
  }

  public onContentReady({component}: IDevExpressDataGridEventOnContentReady): void {
    this._dataGridInstance.beginCustomLoading(undefined);
    const dataSource: DataSource = this._getActiveDataSource();
    if (dataSource) {
      dataSource.load().then((rubricas: Array<IJsonDemFinSNCRubrica | IJsonDemFinSNCRubricaEX | IJsonDemFinSNCRubricaFC>) => {
        for (const rubrica of rubricas) {
          if (rubrica.tipo === EDFRubricaTipo.Rubrica) {
            if (this.tabActiveId === 'demAltCP' || this.tabActiveId === 'demAltCP2') {
              const cellElementCapitalRealizado: HTMLElement = component.getCellElement(rubrica.positionIdx, 'capitalRealizado');
              if ((<IJsonDemFinSNCRubricaEX>rubrica).capitalRealizadoEdited) {
                if (!cellElementCapitalRealizado.classList.contains('bg-warning')) {
                  cellElementCapitalRealizado.classList.add('bg-warning');
                }
              } else if (cellElementCapitalRealizado.classList.contains('bg-warning')) {
                cellElementCapitalRealizado.classList.remove('bg-warning');
              }

              const cellElementAccoesQuotasProprias: HTMLElement = component.getCellElement(rubrica.positionIdx, 'accoesQuotasProprias');
              if ((<IJsonDemFinSNCRubricaEX>rubrica).accoesQuotasPropriasEdited) {
                if (!cellElementAccoesQuotasProprias.classList.contains('bg-warning')) {
                  cellElementAccoesQuotasProprias.classList.add('bg-warning');
                }
              } else if (cellElementAccoesQuotasProprias.classList.contains('bg-warning')) {
                cellElementAccoesQuotasProprias.classList.remove('bg-warning');
              }

              const cellElementOutrosInstrumentosCapProprio: HTMLElement = component.getCellElement(rubrica.positionIdx, 'outrosInstrumentosCapProprio');
              if ((<IJsonDemFinSNCRubricaEX>rubrica).outrosInstrumentosCapProprioEdited) {
                if (!cellElementOutrosInstrumentosCapProprio.classList.contains('bg-warning')) {
                  cellElementOutrosInstrumentosCapProprio.classList.add('bg-warning');
                }
              } else if (cellElementOutrosInstrumentosCapProprio.classList.contains('bg-warning')) {
                cellElementOutrosInstrumentosCapProprio.classList.remove('bg-warning');
              }

              const cellElementPremiosDeEmissao: HTMLElement = component.getCellElement(rubrica.positionIdx, 'premiosDeEmissao');
              if ((<IJsonDemFinSNCRubricaEX>rubrica).premiosDeEmissaoEdited) {
                if (!cellElementPremiosDeEmissao.classList.contains('bg-warning')) {
                  cellElementPremiosDeEmissao.classList.add('bg-warning');
                }
              } else if (cellElementPremiosDeEmissao.classList.contains('bg-warning')) {
                cellElementPremiosDeEmissao.classList.remove('bg-warning');
              }

              const cellElementReservasLegais: HTMLElement = component.getCellElement(rubrica.positionIdx, 'reservasLegais');
              if ((<IJsonDemFinSNCRubricaEX>rubrica).reservasLegaisEdited) {
                if (!cellElementReservasLegais.classList.contains('bg-warning')) {
                  cellElementReservasLegais.classList.add('bg-warning');
                }
              } else if (cellElementReservasLegais.classList.contains('bg-warning')) {
                cellElementReservasLegais.classList.remove('bg-warning');
              }

              const cellElementOutrasReservas: HTMLElement = component.getCellElement(rubrica.positionIdx, 'outrasReservas');
              if ((<IJsonDemFinSNCRubricaEX>rubrica).outrasReservasEdited) {
                if (!cellElementOutrasReservas.classList.contains('bg-warning')) {
                  cellElementOutrasReservas.classList.add('bg-warning');
                }
              } else if (cellElementOutrasReservas.classList.contains('bg-warning')) {
                cellElementOutrasReservas.classList.remove('bg-warning');
              }

              const cellElementResultadosTransitados: HTMLElement = component.getCellElement(rubrica.positionIdx, 'resultadosTransitados');
              if ((<IJsonDemFinSNCRubricaEX>rubrica).resultadosTransitadosEdited) {
                if (!cellElementResultadosTransitados.classList.contains('bg-warning')) {
                  cellElementResultadosTransitados.classList.add('bg-warning');
                }
              } else if (cellElementResultadosTransitados.classList.contains('bg-warning')) {
                cellElementResultadosTransitados.classList.remove('bg-warning');
              }

              const cellElementAjustamentosEmActivosFinanceirosOutrasVariacoes: HTMLElement = component.getCellElement(rubrica.positionIdx, 'ajustamentosEmActivosFinanceirosOutrasVariacoes');
              if ((<IJsonDemFinSNCRubricaEX>rubrica).ajustamentosEmActivosFinanceirosOutrasVariacoesEdited) {
                if (!cellElementAjustamentosEmActivosFinanceirosOutrasVariacoes.classList.contains('bg-warning')) {
                  cellElementAjustamentosEmActivosFinanceirosOutrasVariacoes.classList.add('bg-warning');
                }
              } else if (cellElementAjustamentosEmActivosFinanceirosOutrasVariacoes.classList.contains('bg-warning')) {
                cellElementAjustamentosEmActivosFinanceirosOutrasVariacoes.classList.remove('bg-warning');
              }

              const cellElementExcedentesDeRevalorizacao: HTMLElement = component.getCellElement(rubrica.positionIdx, 'excedentesDeRevalorizacao');
              if ((<IJsonDemFinSNCRubricaEX>rubrica).excedentesDeRevalorizacaoEdited) {
                if (!cellElementExcedentesDeRevalorizacao.classList.contains('bg-warning')) {
                  cellElementExcedentesDeRevalorizacao.classList.add('bg-warning');
                }
              } else if (cellElementExcedentesDeRevalorizacao.classList.contains('bg-warning')) {
                cellElementExcedentesDeRevalorizacao.classList.remove('bg-warning');
              }

              const cellElementResultadoLiquidoDoPeriodo: HTMLElement = component.getCellElement(rubrica.positionIdx, 'resultadoLiquidoDoPeriodo');
              if ((<IJsonDemFinSNCRubricaEX>rubrica).resultadoLiquidoDoPeriodoEdited) {
                if (!cellElementResultadoLiquidoDoPeriodo.classList.contains('bg-warning')) {
                  cellElementResultadoLiquidoDoPeriodo.classList.add('bg-warning');
                }
              } else if (cellElementResultadoLiquidoDoPeriodo.classList.contains('bg-warning')) {
                cellElementResultadoLiquidoDoPeriodo.classList.remove('bg-warning');
              }
            } else {
              const cellElementAnoN: HTMLElement = component.getCellElement(rubrica.positionIdx, 'anoN');
              if (rubrica.anoNEdited) {
                if (!cellElementAnoN.classList.contains('bg-warning')) {
                  cellElementAnoN.classList.add('bg-warning');
                }
              } else if (cellElementAnoN.classList.contains('bg-warning')) {
                cellElementAnoN.classList.remove('bg-warning');
              }

              const cellElementAnoN1: HTMLElement = component.getCellElement(rubrica.positionIdx, 'anoN1');
              if (rubrica.anoN1Edited) {
                if (!cellElementAnoN1.classList.contains('bg-warning')) {
                  cellElementAnoN1.classList.add('bg-warning');
                }
              } else if (cellElementAnoN1.classList.contains('bg-warning')) {
                cellElementAnoN1.classList.remove('bg-warning');
              }
            }
          }
        }

        this._dataGridInstance.endCustomLoading();
      });
    }
  }

  public onEditorPreparing(event: IDevExpressDataGridEventOnEditorPreparing<IJsonDemFinSNCRubrica>): void {
    if (event.parentType === 'dataRow' && event.row.data.tipo !== EDFRubricaTipo.Rubrica) {
      event.editorOptions.disabled = true;
    }
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonDemFinSNCRubrica>): void {
    if (event.rowType === 'data') {
      if (event.data.tipo === EDFRubricaTipo.Sep) {
        event.cellElement.classList.add('df-rubrica-tipo-sep');
      }
      if (
        event.data.tipo === EDFRubricaTipo.SubRubricaTotal ||
        event.data.tipo === EDFRubricaTipo.MediaRubricaTotal ||
        event.data.tipo === EDFRubricaTipo.GrandeRubricaTotal ||
        event.data.tipo === EDFRubricaTipo.GeralTotal
      ) {
        event.cellElement.classList.add('df-rubrica-tipo-total');
        if (event.column.type === 'detailExpand') {
          event.cellElement.classList.add('df-rubrica-tipo-sep');
        }
        if (event.column.dataField === 'notas') {
          event.cellElement.classList.add('df-rubrica-tipo-total-notas');
        }
      }
      if (event.data.tipo === EDFRubricaTipo.SubRubricaCaption || event.data.tipo === EDFRubricaTipo.MediaRubricaCaption || event.data.tipo === EDFRubricaTipo.GrandeRubricaCaption) {
        event.cellElement.classList.add('df-rubrica-tipo-caption');
        if (event.column.dataField !== 'descricao') {
          event.cellElement.classList.add('df-rubrica-tipo-sep');
        }
      }
    }
  }

  public onRowUpdated(event: IDevExpressDataGridEventOnRowUpdated<IJsonDemFinSNCRubrica>): Promise<void> {
    const auxRubricas: Array<IJsonDemFinSNCRubrica | IJsonDemFinSNCRubricaEX | IJsonDemFinSNCRubricaFC> = this._getActiveRubricaAux();

    if (this.tabActiveId === 'demAltCP' || this.tabActiveId === 'demAltCP2') {
      (<IJsonDemFinSNCRubricaEX>event.data).capitalRealizadoEdited =
        (<IJsonDemFinSNCRubricaEX>auxRubricas[event.data.positionIdx]).capitalRealizado !== (<IJsonDemFinSNCRubricaEX>event.data).capitalRealizado;

      (<IJsonDemFinSNCRubricaEX>event.data).accoesQuotasPropriasEdited =
        (<IJsonDemFinSNCRubricaEX>auxRubricas[event.data.positionIdx]).accoesQuotasProprias !== (<IJsonDemFinSNCRubricaEX>event.data).accoesQuotasProprias;

      (<IJsonDemFinSNCRubricaEX>event.data).outrosInstrumentosCapProprioEdited =
        (<IJsonDemFinSNCRubricaEX>auxRubricas[event.data.positionIdx]).outrosInstrumentosCapProprio !== (<IJsonDemFinSNCRubricaEX>event.data).outrosInstrumentosCapProprio;

      (<IJsonDemFinSNCRubricaEX>event.data).premiosDeEmissaoEdited =
        (<IJsonDemFinSNCRubricaEX>auxRubricas[event.data.positionIdx]).premiosDeEmissao !== (<IJsonDemFinSNCRubricaEX>event.data).premiosDeEmissao;

      (<IJsonDemFinSNCRubricaEX>event.data).reservasLegaisEdited =
        (<IJsonDemFinSNCRubricaEX>auxRubricas[event.data.positionIdx]).reservasLegais !== (<IJsonDemFinSNCRubricaEX>event.data).reservasLegais;

      (<IJsonDemFinSNCRubricaEX>event.data).outrasReservasEdited =
        (<IJsonDemFinSNCRubricaEX>auxRubricas[event.data.positionIdx]).outrasReservas !== (<IJsonDemFinSNCRubricaEX>event.data).outrasReservas;

      (<IJsonDemFinSNCRubricaEX>event.data).resultadosTransitadosEdited =
        (<IJsonDemFinSNCRubricaEX>auxRubricas[event.data.positionIdx]).resultadosTransitados !== (<IJsonDemFinSNCRubricaEX>event.data).resultadosTransitados;

      (<IJsonDemFinSNCRubricaEX>event.data).ajustamentosEmActivosFinanceirosOutrasVariacoesEdited =
        (<IJsonDemFinSNCRubricaEX>auxRubricas[event.data.positionIdx]).ajustamentosEmActivosFinanceirosOutrasVariacoes !==
        (<IJsonDemFinSNCRubricaEX>event.data).ajustamentosEmActivosFinanceirosOutrasVariacoes;

      (<IJsonDemFinSNCRubricaEX>event.data).excedentesDeRevalorizacaoEdited =
        (<IJsonDemFinSNCRubricaEX>auxRubricas[event.data.positionIdx]).excedentesDeRevalorizacao !== (<IJsonDemFinSNCRubricaEX>event.data).excedentesDeRevalorizacao;

      (<IJsonDemFinSNCRubricaEX>event.data).resultadoLiquidoDoPeriodoEdited =
        (<IJsonDemFinSNCRubricaEX>auxRubricas[event.data.positionIdx]).resultadoLiquidoDoPeriodo !== (<IJsonDemFinSNCRubricaEX>event.data).resultadoLiquidoDoPeriodo;
    } else {
      event.data.anoNEdited = auxRubricas[event.data.positionIdx].anoN !== event.data.anoN;
      event.data.anoN1Edited = auxRubricas[event.data.positionIdx].anoN1 !== event.data.anoN1;
    }

    this._calculateTotals(event.component.getDataSource());
    return Promise.resolve();
  }

  public onInitializedVariaveisApuramentoN({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstanceVarApuramentoN = component;
  }

  public onInitializedVariaveisApuramentoN1({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstanceVarApuramentoN1 = component;
  }

  public onDataGridVariaveisApuramentoCellClick(event: IDevExpressDataGridEventOnCellClick<IJsonVariaveisApuramento>): void {
    devExpressDataGridExpandDetailHandler(event);
  }

  public changeValorParentRow(item: IJsonVariaveisApuramento): void {
    item.total = 0;
    if (item.valoresInventario) {
      for (const itemValor of item.valoresInventario) {
        item.total += itemValor.valor;
      }
    }
  }

  public periodoIntercalaresChanged(value: IJsonDemFinSNCPeriodo): Promise<void> {
    if (!value || isEmpty(value.periodo)) {
      this.variaveisApuramentoN = [];
      this.variaveisApuramentoN1 = [];
      this.periodoIntercalares = '';
      this.periodoItem = {periodo: '', nome: ''};
      return Promise.resolve();
    }

    this.periodoIntercalares = value.periodo;
    this.periodoItem = value;
    if (this._dataGridInstanceVarApuramentoN) {
      this._dataGridInstanceVarApuramentoN.beginCustomLoading(undefined);
    }
    if (this._dataGridInstanceVarApuramentoN1) {
      this._dataGridInstanceVarApuramentoN1.beginCustomLoading(undefined);
    }
    return this._demFinSNCService
      .getVariaveisApuramento(this.periodoIntercalares)
      .then((response: HttpResponse<IJsonDemFinSNCVarApuramento>) => {
        this.variaveisApuramentoN = response.body.apResultN;
        this.variaveisApuramentoN1 = response.body.apResultN1;
      })
      .finally(() => {
        this._dataGridInstanceVarApuramentoN.endCustomLoading();
        this._dataGridInstanceVarApuramentoN1.endCustomLoading();
      });
  }

  public async processaIntercalaresChanged(value: boolean): Promise<void> {
    this.processaIntercalares = value;
    await this._getListaPeriodosIntercalares();
    return Promise.resolve();
  }

  public anoChanged(): void {
    if (this.ano !== this.anoEmCursoIRC) {
      this.processaIntercalares = false;
      this.periodoIntercalares = '';
      this.variaveisApuramentoN = [];
      this.variaveisApuramentoN1 = [];
    }
  }

  public changedResizerValues(): void {
    if (this._dataGridInstanceVarApuramentoN) {
      this._dataGridInstanceVarApuramentoN.updateDimensions();
    }
    if (this._dataGridInstanceVarApuramentoN1) {
      this._dataGridInstanceVarApuramentoN1.updateDimensions();
    }
  }

  public readonly fnSaveDemFinSNC = (): Promise<void> => this._saveDemFinSNC();

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _evaluateToolbar(): void {
    this.toolbar.removeGroupId(TOOLBAR_GROUP_RESPONSIVE);
    this.dropdownActions.visible = true;
    this._btnProcessar.menu = [this._btnImprimirDemFinanceiras];
    if (!this.isMobile) {
      this.toolbar.addButton(this._btnAnexoRelatorioGestao);
      this._btnAnexoRelatorioGestao.class = <string>ngClassAdd(this._btnAnexoRelatorioGestao.class, 'btn-success');
    } else {
      this._btnAnexoRelatorioGestao.class = <string>ngClassRemove(this._btnAnexoRelatorioGestao.class, 'btn-success');
      this._btnProcessar.menu.push(this._btnAnexoRelatorioGestao);
    }
  }

  private _captionLanguage(descLanguage): string {
    return this._translateService.instant('demfinsnc.language.idioma', {idioma: descLanguage});
  }

  private _previsualizarDemFinSNCGrid(): Promise<void> {
    this._observacoes = '';
    if (this.processaIntercalares && isEmpty(this.periodoIntercalares)) {
      this._plAlertService.error('demfinsnc.messages.periodoempty');
      return Promise.resolve();
    }
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }

    const varApuramento: IJsonDemFinSNCVarApuramento = {apResultN: this.variaveisApuramentoN, apResultN1: this.variaveisApuramentoN1};
    return this._demFinSNCService
      .listDemFinSNC(this.ano, this.processaFluxos, this.verComDados, this.processaIntercalares, this.periodoIntercalares, this._tipoLingua, varApuramento)
      .then((response: HttpResponse<IJsonDemFinSNCRubricaMain>) => {
        this._cardPanel.collapse();
        this._demFinSNCHeaderProcessed = {
          ano: this.ano,
          processaFluxos: this.processaFluxos,
          processaIntercalares: this.processaIntercalares,
          verComDados: this.verComDados,
          periodoIntercalares: this.periodoIntercalares,
          variaveisApuramento: varApuramento,
          idioma: this._tipoLingua
        };
        this.showDACP = !this.processaIntercalares;
        if (this.verComDados) {
          response.body.rubricasBalanco = response.body.rubricasBalanco.filter((value: IJsonDemFinSNCRubrica) => value.anoN !== 0 || value.anoN1 !== 0 || value.tipo === TIPO3 || value.tipo === TIPO5);
          response.body.rubricasDRNat = response.body.rubricasDRNat.filter((value: IJsonDemFinSNCRubrica) => value.anoN !== 0 || value.anoN1 !== 0 || value.tipo === TIPO3 || value.tipo === TIPO5);
          response.body.rubricasDRFnc = response.body.rubricasDRFnc.filter((value: IJsonDemFinSNCRubrica) => value.anoN !== 0 || value.anoN1 !== 0 || value.tipo === TIPO3 || value.tipo === TIPO5);
          response.body.rubricasFluxosCaixa = response.body.rubricasFluxosCaixa.filter(
            (value: IJsonDemFinSNCRubricaFC) => value.anoN !== 0 || value.anoN1 !== 0 || value.tipo === TIPO3 || value.tipo === TIPO5
          );
          response.body.rubricasCapPropAnoN = response.body.rubricasCapPropAnoN.filter((value: IJsonDemFinSNCRubricaEX) => value.total !== 0 || value.tipo === TIPO3 || value.tipo === TIPO5);
          response.body.rubricasCapPropAnoN1 = response.body.rubricasCapPropAnoN1.filter((value: IJsonDemFinSNCRubricaEX) => value.total !== 0 || value.tipo === TIPO3 || value.tipo === TIPO5);
        }
        this._demFinSNCRubricaMain = response.body;
        this._initialDemFinSNCRubrica = copy(this._demFinSNCRubricaMain);
        this._generateDataGridData(this._demFinSNCRubricaMain.anoNCaption, this._demFinSNCRubricaMain.anoN1Caption);
      })
      .finally(() => {
        if (this._dataGridInstance) {
          this._dataGridInstance.endCustomLoading();
        }
      });
  }

  private _getActiveRubricaAux(): Array<IJsonDemFinSNCRubrica | IJsonDemFinSNCRubricaEX | IJsonDemFinSNCRubricaFC> {
    switch (this.tabActiveId) {
      case 'demresultNat':
        return this._initialDemFinSNCRubrica.rubricasDRNat;
      case 'demresultFunc':
        return this._initialDemFinSNCRubrica.rubricasDRFnc;
      case 'demfluxocaixa':
        return this._initialDemFinSNCRubrica.rubricasFluxosCaixa;
      case 'demAltCP':
        return this._initialDemFinSNCRubrica.rubricasCapPropAnoN;
      case 'demAltCP2':
        return this._initialDemFinSNCRubrica.rubricasCapPropAnoN1;
      default:
        return this._initialDemFinSNCRubrica.rubricasBalanco;
    }
  }

  private _getActiveDataSource(): DataSource {
    switch (this.tabActiveId) {
      case 'demresultNat':
        return <DataSource>this.dataGridDemDRNatDefinition.dataSource;
      case 'demresultFunc':
        return <DataSource>this.dataGridDemDRFncDefinition.dataSource;
      case 'demfluxocaixa':
        return <DataSource>this.dataGridDemDRFluxCaixaDefinition.dataSource;
      case 'demAltCP':
        return <DataSource>this.dataGridDemAltCPDefinition.dataSource;
      case 'demAltCP2':
        return <DataSource>this.dataGridDemAltCP2Definition.dataSource;
      default:
        return <DataSource>this.dataGridDemBalancoDefinition.dataSource;
    }
  }

  private _modalNotasSNC(): Promise<void> {
    return this._demFinSNCService.getNotasDemFinSNC().then((response: HttpResponse<IJsonDemFinSNCNotas>) => {
      const modalInstance: NgbModalRef = this._cgModalService.showVanilla(DemFinSNCNotasModalComponent, {size: 'xxl'});
      const componentInstance: DemFinSNCNotasModalComponent = modalInstance.componentInstance;
      componentInstance.notas = response.body;
      modalInstance.result.then(() => {
        return this._previsualizarDemFinSNCGrid();
      });
    });
  }

  private _modalDemFinSaved(): Promise<void> {
    return this._demFinSNCService.getDemFinSNCSavedList().then((response: HttpResponse<Array<IJsonDemFinSNCSaved>>) => {
      const modalInstance: NgbModalRef = this._cgModalService.showVanilla(DemFinSNCListSavedModalComponent, {size: 'xxl'});
      const componentInstance: DemFinSNCListSavedModalComponent = modalInstance.componentInstance;
      componentInstance.listSaved = response.body;
      componentInstance.idDemFinSNC = this._demFinSNCRubricaMain.loadedID;
      modalInstance.result.then(async (result: IDemFinSNCRubricaSaved) => {
        this._demFinSNCRubricaMain = result;
        this.ano = result.ano;
        this.processaIntercalares = result.isProcessaIntercalares;
        this.processaFluxos = false;
        await this._getListaPeriodosIntercalares();
        this.periodoItem = this.periodosSource.find((item: IJsonDemFinSNCPeriodo) => item.periodo === result.periodoIntercalares);
        await this.periodoIntercalaresChanged(this.periodoItem);
        this.periodoIntercalares = '';
        this._generateDataGridData(this._demFinSNCRubricaMain.anoNCaption, this._demFinSNCRubricaMain.anoN1Caption);
      });
    });
  }

  private _modalDemFinPreviewPDF(isModalImprimirDemonstracoesFinanceiras: boolean): Promise<void> {
    const varApuramento: IJsonDemFinSNCVarApuramento = {apResultN: this.variaveisApuramentoN, apResultN1: this.variaveisApuramentoN1};
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(DemFinSNCPreviewPDFModalComponent, {size: 'xxl'});
    const componentInstance: DemFinSNCPreviewPDFModalComponent = modalInstance.componentInstance;
    componentInstance.ano = this.ano;
    componentInstance.processaFluxos = this.processaFluxos;
    componentInstance.verComDados = this.verComDados;
    componentInstance.processaIntercalares = this.processaIntercalares;
    componentInstance.periodoIntercalares = this.periodoIntercalares;
    componentInstance.idioma = this._tipoLingua;
    componentInstance.loadedID = this._demFinSNCRubricaMain.loadedID;
    componentInstance.varApuramento = varApuramento;
    componentInstance.selectedModalImprimirReports = isModalImprimirDemonstracoesFinanceiras;
    return modalInstance.result;
  }

  private _generateDataGridData(captionAnoN: string, captionAnoN1: string): void {
    const dataDemAltCP = isEmpty(captionAnoN) ? `31/12/${this.ano}` : captionAnoN;
    const dataDemAltCP2 = isEmpty(captionAnoN1) ? `31/12/${this.ano - 1}` : captionAnoN1;
    this.demAltCPCaption = this._translateService.instant('demfinsnc.tabs.demAltCPTitle', {dataDemAltCP: dataDemAltCP});
    this.demAltCP2Caption = this._translateService.instant('demfinsnc.tabs.demAltCP2Title', {dataDemAltCP2: dataDemAltCP2});
    this._generateDataGridDefinitions(captionAnoN, captionAnoN1);
    this.dataGridDemBalancoDefinition.dataSource = new ArrayStore({key: 'positionIdx', data: this._demFinSNCRubricaMain.rubricasBalanco});
    // this._applyDataSource(this.dataGridDemBalancoDefinition.dataSource, this._demFinSNCRubricaMain.rubricasBalanco);
    this.dataGridDemDRNatDefinition.dataSource = new ArrayStore({key: 'positionIdx', data: this._demFinSNCRubricaMain.rubricasDRNat});
    this.dataGridDemDRFncDefinition.dataSource = new ArrayStore({key: 'positionIdx', data: this._demFinSNCRubricaMain.rubricasDRFnc});
    this.dataGridDemDRFluxCaixaDefinition.dataSource = new ArrayStore({key: 'positionIdx', data: this._demFinSNCRubricaMain.rubricasFluxosCaixa});
    this.dataGridDemAltCPDefinition.dataSource = new ArrayStore({key: 'positionIdx', data: this._demFinSNCRubricaMain.rubricasCapPropAnoN});
    this.dataGridDemAltCP2Definition.dataSource = new ArrayStore({key: 'positionIdx', data: this._demFinSNCRubricaMain.rubricasCapPropAnoN1});
    this.dataGridIntContaDefinition = this._generateDataGridDetails(captionAnoN, captionAnoN1, true, true);
    this.dataGridDetailDefinition = this._generateDataGridDetails(captionAnoN, captionAnoN1, false, false, true);
    this.dataGridFluxoCaixaDefinition = this._generateDataGridDetails(captionAnoN, captionAnoN1, false, false, false, true);
  }

  private _generateDataGridDefinitions(captionAnoN: string, captionAnoN1: string): void {
    const dataDemAltCP = isEmpty(captionAnoN) ? `31/12/${this.ano}` : captionAnoN;
    const dataDemAltCP2 = isEmpty(captionAnoN1) ? `31/12/${this.ano - 1}` : captionAnoN1;
    this.dataGridDemBalancoDefinition = this._generateDataGridDefinition(dataDemAltCP, dataDemAltCP2, 'demfinsnc.tabs.balancoTitle', 'masterDetailTemplateDemBalanco');
    this.dataGridDemDRNatDefinition = this._generateDataGridDefinition(dataDemAltCP, dataDemAltCP2, 'demfinsnc.tabs.demresultNatTitle', 'masterDetailTemplateDemDRNat');
    this.dataGridDemDRFncDefinition = this._generateDataGridDefinition(dataDemAltCP, dataDemAltCP2, 'demfinsnc.tabs.demresultNatTitle', 'masterDetailTemplateDemDRFunc');
    this.dataGridDemDRFluxCaixaDefinition = this._generateDataGridDefinition(dataDemAltCP, dataDemAltCP2, 'demfinsnc.tabs.demresultNatTitle', 'masterDetailTemplateDemDRFluxoCaixa');
    this.dataGridDemAltCPDefinition = this._generateDataGridDefinition(dataDemAltCP, dataDemAltCP2, 'demfinsnc.tabs.demresultNatTitle', 'masterDetailTemplateDemAltCP', true);
    this.dataGridDemAltCP2Definition = this._generateDataGridDefinition(dataDemAltCP, dataDemAltCP2, 'demfinsnc.tabs.demresultNatTitle', 'masterDetailTemplateDemAltCP2', true);
  }

  private _validateGrid(options: IJsonDemFinSNCRubrica): boolean {
    this.canSaveData = /^[0-9]+[,.]{0,1}[0-9]*$/.test(options.notas);
    return this.canSaveData;
  }

  private _generateDataGridDefinition(captionAnoN: string, captionAnoN1: string, exportTitle: string, masterDetailTemplate: string, toCapitalProp: boolean = false): IDevExpressDataGrid {
    this._generateCaptionDataFields();
    let columns: Array<IDevExpressDataGridColumn>;
    if (!toCapitalProp) {
      columns = [
        {dataField: 'descricao', dataType: 'string', caption: this._captionDataFieldDemFinSNC.rubrica, allowEditing: false},
        {
          dataField: 'notas',
          dataType: 'string',
          caption: this._captionDataFieldDemFinSNC.notas,
          visible: !this.defaultData.empresaME,
          showInColumnChooser: !this.defaultData.empresaME,
          validationRules: [
            {
              type: 'custom',
              ignoreEmptyValue: true,
              message: this._translateService.instant('demfinsnc.messages.notastemdeserumnumero'),
              validationCallback: (options: TJsonDemFinSNCRubricaValidationCallbackData): boolean => this._validateGrid(options.data)
            }
          ]
        },
        {dataField: 'anoN', dataType: 'double', caption: captionAnoN},
        {dataField: 'anoN1', dataType: 'double', caption: captionAnoN1}
      ];
    } else {
      columns = [
        {dataField: 'descricao', dataType: 'string', caption: this._captionDataFieldDemFinSNC.rubrica, allowEditing: false},
        {
          dataField: 'notas',
          dataType: 'string',
          caption: this._captionDataFieldDemFinSNC.notas,
          visible: !this.defaultData.empresaME,
          showInColumnChooser: !this.defaultData.empresaME,
          validationRules: [
            {
              type: 'custom',
              ignoreEmptyValue: true,
              message: this._translateService.instant('demfinsnc.messages.notastemdeserumnumero'),
              validationCallback: (options: TJsonDemFinSNCRubricaValidationCallbackData): boolean => this._validateGrid(options.data)
            }
          ]
        },
        {dataField: 'capitalRealizado', dataType: 'double', caption: this._captionDataFieldDemFinSNC.capitalRealizado},
        {dataField: 'accoesQuotasProprias', dataType: 'double', caption: this._captionDataFieldDemFinSNC.accoesQuotasProprias},
        {dataField: 'outrosInstrumentosCapProprio', dataType: 'double', caption: this._captionDataFieldDemFinSNC.outrosInstrumentosCapProprio},
        {dataField: 'premiosDeEmissao', dataType: 'double', caption: this._captionDataFieldDemFinSNC.premiosDeEmissao},
        {dataField: 'reservasLegais', dataType: 'double', caption: this._captionDataFieldDemFinSNC.reservasLegais},
        {dataField: 'outrasReservas', dataType: 'double', caption: this._captionDataFieldDemFinSNC.outrasReservas},
        {dataField: 'resultadosTransitados', dataType: 'double', caption: this._captionDataFieldDemFinSNC.resultadosTransitados},
        {dataField: 'ajustamentosEmActivosFinanceirosOutrasVariacoes', dataType: 'double', caption: this._captionDataFieldDemFinSNC.ajustamentosEmActivosFinanceirosOutrasVariacoes},
        {dataField: 'excedentesDeRevalorizacao', dataType: 'double', caption: this._captionDataFieldDemFinSNC.excedentesDeRevalorizacao},
        {dataField: 'resultadoLiquidoDoPeriodo', dataType: 'double', caption: this._captionDataFieldDemFinSNC.resultadoLiquidoDoPeriodo},
        {dataField: 'total', dataType: 'double', caption: this._captionDataFieldDemFinSNC.total, allowEditing: false}
      ];
    }

    return {
      columnHidingEnabled: false,
      columns: columns,
      dataSource: new ArrayStore({key: 'positionIdx', data: []}),
      editing: {allowUpdating: true, mode: 'cell', selectTextOnEditStart: true, startEditAction: 'click'},
      export: {filename: exportTitle, enabledPdf: false},
      filterRow: {visible: false},
      masterDetail: {enabled: true, template: masterDetailTemplate},
      paging: {enabled: false},
      sorting: {mode: 'none'},
      showColumnLines: false,
      keyboardNavigation: {editOnKeyPress: false, enterKeyAction: 'moveFocus', enterKeyDirection: 'column'},
      remoteOperations: false,
      repaintChangesOnly: true,
      toolbar: {
        items: [
          {
            location: 'after',
            template: 'templateToolbarBadgeCellManualEdited',
            locateInMenu: 'auto'
          },
          {
            location: 'before',
            template: 'templateToolbarBtnReporDados',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
  }

  private _generateDataGridDetails(
    captionAnoN: string,
    captionAnoN1: string,
    hasMasterDetail: boolean,
    isIntConta: boolean = false,
    isDetail: boolean = false,
    isFluxoCaixa: boolean = false
  ): IDevExpressDataGrid {
    let columns: Array<IDevExpressDataGridColumn>;
    if (isIntConta) {
      columns = [
        {dataField: 'nContaDe', dataType: 'string', caption: 'demfinsnc.tabs.table.nContaDe'},
        {dataField: 'nContaAte', dataType: 'string', caption: 'demfinsnc.tabs.table.nContaAte'},
        {dataField: 'mesPeriodo', dataType: 'string', caption: 'demfinsnc.tabs.table.mesPeriodo'}
      ];
    } else if (isDetail) {
      columns = [
        {dataField: 'nConta', dataType: 'string', caption: 'demfinsnc.tabs.table.nConta'},
        {dataField: 'nomeConta', dataType: 'string', caption: 'demfinsnc.tabs.table.nomeConta'}
      ];
    } else if (isFluxoCaixa) {
      columns = [
        {dataField: 'codFluxo', dataType: 'string', caption: 'demfinsnc.tabs.table.codFluxo'},
        {dataField: 'designacao', dataType: 'string', caption: 'demfinsnc.tabs.table.designacao'}
      ];
    }

    const dataDemAltCP = isEmpty(captionAnoN) ? `31/12/${this.ano}` : captionAnoN;
    const dataDemAltCP2 = isEmpty(captionAnoN1) ? `31/12/${this.ano - 1}` : captionAnoN1;
    columns.push({dataField: 'anoN', dataType: 'double', caption: dataDemAltCP}, {dataField: 'anoN1', dataType: 'double', caption: dataDemAltCP2});
    return {
      columnHidingEnabled: false,
      columns: columns,
      dataSource: [],
      columnChooser: {enabled: false},
      export: {enabled: false},
      filterRow: {visible: false},
      masterDetail: {enabled: hasMasterDetail, template: 'masterDetailTemplateIntContas'},
      remoteOperations: false
    };
  }

  private _calculateTotals(rubricasDataSource: DataSource): void {
    if (this.tabActiveId === 'demAltCP' || this.tabActiveId === 'demAltCP2') {
      const dataSource: Array<IJsonDemFinSNCRubricaEX> = rubricasDataSource.items();
      const itemsWithFormula = dataSource.filter((value: IJsonDemFinSNCRubricaEX) => !isEmpty(value.formula));
      for (const item of itemsWithFormula) {
        const formulas: Array<string> = item.formula.split('+');
        item.capitalRealizado =
          item.accoesQuotasProprias =
          item.outrosInstrumentosCapProprio =
          item.premiosDeEmissao =
          item.reservasLegais =
          item.outrasReservas =
          item.resultadosTransitados =
          item.ajustamentosEmActivosFinanceirosOutrasVariacoes =
          item.excedentesDeRevalorizacao =
          item.resultadoLiquidoDoPeriodo =
            0;
        for (const prop of formulas) {
          item.capitalRealizado += dataSource.find((rub: IJsonDemFinSNCRubricaEX) => rub.prop === prop.trim()).capitalRealizado;
          item.accoesQuotasProprias += dataSource.find((rub: IJsonDemFinSNCRubricaEX) => rub.prop === prop.trim()).accoesQuotasProprias;
          item.outrosInstrumentosCapProprio += dataSource.find((rub: IJsonDemFinSNCRubricaEX) => rub.prop === prop.trim()).outrosInstrumentosCapProprio;
          item.premiosDeEmissao += dataSource.find((rub: IJsonDemFinSNCRubricaEX) => rub.prop === prop.trim()).premiosDeEmissao;
          item.reservasLegais += dataSource.find((rub: IJsonDemFinSNCRubricaEX) => rub.prop === prop.trim()).reservasLegais;
          item.outrasReservas += dataSource.find((rub: IJsonDemFinSNCRubricaEX) => rub.prop === prop.trim()).outrasReservas;
          item.resultadosTransitados += dataSource.find((rub: IJsonDemFinSNCRubricaEX) => rub.prop === prop.trim()).resultadosTransitados;
          item.ajustamentosEmActivosFinanceirosOutrasVariacoes += dataSource.find((rub: IJsonDemFinSNCRubricaEX) => rub.prop === prop.trim()).ajustamentosEmActivosFinanceirosOutrasVariacoes;
          item.excedentesDeRevalorizacao += dataSource.find((rub: IJsonDemFinSNCRubricaEX) => rub.prop === prop.trim()).excedentesDeRevalorizacao;
          item.resultadoLiquidoDoPeriodo += dataSource.find((rub: IJsonDemFinSNCRubricaEX) => rub.prop === prop.trim()).resultadoLiquidoDoPeriodo;
        }
        (<ArrayStore>rubricasDataSource.store()).update(item.positionIdx, item);
      }
    } else {
      const dataSource: Array<IJsonDemFinSNCRubrica> = rubricasDataSource.items();
      const itemsWithFormula = dataSource.filter((value: IJsonDemFinSNCRubrica) => !isEmpty(value.formula));
      for (const item of itemsWithFormula) {
        const formulas: Array<string> = item.formula.split('+');
        item.anoN = item.anoN1 = 0;
        for (const prop of formulas) {
          item.anoN += dataSource.find((rub: IJsonDemFinSNCRubrica) => rub.prop === prop.trim()).anoN;
          item.anoN1 += dataSource.find((rub: IJsonDemFinSNCRubrica) => rub.prop === prop.trim()).anoN1;
        }
        (<ArrayStore>rubricasDataSource.store()).update(item.positionIdx, item);
      }
    }
  }

  private async _saveDemFinSNC(): Promise<void> {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(DemFinSNCObsModalComponent, {size: 'sm'});
    const componentInstance: DemFinSNCObsModalComponent = modalInstance.componentInstance;
    componentInstance.observacoes = this._observacoes;
    await modalInstance.result.then((result: string) => {
      this._observacoes = result;
    });

    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    this._demFinSNCRubricaMain.rubricasBalanco = await (<DataSource>this.dataGridDemBalancoDefinition.dataSource).load();
    this._demFinSNCRubricaMain.rubricasDRNat = await (<DataSource>this.dataGridDemDRNatDefinition.dataSource).load();
    this._demFinSNCRubricaMain.rubricasDRFnc = await (<DataSource>this.dataGridDemDRFncDefinition.dataSource).load();
    this._demFinSNCRubricaMain.rubricasFluxosCaixa = await (<DataSource>this.dataGridDemDRFluxCaixaDefinition.dataSource).load();
    this._demFinSNCRubricaMain.rubricasCapPropAnoN = await (<DataSource>this.dataGridDemAltCPDefinition.dataSource).load();
    this._demFinSNCRubricaMain.rubricasCapPropAnoN1 = await (<DataSource>this.dataGridDemAltCP2Definition.dataSource).load();
    this._demFinSNCRubricaMain.demFinSNCVarApuramento = this._demFinSNCHeaderProcessed.variaveisApuramento;

    await this._demFinSNCService
      .saveDemFinSNC(
        this._demFinSNCHeaderProcessed.ano,
        this._demFinSNCHeaderProcessed.processaFluxos,
        this._demFinSNCHeaderProcessed.verComDados,
        this._demFinSNCHeaderProcessed.processaIntercalares,
        this._demFinSNCHeaderProcessed.periodoIntercalares,
        this._observacoes,
        this._tipoLingua,
        this._demFinSNCRubricaMain
      )
      .then((response: HttpResponse<IJsonNumber>) => {
        this._demFinSNCRubricaMain.loadedID = response.body.value;
        this._plAlertService.success('demfinsnc.modal.message.savedemfin');
      })
      .finally(() => {
        if (this._dataGridInstance) {
          this._dataGridInstance.endCustomLoading();
        }
      });
  }

  private _generateCaptionDataFields(): void {
    this._captionDataFieldDemFinSNC = {
      rubrica: this._findTranslateForCaptionField('rubrica'),
      notas: this._findTranslateForCaptionField('notas'),
      capitalRealizado: this._findTranslateForCaptionField('capitalRealizado'),
      accoesQuotasProprias: this._findTranslateForCaptionField('accoesQuotasProprias'),
      outrosInstrumentosCapProprio: this._findTranslateForCaptionField('outrosInstrumentosCapProprio'),
      premiosDeEmissao: this._findTranslateForCaptionField('premiosDeEmissao'),
      reservasLegais: this._findTranslateForCaptionField('reservasLegais'),
      outrasReservas: this._findTranslateForCaptionField('outrasReservas'),
      resultadosTransitados: this._findTranslateForCaptionField('resultadosTransitados'),
      ajustamentosEmActivosFinanceirosOutrasVariacoes: this._findTranslateForCaptionField('ajustamentosEmActivosFinanceirosOutrasVariacoes'),
      excedentesDeRevalorizacao: this._findTranslateForCaptionField('excedentesDeRevalorizacao'),
      resultadoLiquidoDoPeriodo: this._findTranslateForCaptionField('resultadoLiquidoDoPeriodo'),
      total: this._findTranslateForCaptionField('total')
    };
  }

  private _findTranslateForCaptionField(field: string): string {
    const index = this._demFinSNCRubricaMain.demFinSNCHeaderByLang.findIndex((item: IJsonDemFinSNCTranslatedHeader) => item.parametro === field);
    if (index !== -1) {
      return this._demFinSNCRubricaMain.demFinSNCHeaderByLang[index].traducao;
    }
    return '';
  }

  private _getListaPeriodosIntercalares(): Promise<void> {
    if (this.processaIntercalares && (!this.periodosSource || this.periodosSource.length < 1)) {
      return this._demFinSNCService.getListaPeriodos().then((response: HttpResponse<Array<IJsonDemFinSNCPeriodo>>) => {
        this.periodosSource = orderBy(response.body, 'periodo', 'asc');
      });
    }
    return Promise.resolve();
  }
}
