import {Injector} from '@angular/core';
import {ConfigService} from '../../services/config/config.service';
import {DATA_SOURCE_NAME_TIPOS_RET_SERV_NIF} from '../../datasources/tiposretservnif/tiposRetServNIF.datasource.interface';
import {ENTITY_NAME_NIFS, INifsEntity} from './nifs.entity.interface';
import {IJsonExternalNif} from '../../services/externalnifs/externalnifs.interface';
import {NifsEditComponent} from './components/edit/nifs.entity.edit.component';
import {NifsListComponent} from './components/list/nifs.entity.list.component';
import {ROLE} from '../../services/role.const';
import {TInjectorResolvable} from '../../../common/injectors/common';
import {THttpQueryResponse, TServiceResponse} from '../../services/api/api.service.interface';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {ENTITY_NAME_TAXAS_RETENCAO} from '../taxasretencao/taxasRetencao.entity.interface';
import {IJsonTxRet} from '../taxasretencao/jsonTxRet.entity.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {ENTITY_NAME_PAISES} from '../paises/paises.entity.interface';

function sujeitaAsLeisVigoramEmAngola(injector: Injector): boolean {
  const configService: ConfigService = injector.get<ConfigService>(ConfigService);
  return configService.configurations.empresa.sujeitaAsLeisVigoramEmAngola;
}

export const ENTITY_NIF: TInjectorResolvable = [
  Injector,
  (injector: Injector): INifsEntity => {
    return {
      name: ENTITY_NAME_NIFS,
      roles: [ROLE.CONTABILIDADE, ROLE.RH, ROLE.ERP, ROLE.ATIVOS],
      searchPlaceholder: 'nifs.pesquisa',
      pageTitle: 'global.menu.nifs',
      metadata: {
        keyName: 'nContribuinte',
        fields: [
          {name: 'codPais', type: 'cgsmallint', caption: 'nifs.fields.codPais'},
          {
            name: 'abrevFiscal',
            caption: 'nifs.fields.codPais',
            entity: {name: ENTITY_NAME_PAISES, keyTarget: 'codPais', outputTarget: 'abrevFiscal', outputDescription: 'abrevFiscal'},
            validators: {required: true}
          },
          {name: 'nContribuinte', width: '200px', caption: 'nifs.fields.nContribuinte', validators: {required: true, minlength: 9}},
          {name: 'codRet', caption: 'nifs.fields.codRet'},
          {
            name: 'nomeRet',
            caption: 'nifs.fields.codRet',
            entity: {name: 'taxasretencao', keyTarget: 'codRet', outputTarget: 'nomeRet', outputDescription: 'nomeRet'}
          },
          {
            name: 'tipoRetServNIF',
            type: DATA_SOURCE_NAME_TIPOS_RET_SERV_NIF,
            caption: 'nifs.fields.tipoRetServNIF',
            validators: {required: true},
            properties: {
              devExpress: {
                dataGrid: {
                  lookup: {cgDataSource: DATA_SOURCE_NAME_TIPOS_RET_SERV_NIF}
                }
              }
            }
          },
          {name: 'temCativo', type: 'boolean', caption: 'nifs.fields.temCativo', visible: () => sujeitaAsLeisVigoramEmAngola(injector)},
          {name: 'taxaCativo', type: 'number', caption: 'nifs.fields.taxaCativo', visible: () => sujeitaAsLeisVigoramEmAngola(injector)}
        ],
        order: 'nContribuinte',
        searchFields: 'nContribuinte,abrevFiscal,nomeCodRet,tipoRetServNIF',
        detailFields: 'abrevFiscal,nContribuinte,tipoRetServNIF'
      },
      autocomplete: {
        rowTemplate: '{{nContribuinte}}',
        output: 'nContribuinte',
        searchFields: 'nContribuinte'
      },
      actions: {
        new: true,
        detail: true,
        edit: true,
        delete: true,
        search: true,
        filter: true
      },
      list: {
        state: {
          component: NifsListComponent
        }
      },
      detail: {
        state: {
          component: NifsEditComponent,
          resolve: [
            {
              provide: 'taxasRetencaoList',
              deps: [EntityServiceBuilder],
              useFactory: (entityServiceBuilder: EntityServiceBuilder): Promise<Array<IJsonTxRet>> => {
                const taxasRetencaoService: IEntityService<IJsonTxRet> = entityServiceBuilder.build<IJsonTxRet>(ENTITY_NAME_TAXAS_RETENCAO);
                return taxasRetencaoService.query().then((response: THttpQueryResponse<IJsonTxRet>) => response.body.list);
              }
            }
          ]
        }
      },
      service: function () {
        this.getExternalNifFromVIES = (nContribuinte: string): TServiceResponse<IJsonExternalNif> => {
          return this.get<IJsonExternalNif>({id: `${nContribuinte}/VIES`});
        };

        this.registaNif = (nContribuinte: string): TServiceResponse<void> => {
          return this.post<void>({url: `${nContribuinte}/regista`});
        };
      }
    };
  }
];
