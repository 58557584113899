<div class="cg-app-status-maintenance">
  <div class="toast cg-toast fade show toast-maintenance" role="alert" aria-live="assertive" aria-atomic="true" [class.show]="showToast" [class.mb-3]="showToast">
    <div class="toast-header gap-2">
      <i cgcIcon="fa-rocket"></i>
      <strong [translate]="'application.versionUpdate.title'"></strong>
      <div class="ms-auto">
        <button type="button" class="btn-close" (click)="hide()"></button>
      </div>
    </div>

    <div class="toast-body">
      <span [translate]="'application.versionUpdate.description'"></span>&nbsp;<button
        type="button"
        class="btn btn-link p-0"
        [translate]="'application.versionUpdate.reload'"
        (click)="reloadApplication()"></button
      >.
    </div>

    <div class="cg-toast-backdrop bg-info"></div>
  </div>
</div>
