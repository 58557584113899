<pl-form>
  <div class="concilmovab-justemseriecontas-modal">
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'concilmovab.modal.justemseriecontas'"></h5>
    </div>

    <div class="modal-body">
      <pl-group>
        <pl-group>
          <label [translate]="'concilmovab.deconta'"></label>
          <edit>
            <entity-autocomplete
              entity="pocs"
              attrName="conta"
              [model]="concilMovAbNContaEmSerie"
              (selectedKeyChange)="concilMovAbNContaEmSerie.nContaDe = $event"
              [filter]="filterMovimento"
              [fieldsMap]="{nConta: 'nContaDe'}"
              [helperMode]="true"
              output="key"
              plAutoFocus>
            </entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'concilmovab.ate'"></label>
          <edit>
            <entity-autocomplete
              entity="pocs"
              attrName="conta"
              [model]="concilMovAbNContaEmSerie"
              (selectedKeyChange)="concilMovAbNContaEmSerie.nContaAte = $event"
              [filter]="filterMovimento"
              [fieldsMap]="{nConta: 'nContaAte'}"
              [helperMode]="true"
              output="key">
            </entity-autocomplete>
          </edit>
        </pl-group>
      </pl-group>
      <pl-group>
        <pl-group>
          <label class="d-flex">
            <span [translate]="'concilmovab.table.periodo'"></span>
            <pl-tooltip class="tooltip-info-label" [config]="{text: 'concilmovab.searchlimiteperiodomaximo', placement: ['right'], tooltipClass: 'tooltip-info'}">
              <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
            </pl-tooltip>
          </label>
          <edit>
            <entity-autocomplete
              entity="periodos"
              attrName="periodo"
              [model]="concilMovAbNContaEmSerie"
              (selectedKeyChange)="concilMovAbNContaEmSerie.periodoAte = $event"
              [fieldsMap]="{periodo: 'periodoAte'}"
              [properties]="{events: {keydown: keydownPeriodoAte}}"
              output="periodo">
            </entity-autocomplete>
          </edit>
        </pl-group>
      </pl-group>
    </div>
    <div class="modal-footer">
      <pl-button klass="btn-primary action-close" [onClick]="close" attrName="justserie"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'concilmovab.btn.justserie'"></span> </pl-button>
      <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
    </div>
  </div>
</pl-form>
