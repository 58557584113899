<div class="docs-fiscais">
  <div class="d-flex docs-fiscais-body">
    <div class="docs-fiscais-body-panel docs-fiscais-body-panel-left">
      <div class="docs-fiscais-content-wrapper">
        <div class="list-group docs-fiscais-left-menu">
          <ng-container *ngFor="let m of mainMenu">
            <ng-container *ngIf="m.isHeader === true">
              <div class="list-group-item docs-fiscais-left-menu-header"><i class="fa fa-cube" aria-hidden="true"></i> <span [translate]="m.caption"></span></div>
            </ng-container>

            <ng-container *ngIf="m.isHeader !== true">
              <button type="button" (click)="mainMenuClick(m, $event)" class="list-group-item list-group-item-action" [ngClass]="{active: activeMenu === m.menu}">
                <i class="fa fa-folder-o" aria-hidden="true" [ngClass]="{'fa-folder-open-o': activeMenu === m.menu, 'fa-folder-o': activeMenu !== m.menu}"></i>
                <span [translate]="m.caption"></span>
              </button>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="docs-fiscais-body-panel docs-fiscais-body-panel-right">
      <div class="docs-fiscais-content-wrapper">
        <ng-container *ngIf="activeMenu === mainMenuTypes.IVA">
          <pl-tabs [theme]="'style3'">
            <pl-tab caption="documentosFiscais.tabIvaDp">
              <div *plTabContent>
                <pl-group class="pl-group-inline">
                  <label [translate]="'global.text.year'"></label>
                  <edit style="max-width: 80px">
                    <pl-select [clearable]="false" [model]="filterAno" (modelChange)="onYearChange($event)" [source]="anosSource" [rowTemplate]="anosRowTemplate" [output]="anosOutput"></pl-select>
                  </edit>
                  <actions>
                    <pl-button type="button" klass="btn-primary" (evtClicked)="fnTabIvaDpGetList()">
                      <i class="fa fa-download"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.get'"></span>
                    </pl-button>
                  </actions>
                </pl-group>

                <dx-data-grid
                  [cgDxDataGrid]="modules.dpIva.dataGridDefinition"
                  [cgDxDataGridInstanceName]="modules.dpIva.dataGridInstanceName"
                  [dataSource]="modules.dpIva.dataGridDefinition.dataSource"
                  (onInitialized)="onDataGridInitialized($event, modules.dpIva)">
                  <div *dxTemplate="let item of 'cellTemplateComprovativo'">
                    <ng-container *ngIf="item.data._hasComprovativo">
                      <pl-button klass="btn btn-secondary btn-xs" (evtClicked)="showDpIvaComprovativoPdf(item.data)">
                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </pl-button>
                    </ng-container>
                    <ng-container *ngIf="!item.data._hasComprovativo">
                      <span><i style="font-size: 11px" class="text-muted" [translate]="'documentosFiscais.strings.naoDisponivel'"></i></span>
                    </ng-container>
                  </div>

                  <div *dxTemplate="let item of 'cellTemplatePagamento'">
                    <ng-container *ngIf="item.data._hasDocPagamento">
                      <pl-button klass="btn btn-secondary btn-xs me-2" (evtClicked)="showDpIvaPagamentoPdf(item.data)" [tooltip]="{text: 'documentosFiscais.strings.verDocumento', container: 'body'}">
                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </pl-button>

                      <pl-button
                        klass="btn btn-secondary btn-xs"
                        [tooltip]="{
                          container: 'body',
                          text: empresaTemContabilidadeDigital
                            ? 'documentosFiscais.strings.associarDocumentoContabDigital'
                            : !licencaStoreModePublic
                            ? ('arquivodigital.errors.licenseNotActivated' | translate)
                            : ('arquivodigital.errors.licenseNotActivatedCGOn' | translate: {href: cgStoreUrlBackOffice})
                        }"
                        [disabled]="!empresaTemContabilidadeDigital"
                        [onClick]="associarDocumentoContabDigital(modules.dpIva, item.data, '')">
                        <i class="fa pl-icon-adicionar-docs" aria-hidden="true"></i>
                      </pl-button>
                    </ng-container>
                    <ng-container *ngIf="!item.data._hasDocPagamento">
                      <span><i style="font-size: 11px" class="text-muted" [translate]="'documentosFiscais.strings.naoDisponivel'"></i></span>
                    </ng-container>
                  </div>
                </dx-data-grid>
              </div>
            </pl-tab>
            <pl-tab caption="documentosFiscais.tabIvaDr">
              <div *plTabContent>
                <pl-group class="pl-group-inline">
                  <label [translate]="'global.text.year'"></label>
                  <edit style="max-width: 80px">
                    <pl-select [clearable]="false" [model]="filterAno" (modelChange)="onYearChange($event)" [source]="anosSource" [rowTemplate]="anosRowTemplate" [output]="anosOutput"></pl-select>
                  </edit>
                  <actions>
                    <pl-button type="button" klass="btn-primary" (evtClicked)="fnTabIvaDrGetList()">
                      <i class="fa fa-download"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.get'"></span>
                    </pl-button>
                  </actions>
                </pl-group>

                <dx-data-grid
                  [cgDxDataGrid]="modules.drIva.dataGridDefinition"
                  [cgDxDataGridInstanceName]="modules.drIva.dataGridInstanceName"
                  [dataSource]="modules.drIva.dataGridDefinition.dataSource"
                  (onInitialized)="onDataGridInitialized($event, modules.drIva)">
                  <div *dxTemplate="let item of 'cellTemplateComprovativo'">
                    <ng-container *ngIf="item.data._hasComprovativo">
                      <pl-button klass="btn btn-secondary btn-xs" (evtClicked)="showDrIvaComprovativoPdf(item.data)">
                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </pl-button>
                    </ng-container>
                    <ng-container *ngIf="!item.data._hasComprovativo">
                      <span><i style="font-size: 11px" class="text-muted" [translate]="'documentosFiscais.strings.naoDisponivel'"></i></span>
                    </ng-container>
                  </div>
                </dx-data-grid>
              </div>
            </pl-tab>
          </pl-tabs>
        </ng-container>

        <ng-container *ngIf="activeMenu === mainMenuTypes.IRC">
          <pl-tabs [theme]="'style3'" [(activeId)]="activeTab">
            <pl-tab caption="documentosFiscais.tabIRCPagAnt" [id]="docsFiscaisTab.IrcPagAnt">
              <div *plTabContent>
                <pl-group class="pl-group-inline">
                  <label [translate]="'global.text.year'"></label>
                  <edit style="max-width: 80px">
                    <pl-select [clearable]="false" [model]="filterAno" (modelChange)="onYearChange($event)" [source]="anosSource" [rowTemplate]="anosRowTemplate" [output]="anosOutput"></pl-select>
                  </edit>
                  <actions>
                    <pl-button type="button" klass="btn-primary" (evtClicked)="fnTabIRCPagAntGetList()">
                      <i class="fa fa-download"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.get'"></span>
                    </pl-button>
                  </actions>
                </pl-group>

                <dx-data-grid
                  [cgDxDataGrid]="modules.ircPagAnt.dataGridDefinition"
                  [cgDxDataGridInstanceName]="modules.ircPagAnt.dataGridInstanceName"
                  [dataSource]="modules.ircPagAnt.dataGridDefinition.dataSource"
                  (onInitialized)="onDataGridInitialized($event, modules.ircPagAnt)">
                  <div *dxTemplate="let item of 'cellTemplateDetalhes'">
                    <ng-container *ngIf="item.data._hasDetalhes">
                      <pl-button
                        klass="btn btn-secondary btn-xs me-2"
                        (evtClicked)="showIrcPagAntDetalhesPdf(item.data)"
                        [tooltip]="{text: 'documentosFiscais.strings.verDocumento', container: 'body'}">
                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </pl-button>
                      <pl-button
                        klass="btn btn-secondary btn-xs"
                        [tooltip]="{
                          container: 'body',
                          text: empresaTemContabilidadeDigital
                            ? 'documentosFiscais.strings.associarDocumentoContabDigital'
                            : !licencaStoreModePublic
                            ? ('arquivodigital.errors.licenseNotActivated' | translate)
                            : ('arquivodigital.errors.licenseNotActivatedCGOn' | translate: {href: cgStoreUrlBackOffice})
                        }"
                        [disabled]="!empresaTemContabilidadeDigital"
                        [onClick]="associarDocumentoContabDigital(modules.ircPagAnt, item.data, '')">
                        <i class="fa pl-icon-adicionar-docs" aria-hidden="true"></i>
                      </pl-button>
                    </ng-container>
                    <ng-container *ngIf="!item.data._hasDetalhes">
                      <span><i style="font-size: 11px" class="text-muted" [translate]="'documentosFiscais.strings.naoDisponivel'"></i></span>
                    </ng-container>
                  </div>
                </dx-data-grid>
              </div>
            </pl-tab>
            <pl-tab caption="documentosFiscais.tabIRCMod22" [id]="docsFiscaisTab.IrcMod22">
              <div *plTabContent>
                <pl-group class="pl-group-inline">
                  <label [translate]="'global.text.year'"></label>
                  <edit style="max-width: 80px">
                    <pl-select [clearable]="false" [model]="filterAno" (modelChange)="onYearChange($event)" [source]="anosSource" [rowTemplate]="anosRowTemplate" [output]="anosOutput"></pl-select>
                  </edit>
                  <actions>
                    <pl-button type="button" klass="btn-primary" (evtClicked)="fnTabIRCMod22List()">
                      <i class="fa fa-download"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.get'"></span>
                    </pl-button>
                  </actions>
                </pl-group>

                <dx-data-grid
                  [cgDxDataGrid]="modules.ircMod22.dataGridDefinition"
                  [cgDxDataGridInstanceName]="modules.ircMod22.dataGridInstanceName"
                  [dataSource]="modules.ircMod22.dataGridDefinition.dataSource"
                  (onInitialized)="onDataGridInitialized($event, modules.ircMod22)">
                  <div *dxTemplate="let item of 'cellTemplateComprovativo'">
                    <ng-container *ngIf="item.data._hasComprovativo">
                      <pl-button klass="btn btn-secondary btn-xs" (evtClicked)="showIrcMod22ComprovativoPdf(item.data)">
                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </pl-button>
                    </ng-container>
                    <ng-container *ngIf="!item.data._hasComprovativo">
                      <span><i style="font-size: 11px" class="text-muted" [translate]="'documentosFiscais.strings.naoDisponivel'"></i></span>
                    </ng-container>
                  </div>

                  <div *dxTemplate="let item of 'cellTemplatePagamento'">
                    <ng-container *ngIf="item.data._hasDocPagamento">
                      <pl-button
                        klass="btn btn-secondary btn-xs me-2"
                        (evtClicked)="showIrcMod22PagamentoPdf(item.data)"
                        [tooltip]="{text: 'documentosFiscais.strings.verDocumento', container: 'body'}">
                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </pl-button>
                      <pl-button
                        klass="btn btn-secondary btn-xs"
                        [tooltip]="{
                          container: 'body',
                          text: empresaTemContabilidadeDigital
                            ? 'documentosFiscais.strings.associarDocumentoContabDigital'
                            : !licencaStoreModePublic
                            ? ('arquivodigital.errors.licenseNotActivated' | translate)
                            : ('arquivodigital.errors.licenseNotActivatedCGOn' | translate: {href: cgStoreUrlBackOffice})
                        }"
                        [disabled]="!empresaTemContabilidadeDigital"
                        [onClick]="associarDocumentoContabDigital(modules.ircMod22, item.data, '')">
                        <i class="fa pl-icon-adicionar-docs" aria-hidden="true"></i>
                      </pl-button>
                    </ng-container>
                    <ng-container *ngIf="!item.data._hasDocPagamento">
                      <span><i style="font-size: 11px" class="text-muted" [translate]="'documentosFiscais.strings.naoDisponivel'"></i></span>
                    </ng-container>
                  </div>
                </dx-data-grid>
              </div>
            </pl-tab>
          </pl-tabs>
        </ng-container>

        <ng-container *ngIf="activeMenu === mainMenuTypes.RECIBOS">
          <pl-tabs [theme]="'style3'" [(activeId)]="activeTab">
            <pl-tab caption="documentosFiscais.tabRecibosRVClient" [id]="docsFiscaisTab.RecibosRV">
              <div *plTabContent plNavigation>
                <div>
                  <pl-group class="pl-group-inline">
                    <label>Data de:</label>
                    <edit style="max-width: 150px">
                      <pl-edit-datepicker
                        [model]="modules.recibosRV.startDate"
                        (modelChange)="onRVStartDateChange($event)"
                        plAutoFocus
                        [properties]="{events: {keydown: keydownEndDate}}"></pl-edit-datepicker>
                    </edit>
                  </pl-group>
                  <pl-group class="pl-group-inline">
                    <label>até:</label>
                    <edit style="max-width: 150px">
                      <pl-edit-datepicker [attrName]="'endDate'" [(model)]="modules.recibosRV.endDate" [properties]="{events: {keydown: keydownNif}}"></pl-edit-datepicker>
                    </edit>
                  </pl-group>
                  <pl-group class="pl-group-inline">
                    <label>NIF:</label>
                    <edit style="max-width: 120px">
                      <pl-edit attrName="nif" type="text" [(model)]="modules.recibosRV.nifPrestador" [properties]="{events: {keydown: keydownObter}}"></pl-edit>
                    </edit>
                    <actions>
                      <pl-button attrName="btnObter" type="button" klass="btn-primary" (evtClicked)="fnTabRecibosRVGetList()">
                        <i class="fa fa-download"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.get'"></span>
                      </pl-button>
                    </actions>
                  </pl-group>
                </div>

                <dx-data-grid
                  [cgDxDataGrid]="modules.recibosRV.dataGridDefinition"
                  [cgDxDataGridInstanceName]="modules.recibosRV.dataGridInstanceName"
                  [dataSource]="modules.recibosRV.dataGridDefinition.dataSource"
                  (onCellClick)="onDataGridRecibosRVCellClick($event)"
                  (onInitialized)="onDataGridInitialized($event, modules.recibosRV)">
                  <div *dxTemplate="let item of 'cellTemplatePrint'">
                    <div class="d-flex justify-content-center">
                      <pl-button klass="btn btn-secondary btn-xs me-2" (evtClicked)="showRecibosRVPdf(item.data)" [tooltip]="{text: 'documentosFiscais.strings.verDocumento', container: 'body'}">
                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </pl-button>

                      <pl-button
                        klass="btn btn-secondary btn-xs"
                        [tooltip]="{
                          container: 'body',
                          text: empresaTemContabilidadeDigital
                            ? 'documentosFiscais.strings.associarDocumentoContabDigital'
                            : !licencaStoreModePublic
                            ? ('arquivodigital.errors.licenseNotActivated' | translate)
                            : ('arquivodigital.errors.licenseNotActivatedCGOn' | translate: {href: cgStoreUrlBackOffice})
                        }"
                        [disabled]="!empresaTemContabilidadeDigital"
                        [onClick]="associarDocumentoContabDigital(modules.recibosRV, item.data, 'dataEmissao')">
                        <i class="fa pl-icon-adicionar-docs" aria-hidden="true"></i>
                      </pl-button>
                    </div>
                  </div>

                  <div *dxTemplate="let item of 'detail'">
                    <div class="custom-details">
                      <div class="row">
                        <div class="col-md-6">
                          <h5 class="custom-header" [translate]="'documentosFiscais.fields.tipo'"></h5>
                          <div class="detail-block-row">
                            <div>{{ item.data.tipoDocumento }}</div>
                          </div>
                          <h5 class="custom-header" [translate]="'documentosFiscais.fields.domicilioFiscal'"></h5>
                          <div class="detail-block-row">
                            <div>{{ item.data.moradaFiscalPrestador }}</div>
                          </div>
                          <h5 class="custom-header" [translate]="'documentosFiscais.fields.titulo'"></h5>
                          <div class="detail-block-row">
                            <div>{{ item.data.titulo }}</div>
                          </div>
                          <h5 class="custom-header" [translate]="'documentosFiscais.fields.descricao'"></h5>
                          <div class="detail-block-row">
                            <div [innerHTML]="item.data.descricao"></div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <h5 class="custom-header" [translate]="'documentosFiscais.fields.situacao'"></h5>
                          <div class="detail-block-row">
                            <div>{{ item.data.situacao }}</div>
                          </div>
                          <h5 class="custom-header" [translate]="'documentosFiscais.fields.atividadePrestador'"></h5>
                          <div class="detail-block-row">
                            <div>{{ item.data.atividadePrestador }}</div>
                          </div>
                          <h5 class="custom-header" [translate]="'documentosFiscais.fields.dataRef'"></h5>
                          <div class="detail-block-row">
                            <div>{{ item.data.dataTransmissao | cgcDate }}</div>
                          </div>
                          <h5 class="custom-header" [translate]="'documentosFiscais.fields.ivaDesc'"></h5>
                          <div class="detail-block-row">
                            <div>{{ item.data.regimeIVADescr }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </dx-data-grid>
              </div>
            </pl-tab>
            <pl-tab caption="documentosFiscais.tabRecibosRR" [id]="docsFiscaisTab.RecibosRR">
              <div *plTabContent plNavigation>
                <div>
                  <pl-group class="pl-group-inline">
                    <label>Data de:</label>
                    <edit style="max-width: 150px">
                      <pl-edit-datepicker
                        [model]="modules.recibosRR.startDate"
                        (modelChange)="onRRStartDateChange($event)"
                        plAutoFocus
                        [properties]="{events: {keydown: keydownEndDate}}"></pl-edit-datepicker>
                    </edit>
                  </pl-group>
                  <pl-group class="pl-group-inline">
                    <label>até:</label>
                    <edit style="max-width: 150px">
                      <pl-edit-datepicker [attrName]="'endDate'" [(model)]="modules.recibosRR.endDate" [properties]="{events: {keydown: keydownObter}}"></pl-edit-datepicker>
                    </edit>
                    <actions>
                      <pl-button attrName="btnObter" type="button" klass="btn-primary" (evtClicked)="fnTabRecibosRRGetList()">
                        <i class="fa fa-download"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.get'"></span>
                      </pl-button>
                    </actions>
                  </pl-group>
                </div>

                <dx-data-grid
                  [cgDxDataGrid]="modules.recibosRR.dataGridDefinition"
                  [cgDxDataGridInstanceName]="modules.recibosRR.dataGridInstanceName"
                  [dataSource]="modules.recibosRR.dataGridDefinition.dataSource"
                  (onCellClick)="onDataGridRecibosRRCellClick($event)"
                  (onInitialized)="onDataGridInitialized($event, modules.recibosRR)">
                  <div *dxTemplate="let item of 'cellTemplateDocument'">
                    <pl-button
                      klass="btn btn-secondary btn-xs me-2"
                      (evtClicked)="showRecibosRRComprovativoPdf(item.data)"
                      [tooltip]="{text: 'documentosFiscais.strings.verDocumento', container: 'body'}">
                      <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                    </pl-button>
                    <pl-button
                      klass="btn btn-secondary btn-xs"
                      [tooltip]="{
                        container: 'body',
                        text: empresaTemContabilidadeDigital
                          ? 'documentosFiscais.strings.associarDocumentoContabDigital'
                          : !licencaStoreModePublic
                          ? ('arquivodigital.errors.licenseNotActivated' | translate)
                          : ('arquivodigital.errors.licenseNotActivatedCGOn' | translate: {href: cgStoreUrlBackOffice})
                      }"
                      [disabled]="!empresaTemContabilidadeDigital"
                      [onClick]="associarDocumentoContabDigital(modules.recibosRR, item.data, 'dataInicio')">
                      <i class="fa pl-icon-adicionar-docs" aria-hidden="true"></i>
                    </pl-button>
                  </div>

                  <div *dxTemplate="let item of 'detail'">
                    <div class="custom-details">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div>
                            <h4 class="custom-header">Detalhes Recibo #{{ item.data.numeroRecibo }}</h4>

                            <h5 class="custom-header">Emitente</h5>
                            <div class="detail-block-row">
                              <div>NIF {{ item.data.nifEmitente }} - {{ item.data.nomeEmitente }}</div>
                            </div>

                            <h5 class="custom-header">Locador / Sublocador (Senhorio) / Cedente</h5>
                            <div class="detail-block-row">
                              <div *ngFor="let locador of item.data.reciboDetails?.locadores">NIF {{ locador.nif }} - {{ locador.nome }}</div>
                            </div>

                            <h5 class="custom-header">Locatário / Sublocatário (Inquilino) / Cessionário</h5>
                            <div class="detail-block-row">
                              <div *ngFor="let locatario of item.data.reciboDetails?.locatarios">{{ locatario.nif }} - {{ locatario.nome }}</div>
                            </div>

                            <h5 class="custom-header">Identificação do Imóvel</h5>
                            <div class="detail-block-row">
                              <div class="row" *ngFor="let imovel of item.data.reciboDetails?.imoveis">
                                <div class="col-md-4">
                                  <dl>
                                    <dt>Freguesia</dt>
                                    <dd>{{ imovel.freguesia.codigo }}</dd>
                                  </dl>
                                </div>
                                <div class="col-md-4">
                                  <dl>
                                    <dt>Tipo</dt>
                                    <dd>{{ imovel.tipo.label }}</dd>
                                  </dl>
                                </div>
                                <div class="col-md-4">
                                  <dl>
                                    <dt>Artigo</dt>
                                    <dd>{{ imovel.artigo }}</dd>
                                  </dl>
                                </div>
                                <div class="col-md-12">
                                  <dl>
                                    <dt>Localização/Morada</dt>
                                    <dd>
                                      {{ imovel.morada }} {{ imovel.andar }}
                                      <span *ngIf="imovel.tipo.codigo === 'U'">{{ imovel.codigoPostal }} -{{ imovel.unidadeFuncional }} {{ imovel.localidade }}</span>
                                    </dd>
                                  </dl>
                                </div>
                              </div>
                            </div>

                            <h5 class="custom-header">Tipo de Contrato</h5>
                            <div class="detail-block-row">
                              <div>{{ item.data.reciboDetails?.tipoContrato?.label }}</div>
                            </div>

                            <h5 class="custom-header">Renda</h5>
                            <div class="detail-block-row">
                              <div>
                                Importância recebida a título de <strong>{{ item.data.reciboDetails?.tipoImportancia?.label }}</strong>
                              </div>
                            </div>
                            <div class="detail-block-row">
                              <div class="row recibos-details-totals">
                                <div class="col-md-8">
                                  <div><strong>Valor</strong></div>
                                  <div>
                                    <strong>{{ item.data.reciboDetails?.retencao?.label }}</strong>
                                  </div>
                                  <div><strong>Importância Recebida</strong></div>
                                </div>
                                <div class="col-md-4">
                                  <div>{{ item.data.reciboDetails?.valor | cgcCurrency }}</div>
                                  <div>{{ item.data.reciboDetails?.vRetencao | cgcCurrency }}</div>
                                  <div>{{ item.data.reciboDetails?.vImportanciaRecb | cgcCurrency }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <div>
                            <h4 class="custom-header">Detalhes Contrato #{{ item.data.numeroContrato }}</h4>
                            <h5 class="custom-header">Caraterização do contrato</h5>
                            <div class="detail-block-row">
                              <div class="row">
                                <div class="col-md-4">
                                  <dl>
                                    <dt>Tipo</dt>
                                    <dd>{{ item.data.contrato?.tipoContrato?.label }}</dd>
                                  </dl>
                                </div>
                                <div class="col-md-4">
                                  <dl>
                                    <dt>Finalidade</dt>
                                    <dd>{{ item.data.contrato?.finalidadeContrato?.label }}</dd>
                                  </dl>
                                </div>
                                <div class="col-md-4">
                                  <dl>
                                    <dt>Renovável</dt>
                                    <dd>{{ item.data.contrato?.renovavel ? 'Sim' : 'Não' }}</dd>
                                  </dl>
                                </div>
                                <div class="col-md-4">
                                  <dl>
                                    <dt>Data de Início</dt>
                                    <dd>{{ item.data.contrato?.dataInicio | cgcDate }}</dd>
                                  </dl>
                                </div>
                                <div class="col-md-4">
                                  <dl>
                                    <dt>Data de Termo</dt>
                                    <dd>{{ item.data.contrato?.dataFim | cgcDate }}</dd>
                                  </dl>
                                </div>
                                <div class="col-md-4">
                                  <dl>
                                    <dt>Tipo de Declaração</dt>
                                    <dd>{{ item.data.contrato?.tipoDeclaracao?.label }}</dd>
                                  </dl>
                                </div>
                              </div>
                            </div>

                            <h5 class="custom-header">Imóveis</h5>
                            <div class="detail-block-row">
                              <div class="row" *ngFor="let imovel of item.data.contrato?.imoveis">
                                <div class="col-md-4">
                                  <dl>
                                    <dt>Freguesia</dt>
                                    <dd>{{ imovel.freguesia.codigo }}</dd>
                                  </dl>
                                </div>
                                <div class="col-md-4">
                                  <dl>
                                    <dt>Tipo</dt>
                                    <dd>{{ imovel.tipo.label }}</dd>
                                  </dl>
                                </div>
                                <div class="col-md-4">
                                  <dl>
                                    <dt>Artigo</dt>
                                    <dd>{{ imovel.artigo }}</dd>
                                  </dl>
                                </div>
                                <div class="col-md-12">
                                  <dl>
                                    <dt>Localização/Morada</dt>
                                    <dd>
                                      {{ imovel.morada }} {{ imovel.andar }}
                                      <span *ngIf="imovel.tipo.codigo === 'U'">{{ imovel.codigoPostal }} -{{ imovel.unidadeFuncional }} {{ imovel.localidade }}</span>
                                    </dd>
                                  </dl>
                                </div>
                              </div>
                            </div>

                            <h5 class="custom-header">Locadores (Senhorios)</h5>
                            <div class="detail-block-row">
                              <div class="row" *ngFor="let locador of item.data.contrato?.locadores">
                                <ng-container *ngFor="let recLocador of item.data.reciboDetails?.locadores">
                                  <ng-container *ngIf="recLocador.nif === locador.nif">
                                    <div class="col-sm-6 col-md-6">
                                      <div class="mb-15">
                                        <strong>{{ recLocador.nome }}</strong>
                                      </div>
                                      <div *ngIf="locador.nifConjuge">
                                        Casado no regime <strong>{{ locador.regime.label }}</strong> com <strong>{{ locador.nomeConjuge }}</strong>
                                      </div>
                                    </div>
                                    <div class="col-sm-3 col-md-3">
                                      <span>Quota-Parte: {{ locador.quotaParte }}</span>
                                    </div>
                                    <div class="col-sm-3 col-md-3 text-right">
                                      <div class="mb-15">NIF: {{ locador.nif }}</div>
                                      <div *ngIf="locador.nifConjuge">NIF: {{ locador.nifConjuge }}</div>
                                    </div>
                                  </ng-container>
                                </ng-container>
                              </div>
                            </div>

                            <h5 class="custom-header">Locatários (Inquilinos)</h5>
                            <div class="detail-block-row">
                              <div class="row" *ngFor="let locatario of item.data.contrato?.locatarios">
                                <div class="col-md-8">
                                  <div class="mb-15">
                                    <strong>{{ locatario.nomeLocatario }}</strong>
                                  </div>
                                  <div><strong>Retenção na Fonte</strong>: {{ locatario.retencao.label }}</div>
                                </div>
                                <div class="col-md-4 text-right">NIF: {{ locatario.nif }}</div>
                              </div>
                            </div>

                            <h5 class="custom-header">Renda</h5>
                            <div class="detail-block-row">
                              <div class="row">
                                <div class="col-md-4">
                                  <dl>
                                    <dt>Valor da renda</dt>
                                    <dd>{{ item.data.contrato?.valorRenda | cgcCurrency }}</dd>
                                  </dl>
                                </div>
                                <div class="col-md-6">
                                  <dl>
                                    <dt>Período de Renda</dt>
                                    <dd>{{ item.data.contrato?.unidadeTempo.label }}</dd>
                                  </dl>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </dx-data-grid>
              </div>
            </pl-tab>
          </pl-tabs>
        </ng-container>

        <ng-container *ngIf="activeMenu === mainMenuTypes.IES">
          <pl-tabs [theme]="'style3'">
            <pl-tab caption="documentosFiscais.tabIESConsult">
              <div *plTabContent>
                <dx-data-grid
                  [cgDxDataGrid]="modules.ies.dataGridDefinition"
                  [cgDxDataGridInstanceName]="modules.ies.dataGridInstanceName"
                  [dataSource]="modules.ies.dataGridDefinition.dataSource"
                  (onInitialized)="onDataGridInitialized($event, modules.ies)">
                  <div *dxTemplate="let cell of 'templateToolbarObterButton'">
                    <pl-button type="button" klass="btn-primary btn-sm" (evtClicked)="fnTabIESGetList()">
                      <i class="fa fa-download"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.get'"></span>
                    </pl-button>
                  </div>

                  <div *dxTemplate="let item of 'cellTemplateComprovativo'">
                    <ng-container *ngIf="item.data._hasComprovativo">
                      <pl-button klass="btn btn-secondary btn-xs" (evtClicked)="showIesComprovativoPdf(item.data)">
                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </pl-button>
                    </ng-container>
                    <ng-container *ngIf="!item.data._hasComprovativo">
                      <span><i style="font-size: 11px" class="text-muted" [translate]="'documentosFiscais.strings.naoDisponivel'"></i></span>
                    </ng-container>
                  </div>

                  <div *dxTemplate="let item of 'cellTemplatePagamentoMB'">
                    <pl-button klass="btn btn-info btn-xs" (evtClicked)="showIesDadosPagamento(item.data)">
                      <i class="fa fa-info" aria-hidden="true"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.mb'"></span>
                    </pl-button>
                  </div>
                </dx-data-grid>
              </div>
            </pl-tab>
          </pl-tabs>
        </ng-container>

        <ng-container *ngIf="activeMenu === mainMenuTypes.IRS">
          <pl-tabs [theme]="'style3'">
            <pl-tab caption="documentosFiscais.tabIRSMod10">
              <div *plTabContent>
                <dx-data-grid
                  [cgDxDataGrid]="modules.irs.dataGridDefinition"
                  [cgDxDataGridInstanceName]="modules.irs.dataGridInstanceName"
                  [dataSource]="modules.irs.dataGridDefinition.dataSource"
                  (onInitialized)="onDataGridInitialized($event, modules.irs)">
                  <div *dxTemplate="let cell of 'templateToolbarObterButton'">
                    <pl-button type="button" klass="btn-primary btn-sm" (evtClicked)="fnTabIRSMod10GetList()">
                      <i class="fa fa-download"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.get'"></span>
                    </pl-button>
                  </div>

                  <div *dxTemplate="let item of 'cellTemplateComprovativo'">
                    <ng-container *ngIf="item.data.temComprovativo">
                      <pl-button klass="btn btn-secondary btn-xs" (evtClicked)="showIrsComprovativoPdf(item.data, false)">
                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </pl-button>
                    </ng-container>
                    <ng-container *ngIf="!item.data.temComprovativo">
                      <span><i style="font-size: 11px" class="text-muted" [translate]="'documentosFiscais.strings.naoDisponivel'"></i></span>
                    </ng-container>
                  </div>

                  <div *dxTemplate="let item of 'cellTemplateComprovativo2via'">
                    <ng-container *ngIf="item.data.tem2Via">
                      <pl-button klass="btn btn-secondary btn-xs" (evtClicked)="showIrsComprovativoPdf(item.data, true)">
                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </pl-button>
                    </ng-container>
                    <ng-container *ngIf="!item.data.tem2Via">
                      <span><i style="font-size: 11px" class="text-muted" [translate]="'documentosFiscais.strings.naoDisponivel'"></i></span>
                    </ng-container>
                  </div>
                </dx-data-grid>
              </div>
            </pl-tab>
          </pl-tabs>
        </ng-container>

        <ng-container *ngIf="activeMenu === mainMenuTypes.RETENCOES">
          <pl-tabs [theme]="'style3'">
            <pl-tab caption="documentosFiscais.tabRetencoesIRCIRS">
              <div *plTabContent>
                <pl-group class="pl-group-inline">
                  <label [translate]="'global.text.year'"></label>
                  <edit style="max-width: 80px">
                    <pl-select [clearable]="false" [model]="filterAno" (modelChange)="onYearChange($event)" [source]="anosSource" [rowTemplate]="anosRowTemplate" [output]="anosOutput"></pl-select>
                  </edit>
                  <actions>
                    <pl-button type="button" klass="btn-primary" (evtClicked)="fnTabRetencoesIRCIRSGetList()">
                      <i class="fa fa-download"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.get'"></span>
                    </pl-button>
                  </actions>
                </pl-group>

                <dx-data-grid
                  [cgDxDataGrid]="modules.retencoesIRCIRS.dataGridDefinition"
                  [cgDxDataGridInstanceName]="modules.retencoesIRCIRS.dataGridInstanceName"
                  [dataSource]="modules.retencoesIRCIRS.dataGridDefinition.dataSource"
                  (onInitialized)="onDataGridInitialized($event, modules.retencoesIRCIRS)">
                  <div *dxTemplate="let item of 'cellTemplateComprovativo'">
                    <ng-container *ngIf="item.data._hasComprovativo">
                      <pl-button
                        klass="btn btn-secondary btn-xs me-2"
                        (evtClicked)="showRetencoesIRCIRSComprovativoPdf(item.data)"
                        [tooltip]="{text: 'documentosFiscais.strings.verDocumento', container: 'body'}">
                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </pl-button>
                      <pl-button
                        klass="btn btn-secondary btn-xs"
                        [tooltip]="{
                          container: 'body',
                          text: empresaTemContabilidadeDigital
                            ? 'documentosFiscais.strings.associarDocumentoContabDigital'
                            : !licencaStoreModePublic
                            ? ('arquivodigital.errors.licenseNotActivated' | translate)
                            : ('arquivodigital.errors.licenseNotActivatedCGOn' | translate: {href: cgStoreUrlBackOffice})
                        }"
                        [disabled]="!empresaTemContabilidadeDigital"
                        [onClick]="associarDocumentoContabDigital(modules.retencoesIRCIRS, item.data, '')">
                        <i class="fa pl-icon-adicionar-docs" aria-hidden="true"></i>
                      </pl-button>
                    </ng-container>
                    <ng-container *ngIf="!item.data._hasComprovativo">
                      <span><i style="font-size: 11px" class="text-muted" [translate]="'documentosFiscais.strings.naoDisponivel'"></i></span>
                    </ng-container>
                  </div>
                </dx-data-grid>
              </div>
            </pl-tab>
          </pl-tabs>
        </ng-container>

        <ng-container *ngIf="activeMenu === mainMenuTypes.IUC">
          <pl-tabs [theme]="'style3'">
            <pl-tab caption="documentosFiscais.tabIUCDocumentos">
              <div *plTabContent>
                <pl-group class="pl-group-inline">
                  <label [translate]="'global.text.year'"></label>
                  <edit style="max-width: 80px">
                    <pl-select [clearable]="false" [model]="filterAno" (modelChange)="onYearChange($event)" [source]="anosSource" [rowTemplate]="anosRowTemplate" [output]="anosOutput"></pl-select>
                  </edit>
                  <actions>
                    <pl-button type="button" klass="btn-primary" (evtClicked)="fnTabIUCDocumentosList()">
                      <i class="fa fa-download"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.get'"></span>
                    </pl-button>
                  </actions>
                </pl-group>

                <dx-data-grid
                  [cgDxDataGrid]="modules.iuc.dataGridDefinition"
                  [cgDxDataGridInstanceName]="modules.iuc.dataGridInstanceName"
                  [dataSource]="modules.iuc.dataGridDefinition.dataSource"
                  (onInitialized)="onDataGridInitialized($event, modules.iuc)">
                  <div *dxTemplate="let item of 'cellTemplatePagamento'">
                    <ng-container *ngIf="item.data._hasDocPagamento">
                      <pl-button klass="btn btn-secondary btn-xs me-2" (evtClicked)="showIUCPagamentoPdf(item.data)" [tooltip]="{text: 'documentosFiscais.strings.verDocumento', container: 'body'}">
                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </pl-button>
                      <pl-button
                        klass="btn btn-secondary btn-xs"
                        [tooltip]="{
                          container: 'body',
                          text: empresaTemContabilidadeDigital
                            ? 'documentosFiscais.strings.associarDocumentoContabDigital'
                            : !licencaStoreModePublic
                            ? ('arquivodigital.errors.licenseNotActivated' | translate)
                            : ('arquivodigital.errors.licenseNotActivatedCGOn' | translate: {href: cgStoreUrlBackOffice})
                        }"
                        [disabled]="!empresaTemContabilidadeDigital"
                        [onClick]="associarDocumentoContabDigital(modules.iuc, item.data, '')">
                        <i class="fa pl-icon-adicionar-docs" aria-hidden="true"></i>
                      </pl-button>
                    </ng-container>
                    <ng-container *ngIf="!item.data._hasDocPagamento">
                      <span><i style="font-size: 11px" class="text-muted" [translate]="'documentosFiscais.strings.naoDisponivel'"></i></span>
                    </ng-container>
                  </div>

                  <div *dxTemplate="let item of 'detail'">
                    <dx-data-grid
                      [cgDxDataGrid]="modules.iuc.detailsDataGridDefinition"
                      [cgDxDataGridInstanceName]="modules.iuc.detailsDataGridInstanceName"
                      (onCellClick)="onDataGridCellClickSimpleExpand($event)"
                      [dataSource]="[item.data.details]">
                      <div *dxTemplate="let itemDetails of 'liquidacoes'">
                        <dx-data-grid
                          [cgDxDataGrid]="modules.iuc.liquidacoesDataGridDefinition"
                          [cgDxDataGridInstanceName]="modules.iuc.liquidacoesDataGridInstanceName"
                          (onCellClick)="onDataGridCellClickSimpleExpand($event)"
                          [dataSource]="itemDetails.data.liquidacoes">
                          <div *dxTemplate="let item of 'cellTemplateComprovativo'">
                            <pl-button klass="btn btn-secondary btn-xs" (evtClicked)="showIUCLiquidacaoComprovativoPdf(item.data)">
                              <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                            </pl-button>
                          </div>
                        </dx-data-grid>
                      </div>
                    </dx-data-grid>
                  </div>
                </dx-data-grid>
              </div>
            </pl-tab>
            <pl-tab caption="documentosFiscais.tabIUCEmitir">
              <div *plTabContent>
                <div class="mb-4">
                  <pl-edit
                    type="radio"
                    [model]="modules.iuc.emitirGrupo"
                    (modelChange)="emitirGrupoChanged($event)"
                    [properties]="{value: iucEmitirGrupos.GRUPO_LIGEIROS, label: 'documentosFiscais.strings.iucEmitirLigeiros'}">
                  </pl-edit>
                  <div class="mt-2 documentos-fiscais-iuc-emitir-desc-grupo">
                    <p [translate]="'documentosFiscais.modules.iuc.emitir.catA'"></p>
                    <p [translate]="'documentosFiscais.modules.iuc.emitir.catB'"></p>
                    <p [translate]="'documentosFiscais.modules.iuc.emitir.catE'"></p>
                  </div>
                </div>

                <div class="mb-3">
                  <pl-edit
                    type="radio"
                    [model]="modules.iuc.emitirGrupo"
                    (modelChange)="emitirGrupoChanged($event)"
                    [properties]="{value: iucEmitirGrupos.GRUPO_MERCADORIAS, label: 'documentosFiscais.strings.iucEmitirMercadorias'}">
                  </pl-edit>
                  <div class="mt-2 documentos-fiscais-iuc-emitir-desc-grupo">
                    <p [translate]="'documentosFiscais.modules.iuc.emitir.catC'"></p>
                    <p [translate]="'documentosFiscais.modules.iuc.emitir.catD'"></p>
                  </div>
                </div>

                <div class="mt-2 mb-2">
                  <pl-button type="button" klass="btn-primary" (evtClicked)="fnTabIUCEmitirList()">
                    <i class="fa fa-download"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.search'"></span>
                  </pl-button>
                </div>

                <dx-data-grid
                  [cgDxDataGrid]="modules.iuc.emitirDataGridDefinition"
                  [cgDxDataGridInstanceName]="modules.iuc.emitirDataGridInstanceName"
                  [dataSource]="modules.iuc.emitirDataGridDefinition.dataSource"
                  (onInitialized)="onDataGridInitializedIUCModule($event, modules.iuc)">
                  <div *dxTemplate="let item of 'cellTemplateActions'">
                    <ng-container *ngIf="item.data._canIssue">
                      <pl-button klass="btn btn-success btn-xs" (evtClicked)="emitirIUC(item.data)">
                        <i class="fa fa-bolt me-1" aria-hidden="true"></i><span [translate]="'documentosFiscais.strings.emitir'"></span>
                      </pl-button>
                    </ng-container>
                    <ng-container *ngIf="!item.data._canIssue">
                      <span><i style="font-size: 11px" class="text-muted" [translate]="'documentosFiscais.strings.emitido'"></i></span>
                    </ng-container>
                  </div>
                </dx-data-grid>
              </div>
            </pl-tab>
          </pl-tabs>
        </ng-container>

        <ng-container *ngIf="activeMenu === mainMenuTypes.VEICULOS">
          <pl-tabs [theme]="'style3'">
            <pl-tab caption="documentosFiscais.tabVeiculos">
              <div *plTabContent>
                <dx-data-grid
                  [cgDxDataGrid]="modules.veiculos.dataGridDefinition"
                  [cgDxDataGridInstanceName]="modules.veiculos.dataGridInstanceName"
                  [dataSource]="modules.veiculos.dataGridDefinition.dataSource"
                  (onInitialized)="onDataGridInitialized($event, modules.veiculos)">
                  <div *dxTemplate="let cell of 'templateToolbarObterButton'">
                    <pl-button type="button" klass="btn-primary btn-sm" (evtClicked)="fnTabVeiculosGetList()">
                      <i class="fa fa-download"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.get'"></span>
                    </pl-button>
                  </div>
                </dx-data-grid>
              </div>
            </pl-tab>
          </pl-tabs>
        </ng-container>

        <ng-container *ngIf="activeMenu === mainMenuTypes.CERTIDOES">
          <pl-tabs [theme]="'style3'">
            <pl-tab caption="documentosFiscais.tabConsultarCertidoes">
              <div *plTabContent>
                <pl-group class="pl-group-inline">
                  <label>Data de:</label>
                  <edit style="max-width: 150px">
                    <pl-edit-datepicker [(model)]="modules.certidoes.startDate" plAutoFocus></pl-edit-datepicker>
                  </edit>
                </pl-group>
                <pl-group class="pl-group-inline">
                  <label>até:</label>
                  <edit style="max-width: 150px">
                    <pl-edit-datepicker [(model)]="modules.certidoes.untilDate"></pl-edit-datepicker>
                  </edit>
                </pl-group>
                <pl-group class="pl-group-inline">
                  <label>Tipo:</label>
                  <edit style="max-width: 180px">
                    <pl-edit type="select" [(model)]="modules.certidoes.tipo" [properties]="{select: {valueProp: 'tipo', labelProp: 'descricao', list: certidoesTipoList}, disallowClear: true}">
                    </pl-edit>
                  </edit>
                </pl-group>
                <pl-group class="pl-group-inline">
                  <label>Estado:</label>
                  <edit style="max-width: 150px">
                    <pl-edit type="select" [(model)]="modules.certidoes.estado" [properties]="{select: {valueProp: 'estado', labelProp: 'descricao', list: certidoesEstadoList}, disallowClear: true}">
                    </pl-edit>
                  </edit>
                  <actions>
                    <pl-button attrName="btnObter" type="button" klass="btn-primary" (evtClicked)="fnTabCertidoesGetList()">
                      <i class="fa fa-download"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.get'"></span>
                    </pl-button>
                  </actions>
                </pl-group>

                <dx-data-grid
                  [cgDxDataGrid]="modules.certidoes.dataGridDefinition"
                  [cgDxDataGridInstanceName]="modules.certidoes.dataGridInstanceName"
                  [dataSource]="modules.certidoes.dataGridDefinition.dataSource"
                  (onInitialized)="onDataGridInitialized($event, modules.certidoes)">
                  <div *dxTemplate="let item of 'cellTemplateComprovativo'">
                    <pl-button klass="btn btn-secondary btn-xs" (evtClicked)="showCertidaoPdf(item.data)">
                      <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                    </pl-button>
                  </div>
                </dx-data-grid>
              </div>
            </pl-tab>
            <pl-tab caption="documentosFiscais.tabEmitirCertidoes">
              <div *plTabContent>
                <div class="d-flex">
                  <div class="docs-fiscais-radio-group-vertical" style="max-width: 350px">
                    <pl-group>
                      <label class="mb-2" [translate]="'documentosFiscais.fields.tipoCertidao'"></label>
                      <edit>
                        <pl-edit type="radiogroup" [(model)]="modules.certidoes.pedidoTipoCertidao" [properties]="pedidoTipoCertidaoGroup"></pl-edit>
                      </edit>
                    </pl-group>

                    <pl-group class="pl-group-inline" *ngIf="modules.certidoes.pedidoTipoCertidao === certidoesTipos.LiquidacaoIRS">
                      <label [translate]="'global.text.year'"></label>
                      <edit style="max-width: 80px">
                        <pl-select
                          [clearable]="false"
                          [model]="modules.certidoes.pedidoLiqIRSAno"
                          (modelChange)="onYearChange($event)"
                          [source]="anosSource"
                          [rowTemplate]="anosRowTemplate"
                          [output]="anosOutput"></pl-select>
                      </edit>
                    </pl-group>
                  </div>
                  <div>
                    <pl-group class="pl-group-inline">
                      <pl-button type="button" klass="btn-primary btn-sm" (evtClicked)="fnTabCertidoesEmitir()">
                        <i class="fa fa-download"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.efetuarPedido'"></span>
                      </pl-button>
                    </pl-group>
                  </div>
                </div>
              </div>
            </pl-tab>
          </pl-tabs>
        </ng-container>

        <ng-container *ngIf="activeMenu === mainMenuTypes.IMOVEIS">
          <pl-tabs [theme]="'style3'">
            <pl-tab caption="documentosFiscais.tabImoveisPP">
              <div *plTabContent>
                <dx-data-grid
                  [cgDxDataGrid]="modules.imoveis.dataGridDefinition"
                  [cgDxDataGridInstanceName]="modules.imoveis.dataGridInstanceName"
                  [dataSource]="modules.imoveis.dataGridDefinition.dataSource"
                  (onInitialized)="onDataGridInitialized($event, modules.imoveis)">
                  <div *dxTemplate="let cell of 'templateToolbarObterButton'">
                    <pl-button type="button" klass="btn-primary btn-sm" (evtClicked)="fnTabImoveisPPGetList()">
                      <i class="fa fa-download"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.get'"></span>
                    </pl-button>
                  </div>

                  <div *dxTemplate="let item of 'cellTemplateCadernetaPredial'">
                    <pl-button klass="btn btn-secondary btn-xs" (evtClicked)="showCadernetaPredialPdf(item.data)">
                      <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                    </pl-button>
                  </div>
                </dx-data-grid>
              </div>
            </pl-tab>
            <pl-tab caption="documentosFiscais.tabImoveisNC">
              <div *plTabContent>
                <pl-group class="pl-group-inline">
                  <label [translate]="'global.text.year'"></label>
                  <edit style="max-width: 80px">
                    <pl-select [clearable]="false" [model]="filterAno" (modelChange)="onYearChange($event)" [source]="anosSource" [rowTemplate]="anosRowTemplate" [output]="anosOutput"></pl-select>
                  </edit>
                  <actions>
                    <pl-button type="button" klass="btn-primary" (evtClicked)="fnTabImoveisNCGetList()">
                      <i class="fa fa-download"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.get'"></span>
                    </pl-button>
                  </actions>
                </pl-group>

                <dx-data-grid
                  [cgDxDataGrid]="modules.imoveis.ncDataGridDefinition"
                  [cgDxDataGridInstanceName]="modules.imoveis.ncDataGridInstanceName"
                  [dataSource]="modules.imoveis.ncDataGridDefinition.dataSource"
                  (onInitialized)="onDataGridInitializedImoveisModule($event, modules.imoveis)">
                  <div *dxTemplate="let item of 'cellTemplateGuia'">
                    <ng-container *ngIf="item.data._haveGuia">
                      <pl-button klass="btn btn-secondary btn-xs me-2" (evtClicked)="showImoveisNCGuiaPdf(item.data)" [tooltip]="{text: 'documentosFiscais.strings.verDocumento', container: 'body'}">
                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </pl-button>
                      <pl-button
                        klass="btn btn-secondary btn-xs"
                        [tooltip]="{
                          container: 'body',
                          text: empresaTemContabilidadeDigital
                            ? 'documentosFiscais.strings.associarDocumentoContabDigital'
                            : !licencaStoreModePublic
                            ? ('arquivodigital.errors.licenseNotActivated' | translate)
                            : ('arquivodigital.errors.licenseNotActivatedCGOn' | translate: {href: cgStoreUrlBackOffice})
                        }"
                        [disabled]="!empresaTemContabilidadeDigital"
                        [onClick]="associarDocumentoContabDigital(modules.imoveis, item.data, '')">
                        <i class="fa pl-icon-adicionar-docs" aria-hidden="true"></i>
                      </pl-button>
                    </ng-container>
                    <ng-container *ngIf="!item.data._haveGuia">
                      <span><i style="font-size: 11px" class="text-muted" [translate]="'documentosFiscais.strings.naoDisponivel'"></i></span>
                    </ng-container>
                  </div>
                </dx-data-grid>
              </div>
            </pl-tab>
          </pl-tabs>
        </ng-container>

        <ng-container *ngIf="activeMenu === mainMenuTypes.PENHORAS">
          <pl-tabs [theme]="'style3'">
            <pl-tab caption="documentosFiscais.tabPenhoras">
              <div *plTabContent>
                <dx-data-grid
                  [cgDxDataGrid]="modules.penhoras.dataGridDefinition"
                  [cgDxDataGridInstanceName]="modules.penhoras.dataGridInstanceName"
                  [dataSource]="modules.penhoras.dataGridDefinition.dataSource"
                  (onInitialized)="onDataGridInitialized($event, modules.penhoras)">
                  <div *dxTemplate="let cell of 'templateToolbarObterButton'">
                    <pl-button type="button" klass="btn-primary btn-sm" (evtClicked)="fnTabPenhorasGetList()">
                      <i class="fa fa-download"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.get'"></span>
                    </pl-button>
                  </div>

                  <div *dxTemplate="let item of 'detail'">
                    <dx-data-grid
                      [cgDxDataGrid]="modules.penhoras.detailsDataGridDefinition"
                      [cgDxDataGridInstanceName]="modules.penhoras.detailsDataGridInstanceName"
                      (onCellClick)="onDataGridCellClickSimpleExpand($event)"
                      [dataSource]="item.data.detalhes">
                    </dx-data-grid>
                  </div>
                </dx-data-grid>
              </div>
            </pl-tab>
          </pl-tabs>
        </ng-container>

        <ng-container *ngIf="activeMenu === mainMenuTypes.SS_SITUACAO_CONTRIB">
          <pl-tabs [theme]="'style3'">
            <pl-tab caption="documentosFiscais.tabSSSituacaoContrib">
              <div *plTabContent>
                <pl-alert theme="pl-alert-sm" type="info" [closeable]="false">
                  <i class="fa fa-info-circle me-1" aria-hidden="true"></i><span [translate]="'documentosFiscais.strings.ssSitContribInfoText'"></span>
                </pl-alert>

                <dx-data-grid
                  [cgDxDataGrid]="modules.sssitcontrib.dataGridDefinition"
                  [cgDxDataGridInstanceName]="modules.sssitcontrib.dataGridInstanceName"
                  [dataSource]="modules.sssitcontrib.dataGridDefinition.dataSource"
                  (onInitialized)="onDataGridInitialized($event, modules.sssitcontrib)">
                  <div *dxTemplate="let cell of 'templateToolbarObterButton'">
                    <pl-button type="button" klass="btn-primary btn-sm" (evtClicked)="fnTabSSSituacaoContribGetList()">
                      <i class="fa fa-download"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.get'"></span>
                    </pl-button>
                  </div>

                  <div *dxTemplate="let item of 'cellTemplateComprovativo'">
                    <ng-container *ngIf="item.data.haveDocument">
                      <pl-button klass="btn btn-secondary btn-xs" (evtClicked)="showSSSitContribPdf(item.data)">
                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </pl-button>
                    </ng-container>

                    <ng-container *ngIf="!item.data.haveDocument">
                      <span><i style="font-size: 11px" class="text-muted" [translate]="'documentosFiscais.strings.naoDisponivel'"></i></span>
                    </ng-container>
                  </div>
                </dx-data-grid>
              </div>
            </pl-tab>
          </pl-tabs>
        </ng-container>

        <ng-container *ngIf="activeMenu === mainMenuTypes.VIACTT_CAIXA_CORREIO">
          <pl-tabs [theme]="'style3'">
            <pl-tab caption="documentosFiscais.tabViaCTTInbox">
              <div *plTabContent>
                <pl-alert theme="pl-alert-sm" type="info" [closeable]="false">
                  <i class="fa fa-info-circle me-1" aria-hidden="true"></i><span [translate]="'documentosFiscais.strings.viaCTTInfoText'"></span>
                </pl-alert>

                <dx-data-grid
                  [cgDxDataGrid]="modules.viactt.dataGridDefinition"
                  [cgDxDataGridInstanceName]="modules.viactt.dataGridInstanceName"
                  [dataSource]="modules.viactt.dataGridDefinition.dataSource"
                  (onInitialized)="onDataGridInitialized($event, modules.viactt)">
                  <div *dxTemplate="let cell of 'templateToolbarObterButton'">
                    <pl-button type="button" klass="btn-primary btn-sm" (evtClicked)="fnTabViaCTTInboxGetList()">
                      <i class="fa fa-download"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.get'"></span>
                    </pl-button>
                  </div>

                  <div *dxTemplate="let item of 'cellTemplateComprovativo'">
                    <pl-button klass="btn btn-secondary btn-xs" (evtClicked)="showViaCTTDocPdf(item.data)">
                      <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                    </pl-button>
                  </div>
                </dx-data-grid>
              </div>
            </pl-tab>
          </pl-tabs>
        </ng-container>
      </div>
    </div>
  </div>
</div>
