<div class="utilizadores-basic-edit">
  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [formInstance]="form?.control"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: !callback.isEditing(), validators: {required: {value: true}}}" (ngFormChange)="form = $event">
        <pl-group>
          <label [translate]="'users.fields.formUsername'"></label>
          <edit>
            <pl-edit type="string" attrName="username" [(model)]="model.username" plAutoFocus></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'users.fields.firstName'"></label>
          <edit>
            <pl-edit type="string" attrName="firstName" [(model)]="model.firstName"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'users.fields.lastName'"></label>
          <edit>
            <pl-edit type="string" attrName="lastName" [(model)]="model.lastName" [properties]="{validators: {required: {value: false}}}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'users.fields.formEmail'"></label>
          <edit>
            <pl-edit type="email" attrName="email" [(model)]="model.email"></pl-edit>
          </edit>
        </pl-group>

        <pl-group *ngIf="administrator">
          <label [translate]="'users.fields.administrator'"></label>
          <edit>
            <pl-edit type="boolean" attrName="administrator" [(model)]="model.administrator" [properties]="{validators: {required: {value: false}}}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'users.fields.active'"></label>
          <edit>
            <pl-edit type="boolean" attrName="activo" [(model)]="model.active"></pl-edit>
          </edit>
        </pl-group>

        <!--<pl-group *ngIf="type === detailStateTypes.NEW">
          <label [translate]="'users.actions.sendEmail'"></label>
          <edit>
            <pl-edit type="boolean" attrName="sendEmail" [(model)]="model.sendEmail"></pl-edit>
          </edit>
        </pl-group>-->

        <pl-group *ngIf="erps.length > 1">
          <label [translate]="'usersbasic.fields.instalacao'"></label>
          <edit>
            <pl-autocomplete
              attrName="instalacao"
              [source]="erps"
              [model]="instalacao"
              (modelChange)="changedInstalacao($event)"
              [rowTemplate]="'{{name}} - {{apiUrl}}'"
              [output]="'name'"
              [properties]="{readonly: type !== detailStateTypes.NEW}">
            </pl-autocomplete>
          </edit>
        </pl-group>

        <pl-group *ngIf="type === detailStateTypes.NEW">
          <label [translate]="'usersbasic.text.createUtilizador'"></label>
          <edit>
            <pl-edit type="boolean" attrName="createUtilizador" [(model)]="model.createUtilizador"></pl-edit>
          </edit>
        </pl-group>

        <pl-group *ngIf="type !== detailStateTypes.NEW || !model.createUtilizador">
          <label [translate]="'usersbasic.fields.utilizador'"></label>
          <edit>
            <entity-autocomplete entity="erputilizadores" attrName="utilizador" [(model)]="utilizadorErp" [getDataConfig]="configUtilizadorErp"></entity-autocomplete>
          </edit>
        </pl-group>

        <!-- Installations -->
        <user-installations
          [installation]="instalacao?.centralGestId"
          [userWithAccesses]="model"
          [erps]="erps"
          [new]="isNew"
          [disabled]="!callback.isEditing()"
          [applyToModelOnChanged]="false"
          [callback]="callbackUserInstallations">
        </user-installations>
      </pl-form>
    </div>
  </entity-detail>
</div>
