import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonPlanoContasAlternativoCubo} from '../../jsonPlanoContasAlternativo.entity.interface';
import {
  ENTITY_NAME_PLANOS_CONTAS_ALTERNATIVOS,
  EPlanoContasAltCuboLayout,
  IFiltersPlanoContasAltCubo,
  IPlanoContasAltCuboLayout,
  IPlanosContasAlternativosEntityService
} from '../../planosContasAlternativos.entity.interface';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {IPlDynamicVisualsSecondaryClickAction, isEmpty, KEYCODES, PlAlertService} from 'pl-comps-angular';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';
import {IDevExpressPivotGrid} from '../../../../components/devexpress/pivotgrid/devexpress.pivotgrid.interface';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import {HttpResponse} from '@angular/common/http';
import {devExpressGenerateFormatDouble} from '../../../../components/devexpress/widget/devexpress.widget.utilities';
import {TranslateService} from '@ngx-translate/core';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {PlanosContasAlternativosExtratoModalComponent} from '../extrato/planosContasAlternativos.extrato.modal.component';
import {DevExpressDataGridUIService} from '../../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import DataSource from 'devextreme/data/data_source';
import DxPivotGrid, {ContextMenuPreparingEvent, InitializedEvent} from 'devextreme/ui/pivot_grid';
import {IDevExpressPivotGridEventOnExporting} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressPivotGridExportToExcel} from '../../../../components/devexpress/pivotgrid/export/devexpress.pivotgrid.export';

const TIPO_CONTA_MOVIMENTO = '0';

@Component({
  selector: 'planos-contas-alternativos-cubo-modal',
  templateUrl: './planosContasAlternativos.cubo.modal.component.html'
})
export class PlanosContasAlternativosCuboModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public filters: IFiltersPlanoContasAltCubo;

  public readonly pivotGridDefinition: IDevExpressPivotGrid;
  public readonly filterPocs: string;
  public readonly filterPeriodos: string;
  public readonly cuboLayoutSource: Array<IPlanoContasAltCuboLayout>;

  public pivotGridDataSource: PivotGridDataSource;
  public drillDownDataSource: DataSource<IJsonPlanoContasAlternativoCubo>;
  public cuboLayout: IPlanoContasAltCuboLayout;

  private readonly _servicePlanosContasAlt: IPlanosContasAlternativosEntityService;

  private _cardPanel: CGCardPanelComponent;
  private _cuboPlanoContasAltList: Array<IJsonPlanoContasAlternativoCubo>;
  private _pivotGridInstance: DxPivotGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _translateService: TranslateService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._servicePlanosContasAlt = this._entityServiceBuilder.build(ENTITY_NAME_PLANOS_CONTAS_ALTERNATIVOS);
    this.filterPocs = `tipo=${ETipoContaContabilidade.Movimento}`;
    this.filterPeriodos = `periodo=%${this.configurations.empresa.anoEmCursoIRC}%|periodo=%${this.configurations.empresa.anoEmCursoIRC + 1}%`;
    this.pivotGridDefinition = {
      allowSortingBySummary: true,
      allowFiltering: true,
      showBorders: true,
      showRowGrandTotals: true,
      showRowTotals: false,
      showColumnGrandTotals: true,
      showColumnTotals: false,
      fieldChooser: {
        enabled: true,
        allowSearch: true,
        layout: 1
      },
      fieldPanel: {
        showDataFields: true,
        showRowFields: true,
        showColumnFields: true,
        showFilterFields: true,
        allowFieldDragging: true,
        visible: true
      },
      export: {enabled: true},
      scrolling: {
        mode: 'virtual'
      },
      stateStoring: {
        enabled: true,
        type: 'localStorage',
        storageKey: 'plano-contas-alternativo-cubo'
      },
      texts: {
        grandTotal: 'Total'
      }
    };
    this.cuboLayout = {layout: EPlanoContasAltCuboLayout.SALDO_GERAL, caption: this._translateService.instant('planoscontasalternativos.modals.cubo.layoutCubo.saldoGeral')};
    this.cuboLayoutSource = [
      {layout: EPlanoContasAltCuboLayout.SALDO_GERAL, caption: 'planoscontasalternativos.modals.cubo.layoutCubo.saldoGeral'},
      {layout: EPlanoContasAltCuboLayout.SALDO_CRED_DEB, caption: 'planoscontasalternativos.modals.cubo.layoutCubo.saldoCredDeb'}
    ];
    this.pesquisarCubo = this.pesquisarCubo.bind(this);
  }

  public ngOnInit(): void {
    const filters: IFiltersPlanoContasAltCubo = {
      deConta: '1',
      ateConta: '99999999',
      dePeriodo: `${this.configurations.empresa.anoEmCursoIRC}010`,
      dePeriodoNome: '',
      atePeriodo: `${this.configurations.empresa.anoEmCursoIRC + 1}123`,
      atePeriodoNome: '',
      pocAltCabID: '',
      nomePocAlt: '',
      escondeRubricasSemMovimento: false
    };
    this.filters = {...filters, ...this.filters};
  }

  public pesquisarCubo(): Promise<void> {
    if (isEmpty(this.filters.pocAltCabID)) {
      this._plAlertService.error('planoscontasalternativos.messages.notSelectPlanoContAlt');
      return Promise.resolve();
    }

    return this._servicePlanosContasAlt
      .cuboPlanoContasAlt(this.filters.pocAltCabID, this.filters.dePeriodo, this.filters.atePeriodo, this.filters.deConta, this.filters.ateConta, this.filters.escondeRubricasSemMovimento)
      .then((response: HttpResponse<Array<IJsonPlanoContasAlternativoCubo>>) => {
        this._cuboPlanoContasAltList = response.body;
        this._applyPivotGrid(this._cuboPlanoContasAltList);
        this._cardPanel.collapse();
      });
  }

  public cuboLayoutChanged(cuboLayout: IPlanoContasAltCuboLayout): void {
    this.cuboLayout = cuboLayout;
    const isSaldoGeral = this.cuboLayout.layout === EPlanoContasAltCuboLayout.SALDO_GERAL;

    this._pivotGridInstance.getDataSource().field('saldoGeral', {
      area: isSaldoGeral ? 'data' : 'filter'
    });
    this._pivotGridInstance.getDataSource().field('saldoDebito', {
      area: isSaldoGeral ? 'filter' : 'data'
    });
    this._pivotGridInstance.getDataSource().field('saldoCredito', {
      area: isSaldoGeral ? 'filter' : 'data'
    });
    this._pivotGridInstance.getDataSource().reload();
  }

  public onInitializedPivotGrid(event: InitializedEvent): void {
    this._pivotGridInstance = event.component;
  }

  public onContextMenuPreparingPivotGrid(event: ContextMenuPreparingEvent): void {
    event.items = [];
    if (event.area === 'data') {
      this.drillDownDataSource = this.pivotGridDataSource.createDrillDownDataSource(event.cell);

      this.drillDownDataSource.load().then((item: Array<IJsonPlanoContasAlternativoCubo>) => {
        if (item[0].tipo !== TIPO_CONTA_MOVIMENTO) {
          return;
        }

        const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActions(item[0].nconta, item[0].periodo, item[0].periodo);
        this._devExpressDataGridUIService.openContextMenu(event.cellElement, actions);
      });
    }
  }

  public onExporting(event: IDevExpressPivotGridEventOnExporting): void {
    devExpressPivotGridExportToExcel(event, this._translateService);
  }

  public readonly fnKeydownProcessar = (value: string, event: KeyboardEvent): void => {
    this._keydownProcessar(event);
  };

  @ViewChild('cardPanelCubo')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _keydownProcessar(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      const button: HTMLElement = this._element.querySelector('button.action-search');
      if (button) {
        setTimeout(() => {
          button.focus();
        });
      }
    }
  }

  private _applyPivotGrid(store: Array<IJsonPlanoContasAlternativoCubo>): void {
    const format: string = devExpressGenerateFormatDouble(2);
    const isSaldoGeral = this.cuboLayout.layout === EPlanoContasAltCuboLayout.SALDO_GERAL;

    this.pivotGridDataSource = new PivotGridDataSource({
      fields: [
        {
          dataField: 'ncontaNome',
          area: 'row',
          width: 150,
          expanded: true,
          caption: this._translateService.instant('planoscontasalternativos.modals.cubo.fields.ncontaNome'),
          areaIndex: 0
        },
        {
          dataField: 'nmesNome',
          dataType: 'string',
          area: 'column',
          expanded: true,
          caption: this._translateService.instant('planoscontasalternativos.modals.cubo.fields.nmesNome'),
          areaIndex: 0
        },
        {
          dataField: 'saldoGeral',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          area: isSaldoGeral ? 'data' : 'filter',
          caption: this._translateService.instant('planoscontasalternativos.modals.cubo.fields.saldoGeral')
        },
        {
          dataField: 'saldoDebito',
          dataType: 'number',
          format: format,
          summaryType: 'sum',
          area: isSaldoGeral ? 'filter' : 'data',
          caption: this._translateService.instant('planoscontasalternativos.modals.cubo.fields.saldoDebito')
        },
        {
          dataField: 'saldoCredito',
          dataType: 'number',
          format: format,
          summaryType: 'sum',
          area: isSaldoGeral ? 'filter' : 'data',
          caption: this._translateService.instant('planoscontasalternativos.modals.cubo.fields.saldoCredito')
        },
        {
          dataField: 'acumuladoDebito',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('planoscontasalternativos.modals.cubo.fields.acumuladoDebito')
        },
        {
          dataField: 'acumuladoCredito',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('planoscontasalternativos.modals.cubo.fields.acumuladoCredito')
        },
        {
          dataField: 'periodo',
          dataType: 'string',
          caption: this._translateService.instant('planoscontasalternativos.modals.cubo.fields.periodo')
        },
        {
          dataField: 'nconta',
          dataType: 'string',
          caption: this._translateService.instant('planoscontasalternativos.modals.cubo.fields.nconta')
        },
        {
          dataField: 'nome',
          dataType: 'string',
          caption: this._translateService.instant('planoscontasalternativos.modals.cubo.fields.nome')
        },
        {
          dataField: 'ano',
          dataType: 'number',
          caption: this._translateService.instant('planoscontasalternativos.modals.cubo.fields.ano')
        },
        {
          dataField: 'mes',
          dataType: 'number',
          caption: this._translateService.instant('planoscontasalternativos.modals.cubo.fields.mes')
        },
        {
          dataField: 'periodoDebito',
          dataType: 'number',
          caption: this._translateService.instant('planoscontasalternativos.modals.cubo.fields.periodoDebito')
        },
        {
          dataField: 'periodoCredito',
          dataType: 'number',
          caption: this._translateService.instant('planoscontasalternativos.modals.cubo.fields.periodoCredito')
        },
        {
          dataField: 'nomeNConta',
          dataType: 'string',
          caption: this._translateService.instant('planoscontasalternativos.modals.cubo.fields.nomeNConta')
        }
      ],
      store: store,
      retrieveFields: false
    });
  }

  private _generateSecondaryClickActions(nConta: string, dePeriodo: string, atePeriodo: string): Array<IPlDynamicVisualsSecondaryClickAction> {
    const actions: Array<IPlDynamicVisualsSecondaryClickAction> = [];
    actions.push(
      {
        caption: 'balancetes.btn.dropdown.extratoPlanoAlt',
        click: () => {
          this._openExtratoPlanoAlt(nConta, dePeriodo, atePeriodo, false);
        }
      },
      {
        caption: 'balancetes.btn.dropdown.acumuPlanoAlt',
        click: () => {
          this._openExtratoPlanoAlt(nConta, dePeriodo, atePeriodo, true);
        }
      }
    );
    return actions;
  }

  private _openExtratoPlanoAlt(nconta: string, dePeriodo: string, atePeriodo: string, acumulado: boolean): void {
    const modalInstance = this._cgModalService.showVanilla(PlanosContasAlternativosExtratoModalComponent);
    const componentInstance: PlanosContasAlternativosExtratoModalComponent = modalInstance.componentInstance;
    componentInstance.pocAltCabID = this.filters.pocAltCabID;
    componentInstance.nconta = nconta;
    componentInstance.dePeriodo = dePeriodo;
    componentInstance.atePeriodo = atePeriodo;
    componentInstance.acumulado = acumulado;
  }
}
