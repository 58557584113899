export enum EPlCompsCrossOriginAttribute {
  Empty = '', // same as 'Anonymous'
  Anonymous = 'anonymous', // credentials flag set to 'same-origin'
  UseCredentials = 'use-credentials' // credentials flag set to 'include'
}

export enum EPlCompsReferrerPolicyAttribute {
  NoReferrer = 'no-referrer',
  NoReferrerWhenDowngrade = 'no-referrer-when-downgrade',
  Origin = 'origin',
  OriginWhenCrossOrigin = 'origin-when-cross-origin',
  SameOrigin = 'same-origin',
  StrictOrigin = 'strict-origin',
  StrictOriginWhenCrossOrigin = 'strict-origin-when-cross-origin',
  UnsafeUrl = 'unsafe-url'
}

export interface IPlCompsScript {
  [prop: string]: string | number | boolean | Document;

  src: string;
  async?: boolean;
  crossorigin?: EPlCompsCrossOriginAttribute;
  defer?: boolean;
  document?: Document;
  id?: string;
  integrity?: string;
  nomodule?: boolean;
  nonce?: string | number;
  referrerpolicy?: EPlCompsReferrerPolicyAttribute;
  type?: string | 'module';
}
