import {Moment} from 'moment';
import {TDate} from '../../../../common/dates';

export enum ETesRubricaReceitaDespesa {
  RECEITA = 0,
  DESPESA = 1,
  NONE = -1
}

export enum EAnalisadorTesRecordItemType {
  TOP,
  RUBRICA,
  CLIENT,
  RECORD
}

export enum EAnalisadorTesPeriodicidadeAnalise {
  DIARIA,
  SEMANAL,
  MENSAL
}

export enum EAnalisadorTesOrigemSaldoInicial {
  MANUAL,
  CONTABILIDADE,
  CGBANKING
}

export enum EAnalisadorTesLancoForeignOrigin {
  None,
  Contabilidade,
  Obras,
  Lotes
}

export interface IAnalisadorTesRecordData {
  data: string;
  valor: number;
  tesLancoID: string;
}

export interface IAnalisadorTesRecord {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;

  receitaDespesa: ETesRubricaReceitaDespesa;
  nomeRubrica: string;
  descicaoRubrica: string;
  tesRubricaId: number;
  groupId: number;
  itemType: EAnalisadorTesRecordItemType;
  keyId: string;
  nodeParentId: string;
  nodeId: string;
  data: Array<IAnalisadorTesRecordData>;
}

export interface IAnalisadorTesRecordWithItems extends IAnalisadorTesRecord {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  items: Array<IAnalisadorTesRecord | IAnalisadorTesRecordWithItems>;
  depth: number;
}

export interface IAnalisadorTesUIModel {
  saldoInicial: number;
  fromDate: Moment;
  toDate: Moment;
  periodoAnalise: EAnalisadorTesPeriodicidadeAnalise;
}

export interface IAnalisadorTesDateInterval {
  fromDate: Moment;
  toDate: Moment;
  value: number;
}

export interface IAnalisadorTesConfiguration {
  origemSaldoInicial: number;
  origemSaldoInicialContabContaDe: string;
  origemSaldoInicialContabContaAte: string;
  origemSaldoInicialCGBankingContaDe: string;
  origemSaldoInicialCGBankingContaAte: string;
  importLotes: boolean;
  lotesRubricaRec: number;
  lotesRubricaPag: number;
}

export interface IAnalisadorTesLanco {
  tesLancoID: string;
  data: TDate;
  descricao: string;
  nLanc: string;
  foreignOrigin: EAnalisadorTesLancoForeignOrigin;
  foreignKeyID: string;
  tesRubricaID: number;
  valor: number;
  nota: string;
  notaLastUpd: TDate;
}

export interface IAnalisadorTesLancoBase {
  toDate: TDate;
  nota: string;
}

export interface IAnalisadorTesLancoUpdateByNConta extends IAnalisadorTesLancoBase {
  fromDate: Array<string>;
}

export interface IAnalisadorTesCellSelectionItem {
  model: IAnalisadorTesRecord;
  cellData: IAnalisadorTesRecordData;
}

export interface IAnalisadorTesMultipleTesLancoItem {
  tesLancoID: string;
  data: IAnalisadorTesLancoBase;
}

export interface IAnalisadorTesRecibosPagamParameters {
  nConta: string;
  nome: string;
}

export interface IJsonAnalisadorTesValoresAntItem {
  tesLancoID: string;
  nomeRubrica: string;
  descricao: string;
  nDocString: string;
  nConta: string;
  nomeConta: string;
  valor: number;
  data: TDate;
  newDataTes: TDate;
}

export interface IJsonAnalisadorTesValoresAntSaveItem {
  tesLancoID: string;
  newDataTes: TDate;
}

export interface IJsonAnalisadorTesFACCBInfo {
  faccbId: number;
  docfaGrupoId: number;
}

export const MODULE_NAME_ANALISADOR_TES = 'analisadorTes';
