<div class="cg-pdf-viewer">
  <pl-pdf
    [pdfSrc]="pdfSrc"
    [externalLinkTarget]="externalLinkTarget"
    [filename]="filename"
    [contentType]="contentType"
    [page]="page"
    [renderText]="renderText"
    [renderTextMode]="renderTextMode"
    [rotation]="rotation"
    [showAll]="showAll"
    [showBorders]="showBorders"
    [stickToPage]="stickToPage"
    [toolbarInstanceId]="toolbarInstanceId"
    [zoomLevel]="zoomLevel"
    [callback]="callback"
    (pageChange)="changedPage($event)"
    (zoomLevelChange)="changedZoomLevel($event)"
    (evtConfigureToolbar)="configureToolbar($event)"
    (evtAfterLoadComplete)="afterLoadComplete($event)"
    (evtPageRendered)="pageRendered($event)"
    (evtTextLayerRendered)="textLayerRendered($event)"
    (evtError)="errored($event)"
    (evtOnProgress)="progress($event)"
    (evtStatus)="status($event)">
    <ng-container *ngIf="hideToolbar !== true">
      <div class="cg-pdf-viewer-header" *plPdfHeader>
        <pl-toolbar class="cg-pdf-viewer-toolbar" [instanceId]="toolbarInstanceId" (evtPageChanged)="changedPage($event)"> </pl-toolbar>
      </div>
    </ng-container>
  </pl-pdf>
</div>
