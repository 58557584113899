import {merge} from 'lodash-es';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {IPlToolbarItem, isNumber, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ConfigErpService} from '../../../../services/configErp.service';
import {EConfigERPGenDefaultDataState} from '../../../../entities/configserp/jsonConfigERP.entity.interface';
import {EErpCheckItemState, EErpCheckState, IConfigERPCheck} from '../erp.check.module.interface';
import {ErpCheckValidateAllModalComponent} from '../modals/validateallmodal/erp.check.validateAll.modal.component';
import {ModuloComponent} from '../../../../components/module/module.component';
import {TServiceResponse} from '../../../../services/api/api.service.interface';

const INTERVAL_TIMEOUT = 10000;
const STATUS_MAX_RETRIES = 10;

@Component({
  selector: 'module-erp-check',
  templateUrl: './erp.check.module.component.html'
})
export class ErpCheckModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public list: Array<IConfigERPCheck>;

  public readonly states: typeof EErpCheckState;
  public readonly itemStates: typeof EErpCheckItemState;
  public state: EErpCheckState;
  public activeItem: IConfigERPCheck;

  private readonly _btnValidate: IPlToolbarItem;
  private readonly _btnRepairAll: IPlToolbarItem;
  private _listToCheck: Array<IConfigERPCheck>;
  private _intervalCheckGenerateDefaultDataStatus: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _configErpService: ConfigErpService
  ) {
    super(_injector);
    this.states = EErpCheckState;
    this.itemStates = EErpCheckItemState;
    this.state = EErpCheckState.Validate;
    this._btnValidate = {
      id: 'erpCheckValidate',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-cogs"></i>',
      class: 'btn-info',
      caption: 'configsERPCheck.actions.validateAll',
      click: () => this._validateAll(),
      disabled: false,
      tooltip: {
        disabled: true,
        text: 'configsErp.check.disabledValidate',
        placement: 'bottom'
      }
    };
    this._btnRepairAll = {
      id: 'erpCheckRepair',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-check-square-o"></i>',
      class: 'btn-success',
      caption: 'configsERPCheck.actions.repairAll',
      click: () => this._repairAll(),
      disabled: false,
      tooltip: {
        disabled: true,
        text: 'configsErp.check.disabledRepair',
        placement: 'bottom'
      }
    };
    this._listToCheck = [];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnValidate).addButton(this._btnRepairAll);
    for (const config of this.list) {
      config._state = EErpCheckItemState.PendingValidate;
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._clearIntervalCheckGenerateDefaultDataStatus();
    // We empty this array as to stop execution in case `checkAllItems` was started
    this._listToCheck.splice(0, this._listToCheck.length);
  }

  public clickedItem(item: IConfigERPCheck): void {
    if (item._state !== EErpCheckItemState.Success) {
      item._showDetail = !item._showDetail;
    }
  }

  public async validateItem(item: IConfigERPCheck): Promise<void> {
    if (this.state !== EErpCheckState.Validating && item._state !== EErpCheckItemState.Success) {
      this._toState(EErpCheckState.Validating);
      await this._checkItem(item);
      this._toState(EErpCheckState.Finished);
    }
  }

  public async repairItem(item: IConfigERPCheck): Promise<void> {
    if (this.state !== EErpCheckState.Repair && item._state !== EErpCheckItemState.Success) {
      this._toState(EErpCheckState.Repair);
      await this._checkItem(item);
      this._toState(EErpCheckState.Finished);
    }
  }

  private async _validateAll(): Promise<void> {
    if (this.state === EErpCheckState.GenerateDefaultData || this.state === EErpCheckState.Validating) {
      return;
    }
    const shouldGenerateDefaultData: boolean = await this._cgModalService.show<boolean>(ErpCheckValidateAllModalComponent, {size: 'md'});
    if (shouldGenerateDefaultData) {
      await this._generateDefaultData();
    }
    this._toState(EErpCheckState.Validating);
    await this._checkAllItems();
  }

  private async _repairAll(): Promise<void> {
    if (this.state !== EErpCheckState.Repair) {
      this._toState(EErpCheckState.Repair);
      await this._checkAllItems();
    }
  }

  private _checkAllItems(): Promise<void> {
    this._listToCheck = this.list.filter((item: IConfigERPCheck) => item._state !== EErpCheckItemState.Success).reverse();
    return this._checkNextItem();
  }

  private _checkNextItem(): Promise<void> {
    const itemToCheck: IConfigERPCheck = this._listToCheck.pop();
    if (!itemToCheck) {
      this._toState(EErpCheckState.Finished);
      return Promise.resolve();
    }
    return this._checkItem(itemToCheck)
      .then(() => {
        return this._checkNextItem();
      })
      .catch(() => {
        return this._checkNextItem();
      });
  }

  private async _checkItem(item: IConfigERPCheck): Promise<void> {
    if (item._state === EErpCheckItemState.Success) {
      return Promise.resolve();
    }
    this.activeItem = item;
    let promise: TServiceResponse<IConfigERPCheck>;
    if (this.state === EErpCheckState.Validating) {
      item._state = EErpCheckItemState.Working;
      promise = this._configErpService.validateItem(item);
    } else if (this.state === EErpCheckState.Repair && item.error && item.canRepair) {
      item._state = EErpCheckItemState.Working;
      promise = this._configErpService.repairItem(item);
    } else {
      return Promise.resolve();
    }
    const response: HttpResponse<IConfigERPCheck> = await promise.catch((reason: HttpErrorResponse) => {
      this._logger.error(reason);
      item._state = EErpCheckItemState.Error;
      item._showDetail = true;
      return undefined;
    });
    if (response) {
      merge(item, response.body);
      if (item.error) {
        item._state = EErpCheckItemState.Error;
        item._showDetail = true;
      } else {
        item._state = EErpCheckItemState.Success;
        item._showDetail = false;
      }
    }
    return Promise.resolve();
  }

  private _toState(state: EErpCheckState): void {
    this.state = state;
    this._evaluateToolbarItems();
    if (this.state === EErpCheckState.Finished) {
      this._configService.refresh();
    }
  }

  private async _generateDefaultData(): Promise<void> {
    this._toState(EErpCheckState.GenerateDefaultData);
    await this._configErpService.genDefaultData();
    await new Promise<void>((resolve) => {
      let retries = 0;
      this._clearIntervalCheckGenerateDefaultDataStatus();
      this._intervalCheckGenerateDefaultDataStatus = window.setInterval(() => {
        this._configErpService
          .genDefaultDataStatus()
          .then((response: HttpResponse<EConfigERPGenDefaultDataState>) => {
            retries = 0;
            switch (response.body) {
              case EConfigERPGenDefaultDataState.Terminated:
                resolve();
                break;
              case EConfigERPGenDefaultDataState.Error:
                this._plAlertService.error('configsErp.items.validacaoDasConfiguracoes.errors.GenDefaultDataError');
                resolve();
                break;
              default:
                break;
            }
          })
          .catch((error: HttpErrorResponse) => {
            this._logger.error(error);
            retries++;
            if (retries >= STATUS_MAX_RETRIES) {
              resolve();
            }
          });
      }, INTERVAL_TIMEOUT);
    }).finally(() => {
      this._clearIntervalCheckGenerateDefaultDataStatus();
    });
  }

  private _clearIntervalCheckGenerateDefaultDataStatus(): void {
    if (isNumber(this._intervalCheckGenerateDefaultDataStatus)) {
      window.clearInterval(this._intervalCheckGenerateDefaultDataStatus);
      this._intervalCheckGenerateDefaultDataStatus = undefined;
    }
  }

  private _evaluateToolbarItems(): void {
    this._btnValidate.disabled = this.state !== EErpCheckState.Validate && this.state !== EErpCheckState.Load && this.state !== EErpCheckState.Finished;
    this._btnValidate.tooltip.disabled = !this._btnValidate.disabled;
    this._btnRepairAll.disabled = this._btnValidate.disabled;
    this._btnRepairAll.tooltip.disabled = !this._btnRepairAll.disabled;
  }
}
