<div class="ativosabate">
  <div class="entity-detail-form">
    <pl-form>
      <pl-group>
        <pl-group>
          <label [translate]="'ativosabate.dataAbate'"></label>
          <edit>
            <pl-edit type="date" attrName="dataAbate" [(model)]="ativosabate.dataAbate"> </pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'ativosabate.radicalAbate'"></label>
          <edit>
            <entity-autocomplete
              entity="pocs"
              attrName="radicalAbate"
              [model]="ativosabate"
              (selectedKeyChange)="ativosabate.ncontaAbate = $event"
              (evtSelectedDescriptionChanged)="ativosabate.nomecontaAbate = $event"
              [output]="pocsOutput"
              [fieldsMap]="{nConta: 'ncontaAbate', nome: 'nomecontaAbate'}">
            </entity-autocomplete>
          </edit>
        </pl-group>
      </pl-group>
    </pl-form>
  </div>

  <h5 class="mt-3 mb-2" [translate]="'ativosabate.selecionarativos'"></h5>

  <div class="ativosabate-wrapper-dxgrid">
    <div class="ativosabate-table-dxgrid">
      <div class="text-nowrap"><h6 [translate]="'ativosabate.table.ativosporabater'"></h6></div>
      <dx-data-grid
        [cgDxDataGrid]="dataGridDefinition"
        [dataSource]="dataGridDefinition.dataSource"
        [filterValue]="['status', '=', 'porAbater']"
        (onInitialized)="onInitialized($event, true)"
        (onContentReady)="onContentReady()"
        (onSelectionChanged)="onSelectionChanged($event, true)"
        (onCellDblClick)="onCellDblClick(true)">
        <dxo-row-dragging group="statusGroup" data="porAbater" [onAdd]="onDataGridAddAbate"></dxo-row-dragging>
      </dx-data-grid>
    </div>

    <div class="abate-btn-actions-dxgrid">
      <button type="button" class="btn btn-primary btn-sm" [disabled]="emptyAtivosPorAbaterTable" (click)="allRight()">
        <i class="fa fa-fw fa-angle-double-right"></i>
      </button>

      <button type="button" class="btn btn-primary btn-sm" [disabled]="!selectedAtivoPorAbater" (click)="selectedRight()">
        <i class="fa fa-fw fa-angle-right"></i>
      </button>

      <button type="button" class="btn btn-primary btn-sm" [disabled]="!selectedAtivoAAbater" (click)="selectedLeft()">
        <i class="fa fa-fw fa-angle-left"></i>
      </button>

      <button type="button" class="btn btn-primary btn-sm" [disabled]="emptyAtivosAAbaterTable" (click)="allLeft()">
        <i class="fa fa-fw fa-angle-double-left"></i>
      </button>
    </div>

    <div class="ativosabate-table-dxgrid">
      <div class="text-nowrap"><h6 [translate]="'ativosabate.table.ativosaabater'"></h6></div>
      <dx-data-grid
        [cgDxDataGrid]="dataGridDefinition"
        [dataSource]="dataGridDefinition.dataSource"
        [filterValue]="['status', '=', 'aAbater']"
        (onInitialized)="onInitialized($event, false)"
        (onContentReady)="onContentReady()"
        (onSelectionChanged)="onSelectionChanged($event, false)"
        (onCellDblClick)="onCellDblClick(false)">
        <dxo-row-dragging group="statusGroup" data="aAbater" [onAdd]="onDataGridAddAbate"></dxo-row-dragging>
      </dx-data-grid>
    </div>
  </div>
</div>
