import {Component, EventEmitter, Input, Output} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {
  ICGTableOnSelect,
  IPlFilterPanelEvtFiltered,
  IPlFilterPanelField,
  IPlFilterPanelFilters,
  IPlTableCallback,
  IPlTableDefinition,
  IPlTableOptions,
  isNumber,
  TCGTableOnSelectFunction,
  TPlTableItem
} from 'pl-comps-angular';
import {AuthService} from '../../../services/auth/auth.service';
import {ConfigService} from '../../../services/config/config.service';
import {EmpresasService} from '../../../services/empresas/empresas.service';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService} from '../../../modules/portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IJsonDocContabilidade} from '../../../modules/portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {IJsonEmpresaAno} from '../../../interfaces/jsonEmpresa.interface';
import {IPesquisaDocsLinhaDocContabilidadeCab} from './pesquisaDocsLinha.component.interface';
import {PesquisaDocsLinhaService} from './pesquisaDocsLinha.service';
import {TUserSession} from '../../../services/account/jsonUserApi.interface';
import {EDebitoCredito} from '../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {THttpQueryResponse} from '../../../services/api/api.service.interface';

@Component({
  selector: 'contabilidade-pesquisa-docs-linha',
  templateUrl: './pesquisaDocsLinha.component.html'
})
export class CPPesquisaDocsLinhaComponent {
  @Input() public filters: IPlFilterPanelFilters;
  @Output() public readonly evtDocSelect: EventEmitter<IPesquisaDocsLinhaDocContabilidadeCab>;

  public readonly anosRowTemplate: string;
  public readonly anosOutput: string;
  public readonly filterFields: Array<IPlFilterPanelField>;
  public readonly tableDefinition: IPlTableDefinition;
  public readonly tableOptions: IPlTableOptions;
  public readonly tableCallback: IPlTableCallback;
  public anosSource: Array<number>;
  public anoEmCurso: number;
  public filterCollapsed: boolean;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private _selectedItem: IPesquisaDocsLinhaDocContabilidadeCab;
  private _filter: string;

  constructor(
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _configService: ConfigService,
    private readonly _authService: AuthService,
    private readonly _empresasService: EmpresasService,
    private readonly _pesquisaDocsLinhaService: PesquisaDocsLinhaService
  ) {
    this.evtDocSelect = new EventEmitter<IPesquisaDocsLinhaDocContabilidadeCab>();
    this.anosRowTemplate = '{{caption}}';
    this.anosOutput = 'caption';
    this.filterFields = [
      {
        name: 'nContribuinte',
        caption: 'Nº. Contribuinte'
      },
      {
        name: 'DC',
        caption: 'DC',
        type: 'select',
        properties: {
          select: {
            list: [
              {name: 'Débito', value: EDebitoCredito.Debito},
              {name: 'Crédito', value: EDebitoCredito.Credito}
            ]
          }
        }
      },
      {
        name: 'VALOR',
        caption: 'Valor',
        type: 'currency'
      },
      {
        name: 'NCONTA',
        caption: 'Conta'
      },
      {
        name: 'DESCRICAO',
        caption: 'Descrição'
      },
      {
        name: 'documentoExterno',
        caption: 'Nº. Doc. Externo'
      },
      {
        name: 'DATADOC',
        caption: 'Data Doc.',
        type: 'date'
      },
      {
        name: 'PERIODO',
        caption: 'Período'
      },
      {
        name: 'NDIARIO',
        caption: 'N.Diario',
        type: 'number'
      },
      {
        name: 'nDocInterno',
        caption: 'Doc. Interno'
      }
    ];
    this.tableDefinition = {
      fields: [
        {name: 'nDocumento', caption: 'docscontabilidade.fields.nDocumento'},
        {name: 'nDocInterno', caption: 'docscontabilidade.fields.nDocInterno'},
        {name: 'dataLancamento', caption: 'docscontabilidade.fields.dataLancamento', type: 'date'},
        {name: 'dataVencimento', caption: 'docscontabilidade.fields.dataVencimento', type: 'date'},
        {name: 'dataDoc', caption: 'docscontabilidade.fields.dataDoc', type: 'date'},
        {name: 'documentoExterno', caption: 'docscontabilidade.fields.documentoExterno'},
        {name: 'descricao', caption: 'docscontabilidade.fields.descricao'},
        {name: 'nContribuinte', caption: 'docscontabilidade.fields.nContribuinte'},
        {name: 'totalCreditoGeral', caption: 'docscontabilidade.fields.totalCreditoGeral', type: 'currency'},
        {name: 'totalDebitoGeral', caption: 'docscontabilidade.fields.totalDebitoGeral', type: 'currency'}
      ]
    };
    this.tableOptions = {perPage: 20, suppressEmptyLines: true};
    this.tableCallback = {};
    this.anosSource = [];
    this.anoEmCurso = this._configService.configurations.empresa.anoEmCursoIRC;
    this.filterCollapsed = false;
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._authService.identity().then((response: TUserSession) => {
      this._loadAnosDisponiveis(response.erp.nEmpresa);
    });
  }

  public anosChanged(ano: number): void {
    this.anoEmCurso = ano;
    this.tableCallback.refresh();
  }

  public onFilter({serializedFilters}: IPlFilterPanelEvtFiltered<string>): void {
    this._filter = serializedFilters;
    this.tableCallback.refresh();
  }

  public readonly fnTableSource = (): Promise<Array<TPlTableItem<IPesquisaDocsLinhaDocContabilidadeCab>>> => this._tableSource();

  public readonly fnOnSelect: TCGTableOnSelectFunction<IPesquisaDocsLinhaDocContabilidadeCab> = (event: ICGTableOnSelect<IPesquisaDocsLinhaDocContabilidadeCab>) => this._onSelect(event);

  private _loadAnosDisponiveis(nEmpresa: string): void {
    this._empresasService.getAnos(nEmpresa).then((response: THttpQueryResponse<IJsonEmpresaAno>) => {
      if (response.body.list.length) {
        this.anosSource = response.body.list.map((empresaAno: IJsonEmpresaAno) => empresaAno.ano);
        const nextYear: number = this.anoEmCurso + 1;
        if (!this.anosSource.includes(nextYear)) {
          this.anosSource.push(nextYear);
        }
        this.anosSource.sort((a: number, b: number) => b - a);
      }
    });
  }

  private _tableSource(): Promise<Array<TPlTableItem<IPesquisaDocsLinhaDocContabilidadeCab>>> {
    let query: string = this._filter || '';
    if (query) {
      query += '&';
    }
    query += `ano=${this.anoEmCurso}`;
    return this._pesquisaDocsLinhaService.pesquisar({pesquisa: query}).then((response: THttpQueryResponse<IPesquisaDocsLinhaDocContabilidadeCab>) => {
      const source: Array<TPlTableItem<IPesquisaDocsLinhaDocContabilidadeCab>> = response.body.list;
      for (const linha of source) {
        linha.rowClass = (item: IPesquisaDocsLinhaDocContabilidadeCab) => this._evaluateRowClass(item);
      }
      return source;
    });
  }

  private _onSelect(event: ICGTableOnSelect<IPesquisaDocsLinhaDocContabilidadeCab>): Promise<void> {
    const {item, columnIndex} = event;
    item.ano = this.anoEmCurso;
    if (item !== this._selectedItem) {
      this._selectedItem = item;
      this.tableCallback.rePaintBody();
      this.evtDocSelect.emit(item);
    }
    if (!item._detailOpen) {
      return this._onDetail(event);
    }
    if (columnIndex !== -1) {
      item._detailOpen = false;
    }
    return Promise.resolve();
  }

  private _onDetail({item, columnIndex}: ICGTableOnSelect<IPesquisaDocsLinhaDocContabilidadeCab>): Promise<void> {
    return this._serviceDocsContabilidade.get({id: item.extPocCabID}).then((response: HttpResponse<IJsonDocContabilidade>) => {
      item._doc = response.body;
      if (isNumber(columnIndex) && columnIndex !== -1) {
        item._detailOpen = !item._detailOpen;
      }
    });
  }

  private _evaluateRowClass(item: IPesquisaDocsLinhaDocContabilidadeCab): string {
    if (this._selectedItem && this._selectedItem.extPocCabID === item.extPocCabID) {
      return 'pesqDocLinhaRowSelected';
    }
    return '';
  }
}
