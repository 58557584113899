import {Subscription} from 'rxjs';
import {Component, Injector, Input, OnDestroy} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {isDefinedNotNull, isEmpty, isFunction, isNumber, isObject, timeout} from 'pl-comps-angular';
import {EEntityMaintenanceEditMode, IEntityMaintenanceEditModalOptions} from '../../entity/entity.maintenance.interface';
import {EEntityStateDetailType, entityStateDetailQueryParam} from '../../../../../../common/utils/entity.state.utils';
import {EntityMaintenanceModalComponent} from '../entity.maintenance.modal.component';
import {IApiRequestConfigWithBody} from '../../../../../services/api/api.service.interface';
import {ModuloEntityDetailComponent} from '../../../../module/entitydetail/module.entitydetail.component';
import {IModuleEntityDetailActionDuplicateOptions} from '../../../../module/module.definition.interface';

@Component({
  selector: 'entity-maintenance-detail-modal',
  templateUrl: './entity.maintenance.detail.modal.component.html'
})
export class EntityMaintenanceDetailModalComponent<T extends object, S extends ModuloEntityDetailComponent<T> = ModuloEntityDetailComponent<T>>
  extends EntityMaintenanceModalComponent<T, S>
  implements OnDestroy
{
  @Input() public type: EEntityStateDetailType;
  @Input() public id: string | number;
  @Input() public editOptions: IEntityMaintenanceEditModalOptions;
  @Input() public onMaintenanceSave: (config: IApiRequestConfigWithBody<T>) => Promise<T>;

  public toolbarTitle: string;

  private _subscriptionOnClose: Subscription;
  private _subscriptionOnSave: Subscription;
  private _subscriptionOnDelete: Subscription;
  private _fnEntityDuplicate: (options?: IModuleEntityDetailActionDuplicateOptions) => Promise<void>;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this._duplicate = this._duplicate.bind(this);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._clearSubscription();
  }

  protected _transitionParams(): object {
    const superParams: object = super._transitionParams();
    const params: object = {...superParams, id: this.id};
    const type = entityStateDetailQueryParam(this.entityMaintenanceInstance.entity.name);
    params[type] = isDefinedNotNull(this.type) ? this.type : isEmpty(this.id) ? EEntityStateDetailType.NEW : EEntityStateDetailType.EDIT;
    return params;
  }

  protected _buildTemplate(resolvedValues: Map<string, unknown>): void {
    super._buildTemplate(resolvedValues);
    this._componentRefInstance.formOrientation = 'horizontal';
    this._componentRefInstance.afterDelete = this._afterDelete.bind(this);
  }

  protected _configToolbar(): void {
    super._configToolbar();
    const modeReadonly: boolean = Boolean(this.editOptions) && this.editOptions.mode === EEntityMaintenanceEditMode.ReadOnly;
    const modeEditOnly: boolean = Boolean(this.editOptions) && this.editOptions.mode === EEntityMaintenanceEditMode.EditOnly;
    this._componentRefInstance.btnSave.visible = !modeReadonly;
    this._componentRefInstance.btnDelete.visible = Boolean(this.entityMaintenanceInstance.entity.actions.delete) && this._componentRefInstance.type === EEntityStateDetailType.EDIT && !modeReadonly;
    this._componentRefInstance.btnNovo.visible =
      Boolean(this.entityMaintenanceInstance.entity.actions.new) && this._componentRefInstance.type === EEntityStateDetailType.EDIT && !modeEditOnly && !modeReadonly;
    if (this._componentRefInstance.btnNovo.visible && isNumber(this._componentRefInstance.btnDuplicate.order)) {
      this._componentRefInstance.btnNovo.order = this._componentRefInstance.btnDuplicate.order - 1;
      this._componentRefInstance.toolbar.sortItems();
    }
    this._componentRefInstance.btnDuplicate.visible =
      Boolean(this.entityMaintenanceInstance.entity.actions.duplicate) && this._componentRefInstance.type === EEntityStateDetailType.EDIT && !modeEditOnly && !modeReadonly;
    if (this._componentRefInstance.type !== EEntityStateDetailType.NEW && this._componentRefInstance.toolTitle.caption && !this._componentRefInstance.toolTitle.caption.includes('{{')) {
      this.toolbarTitle = this._componentRefInstance.toolTitle.caption;
    }
    if (this._componentRefInstance.btnEdit.visible && modeReadonly) {
      this._componentRefInstance.btnEdit.visible = false;
    }
    this._componentRefInstance.novo = () => {
      this._new();
      return Promise.resolve();
    };
    this._fnEntityDuplicate = this._componentRefInstance.duplicate.bind(this._componentRefInstance);
    this._componentRefInstance.duplicate = this._duplicate;
    const model: unknown | HttpErrorResponse = this._uiInjector.get('model');
    if (model instanceof HttpErrorResponse) {
      console.error(model);
      this._componentRefInstance.callback.update(EEntityStateDetailType.NEW);
      if (isObject(this._componentRefInstance.params) && !isEmpty(this._componentRefInstance.params.id)) {
        this.entityMaintenanceInstance.entity.setId(this._componentRefInstance.model, this._componentRefInstance.params.id);
      }
    }
    if (isFunction(this.onMaintenanceSave)) {
      this._componentRefInstance.save = (config: IApiRequestConfigWithBody<T>) => {
        config = {
          body: this._componentRefInstance.model,
          ...config
        };
        const promise: Promise<T> = this.onMaintenanceSave(config);
        this._onSave(promise);
        return promise;
      };
    }
  }

  protected _observe(): void {
    this._clearSubscription();
    this._subscriptionOnClose = this._componentRefInstance.evtOnCloseMaintenance().subscribe((value: unknown) => {
      this.enableClose();
      this.close(<T>value);
    });
    this._subscriptionOnSave = this._componentRefInstance.evtOnSave().subscribe((value: Promise<T>) => {
      this._onSave(value);
    });
    this._subscriptionOnDelete = this._componentRefInstance.evtOnDelete().subscribe((value: Promise<void>) => {
      this._onDelete(value);
    });
  }

  protected _clearSubscription(): void {
    if (this._subscriptionOnClose) {
      this._subscriptionOnClose.unsubscribe();
    }
    if (this._subscriptionOnSave) {
      this._subscriptionOnSave.unsubscribe();
    }
    if (this._subscriptionOnDelete) {
      this._subscriptionOnDelete.unsubscribe();
    }
  }

  private _new(): void {
    (<object>this._componentRefInstance.model) = {};
    this._componentRefInstance.callback.new();
    this.toolbarTitle = undefined;
    setTimeout(() => {
      this._configToolbar();
      this._changeDetectorRef.detectChanges();
    });
  }

  private async _duplicate(): Promise<void> {
    await this._fnEntityDuplicate();
    this.toolbarTitle = undefined;
    await timeout();
    this._configToolbar();
    this._changeDetectorRef.detectChanges();
  }

  private async _onSave(savePromise: Promise<T>): Promise<void> {
    this.disableClose();
    try {
      const response = await Promise.resolve(savePromise);
      this.enableClose();
      this.close(response);
    } catch (error: unknown) {
      this.enableClose();
      this._logger.error(error);
    }
  }

  private async _onDelete(deletePromise: Promise<void>): Promise<void> {
    this.disableClose();
    try {
      await Promise.resolve(deletePromise);
      this.enableClose();
      this.close(undefined);
    } catch (error: unknown) {
      this.enableClose();
      this._logger.error(error);
    }
  }

  private _afterDelete(): Promise<void> {
    return Promise.resolve();
  }
}
