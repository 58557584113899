import {isDefinedNotNull} from '../utilities/utilities';
import type {IPlCompsScript} from './scriptloader.interface';

export function loadScript(script: IPlCompsScript): Promise<void> {
  const config: IPlCompsScript = {
    async: true,
    crossorigin: undefined,
    defer: true,
    document: document,
    id: undefined,
    integrity: undefined,
    nomodule: undefined,
    nonce: undefined,
    referrerpolicy: undefined,
    type: 'text/javascript',
    ...script
  };

  const windowBody: HTMLElement = config.document.body || config.document.getElementsByTagName<'body'>('body')[0];
  const scriptElement: HTMLScriptElement = config.document.createElement<'script'>('script');

  // Cleanup old scripts
  if (config.id) {
    const scriptElementById: HTMLScriptElement = windowBody.querySelector(`script#${config.id}`);
    if (scriptElementById) {
      const parentElement = scriptElementById.parentElement;
      if (parentElement) {
        parentElement.removeChild(scriptElementById);
      }
    }
  }

  // Set script element attributes
  for (const attr of Object.keys(config)) {
    const value: unknown = config[attr];
    if (isDefinedNotNull(value)) {
      scriptElement.setAttribute(attr, String(value));
    }
  }

  return new Promise<void>((resolve, reject) => {
    const cleanup = (): void => {
      scriptElement.onload = undefined;
      scriptElement.onerror = undefined;
    };

    scriptElement.onload = function () {
      cleanup();
      resolve();
    };

    scriptElement.onerror = function (...args: Array<any>) {
      cleanup();
      reject.call(this, ...args);
    };

    // Append on top
    const firstScript: HTMLScriptElement = windowBody.getElementsByTagName<'script'>('script')[0];
    if (firstScript) {
      const parentElement = firstScript.parentElement;
      parentElement.insertBefore(scriptElement, firstScript);
    } else {
      windowBody.append(scriptElement);
    }
  });
}
