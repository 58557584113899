<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'debDiretosSEPA.strings.modalTitleContaCredora'"></h4>
</div>

<div class="modal-body">
  <div class="bg-primary text-white p-2 mb-3 text-center" style="margin: -16px -16px 0 -16px; font-weight: 600" [translate]="getPanelTitle()"></div>
  <pl-group>
    <label [translate]="'components.contabilidade.listagens.title'"></label>
    <edit>
      <pl-edit type="reports_ddsepa_cliente" attrName="listagem" [model]="reportModel" (modelChange)="reportModel = $event; listar()" plAutoFocus></pl-edit>
    </edit>
  </pl-group>

  <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>

<div class="modal-footer" plNavigation>
  <pl-button klass="btn btn-sm btn-success" [onClick]="fnEnviarEmail"><i class="fa fa-fw fa-send"></i>&nbsp;<span [translate]="'global.btn.sendMail'"></span></pl-button>
  <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
</div>
