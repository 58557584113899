import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {IPlToolbarItem, IPlTooltipConfig, isEmpty, PlAlertService} from 'pl-comps-angular';
import {CessacaoContTrabModalComponent} from '../modals/cessacaoconttrab/cessacaoContTrab.modal.component';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ComplementosExcepcoesModalComponent} from '../modals/complementosexpcecoes/complementosExcepcoes.modal.component';
import {ENTITY_NAME_DGEMPS_FULL} from '../../dgempsfull/dgempsFull.interface';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {
  ETipoContratoTrab,
  ETipoEntidadeCessaCont,
  IJsonRhCessaContratoTrabalho,
  IJsonRhCessaContratoTrabalhoEncerrar,
  IJsonRhCessaContratoTrabalhoForm,
  IJsonRhCessaContratoTrabalhoSimulacao
} from '../jsonRHCessaContratoTrabalho.module.interface';
import {ICGExceptionError} from '../../../../components/exceptions/exceptions.service.interface';
import {IExcluidosAbonosDescontos} from '../rhCessaContratoTrabalho.module.interface';
import {IJsonConfigRHManutencaoCessacaoContrato} from '../../../rhconfiguracoes/jsonRHConfiguracoes.module.interface';
import {IJsonListaExcl} from '../../rhfolfer/jsonRHFolfer.module.interface';
import {MODULE_NAME_RH_MANUTENCAO} from '../../../rhconfiguracoes/rhConfiguracoes.module.interface';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {ModuloComponent} from '../../../../components/module/module.component';
import moment from 'moment';
import {RhCessaContratoTrabalhoService} from '../rhCessaContratoTrabalho.module.service';
import {RHConfiguracoesService} from '../../../rhconfiguracoes/rhConfiguracoes.module.service';

@Component({
  selector: 'module-rh-cessa-contrato-trabalho',
  templateUrl: './rhCessaContratoTrabalho.module.component.html'
})
export class RhCessaContratoTrabalhoModuleComponent extends ModuloComponent implements OnInit {
  @Input() public cessacaoContratoDefault: IJsonConfigRHManutencaoCessacaoContrato;

  public readonly tipoEntidade: typeof ETipoEntidadeCessaCont;
  public readonly tiposContratoTrab: typeof ETipoContratoTrab;
  public readonly tooltipRetribuicaoBase: IPlTooltipConfig;
  public readonly tooltipComplementos: IPlTooltipConfig;
  public readonly tooltipTipoContrato: IPlTooltipConfig;
  public readonly tooltipJustaCausa: IPlTooltipConfig;
  public readonly tooltipFeriasPropValor: IPlTooltipConfig;
  public readonly tooltipExcecoes: IPlTooltipConfig;
  public readonly tooltipReCalcular: IPlTooltipConfig;

  public cessaContTrabForm: IJsonRhCessaContratoTrabalhoForm;
  public cessaContTrabSimul: IJsonRhCessaContratoTrabalhoSimulacao;
  public duracaoDate: string;
  public promise: Promise<void>;

  private readonly _maintenanceDGEMPFull: IEntityMaintenanceInstance;
  private readonly _maintenanceRhManutencao: IModuleMaintenanceInstance;
  private readonly _btnValidar: IPlToolbarItem;
  private _excludedListPossiveisDefault: Array<IJsonListaExcl>;
  private _excludedListPossiveis: Array<IJsonListaExcl>;
  private _excludeListExcluidos: Array<IJsonListaExcl>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _rhcessaContratoTrabalhoService: RhCessaContratoTrabalhoService,
    private readonly _rhConfiguracoesService: RHConfiguracoesService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _cgModalService: CGModalService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.tipoEntidade = ETipoEntidadeCessaCont;
    this.tiposContratoTrab = ETipoContratoTrab;
    this.tooltipRetribuicaoBase = {text: 'rhcessacontratotrabalho.infos.tooltipRetribuicaoBase', placement: 'bottom', container: 'body'};
    this.tooltipComplementos = {text: 'rhcessacontratotrabalho.infos.tooltipComplementos', placement: 'bottom', container: 'body'};
    this.tooltipTipoContrato = {text: 'rhcessacontratotrabalho.infos.tooltipTipoContrato', placement: 'bottom', container: 'body'};
    this.tooltipJustaCausa = {text: 'rhcessacontratotrabalho.infos.tooltipJustCausa', placement: 'bottom', container: 'body'};
    this.tooltipFeriasPropValor = {text: 'rhcessacontratotrabalho.infos.tooltipFeriasPropValor', placement: 'bottom', container: 'body'};
    this.tooltipExcecoes = {text: 'rhcessacontratotrabalho.infos.tooltipExcecpoes', placement: 'bottom', container: 'body'};
    this.tooltipReCalcular = {text: 'rhcessacontratotrabalho.infos.tooltipReCalcular', placement: 'bottom', container: 'body'};
    this._maintenanceDGEMPFull = this._entityMaintenanceService.build(ENTITY_NAME_DGEMPS_FULL);
    this._maintenanceRhManutencao = this._moduleMaintenanceService.build(MODULE_NAME_RH_MANUTENCAO);
    this._btnValidar = {
      id: 'validar',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-floppy-o"></i>',
      class: 'btn-primary',
      caption: 'rhcessacontratotrabalho.btn.validar',
      tooltip: {disabled: false, text: 'rhcessacontratotrabalho.infos.insiraemp', placement: 'bottom'},
      disabled: true,
      click: () => this._openValidarCessacao()
    };
    this._excludedListPossiveisDefault = [];
    this._excludedListPossiveis = [];
    this._excludeListExcluidos = [];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!this.cessaContTrabForm) {
      this._clearCessaContTrabForm();
    }
    if (!this.cessaContTrabSimul) {
      this.clearSimulacao();
    }
    this.toolbar.addButton(this._btnValidar);
    this.toolbar.addButton({
      id: 'simularcessacao',
      order: 3,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-calculator"></i>',
      class: 'btn-info',
      caption: 'rhcessacontratotrabalho.btn.simularcessacao',
      click: () => this.simularCessacaoContrato()
    });
    this._calcDuracaoContrato();
    this._rhcessaContratoTrabalhoService.getExcludedAbonos().then((response: HttpResponse<Array<IJsonListaExcl>>) => {
      this._excludedListPossiveisDefault = response.body;
      this._excludedListPossiveis = response.body;
    });
  }

  public simularCessacaoContrato(): Promise<void> {
    const cessacontratotrabalho: IJsonRhCessaContratoTrabalho = {
      rhCessaContratoTrabalhoForm: this.cessaContTrabForm,
      rhCessaContratoTrabalhoSimulacao: this.cessaContTrabSimul
    };
    this.promise = this._rhcessaContratoTrabalhoService.postSimularValor(cessacontratotrabalho).then((response: HttpResponse<IJsonRhCessaContratoTrabalho>) => {
      const emptyDataPrimeiraRenovacaoExtra: boolean = isEmpty(this.cessaContTrabForm.dataPrimeiraRenovacaoExtra);
      this.cessaContTrabForm = response.body.rhCessaContratoTrabalhoForm;
      this.cessaContTrabForm.dataPrimeiraRenovacaoExtra = emptyDataPrimeiraRenovacaoExtra ? undefined : this.cessaContTrabForm.dataPrimeiraRenovacaoExtra;
      this.cessaContTrabSimul = response.body.rhCessaContratoTrabalhoSimulacao;
      this._plAlertService.success('rhcessacontratotrabalho.infos.calcsuccess');
      this._calcDuracaoContrato();
      this._validateBtnValidarCessacao();
    });
    return this.promise;
  }

  public codEmpChanged(value: number): Promise<void> {
    this.cessaContTrabForm.codEmp = value;
    this.promise = this._rhcessaContratoTrabalhoService
      .postCarregaByCodEmp(this.cessaContTrabForm)
      .then((response: HttpResponse<IJsonRhCessaContratoTrabalhoForm>) => {
        const emptyDataPrimeiraRenovacaoExtra: boolean = isEmpty(this.cessaContTrabForm.dataPrimeiraRenovacaoExtra);
        this.cessaContTrabForm = response.body;
        this.cessaContTrabForm.dataPrimeiraRenovacaoExtra = emptyDataPrimeiraRenovacaoExtra ? undefined : this.cessaContTrabForm.dataPrimeiraRenovacaoExtra;
      })
      .catch((reason: HttpErrorResponse) => {
        this._logger.error(reason);
        const exception: ICGExceptionError = this._cgExceptionService.get(reason);
        // Código de empregado inválido
        if (exception.class === 'ERestNotFound') {
          this.cessaContTrabForm = {...this.cessaContTrabForm, codEmp: undefined, nomeEmp: undefined};
        }
      })
      .finally(() => {
        this._calcDuracaoContrato();
        this.clearSimulacao();
        this._validateBtnValidarCessacao();
      });
    return this.promise;
  }

  public openEmpManutencao(): Promise<void> {
    return this._maintenanceDGEMPFull.maintenanceEdit(this.cessaContTrabForm.codEmp).then(() => {
      return this.codEmpChanged(this.cessaContTrabForm.codEmp);
    });
  }

  public clearSimulacao(): void {
    this.cessaContTrabSimul = {
      compensacaoTotal: 0,
      valorSubsidioFerias: 0,
      feriasPropValor: 0,
      subsidioFeriasPropValor: 0,
      compensacao: 0,
      subsidioNatalPropValor: 0,
      valorFeriasVencidas: 0
    };
  }

  public dataChanged(): void {
    this._calcDuracaoContrato();
    this.clearSimulacao();
    this._validateBtnValidarCessacao();
  }

  public openManutencaoCessaContrato(): Promise<void> {
    return this._cgStateService.redirectToState({stateOrName: MODULE_NAME_RH_MANUTENCAO});
  }

  public openExcludedAbonos(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ComplementosExcepcoesModalComponent);
    const componentInstance: ComplementosExcepcoesModalComponent = modalInstance.componentInstance;
    componentInstance.excludedListPossiveis = this._excludedListPossiveis.slice();
    componentInstance.excludedListExcluidos = this._excludeListExcluidos.slice();
    this.promise = modalInstance.result.then((result: IExcluidosAbonosDescontos) => {
      this._excludedListPossiveis = result.possiveis;
      this._excludeListExcluidos = result.excluidos;
      return this.calcComplementos();
    });
    return this.promise;
  }

  public calcComplementos(): Promise<void> {
    const listaExcluidos: string = this._excludeListExcluidos.map<string>((linha: IJsonListaExcl) => linha.value).join(',');
    this.promise = this._rhcessaContratoTrabalhoService
      .postRecalcularComplementos(listaExcluidos, this.cessaContTrabForm)
      .then((response: HttpResponse<IJsonRhCessaContratoTrabalhoForm>) => {
        this.cessaContTrabForm = response.body;
        this._plAlertService.success('rhcessacontratotrabalho.infos.calculadocomplementossuccess');
      })
      .finally(() => {
        this._calcDuracaoContrato();
        this.clearSimulacao();
        this._validateBtnValidarCessacao();
      });
    return this.promise;
  }

  private _validateBtnValidarCessacao(): void {
    this._btnValidar.tooltip.disabled =
      this.cessaContTrabForm.codEmp > 0 && (this.cessaContTrabSimul.compensacaoTotal > 0 || this.cessaContTrabSimul.compensacao > 0) && isEmpty(this.cessaContTrabForm.erro);

    this._btnValidar.disabled =
      this.cessaContTrabForm.codEmp < 1 || (this.cessaContTrabSimul.compensacaoTotal === 0 && this.cessaContTrabSimul.compensacao === 0) || !isEmpty(this.cessaContTrabForm.erro);

    this._btnValidar.tooltip.text = this.cessaContTrabForm.erro === '' ? 'rhcessacontratotrabalho.infos.temcalcularvalor' : 'rhcessacontratotrabalho.infos.temcorrigiremp';

    if (this.cessaContTrabForm.codEmp < 1) {
      this._btnValidar.tooltip.text = 'rhcessacontratotrabalho.infos.insiraemp';
    }
  }

  private _openValidarCessacao(): Promise<void> {
    this.promise = (async () => {
      if (!this.cessacaoContratoDefault.situacaoCessacao && !this.cessacaoContratoDefault.motivoCessacao) {
        try {
          await this._cgModalService.showOkCancel('rhcessacontratotrabalho.modal.titleconf', 'rhcessacontratotrabalho.modal.defsitandmot').then(async () => {
            try {
              await this._maintenanceRhManutencao.maintenance();
            } catch (reason) {
              this._logger.error(reason);
            }
          });
        } catch (reason: unknown) {
          this._logger.error(reason);
        }
        await this._rhConfiguracoesService
          .getManutencaoCessacaoContrato()
          .then((responseDefault: HttpResponse<IJsonConfigRHManutencaoCessacaoContrato>) => {
            this.cessacaoContratoDefault = responseDefault.body;
          })
          .catch((reason: unknown) => {
            this._logger.error(reason);
          });
      }
      this.cessaContTrabForm.motivo = this.cessacaoContratoDefault.motivoCessacao;
      this.cessaContTrabForm.situacao = this.cessacaoContratoDefault.situacaoCessacao;
      this.cessaContTrabForm.nomeMotivo = this.cessacaoContratoDefault.nomeMotivoCessacao;
      this.cessaContTrabForm.nomeSituacao = this.cessacaoContratoDefault.nomeSituacaoCessacao;
      const response: HttpResponse<Array<IJsonRhCessaContratoTrabalhoEncerrar>> = await this._rhcessaContratoTrabalhoService.postDadosCessacaoContTrab(this.cessaContTrabForm);
      const modalInstance = this._cgModalService.showVanilla(CessacaoContTrabModalComponent);
      const componentInstance: CessacaoContTrabModalComponent = modalInstance.componentInstance;
      componentInstance.cessaContTrabForm = this.cessaContTrabForm;
      componentInstance.cessaContTrabEncerrar = response.body;
      await modalInstance.result;
      this._excludedListPossiveis = this._excludedListPossiveisDefault;
      this._excludeListExcluidos = [];
      this._clearCessaContTrabForm();
      this.clearSimulacao();
      this._calcDuracaoContrato();
      this._validateBtnValidarCessacao();
    })();
    return this.promise;
  }

  private _clearCessaContTrabForm(): void {
    this.cessaContTrabForm = {
      entidade: ETipoEntidadeCessaCont.Empregador,
      tipoContrato: undefined,
      dataInicio: moment(),
      horasSemana: 40,
      dataCessacao: moment(),
      dataPrimeiraRenovacaoExtra: undefined,
      complementos: 0,
      diuturnidades: 0,
      retribuicaoBase: 0,
      feriasGozadas: 0,
      subsidioFerias: 0,
      justaCausa: false,
      subsidioNatal: 0,
      codEmp: 0,
      motivo: '',
      situacao: '',
      tipoProcessamento: 0,
      subSidioNatalJaRecebido: 0,
      erro: '',
      nomeEmp: '',
      nomeMotivo: '',
      nomeSituacao: '',
      nomeTipoProcessamento: ''
    };
  }

  private _calcDuracaoContrato(): void {
    const dtInicio = moment(this.cessaContTrabForm.dataInicio);
    const dtCessa = moment(this.cessaContTrabForm.dataCessacao);

    const years = dtCessa.diff(dtInicio, 'year');
    dtInicio.add(years, 'years');
    const months = dtCessa.diff(dtInicio, 'months');
    dtInicio.add(months, 'months');
    const days = dtCessa.diff(dtInicio, 'days');

    this.duracaoDate = this._translateService.instant('rhcessacontratotrabalho.fields.duracaodata', {years: years, months: months, days: days});
  }
}
