import {findLast, merge} from 'lodash-es';
import {firstValueFrom, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {StateDeclaration, StateService} from '@uirouter/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {
  copy,
  debounce,
  IPlToolbarInstance,
  IPlToolbarItem,
  IPlToolbarMenuItem,
  isArray,
  isBoolean,
  isEmpty,
  isNumber,
  isObject,
  isUndefinedOrNull,
  Logger,
  PL_PDF_TOOLBAR_ORDER_BASE,
  PlAlertService,
  PlI18nService,
  PlToolbarService,
  PlTranslateService
} from 'pl-comps-angular';
import {
  CG_HELP_TOPIC_CONTAB_DIGITAL_FAQ,
  CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_CONTABILIDADE,
  CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_DOCS_NAO_CLASSIFICADOS,
  CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_SEPARATOR,
  IContabDigitalDocViewerRecolhaCallback,
  IContabDigitalDocViewerRecolhaSelectAttachmentOptions
} from './contabilidadedigital.docviewer.recolha.component.interface';
import {ContabilidadeDigitalServiceDocuments} from '../../../../services/contabilidadedigital/contabilidadedigital.service.documents';
import {
  EArquivoDigitalDocViewerAddDocsMode,
  EArquivoDigitalDocViewerState,
  IArquivoDigitalDocViewerEvtChangedAttachment,
  IArquivoDigitalDocViewerEvtConfigureToolbar
} from '../../common/docviewer/arquivodigital.docviewer.component.interface';
import {IContabDigitalGDocViewerRecolhaDoc, IContabDigitalGDocViewerRecolhaSearchParams} from '../../../../services/contabilidadedigital/contabilidadedigital.interface';
import {
  IJsonContabDigitalAdicionarVerbeteData,
  IJsonContabDigitalConfigs,
  IJsonContabDigitalGDocViewerRecolhaFolderAttachments,
  IJsonContabDigitalGDocViewerRecolhaSearch,
  IJsonContabDigitalGDocViewerRecolhaSearchResult,
  IJsonDocDigitalizado,
  IJsonDocOCR
} from '../../../../services/contabilidadedigital/jsonContabDigital.interface';
import {ConfigService} from '../../../../services/config/config.service';
import {ConfigSiteService} from '../../../../services/configsite.service';
import {CGModalService} from '../../../cg/modal/cgmodal.service';
import {CGStateService} from '../../../state/cg.state.service';
import {ContabilidadeDigitalUIService} from '../../../../services/contabilidadedigital/contabilidadedigital.ui.service';
import {DocsContabilidadeService} from '../../../../modules/portalcontabilidade/docscontabilidade/service/docsContabilidade.service';
import {MODULE_NAME_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS} from '../../../../modules/contabilidadedigital/documentosdigitais/contabDigital.documentosDigitais.module.interface';
import {ICGConfigurations} from '../../../../services/config/config.service.interface';
import {ContabilidadeDigitalDocViewerRecolhaChooseFolderModalComponent} from '../../modals/choosefolder/contabilidadedigital.docviewer.recolha.choosefolder.modal.component';
import {IContabDigitalDocViewerRecolhaChooseFolderModalResult} from '../../modals/choosefolder/contabilidadedigital.docviewer.recolha.choosefolder.modal.component.interface';
import {ContabilidadeDigitalClientConnectAddDocsModalComponent} from '../clientconnect/modals/adddocsmodal/contabilidadedigital.clientconnect.adddocsmodal.component';
import {ContabilidadeDigitalDocViewerRecolhaAttachExistingDocModalComponent} from '../../modals/attachexistingdoc/contabilidadedigital.docviewer.recolha.attachexistingdoc.modal.component';
import {EContabilidadeDigitalActivateLicenseType} from '../../modals/activatelicense/contabilidadedigital.activatelicensemodal.component.interface';
import {ArquivoDigitalConfigModalComponent} from '../../modals/config/arquivoDigital.config.modal.component';
import {ICGHelpTopic} from '../../../cg/modal/helptopics/helptopics.modal.component.interface';
import {CG_HELP_TOPIC_OTHER} from '../../../cg/modal/helptopics/helptopics';
import moment from 'moment';
import {TUserSession} from '../../../../services/account/jsonUserApi.interface';
import {AuthService} from '../../../../services/auth/auth.service';

const TOOLBAR_GROUP_ID = 'contabilidadedigital-docviewer-recolha';
const TOOLBAR_ORDER_BASE = PL_PDF_TOOLBAR_ORDER_BASE - 100;
const TOOLBAR_ORDER_STEP = 1;
const SELECT_ATTACHMENT_DEBOUNCE_ID = 'contabilidadedigital-docviewer-recolha-select-attachment';
const SELECT_ATTACHMENT_DEBOUNCE_TIMEOUT = 300;
const LENGTH_YEAR = 4;
let TOOLBAR_ID = 0;
let DEBOUNCE_ID = 0;

@Component({
  selector: 'contabilidadedigital-docviewer-recolha',
  templateUrl: './contabilidadedigital.docviewer.recolha.component.html'
})
export class ContabilidadeDigitalDocViewerRecolhaComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public documentsService: ContabilidadeDigitalServiceDocuments;
  @Input() public toolbarInstanceId: string;
  @Input() public readonly: boolean;
  @Input() public state: EArquivoDigitalDocViewerState;
  @Input() public doc: IContabDigitalGDocViewerRecolhaDoc;
  @Input() public year: number;
  @Input() public search: IJsonContabDigitalGDocViewerRecolhaSearch;
  @Input() public searchParams: IContabDigitalGDocViewerRecolhaSearchParams;
  @Input() public searchResult: IJsonContabDigitalGDocViewerRecolhaSearchResult;
  @Input() public addDocsMode: EArquivoDigitalDocViewerAddDocsMode;
  @Input() public obtemDadosDocDigital: boolean;
  @Input() public hideToolbarOnEmptyDoc: boolean;
  @Input() public showHeader: boolean;
  @Input() public showFooter: boolean;
  @Input() public showFooterCollapse: boolean;
  @Input() public showLinhasIVA: boolean;
  @Input() public nDocumento: string;
  @Input() public footerCollapsed: boolean;
  @Input() public callback: IContabDigitalDocViewerRecolhaCallback;
  @Output() public readonly stateChange: EventEmitter<EArquivoDigitalDocViewerState>;
  @Output() public readonly footerCollapsedChange: EventEmitter<boolean>;
  @Output() public readonly evtChangedAttachment: EventEmitter<IArquivoDigitalDocViewerEvtChangedAttachment>;
  @Output() public readonly evtAttachedAttachment: EventEmitter<IJsonDocDigitalizado>;
  @Output() public readonly evtDeletedAttachment: EventEmitter<void>;
  @Output() public readonly evtAttachedVerbete: EventEmitter<string>;

  public readonly states: typeof EArquivoDigitalDocViewerState;
  public location: string;
  public locationWithFilename: string;
  public searchingTitle: string;
  public attachmentsTotal: number;
  public attachmentDocId: string;
  public validSearch: boolean;
  public oneHundredPercentMatch: boolean;
  public selectedAttachment: IJsonDocOCR;

  private readonly _selectAttachmentDebounceId: string;
  private readonly _toolbarLocation: IPlToolbarItem<string>;
  private readonly _btnChooseFolder: IPlToolbarItem;
  private readonly _btnAddDocs: IPlToolbarItem;
  private readonly _btnAttachExistingDoc: IPlToolbarMenuItem;
  private readonly _btnAttachNewDoc: IPlToolbarMenuItem;
  private readonly _btnAttachVerbete: IPlToolbarMenuItem;
  private readonly _mnuAttachDoc: IPlToolbarItem;
  private readonly _btnDeleteAttachment: IPlToolbarItem;
  private readonly _mnuBtnSelectFirstAttachment: IPlToolbarMenuItem;
  private readonly _mnuBtnSelectPreviousAttachment: IPlToolbarMenuItem;
  private readonly _mnuBtnSelectNextAttachment: IPlToolbarMenuItem;
  private readonly _mnuBtnSelectLastAttachment: IPlToolbarMenuItem;
  private readonly _mnuNav: IPlToolbarItem;
  private readonly _btnDownload: IPlToolbarMenuItem;
  private readonly _btnScannedDocsManagement: IPlToolbarItem;
  private readonly _btnConfig: IPlToolbarItem;
  private readonly _btnHelp: IPlToolbarItem;
  private readonly _subscriptionConfigurations: Subscription;
  private _pdfToolbarBtnDownload: IPlToolbarItem;
  private _toolbarInstance: IPlToolbarInstance;
  private _toolbarInstanceOwned: boolean;
  private _selectedFolder: IJsonContabDigitalGDocViewerRecolhaFolderAttachments;
  private _selectedAttachmentIndex: number;
  private _attachmentsCurrent: number;
  private _loading: boolean;
  private _disabledAdd: boolean;
  private _anoEmCursoIRC: number;
  private _modoCGOn: boolean;
  private _contabDigitalCGOnAllowAddFiles: boolean;
  private _cgStoreUrl: string;

  constructor(
    private readonly _stateService: StateService,
    private readonly _logger: Logger,
    private readonly _plAlertService: PlAlertService,
    private readonly _plI18nService: PlI18nService,
    private readonly _plToolbarService: PlToolbarService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _configService: ConfigService,
    private readonly _configSiteService: ConfigSiteService,
    private readonly _cgModalService: CGModalService,
    private readonly _cgStateService: CGStateService,
    private readonly _contabilidadeDigitalUIService: ContabilidadeDigitalUIService,
    private readonly _docsContabilidadeService: DocsContabilidadeService,
    private readonly _authService: AuthService
  ) {
    this.documentsService = this._contabilidadeDigitalUIService.documents;
    this.stateChange = new EventEmitter<EArquivoDigitalDocViewerState>();
    this.footerCollapsedChange = new EventEmitter<boolean>();
    this.evtChangedAttachment = new EventEmitter<IArquivoDigitalDocViewerEvtChangedAttachment>();
    this.evtAttachedAttachment = new EventEmitter<IJsonDocDigitalizado>();
    this.evtDeletedAttachment = new EventEmitter<void>();
    this.evtAttachedVerbete = new EventEmitter<string>();
    this.states = EArquivoDigitalDocViewerState;
    this.validSearch = false;
    this._selectAttachmentDebounceId = `${SELECT_ATTACHMENT_DEBOUNCE_ID}-${DEBOUNCE_ID++}`;
    this._toolbarLocation = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'location',
      type: 'html',
      order: TOOLBAR_ORDER_BASE,
      caption: '',
      visible: false
    };
    this._btnChooseFolder = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'chooseFolder',
      type: 'button',
      order: this._toolbarLocation.order + TOOLBAR_ORDER_STEP,
      iconLeft: '<i class="fa fa-folder-open-o"></i>',
      class: 'btn-sm btn-primary',
      title: 'arquivodigital.docviewerrecolha.title.chooseFolder',
      disabled: true,
      click: () => this.chooseFolder()
    };
    this._btnAddDocs = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'addDocs',
      type: 'button',
      order: this._btnChooseFolder.order + TOOLBAR_ORDER_STEP,
      iconLeft: '<i class="fa fa-plus-circle"></i>',
      class: 'btn-sm btn-primary',
      title: 'arquivodigital.docviewerrecolha.title.addDocsCurrentFolder',
      visible: false,
      disabled: true,
      click: () => {
        this.loading = true;
        return this._addDocs().finally(() => {
          this.loading = false;
        });
      }
    };
    this._btnAttachExistingDoc = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'attachExistingDoc',
      iconLeft: '<i class="fa fa-fw fa-list"></i>&nbsp;',
      caption: 'arquivodigital.docviewerrecolha.actions.attachExistingDoc',
      disabled: true,
      click: () => this._attachExistingDoc()
    };
    this._btnAttachNewDoc = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'attachNewDoc',
      iconLeft: '<i class="fa fa-fw fa-plus-circle"></i>&nbsp;',
      caption: 'arquivodigital.docviewerrecolha.actions.attachNewDoc',
      disabled: true,
      click: () => this._attachNewDoc()
    };
    this._btnAttachVerbete = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'attachVerbete',
      iconLeft: '<i class="fa fa-fw fa-list-alt"></i>&nbsp;',
      caption: 'arquivodigital.docviewerrecolha.actions.attachVerbete',
      disabled: true,
      click: () => this._attachVerbete()
    };
    this._mnuAttachDoc = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'menuAttachDoc',
      type: 'dropdown',
      order: this._btnAddDocs.order,
      class: 'btn-sm btn-primary',
      iconLeft: '<i class="fa fa-file-pdf-o"></i>&nbsp;',
      title: 'arquivodigital.docviewerrecolha.title.attachDoc',
      disabled: true,
      visible: false,
      menu: [this._btnAttachExistingDoc, this._btnAttachNewDoc, this._btnAttachVerbete]
    };
    this._btnDeleteAttachment = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'deleteAttachment',
      type: 'button',
      order: this._mnuAttachDoc.order + TOOLBAR_ORDER_STEP,
      class: 'btn-sm btn-danger',
      title: 'arquivodigital.docviewerrecolha.title.deleteAttachment',
      disabled: true,
      click: () => this._deleteSelectedAttachment(true)
    };
    this._mnuBtnSelectFirstAttachment = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'selectFirstAttachment',
      iconLeft: '<i class="fa fa-angle-double-left"></i>',
      class: 'btn-sm btn-light',
      title: 'arquivodigital.docviewerrecolha.navigation.selectFirstAttachment',
      click: () => {
        this._selectFirstAttachment();
      }
    };
    this._mnuBtnSelectPreviousAttachment = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'selectPreviousAttachment',
      iconLeft: '<i class="fa fa-angle-left"></i>',
      class: 'btn-sm btn-light',
      title: 'arquivodigital.docviewerrecolha.navigation.selectPreviousAttachment',
      click: () => {
        this._selectPreviousAttachment();
      }
    };
    this._mnuBtnSelectNextAttachment = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'selectNextAttachment',
      iconLeft: '<i class="fa fa-angle-right"></i>',
      class: 'btn-sm btn-light',
      title: 'arquivodigital.docviewerrecolha.navigation.selectNextAttachment',
      click: () => {
        this._selectNextAttachment();
      }
    };
    this._mnuBtnSelectLastAttachment = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'selectLastAttachment',
      iconLeft: '<i class="fa fa-angle-double-right"></i>',
      class: 'btn-sm btn-light',
      title: 'arquivodigital.docviewerrecolha.navigation.selectLastAttachment',
      click: () => {
        this._selectLastAttachment();
      }
    };
    this._mnuNav = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'attachmentsNav',
      type: 'button-group',
      order: this._btnDeleteAttachment.order + TOOLBAR_ORDER_STEP,
      items: [this._mnuBtnSelectFirstAttachment, this._mnuBtnSelectPreviousAttachment, this._mnuBtnSelectNextAttachment, this._mnuBtnSelectLastAttachment]
    };
    this._btnDownload = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'download',
      type: 'download',
      iconLeft: '<i class="fa fa-download"></i>',
      class: 'btn-sm btn-light',
      title: 'arquivodigital.docviewerrecolha.title.download',
      disabled: true,
      visible: false,
      download: {url: undefined, filename: undefined}
    };
    const moduleDocsDigitais: StateDeclaration = this._cgStateService.getRedirectState({stateOrName: MODULE_NAME_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS});
    const moduleDocsDigitaisUrl: string = !moduleDocsDigitais ? undefined : this._stateService.href(moduleDocsDigitais);
    this._btnScannedDocsManagement = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'scannedDocsManagement',
      type: 'link',
      order: this._btnDownload.order + TOOLBAR_ORDER_STEP,
      iconLeft: '<i class="fa fa-barcode"></i>',
      class: 'btn-light',
      title: 'arquivodigital.docviewerrecolha.title.scannedDocumentManagement',
      visible: Boolean(moduleDocsDigitaisUrl),
      href: {
        target: '_blank',
        value: moduleDocsDigitaisUrl
      }
    };
    this._btnConfig = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'config',
      type: 'button',
      order: this._btnScannedDocsManagement.order + TOOLBAR_ORDER_STEP,
      iconLeft: '<i class="fa fa-list-alt"></i>',
      class: 'btn-sm btn-light',
      title: 'arquivodigital.docviewerrecolha.title.config',
      click: () => this._config()
    };
    this._btnHelp = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'help',
      type: 'button',
      iconLeft: '<i class="fa fa-question-circle"></i>',
      class: 'btn-sm btn-light',
      title: 'global.btn.help',
      click: () => this._help()
    };
    this._toolbarInstanceOwned = false;
    this._selectedFolder = {
      folderID: '',
      folderParentID: '',
      folderName: '',
      folderPath: '',
      isFolderFromNaoClassificados: false,
      isFolderFromClassificados: false,
      anexos: []
    };
    this._selectedAttachmentIndex = -1;
    this._attachmentsCurrent = 0;
    this.attachmentsTotal = 0;
    this._loading = false;
    this._disabledAdd = true;
    this._subscriptionConfigurations = this._configService.configurationsAsObservable().subscribe((configurations: ICGConfigurations) => {
      this._anoEmCursoIRC = configurations.empresa.anoEmCursoIRC;
      this._modoCGOn = configurations.licenca.modoCGOn;
      this._contabDigitalCGOnAllowAddFiles = configurations.empresa.cgon.allowAddFiles;
    });
    this._configSiteService.cgStoreUrlBackOffice().then((value: string) => {
      this._cgStoreUrl = value;
    });
  }

  public ngOnInit(): void {
    this._changedDocumentsService();
    this._changedToolbarInstanceId();
    this._changedReadonly();
    this._changedDoc();
    this._changedSearch();
    this._changedSearchResult();
    this._changedObtemDadosDocDigital();
    this._changedAddDocsMode();
    this._changedHideToolbarOnEmptyDoc();
    this._changedShowHeader();
    this._changedShowFooter();
    this._changedShowLinhasIVA();
    this._changedState();
    this._init();
  }

  public ngOnChanges({
    toolbarInstanceId,
    readonly,
    state,
    doc,
    year,
    search,
    searchResult,
    obtemDadosDocDigital,
    addDocsMode,
    hideToolbarOnEmptyDoc,
    showFooter,
    showLinhasIVA,
    showHeader,
    callback
  }: SimpleChanges): void {
    if (year && !year.isFirstChange()) {
      this._changedYear(year.currentValue);
    }
    const changedToolbarInstanceId: boolean = toolbarInstanceId && !toolbarInstanceId.isFirstChange();
    const changedState: boolean = state && !state.isFirstChange();
    const changedDoc: boolean = doc && !doc.isFirstChange();
    const changedSearch: boolean = search && !search.isFirstChange();
    const changedSearchResult: boolean = searchResult && !searchResult.isFirstChange();
    const changedShowHeader: boolean = showHeader && !showHeader.isFirstChange();
    if (changedToolbarInstanceId || changedState || changedDoc || changedSearch || changedSearchResult || changedShowHeader) {
      if (changedToolbarInstanceId) {
        this._changedToolbarInstanceId(toolbarInstanceId.currentValue);
      }
      if (changedState || changedDoc || changedSearch || changedSearchResult) {
        if (changedState) {
          this._changedState(state.currentValue);
        } else {
          if (changedDoc) {
            this._changedDoc(doc.currentValue);
          }
          if (changedSearch) {
            this._changedSearch(search.currentValue);
          }
          if (changedSearchResult) {
            this._changedSearchResult(searchResult.currentValue);
          }
          this._evaluateState();
        }
        if (this.state === EArquivoDigitalDocViewerState.Editing) {
          this.loadDocAttachments();
        } else if (this.state === EArquivoDigitalDocViewerState.Searching) {
          this.searchContabilidadeAttachments();
        }
      }
      if (changedShowHeader) {
        this._changedShowHeader(showHeader.currentValue);
      }
      this._evaluateToolbar();
    }
    if (readonly && !readonly.isFirstChange()) {
      this._changedReadonly(readonly.currentValue);
    }
    if (obtemDadosDocDigital && !obtemDadosDocDigital.isFirstChange()) {
      this._changedObtemDadosDocDigital(obtemDadosDocDigital.currentValue);
    }
    if (addDocsMode && !addDocsMode.isFirstChange()) {
      this._changedAddDocsMode(addDocsMode.currentValue);
      this._evaluateLocation();
    }
    if (hideToolbarOnEmptyDoc && !hideToolbarOnEmptyDoc.isFirstChange()) {
      this._changedHideToolbarOnEmptyDoc(hideToolbarOnEmptyDoc.currentValue);
    }
    if (showFooter && !showFooter.isFirstChange()) {
      this._changedShowFooter(showFooter.currentValue);
    }
    if (showLinhasIVA && !showLinhasIVA.isFirstChange()) {
      this._changedShowLinhasIVA(showLinhasIVA.currentValue);
    }
    if (callback) {
      const cb: IContabDigitalDocViewerRecolhaCallback = callback.currentValue;
      if (isObject(cb)) {
        cb.clear = () => {
          this._clear();
          return Promise.resolve();
        };
        cb.loadLastFolder = () => this.loadLastFolder();
        cb.loadDocAttachments = (cbExtPocCabID: string, cbYear: number) => this.loadDocAttachments(cbExtPocCabID, cbYear);
        cb.searchContabilidadeAttachments = (cbSearch: IJsonContabDigitalGDocViewerRecolhaSearch, cbSearchParams: IContabDigitalGDocViewerRecolhaSearchParams) => {
          return this.searchContabilidadeAttachments(cbSearch, cbSearchParams);
        };
        cb.reset = () => this._init();
        cb.getSelectedAttachment = () => copy(this.selectedAttachment);
        cb.getNextAttachment = () => this._getNextAttachment();
        cb.deleteSelectedAttachment = (deleteFromDB: boolean, properties?: IContabDigitalDocViewerRecolhaSelectAttachmentOptions) => this._deleteSelectedAttachment(deleteFromDB, properties);
      }
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionConfigurations.unsubscribe();
    this._cleanupToolbar();
  }

  public async chooseFolder(saveLastFolder: boolean = true): Promise<void> {
    if (this.readonly) {
      return Promise.resolve();
    }
    const {folder, file} = await this._callChooseFolder();
    const folderId: string = folder.folderID;
    if (saveLastFolder) {
      this._contabilidadeDigitalUIService.configs.saveLastFolder(folderId).catch((reason: unknown) => {
        this._logger.error(reason);
      });
    }
    const docId: string = isObject(file) ? file.docID : undefined;
    return this._selectFolderId(folderId, docId);
  }

  public configureToolbar(event: IArquivoDigitalDocViewerEvtConfigureToolbar): void {
    this._contabilidadeDigitalUIService.configureToolbar(event);
    event.mnuNav.visible = false;
    event.btnDownload.visible = false;
    this._pdfToolbarBtnDownload = event.btnDownload;
    this._btnDownload.order = event.btnDownload.order;
    this._btnScannedDocsManagement.order = event.btnRotateRight.order + TOOLBAR_ORDER_STEP;
    this._btnConfig.order = this._btnScannedDocsManagement.order + TOOLBAR_ORDER_STEP;
    this._btnHelp.order = this._btnConfig.order + TOOLBAR_ORDER_STEP;
    this._toolbarInstance.sortItems();
    this._evaluateToolbar();
  }

  public changedFooterCollapsed(value: boolean): void {
    this.footerCollapsed = value;
    this.footerCollapsedChange.emit(this.footerCollapsed);
  }

  public async loadLastFolder(): Promise<void> {
    this.loading = true;
    const configs: IJsonContabDigitalConfigs = await firstValueFrom(this._contabilidadeDigitalUIService.configs.getConfigs().pipe(take(1)));
    if (isObject(configs) && !isEmpty(configs.lastFolder)) {
      await this._selectFolderId(configs.lastFolder);
      this.loading = false;
    } else {
      this.loading = false;
    }
  }

  public async loadDocAttachments(extPocCabID: string = this.doc?.extPocCabID, year: number = this.year): Promise<void> {
    this.loading = true;
    const docyear: number = year ? year : !isEmpty(this.doc?.periodo) ? Number(this.doc?.periodo.substring(0, LENGTH_YEAR)) : -1;
    const response: HttpResponse<string> = await this._docsContabilidadeService.getDocsDigitaisFolder(extPocCabID, docyear).catch((reason: HttpErrorResponse) => {
      this._logger.error(reason);
      return undefined;
    });
    const folderId: string = response ? response.body : undefined;
    if (!folderId) {
      this.loading = false;
      this._selectFolder(undefined);
      return Promise.resolve();
    }
    return this._selectFolderId(folderId).finally(() => {
      this.loading = false;
    });
  }

  public async searchContabilidadeAttachments(
    search: IJsonContabDigitalGDocViewerRecolhaSearch = this.search,
    searchParams: IContabDigitalGDocViewerRecolhaSearchParams = this.searchParams
  ): Promise<IJsonContabDigitalGDocViewerRecolhaSearchResult> {
    let searchResult: IJsonContabDigitalGDocViewerRecolhaSearchResult;
    if (isObject(this.searchResult)) {
      this.validSearch = true;
      searchResult = this.searchResult;
    } else if (
      !isObject(search) ||
      (isEmpty(search.nif) && isEmpty(search.nDocExterno) && isEmpty(search.dataDoc) && !isNumber(search.totalBase) && !isNumber(search.totalIVA) && !isNumber(search.totalDoc))
    ) {
      this.validSearch = false;
      searchResult = {
        folderID: '',
        folderParentID: '',
        folderName: '',
        folderPath: '',
        isFolderFromNaoClassificados: false,
        isFolderFromClassificados: false,
        anexos: [],
        oneHundredPercentMatch: false
      };
    } else {
      this.validSearch = true;
      this.loading = true;
      const response: HttpResponse<IJsonContabDigitalGDocViewerRecolhaSearchResult> = await this._contabilidadeDigitalUIService.gDocViewerRecolha.search(search, searchParams);
      this.loading = false;
      searchResult = response.body;
    }
    this.oneHundredPercentMatch = searchResult.oneHundredPercentMatch;
    this._evaluateSearchingTitle(searchResult);
    this._selectFolder(searchResult);
    return searchResult;
  }

  public get loading(): boolean {
    return this._loading;
  }

  public set loading(value: boolean) {
    this._loading = value;
    this._evaluateToolbar();
  }

  private _changedDocumentsService(documentsService: ContabilidadeDigitalServiceDocuments = this.documentsService): void {
    let val: ContabilidadeDigitalServiceDocuments = documentsService;
    if (isUndefinedOrNull(val)) {
      val = this._contabilidadeDigitalUIService.documents;
    }
    this.documentsService = val;
  }

  private _changedState(value: EArquivoDigitalDocViewerState = this.state): void {
    if (isNumber(value)) {
      this.state = value;
    } else {
      this._evaluateState();
    }
  }

  private _changedToolbarInstanceId(value: string = this.toolbarInstanceId): void {
    this._cleanupToolbar();
    this.toolbarInstanceId = value || `${TOOLBAR_GROUP_ID}-${TOOLBAR_ID++}`;
    this._toolbarInstanceOwned = !this._plToolbarService.isRegistered(this.toolbarInstanceId);
    this._toolbarInstance = this._plToolbarService.getInstance(this.toolbarInstanceId);
    this._toolbarInstance
      .removeGroupId(TOOLBAR_GROUP_ID)
      .addButton(this._toolbarLocation)
      .addButton(this._btnChooseFolder)
      .addButton(this._btnAddDocs)
      .addButton(this._mnuAttachDoc)
      .addButton(this._btnDeleteAttachment)
      .addButton(this._mnuNav)
      .addButton(this._btnDownload)
      .addButton(this._btnConfig)
      .addButton(this._btnHelp);
  }

  private _changedReadonly(value: boolean = this.readonly): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = false;
    }
    this.readonly = val;
  }

  private _changedDoc(value: IContabDigitalGDocViewerRecolhaDoc = this.doc): void {
    this.doc = isObject(value) ? value : undefined;
  }

  private _changedYear(value: number = this.year): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this._anoEmCursoIRC;
    }
    if (!isNumber(val)) {
      val = -1;
    }
    this.year = val;
  }

  private _changedSearch(value: IJsonContabDigitalGDocViewerRecolhaSearch = this.search): void {
    this.search = isObject(value) ? value : undefined;
  }

  private _changedSearchResult(value: IJsonContabDigitalGDocViewerRecolhaSearchResult = this.searchResult): void {
    this.searchResult = isObject(value) ? value : undefined;
  }

  private _changedObtemDadosDocDigital(value: boolean = this.obtemDadosDocDigital): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = true;
    }
    this.obtemDadosDocDigital = val;
  }

  private _changedAddDocsMode(value: EArquivoDigitalDocViewerAddDocsMode = this.addDocsMode): void {
    this.addDocsMode = isNumber(value) ? value : EArquivoDigitalDocViewerAddDocsMode.CurrentFolder;
  }

  private _changedHideToolbarOnEmptyDoc(value: boolean = this.hideToolbarOnEmptyDoc): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = true;
    }
    this.hideToolbarOnEmptyDoc = val;
  }

  private _changedShowFooter(value: boolean = this.showFooter): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = false;
    }
    this.showFooter = val;
  }

  private _changedShowLinhasIVA(value: boolean = this.showLinhasIVA): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = true;
    }
    this.showLinhasIVA = val;
  }

  private _changedShowHeader(value: boolean = this.showHeader): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = false;
    }
    this.showHeader = val;
  }

  private _evaluateState(): void {
    if (isObject(this.doc)) {
      this.state = EArquivoDigitalDocViewerState.Editing;
    } else if (isObject(this.search) || isObject(this.searchResult)) {
      this.state = EArquivoDigitalDocViewerState.Searching;
    } else {
      this.state = EArquivoDigitalDocViewerState.Default;
    }
  }

  private _init(): Promise<void> {
    switch (this.state) {
      case EArquivoDigitalDocViewerState.Default:
        return this.loadLastFolder();
      case EArquivoDigitalDocViewerState.Editing:
        return this.loadDocAttachments();
      case EArquivoDigitalDocViewerState.Searching:
        return this.searchContabilidadeAttachments().then(() => undefined);
      default:
        return Promise.resolve();
    }
  }

  private async _selectFolderId(folderId: string, docId?: string): Promise<void> {
    const response: HttpResponse<IJsonContabDigitalGDocViewerRecolhaFolderAttachments> = await this._contabilidadeDigitalUIService.gDocViewerRecolha.folderAttachments(folderId);
    this._selectFolder(response.body, docId);
  }

  private _selectFolder(folder: IJsonContabDigitalGDocViewerRecolhaFolderAttachments, docId?: string): void {
    this._selectedFolder = merge(
      {
        folderID: '',
        folderParentID: '',
        folderName: '',
        folderPath: '',
        isFolderFromNaoClassificados: false,
        isFolderFromClassificados: false,
        anexos: []
      },
      folder
    );
    this._evaluateLocation();
    this._disabledAdd = this._selectedFolder.isFolderFromClassificados;
    const hasAttachments = isArray(this._selectedFolder.anexos) && this._selectedFolder.anexos.length > 0;
    this._changedFolder(hasAttachments);
    if (hasAttachments) {
      if (docId) {
        const attachment: IJsonDocOCR = this._selectedFolder.anexos.find((folderAttachment: IJsonDocOCR) => folderAttachment.cab.docID === docId);
        if (attachment) {
          this._selectAttachment(attachment);
          return;
        }
      }
      this._selectFirstAttachment();
    }
  }

  private _changedFolder(preventSelectAttachment: boolean = false): void {
    if (!preventSelectAttachment) {
      this._selectAttachment(undefined);
    }
    this.attachmentsTotal = this._selectedFolder.anexos.length;
    this._evaluateToolbar();
  }

  private _evaluateToolbar(): void {
    const editing: boolean = this.state === EArquivoDigitalDocViewerState.Editing;
    const searching: boolean = this.state === EArquivoDigitalDocViewerState.Searching;
    const readonly: boolean = this.readonly;
    this._btnChooseFolder.visible = !editing && !searching && !readonly;
    this._btnChooseFolder.disabled = this.loading;
    this._btnAddDocs.visible = !editing && !readonly;
    this._btnAddDocs.disabled = this.loading || this._disabledAdd;
    this._mnuAttachDoc.visible = !this._btnAddDocs.visible && !readonly;
    this._mnuAttachDoc.disabled = this.loading;
    this._btnAttachExistingDoc.disabled = this._mnuAttachDoc.disabled;
    this._btnAttachNewDoc.disabled = this._mnuAttachDoc.disabled;
    this._btnAttachVerbete.disabled = this._mnuAttachDoc.disabled;
    this._btnDeleteAttachment.visible = !readonly;
    this._btnDeleteAttachment.title = !editing
      ? 'arquivodigital.docviewerrecolha.actions.deleteAttachment.titleDelete'
      : 'arquivodigital.docviewerrecolha.actions.deleteAttachment.titleRemoveAssociation';
    this._btnDeleteAttachment.iconLeft = `<i class="fa ${!editing ? 'fa-trash' : 'fa-chain-broken'}"></i>`;
    this._btnDeleteAttachment.disabled = this.loading || !isObject(this.selectedAttachment);
    this._mnuBtnSelectFirstAttachment.disabled = this.loading || !this.attachmentsTotal || this._attachmentsCurrent === 1;
    this._mnuBtnSelectPreviousAttachment.disabled = this._mnuBtnSelectFirstAttachment.disabled;
    this._mnuBtnSelectNextAttachment.disabled = this.loading || !this.attachmentsTotal || this._attachmentsCurrent === this.attachmentsTotal;
    this._mnuBtnSelectLastAttachment.disabled = this._mnuBtnSelectNextAttachment.disabled;
    this._btnDownload.visible = isObject(this._selectedFolder) && this._selectedFolder.isFolderFromClassificados;
    this._btnDownload.disabled = this.loading || !isObject(this.selectedAttachment) || !this._btnDownload.download.url;
    if (this._pdfToolbarBtnDownload) {
      this._pdfToolbarBtnDownload.visible = !this._btnDownload.visible;
      this._pdfToolbarBtnDownload.disabled = this._btnDownload.disabled;
    }
    this._btnScannedDocsManagement.visible = !readonly;
    this._btnConfig.visible = !readonly;
    this._btnHelp.visible = !readonly;
    this._evaluateToolbarLocation();
  }

  private _selectFirstAttachment(): void {
    if (this._selectedFolder.anexos.length) {
      this._selectAttachment(this._selectedFolder.anexos[0], {emitEventWithDebounce: true});
    }
  }

  private _selectPreviousAttachment(): void {
    if (this._selectedFolder.anexos.length && this._selectedAttachmentIndex > 0) {
      this._selectAttachment(this._selectedFolder.anexos[this._selectedAttachmentIndex - 1], {emitEventWithDebounce: true});
    }
  }

  private _selectNextAttachment(): void {
    if (this._selectedFolder.anexos.length && this._selectedAttachmentIndex < this._selectedFolder.anexos.length - 1) {
      this._selectAttachment(this._selectedFolder.anexos[this._selectedAttachmentIndex + 1], {emitEventWithDebounce: true});
    }
  }

  private _selectLastAttachment(): void {
    if (this._selectedFolder.anexos.length) {
      this._selectAttachment(this._selectedFolder.anexos[this._selectedFolder.anexos.length - 1], {emitEventWithDebounce: true});
    }
  }

  private _selectAttachment(value: IJsonDocOCR, properties?: IContabDigitalDocViewerRecolhaSelectAttachmentOptions): void {
    properties = {emitEvent: true, emitEventWithDebounce: false, doInitDocumento: true, ...properties};
    const {emitEvent, emitEventWithDebounce, doInitDocumento}: IContabDigitalDocViewerRecolhaSelectAttachmentOptions = properties;
    this.selectedAttachment = value;
    let attachmentDocId: string;
    if (!this.selectedAttachment) {
      this._selectedAttachmentIndex = -1;
      attachmentDocId = undefined;
      this._btnDownload.download.url = undefined;
      this._btnDownload.download.filename = undefined;
      if (this._selectedFolder) {
        this._evaluateLocation();
      }
    } else {
      this._selectedAttachmentIndex = this._selectedFolder.anexos.findIndex((folderAttachment: IJsonDocOCR) => folderAttachment === this.selectedAttachment);
      attachmentDocId = this.selectedAttachment.cab.docID;
      this._btnDownload.download.filename = this.selectedAttachment.cab.gDoc.nomeFicheiro;
      this.documentsService.getUrl(attachmentDocId, true, false).subscribe((url: string) => {
        this._btnDownload.download.url = url;
      });
      if (this._selectedFolder) {
        this._evaluateLocation();
      }
    }
    this._attachmentsCurrent = this._selectedAttachmentIndex + 1;
    if (emitEvent) {
      if (emitEventWithDebounce && attachmentDocId) {
        // Avoids browser slowdown when selecting attachments very quickly
        debounce(
          () => {
            this._setAttachmentDocId(attachmentDocId, doInitDocumento);
          },
          SELECT_ATTACHMENT_DEBOUNCE_TIMEOUT,
          this._selectAttachmentDebounceId
        );
      } else {
        this._setAttachmentDocId(attachmentDocId, doInitDocumento);
      }
    }
    this._evaluateToolbar();
  }

  private _setAttachmentDocId(attachmentDocId: string, doInitDocumento?: boolean): void {
    this.attachmentDocId = attachmentDocId;
    this.evtChangedAttachment.emit({folder: this._selectedFolder, attachment: this.selectedAttachment, doInitDocumento: doInitDocumento});
  }

  private _evaluateLocation(): void {
    const selectedFolderPath: string = this._selectedFolder?.folderPath ?? '';
    const selectedFileName: string = this.selectedAttachment?.cab.gDoc.nomeFicheiro ?? '';
    const locations: [Array<string>, Array<string>] = this._parseLocation(selectedFolderPath, selectedFileName);
    const location: Array<string> = locations[0];
    const locationWithFilename: Array<string> = locations[1];
    if (this.addDocsMode === EArquivoDigitalDocViewerAddDocsMode.ChooseFolder || !location.length || this._selectedFolder.isFolderFromClassificados) {
      this._btnAddDocs.title =
        this.addDocsMode === EArquivoDigitalDocViewerAddDocsMode.ChooseFolder ? 'arquivodigital.docviewerrecolha.title.addDocs' : 'arquivodigital.docviewerrecolha.title.addDocsCurrentFolder';
    } else {
      const folderPath: Array<string> = location.slice();
      const docsNaoClassificadosIndex: number = folderPath.indexOf(CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_DOCS_NAO_CLASSIFICADOS);
      if (docsNaoClassificadosIndex > -1) {
        folderPath.splice(docsNaoClassificadosIndex, 1);
      }
      this._btnAddDocs.title = this._plTranslateService.translate('arquivodigital.docviewerrecolha.title.addDocsWithPath', {
        folderPath: folderPath.join(CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_SEPARATOR)
      });
    }
    this.location = location.join(CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_SEPARATOR);
    this.locationWithFilename = locationWithFilename.join(CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_SEPARATOR);
    this._evaluateToolbarLocation();
  }

  private _evaluateToolbarLocation(): void {
    this._toolbarLocation.visible = !this.showHeader && Boolean(this.locationWithFilename);
    if (this._toolbarLocation.visible && this._toolbarLocation.data !== this.locationWithFilename) {
      const textLocation: string = this._plTranslateService.translate('arquivodigital.docviewerrecolha.title.location');
      this._toolbarLocation.caption = `<i class="fa fa-fw fa-map-marker" title="${textLocation}: ${this.locationWithFilename}"></i>`;
      this._toolbarLocation.data = this.locationWithFilename;
    }
  }

  private _evaluateSearchingTitle(searchResult: IJsonContabDigitalGDocViewerRecolhaSearchResult): void {
    if (!isObject(searchResult)) {
      this.searchingTitle = '';
      return;
    }
    this.searchingTitle = this._plTranslateService.translate(
      !isArray(searchResult.anexos) || !searchResult.anexos.length
        ? 'arquivodigital.docviewerrecolha.title.attachmentNotFound'
        : searchResult.anexos.length > 1
          ? 'arquivodigital.docviewerrecolha.title.attachmentMultipleFound'
          : searchResult.oneHundredPercentMatch
            ? 'arquivodigital.docviewerrecolha.title.attachmentFound'
            : 'arquivodigital.docviewerrecolha.title.attachmentPossiblyFound'
    );
  }

  private _parseLocation(folderPath: string, fileName?: string): [Array<string>, Array<string>] {
    let location: Array<string> = [];
    let locationWithFilename: Array<string> = [];
    if (folderPath) {
      location = this._getLocationPath(folderPath);
      if (location.length) {
        if (location[0] === CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_CONTABILIDADE) {
          location.splice(0, 1);
        }
        if (location[0] === CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_DOCS_NAO_CLASSIFICADOS && this.state === EArquivoDigitalDocViewerState.Default) {
          location.splice(0, 1);
        }
        locationWithFilename = location.slice();
        if (fileName) {
          locationWithFilename.push(fileName);
        }
      }
    }
    return [location, locationWithFilename];
  }

  private _getLocationPath(location: string = this.location): Array<string> {
    return location.split(CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_SEPARATOR);
  }

  private async _addDocs(): Promise<void> {
    if (this.readonly || this.state === EArquivoDigitalDocViewerState.Editing) {
      return;
    }
    const licenseValid: boolean = await this._validateAddFiles();
    if (!licenseValid) {
      return;
    }
    await this._checkFoldersCreated();
    let folderID: string = this._selectedFolder.folderID;
    let locationPath: Array<string> = this._getLocationPath();
    if (this.addDocsMode === EArquivoDigitalDocViewerAddDocsMode.ChooseFolder) {
      const modalInstance: NgbModalRef = this._cgModalService.showVanilla(ContabilidadeDigitalDocViewerRecolhaChooseFolderModalComponent, {size: 'md'});
      const componentInstanceChooseFolderModal: ContabilidadeDigitalDocViewerRecolhaChooseFolderModalComponent = modalInstance.componentInstance;
      componentInstanceChooseFolderModal.showFiles = false;
      componentInstanceChooseFolderModal.anoEmCursoIRC = true;
      const {folder}: IContabDigitalDocViewerRecolhaChooseFolderModalResult = await modalInstance.result;
      folderID = folder.folderID;
      this._contabilidadeDigitalUIService.configs.saveLastFolder(folderID);
      const [location] = this._parseLocation(folder.folderPath + folder.name);
      locationPath = location;
    }
    const modalInstance = this._cgModalService.showVanilla(ContabilidadeDigitalClientConnectAddDocsModalComponent, {size: 'lg'});
    const componentInstance: ContabilidadeDigitalClientConnectAddDocsModalComponent = modalInstance.componentInstance;
    componentInstance.documentsService = this.documentsService;
    componentInstance.folderId = folderID;
    componentInstance.foldersNamePath = locationPath;
    componentInstance.doOCR = this.obtemDadosDocDigital;
    let needsRefresh = false;
    await modalInstance.result
      .then((result: boolean) => {
        needsRefresh = result;
      })
      .catch((reason: unknown) => {
        if (isBoolean(reason)) {
          needsRefresh = reason;
        }
      });
    if (needsRefresh) {
      if (this.state === EArquivoDigitalDocViewerState.Default) {
        const docId: string = this.selectedAttachment ? this.selectedAttachment.cab.docID : undefined;
        await this._selectFolderId(folderID, docId);
      }
      this._plAlertService.success('arquivodigital.clientconnect.adddocsmodal.success');
    }
  }

  private async _attachExistingDoc(): Promise<void> {
    if (this.readonly || this.state !== EArquivoDigitalDocViewerState.Editing) {
      return;
    }
    const licenseValid: boolean = await this._validateAddFiles();
    if (!licenseValid) {
      return;
    }
    await this._checkHasDigitalDoc();
    await this._checkFoldersCreated();
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(ContabilidadeDigitalDocViewerRecolhaChooseFolderModalComponent, {size: 'xl'});
    const componentInstance: ContabilidadeDigitalDocViewerRecolhaChooseFolderModalComponent = modalInstance.componentInstance;
    componentInstance.fileRequired = true;
    const {folder, file}: IContabDigitalDocViewerRecolhaChooseFolderModalResult = await modalInstance.result;
    if (!isEmpty(this.doc.dataDoc)) {
      const responseAttachment: HttpResponse<IJsonDocOCR> = await this._contabilidadeDigitalUIService.gDocViewerRecolha.getAttachment(file.docID);
      const attachment: IJsonDocOCR = responseAttachment.body;
      if (!moment(this.doc.dataDoc).isSame(attachment.cab.dataDoc, 'day')) {
        await this._cgModalService.showOkCancel(
          'global.text.warning',
          this._plTranslateService.translate('arquivodigital.docviewerrecolha.text.warningDateMismatch', {
            dataDoc: this._plI18nService.formatDate(this.doc.dataDoc),
            dataDocDigital: this._plI18nService.formatDate(attachment.cab.dataDoc)
          })
        );
      }
    }
    await this._selectFolderId(folder.folderID, file.docID);
    const responseDocDigitalizado: HttpResponse<IJsonDocDigitalizado> = await this._docsContabilidadeService.anexarDocDigitalExistente(this.doc.extPocCabID, file.docID);
    const docDigitalizado: IJsonDocDigitalizado = responseDocDigitalizado.body;
    this.evtAttachedAttachment.emit(docDigitalizado);
    const {folderID, docID}: IJsonDocDigitalizado = docDigitalizado;
    await this._selectFolderId(folderID, docID);
    this._plAlertService.success('arquivodigital.docviewerrecolha.text.successAttachDoc');
  }

  private async _attachNewDoc(): Promise<void> {
    if (this.readonly || this.state !== EArquivoDigitalDocViewerState.Editing) {
      return;
    }
    const licenseValid: boolean = await this._validateAddFiles();
    if (!licenseValid) {
      return;
    }
    await this._checkHasDigitalDoc();
    await this._checkFoldersCreated();
    let folderLocation: Array<string> = [];
    let modalInstance: NgbModalRef;
    let folderIDModal = this._selectedFolder?.folderID ?? '';
    if (this.state !== EArquivoDigitalDocViewerState.Editing || !this.attachmentDocId) {
      modalInstance = this._cgModalService.showVanilla(ContabilidadeDigitalDocViewerRecolhaChooseFolderModalComponent, {size: 'md'});
      const componentInstanceChooseFolderModal: ContabilidadeDigitalDocViewerRecolhaChooseFolderModalComponent = modalInstance.componentInstance;
      componentInstanceChooseFolderModal.showFiles = false;
      componentInstanceChooseFolderModal.anoEmCursoIRC = true;
      const {folder}: IContabDigitalDocViewerRecolhaChooseFolderModalResult = await modalInstance.result;
      folderIDModal = folder.folderID;
      folderLocation = this._parseLocation(folder.folderPath + folder.name)[0];
    }

    modalInstance = this._cgModalService.showVanilla(ContabilidadeDigitalDocViewerRecolhaAttachExistingDocModalComponent, {size: 'lg'});
    const componentInstanceAttachExistingDocModal: ContabilidadeDigitalDocViewerRecolhaAttachExistingDocModalComponent = modalInstance.componentInstance;
    componentInstanceAttachExistingDocModal.foldersNamePath = folderLocation;
    const file: File = await modalInstance.result;

    const responseDocDigitalizado: HttpResponse<IJsonDocDigitalizado> = await this._docsContabilidadeService.anexarNovoDocDigital(this.doc.extPocCabID, folderIDModal, file);
    const {folderID, docID}: IJsonDocDigitalizado = responseDocDigitalizado.body;
    await this._selectFolderId(folderID, docID);
    this._plAlertService.success('arquivodigital.docviewerrecolha.text.successAttachDoc');
  }

  private async _attachVerbete(): Promise<void> {
    if (this.readonly || this.state !== EArquivoDigitalDocViewerState.Editing) {
      return;
    }
    const licenseValid: boolean = await this._validateLicense();
    if (!licenseValid) {
      return;
    }
    const adicionarVerbeteData: IJsonContabDigitalAdicionarVerbeteData = {
      extPocCabID: this.doc.extPocCabID,
      docOCRCabID: this.selectedAttachment?.cab.docOCRCabID
    };
    if (!adicionarVerbeteData.docOCRCabID) {
      const {folder}: IContabDigitalDocViewerRecolhaChooseFolderModalResult = await this._callChooseFolder();
      adicionarVerbeteData.folderID = folder.folderID;
    }
    const attachmentDocId: string = (await this._contabilidadeDigitalUIService.documents.adicionarVerbete(adicionarVerbeteData)).body;
    if (!adicionarVerbeteData.folderID) {
      await this.callback.pdf.refresh(true);
    } else {
      this.attachmentDocId = attachmentDocId;
    }
    this._plAlertService.success('arquivodigital.docviewerrecolha.text.successAttachVerbete');
    this.evtAttachedVerbete.emit(attachmentDocId);
  }

  private async _validateLicense(): Promise<boolean> {
    return this._contabilidadeDigitalUIService.checkConsumeLicense(EContabilidadeDigitalActivateLicenseType.ContabilidadeDigital);
  }

  private async _validateAddFiles(): Promise<boolean> {
    const validLicense: boolean = await this._validateLicense();
    if (!validLicense) {
      return false;
    }
    if (this._modoCGOn && !this._contabDigitalCGOnAllowAddFiles) {
      this._plAlertService.error(this._plTranslateService.translate('arquivodigital.gestaodocsdigitalizados.errors.addFilesDisallowed', {href: this._cgStoreUrl}));
      return false;
    }
    return true;
  }

  private async _callChooseFolder(): Promise<IContabDigitalDocViewerRecolhaChooseFolderModalResult> {
    await this._checkFoldersCreated();
    return this._cgModalService.show(ContabilidadeDigitalDocViewerRecolhaChooseFolderModalComponent, {size: 'xl'});
  }

  private async _config(): Promise<void> {
    if (this.readonly) {
      return Promise.resolve();
    }
    const validLicense: boolean = await this._contabilidadeDigitalUIService.checkConsumeLicense(EContabilidadeDigitalActivateLicenseType.ContabilidadeDigital);
    if (!validLicense) {
      return Promise.reject(new Error('Invalid "Contabilidade Digital" license.'));
    }
    const session: TUserSession = await this._authService.identity();
    const modalInstance = this._cgModalService.showVanilla(ArquivoDigitalConfigModalComponent, {size: 'xxl'});
    const componentInstance: ArquivoDigitalConfigModalComponent = modalInstance.componentInstance;
    componentInstance.session = session;
    return modalInstance.result.then(() => undefined);
  }

  private async _deleteSelectedAttachment(deleteFromDB: boolean, properties?: IContabDigitalDocViewerRecolhaSelectAttachmentOptions): Promise<void> {
    if (this.readonly || !this._selectedFolder || !this.selectedAttachment) {
      return;
    }
    const selectedAttachmentIndex: number = this._getSelectedAttachmentIndex();
    if (selectedAttachmentIndex > -1) {
      if (deleteFromDB) {
        const title: string =
          this.state === EArquivoDigitalDocViewerState.Editing
            ? 'arquivodigital.docviewerrecolha.actions.deleteAttachment.titleRemoveAssociation'
            : 'arquivodigital.docviewerrecolha.actions.deleteAttachment.titleDelete';
        const message: string =
          this.state === EArquivoDigitalDocViewerState.Editing
            ? 'arquivodigital.docviewerrecolha.actions.deleteAttachment.messageRemoveAssociation'
            : 'arquivodigital.docviewerrecolha.actions.deleteAttachment.messageDelete';
        await this._cgModalService.showOkCancel(title, message);
        if (this.state === EArquivoDigitalDocViewerState.Editing) {
          await this.documentsService.moverParaDocsNaoClassificados([this.selectedAttachment.cab.docID]);
        } else {
          await this.documentsService.delete(this.selectedAttachment.cab.docID, this._selectedFolder.folderID);
        }
        this.evtDeletedAttachment.emit();
      }
      const attachment: IJsonDocOCR = this._getNextAttachment();
      this._selectedFolder.anexos.splice(selectedAttachmentIndex, 1);
      this.attachmentsTotal = this._selectedFolder.anexos.length;
      this._selectAttachment(attachment, properties);
    }
  }

  private _getSelectedAttachmentIndex(): number {
    if (!this.selectedAttachment) {
      return -1;
    }
    return this._selectedFolder.anexos.findIndex((attachment: IJsonDocOCR) => attachment.cab.docID === this.selectedAttachment.cab.docID);
  }

  private _getNextAttachment(): IJsonDocOCR {
    if (!this._selectedFolder || this._selectedFolder.anexos.length <= 1) {
      return undefined;
    }
    const selectedAttachmentIndex: number = this._getSelectedAttachmentIndex();
    let attachment: IJsonDocOCR = this._selectedFolder.anexos[selectedAttachmentIndex + 1];
    if (!attachment && this._selectedFolder.anexos.length) {
      attachment = this._selectedFolder.anexos[selectedAttachmentIndex];
      attachment = findLast(this._selectedFolder.anexos, (attachmentItem: IJsonDocOCR) => !attachment || attachmentItem.cab.docOCRCabID !== attachment.cab.docOCRCabID);
    }
    return attachment;
  }

  private async _help(): Promise<void> {
    if (this.readonly) {
      return Promise.resolve();
    }
    const topics: Array<ICGHelpTopic> = [CG_HELP_TOPIC_CONTAB_DIGITAL_FAQ, CG_HELP_TOPIC_OTHER];
    return this._cgModalService.showHelpTopics(topics, undefined, {size: 'md'});
  }

  private async _checkHasDigitalDoc(): Promise<void> {
    if (this.state !== EArquivoDigitalDocViewerState.Editing || !this.attachmentDocId) {
      return Promise.resolve();
    }
    const message: string = this._plTranslateService.translate('arquivodigital.docviewerrecolha.text.warningHasDigitalDoc', {
      periodo: this.doc.periodo,
      nDiario: this.doc.nDiario,
      nDocInterno: this.doc.nDocInterno
    });
    return this._cgModalService.showOkCancel('global.text.warning', message, {btnOkText: 'global.btn.yes', btnCancelText: 'global.btn.no'});
  }

  private async _checkFoldersCreated(): Promise<void> {
    const empresaTemPastasCriadas: boolean = await firstValueFrom(this._contabilidadeDigitalUIService.configs.empresaTemPastasCriadas().pipe(take(1)));
    if (empresaTemPastasCriadas !== true) {
      await this._contabilidadeDigitalUIService.checkFoldersCreated();
    }
  }

  private _cleanupToolbar(): void {
    if (this._toolbarInstance) {
      if (this._toolbarInstanceOwned) {
        this._plToolbarService.unRegisterInstance(this._toolbarInstance);
        this._toolbarInstanceOwned = false;
      } else {
        this._toolbarInstance.removeGroupId(TOOLBAR_GROUP_ID);
      }
      this._toolbarInstance = undefined;
    }
  }

  private _clear(): void {
    this.attachmentDocId = undefined;
  }
}
