import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IPlDynamicVisualsSecondaryClickAction, isObject, KEYCODES} from 'pl-comps-angular';
import {AtivosImpressaoDepModalComponent} from '../modal/depreciacoes/ativosImpressao.dep.modal.component';
import {AtivosImpressaoDocModalComponent} from '../modal/doccontabilidade/ativosImpressao.doc.modal.component';
import {AtivosImpressaoModalComponent} from '../modal/ativosImpressao.modal.component';
import {AtivosImpressaoService} from '../ativosImpressao.module.service';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {DevExpressDataGridUIService} from '../../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {EAtivosMapasOficiaisEstadoUso} from '../../ativosmapasoficiais/ativosMapasOficiais.module.interface';
import {ENTITY_NAME_ATIVOS} from '../../../../entities/ativos/interfaces/ativos.entity.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService} from '../../../portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IAtivosImpressaoHeader, IAtivosImpressaoLinha, RADIO_GROUP_ATIVOS_ESTADO_USO_TEMPLATE} from '../ativosImpressao.module.interface';
import {IContabilidadeExtratosGridParams, MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID} from '../../../portalcontabilidade/estatistica/extratosgrid/contabilidade.extratosGrid.module.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnContextMenuPreparing, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonAtivosImpressaoConsultDep, IJsonAtivosImpressaoHeader} from '../jsonAtivosImpressao.module.interface';
import {IJsonAtivos} from '../../../../entities/ativos/interfaces/jsonAtivos.entity.interface';
import {IJsonCCusto} from '../../../../entities/ccustos/jsonCCusto.entity.interface';
import {IJsonDocContabilidade} from '../../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {IJsonImtab} from '../../../../entities/amortizacoes/jsonImtab.entity.interface';
import {IJsonPOC} from '../../../../entities/pocs/jsonPOC.entity.interface';
import {IRadioGroup} from '../../../../../common/interfaces/interfaces';
import {maxDateCG, minDateCG} from '../../../../../common/utils/utils';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {ModuloComponent} from '../../../../components/module/module.component';

const BTN_ID_PESQUISAR = 'pesquisar';

@Component({
  selector: 'module-ativos-impressao',
  templateUrl: './ativosImpressao.module.component.html'
})
export class AtivosImpressaoModuleComponent extends ModuloComponent implements OnInit {
  public readonly ativosAte: Partial<IJsonAtivos>;
  public readonly contaDe: Partial<IJsonPOC>;
  public readonly contaAte: Partial<IJsonPOC>;
  public readonly cCustoAte: Partial<IJsonCCusto>;
  public readonly amortizacaoDe: Partial<IJsonImtab>;
  public readonly amortizacaoAte: Partial<IJsonImtab>;
  public readonly mapaamortizacaoOutput: string;
  public readonly amortizacaoOutput: string;
  public readonly ccustoOutput: string;
  public readonly ativoOutput: string;
  public readonly mapaamortizacaoDe: string;
  public readonly mapaamortizacaoAte: string;
  public readonly radioGroupTemplateEstadoUso: IRadioGroup<EAtivosMapasOficiaisEstadoUso>;
  public readonly dataGridDefenition: IDevExpressDataGrid<IAtivosImpressaoLinha, number>;
  public header: IAtivosImpressaoHeader;
  public ativosDepImpressao: Array<IAtivosImpressaoLinha>;
  public collapsedPesqAvancada: boolean;

  private readonly _maintenanceInstanceExtratosGrid: IModuleMaintenanceInstance;
  private readonly _maintenanceInstanceAtivos: IEntityMaintenanceInstance;
  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private _dataGridInstance: dxDataGrid<IAtivosImpressaoLinha, number>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosImpressaoService: AtivosImpressaoService,
    private readonly _cgModalService: CGModalService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService
  ) {
    super(_injector);
    this.dataGridDefenition = {
      columnHidingEnabled: false,
      columns: [
        {
          caption: 'ativosimpressao.table.ativo',
          alignment: 'center',
          columns: [
            {dataField: 'codativo', caption: 'ativosimpressao.table.sub.codativo'},
            {dataField: 'designacao', caption: 'ativosimpressao.table.sub.designacao', width: 150},
            {dataField: 'estado', caption: 'ativosimpressao.table.sub.estado'},
            {dataField: 'situacaoAtDataSTR', caption: 'ativosimpressao.table.sub.situacaoa', headerCellTemplate: 'headerTemplateSituacao'},
            {dataField: 'refExterna', caption: 'ativosimpressao.table.sub.refexterna'},
            {dataField: 'usado', caption: 'ativosimpressao.table.sub.usado', dataType: 'boolean'},
            {dataField: 'contabValorAquisicaoComResidual', caption: 'ativosimpressao.table.sub.valoraquisicaocontabilisticocomr', dataType: 'double', width: 150}
          ]
        },
        {
          caption: 'ativosimpressao.table.aquisicao',
          alignment: 'center',
          columns: [
            {dataField: 'dataaquisicao', caption: 'ativosimpressao.table.sub.dataaquisicao', dataType: 'date'},
            {dataField: 'nmapaamortizacao', caption: 'ativosimpressao.table.sub.mapa'},
            {dataField: 'codAmortizacao', caption: 'ativosimpressao.table.sub.coddecregulamentar', width: 120},
            {dataField: 'amortizacaoNome', caption: 'ativosimpressao.table.sub.nomeDecRegulamentar', width: 210},
            {dataField: 'amortizacaotaxa', caption: 'ativosimpressao.table.sub.taxaDoDecRegulamentar', dataType: 'double', width: 120},
            {dataField: 'codAtivoPaiParaGrid', caption: 'ativosimpressao.table.sub.codAtivoPai'},
            {dataField: 'nDocumentoContabilidade', caption: 'ativosimpressao.table.sub.doccontabilidade'}
          ]
        },
        {
          caption: 'ativosimpressao.table.classificadores',
          alignment: 'center',
          visible: false,
          columns: [
            {dataField: 'nRefProcesso', caption: 'ativosimpressao.table.sub.codProcesso', dataType: 'string', visible: false},
            {dataField: 'processoNome', caption: 'ativosimpressao.table.sub.nomeProcesso', dataType: 'string', visible: false},
            {dataField: 'nCCusto', caption: 'ativosimpressao.table.sub.codCCusto', dataType: 'string', visible: false},
            {dataField: 'cCustoNome', caption: 'ativosimpressao.table.sub.nomeCCusto', dataType: 'string', visible: false}
          ]
        },
        {
          caption: 'ativosimpressao.table.contas',
          alignment: 'center',
          columns: [
            {dataField: 'nContaDoAtivo', caption: 'ativosimpressao.table.sub.numContaAtivo'},
            {dataField: 'nContaAmortAcumu', caption: 'ativosimpressao.table.sub.numContaDepAcum'},
            {dataField: 'nContaAmortExerc', caption: 'ativosimpressao.table.sub.numContaDepExerc'}
          ]
        },
        {
          caption: 'ativosimpressao.table.dadoscontabilisticos',
          alignment: 'center',
          columns: [
            {dataField: 'contabValorAquisicao', caption: 'ativosimpressao.table.sub.valAquisicao', dataType: 'double'},
            {dataField: 'contabValorResidual', caption: 'ativosimpressao.table.sub.valResidual', dataType: 'double'},
            {dataField: 'contabTaxaAmortiza', caption: 'ativosimpressao.table.sub.taxaDepreciacaoAdoptada', dataType: 'double', width: 120},
            {dataField: 'contabPercReducao', caption: 'ativosimpressao.table.sub.reducao', dataType: 'double'},
            {dataField: 'depContabAcumAno', caption: 'ativosimpressao.table.sub.depNoAno', dataType: 'double'},
            {dataField: 'depContabAcumAnt', caption: 'ativosimpressao.table.sub.depAcumNoAnoAnterior', dataType: 'double', width: 120},
            {dataField: 'depContabAcumTot', caption: 'ativosimpressao.table.sub.depAcumTotal', dataType: 'double'},
            {dataField: 'contabBaseCalculoSTR', caption: 'ativosimpressao.table.sub.baseCalculo', dataType: 'string'},
            {dataField: 'contabMetodoCalculoSTR', caption: 'ativosimpressao.table.sub.metodoCalculo', dataType: 'string'}
          ]
        },
        {
          caption: 'ativosimpressao.table.dadosfiscais',
          alignment: 'center',
          columns: [
            {dataField: 'fiscalValorAquisicao', caption: 'ativosimpressao.table.sub.valAquisicao', dataType: 'double'},
            {dataField: 'fiscalValorResidual', caption: 'ativosimpressao.table.sub.valResidual', dataType: 'double'},
            {dataField: 'fiscalTaxaAmortiza', caption: 'ativosimpressao.table.sub.taxaDepreciacaoAdoptada', dataType: 'double', width: 120},
            {dataField: 'fiscalPercReducao', caption: 'ativosimpressao.table.sub.reducao', dataType: 'double'},
            {dataField: 'depFiscalAcumAno', caption: 'ativosimpressao.table.sub.depNoAno', dataType: 'double'},
            {dataField: 'depFiscalAcumAnt', caption: 'ativosimpressao.table.sub.depAcumNoAnoAnterior', dataType: 'double', width: 120},
            {dataField: 'depFiscalAcumTot', caption: 'ativosimpressao.table.sub.depAcumTotal', dataType: 'double'},
            {dataField: 'fiscalBaseCalculoSTR', caption: 'ativosimpressao.table.sub.baseCalculo', dataType: 'string'},
            {dataField: 'fiscalMetodoCalculoSTR', caption: 'ativosimpressao.table.sub.metodoCalculo', dataType: 'string'}
          ]
        },
        {
          caption: 'ativosimpressao.table.abatevenda',
          alignment: 'center',
          columns: [
            {dataField: 'dataSTRForGrid', caption: 'ativosimpressao.table.sub.data', dataType: 'string'},
            {dataField: 'valor', caption: 'ativosimpressao.table.sub.valor', dataType: 'double'},
            {dataField: 'coefCorrMonet', caption: 'ativosimpressao.table.sub.coefCorrecaoMonetaria', dataType: 'double', width: 120},
            {dataField: 'nContaPOC', caption: 'ativosimpressao.table.sub.contaVenda', dataType: 'string'},
            {dataField: 'contabMaisMenosValiaValorSinal', caption: 'ativosimpressao.table.sub.maisMenosValiaContabilistica', dataType: 'double'},
            {dataField: 'fiscalMaisMenosValiaValorSinal', caption: 'ativosimpressao.table.sub.maisMenosValiaFiscal', dataType: 'double'}
          ]
        },
        {
          caption: 'ativosimpressao.table.limiteminimofiscal',
          alignment: 'center',
          columns: [
            {dataField: 'depLimMinFisAcumAno', caption: 'ativosimpressao.table.sub.noAno', dataType: 'double'},
            {dataField: 'depLimMinFisAcumAnt', caption: 'ativosimpressao.table.sub.acumNoAnoAnterior', dataType: 'double'},
            {dataField: 'depLimMinFisAcumTot', caption: 'ativosimpressao.table.sub.acumTotal', dataType: 'double'}
          ]
        },
        {
          caption: 'ativosimpressao.table.perdasimparidadecontabilistica',
          alignment: 'center',
          columns: [
            {dataField: 'perImpContabAcumAno', caption: 'ativosimpressao.table.sub.noAno', dataType: 'double'},
            {dataField: 'perImpContabAcumAnt', caption: 'ativosimpressao.table.sub.acumNoAnoAnterior', dataType: 'double'},
            {dataField: 'perImpContabAcumTot', caption: 'ativosimpressao.table.sub.acumTotal', dataType: 'double'}
          ]
        },
        {
          caption: 'ativosimpressao.table.perdasimparidadefiscais',
          alignment: 'center',
          columns: [
            {dataField: 'perImpFiscalAcumAno', caption: 'ativosimpressao.table.sub.noAno', dataType: 'double'},
            {dataField: 'perImpFiscalAcumAnt', caption: 'ativosimpressao.table.sub.acumNoAnoAnterior', dataType: 'double'},
            {dataField: 'perImpFiscalAcumTot', caption: 'ativosimpressao.table.sub.acumTotal', dataType: 'double'}
          ]
        },
        {
          caption: 'ativosimpressao.table.valoresperdidosfiscais',
          alignment: 'center',
          columns: [
            {dataField: 'depPerdFisAcumAno', caption: 'ativosimpressao.table.sub.noAno', dataType: 'double'},
            {dataField: 'depPerdFisAcumAnt', caption: 'ativosimpressao.table.sub.acumNoAnoAnterior', dataType: 'double'},
            {dataField: 'depPerdFisAcumTot', caption: 'ativosimpressao.table.sub.acumTotal', dataType: 'double'}
          ]
        },
        {
          caption: 'ativosimpressao.table.taxasperdidasfiscais',
          alignment: 'center',
          columns: [
            {dataField: 'taxPerdFisAcumAno', caption: 'ativosimpressao.table.sub.noAno', dataType: 'double'},
            {dataField: 'taxPerdFisAcumAnt', caption: 'ativosimpressao.table.sub.acumNoAnoAnterior', dataType: 'double'},
            {dataField: 'taxPerdFisAcumTot', caption: 'ativosimpressao.table.sub.acumTotal', dataType: 'double'}
          ]
        },
        {
          caption: 'ativosimpressao.table.depreciacoesnaoaceites',
          alignment: 'center',
          columns: [
            {dataField: 'depNaoAceiteAcumAno', caption: 'ativosimpressao.table.sub.noAno', dataType: 'double'},
            {dataField: 'depNaoAceiteAcumAnt', caption: 'ativosimpressao.table.sub.acumNoAnoAnterior', dataType: 'double'},
            {dataField: 'depNaoAceiteAcumTot', caption: 'ativosimpressao.table.sub.acumTotal', dataType: 'double'}
          ]
        },
        {
          caption: 'ativosimpressao.table.imparidadesnaoaceites',
          alignment: 'center',
          columns: [
            {dataField: 'impNaoAceiteAcumAno', caption: 'ativosimpressao.table.sub.noAno', dataType: 'double'},
            {dataField: 'impNaoAceiteAcumAnt', caption: 'ativosimpressao.table.sub.acumNoAnoAnterior', dataType: 'double'},
            {dataField: 'impNaoAceiteAcumTot', caption: 'ativosimpressao.table.sub.acumTotal', dataType: 'double'}
          ]
        },
        {
          caption: 'ativosimpressao.table.depreciacoesarecuperar',
          alignment: 'center',
          columns: [
            {dataField: 'depARecuperarAcuAno', caption: 'ativosimpressao.table.sub.noAno', dataType: 'double'},
            {dataField: 'depARecuperarAcuAnt', caption: 'ativosimpressao.table.sub.acumNoAnoAnterior', dataType: 'double'},
            {dataField: 'depARecuperarAcuTot', caption: 'ativosimpressao.table.sub.acumTotal', dataType: 'double'}
          ]
        },
        {
          caption: 'ativosimpressao.table.depreciacoesrecuperadas',
          alignment: 'center',
          columns: [
            {dataField: 'depRecuperadAcumAno', caption: 'ativosimpressao.table.sub.noAno', dataType: 'double'},
            {dataField: 'depRecuperadAcumAnt', caption: 'ativosimpressao.table.sub.acumNoAnoAnterior', dataType: 'double'},
            {dataField: 'depRecuperadAcumTot', caption: 'ativosimpressao.table.sub.acumTotal', dataType: 'double'}
          ]
        },
        {
          caption: 'ativosimpressao.table.imparidadesrecuperadasnoperiodo',
          alignment: 'center',
          columns: [
            {dataField: 'impRecuperadAcumAno', caption: 'ativosimpressao.table.sub.noAno', dataType: 'double'},
            {dataField: 'impRecuperadAcumAnt', caption: 'ativosimpressao.table.sub.acumNoAnoAnterior', dataType: 'double'},
            {dataField: 'impRecuperadAcumTot', caption: 'ativosimpressao.table.sub.acumTotal', dataType: 'double'}
          ]
        },
        {
          caption: 'ativosimpressao.table.acrescemodelo22',
          alignment: 'center',
          columns: [
            {dataField: 'acresceM22AcumAno', caption: 'ativosimpressao.table.sub.noAno', dataType: 'double'},
            {dataField: 'acresceM22AcumAnt', caption: 'ativosimpressao.table.sub.acumNoAnoAnterior', dataType: 'double'},
            {dataField: 'acresceM22AcumTot', caption: 'ativosimpressao.table.sub.acumTotal', dataType: 'double'}
          ]
        },
        {
          caption: 'ativosimpressao.table.deduzmodelo22',
          alignment: 'center',
          columns: [
            {dataField: 'deduzM22AcumAno', caption: 'ativosimpressao.table.sub.noAno', dataType: 'double'},
            {dataField: 'deduzM22AcumAnt', caption: 'ativosimpressao.table.sub.acumNoAnoAnterior', dataType: 'double'},
            {dataField: 'deduzM22AcumTot', caption: 'ativosimpressao.table.sub.acumTotal', dataType: 'double'}
          ]
        },
        {
          caption: 'ativosimpressao.table.valortributavelativosporimpos',
          alignment: 'center',
          columns: [
            {dataField: 'vTAtivoImpDifAcuAno', caption: 'ativosimpressao.table.sub.noAno', dataType: 'double'},
            {dataField: 'vTAtivoImpDifAcuAnt', caption: 'ativosimpressao.table.sub.acumNoAnoAnterior', dataType: 'double'},
            {dataField: 'vTAtivoImpDifAcuTot', caption: 'ativosimpressao.table.sub.acumTotal', dataType: 'double'}
          ]
        },
        {
          caption: 'ativosimpressao.table.valortributaveldopassivoporimpos',
          alignment: 'center',
          columns: [
            {dataField: 'vTPassiImpDifAcuAno', caption: 'ativosimpressao.table.sub.noAno', dataType: 'double'},
            {dataField: 'vTPassiImpDifAcuAnt', caption: 'ativosimpressao.table.sub.acumNoAnoAnterior', dataType: 'double'},
            {dataField: 'vTPassiImpDifAcuTot', caption: 'ativosimpressao.table.sub.acumTotal', dataType: 'double'}
          ]
        },
        {
          caption: 'ativosimpressao.table.valornaoaceitefiscalmenteporreavaliacoes',
          alignment: 'center',
          columns: [
            {dataField: 'reavNaoAceiteAcumAno', caption: 'ativosimpressao.table.sub.noAno', dataType: 'double'},
            {dataField: 'reavNaoAceiteAcumAnt', caption: 'ativosimpressao.table.sub.acumNoAnoAnterior', dataType: 'double'},
            {dataField: 'reavNaoAceiteAcumTot', caption: 'ativosimpressao.table.sub.acumTotal', dataType: 'double'}
          ]
        },
        {
          caption: 'ativosimpressao.table.impostosdiferidos',
          alignment: 'center',
          columns: [
            {dataField: 'impostosDifTaxaIRC', caption: 'ativosimpressao.table.sub.taxaIRC', dataType: 'double'},
            {dataField: 'impostosDifAcumAno', caption: 'ativosimpressao.table.sub.noAno', dataType: 'double'},
            {dataField: 'impostosDifAcumAnt', caption: 'ativosimpressao.table.sub.noAnoAnterior', dataType: 'double'},
            {dataField: 'impostosDifAcumTot', caption: 'ativosimpressao.table.sub.total', dataType: 'double'}
          ]
        },
        {
          caption: 'ativosimpressao.table.rendimentosdossubsidios',
          alignment: 'center',
          columns: [
            {dataField: 'rendimentosAcumAno', caption: 'ativosimpressao.table.sub.noAno', dataType: 'double'},
            {dataField: 'rendimentosAcumAnt', caption: 'ativosimpressao.table.sub.acumNoAnoAnterior', dataType: 'double'},
            {dataField: 'rendimentosAcumTot', caption: 'ativosimpressao.table.sub.acumTotal', dataType: 'double'}
          ]
        },
        {
          caption: 'ativosimpressao.table.impostosdifSubsidios',
          alignment: 'center',
          columns: [
            {dataField: 'impostosDifSubsidiosAcumAno', caption: 'ativosimpressao.table.sub.noAno', dataType: 'double'},
            {dataField: 'impostosDifSubsidiosAcumAnt', caption: 'ativosimpressao.table.sub.acumNoAnoAnterior', dataType: 'double'},
            {dataField: 'impostosDifSubsidiosAcumTot', caption: 'ativosimpressao.table.sub.acumTotal', dataType: 'double'}
          ]
        }
      ],
      height: '60vh',
      pager: {visible: false},
      paging: {enabled: false, pageSize: 100},
      keyExpr: 'codativo',
      scrolling: {columnRenderingMode: 'virtual', rowRenderingMode: 'virtual'},
      summary: {
        totalItems: [
          {column: 'contabValorAquisicao', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'contabValorResidual', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depContabAcumAno', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depContabAcumAnt', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depContabAcumTot', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'fiscalValorAquisicao', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'fiscalValorResidual', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depFiscalAcumAno', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depFiscalAcumAnt', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depFiscalAcumTot', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depLimMinFisAcumAno', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depLimMinFisAcumAnt', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depLimMinFisAcumTot', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'perImpContabAcumAno', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'perImpContabAcumAnt', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'perImpContabAcumTot', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'perImpFiscalAcumAno', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'perImpFiscalAcumAnt', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'perImpFiscalAcumTot', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depPerdFisAcumAno', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depPerdFisAcumAnt', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depPerdFisAcumTot', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'taxPerdFisAcumAno', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'taxPerdFisAcumAnt', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'taxPerdFisAcumTot', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depNaoAceiteAcumAno', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depNaoAceiteAcumAnt', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depNaoAceiteAcumTot', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'impNaoAceiteAcumAno', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'impNaoAceiteAcumAnt', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'impNaoAceiteAcumTot', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depARecuperarAcuAno', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depARecuperarAcuAnt', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depARecuperarAcuTot', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depRecuperadAcumAno', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depRecuperadAcumAnt', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'depRecuperadAcumTot', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'impRecuperadAcumAno', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'impRecuperadAcumAnt', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'impRecuperadAcumTot', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'acresceM22AcumAno', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'acresceM22AcumAnt', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'acresceM22AcumTot', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'deduzM22AcumAno', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'deduzM22AcumAnt', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'deduzM22AcumTot', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'vTAtivoImpDifAcuAno', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'vTAtivoImpDifAcuAnt', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'vTAtivoImpDifAcuTot', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'vTPassiImpDifAcuAno', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'vTPassiImpDifAcuAnt', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'vTPassiImpDifAcuTot', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'reavNaoAceiteAcumAno', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'reavNaoAceiteAcumAnt', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'reavNaoAceiteAcumTot', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'rendimentosAcumAno', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'rendimentosAcumAnt', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'rendimentosAcumTot', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'impostosDifSubsidiosAcumAno', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'impostosDifSubsidiosAcumAnt', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'impostosDifSubsidiosAcumTot', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'}
        ]
      }
    };
    this.ativosDepImpressao = [];
    this.ativosAte = {codAtivo: 2147483647, designacao: 'Todos'};
    this.ativoOutput = '{{codAtivo}} - {{designacao}}';
    this.contaDe = {nConta: '1', nome: 'MEIOS FINANCEIROS LÍQUIDOS'};
    this.contaAte = {nConta: '9999999999', nome: 'Todos'};
    this.cCustoAte = {nCCusto: '9999999999', nome: 'Todos'};
    this.ccustoOutput = '{{nCCusto}} - {{nome}}';
    this.mapaamortizacaoDe = '32';
    this.mapaamortizacaoAte = '33.9';
    this.mapaamortizacaoOutput = '{{nImmap}} - {{nome}}';
    this.amortizacaoAte = {nImtab: '9999', nome: 'Todos'};
    this.amortizacaoOutput = '{{nImtab}} - {{nome}}';
    this.collapsedPesqAvancada = true;
    this.radioGroupTemplateEstadoUso = RADIO_GROUP_ATIVOS_ESTADO_USO_TEMPLATE;
    this._maintenanceInstanceExtratosGrid = this._moduleMaintenanceService.build(MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID);
    this._maintenanceInstanceAtivos = this._entityMaintenanceService.build(ENTITY_NAME_ATIVOS);
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: BTN_ID_PESQUISAR,
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-table fa-fw"></i>',
      class: 'btn-success',
      caption: 'ativosimpressao.btn.pesquisar',
      click: () => this._pesquisar()
    });
    this.toolbar.addButton({
      id: 'previsualizar',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-search fa-fw"></i>',
      class: 'btn-success',
      caption: 'ativosimpressao.btn.previsualizar',
      click: () => {
        this._previsualizarModal();
      }
    });
    if (!this.header) {
      this.header = {
        abatido: true,
        codAmortAte: '9999',
        codAmortDe: '0000',
        codAtivoAte: 2147483647,
        codAtivoDe: 0,
        codMapaAte: '33.9',
        codMapaDe: '32',
        dataAbateAte: maxDateCG(),
        dataAbateDe: minDateCG(),
        dataAquisicaoAte: maxDateCG(),
        dataAquisicaoDe: minDateCG(),
        nCCustoAte: '9999999999',
        nCCustoDe: '',
        nContaAte: '9999999999',
        nContaDe: '1',
        normal: true,
        vendido: true,
        dividido: false,
        reportName: 'Ativos01.FR3',
        tipoExportacao: 0,
        dataReferencia: maxDateCG(),
        estadoUso: EAtivosMapasOficiaisEstadoUso.Indiferente
      };
    }
    this._getDefaultHeader();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IAtivosImpressaoLinha, number>): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public readonly fnKeydownPesquisar = (value: string, event: KeyboardEvent): void => {
    this._keydownPesquisar(event);
  };

  public onDataGridContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IAtivosImpressaoLinha>): void {
    if (event.target === 'content' && event.row?.rowType === 'data' && isObject(event.row.data)) {
      event.event.preventDefault();
      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActions(event.row.data);
      this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions);
    }
  }

  private _generateSecondaryClickActions(line: IAtivosImpressaoLinha): Array<IPlDynamicVisualsSecondaryClickAction> {
    return [
      {
        caption: 'ativosimpressao.btn.dropdown.depreciacoes',
        click: () => {
          this._consultardepreciacoes(line.codativo);
        }
      },
      {
        caption: 'ativosimpressao.btn.dropdown.fichaativo',
        click: () => this._maintenanceInstanceAtivos.maintenanceEdit(line.codativo)
      },
      {
        caption: 'ativosimpressao.btn.dropdown.doccontab',
        click: () => this._docContabilidade(line)
      },
      {
        caption: 'ativosimpressao.btn.dropdown.extaquisicao',
        click: () => this._maintenanceExtratosGrid(line.nContaDoAtivo)
      },
      {
        caption: 'ativosimpressao.btn.dropdown.extexercicio',
        click: () => this._maintenanceExtratosGrid(line.nContaAmortExerc)
      },
      {
        caption: 'ativosimpressao.btn.dropdown.extacumulada',
        click: () => this._maintenanceExtratosGrid(line.nContaAmortAcumu)
      }
    ];
  }

  private _pesquisar(): Promise<void> {
    this._dataGridInstance.beginCustomLoading(undefined);
    return this._ativosImpressaoService
      .ativosImpressaoGet(this.header)
      .then((response: HttpResponse<Array<IAtivosImpressaoLinha>>) => {
        this.ativosDepImpressao = response.body;
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
  }

  private _previsualizarModal(): void {
    const modalInstance = this._cgModalService.showVanilla(AtivosImpressaoModalComponent);
    const componentInstance: AtivosImpressaoModalComponent = modalInstance.componentInstance;
    componentInstance.modelHeader = this.header;
  }

  private _getDefaultHeader(): Promise<void> {
    return this._ativosImpressaoService.getDefaultDataHeader().then((response: HttpResponse<IJsonAtivosImpressaoHeader>) => {
      this.header = {...this.header, ...response.body};
      if (this._dataGridInstance) {
        this._dataGridInstance.repaint();
      }
      return Promise.resolve();
    });
  }

  private _maintenanceExtratosGrid(nconta: string): Promise<void> {
    let params: IContabilidadeExtratosGridParams;
    if (nconta.length) {
      params = {nConta: nconta};
    }
    return this._maintenanceInstanceExtratosGrid.maintenance({params: params});
  }

  private _consultardepreciacoes(codativo: number): void {
    this._ativosImpressaoService.getConsultarDepreciacoes(codativo).then((response: HttpResponse<IJsonAtivosImpressaoConsultDep>) => {
      const modalInstance = this._cgModalService.showVanilla(AtivosImpressaoDepModalComponent);
      const componentInstance: AtivosImpressaoDepModalComponent = modalInstance.componentInstance;
      componentInstance.ativosImpressaoConsultDep = response.body;
    });
  }

  private async _docContabilidade(line: IAtivosImpressaoLinha): Promise<void> {
    if (!line._docContabilidade) {
      await this._serviceDocsContabilidade.get({id: line.extPocCabId}).then((response: HttpResponse<IJsonDocContabilidade>) => {
        line._docContabilidade = response.body;
      });
    }
    const modalInstance = this._cgModalService.showVanilla(AtivosImpressaoDocModalComponent, {backdrop: 'static'});
    const componentInstance: AtivosImpressaoDocModalComponent = modalInstance.componentInstance;
    componentInstance.doc = line._docContabilidade;
    const needReload: boolean = await modalInstance.result;
    if (needReload) {
      await this._pesquisar();
    }
  }

  private _keydownPesquisar(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.preventDefault();
      event.stopPropagation();
      this.toolbar.focusItem(BTN_ID_PESQUISAR);
    }
  }
}
