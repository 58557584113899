import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {AtivosMudarContaService} from '../../services/ativosMudarConta.service';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IJsonAtivosDivisao} from './jsonAtivosDivisao.interface';
import {IJsonAtivos} from '../../interfaces/jsonAtivos.entity.interface';
import moment from 'moment';
import {round} from '../../../../../common/utils/utils';

@Component({
  selector: 'modal-ativos-divisao',
  templateUrl: './ativosDivisao.modal.component.html'
})
export class AtivosDivisaoModalComponent extends CGModalComponent<IJsonAtivos> implements OnInit {
  @Input() public ativo: IJsonAtivos;

  public readonly info: string;
  public ativosDivisao: IJsonAtivosDivisao;
  public valorLiquidoContab: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosMudarContaService: AtivosMudarContaService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.info = 'ativosdivisao.message.info';
  }

  public ngOnInit(): void {
    this.ativosDivisao = {
      dataDivisao: moment().startOf('month').format('YYYY-MM-DD'),
      designacao: this.ativo.designacao,
      unidadesQtd: 0,
      valorAquisicao: 0,
      ativo: this.ativo
    };
    this.valorLiquidoContab = Math.max(0, this.ativo.valorAquisicaoContab - this.ativo.valorDepreciacaoContab);
  }

  public unidadesQtdChanged(value: number): void {
    this.ativosDivisao.unidadesQtd = value;
    this.ativosDivisao.valorAquisicao = this.ativo.aquisicao.quantidade > 0 ? round((this.ativosDivisao.unidadesQtd * this.ativo.valorAquisicaoContab) / this.ativo.aquisicao.quantidade) : 0;
  }

  public valorAquisicaoChanged(value: number): void {
    this.ativosDivisao.valorAquisicao = value;
    this.ativosDivisao.unidadesQtd = this.ativo.valorAquisicaoContab > 0 ? round((this.ativosDivisao.valorAquisicao * this.ativo.aquisicao.quantidade) / this.ativo.valorAquisicaoContab) : 0;
  }

  public close(): Promise<void> {
    this.disableClose();
    return this._cgModalService
      .showOkCancel('ativosdivisao.message.confirmacao', 'ativosdivisao.message.temcerteza')
      .then(() => {
        return this._ativosMudarContaService
          .postDivisaoDoAtivo(this.ativosDivisao)
          .then((response: HttpResponse<IJsonAtivos>) => {
            this.enableClose();
            this._plAlertService.success('ativosdivisao.message.success');
            super.close(response.body);
          })
          .catch((reason: unknown) => {
            this.enableClose();
            this._logger.error(reason);
          });
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }
}
