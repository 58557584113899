import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {ModuloComponent} from '../../../../components/module/module.component';
import {IAnosCalculo} from '../../../ativos/ativosdepreciacao/ativosDepreciacao.module.interface';
import {EDelphiNumberTypes, isDefinedNotNull} from 'pl-comps-angular';
import {IJsonErpUser} from '../../../../services/account/jsonUserApi.interface';
import {IJsonReport} from '../../../../entities/reports/jsonReport.interface';
import {ReportsRegistryService} from '../../../../components/reports/reports.registry.service';
import moment from 'moment';
import {MAX_STRING} from '../../../listagens/docscomerciasestatisticas/docsComerciaisEstatisticas.module.interface';
import {EReport} from '../../../../entities/reports/reports.interface';
import {ERadioFiltrarPesquisa, IFiltersImpde119, IFiltrarPesquisa} from '../impDe119.module.interface';
import {Impde119Service} from '../impDe119.module.service';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';
import {EPortal} from '../../../../../common/enums/portals.enums';
import {ROLE} from '../../../../services/role.const';
import {hasAuthority} from '../../../../../common/utils/roles.utils';

@Component({
  selector: 'module-impde119',
  templateUrl: './impDe119.module.component.html'
})
export class ImpDe119ModuleComponent extends ModuloComponent implements OnInit {
  public readonly metodoSource: Array<IAnosCalculo>;
  public readonly empresasTemplate: string;
  public readonly eTiposDeclaracao: typeof ERadioFiltrarPesquisa;
  public filtrarPesquisa: Array<IFiltrarPesquisa>;
  public filters: IFiltersImpde119;
  public empresas: Array<IJsonErpUser>;
  public empresasSource: Array<IJsonErpUser>;
  public report: IJsonReport;
  public pdfUrl: string;
  public collapsedPesqAvancada: boolean;
  public showPdfViewer: boolean;

  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _impDe119Service: Impde119Service,
    private readonly _reportsRegistryService: ReportsRegistryService
  ) {
    super(_injector);
    this.eTiposDeclaracao = ERadioFiltrarPesquisa;
    this.empresasTemplate = '{{nEmpresa}} - {{nomeEmpresa}}';
    this.empresas = [];
    this.collapsedPesqAvancada = true;

    this.filtrarPesquisa = [
      {value: ERadioFiltrarPesquisa.Empregado, label: 'impde119.filters.empregado', disabled: false, validRoles: [ROLE.RH]},
      {value: ERadioFiltrarPesquisa.Outros, label: 'impde119.filters.outros', disabled: false, validRoles: [ROLE.CONTABILIDADE]},
      {value: ERadioFiltrarPesquisa.Ambos, label: 'impde119.filters.ambos', disabled: false, validRoles: [ROLE.RH, ROLE.CONTABILIDADE]}
    ];
    this.filters = {
      filtrarpesquisa: this.currentPortal.portal === EPortal.RH ? ERadioFiltrarPesquisa.Empregado : ERadioFiltrarPesquisa.Outros,
      dedata: moment(this._configService.configurations.empresa.anoEmCursoIRC, 'y').startOf('year'),
      atedata: moment(this._configService.configurations.empresa.anoEmCursoIRC, 'y').endOf('year'),
      decodemp: 0,
      atecodemp: EDelphiNumberTypes.MaxSmallInt,
      dencontribuinte: '',
      atencontribuinte: MAX_STRING,
      listaempresas: '',
      reportname: ''
    };
    this.showPdfViewer = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._evaluateFiltrarPesquisa();
    this.toolbar.addButton({
      id: 'previsualizar',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-search fa-fw"></i>',
      class: 'btn-success',
      caption: 'rhimpvenct.btn.previsualizar',
      click: () => {
        this._previsualizar();
      }
    });
    this._loadEmpresas();
    this._loadDefaultReport();
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _previsualizar(): void {
    this.filters.listaempresas = !this.empresas.length ? this.session.erp.nEmpresa : this.empresas.map((value: IJsonErpUser) => value.nEmpresa).join('-');
    this.filters.reportname = this.report.name;
    this._impDe119Service.getListagemIMPDE119RUrl(this.filters).subscribe((url: string) => {
      this.pdfUrl = url;
      this._cardPanel.collapse();
      this.showPdfViewer = true;
    });
  }

  private _loadEmpresas(): boolean {
    const empresassel: Array<IJsonErpUser> = [...this.session.erps];
    let idx = -1;
    const foundItem: IJsonErpUser = empresassel.find((item: IJsonErpUser, index: number) => {
      if (item.nEmpresa === this.session.erp.nEmpresa) {
        idx = index;
        return true;
      }
      return false;
    });
    if (idx > -1 && isDefinedNotNull(foundItem)) {
      empresassel.splice(idx, 1);
      this.empresas = [foundItem];
    }
    this.empresasSource = empresassel;
    return true;
  }

  private _loadDefaultReport(): void {
    this._reportsRegistryService
      .get(EReport.ImpDe119)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          this.report = reports[0];
        }
      });
  }

  private _evaluateFiltrarPesquisa(): void {
    for (const item of this.filtrarPesquisa) {
      for (const role of item.validRoles) {
        if (!hasAuthority(this.session, role)) {
          item.disabled = true;
          break;
        }
      }
    }
  }
}
