import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {DebDiretosSEPAModuleService} from '../debDiretosSEPA.module.service';
import moment, {Moment} from 'moment';
import {
  IDebDiretosSEPAEmissaoModel,
  IJsonDebDiretosSEPAEmissaoListaAIntervirItem,
  IJsonDebDiretosSEPAEmissaoListaFicheirosGeradosItem,
  IJsonDebDiretosSEPAEmissaoResumoProcessaItem,
  IJsonDebDiretosSEPAErrorsAndWarningItem
} from '../debDiretosSEPA.module.interface';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import dxDataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import {TDate} from '../../../../common/dates';
import {downloadStream, IPlNavWizardCallback, IPlNavWizardEventBeforeChange, IPlNavWizardEventStep, IPlNavWizardEventValidator, isDefinedNotNull, PlAlertService} from 'pl-comps-angular';
import {ICGExceptionError} from '../../../components/exceptions/exceptions.service.interface';
import {CGExceptionService} from '../../../components/exceptions/exceptions.service';
import {TranslateService} from '@ngx-translate/core';
import {HttpResponse} from '@angular/common/http';

const WIZARD_STEP_FICHEIROS_GERADOS = 'resumoFicheirosGerados';

@Component({
  selector: 'ddsepa-emissao-modal',
  templateUrl: './debDiretosSEPA.emissao.modal.component.html'
})
export class DebDiretosSEPAEmissaoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public listaAIntervir: Array<IJsonDebDiretosSEPAEmissaoListaAIntervirItem>;

  public readonly resumoFicheirosGeradosTab: string = 'resumoFicheirosGeradosTab';
  public readonly resumoFicheirosGeradosErrosTab: string = 'resumoFicheirosGeradosErrosTab';
  public readonly wizGeraFicheiroDDStepId: string = 'geraFicheiroDD';
  public readonly wizResumoFicheirosGeradosStepId: string = 'resumoFicheirosGerados';

  public model: IDebDiretosSEPAEmissaoModel;
  public dataGridListaAIntervirDefinition: IDevExpressDataGrid<IJsonDebDiretosSEPAEmissaoListaAIntervirItem, number>;
  public dataGridResumoProcessaDefinition: IDevExpressDataGrid<IJsonDebDiretosSEPAEmissaoResumoProcessaItem, string>;
  public dataGridFicheirosGeradosDefinition: IDevExpressDataGrid<IJsonDebDiretosSEPAEmissaoListaFicheirosGeradosItem>;
  public dataGridErrosEAvisosDefinition: IDevExpressDataGrid<IJsonDebDiretosSEPAErrorsAndWarningItem>;
  public dataGridNotificaoDefinition: IDevExpressDataGrid<IJsonDebDiretosSEPAEmissaoResumoProcessaItem>;
  public plWizardCallback: IPlNavWizardCallback;
  public resumoFicheirosGeradosActiveTabId: string;
  public listaErrosEAvisos: Array<IJsonDebDiretosSEPAErrorsAndWarningItem>;

  private _resumoProcessa: Array<IJsonDebDiretosSEPAEmissaoResumoProcessaItem>;
  private _listaGerados: Array<IJsonDebDiretosSEPAEmissaoListaFicheirosGeradosItem>;
  private _dataGridListaAIntervirInstance: dxDataGrid<IJsonDebDiretosSEPAEmissaoListaAIntervirItem, number>;
  private _dataGridResumoProcessaInstance: dxDataGrid<IJsonDebDiretosSEPAEmissaoResumoProcessaItem, string>;
  private _dataGridNotificaoInstance: dxDataGrid<IJsonDebDiretosSEPAEmissaoResumoProcessaItem>;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _debDiretosSEPAModuleService: DebDiretosSEPAModuleService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this.resumoFicheirosGeradosActiveTabId = this.resumoFicheirosGeradosTab;
    this.model = {
      vencimentoAte: moment().endOf('month'),
      dataLiquidacaoFRST: moment(),
      dataLiquidacaoRCUR: moment()
    };
    this.listaAIntervir = [];
    this._resumoProcessa = [];
    this._listaGerados = [];
    this.listaErrosEAvisos = [];
    this.plWizardCallback = {};
    this.dataGridListaAIntervirDefinition = {
      columns: [
        {dataField: 'nDocFa', dataType: 'number', caption: 'debDiretosSEPA.fields.nDocFa', visible: false},
        {dataField: 'nNumer', dataType: 'number', caption: 'debDiretosSEPA.fields.nNumer', visible: false},
        {dataField: 'nDocumento', dataType: 'number', caption: 'debDiretosSEPA.fields.nDocumento', visible: false},
        {dataField: 'nDocAsStringSAFT', dataType: 'string', caption: 'debDiretosSEPA.fields.documento'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'debDiretosSEPA.fields.dataDoc'},
        {dataField: 'dataVencimento', dataType: 'date', caption: 'debDiretosSEPA.strings.dataVencimento'},
        {dataField: 'nConta', dataType: 'string', caption: 'debDiretosSEPA.fields.nConta'},
        {dataField: 'nContaNome', dataType: 'string', caption: 'debDiretosSEPA.fields.nome'},
        {dataField: 'valorEmAberto', dataType: 'double', caption: 'debDiretosSEPA.fields.valorEmAberto'},
        {dataField: 'enviadoEmail', dataType: 'boolean', caption: 'debDiretosSEPA.fields.enviadoEmail', visible: false},
        {dataField: 'xmlGerado', dataType: 'boolean', caption: 'debDiretosSEPA.fields.xmlGerado', visible: false},
        {dataField: 'nomeMeioPagamento', dataType: 'string', caption: 'debDiretosSEPA.fields.meioPagamento', visible: false},
        {dataField: 'ibanMasked', dataType: 'string', caption: 'debDiretosSEPA.fields.iban', visible: false},
        {dataField: 'nContaBancoMasked', dataType: 'string', caption: 'debDiretosSEPA.tipoChaveAutorizacao.ibanCliente', visible: false},
        {dataField: 'nomeTitularIBAN', dataType: 'string', caption: 'debDiretosSEPA.fields.titularIBAN', visible: false},
        {dataField: 'tipoFicheiro', dataType: 'string', caption: 'debDiretosSEPA.fields.tipoFicheiro', visible: false},
        {dataField: 'erro', dataType: 'boolean', caption: 'debDiretosSEPA.fields.erro', visible: false},
        {dataField: 'aviso', dataType: 'boolean', caption: 'debDiretosSEPA.fields.aviso', visible: false}
      ],
      dataSource: new CustomStore({
        key: 'faccbId',
        load: () => this._getListaAIntervirTableSource()
      }),
      selection: {
        mode: 'multiple',
        selectAllMode: 'allPages',
        showCheckBoxesMode: 'always'
      },
      summary: {
        totalItems: [{column: 'valorEmAberto', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'}]
      }
    };
    this.dataGridResumoProcessaDefinition = {
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'debDiretosSEPA.fields.nConta'},
        {dataField: 'nome', dataType: 'string', caption: 'debDiretosSEPA.fields.nome'},
        {
          dataField: 'valor',
          dataType: 'double',
          caption: 'debDiretosSEPA.fields.valor',
          fixed: true,
          fixedPosition: 'right'
        }
      ],
      summary: {
        totalItems: [{column: 'valor', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'}]
      },
      dataSource: new CustomStore({
        key: 'nConta',
        load: () => this._getResumoProcessaTableSource()
      })
    };

    this.dataGridFicheirosGeradosDefinition = {
      columns: [
        {dataField: 'nLoteFicheiro', dataType: 'string', caption: 'debDiretosSEPA.fields.nLoteFicheiro'},
        {dataField: 'nLotePagamento', dataType: 'string', caption: 'debDiretosSEPA.fields.nLotePagamento'},
        {dataField: 'firstAsSTR', dataType: 'string', caption: 'debDiretosSEPA.fields.firstAsSTR'},
        {dataField: 'nRegistos', dataType: 'number', caption: 'debDiretosSEPA.fields.nRegistos'},
        {dataField: 'valor', dataType: 'double', caption: 'debDiretosSEPA.fields.valor'},
        {dataField: 'dataLiquidacao', dataType: 'date', caption: 'debDiretosSEPA.strings.dataLiquidacao'},
        {
          dataField: 'vf_actions',
          width: 50,
          fixed: true,
          fixedPosition: 'right',
          caption: ' ',
          cellTemplate: 'cellTemplateActions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false
        }
      ],
      summary: {
        totalItems: [{column: 'valor', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'}]
      },
      dataSource: new CustomStore({
        key: 'nConta',
        load: () => this._getFicheirosGeradosTableSource()
      })
    };

    this.dataGridErrosEAvisosDefinition = {
      columns: [
        {dataField: 'tipoMensagemStr', dataType: 'string', caption: 'debDiretosSEPA.fields.tipo'},
        {dataField: 'nomeCampo', dataType: 'string', caption: 'debDiretosSEPA.fields.campo'},
        {dataField: 'mensagem', dataType: 'string', caption: 'debDiretosSEPA.fields.mensagem'}
      ],
      dataSource: new CustomStore({
        load: () => this._getErrosEAvisosTableSource()
      })
    };

    this.dataGridNotificaoDefinition = {
      selection: {
        mode: 'multiple',
        selectAllMode: 'allPages',
        showCheckBoxesMode: 'always'
      },
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'debDiretosSEPA.fields.nConta'},
        {dataField: 'nome', dataType: 'string', caption: 'debDiretosSEPA.fields.nome'},
        {dataField: 'emailDD', dataType: 'string', caption: 'debDiretosSEPA.fields.emailDD'},
        {
          dataField: 'valor',
          dataType: 'double',
          caption: 'debDiretosSEPA.fields.valor'
        }
      ],
      dataSource: new CustomStore({
        key: 'nConta',
        load: () => this._getNotificaoTableSource()
      })
    };
  }

  public readonly fnDownloadFicheiro = (nLoteFicheiro: string) => (): Promise<unknown> => this._downloadFicheiro(nLoteFicheiro);
  public readonly fnBeforeStepChange = (event: IPlNavWizardEventBeforeChange): Promise<boolean> => this._beforeStepChange(event);

  public ngOnInit(): void {
    this._debDiretosSEPAModuleService.getDebDiretosSEPAEmissaoPreload().then((response) => {
      this.model.dataLiquidacaoFRST = response.body.dataLiquidacaoFRST;
      this.model.dataLiquidacaoRCUR = response.body.dataLiquidacaoRCUR;
    });
  }

  public onDataGridListaAIntervirDefinitionInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonDebDiretosSEPAEmissaoListaAIntervirItem, number>): void {
    this._dataGridListaAIntervirInstance = component;
  }

  public onDataGridListaAIntervirContentReady(): void {
    this._dataGridListaAIntervirInstance.endCustomLoading();
  }

  public onDataGridNotificaoContentReady(): void {
    this._dataGridNotificaoInstance.endCustomLoading();
    this._dataGridNotificaoInstance.selectAll();
  }

  public onDataGridListaAIntervirSelectionChanged(): void {
    this._evaluateDataGridListaAIntervirSelection();
  }

  public onDataGridResumoProcessaDefinitionInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonDebDiretosSEPAEmissaoResumoProcessaItem, string>): void {
    this._dataGridResumoProcessaInstance = component;
  }

  public onDataGridNotificaoInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonDebDiretosSEPAEmissaoResumoProcessaItem>): void {
    this._dataGridNotificaoInstance = component;
  }

  public dataLiquidacaoFRSTChanged(value: TDate): void {
    this.model.dataLiquidacaoFRST = value;
    this._debDiretosSEPAModuleService.getNextDiaUtilByData(this.model.dataLiquidacaoFRST).then((response: HttpResponse<Moment>) => {
      this.model.dataLiquidacaoFRST = response.body;
    });
  }

  public dataLiquidacaoRCURChanged(value: TDate): void {
    this.model.dataLiquidacaoRCUR = value;
    this._debDiretosSEPAModuleService.getNextDiaUtilByData(this.model.dataLiquidacaoRCUR).then((response: HttpResponse<Moment>) => {
      this.model.dataLiquidacaoRCUR = response.body;
    });
  }

  public splitSizeValuesChanged(): void {
    this._dataGridListaAIntervirInstance.updateDimensions();
    this._dataGridResumoProcessaInstance.updateDimensions();
  }

  public wizardStepChange(params: IPlNavWizardEventStep): void {
    if (params.currentStep.stepId === this.wizGeraFicheiroDDStepId) {
      params.currentStep.setIncomplete();
      if (params.previousStep.stepId === this.wizResumoFicheirosGeradosStepId) {
        this._search();
      }
    }
  }

  public readonly fnSearch = (): Promise<void> => this._search();

  public readonly fnValidateStepRecords: (event: IPlNavWizardEventValidator) => Promise<boolean> = () => this._validateStepRecords();

  public readonly fnFinalize = (): Promise<void> => this._finalize();

  private _getListaAIntervirTableSource(): Array<IJsonDebDiretosSEPAEmissaoListaAIntervirItem> {
    return this.listaAIntervir;
  }

  private _getResumoProcessaTableSource(): Array<IJsonDebDiretosSEPAEmissaoResumoProcessaItem> {
    return this._resumoProcessa;
  }

  private async _search(): Promise<void> {
    const response: HttpResponse<Array<IJsonDebDiretosSEPAEmissaoListaAIntervirItem>> = await this._debDiretosSEPAModuleService.getDebDiretosSEPARecordsEmissao(this.model.vencimentoAte);
    this.listaAIntervir = response.body;
    await this._dataGridListaAIntervirInstance.refresh();
  }

  private _evaluateDataGridListaAIntervirSelection(): void {
    const selectedData: Array<IJsonDebDiretosSEPAEmissaoListaAIntervirItem> = this._dataGridListaAIntervirInstance.getSelectedRowsData();
    this._resumoProcessa = [];
    selectedData.forEach((item: IJsonDebDiretosSEPAEmissaoListaAIntervirItem) => {
      const resumo: IJsonDebDiretosSEPAEmissaoResumoProcessaItem = this._resumoProcessa.find((resumoItem: IJsonDebDiretosSEPAEmissaoResumoProcessaItem) => resumoItem.nConta === item.nConta);
      if (isDefinedNotNull(resumo)) {
        resumo.valor += item.valorEmAberto;
      } else {
        this._resumoProcessa.push({nConta: item.nConta, nome: item.nContaNome, valor: item.valorEmAberto, emailDD: item.emailDD});
      }
    });
    this._dataGridResumoProcessaInstance.refresh();
  }

  private _validateStepRecords(): Promise<boolean> {
    const selectedRowKeys: Array<number> = this._dataGridListaAIntervirInstance.getSelectedRowKeys();
    if (!selectedRowKeys.length) {
      this._plAlertService.error('debDiretosSEPA.messages.semRegistosSelecionados');
      return Promise.resolve(false);
    }
    return this._debDiretosSEPAModuleService
      .emissaoGerarFicheirosXML({
        selList: selectedRowKeys,
        dataLiquidacaoFRST: this.model.dataLiquidacaoRCUR,
        dataLiquidacaoRCUR: this.model.dataLiquidacaoRCUR
      })
      .then((response) => {
        this._listaGerados = response.body.listaGerados;
        this.listaErrosEAvisos = response.body.listaErrosEAvisos;
        this.plWizardCallback.setStep(WIZARD_STEP_FICHEIROS_GERADOS);
        if (this.listaErrosEAvisos.length) {
          this.resumoFicheirosGeradosActiveTabId = this.resumoFicheirosGeradosErrosTab;
        }
        return true;
      })
      .catch((reason) => {
        if (isDefinedNotNull(reason)) {
          const exception: ICGExceptionError = this._cgExceptionService.get(reason);
          this._plAlertService.error(exception.message);
        }
        return false;
      });
  }

  private _getFicheirosGeradosTableSource(): Array<IJsonDebDiretosSEPAEmissaoListaFicheirosGeradosItem> {
    return this._listaGerados;
  }

  private _getErrosEAvisosTableSource(): Array<IJsonDebDiretosSEPAErrorsAndWarningItem> {
    return this.listaErrosEAvisos;
  }

  private _getNotificaoTableSource(): Array<IJsonDebDiretosSEPAEmissaoResumoProcessaItem> {
    return this._resumoProcessa;
  }

  private _finalize(): Promise<void> {
    const selectedRowKeys: Array<string> = this._dataGridNotificaoInstance.getSelectedRowKeys();
    if (!selectedRowKeys.length) {
      this.close();
      return Promise.resolve();
    }
    return this._debDiretosSEPAModuleService.emissaoNotificarDevedores(selectedRowKeys).then(() => {
      this._plAlertService.success(this._translateService.instant('debDiretosSEPA.messages.devedoresNotificadosSucesso', {count: selectedRowKeys.length}));
      this.close();
    });
  }

  private _downloadFicheiro(nLoteFicheiro: string): Promise<unknown> {
    return this._debDiretosSEPAModuleService.downloadFicheiroXml(nLoteFicheiro).then((response: HttpResponse<Blob>) => {
      const stream: Blob = response.body;
      if (stream?.size) {
        downloadStream(response);
      }
    });
  }

  private _beforeStepChange({nextStep, currentStep, type}: IPlNavWizardEventBeforeChange): Promise<boolean> {
    if (type === 'set') {
      if (nextStep && nextStep.stepId === this.wizGeraFicheiroDDStepId) {
        return Promise.resolve(false);
      }
    } else if (type === 'next') {
      if (currentStep.stepId === this.wizGeraFicheiroDDStepId) {
        let total = 0;
        this._resumoProcessa.forEach((item) => {
          total += item.valor;
        });
        if (total === 0) {
          this._plAlertService.error('debDiretosSEPA.messages.naoPodeGerarFichComValorTotalZero');
          return Promise.resolve(false);
        }
      }
    }
    return Promise.resolve(true);
  }
}
