import type {Subscription} from 'rxjs';
import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import type {IPlRecaptchaInstance} from '../recaptcha.interface';
import {Logger} from '../../logger/logger';
import {PlRecaptchaService} from '../recaptcha.service';

@Component({
  selector: 'pl-recaptcha',
  template: '<ng-template></ng-template>'
})
export class PlRecaptchaComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public siteKey: string;
  @Input() public theme: ReCaptchaV2.Theme;
  @Input() public type: ReCaptchaV2.Type;
  @Input() public size: ReCaptchaV2.Size;
  @Input() public tabindex: number;
  @Input() public badge: ReCaptchaV2.Badge;
  @Input() public isolated: boolean;
  @Output() public readonly evtInstanceChanged: EventEmitter<IPlRecaptchaInstance>;
  @Output() public readonly evtSuccess: EventEmitter<string>;
  @Output() public readonly evtExpired: EventEmitter<void>;
  @Output() public readonly evtError: EventEmitter<void>;

  private readonly _element: HTMLElement;
  private _instance: IPlRecaptchaInstance;
  private _subscriptionOnSuccess: Subscription;
  private _subscriptionOnExpired: Subscription;
  private _subscriptionOnError: Subscription;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _logger: Logger,
    private readonly _plRecaptchaService: PlRecaptchaService
  ) {
    this.evtInstanceChanged = new EventEmitter<IPlRecaptchaInstance>();
    this.evtSuccess = new EventEmitter<string>();
    this.evtExpired = new EventEmitter<void>();
    this.evtError = new EventEmitter<void>();
    this._element = this._elementRef.nativeElement;
  }

  public ngOnInit(): void {
    this.generateRecaptcha();
  }

  public ngOnChanges({siteKey, theme, type, size, tabindex, badge, isolated}: SimpleChanges): void {
    if (
      (siteKey && !siteKey.isFirstChange()) ||
      (theme && !theme.isFirstChange()) ||
      (type && !type.isFirstChange()) ||
      (size && !size.isFirstChange()) ||
      (tabindex && !tabindex.isFirstChange()) ||
      (badge && !badge.isFirstChange()) ||
      (isolated && !isolated.isFirstChange())
    ) {
      this.generateRecaptcha();
    }
  }

  public ngOnDestroy(): void {
    this._clearInstance();
  }

  public generateRecaptcha(): void {
    this._clearInstance();
    this._plRecaptchaService
      .newInstance(this._element, {
        sitekey: this.siteKey,
        theme: this.theme,
        type: this.type,
        size: this.size,
        badge: this.badge,
        tabindex: this.tabindex,
        isolated: this.isolated
      })
      .then((instance: IPlRecaptchaInstance) => {
        this.instance = instance;
        this._subscriptionOnSuccess = this.instance.onSuccess().subscribe((token: string) => {
          this.evtSuccess.emit(token);
        });
        this._subscriptionOnExpired = this.instance.onExpired().subscribe(() => {
          this.evtExpired.emit();
        });
        this._subscriptionOnError = this.instance.onError().subscribe(() => {
          this.evtError.emit();
        });
      })
      .catch((reason: unknown) => {
        this._logger.error(reason);
      });
  }

  public reset(): void {
    if (this.instance) {
      if (this.instance.getResponse()) {
        this.evtSuccess.emit();
      }
      this.instance.reset();
    }
  }

  public get instance(): IPlRecaptchaInstance {
    return this._instance;
  }

  public set instance(value: IPlRecaptchaInstance) {
    this._instance = value;
    this.evtInstanceChanged.emit(this._instance);
  }

  private _clearInstance(): void {
    if (!this.instance) {
      return;
    }
    if (this._subscriptionOnSuccess) {
      this._subscriptionOnSuccess.unsubscribe();
    }
    if (this._subscriptionOnExpired) {
      this._subscriptionOnExpired.unsubscribe();
    }
    if (this._subscriptionOnError) {
      this._subscriptionOnError.unsubscribe();
    }
    while (this._element.lastChild) {
      this._element.removeChild(this._element.lastChild);
    }
    this._plRecaptchaService.deleteInstance(this.instance.widgetId());
    this.instance = undefined;
  }
}
