import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ERHRelatorioUnicoState, ERHRelatorioUnicoStep, IRHRelatorioUnicoError, IRHRelatorioUnicoStatus} from '../rhRelatorioUnico.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {RHRelatorioUnicoService} from '../rhRelatorioUnico.module.service';
import {downloadStream, IPlTabsEventSelected, isNumber, PlAlertService} from 'pl-comps-angular';
import {RHRelatorioUnicoConfigModalComponent} from '../modals/config/rhRelatorioUnico.config.modal.component';
import {IJsonRHRelatorioUnicoConfig} from '../jsonRHRelatorioUnico.module.interface';
import {HookMatchCriteria, StateDeclaration, StateObject, Transition, TransitionService} from '@uirouter/core';
import {STATE_NAME_LOGIN} from '../../../../states/account/login/login.state.interface';
import {STATE_NAME_DISCONNECTED} from '../../../../states/account/disconnected/disconnected.state.interface';
import {isTest} from '../../../../../config/constants';

const INTERVAL_TIMEOUT = 2000;

@Component({
  selector: 'rhrelatoriounico',
  templateUrl: './rhRelatorioUnico.module.component.html'
})
export class RHRelatorioUnicoComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public currentStatus: IRHRelatorioUnicoStatus;
  @Input() public anoProcessamento: number;

  public readonly tabProcess: string;
  public readonly tabDownload: string;
  public readonly rhrelatoriounicoSteps: typeof ERHRelatorioUnicoStep;
  public readonly filename: string;
  public currentStep: ERHRelatorioUnicoStep;
  public statusDescription: string;
  public statusErrorList: Array<IRHRelatorioUnicoError>;
  public pbPos: number;
  public isBlocked: boolean;
  public tabActiveId: string;

  private _intervalId: number;
  private _deRegisterOnStartFn: Function;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _rhrelatoriounicoService: RHRelatorioUnicoService,
    private readonly _plAlertService: PlAlertService,
    protected readonly _transitionService: TransitionService
  ) {
    super(_injector);
    this.tabProcess = 'tabProcessamento';
    this.tabDownload = 'tabDownload';
    this.rhrelatoriounicoSteps = ERHRelatorioUnicoStep;
    this.filename = 'RU.xml';
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.tabActiveId = this.tabProcess;
    this.statusErrorList = [];
    this.toolbar.addButton({
      id: 'configRU',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-cog"></i>&nbsp;',
      class: 'btn-light',
      caption: 'global.menu.configs',
      click: () => this._openConfigRUModal()
    });

    this.setCaption(this._translateService.instant('rhrelatoriounico.titleExport', {year: this.anoProcessamento}));

    if (this.currentStatus.state === ERHRelatorioUnicoState.Timeout) {
      this._showTimeoutModal();
      this._init();
    } else if (this.currentStatus.state === ERHRelatorioUnicoState.Error) {
      this.currentStep = ERHRelatorioUnicoStep.Errors;
      this._handleErrors(this.currentStatus);
    } else if (this.currentStatus.state === ERHRelatorioUnicoState.Ended) {
      this._init(this.tabDownload);
    } else if (this.currentStatus.state === ERHRelatorioUnicoState.Inactive) {
      this._init();
    } else {
      this.isBlocked = this.currentStatus.userStartedId !== this.session.userId;
      if (!this.isBlocked) {
        this.currentStep = ERHRelatorioUnicoStep.Processing;
        this._startProcessChecker();
      }
    }

    setTimeout(() => {
      this._registerOnStart();
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._clearProcessChecker();
    this._deRegisterOnStart();
    this._rhrelatoriounicoService.cancelProcess();
  }

  public closeErrorPanel(): void {
    this._init();
  }

  public formatBytes(bytes: number, decimals: number = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  public onTabChanged(event: IPlTabsEventSelected): void {
    this.tabActiveId = String(event.nextId);
  }

  public readonly fnVisualProcess = (): Promise<void> => this._visualProcess();

  public doDownload(): Promise<void> {
    return this._rhrelatoriounicoService.downloadFile().then((blob: HttpResponse<Blob>) => {
      if (!blob) {
        this._plAlertService.error(this._translateService.instant('rhrelatoriounico.erroDownload'));
      } else {
        downloadStream(blob);
      }
    });
  }

  protected _onPageUnload(): void {
    super._onPageUnload();
    this._appService.sendBeacon(this._rhrelatoriounicoService.cancelProcessUrl());
  }

  private _init(activeTab: string = this.tabProcess): void {
    this.currentStep = ERHRelatorioUnicoStep.Configuration;
    this.statusDescription = '';
    this.statusErrorList = [];
    this.pbPos = 0;
    if (activeTab.length) {
      this.tabActiveId = activeTab;
    }
    this._rhrelatoriounicoService.getAnoProcessamento();
  }

  private _visualProcess(): Promise<void> {
    if (!this.currentStatus.ficheiroProcessado) {
      return this._processar();
    }
    return this._cgModalService
      .showOkCancel('rhrelatoriounico.promptReprocessTitle', 'rhrelatoriounico.promptReprocessMessage', {
        size: 'md',
        btnOkText: 'rhrelatoriounico.buttons.yes',
        btnCancelText: 'rhrelatoriounico.buttons.no',
        backdrop: 'static',
        keyboard: false
      })
      .then(() => this._processar());
  }

  private _processar(): Promise<void> {
    this.currentStatus.ficheiroProcessado = false;
    return this._rhrelatoriounicoService.processar().then(() => {
      this.currentStep = ERHRelatorioUnicoStep.Processing;
      this._startProcessChecker();
    });
  }

  private _startProcessChecker(): void {
    this._clearProcessChecker();
    this._intervalId = window.setInterval(() => {
      this._rhrelatoriounicoService.getStatus().then((response: HttpResponse<IRHRelatorioUnicoStatus>) => {
        this.currentStatus = response.body;
        if (this.currentStatus.state === ERHRelatorioUnicoState.Timeout) {
          this._showTimeoutModal();
          this._clearProcessChecker();
          this._init();
          return;
        } else if (this.currentStatus.state === ERHRelatorioUnicoState.Error) {
          this.currentStep = ERHRelatorioUnicoStep.Errors;
          this._handleErrors(this.currentStatus);
          this._clearProcessChecker();
          return;
        } else if (this.currentStatus.state === ERHRelatorioUnicoState.Ended) {
          if (this.currentStatus.ficheiroProcessado) {
            this._init(this.tabDownload);
          } else {
            this.statusErrorList.push({nome: 'rhrelatoriounico.generation', descricao: 'rhrelatoriounico.fileNotProcessed'});
            this.currentStep = ERHRelatorioUnicoStep.Errors;
            this.tabActiveId = this.tabProcess;
          }
          this._clearProcessChecker();
          return;
        }
        this.pbPos = Math.round((this.currentStatus.position * 100) / this.currentStatus.max);
        this.statusDescription = this.currentStatus.description;
      });
    }, INTERVAL_TIMEOUT);
  }

  private _showTimeoutModal(): Promise<void> {
    return this._cgModalService.showOkCancel('rhrelatoriounico.jobTimeoutModalTitle', 'rhrelatoriounico.jobTimeoutModalMessage', {
      size: 'md',
      showCancelBtn: false,
      btnOkText: 'rhrelatoriounico.buttons.reiniciar',
      backdrop: 'static',
      keyboard: false
    });
  }

  private _handleErrors(status: IRHRelatorioUnicoStatus): void {
    this.statusErrorList = status.errorList.length ? status.errorList : [{nome: 'Erro', descricao: status.description}];
  }

  private _clearProcessChecker(): void {
    if (isNumber(this._intervalId)) {
      window.clearTimeout(this._intervalId);
      this._intervalId = undefined;
    }
  }

  private _openConfigRUModal(): Promise<void> {
    return this._rhrelatoriounicoService.getRelatorioUnicoConfig().then((response: HttpResponse<IJsonRHRelatorioUnicoConfig>) => {
      const modalInstance = this._cgModalService.showVanilla(RHRelatorioUnicoConfigModalComponent);
      const componentInstance: RHRelatorioUnicoConfigModalComponent = modalInstance.componentInstance;
      componentInstance.ruConfig = response.body;
    });
  }

  private _registerOnStart(): void {
    this._deRegisterOnStart();
    const criteria: HookMatchCriteria = {
      to: (state: StateObject, transition: Transition) => {
        const toState: StateDeclaration = transition.to();
        return transition.from() !== toState && toState.name !== STATE_NAME_LOGIN && toState.name !== STATE_NAME_DISCONNECTED;
      }
    };
    this._deRegisterOnStartFn = this._transitionService.onStart(criteria, () => this._navigationSafeGuard());
  }

  private _deRegisterOnStart(): void {
    if (this._deRegisterOnStartFn) {
      this._deRegisterOnStartFn();
      this._deRegisterOnStartFn = undefined;
    }
  }

  private _navigationSafeGuard(): Promise<void> {
    if (!isTest()) {
      if (this.currentStatus.ficheiroProcessado) {
        return this._cgModalService.showOkCancel('rhrelatoriounico.leavePromptTitle', 'rhrelatoriounico.leavePromptMessage', {
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          btnOkText: 'global.btn.yes',
          btnCancelText: 'global.btn.no'
        });
      }
    }
    return Promise.resolve();
  }
}
