import {merge} from 'lodash-es';
import {Component, Injector, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ValidatorFn} from '@angular/forms';
import type {IPlEditComponentOptionsInputSwitch} from './edit.switch.component.interface';
import {isDefinedNotNull, isEmpty, isFunction} from '../../../common/utilities/utilities';
import {PlEditInputComponent} from '../../generic/input/edit.input.component';

@Component({
  selector: 'pl-edit-switch',
  templateUrl: './edit.switch.component.html'
})
export class PlEditSwitchComponent extends PlEditInputComponent<any, IPlEditComponentOptionsInputSwitch> implements OnInit, OnChanges {
  @Input() public label: string;
  @Input() public trueValue: any;
  @Input() public falseValue: any;

  public val: boolean;
  public currentLabel: string;

  private _trueValue: unknown;
  private _falseValue: unknown;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this._defaultOptions = Object.freeze<IPlEditComponentOptionsInputSwitch>(
      merge({}, this._defaultOptions, {
        label: undefined,
        value: undefined,
        trueValue: true,
        falseValue: false,
        round: true
      })
    );
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._subscriptionValueChanges.unsubscribe();
    this._handleChanges();
    this.setValidators(this._requiredValidator());
    this._handleModelChange();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const {label, trueValue, falseValue} = changes;
    if (label && !label.isFirstChange()) {
      this._changedLabel(label.currentValue);
    }
    if (trueValue && !trueValue.isFirstChange()) {
      this._changedTrueValue(trueValue.currentValue);
    }
    if (falseValue && !falseValue.isFirstChange()) {
      this._changedFalseValue(falseValue.currentValue);
    }
  }

  public updateComponent(properties: IPlEditComponentOptionsInputSwitch): void {
    super.updateComponent(properties);
    this._handleChanges();
  }

  public updateValue(value: boolean): void {
    super.updateValue(value);
    this._handleModelChange();
  }

  public render(): Promise<void> {
    let value: unknown;
    if (!this.options.value && this.options.value !== 0) {
      value = this._toModelValue(this.val);
    } else if (this.value !== this.options.value) {
      value = this.options.value;
    } else {
      value = this.options.falseValue;
    }
    if (isDefinedNotNull(value)) {
      return super.render(value);
    }
    return Promise.resolve();
  }

  public toggleValue(): void {
    if (this.options.disabled || this.options.readonly) {
      return;
    }
    let promise;
    if (isFunction(this.options.events.beforeChange)) {
      const newValue: unknown = this._toModelValue(!this.val);
      promise = this.options.events.beforeChange(newValue, this.value);
    }
    Promise.resolve(promise).then((prevent: boolean) => {
      if (prevent === false) {
        return;
      }
      this.val = !this.val;
      this.render();
    });
  }

  public onKeydownEnter(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.toggleValue();
  }

  private _handleChanges(): void {
    this._changedLabel();
    this._changedTrueValue();
    this._changedFalseValue();
  }

  private _changedLabel(value: string = this.label): void {
    this.currentLabel = value || this.options.label || '';
  }

  private _changedTrueValue(value: unknown = this.trueValue): void {
    let val: unknown = value;
    if (isEmpty(val)) {
      val = this.options.trueValue;
    }
    if (isEmpty(val)) {
      val = true;
    }
    this._trueValue = val;
  }

  private _changedFalseValue(value: unknown = this.falseValue): void {
    let val: unknown = value;
    if (isEmpty(val)) {
      val = this.options.falseValue;
    }
    if (isEmpty(val)) {
      val = false;
    }
    this._falseValue = val;
  }

  private _requiredValidator(): ValidatorFn {
    return () => {
      if (this.validate && this.options.validators.required?.value === true) {
        return !isEmpty(this.value) ? undefined : {required: true};
      }
      return undefined;
    };
  }

  private _handleModelChange(): void {
    this.val = isEmpty(this.options.value) ? Boolean(this.value) : this.value === this.options.value;
  }

  private _toModelValue(value: unknown): unknown {
    return value ? this._trueValue : this._falseValue;
  }
}
