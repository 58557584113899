<div class="analisador-tesouraria">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1">
        <div class="row">
          <div class="col-sm-12 col-xl-3">
            <pl-group>
              <label [translate]="'analisadorTes.strings.saldoInicial'"></label>
              <edit>
                <pl-edit type="number" attrName="saldoInicial" [model]="model.saldoInicial" (modelChange)="saldoInicialChange($event)" [properties]="{disabled: isFirstTime}">
                  <div *inputGroup="{klass: 'btn-light', promise: promiseUpdateSaldoInicial}">
                    <pl-tooltip [config]="{text: 'analisadorTes.strings.updateSaldoInicial'}" (click)="updateSaldoInicial()" data-capture-click>
                      <i *plTooltipContent class="fa fa-refresh"></i>
                    </pl-tooltip>
                  </div>
                </pl-edit>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3">
            <pl-group>
              <label [translate]="'analisadorTes.strings.de'"></label>
              <edit>
                <pl-edit-datepicker [model]="model.fromDate" (modelChange)="fromDateChanged($event)"></pl-edit-datepicker>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3">
            <pl-group>
              <label [translate]="'analisadorTes.strings.ate'"></label>
              <edit>
                <pl-edit-datepicker [model]="model.toDate" (modelChange)="toDateChanged($event)"></pl-edit-datepicker>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3 align-self-end">
            <pl-group>
              <edit>
                <button type="button" class="btn btn-sm btn-primary" name="pesquisar" id="btnPesquisar" [click]="fnPesquisar" plPromise data-focus>
                  <i class="fa fa-search fa-fw"></i>&nbsp;<span [translate]="'global.btn.search'"></span>
                </button>
              </edit>
            </pl-group>
          </div>
        </div>
      </pl-form>
    </div>
  </cg-card-panel>

  <dx-tree-list
    id="treelist"
    [dataSource]="dataSource"
    [showBorders]="true"
    [columnAutoWidth]="true"
    [remoteOperations]="{filtering: true}"
    (onInitialized)="onInitialized($event)"
    (onCellPrepared)="onCellPreparedEvent($event)"
    (onContextMenuPreparing)="addContextMenuItems($event)"
    (onCellClick)="onCellClick($event)"
    height="calc(100vh - 260px)"
    keyExpr="nodeId"
    parentIdExpr="nodeParentId"
    [hasItemsExpr]="hasItemsExprFn"
    rootValue="-1">
    <dxi-column [name]="'rubricaTopBand'" [allowResizing]="true" width="250" [caption]="'analisadorTes.strings.rubrica' | translate" alignment="center">
      <dxi-column [dataField]="nomeRubricaDataField" [allowResizing]="true" width="250" caption=""></dxi-column>
    </dxi-column>
    <dxi-column [name]="'descricaoTopBand'" [allowResizing]="true" width="250" [caption]="'analisadorTes.strings.descricao' | translate" alignment="center">
      <dxi-column
        [dataField]="descricaoRubricaDataField"
        [allowResizing]="true"
        cssClass="col-header-saldo-inicial"
        width="250"
        [caption]="'analisadorTes.strings.tabSaldoInicialTitle' | translate"></dxi-column>
    </dxi-column>

    <dxi-column [name]="placeholderColName" alignment="center"></dxi-column>

    <div *dxTemplate="let item of 'colHeaderSaldoInicial'">
      <b style="color: rgba(0, 0, 0, 0.87)">{{ getColHeaderSaldoInicialValue(item.column) }}</b>
    </div>

    <dxo-toolbar>
      <dxi-item name="exportToXlsx" widget="dxButton" locateInMenu="auto" [options]="exportButtonOptions" />
    </dxo-toolbar>
  </dx-tree-list>
</div>
