<div class="bancos-extrato-module-docs-semelhantes-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'bancosextrato.docssemelhantesmodal.title'"></h5>
  </div>

  <div class="modal-body">
    <dx-data-grid
      [cgDxDataGrid]="dataGrid"
      [cgDxDataGridInstanceName]="dataGridInstanceName"
      [dataSource]="dataGrid.dataSource"
      (onInitialized)="onDataGridInitialized($event)"
      (onContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
      (onFocusedRowChanged)="onDataGridFocusedRowChanged($event)"
      (onCellDblClick)="onDataGridCellDblClick($event)">
      <div *dxTemplate="let row of 'dataGridTemplateActions'">
        <button type="button" class="btn btn-xs btn-primary action-ver-doc" [click]="fnDataGridOpenDoc(row.data)" plPromise [attr.title]="'bancosextrato.actions.viewDocument' | translate">
          <i class="fa fa-fw pl-icon-ver-doc-contabilidade" aria-hidden="true"></i>
        </button>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-edit-switch
      class="bancos-extrato-module-docs-semelhantes-modal-mostrar-outras-datas"
      attrName="mostraroutrasdatas"
      [model]="mostrarOutrasDatas"
      (modelChange)="changedMostrarOutrasDatas($event)"
      [label]="'bancosextrato.docssemelhantesmodal.text.mostrarOutrasDatas'">
    </pl-edit-switch>

    <pl-button klass="btn-primary action-associar" [disabled]="closeDisabled || !focusedDocSemelhante" [onClick]="fnAssociarDocSemelhante">
      <i class="fa fa-fw fa-random"></i>&nbsp;<span [translate]="'bancosextrato.docssemelhantesmodal.actions.connect'"></span>
    </pl-button>

    <pl-button klass="btn-light action-cancel" [disabled]="closeDisabled" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
  </div>
</div>
