<pl-form class="contabilidadedigital-pre-definidos-config-modal" [submit]="close" plPromise>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()" [disabled]="closeDisabled"></button>
    <h4 class="modal-title" [translate]="'arquivodigital.predefinidosconfig.title'"></h4>
  </div>

  <div class="modal-body" [plPromise]="promiseNIF">
    <pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.nif'"></label>
        <edit>
          <entity-autocomplete entity="nifs" attrName="nif" [selectedKey]="nif" (selectedKeyChange)="nifChanged($event)" output="key"> </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.nomeFornecedor'"></label>
        <edit>
          <pl-edit type="text" attrName="nomeFornecedor" [(model)]="nomeFornecedor" [properties]="{disabled: true}"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <hr />

    <pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.fatura'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="fatura"
            [model]="preDefinido"
            (selectedKeyChange)="preDefinido.fatura = $event"
            (evtSelectedDescriptionChanged)="preDefinido.nomeFatura = $event"
            [fieldsMap]="{preDefinidosID: 'fatura', descricao: 'nomeFatura'}">
            <div *inputGroup="{klass: 'hollow-form'}">
              <pl-tooltip *ngIf="preDefinido.eFaturaFatura" [config]="tooltipInfoEfatura">
                <i *plTooltipContent class="fa fa-info-circle fa-fw" aria-hidden="true"></i>
              </pl-tooltip>
            </div>
          </entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.vendaDinheiro'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="vendaDinheiro"
            [model]="preDefinido"
            (selectedKeyChange)="preDefinido.vendaDinheiro = $event"
            (evtSelectedDescriptionChanged)="preDefinido.nomeVendaDinheiro = $event"
            [fieldsMap]="{preDefinidosID: 'vendaDinheiro', descricao: 'nomeVendaDinheiro'}">
            <div *inputGroup="{klass: 'hollow-form'}">
              <pl-tooltip *ngIf="preDefinido.eFaturaVendaDinheiro" [config]="tooltipInfoEfatura">
                <i *plTooltipContent class="fa fa-info-circle fa-fw" aria-hidden="true"></i>
              </pl-tooltip>
            </div>
          </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.faturaSimplificada'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="faturaSimplificada"
            [model]="preDefinido"
            (selectedKeyChange)="preDefinido.faturaSimplificada = $event"
            (evtSelectedDescriptionChanged)="preDefinido.nomeFaturaSimplificada = $event"
            [fieldsMap]="{preDefinidosID: 'faturaSimplificada', descricao: 'nomeFaturaSimplificada'}">
            <div *inputGroup="{klass: 'hollow-form'}">
              <pl-tooltip *ngIf="preDefinido.eFaturaFaturaSimplificada" [config]="tooltipInfoEfatura">
                <i *plTooltipContent class="fa fa-info-circle fa-fw" aria-hidden="true"></i>
              </pl-tooltip>
            </div>
          </entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.faturaRecibo'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="faturaRecibo"
            [model]="preDefinido"
            (selectedKeyChange)="preDefinido.faturaRecibo = $event"
            (evtSelectedDescriptionChanged)="preDefinido.nomeFaturaRecibo = $event"
            [fieldsMap]="{preDefinidosID: 'faturaRecibo', descricao: 'nomeFaturaRecibo'}">
            <div *inputGroup="{klass: 'hollow-form'}">
              <pl-tooltip *ngIf="preDefinido.eFaturaFaturaRecibo" [config]="tooltipInfoEfatura">
                <i *plTooltipContent class="fa fa-info-circle fa-fw" aria-hidden="true"></i>
              </pl-tooltip>
            </div>
          </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.notaDebito'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="notaDebito"
            [model]="preDefinido"
            (selectedKeyChange)="preDefinido.notaDebito = $event"
            (evtSelectedDescriptionChanged)="preDefinido.nomeNotaDebito = $event"
            [fieldsMap]="{preDefinidosID: 'notaDebito', descricao: 'nomeNotaDebito'}">
            <div *inputGroup="{klass: 'hollow-form'}">
              <pl-tooltip *ngIf="preDefinido.eFaturaNotaDebito" [config]="tooltipInfoEfatura">
                <i *plTooltipContent class="fa fa-info-circle fa-fw" aria-hidden="true"></i>
              </pl-tooltip>
            </div>
          </entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.notaCredito'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="notaCredito"
            [model]="preDefinido"
            (selectedKeyChange)="preDefinido.notaCredito = $event"
            (evtSelectedDescriptionChanged)="preDefinido.nomeNotaCredito = $event"
            [fieldsMap]="{preDefinidosID: 'notaCredito', descricao: 'nomeNotaCredito'}">
            <div *inputGroup="{klass: 'hollow-form'}">
              <pl-tooltip *ngIf="preDefinido.eFaturaNotaCredito" [config]="tooltipInfoEfatura">
                <i *plTooltipContent class="fa fa-info-circle fa-fw" aria-hidden="true"></i>
              </pl-tooltip>
            </div>
          </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.talaoVenda'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="talaoVenda"
            [model]="preDefinido"
            (selectedKeyChange)="preDefinido.talaoVenda = $event"
            (evtSelectedDescriptionChanged)="preDefinido.nomeTalaoVenda = $event"
            [fieldsMap]="{preDefinidosID: 'talaoVenda', descricao: 'nomeTalaoVenda'}">
          </entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.talaoDevolucao'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="talaoDevolucao"
            [model]="preDefinido"
            (selectedKeyChange)="preDefinido.talaoDevolucao = $event"
            (evtSelectedDescriptionChanged)="preDefinido.nomeTalaoDevolucao = $event"
            [fieldsMap]="{preDefinidosID: 'talaoDevolucao', descricao: 'nomeTalaoDevolucao'}">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.alienacaoAtivos'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="alienacaoAtivos"
            [model]="preDefinido"
            (selectedKeyChange)="preDefinido.alienacaoAtivos = $event"
            (evtSelectedDescriptionChanged)="preDefinido.nomeAlienacaoAtivos = $event"
            [fieldsMap]="{preDefinidosID: 'alienacaoAtivos', descricao: 'nomeAlienacaoAtivos'}">
          </entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.devolucaoAtivos'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="devolucaoAtivos"
            [model]="preDefinido"
            (selectedKeyChange)="preDefinido.devolucaoAtivos = $event"
            (evtSelectedDescriptionChanged)="preDefinido.nomeDevolucaoAtivos = $event"
            [fieldsMap]="{preDefinidosID: 'devolucaoAtivos', descricao: 'nomeDevolucaoAtivos'}">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.premio'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="premio"
            [model]="preDefinido"
            (selectedKeyChange)="preDefinido.premio = $event"
            (evtSelectedDescriptionChanged)="preDefinido.nomePremio = $event"
            [fieldsMap]="{preDefinidosID: 'premio', descricao: 'nomePremio'}">
          </entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.estorno'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="estorno"
            [model]="preDefinido"
            (selectedKeyChange)="preDefinido.estorno = $event"
            (evtSelectedDescriptionChanged)="preDefinido.nomeEstorno = $event"
            [fieldsMap]="{preDefinidosID: 'estorno', descricao: 'nomeEstorno'}">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.imputacaoSeguradoras'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="imputacaoSeguradoras"
            [model]="preDefinido"
            (selectedKeyChange)="preDefinido.imputacaoSeguradoras = $event"
            (evtSelectedDescriptionChanged)="preDefinido.nomeImputacaoSeguradoras = $event"
            [fieldsMap]="{preDefinidosID: 'imputacaoSeguradoras', descricao: 'nomeImputacaoSeguradoras'}">
          </entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.imputacaoSegLider'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="imputacaoSegLider"
            [model]="preDefinido"
            (selectedKeyChange)="preDefinido.imputacaoSegLider = $event"
            (evtSelectedDescriptionChanged)="preDefinido.nomeImputacaoSegLider = $event"
            [fieldsMap]="{preDefinidosID: 'imputacaoSegLider', descricao: 'nomeImputacaoSegLider'}">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.recibo'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="recibo"
            [model]="preDefinido"
            (selectedKeyChange)="preDefinido.recibo = $event"
            (evtSelectedDescriptionChanged)="preDefinido.nomeRecibo = $event"
            [fieldsMap]="{preDefinidosID: 'recibo', descricao: 'nomeRecibo'}">
          </entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.pagamentoEstado'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="pagamentoEstado"
            [model]="preDefinido"
            (selectedKeyChange)="preDefinido.pagamentoEstado = $event"
            (evtSelectedDescriptionChanged)="preDefinido.nomePagamentoEstado = $event"
            [fieldsMap]="{preDefinidosID: 'pagamentoEstado', descricao: 'nomePagamentoEstado'}">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.levantamento'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="levantamento"
            [model]="preDefinido"
            (selectedKeyChange)="preDefinido.levantamento = $event"
            (evtSelectedDescriptionChanged)="preDefinido.nomeLevantamento = $event"
            [fieldsMap]="{preDefinidosID: 'levantamento', descricao: 'nomeLevantamento'}">
          </entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.pagamento'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="pagamento"
            [model]="preDefinido"
            (selectedKeyChange)="preDefinido.pagamento = $event"
            (evtSelectedDescriptionChanged)="preDefinido.nomePagamento = $event"
            [fieldsMap]="{preDefinidosID: 'pagamento', descricao: 'nomePagamento'}">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.deposito'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="deposito"
            [model]="preDefinido"
            (selectedKeyChange)="preDefinido.deposito = $event"
            (evtSelectedDescriptionChanged)="preDefinido.nomeDeposito = $event"
            [fieldsMap]="{preDefinidosID: 'deposito', descricao: 'nomeDeposito'}">
          </entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.predefinidosconfig.cheque'"></label>
        <edit>
          <entity-autocomplete
            entity="predefinidoscontabilidade"
            attrName="cheque"
            [model]="preDefinido"
            (selectedKeyChange)="preDefinido.cheque = $event"
            (evtSelectedDescriptionChanged)="preDefinido.nomeCheque = $event"
            [fieldsMap]="{preDefinidosID: 'cheque', descricao: 'nomeCheque'}">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <label [translate]="'arquivodigital.predefinidosconfig.transferencia'"></label>
      <edit>
        <entity-autocomplete
          entity="predefinidoscontabilidade"
          attrName="transferencia"
          [model]="preDefinido"
          (selectedKeyChange)="preDefinido.transferencia = $event"
          (evtSelectedDescriptionChanged)="preDefinido.nomeTransferencia = $event"
          [fieldsMap]="{preDefinidosID: 'transferencia', descricao: 'nomeTransferencia'}">
        </entity-autocomplete>
      </edit>
    </pl-group>

    <hr *ngIf="modoLancarDocsAutoDisponivel" />

    <div class="d-flex" *ngIf="modoLancarDocsAutoDisponivel">
      <pl-tooltip [config]="{text: 'arquivodigital.predefinidosconfig.lancfaturahint', placement: 'bottom-right', container: 'body'}">
        <button *plTooltipContent type="button" class="btn btn-primary btn-xs me-2" (click)="lancaDocsAutoInfo()">
          <i class="fa fa-fw fa-question-circle-o"></i>
        </button>
      </pl-tooltip>

      <pl-edit type="checkbox" [(model)]="preDefinido.lancaDocsAuto" [properties]="{label: preDefinido.lancaDocsAutoCaption}"></pl-edit>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <div class="warning-efatura" [translate]="'arquivodigital.predefinidosconfig.warningEFatura'"></div>

    <pl-button type="submit" klass="btn-success action-submit" [disabled]="closeDisabled"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span> </pl-button>

    <pl-button klass="btn-light action-cancel" (evtClicked)="dismiss()" [disabled]="closeDisabled"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</pl-form>
