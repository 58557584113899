import {IJsonDocContabilidade} from '../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {IJsonAquisicaoContabDoc} from '../ativosaquisicaocontab/jsonAtivosAquisicaoContab.module.interface';

export interface IAquisicaoInvestEmCursoPorDocItem extends IJsonAquisicaoContabDoc {
  _docContabilidade?: IJsonDocContabilidade;
}

export interface IAquisicaoContaDeInvestEmCursoPorDocParams {
  doSearch: boolean;
}

export const MODULE_NAME_ATIVOS_AQUISICAO_INVEST_EM_CURSO_POR_DOC = 'ativosaquisicaoinvestemcurso';
