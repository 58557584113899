<div class="modal-header">
  <h4 class="modal-title" [translate]="'importadorSaftDirect.strings.correspCCAssociarModalTitle'"></h4>
</div>

<div class="modal-body">
  <pl-form [properties]="{validators: {required: {value: true}}}">
    <pl-group>
      <label [translate]="'importadorSaftDirect.steps.contasCorrentes.nContaDiversos'"></label>
      <edit>
        <entity-autocomplete entity="clifos" [(selectedKey)]="nConta" [properties]="{allowInvalid: false}" output="nConta"> </entity-autocomplete>
      </edit>
    </pl-group>
  </pl-form>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-sm btn-primary action-close" (evtClicked)="close(nConta)" plAutoFocus>
    <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'importadorSaftDirect.strings.modalAssociar'"></span>
  </pl-button>

  <pl-button klass="btn-sm btn-light" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'importadorSaftDirect.strings.modalCancel'"></span> </pl-button>
</div>
