<div class="ativosabate-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'rhtransferenciasbancarias.modal.configransfticket'"></h5>
  </div>

  <div class="modal-body entity-detail-form">
    <pl-form>
      <pl-group>
        <label [translate]="'rhtransferenciasbancarias.modal.codBanco'"></label>
        <edit>
          <entity-autocomplete
            entity="idbancos"
            attrName="idbancos"
            [model]="transferencia"
            (selectedKeyChange)="changedCodBanco($event)"
            (evtSelectedDescriptionChanged)="transferencia.nomeBanco = $event"
            [fieldsMap]="{idinterBancario: 'codBanco', nomeInterBancario: 'nomeBanco'}"
            [properties]="{
              readonly: edit,
              allowInvalid: false,
              validators: {
                required: {value: true},
                banco: validatorBanco
              }
            }">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'rhtransferenciasbancarias.modal.codOpTrfBa'"></label>
          <edit>
            <pl-edit type="text" attrName="codOpTrfBa" [(model)]="transferencia.codOpTrfBa"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'rhtransferenciasbancarias.modal.nClienteBanco'"></label>
          <edit>
            <pl-edit type="text" attrName="nClienteBanco" [(model)]="transferencia.nClienteBanco"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'rhtransferenciasbancarias.modal.nCartaoTrfBaIBAN'"></label>
          <edit>
            <pl-edit type="boolean" attrName="nCartaoTrfBaIBAN" [(model)]="transferencia.nCartaoTrfBaIBAN"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'rhtransferenciasbancarias.modal.cartaoDifBanco'"></label>
          <edit>
            <pl-edit type="boolean" attrName="cartaoDifBanco" [(model)]="transferencia.cartaoDifBanco"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <label [translate]="'rhtransferenciasbancarias.modal.configTrfBaTicket'"></label>
        <edit>
          <pl-edit type="boolean" attrName="configTrfBaTicket" [(model)]="transferencia.configTrfBaTicket"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'rhtransferenciasbancarias.modal.tipoFichTrf'"></label>
        <edit>
          <pl-edit type="number" attrName="tipoFichTrf" [(model)]="transferencia.tipoFichTrf"></pl-edit>
        </edit>
      </pl-group>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success action-dismiss" [disabled]="!valid" (evtClicked)="close()"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span> </pl-button>

    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
