<div class="calculodiferimentos">
  <pl-tabs *ngIf="mainModule" (evtSelected)="changedTab($event.nextId)" [activeId]="activeTab">
    <pl-tab id="infoDiferimentosTab" caption="diferimentosglobal.diferimentos">
      <div *plTabContent>
        <ng-container *ngTemplateOutlet="templateInfoDataGrid; context: {id: 'infoDiferimentosTotais', dataSource: infoGlobal.infoList}"></ng-container>
      </div>
    </pl-tab>

    <pl-tab id="infoAcrescimosTab" caption="diferimentosglobal.acrescimos">
      <div *plTabContent>
        <ng-container *ngTemplateOutlet="templateInfoDataGrid; context: {id: 'infoGlobalAcrescimos', dataSource: infoGlobalAcrescimos.infoList}"></ng-container>
      </div>
    </pl-tab>
  </pl-tabs>

  <div *ngIf="!mainModule">
    <ng-container *ngTemplateOutlet="templateInfoDataGrid; context: {id: 'infoDiferimentosTotais', dataSource: infoGlobal.infoList}"></ng-container>
  </div>
</div>

<ng-template #templateInfoDataGrid let-id="id" let-dataSource="dataSource">
  <dx-data-grid [cgDxDataGrid]="dataGridDefinitionTotais" [cgDxDataGridInstanceName]="id" [dataSource]="dataSource" (onCellClick)="onCellClick($event)">
    <div *dxTemplate="let detailInfo of 'masterDetailTemplateTotais'">
      <pl-split-view [initialSizeLeft]="50">
        <div *plSplitViewLeftSide>
          <dx-data-grid [cgDxDataGrid]="dataGridDefinitionDetail" [dataSource]="detailInfo.data.detalhes">
            <div *dxTemplate="let item of 'toolbarTemplateDetalhes'">
              <h5 [translate]="'diferimentosglobal.docsselmes'"></h5>
            </div>
          </dx-data-grid>
        </div>

        <div *plSplitViewRightSide>
          <dx-data-grid
            [cgDxDataGrid]="dataGridDefinitionDetail"
            [dataSource]="detailInfo.data.docsSaldo"
            (onCellDblClick)="onCellDblClick($event)"
            (onContextMenuPreparing)="onContextMenuPreparing($event)">
            <div *dxTemplate="let item of 'toolbarTemplateDetalhes'">
              <h5 [translate]="'diferimentosglobal.docscontribuem'"></h5>
            </div>
          </dx-data-grid>
        </div>
      </pl-split-view>
    </div>
  </dx-data-grid>
</ng-template>
