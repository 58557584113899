import {IPlEditComponentOptionsInputAutocomplete, IPlValidatorValidateParams} from 'pl-comps-angular';
import {
  EGTOCOPYFOR,
  EGTOExecuteEstado,
  IJsonGTOConfig,
  IJsonGTOTarefa,
  IJsonGTOTarefaMarc,
  IJsonGTOTarefaMultiItem,
  IJsonGTOTarefaMultiRow,
  IJsonGTOTarefas,
  IJsonGTOTarefasMulti
} from './jsonGTO.module.interface';
import {TDate} from '../../../../common/dates';

export type TGTOMonthsValidateParams = IPlValidatorValidateParams<string, string | IGTOMonthsDataSourceItem>;

export interface IGTOTarefasBase {
  haveDMRAT: boolean;
  haveDMRSS: boolean;
  haveAlerts: boolean;
}

export interface IGTOSingleFilters {
  nempresa: string;
  ano: number;
  tipoTarefa: number;
  nResponsavel: number;
  nomeResponsavel?: string;
  nome?: string;
}

export interface IGTOMultiFilters {
  ano: number;
  mes: number;
  tipoTarefa: number;
  nResponsavel: number;
  periodoIVA: number;
  regimeIRC: number;
  nomeResponsavel?: string;
  nomeTarefa: string;
}

export interface IGTOResponsavel {
  nResponsavel: number;
  nomeResponsavel: string;
}

export interface IGTOMonthsModel {
  [month: string]: IGTOMonthsModelItem;

  m1: IGTOMonthsModelItem;
  m2: IGTOMonthsModelItem;
  m3: IGTOMonthsModelItem;
  m4: IGTOMonthsModelItem;
  m5: IGTOMonthsModelItem;
  m6: IGTOMonthsModelItem;
  m7: IGTOMonthsModelItem;
  m8: IGTOMonthsModelItem;
  m9: IGTOMonthsModelItem;
  m10: IGTOMonthsModelItem;
  m11: IGTOMonthsModelItem;
  m12: IGTOMonthsModelItem;
}

export interface IGTOMonthsModelItem {
  source: Array<IGTOMonthsDataSourceItem>;
  selectedDay: IGTOMonthsDataSourceItem;
  gtoTarefasMarcacaoID: string;
  properties: IPlEditComponentOptionsInputAutocomplete;
}

export interface IGTOMonthsDataSourceItem {
  value: number;
  name?: string;
}

export interface IGTOConfigModel {
  nempresa: string;
  nome: string;
  empConfig: IJsonGTOConfig;
  globalConfig: IJsonGTOConfig;
}

export interface IGTOChangeResponsavelModel {
  nResponsavel: number;
  nomeResponsavel: string;
}

export interface IGTOTarefas extends IJsonGTOTarefas {
  list: Array<IGTOTarefa>;
}

export interface IGTOTarefa extends IJsonGTOTarefa {
  gtoTarefaMarc: Array<IGTOTarefaMarc>;
  janeiro?: IGTOTarefaMarc;
  fevereiro?: IGTOTarefaMarc;
  marco?: IGTOTarefaMarc;
  abril?: IGTOTarefaMarc;
  maio?: IGTOTarefaMarc;
  junho?: IGTOTarefaMarc;
  julho?: IGTOTarefaMarc;
  agosto?: IGTOTarefaMarc;
  setembro?: IGTOTarefaMarc;
  outubro?: IGTOTarefaMarc;
  novembro?: IGTOTarefaMarc;
  dezembro?: IGTOTarefaMarc;
}

export interface IGTOTarefaMarc extends IJsonGTOTarefaMarc {
  _cssClass?: string;
}

export interface IGTOTarefasMulti extends IJsonGTOTarefasMulti {
  list: Array<IGTOTarefaMultiRow>;
}

export interface IGTOTarefaMultiRow extends IJsonGTOTarefaMultiRow {
  data: Array<IGTOTarefaMultiItem>;
  _selected?: boolean;
}

export interface IGTOTarefaMultiItem extends IJsonGTOTarefaMultiItem {
  _cssClass?: string;
}

export interface IDynamicGTOTarefaMulti extends IGTOTarefaMultiRow {
  [key: string]: unknown;
}

export interface IGTOCopyModalModel {
  ano: number;
  copyFor: number;
  nempresa: string;
  nome: string;
  copyAno: number;
  fullOverride: boolean;
}

export interface IGTOCopyForItem {
  value: EGTOCOPYFOR;
  label: string;
}

export interface IGTOExecuteItem {
  value: EGTOExecuteEstado;
  label: string;
}

export interface IGTOAlertasTableItem {
  nEmpresa: string;
  nomeEmpresa: string;
  items: Array<IGTOAlertasSubTableItem>;
}

export interface IGTOAlertasSubTableItem {
  nomeTarefa: string;
  expireDate: TDate;
}

export interface IGTOTabCallback {
  refresh?(): Promise<void>;

  getFilters?(): IGTOSingleFilters;
}

export interface IGTOTabMultiCallback extends IGTOTabCallback {
  getSelectedRow?(): IGTOTarefaMultiRow;
  repaintGridOnMobile?(): void;
}

export interface IGTOConfigModalResult {
  refresh: boolean;
  nempresa: string;
}

export interface IGTOCellInfo {
  cellElement?: HTMLElement;
  rowIndex?: number;
  columnIndex?: number;
  tarefaMarc: IGTOTarefaMultiItem;
}

export interface IGTOSelectedRange {
  startRowIndex?: number;
  endRowIndex?: number;
  startColumnIndex?: number;
  endColumnIndex?: number;
}

export const MODULE_NAME_GTO = 'gto';

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const GTO_MIN_DATE: TDate = new Date(1753, 0, 1);

const GTO_CHAR_ENTIDADE_EXTERNA = '_';
const EMPRESA_MAX_LENGTH = 3;

export function gtoIsEntidadeExterna(nEmpresa: string): boolean {
  return nEmpresa.length > EMPRESA_MAX_LENGTH || nEmpresa.startsWith(GTO_CHAR_ENTIDADE_EXTERNA);
}
