<div class="reset-password">
  <div class="account-title">
    <h3 [innerHTML]="'resetpassword.message' | translate"></h3>
  </div>

  <form class="account-form account-panel" rel="noopener noreferrer" novalidate [formGroup]="form" [submit]="fnResetPassword" plPromise>
    <div class="account-panel-body">
      <div class="account-logo">
        <a uiSref="login" rel="noopener noreferrer">
          <img src="../../../../../assets/images/logo.webp" alt="CentralGest ERPCloud Logo" />
        </a>
      </div>

      <input
        type="text"
        id="email"
        class="form-control account-empty"
        formControlName="username"
        autocapitalize="off"
        autocomplete="off"
        plAutoFocus
        [attr.placeholder]="'&#xF007;&nbsp;' + ('resetpassword.email.placeholder' | translate)"
        maxlength="120" />

      <pl-recaptcha *ngIf="recaptchaActive" formControlName="recaptcha"></pl-recaptcha>

      <button type="submit" class="btn btn-primary btn-block action-submit"><i class="fa fa-fw fa-key"></i> <span [translate]="'resetpassword.action.resetpassword'"></span></button>

      <p class="text-center">
        <small>
          <a uiSref="login" rel="noopener noreferrer"> <i class="fa fa-fw fa-angle-left"></i>&nbsp;<span [translate]="'entity.action.back'"></span> </a>
        </small>
      </p>
    </div>
  </form>
</div>
