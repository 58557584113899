<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="'proceDMRAT.printMapaModalTitle'"></h5>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <pl-group>
        <label [translate]="'proceDMRAT.listagem'"></label>
        <edit>
          <pl-edit [type]="reportType" [model]="reportModel" (modelChange)="onReportChange($event)" [properties]="{entity: {outputKey: 'name', outputDescription: 'title'}}"></pl-edit>
        </edit>
        <actions>
          <pl-button type="button" klass="btn btn-success" (evtClicked)="loadPdf()">
            <i class="fa fa-file-pdf-o" aria-hidden="true"></i>&nbsp;<span [translate]="'proceDMRAT.obterPdf'"></span>
          </pl-button>
        </actions>
      </pl-group>
    </div>
  </div>

  <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-close"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
