import {findLastIndex} from 'lodash-es';
import {Subscription} from 'rxjs';
import {Component, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IPlToolbarItem, isArray, isEmpty, isObject, KEYCODES, PlAlertService, toInteger} from 'pl-comps-angular';
import {ContabilidadeEstatisticaService} from '../../contabilidadeEstatistica.module.service';
import {ENTITY_NAME_PERIODOS, IPeriodosEntityService} from '../../../../../entities/periodos/periodos.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {EPcaContabilidadeServiceOptionsDefaultYear, IPcaContabilidadeServiceRefreshedEvent} from '../../../../portalclientaccounts/contabilidade/pca.contabilidade.module.service.interface';
import {IReportInstance} from '../../../../../components/reports/input/reports.input.component.interface';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {ETipoContaContabilidade} from '../../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {IBalancetesFilters, IContabilidadeBalancetesParams} from '../contabilidade.balancetes.module.interface';
import {IJsonBalanceteAno, IJsonBalanceteCompl} from '../jsonContabilidade.balancetes.module.interface';
import {IJsonPeriodo} from '../../../../../entities/periodos/jsonPeriodo.entity.interface';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {PcaContabilidadeService} from '../../../../portalclientaccounts/contabilidade/pca.contabilidade.module.service';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';
import {CGCardPanelComponent} from '../../../../../components/cg/cardpanel/cardpanel.component';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {PlanosContasAlternativosCuboModalComponent} from '../../../../../entities/planoscontasalternativos/modals/cubo/planosContasAlternativos.cubo.modal.component';
import {IApiQueryResponse} from '../../../../../services/api/api.service.interface';

const BTN_PROCESS_ID = 'processar';
const BTN_PROCESSGRID_ID = 'processarGrelha';
const BTN_CUBO = 'cuboPCA';

@Component({
  selector: 'contabilidade-balancete',
  templateUrl: './contabilidade.balancetes.module.component.html'
})
export class ContabilidadeBalancetesComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public altMode: boolean;

  public readonly outputPeriodo: string;
  public readonly filterPocs: string;
  public readonly params: IContabilidadeBalancetesParams;

  public titleTableEmpresa: string;
  public filterPeriodos: string;
  public usaAnaliticaConfig: boolean;
  public nEmpresa: string;
  public nomeEmpresa: string;
  public balanceteComplList: Array<IJsonBalanceteCompl>;
  public isTableView: boolean;
  public anosSource: Array<number>;
  public filters: IBalancetesFilters;
  public report: IJsonReport;
  public reportType: EReport;
  public pdfUrl: string;

  private readonly _subscriptionOnRefresh: Subscription;
  private readonly _entityPeriodos: IPeriodosEntityService;
  private readonly _btnProcess: IPlToolbarItem;
  private readonly _btnProcessGrid: IPlToolbarItem;
  private readonly _isPeriodosFromParams: boolean;
  private readonly _isPeriodosFromParamsAi: boolean;
  private _periodos: Array<IJsonPeriodo>;
  private _reportInstance: IReportInstance;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _contabilidadeEstatisticaService: ContabilidadeEstatisticaService,
    private readonly _plAlertService: PlAlertService,
    private readonly _pcaContabilidadeService: PcaContabilidadeService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.params = <IContabilidadeBalancetesParams>this._transition.params();
    this._isPeriodosFromParams = !isEmpty(this.params.dePeriodo) || !isEmpty(this.params.periodoAte);
    this._isPeriodosFromParamsAi = !isEmpty(this.params.date);
    this.outputPeriodo = '{{periodo}} - {{nome}}';
    this.filterPocs = `tipo=${ETipoContaContabilidade.Movimento}`;
    this.anosSource = [];
    this.report = {title: '', name: ''};
    this.balanceteComplList = [];
    this.isTableView = true;
    this._entityPeriodos = this._entityServiceBuilder.build<IJsonPeriodo, IPeriodosEntityService>(ENTITY_NAME_PERIODOS);
    this._periodos = [];
    this._subscriptionOnRefresh = this._pcaContabilidadeService.onRefresh().subscribe((event: IPcaContabilidadeServiceRefreshedEvent) => {
      this._onNotify(event);
    });
    this._btnProcess = {
      id: BTN_PROCESS_ID,
      order: 3,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-gear"></i>',
      class: 'btn-primary',
      caption: 'balancetes.btn.processar',
      disabled: true,
      click: () => {
        this._processar();
      }
    };
    this._btnProcessGrid = {
      id: BTN_PROCESSGRID_ID,
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-table"></i>',
      class: 'btn-success',
      caption: 'balancetes.btn.processargrelha',
      disabled: true,
      click: () => this._processarGrelha()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnProcess);
    this.toolbar.addButton(this._btnProcessGrid);
    this.filters = {
      anosComparativos: [],
      deConta: !isEmpty(this.params.deConta) ? this.params.deConta : '1',
      ateConta: !isEmpty(this.params.contaAte) ? this.params.contaAte : this.altMode ? '99999999' : '89999999',
      dePeriodo: !isEmpty(this.params.dePeriodo) ? this.params.dePeriodo : '',
      dePeriodoNome: '',
      atePeriodo: !isEmpty(this.params.periodoAte) ? this.params.periodoAte : 'ZZZZZZZ',
      atePeriodoNome: '',
      contasClasse: true,
      contasCorrentes: !this.altMode,
      contasMovimento: true,
      contasRazao: true,
      contasSubTotal: true,
      escodeContasSaldoZero: false,
      loadAllAnos: 1,
      loadOnlyAno: 0,
      dePeriodoAno: '',
      deCCusto: '',
      ateCCusto: 'ZZZZ',
      calculaSaldosIniciaisAnoNMaisUm: false,
      pocAltCabID: '',
      nomePocAlt: '',
      escondeRubricasSemMovimento: false
    };
    if (this.altMode) {
      this.toolbar.addButton({
        id: BTN_CUBO,
        order: 5,
        type: 'button',
        iconLeft: '<i class="fa fa-fw fa-cubes"></i>',
        class: 'btn-primary',
        caption: 'planoscontasalternativos.modals.cubo.btn',
        click: () => {
          this._openCuboPlanoContasAlt();
        }
      });
    }
    this.titleTableEmpresa = this._translateService.instant('balancetes.modal.table.empresa', {nempresa: this.nEmpresa, descempresa: this.nomeEmpresa});

    Promise.all([this._loadReport(), this._loadPeriodos(this.configurations.empresa.anoEmCursoIRC)]).finally(async () => {
      if (!this._isPeriodosFromParamsAi) {
        this.filtersChanged();
      } else {
        await this._processarGrelha();
      }
    });

    this._pcaContabilidadeService
      .init(this.toolbar, {
        showDropdownPeriodos: false,
        showCaptionEncerrado: true,
        defaultYear: this.params.fromModelo22 ? EPcaContabilidadeServiceOptionsDefaultYear.AnoCursoIRC : EPcaContabilidadeServiceOptionsDefaultYear.First
      })
      .then(() => {
        this.anosSource = this._pcaContabilidadeService.anos.map<number>(({ano}: IJsonBalanceteAno) => ano);
      });
    this._checkPeriodosAndEnableBtnsProcessar();
    if (!isEmpty(this.params.deConta) && !isEmpty(this.params.contaAte) && !this._btnProcess.disabled) {
      this._processarGrelha();
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionOnRefresh.unsubscribe();
  }

  public changedReport(value: IJsonReport): void {
    this.report = value;
    this.filtersChanged();
  }

  public changedDePeriodo(value: string): void {
    this.filters.dePeriodo = value;
    this.filters.dePeriodoNome = this._getDescriptionFromPeriodo(this.filters.dePeriodo);
    this.filters = {...this.filters};
    if (this.filters.dePeriodo && this.filters.atePeriodo) {
      this._checkPeriodos();
    }
    this.filtersChanged();
  }

  public changedAtePeriodo(value: string): void {
    this.filters.atePeriodo = value;
    const periodoDe: number = toInteger(this.filters.dePeriodo);
    const periodoAte: number = toInteger(this.filters.atePeriodo);
    if (periodoAte < periodoDe) {
      this.filters.dePeriodo = value;
    }
    this.filters.dePeriodoNome = this._getDescriptionFromPeriodo(this.filters.dePeriodo);
    this.filters.atePeriodoNome = this._getDescriptionFromPeriodo(this.filters.atePeriodo);
    this.filters = {...this.filters};
    if (this.filters.dePeriodo && this.filters.atePeriodo) {
      this._checkPeriodos();
    }
    this.filtersChanged();
  }

  public anosCompararChanged(anosComparativos: Array<number>): Promise<void> {
    this.filters.anosComparativos = anosComparativos;
    let promise: Promise<unknown>;
    if ((this.filters.anosComparativos.length && this.reportType !== EReport.BalancetesComparativo) || (!this.filters.anosComparativos.length && this.reportType !== EReport.BalancetesNormal)) {
      promise = this._loadReport();
    }
    return Promise.resolve(promise).then(() => {
      this.filtersChanged();
    });
  }

  public filtersChanged(): void {
    if (!this.filters.contasMovimento) {
      this.filters.contasCorrentes = false;
    }
    if (this.filters.calculaSaldosIniciaisAnoNMaisUm) {
      this.filters.dePeriodo = `${this.configurations.empresa.anoEmCursoIRC + 1}010`;
      this.filters.anosComparativos = [];
      this.filters = {...this.filters};
    }
    this._checkPeriodosAndEnableBtnsProcessar();
  }

  public readonly fnKeydownProcessar = (value: string, event: KeyboardEvent): void => {
    this._keydownProcessar(event);
  };

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  protected _onConfigurationsChanged(): void {
    this.usaAnaliticaConfig = this.configurations.empresa.usaConfigAnalitica;
    this.nEmpresa = this.configurations.empresa.nEmpresa;
    this.nomeEmpresa = this.configurations.empresa.nome;
  }

  private _onNotify({ano}: IPcaContabilidadeServiceRefreshedEvent): void {
    this._evaluateFilterPeriodos(ano.ano);
    this._loadPeriodos(ano.ano);
  }

  private _evaluateFilterPeriodos(ano: number): void {
    let filterDePeriodo = `periodo=%${ano}%`;
    if (ano === this.configurations.empresa.anoEmCursoIRC) {
      filterDePeriodo += `|periodo=%${ano + 1}%`;
    } else if (ano === this.configurations.empresa.anoEmCursoIRC + 1) {
      filterDePeriodo += `|periodo=%${this.configurations.empresa.anoEmCursoIRC}%`;
    } else {
      filterDePeriodo = `loadAllAnos=1&(${filterDePeriodo})`;
    }
    this.filterPeriodos = filterDePeriodo;
  }

  private _loadReport(): Promise<void> {
    this.reportType = !this.filters.anosComparativos.length ? EReport.BalancetesNormal : EReport.BalancetesComparativo;
    this._reportInstance = this._reportsRegistryService.get(this.reportType);
    return this._reportInstance.query().then((reports: Array<IJsonReport>) => {
      if (reports.length) {
        const reportName: string = this.configurations.contabilidade.balancetes.reportImpressao;
        this.report = this.reportType === EReport.BalancetesNormal ? reports.find((report: IJsonReport) => report.name === reportName) || reports[0] : reports[0];
      }
    });
  }

  private async _loadPeriodos(ano: number): Promise<void> {
    const response: HttpResponse<IApiQueryResponse<IJsonPeriodo>> = await this._entityPeriodos.query({pesquisa: this.filterPeriodos});
    this._periodos = response.body.list;
    if (this._periodos.length) {
      if (this._isPeriodosFromParamsAi) {
        const periodoFromDate: HttpResponse<IJsonPeriodo> = await this._entityPeriodos.obterPeriodoFromDate(this.params.date);
        this.filters.atePeriodo = periodoFromDate.body.periodo;
        this.filters.dePeriodo = this._periodos[0].periodo;
      }
      if (this._isPeriodosFromParams || this._isPeriodosFromParamsAi) {
        this.filters.dePeriodoNome = this._getDescriptionFromPeriodo(this.filters.dePeriodo);
        this.filters.atePeriodoNome = this._getDescriptionFromPeriodo(this.filters.atePeriodo);
      } else {
        this.filters.dePeriodo = this._periodos[0].periodo;
        this.filters.dePeriodoNome = this._periodos[0].nome;
        if (ano === this.configurations.empresa.anoEmCursoIRC) {
          const index: number = findLastIndex(this._periodos, (item: IJsonPeriodo) => item.periodo.startsWith(String(ano)));
          if (index > -1) {
            this.filters.atePeriodo = this._periodos[index].periodo;
            this.filters.atePeriodoNome = this._periodos[index].nome;
          }
        } else {
          this.filters.atePeriodo = this._periodos[this._periodos.length - 1].periodo;
          this.filters.atePeriodoNome = this._periodos[this._periodos.length - 1].nome;
        }
      }
      this.changedDePeriodo(this.filters.dePeriodo);
    }
  }

  private _processar(): void {
    const anos = isArray(this.filters.anosComparativos) ? this.filters.anosComparativos : isObject(this.filters.anosComparativos) ? this.filters.anosComparativos : [];

    if (this.altMode && isEmpty(this.filters.pocAltCabID)) {
      this._plAlertService.error('planoscontasalternativos.messages.notSelectPlanoContAlt');
      return;
    }

    if (this.filters.atePeriodo < this.filters.dePeriodo) {
      this._plAlertService.error('docscontabilidade.erros.periodoFinalInferiorInicial');
      return;
    }

    if (!this.filters.contasMovimento && !this.filters.contasClasse && !this.filters.contasRazao && !this.filters.contasSubTotal && !this.filters.contasCorrentes) {
      this._plAlertService.error('balancetes.naoTemTipoConta');
      return;
    }

    this._contabilidadeEstatisticaService
      .getBalancetesUrl(
        this.filters.dePeriodo,
        this.filters.atePeriodo,
        this.filters.deConta,
        this.filters.ateConta,
        this.filters.contasRazao,
        this.filters.contasClasse,
        this.filters.contasSubTotal,
        this.filters.contasMovimento,
        this.filters.contasCorrentes,
        this.filters.escodeContasSaldoZero,
        this.report.name,
        anos,
        0,
        this.filters.deCCusto,
        this.filters.ateCCusto,
        this.filters.calculaSaldosIniciaisAnoNMaisUm,
        this.filters.escondeRubricasSemMovimento,
        this.filters.pocAltCabID
      )
      .subscribe((url: string) => {
        this.pdfUrl = url;
      });

    this.isTableView = false;
    this._cardPanel.collapse();
  }

  private _processarGrelha(): Promise<void> {
    if (this.altMode && isEmpty(this.filters.pocAltCabID)) {
      this._plAlertService.error('planoscontasalternativos.messages.notSelectPlanoContAlt');
      return Promise.resolve();
    }

    if (this.filters.atePeriodo < this.filters.dePeriodo) {
      this._plAlertService.error('docscontabilidade.erros.periodoFinalInferiorInicial');
      return Promise.resolve();
    }

    if (!this.filters.contasMovimento && !this.filters.contasClasse && !this.filters.contasRazao && !this.filters.contasSubTotal && !this.filters.contasCorrentes) {
      this._plAlertService.error('balancetes.naoTemTipoConta');
      return Promise.resolve();
    }

    this.balanceteComplList = [];
    const anos: string = this.filters.anosComparativos.filter((ano: number) => ano).join(',');
    let promise: Promise<void>;
    promise = this._contabilidadeEstatisticaService
      .getBalancetesGrid(
        this.filters.dePeriodo,
        this.filters.atePeriodo,
        this.filters.deConta,
        this.filters.ateConta,
        this.filters.contasRazao,
        this.filters.contasClasse,
        this.filters.contasSubTotal,
        this.filters.contasMovimento,
        this.filters.contasCorrentes,
        this.filters.escodeContasSaldoZero,
        anos,
        this.filters.deCCusto,
        this.filters.ateCCusto,
        '',
        this.filters.calculaSaldosIniciaisAnoNMaisUm,
        this.filters.escondeRubricasSemMovimento,
        this.filters.pocAltCabID
      )
      .then((response: HttpResponse<Array<IJsonBalanceteCompl>>) => {
        this.balanceteComplList = response.body;
        this.isTableView = true;
        if (this.balanceteComplList?.length > 0) {
          this._cardPanel.collapse();
        } else {
          this._plAlertService.info('balancetes.messages.withoutData');
        }
      })
      .finally(() => {
        promise = undefined;
      });
    this._btnProcess.promise = this._btnProcessGrid.promise = promise;
    return promise;
  }

  private _getDescriptionFromPeriodo(periodo: string): string {
    const item: IJsonPeriodo = this._periodos.find((periodoItem: IJsonPeriodo) => periodoItem.periodo === periodo);
    return item?.nome;
  }

  private _checkPeriodos(): Promise<unknown> {
    if (this.filters.dePeriodo && this.filters.atePeriodo) {
      return this._entityPeriodos.obterAnoDosPeriodos(this.filters.dePeriodo, this.filters.atePeriodo);
    }
    return Promise.resolve();
  }

  private _keydownProcessar(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.preventDefault();
      event.stopPropagation();
      this.toolbar.focusItem(BTN_PROCESS_ID);
    }
  }

  private _openCuboPlanoContasAlt(): void {
    const modalInstance = this._cgModalService.showVanilla(PlanosContasAlternativosCuboModalComponent, {size: 'fullscreen'});
    const componentInstance: PlanosContasAlternativosCuboModalComponent = modalInstance.componentInstance;
    componentInstance.filters = {
      dePeriodo: this.filters.dePeriodo,
      atePeriodo: this.filters.atePeriodo,
      deConta: this.filters.deConta,
      ateConta: this.filters.ateConta,
      pocAltCabID: this.filters.pocAltCabID,
      nomePocAlt: this.filters.nomePocAlt,
      escondeRubricasSemMovimento: this.filters.escondeRubricasSemMovimento
    };
  }

  private _checkPeriodosAndEnableBtnsProcessar(): void {
    if (this.filters.dePeriodo && this.filters.atePeriodo) {
      this._btnProcess.disabled = this._btnProcessGrid.disabled = false;
    }
  }
}
