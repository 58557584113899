<div class="acrescimosencargosferias-resumoanual-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'acrescimosencargosferias.modals.resumoAnual.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-split-view [initialSizeLeft]="40" [initialSizeRight]="60">
      <div *plSplitViewLeftSide>
        <fieldset>
          <legend [translate]="'acrescimosencargosferias.modals.resumoAnual.totaisAnual'"></legend>

          <div class="entity-detail-form">
            <pl-group>
              <label [translate]="'acrescimosencargosferias.modals.resumoAnual.acertoManual'"></label>
              <edit>
                <div class="form-control-align">{{ resumoAnual.valorAcertoManual | cgcDecimal }}</div>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'acrescimosencargosferias.modals.resumoAnual.subferias'"></label>
              <edit>
                <div class="form-control-align">{{ resumoAnual.valorSubFerias | cgcDecimal }}</div>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'acrescimosencargosferias.modals.resumoAnual.ferias'"></label>
              <edit>
                <div class="form-control-align">{{ resumoAnual.valorFerias | cgcDecimal }}</div>
              </edit>
            </pl-group>
          </div>
        </fieldset>

        <fieldset>
          <legend [translate]="'acrescimosencargosferias.modals.resumoAnual.encargos'"></legend>

          <div class="entity-detail-form">
            <pl-group>
              <label [translate]="'acrescimosencargosferias.modals.resumoAnual.encSegSocial'"></label>
              <edit>
                <div class="form-control-align">{{ resumoAnual.valorEncargosSS | cgcDecimal }}</div>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'acrescimosencargosferias.modals.resumoAnual.encAcTrab'"></label>
              <edit>
                <div class="form-control-align">{{ resumoAnual.valorEncargoAcTrab | cgcDecimal }}</div>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'acrescimosencargosferias.modals.resumoAnual.outrosEnc'"></label>
              <edit>
                <div class="form-control-align">{{ resumoAnual.valorOutrosEncargos | cgcDecimal }}</div>
              </edit>
            </pl-group>
          </div>
        </fieldset>

        <fieldset>
          <legend [translate]="'acrescimosencargosferias.modals.resumoAnual.total'"></legend>

          <div class="entity-detail-form">
            <pl-group>
              <label [translate]="'acrescimosencargosferias.modals.resumoAnual.custosEmp'"></label>
              <edit>
                <div class="form-control-align">{{ resumoAnual.total | cgcDecimal }}</div>
              </edit>
            </pl-group>
          </div>
        </fieldset>
      </div>

      <div *plSplitViewRightSide>
        <dx-chart [cgDxChart]="chartResumoAnual" [dataSource]="resumoAnualChart">
          <div *dxTemplate="let item of 'customTooltip'" class="customTooltip">
            <b>{{ item.valueText }}</b>
          </div>
        </dx-chart>
      </div>
    </pl-split-view>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light btn-sm action-dismiss" [disabled]="closeDisabled" (evtClicked)="dismiss()"
      ><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span>
    </pl-button>
  </div>
</div>
