<div class="gestao-dgemps-year-view">
  <div class="gestao-dgemps-year-view-content">
    <div class="gestao-dgemps-year-view-content-employees" *ngIf="manager">
      <div class="gestao-dgemps-year-view-content-employees-table">
        <dx-data-grid
          [cgDxDataGrid]="dataGridColaboradores"
          [cgDxDataGridColumns]="dataGridColaboradores.columns"
          cgDxDataGridInstanceName="rhmarcacoescolaboradores"
          [dataSource]="colaboradores"
          [selectedRowKeys]="selectedColaboradoresKeys"
          (onInitialized)="onDataGridColaboradoresInitialized($event)"
          (onCellPrepared)="onDataGridColaboradoresCellPrepared($event)"
          (onCellClick)="onDataGridColaboradoresCellClick($event)"
          (onSelectionChanged)="onDataGridColaboradoresSelectionChanged($event)">
          <div *dxTemplate="let cell of 'templateNomeEmp'" [attr.title]="cell.data.nomeCompleto">{{ cell.data.nome }}</div>
        </dx-data-grid>
      </div>
    </div>

    <div class="gestao-dgemps-year-view-content-calendar">
      <div class="table-responsive">
        <pl-calendar-year-view
          [events]="yearEvents"
          [viewDate]="viewDate"
          [holidays]="false"
          [holidaysDates]="holidaysDates"
          [allowSelectHolidays]="type !== types.Ferias"
          [rangeSelect]="true"
          [datasetDetail]="true"
          [(datasetDetailActive)]="detailActive"
          [(selectedRange)]="yearSelectedRange"
          [templateTitle]="templateTitle"
          [templateDay]="type === types.Faltas ? templateDay : undefined"
          [templateDayDetail]="templateDayDetail"
          (evtBeforeViewRender)="beforeViewRender($event)"
          (evtDayClick)="onYearDayClick($event)"
          (evtRangeSelect)="onYearRangeSelect($event)"
          (evtSelectionChanged)="onYearSelectionChanged($event)">
        </pl-calendar-year-view>
      </div>
    </div>
  </div>
</div>

<ng-template #templateTitle>
  <div class="gestao-dgemps-view-title">
    <gestao-dgemps-view-date [view]="views.Year" [viewDate]="viewDate" (viewDateChange)="setViewDate($event)" [format]="'yyyy'"> </gestao-dgemps-view-date>

    <div class="gestao-dgemps-view-title-marcacoes-ferias" *ngIf="colaboradorMarcarFerias && feriasEmpregado">({{ feriasEmpregado }})</div>
  </div>
</ng-template>

<ng-template #templateDay let-day="day">
  <div class="gestao-dgemps-day">
    <span>{{ day.label }}</span>
    <i class="fa fa-file gestao-dgemps-day-has-attachment" aria-hidden="true" *ngIf="yearEventsWithAttachment.has(day)"></i>
  </div>
</ng-template>

<ng-template #templateDayDetail>
  <gestao-dgemps-events-detail
    [type]="type"
    [manager]="manager"
    [codEmpLanca]="codEmpLanca"
    [start]="detailStart"
    [end]="detailEnd"
    [events]="detailEvents"
    [working]="loading"
    (evtRefresh)="onYearDetailRefresh()"
    (evtAddEvent)="onDetailAddEvent($event)"
    (evtCloseDetail)="hideEventsDetail()">
  </gestao-dgemps-events-detail>
</ng-template>
