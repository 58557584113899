<div
  *ngIf="!options.readonly"
  class="input-form"
  [ngClass]="{'input-group pl-input-group': options.raw === false && (hasGroup || showClear), 'input-focus': inputFocused, 'input-disabled': inputElement.disabled}"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()">
  <select
    #inputElement
    class="form-control form-select"
    autocapitalize="off"
    autocomplete="off"
    editEventsHandlerIgnore="focus,blur"
    [formControl]="formControl"
    [compareWith]="fnCompareItems"
    [attr.name]="attrName"
    [attr.placeholder]="placeholder"
    [attr.size]="options.size"
    [ngClass]="inputClass"
    [required]="validate && options.validators.required?.value"
    [editEventsHandler]="options.events"
    [editEventsHandlerValue]="value"
    (focus)="onInputFocus($event)"
    (blur)="onInputBlur($event)">
    <ng-container *ngIf="!hasGroups">
      <option *ngFor="let option of itemSourceOptions" [ngValue]="option.value" [disabled]="option.disabled" [plTranslate]="option.rowTemplate"></option>
    </ng-container>

    <ng-container *ngIf="hasGroups">
      <optgroup *ngFor="let optionGroup of itemSourceGroups" [attr.label]="optionGroup.label" [disabled]="optionGroup.disabled">
        <option *ngFor="let option of optionGroup.options" [ngValue]="option.value" [disabled]="option.disabled" [plTranslate]="option.rowTemplate"></option>
      </optgroup>
    </ng-container>
  </select>
  <button *ngIf="showClear" type="button" class="btn hollow-form" tabindex="-1" [ngClass]="{'collapse-size': !isMouseIn}" (click)="clearViewValue()">
    <i class="pl-icon-close" aria-hidden="true"></i>
  </button>
  <ng-container *ngIf="hasGroup && options.raw !== true">
    <button
      *ngFor="let itemGroup of itemGroups"
      #buttonElement
      type="button"
      class="btn"
      plDelegateEventDownwards
      [ngClass]="itemGroup.klass"
      [class.raw]="options.raw || itemGroup.raw"
      [disabled]="itemGroup.disabled ?? options.disabled ?? false"
      [attr.tabindex]="itemGroup.tabindex"
      [attr.title]="itemGroup.title"
      [plPromise]="itemGroup.promise">
      <ng-container *ngTemplateOutlet="itemGroup.templateRef; context: {button: buttonElement, isMouseIn: isMouseIn}"></ng-container>
    </button>
  </ng-container>
</div>

<pl-messages *ngIf="validate" [instance]="self" [formControlInstance]="formControl" [modelValue]="value" [validate]="validate" [validators]="validators" [ngFormInstance]="ngFormInstance">
</pl-messages>

<span *ngIf="options.readonly" class="form-control-align">{{ output }}</span>
