<div class="mapa-exploracao-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'mapaexploracao.modal.config.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'mapaexploracao.modal.config.label.tipoarredondamento'"></label>
      <edit>
        <pl-edit type="radiogroup" attrName="estado" [model]="tipoArredondamento" (modelChange)="tipoArredondamento = $event" [properties]="radioGroupTemplateTipoArredondamento"> </pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span> </pl-button>

    <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
