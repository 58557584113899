import {Component, Injector} from '@angular/core';
import {IBlockedPluginStateParams} from '../blockedPlugin.module.interface';
import {ModuloComponent} from '../../../components/module/module.component';
import {ROLE} from '../../../services/role.const';
import {isArray} from 'pl-comps-angular';

@Component({
  selector: 'blocked-plugin-state',
  templateUrl: './blockedPlugin.module.component.html'
})
export class BlockedPluginModuleComponent extends ModuloComponent {
  public pluginRoles: Array<ROLE>;
  public requiredRoles: Array<ROLE>;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    const {pageTitle, pluginRoles, requiredRoles}: IBlockedPluginStateParams = <IBlockedPluginStateParams>this._transition.params();
    this.setCaption(pageTitle);
    this.requiredRoles = isArray(requiredRoles) ? requiredRoles : [];
    this.pluginRoles = isArray(pluginRoles) ? pluginRoles : [];
  }
}
