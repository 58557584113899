import {Component, Injector, Input, OnInit} from '@angular/core';
import {PreviTesService} from '../../previTes.module.service';
import {HttpResponse} from '@angular/common/http';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IPlDynamicVisualsSecondaryClickAction, isDefinedNotNull, isObject, PlAlertService} from 'pl-comps-angular';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {IJsonPreviTesAuto, IJsonPreviTesAutoHeader} from '../../../../../entities/tesrubrica/jsonTesRubrica.entity.interface';
import {EClientesFornecedoresPrevisAuto, EPrevisAutoLegendColors, RADIO_GROUP_PREVI_TES_AUTO, TABLE_LEGEND_PREVIS_AUTO} from '../../previTes.module.interface';
import {IDevExpressDataGrid, IDevExpressDataGridSummaryCalculateCustomSummaryOptions} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IRadioGroup} from '../../../../../../common/interfaces/interfaces';
import {TDate} from '../../../../../../common/dates';
import {IEntityMaintenanceInstance} from '../../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {DevExpressDataGridUIService} from '../../../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {ENTITY_NAME_DOCS_CONTABILIDADE} from '../../../../portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {EntityMaintenanceService} from '../../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnContextMenuPreparing,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowUpdated,
  IDevExpressDataGridEventOnSelectionChanged,
  IDevExpressDataGridEventOnToolbarPreparing
} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {PreviTesAutoCodRubricaModalComponent} from '../modals/codrubrica/previTesAuto.codRubrica.modal.component';
import {PreviTesAutoDataTesModalComponent} from '../modals/datates/previTesAuto.dataTes.modal.component';
import {TTableLegend} from '../../../../../components/tablelegend/tablelegend.component.interface';

@Component({
  selector: 'module-previ-tes-auto',
  templateUrl: './previTesAuto.module.component.html'
})
export class PreviTesAutoModuleComponent extends ModuloComponent implements OnInit {
  @Input() public readonly previTesAutoHeader: IJsonPreviTesAutoHeader;

  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonPreviTesAuto, string>;
  public readonly radioGroupTemplateClienteFornecedor: IRadioGroup<EClientesFornecedoresPrevisAuto>;
  public selectedKeys: Array<string>;
  public previTesAutoList: Array<IJsonPreviTesAuto>;
  public clientFornecedor: EClientesFornecedoresPrevisAuto;
  public contaDe: string;
  public contaAte: string;
  public dataTesDe: TDate;
  public dataTesAte: TDate;
  public autoSelectAllLinesOfDocument: boolean;
  public tableLegend: TTableLegend;

  private readonly _maintenanceInstanceDocsContab: IEntityMaintenanceInstance;
  private _dataGridInstance: dxDataGrid<IJsonPreviTesAuto, string>;
  private _isAutoSelection: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _previTesService: PreviTesService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._maintenanceInstanceDocsContab = this._entityMaintenanceService.build(ENTITY_NAME_DOCS_CONTABILIDADE);
    this.radioGroupTemplateClienteFornecedor = RADIO_GROUP_PREVI_TES_AUTO;
    this.previTesAutoList = [];
    this.selectedKeys = [];
    this.autoSelectAllLinesOfDocument = false;
    this._isAutoSelection = false;
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'prevites.automatica.fields.nConta', allowEditing: false},
        {dataField: 'nome', dataType: 'string', caption: 'prevites.automatica.fields.nome', width: 200, allowEditing: false},
        {dataField: 'nDocString', dataType: 'string', caption: 'prevites.automatica.fields.nDocString', allowEditing: false},
        {dataField: 'nDocExterno', dataType: 'string', caption: 'prevites.automatica.fields.nDocExterno', allowEditing: false},
        {dataField: 'descricao', dataType: 'string', caption: 'prevites.automatica.fields.descricao', width: 200, allowEditing: false},
        {dataField: 'debito', dataType: 'double', caption: 'prevites.automatica.fields.debito', allowEditing: false},
        {dataField: 'credito', dataType: 'double', caption: 'prevites.automatica.fields.credito', allowEditing: false},
        {dataField: 'porPagar', dataType: 'double', caption: 'prevites.automatica.fields.porPagar', allowEditing: false},
        {dataField: 'dataDocExt', dataType: 'date', caption: 'prevites.automatica.fields.dataDocExt', allowEditing: false},
        {dataField: 'dataVencimento', dataType: 'date', caption: 'prevites.automatica.fields.dataVencimento', allowEditing: false},
        {dataField: 'dataTes', dataType: 'date', caption: 'prevites.automatica.fields.dataTes', allowEditing: true, fixed: true, fixedPosition: 'right'}
      ],
      summary: {
        totalItems: [
          {
            name: 'valorTotalSelecionado',
            showInColumn: 'porPagar',
            displayFormat: `${<string>this._translateService.instant('prevites.automatica.valselecionado')} {0}`,
            valueFormat: 'double',
            summaryType: 'custom'
          }
        ],
        calculateCustomSummary: this._calculateCustomSummary.bind(this)
      },
      dataSource: [],
      editing: {mode: 'cell', startEditAction: 'click', selectTextOnEditStart: true, allowUpdating: true},
      export: {filename: 'global.menu.previtesauto'},
      height: '60vh',
      pager: {visible: false},
      paging: {enabled: false, pageSize: 100},
      scrolling: {columnRenderingMode: 'virtual', rowRenderingMode: 'virtual'},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      keyExpr: 'nLanc',
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'pesquisar',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-search"></i>',
      class: 'btn-primary',
      caption: 'global.btn.search',
      click: () => this._pesquisar()
    });
    this.toolbar.addButton({
      id: 'processar',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-bolt fa-fw"></i>',
      class: 'btn-success',
      caption: 'global.btn.process',
      click: () => this._modalProcessaPreviTes()
    });
    this.clientFornecedor = EClientesFornecedoresPrevisAuto.Cliente;
    this.contaDe = this.previTesAutoHeader.contaDeCliente;
    this.contaAte = this.previTesAutoHeader.contaAteCliente;
    this.dataTesDe = this.previTesAutoHeader.dataTesDe;
    this.dataTesAte = this.previTesAutoHeader.dataTesAte;
    this.tableLegend = TABLE_LEGEND_PREVIS_AUTO;
  }

  public async onSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<IJsonPreviTesAuto, string>): Promise<void> {
    if (!this._isAutoSelection && this.autoSelectAllLinesOfDocument && event.currentSelectedRowKeys.length && event.currentSelectedRowKeys.length !== this.previTesAutoList.length) {
      const previTesAutoItem: IJsonPreviTesAuto = this.previTesAutoList.find((item: IJsonPreviTesAuto) => item.nLanc === event.currentSelectedRowKeys[0]);
      if (isDefinedNotNull(previTesAutoItem)) {
        const keysOfLinesOfDocument: Array<string> = this.previTesAutoList
          .filter((item: IJsonPreviTesAuto) => item.extPocCabID === previTesAutoItem.extPocCabID)
          .map((item: IJsonPreviTesAuto) => item.nLanc);
        if (keysOfLinesOfDocument.length) {
          this._isAutoSelection = true;
          this._dataGridInstance.selectRows(keysOfLinesOfDocument, true).then(() => {
            this._isAutoSelection = false;
          });
        }
      }
    }

    await this._dataGridInstance.refresh(true);
  }

  public clifornChanged(): void {
    this.contaDe = this.clientFornecedor === EClientesFornecedoresPrevisAuto.Cliente ? this.previTesAutoHeader.contaDeCliente : this.previTesAutoHeader.contaDeFornecedor;
    this.contaAte = this.clientFornecedor === EClientesFornecedoresPrevisAuto.Cliente ? this.previTesAutoHeader.contaAteCliente : this.previTesAutoHeader.contaAteFornecedor;
    this.dataTesDe = this.previTesAutoHeader.dataTesDe;
    this.dataTesAte = this.previTesAutoHeader.dataTesAte;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonPreviTesAuto, string>): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this.dataGridInstance.endCustomLoading();
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonPreviTesAuto>): void {
    if (event.rowType === 'data' && event.column.dataField === 'dataTes' && event.data.isDataTesFixed) {
      event.cellElement.classList.add(EPrevisAutoLegendColors.CorrecaoAutomatica);
    }
  }

  public onRowUpdated(event: IDevExpressDataGridEventOnRowUpdated<IJsonPreviTesAuto>): void {
    const index: number = this.previTesAutoList.findIndex((item: IJsonPreviTesAuto) => item.nLanc === event.data.nLanc);
    if (index !== -1) {
      this.previTesAutoList[index].isDataTesFixed = false;
      this.previTesAutoList[index].dataTes = event.data.dataTes;
    }
    this.dataGridDefinition.dataSource = this.previTesAutoList;
  }

  public async onContextMenuPreparing({row, event}: IDevExpressDataGridEventOnContextMenuPreparing<IJsonPreviTesAuto>): Promise<void> {
    if (isObject(row) && row.rowType === 'data' && isObject(row.data)) {
      event.preventDefault();
      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActionsGroup(row.data);
      await this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.target, actions);
    }
  }

  public onToolbarPreparing(event: IDevExpressDataGridEventOnToolbarPreparing): void {
    event.toolbarOptions.items.unshift({
      location: 'before',
      template: 'headerTemplateAutoSelection'
    });
  }

  public get dataGridInstance(): dxDataGrid {
    return this._dataGridInstance;
  }

  private async _pesquisar(): Promise<void> {
    if (this.dataGridInstance) {
      this.dataGridInstance.beginCustomLoading(undefined);
    }
    try {
      const response: HttpResponse<Array<IJsonPreviTesAuto>> = await this._previTesService.getPesquisaPrevTesAuto(this.contaDe, this.contaAte, this.dataTesDe, this.dataTesAte);
      this.previTesAutoList = response.body;
      this.dataGridDefinition.dataSource = response.body;
      this.selectedKeys = [];
    } finally {
      if (this.dataGridInstance) {
        this.dataGridInstance.endCustomLoading();
      }
    }
  }

  private async _modalProcessaPreviTes(): Promise<void> {
    if (!this.selectedKeys.length) {
      this._plAlertService.error('prevites.automatica.messages.temselecionar');
      return;
    }

    for (const selectedKey of this.selectedKeys) {
      const index: number = this.previTesAutoList.findIndex((item: IJsonPreviTesAuto) => item.nLanc === selectedKey);
      if (index !== -1) {
        this.previTesAutoList[index].seleccionado = true;
      }
    }

    const instance = this._cgModalService.showVanilla(PreviTesAutoCodRubricaModalComponent, {size: 'sm'});
    instance.componentInstance.previTesAutoList = this.previTesAutoList;
    instance.componentInstance.contaDe = this.contaDe;
    instance.componentInstance.contaAte = this.contaAte;
    instance.componentInstance.dataTesDe = this.dataTesDe;
    instance.componentInstance.dataTesAte = this.dataTesAte;

    try {
      this.previTesAutoList = await instance.result;
      for (const selectedKey of this.selectedKeys) {
        const indexSel: number = this.previTesAutoList.findIndex((item: IJsonPreviTesAuto) => item.nLanc === selectedKey);
        if (indexSel !== -1) {
          this.previTesAutoList.splice(indexSel, 1);
        }
      }
      this.selectedKeys = [];
      this.dataGridDefinition.dataSource = this.previTesAutoList;
    } catch (error) {
      console.error('Error processing PreviTes:', error);
    }
  }

  private _generateSecondaryClickActionsGroup(rowData: IJsonPreviTesAuto): Array<IPlDynamicVisualsSecondaryClickAction> {
    return [
      {
        caption: 'prevites.automatica.actions.aplicardata',
        icon: 'fa-calendar',
        click: () => this._openAplicarDataModal(rowData.nLanc)
      },
      {
        divider: true
      },
      {
        caption: 'prevites.automatica.actions.selecionaDocConta',
        icon: 'fa-check-square-o',
        click: () => this._itemsConta(rowData.nConta, true)
      },
      {
        caption: 'prevites.automatica.actions.removeSeleconta',
        icon: 'fa-square-o',
        click: () => this._itemsConta(rowData.nConta, false)
      },
      {
        divider: true
      },
      {
        caption: 'prevites.automatica.actions.verdoc',
        icon: 'fa-file-o',
        click: () => this._openDocAsModal(rowData.extPocCabID)
      }
    ];
  }

  private async _openDocAsModal(extPocCabID: string): Promise<void> {
    await this._maintenanceInstanceDocsContab.maintenanceEdit(extPocCabID);
  }

  private async _openAplicarDataModal(nLanc: string): Promise<void> {
    const instance = this._cgModalService.showVanilla(PreviTesAutoDataTesModalComponent, {size: 'sm'});
    try {
      const response: TDate = await instance.result;
      if (this.selectedKeys.findIndex((key: string) => key === nLanc) === -1) {
        await this.dataGridInstance.selectRows([nLanc], true);
      }

      for (const selectedKey of this.selectedKeys) {
        const index: number = this.previTesAutoList.findIndex((item: IJsonPreviTesAuto) => item.nLanc === selectedKey);
        if (index !== -1) {
          this.previTesAutoList[index].isDataTesFixed = false;
          this.previTesAutoList[index].dataTes = response;
        }
      }
      this.dataGridDefinition.dataSource = this.previTesAutoList;
    } catch (error) {
      console.error('Error applying data modal:', error);
    }
  }

  private _itemsConta(nConta: string, select: boolean): Promise<Array<unknown>> {
    const list: Array<string> = this.previTesAutoList.filter((item: IJsonPreviTesAuto) => item.nConta === nConta).map((value: IJsonPreviTesAuto) => value.extPocCabID);
    if (select) {
      return this.dataGridInstance.selectRows(list, true);
    }
    return this.dataGridInstance.deselectRows(list);
  }

  private _calculateCustomSummary(options: IDevExpressDataGridSummaryCalculateCustomSummaryOptions<IJsonPreviTesAuto, number>): void {
    if (options.name === 'valorTotalSelecionado') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      }
      if (options.summaryProcess === 'calculate') {
        if (this.selectedKeys.includes(options.value.nLanc)) {
          options.totalValue += options.value.porPagar;
        }
      }
    }
  }
}
