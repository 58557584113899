<div class="demfinsnc-listsaved-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'demfinsnc.modal.titlelistsaved'"></h5>
  </div>

  <div class="modal-body">
    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinition"
      cgDxDataGridInstanceName="demfinsnclistsaved"
      [dataSource]="dataGridDefinition.dataSource"
      (onInitialized)="onInitialized($event)"
      (onCellPrepared)="onCellPrepared($event)"
      (onCellClick)="onCellClick($event)"
      (onCellDblClick)="onCellDblClick($event)">
      <div *dxTemplate="let cell of 'templateToolbarListSaved'">
        <ul class="table-legend">
          <li>
            <div class="table-legend-badge bg-info"></div>
            <div class="table-legend-caption" [translate]="'demfinsnc.modal.badgecaptionsaved'"></div>
          </li>
        </ul>
      </div>

      <div *dxTemplate="let item of 'cellTemplateBtnsListSaved'">
        <pl-tooltip [config]="{text: 'global.btn.delete', tooltipClass: 'tooltip-danger', placement: 'left'}">
          <button type="button" *plTooltipContent class="btn btn-danger btn-xs" [click]="fnDeleteSavedItem(item.data)" plPromise>
            <i class="fa fa-fw fa-minus"></i>
          </button>
        </pl-tooltip>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer">
    <pl-button klass="btn-primary action-close" [onClick]="close" [disabled]="!selectedLine || disableBtns" attrName="load">
      <i class="fa fa-fw fa-cog"></i>&nbsp;<span [translate]="'global.btn.load'"></span>
    </pl-button>
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()" [disabled]="disableBtns"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
