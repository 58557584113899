import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {API_FAQS} from '../../../config/constants';
import {ApiService} from '../api/api.service';
import {ICGFaq} from './faqs.service.interface';
import {IModuleHelperLink} from '../../components/module/module.definition.interface';

@Injectable({
  providedIn: 'root'
})
export class FaqsService {
  constructor(private readonly _apiService: ApiService) {}

  public getCGFaqs(faqsName: string): Promise<Array<[ICGFaq, IModuleHelperLink]>> {
    return this._apiService.get<Array<ICGFaq>>({url: API_FAQS + faqsName, withCredentials: false}).then((response: HttpResponse<Array<ICGFaq>>) => {
      return response.body.map<[ICGFaq, IModuleHelperLink]>((cgFaq: ICGFaq) => {
        return [
          cgFaq,
          {
            caption: cgFaq.title,
            href: cgFaq.link,
            type: 'fa-question-circle'
          }
        ];
      });
    });
  }
}
