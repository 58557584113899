<div class="rhcessacontratotrabalho entity-detail-form" [plPromise]="promise">
  <pl-form>
    <pl-alert class="mb-4" *ngIf="!cessacaoContratoDefault.motivoCessacao && !cessacaoContratoDefault.situacaoCessacao" type="info" [closeable]="false">
      <span [translate]="'rhcessacontratotrabalho.infos.situacaoandmotivodefault'"></span>
      <div class="hyperlink" [translate]="'rhcessacontratotrabalho.btn.manutencaocessacontrato'" (click)="openManutencaoCessaContrato()"></div>
    </pl-alert>

    <pl-alert *ngIf="cessaContTrabForm.erro && cessaContTrabForm.codEmp" type="error" [closeable]="false">
      <span>{{ cessaContTrabForm.erro }}</span>
      <div class="hyperlink" [translate]="'rhcessacontratotrabalho.btn.dadosemp'" (click)="openEmpManutencao()"></div>
    </pl-alert>

    <pl-group>
      <label [translate]="'rhcessacontratotrabalho.fields.datacessacao'"></label>
      <edit>
        <pl-edit type="date" attrName="dataCessacao" [model]="cessaContTrabForm.dataCessacao" (modelChange)="cessaContTrabForm.dataCessacao = $event; dataChanged()" plAutoFocus></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'rhcessacontratotrabalho.fields.codEmp'"></label>
      <edit>
        <entity-autocomplete
          entity="dgempsfull"
          attrName="codEMP"
          [selectedKey]="cessaContTrabForm.codEmp"
          (selectedKeyChange)="codEmpChanged($event)"
          (evtSelectedDescriptionChanged)="cessaContTrabForm.nomeEmp = $event"
          [fieldsMap]="{nome: 'nomeEmp'}"
          [filter]="'ativo=true'"
          output="nome">
        </entity-autocomplete>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'rhcessacontratotrabalho.fields.entidade'"></label>
      <edit>
        <div class="d-flex">
          <pl-edit
            type="radio"
            class="me-3"
            attrName="entidadeEmpregador"
            [model]="cessaContTrabForm.entidade"
            (modelChange)="cessaContTrabForm.entidade = $event; clearSimulacao()"
            [properties]="{value: tipoEntidade.Empregador, label: 'rhcessacontratotrabalho.fields.radio.empregador'}">
          </pl-edit>

          <pl-edit
            type="radio"
            class="me-3"
            attrName="entidadeTrabalhador"
            [model]="cessaContTrabForm.entidade"
            (modelChange)="cessaContTrabForm.entidade = $event; clearSimulacao()"
            [properties]="{value: tipoEntidade.Trabalhador, label: 'rhcessacontratotrabalho.fields.radio.trabalhador'}">
          </pl-edit>

          <pl-edit
            type="checkbox"
            class="me-2"
            attrName="justCausa"
            [model]="cessaContTrabForm.justaCausa"
            (modelChange)="cessaContTrabForm.justaCausa = $event; clearSimulacao()"
            [properties]="{label: 'rhcessacontratotrabalho.fields.radio.justacausa'}">
          </pl-edit>

          <pl-tooltip [config]="tooltipJustaCausa">
            <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
          </pl-tooltip>
        </div>
      </edit>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'rhcessacontratotrabalho.fields.tipoContrato'"></label>
        <edit>
          <pl-edit type="tipoContratoTrab" attrName="tipoContrato" [model]="cessaContTrabForm.tipoContrato" (modelChange)="cessaContTrabForm.tipoContrato = $event; clearSimulacao()">
            <div *inputGroup="{klass: 'hollow-form'}">
              <pl-tooltip [config]="tooltipTipoContrato">
                <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
              </pl-tooltip>
            </div>
          </pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'rhcessacontratotrabalho.fields.datainicio'"></label>
        <edit>
          <pl-edit type="date" [attrName]="'dataInicio'" [model]="cessaContTrabForm.dataInicio" (modelChange)="cessaContTrabForm.dataInicio = $event; dataChanged()"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'rhcessacontratotrabalho.fields.horasSemana'"></label>
        <edit>
          <pl-edit
            type="number"
            [attrName]="'horasSemana'"
            [model]="cessaContTrabForm.horasSemana"
            (modelChange)="cessaContTrabForm.horasSemana = $event; clearSimulacao()"
            [properties]="{disabled: cessaContTrabForm.tipoContrato === tiposContratoTrab.TempoIndeterminado}">
          </pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'rhcessacontratotrabalho.fields.dataPrimeiraRenovacaoExtra'"></label>
        <edit>
          <pl-edit
            type="date"
            [attrName]="'dataPrimeiraRenovacaoExtra'"
            [model]="cessaContTrabForm.dataPrimeiraRenovacaoExtra"
            (modelChange)="cessaContTrabForm.dataPrimeiraRenovacaoExtra = $event; clearSimulacao()"
            [properties]="{disabled: cessaContTrabForm.tipoContrato === tiposContratoTrab.TempoIndeterminado}"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <div class="d-flex flex-column bd-highlight mb-2">
      <label class="me-2" [translate]="'rhcessacontratotrabalho.fields.duracao'"></label>
      <span>{{ duracaoDate }}</span>
    </div>

    <div class="rhcessacontratotrab-cards mb-3">
      <fieldset>
        <legend [translate]="'rhcessacontratotrabalho.card.retribuicao'"></legend>
        <pl-group>
          <pl-group>
            <label [translate]="'rhcessacontratotrabalho.fields.retribuicaoBase'"></label>
            <edit>
              <pl-edit type="number" [attrName]="'retribuicaoBase'" [model]="cessaContTrabForm.retribuicaoBase" (modelChange)="cessaContTrabForm.retribuicaoBase = $event; clearSimulacao()">
                <div *inputGroup="{klass: 'hollow-form'}">
                  <pl-tooltip [config]="tooltipRetribuicaoBase">
                    <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
                  </pl-tooltip>
                </div>
              </pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'rhcessacontratotrabalho.fields.diuturnidades'"></label>
            <edit>
              <pl-edit type="number" [attrName]="'diuturnidades'" [model]="cessaContTrabForm.diuturnidades" (modelChange)="cessaContTrabForm.diuturnidades = $event; clearSimulacao()"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <label [translate]="'rhcessacontratotrabalho.fields.complementos'"></label>
          <edit>
            <pl-edit type="number" [attrName]="'complementos'" [model]="cessaContTrabForm.complementos" (modelChange)="cessaContTrabForm.complementos = $event; clearSimulacao()">
              <div *inputGroup="{klass: 'hollow-form'}" class="complementosTooltipDisplay">
                <pl-tooltip [config]="tooltipExcecoes">
                  <i *plTooltipContent class="fa fa-ban text-danger" aria-hidden="true" (click)="openExcludedAbonos()" [plPromise]="promise"></i>
                </pl-tooltip>

                <pl-tooltip [config]="tooltipReCalcular">
                  <i *plTooltipContent class="fa fa-calculator text-info" aria-hidden="true" (click)="calcComplementos()" [plPromise]="promise"></i>
                </pl-tooltip>

                <pl-tooltip [config]="tooltipComplementos">
                  <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
                </pl-tooltip>
              </div>
            </pl-edit>
          </edit>
        </pl-group>
      </fieldset>

      <fieldset>
        <legend [translate]="'rhcessacontratotrabalho.card.ferias'"></legend>

        <pl-group>
          <label [translate]="'rhcessacontratotrabalho.fields.feriasGozadas'"></label>
          <edit>
            <pl-edit type="number" [attrName]="'feriasGozadas'" [model]="cessaContTrabForm.feriasGozadas" (modelChange)="cessaContTrabForm.feriasGozadas = $event; clearSimulacao()"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'rhcessacontratotrabalho.fields.subsidioFerias'"></label>
            <edit>
              <pl-edit type="number" [attrName]="'subsidioFerias'" [model]="cessaContTrabForm.subsidioFerias" (modelChange)="cessaContTrabForm.subsidioFerias = $event; clearSimulacao()"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'rhcessacontratotrabalho.fields.subSidioNatalJaRecebido'"></label>
            <edit>
              <pl-edit
                type="number"
                [attrName]="'subSidioNatalJaRecebido'"
                [model]="cessaContTrabForm.subSidioNatalJaRecebido"
                (modelChange)="cessaContTrabForm.subSidioNatalJaRecebido = $event; clearSimulacao()">
              </pl-edit>
            </edit>
          </pl-group>
        </pl-group>
      </fieldset>
    </div>

    <fieldset>
      <legend [translate]="'rhcessacontratotrabalho.card.valorescalc'"></legend>

      <pl-group>
        <edit><label [translate]="'rhcessacontratotrabalho.fields.calculado.compensacao'"></label>&nbsp;{{ cessaContTrabSimul.compensacao | cgcDecimal }}</edit>
      </pl-group>

      <div class="rhcessacontratotrab-cards mb-2">
        <fieldset>
          <legend [translate]="'rhcessacontratotrabalho.card.valoremfalta'"></legend>
          <pl-group>
            <edit>
              <span [translate]="'rhcessacontratotrabalho.fields.calculado.valorFeriasVencidas'" class="pe-2"></span>
              <span>{{ cessaContTrabSimul.valorFeriasVencidas | cgcDecimal }}</span>
            </edit>
          </pl-group>

          <pl-group>
            <edit>
              <span [translate]="'rhcessacontratotrabalho.fields.calculado.valorSubsidioFerias'" class="pe-2"></span>
              <span>{{ cessaContTrabSimul.valorSubsidioFerias | cgcDecimal }}</span>
            </edit>
          </pl-group>
        </fieldset>

        <fieldset>
          <legend [translate]="'rhcessacontratotrabalho.card.proporcionais'"></legend>
          <div class="d-flex flex-column bd-highlight">
            <div>
              <span [translate]="'rhcessacontratotrabalho.fields.calculado.feriasPropValor'" class="pe-2"></span>
              <span class="me-2">{{ cessaContTrabSimul.feriasPropValor | cgcDecimal }}</span>
              <pl-tooltip [config]="tooltipFeriasPropValor">
                <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
              </pl-tooltip>
            </div>

            <div>
              <span [translate]="'rhcessacontratotrabalho.fields.calculado.subsidioFeriasPropValor'" class="pe-2"></span>
              <span>{{ cessaContTrabSimul.subsidioFeriasPropValor | cgcDecimal }}</span>
            </div>

            <div>
              <span [translate]="'rhcessacontratotrabalho.fields.calculado.subsidioNatalPropValor'" class="pe-2"></span>
              <span>{{ cessaContTrabSimul.subsidioNatalPropValor | cgcDecimal }}</span>
            </div>
          </div>
        </fieldset>
      </div>

      <pl-group>
        <edit><label [translate]="'rhcessacontratotrabalho.fields.calculado.compensacaoTotal'"></label>&nbsp;{{ cessaContTrabSimul.compensacaoTotal | cgcDecimal }}</edit>
      </pl-group>
    </fieldset>
  </pl-form>
</div>
