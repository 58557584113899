<div class="ativoscoeficientecm entity-detail-form">
  <pl-group>
    <label [translate]="'ativoscoeficientecm.header.anoPesquisa'"></label>
    <edit>
      <pl-autocomplete [source]="metodoSource" [model]="ano" (modelChange)="ano = $event" [allowInvalid]="false"> </pl-autocomplete>
    </edit>
  </pl-group>

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="ativosCoeficienteCM"
    [dataSource]="coeficienteCMList"
    (onInitialized)="onInitialized($event)"
    (onContentReady)="onContentReady()"
    (onSelectionChanged)="onSelectionChanged()"
    [(selectedRowKeys)]="selectedRowKeys">
  </dx-data-grid>
</div>
