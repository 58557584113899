import type {Subscription} from 'rxjs';
import {Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import type {IPlLocale} from '../common/locale/locales.interface';
import type {IPlSidebarEventItemClicked, IPlSidebarMenu, IPlSidebarToShowItem, TPlSidebarFnItemClicked} from './sidebar.interface';
import {isArray, isFunction, normalizeAccents} from '../common/utilities/utilities';
import {isMobile, KEYCODES} from '../common/constants';
import {PlLocaleService} from '../common/locale/locale.service';
import {PlPageWrapperService} from '../pagewrapper/pagewrapper.service';
import {PlTranslateService} from '../translate/translate.service';

@Component({
  selector: 'pl-sidebar',
  templateUrl: './sidebar.component.html'
})
export class PlSidebarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public menus: Array<IPlSidebarMenu>;
  @Input() public itemActive: TPlSidebarFnItemClicked;
  @Input() public closeOnItemAction: boolean;
  @Input() public hideTogglerHelper: boolean;
  @Input() public itemClicked: (event: IPlSidebarEventItemClicked) => void | Promise<any>;
  @Output() public readonly evtItemClicked: EventEmitter<IPlSidebarEventItemClicked>;

  public filterValue: string;
  public filterActive: boolean;
  public placeholder: string;

  private readonly _subscriptionLocale: Subscription;
  private readonly _subscriptionToggled: Subscription;
  private readonly _subscriptionShowing: Subscription;
  private _toggled: boolean;
  private _showingSideBar: boolean;
  private _menusMap: Array<string>;
  private _toShow: Array<IPlSidebarToShowItem>;
  private _previouslyOpen: Array<string>;
  private _previousActive: IPlSidebarMenu;

  constructor(
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plLocaleService: PlLocaleService,
    private readonly _plPageWrapperService: PlPageWrapperService
  ) {
    this.evtItemClicked = new EventEmitter<IPlSidebarEventItemClicked>();
    this.filterValue = '';
    this.filterActive = false;
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this.placeholder = locale.placeholders.search;
    });
    this._subscriptionToggled = this._plPageWrapperService.toggled().subscribe((value: boolean) => {
      this._toggled = value;
    });
    this._subscriptionShowing = this._plPageWrapperService.showingSideBar().subscribe((value: boolean) => {
      this._showingSideBar = value;
    });
    this._toggled = false;
    this._showingSideBar = false;
    this._menusMap = [];
    this._toShow = [];
    this._previouslyOpen = [];
  }

  public ngOnInit(): void {
    this._initMenu();
  }

  public ngOnChanges({menus}: SimpleChanges): void {
    if (menus && !menus.isFirstChange()) {
      this._previousActive = undefined;
      this._initMenu();
      if (this.filterActive) {
        this.filterValueChanged();
      }
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionLocale.unsubscribe();
    this._subscriptionToggled.unsubscribe();
    this._subscriptionShowing.unsubscribe();
  }

  public toggleSidebar(): void {
    this._plPageWrapperService.toggleSidebar();
  }

  public async go(menu: IPlSidebarMenu, event: MouseEvent, parentMenu?: IPlSidebarMenu): Promise<void> {
    if (!menu.menu) {
      if (isFunction(menu.action)) {
        await menu.action(menu);
      }
      const itemClickedEvent: IPlSidebarEventItemClicked = {menu: menu, event: event};
      if (isFunction(this.itemClicked)) {
        await this.itemClicked(itemClickedEvent);
      }
      this.evtItemClicked.emit(itemClickedEvent);
      if (this.closeOnItemAction) {
        const hasSearchParent: Element = (<Element>event.target).closest('.pl-sidebar-list-search');
        if (!hasSearchParent) {
          if (this._showingSideBar) {
            this._plPageWrapperService.setShowingSideBar(false);
          }
          if (this._toggled && isMobile()) {
            this._plPageWrapperService.setToggled(false);
          }
        }
      }
      const isActive: boolean = this._isActive(menu, parentMenu);
      if (isActive) {
        menu.active = true;
      }
    } else {
      menu.isOpened = !menu.isOpened;
      menu.active = menu.isOpened;
      if (parentMenu) {
        parentMenu.isOpened = true;
      }
    }
  }

  public filterValueChanged(): void {
    if (!this.filterValue) {
      this.clearFilter();
      return;
    }
    if (!this.filterActive) {
      this._previouslyOpen = [];
      for (const menu of this.menus) {
        if (menu.isOpened) {
          this._previouslyOpen.push(menu.title);
        }
      }
    }
    const filterValues = this._normalize(this.filterValue).split(' ');
    this.filterActive = true;
    this._toShow = [];
    for (const menusMap of this._menusMap) {
      const map: Array<string> = menusMap.split('_');
      for (let j = 1; j < map.length; j++) {
        const mapParentValue: string = map[0];
        let mapValue: string | Array<string> = map[j];
        let valid = true;
        for (const filterValue of filterValues) {
          const filterValueIndex = this._normalizeSpecialChars(mapValue).indexOf(filterValue);
          if (filterValueIndex === -1) {
            if (mapParentValue.includes(filterValue) && filterValues.length > 1) {
              continue;
            }
            valid = false;
            break;
          }

          // Removing found word from mapValue
          if (filterValues.length > 1 && mapValue.includes(' ')) {
            mapValue = mapValue.split(' ');
            const index = mapValue.findIndex(this._findMapValue(filterValue));
            if (index !== -1) {
              mapValue.splice(index, 1);
            }
            mapValue = mapValue.join(' ');
          }
        }
        if (valid) {
          this._toShow.push({
            parent: mapParentValue,
            value: map[j]
          });
        }
      }
    }

    // Filter menus
    this._doFilter();
  }

  public clearFilter(): void {
    this.filterValue = '';
    this.filterActive = false;
    for (const menu of this.menus) {
      menu.isOpened = this._previouslyOpen.includes(menu.title);
    }
    this._doFilter();
  }

  public filterKeyUp(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ESC) {
      this.clearFilter();
    }
  }

  public mouseEnterTogglerHelper(): void {
    if (!this._showingSideBar) {
      this._plPageWrapperService.showSidebarWithDebounce();
    }
  }

  public mouseLeaveTogglerHelper(): void {
    if (!this._showingSideBar) {
      this._plPageWrapperService.cancelSetShowingSidebar();
    }
  }

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    if (this._showingSideBar) {
      this._plPageWrapperService.cancelSetShowingSidebar();
    }
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this._plPageWrapperService.hideSidebarWithDebounce();
  }

  private _normalizeSpecialChars(value: string): string {
    return value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, '');
  }

  private _normalize(value: string): string {
    return normalizeAccents(value);
  }

  private _initMenu(): void {
    if (!isArray(this.menus)) {
      return;
    }
    this._menusMap = [];
    for (const menu of this.menus) {
      menu.title = this._plTranslateService.translate(menu.title);
      menu.filterValue = this._normalize(menu.title);
      if (menu.suffix) {
        menu.suffix = this._plTranslateService.translate(menu.suffix);
        menu.filterValue += this._normalize(menu.suffix);
      }
      this._initMenuItem(menu);
      menu.active = this._isActive(menu);
    }
  }

  private _initMenuItem(menuItem: IPlSidebarMenu, parentMenu?: IPlSidebarMenu): void {
    menuItem.isFiltered = false;
    menuItem.active = this._isActive(menuItem);
    if (menuItem.menu) {
      for (const item of menuItem.menu) {
        this._initMenuItem(item, menuItem);
        item.title = this._plTranslateService.translate(item.title);
        if (menuItem.filterValue) {
          let title = item.title;
          if (item.suffix) {
            item.suffix = this._plTranslateService.translate(item.suffix);
            title += item.suffix;
          }
          menuItem.filterValue += `_${this._normalize(title)}`;
        }
      }
    } else if (!parentMenu) {
      menuItem.filterValue += `_${menuItem.filterValue}`;
    }
    if (!parentMenu) {
      this._menusMap.push(menuItem.filterValue);
    }
  }

  private _isActive(menu: IPlSidebarMenu, parentMenu?: IPlSidebarMenu): boolean {
    let active = false;
    if (isFunction(this.itemActive)) {
      active = this.itemActive(menu, parentMenu);
    }
    if (active) {
      if (this._previousActive) {
        this._previousActive.active = false;
      }
      menu.active = true;
      this._previousActive = menu;
      if (parentMenu) {
        parentMenu.isOpened = true;
      }
    } else if (isArray(menu.menu)) {
      for (const item of menu.menu) {
        active = this._isActive(item, menu);
        if (active) {
          return active;
        }
      }
    }
    return active;
  }

  private _findMapValue(filterValue: string): (value: string) => boolean {
    return (value) => value.includes(filterValue);
  }

  private _doFilter(): void {
    for (const menuItem of this.menus) {
      const hasSubItems: boolean = isArray(menuItem.menu) && menuItem.menu.length > 0;
      let isSubMenuItemFiltered = true;
      if (hasSubItems) {
        for (const subMenuItem of menuItem.menu) {
          subMenuItem.isFiltered = this._isFiltered(subMenuItem, menuItem);
          if (!subMenuItem.isFiltered) {
            isSubMenuItemFiltered = false;
            if (subMenuItem.active && !menuItem.isOpened) {
              menuItem.isOpened = true;
            }
          }
        }
      }
      menuItem.isFiltered = hasSubItems && !isSubMenuItemFiltered ? false : this._isFiltered(menuItem);
    }
  }

  private _isFiltered(menu: IPlSidebarMenu, parent?: IPlSidebarMenu): boolean {
    if (!this.filterActive) {
      return false;
    }
    const index: number = this._toShow.findIndex((itemToShow: IPlSidebarToShowItem) => {
      let title: string = this._normalize(menu.title);
      if (menu.suffix) {
        title += this._normalize(menu.suffix);
      }
      if (!parent) {
        return itemToShow.value === title || itemToShow.parent === title;
      }
      return itemToShow.value === title && itemToShow.parent === this._normalize(parent.title);
    });
    const isFiltered: boolean = index === -1;
    if (!isFiltered) {
      if (parent) {
        parent.isOpened = true;
      } else {
        menu.isOpened = true;
      }
    }
    return isFiltered;
  }
}
