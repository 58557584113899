import {DATA_SOURCE_NAME_TIPOS_PERIOCIDADE} from '../../datasources/tipoperiocidade/tipoPeriocidade.datasource.interface';
import {ENTITY_NAME_RH_TIPOS_PROCESSAMENTO} from './rhTiposProcessamento.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonRHTipoProcessamento} from './jsonRhTiposProcessamento.entity.interface';
import {RhTiposProcessamentoEditComponent} from './components/edit/rhTiposProcessamento.entity.edit.component';
import {ROLE} from '../../services/role.const';

export const ENTITY_RH_TIPOS_PROCESSAMENTO: IEntityDefinition<IJsonRHTipoProcessamento> = {
  name: ENTITY_NAME_RH_TIPOS_PROCESSAMENTO,
  roles: [ROLE.RH, ROLE.PCA],
  searchPlaceholder: 'rhtipoprocess.pesquisa',
  metadata: {
    keyName: 'tipoProcessamento',
    fields: [
      {name: 'tipoProcessamento', type: 'cginteger', width: '200px', caption: 'rhtipoprocess.fields.tipoProcessamento', validators: {required: true}},
      {name: 'descricao', caption: 'rhtipoprocess.fields.descricao', validators: {required: true}},
      {name: 'dadosVencimento', type: 'boolean', caption: 'rhtipoprocess.fields.dadosVencimento'},
      {name: 'dadosManuais', type: 'boolean', caption: 'rhtipoprocess.fields.dadosManuais'},
      {name: 'dadosFixos', type: 'boolean', caption: 'rhtipoprocess.fields.dadosFixos'},
      {name: 'subsidioRefeicao', type: 'boolean', caption: 'rhtipoprocess.fields.subsidioRefeicao'},
      {name: 'diuturnidades', type: 'boolean', caption: 'rhtipoprocess.fields.diuturnidades'},
      {name: 'subsidioNatal', type: 'boolean', caption: 'rhtipoprocess.fields.subsidioNatal'},
      {name: 'subsidioFerias', type: 'boolean', caption: 'rhtipoprocess.fields.subsidioFerias'},
      {name: 'valorCaducidadeCTR', type: 'boolean', caption: 'rhtipoprocess.fields.valorCaducidadeCTR'},
      {name: 'tipoPeriodicidade', type: DATA_SOURCE_NAME_TIPOS_PERIOCIDADE, caption: 'rhtipoprocess.fields.tipoPeriodicidade', validators: {required: true}},
      {name: 'desativado', type: 'boolean', caption: 'rhtipoprocess.fields.desativado'}
    ],
    order: 'tipoProcessamento',
    searchFields: 'tipoProcessamento,descricao',
    listFields: 'tipoProcessamento,descricao'
  },
  autocomplete: {
    rowTemplate: '{{tipoProcessamento}} - {{descricao}}',
    output: 'descricao',
    searchFields: 'tipoProcessamento,tipoProcessamento'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.tipoProcessamento'
      }
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.tipoProcessamento'
      },
      component: RhTiposProcessamentoEditComponent
    }
  }
};
