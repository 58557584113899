<div class="modal-header">
  <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'pagamentos.saveModal.title'"></h4>
</div>

<div class="modal-body" [plPromise]="promise">
  <pl-alert *ngIf="errorMessages.length" [plAnimationShake]="errorMessages.length" (evtClosed)="clearErros()">
    <div *ngFor="let errorMessage of errorMessages" [innerHTML]="errorMessage | translate"></div>
  </pl-alert>

  <div class="table-responsive">
    <table class="table table-hover table-sm table-docs table-pagamentos" [plTableNavigation]>
      <thead>
        <tr>
          <th><span [translate]="'pagamentos.saveModal.paymentMean'"></span></th>
          <th class="text-end"><span [translate]="'pagamentos.saveModal.paymentValue'"></span></th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let meio of pagamento.meiosPagamento; index as $index">
          <td class="text-padding">
            <a class="btn-link" (click)="manutencaoMeioPagamento(meio)">
              <span>{{ meio.nomeCaixa }}</span>
            </a>
            &nbsp;
            <span *ngIf="meio.iban !== '' && meio.tipo === tipoPagamento.TrfBancaria">({{ meio.iban }})</span>
          </td>

          <td>
            <pl-inline-edit type="number" [model]="meio.valor" (modelChange)="calcularResumo($event, meio)"></pl-inline-edit>
          </td>

          <td>
            <button type="button" class="btn btn-success btn-xs" [attr.title]="'pagamentos.saveModal.payInFull' | translate" (click)="receberTudoLinha($index)" [disabled]="closeDisabled">
              <i class="fa fa-fw fa-check"></i>
            </button>

            <button type="button" class="btn btn-danger btn-xs" [attr.title]="'pagamentos.saveModal.clearValue' | translate" (click)="limpaLinha($index)" [disabled]="closeDisabled">
              <i class="fa fa-fw fa-eraser"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="mt-3 d-flex align-items-start gap-3">
    <div class="d-flex align-items-center" style="flex-grow: 1">
      <div class="fw-semibold" [translate]="'pagamentos.pagamento.cab.descricao'"></div>
      &nbsp;
      <pl-edit-text [(model)]="pagamento.cab.descricao" attrName="descricao" style="flex-basis: 300px" [properties]="{validators: {maxlength: {value: 50}}}"></pl-edit-text>
    </div>

    <div class="resumo">
      <dl class="page-invoice-amount">
        <dt><span [translate]="'pagamentos.saveModal.document'"></span>:</dt>
        <dd>{{ pagamento.cab.total | cgcCurrency: valorDecimais : abreviaturaMoeda : 'code' }}</dd>

        <dt><span [translate]="'pagamentos.pagamento.totalCaixa'"></span>:</dt>
        <dd>{{ pagamento.totalCaixa | cgcCurrency: valorDecimais : abreviaturaMoeda : 'code' }}</dd>

        <dt><span [translate]="'pagamentos.pagamento.troco'"></span>:</dt>
        <dd>{{ pagamento.troco | cgcCurrency: valorDecimais : abreviaturaMoeda : 'code' }}</dd>
      </dl>
    </div>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-sm btn-primary action-save" [click]="fnSave('save')" plPromise [disabled]="closeDisabled || temCodMoedaDiferentes">
    <i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'pagamentos.saveModal.save'"></span>
  </button>

  <pl-tooltip [config]="sepaTooltipConfig">
    <div *plTooltipContent class="btn-group">
      <button type="button" class="btn btn-sm btn-success" [click]="fnSave('saveCreateSepa')" plPromise [disabled]="closeDisabled || sepaDisabled || !comercialAdvanced || temCodMoedaDiferentes">
        <i class="fa fa-fw fa-file-code-o"></i>&nbsp;<span [translate]="'pagamentos.saveModal.saveGenerateSEPA'"></span>
      </button>
      <div class="btn-group" ngbDropdown role="group">
        <button type="button" class="btn btn-sm btn-success" [disabled]="closeDisabled || sepaDisabled || !comercialAdvanced" ngbDropdownToggle></button>
        <div ngbDropdownMenu>
          <button type="button" ngbDropdownItem [disabled]="closeDisabled" [click]="fnSave('saveGestaoSepa')" plPromise>
            <i class="fa fa-fw fa-folder-open"></i>&nbsp;<span [translate]="'pagamentos.saveModal.saveGestaoSEPA'"></span>
          </button>
        </div>
      </div>
    </div>
  </pl-tooltip>

  <button type="button" class="btn btn-sm btn-warning action-save-pdf" [click]="fnSave('pdf')" plPromise [disabled]="closeDisabled || temCodMoedaDiferentes">
    <i class="fa fa-fw fa-file-pdf-o"></i>&nbsp;<span [translate]="'pagamentos.saveModal.saveCheck'"></span>
  </button>

  <button type="button" class="btn btn-sm btn-light action-dismiss" [disabled]="closeDisabled" (click)="dismiss()">
    <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
  </button>
</div>

<ng-template #templateTooltipGestaoSepa>
  <div
    *ngIf="!comercialAdvanced"
    [innerHTML]="(licencaStoreModePublic ? 'blockedPlugin.text.noLicense' : 'blockedPlugin.text.noLicenseStoreMode') | translate: {href: cgStoreUrlBackOffice, portal: portalName}"></div>
  <div *ngIf="comercialAdvanced">
    <span>{{ sepaTooltipMessage }}</span>
  </div>
</ng-template>
