import {Draft} from 'immer';
import {Observable} from 'rxjs';
import {ConfigOptionsService} from './config.options.service';
import {EGroupName} from '../../../../config/constants';
import {EConfigOptionsInstanceName, IConfigOptions, IConfigOptionsGroup, TConfigOptions} from './config.options.service.interface';
import {IConfigOptionsInstance} from './config.options.instance.interface';
import {Injector} from '@angular/core';

export abstract class ConfigOptionsInstanceService<T, S extends IConfigOptions<T>, TConfigOptionsGroup extends IConfigOptionsGroup<T, S>> {
  private readonly _configOptionsService: ConfigOptionsService;

  protected constructor(
    protected readonly _injector: Injector,
    private readonly _groupName: EGroupName,
    private readonly _instanceName: EConfigOptionsInstanceName
  ) {
    this._configOptionsService = this._injector.get<ConfigOptionsService>(ConfigOptionsService);
  }

  public getDefaultOptions(): TConfigOptions<T, S> {
    return this._instanceOptions().get(this._instanceName).defaultOptions();
  }

  public getOptions(): Observable<TConfigOptions<T, S>> {
    return this._instanceOptions().get(this._instanceName).options();
  }

  public setOptions(value: TConfigOptions<T, S>, persist?: boolean): Promise<TConfigOptions<T, S>> {
    return this._instanceOptions().get(this._instanceName).setOptions(value, persist);
  }

  public setOption(option: Draft<keyof S>, value: T, persist?: boolean): Promise<TConfigOptions<T, S>> {
    return new Promise<TConfigOptions<T, S>>((resolve, reject) => {
      this._instanceOptions()
        .get(this._instanceName)
        .setOption(option, value, persist)
        .then((configOptions) => {
          resolve(configOptions);
        })
        .catch(reject);
    });
  }

  public reloadOptions(): Promise<TConfigOptions<T, S>> {
    return this._instanceOptions().get(this._instanceName).reloadOptions();
  }

  public get configOptionsInstanceName(): EConfigOptionsInstanceName {
    return this._instanceName;
  }

  public get configOptionsGroupName(): EGroupName {
    return this._groupName;
  }

  private _instanceOptions(): ReadonlyMap<keyof TConfigOptionsGroup, IConfigOptionsInstance<T, S>> {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    switch (this._groupName) {
      case EGroupName.ERP:
        return <any>this._configOptionsService.getOptionsErp();
      case EGroupName.PCA:
        return <any>this._configOptionsService.getOptionsPca();
      case EGroupName.COLABORADOR:
        return <any>this._configOptionsService.getOptionsColaborador();
      case EGroupName.GESTOR_EMENTAS:
        return <any>this._configOptionsService.getOptionsGestorEmentas();
      case EGroupName.GESTOR_RH:
        return <any>this._configOptionsService.getOptionsGestorRh();
      case EGroupName.GESTOR_SERVICO:
        return <any>this._configOptionsService.getOptionsServico();
      case EGroupName.CONTABILIDADE:
        return <any>this._configOptionsService.getOptionsContabilidade();
      default:
        return undefined;
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */
  }
}
