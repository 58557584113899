<div class="concelhos">
  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [model]="model" [definition]="formDefinition" [template]="formTemplate" [properties]="{readonly: type === 'detail'}" [plPromise]="promise"></pl-form>

      <div class="entity-detail-form">
        <pl-group [properties]="{readonly: type === 'detail'}">
          <label [translate]="'concelhos.fields.nomeDistrito'"></label>
          <edit>
            <entity-autocomplete
              entity="distritos"
              attrName="codDistrito"
              [model]="model"
              (selectedKeyChange)="model.codDistrito = $event; loadListaConcelhosAT(true)"
              (evtSelectedDescriptionChanged)="model.nomeDistrito = $event"
              [properties]="{validators: {required: {value: true}}}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group [properties]="{readonly: type === 'detail'}">
          <label [translate]="'concelhos.fields.codConcelhoAT'"></label>
          <edit>
            <pl-autocomplete
              attrName="codConcelhoAT"
              [source]="listConcelhosAT"
              [model]="model.nomeConcelhoAT"
              (evtSelected)="evtConcelhoATChanged($event)"
              [rowTemplate]="concelhoATTemplate"
              [output]="concelhoATTemplate">
            </pl-autocomplete>
          </edit>
        </pl-group>

        <div class="card" *ngIf="type !== entityState.NEW">
          <div class="card-header" [translate]="'concelhos.cardderrama'"></div>
          <div class="card-body">
            <pl-group>
              <pl-group>
                <label [translate]="'concelhos.fields.ano'"></label>
                <edit>
                  <pl-select [clearable]="false" [model]="anoDerrama" [source]="anosDerrama" (modelChange)="anoDerramaChanged($event)" output="key"> </pl-select>
                </edit>
              </pl-group>
              <pl-group>
                <pl-button klass="btn-primary btn-sm action-calc-derrama" (evtClicked)="loadDerrama()" [plPromise]="promiseAnoDerrama">
                  <i class="fa fa-fw fa-percent"></i>&nbsp;<span [translate]="'concelhos.cardderrama'"></span>
                </pl-button>
              </pl-group>
            </pl-group>
          </div>
        </div>
      </div>
    </div>
  </entity-detail>
</div>
