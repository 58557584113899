<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'planoscontasalternativos.modals.import.title'"></h4>
</div>

<div class="modal-body">
  <pl-upload [hideGlobalActions]="true" [hideActions]="true" [autoProcessQueue]="false" [maxFiles]="1" [callback]="callback" acceptedFiles=".xlsx,.xls"> </pl-upload>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-primary action-submit" (click)="close()"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.import'"></span></button>

  <button type="button" class="btn btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
</div>
