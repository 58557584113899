<div class="intfac-modal">
  <div class="modal-header">
    <button type="button" (click)="dismiss()" class="btn-close"></button>
    <h4 class="modal-title" [translate]="'intfac.modal.title'"></h4>
  </div>

  <div class="modal-body">
    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinition"
      cgDxDataGridInstanceName="intFacModal"
      [dataSource]="dataGridDefinition.dataSource"
      (onInitialized)="onInitialized($event)"
      (onContentReady)="onContentReady()">
      <div *dxTemplate="let row of 'templateMasterDetail'">
        <div class="text-danger"><i class="fa fa-fw fa-times-circle mr-1" aria-hidden="true"></i>{{ row.data.error }}</div>
      </div>
    </dx-data-grid>

    <ul class="table-legend mt-2">
      <li>
        <div class="table-legend-badge bg-error"></div>
        <div class="table-legend-caption" [translate]="'intfac.modal.legend.legendErro'"></div>
      </li>
    </ul>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light action-close" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
  </div>
</div>
