import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHandlerFn, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {TestsService} from '../core/services/tests/tests.service';

export function interceptorTestsHelper(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const testsService: TestsService = inject(TestsService);
  testsService.incrementPendingRequests();
  return next(request).pipe(
    tap({
      error: () => {
        testsService.decrementPendingRequests();
      },
      complete: () => {
        testsService.decrementPendingRequests();
      }
    })
  );
}

@Injectable()
export class TestsHelperInterceptor implements HttpInterceptor {
  constructor(private readonly _testsService: TestsService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this._testsService.incrementPendingRequests();
    return next.handle(request).pipe(
      tap({
        error: () => {
          this._testsService.decrementPendingRequests();
        },
        complete: () => {
          this._testsService.decrementPendingRequests();
        }
      })
    );
  }
}
