<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'docsPartilhados.uploadmodal.titlenew'"></h4>
</div>

<div class="modal-body clearfix">
  <div class="col-sm-12">
    <pl-group>
      <label [translate]="'docsPartilhados.uploadmodal.dataRef'"></label>
      <edit>
        <pl-edit-datepicker [(model)]="refDate"></pl-edit-datepicker>
      </edit>
    </pl-group>
    <br />
    <pl-upload
      [url]="uploadUrl"
      [hideGlobalActions]="true"
      [hideActions]="true"
      [autoProcessQueue]="false"
      [uploadMultiple]="true"
      [callback]="plUpload"
      acceptedFiles=".bmp,.png,.jpeg,.jpg,.pdf,.docx,.doc,.csv,.xlsx,.xls,.xml">
    </pl-upload>
  </div>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary action-send-files" [onClick]="fnSendFile" [disabled]="!plUpload.getQueuedFiles().length">
    <i class="fa fa-fw fa-upload"></i>&nbsp;<span [translate]="'docsPartilhados.btn.enviarFicheiros'"></span>
  </pl-button>

  <pl-button klass="btn-danger action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
</div>
