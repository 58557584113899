import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonAtivos} from '../../interfaces/jsonAtivos.entity.interface';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {TDate} from '../../../../../common/dates';
import {AtivosService} from '../../services/ativos.service';

@Component({
  selector: 'modal-ativos-opjustovalorabp',
  templateUrl: './ativosOpJustoValorABP.modal.component.html'
})
export class AtivosOpJustoValorABPModalComponent extends CGModalComponent<IJsonAtivos> {
  @Input() public ativo: IJsonAtivos;
  @Input() public dataOperacao: TDate;
  public valorOperacao: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosService: AtivosService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.valorOperacao = 0;
  }

  public close(): Promise<void> {
    return this._cgModalService.showOkCancel('ativos.confirmacao', 'ativos.ativosopjustovalorabp.temcertezaOPJustoValor').then(() => {
      return this._ativosService.postOpJustoValorABP(this.ativo.codAtivo, this.dataOperacao, this.valorOperacao).then((response: HttpResponse<IJsonAtivos>) => {
        this._plAlertService.success('ativos.ativosopjustovalorabp.successOPJustoValor');
        super.close(response.body);
      });
    });
  }
}
