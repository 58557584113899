<div class="contratos-print">
  <config-options [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="instanceName"></config-options>
  <pl-form class="entity-detail-form">
    <pl-group>
      <label [translate]="'contratos.print.filters.listagem'"></label>
      <edit>
        <pl-edit type="reports_contratos" attrName="listagem" [model]="report" (modelChange)="onReportChange($event)" [properties]="{disallowClear: true}"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'contratos.print.filters.declifo'"></label>
        <edit>
          <entity-autocomplete entity="clientes" attrName="declifo" [selectedKey]="filters.declifo" (selectedKeyChange)="onDeClifoChanged($event)" output="key"></entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'contratos.print.filters.ateclifo'"></label>
        <edit>
          <entity-autocomplete entity="clientes" attrName="ateclifo" [(selectedKey)]="filters.ateclifo" output="key"></entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'contratos.print.filters.dencontrato'"></label>
        <edit>
          <pl-edit type="integer" attrName="dencontrato" [(model)]="filters.dencontrato"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'contratos.print.filters.atencontrato'"></label>
        <edit>
          <pl-edit type="integer" attrName="atencontrato" [(model)]="filters.atencontrato"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'contratos.print.filters.dtdeini'"></label>
        <edit>
          <pl-edit-datepicker attrName="dtdeini" [(model)]="filters.dtdeini"></pl-edit-datepicker>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'contratos.print.filters.dtateini'"></label>
        <edit>
          <pl-edit-datepicker attrName="dtateini" [(model)]="filters.dtateini"></pl-edit-datepicker>
        </edit>
      </pl-group>
    </pl-group>
    <pl-group>
      <pl-group>
        <label [translate]="'contratos.print.filters.dtdefim'"></label>
        <edit>
          <pl-edit-datepicker attrName="dtdefim" [(model)]="filters.dtdefim"></pl-edit-datepicker>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'contratos.print.filters.dtatefim'"></label>
        <edit>
          <pl-edit-datepicker attrName="dtatefim" [(model)]="filters.dtatefim"></pl-edit-datepicker>
        </edit>
      </pl-group>
    </pl-group>
    <pl-group>
      <pl-group>
        <label [translate]="'contratos.print.filters.dtdedatactr'"></label>
        <edit>
          <pl-edit-datepicker attrName="dtdedatactr" [(model)]="filters.dtdedatactr"></pl-edit-datepicker>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'contratos.print.filters.dtatedatactr'"></label>
        <edit>
          <pl-edit-datepicker attrName="dtatedatactr" [(model)]="filters.dtatedatactr"></pl-edit-datepicker>
        </edit>
      </pl-group>
    </pl-group>

    <div *ngIf="optionShowClasses">
      <strong><span [translate]="'contratos.print.filters.classes'"></span></strong>
      <div class="card">
        <div class="card-body">
          <pl-group *ngIf="classesContratos.length > 0">
            <div *ngFor="let classe of classesContratos">
              <edit>
                <pl-edit type="checkbox" attrName="normal" [(model)]="classe.selected" (modelChange)="onClasseSelectChange()" [properties]="{label: classe.nClasseCtr + '-' + classe.nomeClasseCtr}">
                </pl-edit>
              </edit>
            </div>
          </pl-group>
        </div>
      </div>

      <strong><span [translate]="'contratos.print.filters.tipos'"></span></strong>
      <div class="card">
        <div class="card-body">
          <pl-group *ngIf="contratoTipoSource.length > 0">
            <div *ngFor="let tipoContrato of contratoTipoSource">
              <edit>
                <pl-edit type="checkbox" attrName="normal" [(model)]="tipoContrato.selected" [properties]="{label: tipoContrato.nTipoContrato + '-' + tipoContrato.nome}"></pl-edit>
              </edit>
            </div>
          </pl-group>
          <div *ngIf="!contratoTipoSource.length">
            <span [translate]="'faturacaoContratos.messages.semClasseSelecionada'"></span>
          </div>
        </div>
      </div>
    </div>
  </pl-form>

  <hr />

  <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>
