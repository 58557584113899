import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {ModuloComponent} from '../../../../components/module/module.component';
import {Modelo39Service} from '../modelo39.module.service';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IJsonModelo39, IJsonModelo39Quadro06} from '../jsonModelo39.module.interface';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {IRadioGroup} from '../../../../../common/interfaces/interfaces';
import {DATA_CODIGOS_RENDIMENTOS, ERadioTipoDeclaracao, ICodigoImpedimento, ICodigoRendimento, IJsonModeloErrors, RADIO_GROUP_TIPO_DECLARACAO} from '../modelo39.module.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {Properties as ButtonProperties} from 'devextreme/ui/button';
import {IDevExpressDataGridEventOnInitialized, IDevExpressDataGridEventOnInitNewRow} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {TDevExpressValidatorRule} from '../../../../components/devexpress/validator/devexpress.validator.rules.interface';
import {downloadStream, IPlToolbarItem, IPlToolbarMenuItem, PlAlertService} from 'pl-comps-angular';
import {ETiposValidar} from '../../modelo25/jsonModelo25.module.interface';
import {ICGExceptionError} from '../../../../components/exceptions/exceptions.service.interface';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {ModeloConfigwsModalComponent} from '../../modeloconfigwsmodal/modals/modelo.configWS.modal.component';

const TOOLBAR_GROUP_RESPONSIVE = 'module-btns-responsive';

@Component({
  selector: 'module-modelo39',
  templateUrl: './modelo39.module.component.html'
})
export class Modelo39ModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public anosAnteriores: Array<number>;

  public readonly tiposDeclaracao: IRadioGroup<ERadioTipoDeclaracao>;
  public readonly codigosRendimentos: Array<ICodigoRendimento>;
  public readonly optionsTiposImpedimento: Array<ICodigoImpedimento>;
  public readonly templateTiposImpedimento: string;
  public readonly outputTiposImpedimento: string;
  public readonly eTiposValidar: typeof ETiposValidar;

  public dataGridRegistoList: IDevExpressDataGrid<IJsonModelo39Quadro06, IJsonModelo39Quadro06>;
  public errors: IJsonModeloErrors;
  public modelo39: IJsonModelo39;
  public showAjudaQuadro6: boolean;

  private readonly _btnAtualizar: IPlToolbarItem;
  private readonly _btnCriarFicheiro: IPlToolbarItem;
  private readonly _btnValidarAt: IPlToolbarItem;
  private readonly _btnSumbeterAt: IPlToolbarItem;
  private readonly _btnConfigWS: IPlToolbarItem;

  private _dataGridInstanceQ06: dxDataGrid<IJsonModelo39Quadro06, IJsonModelo39Quadro06>;
  private _selectedAno: IPlToolbarMenuItem;
  private _mnuAnos: IPlToolbarItem;
  private _anoAtivo: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _modelo39Service: Modelo39Service,
    private readonly _cgModalService: CGModalService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._cleanErrors();
    this.tiposDeclaracao = RADIO_GROUP_TIPO_DECLARACAO;
    this.codigosRendimentos = DATA_CODIGOS_RENDIMENTOS;
    this.anosAnteriores = [];
    this.eTiposValidar = ETiposValidar;
    this.showAjudaQuadro6 = false;
    this.templateTiposImpedimento = '{{value}} - {{name}}';
    this.outputTiposImpedimento = 'value';
    this.optionsTiposImpedimento = [
      {value: 1, name: 'modelo39.codigosJustoImpedimento.01'},
      {value: 2, name: 'modelo39.codigosJustoImpedimento.02'},
      {value: 3, name: 'modelo39.codigosJustoImpedimento.03'},
      {value: 4, name: 'modelo39.codigosJustoImpedimento.04'}
    ];
    this._mnuAnos = {order: 1};
    this._btnAtualizar = {
      id: 'atualizarModelo',
      type: 'button',
      order: this._mnuAnos.order + 1,
      iconLeft: '<i class="fa fa-fw fa-refresh"></i>',
      class: 'btn-success',
      caption: 'global.btn.load',
      click: () => this._atualizarModelo()
    };
    this._btnCriarFicheiro = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'criarFicheiro',
      type: 'button',
      order: this._btnAtualizar.order + 1,
      iconLeft: '<i class="fa fa-fw fa-file-text-o"></i>',
      class: 'btn-primary',
      caption: 'global.btn.createFile',
      disabled: true,
      click: () => this._createFileModelo39()
    };
    this._btnValidarAt = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'validarNaAT',
      type: 'dropdown-split',
      order: this._btnCriarFicheiro.order + 1,
      caption: 'global.btn.validarAt',
      iconLeft: '<i class="fa fa-fw fa-globe"></i>',
      class: 'btn-primary',
      click: () => this._validarNaAT(),
      disabled: true
    };
    this._btnSumbeterAt = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'submeterNaAT',
      order: this._btnValidarAt.order + 1,
      type: 'button',
      caption: 'global.btn.submeterAt',
      iconLeft: '<i class="fa fa-fw fa-upload"></i>',
      click: () => this._submeterNaAT(),
      disabled: true
    };
    this._btnConfigWS = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'configWS',
      order: this._btnSumbeterAt.order + 1,
      type: 'button',
      caption: 'global.btn.configWS',
      iconLeft: '<i class="fa fa-fw fa-cog"></i>',
      class: 'btn-light',
      click: () => this._callConfigWS()
    };
    this._btnValidarAt.menu = [this._btnSumbeterAt];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.modelo39 = {
      rosto: {
        q01c01: undefined,
        q02c02: undefined,
        q02c06: undefined,
        q02c07: undefined,
        q02c08: undefined,
        q03c03: undefined,
        q04c04: undefined,
        q05c05: undefined,
        q06c06: [],
        quadroInicio: undefined
      },
      versao: undefined
    };
    const btnAjuda: Partial<ButtonProperties> = {
      icon: 'help',
      hint: this._translateService.instant('modelo39.groups.q6.dataGridRegistoList.extras.ajuda'),
      onClick: () => {
        this._toggleSplitQuadro6();
      }
    };
    const validationRules: Array<TDevExpressValidatorRule> = [{type: 'required', trim: true, message: this._translateService.instant('datagrid.column.required')}];
    this.dataGridRegistoList = {
      columnHidingEnabled: false,
      columns: [
        {
          dataField: 'nif',
          dataType: 'number',
          caption: 'modelo39.groups.q6.dataGridRegistoList.colunas.nif',
          validationRules: validationRules
        },
        {
          dataField: 'codRend',
          dataType: 'string',
          caption: 'modelo39.groups.q6.dataGridRegistoList.colunas.codRend',
          showEditorAlways: true,
          lookup: {
            dataSource: this.codigosRendimentos,
            valueExpr: 'cod',
            displayExpr: (item: ICodigoRendimento) => `${item.cod} - ${<string>this._translateService.instant('modelo39.codigosRendimentos.desc')}`,
            allowClearing: false
          },
          validationRules: validationRules
        },
        {dataField: 'rendimento', dataType: 'double', caption: 'modelo39.groups.q6.dataGridRegistoList.colunas.rendimento', validationRules: validationRules},
        {dataField: 'retido', dataType: 'double', caption: 'modelo39.groups.q6.dataGridRegistoList.colunas.retido', validationRules: validationRules},
        {dataField: 'nifEmitente', dataType: 'number', caption: 'modelo39.groups.q6.dataGridRegistoList.colunas.nifEmitente', allowEditing: false}
      ],
      editing: {
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true,
        mode: 'cell',
        newRowPosition: 'last'
      },
      toolbar: {
        items: ['addRowButton', {name: 'ajudaBtn', location: 'after', widget: 'dxButton', options: btnAjuda}, 'exportButton']
      },
      export: {filename: 'modelo39.title'},
      columnChooser: {enabled: false},
      remoteOperations: false
    };

    const anoEmCursoIRC = this.configurations.empresa.anoEmCursoIRC;
    this._anoAtivo = anoEmCursoIRC;
    const anos: Array<IPlToolbarMenuItem> = [];
    let addedCurrentYear = false;
    for (const ano of this.anosAnteriores) {
      if (ano === anoEmCursoIRC) {
        addedCurrentYear = true;
      }
      const itemAno: IPlToolbarMenuItem = {
        caption: String(ano),
        active: ano === this._anoAtivo,
        click: (menuItem: IPlToolbarMenuItem) => this._anoChanged(menuItem)
      };
      if (itemAno.active) {
        this._selectedAno = itemAno;
      }
      anos.push(itemAno);
    }
    if (!addedCurrentYear) {
      anos.unshift({
        caption: String(anoEmCursoIRC),
        active: anoEmCursoIRC === this._anoAtivo,
        click: (menuItem: IPlToolbarMenuItem) => this._anoChanged(menuItem)
      });
      const itemAno: IPlToolbarMenuItem = anos[0];
      if (itemAno.active) {
        this._selectedAno = itemAno;
      }
    }
    this._mnuAnos = {...this._mnuAnos, id: 'anos', caption: this._getAnoCaption(), type: 'dropdown', menu: anos};

    this.toolbar.addButton(this._mnuAnos).addButton(this._btnAtualizar);
    this.dropdownActions.menu = [this._btnCriarFicheiro, this._btnValidarAt, this._btnSumbeterAt, this._btnConfigWS];
    this._buildToolbarResponsive(this.isMobile);
    this._evaluateButtons();
  }

  public setIsMobile(value: boolean): void {
    super.setIsMobile(value);
    if (this.toolbar) {
      this._buildToolbarResponsive(value);
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public onInitializedQ06({component}: IDevExpressDataGridEventOnInitialized<IJsonModelo39Quadro06, IJsonModelo39Quadro06>): void {
    this._dataGridInstanceQ06 = component;
  }

  public onInitNewRow(event: IDevExpressDataGridEventOnInitNewRow<IJsonModelo39Quadro06, IJsonModelo39Quadro06>): void {
    event.data.nifEmitente = this.modelo39.rosto.q01c01;
  }

  public onRowInserted(): void {
    this._evaluateButtons();
  }

  public onEditCanceled(): void {
    this._evaluateButtons();
  }

  public onRowRemoved(): void {
    this._evaluateButtons();
  }

  public changedTipoImpedimento(event: ICodigoImpedimento): void {
    if (event) {
      this.modelo39.rosto.q02c06 = event.value;
    } else {
      this.modelo39.rosto.q02c06 = undefined;
      this.modelo39.rosto.q02c07 = this.modelo39.rosto.q02c08 = undefined;
    }
  }

  private async _getInitModelo(): Promise<void> {
    let tipoRendimentosCap;
    await this._showInitModal()
      .then(() => {
        tipoRendimentosCap = true;
      })
      .catch(() => {
        tipoRendimentosCap = false;
      });
    this.modelo39 = await this._modelo39Service.getModelo(tipoRendimentosCap, String(this._anoAtivo)).then((response: HttpResponse<IJsonModelo39>) => response.body);
    if (!this.modelo39.rosto.q02c06) {
      this.modelo39.rosto.q02c07 = this.modelo39.rosto.q02c08 = undefined;
    }
    this._evaluateButtons();
  }

  private _showInitModal(): Promise<void> {
    return this._cgModalService.showOkCancel('modelo39.initModal.title', this._translateService.instant('modelo39.initModal.message', {ano: this._anoAtivo}), {
      size: 'md',
      btnOkText: 'global.btn.yes',
      btnCancelText: 'global.btn.no',
      backdrop: 'static',
      keyboard: false
    });
  }

  private _toggleSplitQuadro6(): void {
    this.showAjudaQuadro6 = !this.showAjudaQuadro6;
    this._dataGridInstanceQ06.repaint();
  }

  private async _atualizarModelo(): Promise<void> {
    this._cleanErrors();
    await this._getInitModelo();
    this._evaluateButtons();
  }

  private _cleanErrors(): void {
    this.errors = {
      tipoValidacao: ETiposValidar.DEFAULT,
      erros: []
    };
  }

  private _evaluateButtons(): void {
    const invalid = !this.modelo39.rosto.q06c06.length;
    this._btnCriarFicheiro.disabled = invalid;
    this._btnValidarAt.disabled = invalid;
    this._btnSumbeterAt.disabled = invalid;
  }

  private async _createFileModelo39(): Promise<void> {
    this._cleanErrors();
    const responseFicheiro: HttpResponse<Blob> = await this._modelo39Service.criarFicheiro(this.modelo39);
    downloadStream(responseFicheiro);
  }

  private async _validarNaAT(): Promise<void> {
    this._cleanErrors();
    const response = await this._modelo39Service.validarDeclaracaoAT(this.modelo39).catch((reason: HttpErrorResponse) => {
      const exception: ICGExceptionError = this._cgExceptionService.get(reason);
      this.errors = {
        tipoValidacao: ETiposValidar.AT,
        mensagemTipoHeader: this._translateService.instant('modelo39.servicoAT.validarAtFailed'),
        erros: [{mensagemOriginal: exception.message}]
      };
    });
    if (response) {
      this._plAlertService.success('modelo39.servicoAT.validarAtSuccess');
    }
  }

  private async _submeterNaAT(): Promise<void> {
    this._cleanErrors();
    const response = await this._modelo39Service.submeterDeclaracaoAT(this.modelo39).catch((reason: HttpErrorResponse) => {
      const exception: ICGExceptionError = this._cgExceptionService.get(reason);
      this.errors = {
        tipoValidacao: ETiposValidar.AT,
        mensagemTipoHeader: this._translateService.instant('modelo39.servicoAT.submeterAtFailed'),
        erros: [{mensagemOriginal: exception.message}]
      };
    });
    if (response) {
      this._plAlertService.success('modelo39.servicoAT.submeterAtSuccess');
    }
  }

  private _callConfigWS(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ModeloConfigwsModalComponent);
    return modalInstance.result;
  }

  private _buildToolbarResponsive(isMobile: boolean): void {
    this.toolbar.removeGroupId(TOOLBAR_GROUP_RESPONSIVE, false);
    this.dropdownActions.visible = isMobile;
    this._btnValidarAt.type = isMobile ? 'button' : 'dropdown-split';
    if (!isMobile) {
      this._btnCriarFicheiro.class = this._btnValidarAt.class = 'btn-primary';
      this._btnConfigWS.class = 'btn-light';
      this._btnValidarAt.menu = [this._btnSumbeterAt];
      this.toolbar.addButton(this._btnCriarFicheiro).addButton(this._btnValidarAt).addButton(this._btnConfigWS);
    } else {
      this._btnCriarFicheiro.class = this._btnValidarAt.class = this._btnConfigWS.class = undefined;
    }
  }

  private _getAnoCaption(): string {
    return this._translateService.instant('toolbar.year', {value: this._anoAtivo});
  }

  private _anoChanged(menuItem: IPlToolbarMenuItem): Promise<void> {
    if (menuItem.caption !== String(this._anoAtivo)) {
      if (this._selectedAno) {
        this._selectedAno.active = false;
      }
      this._selectedAno = menuItem;
      this._selectedAno.active = true;
      this._anoAtivo = Number(menuItem.caption);
      this._mnuAnos.caption = this._getAnoCaption();
    }
    return Promise.resolve();
  }
}
