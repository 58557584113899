import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ProceSegSocialService} from '../../proceSegSocial.module.service';
import {TranslateService} from '@ngx-translate/core';
import {isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import {IRHProceSegSocialFicheiroEdicao, IRHProceSegSocialFicheiroEdicaoDataGridRecord, IRHProceSegSocialFicheiroEdicaoSave} from '../../proceSegSocial.module.interface';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {RhProcSegSocialConsultEnviosService} from '../../../../portalrh/rhprocsegsocialconsultenvios/rhProcCegCocialConsultEnvios.module.service';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import {round} from 'lodash-es';

export enum EProceSegSocialViewInfoServiceType {
  ProcSegSocialService,
  ProcSegSocialConsultService
}

@Component({
  selector: 'proce-seg-social-view-info-modal',
  templateUrl: './proceSegSocial.viewInfo.modal.component.html'
})
export class ProceSegSocialViewInfoComponent extends CGModalComponent<void> implements OnInit {
  @Input() public listSource: Array<IRHProceSegSocialFicheiroEdicao>;
  @Input() public serviceType: EProceSegSocialViewInfoServiceType;
  @Input() public nEmpresa: string;
  @Input() public ano: number;
  @Input() public mes: number;
  @Input() public idOrdemFichGerado: number;

  public readonly dataGridDefinition: IDevExpressDataGrid;

  public modalTitle: string;
  public model: IRHProceSegSocialFicheiroEdicao;

  private _service: ProceSegSocialService | RhProcSegSocialConsultEnviosService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _translateService: TranslateService,
    private readonly _proceSegSocialService: ProceSegSocialService,
    private readonly _rhprocSegSocialConsultEnviosService: RhProcSegSocialConsultEnviosService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'statusImage', dataType: 'string', caption: 'proceSegSocial.fields.statusImage', alignment: 'center', cellTemplate: 'cellTemplateStatusImage'},
        {dataField: 'codEmp', dataType: 'string', caption: 'proceSegSocial.fields.codEmp'},
        {dataField: 'nome', dataType: 'string', caption: 'proceSegSocial.fields.nome'},
        {dataField: 'numSS', dataType: 'string', caption: 'proceSegSocial.fields.numSS'},
        {dataField: 'anoRefCodEmp', dataType: 'number', caption: 'proceSegSocial.fields.ssAno'},
        {dataField: 'mesRefCodEmp', dataType: 'number', caption: 'proceSegSocial.fields.ssMes'},
        {dataField: 'totalVencRemun', dataType: 'double', caption: 'proceSegSocial.fields.totalVencRemun'},
        {dataField: 'totalSegSocialRemun', dataType: 'double', caption: 'proceSegSocial.fields.totalSegSocialRemun'},
        {dataField: 'taxaAAplicar', dataType: 'number', caption: 'proceSegSocial.fields.taxaAAplicar', alignment: 'right'}
      ],
      dataSource: [],
      export: {filename: 'proceSegSocial.tabResultsCaption'},
      masterDetail: {enabled: true, autoExpandAll: false, template: 'masterDetailTemplate'},
      remoteOperations: false
    };
    this.model = {
      valPRemPerMan: 0,
      nDiasPRemPerMan: 0,
      anoRefCodEmp: 0,
      codEmp: 0,
      erro: 0,
      erroDescricao: '',
      mesRefCodEmp: 0,
      nDias2FerPagasCess: 0,
      nDiasICtRinTermit: 0,
      nome: '',
      numSS: '',
      totalSegSocialRemun: 0,
      totalVencRemun: 0,
      val2FerPagasCess: 0,
      val6DifVencim: 0,
      valAAjudasCusto: 0,
      valBPremMensal: 0,
      valCComissoes: 0,
      valDCompCessacao: 0,
      valFSubFerias: 0,
      valHHonorarios: 0,
      valICtRinTermit: 0,
      valMSubsRegMensal: 0,
      valNSubNatal: 0,
      valOPrNaoMensal: 0,
      valRSubRefeicao: 0,
      valSRSuplementar: 0,
      valTTrabNocturno: 0,
      valXOutSubsidios: 0,
      taxaAAplicar: 0
    };
  }

  public ngOnInit(): void {
    this._service =
      isUndefinedOrNull(this.serviceType) || this.serviceType === EProceSegSocialViewInfoServiceType.ProcSegSocialService ? this._proceSegSocialService : this._rhprocSegSocialConsultEnviosService;
    this.modalTitle = this._translateService.instant('proceSegSocial.viewInfoSSModalTitle', {ano: this.ano, mes: this.mes});
    this.dataGridDefinition.export = {filename: this.modalTitle};
    this.dataGridDefinition.dataSource = new CustomStore({
      load: () => this._identTableSource()
    });
  }

  public saveItem(item: IRHProceSegSocialFicheiroEdicao): Promise<void> {
    return this._cgModalService
      .showOkCancel('proceSegSocial.confirmTitle', 'proceSegSocial.promptInfoSSSave', {
        size: 'md',
        btnOkText: 'global.btn.yes',
        backdrop: 'static',
        keyboard: false
      })
      .then(() => {
        const data: IRHProceSegSocialFicheiroEdicaoSave = {
          numSS: item.numSS,
          nDiasPRemPerMan: item.nDiasPRemPerMan,
          nDiasICtRinTermit: item.nDiasICtRinTermit,
          nDias2FerPagasCess: item.nDias2FerPagasCess,
          mesRefCodEmp: item.mesRefCodEmp,
          anoRefCodEmp: item.anoRefCodEmp,
          codEmp: item.codEmp
        };
        (this._service instanceof ProceSegSocialService ? this._service.saveInfoSS(data) : this._service.saveInfoSS(this.nEmpresa, this.ano, this.mes, this.idOrdemFichGerado, data)).then(() => {
          this._plAlertService.success('proceSegSocial.infoSSSaved');
        });
      });
  }

  public readonly fnSaveItem: (item) => () => Promise<void> = (item) => (): Promise<void> => this.saveItem(item);

  private _identTableSource(): Promise<Array<IRHProceSegSocialFicheiroEdicaoDataGridRecord>> {
    return this._service.getViewInfoSS(this.nEmpresa, this.ano, this.mes, this.idOrdemFichGerado).then((response: Array<IRHProceSegSocialFicheiroEdicaoDataGridRecord>) => {
      for (const item of response) {
        const klass: string = item.erro !== 0 ? 'text-danger' : 'text-success';
        item.statusImage = `fa fa-circle ${klass}`;
        item.ano = this.ano;
        item.mes = this.mes;
        item.valPRemPerMan = round(item.valPRemPerMan, 2);
        item.valBPremMensal = round(item.valBPremMensal, 2);
      }
      return response;
    });
  }
}
