import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {copy, IPlDynamicVisualsSecondaryClickAction, IPlToolbarItem, isEmpty, isObject, PlAlertService} from 'pl-comps-angular';
import {
  EModeloTributAutonoma,
  IJsonTributAutonoma,
  IJsonTributAutonomaHeader,
  IJsonTributAutonomaSave,
  IJsonTributAutonomaSearch,
  IJsonTributAutonoTaxas
} from '../../jsonTributAutonomas.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {TributAutonomasModuleService} from '../../tributAutonomas.module.service';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {ITributAutonomasParams, RADIO_GROUP_TRIBUTACAO_APLICADA} from '../../tributAutonomas.module.interface';
import dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnContextMenuPreparing, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IRadioGroup} from '../../../../../common/interfaces/interfaces';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {HttpResponse} from '@angular/common/http';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ManutTributAutonomasEmpresasModalComponent} from '../modals/empresas/manutTributAutonomas.empresas.modal.component';
import {ENTITY_NAME_POCS} from '../../../../entities/pocs/pocs.entity.interface';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {DevExpressDataGridUIService} from '../../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';

@Component({
  selector: 'tribut-autonomas-module',
  templateUrl: './manutTributAutonomas.module.component.html'
})
export class ManutTributAutonomasModuleComponent extends ModuloComponent implements OnInit {
  @Input() public header: IJsonTributAutonomaHeader;

  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonTributAutonoma, string>;
  public readonly propertiesModeloTributAutonoma: IRadioGroup<EModeloTributAutonoma>;
  public readonly filterMovimento: string;

  public tributAutonoTaxasSource: Array<IJsonTributAutonoTaxas>;
  public promise: Promise<void>;

  private readonly _params: ITributAutonomasParams;
  private readonly _btnCopiaParaOutraEmpresas: IPlToolbarItem;
  private readonly _maintenanceInstancePocs: IEntityMaintenanceInstance;

  private _dataGridInstance: dxDataGrid<IJsonTributAutonoma, string>;
  private _cardPanel: CGCardPanelComponent;
  private _tributAutonoSave: IJsonTributAutonomaSave;

  constructor(
    protected readonly _injector: Injector,
    private readonly _tributAutonomasService: TributAutonomasModuleService,
    private readonly _cgModalService: CGModalService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _plAlertService: PlAlertService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService
  ) {
    super(_injector);
    this._maintenanceInstancePocs = this._entityMaintenanceService.build(ENTITY_NAME_POCS);
    this.filterMovimento = `tipo=${ETipoContaContabilidade.Movimento}`;
    this.propertiesModeloTributAutonoma = copy(RADIO_GROUP_TRIBUTACAO_APLICADA);
    this.propertiesModeloTributAutonoma.events = {
      beforeChange: (newValue: EModeloTributAutonoma): Promise<void> => this._validateModeloTributAutonomaChange(newValue)
    };
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nConta', visible: false, showInColumnChooser: false, allowEditing: false},
        {dataField: 'contaDesc', dataType: 'string', caption: 'tributAutonomas.fields.contaDescricao', allowEditing: false},
        {dataField: 'taxa', dataType: 'double', caption: 'tributAutonomas.fields.taxa', allowEditing: false, minWidth: 90},
        {dataField: 'taxaAgravada', dataType: 'double', caption: 'tributAutonomas.fields.taxaAgravada', visible: false, allowEditing: false},
        {dataField: 'descricao', dataType: 'string', caption: 'tributAutonomas.fields.descricao', editCellTemplate: 'descricaoEditCellTemplate', width: 300},
        {type: 'actions', cellTemplate: 'cellTemplateActions', width: 50}
      ],
      dataSource: [],
      editing: {mode: 'cell', startEditAction: 'click', selectTextOnEditStart: true, allowUpdating: true, refreshMode: 'repaint'},
      export: {filename: 'global.menu.tributautonomas'},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      height: '60vh',
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      scrolling: {rowRenderingMode: 'virtual'},
      keyExpr: 'nConta',
      remoteOperations: false
    };
    this.header = {
      modeloTributAutonoma: EModeloTributAutonoma.IRS,
      ano: this.configurations.empresa.anoEmCursoIRC,
      contasDe: '6',
      contasAte: '69999',
      prejuizoFiscal: false,
      anosSource: [this.configurations.empresa.anoEmCursoIRC]
    };
    this._btnCopiaParaOutraEmpresas = {
      id: 'copiaParaOutraEmpresas',
      order: this.btnSave.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-upload"></i>',
      class: 'btn-info',
      caption: 'tributAutonomas.btn.copiaParaOutraEmpresas',
      click: () => this._copiaParaOutraEmpresas()
    };
    this.tributAutonoTaxasSource = [];
    this._params = <ITributAutonomasParams>this._transition.params();
    this.search = this.search.bind(this);
    this.btnSave.visible = true;
    this.btnSave.click = () => this._save();
    this.btnSave.disabled = true;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnCopiaParaOutraEmpresas);
    if (!isEmpty(this._params?.nConta)) {
      this.header.contasDe = this._params.nConta;
    }
  }

  public search(): Promise<void> {
    return this._tributAutonomasService.searchClassificacaoContas(this.header).then((response: HttpResponse<IJsonTributAutonomaSearch>) => {
      this.dataGridDefinition.dataSource = response.body.tributAutonoma;
      this.tributAutonoTaxasSource = response.body.tributAutonoTaxas;
      this._tributAutonoSave = {tributAutonomaChanged: [], tributAutonoHeader: this.header};
      this.btnSave.disabled = !response.body.tributAutonoma?.length;
      if (response.body.tributAutonoma.length > 0) {
        this._cardPanel.collapse();
      }
    });
  }

  public async tributAutonoTaxasChanged(eventItem: IJsonTributAutonoTaxas, item: IJsonTributAutonoma): Promise<void> {
    let selectedRows: Array<IJsonTributAutonoma> = await this._dataGridInstance.getSelectedRowsData();

    if (selectedRows.length > 0) {
      await this._cgModalService.showOkCancel('global.text.confirmation', 'tributAutonomas.messages.desejaAtribuirOClassificadorAsCo').catch(() => {
        selectedRows = [];
      });
    }

    const indexSelected: number = selectedRows.findIndex((value: IJsonTributAutonoma) => value.nConta === item.nConta);
    if (indexSelected === -1) {
      selectedRows.push(item);
    }

    for (const selectedRow of selectedRows) {
      selectedRow.codTributAutonoma = eventItem.codTributAutonoma;
      selectedRow.taxa = eventItem.taxa;
      selectedRow.taxaAgravada = eventItem.taxaAgravada;
      selectedRow.descricao = eventItem.descricao;
      selectedRow.tributAutonoTaxasID = eventItem.tributAutonoTaxasID;

      const index: number = this._tributAutonoSave.tributAutonomaChanged.findIndex((value: IJsonTributAutonoma) => value.nConta === selectedRow.nConta);
      if (index !== -1) {
        this._tributAutonoSave.tributAutonomaChanged[index] = selectedRow;
      } else {
        this._tributAutonoSave.tributAutonomaChanged.push(selectedRow);
      }
    }

    this._dataGridInstance.deselectAll();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonTributAutonoma, string>): void {
    this._dataGridInstance = component;
  }

  public onContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IJsonTributAutonoma, string>): void {
    if (event.target === 'content' && event.row?.rowType === 'data' && isObject(event.row.data)) {
      event.event.preventDefault();
      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActions(event.row.data);
      this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions).then(() => undefined);
    }
  }

  public openPOC(nConta: string): Promise<void> {
    return this._maintenanceInstancePocs.maintenanceEdit(nConta).then(() => undefined);
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _save(): Promise<void> {
    return this._tributAutonomasService.saveClassificacaoContas(this._tributAutonoSave).then(() => {
      this._plAlertService.success('entity.state.saved');
    });
  }

  private _copiaParaOutraEmpresas(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ManutTributAutonomasEmpresasModalComponent);
    const componentInstance: ManutTributAutonomasEmpresasModalComponent = modalInstance.componentInstance;
    componentInstance.nEmpresa = this.configurations.empresa.nEmpresa;
    componentInstance.header = !this._tributAutonoSave?.tributAutonoHeader ? this.header : this._tributAutonoSave.tributAutonoHeader;
    return modalInstance.result;
  }

  private async _validateModeloTributAutonomaChange(event: EModeloTributAutonoma): Promise<void> {
    if (this.header.modeloTributAutonoma === event) {
      return;
    }
    const modeloTribut = this._translateService.instant(`tributAutonomas.modeloTributAutonoma.modelo${this.header.modeloTributAutonoma}`);
    const modeloTributAfter = this._translateService.instant(`tributAutonomas.modeloTributAutonoma.modelo${event}`);
    const message = this._translateService.instant('tributAutonomas.messages.changedModeloTriubt', {modelTribut: modeloTribut, modelTributAfter: modeloTributAfter});
    await this._cgModalService.showOkCancel('global.text.warning', message).then(() => {
      this._dataGridInstance.beginCustomLoading(undefined);
      this.promise = this._tributAutonomasService
        .atualizaModeloTributAutonoma(event, this.header.prejuizoFiscal)
        .then(() => {
          this._plAlertService.success(this._translateService.instant('tributAutonomas.messages.modeloChangedsuccess', {modelTribut: modeloTributAfter}));
          this.dataGridDefinition.dataSource = [];
          this.tributAutonoTaxasSource = [];
          if (this._cardPanel.collapsed) {
            this._cardPanel.toggleCollapse();
          }
        })
        .finally(() => {
          this.promise = undefined;
          this._dataGridInstance.endCustomLoading();
        });

      this.btnSave.promise = this.promise;
      this._btnCopiaParaOutraEmpresas.promise = this.promise;
      return this.promise;
    });
  }

  private _generateSecondaryClickActions(item: IJsonTributAutonoma): Array<IPlDynamicVisualsSecondaryClickAction> {
    return [
      {
        caption: 'tributAutonomas.actions.poc',
        click: () => {
          this.openPOC(item.nConta).then(() => undefined);
        }
      }
    ];
  }
}
