<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'analisadorTes.strings.configModalTitle'"></h4>
</div>

<div [plPromise]="promise">
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12" [ngClass]="{'col-md-6': showValorField}">
        <pl-group>
          <label [translate]="'analisadorTes.strings.data'"></label>
          <edit>
            <pl-edit-datepicker [(model)]="model.data" [properties]="{validators: {required: {value: true}}}"></pl-edit-datepicker>
          </edit>
        </pl-group>
      </div>
      <div class="col-sm-12 col-md-6" *ngIf="showValorField">
        <pl-group>
          <label [translate]="'analisadorTes.strings.valor'"></label>
          <edit>
            <pl-edit type="number" [(model)]="model.valor" [properties]="{validators: {required: {value: true}}}"></pl-edit>
          </edit>
        </pl-group>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <pl-group>
          <label [translate]="'analisadorTes.strings.nota'"></label>
          <edit>
            <textarea [rows]="3" class="form-control" [(ngModel)]="model.nota"></textarea>
          </edit>
        </pl-group>
        <div *ngIf="showNotaLastUpdField()">
          <small><b [translate]="'analisadorTes.strings.dataUltimaAtualizacaoNota'"></b>: {{ getNotaLastUpdField() }}</small>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-sm btn-primary" (evtClicked)="closeModal()"><i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
    <pl-button klass="btn-sm btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
