<div class="modal-header">
  <h4 class="modal-title" [translate]="'importadorSaftDirect.configMascaraAnalitica.modalTitle'"></h4>
</div>

<div class="modal-body" [plPromise]="applyPromise">
  <div class="row">
    <div class="col-md-12 mb-3">
      <pl-edit type="boolean" [(model)]="configModel.usaAnalitica" [properties]="{label: 'importadorSaftDirect.steps.cfgTiposDocs.usaAnalitica'}"></pl-edit>
    </div>
    <div class="col-md-12">
      <div class="config-mascara-analitica-tipos-radio-group">
        <pl-group>
          <label [translate]="'importadorSaftDirect.configMascaraAnalitica.comoPretendeAtribuirAnalitica'"></label>
          <edit>
            <pl-edit type="radiogroup" [(model)]="configModel.tipoAnaliticaIS" [properties]="{groupItems: tipoAnaliticaISItems, disabled: !configModel.usaAnalitica}"></pl-edit>
          </edit>
        </pl-group>
      </div>
    </div>
    <div class="col-md-12">
      <pl-group>
        <label [translate]="'importadorSaftDirect.steps.cfgTiposDocs.mascaraLabel'"></label>
        <edit>
          <entity-autocomplete
            entity="mascarasanalitica"
            [(model)]="configModel._mascaraObj"
            [output]="'mascara'"
            [outputKey]="'mascara'"
            [properties]="{validators: {maxlength: {value: 100}, required: {value: true}}, disabled: !isEnabledMascaraAC()}">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </div>
  </div>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-sm btn-light" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>

  <pl-button klass="btn-sm btn-primary action-close" (evtClicked)="saveConfig()" plAutoFocus>
    <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'importadorSaftDirect.strings.modalSave'"></span>
  </pl-button>
</div>
