import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_RH_ESTATISTICAS_MULTI} from './rhEstatisticasMulti.module.interface';
import {RhEstatisticasMultiModuleComponent} from './components/rhEstatisticasMulti.module.component';

export const MODULE_RH_ESTATISTICAS_MULTI: IModuleDefinition = {
  name: MODULE_NAME_RH_ESTATISTICAS_MULTI,
  state: {
    url: `/${MODULE_NAME_RH_ESTATISTICAS_MULTI}`,
    component: RhEstatisticasMultiModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.rhestatisticasmulti'
    }
  }
};
