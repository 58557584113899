<div class="rhrecolhaseriesubsidios entity-detail-form" [plPromise]="promise">
  <pl-nav-wizard [onFinalize]="finalize">
    <pl-nav-wizard-step stepId="pesquisa" caption="rhrecolhaseriesubsidios.steps.pesquisa" icon="fa-search">
      <div *plNavWizardStepContent>
        <h4 class="page-header"><label [translate]="'rhrecolhaseriesubsidios.titles.pesquisa'"></label></h4>
        <pl-form formInstanceName="pesquisa" [plPromise]="promisePesquisa">
          <pl-group>
            <pl-group>
              <label [translate]="'rhrecolhaseriesubsidios.fields.codEmpDe'"></label>
              <edit>
                <entity-autocomplete entity="dgempsfull" attrName="codEmpDe" [(selectedKey)]="codEmpDe" output="key" [helperMode]="true"></entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'rhrecolhaseriesubsidios.fields.codEmpAte'"></label>
              <edit>
                <entity-autocomplete entity="dgempsfull" attrName="codEmpAte" [(selectedKey)]="codEmpAte" output="key" [helperMode]="true"></entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <label [translate]="'rhrecolhaseriesubsidios.fields.situacaoEmprego'"></label>
            <edit>
              <pl-edit
                type="radio"
                class="me-2"
                attrName="situacaoEmprego"
                [model]="situacaoEmprego"
                (modelChange)="situacaoEmprego = $event"
                [properties]="{value: sitEmprego.Ativo, label: 'rhrecolhaseriesubsidios.fields.radio.ativo'}">
              </pl-edit>

              <pl-edit
                type="radio"
                class="me-2"
                attrName="situacaoEmprego"
                [model]="situacaoEmprego"
                (modelChange)="situacaoEmprego = $event"
                [properties]="{value: sitEmprego.Inativo, label: 'rhrecolhaseriesubsidios.fields.radio.inativo'}">
              </pl-edit>

              <pl-edit
                type="radio"
                attrName="situacaoEmprego"
                [model]="situacaoEmprego"
                (modelChange)="situacaoEmprego = $event"
                [properties]="{value: sitEmprego.Todos, label: 'rhrecolhaseriesubsidios.fields.radio.todos'}">
              </pl-edit>
            </edit>
          </pl-group>

          <pl-button klass="btn-primary btn-sm float-end" id="pesquisa" (evtClicked)="pesquisar()"> <i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span> </pl-button>

          <hr />

          <dx-data-grid class="mt-3" [cgDxDataGrid]="dataGrid" [dataSource]="rhRecolhaSerieSubsidiosEmpList" cgDxDataGridInstanceName="rhSalToTrfTable" (selectedRowKeysChange)="selectedEmps = $event">
          </dx-data-grid>
        </pl-form>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step stepId="processar" caption="rhrecolhaseriesubsidios.steps.processar" icon="fa-th-list" [captionBtnFinalize]="'global.btn.process'">
      <div *plNavWizardStepContent>
        <h4 class="page-header"><label [translate]="'rhrecolhaseriesubsidios.titles.processar'"></label></h4>
        <pl-form formInstanceName="processar">
          <pl-group>
            <label [translate]="'rhrecolhaseriesubsidios.fields.tipoProcessamento'"></label>
            <edit>
              <entity-autocomplete
                entity="rhtipoprocess"
                attrName="rhtipoprocess"
                [model]="rhRecolhaSerieSubsidios"
                (selectedKeyChange)="rhRecolhaSerieSubsidios.tipoProcessamento = $event"
                (evtSelectedDescriptionChanged)="rhRecolhaSerieSubsidios.nomeTipoProcessamento = $event"
                [fieldsMap]="{descricao: 'nomeTipoProcessamento'}"
                [filter]="tipoProcessFilter"
                [output]="tipoProcessOutput">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'rhrecolhaseriesubsidios.fields.dataReferencia'"></label>
            <edit>
              <pl-edit type="date" attrName="dataReferencia" [model]="rhRecolhaSerieSubsidios.dataReferencia" (modelChange)="changedDataReferencia($event)"></pl-edit>
            </edit>
          </pl-group>

          <pl-group *ngIf="tipoSubsidio !== tiposSubsidio.SubAlimentacaoPreProce">
            <label [translate]="'rhrecolhaseriesubsidios.fields.dataParaPagarSubsidio'"></label>
            <edit>
              <pl-edit type="date" attrName="dataParaPagarSubsidio" [(model)]="rhRecolhaSerieSubsidios.dataParaPagarSubsidio"></pl-edit>
            </edit>
          </pl-group>

          <pl-group *ngIf="tipoSubsidio === tiposSubsidio.SubAlimentacaoPreProce">
            <label [translate]="'rhrecolhaseriesubsidios.fields.nDiasSubsidioAlimentacao'"></label>
            <edit>
              <pl-edit type="number" attrName="dataParaPagarSubsidio" [(model)]="rhRecolhaSerieSubsidios.nDiasSubsidioAlimentacao"></pl-edit>
            </edit>
          </pl-group>
        </pl-form>
      </div>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
