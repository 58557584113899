import moment, {Moment, MomentInput} from 'moment';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AsyncArrayStore} from '../../../../../components/devexpress/datalayer/stores/asyncarray/devexpress.datalayer.async.array.store';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {EGestaoDGEMPSType} from '../../../../../components/gestaodgemps/gestaodgemps.interface';
import {evaluateDGEMPName, IJsonDGEMP} from '../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {GestaoDGEMPSPrintModalComponent} from '../../../../../components/gestaodgemps/modals/print/gestaodgemps.print.modal.component';
import {GestaoDGEMPSService} from '../../../../../components/gestaodgemps/gestaodgemps.service';
import {IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonConfigEstrutura, IJsonConfigTipo, IJsonPRHEventosConfig} from '../../../../../entities/prheventosconfig/jsonPRHEventosConfig.entity.interface';
import {IJsonPRHAbonos} from '../../../../../interfaces/jsonPRHAbonos.interface';
import {IJsonPRHFaltas} from '../../../../../interfaces/jsonPRHFaltas.interface';
import {IJsonPRHFerias} from '../../../../../interfaces/jsonPRHFerias.interface';
import {IJsonPRHFluxo} from '../../../../../entities/prhfluxos/jsonPRHFluxo.entity.interface';
import {IJsonPRHServicoMarcacoes} from '../../../../../entities/prhservicos/jsonPRHServico.entity.interface';
import {IPcaRhGestaoDGEMPSAbonoIntegrado, IPcaRhGestaoDGEMPSFaltaIntegrada, IPcaRhGestaoDGEMPSFeriaIntegrada, TPcaRhGestaoDGEMPSIntegradoItem} from '../pca.rh.gestaoDGEMPS.module.interface';
import {IPRHIntegradas} from '../../../../../interfaces/jsonPRHMarcacoes.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {PCARHGestaoDGEMPSService} from '../pca.rh.gestaoDGEMPS.module.service';
import {TDate} from '../../../../../../common/dates';

@Component({
  selector: 'module-pca-rh-gestao-dgemps',
  templateUrl: './pca.rh.gestaoDGEMPS.module.component.html',
  providers: [{provide: GestaoDGEMPSService, useExisting: PCARHGestaoDGEMPSService}]
})
export class PcaRhGestaoDGEMPSModuleComponent extends ModuloComponent implements OnInit {
  @Input() public moduleType: EGestaoDGEMPSType;
  @Input() public configStructure: IJsonConfigEstrutura;
  @Input() public configEvents: Array<IJsonPRHEventosConfig>;
  @Input() public fluxos: Array<IJsonPRHFluxo>;
  @Input() public empregado: IJsonDGEMP;
  @Input() public holidaysDates: Array<MomentInput>;

  public readonly codServico: number;
  public dataGridDefinition: IDevExpressDataGrid<TPcaRhGestaoDGEMPSIntegradoItem, TPcaRhGestaoDGEMPSIntegradoItem>;
  public iconMarcacao: string;
  public titleMarcacao: string;
  public titleSummary: string;
  public justified: boolean;
  public dateFilterFrom: Moment;
  public dateFilterTo: Moment;
  public totalIntegrados: number;
  public search: string;
  public searchValue: string;

  private _dataGridInstance: dxDataGrid<TPcaRhGestaoDGEMPSIntegradoItem, TPcaRhGestaoDGEMPSIntegradoItem>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _gestaoDGEMPSService: GestaoDGEMPSService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.codServico = 0;
    this.justified = false;
    this.totalIntegrados = 0;
    this.dateFilterFrom = moment().startOf('year');
    this.dateFilterTo = this.dateFilterFrom.clone().endOf('year');
  }

  public ngOnInit(): void {
    super.ngOnInit();
    let iconMarcacao: string;
    let titleMarcacao: string;
    let titleSummary: string;
    switch (this.moduleType) {
      case EGestaoDGEMPSType.Abonos:
        iconMarcacao = 'fa-calendar-check-o';
        titleMarcacao = 'gestaodgemps.abonos.motivos.marcar';
        titleSummary = 'gestaodgemps.text.abonos';
        this.dataGridDefinition = {
          columns: [
            {dataField: 'data', dataType: 'date', caption: 'gestaodgemps.fields.data'},
            {dataField: 'nome', dataType: 'string', caption: 'gestaodgemps.fields.nomeEmpregado'},
            {dataField: 'descricaoTipo', dataType: 'string', caption: 'gestaodgemps.fields.tipo'},
            {dataField: 'qtd', dataType: 'number', caption: 'gestaodgemps.fields.quantidade'}
          ],
          dataSource: [],
          export: {filename: 'sumario_marcacao_abonos'},
          remoteOperations: false,
          pager: {
            displayMode: 'full'
          },
          searchPanel: {
            visible: true
          },
          toolbar: {
            items: [
              {
                location: 'before',
                locateInMenu: 'auto',
                template: 'templateToolbar'
              },
              'exportButton',
              'columnChooserButton',
              'searchPanel'
            ]
          }
        };
        break;
      case EGestaoDGEMPSType.Faltas:
        iconMarcacao = 'fa-calendar-times-o';
        titleMarcacao = 'gestaodgemps.faltas.motivos.marcar';
        titleSummary = 'gestaodgemps.text.faltas';
        this.dataGridDefinition = {
          columns: [
            {dataField: 'data', dataType: 'date', caption: 'gestaodgemps.fields.data'},
            {dataField: 'nome', dataType: 'string', caption: 'gestaodgemps.fields.nomeEmpregado'},
            {dataField: 'motivo', dataType: 'string', caption: 'gestaodgemps.fields.motivo'}
          ],
          dataSource: [],
          export: {filename: 'sumario_marcacao_faltas'},
          remoteOperations: false,
          pager: {
            displayMode: 'full'
          },
          searchPanel: {
            visible: true
          },
          toolbar: {
            items: [
              {
                location: 'before',
                locateInMenu: 'auto',
                template: 'templateToolbar'
              },
              'exportButton',
              'columnChooserButton',
              'searchPanel'
            ]
          }
        };
        break;
      case EGestaoDGEMPSType.Ferias:
        iconMarcacao = 'fa-calendar-plus-o';
        titleMarcacao = 'gestaodgemps.ferias.motivos.marcar';
        titleSummary = 'gestaodgemps.text.ferias';
        this.dataGridDefinition = {
          columns: [
            {dataField: 'data', dataType: 'date', caption: 'gestaodgemps.fields.data'},
            {dataField: 'nome', dataType: 'string', caption: 'gestaodgemps.fields.nomeEmpregado'}
          ],
          dataSource: [],
          export: {filename: 'sumario_marcacao_ferias'},
          remoteOperations: false,
          pager: {
            displayMode: 'full'
          },
          searchPanel: {
            visible: true
          },
          toolbar: {
            items: [
              {
                location: 'before',
                locateInMenu: 'auto',
                template: 'templateToolbar'
              },
              'exportButton',
              'columnChooserButton',
              'searchPanel'
            ]
          }
        };
        break;
    }
    this.dataGridDefinition.dataSource = new AsyncArrayStore({
      injector: this._injector,
      load: () => this._getIntegrados()
    });
    this.iconMarcacao = iconMarcacao;
    this.titleMarcacao = this._translateService.instant(titleMarcacao);
    this.titleSummary = this._translateService.instant(titleSummary);
    if (this.moduleType === EGestaoDGEMPSType.Faltas || this.moduleType === EGestaoDGEMPSType.Ferias) {
      this.toolbar.addButton({
        id: 'gestaodgemps-toolbar-print',
        type: 'button',
        order: 1,
        class: 'btn-primary',
        iconLeft: '<i class="fa fa-fw fa-print"></i>',
        caption: 'global.btn.print',
        click: () => this._printModal()
      });
    }
    this._getIntegrados();
  }

  public setIsMobile(value: boolean): void {
    super.setIsMobile(value);
    this.justified = value;
  }

  public changedDateFilterFrom(value: TDate): void {
    this.dateFilterFrom = moment(value);
    this._dataGridInstance.refresh();
  }

  public changedDateFilterTo(value: TDate): void {
    this.dateFilterTo = moment(value);
    this._dataGridInstance.refresh();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<TPcaRhGestaoDGEMPSIntegradoItem, TPcaRhGestaoDGEMPSIntegradoItem>): void {
    this._dataGridInstance = component;
  }

  private _getIntegrados(): Promise<Array<TPcaRhGestaoDGEMPSIntegradoItem>> {
    return this._gestaoDGEMPSService.getListaMarcacoesMes(this.moduleType, this.codServico, this.dateFilterFrom, this.dateFilterTo).then((marcacoes: IJsonPRHServicoMarcacoes) => {
      const listIntegrados: Array<TPcaRhGestaoDGEMPSIntegradoItem> = [];
      for (const colaborador of marcacoes.listaEmp) {
        let listaJaIntegradas: Array<IPRHIntegradas>;
        switch (this.moduleType) {
          case EGestaoDGEMPSType.Abonos:
            listaJaIntegradas = colaborador.listaAbonosJaIntegradas;
            break;
          case EGestaoDGEMPSType.Faltas:
            listaJaIntegradas = colaborador.listaFaltasJaIntegradas;
            break;
          case EGestaoDGEMPSType.Ferias:
            listaJaIntegradas = colaborador.listaFeriasJaIntegradas;
            break;
        }
        for (const jaIntegrada of listaJaIntegradas) {
          switch (this.moduleType) {
            case EGestaoDGEMPSType.Abonos:
              const abono: IJsonPRHAbonos = <IJsonPRHAbonos>jaIntegrada;
              const abonoIntegrado: IPcaRhGestaoDGEMPSAbonoIntegrado = {
                data: abono.dataAbono,
                nome: evaluateDGEMPName(colaborador.dgEmp),
                descricaoTipo: this._getDescricaoTipoAbono(abono.ncodAbdes),
                qtd: abono.quantidade
              };
              listIntegrados.push(abonoIntegrado);
              break;
            case EGestaoDGEMPSType.Faltas:
              const falta: IJsonPRHFaltas = <IJsonPRHFaltas>jaIntegrada;
              const faltaIntegrada: IPcaRhGestaoDGEMPSFaltaIntegrada = {
                data: falta.data,
                nome: evaluateDGEMPName(colaborador.dgEmp),
                motivo: this._getDescricaoMotivo(falta.tipoFalta)
              };
              listIntegrados.push(faltaIntegrada);
              break;
            case EGestaoDGEMPSType.Ferias:
              const feria: IJsonPRHFerias = <IJsonPRHFerias>jaIntegrada;
              const feriaIntegrada: IPcaRhGestaoDGEMPSFeriaIntegrada = {
                data: feria.data,
                nome: evaluateDGEMPName(colaborador.dgEmp)
              };
              listIntegrados.push(feriaIntegrada);
              break;
          }
        }
      }
      this.totalIntegrados = listIntegrados.length;
      return listIntegrados;
    });
  }

  private _getDescricaoTipoAbono(nCodAbDes: string): string {
    return this.configEvents.find((configEvent: IJsonPRHEventosConfig) => configEvent.ncodAbdes === nCodAbDes)?.descricao;
  }

  private _getDescricaoMotivo(codTipo: number): string {
    return this.configStructure.listaTipo.find((tipo: IJsonConfigTipo) => tipo.codTipo === codTipo)?.descricaoTipo;
  }

  private _printModal(): Promise<void> {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(GestaoDGEMPSPrintModalComponent);
    const componentInstance: GestaoDGEMPSPrintModalComponent = modalInstance.componentInstance;
    componentInstance.type = this.moduleType;
    return modalInstance.result;
  }
}
