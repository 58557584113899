import {HttpResponse, HttpResponseBase} from '@angular/common/http';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {ERadix, isDefinedNotNull, isFunction, isString, KEYCODES, PlAlertService} from 'pl-comps-angular';
import {AsyncArrayStore} from '../../../../../components/devexpress/datalayer/stores/asyncarray/devexpress.datalayer.async.array.store';
import {AtService} from '../../../../../services/at.service';
import {CGExceptionService} from '../../../../../components/exceptions/exceptions.service';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {devExpressDataGridExpandDetailHandler} from '../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {DocsDigitaisTreeDocsNaoClassificadosModalComponent} from '../../../../contabilidadedigital/documentosdigitais/modals/treedocsnaoclassificados/docsDigitais.treeDocsNaoClassificados.modal.component';
import {DocumentosFiscaisAssociarDocumentosContabDigitalModalComponent} from '../modals/associardocumentoscontabdigital/documentosFiscais.associarDocumentosContabDigital.modal.component';
import {DocumentosFiscaisDpIvaDocPagamentoModalComponent} from '../modals/dpIvadocpagamento/documentosFiscais.dpIvaDocPagamento.modal.component';
import {DocumentosFiscaisIesPagamentoModalComponent} from '../modals/iespagamento/documentosFiscais.iesPagamento.modal.component';
import {
  EDocumentosFiscaisCertidaoEstado,
  EDocumentosFiscaisCertidaoTipo,
  EDocumentosFiscaisCredentialsModalType,
  EDocumentosFiscaisIUCEmitir,
  EDocumentosFiscaisMainMenu,
  EDocumentosFiscaisTab,
  EDpIvaDocPagamento,
  IDocumentosFiscaisCertidoesModule,
  IDocumentosFiscaisDpIvaDocPagementoModel,
  IDocumentosFiscaisDpIvaItem,
  IDocumentosFiscaisDpIvaModule,
  IDocumentosFiscaisDrIvaItem,
  IDocumentosFiscaisDrIvaModule,
  IDocumentosFiscaisEmitirIUCItem,
  IDocumentosFiscaisIESItem,
  IDocumentosFiscaisIESModule,
  IDocumentosFiscaisImoveisModule,
  IDocumentosFiscaisImoveisNotasCobrancaItem,
  IDocumentosFiscaisIRCMod22Item,
  IDocumentosFiscaisIRCMod22Module,
  IDocumentosFiscaisIRCPagAntItem,
  IDocumentosFiscaisIRCPagAntModule,
  IDocumentosFiscaisIRSMod10Item,
  IDocumentosFiscaisIRSModule,
  IDocumentosFiscaisIUCItem,
  IDocumentosFiscaisIUCModule,
  IDocumentosFiscaisMainMenuItem,
  IDocumentosFiscaisModule,
  IDocumentosFiscaisModuleParams,
  IDocumentosFiscaisPenhorasModule,
  IDocumentosFiscaisRecibosRRItem,
  IDocumentosFiscaisRecibosRRModule,
  IDocumentosFiscaisRecibosRVItem,
  IDocumentosFiscaisRecibosRVModule,
  IDocumentosFiscaisRetencoesIRCIRSItem,
  IDocumentosFiscaisRetencoesIRCIRSModule,
  IDocumentosFiscaisSSSitContribModule,
  IDocumentosFiscaisVeiculosModule,
  IDocumentosFiscaisViaCTTModule
} from '../documentosFiscais.module.interface';
import {ESimpleLoginType} from '../../../../simplelogin/simpleLogin.module.interface';
import {
  IAtCertidao,
  IAtImoveisPatrimnioPredial,
  IAtPenhora,
  IAtRecibosRendasContratoLocatario,
  IAtRecibosRendasReciboDetails,
  IAtVeiculo,
  ISSSituacaoContributiva,
  IViaCTTDocument
} from '../../../../../../common/interfaces/at.interfaces';
import {IContabilidadeDigitalTreeViewerFolder} from '../../../../../components/arquivodigital/common/treeviewer/contabilidadedigital.treeviewer.component.interface';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IRadioGroup} from '../../../../../../common/interfaces/interfaces';
import {ModuloComponent} from '../../../../../components/module/module.component';
import moment from 'moment';
import {SimpleLoginModalComponent} from '../../../../simplelogin/modals/simpleLogin.modal.component';
import {DocumentosFiscaisProgressModalComponent} from '../modals/progresscontabdigital/documentosFiscais.progress.modal.component';
import {TDate} from '../../../../../../common/dates';
import {ConfigLoginWsSSModalComponent} from '../../../../segsocial/modals/loginWsSS.modal.component';

const YEAR_SOURCE_SUBTRACT_NUM = 10;
const AT_CREDENTIALS_ERROR_CLASS = 'EATServiceATCredentials';
const SSD_CREDENTIALS_ERROR_CLASS = 'EATServiceSSDCredentials';
const VIACTT_CREDENTIALS_ERROR_CLASS = 'EATServiceViaCTTCredentials';
const IMOVEL_NC_SITUACAO_CODIGO_EMITIDA = 2;
const IMOVEL_NC_SITUACAO_CODIGO_PAGA = 4;
const IMOVEL_NC_SITUACAO_CODIGO_PAGA_FORA_PRAZO = 8;

@Component({
  selector: 'documentos-fiscais',
  templateUrl: './documentosFiscais.module.component.html'
})
export class DocumentosFiscaisComponent extends ModuloComponent implements OnInit {
  @Input() public licencaStoreModePublic: boolean;
  @Input() public empresaTemContabilidadeDigital: boolean;
  @Input() public cgStoreUrlBackOffice: string;

  public readonly anosRowTemplate: string = '{{caption}}';
  public readonly anosOutput: string = 'caption';
  public readonly mainMenuTypes: typeof EDocumentosFiscaisMainMenu;
  public readonly iucEmitirGrupos: typeof EDocumentosFiscaisIUCEmitir;
  public readonly certidoesTipos: typeof EDocumentosFiscaisCertidaoTipo;
  public readonly certidoesTipoList: Array<{tipo: EDocumentosFiscaisCertidaoTipo; descricao: string}>;
  public readonly certidoesEstadoList: Array<{estado: EDocumentosFiscaisCertidaoEstado; descricao: string}>;
  public readonly pedidoTipoCertidaoGroup: IRadioGroup<EDocumentosFiscaisCertidaoTipo>;
  public readonly docsFiscaisTab: typeof EDocumentosFiscaisTab;
  public filterAno: number;
  public anosSource: Array<number>;
  public modules: {
    dpIva: IDocumentosFiscaisDpIvaModule;
    drIva: IDocumentosFiscaisDrIvaModule;
    ircPagAnt: IDocumentosFiscaisIRCPagAntModule;
    ircMod22: IDocumentosFiscaisIRCMod22Module;
    recibosRV: IDocumentosFiscaisRecibosRVModule;
    recibosRR: IDocumentosFiscaisRecibosRRModule;
    ies: IDocumentosFiscaisIESModule;
    irs: IDocumentosFiscaisIRSModule;
    retencoesIRCIRS: IDocumentosFiscaisRetencoesIRCIRSModule;
    iuc: IDocumentosFiscaisIUCModule;
    veiculos: IDocumentosFiscaisVeiculosModule;
    certidoes: IDocumentosFiscaisCertidoesModule;
    imoveis: IDocumentosFiscaisImoveisModule;
    penhoras: IDocumentosFiscaisPenhorasModule;
    sssitcontrib: IDocumentosFiscaisSSSitContribModule;
    viactt: IDocumentosFiscaisViaCTTModule;
  };
  public activeMenu: EDocumentosFiscaisMainMenu;
  public mainMenu: Array<IDocumentosFiscaisMainMenuItem>;
  public activeTab: EDocumentosFiscaisTab;

  private readonly _moduleParams: IDocumentosFiscaisModuleParams;

  constructor(
    protected readonly _injector: Injector,
    private readonly _atService: AtService,
    private readonly _cgModalService: CGModalService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._moduleParams = <IDocumentosFiscaisModuleParams>this._transition.params();
    this.mainMenuTypes = EDocumentosFiscaisMainMenu;
    this.iucEmitirGrupos = EDocumentosFiscaisIUCEmitir;
    this.certidoesTipos = EDocumentosFiscaisCertidaoTipo;
    this.docsFiscaisTab = EDocumentosFiscaisTab;
    this.certidoesTipoList = [
      {tipo: EDocumentosFiscaisCertidaoTipo.Todos, descricao: this._translateService.instant('documentosFiscais.strings.todos')},
      {tipo: EDocumentosFiscaisCertidaoTipo.LiquidacaoIRS, descricao: this._translateService.instant('documentosFiscais.strings.liquidacaoIRS')},
      {tipo: EDocumentosFiscaisCertidaoTipo.RenunciaIsencaoIvaImovel, descricao: this._translateService.instant('documentosFiscais.strings.renunciaIsencaoIvaImovel')},
      {tipo: EDocumentosFiscaisCertidaoTipo.ResidenciaFiscal, descricao: this._translateService.instant('documentosFiscais.strings.residenciaFiscal')},
      {tipo: EDocumentosFiscaisCertidaoTipo.DividaNaoDivida, descricao: this._translateService.instant('documentosFiscais.strings.dividaNaoDivida')},
      {tipo: EDocumentosFiscaisCertidaoTipo.SujPassivoRecupIVAEstrangeiro, descricao: this._translateService.instant('documentosFiscais.strings.sujPassivoRecupIVAEstrangeiro')},
      {tipo: EDocumentosFiscaisCertidaoTipo.ResidenteNaoHabitual, descricao: this._translateService.instant('documentosFiscais.strings.residenteNaoHabitual')},
      {tipo: EDocumentosFiscaisCertidaoTipo.DomicilioFiscal, descricao: this._translateService.instant('documentosFiscais.strings.domicilioFiscal')},
      {tipo: EDocumentosFiscaisCertidaoTipo.PredialNegativa, descricao: this._translateService.instant('documentosFiscais.strings.predialNegativa')},
      {tipo: EDocumentosFiscaisCertidaoTipo.Predial, descricao: this._translateService.instant('documentosFiscais.strings.predial')}
    ];
    this.certidoesEstadoList = [
      {estado: EDocumentosFiscaisCertidaoEstado.Todos, descricao: this._translateService.instant('documentosFiscais.strings.todos')},
      {estado: EDocumentosFiscaisCertidaoEstado.Activa, descricao: this._translateService.instant('documentosFiscais.strings.activa')},
      {estado: EDocumentosFiscaisCertidaoEstado.Pendente, descricao: this._translateService.instant('documentosFiscais.strings.pendente')},
      {estado: EDocumentosFiscaisCertidaoEstado.PendenteConfirmacao, descricao: this._translateService.instant('documentosFiscais.strings.pendenteConfirmacao')},
      {estado: EDocumentosFiscaisCertidaoEstado.Expirada, descricao: this._translateService.instant('documentosFiscais.strings.expirada')},
      {estado: EDocumentosFiscaisCertidaoEstado.Anulada, descricao: this._translateService.instant('documentosFiscais.strings.anulada')}
    ];
    this.pedidoTipoCertidaoGroup = {
      groupItems: [
        {value: EDocumentosFiscaisCertidaoTipo.LiquidacaoIRS, label: 'documentosFiscais.strings.liquidacaoIRS'},
        {value: EDocumentosFiscaisCertidaoTipo.DividaNaoDivida, label: 'documentosFiscais.strings.dividaNaoDivida'},
        {value: EDocumentosFiscaisCertidaoTipo.SujPassivoRecupIVAEstrangeiro, label: 'documentosFiscais.strings.sujPassivoRecupIVAEstrangeiro'}
      ]
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'mainToolbar-shortcuts',
      type: 'dropdown',
      iconLeft: '<i class="fa fa-key me-1" aria-hidden="true"></i>',
      caption: 'documentosFiscais.strings.alterarCredenciais',
      order: 1,
      class: 'btn-info',
      menu: [
        {
          caption: 'documentosFiscais.strings.portalAT',
          click: () => this._callModalCredentials(EDocumentosFiscaisCredentialsModalType.AT)
        },
        {
          caption: 'documentosFiscais.strings.portalSSD',
          click: () => this._callModalCredentials(EDocumentosFiscaisCredentialsModalType.SSD)
        },
        {
          caption: 'documentosFiscais.strings.portalViaCTT',
          click: () => this._callModalCredentials(EDocumentosFiscaisCredentialsModalType.VIACTT)
        }
      ]
    });
    this.anosSource = [];
    this.filterAno = moment().year();
    for (let i = this.filterAno; i > this.filterAno - YEAR_SOURCE_SUBTRACT_NUM; i--) {
      this.anosSource.push(i);
    }
    this.mainMenu = [
      {
        caption: 'documentosFiscais.strings.headerAT',
        isHeader: true,
        menu: undefined
      },
      {
        caption: 'documentosFiscais.tabIva',
        menu: EDocumentosFiscaisMainMenu.IVA
      },
      {
        caption: 'documentosFiscais.tabIRC',
        menu: EDocumentosFiscaisMainMenu.IRC
      },
      {
        caption: 'documentosFiscais.tabRecibos',
        menu: EDocumentosFiscaisMainMenu.RECIBOS
      },
      {
        caption: 'documentosFiscais.tabIES',
        menu: EDocumentosFiscaisMainMenu.IES
      },
      {
        caption: 'documentosFiscais.tabIRS',
        menu: EDocumentosFiscaisMainMenu.IRS
      },
      {
        caption: 'documentosFiscais.tabRetencoes',
        menu: EDocumentosFiscaisMainMenu.RETENCOES
      },
      {
        caption: 'documentosFiscais.tabIUC',
        menu: EDocumentosFiscaisMainMenu.IUC
      },
      {
        caption: 'documentosFiscais.tabVeiculos',
        menu: EDocumentosFiscaisMainMenu.VEICULOS
      },
      {
        caption: 'documentosFiscais.tabCertidoes',
        menu: EDocumentosFiscaisMainMenu.CERTIDOES
      },
      {
        caption: 'documentosFiscais.tabImoveis',
        menu: EDocumentosFiscaisMainMenu.IMOVEIS
      },
      {
        caption: 'documentosFiscais.tabPenhoras',
        menu: EDocumentosFiscaisMainMenu.PENHORAS
      },
      {
        caption: 'documentosFiscais.strings.headerSS',
        isHeader: true,
        menu: undefined
      },
      {
        caption: 'documentosFiscais.tabSSSituacaoContrib',
        menu: EDocumentosFiscaisMainMenu.SS_SITUACAO_CONTRIB
      },
      {
        caption: 'documentosFiscais.strings.headerViaCTT',
        isHeader: true,
        menu: undefined
      },
      {
        caption: 'documentosFiscais.tabViaCTTInbox',
        menu: EDocumentosFiscaisMainMenu.VIACTT_CAIXA_CORREIO
      }
    ];
    this.activeMenu = isDefinedNotNull(this._moduleParams.menu) ? this._moduleParams.menu : EDocumentosFiscaisMainMenu.IVA;
    this.modules = {
      dpIva: {
        dataGridDefinition: {
          columns: [
            {dataField: 'periodo', caption: 'documentosFiscais.fields.periodo'},
            {dataField: 'numeroDeclaracao', caption: 'documentosFiscais.fields.identificacao'},
            {dataField: 'dataRececao', caption: 'documentosFiscais.fields.dataRececao', dataType: 'date'},
            {
              dataField: 'valor1Dec',
              caption: 'documentosFiscais.fields.valor1',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {
              dataField: 'valor2Dec',
              caption: 'documentosFiscais.fields.valor2',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {
              dataField: '_hasComprovativo',
              cellTemplate: 'cellTemplateComprovativo',
              caption: 'documentosFiscais.fields.comprovativo',
              alignment: 'center'
            },
            {
              dataField: '_hasDocPagamento',
              cellTemplate: 'cellTemplatePagamento',
              caption: 'documentosFiscais.fields.docPagamento',
              alignment: 'center'
            }
          ],
          dataSource: new AsyncArrayStore({
            injector: this._injector,
            key: 'declId',
            load: () => this._getTableSourceDpIva()
          }),
          remoteOperations: false
        },
        dataGridInstanceName: 'documentosFiscaisDpIvaInstName',
        dataGridInstance: undefined,
        menu: EDocumentosFiscaisMainMenu.IVA,
        firstLoad: true
      },
      drIva: {
        dataGridDefinition: {
          columns: [
            {dataField: 'periodo', caption: 'documentosFiscais.fields.periodo'},
            {dataField: 'tipo', caption: 'documentosFiscais.fields.tipo'},
            {dataField: 'situacao', caption: 'documentosFiscais.fields.situacao'},
            {dataField: 'dataRececao', caption: 'documentosFiscais.fields.dataRececao', dataType: 'date'},
            {
              dataField: '_hasComprovativo',
              cellTemplate: 'cellTemplateComprovativo',
              caption: 'documentosFiscais.fields.comprovativo',
              alignment: 'center'
            }
          ],
          dataSource: new AsyncArrayStore({
            injector: this._injector,
            key: 'decId',
            load: () => this._getTableSourceDrIva()
          }),
          remoteOperations: false
        },
        dataGridInstanceName: 'documentosFiscaisDrIvaInstName',
        dataGridInstance: undefined,
        menu: EDocumentosFiscaisMainMenu.IVA,
        firstLoad: true
      },
      ircPagAnt: {
        dataGridDefinition: {
          columns: [
            {dataField: 'numero', caption: 'documentosFiscais.fields.numero'},
            {dataField: 'tipo', caption: 'documentosFiscais.fields.tipo'},
            {dataField: 'dataSubmissao', caption: 'documentosFiscais.fields.dataSubmissao', dataType: 'date'},
            {dataField: 'dataSubmissao', caption: 'documentosFiscais.fields.dataPagamento', dataType: 'date'},
            {
              dataField: 'submissaoValor',
              caption: 'documentosFiscais.fields.submissaoValor',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {
              dataField: 'pagamentoValor',
              caption: 'documentosFiscais.fields.pagamentoValor',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {
              dataField: '_hasDetalhes',
              cellTemplate: 'cellTemplateDetalhes',
              caption: 'documentosFiscais.fields.obterDetalhes',
              alignment: 'center'
            }
          ],
          dataSource: new AsyncArrayStore({
            injector: this._injector,
            key: 'numero',
            load: () => this._getTableSourceIRCPagAnt()
          }),
          remoteOperations: false
        },
        dataGridInstanceName: 'documentosFiscaisIrcPagAntInstName',
        dataGridInstance: undefined,
        menu: EDocumentosFiscaisMainMenu.IRC,
        firstLoad: true
      },
      ircMod22: {
        dataGridDefinition: {
          columns: [
            {dataField: 'declId', caption: 'documentosFiscais.fields.identificacao'},
            {dataField: 'situacaoStr', caption: 'documentosFiscais.fields.situacao'},
            {dataField: 'dataRececao', caption: 'documentosFiscais.fields.dataRececao', dataType: 'date'},
            {
              dataField: '_hasComprovativo',
              cellTemplate: 'cellTemplateComprovativo',
              caption: 'documentosFiscais.fields.comprovativo',
              alignment: 'center'
            },
            {
              dataField: '_hasDocPagamento',
              cellTemplate: 'cellTemplatePagamento',
              caption: 'documentosFiscais.fields.docPagamento',
              alignment: 'center'
            }
          ],
          dataSource: new AsyncArrayStore({
            injector: this._injector,
            key: 'declId',
            load: () => this._getTableSourceIrcMod22()
          }),
          remoteOperations: false
        },
        dataGridInstanceName: 'documentosFiscaisIrcMod22InstName',
        dataGridInstance: undefined,
        menu: EDocumentosFiscaisMainMenu.IRC,
        firstLoad: true
      },
      recibosRV: {
        dataGridDefinition: {
          columns: [
            {dataField: 'numDocumento', caption: 'documentosFiscais.fields.numDocumento'},
            {dataField: 'dataEmissao', caption: 'documentosFiscais.fields.dataEmissao', dataType: 'date'},
            {dataField: 'nifPrestadorServicos', caption: 'documentosFiscais.fields.nif'},
            {dataField: 'nomePrestador', caption: 'documentosFiscais.fields.nome'},
            {
              dataField: 'valorBase',
              caption: 'documentosFiscais.fields.valorBase',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {
              dataField: 'valorIVA',
              caption: 'documentosFiscais.fields.valorIVA',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {
              dataField: 'valorImpostoSelo',
              caption: 'documentosFiscais.fields.valorImpostoSelo',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {
              dataField: 'valorIRS',
              caption: 'documentosFiscais.fields.valorIRS',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {
              dataField: 'importanciaRecebida',
              caption: 'documentosFiscais.fields.importanciaRecebida',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {
              dataField: '_hasRecibo',
              cellTemplate: 'cellTemplatePrint',
              caption: 'documentosFiscais.fields.reciboNormal',
              alignment: 'center'
            }
          ],
          dataSource: new AsyncArrayStore({
            injector: this._injector,
            key: 'numDocumento',
            load: () => this._getTableSourceRecibosRV()
          }),
          remoteOperations: false,
          masterDetail: {
            enabled: true,
            template: 'detail'
          }
        },
        dataGridInstanceName: 'documentosFiscaisRecibosRVInstName',
        dataGridInstance: undefined,
        menu: EDocumentosFiscaisMainMenu.RECIBOS,
        firstLoad: true,
        startDate: moment().year(this.filterAno).startOf('year'),
        endDate: moment().year(this.filterAno).endOf('year'),
        nifPrestador: ''
      },
      recibosRR: {
        dataGridDefinition: {
          columns: [
            {dataField: 'numeroRecibo', caption: 'documentosFiscais.fields.numeroRecibo'},
            {dataField: 'recRefContrato', caption: 'documentosFiscais.fields.recRefContrato'},
            {dataField: 'nifEmitente', caption: 'documentosFiscais.fields.nif'},
            {dataField: 'nomeEmitente', caption: 'documentosFiscais.fields.nome'},
            {dataField: 'periodoFull', caption: 'documentosFiscais.fields.periodoFull'},
            {
              dataField: 'importancia',
              caption: 'documentosFiscais.fields.importancia',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {
              dataField: '_hasDocument',
              cellTemplate: 'cellTemplateDocument',
              caption: 'documentosFiscais.fields.document',
              alignment: 'center'
            }
          ],
          dataSource: new AsyncArrayStore({
            injector: this._injector,
            key: 'numeroRecibo',
            load: () => this._getTableSourceRecibosRR()
          }),
          remoteOperations: false,
          masterDetail: {
            enabled: true,
            template: 'detail'
          }
        },
        dataGridInstanceName: 'documentosFiscaisRecibosRRInstName',
        dataGridInstance: undefined,
        menu: EDocumentosFiscaisMainMenu.RECIBOS,
        firstLoad: true,
        startDate: moment().year(this.filterAno).startOf('year'),
        endDate: moment().year(this.filterAno).endOf('year')
      },
      ies: {
        dataGridDefinition: {
          columns: [
            {dataField: 'ano', caption: 'documentosFiscais.fields.periodo'},
            {dataField: 'anexos', caption: 'documentosFiscais.fields.anexos'},
            {dataField: 'declID', caption: 'documentosFiscais.fields.declaracao'},
            {
              dataField: '_hasComprovativo',
              cellTemplate: 'cellTemplateComprovativo',
              caption: 'documentosFiscais.fields.comprovativo',
              alignment: 'center'
            },
            {
              dataField: '_pagamentoMB',
              cellTemplate: 'cellTemplatePagamentoMB',
              caption: 'documentosFiscais.fields.pagamento',
              alignment: 'center'
            }
          ],
          dataSource: new AsyncArrayStore({
            injector: this._injector,
            key: 'declID',
            load: () => this._getTableSourceIES()
          }),
          remoteOperations: false,
          toolbar: {
            items: [
              {
                location: 'before',
                template: 'templateToolbarObterButton',
                locateInMenu: 'auto'
              }
            ]
          }
        },
        dataGridInstanceName: 'documentosFiscaisIESInstName',
        dataGridInstance: undefined,
        menu: EDocumentosFiscaisMainMenu.IES,
        firstLoad: true
      },
      irs: {
        dataGridDefinition: {
          columns: [
            {dataField: 'ano', caption: 'documentosFiscais.fields.ano'},
            {dataField: 'decId', caption: 'documentosFiscais.fields.identificacao'},
            {dataField: 'dataRecepcao', caption: 'documentosFiscais.fields.dataRecepcao', dataType: 'date'},
            {
              dataField: 'temComprovativo',
              cellTemplate: 'cellTemplateComprovativo',
              caption: 'documentosFiscais.fields.comprovativo',
              alignment: 'center'
            },
            {
              dataField: 'tem2Via',
              cellTemplate: 'cellTemplateComprovativo2via',
              caption: 'documentosFiscais.fields.via2comprovativo',
              alignment: 'center'
            }
          ],
          dataSource: new AsyncArrayStore({
            injector: this._injector,
            key: 'decId',
            load: () => this._getTableSourceIRSMod10()
          }),
          remoteOperations: false,
          toolbar: {
            items: [
              {
                location: 'before',
                template: 'templateToolbarObterButton',
                locateInMenu: 'auto'
              }
            ]
          }
        },
        dataGridInstanceName: 'documentosFiscaisIRSInstName',
        dataGridInstance: undefined,
        menu: EDocumentosFiscaisMainMenu.IRS,
        firstLoad: true
      },
      retencoesIRCIRS: {
        dataGridDefinition: {
          columns: [
            {dataField: 'periodo', caption: 'documentosFiscais.fields.periodo'},
            {dataField: 'nDoc', caption: 'documentosFiscais.fields.identificacao'},
            {dataField: 'dataRecepcao', caption: 'documentosFiscais.fields.dataRecepcao', dataType: 'datetime'},
            {
              dataField: 'aPagar',
              caption: 'documentosFiscais.fields.aPagar',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {
              dataField: 'comprovativo',
              cellTemplate: 'cellTemplateComprovativo',
              caption: 'documentosFiscais.fields.comprovativo',
              alignment: 'center'
            }
          ],
          dataSource: new AsyncArrayStore({
            injector: this._injector,
            key: 'nDoc',
            load: () => this._getTableSourceRetencoesIRCIRS()
          }),
          remoteOperations: false
        },
        dataGridInstanceName: 'documentosFiscaisRetencoesIRCIRSInstName',
        dataGridInstance: undefined,
        menu: EDocumentosFiscaisMainMenu.RETENCOES,
        firstLoad: true
      },
      iuc: {
        dataGridDefinition: {
          columns: [
            {dataField: 'numDocumento', caption: 'documentosFiscais.fields.numDocumento'},
            {dataField: 'tipoDocumento', caption: 'documentosFiscais.fields.tipoDocumento'},
            {dataField: 'situacao', caption: 'documentosFiscais.fields.situacao'},
            {dataField: 'dataSituacao', caption: 'documentosFiscais.fields.dataSituacao', dataType: 'date'},
            {
              dataField: 'valor',
              caption: 'documentosFiscais.fields.valor',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {
              dataField: '_hasDocPagamento',
              cellTemplate: 'cellTemplatePagamento',
              caption: 'documentosFiscais.fields.guiaPagamento',
              alignment: 'center'
            }
          ],
          dataSource: new AsyncArrayStore({
            injector: this._injector,
            key: 'numDocumento',
            load: () => this._getTableSourceIUC()
          }),
          remoteOperations: false,
          masterDetail: {
            enabled: true,
            template: 'detail'
          }
        },
        dataGridInstanceName: 'documentosFiscaisIUCInstName',
        dataGridInstance: undefined,
        menu: EDocumentosFiscaisMainMenu.IUC,
        firstLoad: true,
        emitirGrupo: EDocumentosFiscaisIUCEmitir.GRUPO_LIGEIROS,
        emitirFirstLoad: true,
        emitirDataGridInstance: undefined,
        emitirDataGridInstanceName: 'documentosFiscaisEmitirIUCInstName',
        emitirDataGridDefinition: {
          columns: [
            {dataField: 'matricula', caption: 'documentosFiscais.fields.matricula'},
            {dataField: 'ano', caption: 'documentosFiscais.fields.ano'},
            {dataField: 'catIUC', caption: 'documentosFiscais.fields.catIUC', alignment: 'center'},
            {dataField: 'dataLimitePagamento', caption: 'documentosFiscais.fields.dataLimitePagamento', dataType: 'date'},
            {
              dataField: 'valorImposto',
              caption: 'documentosFiscais.fields.valorImposto',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {
              dataField: 'valorJuros',
              caption: 'documentosFiscais.fields.valorJuros',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {dataField: 'dataEmissao', caption: 'documentosFiscais.fields.dataEmissao', dataType: 'date'},
            {dataField: 'situacaoNota', caption: 'documentosFiscais.fields.situacaoNota', alignment: 'center'},
            {dataField: 'refPagamento', caption: 'documentosFiscais.fields.refPagamento', alignment: 'center'},
            {
              dataField: 'vf_actions',
              cellTemplate: 'cellTemplateActions',
              caption: 'documentosFiscais.fields.emissao',
              alignment: 'center'
            }
          ],
          dataSource: new AsyncArrayStore({
            injector: this._injector,
            key: 'matricula',
            load: () => this._getTableSourceEmitirIUC()
          })
        },
        detailsDataGridDefinition: {
          keyExpr: 'idDocumento',
          columns: [
            {dataField: 'idDocumento', caption: 'documentosFiscais.fields.document'},
            {dataField: 'situacao', caption: 'documentosFiscais.fields.situacao'},
            {dataField: 'dataSituacao', caption: 'documentosFiscais.fields.dataSituacao', dataType: 'date'},
            {dataField: 'tipoNota', caption: 'documentosFiscais.fields.tipoNota'},
            {dataField: 'dataEmissao', caption: 'documentosFiscais.fields.dataEmissao', dataType: 'date'},
            {
              dataField: 'valorEmitido',
              caption: 'documentosFiscais.fields.valorEmitido',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {dataField: 'anoImposto', caption: 'documentosFiscais.fields.ano', alignment: 'center'},
            {dataField: 'refPagamento', caption: 'documentosFiscais.fields.refPagamento'},
            {dataField: 'dataLimitePagamento', caption: 'documentosFiscais.fields.dataLimitePagamento', dataType: 'date'},
            {
              dataField: 'valorPago',
              caption: 'documentosFiscais.fields.valorPago',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {dataField: 'qtdVeiculosLiquidados', caption: 'documentosFiscais.fields.qtdVeiculosLiquidados'}
          ],
          masterDetail: {
            enabled: true,
            template: 'liquidacoes'
          }
        },
        detailsDataGridInstanceName: 'documentosFiscaisIUCDetailsInstName',
        liquidacoesDataGridDefinition: {
          keyExpr: 'matricula',
          columns: [
            {dataField: 'matricula', caption: 'documentosFiscais.fields.matricula'},
            {dataField: 'categoria', caption: 'documentosFiscais.fields.categoria'},
            {dataField: 'mesMatricula', caption: 'documentosFiscais.fields.mesMatricula'},
            {
              dataField: 'adicionalIUC',
              caption: 'documentosFiscais.fields.adicionalIUC',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {
              dataField: 'valorImposto',
              caption: 'documentosFiscais.fields.valorImposto',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {
              dataField: '_docPdf',
              cellTemplate: 'cellTemplateComprovativo',
              caption: 'documentosFiscais.fields.comprovativo',
              alignment: 'center'
            }
          ]
        },
        liquidacoesDataGridInstanceName: 'documentosFiscaisIUCLiquidacoesInstName'
      },
      veiculos: {
        dataGridDefinition: {
          columns: [
            {dataField: 'matricula', caption: 'documentosFiscais.fields.matricula'},
            {dataField: 'dataMatricula', caption: 'documentosFiscais.fields.dataRecepcao', dataType: 'date'},
            {dataField: 'marca', caption: 'documentosFiscais.fields.marca'},
            {dataField: 'modelo', caption: 'documentosFiscais.fields.modelo'},
            {dataField: 'propLoc', caption: 'documentosFiscais.fields.propLoc'}
          ],
          dataSource: new AsyncArrayStore({
            injector: this._injector,
            key: 'matricula',
            load: () => this._getTableSourceVeiculos()
          }),
          remoteOperations: false,
          toolbar: {
            items: [
              {
                location: 'before',
                template: 'templateToolbarObterButton',
                locateInMenu: 'auto'
              }
            ]
          }
        },
        dataGridInstanceName: 'documentosFiscaisVeiculosInstName',
        dataGridInstance: undefined,
        menu: EDocumentosFiscaisMainMenu.VEICULOS,
        firstLoad: true
      },
      certidoes: {
        dataGridDefinition: {
          columns: [
            {dataField: 'certidaoId', caption: 'documentosFiscais.fields.identificacao'},
            {dataField: 'dataPedido', caption: 'documentosFiscais.fields.dataPedido', dataType: 'date'},
            {dataField: 'tipoCertidaoString', caption: 'documentosFiscais.fields.tipoCertidao'},
            {dataField: 'estado', caption: 'documentosFiscais.fields.estado', alignment: 'center'},
            {
              dataField: '_docPdf',
              cellTemplate: 'cellTemplateComprovativo',
              caption: 'documentosFiscais.fields.certidao',
              alignment: 'center'
            }
          ],
          dataSource: new AsyncArrayStore({
            injector: this._injector,
            key: 'certidaoId',
            load: () => this._getTableSourceCertidoes()
          }),
          remoteOperations: false
        },
        dataGridInstanceName: 'documentosFiscaisCertidoesInstName',
        dataGridInstance: undefined,
        menu: EDocumentosFiscaisMainMenu.CERTIDOES,
        firstLoad: true,
        startDate: moment().startOf('year'),
        untilDate: moment().endOf('year'),
        tipo: EDocumentosFiscaisCertidaoTipo.Todos,
        estado: EDocumentosFiscaisCertidaoEstado.Todos,
        pedidoTipoCertidao: EDocumentosFiscaisCertidaoTipo.LiquidacaoIRS,
        pedidoLiqIRSAno: moment().year()
      },
      imoveis: {
        dataGridDefinition: {
          columns: [
            {dataField: 'frg', caption: 'documentosFiscais.fields.freguesia'},
            {dataField: 'art', caption: 'documentosFiscais.fields.artigo'},
            {dataField: 'qP', caption: 'documentosFiscais.fields.parte'},
            {dataField: 'ano', caption: 'documentosFiscais.fields.ano'},
            {
              dataField: 'vIni',
              caption: 'documentosFiscais.fields.valorInicial',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {
              dataField: 'val',
              caption: 'documentosFiscais.fields.valor',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {
              dataField: '_cadernetaPredialPdf',
              cellTemplate: 'cellTemplateCadernetaPredial',
              caption: 'documentosFiscais.fields.cadernetaPredial',
              alignment: 'center'
            }
          ],
          dataSource: new AsyncArrayStore({
            injector: this._injector,
            key: 'id',
            load: () => this._getTableSourceImoveisPP()
          }),
          remoteOperations: false,
          toolbar: {
            items: [
              {
                location: 'before',
                template: 'templateToolbarObterButton',
                locateInMenu: 'auto'
              }
            ]
          }
        },
        dataGridInstanceName: 'documentosFiscaisImoveisPPInstName',
        dataGridInstance: undefined,
        menu: EDocumentosFiscaisMainMenu.IMOVEIS,
        firstLoad: true,
        ncDataGridDefinition: {
          columns: [
            {dataField: 'idNotaCobranca', caption: 'documentosFiscais.fields.idNotaCobranca'},
            {
              dataField: 'valor',
              caption: 'documentosFiscais.fields.valor',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {dataField: 'dataLimitePagamento', caption: 'documentosFiscais.fields.dataLimitePagamento', dataType: 'date'},
            {dataField: 'situacao', caption: 'documentosFiscais.fields.situacao'},
            {dataField: 'refMultibanco', caption: 'documentosFiscais.fields.refPagamento'},
            {dataField: 'numPredios', caption: 'documentosFiscais.fields.numPredios'},
            {
              dataField: '_haveGuia',
              cellTemplate: 'cellTemplateGuia',
              caption: 'documentosFiscais.fields.guia',
              alignment: 'center'
            }
          ],
          dataSource: new AsyncArrayStore({
            injector: this._injector,
            key: 'idNotaCobranca',
            load: () => this._getTableSourceImoveisNC()
          })
        },
        ncDataGridInstanceName: 'documentosFiscaisImoveisPPInstName',
        ncDataGridInstance: undefined,
        ncFirstLoad: true
      },
      penhoras: {
        dataGridDefinition: {
          columns: [
            {dataField: 'estado', caption: 'documentosFiscais.fields.estado'},
            {dataField: 'nPedidoPenhora', caption: 'documentosFiscais.fields.nPedidoPenhora'},
            {dataField: 'tipo', caption: 'documentosFiscais.fields.tipo'},
            {dataField: 'data', caption: 'documentosFiscais.fields.data', dataType: 'date'}
          ],
          dataSource: new AsyncArrayStore({
            injector: this._injector,
            key: 'nPedidoPenhora',
            load: () => this._getTableSourcePenhoras()
          }),
          remoteOperations: false,
          masterDetail: {
            enabled: true,
            template: 'detail'
          },
          toolbar: {
            items: [
              {
                location: 'before',
                template: 'templateToolbarObterButton',
                locateInMenu: 'auto'
              }
            ]
          }
        },
        dataGridInstanceName: 'documentosFiscaisPenhorasInstName',
        dataGridInstance: undefined,
        menu: EDocumentosFiscaisMainMenu.PENHORAS,
        firstLoad: true,
        detailsDataGridDefinition: {
          columns: [
            {dataField: 'descricaoBem', caption: 'documentosFiscais.fields.descricaoBem'},
            {dataField: 'codigoBem', caption: 'documentosFiscais.fields.codigoBem'},
            {dataField: 'qtdReconhecida', caption: 'documentosFiscais.fields.qtdReconhecida'},
            {dataField: 'unidade', caption: 'documentosFiscais.fields.unidade'},
            {
              dataField: 'valorUnit',
              caption: 'documentosFiscais.fields.valorUnit',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            }
          ]
        },
        detailsDataGridInstanceName: 'documentosFiscaisPenhorasDetailsInstName'
      },
      sssitcontrib: {
        dataGridDefinition: {
          columns: [
            {dataField: 'id', caption: 'documentosFiscais.fields.identificacao'},
            {dataField: 'dataRegisto', caption: 'documentosFiscais.fields.dataRegisto', dataType: 'date', alignment: 'center'},
            {dataField: 'dataResposta', caption: 'documentosFiscais.fields.dataResposta', dataType: 'date', alignment: 'center'},
            {dataField: 'resposta', caption: 'documentosFiscais.fields.resposta', alignment: 'center'},
            {dataField: 'pedidoPor', caption: 'documentosFiscais.fields.pedidoPor', alignment: 'center'},
            {
              dataField: 'haveDocument',
              cellTemplate: 'cellTemplateComprovativo',
              caption: 'documentosFiscais.fields.document',
              alignment: 'center'
            }
          ],
          dataSource: new AsyncArrayStore({
            injector: this._injector,
            key: 'id',
            load: () => this._getTableSourceSSSitContrib()
          }),
          remoteOperations: false,
          toolbar: {
            items: [
              {
                location: 'before',
                template: 'templateToolbarObterButton',
                locateInMenu: 'auto'
              }
            ]
          }
        },
        dataGridInstanceName: 'documentosFiscaisSSSitContribInstName',
        dataGridInstance: undefined,
        menu: EDocumentosFiscaisMainMenu.SS_SITUACAO_CONTRIB,
        firstLoad: true
      },
      viactt: {
        dataGridDefinition: {
          columns: [
            {dataField: 'messageType', caption: 'documentosFiscais.fields.tipo'},
            {dataField: 'entidade', caption: 'documentosFiscais.fields.entidade'},
            {dataField: 'conta', caption: 'documentosFiscais.fields.conta'},
            {dataField: 'idDocumento', caption: 'documentosFiscais.fields.document'},
            {dataField: 'dataEntrega', caption: 'documentosFiscais.fields.dataEntrega', dataType: 'date'},
            {dataField: 'lido', caption: 'documentosFiscais.fields.lido', dataType: 'boolean'},
            {dataField: 'tratado', caption: 'documentosFiscais.fields.tratado'},
            {dataField: 'pago', caption: 'documentosFiscais.fields.pago'},
            {dataField: 'valor', caption: 'documentosFiscais.fields.valor'},
            {
              dataField: 'comprovativoField',
              cellTemplate: 'cellTemplateComprovativo',
              caption: 'documentosFiscais.fields.comprovativo',
              alignment: 'center'
            }
          ],
          dataSource: new AsyncArrayStore({
            injector: this._injector,
            key: 'idDocumento',
            load: () => this._getTableSourceViaCTTInbox()
          }),
          remoteOperations: false,
          toolbar: {
            items: [
              {
                location: 'before',
                template: 'templateToolbarObterButton',
                locateInMenu: 'auto'
              }
            ]
          }
        },
        dataGridInstanceName: 'documentosFiscaisSSSitContribInstName',
        dataGridInstance: undefined,
        menu: EDocumentosFiscaisMainMenu.VIACTT_CAIXA_CORREIO,
        firstLoad: true
      }
    };
  }

  public onDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized, module: IDocumentosFiscaisModule): void {
    module.dataGridInstance = component;
  }

  public onDataGridInitializedIUCModule({component}: IDevExpressDataGridEventOnInitialized, module: IDocumentosFiscaisIUCModule): void {
    module.emitirDataGridInstance = component;
  }

  public onDataGridInitializedImoveisModule({component}: IDevExpressDataGridEventOnInitialized, module: IDocumentosFiscaisImoveisModule): void {
    module.ncDataGridInstance = component;
  }

  public fnTabIvaDpGetList(): void {
    this.modules.dpIva.firstLoad = false;
    if (isFunction(this.modules.dpIva.dataGridInstance?.refresh)) {
      this.modules.dpIva.dataGridInstance.refresh();
    }
  }

  public fnTabIvaDrGetList(): void {
    this.modules.drIva.firstLoad = false;
    if (isFunction(this.modules.drIva.dataGridInstance?.refresh)) {
      this.modules.drIva.dataGridInstance.refresh();
    }
  }

  public fnTabIRCPagAntGetList(): void {
    this.modules.ircPagAnt.firstLoad = false;
    if (isFunction(this.modules.ircPagAnt.dataGridInstance?.refresh)) {
      this.modules.ircPagAnt.dataGridInstance.refresh();
    }
  }

  public fnTabIRCMod22List(): void {
    this.modules.ircMod22.firstLoad = false;
    if (isFunction(this.modules.ircMod22.dataGridInstance?.refresh)) {
      this.modules.ircMod22.dataGridInstance.refresh();
    }
  }

  public fnTabRecibosRVGetList(): void {
    this.modules.recibosRV.firstLoad = false;
    if (isFunction(this.modules.recibosRV.dataGridInstance?.refresh)) {
      this.modules.recibosRV.dataGridInstance.refresh();
    }
  }

  public fnTabRecibosRRGetList(): void {
    this.modules.recibosRR.firstLoad = false;
    if (isFunction(this.modules.recibosRR.dataGridInstance?.refresh)) {
      this.modules.recibosRR.dataGridInstance.refresh();
    }
  }

  public fnTabIESGetList(): void {
    this.modules.ies.firstLoad = false;
    if (isFunction(this.modules.ies.dataGridInstance?.refresh)) {
      this.modules.ies.dataGridInstance.refresh();
    }
  }

  public fnTabIRSMod10GetList(): void {
    this.modules.irs.firstLoad = false;
    if (isFunction(this.modules.irs.dataGridInstance?.refresh)) {
      this.modules.irs.dataGridInstance.refresh();
    }
  }

  public fnTabRetencoesIRCIRSGetList(): void {
    this.modules.retencoesIRCIRS.firstLoad = false;
    if (isFunction(this.modules.retencoesIRCIRS.dataGridInstance?.refresh)) {
      this.modules.retencoesIRCIRS.dataGridInstance.refresh();
    }
  }

  public fnTabIUCDocumentosList(): void {
    this.modules.iuc.firstLoad = false;
    if (isFunction(this.modules.iuc.dataGridInstance?.refresh)) {
      this.modules.iuc.dataGridInstance.refresh();
    }
  }

  public fnTabIUCEmitirList(): void {
    this.modules.iuc.emitirFirstLoad = false;
    if (isFunction(this.modules.iuc.emitirDataGridInstance?.refresh)) {
      this.modules.iuc.emitirDataGridInstance.refresh();
    }
  }

  public fnTabVeiculosGetList(): void {
    this.modules.veiculos.firstLoad = false;
    if (isFunction(this.modules.veiculos.dataGridInstance?.refresh)) {
      this.modules.veiculos.dataGridInstance.refresh();
    }
  }

  public fnTabImoveisPPGetList(): void {
    this.modules.imoveis.firstLoad = false;
    if (isFunction(this.modules.imoveis.dataGridInstance?.refresh)) {
      this.modules.imoveis.dataGridInstance.refresh();
    }
  }

  public fnTabImoveisNCGetList(): void {
    this.modules.imoveis.ncFirstLoad = false;
    if (isFunction(this.modules.imoveis.ncDataGridInstance?.refresh)) {
      this.modules.imoveis.ncDataGridInstance.refresh();
    }
  }

  public fnTabCertidoesGetList(): void {
    this.modules.certidoes.firstLoad = false;
    if (isFunction(this.modules.certidoes.dataGridInstance?.refresh)) {
      this.modules.certidoes.dataGridInstance.refresh();
    }
  }

  public fnTabPenhorasGetList(): void {
    this.modules.penhoras.firstLoad = false;
    if (isFunction(this.modules.penhoras.dataGridInstance?.refresh)) {
      this.modules.penhoras.dataGridInstance.refresh();
    }
  }

  public fnTabSSSituacaoContribGetList(): void {
    this.modules.sssitcontrib.firstLoad = false;
    if (isFunction(this.modules.sssitcontrib.dataGridInstance?.refresh)) {
      this.modules.sssitcontrib.dataGridInstance.refresh();
    }
  }

  public fnTabViaCTTInboxGetList(): void {
    this.modules.viactt.firstLoad = false;
    if (isFunction(this.modules.viactt.dataGridInstance?.refresh)) {
      this.modules.viactt.dataGridInstance.refresh();
    }
  }

  public keydownEndDate(value: string, event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      const button: HTMLElement = document.querySelector('[data-attr-name="endDate"] input');
      event.stopPropagation();
      if (button) {
        setTimeout(() => {
          button.focus();
        });
      }
    }
  }

  public keydownNif(value: string, event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      const button: HTMLElement = document.querySelector('[data-attr-name="nif"] input');
      event.stopPropagation();
      if (button) {
        setTimeout(() => {
          button.focus();
        });
      }
    }
  }

  public keydownObter(value: string, event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      const button: HTMLElement = document.querySelector('button[name="btnObter"]');
      event.stopPropagation();
      if (button) {
        setTimeout(() => {
          button.focus();
        });
      }
    }
  }

  public showDpIvaComprovativoPdf(item: IDocumentosFiscaisDpIvaItem): void {
    const modalTitle = this._translateService.instant('documentosFiscais.strings.comprovativo', {
      title: this._translateService.instant('documentosFiscais.tabIvaDp'),
      id: item.numeroDeclaracao
    });
    this._cgModalService.showPdf(modalTitle, this._atService.getDpIvaComprovativoURL(item.declId));
  }

  public showIUCPagamentoPdf(item: IDocumentosFiscaisIUCItem): void {
    const modalTitle = this._translateService.instant('documentosFiscais.strings.modalTitleGuiaPagamento', {
      title: this._translateService.instant('documentosFiscais.tabIUC'),
      id: item.numDocumento
    });
    this._cgModalService.showPdf(modalTitle, this._atService.getIUCGuiaPagamentoURL(this.filterAno, item.numDocumento));
  }

  public showIUCLiquidacaoComprovativoPdf(item: IAtCertidao): void {
    const modalTitle = this._translateService.instant('documentosFiscais.strings.comprovativo', {
      title: this._translateService.instant('documentosFiscais.tabCertidoes'),
      id: item.tipoCertidaoString
    });
    this._cgModalService.showPdf(modalTitle, this._atService.getCertidaoDocumentoURL(item.certidaoId, item.tipoCertidao));
  }

  public showCadernetaPredialPdf(item: IAtImoveisPatrimnioPredial): void {
    const modalTitle = this._translateService.instant('documentosFiscais.strings.cadernetaPredial', {
      title: this._translateService.instant('documentosFiscais.tabImoveis'),
      artigo: item.art,
      ano: item.ano
    });
    this._cgModalService.showPdf(modalTitle, this._atService.getImoveisDocumentoURL(item.id));
  }

  public showImoveisNCGuiaPdf(item: IDocumentosFiscaisImoveisNotasCobrancaItem): void {
    const modalTitle = this._translateService.instant('documentosFiscais.strings.comprovativo', {
      title: this._translateService.instant('documentosFiscais.tabImoveisNC'),
      id: item.idNotaCobranca
    });
    this._cgModalService.showPdf(modalTitle, this._atService.getImoveisGuiaNCURL(this.filterAno, item.idNotaCobranca));
  }

  public showDpIvaPagamentoPdf(item: IDocumentosFiscaisDpIvaItem): void {
    this._viewDocPagamentoDpIva(item);
  }

  public showDrIvaComprovativoPdf(item: IDocumentosFiscaisDrIvaItem): void {
    const modalTitle = this._translateService.instant('documentosFiscais.strings.comprovativo', {
      title: this._translateService.instant('documentosFiscais.tabIvaDr'),
      id: item.decId
    });
    this._cgModalService.showPdf(modalTitle, this._atService.getDrIvaComprovativoURL(item.decId));
  }

  public showIrcPagAntDetalhesPdf(item: IDocumentosFiscaisIRCPagAntItem): void {
    const modalTitle = this._translateService.instant('documentosFiscais.strings.detalhesDoc', {
      title: this._translateService.instant('documentosFiscais.tabIRCPagAnt'),
      id: item.numero
    });
    this._cgModalService.showPdf(modalTitle, this._atService.getIRCPagamentosAntecipadosComprovativoURL(item.nguia));
  }

  public showIrcMod22ComprovativoPdf(item: IDocumentosFiscaisIRCMod22Item): void {
    const modalTitle = this._translateService.instant('documentosFiscais.strings.comprovativo', {
      title: this._translateService.instant('documentosFiscais.tabIRCMod22'),
      id: item.declId
    });
    this._cgModalService.showPdf(modalTitle, this._atService.getIRCMod22ComprovativoURL(item.declId));
  }

  public showIrcMod22PagamentoPdf(item: IDocumentosFiscaisIRCMod22Item): void {
    const modalTitle = this._translateService.instant('documentosFiscais.strings.docPagamento', {
      title: this._translateService.instant('documentosFiscais.tabIRCMod22'),
      id: item.declId
    });
    this._cgModalService.showPdf(modalTitle, this._atService.getIRCMod22DocPagamentoURL(item.declId));
  }

  public showRecibosRVPdf(item: IDocumentosFiscaisRecibosRVItem): void {
    const modalTitle = this._translateService.instant('documentosFiscais.strings.recibo', {
      title: this._translateService.instant('documentosFiscais.tabRecibosRVClient'),
      id: `${item.nifPrestadorServicos} - ${item.numDocumento}`
    });
    this._cgModalService.showPdf(modalTitle, this._atService.getReciboVerdeComprovativoURL(item.nifPrestadorServicos, item.numDocumento, false, item.userId));
  }

  public showRecibosRRComprovativoPdf(item: IDocumentosFiscaisRecibosRRItem): void {
    const modalTitle = this._translateService.instant('documentosFiscais.strings.recibo', {
      title: this._translateService.instant('documentosFiscais.tabRecibosRR'),
      id: `${item.numeroContrato}/${item.numeroRecibo}`
    });
    this._cgModalService.showPdf(modalTitle, this._atService.getReciboRendaComprovativoURL(item.numeroContrato, item.numeroRecibo));
  }

  public showIesComprovativoPdf(item: IDocumentosFiscaisIESItem): void {
    const modalTitle = this._translateService.instant('documentosFiscais.strings.comprovativo', {
      title: this._translateService.instant('documentosFiscais.tabIES'),
      id: item.declID
    });
    this._cgModalService.showPdf(modalTitle, this._atService.getIESComprovativoURL(item.declID, item.ano, item.situacaoVigente));
  }

  public showIesDadosPagamento(item: IDocumentosFiscaisIESItem): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DocumentosFiscaisIesPagamentoModalComponent, {size: 'sm'});
    const componentInstance: DocumentosFiscaisIesPagamentoModalComponent = modalInstance.componentInstance;
    componentInstance.idPagamento = item.idPagamento;
    return modalInstance.result;
  }

  public showIrsComprovativoPdf(item: IDocumentosFiscaisIRSMod10Item, is2Via: boolean): void {
    if (!is2Via) {
      const modalTitle = this._translateService.instant('documentosFiscais.strings.comprovativo', {
        title: this._translateService.instant('documentosFiscais.tabIRSMod10'),
        id: item.decId
      });
      this._cgModalService.showPdf(modalTitle, this._atService.getIRSMod10ComprovativoURL(item.decId));
    } else {
      const modalTitle = this._translateService.instant('documentosFiscais.strings.via2comprovativo', {
        title: this._translateService.instant('documentosFiscais.tabIRSMod10'),
        id: item.decId
      });
      this._cgModalService.showPdf(modalTitle, this._atService.getIRSMod10ComprovativoURL(item.via2Id));
    }
  }

  public showRetencoesIRCIRSComprovativoPdf(item: IDocumentosFiscaisRetencoesIRCIRSItem): void {
    const modalTitle = this._translateService.instant('documentosFiscais.strings.comprovativo', {
      title: this._translateService.instant('documentosFiscais.tabRetencoesIRCIRS'),
      id: item.nDoc
    });
    this._cgModalService.showPdf(modalTitle, this._atService.getRetencoesIRCIRSComprovativoURL(item.nDoc));
  }

  public showCertidaoPdf(item: IAtCertidao): void {
    const modalTitle = this._translateService.instant('documentosFiscais.strings.comprovativo', {
      title: this._translateService.instant('documentosFiscais.tabCertidoes'),
      id: item.certidaoId
    });
    this._cgModalService.showPdf(modalTitle, this._atService.getCertidaoDocumentoURL(item.certidaoId, item.tipoCertidao));
  }

  public showSSSitContribPdf(item: ISSSituacaoContributiva): void {
    const modalTitle = this._translateService.instant('documentosFiscais.strings.comprovativo', {
      title: this._translateService.instant('documentosFiscais.tabSSSituacaoContrib'),
      id: item.id
    });
    this._cgModalService.showPdf(modalTitle, this._atService.getSSSituacaoContributivaDocURL(item.id));
  }

  public showViaCTTDocPdf(item: IViaCTTDocument): void {
    const modalTitle = this._translateService.instant('documentosFiscais.strings.comprovativo', {
      title: this._translateService.instant('documentosFiscais.tabViaCTTInbox'),
      id: item.idDocumento
    });
    this._cgModalService.showPdf(modalTitle, this._atService.getViaCTTDocURL(item.accountId, item.billerId, item.userId, item.idDocumento));
  }

  public associarDocumentoContabDigital =
    <T extends object>(module: IDocumentosFiscaisModule, documento: T, propertyName: keyof T) =>
    async (): Promise<void> => {
      const moduleStore: AsyncArrayStore<T, T> = <AsyncArrayStore<T, T>>module.dataGridInstance.getDataSource().store();
      let documentos: Array<T> = moduleStore.items();
      if (!documentos.length) {
        this._plAlertService.info('documentosFiscais.strings.emptyDocs');
        return;
      }

      const date: TDate = documento[propertyName];
      documentos = documentos.filter((item: T) => moment(item[propertyName]).isSame(date, 'month'));

      // Selecionar documentos
      const modalInstance = this._cgModalService.showVanilla(DocumentosFiscaisAssociarDocumentosContabDigitalModalComponent, {size: 'xl'});
      const componentInstance: DocumentosFiscaisAssociarDocumentosContabDigitalModalComponent<T> = modalInstance.componentInstance;
      componentInstance.module = module;
      componentInstance.date = date;
      componentInstance.documento = documento;
      componentInstance.documentos = documentos;
      if (!propertyName) {
        componentInstance.ano = this.filterAno;
      }

      switch (module.menu) {
        case EDocumentosFiscaisMainMenu.IES:
          break;
        case EDocumentosFiscaisMainMenu.IRS:
          break;
        case EDocumentosFiscaisMainMenu.VEICULOS:
          break;
        case EDocumentosFiscaisMainMenu.CERTIDOES:
          break;
        case EDocumentosFiscaisMainMenu.PENHORAS:
          break;
        case EDocumentosFiscaisMainMenu.SS_SITUACAO_CONTRIB:
          break;
        case EDocumentosFiscaisMainMenu.VIACTT_CAIXA_CORREIO:
          break;
        case EDocumentosFiscaisMainMenu.RECIBOS:
          componentInstance.folderName = this._translateService.instant('documentosFiscais.tabRecibos');
          break;
        case EDocumentosFiscaisMainMenu.IVA:
          componentInstance.folderName = this._translateService.instant('documentosFiscais.tabIva');
          break;
        case EDocumentosFiscaisMainMenu.IRC:
          componentInstance.folderName = this._translateService.instant('documentosFiscais.tabIRC');
          break;
        case EDocumentosFiscaisMainMenu.RETENCOES:
          componentInstance.folderName = this._translateService.instant('documentosFiscais.tabRetencoes');
          break;
        case EDocumentosFiscaisMainMenu.IUC:
          componentInstance.folderName = this._translateService.instant('documentosFiscais.tabIUC');
          break;
        case EDocumentosFiscaisMainMenu.IMOVEIS:
          componentInstance.folderName = this._translateService.instant('documentosFiscais.tabImoveis');
          break;
      }

      const selectedDocumentos: Array<T> = <Array<T>>await modalInstance.result;

      // Selecionar pasta
      const {folderID}: IContabilidadeDigitalTreeViewerFolder = await this._cgModalService.show(DocsDigitaisTreeDocsNaoClassificadosModalComponent, {size: 'md'});

      // Associar documentos
      const progressModal = this._cgModalService.showVanilla(DocumentosFiscaisProgressModalComponent, {size: 'md', backdrop: 'static', keyboard: false});

      await this._atService.associarDocumentosContabDigital(module.menu, selectedDocumentos, folderID, this.activeTab).finally(() => {
        progressModal.close();
      });
      this._plAlertService.success('documentosFiscais.strings.successAssociarComprovativoContabDigital');
    };

  public mainMenuClick(menuItem: IDocumentosFiscaisMainMenuItem, e: Event): void {
    e.preventDefault();
    this._setFirstLoad(menuItem.menu);
    this.activeMenu = menuItem.menu;
    switch (this.activeMenu) {
      case EDocumentosFiscaisMainMenu.IRC:
        this.activeTab = EDocumentosFiscaisTab.IrcPagAnt;
        break;
      case EDocumentosFiscaisMainMenu.RECIBOS:
        this.activeTab = EDocumentosFiscaisTab.RecibosRV;
        break;
      case EDocumentosFiscaisMainMenu.IES:
        break;
      case EDocumentosFiscaisMainMenu.IRS:
        break;
      case EDocumentosFiscaisMainMenu.VEICULOS:
        break;
      case EDocumentosFiscaisMainMenu.CERTIDOES:
        break;
      case EDocumentosFiscaisMainMenu.PENHORAS:
        break;
      case EDocumentosFiscaisMainMenu.SS_SITUACAO_CONTRIB:
        break;
      case EDocumentosFiscaisMainMenu.VIACTT_CAIXA_CORREIO:
        break;
      default:
        this.activeTab = EDocumentosFiscaisTab.None;
    }
  }

  public onYearChange(year: number): void {
    this.filterAno = year;
    this.modules.recibosRV.startDate = moment(this.modules.recibosRV.startDate).year(year);
    this.modules.recibosRV.endDate = moment(this.modules.recibosRV.endDate).year(year);
    this.modules.recibosRR.startDate = moment(this.modules.recibosRR.startDate).year(year);
    this.modules.recibosRR.endDate = moment(this.modules.recibosRR.endDate).year(year);
  }

  public onRVStartDateChange(value: TDate): void {
    const d = moment(value);
    this.modules.recibosRV.startDate = d;
    this.filterAno = d.year();
    this.modules.recibosRV.endDate = moment(this.modules.recibosRV.endDate).year(this.filterAno);
    this.modules.recibosRR.startDate = moment(this.modules.recibosRR.startDate).year(this.filterAno);
    this.modules.recibosRR.endDate = moment(this.modules.recibosRR.endDate).year(this.filterAno);
  }

  public onRRStartDateChange(value: TDate): void {
    const d = moment(value);
    this.modules.recibosRR.startDate = d;
    this.filterAno = d.year();
    this.modules.recibosRR.endDate = moment(this.modules.recibosRR.endDate).year(this.filterAno);
    this.modules.recibosRV.startDate = moment(this.modules.recibosRV.startDate).year(this.filterAno);
    this.modules.recibosRV.endDate = moment(this.modules.recibosRV.endDate).year(this.filterAno);
  }

  public onDataGridRecibosRVCellClick(event: IDevExpressDataGridEventOnCellClick<IDocumentosFiscaisRecibosRVItem>): void {
    if (event.column.type !== 'adaptive' && event.column.type !== 'selection' && event.column.dataField !== '_hasRecibo') {
      devExpressDataGridExpandDetailHandler(event);
    }
  }

  public onDataGridRecibosRRCellClick(event: IDevExpressDataGridEventOnCellClick<IDocumentosFiscaisRecibosRRItem>): void {
    if (event.column.type !== 'adaptive' && event.column.type !== 'selection' && event.column.dataField !== '_hasDocument') {
      devExpressDataGridExpandDetailHandler(event, () => this._onRecibosRRRowExpand(event.data));
    }
  }

  public emitirGrupoChanged(value: EDocumentosFiscaisIUCEmitir): void {
    this.modules.iuc.emitirGrupo = value;
  }

  public emitirIUC(item: IDocumentosFiscaisEmitirIUCItem): void {
    const message = this._translateService.instant('documentosFiscais.strings.modalMessageEmitirIUC', {matricula: item.matricula});
    this._cgModalService
      .showOkCancel('documentosFiscais.strings.modalTitleConfirmation', message, {
        size: 'md',
        backdrop: 'static',
        keyboard: false
      })
      .then(() => {
        this._atService.postIUCEmitir(this.modules.iuc.emitirGrupo, item.matricula).then((response) => {
          if (response.body.success) {
            this._plAlertService.success('documentosFiscais.strings.emitidoComSucesso');
            this.fnTabIUCEmitirList();
          } else {
            this._plAlertService.error(response.body.errorMessage);
          }
        });
      });
  }

  public onDataGridCellClickSimpleExpand(event: IDevExpressDataGridEventOnCellClick): void {
    devExpressDataGridExpandDetailHandler(event);
  }

  public async fnTabCertidoesEmitir(): Promise<void> {
    let certidaoNome: string;
    if (this.modules.certidoes.pedidoTipoCertidao === EDocumentosFiscaisCertidaoTipo.LiquidacaoIRS) {
      certidaoNome = this._translateService.instant('documentosFiscais.strings.liquidacaoIRS');
    } else if (this.modules.certidoes.pedidoTipoCertidao === EDocumentosFiscaisCertidaoTipo.DividaNaoDivida) {
      certidaoNome = this._translateService.instant('documentosFiscais.strings.dividaNaoDivida');
    } else {
      certidaoNome = this._translateService.instant('documentosFiscais.strings.sujPassivoRecupIVAEstrangeiro');
    }
    const message = this._translateService.instant('documentosFiscais.strings.modalMessagePedirCertidao', {nome: certidaoNome});
    await this._cgModalService.showOkCancel('documentosFiscais.strings.modalTitleConfirmation', message, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
    const url = this._atService.getCertidaoPedidoURL(this.modules.certidoes.pedidoLiqIRSAno, this.modules.certidoes.pedidoTipoCertidao);
    await this._cgModalService.showPdf(this._translateService.instant('documentosFiscais.strings.comprovativoSimpleTtile'), url);
  }

  private async _onRecibosRRRowExpand(item: IDocumentosFiscaisRecibosRRItem): Promise<void> {
    if (!item.contrato) {
      const responseContrato: HttpResponse<IAtRecibosRendasContratoLocatario> = await this._atService.getReciboRendaContratoLocatario(item.numeroContrato);
      item.contrato = responseContrato.body;
    }
    if (!item.reciboDetails) {
      const responseRecibo: HttpResponse<IAtRecibosRendasReciboDetails> = await this._atService.getReciboRendaReciboDetails(item.numeroContrato, item.numeroRecibo);
      item.reciboDetails = responseRecibo.body;
    }
  }

  private _getTableSourceDpIva(): Promise<Array<IDocumentosFiscaisDpIvaItem>> {
    if (this.modules.dpIva.firstLoad) {
      return Promise.resolve(undefined);
    }

    return new Promise<Array<IDocumentosFiscaisDpIvaItem>>((resolve, reject) => {
      this._atService.getDpIvaList(this.filterAno).then(
        (response) => {
          const source: Array<IDocumentosFiscaisDpIvaItem> = response.body.data;
          for (const item of source) {
            item.valor1Dec = item.valor1 / 100;
            item.valor2Dec = item.valor2 / 100;
            item._hasComprovativo = true;
            item._hasDocPagamento = item.valor1 > item.valor2;
          }
          resolve(source);
        },
        (reason) => {
          this._handleException(reason)
            .then(() => {
              this.fnTabIvaDpGetList();
            })
            .catch(reject);
        }
      );
    });
  }

  private _getTableSourceDrIva(): Promise<Array<IDocumentosFiscaisDrIvaItem>> {
    if (this.modules.drIva.firstLoad) {
      return Promise.resolve(undefined);
    }
    return new Promise<Array<IDocumentosFiscaisDrIvaItem>>((resolve, reject) => {
      this._atService
        .getDrIvaList(this.filterAno)
        .then((response) => {
          const source: Array<IDocumentosFiscaisDrIvaItem> = response.body.data;
          for (const item of source) {
            item._hasComprovativo = item.comprovativoDecId !== '';
          }
          resolve(source);
        })
        .catch((reason) => {
          this._handleException(reason)
            .then(() => {
              resolve(undefined);
              this.fnTabIvaDrGetList();
            })
            .catch(reject);
        });
    });
  }

  private _getTableSourceIRCPagAnt(): Promise<Array<IDocumentosFiscaisIRCPagAntItem>> {
    if (this.modules.ircPagAnt.firstLoad) {
      return Promise.resolve(undefined);
    }

    return new Promise<Array<IDocumentosFiscaisIRCPagAntItem>>((resolve, reject) => {
      this._atService.getIRCPagamentosAntecipadosList(this.filterAno).then(
        (response) => {
          const source: Array<IDocumentosFiscaisIRCPagAntItem> = response.body.data;
          for (const item of source) {
            item._hasDetalhes = item.nguia !== '';
          }
          resolve(source);
        },
        (reason) => {
          this._handleException(reason)
            .then(() => {
              this.fnTabIRCPagAntGetList();
            })
            .catch(reject);
        }
      );
    });
  }

  private _getTableSourceIrcMod22(): Promise<Array<IDocumentosFiscaisIRCMod22Item>> {
    if (this.modules.ircMod22.firstLoad) {
      return Promise.resolve(undefined);
    }

    return new Promise<Array<IDocumentosFiscaisIRCMod22Item>>((resolve, reject) => {
      this._atService.getIRCMod22List(this.filterAno).then(
        (response) => {
          const source: Array<IDocumentosFiscaisIRCMod22Item> = response.body.data;
          for (const item of source) {
            item.situacaoStr = item.situacao.label;
            item._hasComprovativo = item.declId !== '';
            item._hasDocPagamento = item.declId !== '';
          }
          resolve(source);
        },
        (reason) => {
          this._handleException(reason)
            .then(() => {
              this.fnTabIRCMod22List();
            })
            .catch(reject);
        }
      );
    });
  }

  private _getTableSourceRecibosRV(): Promise<Array<IDocumentosFiscaisRecibosRVItem>> {
    if (this.modules.recibosRV.firstLoad) {
      return Promise.resolve(undefined);
    }

    return new Promise<Array<IDocumentosFiscaisRecibosRVItem>>((resolve, reject) => {
      this._atService.getRecibosVerdesList(this.modules.recibosRV.startDate, this.modules.recibosRV.endDate, this.modules.recibosRV.nifPrestador).then(
        (response) => {
          const source: Array<IDocumentosFiscaisRecibosRVItem> = response.body.data;
          for (const item of source) {
            item._hasRecibo = true;
          }
          resolve(source);
        },
        (reason) => {
          this._handleException(reason)
            .then(() => {
              this.fnTabRecibosRVGetList();
            })
            .catch(reject);
        }
      );
    });
  }

  private _getTableSourceRecibosRR(): Promise<Array<IDocumentosFiscaisRecibosRRItem>> {
    if (this.modules.recibosRR.firstLoad) {
      return Promise.resolve(undefined);
    }

    return new Promise<Array<IDocumentosFiscaisRecibosRRItem>>((resolve, reject) => {
      this._atService.getRecibosRendasList(this.modules.recibosRR.startDate, this.modules.recibosRR.endDate).then(
        (response) => {
          const source: Array<IDocumentosFiscaisRecibosRRItem> = response.body.data;
          for (const item of source) {
            item.recRefContrato = `${item.referencia} (${item.imovel.alternateId})`;
            item.periodoFull = `${moment(item.dataInicio).format(this.format.momentDate)} - ${moment(item.dataFim).format(this.format.momentDate)}`;
            item._hasDocument = true;
          }
          resolve(source);
        },
        (reason) => {
          this._handleException(reason)
            .then(() => {
              this.fnTabRecibosRRGetList();
            })
            .catch(reject);
        }
      );
    });
  }

  private _getTableSourceIES(): Promise<Array<IDocumentosFiscaisIESItem>> {
    if (this.modules.ies.firstLoad) {
      return Promise.resolve(undefined);
    }

    return new Promise<Array<IDocumentosFiscaisIESItem>>((resolve, reject) => {
      this._atService.getIESList().then(
        (response) => {
          const source: Array<IDocumentosFiscaisIESItem> = response.body.data;
          for (const item of source) {
            item._hasComprovativo = true;
          }
          resolve(source);
        },
        (reason) => {
          this._handleException(reason)
            .then(() => {
              this.fnTabIESGetList();
            })
            .catch(reject);
        }
      );
    });
  }

  private _getTableSourceIRSMod10(): Promise<Array<IDocumentosFiscaisIRSMod10Item>> {
    if (this.modules.irs.firstLoad) {
      return Promise.resolve([]);
    }

    return new Promise<Array<IDocumentosFiscaisIRSMod10Item>>((resolve, reject) => {
      this._atService.getIRSMod10List().then(
        (response) => {
          resolve(response.body.data);
        },
        (reason) => {
          this._handleException(reason)
            .then(() => {
              this.fnTabIRSMod10GetList();
            })
            .catch(reject);
        }
      );
    });
  }

  private _getTableSourceRetencoesIRCIRS(): Promise<Array<IDocumentosFiscaisRetencoesIRCIRSItem>> {
    if (this.modules.retencoesIRCIRS.firstLoad) {
      return Promise.resolve([]);
    }

    return new Promise<Array<IDocumentosFiscaisRetencoesIRCIRSItem>>((resolve, reject) => {
      this._atService.getRetencoesIRCIRSList(this.filterAno).then(
        (response) => {
          const source: Array<IDocumentosFiscaisRetencoesIRCIRSItem> = response.body.data;
          for (const item of source) {
            if (isString(item.aPagar)) {
              item.aPagar = parseFloat((<string>item.aPagar).replace(',', '.'));
            }
            item._hasComprovativo = true;
          }
          resolve(source);
        },
        (reason) => {
          this._handleException(reason)
            .then(() => {
              this.fnTabRetencoesIRCIRSGetList();
            })
            .catch(reject);
        }
      );
    });
  }

  private _getTableSourceIUC(): Promise<Array<IDocumentosFiscaisIUCItem>> {
    if (this.modules.iuc.firstLoad) {
      return Promise.resolve(undefined);
    }

    return new Promise<Array<IDocumentosFiscaisIUCItem>>((resolve, reject) => {
      this._atService.getIUCList(this.filterAno).then(
        (response) => {
          const source: Array<IDocumentosFiscaisIUCItem> = response.body.data.documents;
          for (const item of source) {
            item._hasDocPagamento = true;
            for (const liqItem of item.details.liquidacoes) {
              liqItem.numDocumento = item.numDocumento;
              liqItem.anoImposto = item.details.anoImposto;
            }
          }
          resolve(source);
        },
        (reason) => {
          this._handleException(reason)
            .then(() => {
              this._getTableSourceIUC();
            })
            .catch(reject);
        }
      );
    });
  }

  private _getTableSourceEmitirIUC(): Promise<Array<IDocumentosFiscaisEmitirIUCItem>> {
    if (this.modules.iuc.emitirFirstLoad) {
      return Promise.resolve(undefined);
    }

    return new Promise<Array<IDocumentosFiscaisEmitirIUCItem>>((resolve, reject) => {
      this._atService.getIUCListaParaEmitir(this.modules.iuc.emitirGrupo).then(
        (response) => {
          const source: Array<IDocumentosFiscaisEmitirIUCItem> = response.body.data;
          for (const item of source) {
            item._canIssue = item.dataEmissao === '' || item.dataEmissao === '-';
          }
          resolve(source);
        },
        (reason) => {
          this._handleException(reason)
            .then(() => {
              this._getTableSourceEmitirIUC();
            })
            .catch(reject);
        }
      );
    });
  }

  private _getTableSourceVeiculos(): Promise<Array<IAtVeiculo>> {
    if (this.modules.veiculos.firstLoad) {
      return Promise.resolve(undefined);
    }

    return new Promise<Array<IAtVeiculo>>((resolve, reject) => {
      this._atService.getVeiculosList().then(
        (response) => {
          resolve(response.body.data);
        },
        (reason) => {
          this._handleException(reason)
            .then(() => {
              this._getTableSourceVeiculos();
            })
            .catch(reject);
        }
      );
    });
  }

  private _getTableSourceSSSitContrib(): Promise<Array<ISSSituacaoContributiva>> {
    if (this.modules.sssitcontrib.firstLoad) {
      return Promise.resolve(undefined);
    }

    return new Promise<Array<ISSSituacaoContributiva>>((resolve, reject) => {
      this._atService.getSSSituacaoContributivaList().then(
        (response) => {
          resolve(response.body.data);
        },
        (reason) => {
          this._handleException(reason)
            .then(() => {
              this._getTableSourceSSSitContrib();
            })
            .catch(reject);
        }
      );
    });
  }

  private _getTableSourceViaCTTInbox(): Promise<Array<IViaCTTDocument>> {
    if (this.modules.viactt.firstLoad) {
      return Promise.resolve(undefined);
    }

    return new Promise<Array<IViaCTTDocument>>((resolve, reject) => {
      this._atService.getViaCTTList().then(
        (response) => {
          resolve(response.body.data);
        },
        (reason) => {
          this._handleException(reason)
            .then(() => {
              this._getTableSourceViaCTTInbox();
            })
            .catch(reject);
        }
      );
    });
  }

  private _getTableSourceCertidoes(): Promise<Array<IAtCertidao>> {
    if (this.modules.certidoes.firstLoad) {
      return Promise.resolve(undefined);
    }

    return new Promise<Array<IAtCertidao>>((resolve, reject) => {
      this._atService.getCertidaoList(this.modules.certidoes.startDate, this.modules.certidoes.untilDate, this._getFiltroCertidaoTipo(), this._getFiltroCertidaoEstado()).then(
        (response) => {
          const source: Array<IAtCertidao> = response.body.data;
          for (const item of source) {
            if (item.estado === '') {
              item.estado = 'Ativa';
            } else if (item.estado === 'E') {
              item.estado = 'Expirada';
            }
          }
          resolve(source);
        },
        (reason) => {
          this._handleException(reason)
            .then(() => {
              this._getTableSourceCertidoes();
            })
            .catch(reject);
        }
      );
    });
  }

  private _getTableSourceImoveisPP(): Promise<Array<IAtImoveisPatrimnioPredial>> {
    if (this.modules.imoveis.firstLoad) {
      return Promise.resolve(undefined);
    }

    return new Promise<Array<IAtImoveisPatrimnioPredial>>((resolve, reject) => {
      this._atService.getImoveisPPList().then(
        (response) => {
          resolve(response.body.data);
        },
        (reason) => {
          this._handleException(reason)
            .then(() => {
              this._getTableSourceImoveisPP();
            })
            .catch(reject);
        }
      );
    });
  }

  private _getTableSourceImoveisNC(): Promise<Array<IDocumentosFiscaisImoveisNotasCobrancaItem>> {
    if (this.modules.imoveis.ncFirstLoad) {
      return Promise.resolve(undefined);
    }

    return new Promise<Array<IDocumentosFiscaisImoveisNotasCobrancaItem>>((resolve, reject) => {
      this._atService.getImoveisNCList(this.filterAno).then(
        (response) => {
          const source: Array<IDocumentosFiscaisImoveisNotasCobrancaItem> = response.body.data;
          for (const item of source) {
            item._haveGuia = false;
            const arr = item.situacao.split('-');
            if (arr.length) {
              const codigo = parseInt(arr[0], ERadix.Decimal);
              item._haveGuia = codigo === IMOVEL_NC_SITUACAO_CODIGO_EMITIDA || codigo === IMOVEL_NC_SITUACAO_CODIGO_PAGA || codigo === IMOVEL_NC_SITUACAO_CODIGO_PAGA_FORA_PRAZO;
            }
          }
          resolve(source);
        },
        (reason) => {
          this._handleException(reason)
            .then(() => {
              this._getTableSourceImoveisNC();
            })
            .catch(reject);
        }
      );
    });
  }

  private _getTableSourcePenhoras(): Promise<Array<IAtPenhora>> {
    if (this.modules.penhoras.firstLoad) {
      return Promise.resolve(undefined);
    }

    return new Promise<Array<IAtPenhora>>((resolve, reject) => {
      this._atService.getPenhorasList().then(
        (response) => {
          resolve(response.body.data);
        },
        (reason) => {
          this._handleException(reason)
            .then(() => {
              this._getTableSourceVeiculos();
            })
            .catch(reject);
        }
      );
    });
  }

  private _getFiltroCertidaoTipo(): string {
    return this.modules.certidoes.tipo === EDocumentosFiscaisCertidaoTipo.Todos ? '' : this.modules.certidoes.tipo;
  }

  private _getFiltroCertidaoEstado(): string {
    return this.modules.certidoes.estado === EDocumentosFiscaisCertidaoEstado.Todos ? '' : this.modules.certidoes.estado;
  }

  private _handleException(error: HttpResponseBase): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const err = this._cgExceptionService.get(error);
      const loginModalType = this._checkLoginError(err.class);
      this._plAlertService.error(err.message);
      if (loginModalType !== EDocumentosFiscaisCredentialsModalType.NONE) {
        this._callModalCredentials(loginModalType)
          .then(() => {
            this._plAlertService.success('simpleLogin.messages.successLogin');
            resolve(true);
          })
          .catch(reject);
      } else {
        reject(error);
      }
    });
  }

  private _viewDocPagamentoDpIva(item: IDocumentosFiscaisDpIvaItem): void {
    const modalInstance = this._cgModalService.showVanilla(DocumentosFiscaisDpIvaDocPagamentoModalComponent, {size: 'md'});
    const componentInstance: DocumentosFiscaisDpIvaDocPagamentoModalComponent = modalInstance.componentInstance;
    componentInstance.ivaEntregar = item.valor1Dec;
    modalInstance.result.then((result: IDocumentosFiscaisDpIvaDocPagementoModel) => {
      const url = this._atService.getDpIvaDocPagamentoURL(item.declId, result.type === EDpIvaDocPagamento.DeclaracaoSubstituicao, result.ivaPago * 100);
      const modalTitle = this._translateService.instant('documentosFiscais.strings.docPagamento', {
        title: this._translateService.instant('documentosFiscais.tabIvaDp'),
        id: item.declId
      });
      this._cgModalService.showPdf(modalTitle, url, {size: 'xl'});
    });
  }

  private _callModalCredentials(type: EDocumentosFiscaisCredentialsModalType): Promise<unknown> {
    if (type === EDocumentosFiscaisCredentialsModalType.SSD) {
      const modalInstance = this._cgModalService.showVanilla(ConfigLoginWsSSModalComponent, {size: 'md'});
      const componentInstance: ConfigLoginWsSSModalComponent = modalInstance.componentInstance;
      componentInstance.nEmpresa = this.configurations.empresa.nEmpresa;
      return modalInstance.result;
    }

    const modalInstance = this._cgModalService.showVanilla(SimpleLoginModalComponent, {size: 'sm', backdrop: 'static'});
    const componentInstance: SimpleLoginModalComponent = modalInstance.componentInstance;
    switch (type) {
      case EDocumentosFiscaisCredentialsModalType.AT:
        componentInstance.loginType = ESimpleLoginType.AT;
        break;
      case EDocumentosFiscaisCredentialsModalType.VIACTT:
        componentInstance.loginType = ESimpleLoginType.VIACTT;
        break;
      default:
        return Promise.resolve();
    }
    return modalInstance.result;
  }

  private _checkLoginError(errorClass: string): EDocumentosFiscaisCredentialsModalType {
    switch (errorClass) {
      case AT_CREDENTIALS_ERROR_CLASS:
        return EDocumentosFiscaisCredentialsModalType.AT;
      case SSD_CREDENTIALS_ERROR_CLASS:
        return EDocumentosFiscaisCredentialsModalType.SSD;
      case VIACTT_CREDENTIALS_ERROR_CLASS:
        return EDocumentosFiscaisCredentialsModalType.VIACTT;
      default:
        return EDocumentosFiscaisCredentialsModalType.NONE;
    }
  }

  private _setFirstLoad(menu: EDocumentosFiscaisMainMenu): void {
    switch (menu) {
      case EDocumentosFiscaisMainMenu.IVA:
        this.modules.dpIva.firstLoad = true;
        this.modules.drIva.firstLoad = true;
        break;
      case EDocumentosFiscaisMainMenu.IRC:
        this.modules.ircPagAnt.firstLoad = true;
        this.modules.ircMod22.firstLoad = true;
        break;
      case EDocumentosFiscaisMainMenu.RECIBOS:
        this.modules.recibosRR.firstLoad = true;
        this.modules.recibosRV.firstLoad = true;
        break;
      case EDocumentosFiscaisMainMenu.IES:
        this.modules.ies.firstLoad = true;
        break;
      case EDocumentosFiscaisMainMenu.IRS:
        this.modules.irs.firstLoad = true;
        break;
      case EDocumentosFiscaisMainMenu.RETENCOES:
        this.modules.retencoesIRCIRS.firstLoad = true;
        break;
      case EDocumentosFiscaisMainMenu.IUC:
        this.modules.iuc.firstLoad = true;
        this.modules.iuc.emitirFirstLoad = true;
        break;
      case EDocumentosFiscaisMainMenu.VEICULOS:
        this.modules.veiculos.firstLoad = true;
        break;
      case EDocumentosFiscaisMainMenu.CERTIDOES:
        this.modules.certidoes.firstLoad = true;
        break;
      case EDocumentosFiscaisMainMenu.IMOVEIS:
        this.modules.imoveis.firstLoad = true;
        this.modules.imoveis.ncFirstLoad = true;
        break;
      case EDocumentosFiscaisMainMenu.PENHORAS:
        this.modules.penhoras.firstLoad = true;
        break;
      case EDocumentosFiscaisMainMenu.SS_SITUACAO_CONTRIB:
        this.modules.sssitcontrib.firstLoad = true;
        break;
      case EDocumentosFiscaisMainMenu.VIACTT_CAIXA_CORREIO:
        this.modules.viactt.firstLoad = true;
        break;
    }
  }
}
