<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="'emailclifos.sendMail.sendAllMessage'"></h5>
</div>

<div class="modal-body">
  <pl-edit type="boolean" [(model)]="sendAll" [properties]="{label: 'emailclifos.sendMail.sendAllCheckbox'}"></pl-edit>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary action-close" (evtClicked)="close(sendAll)" plAutoFocus> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span> </pl-button>

  <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()" [tooltip]="{text: 'emailclifos.sendMail.tooltips.cancelCheck', placement: 'top'}">
    <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
  </pl-button>
</div>
