<div id="pl-sidebar-wrapper" class="pl-sidebar">
  <div class="pl-sidebar-base">
    <div class="pl-sidebar-main d-md-none">
      <button type="button" class="pl-sidebar-toggler" (click)="toggleSidebar()">
        <div class="menu-title">
          <ng-content select="pl-sidebar-title"></ng-content>
        </div>
        <div class="menu-close pl-icon-close"></div>
      </button>
    </div>

    <div class="pl-sidebar-list-wrapper">
      <ng-container *ngFor="let item of menus">
        <div *ngIf="!item.isFiltered" class="pl-sidebar-list" [ngClass]="{active: item.active, 'active-sub': item.menu && item.isOpened}">
          <a rel="noopener noreferrer" class="pl-sidebar-list-item" [attr.href]="item.href" [ngClass]="{'active-sub': item.menu && item.isOpened}" (click)="go(item, $event)">
            <button *ngIf="item.menu" type="button" class="btn btn-sm btn-arrow">
              <i class="fa fa-fw fa-angle-right" [class.fa-rotate-90]="item.isOpened"></i>
            </button>
            <span class="menu-title" [innerHTML]="item.title | cgcHighlight: filterValue : filterActive && (!item.menu || !item.menu.length) : true | cgcBindHtml"></span>
            <span class="menu-icon" [cgcIcon]="item.icon"></span>
          </a>

          <div *ngIf="item.isOpened" class="pl-sidebar-second-level">
            <ng-container *ngFor="let sub of item.menu">
              <div *ngIf="!sub.isFiltered" class="pl-sidebar-list" [ngClass]="{active: sub.active}">
                <a rel="noopener noreferrer" class="pl-sidebar-list-item" [attr.href]="sub.href" (click)="go(sub, $event, item)">
                  <span class="menu-title">
                    <span [innerHTML]="sub.title | cgcHighlight: filterValue : filterActive : true | cgcBindHtml"></span>
                    <span *ngIf="sub.suffix" [innerHTML]="sub.suffix | cgcHighlight: filterValue : filterActive : true | cgcBindHtml"></span>
                  </span>
                  <span class="menu-icon" [cgcIcon]="sub.icon"></span>
                </a>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="pl-sidebar-list-search">
      <div class="input-group input-group-sm input-group-toggle">
        <input
          type="text"
          class="form-control"
          autocapitalize="off"
          autocomplete="off"
          [attr.placeholder]="placeholder"
          [(ngModel)]="filterValue"
          (ngModelChange)="filterValueChanged()"
          (keyup)="filterKeyUp($event)" />
        @if (filterActive) {
          <button type="button" class="btn btn-primary" (click)="clearFilter()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        }
      </div>
    </div>
  </div>

  <div *ngIf="hideTogglerHelper !== true" class="pl-sidebar-toggle-helper" (mouseenter)="mouseEnterTogglerHelper()" (mouseleave)="mouseLeaveTogglerHelper()"></div>
</div>
