<div class="importador-efatura">
  <div class="row">
    <div class="col-12">
      <pl-form [properties]="{orientation: 'horizontal'}" [submit]="fnPesquisar(cardPanel)" plPromise>
        <cg-card-panel #cardPanel="cgCardPanel">
          <div *cgCardPanelContent>
            <pl-group [properties]="{validators: {required: {value: true}}}">
              <pl-group>
                <label [translate]="'global.text.fromDate'"></label>
                <edit>
                  <pl-edit-datepicker [model]="filters.fromDate" (modelChange)="onFromDateChange($event)" plAutoFocus></pl-edit-datepicker>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'global.text.to'"></label>
                <edit>
                  <pl-edit-datepicker [model]="filters.toDate" (modelChange)="onToDateChange($event)"></pl-edit-datepicker>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'importadorEfatura.fields.tipoDocumentoDesc'"></label>
                <edit>
                  <pl-autocomplete [(model)]="filters.tipo" [source]="tiposFatura" rowTemplate="tipo" output="tipo" [allowInvalid]="false" [allowEmpty]="false"></pl-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'importadorEfatura.fields.fornecedor'"></label>
                <edit>
                  <entity-autocomplete
                    entity="fornecedores"
                    [attrName]="attrNameFornecedores"
                    [model]="filters"
                    (modelChange)="changeFornecedor($event)"
                    [fieldsMap]="{nContribuinte: 'filters.nifFornecedor'}">
                  </entity-autocomplete>
                </edit>
                <actions>
                  <pl-button type="submit" klass="btn-primary"><i class="fa fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span></pl-button>
                </actions>
              </pl-group>
            </pl-group>
          </div>
        </cg-card-panel>
      </pl-form>
    </div>
  </div>

  <div class="row">
    <div class="col-md-2" [hidden]="!showLastDocs">
      <div class="importador-efatura-last-docs-container">
        <h5 class="importador-efatura-title">Últimos Documentos</h5>
        <ul>
          <li *ngFor="let lastDoc of lastDocsSource">
            <pl-tooltip [config]="{text: lastDoc.hint}">
              <div *plTooltipContent class="hyperlink" (click)="lastDocClick(lastDoc, $event)">{{ lastDoc.displayName }}</div>
            </pl-tooltip>
          </li>
        </ul>
      </div>
    </div>

    <div [ngClass]="getWorkAreaCssClass()">
      <pl-tabs [(activeId)]="tabsActiveId" [destroyOnHide]="false" (evtClosed)="closedTab($event.tabId)">
        <pl-tab [id]="mainTabId" caption="importadorEfatura.docSearchTabCaption">
          <div *plTabContent>
            <div class="row">
              <div class="col-md-6"></div>

              <div class="col-md-6 text-right">
                <div class="d-inline-block">
                  <pl-edit type="boolean" [model]="lancarDocsEmSerie" (modelChange)="lancarDocsEmSerieChange($event)" [properties]="{label: 'Lançar Documentos em série'}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 col-main-table" [class.contabilidade-digital-active]="contabilidadeDigital">
                <pl-split-view [initialSizeLeft]="60" [hideRightSide]="!contabilidadeDigital" (evtSizeValuesChanged)="onResizerValuesChanged()">
                  <div *plSplitViewLeftSide>
                    <ng-container *ngIf="errorList.length">
                      <pl-alert type="error" [closeable]="false">
                        <div class="text-center">
                          <span [translate]="'importadorEfatura.atMessagePrefix'"></span>
                          <br />
                          <span [innerHTML]="getErrors()"></span>
                        </div>
                      </pl-alert>
                    </ng-container>

                    <ng-container *ngIf="warningsList.length">
                      <pl-alert type="warning" [closeable]="false">
                        <div class="text-center">
                          <span [translate]="'importadorEfatura.atMessagePrefix'"></span>
                          <br />
                          <span [innerHTML]="getWarnings()"></span>
                        </div>
                      </pl-alert>
                    </ng-container>

                    <dx-data-grid
                      [cgDxDataGrid]="definitionsDataGrid.main"
                      [cgDxDataGridInstanceName]="dataGridInstanceName"
                      [dataSource]="tableSource"
                      (onInitialized)="onDataGridInitialized($event)"
                      (onRowCollapsed)="onDataGridRowCollapsed($event)"
                      (onCellPrepared)="onCellPrepared($event)"
                      (onSelectionChanged)="onSelectionChanged()"
                      (onContentReady)="onContentReady($event)"
                      (onCellClick)="onDataGridCellClick($event)"
                      (onContextMenuPreparing)="onDataGridContextMenuPreparing($event)">
                      <div *dxTemplate="let item of 'detail'">
                        <div class="row importador-efatura-details-row">
                          <div class="col-md-12" *ngIf="getErrorString(item.data).length">
                            <pl-alert [type]="'error'" [closeable]="false">
                              <div [class]="'item-detail-error'">
                                <span><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>&nbsp;
                                <span [innerHTML]="getErrorString(item.data)"></span>
                              </div>
                            </pl-alert>
                          </div>

                          <div class="col-md-12 mb-2">
                            <pl-toolbar [instanceId]="item.data._toolbarInstanceId"></pl-toolbar>
                          </div>

                          <div class="col-md-12" [hidden]="!item.data.showDetails">
                            <div class="card" style="margin-bottom: 15px">
                              <div class="card-header">Detalhes</div>
                              <div class="card-body">
                                <dx-data-grid [cgDxDataGrid]="definitionsDataGrid.detalhes" (onInitialized)="onDetalhesDataGridInitialized($event, item.data)" [dataSource]="item.data.linhas">
                                </dx-data-grid>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-12">
                            <div class="card">
                              <div class="card-header" [translate]="'importadorEfatura.criarDoc'"></div>
                              <div class="card-body">
                                <doccontabilidade
                                  [model]="item.data.docModel"
                                  (modelChange)="itemDocModelChanged($event, item.data)"
                                  [toolbarInstanceName]="item.data._toolbarInstanceId"
                                  [onLoadPreDefinido]="fnOnLoadPreDefinido(item.data)"
                                  [onLoadFocusField]="item.data.docLoadFocusField"
                                  plLifeCycle
                                  (evtInit)="docContabilidadeInit(item.data, $event)"
                                  (evtDestroy)="docContabilidadeDestroy(item.data)"
                                  [onAfterInitDocument]="fnDocContabilidadeAfterInitDocument(item.data)"
                                  [callback]="item.data.docCallback">
                                </doccontabilidade>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div *dxTemplate="let item of 'actions'">
                        <div ngbDropdown container="body" class="col-main-table-actions">
                          <button type="button" class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle><i class="fa fa-bars"></i></button>
                          <div ngbDropdownMenu>
                            <button type="button" ngbDropdownItem (click)="openConfigPreDefinidosSupplierModal(item.data)">
                              <i class="fa fa-cog me-1" aria-hidden="true"></i><span [translate]="'importadorEfatura.actions.configSupplier'"></span>
                            </button>
                            <button type="button" ngbDropdownItem (click)="openCreateNewContaCorrentModal(item.data)" [disabled]="!!item.data.contaCorrente">
                              <i class="fa fa-asterisk me-1" aria-hidden="true"></i> <span [translate]="'importadorEfatura.tooltip.criarNovaContaCorrente'"></span>
                            </button>
                            <button type="button" ngbDropdownItem (click)="openExtratosContaModal(item.data)" [disabled]="!item.data.contaCorrente">
                              <i class="fa fa-file-text-o me-1" aria-hidden="true"></i> <span [translate]="'importadorEfatura.tooltip.extratoCorrente'"></span>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div *dxTemplate="let item of 'cellTemplateNifEmitente'">
                        <span>{{ item.text }}</span>
                        <pl-tooltip [config]="{text: item.data.erro, placement: 'left', container: 'body'}" *ngIf="item.data.erro.length || item.data.listaErros.length">
                          <i *plTooltipContent class="fa fa-exclamation-triangle ms-1" aria-hidden="true"></i>
                        </pl-tooltip>
                      </div>
                    </dx-data-grid>
                  </div>

                  <div *plSplitViewRightSide>
                    <contabilidadedigital-docviewer-recolha [search]="contabilidadeDigitalSearch" [callback]="callbackDocViewer" (evtChangedAttachment)="changedContabilidadeDigitalAttachment($event)">
                    </contabilidadedigital-docviewer-recolha>
                  </div>
                </pl-split-view>
              </div>
            </div>
          </div>
        </pl-tab>

        <pl-tab *ngFor="let tab of lastDocsTabs" [id]="tab.extPocCabID" [caption]="tab.displayName" [closable]="true">
          <div *plTabContent>
            <div class="col-md-12 mb-2">
              <pl-toolbar [instanceId]="tab._toolbarInstanceId"></pl-toolbar>
            </div>

            <doccontabilidade
              [editing]="true"
              [(model)]="tab.docModel"
              [toolbarInstanceName]="tab._toolbarInstanceId"
              [onLoadFocusField]="!tab.docModel?.isUsingPreDefinido ? 'linhas' : undefined"
              plLifeCycle
              (evtInit)="lastDocContabilidadeInit(tab, $event)"
              (evtDestroy)="lastDocContabilidadeDestroy(tab)">
            </doccontabilidade>
          </div>
        </pl-tab>
      </pl-tabs>
    </div>
  </div>

  <cg-panel-multiple-selection-execute
    *ngIf="lancarDocsEmSerie && lancarDocsEmSerieCount > 0"
    [labelTitle]="'components.panelmultipleselectionexecute.labels.selectedDocs'"
    [labelAction]="'components.panelmultipleselectionexecute.actions.lancarDocs'"
    [count]="lancarDocsEmSerieCount"
    [executeFn]="fnDoLancarDocsEmSerie">
  </cg-panel-multiple-selection-execute>
</div>
