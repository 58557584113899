import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {
  HrefOptions,
  PathNode,
  RawParams,
  StateObject,
  StateOrName,
  StateRegistry,
  StateService,
  TargetState,
  Transition,
  TransitionOptions,
  TransitionService,
  UIRouter,
  UIRouterGlobals
} from '@uirouter/core';
import {isEmpty, isObject, isString, isUndefined, skipIf} from 'pl-comps-angular';
import {EEntityStateDetailType, entityStateDetailQueryParam, entityStateName} from '../../../common/utils/entity.state.utils';
import {ICGStateDeclaration, STATE_NAME_PORTAL} from '../../services/portals/portals.service.interface';
import {ICGRedirectToTargetState, ICGTargetState} from './cg.state.service.interface';
import {EPortal} from '../../../common/enums/portals.enums';

@Injectable({
  providedIn: 'root'
})
export class CGStateService implements OnDestroy {
  private readonly _deRegisterOnStart: Function;
  private readonly _deRegisterOnSuccess: Function;
  private readonly _deRegisterOnError: Function;
  private _subjectTransitionOnStart: BehaviorSubject<Transition>;
  private _subjectTransitionOnSuccess: BehaviorSubject<Transition>;
  private _subjectTransitionOnError: BehaviorSubject<Transition>;
  private _initialTransitionOnStart: Transition;
  private _initialTransitionOnSuccess: Transition;
  private _initialTransitionOnError: Transition;
  private _observableTransitionOnStart: Observable<Transition>;
  private _observableTransitionOnSuccess: Observable<Transition>;
  private _observableTransitionOnError: Observable<Transition>;
  private _lastSuccessfulTransition: Transition;

  constructor(
    private readonly _router: UIRouter,
    private readonly _uiRouterGlobals: UIRouterGlobals,
    private readonly _stateRegistry: StateRegistry,
    private readonly _stateService: StateService,
    private readonly _transitionService: TransitionService
  ) {
    this._deRegisterOnStart = this._transitionService.onStart({}, (transition: Transition) => {
      if (this._subjectTransitionOnStart) {
        this._subjectTransitionOnStart.next(transition);
      } else {
        this._initialTransitionOnStart = transition;
      }
    });
    this._deRegisterOnSuccess = this._transitionService.onSuccess({}, (transition: Transition) => {
      this._lastSuccessfulTransition = transition;
      if (this._subjectTransitionOnSuccess) {
        this._subjectTransitionOnSuccess.next(transition);
      } else {
        this._initialTransitionOnSuccess = transition;
      }
    });
    this._deRegisterOnError = this._transitionService.onError({}, (transition: Transition) => {
      if (this._subjectTransitionOnError) {
        this._subjectTransitionOnError.next(transition);
      } else {
        this._initialTransitionOnError = transition;
      }
    });
  }

  public ngOnDestroy(): void {
    this._deRegisterOnStart();
    this._deRegisterOnSuccess();
    this._deRegisterOnError();
    if (this._subjectTransitionOnStart) {
      this._subjectTransitionOnStart.complete();
    }
    if (this._subjectTransitionOnSuccess) {
      this._subjectTransitionOnSuccess.complete();
    }
    if (this._subjectTransitionOnError) {
      this._subjectTransitionOnError.complete();
    }
  }

  public routerTransitionOnStart(): Observable<Transition> {
    if (!this._subjectTransitionOnStart) {
      this._subjectTransitionOnStart = new BehaviorSubject<Transition>(this._initialTransitionOnStart);
      this._initialTransitionOnStart = undefined;
      this._observableTransitionOnStart = this._subjectTransitionOnStart.asObservable().pipe(skipIf(isUndefined));
    }
    return this._observableTransitionOnStart;
  }

  public routerTransitionOnSuccess(): Observable<Transition> {
    if (!this._subjectTransitionOnSuccess) {
      this._subjectTransitionOnSuccess = new BehaviorSubject<Transition>(this._initialTransitionOnSuccess);
      this._initialTransitionOnSuccess = undefined;
      this._observableTransitionOnSuccess = this._subjectTransitionOnSuccess.asObservable().pipe(skipIf(isUndefined));
    }
    return this._observableTransitionOnSuccess;
  }

  public routerTransitionOnError(): Observable<Transition> {
    if (!this._subjectTransitionOnError) {
      this._subjectTransitionOnError = new BehaviorSubject<Transition>(this._initialTransitionOnError);
      this._initialTransitionOnError = undefined;
      this._observableTransitionOnError = this._subjectTransitionOnError.asObservable().pipe(skipIf(isUndefined));
    }
    return this._observableTransitionOnError;
  }

  public getCurrentPortalStateName(): string {
    const state = this._uiRouterGlobals.current;
    if (state) {
      const name = state.name;
      return name.substring(0, name.lastIndexOf('.'));
    }
    return '';
  }

  public getCurrentStateName(): string {
    const state = this._uiRouterGlobals.current;
    if (state) {
      const name = state.name;
      return name.substring(name.indexOf('.') + 1, name.lastIndexOf('.'));
    }
    return '';
  }

  public targetState(targetState: ICGRedirectToTargetState): TargetState {
    const stateDeclaration = this.getRedirectState(targetState);
    return this._stateService.target(stateDeclaration, targetState.params, targetState.transitionOptions);
  }

  public redirectToState(redirectParams: ICGRedirectToTargetState): Promise<void> {
    if (isObject(redirectParams.stateOrName)) {
      redirectParams.exactMatch = true;
    }
    const state = this.getRedirectState(redirectParams);
    if (!state) {
      throw new Error(`State or name "${String(redirectParams.stateOrName)}" not found in current portal.`);
    }
    if (redirectParams.stateType) {
      if (!isObject(redirectParams.params)) {
        redirectParams.params = {};
      }
      let stateName = state.data?._moduleName ?? state.data?._entityName;
      if (!stateName) {
        const portal: string = this.getCurrentPortalStateName();
        stateName = state.name.substring(portal.length + 1);
      }
      const stateType: string = entityStateDetailQueryParam(stateName);
      redirectParams.params[stateType] = redirectParams.stateType;
    }
    return this._stateService.go(state, redirectParams.params, redirectParams.transitionOptions).then(() => undefined);
  }

  public getRedirectState({stateOrName, exactMatch, stateType, portal, anyPortal}: ICGTargetState): ICGStateDeclaration {
    const registeredStates: Array<ICGStateDeclaration> = <Array<ICGStateDeclaration>>this._stateRegistry.get();
    if (isEmpty(portal)) {
      portal = <EPortal>this.getCurrentPortalStateName();
      if (portal.startsWith(STATE_NAME_PORTAL)) {
        portal = <EPortal>portal.slice(STATE_NAME_PORTAL.length + 1);
      }
    }
    if (anyPortal) {
      portal = undefined;
    }
    const portalLength: number = portal ? portal.length + 1 : -1;
    let stateName = isString(stateOrName) ? stateOrName : stateOrName.name;
    if (stateType) {
      exactMatch = true;
      stateName = entityStateName(stateName, stateType);
    }
    stateName = stateName.replace(/\./g, '\\.');
    const stateNameRegex = exactMatch ? new RegExp(`^${stateName}$`) : new RegExp(`^${stateName}((_${EEntityStateDetailType.NEW})|(_${EEntityStateDetailType.DETAIL}))?$`);
    return registeredStates.find((state: ICGStateDeclaration) => {
      let toMatch: string = state.name;
      if (!toMatch) {
        return false;
      }
      if (portal) {
        if (toMatch.startsWith(STATE_NAME_PORTAL)) {
          toMatch = toMatch.substring(STATE_NAME_PORTAL.length + 1);
        }
        if (!toMatch.startsWith(portal)) {
          return false;
        }
        toMatch = toMatch.slice(portalLength);
      } else {
        toMatch = toMatch.substring(toMatch.lastIndexOf('.') + 1);
      }
      return stateNameRegex.exec(toMatch) !== null;
    });
  }

  public reload(reloadState?: StateOrName): Promise<StateObject> {
    return this._stateService.reload(reloadState);
  }

  public buildTransition(stateOrName: StateOrName, params?: RawParams, options?: TransitionOptions): Transition {
    const from: Array<PathNode> = this._getCurrentPath();
    const to: TargetState = this._stateService.target(stateOrName, params, options);
    return this._transitionService.create(from, to);
  }

  public openStateInNewTabOrWindow(stateOrName: StateOrName, params?: RawParams, options?: HrefOptions): void {
    const url: string = this._stateService.href(stateOrName, params, options);
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  public goBack(): Promise<void> {
    if (!this._lastSuccessfulTransition) {
      return this.goHome();
    }
    return this._stateService
      .go(this._lastSuccessfulTransition.from(), this._lastSuccessfulTransition.params('from'))
      .then(() => undefined)
      .catch(() => {
        return this.goHome();
      });
  }

  public goHome(): Promise<void> {
    return this._stateService.go(STATE_NAME_PORTAL).then(() => undefined);
  }

  private _getCurrentPath(): Array<PathNode> {
    const latestSuccess: Transition = this._router.globals.successfulTransitions.peekTail();
    if (latestSuccess) {
      return latestSuccess.treeChanges().to;
    }
    return [new PathNode(this._router.stateRegistry.root())];
  }
}
