<div class="documento-faturacao">
  <div class="card">
    <div class="card-header" [class.doc-anulado]="contrato.cab.terminado">
      <div class="card-title documento-faturacao-header">
        <div class="documento-faturacao-header-title">
          <div class="badge bg-danger" *ngIf="contrato.cab.terminado"><strong [translate]="'contratos.info.terminado'"></strong></div>
          <strong
            ><span>{{ contrato.cab.nContratoSemVersaoAsString }} - {{ contrato.cab.descricao }}</span></strong
          >
        </div>
        <div class="documento-faturacao-header-toolbar">
          <button type="button" class="btn btn-sm btn-warning action-editar-contrato" *ngIf="actionEditarContrato && !contrato.cab.terminado" (click)="editarContrato()">
            <i class="fa fa-fw fa-pencil-square-o"></i>&nbsp;<span [translate]="'contratos.btn.editar'"></span>
          </button>

          <div ngbDropdown>
            <button type="button" class="btn btn-info btn-sm" ngbDropdownToggle><i class="fa fa-fw fa fa-bars"></i>&nbsp;<span class="caret" [translate]="'contratos.btn.actions'"></span></button>

            <div ngbDropdownMenu>
              <button type="button" class="action-contrato-reabrir" ngbDropdownItem *ngIf="contrato.cab.terminado" (click)="reabrirContrato()">
                <i class="fa fa-fw fa-unlock" aria-hidden="true"></i>&nbsp;<span [translate]="'contratos.btn.reabrir'"></span>
              </button>

              <button type="button" class="action-contrato-terminar" ngbDropdownItem *ngIf="!contrato.cab.terminado" (click)="terminarContrato()">
                <i class="fa fa-fw fa-lock"></i>&nbsp;<span [translate]="'contratos.btn.terminar'"></span>
              </button>
            </div>
          </div>

          <button type="button" class="btn btn-sm btn-danger action-apagar-contrato" *ngIf="actionEditarContrato" (click)="apagarContrato()">
            <i class="fa fa-fw fa-scissors"></i>&nbsp;<span [translate]="'contratos.btn.apagar'"></span>
          </button>
        </div>

        <div class="documento-faturacao-header-doc-info resumo-wrapper">
          <dl class="resumo">
            <dt [translate]="'contratos.detail.cab.dataCtr'"></dt>
            <dd>{{ contrato.cab.dataCtr | cgcDate }}</dd>
          </dl>
        </div>
      </div>
    </div>

    <div class="card-body card-documento">
      <div class="documento-faturacao-resumo-header" [class.resumo]="!mobile">
        <dl class="page-documento-header">
          <dt [translate]="'contratos.detail.cab.nClifo'" id="contrato-cab-nClifo"></dt>
          <dd>{{ contrato.cab.nClifo }} - {{ contrato.cab.clifoNome }}</dd>
          <dt [translate]="'contratos.detail.cab.nContribuinte'" *ngIf="contrato.cab.nContribuint" id="contrato-cab-nContribuinte"></dt>
          <dd *ngIf="contrato.cab.nContribuint">{{ contrato.cab.nContribuint }}</dd>
          <dt [translate]="'contratos.detail.cab.codPostal'" *ngIf="contrato.cab.codPostal && contrato.cab.localidade" id="contrato-cab-codPostal"></dt>
          <dd *ngIf="contrato.cab.codPostal && contrato.cab.localidade">{{ contrato.cab.codPostal }} {{ contrato.cab.localidade }}</dd>
          <dt [translate]="'contratos.detail.cab.dataInicio'" *ngIf="contrato.cab.dataInicio" id="contrato-cab-dataInicio"></dt>
          <dd *ngIf="contrato.cab.dataInicio">{{ contrato.cab.dataInicio | cgcDate }}</dd>
          <dt [translate]="'contratos.detail.cab.dataFim'" *ngIf="contrato.cab.dataFim" id="contrato-cab-dataFim"></dt>
          <dd *ngIf="contrato.cab.dataFim">{{ contrato.cab.dataFim | cgcDate }}</dd>
        </dl>
        <span></span><span></span>
      </div>

      <div class="documento-faturacao-documentos mt-3">
        <dx-data-grid [cgDxDataGrid]="dataGridTable" [dataSource]="contrato.linhas" (onInitialized)="onDataGridInitialized($event)">
          <div *dxTemplate="let item of 'actions'" class="text-center">
            <div class="col-main-table-actions" ngbDropdown container="body">
              <button type="button" class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle>
                <i class="fa fa-cog"></i>
              </button>
              <div ngbDropdownMenu>
                <button type="button" ngbDropdownItem (click)="openArtigo(item.data)">
                  <i class="fa fa-tags fa-fw"></i>&nbsp;
                  <span [translate]="'docscomerciais.actions.artigos'"></span>
                </button>
              </div>
            </div>
          </div>
        </dx-data-grid>
      </div>

      <hr />

      <div class="resumo-wrapper">
        <div class="resumo">
          <h4 class="page-header-cg" [translate]="'contratos.totais.resumo'"></h4>
          <dl class="total-doc">
            <dt [translate]="'contratos.totais.total'"></dt>
            <dd>{{ this.totalContrato | cgcDecimal }}</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</div>
