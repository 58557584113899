import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';
import {PlTranslateService} from './translate.service';

@Directive({
  selector: '[plTranslate]'
})
export class PlTranslateDirective implements OnChanges {
  @Input() public plTranslate: string;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _renderer: Renderer2,
    private readonly _plTranslateService: PlTranslateService
  ) {
  }

  public ngOnChanges({plTranslate}: SimpleChanges): void {
    if (plTranslate) {
      const value: string = this._plTranslateService.translate(plTranslate.currentValue);
      this._renderer.setProperty(this._elementRef.nativeElement, 'innerHTML', value);
    }
  }
}
