import type {Subscription} from 'rxjs';
import {merge} from 'lodash-es';
import {Component, ContentChildren, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Optional, Output, QueryList, SimpleChanges, SkipSelf} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {copy, generateUniqueID, isArray, isDefinedNotNull, isEmpty, isFunction, isObject, isString, isUndefinedOrNull} from '../../../common/utilities/utilities';
import type {IPlEditBaseComponent, IPlEditBaseComponentOptions} from '../../component/edit.component.interface';
import type {IPlFormatConfig} from '../../../common/format/format.service.interface';
import type {IPlInlineEditOptions, TPlInlineEditOnValidateFn} from './inline.edit.component.interface';
import {KEYCODES} from '../../../common/constants';
import {PlCompsService} from '../../../common/service/comps.service';
import {PlEditGroupToken} from '../group/edit.group.token';
import {PlEditInputGroupDirective} from '../../../edit/generic/inputgroup/edit.input.group.directive';
import {PlFormatService} from '../../../common/format/format.service';
import {PlInlineEditToken} from './inline.edit.token';
import type {TEditInputKeyboardEvent} from '../../generic/input/edit.input.eventshandler.directive.interface';
import {TValueType} from '../../../common/interface';

@Component({
  selector: 'pl-inline-edit',
  templateUrl: './inline.edit.component.html',
  providers: [{provide: PlInlineEditToken, useExisting: PlInlineEditComponent}]
})
export class PlInlineEditComponent extends PlInlineEditToken<any> implements OnInit, OnChanges, OnDestroy {
  @Input() public type: TValueType;
  @Input() public model: any;
  @Input() public attrName: string;
  @Input() public inputClass: string;
  @Input() public editMode: boolean;
  @Input() public onValidate: TPlInlineEditOnValidateFn;
  @Input() public properties: IPlInlineEditOptions | any;
  @Output() public readonly modelChange: EventEmitter<any>;
  @Output() public readonly editModeChange: EventEmitter<boolean>;
  @ContentChildren(PlEditInputGroupDirective, {descendants: true}) public itemGroups: QueryList<PlEditInputGroupDirective>;

  public value: any;
  public viewValue: string;
  public showMask: boolean;
  public validating: boolean;

  private readonly _element: HTMLInputElement;
  private readonly _defaultOptions: IPlInlineEditOptions;
  private readonly _subscriptionFormat: Subscription;
  private _format: IPlFormatConfig;
  private _editComponent: IPlEditBaseComponent<any>;
  private _oldValue: any;
  private _originalKeyDown: TEditInputKeyboardEvent<any>;
  private _renderedCurrentValue: boolean;
  private _closeOnRender: boolean;
  private _inputValue: string;

  constructor(
    @SkipSelf() @Optional() private readonly _plInlineEdit: PlInlineEditComponent,
    @Optional() private readonly _plGroup: PlEditGroupToken,
    private readonly _elementRef: ElementRef<HTMLInputElement>,
    private readonly _plCompsService: PlCompsService,
    private readonly _plFormatService: PlFormatService
  ) {
    super();
    this.modelChange = new EventEmitter<any>();
    this.editModeChange = new EventEmitter<boolean>();
    this.showMask = true;
    this.validating = false;
    this._subscriptionFormat = this._plFormatService.format.subscribe((format: IPlFormatConfig) => {
      this._format = format;
    });
    this._element = this._elementRef.nativeElement;
    this._defaultOptions = Object.freeze<IPlInlineEditOptions>({
      labelSet: undefined
    });
    this._renderedCurrentValue = false;
    this._closeOnRender = false;
    this._inputValue = '';
  }

  public ngOnInit(): void {
    if (isEmpty(this.attrName)) {
      this.attrName = generateUniqueID('plInlineEdit');
    }
    const initialOptions: Partial<IPlInlineEditOptions> = {};
    if (!this._plInlineEdit && this._plGroup) {
      this._plGroup.addEdit(this);
      if (this._plGroup.properties) {
        merge(initialOptions, this._plGroup.properties);
      }
    }
    this.options = merge({}, this._defaultOptions, initialOptions, this.options, this.properties);
    this._changedOptions();

    this.editMode = Boolean(this.editMode);
    if (this.options.format) {
      merge(this._format, this.options.format);
    }
    this.value = this.model;
    this._setViewValue();
    this.showMask = this._shouldShowMask();
  }

  public ngOnChanges({type, model, options, properties}: SimpleChanges): void {
    if (options || properties) {
      const changesOptions = copy(this._defaultOptions);
      if (this.options) {
        merge(changesOptions, this.options);
      }
      if (options) {
        merge(changesOptions, options.currentValue);
      }
      if (properties) {
        merge(changesOptions, properties.currentValue);
      }
      if (this.properties) {
        merge(changesOptions, this.properties);
      }
      this.updateComponent(changesOptions);
    }
    if (model && !model.isFirstChange()) {
      this.value = model.currentValue;
      this._setViewValue();
      this.showMask = this._shouldShowMask();
    }
    if (type && !type.isFirstChange()) {
      this._setViewValue();
    }
  }

  public ngOnDestroy(): void {
    if (!this._plInlineEdit && this._plGroup) {
      this._plGroup.removeEdit(this.attrName);
    }
    this._subscriptionFormat.unsubscribe();
  }

  public updateValue(value: any): void {
    this.value = value;
  }

  public render(value: any = this.value): void {
    this.model = value;
    this.modelChange.emit(this.model);
    this._renderedCurrentValue = true;
    if (this._closeOnRender) {
      this._closeOnRender = false;
      this.close();
    }
  }

  public valueChanged(value: any, field: string): void {
    if (this._plGroup) {
      this._plGroup.valueChanged(value, field);
    }
  }

  public updateComponent(options: IPlEditBaseComponentOptions<any>): void {
    this.options = merge({}, this.options, options, this.properties);
    this._changedOptions();
    if (this._editComponent) {
      this._editComponent.updateComponent(this.options);
    }
  }

  public setHasError(hasError: boolean): void {
    if (this._plGroup) {
      this._plGroup.setHasError(hasError);
    }
  }

  public addComponent(component: IPlEditBaseComponent<any>): void {
    if (!this._editComponent) {
      this._editComponent = component;
    }
  }

  public removeComponent(): void {
    if (this._editComponent) {
      this.removeControl(this.attrName);
      this._editComponent = undefined;
    }
  }

  public addControl(name: string, control: AbstractControl): void {
    if (!this._plInlineEdit && this._plGroup) {
      this._plGroup.addControl(name, control);
    }
  }

  public removeControl(name: string): void {
    if (!this._plInlineEdit && this._plGroup) {
      this._plGroup.removeControl(name);
    }
  }

  public open(): void {
    this._setEditMode(true);
    this._setOldValue(this.value);
    setTimeout(() => {
      const input = this._element.querySelector<HTMLInputElement>(`input[name="${this.attrName}]"`);
      if (input) {
        input.focus();
      }
    });
  }

  public cancel(): void {
    this._setEditMode(false);
    this.render(this._oldValue);
  }

  public close(): Promise<void> {
    return Promise.resolve(this._validate()).then(() => {
      this._renderedCurrentValue = false;
      this._setEditMode(false);
      this._setViewValue();
      this.showMask = this._shouldShowMask();
    });
  }

  public getValue(): string {
    return this._plCompsService.prettyPrintValue(this.value, this.type, this._format, this.options);
  }

  public toggleMode(): void {
    if (!this.editMode) {
      this.open();
    } else {
      this.close();
    }
  }

  private _changedOptions(): void {
    if (!isObject(this.options.events)) {
      this.options.events = {};
    }
    if (isFunction(this.options.events.keydown) && this.options.events.keydown !== this._fnKeydownHandler) {
      this._originalKeyDown = this.options.events.keydown;
    }
    this.options.events.keydown = this._fnKeydownHandler;
  }

  private _shouldShowMask(): boolean {
    if (this.options.readonly) {
      return false;
    }
    let value = this.value;
    if (isUndefinedOrNull(value)) {
      value = this.getValue();
    }
    if (isString(value)) {
      return value.length === 0;
    }
    return isUndefinedOrNull(value) || isObject(value) || isArray(value);
  }

  private _setViewValue(): void {
    this.viewValue = this.getValue();
  }

  private _setEditMode(value: boolean): void {
    this.editMode = value;
    this.editModeChange.emit(value);
  }

  private _setOldValue(value: any): void {
    this._oldValue = copy(value);
  }

  private _validate(): Promise<any> {
    let onValidate = this.onValidate;
    if (!isFunction(onValidate)) {
      onValidate = this.options.onValidate;
      if (!isFunction(onValidate)) {
        return Promise.resolve();
      }
    }
    this.validating = true;
    return Promise.resolve(onValidate(this.value, this._inputValue))
      .then((response: unknown): unknown => {
        if (isDefinedNotNull(response)) {
          return response;
        }
        return undefined;
      })
      .finally(() => {
        this.validating = false;
      });
  }

  private _keydownHandler(value: any, event: KeyboardEvent): void {
    this._inputValue = value;
    switch (event.key) {
      case KEYCODES.ENTER:
        if (this.value === this.model && this._renderedCurrentValue) {
          this.close();
        } else {
          this._closeOnRender = true;
        }
        break;
      case KEYCODES.ESC:
        this.cancel();
        break;
    }
    if (isFunction(this._originalKeyDown)) {
      this._originalKeyDown(value, event);
    }
  }

  private readonly _fnKeydownHandler: (value: any, event: KeyboardEvent) => void = (value: any, event: KeyboardEvent) => {
    this._keydownHandler(value, event);
  };
}
