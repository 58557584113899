import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {
  ETipoAcrescimoEncFerias,
  IJsonAcrescimoEncargoComFeriasGerarMultiEmpresas,
  IJsonAcrescimoEncargoComFeriasLin,
  IJsonAcrescimoEncargoComFeriasLinMulti
} from '../../jsonAcrescimosEncargosFerias.module.interface';
import {AcrescimosEncargosFeriasService} from '../../acrescimosEncargosFerias.module.service';
import {ITipoAcrescimoEncFerias} from '../../acrescimosEncargosFerias.module.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import ArrayStore from 'devextreme/data/array_store';
import {IDevExpressDataGridEventOnCellPrepared, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {PlAlertService} from 'pl-comps-angular';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'modal-acrescimos-encargos-ferias-gerar-encargos-multi',
  templateUrl: './acrescimosEncargosFerias.gerarEncargosMulti.modal.component.html'
})
export class AcrescimosEncargosFeriasGerarEncargosMultiModalComponent extends CGModalComponent<Array<IJsonAcrescimoEncargoComFeriasLinMulti>> implements OnInit {
  @Input() public listEmpresas: Array<IJsonAcrescimoEncargoComFeriasGerarMultiEmpresas>;

  public readonly dataGridDefinitionEmpresas: IDevExpressDataGrid;

  public tipoAcrescimoEncFeriasSource: Array<ITipoAcrescimoEncFerias>;

  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _acrescimosEncargosService: AcrescimosEncargosFeriasService,
    private readonly _plaAlertService: PlAlertService
  ) {
    super(_injector);
    this.tipoAcrescimoEncFeriasSource = [
      {
        valueMetodo: ETipoAcrescimoEncFerias.Anual,
        nameMetodo: 'acrescimosencargosferias.tipoAcrescimoEncFeriasSource.anual'
      },
      {
        valueMetodo: ETipoAcrescimoEncFerias.Mensal,
        nameMetodo: 'acrescimosencargosferias.tipoAcrescimoEncFeriasSource.mensal'
      }
    ];
    this.dataGridDefinitionEmpresas = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nEmpresa', dataType: 'string', caption: 'acrescimosencargosferias.modals.empresas.nEmpresa', allowEditing: false},
        {dataField: 'nomeEmpresa', dataType: 'string', caption: 'acrescimosencargosferias.modals.empresas.nomeEmpresa', allowEditing: false},
        {dataField: 'anoEncargo', dataType: 'number', caption: 'acrescimosencargosferias.modals.empresas.anoEncargo', allowEditing: false},
        {
          dataField: 'tipo',
          dataType: 'number',
          caption: 'acrescimosencargosferias.modals.empresas.tipo',
          lookup: {
            dataSource: this.tipoAcrescimoEncFeriasSource,
            valueExpr: 'valueMetodo',
            displayExpr: 'nameMetodo'
          },
          showEditorAlways: true
        },
        {dataField: 'percSeguradora', dataType: 'double', caption: 'acrescimosencargosferias.modals.empresas.percSeguradora'}
      ],
      dataSource: [],
      editing: {mode: 'cell', startEditAction: 'click', selectTextOnEditStart: true, allowUpdating: true, refreshMode: 'repaint'},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      height: '60vh',
      paging: {enabled: false},
      pager: {visible: false},
      scrolling: {rowRenderingMode: 'virtual'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplateBtnsEmpresas',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
  }

  public ngOnInit(): void {
    this.dataGridDefinitionEmpresas.dataSource = new ArrayStore({key: 'nEmpresa', data: this.listEmpresas});
  }

  public close(): Promise<void> {
    const list: Array<IJsonAcrescimoEncargoComFeriasGerarMultiEmpresas> = this._dataGridInstance.getSelectedRowsData();

    if (list.length < 1) {
      this._plaAlertService.error('acrescimosencargosferias.messages.naoSelEmp');
      return Promise.resolve();
    }

    this.enableClose();
    return this._acrescimosEncargosService
      .gerarEncargosListMultiEmpresas(list)
      .then((response: HttpResponse<Array<IJsonAcrescimoEncargoComFeriasLinMulti>>) => {
        super.close(response.body);
      })
      .finally(() => {
        this.enableClose();
      });
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonAcrescimoEncargoComFeriasLin>): void {
    if (event.rowType === 'data') {
      if (event.column.dataField === 'tipo' || event.column.dataField === 'percSeguradora') {
        event.cellElement.classList.add('datagrid-editable-cell');
      }
    }
  }
}
