import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {distinctUntilChanged, skip} from 'rxjs/operators';
import {ChangeDetectorRef, Directive, ElementRef, Injector, OnDestroy, OnInit, TemplateRef, ViewRef} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {RawParams, StateOrName, StateService, Transition, TransitionOptions, UIRouterGlobals} from '@uirouter/core';
import {TranslateService} from '@ngx-translate/core';
import {
  IPlFormatConfig,
  IPlToolbarInstance,
  IPlToolbarItem,
  IPlToolbarItemTemplateContext,
  IPlToolbarMenuItem,
  isArray,
  isDefined,
  isFunction,
  isMobile as cgcIsMobile,
  isObject,
  Logger,
  PlDocumentService,
  PlToolbarService,
  THyperlinkTarget
} from 'pl-comps-angular';
import {AppService} from '../../services/app/app.service';
import {CGStateService} from '../state/cg.state.service';
import {ConfigService} from '../../services/config/config.service';
import {DEFAULT_TOOLBAR_ID, LINK_CENTRALGEST_FAQS_CLOUD, LINK_CENTRALGEST_VIDEOS_CLOUD} from '../../../config/constants';
import {DI_PORTAL_COMPONENT, IPortalComponent} from '../portal/portal.component.interface';
import {EAppLaunchMode} from '../../../common/site';
import {FaqsService} from '../../services/faqs/faqs.service';
import {IActivePortal, ICGStateDeclaration} from '../../services/portals/portals.service.interface';
import {IAppStatus} from '../../services/app/app.service.interface';
import {ICGConfigurations} from '../../services/config/config.service.interface';
import {ICGFaq} from '../../services/faqs/faqs.service.interface';
import {IModuleAction, IModuleActionDefinition, IModuleHelperLink} from './module.definition.interface';
import {PortalService} from '../../services/portal/portal.service';
import {RESOLVER_CONFIGURATIONS} from '../../services/config/config.service.router';
import {RESOLVER_SESSION} from '../../../config/uirouter/uirouter.resolvers';
import {TUserSession} from '../../services/account/jsonUserApi.interface';

@Directive({
  selector: 'modulo'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class ModuloComponent implements OnInit, OnDestroy {
  public readonly session: TUserSession;
  public toolbar: IPlToolbarInstance;
  public toolTitle: IPlToolbarItem;
  public btnBack: IPlToolbarItem;
  public btnRefresh: IPlToolbarItem;
  public btnNovo: IPlToolbarItem;
  public btnEdit: IPlToolbarItem;
  public btnSave: IPlToolbarItem;
  public btnCancel: IPlToolbarItem;
  public btnDelete: IPlToolbarItem;
  public btnDuplicate: IPlToolbarItem;
  public btnConfig: IPlToolbarItem;
  public dropdownActions: IPlToolbarItem;
  public toolHelperLinks: IPlToolbarItem;
  public toolKeyboardShortcuts: IPlToolbarItem;
  public toolSearch: IPlToolbarItem;

  protected readonly _logger: Logger;
  protected readonly _viewRef: ViewRef;
  protected readonly _transition: Transition;
  protected readonly _uiRouterGlobals: UIRouterGlobals;
  protected readonly _stateService: StateService;
  protected readonly _translateService: TranslateService;
  protected readonly _plDocumentService: PlDocumentService;
  protected readonly _plToolbarService: PlToolbarService;
  protected readonly _appService: AppService;
  protected readonly _cgStateService: CGStateService;
  protected readonly _configService: ConfigService;

  protected readonly _document: Document;
  protected readonly _element: HTMLElement;
  protected readonly _subjectOnSave: Subject<Promise<unknown>>;
  protected readonly _subjectOnBack: Subject<Promise<void>>;

  private readonly _faqsService: FaqsService;
  private readonly _elementRef: ElementRef<HTMLElement>;
  private readonly _portalService: PortalService;
  private readonly _portalComponent: IPortalComponent;
  private readonly _subjectMaintenanceModeFullscreen: BehaviorSubject<boolean>;
  private readonly _subjectCloseMaintenance: Subject<unknown>;
  private readonly _subscriptionIsMobile: Subscription;
  private readonly _subscriptionAppPortal: Subscription;
  private readonly _subscriptionPageUnload: Subscription;
  private readonly _subscriptionStatus: Subscription;
  private readonly _subscriptionFormat: Subscription;
  private _instanceName: string;
  private _maintenanceMode: boolean;
  private _launchMode: EAppLaunchMode;
  private _configurations: ICGConfigurations;
  private _localHelperLinks: Array<IModuleHelperLink>;
  private _remoteHelperLinks: Array<IModuleHelperLink>;
  private _originalPortalToolbarInstanceId: string;
  private _isMobile: boolean;
  private _currentPortal: IActivePortal;
  private _format: IPlFormatConfig;
  private _observableOnSave: Observable<Promise<unknown>>;
  private _observableOnBack: Observable<Promise<void>>;
  private _observableOnCloseMaintenance: Observable<unknown>;
  private _observableMaintenanceModeFullscreen: Observable<boolean>;
  private _subscriptionConfigurations: Subscription;

  constructor(protected readonly _injector: Injector) {
    this._onConfigurationsChanged = this._onConfigurationsChanged.bind(this);

    this.session = this._injector.get<TUserSession>(RESOLVER_SESSION.provide);
    this._logger = this._injector.get<Logger>(Logger);
    this._viewRef = <ViewRef>this._injector.get<ChangeDetectorRef>(ChangeDetectorRef);
    this._transition = this._injector.get<Transition>(Transition);
    this._uiRouterGlobals = this._injector.get<UIRouterGlobals>(UIRouterGlobals);
    this._stateService = this._injector.get<StateService>(StateService);
    this._translateService = this._injector.get<TranslateService>(TranslateService);
    this._plDocumentService = this._injector.get<PlDocumentService>(PlDocumentService);
    this._plToolbarService = this._injector.get<PlToolbarService>(PlToolbarService);
    this._appService = this._injector.get<AppService>(AppService);
    this._cgStateService = this._injector.get<CGStateService>(CGStateService);
    this._configService = this._injector.get<ConfigService>(ConfigService);
    this._faqsService = this._injector.get<FaqsService>(FaqsService);
    this._document = this._injector.get<Document>(DOCUMENT);
    this._elementRef = this._injector.get<ElementRef>(ElementRef);
    this._portalService = this._injector.get<PortalService>(PortalService);
    this._portalComponent = this._injector.get<IPortalComponent>(DI_PORTAL_COMPONENT, undefined, {optional: true});
    this._configurations = this._injector.get<ICGConfigurations>(RESOLVER_CONFIGURATIONS.provide);

    this.toolTitle = {
      order: 0,
      groupId: DEFAULT_TOOLBAR_ID,
      id: 'title',
      type: 'title',
      caption: this._transition.to().data.pageTitle
    };
    this.toolTitle.visible = Boolean(this.toolTitle.caption);
    this.btnBack = {
      order: 10,
      groupId: DEFAULT_TOOLBAR_ID,
      id: 'back',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-angle-left"></i>',
      caption: 'entity.action.back',
      class: 'btn-back',
      visible: false,
      hideCaptionOnMobile: true,
      click: () => this.back()
    };
    this.btnRefresh = {
      order: 50,
      groupId: DEFAULT_TOOLBAR_ID,
      id: 'refresh',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-refresh"></i>',
      caption: 'entity.action.refresh',
      class: 'btn-success',
      visible: false,
      hideCaptionOnMobile: true
    };
    this.btnNovo = {
      order: 100,
      groupId: DEFAULT_TOOLBAR_ID,
      id: 'novo',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-plus-circle"></i>',
      caption: 'entity.action.new',
      class: 'btn-primary',
      visible: false,
      hideCaptionOnMobile: true
    };
    this.btnEdit = {
      order: 105,
      groupId: DEFAULT_TOOLBAR_ID,
      id: 'edit',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-pencil-square-o"></i>',
      caption: 'entity.action.edit',
      class: 'btn-warning',
      visible: false
    };
    this.btnSave = {
      order: 110,
      groupId: DEFAULT_TOOLBAR_ID,
      id: 'save',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-floppy-o"></i>',
      caption: 'entity.action.save',
      class: 'btn-primary',
      visible: false
    };
    this.btnCancel = {
      order: 115,
      groupId: DEFAULT_TOOLBAR_ID,
      id: 'cancel',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-ban"></i>',
      caption: 'entity.action.cancel',
      class: 'btn-danger',
      visible: false
    };
    this.btnDelete = {
      order: 120,
      groupId: DEFAULT_TOOLBAR_ID,
      id: 'delete',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-trash"></i>',
      caption: 'entity.action.delete',
      class: 'btn-danger',
      visible: false
    };
    this.btnDuplicate = {
      order: 125,
      groupId: DEFAULT_TOOLBAR_ID,
      id: 'duplicate',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-files-o"></i>',
      caption: 'entity.action.duplicate',
      class: 'btn-info',
      visible: false,
      tooltip: {placement: 'bottom', text: 'entity.action.tooltip.duplicate'}
    };
    this.dropdownActions = {
      order: 130,
      groupId: DEFAULT_TOOLBAR_ID,
      id: 'actions',
      type: 'dropdown',
      iconLeft: '<i class="fa fa-fw fa-bars"></i>',
      caption: 'entity.action.actions',
      class: 'btn-info',
      visible: false,
      menu: []
    };
    this.btnConfig = {
      order: 135,
      groupId: DEFAULT_TOOLBAR_ID,
      id: 'config',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-cog"></i>',
      caption: 'entity.action.config',
      class: 'btn-light',
      visible: false
    };
    this.toolSearch = {order: 500, groupId: DEFAULT_TOOLBAR_ID, id: 'search', type: 'search', visible: false, align: 'right'};
    this.toolHelperLinks = {
      order: 510,
      groupId: DEFAULT_TOOLBAR_ID,
      id: 'module-helper-links',
      type: 'dropdown',
      class: 'btn-link btn-helper-links',
      visible: false,
      align: 'right',
      iconLeft: '<i class="fa fa-fw fa-question-circle"></i>',
      caption: 'global.btn.help',
      menu: []
    };
    this.toolKeyboardShortcuts = {
      order: 520,
      groupId: DEFAULT_TOOLBAR_ID,
      id: 'module-keyboard-shortcuts',
      type: 'custom',
      visible: false,
      align: 'right',
      templateRef: undefined
    };

    this._element = this._elementRef.nativeElement;
    this._subjectOnSave = new Subject<Promise<unknown>>();
    this._subjectOnBack = new Subject<Promise<void>>();
    this._subjectMaintenanceModeFullscreen = new BehaviorSubject<boolean>(false);
    this._subjectCloseMaintenance = new Subject<unknown>();
    this._maintenanceMode = false;
    this._launchMode = EAppLaunchMode.Default;
    this._localHelperLinks = [];
    this._remoteHelperLinks = [];
    this._isMobile = cgcIsMobile();

    this._subscriptionIsMobile = this._plDocumentService.isMobile().subscribe((isMobile: boolean) => {
      this.setIsMobile(isMobile);
    });

    this._subscriptionAppPortal = this._portalService.getAppPortal().subscribe((activePortal: IActivePortal) => {
      this._currentPortal = activePortal;
    });

    this._subscriptionPageUnload = this._appService.onPageUnload().subscribe(this._onPageUnload.bind(this));

    this._subscriptionStatus = this._appService.status().subscribe((status: IAppStatus) => {
      this._launchMode = status.launchMode;
      if (this._launchMode === EAppLaunchMode.HybridPartial) {
        this.toolTitle.visible = false;
      }
    });

    this._subscriptionFormat = this._appService.format().subscribe((format: IPlFormatConfig) => {
      this._format = format;
    });

    this.setInstanceName(DEFAULT_TOOLBAR_ID);
  }

  public ngOnInit(): void {
    this._onConfigurationsChanged();
    this.setToolbarInstanceId(this.instanceName);
    if (!this.maintenanceMode) {
      this._appService.updateTitle();
    }
    this.setModuleHelperLinks(this._state.data?.helperLinks);
    this._faqsService.getCGFaqs(this._faqsName()).then((remoteHelperLinks: Array<[ICGFaq, IModuleHelperLink]>) => {
      let faqsUrl = '';
      this._remoteHelperLinks = [];
      for (const [faq, helperLink] of remoteHelperLinks) {
        this._remoteHelperLinks.push(helperLink);
        // Implicit equals `==` conversion intended
        if (!faqsUrl && faq.parentId === 0) {
          faqsUrl = faq.link;
        }
      }
      if (!this.maintenanceMode) {
        this._portalService.setAppFaqsUrl(faqsUrl);
      }
      this._evaluateHelperLinks();
    });
  }

  public ngOnDestroy(): void {
    this._subjectOnSave.complete();
    this._subjectOnBack.complete();
    this._subjectMaintenanceModeFullscreen.complete();
    this._subjectCloseMaintenance.complete();
    this._subscriptionIsMobile.unsubscribe();
    this._subscriptionAppPortal.unsubscribe();
    this._subscriptionPageUnload.unsubscribe();
    this._subscriptionStatus.unsubscribe();
    this._subscriptionFormat.unsubscribe();
    if (this._subscriptionConfigurations) {
      this._subscriptionConfigurations.unsubscribe();
    }
    if (this._originalPortalToolbarInstanceId && this._portalComponent) {
      this._portalComponent.setToolbarInstanceId(this._originalPortalToolbarInstanceId);
    }
    if (!this.maintenanceMode) {
      this._portalService.setAppFaqsUrl('');
    }
  }

  public back(): Promise<void> {
    if (this.maintenanceMode) {
      return Promise.resolve();
    }
    const transitionPromise = this._stateService.go(this._state.data.backState).then(() => undefined);
    this._subjectOnBack.next(transitionPromise);
    return transitionPromise;
  }

  public setCaption(caption: string): void {
    this.toolTitle.caption = caption;
    this.toolTitle.visible = Boolean(this.toolTitle.caption);
    if (!this.maintenanceMode) {
      this._appService.updateTitle(caption);
    }
  }

  public addModuleAction<T>(moduleAction: IModuleActionDefinition<T>): IModuleAction<T> {
    if (!isObject(moduleAction)) {
      throw new TypeError('Expected argument module action to be an object.');
    }
    const action: IModuleAction<T> = <IModuleAction<T>>moduleAction;
    const menuItem: IPlToolbarMenuItem<T> = moduleAction;
    action.setDisabled = (value: boolean) => {
      menuItem.disabled = value;
    };
    action.enable = () => {
      action.setDisabled(false);
    };
    action.disable = () => {
      action.setDisabled(true);
    };
    if (isFunction(action.action)) {
      menuItem.click = () => action.action(action.data);
    } else if (isObject(action.redirectTo)) {
      action._state = this._cgStateService.getRedirectState({stateOrName: action.redirectTo.state}) || action.redirectTo.state;
      menuItem.click = () => this._goToState(action._state, action.redirectTo.params, action.redirectTo.options);
    }
    this.dropdownActions.menu.push(menuItem);
    this.dropdownActions.visible = Boolean(this.dropdownActions.menu.length);
    return action;
  }

  public setModuleActions<T>(moduleActionOrActions: IModuleActionDefinition<T> | Array<IModuleActionDefinition<T>>): Array<IModuleAction<T>> {
    const actions: Array<IModuleActionDefinition<T>> = isArray(moduleActionOrActions) ? moduleActionOrActions : [moduleActionOrActions];
    this.dropdownActions.menu = [];
    const result: Array<IModuleAction<T>> = [];
    for (const action of actions) {
      result.push(this.addModuleAction(action));
    }
    this.dropdownActions.visible = Boolean(this.dropdownActions.menu.length);
    return result;
  }

  public setModuleHelperLinks(helperLinkOrLinks: IModuleHelperLink | Array<IModuleHelperLink>): void {
    this._localHelperLinks = helperLinkOrLinks ? (isArray(helperLinkOrLinks) ? helperLinkOrLinks : [helperLinkOrLinks]) : [];
    this._evaluateHelperLinks();
  }

  public setModuleKeyboardShortcuts(template: TemplateRef<IPlToolbarItemTemplateContext>): void {
    this.toolKeyboardShortcuts.templateRef = template;
    this.toolKeyboardShortcuts.visible = isDefined(this.toolKeyboardShortcuts);
  }

  public evtOnSave<T = unknown>(): Observable<Promise<T>> {
    if (!this._observableOnSave) {
      this._observableOnSave = this._subjectOnSave.asObservable();
    }
    return <Observable<Promise<T>>>this._observableOnSave;
  }

  public evtOnBack(): Observable<Promise<void>> {
    if (!this._observableOnBack) {
      this._observableOnBack = this._subjectOnBack.asObservable();
    }
    return this._observableOnBack;
  }

  public evtOnCloseMaintenance(): Observable<unknown> {
    if (!this._observableOnCloseMaintenance) {
      this._observableOnCloseMaintenance = this._subjectCloseMaintenance.asObservable();
    }
    return this._observableOnCloseMaintenance;
  }

  public setInstanceName(value: string): void {
    this._instanceName = value;
  }

  public setToolbarInstanceId(toolbarInstanceId: string): void {
    this.toolbar = this._plToolbarService.getInstance(toolbarInstanceId);
    this.toolbar.setItems([
      this.toolTitle,
      this.btnBack,
      this.btnRefresh,
      this.btnNovo,
      this.btnEdit,
      this.btnCancel,
      this.btnSave,
      this.btnDelete,
      this.btnDuplicate,
      this.dropdownActions,
      this.btnConfig,
      this.toolSearch,
      this.toolHelperLinks,
      this.toolKeyboardShortcuts
    ]);
    this.toolbar.config.search.active = false;
    this.toolbar.config.search.placeholder = 'entity.placeholder.search';
    this.toolbar.config.search.text = '';
    this.toolbar.setSearch(this.toolbar.config.search.text);
    this.setPortalToolbarInstanceId(toolbarInstanceId);
  }

  public setPortalToolbarInstanceId(toolbarInstanceId: string): void {
    if (!this._portalComponent) {
      return;
    }
    if (!this._originalPortalToolbarInstanceId) {
      this._originalPortalToolbarInstanceId = this._portalComponent.toolbarInstanceId;
    }
    this._portalComponent.setToolbarInstanceId(toolbarInstanceId);
  }

  public setMaintenanceMode(value: boolean): void {
    this._maintenanceMode = value;
  }

  public setMaintenanceModeFullscreen(value: boolean): void {
    if (!this.maintenanceMode) {
      return;
    }
    this._subjectMaintenanceModeFullscreen.next(value);
  }

  // When true and in maintenance mode, makes modal fullscreen
  public maintenanceModeFullscreen(): Observable<boolean> {
    if (!this._observableMaintenanceModeFullscreen) {
      this._observableMaintenanceModeFullscreen = this._subjectMaintenanceModeFullscreen.asObservable().pipe(distinctUntilChanged());
    }
    return this._observableMaintenanceModeFullscreen;
  }

  public setIsMobile(value: boolean): void {
    this._isMobile = value;
    this.toolHelperLinks.caption = this._isMobile ? '' : 'global.btn.help';
  }

  public closeMaintenance(value?: unknown): void {
    this._subjectCloseMaintenance.next(value);
  }

  public get instanceName(): string {
    return this._instanceName;
  }

  public get maintenanceMode(): boolean {
    return this._maintenanceMode;
  }

  public get launchMode(): EAppLaunchMode {
    return this._launchMode;
  }

  public get hybridMode(): boolean {
    return this._launchMode === EAppLaunchMode.Hybrid || this._launchMode === EAppLaunchMode.HybridPartial;
  }

  public get configurations(): ICGConfigurations {
    if (!this._subscriptionConfigurations) {
      this._subscriptionConfigurations = this._configService
        .configurationsAsObservable()
        .pipe(skip(1))
        .subscribe((configurations: ICGConfigurations) => {
          this._configurations = configurations;
          this._onConfigurationsChanged();
        });
    }
    return this._configurations;
  }

  public get isMobile(): boolean {
    return this._isMobile;
  }

  public get currentPortal(): IActivePortal {
    return this._currentPortal;
  }

  public get format(): IPlFormatConfig {
    return this._format;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected _onConfigurationsChanged(): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected _onPageUnload(): void {}

  protected _faqsName(): string {
    return this._state.data._moduleName;
  }

  protected _goToState(to: StateOrName, params?: RawParams, options?: TransitionOptions): Promise<void> {
    return this._stateService.go(to, params, options).then(() => undefined);
  }

  protected get _state(): ICGStateDeclaration {
    return <ICGStateDeclaration>this._transition.to();
  }

  private _evaluateHelperLinks(): void {
    const helperLinks: Array<IModuleHelperLink> = this._localHelperLinks.concat(this._remoteHelperLinks);
    this.toolHelperLinks.visible = Boolean(helperLinks.length);
    if (!this.toolHelperLinks.visible) {
      return;
    }
    this.toolHelperLinks.menu = helperLinks.map<IPlToolbarMenuItem>((helperLink: IModuleHelperLink) => {
      let iconLeft = helperLink.type;
      switch (iconLeft) {
        case 'youtube':
          iconLeft = 'fa-youtube-play';
          break;
      }
      if (iconLeft) {
        iconLeft = `<i class="fa fa-fw ${iconLeft}"></i>`;
      }
      const target: THyperlinkTarget = helperLink.target ? (helperLink.target.startsWith('_') ? <THyperlinkTarget>helperLink.target : <THyperlinkTarget>`_${helperLink.target}`) : undefined;
      return {
        type: 'link',
        caption: helperLink.caption,
        class: 'btn-helper-link',
        iconLeft: iconLeft,
        href: {value: helperLink.href, target: target || '_blank'}
      };
    });
    this.toolHelperLinks.menu.push({type: 'divider'});
    this.toolHelperLinks.menu.push({
      type: 'link',
      caption: 'helperLinks.global.all',
      class: 'btn-helper-link',
      iconLeft: '<i class="fa fa-fw fa-external-link-square"></i>',
      href: {value: LINK_CENTRALGEST_VIDEOS_CLOUD, target: '_blank'}
    });
    this.toolHelperLinks.menu.push({
      type: 'link',
      caption: 'helperLinks.global.faqs',
      class: 'btn-helper-link',
      iconLeft: '<i class="fa fa-fw fa-external-link-square"></i>',
      href: {value: LINK_CENTRALGEST_FAQS_CLOUD, target: '_blank'}
    });
  }
}
