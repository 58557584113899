<div class="gestao-dgemps-month-view">
  <pl-calendar-month-view
    [datasets]="datasets"
    [datasetsLabel]="'gestaodgemps.fields.nomeEmpregado' | translate"
    [viewDate]="viewDate"
    [holidays]="false"
    [holidaysDates]="holidaysDates"
    [allowSelectHolidays]="type !== types.Ferias"
    [rangeSelect]="true"
    [datasetDetail]="true"
    [(datasetDetailActive)]="detailActive"
    [(selectedRange)]="monthSelectedRange"
    [templateTitle]="templateTitle"
    [templateDataset]="templateDataset"
    [templateDay]="templateDay"
    [templateDayDetail]="templateDayDetail"
    (evtBeforeViewRender)="beforeViewRender($event)"
    (evtDayClick)="onMonthDayClick($event)"
    (evtRangeSelect)="onMonthRangeSelect($event)"
    (evtSelectionChanged)="onMonthSelectionChanged($event)">
  </pl-calendar-month-view>
</div>

<ng-template #templateTitle>
  <gestao-dgemps-view-date [view]="views.Month" [viewDate]="viewDate" (viewDateChange)="setViewDate($event)" [format]="'LLLL yyyy'"> </gestao-dgemps-view-date>
</ng-template>

<ng-template #templateDataset let-dataset="dataset">
  <span [attr.title]="dataset.meta.labelCompleta">{{ dataset.label }}</span>
</ng-template>

<ng-template #templateDay let-day="day">
  <div class="gestao-dgemps-day">
    <span>{{ day.label }}</span>
    <i class="fa fa-file gestao-dgemps-day-has-attachment" aria-hidden="true" *ngIf="monthDaysWithAttachment.has(day)"></i>
  </div>
</ng-template>

<ng-template #templateDayDetail>
  <gestao-dgemps-events-detail
    [type]="type"
    [manager]="manager"
    [codEmpLanca]="codEmpLanca"
    [start]="detailStart"
    [end]="detailEnd"
    [events]="detailEvents"
    [working]="loading"
    (evtRefresh)="onMonthDetailRefresh()"
    (evtAddEvent)="onDetailAddEvent($event)"
    (evtCloseDetail)="hideEventsDetail()">
  </gestao-dgemps-events-detail>
</ng-template>
