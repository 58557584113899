import type dxDataGrid from 'devextreme/ui/data_grid';
import {deepFreeze, ICGCTableData} from 'pl-comps-angular';
import {IEFaturaDocumentoComLinhas, IEFaturaDocumentoLinha} from '../importadorefatura/service/importadorEfatura.service.interface';
import {TDate} from '../../../../common/dates';
import {TTableLegend} from '../../../components/tablelegend/tablelegend.component.interface';
import {EDataGridLegendColors} from '../../../components/devexpress/datagrid/configuration/devexpress.datagrid.configuration';

export enum ETipoItemJaLancado {
  None,
  LigacaoDiretaNIFNDocExterno,
  Ignorado,
  AssociadoSemNDocExterno,
  IgnoradoPorFornecedor
}

export enum EDocDigital {
  NaoTem,
  Tem,
  TemSemelhante
}

export interface IVerificaEFaturaItem extends IEFaturaDocumentoComLinhas {
  possiveisDocs: Array<IConciliacaoEFaturasConnectDocument>;
  semelhantesDocs: Array<IConciliacaoEFaturasConnectDocument>;
  tipoItemJaLancado: ETipoItemJaLancado;
  isDeAnoEncerrado: boolean;
  documentoLancado: string;
  extPocCabId: string;
  numPossiveisDocs?: number;
  numSemelhantesDocs?: number;
  detalhesSource?: Array<IEFaturaDocumentoLinha>;
  detalhesDataGridInstance?: dxDataGrid;
  docDigital: EDocDigital;
}

export interface IVerificaEFaturaSource extends ICGCTableData<IVerificaEFaturaItem> {
  listLancadosIgnorados: Array<IVerificaEFaturaItem>;
  cache?: boolean;
}

export interface IConciliacaoEFaturasFilterTipo {
  id: number;
  tipo: string;
}

export interface IConciliacaoEFaturasFilter {
  fromDate: TDate;
  toDate: TDate;
  tipo?: IConciliacaoEFaturasFilterTipo;
  nifFornecedor?: string;
}

export interface IConciliacaoEFaturasConnectDocument {
  nDocInterno: string;
  nDocExterno: string;
  valor: number;
  dataDocExterno: TDate;
  periodo: string;
  nDiario: number;
  ano: number;
  nifEmitente: string;
  numerodocumento: string;
  extPocCabID: string;
  link?: string;
}

export const TABLE_LEGEND_DOCUMENTOS_SEM_RELACAO: TTableLegend = deepFreeze([
  {
    caption: 'conciliacaoEFaturas.withoutPossibleDocs',
    badgeCSSClass: EDataGridLegendColors.RedLegendColor
  }
]);

export const TABLE_LEGEND_DOCUMENTOS_JA_LANACADOS: TTableLegend = deepFreeze([
  {
    caption: 'conciliacaoEFaturas.tabJaLancadosCaptionContainer.legends.documentoIgnorado',
    badgeCSSClass: EDataGridLegendColors.YellowLegendColor
  },
  {
    caption: 'conciliacaoEFaturas.tabJaLancadosCaptionContainer.legends.associadoDocExtDiferente',
    badgeCSSClass: EDataGridLegendColors.RedLegendColor
  },
  {
    caption: 'conciliacaoEFaturas.tabJaLancadosCaptionContainer.legends.documentoFornecedorIgnorado',
    badgeCSSClass: EDataGridLegendColors.BlueLegendColor
  }
]);

export const MODULE_NAME_CONCILIACAO_EFATURAS = 'conciliacaoEfaturas';
