import {TDate} from '../../../common/dates';
import {Moment} from 'moment';

export interface IJsonExtractoArtigo {
  nArtigo: string;
  nomeArtigo: string;
  dataDoc: TDate;
  nDocAsString: string;
  nDocfa: number;
  nDocfaDraft: number;
  nNumerDraft: number;
  nDocumentoDraft: number;
  descricaoDocfa: string;
  nArmazem: number;
  nomeArmazem: string;
  nLoteEspecifico: string;
  preco: number;
  valorEntrada: number;
  valorSaida: number;
  saldoValor: number;
  qtdEntrada: number;
  qtdSaida: number;
  saldoQtd: number;
  nConta: string;
  nomeClifo: string;
  unidadeMov: number;
}

export interface IJsonExtractoArtigoFilter {
  nArtigoDe: string;
  nArtigoAte: string;
  nArmazemDe: number;
  nArmazemAte: number;
  dateAte: Moment;
  dateDe: Moment;
  calcMovAntesDepoisData: boolean;
}

export interface IExtractoArtigoModuleParams {
  nArtigo?: string;
}

export const MODULE_NAME_EXTRACTOSARTIGOS = 'extractosartigos';
