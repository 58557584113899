<div class="rhatualizaferiasemp-datacadastro-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'rhatualizaferiasemp.atualizadatacadastro'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'rhatualizaferiasemp.fields.dataMotivoCadastro'"></label>
      <edit>
        <pl-edit type="date" attrName="date" [(model)]="date"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success action-ok" [onClick]="close"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span> </pl-button>
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
