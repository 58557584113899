import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonAcrescimoEncargoComFeriasLinMulti} from '../../jsonAcrescimosEncargosFerias.module.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'modal-acrescimos-encargos-ferias-integ-contab-multi-errors',
  templateUrl: './acrescimosEncargosFerias.integContabMultiErrors.modal.component.html'
})
export class AcrescimosEncargosFeriasIntegContabMultiErrorsModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public listEmpresas: Array<IJsonAcrescimoEncargoComFeriasLinMulti>;

  public readonly dataGridDefinitionEmpresas: IDevExpressDataGrid;
  public empresasError: Array<IJsonAcrescimoEncargoComFeriasLinMulti>;
  public empresasSuccess: Array<IJsonAcrescimoEncargoComFeriasLinMulti>;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.dataGridDefinitionEmpresas = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'temErro;', dataType: 'boolean', caption: 'acrescimosencargosferias.integContabMultiErrors.integ', cellTemplate: 'cellTemplateIntegrado'},
        {dataField: 'nEmpresa', dataType: 'string', caption: 'acrescimosencargosferias.fields.nEmpresa'},
        {dataField: 'nomeEmpresa', dataType: 'string', caption: 'acrescimosencargosferias.fields.nomeEmpresa'},
        {dataField: 'anoEncargo', dataType: 'number', caption: 'acrescimosencargosferias.fields.anoEncargo'},
        {dataField: 'multiLinhaErro', dataType: 'string', caption: 'acrescimosencargosferias.fields.infoErro'}
      ],
      dataSource: [],
      height: '60vh',
      paging: {enabled: false},
      pager: {visible: false},
      scrolling: {rowRenderingMode: 'virtual'},
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    this.dataGridDefinitionEmpresas.dataSource = this.listEmpresas;
  }
}
