<div class="exportacao-compras-module-config-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()" [disabled]="closeDisabled"></button>
    <h5 class="modal-title" [translate]="'exportacaoCompras.config.title'"></h5>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12 col-xl-6">
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinitionDocsComprasList"
          cgDxDataGridInstanceName="docsComprasList"
          [dataSource]="this.dataDocsComprasList"
          (onInitialized)="onInitializedDocsComprasList($event)"
          (onSaving)="onSavingDocs($event)"
          (onSaved)="onSavedDocsCompras($event)">
        </dx-data-grid>
      </div>

      <div class="col-sm-12 col-xl-6">
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinitionDocsNotaCreditoList"
          cgDxDataGridInstanceName="docsNotaCreditoList"
          [dataSource]="this.dataDocsNotaCreditoList"
          (onInitialized)="onInitializedDocsNotaCreditoList($event)"
          (onSaving)="onSavingDocs($event)"
          (onSaved)="onSavedDocsNotaCredito($event)">
        </dx-data-grid>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-sm-12">
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinitionFamiliasList"
          cgDxDataGridInstanceName="familiasList"
          [dataSource]="this.dataFamiliasList"
          (onInitialized)="onInitializedFamiliasList($event)"
          (onCellPrepared)="onCellPreparedFamiliasList($event)"
          (onSaving)="onSavingFamilias($event)"
          (onRowUpdated)="onRowUpdatedFamiliasList($event)">
        </dx-data-grid>
        <br />
        <div class="bancos-extrato-module-legend">
          <cg-table-legend [legend]="this.familiasDataGridLegend"></cg-table-legend>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success action-dismiss" [onClick]="close" [disabled]="closeDisabled"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span> </pl-button>

    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
