<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'conciliacaoBancaria.viewDocModalTitle'"></h4>
</div>

<div class="modal-body">
  <div [plPromise]="promise">
    <documento-contabilidade [(doc)]="doc" [maintenanceMode]="true" [showToolbar]="false" [onActionEdited]="fnOnDocChanged" [onActionDeleted]="fnOnDocChanged"> </documento-contabilidade>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
