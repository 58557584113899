import {Observable} from 'rxjs';
import {inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHandlerFn, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {API_PATH} from '../common/api';
import {APP_API_KEY} from '../common/app';
import {AuthService} from '../core/services/auth/auth.service';
import {HTTP_HEADER_X_AUTH_NEMPRESA} from '../config/constants';

export function interceptorRequest(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const authService: AuthService = inject(AuthService);
  if (request.url.startsWith(API_PATH.host)) {
    if (request.method === 'DELETE') {
      const headers = request.headers.set('Content-Type', 'application/json');
      request = request.clone({headers: headers});
    }

    const nEmpresa: string = authService.identityValue?.erp?.nEmpresa;
    if (nEmpresa) {
      request = request.clone({headers: request.headers.set(HTTP_HEADER_X_AUTH_NEMPRESA, nEmpresa)});
    }

    if (APP_API_KEY.value) {
      request = request.clone({headers: request.headers.set('x-auth-token', APP_API_KEY.value)});
    }
  }
  return next(request);
}

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private readonly _authService: AuthService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith(API_PATH.host)) {
      if (request.method === 'DELETE') {
        const headers = request.headers.set('Content-Type', 'application/json');
        request = request.clone({headers: headers});
      }

      const nEmpresa: string = this._authService.identityValue?.erp?.nEmpresa;
      if (nEmpresa) {
        request = request.clone({headers: request.headers.set(HTTP_HEADER_X_AUTH_NEMPRESA, nEmpresa)});
      }

      if (APP_API_KEY.value) {
        request = request.clone({headers: request.headers.set('x-auth-token', APP_API_KEY.value)});
      }
    }
    return next.handle(request);
  }
}
