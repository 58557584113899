import {Subscription} from 'rxjs';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {HookMatchCriteria, StateDeclaration, StateObject, Transition, TransitionService} from '@uirouter/core';
import {downloadStream, IPlToolbarInstance, IPlToolbarItem, isFunction, isNumber, isObject, PlAlertService} from 'pl-comps-angular';
import {CG_HELP_TOPIC_CONTAB_DIGITAL_FAQ} from '../../../../components/arquivodigital/contabilidade/docviewerrecolha/contabilidadedigital.docviewer.recolha.component.interface';
import {CG_HELP_TOPIC_OTHER} from '../../../../components/cg/modal/helptopics/helptopics';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ConfigOptionsService} from '../../../../services/config/options/config.options.service';
import {ArquivoDigitalConfigModalComponent} from '../../../../components/arquivodigital/modals/config/arquivoDigital.config.modal.component';
import {ContabilidadeDigitalServiceDocuments} from '../../../../services/contabilidadedigital/contabilidadedigital.service.documents';
import {ContabilidadeDigitalUIService} from '../../../../services/contabilidadedigital/contabilidadedigital.ui.service';
import {DocsDigitaisExportarModalComponent} from '../modals/exportar/docsDigitais.exportar.modal.component';
import {EConfigOptionsInstanceName, IContabDigitalDocumentosDigitaisConfigOptions, TConfigOptions} from '../../../../services/config/options/config.options.service.interface';
import {
  EArquivoDigitalGestaoDocsDigitalizadosTypeAction,
  EContabDigitalGestaoDocsDigitalizadosExportState,
  IContabDigitalGestaoDocsDigitalizadosCallback,
  IContabDigitalGestaoDocsDigitalizadosExportFilters,
  IContabDigitalGestaoDocsDigitalizadosExportStatus,
  IContabDigitalGestaoDocsDigitalizadosExportZipControl,
  IDocDigitalizado
} from '../contabDigital.documentosDigitais.module.interface';
import {EContabilidadeDigitalActivateLicenseType} from '../../../../components/arquivodigital/modals/activatelicense/contabilidadedigital.activatelicensemodal.component.interface';
import {ICGHelpTopic} from '../../../../components/cg/modal/helptopics/helptopics.modal.component.interface';
import {IConfigOptionsInstance} from '../../../../services/config/options/config.options.instance.interface';
import {IArquivoDigitalDocViewerCallback, IArquivoDigitalDocViewerEvtConfigureToolbar} from '../../../../components/arquivodigital/common/docviewer/arquivodigital.docviewer.component.interface';
import {
  EGestaoDocumentosDigitalizadosMode,
  IArquivoDigitalGestaoDocumentosDigitalizadosEvtCalledAction
} from '../../../../components/arquivodigital/common/gestaodocumentosdigitalizados/arquivoDigital.gestaoDocumentosDigitalizados.component.interface';
import {IJsonDocDigitalizado, IJsonDocOCR, IJsonDocOCRLinhaIva} from '../../../../services/contabilidadedigital/jsonContabDigital.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {STATE_NAME_DISCONNECTED} from '../../../../states/account/disconnected/disconnected.state.interface';
import {STATE_NAME_LOGIN} from '../../../../states/account/login/login.state.interface';
import {IContabilidadeDigitalDocumentosDigitaisParams} from '../../../../services/contabilidadedigital/contabilidadedigital.interface';
import {TUserSession} from '../../../../services/account/jsonUserApi.interface';
import {AuthService} from '../../../../services/auth/auth.service';

const TOOLBAR_ID_DOCUMENTOS_DIGITAIS = 'contabilidade-digital-documentos-digitais';
const TOOLBAR_ORDER_STEP = 1;
const EXPORT_STATUS_INTERVAL_TIMEOUT = 10000;

@Component({
  selector: 'module-contabilidade-digital-documentos-digitais',
  templateUrl: './contabDigital.documentosDigitais.module.component.html'
})
export class ContabilidadeDigitalDocumentosDigitaisModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public temContabilidadeDigital: boolean;
  @Input() public cgStoreUrlBackOffice: string;
  @Input() public clientConnectMode: boolean;
  @Input() public mode: EGestaoDocumentosDigitalizadosMode;
  @Input() public temDocumentosDigitais: boolean;

  public readonly callbackContabDigital: IContabDigitalGestaoDocsDigitalizadosCallback;
  public readonly documentsService: ContabilidadeDigitalServiceDocuments;
  public readonly callbackDocViewer: IArquivoDigitalDocViewerCallback;
  public readonly exportModel: IContabDigitalGestaoDocsDigitalizadosExportZipControl;
  public modoCGOn: boolean;
  public docID: string;
  public toolbarInstanceId: string;
  public nDocumentoContabDigitalClassfied: string;
  public docOCR: IJsonDocOCR;
  public docViewerFooterCollapsed: boolean;
  public headerParams: IContabilidadeDigitalDocumentosDigitaisParams;

  protected readonly _plAlertService: PlAlertService;
  protected readonly _cgModalService: CGModalService;

  private readonly _transitionService: TransitionService;
  private readonly _configOptionsService: ConfigOptionsService;
  private readonly _contabilidadeDigitalService: ContabilidadeDigitalUIService;
  private readonly _authService: AuthService;

  private readonly _configOptionsInstance: IConfigOptionsInstance<boolean, IContabDigitalDocumentosDigitaisConfigOptions>;
  private readonly _docOCRLinhasIVA: Map<string, Array<IJsonDocOCRLinhaIva>>;
  private readonly _subscriptionConfigOptions: Subscription;
  private _btnConfig: IPlToolbarItem;
  private _btnHelp: IPlToolbarItem;
  private _btnConfigGlobal: IPlToolbarItem;
  private _btnExportGlobal: IPlToolbarItem;
  private _toolbarInstance: IPlToolbarInstance;
  private _optionDocViewerFooterCollapsed: boolean;
  private _exportStatusIntervalId: number;
  private _deRegisterOnStartFn: Function;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this._plAlertService = this._injector.get<PlAlertService>(PlAlertService);
    this._cgModalService = this._injector.get<CGModalService>(CGModalService);
    this._transitionService = this._injector.get<TransitionService>(TransitionService);
    this._configOptionsService = this._injector.get<ConfigOptionsService>(ConfigOptionsService);
    this._contabilidadeDigitalService = this._injector.get<ContabilidadeDigitalUIService>(ContabilidadeDigitalUIService);
    this._authService = this._injector.get<AuthService>(AuthService);

    this.callbackContabDigital = {};
    this.documentsService = this._contabilidadeDigitalService.documents;
    this.callbackDocViewer = {};
    this.exportModel = {
      isReady: false,
      fileName: '',
      showProcess: false
    };
    this.temContabilidadeDigital = false;
    this.modoCGOn = false;
    this.docOCR = {
      cab: {
        docOCRCabID: undefined,
        docID: undefined,
        nif: undefined,
        temNIFEmpresa: undefined,
        dataDoc: undefined,
        nDocumento: undefined,
        totalIVA: undefined,
        totalBase: undefined,
        total: undefined,
        totaisObtidos: undefined,
        totaisOK: undefined,
        status: undefined,
        associadoADocContab: undefined,
        fiscalmenteRelevante: undefined,
        invoiceType: undefined,
        gDoc: undefined,
        validadoEFatura: undefined,
        preDefinidosID: undefined,
        temQRCode: undefined,
        valorRetencao: undefined,
        nContaEntidade: undefined,
        idDocOCRCabRepetidoNIFDoc: undefined
      },
      linhasIVA: []
    };

    this._docOCRLinhasIVA = new Map<string, Array<IJsonDocOCRLinhaIva>>();
    this.headerParams = <IContabilidadeDigitalDocumentosDigitaisParams>this._transition.params();

    this._configOptionsInstance = this._configOptionsService.getOptionsContabilidade().get(EConfigOptionsInstanceName.CONTAB_DIGITAL_DOCUMENTOS_DIGITAIS);
    this._subscriptionConfigOptions = this._configOptionsInstance.options().subscribe((configOptions: TConfigOptions<boolean, IContabDigitalDocumentosDigitaisConfigOptions>) => {
      this._optionDocViewerFooterCollapsed = configOptions.get('docViewerFooterCollapsed').value;
      this._evaluateDocViewerFooterCollapsed();
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnSave.click = () => this._save();
    this.toolbar.addButton(this._btnConfigGlobal);
    if (!this.clientConnectMode) {
      if (this.temContabilidadeDigital) {
        this.btnSave.visible = true;
        setTimeout(() => {
          this._registerOnStart();
        });
      } else if (this.temDocumentosDigitais) {
        this.toolbar.addButton(this._btnExportGlobal);
      }
      if (this.mode !== EGestaoDocumentosDigitalizadosMode.Comercial) {
        this._contabilidadeDigitalService.exportStatus().then((response: HttpResponse<IContabDigitalGestaoDocsDigitalizadosExportStatus>) => {
          this._processExportStatusResponse(response.body);
        });
      }
    }
    this._evaluateBtnConfigVisibility();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._deRegisterOnStart();
    this._subscriptionConfigOptions.unsubscribe();
    this._clearExportStatusInterval();
    if (!this.clientConnectMode && this.mode !== EGestaoDocumentosDigitalizadosMode.Comercial) {
      this._contabilidadeDigitalService.exportCancel();
    }
  }

  public setInstanceName(value: string): void {
    super.setInstanceName(value);
    if (!this._btnConfig) {
      this._btnConfig = {
        groupId: TOOLBAR_ID_DOCUMENTOS_DIGITAIS,
        id: 'config',
        type: 'button',
        iconLeft: '<i class="fa fa-list-alt"></i>',
        class: 'btn-sm btn-light',
        title: 'arquivodigital.docviewerrecolha.title.config',
        click: () => this._config()
      };
    }
    if (!this._btnHelp) {
      this._btnHelp = {
        groupId: TOOLBAR_ID_DOCUMENTOS_DIGITAIS,
        id: 'help',
        type: 'button',
        iconLeft: '<i class="fa fa-question-circle"></i>',
        class: 'btn-sm btn-light',
        title: 'global.btn.help',
        click: () => this._help()
      };
    }
    if (!this._btnConfigGlobal) {
      this._btnConfigGlobal = {
        ...this._btnConfig,
        order: this.btnSave.order + 1,
        iconLeft: '<i class="fa fa-fw fa-list-alt"></i>',
        caption: 'arquivodigital.docviewerrecolha.title.config'
      };
    }
    if (!this._btnExportGlobal) {
      this._btnExportGlobal = {
        groupId: TOOLBAR_ID_DOCUMENTOS_DIGITAIS,
        id: 'config',
        type: 'button',
        order: this._btnConfigGlobal.order + 1,
        iconLeft: '<i class="fa fa-fw fa-download"></i>',
        class: 'btn-sm btn-primary',
        caption: 'arquivodigital.gestaodocsdigitalizados.actions.exportarArquivo',
        click: () => this._exportDocumentosDigitais()
      };
    }
    if (this._toolbarInstance) {
      this._toolbarInstance.removeGroupId(TOOLBAR_ID_DOCUMENTOS_DIGITAIS);
    }
    this.toolbarInstanceId = `${this.instanceName}-${TOOLBAR_ID_DOCUMENTOS_DIGITAIS}`;
    this._toolbarInstance = this._plToolbarService.getInstance(this.toolbarInstanceId);
    this._toolbarInstance.removeGroupId(TOOLBAR_ID_DOCUMENTOS_DIGITAIS).addButton(this._btnConfig).addButton(this._btnHelp);
  }

  public setIsMobile(value: boolean): void {
    super.setIsMobile(value);
    this._evaluateDocViewerFooterCollapsed();
  }

  public changedSelectedLine(value: IDocDigitalizado): void {
    this.nDocumentoContabDigitalClassfied = isObject(value) && value._classified ? value.nDocInterno : '';
    this.docID = isObject(value) ? value.docID : undefined;
    if (!this.clientConnectMode) {
      this._setDocOCR(value);
    }
  }

  public changedDocViewerFooterCollapsed(value: boolean): void {
    this._configOptionsInstance.setOption('docViewerFooterCollapsed', value).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }

  public configureToolbar(event: IArquivoDigitalDocViewerEvtConfigureToolbar): void {
    this._contabilidadeDigitalService.configureToolbar(event);
    this._btnConfig.order = event.btnRotateRight.order + TOOLBAR_ORDER_STEP;
    this._evaluateBtnConfigVisibility();
    this._btnHelp.order = this._btnConfig.order + TOOLBAR_ORDER_STEP;
    this._toolbarInstance.sortItems();
  }

  public calledAction({action, selectedLine}: IArquivoDigitalGestaoDocumentosDigitalizadosEvtCalledAction): void {
    switch (action) {
      case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.UnirDocs:
        this.docID = undefined;
        break;
      case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.RemovePages:
        this.docID = undefined;
        setTimeout(() => {
          this.docID = isObject(selectedLine) ? selectedLine.docID : undefined;
        });
        break;
      case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.ExportarArquivo:
        this._exportDocumentosDigitais();
        break;
      default:
        break;
    }
  }

  public onResizerValuesChanged(): void {
    if (isFunction(this.callbackDocViewer.pdf?.updateSize)) {
      setTimeout(this.callbackDocViewer.pdf.updateSize);
    }
  }

  public async exportProcessDownload(): Promise<void> {
    try {
      const response: HttpResponse<Blob> = await this._contabilidadeDigitalService.downloadExportedFile();
      downloadStream(response);
      this.exportProcessClose();
      await this._contabilidadeDigitalService.exportCancel();
    } catch (reason: unknown) {
      this._logger.error(reason);
      this._plAlertService.error('arquivodigital.gestaodocsdigitalizados.export.downloadError');
    }
  }

  public exportProcessClose(): void {
    this.exportModel.showProcess = false;
  }

  protected _onConfigurationsChanged(): void {
    this.temContabilidadeDigital = this.configurations.empresa.temContabilidadeDigital;
    this.modoCGOn = this.configurations.licenca.modoCGOn;
    this._evaluateBtnConfigVisibility();
  }

  protected _onPageUnload(): void {
    super._onPageUnload();
    if (!this.clientConnectMode && this.mode !== EGestaoDocumentosDigitalizadosMode.Comercial) {
      this._appService.sendBeacon(this._contabilidadeDigitalService.exportCancelUrl());
    }
  }

  protected async _callConfigModal(): Promise<void> {
    const session: TUserSession = await this._authService.identity();
    const modalInstance = this._cgModalService.showVanilla(ArquivoDigitalConfigModalComponent, {size: 'xxl'});
    const componentInstance: ArquivoDigitalConfigModalComponent = modalInstance.componentInstance;
    componentInstance.session = session;
    return modalInstance.result.then(() => undefined);
  }

  protected _setDocOCR(docDigitalizado: IJsonDocDigitalizado): void {
    let linhasIVA: Array<IJsonDocOCRLinhaIva> | Promise<Array<IJsonDocOCRLinhaIva>>;
    if (!docDigitalizado) {
      linhasIVA = [];
    } else if (this._docOCRLinhasIVA.has(docDigitalizado?.docOCRCabID)) {
      linhasIVA = this._docOCRLinhasIVA.get(docDigitalizado.docOCRCabID);
    } else {
      linhasIVA = this._contabilidadeDigitalService
        .getDocDigitalizadoLinhasIVA(docDigitalizado.docOCRCabID)
        .then((response: HttpResponse<Array<IJsonDocOCRLinhaIva>>) => {
          this._docOCRLinhasIVA.set(docDigitalizado.docOCRCabID, response.body);
          return response.body;
        })
        .catch(() => []);
    }
    Promise.resolve(linhasIVA).then((value: Array<IJsonDocOCRLinhaIva>) => {
      this.docOCR = {
        cab: {
          validadoEFatura: false,
          totalBase: 0,
          totalIVA: 0,
          valorRetencao: 0,
          total: 0,
          ...docDigitalizado
        },
        linhasIVA: value
      };
    });
  }

  private _evaluateBtnConfigVisibility(): void {
    const visible: boolean = !this.clientConnectMode && (this.temContabilidadeDigital || !this.modoCGOn);
    if (this._btnConfig) {
      this._btnConfig.visible = visible;
    }
    if (this._btnConfigGlobal) {
      this._btnConfigGlobal.visible = visible;
    }
  }

  private _save(): Promise<void> {
    return this.callbackContabDigital.save();
  }

  private _navigationSafeGuard(): Promise<void> {
    if ((isFunction(this.callbackContabDigital.hasLineChanges) && this.callbackContabDigital.hasLineChanges()) || this.exportModel.showProcess) {
      if (isFunction(this.callbackContabDigital.hasLineChanges) && this.callbackContabDigital.hasLineChanges()) {
        return this._cgModalService.showOkCancel('arquivodigital.gestaodocsdigitalizados.messages.exitTitle', 'arquivodigital.gestaodocsdigitalizados.messages.exitMessage');
      }
      return this._cgModalService.showOkCancel('arquivodigital.gestaodocsdigitalizados.messages.leavePromptTitle', 'arquivodigital.gestaodocsdigitalizados.messages.leavePromptMessage', {
        size: 'md',
        backdrop: 'static',
        keyboard: false,
        btnOkText: 'global.btn.yes',
        btnCancelText: 'global.btn.no'
      });
    }
    return Promise.resolve();
  }

  private _registerOnStart(): void {
    this._deRegisterOnStart();
    const criteria: HookMatchCriteria = {
      to: (state: StateObject, transition: Transition) => {
        const toState: StateDeclaration = transition.to();
        return transition.from() !== toState && toState.name !== STATE_NAME_LOGIN && toState.name !== STATE_NAME_DISCONNECTED;
      }
    };
    this._deRegisterOnStartFn = this._transitionService.onStart(criteria, () => this._navigationSafeGuard());
  }

  private _deRegisterOnStart(): void {
    if (isFunction(this._deRegisterOnStartFn)) {
      this._deRegisterOnStartFn();
      this._deRegisterOnStartFn = undefined;
    }
  }

  private async _config(): Promise<void> {
    const validLicense: boolean = await this._contabilidadeDigitalService.checkConsumeLicense(EContabilidadeDigitalActivateLicenseType.ContabilidadeDigital);
    if (!validLicense) {
      return Promise.reject(new Error('Invalid "Contabilidade Digital" license.'));
    }
    return this._callConfigModal();
  }

  private async _help(): Promise<void> {
    const topics: Array<ICGHelpTopic> = [CG_HELP_TOPIC_CONTAB_DIGITAL_FAQ, CG_HELP_TOPIC_OTHER];
    return this._cgModalService.showHelpTopics(topics, undefined, {size: 'md'});
  }

  private _evaluateDocViewerFooterCollapsed(): void {
    this.docViewerFooterCollapsed = !this.isMobile && this._optionDocViewerFooterCollapsed;
  }

  private _exportDocumentosDigitais(): Promise<void> {
    return this._cgModalService
      .show(DocsDigitaisExportarModalComponent, {size: 'md'})
      .then((filterModel: IContabDigitalGestaoDocsDigitalizadosExportFilters) => {
        this._resetExportModel();
        return this._contabilidadeDigitalService
          .export(filterModel)
          .then(() => {
            return this._startExportProcessChecker();
          })
          .catch((reason: HttpErrorResponse) => {
            this._logger.error(reason);
          });
      })
      .catch((reason: unknown) => {
        this._logger.error(reason);
      });
  }

  private _startExportProcessChecker(): Promise<void> {
    this._clearExportStatusInterval();
    return this._contabilidadeDigitalService.exportStatus().then((response: HttpResponse<IContabDigitalGestaoDocsDigitalizadosExportStatus>) => {
      this._processExportStatusResponse(response.body);
      if (this.exportModel.currentState.state === EContabDigitalGestaoDocsDigitalizadosExportState.Started) {
        this._exportStatusIntervalId = window.setInterval(() => {
          this._startExportProcessChecker();
        }, EXPORT_STATUS_INTERVAL_TIMEOUT);
      }
    });
  }

  private _processExportStatusResponse(status: IContabDigitalGestaoDocsDigitalizadosExportStatus): void {
    this.exportModel.currentState = status;
    if (isObject(this.exportModel.currentState)) {
      switch (status.state) {
        case EContabDigitalGestaoDocsDigitalizadosExportState.Timeout:
          this._showTimeoutModal();
          break;
        case EContabDigitalGestaoDocsDigitalizadosExportState.Error:
          this._plAlertService.error(status.description);
          break;
        case EContabDigitalGestaoDocsDigitalizadosExportState.Ended:
          if (status.isReady) {
            this.exportModel.showProcess = true;
            this.exportModel.fileName = status.filename;
            this.exportModel.isReady = true;
          } else {
            this._plAlertService.error('arquivodigital.gestaodocsdigitalizados.export.fileNotProcessed');
          }
          break;
        case EContabDigitalGestaoDocsDigitalizadosExportState.Started:
          this.exportModel.showProcess = true;
          this.exportModel.isReady = false;
          break;
        default:
          break;
      }
    }
  }

  private _showTimeoutModal(): Promise<void> {
    return this._cgModalService
      .showOkCancel('arquivodigital.gestaodocsdigitalizados.export.jobTimeoutModalTitle', 'arquivodigital.gestaodocsdigitalizados.export.jobTimeoutModalMessage', {
        size: 'md',
        showCancelBtn: false,
        backdrop: 'static',
        keyboard: false
      })
      .then(() => {
        this._resetExportModel();
      });
  }

  private _resetExportModel(): void {
    this.exportModel.fileName = '';
    this.exportModel.isReady = false;
    this.exportModel.showProcess = true;
  }

  private _clearExportStatusInterval(): void {
    if (isNumber(this._exportStatusIntervalId)) {
      window.clearInterval(this._exportStatusIntervalId);
      this._exportStatusIntervalId = undefined;
    }
  }
}
