<pl-form class="encomendas-impressao-modal entity-detail-form" [formInstanceName]="'encomendas-impressao-modal-intance-name'">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()" [disabled]="closeDisabled"></button>
    <h4 class="modal-title" [translate]="'encomendas.modal.impressao.title'"></h4>
    <pl-button klass="btn-success btn-sm action-previsualizar" (evtClicked)="previsualizar()" #btnActionPreview>
      <i class="fa fa-fw fa-file-pdf-o"></i>&nbsp;<span [translate]="'global.btn.visualize'"></span>
    </pl-button>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'global.text.listing'"></label>
      <edit>
        <pl-edit type="reports_encomendas" attrName="listagem" [(model)]="reportModel" plAutoFocus></pl-edit>
      </edit>
    </pl-group>

    <div *ngIf="showFilter">
      <pl-group *ngIf="modeClifo">
        <label [translate]="clifoDescription"></label>
        <edit>
          <div class="form-control-align">{{ header.nConta }} - {{ header.nomeConta }}</div>
        </edit>
      </pl-group>

      <pl-group *ngIf="!modeClifo">
        <label [translate]="clifoDescription"></label>
        <edit>
          <pl-autocomplete [source]="clifosSource" [model]="clifoSource" [rowTemplate]="outputClifos" (evtSelected)="clifoChanged($event.item)" [allowInvalid]="false" [output]="outputClifos">
          </pl-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'encomendas.modal.impressao.fields.dataDocDe'"></label>
          <edit>
            <pl-edit-datepicker [(model)]="header.dataDocDe"></pl-edit-datepicker>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'global.text.to'"></label>
          <edit>
            <pl-edit-datepicker [(model)]="header.dataDocAte"></pl-edit-datepicker>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'encomendas.modal.impressao.fields.dataEntregaPrevDe'"></label>
          <edit>
            <pl-edit-datepicker [(model)]="header.dataPrevEntregaDe"></pl-edit-datepicker>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'global.text.to'"></label>
          <edit>
            <pl-edit-datepicker [(model)]="header.dataPrevEntregaAte"></pl-edit-datepicker>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'encomendas.modal.impressao.fields.armazens'"></label>
          <edit>
            <pl-select
              [source]="armazensSource"
              [(model)]="armazemSource"
              (evtChanged)="armazensChanged($event.item)"
              [rowTemplate]="armazemTemplate"
              [output]="armazemTemplate"
              [properties]="{placeholder: 'encomendas.modal.impressao.filtros.todos' | translate}"
              [multiple]="true">
            </pl-select>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'encomendas.modal.impressao.fields.docfaclist'"></label>
          <edit>
            <pl-select
              [source]="docFaListSource"
              [(model)]="docFaSource"
              (evtChanged)="docfaListChanged($event.item)"
              [rowTemplate]="docFaTemplate"
              [output]="docFaTemplate"
              [properties]="{placeholder: 'encomendas.modal.impressao.filtros.todos' | translate}"
              [multiple]="true">
            </pl-select>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <label [translate]="'encomendas.modal.impressao.fields.estadoEncomendas'"></label>
        <edit>
          <pl-edit
            type="radiogroup"
            attrName="estado"
            [model]="header.estadoEncomendas"
            (modelChange)="header.estadoEncomendas = $event"
            [properties]="radioGroupTemplateEstado"
            #elementEstado></pl-edit>
        </edit>
      </pl-group>
    </div>

    <br />

    <cg-pdf-viewer [pdfSrc]="pdfSrc"></cg-pdf-viewer>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-sm btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</pl-form>
