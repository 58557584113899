import {Subscription} from 'rxjs';
import {Component, Input, OnDestroy} from '@angular/core';
import type {NgbDropdown} from '@ng-bootstrap/ng-bootstrap';
import {capitalize, isMobile, PlDocumentService} from 'pl-comps-angular';
import {AppService} from '../../../../services/app/app.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {CGLocalStorageService} from '../../../../services/storage/localstorage.service';
import {CGModalService} from '../../modal/cgmodal.service';
import {ConfigSiteService} from '../../../../services/configsite.service';
import {IJsonErpUser, TUserSession} from '../../../../services/account/jsonUserApi.interface';
import {SCHEMA_BOOLEAN} from '../../../../../common/schemas';
import {STATE_NAME_LOGIN} from '../../../../states/account/login/login.state.interface';
import {ConfigService} from '../../../../services/config/config.service';
import {ICGConfigurations} from '../../../../services/config/config.service.interface';

const MAX_PORTALS = 10;
const KEY_COLLAPSE_RECENT_COMPANIES = 'collapse_recent_companies';

@Component({
  selector: 'cg-user-nav',
  templateUrl: './cg.user.nav.component.html'
})
export class CGUserNavComponent implements OnDestroy {
  @Input() public dropdownRef: NgbDropdown;

  public readonly stateNameLogin: string;
  public mobile: boolean;
  public email: string;
  public name: string;
  public recentCompanies: Array<IJsonErpUser>;
  public collapseRecentCompanies: boolean;
  public storeModePublic: boolean;
  public cgStoreUrl: string;

  private readonly _subscriptionSession: Subscription;
  private readonly _subscriptionConfigurations: Subscription;
  private readonly _subscriptionIsMobile: Subscription;
  private _session: TUserSession;
  private _subscriptionGetCollapseRecentCompanies: Subscription;
  private _subscriptionSetCollapseRecentCompanies: Subscription;

  constructor(
    private readonly _plDocumentService: PlDocumentService,
    private readonly _appService: AppService,
    private readonly _authService: AuthService,
    private readonly _configService: ConfigService,
    private readonly _configSiteService: ConfigSiteService,
    private readonly _cgModalService: CGModalService,
    private readonly _cgLocalStorageService: CGLocalStorageService
  ) {
    this.stateNameLogin = STATE_NAME_LOGIN;
    this.mobile = isMobile();
    this.recentCompanies = [];
    this.collapseRecentCompanies = false;

    this._configSiteService.cgStoreUrl(false).then((cgStoreUrl: string) => {
      this.cgStoreUrl = cgStoreUrl;
    });

    this._subscriptionSession = this._authService.identityAsObservable().subscribe((session: TUserSession) => {
      this._session = session;
      this._evaluateSession();
    });

    this._subscriptionConfigurations = this._configService.configurationsAsObservable().subscribe((configurations: ICGConfigurations) => {
      this.storeModePublic = configurations.licenca.storeModePublic;
    });

    this._subscriptionIsMobile = this._plDocumentService.isMobile().subscribe((mobile: boolean) => {
      this.mobile = mobile;
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionSession.unsubscribe();
    this._subscriptionConfigurations.unsubscribe();
    this._subscriptionIsMobile.unsubscribe();
  }

  public async changeEmpresaDialog(): Promise<void> {
    await this._cgModalService.showChangeEmpresa();
  }

  public toggleCollapseRecentCompanies(): void {
    this.collapseRecentCompanies = !this.collapseRecentCompanies;
    if (this._session?.userId) {
      const key: string = this._collapseRecentCompaniesKey();
      if (this._subscriptionSetCollapseRecentCompanies) {
        this._subscriptionSetCollapseRecentCompanies.unsubscribe();
      }
      this._subscriptionSetCollapseRecentCompanies = this._cgLocalStorageService.setItem(key, this.collapseRecentCompanies, SCHEMA_BOOLEAN).subscribe();
    }
  }

  public readonly fnChangeCompany = (company: IJsonErpUser) => (): Promise<void> => this._changeCompany(company);

  private _evaluateSession(): void {
    if (!this._session) {
      return;
    }

    this.email = this._session.email.trim();
    this.name = `${capitalize(this._session.firstName)} ${capitalize(this._session.lastName)}`.trim();

    if (!this.mobile) {
      this.recentCompanies = this._session.lasterps.filter((company: IJsonErpUser) => company.ativo && company.nEmpresa !== this._session.erp.nEmpresa).slice(0, MAX_PORTALS);
    }

    if (this._subscriptionGetCollapseRecentCompanies) {
      this._subscriptionGetCollapseRecentCompanies.unsubscribe();
    }

    const key: string = this._collapseRecentCompaniesKey();
    this._subscriptionGetCollapseRecentCompanies = this._cgLocalStorageService.getItem(key, SCHEMA_BOOLEAN).subscribe((collapseRecentCompanies: boolean) => {
      this.collapseRecentCompanies = collapseRecentCompanies;
    });
  }

  private _collapseRecentCompaniesKey(): string {
    return `${this._session.userId}.${KEY_COLLAPSE_RECENT_COMPANIES}`;
  }

  private async _changeCompany(company: IJsonErpUser): Promise<void> {
    const promise: Promise<unknown> = this._authService.changeEmpresa({cgId: company.cgID, nEmpresa: company.nEmpresa});
    this._appService.setGlobalLoading(promise);
    await promise;
  }
}
