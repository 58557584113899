import {HttpResponse} from '@angular/common/http';
import {Component, Injector, OnInit} from '@angular/core';
import {EMonth, IPlDynamicVisualsSecondaryClickAction, IPlToolbarItem, isEmpty, isObject} from 'pl-comps-angular';
import {EEstadoRetencao, EOrigemContasRetencoes, ETipoVisualizacao, IRadioItemRet, IRetencoesStateParams} from '../retencoes.module.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE} from '../../docscontabilidade/docsContabilidade.interface';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {ETipoRetServNIF} from '../../../../entities/nifs/jsonNifs.entity.interface';
import {IJsonRetencoes, IJsonRetencoesMain} from '../jsonRetencoes.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {RetencoesService} from '../retencoes.module.service';
import {TDate} from '../../../../../common/dates';
import moment from 'moment';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnContextMenuPreparing,
  IDevExpressDataGridEventOnInitialized
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {DevExpressDataGridUIService} from '../../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';

@Component({
  selector: 'module-retencoes',
  templateUrl: './retencoes.module.component.html'
})
export class RetencoesModuleComponent extends ModuloComponent implements OnInit {
  public readonly tipoVisualizacaoSource: Array<IRadioItemRet>;
  public readonly estadoRetSource: Array<IRadioItemRet>;
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly dataGridDefinitionDetail: IDevExpressDataGrid;

  public tipoVisualizacao: ETipoVisualizacao;
  public tipRetServNif: ETipoRetServNIF;
  public estadoRet: EEstadoRetencao;
  public origemContas: EOrigemContasRetencoes;
  public nifDe: string;
  public nifAte: string;
  public dataDe: TDate;
  public dataAte: TDate;
  public isRetencoesEfetuadas: boolean;
  public doPesquisar: boolean;

  private readonly _maintenanceInstanceDocsContab: IEntityMaintenanceInstance;
  private readonly _btnSearch: IPlToolbarItem;
  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _retencoesService: RetencoesService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService
  ) {
    super(_injector);
    const params: IRetencoesStateParams = <IRetencoesStateParams>this._transition.params();
    this._maintenanceInstanceDocsContab = this._entityMaintenanceService.build(ENTITY_NAME_DOCS_CONTABILIDADE);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'isContaPocOrigemFornecedor', dataType: 'string', caption: 'retencoes.table.fields.isContaPocOrigemFornecedor'},
        {dataField: 'nContribuinte', dataType: 'string', caption: 'retencoes.table.fields.nContribuinte'},
        {dataField: 'conta', dataType: 'string', caption: 'retencoes.table.fields.conta', width: 200},
        {dataField: 'codRetencao', dataType: 'string', caption: 'retencoes.table.fields.codRetencao', width: 100},
        {dataField: 'taxaRetUsada', dataType: 'double', caption: 'retencoes.table.fields.taxaRetUsada'},
        {dataField: 'tipoRendimento', dataType: 'string', caption: 'retencoes.table.fields.tipoRendimento', width: 200},
        {dataField: 'periodo', dataType: 'string', caption: 'retencoes.table.fields.periodo'},
        {dataField: 'nDiario', dataType: 'number', caption: 'retencoes.table.fields.nDiario'},
        {dataField: 'nDocInterno', dataType: 'string', caption: 'retencoes.table.fields.nDocInterno'},
        {dataField: 'descricaoDC', dataType: 'string', caption: 'retencoes.table.fields.descricaoDC'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'retencoes.table.fields.dataDoc'},
        {dataField: 'nDocExterno', dataType: 'string', caption: 'retencoes.table.fields.nDocExterno'},
        {dataField: 'valorOrigem', dataType: 'double', caption: 'retencoes.table.fields.valorOrigem'},
        {dataField: 'valorDestino', dataType: 'double', caption: 'retencoes.table.fields.valorDestino'},
        {dataField: 'valorDestDispo', dataType: 'double', caption: 'retencoes.table.fields.valorDestDispo'}
      ],
      dataSource: [],
      export: {filename: 'global.menu.retencoes'},
      height: '60vh',
      masterDetail: {enabled: true, template: 'templateMasterDetail'},
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      scrolling: {rowRenderingMode: 'virtual', columnRenderingMode: 'virtual'},
      summary: {
        totalItems: [
          {column: 'valorOrigem', valueFormat: 'double', displayFormat: '{0}', summaryType: 'sum'},
          {column: 'valorDestino', valueFormat: 'double', displayFormat: '{0}', summaryType: 'sum'},
          {column: 'valorDestDispo', valueFormat: 'double', displayFormat: '{0}', summaryType: 'sum'}
        ]
      },
      remoteOperations: false
    };
    this.dataGridDefinitionDetail = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'isContaPocOrigemFornecedor', dataType: 'string', caption: 'retencoes.table.fields.isContaPocOrigemFornecedor'},
        {dataField: 'nContribuinte', dataType: 'string', caption: 'retencoes.table.fields.nContribuinte'},
        {dataField: 'conta', dataType: 'string', caption: 'retencoes.table.fields.conta', width: 200},
        {dataField: 'periodo', dataType: 'string', caption: 'retencoes.table.fields.periodo'},
        {dataField: 'nDiario', dataType: 'number', caption: 'retencoes.table.fields.nDiario'},
        {dataField: 'nDocInterno', dataType: 'string', caption: 'retencoes.table.fields.nDocInterno'},
        {dataField: 'descricaoDC', dataType: 'string', caption: 'retencoes.table.fields.descricaoDC'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'retencoes.table.fields.dataDoc'},
        {dataField: 'nDocExterno', dataType: 'string', caption: 'retencoes.table.fields.nDocExterno'},
        {dataField: 'valorOrigem', dataType: 'double', caption: 'retencoes.table.fields.valorOrigem'},
        {dataField: 'valorDestino', dataType: 'double', caption: 'retencoes.table.fields.valorDestino'},
        {dataField: 'valorDestDispo', dataType: 'double', caption: 'retencoes.table.fields.valorDestDispo'}
      ],
      dataSource: [],
      export: {filename: 'global.menu.retencoes'},
      remoteOperations: false
    };
    this.tipoVisualizacaoSource = [
      {
        value: ETipoVisualizacao.RetEfeutadas,
        label: 'retencoes.header.radio.retEfeutadas'
      },
      {
        value: ETipoVisualizacao.DocsSujeitosRet,
        label: 'retencoes.header.radio.docsSujeitosRet'
      }
    ];
    this.estadoRetSource = [
      {
        value: EEstadoRetencao.SujeitoRetencao,
        label: 'retencoes.header.radio.sujeitoRetencao'
      },
      {
        value: EEstadoRetencao.Retido,
        label: 'retencoes.header.radio.retido'
      },
      {
        value: EEstadoRetencao.Ambos,
        label: 'retencoes.header.radio.ambos'
      }
    ];
    this.tipoVisualizacao = ETipoVisualizacao.DocsSujeitosRet;
    this.doPesquisar = !isEmpty(params.nif);
    this.nifDe = !isEmpty(params.nif) ? params.nif : '';
    this.nifAte = !isEmpty(params.nif) ? params.nif : 'ZZZZ';
    this.dataDe = moment().year(this._configService.configurations.empresa.anoEmCursoIRC).month(EMonth.January).startOf('month');
    this.dataAte = moment().year(this._configService.configurations.empresa.anoEmCursoIRC).month(EMonth.December).endOf('month');
    this.tipRetServNif = ETipoRetServNIF.PrestadorServicos;
    this.estadoRet = EEstadoRetencao.Ambos;
    this.origemContas = EOrigemContasRetencoes.Ambos;
    this._btnSearch = {
      id: 'search',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-search"></i>',
      class: 'btn-primary',
      caption: 'global.btn.search',
      click: () => this._pesquisar()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnSearch);
    this.isRetencoesEfetuadas = false;
    if (this.doPesquisar) {
      this._pesquisar();
    }
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public onContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IJsonRetencoes | IJsonRetencoesMain>): void {
    if (event.target === 'content' && event.row.rowType === 'data' && isObject(event.row.data)) {
      event.event.preventDefault();
      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActionsGroup(event.row.data.extPocCabID);
      this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions);
    }
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IJsonRetencoes | IJsonRetencoesMain>): void {
    devExpressDataGridExpandDetailHandler(event);
  }

  private _pesquisar(): Promise<void> {
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    let promise: Promise<void>;
    this._btnSearch.promise = promise;
    this.nifAte = isEmpty(this.nifDe) ? 'ZZZZ' : this.nifDe;
    promise = this._retencoesService
      .getRetencoes(this.tipoVisualizacao, this.nifDe, this.nifAte, this.dataDe, this.dataAte, this.tipRetServNif, this.estadoRet, this.origemContas)
      .then((response: HttpResponse<Array<IJsonRetencoes>>) => {
        this.isRetencoesEfetuadas = this.tipoVisualizacao === ETipoVisualizacao.RetEfeutadas;
        this.dataGridDefinition.dataSource = response.body;
        this.dataGridDefinition.masterDetail.enabled = this.isRetencoesEfetuadas;
      })
      .finally(() => {
        if (this._dataGridInstance) {
          this._dataGridInstance.endCustomLoading();
        }
        promise = undefined;
      });
    return promise;
  }

  private _generateSecondaryClickActionsGroup(extPocCabID: string): Array<IPlDynamicVisualsSecondaryClickAction> {
    return [
      {
        caption: 'retencoes.doc',
        icon: 'fa-file-o',
        click: () => this._openDocAsModal(extPocCabID)
      }
    ];
  }

  private _openDocAsModal(extPocCabID: string): Promise<void> {
    return this._maintenanceInstanceDocsContab.maintenanceEdit(extPocCabID).then(() => undefined);
  }
}
