import {merge} from 'lodash-es';
import {asyncScheduler} from 'rxjs';
import {AfterViewInit, Component, Injector, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ValidatorFn} from '@angular/forms';
import {EMouseEventButton} from '../../../common/constants';
import {isBoolean, isDefinedNotNull, isEmpty, isFunction, isUndefinedOrNull} from '../../../common/utilities/utilities';
import {IPlEditComponentOptionsInputCheckbox} from './edit.checkbox.component.interface';
import {PlEditInputTypeComponent} from '../../generic/input/edit.input.type.component';

const KLASS_EVALUATE_TRUE = 'pl-checkbox-checked';
const KLASS_EVALUATE_FALSE = 'pl-checkbox-unchecked';
const KLASS_EVALUATE_INDETERMINATE = 'pl-checkbox-indeterminate';
let UNIQUE_ID = 0;

@Component({
  selector: 'pl-edit-checkbox',
  templateUrl: './edit.checkbox.component.html'
})
export class PlEditCheckboxComponent extends PlEditInputTypeComponent<boolean | any, IPlEditComponentOptionsInputCheckbox> implements OnInit, OnChanges, AfterViewInit {
  @Input() public label: string;
  @Input() public trueValue: any;
  @Input() public falseValue: any;
  @Input() public indeterminate: boolean;

  public readonly uniqueId: number;
  public evaluatedStatus: string;
  public evaluatedLabel: string;

  private _trueValue: unknown;
  private _falseValue: unknown;
  private _evaluatedIndeterminate: boolean;

  constructor(protected readonly _injector: Injector) {
    super('checkbox', _injector);
    this.uniqueId = ++UNIQUE_ID;
    this._defaultOptions = Object.freeze<IPlEditComponentOptionsInputCheckbox>(
      merge({}, this._defaultOptions, {
        trueValue: true,
        falseValue: false,
        indeterminate: false
      })
    );
    this._trueValue = this._defaultOptions.trueValue;
    this._falseValue = this._defaultOptions.falseValue;
    this._evaluatedIndeterminate = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._handleChanges();
    this.setValidators(this._requiredValidator());
    this.value = Boolean(this.value);
    this._setBeforeChange();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const {label, trueValue, falseValue, indeterminate} = changes;
    if (label && !label.isFirstChange()) {
      this._changedLabel(label.currentValue);
      this._evaluateStatus();
    }
    if (trueValue && !trueValue.isFirstChange()) {
      this._changedTrueValue(trueValue.currentValue);
      this._evaluateStatus();
    }
    if (falseValue && !falseValue.isFirstChange()) {
      this._changedFalseValue(falseValue.currentValue);
      this._evaluateStatus();
    }
    if (indeterminate && !indeterminate.isFirstChange()) {
      this._changedIndeterminate(indeterminate.currentValue);
      this._evaluateStatus();
    }
  }

  public ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this._setValue(this.value);
    this._evaluateStatus();
  }

  public updateComponent(properties: IPlEditComponentOptionsInputCheckbox): void {
    super.updateComponent(properties);
    this._handleChanges();
    this._setBeforeChange();
    this._evaluateStatus();
  }

  public updateValue(value: boolean | any): void {
    if (isDefinedNotNull(value)) {
      value = Boolean(value);
    }
    super.updateValue(value);
    this._setValue(value);
    this._evaluateStatus();
  }

  public async render(value: boolean | any): Promise<void> {
    if (this.options.disabled || this.options.readonly) {
      return;
    }
    this.formControl.updateValueAndValidity();
    if (!this.formControl.invalid) {
      value = isUndefinedOrNull(value) ? undefined : value ? this._trueValue : this._falseValue;
    }
    await super.render(value);
    asyncScheduler.schedule(() => {
      this._setValue(value);
      this._evaluateStatus();
    });
  }

  public onClick(event: MouseEvent): void {
    if (isFunction(this.options.events?.click)) {
      this.options.events.click(this.value, event);
    }
    if (event.button !== EMouseEventButton.Main) {
      return;
    }
    event.preventDefault();
    this.toggleValue();
  }

  public toggleValue(): Promise<unknown> {
    if (this.options.disabled || this.options.readonly) {
      return Promise.resolve();
    }
    const newValue = !this.value;
    return this.render(newValue);
  }

  public onKeydownEnter(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.toggleValue();
  }

  private _handleChanges(): void {
    this._changedLabel();
    this._changedTrueValue();
    this._changedFalseValue();
    this._changedIndeterminate();
  }

  private _changedLabel(value: string = this.label): void {
    this.evaluatedLabel = value || this.options.label || '';
    if (this.evaluatedLabel) {
      this.evaluatedLabel = this._plTranslateService.translate(this.evaluatedLabel);
    }
  }

  private _changedTrueValue(value: unknown = this.trueValue): void {
    let val: unknown = value;
    if (isEmpty(val)) {
      val = this.options.trueValue;
    }
    if (isEmpty(val)) {
      val = true;
    }
    this._trueValue = val;
  }

  private _changedFalseValue(value: unknown = this.falseValue): void {
    let val: unknown = value;
    if (isEmpty(val)) {
      val = this.options.falseValue;
    }
    if (isEmpty(val)) {
      val = false;
    }
    this._falseValue = val;
  }

  private _changedIndeterminate(value: boolean = this.indeterminate): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.indeterminate;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this._evaluatedIndeterminate = val;
  }

  private _requiredValidator(): ValidatorFn {
    return () => {
      if (this.validate && this.options.validators.required?.value === true) {
        return isDefinedNotNull(this.value) ? undefined : {required: true};
      }
      return undefined;
    };
  }

  private _setValue(checked: unknown): void {
    this.value = Boolean(checked);
    if (this._inputField) {
      this._renderer.setProperty(this._inputField, 'checked', this.value);
    }
  }

  private _setBeforeChange(): void {
    if (isFunction(this.options.events.beforeChange)) {
      const originalFn = this.options.events.beforeChange;
      this.options.events.beforeChange = (): Promise<void | boolean> => {
        const value = this.value ? this._trueValue : this._falseValue;
        return Promise.resolve(originalFn(value, this.model));
      };
    }
  }

  private _evaluateStatus(): void {
    const cssClass: Array<string> = [];
    if (this._evaluatedIndeterminate) {
      cssClass.push(KLASS_EVALUATE_INDETERMINATE);
    }
    if (this.value === true) {
      cssClass.push(KLASS_EVALUATE_TRUE);
    } else {
      cssClass.push(KLASS_EVALUATE_FALSE);
    }
    this.evaluatedStatus = cssClass.join(' ');
    if (this.inputField) {
      this.inputField.indeterminate = this._evaluatedIndeterminate;
    }
  }
}
