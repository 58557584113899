<ng-container *ngIf="isBlocked">
  <div class="blocked-by-other-user-container">
    <div class="block-inline">
      <i class="fa fa-lock" aria-hidden="true"></i>
    </div>
    <div class="block-inline">
      <h5 [translate]="'importadorSaftDirect.messages.importInProcessModalMessageByOtherUser'"></h5>
      <div class="blocked-by-username">{{ currentStatus.userStarted }}</div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!isBlocked">
  <pl-nav-wizard [definition]="definitionNavWizard" [onFinalize]="fnFinalize" [properties]="propertiesNavWizard" [callback]="plWizardCallback">
    <pl-nav-wizard-step icon="fa-home" caption="integracaoSalariosMulti.tabIntroCaption" [id]="integracaoSalariosMultiSteps.INTRO">
      <div *plNavWizardStepContent>
        <h4 [translate]="'integracaoSalariosMulti.tabIntroTitle'"></h4>
        <p [translate]="'integracaoSalariosMulti.tabIntroSubTitle'"></p>
        <p [translate]="'integracaoSalariosMulti.tabIntroSubTitle2'"></p>
        <p [translate]="'integracaoSalariosMulti.tabIntroSubTitle3'"></p>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-th-list" caption="integracaoSalariosMulti.tabEmpresasCaption" [id]="integracaoSalariosMultiSteps.EMPRESAS_SEL" [validator]="fnValidateStepEmpresasSel">
      <div *plNavWizardStepContent>
        <div class="row">
          <div class="col-md-12">
            <h4 class="mainTitle" [translate]="'integracaoSalariosMulti.selMesIntegrarTitle'"></h4>
          </div>
        </div>

        <div class="row">
          <div class="col-md-2">
            <pl-group>
              <label [translate]="'integracaoSalariosMulti.fields.ano'"></label>
              <edit>
                <pl-select [clearable]="false" [(model)]="selYear" [source]="yearsSource"></pl-select>
              </edit>
            </pl-group>
          </div>

          <div class="col-md-2">
            <pl-group>
              <label [translate]="'integracaoSalariosMulti.fields.mes'"></label>
              <edit>
                <pl-edit type="meses" [(model)]="selMonth"></pl-edit>
              </edit>
            </pl-group>
          </div>
        </div>

        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinitionEmpresas"
          cgDxDataGridInstanceName="empresasTable"
          [dataSource]="dataGridDefinitionEmpresas.dataSource"
          [(selectedRowKeys)]="selectedRowKeysEmpresas"
          (onInitialized)="onInitializedEmpresas($event)">
          <div *dxTemplate="let item of 'cellTemplateBtnEmpresas'">
            <button type="button" [ngClass]="{'btn-light': item.data.configuracoes, 'btn-warning': !item.data.configuracoes}" class="btn btn-xs" (click)="showConfigModal(item.data)">
              <i class="fa fa-fw" [ngClass]="{'fa-cog': item.data.configuracoes, 'fa-exclamation-triangle': !item.data.configuracoes}"></i>&nbsp;<span
                [translate]="'integracaoSalariosMulti.configLabel'"></span>
            </button>
          </div>
        </dx-data-grid>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-bolt" caption="integracaoSalariosMulti.tabProcCaption" [id]="integracaoSalariosMultiSteps.PROC">
      <div *plNavWizardStepContent class="proc-container">
        <div class="progress">
          <div
            class="progress-bar bg-info progress-bar-striped progress-bar-animated"
            role="progressbar"
            [attr.aria-valuenow]="pbProcPos"
            [attr.aria-valuemin]="0"
            [attr.aria-valuemax]="100"
            [style.width.%]="pbProcPos"></div>
        </div>
        <div class="procLabel" [translate]="pbProcLabel"></div>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-flag-checkered" caption="integracaoSalariosMulti.tabResultsCaption" [id]="integracaoSalariosMultiSteps.PROC_RESULTS">
      <div *plNavWizardStepContent>
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinitionProcResults"
          cgDxDataGridInstanceName="procResults"
          [dataSource]="dataGridDefinitionProcResults.dataSource"
          (onInitialized)="onInitializedProcResults($event)"
          (onCellClick)="onCellClick($event)">
          <div *dxTemplate="let item of 'cellTemplateProcResults'">
            <i [ngClass]="item.data.icon" aria-hidden="true"></i>
          </div>
          <div *dxTemplate="let item of 'masterDetailProcResults'">
            <dx-data-grid [cgDxDataGrid]="dataGridDefinitionProcResultsMessages" [dataSource]="item.data.messages">
              <div *dxTemplate="let item of 'cellTemplateProcResultsMessages'">
                <i [ngClass]="item.data.icon" aria-hidden="true"></i>
              </div>
            </dx-data-grid>
          </div>
        </dx-data-grid>
      </div>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</ng-container>
