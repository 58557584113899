<div class="doccontabilidade-totais">
  <div class="row">
    <div class="col-sm-12 col-lg-4">
      <div *ngIf="selectedAccount && selectedAccount.nConta">
        <div class="doccontabilidade-totais-conta-nome">
          <div>{{ selectedAccount.nConta + ' - ' + selectedAccount.poc.nome }}</div>
          <div *ngIf="selectedAccount._imputaDocPrincipal && selectedAccount._extPocCabImput !== '' && selectedAccount._showBtnImputacao">
            <div>
              <span [translate]="'docscontabilidade.text.docImputacao'" [translateParams]="{ndocImput: selectedAccount._nDocImput}"></span>
            </div>
            <div *ngIf="showOpenDocButton">
              <pl-button klass="btn-primary btn-sm" (evtClicked)="openDocImputacao(selectedAccount._extPocCabImput)">
                <i class="fa fa-eye" aria-hidden="true"></i>&nbsp;<span [translate]="'conciliacaoBancaria.viewDocTooltip'"></span>
              </pl-button>
            </div>
          </div>
          <div *ngIf="selectedAccount.saldoContaCalculado">
            <span [translate]="'docscontabilidade.text.saldoConta'" [translateParams]="{saldoConta: selectedAccount.saldoConta | cgcDecimal: config.valor}"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-lg-8">
      <div class="doccontabilidade-totais-table">
        <div class="table-responsive">
          <table class="table table-sm table-docscontabilidade-totais">
            <thead>
              <tr>
                <th></th>
                <th class="doccontabilidade-totais-debito" [translate]="'docscontabilidade.doc.linhas.dcDebito'"></th>
                <th class="doccontabilidade-totais-credito" [translate]="'docscontabilidade.doc.linhas.dcCredito'"></th>
                <th class="doccontabilidade-totais-saldo" [translate]="'docscontabilidade.doc.totais.saldo'"></th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td [translate]="'docscontabilidade.doc.totais.baseTributavel'"></td>
                <td class="doccontabilidade-totais-total-debito-base-tributavel">{{ doccontabilidade.totalDebitoBaseTributavel | cgcDecimal: config.valor }}</td>
                <td class="doccontabilidade-totais-total-credito-base-tributavel">{{ doccontabilidade.totalCredidoBaseTributavel | cgcDecimal: config.valor }}</td>
                <td class="doccontabilidade-totais-empty"></td>
              </tr>

              <tr>
                <td [translate]="'docscontabilidade.doc.totais.totalIVA'"></td>
                <td class="doccontabilidade-totais-total-debito-iva">{{ doccontabilidade.totalDebitoIva | cgcDecimal: config.valor }}</td>
                <td class="doccontabilidade-totais-total-credito-iva">{{ doccontabilidade.totalCredidoIva | cgcDecimal: config.valor }}</td>
                <td class="doccontabilidade-totais-empty"></td>
              </tr>

              <tr *ngIf="isMoedaPresent()">
                <td [translate]="'docscontabilidade.doc.totais.moedaEstrangeira'"></td>
                <td class="doccontabilidade-totais-total-debito-moeda-estrangeira">{{ doccontabilidade.totalDebitoMoedaEstrangeira | cgcDecimal: config.valor }}</td>
                <td class="doccontabilidade-totais-total-credito-moeda-estrangeira">{{ doccontabilidade.totalCreditoMoedaEstrangeira | cgcDecimal: config.valor }}</td>
                <td class="doccontabilidade-totais-total-moeda-estrangeira">
                  <span>{{ doccontabilidade.totalDebitoMoedaEstrangeira - doccontabilidade.totalCreditoMoedaEstrangeira | cgcDecimal: config.valor }}</span>
                </td>
              </tr>

              <tr>
                <td [translate]="'docscontabilidade.doc.totais.totalGeral'"></td>
                <td class="doccontabilidade-totais-total-debito">{{ doccontabilidade.totalDebitoGeral | cgcDecimal: config.valor }}</td>
                <td class="doccontabilidade-totais-total-credito">{{ doccontabilidade.totalCreditoGeral | cgcDecimal: config.valor }}</td>
                <td class="doccontabilidade-totais-total">{{ doccontabilidade.totalDebitoGeral - doccontabilidade.totalCreditoGeral | cgcDecimal: config.valor }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
