<div class="concelhos-derrama-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'concelhos.modal.title'"></h5>
  </div>

  <div class="modal-body text-nowrap" [plPromise]="promise">
    <label class="d-flex" [translate]="'modelo22.modal.derrama.ano'" [translateParams]="{ano: dadosDerrama.ano}"></label>
    <label class="d-flex" [translate]="'modelo22.modal.derrama.concelho'" [translateParams]="{cod: dadosDerrama.codConcelho, name: dadosDerrama.nomeConcelho}"></label>

    <hr />

    <div class="d-flex">
      <pl-button klass="btn-warning btn-sm action-edit-dados ms-auto" (evtClicked)="editDerrama()">
        <i class="fa fa-fw fa-pencil"></i>&nbsp;<span [translate]="'concelhos.modal.editardados'"></span>
      </pl-button>
    </div>

    <dx-data-grid [cgDxDataGrid]="definitionDadosIntrod" [dataSource]="dadosDerrama.dadosIntroduzidosList" (onInitialized)="onDataGridInitializedDadosIntrod($event)"> </dx-data-grid>

    <hr />

    <dx-data-grid [cgDxDataGrid]="definitionCondicoes" [dataSource]="dadosDerrama.condicoesTaxaList" (onInitialized)="onDataGridInitializedCondicoes($event)"> </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
  </div>
</div>
