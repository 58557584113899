import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {
  downloadStream,
  ICGTableOnSelect,
  IPlNavWizardCallback,
  IPlNavWizardDefinition,
  IPlNavWizardEventValidator,
  IPlNavWizardOptions,
  IPlTableCallback,
  IPlTableDefinition,
  IPlTableOptions,
  IPlTabsCallback,
  isDefined,
  isDefinedNotNull,
  isEmpty,
  isFunction,
  PlAlertService,
  TPlTableItem
} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {
  ERHProceDMRATStatus,
  ERHProceDMRATSteps,
  IRHProceDMRATDataItem,
  IRHProceDMRATDMRATList,
  IRHProceDMRATErroItem,
  IRHProceDMRATItem,
  IRHProceDMRATModel,
  IRHProceDMRATProcessExistentesItem,
  IRHProceDMRATProcessExistentesParams,
  IRHProceDMRATReProcItem,
  IRHProceDMRATStatus
} from '../proceDMRAT.module.interface';
import {HttpResponse} from '@angular/common/http';
import {IJsonErpUser, IJsonUserRole} from '../../../../services/account/jsonUserApi.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import moment from 'moment';
import {ProceDMRATPrintModalComponent} from '../modals/print/proceDMRAT.print.modal.component';
import {ProceDMRATService} from '../proceDMRAT.module.service';
import {ProceDMRATViewInfoComponent} from '../modals/viewinfo/proceDMRAT.viewInfo.modal.component';
import {ROLE} from '../../../../services/role.const';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnCellPrepared, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import {orderBy} from 'lodash-es';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {MODULE_NAME_RH_GESTAO_FICHEIROS_DMR_AT} from '../../../portalrh/rhgestaoficheirosdmrat/rhGestaoFicheirosDMRAT.module.interface';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';

const INTERVAL_TIMEOUT = 2000;

const STEP_INTRO_INDEX = 0;
const STEP_EMPRESAS_SEL_INDEX = 1;
const STEP_PROC_CONFIG_INDEX = 2;
const STEP_PROC_INDEX = 3;
const STEP_PROC_RESULTS_INDEX = 4;
const TOTAL_MONTHS = 12;

const TAB_ID_PROC_RESULT = 'proc-results-tab';
const TAB_ID_PROC_RESULT_ERRORS = 'empregados-tab';
const TOOLBAR_INSTANCE_ID_PROC_RESULTS = 'toolbar-proc-results';

@Component({
  selector: 'proceDMRAT',
  templateUrl: './proceDMRAT.module.component.html'
})
export class ProceDMRATComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly dataGridDefinitionProcExistentes: IDevExpressDataGrid<IRHProceDMRATProcessExistentesItem, number>;
  public readonly dataGridDefinitionProcResults: IDevExpressDataGrid<IRHProceDMRATItem, IRHProceDMRATItem>;
  public readonly dataGridDefinitionProcResultsErrors: IDevExpressDataGrid;
  public readonly proceDMRATSteps: typeof ERHProceDMRATSteps;
  public readonly definitionNavWizard: IPlNavWizardDefinition;
  public readonly tabIdRrocResultsErrors: string;
  public readonly tabIdRrocResults: string;
  public readonly msEmpresasTemplate: string;
  public readonly procResultsTabsCallback: IPlTabsCallback;

  public model: IRHProceDMRATModel;
  public propertiesNavWizard: IPlNavWizardOptions;
  public plWizardCallback: IPlNavWizardCallback;
  public empresasSource: Array<IJsonErpUser>;
  public empresasSel: Array<IJsonErpUser>;
  public multiDatasTableDef: IPlTableDefinition;
  public multiDatasTableCallback: IPlTableCallback;
  public multiDatasTableOptions: IPlTableOptions;
  public currentStatus: IRHProceDMRATStatus;
  public selectedRowKeys: Array<number>;
  public toolbarInstIdProcResults: string;
  public orderBySelected: string;
  public pbProcLabel: string;
  public selectedYear: number;
  public vizProcExistentes: boolean;
  public decSubstituicao: boolean;
  public isBlocked: boolean;

  private readonly _multiDatasSourceCache: unknown;
  private readonly _currentMonth: number;
  private readonly _maintenanceRHGestaoFicheirosDMRAT: IModuleMaintenanceInstance;
  private _multiDatasTableSource: Array<IRHProceDMRATDataItem>;
  private _procExistentesTableSource: Array<IRHProceDMRATProcessExistentesItem>;
  private _procResultsTableSource: Array<TPlTableItem<IRHProceDMRATItem>>;
  private _procResultsErrorsTableSource: Array<IRHProceDMRATErroItem>;
  private _dataGridProcExistentesInstance: dxDataGrid;
  private _dataGridProcResultsInstance: dxDataGrid;
  private _dataGridProcErrorsInstance: dxDataGrid;
  private _intervalId: number;
  private _isFirstTime: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _proceDMRATService: ProceDMRATService,
    private readonly _plAlertService: PlAlertService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _cgExceptionService: CGExceptionService
  ) {
    super(_injector);
    this._maintenanceRHGestaoFicheirosDMRAT = this._moduleMaintenanceService.build(MODULE_NAME_RH_GESTAO_FICHEIROS_DMR_AT);
    this.msEmpresasTemplate = '{{nEmpresa}} - {{nomeEmpresa}}';
    this._currentMonth = moment().subtract(1, 'months').month() + 1;
    this.tabIdRrocResults = TAB_ID_PROC_RESULT;
    this.tabIdRrocResultsErrors = TAB_ID_PROC_RESULT_ERRORS;
    this.procResultsTabsCallback = {};
    this.definitionNavWizard = {
      items: []
    };
    this.proceDMRATSteps = ERHProceDMRATSteps;
    this.propertiesNavWizard = {
      disableNavigation: false,
      disablePreviousStep: false,
      disableNextStep: false
    };
    this.plWizardCallback = {};
    this._multiDatasSourceCache = {};
    this._multiDatasTableSource = [];
    this.multiDatasTableCallback = {};
    this.empresasSel = [];
    this.selectedRowKeys = [];
    this._plToolbarService.registerInstance(this.toolbarInstIdProcResults, {
      items: [
        {
          id: 'toolbar-imprimir-map-por-empregado',
          type: 'button',
          class: 'btn-outline-primary',
          iconLeft: '<i class="fa fa-print"></i>&nbsp;',
          caption: 'proceDMRAT.printMapPerEmp',
          click: () => {
            this._printMapa();
          }
        },
        {
          id: 'empregados-gerar-ficheiro-dri',
          type: 'button',
          class: 'btn-outline-primary',
          iconLeft: '<i class="fa fa-bolt"></i>&nbsp;',
          caption: 'proceDMRAT.genDRIFile',
          click: () => this._genDRIFile()
        },
        {
          id: 'empregados-enviar-ficheiro',
          type: 'button',
          class: 'btn-outline-primary',
          iconLeft: '<i class="fa fa-upload"></i>&nbsp;',
          caption: 'proceDMRAT.sendFile',
          click: () => this._sendFile()
        },
        {
          id: 'empregados-consultar-envio',
          type: 'button',
          class: 'btn-outline-primary',
          visible: false,
          iconLeft: '<i class="fa fa-bars"></i>&nbsp;',
          caption: 'proceDMRAT.viewSends'
          // click: () => this._viewSends()
        }
      ]
    });
    this.multiDatasTableDef = {
      fields: [
        {name: 'selected', caption: 'proceDMRAT.fields.selected', type: 'boolean'},
        {name: 'descricao', caption: 'proceDMRAT.fields.mesAno'}
      ]
    };
    this.multiDatasTableOptions = {perPage: 12, suppressEmptyLines: true, hidePagination: true};
    this._procExistentesTableSource = [];
    this.dataGridDefinitionProcExistentes = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'tipoProcessamento', dataType: 'string', caption: 'proceDMRAT.fields.tipoProcessamento'},
        {dataField: 'tipoProcessamentoDescricao', dataType: 'string', caption: 'proceDMRAT.fields.tipoProcessamentoDescricao'},
        {dataField: 'nProcessamento', dataType: 'string', caption: 'proceDMRAT.fields.nProcessamento'},
        {dataField: 'dataProcessamento', dataType: 'date', caption: 'proceDMRAT.fields.dataProcessamento'},
        {dataField: 'empresa', dataType: 'string', caption: 'proceDMRAT.fields.empresa'},
        {dataField: 'nomeEmpresa', dataType: 'string', caption: 'proceDMRAT.fields.nomeEmpresa'},
        {dataField: 'dmrAno', dataType: 'string', caption: 'proceDMRAT.fields.dmrAno'},
        {dataField: 'dmrMes', dataType: 'string', caption: 'proceDMRAT.fields.dmrMes'}
      ],
      dataSource: new CustomStore({
        key: '_index',
        load: () => this._getProcExistentesTableSource()
      }),
      export: {filename: 'proceDMRAT.tabConfigCaption'},
      selection: {mode: 'multiple', selectAllMode: 'allPages', showCheckBoxesMode: 'always'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplateProcExistentes',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.dataGridDefinitionProcResults = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'statusImage', dataType: 'string', caption: 'proceDMRAT.fields.statusImage', alignment: 'center', cellTemplate: 'cellTemplateStatusImage'},
        {dataField: 'ssNEMPRESA', dataType: 'string', caption: 'proceDMRAT.fields.empresa'},
        {dataField: 'ssNOMEEMPRESA', dataType: 'string', caption: 'proceDMRAT.fields.nomeEmpresa'},
        {dataField: 'ano', dataType: 'number', caption: 'proceDMRAT.fields.dmrAno'},
        {dataField: 'mes', dataType: 'number', caption: 'proceDMRAT.fields.dmrMes'},
        {dataField: 'totalValorRendimentos', dataType: 'double', caption: 'proceDMRAT.fields.totalValorRendimentos'},
        {dataField: 'totalValorRetencaoIRS', dataType: 'double', caption: 'proceDMRAT.fields.totalValorRetencaoIRS'},
        {dataField: 'totalValorContribuicoesObrigatorias', dataType: 'double', caption: 'proceDMRAT.fields.totalValorContribuicoesObrigatorias'},
        {dataField: 'totalValorQuotizacaoSindicais', dataType: 'double', caption: 'proceDMRAT.fields.totalValorQuotizacaoSindicais'},
        {dataField: 'totalValorRetencaoSobretaxa', dataType: 'double', caption: 'proceDMRAT.fields.totalValorRetencaoSobretaxa'},
        {dataField: 'totalValores', dataType: 'double', caption: 'proceDMRAT.fields.totalValores'},
        {dataField: 'wsEstadoFicheiroStr', dataType: 'string', caption: 'proceDMRAT.fields.wsEstadoFicheiroStr'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnProcResults'}
      ],
      dataSource: new CustomStore({
        load: () => this._getProcResultsTableSource()
      }),
      export: {filename: 'proceDMRAT.tabResultsCaption'},
      masterDetail: {enabled: true, autoExpandAll: false, template: 'masterDetailProcResults'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplateProcResults',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.dataGridDefinitionProcResultsErrors = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nEmpresa', dataType: 'string', caption: 'proceDMRAT.fields.empresa'},
        {dataField: 'erro', dataType: 'string', caption: 'proceDMRAT.fields.erro'},
        {dataField: 'queFazer', dataType: 'string', caption: 'proceDMRAT.fields.queFazer'}
      ],
      dataSource: new CustomStore({
        load: () => this._getProcResultsErrorsTableSource()
      }),
      export: {filename: 'proceDMRAT.erros'},
      remoteOperations: false
    };
    this._init();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._loadEmpresas();
    this._isFirstTime = true;
    this._proceDMRATService.getJobStatus().then((response) => {
      this.currentStatus = response;
      this._init();
      if (response.state === ERHProceDMRATStatus.Timeout) {
        this._showTimeoutModal();
        this._resetWizard();
      } else if (response.state === ERHProceDMRATStatus.Error) {
        this._handleStateError(response);
        this._resetWizard();
      } else if (response.state === ERHProceDMRATStatus.Ended) {
        this._cgModalService
          .showOkCancel('proceDMRAT.promptViewResultsTitle', 'proceDMRAT.promptViewResultsMessage', {
            size: 'md',
            btnOkText: 'proceDMRAT.viewResultBtn',
            btnCancelText: 'proceDMRAT.initNewProc',
            btnOkIcon: 'fa-eye',
            btnCancelIcon: 'fa-home',
            backdrop: 'static',
            showCloseBtn: false,
            keyboard: false
          })
          .then(() => {
            this._showResults();
          })
          .catch(() => {
            this._resetWizard();
            this._init();
          });
      } else if (response.state === ERHProceDMRATStatus.Started) {
        this.isBlocked = response.userStartedId !== this.session.userId;
        if (!this.isBlocked) {
          this._setWizActiveStep(ERHProceDMRATSteps.PROC, true);
          this._startProcessChecker(true);
        }
      }
    });
    this.orderBySelected = this._translateService.instant('proceDMRAT.btn.dropdown.sortEmpresasByNEmpresa');
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    clearInterval(this._intervalId);
    this._plToolbarService.unRegisterInstance(this.toolbarInstIdProcResults);
    this._proceDMRATService.stopJob();
  }

  public setInstanceName(value: string): void {
    super.setInstanceName(value);
    this.toolbarInstIdProcResults = `${this.instanceName}-${TOOLBAR_INSTANCE_ID_PROC_RESULTS}`;
  }

  public onMultiYearChange(year: number): void {
    this.selectedYear = year;
    this._invalidateSteps(ERHProceDMRATSteps.PROC_CONFIG, 'forward');
    this.definitionNavWizard.items[STEP_PROC_CONFIG_INDEX].setIncomplete();
    if (isFunction(this.multiDatasTableCallback.refresh)) {
      this.multiDatasTableCallback.refresh();
    }
  }

  public onDecYearClick(): void {
    this.selectedYear -= 1;
    if (isFunction(this.multiDatasTableCallback.refresh)) {
      this.multiDatasTableCallback.refresh();
    }
  }

  public onIncYearClick(): void {
    this.selectedYear += 1;
    if (isFunction(this.multiDatasTableCallback.refresh)) {
      this.multiDatasTableCallback.refresh();
    }
  }

  public empresasChanged(): void {
    this._invalidateSteps(ERHProceDMRATSteps.EMPRESAS_SEL, 'forward');
    this.definitionNavWizard.items[STEP_EMPRESAS_SEL_INDEX].setIncomplete();
  }

  public onVizProcExistentesChange(value: boolean): void {
    this.vizProcExistentes = value;
    if (this.vizProcExistentes) {
      this._loadProceExistentes();
    } else {
      this._procExistentesTableSource = [];
      this._refreshProceExistentesTable();
    }
  }

  public dmratViewInfoClick(item: IRHProceDMRATItem): void {
    const modalRef = this._cgModalService.showVanilla(ProceDMRATViewInfoComponent, {size: 'xxl'});
    const componentInstance: ProceDMRATViewInfoComponent = modalRef.componentInstance;
    componentInstance.nEmpresa = item.ssNEMPRESA;
    componentInstance.ano = item.ano;
    componentInstance.mes = item.mes;
  }

  public onSelectMultiDatasTable(data: ICGTableOnSelect<IRHProceDMRATDataItem>): void {
    for (const item of this._multiDatasTableSource) {
      item.selected = false;
    }
    data.item.selected = !data.item.selected;
    if (!data.item.selected) {
      if (this.vizProcExistentes) {
        this._procExistentesTableSource = this._procExistentesTableSource.filter((item: IRHProceDMRATProcessExistentesItem) => item.dmrMes !== data.item.mes);
        this._refreshProceExistentesTable();
      }
    } else if (this.vizProcExistentes) {
      this.multiDatasTableCallback.refresh();
      this._loadProceExistentes();
      return;
    }
    this._invalidateSteps(ERHProceDMRATSteps.PROC_CONFIG, 'forward');
    this.definitionNavWizard.items[STEP_PROC_CONFIG_INDEX].setIncomplete();
    this.multiDatasTableCallback.refresh();
  }

  public onSelectProcExistentes(): void {
    this._invalidateSteps(ERHProceDMRATSteps.PROC_CONFIG, 'forward');
    this.definitionNavWizard.items[STEP_PROC_CONFIG_INDEX].setIncomplete();
  }

  public onInitializedProcExistentes({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridProcExistentesInstance = component;
  }

  public onInitializedProcResults({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridProcResultsInstance = component;
  }

  public onCellPreparedProcResults(event: IDevExpressDataGridEventOnCellPrepared<IRHProceDMRATItem>): void {
    if (event.rowType === 'data') {
      if (event.column.command === 'expand' && isEmpty(event.data.wsMensagemErro)) {
        (<HTMLElement>event.cellElement.childNodes[0]).classList.remove('dx-datagrid-group-closed');
        event.cellElement.classList.remove('dx-datagrid-expand');
      }
      if (event.column.dataField === 'wsEstadoFicheiroStr') {
        if (event.data.erros !== 0) {
          event.cellElement.classList.add('text-danger');
        }
      }
    }
  }

  public onInitializedProcResultsErrors({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridProcErrorsInstance = component;
  }

  public sortEmpresasByNEmpresa(): void {
    this.empresasSource = orderBy(this.empresasSource, ['nEmpresa', 'asc']);
    this.empresasSel = orderBy(this.empresasSel, ['nEmpresa', 'asc']);
    this.orderBySelected = this._translateService.instant('proceDMRAT.btn.dropdown.sortEmpresasByNEmpresa');
  }

  public sortEmpresasByName(): void {
    this.empresasSource = orderBy(this.empresasSource, ['nomeEmpresa', 'asc']);
    this.empresasSel = orderBy(this.empresasSel, ['nomeEmpresa', 'asc']);
    this.orderBySelected = this._translateService.instant('proceDMRAT.btn.dropdown.sortEmpresasByName');
  }

  public readonly fnValidateStepEmpresasSel: (event: IPlNavWizardEventValidator) => boolean = () => this._validateStepEmpresasSel();

  public readonly fnValidateStepProcConfig: (event: IPlNavWizardEventValidator) => Promise<void> = () => this._validateStepProcConfig();

  public readonly fnFinalize: () => Promise<void> = () => this._finalize();

  public readonly fnMultiDatasTableSource = (): Array<IRHProceDMRATDataItem> => this._getMultiDatasTableSource();

  protected _onPageUnload(): void {
    super._onPageUnload();
    this._appService.sendBeacon(this._proceDMRATService.stopJobUrl());
  }

  private _init(): void {
    this.model = {
      datas: [],
      empresas: [],
      reProcList: [],
      processaNovamente: false,
      decleracaoSubstiticao: false
    };
    this.decSubstituicao = false;
    this.vizProcExistentes = false;
    this.selectedYear = moment().subtract(1, 'months').year();
    this.pbProcLabel = 'A processar...';
    this._procResultsTableSource = [];
    this._procResultsErrorsTableSource = [];
  }

  private _showTimeoutModal(): Promise<void> {
    return this._cgModalService.showOkCancel('proceDMRAT.jobTimeoutModalTitle', 'proceDMRAT.jobTimeoutModalMessage', {
      size: 'md',
      showCancelBtn: false,
      btnOkText: 'proceDMRAT.comecar',
      backdrop: 'static',
      keyboard: false
    });
  }

  private _handleStateError(state: IRHProceDMRATStatus): void {
    this._cgModalService.showOkCancel('proceDMRAT.erro', state.description, {
      size: 'md',
      showCancelBtn: false,
      backdrop: 'static',
      keyboard: false
    });
  }

  private _loadEmpresas(): boolean {
    const empresas: Array<IJsonErpUser> = this.session.erps.filter((erp: IJsonErpUser) => {
      const role: IJsonUserRole = erp.rolesAcess.find((erpRole: IJsonUserRole) => erpRole.role === ROLE.RH);
      return isDefined(role);
    });
    let idx = -1;
    const foundItem = empresas.find((item, index) => {
      if (item.nEmpresa === this.session.erp.nEmpresa) {
        idx = index;
        return true;
      }
      return false;
    });
    if (idx > -1 && isDefinedNotNull(foundItem)) {
      empresas.splice(idx, 1);
      this.empresasSel = [foundItem];
    }
    this.empresasSource = empresas;
    this.sortEmpresasByNEmpresa();
    return true;
  }

  private _finalize(): Promise<void> {
    this._wizNavControl(true, false);
    this._init();
    this._invalidateSteps(ERHProceDMRATSteps.INTRO, 'forward');
    this._setWizActiveStep(ERHProceDMRATSteps.INTRO);
    return Promise.resolve();
  }

  private _validateStepEmpresasSel(): boolean {
    if (!this.empresasSel.length) {
      this._plAlertService.error(this._translateService.instant('proceDMRAT.temSeleccionarUmaEmpresa'));
      return false;
    }
    return true;
  }

  private _validateStepProcConfig(): Promise<void> {
    this.model.processaNovamente = false;
    this.model.decleracaoSubstiticao = this.decSubstituicao;
    for (const item of this._procExistentesTableSource) {
      item.selected = this.selectedRowKeys.findIndex((index: number) => item._index === index) !== -1;
    }
    this.model.reProcList = this._procExistentesTableSource
      .filter((item: IRHProceDMRATProcessExistentesItem) => item.selected)
      .map<IRHProceDMRATReProcItem>((item: IRHProceDMRATProcessExistentesItem) => {
        this.model.processaNovamente = true;
        return {
          empresa: item.empresa,
          dmrAno: item.dmrAno,
          dmrMes: item.dmrMes,
          nProcessamento: item.nProcessamento
        };
      });
    return this._processar();
  }

  private _getMultiDatasTableSource(): Array<IRHProceDMRATDataItem> {
    this._multiDatasTableSource = this._multiDatasSourceCache[this.selectedYear];
    if (this._multiDatasTableSource) {
      if (this._isFirstTime) {
        for (const item of this._multiDatasTableSource) {
          item.selected = false;
        }
        this._selectMultiDatasCurrentMonth();
        this._isFirstTime = false;
      }
      return this._multiDatasTableSource;
    }
    this._multiDatasTableSource = this._buildDataSource(this.selectedYear);
    if (this._isFirstTime) {
      this._selectMultiDatasCurrentMonth();
      this._isFirstTime = false;
    }
    this._multiDatasSourceCache[this.selectedYear] = this._multiDatasTableSource;
    return this._multiDatasTableSource;
  }

  private _startProcessChecker(fromInit: boolean = false): void {
    this._wizNavControl(false, false);
    this._intervalId = window.setInterval(() => {
      this._proceDMRATService.getJobStatus().then((response) => {
        this.currentStatus = response;
        if (response.state === ERHProceDMRATStatus.Timeout) {
          clearInterval(this._intervalId);
          this._showTimeoutModal();
          this._resetWizard();
          this._init();
        } else if (response.state === ERHProceDMRATStatus.Error) {
          clearInterval(this._intervalId);
          this._handleStateError(response);
          if (fromInit) {
            this._init();
            this._resetWizard();
          } else {
            this._wizNavControl(true, false);
            this.definitionNavWizard.items[STEP_PROC_CONFIG_INDEX].setIncomplete();
            this._setWizActiveStep(ERHProceDMRATSteps.PROC_CONFIG);
          }
        } else if (response.state === ERHProceDMRATStatus.Ended) {
          clearInterval(this._intervalId);
          this._showResults();
        }
      });
    }, INTERVAL_TIMEOUT);
  }

  private _resetWizard(): void {
    this._wizNavControl(true, true);
    this._invalidateSteps(ERHProceDMRATSteps.INTRO, 'forward');
    this._setWizActiveStep(ERHProceDMRATSteps.INTRO);
    this.definitionNavWizard.items[STEP_INTRO_INDEX].setIncomplete();
  }

  private _processar(): Promise<void> {
    this.model.empresas = this.empresasSel.map((item) => item.nEmpresa);
    this.model.datas = this._multiDatasTableSource.filter((item) => item.selected);
    if (!this.model.datas.length) {
      this._plAlertService.error('proceDMRAT.temSeleccionarDatas');
      return Promise.resolve();
    }
    this._wizNavControl(false, false);
    return this._proceDMRATService.processar(this.model).then(() => {
      this._startProcessChecker();
    });
  }

  private _getWizStepIndex(wizStepId: ERHProceDMRATSteps): number {
    switch (wizStepId) {
      case ERHProceDMRATSteps.INTRO:
        return STEP_INTRO_INDEX;
      case ERHProceDMRATSteps.EMPRESAS_SEL:
        return STEP_EMPRESAS_SEL_INDEX;
      case ERHProceDMRATSteps.PROC_CONFIG:
        return STEP_PROC_CONFIG_INDEX;
      case ERHProceDMRATSteps.PROC:
        return STEP_PROC_INDEX;
      case ERHProceDMRATSteps.PROC_RESULTS:
        return STEP_PROC_RESULTS_INDEX;
      default:
        return -1;
    }
  }

  private _setWizActiveStep(wizStepId: ERHProceDMRATSteps, markPrevAsVisited: boolean = false): void {
    const stepIndex = this._getWizStepIndex(wizStepId);
    if (markPrevAsVisited) {
      for (let i = 0; i < stepIndex; i++) {
        this.definitionNavWizard.items[i].visited = true;
        this.definitionNavWizard.items[i].setComplete();
      }
    }
    this.definitionNavWizard.items[stepIndex].visited = true;
    this.plWizardCallback.setStep(this.definitionNavWizard.items[stepIndex], true);
  }

  private _wizNavControl(nav: boolean, finilize: boolean = true): void {
    this.propertiesNavWizard = {disableNavigation: !nav, disableFinalize: !finilize};
  }

  private _invalidateSteps(fromStep: ERHProceDMRATSteps, direction: 'forward' | 'backward'): void {
    const mapArray: Array<ERHProceDMRATSteps> = [ERHProceDMRATSteps.INTRO, ERHProceDMRATSteps.EMPRESAS_SEL, ERHProceDMRATSteps.PROC_CONFIG, ERHProceDMRATSteps.PROC, ERHProceDMRATSteps.PROC_RESULTS];
    const fromIndex = mapArray.findIndex((item) => item === fromStep);
    this.definitionNavWizard.items.forEach((step) => {
      const itemIndex = mapArray.findIndex((item) => item === step.stepId);
      const doInvalidate = direction === 'forward' ? itemIndex > fromIndex : itemIndex < fromIndex;
      if (doInvalidate) {
        step.visited = false;
        step.setIncomplete();
      }
    });
  }

  private _selectMultiDatasCurrentMonth(): void {
    const item = this._multiDatasTableSource.find((dataItem) => dataItem.mes === this._currentMonth && dataItem.ano === moment().subtract(1, 'months').year());
    if (item) {
      item.selected = true;
    }
  }

  private _buildDataSource(year: number): Array<IRHProceDMRATDataItem> {
    const source: Array<IRHProceDMRATDataItem> = [];
    for (let i = 1; i <= TOTAL_MONTHS; i++) {
      source.push({
        selected: false,
        mes: i,
        ano: year,
        descricao: moment()
          .year(year)
          .month(i - 1)
          .format('MMMM [de] YYYY')
      });
    }
    return source;
  }

  private _getProcExistentesTableSource(): Array<IRHProceDMRATProcessExistentesItem> {
    this.selectedRowKeys = [];
    let index = 0;
    for (const item of this._procExistentesTableSource) {
      item._index = index;
      if (item.selected) {
        this.selectedRowKeys.push(index);
      }
      index++;
    }
    return this._procExistentesTableSource;
  }

  private _getProcResultsTableSource(): Array<IRHProceDMRATItem> {
    return this._procResultsTableSource;
  }

  private _getProcResultsErrorsTableSource(): Array<IRHProceDMRATErroItem> {
    return this._procResultsErrorsTableSource;
  }

  private _loadProceExistentes(): Promise<void> {
    const params: IRHProceDMRATProcessExistentesParams = {
      empresas: this.empresasSel.map((empresa) => empresa.nEmpresa),
      datas: this._multiDatasTableSource.filter((item) => item.selected)
    };

    if (!params.datas.length) {
      this._plAlertService.error(this._translateService.instant('proceDMRAT.temSeleccionarDatas'));
      return undefined;
    }

    return this._proceDMRATService.getProcessExistentes(params).then((response) => {
      this._procExistentesTableSource = response;
      this._refreshProceExistentesTable();
    });
  }

  private _loadResults(): Promise<void> {
    return this._proceDMRATService.getProcResults().then((response: IRHProceDMRATDMRATList) => {
      this._procResultsTableSource = response.list;

      this._procResultsTableSource.forEach((item: IRHProceDMRATItem) => {
        item.statusImage = `fa fa-circle ${item.erros !== 0 ? 'text-danger' : 'text-success'}`;
      });

      this._procResultsErrorsTableSource = response.erros;
      this._refreshProcResultsTable();
      this._refreshProcResultsErrorsTable();
      if (this._procResultsErrorsTableSource.length > 0) {
        this.procResultsTabsCallback.select(this.tabIdRrocResultsErrors);
      }
    });
  }

  private _showResults(): Promise<void> {
    this._wizNavControl(false, true);
    this._setWizActiveStep(ERHProceDMRATSteps.PROC_RESULTS, true);
    return this._loadResults();
  }

  private _refreshProceExistentesTable(): void {
    if (this._dataGridProcExistentesInstance) {
      this._dataGridProcExistentesInstance.refresh();
    }
  }

  private _refreshProcResultsTable(): void {
    if (this._dataGridProcResultsInstance) {
      this._dataGridProcResultsInstance.refresh();
    }
  }

  private _refreshProcResultsErrorsTable(): void {
    if (this._dataGridProcErrorsInstance) {
      this._dataGridProcErrorsInstance.refresh();
    }
  }

  private _printMapa(): void {
    this._cgModalService.showVanilla(ProceDMRATPrintModalComponent, {size: 'xl'});
  }

  private _genDRIFile(): Promise<void> {
    return this._proceDMRATService.genDRIFile().then((response: HttpResponse<Blob>) => {
      const doc: Blob = response.body;
      if (!doc?.size) {
        this._plAlertService.success(this._translateService.instant('proceDMRAT.semAlteracaoParaGerar'));
      } else {
        downloadStream(response);
      }
    });
  }

  private _showMaintenanceRHGestaoFicheirosDMRAT(ano: number, mes: number, temErro: boolean): void {
    const modalTitle = temErro ? 'proceDMRAT.promptAlertModalTitle' : 'proceDMRAT.promptConfirmModalTitle';
    const modalMessage = temErro ? 'proceDMRAT.enviadoComErros' : 'proceDMRAT.enviadoComSuccess';

    this._cgModalService
      .showOkCancel(modalTitle, modalMessage, {
        size: 'md',
        btnOkText: 'global.btn.yes',
        btnCancelText: 'global.btn.no',
        backdrop: 'static',
        showCloseBtn: false,
        keyboard: false
      })
      .then(() => {
        this._maintenanceRHGestaoFicheirosDMRAT.maintenance({
          params: {
            ano: ano,
            mes: mes
          }
        });
      });
  }

  private async _sendFile(): Promise<void> {
    await this._cgModalService.showOkCancel('proceDMRAT.promptConfirmModalTitle', 'proceDMRAT.promptSendModalMessage', {
      size: 'md',
      btnOkText: 'global.btn.yes',
      btnCancelText: 'global.btn.no',
      backdrop: 'static',
      showCloseBtn: false,
      keyboard: false
    });

    this._proceDMRATService
      .sendFileWebService()
      .then(() => {
        this._loadResults();
        if (this._procResultsTableSource.length > 0) {
          this._showMaintenanceRHGestaoFicheirosDMRAT(this._procResultsTableSource[0].ano, this._procResultsTableSource[0].mes, false);
        }
      })
      .catch((reason) => {
        if (this._procResultsTableSource.length > 0) {
          const exception = this._cgExceptionService.get(reason);
          if (exception) {
            const index = this._procResultsErrorsTableSource.findIndex((item) => item.nEmpresa === this._procResultsTableSource[0].ssNEMPRESA);
            if (index === -1) {
              this._procResultsErrorsTableSource.push({
                nEmpresa: this._procResultsTableSource[0].ssNEMPRESA,
                erro: exception.message,
                queFazer: this._translateService.instant('proceDMRAT.comoResolver')
              });
            } else {
              this._procResultsErrorsTableSource[index].erro = exception.message;
            }
          }

          if (this._procResultsErrorsTableSource.length > 0) {
            this.procResultsTabsCallback.select(this.tabIdRrocResultsErrors);
          }

          this._showMaintenanceRHGestaoFicheirosDMRAT(this._procResultsTableSource[0].ano, this._procResultsTableSource[0].mes, true);
        }
      });
  }
}
