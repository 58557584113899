import {ClassProvider, forwardRef, Injectable} from '@angular/core';
import {DI_AMALIA_INTENT_HANDLERS} from '../../../../../services/amalia/intent/amalia.intent.handler.di';
import {IAmaliaIntent, IAmaliaIntentHandler} from '../../../../../services/amalia/intent/amalia.intent.handler.interface';
import {CGStateService} from '../../../../../components/state/cg.state.service';
import {EPortal} from '../../../../../../common/enums/portals.enums';
import {AMALIA_INTENT_NAME_EXTRATOS_GRID} from './extratosgrid.amalia.interface';
import {IContabilidadeExtratosGridParams, MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID} from '../contabilidade.extratosGrid.module.interface';

export const AMALIA_INTENT_HANDLER_EXTRATOS_GRID: ClassProvider = {
  provide: DI_AMALIA_INTENT_HANDLERS,
  multi: true,
  useClass: forwardRef(() => ExtratosGridAmaliaIntentHandler)
};

@Injectable()
export class ExtratosGridAmaliaIntentHandler implements IAmaliaIntentHandler {
  constructor(private readonly _cgStateService: CGStateService) {}

  public intentName(): string {
    return AMALIA_INTENT_NAME_EXTRATOS_GRID;
  }

  public async intentHandler(intent: IAmaliaIntent<IContabilidadeExtratosGridParams>): Promise<void> {
    return this._cgStateService.redirectToState({
      stateOrName: MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID,
      portal: EPortal.CONTABILIDADE,
      exactMatch: true,
      transitionOptions: {reload: true},
      params: {
        nConta: intent.parameters?.nConta,
        process: intent.parameters?.process ? JSON.parse(intent.parameters.process) : false
      }
    });
  }
}
