<div class="bancos-extrato-module-config-modal">
  <pl-form [properties]="{disabled: !!promise}" [submit]="close" plPromise>
    <div class="modal-header">
      <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'bancosextrato.configmodal.title'"></h5>
    </div>

    <div class="modal-body" [plPromise]="promise">
      <ng-container *ngIf="!simpleMode">
        <pl-group>
          <label [translate]="'bancosextrato.fields.diarioRecebimentos'"></label>
          <edit>
            <entity-autocomplete
              entity="diarios"
              attrName="diariorecebimentos"
              [model]="configs"
              (selectedKeyChange)="configs.nDiarioRecebimentos = $event"
              (evtSelectedDescriptionChanged)="configs.nomeDiarioRecebimentos = $event"
              [fieldsMap]="{nDiario: 'nDiarioRecebimentos', nome: 'nomeDiarioRecebimentos'}"
              plAutoFocus>
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'bancosextrato.fields.diarioPagamentos'"></label>
          <edit>
            <entity-autocomplete
              entity="diarios"
              attrName="diariopagamentos"
              [model]="configs"
              (selectedKeyChange)="configs.nDiarioPagamentos = $event"
              (evtSelectedDescriptionChanged)="configs.nomeDiarioPagamentos = $event"
              [fieldsMap]="{nDiario: 'nDiarioPagamentos', nome: 'nomeDiarioPagamentos'}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'bancosextrato.configmodal.fields.descricao'"></label>
          <edit>
            <pl-edit-text attrName="descricao" [(model)]="configs.descricao"></pl-edit-text>
          </edit>
        </pl-group>

        <pl-group>
          <edit>
            <pl-edit-switch attrName="movabauto" [(model)]="configs.movAbAuto" [label]="'bancosextrato.configmodal.fields.movAbAuto'"></pl-edit-switch>
          </edit>
        </pl-group>

        <pl-group>
          <edit>
            <pl-edit-switch attrName="showdescritivo" [(model)]="configs.showDescritivo" [label]="'bancosextrato.configmodal.fields.showDescritivo'"></pl-edit-switch>
          </edit>
        </pl-group>
      </ng-container>

      <ng-container *ngIf="simpleMode">
        <pl-group>
          <label [translate]="'bancosextrato.dataRegistoRecebimentosPagamentos.label'"></label>
          <edit>
            <pl-edit type="radiogroup" attrName="dataRegistoRecebimentosPagamentos" [(model)]="configs.dataRegistoRecebimentosPagamentos" [properties]="radioGroupDataRegistoRecebimentosPagamentos">
            </pl-edit>
          </edit>
        </pl-group>
      </ng-container>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button type="submit" klass="btn-primary action-submit"> <i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'global.btn.save'"></span> </pl-button>

      <pl-button klass="btn-light action-cancel" [disabled]="closeDisabled" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
    </div>
  </pl-form>
</div>
