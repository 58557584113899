import {merge, sortBy} from 'lodash-es';
import type {Subscription} from 'rxjs';
import {
  AfterContentChecked,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TrackByFunction
} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {cgcHighlight} from '../pipes/highlight.pipe';
import {
  elementIndex,
  getPathValue,
  isArray,
  isBoolean,
  isDefined,
  isDefinedNotNull,
  isEmpty,
  isError,
  isFunction,
  isNumber,
  isObject,
  isString,
  isUndefined,
  isUndefinedOrNull,
  parseExpressionToString
} from '../common/utilities/utilities';
import {filterSearchInsensitive, orderByFields, paginate} from '../common/utilities/array.utilities';
import {
  ICGCTableData,
  ICGTableEvaluatedField,
  ICGTableEvaluatedValue,
  ICGTableEvtPaginationChanged,
  ICGTableOnDetail,
  ICGTableOnSelect,
  IPlTableCallback,
  IPlTableDefinition,
  IPlTableField,
  IPlTableOptions,
  TCGTableDataReceivedFunction,
  TCGTableEvaluatedRow,
  TCGTableOnDetailFunction,
  TCGTableOnSelectFunction,
  TCGTableSourceFunction,
  TPlTableItem
} from './table.interface';
import type {IPlEventListeners, TPlMouseEventListener} from '../events/listener/events.listener.directive.interface';
import type {IPlFormatConfig} from '../common/format/format.service.interface';
import type {IPlPaginationEvtPaginationChanged} from '../pagination/pagination.component.interface';
import type {IPlVirtualScrolling} from '../scrolling/virtual.scrolling.interface';
import {Logger} from '../logger/logger';
import {PlFormatService} from '../common/format/format.service';
import {PlI18nPlNumberService} from '../i18n/i18n.plNumber.service';
import {PlI18nService} from '../i18n/i18n.service';
import {PlTableHeaderActionsDirective} from './table.header.actions.directive';
import {PlTableItemActionsDirective} from './table.item.actions.directive';
import {PlTableItemDetailDirective} from './table.item.detail.directive';
import {PlTranslateService} from '../translate/translate.service';

const FIELDS_SEPARATOR = ',';
const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 10;
const REGEX_INVALID_CHARACTERS = new RegExp('[a-zA-Z-:]');

@Component({
  selector: 'pl-table',
  templateUrl: './table.component.html'
})
export class PlTableComponent implements OnInit, OnChanges, AfterContentChecked, OnDestroy {
  @Input() public instanceId: string;
  @Input() public definition: IPlTableDefinition;
  @Input() public source: ICGCTableData | Array<any> | TCGTableSourceFunction<any>;
  @Input() public search: string;
  @Input() public order: string;
  @Input() public page: number;
  @Input() public perPage: number;
  @Input() public properties: IPlTableOptions;
  @Input() public selected: any;
  @Input() public callback: IPlTableCallback;
  @Input() public fields: string | Array<string>;
  @Input() public tableClass: string | Array<string>;
  @Input() public highlightFields: string | Array<string>;
  @Input() public theme: string;
  @Input() public format: Partial<IPlFormatConfig>;
  @Input() public onSelect: TCGTableOnSelectFunction<any>;
  @Input() public onDetail: TCGTableOnDetailFunction<any>;
  @Input() public trackByFn: TrackByFunction<any>;
  @Input() public dataReceived: TCGTableDataReceivedFunction<any>;
  @Input() public virtualScroll: Partial<IPlVirtualScrolling<TPlTableItem<any>>>;
  @Output() public readonly selectedChange: EventEmitter<any>;
  @Output() public readonly pageChange: EventEmitter<number>;
  @Output() public readonly perPageChange: EventEmitter<number>;
  @Output() public readonly evtSelect: EventEmitter<ICGTableOnSelect<any>>;
  @Output() public readonly evtDetail: EventEmitter<ICGTableOnDetail<any>>;
  @Output() public readonly evtPaginationChanged: EventEmitter<ICGTableEvtPaginationChanged>;

  public bodyValues: Array<TCGTableEvaluatedRow>;
  public footerValues: Array<TCGTableEvaluatedRow>;
  public evaluatedFields: Array<ICGTableEvaluatedField>;
  public actionsAlign: string;
  public hasDetail: boolean;
  public hasActions: boolean;
  public footerSource: Array<any>;
  public data: ICGCTableData;
  public isLoading: boolean;
  public disableVirtualScroll: boolean;
  public options: IPlTableOptions;
  public templateHeaderActions: PlTableHeaderActionsDirective;
  public templateItemActions: PlTableItemActionsDirective;
  public templateItemDetail: PlTableItemDetailDirective;

  @ContentChildren(PlTableHeaderActionsDirective, {descendants: false}) private readonly _templatesHeaderActions: QueryList<PlTableHeaderActionsDirective>;
  @ContentChildren(PlTableItemActionsDirective, {descendants: false}) private readonly _templatesItemActions: QueryList<PlTableItemActionsDirective>;
  @ContentChildren(PlTableItemDetailDirective, {descendants: false}) private readonly _templatesItemDetail: QueryList<PlTableItemDetailDirective>;
  private readonly _element: HTMLElement;
  private readonly _defaultOptions: IPlTableOptions;
  private readonly _subscriptionFormat: Subscription;
  private readonly _queuedRowsToOpen: Set<number>;
  private readonly _safeValueTrue: SafeHtml;
  private readonly _safeValueFalse: SafeHtml;
  private _format: IPlFormatConfig;
  private _lastSearch: string;
  private _targetDetail: HTMLElement;
  private _targetColumn: HTMLElement;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _domSanitizer: DomSanitizer,
    private readonly _plFormatService: PlFormatService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _logger: Logger,
    private readonly _plI18nService: PlI18nService,
    private readonly _plI18nPlNumberService: PlI18nPlNumberService
  ) {
    this.selectedChange = new EventEmitter<any>();
    this.pageChange = new EventEmitter<number>();
    this.perPageChange = new EventEmitter<number>();
    this.evtSelect = new EventEmitter<ICGTableOnSelect<any>>();
    this.evtDetail = new EventEmitter<ICGTableOnDetail<any>>();
    this.evtPaginationChanged = new EventEmitter<ICGTableEvtPaginationChanged>();
    this.instanceId = '';
    this.search = '';
    this.order = '';
    this.highlightFields = [];
    this.theme = '';
    this.evaluatedFields = [];
    this.bodyValues = [];
    this.footerValues = [];
    this.footerSource = [];
    this.disableVirtualScroll = true;
    this.options = {};
    this._element = this._elementRef.nativeElement;
    this._defaultOptions = {
      border: false,
      hasActions: undefined,
      hasDetail: undefined,
      hidePagination: false,
      perPage: undefined,
      selectFirst: false,
      sortType: 'auto',
      suppressEmptyLines: false,
      tableClass: undefined,
      useUrl: false,
      noDataPlaceholder: false,
      noDataPlaceholderMessage: '',
      preventRowsContextMenu: false
    };
    this._subscriptionFormat = this._plFormatService.format.subscribe((format: IPlFormatConfig) => {
      this._format = format;
    });
    this._queuedRowsToOpen = new Set<number>();
    this._safeValueTrue = this._domSanitizer.bypassSecurityTrustHtml('<i class="fa fa-check-square-o" aria-hidden="true"></i>');
    this._safeValueFalse = this._domSanitizer.bypassSecurityTrustHtml('<i class="fa fa-square-o" aria-hidden="true"></i>');
  }

  public ngOnInit(): void {
    if (isUndefinedOrNull(this.definition)) {
      throw new TypeError('Input `definition` must be provided but is [undefined] or [null].');
    }
    if (!isObject(this.definition)) {
      throw new TypeError(`Input \`definition\` must be an object but is [${typeof this.definition}].`);
    }
    if (!isArray(this.definition.fields)) {
      throw new TypeError(`Definition's property \`fields\` type must be an \`Array\` but is [${typeof this.definition.fields}].`);
    }
    this._handleChanges();
    this._changedDefinition();
    this._changedPage();
    this._changedPerPage();
    this._changedDataReceived();
    this._changedTrackByFn();
    this._changedFormat();

    this.definition.fields = this.definition.fields.slice();
    this.fields = this.fields || this.definition.listFields || undefined;
    if (this.fields) {
      const normalizedFieldNames: Array<string> = (isString(this.fields) ? this.fields.split(FIELDS_SEPARATOR) : isArray(this.fields) ? this.fields : []).map((fieldName: string) =>
        fieldName.toUpperCase()
      );
      const filteredFields: Array<IPlTableField> = this.definition.fields.filter((field: IPlTableField) => {
        if (isEmpty(field.name)) {
          return false;
        }
        const normalizedFieldName: string = field.name.toUpperCase();
        return normalizedFieldNames.includes(normalizedFieldName);
      });
      this.definition.fields = sortBy(filteredFields, 'order');
    }
    this.highlightFields = isString(this.highlightFields)
      ? !isEmpty(this.highlightFields)
        ? this.highlightFields.split(FIELDS_SEPARATOR)
        : []
      : isArray(this.highlightFields)
        ? this.highlightFields
        : [];

    this.data = {list: [], footer: [], total: 0};
    if (!this.options.suppressEmptyLines && this.perPage > 0) {
      this.data.total = this.perPage;
      for (let j = 0; j < this.perPage; j++) {
        this.data.list.push({empty: true});
      }
    }

    if (isObject(this.definition) && isEmpty(this.order)) {
      this._changedOrder(this.definition.order);
    }

    this.loadPage();
  }

  public ngOnChanges({definition, search, order, page, perPage, dataReceived, trackByFn, format, virtualScroll, callback}: SimpleChanges): void {
    this._handleChanges();
    if (definition && !definition.isFirstChange()) {
      this._changedDefinition(definition.currentValue);
    }
    if (search && !search.isFirstChange()) {
      this._doSearch(true);
    }
    if (order && !order.isFirstChange()) {
      this._changedOrder(order.currentValue);
    }
    if (page && !page.isFirstChange()) {
      this._changedPage(page.currentValue);
    }
    if (perPage && !perPage.isFirstChange()) {
      this._changedPerPage(perPage.currentValue);
    }
    if (dataReceived && !dataReceived.isFirstChange()) {
      this._changedDataReceived(dataReceived.currentValue);
    }
    if (trackByFn && !trackByFn.isFirstChange()) {
      this._changedTrackByFn(trackByFn.currentValue);
    }
    if (format && !format.isFirstChange()) {
      this._changedFormat(format.currentValue);
    }
    if (virtualScroll) {
      this.disableVirtualScroll = !isObject(virtualScroll.currentValue);
    }
    if (callback) {
      const cb: IPlTableCallback = callback.currentValue;
      if (isObject(cb)) {
        cb.refresh = () => this._doSearch(true);
        cb.getTableData = () => this.data;
        cb.setIsLoading = (value: boolean) => {
          this._setIsLoading(value);
        };
        cb.openRowDetail = (index: number) => {
          this._openRowDetail(index);
        };
        cb.getItemByElement = (element: HTMLElement) => this.getItemByElement(element);
        cb.getItemByRowIndex = (index: number) => this.getItemByRowIndex(index);
        cb.rePaint = () => {
          this._paint();
        };
        cb.rePaintBody = () => {
          this._paintBody();
        };
        cb.rePaintFooter = () => {
          this._paintFooter();
        };
        cb.rePaintBodyItem = (itemOrIndex: TPlTableItem<any> | number, fieldsOrNames?: string | IPlTableField | Array<string | IPlTableField>) => {
          this._rePaintBodyItem(itemOrIndex, fieldsOrNames);
        };
        cb.rePaintFooterItem = (itemOrIndex: TPlTableItem<any> | number, fieldsOrNames?: string | IPlTableField | Array<string | IPlTableField>) => {
          this._rePaintFooterItem(itemOrIndex, fieldsOrNames);
        };
      }
    }
  }

  public ngAfterContentChecked(): void {
    this.templateHeaderActions = this._templatesHeaderActions.first;
    let actionsAlign;
    if (this.templateHeaderActions?.actionsAlign) {
      actionsAlign = this.templateHeaderActions.actionsAlign;
    }
    this.templateItemActions = this._templatesItemActions.first;
    if (this.templateItemActions?.actionsAlign) {
      actionsAlign = this.templateItemActions.actionsAlign;
    }
    this.templateItemDetail = this._templatesItemDetail.first;
    this.hasActions = this._isOptionBoolean('hasActions') ? this.options.hasActions : isDefined(this.templateHeaderActions) || isDefined(this.templateItemActions);
    this.hasDetail = this._isOptionBoolean('hasDetail') ? this.options.hasDetail : isDefined(this.templateItemDetail);
    this.actionsAlign = actionsAlign || this.options.actionsAlign || this.actionsAlign || 'right';
  }

  public ngOnDestroy(): void {
    this._subscriptionFormat.unsubscribe();
  }

  public sort(item: IPlTableField): void {
    if (this.order === item.name) {
      this._changedOrder(`${this.order} desc`);
    } else {
      this._changedOrder(item.name);
    }
    this.loadPage();
  }

  public doSelect(item: TPlTableItem<object>, itemIndex: number, column?: IPlTableField, columnIndex?: number, prevent?: boolean, event?: MouseEvent): Promise<void> {
    if (prevent) {
      return Promise.resolve();
    }
    this.selected = item;
    this.selectedChange.emit(this.selected);
    if (item && !item.empty) {
      const onSelectEvent: ICGTableOnSelect<any> = {item: item, itemIndex: itemIndex, column: column, columnIndex: columnIndex, tableData: this.data, event: event};
      let promise: Promise<void>;
      if (isFunction(this.onSelect) && !item._promise) {
        promise = Promise.resolve(this.onSelect(onSelectEvent));
      }
      if (promise) {
        item._promise = promise;
      }
      return Promise.resolve(promise).finally(() => {
        item._promise = undefined;
        this.evtSelect.emit(onSelectEvent);
      });
    }
    return Promise.resolve();
  }

  public onDetailMouseDown(event: MouseEvent): void {
    this._targetDetail = <HTMLElement>event.target;
  }

  public onDetailMouseUp(item: TPlTableItem<object>, itemIndex: number, column?: IPlTableField, columnIndex?: number, prevent?: boolean, event?: MouseEvent): Promise<void> {
    const target: HTMLElement = <HTMLElement>event.target;
    if (!this._targetDetail || this._targetDetail !== target || !this.hasDetail || item._hasDetail === false || item.empty || prevent) {
      this._targetDetail = undefined;
      return Promise.resolve();
    }
    this._targetDetail = undefined;
    return Promise.resolve(this.doSelect(item, itemIndex, column, columnIndex, prevent, event)).then(() => {
      const onDetailEvent: ICGTableOnDetail<any> = {item: item, itemIndex: itemIndex, tableData: this.data, event: event};
      let promise: Promise<void>;
      if (isFunction(this.onDetail) && !item._promise) {
        promise = Promise.resolve(this.onDetail(onDetailEvent));
      }
      if (promise) {
        item._promise = promise;
      }
      return Promise.resolve(promise).finally(() => {
        item._detailOpen = !item._detailOpen;
        item._promise = undefined;
        this.evtDetail.emit(onDetailEvent);
      });
    });
  }

  public onColumnMouseDown(column: IPlTableField, event: MouseEvent): void {
    this._targetColumn = (<HTMLElement>event.target).closest<'tr'>('tr');
    const events: IPlEventListeners = column.events || this.definition.columnsEvents;
    if (isObject(events)) {
      const eventMouseDown: TPlMouseEventListener = events.mousedown;
      if (isFunction(eventMouseDown)) {
        eventMouseDown.call(<HTMLElement>event.target, event);
      }
    }
  }

  public onColumnMouseUp(item: TPlTableItem<object>, itemIndex: number, column: IPlTableField, columnIndex: number, prevent: boolean, event: MouseEvent): Promise<void> {
    const target: HTMLElement = (<HTMLElement>event.target).closest<'tr'>('tr');
    if (!this._targetColumn || this._targetColumn !== target) {
      this._targetColumn = undefined;
      return Promise.resolve();
    }
    this._targetColumn = undefined;
    const events: IPlEventListeners = column.events || this.definition.columnsEvents;
    if (isObject(events)) {
      const eventMouseUp: TPlMouseEventListener = events.mouseup;
      if (isFunction(eventMouseUp)) {
        eventMouseUp.call(<HTMLElement>event.target, event);
      }
    }
    return this.doSelect(item, itemIndex, column, columnIndex, prevent, event);
  }

  public onColumnContextMenu(event: MouseEvent): void {
    if (this.options.preventRowsContextMenu) {
      event.preventDefault();
    }
  }

  public loadPage(): Promise<ICGCTableData> {
    if (isUndefined(this.source)) {
      this._setIsLoading(false);
      return Promise.reject(new Error('pl-table.source not defined!'));
    }
    this.selected = undefined;
    this._lastSearch = this.search;
    this._setIsLoading(true);

    if (isObject(this.source) && isArray((<ICGCTableData>this.source).list)) {
      if (isArray((<ICGCTableData>this.source).footer) && (<ICGCTableData>this.source).footer.length) {
        this.footerSource = (<ICGCTableData>this.source).footer;
      }
      this.source = (<ICGCTableData>this.source).list;
    }
    return new Promise<ICGCTableData>((resolve, reject) => {
      if (isArray(this.source)) {
        this._dataReceived(this.source, this.page).then(() => {
          resolve(this.data);
        });
      } else {
        Promise.resolve((<TCGTableSourceFunction<any>>this.source)(this.search, this.order, this.page, this.perPage))
          .then((response) => {
            this._dataReceived(response, this.page).finally(() => {
              resolve(this.data);
            });
          })
          .catch((reason: unknown) => {
            this._setIsLoading(false);
            if (isError(reason)) {
              reject(reason);
            } else {
              reject(new Error(String(reason)));
            }
          });
      }
    });
  }

  public changedPagination({page, perPage}: IPlPaginationEvtPaginationChanged): void {
    this.page = page;
    this.pageChange.emit(this.page);
    this.perPage = perPage;
    this.perPageChange.emit(this.perPage);
    this.evtPaginationChanged.emit({search: this.search, page: this.page, perPage: this.perPage});
    this.loadPage();
  }

  public getItemByElement<T>(element: HTMLElement): TPlTableItem<T> {
    if (!element || !this._element.contains(element)) {
      return undefined;
    }
    const row: HTMLElement = element.closest<'tbody'>('tbody');
    if (!row) {
      return undefined;
    }
    const rowIndex: number = elementIndex(row);
    if (!isNumber(rowIndex) || rowIndex < 1) {
      return undefined;
    }
    return this.getItemByRowIndex(rowIndex - 1);
  }

  public getItemByRowIndex<T>(index: number): TPlTableItem<T> {
    if (index >= 0 && index < this.data.list.length) {
      return this.data.list[index];
    }
    return undefined;
  }

  @HostBinding('attr.data-instance-id')
  public get dataInstanceId(): string {
    return this.instanceId;
  }

  private _handleChanges(): void {
    this.options = merge({}, this._defaultOptions, this.options, this.properties);
    if (this.tableClass) {
      if (isString(this.tableClass)) {
        this.tableClass = this.tableClass.split(' ');
      }
      if (!isArray(this.tableClass)) {
        this.tableClass = this.tableClass ? [this.tableClass] : [];
      }
    } else {
      this.tableClass = [];
    }
    this.tableClass = this.tableClass.concat(['table', 'table-hover', 'table-sm']).join(' ');
  }

  private _changedDefinition(value: IPlTableDefinition = this.definition): void {
    this.definition = value;
    this.evaluatedFields = this.definition.fields;
    for (const field of this.evaluatedFields) {
      field._caption = field.caption ? this._plTranslateService.translate(field.caption) : '';
      field._canSort = field.canOrder !== false;
      field._thClass = this._getThClass(field);
      this._evaluateFieldOrder(field);
    }
  }

  private _changedOrder(value: string = this.order): void {
    this.order = value;
    this._evaluateOrder();
  }

  private _changedPage(value: number = this.page): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this.options.page;
    }
    if (!isNumber(val)) {
      val = DEFAULT_PAGE;
    }
    this.page = val;
  }

  private _changedPerPage(value: number = this.perPage): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this.options.perPage;
    }
    if (!isNumber(val)) {
      val = DEFAULT_PER_PAGE;
    }
    this.perPage = val;
  }

  private _changedDataReceived(value: TCGTableDataReceivedFunction<any> = this.dataReceived): void {
    this.dataReceived = value || this.options.dataReceived;
  }

  private _changedTrackByFn(value: TrackByFunction<any> = this.trackByFn): void {
    this.trackByFn = value || this.options.trackByFn;
  }

  private _changedFormat(value: Partial<IPlFormatConfig> = this.format): void {
    this.format = {
      date: this._format.date,
      datetime: this._format.datetime,
      time: this._format.time,
      currencyCode: this._format.currencyCode,
      digitsInfo: this._format.digitsInfo,
      digitsInfoCurrency: this._format.digitsInfoCurrency,
      digitsInfoInteger: this._format.digitsInfoInteger,
      digitsInfoFloat: this._format.digitsInfoFloat,
      ...value
    };
  }

  private _getValue(item: object, field: IPlTableField): string {
    let value = this._getRawValue(item, field);
    switch (field.type) {
      case 'date':
        value = this._plI18nService.formatDate(value, this.format.date);
        break;
      case 'datetime':
        value = this._plI18nService.formatDate(value, this.format.datetime);
        break;
      case 'time':
        value = this._plI18nService.formatDate(value, this.format.time);
        break;
      case 'currency':
        value = this._plI18nService.formatCurrency(value, this.format.digitsInfoCurrency, this.format.currencyCode);
        break;
      case 'tax':
        value = this._plI18nPlNumberService.formatTax(value, item[field.name].properties);
        break;
      case 'number':
        value = this._plI18nService.formatNumber(value, this.format.digitsInfo);
        break;
      case 'integer':
        value = this._plI18nService.formatNumber(value, this.format.digitsInfoInteger);
        break;
      case 'double':
        value = this._plI18nService.formatNumber(value, this.format.digitsInfoFloat);
        break;
      default:
        if (field.type !== 'boolean' && field.type !== 'color' && isString(value)) {
          const translatedValue = this._plTranslateService.translate(value);
          if (translatedValue) {
            value = translatedValue;
          }
        }
        break;
    }
    if (field.highlight !== false && field.type !== 'boolean' && field.type !== 'color' && isString(value) && value && (!this.highlightFields.length || this.highlightFields.includes(field.name))) {
      value = cgcHighlight(value, this.search);
    }
    return value;
  }

  private _getRawValue(item: TPlTableItem<object>, field: IPlTableField): any {
    if (!isObject(item) || item.empty) {
      return undefined;
    }

    if (isFunction(this.definition.onGetFieldValue)) {
      const evaluatedValue: unknown = this.definition.onGetFieldValue(field, item);
      if (isDefinedNotNull(evaluatedValue)) {
        return evaluatedValue;
      }
    }

    if (!field.modelName) {
      field = this._initModelName(field);
    }

    let value = getPathValue(item, field.name)[field.modelName];
    if (isUndefinedOrNull(value)) {
      value = undefined;
    }

    if (isDefined(value) && isObject(field.select) && isArray(field.select.list)) {
      const key = field.select.valueProp || 'value';
      const label = field.select.labelProp || 'name';
      for (const selectListItem of field.select.list) {
        if (selectListItem[key] === value) {
          value = selectListItem[label];
        }
      }
    }

    if (value === 0 && field.emptyIfZero) {
      value = undefined;
    }

    return value;
  }

  private _getThClass(field: IPlTableField): string {
    const cssClasses: Array<string> = [];

    if (field.thClass) {
      if (isArray(field.thClass)) {
        cssClasses.concat(field.thClass);
      } else {
        cssClasses.push(field.thClass);
      }
    }

    if (this.definition.thsClass) {
      if (isFunction(this.definition.thsClass)) {
        const result: string | Array<string> = this.definition.thsClass(field);
        if (isArray(result)) {
          cssClasses.concat(result);
        } else if (isString(result) && result) {
          cssClasses.push(result);
        }
      } else if (isArray(this.definition.thsClass)) {
        cssClasses.concat(this.definition.thsClass);
      } else if (isString(this.definition.thsClass) && this.definition.thsClass) {
        cssClasses.push(this.definition.thsClass);
      }
    }

    if (field.align === 'right' || field.type === 'currency' || field.type === 'tax') {
      cssClasses.push('text-right');
    } else if (field.align === 'center') {
      cssClasses.push('text-center');
    }

    return cssClasses.filter((cssClass: string) => !isEmpty(cssClass)).join(' ');
  }

  private _getTdClass(item: TPlTableItem<object>, field: IPlTableField, itemIndex: number): string {
    const cssClasses: Array<string> = [];

    if (item.tdClass) {
      if (isFunction(item.tdClass)) {
        if (!item.empty) {
          const result: string | Array<string> = item.tdClass(item, itemIndex);
          if (isArray(result)) {
            cssClasses.concat(result);
          } else if (isString(result) && result) {
            cssClasses.push(result);
          }
        }
      } else if (isArray(item.tdClass)) {
        cssClasses.concat(item.tdClass);
      } else if (isString(item.tdClass) && item.tdClass) {
        cssClasses.push(item.tdClass);
      }
    }

    if (field?.tdClass) {
      cssClasses.push(parseExpressionToString(field.tdClass));
    }

    if (this.definition.tdsClass) {
      if (isFunction(this.definition.tdsClass)) {
        if (!item.empty) {
          const result: string | Array<string> = this.definition.tdsClass(item, field, itemIndex);
          if (isArray(result)) {
            cssClasses.concat(result);
          } else if (isString(result) && result) {
            cssClasses.push(result);
          }
        }
      } else if (isArray(this.definition.tdsClass)) {
        cssClasses.concat(this.definition.tdsClass);
      } else if (isString(this.definition.tdsClass) && this.definition.tdsClass) {
        cssClasses.push(this.definition.tdsClass);
      }
    }

    if (field.align === 'right' || field.type === 'currency' || field.type === 'tax') {
      cssClasses.push('text-right');
    } else if (field.align === 'center') {
      cssClasses.push('text-center');
    }

    return cssClasses.filter((cssClass: string) => !isEmpty(cssClass)).join(' ');
  }

  private _getRowClass(item: TPlTableItem<object>, itemIndex: number): string {
    const cssClasses: Array<string> = [];

    if (item.rowClass) {
      if (isFunction(item.rowClass)) {
        if (!item.empty) {
          const result: string | Array<string> = item.rowClass(item, itemIndex);
          if (isArray(result)) {
            cssClasses.concat(result);
          } else if (isString(result) && result) {
            cssClasses.push(result);
          }
        }
      } else if (isArray(item.rowClass)) {
        cssClasses.concat(item.rowClass);
      } else if (isString(item.rowClass) && item.rowClass) {
        cssClasses.push(item.rowClass);
      }
    }

    if (this.definition.rowsClass) {
      const rowsClass: string = parseExpressionToString(this.definition.rowsClass, [item, itemIndex]);
      cssClasses.push(rowsClass);
    }

    const activeClass = this.hasDetail && item._hasDetail !== false && item._detailOpen ? 'active' : '';
    cssClasses.push(activeClass);

    return cssClasses.filter((cssClass: string) => !isEmpty(cssClass)).join(' ');
  }

  private _getTdValSpanClass(item: TPlTableItem<object>, field: IPlTableField, itemIndex: number): string | Array<string> {
    if (isEmpty(field.valSpanClass)) {
      return '';
    }
    return isFunction(field.valSpanClass) ? (!item.empty ? field.valSpanClass(item, field, itemIndex) : '') : field.valSpanClass;
  }

  private _setIsLoading(value: boolean): void {
    this.isLoading = value;
  }

  private _openRowDetail(index: number): void {
    if (this.isLoading) {
      this._queuedRowsToOpen.add(index);
      return;
    }
    this._queuedRowsToOpen.delete(index);
    if (!isNumber(index) || index < 0) {
      return;
    }
    if (!this.isLoading) {
      if (index >= this.data.list.length) {
        return;
      }
      this.data.list[index]._detailOpen = true;
    }
  }

  private _initModelName(field: IPlTableField): IPlTableField {
    let index = 0;
    if (!field.name || field.name.trim() === '') {
      throw new TypeError('Invalid field name');
    }
    if (field.name.includes('.')) {
      index = field.name.lastIndexOf('.') + 1;
    }
    field.modelName = field.name.substring(index, field.name.length);
    return field;
  }

  private _doSearch(force: boolean = false): Promise<ICGCTableData> {
    if (!force && this.search === this._lastSearch) {
      return Promise.resolve(this.data);
    }
    this._lastSearch = !isEmpty(this.search) ? this.search : undefined;
    if (this._lastSearch) {
      this.page = 1;
      this.pageChange.emit(this.page);
      this._changedPagination();
    }
    return this.loadPage();
  }

  private _validateDefinitions(data: any): void {
    if (this.definition?.fields.length || !data?.length) {
      return;
    }
    this.definition = {fields: []};
    for (const name of Object.keys(data[0])) {
      if (!name.startsWith('$')) {
        this.definition.fields.push({name: name, caption: name});
      }
    }
  }

  private _dataReceived(data: any, page: number): Promise<void> {
    if (!data) {
      this._logger.error("Cannot read property 'data' of undefined");
      return Promise.resolve();
    }

    if (isObject(data) && data.data) {
      data = data.data;
    }

    if (isArray(data)) {
      data = data.slice();
      if (this.search) {
        data = filterSearchInsensitive(data, this.search);
      }

      if (!isEmpty(this.order)) {
        if (this.options.sortType === 'auto') {
          const orderFields: Array<string> = this.order.split(FIELDS_SEPARATOR);
          for (const orderField of orderFields) {
            const order: Array<string> = orderField.split(' ');
            if (order.length) {
              const transformedData: Array<number> = this._tryParseColumnToNumber(data, order[0]);
              if (transformedData.length) {
                data = transformedData;
              }
            }
          }
        }
        data = orderByFields(data, this.order);
      }

      const total: number = data.length;
      const list: Array<any> = paginate(data, page, this.perPage);
      this.data = {list: list, footer: this.footerSource, total: total};
    } else {
      if (!isArray(data.list)) {
        this._logger.error("Source doesn't have list property");
        return Promise.resolve();
      }
      this.data.list = data.list.slice();
      if (isNumber(data.total)) {
        this.data.total = data.total;
      }
      if (!isNumber(this.data.total)) {
        this.data.total = this.data.list.length;
      }
    }

    if (isArray(data.footer) && this.footerSource !== data.footer && data.footer.length) {
      this.footerSource = data.footer;
    }

    this._validateDefinitions(this.data.list);
    if (!this.options.suppressEmptyLines && this.perPage !== -1) {
      for (let j = this.data.list.length; j < this.perPage; j++) {
        this.data.list.push({empty: true});
      }
      if (this.data.total < this.perPage) {
        this.data.total = this.perPage;
      }
    }

    const totalPages: number = Math.ceil(this.data.total / this.perPage);
    if (this.page > totalPages) {
      this.page = DEFAULT_PAGE;
      this.pageChange.emit(this.page);
      this._changedPagination();
    }

    let promise: Promise<void>;
    if (this.options.selectFirst && this.data.list.length > 0) {
      promise = this.doSelect(this.data.list[0], 0);
    }
    return new Promise<void>((resolve) => {
      Promise.resolve(promise).finally(() => {
        promise = Promise.resolve(isFunction(this.dataReceived) ? this.dataReceived({data: this.data, page: page, perPage: this.perPage}) : undefined);
        promise.finally(() => {
          this._setIsLoading(false);
          this._paint();
          if (this._queuedRowsToOpen.size) {
            for (const row of this._queuedRowsToOpen) {
              this._openRowDetail(row);
            }
            this._queuedRowsToOpen.clear();
          }
          resolve();
        });
      });
    });
  }

  private _isOptionBoolean(value: string): boolean {
    return isObject(this.options) && isBoolean(this.options[value]);
  }

  private _tryParseColumnToNumber(list: Array<any>, property: string): Array<number> {
    const transformedValues: Array<{item: any; parsedValue: number}> = [];
    for (const item of list) {
      const value = item[property];
      if (REGEX_INVALID_CHARACTERS.test(value)) {
        return [];
      }
      const parsedValue = parseFloat(value);
      if (Number.isNaN(parsedValue)) {
        return [];
      }
      transformedValues.push({item: item, parsedValue: parsedValue});
    }
    for (const transformedValue of transformedValues) {
      const item = transformedValue.item;
      item[property] = transformedValue.parsedValue;
    }
    return list;
  }

  private _changedPagination(): void {
    this.evtPaginationChanged.emit({search: this.search, page: this.page, perPage: this.perPage});
  }

  private _evaluateOrder(): void {
    for (const field of this.evaluatedFields) {
      this._evaluateFieldOrder(field);
    }
  }

  private _evaluateFieldOrder(field: ICGTableEvaluatedField): void {
    field._isOrderAsc = field.name === this.order;
    field._isOrderDesc = `${field.name} desc` === this.order;
  }

  private _paint(): void {
    this._paintBody();
    this._paintFooter();
  }

  private _paintBody(): void {
    this.bodyValues = [];
    this._paintItems([this.bodyValues, this.data.list]);
  }

  private _paintFooter(): void {
    this.footerValues = [];
    this._paintItems([this.footerValues, this.footerSource]);
  }

  private _paintItems(itemsToPaint: [Array<TCGTableEvaluatedRow>, Array<TPlTableItem<unknown>>]): void {
    const values: Array<TCGTableEvaluatedRow> = itemsToPaint[0];
    const items: Array<TPlTableItem<unknown>> = itemsToPaint[1];
    values.splice(0, values.length);
    for (let i = 0; i < items.length; i++) {
      const item: TCGTableEvaluatedRow = items[i];
      this._paintItem(item, i, this.evaluatedFields);
      values.push(item);
    }
  }

  private _paintItem(item: TCGTableEvaluatedRow, itemIndex: number, fields: Array<IPlTableField>): void {
    item._rowClass = this._getRowClass(item, itemIndex);
    item._values = new Map<string, ICGTableEvaluatedValue>();
    for (const field of fields) {
      this._paintItemField(item, itemIndex, field);
    }
  }

  private _paintItemField(item: TCGTableEvaluatedRow, itemIndex: number, field: IPlTableField): void {
    const value = this._evaluateItemValue(item, field);
    const evaluatedValue: ICGTableEvaluatedValue = {
      value: value,
      tdClass: this._getTdClass(item, field, itemIndex),
      valueClass: this._getTdValSpanClass(item, field, itemIndex)
    };
    item._values.set(field.name, evaluatedValue);
  }

  private _evaluateItemValue(item: TPlTableItem<object>, field: IPlTableField): string | SafeHtml {
    switch (field.type) {
      case 'boolean':
        return this._getRawValue(item, field) ? this._safeValueTrue : this._safeValueFalse;
      case 'color':
        return this._domSanitizer.bypassSecurityTrustHtml(`<span class="circle" style="background: ${String(item[field.name])}"></span>`);
      default:
        return this._getValue(item, field);
    }
  }

  private _rePaintBodyItem(itemOrIndex: TPlTableItem<any> | number, fieldsOrNames?: string | IPlTableField | Array<string | IPlTableField>): void {
    this._rePaintItem(this.bodyValues, itemOrIndex, fieldsOrNames);
  }

  private _rePaintFooterItem(itemOrIndex: TPlTableItem<any> | number, fieldsOrNames?: string | IPlTableField | Array<string | IPlTableField>): void {
    this._rePaintItem(this.footerValues, itemOrIndex, fieldsOrNames);
  }

  private _rePaintItem(source: Array<TCGTableEvaluatedRow>, itemOrIndex: TPlTableItem<any> | number, fieldsOrNames?: string | IPlTableField | Array<string | IPlTableField>): void {
    const itemIndex: number = isNumber(itemOrIndex) ? itemOrIndex : this.bodyValues.findIndex((row: TCGTableEvaluatedRow) => row === itemOrIndex);
    const item: TCGTableEvaluatedRow = source[itemIndex];
    if (!item) {
      return;
    }
    const fields: Array<IPlTableField> = isDefined(fieldsOrNames) ? this._getFields(fieldsOrNames) : this.evaluatedFields;
    this._paintItem(item, itemIndex, fields);
  }

  private _getFields(fieldsOrNames: string | IPlTableField | Array<string | IPlTableField>): Array<IPlTableField> {
    return isString(fieldsOrNames)
      ? fieldsOrNames
          .split(FIELDS_SEPARATOR)
          .map((fieldName: string) => this._getField(fieldName))
          .filter(isDefined)
      : isArray(fieldsOrNames)
        ? fieldsOrNames
            .map<IPlTableField>((fieldOrName: string | IPlTableField) => {
              return isString(fieldOrName) ? this._getField(fieldOrName) : isObject(fieldOrName) ? fieldOrName : undefined;
            })
            .filter(isDefined)
        : isObject(fieldsOrNames)
          ? [fieldsOrNames]
          : [];
  }

  private _getField(fieldName: string): IPlTableField {
    return this.evaluatedFields.find((field: ICGTableEvaluatedField) => field.name === fieldName);
  }
}
