<div class="modelo10-config-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'modelo10.modal.config.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'modelo10.modal.config.radicais'"></label>
      <edit>
        <pl-edit type="string" attrName="radicais" [(model)]="radicais"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success action-dismiss" (evtClicked)="close()"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span> </pl-button>

    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
