<div class="verificacaoacumulados">
  <pl-navpill attrName="verificacaoacumulados" (evtSelected)="changedPanel($event)">
    <pl-navpill-panel *ngFor="let panel of panels" [id]="panel.name" [caption]="panel.caption">
      <div *plNavPillPanelContent>
        <div class="entity-detail-form">
          <pl-form [(ngForm)]="forms[panel.name]">
            <pl-group>
              <pl-group [hidden]="selectedPanel === 'inconsistencias'">
                <label [translate]="'verificacaoacumulados.fields.deNconta'"></label>
                <edit>
                  <entity-autocomplete entity="pocs" attrName="deNconta" output="key" [(selectedKey)]="model.deNconta"> </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group [hidden]="selectedPanel === 'inconsistencias'">
                <label [translate]="'verificacaoacumulados.fields.ateNconta'"></label>
                <edit>
                  <entity-autocomplete entity="pocs" attrName="ateNconta" output="key" [(selectedKey)]="model.ateNconta"> </entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group [hidden]="selectedPanel !== 'acumuladosComMovimentos'">
              <label [translate]="'verificacaoacumulados.fields.periodo'"></label>
              <edit>
                <entity-autocomplete entity="periodos" attrName="periodo" [(selectedKey)]="model.periodo" (evtSelectedDescriptionChanged)="nomePeriodo = $event" [properties]="propertiesPeriodo">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-form>
        </div>

        <hr *ngIf="selectedPanel !== 'inconsistencias'" />

        <dx-data-grid
          [cgDxDataGrid]="panel.tableDefinition"
          [dataSource]="source"
          (onInitialized)="onInitialized($event, panel)"
          (onCellClick)="onCellClick($event)"
          (onSelectionChanged)="onSelectionChanged($event)">
          <div *dxTemplate="let item of 'actions'" class="text-center">
            <button type="button" class="btn btn-xs btn-primary" [click]="fnReconstruirAcumulados(item.data.nConta)" plPromise>
              <i class="fa fa-refresh"></i>
            </button>
          </div>

          <div *dxTemplate="let item of 'detailTemplate'">
            <div>{{ item.data.desc }}</div>
            <div>{{ item.data.erro }}</div>
          </div>
        </dx-data-grid>
      </div>
    </pl-navpill-panel>
  </pl-navpill>
</div>
