import type dxDataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import {LoadOptions} from 'devextreme/data';
import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';
import {devExpressDataGridFiltersToQueryFilter, devExpressDataGridSortToOrder} from '../../../devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {DevExpressDatagridPaging} from '../../../devexpress/datagrid/utilities/devexpress.datagrid.paging';
import {ENTITY_NAME_PRH_LOGS} from '../../../../entities/prhlogs/pRHLogs.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IDevExpressDataGrid, IDevExpressDataGridLoadResult} from '../../../devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IGestaoDGEMPSEvent} from '../../gestaodgemps.interface';
import {IJsonPRHLog} from '../../../../entities/prhlogs/jsonPRHLog.entity.interface';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';

@Component({
  selector: 'gestao-dgemps-event-history-modal',
  templateUrl: './gestaodgemps.eventhistory.modal.component.html'
})
export class GestaoDGEMPSEventHistoryModalComponent extends CGModalComponent<void> {
  @Input() public event: IGestaoDGEMPSEvent;

  public readonly dataGrid: IDevExpressDataGrid<IJsonPRHLog, number>;

  private readonly _servicePRHLogs: IEntityService<IJsonPRHLog>;
  private _dataGridInstance: dxDataGrid<IJsonPRHLog, number>;
  private _dataGridPaging: DevExpressDatagridPaging;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.dataGrid = {
      columns: [
        {dataField: 'idLog', dataType: 'number', visible: false, sortOrder: 'desc', sortIndex: 1, showInColumnChooser: false},
        {dataField: 'data', dataType: 'date', caption: 'gestaodgemps.fields.data', sortOrder: 'desc', sortIndex: 2},
        {dataField: 'motivo', dataType: 'string', caption: 'gestaodgemps.fields.motivo'},
        {dataField: 'nomeEmpregado', dataType: 'string', caption: 'gestaodgemps.fields.nomeEmpregado'}
      ],
      dataSource: new CustomStore({
        key: 'idLog',
        load: (loadOptions: LoadOptions) => this._getListLog(loadOptions)
      }),
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      filterRow: {visible: false},
      grouping: {contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false
    };
    const entityServiceBuilder: EntityServiceBuilder = this._injector.get<EntityServiceBuilder>(EntityServiceBuilder);
    this._servicePRHLogs = entityServiceBuilder.build<IJsonPRHLog>(ENTITY_NAME_PRH_LOGS);
  }

  public onDataGridInitialized(event: IDevExpressDataGridEventOnInitialized<IJsonPRHLog, number>): void {
    this._dataGridInstance = event.component;
  }

  private _getListLog(loadOptions: LoadOptions): Promise<IDevExpressDataGridLoadResult<IJsonPRHLog>> {
    if (!this._dataGridPaging) {
      this._dataGridPaging = new DevExpressDatagridPaging<IJsonPRHLog, number>(this._dataGridInstance);
    }
    let queryFilter: string = devExpressDataGridFiltersToQueryFilter(this._dataGridPaging.dataGridInstance, loadOptions.filter);
    if (queryFilter) {
      queryFilter += '&';
    }
    queryFilter += `idTarefaCab=${this.event.idTarefaCab}`;
    const order: string = loadOptions.sort ? devExpressDataGridSortToOrder(loadOptions.sort) : undefined;
    const {page, perPage} = this._dataGridPaging.paginate(loadOptions);
    return this._servicePRHLogs.query({pesquisa: queryFilter, ordena: order, pagina: page, porpagina: perPage}).then((response: THttpQueryResponse<IJsonPRHLog>) => {
      return this._dataGridPaging.processResult(response);
    });
  }
}
