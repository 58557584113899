import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {
  IPlTableCallback,
  IPlTableDefinition,
  IPlTableOptions,
  IPlTabsCallback,
  IPlUploadCallback,
  IPlUploadFile,
  isDefinedNotNull,
  isFunction,
  PlAlertService,
  PlI18nService,
  TCGTableSourceFunction
} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {ConciliacaoBancariaConfigImportExtratoModalComponent} from '../configimportextrato/conciliacaoBancaria.configImportExtrato.modal.component';
import {ConciliacaoBancariaService} from '../../conciliacaoBancaria.module.service';
import {IConciliacaoBancariaImportExtratoBancarioModel, IConciliacaoBancariaImportExtratoBancarioUploadResponse, IConciliacaoBancariaLinItem} from '../../conciliacaoBancaria.module.interface';
import {CGExceptionService} from '../../../../../../core/components/exceptions/exceptions.service';

const TAB_ID_IMPORTADOR = 'importadorTab';
const TAB_ID_IMPORTADOR_RESULTS = 'importadorResultsTab';

@Component({
  selector: 'conciliacao-bancaria-import-extrato-bancario-modal',
  templateUrl: './conciliacaoBancaria.importExtratoBancario.modal.component.html'
})
export class ConciliacaoBancariaImportExtratoBancarioModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public nConta: string;
  @Input() public periodo: string;
  @Input() public nomeConta: string;
  @Input() public concilBancoCabID: string;
  @Input() public decimais: number;

  public readonly plUploadCallback: IPlUploadCallback;
  public readonly tabCallback: IPlTabsCallback;
  public readonly importadorTabId: string;
  public readonly importadorResultsTabId: string;
  public readonly tableOptions: IPlTableOptions;
  public template: string;
  public model: IConciliacaoBancariaImportExtratoBancarioModel;
  public uploadUrl: string;
  public tableDefinition: IPlTableDefinition;
  public tableCallback: IPlTableCallback;
  public isSaldoFinalImportadoValido: boolean;
  public toggleBtnCaption: string;
  public mainPromise: Promise<void>;
  public canSave: boolean;
  public errorMessage: string;
  public readyForUpload: boolean;

  private _tableSource: Array<IConciliacaoBancariaLinItem>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _conciliacaoBancariaService: ConciliacaoBancariaService,
    private readonly _plI18nService: PlI18nService,
    private readonly _plAlertService: PlAlertService,
    private readonly _translateService: TranslateService,
    private readonly _cgModalService: CGModalService,
    private readonly _cgExceptionService: CGExceptionService
  ) {
    super(_injector);
    this.importadorTabId = TAB_ID_IMPORTADOR;
    this.importadorResultsTabId = TAB_ID_IMPORTADOR_RESULTS;
    this.tableOptions = {
      perPage: 50,
      suppressEmptyLines: true
    };
    this.errorMessage = '';
    this._tableSource = [];
    this.tabCallback = {};
    this.plUploadCallback = {};
    this.isSaldoFinalImportadoValido = true;
    this.readyForUpload = false;
    this.model = {
      list: [],
      bancoTemplate: '',
      concilBancoCabID: '',
      saldoFinal: 0,
      saldoInicial: 0,
      saldoFinalImportado: 0,
      saldoInicialImportado: 0
    };
    this.toggleBtnCaption = 'conciliacaoBancaria.toggleBtnHide';

    this.tableDefinition = {
      order: 'nSeq',
      fields: [
        {name: 'nSeq', caption: 'conciliacaoBancaria.fields.nSeq'},
        {name: 'data', caption: 'conciliacaoBancaria.fields.data', type: 'date'},
        {name: 'descricao', caption: 'conciliacaoBancaria.fields.descricao'},
        {name: 'dcStr', caption: 'conciliacaoBancaria.fields.dc'},
        {name: 'valor', caption: 'conciliacaoBancaria.fields.valor', type: 'number'},
        {name: 'saldo', caption: 'conciliacaoBancaria.fields.saldo', type: 'number'},
        {name: 'infoMsg', caption: 'conciliacaoBancaria.fields.message'}
      ]
    };
    this.canSave = false;
  }

  public readonly fnGetTableSource: TCGTableSourceFunction<IConciliacaoBancariaLinItem> = () => this._getTableSource();

  public ngOnInit(): void {
    this.uploadUrl = this._conciliacaoBancariaService.getImportExtratoBancarioUrl(this.nConta, this.periodo);
    this.mainPromise = this._conciliacaoBancariaService.loadImportExtractoBancario(this.concilBancoCabID, this.nConta, this.periodo).then((response) => {
      this.model = {...this.model, ...response};
      this.readyForUpload = this.model.bancoTemplate && this.model.bancoTemplate.length > 0;
    });
  }

  public getSaldoString(value: number): string {
    const dcString = value >= 0 ? 'CRE' : 'DEB';
    return `${this._plI18nService.formatNumber(Math.abs(value), this.decimais)} <small>${dcString}</small>`;
  }

  public fnOnFileUploaded(file: IPlUploadFile): void {
    this.errorMessage = '';
    if (file.upload.response instanceof HttpResponse) {
      const response: HttpResponse<IConciliacaoBancariaImportExtratoBancarioUploadResponse> = file.upload.response;
      this._tableSource = response.body.list;
      this.model = {...this.model, saldoInicialImportado: response.body.saldoInicialImportado, saldoFinalImportado: response.body.saldoFinalImportado};
      this.isSaldoFinalImportadoValido = response.body.saldoFinalImportadoValido;
      if (!this.isSaldoFinalImportadoValido) {
        this.errorMessage = this._translateService.instant('conciliacaoBancaria.osSaldosDoExtratoBancarioImporta');
        this._plAlertService.error(this.errorMessage);
      } else {
        this.canSave = true;
        this.plUploadCallback.removeAllFiles();
      }
      this.tabCallback.select(TAB_ID_IMPORTADOR_RESULTS);
      if (isDefinedNotNull(this.tableCallback) && isFunction(this.tableCallback.refresh)) {
        this.tableCallback.refresh();
      }
    } else {
      const response: HttpErrorResponse = file.upload.response;
      this._plAlertService.error(response.message);
    }
  }

  public save(): Promise<void> {
    this.disableClose();
    return this._conciliacaoBancariaService
      .saveExtractoBancario(this.nConta, this.periodo)
      .then(() => {
        this.enableClose();
        this.close();
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
        if (reason instanceof HttpErrorResponse) {
          const exception = this._cgExceptionService.get(reason);
          if (exception?.message) {
            this._plAlertService.error(exception.message);
          }
        }
      });
  }

  public fnOnAcceptedFile(): void {
    this.errorMessage = '';
    this.canSave = false;
  }

  public fnConfigImportClick(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ConciliacaoBancariaConfigImportExtratoModalComponent, {size: 'xl'});
    const componentInstance: ConciliacaoBancariaConfigImportExtratoModalComponent = modalInstance.componentInstance;
    componentInstance.templateName = this.model.bancoTemplate;
    componentInstance.nConta = this.nConta;
    componentInstance.periodo = this.periodo;
    return modalInstance.result;
  }

  public bancoTemplateChanged(bancoTemplate: string): void {
    this.model.bancoTemplate = bancoTemplate;
    this._conciliacaoBancariaService.setTemplateImportExtrato(this.model.bancoTemplate, this.nConta, this.periodo).then(() => {
      this.readyForUpload = true;
    });
  }

  private _getTableSource(): Array<IConciliacaoBancariaLinItem> {
    return this._tableSource;
  }
}
