import {ModuloComponent} from '../../../../components/module/module.component';
import {Component, Injector, OnInit} from '@angular/core';
import {ReportsRegistryService} from '../../../../components/reports/reports.registry.service';
import {IJsonReport} from '../../../../entities/reports/jsonReport.interface';
import {EReport} from '../../../../entities/reports/reports.interface';
import moment from 'moment';
import {IJsonImpFFERPOC} from '../jsonImpFFERPOC.module.interface';
import {ImpFFERPOCService} from '../impFFERPOC.module.service';
import {TDate} from '../../../../../common/dates';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'imp-fferpoc',
  templateUrl: './impFFERPOC.module.component.html'
})
export class ImpFFERPOCModuleComponent extends ModuloComponent implements OnInit {
  public report: IJsonReport;
  public pdfUrl: string;
  public collapsedPesqAvancada: boolean;
  public filters: IJsonImpFFERPOC;
  public datasProcessamentos: Array<TDate>;
  public dataProcessamento: TDate;
  public showPdfViewer: boolean;
  private _datasProcessamentos: Array<TDate>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _impFFERPOCService: ImpFFERPOCService,
    private readonly _reportsRegistryService: ReportsRegistryService
  ) {
    super(_injector);
    this.collapsedPesqAvancada = true;
    this.datasProcessamentos = [];
    this._datasProcessamentos = [];
    this.filters = {
      dataProcessamento: moment(),
      reportName: ''
    };
    this.dataProcessamento = moment();
    this.showPdfViewer = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'previsualizar',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-search fa-fw"></i>',
      class: 'btn-success',
      caption: 'impfferpoc.btn.previsualizar',
      click: () => {
        this._previsualizar();
      }
    });
    this._impFFERPOCService.getDatasProcessamentos().then((response: HttpResponse<Array<TDate>>) => {
      this.datasProcessamentos = response.body.map<string>((data: TDate) => moment(data).format(this.format.momentDate));
      this._datasProcessamentos = response.body;
      this.filters.dataProcessamento = this._datasProcessamentos[this._datasProcessamentos.length - 1];
      this.dataProcessamento = this.datasProcessamentos[this.datasProcessamentos.length - 1];
      this._loadDefaultReport();
    });
  }

  public changeDataProcessamento(data: TDate): void {
    this.dataProcessamento = data;
    const index: number = this._datasProcessamentos.findIndex((date: TDate) => moment(date).format(this.format.momentDate) === data);
    if (index !== -1) {
      this.filters.dataProcessamento = this._datasProcessamentos[index];
    }
  }

  private _previsualizar(): void {
    this.filters.reportName = this.report.name;
    this._impFFERPOCService.getListagemImpFFERPOCUrl(this.filters).subscribe((url: string) => {
      this.pdfUrl = url;
      this.showPdfViewer = true;
    });
  }

  private _loadDefaultReport(): void {
    this._reportsRegistryService
      .get(EReport.ImpFFERPOC)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          this.report = reports[0];
        }
      });
  }
}
