<div class="ativosimpressao entity-detail-form">
  <pl-form>
    <pl-group>
      <pl-group>
        <edit class="inputs-text-inline">
          <label [translate]="'ativosimpressao.header.codAtivoDe'"></label>
          <entity-autocomplete entity="ativos" attrName="codAtivoDe" (selectedKeyChange)="header.codAtivoDe = $event" [output]="ativoOutput"> </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <edit class="inputs-text-inline">
          <label [translate]="'ativosimpressao.header.codAtivoAte'"></label>
          <entity-autocomplete entity="ativos" attrName="codAtivoAte" [model]="ativosAte" (selectedKeyChange)="header.codAtivoAte = $event" [output]="ativoOutput"> </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <hr class="row-only-phone" />

    <pl-group>
      <pl-group>
        <edit class="inputs-text-inline">
          <label [translate]="'ativosimpressao.header.dataAquisicaoDe'"></label>
          <pl-edit type="date" [(model)]="header.dataAquisicaoDe" [properties]="{validators: {required: {value: true}}}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <edit class="inputs-text-inline">
          <label [translate]="'ativosimpressao.header.dataAquisicaoAte'"></label>
          <pl-edit type="date" [(model)]="header.dataAquisicaoAte" [properties]="{validators: {required: {value: true}}}"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'ativosimpressao.header.situacao'"></label>
        <edit>
          <pl-edit type="checkbox" attrName="normal" [model]="header.normal" (modelChange)="header.normal = $event" [properties]="{label: 'ativosimpressao.radioOptions.normal'}"> </pl-edit>
          <pl-edit type="checkbox" attrName="abatido" [model]="header.abatido" (modelChange)="header.abatido = $event" [properties]="{label: 'ativosimpressao.radioOptions.abatido'}"> </pl-edit>
          <pl-edit type="checkbox" attrName="vendido" [model]="header.vendido" (modelChange)="header.vendido = $event" [properties]="{label: 'ativosimpressao.radioOptions.vendido'}"> </pl-edit>
          <pl-edit type="checkbox" attrName="dividido" [model]="header.dividido" (modelChange)="header.dividido = $event" [properties]="{label: 'ativosimpressao.radioOptions.dividido'}"> </pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'ativosimpressao.header.estadoUso'"></label>
        <edit>
          <pl-edit type="radiogroup" attrName="estado" [model]="header.estadoUso" (modelChange)="header.estadoUso = $event" [properties]="radioGroupTemplateEstadoUso"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <div class="card mt-3">
      <div class="card-header fw-bold pesquisa-avançada" (click)="collapsedPesqAvancada = !collapsedPesqAvancada">
        <button type="button" class="btn btn-link" [translate]="'ativosimpressao.header.pesqavancada'"></button>
        <i class="fa fa-caret-right" aria-hidden="true" [plAnimationRotate]="!collapsedPesqAvancada"></i>
      </div>
      <pl-animation-collapse [collapsed]="collapsedPesqAvancada">
        <div class="card-body">
          <pl-group>
            <pl-group>
              <label [translate]="'ativosimpressao.header.nContaDe'"></label>
              <edit>
                <entity-autocomplete entity="pocs" attrName="nContaDe" [model]="contaDe" (selectedKeyChange)="header.nContaDe = $event"> </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'ativosimpressao.header.nContaAte'"></label>
              <edit>
                <entity-autocomplete entity="pocs" attrName="nContaAte" [model]="contaAte" (selectedKeyChange)="header.nContaAte = $event"> </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'ativosimpressao.header.nCCustoDe'"></label>
              <edit>
                <entity-autocomplete entity="ccustos" attrName="nCCustoDe" (selectedKeyChange)="header.nCCustoDe = $event" [output]="ccustoOutput" [filter]="'encerrado=0'"> </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'ativosimpressao.header.nCCustoAte'"></label>
              <edit>
                <entity-autocomplete entity="ccustos" attrName="nCCustoAte" [model]="cCustoAte" (selectedKeyChange)="header.nCCustoAte = $event" [output]="ccustoOutput" [filter]="'encerrado=0'">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'ativosimpressao.header.codAmortDe'"></label>
              <edit>
                <entity-autocomplete
                  entity="amortizacao"
                  attrName="codAmortDe"
                  [model]="amortizacaoDe"
                  (selectedKeyChange)="header.codAmortDe = $event"
                  [properties]="{validators: {maxlength: {value: 4}}}"
                  [output]="amortizacaoOutput">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'ativosimpressao.header.codAmortAte'"></label>
              <edit>
                <entity-autocomplete
                  entity="amortizacao"
                  attrName="codAmortAte"
                  [model]="amortizacaoAte"
                  (selectedKeyChange)="header.codAmortAte = $event"
                  [properties]="{validators: {maxlength: {value: 4}, required: {value: true}}}"
                  [output]="amortizacaoOutput">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'ativosmapasoficiais.header.nMapa'"></label>
              <edit>
                <pl-edit type="mapasOficiais" [model]="mapaamortizacaoDe" (modelChange)="header.codMapaDe = $event"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'ativosmapasoficiais.header.nMapa'"></label>
              <edit>
                <pl-edit type="mapasOficiais" [model]="mapaamortizacaoAte" (modelChange)="header.codMapaAte = $event"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <label [translate]="'ativosimpressao.header.datareferencia'"></label>
            <edit>
              <pl-edit type="date" [(model)]="header.dataReferencia" [properties]="{validators: {required: {value: true}}}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group [properties]="{disabled: !header.abatido && !header.vendido}">
            <pl-group>
              <label [translate]="'ativosimpressao.header.dataAbateDe'"></label>
              <edit>
                <pl-edit type="date" [(model)]="header.dataAbateDe" [properties]="{validators: {required: {value: true}}}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'ativosimpressao.header.dataAbateAte'"></label>
              <edit>
                <pl-edit type="date" [(model)]="header.dataAbateAte" [properties]="{validators: {required: {value: true}}, events: {keydown: fnKeydownPesquisar}}"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>
        </div>
      </pl-animation-collapse>
    </div>
  </pl-form>

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefenition"
    cgDxDataGridInstanceName="ativosImpressao"
    [dataSource]="ativosDepImpressao"
    (onInitialized)="onInitialized($event)"
    (onContentReady)="onContentReady()"
    (onContextMenuPreparing)="onDataGridContextMenuPreparing($event)">
    <div *dxTemplate="let headerInfo of 'headerTemplateSituacao'"><span [translate]="'ativosimpressao.table.sub.situacaoa'"></span> {{ header.dataReferencia | cgcDate }}</div>
  </dx-data-grid>
</div>
