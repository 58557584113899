import {Component, Injector} from '@angular/core';
import {downloadStream, IPlNavWizardCallback, IPlNavWizardDefinition, IPlNavWizardEventValidator, IPlUploadCallback, IPlUploadFile, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {HttpResponse} from '@angular/common/http';
import {focusElement} from '../../../../../common/utils/element.utils';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {DocumentosService} from '../../service/documentos.entity.service';
import {IDocsComerciaisImportadorLinhasExtraField, IDocsComerciaisImportadorLinhasImportResult} from './docsComerciais.importadorLinhas.interface';
import {EDocsComerciaisImportadorLinhasExtraField} from '../../service/documentos.entity.service.interface';
import {IJsonDocComercialLinha} from '../../jsonDocComercial.entity.interface';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';

const WIZARD_STEP_ID_WELCOME = 'welcome';
const WIZARD_STEP_ID_DOWNLOAD_MODEL = 'downloadmodel';
const WIZARD_STEP_ID_UPLOAD_MODEL = 'uploadmodel';
const WIZARD_STEP_ID_SUMMARY = 'resumo';

@Component({
  selector: 'docscomerciais-importador-linhas-modal',
  templateUrl: './docsComerciais.importadorLinhas.modal.component.html'
})
export class DocsComerciaisImportadorLinhasModalComponent extends CGModalComponent<Array<IJsonDocComercialLinha>> {
  public readonly navWizardDefinition: IPlNavWizardDefinition;
  public readonly navWizardCallback: IPlNavWizardCallback;
  public readonly uploadCallback: IPlUploadCallback;
  public readonly wizardStepIdWelcome: string;
  public readonly wizardStepIdDownloadModel: string;
  public readonly wizardStepIdUploadModel: string;
  public readonly wizardStepIdResumo: string;
  public textType: string;
  public uploadUrl: string;
  public uploadedFilledModel: boolean;
  public downloadedErrors: boolean;
  public uploadingFilledModel: boolean;
  public checkboxList: Array<IDocsComerciaisImportadorLinhasExtraField>;
  public importResult: IDocsComerciaisImportadorLinhasImportResult;
  public collapsedErrosList: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _documentosService: DocumentosService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);

    this.checkboxList = [
      {
        checked: false,
        value: EDocsComerciaisImportadorLinhasExtraField.NOMEARTIGO,
        label: this._plTranslateService.translate('docscomerciais.importadorLinhas.extraFields.nomeartigo')
      },
      {
        checked: false,
        value: EDocsComerciaisImportadorLinhasExtraField.CUNIMO,
        label: this._plTranslateService.translate('docscomerciais.importadorLinhas.extraFields.cunimo')
      },
      {
        checked: false,
        value: EDocsComerciaisImportadorLinhasExtraField.NARMAZ,
        label: this._plTranslateService.translate('docscomerciais.importadorLinhas.extraFields.narmazem')
      },
      {
        checked: false,
        value: EDocsComerciaisImportadorLinhasExtraField.NVENDEDOR,
        label: this._plTranslateService.translate('docscomerciais.importadorLinhas.extraFields.nvendedor')
      },
      {
        checked: false,
        value: EDocsComerciaisImportadorLinhasExtraField.NCCUSTO,
        label: this._plTranslateService.translate('docscomerciais.importadorLinhas.extraFields.nccusto')
      },
      {
        checked: false,
        value: EDocsComerciaisImportadorLinhasExtraField.NREFPROCESSO,
        label: this._plTranslateService.translate('docscomerciais.importadorLinhas.extraFields.nrefprocesso')
      }
    ];

    this.navWizardDefinition = {
      items: [],
      force: false
    };
    this.navWizardCallback = {};
    this.uploadCallback = {};
    this.wizardStepIdWelcome = WIZARD_STEP_ID_WELCOME;
    this.wizardStepIdDownloadModel = WIZARD_STEP_ID_DOWNLOAD_MODEL;
    this.wizardStepIdUploadModel = WIZARD_STEP_ID_UPLOAD_MODEL;
    this.wizardStepIdResumo = WIZARD_STEP_ID_SUMMARY;
    this._reset();
  }

  public onUploadedFilledModel(uploadFile: IPlUploadFile): void {
    this.uploadingFilledModel = false;
    const response: HttpResponse<IDocsComerciaisImportadorLinhasImportResult> = <HttpResponse<IDocsComerciaisImportadorLinhasImportResult>>uploadFile.upload.response;
    this.importResult = response.body;
    this.uploadedFilledModel = true;
    this.navWizardCallback.setStep(this.wizardStepIdResumo);
    this.uploadCallback.removeAllFiles();
  }

  public retry(): void {
    this.uploadCallback.removeAllFiles();
    this.uploadedFilledModel = false;
    this.downloadedErrors = false;
    this.importResult = {
      linhas: [],
      erros: []
    };
    this.navWizardCallback.setStep(this.wizardStepIdUploadModel);
  }

  public closeModal(): void {
    if (this.importResult.linhas.length > 0) {
      this._cgModalService
        .showOkCancel('docscomerciais.importadorLinhas.promptTitle', 'docscomerciais.importadorLinhas.promptMsg', {
          size: 'md',
          btnOkText: 'global.btn.yes',
          backdrop: 'static',
          keyboard: false
        })
        .then(() => {
          this.dismiss();
        });
    } else {
      this.dismiss();
    }
  }

  public readonly fnValidatorStepUploadModel = (): boolean => this._validatorStepUploadModel();

  public readonly fnValidatorStepAnalyzeErrors = (): boolean => this._validatorStepAnalyzeErrors();

  public readonly fnDownloadModel = (): Promise<void> => this._downloadModel();

  public readonly fnFinalize = (): void => {
    this.close(this.importResult.linhas);
  };

  public readonly fnValidateStepDownloadModel: (event: IPlNavWizardEventValidator) => boolean = () => this._validateStepDownloadModel();

  private _reset(): void {
    this.collapsedErrosList = true;
    this.textType = undefined;
    this.uploadUrl = undefined;
    this.uploadedFilledModel = false;
    this.downloadedErrors = false;
    this.uploadingFilledModel = false;
    this.importResult = {
      linhas: [],
      erros: []
    };
  }

  private _validatorStepUploadModel(): boolean {
    const valid: boolean = this.uploadedFilledModel;
    if (!valid) {
      this._plAlertService.error('components.genericImporter.errors.requiredUploadModel');
    }
    return valid;
  }

  private _validatorStepAnalyzeErrors(): boolean {
    return false;
  }

  private _downloadModel(): Promise<void> {
    return this._documentosService.getModeloImportacaoDocLinhas(this._getCheckedExtraFields()).then((response: HttpResponse<Blob>) => {
      downloadStream(response);
      this._focusBtnNext();
    });
  }

  private _focusBtnNext(): void {
    const btnNext: HTMLButtonElement = this._element.querySelector<HTMLButtonElement>('pl-nav-wizard .nav-wizard-item-content-wrapper.active .nav-wizard-item-footer .action-next-step');
    focusElement(btnNext);
  }

  private _getCheckedExtraFields(): Array<EDocsComerciaisImportadorLinhasExtraField> {
    const array: Array<EDocsComerciaisImportadorLinhasExtraField> = [];
    this.checkboxList.forEach((item) => {
      if (item.checked) {
        array.push(item.value);
      }
    });
    return array;
  }

  private _validateStepDownloadModel(): boolean {
    this._documentosService.importModeloDocLinhasUrl(this._getCheckedExtraFields()).subscribe((url: string) => {
      this.uploadUrl = url;
    });
    return true;
  }
}
