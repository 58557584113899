<div class="impctrirs entity-detail-form">
  <pl-form>
    <pl-group>
      <label [translate]="'impctrirs.fields.listagem'"></label>
      <edit>
        <pl-edit type="reports_imp_ctr_irs" attrName="listagem" [(model)]="report"></pl-edit>
      </edit>
    </pl-group>

    <div class="card mt-2">
      <div class="card-filters fw-bold pesquisa-avançada" (click)="collapsedPesqAvancada = !collapsedPesqAvancada">
        <button type="button" class="btn btn-link" [translate]="'impctrirs.filtropesq'"></button>
        <i class="fa fa-caret-right" aria-hidden="true" [plAnimationRotate]="!collapsedPesqAvancada"></i>
      </div>
      <pl-animation-collapse [collapsed]="collapsedPesqAvancada">
        <div class="card-body">
          <pl-group>
            <pl-group>
              <label [translate]="'impctrirs.fields.deData'"></label>
              <edit>
                <pl-edit type="date" [(model)]="filters.deData"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'impctrirs.fields.ateData'"></label>
              <edit>
                <pl-edit type="date" [(model)]="filters.ateData"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>
          <pl-group>
            <pl-group>
              <label [translate]="'impctrirs.fields.deCodEmp'"></label>
              <edit>
                <entity-autocomplete entity="dgempsfull" attrName="deCodEmp" [model]="filters" (selectedKeyChange)="filters.deCodEmp = $event" [fieldsMap]="{codEmp: 'deCodEmp'}" output="key">
                </entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'impctrirs.fields.ateCodEmp'"></label>
              <edit>
                <entity-autocomplete entity="dgempsfull" attrName="ateCodEmp" [model]="filters" (selectedKeyChange)="filters.ateCodEmp = $event" [fieldsMap]="{codEmp: 'ateCodEmp'}" output="key">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
        </div>
      </pl-animation-collapse>
    </div>

    <pl-multiselect [model]="empresas" [source]="empresasSource" [template]="empresasTemplate" key="nEmpresa"> </pl-multiselect>
  </pl-form>

  <hr />

  <cg-pdf-viewer *ngIf="showPdfViewer" [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>
