<div class="modal-header ativosimpressao-modal">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="'ativosimpressao.modal.ativoimpressaomodaltitle'"></h5>
  <pl-button klass="btn-success btn-sm action-processar" (evtClicked)="processar()">
    <i class="fa fa-fw fa-file-pdf-o"></i>&nbsp;<span [translate]="'ativosimpressao.btn.processar'"></span>
  </pl-button>
</div>

<div class="modal-body">
  <pl-form>
    <pl-group plAutoFocus>
      <label [translate]="'ativosimpressao.modal.listagem'"></label>
      <edit>
        <pl-edit type="reports_ativos" attrName="listagem" [(model)]="reportModel" [properties]="{events: {keydown: fnKeydownProcessar}}"></pl-edit>
      </edit>
    </pl-group>
  </pl-form>

  <br />

  <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-light action-dismiss" (evtClicked)="close()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'ativosimpressao.btn.ok'"></span></pl-button>
</div>
