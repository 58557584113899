import {merge} from 'lodash-es';
import {Component, Injector, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ValidatorFn} from '@angular/forms';
import type {IPlEditComponentOptionsInputRowSelect, TPlEditRowSelectList} from './edit.rowselect.component.interface';
import {isArray, isDefinedNotNull, isNumber, isObject} from '../../../common/utilities/utilities';
import {PlEditInputComponent} from '../../generic/input/edit.input.component';

const DEFAULT_START = 1;
const DEFAULT_END = 5;

@Component({
  selector: 'pl-row-select',
  templateUrl: './edit.rowselect.component.html'
})
export class PlRowSelectComponent extends PlEditInputComponent<any, IPlEditComponentOptionsInputRowSelect<any>> implements OnInit, OnChanges {
  @Input() public list: TPlEditRowSelectList;
  @Input() public start: number;
  @Input() public end: number;

  public source: TPlEditRowSelectList;
  public listKeys: Array<string>;
  public isArray: boolean;

  private _start: number;
  private _end: number;
  private _lastStart: number;
  private _lastEnd: number;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this._defaultOptions = Object.freeze<IPlEditComponentOptionsInputRowSelect>(
      merge({}, this._defaultOptions, {
        start: DEFAULT_START,
        end: DEFAULT_END,
        btnCSSClass: 'btn-secondary',
        spaced: false
      })
    );
    this.source = [];
    this.listKeys = [];
    this.isArray = true;
    this._start = DEFAULT_START;
    this._end = DEFAULT_END;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._handleChanges();
    this._buildList();
    this.setValidators(this._requiredValidator());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const {start, end, list, properties} = changes;
    const changedList: boolean = list && !list.isFirstChange();
    const changedStart: boolean = start && !start.isFirstChange();
    const changedEnd: boolean = end && !end.isFirstChange();
    const changedProperties: boolean = properties && !properties.isFirstChange();
    if (changedList || changedStart || changedEnd || changedProperties) {
      if (changedProperties) {
        this._handleChanges();
      } else {
        if (changedList) {
          this._changedList(list.currentValue);
        }
        if (changedStart) {
          this._lastStart = this._start;
          this._changedStart(start.currentValue);
        }
        if (changedEnd) {
          this._lastEnd = this._end;
          this._changedEnd(end.currentValue);
        }
      }
      this._buildList();
    }
  }

  public select(item: any): void {
    if (!this.options.readonly && !this.options.disabled) {
      this.value = item;
      this.render();
    }
  }

  public get sourceArray(): Array<unknown> {
    return <Array<unknown>>this.source;
  }

  public get sourceObject(): object {
    return <object>this.source;
  }

  private _handleChanges(): void {
    this._lastStart = this._start;
    this._lastEnd = this._end;
    this._changedList();
    this._changedStart();
    this._changedEnd();
  }

  private _changedList(value: TPlEditRowSelectList = this.list): void {
    let val: TPlEditRowSelectList = value;
    this.isArray = isArray(val);
    if (!this.isArray && !isObject(val)) {
      val = undefined;
    }
    this.source = val;
  }

  private _changedStart(value: number = this.start): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this.options.start;
    }
    if (!isNumber(val)) {
      val = DEFAULT_START;
    }
    this._start = val;
  }

  private _changedEnd(value: number = this.end): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this.options.end;
    }
    if (!isNumber(val)) {
      val = DEFAULT_END;
    }
    this._end = val;
  }

  private _buildList(): void {
    if (this._start !== this._lastStart || this._end !== this._lastEnd) {
      this._lastStart = this._start;
      this._lastEnd = this._end;
      this.source = undefined;
    }
    if (!this.source) {
      this.source = [];
      for (let i = this._start; i <= this.options.end; i++) {
        this.sourceArray.push(i);
      }
    }
    this.isArray = isArray(this.source);
    this.listKeys = !this.isArray && isObject(this.source) ? Object.keys(this.sourceObject) : [];
  }

  private _requiredValidator(): ValidatorFn {
    return () => {
      if (this.validate && this.options.validators.required?.value === true) {
        return isDefinedNotNull(this.value) ? undefined : {required: true};
      }
      return undefined;
    };
  }
}
