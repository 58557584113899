<div class="modal-planos-contas-alternativos-cubo modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'planoscontasalternativos.modals.cubo.title'"></h4>
</div>

<div class="modal-body">
  <pl-form>
    <cg-card-panel #cardPanelCubo="cgCardPanel">
      <div *cgCardPanelContent>
        <pl-group>
          <label [translate]="'balancetes.fields.planoAlt'"></label>
          <edit>
            <entity-autocomplete
              entity="planoscontasalternativos"
              attrName="pocAltCabID"
              [model]="filters"
              (selectedKeyChange)="filters.pocAltCabID = $event"
              (evtSelectedDescriptionChanged)="filters.nomePocAlt = $event"
              [fieldsMap]="{nome: 'nomePocAlt'}"
              [properties]="{validators: {required: {value: true}}}"
              plAutoFocus>
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'balancetes.fields.doPeriodo'"></label>
            <edit>
              <entity-autocomplete
                entity="periodos"
                attrName="doPeriodo"
                [model]="filters"
                (selectedKeyChange)="filters.dePeriodo = $event"
                [filter]="filterPeriodos"
                [fieldsMap]="{periodo: 'dePeriodo'}"
                output="key"
                [helperMode]="true">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'balancetes.fields.atePeriodo'"></label>
            <edit>
              <entity-autocomplete
                entity="periodos"
                attrName="atePeriodo"
                [model]="filters"
                (selectedKeyChange)="filters.atePeriodo = $event"
                [filter]="filterPeriodos"
                [fieldsMap]="{periodo: 'atePeriodo'}"
                output="key"
                [helperMode]="true">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'balancetes.fields.deConta'"></label>
            <edit>
              <entity-autocomplete
                entity="pocs"
                attrName="daConta"
                [selectedKey]="filters.deConta"
                [filter]="filterPocs"
                [helperMode]="true"
                (selectedKeyChange)="filters.deConta = $event"
                output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'balancetes.fields.ateConta'"></label>
            <edit>
              <entity-autocomplete
                entity="pocs"
                attrName="ateConta"
                [selectedKey]="filters.ateConta"
                [filter]="filterPocs"
                [helperMode]="true"
                (selectedKeyChange)="filters.ateConta = $event"
                [properties]="{events: {keydown: fnKeydownProcessar}}"
                output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group class="contabilidade-balancetes-esconde-contas">
          <edit>
            <pl-edit
              type="boolean"
              attrName="escondeRubricasSemMovimento"
              [model]="filters.escondeRubricasSemMovimento"
              (modelChange)="filters.escondeRubricasSemMovimento = $event"
              [properties]="{label: 'planoscontasalternativos.fields.escondeRubricasSemMovimento'}">
            </pl-edit>
          </edit>
        </pl-group>

        <pl-button klass="btn-primary btn-sm action-search" [onClick]="pesquisarCubo"> <i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span> </pl-button>
      </div>
    </cg-card-panel>

    <pl-group>
      <label [translate]="'planoscontasalternativos.modals.cubo.layoutCubo.caption'"></label>
      <edit>
        <pl-autocomplete attrName="cuboLayout" [source]="cuboLayoutSource" [model]="cuboLayout.caption" (modelChange)="cuboLayoutChanged($event)" rowTemplate="caption" output="caption">
        </pl-autocomplete>
      </edit>
    </pl-group>
  </pl-form>

  <dx-pivot-grid
    id="pivotgridPlanoContasAlt"
    [fieldChooser]="pivotGridDefinition.fieldChooser"
    [fieldPanel]="pivotGridDefinition.fieldPanel"
    [scrolling]="pivotGridDefinition.scrolling"
    [stateStoring]="pivotGridDefinition.stateStoring"
    [allowSortingBySummary]="pivotGridDefinition.allowSortingBySummary"
    [allowFiltering]="pivotGridDefinition.allowFiltering"
    [showBorders]="pivotGridDefinition.showBorders"
    [showRowGrandTotals]="pivotGridDefinition.showRowGrandTotals"
    [showRowTotals]="pivotGridDefinition.showRowTotals"
    [showColumnGrandTotals]="pivotGridDefinition.showColumnGrandTotals"
    [showColumnTotals]="pivotGridDefinition.showColumnTotals"
    [texts]="pivotGridDefinition.texts"
    [export]="pivotGridDefinition.export"
    [dataSource]="pivotGridDataSource"
    (onInitialized)="onInitializedPivotGrid($event)"
    (onContextMenuPreparing)="onContextMenuPreparingPivotGrid($event)"
    (onExporting)="onExporting($event)">
  </dx-pivot-grid>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
