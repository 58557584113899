<div class="ativosreavaliacoesnaolegais-modal">
  <pl-form>
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'ativosreavaliacoesnaolegais.title'"></h5>
    </div>

    <div class="modal-body">
      <pl-alert *ngIf="ativosReavaliacoesNaoLegais.jaHaDepEfetuadasNoAno" type="warning" [closeable]="false">
        <div [innerHTML]="'ativosreavaliacoesnaolegais.jahadepreciacoes' | translate"></div>
      </pl-alert>

      <label [translate]="subtitle"></label>

      <hr />

      <pl-group>
        <label [translate]="'ativosreavaliacoesnaolegais.fields.dataReavNaoLegal'"></label>
        <edit>
          <pl-edit type="date" attrName="dataReavNaoLegal" [(model)]="ativosReavaliacoesNaoLegais.dataReavNaoLegal" plAutoFocus></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'ativosreavaliacoesnaolegais.fields.vidaUtilMeses'"></label>
        <edit>
          <pl-edit type="cginteger" attrName="vidaUtilMeses" [(model)]="ativosReavaliacoesNaoLegais.vidaUtilMeses"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'ativosreavaliacoesnaolegais.fields.vidaUtilAnos'"></label>
        <edit>
          <pl-edit type="number" attrName="vidaUtilAnos" [(model)]="ativosReavaliacoesNaoLegais.vidaUtilAnos"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'ativosreavaliacoesnaolegais.fields.aumentoValor'"></label>
        <edit>
          <pl-edit type="number" attrName="aumentoValor" [(model)]="ativosReavaliacoesNaoLegais.aumentoValor"></pl-edit>
        </edit>
      </pl-group>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button klass="btn btn-success action-save" [onClick]="close" [disabled]="ativosReavaliacoesNaoLegais.jaHaDepEfetuadasNoAno" data-focus>
        <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span>
      </pl-button>

      <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
    </div>
  </pl-form>
</div>
