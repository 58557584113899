import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService} from '../../../../docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../../../../services/entity/entity.service.builder';
import {IDocsContasInventario} from '../../contabilidade.apuramentoResultados.module.interface';
import {IJsonDocContabilidade} from '../../../../docscontabilidade/jsonDocContabilidade.interface';
import {IDevExpressDataGrid} from '../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import ArrayStore from 'devextreme/data/array_store';
import {IDevExpressDataGridEventOnCellClick} from '../../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';

@Component({
  selector: 'modal-docs-contas-inventario',
  templateUrl: './docsContasInventario.modal.component.html'
})
export class DocsContasInventarioModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public docsContasInventario: Array<IDocsContasInventario>;
  @Input() public onlyContaInventario: boolean;
  public dataGridDefinition: IDevExpressDataGrid<IDocsContasInventario>;

  public title: string;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
  }

  public ngOnInit(): void {
    this.title = this.onlyContaInventario ? 'apuramentoresultados.modal.docscontasinventario.title' : 'apuramentoresultados.modal.docscontasinventario.titleIcons';
    const errorTitle = this.onlyContaInventario ? 'apuramentoresultados.modal.docscontasinventario.fields.docMovConta' : 'apuramentoresultados.modal.docscontasinventario.fields.inconsistenciasDocApu';

    this.dataGridDefinition = {
      columns: [
        {dataField: 'periodo', dataType: 'string', caption: 'apuramentoresultados.modal.docscontasinventario.fields.periodo'},
        {dataField: 'nDiario', dataType: 'number', caption: 'apuramentoresultados.modal.docscontasinventario.fields.nDiario'},
        {dataField: 'nDocinterno', dataType: 'string', caption: 'apuramentoresultados.modal.docscontasinventario.fields.nDocinterno'},
        {dataField: 'erro', dataType: 'string', caption: errorTitle}
      ],
      dataSource: new ArrayStore({
        key: 'extPocCabID',
        data: this.docsContasInventario
      }),
      height: '60vh',
      paging: {enabled: false},
      pager: {visible: false},
      scrolling: {rowRenderingMode: 'virtual'},
      masterDetail: {enabled: true, template: 'masterDetailTemplate'},
      remoteOperations: false
    };
  }

  public close(): void {
    super.close();
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IDocsContasInventario>): void {
    devExpressDataGridExpandDetailHandler(event, () => {
      return this._serviceDocsContabilidade.get({id: event.data.extPocCabID}).then((response: HttpResponse<IJsonDocContabilidade>) => {
        event.data._docContabilidade = response.body;
      });
    }).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }
}
