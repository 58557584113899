<div class="acrescimosencargosferias-config-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'acrescimosencargosferias.modals.config.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-tabs>
      <pl-tab caption="acrescimosencargosferias.modals.config.dadosProce">
        <div *plTabContent>
          <pl-group>
            <label [translate]="'acrescimosencargosferias.modals.config.anoEncargo'"></label>
            <edit>
              <pl-edit type="cginteger" attrName="anoEncargo" [(model)]="cabEncargos.anoEncargo" [properties]="{disabled: true}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'acrescimosencargosferias.modals.config.tipo'"></label>
            <edit>
              <pl-autocomplete
                attrName="tipodiferimento"
                [source]="tipoAcrescimoEncFeriasSource"
                [model]="cabEncargos.tipoDesc"
                (modelChange)="setTipoAcrescimoEncFeriasSource($event)"
                rowTemplate="nameMetodo"
                output="nameMetodo">
              </pl-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'acrescimosencargosferias.modals.config.percSeguradora'"></label>
            <edit>
              <pl-edit type="number" attrName="percSeguradora" [(model)]="cabEncargos.percSeguradora"></pl-edit>
            </edit>
          </pl-group>
        </div>
      </pl-tab>
      <pl-tab caption="acrescimosencargosferias.modals.config.intContab">
        <div *plTabContent>
          <pl-group>
            <pl-group>
              <label [translate]="'acrescimosencargosferias.modals.config.diario'"></label>
              <edit>
                <entity-autocomplete
                  entity="diarios"
                  attrName="diario"
                  [model]="configEncargos"
                  (selectedKeyChange)="configEncargos.diario = $event"
                  (evtSelectedDescriptionChanged)="configEncargos.diarioDesc = $event"
                  [properties]="{validators: {required: {value: false}}}"
                  [fieldsMap]="{nDiario: 'diario', nome: 'diarioDesc'}">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'acrescimosencargosferias.modals.config.descritivo'"></label>
              <edit>
                <entity-autocomplete
                  entity="descritivos"
                  attrName="descritivo"
                  [model]="configEncargos"
                  (selectedKeyChange)="configEncargos.descritivo = $event"
                  (evtSelectedDescriptionChanged)="configEncargos.descritivoDesc = $event"
                  [properties]="{validators: {required: {value: false}}}"
                  [fieldsMap]="{nDescrit: 'descritivo', nome: 'descritivoDesc'}">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <fieldset>
            <legend [translate]="'acrescimosencargosferias.modals.config.contasDebito'"></legend>
            <div>
              <pl-group>
                <pl-group>
                  <label [translate]="'acrescimosencargosferias.modals.config.pocSubFerias'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="pocDebitoSubFerias"
                      [model]="configEncargos"
                      (selectedKeyChange)="configEncargos.pocDebitoSubFerias = $event"
                      (evtSelectedDescriptionChanged)="configEncargos.pocDebitoSubFeriasDesc = $event"
                      [properties]="{validators: {required: {value: false}}}"
                      [fieldsMap]="{nConta: 'pocDebitoSubFerias', nome: 'pocDebitoSubFeriasDesc'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'acrescimosencargosferias.modals.config.pocFerias'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="pocDebitoFerias"
                      [model]="configEncargos"
                      (selectedKeyChange)="configEncargos.pocDebitoFerias = $event"
                      (evtSelectedDescriptionChanged)="configEncargos.pocDebitoFeriasDesc = $event"
                      [properties]="{validators: {required: {value: false}}}"
                      [fieldsMap]="{nConta: 'pocDebitoFerias', nome: 'pocDebitoFeriasDesc'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'acrescimosencargosferias.modals.config.pocEncargosSegSocial'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="pocDebitoEncargosSegSocial"
                      [model]="configEncargos"
                      (selectedKeyChange)="configEncargos.pocDebitoEncargosSegSocial = $event"
                      (evtSelectedDescriptionChanged)="configEncargos.pocDebitoEncargosSegSocialDesc = $event"
                      [properties]="{validators: {required: {value: false}}}"
                      [fieldsMap]="{nConta: 'pocDebitoEncargosSegSocial', nome: 'pocDebitoEncargosSegSocialDesc'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
                <pl-group>
                  <label [translate]="'acrescimosencargosferias.modals.config.pocEncargosActTrabalho'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="pocDebitoEncargosActTrabalho"
                      [model]="configEncargos"
                      (selectedKeyChange)="configEncargos.pocDebitoEncargosActTrabalho = $event"
                      (evtSelectedDescriptionChanged)="configEncargos.pocDebitoEncargosActTrabalhoDesc = $event"
                      [properties]="{validators: {required: {value: false}}}"
                      [fieldsMap]="{nConta: 'pocDebitoEncargosActTrabalho', nome: 'pocDebitoEncargosActTrabalhoDesc'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <label [translate]="'acrescimosencargosferias.modals.config.pocOutrosEncargos'"></label>
                <edit>
                  <entity-autocomplete
                    entity="pocs"
                    attrName="pocDebitoOutrosEncargos"
                    [model]="configEncargos"
                    (selectedKeyChange)="configEncargos.pocDebitoOutrosEncargos = $event"
                    (evtSelectedDescriptionChanged)="configEncargos.pocDebitoOutrosEncargosDesc = $event"
                    [properties]="{validators: {required: {value: false}}}"
                    [fieldsMap]="{nConta: 'pocDebitoOutrosEncargos', nome: 'pocDebitoOutrosEncargosDesc'}">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </div>
          </fieldset>

          <fieldset>
            <legend [translate]="'acrescimosencargosferias.modals.config.contasCredito'"></legend>
            <div>
              <pl-group>
                <pl-group>
                  <label [translate]="'acrescimosencargosferias.modals.config.pocSubFerias'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="pocCreditoSubFerias"
                      [model]="configEncargos"
                      (selectedKeyChange)="configEncargos.pocCreditoSubFerias = $event"
                      (evtSelectedDescriptionChanged)="configEncargos.pocCreditoSubFeriasDesc = $event"
                      [properties]="{validators: {required: {value: false}}}"
                      [fieldsMap]="{nConta: 'pocCreditoSubFerias', nome: 'pocCreditoSubFeriasDesc'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'acrescimosencargosferias.modals.config.pocFerias'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="pocCreditoFerias"
                      [model]="configEncargos"
                      (selectedKeyChange)="configEncargos.pocCreditoFerias = $event"
                      (evtSelectedDescriptionChanged)="configEncargos.pocCreditoFeriasDesc = $event"
                      [properties]="{validators: {required: {value: false}}}"
                      [fieldsMap]="{nConta: 'pocCreditoFerias', nome: 'pocCreditoFeriasDesc'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'acrescimosencargosferias.modals.config.pocEncargosSegSocial'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="pocCreditoEncargosSegSocial"
                      [model]="configEncargos"
                      (selectedKeyChange)="configEncargos.pocCreditoEncargosSegSocial = $event"
                      (evtSelectedDescriptionChanged)="configEncargos.pocCreditoEncargosSegSocialDesc = $event"
                      [properties]="{validators: {required: {value: false}}}"
                      [fieldsMap]="{nConta: 'pocCreditoEncargosSegSocial', nome: 'pocCreditoEncargosSegSocialDesc'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
                <pl-group>
                  <label [translate]="'acrescimosencargosferias.modals.config.pocEncargosActTrabalho'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="pocCreditoEncargosActTrabalho"
                      [model]="configEncargos"
                      (selectedKeyChange)="configEncargos.pocCreditoEncargosActTrabalho = $event"
                      (evtSelectedDescriptionChanged)="configEncargos.pocCreditoEncargosActTrabalhoDesc = $event"
                      [properties]="{validators: {required: {value: false}}}"
                      [fieldsMap]="{nConta: 'pocCreditoEncargosActTrabalho', nome: 'pocCreditoEncargosActTrabalhoDesc'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <label [translate]="'acrescimosencargosferias.modals.config.pocOutrosEncargos'"></label>
                <edit>
                  <entity-autocomplete
                    entity="pocs"
                    attrName="pocCreditoOutrosEncargos"
                    [model]="configEncargos"
                    (selectedKeyChange)="configEncargos.pocCreditoOutrosEncargos = $event"
                    (evtSelectedDescriptionChanged)="configEncargos.pocCreditoOutrosEncargosDesc = $event"
                    [properties]="{validators: {required: {value: false}}}"
                    [fieldsMap]="{nConta: 'pocCreditoOutrosEncargos', nome: 'pocCreditoOutrosEncargosDesc'}">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </div>
          </fieldset>
        </div>
      </pl-tab>
    </pl-tabs>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-dismiss" [disabled]="closeDisabled" [onClick]="close">
      <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span>
    </pl-button>

    <pl-button klass="btn-light btn-sm action-dismiss" [disabled]="closeDisabled" (evtClicked)="dismiss()"
      ><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
    </pl-button>
  </div>
</div>
