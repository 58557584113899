<div class="contabilidade-balancetes entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1">
        <pl-group *ngIf="altMode">
          <label [translate]="'balancetes.fields.planoAlt'"></label>
          <edit>
            <entity-autocomplete
              entity="planoscontasalternativos"
              attrName="pocAltCabID"
              [model]="filters"
              (selectedKeyChange)="filters.pocAltCabID = $event; filtersChanged()"
              (evtSelectedDescriptionChanged)="filters.nomePocAlt = $event"
              [fieldsMap]="{nome: 'nomePocAlt'}"
              plAutoFocus>
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'components.contabilidade.balancetes.list'"></label>
          <edit>
            <pl-edit [type]="reportType" attrName="listagem" [model]="report" (modelChange)="changedReport($event)"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'balancetes.fields.doPeriodo'"></label>
            <edit>
              <entity-autocomplete
                entity="periodos"
                attrName="doPeriodo"
                [model]="filters"
                (selectedKeyChange)="changedDePeriodo($event)"
                [filter]="filterPeriodos"
                [fieldsMap]="{nome: 'dePeriodoNome', periodo: 'dePeriodo'}"
                [output]="outputPeriodo"
                outputDescription="nome"
                [helperMode]="true"
                plAutoFocus
                [plAutoFocusDisabled]="altMode">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'balancetes.fields.atePeriodo'"></label>
            <edit>
              <entity-autocomplete
                entity="periodos"
                attrName="atePeriodo"
                [model]="filters"
                (selectedKeyChange)="changedAtePeriodo($event)"
                [filter]="filterPeriodos"
                [fieldsMap]="{nome: 'atePeriodoNome', periodo: 'atePeriodo'}"
                [output]="outputPeriodo"
                outputDescription="nome"
                [helperMode]="true">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'balancetes.fields.deConta'"></label>
            <edit>
              <entity-autocomplete
                entity="pocs"
                attrName="daConta"
                [selectedKey]="filters.deConta"
                [filter]="filterPocs"
                [helperMode]="true"
                (selectedKeyChange)="filters.deConta = $event; filtersChanged()"
                output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'balancetes.fields.ateConta'"></label>
            <edit>
              <entity-autocomplete
                entity="pocs"
                attrName="ateConta"
                [selectedKey]="filters.ateConta"
                [filter]="filterPocs"
                [helperMode]="true"
                (selectedKeyChange)="filters.ateConta = $event; filtersChanged()"
                [properties]="{events: {keydown: fnKeydownProcessar}}"
                output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group *ngIf="usaAnaliticaConfig && !altMode">
          <pl-group>
            <label [translate]="'balancetes.fields.deCCusto'"></label>
            <edit>
              <entity-autocomplete
                entity="ccustos"
                attrName="deCCusto"
                [selectedKey]="filters.deCCusto"
                (selectedKeyChange)="filters.deCCusto = $event; filtersChanged()"
                [helperMode]="true"
                output="key"
                [filter]="'encerrado=0'">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'balancetes.fields.ateCCusto'"></label>
            <edit>
              <entity-autocomplete
                entity="ccustos"
                attrName="ateCCusto"
                [selectedKey]="filters.ateCCusto"
                (selectedKeyChange)="filters.ateCCusto = $event; filtersChanged()"
                [helperMode]="true"
                output="key"
                [filter]="'encerrado=0'">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <div class="contabilidade-balancetes-inline">
            <pl-group class="contabilidade-balancetes-account-types">
              <label [translate]="'components.contabilidade.balancetes.accountTypes'"></label>
              <edit>
                <pl-edit
                  type="checkbox"
                  class="me-3"
                  attrName="tipoContaClasse"
                  [model]="filters.contasClasse"
                  (modelChange)="filters.contasClasse = $event; filtersChanged()"
                  [properties]="{label: 'tipoConta.data.class'}">
                </pl-edit>

                <pl-edit
                  type="checkbox"
                  class="me-3"
                  attrName="tipoContaRazao"
                  [model]="filters.contasRazao"
                  (modelChange)="filters.contasRazao = $event; filtersChanged()"
                  [properties]="{label: 'tipoConta.data.reason'}">
                </pl-edit>

                <pl-edit
                  type="checkbox"
                  class="me-3"
                  attrName="tipoContaSubTotal"
                  [model]="filters.contasSubTotal"
                  (modelChange)="filters.contasSubTotal = $event; filtersChanged()"
                  [properties]="{label: 'tipoConta.data.subtotal'}">
                </pl-edit>

                <pl-edit
                  type="checkbox"
                  class="me-3"
                  attrName="tipoContaMovimento"
                  [model]="filters.contasMovimento"
                  (modelChange)="filters.contasMovimento = $event; filtersChanged()"
                  [properties]="{label: 'tipoConta.data.movement'}">
                </pl-edit>

                <pl-edit
                  *ngIf="!altMode"
                  type="checkbox"
                  class="me-3"
                  attrName="tipoContaContaCorrente"
                  [model]="filters.contasCorrentes"
                  (modelChange)="filters.contasCorrentes = $event; filtersChanged()"
                  [properties]="{label: 'tipoConta.data.cc', disabled: !filters.contasMovimento}">
                </pl-edit>
              </edit>
            </pl-group>

            <pl-group class="contabilidade-balancetes-years-to-compare" *ngIf="!altMode">
              <label [translate]="'components.contabilidade.balancetes.yearsToCompare'"></label>
              <edit>
                <pl-select attrName="anosComparar" [model]="filters.anosComparativos" (modelChange)="anosCompararChanged($event)" [source]="anosSource" [multiple]="true"></pl-select>
              </edit>
            </pl-group>

            <pl-group class="contabilidade-balancetes-esconde-contas">
              <label></label>
              <edit>
                <pl-edit
                  type="boolean"
                  attrName="escondeContasSaldoZero"
                  [model]="filters.escodeContasSaldoZero"
                  (modelChange)="filters.escodeContasSaldoZero = $event; filtersChanged()"
                  [properties]="{label: 'components.contabilidade.balancetes.hideAccountsZeroBalance'}">
                </pl-edit>
              </edit>
            </pl-group>

            <pl-group class="contabilidade-balancetes-calcula-saldos" *ngIf="!altMode">
              <label></label>
              <edit>
                <pl-edit
                  type="boolean"
                  attrName="calculaSaldosIniciaisAnoNMaisUm"
                  [model]="filters.calculaSaldosIniciaisAnoNMaisUm"
                  (modelChange)="filters.calculaSaldosIniciaisAnoNMaisUm = $event; filtersChanged()"
                  [properties]="{
                    label:
                      ('components.contabilidade.balancetes.calculaSaldosIniciaisAnoNMaisUm'
                      | translate
                        : {
                            nextYear: configurations.empresa.anoEmCursoIRC + 1,
                            year: configurations.empresa.anoEmCursoIRC
                          })
                  }">
                </pl-edit>
              </edit>
            </pl-group>

            <pl-group class="contabilidade-balancetes-esconde-contas" *ngIf="altMode">
              <label></label>
              <edit>
                <pl-edit
                  type="boolean"
                  attrName="escondeRubricasSemMovimento"
                  [model]="filters.escondeRubricasSemMovimento"
                  (modelChange)="filters.escondeRubricasSemMovimento = $event; filtersChanged()"
                  [properties]="{label: 'planoscontasalternativos.fields.escondeRubricasSemMovimento'}">
                </pl-edit>
              </edit>
            </pl-group>
          </div>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <ng-container [ngSwitch]="isTableView">
    <div *ngSwitchDefault>
      <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
    </div>

    <div *ngSwitchCase="true">
      <ng-container>
        <balancetes-grid
          [balanceteComplList]="balanceteComplList"
          [dePeriodo]="filters.dePeriodo"
          [atePeriodo]="filters.atePeriodo"
          [pocAltCabID]="filters.pocAltCabID"
          [planoContasAlt]="altMode"></balancetes-grid>
      </ng-container>
    </div>
  </ng-container>
</div>
