<div class="modelo22-anexoa-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'modelo22.modal.anexoa.titles.' + newLineFor"></h5>
  </div>

  <div class="modal-body">
    <pl-form>
      <pl-group *ngIf="isC || isD">
        <label [translate]="'modelo22.modal.anexoa.primeiroAno'"></label>
        <edit>
          <pl-edit type="checkbox" attrName="primeiroAno" [(model)]="primeiroAno"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexoa.distmuni'"></label>
          <edit>
            <pl-edit type="modelo22distmuni" attrName="distrito" [(model)]="distrito"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'modelo22.modal.anexoa.taxaderra'"></label>
          <edit>
            <pl-edit type="number" attrName="taxaDerama" [(model)]="taxaDerama"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <label [translate]="'modelo22.modal.anexoa.massasal' + newLineFor"></label>
        <edit>
          <pl-edit type="number" attrName="massaSalarial" [(model)]="massaSalarial"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="isC || isD">
        <label [translate]="'modelo22.modal.anexoa.areainstexpl'"></label>
        <edit>
          <pl-edit type="number" attrName="areaInstalacao" [(model)]="areaInstalacao"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="isC">
        <pl-group>
          <label [translate]="'modelo22.modal.anexoa.potencia'"></label>
          <edit>
            <pl-edit type="number" attrName="potencia" [(model)]="potencia"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'modelo22.modal.anexoa.totalProduzido'"></label>
          <edit>
            <pl-edit type="number" attrName="totalProduzido" [(model)]="totalProduzido"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="isD">
        <label [translate]="'modelo22.modal.anexoa.producao'"></label>
        <edit>
          <pl-edit type="number" attrName="producao" [(model)]="producao"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'modelo22.modal.anexoa.racio' + newLineFor"></label>
        <edit>
          <pl-edit type="number" attrName="racio" [(model)]="racio"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'modelo22.modal.anexoa.derrama' + newLineFor"></label>
        <edit>
          <pl-edit type="number" attrName="derrama" [(model)]="derrama"></pl-edit>
        </edit>
      </pl-group>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span> </pl-button>

    <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
