<div class="ativosaquisicaocontab entity-detail-form">
  <div class="card">
    <div class="card-body" *ngIf="cab.contaFornecedor">
      <div class="header">
        <div class="d-flex">
          <div class="ativosaquisicaocontab-header-line">
            <label [translate]="'ativosaquisicaocontab.header.fornecedor'"></label>
            <p class="card-text">{{ cab.contaFornecedor }}</p>
          </div>
          <div class="ativosaquisicaocontab-header-line">
            <label [translate]="'ativosaquisicaocontab.header.total'"></label>
            <p class="card-text">{{ cab.valorFornecedor | cgcNumber }}</p>
          </div>
        </div>

        <hr />

        <div class="d-flex">
          <div class="ativosaquisicaocontab-header-line">
            <label [translate]="'ativosaquisicaocontab.header.totalIva'"></label>
            <p class="card-text">{{ cab.totalIVA | cgcNumber }}</p>
          </div>
          <div class="ativosaquisicaocontab-header-line">
            <label [translate]="'ativosaquisicaocontab.header.valorAssociado'"></label>
            <p class="card-text">{{ cab.jaAssociado | cgcNumber }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <cg-card-panel [caption]="'ativosaquisicaocontab.half.contasAquisicao'">
    <div *cgCardPanelContent>
      <dx-data-grid
        [cgDxDataGrid]="dataGridDefinitionContasAquisicao"
        cgDxDataGridInstanceName="contasAquisicaoContab"
        [dataSource]="dataGridDefinitionContasAquisicao.dataSource"
        (onInitialized)="onInitializedContasAquisicao($event)"
        (onContentReady)="onContentReadyContasAquisicao()"
        (onCellClick)="onCellClickContasAquisicao($event)">
        <div *dxTemplate="let item of 'detailTemplateDocContabilidade'">
          <split-viewer-doccontab-digital
            [(docContabilidade)]="item.data._docContabilidade"
            [contabDigitalDoc]="item.data._contabDigitalDoc"
            [initialSizeLeft]="60"
            [showToolbar]="true"
            [maintenanceMode]="true"
            [readonlyDigital]="true">
          </split-viewer-doccontab-digital>
        </div>

        <div *dxTemplate="let item of 'cellTemplateBtnsContasAquisicao'">
          <pl-tooltip [config]="{text: 'ativosaquisicaocontab.tooltip.removedoc', tooltipClass: 'tooltip-danger', placement: 'left'}">
            <button type="button" *plTooltipContent class="btn btn-danger btn-xs" [click]="fnRemoveDocumento(item.data)" plPromise>
              <i class="fa fa-fw fa-minus"></i>
            </button>
          </pl-tooltip>

          <pl-tooltip
            [config]="{
              text: item.data.valor === item.data.valorCalculado2 ? 'ativosaquisicaocontab.tooltip.valorEsgotado' : 'ativosaquisicaocontab.tooltip.novoativo',
              placement: 'left',
              tooltipClass: 'tooltip-success'
            }">
            <button type="button" *plTooltipContent [disabled]="item.data.valor === item.data.valorCalculado2" class="btn btn-success btn-xs" [click]="fnNovoAtivo(item.data)" plPromise>
              <i class="fa fa-fw fa-plus"></i>
            </button>
          </pl-tooltip>
        </div>
      </dx-data-grid>
    </div>
  </cg-card-panel>

  <cg-card-panel [caption]="'ativosaquisicaocontab.half.ativos'">
    <div *cgCardPanelContent>
      <dx-data-grid
        [cgDxDataGrid]="dataGridDefinitionAtivos"
        cgDxDataGridInstanceName="ativosAquisicaoContabAtivos"
        [dataSource]="dataGridDefinitionAtivos.dataSource"
        (onInitialized)="onInitializedAtivos($event)"
        (onContentReady)="onContentReadyAtivos()">
        <div *dxTemplate="let item of 'cellTemplateBtnsAtivos'">
          <pl-tooltip [config]="{text: 'ativosaquisicaocontab.tooltip.removeAtivo', tooltipClass: 'tooltip-danger', placement: 'left'}">
            <button type="button" *plTooltipContent class="btn btn-danger btn-xs" [click]="fnRemoveAtivo(item.data)" plPromise>
              <i class="fa fa-fw fa-minus"></i>
            </button>
          </pl-tooltip>
        </div>
      </dx-data-grid>
    </div>
  </cg-card-panel>
</div>
