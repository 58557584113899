import {Directive, TemplateRef} from '@angular/core';

@Directive({
  selector: 'ng-template[plSplitViewLeftSide]'
})
export class PlSplitViewLeftSideDirective {
  constructor(
    public readonly templateRef: TemplateRef<void>
  ) {
  }
}
