export const MODULE_NAME_RH_ESTATISTICAS_MULTI = 'rhestatisticasmulti';

export interface IJsonRHEstatisticasMultiItem {
  nEmpresa: string;
  nome: string;
  totalAbonos: number;
  totalDescontos: number;
  totalBruto: number;
  totalImpostosColaborador: number;
  totalSalarios: number;
  totalEncargos: number;
  totalCustos: number;
}

export interface IRHEstatisticasMultiFilters {
  ano: number;
  mes: number;
}

export interface IRHEstatisticasEmpresaSimpleItem {
  nEmpresa: string;
  nomeEmpresa: string;
}

export interface IJsonRHEstatisticasUserConfig {
  selectedEmpresas: Array<IRHEstatisticasEmpresaSimpleItem>;
  allowedEmpresas: Array<IRHEstatisticasEmpresaSimpleItem>;
}
