import {Directive, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {isEmpty} from 'pl-comps-angular';
import {IDevExpressDataGridColumn, IDevExpressDataGridSummary} from '../../devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';

@Directive()
export abstract class MovimentosPendentesBaseComponent implements OnInit {
  @Input() public fields: string;
  @Input() public nConta: string;
  @Input() public search: string;
  @Input() public searchFields: string;
  @Input() public showDocExterno: boolean;
  @Output() public readonly evtDataGridInstance: EventEmitter<dxDataGrid>;

  public readonly dataGridColumns: Array<IDevExpressDataGridColumn>;
  public readonly dataGridSummary: IDevExpressDataGridSummary;

  constructor() {
    this.showDocExterno = false;
    this.dataGridColumns = this._buildDefinition();
    this.dataGridSummary = this._buildGridSummary();
    this.evtDataGridInstance = new EventEmitter<dxDataGrid>();
  }

  public ngOnInit(): void {
    if (!isEmpty(this.fields)) {
      this.searchFields = this.fields;
    }
  }

  protected abstract _buildDefinition(): Array<IDevExpressDataGridColumn>;
  protected abstract _buildGridSummary(): IDevExpressDataGridSummary;

  protected abstract _listFields(): string;
}
