<pl-form class="encomendas-lotes-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()" [disabled]="closeDisabled"></button>
    <h4 class="modal-title" [translate]="'encomendas.modal.lotes.title'"></h4>
  </div>

  <div class="modal-body">
    <dx-data-grid
      cgDxDataGridInstanceName="gridLinhasComLotes"
      [cgDxDataGrid]="dataGridLinhasDefinition"
      [dataSource]="dataGridLinhasDefinition.dataSource"
      (onEditingStart)="onGridLinhasEditorStart($event)"
      (onSelectionChanged)="onGridLinhasSelectionChanged($event)">
      <div *dxTemplate="let item of 'templateBtnToolbar'">
        <pl-button klass="btn-sm btn-warning action-preenche-auto-lotes" (evtClicked)="preencheAutomaticamenteLotes()">
          <i class="fa fa-list-ul"></i>&nbsp;<span [translate]="'encomendas.modal.btns.preencherAuto'"></span>
        </pl-button>
      </div>
    </dx-data-grid>
    <hr />
    <dx-data-grid
      cgDxDataGridInstanceName="gridSelecaoLotes"
      #gridLotes
      [cgDxDataGrid]="dataGridLotesDefinition"
      [dataSource]="dataGridLotesDefinition.dataSource"
      (onInitialized)="onGridLotesInitialized($event)"
      (onContentReady)="onGridLotesContentReady()"
      (onCellPrepared)="onGridLotesCellPrepared($event)"
      (onFocusedCellChanged)="onGridLotesFocusedCellChanged($event)">
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation>
    <pl-button klass="btn-sm btn-success action-close" (evtClicked)="close()"> <i class="fa fa-cog"></i>&nbsp;<span [translate]="'encomendas.btn.initProce'"></span> </pl-button>

    <button type="button" class="btn btn-sm btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</pl-form>
