import type {Subscription} from 'rxjs';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import moment, {Moment, MomentInput} from 'moment';
import {DATE_MAXIMUM_YEAR, DATE_MINIMUM_YEAR} from '../common/constants';
import {
  EPlDatepickerKind,
  EPlDatepickerState,
  IPlDatepickerDay,
  IPlDatepickerDecade,
  IPlDatepickerMonth,
  IPlDatepickerYear,
  TPlDatepickerEvaluateDateFn,
  TPlDatepickerEvaluatedDates
} from './datepicker.component.interface';
import {ERadix} from '../common/enums';
import type {IPlLocale} from '../common/locale/locales.interface';
import {isArray, isBoolean, isDefinedNotNull, isFunction, isObject, isUndefinedOrNull} from '../common/utilities/utilities';
import {isMoment, normalizeDate} from '../common/dates/moment.utils';
import {PlLocaleService} from '../common/locale/locale.service';
import {PlDatepickerToken} from './datepicker.token';

const NUM_MONTHS = 12;
const TRUNC_MILLENNIUM = 1000;
const TRUNC_CENTURY = 100;
const TRUNC_DECADE = 10;
const DECADE_YEAR_START_END_OFFSET = 9;
const YEAR_STR_LENGTH = 4;

@Component({
  selector: 'pl-datepicker',
  templateUrl: './datepicker.component.html',
  providers: [{provide: PlDatepickerToken, useExisting: PlDatepickerComponent}],
  exportAs: 'cgcDatepicker'
})
export class PlDatepickerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public model: MomentInput;
  @Input() public kind: EPlDatepickerKind;
  @Input() public state: EPlDatepickerState;
  @Input() public disabledDates: TPlDatepickerEvaluatedDates;
  @Input() public markedDates: TPlDatepickerEvaluatedDates;
  @Input() public minimumDate: MomentInput;
  @Input() public maximumDate: MomentInput;
  @Input() public dateDisabled: TPlDatepickerEvaluateDateFn;
  @Input() public dateMarked: TPlDatepickerEvaluateDateFn;
  @Input() public showActions: boolean;
  @Input() public showActionToday: boolean;
  @Output() public readonly modelChange: EventEmitter<MomentInput>;
  @Output() public readonly stateChange: EventEmitter<EPlDatepickerState>;

  public readonly kinds: typeof EPlDatepickerKind;
  public readonly states: typeof EPlDatepickerState;
  public dayNamesShort: ReadonlyArray<string>;
  public dayNames: ReadonlyArray<string>;
  public locale: IPlLocale;
  public decades: Array<IPlDatepickerDecade>;
  public years: Array<IPlDatepickerYear>;
  public months: Array<IPlDatepickerMonth>;
  public days: Array<IPlDatepickerDay>;
  public formattedContext: string;
  public formattedToday: string;
  public allowActionToday: boolean;

  public titleDoublePreviousContext: string;
  public titlePreviousContext: string;
  public titleNextContext: string;
  public titleDoubleNextContext: string;
  public titleContext: string;

  private readonly _todayDate: Moment;
  private readonly _disabledDates: Map<string, boolean>;
  private readonly _markedDates: Map<string, boolean>;
  private readonly _subscriptionLocale: Subscription;
  private _modelDate: Moment;
  private _context: Moment;
  private _hasMinimumDate: boolean;
  private _hasMaximumDate: boolean;
  private _minimumDate: Moment;
  private _maximumDate: Moment;

  constructor(private readonly _plLocaleService: PlLocaleService) {
    this.modelChange = new EventEmitter<MomentInput>();
    this.stateChange = new EventEmitter<EPlDatepickerState>();
    this.kinds = EPlDatepickerKind;
    this.states = EPlDatepickerState;
    this.dayNamesShort = [];
    this.dayNames = [];
    this.allowActionToday = true;
    this._todayDate = this._moment();
    this._disabledDates = new Map<string, boolean>();
    this._markedDates = new Map<string, boolean>();
    this._hasMinimumDate = false;
    this._hasMaximumDate = false;
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this.dayNamesShort = Object.freeze(moment.weekdaysShort(true));
      this.dayNames = Object.freeze(moment.weekdays(true));
      this.locale = locale;
      this.formattedToday = this._moment().format(`D [${this.locale.text.of}] MMMM [${this.locale.text.of}] YYYY`);
    });
  }

  public ngOnInit(): void {
    this._handleChanges();
    this.generateCalendar();
  }

  public ngOnChanges({model, kind, state, disabledDates, markedDates, minimumDate, maximumDate, dateDisabled, dateMarked, showActions, showActionToday}: SimpleChanges): void {
    const changedModel = model && !model.isFirstChange();
    const changedKind = kind && !kind.isFirstChange();
    const changedState = state && !state.isFirstChange();
    const changedDisabledDates = disabledDates && !disabledDates.isFirstChange();
    const changedMarkedDates = markedDates && !markedDates.isFirstChange();
    const changedMinimumDate = minimumDate && !minimumDate.isFirstChange();
    const changedMaximumDate = maximumDate && !maximumDate.isFirstChange();
    const changedDateDisabled = dateDisabled && !dateDisabled.isFirstChange();
    const changedDateMarked = dateMarked && !dateMarked.isFirstChange();
    if (changedModel || changedKind || changedState || changedDisabledDates || changedMarkedDates || changedMinimumDate || changedMaximumDate || changedDateDisabled || changedDateMarked) {
      if (changedKind) {
        this._changedKind(kind.currentValue);
      }
      if (changedState) {
        this._changedState(state.currentValue);
      }
      if (changedDisabledDates) {
        this._changedDisabledDates(disabledDates.currentValue);
      }
      if (changedMarkedDates) {
        this._changedMarkedDates(markedDates.currentValue);
      }
      if (changedDateDisabled) {
        this._changedDateDisabled(dateDisabled.currentValue);
      }
      if (changedDateMarked) {
        this._changedDateMarked(dateMarked.currentValue);
      }
      if (changedMinimumDate || changedMaximumDate) {
        if (changedMinimumDate) {
          this._changedMinimumDate(minimumDate.currentValue);
        }
        if (changedMaximumDate) {
          this._changedMaximumDate(maximumDate.currentValue);
        }
        this._evaluateAllowActionToday();
      }
      if (changedModel) {
        this._changedModel(model.currentValue);
      }
      this.generateCalendar();
    }
    if (showActions && !showActions.isFirstChange()) {
      this._changedShowActions(showActions.currentValue);
    }
    if (showActionToday && !showActionToday.isFirstChange()) {
      this._changedShowActionToday(showActionToday.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionLocale.unsubscribe();
  }

  public selectDecade(decade: IPlDatepickerDecade): void {
    if (decade.disabled) {
      return;
    }
    this._context.year(decade.startYear);
    this._setState(EPlDatepickerState.Year);
    this.generateCalendar();
  }

  public selectYear(year: IPlDatepickerYear): void {
    if (year.disabled) {
      return;
    }
    if (this.kind === EPlDatepickerKind.Year) {
      this._modelDate = this._moment(this._modelDate.year(year.year).startOf('year'));
      this._render();
    } else {
      this._context.year(year.year);
      this._setState(EPlDatepickerState.Month);
      this.generateCalendar();
    }
  }

  public selectMonth(month: IPlDatepickerMonth): void {
    if (month.disabled) {
      return;
    }
    if (this.kind === EPlDatepickerKind.Month) {
      this._modelDate = this._moment(this._modelDate.year(month.year).month(month.month).startOf('month'));
      this._render();
    } else {
      this._context.year(month.year).month(month.month);
      this._setState(EPlDatepickerState.Day);
      this.generateCalendar();
    }
  }

  public selectDay(day: IPlDatepickerDay): void {
    if (day.disabled) {
      return;
    }
    if (this.kind === EPlDatepickerKind.Date) {
      this._render(day.date);
    } else {
      this._context.year(day.year).month(day.month).date(day.day);
      this._setState(EPlDatepickerState.Day);
      this.generateCalendar();
    }
  }

  public toggleContext(): void {
    switch (this.state) {
      case EPlDatepickerState.Decade:
        break;
      case EPlDatepickerState.Year:
        this._setState(EPlDatepickerState.Decade);
        this.generateCalendar();
        break;
      case EPlDatepickerState.Month:
        this._setState(EPlDatepickerState.Year);
        this.generateCalendar();
        break;
      case EPlDatepickerState.Day:
        this._setState(EPlDatepickerState.Month);
        this.generateCalendar();
        break;
    }
  }

  public doublePreviousContext(): void {
    switch (this.state) {
      case EPlDatepickerState.Decade:
        this.previousMillennium();
        break;
      case EPlDatepickerState.Year:
        this.previousCentury();
        break;
      case EPlDatepickerState.Month:
        this.previousDecade();
        break;
      case EPlDatepickerState.Day:
        this.previousYear();
        break;
    }
  }

  public previousContext(): void {
    switch (this.state) {
      case EPlDatepickerState.Decade:
        this.previousCentury();
        break;
      case EPlDatepickerState.Year:
        this.previousDecade();
        break;
      case EPlDatepickerState.Month:
        this.previousYear();
        break;
      case EPlDatepickerState.Day:
        this.previousMonth();
        break;
    }
  }

  public nextContext(): void {
    switch (this.state) {
      case EPlDatepickerState.Decade:
        this.nextCentury();
        break;
      case EPlDatepickerState.Year:
        this.nextDecade();
        break;
      case EPlDatepickerState.Month:
        this.nextYear();
        break;
      case EPlDatepickerState.Day:
        this.nextMonth();
        break;
    }
  }

  public doubleNextContext(): void {
    switch (this.state) {
      case EPlDatepickerState.Decade:
        this.nextMillennium();
        break;
      case EPlDatepickerState.Year:
        this.nextCentury();
        break;
      case EPlDatepickerState.Month:
        this.nextDecade();
        break;
      case EPlDatepickerState.Day:
        this.nextYear();
        break;
    }
  }

  public previousMillennium(): void {
    this._contextSubtractYears(TRUNC_MILLENNIUM);
    this.generateCalendar();
  }

  public nextMillennium(): void {
    this._contextAddYears(TRUNC_MILLENNIUM);
    this.generateCalendar();
  }

  public previousCentury(): void {
    this._contextSubtractYears(TRUNC_CENTURY);
    this.generateCalendar();
  }

  public nextCentury(): void {
    this._contextAddYears(TRUNC_CENTURY);
    this.generateCalendar();
  }

  public previousDecade(): void {
    this._contextSubtractYears(TRUNC_DECADE);
    this.generateCalendar();
  }

  public nextDecade(): void {
    this._contextAddYears(TRUNC_DECADE);
    this.generateCalendar();
  }

  public previousYear(): void {
    this._contextSubtractYears(1);
    this.generateCalendar();
  }

  public nextYear(): void {
    this._contextAddYears(1);
    this.generateCalendar();
  }

  public previousMonth(): void {
    this._context.subtract(1, 'month');
    this.generateCalendar();
  }

  public nextMonth(): void {
    this._context.add(1, 'month');
    this.generateCalendar();
  }

  public today(): void {
    this._context = this._moment();
    switch (this.kind) {
      case EPlDatepickerKind.Year:
        this._setState(EPlDatepickerState.Year);
        break;
      case EPlDatepickerKind.Month:
        this._setState(EPlDatepickerState.Month);
        break;
      case EPlDatepickerKind.Date:
        this._setState(EPlDatepickerState.Day);
        break;
    }
    this._render(this._context);
  }

  public generateCalendar(context: MomentInput = this._context): void {
    context = this._moment(context);

    const contextMonth: number = context.month();
    const contextYear: number = context.year();

    const modelDate: number = this._modelDate.date();
    const modelMonth: number = this._modelDate.month();
    const modelYear: number = this._modelDate.year();

    const todayDate: number = this._todayDate.date();
    const todayMonth: number = this._todayDate.month();
    const todayYear: number = this._todayDate.year();

    let yearStart: number;
    let yearEnd: number;
    switch (this.state) {
      case EPlDatepickerState.Decade:
        yearStart = Number(`${Math.trunc(contextYear / TRUNC_CENTURY)}00`);
        yearEnd = Number(`${Math.trunc(contextYear / TRUNC_CENTURY)}99`);
        this.formattedContext = context.format(`${this._ensureYearDigits(yearStart)}-${this._ensureYearDigits(yearEnd)}`);
        this.decades = [];
        const decades: number = Math.max(0, Math.ceil((yearEnd - yearStart) / TRUNC_DECADE));
        if (decades === 0) {
          break;
        }
        for (let i = 1; i <= decades; i++) {
          yearEnd = yearStart + DECADE_YEAR_START_END_OFFSET;
          this.decades.push({
            startYear: yearStart,
            endYear: yearEnd,
            decadeStr: `${this._ensureYearDigits(yearStart)}-${this._ensureYearDigits(yearEnd)}`,
            current: yearStart <= todayYear && yearEnd >= todayYear,
            selected: yearStart <= modelYear && yearEnd >= modelYear,
            disabled: (this._hasMinimumDate && yearEnd < this._minimumDate.year()) || (this._hasMaximumDate && yearStart > this._maximumDate.year())
          });
          yearStart += TRUNC_DECADE;
        }
        break;
      case EPlDatepickerState.Year:
        yearStart = Number(`${Math.trunc(contextYear / TRUNC_DECADE)}0`);
        yearEnd = Number(`${Math.trunc(contextYear / TRUNC_DECADE)}9`);
        this.formattedContext = context.format(`${this._ensureYearDigits(yearStart)}-${this._ensureYearDigits(yearEnd)}`);
        if (yearStart > DATE_MINIMUM_YEAR) {
          yearStart--;
        }
        if (yearEnd < DATE_MAXIMUM_YEAR) {
          yearEnd++;
        }
        this.years = [];
        for (let year = yearStart; year <= yearEnd; year++) {
          this.years.push({
            year: year,
            yearStr: this._ensureYearDigits(year),
            current: year === todayYear,
            selected: year === modelYear,
            disabled: (this._hasMinimumDate && year < this._minimumDate.year()) || (this._hasMaximumDate && year > this._maximumDate.year())
          });
        }
        break;
      case EPlDatepickerState.Month:
        this.formattedContext = context.format('YYYY');
        this.months = [];
        for (let month = 0; month < NUM_MONTHS; month++) {
          const monthDate: Moment = context.clone().month(month);
          this.months.push({
            month: month,
            monthStr: monthDate.format('MMMM'),
            monthStrShort: monthDate.format('MMM'),
            year: contextYear,
            current: month === todayMonth && contextYear === todayYear,
            selected: month === modelMonth && contextYear === modelYear,
            disabled: (this._hasMinimumDate && monthDate.isBefore(this._minimumDate, 'month')) || (this._hasMaximumDate && monthDate.isAfter(this._maximumDate, 'month'))
          });
        }
        break;
      case EPlDatepickerState.Day:
        this.formattedContext = context.format('MMMM YYYY');

        const monthFirstDay: number = 1 - context.clone().startOf('month').weekday();
        const monthLastDay: number = context.clone().endOf('month').date();

        this.days = [];
        for (let day = monthFirstDay; day <= monthLastDay; day++) {
          const newDay: IPlDatepickerDay = {
            date: undefined,
            day: undefined,
            month: undefined,
            year: undefined,
            current: false,
            selected: false,
            disabled: true,
            marked: false
          };
          if (day > 0) {
            newDay.day = day;
            newDay.month = contextMonth + 1;
            newDay.year = contextYear;
            newDay.current = day === todayDate && contextMonth === todayMonth && contextYear === todayYear;
            newDay.selected = day === modelDate && contextMonth === modelMonth && contextYear === modelYear;
            newDay.disabled = false;
            newDay.date = this._dayToDate(newDay);
          }
          this._evaluateDay(newDay);
          this.days.push(newDay);
        }

        break;
    }
  }

  private _handleChanges(): void {
    this._changedKind();
    this._changedState();
    this._changedDisabledDates();
    this._changedMarkedDates();
    this._changedMinimumDate();
    this._changedMaximumDate();
    this._changedDateDisabled();
    this._changedDateMarked();
    this._changedShowActions();
    this._changedShowActionToday();
    this._changedModel();
    this._evaluateAllowActionToday();
  }

  private _changedModel(value: MomentInput = this.model): void {
    this._context = value || value === 0 ? this._moment(value) : this._moment();
    if (!this._context.isValid()) {
      this._context = this._todayDate.clone();
    }
    this._modelDate = this._context.clone();
  }

  private _changedKind(value: EPlDatepickerKind = this.kind): void {
    let kind: EPlDatepickerKind = value;
    if (!kind || (kind !== EPlDatepickerKind.Year && kind !== EPlDatepickerKind.Month && kind !== EPlDatepickerKind.Date)) {
      kind = EPlDatepickerKind.Date;
    }
    this.kind = kind;
  }

  private _changedState(value: EPlDatepickerState = this.state): void {
    let state: EPlDatepickerState = value;
    if (!state || (state !== EPlDatepickerState.Decade && state !== EPlDatepickerState.Year && state !== EPlDatepickerState.Month && state !== EPlDatepickerState.Day)) {
      state = EPlDatepickerState.Day;
    }
    this._setState(state, false);
  }

  private _changedDisabledDates(value: TPlDatepickerEvaluatedDates = this.disabledDates): void {
    this.disabledDates = value;
    this._parseEvaluatedDates(this._disabledDates, this.disabledDates);
  }

  private _changedMarkedDates(value: TPlDatepickerEvaluatedDates = this.markedDates): void {
    this.markedDates = value;
    this._parseEvaluatedDates(this._markedDates, this.markedDates);
  }

  private _changedMinimumDate(value: MomentInput = this.minimumDate): void {
    this.minimumDate = value;
    this._hasMinimumDate = Boolean(this.minimumDate || this.minimumDate === 0);
    this._minimumDate = this._hasMinimumDate ? this._moment(this.minimumDate) : undefined;
  }

  private _changedMaximumDate(value: MomentInput = this.maximumDate): void {
    this.maximumDate = value;
    this._hasMaximumDate = Boolean(this.maximumDate || this.maximumDate === 0);
    this._maximumDate = this._hasMaximumDate ? this._moment(this.maximumDate) : undefined;
  }

  private _changedDateDisabled(value: TPlDatepickerEvaluateDateFn = this.dateDisabled): void {
    this.dateDisabled = value;
    if (!isFunction(this.dateDisabled)) {
      this.dateDisabled = undefined;
    }
  }

  private _changedDateMarked(value: TPlDatepickerEvaluateDateFn = this.dateMarked): void {
    this.dateMarked = value;
    if (!isFunction(this.dateMarked)) {
      this.dateMarked = undefined;
    }
  }

  private _changedShowActions(value: boolean = this.showActions): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = true;
    }
    this.showActions = val;
  }

  private _changedShowActionToday(value: boolean = this.showActionToday): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = true;
    }
    this.showActionToday = val;
  }

  private _setState(state: EPlDatepickerState, emit: boolean = true): void {
    switch (this.kind) {
      case EPlDatepickerKind.Year:
        if (state === EPlDatepickerState.Month || state === EPlDatepickerState.Day) {
          state = EPlDatepickerState.Year;
        }
        break;
      case EPlDatepickerKind.Month:
        if (state === EPlDatepickerState.Day) {
          state = EPlDatepickerState.Month;
        }
        break;
      case EPlDatepickerKind.Date:
        // Doesn't need self correct
        break;
    }
    this.state = state;
    this._evaluateTitles();
    if (emit) {
      this.stateChange.emit(this.state);
    }
  }

  private _render(date?: Moment): void {
    if (isDefinedNotNull(date)) {
      this._modelDate = date.clone();
    }
    this.model = this._modelDate.toISOString();
    this.modelChange.emit(this.model);
  }

  private _parseEvaluatedDates(dates: Map<string, boolean>, evaluatedDates: TPlDatepickerEvaluatedDates): void {
    dates.clear();
    if (isUndefinedOrNull(evaluatedDates)) {
      return;
    }
    if (isMoment(evaluatedDates) && evaluatedDates.isValid()) {
      dates.set(this._moment(evaluatedDates).toISOString(), true);
    } else if (evaluatedDates instanceof Set) {
      this._parseEvaluatedDates(dates, Array.from(evaluatedDates));
    } else if (isArray(evaluatedDates)) {
      for (const date of evaluatedDates) {
        const momentDate = this._moment(date);
        if (momentDate.isValid()) {
          dates.set(momentDate.toISOString(), true);
        }
      }
    } else if (isObject(evaluatedDates)) {
      for (const key of Object.keys(<object>evaluatedDates)) {
        const date: Moment = this._moment(key);
        if (date.isValid()) {
          const value: boolean = evaluatedDates[key];
          if (isBoolean(value)) {
            dates.set(key, value);
          }
        }
      }
    }
  }

  private _evaluateDay(day: IPlDatepickerDay): void {
    if (day.disabled) {
      return;
    }
    day.disabled = this._isDateDisabled(day.date);
    day.marked = this._isDateMarked(day.date);
  }

  private _evaluateTitles(): void {
    switch (this.state) {
      case EPlDatepickerState.Decade:
        this.titleDoublePreviousContext = this.locale.datepicker.previousMillennium;
        this.titlePreviousContext = this.locale.datepicker.previousCentury;
        this.titleNextContext = this.locale.datepicker.nextCentury;
        this.titleDoubleNextContext = this.locale.datepicker.nextMillennium;
        this.titleContext = '';
        break;
      case EPlDatepickerState.Year:
        this.titleDoublePreviousContext = this.locale.datepicker.previousCentury;
        this.titlePreviousContext = this.locale.datepicker.previousDecade;
        this.titleNextContext = this.locale.datepicker.nextDecade;
        this.titleDoubleNextContext = this.locale.datepicker.nextCentury;
        this.titleContext = this.locale.datepicker.toggleContext;
        break;
      case EPlDatepickerState.Month:
        this.titleDoublePreviousContext = this.locale.datepicker.previousDecade;
        this.titlePreviousContext = this.locale.datepicker.previousYear;
        this.titleNextContext = this.locale.datepicker.nextYear;
        this.titleDoubleNextContext = this.locale.datepicker.nextDecade;
        this.titleContext = this.locale.datepicker.toggleContext;
        break;
      case EPlDatepickerState.Day:
        this.titleDoublePreviousContext = this.locale.datepicker.previousYear;
        this.titlePreviousContext = this.locale.datepicker.previousMonth;
        this.titleNextContext = this.locale.datepicker.nextMonth;
        this.titleDoubleNextContext = this.locale.datepicker.nextYear;
        this.titleContext = this.locale.datepicker.toggleContext;
        break;
    }
  }

  private _evaluateAllowActionToday(): void {
    this.allowActionToday = (!this._hasMinimumDate || this._todayDate.isSameOrAfter(this._minimumDate, 'date')) && (!this._hasMaximumDate || this._todayDate.isSameOrBefore(this._maximumDate, 'date'));
  }

  private _isDateDisabled(date: Moment): boolean {
    date = date.clone();
    const normalizedDate = date.toISOString();
    let disabled: boolean;
    if (isFunction(this.dateDisabled)) {
      disabled = this.dateDisabled(date, normalizedDate);
    }
    if (!isBoolean(disabled)) {
      disabled = this._disabledDates.get(normalizedDate);
    }
    if (!isBoolean(disabled) && (this._hasMinimumDate || this._hasMaximumDate)) {
      if (this._hasMinimumDate) {
        disabled = date.isBefore(this._minimumDate, 'date');
      } else {
        disabled = date.isAfter(this._maximumDate, 'date');
      }
    }
    if (!isBoolean(disabled)) {
      disabled = false;
    }
    return disabled;
  }

  private _isDateMarked(date: Moment): boolean {
    date = date.clone();
    const normalizedDate = date.toISOString();
    let marked: boolean;
    if (isFunction(this.dateMarked)) {
      marked = this.dateMarked(date, normalizedDate);
    }
    if (!isBoolean(marked)) {
      marked = this._markedDates.get(normalizedDate);
    }
    if (!isBoolean(marked)) {
      marked = false;
    }
    return marked;
  }

  private _dayToDate(day: IPlDatepickerDay): Moment {
    return this._moment(moment(`${day.day}/${day.month}/${day.year}`, 'DD/MM/YYYY'));
  }

  private _moment(momentInput?: MomentInput): Moment {
    return normalizeDate(momentInput);
  }

  private _contextAddYears(years: number): void {
    this._context.add(years, 'year');
    if (this._context.year() > DATE_MAXIMUM_YEAR) {
      this._context.year(DATE_MAXIMUM_YEAR);
    }
  }

  private _contextSubtractYears(years: number): void {
    this._context.subtract(years, 'year');
    if (this._context.year() < DATE_MINIMUM_YEAR) {
      this._context.year(DATE_MINIMUM_YEAR);
    }
  }

  private _ensureYearDigits(year: number): string {
    let yearStr = year.toString(ERadix.Decimal);
    while (yearStr.length < YEAR_STR_LENGTH) {
      yearStr = `0${yearStr}`;
    }
    return yearStr;
  }
}
