import {Component, Input} from '@angular/core';
import {animate, query, style, transition, trigger} from '@angular/animations';

const animationDuration = '{{duration}}';

@Component({
  selector: 'pl-animation-collapse',
  templateUrl: './animation.collapse.component.html',
  animations: [
    trigger('collapse', [
      transition('* => *', [
        // each time the binding value changes
        query(':leave', [style({overflow: 'hidden'}), animate(animationDuration, style({height: '0'}))], {optional: true}),
        query(':enter', [style({height: '0', overflow: 'hidden'}), animate(animationDuration, style({height: '*'}))], {optional: true})
      ])
    ])
  ]
})
export class PlAnimationCollapseComponent {
  @Input() public collapsed: boolean;
  @Input() public animationDuration: string | number;

  constructor() {
    this.collapsed = false;
    this.animationDuration = '100ms';
  }
}
