<div class="cg-dashboards">
  <div class="row">
    <div class="col-md-6 cg-dashboards-spacing">
      <div class="row">
        <div class="col-12">
          <div class="card p-3">
            <dx-chart
              [cgDxChart]="chartVendasComprasTotais"
              [dataSource]="chartVendasComprasTotaisData"
              (onInitialized)="onInitializedChartVendasComprasTotais($event)"
              (onPointHoverChanged)="onChartPointHoverChanged($event)"
              (onLegendClick)="onChartLegendClick($event)">
              <div *dxTemplate="let item of 'customTooltip'">
                <div>
                  <b>{{ item.argument }}</b>
                </div>
                <div *ngFor="let itemPoint of item.points">
                  <span>{{ itemPoint.seriesName }}: </span>
                  <span>{{ itemPoint.originalValue | cgcNumber }}</span>
                </div>
              </div>
            </dx-chart>
          </div>
        </div>
      </div>

      <div class="row cg-dashboards-spacing">
        <div class="col-sm-12 col-xl-4">
          <pl-dashboard [dashboard]="vendasTotaisDia"></pl-dashboard>
        </div>

        <div class="col-sm-12 col-xl-4">
          <pl-dashboard [dashboard]="vendasTotaisMes"></pl-dashboard>
        </div>

        <div class="col-sm-12 col-xl-4">
          <pl-dashboard [dashboard]="vendasTotaisAno"></pl-dashboard>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card p-3">
            <dx-pie-chart [cgDxPieChart]="chartPieVendas" [dataSource]="chartPieVendasData" (onInitialized)="onInitializedChartPieVendas($event)"> </dx-pie-chart>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <pl-dashboard class="cg-dashboards-table" [dashboard]="vendasTotaisAnoTop"></pl-dashboard>
        </div>
      </div>
    </div>

    <div class="col-md-6 cg-dashboards-spacing">
      <div class="row">
        <div class="col-12">
          <div class="card p-3">
            <dx-chart
              [cgDxChart]="chartClientes"
              [dataSource]="chartClientesData"
              (onInitialized)="onInitializedChartClientes($event)"
              (onPointHoverChanged)="onChartPointHoverChanged($event)"
              (onLegendClick)="onChartLegendClick($event)">
              <div *dxTemplate="let item of 'customTooltip'">
                <div>
                  <b>{{ item.argument }}</b>
                </div>
                <div *ngFor="let itemPoint of item.points">
                  <span>{{ itemPoint.seriesName }}: </span>
                  <span>{{ itemPoint.originalValue | cgcNumber }}</span>
                </div>
              </div>
            </dx-chart>
          </div>
        </div>
      </div>

      <div class="row cg-dashboards-spacing">
        <div class="col-sm-12 col-xl-4">
          <pl-dashboard [dashboard]="clientesNovosDia"></pl-dashboard>
        </div>

        <div class="col-sm-12 col-xl-4">
          <pl-dashboard [dashboard]="clientesNovosMes"></pl-dashboard>
        </div>

        <div class="col-sm-12 col-xl-4">
          <pl-dashboard [dashboard]="clientesNovosAno"></pl-dashboard>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card p-3">
            <dx-pie-chart [cgDxPieChart]="chartPieClientes" [dataSource]="chartPieClientesData" (onInitialized)="onInitializedChartPieClientes($event)"> </dx-pie-chart>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <pl-dashboard class="cg-dashboards-table table-success" [dashboard]="clientesTopVendas"></pl-dashboard>
        </div>
      </div>
    </div>
  </div>
</div>
