import type {Subscription} from 'rxjs';
import {Component, ContentChild, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, TemplateRef} from '@angular/core';
import {copyArrayItem, isArray, isBoolean, isObject, isUndefinedOrNull, transferArrayItem} from '../common/utilities/utilities';
import {
  IPlUITreeCallback,
  IPlUITreeDrag,
  IPlUITreeDragContext,
  IPlUITreeDragEvtRemoveNode,
  IPlUITreeDragInstance,
  IPlUITreeDragInternal,
  IPlUITreeDragInternalNode,
  IPlUITreeDragNode,
  IPlUITreeDragNodeContext,
  IPlUITreeDragNodeInstance,
  IPlUITreeDragPreventableEvtNode
} from './uitreedrag.interface';
import {PlUITreeDragInstancesService} from './uitreedrag.instances.service';
import {PlUITreeDragNodeContentDirective} from './uitreedrag.node.content.directive';

const PERCENTAGE_HALF = 50;

@Component({
  selector: 'pl-ui-tree-drag',
  templateUrl: './uitreedrag.component.html'
})
export class PlUITreeDragComponent implements OnChanges, OnDestroy, IPlUITreeDragInstance<any> {
  @Input() public uiTree: IPlUITreeDrag<any>;
  @Input() public disabled: boolean;
  @Input() public noDrop: boolean;
  @Input() public cloneEnabled: boolean;
  @Input() public maxLevels: number;
  @Input() public templateContent: TemplateRef<IPlUITreeDragNodeContext<any>>;
  @Input() public callback: IPlUITreeCallback;
  @Output() public readonly uiTreeChange: EventEmitter<IPlUITreeDrag<any>>;
  @Output() public readonly evtNodeChanged: EventEmitter<IPlUITreeDragPreventableEvtNode<any>>;
  @Output() public readonly evtNodeMoved: EventEmitter<IPlUITreeDragPreventableEvtNode<any>>;
  @Output() public readonly evtNodeCloned: EventEmitter<IPlUITreeDragPreventableEvtNode<any>>;
  @Output() public readonly evtCanceled: EventEmitter<IPlUITreeDragPreventableEvtNode<any>>;
  @Output() public readonly evtDropped: EventEmitter<IPlUITreeDragPreventableEvtNode<any>>;

  @ContentChild(PlUITreeDragNodeContentDirective, {read: TemplateRef}) public readonly templateContentDirective: TemplateRef<IPlUITreeDragNodeContext<any>>;
  public internalUITree: Array<IPlUITreeDragInternal>;

  private readonly _subscriptionContext: Subscription;
  private _context: IPlUITreeDragContext;

  constructor(public readonly elementRef: ElementRef<HTMLElement>, private readonly _plUITreeDragInstancesService: PlUITreeDragInstancesService) {
    this.uiTreeChange = new EventEmitter<IPlUITreeDrag<any>>();
    this.evtNodeChanged = new EventEmitter<IPlUITreeDragPreventableEvtNode<any>>();
    this.evtNodeMoved = new EventEmitter<IPlUITreeDragPreventableEvtNode<any>>();
    this.evtNodeCloned = new EventEmitter<IPlUITreeDragPreventableEvtNode<any>>();
    this.evtCanceled = new EventEmitter<IPlUITreeDragPreventableEvtNode<any>>();
    this.evtDropped = new EventEmitter<IPlUITreeDragPreventableEvtNode<any>>();
    this.uiTree = {items: []};
    this.disabled = false;
    this.noDrop = false;
    this.cloneEnabled = false;
    this.maxLevels = 0;
    this.internalUITree = [];
    this._plUITreeDragInstancesService.registerInstance(this);
    this._subscriptionContext = this._plUITreeDragInstancesService.draggingContext().subscribe((context: IPlUITreeDragContext) => {
      this._context = context;
    });
  }

  public ngOnChanges({uiTree, callback}: SimpleChanges): void {
    if (uiTree) {
      const uiTreeData: IPlUITreeDrag = uiTree.currentValue;
      if (isObject(uiTreeData) && isArray(uiTreeData.items)) {
        this.internalUITree = this._loadMenuUiTree(uiTreeData.items);
      } else if (this.internalUITree.length) {
        this.internalUITree = [];
      }
    }
    if (callback) {
      const cb: IPlUITreeCallback = callback.currentValue;
      if (isObject(cb)) {
        cb.menuChanged = () => {
          this.menuChanged();
        };
        cb.removeNode = (nodeInstance: IPlUITreeDragNodeInstance) => {
          this.removeNodeInstance(nodeInstance);
        };
      }
    }
  }

  public ngOnDestroy(): void {
    this._plUITreeDragInstancesService.deRegisterInstance(this);
    this._subscriptionContext.unsubscribe();
  }

  public menuChanged(): void {
    this.uiTree.items = this._updateMenu(this.internalUITree);
    this.uiTreeChange.emit(this.uiTree);
  }

  public dragStart(event: MouseEvent, sourceArray: Array<IPlUITreeDragInternalNode>, index: number): void {
    event.stopImmediatePropagation();
    (<any>event).dataTransfer.effectAllowed = 'all';

    // Init control variables
    let target: HTMLElement = <HTMLElement>event.target;
    if (target.matches('.pl-ui-tree-node')) {
      target = target.querySelector<HTMLElement>('.pl-ui-tree-node-content');
    }
    const targetItem: IPlUITreeDragInternalNode = sourceArray[index];
    this._plUITreeDragInstancesService.startDrag({
      dragging: true,
      dragOriginalElement: target,
      dragOriginalIndex: index,
      dragOriginalArray: sourceArray,
      dragOriginalNodeItem: targetItem,
      dragOriginalInstance: this,
      dragCurrentIndex: index,
      dragCurrentArray: sourceArray,
      dragCurrentNodeItem: targetItem,
      dragCurrentInstance: this,
      dragPreviousIndex: index,
      dragPreviousArray: sourceArray,
      dragPreviousClientY: undefined,
      deepLevelMenu: this._deepestLevelAtMenuMoved(targetItem),
      clonedNodeItem: false
    });
    targetItem._cssClass = 'pl-ui-tree-node-placeholder';
  }

  public dragOver(event: MouseEvent, targetArray: Array<IPlUITreeDragInternalNode>, index: number): void {
    if (!this._context.dragging) {
      return;
    }
    event.preventDefault();
    event.stopImmediatePropagation();
    this._plUITreeDragInstancesService.updateDrag({dragCurrentInstance: this});

    const dataTransfer: any = (<any>event).dataTransfer;
    if (this.noDrop) {
      dataTransfer.dropEffect = 'none';
      return;
    }
    dataTransfer.dropEffect = this._context.dragOriginalInstance.cloneEnabled && !this._context.clonedNodeItem ? 'copy' : 'move';

    let element: HTMLElement = <HTMLElement>event.target;
    if (element.matches('.pl-ui-tree-nodes')) {
      return;
    }
    if (!element.matches('.pl-ui-tree-node-content')) {
      element = element.closest('.pl-ui-tree-node-content');
    }
    if (element === this._context.dragOriginalElement) {
      return;
    }

    if (this._context.dragPreviousArray[this._context.dragPreviousIndex]._isMenu) {
      const flattenArray = this._flattenNodeItems(this._context.dragCurrentNodeItem.items);
      if (flattenArray.includes(targetArray[index])) {
        return;
      }
    }

    if (targetArray[index]._isMenu && targetArray[index] === this._context.dragCurrentArray[this._context.dragCurrentIndex]) {
      return;
    }

    if (!this._context.dragPreviousClientY || this._context.dragPreviousClientY === event.clientY) {
      this._plUITreeDragInstancesService.updateDrag({dragPreviousClientY: event.clientY});
      return;
    }

    let doTransferArrayItem = false;
    const directionUpwards: boolean = this._context.dragPreviousClientY > event.clientY;
    this._plUITreeDragInstancesService.updateDrag({dragPreviousClientY: event.clientY});

    const elementRect: DOMRect = element.getBoundingClientRect();
    const offsetY: number = !directionUpwards ? event.clientY - elementRect.top : (event.clientY - elementRect.bottom) * -1;
    if (offsetY > 0) {
      const cursorVerticalPosition: number = Math.ceil((100 * offsetY) / elementRect.height);
      const cursorHalfWayElement: boolean = cursorVerticalPosition > PERCENTAGE_HALF;

      if (cursorHalfWayElement) {
        let addItemToHeadMenu =
          !directionUpwards &&
          targetArray[index] !== this._context.dragPreviousArray[this._context.dragPreviousIndex] &&
          targetArray[index]._isMenu &&
          isArray(targetArray[index].items) &&
          !targetArray[index].collapsed;

        const finalLevel = !directionUpwards ? targetArray[index]._level + this._context.deepLevelMenu : targetArray[index]._level - 1 + this._context.deepLevelMenu;
        if (
          isUndefinedOrNull(targetArray[index]._level) ||
          (this.maxLevels > 0 && (addItemToHeadMenu || targetArray[index]._level !== this._context.dragPreviousArray[this._context.dragPreviousIndex]._level) && finalLevel > this.maxLevels)
        ) {
          if (!targetArray[index]._isMenu || targetArray[index].items.length > 0) {
            return;
          }
          addItemToHeadMenu = false;
        }

        const dragCurrentIndex = !directionUpwards ? (addItemToHeadMenu ? 0 : index) : index;

        const changedArray: boolean = addItemToHeadMenu ? true : targetArray !== this._context.dragPreviousArray;
        if (changedArray || dragCurrentIndex !== this._context.dragCurrentIndex) {
          this._plUITreeDragInstancesService.updateDrag({
            dragCurrentIndex: dragCurrentIndex,
            dragCurrentArray: addItemToHeadMenu ? targetArray[index].items : targetArray
          });

          this._context.dragPreviousArray[this._context.dragPreviousIndex]._level = addItemToHeadMenu
            ? targetArray[index]._level + 1
            : this._context.dragCurrentArray[this._context.dragCurrentIndex]._level;

          if (this._context.dragPreviousArray[this._context.dragPreviousIndex]._isMenu && this._context.dragPreviousArray[this._context.dragPreviousIndex].items.length > 0) {
            this._updateItemSubLevels(this._context.dragPreviousArray[this._context.dragPreviousIndex].items, this._context.dragPreviousArray[this._context.dragPreviousIndex]._level);
          }

          doTransferArrayItem = true;
        }
      }

      if (doTransferArrayItem) {
        let calledPreventDefault = false;
        const evtNode: IPlUITreeDragPreventableEvtNode = {
          ...this._plUITreeDragInstancesService.generateEvtNode(),
          preventDefault: () => {
            calledPreventDefault = true;
          }
        };

        if (!this._context.dragOriginalInstance.cloneEnabled || this._context.clonedNodeItem) {
          transferArrayItem(this._context.dragPreviousArray, this._context.dragCurrentArray, this._context.dragPreviousIndex, this._context.dragCurrentIndex);
          this.evtNodeMoved.emit(evtNode);
        } else {
          copyArrayItem(this._context.dragPreviousArray, this._context.dragCurrentArray, this._context.dragPreviousIndex, this._context.dragCurrentIndex, true);
          this._context.dragCurrentNodeItem._cssClass = '';
          this._plUITreeDragInstancesService.updateDrag({
            dragCurrentNodeItem: this._context.dragCurrentArray[this._context.dragCurrentIndex],
            clonedNodeItem: true
          });
          this.evtNodeCloned.emit(evtNode);
        }

        if (!calledPreventDefault) {
          this.evtNodeChanged.emit(evtNode);
        }
        if (calledPreventDefault) {
          this._plUITreeDragInstancesService.cancelDrag();
        } else {
          this._plUITreeDragInstancesService.updateDrag({
            dragPreviousArray: this._context.dragCurrentArray,
            dragPreviousIndex: this._context.dragCurrentIndex,
            dragPreviousClientY: undefined
          });
        }
      }
    }
  }

  public removeNode({parentArray, nodeIndex}: IPlUITreeDragEvtRemoveNode): void {
    parentArray.splice(nodeIndex, 1);
    this.menuChanged();
  }

  public removeNodeInstance(nodeInstance: IPlUITreeDragNodeInstance): void {
    nodeInstance.removeNode();
  }

  private _deepestLevelAtMenuMoved(dragItem: IPlUITreeDragInternalNode, level = 1): number {
    if (isArray(dragItem.items) && dragItem.items.length > 0) {
      level += 1;
      for (const dragOriginalItemElement of dragItem.items) {
        if (dragOriginalItemElement._isMenu && dragOriginalItemElement.items.length > 0) {
          level = this._deepestLevelAtMenuMoved(dragOriginalItemElement, level);
        }
      }
    }
    return level;
  }

  private _loadMenuUiTree(menus: Array<IPlUITreeDragNode>, level: number = 1): Array<IPlUITreeDragInternalNode> {
    const menuUiTree: Array<IPlUITreeDragInternalNode> = [];
    for (const menu of menus) {
      const isMenu: boolean = isArray(menu.items);
      menuUiTree.push({
        items: isMenu ? this._loadMenuUiTree(menu.items, level + 1) : undefined,
        meta: menu.meta,
        collapsed: isBoolean(menu.collapsed) ? menu.collapsed : !isMenu,
        _level: level,
        _isMenu: isMenu,
        _cssClass: ''
      });
    }
    return menuUiTree;
  }

  private _updateMenu(menuUiTree: Array<IPlUITreeDragInternalNode>): Array<IPlUITreeDragNode> {
    const newMenu: Array<IPlUITreeDragNode> = [];
    for (const menuUI of menuUiTree) {
      newMenu.push({
        title: menuUI.title,
        items: isArray(menuUI.items) ? this._updateMenu(menuUI.items) : undefined,
        collapsed: menuUI.collapsed,
        meta: menuUI.meta
      });
    }
    return newMenu;
  }

  private _updateItemSubLevels(itemMenu: Array<IPlUITreeDragInternalNode>, level: number): void {
    for (const item of itemMenu) {
      item._level = level + 1;
      if (item._isMenu && item.items.length > 0) {
        this._updateItemSubLevels(item.items, item._level);
      }
    }
  }

  private _flattenNodeItems(nodeItems: Array<IPlUITreeDragInternalNode>): Array<IPlUITreeDragInternalNode> {
    const result: Array<IPlUITreeDragInternalNode> = [];
    const flatten = (items: Array<IPlUITreeDragInternalNode>): void => {
      for (const item of items) {
        result.push(item);
        if (isArray(item.items)) {
          flatten(item.items);
        }
      }
    };
    flatten(nodeItems);
    return result;
  }
}
