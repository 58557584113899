import {AfterContentChecked, ContentChildren, Directive, Input, QueryList} from '@angular/core';
import {PlNavPillPanelContentDirective} from './navpill.panel.content.directive';
import {PlNavPillPanelTitleDirective} from './navpill.panel.title.directive';

let nextId = 0;

@Directive({
  selector: 'pl-navpill-panel'
})
export class PlNavPillPanelDirective implements AfterContentChecked {
  @Input() public id: any;
  @Input() public caption: string;
  @Input() public captionClass: string;
  @Input() public disabled: boolean;

  public templateTitle: PlNavPillPanelTitleDirective;
  public templateContent: PlNavPillPanelContentDirective;

  @ContentChildren(PlNavPillPanelTitleDirective, {descendants: false}) private readonly _templateTitle: QueryList<PlNavPillPanelTitleDirective>;
  @ContentChildren(PlNavPillPanelContentDirective, {descendants: false}) private readonly _templateContent: QueryList<PlNavPillPanelContentDirective>;

  constructor() {
    this.id = `pl-navpill-panel-${nextId++}`;
  }

  public ngAfterContentChecked(): void {
    this.templateTitle = this._templateTitle.first;
    this.templateContent = this._templateContent.first;
  }
}
