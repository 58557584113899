import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_ESTADO_CIVIL} from './estadoCivil.datasource.interface';
import {EEstadoCivil} from '../../../modules/portalrh/dgempsfull/jsonDGEMPFull.interface';
import {IDataSource} from '../../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_ESTADO_CIVIL: IDataSource<EEstadoCivil> = deepFreeze({
  name: DATA_SOURCE_NAME_ESTADO_CIVIL,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EEstadoCivil.None, name: 'dgemps.civilStatus.0', label: 'dgemps.civilStatus.0'},
    {value: EEstadoCivil.Casado, name: 'dgemps.civilStatus.1', label: 'dgemps.civilStatus.1'},
    {value: EEstadoCivil.Solteiro, name: 'dgemps.civilStatus.2', label: 'dgemps.civilStatus.2'},
    {value: EEstadoCivil.Divorciado, name: 'dgemps.civilStatus.3', label: 'dgemps.civilStatus.3'},
    {value: EEstadoCivil.Viuvo, name: 'dgemps.civilStatus.4', label: 'dgemps.civilStatus.4'},
    {value: EEstadoCivil.UniaoFacto, name: 'dgemps.civilStatus.5', label: 'dgemps.civilStatus.5'}
  ]
});
