import {merge} from 'lodash-es';
import {fromEvent, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import moment, {Moment, MomentInput} from 'moment';
import {Component, ElementRef, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormControl, ValidatorFn} from '@angular/forms';
import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import type {IPlEditComponentOptionsInputDropdown} from '../../generic/input/edit.input.dropdown.interface';
import type {IPlFormatConfig} from '../../../common/format/format.service.interface';
import type {IPlLocale, IPlLocaleText} from '../../../common/locale/locales.interface';
import {isDefined, isEmpty, isFunction} from '../../../common/utilities/utilities';
import {KEYCODES} from '../../../common/constants';
import {next, prev} from '../../../common/utilities/dom.utilities';
import {PlEditInputDropdownComponent} from '../../generic/input/edit.input.dropdown.component';
import {PlFormatService} from '../../../common/format/format.service';
import {PlLocaleService} from '../../../common/locale/locale.service';

const NGB_SELECTOR_CONTAINER = '.ngb-tp-input-container';

@Component({
  selector: 'pl-edit-timepicker',
  templateUrl: './edit.timepicker.component.html'
})
export class PlEditTimePickerComponent extends PlEditInputDropdownComponent<MomentInput> implements OnInit, OnChanges, OnDestroy {
  @Input() public format: string;

  public locales: IPlLocaleText;
  public timeControl: UntypedFormControl;

  private readonly _subscriptionFormat: Subscription;
  private readonly _subscriptionLocale: Subscription;
  private _format: IPlFormatConfig;
  private _btnCloseTimer: HTMLButtonElement;
  private _subscriptionTimerChanges: Subscription;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plLocaleService: PlLocaleService,
    private readonly _plFormatService: PlFormatService
  ) {
    super(_injector);
    this._defaultOptions = Object.freeze<IPlEditComponentOptionsInputDropdown<string>>(
      merge({}, this._defaultOptions, {
        appendToBody: true
      })
    );
    this.timeControl = new UntypedFormControl();
    this._subscriptionFormat = this._plFormatService.format.subscribe((format: IPlFormatConfig) => {
      this._format = format;
    });
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this.locales = locale.text;
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.setValidators(this._requiredValidator());
    this._subscriptionTimerChanges = this.timeControl.valueChanges.subscribe((value: NgbTimeStruct) => {
      if (value) {
        this.value = this._getTimePickerValue(value).toISOString();
        this.render();
      }
    });
    this._handleModelChange();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const {format} = changes;
    if (format) {
      this.format = format.currentValue || this._format.time;
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionFormat.unsubscribe();
    this._subscriptionLocale.unsubscribe();
    this._subscriptionTimerChanges.unsubscribe();
  }

  public updateValue(value: MomentInput): void {
    this.value = value;
    this._handleModelChange();
  }

  public render(): Promise<void> {
    if (!isEmpty(this.value)) {
      const time = moment(this.value);
      if (time.isValid()) {
        time.milliseconds(0);
        this.value = time.toISOString();
        super.render();
        return undefined;
      }
    }
    this.value = undefined;
    return super.render();
  }

  public inputValueChanged(value: string): void {
    this._setTimePickerValue(value ? moment(value, 'HH:mm:ss') : undefined, false);
  }

  public showDropdown(): void {
    if (this.dropdownOpen || this.options.disabled || this.options.readonly) {
      return;
    }
    this.dropdownOpen = true;
  }

  public closeDropdown(): void {
    if (!this.dropdownOpen || this.options.disabled || this.options.readonly) {
      return;
    }
    this.dropdownOpen = false;
    this.inputFocus();
  }

  public onInputKeyUp(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      this._applyTimeFromView();
    }
    if (this.options.events && isFunction(this.options.events.keyup)) {
      this.options.events.keyup(this.value, event);
    }
  }

  public onInputBlur(event: FocusEvent): void {
    super.onInputBlur(event);
    if (!this.isMouseIn) {
      this.closeDropdown();
    }
    this._applyTimeFromView();
    if (this.options.events && isFunction(this.options.events.blur)) {
      this.options.events.blur(this.value, event);
    }
  }

  public nowClick(): void {
    this.value = moment().toISOString();
    this.render();
    this.inputSelectAll();
  }

  public clearClick(): void {
    this.value = undefined;
    this.render();
  }

  @ViewChild('btnCloseTimer')
  public set btnCloseTimerInit(value: ElementRef<HTMLButtonElement>) {
    this._btnCloseTimer = value?.nativeElement;
  }

  @ViewChild('ngbTimepicker', {read: ElementRef})
  public set ngbTimepicker(value: ElementRef<HTMLElement>) {
    const ngbTimepicker: HTMLElement = value?.nativeElement;
    if (ngbTimepicker) {
      const inputElements: NodeListOf<HTMLInputElement> = ngbTimepicker.querySelectorAll<HTMLInputElement>('input');
      for (const inputElement of Array.from(inputElements)) {
        fromEvent<KeyboardEvent>(inputElement, 'keydown').pipe(takeUntil(this._dropdownClosed)).subscribe(this._fnKeydownTimeInput);
      }
    }
  }

  private _handleModelChange(): void {
    const value: Moment = !isEmpty(this.value) ? moment(this.value).milliseconds(0) : undefined;
    this._setViewValue(value);
  }

  private _setViewValue(value: Moment): void {
    this.viewValue = value?.isValid() ? value.format('HH:mm:ss') : '';
  }

  private _getTimePickerValue(value: NgbTimeStruct = this.timeControl.value): Moment {
    return moment().hour(value.hour).minute(value.minute).second(value.second).millisecond(0);
  }

  private _setTimePickerValue(value: Moment, emitEvent: boolean = true): void {
    this.timeControl.setValue(
      isDefined(value)
        ? {
            hour: value.hour(),
            minute: value.minute(),
            second: value.second()
          }
        : undefined,
      {emitEvent: emitEvent}
    );
  }

  private _applyTimeFromView(value: MomentInput = this.formControl.value): void {
    const time = moment(value, 'HH:mm:ss');
    if (!isEmpty(value) && time.isValid()) {
      this.value = time.toISOString();
      this.render();
    } else {
      this._setTimePickerValue(undefined);
      this.value = '';
      this.render();
    }
  }

  private _keydownTimeInput(event: KeyboardEvent): void {
    const element: HTMLInputElement = <HTMLInputElement>event.target;
    if (event.key === KEYCODES.ENTER) {
      event.preventDefault();
      event.stopImmediatePropagation();
      const inputElement: HTMLInputElement = next(element.closest(NGB_SELECTOR_CONTAINER), NGB_SELECTOR_CONTAINER)?.querySelector<HTMLInputElement>('input');
      if (inputElement) {
        inputElement.focus();
        const inputValue = inputElement.value;
        if (inputValue) {
          inputElement.setSelectionRange(0, inputElement.value.length);
        }
      } else if (this._btnCloseTimer) {
        this._btnCloseTimer.focus();
      }
    } else if (element.selectionStart === element.selectionEnd) {
      let inputElement: HTMLInputElement;
      if (event.key === KEYCODES.LEFT && element.selectionStart === 0) {
        inputElement = prev(element.closest(NGB_SELECTOR_CONTAINER), NGB_SELECTOR_CONTAINER)?.querySelector<HTMLInputElement>('input');
      }
      if (event.key === KEYCODES.RIGHT && element.selectionStart === String(element.value).length) {
        inputElement = next(element.closest(NGB_SELECTOR_CONTAINER), NGB_SELECTOR_CONTAINER)?.querySelector<HTMLInputElement>('input');
      }
      if (inputElement) {
        const inputValue = inputElement.value;
        let position = 0;
        if (inputValue) {
          position = inputValue.length;
        }
        setTimeout(() => {
          inputElement.focus();
          inputElement.setSelectionRange(position, position);
        });
      }
    }
  }

  private _requiredValidator(): ValidatorFn {
    return () => {
      if (this.validate && this.options.validators.required?.value) {
        return !isEmpty(this.value) && moment(this.value).isValid() ? undefined : {required: true};
      }
      return undefined;
    };
  }

  private readonly _fnKeydownTimeInput: (event: KeyboardEvent) => void = (event: KeyboardEvent) => {
    this._keydownTimeInput(event);
  };
}
