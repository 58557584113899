<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="close()"></button>
    <h4 class="modal-title">{{ modalTitle }}</h4>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'gestaodgemps.fields.motivo'"></label>
      <edit>
        <pl-edit-text [(model)]="motivo" [properties]="{validators: {maxlength: {value: 250}}}" plAutoFocus> </pl-edit-text>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-primary action-submit" (evtClicked)="close()"> <i class="fa fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span> </pl-button>

    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</pl-form>
