import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {copy, downloadStream, IPlToolbarItem, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {
  ERadioTipoDeclaracao,
  ETiposValidar,
  IJsonModelo25,
  IJsonModelo25Config,
  IJsonModeloErrors,
  IJsonRegistoLinhaList,
  IJsonRegistoList,
  IJsonTiposDonativos,
  IJsonValidarModeloErrors
} from '../jsonModelo25.module.interface';
import {ETipModelo25, RADIO_GROUP_TIPO_DECLARACAO} from '../modelo25.module.interface';
import {
  IDevExpressDataGridEventOnEditCanceled,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowRemoved,
  IDevExpressDataGridEventOnRowValidating
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IRadioGroup} from '../../../../../common/interfaces/interfaces';
import {Modelo25ConfigModalComponent} from '../modal/config/modelo25.config.modal.component';
import {Modelo25Service} from '../modelo25.module.service';
import {ModuloComponent} from '../../../../components/module/module.component';
import {ICGExceptionError} from '../../../../components/exceptions/exceptions.service.interface';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {ModeloConfigwsModalComponent} from '../../modeloconfigwsmodal/modals/modelo.configWS.modal.component';
import {EDebitoCredito} from '../../../../datasources/debitocredito/debitoCredito.datasource.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';

const TOOLBAR_GROUP_RESPONSIVE = 'module-btns-responsive';

@Component({
  selector: 'module-modelo25',
  templateUrl: './modelo25.module.component.html'
})
export class Modelo25ModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public modelo25: IJsonModelo25;
  @Input() public modeloConfig: IJsonModelo25Config;
  @Input() public tiposDonativos: Array<IJsonTiposDonativos>;

  public readonly eTiposValidar: typeof ETiposValidar;
  public readonly tiposDeclaracao: IRadioGroup<ERadioTipoDeclaracao>;
  public dataGridRegistoList: IDevExpressDataGrid<IJsonRegistoList, IJsonRegistoList>;
  public dataGridRegistoLinhaDetail: IDevExpressDataGrid<IJsonRegistoLinhaList, IJsonRegistoLinhaList>;
  public quadro5AuxList: Array<IJsonRegistoList>;
  public errors: IJsonModeloErrors;

  private readonly _btnCriarFicheiro: IPlToolbarItem;
  private readonly _btnValidarAt: IPlToolbarItem;
  private readonly _btnSumbeterAt: IPlToolbarItem;
  private readonly _btnConfigWS: IPlToolbarItem;
  private readonly _hasErrorsRegistoList: Set<IJsonRegistoList>;
  private _dataGridInstance: dxDataGrid<IJsonRegistoList, IJsonRegistoList>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _modelo25Service: Modelo25Service,
    private readonly _cgModalService: CGModalService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.tiposDeclaracao = RADIO_GROUP_TIPO_DECLARACAO;
    this.eTiposValidar = ETiposValidar;
    this.quadro5AuxList = [];
    this._cleanErrors();
    this._btnCriarFicheiro = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'criarFicheiro',
      type: 'button',
      order: this.btnRefresh.order + 1,
      iconLeft: '<i class="fa fa-fw fa-file-text-o"></i>',
      caption: 'modelo25.global.btns.btnCriarFicheiro',
      disabled: true,
      click: () => this._saveModelo25()
    };
    this._btnValidarAt = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'validarNaAT',
      type: 'dropdown-split',
      order: this._btnCriarFicheiro.order + 1,
      caption: 'modelo25.global.btns.btnValidarAt',
      iconLeft: '<i class="fa fa-fw fa-globe"></i>',
      click: () => this._validarNaAT(),
      disabled: true,
      menu: [this._btnSumbeterAt, this._btnConfigWS]
    };
    this._btnSumbeterAt = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'submeterNaAT',
      order: this._btnValidarAt.order + 1,
      type: 'button',
      caption: 'modelo25.global.btns.btnSubmeterAt',
      iconLeft: '<i class="fa fa-fw fa-upload"></i>',
      click: () => this._submeterNaAT(),
      disabled: true
    };
    this._btnConfigWS = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'configWS',
      order: this._btnSumbeterAt.order + 1,
      type: 'button',
      caption: 'modelo25.global.btns.btnConfigWS',
      iconLeft: '<i class="fa fa-fw fa-cog"></i>',
      click: () => this._callConfigWS(),
      disabled: false
    };
    this._hasErrorsRegistoList = new Set<IJsonRegistoList>();
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.dataGridRegistoList = {
      columnHidingEnabled: false,
      columns: [
        {
          dataField: 'entidade',
          dataType: 'string',
          caption: 'modelo25.groups.q5.dataGridRegistoList.colunas.entidade',
          validationRules: [{type: 'required', trim: true, message: this._translateService.instant('datagrid.column.required')}]
        },
        {
          dataField: 'codigo',
          dataType: 'string',
          caption: 'modelo25.groups.q5.dataGridRegistoList.colunas.codigoDonativo',
          showEditorAlways: true,
          lookup: {
            dataSource: this.tiposDonativos,
            valueExpr: 'cod',
            displayExpr: (item: IJsonTiposDonativos) => `${item.cod} - ${item.descricao}`,
            allowClearing: false
          }
        },
        {dataField: 'valorDonativoNumerario', dataType: 'double', caption: 'modelo25.groups.q5.dataGridRegistoList.colunas.valorNum'},
        {dataField: 'valorDonativoEspecie', dataType: 'double', caption: 'modelo25.groups.q5.dataGridRegistoList.colunas.valorEsp'},
        {dataField: 'donativoStr', dataType: 'string', caption: 'modelo25.groups.q5.dataGridRegistoList.colunas.donativoStr', visible: false}
      ],
      editing: {
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true,
        mode: 'cell',
        newRowPosition: 'last'
      },
      masterDetail: {
        enabled: true,
        autoExpandAll: false,
        template: 'detailTemplateLinhas'
      },
      summary: {
        totalItems: [
          {
            column: 'valorDonativoNumerario',
            summaryType: 'sum',
            valueFormat: 'double',
            displayFormat: `${<string>this._translateService.instant('global.text.total')}: {0}`
          },
          {
            column: 'valorDonativoEspecie',
            summaryType: 'sum',
            valueFormat: 'double',
            displayFormat: `${<string>this._translateService.instant('global.text.total')}: {0}`
          }
        ]
      },
      export: {filename: 'modelo25.title'},
      columnChooser: {enabled: false},
      remoteOperations: false
    };
    this.dataGridRegistoLinhaDetail = {
      columns: [
        {dataField: 'periodo', dataType: 'string', caption: 'modelo25.groups.q5.dataGridRegistoList.colunas.linhas.periodo'},
        {dataField: 'nDiario', dataType: 'number', caption: 'modelo25.groups.q5.dataGridRegistoList.colunas.linhas.nDiario'},
        {dataField: 'nDocInterno', dataType: 'string', caption: 'modelo25.groups.q5.dataGridRegistoList.colunas.linhas.nDocInterno'},
        {dataField: 'nConta', dataType: 'string', caption: 'modelo25.groups.q5.dataGridRegistoList.colunas.linhas.nConta'},
        {dataField: 'nomePOC', dataType: 'string', caption: 'modelo25.groups.q5.dataGridRegistoList.colunas.linhas.nomePOC'},
        {
          dataField: 'valor',
          dataType: 'double',
          caption: 'modelo25.groups.q5.dataGridRegistoList.colunas.linhas.valor',
          calculateDisplayValue: (rowData: IJsonRegistoLinhaList): number => {
            if (rowData.dc === EDebitoCredito.Debito) {
              return rowData.valor * -1;
            }
            return rowData.valor;
          }
        }
      ],
      columnHidingEnabled: false,
      headerFilter: {visible: false},
      remoteOperations: false,
      columnChooser: {enabled: false},
      export: {enabled: false}
    };

    this._buildToolbar();
    this._buildToolbarResponsive(this.isMobile);
    this._evaluateButtons();
    await this._buildQuadro5List();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public setIsMobile(value: boolean): void {
    super.setIsMobile(value);
    if (this.toolbar) {
      this._buildToolbarResponsive(value);
    }
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonRegistoList, IJsonRegistoList>): void {
    this._dataGridInstance = component;
  }

  public onEditCanceled(event: IDevExpressDataGridEventOnEditCanceled<IJsonRegistoList, IJsonRegistoList>): void {
    if (!event.changes.length) {
      return;
    }
    for (const change of event.changes) {
      if (change.type === 'insert') {
        this._hasErrorsRegistoList.delete(change.key);
      }
    }
    this._evaluateButtons();
  }

  public onRowInserted(): void {
    this._evaluateButtons();
  }

  public onRowRemoved(event: IDevExpressDataGridEventOnRowRemoved<IJsonRegistoList, IJsonRegistoList>): void {
    this._hasErrorsRegistoList.delete(event.key);
    this._evaluateButtons();
  }

  public onRowValidating(event: IDevExpressDataGridEventOnRowValidating<IJsonRegistoList, IJsonRegistoList>): void {
    if (event.brokenRules.length > 0) {
      this._hasErrorsRegistoList.add(event.key);
    } else {
      this._hasErrorsRegistoList.delete(event.key);
    }
    this._evaluateButtons();
  }

  public async openConfigModal(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(Modelo25ConfigModalComponent);
    const componentInstance: Modelo25ConfigModalComponent = modalInstance.componentInstance;
    componentInstance.modeloConfig = copy(this.modeloConfig);
    componentInstance.tiposDonativos = this.tiposDonativos;
    this.modeloConfig = await modalInstance.result;
    await this._stateService.reload(this._state);
  }

  private async _saveModelo25(): Promise<void> {
    this._cleanErrors();
    if (this._hasDatagridIncompletes()) {
      return;
    }

    const response: HttpResponse<Array<IJsonValidarModeloErrors>> = await this._modelo25Service.validarModelo(this.modelo25);
    this.errors.erros = response.body;
    if (!this.errors.erros.length) {
      const responseFicheiro: HttpResponse<Blob> = await this._modelo25Service.criarFicheiro(this.modelo25);
      downloadStream(responseFicheiro);
    }
  }

  private async _atualizarModelo(): Promise<void> {
    this._cleanErrors();
    this.modelo25 = await this._modelo25Service.getModelo25(ETipModelo25.NewFromDB).then((response: HttpResponse<IJsonModelo25>) => response.body);
    this._evaluateButtons();
    await this._buildQuadro5List();
  }

  private async _submeterNaAT(): Promise<void> {
    this._cleanErrors();
    if (this._hasDatagridIncompletes()) {
      return;
    }
    const response = await this._modelo25Service.submeterDeclaracaoAT(this.modelo25).catch((reason: HttpErrorResponse) => {
      const exception: ICGExceptionError = this._cgExceptionService.get(reason);
      this.errors = {
        tipoValidacao: ETiposValidar.AT,
        mensagemTipoHeader: this._translateService.instant('modelo25.servicoAT.submeterAtFailed'),
        erros: [{mensagemOriginal: exception.message}]
      };
    });
    if (response) {
      this._plAlertService.success('modelo25.servicoAT.submeterAtSuccess');
    }
  }

  private async _validarNaAT(): Promise<void> {
    this._cleanErrors();
    if (this._hasDatagridIncompletes()) {
      return;
    }
    const response = await this._modelo25Service.validarDeclaracaoAT(this.modelo25).catch((reason: HttpErrorResponse) => {
      const exception: ICGExceptionError = this._cgExceptionService.get(reason);
      this.errors = {
        tipoValidacao: ETiposValidar.AT,
        mensagemTipoHeader: this._translateService.instant('modelo25.servicoAT.validarAtFailed'),
        erros: [{mensagemOriginal: exception.message}]
      };
    });
    if (response) {
      this._plAlertService.success('modelo25.servicoAT.validarAtSuccess');
    }
  }

  private _evaluateButtons(): void {
    this._btnCriarFicheiro.disabled = this._hasErrorsRegistoList.size > 0 || !this.modelo25.registoList.length;
    this._btnValidarAt.disabled = this._hasErrorsRegistoList.size > 0 || !this.modelo25.registoList.length;
    this._btnSumbeterAt.disabled = this._hasErrorsRegistoList.size > 0 || !this.modelo25.registoList.length;
  }

  private _hasDatagridIncompletes(): boolean {
    let foundError = false;
    if (this.modelo25.registoList) {
      for (const registo of this.modelo25.registoList) {
        if (!registo.codigo) {
          this.errors.erros.push({
            tipoMensagemStr: this._translateService.instant('modelo25.errors.errorTipo'),
            mensagem: this._translateService.instant('modelo25.errors.errorCodigoDonMensagem', {entidade: registo.entidade}),
            mensagemOriginal: this._translateService.instant('modelo25.errors.errorCodigoDonRequired'),
            nomeCampo: this._translateService.instant('modelo25.errors.errorCodigoDonName')
          });
          foundError = true;
        }
        if (!registo.valorDonativoNumerario && !registo.valorDonativoEspecie) {
          this.errors.erros.push({
            tipoMensagemStr: this._translateService.instant('modelo25.errors.errorTipo'),
            mensagem: this._translateService.instant('modelo25.errors.errorValoresMensagem', {entidade: registo.entidade}),
            mensagemOriginal: this._translateService.instant('modelo25.errors.errorValoresRequired'),
            nomeCampo: ''
          });
          foundError = true;
        }
      }
    }
    return foundError;
  }

  private _callConfigWS(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ModeloConfigwsModalComponent);
    return modalInstance.result;
  }

  private _cleanErrors(): void {
    this.errors = {
      tipoValidacao: ETiposValidar.DEFAULT,
      erros: []
    };
  }

  private _buildToolbar(): void {
    this.dropdownActions.menu = [this._btnCriarFicheiro, this._btnValidarAt, this._btnSumbeterAt, this._btnConfigWS];

    this.btnRefresh.visible = this.btnConfig.visible = true;
    this.btnRefresh.order = 1;
    this.btnRefresh.click = () => this._atualizarModelo();
    this.btnConfig.order = this.dropdownActions.order + 1;
    this.btnConfig.click = () => this.openConfigModal();
  }

  private _buildToolbarResponsive(isMobile: boolean): void {
    this.toolbar.removeGroupId(TOOLBAR_GROUP_RESPONSIVE, false);
    this.dropdownActions.visible = isMobile;
    this._btnValidarAt.type = isMobile ? 'button' : 'dropdown-split';
    if (!isMobile) {
      this._btnCriarFicheiro.class = this._btnValidarAt.class = 'btn-primary';
      this._btnValidarAt.menu = [this._btnSumbeterAt, this._btnConfigWS];
      this.toolbar.addButton(this._btnCriarFicheiro).addButton(this._btnValidarAt);
    } else {
      this._btnCriarFicheiro.class = this._btnValidarAt.class = undefined;
      this.toolbar.addButton(this.dropdownActions);
    }
  }

  private async _buildQuadro5List(): Promise<void> {
    this.quadro5AuxList = copy(this.modelo25.registoList);
    this.modelo25.registoList = [];
    this.quadro5AuxList.forEach((registo: IJsonRegistoList) => {
      const registoItem: IJsonRegistoList = this.modelo25.registoList.find((item: IJsonRegistoList) => item.codigo === registo.codigo && item.entidade === registo.entidade);
      if (!registoItem) {
        registo.valorDonativoNumerario = !registo.valorDonativoNumerario ? 0 : registo.valorDonativoNumerario;
        registo.valorDonativoEspecie = !registo.valorDonativoEspecie ? 0 : registo.valorDonativoEspecie;
        this.modelo25.registoList.push(copy(registo));
      } else {
        registoItem.valorDonativoNumerario += !registo.valorDonativoNumerario ? 0 : registo.valorDonativoNumerario;
        registoItem.valorDonativoEspecie += !registo.valorDonativoEspecie ? 0 : registo.valorDonativoEspecie;

        //adicionar as linhas do registo
        registo.linhas.forEach((linha: IJsonRegistoLinhaList) => {
          registoItem.linhas.push(copy(linha));
        });
      }
    });
    if (this._dataGridInstance) {
      await this._dataGridInstance.refresh();
    }
  }
}
