import {isArray, Logger} from 'pl-comps-angular';
import {CGInjector} from '../common/injectors/cginjector';
import {IModuleDefinition} from '../core/components/module/module.definition.interface';
import {ModuleRegistryService} from '../core/components/module/module.registry.service';
import {TInjectorResolvable} from '../common/injectors/common';

import {
  MODULE_ADVANCEDOPERATIONS_ANULACAO,
  MODULE_ADVANCEDOPERATIONS_DIARIO,
  MODULE_ADVANCEDOPERATIONS_PERIODO
} from '../core/modules/portalcontabilidade/contabadvancedoperations/contabAdvancedOperations.module';
import {MODULE_ALTERACOES_ENTIDADES} from '../core/modules/colaboradores/alteracoesentidades/alteracoesEntidades.module';
import {MODULE_ANALISADOR_TES} from '../core/modules/tesouraria/analisadortes/analisadorTes.module';
import {MODULE_ANALITICA_CONFIG} from '../core/modules/analiticaconfig/analiticaConfig.module';
import {MODULE_APROVACOES_APROVAR_REFEICOES} from '../core/modules/colaboradores/refeicoes/aprovacoes/aprovacoesRefeicoes.module';
import {MODULE_ASSISTENTE_CONFIG_PORTAIS_EMPREGADO} from '../core/modules/configs/assistenteconfigportais/portais/portalclientaccounts/empregado/assistConfigPortais.empregado.module';
import {MODULE_ASSISTENTE_CONFIG_PORTAIS_EMPRESA} from '../core/modules/configs/assistenteconfigportais/portais/portalclientaccounts/empresa/assistConfigPortais.empresa.module';
import {MODULE_ASSISTENTE_CONFIG_PORTAIS_GERAL_EMAIL} from '../core/modules/configs/assistenteconfigportais/portais/geral/email/assistConfigPortais.geral.email.module';
import {MODULE_ASSISTENTE_CONFIG_PORTAIS_PORTAL_CLIENT_CONNECT_UTILIZADOR_EXISTENTE} from '../core/modules/configs/assistenteconfigportais/portais/portalclientconnect/utilizadorexistente/assistConfigPortais.portalClientConnect.utilizadorExistente.module';
import {MODULE_ASSISTENTE_CONFIG_PORTAIS_PORTAL_CLIENT_CONNECT_UTILIZADOR} from '../core/modules/configs/assistenteconfigportais/portais/portalclientconnect/utilizador/assistConfigPortais.portalClientConnect.utilizador.module';
import {MODULE_ASSISTENTE_CONFIG_PORTAIS_UTILIZADOR} from '../core/modules/configs/assistenteconfigportais/portais/portalclientaccounts/utilizador/assistConfigPortais.utilizador.module';
import {MODULE_ASSISTENTE_CONFIG_PORTAIS} from '../core/modules/configs/assistenteconfigportais/assistenteConfigPortais.module';
import {MODULE_ATIVOS_ABATE} from '../core/modules/ativos/ativosabate/ativosAbate.module';
import {MODULE_ATIVOS_AQUISICAO_CONTAB} from '../core/modules/ativos/ativosaquisicaocontab/ativosAquisicaoContab.module';
import {MODULE_ATIVOS_AQUISICAO_INVEST_EM_CURSO_POR_DOC} from '../core/modules/ativos/ativosaquisicaoinvestemcurso/ativosAquisicaoInvestEmCursoPorDoc.module';
import {MODULE_ATIVOS_COEFICIENTE_CORRECAO_MONETARIA} from '../core/modules/ativos/ativoscoeficientecm/ativosCoeficienteCM.module';
import {MODULE_ATIVOS_CONFIGURACOES} from '../core/modules/ativos/ativosconfiguracoes/ativosConfiguracoes.module';
import {MODULE_ATIVOS_CONSISTENCIA} from '../core/modules/ativos/ativosconsistencia/ativosConsistencia.module';
import {MODULE_ATIVOS_DEPRECIACAO} from '../core/modules/ativos/ativosdepreciacao/ativosDepreciacao.module';
import {MODULE_ATIVOS_GERA_MAPAS_AUTO} from '../core/modules/ativos/ativosgeramapasauto/ativosGeraMapasAuto.module';
import {MODULE_ATIVOS_IMPRESSAO} from '../core/modules/ativos/ativosimpressao/ativosImpressao.module';
import {MODULE_ATIVOS_MAPAS_OFICIAIS} from '../core/modules/ativos/ativosmapasoficiais/ativosMapasOficiais.module';
import {MODULE_ATIVOS_VENDA} from '../core/modules/ativos/ativosvenda/ativosVenda.module';
import {MODULE_AVISOS_COBRANCA_GRID} from '../core/modules/portalcontabilidade/avisoscobranca/avisoscobrancagrid/avisosCobrancaGrid.module';
import {MODULE_BANCOS_EXTRATO, MODULE_BANCOS_EXTRATO_SIMPLE} from '../core/modules/bancosextrato/bancosExtrato.module';
import {MODULE_BLOCKED_PLUGIN} from '../core/modules/blockedplugin/blockedPlugin.module';
import {MODULE_CALCULO_DIFERIMENTOS} from '../core/modules/diferimentos/calculodiferimentos/calculoDiferimentos.module';
import {MODULE_COLABORADOR_BOARD} from '../core/modules/colaboradores/dgemps/colaboradorboard/colaboradorBoard.module';
import {MODULE_COLABORADORES_ASSOCIA_SERVICOS} from '../core/modules/colaboradores/servicos/associaservicos/associaServicos.module';
import {
  MODULE_COLABORADORES_GESTAO_DGEMPS_ABONOS_GESTOR,
  MODULE_COLABORADORES_GESTAO_DGEMPS_FALTAS,
  MODULE_COLABORADORES_GESTAO_DGEMPS_FALTAS_GESTOR,
  MODULE_COLABORADORES_GESTAO_DGEMPS_FERIAS,
  MODULE_COLABORADORES_GESTAO_DGEMPS_FERIAS_GESTOR
} from '../core/modules/colaboradores/gestaodgemps/colaboradores.gestaodgemps.module';
import {MODULE_COLABORADORES_HOME} from '../core/modules/colaboradores/home/home.module';
import {MODULE_COLABORADORES_SERVICOS} from '../core/modules/colaboradores/servicos/servicos.module';
import {MODULE_COMUNICACOES_INTERNAS} from '../core/modules/colaboradores/comunicacoesinternas/comunicacoesInternas.module';
import {MODULE_CONCIL_MOV_AB} from '../core/modules/concilmovab/concilMovAb.module';
import {MODULE_CONCILIACAO_BANCARIA} from '../core/modules/portalcontabilidade/conciliacaobancaria/conciliacaoBancaria.module';
import {MODULE_CONFIG_EFATURA_DOCUMENTOS_POR_LANCAR} from '../core/modules/configEfaturaDocPorLancar/configEfaturaDocPorLanc.module';
import {MODULE_CONFIGS_CLIFOS} from '../core/modules/configs/erp/clifos/configs.clifos.module';
import {MODULE_CONFIGS_COMERCIAL} from '../core/modules/configs/erp/comercial/configs.comercial.module';
import {MODULE_CONFIGS_CONTABILIDADE} from '../core/modules/configs/erp/contabilidade/configs.contabilidade.module';
import {MODULE_CONFIGS_EMPRESA} from '../core/modules/configs/erp/empresa/configs.empresa.module';
import {MODULE_CONFIGS_ERP_CHECK} from '../core/modules/configs/erpcheck/erp.check.module';
import {MODULE_CONFIGS_LICENCA} from '../core/modules/configs/erp/licenca/configs.licenca.module';
import {MODULE_CONFIGS_PCA} from '../core/modules/configs/erp/pca/configs.pca.module';
import {MODULE_CONFIGS_RH} from '../core/modules/configs/erp/rh/configs.rh.module';
import {MODULE_CONFIGURACOES_EVENTOS} from '../core/modules/colaboradores/configuracoeseventos/configuracoesEventos.module';
import {MODULE_CONTABILIDADE_DIGITAL_CLIENT_CONNECT} from '../core/modules/contabilidadedigital/clientconnect/contabDigital.clientConnect.module';
import {
  MODULE_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS,
  MODULE_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS_CLIENT_CONNECT,
  MODULE_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS_CONCILIACAO
} from '../core/modules/contabilidadedigital/documentosdigitais/contabDigital.documentosDigitais.module';
import {MODULE_CONTABILIDADE_PREDEFINIDOS} from '../core/modules/portalcontabilidade/manutencao/predefinidos/contabilidade.predefinidos.module';
import {MODULE_CONTRATOS_PRINT} from '../core/modules/contratos/print/contratos.print.module';
import {MODULE_DASHBOARDS_ERP} from '../core/modules/dashboardserp/dashBoardsERP.module';
import {MODULE_DEM_FIN_SNC} from '../core/modules/demfinsnc/demFinSNC.module';
import {MODULE_DGEMPS_FICHA} from '../core/modules/colaboradores/dgemps/ficha/dgempsFicha.module';
import {MODULE_DGEMPS} from '../core/modules/colaboradores/dgemps/dgemps.module';
import {MODULE_DMRF} from '../core/modules/portalcontabilidade/dmrf/dmrf.module';
import {
  MODULE_DOCS_COMERCIAIS_ESTATISTICAS_COMPRAS,
  MODULE_DOCS_COMERCIAIS_ESTATISTICAS_DOC_COMPRAS,
  MODULE_DOCS_COMERCIAIS_ESTATISTICAS_DOC_TODOS,
  MODULE_DOCS_COMERCIAIS_ESTATISTICAS_DOC_VENDAS,
  MODULE_DOCS_COMERCIAIS_ESTATISTICAS_TODOS,
  MODULE_DOCS_COMERCIAIS_ESTATISTICAS_VENDAS
} from '../core/modules/listagens/docscomerciasestatisticas/docsComerciaisEstatisticas.module';
import {MODULE_DOCS_PLATAFORMA} from '../core/modules/colaboradores/docsplataforma/docsPlataforma.module';
import {MODULE_DOCUMENTOS_PARTILHADOS} from '../core/modules/documentospartilhados/documentosPartilhados.module';
import {
  MODULE_DOSSIER_FISCAL_ALTERACOES_CAPITAL_PROPRIO,
  MODULE_DOSSIER_FISCAL_ANEXO_DEMONSTRACOES_FINANCEIRAS,
  MODULE_DOSSIER_FISCAL_BALANCO,
  MODULE_DOSSIER_FISCAL_DEMONSTRACAO_RESULTADOS,
  MODULE_DOSSIER_FISCAL_FLUXO_CAIXA,
  MODULE_DOSSIER_FISCAL_RESULTADOS_POR_NATUREZA
} from '../core/modules/portalclientaccounts/dossierfiscal/dossierFiscal.module';
import {MODULE_EFATURA} from '../core/modules/efatura/eFatura.module';
import {MODULE_EMPRESAS} from '../core/modules/empresas/empresas.module';
import {
  MODULE_ESTATISTICA_EXTRATOS_DT,
  MODULE_ESTATISTICA_EXTRATOS_DT_CLIENTES,
  MODULE_ESTATISTICA_EXTRATOS_DT_FORNECEDORES
} from '../core/modules/portalcontabilidade/estatistica/extratosdt/extratosDT.module';
import {MODULE_EXTRACTOSARTIGOS} from '../core/modules/extractosartigos/extractosArtigos.module';
import {MODULE_EXTRATOS_CLIENTES, MODULE_EXTRATOS_FORNECEDORES} from '../core/modules/extratosclifos/extratosClifos.module';
import {MODULE_FATURACAO_CONTRATOS} from '../core/modules/faturacaocontratos/faturacaoContratos.module';
import {MODULE_FATURACAO_ELETRONICA_CONFIGURACOES} from '../core/modules/faturacaoeletronicaconfiguracoes/faturacaoEletronicaConfiguracoes.module';
import {MODULE_FATURACAO_PUBLICA} from '../core/modules/faturacaopublica/faturacaoPublica.module';
import {MODULE_FCT} from '../core/modules/fct/fct.module';
import {MODULE_FIM_ANO} from '../core/modules/portalcontabilidade/fimano/fimAno.module';
import {MODULE_FLUXOS} from '../core/modules/colaboradores/fluxos/fluxos.module';
import {MODULE_GESTAO_FICHEIROS_SEPA} from '../core/modules/gestaoficheirossepa/gestaoFicheirosSepa.module';
import {MODULE_GTO} from '../core/modules/portalcontabilidade/gto/gto.module';
import {MODULE_IES} from '../core/modules/ies/ies.module';
import {MODULE_IMP_DADOS_EMP} from '../core/modules/portalcontabilidade/impdadosemp/impDadosEmp.module';
import {MODULE_IMP_FFERPOC} from '../core/modules/portalrh/impfferpoc/impFFERPOC.module';
import {MODULE_IMPAVISOSCOBRANCA} from '../core/modules/portalcontabilidade/avisoscobranca/impavisoscobranca/impAvisosCobranca.module';
import {MODULE_IMPCTRIRS} from '../core/modules/portalrh/impctrirs/impCtrIrs.module';
import {MODULE_IMPEDE119} from '../core/modules/portalrh/impde119/impDe119.module';
import {MODULE_IMPENCAR} from '../core/modules/portalrh/impencar/impEncar.module';
import {MODULE_IMPORT_XLS} from '../core/modules/importxls/importXLS.module';
import {MODULE_IMPORTADOR_SAFT_DIRECT} from '../core/modules/portalcontabilidade/importadorsaftdirect/importadorSaftDirect.module';
import {
  MODULE_INFO_GLOBAL_ACRESCIMOS,
  MODULE_INFO_GLOBAL_DIFERIMENTOS,
  MODULE_INFO_GLOBAL_DIFERIMENTOS_ACRESCIMOS
} from '../core/modules/diferimentos/infoglobaldiferimentosacrescimos/infoGlobalDiferimentosAcrescimos.module';
import {MODULE_INTEGRACAO_DOCUMENTOS} from '../core/modules/integracaodocumentos/intFac.module';
import {MODULE_INTEGRACAO_SALARIOS_MULTI} from '../core/modules/colaboradores/integracaosalariosmulti/integracaoSalariosMulti.module';
import {MODULE_INTEGRACAO_SALARIOS} from '../core/modules/colaboradores/integracaosalarios/integracaoSalarios.module';
import {MODULE_INVENTARIOS} from '../core/modules/inventarios/inventarios.module';
import {MODULE_LISTAGENS_MOVIMENTOS_PENDENTES_CLIENTES} from '../core/modules/movimentospendentes/clientes/movimentos.pendentes.clientes.module';
import {MODULE_LISTAGENS_MOVIMENTOS_PENDENTES_FORNCEDORES} from '../core/modules/movimentospendentes/fornecedores/movimentos.pendentes.fornecedores.module';
import {MODULE_LISTAGENS_MOVIMENTOS_PENDENTES_OUTROS_DEVEDORES_CREDORES} from '../core/modules/movimentospendentes/outrosdevedorescredores/movimentos.pendentes.outrosDevedoresCredores.module';
import {MODULE_LISTAGENS_RESUMO_IVA} from '../core/modules/listagens/resumoiva/resumoIva.module';
import {MODULE_LISTAGENS_VENDAS_ANO_MES} from '../core/modules/listagens/vendasAnoMes.module';
import {MODULE_LISTAGENS_VENDAS_ARTIGO} from '../core/modules/listagens/vendasartigo/vendasArtigo.module';
import {MODULE_LISTAGENS_VENDAS_CLIENTE} from '../core/modules/listagens/vendascliente/vendasCliente.module';
import {MODULE_MAPA_EXPLORACAO} from '../core/modules/portalcontabilidade/estatistica/mapaexploracao/mapaExploracao.module';
import {MODULE_MARCACAO_REFEICOES} from '../core/modules/colaboradores/refeicoes/marcar/marcacaoRefeicoes.module';
import {MODULE_MB_CONFIGS} from '../core/modules/mbconfigs/mbConfigs.module';
import {MODULE_MODELO_10} from '../core/modules/portalcontabilidade/modelo10/modelo10.module';
import {MODULE_MODELO_22} from '../core/modules/portalcontabilidade/modelo22/modelo22.module';
import {MODULE_MODULES} from '../core/modules/modules/modules.module';
import {MODULE_NOTIFICATION_CENTER} from '../core/modules/notificationcenter/notificationCenter.module';
import {MODULE_OFFICE_REPORTING} from '../core/modules/portalcontabilidade/officereporting/officeReporting.module';
import {MODULE_AT_FATURAS_RECIBOS_VERDES} from '../core/modules/portalcontabilidade/atFaturasRecibosVerdes/atFaturasRecibosVerdes.module';
import {MODULE_CONCILIACAO_EFATURAS} from '../core/modules/portalcontabilidade/conciliacaoEFaturas/conciliacaoEFaturas.module';
import {MODULE_PCA_CONTABILIDADE_BALANCETES} from '../core/modules/portalclientaccounts/contabilidade/balancetes/pca.balancetes.module';
import {MODULE_PCA_CONTABILIDADE_EXTRATOS} from '../core/modules/portalclientaccounts/contabilidade/extratos/extratos.module';
import {MODULE_PCA_CONTABILIDADE_IDADE_SALDOS_CLIENTES, MODULE_PCA_CONTABILIDADE_IDADE_SALDOS_FORNECEDORES} from '../core/modules/portalclientaccounts/contabilidade/idadesaldos/idadeSaldos.module';
import {
  MODULE_PCA_CONTABILIDADE_MOVIMENTOS_ABERTO_CLIENTE,
  MODULE_PCA_CONTABILIDADE_MOVIMENTOS_ABERTO_FORNECEDOR
} from '../core/modules/portalclientaccounts/contabilidade/movimentosaberto/movimentosAberto.module';
import {MODULE_PCA_DASHBOARDS} from '../core/modules/portalclientaccounts/dashboards/dashBoards.module';
import {MODULE_PCA_DOCUMENTOS_FISCAIS} from '../core/modules/portalcontabilidade/manutencao/documentosFiscais/documentosFiscais.module';
import {MODULE_PCA_ESTATISTICA_BALANCETES_ANALITICA} from '../core/modules/portalcontabilidade/estatistica/balancetesanalitica/contabilidade.balancetes.analitica.module';
import {MODULE_PCA_ESTATISTICA_BALANCETES, MODULE_PCA_ESTATISTICA_BALANCETES_ALTERNATIVOS} from '../core/modules/portalcontabilidade/estatistica/balancetes/contabilidade.balancetes.module';
import {MODULE_PCA_ESTATISTICA_EXTRATOS_GRID} from '../core/modules/portalcontabilidade/estatistica/extratosgrid/contabilidade.extratosGrid.module';
import {MODULE_PCA_ESTATISTICA_EXTRATOS} from '../core/modules/portalcontabilidade/estatistica/extratos/contabilidade.extratos.module';
import {MODULE_PCA_ESTATISTICA_IMPDECPE} from '../core/modules/portalcontabilidade/estatistica/impdecpe/contabilidade.impDecPe.module';
import {MODULE_PCA_ESTATISTICA_MOVIMENTOS_EM_ABERTO} from '../core/modules/portalcontabilidade/estatistica/movimentosemaberto/contabilidade.movimentosEmAberto.module';
import {MODULE_PCA_IMPORTADOR_EFATURA} from '../core/modules/portalcontabilidade/importadorefatura/importadorEfatura.module';
import {MODULE_PCA_LISTAGEM_DE_DIARIOS} from '../core/modules/portalcontabilidade/estatistica/listagemdiarios/listagemDiarios.module';
import {MODULE_PCA_MANUTENCAO_APURAIVA} from '../core/modules/portalcontabilidade/manutencao/apuraiva/contabilidade.apuraIva.module';
import {MODULE_PCA_MANUTENCAO_APURAMENTO_RESULTADOS} from '../core/modules/portalcontabilidade/manutencao/apuramentoresultados/contabilidade.apuramentoResultados.module';
import {MODULE_PCA_MANUTENCAO_DECLARACAOIVA_RECAPITULATIVA} from '../core/modules/portalcontabilidade/manutencao/declaracaoiva/recapitulativa/contabilidade.declaracaoIva.recapitulativa.module';
import {MODULE_PCA_MANUTENCAO_DECLARACAOIVA} from '../core/modules/portalcontabilidade/manutencao/declaracaoiva/periodica/contabilidade.declaracaoIva.module';
import {MODULE_PCA_MANUTENCAO_DOCS_NAO_SALDADOS} from '../core/modules/portalcontabilidade/manutencao/docsnaosaldados/contabilidade.docsNaoSaldados.module';
import {MODULE_PCA_MANUTENCAO_RECONSTRUCAO_ACUMULADOS} from '../core/modules/portalcontabilidade/manutencao/reconstrucaoacumulados/reconstrucaoAcumulados.module';
import {MODULE_PCA_MANUTENCAO_SVAT} from '../core/modules/portalcontabilidade/manutencao/svat/contabilidade.svat.module';
import {MODULE_PCA_MANUTENCAO_VERIFICAO_ACUMULADOS} from '../core/modules/portalcontabilidade/manutencao/verificacaoacumulados/verificacaoAcumulados.module';
import {MODULE_PCA_MYACCOUNT_CONTAS_CLIENTE} from '../core/modules/portalclientaccounts/myaccount/contascliente/contasCliente.module';
import {MODULE_PCA_MYACCOUNT_DOCUMENTOS_COMERCIAIS} from '../core/modules/portalclientaccounts/myaccount/documentoscomerciais/documentosComerciais.module';
import {MODULE_PCA_MYACCOUNT_EXTRATOS_CONTA} from '../core/modules/portalclientaccounts/myaccount/extratosconta/extratosConta.module';
import {MODULE_PCA_MYACCOUNT_MOVIMENTOS_ABERTO} from '../core/modules/portalclientaccounts/myaccount/movimentosaberto/movimentosAberto.module';
import {
  MODULE_PCA_RH_GESTAO_DGEMPS_ABONOS,
  MODULE_PCA_RH_GESTAO_DGEMPS_FALTAS,
  MODULE_PCA_RH_GESTAO_DGEMPS_FERIAS
} from '../core/modules/portalclientaccounts/rh/gestaodgemps/pca.rh.gestaoDGEMPS.module';
import {MODULE_PCA_RH_MAPA_FALTAS} from '../core/modules/portalclientaccounts/rh/mapafaltas/mapaFaltas.module';
import {MODULE_PCA_RH_MAPA_FERIAS} from '../core/modules/portalclientaccounts/rh/mapaferias/mapaFerias.module';
import {MODULE_PCA_RH_MAPA_FUNDOS_COMPENSACAO} from '../core/modules/portalclientaccounts/rh/mapafundoscompensacao/mapaFundosCompensacao.module';
import {MODULE_PCA_RH_MAPA_SEGURADORA} from '../core/modules/portalclientaccounts/rh/mapaseguradora/mapaSeguradora.module';
import {MODULE_PCA_RH_MAPA_SEGURANCA_SOCIAL} from '../core/modules/portalclientaccounts/rh/mapaSegurancaSocial/mapaSegurancaSocial.module';
import {MODULE_PCA_RH_MAPA_SINDICATOS} from '../core/modules/portalclientaccounts/rh/mapasindicatos/mapaSindicatos.module';
import {MODULE_PCA_RH_RECIBOS_VENCIMENTOS} from '../core/modules/portalclientaccounts/rh/recibosVencimentos/recibosVencimentos.module';
import {MODULE_PCA_RH_SIMULACAO_VENCIMENTO} from '../core/modules/portalclientaccounts/rh/simulacaovencimento/simulacaoVencimento.module';
import {MODULE_PERFIL_CATEGORIA_ABONO} from '../core/modules/perfilcategoriaabdes/perfisabonos/perfilCategoriaAbono.module';
import {MODULE_PERFIL_CATEGORIA_DESCONTO} from '../core/modules/perfilcategoriaabdes/perfisdescontos/perfilCategoriaDesconto.module';
import {MODULE_PREV_TES_OBRAS} from '../core/modules/tesouraria/prevtesobra/prevTesObras.module';
import {MODULE_PREVI_TES_AUTO} from '../core/modules/tesouraria/prevites/previtesauto/previTesAuto.module';
import {MODULE_PREVI_TES} from '../core/modules/tesouraria/prevites/previtesmanual/previTes.module';
import {MODULE_PROC_SEGURADORA_FOLHA_FERIAS} from '../core/modules/colaboradores/procseguradorafolhaferias/procSeguradoraFolhaFerias.module';
import {MODULE_PROCE_DMR_AT} from '../core/modules/colaboradores/procedmrat/proceDMRAT.module';
import {MODULE_PROCE_SALARIOS} from '../core/modules/colaboradores/procesalarios/proceSalarios.module';
import {MODULE_PROCE_SEG_SOCIAL} from '../core/modules/colaboradores/procesegsocial/proceSegSocial.module';
import {MODULE_RECIBOS_VENCIMENTO} from '../core/modules/colaboradores/recibosvencimento/recibosVencimento.module';
import {MODULE_REFEICOES_ASSOCIAR_MENUS} from '../core/modules/colaboradores/refeicoes/associarmenus/associarMenus.module';
import {MODULE_REFEICOES_LISTAGEM_MENUS} from '../core/modules/colaboradores/refeicoes/listagens/listagemmenus/listagemMenus.module';
import {MODULE_REFEICOES_LISTAGEM_REFEICOES} from '../core/modules/colaboradores/refeicoes/listagens/listagemrefeicoes/listagemRefeicoes.module';
import {MODULE_RETENCOES} from '../core/modules/portalcontabilidade/retencoes/retencoes.module';
import {MODULE_RH_ATUALIZA_FERIAS_EMP} from '../core/modules/portalrh/rhatualizaferiasemp/rhAtualizaFeriasEmp.module';
import {MODULE_RH_CALCULO_RENDIMENTO_LIQUIDO} from '../core/modules/portalrh/rhcalculorendimentoliquido/rhCalculoRendimentoLiquido.module';
import {MODULE_RH_CESSA_CONTRATO_TRABALHO} from '../core/modules/portalrh/rhcessacontratotrabalho/rhCessaContratoTrabalho.module';
import {MODULE_RH_CONFIGURACOES_DADOS_CONFIG_FICHA} from '../core/modules/rhconfiguracoes/rhdadosconfigficha/rhDadosConfigFicha.module';
import {MODULE_RH_CONFIGURACOES_GOZO_FERIAS} from '../core/modules/rhconfiguracoes/rhgozoferias/rhGozoFerias.module';
import {MODULE_RH_CONFIGURACOES_INTEGRACAO_SALARIOS} from '../core/modules/rhconfiguracoes/rhintegracaosalarios/rhIntegracaoSalarios.module';
import {MODULE_RH_CONFIGURACOES_TRANSFERENCIAS_BANCARIAS} from '../core/modules/rhconfiguracoes/rhtransferenciasbancarias/rhTransferenciasBancarias.module';
import {MODULE_RH_FOLFER} from '../core/modules/portalrh/rhfolfer/rhFolfer.module';
import {MODULE_RH_GESTAO_FICHEIROS_DMR_AT} from '../core/modules/portalrh/rhgestaoficheirosdmrat/rhGestaoFicheirosDMRAT.module';
import {MODULE_RH_IMPVENCT} from '../core/modules/portalrh/rhimpvenct/rhImpVenct.module';
import {MODULE_RH_MANUTENCAO_CAMPOS} from '../core/modules/portalrh/rhmanutencaocampos/rhManutencaoCampos.module';
import {MODULE_RH_MANUTENCAO} from '../core/modules/rhconfiguracoes/rhmanutencao/rhManutencao.module';
import {MODULE_RH_MAPAS_SINDICATOS} from '../core/modules/portalrh/mapassindicatos/rhMapasSindicatos.module';
import {MODULE_RH_PROC_SEG_SOCIAL_CONSULT_ENVIOS} from '../core/modules/portalrh/rhprocsegsocialconsultenvios/rhProcCegCocialConsultEnvios.module';
import {MODULE_RH_PROCESSAMENTO_SALARIOS} from '../core/modules/rhconfiguracoes/rhprocessamentosalarios/rhProcessamentoSalarios.module';
import {MODULE_RH_QPIRCT} from '../core/modules/portalrh/rhqpirct/rhQPIRCT.module';
import {
  MODULE_RH_RECOLHA_SERIE_SUBSIDIOS_ALIMENTACAO,
  MODULE_RH_RECOLHA_SERIE_SUBSIDIOS_FERIAS,
  MODULE_RH_RECOLHA_SERIE_SUBSIDIOS_NATAL
} from '../core/modules/portalrh/rhrecolhaseriesubsidios/rhRecolhaSerieSubsidios.module';
import {MODULE_RH_REGISTAR_EMPREGADOS_SS} from '../core/modules/portalrh/rhregistarempregadosss/rhRegistarEmpregadosSS.module';
import {MODULE_RH_RELATORIO_UNICO} from '../core/modules/portalrh/rhrelatoriounico/rhRelatorioUnico.module';
import {MODULE_RH_TRANS_SAL} from '../core/modules/portalrh/rhsaltotrf/rhSalToTrf.module';
import {MODULE_RH_SEG_SOCIAL_FOLHA_FERIAS} from '../core/modules/rhconfiguracoes/rhsegsocialfolhaferias/rhSegSocialFolhaFerias.module';
import {MODULE_SAFT} from '../core/modules/saft/saft.module';
import {MODULE_TAXONOMIAS} from '../core/modules/taxonomias/taxonomias.module';
import {MODULE_TERCEIROS} from '../core/modules/terceiros/terceiros.module';
import {MODULE_TRF_BA_RH} from '../core/modules/trfba/trfBa.module';
import {MODULE_TRFLANCCONTAS} from '../core/modules/trflanccontas/trfLancContas.module';
import {MODULE_UTL_VIAT_PATRONAL} from '../core/modules/utlviatpatronal/utlViatPatronal.module';
import {MODULE_VISUALIZAR_REFEICOES} from '../core/modules/colaboradores/refeicoes/visualizar/visualizaRefeicoes.module';
import {MODULE_POCS_COPY} from '../core/modules/pocscopy/pocsCopy.module';
import {MODULE_CONTROLO_IVA_ENTRE_EMPRESAS} from '../core/modules/controloivaentreempresas/controloIVAEntreEmpresas.module';
import {MODULE_SAFT_CONTAB} from '../core/modules/saftcontab/saftContab.module';
import {MODULE_MODELO_25} from '../core/modules/portalcontabilidade/modelo25/modelo25.module';
import {MODULE_MAPA_PAGAMENTOS} from '../core/modules/portalcontabilidade/mapapagamentos/mapaPagamentos.module';
import {MODULE_MODELO_39} from '../core/modules/portalcontabilidade/modelo39/modelo39.module';
import {MODULE_EXPORTACAO_COMPRAS} from '../core/modules/integracoes/geofolia/exportacaocompras/exportacaoCompras.module';
import {MODULE_ENCOMENDAS} from '../core/modules/encomendas/encomendas.module';
import {MODULE_MODELO_30} from '../core/modules/portalcontabilidade/modelo30/modelo30.module';
import {MODULE_ACRESCIMO_ENCARGOS_COM_FERIAS} from '../core/modules/acrescimosencargoscomferias/acrescimosEncargosFerias.module';
import {MODULE_CONFIGS_SITE} from '../core/modules/configs/site/configsSite.module';
import {MODULE_DASHBOARD_FINANCEIRO} from '../core/modules/dashboardsfinanceiro/dashboardFinanceiro.module';
import {MODULE_ADIANTAMENTOS_CLIENTES, MODULE_ADIANTAMENTOS_FORNECEDORES} from '../core/modules/adiantamentos/adiantamentos.module';
import {MODULE_RH_COPIA_ABONOS_DESCONTOS} from '../core/modules/portalrh/rhcopiaabonosdescontos/rhCopiaAbonosDescontos.module';
import {MODULE_RPTCONFIGS} from '../core/modules/rptconfigs/rptConfigs.module';
import {MODULE_APURA_INVENT_PERMA} from '../core/modules/portalcontabilidade/apurainventperma/contabilidade.apurainventperma.module';
import {MODULE_TRIBUT_AUTONOMAS} from '../core/modules/tributacaoautonoma/manuttributautonomas/manutTributAutonomas.module';
import {MODULE_TRIBUT_AUTONOMAS_ANALISE} from '../core/modules/tributacaoautonoma/tributautonomasanalise/tributAutonomas.module';
import {MODULE_COMERCIAL_DIGITAL_DOCUMENTOS_DIGITAIS} from '../core/modules/comercialdigital/documentosdigitais/comercialDigital.documentosDigitais.module';
import {MODULE_DASHBOARD_CONTABILIDADE} from '../core/modules/dashboardscontabilidade/dashboardContabilidade.module';
import {MODULE_DASHBOARD_ATIVOS} from '../core/modules/ativos/dashboardsativos/dashboardAtivos.module';
import {MODULE_HABITACAO_PERMANENTE} from '../core/modules/rendhabitperm/rendHabitPerm.module';
import {MODULE_DASHBOARD_RH} from '../core/modules/dashboardsrh/dashboardRh.module';
import {MODULE_DEBITOS_DIRETOS_SEPA} from '../core/modules/debDiretosSEPA/debDiretosSEPA.module';
import {MODULE_TRDOCS_CABS_WIZARD} from '../core/modules/trDocsCabsWizard/trDocsCabsWizard.module';
import {MODULE_RH_ESTATISTICAS} from '../core/modules/portalrh/rhestatisticas/rhEstatisticas.module';
import {MODULE_DOCS_COMERCIAIS_CONFIG_NOME_FILE_EXPORT} from '../core/modules/docscomerciaisconfignomefileexport/docsComerciaisConfigNomeFileExport.module';
import {MODULE_RH_ESTATISTICAS_MULTI} from '../core/modules/portalrh/rhestatisticasMulti/rhEstatisticasMulti.module';
import {MODULE_MAPA_DIFERIMENTOS_ACRESCIMOS} from '../core/modules/diferimentos/mapadiferimentosacrescimos/mapaDiferimentosAcrescimos.module';
import {MODULE_CONFIGS_PORTAL_COLABORADOR} from '../core/modules/configs/erp/portalcolaborador/configs.portalcolaborador.module';
import {MODULE_GRUPO_DOCFA_CONFIGURACOES} from '../core/modules/grupoDocfaConfiguracoes/grupoDocfaConfiguracoes.module';
import {MODULE_ANALISE_MARGEM_LUCRO} from '../core/modules/listagens/analisemargemlucro/analisemargemlucro.module';

export const MODULES: Array<IModuleDefinition | TInjectorResolvable> = [
  MODULE_ACRESCIMO_ENCARGOS_COM_FERIAS,
  MODULE_ADIANTAMENTOS_CLIENTES,
  MODULE_ADIANTAMENTOS_FORNECEDORES,
  MODULE_ADVANCEDOPERATIONS_ANULACAO,
  MODULE_ADVANCEDOPERATIONS_DIARIO,
  MODULE_ADVANCEDOPERATIONS_PERIODO,
  MODULE_ALTERACOES_ENTIDADES,
  MODULE_ANALISADOR_TES,
  MODULE_ANALITICA_CONFIG,
  MODULE_APROVACOES_APROVAR_REFEICOES,
  MODULE_APURA_INVENT_PERMA,
  MODULE_ASSISTENTE_CONFIG_PORTAIS,
  MODULE_ASSISTENTE_CONFIG_PORTAIS_EMPREGADO,
  MODULE_ASSISTENTE_CONFIG_PORTAIS_EMPRESA,
  MODULE_ASSISTENTE_CONFIG_PORTAIS_GERAL_EMAIL,
  MODULE_ASSISTENTE_CONFIG_PORTAIS_PORTAL_CLIENT_CONNECT_UTILIZADOR,
  MODULE_ASSISTENTE_CONFIG_PORTAIS_PORTAL_CLIENT_CONNECT_UTILIZADOR_EXISTENTE,
  MODULE_ASSISTENTE_CONFIG_PORTAIS_UTILIZADOR,
  MODULE_ATIVOS_ABATE,
  MODULE_ATIVOS_AQUISICAO_CONTAB,
  MODULE_ATIVOS_AQUISICAO_INVEST_EM_CURSO_POR_DOC,
  MODULE_ATIVOS_COEFICIENTE_CORRECAO_MONETARIA,
  MODULE_ATIVOS_CONFIGURACOES,
  MODULE_ATIVOS_CONSISTENCIA,
  MODULE_ATIVOS_DEPRECIACAO,
  MODULE_ATIVOS_GERA_MAPAS_AUTO,
  MODULE_ATIVOS_IMPRESSAO,
  MODULE_ATIVOS_MAPAS_OFICIAIS,
  MODULE_ATIVOS_VENDA,
  MODULE_AVISOS_COBRANCA_GRID,
  MODULE_BANCOS_EXTRATO,
  MODULE_BANCOS_EXTRATO_SIMPLE,
  MODULE_BLOCKED_PLUGIN,
  MODULE_CALCULO_DIFERIMENTOS,
  MODULE_COLABORADOR_BOARD,
  MODULE_COLABORADORES_ASSOCIA_SERVICOS,
  MODULE_COLABORADORES_GESTAO_DGEMPS_ABONOS_GESTOR,
  MODULE_COLABORADORES_GESTAO_DGEMPS_FALTAS,
  MODULE_COLABORADORES_GESTAO_DGEMPS_FALTAS_GESTOR,
  MODULE_COLABORADORES_GESTAO_DGEMPS_FERIAS,
  MODULE_COLABORADORES_GESTAO_DGEMPS_FERIAS_GESTOR,
  MODULE_COLABORADORES_HOME,
  MODULE_COLABORADORES_SERVICOS,
  MODULE_COMERCIAL_DIGITAL_DOCUMENTOS_DIGITAIS,
  MODULE_COMUNICACOES_INTERNAS,
  MODULE_CONCIL_MOV_AB,
  MODULE_CONCILIACAO_BANCARIA,
  MODULE_CONFIG_EFATURA_DOCUMENTOS_POR_LANCAR,
  MODULE_CONFIGS_CLIFOS,
  MODULE_CONFIGS_COMERCIAL,
  MODULE_CONFIGS_CONTABILIDADE,
  MODULE_CONFIGS_EMPRESA,
  MODULE_CONFIGS_ERP_CHECK,
  MODULE_CONFIGS_LICENCA,
  MODULE_CONFIGS_PCA,
  MODULE_CONFIGS_PORTAL_COLABORADOR,
  MODULE_CONFIGS_RH,
  MODULE_CONFIGS_SITE,
  MODULE_CONFIGURACOES_EVENTOS,
  MODULE_CONTABILIDADE_DIGITAL_CLIENT_CONNECT,
  MODULE_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS,
  MODULE_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS_CLIENT_CONNECT,
  MODULE_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS_CONCILIACAO,
  MODULE_CONTABILIDADE_PREDEFINIDOS,
  MODULE_CONTRATOS_PRINT,
  MODULE_CONTROLO_IVA_ENTRE_EMPRESAS,
  MODULE_DASHBOARD_ATIVOS,
  MODULE_DASHBOARD_CONTABILIDADE,
  MODULE_DASHBOARD_FINANCEIRO,
  MODULE_DASHBOARD_RH,
  MODULE_DASHBOARDS_ERP,
  MODULE_DEBITOS_DIRETOS_SEPA,
  MODULE_DEM_FIN_SNC,
  MODULE_DGEMPS,
  MODULE_DGEMPS_FICHA,
  MODULE_DMRF,
  MODULE_DOCS_COMERCIAIS_CONFIG_NOME_FILE_EXPORT,
  MODULE_DOCS_COMERCIAIS_ESTATISTICAS_COMPRAS,
  MODULE_DOCS_COMERCIAIS_ESTATISTICAS_DOC_COMPRAS,
  MODULE_DOCS_COMERCIAIS_ESTATISTICAS_DOC_TODOS,
  MODULE_DOCS_COMERCIAIS_ESTATISTICAS_DOC_VENDAS,
  MODULE_DOCS_COMERCIAIS_ESTATISTICAS_TODOS,
  MODULE_DOCS_COMERCIAIS_ESTATISTICAS_VENDAS,
  MODULE_DOCS_PLATAFORMA,
  MODULE_DOCUMENTOS_PARTILHADOS,
  MODULE_DOSSIER_FISCAL_ALTERACOES_CAPITAL_PROPRIO,
  MODULE_DOSSIER_FISCAL_ANEXO_DEMONSTRACOES_FINANCEIRAS,
  MODULE_DOSSIER_FISCAL_BALANCO,
  MODULE_DOSSIER_FISCAL_DEMONSTRACAO_RESULTADOS,
  MODULE_DOSSIER_FISCAL_FLUXO_CAIXA,
  MODULE_DOSSIER_FISCAL_RESULTADOS_POR_NATUREZA,
  MODULE_EFATURA,
  MODULE_EMPRESAS,
  MODULE_ESTATISTICA_EXTRATOS_DT,
  MODULE_ESTATISTICA_EXTRATOS_DT_CLIENTES,
  MODULE_ESTATISTICA_EXTRATOS_DT_FORNECEDORES,
  MODULE_EXTRACTOSARTIGOS,
  MODULE_EXTRATOS_CLIENTES,
  MODULE_EXTRATOS_FORNECEDORES,
  MODULE_EXPORTACAO_COMPRAS,
  MODULE_FATURACAO_CONTRATOS,
  MODULE_FATURACAO_ELETRONICA_CONFIGURACOES,
  MODULE_FATURACAO_PUBLICA,
  MODULE_FCT,
  MODULE_FIM_ANO,
  MODULE_FLUXOS,
  MODULE_ENCOMENDAS,
  MODULE_GESTAO_FICHEIROS_SEPA,
  MODULE_GTO,
  MODULE_HABITACAO_PERMANENTE,
  MODULE_IES,
  MODULE_IMP_DADOS_EMP,
  MODULE_IMP_FFERPOC,
  MODULE_IMPAVISOSCOBRANCA,
  MODULE_IMPCTRIRS,
  MODULE_IMPEDE119,
  MODULE_IMPENCAR,
  MODULE_IMPORT_XLS,
  MODULE_IMPORTADOR_SAFT_DIRECT,
  MODULE_INFO_GLOBAL_ACRESCIMOS,
  MODULE_INFO_GLOBAL_DIFERIMENTOS,
  MODULE_INFO_GLOBAL_DIFERIMENTOS_ACRESCIMOS,
  MODULE_INTEGRACAO_DOCUMENTOS,
  MODULE_INTEGRACAO_SALARIOS,
  MODULE_INTEGRACAO_SALARIOS_MULTI,
  MODULE_INVENTARIOS,
  MODULE_LISTAGENS_MOVIMENTOS_PENDENTES_CLIENTES,
  MODULE_LISTAGENS_MOVIMENTOS_PENDENTES_FORNCEDORES,
  MODULE_LISTAGENS_MOVIMENTOS_PENDENTES_OUTROS_DEVEDORES_CREDORES,
  MODULE_LISTAGENS_RESUMO_IVA,
  MODULE_LISTAGENS_VENDAS_ANO_MES,
  MODULE_LISTAGENS_VENDAS_ARTIGO,
  MODULE_LISTAGENS_VENDAS_CLIENTE,
  MODULE_MAPA_DIFERIMENTOS_ACRESCIMOS,
  MODULE_MAPA_EXPLORACAO,
  MODULE_MAPA_PAGAMENTOS,
  MODULE_MARCACAO_REFEICOES,
  MODULE_MB_CONFIGS,
  MODULE_MODELO_10,
  MODULE_MODELO_22,
  MODULE_MODELO_25,
  MODULE_MODELO_39,
  MODULE_MODELO_30,
  MODULE_MODULES,
  MODULE_NOTIFICATION_CENTER,
  MODULE_OFFICE_REPORTING,
  MODULE_AT_FATURAS_RECIBOS_VERDES,
  MODULE_CONCILIACAO_EFATURAS,
  MODULE_PCA_CONTABILIDADE_BALANCETES,
  MODULE_PCA_CONTABILIDADE_EXTRATOS,
  MODULE_PCA_CONTABILIDADE_IDADE_SALDOS_CLIENTES,
  MODULE_PCA_CONTABILIDADE_IDADE_SALDOS_FORNECEDORES,
  MODULE_PCA_CONTABILIDADE_MOVIMENTOS_ABERTO_CLIENTE,
  MODULE_PCA_CONTABILIDADE_MOVIMENTOS_ABERTO_FORNECEDOR,
  MODULE_PCA_DASHBOARDS,
  MODULE_PCA_DOCUMENTOS_FISCAIS,
  MODULE_PCA_ESTATISTICA_BALANCETES,
  MODULE_PCA_ESTATISTICA_BALANCETES_ALTERNATIVOS,
  MODULE_PCA_ESTATISTICA_BALANCETES_ANALITICA,
  MODULE_PCA_ESTATISTICA_EXTRATOS,
  MODULE_PCA_ESTATISTICA_EXTRATOS_GRID,
  MODULE_PCA_ESTATISTICA_IMPDECPE,
  MODULE_PCA_ESTATISTICA_MOVIMENTOS_EM_ABERTO,
  MODULE_PCA_IMPORTADOR_EFATURA,
  MODULE_PCA_LISTAGEM_DE_DIARIOS,
  MODULE_PCA_MANUTENCAO_APURAIVA,
  MODULE_PCA_MANUTENCAO_APURAMENTO_RESULTADOS,
  MODULE_PCA_MANUTENCAO_DECLARACAOIVA,
  MODULE_PCA_MANUTENCAO_DECLARACAOIVA_RECAPITULATIVA,
  MODULE_PCA_MANUTENCAO_DOCS_NAO_SALDADOS,
  MODULE_PCA_MANUTENCAO_RECONSTRUCAO_ACUMULADOS,
  MODULE_PCA_MANUTENCAO_SVAT,
  MODULE_PCA_MANUTENCAO_VERIFICAO_ACUMULADOS,
  MODULE_PCA_MYACCOUNT_CONTAS_CLIENTE,
  MODULE_PCA_MYACCOUNT_DOCUMENTOS_COMERCIAIS,
  MODULE_PCA_MYACCOUNT_EXTRATOS_CONTA,
  MODULE_PCA_MYACCOUNT_MOVIMENTOS_ABERTO,
  MODULE_PCA_RH_GESTAO_DGEMPS_ABONOS,
  MODULE_PCA_RH_GESTAO_DGEMPS_FALTAS,
  MODULE_PCA_RH_GESTAO_DGEMPS_FERIAS,
  MODULE_PCA_RH_MAPA_FALTAS,
  MODULE_PCA_RH_MAPA_FERIAS,
  MODULE_PCA_RH_MAPA_FUNDOS_COMPENSACAO,
  MODULE_PCA_RH_MAPA_SEGURADORA,
  MODULE_PCA_RH_MAPA_SEGURANCA_SOCIAL,
  MODULE_PCA_RH_MAPA_SINDICATOS,
  MODULE_PCA_RH_RECIBOS_VENCIMENTOS,
  MODULE_PCA_RH_SIMULACAO_VENCIMENTO,
  MODULE_PERFIL_CATEGORIA_ABONO,
  MODULE_PERFIL_CATEGORIA_DESCONTO,
  MODULE_PREV_TES_OBRAS,
  MODULE_PREVI_TES,
  MODULE_PREVI_TES_AUTO,
  MODULE_POCS_COPY,
  MODULE_PROC_SEGURADORA_FOLHA_FERIAS,
  MODULE_PROCE_DMR_AT,
  MODULE_PROCE_SALARIOS,
  MODULE_PROCE_SEG_SOCIAL,
  MODULE_RECIBOS_VENCIMENTO,
  MODULE_REFEICOES_ASSOCIAR_MENUS,
  MODULE_REFEICOES_LISTAGEM_MENUS,
  MODULE_REFEICOES_LISTAGEM_REFEICOES,
  MODULE_RETENCOES,
  MODULE_RH_ATUALIZA_FERIAS_EMP,
  MODULE_RH_CALCULO_RENDIMENTO_LIQUIDO,
  MODULE_RH_CESSA_CONTRATO_TRABALHO,
  MODULE_RH_CONFIGURACOES_DADOS_CONFIG_FICHA,
  MODULE_RH_CONFIGURACOES_GOZO_FERIAS,
  MODULE_RH_CONFIGURACOES_INTEGRACAO_SALARIOS,
  MODULE_RH_CONFIGURACOES_INTEGRACAO_SALARIOS,
  MODULE_RH_CONFIGURACOES_TRANSFERENCIAS_BANCARIAS,
  MODULE_RH_COPIA_ABONOS_DESCONTOS,
  MODULE_RH_ESTATISTICAS,
  MODULE_RH_ESTATISTICAS_MULTI,
  MODULE_RH_FOLFER,
  MODULE_RH_GESTAO_FICHEIROS_DMR_AT,
  MODULE_RH_IMPVENCT,
  MODULE_RH_MANUTENCAO,
  MODULE_RH_MANUTENCAO_CAMPOS,
  MODULE_RH_MAPAS_SINDICATOS,
  MODULE_RH_PROC_SEG_SOCIAL_CONSULT_ENVIOS,
  MODULE_RH_PROCESSAMENTO_SALARIOS,
  MODULE_RH_QPIRCT,
  MODULE_RH_RECOLHA_SERIE_SUBSIDIOS_ALIMENTACAO,
  MODULE_RH_RECOLHA_SERIE_SUBSIDIOS_FERIAS,
  MODULE_RH_RECOLHA_SERIE_SUBSIDIOS_NATAL,
  MODULE_RH_REGISTAR_EMPREGADOS_SS,
  MODULE_RH_RELATORIO_UNICO,
  MODULE_RH_TRANS_SAL,
  MODULE_RH_SEG_SOCIAL_FOLHA_FERIAS,
  MODULE_SAFT,
  MODULE_SAFT_CONTAB,
  MODULE_TAXONOMIAS,
  MODULE_TERCEIROS,
  MODULE_TRDOCS_CABS_WIZARD,
  MODULE_TRF_BA_RH,
  MODULE_TRFLANCCONTAS,
  MODULE_TRIBUT_AUTONOMAS,
  MODULE_TRIBUT_AUTONOMAS_ANALISE,
  MODULE_UTL_VIAT_PATRONAL,
  MODULE_VISUALIZAR_REFEICOES,
  MODULE_RPTCONFIGS,
  MODULE_GRUPO_DOCFA_CONFIGURACOES,
  MODULE_ANALISE_MARGEM_LUCRO
];

export function configModules(moduleRegistryService: ModuleRegistryService, logger: Logger): void {
  for (const module of MODULES) {
    try {
      if (isArray(module)) {
        moduleRegistryService.register(CGInjector.invoke(<TInjectorResolvable>module));
      } else {
        moduleRegistryService.register(<IModuleDefinition>module);
      }
    } catch (exception: unknown) {
      logger.error(`Error while trying to register [${(<IModuleDefinition>module).name}] module`, module, exception);
    }
  }
}
