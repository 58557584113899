<pl-form [(formInstance)]="form" (evtSubmitted)="close()">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'docscontabilidade.retencao.title'" [translateParams]="{value: getTitle()}"></h4>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <pl-group>
          <label [translate]="'docscontabilidade.disponibilidadeRendimento'"></label>
          <edit>
            <!--            <pl-edit type="radiogroup" [model]="model.dispoRendimento" (modelChange)="dispoRendimentoChanged($event)" [properties]="radioGroupDisponibilidadeRendimento"></pl-edit>-->
            <pl-edit type="radiogroup" [(model)]="model.dispoRendimento" [properties]="radioGroupDisponibilidadeRendimento"></pl-edit>
          </edit>
        </pl-group>
      </div>

      <div class="col-12">
        <pl-group>
          <label [translate]="'docscontabilidade.contaCorrenteTemRetencaoDeduzida'"></label>
          <edit>
            <div class="d-inline-block">
              <pl-tooltip [config]="tooltipRetencaoDeduzida">
                <pl-edit
                  *plTooltipContent
                  type="boolean"
                  attrName="contaCorrenteTemRetencaoDeduzida"
                  [(model)]="model.contaCorrenteTemRetencaoDeduzida"
                  [properties]="{disabled: !tooltipRetencaoDeduzida.disabled}">
                </pl-edit>
              </pl-tooltip>
            </div>
          </edit>
        </pl-group>
      </div>

      <div class="col-12 col-lg-6">
        <pl-group>
          <label [translate]="'docscontabilidade.montanteTributavel'"></label>
          <edit>
            <pl-edit type="number" attrName="valorTributavel" [model]="model.valorTributavel" (modelChange)="valorTributavelChanged($event)" plAutoFocus></pl-edit>
          </edit>
        </pl-group>
      </div>
      <div class="col-12 col-lg-6">
        <pl-group>
          <label [translate]="'docscontabilidade.montanteRetido'"></label>
          <edit>
            <pl-edit type="number" attrName="valorRetido" [model]="model.valorRetido" (modelChange)="valorRetidoChanged($event)">
              <div *inputGroup="{klass: 'btn-light'}">
                <i class="fa fa-fw fa-pencil" plDelegateEventDownwardsClick (click)="changeValorTaxa()" (keyup)="changeValorTaxa($event)" tabindex="0"></i>
              </div>
            </pl-edit>
          </edit>
        </pl-group>
      </div>

      <div class="col-12">
        <fieldset class="mt-3" *ngIf="linha.entNIF.txRet.tipoDeclaracao === tiposDeclaracoes.Modelo30">
          <legend [translate]="'docscontabilidade.retencao.modelo30.title'"></legend>
          <div class="row">
            <div class="col-12">
              <pl-group>
                <label [translate]="'docscontabilidade.retencao.modelo30.nGuiaPagamento'"></label>
                <edit>
                  <pl-edit type="text" attrName="numeroGuiaPagamento" [(model)]="model.numeroGuiaPagamento" [properties]="{validators: {maxlength: {value: 40}}}"> </pl-edit>
                </edit>
              </pl-group>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button type="submit" klass="btn-primary action-save"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.apply'"></span> </pl-button>
    <pl-button type="button" klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</pl-form>
