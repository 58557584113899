<div class="modal-header">
  <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'docscomerciais.codATPerPhone'"></h4>
</div>

<div class="modal-body">
  <pl-form [properties]="{orientation: 'horizontal'}">
    <pl-group>
      <label class="control-label" [translate]="'docscomerciais.doc.cab.atDocCodeID'"></label>
      <edit>
        <pl-edit-text attrName="cod-at" [(model)]="codAT" [properties]="{validators: {required: {value: true}}}" plAutoFocus></pl-edit-text>
      </edit>
    </pl-group>
  </pl-form>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-sm btn-success action-save" [disabled]="closeDisabled" [click]="close" plPromise>
    <i class="fa fa-refresh"></i>&nbsp;<span [translate]="'docscomerciais.markAsCompleted'"></span>
  </button>

  <button type="button" class="btn btn-sm btn-light action-dismiss" [disabled]="closeDisabled" (click)="dismiss()">
    <i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
  </button>
</div>
