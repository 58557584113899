import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IGTOAlertasSubTableItem, IGTOAlertasTableItem} from '../../gto.module.interface';
import {IJsonGTOAlerta, IJsonGTOAlertsRequest} from '../../jsonGTO.module.interface';
import {isArray, isUndefinedOrNull} from 'pl-comps-angular';
import {GtoService} from '../../gto.module.service';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {HttpResponse} from '@angular/common/http';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'gto-alerts-modal',
  templateUrl: './gto.alerts.modal.component.html'
})
export class GtoAlertsModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public requestData: IJsonGTOAlertsRequest;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly dataGridDefinitionSubTable: IDevExpressDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _gtoService: GtoService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nEmpresa', dataType: 'string', caption: 'gto.fields.nEmpresa'},
        {dataField: 'nomeEmpresa', dataType: 'string', caption: 'gto.fields.nomeEmpresa'}
      ],
      dataSource: [],
      export: {filename: 'gto.alertsModalTitle'},
      masterDetail: {enabled: true, autoExpandAll: true, template: 'detailTemplate'},
      remoteOperations: false
    };

    this.dataGridDefinitionSubTable = {
      columnHidingEnabled: false,
      allowColumnReordering: false,
      groupPanel: {
        visible: false
      },
      toolbar: {
        visible: false
      },
      columns: [
        {dataField: 'nomeTarefa', dataType: 'string', caption: 'gto.fields.nomeTarefa'},
        {dataField: 'expireDate', dataType: 'date', caption: 'gto.fields.data'}
      ],
      dataSource: [],
      export: {enabled: false},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      columnChooser: {enabled: false},
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    this.dataGridDefinition.dataSource = new CustomStore({
      load: () => this._getMainTableSource()
    });
  }

  private _getMainTableSource(): Promise<Array<IGTOAlertasTableItem>> {
    const source: Array<IGTOAlertasTableItem> = [];
    return this._gtoService.getAlerts(this.requestData).then((response: HttpResponse<Array<IJsonGTOAlerta>>) => {
      const helper: {[nempresa: string]: {nome: string; items: Array<IGTOAlertasSubTableItem>}} = {};
      if (isArray(response.body)) {
        response.body.forEach((item: IJsonGTOAlerta) => {
          if (isUndefinedOrNull(helper[item.nEmpresa])) {
            helper[item.nEmpresa] = {nome: item.nomeEmpresa, items: []};
          }
          helper[item.nEmpresa].items.push({nomeTarefa: item.nomeTarefa, expireDate: item.expireDate});
        });

        for (const nempresa of Object.keys(helper)) {
          source.push({
            nEmpresa: nempresa,
            nomeEmpresa: helper[nempresa].nome,
            items: helper[nempresa].items
          });
        }
      }
      return source;
    });
  }
}
