<pl-form class="bancos-extrato-module-activate-license-modal" [submit]="close" plPromise>
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'bancosextrato.activatelicensemodal.title'" [translateParams]="{nEmpresa: nEmpresa, nomeEmpresa: nomeEmpresa}"></h5>
  </div>

  <div class="modal-body" [plPromise]="promise">
    <ng-container [ngSwitch]="dadosLicenca.nEmpresasPorConsumir > 0">
      <ng-container *ngSwitchCase="true">
        <p [translate]="'bancosextrato.activatelicensemodal.message1'"></p>
        <p [translate]="'bancosextrato.activatelicensemodal.message2'" [translateParams]="{totalToConsume: dadosLicenca.nEmpresasPorConsumir}"></p>
      </ng-container>
      <div
        class="text-danger"
        *ngSwitchCase="false"
        [translate]="'bancosextrato.errors.activateCGBankingLimitReached'"
        [translateParams]="{consumidas: dadosLicenca.nEmpresasConsumidas, total: dadosLicenca.nEmpresasTotal}"></div>
    </ng-container>
    <br />
    <h6 class="mb-2" [translate]="'bancosextrato.activatelicensemodal.dadosLicenca'"></h6>
    <div [translate]="'bancosextrato.activatelicensemodal.total'" [translateParams]="{total: dadosLicenca.nEmpresasTotal}"></div>
    <div [translate]="'bancosextrato.activatelicensemodal.totalConsumed'" [translateParams]="{totalConsumed: dadosLicenca.nEmpresasConsumidas}"></div>
    <div [translate]="'bancosextrato.activatelicensemodal.totalToConsume'" [translateParams]="{totalToConsume: dadosLicenca.nEmpresasPorConsumir}"></div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button
      type="submit"
      klass="btn-primary action-submit"
      [disabled]="closeDisabled || dadosLicenca.nEmpresasPorConsumir <= 0"
      plAutoFocus
      [plAutoFocusDisabled]="dadosLicenca.nEmpresasPorConsumir <= 0">
      <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.yes'"></span>
    </pl-button>

    <pl-button klass="btn-light action-dismiss" [disabled]="closeDisabled" (evtClicked)="dismiss()" plAutoFocus [plAutoFocusDisabled]="dadosLicenca.nEmpresasPorConsumir > 0">
      <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.no'"></span>
    </pl-button>
  </div>
</pl-form>
