<div class="faturacao-eletronica-configuracoes-add-safe-certificado-modal">
  <pl-form class="entity-detail-form" [submit]="close" plPromise>
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="modalTitle"></h5>
    </div>

    <div class="modal-body">
      <pl-group>
        <edit>
          <pl-edit-radio-group attrName="tipoCidadao" [(model)]="tipoCidadao" [properties]="propertiesTipoCidadao"> </pl-edit-radio-group>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'faturacaoeletronicaconfiguracoes.addsafecertificadomodal.fields.nomeConta'"></label>
        <edit>
          <pl-edit-text attrName="nomeConta" [(model)]="nomeConta" [properties]="{validators: {maxlength: {value: 25}, pattern: validatorNomeConta}}" plAutoFocus></pl-edit-text>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'faturacaoeletronicaconfiguracoes.addsafecertificadomodal.fields.email'"></label>
        <edit>
          <pl-edit-email attrName="email" [(model)]="email" [properties]="{validators: {maxlength: {value: 320}}}"></pl-edit-email>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'faturacaoeletronicaconfiguracoes.addsafecertificadomodal.fields.validade'"></label>
        <edit>
          <pl-edit-datepicker attrName="validade" [(model)]="validade"></pl-edit-datepicker>
        </edit>
      </pl-group>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button type="submit" klass="btn-sm btn-primary action-submit"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.add'"></span></pl-button>
      <pl-button klass="btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
    </div>
  </pl-form>
</div>
