<div class="rhqpirct-imported-list-modal" [plPromise]="promise">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'rhqpirct.modal.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'rhqpirct.card.codirct'"></label>
      <edit>
        <pl-edit type="text" attrName="codIRCT" [(model)]="qpirctImp.codIRCT" [properties]="{placeholder: 'rhqpirct.card.codirct'}"></pl-edit>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'rhqpirct.card.nomeirct'"></label>
      <edit>
        <pl-edit type="text" attrName="nomeIRCT" [(model)]="qpirctImp.nome" [properties]="{placeholder: 'rhqpirct.card.nomeirct'}"></pl-edit>
      </edit>
    </pl-group>
    <dx-data-grid [cgDxDataGrid]="tableDefinition" [dataSource]="tableDefinition.dataSource"> </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success action-dismiss" [onClick]="close" [plPromise]="promise"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span> </pl-button>
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()" [plPromise]="promise"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
