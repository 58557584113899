import moment from 'moment';
import {fromEvent, Subscription} from 'rxjs';
import {DxDataGridComponent, DxTooltipComponent} from 'devextreme-angular';
import type dxDataGrid from 'devextreme/ui/data_grid';
import ArrayStore from 'devextreme/data/array_store';
import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {
  IPlDynamicVisualsSecondaryClickAction,
  IPlFormatConfig,
  IPlTooltipConfig,
  isDefinedNotNull,
  isEmpty,
  isObject,
  isString,
  isUndefinedOrNull,
  PlAlertService,
  PlDocumentService,
  PlI18nService,
  PlTranslateService
} from 'pl-comps-angular';
import {AppService} from '../../../../../services/app/app.service';
import {AuthService} from '../../../../../services/auth/auth.service';
import {CGCardPanelComponent} from '../../../../../components/cg/cardpanel/cardpanel.component';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {ConfigService} from '../../../../../services/config/config.service';
import {DevExpressDataGridUIService} from '../../../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {EGTOExecuteEstado, EGTOMostrarFilter, EGTOTarefasMarcacaoState, IJsonGTOCheckDMR, IJsonGTOTarefasMulti} from '../../jsonGTO.module.interface';
import {GtoAlertsModalComponent} from '../../modals/alerts/gto.alerts.modal.component';
import {GtoCreateEditTaskModalComponent} from '../../modals/createedittask/gto.createEditTask.modal.component';
import {GtoExecuteModalComponent} from '../../modals/execute/gto.execute.modal.component';
import {GtoService} from '../../gto.module.service';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellHoverChanged,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnContextMenuPreparing,
  IDevExpressDataGridEventOnInitialized
} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {
  IDynamicGTOTarefaMulti,
  IGTOCellInfo,
  IGTOMultiFilters,
  IGTOResponsavel,
  IGTOSelectedRange,
  IGTOTabMultiCallback,
  IGTOTarefa,
  IGTOTarefaMarc,
  IGTOTarefaMultiItem,
  IGTOTarefaMultiRow,
  IGTOTarefasMulti
} from '../../gto.module.interface';
import {IJsonErpUser, TUserSession} from '../../../../../services/account/jsonUserApi.interface';
import {IJsonNumber} from '../../../../../../common/interfaces/json';
import {TDevExpressDataGridColumnDataType} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.types.interface';

@Component({
  selector: 'gto-multi-empresa',
  templateUrl: './gto.multiEmpresa.component.html'
})
export class GTOMultiEmpresaComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit, OnDestroy {
  @ViewChild(DxTooltipComponent) public readonly tooltip: DxTooltipComponent;
  @ViewChild(DxDataGridComponent) public readonly dataGrid: DxDataGridComponent;
  @Input() public sourceAnos: Array<number>;
  @Input() public sourceResponsaveis: Array<IGTOResponsavel>;
  @Input() public centralGestId: number;
  @Input() public storeModePublic: boolean;
  @Input() public callback: IGTOTabMultiCallback;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly gtoMostrarFilterEnum: typeof EGTOMostrarFilter;
  public readonly configTooltip: IPlTooltipConfig;
  public usersTemplate: string;
  public gtoMultiFilters: IGTOMultiFilters;
  public gtoMultiTarefas: IGTOTarefasMulti;
  public gtoMostrarFilter: EGTOMostrarFilter;
  public tableHeaderDynamic: Array<string>;
  public selectAllMulti: boolean;
  public concluidoDentroPrazoCount: number;
  public guardarValidacaoCount: number;
  public emAtrasoJustificadoCount: number;
  public concluidoForaPrazoCount: number;
  public emAtrasoCount: number;
  public prazoADecorrerCount: number;
  public naoAplicavelCount: number;
  public collapsedPesqAvancada: boolean;
  public captionCheckStateDMR: string;
  public sourceTarefas: Array<string>;
  public promise: Promise<void>;
  public cellToolTipText: string;

  private readonly _shiftSelectRange: IGTOSelectedRange;
  private readonly _cellInfos: Array<IGTOCellInfo>;
  private readonly _decimais: number;
  private readonly _subscriptionIsMobile: Subscription;
  private readonly _subscriptionFormat: Subscription;
  private _subscriptionTouchMove: Subscription;
  private _formatDateTime: string;
  private _dataGridInstance: dxDataGrid;
  private _auxERPUser: IJsonErpUser;
  private _dynamicGTOTarefaMulti: Array<IDynamicGTOTarefaMulti>;
  private _cellData: Array<IGTOCellInfo>;
  private _selectedRange: IGTOSelectedRange;
  private _isSelectionStopped: boolean;
  private _cardPanel: CGCardPanelComponent;
  private _isMobile: boolean;

  constructor(
    private readonly _plAlertService: PlAlertService,
    private readonly _plDocumentService: PlDocumentService,
    private readonly _plI18nService: PlI18nService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _appService: AppService,
    private readonly _authService: AuthService,
    private readonly _cgModalService: CGModalService,
    private readonly _configService: ConfigService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService,
    private readonly _gtoService: GtoService
  ) {
    this._decimais = this._configService.configurations.contabilidade.decimais.valor;
    this.configTooltip = {disabled: false, text: 'gto.tabs.btn.alertas', placement: 'left', container: 'body', tooltipClass: 'tooltip-warning'};
    this.gtoMostrarFilterEnum = EGTOMostrarFilter;
    this.gtoMostrarFilter = EGTOMostrarFilter.DataLimite;
    this.tableHeaderDynamic = [];
    this.sourceTarefas = [];
    this.gtoMultiTarefas = {haveAlerts: false, haveDMRAT: false, haveDMRSS: false, list: []};
    this.captionCheckStateDMR = 'gto.tabs.btn.checkstatedmratss';
    this.gtoMultiFilters = {
      ano: moment().year(),
      mes: moment().month() + 1,
      tipoTarefa: -1,
      nResponsavel: undefined,
      periodoIVA: undefined,
      regimeIRC: undefined,
      nomeTarefa: ''
    };
    this.selectAllMulti = false;
    this._authService.identity().then((session: TUserSession) => {
      this._auxERPUser = session.erp;
    });
    this.concluidoDentroPrazoCount = 0;
    this.guardarValidacaoCount = 0;
    this.emAtrasoJustificadoCount = 0;
    this.concluidoForaPrazoCount = 0;
    this.emAtrasoCount = 0;
    this.prazoADecorrerCount = 0;
    this.naoAplicavelCount = 0;
    this.collapsedPesqAvancada = true;
    this._dynamicGTOTarefaMulti = [];
    this.dataGridDefinition = {
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      columns: this._evaluateDataGridDefinitionColumns(),
      dataSource: [],
      export: {filename: 'gto.tabs.title.porEmpresa'},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false},
      headerFilter: {visible: false},
      height: '60vh',
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      scrolling: {rowRenderingMode: 'virtual'},
      searchPanel: {visible: true},
      selection: {mode: 'multiple', selectAllMode: 'allPages', showCheckBoxesMode: 'always'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplateTable',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton',
          'searchPanel'
        ]
      }
    };
    this._cellData = [];
    this._selectedRange = {};
    this._shiftSelectRange = {};
    this._isSelectionStopped = true;
    this._cellInfos = [];
    this._isMobile = false;

    this._subscriptionIsMobile = this._plDocumentService.isMobile().subscribe((isMobile: boolean) => {
      this._setIsMobile(isMobile);
    });

    this._subscriptionFormat = this._appService.format().subscribe((format: IPlFormatConfig) => {
      this._formatDateTime = format.momentDatetime;
    });
  }

  public ngOnInit(): void {
    this.usersTemplate = !this.storeModePublic ? '{{nResponsavel}} - {{nomeResponsavel}}' : '{{nomeResponsavel}}';
  }

  public ngOnChanges({callback}: SimpleChanges): void {
    if (callback) {
      const cb: IGTOTabMultiCallback = callback.currentValue;
      if (isObject(cb)) {
        cb.refresh = () => this._pesquisar();
        cb.getSelectedRow = () => this._getSelectedRow();
        cb.repaintGridOnMobile = () => {
          if (this._isMobile) {
            this._dataGridInstance.repaint();
          }
        };
      }
    }
  }

  public ngAfterViewInit(): void {
    const dataGridElement = this.dataGrid.instance.element();
    this._subscriptionTouchMove = fromEvent(dataGridElement, 'touchmove').subscribe((event: TouchEvent) => {
      const touch = event.touches[0];
      const element = document.elementFromPoint(touch.clientX, touch.clientY);
      const cellInfo = this._cellInfos.find((x: IGTOCellInfo) => x.cellElement === element);
      if (cellInfo) {
        this._selectedRange.endRowIndex = cellInfo.rowIndex;
        this._selectedRange.endColumnIndex = cellInfo.columnIndex;
        this._showSelection(this.dataGrid.instance, this._selectedRange);
      }
    });
  }

  public ngOnDestroy(): void {
    if (this._subscriptionTouchMove) {
      this._subscriptionTouchMove.unsubscribe();
    }
    this._subscriptionIsMobile.unsubscribe();
    this._subscriptionFormat.unsubscribe();
  }

  public checkStateDMR(checkDMRAT: boolean, checkDMRSS: boolean): Promise<void> {
    const nEmpresaList: string = this._dataGridInstance.getSelectedRowKeys().join(',');
    if (isEmpty(nEmpresaList)) {
      this._plAlertService.error('gto.messages.selempreforcheck');
      return Promise.resolve();
    }
    return this._gtoService
      .checkDMRMulti(this.gtoMultiFilters.ano, this.gtoMultiFilters.mes, this.gtoMultiFilters.tipoTarefa, this.gtoMultiFilters.nResponsavel, checkDMRAT, checkDMRSS, nEmpresaList)
      .then((response: HttpResponse<IJsonGTOCheckDMR>) => {
        this._plAlertService.success('gto.messages.validacaosuccess');
        if (response.body.needRefresh) {
          return this._pesquisar();
        }
        return Promise.resolve();
      });
  }

  public openAlertsModal(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(GtoAlertsModalComponent, {size: 'lg'});
    const componentInstance: GtoAlertsModalComponent = modalInstance.componentInstance;
    componentInstance.requestData = {
      isMulti: true,
      nempresa: '',
      ano: this.gtoMultiFilters.ano,
      mes: this.gtoMultiFilters.mes,
      tipoTarefa: this.gtoMultiFilters.tipoTarefa,
      nResponsavel: this.gtoMultiFilters.nResponsavel,
      periodoIVA: this.gtoMultiFilters.periodoIVA,
      regimeIRC: this.gtoMultiFilters.regimeIRC,
      nomeTarefa: this.gtoMultiFilters.nomeTarefa
    };
    return modalInstance.result;
  }

  public userSelect(user: string | IGTOResponsavel): void {
    let nResponsavel: number;
    let nomeResponsavel: string;
    if (isString(user)) {
      nResponsavel = Number(user);
      if (Number.isNaN(nResponsavel)) {
        nResponsavel = undefined;
      } else {
        const responsavel: IGTOResponsavel = this.sourceResponsaveis.find((item: IGTOResponsavel) => item.nResponsavel === nResponsavel);
        if (responsavel) {
          user = responsavel;
        }
      }
    }
    if (isObject(user)) {
      user = <IGTOResponsavel>user;
      nResponsavel = user.nResponsavel;
      nomeResponsavel = user.nomeResponsavel;
    }
    this.gtoMultiFilters = {
      ...this.gtoMultiFilters,
      nResponsavel: nResponsavel,
      nomeResponsavel: nomeResponsavel
    };
  }

  public getHint(tarefaMarc: IGTOTarefaMultiItem): string {
    let hintText = '';
    switch (tarefaMarc.state) {
      case EGTOTarefasMarcacaoState.NaoAplicavel:
        hintText = this._plTranslateService.translate('gto.estados.naoAplicavel');
        break;
      case EGTOTarefasMarcacaoState.GuardarValidacao:
        hintText = this._plTranslateService.translate('gto.estados.guardarValidacao');
        break;
      case EGTOTarefasMarcacaoState.EmAtrasoJustificado:
        hintText = this._plTranslateService.translate('gto.hints.emAtrasoJustificado', {justificacao: tarefaMarc.justificacao});
        break;
      case EGTOTarefasMarcacaoState.ConcluidoDentroPrazo:
        if (isUndefinedOrNull(tarefaMarc.dataExec)) {
          hintText = '';
          break;
        }
        hintText = this._plTranslateService.translate('gto.hints.concluidoDentroPrazo', {
          dataExec: moment(tarefaMarc.dataExec).format(this._formatDateTime),
          user: tarefaMarc.nUtilizNameExec
        });
        break;
      case EGTOTarefasMarcacaoState.ConcluidoForaPrazo:
        hintText = this._plTranslateService.translate('gto.hints.concluidoForaPrazo', {
          dataExec: moment(tarefaMarc.dataExec).format(this._formatDateTime),
          user: tarefaMarc.nUtilizNameExec
        });
        break;
      default:
        break;
    }

    if (tarefaMarc.valor.length) {
      if (hintText.length) {
        hintText += '<br/>';
      }
      hintText += this._plTranslateService.translate('gto.hints.valor', {valor: tarefaMarc.valor});
    }

    if (tarefaMarc.nota.length) {
      if (hintText.length) {
        hintText += '<br/>';
      }
      hintText += this._plTranslateService.translate('gto.hints.nota', {nota: tarefaMarc.nota});
    }

    return hintText;
  }

  public gtoMostrarFilterChanged(): void {
    this.dataGridDefinition.columns = this._evaluateDataGridDefinitionColumns();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IDynamicGTOTarefaMulti>): void {
    if (event.rowType === 'data') {
      this._cellInfos.push({
        cellElement: event.cellElement,
        rowIndex: event.rowIndex,
        columnIndex: event.columnIndex,
        tarefaMarc: undefined
      });

      event.cellElement.addEventListener('touchstart', () => {
        this._selectedRange.startRowIndex = this._selectedRange.endRowIndex = event.rowIndex;
        this._selectedRange.startColumnIndex = this._selectedRange.endColumnIndex = event.columnIndex;
        this._showSelection(event.component, this._selectedRange);
      });

      if (!event.column.type && event.column.dataField) {
        const gtoTarefaMultiItem: IGTOTarefaMultiItem = this._getGTOTarefaMultiItem(event.column.dataField, event.data);
        if (gtoTarefaMultiItem && !isEmpty(gtoTarefaMultiItem._cssClass)) {
          event.cellElement.classList.add(gtoTarefaMultiItem._cssClass);

          fromEvent(event.cellElement, 'mouseover', {passive: true}).subscribe((mouseEvent: MouseEvent) => {
            this.cellToolTipText = this.getHint(gtoTarefaMultiItem);
            if (this.cellToolTipText.length) {
              this.tooltip.instance.show(<HTMLElement>mouseEvent.target);
            }
          });

          fromEvent(event.cellElement, 'mouseout', {passive: true}).subscribe(() => {
            this.tooltip.instance.hide();
          });
        }
      }
    }
  }

  public onCellHoverChanged(event: IDevExpressDataGridEventOnCellHoverChanged<IDynamicGTOTarefaMulti>): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((<MouseEvent>(<any>event).event).buttons === 1) {
      if (this._isSelectionStopped) {
        this._isSelectionStopped = false;
        this._selectedRange = {};
      }
      if (this._selectedRange.startRowIndex === undefined) {
        this._selectedRange.startRowIndex = event.rowIndex;
      }
      if (this._selectedRange.startColumnIndex === undefined) {
        this._selectedRange.startColumnIndex = event.columnIndex;
      }

      this._selectedRange.endRowIndex = event.rowIndex;
      this._selectedRange.endColumnIndex = event.columnIndex;
      this._showSelection(event.component, this._selectedRange);
    } else {
      this._isSelectionStopped = true;
    }
  }

  public onCellClick(e: IDevExpressDataGridEventOnCellClick<IDynamicGTOTarefaMulti>): void {
    //Save the first hovered cell
    if (e.rowType !== 'data' || isUndefinedOrNull(e.column.dataField) || ['nEmpresa', 'nomeEmpresa'].includes(e.column.dataField)) {
      return;
    }
    const gtoTarefaMultiItem: IGTOTarefaMultiItem = this._getGTOTarefaMultiItem(e.column.dataField, e.row.data);
    if (isUndefinedOrNull(gtoTarefaMultiItem) || isEmpty(gtoTarefaMultiItem.gtoTarefaID)) {
      return;
    }

    if (e.event && e.event.ctrlKey === true && e.event.shiftKey === false) {
      //selects or deselects a single cell when Ctrl + Left Click
      if (e.cellElement.classList.contains('cell-selected')) {
        this._cellData = this._cellData.filter((item: IGTOCellInfo) => !(item.rowIndex === e.rowIndex && item.columnIndex === e.columnIndex));
        e.cellElement.classList.remove('cell-selected');
      } else {
        this._cellData.push({rowIndex: e.rowIndex, columnIndex: e.columnIndex, tarefaMarc: gtoTarefaMultiItem});
        e.cellElement.classList.add('cell-selected');
      }
    } else if (e.event && e.event.ctrlKey === false && e.event.shiftKey === true) {
      //Selection via Shift + Left Click
      this._shiftSelectRange.endRowIndex = e.rowIndex;
      this._shiftSelectRange.endColumnIndex = e.columnIndex;
      this._showSelection(e.component, this._shiftSelectRange);
    } else {
      this._shiftSelectRange.startRowIndex = e.rowIndex;
      this._shiftSelectRange.startColumnIndex = e.columnIndex;
      this._selectedRange.startRowIndex = this._selectedRange.endRowIndex = e.rowIndex;
      this._selectedRange.startColumnIndex = this._selectedRange.endColumnIndex = e.columnIndex;
      this._isSelectionStopped = false;
      this._showSelection(e.component, this._selectedRange);
    }
  }

  public onContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IDynamicGTOTarefaMulti>): void {
    if (
      event.target === 'content' &&
      event.row.rowType === 'data' &&
      !event.column.type &&
      event.column.dataField !== 'nEmpresa' &&
      event.column.dataField !== 'nomeEmpresa' &&
      event.column.dataField !== 'autoSizingCol'
    ) {
      event.event.preventDefault();
      const gtoTarefaMultiItem: IGTOTarefaMultiItem = this._getGTOTarefaMultiItem(event.column.dataField, event.row.data);
      if (isEmpty(gtoTarefaMultiItem.gtoTarefaID)) {
        return;
      }
      if (this._cellData.length <= 1) {
        const onCellClickEvent: IDevExpressDataGridEventOnCellClick<IDynamicGTOTarefaMulti> = {
          ...(<IDevExpressDataGridEventOnCellClick<IDynamicGTOTarefaMulti>>(<unknown>event)),
          rowType: event.row.rowType
        };
        this.onCellClick(onCellClickEvent);
      }
      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActions(event.row.data, gtoTarefaMultiItem);
      this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions);
    }
  }

  public onContentReady(): void {
    this._cellData = [];
  }

  public getDataLimitePlusNotesTmplValue(row: IGTOTarefa, column: IDevExpressDataGridColumn): string {
    const jsonPathArray = column.dataField.split('.');
    const marcacao: IGTOTarefaMarc = row[jsonPathArray[0]];
    let outputHtml = '';
    if (isDefinedNotNull(marcacao.data)) {
      outputHtml = `<div class="gto-table-tmpl-cell-value">${moment(marcacao.data).format('DD/MM')}</div>`;
      if (marcacao.nota.length) {
        outputHtml = `${outputHtml}<div class="gto-table-tmpl-cell-nota"><span class="badge badge-secondary"><b>Nota:</b> ${marcacao.nota}</span></div>`;
      }
    }
    return outputHtml;
  }

  public getDataExecucaoPlusNotesTmplValue(row: IGTOTarefa, column: IDevExpressDataGridColumn): string {
    const jsonPathArray = column.dataField.split('.');
    const marcacao: IGTOTarefaMarc = row[jsonPathArray[0]];
    let outputHtml = '';
    if (isDefinedNotNull(marcacao.dataExec)) {
      outputHtml = `<div class="gto-table-tmpl-cell-value">${moment(marcacao.dataExec).format('DD/MM')}</div>`;
      if (marcacao.nota.length) {
        outputHtml = `${outputHtml}<div class="gto-table-tmpl-cell-nota"><span class="badge badge-secondary"><b>Nota:</b> ${marcacao.nota}</span></div>`;
      }
    }
    return outputHtml;
  }

  public getDiasPlusNotesTmplValue(row: IGTOTarefa, column: IDevExpressDataGridColumn): string {
    const jsonPathArray = column.dataField.split('.');
    const marcacao: IGTOTarefaMarc = row[jsonPathArray[0]];
    let outputHtml = '';
    if (isDefinedNotNull(marcacao.data)) {
      outputHtml = `<div class="gto-table-tmpl-cell-value">${moment(marcacao.data).format('DD')}</div>`;
      if (marcacao.nota.length) {
        outputHtml = `${outputHtml}<div class="gto-table-tmpl-cell-nota"><span class="badge badge-secondary"><b>Nota:</b> ${marcacao.nota}</span></div>`;
      }
    }
    return outputHtml;
  }

  public getValuesPlusNotesTmplValue(row: IGTOTarefa, column: IDevExpressDataGridColumn): string {
    const jsonPathArray = column.dataField.split('.');
    const marcacao: IGTOTarefaMarc = row[jsonPathArray[0]];
    let outputHtml = '';
    if (isDefinedNotNull(marcacao.valor) && marcacao.valor.length) {
      outputHtml = `<div class="gto-table-tmpl-cell-value">${this._plI18nService.formatNumber(marcacao.valor, this._decimais)}</div>`;
      if (marcacao.nota.length) {
        outputHtml = `${outputHtml}<div class="gto-table-tmpl-cell-nota"><span class="badge badge-secondary"><b>Nota:</b> ${marcacao.nota}</span></div>`;
      }
    }
    return outputHtml;
  }

  public readonly fnGetData = (search: string, page: number, perPage: number): Promise<Array<string>> => this._getTarefasAC(search, page, perPage);

  public readonly fnPesquisar = (): Promise<void> => this._pesquisar();

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _pesquisar(): Promise<void> {
    if (this.promise) {
      return Promise.resolve();
    }

    this.promise = this._gtoService
      .getDataForMultiView(this.gtoMultiFilters)
      .then((response: HttpResponse<IJsonGTOTarefasMulti>) => {
        this.gtoMultiTarefas = response.body;
        if (this.gtoMultiTarefas.list.length) {
          this._cardPanel.collapse();
        }

        this._evaluateStateGTOTarefas();
        this.dataGridDefinition.columns = this._evaluateDataGridDefinitionColumns();
        this._evaluateGTOTarefas();

        this.captionCheckStateDMR =
          (this.gtoMultiTarefas.haveDMRSS && this.gtoMultiTarefas.haveDMRAT) || (!this.gtoMultiTarefas.haveDMRSS && !this.gtoMultiTarefas.haveDMRAT)
            ? 'gto.tabs.btn.checkstatedmratss'
            : this.gtoMultiTarefas.haveDMRSS && !this.gtoMultiTarefas.haveDMRAT
              ? 'gto.tabs.btn.checkstatedmrss'
              : 'gto.tabs.btn.checkstatedmrat';
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }

  private _getSelectedRow(): IGTOTarefaMultiRow {
    if (isDefinedNotNull(this._dataGridInstance)) {
      const selectedArray: Array<IGTOTarefaMultiRow> = this._dataGridInstance.getSelectedRowsData();
      if (selectedArray.length) {
        return selectedArray[0];
      }
    }
    return undefined;
  }

  private _evaluateStateGTOTarefas(): void {
    this.concluidoDentroPrazoCount = 0;
    this.guardarValidacaoCount = 0;
    this.emAtrasoJustificadoCount = 0;
    this.concluidoForaPrazoCount = 0;
    this.emAtrasoCount = 0;
    this.prazoADecorrerCount = 0;
    this.naoAplicavelCount = 0;
    this._dynamicGTOTarefaMulti = [];

    let index = 0;
    for (const gtoTarefa of this.gtoMultiTarefas.list) {
      this._dynamicGTOTarefaMulti.push({nEmpresa: gtoTarefa.nEmpresa, nomeEmpresa: gtoTarefa.nomeEmpresa, data: gtoTarefa.data});
      for (const tarefaMarc of gtoTarefa.data) {
        if (isEmpty(tarefaMarc.gtoTarefasMarcacaoID)) {
          tarefaMarc._cssClass = '';
        } else {
          switch (tarefaMarc.state) {
            case EGTOTarefasMarcacaoState.ConcluidoDentroPrazo:
              tarefaMarc._cssClass = 'state-color-concluido-dentro-prazo';
              this.concluidoDentroPrazoCount++;
              break;
            case EGTOTarefasMarcacaoState.EmAtraso:
              tarefaMarc._cssClass = 'state-color-em-atraso';
              this.emAtrasoCount++;
              break;
            case EGTOTarefasMarcacaoState.ConcluidoForaPrazo:
              tarefaMarc._cssClass = 'state-color-concluido-fora-prazo';
              this.concluidoForaPrazoCount++;
              break;
            case EGTOTarefasMarcacaoState.EmAtrasoJustificado:
              tarefaMarc._cssClass = 'state-color-em-atraso-justificado';
              this.emAtrasoJustificadoCount++;
              break;
            case EGTOTarefasMarcacaoState.NaoAplicavel:
              tarefaMarc._cssClass = 'state-color-nao-aplicavel';
              this.naoAplicavelCount++;
              break;
            case EGTOTarefasMarcacaoState.GuardarValidacao:
              tarefaMarc._cssClass = 'state-color-guardar-validacao';
              this.guardarValidacaoCount++;
              break;
            case EGTOTarefasMarcacaoState.PrazoADecorrer:
              tarefaMarc._cssClass = 'state-color-prazo-a-decorrer';
              this.prazoADecorrerCount++;
              break;
            default:
              tarefaMarc._cssClass = '';
              break;
          }
        }

        if (moment(tarefaMarc.dataExec).year() !== this.gtoMultiFilters.ano) {
          tarefaMarc.dataExec = undefined;
        }
        if (moment(tarefaMarc.data).year() !== this.gtoMultiFilters.ano) {
          tarefaMarc.data = undefined;
        }

        if (tarefaMarc.tarefaNome) {
          this._dynamicGTOTarefaMulti[index][tarefaMarc.tarefaNome.replace('.', '')] = tarefaMarc;
        }
      }
      index++;
    }

    this.dataGridDefinition.dataSource = new ArrayStore({
      key: 'nEmpresa',
      data: this._dynamicGTOTarefaMulti
    });
  }

  private _evaluateGTOTarefas(): void {
    this.tableHeaderDynamic = [];

    for (const gtoTarefaMultiRow of this.gtoMultiTarefas.list) {
      for (const item of gtoTarefaMultiRow.data) {
        if (!this.tableHeaderDynamic.includes(item.tarefaNome)) {
          this.tableHeaderDynamic.push(item.tarefaNome);
        }
      }
    }

    // order array by table header fields
    for (const gtoTarefaMultiRow of this.gtoMultiTarefas.list) {
      const gtoTarefaMultiIntern: Array<IGTOTarefaMultiItem> = [];
      for (const headerfield of this.tableHeaderDynamic) {
        const index = gtoTarefaMultiRow.data.findIndex((value: IGTOTarefaMultiItem) => value.tarefaNome === headerfield);
        if (index !== -1) {
          gtoTarefaMultiIntern.push(gtoTarefaMultiRow.data[index]);
        } else {
          gtoTarefaMultiIntern.push(this._emptyGTOTarefaMultiRowItem());
        }
      }
      gtoTarefaMultiRow.data = gtoTarefaMultiIntern.slice();
    }
  }

  private _emptyGTOTarefaMultiRowItem(): IGTOTarefaMultiItem {
    return {
      tarefaNome: '',
      gtoTarefasMarcacaoID: '',
      gtoTarefaID: '',
      data: undefined,
      valor: '',
      state: undefined,
      dataExec: undefined,
      justificacao: '',
      nUtilizExec: undefined,
      nResponsavel: undefined,
      nota: '',
      nUtilizNameExec: '',
      _cssClass: ''
    };
  }

  private _generateSecondaryClickActions(line: IGTOTarefaMultiRow, tarefaMarc: IGTOTarefaMultiItem): Array<IPlDynamicVisualsSecondaryClickAction> {
    if (this._cellData.length > 1) {
      return [
        {
          caption: 'gto.tabs.actions.concluirtarefa',
          disabled: isEmpty(tarefaMarc.gtoTarefasMarcacaoID) || tarefaMarc.state === EGTOTarefasMarcacaoState.ConcluidoDentroPrazo || tarefaMarc.state === EGTOTarefasMarcacaoState.ConcluidoForaPrazo,
          icon: 'fa-check',
          click: async () => {
            await this._cgModalService.showOkCancel('gto.promptTitle', 'gto.messages.temACertezaQuePertendeConcluirTarefasSelecionadas', {
              size: 'md',
              btnOkText: 'global.btn.yes',
              btnCancelText: 'global.btn.no',
              backdrop: 'static',
              keyboard: false
            });

            await Promise.allSettled(
              this._cellData.map((cellData) =>
                this._gtoService
                  .executeTask({
                    gtoTarefasMarcacaoID: cellData.tarefaMarc.gtoTarefasMarcacaoID,
                    execDate: moment(),
                    state: EGTOExecuteEstado.Concluir,
                    justificacao: '',
                    valor: '',
                    nota: ''
                  })
                  .then((response: HttpResponse<IJsonNumber>) => {
                    cellData.tarefaMarc.state = response.body.value;
                    this._evaluateStateGTOTarefas();
                  })
              )
            );

            this._plAlertService.success('gto.operacaoConcluidaComSucesso');
          }
        },
        {
          caption: 'gto.tabs.actions.conctarefdentroprazo',
          disabled: isEmpty(tarefaMarc.gtoTarefasMarcacaoID) || tarefaMarc.state === EGTOTarefasMarcacaoState.ConcluidoDentroPrazo || tarefaMarc.state === EGTOTarefasMarcacaoState.ConcluidoForaPrazo,
          icon: 'fa-check',
          click: async () => {
            await this._cgModalService.showOkCancel('gto.promptTitle', 'gto.messages.temACertezaQuePertendeConcluirTarefasSelecionadas', {
              size: 'md',
              btnOkText: 'global.btn.yes',
              btnCancelText: 'global.btn.no',
              backdrop: 'static',
              keyboard: false
            });

            const now = moment();

            await Promise.allSettled(
              this._cellData.map((cellData) =>
                this._gtoService
                  .executeTask({
                    gtoTarefasMarcacaoID: cellData.tarefaMarc.gtoTarefasMarcacaoID,
                    execDate: now > moment(cellData.tarefaMarc.data) ? cellData.tarefaMarc.data : now,
                    state: EGTOExecuteEstado.Concluir,
                    justificacao: '',
                    valor: '',
                    nota: ''
                  })
                  .then((response: HttpResponse<IJsonNumber>) => {
                    cellData.tarefaMarc.state = response.body.value;
                    this._evaluateStateGTOTarefas();
                  })
              )
            );

            this._plAlertService.success('gto.operacaoConcluidaComSucesso');
          }
        }
      ];
    }
    return [
      {
        caption: 'gto.tabs.actions.executartarefa',
        disabled: isEmpty(tarefaMarc.gtoTarefasMarcacaoID),
        icon: 'fa-bolt',
        click: () => {
          const modalInstance = this._cgModalService.showVanilla(GtoExecuteModalComponent, {size: 'lg'});
          const componentInstance: GtoExecuteModalComponent = modalInstance.componentInstance;
          componentInstance.sessionNUtilizador = this._auxERPUser.nUtilizador;
          componentInstance.nResponsavel = tarefaMarc.nResponsavel;
          componentInstance.gtoTarefaMarcacao = tarefaMarc;
          modalInstance.result.then(() => this._pesquisar());
        }
      },
      {
        caption: 'gto.tabs.actions.concluirtarefa',
        disabled: isEmpty(tarefaMarc.gtoTarefasMarcacaoID) || tarefaMarc.state === EGTOTarefasMarcacaoState.ConcluidoDentroPrazo || tarefaMarc.state === EGTOTarefasMarcacaoState.ConcluidoForaPrazo,
        icon: 'fa-check',
        click: () => {
          return this._gtoService
            .executeTask({
              gtoTarefasMarcacaoID: tarefaMarc.gtoTarefasMarcacaoID,
              execDate: moment(),
              state: EGTOExecuteEstado.Concluir,
              justificacao: '',
              valor: '',
              nota: ''
            })
            .then((response: HttpResponse<IJsonNumber>) => {
              tarefaMarc.state = response.body.value;
              this._evaluateStateGTOTarefas();
              this._plAlertService.success('gto.operacaoConcluidaComSucesso');
            });
        }
      },
      {
        caption: 'gto.tabs.actions.conctarefdentroprazo',
        disabled: isEmpty(tarefaMarc.gtoTarefasMarcacaoID) || tarefaMarc.state === EGTOTarefasMarcacaoState.ConcluidoDentroPrazo || tarefaMarc.state === EGTOTarefasMarcacaoState.ConcluidoForaPrazo,
        icon: 'fa-check',
        click: () => {
          const now = moment();
          return this._gtoService
            .executeTask({
              gtoTarefasMarcacaoID: tarefaMarc.gtoTarefasMarcacaoID,
              execDate: now > moment(tarefaMarc.data) ? tarefaMarc.data : now,
              state: EGTOExecuteEstado.Concluir,
              justificacao: '',
              valor: '',
              nota: ''
            })
            .then((response: HttpResponse<IJsonNumber>) => {
              tarefaMarc.state = response.body.value;
              this._evaluateStateGTOTarefas();
              this._plAlertService.success('gto.operacaoConcluidaComSucesso');
            });
        }
      },
      {
        divider: true
      },
      {
        caption: 'gto.tabs.actions.editartarefa',
        icon: 'fa-pencil',
        click: () => {
          const modalInstance = this._cgModalService.showVanilla(GtoCreateEditTaskModalComponent, {size: 'lg'});
          const componentInstance: GtoCreateEditTaskModalComponent = modalInstance.componentInstance;
          componentInstance.nempresa = line.nEmpresa;
          componentInstance.nomeEmpresa = line.nomeEmpresa;
          componentInstance.nUtilizador = this._auxERPUser.nUtilizador;
          componentInstance.gtoTarefaId = tarefaMarc.gtoTarefaID;
          return modalInstance.result.then(() => this._pesquisar());
        }
      },
      {
        caption: 'gto.tabs.actions.desativartarefa',
        icon: 'fa-ban',
        click: () => {
          return this._gtoService.toggleActive(tarefaMarc.gtoTarefaID, false).then(() => {
            this._plAlertService.success('gto.operacaoConcluidaComSucesso');
            this._pesquisar();
          });
        }
      }
    ];
  }

  private _getTarefasAC(search: string, page: number, perPage: number): Promise<Array<string>> {
    return this._gtoService.getTarefasAC(search, this.gtoMultiFilters.ano, page, perPage).then((response: HttpResponse<Array<string>>) => response.body);
  }

  private _evaluateDataGridDefinitionColumns(): Array<IDevExpressDataGridColumn> {
    let subCaptionDataField: string;
    let format: string;
    let dataType: TDevExpressDataGridColumnDataType;
    let cellTemplate: string;

    switch (this.gtoMostrarFilter) {
      case EGTOMostrarFilter.DataLimite:
      case EGTOMostrarFilter.DataExecucao:
      case EGTOMostrarFilter.DataLimitePlusNotes:
      case EGTOMostrarFilter.DataExecucaoPlusNotes:
        subCaptionDataField = this.gtoMostrarFilter === EGTOMostrarFilter.DataLimite || this.gtoMostrarFilter === EGTOMostrarFilter.DataLimitePlusNotes ? 'data' : 'dataExec';
        if (this.gtoMostrarFilter === EGTOMostrarFilter.DataLimite || this.gtoMostrarFilter === EGTOMostrarFilter.DataExecucao) {
          format = 'dd/MM';
        } else {
          cellTemplate = this.gtoMostrarFilter === EGTOMostrarFilter.DataLimitePlusNotes ? 'dataLimitePlusNotesTmpl' : 'dataExecucaoPlusNotesTmpl';
          format = '';
        }
        dataType = 'date';
        break;
      case EGTOMostrarFilter.Values:
      case EGTOMostrarFilter.ValuesPlusNotes:
        subCaptionDataField = 'valor';
        format = '';
        dataType = 'double';
        if (this.gtoMostrarFilter === EGTOMostrarFilter.ValuesPlusNotes) {
          cellTemplate = 'valuesPlusNotesTmpl';
        }
        break;
      case EGTOMostrarFilter.Dias:
      case EGTOMostrarFilter.DiasPlusNotes:
        subCaptionDataField = 'data';
        format = 'day';
        if (this.gtoMostrarFilter === EGTOMostrarFilter.DiasPlusNotes) {
          cellTemplate = 'diasPlusNotesTmpl';
          format = '';
        }
        dataType = 'date';
        break;
    }

    const columns: Array<IDevExpressDataGridColumn> = [
      {dataField: 'nEmpresa', dataType: 'string', caption: 'gto.tabs.table.fields.nempresa', fixed: !this._isMobile, width: 120},
      {dataField: 'nomeEmpresa', dataType: 'string', caption: 'gto.tabs.filters.empresa', fixed: !this._isMobile, width: 250}
    ];

    if (this._dynamicGTOTarefaMulti.length) {
      for (const tarefa of Object.keys(this._dynamicGTOTarefaMulti[0])) {
        if (tarefa !== 'nEmpresa' && tarefa !== 'nomeEmpresa' && tarefa !== 'data') {
          columns.push({
            dataField: `${tarefa}.${subCaptionDataField}`,
            dataType: dataType,
            format: format,
            width: 160,
            cellTemplate: cellTemplate,
            caption: (<IGTOTarefaMultiItem>this._dynamicGTOTarefaMulti[0][tarefa]).tarefaNome,
            alignment: 'center'
          });
        }
      }
    }

    columns.push({
      width: 'auto',
      dataField: 'autoSizingCol',
      caption: ''
    });

    return columns;
  }

  private _getGTOTarefaMultiItem(datafield: string, data: IGTOTarefaMultiRow): IGTOTarefaMultiItem {
    switch (this.gtoMostrarFilter) {
      case EGTOMostrarFilter.DataLimite:
      case EGTOMostrarFilter.Dias:
      case EGTOMostrarFilter.DataLimitePlusNotes:
      case EGTOMostrarFilter.DiasPlusNotes:
        return data[datafield.replace('.data', '')];
      case EGTOMostrarFilter.DataExecucao:
      case EGTOMostrarFilter.DataExecucaoPlusNotes:
        return data[datafield.replace('.dataExec', '')];
      case EGTOMostrarFilter.Values:
      case EGTOMostrarFilter.ValuesPlusNotes:
        return data[datafield.replace('.valor', '')];
      default:
        return undefined;
    }
  }

  private _showSelection(component: dxDataGrid, selectedRange: IGTOSelectedRange): void {
    const selectedCells = component.element().querySelectorAll('.cell-selected');
    if (selectedCells) {
      selectedCells.forEach((cellElement) => {
        cellElement.classList.remove('cell-selected');
      });
    }
    this._foreachRange(selectedRange, (rowIndex: number, columnIndex: number) => {
      component.getCellElement(rowIndex, columnIndex).classList.add('cell-selected');
    });
  }

  private _foreachRange(selectedRange: IGTOSelectedRange, func: Function): void {
    this._cellData = [];
    if (selectedRange.startRowIndex >= 0) {
      const minRowIndex = Math.min(selectedRange.startRowIndex, selectedRange.endRowIndex);
      const maxRowIndex = Math.max(selectedRange.startRowIndex, selectedRange.endRowIndex);
      const minColumnIndex = Math.min(selectedRange.startColumnIndex, selectedRange.endColumnIndex);
      const maxColumnIndex = Math.max(selectedRange.startColumnIndex, selectedRange.endColumnIndex);

      const rows = this._dataGridInstance.getVisibleRows();
      const columns = this._dataGridInstance.getVisibleColumns();
      for (let rowIndex = minRowIndex; rowIndex <= maxRowIndex; rowIndex++) {
        for (let columnIndex = minColumnIndex; columnIndex <= maxColumnIndex; columnIndex++) {
          func(rowIndex, columnIndex);
          const gtoTarefaMultiItem: IGTOTarefaMultiItem = this._getGTOTarefaMultiItem(columns[columnIndex].dataField, rows[rowIndex].data);
          this._cellData.push({rowIndex: rowIndex, columnIndex: columnIndex, tarefaMarc: gtoTarefaMultiItem});
        }
      }
    }
  }

  private _setIsMobile(isMobile: boolean): void {
    this._isMobile = isMobile;
    let col = this.dataGridDefinition.columns.find((c) => c.dataField === 'nEmpresa');
    col.fixed = !this._isMobile;
    col = this.dataGridDefinition.columns.find((c) => c.dataField === 'nomeEmpresa');
    col.fixed = !this._isMobile;
    if (isDefinedNotNull(this._dataGridInstance)) {
      this._dataGridInstance.columnOption('nEmpresa', 'fixed', !this._isMobile);
      this._dataGridInstance.columnOption('nomeEmpresa', 'fixed', !this._isMobile);
    }
  }
}
