<div class="bancos-extrato-module-import-by-reconciliation-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'bancosextrato.importbyreconciliationmodal.title'"></h5>
  </div>

  <div class="modal-body" [plPromise]="promise">
    <conciliacao-bancaria-bancos-tree-viewer [collapseOnSelect]="true" (modelChange)="changedSelectedNode($event)" (evtDoubleClickedItem)="close()"> </conciliacao-bancaria-bancos-tree-viewer>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button class="action-maintenance-conciliacao-bancaria" klass="btn-info" [onClick]="fnMaintenanceConciliacaoBancaria">
      <i class="fa fa-fw fa-university"></i>&nbsp;<span [translate]="'global.menu.conciliacaobancaria'"></span>
    </pl-button>

    <pl-button klass="btn-primary action-close" [disabled]="closeDisabled || !selectedBanco" [onClick]="close">
      <i class="fa fa-fw fa-download"></i>&nbsp;<span [translate]="'global.btn.import'"></span>
    </pl-button>

    <pl-button klass="btn-light action-dismiss" [disabled]="closeDisabled" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
