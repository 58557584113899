import {merge} from 'lodash-es';
import {Properties as ButtonOptions} from 'devextreme/ui/button';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {EArrayIds, ICampo03NifList, ICampo604List, ICampo605List, ICampo907NifList, IJsonModelo22, IJsonModelo22AnexoD} from '../../../jsonModelo22.module.interface';
import {IAnexoDQuadro7Totals} from '../../../modelo22.module.interface';
import {copy, isDefinedNotNull, isNumber} from 'pl-comps-angular';
import {CGModalService} from '../../../../../../components/cg/modal/cgmodal.service';
import {Modelo22AnexoDModalComponent} from './modals/modelo22.anexoD.modal.component';
import {Modelo22AnexoDQuadro7ModalComponent} from './modals/modelo22.anexoD.quadro7.modal.component';
import {Modelo22AnexoDQuadro7Tabelas78ModalComponent} from './modals/modelo22.anexoD.quadro7.tabelas78.modal.component';
import {IDevExpressDataGrid, TDevExpressDataGridToolbar} from '../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {TranslateService} from '@ngx-translate/core';
import {maxDateCG, maxDateCGD, minDateCG, minDateCGD} from '../../../../../../../common/utils/utils';
import moment from 'moment';
import {HttpResponse} from '@angular/common/http';
import {Modelo22Service} from '../../../modelo22.module.service';

let nifs907ListId = 1;
let nifs03ListId = 1;
let cod604ListId = 1;
let cod605ListId = 1;

@Component({
  selector: 'modelo22-anexos-anexod',
  templateUrl: './modelo22.anexos.anexoD.component.html'
})
export class Modelo22AnexosAnexoDComponent implements OnInit, OnChanges {
  @Input() public modelo22: IJsonModelo22;
  @Output() public readonly evtChangedModelo22: EventEmitter<IJsonModelo22>;

  public readonly dataGridDefinition31A: IDevExpressDataGrid;
  public readonly dataGridDefinition31B: IDevExpressDataGrid;
  public readonly dataGridDefinition32A: IDevExpressDataGrid;
  public readonly dataGridDefinition32B: IDevExpressDataGrid;
  public readonly dataGridDefinition04A: IDevExpressDataGrid;
  public readonly dataGridDefinition041: IDevExpressDataGrid;
  public readonly dataGridDefinition603: IDevExpressDataGrid;
  public readonly dataGridDefinition71: IDevExpressDataGrid;
  public readonly dataGridDefinition71A: IDevExpressDataGrid;
  public readonly dataGridDefinition73: IDevExpressDataGrid;
  public readonly dataGridDefinition73A: IDevExpressDataGrid;
  public readonly dataGridDefinition74: IDevExpressDataGrid;
  public readonly dataGridDefinition74A: IDevExpressDataGrid;
  public readonly dataGridDefinition76: IDevExpressDataGrid;
  public readonly dataGridDefinition76A: IDevExpressDataGrid;
  public readonly dataGridDefinition79: IDevExpressDataGrid;
  public readonly dataGridDefinition710: IDevExpressDataGrid;
  public readonly dataGridDefinition710A: IDevExpressDataGrid;
  public readonly dataGridDefinition77: IDevExpressDataGrid;
  public readonly dataGridDefinition78: IDevExpressDataGrid;
  public readonly dataGridDefinition78A1: IDevExpressDataGrid;
  public readonly dataGridDefinition78A2: IDevExpressDataGrid;
  public readonly dataGridDefinition78A3: IDevExpressDataGrid;
  public readonly dataGridDefinition08: IDevExpressDataGrid;
  public readonly dataGridDefinition121: IDevExpressDataGrid;
  public readonly dataGridDefinition122: IDevExpressDataGrid;

  public readonly dataGridDefinitionCampo907List: IDevExpressDataGrid;
  public readonly dataGridDefinitionCampo03List: IDevExpressDataGrid;
  public readonly dataGridDefinitionCampo604List: IDevExpressDataGrid;
  public readonly dataGridDefinitionCampo605List: IDevExpressDataGrid;
  public readonly dataGridDefinitionQuadro07Lista071SomaControlo: IDevExpressDataGrid;
  public readonly dataGridDefinitionQuadro07Lista071ASomaControlo: IDevExpressDataGrid;
  public readonly dataGridDefinitionQuadro07Lista073SomaControlo: IDevExpressDataGrid;
  public readonly dataGridDefinitionQuadro07Lista073ASomaControlo: IDevExpressDataGrid;
  public readonly dataGridDefinitionQuadro07Lista074SomaControlo: IDevExpressDataGrid;
  public readonly dataGridDefinitionQuadro07Lista074ASomaControlo: IDevExpressDataGrid;
  public readonly dataGridDefinitionQuadro07Lista076SomaControlo: IDevExpressDataGrid;
  public readonly dataGridDefinitionQuadro07Lista076ASomaControlo: IDevExpressDataGrid;
  public readonly dataGridDefinitionQuadro07Lista079SomaControlo: IDevExpressDataGrid;
  public readonly dataGridDefinitionQuadro07Lista0710SomaControlo: IDevExpressDataGrid;
  public readonly dataGridDefinitionQuadro07Lista0710ASomaControlo: IDevExpressDataGrid;
  public readonly dataGridDefinitionQuadro07Lista077SomaControlo: IDevExpressDataGrid;

  public nifs907List: Array<ICampo907NifList>;
  public nifs03List: Array<ICampo03NifList>;
  public cods604List: Array<ICampo604List>;
  public cods605List: Array<ICampo605List>;

  public quadro7List71Totais: Array<IAnexoDQuadro7Totals>;
  public quadro7List71ATotais: Array<IAnexoDQuadro7Totals>;
  public quadro7List73Totais: Array<IAnexoDQuadro7Totals>;
  public quadro7List73ATotais: Array<IAnexoDQuadro7Totals>;
  public quadro7List74Totais: Array<IAnexoDQuadro7Totals>;
  public quadro7List74ATotais: Array<IAnexoDQuadro7Totals>;
  public quadro7List76Totais: Array<IAnexoDQuadro7Totals>;
  public quadro7List76ATotais: Array<IAnexoDQuadro7Totals>;
  public quadro7List79Totais: Array<IAnexoDQuadro7Totals>;
  public quadro7List710Totais: Array<IAnexoDQuadro7Totals>;
  public quadro7List710ATotais: Array<IAnexoDQuadro7Totals>;
  public quadro7List77Totais: Array<IAnexoDQuadro7Totals>;

  public promise: Promise<void>;

  private readonly _anexoDQuadro7Lista071Totals: IAnexoDQuadro7Totals;
  private readonly _anexoDQuadro7Lista071ATotals: IAnexoDQuadro7Totals;
  private readonly _anexoDQuadro7Lista073Totals: IAnexoDQuadro7Totals;
  private readonly _anexoDQuadro7Lista073ATotals: IAnexoDQuadro7Totals;
  private readonly _anexoDQuadro7Lista074Totals: IAnexoDQuadro7Totals;
  private readonly _anexoDQuadro7Lista074ATotals: IAnexoDQuadro7Totals;
  private readonly _anexoDQuadro7Lista076Totals: IAnexoDQuadro7Totals;
  private readonly _anexoDQuadro7Lista076ATotals: IAnexoDQuadro7Totals;
  private readonly _anexoDQuadro7Lista079Totals: IAnexoDQuadro7Totals;
  private readonly _anexoDQuadro7Lista0710Totals: IAnexoDQuadro7Totals;
  private readonly _anexoDQuadro7Lista0710ATotals: IAnexoDQuadro7Totals;
  private readonly _anexoDQuadro7Lista077Totals: IAnexoDQuadro7Totals;

  constructor(
    private readonly _cgModalService: CGModalService,
    private readonly _modelo22Service: Modelo22Service,
    private readonly _translateService: TranslateService
  ) {
    this.evtChangedModelo22 = new EventEmitter<IJsonModelo22>();

    this.dataGridDefinition31A = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'codBeneficio', dataType: 'number', caption: 'modelo22.anexoD.quadro03.table.fields.codben'},
        {dataField: 'montante', dataType: 'double', caption: 'modelo22.anexoD.quadro03.table.fields.montante'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn31A'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro03.desc31A'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro3('31A'))
    };
    this.dataGridDefinition31B = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'codBeneficio', dataType: 'number', caption: 'modelo22.anexoD.quadro03.table.fields.codben'},
        {dataField: 'montante', dataType: 'double', caption: 'modelo22.anexoD.quadro03.table.fields.montante'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn31B'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro03.desc31B'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro3('31B'))
    };
    this.dataGridDefinition32A = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'codBeneficio', dataType: 'number', caption: 'modelo22.anexoD.quadro03.table.fields.codben'},
        {dataField: 'montante', dataType: 'double', caption: 'modelo22.anexoD.quadro03.table.fields.montante'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn32A'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro03.desc32A'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro3('32A'))
    };
    this.dataGridDefinition32B = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'codBeneficio', dataType: 'number', caption: 'modelo22.anexoD.quadro03.table.fields.codben'},
        {dataField: 'montante', dataType: 'double', caption: 'modelo22.anexoD.quadro03.table.fields.montante'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn32B'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro03.desc32B'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro3('32B'))
    };
    this.dataGridDefinition04A = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'codBeneficio', dataType: 'number', caption: 'modelo22.anexoD.quadro04.table.fields.codben'},
        {dataField: 'montante', dataType: 'double', caption: 'modelo22.anexoD.quadro04.table.fields.montante'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn04A'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro04.desc4A'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro4('04A'))
    };
    this.dataGridDefinition041 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'codBeneficio', dataType: 'number', caption: 'modelo22.anexoD.quadro04.table.fields.codben'},
        {dataField: 'nif', dataType: 'number', caption: 'modelo22.anexoD.quadro04.table.fields.nifsoc'},
        {dataField: 'montante', dataType: 'double', caption: 'modelo22.anexoD.quadro04.table.fields.montante'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn041'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro04.desc41'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro4('041'))
    };
    this.dataGridDefinition603 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.anexoD.quadro06.ano'},
        {dataField: 'montante', dataType: 'number', caption: 'modelo22.anexoD.quadro06.montante'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn041'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro06.desc603'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro6('603'))
    };
    this.dataGridDefinition71 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'nif', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table71.fields.field700'},
        {dataField: 'diploma', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table71.fields.field1'},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table71.fields.field2'},
        {dataField: 'saldoCaducado', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table71.fields.field3'},
        {dataField: 'saldoNaoDeduzido', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table71.fields.field701'},
        {dataField: 'dotacaoDoPeriodo', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table71.fields.field702'},
        {dataField: 'deducaoNoPeriodo', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table71.fields.field703'},
        {dataField: 'saldoQueTransita', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table71.fields.field704'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn71'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro07.desc71'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro7('71'))
    };
    this.dataGridDefinition71A = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'diploma', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table71.fields.field1'},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table71.fields.field2'},
        {dataField: 'saldoCaducado', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table71.fields.field3'},
        {dataField: 'saldoNaoDeduzido', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table71.fields.field701'},
        {dataField: 'dotacaoDoPeriodo', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table71.fields.field702'},
        {dataField: 'deducaoUtilizada', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table71.fields.field703'},
        {dataField: 'saldoQueTransita', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table71.fields.field704'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn71A'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro07.desc71A'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro7('71A'))
    };
    this.dataGridDefinition73 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'nif', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table73.fields.field743'},
        {dataField: 'diploma', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table73.fields.field1'},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table73.fields.field2'},
        {dataField: 'saldoCaducado', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table73.fields.field3'},
        {dataField: 'saldoNaoDeduzido', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table73.fields.field709'},
        {dataField: 'dotacaoDoPeriodo', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table73.fields.field710'},
        {dataField: 'deducaoNoPeriodo', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table73.fields.field711'},
        {dataField: 'saldoQueTransita', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table73.fields.field712'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn73'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro07.desc73'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro7('73'))
    };
    this.dataGridDefinition73A = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'diploma', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table73A.fields.field1'},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table73A.fields.field2'},
        {dataField: 'saldoCaducado', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table73A.fields.field3'},
        {dataField: 'saldoNaoDeduzido', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table73A.fields.field4'},
        {dataField: 'dotacaoDoPeriodo', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table73A.fields.field5'},
        {dataField: 'deducaoUtilizada', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table73A.fields.field6'},
        {dataField: 'saldoQueTransita', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table73A.fields.field7'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn73A'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro07.desc73A'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro7('73A'))
    };
    this.dataGridDefinition74 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'nif', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table74.fields.field744'},
        {dataField: 'diploma', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table74.fields.field1'},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table74.fields.field2'},
        {dataField: 'saldoCaducado', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table74.fields.field3'},
        {dataField: 'saldoNaoDeduzido', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table74.fields.field713'},
        {dataField: 'dotacaoDoPeriodo', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table74.fields.field714'},
        {dataField: 'deducaoNoPeriodo', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table74.fields.field715'},
        {dataField: 'saldoQueTransita', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table74.fields.field716'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn74'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro07.desc74'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro7('74'))
    };
    this.dataGridDefinition74A = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'diploma', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table74A.fields.field1'},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table74A.fields.field2'},
        {dataField: 'saldoCaducado', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table74A.fields.field3'},
        {dataField: 'saldoNaoDeduzido', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table74A.fields.field4'},
        {dataField: 'dotacaoDoPeriodo', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table74A.fields.field5'},
        {dataField: 'deducaoUtilizada', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table74A.fields.field6'},
        {dataField: 'saldoQueTransita', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table74A.fields.field7'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn74A'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro07.desc74A'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro7('74A'))
    };
    this.dataGridDefinition76 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'nif', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table76.field1'},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table76.field2'},
        {dataField: 'saldoNaoDeduzido', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table76.field722'},
        {dataField: 'dotacaoDoPeriodo', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table76.field723'},
        {dataField: 'deducaoDoPeriodo', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table76.field724'},
        {dataField: 'saldoQueTransita', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table76.field725'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn76'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro07.desc76'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro7('76'))
    };
    this.dataGridDefinition76A = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table76A.field1'},
        {dataField: 'saldoNaoDeduzido', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table76A.field2'},
        {dataField: 'dotacaoDoPeriodo', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table76A.field3'},
        {dataField: 'deducaoUtilizada', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table76A.field4'},
        {dataField: 'saldoQueTransita', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table76A.field5'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn76A'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro07.desc76A'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro7('76A'))
    };
    this.dataGridDefinition79 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'nIdentificacao', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table79.fields.field790'},
        {dataField: 'dataInicio', dataType: 'date', caption: 'modelo22.anexoD.quadro07.table79.fields.field791'},
        {dataField: 'dataConclusao', dataType: 'date', caption: 'modelo22.anexoD.quadro07.table79.fields.field792'},
        {dataField: 'saldoNaoDeduzido', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table79.fields.field793'},
        {dataField: 'valorIncentivo', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table79.fields.field794'},
        {dataField: 'deducaoDoPeriodo', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table79.fields.field795'},
        {dataField: 'saldoQueTransita', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table79.fields.field796'},
        {dataField: 'valorAReembolsar', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table79.fields.field797'},
        {dataField: 'valorARepor', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table79.fields.field798'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn79'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro07.desc79'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro7('79'))
    };
    this.dataGridDefinition710 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'nIdentificacao', dataType: 'string', caption: 'modelo22.anexoD.quadro07.table710.fields.field71001'},
        {dataField: 'saldoNaoDeduzido', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table710.fields.field71002'},
        {dataField: 'montanteBeneficio', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table710.fields.field71003'},
        {dataField: 'deducaoDoPeriodo', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table710.fields.field71004'},
        {dataField: 'saldoQueTransita', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table710.fields.field71005'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn710'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro07.desc710'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro7('710'))
    };
    this.dataGridDefinition710A = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'saldoNaoDeduzido', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table710A.fields.field71001A'},
        {dataField: 'montanteBeneficio', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table710A.fields.field71002A'},
        {dataField: 'deducaoDoPeriodo', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table710A.fields.field71003A'},
        {dataField: 'saldoQueTransita', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table710A.fields.field71004A'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn710A'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro07.desc710A'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro7('710A'))
    };
    this.dataGridDefinition77 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'nif', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table77.fields.field729'},
        {dataField: 'diploma', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table77.fields.field1'},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table77.fields.field2'},
        {dataField: 'saldo', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table77.fields.field730'},
        {dataField: 'dotacao', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table77.fields.field731'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn77'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro07.desc77'},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro7('77'))
    };
    this.dataGridDefinition78 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'codigoBeneficio', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table78.fields.field746'},
        {dataField: 'regiao', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table78.fields.field735'},
        {dataField: 'cae', dataType: 'string', caption: 'modelo22.anexoD.quadro07.table78.fields.field736'},
        {dataField: 'montanteDasAplicacoes', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78.fields.field737'},
        {
          caption: 'modelo22.anexoD.quadro07.table78.fields.incenfisc',
          columns: [
            {dataField: 'irc', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78.fields.field738'},
            {dataField: 'imiIMTSelo', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78.fields.field739'}
          ]
        },
        {dataField: 'naoFiscais', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78.fields.field740'},
        {dataField: 'total', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78.fields.field741'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn78'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro07.desc78'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro7Tabelas78('78'))
    };
    this.dataGridDefinition78A1 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'numeroLinha', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table78A1.fields.field782'},
        {
          caption: 'modelo22.anexoD.quadro07.table78A1.fields.projinvinc',
          columns: [
            {dataField: 'tipo', dataType: 'string', caption: 'modelo22.anexoD.quadro07.table78A1.fields.field750'},
            {dataField: 'numProjecto', dataType: 'string', caption: 'modelo22.anexoD.quadro07.table78A1.fields.field751'},
            {dataField: 'dataInicio', dataType: 'date', caption: 'modelo22.anexoD.quadro07.table78A1.fields.field752'},
            {dataField: 'dataFim', dataType: 'date', caption: 'modelo22.anexoD.quadro07.table78A1.fields.field753'},
            {dataField: 'tipologia', dataType: 'string', caption: 'modelo22.anexoD.quadro07.table78A1.fields.field754'},
            {dataField: 'identificacao', dataType: 'string', caption: 'modelo22.anexoD.quadro07.table78A1.fields.field755'}
          ]
        },
        {
          caption: 'modelo22.anexoD.quadro07.table78A1.fields.appreleprev',
          columns: [
            {dataField: 'regiao', dataType: 'string', caption: 'modelo22.anexoD.quadro07.table78A1.fields.field756'},
            {dataField: 'cae', dataType: 'string', caption: 'modelo22.anexoD.quadro07.table78A1.fields.field757'},
            {dataField: 'montante', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A1.fields.field758'},
            {dataField: 'montanteActualizado', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A1.fields.field759'}
          ]
        },
        {type: 'buttons', cellTemplate: 'cellTemplateBtn78A1'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro07.desc78-A1'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro7Tabelas78('78A1'))
    };
    this.dataGridDefinition78A2 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'numeroLinha', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table78A2.fields.field760'},
        {
          caption: 'modelo22.anexoD.quadro07.table78A2.fields.apprelreali',
          columns: [
            {dataField: 'aplicacoesMontante', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A2.fields.field761'},
            {dataField: 'aplicacoesActualizado', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A2.fields.field762'}
          ]
        },
        {
          caption: 'modelo22.anexoD.quadro07.table78A2.fields.financeiro',
          columns: [
            {dataField: 'financeiroUsufruido', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A2.fields.field763'},
            {dataField: 'financeiroActualizado', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A2.fields.field764'}
          ]
        },
        {
          caption: 'modelo22.anexoD.quadro07.table78A2.fields.irc',
          columns: [
            {dataField: 'ircUtilizado', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A2.fields.field765'},
            {dataField: 'ircActualizado', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A2.fields.field766'}
          ]
        },
        {
          caption: 'modelo22.anexoD.quadro07.table78A2.fields.imi',
          columns: [
            {dataField: 'imiUtilizado', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A2.fields.field767'},
            {dataField: 'imiActualizado', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A2.fields.field768'}
          ]
        },
        {
          caption: 'modelo22.anexoD.quadro07.table78A2.fields.imt',
          columns: [{dataField: 'imtUtilizado', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A2.fields.field769'}]
        },
        {
          caption: 'modelo22.anexoD.quadro07.table78A2.fields.selo',
          columns: [{dataField: 'seloUtilizado', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A2.fields.field770'}]
        },
        {dataField: 'montanteTotal', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A2.fields.field771'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn78A2'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro07.desc78-A2'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro7Tabelas78('78A2'))
    };
    this.dataGridDefinition78A3 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'numeroLinha', dataType: 'number', caption: 'modelo22.anexoD.quadro07.table78A3.fields.field772'},
        {
          caption: 'modelo22.anexoD.quadro07.table78A3.fields.apprelreali',
          columns: [{dataField: 'aplicacoesMontante', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A3.fields.field773'}]
        },
        {
          caption: 'modelo22.anexoD.quadro07.table78A3.fields.financeiro',
          columns: [{dataField: 'financeiroMontante', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A3.fields.field774'}]
        },
        {
          caption: 'modelo22.anexoD.quadro07.table78A3.fields.irc',
          columns: [{dataField: 'ircMontante', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A3.fields.field775'}]
        },
        {
          caption: 'modelo22.anexoD.quadro07.table78A3.fields.imi',
          columns: [{dataField: 'imiMontante', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A3.fields.field776'}]
        },
        {
          caption: 'modelo22.anexoD.quadro07.table78A3.fields.imt',
          columns: [{dataField: 'imtMontante', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A3.fields.field777'}]
        },
        {
          caption: 'modelo22.anexoD.quadro07.table78A3.fields.selo',
          columns: [{dataField: 'seloMontante', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A3.fields.field778'}]
        },
        {dataField: 'montanteTotal', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A3.fields.field779'},
        {dataField: 'intensidade', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A3.fields.field780'},
        {dataField: 'montanteAInscrever', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table78A3.fields.field781'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn78A3'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro07.desc78-A3'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro7Tabelas78('78A3'))
    };
    this.dataGridDefinition08 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'tipoDonativo', dataType: 'string', caption: 'modelo22.anexoD.quadro08.table.fields.tipdona'},
        {dataField: 'nif', dataType: 'number', caption: 'modelo22.anexoD.quadro08.table.fields.nif'},
        {dataField: 'valor', dataType: 'double', caption: 'modelo22.anexoD.quadro08.table.fields.donativo'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn08'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro08.title'},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro8())
    };
    this.dataGridDefinition121 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'nif', dataType: 'number', caption: 'modelo22.anexoD.quadro12.table121.fields.field1'},
        {dataField: 'data', dataType: 'date', caption: 'modelo22.anexoD.quadro12.table121.fields.field2'},
        {dataField: 'percentagem', dataType: 'double', caption: 'modelo22.anexoD.quadro12.table121.fields.field3'},
        {dataField: 'periodo', dataType: 'double', caption: 'modelo22.anexoD.quadro12.table121.fields.field4'},
        {dataField: 'prejuizosSaldo', dataType: 'double', caption: 'modelo22.anexoD.quadro12.table121.fields.field5'},
        {dataField: 'prejuizosMontante', dataType: 'double', caption: 'modelo22.anexoD.quadro12.table121.fields.field6'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn121'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro12.desc121'},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro12('121'))
    };
    this.dataGridDefinition122 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'nif', dataType: 'number', caption: 'modelo22.anexoD.quadro12.table122.fields.field1'},
        {dataField: 'percentagem', dataType: 'double', caption: 'modelo22.anexoD.quadro12.table122.fields.field2'},
        {dataField: 'periodo', dataType: 'number', caption: 'modelo22.anexoD.quadro12.table122.fields.field3'},
        {dataField: 'prejuizosSaldo', dataType: 'double', caption: 'modelo22.anexoD.quadro12.table122.fields.field4'},
        {dataField: 'prejuizosMontante', dataType: 'double', caption: 'modelo22.anexoD.quadro12.table122.fields.field5'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn122'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoD.quadro12.desc122'},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro12('122'))
    };
    this.dataGridDefinitionCampo907List = {
      keyExpr: 'id',
      columns: [
        {dataField: 'id', dataType: 'number', caption: '', width: 75, alignment: 'center'},
        {dataField: 'nif', dataType: 'number', caption: 'modelo22.anexoD.quadro09.table.field', alignment: 'left'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn907', alignment: 'center'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro9())
    };
    this.dataGridDefinitionCampo03List = {
      keyExpr: 'id',
      columns: [
        {dataField: 'id', dataType: 'number', caption: '', width: 75, alignment: 'center'},
        {dataField: 'nif', dataType: 'number', caption: 'modelo22.anexoD.quadro11A.table.field', alignment: 'left'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn03', alignment: 'center'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro11A())
    };
    this.dataGridDefinitionCampo604List = {
      keyExpr: 'id',
      columns: [
        {dataField: 'id', dataType: 'number', caption: '', width: 75, alignment: 'center'},
        {dataField: 'cod', dataType: 'number', caption: 'modelo22.anexoD.quadro06.desc604', alignment: 'left'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn604', alignment: 'center'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro6('604'))
    };
    this.dataGridDefinitionCampo605List = {
      keyExpr: 'id',
      columns: [
        {dataField: 'id', dataType: 'number', caption: '', width: 75, alignment: 'center'},
        {dataField: 'cod', dataType: 'number', caption: 'modelo22.anexoD.quadro06.desc605', alignment: 'left'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn605', alignment: 'center'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoDQuadro6('605'))
    };

    this.dataGridDefinitionQuadro07Lista071SomaControlo = {
      columns: [
        {dataField: 'title', dataType: 'string', caption: ''},
        {dataField: '_total1', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table71.fields.tot1'},
        {dataField: '_total2', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table71.fields.tot2'},
        {dataField: '_total3', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table71.fields.tot3'},
        {dataField: '_total4', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table71.fields.tot4'},
        {dataField: '_total5', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table71.fields.tot5'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false
    };
    this.dataGridDefinitionQuadro07Lista071ASomaControlo = {
      columns: [
        {dataField: 'title', dataType: 'string', caption: ''},
        {dataField: '_total1', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table71A.fields.tot1'},
        {dataField: '_total2', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table71A.fields.tot2'},
        {dataField: '_total3', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table71A.fields.tot3'},
        {dataField: '_total4', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table71A.fields.tot4'},
        {dataField: '_total5', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table71A.fields.tot5'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false
    };
    this.dataGridDefinitionQuadro07Lista073SomaControlo = {
      columns: [
        {dataField: 'title', dataType: 'string', caption: ''},
        {dataField: '_total1', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table73.fields.tot1'},
        {dataField: '_total2', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table73.fields.tot2'},
        {dataField: '_total3', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table73.fields.tot3'},
        {dataField: '_total4', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table73.fields.tot4'},
        {dataField: '_total5', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table73.fields.tot5'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false
    };
    this.dataGridDefinitionQuadro07Lista073ASomaControlo = {
      columns: [
        {dataField: 'title', dataType: 'string', caption: ''},
        {dataField: '_total1', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table73A.fields.tot1'},
        {dataField: '_total2', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table73A.fields.tot2'},
        {dataField: '_total3', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table73A.fields.tot3'},
        {dataField: '_total4', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table73A.fields.tot4'},
        {dataField: '_total5', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table73A.fields.tot5'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false
    };
    this.dataGridDefinitionQuadro07Lista074SomaControlo = {
      columns: [
        {dataField: 'title', dataType: 'string', caption: ''},
        {dataField: '_total1', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table74.fields.tot1'},
        {dataField: '_total2', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table74.fields.tot2'},
        {dataField: '_total3', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table74.fields.tot3'},
        {dataField: '_total4', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table74.fields.tot4'},
        {dataField: '_total5', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table74.fields.tot5'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false
    };
    this.dataGridDefinitionQuadro07Lista074ASomaControlo = {
      columns: [
        {dataField: 'title', dataType: 'string', caption: ''},
        {dataField: '_total1', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table74A.fields.tot1'},
        {dataField: '_total2', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table74A.fields.tot2'},
        {dataField: '_total3', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table74A.fields.tot3'},
        {dataField: '_total4', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table74A.fields.tot4'},
        {dataField: '_total5', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table74A.fields.tot5'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false
    };
    this.dataGridDefinitionQuadro07Lista076SomaControlo = {
      columns: [
        {dataField: 'title', dataType: 'string', caption: ''},
        {dataField: '_total1', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table76.tot1'},
        {dataField: '_total2', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table76.tot2'},
        {dataField: '_total3', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table76.tot3'},
        {dataField: '_total4', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table76.tot4'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false
    };
    this.dataGridDefinitionQuadro07Lista076ASomaControlo = {
      columns: [
        {dataField: 'title', dataType: 'string', caption: ''},
        {dataField: '_total1', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table76A.tot1'},
        {dataField: '_total2', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table76A.tot2'},
        {dataField: '_total3', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table76A.tot3'},
        {dataField: '_total4', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table76A.tot4'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false
    };
    this.dataGridDefinitionQuadro07Lista079SomaControlo = {
      columns: [
        {dataField: 'title', dataType: 'string', caption: ''},
        {dataField: '_total1', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table79.fields.tot1'},
        {dataField: '_total2', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table79.fields.tot2'},
        {dataField: '_total3', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table79.fields.tot3'},
        {dataField: '_total4', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table79.fields.tot4'},
        {dataField: '_total5', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table79.fields.tot5'},
        {dataField: '_total6', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table79.fields.tot6'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false
    };
    this.dataGridDefinitionQuadro07Lista0710SomaControlo = {
      columns: [
        {dataField: 'title', dataType: 'string', caption: ''},
        {dataField: '_total1', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table710.fields.tot1'},
        {dataField: '_total2', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table710.fields.tot2'},
        {dataField: '_total3', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table710.fields.tot3'},
        {dataField: '_total4', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table710.fields.tot4'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false
    };
    this.dataGridDefinitionQuadro07Lista0710ASomaControlo = {
      columns: [
        {dataField: 'title', dataType: 'string', caption: ''},
        {dataField: '_total1', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table710A.fields.tot1'},
        {dataField: '_total2', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table710A.fields.tot2'},
        {dataField: '_total3', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table710A.fields.tot3'},
        {dataField: '_total4', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table710A.fields.tot4'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false
    };
    this.dataGridDefinitionQuadro07Lista077SomaControlo = {
      columns: [
        {dataField: 'title', dataType: 'string', caption: ''},
        {dataField: '_total1', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table77.fields.tot1'},
        {dataField: '_total2', dataType: 'double', caption: 'modelo22.anexoD.quadro07.table77.fields.tot2'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false
    };

    this.nifs907List = [];
    this.nifs03List = [];
    this.cods604List = [];
    this.cods605List = [];

    const totalsTitle: string = this._translateService.instant('modelo22.anexoD.quadro07.somacontrolo');
    this._anexoDQuadro7Lista071Totals = {
      title: totalsTitle,
      _total1: 0,
      _total2: 0,
      _total3: 0,
      _total4: 0,
      _total5: 0,
      _total6: 0
    };
    this._anexoDQuadro7Lista071ATotals = {
      title: totalsTitle,
      _total1: 0,
      _total2: 0,
      _total3: 0,
      _total4: 0,
      _total5: 0,
      _total6: 0
    };
    this._anexoDQuadro7Lista073Totals = {
      title: totalsTitle,
      _total1: 0,
      _total2: 0,
      _total3: 0,
      _total4: 0,
      _total5: 0,
      _total6: 0
    };
    this._anexoDQuadro7Lista073ATotals = {
      title: totalsTitle,
      _total1: 0,
      _total2: 0,
      _total3: 0,
      _total4: 0,
      _total5: 0,
      _total6: 0
    };
    this._anexoDQuadro7Lista074Totals = {
      title: totalsTitle,
      _total1: 0,
      _total2: 0,
      _total3: 0,
      _total4: 0,
      _total5: 0,
      _total6: 0
    };
    this._anexoDQuadro7Lista074ATotals = {
      title: totalsTitle,
      _total1: 0,
      _total2: 0,
      _total3: 0,
      _total4: 0,
      _total5: 0,
      _total6: 0
    };
    this._anexoDQuadro7Lista076Totals = {
      title: totalsTitle,
      _total1: 0,
      _total2: 0,
      _total3: 0,
      _total4: 0,
      _total5: 0,
      _total6: 0
    };
    this._anexoDQuadro7Lista076ATotals = {
      title: totalsTitle,
      _total1: 0,
      _total2: 0,
      _total3: 0,
      _total4: 0,
      _total5: 0,
      _total6: 0
    };
    this._anexoDQuadro7Lista079Totals = {
      title: totalsTitle,
      _total1: 0,
      _total2: 0,
      _total3: 0,
      _total4: 0,
      _total5: 0,
      _total6: 0
    };
    this._anexoDQuadro7Lista0710Totals = {
      title: totalsTitle,
      _total1: 0,
      _total2: 0,
      _total3: 0,
      _total4: 0,
      _total5: 0,
      _total6: 0
    };
    this._anexoDQuadro7Lista0710ATotals = {
      title: totalsTitle,
      _total1: 0,
      _total2: 0,
      _total3: 0,
      _total4: 0,
      _total5: 0,
      _total6: 0
    };
    this._anexoDQuadro7Lista077Totals = {
      title: totalsTitle,
      _total1: 0,
      _total2: 0,
      _total3: 0,
      _total4: 0,
      _total5: 0,
      _total6: 0
    };

    this.quadro7List71Totais = [this._anexoDQuadro7Lista071Totals];
    this.quadro7List71ATotais = [this._anexoDQuadro7Lista071ATotals];
    this.quadro7List73Totais = [this._anexoDQuadro7Lista073Totals];
    this.quadro7List73ATotais = [this._anexoDQuadro7Lista073ATotals];
    this.quadro7List74Totais = [this._anexoDQuadro7Lista074Totals];
    this.quadro7List74ATotais = [this._anexoDQuadro7Lista074ATotals];
    this.quadro7List76Totais = [this._anexoDQuadro7Lista076Totals];
    this.quadro7List76ATotais = [this._anexoDQuadro7Lista076ATotals];
    this.quadro7List79Totais = [this._anexoDQuadro7Lista079Totals];
    this.quadro7List710Totais = [this._anexoDQuadro7Lista0710Totals];
    this.quadro7List710ATotais = [this._anexoDQuadro7Lista0710ATotals];
    this.quadro7List77Totais = [this._anexoDQuadro7Lista077Totals];
  }

  public ngOnInit(): void {
    this._calcTotals();
    this._setListIndexes();

    if (this.anexoD) {
      this._convertToDefinition(EArrayIds.Tabela907);
      this._convertToDefinition(EArrayIds.Tabela03);
      this._convertToDefinition(EArrayIds.Tabela604);
      this._convertToDefinition(EArrayIds.Tabela605);
    }
  }

  public ngOnChanges({modelo22}: SimpleChanges): void {
    if (modelo22 && !modelo22.isFirstChange()) {
      this.modelo22 = modelo22.currentValue;

      this._calcTotals();
      if (this.anexoD) {
        this._convertToDefinition(EArrayIds.Tabela907);
        this._convertToDefinition(EArrayIds.Tabela03);
        this._convertToDefinition(EArrayIds.Tabela604);
        this._convertToDefinition(EArrayIds.Tabela605);
      }
      this._setListIndexes();
    }
  }

  public onDataGridRowRemoved(): void {
    this._setListIndexes();
    this.emitModelo22();
  }

  public addLineAnexoDQuadro3(field: string): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(Modelo22AnexoDModalComponent);
    const componentInstance: Modelo22AnexoDModalComponent = modalInstance.componentInstance;
    componentInstance.anexoD = copy(this.anexoD);
    componentInstance.newLineFor = field;
    return modalInstance.result.then((result: IJsonModelo22AnexoD) => {
      this._applyAnexoD(result);
    });
  }

  public deleteLineAnexoDQuadro3(line: number, field: string): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'entity.delete.message').then(() => {
      if (field === '31A') {
        this.anexoD.quadro03.campo031AList.splice(line, 1);
      }
      if (field === '31B') {
        this.anexoD.quadro03.campo031BList.splice(line, 1);
      }
      if (field === '32A') {
        this.anexoD.quadro03.campo032AList.splice(line, 1);
      }
      if (field === '32B') {
        this.anexoD.quadro03.campo032BList.splice(line, 1);
      }
      this._setListIndexes();
      this.emitModelo22();
    });
  }

  public addLineAnexoDQuadro4(field: string): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(Modelo22AnexoDModalComponent);
    const componentInstance: Modelo22AnexoDModalComponent = modalInstance.componentInstance;
    componentInstance.anexoD = copy(this.anexoD);
    componentInstance.newLineFor = field;
    return modalInstance.result.then((result: IJsonModelo22AnexoD) => {
      this._applyAnexoD(result);
    });
  }

  public deleteLineAnexoDQuadro4(line: number, field: string): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'entity.delete.message').then(() => {
      if (field === '04A') {
        this.anexoD.quadro04.campo04AList.splice(line, 1);
      }
      if (field === '041') {
        this.anexoD.quadro04.campo041List.splice(line, 1);
      }
      this._setListIndexes();
      this.emitModelo22();
    });
  }

  public addLineAnexoDQuadro6(field: string): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(Modelo22AnexoDModalComponent);
    const componentInstance: Modelo22AnexoDModalComponent = modalInstance.componentInstance;
    componentInstance.anexoD = copy(this.anexoD);
    componentInstance.newLineFor = field;
    return modalInstance.result.then((result: IJsonModelo22AnexoD) => {
      this._applyAnexoD(result);
      if (field === '604') {
        this._convertToDefinition(EArrayIds.Tabela604);
      } else if (field === '605') {
        this._convertToDefinition(EArrayIds.Tabela605);
      }
    });
  }

  public deleteLineAnexoDQuadro6(line: number, field: string): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'entity.delete.message').then(() => {
      if (field === '603') {
        this.anexoD.quadro06.campo603List.splice(line, 1);
      }
      if (field === '604') {
        this.cods604List.splice(line, 1);
        this._convertFromDefinition(EArrayIds.Tabela604);
      }
      if (field === '605') {
        this.cods605List.splice(line, 1);
        this._convertFromDefinition(EArrayIds.Tabela605);
      }
      this._setListIndexes();
      this.emitModelo22();
    });
  }

  public addLineAnexoDQuadro7(field: string): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(Modelo22AnexoDQuadro7ModalComponent);
    const componentInstance: Modelo22AnexoDQuadro7ModalComponent = modalInstance.componentInstance;
    componentInstance.anexoD = copy(this.anexoD);
    componentInstance.newLineFor = field;
    return modalInstance.result.then((result: IJsonModelo22AnexoD) => {
      this._applyAnexoD(result);
      if (field === '71' || field === '73' || field === '74' || field === '76' || field === '79' || field === '710') {
        this.calcQuadro7();
      }
    });
  }

  public deleteLineAnexoDQuadro7(line: number, field: string): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'entity.delete.message').then(() => {
      if (field === '71') {
        this.anexoD.quadro07.campo071List.splice(line, 1);
      }
      if (field === '71A') {
        this.anexoD.quadro07.campo071AList.splice(line, 1);
      }
      if (field === '73') {
        this.anexoD.quadro07.campo073List.splice(line, 1);
      }
      if (field === '73A') {
        this.anexoD.quadro07.campo073AList.splice(line, 1);
      }
      if (field === '74') {
        this.anexoD.quadro07.campo074List.splice(line, 1);
      }
      if (field === '74A') {
        this.anexoD.quadro07.campo074AList.splice(line, 1);
      }
      if (field === '76') {
        this.anexoD.quadro07.campo076List.splice(line, 1);
      }
      if (field === '76A') {
        this.anexoD.quadro07.campo076AList.splice(line, 1);
      }
      if (field === '79') {
        this.anexoD.quadro07.campo079List.splice(line, 1);
      }
      if (field === '710') {
        this.anexoD.quadro07.campo0710List.splice(line, 1);
      }
      if (field === '710A') {
        this.anexoD.quadro07.campo0710List.splice(line, 1);
      }
      if (field === '77') {
        this.anexoD.quadro07.campo077List.splice(line, 1);
      }
      this._applyAnexoD(this.anexoD);
      if (field === '71' || field === '73' || field === '74' || field === '76' || field === '79' || field === '710') {
        this.calcQuadro7();
      }
    });
  }

  public addLineAnexoDQuadro7Tabelas78(field: string): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(Modelo22AnexoDQuadro7Tabelas78ModalComponent);
    const componentInstance: Modelo22AnexoDQuadro7Tabelas78ModalComponent = modalInstance.componentInstance;
    componentInstance.anexoD = copy(this.anexoD);
    componentInstance.newLineFor = field;
    return modalInstance.result.then((result: IJsonModelo22AnexoD) => {
      this._applyAnexoD(result);
    });
  }

  public deleteLineAnexoDQuadro7Tabelas78(line: number, field: string): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'entity.delete.message').then(() => {
      if (field === '78') {
        this.anexoD.quadro07.campo078List.splice(line, 1);
      }
      if (field === '78A1') {
        this.anexoD.quadro07.campo078A1List.splice(line, 1);
      }
      if (field === '78A2') {
        this.anexoD.quadro07.campo078A2List.splice(line, 1);
      }
      if (field === '78A3') {
        this.anexoD.quadro07.campo078A3List.splice(line, 1);
      }
      this._setListIndexes();
      this.emitModelo22();
    });
  }

  public addLineAnexoDQuadro8(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(Modelo22AnexoDModalComponent);
    const componentInstance: Modelo22AnexoDModalComponent = modalInstance.componentInstance;
    componentInstance.anexoD = copy(this.anexoD);
    componentInstance.newLineFor = 'quadro8';
    return modalInstance.result.then((result: IJsonModelo22AnexoD) => {
      this._applyAnexoD(result);
    });
  }

  public deleteLineAnexoDQuadro8(line: number): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'entity.delete.message').then(() => {
      this.anexoD.quadro08.lista.splice(line, 1);
      this._setListIndexes();
      this.emitModelo22();
    });
  }

  public addLineAnexoDQuadro9(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(Modelo22AnexoDModalComponent);
    const componentInstance: Modelo22AnexoDModalComponent = modalInstance.componentInstance;
    componentInstance.anexoD = copy(this.anexoD);
    componentInstance.newLineFor = 'quadro9';
    return modalInstance.result.then((result: IJsonModelo22AnexoD) => {
      this._applyAnexoD(result);
      this._convertToDefinition(EArrayIds.Tabela907);
    });
  }

  public deleteLineAnexoDQuadro9(line: number): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'entity.delete.message').then(() => {
      this.nifs907List.splice(line, 1);
      this._convertFromDefinition(EArrayIds.Tabela907);
      this.emitModelo22();
    });
  }

  public addLineAnexoDQuadro11A(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(Modelo22AnexoDModalComponent);
    const componentInstance: Modelo22AnexoDModalComponent = modalInstance.componentInstance;
    componentInstance.anexoD = copy(this.anexoD);
    componentInstance.newLineFor = 'quadro11A';
    return modalInstance.result.then((result: IJsonModelo22AnexoD) => {
      this._applyAnexoD(result);
      this._convertToDefinition(EArrayIds.Tabela03);
    });
  }

  public deleteLineAnexoDQuadro11A(line: number): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'entity.delete.message').then(() => {
      this.nifs03List.splice(line, 1);
      this._convertFromDefinition(EArrayIds.Tabela03);
      this.emitModelo22();
    });
  }

  public addLineAnexoDQuadro12(field: string): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(Modelo22AnexoDModalComponent);
    const componentInstance: Modelo22AnexoDModalComponent = modalInstance.componentInstance;
    componentInstance.anexoD = copy(this.anexoD);
    componentInstance.newLineFor = field;
    return modalInstance.result.then((result: IJsonModelo22AnexoD) => {
      this._applyAnexoD(result);
    });
  }

  public deleteLineAnexoDQuadro12(line: number, field: string): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'entity.delete.message').then(() => {
      if (field === '121') {
        this.anexoD.quadro12.lista121.splice(line, 1);
      }
      if (field === '122') {
        this.anexoD.quadro12.lista122.splice(line, 1);
      }
      this._setListIndexes();
      this.emitModelo22();
    });
  }

  public async emitModelo22(evaluateModelo22: boolean = false): Promise<void> {
    if (evaluateModelo22) {
      await this._modelo22Service.evaluateModelo22(this.modelo22).then((response: HttpResponse<IJsonModelo22>) => {
        this.modelo22 = response.body;
      });
    }
    this.evtChangedModelo22.emit(this.modelo22);
  }

  public calcQuadro4(): void {
    const f401 = isNumber(this.anexoD.quadro04.campo401) ? this.anexoD.quadro04.campo401 : 0;
    const f402 = isNumber(this.anexoD.quadro04.campo402) ? this.anexoD.quadro04.campo402 : 0;
    const f403 = isNumber(this.anexoD.quadro04.campo403) ? this.anexoD.quadro04.campo403 : 0;
    const f404 = isNumber(this.anexoD.quadro04.campo404) ? this.anexoD.quadro04.campo404 : 0;
    const f405 = isNumber(this.anexoD.quadro04.campo405) ? this.anexoD.quadro04.campo405 : 0;
    const f406 = isNumber(this.anexoD.quadro04.campo406) ? this.anexoD.quadro04.campo406 : 0;
    const f426 = isNumber(this.anexoD.quadro04.campo426) ? this.anexoD.quadro04.campo426 : 0;
    const f427 = isNumber(this.anexoD.quadro04.campo427) ? this.anexoD.quadro04.campo427 : 0;
    const f428 = isNumber(this.anexoD.quadro04.campo428) ? this.anexoD.quadro04.campo428 : 0;
    const f432 = isNumber(this.anexoD.quadro04.campo432) ? this.anexoD.quadro04.campo432 : 0;
    const f407 = isNumber(this.anexoD.quadro04.campo407) ? this.anexoD.quadro04.campo407 : 0;
    const f408 = isNumber(this.anexoD.quadro04.campo408) ? this.anexoD.quadro04.campo408 : 0;
    const f409 = isNumber(this.anexoD.quadro04.campo409) ? this.anexoD.quadro04.campo409 : 0;
    const f410 = isNumber(this.anexoD.quadro04.campo410) ? this.anexoD.quadro04.campo410 : 0;
    const f412 = isNumber(this.anexoD.quadro04.campo412) ? this.anexoD.quadro04.campo412 : 0;
    const f413 = isNumber(this.anexoD.quadro04.campo413) ? this.anexoD.quadro04.campo413 : 0;
    const f414 = isNumber(this.anexoD.quadro04.campo414) ? this.anexoD.quadro04.campo414 : 0;
    const f415 = isNumber(this.anexoD.quadro04.campo415) ? this.anexoD.quadro04.campo415 : 0;
    const f416 = isNumber(this.anexoD.quadro04.campo416) ? this.anexoD.quadro04.campo416 : 0;
    const f417 = isNumber(this.anexoD.quadro04.campo417) ? this.anexoD.quadro04.campo417 : 0;
    const f418 = isNumber(this.anexoD.quadro04.campo418) ? this.anexoD.quadro04.campo418 : 0;
    const f419 = isNumber(this.anexoD.quadro04.campo419) ? this.anexoD.quadro04.campo419 : 0;
    const f420 = isNumber(this.anexoD.quadro04.campo420) ? this.anexoD.quadro04.campo420 : 0;
    const f421 = isNumber(this.anexoD.quadro04.campo421) ? this.anexoD.quadro04.campo421 : 0;
    const f422 = isNumber(this.anexoD.quadro04.campo422) ? this.anexoD.quadro04.campo422 : 0;
    const f423 = isNumber(this.anexoD.quadro04.campo423) ? this.anexoD.quadro04.campo423 : 0;
    const f424 = isNumber(this.anexoD.quadro04.campo424) ? this.anexoD.quadro04.campo424 : 0;
    const f425 = isNumber(this.anexoD.quadro04.campo425) ? this.anexoD.quadro04.campo425 : 0;
    const f429 = isNumber(this.anexoD.quadro04.campo429) ? this.anexoD.quadro04.campo429 : 0;
    const f430 = isNumber(this.anexoD.quadro04.campo430) ? this.anexoD.quadro04.campo430 : 0;
    const f431 = isNumber(this.anexoD.quadro04.campo431) ? this.anexoD.quadro04.campo431 : 0;
    const f433 = isNumber(this.anexoD.quadro04.campo433) ? this.anexoD.quadro04.campo433 : 0;
    const f434 = isNumber(this.anexoD.quadro04.campo434) ? this.anexoD.quadro04.campo434 : 0;
    const f435 = isNumber(this.anexoD.quadro04.campo435) ? this.anexoD.quadro04.campo435 : 0;
    const f436 = isNumber(this.anexoD.quadro04.campo436) ? this.anexoD.quadro04.campo436 : 0;
    const f437 = isNumber(this.anexoD.quadro04.campo437) ? this.anexoD.quadro04.campo437 : 0;
    const f438 = isNumber(this.anexoD.quadro04.campo438) ? this.anexoD.quadro04.campo438 : 0;
    const f439 = isNumber(this.anexoD.quadro04.campo439) ? this.anexoD.quadro04.campo439 : 0;

    this.anexoD.quadro04.campo411 =
      f401 +
      f402 +
      f403 +
      f404 +
      f405 +
      f406 +
      f426 +
      f427 +
      f428 +
      f432 +
      f407 +
      f408 +
      f409 +
      f410 +
      f412 +
      f413 +
      f414 +
      f415 +
      f416 +
      f417 +
      f418 +
      f419 +
      f420 +
      f421 +
      f422 +
      f423 +
      f424 +
      f425 +
      f429 +
      f430 +
      f431 +
      f433 +
      f434 +
      f435 +
      f436 +
      f437 +
      f438 +
      f439;
    this.emitModelo22();
  }

  public calcQuadro7(): void {
    const f707 = isNumber(this.anexoD.quadro07.campo707) ? this.anexoD.quadro07.campo707 : 0;
    const f717 = isNumber(this.anexoD.quadro07.campo717) ? this.anexoD.quadro07.campo717 : 0;
    const f718 = isNumber(this.anexoD.quadro07.campo718) ? this.anexoD.quadro07.campo718 : 0;
    const f719 = isNumber(this.anexoD.quadro07.campo719) ? this.anexoD.quadro07.campo719 : 0;
    const f720 = isNumber(this.anexoD.quadro07.campo720) ? this.anexoD.quadro07.campo720 : 0;
    const f726 = isNumber(this.anexoD.quadro07.campo726) ? this.anexoD.quadro07.campo726 : 0;
    const f727 = isNumber(this.anexoD.quadro07.campo727) ? this.anexoD.quadro07.campo727 : 0;
    const f728 = isNumber(this.anexoD.quadro07.campo728) ? this.anexoD.quadro07.campo728 : 0;

    this.anexoD.quadro07.campo721 =
      this._anexoDQuadro7Lista071Totals._total4 +
      f707 +
      this._anexoDQuadro7Lista073Totals._total4 +
      this._anexoDQuadro7Lista074Totals._total4 +
      this._anexoDQuadro7Lista076Totals._total3 +
      this._anexoDQuadro7Lista079Totals._total3 +
      this._anexoDQuadro7Lista0710Totals._total3 +
      f717 +
      f718 +
      f719 +
      f720 +
      f726 +
      f727 +
      f728;

    this.emitModelo22();
  }

  public calcQuadro10(): void {
    const f1001 = isNumber(this.anexoD.quadro10.campo1001) ? this.anexoD.quadro10.campo1001 : 0;
    const f1002 = isNumber(this.anexoD.quadro10.campo1002) ? this.anexoD.quadro10.campo1002 : 0;

    this.anexoD.quadro10.campo1003 = f1001 + f1002;

    this.emitModelo22();
  }

  public get anexoD(): IJsonModelo22AnexoD {
    return this.modelo22.anexoD;
  }

  public set anexoD(value: IJsonModelo22AnexoD) {
    this.modelo22.anexoD = value;
  }

  private _applyAnexoD(anexoD: IJsonModelo22AnexoD): void {
    this.anexoD = merge(this.anexoD, anexoD);
    this._clearCGDatesDefault();
    this._calcTotals();
    this._setListIndexes();
    this.emitModelo22();
  }

  private _setListIndexes(): void {
    let index031A = 1;
    for (const listElement of this.anexoD.quadro03.campo031AList) {
      listElement._index = index031A;
      index031A++;
    }
    this.dataGridDefinition31A.dataSource = this.anexoD.quadro03.campo031AList;

    let index031B = 1;
    for (const listElement of this.anexoD.quadro03.campo031BList) {
      listElement._index = index031B;
      index031B++;
    }
    this.dataGridDefinition31B.dataSource = this.anexoD.quadro03.campo031BList.slice();

    let index032A = 1;
    for (const listElement of this.anexoD.quadro03.campo032AList) {
      listElement._index = index032A;
      index032A++;
    }
    this.dataGridDefinition32A.dataSource = this.anexoD.quadro03.campo032AList.slice();

    let index032B = 1;
    for (const listElement of this.anexoD.quadro03.campo032BList) {
      listElement._index = index032B;
      index032B++;
    }
    this.dataGridDefinition32B.dataSource = this.anexoD.quadro03.campo032BList.slice();

    let index041 = 1;
    for (const listElement of this.anexoD.quadro04.campo041List) {
      listElement._index = index041;
      index041++;
    }
    this.dataGridDefinition041.dataSource = this.anexoD.quadro04.campo041List.slice();

    let index04A = 1;
    for (const listElement of this.anexoD.quadro04.campo04AList) {
      listElement._index = index04A;
      index04A++;
    }
    this.dataGridDefinition04A.dataSource = this.anexoD.quadro04.campo04AList.slice();

    let index603 = 1;
    for (const listElement of this.anexoD.quadro06.campo603List) {
      listElement._index = index603;
      index603++;
    }
    this.dataGridDefinition603.dataSource = this.anexoD.quadro06.campo603List.slice();

    let index071 = 1;
    for (const listElement of this.anexoD.quadro07.campo071List) {
      listElement._index = index071;
      index071++;
    }
    this.dataGridDefinition71.dataSource = this.anexoD.quadro07.campo071List.slice();

    let index071A = 1;
    for (const listElement of this.anexoD.quadro07.campo071AList) {
      listElement._index = index071A;
      index071A++;
    }
    this.dataGridDefinition71A.dataSource = this.anexoD.quadro07.campo071AList.slice();

    let index073 = 1;
    for (const listElement of this.anexoD.quadro07.campo073List) {
      listElement._index = index073;
      index073++;
    }
    this.dataGridDefinition73.dataSource = this.anexoD.quadro07.campo073List.slice();

    let index073A = 1;
    for (const listElement of this.anexoD.quadro07.campo073AList) {
      listElement._index = index073A;
      index073A++;
    }
    this.dataGridDefinition73A.dataSource = this.anexoD.quadro07.campo073AList.slice();

    let index074 = 1;
    for (const listElement of this.anexoD.quadro07.campo074List) {
      listElement._index = index074;
      index074++;
    }
    this.dataGridDefinition74A.dataSource = this.anexoD.quadro07.campo074List.slice();

    let index074A = 1;
    for (const listElement of this.anexoD.quadro07.campo074AList) {
      listElement._index = index074A;
      index074A++;
    }
    this.dataGridDefinition74A.dataSource = this.anexoD.quadro07.campo074AList.slice();

    let index076 = 1;
    for (const listElement of this.anexoD.quadro07.campo076List) {
      listElement._index = index076;
      index076++;
    }
    this.dataGridDefinition76.dataSource = this.anexoD.quadro07.campo076List.slice();

    let index076A = 1;
    for (const listElement of this.anexoD.quadro07.campo076AList) {
      listElement._index = index076A;
      index076A++;
    }
    this.dataGridDefinition76A.dataSource = this.anexoD.quadro07.campo076AList.slice();

    let index079 = 1;
    for (const listElement of this.anexoD.quadro07.campo079List) {
      listElement._index = index079;
      index079++;
    }
    this.dataGridDefinition79.dataSource = this.anexoD.quadro07.campo079List.slice();

    let index0710 = 1;
    for (const listElement of this.anexoD.quadro07.campo0710List) {
      listElement._index = index0710;
      index0710++;
    }
    this.dataGridDefinition710.dataSource = this.anexoD.quadro07.campo0710List.slice();

    let index0710A = 1;
    for (const listElement of this.anexoD.quadro07.campo0710AList) {
      listElement._index = index0710A;
      index0710A++;
    }
    this.dataGridDefinition710A.dataSource = this.anexoD.quadro07.campo0710AList.slice();

    let index077 = 1;
    for (const listElement of this.anexoD.quadro07.campo077List) {
      listElement._index = index077;
      index077++;
    }
    this.dataGridDefinition77.dataSource = this.anexoD.quadro07.campo077List.slice();

    let index078 = 1;
    for (const listElement of this.anexoD.quadro07.campo078List) {
      listElement._index = index078;
      index078++;
    }
    this.dataGridDefinition78.dataSource = this.anexoD.quadro07.campo078List.slice();

    let index078A1 = 1;
    for (const listElement of this.anexoD.quadro07.campo078A1List) {
      listElement._index = index078A1;
      index078A1++;
    }
    this.dataGridDefinition78A1.dataSource = this.anexoD.quadro07.campo078A1List.slice();

    let index078A2 = 1;
    for (const listElement of this.anexoD.quadro07.campo078A2List) {
      listElement._index = index078A2;
      index078A2++;
    }
    this.dataGridDefinition78A2.dataSource = this.anexoD.quadro07.campo078A2List.slice();

    let index078A3 = 1;
    for (const listElement of this.anexoD.quadro07.campo078A3List) {
      listElement._index = index078A3;
      index078A3++;
    }
    this.dataGridDefinition78A3.dataSource = this.anexoD.quadro07.campo078A3List.slice();

    let index121 = 1;
    for (const listElement of this.anexoD.quadro12.lista121) {
      listElement._index = index121;
      index121++;
    }
    this.dataGridDefinition121.dataSource = this.anexoD.quadro12.lista121.slice();

    let index122 = 1;
    for (const listElement of this.anexoD.quadro12.lista122) {
      listElement._index = index122;
      index122++;
    }
    this.dataGridDefinition122.dataSource = this.anexoD.quadro12.lista122.slice();
  }

  private _calcTotals(): void {
    this._anexoDQuadro7Lista071Totals._total1 = 0;
    this._anexoDQuadro7Lista071Totals._total2 = 0;
    this._anexoDQuadro7Lista071Totals._total3 = 0;
    this._anexoDQuadro7Lista071Totals._total4 = 0;
    this._anexoDQuadro7Lista071Totals._total5 = 0;
    for (const item of this.anexoD.quadro07.campo071List) {
      const valueCaducado = isNumber(item.saldoCaducado) ? item.saldoCaducado : 0;
      const valueNaoDeduzido = isNumber(item.saldoNaoDeduzido) ? item.saldoNaoDeduzido : 0;
      const valueDotacao = isNumber(item.dotacaoDoPeriodo) ? item.dotacaoDoPeriodo : 0;
      const valueDeducao = isNumber(item.deducaoNoPeriodo) ? item.deducaoNoPeriodo : 0;
      const valueTransita = isNumber(item.saldoQueTransita) ? item.saldoQueTransita : 0;
      this._anexoDQuadro7Lista071Totals._total1 += valueCaducado;
      this._anexoDQuadro7Lista071Totals._total2 += valueNaoDeduzido;
      this._anexoDQuadro7Lista071Totals._total3 += valueDotacao;
      this._anexoDQuadro7Lista071Totals._total4 += valueDeducao;
      this._anexoDQuadro7Lista071Totals._total5 += valueTransita;
    }
    this.quadro7List71Totais = this.quadro7List71Totais.slice();

    this._anexoDQuadro7Lista071ATotals._total1 = 0;
    this._anexoDQuadro7Lista071ATotals._total2 = 0;
    this._anexoDQuadro7Lista071ATotals._total3 = 0;
    this._anexoDQuadro7Lista071ATotals._total4 = 0;
    this._anexoDQuadro7Lista071ATotals._total5 = 0;
    for (const item of this.anexoD.quadro07.campo071AList) {
      const valueCaducado = isNumber(item.saldoCaducado) ? item.saldoCaducado : 0;
      const valueNaoDeduzido = isNumber(item.saldoNaoDeduzido) ? item.saldoNaoDeduzido : 0;
      const valueDotacao = isNumber(item.dotacaoDoPeriodo) ? item.dotacaoDoPeriodo : 0;
      const valueDeducao = isNumber(item.deducaoUtilizada) ? item.deducaoUtilizada : 0;
      const valueTransita = isNumber(item.saldoQueTransita) ? item.saldoQueTransita : 0;

      this._anexoDQuadro7Lista071ATotals._total1 += valueCaducado;
      this._anexoDQuadro7Lista071ATotals._total2 += valueNaoDeduzido;
      this._anexoDQuadro7Lista071ATotals._total3 += valueDotacao;
      this._anexoDQuadro7Lista071ATotals._total4 += valueDeducao;
      this._anexoDQuadro7Lista071ATotals._total5 += valueTransita;
    }
    this.quadro7List71ATotais = this.quadro7List71ATotais.slice();

    this._anexoDQuadro7Lista073Totals._total1 = 0;
    this._anexoDQuadro7Lista073Totals._total2 = 0;
    this._anexoDQuadro7Lista073Totals._total3 = 0;
    this._anexoDQuadro7Lista073Totals._total4 = 0;
    this._anexoDQuadro7Lista073Totals._total5 = 0;
    for (const item of this.anexoD.quadro07.campo073List) {
      const valueCaducado = isNumber(item.saldoCaducado) ? item.saldoCaducado : 0;
      const valueNaoDeduzido = isNumber(item.saldoNaoDeduzido) ? item.saldoNaoDeduzido : 0;
      const valueDotacao = isNumber(item.dotacaoDoPeriodo) ? item.dotacaoDoPeriodo : 0;
      const valueDeducao = isNumber(item.deducaoNoPeriodo) ? item.deducaoNoPeriodo : 0;
      const valueTransita = isNumber(item.saldoQueTransita) ? item.saldoQueTransita : 0;

      this._anexoDQuadro7Lista073Totals._total1 += valueCaducado;
      this._anexoDQuadro7Lista073Totals._total2 += valueNaoDeduzido;
      this._anexoDQuadro7Lista073Totals._total3 += valueDotacao;
      this._anexoDQuadro7Lista073Totals._total4 += valueDeducao;
      this._anexoDQuadro7Lista073Totals._total5 += valueTransita;
    }
    this.quadro7List73Totais = this.quadro7List73Totais.slice();

    this._anexoDQuadro7Lista073ATotals._total1 = 0;
    this._anexoDQuadro7Lista073ATotals._total2 = 0;
    this._anexoDQuadro7Lista073ATotals._total3 = 0;
    this._anexoDQuadro7Lista073ATotals._total4 = 0;
    this._anexoDQuadro7Lista073ATotals._total5 = 0;
    for (const item of this.anexoD.quadro07.campo073AList) {
      const valueCaducado = isNumber(item.saldoCaducado) ? item.saldoCaducado : 0;
      const valueNaoDeduzido = isNumber(item.saldoNaoDeduzido) ? item.saldoNaoDeduzido : 0;
      const valueDotacao = isNumber(item.dotacaoDoPeriodo) ? item.dotacaoDoPeriodo : 0;
      const valueDeducao = isNumber(item.deducaoUtilizada) ? item.deducaoUtilizada : 0;
      const valueTransita = isNumber(item.saldoQueTransita) ? item.saldoQueTransita : 0;

      this._anexoDQuadro7Lista073ATotals._total1 += valueCaducado;
      this._anexoDQuadro7Lista073ATotals._total2 += valueNaoDeduzido;
      this._anexoDQuadro7Lista073ATotals._total3 += valueDotacao;
      this._anexoDQuadro7Lista073ATotals._total4 += valueDeducao;
      this._anexoDQuadro7Lista073ATotals._total5 += valueTransita;
    }
    this.quadro7List73ATotais = this.quadro7List73ATotais.slice();

    this._anexoDQuadro7Lista074Totals._total1 = 0;
    this._anexoDQuadro7Lista074Totals._total2 = 0;
    this._anexoDQuadro7Lista074Totals._total3 = 0;
    this._anexoDQuadro7Lista074Totals._total4 = 0;
    this._anexoDQuadro7Lista074Totals._total5 = 0;
    for (const item of this.anexoD.quadro07.campo074List) {
      const valueCaducado = isNumber(item.saldoCaducado) ? item.saldoCaducado : 0;
      const valueNaoDeduzido = isNumber(item.saldoNaoDeduzido) ? item.saldoNaoDeduzido : 0;
      const valueDotacao = isNumber(item.dotacaoDoPeriodo) ? item.dotacaoDoPeriodo : 0;
      const valueDeducao = isNumber(item.deducaoNoPeriodo) ? item.deducaoNoPeriodo : 0;
      const valueTransita = isNumber(item.saldoQueTransita) ? item.saldoQueTransita : 0;

      this._anexoDQuadro7Lista074Totals._total1 += valueCaducado;
      this._anexoDQuadro7Lista074Totals._total2 += valueNaoDeduzido;
      this._anexoDQuadro7Lista074Totals._total3 += valueDotacao;
      this._anexoDQuadro7Lista074Totals._total4 += valueDeducao;
      this._anexoDQuadro7Lista074Totals._total5 += valueTransita;
    }
    this.quadro7List74Totais = this.quadro7List74Totais.slice();

    this._anexoDQuadro7Lista074ATotals._total1 = 0;
    this._anexoDQuadro7Lista074ATotals._total2 = 0;
    this._anexoDQuadro7Lista074ATotals._total3 = 0;
    this._anexoDQuadro7Lista074ATotals._total4 = 0;
    this._anexoDQuadro7Lista074ATotals._total5 = 0;
    for (const item of this.anexoD.quadro07.campo074AList) {
      const valueCaducado = isNumber(item.saldoCaducado) ? item.saldoCaducado : 0;
      const valueNaoDeduzido = isNumber(item.saldoNaoDeduzido) ? item.saldoNaoDeduzido : 0;
      const valueDotacao = isNumber(item.dotacaoDoPeriodo) ? item.dotacaoDoPeriodo : 0;
      const valueDeducao = isNumber(item.deducaoUtilizada) ? item.deducaoUtilizada : 0;
      const valueTransita = isNumber(item.saldoQueTransita) ? item.saldoQueTransita : 0;

      this._anexoDQuadro7Lista074ATotals._total1 += valueCaducado;
      this._anexoDQuadro7Lista074ATotals._total2 += valueNaoDeduzido;
      this._anexoDQuadro7Lista074ATotals._total3 += valueDotacao;
      this._anexoDQuadro7Lista074ATotals._total4 += valueDeducao;
      this._anexoDQuadro7Lista074ATotals._total5 += valueTransita;
    }
    this.quadro7List74ATotais = this.quadro7List74ATotais.slice();

    this._anexoDQuadro7Lista076Totals._total1 = 0;
    this._anexoDQuadro7Lista076Totals._total2 = 0;
    this._anexoDQuadro7Lista076Totals._total3 = 0;
    this._anexoDQuadro7Lista076Totals._total4 = 0;
    for (const item of this.anexoD.quadro07.campo076List) {
      const valueNaoDeduzido = isNumber(item.saldoNaoDeduzido) ? item.saldoNaoDeduzido : 0;
      const valueDotacao = isNumber(item.dotacaoDoPeriodo) ? item.dotacaoDoPeriodo : 0;
      const valueDeducao = isNumber(item.deducaoDoPeriodo) ? item.deducaoDoPeriodo : 0;
      const valueTransita = isNumber(item.saldoQueTransita) ? item.saldoQueTransita : 0;

      this._anexoDQuadro7Lista076Totals._total1 += valueNaoDeduzido;
      this._anexoDQuadro7Lista076Totals._total2 += valueDotacao;
      this._anexoDQuadro7Lista076Totals._total3 += valueDeducao;
      this._anexoDQuadro7Lista076Totals._total4 += valueTransita;
    }
    this.quadro7List76Totais = this.quadro7List76Totais.slice();

    this._anexoDQuadro7Lista076ATotals._total1 = 0;
    this._anexoDQuadro7Lista076ATotals._total2 = 0;
    this._anexoDQuadro7Lista076ATotals._total3 = 0;
    this._anexoDQuadro7Lista076ATotals._total4 = 0;
    for (const item of this.anexoD.quadro07.campo074AList) {
      const valueNaoDeduzido = isNumber(item.saldoNaoDeduzido) ? item.saldoNaoDeduzido : 0;
      const valueDotacao = isNumber(item.dotacaoDoPeriodo) ? item.dotacaoDoPeriodo : 0;
      const valueDeducao = isNumber(item.deducaoUtilizada) ? item.deducaoUtilizada : 0;
      const valueTransita = isNumber(item.saldoQueTransita) ? item.saldoQueTransita : 0;

      this._anexoDQuadro7Lista076ATotals._total1 += valueNaoDeduzido;
      this._anexoDQuadro7Lista076ATotals._total2 += valueDotacao;
      this._anexoDQuadro7Lista076ATotals._total3 += valueDeducao;
      this._anexoDQuadro7Lista076ATotals._total4 += valueTransita;
    }
    this.quadro7List76ATotais = this.quadro7List76ATotais.slice();

    this._anexoDQuadro7Lista079Totals._total1 = 0;
    this._anexoDQuadro7Lista079Totals._total2 = 0;
    this._anexoDQuadro7Lista079Totals._total3 = 0;
    this._anexoDQuadro7Lista079Totals._total4 = 0;
    this._anexoDQuadro7Lista079Totals._total5 = 0;
    this._anexoDQuadro7Lista079Totals._total6 = 0;
    for (const item of this.anexoD.quadro07.campo079List) {
      const valueNaoDeduzido = isNumber(item.saldoNaoDeduzido) ? item.saldoNaoDeduzido : 0;
      const valueIncentivo = isNumber(item.valorIncentivo) ? item.valorIncentivo : 0;
      const valueDeducao = isNumber(item.deducaoDoPeriodo) ? item.deducaoDoPeriodo : 0;
      const valueTransita = isNumber(item.saldoQueTransita) ? item.saldoQueTransita : 0;
      const valueAReembolsar = isNumber(item.valorAReembolsar) ? item.valorAReembolsar : 0;
      const valueARepor = isNumber(item.valorARepor) ? item.valorARepor : 0;

      this._anexoDQuadro7Lista079Totals._total1 += valueNaoDeduzido;
      this._anexoDQuadro7Lista079Totals._total2 += valueIncentivo;
      this._anexoDQuadro7Lista079Totals._total3 += valueDeducao;
      this._anexoDQuadro7Lista079Totals._total4 += valueTransita;
      this._anexoDQuadro7Lista079Totals._total5 += valueAReembolsar;
      this._anexoDQuadro7Lista079Totals._total6 += valueARepor;
    }
    this.quadro7List79Totais = this.quadro7List79Totais.slice();

    this._anexoDQuadro7Lista0710Totals._total1 = 0;
    this._anexoDQuadro7Lista0710Totals._total2 = 0;
    this._anexoDQuadro7Lista0710Totals._total3 = 0;
    this._anexoDQuadro7Lista0710Totals._total4 = 0;
    for (const item of this.anexoD.quadro07.campo0710List) {
      const valueNaoDeduzido = isNumber(item.saldoNaoDeduzido) ? item.saldoNaoDeduzido : 0;
      const montanteBeneficio = isNumber(item.montanteBeneficio) ? item.montanteBeneficio : 0;
      const valueDeducao = isNumber(item.deducaoDoPeriodo) ? item.deducaoDoPeriodo : 0;
      const valueTransita = isNumber(item.saldoQueTransita) ? item.saldoQueTransita : 0;

      this._anexoDQuadro7Lista0710Totals._total1 += valueNaoDeduzido;
      this._anexoDQuadro7Lista0710Totals._total2 += montanteBeneficio;
      this._anexoDQuadro7Lista0710Totals._total3 += valueDeducao;
      this._anexoDQuadro7Lista0710Totals._total4 += valueTransita;
    }
    this.quadro7List710Totais = this.quadro7List710Totais.slice();

    this._anexoDQuadro7Lista0710ATotals._total1 = 0;
    this._anexoDQuadro7Lista0710ATotals._total2 = 0;
    this._anexoDQuadro7Lista0710ATotals._total3 = 0;
    this._anexoDQuadro7Lista0710ATotals._total4 = 0;
    for (const item of this.anexoD.quadro07.campo0710AList) {
      const valueNaoDeduzido = isNumber(item.saldoNaoDeduzido) ? item.saldoNaoDeduzido : 0;
      const montanteBeneficio = isNumber(item.montanteBeneficio) ? item.montanteBeneficio : 0;
      const valueDeducao = isNumber(item.deducaoDoPeriodo) ? item.deducaoDoPeriodo : 0;
      const valueTransita = isNumber(item.saldoQueTransita) ? item.saldoQueTransita : 0;

      this._anexoDQuadro7Lista0710ATotals._total1 += valueNaoDeduzido;
      this._anexoDQuadro7Lista0710ATotals._total2 += montanteBeneficio;
      this._anexoDQuadro7Lista0710ATotals._total3 += valueDeducao;
      this._anexoDQuadro7Lista0710ATotals._total4 += valueTransita;
    }
    this.quadro7List710Totais = this.quadro7List710Totais.slice();

    this._anexoDQuadro7Lista077Totals._total1 = 0;
    this._anexoDQuadro7Lista077Totals._total2 = 0;
    for (const item of this.anexoD.quadro07.campo077List) {
      const valueSaldo = isNumber(item.saldo) ? item.saldo : 0;
      const valueDotacao = isNumber(item.dotacao) ? item.dotacao : 0;

      this._anexoDQuadro7Lista077Totals._total1 += valueSaldo;
      this._anexoDQuadro7Lista077Totals._total2 += valueDotacao;
    }
    this.quadro7List77Totais = this.quadro7List77Totais.slice();
  }

  private _generateDataGridToolbar(callback: () => void): TDevExpressDataGridToolbar {
    return {
      items: [
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'add',
            hint: this._translateService.instant('modelo22.comum.addline'),
            onClick: () => {
              callback();
            }
          } satisfies ButtonOptions
        },
        'exportButton',
        'columnChooserButton'
      ]
    };
  }

  private _convertToDefinition(arrayId: EArrayIds): void {
    if (arrayId === EArrayIds.Tabela907) {
      nifs907ListId = 1;
      this.nifs907List = this.anexoD.quadro09.campo907List.map((nif: number) => {
        return {
          id: nifs907ListId++,
          nif: nif
        };
      });
    } else if (arrayId === EArrayIds.Tabela03) {
      nifs03ListId = 1;
      this.nifs03List = this.anexoD.quadro11A.campo3List.map((nif: number) => {
        return {
          id: nifs03ListId++,
          nif: nif
        };
      });
    } else if (arrayId === EArrayIds.Tabela604) {
      cod604ListId = 1;
      this.cods604List = this.anexoD.quadro06.campo604List.map((item: number) => {
        return {
          id: cod604ListId++,
          cod: item
        };
      });
    } else if (arrayId === EArrayIds.Tabela605) {
      cod605ListId = 1;
      this.cods605List = this.anexoD.quadro06.campo605List.map((item: number) => {
        return {
          id: cod605ListId++,
          cod: item
        };
      });
    }
  }

  private _convertFromDefinition(arrayId: EArrayIds): void {
    if (arrayId === EArrayIds.Tabela907) {
      this.anexoD.quadro09.campo907List = this.nifs907List.map((item: ICampo907NifList) => {
        return item.nif;
      });
    } else if (arrayId === EArrayIds.Tabela03) {
      this.anexoD.quadro11A.campo3List = this.nifs03List.map((item: ICampo03NifList) => {
        return item.nif;
      });
    } else if (arrayId === EArrayIds.Tabela604) {
      this.anexoD.quadro06.campo604List = this.cods604List.map((item: ICampo604List) => {
        return item.cod;
      });
    } else if (arrayId === EArrayIds.Tabela605) {
      this.anexoD.quadro06.campo605List = this.cods605List.map((item: ICampo605List) => {
        return item.cod;
      });
    }
  }

  private _clearCGDatesDefault(): void {
    if (
      (isDefinedNotNull(this.anexoD.quadro06.campo601) && moment(this.anexoD.quadro06.campo601).isSameOrBefore(minDateCG(), 'date')) ||
      moment(this.anexoD.quadro06.campo601).isSameOrBefore(minDateCGD(), 'date') ||
      moment(this.anexoD.quadro06.campo601).isSameOrAfter(maxDateCG(), 'date') ||
      moment(this.anexoD.quadro06.campo601).isSameOrAfter(maxDateCGD(), 'date')
    ) {
      this.anexoD.quadro06.campo601 = undefined;
    }
  }
}
