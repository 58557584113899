<div class="ativosmudarcontaativo-modal">
  <pl-form>
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'ativosmudarcontaativo.title.mudarcontaativo'"></h5>
    </div>

    <div class="modal-body">
      <pl-group>
        <edit>
          <label [translate]="'ativosmudarcontaativo.fields.ncontaatual'" class="pe-2"></label>
          <span>{{ ativo.aquisicao.nContaDoAtivo }} - {{ ativo.aquisicao.nomeContaDoAtivo }}</span>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'ativosmudarcontaativo.fields.ncontanovo'"></label>
        <edit>
          <entity-autocomplete
            entity="pocs"
            attrName="nContaDoAtivo"
            [selectedKey]="nContaDoAtivo"
            (selectedKeyChange)="nContaDoAtivo = $event"
            [properties]="{validators: {required: {value: true}}}"
            [fieldsMap]="{nConta: 'nContaDoAtivo'}"
            [filter]="filter"
            [output]="pocsOutput"
            plAutoFocus>
          </entity-autocomplete>
        </edit>
      </pl-group>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button klass="btn btn-success action-dismiss" [onClick]="close" data-focus> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span> </pl-button>

      <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
    </div>
  </pl-form>
</div>
