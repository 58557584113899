<div class="mapa-pagamentos">
  <ng-container>
    <pl-alert type="warning" *ngIf="showAlert"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;<span [translate]="'mapapagam.texts.alertMessage'"></span></pl-alert>
  </ng-container>

  <pl-tabs [(activeId)]="activeTab" [destroyOnHide]="false" [theme]="'style3'">
    <pl-tab [id]="tabIdPorEmpresa" caption="gto.tabs.title.porEmpresa">
      <div *plTabContent>
        <pl-alert type="info" [closeable]="false">
          <div><i class="fa fa-fw fa-info-circle" aria-hidden="true"></i><span [translate]="'mapapagam.texts.disponivelBrevemente'"></span></div>
        </pl-alert>
      </div>
    </pl-tab>

    <pl-tab [id]="tabIdMultiEmpresa" caption="gto.tabs.title.multiEmpresa">
      <div *plTabContent>
        <cg-card-panel #cardPanel="cgCardPanel">
          <div *cgCardPanelContent>
            <pl-form class="px-2 py-1">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-xl-3">
                  <pl-group>
                    <label [translate]="'mapapagam.fields.regimeIva'"></label>
                    <edit>
                      <pl-edit type="select" [(model)]="filters.regimeIva" [properties]="{select: {list: regimeIvaSource}, disallowClear: true}"></pl-edit>
                    </edit>
                  </pl-group>
                </div>
                <div class="col-sm-12 col-md-6 col-xl-3">
                  <pl-group>
                    <label [translate]="'mapapagam.fields.periodoIva'"></label>
                    <edit>
                      <pl-edit type="select" [(model)]="filters.periodoIva" [properties]="{select: {list: periodoIvaSource}, disallowClear: true}"></pl-edit>
                    </edit>
                  </pl-group>
                </div>
                <div class="col-sm-12 col-xl-12 align-self-end">
                  <pl-group>
                    <edit>
                      <pl-button klass="btn-sm btn-primary action-register-value" [onClick]="fnPesquisar"
                        ><i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span>
                      </pl-button>
                    </edit>
                  </pl-group>
                </div>
              </div>
            </pl-form>
          </div>
        </cg-card-panel>

        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinition"
          cgDxDataGridInstanceName="mapaPagamentosGridInstanceV2"
          (onContentReady)="onContentReady()"
          (onInitialized)="onInitialized($event)"
          [dataSource]="dataGridDefinition.dataSource"
          (onContextMenuPreparing)="addContextMenuItems($event)">
          <div *dxTemplate="let cell of 'toolbarTemplateFiltros'">
            <button id="toolbarTemplateFiltrosBtn" type="button" class="cg-dx-toolbar-btn" (click)="fnToggleFilterPanel()">
              <i class="fa fa-fw fa-filter" aria-hidden="true"></i>
            </button>
          </div>

          <div *dxTemplate="let item of 'pagamentoTemplate'">
            <div class="mapa-pagamento-cell" *ngIf="item.value && item.value.havePayments">
              <div class="mapa-pagamento-cell-center">
                <div>
                  <ng-container *ngIf="!item.value.pagamento.pago && item.value.montanteTotal > 0">
                    <pl-edit type="checkbox" [model]="item.value.selected" (modelChange)="setPaymentSelected(item.value, item.data, $event)" [properties]="{label: ''}"></pl-edit>
                  </ng-container>
                  <ng-container *ngIf="item.value.pagamento.pago">
                    <pl-tooltip [config]="{text: 'mapapagam.tooltips.pago', container: 'body'}">
                      <div *plTooltipContent class="mapa-pagamento-cell-enviado">
                        <i class="fa fa-fw fa-check text-success" aria-hidden="true"></i>
                      </div>
                    </pl-tooltip>
                  </ng-container>
                </div>
                <div
                  class="mapa-pagamento-cell-valor"
                  [ngClass]="{'mapa-pagamento-cell-valor-pago': item.value.pagamento.pago, 'mapa-pagamento-cell-valor-pago-unknown': item.value.pagamento.pagoUnknown}">
                  <span *ngIf="item.value.pagamento.impostoEnum === impostoManualEnum && item.value.montanteTotal === 0">-</span>
                  <span *ngIf="item.value.montanteTotal !== 0">{{ item.value.montanteTotal | cgcCurrency }}</span>
                  <sup *ngIf="item.value.pagamento.pagoUnknown" style="margin-left: 3px">
                    <pl-tooltip [config]="{text: 'mapapagam.tooltips.naoEPossivelObterEstadoPagamento', container: 'body'}">
                      <i *plTooltipContent class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                    </pl-tooltip>
                  </sup>
                </div>
              </div>
              <div class="mapa-pagamento-cell-right">
                <ng-container *ngIf="item.value.pagamento.lido">
                  <pl-tooltip [config]="{text: getLidoTooltip(item.value), container: 'body'}">
                    <div *plTooltipContent class="mapa-pagamento-cell-lido text-success"><i class="fa fa-fw fa-envelope-open-o" aria-hidden="true"></i></div>
                  </pl-tooltip>
                </ng-container>

                <pl-tooltip [config]="{text: getEnviadoTooltip(item.value), container: 'body'}" *ngIf="item.value.montanteTotal > 0 && !item.value.pagamento.pago && !item.value.pagamento.lido">
                  <div *plTooltipContent class="mapa-pagamento-cell-enviado">
                    <i [ngClass]="{'text-danger': !item.value.pagamento.enviado, 'text-success': item.value.pagamento.enviado}" class="fa fa-fw fa-envelope-o" aria-hidden="true"></i>
                  </div>
                </pl-tooltip>

                <div class="mapa-pagamento-cell-menu">
                  <div tabindex="-1" ngbDropdown container="body">
                    <span ngbDropdownToggle><i class="fa fa-bars" aria-hidden="true"></i></span>
                    <div class="dropdown-menu mapa-pagamento-cell-menu-dropdown-menu" ngbDropdownMenu>
                      <button type="button" ngbDropdownItem *ngIf="item.value.pagamento.impostoEnum === impostoManualEnum" (click)="editarPagamentoManual(item.value, item.data)">
                        <i class="fa fa-fw fa-pencil" aria-hidden="true"></i>&nbsp;<span [translate]="'mapapagam.texts.editarPagamentoManual'"></span>
                      </button>
                      <button
                        type="button"
                        ngbDropdownItem
                        *ngIf="item.value.pagamento.impostoEnum === impostoManualEnum && !item.value.pagamento.pago && item.value.montanteTotal > 0"
                        (click)="marcarComoPago(item.value, item.data)">
                        <i class="fa fa-fw fa-check-square-o" aria-hidden="true"></i>&nbsp;<span [translate]="'mapapagam.texts.marcarComoPago'"></span>
                      </button>
                      <button
                        type="button"
                        ngbDropdownItem
                        *ngIf="item.value.pagamento.entityDocument || item.value.pagamento.haveDocWithAttachment"
                        (click)="verATDocumentoPagamento(item.value, item.data)">
                        <i class="fa fa-fw fa-file-text-o" aria-hidden="true"></i>&nbsp;<span [translate]="'mapapagam.texts.verDocumento'"></span>
                      </button>
                      <button type="button" ngbDropdownItem (click)="enviarPagamento(item.value, item.data)" *ngIf="!item.value.pagamento.pago && item.value.montanteTotal > 0">
                        <i class="fa fa-fw fa-paper-plane-o" aria-hidden="true"></i>&nbsp;<span
                          [translate]="item.value.enviado ? 'mapapagam.texts.reEnviarPagamento' : 'mapapagam.texts.enviarPagamento'"></span>
                      </button>
                      <button type="button" ngbDropdownItem (click)="verDetalhesPagamento(item.value)">
                        <i class="fa fa-fw fa-info-circle" aria-hidden="true"></i>&nbsp;<span [translate]="'mapapagam.texts.verDetalhesPagamento'"></span>
                      </button>
                      <ng-container *ngIf="item.value.pagamento.impostoEnum === impostoManualEnum">
                        <div class="mapa-pagamento-dropdown-separator"></div>
                        <button type="button" ngbDropdownItem (click)="apagarPagamento(item.value, item.data)">
                          <i class="fa fa-fw fa-trash-o" aria-hidden="true"></i>&nbsp;<span [translate]="'mapapagam.texts.apagarPagamento'"></span>
                        </button>
                      </ng-container>

                      <ng-container *ngIf="!item.value.pagamento.pago && item.value.montanteTotal > 0">
                        <div class="mapa-pagamento-dropdown-separator"></div>
                        <div class="mapa-pagamento-cell-menu-dados-pagam">
                          <div *ngFor="let doc of item.value.pagamento.documentos">
                            <ng-container *ngIf="!doc.pago">
                              <div><b>Ref</b>: {{ doc.refPag }}</div>
                              <div><b>Data Limite</b>: {{ doc.dataLimitePag | cgcDate }}</div>
                              <div><b>Montante</b>: {{ doc.montante | cgcCurrency }}</div>
                            </ng-container>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *dxTemplate="let item of 'actions'">
            <pl-tooltip [config]="{text: 'mapapagam.texts.viewDocumentoUnico', container: 'body'}" *ngIf="item.data.havePagamentos">
              <button type="button" *plTooltipContent class="btn btn-xs btn-outline-warning me-1" [disabled]="item.data.totalPagamentosSel <= 0" (click)="previewDocUnico(item.data)">
                <i class="fa fa-fw fa-file-pdf-o" aria-hidden="true"></i>
              </button>
            </pl-tooltip>
          </div>

          <div *dxTemplate="let item of 'statusTmpl'">
            <div class="mapa-pagamentos-entity-span-wrapper">
              <pl-tooltip [config]="{text: item.data.stateTooltipAT, container: 'body'}">
                <span *plTooltipContent [ngClass]="item.data.stateCssClassAT" class="mapa-pagamentos-entity-span">AT</span>
              </pl-tooltip>
              <pl-tooltip [config]="{text: item.data.stateTooltipSSD, container: 'body'}">
                <span *plTooltipContent [ngClass]="item.data.stateCssClassSSD" class="mapa-pagamentos-entity-span">SSD</span>
              </pl-tooltip>
              <div tabindex="-1" ngbDropdown container="body">
                <span class="mapa-pagamentos-entity-dropdown" ngbDropdownToggle><i class="fa fa-bars" aria-hidden="true"></i></span>
                <div class="dropdown-menu mapa-pagamento-cell-menu-dropdown-menu" ngbDropdownMenu>
                  <button type="button" ngbDropdownItem (click)="configCredentials(mapaPagamEntity.AT, item.data)">
                    <span [translate]="'mapapagam.texts.portalAT'"></span>
                  </button>
                  <button type="button" ngbDropdownItem (click)="configCredentials(mapaPagamEntity.SSD, item.data)">
                    <span [translate]="'mapapagam.texts.portalSSD'"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div *dxTemplate="let item of 'headerSelAllCellTemplate'">
            <pl-edit type="checkbox" [model]="headerSelectAll" (modelChange)="headerSelectAllChange($event)" [properties]="{label: ''}"></pl-edit>
          </div>

          <div *dxTemplate="let item of 'headerSelColCellTemplate'">
            <div class="d-flex mp-headerSelColCellTemplate">
              <pl-edit
                type="checkbox"
                [model]="columnCheckboxesModel[item.column.dataField]"
                (modelChange)="pagamentoColCheckClick($event, item)"
                (click)="$event.stopPropagation()"
                [properties]="{label: ''}"></pl-edit>
              <span class="mp-headerSelColCellTemplate-caption" [translate]="item.column.caption"></span>
            </div>
          </div>

          <div *dxTemplate="let item of 'selPagamentoCellTemplate'">
            <ng-container *ngIf="item.data.havePagamentos">
              <pl-edit
                class="mapa-pagamento-select-all-payments"
                type="checkbox"
                [model]="item.data.selectRowCheck"
                (modelChange)="pagamentoRowCheckClick($event, item)"
                [properties]="{label: ''}"></pl-edit>
            </ng-container>
          </div>
        </dx-data-grid>

        <dx-popup
          [width]="430"
          [height]="292"
          [showTitle]="true"
          contentTemplate="popup-content"
          title="Filtros"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [shading]="false"
          [position]="{my: 'right top', at: 'right top', of: '#toolbarTemplateFiltrosBtn'}"
          [(visible)]="showFilterPanel">
          <div *dxTemplate="let data of 'popup-content'">
            <div class="d-flex align-items-center" style="gap: 15px; flex-wrap: wrap">
              <div>
                <label [translate]="'mapapagam.fields.responsavel'"></label>
                <edit>
                  <pl-autocomplete [source]="responsaveis" [(model)]="filters.responsavel" [rowTemplate]="usersTemplate" [output]="usersTemplate"></pl-autocomplete>
                </edit>
              </div>
            </div>
            <hr />
            <div class="d-flex" style="justify-content: flex-end; gap: 15px">
              <button type="button" class="btn btn-sm btn-light" (click)="gridFiltersReset()">
                <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'mapapagam.texts.limparFiltros'"></span>
              </button>
              <button type="button" class="btn btn-sm btn-success" (click)="gridFiltersApply()"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'mapapagam.texts.aplicar'"></span></button>
            </div>
          </div>
        </dx-popup>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
