<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="'conciliacaoBancaria.printModalTitle'"></h5>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <pl-group>
        <label [translate]="'conciliacaoBancaria.listagem'"></label>
        <edit>
          <pl-edit type="reports_conciliacao_bancaria" [model]="reportModel" (modelChange)="onReportChange($event)" [properties]="{entity: {outputKey: 'name', outputDescription: 'title'}}"></pl-edit>
        </edit>
      </pl-group>
    </div>
    <div class="col-md-6">
      <pl-group>
        <label [translate]="'conciliacaoBancaria.deNConta'"></label>
        <edit>
          <entity-autocomplete
            entity="pocs"
            [model]="model"
            [(selectedKey)]="model.deNConta"
            (evtSelectedDescriptionChanged)="(model.nomeDeNConta)"
            [filter]="pocsFilter"
            [showFilter]="false"
            [fieldsMap]="{nConta: 'deNConta', nome: 'nomeDeNConta'}">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'conciliacaoBancaria.dePeriodo'"></label>
        <edit>
          <entity-autocomplete entity="periodos" [model]="model" [(selectedKey)]="model.dePeriodo" [fieldsMap]="{periodo: 'dePeriodo'}" [output]="'key'"> </entity-autocomplete>
        </edit>
      </pl-group>
    </div>
    <div class="col-md-6">
      <pl-group>
        <label [translate]="'conciliacaoBancaria.ateNConta'"></label>
        <edit>
          <entity-autocomplete
            entity="pocs"
            [model]="model"
            [(selectedKey)]="model.ateNConta"
            (evtSelectedDescriptionChanged)="(model.nomeAteNConta)"
            [filter]="pocsFilter"
            [showFilter]="false"
            [fieldsMap]="{nConta: 'deNConta', nome: 'nomeAteNConta'}">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'conciliacaoBancaria.atePeriodo'"></label>
        <edit>
          <entity-autocomplete entity="periodos" [model]="model" [(selectedKey)]="model.atePeriodo" [fieldsMap]="{periodo: 'atePeriodo'}" [output]="'key'"> </entity-autocomplete>
        </edit>
      </pl-group>
    </div>
  </div>

  <cg-pdf-viewer [pdfSrc]="pdfUrl" [toolbarInstanceId]="toolbarInstanceId"></cg-pdf-viewer>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
