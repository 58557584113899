import {Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {StateService, Transition} from '@uirouter/core';
import {isArray, isString, isUndefined, Logger, skipIf} from 'pl-comps-angular';
import {AccountComponent} from '../account.state.component';
import {AuthService} from '../../../services/auth/auth.service';
import {CGExceptionService} from '../../../components/exceptions/exceptions.service';
import {CGStateService} from '../../../components/state/cg.state.service';
import {ConfigService} from '../../../services/config/config.service';
import {EStatusCode, FORM_INVALID_CANNOT_SUBMIT} from '../../../../config/constants';
import {ICGExceptionError} from '../../../components/exceptions/exceptions.service.interface';
import {IJsonRecaptchaConfig} from '../../../interfaces/jsonConfigValue.interface';
import {IJsonUserLoginRequest, TRecaptchaTokenType, TUserSession} from '../../../services/account/jsonUserApi.interface';
import {ILoginStateParams} from './login.state.interface';
import {RecaptchaService} from '../../../services/recaptcha/recaptcha.service';
import {REJECTED_TERMS_MODAL} from '../../../components/cg/modal/terms/terms.modal.interface';
import {STATE_NAME_EMPRESA_BLOQUEADA} from '../empresabloqueada/empresabloqueada.state.interface';

@Component({
  selector: 'login',
  templateUrl: './login.state.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  @Input() public recaptchaConfig: IJsonRecaptchaConfig;

  public readonly form: UntypedFormGroup;
  public recaptchaActive: boolean;

  private readonly _subscriptionSession: Subscription;
  private _session: TUserSession;

  constructor(
    private readonly _accountComponent: AccountComponent,
    private readonly _logger: Logger,
    private readonly _transition: Transition,
    private readonly _stateService: StateService,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _cgStateService: CGStateService,
    private readonly _authService: AuthService,
    private readonly _configService: ConfigService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _recaptchaService: RecaptchaService
  ) {
    this.recaptchaActive = true;
    this.form = this._formBuilder.group({
      username: this._formBuilder.control('', [Validators.required]),
      password: this._formBuilder.control('', [Validators.required])
    });
    this._authService.logout();
    this._subscriptionSession = this._authService
      .identityAsObservable()
      .pipe(skipIf(isUndefined))
      .pipe(distinctUntilChanged())
      .subscribe((session: TUserSession) => {
        this._setSession(session);
      });
  }

  public ngOnInit(): void {
    this.recaptchaActive = this.recaptchaConfig.active;
  }

  public ngOnDestroy(): void {
    this._subscriptionSession.unsubscribe();
  }

  public readonly fnLogin: () => Promise<void> = () => this._login();

  private _login(): Promise<void> {
    this._setSession(undefined);
    if (!this.form.valid) {
      this._accountComponent.setHasError(true);
      const model: IJsonUserLoginRequest = this.form.value;
      const errorMessage: string = !model.username || !model.password ? 'login.messages.error.invalidCredentials' : 'account.messages.error.invalidRecaptcha';
      this._setErrorMessage(errorMessage);
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }
    this._accountComponent.setHasError(false);
    return this._recaptchaAction()
      .then((recaptchaToken: string) => this._authLogin('v3', recaptchaToken))
      .catch((error: string | HttpErrorResponse) => this._handleErrorResponse(error));
  }

  private _authLogin(recaptchaType: TRecaptchaTokenType, recaptchaToken: string): Promise<void> {
    const model: IJsonUserLoginRequest = this.form.value;
    return this._authService
      .login(model.username, model.password, recaptchaType, recaptchaToken, {reportExceptions: false})
      .then(() => this._loginSuccess())
      .catch((error: string | HttpErrorResponse) => {
        this._setSession(undefined);
        return this._handleErrorResponse(error);
      });
  }

  private _loginSuccess(): Promise<void> {
    const params: ILoginStateParams = <ILoginStateParams>this._transition.params();
    const {redirectto, redirectparams} = params;
    if (!params || !redirectto || redirectto === this._transition.to().name) {
      return this._cgStateService.goHome().then(() => undefined);
    }
    return this._stateService.go(redirectto, redirectparams).catch((reason: unknown) => {
      this._logger.error(reason);
      return this._cgStateService.goHome().then(() => undefined);
    });
  }

  private async _handleErrorResponse(response: string | HttpErrorResponse): Promise<void> {
    this._logger.error(response);
    let message: string;
    if (response instanceof HttpErrorResponse) {
      const exception: ICGExceptionError = this._cgExceptionService.get(response);
      switch (response.status) {
        case EStatusCode.NoResponse:
          message = 'error.server.unavailable';
          break;
        case EStatusCode.Unauthorized:
          message = 'account.messages.error.notAuthorized';
          if (this._session && isArray(this._session.roles) && !this._session.roles.length) {
            this._configService.lockEmpresa('empresabloqueada.reasons.companyEmptyRoles');
            return this._stateService.go(STATE_NAME_EMPRESA_BLOQUEADA).then(() => undefined);
          }
          break;
        case EStatusCode.InternalServerError:
          if (exception.class === 'ERecaptchaException' || exception.class === 'ERecaptchaInvalidTokenTypeException' || exception.class === 'ERecaptchaUnsafeHostnameException') {
            message = exception.message;
          } else if (exception.class === 'ERecaptchaUnsafeScoreException') {
            const recaptchaToken: string = await this._recaptchaService.validateRecaptchaV2();
            return this._authLogin('v2', recaptchaToken).catch((reason: unknown) => {
              this._logger.error(reason);
            });
          }
          break;
        case EStatusCode.BadGateway:
          message = 'error.server.unavailable';
          break;
        case EStatusCode.ServiceUnavailable:
          message = 'error.server.serviceUnavailable';
          break;
      }
      if (!message) {
        message = 'login.messages.error.authentication';
        if (exception.message) {
          message = exception.message;
        }
      }
    } else if (isString(response) && response === REJECTED_TERMS_MODAL) {
      message = 'terms.errorRequired';
    }
    this._setErrorMessage(message);
    return Promise.resolve();
  }

  private _setErrorMessage(value: string): void {
    this._accountComponent.errorMessage = value;
    this._accountComponent.setHasError(Boolean(this._accountComponent.errorMessage));
  }

  private _setSession(session: TUserSession): void {
    this._session = session;
  }

  private _recaptchaAction(): Promise<string> {
    if (!this.recaptchaConfig.active) {
      return Promise.resolve('');
    }
    return this._recaptchaService.actions.login();
  }
}
