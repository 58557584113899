<div class="gestao-dgemps-header">
  <div class="gestao-dgemps-header-group gestao-dgemps-header-view" *ngIf="showView !== false">
    <div class="gestao-dgemps-header-label"><span [translate]="'gestaodgemps.text.view'"></span>:</div>

    <div class="btn-group btn-group-sm" role="group">
      <button
        type="button"
        class="btn"
        name="view-year"
        [class.btn-light]="view !== views.Year"
        [class.btn-primary]="view === views.Year"
        [translate]="'gestaodgemps.text.yearly'"
        (click)="setView(views.Year)"></button>

      <button
        type="button"
        class="btn"
        name="view-month"
        [class.btn-light]="view !== views.Month"
        [class.btn-primary]="view === views.Month"
        [translate]="'gestaodgemps.text.monthly'"
        (click)="setView(views.Month)"></button>
    </div>
  </div>

  <div class="gestao-dgemps-header-group gestao-dgemps-header-servicos" *ngIf="showServicos !== false && servicos.length > 1">
    <div class="gestao-dgemps-header-label"><span [translate]="'gestaodgemps.text.servico'"></span>:</div>
    <div class="gestao-dgemps-header-servico">
      <pl-edit-select attrName="servico" [source]="servicos" [model]="servico" (modelChange)="setServico($event)" [properties]="propertiesSelectServico"> </pl-edit-select>
    </div>
  </div>

  <div class="gestao-dgemps-header-nav">
    <div class="gestao-dgemps-header-group gestao-dgemps-header-pending" *ngIf="showPending">
      <div class="gestao-dgemps-header-label"><span [translate]="'gestaodgemps.text.pendentes'"></span>:</div>

      <div class="btn-group btn-group-sm" role="group">
        <button type="button" class="btn btn-primary" name="pendentes-previous" [disabled]="disabledPendingPrevious" (click)="pendingPrevious()">
          <i class="fa fa-fw fa-angle-left"></i>
        </button>

        <button type="button" class="btn btn-primary" name="pendentes-next" [disabled]="disabledPendingNext" (click)="pendingNext()">
          <i class="fa fa-fw fa-angle-right"></i>
        </button>
      </div>
    </div>

    <div class="gestao-dgemps-header-group gestao-dgemps-header-actions btn-group btn-group-sm">
      <button
        type="button"
        class="btn btn-primary gestao-dgemps-header-action-previous"
        [translate]="'gestaodgemps.header.previous'"
        [attr.title]="titlePrevious | translate"
        (click)="viewDatePrevious()"></button>

      <button type="button" class="btn btn-light gestao-dgemps-header-action-today" [translate]="'gestaodgemps.header.today'" (click)="viewDateToday()"></button>

      <button type="button" class="btn btn-primary gestao-dgemps-header-action-next" [translate]="'gestaodgemps.header.next'" [attr.title]="titleNext | translate" (click)="viewDateNext()"></button>
    </div>
  </div>

  <div class="gestao-dgemps-header-loading" *ngIf="loading">
    <pl-spinner type="round"></pl-spinner>
  </div>
</div>
