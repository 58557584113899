import {merge} from 'lodash-es';
import type {Subscription} from 'rxjs';
import {
  ApplicationRef,
  Component,
  ComponentRef,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  SimpleChanges,
  SkipSelf,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {AbstractControl, FormGroupDirective} from '@angular/forms';
import {generateName, generateUniqueID, isEmpty, isUndefined} from '../../common/utilities/utilities';
import type {IPlEdit, IPlEditBaseComponent, IPlEditBaseComponentOptions, IPlEditComponent} from './edit.component.interface';
import {PlEditGroupToken} from '../components/group/edit.group.token';
import {PlEditInputGroupDirective} from '../generic/inputgroup/edit.input.group.directive';
import {PlEditRegistryService} from '../edit.registry.service';
import {PlEditToken} from './edit.token';
import {PlInlineEditToken} from '../components/inline/inline.edit.token';

@Component({
  selector: 'pl-edit',
  template: '<ng-template></ng-template>',
  providers: [{provide: PlEditToken, useExisting: PlEditComponent}]
})
export class PlEditComponent extends PlEditToken<any> implements OnInit, OnChanges, OnDestroy, IPlEditComponent<any> {
  @Input() public model: any;
  @Input() public type: string;
  @Input() public attrName: string;
  @Input() public inputClass: string;
  @Input() public properties: IPlEditBaseComponentOptions<any> | any;
  @Output() public readonly modelChange: EventEmitter<any>;
  @ViewChild(TemplateRef, {static: true, read: ViewContainerRef}) public readonly anchor: ViewContainerRef;
  @ContentChildren(PlEditInputGroupDirective, {descendants: false}) public readonly itemGroups: QueryList<PlEditInputGroupDirective>;

  public options: IPlEditBaseComponentOptions<any>;

  private _componentRef: ComponentRef<IPlEditBaseComponent<any>>;
  private _editComponent: IPlEditBaseComponent<any>;
  private _ngFormInstance: FormGroupDirective;
  private _subscriptionValueChanges: Subscription;

  constructor(
    private readonly _applicationRef: ApplicationRef,
    private readonly _injector: Injector,
    @SkipSelf() @Optional() private readonly _plEdit: PlEditToken<any>,
    @Optional() private readonly _plGroup: PlEditGroupToken,
    @Optional() private readonly _plInlineEdit: PlInlineEditToken<any>,
    private readonly _plEditRegistryService: PlEditRegistryService
  ) {
    super();
    this.modelChange = new EventEmitter<any>();
  }

  public ngOnInit(): void {
    if (isEmpty(this.attrName)) {
      this.attrName = generateUniqueID('plEdit');
    }
    /* Nested ``plEdit's`` SHOULD NOT mess with parent group or inline-edit as this may
     * cause unexpected issues concerning form validators */
    const initialOptions: Partial<IPlEditBaseComponentOptions<any>> = {};
    if (!this._plEdit) {
      if (this._plInlineEdit) {
        this._plInlineEdit.addComponent(this);
        if (this._plInlineEdit.options) {
          merge(initialOptions, this._plInlineEdit.options);
        }
      } else if (this._plGroup) {
        this._plGroup.addEdit(this);
        if (this._plGroup.options) {
          merge(initialOptions, this._plGroup.options);
        }
      }
    }
    this.options = merge({}, initialOptions, this.options, this.properties);
    this._renderTemplate();
  }

  public ngOnChanges({type, model, properties}: SimpleChanges): void {
    if (properties && !properties.isFirstChange()) {
      this.updateComponent(properties.currentValue);
    }
    if (model && !model.isFirstChange()) {
      this._instance.updateValue(model.currentValue);
    }
    if (type && !type.isFirstChange()) {
      this._renderTemplate();
    }
  }

  public ngOnDestroy(): void {
    this._clearSubscriptionValueChanges();
    if (!this._plEdit) {
      if (this._plInlineEdit) {
        this._plInlineEdit.removeComponent();
      } else if (this._plGroup) {
        this._plGroup.removeEdit(this.attrName);
      }
    }
  }

  public render(value: any): void {
    this.model = value;
    this.modelChange.emit(this.model);
    this.valueChanged(this.model);
  }

  public addComponent(component: IPlEditBaseComponent<any>): void {
    if (!this._editComponent) {
      this._editComponent = component;
    }
  }

  public removeComponent(): void {
    if (this._editComponent) {
      this.removeControl(this.attrName);
      this._editComponent = undefined;
    }
  }

  public updateComponent(properties: IPlEditBaseComponentOptions<any>): void {
    if (properties && properties !== this.options) {
      this.options = merge({}, this.options, properties, this.properties);
      if (this._editComponent) {
        this._editComponent.updateComponent(this.options);
      }
    }
  }

  public setHasError(hasError: boolean): void {
    if (this._plGroup) {
      this._plGroup.setHasError(hasError);
    }
  }

  public addControl(name: string, control: AbstractControl): void {
    if (!this._plEdit) {
      if (this._plInlineEdit) {
        this._plInlineEdit.addControl(name, control);
      } else if (this._plGroup) {
        this._plGroup.addControl(name, control);
      }
    }
  }

  public removeControl(name: string): void {
    if (!this._plEdit) {
      if (this._plInlineEdit) {
        this._plInlineEdit.removeControl(name);
      } else if (this._plGroup) {
        this._plGroup.removeControl(name);
      }
    }
  }

  public valueChanged(value: unknown, fieldName: string = this.attrName): void {
    if (this._plInlineEdit) {
      this._plInlineEdit.valueChanged(value, fieldName);
    } else if (this._plGroup) {
      this._plGroup.valueChanged(value, fieldName);
    }
  }

  @HostBinding('attr.data-type')
  public get dataType(): string {
    return this.type;
  }

  @HostBinding('attr.data-attr-name')
  public get dataAttrName(): string {
    return this.attrName;
  }

  public get ngFormInstance(): FormGroupDirective {
    return this._ngFormInstance;
  }

  public set ngFormInstance(value: FormGroupDirective) {
    this._ngFormInstance = value;
    if (this._instance) {
      this._instance.ngFormInstance = this._ngFormInstance;
    }
  }

  private _renderTemplate(): void {
    const edit: IPlEdit = this._plEditRegistryService.get(this.type);
    if (!edit) {
      return;
    }
    this._clearSubscriptionValueChanges();
    this.anchor.clear();
    this._componentRef = this.anchor.createComponent<IPlEditBaseComponent<any>>(edit.component, {environmentInjector: this._applicationRef.injector, injector: this._injector});
    this._instance.model = this.model;
    this._updateAttrNameBinding(this.attrName);
    this._instance.inputClass = this.inputClass || '';
    this._instance.options = edit.defaultOptions ? merge({}, edit.defaultOptions, this.options) : this.options;
    this._instance.ngFormInstance = this._ngFormInstance;
    this._subscriptionValueChanges = this._instance.modelChange.subscribe((value: any) => {
      this.render(value);
    });
  }

  private _clearSubscriptionValueChanges(): void {
    if (this._subscriptionValueChanges) {
      this._subscriptionValueChanges.unsubscribe();
      this._subscriptionValueChanges = undefined;
    }
  }

  private _updateAttrNameBinding(attrName: string): void {
    if (isUndefined(attrName) || !attrName?.trim().length) {
      attrName = generateName(this.attrName, 'plEdit');
    }
    this.attrName = attrName;
    if (this._instance) {
      this._instance.attrName = this.attrName;
    }
  }

  private get _instance(): IPlEditBaseComponent<any> {
    return this._componentRef?.instance;
  }
}
