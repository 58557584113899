import {Component, Injector, Input} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {ICGTableOnSelect, IPlTableCallback, IPlTableDefinition, IPlTableOptions, PlAlertService, TCGTableSourceFunction} from 'pl-comps-angular';
import {CGExceptionService} from '../../../../../components/exceptions/exceptions.service';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ConciliacaoBancariaService} from '../../conciliacaoBancaria.module.service';
import {ICGExceptionError} from '../../../../../components/exceptions/exceptions.service.interface';
import {IConciliacaoBancariaExtPocItem} from '../../conciliacaoBancaria.module.interface';

@Component({
  selector: 'conciliacaoBancaria-add-docs-nao-conciliados-modal',
  templateUrl: './conciliacaoBancaria.addDocsNaoConciliados.modal.component.html'
})
export class ConciliacaoBancariaAddDocsNaoConciliadosModalComponent extends CGModalComponent<void> {
  @Input() public nConta: string;
  @Input() public periodo: string;

  public readonly tableDefinition: IPlTableDefinition;
  public readonly tableOptions: IPlTableOptions;
  public readonly tableCallback: IPlTableCallback;
  public btnAddCaption: string;

  private readonly _selectedItems: Set<string>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _conciliacaoBancariaService: ConciliacaoBancariaService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _plAlertService: PlAlertService,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this.tableDefinition = {
      fields: [
        {name: '_selected', caption: '', type: 'boolean'},
        {name: 'data', caption: 'conciliacaoBancaria.fields.data', type: 'date'},
        {name: 'descricao', caption: 'conciliacaoBancaria.fields.descricao'},
        {name: 'nDoc', caption: 'conciliacaoBancaria.fields.ndoc'},
        {name: 'nDocExterno', caption: 'conciliacaoBancaria.fields.nDocExterno'},
        {name: 'dcStr', caption: 'conciliacaoBancaria.fields.dc'},
        {name: 'valor', caption: 'conciliacaoBancaria.fields.valor', type: 'currency'}
      ]
    };
    this.tableOptions = {
      perPage: 50,
      suppressEmptyLines: true,
      noDataPlaceholder: true,
      noDataPlaceholderMessage: 'conciliacaoBancaria.naoExistemDocs'
    };
    this.tableCallback = {};
    this.btnAddCaption = 'global.btn.add';
    this._selectedItems = new Set<string>();
  }

  public close(): Promise<void> {
    this.disableClose();
    return this._conciliacaoBancariaService
      .incluirDocsDaListaNoExtratoCG(Array.from(this._selectedItems), this.nConta, this.periodo)
      .then(() => {
        this.enableClose();
        super.close();
      })
      .catch((reason: unknown) => {
        this.enableClose();
        if (reason instanceof HttpErrorResponse) {
          this._handleRequestError(reason);
        }
      });
  }

  public onSelect({item, itemIndex}: ICGTableOnSelect<IConciliacaoBancariaExtPocItem>): void {
    item._selected = !item._selected;
    this.tableCallback.rePaintBodyItem(itemIndex);
    if (item._selected) {
      this._selectedItems.add(item.nLanc);
    } else {
      this._selectedItems.delete(item.nLanc);
    }
    let caption = this._translateService.instant('global.btn.add');
    if (this._selectedItems.size > 0) {
      caption += ` (${this._selectedItems.size})`;
    }
    this.btnAddCaption = caption;
  }

  public readonly fnGetTableSource: TCGTableSourceFunction<IConciliacaoBancariaExtPocItem> = () => this._getTableSource();

  private _getTableSource(): Promise<Array<IConciliacaoBancariaExtPocItem>> {
    return this._conciliacaoBancariaService
      .carregaDocumentosAnteriores(this.nConta, this.periodo)
      .then((response) => {
        for (const item of response.list) {
          item._selected = this._selectedItems.has(item.nLanc);
        }
        return response.list;
      })
      .catch((reason: unknown) => {
        if (reason instanceof HttpErrorResponse) {
          this._handleRequestError(reason);
        }
        return [];
      });
  }

  private _handleRequestError(reason: HttpErrorResponse): void {
    const exception: ICGExceptionError = this._cgExceptionService.get(reason);
    this._plAlertService.error(exception.message);
  }
}
