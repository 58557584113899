import {Component, Injector, Input} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {HttpResponse} from '@angular/common/http';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGrid} from '../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IEntityService, IEntityServiceQueryRequestConfig} from '../../../../../../services/entity/entity.service.interface';
import {IJsonBanco} from '../../../../../../entities/bancos/jsonBanco.entity.interface';
import {EEntityMaintenanceEditMode, IEntityMaintenanceInstance} from '../../../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {CGModalService} from '../../../../../../components/cg/modal/cgmodal.service';
import {EntityServiceBuilder} from '../../../../../../services/entity/entity.service.builder';
import {EntityMaintenanceService} from '../../../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {ENTITY_NAME_BANCOS} from '../../../../../../entities/bancos/bancos.entity.interface';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {TEntityListAfterRequestFn, TEntityListBeforeRequestFn} from '../../../../../../components/entity/list/entity.list.component.interface';
import {IApiQueryResponse, THttpQueryResponse} from '../../../../../../services/api/api.service.interface';
import {RHAgenciasContaModalComponent} from './conta/rhAgencias.conta.modal.component';
import {RHAgenciasAgenciaModalComponent} from './agencia/rhAgencias.agencia.modal.component';
import {RHAgenciasService} from '../../../rhAgencias.module.service';
import {IRHAgenciasBanco} from '../../../rhAgencias.module.interface';
import {IJsonRHAgenciasAgencia, IJsonRHAgenciasConta} from '../../../jsonRHAgencias.module.interface';

@Component({
  selector: 'rhagencias-modal',
  templateUrl: './rhAgencias.modal.component.html'
})
export class RHAgenciasModalComponent extends CGModalComponent<void> {
  @Input() public maintenanceMode: boolean;

  public readonly dataGridDefinitionAgencias: IDevExpressDataGrid;
  public readonly dataGridDefinitionContas: IDevExpressDataGrid;
  public onlyIBAN: boolean;
  public onlyAtivos: boolean;

  private readonly _serviceBanco: IEntityService<IJsonBanco>;
  private readonly _entityMaintenanceInstanceBancos: IEntityMaintenanceInstance;
  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhAgenciasService: RHAgenciasService,
    private readonly _cgModalService: CGModalService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._serviceBanco = this._entityServiceBuilder.build<IJsonBanco>(ENTITY_NAME_BANCOS);
    this._entityMaintenanceInstanceBancos = this._entityMaintenanceService.build(ENTITY_NAME_BANCOS);
    this.onlyIBAN = false;
    this.onlyAtivos = true;
    this.dataGridDefinitionAgencias = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'codAgencia', dataType: 'string', caption: 'rhagencias.fields.codAgencia'},
        {dataField: 'nome', dataType: 'string', caption: 'rhagencias.fields.nome'},
        {type: 'buttons', headerCellTemplate: 'headerCellTemplateBtns', cellTemplate: 'cellTemplateBtns'}
      ],
      dataSource: [],
      keyExpr: 'codAgencia',
      masterDetail: {enabled: true, autoExpandAll: false, template: 'masterDetailTemplateAgencias'},
      remoteOperations: false,
      toolbar: {visible: false}
    };
    this.dataGridDefinitionContas = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'iban', dataType: 'string', caption: 'rhagencias.fields.iban'},
        {dataField: 'descricao', dataType: 'string', caption: 'rhagencias.fields.descricao'},
        {type: 'buttons', headerCellTemplate: 'headerCellTemplateBtns', cellTemplate: 'cellTemplateBtns'}
      ],
      dataSource: [],
      remoteOperations: false,
      toolbar: {visible: false}
    };
  }

  public evtDataGridOnInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public async refreshTableBancos(): Promise<void> {
    if (this._dataGridInstance) {
      this._dataGridInstance.collapseAll(-1);
      await this._dataGridInstance.refresh();
    }
  }

  public async onDataGridCellClick(event: IDevExpressDataGridEventOnCellClick<IRHAgenciasBanco>): Promise<void> {
    await devExpressDataGridExpandDetailHandler(event);
    if (event.data.agenciasList && event.data.agenciasList.length < 1) {
      await this._getAgencias(event.data);
    }
  }

  public async onCellClickAgencias(event: IDevExpressDataGridEventOnCellClick): Promise<void> {
    await devExpressDataGridExpandDetailHandler(event);
  }

  public async openNewBanco(): Promise<void> {
    await this._entityMaintenanceInstanceBancos.maintenanceNew();
    if (this._dataGridInstance) {
      await this._dataGridInstance.refresh();
    }
  }

  public async openEditBanco(item: IJsonBanco): Promise<void> {
    await this._entityMaintenanceInstanceBancos.maintenanceEdit(item.codBanco, {mode: EEntityMaintenanceEditMode.EditOnly, modalOptions: {size: 'xl'}});
    await this.refreshTableBancos();
  }

  public async deleteBanco(item: IJsonBanco): Promise<void> {
    await this._cgModalService.showOkCancel('rhagencias.messages.confirm', 'rhagencias.messages.delBanco');
    await this._serviceBanco.delete({id: item.codBanco});
    if (this._dataGridInstance) {
      await this._dataGridInstance.refresh();
    }
  }

  public async openNewAgencia(banco: IRHAgenciasBanco): Promise<void> {
    const agencia: Partial<IJsonRHAgenciasAgencia> = {
      codBanco: banco.codBanco,
      nomeBanco: banco.nome
    };
    const modalInstance = this._cgModalService.showVanilla(RHAgenciasAgenciaModalComponent);
    const componentInstance: RHAgenciasAgenciaModalComponent = modalInstance.componentInstance;
    componentInstance.agencia = agencia;
    await modalInstance.result;
    await this._getAgencias(banco);
  }

  public async openEditAgencia(banco: IRHAgenciasBanco, agencia: IJsonRHAgenciasAgencia): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(RHAgenciasAgenciaModalComponent);
    const componentInstance: RHAgenciasAgenciaModalComponent = modalInstance.componentInstance;
    componentInstance.agencia = agencia;
    await modalInstance.result;
    await this._getAgencias(banco);
  }

  public async deleteAgencia(banco: IRHAgenciasBanco, agencia: IJsonRHAgenciasAgencia): Promise<void> {
    await this._cgModalService.showOkCancel('rhagencias.messages.confirm', 'rhagencias.messages.delAgencia');
    await this._rhAgenciasService.deleteAgencia(agencia.codBanco, agencia.codAgencia);
    await this._getAgencias(banco).then(() => undefined);
    this._plAlertService.success('rhagencias.messages.delsuccAgencia');
  }

  public async openNewConta(agencia: IJsonRHAgenciasAgencia): Promise<void> {
    const conta: Partial<IJsonRHAgenciasConta> = {
      codBanco: agencia.codBanco,
      nomeBanco: agencia.nomeBanco,
      codAgencia: agencia.codAgencia,
      nomeAgencia: agencia.nome
    };
    const modalInstance = this._cgModalService.showVanilla(RHAgenciasContaModalComponent);
    const componentInstance: RHAgenciasContaModalComponent = modalInstance.componentInstance;
    componentInstance.conta = conta;
    const result: IJsonRHAgenciasConta = await modalInstance.result;
    agencia.contasList.push(result);
  }

  public async openEditConta(agencia: IJsonRHAgenciasAgencia, conta: IJsonRHAgenciasConta): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(RHAgenciasContaModalComponent);
    const componentInstance: RHAgenciasContaModalComponent = modalInstance.componentInstance;
    componentInstance.conta = conta;
    const result: IJsonRHAgenciasConta = await modalInstance.result;
    const index: number = agencia.contasList.findIndex((value: IJsonRHAgenciasConta) => value.codBanco === conta.codBanco && value.codAgencia === conta.codAgencia && value.iban === conta.iban);
    if (index !== -1) {
      agencia.contasList[index] = result;
    }
  }

  public async deleteConta(agencia: IJsonRHAgenciasAgencia, conta: IJsonRHAgenciasConta): Promise<void> {
    await this._cgModalService.showOkCancel('rhagencias.messages.confirm', 'rhagencias.messages.delConta');
    await this._rhAgenciasService.deleteConta(conta.codBanco, conta.codAgencia, conta.iban);
    const index: number = agencia.contasList.findIndex((value: IJsonRHAgenciasConta) => value.codBanco === conta.codBanco && value.codAgencia === conta.codAgencia && value.iban === conta.iban);
    if (index !== -1) {
      agencia.contasList.splice(index, 1);
    }
    this._plAlertService.success('rhagencias.messages.delsuccConta');
  }

  public readonly fnBeforeRequest: TEntityListBeforeRequestFn = (queryRequestConfig: IEntityServiceQueryRequestConfig) => this._beforeRequest(queryRequestConfig);

  public readonly fnAfterRequest: TEntityListAfterRequestFn<IRHAgenciasBanco> = (response: THttpQueryResponse<IRHAgenciasBanco>) => this._afterRequest(response);

  private _beforeRequest(queryRequestConfig: IEntityServiceQueryRequestConfig): IEntityServiceQueryRequestConfig {
    if (!queryRequestConfig.pesquisa) {
      queryRequestConfig.pesquisa = '';
    } else {
      queryRequestConfig.pesquisa += '&';
    }
    queryRequestConfig.pesquisa += `onlyiban=${this.onlyIBAN ? 1 : 0}`;
    return queryRequestConfig;
  }

  private _afterRequest(response: THttpQueryResponse<IRHAgenciasBanco>): IApiQueryResponse<IRHAgenciasBanco> {
    for (const banco of response.body.list) {
      banco.agenciasList = [];
    }
    return response.body;
  }

  private async _getAgencias(banco: IRHAgenciasBanco): Promise<void> {
    const response: HttpResponse<Array<IJsonRHAgenciasAgencia>> = await this._rhAgenciasService.getAgencias(banco.codBanco, this.onlyIBAN, this.onlyAtivos);
    banco.agenciasList = response.body;
  }
}
