import {Pipe, PipeTransform} from '@angular/core';
import type {IPlFormatConfig} from '../common/format/format.service.interface';
import {PlI18nPlNumberService} from '../i18n/i18n.plNumber.service';

@Pipe({
  name: 'cgcTax'
})
export class CGCFiltersTaxPipe implements PipeTransform {
  constructor(private readonly _plI18nPlNumberService: PlI18nPlNumberService) {}

  public transform(value: string | any, options?: Partial<IPlFormatConfig>): string {
    return this._plI18nPlNumberService.formatTax(value, options);
  }
}
