import {merge} from 'lodash-es';
import {Subscription} from 'rxjs';
import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FOCUSABLE_QUERY_SELECTOR, isArray, isBoolean, isDefinedNotNull, isEmpty, isObject, isString} from '../../common/utilities/utilities';
import {IPlEditComponentOptionsInputRadio} from '../../edit/components/radio/edit.radio.component.interface';
import {IPlEditComponentOptionsInputSelect} from '../../edit/components/select/edit.select.component.interface';
import {IPlFilterPanelAppliedFilter, IPlFilterPanelEvtFiltered, IPlFilterPanelProperties} from './filter.panel.component.interface';
import {IPlFilterPanelField, IPlFilterPanelFilters} from '../filter.panel.interface';
import {IPlLocale} from '../../common/locale/locales.interface';
import {PlFilterPanelAdapter} from '../filter.panel.adapter';
import {PlLocaleService} from '../../common/locale/locale.service';

const FIELD_TYPE_SELECT: ReadonlyArray<string> = Object.freeze(['radio', 'radiogroup', 'switch', 'switchgroup']);

@Component({
  selector: 'pl-filter',
  templateUrl: './filter.panel.component.html'
})
export class PlFilterPanelComponent<T = any> implements OnInit, OnChanges, OnDestroy {
  @Input() public fields: Array<IPlFilterPanelField>;
  @Input() public field: string | IPlFilterPanelField;
  @Input() public filters: IPlFilterPanelFilters;
  @Input() public serializedFilters: T;
  @Input() public collapsed: boolean;
  @Input() public showCaption: boolean;
  @Input() public autoFocusFieldInput: boolean;
  @Input() public properties: IPlFilterPanelProperties;
  @Output() public readonly filtersChange: EventEmitter<IPlFilterPanelFilters>;
  @Output() public readonly serializedFiltersChange: EventEmitter<T>;
  @Output() public readonly fieldChange: EventEmitter<string>;
  @Output() public readonly evtFiltered: EventEmitter<IPlFilterPanelEvtFiltered<T>>;

  public selectedFilter: IPlFilterPanelAppliedFilter;
  public appliedFilters: Array<IPlFilterPanelAppliedFilter>;
  public appliedVisibleFilters: Array<IPlFilterPanelAppliedFilter>;
  public selectedFilterType: string;
  public selectedFilterProperties: unknown;
  public locale: IPlLocale;
  public options: IPlFilterPanelProperties;

  private readonly _element: HTMLElement;
  private readonly _fieldsMap: Map<string, IPlFilterPanelField>;
  private readonly _appliedFiltersMap: Map<string, IPlFilterPanelAppliedFilter>;
  private readonly _subscriptionLocale: Subscription;
  private _defaultOptions: IPlFilterPanelProperties;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _plLocaleService: PlLocaleService,
    private readonly _plFilterPanelAdapter: PlFilterPanelAdapter<T>
  ) {
    this.filtersChange = new EventEmitter<IPlFilterPanelFilters>();
    this.serializedFiltersChange = new EventEmitter<T>();
    this.fieldChange = new EventEmitter<string>();
    this.evtFiltered = new EventEmitter<IPlFilterPanelEvtFiltered<T>>();
    this.appliedFilters = [];
    this.appliedVisibleFilters = [];
    this._element = this._elementRef.nativeElement;
    this._fieldsMap = new Map<string, IPlFilterPanelField>();
    this._appliedFiltersMap = new Map<string, IPlFilterPanelAppliedFilter>();
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this.locale = locale;
      this._defaultOptions = Object.freeze<IPlFilterPanelProperties>({
        collapsed: false,
        showCaption: true,
        autoFocusFieldInput: true,
        showLabel: `<i class="fa fa-filter" aria-hidden="true"></i>&nbsp;${this.locale.btn.addFilter}`,
        iconClass: 'fa fa-angle-right fa-fw'
      });
    });
  }

  public ngOnInit(): void {
    this._handleChanges();
    if (!this.field && this.fields.length) {
      this.selectFilter(this.fields[0]);
    }
  }

  public ngOnChanges({properties, collapsed, showCaption, autoFocusFieldInput, fields, filters, serializedFilters, field}: SimpleChanges): void {
    if (properties && !properties.isFirstChange()) {
      this._handleChanges();
    }
    if (collapsed && !collapsed.isFirstChange()) {
      this._changedCollapsed(collapsed.currentValue);
    }
    if (showCaption && !showCaption.isFirstChange()) {
      this._changedShowCaption(showCaption.currentValue);
    }
    if (autoFocusFieldInput && !autoFocusFieldInput.isFirstChange()) {
      this._changedAutoFocusFieldInput(autoFocusFieldInput.currentValue);
    }
    if (fields && !fields.isFirstChange()) {
      this._changedFields(fields.currentValue);
    }
    if ((filters && !filters.isFirstChange()) || (serializedFilters && !serializedFilters.isFirstChange())) {
      this._parseFilters();
    }
    if (field && !field.isFirstChange()) {
      this._changedField(field.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionLocale.unsubscribe();
  }

  public toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

  public getField(name: string): IPlFilterPanelField {
    return this._fieldsMap.get(name);
  }

  public selectFilter(filterOrName: string | IPlFilterPanelField): void {
    if ((!isString(filterOrName) || !filterOrName) && !isObject(filterOrName)) {
      return;
    }
    const field: IPlFilterPanelField = isString(filterOrName) ? this.getField(filterOrName) : filterOrName;
    if (!isObject(field)) {
      return;
    }
    if (!this.selectedFilter || this.selectedFilter.field !== field) {
      this.selectedFilter = this._appliedFiltersMap.get(field.name);
      if (!this.selectedFilter) {
        this.selectedFilter = {
          field: field,
          value: undefined,
          prettyValue: undefined
        };
      }
      this.selectedFilterType = this._getSelectedFilterType(this.selectedFilter.field);
      this.selectedFilterProperties = this._getSelectedFilterProperties(this.selectedFilter.field);
      this.fieldChange.emit(field.name);
    }
    if (this.autoFocusFieldInput) {
      setTimeout(() => {
        const toFocus: HTMLElement = this._element.querySelector<HTMLElement>(FOCUSABLE_QUERY_SELECTOR);
        if (toFocus) {
          toFocus.focus();
        }
      });
    }
  }

  public addFilter(value: unknown): void {
    this.selectedFilter.value = value;
    value = (<{value: unknown}>value)?.value || value;
    if (isObject(value)) {
      value = this.selectedFilter.value;
    }
    const field: IPlFilterPanelField = this.selectedFilter.field;
    const found: IPlFilterPanelAppliedFilter = this._appliedFiltersMap.get(field.name);
    if (found) {
      if (isEmpty(value)) {
        this.removeFilter(found);
        return;
      }
      found.value = value;
    } else if (isDefinedNotNull(value)) {
      const appliedFilter: IPlFilterPanelAppliedFilter = {
        field: field,
        value: value,
        prettyValue: this._plFilterPanelAdapter.prettyPrintFilterValue(field, value)
      };
      this.appliedFilters.push(appliedFilter);
      if (!appliedFilter.field.hidden) {
        this.appliedVisibleFilters.push(appliedFilter);
      }
      this._appliedFiltersMap.set(field.name, appliedFilter);
    }
    this._apply();
  }

  public removeFilter(appliedFilter: IPlFilterPanelAppliedFilter): void {
    if (appliedFilter.field.persistent) {
      return;
    }
    const index: number = this.appliedFilters.findIndex((filter: IPlFilterPanelAppliedFilter) => filter === appliedFilter);
    if (index !== -1) {
      this.appliedFilters.splice(index, 1);
      this._evaluateAppliedVisibleFilters();
      this._appliedFiltersMap.delete(appliedFilter.field.name);
      this._apply();
    }
  }

  public clearFilters(): void {
    this.appliedFilters = [];
    this._evaluateAppliedPersistentFilters();
    this._evaluateAppliedVisibleFilters();
    this._evaluateAppliedFiltersMap();
    this._apply();
  }

  private _handleChanges(): void {
    this._changedProperties();
    this._changedCollapsed();
    this._changedShowCaption();
    this._changedAutoFocusFieldInput();
    this._changedFields();
    this._parseFilters();
    this._changedField();
  }

  private _changedProperties(value: IPlFilterPanelProperties = this.properties): void {
    this.options = merge({}, this._defaultOptions, this.options, value);
  }

  private _changedCollapsed(value: boolean = this.collapsed): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.collapsed;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.collapsed = val;
  }

  private _changedShowCaption(value: boolean = this.showCaption): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.showCaption;
    }
    if (!isBoolean(val)) {
      val = true;
    }
    this.showCaption = val;
    if (!this.showCaption) {
      this.collapsed = false;
    }
  }

  private _changedAutoFocusFieldInput(value: boolean = this.autoFocusFieldInput): void {
    let val = value;
    if (!isBoolean(val)) {
      val = this.options.autoFocusFieldInput;
    }
    if (!isBoolean(val)) {
      val = true;
    }
    this.autoFocusFieldInput = val;
  }

  private _changedFields(value: Array<IPlFilterPanelField> = this.fields): void {
    let val: Array<IPlFilterPanelField> = value;
    if (!isArray(val)) {
      val = [];
    }
    this.fields = val;
    this._fieldsMap.clear();
    for (const field of this.fields) {
      this._fieldsMap.set(field.name, field);
    }
  }

  private _changedField(value: string | IPlFilterPanelField = this.field): void {
    this.selectFilter(value);
  }

  private _parseFilters(): void {
    this.appliedFilters = [];

    const parse = (filters: IPlFilterPanelFilters): void => {
      for (const fieldName of Object.keys(filters)) {
        const appliedFilter: IPlFilterPanelAppliedFilter = this._parseFilter(fieldName, filters[fieldName]);
        if (appliedFilter) {
          this.appliedFilters.push(appliedFilter);
        }
      }
    };

    if (isObject(this.filters)) {
      parse(this.filters);
    } else if (!isEmpty(this.serializedFilters)) {
      const filters: IPlFilterPanelFilters = this._plFilterPanelAdapter.deserialize({
        fields: this.fields,
        fieldsMap: this._fieldsMap,
        serializedFilters: this.serializedFilters
      });
      parse(filters);
    }

    this._evaluateAppliedVisibleFilters();
    this._evaluateAppliedFiltersMap();
  }

  private _parseFilter(fieldName: string, value: unknown): undefined | IPlFilterPanelAppliedFilter {
    const field: IPlFilterPanelField = this._fieldsMap.get(fieldName);
    if (!field) {
      return undefined;
    }
    return {
      field: field,
      value: value,
      prettyValue: this._plFilterPanelAdapter.prettyPrintFilterValue(field, value)
    };
  }

  private _getSelectedFilterType(field: IPlFilterPanelField): string {
    if (FIELD_TYPE_SELECT.includes(field.type)) {
      return 'select';
    }
    return field.type || 'text';
  }

  private _getSelectedFilterProperties(field: IPlFilterPanelField): unknown {
    const properties: unknown = merge({}, field.properties, {
      validate: false,
      inlineMode: true,
      appendToBody: false,
      modelOptions: {
        debounce: 300
      }
    });
    delete (<{validators: unknown}>properties).validators;
    if (FIELD_TYPE_SELECT.includes(field.type)) {
      const selectProperties: IPlEditComponentOptionsInputSelect<T> = <IPlEditComponentOptionsInputSelect<T>>properties;

      if (field.type === 'radio' || field.type === 'radiogroup') {
        selectProperties.select = (<{radio: unknown}>properties).radio || {};
        selectProperties.select.list = (<{groupItems: Array<T>}>properties).groupItems;
        selectProperties.select.labelProp = (<IPlEditComponentOptionsInputRadio<T>>(<{radio: unknown}>properties).radio)?.label || 'label';
        delete (<{radio: unknown}>selectProperties).radio;
      }

      if (field.type === 'switch' || field.type === 'switchgroup') {
        selectProperties.select = (<{switch: unknown}>properties).switch || {};
        selectProperties.select.list = (<{groupItems: Array<T>}>properties).groupItems;
        selectProperties.select.labelProp = (<IPlEditComponentOptionsInputRadio<T>>(<{switch: unknown}>properties).switch)?.label || 'label';
        delete (<{switch: unknown}>selectProperties).switch;
      }
    }
    return properties;
  }

  private _apply(): void {
    this.filters = {};
    for (const appliedFilter of this.appliedFilters) {
      this.filters[appliedFilter.field.name] = appliedFilter.value;
    }
    this.filtersChange.emit(this.filters);
    this.serializedFilters = this._plFilterPanelAdapter.serialize({fields: this.fields, fieldsMap: this._fieldsMap, filters: this.filters});
    this.serializedFiltersChange.emit(this.serializedFilters);
    this.evtFiltered.emit({filters: this.filters, serializedFilters: this.serializedFilters});
  }

  private _evaluateAppliedPersistentFilters(): void {
    this.appliedFilters = this.appliedFilters.filter((appliedFilter: IPlFilterPanelAppliedFilter) => appliedFilter.field.persistent);
  }

  private _evaluateAppliedVisibleFilters(): void {
    this.appliedVisibleFilters = this.appliedFilters.filter((appliedFilter: IPlFilterPanelAppliedFilter) => !appliedFilter.field.hidden);
  }

  private _evaluateAppliedFiltersMap(): void {
    this._appliedFiltersMap.clear();
    for (const appliedFilter of this.appliedFilters) {
      this._appliedFiltersMap.set(appliedFilter.field.name, appliedFilter);
    }
  }
}
