<div class="avisos-cobranca-preview-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'avisoscobranca.modal.avisoscobrancaemail'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'avisoscobranca.fields.listagem'"></label>
      <edit>
        <pl-edit type="reports_avisos_cobranca" attrName="listagem" [(model)]="report" [properties]="{disallowClear: true}"></pl-edit>
      </edit>
    </pl-group>

    <hr />

    <pl-group>
      <label [translate]="'emailclifos.sendMail.assunto'"></label>
      <edit>
        <pl-edit type="text" [(model)]="subject"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'emailclifos.sendMail.texto'"></label>
      <edit>
        <dx-html-editor [(ngModel)]="body" [height]="400">
          <dxo-toolbar [multiline]="true">
            <dxi-item name="undo"></dxi-item>
            <dxi-item name="redo"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="size" [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"></dxi-item>
            <dxi-item name="font" [acceptedValues]="['Arial', 'Georgia', 'Tahoma', 'Times New Roman', 'Verdana']"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="bold"></dxi-item>
            <dxi-item name="italic"></dxi-item>
            <dxi-item name="strike"></dxi-item>
            <dxi-item name="underline"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="alignLeft"></dxi-item>
            <dxi-item name="alignCenter"></dxi-item>
            <dxi-item name="alignRight"></dxi-item>
            <dxi-item name="alignJustify"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="orderedList"></dxi-item>
            <dxi-item name="bulletList"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="color"></dxi-item>
            <dxi-item name="background"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="link"></dxi-item>
            <dxi-item name="image"></dxi-item>
          </dxo-toolbar>
        </dx-html-editor>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-success action-ok" [disabled]="closeDisabled" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.sendMail'"></span></pl-button>

    <pl-button klass="btn-light action-dismiss" [disabled]="closeDisabled" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
