import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {chartCombineSeriesTooltip, chartToggleSeriesVisibility} from '../../../components/devexpress/charts/utilities/devexpress.chart.utilities';
import {currentYear} from '../../../../common/dates';
import {IDevExpressChart} from '../../../components/devexpress/charts/devexpress.charts.interface';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonDashboardsValor} from '../../../interfaces/jsonDashboards.interface';
import {IVendasPorMesDadosChart, IVendasPorMesDadosTable} from './vendasAnoMes.interface';
import {ListagensService} from '../../../services/listagens.service';
import {round} from '../../../../common/utils/utils';
import {IDashboardsERP} from '../../../services/dashboardserp/dashboards.erp.service.interface';
import {LegendClickEvent, PointHoverChangedEvent} from 'devextreme/viz/chart';

const PRIMARY_COLOR = '#62A8EA';
const GREEN_COLOR = '#46BE8A';
const WARNING_COLOR = '#F2A654';

@Component({
  selector: 'vendas-ano-mes',
  templateUrl: './vendasAnoMes.component.html'
})
export class VendasAnoMesComponent {
  public readonly definition: IDevExpressDataGrid;
  public readonly chartVendasMes: IDevExpressChart<'BarSeries'>;
  public readonly anoAnalise: number;
  public readonly anoAnaliseAnterior: number;

  public tableVendasMesData: Array<IVendasPorMesDadosTable>;
  public chartVendasMesData: Array<IVendasPorMesDadosChart>;

  public list: Array<IJsonDashboardsValor>;
  public listAnoAnterior: Array<IJsonDashboardsValor>;
  public listTotal: number;
  public listTotalAnoAnterior: number;
  public promise: Promise<void>;
  public anoAnteriorFiltered: boolean;

  private readonly _currentMonth: number;
  private readonly _labels: Array<string>;
  private readonly _datasetAnoAnaliseAnterior: Array<number>;
  private readonly _datasetAnoAnalise: Array<number>;
  private readonly _promiseAnoAnaliseAnterior: Promise<void>;
  private readonly _promiseAnoAnalise: Promise<void>;

  constructor(
    private readonly _listagensService: ListagensService,
    private readonly _translateService: TranslateService
  ) {
    this.anoAnalise = currentYear();
    this.anoAnaliseAnterior = this.anoAnalise - 1;
    this._currentMonth = new Date().getMonth();

    this.list = [];
    this.listAnoAnterior = [];
    this.listTotal = 0;
    this.listTotalAnoAnterior = 0;
    this.anoAnteriorFiltered = false;

    this.chartVendasMesData = [];
    this.tableVendasMesData = [];

    this._labels = [];
    this._datasetAnoAnaliseAnterior = [];
    this._datasetAnoAnalise = [];

    this._promiseAnoAnaliseAnterior = this._listagensService.listagensVendasDoAnoPorMes(currentYear() - 1).then((response: IDashboardsERP) => {
      // Grafico
      for (let i = response.valores.length - 1; i >= 0; i--) {
        const item = response.valores[i];
        this._datasetAnoAnaliseAnterior.push(item.valor);
      }
      // Quadro
      this.listAnoAnterior = response.valores;
    });

    this._promiseAnoAnalise = this._listagensService.listagensVendasDoAnoPorMes(currentYear()).then((response: IDashboardsERP) => {
      // Grafico
      for (let i = response.valores.length - 1; i >= 0; i--) {
        const item = response.valores[i];
        this._labels.push(item.mes);
        this._datasetAnoAnalise.push(item.valor);
      }
      // Quadro
      this.list = response.valores;
    });

    this.chartVendasMes = {
      commonSeriesSettings: {
        argumentField: 'month',
        type: 'bar',
        ignoreEmptyPoints: true,
        hoverMode: 'allArgumentPoints'
      },
      title: {
        text: this._translateService.instant('vendasanomes.chart.title')
      },
      series: [
        {valueField: 'data1', color: PRIMARY_COLOR, name: this.anoAnaliseAnterior.toString()},
        {valueField: 'data2', color: GREEN_COLOR, name: this.anoAnalise.toString()},
        {type: 'spline', valueField: 'valueDif', color: WARNING_COLOR, name: this._translateService.instant('vendasanomes.chart.diference'), point: {size: 6}}
      ],
      tooltip: {
        location: 'edge',
        paddingLeftRight: 15,
        paddingTopBottom: 10,
        contentTemplate: 'customTooltip',
        shared: true
      },
      size: {
        height: 427
      },
      stickyHovering: true
    };
    this.definition = {
      columns: [
        {dataField: 'mes', dataType: 'string', caption: 'vendasanomes.table.mes'},
        {dataField: 'data1', dataType: 'double', caption: this.anoAnaliseAnterior.toString()},
        {dataField: 'data2', dataType: 'double', caption: this.anoAnalise.toString()},
        {dataField: 'variacaoValor', dataType: 'double', caption: 'vendasanomes.table.valorVariacao'},
        {dataField: 'variacaoPercentagem', dataType: 'number', format: {percent: true}, caption: 'vendasanomes.table.percentVariacao'}
      ],
      summary: {
        totalItems: [
          {
            showInColumn: 'mes',
            summaryType: 'custom',
            alignment: 'right',
            customizeText: () => {
              return this._translateService.instant('vendasanomes.table.footer.total');
            }
          },
          {
            name: 'totalData1',
            showInColumn: 'data1',
            summaryType: 'custom',
            valueFormat: 'double'
          },
          {
            name: 'totalData2',
            showInColumn: 'data2',
            summaryType: 'custom',
            valueFormat: 'double'
          },
          {
            name: 'totalVariacaoValor',
            showInColumn: 'variacaoValor',
            summaryType: 'custom',
            valueFormat: 'double'
          },
          {
            showInColumn: 'variacaoPercentagem',
            summaryType: 'custom',
            valueFormat: 'double',
            customizeText: () => {
              return `${this._obterPercVariacao(this.listTotal, this.listTotalAnoAnterior)}%`;
            }
          }
        ],
        calculateCustomSummary: (options) => {
          if (options.summaryProcess === 'finalize') {
            if (options.name === 'totalData1') {
              options.totalValue = this.listTotalAnoAnterior;
            }
            if (options.name === 'totalData2') {
              options.totalValue = this.listTotal;
            }
            if (options.name === 'totalVariacaoValor') {
              options.totalValue = this.listTotal - this.listTotalAnoAnterior;
            }
          }
        }
      },
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      filterRow: {visible: false},
      grouping: {contextMenuEnabled: false, allowCollapsing: false},
      groupPanel: {allowColumnDragging: false, visible: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true,
      pager: {visible: false},
      paging: {enabled: false},
      remoteOperations: false,
      searchPanel: {visible: false},
      showBorders: true
    };

    this.updateInfo();
  }

  public onChartPointHoverChanged(event: PointHoverChangedEvent): void {
    chartCombineSeriesTooltip(event);
  }

  public onChartLegendClick(event: LegendClickEvent): void {
    chartToggleSeriesVisibility(event);
  }

  public updateInfo(): void {
    this.promise = this._updateData(this._promiseAnoAnalise, this._promiseAnoAnaliseAnterior);
  }

  private _obterPercVariacao(valor: number, valorAnterior: number): number {
    const val: number = valor - valorAnterior;
    if (valorAnterior > 0) {
      return round((val / valorAnterior) * 100, 2);
    }
    return 100;
  }

  private _updateData(promiseAnoAnalise: Promise<void>, promiseAnoAnaliseAnterior: Promise<void>): Promise<void> {
    this.chartVendasMesData = [];
    this.tableVendasMesData = [];
    this.listTotal = 0;
    this.listTotalAnoAnterior = 0;
    return Promise.all([promiseAnoAnalise, promiseAnoAnaliseAnterior]).then(() => {
      for (let i = 0; i < this._labels.length; i++) {
        const itemChart: IVendasPorMesDadosChart = {
          month: this._labels[i],
          data1: this._datasetAnoAnaliseAnterior[i],
          data2: this._datasetAnoAnalise[i],
          valueDif: this._currentMonth < i ? null : this._datasetAnoAnalise[i] - this._datasetAnoAnaliseAnterior[i]
        };
        this.chartVendasMesData.push(itemChart);

        const itemTable: IVendasPorMesDadosTable = {
          mes: this._labels[i],
          data1: this._datasetAnoAnaliseAnterior[i],
          data2: this._datasetAnoAnalise[i],
          variacaoValor: Number(this._datasetAnoAnalise[i]) - Number(this._datasetAnoAnaliseAnterior[i]),
          variacaoPercentagem: this._obterPercVariacao(this._datasetAnoAnalise[i], this._datasetAnoAnaliseAnterior[i])
        };
        this.tableVendasMesData.push(itemTable);
        this.listTotalAnoAnterior += this._datasetAnoAnaliseAnterior[i];
        this.listTotal += this._datasetAnoAnalise[i];
        if (!this.anoAnteriorFiltered && this._currentMonth <= i) {
          break;
        }
      }
    });
  }
}
