<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="modalTitle"></h4>
  </div>

  <div class="modal-body" [plPromise]="promise">
    <div class="row">
      <div class="col-md-12">
        <h4 class="main-title" [translate]="'conciliacaoBancaria.lancNaContaContabilidadeNaPerspetivaEmpresaTitle'"></h4>
      </div>

      <div class="col-md-12">
        <pl-group>
          <label [translate]="'conciliacaoBancaria.fields.descricao'"></label>
          <edit>
            <pl-edit type="text" [(model)]="model.descricao" plAutoFocus></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'conciliacaoBancaria.fields.data'"></label>
          <edit>
            <pl-edit-datepicker [(model)]="model.data"></pl-edit-datepicker>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'conciliacaoBancaria.fields.valor'"></label>
          <edit>
            <pl-edit type="number" [(model)]="model.valor"></pl-edit>
          </edit>
        </pl-group>
      </div>

      <div class="col-md-12">
        <pl-group>
          <label [translate]="'conciliacaoBancaria.tipoMovimentoNaPerspectivaEmpresaTitle'"></label>
          <edit>
            <pl-edit type="radiogroup" [(model)]="model.dc" [properties]="{groupItems: tipoMovimentoItems}"></pl-edit>
          </edit>
        </pl-group>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-primary action-save" data-focus [disabled]="closeDisabled" [click]="close" plPromise>
      <i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="btnSaveCaption"></span>
    </button>

    <button type="button" class="btn btn-light action-dismiss" [disabled]="closeDisabled" (click)="dismiss()">
      <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
    </button>
  </div>
</pl-form>
