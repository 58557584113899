<div class="modal-documento-rastreabilidade">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'rastreabilidade.title'"></h4>
  </div>

  <div class="modal-body">
    <pl-split-view [initialSizeLeft]="40" [initialSizeRight]="60" (evtSizeValuesChanged)="onResizerValuesChanged()">
      <div *plSplitViewLeftSide>
        <dx-diagram
          id="diagram"
          (onInitialized)="onInitialized($event)"
          (onContentReady)="onContentReady($event)"
          (onSelectionChanged)="onSelectionChanged($event)"
          [nodes]="diagramNodes"
          [contextMenu]="diagramContextMenu"
          [viewToolbar]="diagramViewToolbar"
          [readOnly]="true"
          [simpleView]="true"
          [showGrid]="false">
        </dx-diagram>
      </div>

      <div *plSplitViewRightSide [plPromise]="promise">
        <documento-contabilidade *ngIf="selected?._docContabilidade" [doc]="selected._docContabilidade" [readonly]="true"></documento-contabilidade>
        <documento-faturacao *ngIf="!selected?.extPocCabID && selected?._doc" [doc]="selected._doc" [actionPdf]="fnGetPdf"></documento-faturacao>
      </div>
    </pl-split-view>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
  </div>
</div>
