import {Pipe, PipeTransform} from '@angular/core';
import {PlTranslateService} from './translate.service';

@Pipe({
  name: 'cgcTranslate'
})
export class CGCTranslatePipe implements PipeTransform {
  constructor(
    private readonly _plTranslateService: PlTranslateService
  ) {
  }

  public transform(value: string, ...args: Array<any>): string {
    if (!value) {
      return '';
    }
    return this._plTranslateService.translate(value, ...args);
  }
}
