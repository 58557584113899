<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'docfasNums.modal.seriesPorComunicarAT.title'"></h4>
</div>

<div class="modal-body">
  <div class="comunicacaoseriesat-table">
    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinition"
      cgDxDataGridInstanceName="comunicacaoSeriesPorComunicarAT"
      [dataSource]="dataGridDefinition.dataSource"
      (onInitialized)="onInitialized($event)"
      (onContentReady)="onContentReady()"
      [selectedRowKeys]="selectedRowKeys"
      (selectedRowKeysChange)="selectedRowKeys = $event; onSelectedRowKeysChange()"
      (onCellPrepared)="onCellPrepared($event)">
      <div *dxTemplate="let item of 'cellTemplateComuncicacoesSerieAtNumInicial'">
        <span class="cell-template-text-comunicacaoseriesat">{{ item.data.numInicial }}</span>
      </div>
      <div *dxTemplate="let item of 'cellTemplateComuncicacoesSerieAtDataInicio'">
        <span class="cell-template-text-comunicacaoseriesat">{{ item.data.dataInicio | cgcDate }}</span>
      </div>
    </dx-data-grid>
  </div>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-success btn-sm" [onClick]="fnComunicarSeriesAT" [disabled]="onClickAction">
    <i class="fa fa-fw fa-paper-plane"></i>&nbsp;<span [translate]="'docfasNums.modal.seriesPorComunicarAT.btn.comunicarseriesAT'"></span>
  </pl-button>

  <pl-button klass="btn-light btn-sm action-close" (evtClicked)="close()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
</div>
