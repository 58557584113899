<div class="modal-header">
  <button type="button" class="btn-close" (click)="close()"></button>
  <h4 class="modal-title" [translate]="'generateAbdes.title'" [translateParams]="{abndesc: titleAbnDesc}"></h4>
</div>

<div class="modal-body">
  <div class="entity-detail-form">
    <pl-nav-wizard [definition]="definition" [beforeStepChange]="fnBeforeStepChange" [properties]="propertiesNavWizard" [destroyOnHide]="false">
      <pl-nav-wizard-step caption="generateAbdes.stepCategoria" icon="fa-file">
        <pl-form *plNavWizardStepContent (formInstanceChange)="formCategoria = $event">
          <pl-group *ngIf="abono">
            <label [translate]="'generateAbdes.fields.categoria'"></label>
            <edit>
              <pl-edit attrName="categoria" type="tiposCategoria" [model]="categoria" (modelChange)="categoriaChange($event)" [properties]="{validators: {required: {value: true}}}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group *ngIf="!abono">
            <label [translate]="'generateAbdes.fields.categoria'"></label>
            <edit>
              <pl-edit attrName="categoria" type="tiposCategoriaDesc" [model]="categoria" (modelChange)="categoriaChange($event)" [properties]="{validators: {required: {value: true}}}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'generateAbdes.fields.perfilAbDesc'"></label>
            <edit>
              <pl-autocomplete
                [source]="listaPerfilSistemaAbdes"
                [model]="perfilSistemaAbdes"
                [rowTemplate]="perfilSistemaAbdesTemplate"
                [output]="perfilSistemaAbdesTemplate"
                (evtSelected)="perfilSistemaAbdesChanged($event)"
                [properties]="{disabled: !categoria && categoria !== 0}"
                attrName="perfilSistemaAbdes">
              </pl-autocomplete>
            </edit>
          </pl-group>

          <pl-group *ngIf="ausenciasVisible">
            <label [translate]="'generateAbdes.fields.ausencia'"></label>
            <edit>
              <pl-edit attrName="ausencia" type="tiposAusencias" [(model)]="ausencia" [properties]="{validators: {required: {value: true}}}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <pl-group *ngIf="valorLimiteIncVisible">
              <label [translate]="'abdes.fields.valorLimIncidencia'"></label>
              <edit>
                <pl-edit-number attrName="valorLimIncidencia" [(model)]="valorLimiteInc"></pl-edit-number>
              </edit>
            </pl-group>

            <pl-group *ngIf="valorUnitVisible">
              <label [translate]="'abdes.fields.valorFixo'"></label>
              <edit>
                <pl-edit-number attrName="valorUnitVisible" [(model)]="valorUnit"></pl-edit-number>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group *ngIf="percAumentHorExtraVisible">
            <label [translate]="'generateAbdes.fields.percAumentHExtra'"></label>
            <edit>
              <pl-edit-number attrName="percAumentHorExtraVisible" [(model)]="percAumentHorExtra"></pl-edit-number>
            </edit>
          </pl-group>
        </pl-form>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step caption="generateAbdes.stepPerfil" [hideFinalize]="true" icon="fa-edit">
        <pl-form *plNavWizardStepContent (formInstanceChange)="formPerfil = $event">
          <pl-group>
            <pl-group>
              <label [translate]="'generateAbdes.fields.nome'"></label>
              <edit>
                <pl-edit-text attrName="nome" [(model)]="nome"></pl-edit-text>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'generateAbdes.fields.descricao'"></label>
              <edit>
                <pl-edit-text attrName="descricao" [(model)]="descricao"></pl-edit-text>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'generateAbdes.fields.grupo'"></label>
              <edit>
                <pl-edit-text attrName="grupo" [(model)]="perfilAbDes.nomeGrupo" [properties]="{readonly: true}"></pl-edit-text>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'generateAbdes.fields.tipoArredondamento'"></label>
              <edit>
                <pl-edit attrName="tipoArredondamento" type="tipoArrendodamentoABDES" [(model)]="perfilAbDes.tipoArredondamento" [properties]="{disabled: isPerfilSistema}"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'generateAbdes.fields.tipoRecolha'"></label>
              <edit>
                <pl-edit attrName="tipoRecolha" type="tipoValorUnitarioRecolher" [(model)]="perfilAbDes.tipoRecolha"></pl-edit>
              </edit>
            </pl-group>

            <pl-group *ngIf="!abono">
              <label [translate]="'generateAbdes.fields.nHorasDiaProvDesc'"></label>
              <edit>
                <pl-edit attrName="nHorasDiaProvDesc" type="cginteger" [(model)]="perfilAbDes.nHorasDiaProvDesc"></pl-edit>
              </edit>
            </pl-group>

            <pl-group *ngIf="abono">
              <label [translate]="'generateAbdes.fields.contribuiCalculoCustoPrecoH'"></label>
              <edit>
                <pl-edit-checkbox attrName="contribuiCalculoCustoPrecoH" [(model)]="perfilAbDes.contribuiCalculoCustoPrecoH"></pl-edit-checkbox>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'generateAbdes.fields.codRem'"></label>
              <edit>
                <entity-autocomplete
                  entity="sscol"
                  attrName="codRem"
                  [model]="perfilAbDes"
                  (selectedKeyChange)="perfilAbDes.codRem = $event"
                  (evtSelectedDescriptionChanged)="perfilAbDes.nomeCodRem = $event"
                  [fieldsMap]="{codSSCol: 'codRem', designacao: 'nomeCodRem'}"
                  [properties]="{disabled: isPerfilSistema}"
                  output="nome">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'generateAbdes.fields.codUnidade'"></label>
              <edit>
                <entity-autocomplete
                  entity="rhunidades"
                  attrName="codUnidade"
                  [model]="perfilAbDes"
                  (selectedKeyChange)="perfilAbDes.unidadeDefeito = $event"
                  (evtSelectedDescriptionChanged)="perfilAbDes.nomeUnidadeDefeito = $event"
                  [fieldsMap]="{codUnidade: 'unidadeDefeito', nome: 'nomeUnidadeDefeito'}"
                  output="nome">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <label [translate]="'generateAbdes.fields.tipoRendimento'"></label>
            <edit>
              <entity-autocomplete
                entity="rendis"
                attrName="tipoRendimento"
                [model]="perfilAbDes"
                (selectedKeyChange)="perfilAbDes.tipoRendimento = $event"
                (evtSelectedDescriptionChanged)="perfilAbDes.nomeTipoRendimento = $event"
                [fieldsMap]="{tipRendiment: 'tipoRendimento', nome: 'nomeTipoRendimento'}"
                [properties]="{disabled: isPerfilSistema}"
                outputKey="tipRendiment"
                output="nome">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <fieldset class="mb-3">
            <legend [translate]="'generateAbdes.abdesemp'"></legend>
            <pl-group>
              <pl-group>
                <label [translate]="'generateAbdes.fields.contaMovDeb'"></label>
                <edit>
                  <entity-autocomplete attrName="contaMovDeb" entity="pocs" [(selectedKey)]="perfilAbDes.nContaDebitoEmp" output="key"></entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'generateAbdes.fields.contaMovCred'"></label>
                <edit>
                  <entity-autocomplete attrName="contaMovCred" entity="pocs" [(selectedKey)]="perfilAbDes.nContaCreditoEmp" output="key"></entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>
          </fieldset>

          <fieldset class="mb-3">
            <legend [translate]="'generateAbdes.encpatronempresa'"></legend>
            <pl-group>
              <pl-group>
                <label [translate]="'generateAbdes.fields.contaEncDEB'"></label>
                <edit>
                  <entity-autocomplete attrName="contaEncDEB" entity="pocs" [(selectedKey)]="perfilAbDes.nContaDebitoPatronato" output="key"></entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'generateAbdes.fields.contaEncCRE'"></label>
                <edit>
                  <entity-autocomplete attrName="contaEncCRE" entity="pocs" [(selectedKey)]="perfilAbDes.nContaCreditoPatronato" output="key"></entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>
          </fieldset>

          <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="generateABDESGrid" [dataSource]="dataGridDefinition.dataSource" (onInitialized)="onInitialized($event)">
            <div *dxTemplate="let item of 'cellTemplateBtnsEdit'">
              <button type="button" class="btn btn-xs btn-warning me-1" [disabled]="isPerfilSistema" (click)="editarCruzamento(item.data)">
                <i class="fa fa-fw fa-pencil-square-o"></i>
              </button>
              <button type="button" class="btn btn-xs btn-danger" [disabled]="isPerfilSistema" (click)="clearCruzamento(item.data)">
                <i class="fa fa-fw fa-times-circle-o"></i>
              </button>
            </div>
          </dx-data-grid>
        </pl-form>
      </pl-nav-wizard-step>
    </pl-nav-wizard>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-primary action-save" [click]="fnGenerateABDES" [disabled]="disableBtnGerar" plPromise>
    <i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'generateAbdes.gerar'"></span>
  </button>
  <button type="button" class="btn btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
</div>
