import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonPlanning} from '../../../ativosaquisicao/jsonAtivosAquisicao.entity.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'modal-ativos-planning',
  templateUrl: './ativosPlanning.modal.component.html'
})
export class AtivosPlanningModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public ativosPlanning: Array<IJsonPlanning>;
  public readonly dataGridDefinition: IDevExpressDataGrid;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {
          caption: 'ativos.table.ano',
          alignment: 'center',
          columns: [{dataField: 'ano', dataType: 'number', caption: 'ativos.table.body.anoEconomico'}]
        },
        {
          caption: 'ativos.table.depreciacoes',
          alignment: 'center',
          columns: [
            {dataField: 'mesde', dataType: 'number', caption: 'ativos.table.body.mesde'},
            {dataField: 'mesate', dataType: 'number', caption: 'ativos.table.body.mesate'}
          ]
        },
        {
          caption: 'ativos.table.dadosContabilisticos',
          alignment: 'center',
          columns: [
            {dataField: 'contabValorAquisicao', dataType: 'double', caption: 'ativos.table.body.valorAquisicao'},
            {dataField: 'contabValorResidual', dataType: 'double', caption: 'ativos.table.body.valorResidual'},
            {dataField: 'contabTaxa', dataType: 'double', caption: 'ativos.table.body.taxa'},
            {dataField: 'contabPercReducao', dataType: 'double', caption: 'ativos.table.body.redTaxa'},
            {dataField: 'contabDepreciacao', dataType: 'double', caption: 'ativos.table.body.depreciacao'}
          ]
        },
        {
          caption: 'ativos.table.dadosFiscais',
          alignment: 'center',
          columns: [
            {dataField: 'fiscalValorAquisicao', dataType: 'double', caption: 'ativos.table.body.valorAquisicao'},
            {dataField: 'fiscalValorResidual', dataType: 'double', caption: 'ativos.table.body.valorResidual'},
            {dataField: 'fiscalTaxa', dataType: 'double', caption: 'ativos.table.body.taxa'},
            {dataField: 'fiscalPercReducao', dataType: 'double', caption: 'ativos.table.body.redTaxa'},
            {dataField: 'fiscalDepreciacao', dataType: 'double', caption: 'ativos.table.body.depreciacao'}
          ]
        }
      ],
      dataSource: [],
      export: {filename: 'ativos.steps.planningAmortizacoes'},
      remoteOperations: false,
      summary: {
        totalItems: [
          {column: 'contabDepreciacao', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'fiscalDepreciacao', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'}
        ]
      }
    };
  }

  public ngOnInit(): void {
    this.dataGridDefinition.dataSource = this.ativosPlanning;
  }
}
