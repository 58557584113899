import {merge, orderBy} from 'lodash-es';
import type {Subscription} from 'rxjs';
import {AfterContentChecked, ContentChildren, Directive, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {IPlCompsResponsiveTheme, IPlCompsServiceConfig} from '../common/interface';
import type {IPlLocale} from '../common/locale/locales.interface';
import type {IPlNavWizardStep, IPlNavWizardStepAction, IPlNavWizardStepOptions, TPlNavWizardOrientation, TPlNavWizardStepValidateFn} from './navwizard.interface';
import {isArray, isBoolean, isEmpty, isFunction, isString} from '../common/utilities/utilities';
import {PlCompsService} from '../common/service/comps.service';
import {PlLocaleService} from '../common/locale/locale.service';
import {PlNavWizardComponent} from './navwizard.component';
import {PlNavWizardStepCaptionDirective} from './navwizard.step.caption.directive';
import {PlNavWizardStepContentDirective} from './navwizard.step.content.directive';

const DEFAULT_STEP_ID = 'pl-nav-wizard-step';
let stepId = 0;

@Directive({
  selector: 'pl-nav-wizard-step'
})
export class PlNavWizardStepDirective implements OnInit, OnChanges, OnDestroy, AfterContentChecked, IPlNavWizardStep {
  @Input() public stepId: string;
  @Input() public caption: string;
  @Input() public captionBtnGoBack: string;
  @Input() public captionBtnGoForward: string;
  @Input() public captionBtnFinalize: string;
  @Input() public complete: boolean;
  @Input() public disabled: boolean;
  @Input() public hideFooter: boolean;
  @Input() public hidePrevious: boolean;
  @Input() public hideNext: boolean;
  @Input() public hideFinalize: boolean;
  @Input() public icon: string;
  @Input() public required: boolean;
  @Input() public valid: boolean;
  @Input() public validator: TPlNavWizardStepValidateFn;
  @Input() public visible: boolean;
  @Input() public actions: Array<IPlNavWizardStepAction>;
  @Input() public responsiveTheme: IPlCompsResponsiveTheme;
  @Input() public formGroup: FormGroupDirective;
  @Input() public properties: IPlNavWizardStepOptions;
  @Output() public readonly completeChange: EventEmitter<boolean>;
  @Output() public readonly validChange: EventEmitter<boolean>;

  public templateCaption: PlNavWizardStepCaptionDirective;
  public templateContent: PlNavWizardStepContentDirective;
  public visited: boolean;
  public footerOffset: string;
  public options: IPlNavWizardStepOptions;

  @ContentChildren(PlNavWizardStepCaptionDirective, {descendants: false}) private readonly _templateTitle: QueryList<PlNavWizardStepCaptionDirective>;
  @ContentChildren(PlNavWizardStepContentDirective, {descendants: false}) private readonly _templateContent: QueryList<PlNavWizardStepContentDirective>;
  private readonly _subscriptionLocale: Subscription;
  private readonly _subscriptionConfig: Subscription;
  private _defaultOptions: Partial<IPlNavWizardStepOptions>;
  private _configOrientation: TPlNavWizardOrientation;
  private _locale: IPlLocale;

  constructor(
    public readonly plNavWizardComponent: PlNavWizardComponent,
    private readonly _plLocaleService: PlLocaleService,
    private readonly _plCompsService: PlCompsService
  ) {
    this.completeChange = new EventEmitter<boolean>();
    this.validChange = new EventEmitter<boolean>();
    this._defaultOptions = Object.freeze({
      footerOffset: undefined
    });
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this._locale = locale;
    });
    this._subscriptionConfig = this._plCompsService.config().subscribe((config: IPlCompsServiceConfig) => {
      this._configOrientation = config.plEditForm.orientation;
      this._defaultOptions = Object.freeze({
        footerOffset: this._configOrientation === 'horizontal' ? 'offset-sm-2' : undefined
      });
    });
  }

  public ngOnInit(): void {
    this.stepId = !isEmpty(this.stepId) ? this.stepId : `${DEFAULT_STEP_ID}-${stepId++}`;
    if (isArray(this.actions)) {
      this.actions = orderBy(this.actions, 'order');
    }
    this._handleChanges();
    this.plNavWizardComponent.addStep(this);
  }

  public ngOnChanges({
    properties,
    caption,
    captionBtnFinalize,
    captionBtnGoBack,
    captionBtnGoForward,
    complete,
    disabled,
    footerOffset,
    hideFooter,
    hidePrevious,
    hideNext,
    hideFinalize,
    icon,
    required,
    valid,
    validator,
    visible
  }: SimpleChanges): void {
    if (properties && !properties.isFirstChange()) {
      this._changedProperties(properties.currentValue);
      this._handleChanges();
      return;
    }
    if (caption && !caption.isFirstChange()) {
      this._changedCaption(caption.currentValue);
    }
    if (captionBtnFinalize && !captionBtnFinalize.isFirstChange()) {
      this._changedCaptionBtnFinalize(captionBtnFinalize.currentValue);
    }
    if (captionBtnGoBack && !captionBtnGoBack.isFirstChange()) {
      this._changedCaptionBtnGoBack(captionBtnGoBack.currentValue);
    }
    if (captionBtnGoForward && !captionBtnGoForward.isFirstChange()) {
      this._changedCaptionBtnGoForward(captionBtnGoForward.currentValue);
    }
    if (complete && !complete.isFirstChange()) {
      this._changedComplete(complete.currentValue);
    }
    if (disabled && !disabled.isFirstChange()) {
      this._changedDisabled(disabled.currentValue);
    }
    if (footerOffset && !footerOffset.isFirstChange()) {
      this._changedFooterOffset(footerOffset.currentValue);
    }
    if (hideFooter && !hideFooter.isFirstChange()) {
      this._changedHideFooter(hideFooter.currentValue);
    }
    if (hidePrevious && !hidePrevious.isFirstChange()) {
      this._changedHidePrevious(hidePrevious.currentValue);
    }
    if (hideNext && !hideNext.isFirstChange()) {
      this._changedHideNext(hideNext.currentValue);
    }
    if (hideFinalize && !hideFinalize.isFirstChange()) {
      this._changedHideFinalize(hideFinalize.currentValue);
    }
    if (icon && !icon.isFirstChange()) {
      this._changedIcon(icon.currentValue);
    }
    if (required && !required.isFirstChange()) {
      this._changedRequired(required.currentValue);
    }
    if (valid && !valid.isFirstChange()) {
      this._changedValid(valid.currentValue);
    }
    if (validator && !validator.isFirstChange()) {
      this._changedValidator(validator.currentValue);
    }
    if (visible && !visible.isFirstChange()) {
      this._changedVisible(visible.currentValue);
    }
  }

  public ngAfterContentChecked(): void {
    this.templateCaption = this._templateTitle.first;
    this.templateContent = this._templateContent.first;
  }

  public ngOnDestroy(): void {
    this._subscriptionLocale.unsubscribe();
    this._subscriptionConfig.unsubscribe();
  }

  public setComplete(): void {
    this.setCompletion(true);
  }

  public setIncomplete(): void {
    this.setCompletion(false);
    this.setInvalid();
  }

  public setCompletion(value: boolean): void {
    this.complete = value;
    this.completeChange.emit(this.complete);
  }

  public setValid(): void {
    this.setValidation(true);
  }

  public setInvalid(): void {
    this.setValidation(false);
  }

  public setValidation(value: boolean): void {
    this.valid = value;
    this.validChange.emit(this.valid);
  }

  public setOrientation(orientation: TPlNavWizardOrientation): void {
    let css = '';
    switch (orientation) {
      case 'horizontal':
        css = this.footerOffset;
        break;
      case 'vertical':
        css = '';
        break;
      default:
        return;
    }
    this.footerOffset = css;
  }

  public updateComponent(properties: IPlNavWizardStepOptions): void {
    if (properties && properties !== this.options) {
      this._changedProperties(properties);
    }
  }

  private _handleChanges(): void {
    this._changedProperties();
    this._changedCaption();
    this._changedCaptionBtnFinalize();
    this._changedCaptionBtnGoBack();
    this._changedCaptionBtnGoForward();
    this._changedComplete();
    this._changedDisabled();
    this._changedFooterOffset();
    this._changedHideFooter();
    this._changedHidePrevious();
    this._changedHideNext();
    this._changedHideFinalize();
    this._changedIcon();
    this._changedRequired();
    this._changedValid();
    this._changedValidator();
    this._changedVisible();
  }

  private _changedProperties(value: IPlNavWizardStepOptions = this.properties): void {
    this.options = merge<object, Partial<IPlNavWizardStepOptions>, IPlNavWizardStepOptions, IPlNavWizardStepOptions>({}, this._defaultOptions, this.options, value);
  }

  private _changedCaption(value: string = this.caption): void {
    this.caption = value || this.options.caption || '';
  }

  private _changedCaptionBtnGoBack(value: string = this.captionBtnGoBack): void {
    this.captionBtnGoBack = value || this.options.captionBtnGoBack || this._locale.btn.goBack;
  }

  private _changedCaptionBtnGoForward(value: string = this.captionBtnGoForward): void {
    this.captionBtnGoForward = value || this.options.captionBtnGoForward || this._locale.btn.goForward;
  }

  private _changedCaptionBtnFinalize(value: string = this.captionBtnFinalize): void {
    this.captionBtnFinalize = value || this.options.captionBtnFinalize || this._locale.btn.finalize;
  }

  private _changedComplete(value: boolean = this.complete): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.complete;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.complete = val;
  }

  private _changedDisabled(value: boolean = this.disabled): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.disabled;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.disabled = val;
  }

  private _changedFooterOffset(value: string = this.footerOffset): void {
    let val: string = value;
    if (!isString(val)) {
      val = this.options.footerOffset;
    }
    if (!isString(val)) {
      val = '';
    }
    this.footerOffset = val;
  }

  private _changedHideFooter(value: boolean = this.hideFooter): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.hideFooter;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.hideFooter = val;
  }

  private _changedHidePrevious(value: boolean = this.hidePrevious): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.hidePrevious;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.hidePrevious = val;
  }

  private _changedHideNext(value: boolean = this.hideNext): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.hideNext;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.hideNext = val;
  }

  private _changedHideFinalize(value: boolean = this.hideFinalize): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.hideFinalize;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.hideFinalize = val;
  }

  private _changedIcon(value: string = this.icon): void {
    this.icon = value || this.options.icon || '';
  }

  private _changedRequired(value: boolean = this.required): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.required;
    }
    if (!isBoolean(val)) {
      val = true;
    }
    this.required = val;
  }

  private _changedValid(value: boolean = this.valid): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.valid;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.valid = val;
  }

  private _changedValidator(value: TPlNavWizardStepValidateFn = this.validator): void {
    let val: TPlNavWizardStepValidateFn = value;
    if (!isFunction(val)) {
      val = this.options.validator;
    }
    if (!isFunction(val)) {
      val = undefined;
    }
    this.validator = val;
  }

  private _changedVisible(value: boolean = this.visible): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.visible;
    }
    if (!isBoolean(val)) {
      val = true;
    }
    this.visible = val;
  }
}
