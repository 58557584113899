import {flatMap} from 'lodash-es';
import {AfterContentChecked, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {debounce, FOCUSABLE_QUERY_SELECTOR, isDefined, isFunction, isNumber, isObject, isString, isUndefinedOrNull} from '../common/utilities/utilities';
import type {IPlNavigatorCallback, IPlNavigatorEventSelected, IPlNavigatorItem} from './navigator.interface';
import {isMobile as cgcIsMobile} from '../common/constants';

const DEBOUNCE_TIMEOUT = 50;
const DEBOUNCE_ID = 'pl-navigator';
const NAV_CONTENT_ITEM = 'pl-navigator-content-item';
const NAV_CONTENT_SUB_ITEM = 'pl-navigator-content-sub-item';
const KLASS_NAV_CONTENT_ITEM = `.${NAV_CONTENT_ITEM}`;
const KLASS_NAV_CONTENT_SUB_ITEM = `.${NAV_CONTENT_SUB_ITEM}`;
let nextId = 0;

@Component({
  selector: 'pl-navigator',
  templateUrl: './navigator.component.html'
})
export class PlNavigatorComponent implements OnInit, OnChanges, OnDestroy, AfterContentChecked {
  @Input() public contentMaxHeight: string | number;
  @Input() public disabled: boolean;
  @Input() public collapsible: boolean;
  @Input() public callback: IPlNavigatorCallback;
  @Output() public readonly activeIdChange: EventEmitter<any>;
  @Output() public readonly activeParentIdChange: EventEmitter<any>;
  @Output() public readonly evtSelected: EventEmitter<IPlNavigatorEventSelected>;

  public readonly isMobile: boolean;
  public readonly items: Array<IPlNavigatorItem>;
  public maxHeightNav: string;
  public maxHeight: string;
  public activeId: any;
  public activeParentId: any;
  public open: boolean;

  private readonly _element: HTMLElement;
  private _plNavigatorContent: HTMLElement;
  private _inInputElement: boolean;
  private _preventScrolled: boolean;
  private _preventBluredElement: boolean;

  constructor(private readonly _elementRef: ElementRef) {
    this.activeIdChange = new EventEmitter<any>();
    this.activeParentIdChange = new EventEmitter<any>();
    this.evtSelected = new EventEmitter<IPlNavigatorEventSelected>();
    this.isMobile = cgcIsMobile();
    this.items = [];
    this.disabled = false;
    this.collapsible = false;
    this.open = false;
    this._element = this._elementRef.nativeElement;
    this._plNavigatorContent = undefined;
    this._inInputElement = false;
    this._preventScrolled = false;
    this._preventBluredElement = false;
  }

  public ngOnInit(): void {
    this._handleChanges();
  }

  public ngOnChanges({contentMaxHeight, callback}: SimpleChanges): void {
    if (contentMaxHeight && !contentMaxHeight.isFirstChange()) {
      this._changedContentMaxHeight(contentMaxHeight.currentValue);
    }
    if (callback) {
      const cb: IPlNavigatorCallback = callback.currentValue;
      if (isObject(cb)) {
        cb.select = (itemId: unknown) => {
          this.select(itemId);
        };
        cb.has = (itemId: unknown) => this.has(itemId);
      }
    }
  }

  public ngOnDestroy(): void {
    this._cleanup();
  }

  public ngAfterContentChecked(): void {
    this.activeParentId = undefined;
    let activeItem: IPlNavigatorItem = this._getItem(this.activeId);
    if (!activeItem && this.items.length) {
      activeItem = this.items[0];
    }
    if (activeItem?.collapsible && activeItem.subItems.length) {
      activeItem = activeItem.subItems[0];
    }
    if (activeItem?.parentItem) {
      this.activeParentId = activeItem.parentItem.id;
    }
    this.activeId = activeItem ? activeItem.id : undefined;
  }

  public addItem(item: IPlNavigatorItem): void {
    if (!isNumber(item.order) || item.order < 0) {
      this.items.push(item);
    } else {
      for (let i = this.items.length - 1; i >= 0; i--) {
        const currentSubItem = this.items[i];
        if (item.order >= currentSubItem.order) {
          this.items.splice(i + 1, 0, item);
          return;
        }
      }
      this.items.push(item);
    }
  }

  public removeItem(item: unknown | IPlNavigatorItem): void {
    const id: unknown = isObject(<IPlNavigatorItem>item) ? (<IPlNavigatorItem>item).id : item;
    const index: number = this.items.findIndex((value: IPlNavigatorItem) => value.id === id);
    if (index !== -1) {
      this.items.splice(1, 0);
    }
  }

  public select(itemId: unknown): void {
    if (this.disabled) {
      return;
    }
    this._preventBluredElement = true;
    const selectedItem: IPlNavigatorItem = this._getItem(itemId);
    if (selectedItem) {
      if (selectedItem.collapsible) {
        selectedItem.collapsed = !selectedItem.collapsed;
      } else if (!selectedItem.disabled && this.activeId !== selectedItem.id) {
        let defaultPrevented = false;
        this.evtSelected.emit({
          activeId: this.activeId,
          nextId: selectedItem.id,
          preventDefault: () => {
            defaultPrevented = true;
          }
        });
        if (!defaultPrevented) {
          this.activeId = selectedItem.id;
          this.activeIdChange.emit(this.activeId);
          const contentElement: HTMLElement = this._element.querySelector<HTMLElement>(`#${NAV_CONTENT_SUB_ITEM}-${String(itemId)}, #${NAV_CONTENT_ITEM}-${String(itemId)}`);
          if (contentElement) {
            this._preventScrolled = true;
            this._scrollToElement(contentElement);
            const toFocus: HTMLElement = contentElement.querySelector<HTMLElement>(FOCUSABLE_QUERY_SELECTOR);
            if (toFocus && isFunction(toFocus.focus)) {
              toFocus.focus({preventScroll: true});
            }
          }
          this.closeNav();
        }
      }
    }
  }

  public has(tabId: unknown): boolean {
    const tab: IPlNavigatorItem = this._getItem(tabId);
    return isDefined(tab);
  }

  public focusedElement(item: IPlNavigatorItem, event: FocusEvent, parentItem?: IPlNavigatorItem): void {
    if (parentItem) {
      event.stopImmediatePropagation();
      this.activeParentId = parentItem.id;
      this.activeParentIdChange.emit(this.activeParentId);
    }
    this.activeId = item.id;
    this.activeIdChange.emit(this.activeId);
    this._inInputElement = true;
  }

  public bluredElement(): void {
    this._inInputElement = false;
    if (!this._preventBluredElement) {
      this._calculateActiveId();
    } else {
      this._preventBluredElement = false;
    }
  }

  public openNav(): void {
    this.open = true;
  }

  public closeNav(): void {
    this.open = false;
  }

  @ViewChild('plNavigatorContent')
  public set plNavigatorContent(value: ElementRef<HTMLElement>) {
    this._cleanup();
    this._plNavigatorContent = value?.nativeElement;
    if (this._plNavigatorContent) {
      this._plNavigatorContent.addEventListener<'scroll'>('scroll', this._fnScrolled, {passive: true});
    }
  }

  private _handleChanges(): void {
    this._changedContentMaxHeight();
  }

  private _changedContentMaxHeight(value: string | number = this.contentMaxHeight): void {
    this.maxHeight = isUndefinedOrNull(value) ? '' : isString(value) ? value : `${value}px`;
    if (this.isMobile) {
      this.maxHeightNav = this.maxHeight;
    }
  }

  private _cleanup(): void {
    if (this._plNavigatorContent) {
      this._plNavigatorContent.removeEventListener<'scroll'>('scroll', this._fnScrolled);
    }
  }

  private _getItem(id: unknown): IPlNavigatorItem {
    for (const item of this.items) {
      if (item.id === id) {
        return item;
      }
      if (item.subItems.length) {
        for (const subItem of item.subItems) {
          if (subItem.id === id) {
            return subItem;
          }
        }
      }
    }
    return undefined;
  }

  private _scrolled(): void {
    if (!this._preventScrolled) {
      debounce(
        () => {
          this._calculateActiveId();
        },
        DEBOUNCE_TIMEOUT,
        `${DEBOUNCE_ID}-${nextId++}`
      );
    } else {
      this._preventScrolled = false;
    }
  }

  private _calculateActiveId(): void {
    if (this._inInputElement || !this.items.length || !this._plNavigatorContent) {
      return;
    }
    if (!this._plNavigatorContent.scrollTop) {
      this.activeId = this.items[0].id;
      this.activeIdChange.emit(this.activeId);
    } else {
      const contentElements: NodeListOf<HTMLElement> = this._plNavigatorContent.querySelectorAll<HTMLElement>(`${KLASS_NAV_CONTENT_SUB_ITEM}, ${KLASS_NAV_CONTENT_ITEM}`);
      if (!contentElements) {
        return;
      }
      const items: Array<IPlNavigatorItem> = flatMap<IPlNavigatorItem, IPlNavigatorItem>(this.items, (item: IPlNavigatorItem) => {
        return !item.subItems && !item.subItems.length ? [item] : [item].concat(item.subItems);
      });
      const scrollTop: number = Math.ceil(this._plNavigatorContent.scrollTop + contentElements.item(0).offsetTop);
      for (let i = 0; i < items.length; i++) {
        const itemElement: HTMLElement = contentElements.item(i);
        const nextItemElement: HTMLElement = i < items.length ? contentElements.item(i + 1) : undefined;
        if (scrollTop >= itemElement.offsetTop && (!nextItemElement || scrollTop < nextItemElement.offsetTop)) {
          this.activeId = items[i].id;
          this.activeIdChange.emit(this.activeId);
          return;
        }
      }
    }
  }

  private _scrollToElement(element: HTMLElement): void {
    if (this._plNavigatorContent) {
      this._plNavigatorContent.scrollTop = element.offsetTop;
    }
  }

  private readonly _fnScrolled: () => void = () => {
    this._scrolled();
  };
}
