import CustomStore from 'devextreme/data/custom_store';
import {Component, Injector} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {AccountService} from '../../../../services/account/account.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';
import {IDevExpressDataGridEventOnEditorPreparing, IDevExpressDataGridEventOnRowClick} from '../../../devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonErpUser, TUserSession} from '../../../../services/account/jsonUserApi.interface';

@Component({
  selector: 'portal-changefirm-modal',
  templateUrl: './portal.changefirm.modal.component.html'
})
export class PortalChangeFirmModalComponent extends CGModalComponent<TUserSession> {
  public readonly dataGrid: IDevExpressDataGrid;

  private _searchPanelEditor: HTMLElement;
  private _promise: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _accountService: AccountService,
    private readonly _authService: AuthService
  ) {
    super(_injector);
    this.dataGrid = {
      columns: [
        {dataField: 'cgID', dataType: 'number', visible: false, showInColumnChooser: false},
        {dataField: 'nEmpresa', dataType: 'string', caption: 'changefirm.fields.nEmpresa', width: 100, sortOrder: 'asc'},
        {dataField: 'nomeEmpresa', dataType: 'string', caption: 'changefirm.fields.nomeEmpresa'}
      ],
      dataSource: new CustomStore({
        key: 'cgID',
        load: () => this._source()
      }),
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true,
      searchPanel: {visible: true},
      groupPanel: {
        allowColumnDragging: false,
        visible: false
      }
    };
  }

  public onContentReady(): void {
    if (this._searchPanelEditor) {
      const input: HTMLElement = this._searchPanelEditor.querySelector('.dx-texteditor-input-container .dx-texteditor-input');
      if (input) {
        setTimeout(() => {
          input.focus();
        });
      }
    }
  }

  public onEditorPreparing(event: IDevExpressDataGridEventOnEditorPreparing): void {
    if (event.parentType === 'searchPanel') {
      this._searchPanelEditor = event.editorElement;
    }
  }

  public onDataGridRowClick(event: IDevExpressDataGridEventOnRowClick<IJsonErpUser>): void {
    if (this._promise) {
      return;
    }
    this.disableClose();
    event.component.beginCustomLoading(undefined);
    this._promise = this._authService
      .changeEmpresa({cgId: event.data.cgID, nEmpresa: event.data.nEmpresa})
      .then((response: TUserSession) => {
        this.enableClose();
        this.close(response);
      })
      .catch((reason: HttpErrorResponse) => {
        this._logger.error(reason);
        this.enableClose();
      })
      .finally(() => {
        this._promise = undefined;
        event.component.endCustomLoading();
      });
  }

  private _source(): Promise<Array<IJsonErpUser>> {
    return this._accountService.getMe().then((response: HttpResponse<TUserSession>) => response.body.erps);
  }
}
