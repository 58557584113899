import {DecimalPipe} from '@angular/common';
import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {PlI18nService} from '../i18n/i18n.service';

@Pipe({
  name: 'cgcDecimal'
})
export class CGCDecimalPipe extends DecimalPipe implements PipeTransform {
  constructor(
    private readonly _plI18nService: PlI18nService,
    @Inject(LOCALE_ID) locale: string
  ) {
    super(locale);
  }

  public transform(value: number | string, digitsInfo?: string | number, locale?: string): string | null;
  public transform(value: null | undefined, digitsInfo?: string | number, locale?: string): null;
  public transform(value: number | string | null | undefined, digitsInfo?: string | number, locale?: string): string | null {
    return this._plI18nService.formatNumber(value, digitsInfo, locale);
  }
}
