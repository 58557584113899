<div class="extratosdt-module">
  <extratosdt
    [moduleType]="moduleType"
    [moduleName]="moduleName"
    [empresaTemContabilidadeDigital]="empresaTemContabilidadeDigital"
    [empresaAnoEmCursoIRC]="empresaAnoEmCursoIRC"
    [params]="params"
    [callback]="callback"
    [keyDownAction]="fnKeydownProcessar"
    (evtPesquisar)="pesquisarChanged($event)">
  </extratosdt>
</div>
