<div class="new-previ-tes-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'prevites.automatica.datatitle'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'prevites.automatica.data'"></label>
      <edit>
        <pl-edit type="date" attrName="dataTesDe" [(model)]="dataTesouraria"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-sm btn-success action-submit" id="save" [onClick]="close"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.apply'"></span> </pl-button>
    <pl-button klass="btn-sm btn-light" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
  </div>
</div>
