<div class="modal-integracao-salarios-simulador">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="modalTitle"></h5>
  </div>

  <div class="modal-body">
    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinition"
      cgDxDataGridInstanceName="integSalariosSimulator"
      [dataSource]="dataGridDefinition.dataSource"
      (onInitialized)="onInitialized($event)"
      (onCellPrepared)="onCellPrepared($event)">
      <div *dxTemplate="let item of 'toolbarTemplateSimulator'">
        <pl-group>
          <edit>
            <pl-edit type="boolean" [model]="onlyErrors" (modelChange)="changedOnlyErrors($event)" [properties]="{label: 'integracaoSalarios.onlyErrorsLabel'}"> </pl-edit>
          </edit>
        </pl-group>
      </div>
      <div *dxTemplate="let item of 'cellTemplateSimulator'">
        <i [ngClass]="item.data.temErroUI" aria-hidden="true"></i>
      </div>
      <div *dxTemplate="let item of 'masterDetailSimulator'">
        <pl-alert type="error" *ngIf="item.data.temErro">
          <span [translate]="item.data.erro"></span>
        </pl-alert>
      </div>
    </dx-data-grid>

    <hr />

    <div class="row">
      <div class="col-md-12 totals">
        <label [translate]="'integracaoSalarios.totalDebitoLabel'"></label>
        <div class="badge bg-primary">{{ totalDebito | cgcCurrency }}</div>
        <div class="sep"></div>
        <label [translate]="'integracaoSalarios.totalCreditoLabel'"></label>
        <div class="badge bg-primary">{{ totalCredito | cgcCurrency }}</div>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-close"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</div>
