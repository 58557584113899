import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {EDelphiNumberTypes, isDefinedNotNull, isEmpty, isUndefined, isUndefinedOrNull, PlAlertService, PlTranslateService, TPlTableItem, TPlToolbarItem} from 'pl-comps-angular';
import {AuthService} from '../../../../services/auth/auth.service';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {
  EAPIJOBRHINTEGRACAOSALARIOSNOTFOUND,
  EIntegracaoSalariosOperation,
  EIntegracaoSalariosStatus,
  ETTipodeIntegracao,
  IIntegracaoSalariosConfig,
  IIntegracaoSalariosProc,
  IIntegracaoSalariosProcessData,
  IIntegracaoSalariosProcessResult,
  IIntegracaoSalariosSearchModel,
  IIntegracaoSalariosStatus
} from '../integracaoSalarios.module.interface';
import {IJsonRHTipoProcessamento} from '../../../../entities/rhTiposProcessamento/jsonRhTiposProcessamento.entity.interface';
import {IntegracaoSalariosConfigModalComponent} from '../modals/config/integracaoSalarios.config.modal.component';
import {IntegracaoSalariosProcessModalComponent} from '../modals/process/integracaoSalarios.process.modal.component';
import {IntegracaoSalariosService} from '../integracaoSalarios.module.service';
import {IntegracaoSalariosSimuladorModalComponent} from '../modals/simulador/integracaoSalarios.simulador.modal.component';
import {ModuloComponent} from '../../../../components/module/module.component';
import {TUserSession} from '../../../../services/account/jsonUserApi.interface';
import {IntegracaoSalariosErrorsModalComponent} from '../modals/errors/integracaoSalarios.errors.modal.component';
import {IntegracaoSalariosErrorPromptModalComponent} from '../modals/errorprompt/integracaoSalarios.error.prompt.modal.component';
import moment from 'moment';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnCellPrepared, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import CustomStore from 'devextreme/data/custom_store';
import {HttpErrorResponse} from '@angular/common/http';
import {ICGExceptionError} from '../../../../components/exceptions/exceptions.service.interface';
import {EStatusCode} from '../../../../../config/constants';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';

const INTERVAL_TIMEOUT = 2000;
const TAB_ID_INTEGRAR = 'integrar-tab-id';
const TAB_ID_REMOVER = 'remover-tab-id';
const TOOLBAR_INSTANCE_ID = 'integracao-salarios-toolbar';
const TOOLBAR_INSTANCE_REMOVER_INTEG_ID = 'integracao-salarios-remover-toolbar';
const SIMULATOR_EXCEPTION_CLASS_TYPE = 'EAPIJobRHIntegracaoSalariosSimulador';

@Component({
  selector: 'integracaoSalarios',
  templateUrl: './integracaoSalarios.module.component.html'
})
export class IntegracaoSalariosComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly dataGridDefinitionPorIntegrar: IDevExpressDataGrid<IIntegracaoSalariosProc, number>;
  public readonly dataGridDefinitionRemoverInteg: IDevExpressDataGrid<IIntegracaoSalariosProc, number>;
  public readonly tTipoDeIntegracao: typeof ETTipodeIntegracao;
  public readonly tipoProcessamentoOutput: string;
  public readonly tabIdIntegrar: string;
  public readonly tabIdRemover: string;

  public selectedRowKeysPorIntegrar: Array<number>;
  public selectedRowKeysRemoverInteg: Array<number>;
  public mainPromise: Promise<void>;
  public currentStatus: IIntegracaoSalariosStatus;
  public sRemoverIntegModel: IIntegracaoSalariosSearchModel;
  public sPorIntegModel: IIntegracaoSalariosSearchModel;
  public config: IIntegracaoSalariosConfig;
  public toolbarPorIntegrarInstId: string;
  public toolbarRemoverIntegInstId: string;
  public isBlocked: boolean;

  private readonly _toolbarConfig: TPlToolbarItem;
  private _integSalariosPorIntegrarTableSource: Array<IIntegracaoSalariosProc>;
  private _integSalariosRemoverIntegTableSource: Array<IIntegracaoSalariosProc>;
  private _dataGridInstancePorIntegrar: dxDataGrid<IIntegracaoSalariosProc, number>;
  private _dataGridInstanceRemoverInteg: dxDataGrid<IIntegracaoSalariosProc, number>;
  private _intervalId: number;
  private _currEmpresa: string;
  private _currNomeEmpresa: string;
  private _processModalRef: NgbModalRef;
  private _errorModalRef: NgbModalRef;
  private _isSimulator: boolean;
  private _timeoutModalRef: NgbModalRef;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _integracaoSalariosService: IntegracaoSalariosService,
    private readonly _authService: AuthService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _cgExceptionService: CGExceptionService
  ) {
    super(_injector);
    this.tipoProcessamentoOutput = '{{tipoProcessamento}} - {{descricao}}';
    this.tTipoDeIntegracao = ETTipodeIntegracao;
    this.tabIdIntegrar = TAB_ID_INTEGRAR;
    this.tabIdRemover = TAB_ID_REMOVER;
    this.config = {
      codEstado: '',
      descricao: '',
      nContaSegSocialSaldoExcedente: '',
      nContaSegSocialSaldoInsuficiente: '',
      nDescritivo: 0,
      nDiario: 0,
      nDocExterno: '',
      tipoDeIntegracao: 0,
      nDiarioDesc: '',
      nDescritivoDesc: '',
      nContaSegSocialSaldoInsuficienteDesc: '',
      nContaSegSocialSaldoExcedenteDesc: '',
      nContaCovid19ApoioFamilia: '',
      nContaCovid19ApoioFamiliaDesc: '',
      nContaCovid19LayoffSimplSuspencao: '',
      nContaCovid19LayoffSimplSuspencaoDesc: '',
      nContaCovid19LayoffSimplParcial: '',
      nContaCovid19LayoffSimplParcialDesc: '',
      nContaCovid19ApoioFamiliaPerc: 0,
      nContaCovid19LayoffSimplSuspencaoPerc: 0,
      nContaCovid19LayoffSimplParcialPerc: 0,
      codEstadoDesc: '',
      errorList: []
    };
    this._toolbarConfig = {
      id: 'tb-config-item',
      type: 'button',
      class: 'btn-light',
      iconLeft: '<i class="fa fa-cog"></i>&nbsp;',
      caption: 'integracaoSalarios.config',
      click: () => this._showConfigModal()
    };
    this._plToolbarService.registerInstance(this.toolbarPorIntegrarInstId, {
      items: [this._toolbarConfig]
    });
    this._plToolbarService.registerInstance(this.toolbarRemoverIntegInstId, {
      items: [this._toolbarConfig]
    });
    this.sPorIntegModel = {
      todosProcs: true,
      tipoProce: undefined,
      codEmpDe: 1,
      codEmpAte: EDelphiNumberTypes.MaxSmallInt,
      dataDe: moment().startOf('month'),
      dataAte: moment().endOf('month')
    };
    this.sRemoverIntegModel = {
      todosProcs: true,
      tipoProce: undefined,
      codEmpDe: 1,
      codEmpAte: EDelphiNumberTypes.MaxSmallInt,
      dataDe: moment().startOf('month'),
      dataAte: moment().endOf('month')
    };
    this.selectedRowKeysPorIntegrar = [];
    this.selectedRowKeysRemoverInteg = [];
    this._integSalariosPorIntegrarTableSource = [];
    this._integSalariosRemoverIntegTableSource = [];
    this.dataGridDefinitionPorIntegrar = this._setDataGridDefinition('porInteg');
    this.dataGridDefinitionRemoverInteg = this._setDataGridDefinition('removeInteg');
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.mainPromise = (async () => {
      const session: TUserSession = await this._authService.identity();
      this._currEmpresa = session.erp.nEmpresa;
      this._currNomeEmpresa = session.erp.nomeEmpresa;

      this.config = await this._integracaoSalariosService.getConfig(this._currEmpresa);

      const status: IIntegracaoSalariosStatus = await this._integracaoSalariosService.getJobStatus();
      this.currentStatus = status;

      if (status.state === EIntegracaoSalariosStatus.Timeout) {
        this._showTimeoutModal();
      } else if (status.state === EIntegracaoSalariosStatus.Error) {
        this._handleStateError(status);
      } else if (status.state === EIntegracaoSalariosStatus.Ended) {
        if (status.currentOperation === EIntegracaoSalariosOperation.Integ) {
          this._integracaoSalariosService
            .getProcessResults(this._currEmpresa)
            .then((results) => {
              const item = results.find((obj) => obj.temErro);
              if (item) {
                this._showSimulatorResults(results);
              } else {
                this._cgModalService.showOkCancel('integracaoSalarios.processDoneModalTitle', 'integracaoSalarios.processDoneModalMsg', {
                  size: 'md',
                  showCancelBtn: false,
                  backdrop: 'static',
                  showCloseBtn: false,
                  keyboard: false
                });
              }
            })
            .catch((reason) => {
              this._handleApiError(reason);
            });
        }
      } else if (status.state === EIntegracaoSalariosStatus.Started) {
        this.isBlocked = status.userStartedId !== this.session.userId;
        if (!this.isBlocked) {
          this._showProcessModal();
        }
      }
    })();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    clearTimeout(this._intervalId);
    this._plToolbarService.unRegisterInstance(this.toolbarRemoverIntegInstId);
    this._plToolbarService.unRegisterInstance(this.toolbarPorIntegrarInstId);
    this._integracaoSalariosService.stopJob();
  }

  public setInstanceName(value: string): void {
    super.setInstanceName(value);
    this.toolbarPorIntegrarInstId = `${this.instanceName}-${TOOLBAR_INSTANCE_ID}`;
    this.toolbarRemoverIntegInstId = `${this.instanceName}-${TOOLBAR_INSTANCE_REMOVER_INTEG_ID}`;
  }

  public getTabIntegrarCaption(): string {
    return this.config.tipoDeIntegracao === ETTipodeIntegracao.PorEmpregado ? 'integracaoSalarios.tabIntegrarPorEmpregadoCaption' : 'integracaoSalarios.tabIntegrarPorProcessamentoCaption';
  }

  public tipoProcessamentoChange(data: IJsonRHTipoProcessamento, fromRemoveInteg: boolean): void {
    if (!fromRemoveInteg) {
      this.sPorIntegModel.tipoProce = !isUndefinedOrNull(data) ? data.tipoProcessamento : undefined;
    } else {
      this.sRemoverIntegModel.tipoProce = !isUndefinedOrNull(data) ? data.tipoProcessamento : undefined;
    }
  }

  public getMainTitle(): string {
    return this.config.tipoDeIntegracao === ETTipodeIntegracao.PorEmpregado ? 'integracaoSalarios.integrarPorEmpregadoMainTitle' : 'integracaoSalarios.integrarPorProcessamentoMainTitle';
  }

  public porIntegTodosProcsChange(value: boolean): void {
    this.sPorIntegModel.todosProcs = value;
    if (value) {
      this.sPorIntegModel = {...this.sPorIntegModel, tipoProce: undefined, descricaoTipoProc: undefined};
    }
  }

  public removerIntegTodosProcsChange(value: boolean): void {
    this.sRemoverIntegModel.todosProcs = value;
    if (value) {
      this.sRemoverIntegModel.tipoProce = undefined;
    }
  }

  public searchPorInteg(): Promise<void> {
    const tipoProce = isUndefined(this.sPorIntegModel.tipoProce) ? 0 : this.sPorIntegModel.tipoProce;
    return this._integracaoSalariosService.loadProcs(this._currEmpresa, this.config.tipoDeIntegracao, tipoProce, this.sPorIntegModel.dataAte).then((response: Array<IIntegracaoSalariosProc>) => {
      this._integSalariosPorIntegrarTableSource = response;
      this._integSalariosPorIntegrarTableSource.forEach((item: TPlTableItem<IIntegracaoSalariosProc>) => {
        item.erroImage = item.erro.length ? 'fa fa-exclamation-triangle text-danger' : 'fa fa-check text-success';
      });
      this._updatePorIntegTable();
    });
  }

  public async execInteg(simula: boolean): Promise<void> {
    this._isSimulator = simula;
    const data: IIntegracaoSalariosProcessData = this._getDataForProc();
    if (!data.ids.length) {
      this._plAlertService.error(this._plTranslateService.translate('integracaoSalarios.temSelecionarRegistos'));
      return;
    }

    try {
      if (!simula) {
        await this._showConfigModal(true, false);
        data.config = {...this.config};
      }
      await this._integracaoSalariosService.process(this._currEmpresa, simula, data);
      this._showProcessModal();
    } catch (reason) {
      this._handleApiError(reason);
    }
  }

  public removeInteg(): Promise<void> {
    this._showProcessModalComponent();
    const removerInteg: Array<IIntegracaoSalariosProc> = [];
    for (const key of this.selectedRowKeysRemoverInteg) {
      removerInteg.push(this._integSalariosRemoverIntegTableSource[key]);
    }
    const selProcs: Array<string> = removerInteg.map((item: IIntegracaoSalariosProc) =>
      this.config.tipoDeIntegracao === ETTipodeIntegracao.PorProcessamento ? String(item.nProcessamento) : item.proceCabId
    );
    return this._integracaoSalariosService
      .removerIntegracao(this._currEmpresa, this.config.tipoDeIntegracao, selProcs)
      .then(() => {
        this._plAlertService.success(this._plTranslateService.translate('integracaoSalarios.integRemovidaComSucesso'));
        this._hideProcessModal();
        return this._searchRemoveInteg();
      })
      .catch((reason) => {
        this._handleApiError(reason);
      })
      .finally(() => {
        this._hideProcessModal();
      });
  }

  public mainTabChanged(): void {
    this._integSalariosPorIntegrarTableSource = [];
    this._integSalariosRemoverIntegTableSource = [];
    this._updateRemoveIntegTable();
    this._updatePorIntegTable();
  }

  public onInitializedPorIntegrar({component}: IDevExpressDataGridEventOnInitialized<IIntegracaoSalariosProc, number>): void {
    this._dataGridInstancePorIntegrar = component;
  }

  public onInitializedRemoverInteg({component}: IDevExpressDataGridEventOnInitialized<IIntegracaoSalariosProc, number>): void {
    this._dataGridInstanceRemoverInteg = component;
  }

  public onContentReady(): void {
    if (this._dataGridInstancePorIntegrar) {
      this._dataGridInstancePorIntegrar.columnOption('codEmp', 'visible', this.config.tipoDeIntegracao === ETTipodeIntegracao.PorEmpregado);
      this._dataGridInstancePorIntegrar.columnOption('nomeEmpregado', 'visible', this.config.tipoDeIntegracao === ETTipodeIntegracao.PorEmpregado);
    }

    if (this._dataGridInstanceRemoverInteg) {
      this._dataGridInstanceRemoverInteg.columnOption('codEmp', 'visible', this.config.tipoDeIntegracao === ETTipodeIntegracao.PorEmpregado);
      this._dataGridInstanceRemoverInteg.columnOption('nomeEmpregado', 'visible', this.config.tipoDeIntegracao === ETTipodeIntegracao.PorEmpregado);
    }
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IIntegracaoSalariosProc, number>): void {
    if (event.rowType === 'data') {
      if (event.column.command === 'expand' && isEmpty(event.data.erro)) {
        (<HTMLElement>event.cellElement.childNodes[0]).classList.remove('dx-datagrid-group-closed');
        event.cellElement.classList.remove('dx-datagrid-expand');
      }
    }
  }

  public readonly fnSearchRemoveInteg: () => Promise<void> = () => this._searchRemoveInteg();

  public readonly fnSearchPorInteg: () => Promise<void> = () => this.searchPorInteg();

  protected _onPageUnload(): void {
    super._onPageUnload();
    this._appService.sendBeacon(this._integracaoSalariosService.stopJobUrl());
  }

  private _searchRemoveInteg(): Promise<void> {
    const tipoProce = isUndefined(this.sRemoverIntegModel.tipoProce) ? 0 : this.sRemoverIntegModel.tipoProce;
    return this._integracaoSalariosService
      .loadProcsForRemove(
        this._currEmpresa,
        this.config.tipoDeIntegracao,
        tipoProce,
        this.sRemoverIntegModel.codEmpDe,
        this.sRemoverIntegModel.codEmpAte,
        this.sRemoverIntegModel.dataDe,
        this.sRemoverIntegModel.dataAte
      )
      .then((response: Array<IIntegracaoSalariosProc>) => {
        this._integSalariosRemoverIntegTableSource = response;
        this._integSalariosRemoverIntegTableSource.forEach((item: IIntegracaoSalariosProc) => {
          item.erroImage = item.erro.length ? 'fa fa-exclamation-triangle text-danger' : '';
        });
        this._updateRemoveIntegTable();
      });
  }

  private _showTimeoutModal(): void {
    if (isDefinedNotNull(this._timeoutModalRef)) {
      this._timeoutModalRef.close();
    }
    this._timeoutModalRef = this._cgModalService.showOkCancelVanilla('integracaoSalarios.jobTimeoutModalTitle', 'integracaoSalarios.jobTimeoutModalMessage', {
      size: 'md',
      showCancelBtn: false,
      backdrop: 'static',
      keyboard: false
    });
    this._timeoutModalRef.result.then(() => {
      this._integSalariosPorIntegrarTableSource = [];
      this._integSalariosRemoverIntegTableSource = [];
      this._updatePorIntegTable();
      this._updateRemoveIntegTable();
    });
  }

  private _handleStateError(state: IIntegracaoSalariosStatus): void {
    if (isDefinedNotNull(this._errorModalRef)) {
      this._errorModalRef.close();
    }

    if (this._isSimulator) {
      if (state.exceptionClassType === SIMULATOR_EXCEPTION_CLASS_TYPE) {
        this._errorModalRef = this._cgModalService.showVanilla(IntegracaoSalariosErrorPromptModalComponent, {size: 'md', backdrop: 'static', keyboard: false});
        const componentInstance: IntegracaoSalariosErrorPromptModalComponent = this._errorModalRef.componentInstance;
        componentInstance.nEmpresa = this._currEmpresa;
        componentInstance.nomeEmpresa = this._currNomeEmpresa;
        componentInstance.errorMessage = state.description;
      } else {
        this._errorModalRef = this._cgModalService.showOkCancelVanilla('integracaoSalarios.erroTitle', state.description, {
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          showCancelBtn: false
        });
      }
    } else {
      const msg = `${state.description}<br/>${this._plTranslateService.translate('integracaoSalarios.visualizarErros')}`;
      this._errorModalRef = this._cgModalService.showOkCancelVanilla('integracaoSalarios.erroTitle', msg, {
        size: 'md',
        backdrop: 'static',
        keyboard: false,
        showCancelBtn: true,
        btnOkText: 'global.btn.yes'
      });
    }

    this._errorModalRef.result.then(() => {
      if (!this._isSimulator) {
        const modalRef = this._cgModalService.showVanilla(IntegracaoSalariosErrorsModalComponent, {size: 'xl', backdrop: 'static', keyboard: false});
        const componentInstance: IntegracaoSalariosErrorsModalComponent = modalRef.componentInstance;
        componentInstance.nEmpresa = this._currEmpresa;
      }
    });
  }

  private _hideProcessModal(): void {
    if (this._processModalRef) {
      this._processModalRef.close();
    }
  }

  private _showSimulatorResults(source: Array<IIntegracaoSalariosProcessResult> = []): void {
    const modalRef = this._cgModalService.showVanilla(IntegracaoSalariosSimuladorModalComponent, {size: 'xxl'});
    const componentInstance: IntegracaoSalariosSimuladorModalComponent = modalRef.componentInstance;
    componentInstance.nEmpresa = this._currEmpresa;
    componentInstance.nomeEmpresa = this._currNomeEmpresa;
    componentInstance.source = source;
  }

  private _showProcessModal(): void {
    this._startProcessChecker();
    this._showProcessModalComponent();
  }

  private _showProcessModalComponent(): void {
    this._processModalRef = this._cgModalService.showVanilla(IntegracaoSalariosProcessModalComponent, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }

  private _showConfigModal(doIntegracao: boolean = false, clearTables: boolean = true): Promise<void> {
    const modalRef = this._cgModalService.showVanilla(IntegracaoSalariosConfigModalComponent, {size: 'md'});
    const componentInstance: IntegracaoSalariosConfigModalComponent = modalRef.componentInstance;
    componentInstance.nEmpresa = this._currEmpresa;
    componentInstance.configInput = this.config;
    componentInstance.showNDocExt = doIntegracao;
    return modalRef.result.then((newConfig: IIntegracaoSalariosConfig) => {
      this.config = !newConfig ? this.config : {...newConfig};
      if (clearTables) {
        this._integSalariosPorIntegrarTableSource = [];
        this._integSalariosRemoverIntegTableSource = [];
        this._updatePorIntegTable();
        this._updateRemoveIntegTable();
      }
    });
  }

  private _getJobStatusForChecker(): void {
    this._integracaoSalariosService.getJobStatus().then((response) => {
      this.currentStatus = response;
      if (response.state === EIntegracaoSalariosStatus.Timeout) {
        clearInterval(this._intervalId);
        this._hideProcessModal();
        this._showTimeoutModal();
      } else if (response.state === EIntegracaoSalariosStatus.Error) {
        clearInterval(this._intervalId);
        this._hideProcessModal();
        this._handleStateError(response);
      } else if (response.state === EIntegracaoSalariosStatus.Ended) {
        clearInterval(this._intervalId);
        this._hideProcessModal();
        if (this._isSimulator) {
          this._showSimulatorResults();
        } else {
          this._integracaoSalariosService
            .getProcessResults(this._currEmpresa)
            .then((results) => {
              const item = results.find((obj) => obj.temErro);
              if (item) {
                this._showSimulatorResults(results);
              } else {
                this._plAlertService.success(this._plTranslateService.translate('integracaoSalarios.processDoneModalMsg'));
                this.searchPorInteg();
              }
            })
            .catch((reason) => {
              this._handleApiError(reason);
            });
        }
      }
    });
  }

  private _startProcessChecker(): void {
    this._getJobStatusForChecker();
    this._intervalId = window.setInterval(() => {
      this._getJobStatusForChecker();
    }, INTERVAL_TIMEOUT);
  }

  private _getIntegSalariosPorIntegrarTableSource(): Array<IIntegracaoSalariosProc> {
    let index = 0;
    for (const item of this._integSalariosPorIntegrarTableSource) {
      item._index = index;
      index++;
    }
    return this._integSalariosPorIntegrarTableSource;
  }

  private _getIntegSalariosRemoverIntegTableSource(): Array<IIntegracaoSalariosProc> {
    let index = 0;
    for (const item of this._integSalariosRemoverIntegTableSource) {
      item._index = index;
      index++;
    }
    return this._integSalariosRemoverIntegTableSource;
  }

  private _updatePorIntegTable(): void {
    if (this._dataGridInstancePorIntegrar) {
      this._dataGridInstancePorIntegrar.refresh();
    }
  }

  private _updateRemoveIntegTable(): void {
    if (this._dataGridInstanceRemoverInteg) {
      this._dataGridInstanceRemoverInteg.refresh();
    }
  }

  private _getDataForProc(): IIntegracaoSalariosProcessData {
    const porIntegar: Array<IIntegracaoSalariosProc> = [];
    for (const key of this.selectedRowKeysPorIntegrar) {
      porIntegar.push(this._integSalariosPorIntegrarTableSource[key]);
    }
    return {
      config: this.config,
      ids: porIntegar.map((item: IIntegracaoSalariosProc) => {
        return this.config.tipoDeIntegracao !== ETTipodeIntegracao.PorEmpregado ? item.nProcessamento : item.proceCabId;
      })
    };
  }

  private _setDataGridDefinition(table: 'porInteg' | 'removeInteg'): IDevExpressDataGrid<IIntegracaoSalariosProc, number> {
    return {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'dataProcessamento', dataType: 'date', caption: 'integracaoSalarios.fields.dataProcessamento'},
        {dataField: 'nProcessamento', dataType: 'number', caption: 'integracaoSalarios.fields.nProcessamento'},
        {dataField: 'codEmp', dataType: 'number', caption: 'integracaoSalarios.fields.codEmp'},
        {dataField: 'nomeEmpregado', dataType: 'string', caption: 'integracaoSalarios.fields.nomeEmpregado'},
        {dataField: 'nomeProcessamento', dataType: 'string', caption: 'integracaoSalarios.fields.nomeProcessamento'},
        {dataField: 'tipoProcessamento', dataType: 'number', caption: 'integracaoSalarios.fields.tipoProcessamento'},
        {dataField: 'tipoProcessamentoDescricao', dataType: 'string', caption: 'integracaoSalarios.fields.tipoProcessamentoDescricao'},
        {dataField: 'totalVencAbonos', dataType: 'double', caption: 'integracaoSalarios.fields.totalVencAbonos'},
        {dataField: 'totalVencDescontos', dataType: 'double', caption: 'integracaoSalarios.fields.totalVencDescontos'},
        {dataField: 'totalEncargos', dataType: 'double', caption: 'integracaoSalarios.fields.totalEncargos'},
        {
          dataField: 'erroImage',
          dataType: 'string',
          caption: 'integracaoSalarios.fields.erro',
          alignment: 'center',
          cellTemplate: table === 'porInteg' ? 'cellTemplatePorIntegrarErro' : 'cellTemplateRemoverIntegErro'
        }
      ],
      dataSource: new CustomStore({
        key: '_index',
        load: () => (table === 'porInteg' ? this._getIntegSalariosPorIntegrarTableSource() : this._getIntegSalariosRemoverIntegTableSource())
      }),
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      masterDetail: {
        enabled: true,
        template: table === 'porInteg' ? 'masterDetailPorIntegrar' : 'masterDetailRemoverInteg'
      },
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: table === 'porInteg' ? 'toolbarTemplatePorIntegrar' : 'toolbarTemplateRemoverInteg',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
  }

  private _handleApiError(reason: HttpErrorResponse): void {
    if (isDefinedNotNull(reason)) {
      const exception: ICGExceptionError = this._cgExceptionService.get(reason);
      if (exception.status === EStatusCode.InternalServerError && exception.class === EAPIJOBRHINTEGRACAOSALARIOSNOTFOUND) {
        this._showTimeoutModal();
      } else {
        this._plAlertService.error(exception.message);
      }
    }
  }
}
