<div class="cg-contab-extratosgrid entity-detail-form" [plPromise]="promise">
  <config-options [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="instanceName"></config-options>

  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-xl-6">
            <pl-group>
              <label [translate]="'components.contabilidade.listagens.account'"></label>
              <edit>
                <entity-autocomplete
                  entity="pocs"
                  attrName="nConta"
                  [model]="conta"
                  (modelChange)="changedConta($event)"
                  (selectedKeyChange)="header.nConta = $event"
                  (evtSelectedDescriptionChanged)="header.nomeConta = $event"
                  [properties]="{validators: {required: {value: true}}}"
                  [filter]="filter"
                  [helperMode]="true"
                  [getItemConfig]="{params: {multiano: true}}"
                  plAutoFocus>
                  <div *inputGroup="{klass: 'btn-primary btn-xs', title: 'global.btn.previous' | translate, promise: promiseFindPreviousNConta}" (click)="findNextNConta(false)" data-capture-click>
                    <i class="fa fa-chevron-circle-left fa-fw" aria-hidden="true"></i>
                  </div>
                  <div *inputGroup="{klass: 'btn-primary btn-xs', title: 'global.btn.next' | translate, promise: promiseFindNextNConta}" (click)="findNextNConta(true)" data-capture-click>
                    <i class="fa fa-chevron-circle-right fa-fw" aria-hidden="true"></i>
                  </div>
                </entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-3 col-xl-3" *ngIf="optionShowDiario">
            <pl-group>
              <label [translate]="'components.contabilidade.listagens.fromdiary'"></label>
              <edit>
                <entity-autocomplete entity="diarios" [model]="header" (selectedKeyChange)="header.deNDiario = $event; deNDIarioChanged()" [fieldsMap]="{nDiario: 'deNDiario'}" [output]="'key'">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-3 col-xl-3" *ngIf="optionShowDiario">
            <pl-group>
              <label [translate]="'components.contabilidade.listagens.to'"></label>
              <edit>
                <entity-autocomplete
                  entity="diarios"
                  [model]="header"
                  [selectedKey]="header.ateNDiario"
                  (selectedKeyChange)="ateNDIarioChanged($event)"
                  [fieldsMap]="{nDiario: 'ateNDiario'}"
                  output="key">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-3 col-xl-3" *ngIf="showPeriodos">
            <pl-group>
              <label [translate]="'components.contabilidade.listagens.fromPeriod'"></label>
              <edit>
                <entity-autocomplete
                  entity="periodos"
                  attrName="dePeriodo"
                  [model]="header"
                  (selectedKeyChange)="changedDePeriodo($event)"
                  (evtSelectedDescriptionChanged)="header.dePeriodoNome = $event"
                  [filter]="'loadAllAnos=1' + header.dePeriodoAno"
                  (evtInputValueChanged)="header.dePeriodoAno = ''"
                  [fieldsMap]="{nome: 'dePeriodoNome', periodo: 'dePeriodo'}"
                  [output]="outputPeriodo"
                  outputDescription="nome"
                  [helperMode]="true">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-3 col-xl-3" *ngIf="showPeriodos">
            <pl-group>
              <label [translate]="'components.contabilidade.listagens.to'"></label>
              <edit>
                <entity-autocomplete
                  entity="periodos"
                  attrName="atePeriodo"
                  [model]="header"
                  (selectedKeyChange)="changedAtePeriodo($event)"
                  (evtSelectedDescriptionChanged)="header.atePeriodoNome = $event"
                  [filter]="'loadAllAnos=' + header.loadAllAnos + '&loadOnlyAno=' + header.loadOnlyAno"
                  [fieldsMap]="{nome: 'atePeriodoNome', periodo: 'atePeriodo'}"
                  [output]="outputPeriodo"
                  outputDescription="nome"
                  [helperMode]="true">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-xl-3 align-self-end">
            <pl-group>
              <edit>
                <pl-button
                  type="button"
                  klass="btn-sm btn-primary"
                  attrName="pesquisar"
                  id="btnPesquisar"
                  [disabled]="!header.nConta || !header.dePeriodo || !header.atePeriodo"
                  [onClick]="pesquisar"
                  data-focus>
                  <i class="fa fa-search fa-fw"></i>&nbsp;<span [translate]="'global.btn.search'"></span>
                </pl-button>
              </edit>
            </pl-group>
          </div>
        </div>
      </pl-form>
    </div>
  </cg-card-panel>

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="extratosgridmodule"
    [dataSource]="extratosGrid"
    (onInitialized)="onInitialized($event)"
    (onCellClick)="onCellClick($event)"
    (onOptionChanged)="onOptionChanged($event)"
    (cgDxDataGridStateLoad)="onDataGridStateLoad($event)">
    <div *dxTemplate="let item of 'detailTemplate'">
      <split-viewer-doccontab-digital
        [(docContabilidade)]="item.data._thedoc"
        [contabDigitalDoc]="item.data._contabDigitalDoc"
        [hideRightSide]="!empresaTemContabilidadeDigital || !item.data._contabDigitalDoc"
        [initialSizeLeft]="60"
        [showToolbar]="true"
        [maintenanceMode]="true"
        [readonlyDigital]="true"
        [highlightNLanc]="item.data.nLanc">
      </split-viewer-doccontab-digital>
    </div>
  </dx-data-grid>
</div>
