<div class="container">
  <div class="partner-millennium">
    <form [formGroup]="form" novalidate rel="noopener noreferrer">
      <div class="partner-millennium-logo">
        <img src="../../../../../assets/images/logo.webp" alt="CentralGest Logo" />
      </div>

      <label class="visually-hidden" for="username" [translate]="'global.form.username'"></label>
      <input
        type="text"
        id="username"
        class="form-control"
        autocapitalize="off"
        autocomplete="off"
        formControlName="username"
        [attr.placeholder]="'&#xF007;&nbsp;' + ('global.form.usernameemail.placeholder' | translate)"
        plAutoFocus />

      <label for="password" class="visually-hidden" [translate]="'login.form.password'"></label>
      <input
        type="password"
        id="password"
        class="form-control"
        autocapitalize="off"
        autocomplete="off"
        formControlName="password"
        [attr.placeholder]="'&#xF084;&nbsp;' + ('login.form.password.placeholder' | translate)" />

      <pl-recaptcha *ngIf="recaptchaActive && !form.disabled" formControlName="recaptcha"></pl-recaptcha>

      <div class="form-validation-error bg-danger" *ngIf="(form.invalid && submitted) || errorMessage">
        <div class="form-validation-error-icon">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        </div>
        <div class="form-validation-error-content">
          <ng-container *ngIf="form.invalid && submitted">
            <div *ngIf="hasError('username', 'required') || hasError('password', 'required')" [translate]="'partners.millennium.erros.invalidUsernameOrPassword'"></div>
            <div *ngIf="hasError('recaptcha')" [translate]="'partners.millennium.erros.invalidRecaptcha'"></div>
          </ng-container>
          <div *ngIf="errorMessage" [translate]="errorMessage"></div>
        </div>
      </div>
    </form>

    <div class="app-global-loader" *ngIf="globalLoading">
      <div class="app-global-loader-spinner">
        <div class="circle-spinner">
          <div class="spinner"></div>
        </div>
      </div>
    </div>
  </div>
</div>
