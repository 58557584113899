import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {IJsonDocContabilidade} from '../../../modules/portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {IContabDigitalGDocViewerRecolhaDoc} from '../../../services/contabilidadedigital/contabilidadedigital.interface';
import {isFunction, isObject} from 'pl-comps-angular';
import {ICGConfigurations} from '../../../services/config/config.service.interface';
import {Subscription} from 'rxjs';
import {ConfigService} from '../../../services/config/config.service';
import {IContabDigitalDocViewerRecolhaCallback} from '../../arquivodigital/contabilidade/docviewerrecolha/contabilidadedigital.docviewer.recolha.component.interface';

const INITIAL_SIZE_LEFT = 60;

@Component({
  selector: 'split-viewer-doccontab-digital',
  templateUrl: './splitViewerDocContabDigital.component.html'
})
export class SplitViewerDocContabDigitalComponent implements OnDestroy {
  @Input() public docContabilidade: IJsonDocContabilidade;
  @Input() public contabDigitalDoc: IContabDigitalGDocViewerRecolhaDoc;
  @Input() public hideRightSide: boolean;
  @Input() public showToolbar: boolean;
  @Input() public maintenanceMode: boolean;
  @Input() public readonlyDigital: boolean;
  @Input() public initialSizeLeft: number;
  @Input() public highlightNLanc: string;

  @Output() public readonly docContabilidadeChange: EventEmitter<IJsonDocContabilidade>;

  public callbackDocViewer: IContabDigitalDocViewerRecolhaCallback;
  public hasContabilidadeDigital: boolean;

  private readonly _subscriptionConfigurations: Subscription;

  constructor(private readonly _configService: ConfigService) {
    this.docContabilidadeChange = new EventEmitter<IJsonDocContabilidade>();
    this.initialSizeLeft = INITIAL_SIZE_LEFT;
    this.hideRightSide = false;
    this.showToolbar = true;
    this._subscriptionConfigurations = this._configService.configurationsAsObservable().subscribe((configurations: ICGConfigurations) => {
      this.hasContabilidadeDigital = configurations.empresa.temContabilidadeDigital;
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionConfigurations.unsubscribe();
  }

  public onResizerValuesChanged(): void {
    if (isObject(this.callbackDocViewer.pdf) && isFunction(this.callbackDocViewer.pdf.updateSize)) {
      setTimeout(this.callbackDocViewer.pdf.updateSize);
    }
  }

  public docContabilidadeChanged(docContab: IJsonDocContabilidade): void {
    this.docContabilidade = docContab;
    this.docContabilidadeChange.emit(this.docContabilidade);
  }
}
