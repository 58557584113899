import {firstValueFrom} from 'rxjs';
import {Component, Injector, OnInit} from '@angular/core';
import {IPlToolbarItem, isEmpty, toInteger} from 'pl-comps-angular';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {IListagemDiariosModel} from '../listagemDiarios.module.interface';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {ETipoContaContabilidade} from '../../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {ListagemDiariosService} from '../listagemDiarios.module.service';
import {maxDateCG, minDateCG} from '../../../../../../common/utils/utils';
import {IJsonPeriodo} from '../../../../../entities/periodos/jsonPeriodo.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IPeriodosEntityService} from '../../../../../entities/periodos/periodos.entity.interface';

const LENGTH_YEAR = 4;

@Component({
  selector: 'listagem-diarios',
  templateUrl: './listagemDiarios.module.component.html'
})
export class ListagemdiariosComponent extends ModuloComponent implements OnInit {
  public header: IListagemDiariosModel;
  public reportModel: IJsonReport;
  public readonly filter: string;
  public pdfUrl: string;
  public deConta: string;
  public ateConta: string;

  private readonly _periodoData: string;
  private readonly _entityPeriodos: IPeriodosEntityService;
  private readonly _btnPreVisualizar: IPlToolbarItem;
  private _periodos: Array<IJsonPeriodo>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _listagemDiariosService: ListagemDiariosService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);

    this._btnPreVisualizar = {
      id: 'processar',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-file-pdf-o"></i>',
      class: 'btn-success',
      caption: 'listagemdiarios.btn.previsualizar',
      click: () => this._processar()
    };

    this._periodoData = this._configService.configurations.empresa.periodoDaData;
    this.deConta = this._configService.configurations.clientesFornecedores.radicalClientes;
    this.ateConta = `${this._configService.configurations.clientesFornecedores.radicalFornecedores}9999999`;
    this._entityPeriodos = this._entityServiceBuilder.build<IJsonPeriodo, IPeriodosEntityService>('periodos');
    this.header = {
      datadocate: maxDateCG(),
      datadocde: minDateCG(),
      ncontaate: '9999999',
      ncontade: '0',
      ndiarioate: '32767',
      ndiariode: '',
      ndocinternoate: 'ZZZZZZZZZZZZZZZZZZZZZZZZZ',
      ndocinternode: '',
      nutilizadorate: 32767,
      nutilizadorde: 0,
      periodoate: this._periodoData,
      periodoateNome: '',
      periodode: '',
      periododeNome: '',
      reportName: '',
      tipoExportacao: 0,
      loadAllAnos: 1,
      loadOnlyAno: 0,
      dePeriodoAno: ''
    };
    this.filter = `tipo=${ETipoContaContabilidade.Movimento}`;
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.toolbar.addButton(this._btnPreVisualizar);
    this._loadPeriodos();
    this._loadDefaultReport();
  }

  public changedDePeriodo(value: string): void {
    this.header.periodode = value;
    this.header.dePeriodoAno = '';
    if (this.header.periodode) {
      let dePeriodoAno: string = this.header.periodode.substring(0, LENGTH_YEAR);
      if (dePeriodoAno) {
        dePeriodoAno = `&periodo=%${dePeriodoAno}%`;
        this.header.dePeriodoAno = dePeriodoAno;
      }
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      this.header.periodoate = `${value.substring(0, 4)}123`;
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      const ano = toInteger(value.substring(0, 4));
      if (ano === this._configService.configurations.empresa.anoEmCursoIRC || ano === this._configService.configurations.empresa.anoEmCursoIRC + 1) {
        this.header.loadOnlyAno = 0;
        this.header.loadAllAnos = 0;
      } else {
        this.header.loadOnlyAno = ano;
        this.header.loadAllAnos = 1;
      }

      this.header.periododeNome = this._getDescriptionFromPeriodo(this.header.periodode);
      this.header.periodoateNome = this._getDescriptionFromPeriodo(this.header.periodoate);
      this.header = {...this.header};
      this._checkPeriodos();
    }
  }

  public changedAtePeriodo(value: string): void {
    this.header.periodoate = value;

    const periodoDe = toInteger(this.header.periodode);

    const periodoAte = toInteger(this.header.periodoate);

    if (periodoAte < periodoDe) {
      this.header.periodode = value;
    }

    if (this.header.periodode && this.header.periodoate) {
      this.header.periododeNome = this._getDescriptionFromPeriodo(this.header.periodode);
      this.header.periodoateNome = this._getDescriptionFromPeriodo(this.header.periodoate);
      this.header = {...this.header};
      this._checkPeriodos();
    }
  }

  private _checkPeriodos(): Promise<unknown> {
    if (this.header.periodode && this.header.periodoate) {
      return this._entityPeriodos.obterAnoDosPeriodos(this.header.periodode, this.header.periodoate);
    }
    return Promise.resolve();
  }

  private _processar(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.header.reportName = this.reportModel.name;
      if (isEmpty(this.header.ndiariode)) {
        this.header.ndiariode = '0';
      }
      firstValueFrom(this._listagemDiariosService.getListagemDiarioUrl(this.header))
        .then((url: string) => {
          this.pdfUrl = url;
          resolve();
        })
        .catch(reject);
    });
  }

  private _loadPeriodos(): void {
    this._entityPeriodos.query().then((response) => {
      this._periodos = response.body.list;
      if (this._periodos) {
        this.header = {
          ...this.header,
          periodode: this._periodos[0].periodo,
          periododeNome: this._periodos[0].nome,
          periodoate: this._periodoData,
          periodoateNome: this._getDescriptionFromPeriodo(this._periodoData)
        };
        this.changedDePeriodo(this.header.periodode);
      }
    });
  }

  private _loadDefaultReport(): void {
    const reportModel = this._configService.configurations.contabilidade.listagemdiarios.reportImpressao;
    this._reportsRegistryService
      .get(EReport.Documentos)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          let foundDefault = false;
          for (const report of reports) {
            if (report.name === reportModel) {
              this.reportModel = report;
              foundDefault = true;
              break;
            }
          }
          if (!foundDefault) {
            this.reportModel = reports[0];
          }
        }
      });
  }

  private _getDescriptionFromPeriodo(periodoKey: string): string {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    periodoKey = periodoKey.substring(periodoKey.length - 3);
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return this._periodos.find((periodo) => periodo.periodo.substring(periodo.periodo.length - 3) === periodoKey).nome;
  }
}
