import {Component, Injector, Input, OnInit} from '@angular/core';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {isUndefinedOrNull} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {ENTITY_NAME_AT_SERIES_LOGS} from '../../../atserieslogs/atSeriesLogs.entity.interface';
import {IJsonAtSerieLog} from '../../../atserieslogs/jsonAtSerieLog.entity.interface';

@Component({
  selector: 'log-comunicacao-serie-at-modal',
  templateUrl: './logComunicacaoSerieAt.modal.component.html'
})
export class LogComunicacaoSerieAtModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public readonly nDocfa: number;
  @Input() public readonly nNumer: number;
  public readonly dataGridDefinition: IDevExpressDataGrid;
  private readonly _docFaNumLogService: IEntityService<IJsonAtSerieLog>;
  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);

    this._docFaNumLogService = this._entityServiceBuilder.build(ENTITY_NAME_AT_SERIES_LOGS);

    this.dataGridDefinition = {
      columns: [
        {dataField: 'atDataInicioPrev', dataType: 'datetime', caption: 'docfasNums.modal.logs.fields.atDataInicioPrev'},
        {dataField: 'operacaoSTR', dataType: 'string', caption: 'docfasNums.modal.logs.fields.operacaoSTR'},
        {dataField: 'erro', dataType: 'boolean', caption: 'docfasNums.modal.logs.fields.erro'},
        {dataField: 'respostaCodigo', dataType: 'number', caption: 'docfasNums.modal.logs.fields.respostaCodigo'},
        {dataField: 'respostaMensagem', dataType: 'string', caption: 'docfasNums.modal.logs.fields.respostaMensagem'},
        {dataField: 'codValidacaoSerie', dataType: 'string', caption: 'docfasNums.modal.logs.fields.codValidacaoSerie'},
        {dataField: 'atTipoSerie', dataType: 'number', caption: 'docfasNums.modal.logs.fields.atTipoSerie'},
        {dataField: 'atTipoSerieDescricao', dataType: 'string', caption: 'docfasNums.modal.logs.fields.atTipoSerieDescricao'},
        {dataField: 'atClasseDoc', dataType: 'number', caption: 'docfasNums.modal.logs.fields.atClasseDoc'},
        {dataField: 'atClasseDocDescricao', dataType: 'string', caption: 'docfasNums.modal.logs.fields.atClasseDocDescricao'},
        {dataField: 'atTipoDocSTR', dataType: 'string', caption: 'docfasNums.modal.logs.fields.atTipoDocSTR'},
        {dataField: 'atEstadoSerie', dataType: 'number', caption: 'docfasNums.modal.logs.fields.atEstadoSerie'},
        {dataField: 'atEstadoSerieDescricao', dataType: 'string', caption: 'docfasNums.modal.logs.fields.atEstadoSerieDescricao'}
      ],
      dataSource: [],
      export: {filename: 'logs.series'},
      remoteOperations: false,
      pager: {
        displayMode: 'full'
      }
    };
  }

  public ngOnInit(): void {
    if (isUndefinedOrNull(this.nDocfa) || isUndefinedOrNull(this.nNumer) || this.nDocfa < 1 || this.nNumer < 1) {
      this._docFaNumLogService.query().then((result) => {
        this.dataGridDefinition.dataSource = result.body.list;
      });
    } else {
      this._docFaNumLogService.query({pesquisa: `nDocfa=${this.nDocfa}&nNumer=${this.nNumer}`}).then((result) => {
        this.dataGridDefinition.dataSource = result.body.list;
      });
    }
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }
}
