<div class="gestao-contratos-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'gestaoContratos.title'"></h4>
  </div>

  <div class="modal-body">
    <div class="entity-detail-form">
      <pl-form>
        <pl-group>
          <pl-group>
            <label [translate]="'gestaoContratos.filters.nClasseContrato'"></label>
            <edit>
              <entity-autocomplete
                attrName="classescontratosservico"
                entity="classescontratosservicos"
                filter="ClasseTipo=0"
                [selectedKey]="filterModel.nClasseContrato"
                (selectedKeyChange)="classeContratoChanged($event)"
                [output]="'{{nClasseCtr}} - {{nomeClasseCtr}}'"
                outputKey="nClasseCtr"
                plAutoFocus>
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <div class="pl-group-label" style="height: 22px">
          <label [translate]="'gestaoContratos.filters.nTiposContrato'"></label>
        </div>
        <div class="card">
          <div class="card-body">
            <pl-group *ngIf="contratoTipoSource.length > 0">
              <div *ngFor="let tipoContrato of contratoTipoSource">
                <edit>
                  <pl-edit type="checkbox" attrName="normal" [(model)]="tipoContrato.selected" [properties]="{label: tipoContrato.nTipoContrato + '-' + tipoContrato.nome}"></pl-edit>
                </edit>
              </div>
            </pl-group>
            <div *ngIf="!contratoTipoSource.length">
              <span [translate]="'faturacaoContratos.messages.semClasseSelecionada'"></span>
            </div>
          </div>
        </div>

        <pl-group>
          <pl-group>
            <label [translate]="'gestaoContratos.filters.dataDocDe'"></label>
            <edit>
              <pl-edit-datepicker [model]="filterModel.dataDocDe" (modelChange)="onDataDocDeChange($event)"></pl-edit-datepicker>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'gestaoContratos.filters.dataDocAte'"></label>
            <edit>
              <pl-edit-datepicker [(model)]="filterModel.dataDocAte"></pl-edit-datepicker>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'gestaoContratos.filters.nContaDe'"></label>
            <edit>
              <entity-autocomplete entity="clientes" attrName="nContaDe" [(selectedKey)]="filterModel.nContaDe" (modelChange)="onNContaDeChanged($event)" output="key"></entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'gestaoContratos.filters.nContaAte'"></label>
            <edit>
              <entity-autocomplete entity="clientes" attrName="nContaAte" [(selectedKey)]="filterModel.nContaAte" (selectedKeyChange)="onNContaAteChanged($event)" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <label [translate]="'gestaoContratos.filters.estadoDoc'"></label>
          <edit>
            <pl-edit type="radiogroup" attrName="tipo" [(model)]="filterModel.estadoDoc" [properties]="{groupItems: estadosDocSource, validators: {required: {value: true}}}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <edit>
            <pl-button klass="btn-primary btn-sm" [onClick]="fnPesquisar" data-focus><i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span></pl-button>
          </edit>
        </pl-group>
      </pl-form>
    </div>

    <br />

    <div class="card-footer estatisticasComercial">
      <dx-data-grid
        [cgDxDataGrid]="dataGridDefinition"
        cgDxDataGridInstanceName="contratosFaturados"
        [dataSource]="contratosFaturados"
        (onInitialized)="onInitialized($event)"
        (onContentReady)="onContentReady()"
        [selectedRowKeys]="selectedRowKeys"
        (selectedRowKeysChange)="selectedRowKeys = $event; onSelectedRowKeysChange()">
        <div *dxTemplate="let item of 'actions'" style="width: 100px; margin-right: 0px !important">
          <pl-tooltip [config]="{text: 'faturacaoContratosLog.tabs.faturados.btn.previsualizar', container: 'body'}">
            <button type="button" *plTooltipContent class="btn btn-xs btn-primary" (click)="previsualizarDoc(item.data)"><i class="fa fa-eye"></i></button>
          </pl-tooltip>
          &nbsp;
          <pl-tooltip [config]="{text: 'faturacaoContratosLog.tabs.faturados.btn.encerrarImprimir', container: 'body'}">
            <button type="button" *plTooltipContent class="btn btn-xs btn-success" (click)="encerrarImprimirDoc(item.data)"><i class="fa fa-print"></i></button>
          </pl-tooltip>
          &nbsp;
          <pl-tooltip *ngIf="item.data.nDocumento === 0" [config]="{text: 'faturacaoContratosLog.tabs.faturados.btn.editarDocumento', container: 'body'}">
            <button type="button" *plTooltipContent class="btn btn-xs btn-warning" (click)="editarDocumento(item.data)"><i class="fa fa-pencil-square-o"></i></button>
          </pl-tooltip>

          <pl-tooltip *ngIf="item.data.nDocumento !== 0" [config]="{text: 'faturacaoContratosLog.tabs.faturados.btn.visualizarDocumento', container: 'body'}">
            <button type="button" *plTooltipContent class="btn btn-xs btn-info" (click)="visualizarDocumento(item.data)"><i class="fa fa-file-text"></i></button>
          </pl-tooltip>
        </div>
        <dxo-editing mode="cell" startEditAction="click" [selectTextOnEditStart]="true" [allowUpdating]="true"></dxo-editing>
      </dx-data-grid>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button *ngIf="contratosFaturados?.length > 0 && btnEncerrarVisible" klass="btn-success" (evtClicked)="encerrarSelecionados()" [disabled]="onClickAction">
      <i class="fa fa-fw fa-file-archive-o"></i>&nbsp;<span [translate]="'faturacaoContratosLog.tabs.faturados.btn.encerrarTudo'"></span>
    </pl-button>

    <pl-button *ngIf="contratosFaturados?.length > 0" klass="btn-primary" (evtClicked)="enviarFatEletronicaSelecionadas()" [disabled]="onClickAction">
      <i class="fa fa-fw fa-envelope-o"></i>&nbsp;<span [translate]="'faturacaoContratosLog.tabs.faturados.btn.enviarFatEletronica'"></span>
    </pl-button>

    <pl-button klass="btn-light action-close" (evtClicked)="close()" [disabled]="onClickAction"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
