import {HttpResponse} from '@angular/common/http';
import {Component, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {copy, IPlFormDefinition, IPlFormTemplate, IPlTableCallback, isFunction, isObject, isUndefinedOrNull} from 'pl-comps-angular';
import {Subscription} from 'rxjs';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {EntityListComponent} from '../../../../components/entity/list/entity.list.component';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {EGroupName} from '../../../../../config/constants';
import {IApiRequestConfigWithBody, TServiceResponse} from '../../../../services/api/api.service.interface';
import {ConfigOptionsService} from '../../../../services/config/options/config.options.service';
import {EConfigOptionsInstanceName, IFichaClientesConfigOptions, TConfigOptions} from '../../../../services/config/options/config.options.service.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IDocComercialCabWithData, IDocsComerciaisEntityService} from '../../../docscomerciais/docsComerciais.entity.interface';
import {IJsonDocComercial, IJsonDocComercialCab} from '../../../docscomerciais/jsonDocComercial.entity.interface';
import {DocumentosService} from '../../../docscomerciais/service/documentos.entity.service';
import {IJsonPagamento, IJsonPagamentoCab} from '../../../pagamentos/jsonPagamento.entity.interface';
import {IPagamentoCabWithData} from '../../../pagamentos/pagamentos.entity.interface';
import {IExtratosDTCallback} from '../../../../modules/portalcontabilidade/estatistica/extratosdt/extratosDT.module.interface';
import {IJsonRecibo, IJsonReciboCab} from '../../../recibos/jsonRecibo.entity.interface';
import {IReciboCabWithData, IRecibosEntityService} from '../../../recibos/recibos.entity.interface';
import {IClifo, IClifoGrupo, IClifosEntityService} from '../../clifos.entity.interface';
import {IJsonClifo} from '../../jsonClifo.entity.interface';
import {ClifosAvisoDadosRepetidosModalComponent} from '../../modals/clifosduplicates/clifos.avisoDadosRepetidos.modal.component';
import {FormGroup} from '@angular/forms';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {ENTITY_NAME_ARCLI} from '../../../arclis/arClis.entity.interface';
import {IJsonArCli} from '../../../arclis/jsonArCli.entity.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {EPortal} from '../../../../../common/enums/portals.enums';
import {ROLE} from '../../../../services/role.const';
import {AuthService} from '../../../../services/auth/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {IClifoFichaCallback} from '../component/ficha/clifos.ficha.interface';

const TOOLBAR_ID_DOCUMENTOS = 'toolbar-documentos';
const TOOLBAR_ID_RECEBIMENTOS = 'toolbar-recebimentos';
const TOOLBAR_ID_PAGAMENTOS = 'toolbar-pagamentos';

@Component({
  selector: 'clifos-edit',
  templateUrl: './clifos.entity.edit.component.html'
})
export class ClifosEditComponent extends ModuloEntityDetailComponent<IClifo, IClifosEntityService> implements OnInit, OnDestroy {
  @Input() public grupo: IClifoGrupo;
  public readonly fieldsMovimentosPendentes: string;
  public readonly callbackDocList: IPlTableCallback;
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public readonly callbackExtratos: IExtratosDTCallback;
  public readonly rolesComercialAdvanced: ROLE;

  public formDefinition: IPlFormDefinition;
  public formTemplate: IPlFormTemplate;
  public formInstance: FormGroup;
  public toolbarInstanceDocumentos: string;
  public toolbarInstanceRecebimentos: string;
  public toolbarInstancePagamentos: string;
  public showExtraTabs: boolean;
  public optionShowZona: boolean;
  public optionShowVendedor: boolean;
  public optionShowSector: boolean;
  public empresaTemContabilidadeDigital: boolean;
  public empresaAnoEmCursoIRC: number;
  public formCliente: boolean;
  public formFornecedor: boolean;
  public disableTabArtigos: boolean;
  public hasComercialAdvanced: boolean;
  public clifosFichaCallback: IClifoFichaCallback;

  protected _subscriptionConfigOptions: Subscription;

  @ViewChild('entityListRecOrPag') private readonly _entityListRecOrPag: EntityListComponent;

  private readonly _serviceDocsComerciais: IDocsComerciaisEntityService;
  private readonly _serviceRecibos: IRecibosEntityService;
  private readonly _servicePagamentos: IEntityService<IJsonPagamento>;
  private readonly _maintenanceInstanceArcli: IEntityMaintenanceInstance;
  private readonly _documentosService: DocumentosService;
  private _nifInvalido: boolean;

  private _dataGridInstanceArCli: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _configOptionsService: ConfigOptionsService,
    protected readonly _entityMaintenanceService: EntityMaintenanceService,
    protected readonly _authService: AuthService,
    protected readonly _translateService: TranslateService
  ) {
    super(_injector);
    this.clifosFichaCallback = {};
    this._nifInvalido = false;
    this.configOptionsGroupName = EGroupName.ERP;
    this.configOptionsInstanceName = EConfigOptionsInstanceName.FICHA_CLIENTES;
    if (this.type === EEntityStateDetailType.DETAIL) {
      this.model.nConta = isUndefinedOrNull(this.model.nConta) ? '' : this.model.nConta;
      this.model.nIdAltern = isUndefinedOrNull(this.model.nIdAltern) ? 0 : this.model.nIdAltern;
    }
    this.fieldsMovimentosPendentes = 'tipoDocumento,numeroDocumento,dataVencimento,valor,valorRecebido,valorPorReceber';
    this.callbackDocList = {};
    this.callbackExtratos = {};
    this._serviceDocsComerciais = this._entityServiceBuilder.build<IJsonDocComercial, IDocsComerciaisEntityService>('docscomerciais');
    this._serviceRecibos = this._entityServiceBuilder.build<IJsonRecibo, IRecibosEntityService>('recibos');
    this._servicePagamentos = this._entityServiceBuilder.build<IJsonPagamento>('pagamentos');
    this._documentosService = this._injector.get<DocumentosService>(DocumentosService);
    this._subscriptionConfigOptions = this._configOptionsService
      .getOptionsErp()
      .get(this.configOptionsInstanceName)
      .options()
      .subscribe((configOptions: TConfigOptions<boolean, IFichaClientesConfigOptions>) => {
        this.optionShowZona = configOptions.get('showZona').value;
        this.optionShowVendedor = configOptions.get('showVendedor').value;
        this.optionShowSector = configOptions.get('showSector').value;
      });

    this.empresaTemContabilidadeDigital = this.configurations.empresa.temContabilidadeDigital;
    this.empresaAnoEmCursoIRC = this.configurations.empresa.anoEmCursoIRC;
    this._maintenanceInstanceArcli = this._entityMaintenanceService.build(ENTITY_NAME_ARCLI);
    this.rolesComercialAdvanced = ROLE.ERPADVANCED;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (isObject(this.model) && this.model.nome) {
      this.setCaption(this.model.nome);
    }

    this.formDefinition = this.entity[this.type].definition;
    this.formTemplate = copy(this.entity[this.type].template);

    this.toolbar.addButton({
      id: 'clifo-navigation',
      order: 1,
      type: 'button-group',
      visible: this.type === EEntityStateDetailType.DETAIL,
      items: [
        {
          id: 'clifo-navigation-first',
          order: 1,
          type: 'button',
          iconLeft: '<i class="fa fa-fw fa-angle-double-left"></i>',
          class: 'btn-primary',
          click: () => this._goToFirstClifo(),
          title: 'clifos.navigation.tooltipFirst'
        },
        {
          id: 'clifo-navigation-previous',
          order: 2,
          type: 'button',
          iconLeft: '<i class="fa fa-fw fa-angle-left"></i>',
          class: 'btn-primary',
          click: () => this._goToPreviousClifo(),
          title: 'clifos.navigation.tooltipPrevious'
        },
        {
          id: 'clifo-navigation-navigation-next',
          order: 3,
          type: 'button',
          iconLeft: '<i class="fa fa-fw fa-angle-right"></i>',
          class: 'btn-primary',
          click: () => this._goToNextClifo(),
          title: 'clifos.navigation.tooltipNext'
        },
        {
          id: 'clifo-navigation-navigation-last',
          order: 4,
          type: 'button',
          iconLeft: '<i class="fa fa-fw fa-angle-double-right"></i>',
          class: 'btn-primary',
          click: () => this._goToLastClifo(),
          title: 'clifos.navigation.tooltipLast'
        }
      ]
    });

    this.formCliente = this.model.nConta.startsWith(this._configService.configurations.clientesFornecedores.radicalClientes);
    this.formFornecedor = this.model.nConta.startsWith(this._configService.configurations.clientesFornecedores.radicalFornecedores);

    this.disableTabArtigos = this.currentPortal.portal !== EPortal.ERP;

    this._authService.hasAuthority(ROLE.ERPADVANCED).then((hasAuthority: boolean) => {
      this.hasComercialAdvanced = hasAuthority;
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionConfigOptions.unsubscribe();
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    this.showExtraTabs = stateType === EEntityStateDetailType.DETAIL;
    return super.onUpdate(stateType).then(() => {
      this.formDefinition = this.entity[stateType].definition;
      this.formTemplate = copy(this.entity[stateType].template);
    });
  }

  public onNifValidStateChange(value: boolean): void {
    this._nifInvalido = value;
  }

  public save(config?: IApiRequestConfigWithBody<IClifo>): Promise<IClifo> {
    let promise = Promise.resolve();
    if (this._nifInvalido) {
      promise = this._cgModalService.showOkCancel(
        this._translateService.instant('clifos.nifInvalidoModal.title'),
        this._translateService.instant('clifos.nifInvalidoModal.bodyMessage', {nContribuint: this.model.nContribuint})
      );
    }

    return promise
      .then(() => {
        config = config ? config : {params: {}};
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (<any>config.params).permitenifsduplicados = true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (<any>config.params).registanifinvalido = true;
        if (this.type === EEntityStateDetailType.NEW) {
          return this.service.getNomeNifDuplicado(this.model.nome, this.model.nContribuint).then((response) => {
            if (response.body.list.length) {
              const instance = this._cgModalService.showVanilla(ClifosAvisoDadosRepetidosModalComponent);
              instance.componentInstance.clifosList = response.body.list;
              return instance.result
                .then(() => {
                  return super.save(config).then(() => this._saveContaPicture());
                })
                .catch(() => {
                  return undefined;
                });
            }
            return super.save(config).then(() => this._saveContaPicture());
          });
        }
        return super.save(config).then(() => this._saveContaPicture());
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  public setInstanceName(value: string): void {
    super.setInstanceName(value);
    this.toolbarInstanceDocumentos = `${this.instanceName}-${TOOLBAR_ID_DOCUMENTOS}`;
    this.toolbarInstanceRecebimentos = `${this.instanceName}-${TOOLBAR_ID_RECEBIMENTOS}`;
    this.toolbarInstancePagamentos = `${this.instanceName}-${TOOLBAR_ID_PAGAMENTOS}`;
  }

  public edit(): void {
    if (this.model.active !== 0) {
      this.model.active = 0;
    }
    super.edit();
  }

  public refreshList(): void {
    this.callback.refresh();
  }

  public refreshDocList(): void {
    this.callbackDocList.refresh();
  }

  public refreshRecOrPagList(): void {
    this._entityListRecOrPag.refresh();
  }

  public refreshExtratos(): Promise<void> {
    return this.callbackExtratos.refresh();
  }

  public onDocsComerciaisDataGridCellClick(event: IDevExpressDataGridEventOnCellClick<IJsonDocComercialCab>): void {
    devExpressDataGridExpandDetailHandler(event, () => this._onDocDetail(event));
  }

  public onRecibosDataGridCellClick(event: IDevExpressDataGridEventOnCellClick<IJsonReciboCab>): void {
    devExpressDataGridExpandDetailHandler(event, () => this._onReciboDetail(event));
  }

  public onPagamentosDataGridCellClick(event: IDevExpressDataGridEventOnCellClick<IJsonPagamentoCab>): void {
    devExpressDataGridExpandDetailHandler(event, () => this._onPagamentoDetail(event));
  }

  public onDataGridEventOnInitializedArCli({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstanceArCli = component;
  }

  public onDataGridCellClickArCli(event: IDevExpressDataGridEventOnCellClick<IJsonArCli>): void {
    if (event.column.name === 'entityListButtons') {
      return;
    }

    this._maintenanceInstanceArcli.maintenanceEdit(event.data.arcliGUIDID).then(() => {
      this._dataGridInstanceArCli.refresh();
    });
  }

  public novoArCli(): void {
    this._maintenanceInstanceArcli.maintenanceNew({params: {nConta: this.model.nConta, nomeConta: this.model.nome}}).then(() => {
      this._dataGridInstanceArCli.refresh();
    });
  }

  public readonly fnGetPdf = (doc: IJsonDocComercial): Promise<void> => this._getPdf(doc);

  public readonly fnGetDoc: (id: number) => Promise<IJsonDocComercial> = (id: number) => this._getDoc(id);

  public readonly fnAnexarGDocComercial: (doc: IJsonDocComercial) => Promise<IJsonDocComercial> = (doc: IJsonDocComercial) => this._anexarGDocComercial(doc);

  public readonly fnComunicarDocWebServiceAT: (doc: IJsonDocComercial) => Promise<IJsonDocComercial> = (doc: IJsonDocComercial) => this._comunicarDocWebServiceAT(doc);

  public readonly fnComunicarDocCirculacaoAT: (doc: IJsonDocComercial) => Promise<IJsonDocComercial> = (doc: IJsonDocComercial) => this._comunicarDocCirculacaoAT(doc);

  public readonly fnInserirCodigoTransporteAT: (doc: IJsonDocComercial) => Promise<IJsonDocComercial> = (doc: IJsonDocComercial) => this._inserirCodigoTransporteAT(doc);

  public readonly fnAnularDocumento: (doc: IJsonDocComercial) => Promise<IJsonDocComercial> = (doc: IJsonDocComercial) => this._anularDocumento(doc);

  public readonly fnNotaCreditoDocumento: (doc: IJsonDocComercial) => Promise<IJsonDocComercial> = (doc: IJsonDocComercial) => this._notaCreditoDocumento(doc);

  public readonly fnTransformarDocumento: (doc: IJsonDocComercial) => Promise<IJsonDocComercial> = (doc: IJsonDocComercial) => this._transformarDocumento(doc);

  public readonly fnDesmarcarDocTransformado: (doc: IJsonDocComercial) => Promise<IJsonDocComercial> = (doc: IJsonDocComercial) => this._desmarcarDocTransformado(doc);

  public readonly fnMeiosPagamentoComercial: (doc: IJsonDocComercial) => Promise<IJsonDocComercial> = (doc: IJsonDocComercial) => this._getMeiosPagamentoComercial(doc);

  public readonly fnEncerrarDocumento = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this._encerrarDocumento(doc);

  public readonly fnRefreshExtratos = (): Promise<void> => this.refreshExtratos();

  public readonly fnEditarArcli = (item: IJsonArCli) => (): Promise<void> => this._editarArcli(item);

  private _getPdf(doc: IJsonDocComercial): Promise<void> {
    return this._documentosService.getPdf(doc);
  }

  private _getDoc(id: number): Promise<IJsonDocComercial> {
    return this._documentosService.getDoc(id).then((response: HttpResponse<IJsonDocComercial>) => response.body);
  }

  private _anexarGDocComercial(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.anexarGDocComercial(doc);
  }

  private _comunicarDocWebServiceAT(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.comunicarDocWebServiceAT(doc.cab.faccbId, false);
  }

  private _comunicarDocCirculacaoAT(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.comunicarDocCirculacaoAT(doc.cab.faccbId);
  }

  private _inserirCodigoTransporteAT(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.inserirCodigoTransporteAT(doc);
  }

  private _anularDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.anularDocumento(doc);
  }

  private _notaCreditoDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.notaCreditoDocumento(doc);
  }

  private _transformarDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.transformarDocumento(doc);
  }

  private _desmarcarDocTransformado(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.desmarcarDocTransformado(doc);
  }

  private _getMeiosPagamentoComercial(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.getMeiosPagamentoComercial(doc);
  }

  private _encerrarDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.encerrarDocumento(doc, true);
  }

  private _onDocDetail({data}: IDevExpressDataGridEventOnCellClick<IDocComercialCabWithData>): Promise<void> {
    return this._serviceDocsComerciais.get({id: data.faccbId}).then((response: HttpResponse<IJsonDocComercial>) => {
      data._doc = response.body;
    });
  }

  private _onReciboDetail({data}: IDevExpressDataGridEventOnCellClick<IReciboCabWithData>): Promise<void> {
    return this._serviceRecibos.get({id: data.extPocCabID}).then((response: HttpResponse<IJsonRecibo>) => {
      data._recibo = response.body;
    });
  }

  private _onPagamentoDetail({data}: IDevExpressDataGridEventOnCellClick<IPagamentoCabWithData>): Promise<void> {
    return this._servicePagamentos.get({id: data.extPocCabID}).then((response: HttpResponse<IJsonPagamento>) => {
      data._pagamento = response.body;
    });
  }

  private async _navigate(callback: () => TServiceResponse<IJsonClifo>): Promise<void> {
    if (this.type !== EEntityStateDetailType.DETAIL) {
      return;
    }

    const clifo = await callback();

    await this._stateService.go(this._transition.to(), {id: clifo.body.id});
  }

  private _editarArcli(arcli?: IJsonArCli): Promise<void> {
    return this._maintenanceInstanceArcli.maintenanceEdit(arcli.arcliGUIDID).then(() => {
      return this._dataGridInstanceArCli.refresh();
    });
  }

  private _goToFirstClifo(): Promise<void> {
    return this._navigate(() => this.service.getFirst());
  }

  private _goToPreviousClifo(): Promise<void> {
    return this._navigate(() => this.service.getPrev(this.model.id));
  }

  private _goToNextClifo(): Promise<void> {
    return this._navigate(() => this.service.getNext(this.model.id));
  }

  private _goToLastClifo(): Promise<void> {
    return this._navigate(() => this.service.getLast());
  }

  private _saveContaPicture(): Promise<void> {
    if (isFunction(this.clifosFichaCallback.savePicture)) {
      return this.clifosFichaCallback.savePicture();
    }
    return Promise.resolve();
  }
}
