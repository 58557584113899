<div class="modelo22-rosto-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'modelo22.modal.rosto.titles.' + newLineFor"></h5>
  </div>

  <div class="modal-body">
    <pl-form>
      <pl-group *ngIf="is396 || is398">
        <label [translate]="'modelo22.modal.prejuizos'"></label>
        <edit>
          <pl-edit type="number" attrName="prejuizo" [(model)]="prejuizo" plAutoFocus></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is396 || is398 || is397A || is397B || is309B || isQuadro12List">
        <label [translate]="'modelo22.modal.nif'"></label>
        <edit>
          <pl-edit type="cginteger" attrName="nif" [(model)]="nif" [properties]="{validate: false}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is309 || is320 || is331 || is397A || is397B || is396 || is398 || is309B">
        <label [translate]="'modelo22.modal.periodo'"></label>
        <edit>
          <pl-edit type="cginteger" attrName="periodo" [(model)]="periodo" [properties]="{validate: false}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is309 || is320 || is331 || is309B">
        <label [translate]="'modelo22.modal.montante'"></label>
        <edit>
          <pl-edit type="number" attrName="montante" [(model)]="montante"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is397A || is397B">
        <label [translate]="'modelo22.modal.valPerio'"></label>
        <edit>
          <pl-edit type="number" attrName="valPerio" [(model)]="valPerio"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="isQuadro12List">
        <label [translate]="'modelo22.modal.retFonte'"></label>
        <edit>
          <pl-edit type="number" attrName="retFonte" [(model)]="retFonte"></pl-edit>
        </edit>
      </pl-group>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span> </pl-button>

    <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
