import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {IPlToolbarItem, KEYCODES} from 'pl-comps-angular';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {EExtratosDTTipo, IExtratosDTCallback, IExtratosDTOutputParams, IExtratosDTStateParams} from '../extratosDT.module.interface';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {ExtratosDTPrintModalComponent} from '../modals/print/extratosDT.print.modal.component';

const BTN_ID_PROCESSAR = 'processar';

@Component({
  selector: 'extratosDT',
  templateUrl: './extratosDT.module.component.html'
})
export class ExtratosDTModuleComponent extends ModuloComponent implements OnInit {
  @Input() public moduleType: EExtratosDTTipo;
  @Input() public moduleName: string;
  @Input() public empresaTemContabilidadeDigital: boolean;
  @Input() public empresaAnoEmCursoIRC: number;

  public readonly callback: IExtratosDTCallback;
  public params: IExtratosDTStateParams;
  public form: FormGroupDirective;

  private readonly _btnImprimir: IPlToolbarItem;
  private _pesquisaFilters: IExtratosDTOutputParams;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.params = this._transition.params();
    this.callback = {};

    this._btnImprimir = {
      id: 'imprimirExtratos',
      order: 1,
      type: 'button',
      class: 'btn-primary',
      iconLeft: '<i class="fa fa-fw fa-file-pdf-o"></i>',
      caption: 'global.btn.print',
      disabled: true,
      click: () => this._modalExtratosPreviewPDF()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._evaluateMaintenanceModeFullscreen();
    this.toolbar.addButton(this._btnImprimir);
  }

  public pesquisarChanged(output: IExtratosDTOutputParams): void {
    if (output) {
      this._pesquisaFilters = output;
      this._btnImprimir.disabled = this._pesquisaFilters.semResgistos;
    }
  }

  public readonly fnKeydownProcessar = (event: KeyboardEvent): void => {
    this._keydownProcessar(event);
  };

  protected _onConfigurationsChanged(): void {
    this.empresaTemContabilidadeDigital = this.configurations.empresa.temContabilidadeDigital;
    this.empresaAnoEmCursoIRC = this.configurations.empresa.anoEmCursoIRC;
    this._evaluateMaintenanceModeFullscreen();
  }

  private _evaluateMaintenanceModeFullscreen(): void {
    if (this.maintenanceMode) {
      this.setMaintenanceModeFullscreen(this.empresaTemContabilidadeDigital);
    }
  }

  private _keydownProcessar(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.preventDefault();
      event.stopPropagation();
      this.toolbar.focusItem(BTN_ID_PROCESSAR);
    }
  }

  private _modalExtratosPreviewPDF(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ExtratosDTPrintModalComponent);
    const componentInstance: ExtratosDTPrintModalComponent = modalInstance.componentInstance;
    componentInstance.filters = this._pesquisaFilters;
    return modalInstance.result;
  }
}
