<div class="impde119 entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1">
        <div class="row">
          <div class="col-sm-12">
            <pl-group>
              <label [translate]="'impde119.filters.listagem'"></label>
              <edit>
                <pl-edit type="reports_impde119" attrName="listagem" [(model)]="report"></pl-edit>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12">
            <hr />
          </div>

          <div class="col-sm-12">
            <label [translate]="'impde119.filters.filtrarPor'"></label>
            <pl-group>
              <edit>
                <pl-edit type="radiogroup" attrName="tipoDeclaracao" [(model)]="filters.filtrarpesquisa" [properties]="{groupItems: filtrarPesquisa}"></pl-edit>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6">
            <pl-group>
              <edit>
                <label [translate]="'impde119.filters.dedata'"></label>
                <pl-edit type="date" [(model)]="filters.dedata"></pl-edit>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6">
            <pl-group>
              <edit>
                <label [translate]="'impde119.filters.atedata'"></label>
                <pl-edit type="date" [(model)]="filters.atedata"></pl-edit>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3">
            <pl-group>
              <label [translate]="'impde119.filters.decodemp'"></label>
              <edit>
                <entity-autocomplete
                  entity="dgempsfull"
                  attrName="codEmpDe"
                  [model]="filters"
                  (selectedKeyChange)="filters.decodemp = $event"
                  [fieldsMap]="{codEmp: 'decodemp'}"
                  output="key"
                  [properties]="{disabled: filters.filtrarpesquisa === eTiposDeclaracao.Outros}">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3">
            <pl-group>
              <label [translate]="'impde119.filters.atecodemp'"></label>
              <edit>
                <entity-autocomplete
                  entity="dgempsfull"
                  attrName="codEmpAte"
                  [model]="filters"
                  (selectedKeyChange)="filters.atecodemp = $event"
                  [fieldsMap]="{codEmp: 'atecodemp'}"
                  output="key"
                  [properties]="{disabled: filters.filtrarpesquisa === eTiposDeclaracao.Outros}">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3">
            <pl-group>
              <label [translate]="'impde119.filters.dencontribuinte'"></label>
              <edit>
                <entity-autocomplete
                  entity="nifs"
                  attrName="dencontribuinte"
                  [(selectedKey)]="filters.dencontribuinte"
                  [properties]="{disabled: filters.filtrarpesquisa === eTiposDeclaracao.Empregado}"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3">
            <pl-group>
              <label [translate]="'impde119.filters.atencontribuinte'"></label>
              <edit>
                <entity-autocomplete
                  entity="nifs"
                  attrName="atencontribuinte"
                  [(selectedKey)]="filters.atencontribuinte"
                  [properties]="{disabled: filters.filtrarpesquisa === eTiposDeclaracao.Empregado}"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12">
            <pl-multiselect [model]="empresas" [source]="empresasSource" [template]="empresasTemplate" key="nEmpresa"></pl-multiselect>
          </div>
        </div>
      </pl-form>
    </div>
  </cg-card-panel>

  <!--  <hr/>-->

  <cg-pdf-viewer *ngIf="showPdfViewer" class="mt-4" [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>
