import {Component, Injector} from '@angular/core';
import type {IPlEditComponentOptionsInputText} from '../../component/edit.component.interface';
import {PlEditInputTypeComponent} from '../../generic/input/edit.input.type.component';

@Component({
  selector: 'pl-edit-textarea',
  templateUrl: './edit.textarea.component.html'
})
export class PlEditTextareaComponent extends PlEditInputTypeComponent<string, IPlEditComponentOptionsInputText<string>> {
  constructor(protected readonly _injector: Injector) {
    super('textarea', _injector);
  }
}
