import {Component, Injector, OnInit, Renderer2} from '@angular/core';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {EPOCAltTipoValidacao, IJsonPlanoContasAlternativoCab, IJsonPlanoContasAlternativoLin, IJsonPlanoContasAlternativoRadicais} from '../../jsonPlanoContasAlternativo.entity.interface';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {CellPreparedEvent, Toolbar} from 'devextreme/ui/tree_list';
import {PlanosContasAlternativosContaModalComponent} from '../../modals/contas/planosContasAlternativos.conta.modal.component';
import {copy, downloadStream, isEmpty, PlAlertService} from 'pl-comps-angular';
import {ENTITY_NAME_PLANOS_CONTAS_ALTERNATIVOS, IPlanosContasAlternativosEntityService} from '../../planosContasAlternativos.entity.interface';
import {HttpResponse} from '@angular/common/http';
import {PlanosContasAlternativosImportModalComponent} from '../../modals/importplanoalt/planosContasAlternativos.import.modal.component';

const CLASS_LEGEND = 'tipoconta';

@Component({
  selector: 'planos-contas-alternativos-edit',
  templateUrl: './planosContasAlternativos.entity.edit.component.html'
})
export class PlanosContasAlternativosEditComponent extends ModuloEntityDetailComponent<IJsonPlanoContasAlternativoCab> implements OnInit {
  public readonly keysTipoContaContab: ReadonlyArray<keyof ETipoContaContabilidade>;
  public readonly tipoContaContab: typeof ETipoContaContabilidade;
  public readonly stateType: typeof EEntityStateDetailType;
  public readonly typePOCAltTipoValidacao: typeof EPOCAltTipoValidacao;
  public readonly toolbarTreeList: Toolbar;
  public readonly classLegend: string;

  private readonly _servicePlanosContasAlt: IPlanosContasAlternativosEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _renderer: Renderer2,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._servicePlanosContasAlt = this._entityServiceBuilder.build(ENTITY_NAME_PLANOS_CONTAS_ALTERNATIVOS);
    this.tipoContaContab = ETipoContaContabilidade;
    this.typePOCAltTipoValidacao = EPOCAltTipoValidacao;
    this.stateType = EEntityStateDetailType;
    this.keysTipoContaContab = Object.freeze<Array<keyof ETipoContaContabilidade>>(<Array<keyof ETipoContaContabilidade>>Object.keys(this.tipoContaContab));
    this.classLegend = CLASS_LEGEND;
    this.toolbarTreeList = {
      items: [
        {
          location: 'before',
          template: 'templateToolbarBefore',
          locateInMenu: 'auto'
        }
      ]
    };
    this.modeloImportContasAlt = this.modeloImportContasAlt.bind(this);
    this.importPlanoAlt = this.importPlanoAlt.bind(this);
    this.exportPlanoAtual = this.exportPlanoAtual.bind(this);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!this.model) {
      this.model = {
        pocAltCabID: '',
        nome: '',
        tipoValidacao: EPOCAltTipoValidacao.ValidaGeral,
        radicaisValidacao: '',
        inverteSinal: false,
        linhas: []
      };
    }

    this._applyDataSourceTree();
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    return super.onUpdate(stateType).then(() => {
      if (stateType === EEntityStateDetailType.DETAIL || stateType === EEntityStateDetailType.EDIT) {
        this.setCaption(this._translateService.instant(`${this.entity.name}.title_detail`, {id: this.model.nome}));
      }
    });
  }

  public onTreeListCellPrepared({data, cellElement, rowType}: CellPreparedEvent<IJsonPlanoContasAlternativoLin>): void {
    if (rowType === 'data' && data?.tipo) {
      this._renderer.addClass(cellElement, `${this.classLegend}-${Number(data.tipo)}`);
    }
  }

  public openNewConta(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(PlanosContasAlternativosContaModalComponent);
    const componentInstance: PlanosContasAlternativosContaModalComponent = modalInstance.componentInstance;
    componentInstance.pocAltCabID = this.model.pocAltCabID;
    return modalInstance.result.then((itemResult: IJsonPlanoContasAlternativoLin) => {
      this.model.linhas.push(itemResult);
      this._applyDataSourceTree();
    });
  }

  public openEditConta(item: IJsonPlanoContasAlternativoLin): Promise<void> {
    const contasList = item.contas.split(',');
    const contasAlternativas: Array<IJsonPlanoContasAlternativoRadicais> = [];
    for (const conta of contasList) {
      contasAlternativas.push({
        incExc: conta.startsWith('-') ? this._translateService.instant('planoscontasalternativos.fields.exclui') : this._translateService.instant('planoscontasalternativos.fields.inclui'),
        nome: '',
        nRadicalConta: conta.startsWith('-') ? conta.substring(1) : conta
      });
    }
    return this._servicePlanosContasAlt.fillRadicals(contasAlternativas).then((response: HttpResponse<Array<IJsonPlanoContasAlternativoRadicais>>) => {
      const modalInstance = this._cgModalService.showVanilla(PlanosContasAlternativosContaModalComponent);
      const componentInstance: PlanosContasAlternativosContaModalComponent = modalInstance.componentInstance;
      componentInstance.pocAltCabID = this.model.pocAltCabID;
      componentInstance.linha = copy(item);
      componentInstance.contasAlternativas = response.body;
      return modalInstance.result.then((itemResult: IJsonPlanoContasAlternativoLin) => {
        const index = this.model.linhas.findIndex((value: IJsonPlanoContasAlternativoLin) => value.pocAltLinID === item.pocAltLinID);
        if (index !== -1) {
          this.model.linhas[index] = {...itemResult};
          this._applyDataSourceTree();
        }
      });
    });
  }

  public openDeleteConta(item: IJsonPlanoContasAlternativoLin): void {
    this._cgModalService.showOkCancel('global.text.confirmation', 'entity.delete.message').then(() => {
      this._servicePlanosContasAlt.deletePCAltLinha(this.model.pocAltCabID, item.pocAltLinID).then(() => {
        const index = this.model.linhas.findIndex((value: IJsonPlanoContasAlternativoLin) => value.pocAltLinID === item.pocAltLinID);
        if (index !== -1) {
          this.model.linhas.splice(index, 1);
          this._applyDataSourceTree();
        }
      });
    });
  }

  public modeloImportContasAlt(): Promise<void> {
    return this._servicePlanosContasAlt.getModImportPlanoAlt().then((response: HttpResponse<Blob>) => {
      downloadStream(response);
      this._plAlertService.success('planoscontasalternativos.messages.modeloImportContasAlt');
    });
  }

  public importPlanoAlt(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(PlanosContasAlternativosImportModalComponent);
    const componentInstance: PlanosContasAlternativosImportModalComponent = modalInstance.componentInstance;
    componentInstance.pocAltCabID = this.model.pocAltCabID;
    return modalInstance.result.then((linhas: Array<IJsonPlanoContasAlternativoLin>) => {
      this.model.linhas = linhas;
      this._applyDataSourceTree();
    });
  }

  public exportPlanoAtual(): Promise<void> {
    return this._servicePlanosContasAlt.exportPlanoAlt(this.model.pocAltCabID).then((response: HttpResponse<Blob>) => {
      downloadStream(response);
      this._plAlertService.success('planoscontasalternativos.messages.exportedsuccess');
    });
  }

  private _applyDataSourceTree(): void {
    for (const conta of this.model.linhas) {
      conta.ncontaPai = isEmpty(conta.ncontaPai) || (conta.tipo === ETipoContaContabilidade.Classe && conta.ncontaPai === conta.nconta) ? '-1' : conta.ncontaPai;
    }
  }
}
