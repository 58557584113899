import {Component, Input} from '@angular/core';
import {CGCIconDirective} from '../directive/icon.directive';

@Component({
  selector: 'cgc-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  standalone: true,
  imports: [CGCIconDirective]
})
export class CGCIconComponent {
  @Input() public icon: string | Array<string>;
  @Input() public normalizeSize: boolean;
}
