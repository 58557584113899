import dxChartPie, {InitializedEvent as PieChartInitializedEvent, Legend} from 'devextreme/viz/pie_chart';
import {BaseWidgetTitle} from 'devextreme/viz/core/base_widget';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {EPlDashboardType, IPlDashboard, PlI18nService, PlTranslateService} from 'pl-comps-angular';
import {BancosExtratoUiService} from '../../bancosextrato/service/bancosExtrato.module.ui.service';
import {chartLabelWithPercent, chartLegend, chartTitle} from '../../../components/devexpress/charts/utilities/devexpress.chart.utilities';
import {DashboardFinanceiroService} from '../dashboardFinanceiro.module.service';
import {EDashboardFinanceiroChartType, IDashboardFinanceiroPieReceberClientesPagarFornecedores} from '../dashboardFinanceiro.module.interface';
import {IDashboardsERPValor} from '../../../services/dashboardserp/dashboards.erp.service.interface';
import {IDevExpressChartLabelCustomizeText} from '../../../components/devexpress/charts/devexpress.charts.interface';
import {IDevExpressPieChart} from '../../../components/devexpress/charts/pie/devexpress.pie.chart.interface';
import {IJsonDashboardsPercentagem} from '../../../interfaces/jsonDashboards.interface';
import {IJsonFinanceiroContaOrdem, IJsonFinanceiroValoresEmAberto, IJsonFinanceiroValoresPagarReceber} from '../jsonDashboardFinanceiro.module.interface';
import {ModuloComponent} from '../../../components/module/module.component';

const TOP_FIVE = 5;

@Component({
  selector: 'module-dashboard-financeiro',
  templateUrl: './dashboardFinanceiro.module.component.html'
})
export class DashboardFinanceiroModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public licencaStoreModePublic: boolean;
  @Input() public empresaTemCGBanking: boolean;
  @Input() public acessoBankingAutomation: boolean;
  @Input() public cgOnCGBankingExpired: boolean;
  @Input() public cgStoreUrlBackOffice: string;

  public readonly chartPieReceberDeClientes: IDevExpressPieChart<'DoughnutSeries'>;
  public readonly chartPiePagarFornecedores: IDevExpressPieChart<'DoughnutSeries'>;
  public contasAOrdem: Promise<IPlDashboard<EPlDashboardType.Table>>;
  public valoresEmAberto: Promise<IPlDashboard<EPlDashboardType.Table>>;
  public receberClientesTable: Promise<IPlDashboard<EPlDashboardType.Table>>;
  public pagarFornecedoresTable: Promise<IPlDashboard<EPlDashboardType.Table>>;
  public chartPieReceberDeClientesData: Array<IDashboardFinanceiroPieReceberClientesPagarFornecedores>;
  public chartPiePagarFornecedoresData: Array<IDashboardFinanceiroPieReceberClientesPagarFornecedores>;
  public promiseActivateCGBanking: Promise<void>;

  private _chartPieReceberDeClientesInstance: dxChartPie;
  private _chartPiePagarFornecedoresInstance: dxChartPie;
  private _destroyed: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plI18nService: PlI18nService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _bancosExtratoService: BancosExtratoUiService,
    private readonly _dashboardFinanceiroService: DashboardFinanceiroService
  ) {
    super(_injector);
    this.chartPieReceberDeClientes = {
      type: 'doughnut',
      palette: 'Soft Blue',
      resolveLabelOverlapping: 'shift',
      title: {
        ...(<BaseWidgetTitle>chartTitle),
        text: this._translateService.instant('dashboards.financeiro.receberPagarTables.titleReceber')
      },
      series: [
        {
          argumentField: 'name',
          valueField: 'value',
          label: {
            visible: true,
            customizeText: this.customizeLabelWithPercentFn,
            connector: {
              visible: true
            }
          }
        }
      ],
      legend: <Legend>chartLegend
    };
    this.chartPiePagarFornecedores = {
      type: 'doughnut',
      palette: 'Soft Blue',
      resolveLabelOverlapping: 'shift',
      title: {
        ...(<BaseWidgetTitle>chartTitle),
        text: this._translateService.instant('dashboards.financeiro.receberPagarTables.titlePagar')
      },
      series: [
        {
          argumentField: 'name',
          valueField: 'value',
          label: {
            visible: true,
            customizeText: this.customizeLabelWithPercentFn,
            connector: {
              visible: true
            }
          }
        }
      ],
      legend: <Legend>chartLegend
    };
    this.acessoBankingAutomation = false;
    this._destroyed = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._loadData(false).finally(() => {
      this.btnRefresh.visible = true;
      this.btnRefresh.click = () => this._loadData(true);
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._destroyed = true;
  }

  public onInitializedChartPieReceberDeClientes(event: PieChartInitializedEvent): void {
    this._chartPieReceberDeClientesInstance = event.component;
    this._chartPieReceberDeClientesInstance.showLoadingIndicator();
  }

  public onInitializedChartPiePagarFornecedores(event: PieChartInitializedEvent): void {
    this._chartPiePagarFornecedoresInstance = event.component;
    this._chartPiePagarFornecedoresInstance.showLoadingIndicator();
  }

  public activateCGBanking(): Promise<void> {
    if (this.empresaTemCGBanking) {
      return Promise.resolve();
    }
    if (!this.promiseActivateCGBanking) {
      this.promiseActivateCGBanking = this._bancosExtratoService
        .consumeLicense()
        .then(() => {
          this.contasAOrdem = this._refreshContasAOrdem(true);
        })
        .finally(() => {
          this.promiseActivateCGBanking = undefined;
        });
    }
    return this.promiseActivateCGBanking;
  }

  public readonly customizeLabelWithPercentFn = (pointInfo: IDevExpressChartLabelCustomizeText): string => chartLabelWithPercent(pointInfo, this._plI18nService, false);

  protected _onConfigurationsChanged(): void {
    super._onConfigurationsChanged();
    this.acessoBankingAutomation = this.configurations.acessos.erpcloud.addons.bankingAutomation.acesso;
  }

  private async _loadData(force: boolean): Promise<void> {
    this.contasAOrdem = this.empresaTemCGBanking && this.acessoBankingAutomation ? this._refreshContasAOrdem(force) : undefined;
    this.valoresEmAberto = this._refreshValoresEmAberto(force);
    this.receberClientesTable = this._refreshTableReceberClientes(force);
    this.pagarFornecedoresTable = this._refreshTablePagarFornecedores(force);
    await Promise.all([this.contasAOrdem, this.valoresEmAberto, this.receberClientesTable, this.pagarFornecedoresTable, this._refreshReceberDeClientes(force), this._refreshPagarFornecedores(force)]);
  }

  private async _refreshContasAOrdem(force: boolean): Promise<IPlDashboard<EPlDashboardType.Table>> {
    if (!this.empresaTemCGBanking || !this.acessoBankingAutomation) {
      return Promise.reject(new Error('No access'));
    }
    const response: HttpResponse<Array<IJsonFinanceiroContaOrdem>> = await this._dashboardFinanceiroService.getFinanceiroContasAOrdem(force);
    return {
      type: EPlDashboardType.Table,
      title: this._plTranslateService.translate('dashboards.financeiro.tableContasAOrdem.title'),
      data: this._formatTableListaContasOrdem(response.body)
    } satisfies IPlDashboard<EPlDashboardType.Table>;
  }

  private _refreshValoresEmAberto(force: boolean): Promise<IPlDashboard<EPlDashboardType.Table>> {
    return <Promise<IPlDashboard<EPlDashboardType.Table>>>this._dashboardFinanceiroService.getFinanceiroValoresEmAberto(force).then((response: HttpResponse<Array<IJsonFinanceiroValoresEmAberto>>) => {
      return {
        type: EPlDashboardType.Table,
        title: this._plTranslateService.translate('dashboards.financeiro.tableValoresEmAberto.title'),
        data: this._formatTableListaValotesEmAberto(response.body)
      };
    });
  }

  private _refreshTableReceberClientes(force: boolean): Promise<IPlDashboard<EPlDashboardType.Table>> {
    return <Promise<IPlDashboard<EPlDashboardType.Table>>>(
      this._dashboardFinanceiroService.getFinanceiroTopValorAReceberClientes(TOP_FIVE, force).then((response: HttpResponse<Array<IJsonFinanceiroValoresPagarReceber>>) => {
        return {
          type: EPlDashboardType.Table,
          title: this._plTranslateService.translate('dashboards.financeiro.receberPagarTables.titleTableReceber'),
          data: this._formatTableListaTopReceberClientesPagarFornecedores(response.body)
        };
      })
    );
  }

  private _refreshTablePagarFornecedores(force: boolean): Promise<IPlDashboard<EPlDashboardType.Table>> {
    return <Promise<IPlDashboard<EPlDashboardType.Table>>>(
      this._dashboardFinanceiroService.getFinanceiroTopValorPagarAFornecedores(TOP_FIVE, force).then((response: HttpResponse<Array<IJsonFinanceiroValoresPagarReceber>>) => {
        return {
          type: EPlDashboardType.Table,
          title: this._plTranslateService.translate('dashboards.financeiro.receberPagarTables.titleTablePagar'),
          data: this._formatTableListaTopReceberClientesPagarFornecedores(response.body)
        };
      })
    );
  }

  private _refreshReceberDeClientes(force: boolean): Promise<void> {
    return this._dashboardFinanceiroService
      .getFinanceiroValorAReceberDeClientes(force)
      .then((response: HttpResponse<IJsonDashboardsPercentagem>) => {
        if (this._destroyed) {
          return;
        }
        this._formatChartData(EDashboardFinanceiroChartType.ReceberDeCliente, response.body.valores);
      })
      .finally(() => {
        if (!this._destroyed) {
          this._chartPieReceberDeClientesInstance.hideLoadingIndicator();
        }
      });
  }

  private _refreshPagarFornecedores(force: boolean): Promise<void> {
    return this._dashboardFinanceiroService
      .getFinanceiroValorAPagarAFornecedores(force)
      .then((response: HttpResponse<IJsonDashboardsPercentagem>) => {
        if (this._destroyed) {
          return;
        }
        this._formatChartData(EDashboardFinanceiroChartType.PagarFornecedores, response.body.valores);
      })
      .finally(() => {
        if (!this._destroyed) {
          this._chartPiePagarFornecedoresInstance.hideLoadingIndicator();
        }
      });
  }

  private _formatChartData(type: EDashboardFinanceiroChartType, data: Array<IDashboardsERPValor>): void {
    if (type === EDashboardFinanceiroChartType.ReceberDeCliente) {
      this.chartPieReceberDeClientesData = data.map<IDashboardFinanceiroPieReceberClientesPagarFornecedores>((dataItem: IDashboardsERPValor) => {
        return {
          name: dataItem.nome,
          value: dataItem.valor,
          percent: dataItem.percentagem
        };
      });
    } else if (type === EDashboardFinanceiroChartType.PagarFornecedores) {
      this.chartPiePagarFornecedoresData = data.map<IDashboardFinanceiroPieReceberClientesPagarFornecedores>((dataItem: IDashboardsERPValor) => {
        return {
          name: dataItem.nome,
          value: dataItem.valor,
          percent: dataItem.percentagem
        };
      });
    }
  }

  private _formatTableListaContasOrdem(items: Array<IJsonFinanceiroContaOrdem>): Array<object> {
    return items.map<object>((item: IJsonFinanceiroContaOrdem) => {
      const tableItem: object = {};
      tableItem[this._translateService.instant('dashboards.financeiro.tableContasAOrdem.descricao')] = item.descricao;
      tableItem[this._translateService.instant('dashboards.financeiro.tableContasAOrdem.nomeBanco')] = item.nomeBanco;
      tableItem[this._translateService.instant('dashboards.financeiro.tableContasAOrdem.iban')] = item.iban;
      tableItem[this._translateService.instant('dashboards.financeiro.tableContasAOrdem.saldoAtual')] = item.saldo;
      // todo: adicionar suporte para btn no pl-cumps
      return tableItem;
    });
  }

  private _formatTableListaValotesEmAberto(items: Array<IJsonFinanceiroValoresEmAberto>): Array<object> {
    return items.map<object>((item: IJsonFinanceiroValoresEmAberto) => {
      const tableItem: object = {};
      tableItem[this._translateService.instant(' ')] = item.descricao;
      tableItem[this._translateService.instant('dashboards.financeiro.tableValoresEmAberto.valorEmAberto')] = item.valorEmAberto;
      // tableItem[this._translateService.instant('dashboards.financeiro.tableValoresEmAberto.prazoMedio')] = item.prazoMedioDias;
      return tableItem;
    });
  }

  private _formatTableListaTopReceberClientesPagarFornecedores(items: Array<IJsonFinanceiroValoresPagarReceber>): Array<object> {
    return items.map<object>((item: IJsonFinanceiroValoresPagarReceber) => {
      const tableItem: object = {};
      tableItem[this._translateService.instant(' ')] = item.nome;
      tableItem[this._translateService.instant('dashboards.financeiro.receberPagarTables.naoVencido')] = item.naoVencido;
      tableItem[this._translateService.instant('dashboards.financeiro.receberPagarTables.vencido')] = item.vencido;
      tableItem[this._translateService.instant('dashboards.financeiro.receberPagarTables.total')] = item.total;
      return tableItem;
    });
  }
}
