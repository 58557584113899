import {LoadOptions} from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {isDefinedNotNull, isEmpty, isString} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ComunicacoesInternasModalComponent} from '../modals/comunicacoesInternas.modal.component';
import {
  devExpressDataGridExpandDetailHandler,
  devExpressDataGridFiltersToQueryFilter,
  devExpressDataGridSortToOrder
} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {DevExpressDatagridPaging} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.paging';
import {EMPTY_GUID} from '../../../../../config/constants';
import {ENTITY_NAME_PRH_COMUNICACOES_INTERNAS, IPRHComunicacoesInternasEntityService} from '../../../../entities/prhcomunicacoesinternas/pRHComunicacoesInternas.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IComunicacaoInterna} from '../comunicacoesInternas.module.interface';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonPRHComIntCab, IJsonPRHComIntLin, IJsonPRHComunicacaoInterna} from '../../../../entities/prhcomunicacoesinternas/jsonPRHComunicacaoInterna.entity.interface';
import {IJsonPRHServico} from '../../../../entities/prhservicos/jsonPRHServico.entity.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';
import {DxButtonTypes} from 'devextreme-angular/ui/button';
import {firstValueFrom} from 'rxjs';

@Component({
  selector: 'comunicacoesinternas-module',
  templateUrl: './comunicacoesInternas.module.component.html'
})
export class ComunicacoesInternasComponent extends ModuloComponent implements OnInit {
  public readonly definition: IDevExpressDataGrid;
  public readonly definitionMasterDetail: IDevExpressDataGrid;
  public readonly emptyGuid: string;
  public lido: boolean;

  private readonly _servicePRHComunicacoesInternas: IPRHComunicacoesInternasEntityService;
  private _dataGridInstance: dxDataGrid<IComunicacaoInterna, number>;
  private _dataGridPaging: DevExpressDatagridPaging;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.definition = {
      columns: [
        {dataField: 'comIntCabID', dataType: 'string', caption: 'prhcomunicacoesinternas.fields.comIntCabID', allowGrouping: false},
        {dataField: 'titulo', dataType: 'string', caption: 'prhcomunicacoesinternas.fields.titulo', allowGrouping: false},
        {dataField: 'data', dataType: 'date', caption: 'prhcomunicacoesinternas.fields.data'},
        {dataField: 'nomeEmpregado', dataType: 'string', caption: 'prhcomunicacoesinternas.fields.de'},
        {dataField: 'texto', dataType: 'string', caption: 'prhcomunicacoesinternas.fields.texto', visible: false},
        {
          width: 100,
          dataField: 'actions',
          caption: '',
          cellTemplate: 'actions',
          allowExporting: false,
          allowFiltering: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowReordering: false,
          allowSearch: false,
          allowSorting: false
        }
      ],
      dataSource: new CustomStore({
        key: 'comIntCabID',
        load: this._loadComunicacoesInternas.bind(this)
      }),
      searchPanel: {visible: true},
      hoverStateEnabled: true,
      masterDetail: {
        enabled: true,
        template: 'detail',
        autoExpandAll: false
      }
    };
    this.definitionMasterDetail = {
      columns: [
        {dataField: 'codEmp', dataType: 'string', caption: 'prhcomunicacoesinternas.masterDetail.emp'},
        {dataField: 'nomeEmp', dataType: 'string', caption: 'prhcomunicacoesinternas.masterDetail.nome'},
        {dataField: 'estadoLido', dataType: 'boolean', caption: 'prhcomunicacoesinternas.masterDetail.lido'},
        {dataField: 'dataHoraLeitura', dataType: 'datetime', caption: 'prhcomunicacoesinternas.masterDetail.dateHora'},
        {dataField: 'dgemp.codServicoPRH', dataType: 'number', visible: false, showInColumnChooser: false},
        {
          width: 100,
          dataField: 'actions',
          caption: '',
          cellTemplate: 'actions',
          allowExporting: false,
          allowFiltering: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowReordering: false,
          allowSearch: false,
          allowSorting: false
        }
      ],
      keyExpr: 'codEmp',
      searchPanel: {visible: true},
      hoverStateEnabled: true
    };
    this.emptyGuid = EMPTY_GUID;
    this._servicePRHComunicacoesInternas = this._entityServiceBuilder.build(ENTITY_NAME_PRH_COMUNICACOES_INTERNAS);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnNovo.click = this._novaComunicacaoInterna.bind(this);
    this.btnNovo.visible = true;
  }

  public onDataGridInitialized(event: IDevExpressDataGridEventOnInitialized<IComunicacaoInterna, number>): void {
    this._dataGridInstance = event.component;
  }

  public onDataGridCellClickComunicacoes(event: IDevExpressDataGridEventOnCellClick<IComunicacaoInterna>): void {
    devExpressDataGridExpandDetailHandler(event, () =>
      this._servicePRHComunicacoesInternas.get({id: event.data.comIntCabID}).then((response: HttpResponse<IJsonPRHComunicacaoInterna>) => {
        event.data.linhas = response.body.linhas.map((linha: IJsonPRHComIntLin) => {
          return {
            ...linha,
            nomeEmp: linha.dgemp.nome
          };
        });
      })
    );
  }

  public changedItemServico(item: IComunicacaoInterna, value: string | IJsonPRHServico): void {
    if (isEmpty(value) || isString(value)) {
      item.servico = undefined;
      item.filterValue = undefined;
    } else {
      item.servico = value;
      item.filterValue = ['dgemp.codServicoPRH', '=', item.servico.codServico];
    }
  }

  public async deleteComunicacao($event: DxButtonTypes.ClickEvent, comIntCabID: number, codEmp: number = undefined): Promise<void> {
    $event.event.stopImmediatePropagation();
    await this._cgModalService.showOkCancel('global.text.confirmation', 'colaboradores.comunicacoesinternas.confirmDeleteMessage');
    await this._servicePRHComunicacoesInternas.delete({id: comIntCabID, params: {codEmp: codEmp}}).then(() => {
      this._dataGridInstance.collapseAll(-1);
      this._dataGridInstance.refresh().then(() => {
        const source: Array<IComunicacaoInterna> = this._dataGridInstance?.getDataSource()?.items();
        const sourceItem = source.find((o) => o.comIntCabID === comIntCabID);
        if (isDefinedNotNull(sourceItem)) {
          this._servicePRHComunicacoesInternas.get({id: comIntCabID}).then((response: HttpResponse<IJsonPRHComunicacaoInterna>) => {
            sourceItem.linhas = response.body.linhas.map((linha: IJsonPRHComIntLin) => {
              return {
                ...linha,
                nomeEmp: linha.dgemp.nome
              };
            });
            this._dataGridInstance.navigateToRow(comIntCabID).then(() => this._dataGridInstance.expandRow(comIntCabID));
          });
        }
      });
    });
  }

  private async _loadComunicacoesInternas(loadOptions: LoadOptions): Promise<Array<IComunicacaoInterna>> {
    const search = loadOptions.filter ? devExpressDataGridFiltersToQueryFilter(this._dataGridInstance, loadOptions.filter) : undefined;
    const order: string = loadOptions.sort ? devExpressDataGridSortToOrder(loadOptions.sort) : undefined;
    if (!this._dataGridPaging) {
      this._dataGridPaging = new DevExpressDatagridPaging(this._dataGridInstance);
    }
    const {page, perPage} = this._dataGridPaging.paginate(loadOptions);
    const response: THttpQueryResponse<IJsonPRHComIntCab> = await this._servicePRHComunicacoesInternas.query({pesquisa: search, ordena: order, pagina: page, porpagina: perPage});
    const comunicacoesInternas: Array<IJsonPRHComIntCab> = response.body.list;
    const urls: Array<string> = await Promise.all(
      comunicacoesInternas.map((comunicacaoInterna: IJsonPRHComIntCab) => firstValueFrom(this._servicePRHComunicacoesInternas.downloadDocUrl(comunicacaoInterna.comIntCabID)))
    );
    return urls.map<IComunicacaoInterna>((url: string, index: number) => {
      return {
        ...comunicacoesInternas[index],
        url: url,
        linhas: []
      };
    });
  }

  private _novaComunicacaoInterna(): Promise<void> {
    return this._cgModalService.show(ComunicacoesInternasModalComponent, {size: 'lg'}).then(() => {
      this._dataGridInstance.collapseAll(-1);
      this._dataGridInstance.refresh();
    });
  }
}
