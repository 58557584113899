<div class="rhMapasSindicatos entity-detail-form">
  <pl-form>
    <pl-group>
      <label [translate]="'rhMapasSindicatos.filters.listagem'"></label>
      <edit>
        <pl-edit type="reports_mapas_sindicato" attrName="listagem" [(model)]="report"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <pl-group>
        <edit>
          <label [translate]="'rhMapasSindicatos.filters.dedata'"></label>
          <pl-edit type="date" [(model)]="filters.dataDe"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <edit>
          <label [translate]="'rhMapasSindicatos.filters.atedata'"></label>
          <pl-edit type="date" [(model)]="filters.dataAte"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <edit>
          <label [translate]="'rhMapasSindicatos.filters.deSindicato'"></label>
          <pl-edit type="cgsmallint" [(model)]="filters.deSindicato"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <edit>
          <label [translate]="'rhMapasSindicatos.filters.ateSindicato'"></label>
          <pl-edit type="cgsmallint" [(model)]="filters.ateSindicato"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>
  </pl-form>

  <hr />

  <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>
