import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonAnosFaturacao} from './jsonAnosFaturacao.entity.interface';
import {ENTITY_NAME_ANOS_FATURACAO} from './anosFaturacao.entity.interface';

export const ENTITY_ANOS_FATURACAO: IEntityDefinition<IJsonAnosFaturacao> = {
  name: ENTITY_NAME_ANOS_FATURACAO,
  roles: [ROLE.ERP],
  searchPlaceholder: 'anosFaturacao.pesquisa',
  pageTitle: 'global.menu.anosFaturacao',
  metadata: {
    keyName: 'ano',
    fields: [{name: 'ano', width: '60px', caption: 'anosFaturacao.fields.ano'}],
    order: 'ano',
    searchFields: 'ano'
  }
};
