import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {StateObject, StateService, Transition, UIRouterGlobals} from '@uirouter/core';
import {copy, isBoolean, isNumber, isObject, PlAutocompleteCache, PlGlobalEventsService} from 'pl-comps-angular';
import {AccountService} from '../account/account.service';
import {AppService} from '../app/app.service';
import {CGModalService} from '../../components/cg/modal/cgmodal.service';
import {CGStateService} from '../../components/state/cg.state.service';
import {CGTermsModalComponent} from '../../components/cg/modal/terms/terms.modal.component';
import {compareSemanticVersion} from '../../../common/utils/utils';
import {ConfigService} from '../config/config.service';
import {EAppLaunchMode} from '../../../common/site';
import {ENTITY_NAME_PORTALS, sortPortals} from '../../entities/portal/portal.entity.interface';
import {EntityServiceBuilder} from '../entity/entity.service.builder';
import {EPortal} from '../../../common/enums/portals.enums';
import {FeatureFlagService} from '../featureflag/featureflag.service';
import {generatePortalId, GLOBAL_EVENT_USER_CHANGED, isTest} from '../../../config/constants';
import {hasAnyAuthority, hasAuthority} from '../../../common/utils/roles.utils';
import {IApiQueryRequestConfig, IApiRequestConfig, IApiRequestConfigWithBody, THttpQueryResponse, TServiceResponse} from '../api/api.service.interface';
import {IAppStatus} from '../app/app.service.interface';
import {IAuthChangeEmpresaParams, IAuthRestoreStateData, IAuthServiceCheckTermsConditions} from './auth.service.interface';
import {IBlockedPluginStateParams, MODULE_NAME_BLOCKED_PLUGIN} from '../../modules/blockedplugin/blockedPlugin.module.interface';
import {ICGConfigurations} from '../config/config.service.interface';
import {ICGStateDeclaration, STATE_NAME_PORTAL} from '../portals/portals.service.interface';
import {IEntityService} from '../entity/entity.service.interface';
import {IJsonConfigERPUser} from '../../entities/configserp/jsonConfigERP.entity.interface';
import {IJsonPortal} from '../../entities/portal/jsonPortal.entity.interface';
import {IJsonUserLicenca} from '../account/jsonUserLicensa.interface';
import {IJsonUserLogin, IJsonUserLoginRequest, TRecaptchaTokenType, TUserSession} from '../account/jsonUserApi.interface';
import {ILoginStateParams, STATE_NAME_LOGIN} from '../../states/account/login/login.state.interface';
import {ISemanticVersionComparison} from '../../../common/interfaces/interfaces';
import {ROLE} from '../role.const';
import {STATE_NAME_DISCONNECTED} from '../../states/account/disconnected/disconnected.state.interface';
import {STATE_NAME_EMPRESA_BLOQUEADA} from '../../states/account/empresabloqueada/empresabloqueada.state.interface';
import {STATE_NAME_NO_AUTHORITY} from '../../states/account/noauthority/noauthority.state.interface';
import {TermsAcceptedService} from '../termsaccepted/termsaccepted.service';

const RELOAD_INVALID_STATES = [STATE_NAME_DISCONNECTED, STATE_NAME_EMPRESA_BLOQUEADA, STATE_NAME_NO_AUTHORITY];

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private readonly _servicePortals: IEntityService<IJsonPortal>;
  private readonly _subjectIdentity: BehaviorSubject<TUserSession>;
  private readonly _subscriptionStatus: Subscription;
  private _identity: TUserSession;
  private _userPortals: Array<IJsonPortal>;
  private _launchMode: EAppLaunchMode;
  private _promiseMe: Promise<TUserSession>;
  private _promiseCheckTermsConditions: Promise<void>;
  private _promiseUserPortals: Promise<Array<IJsonPortal>>;
  private _observableIdentity: Observable<TUserSession>;

  constructor(
    private readonly _stateService: StateService,
    private readonly _cgStateService: CGStateService,
    private readonly _uiRouterGlobals: UIRouterGlobals,
    private readonly _plGlobalEventsService: PlGlobalEventsService,
    private readonly _autocompleteCache: PlAutocompleteCache,
    private readonly _appService: AppService,
    private readonly _accountService: AccountService,
    private readonly _configService: ConfigService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _termsAcceptedService: TermsAcceptedService,
    private readonly _cgModalService: CGModalService,
    private readonly _featureFlagService: FeatureFlagService
  ) {
    this._servicePortals = this._entityServiceBuilder.build<IJsonPortal>(ENTITY_NAME_PORTALS);
    this._subjectIdentity = new BehaviorSubject<TUserSession>(this._identity);
    this._launchMode = EAppLaunchMode.Default;
    this._subscriptionStatus = this._appService.status().subscribe((status: IAppStatus) => {
      this._launchMode = status.launchMode;
    });
  }

  public ngOnDestroy(): void {
    this._subjectIdentity.complete();
    this._subscriptionStatus.unsubscribe();
  }

  public async login(username: string, password: string, recaptchaType: TRecaptchaTokenType, recaptchaToken: string, config?: IApiRequestConfigWithBody<IJsonUserLoginRequest>): Promise<TUserSession> {
    const response: HttpResponse<IJsonUserLogin> = await this._accountService.login(username, password, recaptchaType, recaptchaToken, config);
    this._setIdentity(response.body, {skip: true, cancelable: true});
    await this._checkTermsConditions(true);
    await this._loginOk();
    return this._identity;
  }

  public logout(): Promise<void> {
    return this._accountService.logout().then(() => {
      this._setIdentity(undefined);
    });
  }

  public resetPassword(username: string): TServiceResponse<void> {
    return this._accountService.resetPassword(username);
  }

  public changePassword(password: string, confirmPassword: string, token: string): TServiceResponse<void> {
    return this._accountService.changePassword(password, confirmPassword, token);
  }

  public sendInstructionsNewUser(password: string): TServiceResponse<void> {
    return this._accountService.sendInstructionsNewUser(password);
  }

  public authenticate(force: boolean = false, restoreStateData?: IAuthRestoreStateData<unknown>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const state: ICGStateDeclaration = <ICGStateDeclaration>(this._uiRouterGlobals.transition?.to() || this._uiRouterGlobals.current);
      if (!state) {
        if (force) {
          this.goLogin(restoreStateData).finally(resolve);
          return;
        }
        resolve();
        return;
      }
      if (state.name !== STATE_NAME_DISCONNECTED && !state.data?.roles) {
        resolve();
        return;
      }
      this.identity(force)
        .then(() => {
          if (!this._identity) {
            this.goLogin(restoreStateData).finally(resolve);
          } else if (!hasAnyAuthority(this._identity, state.data.roles)) {
            this._stateService.go(STATE_NAME_NO_AUTHORITY).finally(reject);
          } else if (!hasAuthority(this._identity, state.data.requiredRoles) || !hasAnyAuthority(this._identity, state.data.pluginsRoles)) {
            const params: IBlockedPluginStateParams = {
              pageTitle: state.data.pageTitle,
              requiredRoles: state.data.requiredRoles.slice(),
              pluginRoles: state.data.pluginsRoles.slice()
            };
            this._cgStateService.redirectToState({stateOrName: MODULE_NAME_BLOCKED_PLUGIN, params: params}).finally(reject);
          } else {
            this._checkTermsConditions(false).finally(resolve);
          }
        })
        .catch(() => {
          this.goLogin(restoreStateData).finally(resolve);
        });
    });
  }

  public identity(force: boolean = false, config?: IApiRequestConfig): Promise<TUserSession> {
    // Check and see if we have retrieved the identity data from the server.
    // If we have, reuse it by immediately resolving
    if (!force && isObject(this._identity)) {
      return Promise.resolve(copy(this._identity));
    }
    if (!this._promiseMe) {
      this._promiseMe = new Promise<TUserSession>((resolve, reject) => {
        // Retrieve the identity data from the server, update the identity object, and then resolve.
        this._accountService
          .getMe(force, {...config, reportExceptions: false})
          .then((response) => {
            this._setIdentity(response.body);
            this._loginOk()
              .then(() => {
                resolve(copy(this._identity));
              })
              .catch((reason: HttpErrorResponse) => {
                this._setIdentity(undefined);
                reject(reason);
              });
          })
          .catch((reason: HttpErrorResponse) => {
            this._setIdentity(undefined);
            reject(reason);
          });
      });
      this._promiseMe.finally(() => {
        this._promiseMe = undefined;
      });
    }
    return this._promiseMe;
  }

  public identityAsObservable(): Observable<TUserSession> {
    if (!this._observableIdentity) {
      this._observableIdentity = this._subjectIdentity.asObservable();
    }
    return this._observableIdentity;
  }

  public licenca(): TServiceResponse<IJsonUserLicenca> {
    return this._accountService.licenca();
  }

  public accesses(empresaOuEmpresas?: string | Array<string>): TServiceResponse<Array<IJsonConfigERPUser>> {
    return this._accountService.accesses(empresaOuEmpresas);
  }

  public hasAuthority(roleOrRoles: string | ROLE | Array<string | ROLE>): Promise<boolean> {
    return this.identity().then((session: TUserSession) => hasAuthority(session, roleOrRoles));
  }

  public hasAnyAuthority(roleOrRoles: string | ROLE | Array<string | ROLE>): Promise<boolean> {
    return this.identity().then((session: TUserSession) => hasAnyAuthority(session, roleOrRoles));
  }

  public isAdmin(): Promise<boolean> {
    return this.hasAuthority(ROLE.ADMIN);
  }

  public goLogin(restoreStateData?: IAuthRestoreStateData<unknown>): Promise<void> {
    this._cgModalService.dismissAll('Session is invalid, redirecting to login page.');
    const {stateName, params, data} = restoreStateData;
    let loginStateParams: ILoginStateParams = stateName ? (!isObject(data) || data.disableRecover !== true ? {redirectto: stateName, redirectparams: params} : undefined) : undefined;
    const currentTransition: Transition = this._uiRouterGlobals.transition;
    if (!currentTransition) {
      return this._stateService.go(STATE_NAME_LOGIN, loginStateParams).then(() => undefined);
    }
    return new Promise<void>((resolve, reject) => {
      Promise.resolve(currentTransition.promise)
        .catch(() => {
          const state: StateObject = currentTransition.$to();
          if (state.name !== STATE_NAME_PORTAL) {
            loginStateParams = {redirectto: state.name, redirectparams: currentTransition.params()};
          }
        })
        .finally(() => {
          this._stateService
            .go(STATE_NAME_LOGIN, loginStateParams)
            .then(() => {
              resolve();
            })
            .catch(reject);
        });
    });
  }

  public async changeEmpresa({cgId, nEmpresa}: IAuthChangeEmpresaParams): Promise<TUserSession> {
    this._appService.triggerPageUnload();
    const response: HttpResponse<TUserSession> =
      this._launchMode !== EAppLaunchMode.Hybrid && this._launchMode !== EAppLaunchMode.HybridPartial
        ? await this._accountService.changeEmpresa(cgId)
        : await this._accountService.loginEmpresa(nEmpresa);
    this._setIdentity(response.body);
    await this._loginOk();
    const currentStateName: string = this._uiRouterGlobals.current.name;
    if (!RELOAD_INVALID_STATES.includes(currentStateName)) {
      await this._stateService.reload().catch(() => this._cgStateService.goHome());
    } else {
      await this._cgStateService.goHome();
    }
    return this._identity;
  }

  public userPortals(force: boolean = false, config?: IApiQueryRequestConfig): Promise<Array<IJsonPortal>> {
    if (force || !this._userPortals) {
      if (!this._promiseUserPortals) {
        this._promiseUserPortals = new Promise<Array<IJsonPortal>>((resolve, reject) => {
          this._servicePortals
            .query(config)
            .then((response: THttpQueryResponse<IJsonPortal>) => {
              this._userPortals = sortPortals(response.body.list);
              resolve(this._userPortals);
            })
            .catch((reason: HttpErrorResponse) => {
              this._userPortals = undefined;
              reject(reason);
            });
        });
      }
    }
    return this._promiseUserPortals;
  }

  public getPortalId(portal: EPortal, force?: boolean): Promise<number> {
    return this.getPortalsIds([portal], force).then((portalsIds: Array<number>) => {
      return portalsIds.length ? portalsIds[0] : undefined;
    });
  }

  public getAndGeneratePortalRole(portal: EPortal, force?: boolean): Promise<ROLE> {
    return this.getPortalId(portal, force).then((portalId: number) => {
      return isNumber(portalId) ? generatePortalId(portalId) : undefined;
    });
  }

  public getPortalsIds(portals: Array<EPortal>, force?: boolean): Promise<Array<number>> {
    return this.userPortals(force).then((userPortals: Array<IJsonPortal>) => {
      const portalsIds: Array<number> = [];
      for (const portalUrl of portals) {
        const userPortal = userPortals.find((portalItem: IJsonPortal) => portalItem.url.toLowerCase() === portalUrl.toLowerCase());
        const portalId: number = userPortal ? userPortal.id : undefined;
        portalsIds.push(portalId);
      }
      return portalsIds;
    });
  }

  public getAndGeneratePortalsRole(portals: Array<EPortal>, force?: boolean): Promise<Array<ROLE>> {
    return this.getPortalsIds(portals, force).then((portalsIds: Array<number>) => {
      return portalsIds.map<ROLE>((portalId: number) => (isNumber(portalId) ? generatePortalId(portalId) : undefined));
    });
  }

  /**
   * @description Do not use this directly. Use method {@link identity} instead.
   * @see {@link AuthService.identity}.
   */
  public get identityValue(): TUserSession {
    return this._identity;
  }

  private _loginOk(): Promise<ICGConfigurations> {
    if (!this._identity) {
      return Promise.reject(new Error('Invalid identity data.'));
    }
    // TODO: store language preference in user configuration
    // this._translateService.use(this._identity.langKey);
    // Carregar configuração
    return this._configService.getConfigurations(true);
  }

  private _setIdentity(value: TUserSession, checkTermsConditions?: IAuthServiceCheckTermsConditions): void {
    if (!isObject(checkTermsConditions)) {
      checkTermsConditions = {skip: false, cancelable: false};
    }
    if (!isBoolean(checkTermsConditions.skip)) {
      checkTermsConditions.skip = false;
    }
    if (!isBoolean(checkTermsConditions.cancelable)) {
      checkTermsConditions.cancelable = false;
    }
    const {skip, cancelable} = checkTermsConditions;
    this._userPortals = undefined;
    this._autocompleteCache.clear();
    this._identity = value;
    this._subjectIdentity.next(this._identity);
    this._featureFlagService.updateContext({userId: this._identity?.userId});
    if (!skip) {
      this._checkTermsConditions(cancelable);
    }
    this._plGlobalEventsService.broadcast(GLOBAL_EVENT_USER_CHANGED);
  }

  private _checkTermsConditions(cancelable: boolean): Promise<void> {
    if (isObject(this._identity) && !isTest()) {
      if (!this._promiseCheckTermsConditions) {
        this._promiseCheckTermsConditions = (async () => {
          if (this._launchMode !== EAppLaunchMode.Default) {
            return;
          }
          const currentTermsRGPDVersion: string = await this._termsAcceptedService.termsRGPDVersion();
          const termsComparison: ISemanticVersionComparison = compareSemanticVersion(this._identity.termsAccepted, currentTermsRGPDVersion);
          if (!this._identity.termsAccepted || termsComparison.less()) {
            const modalInstance: NgbModalRef = this._cgModalService.showVanilla(CGTermsModalComponent, {backdrop: 'static'});
            const componentInstance: CGTermsModalComponent = modalInstance.componentInstance;
            componentInstance.session = copy(this._identity);
            componentInstance.cancelable = cancelable;
            await modalInstance.result;
            await this.identity(true);
          }
        })();
        this._promiseCheckTermsConditions.finally(() => {
          this._promiseCheckTermsConditions = undefined;
        });
      }
      return this._promiseCheckTermsConditions;
    }
    return Promise.resolve();
  }
}
