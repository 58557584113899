import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonGrAbono} from './jsonGrAbonos.entity.interface';
import {DATA_SOURCE_NAME_TIPOS_CATEGORIA} from '../../datasources/tipocategoria/tipoCategoria.datasource.interface';
import {DATA_SOURCE_NAME_TIPOS_REMUNERADO} from '../../datasources/tiporemunerado/tipoRemunerado.datasource.interface';
import {DATA_SOURCE_NAME_TIPOS_CONTA_DIAS_SUB_ALIMEN} from '../../datasources/tipodiascontasubalimen/tipoContaDiasSubAlimen.datasource.interface';
import {ENTITY_NAME_GR_ABONOS} from './grAbonos.entity.interface';

export const ENTITY_GR_ABONOS: IEntityDefinition<IJsonGrAbono> = {
  name: ENTITY_NAME_GR_ABONOS,
  roles: [ROLE.RH],
  searchPlaceholder: 'grabonos.pesquisa',
  metadata: {
    keyName: 'codGrAbono',
    fields: [
      {name: 'codGrAbono', type: 'string', caption: 'grabonos.fields.codGrAbono', validators: {required: true}},
      {name: 'descricao', type: 'string', caption: 'grabonos.fields.descricao', validators: {required: true}},
      {name: 'categoria', type: DATA_SOURCE_NAME_TIPOS_CATEGORIA, caption: 'grabonos.fields.categoria'},
      {name: 'remunerado', type: DATA_SOURCE_NAME_TIPOS_REMUNERADO, caption: 'grabonos.fields.remunerado'},
      {name: 'contaDiasSubAlimen', type: DATA_SOURCE_NAME_TIPOS_CONTA_DIAS_SUB_ALIMEN, caption: 'grabonos.fields.contaDiasSubAlimen'}
    ],
    order: 'codGrAbono',
    searchFields: 'codGrAbono,descricao',
    listFields: 'codGrAbono,descricao'
  },
  autocomplete: {
    rowTemplate: '{{codGrAbono}} - {{descricao}}',
    output: 'descricao',
    searchFields: 'codGrAbono,descricao'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.grabonos'
      }
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.grabonos'
      }
    },
    template: {
      items: [
        {type: 'field', field: 'codGrAbono', order: 1},
        {type: 'field', field: 'descricao', order: 2},
        {type: 'field', field: 'categoria', order: 3},
        {type: 'field', field: 'tipoTrabalho', order: 4},
        {type: 'field', field: 'balancoSocial', order: 5},
        {type: 'group', order: 6, fields: [{field: 'remunerado'}, {field: 'contaDiasSubAlimen'}]}
      ]
    }
  }
};
