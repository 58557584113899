import {Subscription} from 'rxjs';
import {Component, OnDestroy} from '@angular/core';
import {deepFreeze} from 'pl-comps-angular';
import {APP_LOCALES} from '../../../../../common/i18n/locales';
import {AppService} from '../../../../services/app/app.service';
import {IDataSourceItem} from '../../../datasource/datasources.interface';

const LANGUAGES: ReadonlyArray<IDataSourceItem<string>> = deepFreeze(
  APP_LOCALES.map<IDataSourceItem<string>>((locale: string) => ({
    value: locale,
    label: `locale.descriptive.${locale}`
  }))
);

let COUNT = 0;

@Component({
  selector: 'cg-user-locale-chooser',
  templateUrl: './cg.user.locale.chooser.component.html'
})
export class CGUserLocaleChooserComponent implements OnDestroy {
  public readonly locales: ReadonlyArray<IDataSourceItem<string>>;
  public readonly count: number;
  public locale: string;

  private readonly _subscriptionLocale: Subscription;

  constructor(private readonly _appService: AppService) {
    this.locales = LANGUAGES;
    this.count = ++COUNT;

    this._subscriptionLocale = this._appService.locale().subscribe((locale: string) => {
      this.locale = locale;
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionLocale.unsubscribe();
  }

  public async changedLocale(locale: string): Promise<void> {
    await this._appService.setLocale(locale);
  }
}
