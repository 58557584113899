<pl-navpill attrName="navPillCategorias" (evtSelected)="categoriaSelected($event)">
  <pl-navpill-panel *ngFor="let categoria of categorias; index as i" [id]="categoria.value.toString()" [caption]="categoria.name">
    <div *plNavPillPanelContent class="entity-detail-form" [plPromise]="myPromise">
      <pl-form>
        <pl-group>
          <label [translate]="'perfilCategoriaAbono.fields.tipoArredondamento'"></label>
          <edit>
            <pl-edit [type]="'tipoArrendodamentoABDES'" [model]="model.tipoArredondamento"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'perfilCategoriaAbono.fields.tipoRecolha'"></label>
            <edit>
              <pl-edit [type]="'tipoValorUnitarioRecolher'" [model]="model.tipoRecolha"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'perfilCategoriaAbono.fields.contribuiCalculoCustoPrecoH'"></label>
            <edit>
              <pl-edit-checkbox [(model)]="model.contribuiCalculoCustoPrecoH"></pl-edit-checkbox>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <label [translate]="'perfilCategoriaAbono.fields.codRem'"></label>
          <edit>
            <entity-autocomplete
              entity="sscol"
              [model]="model"
              [(selectedKey)]="model.codRem"
              (evtSelectedDescriptionChanged)="model.nomeCodRem = $event"
              [fieldsMap]="{codSSCol: 'codRem', designacao: 'nomeCodRem'}"
              output="description">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'perfilCategoriaAbono.fields.codUnidade'"></label>
          <edit>
            <entity-autocomplete
              entity="rhunidades"
              [model]="model"
              [(selectedKey)]="model.unidadeDefeito"
              (evtSelectedDescriptionChanged)="model.nomeUnidadeDefeito = $event"
              [fieldsMap]="{codUnidade: 'unidadeDefeito', nome: 'nomeUnidadeDefeito'}"
              output="description">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'perfilCategoriaAbono.fields.tipoRendimento'"></label>
          <edit>
            <entity-autocomplete
              entity="rendis"
              [model]="model"
              (selectedKeyChange)="model.tipoRendimento = $event"
              (evtSelectedDescriptionChanged)="model.nomeTipoRendimento = $event"
              [fieldsMap]="{tipRendiment: 'tipoRendimento', nome: 'nomeTipoRendimento'}"
              outputKey="tipRendiment"
              output="description">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'perfilCategoriaAbono.fields.contaMovDeb'"></label>
          <edit>
            <entity-autocomplete entity="pocs" [model]="model" [(selectedKey)]="model.nContaDebitoEmp" [fieldsMap]="{nConta: 'nContaDebitoEmp'}" output="key"> </entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'perfilCategoriaAbono.fields.contaMovCred'"></label>
          <edit>
            <entity-autocomplete entity="pocs" [model]="model" [(selectedKey)]="model.nContaCreditoEmp" [fieldsMap]="{nConta: 'nContaCreditoEmp'}" output="key"> </entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'perfilCategoriaAbono.fields.contaEncDEB'"></label>
          <edit>
            <entity-autocomplete entity="pocs" [model]="model" [(selectedKey)]="model.nContaDebitoPatronato" [fieldsMap]="{nConta: 'nContaDebitoPatronato'}" output="key"> </entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'perfilCategoriaAbono.fields.contaEncCRE'"></label>
          <edit>
            <entity-autocomplete entity="pocs" [model]="model" [(selectedKey)]="model.nContaCreditoPatronato" [fieldsMap]="{nConta: 'nContaCreditoPatronato'}" output="key"> </entity-autocomplete>
          </edit>
        </pl-group>

        <dx-data-grid class="mt-4" [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="perfisCategoriasAbonos" [dataSource]="model.cruzamentos"> </dx-data-grid>
      </pl-form>
    </div>
  </pl-navpill-panel>
</pl-navpill>
