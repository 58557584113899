import {take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {copy, PlLocationService} from 'pl-comps-angular';
import {AuthManagerService} from '../../../services/auth/auth.manager.service';
import {AuthService} from '../../../services/auth/auth.service';
import {ConfigService} from '../../../services/config/config.service';
import {IAssistenteConfigPortal, MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS} from './assistente.configPortais.module.service.interface';
import {ICGConfigurations} from '../../../services/config/config.service.interface';
import {IJsonBoolean} from '../../../../common/interfaces/json';
import {IJsonEmpresaErp} from '../../../interfaces/jsonEmpresa.interface';
import {IJsonErpUser, IJsonUserEmpresa, IJsonUserNew, TUserSession} from '../../../services/account/jsonUserApi.interface';
import {IJsonGDocEmailConfig} from './jsonGdocEmailConfig.module.interface';
import {MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_PORTAL_CLIENT_CONNECT_UTILIZADOR_EXISTENTE} from './portais/portalclientconnect/utilizadorexistente/assistConfigPortais.portalClientConnect.utilizadorExistente.module.interface';
import {MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_PORTAL_CLIENT_CONNECT_UTILIZADOR} from './portais/portalclientconnect/utilizador/assistConfigPortais.portalClientConnect.utilizador.module.interface';
import {ROLE} from '../../../services/role.const';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssistenteConfigPortaisService {
  private readonly _moduleSelectConfigPortal: string;
  private readonly _availablePortals: Array<IAssistenteConfigPortal>;

  constructor(
    private readonly _plLocationService: PlLocationService,
    private readonly _authManagerService: AuthManagerService,
    private readonly _authService: AuthService,
    private readonly _configService: ConfigService
  ) {
    this._moduleSelectConfigPortal = MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS;
    this._availablePortals = [
      {
        icon: 'fa fa-fw fa-wrench',
        title: 'portals.text.geral',
        name: 'geral',
        operations: [{title: 'assistenteconfigportais.pages.email', moduleName: 'assistConfigPortaisGeralEmail'}]
      },
      {
        icon: 'fa fa-fw fa-file-text',
        title: 'portals.items.pca',
        name: 'myaccounting',
        operations: [
          {title: 'assistenteconfigportais.operations.myAccounting.addUser', moduleName: 'assistConfigPortaisUtilizador'},
          {title: 'assistenteconfigportais.operations.myAccounting.addEmpresa', moduleName: 'assistConfigPortaisEmpresa'},
          {title: 'assistenteconfigportais.operations.myAccounting.associarEmpregado', moduleName: 'assistConfigPortaisEmpregado'}
        ]
      },
      {
        icon: 'fa fa-fw fa-puzzle-piece',
        title: 'portals.items.erp',
        name: 'erp',
        operations: []
      },
      {
        icon: 'fa fa-fw fa-suitcase',
        title: 'portals.items.contabilidade',
        name: 'contabilidade',
        operations: []
      },
      {
        icon: 'fa fa-fw fa-connectdevelop',
        title: 'portals.items.clientconnect',
        name: 'clientconnect',
        operations: [
          {
            title: 'assistenteconfigportais.operations.portalclientconnect.createUser',
            moduleName: MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_PORTAL_CLIENT_CONNECT_UTILIZADOR
          },
          {
            title: 'assistenteconfigportais.operations.portalclientconnect.addUser',
            moduleName: MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_PORTAL_CLIENT_CONNECT_UTILIZADOR_EXISTENTE
          }
        ]
      }
    ];
  }

  public getModuleSelectPortal(): string {
    return this._moduleSelectConfigPortal;
  }

  public async getPortais(): Promise<Array<IAssistenteConfigPortal>> {
    const session: TUserSession = await this._authService.identity();
    const configurations: ICGConfigurations = await firstValueFrom(this._configService.configurationsAsObservable().pipe(take(1)));
    const portals: Array<IAssistenteConfigPortal> = [];

    let acessoMyAccounting = false;
    for (const userRole of session.roles) {
      if ((userRole.role === ROLE.PCA || userRole.role === ROLE.ADMINBASIC) && !session.idContratoPai) {
        acessoMyAccounting = true;
        break;
      }
    }

    for (const availablePortal of this._availablePortals) {
      if (
        ((availablePortal.name === 'myaccounting' && configurations.licenca.clientPortalAccount > 0 && acessoMyAccounting) ||
          availablePortal.name === 'geral' ||
          availablePortal.name === 'clientconnect') &&
        availablePortal.operations.length > 0
      ) {
        portals.push(availablePortal);
      }
    }

    return copy<Array<IAssistenteConfigPortal>>(portals);
  }

  public generateFilterEmpresas(centralGestId: number, nEmpresa: string, erps: Array<IJsonErpUser>): string {
    let filter = `EmpresaGabinete=0&(NEmpresaGabinete=${nEmpresa})`;
    const filterEmpresas: string = erps
      .filter((erp: IJsonErpUser) => erp.centralGestId === centralGestId)
      .reduce<string>((accumulator: string, erp: IJsonErpUser) => {
        const filterEmpresa = `nempresa=${erp.nEmpresa}`;
        return accumulator ? `${accumulator}|${filterEmpresa}` : filterEmpresa;
      }, '');
    if (filterEmpresas) {
      filter += `&(${filterEmpresas})`;
    }
    return filter;
  }

  public setNewConfigurationUserEmpresaPortal(model: IJsonUserNew, erpName: string, nEmpresaGabinete: string, nEmpresaCliente: string): TServiceResponse<IJsonUserEmpresa> {
    const url = `${this._plLocationService.getUrl()}/changepassword`;
    return this._authManagerService.usersPostGabineteERP(model, erpName, nEmpresaGabinete, nEmpresaCliente, false, url);
  }

  public setEmpresaClienteToEmpresaGabinete(centralgestid: number, erpName: string, nEmpresaGabinete: string, nEmpresaCliente: string): TServiceResponse<IJsonEmpresaErp> {
    const url = `${this._plLocationService.getUrl()}/changepassword`;
    return this._authManagerService.putEmpresaCliConfig(centralgestid, erpName, nEmpresaGabinete, nEmpresaCliente, false, url);
  }

  public associateEmpregadoToUser(userId: number, model: IJsonUserEmpresa): TServiceResponse<IJsonUserEmpresa> {
    return this._authManagerService.updateUser(userId, model, false);
  }

  public setConfiguracaoGDocEmailEmpresa(centralgestid: number, erpName: string, nEmpresa: string, gDocEmailConfigs: Array<IJsonGDocEmailConfig>): TServiceResponse<IJsonBoolean> {
    return this._authManagerService.putEmpresaEmailConfig(centralgestid, erpName, nEmpresa, gDocEmailConfigs);
  }
}
