import {EContratoLockedReason} from '../../../common/enums/contratolockedreason.enum';
import {EPortal} from '../../../common/enums/portals.enums';
import {IJsonConfigERPUser} from '../../entities/configserp/jsonConfigERP.entity.interface';
import {ROLE} from '../role.const';

export enum EErpUserRoleMotivo {
  None,
  Expired
}

export type TUserSession = Readonly<IJsonUserLogin>;

export type TRecaptchaTokenType = 'v2' | 'v3';

export interface IJsonErpUser {
  cgID: number;
  centralGestId: number;
  name: string;
  apiUrl: string;
  nEmpresa: string;
  nUtilizador: number;
  ativo: boolean;
  nomeEmpresa: string;
  nomeUtilizadorCG: string;
  codEmp: number;
  nomeEmpregado: string;
  rolesAcess: Array<IJsonUserRole>;
  rolesNotAcess: Array<IJsonUserRole>;
}

export interface IJsonUser {
  userId: number;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  langKey: string;
  active: boolean;
  idContratoPai: number;
  lockedReason: EContratoLockedReason;
  termsAccepted: string;
}

export interface IJsonUserRole {
  role: ROLE;
  description?: string;
  regex?: string;
  motivo?: EErpUserRoleMotivo;
}

export interface IJsonUserEmpresa extends IJsonUser {
  erps: Array<IJsonErpUser>;
  lasterps: Array<IJsonErpUser>;
  erp: IJsonErpUser;
  numEmpresas: number;
  roles: Array<IJsonUserRole>;
  portais: Array<IJsonUserPortal>;
}

export interface IJsonUserPortal {
  id: number;
  url: EPortal;
}

export interface IJsonUserEmpresaWithAccesses extends IJsonUserEmpresa {
  acessosErpCloud: Array<IJsonConfigERPUser>;
}

export interface IJsonUserLogin extends IJsonUserEmpresa {
  token: string;
}

export interface IJsonUserNew extends IJsonUserEmpresa {
  password: string;
  sendEmail: boolean;
}

export interface IJsonUserNewWithAccesses extends IJsonUserNew {
  createUtilizador: boolean;
  acessosErpCloud: Array<IJsonConfigERPUser>;
}

export interface IJsonUserLoginRequest {
  username: string;
  password: string;
}

export interface IJsonUserPassword {
  password: string;
}

export interface IJsonUserResetPassword {
  newPassword: string;
  token: string;
}
