import {merge} from 'lodash-es';
import type {Subscription} from 'rxjs';
import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, FormGroupDirective, UntypedFormControl, ValidationErrors} from '@angular/forms';
import {interpolate, isFunction, isObject, isUndefinedOrNull} from '../../../common/utilities/utilities';
import type {IPlLocale} from '../../../common/locale/locales.interface';
import type {IPlMessages, IPlMessagesValidator, TPlValidators} from './messages.component.interface';
import type {IPlValidatorValidateParams, TPlValidatorsRegistry} from '../../../validate/validate.interface';
import {Logger} from '../../../logger/logger';
import {PlEditInputComponent} from '../../generic/input/edit.input.component';
import {PlEditInputTypeComponent} from '../../generic/input/edit.input.type.component';
import {PlLocaleService} from '../../../common/locale/locale.service';
import {PlTranslateService} from '../../../translate/translate.service';
import {PlValidatorRegistryService} from '../../../validate/validate.service';

@Component({
  selector: 'pl-messages',
  templateUrl: './messages.component.html'
})
export class PlMessagesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public instance: PlEditInputComponent<any>;
  @Input() public formControlInstance: UntypedFormControl;
  @Input() public modelValue: any;
  @Input() public validate: boolean;
  @Input() public validators: TPlValidators;
  @Input() public ngFormInstance: FormGroupDirective;

  public messages: IPlMessages;
  public messagesKeys: Array<string>;

  private readonly _element: HTMLElement;
  private readonly _subscriptionLocale: Subscription;
  private _locales: IPlLocale;
  private _defaultValidators: IPlMessages;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _logger: Logger,
    private readonly _plLocaleService: PlLocaleService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plValidatorRegistryService: PlValidatorRegistryService
  ) {
    this._element = this._elementRef.nativeElement;
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this._locales = locale;
      this._defaultValidators = Object.freeze<IPlMessages>({
        date: {value: undefined, message: this._locales.validators.date, validate: undefined, error: undefined},
        datetimelocal: {value: undefined, message: this._locales.validators.datetimelocal, validate: undefined, error: undefined},
        email: {value: undefined, message: this._locales.validators.email, validate: undefined, error: undefined},
        max: {value: undefined, message: this._locales.validators.max, validate: undefined, error: undefined},
        maxlength: {value: undefined, message: this._locales.validators.maxlength, validate: undefined, error: undefined},
        maxselected: {value: undefined, message: this._locales.validators.maxSelected, validate: undefined, error: undefined},
        min: {value: undefined, message: this._locales.validators.min, validate: undefined, error: undefined},
        minlength: {value: undefined, message: this._locales.validators.minlength, validate: undefined, error: undefined},
        minselected: {value: undefined, message: this._locales.validators.minSelected, validate: undefined, error: undefined},
        month: {value: undefined, message: this._locales.validators.month, validate: undefined, error: undefined},
        number: {value: undefined, message: this._locales.validators.number, validate: undefined, error: undefined},
        pattern: {value: undefined, message: this._locales.validators.pattern, validate: undefined, error: undefined},
        required: {value: false, message: this._locales.validators.required, validate: undefined, error: undefined},
        time: {value: undefined, message: this._locales.validators.time, validate: undefined, error: undefined},
        url: {value: undefined, message: this._locales.validators.url, validate: undefined, error: undefined},
        week: {value: undefined, message: this._locales.validators.week, validate: undefined, error: undefined},
        /* Not real validators, but are here to avoid errors */
        decimals: {value: undefined, message: '', validate: undefined, error: undefined}
      });
    });
  }

  public ngOnInit(): void {
    this._handleChanges();
    this._transformMessages();
    this._customValidators();
  }

  public ngOnChanges({validators}: SimpleChanges): void {
    if (validators && !validators.isFirstChange()) {
      this._changedValidators(validators.currentValue);
      this._mergeValidators(validators.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionLocale.unsubscribe();
  }

  public inputTarget(): HTMLInputElement {
    return this.instance && this.instance instanceof PlEditInputTypeComponent ? this.instance.inputField : this._element?.parentElement?.querySelector<HTMLInputElement>('input,textarea');
  }

  private _transformMessages(messages: object = this.messages): void {
    for (const key of Object.keys(messages)) {
      let option = messages[key];
      if (option) {
        if (isFunction(option)) {
          option = messages[key] = option();
        }
        if (!isObject(option)) {
          option = {value: option};
        }
        if (!option.message && this._defaultValidators[key]) {
          option.message = this._defaultValidators[key].message;
        }
        // Method evaluated messages have to be defined later
        if (!isFunction(option.message)) {
          option.error = this._plTranslateService.translate(interpolate(option.message || '')(option));
        } else {
          const params: IPlValidatorValidateParams = {
            formControlValue: this.formControlInstance.value,
            modelValue: this.modelValue,
            formControlInstance: this.formControlInstance,
            instance: this.instance,
            plValidatorValue: option.value,
            inputTarget: this.inputTarget()
          };
          option.error = option.message(params);
        }
      }
    }
  }

  private _customValidators(): void {
    const asyncValidators: Array<AsyncValidatorFn> = [];
    for (const property of Object.keys(this.messages)) {
      if (!this._defaultValidators[property]) {
        asyncValidators.push((control: AbstractControl) => {
          return new Promise<ValidationErrors>((resolve, reject) => {
            let validator = this.messages[property];
            if (isFunction(validator)) {
              validator = (<any>validator)();
            }
            Promise.resolve(validator).then((responseValidator: IPlMessagesValidator<any, any>) => {
              this.messages[property] = responseValidator;

              if (this.validate === false) {
                resolve(undefined);
              } else {
                const validationError = this._validationError(property);
                const inputTarget: HTMLInputElement = this.inputTarget();
                const validateParams: IPlValidatorValidateParams = {
                  formControlValue: control.value,
                  modelValue: this.modelValue,
                  formControlInstance: this.formControlInstance,
                  instance: this.instance,
                  plValidatorValue: responseValidator.value,
                  inputTarget: inputTarget
                };
                let error: string | Promise<string>;
                if (isFunction(responseValidator.message)) {
                  error = responseValidator.message(validateParams);
                }
                Promise.resolve<string>(error)
                  .then((evaluatedError: string) => {
                    if (evaluatedError) {
                      responseValidator.error = evaluatedError;
                    }
                    if (isUndefinedOrNull(responseValidator.validate)) {
                      resolve(undefined);
                    } else if (isFunction(responseValidator.validate)) {
                      // Validation function
                      try {
                        const promise = Promise.resolve(responseValidator.validate(validateParams));
                        if (!isFunction(responseValidator.callback)) {
                          promise.then((isValid) => {
                            resolve(validationError(isValid));
                          });
                        }
                        // In case a need for manual response handling arises
                        else {
                          Promise.resolve(responseValidator.callback(promise))
                            .then((isValid) => {
                              resolve(validationError(isValid));
                            })
                            // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
                            .catch(reject);
                        }
                      } catch (exception: unknown) {
                        this._logger.warn(
                          `An error was thrown while trying to validate the component {${
                            inputTarget.attributes.getNamedItem('name').value
                          }} with validation {${property}}. Check the trace log for more information.`
                        );
                        this._logger.error(exception);
                        resolve(undefined);
                      }
                    }
                  })
                  // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
                  .catch(reject);
              }
            });
          });
        });
      }
    }
    this.instance.setAsyncValidators(asyncValidators);
  }

  private _validationError(validator: string): (valid: boolean) => ValidationErrors {
    return (valid: boolean) => {
      if (valid !== false) {
        return undefined;
      }
      const validationError: object = {};
      validationError[validator] = true;
      return validationError;
    };
  }

  private _handleChanges(): void {
    this._changedValidators();
  }

  private _changedValidators(value: TPlValidators = this.validators): void {
    this.validators = value;
    const validators: TPlValidatorsRegistry = this._plValidatorRegistryService.getAll();
    this.messages = merge({}, this._defaultValidators, Object.fromEntries(validators), this.validators);
    this.messagesKeys = Object.keys(this.messages);
  }

  private _mergeValidators(toMerge: TPlValidators): void {
    this._transformMessages(toMerge);
    let runCustomValidators = false;
    for (const key of Object.keys(toMerge)) {
      if (!runCustomValidators && !(key in this._defaultValidators)) {
        runCustomValidators = true;
      }
      this.messages[key] = <IPlMessagesValidator>toMerge[key];
    }
    if (runCustomValidators) {
      this._customValidators();
    }
  }
}
