import {find, findLast, merge} from 'lodash-es';
import {combineLatest, Subscription} from 'rxjs';
import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {animateWith, getElementOffset} from '../common/utilities/dom.utilities';
import {copy, debounce, isArray, isBoolean, isFunction, isObject} from '../common/utilities/utilities';
import {
  DEFAULT_NAV_WIZARD_DEFINITION,
  DEFAULT_NAV_WIZARD_ORIENTATION,
  IPlNavWizardCallback,
  IPlNavWizardDefinition,
  IPlNavWizardEventStep,
  IPlNavWizardInstance,
  IPlNavWizardOptions,
  IPlNavWizardStep,
  TPlNavWizardBeforeStepChangeFn,
  TPlNavWizardOnFinalizeFn
} from './navwizard.interface';
import {getResponseScreenSize} from '../common/interface';
import type {IPlPromisesServiceConfiguration} from '../promises/promises.service.interface';
import {Logger} from '../logger/logger';
import {PlDocumentService} from '../common/document/document.service';
import {PlPageWrapperService} from '../pagewrapper/pagewrapper.service';
import {PlTranslateService} from '../translate/translate.module';

const RESIZE_DEBOUNCE_TIME = 100;

@Component({
  selector: 'pl-nav-wizard',
  templateUrl: './navwizard.component.html',
  exportAs: 'navWizard'
})
export class PlNavWizardComponent implements OnInit, OnChanges, OnDestroy, IPlNavWizardInstance {
  @Input() public definition: IPlNavWizardDefinition;
  @Input() public instanceId: string;
  @Input() public beforeStepChange: TPlNavWizardBeforeStepChangeFn;
  @Input() public onFinalize: TPlNavWizardOnFinalizeFn;
  @Input() public destroyOnHide: boolean;
  @Input() public hideFooter: boolean;
  @Input() public model: IPlNavWizardStep;
  @Input() public properties: IPlNavWizardOptions;
  @Input() public callback: IPlNavWizardCallback;
  @Output() public readonly modelChange: EventEmitter<IPlNavWizardStep>;
  @Output() public readonly evtStepChange: EventEmitter<IPlNavWizardEventStep>;
  @Output() public readonly evtFinalize: EventEmitter<void>;

  public readonly self: PlNavWizardComponent;
  public readonly promiseProperties: Partial<IPlPromisesServiceConfiguration>;
  public options: IPlNavWizardOptions;
  public separatorSize: number;
  public itemSize: number;
  public activeId: unknown;
  public selected: IPlNavWizardStep;
  public promise: Promise<void>;

  private readonly _element: HTMLElement;
  private readonly _defaultOptions: IPlNavWizardOptions;
  private readonly _subscriptionEvaluateSeparatorSize: Subscription;
  private _inited: boolean;
  private _working: boolean;
  private _previouslySelected: IPlNavWizardStep;

  constructor(
    private readonly _domSanitizer: DomSanitizer,
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _plDocumentService: PlDocumentService,
    private readonly _plPageWrapperService: PlPageWrapperService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _logger: Logger
  ) {
    this.self = this;
    this.destroyOnHide = true;
    this.modelChange = new EventEmitter<IPlNavWizardStep>();
    this.evtStepChange = new EventEmitter<IPlNavWizardEventStep>();
    this.evtFinalize = new EventEmitter<void>();
    this.promiseProperties = {addClassToTargetOnly: true};
    this.options = {};
    this._element = this._elementRef.nativeElement;
    this._defaultOptions = {};
    this._inited = false;
    this._working = false;
    this._subscriptionEvaluateSeparatorSize = combineLatest([this._plDocumentService.windowResizeDebounced(RESIZE_DEBOUNCE_TIME), this._plPageWrapperService.toggled()]).subscribe(() => {
      if (this._inited) {
        this._evaluateSeparatorSize();
      }
    });
    this.previousStep = this.previousStep.bind(this);
    this.nextStep = this.nextStep.bind(this);
  }

  public ngOnInit(): void {
    this._inited = true;
    if (!isObject(this.definition)) {
      this.definition = copy(DEFAULT_NAV_WIZARD_DEFINITION);
    } else {
      if (!isArray(this.definition.items)) {
        this.definition.items = [];
      }
      if (!this.definition.type) {
        this.definition.type = DEFAULT_NAV_WIZARD_DEFINITION.type;
      }
    }
    this._handleChanges();
    this.instanceId = this.instanceId ?? '';
  }

  public ngOnChanges({properties, definition, callback}: SimpleChanges): void {
    if (properties && !properties.isFirstChange()) {
      this._changedProperties(properties.currentValue);
    }
    if (definition && !definition.isFirstChange()) {
      this._changedDefinition(definition.currentValue);
    }
    if (callback && !callback.isFirstChange()) {
      this._changedCallback(callback.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionEvaluateSeparatorSize.unsubscribe();
  }

  public setStep(stepToSet: unknown | IPlNavWizardStep, force?: boolean, evaluatedBeforeStepChange?: boolean, preventEventStepChange?: boolean): Promise<void> {
    force = force || this.definition.force;
    if (!force && (stepToSet === this.selected || this.options.disableNavigation || this._working)) {
      return Promise.resolve();
    }

    const stepId: unknown = isObject(stepToSet) ? (<IPlNavWizardStep>stepToSet).stepId : stepToSet;
    const index: number = this.definition.items.findIndex((item: IPlNavWizardStep) => item.stepId === stepId);
    if (index === -1) {
      return Promise.resolve();
    }

    let previous: IPlNavWizardStep;
    if (index > 0) {
      previous = findLast(this.definition.items, (item: IPlNavWizardStep) => item.visible, index - 1);
    }

    const step: IPlNavWizardStep = this.definition.items[index];
    let promise: void | boolean | Promise<void | boolean>;
    if (evaluatedBeforeStepChange !== true && this.beforeStepChange && isFunction(this.beforeStepChange)) {
      this._working = true;
      promise = this.beforeStepChange({
        nextStep: step,
        currentStep: this.selected,
        previousStep: this._previouslySelected,
        type: 'set'
      });
    }
    return Promise.resolve(promise)
      .then((response: void | boolean) => {
        this._working = false;
        if (response !== false && step.visible && (!this.selected || this.selected !== step)) {
          return Promise.resolve(this._validate(step, index, force))
            .then((valid: boolean) => {
              if (valid === false || (previous && !previous.visited && !force)) {
                this.animateCurrent();
                return this.setStep(this.definition.items[index - 1], force);
              }
              if (!step.visited) {
                step.visited = true;
              }
              this._previouslySelected = this.selected;
              this.selected = step;
              this.activeId = this.selected.stepId;
              this._render(preventEventStepChange);
              return Promise.resolve();
            })
            .catch((reason: unknown) => {
              if (reason) {
                this._logger.error(reason);
              }
              this.animateCurrent();
              return this.setStep(this.definition.items[index - 1], force);
            });
        }
        return Promise.resolve();
      })
      .finally(() => {
        this._working = false;
      });
  }

  public previousStep(force: boolean = false): Promise<void> {
    if (!this.selected || this.options.disablePreviousStep === true) {
      return Promise.resolve();
    }

    const index: number = this.definition.items.findIndex((item: IPlNavWizardStep) => item.stepId === this.selected.stepId);
    if (index === -1 || index === 0) {
      return Promise.resolve();
    }

    let promise: void | boolean | Promise<void | boolean>;
    if (this.beforeStepChange && isFunction(this.beforeStepChange)) {
      this._working = true;
      promise = this.beforeStepChange({
        nextStep: undefined,
        currentStep: this.selected,
        previousStep: this._previouslySelected,
        type: 'previous'
      });
    }
    return Promise.resolve(promise)
      .then((response: void | boolean) => {
        this._working = false;
        if (response !== false) {
          const previousVisibleStep: IPlNavWizardStep = findLast(this.definition.items, (step) => step.visible, index - 1);
          return this.setStep(previousVisibleStep, force, true);
        }
        this.animateCurrent();
        return Promise.resolve();
      })
      .catch((reason: unknown) => {
        if (reason) {
          this._logger.error(reason);
        }
        this.animateCurrent();
      })
      .finally(() => {
        this._working = false;
      });
  }

  public nextStep(force: boolean = false): Promise<void> {
    if (!this.selected || this.options.disableNextStep === true) {
      return Promise.resolve();
    }

    const index: number = this.definition.items.findIndex((item: IPlNavWizardStep) => item.stepId === this.selected.stepId);
    if (index === -1 || index === this.definition.items.length - 1) {
      if (index === this.definition.items.length - 1) {
        if (!isFunction(this.onFinalize)) {
          this.evtFinalize.emit();
          return Promise.resolve();
        }
        return Promise.resolve(this.onFinalize()).then(() => {
          this.evtFinalize.emit();
        });
      }
      return Promise.resolve();
    }

    if (this.definition.items[index].formGroup) {
      this.definition.items[index].formGroup.onSubmit(undefined);
    }

    let promise: void | boolean | Promise<void | boolean>;
    if (this.beforeStepChange && isFunction(this.beforeStepChange)) {
      this._working = true;
      promise = this.beforeStepChange({
        nextStep: undefined,
        currentStep: this.selected,
        previousStep: this._previouslySelected,
        type: 'next'
      });
    }
    return Promise.resolve(promise)
      .then((response: void | boolean) => {
        this._working = false;
        if (response !== false) {
          const nextVisibleStep: IPlNavWizardStep = find(this.definition.items, (step: IPlNavWizardStep) => step.visible, index + 1);
          return Promise.resolve(this.setStep(nextVisibleStep, force, true));
        }
        this.animateCurrent();
        return Promise.resolve();
      })
      .catch((reason: unknown) => {
        if (reason) {
          this._logger.error(reason);
        }
        this.animateCurrent();
      })
      .finally(() => {
        this._working = false;
      });
  }

  public addStep(step: IPlNavWizardStep): void {
    if (!isObject(this.definition)) {
      this.definition = {};
    }
    if (!isArray(this.definition.items)) {
      this.definition.items = [];
    }
    this.definition.items.push(step);
    this._evaluateItemSize();
    if (this.definition.items.length === 1) {
      this.setStep(step, undefined, true, true).catch((reason: unknown) => {
        this._logger.error(reason);
      });
    }
  }

  public getStep(index: number): IPlNavWizardStep {
    if (this.definition?.items?.length) {
      if (index < 0) {
        index = this.definition.items.length + index;
      }
      return this.definition.items[index];
    }
    return undefined;
  }

  public getItemContent(item: IPlNavWizardStep): SafeHtml {
    let value: string = !item.responsiveTheme ? item.caption : getResponseScreenSize<string>(item.responsiveTheme, true);
    value = this._plTranslateService.translate(value);
    return this._domSanitizer.bypassSecurityTrustHtml(value);
  }

  public updateComponent(properties: unknown): void {
    if (properties && properties !== this.options) {
      this.options = merge({}, this.options, this._defaultOptions, this.properties, properties);
      for (const item of this.definition.items) {
        if (isFunction(item.updateComponent)) {
          item.updateComponent(properties);
        }
      }
    }
  }

  public animateCurrent(): void {
    debounce(
      () => {
        if (this.selected) {
          let index = this.definition.items.findIndex((item) => {
            return item.stepId === this.selected.stepId;
          });
          if (index !== -1) {
            index++;
            let element;
            if (!this.options.vertical) {
              index++;
              element = this._element.querySelector(`.pl-nav-wizard-step:nth-child(${index})`);
            } else {
              element = this._element.querySelector(`.nav-wizard-item:nth-child(${index})`);
            }
            animateWith(element, 'pl-animation-shake');
          }
        }
      },
      100,
      'plNavWizardAnimateCurrent'
    );
  }

  private _render(preventEventStepChange?: boolean): void {
    this.selected.visited = true;
    this.model = this.selected;
    this.modelChange.emit(this.model);
    if (!preventEventStepChange) {
      this.evtStepChange.emit({currentStep: this.selected, previousStep: this._previouslySelected});
    }
    this._evaluateSeparatorSize();
  }

  private _validate(step: IPlNavWizardStep, index: number, force: boolean): boolean | Promise<boolean> {
    // First must always by allowed
    if (index === 0) {
      return true;
    }

    // Get previous (visible) step
    const previousStep = findLast<IPlNavWizardStep>(
      this.definition.items,
      (item) => {
        return item.visible;
      },
      index - 1
    );
    if (!previousStep) {
      return true;
    }

    return new Promise((resolve) => {
      // Check if previous step is required
      if (previousStep.required && (!previousStep.complete || !previousStep.visited) && !force) {
        // Check if step content has a form; if so check it for validity
        if (previousStep.formGroup) {
          const formGroup = previousStep.formGroup;
          previousStep.setValidation(formGroup.valid);
          previousStep.setCompletion((formGroup.submitted || !formGroup.pristine) && formGroup.valid);
          if (!previousStep.valid) {
            resolve(previousStep.valid);
            return;
          }
        }
        // If a custom validator is provided we use it
        if (isFunction(previousStep.validator)) {
          this._working = true;
          this.promise = Promise.resolve(previousStep.validator({nextStep: step, currentStep: this.selected, previousStep: previousStep}))
            .then((value: void | boolean) => {
              this._working = false;
              if (isBoolean(value)) {
                previousStep.setValidation(value);
                previousStep.setCompletion(value);
              } else {
                previousStep.setValidation(true);
                previousStep.setCompletion(true);
              }
              resolve(previousStep.valid);
            })
            .catch(() => {
              previousStep.setValidation(false);
              previousStep.setCompletion(false);
              resolve(false);
            })
            .finally(() => {
              this._working = false;
              this.promise = undefined;
            });
          return;
        }
      }
      if (previousStep.visited) {
        previousStep.setValidation(true);
        previousStep.setCompletion(true);
      }
      resolve(true);
    });
  }

  private _handleChanges(): void {
    this._changedProperties();
    this._changedDefinition();
    this._changedCallback();
  }

  private _changedProperties(value: IPlNavWizardOptions = this.properties): void {
    this.options = merge({}, this._defaultOptions, this.options, value);
    for (const step of this.definition.items) {
      if (isFunction(step.updateComponent)) {
        step.updateComponent(this.options);
      }
    }
  }

  private _changedDefinition(value: IPlNavWizardDefinition = this.definition): void {
    // Definition
    if (value !== this.definition) {
      if (value.caption) {
        this.definition.caption = value.caption;
      }
      if (isBoolean(value.force)) {
        this.definition.force = value.force;
      }
      if (isArray(value.items)) {
        this.definition.items = merge([], this.definition.items, value.items);
        this._evaluateItemSize();
      }
      if (value.type) {
        this.definition.type = value.type;
      }
      if (isBoolean(value.vertical)) {
        this.definition.vertical = value.vertical;
      }
    }
    this.definition.vertical = this.definition.type === 'vertical';
  }

  private _changedCallback(value: IPlNavWizardCallback = this.callback): void {
    if (isObject(value)) {
      value.previousStep = (force?: boolean) => this.previousStep(force);
      value.nextStep = (force?: boolean) => this.nextStep(force);
      value.setStep = (step: unknown | IPlNavWizardStep, force?: boolean, evaluatedBeforeStepChange?: boolean) => this.setStep(step, force, evaluatedBeforeStepChange);
    }
  }

  private _evaluateItemSize(): void {
    this.itemSize = 100 / this.definition.items.length;
  }

  private _evaluateSeparatorSize(): void {
    let separatorSize = 0;
    const progressLine: HTMLElement = this._element.querySelector<HTMLElement>(DEFAULT_NAV_WIZARD_ORIENTATION.progressLine);
    if (progressLine && this.selected) {
      const item: HTMLElement = this._element.querySelector<HTMLElement>(`.pl-nav-wizard-steps [data-id="${String(this.selected.stepId)}"]`);
      if (item) {
        separatorSize = getElementOffset(item).left - getElementOffset(progressLine).left + item.offsetWidth / 2;
      }
    }
    this.separatorSize = separatorSize;
  }
}
