<div class="modal-header">
  <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'gestaodgemps.faltas.marcarmodal.title'"></h4>
</div>

<div class="modal-body entity-detail-form">
  <p>{{ textMarcarDate }}</p>

  <pl-form [properties]="{validators: {required: {value: true}}}">
    <pl-group>
      <label><span [translate]="'gestaodgemps.fields.tipo'"></span>:</label>
      <edit>
        <select class="form-select" [ngModel]="tipoFalta" (ngModelChange)="changedTipoFalta($event)">
          <option [ngValue]="tipoFalta" *ngFor="let tipoFalta of marcarData.configEvents">{{ tipoFalta.descricao }}</option>
        </select>
      </edit>
    </pl-group>

    <div *ngIf="tipoFalta" [plPromise]="promise">
      <pl-group>
        <label><span [translate]="'gestaodgemps.fields.tipoProcessamento'"></span>:</label>
        <edit>
          <entity-autocomplete entity="rhtipoprocess" [model]="processamento" (modelChange)="changedProcessamento($event)" [output]="outputTipoProcessamento" [properties]="{allowInvalid: false}">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label><span [translate]="'gestaodgemps.fields.designacaoAbDes'"></span>:</label>
        <edit>
          <entity-autocomplete entity="descontos" [model]="desconto" (modelChange)="changedDesconto($event)" [output]="outputABDES" [properties]="{allowInvalid: false}"> </entity-autocomplete>
        </edit>
      </pl-group>
    </div>

    <pl-group>
      <label><span [translate]="'gestaodgemps.fields.allDay'"></span>:</label>
      <edit>
        <pl-edit type="boolean" [(model)]="allDay"></pl-edit>
      </edit>
    </pl-group>

    <pl-group *ngIf="!allDay">
      <pl-group>
        <label><span [translate]="'gestaodgemps.fields.horaInicio'"></span>:</label>
        <edit>
          <pl-edit-timepicker [(model)]="horaInicio" attrName="hora-inicio"></pl-edit-timepicker>
        </edit>
      </pl-group>

      <pl-group>
        <label><span [translate]="'gestaodgemps.fields.horaFim'"></span>:</label>
        <edit>
          <pl-edit-timepicker [(model)]="horaFim" attrName="hora-fim"></pl-edit-timepicker>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <label [translate]="'gestaodgemps.fields.dataProcessamento'"></label>
      <edit>
        <pl-edit-datepicker [(model)]="dataProcessamento" [properties]="{readonly: !dataProcessamentoChecked}" attrName="data-processamento"></pl-edit-datepicker>
      </edit>
      <actions>
        <pl-edit-checkbox [model]="dataProcessamentoChecked" (modelChange)="dataProcessamentoCheckedChange($event)"></pl-edit-checkbox>
      </actions>
    </pl-group>
  </pl-form>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary action-submit" [disabled]="closeDisabled" [onClick]="close"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.mark'"></span> </pl-button>

  <pl-button klass="btn-light action-cancel" [disabled]="closeDisabled" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
</div>
