<div class="modelo22-anexof-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'modelo22.modal.anexof.titles.' + newLineFor"></h5>
  </div>

  <div class="modal-body">
    <pl-form>
      <div class="card" *ngIf="isA">
        <div class="card-header" [translate]="'modelo22.modal.anexof.identificacao'"></div>
        <div class="card-body">
          <pl-group>
            <pl-group>
              <label [translate]="'modelo22.modal.anexof.codFreguesia'"></label>
              <edit>
                <pl-edit type="text" attrName="codFreguesia" [(model)]="codFreguesia"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'modelo22.modal.anexof.tipo'"></label>
              <edit>
                <pl-edit type="modelo22tipoidentmatricial" attrName="tipo" [(model)]="tipo"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'modelo22.modal.anexof.artigo'"></label>
              <edit>
                <pl-edit type="number" attrName="artigo" [(model)]="artigo"> </pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'modelo22.modal.anexof.fraccao'"></label>
              <edit>
                <pl-edit type="number" attrName="fraccao" [(model)]="fraccao"> </pl-edit>
              </edit>
            </pl-group>
          </pl-group>
        </div>
      </div>

      <pl-group *ngIf="isB">
        <label [translate]="'modelo22.modal.anexof.designacao'"></label>
        <edit>
          <pl-edit type="text" attrName="designacao" [(model)]="designacao"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexof.dataAquisicao'"></label>
          <edit>
            <pl-edit type="date" attrName="dataAquisicao" [(model)]="dataAquisicao"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'modelo22.modal.anexof.dataAlienacao'"></label>
          <edit>
            <pl-edit type="date" attrName="dataAlienacao" [(model)]="dataAlienacao"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <label [translate]="'modelo22.modal.anexof.montante' + newLineFor"></label>
        <edit>
          <pl-edit type="number" attrName="montante" [(model)]="montante"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="isA">
        <label [translate]="'modelo22.modal.anexof.montanteRegimeTransitorio'"></label>
        <edit>
          <pl-edit type="number" attrName="montanteRegimeTransitorio" [(model)]="montanteRegimeTransitorio"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="isB">
        <label [translate]="'modelo22.modal.anexof.imposto'"></label>
        <edit>
          <pl-edit type="number" attrName="imposto" [(model)]="imposto"></pl-edit>
        </edit>
      </pl-group>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span> </pl-button>

    <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
