<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="'global.menu.extratos'"></h5>
</div>

<div class="modal-body">
  <pl-form>
    <pl-group>
      <label [translate]="'components.contabilidade.listagens.title'"></label>
      <edit>
        <pl-edit type="reports_extratos_por_conta" attrName="listagem" [model]="reportModel" (modelChange)="reportModelChange($event)"> </pl-edit>
      </edit>
    </pl-group>
  </pl-form>

  <cg-pdf-viewer [pdfSrc]="pdfUrl" [toolbarInstanceId]="toolbarInstanceId"></cg-pdf-viewer>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-light action-close" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
</div>
