import {Component, Injector, OnInit} from '@angular/core';
import {ModuloComponent} from '../../../../../../components/module/module.component';
import {copy} from 'pl-comps-angular';
import {CGModalService} from '../../../../../../components/cg/modal/cgmodal.service';
import {EntityServiceBuilder} from '../../../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../../../services/entity/entity.service.interface';
import {IJsonPRHLocal} from '../../../../../../entities/prhlocais/jsonPRHLocal.entity.interface';
import {IJsonPRHRelEmentas} from '../../../../../../entities/prhrelementas/jsonPRHRelEmentas.entity.interface';
import {IJsonPRHTipoRefeicaoMenus} from '../../listagemMenus.interface';
import moment, {Moment} from 'moment';
import {ENTITY_NAME_PRH_LOCAIS} from '../../../../../../entities/prhlocais/pRHLocais.entity.interface';
import {ENTITY_NAME_PRH_TIPO_REFEICOES} from '../../../../../../entities/prhtiporefeicoes/pRHTipoRefeicoes.entity.interface';
import {ENTITY_NAME_PRH_REL_EMENTAS} from '../../../../../../entities/prhrelementas/pRHRelEmentas.entity.interface';
import {IDevExpressDataGrid, IDevExpressDataGridColumnCustomizeTextCellInfo} from '../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'listagem-menus',
  templateUrl: './listagemMenus.module.component.html'
})
export class ListagemMenusComponent extends ModuloComponent implements OnInit {
  public readonly definition: IDevExpressDataGrid;

  public listagemArray: Array<unknown>;
  public relEmentas: Array<IJsonPRHRelEmentas>;
  public dataDe: Moment;
  public local: IJsonPRHLocal;
  public tiposRefeicoesList: Array<IJsonPRHTipoRefeicaoMenus>;
  public showTable: boolean;

  private readonly _prhLocais: IEntityService<IJsonPRHLocal>;
  private readonly _prhTipoRefeicoes: IEntityService<IJsonPRHTipoRefeicaoMenus>;
  private readonly _prhRelEmentas: IEntityService<IJsonPRHRelEmentas>;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _entityServiceBuilder: EntityServiceBuilder,
    protected readonly _cgModalService: CGModalService
  ) {
    super(_injector);

    this.dataDe = moment();
    this.local = {codLocal: null, nome: ''};
    this.tiposRefeicoesList = [];
    this._prhLocais = this._entityServiceBuilder.build<IJsonPRHLocal>(ENTITY_NAME_PRH_LOCAIS);
    this._prhTipoRefeicoes = this._entityServiceBuilder.build<IJsonPRHTipoRefeicaoMenus>(ENTITY_NAME_PRH_TIPO_REFEICOES);
    this._prhRelEmentas = this._entityServiceBuilder.build<IJsonPRHRelEmentas>(ENTITY_NAME_PRH_REL_EMENTAS);
    this.showTable = false;

    this.definition = {
      columns: [
        {
          caption: '',
          columns: [{dataField: 'data', dataType: 'string', caption: '', alignment: 'center', cssClass: 'table-dias-ementa', customizeText: this.customizeLabelDateFn}]
        },
        {
          caption: 'listagemMenus.table.almoco',
          columns: [
            {dataField: 'refeicao.almoco.sopa', dataType: 'string', caption: 'listagemMenus.table.sopa'},
            {dataField: 'refeicao.almoco.geral', dataType: 'string', caption: 'listagemMenus.table.geral'},
            {dataField: 'refeicao.almoco.ligeira', dataType: 'string', caption: 'listagemMenus.table.ligeira'},
            {dataField: 'refeicao.almoco.sobremesa', dataType: 'string', caption: 'listagemMenus.table.sobremesa'}
          ]
        },
        {
          caption: 'listagemMenus.table.jantar',
          columns: [
            {dataField: 'refeicao.jantar.sopa', dataType: 'string', caption: 'listagemMenus.table.sopa'},
            {dataField: 'refeicao.jantar.geral', dataType: 'string', caption: 'listagemMenus.table.geral'},
            {dataField: 'refeicao.jantar.ligeira', dataType: 'string', caption: 'listagemMenus.table.ligeira'},
            {dataField: 'refeicao.jantar.sobremesa', dataType: 'string', caption: 'listagemMenus.table.sobremesa'}
          ]
        }
      ],
      searchPanel: {visible: false},
      grouping: {contextMenuEnabled: false},
      columnFixing: {enabled: false},
      export: {enabled: false},
      columnChooser: {enabled: false},
      headerFilter: {visible: false, search: {enabled: false}},
      filterRow: {visible: false},
      allowColumnReordering: false,
      columnHidingEnabled: false,
      pager: {visible: false},
      paging: {enabled: false},
      groupPanel: {allowColumnDragging: false, visible: false},
      showBorders: true,
      sorting: {mode: 'none'}
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._prhLocais.query().then((responseLocais) => {
      if (responseLocais.body.list.length > 0) {
        this.local = responseLocais.body.list[0];
        this._prhTipoRefeicoes.query().then((responseTipoRefeicoes) => {
          this.tiposRefeicoesList = responseTipoRefeicoes.body.list;
          for (const tipoRefeicao of this.tiposRefeicoesList) {
            tipoRefeicao.menus = [];
            this.fillPresencaListByLocal();
          }
        });
      }
    });
  }

  public readonly customizeLabelDateFn = (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string => this._customizeTextDate(cellInfo);

  public mySplit(value: string, nb: number): string {
    const array = value.split('|');
    return array[nb];
  }

  public fillPresencaListByData(value: string): void {
    this.dataDe = moment(value);
    this.fillPresencaListByLocal(this.local);
  }

  public imprimir(): void {
    window.setTimeout(() => {
      print();
    });
  }

  public fillPresencaListByLocal(item?: IJsonPRHLocal): void {
    if (item) {
      this.local = item;
    }
    if (!this.local.codLocal) {
      return;
    }
    if (!this.local) {
      this.local = {nome: '', codLocal: null};
    }
    if (!this.local.codLocal && this.local.codLocal !== 0) {
      this.local.codLocal = 1;
    }
    const dataDeAux = moment(this.dataDe);
    const dataAteAux = moment(dataDeAux);
    this.showTable = false;

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    dataAteAux.date(dataDeAux.date() + 6);
    const dataDe = `${dataDeAux.year()}-${dataDeAux.month() + 1}-${dataDeAux.date()}`;
    const dataAte = `${dataAteAux.year()}-${dataAteAux.month() + 1}-${dataAteAux.date()}`;
    const filterprhrelementas = `codLocal=${this.local.codLocal}&data>=${dataDe}&data<=${dataAte}`;
    this.listagemArray = [];

    this._prhRelEmentas.query({pesquisa: filterprhrelementas}).then((response) => {
      const refeicaoDia = {almoco: {sopa: '', geral: '', ligeiro: '', sobremesa: ''}, jantar: {sopa: '', geral: '', ligeiro: '', sobremesa: ''}};
      this.relEmentas = response.body.list;
      // eslint-disable-next-line no-unmodified-loop-condition
      for (const d = dataDeAux; d <= dataAteAux; d.date(d.date() + 1)) {
        const auxDate = moment(d);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const refDiaAux: any = copy(refeicaoDia);
        for (const relEmenta of this.relEmentas) {
          const auxRelDate = moment(relEmenta.data);
          if (auxRelDate.date() === auxDate.date()) {
            if (relEmenta.tipoRefeicao === 1 || relEmenta.tipoRefeicao === 2) {
              refDiaAux.almoco.sopa = this.mySplit(relEmenta.menu.descricao, 0);

              refDiaAux.almoco.sobremesa = this.mySplit(relEmenta.menu.descricao, 2);
              if (relEmenta.tipoRefeicao === 1) {
                refDiaAux.almoco.geral = this.mySplit(relEmenta.menu.descricao, 1);
              } else {
                refDiaAux.almoco.ligeira = this.mySplit(relEmenta.menu.descricao, 1);
              }
            } else {
              refDiaAux.jantar.sopa = this.mySplit(relEmenta.menu.descricao, 0);

              refDiaAux.jantar.sobremesa = this.mySplit(relEmenta.menu.descricao, 2);
              // eslint-disable-next-line @typescript-eslint/no-magic-numbers
              if (relEmenta.tipoRefeicao === 3) {
                refDiaAux.jantar.geral = this.mySplit(relEmenta.menu.descricao, 1);
              } else {
                refDiaAux.jantar.ligeira = this.mySplit(relEmenta.menu.descricao, 1);
              }
            }
          }
        }
        this.showTable = true;
        this.listagemArray.push({data: moment(d), refeicao: refDiaAux});
      }
    });
  }

  private _customizeTextDate(cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string {
    return moment(cellInfo.value).format(this.format.momentDate);
  }
}
