import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {EPlFormNavigationEvent} from './form.navigation.interface';
import {FOCUSABLE_QUERY_SELECTOR, getNodePath, isFunction, nodeListIndex, timeout} from '../../common/utilities/utilities';
import {KEYCODES} from '../../common/constants';
import {Logger} from '../../logger/logger';
import {SELECTOR_NAV_WIZARD_COMPONENT} from '../../navwizard/navwizard.interface';

const FOCUSABLE_QUERY_NAV_WIZARD = ['.action-next-step', '.action-finalize', '.action-click'];

@Directive({
  selector: '[plFormNavigation]'
})
export class PlFormNavigationDirective {
  @Input() public plFormNavigation: '' | Promise<any>;
  @Input() public async: boolean;

  private readonly _element: HTMLElement;
  private readonly _focusableQuery: string;
  private readonly _focusableQueryNavWizard: string;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _logger: Logger
  ) {
    this._element = this._elementRef.nativeElement;
    this._focusableQuery = FOCUSABLE_QUERY_SELECTOR;
    this._focusableQueryNavWizard = FOCUSABLE_QUERY_NAV_WIZARD.join(',');
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    const target: string = getNodePath(<HTMLInputElement>event.target);
    Promise.resolve(this.plFormNavigation)
      .then(async () => {
        if (this.async) {
          await timeout();
        }
        this._check(target, event);
      })
      .catch((reason: unknown) => {
        this._logger.error(reason);
        this.plFormNavigation = undefined;
      });
  }

  private _check(target: string, event: KeyboardEvent): void {
    const eventTarget: HTMLInputElement | HTMLButtonElement = window.document.querySelector<HTMLInputElement | HTMLButtonElement>(target);
    /* PL-SELECT FIX
     *  - when the user is using keyboard navigation while pl-select component is active,
     *    this directive must not interfere with said navigation */
    if (!eventTarget || eventTarget.tagName.toUpperCase() === 'TEXTAREA' || this._element.querySelector('.ui-select-choices-row.active') || event.altKey) {
      return;
    }
    if (event.key === KEYCODES.ENTER || event.key === KEYCODES.DOWN) {
      if (event.key === KEYCODES.ENTER && (<HTMLElement>event.target).matches('button')) {
        return;
      }
      this._next(event, this._element, EPlFormNavigationEvent.NEXT);
    } else if (event.key === KEYCODES.UP) {
      this._next(event, this._element, EPlFormNavigationEvent.PREVIOUS);
    }
  }

  private _next(event: KeyboardEvent, element: HTMLElement, step: EPlFormNavigationEvent): void {
    const focusableList: NodeListOf<HTMLInputElement | HTMLButtonElement> = element.querySelectorAll<HTMLInputElement | HTMLButtonElement>(this._focusableQuery);
    if (focusableList.length) {
      const target: HTMLElement = <HTMLElement>event.target;
      const targetIndex = nodeListIndex<HTMLElement>(focusableList, target);
      if (targetIndex !== -1) {
        let nextIndex = targetIndex + step;
        let toTrigger: HTMLInputElement | HTMLButtonElement;
        if (nextIndex >= focusableList.length) {
          const navWizard: HTMLElement = this._element.closest(SELECTOR_NAV_WIZARD_COMPONENT);
          if (navWizard) {
            toTrigger = navWizard.querySelector<HTMLInputElement | HTMLButtonElement>(this._focusableQueryNavWizard);
            this._focus(event, toTrigger);
            return;
          }
          nextIndex = 0;
        }
        toTrigger = focusableList.item(nextIndex);
        this._focus(event, toTrigger);
      }
    }
  }

  private _focus(event: KeyboardEvent, toTrigger: HTMLInputElement | HTMLButtonElement): void {
    if (!toTrigger) {
      return;
    }
    event.preventDefault();
    event.stopImmediatePropagation();
    toTrigger.focus();
    if (isFunction((<HTMLInputElement>toTrigger).select)) {
      (<HTMLInputElement>toTrigger).select();
    }
  }
}
