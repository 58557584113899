<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="modalTitle"></h5>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="procSegSocialViewInfoTable2" [dataSource]="dataGridDefinition.dataSource">
        <div *dxTemplate="let item of 'cellTemplateStatusImage'">
          <i aria-hidden="true" [ngClass]="item.data.statusImage"></i>
        </div>

        <div *dxTemplate="let item of 'masterDetailTemplate'">
          <pl-alert type="error" *ngIf="item.data.erro > 0"> <i class="fa fa-times-circle" aria-hidden="true"></i>&nbsp;<span [translate]="item.data.erroDescricao"></span> </pl-alert>
          <pl-form>
            <div class="view-info-ss-details">
              <ng-container *ngIf="item.data.valPRemPerMan !== 0">
                <pl-group>
                  <label [translate]="'proceSegSocial.nDiasCodPLabel'"></label>
                  <edit>
                    <pl-edit type="cginteger" [(model)]="item.data.nDiasPRemPerMan"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group [properties]="{disabled: true}">
                  <label [translate]="'proceSegSocial.valPLabel'"></label>
                  <edit>
                    <pl-edit type="number" [(model)]="item.data.valPRemPerMan"></pl-edit>
                  </edit>
                </pl-group>
              </ng-container>

              <ng-container *ngIf="item.data.valRSubRefeicao !== 0">
                <pl-group [properties]="{disabled: true}">
                  <label [translate]="'proceSegSocial.valRLabel'"></label>
                  <edit>
                    <pl-edit type="number" [(model)]="item.data.valRSubRefeicao"></pl-edit>
                  </edit>
                </pl-group>
              </ng-container>

              <ng-container *ngIf="item.data.valFSubFerias !== 0">
                <pl-group [properties]="{disabled: true}">
                  <label [translate]="'proceSegSocial.valFLabel'"></label>
                  <edit>
                    <pl-edit type="number" [(model)]="item.data.valFSubFerias"></pl-edit>
                  </edit>
                </pl-group>
              </ng-container>

              <ng-container *ngIf="item.data.valNSubNatal !== 0">
                <pl-group [properties]="{disabled: true}">
                  <label [translate]="'proceSegSocial.valNLabel'"></label>
                  <edit>
                    <pl-edit type="number" [(model)]="item.data.valNSubNatal"></pl-edit>
                  </edit>
                </pl-group>
              </ng-container>

              <ng-container *ngIf="item.data.valAAjudasCusto !== 0">
                <pl-group [properties]="{disabled: true}">
                  <label [translate]="'proceSegSocial.valALabel'"></label>
                  <edit>
                    <pl-edit type="number" [(model)]="item.data.valAAjudasCusto"></pl-edit>
                  </edit>
                </pl-group>
              </ng-container>

              <ng-container *ngIf="item.data.valBPremMensal !== 0">
                <pl-group [properties]="{disabled: true}">
                  <label [translate]="'proceSegSocial.valBLabel'"></label>
                  <edit>
                    <pl-edit type="number" [(model)]="item.data.valBPremMensal"></pl-edit>
                  </edit>
                </pl-group>
              </ng-container>

              <ng-container *ngIf="item.data.valCComissoes !== 0">
                <pl-group [properties]="{disabled: true}">
                  <label [translate]="'proceSegSocial.valCLabel'"></label>
                  <edit>
                    <pl-edit type="number" [(model)]="item.data.valCComissoes"></pl-edit>
                  </edit>
                </pl-group>
              </ng-container>

              <ng-container *ngIf="item.data.valDCompCessacao !== 0">
                <pl-group [properties]="{disabled: true}">
                  <label [translate]="'proceSegSocial.valDLabel'"></label>
                  <edit>
                    <pl-edit type="number" [(model)]="item.data.valDCompCessacao"></pl-edit>
                  </edit>
                </pl-group>
              </ng-container>

              <ng-container *ngIf="item.data.valHHonorarios !== 0">
                <pl-group [properties]="{disabled: true}">
                  <label [translate]="'proceSegSocial.valHLabel'"></label>
                  <edit>
                    <pl-edit type="number" [(model)]="item.data.valHHonorarios"></pl-edit>
                  </edit>
                </pl-group>
              </ng-container>

              <ng-container *ngIf="item.data.valMSubsRegMensal !== 0">
                <pl-group [properties]="{disabled: true}">
                  <label [translate]="'proceSegSocial.valMLabel'"></label>
                  <edit>
                    <pl-edit type="number" [(model)]="item.data.valMSubsRegMensal"></pl-edit>
                  </edit>
                </pl-group>
              </ng-container>

              <ng-container *ngIf="item.data.valOPrNaoMensal !== 0">
                <pl-group [properties]="{disabled: true}">
                  <label [translate]="'proceSegSocial.valOLabel'"></label>
                  <edit>
                    <pl-edit type="number" [(model)]="item.data.valOPrNaoMensal"></pl-edit>
                  </edit>
                </pl-group>
              </ng-container>

              <ng-container *ngIf="item.data.valSRSuplementar !== 0">
                <pl-group [properties]="{disabled: true}">
                  <label [translate]="'proceSegSocial.valSLabel'"></label>
                  <edit>
                    <pl-edit type="number" [(model)]="item.data.valSRSuplementar"></pl-edit>
                  </edit>
                </pl-group>
              </ng-container>

              <ng-container *ngIf="item.data.valTTrabNocturno !== 0">
                <pl-group [properties]="{disabled: true}">
                  <label [translate]="'proceSegSocial.valTLabel'"></label>
                  <edit>
                    <pl-edit type="number" [(model)]="item.data.valTTrabNocturno"></pl-edit>
                  </edit>
                </pl-group>
              </ng-container>

              <ng-container *ngIf="item.data.valXOutSubsidios !== 0">
                <pl-group [properties]="{disabled: true}">
                  <label [translate]="'proceSegSocial.valXLabel'"></label>
                  <edit>
                    <pl-edit type="number" [(model)]="item.data.valXOutSubsidios"></pl-edit>
                  </edit>
                </pl-group>
              </ng-container>

              <ng-container *ngIf="item.data.val2FerPagasCess !== 0">
                <pl-group>
                  <label [translate]="'proceSegSocial.nDias2Label'"></label>
                  <edit>
                    <pl-edit type="cginteger" [(model)]="item.data.nDias2FerPagasCess"></pl-edit>
                  </edit>
                </pl-group>
                <pl-group [properties]="{disabled: true}">
                  <label [translate]="'proceSegSocial.val2Label'"></label>
                  <edit>
                    <pl-edit type="number" [(model)]="item.data.val2FerPagasCess"></pl-edit>
                  </edit>
                </pl-group>
              </ng-container>

              <ng-container *ngIf="item.data.val6DifVencim !== 0">
                <pl-group [properties]="{disabled: true}">
                  <label [translate]="'proceSegSocial.val6Label'"></label>
                  <edit>
                    <pl-edit type="number" [(model)]="item.data.val6DifVencim"></pl-edit>
                  </edit>
                </pl-group>
              </ng-container>

              <ng-container *ngIf="item.data.valICtRinTermit !== 0">
                <pl-group>
                  <label [translate]="'proceSegSocial.nDiasILabel'"></label>
                  <edit>
                    <pl-edit type="cginteger" [(model)]="item.data.nDiasICtRinTermit"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group [properties]="{disabled: true}">
                  <label [translate]="'proceSegSocial.valILabel'"></label>
                  <edit>
                    <pl-edit type="number" [(model)]="item.data.valICtRinTermit"></pl-edit>
                  </edit>
                </pl-group>
              </ng-container>
            </div>
          </pl-form>
          <ng-container *ngIf="item.data.erro === 0">
            <hr />
            <div class="text-end">
              <pl-button type="button" klass="btn btn-success btn-sm mb-2 me-2" [onClick]="fnSaveItem(item.data)">
                <i class="fa fa-fw fa-check"></i> <span [translate]="'global.btn.save'"></span>
              </pl-button>
            </div>
          </ng-container>
        </div>
      </dx-data-grid>
    </div>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-close"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
