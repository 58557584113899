<div class="portal">
  <pl-page-wrapper>
    <pl-page-wrapper-sidebar *ngIf="launchMode !== launchModes.HybridPartial">
      <pl-sidebar [menus]="sidebarMenu" [closeOnItemAction]="true" [itemActive]="sideBarActive" [itemClicked]="sidebarClick">
        <pl-sidebar-title>
          <img [attr.src]="logotipoUrl" plFallbackSrc="../../../assets/images/logo.webp" alt="logo-empresa" />
        </pl-sidebar-title>
      </pl-sidebar>
    </pl-page-wrapper-sidebar>

    <pl-page-wrapper-navbar *ngIf="launchMode !== launchModes.HybridPartial" [class.portal-sticky]="hasStickyPortals">
      <pl-navbar [menus]="navBarMenu" [itemActive]="navBarActive">
        <pl-navbar-brand>
          <div class="pl-navbar-logo">
            <img [attr.src]="logotipoUrl" plFallbackSrc="../../../assets/images/logo.webp" alt="logo-empresa" />
          </div>
        </pl-navbar-brand>

        <pl-navbar-sub-brand>
          <button type="button" class="btn navbar-sub-brand portal-sub-brand" (click)="callChangeEmpresa()" [attr.title]="'global.btn.changeEmpresa' | translate">
            <i cgcIcon="fa-angle-down"></i><span>{{ nomeEmpresa }}</span>
          </button>
        </pl-navbar-sub-brand>
      </pl-navbar>
    </pl-page-wrapper-navbar>

    <pl-page-wrapper-container>
      <main>
        <pl-toolbar [instanceId]="toolbarInstanceId" [collapseOnMobile]="true"></pl-toolbar>
        <div class="widget">
          <ui-view></ui-view>
        </div>
      </main>

      <footer class="footer" *ngIf="launchMode !== launchModes.HybridPartial">
        <div class="site-footer-legal">
          © 1987 - {{ currentYear }}
          <ng-container *ngIf="!isMobile">Todos os direitos reservados.</ng-container>
          <ng-container *ngIf="isMobile"><a [attr.href]="companyUrl" target="_blank" rel="noopener noreferrer">CentralGest</a>.</ng-container>
          {{ version }}
          <ng-container *ngIf="isAdmin">
            <a rel="noopener noreferrer" (click)="callAbout()">Sobre</a>
            <span> | </span>
          </ng-container>
          <a [attr.href]="cgSupportUrl" target="_blank" rel="noopener noreferrer" *ngIf="cgSupportUrl">Centro de ajuda</a>
          <span> | </span>
          <a rel="noopener noreferrer" (click)="callTerms()">Termos e condições</a>
        </div>

        <div class="site-footer-right" *ngIf="!isMobile">Powered by <a [attr.href]="companyUrl" target="_blank" rel="noopener noreferrer">CentralGest</a></div>
      </footer>

      <div class="app-global-loader" *ngIf="globalLoading">
        <div class="app-global-loader-spinner">
          <div class="circle-spinner">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
    </pl-page-wrapper-container>
  </pl-page-wrapper>

  <div class="portal-fixed-dialog">
    <cg-app-status-new-version-available></cg-app-status-new-version-available>
    <cg-app-status-maintenance #maintenance="appStatusMaintenance"></cg-app-status-maintenance>
    <cg-app-status-disconnected #disconnected="appStatusDisconnected"></cg-app-status-disconnected>
  </div>

  <div class="amalia-prompt" *ngIf="!disconnected.showToast && !maintenance.showToast">
    <button type="button" class="btn btn-link amalia-prompt-open">
      <img ngSrc="../../../assets/images/amalia.png" alt="logo-amalia" [width]="50" [height]="50" (click)="openAmaliaWindow()" />
    </button>
  </div>
</div>

<amalia-window *ngIf="amaliaWindowOpen"></amalia-window>

<ng-template #templateNavbarItemUserTitle>
  <cg-user-profile-image></cg-user-profile-image>
</ng-template>

<ng-template #templateNavbarItemUserMenu let-dropdownRef="dropdownRef">
  <cg-user-nav [dropdownRef]="dropdownRef"></cg-user-nav>
</ng-template>

<ng-template #templateNavbarNotificationCenter>
  <div class="toolbar-notification-center" [attr.title]="'notificationcenter.title' | translate" [attr.aria-label]="'notificationcenter.title' | translate">
    <i class="fa fa-fw fa-bell" aria-hidden="true" [class.bell-ring]="notificationsCount > 0"></i>
    <div class="badge rounded-pill" *ngIf="notificationsCount > 0">{{ notificationsCount }}</div>
  </div>
</ng-template>
