<div class="modelo39 entity-detail-form">
  <div *ngIf="errors.erros.length > 0" [plAnimationShake]="errors.erros.length > 0">
    <pl-alert type="danger" *ngIf="errors.erros.length > 0" (evtClosed)="errors.erros.length = 0">
      <div [translate]="errors.tipoValidacao !== eTiposValidar.DEFAULT ? errors.mensagemTipoHeader : 'modelo39.errors.errorHeader'"></div>
      <hr class="mt-1 mb-1" />
      <div *ngFor="let message of errors.erros">
        <ng-container [ngSwitch]="!!message.mensagem">
          <ng-container *ngSwitchCase="true">
            <strong [innerHTML]="message.mensagem + ': ' + message.mensagemOriginal"></strong>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <strong [innerHTML]="message.mensagemOriginal"></strong>
          </ng-container>
        </ng-container>
      </div>
    </pl-alert>
  </div>

  <pl-form>
    <pl-navigator contentMaxHeight="80vh">
      <pl-navigator-item caption="modelo39.groups.q0.title" [order]="0" [id]="0">
        <div *plNavigatorItemCaption><span class="number-title-box">00</span>&nbsp;<span [translate]="'modelo39.groups.q0.title'"></span></div>
        <div *plNavigatorItemContent>
          <p></p>
          <p>
            <b><span [translate]="'modelo39.groups.q0.p2'"></span></b>
          </p>
          <p>
            <span [translate]="'modelo39.groups.q0.p3'"></span>
            <br />
            <span [translate]="'modelo39.groups.q0.p4'"></span>
          </p>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo39.groups.q1.title" [order]="1" [id]="1">
        <div *plNavigatorItemCaption><span class="number-title-box">01</span>&nbsp;<span class="nav-item-title" [translate]="'modelo39.groups.q1.title'"></span></div>
        <div *plNavigatorItemContent>
          <div class="row">
            <div class="col-md-6 col-12">
              <label [translate]="'modelo39.groups.q1.campoNifDeclarante'"></label>
              <div class="d-flex">
                <div class="input-box-numbers">01</div>
                <pl-edit type="text" attrName="q01c01" [model]="modelo39.rosto.q01c01" [properties]="{disabled: true}"></pl-edit>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo39.groups.q2.title" [order]="2" [id]="2">
        <div *plNavigatorItemCaption><span class="number-title-box">02</span>&nbsp;<span class="nav-item-title" [translate]="'modelo39.groups.q2.title'"></span></div>
        <div *plNavigatorItemContent>
          <div>
            <label [translate]="'modelo39.groups.q2.q02C02'"></label>
            <div class="d-flex">
              <div class="input-box-numbers">02</div>
              <pl-edit type="text" attrName="q02C02" [model]="modelo39.rosto.q02c02" [properties]="{disabled: true}"></pl-edit>
            </div>
          </div>

          <div class="mt-3">
            <label [translate]="'modelo39.groups.q2.q02Caso'"></label>
          </div>

          <div class="row">
            <div class="col-sm-12 col-lg-4">
              <label [translate]="'modelo39.groups.q2.q02C06'"></label>
              <div class="d-flex">
                <div class="input-box-numbers">06</div>
                <pl-autocomplete
                  [source]="optionsTiposImpedimento"
                  class="flex-grow-1"
                  attrName="q02C06"
                  [model]="modelo39.rosto.q02c06"
                  (modelChange)="changedTipoImpedimento($event)"
                  [rowTemplate]="templateTiposImpedimento"
                  [output]="outputTiposImpedimento"
                  [allowInvalid]="false"
                  [allowEmpty]="true">
                </pl-autocomplete>
              </div>
            </div>
            <div class="col-sm-12 col-lg-4">
              <label [translate]="'modelo39.groups.q2.q02C07'"></label>
              <div class="d-flex">
                <div class="input-box-numbers">07</div>
                <pl-edit type="date" class="flex-grow-1" attrName="q02C07" [(model)]="modelo39.rosto.q02c07"></pl-edit>
              </div>
            </div>
            <div class="col-sm-12 col-lg-4">
              <label [translate]="'modelo39.groups.q2.q02C08'"></label>
              <div class="d-flex">
                <div class="input-box-numbers">08</div>
                <pl-edit type="date" class="flex-grow-1" attrName="q02C08" [(model)]="modelo39.rosto.q02c08"></pl-edit>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo39.groups.q3.title" [order]="3" [id]="3">
        <div *plNavigatorItemCaption><span class="number-title-box">03</span>&nbsp;<span class="nav-item-title" [translate]="'modelo39.groups.q3.title'"></span></div>
        <div *plNavigatorItemContent>
          <div>
            <label [translate]="'modelo39.groups.q3.q03C03'"></label>
            <div class="d-flex">
              <div class="input-box-numbers">03</div>
              <pl-edit type="text" attrName="q03q03" [model]="modelo39.rosto.q03c03" [properties]="{disabled: true}"></pl-edit>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo39.groups.q3.title" [order]="4" [id]="4">
        <div *plNavigatorItemCaption><span class="number-title-box">04</span>&nbsp;<span class="nav-item-title" [translate]="'modelo39.groups.q4.title'"></span></div>
        <div *plNavigatorItemContent>
          <div>
            <label [translate]="'modelo39.groups.q4.q04C04'"></label>
            <div class="d-flex">
              <div class="input-box-numbers">04</div>
              <pl-edit type="text" attrName="q04c04" [model]="modelo39.rosto.q04c04" [properties]="{disabled: true}"></pl-edit>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo39.groups.q4.title" [order]="5" [id]="5">
        <div *plNavigatorItemCaption><span class="number-title-box">05</span>&nbsp;<span class="nav-item-title" [translate]="'modelo39.groups.q5.title'"></span></div>
        <div *plNavigatorItemContent>
          <div>
            <label [translate]="'modelo39.groups.q5.title'"></label>
            <pl-group>
              <edit>
                <pl-edit type="radiogroup" attrName="q05c05" [(model)]="modelo39.rosto.q05c05" [properties]="tiposDeclaracao"></pl-edit>
              </edit>
            </pl-group>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo39.groups.q5.title" [order]="6" [id]="6">
        <div *plNavigatorItemCaption><span class="number-title-box">06</span>&nbsp;<span class="nav-item-title" [translate]="'modelo39.groups.q6.title'"></span></div>
        <div *plNavigatorItemContent>
          <pl-split-view [initialSizeLeft]="70" [hideRightSide]="!showAjudaQuadro6">
            <div *plSplitViewLeftSide>
              <div *ngIf="!modelo39.rosto.q06c06.length">
                <pl-alert [plAnimationShake]="!modelo39.rosto.q06c06.length" [closeable]="false" [type]="'warning'" (evtClosed)="modelo39.rosto.q06c06.length = 0">
                  <div [innerHTML]="'modelo39.errors.errorNoDataList' | translate"></div>
                </pl-alert>
              </div>

              <dx-data-grid
                [cgDxDataGrid]="dataGridRegistoList"
                cgDxDataGridInstanceName="modelo39RegistoList"
                [dataSource]="modelo39.rosto.q06c06"
                (onInitialized)="onInitializedQ06($event)"
                (onInitNewRow)="onInitNewRow($event)"
                (onRowInserted)="onRowInserted()"
                (onEditCanceled)="onEditCanceled()"
                (onRowRemoved)="onRowRemoved()">
              </dx-data-grid>
            </div>

            <div *plSplitViewRightSide>
              <h6 [translate]="'modelo39.groups.q6.dataGridRegistoList.extras.ajudaTitle'" class="mb-3 ms-2 font-weight-bold"></h6>
              <div class="modelo39-scrollable">
                <div class="row ajuda-codigos" *ngFor="let itemCod of codigosRendimentos">
                  <div class="col-sm-12">
                    <h6 class="modelo39-cod-rendimentos-ajuda-section">{{ 'modelo39.groups.q6.dataGridRegistoList.extras.cod' | translate }} {{ itemCod.cod }}</h6>
                    <div class="row mb-3">
                      <ul>
                        <li class="ajuda-desc" *ngFor="let itemDesc of itemCod.rendimentos" [translate]="itemDesc"></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </pl-split-view>
        </div>
      </pl-navigator-item>
    </pl-navigator>
  </pl-form>
</div>
