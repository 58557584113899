import {outdatedBrowser} from 'outdated-browser-es6/outdatedbrowser/outdatedbrowser.es6';

export function handleFirstPageLoad(): void {
  window.document.querySelector<HTMLElement>('#loader-container').style.display = 'none';
  outdatedBrowser({
    bgColor: '#62a8ea',
    lowerThan: 'IE11',
    languagePath: ''
  });
}
