<div class="user-installation">
  <!-- Acessos (empresas) -->
  <div class="user-installation-accesses card">
    <div class="card-body">
      <div class="card-title" [translate]="'usersbasic.text.acessos'"></div>

      <div *ngIf="installation.empresasLoading" class="d-flex column-gap-2" style="column-gap: 0.5rem">
        <p class="m-0" [translate]="'usersbasic.text.loadingEmpresas'"></p>
        <div class="circle-spinner m-0 fs-2">
          <div class="spinner"></div>
        </div>
      </div>

      <ng-container *ngIf="!installation.empresasLoading" [ngSwitch]="installation.empresas.length">
        <pl-alert type="warning" *ngSwitchCase="0">
          <div [translate]="'usersbasic.text.emptyCompanies'"></div>
        </pl-alert>

        <div class="user-installation-accesses-config" *ngSwitchDefault>
          <pl-navpill [activeId]="activeId" (activeIdChange)="changedNavPill($event)">
            <pl-navpill-panel *ngFor="let keyAccess of installation.accesses.keys" [id]="keyAccess">
              <div *plNavPillPanelTitle>
                <div class="user-installation-accesses-config-title">
                  <div class="user-installation-accesses-config-title-content" [translate]="installation.accesses.values.get(keyAccess).name"></div>
                </div>
              </div>

              <div *plNavPillPanelContent>
                <div class="d-flex justify-content-center" *ngIf="!disabled && installation.accesses.values.get(keyAccess).portalIncludes.length">
                  <div class="card d-inline-flex bg-info text-white" style="--bs-bg-opacity: 0.7">
                    <div class="card-body p-2">
                      <p class="m-0" *ngIf="installation.accesses.values.get(keyAccess).portalIncludes.length === 1">
                        <span [translate]="'usersbasic.text.portalInclude'"></span>&nbsp;"{{ installation.accesses.values.get(keyAccess).portalIncludes[0].name }}".
                      </p>

                      <ng-container *ngIf="installation.accesses.values.get(keyAccess).portalIncludes.length > 1">
                        <p class="m-0"><span [translate]="'usersbasic.text.portalIncludes'"></span>:</p>
                        <ul>
                          <li *ngFor="let includedPortal of installation.accesses.values.get(keyAccess).portalIncludes">{{ includedPortal.name }}</li>
                        </ul>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="usersinstallation-{{ keyAccess }}" [dataSource]="dataGridDefinition.dataSource">
                  <dxo-search-panel (textChange)="changedFilterValue($event)"></dxo-search-panel>

                  <dxo-paging (pageIndexChange)="changedPageIndex($event)" (pageSizeChange)="changedPageSize($event)"></dxo-paging>

                  <div *dxTemplate="let cellInfo of 'templateSelectionHeader'">
                    <pl-edit-checkbox
                      attrName="{{ keyAccess }}-selected-all"
                      [model]="installation.accesses.values.get(keyAccess).selectedAll"
                      (modelChange)="changedSelectAllCompanies($event, installation.accesses.values.get(keyAccess))"
                      [label]="'usersbasic.text.selectAll'"
                      [indeterminate]="installation.accesses.values.get(keyAccess).indeterminate"
                      [properties]="{disabled: disabled, validate: false}">
                    </pl-edit-checkbox>
                  </div>

                  <div *dxTemplate="let cellInfo of 'templateSelection'">
                    <pl-edit-checkbox
                      attrName="{{ keyAccess }}-selected-{{ cellInfo.data.nempresa }}"
                      [model]="installation.accesses.values.get(keyAccess).values.get(cellInfo.data.nempresa).selected"
                      (modelChange)="changedSelectCompany($event, installation.accesses.values.get(keyAccess).values.get(cellInfo.data.nempresa), installation.accesses.values.get(keyAccess))"
                      [indeterminate]="installation.accesses.values.get(keyAccess).values.get(cellInfo.data.nempresa).indeterminate"
                      [properties]="{disabled: disabled, validate: false}">
                    </pl-edit-checkbox>
                  </div>
                </dx-data-grid>
              </div>
            </pl-navpill-panel>
          </pl-navpill>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Acessos (políticas) -->
  <div class="user-installation-accesses-company card mt-4" *ngIf="!installation.empresasLoading && installation.empresas.length">
    <div class="card-body">
      <div class="card-title" [translate]="'usersbasic.text.acessosEmpresa'"></div>

      <div class="config-companies-needs-loading" *ngIf="installation.policiesNeedLoading" [ngSwitch]="loadingAccesses">
        <div class="config-companies-loading-action" *ngSwitchCase="false">
          <button type="button" class="btn btn-link" [translate]="'usersbasic.text.loadPolicies'" (click)="loadPolicies()"></button>

          <div class="config-companies-loading-action-errors" *ngIf="loadingAccessesErrors.length > 0">
            <hr />
            <div class="config-companies-loading-action-errors-header" [translate]="'usersbasic.text.loadPoliciesError'"></div>
            <div class="config-companies-loading-action-errors-content">
              <div class="config-companies-loading-action-error" *ngFor="let error of loadingAccessesErrors">
                <span
                  ><strong>{{ error.nEmpresa }}</strong
                  >: {{ error.message }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="config-companies-loading" *ngSwitchCase="true">
          <div class="config-companies-loading-label" [translate]="'usersbasic.text.loadingPolicies'"></div>
          <pl-progress theme="bg-info" [min]="1" [max]="100" [value]="loadingAccessesValue"></pl-progress>
        </div>
      </div>

      <div class="user-installation-accesses-company-content">
        <hr *ngIf="installation.policiesNeedLoading" />

        <ng-container [ngSwitch]="installation.showPolicies">
          <div class="user-installation-accesses-company-blocked" *ngSwitchCase="false">
            <pl-alert type="primary" [closeable]="false">
              <div [translate]="'usersbasic.text.blockedAcessosEmpresa'"></div>
            </pl-alert>
          </div>

          <div class="config-companies" *ngSwitchCase="true">
            <pl-navpill>
              <ng-container *ngFor="let policyKey of installation.policies.keys">
                <pl-navpill-panel *ngIf="installation.policies.values.get(policyKey).visible" [id]="policyKey" [caption]="installation.policies.values.get(policyKey).name">
                  <div *plNavPillPanelContent>
                    <div class="config-company-general" *ngIf="policyKey !== companyGlobal">
                      <div class="config-company-title" [translate]="'usersbasic.text.configuracaoGeral'"></div>

                      <pl-group>
                        <label [translate]="'erputilizadores.editRow.codEmpregado'"></label>
                        <edit>
                          <entity-autocomplete
                            entity="dgemps"
                            attrName="dgemps-{{ policyKey }}"
                            [model]="installation.policies.values.get(policyKey)"
                            (selectedKeyChange)="installation.policies.values.get(policyKey).codEmp = $event"
                            (evtSelectedDescriptionChanged)="installation.policies.values.get(policyKey).nomeEmpregado = $event"
                            [fieldsMap]="{nome: 'nomeEmpregado'}"
                            [properties]="{validators: {required: {value: false}}}"
                            filter="nempresa={{ policyKey }}">
                          </entity-autocomplete>
                        </edit>
                      </pl-group>

                      <hr />
                    </div>

                    <div class="config-company-policies" *ngIf="!installation.policiesNeedLoading">
                      <div class="config-company-title" [translate]="'usersbasic.text.acessosEmpresa'"></div>
                      <cg-user-accesses
                        attrName="policies-{{ policyKey }}"
                        [policy]="installation.policies.values.get(policyKey)"
                        [disabled]="disabled"
                        (evtConfigErpChanged)="changedConfigErpValue(policyKey, $event)"
                        (evtConfigsErpChanged)="changedConfigsErpValue(policyKey)">
                      </cg-user-accesses>
                    </div>
                  </div>
                </pl-navpill-panel>
              </ng-container>
            </pl-navpill>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
