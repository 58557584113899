import {Injector, NgModule} from '@angular/core';
import {NgOptimizedImage} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {provideHttpClient, withInterceptors, withNoXsrfProtection} from '@angular/common/http';
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {UIRouterModule} from '@uirouter/angular';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {
  DxButtonModule,
  DxChartModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDiagramModule,
  DxDropDownButtonModule,
  DxHtmlEditorModule,
  DxListModule,
  DxPieChartModule,
  DxPivotGridModule,
  DxPopupModule,
  DxSelectBoxModule,
  DxTextBoxModule,
  DxTooltipModule,
  DxTreeListModule
} from 'devextreme-angular';
import {DxHttpModule} from 'devextreme-angular/http';
import {CGCompsModule, ERadix, isNumber, isString, PlCompsService, PlFormatService, PlPdfOptionsService, PlTranslateService, PlUploadConfigService} from 'pl-comps-angular';

import {AppComponent} from './app.component';
import {applyScssI18n} from './common/i18n/i18n.scss';
import {CGInjector} from './common/injectors/cginjector';
import {COMPONENT_NAME_INPUT_ENTITY} from './core/components/entity/component/input.entity.component.interface';
import {DEFAULT_FORM_ORIENTATION, DEFAULT_TOOLBAR_ID, isDebug, isProduction} from './config/constants';
import {FeatureFlagService} from './core/services/featureflag/featureflag.service';
import {HTTP_INTERCEPTORS} from './config/http.config';
import {NOTIFICATION_CENTER_ENABLED} from './core/services/notificationcenter/notificationcenter.service.interface';
import {NotificationCenterService} from './core/services/notificationcenter/notificationcenter.service';
import {provideAmaliaIntentHandlers} from './config/amalia.config';
import {provideAppInitialization} from './config/app.initialization.service';
import {provideCGComponentsConfiguration} from './config/cgcomps.config';
import {provideDevExpressDataGridConfiguration} from './core/components/devexpress/datagrid/configuration/devexpress.datagrid.configuration';
import {provideHammerConfig} from './config/hammer.config';
import {provideLocaleId} from './config/translation/translation.di';
import {provideServiceWorker} from './config/service.worker.config';
import {provideStorageConfigurations} from './config/local.storage.config';
import {TRANSLATE_MODULE_CONFIG} from './config/translation/translation.config';
import {UI_ROUTER_MODULE_CONFIG} from './config/uirouter.config';

import {AbDesEditComponent} from './core/entities/abdes/components/edit/abdes.entity.edit.component';
import {AccountComponent} from './core/states/account/account.state.component';
import {AddAbonoDescontoModalComponent} from './core/modules/portalrh/rhcessacontratotrabalho/modals/addabonodesconto/addAbonoDesconto.modal.component';
import {AlertDebugErrorComponent} from './core/components/alert/alert.debug.error.component';
import {AlteracoesEntidadesModuleComponent} from './core/modules/colaboradores/alteracoesentidades/components/alteracoesEntidades.module.component';
import {AlterarCamposSerieModalComponent} from './core/entities/docscomerciais/modals/alterarcamposserie/alterarCamposSerie.modal.component';
import {AmaliaChatMessageComponent} from './core/components/amalia/chatmessage/amalia.chatmessage.component';
import {AmaliaChatMessageContentComponent} from './core/components/amalia/chatmessagecontent/amalia.chatmessagecontent.component';
import {AmaliaWindowComponent} from './core/components/amalia/window/amalia.window.component';
import {AnalisadorTesComponent} from './core/modules/tesouraria/analisadortes/components/analisadorTes.module.component';
import {AnalisadorTesConfigModalComponent} from './core/modules/tesouraria/analisadortes/modals/config/analisadorTes.config.modal.component';
import {AnalisadorTesProgressModalComponent} from './core/modules/tesouraria/analisadortes/modals/progress/analisadorTes.progress.modal';
import {AnalisadorTesTeslancoModalComponent} from './core/modules/tesouraria/analisadortes/modals/teslanco/analisadorTes.tesLanco.modal.component';
import {AnaliticaConfigModuleComponent} from './core/modules/analiticaconfig/components/analiticaConfig.module.component';
import {AprovacoesRefeicoesComponent} from './core/modules/colaboradores/refeicoes/aprovacoes/components/aprovacoesRefeicoes.module.component';
import {ArclisEditComponent} from './core/entities/arclis/component/edit/arclis.entity.edit.component';
import {ArtigosEditComponent} from './core/entities/artigos/components/edit/artigos.entity.edit.component';
import {ArtigosGdocComponent} from './core/entities/artigos/components/gdocs/artigos.gdoc.component';
import {ArtigosListComponent} from './core/entities/artigos/components/list/artigos.entity.list.component';
import {AssistConfigPortaisEmpregadoModuleComponent} from './core/modules/configs/assistenteconfigportais/portais/portalclientaccounts/empregado/components/assistConfigPortais.empregado.module.component';
import {AssistConfigPortaisEmpresaModuleComponent} from './core/modules/configs/assistenteconfigportais/portais/portalclientaccounts/empresa/components/assistConfigPortais.empresa.module.component';
import {AssistConfigPortaisGeralEmailModuleComponent} from './core/modules/configs/assistenteconfigportais/portais/geral/email/components/assistConfigPortais.geral.email.module.component';
import {AssistConfigPortaisPortalClientConnectUtilizadorExistenteModuleComponent} from './core/modules/configs/assistenteconfigportais/portais/portalclientconnect/utilizadorexistente/components/assistConfigPortais.portalClientConnect.utilizadorExistente.module.component';
import {AssistConfigPortaisPortalClientConnectUtilizadorModuleComponent} from './core/modules/configs/assistenteconfigportais/portais/portalclientconnect/utilizador/components/assistConfigPortais.portalClientConnect.utilizador.module.component';
import {AssistConfigPortaisUtilizadorModuleComponent} from './core/modules/configs/assistenteconfigportais/portais/portalclientaccounts/utilizador/components/assistConfigPortais.utilizador.module.component';
import {AssistenteConfigPortaisModuleComponent} from './core/modules/configs/assistenteconfigportais/components/assistenteConfigPortais.module.component';
import {AssociarMenusComponent} from './core/modules/colaboradores/refeicoes/associarmenus/components/associarMenus.module.component';
import {AssociarServicosComponent} from './core/modules/colaboradores/servicos/associaservicos/components/associaServicos.module.component';
import {AtFaturasRecibosVerdesModuleComponent} from './core/modules/portalcontabilidade/atFaturasRecibosVerdes/atFaturasRecibosVerdes.module.component';
import {AtFaturasRecibosVerdesConfigModalComponent} from './core/modules/portalcontabilidade/atFaturasRecibosVerdes/modal/config/atFaturasRecibosVerdes.config.modal';
import {AtFaturasRecibosVerdesLancarDocsEmSerieModalComponent} from './core/modules/portalcontabilidade/atFaturasRecibosVerdes/modal/lancarDocsEmSerie/atFaturasRecibosVerdes.lancarDocsEmSerie.modal';
import {AtFaturasRecibosVerdesNifConfigModalComponent} from './core/modules/portalcontabilidade/atFaturasRecibosVerdes/modal/nifConfig/atFaturasRecibosVerdes.nifConfig.modal';
import {AtFaturasRecibosVerdesNovaContaCorrenteModalComponent} from './core/modules/portalcontabilidade/atFaturasRecibosVerdes/modal/novaContaCorrente/atFaturasRecibosVerdes.novaContaCorrente.modal';
import {AtivosAbateModalComponent} from './core/entities/ativos/modals/ativosabate/ativosAbate.modal.component';
import {AtivosAbateModuleComponent} from './core/modules/ativos/ativosabate/components/ativosAbate.module.component';
import {AtivosAjusteDepAntigasModalComponent} from './core/entities/ativos/modals/ajustedepantigas/ativosAjusteDepAntigas.modal.component';
import {AtivosAquisicaoContabModalComponent} from './core/modules/ativos/ativosaquisicaocontab/modal/ativosAquisicaoContab.modal.component';
import {AtivosAquisicaoContabModuleComponent} from './core/modules/ativos/ativosaquisicaocontab/components/ativosAquisicaoContab.module.component';
import {AtivosAquisicaoInvestEmCursoPorDocModuleComponent} from './core/modules/ativos/ativosaquisicaoinvestemcurso/components/ativosAquisicaoInvestEmCursoPorDoc.module.component';
import {AtivosAumentoIFRS16ModalComponent} from './core/entities/ativos/modals/aumentoifrs16/ativos.aumentoIFRS16.modal.component';
import {AtivosCoeficienteCMModuleComponent} from './core/modules/ativos/ativoscoeficientecm/components/ativosCoeficienteCM.module.component';
import {AtivosComponent} from './core/entities/ativos/components/component/ativos.component';
import {AtivosConfiguracoesModuleComponent} from './core/modules/ativos/ativosconfiguracoes/components/ativosConfiguracoes.module.component';
import {AtivosConsistenciaLancaDifModalComponent} from './core/modules/ativos/ativosconsistencia/modal/lancadif/ativosConsistencia.lancaDif.modal.component';
import {AtivosConsistenciaListaAtivosModalComponent} from './core/modules/ativos/ativosconsistencia/modal/listaativos/ativosConsistencia.listaAtivos.modal.component';
import {AtivosConsistenciaModuleComponent} from './core/modules/ativos/ativosconsistencia/components/ativosConsistencia.module.component';
import {AtivosDepreciacaoDatasPosteModalComponent} from './core/modules/ativos/ativosdepreciacao/modal/datasposte/ativosDepreciacao.datasPoste.modal.component';
import {AtivosDepreciacaoModuleComponent} from './core/modules/ativos/ativosdepreciacao/components/ativosDepreciacao.module.component';
import {AtivosDeprecriacoesDetalhadasModalComponent} from './core/entities/ativos/modals/ativosdepreciacoesdetalhadas/ativosDepreciacoesDetalhadas.modal.component';
import {AtivosDeprecriacoesModalComponent} from './core/entities/ativos/modals/ativosdepreciacoes/ativosDepreciacoes.modal.component';
import {AtivosDivisaoModalComponent} from './core/entities/ativos/modals/ativosdivisao/ativosDivisao.modal.component';
import {AtivosEditComponent} from './core/entities/ativos/components/edit/ativos.entity.edit.component';
import {AtivosGeraMapasAutoModuleComponent} from './core/modules/ativos/ativosgeramapasauto/components/ativosGeraMapasAuto.module.component';
import {AtivosGrandeReparacaoModelComponent} from './core/entities/ativos/modals/grandereparacao/ativos.grandeReparacao.model.component';
import {AtivosHistoricoModalComponent} from './core/entities/ativos/modals/historico/ativosHistorico.modal.component';
import {AtivosImportAjustesNasContasModalComponent} from './core/entities/ativosimport/modals/ajustesnascontas/ativosImport.ajustesNasContas.modal.component';
import {AtivosImportAtPercReducaoModalComponent} from './core/entities/ativosimport/modals/atpercreducao/ativosImport.atPercReducao.modal.component';
import {AtivosImportAtValorAnualManModalComponent} from './core/entities/ativosimport/modals/atvaloranualman/ativosImport.atValorAnualMan.modal.component';
import {AtivosImportConsistenciasContasModalComponent} from './core/entities/ativosimport/modals/ativosconsistenciacontas/ativosImport.consistenciasContas.modal.component';
import {AtivosImportEditComponent} from './core/entities/ativosimport/component/edit/ativosImport.entity.edit.component';
import {AtivosImportFolhaImportModalComponent} from './core/entities/ativosimport/modals/folhaimport/ativosImport.folhaImport.modal.component';
import {AtivosImportImportarFicheiroModalComponent} from './core/entities/ativosimport/modals/importarficheiro/ativosImport.importarFicheiro.modal.component';
import {AtivosImportListComponent} from './core/entities/ativosimport/component/list/ativosImport.entity.list.component';
import {AtivosImportProgressBarModalComponent} from './core/entities/ativosimport/modals/progressbar/ativosImport.progressBar.modal.component';
import {AtivosImpressaoDepModalComponent} from './core/modules/ativos/ativosimpressao/modal/depreciacoes/ativosImpressao.dep.modal.component';
import {AtivosImpressaoDocModalComponent} from './core/modules/ativos/ativosimpressao/modal/doccontabilidade/ativosImpressao.doc.modal.component';
import {AtivosImpressaoModalComponent} from './core/modules/ativos/ativosimpressao/modal/ativosImpressao.modal.component';
import {AtivosImpressaoModuleComponent} from './core/modules/ativos/ativosimpressao/components/ativosImpressao.module.component';
import {AtivosListComponent} from './core/entities/ativos/components/list/ativos.entity.list.component';
import {AtivosMapasOficiaisModuleComponent} from './core/modules/ativos/ativosmapasoficiais/components/ativosMapasOficiais.module.component';
import {AtivosMudarContaAtivoModalComponent} from './core/entities/ativos/modals/mudarcontaativo/ativosMudarContaAtivo.modal.component';
import {AtivosMudarContaDepModalComponent} from './core/entities/ativos/modals/mudarcontadep/ativosMudarContaDep.modal.component';
import {AtivosMudarTaxasDepModalComponent} from './core/entities/ativos/modals/mudartaxasdep/ativosMudarTaxasDep.modal.component';
import {AtivosNewComponent} from './core/entities/ativos/components/new/ativos.entity.new.component';
import {AtivosNotaCreditoModalComponent} from './core/entities/ativos/modals/ativosnotacredito/ativosNotaCredito.modal.component';
import {AtivosNotaCreditoSubModalModalComponent} from './core/entities/ativos/modals/ativosnotacredito/submodal/ativosNotaCredito.modal.subModal.component';
import {AtivosOpJustoValorABPModalComponent} from './core/entities/ativos/modals/opjustovalorabp/ativosOpJustoValorABP.modal.component';
import {AtivosPesquisaContabilidadeModalComponent} from './core/entities/ativos/modals/ativospesquisacontabilidade/ativosPesquisaContabilidade.modal.component';
import {AtivosPlanningModalComponent} from './core/entities/ativos/modals/ativosplanning/ativosPlanning.modal.component';
import {AtivosReavaliacoesNaoLegaisModalComponent} from './core/entities/ativos/modals/reavaliacoesnaolegais/ativosReavaliacoesNaoLegais.modal.component';
import {AtivosVendaContabModalComponent} from './core/modules/ativos/ativosvenda/modal/contab/ativosVenda.contab.modal.component';
import {AtivosVendaModalComponent} from './core/entities/ativos/modals/ativosvenda/ativosVenda.modal.component';
import {AtivosVendaModuleComponent} from './core/modules/ativos/ativosvenda/components/ativosVenda.module.component';
import {AvencasEditComponent} from './core/entities/avencas/components/edit/avencas.entity.edit.component';
import {AvencasListComponent} from './core/entities/avencas/components/list/avencas.entity.list.component';
import {AvencasSelecionarTipoModalComponent} from './core/entities/avencas/modal/selecionartipo/avencas.selecionar.tipo.modal.component';
import {AvisoAlteracaoCCModalComponent} from './core/entities/pocs/modals/avisoAlteracaoCC.modal.component';
import {AvisosCobrancaConfigsModalComponent} from './core/modules/portalcontabilidade/avisoscobranca/avisoscobrancagrid/modals/configs/avisosCobranca.configs.modal.component';
import {AvisosCobrancaEmailModalComponent} from './core/modules/portalcontabilidade/avisoscobranca/avisoscobrancagrid/modals/email/avisosCobranca.email.modal.component';
import {AvisosCobrancaGridModuleComponent} from './core/modules/portalcontabilidade/avisoscobranca/avisoscobrancagrid/components/avisosCobrancaGrid.module.component';
import {AvisosCobrancaPreviewModalComponent} from './core/modules/portalcontabilidade/avisoscobranca/avisoscobrancagrid/modals/preview/avisosCobranca.preview.modal.component';
import {BalancetesGridComponent} from './core/components/contabilidade/balancetes/balancetes.grid.component';
import {BancosExtratoModuleActivateLicenseModalComponent} from './core/modules/bancosextrato/modals/activatelicense/bancosExtrato.activateLicense.modal.component';
import {BancosExtratoModuleAuthorizationsModalComponent} from './core/modules/bancosextrato/modals/authorizations/bancosExtrato.authorizations.modal.component';
import {BancosExtratoModuleComponent} from './core/modules/bancosextrato/components/bancosExtrato.module.component';
import {BancosExtratoModuleConfigModalComponent} from './core/modules/bancosextrato/modals/config/bancosExtrato.config.modal.component';
import {BancosExtratoModuleDocsSemelhantesModalComponent} from './core/modules/bancosextrato/modals/docssemelhantes/bancosExtrato.docssemelhantes.modal.component';
import {BancosExtratoModuleEditBankAccountModalComponent} from './core/modules/bancosextrato/modals/editbankaccount/bancosExtrato.editbankaccount.modal.component';
import {BancosExtratoModuleImportByReconciliationModalComponent} from './core/modules/bancosextrato/modals/importbyreconciliation/bancosExtrato.importbyreconciliation.modal.component';
import {BancosExtratoMovabModalComponent} from './core/modules/bancosextrato/modals/movab/bancosExtrato.movab.modal.component';
import {BancosExtratoModulePredefDescModalComponent} from './core/modules/bancosextrato/modals/predefdesc/bancosExtrato.predefdesc.modal.component';
import {BlockedPluginModuleComponent} from './core/modules/blockedplugin/components/blockedPlugin.module.component';
import {CalcDiferimentosComponent} from './core/components/calcdiferimentos/calcdiferimentos.component';
import {CalculoDiferimentosConfigModalComponent} from './core/modules/diferimentos/modals/config/calculoDiferimentos.config.modal.component';
import {CalculoDiferimentosInfoModalComponent} from './core/modules/diferimentos/modals/info/calculoDiferimentos.info.modal.component';
import {CalculoDiferimentosModuleComponent} from './core/modules/diferimentos/calculodiferimentos/components/calculoDiferimentos.module.component';
import {CctEditComponent} from './core/entities/cct/components/edit/cct.entity.edit.component';
import {CCustosNewComponent} from './core/entities/ccustos/components/new/cCustos.entity.new.component';
import {CessacaoContTrabModalComponent} from './core/modules/portalrh/rhcessacontratotrabalho/modals/cessacaoconttrab/cessacaoContTrab.modal.component';
import {CGAppStatusDisconnectedComponent} from './core/components/cg/app/status/disconnected/cg.app.status.disconnected.component';
import {CGAppStatusMaintenanceComponent} from './core/components/cg/app/status/maintenance/cg.app.status.maintenance.component';
import {CGAppStatusNewVersionAvailableComponent} from './core/components/cg/app/status/newversionavailable/cg.app.status.new.version.available.component';
import {CGBlockedPluginComponent} from './core/components/cg/blockedplugin/blockedplugin.component';
import {CGCardPanelComponent} from './core/components/cg/cardpanel/cardpanel.component';
import {CGCardPanelContentDirective} from './core/components/cg/cardpanel/cardpanel.content.directive';
import {CGContabExtratosComponent} from './core/components/contabilidade/extratos/extratos.component';
import {CGCookiesConsentComponent} from './core/components/cg/cookieconsent/cg.cookies.consent.component';
import {CGDateFromNowPipe} from './common/pipes/dateFromNow/datefromnow.pipe';
import {CGDeletePromptModalComponent} from './core/components/cg/modal/deleteprompt/deleteprompt.modal.component';
import {CGEntityFilterComponent} from './core/components/entity/filter/entity.filter.component';
import {CGHelpTopicsModalComponent} from './core/components/cg/modal/helptopics/helptopics.modal.component';
import {CGModalChooseCountryComponent} from './core/components/cg/modal/choosecountry/choosecountry.modal.component';
import {CGModalComponent} from './core/components/cg/modal/cgmodal.component';
import {CGModalContentDirective} from './core/components/cg/modal/cgmodal.content.directive';
import {CGModalHeaderDirective} from './core/components/cg/modal/cgmodal.header.directive';
import {CGModalOkCancelComponent} from './core/components/cg/modal/okcancel/okcancel.modal.component';
import {CGPanelMultipleSelectionExecuteComponent} from './core/components/panelmultipleselectionexecute/panelmultipleselectionexecute.component';
import {CGPanelSliderComponent} from './core/components/panelslider/panelslider.component';
import {CGPanelSliderContentDirective} from './core/components/panelslider/panelslider.content.directive';
import {CGPdfModalComponent} from './core/components/cg/modal/pdf/cg.pdf.modal.component';
import {CGPdfViewerComponent} from './core/components/cg/pdf/cg.pdf.viewer.component';
import {CGTableLegendComponent} from './core/components/tablelegend/tablelegend.component';
import {CGTermsModalComponent} from './core/components/cg/modal/terms/terms.modal.component';
import {CGUserAccessesComponent} from './core/modules/utilizadores/accesses/utilizadores.accesses.component';
import {CGUserAccessesItemComponent} from './core/modules/utilizadores/accesses/utilizadores.accesses.item.component';
import {CGUserLocaleChooserComponent} from './core/components/cg/user/localechooser/cg.user.locale.chooser.component';
import {CGUserNavComponent} from './core/components/cg/user/nav/cg.user.nav.component';
import {CGUserProfileImageComponent} from './core/components/cg/user/profileimage/cg.user.profileimage.component';
import {ChangePasswordComponent} from './core/states/account/changepassword/changepassword.component';
import {CheckSendAllMailsModalComponent} from './core/components/emailsCliFo/modal/checkSendAll/checkSendAllMails.modal.component';
import {ChooseRolesModalComponent} from './core/modules/colaboradores/dgemps/modals/chooserolesmodal/chooseRoles.modal.component';
import {ClassesCaixaEditComponent} from './core/entities/classescaixa/components/edit/classesCaixa.entity.edit.component';
import {ClientesEditComponent} from './core/entities/clifos/components/clientes/edit/clientes.entity.edit.component';
import {ClifoDefinicaoEmailsComponent} from './core/entities/clifos/components/component/definicaoemails/clifoDefinicaoEmails.component';
import {ClifosAvisoDadosRepetidosModalComponent} from './core/entities/clifos/modals/clifosduplicates/clifos.avisoDadosRepetidos.modal.component';
import {ClifosEditComponent} from './core/entities/clifos/components/edit/clifos.entity.edit.component';
import {ClifosFichaComponent} from './core/entities/clifos/components/component/ficha/clifos.ficha.component';
import {ClifosGdocComponent} from './core/components/clifos/gdocs/clifos.gdoc.component';
import {ClifosListComponent} from './core/entities/clifos/components/list/clifos.entity.list.component';
import {CloneMenusModalComponent} from './core/modules/colaboradores/refeicoes/associarmenus/modals/clonemenusmodal/cloneMenus.modal.component';
import {ColaboradorBoardComponent} from './core/modules/colaboradores/dgemps/colaboradorboard/components/colaboradorBoard.module.component';
import {ColaboradoresGestaoDGEMPSModuleComponent} from './core/modules/colaboradores/gestaodgemps/components/colaboradores.gestaodgemps.module.component';
import {ColaboradoresHomeComponent} from './core/modules/colaboradores/home/components/home.module.component';
import {ColaboradoresMarcacaoRefeicoesModuleComponent} from './core/modules/colaboradores/refeicoes/marcar/components/marcacaoRefeicoes.module.component';
import {ColaboradoresVisualizarRefeicoesModuleComponent} from './core/modules/colaboradores/refeicoes/visualizar/components/visualizaRefeicoes.module.component';
import {CompanyStatusComponent} from './core/states/account/companystatus/companystatus.component';
import {ComplementosExcepcoesModalComponent} from './core/modules/portalrh/rhcessacontratotrabalho/modals/complementosexpcecoes/complementosExcepcoes.modal.component';
import {ComunicacaoInternaRecursivaComponent} from './core/modules/colaboradores/home/components/comunicacaointernacursiva/comunicacaoInternaRecursiva.component';
import {ComunicacaoSeriesATModalComponent} from './core/entities/docfasnums/modals/comunicacao/comunicacaoseriesat/comunicacaoSeriesAt.modal.component';
import {ComunicacoesInternasComponent} from './core/modules/colaboradores/comunicacoesinternas/components/comunicacoesInternas.module.component';
import {ComunicacoesInternasModalComponent} from './core/modules/colaboradores/comunicacoesinternas/modals/comunicacoesInternas.modal.component';
import {ComunicaDocsModalComponent} from './core/modules/efatura/modals/comunicadocsmodal/comunicaDocs.modal.component';
import {ConcelhosDadosDerramaModalComponent} from './core/entities/concelhos/modals/concelhos.dadosDerrama.modal.component';
import {ConcelhosEditComponent} from './core/entities/concelhos/components/edit/concelhos.entity.edit.component';
import {ConciliacaoBancariaAddDocsNaoConciliadosModalComponent} from './core/modules/portalcontabilidade/conciliacaobancaria/modals/adddocsnaoconciliados/conciliacaoBancaria.addDocsNaoConciliados.modal.component';
import {ConciliacaoBancariaAddLinhaExtratoBancoModalComponent} from './core/modules/portalcontabilidade/conciliacaobancaria/modals/addlinhaextratobanco/conciliacaoBancaria.addLinhaExtratoBanco.modal.component';
import {ConciliacaoBancariaAddLinhaExtratoCGModalComponent} from './core/modules/portalcontabilidade/conciliacaobancaria/modals/addlinhaextratocg/conciliacaoBancaria.addLinhaExtratoCG.modal.component';
import {ConciliacaoBancariaBancosTreeViewerComponent} from './core/components/conciliacaobancaria/bancostreeviewer/conciliacaobancaria.bancos.treeviewer.component';
import {ConciliacaoBancariaConfigImportExtratoModalComponent} from './core/modules/portalcontabilidade/conciliacaobancaria/modals/configimportextrato/conciliacaoBancaria.configImportExtrato.modal.component';
import {ConciliacaoBancariaImportExtratoBancarioModalComponent} from './core/modules/portalcontabilidade/conciliacaobancaria/modals/importextratobancario/conciliacaoBancaria.importExtratoBancario.modal.component';
import {ConciliacaoBancariaModuleComponent} from './core/modules/portalcontabilidade/conciliacaobancaria/components/conciliacaoBancaria.module.component';
import {ConciliacaoBancariaNovaConciliacaoModalComponent} from './core/modules/portalcontabilidade/conciliacaobancaria/modals/novaconciliacao/conciliacaoBancaria.novaConciliacao.modal.component';
import {ConciliacaoBancariaPdfModalComponent} from './core/modules/portalcontabilidade/conciliacaobancaria/modals/pdf/conciliacaoBancaria.pdf.modal.component';
import {ConciliacaoBancariaSaldoDetalhesModalComponent} from './core/modules/portalcontabilidade/conciliacaobancaria/modals/saldodetalhes/conciliacaoBancaria.saldo.detalhes.modal.component';
import {ConciliacaoBancariaViewDocModalComponent} from './core/modules/portalcontabilidade/conciliacaobancaria/modals/viewdoc/conciliacaoBancaria.viewDoc.modal.component';
import {ConcilMovAbJustEmSerieContasModalComponent} from './core/modules/concilmovab/modal/justemseriecontas/concilMovAb.justEmSerieContas.modal.component';
import {ConcilMovAbModuleComponent} from './core/modules/concilmovab/components/concilMovAb.module.component';
import {ConcilMovAbProgressBarModalComponent} from './core/modules/concilmovab/modal/progressbar/concilMovAb.progressBar.modal.component';
import {CondicoescomerciaisEditComponent} from './core/entities/condicoescomerciais/components/edit/condicoesComerciais.entity.edit.component';
import {ConfigDocsEFaturaGlobalComponent} from './core/modules/configEfaturaDocPorLancar/components/global/configDocsEfatura.global.component';
import {ConfigDocsEFaturaHeaderComponent} from './core/modules/configEfaturaDocPorLancar/components/header/configDocsEfatura.header.component';
import {ConfigDocsEFaturaSupplierComponent} from './core/modules/configEfaturaDocPorLancar/components/supplier/configdocsefatura.supplier.component';
import {ConfigEfaturaDocPorLancModalComponent} from './core/modules/configEfaturaDocPorLancar/modals/configEfaturaDocPorLanc.modal.component';
import {ConfigEfaturaDocPorLancModuleComponent} from './core/modules/configEfaturaDocPorLancar/components/configEfaturaDocPorLanc.module.component';
import {ConfigEfaturaDocPorLancSupplierModalComponent} from './core/modules/configEfaturaDocPorLancar/modals/supplier/configEfaturaDocPorLanc.supplier.modal.component';
import {ConfigErpComponent} from './core/modules/configs/erp/components/configedit/configs.erp.edit.component';
import {ConfigLoginWsSSModalComponent} from './core/modules/segsocial/modals/loginWsSS.modal.component';
import {ConfigOptionsComponent} from './core/components/configoptions/config.options.component';
import {ConfigsErpClifosModuleComponent} from './core/modules/configs/erp/clifos/components/configs.clifos.module.component';
import {ConfigsErpComercialComponent} from './core/modules/configs/erp/comercial/components/configs.comercial.module.component';
import {ConfigsErpContabilidadeModuleComponent} from './core/modules/configs/erp/contabilidade/components/configs.contabilidade.module.component';
import {ConfigsErpEmpresaModuleComponent} from './core/modules/configs/erp/empresa/components/configs.empresa.module.component';
import {ConfigsErpLicencaModuleComponent} from './core/modules/configs/erp/licenca/components/configs.licenca.module.component';
import {ConfigsErpPCAModuleComponent} from './core/modules/configs/erp/pca/components/configs.pca.module.component';
import {ConfigsErpRHModuleComponent} from './core/modules/configs/erp/rh/components/configs.rh.module.component';
import {ConfigsSiteModuleComponent} from './core/modules/configs/site/components/configsSite.module.component';
import {ConfiguracoesEventosComponent} from './core/modules/colaboradores/configuracoeseventos/components/component/configuracoesEventos.component';
import {ConfiguracoesEventosModalComponent} from './core/modules/colaboradores/configuracoeseventos/modal/configuracoesEventos.modal.component';
import {ConfiguracoesEventosModuleComponent} from './core/modules/colaboradores/configuracoeseventos/components/configuracoesEventos.module.component';
import {ConfigValueComponent} from './core/modules/configs/erp/components/configeditvalue/configs.erp.editvalue.component';
import {ConsultaSerieAtModalComponent} from './core/entities/docfasnums/modals/consulta/consultaSerieAt.modal.component';
import {ConsultEnviosImpResumoModalComponent} from './core/modules/portalrh/rhprocsegsocialconsultenvios/modals/impresumo/consultEnvios.impResumo.modal.component';
import {ContabAdvancedOperationsModuleComponent} from './core/modules/portalcontabilidade/contabadvancedoperations/components/contabAdvancedOperations.module.component';
import {ContabDigitalClientConnectSynchronizationKeyModalComponent} from './core/components/arquivodigital/modals/config/clientconnectsynchronizationkey/contabdigital.clientconnectsynchronizationkey.modal.component';
import {ArquivoDigitalConfigModalComponent} from './core/components/arquivodigital/modals/config/arquivoDigital.config.modal.component';
import {ContabilidadeApuraivaComponent} from './core/modules/portalcontabilidade/manutencao/apuraiva/components/contabilidade.apuraIva.module.component';
import {ContabilidadeApuraIvaSaveModalComponent} from './core/modules/portalcontabilidade/manutencao/apuraiva/modals/save/contabilidade.apuraIva.save.modal.component';
import {ContabilidadeApuramentoResultadosComponent} from './core/modules/portalcontabilidade/manutencao/apuramentoresultados/components/contabilidade.apuramentoResultados.module.component';
import {ContabilidadeBalancetesAnaliticaComponent} from './core/modules/portalcontabilidade/estatistica/balancetesanalitica/components/contabilidade.balancetes.analitica.module.component';
import {ContabilidadeBalancetesComponent} from './core/modules/portalcontabilidade/estatistica/balancetes/components/contabilidade.balancetes.module.component';
import {ContabilidadeDeclaracaoivaConfigModalComponent} from './core/modules/portalcontabilidade/manutencao/declaracaoiva/modals/config/contabilidade.declaracaoIva.config.modal.component';
import {ContabilidadeDeclaracaoIvaPeriodicaModuleComponent} from './core/modules/portalcontabilidade/manutencao/declaracaoiva/periodica/contabilidade.declaracaoIvaPeriodica.module.component';
import {ContabilidadeDeclaracaoIvaRecapitulativaModuleComponent} from './core/modules/portalcontabilidade/manutencao/declaracaoiva/recapitulativa/contabilidade.declaracaoIva.recapitulativa.module.component';
import {ContabilidadeDigitalActivateLicenseModalComponent} from './core/components/arquivodigital/modals/activatelicense/contabilidadedigital.activatelicensemodal.component';
import {ContabilidadeDigitalAtribuirTipoDocumentoModalComponent} from './core/components/arquivodigital/modals/atribuirtipodocumento/contabilidadedigital.atribuirtipodocumento.modal.component';
import {ContabilidadeDigitalCheckFoldersCreatedModalComponent} from './core/components/arquivodigital/modals/checkfolderscreated/contabilidadedigital.checkfolderscreated.modal.component';
import {ContabilidadeDigitalClientConnectAddDocsModalComponent} from './core/components/arquivodigital/contabilidade/clientconnect/modals/adddocsmodal/contabilidadedigital.clientconnect.adddocsmodal.component';
import {ContabilidadeDigitalClientConnectDeleteDocsModalComponent} from './core/components/arquivodigital/contabilidade/clientconnect/modals/deletedocsmodal/contabilidadedigital.clientconnect.deletedocsmodal.component';
import {ContabilidadeDigitalClientConnectInspectDocModalComponent} from './core/components/arquivodigital/contabilidade/clientconnect/modals/inspectdocmodal/contabilidadedigital.clientconnect.inspectdocmodal.component';
import {ContabilidadeDigitalClientConnectModuleComponent} from './core/modules/contabilidadedigital/clientconnect/components/contabDigital.clientConnect.module.component';
import {ContabilidadeDigitalClientConnectNativeComponent} from './core/components/arquivodigital/contabilidade/clientconnect/native/contabilidadedigital.clientconnect.native.component';
import {ContabilidadeDigitalCopiarDocModalComponent} from './core/components/arquivodigital/modals/copiardoc/contabilidadedigital.copiardoc.modal.component';
import {ContabilidadeDigitalDocumentosDigitaisClientConnectModuleComponent} from './core/modules/contabilidadedigital/documentosdigitais/clientconnect/contabDigital.documentosDigitais.clientConnect.module.component';
import {ContabilidadeDigitalDocumentosDigitaisConcilicaoModuleComponent} from './core/modules/contabilidadedigital/documentosdigitais/conciliacaodocs/contabDigital.documentosDigitais.conciliacao.module.component';
import {ContabilidadeDigitalDocumentosDigitaisModuleComponent} from './core/modules/contabilidadedigital/documentosdigitais/components/contabDigital.documentosDigitais.module.component';
import {ArquivoDigitalDocViewerComponent} from './core/components/arquivodigital/common/docviewer/arquivodigital.docviewer.component';
import {ContabilidadeDigitalDocViewerRecolhaAttachExistingDocModalComponent} from './core/components/arquivodigital/modals/attachexistingdoc/contabilidadedigital.docviewer.recolha.attachexistingdoc.modal.component';
import {ContabilidadeDigitalDocViewerRecolhaChooseFolderModalComponent} from './core/components/arquivodigital/modals/choosefolder/contabilidadedigital.docviewer.recolha.choosefolder.modal.component';
import {ContabilidadeDigitalDocViewerRecolhaComponent} from './core/components/arquivodigital/contabilidade/docviewerrecolha/contabilidadedigital.docviewer.recolha.component';
import {ContabilidadeDigitalDocViewerRecolhaFooterComponent} from './core/components/arquivodigital/contabilidade/docviewerrecolha/footer/contabilidadedigital.docviewer.recolha.footer.component';
import {ContabilidadeDigitalFlatViewerComponent} from './core/components/arquivodigital/common/flatviewer/contabilidadedigital.flatviewer.component';
import {ArquivoDigitalGestaoDocumentosDigitalizadosComponent} from './core/components/arquivodigital/common/gestaodocumentosdigitalizados/arquivoDigital.gestaoDocumentosDigitalizados.component';
import {ContabilidadeDigitalNovaContaCCModalComponent} from './core/components/arquivodigital/modals/novacontacc/contabilidadedigital.novacontacc.modal.component';
import {ContabilidadeDigitalPreDefinidosConfigModalComponent} from './core/components/arquivodigital/contabilidade/predefinidoconfig/contabilidadedigital.predefinidosconfig.modal.component';
import {ContabilidadeDigitalRemovePagesModalComponent} from './core/components/arquivodigital/modals/removepages/contabilidadedigital.removepages.modal.component';
import {ContabilidadeDigitalSepararDocModalComponent} from './core/components/arquivodigital/modals/separardoc/contabilidadedigital.separardoc.modal.component';
import {ContabilidadeDigitalTreeViewerComponent} from './core/components/arquivodigital/common/treeviewer/contabilidadedigital.treeviewer.component';
import {ContabilidadeDigitalTreeViewerNodeComponent} from './core/components/arquivodigital/common/treeviewer/contabilidadedigital.treeviewer.node.component';
import {ContabilidadeDigitalUnirDocsModalComponent} from './core/components/arquivodigital/modals/unirdocs/contabilidadedigital.unirdocs.modal.component';
import {ContabilidadeDocsNaoSaldadosComponent} from './core/modules/portalcontabilidade/manutencao/docsnaosaldados/components/contabilidade.docsNaoSaldados.module.component';
import {ContabilidadeExtratosComponent} from './core/modules/portalcontabilidade/estatistica/extratosdecontas/contabilidade.extratosDeContas.module.component';
import {ContabilidadeExtratosGridComponent} from './core/modules/portalcontabilidade/estatistica/extratosgrid/components/contabilidade.extratosGrid.module.component';
import {ContabilidadeExtratosGridModalComponent} from './core/modules/portalcontabilidade/estatistica/extratosgrid/modal/contabilidade.extratosGrid.modal.component';
import {ContabilidadeExtratosSoAtePeriodoComponent} from './core/modules/portalcontabilidade/estatistica/extratos/components/contabilidade.extratos.module.component';
import {ContabilidadeImpdecpeComponent} from './core/modules/portalcontabilidade/estatistica/impdecpe/components/contabilidade.impDecPe.module.component';
import {ContabilidadeMovimentosEmAbertoComponent} from './core/modules/portalcontabilidade/estatistica/movimentosemaberto/components/contabilidade.movimentosEmAberto.module.component';
import {ContabilidadePredefinidosComponent} from './core/modules/portalcontabilidade/manutencao/predefinidos/components/contabilidade.predefinidos.component';
import {ContabilidadeSvatBalanceteModalComponent} from './core/modules/portalcontabilidade/manutencao/svat/modals/balancete/contabilidade.svat.balancete.modal.component';
import {ContabilidadeSvatComponent} from './core/modules/portalcontabilidade/manutencao/svat/components/contabilidade.svat.module.component';
import {ContabilidadeSvatPrintComponent} from './core/modules/portalcontabilidade/manutencao/svat/modals/print/contabilidade.svat.print.modal.component';
import {ContaPocSaldaModalComponent} from './core/modules/taxonomias/modals/contapocsalda/contaPocSalda.modal.component';
import {ContasClienteComponent} from './core/modules/portalclientaccounts/myaccount/contascliente/components/contasCliente.module.component';
import {ContratosDetailComponent} from './core/entities/contratos/components/detail/contratos.entity.detail.component';
import {ContratosEditComponent} from './core/entities/contratos/components/edit/contratos.entity.edit.component';
import {ContratosListComponent} from './core/entities/contratos/components/list/contratos.entity.list.component';
import {ContratosPrintModuleComponent} from './core/modules/contratos/print/contratos.print.module.component';
import {ContratosTipoAvencasEditComponent} from './core/entities/contratostipoavencas/components/edit/contratosTipoAvencas.entity.edit.component';
import {ContratosTiposEditComponent} from './core/entities/contratostipos/components/edit/contratosTipos.entity.edit.component';
import {ControloIVAEditComponent} from './core/entities/controloiva/components/edit/controloIVA.entity.edit.component';
import {ControloIVAEntreEmpresasComponent} from './core/modules/controloivaentreempresas/components/controloIVAEntreEmpresas.module.component';
import {ControloIVAListComponent} from './core/entities/controloiva/components/list/controloIVA.entity.list.component';
import {ContTrabBaseLegalEditComponent} from './core/entities/conttrabbaselegal/components/edit/contTrabBaseLegal.entity.edit.component';
import {CPPesquisaDocsLinhaComponent} from './core/components/contabilidade/pesquisaDocsLinha/pesquisaDocsLinha.component';
import {CreateNewContaCorrenteModalComponent} from './core/modules/portalcontabilidade/importadorefatura/modals/createNewContaCorrente/createNewContaCorrente.modal.component';
import {CriarTaxaIvaModalComponent} from './core/entities/taxasIva/modals/criataxasiva/criarTaxaIva.modal.component';
import {CRSSEditComponent} from './core/entities/crss/components/edit/crss.entity.edit.component';
import {CruzamentosABDESEditComponent} from './core/entities/crabd/components/edit/crabd.entity.edit.component';
import {CruzamentosABDESListComponent} from './core/entities/crabd/components/list/crabd.entity.list.component';
import {DashboardsERPModuleComponent} from './core/modules/dashboardserp/components/dashBoardsERP.module.component';
import {DataSourceInputComponent} from './core/components/datasource/input/datasource.input.component';
import {DeclaracaoIvaPeriodicaExtratoCampoModalComponent} from './core/modules/portalcontabilidade/manutencao/declaracaoiva/modals/extratocampo/declaracaoIvaPeriodica.extratoCampo.modal.component';
import {DeclaracaoIvaPeriodicaNifROCModalComponent} from './core/modules/portalcontabilidade/manutencao/declaracaoiva/modals/roc/declaracaoIvaPeriodica.nifRoc.modal.component';
import {DeclaracaoIvaPeriodicaPrevisualizaDeclaracaoModalComponent} from './core/modules/portalcontabilidade/manutencao/declaracaoiva/modals/previsualizadeclaracao/declaracaoIvaPeriodica.preVisualizaDeclaracao.modal.component';
import {DefinicaoEmailsModalComponent} from './core/entities/clifos/modals/definicaoemails/definicaoEmails.modal.component';
import {DemFinSNCListSavedModalComponent} from './core/modules/demfinsnc/modals/listsaved/demFinSNC.listSaved.modal.component';
import {DemFinSNCModuleComponent} from './core/modules/demfinsnc/components/demFinSNC.module.component';
import {DemFinSNCNotasModalComponent} from './core/modules/demfinsnc/modals/notas/demFinSNC.notas.modal.component';
import {DemFinSNCObsModalComponent} from './core/modules/demfinsnc/modals/obs/demFinSNC.obs.modal.component';
import {DemFinSNCPreviewPDFModalComponent} from './core/modules/demfinsnc/modals/previewpdf/demFinSNC.previewPDF.modal.component';
import {DevExpressCentralGestContextMenuComponent} from './core/components/devexpress/centralgest/contextmenu/devexpress.centralgest.contextmenu.component';
import {DevExpressChartCentralGestDirective} from './core/components/devexpress/charts/centralgest/devexpress.chart.centralgest.directive';
import {DevExpressDataGridCentralGestDirective} from './core/components/devexpress/datagrid/centralgest/devexpress.datagrid.centralgest.directive';
import {DevExpressDatagridNavigationDirective} from './core/components/devexpress/datagrid/navigation/devexpress.datagrid.navigation.directive';
import {DevExpressPieChartCentralGestDirective} from './core/components/devexpress/charts/centralgest/devexpress.pie.chart.centralgest.directive';
import {DgempsFichaComponent} from './core/modules/colaboradores/dgemps/ficha/components/dgempsFicha.module.component';
import {DGEMPSFullCommentModalComponent} from './core/modules/portalrh/dgempsfull/modals/comment/dgempsFull.comment.modal.component';
import {DGEMPSFullEditComponent} from './core/modules/portalrh/dgempsfull/components/edit/dgempsFull.edit.component';
import {DGEMPSFullEditDeletePromptModalComponent} from './core/modules/portalrh/dgempsfull/modals/deleteprompt/dgempsFull.edit.deletePrompt.modal.component';
import {DgempsFullListComponent} from './core/modules/portalrh/dgempsfull/components/list/dgempsFull.list.component';
import {DGEMPSFullSaveCadastroModalComponent} from './core/modules/portalrh/dgempsfull/modals/savecadastro/dgempsFull.saveCadastro.modal.component';
import {DisconnectedComponent} from './core/states/account/disconnected/disconnected.state.component';
import {DmrfEditModalComponent} from './core/modules/portalcontabilidade/dmrf/modals/dmrf.edit.modal.component';
import {DmrfModuleComponent} from './core/modules/portalcontabilidade/dmrf/components/dmrf.module.component';
import {DocContabilidadeCalcDiferimentosModalComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/modals/calcdiferimentos/docContabilidade.calcDiferimentos.modal.component';
import {DocContabilidadeCambioComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/fields/cambio/docContabilidade.cambio.component';
import {DocContabilidadeCDecAnualComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/fields/cdecanual/docContabilidade.CDecAnual.component';
import {DocContabilidadeCDecPerComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/fields/cdecper/docContabilidade.CDecPer.component';
import {DocContabilidadeComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/docContabilidade.component';
import {DocContabilidadeDataDocComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/fields/datadoc/docContabilidade.dataDoc.component';
import {DocContabilidadeDescricaoComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/fields/descricao/docContabilidade.descricao.component';
import {DocContabilidadeNContaCreditoComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/fields/ncontacredito/docContabilidade.nContaCredito.component';
import {DocContabilidadeNContaDebitoComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/fields/ncontadebito/docContabilidade.nContaDebito.component';
import {DocContabilidadeNContribuinteComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/fields/ncontribuinte/docContabilidade.nContribuinte.component';
import {DocContabilidadeTotaisComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/totais/docContabilidade.totais.component';
import {DocContabilidadeValorComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/fields/valor/docContabilidade.valor.component';
import {DocContabilidadeValorMEComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/fields/valorme/docContabilidade.valorME.component';
import {DocContabilidadeValorTaxaComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/fields/valortaxa/docContabilidade.valorTaxa.component';
import {DocfaNumsFinalizarAtModalComponent} from './core/entities/docfasnums/modals/comunicacao/finalizarserie/docfaNumsFinalizarAt.modal.component';
import {DocfasEditComponent} from './core/entities/docfas/components/edit/docFas.entity.edit.component';
import {DocfasListComponent} from './core/entities/docfas/components/list/docFas.entity.list.component';
import {DocfasNewModalComponent} from './core/entities/docfas/modals/newdocfas/docFas.new.modal.component';
import {DocfasNovaSerieModalComponent} from './core/entities/docfas/modals/novaserie/docFas.novaSerie.modal.component';
import {DocFasNumsComunicacaoLoginModalComponent} from './core/modules/docfasnumscomunicacaologin/docFasNumsComunicacaoLogin.modal.component';
import {DocsComerciaisEditComponent} from './core/entities/docscomerciais/components/edit/docsComerciais.entity.edit.component';
import {DocsComerciaisEstatisticasComponent} from './core/modules/listagens/docscomerciasestatisticas/artigos/components/docsComerciaisEstatisticas.component';
import {DocsComerciaisEstatisticasModuleComponent} from './core/modules/listagens/docscomerciasestatisticas/artigos/docsComerciaisEstatisticas.module.component';
import {DocsComerciaisEstatisticasDocComponent} from './core/modules/listagens/docscomerciasestatisticas/docs/docsComerciaisEstatisticasDoc.module.component';
import {DocsComerciaisEstatisticasDocPrintModalComponent} from './core/modules/listagens/docscomerciasestatisticas/docs/modals/docsComerciaisEstatisticasDoc.print.modal';
import {DocsComerciaisEstatisticasPrintModalComponent} from './core/modules/listagens/docscomerciasestatisticas/artigos/modals/docsComerciaisEstatisticas.print.modal';
import {DocsComerciaisImportadorLinhasModalComponent} from './core/entities/docscomerciais/modals/importadorlinhas/docsComerciais.importadorLinhas.modal.component';
import {DocsComerciaisListComponent} from './core/entities/docscomerciais/components/list/docsComerciais.entity.list.component';
import {DocsComerciaisMeiosPagamentoComponent} from './core/entities/docscomerciais/components/meiospagamento/docsComerciais.meiosPagamento.component';
import {DocsComerciaisSaveModalComponent} from './core/entities/docscomerciais/modals/save/docsComerciais.save.modal.component';
import {DocsContabilidadeEditCCustoModalComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/modals/editccustomodal/docsContabilidade.edit.cCusto.modal.component';
import {DocsContabilidadeEditComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/edit/docsContabilidade.edit.component';
import {DocsContabilidadeEditMoedaEstrangeiraModalComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/modals/editmoedaestrangeiramodal/docsContabilidade.edit.moedaEstrangeira.modal.component';
import {DocsContabilidadeEditMovabModalComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/modals/editmovimentosabertomodal/docsContabilidade.edit.movAb.modal.component';
import {DocsContabilidadeEditRegularizacaoCampo40ModalComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/modals/editregularizacaocampo40modal/docsContabilidade.edit.regularizacaoCampo40.modal.component';
import {DocsContabilidadeEditReguralizacaoCampo40CancelModalComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/modals/editregularizacaocampo40modal/cancelPrompt/docsContabilidade.edit.regularizacaoCampo40.cancel.modal.component';
import {DocsContabilidadeEditRetencaoModalComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/modals/editretencaomodal/docsContabilidade.edit.retencao.modal.component';
import {DocsContabilidadeEditValorModalComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/modals/editvalormodal/docsContabilidade.editValor.modal.component';
import {DocsContabilidadeListComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/list/docsContabilidade.list.component';
import {DocsContabilidadePrintModalComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/printmodal/docsContabilidade.print.modal.component';
import {DocsContabilidadeSavePromptModalComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/modals/savepromptmodal/docsContabilidade.savePrompt.modal.component';
import {DocsContabilidadeViewDocModalComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/modals/viewdoc/docsContabilidade.viewdoc.modal.component';
import {DocsContasInventarioModalComponent} from './core/modules/portalcontabilidade/manutencao/apuramentoresultados/modals/docscontasinventario/docsContasInventario.modal.component';
import {DocsDigitaisAdicionarDocumentosModalComponent} from './core/modules/contabilidadedigital/documentosdigitais/modals/adicionardocumentos/docsDigitais.adicionarDocumentos.modal.component';
import {DocsDigitaisDocsContabSemDocDigitalModalComponent} from './core/modules/contabilidadedigital/documentosdigitais/modals/docscontabsemdocdigital/docsDigitais.docsContabSemDocDigital.modal.component';
import {DocsDigitaisExportarModalComponent} from './core/modules/contabilidadedigital/documentosdigitais/modals/exportar/docsDigitais.exportar.modal.component';
import {DocsDigitaisTreeDocsNaoClassificadosModalComponent} from './core/modules/contabilidadedigital/documentosdigitais/modals/treedocsnaoclassificados/docsDigitais.treeDocsNaoClassificados.modal.component';
import {DocsPlataformaComponent} from './core/modules/colaboradores/docsplataforma/components/docsPlataforma.module.component';
import {DocsPlataformaModalComponent} from './core/modules/colaboradores/docsplataforma/modals/docsPlataforma.modal.component';
import {DocumentoAnularModalComponent} from './core/entities/docscomerciais/modals/anular/documento.anular.modal.component';
import {DocumentoArredondamentoManualModalComponent} from './core/entities/docscomerciais/modals/arredondamentomanual/documento.arredondamentoManual.modal.component';
import {DocumentoComunicarATModalComponent} from './core/entities/docscomerciais/modals/autoridadetributaria/documento.comunicarAT.modal.component';
import {DocumentoContabilidadeComponent} from './core/components/documento/contabilidade/documento.contabilidade.component';
import {DocumentoDetailModalComponent} from './core/entities/docscomerciais/modals/detalhe/documento.detail.modal.component';
import {DocumentoDuplicarModalComponent} from './core/entities/docscomerciais/modals/duplicardocumento/documento.duplicarDocumento.modal.component';
import {DocumentoFacturacaoEncerrarModalComponent} from './core/components/documento/facturacao/documentofaturacaoencerrarModal/documento.facturacao.encerrar.modal.component';
import {DocumentoFaturacaoComponent} from './core/components/documento/facturacao/documento.facturacao.component';
import {DocumentoMeiosPagamentoModalComponent} from './core/entities/docscomerciais/modals/meiospagamento/documento.meiosPagamento.modal';
import {DocumentoNotacreditoModalComponent} from './core/entities/docscomerciais/modals/notacredito/documento.notaCredito.modal.component';
import {DocumentoPesqDocsGlobaisModalComponent} from './core/entities/docscomerciais/modals/pesqdocsglobais/documento.pesqDocsGlobais.modal.component';
import {DocumentoPesqDocsModalComponent} from './core/entities/docscomerciais/modals/pesqdocs/documento.pesqDocs.modal.component';
import {DocumentoRastreabilidadeModalComponent} from './core/entities/docscomerciais/modals/rastreabilidade/documento.rastreabilidade.modal.component';
import {DocumentosComerciaisComponent} from './core/modules/portalclientaccounts/myaccount/documentoscomerciais/components/documentosComerciais.module.component';
import {DocumentosFiscaisAssociarDocumentosContabDigitalModalComponent} from './core/modules/portalcontabilidade/manutencao/documentosFiscais/modals/associardocumentoscontabdigital/documentosFiscais.associarDocumentosContabDigital.modal.component';
import {DocumentosFiscaisComponent} from './core/modules/portalcontabilidade/manutencao/documentosFiscais/components/documentosFiscais.module.component';
import {DocumentosFiscaisDpIvaDocPagamentoModalComponent} from './core/modules/portalcontabilidade/manutencao/documentosFiscais/modals/dpIvadocpagamento/documentosFiscais.dpIvaDocPagamento.modal.component';
import {DocumentosFiscaisIesPagamentoModalComponent} from './core/modules/portalcontabilidade/manutencao/documentosFiscais/modals/iespagamento/documentosFiscais.iesPagamento.modal.component';
import {DocumentosPartilhadosComponent} from './core/modules/documentospartilhados/components/documentosPartilhados.module.component';
import {DocumentosPartilhadosUploadModalComponent} from './core/modules/documentospartilhados/modals/upload/documentosPartilhados.upload.modal.component';
import {DossierFiscalComponent} from './core/modules/portalclientaccounts/dossierfiscal/components/dossierFiscal.module.component';
import {EditarCruzamentosModalComponent} from './core/entities/abdes/modals/editarcruzamentos/editarCruzamentos.modal.component';
import {EditarCruzamentosPerfilModalComponent} from './core/entities/abdes/modals/editarcruzamentosperfil/editarCruzamentosPerfil.modal.component';
import {EFaturaCirculacaoLoginModalComponent} from './core/modules/efaturacirculacaologin/eFaturaCirculacaoLogin.modal.component';
import {EFaturaComponent} from './core/modules/efatura/components/eFatura.module.component';
import {EFaturaGenerateDocsModalComponent} from './core/modules/efatura/modals/efaturageneratedocs/eFaturaGenerateDocs.modal.component';
import {EFaturaLoginModalComponent} from './core/modules/efaturalogin/eFaturaLogin.modal.component';
import {EmailClifoSendMailModalComponent} from './core/components/emailsCliFo/modal/emailClifo.modal.component';
import {EmpregadosComponent} from './core/modules/colaboradores/dgemps/empregados/empregados.module.component';
import {EmpresaBloqueadaComponent} from './core/states/account/empresabloqueada/empresabloqueada.state.component';
import {EmpresasComponent} from './core/modules/empresas/components/empresas.module.component';
import {EmpresasPerioContabFirstMonthModalComponent} from './core/modules/empresas/modals/periocontabfirstmonth/empresas.perioContabFirstMonth.modal.component';
import {EmpresaTarifasModalComponent} from './core/modules/empresas/modals/empresatarifas/empresaTarifas.modal.component';
import {EntidadeExternaEditComponent} from './core/entities/entidadeexterna/component/edit/entidadeExterna.entity.edit.component';
import {EntityAutocompleteComponent} from './core/components/cg/autocomplete/entity.autocomplete.component';
import {EntityDetailComponent} from './core/components/entity/detail/entity.detail.component';
import {EntityDetailContentDirective} from './core/components/entity/detail/entity.detail.content.directive';
import {EntityDetailPartialContentDirective} from './core/components/entity/detail/entity.detail.partial.content.directive';
import {EntityDetailSuccessDirective} from './core/components/entity/detail/entity.detail.success.directive';
import {EntityListComponent} from './core/components/entity/list/entity.list.component';
import {EntityListDataGridTemplateDirective} from './core/components/entity/list/entity.list.datagrid.template.directive';
import {EntityListDatagridToolbarItemTemplateDirective} from './core/components/entity/list/entity.list.datagrid.toolbar.item.template.directive';
import {EntityListHeaderActionsDirective} from './core/components/entity/list/entity.list.headeractions.directive';
import {EntityListItemActionsDirective} from './core/components/entity/list/entity.list.itemactions.directive';
import {EntityListItemDetailDirective} from './core/components/entity/list/entity.list.itemdetail.directive';
import {EntityListToolbarComponent} from './core/components/entity/list/entity.list.toolbar.component';
import {EntityMaintenanceDetailModalComponent} from './core/components/entity/maintenance/modal/detail/entity.maintenance.detail.modal.component';
import {EntityMaintenanceListModalComponent} from './core/components/entity/maintenance/modal/list/entity.maintenance.list.modal.component';
import {ErpCheckModuleComponent} from './core/modules/configs/erpcheck/components/erp.check.module.component';
import {ErpCheckValidateAllModalComponent} from './core/modules/configs/erpcheck/modals/validateallmodal/erp.check.validateAll.modal.component';
import {ExcecoesCrAbDModalComponent} from './core/entities/crabd/modals/excecoes/excecoesCrabd.modal.component';
import {ExtractosArtigosComponent} from './core/modules/extractosartigos/components/component/extractosArtigos.component';
import {ExtractosartigosModuleComponent} from './core/modules/extractosartigos/components/extractosArtigos.module.component';
import {ExtratosClifosComponent} from './core/modules/extratosclifos/components/extratosClifos.module.component';
import {ExtratosContaComponent} from './core/modules/portalclientaccounts/myaccount/extratosconta/components/extratosConta.module.component';
import {ExtratosDTComponent} from './core/modules/portalcontabilidade/estatistica/extratosdt/components/component/extratosDT.component';
import {ExtratosDTModuleComponent} from './core/modules/portalcontabilidade/estatistica/extratosdt/components/extratosDT.module.component';
import {FamiliasEditComponent} from './core/entities/familias/components/edit/familias.entity.edit.component';
import {FaturacaoContratosComponent} from './core/modules/faturacaocontratos/components/faturacaoContratos.module.component';
import {FaturacaoContratosLogComponent} from './core/modules/faturacaocontratos/modals/faturacaocontratoslog/faturacaoContratosLog.modal.component';
import {FaturacaoEletronicaConfiguracoesAddSafeCertificadoModalComponent} from './core/modules/faturacaoeletronicaconfiguracoes/modals/addsafecertificado/faturacaoEletronicaConfiguracoes.addSafeCertificado.modal.component';
import {FaturacaoEletronicaConfiguracoesClientModalComponent} from './core/modules/faturacaoeletronicaconfiguracoes/modals/client/faturacaoEletronicaConfiguracoes.client.modal.component';
import {FaturacaoEletronicaConfiguracoesDigitalSignAuthorizerModalComponent} from './core/modules/faturacaoeletronicaconfiguracoes/modals/digitalsignauthorizer/faturacaoEletronicaConfiguracoes.digitalSignAuthorizer.modal.component';
import {FaturacaoEletronicaConfiguracoesGuideModalComponent} from './core/modules/faturacaoeletronicaconfiguracoes/modals/guide/faturacaoEletronicaConfiguracoes.guide.modal.component';
import {FaturacaoEletronicaConfiguracoesModuleComponent} from './core/modules/faturacaoeletronicaconfiguracoes/components/faturacaoEletronicaConfiguracoes.module.component';
import {FaturacaoEletronicaConfiguracoesPostAddSafeCertificadoModalComponent} from './core/modules/faturacaoeletronicaconfiguracoes/modals/postaddsafecertificado/faturacaoEletronicaConfiguracoes.postAddSafeCertificado.modal.component';
import {FaturacaoPublicaComunicacaoManualModalComponent} from './core/modules/faturacaopublica/modals/comunicacaomanual/faturacaoPublica.comunicacaoManual.modal.component';
import {FaturacaoPublicaConfigModalComponent} from './core/modules/faturacaopublica/modals/config/faturacaoPublica.config.modal.component';
import {FaturacaopublicaConfigNovoestadoModalComponent} from './core/modules/faturacaopublica/modals/novoestado/faturacaoPublica.config.novoEstado.modal.component';
import {FaturacaoPublicaDocComercialModalComponent} from './core/modules/faturacaopublica/modals/doccomercial/faturacaoPublica.docComercial.modal.component';
import {FaturacaoPublicaHistoricoModalComponent} from './core/modules/faturacaopublica/modals/historico/faturacaoPublica.historico.modal.component';
import {FaturacaoPublicaModuleComponent} from './core/modules/faturacaopublica/components/faturacaoPublica.module.component';
import {FaturacaoPublicaObservacoesModalComponent} from './core/modules/faturacaopublica/modals/observacoes/faturacaoPublica.observacoes.modal.component';
import {FctComponent} from './core/modules/fct/components/fct.module.component';
import {FctCredentialsModalComponent} from './core/modules/fct/modals/credentials/fct.credentials.modal.component';
import {FctDadosTodosTrabComFCModalComponent} from './core/modules/fct/modals/dadostodostrabcomfc/fct.dadosTodosTrabComFC.modal.component';
import {FctPedidoReembolsoModalComponent} from './core/modules/fct/modals/pedidoreembolso/fct.pedidoReembolso.modal.component';
import {FctPedidosReembolsoEfetuadosModalComponent} from './core/modules/fct/modals/pedidosreembolsoefetuados/fct.pedidosReembolsoEfetuados.modal.component';
import {FctPromptModalComponent} from './core/modules/fct/modals/prompt/fct.prompt.modal.component';
import {FileViewerComponent} from './core/components/fileviewer/fileviewer.component';
import {FimAnoComponent} from './core/modules/portalcontabilidade/fimano/components/fimAno.module.component';
import {FimPeriodoEncEditComponent} from './core/entities/fimperiodoenc/components/edit/fimPeriodoEnc.entity.edit.component';
import {FimPeriodoEncListComponent} from './core/entities/fimperiodoenc/components/list/fimPeriodoEnc.entity.list.component';
import {FimPeriodoEncModalComponent} from './core/entities/fimperiodoenc/modals/fimPeriodoEnc.modal.component';
import {FluxosComponent} from './core/modules/colaboradores/fluxos/components/fluxos.module.component';
import {FluxosFichaModalComponent} from './core/modules/colaboradores/fluxos/modals/fluxosficha/fluxos.ficha.modal.component';
import {FornecedoresEditComponent} from './core/entities/clifos/components/fornecedores/edit/fornecedores.entity.edit.component';
import {GDocComercialModalComponent} from './core/entities/docscomerciais/modals/gdoccomercial/gdoc.comercial.modal.component';
import {GDocContabilidadeModalComponent} from './core/modules/portalcontabilidade/docscontabilidade/components/gdoccontabilidade/gdoc.contabilidade.modal.component';
import {GDocsComponent} from './core/components/gdocs/gdocs.component';
import {GenerateAbdesModalComponent} from './core/entities/abdes/modals/generateabdes/generateAbdes.modal.component';
import {GenericImporterComponent} from './core/components/genericimporter/generic.importer.component';
import {GenericImporterModalComponent} from './core/components/genericimporter/modal/generic.importer.modal.component';
import {GestaoAvencasModalComponent} from './core/modules/faturacaocontratos/modals/gestaoavencasfaturadas/gestaoAvencas.modal.component';
import {GestaoDGEMPSComponent} from './core/components/gestaodgemps/gestaodgemps.component';
import {GestaoDGEMPSEventHistoryModalComponent} from './core/components/gestaodgemps/modals/eventhistory/gestaodgemps.eventhistory.modal.component';
import {GestaoDGEMPSEventsDetailComponent} from './core/components/gestaodgemps/eventsdetail/gestaodgemps.eventsdetail.component';
import {GestaoDGEMPSHeaderComponent} from './core/components/gestaodgemps/header/gestaodgemps.header.component';
import {GestaoDGEMPSMarcarAbonosModalComponent} from './core/components/gestaodgemps/modals/marcar/abonos/gestaodgemps.marcar.abonos.modal.component';
import {GestaoDGEMPSMarcarFaltasModalComponent} from './core/components/gestaodgemps/modals/marcar/faltas/gestaodgemps.marcar.faltas.modal.component';
import {GestaoDGEMPSMarcarFeriasModalComponent} from './core/components/gestaodgemps/modals/marcar/ferias/gestaodgemps.marcar.ferias.modal.component';
import {GestaoDGEMPSMonthViewComponent} from './core/components/gestaodgemps/views/month/gestaodgemps.month.view.component';
import {GestaoDGEMPSMotivoModalComponent} from './core/components/gestaodgemps/modals/motivo/gestaodgemps.motivo.modal.component';
import {GestaoDGEMPSPrintModalComponent} from './core/components/gestaodgemps/modals/print/gestaodgemps.print.modal.component';
import {GestaoDGEMPSViewDateComponent} from './core/components/gestaodgemps/viewdate/gestaodgemps.viewdate.component';
import {GestaoDGEMPSViewDatePickerComponent} from './core/components/gestaodgemps/viewdate/picker/gestaodgemps.viewdate.picker.component';
import {GestaoDGEMPSYearViewComponent} from './core/components/gestaodgemps/views/year/gestaodgemps.year.view.component';
import {GestaoDocsComerciaisModalComponent} from './core/entities/docscomerciais/modals/gestaodocscomerciais/gestaoDocsComerciais.modal.component';
import {GestaoFicheirosSepaModuleComponent} from './core/modules/gestaoficheirossepa/components/gestaoFicheirosSepa.module.component';
import {GtoAlertsModalComponent} from './core/modules/portalcontabilidade/gto/modals/alerts/gto.alerts.modal.component';
import {GtoConfigModalComponent} from './core/modules/portalcontabilidade/gto/modals/config/gto.config.modal.component';
import {GtoCopyTasksModalComponent} from './core/modules/portalcontabilidade/gto/modals/copytasks/gto.copyTasks.modal.component';
import {GtoCreateEditTaskModalComponent} from './core/modules/portalcontabilidade/gto/modals/createedittask/gto.createEditTask.modal.component';
import {GtoExecuteModalComponent} from './core/modules/portalcontabilidade/gto/modals/execute/gto.execute.modal.component';
import {GtoLicenseModalComponent} from './core/modules/portalcontabilidade/gto/modals/license/gto.license.modal.component';
import {GtoModuleComponent} from './core/modules/portalcontabilidade/gto/components/gto.module.component';
import {GTOMultiEmpresaComponent} from './core/modules/portalcontabilidade/gto/components/multiempresa/gto.multiEmpresa.component';
import {GTOPorEmpresaComponent} from './core/modules/portalcontabilidade/gto/components/porempresa/gto.porEmpresa.component';
import {HomeComunicacoesInternasModalComponent} from './core/modules/colaboradores/home/modals/comunicacaointerna/home.comunicacaoInterna.modal.component';
import {IesComponent} from './core/modules/ies/components/ies.module.component';
import {ImpAvisosCobrancaErrosModalComponent} from './core/modules/portalcontabilidade/avisoscobranca/impavisoscobranca/modal/impAvisosCobranca.erros.modal.component';
import {ImpAvisosCobrancaModuleComponent} from './core/modules/portalcontabilidade/avisoscobranca/impavisoscobranca/components/impAvisosCobranca.module.component';
import {ImpCtrIrsModuleComponent} from './core/modules/portalrh/impctrirs/components/impCtrIrs.module.component';
import {ImpDadosEmpModuleComponent} from './core/modules/portalcontabilidade/impdadosemp/components/impDadosEmp.module.component';
import {ImpDe119ModuleComponent} from './core/modules/portalrh/impde119/components/impDe119.module.component';
import {ImpdocModalComponent} from './core/entities/docscomerciais/modals/impdoc/impDoc.modal.component';
import {ImpencarModuleComponent} from './core/modules/portalrh/impencar/components/impEncar.module.component';
import {ImpFFERPOCModuleComponent} from './core/modules/portalrh/impfferpoc/components/impFFERPOC.module.component';
import {ImportadorEfaturaComponent} from './core/modules/portalcontabilidade/importadorefatura/importadorEfatura.module.component';
import {ImportadorEfaturaLancarDocsEmSerieModalComponent} from './core/modules/portalcontabilidade/importadorefatura/modals/lancardocsemserie/importadorEfatura.lancarDocsEmSerie.modal.component';
import {ImportadorSaftDirectArtigosConfigModalComponent} from './core/modules/portalcontabilidade/importadorsaftdirect/editors/artigosconfig/importadorSaftDirect.artigos.config.modal.component';
import {ImportadorSaftDirectComponent} from './core/modules/portalcontabilidade/importadorsaftdirect/components/importadorSaftDirect.module.component';
import {ImportadorSaftDirectConfigMascaraAnaliticaModalComponent} from './core/modules/portalcontabilidade/importadorsaftdirect/editors/configmascaraanalitica/importadorSaftDirect.configMascaraAnalitica.modal.component';
import {ImportadorSaftDirectCorrespContasAssociarModalComponent} from './core/modules/portalcontabilidade/importadorsaftdirect/editors/correspcontasassociar/importadorSaftDirect.correspContas.associar.modal.component';
import {ImportadorSaftDirectCorrespContasAtribuirModalComponent} from './core/modules/portalcontabilidade/importadorsaftdirect/editors/correspcontasatribuir/importadorSaftDirect.correspContas.atribuir.modal.component';
import {ImportadorSaftDirectCorrespContasModalComponent} from './core/modules/portalcontabilidade/importadorsaftdirect/editors/correspcontas/importadorSaftDirect.correspContas.modal.component';
import {ImportadorSaftDirectDatasDocumentosEditModalComponent} from './core/modules/portalcontabilidade/importadorsaftdirect/editors/datasdocumentos/importadorSaftDirect.datasDocumentos.edit.modal.component';
import {ImportadorSaftDirectFinalizedModalComponent} from './core/modules/portalcontabilidade/importadorsaftdirect/modals/finalized/importadorSaftDirect.finalized.modal.component';
import {ImportadorSaftDirectInProcessModalComponent} from './core/modules/portalcontabilidade/importadorsaftdirect/modals/inprocess/importadorSaftDirect.inProcess.modal.component';
import {ImportadorSaftDirectTaxasIvaModalComponent} from './core/modules/portalcontabilidade/importadorsaftdirect/editors/taxasiva/importadorSaftDirect.taxasIva.modal.component';
import {ImportadorSaftDirectTiposDocsModalComponent} from './core/modules/portalcontabilidade/importadorsaftdirect/editors/tiposdocs/importadorSaftDirect.tiposDocs.modal.component';
import {ImportadorSaftDirectVerContaFormadaModalComponent} from './core/modules/portalcontabilidade/importadorsaftdirect/editors/vercontaformada/importadorSaftDirect.verContaFormada.modal.component';
import {ImportXLSModuleComponent} from './core/modules/importxls/components/importXLS.module.component';
import {ImtabEditComponent} from './core/entities/amortizacoes/components/edit/imtab.entity.edit.component';
import {ImtabEditDataValorModalComponent} from './core/entities/amortizacoes/modals/imtab.edit.dataValor.modal.component';
import {InfoGlobalDiferimentosAcrescimosModuleComponent} from './core/modules/diferimentos/infoglobaldiferimentosacrescimos/components/infoGlobalDiferimentosAcrescimos.module.component';
import {InputEntityComponent} from './core/components/entity/component/input.entity.component';
import {IntegracaoDocumentosModuleComponent} from './core/modules/integracaodocumentos/components/intFac.module.component';
import {IntegracaoSalariosComponent} from './core/modules/colaboradores/integracaosalarios/components/integracaoSalarios.module.component';
import {IntegracaoSalariosConfigModalComponent} from './core/modules/colaboradores/integracaosalarios/modals/config/integracaoSalarios.config.modal.component';
import {IntegracaoSalariosErrorPromptModalComponent} from './core/modules/colaboradores/integracaosalarios/modals/errorprompt/integracaoSalarios.error.prompt.modal.component';
import {IntegracaoSalariosErrorsModalComponent} from './core/modules/colaboradores/integracaosalarios/modals/errors/integracaoSalarios.errors.modal.component';
import {IntegracaoSalariosMultiComponent} from './core/modules/colaboradores/integracaosalariosmulti/components/integracaoSalariosMulti.module.component';
import {IntegracaoSalariosProcessModalComponent} from './core/modules/colaboradores/integracaosalarios/modals/process/integracaoSalarios.process.modal.component';
import {IntegracaoSalariosSimuladorModalComponent} from './core/modules/colaboradores/integracaosalarios/modals/simulador/integracaoSalarios.simulador.modal.component';
import {InventariosImprimirModalComponent} from './core/modules/inventarios/modals/imprimir/inventarios.imprimir.modal.component';
import {InventariosNewModalComponent} from './core/modules/inventarios/modals/new/inventarios.new.modal.component';
import {InventariosStockComponent} from './core/modules/inventarios/components/inventariosStock.module.component';
import {InventariosValorizarModalComponent} from './core/modules/inventarios/modals/valorizar/inventarios.valorizar.modal.component';
import {IvasEditComponent} from './core/entities/ivas/components/edit/ivas.entity.edit.component';
import {KeyBindingsHelperComponent} from './core/components/keybindings/helper/keybindings.helper.component';
import {ListagemdiariosComponent} from './core/modules/portalcontabilidade/estatistica/listagemdiarios/components/listagemDiarios.module.component';
import {ListagemMenusComponent} from './core/modules/colaboradores/refeicoes/listagens/listagemmenus/components/listagemMenus.module.component';
import {ListagemRefeicoesComponent} from './core/modules/colaboradores/refeicoes/listagens/listagemrefeicoes/components/listagemRefeicoes.module.component';
import {LoadModuleStateComponent} from './core/states/loadmodule/loadmodule.state.component';
import {LockedContractStateComponent} from './core/states/account/lockedcontract/lockedcontract.state.component';
import {LogComunicacaoSerieAtModalComponent} from './core/entities/docfasnums/modals/logs/logComunicacaoSerieAt.modal.component';
import {LoginComponent} from './core/states/account/login/login.state.component';
import {LotesEditComponent} from './core/entities/lotes/components/edit/lotes.entity.edit.component';
import {LotesListComponent} from './core/entities/lotes/components/list/lotes.entity.list.component';
import {MaintenanceStateComponent} from './core/states/account/maintenance/maintenance.state.component';
import {MapaExploracaoComponent} from './core/modules/portalcontabilidade/estatistica/mapaexploracao/components/mapaExploracao.module.component';
import {MapaFaltasComponent} from './core/modules/portalclientaccounts/rh/mapafaltas/components/mapaFaltas.module.component';
import {MapaFeriasComponent} from './core/modules/portalclientaccounts/rh/mapaferias/components/mapaFerias.module.component';
import {MapaFundosCompensacaoComponent} from './core/modules/portalclientaccounts/rh/mapafundoscompensacao/components/mapaFundosCompensacao.module.component';
import {MapaPagamentosApagarPromptModalComponent} from './core/modules/portalcontabilidade/mapapagamentos/modals/apagar/mapaPagamentos.apagar.prompt.modal.component';
import {MapaPagamentosDocumentosModalComponent} from './core/modules/portalcontabilidade/mapapagamentos/modals/documentos/mapaPagamentos.documentos.modal.component';
import {MapaPagamentosEnviadosPromptModalComponent} from './core/modules/portalcontabilidade/mapapagamentos/modals/enviados/mapaPagamentos.enviados.prompt.modal.component';
import {MapaPagamentosLoginModalComponent} from './core/modules/portalcontabilidade/mapapagamentos/modals/login/mapaPagamentos.login.modal.component';
import {MapaPagamentosModuleComponent} from './core/modules/portalcontabilidade/mapapagamentos/components/mapaPagamentos.module.component';
import {MapaPagamentosPagamentoDetalhesModalComponent} from './core/modules/portalcontabilidade/mapapagamentos/modals/pagamentodetalhes/mapaPagamentos.pagamento.detalhes.modal.component';
import {MapaPagamentosPagamentoModalComponent} from './core/modules/portalcontabilidade/mapapagamentos/modals/pagamento/mapaPagamentos.pagamento.modal.component';
import {MapaPagamentosProcessModalComponent} from './core/modules/portalcontabilidade/mapapagamentos/modals/process/mapaPagamentos.process.modal.component';
import {MapaPagamentosResumoEnvioModalComponent} from './core/modules/portalcontabilidade/mapapagamentos/modals/resumoenvio/mapaPagamentos.resumoEnvio.modal.component';
import {MapaSeguradoraComponent} from './core/modules/portalclientaccounts/rh/mapaseguradora/components/mapaSeguradora.module.component';
import {MapaSegurancaSocialComponent} from './core/modules/portalclientaccounts/rh/mapaSegurancaSocial/components/mapaSegurancaSocial.module.component';
import {MapaSindicatosComponent} from './core/modules/portalclientaccounts/rh/mapasindicatos/components/mapaSindicatos.module.component';
import {MascarasAnaliticaEditComponent} from './core/entities/mascarasanalitica/components/edit/mascarasAnalitica.entity.edit.component';
import {MascarasAnaliticaEditDefinitionNamesModalComponent} from './core/entities/mascarasanalitica/modals/definitionnames/mascarasAnalitica.edit.definitionNames.modal.component';
import {MBConfigsAddTipoDocModalComponent} from './core/modules/mbconfigs/modals/addtipodoc/mbConfigs.addTipoDoc.modal.component';
import {MBConfigsModuleComponent} from './core/modules/mbconfigs/components/mbConfigs.module.component';
import {MeiosPagamentoEditComponent} from './core/entities/meiospagamento/components/edit/meiosPagamento.entity.edit.component';
import {MeiosPagamentoListComponent} from './core/entities/meiospagamento/components/list/meiosPagamento.entity.list.component';
import {MenuseditComponent} from './core/modules/colaboradores/refeicoes/menus/components/menusEdit.module.component';
import {MillenniumComponent} from './core/states/partners/millennium/millennium.state.component';
import {Modelo10ConfigModalComponent} from './core/modules/portalcontabilidade/modelo10/modal/config/modelo10.config.modal.component';
import {Modelo10ModuleComponent} from './core/modules/portalcontabilidade/modelo10/components/modelo10.module.component';
import {Modelo10RetencaoMod10ModalComponent} from './core/modules/portalcontabilidade/modelo10/modal/retencaomod10/modelo10.retencaoMod10.modal.component';
import {Modelo22AnexoAModalComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/anexoA/modals/modelo22.anexoA.modal.component';
import {Modelo22AnexoDModalComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/anexoD/modals/modelo22.anexoD.modal.component';
import {Modelo22AnexoDQuadro7ModalComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/anexoD/modals/modelo22.anexoD.quadro7.modal.component';
import {Modelo22AnexoDQuadro7Tabelas78ModalComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/anexoD/modals/modelo22.anexoD.quadro7.tabelas78.modal.component';
import {Modelo22AnexoEModalComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/anexoE/modals/modelo22.anexoE.modal.component';
import {Modelo22AnexoFModalComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/anexoF/modals/modelo22.anexoF.modal.component';
import {Modelo22AnexoGModalComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/anexoG/modals/modelo22.anexoG.modal.component';
import {Modelo22AnexosAnexoAComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/anexoA/modelo22.anexos.anexoA.component';
import {Modelo22AnexosAnexoCComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/anexoC/modelo22.anexos.anexoC.component';
import {Modelo22AnexosAnexoDComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/anexoD/modelo22.anexos.anexoD.component';
import {Modelo22AnexosAnexoEComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/anexoE/modelo22.anexos.anexoE.component';
import {Modelo22AnexosAnexoFComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/anexoF/modelo22.anexos.anexoF.component';
import {Modelo22AnexosAnexoGComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/anexoG/modelo22.anexos.anexoG.component';
import {Modelo22AnexosRostoComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/rosto/modelo22.anexos.rosto.component';
import {Modelo22ConfigwsModalComponent} from './core/modules/portalcontabilidade/modelo22/modals/modelo22.configWS.modal.component';
import {Modelo22ContasDefModalComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/rosto/modal/contasdef/modelo22.contasDef.modal.component';
import {Modelo22DerramaModalComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/modal/modelo22.derrama.modal.component';
import {Modelo22ModuleComponent} from './core/modules/portalcontabilidade/modelo22/components/modelo22.module.component';
import {Modelo22PrejuizosFiscaisModalComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/rosto/modal/prejuizosfiscais/modelo22.prejuizosFiscais.modal.component';
import {Modelo22RostoModalComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/rosto/modal/modelo22.rosto.modal.component';
import {Modelo22RostoQuadro14ModalComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/rosto/modal/quadro14/modelo22.rosto.quadro14.modal.component';
import {Modelo25ConfigModalComponent} from './core/modules/portalcontabilidade/modelo25/modal/config/modelo25.config.modal.component';
import {Modelo25ModuleComponent} from './core/modules/portalcontabilidade/modelo25/components/modelo25.module.component';
import {Modelo39ModuleComponent} from './core/modules/portalcontabilidade/modelo39/components/modelo39.module.component';
import {ModeloConfigwsModalComponent} from './core/modules/portalcontabilidade/modeloconfigwsmodal/modals/modelo.configWS.modal.component';
import {ModuleMaintenanceModalComponent} from './core/components/entity/maintenance/modal/module/module.maintenance.modal.component';
import {ModulesModuleComponent} from './core/modules/modules/components/modules.module.component';
import {ModuloComponent} from './core/components/module/module.component';
import {ModuloDetailComponent} from './core/components/module/module.detail.component';
import {ModuloEntityDetailComponent} from './core/components/module/entitydetail/module.entitydetail.component';
import {ModuloEntityListComponent} from './core/components/module/entitylist/module.entitylist.component';
import {MoedaEditCambioModalComponent} from './core/modules/portalcontabilidade/manutencao/moeda/modals/cambio/moeda.edit.cambio.modal.component';
import {MoedaEditComponent} from './core/modules/portalcontabilidade/manutencao/moeda/components/moeda.edit.component';
import {MoralsEditComponent} from './core/entities/morals/component/edit/morals.entity.edit.component';
import {MotivoAnulacaoModalComponent} from './core/entities/docscomerciais/modals/motivoanulacao/motivoAnulacao.modal.component';
import {MovimentosAbertoComponent} from './core/modules/portalclientaccounts/contabilidade/movimentosaberto/components/movimentosAberto.module.component';
import {MovimentosPendentesClientesComponent} from './core/components/erp/movimentospendentes/clientes/movimentos.pendentes.clientes.component';
import {MovimentosPendentesClientesModuleComponent} from './core/modules/movimentospendentes/clientes/components/movimentos.pendentes.clientes.module.component';
import {MovimentosPendentesComponent} from './core/components/erp/movimentospendentes/movimentos.pendentes.component';
import {MovimentosPendentesFornecedoresComponent} from './core/components/erp/movimentospendentes/fornecedores/movimentos.pendentes.fornecedores.component';
import {MovimentosPendentesFornecedoresModuleComponent} from './core/modules/movimentospendentes/fornecedores/components/movimentos.pendentes.fornecedores.module.component';
import {MovimentosPendentesOutrosDevedoresCredoresComponent} from './core/components/erp/movimentospendentes/outrosdevedorescredores/movimentos.pendentes.outrosdevedorescredores.component';
import {MovimentosPendentesOutrosDevedoresCredoresModuleComponent} from './core/modules/movimentospendentes/outrosdevedorescredores/components/movimentos.pendentes.outrosDevedoresCredores.module.component';
import {MudaPasswordModalComponent} from './core/modules/utilizadores/modals/mudapassword/mudapassword.modal.component';
import {NewPreviTesModalComponent} from './core/modules/tesouraria/prevites/previtesmanual/modals/newprevites/newpreviTes.modal.component';
import {NifsComponent} from './core/modules/components/nifs/nifs.component';
import {NifsEditComponent} from './core/entities/nifs/components/edit/nifs.entity.edit.component';
import {NifsListComponent} from './core/entities/nifs/components/list/nifs.entity.list.component';
import {NoAuthorityComponent} from './core/states/account/noauthority/noauthority.state.component';
import {NotificationCenterComponent} from './core/components/notificationcenter/notificationcenter.component';
import {NotificationCenterModuleComponent} from './core/modules/notificationcenter/components/notificationCenter.module.component';
import {NotificationCenterNoNotificationsComponent} from './core/components/notificationcenter/nonotifications/notificationcenter.nonotifications.component';
import {NotificationItemComponent} from './core/components/notificationcenter/item/notificationcenter.item.component';
import {OfficeReportingModuleComponent} from './core/modules/portalcontabilidade/officereporting/components/officeReporting.module.component';
import {OutrosDevedoresCredoresEditComponent} from './core/entities/clifos/components/outrosdevedorescredores/edit/outrosDevedoresCredores.entity.edit.component';
import {PagamentoComponent} from './core/entities/pagamentos/components/pagamento/pagamento.component';
import {PagamentoImprimirModalComponent} from './core/entities/pagamentos/modals/imprimirmodal/pagamento.imprimir.modal.component';
import {PagamentosDistribuiValorModalComponent} from './core/entities/pagamentos/modals/distribuivalormodal/pagamentos.distribuiValor.modal.component';
import {PagamentosEditComponent} from './core/entities/pagamentos/components/edit/pagamentos.entity.edit.component';
import {PagamentoSepaAnularModalComponent} from './core/entities/pagamentos/modals/sepaanularmodal/pagamento.sepaAnular.modal.component';
import {PagamentosListComponent} from './core/entities/pagamentos/components/list/pagamentos.entity.list.component';
import {PagamentosSaveModalComponent} from './core/entities/pagamentos/modals/savemodal/pagamentos.save.modal.component';
import {PcaBalancetesModuleComponent} from './core/modules/portalclientaccounts/contabilidade/balancetes/components/pca.balancetes.module.component';
import {PCAContabilidadeExtratosComponent} from './core/modules/portalclientaccounts/contabilidade/extratos/components/extratos.module.component';
import {PCADashboardsComponent} from './core/modules/portalclientaccounts/dashboards/components/dashBoards.module.component';
import {PcaIdadeSaldosComponent} from './core/modules/portalclientaccounts/contabilidade/idadesaldos/components/idadeSaldos.module.component';
import {PCAMovimentosAbertoComponent} from './core/modules/portalclientaccounts/myaccount/movimentosaberto/components/movimentosAberto.module.component';
import {PcaRhGestaoDGEMPSAbonosMarcarModalComponent} from './core/modules/portalclientaccounts/rh/gestaodgemps/modals/marcar/abonos/pca.rh.gestaoDGEMPS.abonos.marcar.modal.component';
import {PcaRhGestaoDGEMPSFaltasMarcarModalComponent} from './core/modules/portalclientaccounts/rh/gestaodgemps/modals/marcar/faltas/pca.rh.gestaoDGEMPS.faltas.marcar.modal.component';
import {PcaRhGestaoDGEMPSFeriasMarcarModalComponent} from './core/modules/portalclientaccounts/rh/gestaodgemps/modals/marcar/ferias/pca.rh.gestaoDGEMPS.ferias.marcar.modal.component';
import {PcaRhGestaoDGEMPSModuleComponent} from './core/modules/portalclientaccounts/rh/gestaodgemps/components/pca.rh.gestaoDGEMPS.module.component';
import {PerfilCategoriaAbonoEditComponent} from './core/modules/perfilcategoriaabdes/perfisabonos/components/perfilCategoriaAbono.edit.component';
import {PerfilCategoriaDescontoEditComponent} from './core/modules/perfilcategoriaabdes/perfisdescontos/components/perfilCategoriaDesconto.edit.component';
import {POCSCopyModuleComponent} from './core/modules/pocscopy/components/pocsCopy.module.component';
import {PocsEditComponent} from './core/entities/pocs/component/edit/pocs.entity.edit.component';
import {PocsListComponent} from './core/entities/pocs/component/list/pocs.entity.list.component';
import {PortalAboutModalComponent} from './core/components/portal/modals/about/portal.about.modal.component';
import {PortalChangeFirmModalComponent} from './core/components/portal/modals/changefirm/portal.changefirm.modal.component';
import {PortalComponent} from './core/components/portal/portal.component';
import {PortalEntityEditComponent} from './core/entities/portal/components/edit/portal.entity.edit.component';
import {PortalEntityListComponent} from './core/entities/portal/components/list/portal.entity.list.component';
import {PortalsSortModalComponent} from './core/entities/portal/modals/sort/portals.sort.modal.component';
import {PortalUITreeComponent} from './core/components/uitree/portal.uitree.component';
import {PortalUITreeEditNodeModalComponent} from './core/components/uitree/editnodemodal/portal.uitree.editnode.modal.component';
import {PredefinidoContabCabComponent} from './core/modules/portalcontabilidade/manutencao/predefinidos/components/cabecalho/predefinidocontabcab.component';
import {PredefinidoContabFormulaComponent} from './core/modules/portalcontabilidade/manutencao/predefinidos/components/formula/predefinidocontabformula.component';
import {PredefinidoContabFormulaDialogModalComponent} from './core/modules/portalcontabilidade/manutencao/predefinidos/components/formula/dialogmodal/predefinidocontabformula.dialog.modal.component';
import {PredefinidoContabLinhasComponent} from './core/modules/portalcontabilidade/manutencao/predefinidos/components/linhas/predefinidocontablinhas.component';
import {PreviTesAutoCodRubricaModalComponent} from './core/modules/tesouraria/prevites/previtesauto/modals/codrubrica/previTesAuto.codRubrica.modal.component';
import {PreviTesAutoDataTesModalComponent} from './core/modules/tesouraria/prevites/previtesauto/modals/datates/previTesAuto.dataTes.modal.component';
import {PreviTesAutoModuleComponent} from './core/modules/tesouraria/prevites/previtesauto/components/previTesAuto.module.component';
import {PreviTesModuleComponent} from './core/modules/tesouraria/prevites/previtesmanual/components/previTes.module.component';
import {PrevTesObrasModuleComponent} from './core/modules/tesouraria/prevtesobra/components/prevTesObras.module.component';
import {PrevTesObrasRubricaModalComponent} from './core/modules/tesouraria/prevtesobra/modals/rubrica/prevTesObras.rubrica.modal.component';
import {ProceDMRATComponent} from './core/modules/colaboradores/procedmrat/components/proceDMRAT.module.component';
import {ProceDMRATPrintModalComponent} from './core/modules/colaboradores/procedmrat/modals/print/proceDMRAT.print.modal.component';
import {ProceDMRATViewInfoComponent} from './core/modules/colaboradores/procedmrat/modals/viewinfo/proceDMRAT.viewInfo.modal.component';
import {ProceSalariosComponent} from './core/modules/colaboradores/procesalarios/components/proceSalarios.module.component';
import {ProceSalariosEmpregadosModalComponent} from './core/modules/colaboradores/procesalarios/modals/empregados/proceSalarios.empregados.modal.component';
import {ProceSegSocialComponent} from './core/modules/colaboradores/procesegsocial/components/proceSegSocial.module.component';
import {ProceSegSocialPrintModalComponent} from './core/modules/colaboradores/procesegsocial/modals/print/proceSegSocial.print.modal.component';
import {ProceSegSocialViewInfoComponent} from './core/modules/colaboradores/procesegsocial/modals/viewinfo/proceSegSocial.viewInfo.modal.component';
import {ProcSeguradoraFolhaFeriasInfoModalComponent} from './core/modules/colaboradores/procseguradorafolhaferias/modals/info/procSeguradoraFolhaFerias.info.modal.component';
import {ProcSeguradoraFolhaFeriasModuleComponent} from './core/modules/colaboradores/procseguradorafolhaferias/components/procSeguradoraFolhaFerias.module.component';
import {ProcSeguradoraFolhaFeriasPrintModalComponent} from './core/modules/colaboradores/procseguradorafolhaferias/modals/print/procSeguradoraFolhaFerias.print.modal.component';
import {ProtecaoPasswordModalComponent} from './core/modules/portalclientaccounts/rh/recibosVencimentos/modals/protecaopassword/protecaoPassword.modal.component';
import {RecaptchaBrandingComponent} from './core/components/recaptcha/branding/recaptcha.branding.component';
import {RecaptchaV2ModalComponent} from './core/services/recaptcha/v2modal/recaptcha.v2.modal.component';
import {ReciboComponent} from './core/entities/recibos/components/recibo/recibo.component';
import {ReciboImprimirModalComponent} from './core/entities/recibos/modals/imprimirmodal/recibo.imprimir.modal.component';
import {RecibosDistribuivalorModalComponent} from './core/entities/recibos/modals/distribuirvalormodal/recibos.distribuiValor.modal.component';
import {RecibosEditComponent} from './core/entities/recibos/components/edit/recibos.entity.edit.component';
import {RecibosListComponent} from './core/entities/recibos/components/list/recibos.entity.list.component';
import {RecibosSaveModalComponent} from './core/entities/recibos/modals/savemodal/recibos.save.modal.component';
import {RecibosVencimentoComponent} from './core/modules/colaboradores/recibosvencimento/components/recibosVencimento.module.component';
import {RecibosVencimentosEmailModalComponent} from './core/modules/portalclientaccounts/rh/recibosVencimentos/modals/email/recibosVencimentos.email.modal.component';
import {RecibosVencimentosModuleComponent} from './core/modules/portalclientaccounts/rh/recibosVencimentos/components/recibosVencimentos.module.component';
import {ReconstrucaoAcumuladosComponent} from './core/modules/portalcontabilidade/manutencao/reconstrucaoacumulados/components/reconstrucaoAcumulados.module.component';
import {ReembolsosIvaDetailComponent} from './core/entities/reembolsosiva/components/detail/reembolsosIva.entity.detail.component';
import {ReembolsosivaNewComponent} from './core/entities/reembolsosiva/components/new/reembolsosIva.entity.new.component';
import {RefeicoesSchedulerComponent} from './core/components/refeicoes/scheduler/refeicoes.scheduler.component';
import {RegistarNifModalComponent} from './core/entities/nifs/modals/registanif/registarNIF.modal.component';
import {ReparticoesCCustoComponent} from './core/modules/components/reparticoesccusto/reparticoesCCusto.component';
import {ReparticoesCCustoEditComponent} from './core/entities/reparticoesccusto/components/edit/reparticoesCCusto.entity.edit.component';
import {ReportsInputComponent} from './core/components/reports/input/reports.input.component';
import {ResetPasswordComponent} from './core/states/account/resetpassword/resetpassword.state.component';
import {ResumoIvaModuleComponent} from './core/modules/listagens/resumoiva/components/resumoIva.module.component';
import {RetencoesModuleComponent} from './core/modules/portalcontabilidade/retencoes/components/retencoes.module.component';
import {RHAtualizaFeriasEmpDataCadastroModalComponent} from './core/modules/portalrh/rhatualizaferiasemp/modals/rhAtualizaFeriasEmp.dataCadastro.modal.component';
import {RHAtualizaFeriasEmpModuleComponent} from './core/modules/portalrh/rhatualizaferiasemp/components/rhAtualizaFeriasEmp.module.component';
import {RhCalculoRendimentoLiquidoModuleComponent} from './core/modules/portalrh/rhcalculorendimentoliquido/components/rhCalculoRendimentoLiquido.module.component';
import {RhCessaContratoTrabalhoModuleComponent} from './core/modules/portalrh/rhcessacontratotrabalho/components/rhCessaContratoTrabalho.module.component';
import {RHDadosConfigFichaComponent} from './core/modules/rhconfiguracoes/rhdadosconfigficha/components/rhdadosconfigficha/rhDadosConfigFicha.component';
import {RHDadosConfigFichaModuleComponent} from './core/modules/rhconfiguracoes/rhdadosconfigficha/components/rhDadosConfigFicha.module.component';
import {RHFolFerModuleComponent} from './core/modules/portalrh/rhfolfer/components/rhFolfer.module.component';
import {RHGestaoFicheirosDMRATModalComponent} from './core/modules/portalrh/rhgestaoficheirosdmrat/modals/rhGestaoFicheirosDMRAT.modal.component';
import {RHGestaoFicheirosDMRATModuleComponent} from './core/modules/portalrh/rhgestaoficheirosdmrat/components/rhGestaoFicheirosDMRAT.module.component';
import {RHGozoFeriasModuleComponent} from './core/modules/rhconfiguracoes/rhgozoferias/components/rhGozoFerias.module.component';
import {RhImpVenctModuleComponent} from './core/modules/portalrh/rhimpvenct/components/rhImpVenct.module.component';
import {RHIntegracaoSalariosModuleComponent} from './core/modules/rhconfiguracoes/rhintegracaosalarios/components/rhIntegracaoSalarios.module.component';
import {RHManutencaoCamposModuleComponent} from './core/modules/portalrh/rhmanutencaocampos/components/rhManutencaoCampos.module.component';
import {RhManutencaoModuleComponent} from './core/modules/rhconfiguracoes/rhmanutencao/components/rhManutencao.module.component';
import {RhMapasSindicatosModuleComponent} from './core/modules/portalrh/mapassindicatos/components/rhMapasSindicatos.module.component';
import {RhPenhorasEditComponent} from './core/entities/rhpenhoras/components/edit/rhPenhoras.entity.edit.component';
import {RhPenhorasListComponent} from './core/entities/rhpenhoras/components/list/rhPenhoras.entity.list.component';
import {RHProcessamentoSalariosModuleComponent} from './core/modules/rhconfiguracoes/rhprocessamentosalarios/components/rhProcessamentoSalarios.module.component';
import {RHProcSegSocialConsultEnviosModuleComponent} from './core/modules/portalrh/rhprocsegsocialconsultenvios/components/rhProcCegCocialConsultEnvios.module.component';
import {RHQPIRCTImportedListModalComponent} from './core/modules/portalrh/rhqpirct/modals/importedlist/rhQPIRCT.importedList.modal.component';
import {RHQPIRCTImportFileModalComponent} from './core/modules/portalrh/rhqpirct/modals/importfile/rhQPIRCT.importFile.modal.component';
import {RHQPIRCTModuleComponent} from './core/modules/portalrh/rhqpirct/components/rhQPIRCT.module.component';
import {RHRecolhaDadosEditComponent} from './core/entities/rhrecolhadados/components/edit/rhRecolhaDados.entity.edit.component';
import {RHRecolhaDadosListComponent} from './core/entities/rhrecolhadados/components/list/rhRecolhaDados.entity.list.component';
import {RHRecolhaSerieSubsidiosModuleComponent} from './core/modules/portalrh/rhrecolhaseriesubsidios/components/rhRecolhaSerieSubsidios.module.component';
import {RHRecolhaSerieSubsidiosSubsSavedModalComponent} from './core/modules/portalrh/rhrecolhaseriesubsidios/modals/subssaved/rhRecolhaSerieSubsidios.subsSaved.modal.component';
import {RHRegistarEmpregadosSSConfigWSModalComponent} from './core/modules/portalrh/rhregistarempregadosss/modals/configws/rhRegistarEmpregadosSS.configWS.modal.component';
import {RHRegistarEmpregadosSSEditarContratoModalComponent} from './core/modules/portalrh/rhregistarempregadosss/modals/editarcontrato/rhRegistarEmpregadosSS.editarContrato.modal.component';
import {RHRegistarEmpregadosSSModuleComponent} from './core/modules/portalrh/rhregistarempregadosss/components/rhRegistarEmpregadosSS.module.component';
import {RHRelatorioUnicoComponent} from './core/modules/portalrh/rhrelatoriounico/components/rhRelatorioUnico.module.component';
import {RHRelatorioUnicoConfigModalComponent} from './core/modules/portalrh/rhrelatoriounico/modals/config/rhRelatorioUnico.config.modal.component';
import {RHSalToTrfDadosTransfModalComponent} from './core/modules/portalrh/rhsaltotrf/modals/dadostransf/rhSalToTrf.dadosTransf.modal.component';
import {RHSalToTrfModuleComponent} from './core/modules/portalrh/rhsaltotrf/components/rhSalToTrf.module.component';
import {RhSegSocialFolhaFeriasModuleComponent} from './core/modules/rhconfiguracoes/rhsegsocialfolhaferias/components/rhSegSocialFolhaFerias.module.component';
import {RhTiposProcessamentoEditComponent} from './core/entities/rhTiposProcessamento/components/edit/rhTiposProcessamento.entity.edit.component';
import {RHTransferenciasBancariasModuleComponent} from './core/modules/rhconfiguracoes/rhtransferenciasbancarias/components/rhTransferenciasBancarias.module.component';
import {SaftComponent} from './core/modules/saft/components/saft.module.component';
import {SaftContabConvertContasModalComponent} from './core/modules/saftcontab/modals/convertcontas/saftContab.convertContas.modal.component';
import {SaftContabImportConfigsModalComponent} from './core/modules/saftcontab/modals/importconfigs/saftContab.importConfigs.modal.component';
import {SaftContabModuleComponent} from './core/modules/saftcontab/components/saftContab.module.component';
import {SaldosContasInventarioModalComponent} from './core/modules/portalcontabilidade/manutencao/apuramentoresultados/modals/saldoscontasinventario/saldosContasInventario.modal.component';
import {SegurEditComponent} from './core/entities/segur/components/edit/segur.entity.edit.component';
import {SelecaoloteslinhaModalComponent} from './core/entities/docscomerciais/modals/selecaolotes/linha/selecaoLotes.linha.modal.component';
import {SeriesPorComunicarATModalComponent} from './core/entities/docfasnums/modals/comunicacao/seriesporcomunicarat/seriesPorComunicarAt.modal.component';
import {ServicosComponent} from './core/modules/colaboradores/servicos/components/servicos.module.component';
import {SimpleLoginModalComponent} from './core/modules/simplelogin/modals/simpleLogin.modal.component';
import {SimulaValorVencimentoComponent} from './core/modules/colaboradores/simulavalorvencimento/components/simulaValorVencimento.module.component';
import {SOLICEntityEditComponent} from './core/entities/solic/components/edit/solic.entity.edit.component';
import {SOLICEntityListComponent} from './core/entities/solic/components/list/solic.entity.list.component';
import {StoreWelcomeModalComponent} from './core/modules/empresas/modals/storewelcome/storeWelcome.modal.component';
import {SubRefeicaoModalComponent} from './core/entities/rhrecolhadados/modals/subRefeicao.modal.component';
import {TaxasIvaComponent} from './core/modules/components/taxasiva/taxasIva.component';
import {TaxasIvaEditComponent} from './core/entities/taxasIva/components/edit/taxasIva.entity.edit.component';
import {TaxasRetencaoEditComponent} from './core/entities/taxasretencao/components/edit/taxasRetencao.entity.edit.component';
import {TaxonomiasModuleComponent} from './core/modules/taxonomias/components/taxonomias.module.component';
import {TemplatesDetailComponent} from './core/entities/templates/components/detail/templates.entity.detail.component';
import {TerceirosComponent} from './core/modules/terceiros/components/terceiros.module.component';
import {TiposRegistoCadastroEditComponent} from './core/entities/tiposregistocadastro/components/edit/tiposRegistoCadastro.entity.edit.component';
import {TIRSEditComponent} from './core/entities/tirs/components/edit/tirs.entity.edit.component';
import {TransferenciaBancariaModalComponent} from './core/modules/rhconfiguracoes/rhtransferenciasbancarias/modals/transferenciaBancaria.modal.component';
import {TransferenciasArmazensConfigModalComponent} from './core/entities/transferenciasarmazens/modals/config/transferenciasArmazens.config.modal.component';
import {TransferenciasArmazensDetailComponent} from './core/entities/transferenciasarmazens/components/detail/transferenciasArmazens.entity.detail.component';
import {TransferenciasArmazensListComponent} from './core/entities/transferenciasarmazens/components/list/transferenciasArmazens.entity.list.component';
import {TransferenciasArmazensModuleComponent} from './core/entities/transferenciasarmazens/components/transferenciasArmazens.entity.component';
import {TransferenciasArmazensNewLineModalComponent} from './core/entities/transferenciasarmazens/modals/newline/transferenciasArmazens.newLine.modal.component';
import {TransferenciasArmazensProcessarModalComponent} from './core/entities/transferenciasarmazens/modals/processartrfarm/transferenciasArmazens.processar.modal.component';
import {TrdocModalComponent} from './core/entities/docscomerciais/modals/trdoc/trdoc.modal.component';
import {TreeViewerComponent} from './core/components/treeviewer/treeviewer.component';
import {TreeViewerNodeComponent} from './core/components/treeviewer/treeviewer.node.component';
import {TrfBaConfigModalComponent} from './core/modules/trfba/modal/config/trfBa.config.modal.component';
import {TrfBaGeraFicheiroModalComponent} from './core/modules/trfba/modal/geraficheiro/trfBa.geraFicheiro.modal.component';
import {TrfBaModuleComponent} from './core/modules/trfba/components/trfBa.module.component';
import {TrfLancContasModuleComponent} from './core/modules/trflanccontas/components/trfLancContas.module.component';
import {UIRouterViewIdentityComponent} from './core/components/uirouter/uirouter.view.identity.component';
import {UploadLogoModalComponent} from './core/modules/empresas/modals/uploadlogo/uploadLogo.modal.component';
import {UtilizadoresBasicEditComponent} from './core/modules/utilizadores/basic/edit/utilizadores.basic.edit.component';
import {UtilizadoresBasicListComponent} from './core/modules/utilizadores/basic/list/utilizadores.basic.list.component';
import {UtilizadoresInstallationComponent} from './core/modules/utilizadores/installations/utilizadores.installation.component';
import {UtilizadoresInstallationsComponent} from './core/modules/utilizadores/installations/utilizadores.installations.component';
import {UtlViatPatronalModalComponent} from './core/modules/utlviatpatronal/modals/utlViatPatronal.modal.component';
import {UtlViatPatronalModuleComponent} from './core/modules/utlviatpatronal/components/utlViatPatronal.module.component';
import {VendasAnoMesComponent} from './core/modules/listagens/components/vendasAnoMes.component';
import {VendasAnoMesModuleComponent} from './core/modules/listagens/vendasanomes/vendasAnoMes.module.component';
import {VendasArtigoModuleComponent} from './core/modules/listagens/vendasartigo/components/vendasArtigo.module.component';
import {VendasClienteModuleComponent} from './core/modules/listagens/vendascliente/components/vendasCliente.module.component';
import {VerificacaoAcumuladosComponent} from './core/modules/portalcontabilidade/manutencao/verificacaoacumulados/components/verificacaoAcumulados.module.component';
import {ConciliacaoEFaturasModuleComponent} from './core/modules/portalcontabilidade/conciliacaoEFaturas/conciliacaoEFaturas.module.component';
import {ConciliacaoEFaturasModuleConfigModalComponent} from './core/modules/portalcontabilidade/conciliacaoEFaturas/modal/config/conciliacaoEFaturas.module.config.modal';
import {ConciliacaoEFaturasModuleExtratosModalComponent} from './core/modules/portalcontabilidade/conciliacaoEFaturas/modal/extratos/conciliacaoEFaturas.module.extratos.modal';
import {ConciliacaoEFaturasModulePesquisaDocPossivelModalComponent} from './core/modules/portalcontabilidade/conciliacaoEFaturas/modal/pesquisadocpossivel/conciliacaoEFaturas.module.pesquisaDocPossivel.modal';
import {ConciliacaoEFaturasModuleViewDocModalComponent} from './core/modules/portalcontabilidade/conciliacaoEFaturas/modal/viewdoc/conciliacaoEFaturas.module.viewDoc.modal';
import {ExportacaoComprasModuleComponent} from './core/modules/integracoes/geofolia/exportacaocompras/components/exportacaoCompras.module.component';
import {ExportacaoComprasConfigModalComponent} from './core/modules/integracoes/geofolia/exportacaocompras/modal/config/exportacaoCompras.config.modal.component';
import {EncomendasProcessamentoModalComponent} from './core/components/docscomerciais/encomendas/modals/processamento/encomendas.processamento.modal.component';
import {EncomendasModuleComponent} from './core/modules/encomendas/components/encomendas.module.component';
import {EncomendasDocumentoModalComponent} from './core/components/docscomerciais/encomendas/modals/documento/encomendas.documento.modal.component';
import {EncomendasImpressaoModalComponent} from './core/components/docscomerciais/encomendas/modals/impressao/encomendas.impressao.modal.component';
import {EncomendasSimulacaoModalComponent} from './core/components/docscomerciais/encomendas/modals/simulacao/encomendas.simulacao.modal.component';
import {EncomendasSatisfazerClifoModalComponent} from './core/components/docscomerciais/encomendas/modals/satisfazerclifo/encomendas.satisfazerclifo.modal.component';
import {EncomendasComponent} from './core/components/docscomerciais/encomendas/encomendas.component';
import {EncomendasLotesModalComponent} from './core/components/docscomerciais/encomendas/modals/lotes/encomendas.lotes.modal.component';
import {PlanosContasAlternativosEditComponent} from './core/entities/planoscontasalternativos/components/edit/planosContasAlternativos.entity.edit.component';
import {PlanosContasAlternativosContaModalComponent} from './core/entities/planoscontasalternativos/modals/contas/planosContasAlternativos.conta.modal.component';
import {PlanosContasAlternativosImportModalComponent} from './core/entities/planoscontasalternativos/modals/importplanoalt/planosContasAlternativos.import.modal.component';
import {PlanosContasAlternativosCuboModalComponent} from './core/entities/planoscontasalternativos/modals/cubo/planosContasAlternativos.cubo.modal.component';
import {PlanosContasAlternativosExtratoModalComponent} from './core/entities/planoscontasalternativos/modals/extrato/planosContasAlternativos.extrato.modal.component';
import {Modelo30ModuleComponent} from './core/modules/portalcontabilidade/modelo30/components/modelo30.module.component';
import {Modelo30VerificarLancamentosModalComponent} from './core/modules/portalcontabilidade/modelo30/modals/verificarlancamentos/modelo30.verificarLancamentos.modal.component';
import {MapaPagamentosConfigModalComponent} from './core/modules/portalcontabilidade/mapapagamentos/modals/config/mapaPagamentos.config.modal.component';
import {AcrescimosEncargosFeriasModuleComponent} from './core/modules/acrescimosencargoscomferias/components/acrescimosEncargosFerias.module.component';
import {AcrescimosEncargosFeriasConfigModalComponent} from './core/modules/acrescimosencargoscomferias/modals/config/acrescimosEncargosFerias.config.modal.component';
import {AcrescimosEncargosFeriasResumoanualModalComponent} from './core/modules/acrescimosencargoscomferias/modals/resumoanual/acrescimosEncargosFerias.resumoanual.modal.component';
import {AcrescimosEncargosFeriasGerarEncargosMultiModalComponent} from './core/modules/acrescimosencargoscomferias/modals/gerarencargosmulti/acrescimosEncargosFerias.gerarEncargosMulti.modal.component';
import {AcrescimosEncargosFeriasSingleComponent} from './core/modules/acrescimosencargoscomferias/components/single/acrescimosEncargosFerias.single.component';
import {AcrescimosEncargosFeriasMultiempresadetailModalComponent} from './core/modules/acrescimosencargoscomferias/modals/multiempresadetail/acrescimosEncargosFerias.multiempresadetail.modal.component';
import {RendisEditComponent} from './core/entities/rendis/component/edit/rendis.entity.edit.component';
import {AcrescimosEncargosFeriasPrintModalComponent} from './core/modules/acrescimosencargoscomferias/modals/print/acrescimosEncargosFerias.print.modal.component';
import {AvisosCobrancaAlterarEmailPontualModalComponent} from './core/modules/portalcontabilidade/avisoscobranca/avisoscobrancagrid/modals/alteraremailpontual/avisosCobranca.alterarEmailPontual.modal.component';
import {DocumentosFiscaisProgressModalComponent} from './core/modules/portalcontabilidade/manutencao/documentosFiscais/modals/progresscontabdigital/documentosFiscais.progress.modal.component';
import {ProceSalariosProgressModalComponent} from './core/modules/colaboradores/procesalarios/modals/progress/proceSalarios.progress.modal.component';
import {AcrescimosEncargosFeriasIntegContabMultiErrorsModalComponent} from './core/modules/acrescimosencargoscomferias/modals/integcontabmultierrors/acrescimosEncargosFerias.integContabMultiErrors.modal.component';
import {DgempsfullSimulaTaxaFixaIrsModalComponent} from './core/modules/portalrh/dgempsfull/modals/simulartaxafixairs/dgempsFull.simulaTaxaFixaIrs.modal.component';
import {DashboardFinanceiroModuleComponent} from './core/modules/dashboardsfinanceiro/components/dashboardFinanceiro.module.component';
import {AdiantamentosModuleComponent} from './core/modules/adiantamentos/components/adiantamentos.module.component';
import {AdiantamentosAutoConfigModalComponent} from './core/modules/adiantamentos/modals/autoconfig/adiantamentos.autoConfig.modal.component';
import {AdiantamentosConfigsModalComponent} from './core/modules/adiantamentos/modals/configs/adiantamentos.configs.modal.component';
import {AdiantamentosRegistarModalComponent} from './core/modules/adiantamentos/modals/registar/adiantamentos.registar.modal.component';
import {RHCopiaAbonosDescontosModuleComponent} from './core/modules/portalrh/rhcopiaabonosdescontos/components/rhCopiaAbonosDescontos.module.component';
import {RHCopiaAbonosDescontosInformacaoConfirmacaoModalComponent} from './core/modules/portalrh/rhcopiaabonosdescontos/modals/informacaoconfirmacao/rhCopiaAbonosDescontos.informacaoConfirmacao.modal.component';
import {DocumentoArtigosAdvancedSearchModalComponent} from './core/entities/docscomerciais/modals/advancedsearch/documento.artigosAdvancedSearch.modal.component';
import {DocsDigitaisExportarFolderModalComponent} from './core/modules/contabilidadedigital/documentosdigitais/modals/exportarfolder/docsDigitais.exportarFolder.modal.component';
import {AbDesListComponent} from './core/entities/abdes/components/list/abdes.entity.list.component';
import {AdiantamentosPrintModalComponent} from './core/modules/adiantamentos/modals/print/adiantamentos.print.modal.component';
import {TrDocsLinhasModalComponent} from './core/components/docscomerciais/trDocs/modals/linhas/trdocs.linhas.modal.component';
import {ProceSalariosProcErrorsModalComponent} from './core/modules/colaboradores/procesalarios/modals/procerrors/proceSalarios.procErrors.modal.component';
import {RptConfisModuleComponent} from './core/modules/rptconfigs/components/rptConfigs.module.component';
import {CargVeicEditComponent} from './core/entities/cargveic/component/cargVeic.entity.edit.component';
import {CargVeicReboqueModalComponent} from './core/entities/cargveic/modals/cargVeic.reboque.modal.component';
import {MovimentosAbertoModalComponent} from './core/modules/portalclientaccounts/contabilidade/movimentosaberto/modals/movimentosAberto.modal.component';
import {ExtratosDTModalComponent} from './core/modules/portalcontabilidade/estatistica/extratosdt/modals/extratosDT.modal.component';
import {TxtFaturacaoModalComponent} from './core/entities/clifos/modals/txtfaturacao/txtFaturacao.modal.component';
import {DebDiretosSEPAModuleComponent} from './core/modules/debDiretosSEPA/components/debDiretosSEPA.module.component';
import {DebDiretosSEPAConfiguracaoModalComponent} from './core/modules/debDiretosSEPA/modals/debDiretosSEPA.configuracao.modal.component';
import {DebDiretosSEPAContaCredoraModalComponent} from './core/modules/debDiretosSEPA/modals/debDiretosSEPA.contaCredora.modal.component';
import {DebDiretosSEPAClienteModalComponent} from './core/modules/debDiretosSEPA/modals/debDiretosSEPA.cliente.modal.component';
import {DebDiretosSEPAClientesModalComponent} from './core/modules/debDiretosSEPA/modals/debDiretosSEPA.clientes.modal.component';
import {TIRSListComponent} from './core/entities/tirs/components/list/tirs.entity.list.component';
import {DebDiretosSEPALogModalComponent} from './core/modules/debDiretosSEPA/modals/debDiretosSEPA.log.modal.component';
import {DebDiretosSEPAEmissaoModalComponent} from './core/modules/debDiretosSEPA/modals/debDiretosSEPA.emissao.modal.component';
import {DebDiretosSEPAImpressaoModalComponent} from './core/modules/debDiretosSEPA/modals/debDiretosSEPA.impressao.modal.component';
import {DebDiretosSEPAImpressaoClienteModalComponent} from './core/modules/debDiretosSEPA/modals/debDiretosSEPA.impressaoCliente.modal.component';
import {DebDiretosSEPAImportadorModalComponent} from './core/modules/debDiretosSEPA/modals/debDiretosSEPA.importador.modal.component';
import {DevolucoesModalComponent} from './core/entities/docscomerciais/modals/devolucoes/devolucoes.modal.component';
import {ContabilidadeApuraInventPermaComponent} from './core/modules/portalcontabilidade/apurainventperma/components/contabilidade.apurainventperma.module.component';
import {ContabilidadeApuraInventPermaConfigContasModalComponent} from './core/modules/portalcontabilidade/apurainventperma/modal/configcontas/configContas.modal.component';
import {ManutTributAutonomasModuleComponent} from './core/modules/tributacaoautonoma/manuttributautonomas/components/manutTributAutonomas.module.component';
import {ManutTributAutonomasEmpresasModalComponent} from './core/modules/tributacaoautonoma/manuttributautonomas/modals/empresas/manutTributAutonomas.empresas.modal.component';
import {TributAutonomasModuleComponent} from './core/modules/tributacaoautonoma/tributautonomasanalise/components/tributAutonomas.module.component';
import {TributAutonomasPrintModalComponent} from './core/modules/tributacaoautonoma/tributautonomasanalise/modals/print/tributAutonomas.print.modal.component';
import {AnalisadorTesValoresAnterioresModalComponent} from './core/modules/tesouraria/analisadortes/modals/valoresAnteriores/analisadorTes.valoresAnteriores.modal';
import {ComercialDigitalDocumentosDigitaisModuleComponent} from './core/modules/comercialdigital/documentosdigitais/components/comercialDigital.documentosDigitais.module.component';
import {DocsComerciaisComponent} from './core/entities/docscomerciais/components/component/docsComerciais.component';
import {ComercialDigitalDocViewerComponent} from './core/components/arquivodigital/comercialdigital/docviewer/comercialDigital.docViewer.component';
import {DashboardContabilidadeModuleComponent} from './core/modules/dashboardscontabilidade/components/dashboardContabilidade.module.component';
import {DashboardAtivosModuleComponent} from './core/modules/ativos/dashboardsativos/components/dashboardAtivos.module.component';
import {SaftContabImportModalComponent} from './core/modules/saftcontab/modals/import/saftContab.import.modal.component';
import {Modelo22ContasDefAllModalComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/rosto/modal/contasdefall/modelo22.contasDefAll.modal.component';
import {Modelo22ContasDefCopyModalComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/rosto/modal/contasdefcopy/modelo22.contasDefCopy.modal.component';
import {RendHabitPermModuleComponent} from './core/modules/rendhabitperm/components/rendHabitPerm.module.component';
import {RendHabitPermModalComponent} from './core/modules/rendhabitperm/modals/rendHabitPerm.modal.component';
import {IntegracaoDocumentosModalComponent} from './core/modules/integracaodocumentos/modal/intFac.modal.componen';
import {DashboardRhModuleComponent} from './core/modules/dashboardsrh/components/dashboardRh.module.component';
import {MapaExploracaoConfigModalComponent} from './core/modules/portalcontabilidade/estatistica/mapaexploracao/components/modal/mapaExploracao.config.modal.component';
import {ComunicaInexistenciaModalComponent} from './core/modules/efatura/modals/comunicaInexistencia/comunicaInexistencia.modal.component';
import {Modelo22InputsCurrencyInlineComponent} from './core/modules/portalcontabilidade/modelo22/components/commons/inputscurrencyinline/inputsCurrencyInline.component';
import {CategoriasEditComponent} from './core/entities/categorias/components/edit/categorias.entity.edit.component';
import {DebDiretosSEPAPorNotificarModalComponent} from './core/modules/debDiretosSEPA/modals/debDiretosSEPA.porNotificar.modal.component';
import {DebDiretosSEPAReciboModalComponent} from './core/modules/debDiretosSEPA/modals/debDiretosSEPA.recibo.modal.component';
import {Modelo22NewTaxaDerramaModalComponent} from './core/modules/portalcontabilidade/modelo22/components/anexos/rosto/modal/newtaxaderrama/modelo22.newTaxaDerrama.modal.component';
import {RHAgenciasModalComponent} from './core/modules/portalrh/rhsaltotrf/modals/config/rhAgencias/rhAgencias.modal.component';
import {RHAgenciasAgenciaModalComponent} from './core/modules/portalrh/rhsaltotrf/modals/config/rhAgencias/agencia/rhAgencias.agencia.modal.component';
import {RHAgenciasContaModalComponent} from './core/modules/portalrh/rhsaltotrf/modals/config/rhAgencias/conta/rhAgencias.conta.modal.component';
import {RhSalToTrfImprimirModalComponent} from './core/modules/portalrh/rhsaltotrf/modals/imprimir/rhSalToTrf.imprimir.modal.component';
import {ConciliacaoBancariaContaPendentesBancoModalComponent} from './core/modules/portalcontabilidade/conciliacaobancaria/modals/contaPendentesBanco/conciliacaoBancaria.contaPendentesBanco.modal.component';
import {TrdocsCabsModalComponent} from './core/components/docscomerciais/trDocs/modals/cabs/trdocs.cabs.modal.component';
import {TrdocsCabsWizardComponent} from './core/components/docscomerciais/trDocs/components/cabsWizard/trdocs.cabsWizard.component';
import {TrDocsCabsWizardModuleComponent} from './core/modules/trDocsCabsWizard/components/trDocsCabsWizard.module.component';
import {TrdocsCabsErrosModalComponent} from './core/components/docscomerciais/trDocs/modals/cabs/trdocs.cabs.erros.modal.component';
import {SaftContabProgressBarModalComponent} from './core/modules/saftcontab/modals/progressbar/saftContab.progressBar.modal.component';
import {DocsComerciaisPrecoUnitarioModalComponent} from './core/entities/docscomerciais/modals/precoUnitario/docsComerciais.precoUnitario.modal.component';
import {ChangeArtigosFieldsInSerieModalComponent} from './core/entities/docscomerciais/modals/changeArtigosFieldsInSerie/changeArtigosFieldsInSerie.modal.component';
import {RhEstatisticasModuleComponent} from './core/modules/portalrh/rhestatisticas/components/rhEstatisticas.module.component';
import {RhEstatisticasExclusaoModalComponent} from './core/modules/portalrh/rhestatisticas/modals/exclusao/rhEstatisticas.exclusao.modal';
import {RepFinancasEditComponent} from './core/entities/repfinancas/components/edit/repFinancas.entity.edit.component';
import {RhEstatisticasMultiModuleComponent} from './core/modules/portalrh/rhestatisticasMulti/components/rhEstatisticasMulti.module.component';
import {RhEstatisticasMultiConfigModalComponent} from './core/modules/portalrh/rhestatisticasMulti/modals/rhEstatisticasMulti.config.modal';
import {AtivosAquisicaoInvestEmCursoPorDocContabModalComponent} from './core/modules/ativos/ativosaquisicaoinvestemcurso/modal/contab/ativosAquisicaoInvestEmCursoPorDoc.contab.modal.component';
import {DocsComerciaisConfigNomeExportFileModuleComponent} from './core/modules/docscomerciaisconfignomefileexport/components/docsComerciaisConfigNomeFileExport.module.component';
import {TxtclEntityEditComponent} from './core/entities/txtcl/components/edit/txtcl.entity.edit.component';
import {TxtarEntityEditComponent} from './core/entities/txtar/components/edit/txtar.entity.edit.component';
import {MapaDiferimentosAcrescimosModuleComponent} from './core/modules/diferimentos/mapadiferimentosacrescimos/components/mapaDiferimentosAcrescimos.module.component';
import {RhConflitosConflitoModalComponent} from './core/modules/configs/erp/portalcolaborador/modals/rhconflitos.conflito.modal.component';
import {ConfigsErpPortalColaboradorModuleComponent} from './core/modules/configs/erp/portalcolaborador/components/configs.portalcolaborador.module.component';
import {PRHTipoComEntityEditComponent} from './core/entities/prhtipocom/components/edit/prhtipocom.entity.edit.component';
import {GrupoDocfaConfiguracoesComponent} from './core/components/docscomerciais/grupoDocfaConfiguracoes/grupoDocfaConfiguracoes.component';
import {GrupoDocfaConfiguracoesModuleComponent} from './core/modules/grupoDocfaConfiguracoes/components/grupoDocfaConfiguracoes.module.component';
import {GrupoDocfaConfiguracoesModalComponent} from './core/modules/grupoDocfaConfiguracoes/modals/grupoDocfaConfiguracoes.modal.component';
import {AnaliseMargemLucroModuleComponent} from './core/modules/listagens/analisemargemlucro/components/analisemargemlucro.module.component';
import {AnaliseMargemLucroPrintModalComponent} from './core/modules/listagens/analisemargemlucro/modal/analisemargemlucro.print.modal';
import {DocumentoImputacoesDocContabModalComponent} from './core/entities/docscomerciais/modals/imputacoesDocContab/documento.imputacoesDocContab.modal.component';
import {BancosExtratoUnifiedPostAccountModalComponent} from './core/modules/bancosextrato/modals/unifiedpostaccount/bancosExtrato.unifiedpost.account.modal.component';
import {SplitViewerDocContabDigitalComponent} from './core/components/contabilidade/splitviewerdoccontabdigital/splitViewerDocContabDigital.component';
import {ExtratosDTPrintModalComponent} from './core/modules/portalcontabilidade/estatistica/extratosdt/modals/print/extratosDT.print.modal.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DragDropModule,
    ScrollingModule,
    NgOptimizedImage,
    UIRouterModule.forRoot(UI_ROUTER_MODULE_CONFIG),
    TranslateModule.forRoot(TRANSLATE_MODULE_CONFIG),
    HammerModule,
    DxHttpModule,
    DxButtonModule,
    DxChartModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxDiagramModule,
    DxDropDownButtonModule,
    DxHtmlEditorModule,
    DxListModule,
    DxPieChartModule,
    DxPivotGridModule,
    DxPopupModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxTooltipModule,
    DxTreeListModule,
    CGCompsModule
  ],
  declarations: [
    AbDesEditComponent,
    AbDesListComponent,
    AccountComponent,
    AcrescimosEncargosFeriasConfigModalComponent,
    AcrescimosEncargosFeriasGerarEncargosMultiModalComponent,
    AcrescimosEncargosFeriasIntegContabMultiErrorsModalComponent,
    AcrescimosEncargosFeriasModuleComponent,
    AcrescimosEncargosFeriasMultiempresadetailModalComponent,
    AcrescimosEncargosFeriasPrintModalComponent,
    AcrescimosEncargosFeriasResumoanualModalComponent,
    AcrescimosEncargosFeriasSingleComponent,
    AddAbonoDescontoModalComponent,
    AdiantamentosAutoConfigModalComponent,
    AdiantamentosConfigsModalComponent,
    AdiantamentosModuleComponent,
    AdiantamentosPrintModalComponent,
    AdiantamentosRegistarModalComponent,
    AlertDebugErrorComponent,
    AlteracoesEntidadesModuleComponent,
    AlterarCamposSerieModalComponent,
    AmaliaChatMessageComponent,
    AmaliaChatMessageContentComponent,
    AmaliaWindowComponent,
    AnalisadorTesComponent,
    AnalisadorTesConfigModalComponent,
    AnalisadorTesProgressModalComponent,
    AnalisadorTesTeslancoModalComponent,
    AnalisadorTesValoresAnterioresModalComponent,
    AnaliticaConfigModuleComponent,
    AnaliseMargemLucroModuleComponent,
    AnaliseMargemLucroPrintModalComponent,
    AppComponent,
    AprovacoesRefeicoesComponent,
    ArclisEditComponent,
    ArquivoDigitalConfigModalComponent,
    ArquivoDigitalDocViewerComponent,
    ArquivoDigitalGestaoDocumentosDigitalizadosComponent,
    ArtigosEditComponent,
    ArtigosGdocComponent,
    ArtigosListComponent,
    AssistConfigPortaisEmpregadoModuleComponent,
    AssistConfigPortaisEmpresaModuleComponent,
    AssistConfigPortaisGeralEmailModuleComponent,
    AssistConfigPortaisPortalClientConnectUtilizadorExistenteModuleComponent,
    AssistConfigPortaisPortalClientConnectUtilizadorModuleComponent,
    AssistConfigPortaisUtilizadorModuleComponent,
    AssistenteConfigPortaisModuleComponent,
    AssociarMenusComponent,
    AssociarServicosComponent,
    AtFaturasRecibosVerdesModuleComponent,
    AtFaturasRecibosVerdesConfigModalComponent,
    AtFaturasRecibosVerdesLancarDocsEmSerieModalComponent,
    AtFaturasRecibosVerdesNifConfigModalComponent,
    AtFaturasRecibosVerdesNovaContaCorrenteModalComponent,
    AtivosAbateModalComponent,
    AtivosAbateModuleComponent,
    AtivosAjusteDepAntigasModalComponent,
    AtivosAquisicaoContabModalComponent,
    AtivosAquisicaoContabModuleComponent,
    AtivosAquisicaoInvestEmCursoPorDocContabModalComponent,
    AtivosAquisicaoInvestEmCursoPorDocModuleComponent,
    AtivosAumentoIFRS16ModalComponent,
    AtivosCoeficienteCMModuleComponent,
    AtivosComponent,
    AtivosConfiguracoesModuleComponent,
    AtivosConsistenciaLancaDifModalComponent,
    AtivosConsistenciaListaAtivosModalComponent,
    AtivosConsistenciaModuleComponent,
    AtivosDepreciacaoDatasPosteModalComponent,
    AtivosDepreciacaoModuleComponent,
    AtivosDeprecriacoesDetalhadasModalComponent,
    AtivosDeprecriacoesModalComponent,
    AtivosDivisaoModalComponent,
    AtivosEditComponent,
    AtivosGeraMapasAutoModuleComponent,
    AtivosGrandeReparacaoModelComponent,
    AtivosHistoricoModalComponent,
    AtivosImportAjustesNasContasModalComponent,
    AtivosImportAtPercReducaoModalComponent,
    AtivosImportAtValorAnualManModalComponent,
    AtivosImportConsistenciasContasModalComponent,
    AtivosImportEditComponent,
    AtivosImportFolhaImportModalComponent,
    AtivosImportImportarFicheiroModalComponent,
    AtivosImportListComponent,
    AtivosImportProgressBarModalComponent,
    AtivosImpressaoDepModalComponent,
    AtivosImpressaoDocModalComponent,
    AtivosImpressaoModalComponent,
    AtivosImpressaoModuleComponent,
    AtivosListComponent,
    AtivosMapasOficiaisModuleComponent,
    AtivosMudarContaAtivoModalComponent,
    AtivosMudarContaDepModalComponent,
    AtivosMudarTaxasDepModalComponent,
    AtivosNewComponent,
    AtivosNotaCreditoModalComponent,
    AtivosNotaCreditoSubModalModalComponent,
    AtivosOpJustoValorABPModalComponent,
    AtivosPesquisaContabilidadeModalComponent,
    AtivosPlanningModalComponent,
    AtivosReavaliacoesNaoLegaisModalComponent,
    AtivosVendaContabModalComponent,
    AtivosVendaModalComponent,
    AtivosVendaModuleComponent,
    AvencasEditComponent,
    AvencasListComponent,
    AvencasSelecionarTipoModalComponent,
    AvisoAlteracaoCCModalComponent,
    AvisosCobrancaAlterarEmailPontualModalComponent,
    AvisosCobrancaConfigsModalComponent,
    AvisosCobrancaEmailModalComponent,
    AvisosCobrancaGridModuleComponent,
    AvisosCobrancaPreviewModalComponent,
    BalancetesGridComponent,
    BancosExtratoModuleActivateLicenseModalComponent,
    BancosExtratoModuleAuthorizationsModalComponent,
    BancosExtratoModuleComponent,
    BancosExtratoModuleConfigModalComponent,
    BancosExtratoModuleDocsSemelhantesModalComponent,
    BancosExtratoModuleEditBankAccountModalComponent,
    BancosExtratoModuleImportByReconciliationModalComponent,
    BancosExtratoModulePredefDescModalComponent,
    BancosExtratoMovabModalComponent,
    BancosExtratoUnifiedPostAccountModalComponent,
    BlockedPluginModuleComponent,
    CalcDiferimentosComponent,
    CalculoDiferimentosConfigModalComponent,
    CalculoDiferimentosInfoModalComponent,
    CalculoDiferimentosModuleComponent,
    CargVeicEditComponent,
    CargVeicReboqueModalComponent,
    CategoriasEditComponent,
    CctEditComponent,
    CCustosNewComponent,
    CessacaoContTrabModalComponent,
    CGAppStatusDisconnectedComponent,
    CGAppStatusMaintenanceComponent,
    CGAppStatusNewVersionAvailableComponent,
    CGBlockedPluginComponent,
    CGCardPanelComponent,
    CGCardPanelContentDirective,
    CGContabExtratosComponent,
    CGCookiesConsentComponent,
    CGDateFromNowPipe,
    CGDeletePromptModalComponent,
    CGEntityFilterComponent,
    CGHelpTopicsModalComponent,
    CGModalChooseCountryComponent,
    CGModalComponent,
    CGModalContentDirective,
    CGModalHeaderDirective,
    CGModalOkCancelComponent,
    CGPanelMultipleSelectionExecuteComponent,
    CGPanelSliderComponent,
    CGPanelSliderContentDirective,
    CGPdfModalComponent,
    CGPdfViewerComponent,
    CGTableLegendComponent,
    CGTermsModalComponent,
    CGUserAccessesComponent,
    CGUserAccessesItemComponent,
    CGUserLocaleChooserComponent,
    CGUserNavComponent,
    CGUserProfileImageComponent,
    ChangeArtigosFieldsInSerieModalComponent,
    ChangePasswordComponent,
    CheckSendAllMailsModalComponent,
    ChooseRolesModalComponent,
    ClassesCaixaEditComponent,
    ClientesEditComponent,
    ClifoDefinicaoEmailsComponent,
    ClifosAvisoDadosRepetidosModalComponent,
    ClifosEditComponent,
    ClifosFichaComponent,
    ClifosGdocComponent,
    ClifosListComponent,
    CloneMenusModalComponent,
    ColaboradorBoardComponent,
    ColaboradoresGestaoDGEMPSModuleComponent,
    ColaboradoresHomeComponent,
    ColaboradoresMarcacaoRefeicoesModuleComponent,
    ColaboradoresVisualizarRefeicoesModuleComponent,
    ComercialDigitalDocumentosDigitaisModuleComponent,
    ComercialDigitalDocViewerComponent,
    CompanyStatusComponent,
    ComplementosExcepcoesModalComponent,
    ComunicacaoInternaRecursivaComponent,
    ComunicacaoSeriesATModalComponent,
    ComunicacoesInternasComponent,
    ComunicacoesInternasModalComponent,
    ComunicaDocsModalComponent,
    ComunicaInexistenciaModalComponent,
    ConcelhosDadosDerramaModalComponent,
    ConcelhosEditComponent,
    ConciliacaoBancariaAddDocsNaoConciliadosModalComponent,
    ConciliacaoBancariaAddLinhaExtratoBancoModalComponent,
    ConciliacaoBancariaAddLinhaExtratoCGModalComponent,
    ConciliacaoBancariaBancosTreeViewerComponent,
    ConciliacaoBancariaConfigImportExtratoModalComponent,
    ConciliacaoBancariaContaPendentesBancoModalComponent,
    ConciliacaoBancariaImportExtratoBancarioModalComponent,
    ConciliacaoBancariaModuleComponent,
    ConciliacaoBancariaNovaConciliacaoModalComponent,
    ConciliacaoBancariaPdfModalComponent,
    ConciliacaoBancariaSaldoDetalhesModalComponent,
    ConciliacaoBancariaViewDocModalComponent,
    ConciliacaoEFaturasModuleComponent,
    ConciliacaoEFaturasModuleConfigModalComponent,
    ConciliacaoEFaturasModuleExtratosModalComponent,
    ConciliacaoEFaturasModulePesquisaDocPossivelModalComponent,
    ConciliacaoEFaturasModuleViewDocModalComponent,
    ConcilMovAbJustEmSerieContasModalComponent,
    ConcilMovAbModuleComponent,
    ConcilMovAbProgressBarModalComponent,
    CondicoescomerciaisEditComponent,
    ConfigDocsEFaturaGlobalComponent,
    ConfigDocsEFaturaHeaderComponent,
    ConfigDocsEFaturaSupplierComponent,
    ConfigEfaturaDocPorLancModalComponent,
    ConfigEfaturaDocPorLancModuleComponent,
    ConfigEfaturaDocPorLancSupplierModalComponent,
    ConfigErpComponent,
    ConfigLoginWsSSModalComponent,
    ConfigOptionsComponent,
    ConfigsErpClifosModuleComponent,
    ConfigsErpComercialComponent,
    ConfigsErpContabilidadeModuleComponent,
    ConfigsErpEmpresaModuleComponent,
    ConfigsErpLicencaModuleComponent,
    ConfigsErpPCAModuleComponent,
    ConfigsErpPortalColaboradorModuleComponent,
    ConfigsErpRHModuleComponent,
    ConfigsSiteModuleComponent,
    ConfiguracoesEventosComponent,
    ConfiguracoesEventosModalComponent,
    ConfiguracoesEventosModuleComponent,
    ConfigValueComponent,
    ConsultaSerieAtModalComponent,
    ConsultEnviosImpResumoModalComponent,
    ContabAdvancedOperationsModuleComponent,
    ContabDigitalClientConnectSynchronizationKeyModalComponent,
    ContabilidadeApuraInventPermaComponent,
    ContabilidadeApuraInventPermaConfigContasModalComponent,
    ContabilidadeApuraivaComponent,
    ContabilidadeApuraIvaSaveModalComponent,
    ContabilidadeApuramentoResultadosComponent,
    ContabilidadeBalancetesAnaliticaComponent,
    ContabilidadeBalancetesComponent,
    ContabilidadeDeclaracaoivaConfigModalComponent,
    ContabilidadeDeclaracaoIvaPeriodicaModuleComponent,
    ContabilidadeDeclaracaoIvaRecapitulativaModuleComponent,
    ContabilidadeDigitalActivateLicenseModalComponent,
    ContabilidadeDigitalAtribuirTipoDocumentoModalComponent,
    ContabilidadeDigitalCheckFoldersCreatedModalComponent,
    ContabilidadeDigitalClientConnectAddDocsModalComponent,
    ContabilidadeDigitalClientConnectDeleteDocsModalComponent,
    ContabilidadeDigitalClientConnectInspectDocModalComponent,
    ContabilidadeDigitalClientConnectModuleComponent,
    ContabilidadeDigitalClientConnectNativeComponent,
    ContabilidadeDigitalCopiarDocModalComponent,
    ContabilidadeDigitalDocumentosDigitaisClientConnectModuleComponent,
    ContabilidadeDigitalDocumentosDigitaisConcilicaoModuleComponent,
    ContabilidadeDigitalDocumentosDigitaisModuleComponent,
    ContabilidadeDigitalDocViewerRecolhaAttachExistingDocModalComponent,
    ContabilidadeDigitalDocViewerRecolhaChooseFolderModalComponent,
    ContabilidadeDigitalDocViewerRecolhaComponent,
    ContabilidadeDigitalDocViewerRecolhaFooterComponent,
    ContabilidadeDigitalFlatViewerComponent,
    ContabilidadeDigitalNovaContaCCModalComponent,
    ContabilidadeDigitalPreDefinidosConfigModalComponent,
    ContabilidadeDigitalRemovePagesModalComponent,
    ContabilidadeDigitalSepararDocModalComponent,
    ContabilidadeDigitalTreeViewerComponent,
    ContabilidadeDigitalTreeViewerNodeComponent,
    ContabilidadeDigitalUnirDocsModalComponent,
    ContabilidadeDocsNaoSaldadosComponent,
    ContabilidadeExtratosComponent,
    ContabilidadeExtratosGridComponent,
    ContabilidadeExtratosGridModalComponent,
    ContabilidadeExtratosSoAtePeriodoComponent,
    ContabilidadeImpdecpeComponent,
    ContabilidadeMovimentosEmAbertoComponent,
    ContabilidadePredefinidosComponent,
    ContabilidadeSvatBalanceteModalComponent,
    ContabilidadeSvatComponent,
    ContabilidadeSvatPrintComponent,
    ContaPocSaldaModalComponent,
    ContasClienteComponent,
    ContratosDetailComponent,
    ContratosEditComponent,
    ContratosListComponent,
    ContratosPrintModuleComponent,
    ContratosTipoAvencasEditComponent,
    ContratosTiposEditComponent,
    ControloIVAEditComponent,
    ControloIVAEntreEmpresasComponent,
    ControloIVAListComponent,
    ContTrabBaseLegalEditComponent,
    CPPesquisaDocsLinhaComponent,
    CreateNewContaCorrenteModalComponent,
    CriarTaxaIvaModalComponent,
    CRSSEditComponent,
    CruzamentosABDESEditComponent,
    CruzamentosABDESListComponent,
    DashboardAtivosModuleComponent,
    DashboardContabilidadeModuleComponent,
    DashboardFinanceiroModuleComponent,
    DashboardRhModuleComponent,
    DashboardsERPModuleComponent,
    DataSourceInputComponent,
    DebDiretosSEPAClienteModalComponent,
    DebDiretosSEPAClientesModalComponent,
    DebDiretosSEPAConfiguracaoModalComponent,
    DebDiretosSEPAContaCredoraModalComponent,
    DebDiretosSEPAEmissaoModalComponent,
    DebDiretosSEPAImportadorModalComponent,
    DebDiretosSEPAImpressaoClienteModalComponent,
    DebDiretosSEPAImpressaoModalComponent,
    DebDiretosSEPALogModalComponent,
    DebDiretosSEPAModuleComponent,
    DebDiretosSEPAPorNotificarModalComponent,
    DebDiretosSEPAReciboModalComponent,
    DeclaracaoIvaPeriodicaExtratoCampoModalComponent,
    DeclaracaoIvaPeriodicaNifROCModalComponent,
    DeclaracaoIvaPeriodicaPrevisualizaDeclaracaoModalComponent,
    DefinicaoEmailsModalComponent,
    DemFinSNCListSavedModalComponent,
    DemFinSNCModuleComponent,
    DemFinSNCNotasModalComponent,
    DemFinSNCObsModalComponent,
    DemFinSNCPreviewPDFModalComponent,
    DevExpressCentralGestContextMenuComponent,
    DevExpressChartCentralGestDirective,
    DevExpressDataGridCentralGestDirective,
    DevExpressDatagridNavigationDirective,
    DevExpressPieChartCentralGestDirective,
    DevolucoesModalComponent,
    DgempsFichaComponent,
    DGEMPSFullCommentModalComponent,
    DGEMPSFullEditComponent,
    DGEMPSFullEditDeletePromptModalComponent,
    DgempsFullListComponent,
    DGEMPSFullSaveCadastroModalComponent,
    DgempsfullSimulaTaxaFixaIrsModalComponent,
    DisconnectedComponent,
    DmrfEditModalComponent,
    DmrfModuleComponent,
    DocContabilidadeCalcDiferimentosModalComponent,
    DocContabilidadeCambioComponent,
    DocContabilidadeCDecAnualComponent,
    DocContabilidadeCDecPerComponent,
    DocContabilidadeComponent,
    DocContabilidadeDataDocComponent,
    DocContabilidadeDescricaoComponent,
    DocContabilidadeNContaCreditoComponent,
    DocContabilidadeNContaDebitoComponent,
    DocContabilidadeNContribuinteComponent,
    DocContabilidadeTotaisComponent,
    DocContabilidadeValorComponent,
    DocContabilidadeValorMEComponent,
    DocContabilidadeValorTaxaComponent,
    DocfaNumsFinalizarAtModalComponent,
    DocfasEditComponent,
    DocfasListComponent,
    DocfasNewModalComponent,
    DocfasNovaSerieModalComponent,
    DocFasNumsComunicacaoLoginModalComponent,
    DocsComerciaisComponent,
    DocumentoImputacoesDocContabModalComponent,
    DocsComerciaisConfigNomeExportFileModuleComponent,
    DocsComerciaisEditComponent,
    DocsComerciaisEstatisticasModuleComponent,
    DocsComerciaisEstatisticasComponent,
    DocsComerciaisEstatisticasDocComponent,
    DocsComerciaisEstatisticasDocPrintModalComponent,
    DocsComerciaisEstatisticasPrintModalComponent,
    DocsComerciaisImportadorLinhasModalComponent,
    DocsComerciaisListComponent,
    DocsComerciaisMeiosPagamentoComponent,
    DocsComerciaisPrecoUnitarioModalComponent,
    DocsComerciaisSaveModalComponent,
    DocsContabilidadeEditCCustoModalComponent,
    DocsContabilidadeEditComponent,
    DocsContabilidadeEditMoedaEstrangeiraModalComponent,
    DocsContabilidadeEditMovabModalComponent,
    DocsContabilidadeEditRegularizacaoCampo40ModalComponent,
    DocsContabilidadeEditReguralizacaoCampo40CancelModalComponent,
    DocsContabilidadeEditRetencaoModalComponent,
    DocsContabilidadeEditValorModalComponent,
    DocsContabilidadeListComponent,
    DocsContabilidadePrintModalComponent,
    DocsContabilidadeSavePromptModalComponent,
    DocsContabilidadeViewDocModalComponent,
    DocsContasInventarioModalComponent,
    DocsDigitaisAdicionarDocumentosModalComponent,
    DocsDigitaisDocsContabSemDocDigitalModalComponent,
    DocsDigitaisExportarFolderModalComponent,
    DocsDigitaisExportarModalComponent,
    DocsDigitaisTreeDocsNaoClassificadosModalComponent,
    DocsPlataformaComponent,
    DocsPlataformaModalComponent,
    DocumentoAnularModalComponent,
    DocumentoArredondamentoManualModalComponent,
    DocumentoArtigosAdvancedSearchModalComponent,
    DocumentoComunicarATModalComponent,
    DocumentoContabilidadeComponent,
    DocumentoDetailModalComponent,
    DocumentoDuplicarModalComponent,
    DocumentoFacturacaoEncerrarModalComponent,
    DocumentoFaturacaoComponent,
    DocumentoMeiosPagamentoModalComponent,
    DocumentoNotacreditoModalComponent,
    DocumentoPesqDocsGlobaisModalComponent,
    DocumentoPesqDocsModalComponent,
    DocumentoRastreabilidadeModalComponent,
    DocumentosComerciaisComponent,
    DocumentosFiscaisAssociarDocumentosContabDigitalModalComponent,
    DocumentosFiscaisComponent,
    DocumentosFiscaisDpIvaDocPagamentoModalComponent,
    DocumentosFiscaisIesPagamentoModalComponent,
    DocumentosFiscaisProgressModalComponent,
    DocumentosPartilhadosComponent,
    DocumentosPartilhadosUploadModalComponent,
    DossierFiscalComponent,
    EditarCruzamentosModalComponent,
    EditarCruzamentosPerfilModalComponent,
    EFaturaCirculacaoLoginModalComponent,
    EFaturaComponent,
    EFaturaGenerateDocsModalComponent,
    EFaturaLoginModalComponent,
    EmailClifoSendMailModalComponent,
    EmpregadosComponent,
    EmpresaBloqueadaComponent,
    EmpresasComponent,
    EmpresasPerioContabFirstMonthModalComponent,
    EmpresaTarifasModalComponent,
    EncomendasComponent,
    EncomendasDocumentoModalComponent,
    EncomendasImpressaoModalComponent,
    EncomendasLotesModalComponent,
    EncomendasModuleComponent,
    EncomendasProcessamentoModalComponent,
    EncomendasSatisfazerClifoModalComponent,
    EncomendasSimulacaoModalComponent,
    EntidadeExternaEditComponent,
    EntityAutocompleteComponent,
    EntityDetailComponent,
    EntityDetailContentDirective,
    EntityDetailPartialContentDirective,
    EntityDetailSuccessDirective,
    EntityListComponent,
    EntityListDataGridTemplateDirective,
    EntityListDatagridToolbarItemTemplateDirective,
    EntityListHeaderActionsDirective,
    EntityListItemActionsDirective,
    EntityListItemDetailDirective,
    EntityListToolbarComponent,
    EntityMaintenanceDetailModalComponent,
    EntityMaintenanceListModalComponent,
    ErpCheckModuleComponent,
    ErpCheckValidateAllModalComponent,
    ExcecoesCrAbDModalComponent,
    ExportacaoComprasConfigModalComponent,
    ExportacaoComprasModuleComponent,
    ExtractosArtigosComponent,
    ExtractosartigosModuleComponent,
    ExtratosClifosComponent,
    ExtratosContaComponent,
    ExtratosDTComponent,
    ExtratosDTModalComponent,
    ExtratosDTModuleComponent,
    ExtratosDTPrintModalComponent,
    FamiliasEditComponent,
    FaturacaoContratosComponent,
    FaturacaoContratosLogComponent,
    FaturacaoEletronicaConfiguracoesAddSafeCertificadoModalComponent,
    FaturacaoEletronicaConfiguracoesClientModalComponent,
    FaturacaoEletronicaConfiguracoesDigitalSignAuthorizerModalComponent,
    FaturacaoEletronicaConfiguracoesGuideModalComponent,
    FaturacaoEletronicaConfiguracoesModuleComponent,
    FaturacaoEletronicaConfiguracoesPostAddSafeCertificadoModalComponent,
    FaturacaoPublicaComunicacaoManualModalComponent,
    FaturacaoPublicaConfigModalComponent,
    FaturacaopublicaConfigNovoestadoModalComponent,
    FaturacaoPublicaDocComercialModalComponent,
    FaturacaoPublicaHistoricoModalComponent,
    FaturacaoPublicaModuleComponent,
    FaturacaoPublicaObservacoesModalComponent,
    FctComponent,
    FctCredentialsModalComponent,
    FctDadosTodosTrabComFCModalComponent,
    FctPedidoReembolsoModalComponent,
    FctPedidosReembolsoEfetuadosModalComponent,
    FctPromptModalComponent,
    FileViewerComponent,
    FimAnoComponent,
    FimPeriodoEncEditComponent,
    FimPeriodoEncListComponent,
    FimPeriodoEncModalComponent,
    FluxosComponent,
    FluxosFichaModalComponent,
    FornecedoresEditComponent,
    GDocComercialModalComponent,
    GDocContabilidadeModalComponent,
    GDocsComponent,
    GenerateAbdesModalComponent,
    GenericImporterComponent,
    GenericImporterModalComponent,
    GestaoAvencasModalComponent,
    GestaoDGEMPSComponent,
    GestaoDGEMPSEventHistoryModalComponent,
    GestaoDGEMPSEventsDetailComponent,
    GestaoDGEMPSHeaderComponent,
    GestaoDGEMPSMarcarAbonosModalComponent,
    GestaoDGEMPSMarcarFaltasModalComponent,
    GestaoDGEMPSMarcarFeriasModalComponent,
    GestaoDGEMPSMonthViewComponent,
    GestaoDGEMPSMotivoModalComponent,
    GestaoDGEMPSPrintModalComponent,
    GestaoDGEMPSViewDateComponent,
    GestaoDGEMPSViewDatePickerComponent,
    GestaoDGEMPSYearViewComponent,
    GestaoDocsComerciaisModalComponent,
    GestaoFicheirosSepaModuleComponent,
    GrupoDocfaConfiguracoesComponent,
    GrupoDocfaConfiguracoesModuleComponent,
    GrupoDocfaConfiguracoesModalComponent,
    GtoAlertsModalComponent,
    GtoConfigModalComponent,
    GtoCopyTasksModalComponent,
    GtoCreateEditTaskModalComponent,
    GtoExecuteModalComponent,
    GtoLicenseModalComponent,
    GtoModuleComponent,
    GTOMultiEmpresaComponent,
    GTOPorEmpresaComponent,
    HomeComunicacoesInternasModalComponent,
    IesComponent,
    ImpAvisosCobrancaErrosModalComponent,
    ImpAvisosCobrancaModuleComponent,
    ImpCtrIrsModuleComponent,
    ImpDadosEmpModuleComponent,
    ImpDe119ModuleComponent,
    ImpdocModalComponent,
    ImpencarModuleComponent,
    ImpFFERPOCModuleComponent,
    ImportadorEfaturaComponent,
    ImportadorEfaturaLancarDocsEmSerieModalComponent,
    ImportadorSaftDirectArtigosConfigModalComponent,
    ImportadorSaftDirectComponent,
    ImportadorSaftDirectConfigMascaraAnaliticaModalComponent,
    ImportadorSaftDirectCorrespContasAssociarModalComponent,
    ImportadorSaftDirectCorrespContasAtribuirModalComponent,
    ImportadorSaftDirectCorrespContasModalComponent,
    ImportadorSaftDirectDatasDocumentosEditModalComponent,
    ImportadorSaftDirectFinalizedModalComponent,
    ImportadorSaftDirectInProcessModalComponent,
    ImportadorSaftDirectTaxasIvaModalComponent,
    ImportadorSaftDirectTiposDocsModalComponent,
    ImportadorSaftDirectVerContaFormadaModalComponent,
    ImportXLSModuleComponent,
    ImtabEditComponent,
    ImtabEditDataValorModalComponent,
    InfoGlobalDiferimentosAcrescimosModuleComponent,
    InputEntityComponent,
    IntegracaoDocumentosModalComponent,
    IntegracaoDocumentosModuleComponent,
    IntegracaoSalariosComponent,
    IntegracaoSalariosConfigModalComponent,
    IntegracaoSalariosErrorPromptModalComponent,
    IntegracaoSalariosErrorsModalComponent,
    IntegracaoSalariosMultiComponent,
    IntegracaoSalariosProcessModalComponent,
    IntegracaoSalariosSimuladorModalComponent,
    InventariosImprimirModalComponent,
    InventariosNewModalComponent,
    InventariosStockComponent,
    InventariosValorizarModalComponent,
    IvasEditComponent,
    KeyBindingsHelperComponent,
    ListagemdiariosComponent,
    ListagemMenusComponent,
    ListagemRefeicoesComponent,
    LoadModuleStateComponent,
    LockedContractStateComponent,
    LogComunicacaoSerieAtModalComponent,
    LoginComponent,
    LotesEditComponent,
    LotesListComponent,
    MaintenanceStateComponent,
    ManutTributAutonomasEmpresasModalComponent,
    ManutTributAutonomasModuleComponent,
    MapaDiferimentosAcrescimosModuleComponent,
    MapaExploracaoComponent,
    MapaExploracaoConfigModalComponent,
    MapaFaltasComponent,
    MapaFeriasComponent,
    MapaFundosCompensacaoComponent,
    MapaPagamentosApagarPromptModalComponent,
    MapaPagamentosConfigModalComponent,
    MapaPagamentosDocumentosModalComponent,
    MapaPagamentosEnviadosPromptModalComponent,
    MapaPagamentosLoginModalComponent,
    MapaPagamentosModuleComponent,
    MapaPagamentosPagamentoDetalhesModalComponent,
    MapaPagamentosPagamentoModalComponent,
    MapaPagamentosProcessModalComponent,
    MapaPagamentosResumoEnvioModalComponent,
    MapaSeguradoraComponent,
    MapaSegurancaSocialComponent,
    MapaSindicatosComponent,
    MascarasAnaliticaEditComponent,
    MascarasAnaliticaEditDefinitionNamesModalComponent,
    MBConfigsAddTipoDocModalComponent,
    MBConfigsModuleComponent,
    MeiosPagamentoEditComponent,
    MeiosPagamentoListComponent,
    MenuseditComponent,
    MillenniumComponent,
    Modelo10ConfigModalComponent,
    Modelo10ModuleComponent,
    Modelo10RetencaoMod10ModalComponent,
    Modelo22AnexoAModalComponent,
    Modelo22AnexoDModalComponent,
    Modelo22AnexoDQuadro7ModalComponent,
    Modelo22AnexoDQuadro7Tabelas78ModalComponent,
    Modelo22AnexoEModalComponent,
    Modelo22AnexoFModalComponent,
    Modelo22AnexoGModalComponent,
    Modelo22AnexosAnexoAComponent,
    Modelo22AnexosAnexoCComponent,
    Modelo22AnexosAnexoDComponent,
    Modelo22AnexosAnexoEComponent,
    Modelo22AnexosAnexoFComponent,
    Modelo22AnexosAnexoGComponent,
    Modelo22AnexosRostoComponent,
    Modelo22ConfigwsModalComponent,
    Modelo22ContasDefAllModalComponent,
    Modelo22ContasDefCopyModalComponent,
    Modelo22ContasDefModalComponent,
    Modelo22DerramaModalComponent,
    Modelo22InputsCurrencyInlineComponent,
    Modelo22ModuleComponent,
    Modelo22NewTaxaDerramaModalComponent,
    Modelo22PrejuizosFiscaisModalComponent,
    Modelo22RostoModalComponent,
    Modelo22RostoQuadro14ModalComponent,
    Modelo25ConfigModalComponent,
    Modelo25ModuleComponent,
    Modelo30ModuleComponent,
    Modelo30VerificarLancamentosModalComponent,
    Modelo39ModuleComponent,
    ModeloConfigwsModalComponent,
    ModuleMaintenanceModalComponent,
    ModulesModuleComponent,
    ModuloComponent,
    ModuloDetailComponent,
    ModuloEntityDetailComponent,
    ModuloEntityListComponent,
    MoedaEditCambioModalComponent,
    MoedaEditComponent,
    MoralsEditComponent,
    MotivoAnulacaoModalComponent,
    MovimentosAbertoComponent,
    MovimentosAbertoModalComponent,
    MovimentosPendentesClientesComponent,
    MovimentosPendentesClientesModuleComponent,
    MovimentosPendentesComponent,
    MovimentosPendentesFornecedoresComponent,
    MovimentosPendentesFornecedoresModuleComponent,
    MovimentosPendentesOutrosDevedoresCredoresComponent,
    MovimentosPendentesOutrosDevedoresCredoresModuleComponent,
    MudaPasswordModalComponent,
    NewPreviTesModalComponent,
    NifsComponent,
    NifsEditComponent,
    NifsListComponent,
    NoAuthorityComponent,
    NotificationCenterComponent,
    NotificationCenterModuleComponent,
    NotificationCenterNoNotificationsComponent,
    NotificationItemComponent,
    OfficeReportingModuleComponent,
    OutrosDevedoresCredoresEditComponent,
    PagamentoComponent,
    PagamentoImprimirModalComponent,
    PagamentosDistribuiValorModalComponent,
    PagamentosEditComponent,
    PagamentoSepaAnularModalComponent,
    PagamentosListComponent,
    PagamentosSaveModalComponent,
    PcaBalancetesModuleComponent,
    PCAContabilidadeExtratosComponent,
    PCADashboardsComponent,
    PcaIdadeSaldosComponent,
    PCAMovimentosAbertoComponent,
    PcaRhGestaoDGEMPSAbonosMarcarModalComponent,
    PcaRhGestaoDGEMPSFaltasMarcarModalComponent,
    PcaRhGestaoDGEMPSFeriasMarcarModalComponent,
    PcaRhGestaoDGEMPSModuleComponent,
    PerfilCategoriaAbonoEditComponent,
    PerfilCategoriaDescontoEditComponent,
    PlanosContasAlternativosContaModalComponent,
    PlanosContasAlternativosCuboModalComponent,
    PlanosContasAlternativosEditComponent,
    PlanosContasAlternativosExtratoModalComponent,
    PlanosContasAlternativosImportModalComponent,
    POCSCopyModuleComponent,
    PocsEditComponent,
    PocsListComponent,
    PortalAboutModalComponent,
    PortalChangeFirmModalComponent,
    PortalComponent,
    PortalEntityEditComponent,
    PortalEntityListComponent,
    PortalsSortModalComponent,
    PortalUITreeComponent,
    PortalUITreeEditNodeModalComponent,
    PredefinidoContabCabComponent,
    PredefinidoContabFormulaComponent,
    PredefinidoContabFormulaDialogModalComponent,
    PredefinidoContabLinhasComponent,
    PreviTesAutoCodRubricaModalComponent,
    PreviTesAutoDataTesModalComponent,
    PreviTesAutoModuleComponent,
    PreviTesModuleComponent,
    PrevTesObrasModuleComponent,
    PrevTesObrasRubricaModalComponent,
    ProceDMRATComponent,
    ProceDMRATPrintModalComponent,
    ProceDMRATViewInfoComponent,
    ProceSalariosComponent,
    ProceSalariosEmpregadosModalComponent,
    ProceSalariosProcErrorsModalComponent,
    ProceSalariosProgressModalComponent,
    ProceSegSocialComponent,
    ProceSegSocialPrintModalComponent,
    ProceSegSocialViewInfoComponent,
    ProcSeguradoraFolhaFeriasInfoModalComponent,
    ProcSeguradoraFolhaFeriasModuleComponent,
    ProcSeguradoraFolhaFeriasPrintModalComponent,
    ProtecaoPasswordModalComponent,
    RecaptchaBrandingComponent,
    RecaptchaV2ModalComponent,
    ReciboComponent,
    ReciboImprimirModalComponent,
    RecibosDistribuivalorModalComponent,
    RecibosEditComponent,
    RecibosListComponent,
    RecibosSaveModalComponent,
    RecibosVencimentoComponent,
    RecibosVencimentosEmailModalComponent,
    RecibosVencimentosModuleComponent,
    ReconstrucaoAcumuladosComponent,
    ReembolsosIvaDetailComponent,
    ReembolsosivaNewComponent,
    RefeicoesSchedulerComponent,
    RegistarNifModalComponent,
    RendHabitPermModalComponent,
    RendHabitPermModuleComponent,
    RendisEditComponent,
    ReparticoesCCustoComponent,
    ReparticoesCCustoEditComponent,
    RepFinancasEditComponent,
    ReportsInputComponent,
    ResetPasswordComponent,
    ResumoIvaModuleComponent,
    RetencoesModuleComponent,
    RHAgenciasAgenciaModalComponent,
    RHAgenciasContaModalComponent,
    RHAgenciasModalComponent,
    RHAtualizaFeriasEmpDataCadastroModalComponent,
    RHAtualizaFeriasEmpModuleComponent,
    RhCalculoRendimentoLiquidoModuleComponent,
    RhCessaContratoTrabalhoModuleComponent,
    RhConflitosConflitoModalComponent,
    RHCopiaAbonosDescontosInformacaoConfirmacaoModalComponent,
    RHCopiaAbonosDescontosModuleComponent,
    RHDadosConfigFichaComponent,
    RHDadosConfigFichaModuleComponent,
    RhEstatisticasExclusaoModalComponent,
    RhEstatisticasModuleComponent,
    RhEstatisticasMultiConfigModalComponent,
    RhEstatisticasMultiModuleComponent,
    RHFolFerModuleComponent,
    RHGestaoFicheirosDMRATModalComponent,
    RHGestaoFicheirosDMRATModuleComponent,
    RHGozoFeriasModuleComponent,
    RhImpVenctModuleComponent,
    RHIntegracaoSalariosModuleComponent,
    RHManutencaoCamposModuleComponent,
    RhManutencaoModuleComponent,
    RhMapasSindicatosModuleComponent,
    RhPenhorasEditComponent,
    RhPenhorasListComponent,
    RHProcessamentoSalariosModuleComponent,
    RHProcSegSocialConsultEnviosModuleComponent,
    RHQPIRCTImportedListModalComponent,
    RHQPIRCTImportFileModalComponent,
    RHQPIRCTModuleComponent,
    RHRecolhaDadosEditComponent,
    RHRecolhaDadosListComponent,
    RHRecolhaSerieSubsidiosModuleComponent,
    RHRecolhaSerieSubsidiosSubsSavedModalComponent,
    RHRegistarEmpregadosSSConfigWSModalComponent,
    RHRegistarEmpregadosSSEditarContratoModalComponent,
    RHRegistarEmpregadosSSModuleComponent,
    RHRelatorioUnicoComponent,
    RHRelatorioUnicoConfigModalComponent,
    RHSalToTrfDadosTransfModalComponent,
    RhSalToTrfImprimirModalComponent,
    RHSalToTrfModuleComponent,
    RhSegSocialFolhaFeriasModuleComponent,
    RhTiposProcessamentoEditComponent,
    RHTransferenciasBancariasModuleComponent,
    RptConfisModuleComponent,
    SaftComponent,
    SaftContabConvertContasModalComponent,
    SaftContabImportConfigsModalComponent,
    SaftContabImportModalComponent,
    SaftContabModuleComponent,
    SaftContabProgressBarModalComponent,
    SaldosContasInventarioModalComponent,
    SegurEditComponent,
    SelecaoloteslinhaModalComponent,
    SeriesPorComunicarATModalComponent,
    ServicosComponent,
    SimpleLoginModalComponent,
    SimulaValorVencimentoComponent,
    SOLICEntityEditComponent,
    SOLICEntityListComponent,
    SplitViewerDocContabDigitalComponent,
    StoreWelcomeModalComponent,
    SubRefeicaoModalComponent,
    TaxasIvaComponent,
    TaxasIvaEditComponent,
    TaxasRetencaoEditComponent,
    TaxonomiasModuleComponent,
    TemplatesDetailComponent,
    TerceirosComponent,
    TiposRegistoCadastroEditComponent,
    TIRSEditComponent,
    TIRSListComponent,
    TransferenciaBancariaModalComponent,
    TransferenciasArmazensConfigModalComponent,
    TransferenciasArmazensDetailComponent,
    TransferenciasArmazensListComponent,
    TransferenciasArmazensModuleComponent,
    TransferenciasArmazensNewLineModalComponent,
    TransferenciasArmazensProcessarModalComponent,
    TrdocModalComponent,
    TrdocsCabsErrosModalComponent,
    TrdocsCabsModalComponent,
    TrdocsCabsWizardComponent,
    TrDocsCabsWizardModuleComponent,
    TrDocsLinhasModalComponent,
    TreeViewerComponent,
    TreeViewerNodeComponent,
    TrfBaConfigModalComponent,
    TrfBaGeraFicheiroModalComponent,
    TrfBaModuleComponent,
    TrfLancContasModuleComponent,
    TributAutonomasModuleComponent,
    TributAutonomasPrintModalComponent,
    TxtarEntityEditComponent,
    TxtclEntityEditComponent,
    TxtFaturacaoModalComponent,
    UIRouterViewIdentityComponent,
    UploadLogoModalComponent,
    UtilizadoresBasicEditComponent,
    UtilizadoresBasicListComponent,
    UtilizadoresInstallationComponent,
    UtilizadoresInstallationsComponent,
    UtlViatPatronalModalComponent,
    UtlViatPatronalModuleComponent,
    VendasAnoMesComponent,
    VendasAnoMesModuleComponent,
    VendasArtigoModuleComponent,
    VendasClienteModuleComponent,
    VerificacaoAcumuladosComponent,
    PRHTipoComEntityEditComponent
  ],
  providers: [
    provideLocaleId(),
    provideServiceWorker(),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors(HTTP_INTERCEPTORS), withNoXsrfProtection()),
    provideHammerConfig(),
    provideStorageConfigurations(),
    provideCGComponentsConfiguration(),
    provideAppInitialization(),
    provideDevExpressDataGridConfiguration(),
    provideAmaliaIntentHandlers()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private readonly _injector: Injector,
    private readonly _translateService: TranslateService,
    private readonly _plCompsService: PlCompsService,
    private readonly _plFormatService: PlFormatService,
    private readonly _plPdfOptionsService: PlPdfOptionsService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plUploadConfigService: PlUploadConfigService
  ) {
    CGInjector.injector = this._injector;
    this._translateService.onLangChange.subscribe(() => {
      applyScssI18n(this._plTranslateService);
    });
    this._plCompsService.setConfig({
      debug: isDebug(),
      plEditForm: {
        orientation: DEFAULT_FORM_ORIENTATION,
        modelValueEditTypes: [COMPONENT_NAME_INPUT_ENTITY]
      },
      plToolbar: {
        defaultInstanceId: DEFAULT_TOOLBAR_ID
      }
    });
    this._plFormatService.setFormat({
      decimalsSeparator: ',',
      decimalsLimit: 2,
      thousandsSeparator: '.'
    });
    this._plTranslateService.setTranslateFn(this._fnTranslateFn);
    const withCredentials = !isProduction();
    this._plPdfOptionsService.withCredentials = withCredentials;
    this._plUploadConfigService.set({withCredentials: withCredentials, responseType: 'json'});
    if (NOTIFICATION_CENTER_ENABLED) {
      // Initialize service
      this._injector.get<NotificationCenterService>(NotificationCenterService);
    }
    // Initialize feature flag service to start fetching flags
    this._injector.get<FeatureFlagService>(FeatureFlagService);
  }

  private _translateFn(key: string | number | Array<string | number>, interpolateParams?: object): string {
    if (!key && key !== 0) {
      return '';
    }
    const translatedValue: string = this._translateService.instant(key.toString(ERadix.Decimal), interpolateParams);
    return isString(translatedValue) || isNumber(translatedValue) ? translatedValue : '';
  }

  private readonly _fnTranslateFn: (key: string | number | Array<string | number>, interpolateParams?: object) => string = (key: string | Array<string>, interpolateParams?: object) => {
    return this._translateFn(key, interpolateParams);
  };
}
