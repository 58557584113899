import {Component, Injector, Input, OnInit} from '@angular/core';
import {ModuloComponent} from '../../../../components/module/module.component';
import {EDelphiNumberTypes, IPlTableCallback, IPlTableDefinition, IPlToolbarItem, KEYCODES, PlAlertService} from 'pl-comps-angular';
import {EContabTipoOperacao, IContabAdvancedOperations, IContabAdvancedOperationsDocs} from '../contabAdvancedOperations.module.interface';
import {ContabAdvancedOperationsService} from '../contabAdvancedOperations.module.service';
import {MAX_STRING} from '../../../listagens/docscomerciasestatisticas/docsComerciaisEstatisticas.module.interface';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {HttpResponse} from '@angular/common/http';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnInitialized, IDevExpressDataGridEventOnRowExpanding} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonDocContabilidade} from '../../docscontabilidade/jsonDocContabilidade.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService} from '../../docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IJsonContabAdvancedOperationsDefaults} from '../jsonContabAdvancedOperations.module.interface';

@Component({
  selector: 'contab-advanced-operations',
  templateUrl: './contabAdvancedOperations.module.component.html'
})
export class ContabAdvancedOperationsModuleComponent extends ModuloComponent implements OnInit {
  @Input() public tipoOperacao: number;

  public readonly tiposOperacao: typeof EContabTipoOperacao;
  public readonly callbackTableDocsPermitidos: IPlTableCallback;
  public readonly callbackTableDocsNaoPermitidos: IPlTableCallback;
  public readonly definitionDocsPermitidos: IPlTableDefinition;
  public readonly definitionDocsNaoPermitidos: IPlTableDefinition;
  public readonly dataGridDefinitionDocsPermitidos: IDevExpressDataGrid;
  public readonly dataGridDefinitionDocsNaoPermitidos: IDevExpressDataGrid;
  public model: IContabAdvancedOperations;
  public collapsedPesqAvancada: boolean;
  public selectedKeys: Array<string>;

  private readonly _btnProcessar: IPlToolbarItem;
  private readonly _btnPesquisar: IPlToolbarItem;
  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private _dataGridInstanceDocsPermitidos: dxDataGrid<IContabAdvancedOperationsDocs, string>;
  private _dataGridInstanceDocsNaoPermitidos: dxDataGrid<IContabAdvancedOperationsDocs, string>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _contabAdvancedOperationsService: ContabAdvancedOperationsService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this.dataGridDefinitionDocsPermitidos = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'processado', dataType: 'boolean', caption: 'contabAdvancedOperations.list.fields.processado', width: 120},
        {dataField: 'nPeriodo', dataType: 'string', caption: 'contabAdvancedOperations.list.fields.nPeriodo'},
        {dataField: 'nDiario', dataType: 'number', caption: 'contabAdvancedOperations.list.fields.nDiario'},
        {dataField: 'nDoc', dataType: 'string', caption: 'contabAdvancedOperations.list.fields.nDoc'},
        {dataField: 'tipoAviso', dataType: 'string', caption: 'contabAdvancedOperations.list.fields.tipoAviso'}
      ],
      dataSource: [],
      export: {filename: 'contabAdvancedOperations.fields.listaDocsPermitidos'},
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateDocsPermitidosDocContabilidade'},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      keyExpr: 'extPocCabID',
      remoteOperations: false
    };
    this.dataGridDefinitionDocsNaoPermitidos = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nPeriodo', dataType: 'string', caption: 'contabAdvancedOperations.list.fields.nPeriodo'},
        {dataField: 'nDiario', dataType: 'number', caption: 'contabAdvancedOperations.list.fields.nDiario'},
        {dataField: 'nDoc', dataType: 'string', caption: 'contabAdvancedOperations.list.fields.nDoc'},
        {dataField: 'tipoErroNaoPermitido', dataType: 'string', caption: 'contabAdvancedOperations.list.fields.tipoErroNaoPermitido'}
      ],
      dataSource: [],
      export: {filename: 'contabAdvancedOperations.fields.listaDocsNaoPermitidos'},
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateDocsNaoPermitidosDocContabilidade'},
      keyExpr: 'extPocCabID',
      remoteOperations: false
    };
    this.selectedKeys = [];
    this.tiposOperacao = EContabTipoOperacao;
    this.callbackTableDocsPermitidos = {};
    this.callbackTableDocsNaoPermitidos = {};
    this._btnProcessar = {
      id: 'processar',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-gear"></i>',
      class: 'btn-success',
      caption: 'Processar',
      visible: false,
      click: () => this._processaAlteracao()
    };
    this._btnPesquisar = {
      id: 'pesquisar',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-search"></i>',
      class: 'btn-primary',
      caption: 'global.btn.search',
      visible: true,
      click: () => this._pesquisar()
    };
    this.collapsedPesqAvancada = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnProcessar);
    this.toolbar.addButton(this._btnPesquisar);
    switch (this.tipoOperacao) {
      case EContabTipoOperacao.Periodo:
        this._initModelPeriodo();
        break;
      case EContabTipoOperacao.Diario:
        this._initModelDiario();
        break;
      case EContabTipoOperacao.Anulacao:
        this._initModelAnulacao();
        break;
    }
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IContabAdvancedOperationsDocs, string>, tableName: string): void {
    if (tableName === 'docsPermitidos') {
      this._dataGridInstanceDocsPermitidos = component;
    }
    if (tableName === 'docsNaoPermitidos') {
      this._dataGridInstanceDocsNaoPermitidos = component;
    }
  }

  public onContentReady(tableName: string): void {
    if (tableName === 'docsPermitidos') {
      this.dataGridInstanceDocsPermitidos.endCustomLoading();
    }
    if (tableName === 'docsNaoPermitidos') {
      this.dataGridInstanceDocsNaoPermitidos.endCustomLoading();
    }
  }

  public onRowExpanding(event: IDevExpressDataGridEventOnRowExpanding<IContabAdvancedOperationsDocs, string>, tableName: string): void {
    const dGInstance: dxDataGrid<IContabAdvancedOperationsDocs, string> = tableName === 'docsPermitidos' ? this.dataGridInstanceDocsPermitidos : this.dataGridInstanceDocsNaoPermitidos;
    const dGDefinition: IDevExpressDataGrid = tableName === 'docsPermitidos' ? this.dataGridDefinitionDocsPermitidos : this.dataGridDefinitionDocsNaoPermitidos;

    const items: Array<IContabAdvancedOperationsDocs> = dGInstance.getDataSource().items();
    const index: number = items.findIndex((item: IContabAdvancedOperationsDocs) => item.extPocCabID === event.key);
    if (index !== -1 && !dGDefinition.dataSource[index]._docContabilidade) {
      this._serviceDocsContabilidade.get({id: (<IContabAdvancedOperationsDocs>dGDefinition.dataSource[index]).extPocCabID}).then((response: HttpResponse<IJsonDocContabilidade>) => {
        dGDefinition.dataSource[index]._docContabilidade = response.body;
      });
    }
  }

  public dePeriodoChanged(periodo: string): void {
    this.model.dePeriodo = periodo;
    this.model.atePeriodo = periodo;
  }

  public readonly fnKeydownToPesquisar = (value: string, event: KeyboardEvent): void => {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      const button: HTMLElement = this._document.querySelector('button[data-item-id="pesquisar"]');
      if (button) {
        setTimeout(() => {
          button.focus();
        });
      }
    }
  };

  public get dataGridInstanceDocsPermitidos(): dxDataGrid {
    return this._dataGridInstanceDocsPermitidos;
  }

  public get dataGridInstanceDocsNaoPermitidos(): dxDataGrid {
    return this._dataGridInstanceDocsNaoPermitidos;
  }

  private _initModelPeriodo(): void {
    this.model = {
      operacao: this.tipoOperacao,
      dePeriodo: '',
      atePeriodo: '',
      deNDiario: 0,
      ateNDiario: EDelphiNumberTypes.MaxSmallInt,
      deNDoc: '',
      ateNDoc: MAX_STRING,
      periodoDestino: '',
      diarioDestino: 0,
      listaDocsNaoPermitidos: [],
      listaDocsPermitidos: []
    };
    this._getDefaults();
  }

  private _initModelDiario(): void {
    this.model = {
      operacao: this.tipoOperacao,
      dePeriodo: '',
      atePeriodo: '',
      deNDiario: 0,
      ateNDiario: EDelphiNumberTypes.MaxSmallInt,
      deNDoc: '',
      ateNDoc: MAX_STRING,
      periodoDestino: '',
      diarioDestino: 0,
      listaDocsNaoPermitidos: [],
      listaDocsPermitidos: []
    };
    this._getDefaults();
  }

  private _initModelAnulacao(): void {
    this.model = {
      operacao: this.tipoOperacao,
      dePeriodo: '',
      atePeriodo: MAX_STRING,
      deNDiario: 0,
      ateNDiario: EDelphiNumberTypes.MaxSmallInt,
      deNDoc: '',
      ateNDoc: MAX_STRING,
      periodoDestino: '',
      diarioDestino: 0,
      listaDocsNaoPermitidos: [],
      listaDocsPermitidos: []
    };
  }

  private _processaAlteracao(): Promise<void> {
    if (!this.selectedKeys.length) {
      this._plAlertService.error(this._translateService.instant('contabAdvancedOperations.messages.temQueSeleccionar'));
      return Promise.resolve();
    }

    let warningMessage = '';
    switch (this.tipoOperacao) {
      case EContabTipoOperacao.Periodo:
      case EContabTipoOperacao.Diario:
        warningMessage = this._translateService.instant('contabAdvancedOperations.messages.warning', {periodo: this.model.periodoDestino});
        break;
      case EContabTipoOperacao.Anulacao:
        warningMessage = this._translateService.instant('contabAdvancedOperations.messages.warningAnular');
        break;
    }
    return this._cgModalService.showOkCancel('Aviso', warningMessage).then(() => {
      for (const selectedKey of this.selectedKeys) {
        const index: number = this.model.listaDocsPermitidos.findIndex((item: IContabAdvancedOperationsDocs) => item.extPocCabID === selectedKey);
        if (index !== -1) {
          this.model.listaDocsPermitidos[index].seleccionado = true;
        }
      }
      return this._contabAdvancedOperationsService.processaAlteracao(this.model).then((response: HttpResponse<IContabAdvancedOperations>) => {
        this.model = response.body;
        this._plAlertService.success(this._translateService.instant('contabAdvancedOperations.messages.success'));
        this.dataGridDefinitionDocsPermitidos.dataSource = this.model.listaDocsPermitidos.filter((item: IContabAdvancedOperationsDocs) => !item.processado);
        this.dataGridDefinitionDocsNaoPermitidos.dataSource = this.model.listaDocsNaoPermitidos;
        this.selectedKeys = [];
      });
    });
  }

  private _pesquisar(): Promise<void> {
    if (this.dataGridInstanceDocsPermitidos) {
      this.dataGridInstanceDocsPermitidos.beginCustomLoading(undefined);
    }
    if (this.dataGridInstanceDocsNaoPermitidos) {
      this.dataGridInstanceDocsNaoPermitidos.beginCustomLoading(undefined);
    }
    return this._contabAdvancedOperationsService
      .pesquisar(this.model)
      .then((response: HttpResponse<IContabAdvancedOperations>) => {
        this.model = response.body;
        this._btnProcessar.visible = true;
        this.dataGridDefinitionDocsPermitidos.dataSource = this.model.listaDocsPermitidos.filter((item: IContabAdvancedOperationsDocs) => !item.processado);
        this.dataGridDefinitionDocsNaoPermitidos.dataSource = this.model.listaDocsNaoPermitidos;
        this.selectedKeys = [];
      })
      .finally(() => {
        if (this.dataGridInstanceDocsPermitidos) {
          this.dataGridInstanceDocsPermitidos.endCustomLoading();
        }
        if (this.dataGridInstanceDocsNaoPermitidos) {
          this.dataGridInstanceDocsNaoPermitidos.endCustomLoading();
        }
      });
  }

  private _getDefaults(): void {
    this._contabAdvancedOperationsService.getDefaults().then((response: HttpResponse<IJsonContabAdvancedOperationsDefaults>) => {
      this.model.dePeriodo = response.body.dePeriodo;
      this.model.atePeriodo = response.body.dePeriodo;
    });
  }
}
