<div class="modelo22-contasdef-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'modelo22.modal.rosto.contasdef.title'"></h5>
  </div>

  <div class="modal-body">
    <label [translate]="'modelo22.modal.rosto.contasdef.campo'" [translateParams]="{campo: campo}"></label>
    <pl-group>
      <pl-group>
        <label [translate]="'modelo22.modal.rosto.contasdef.nContaDe'"></label>
        <edit>
          <entity-autocomplete entity="pocs" attrName="nContaDe" [model]="nContaPocDe" (selectedKeyChange)="nContaDeChanged($event)" output="key"> </entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'modelo22.modal.rosto.contasdef.nContaAte'"></label>
        <edit>
          <entity-autocomplete entity="pocs" attrName="nContaAte" [model]="nContaPocAte" (selectedKeyChange)="nContaPocAte.nConta = $event" output="key"> </entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <pl-button klass="btn btn-sm btn-success action-add" (evtClicked)="addLine()" [promise]="promise">
          <i class="fa fa-fw fa-plus"></i>&nbsp;<span [translate]="'modelo22.modal.rosto.contasdef.addline'"></span>
        </pl-button>
      </pl-group>
    </pl-group>

    <pl-group>
      <label [translate]="'modelo22.modal.rosto.contasdef.perio'"></label>
      <edit class="d-flex">
        <pl-edit type="radio" attrName="perioAte0" [model]="perioAte" (modelChange)="perioAte = $event" [properties]="{value: '121', label: 'modelo22.modal.rosto.contasdef.deznormal'}"> </pl-edit>
        <pl-edit type="radio" attrName="perioAte1" [model]="perioAte" (modelChange)="perioAte = $event" [properties]="{value: '122', label: 'modelo22.modal.rosto.contasdef.primeiroence'}"> </pl-edit>
        <pl-edit type="radio" attrName="perioAte2" [model]="perioAte" (modelChange)="perioAte = $event" [properties]="{value: '123', label: 'modelo22.modal.rosto.contasdef.segence'}"> </pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'modelo22.modal.rosto.contasdef.sinal'"></label>
      <edit class="d-flex">
        <pl-edit type="radio" attrName="sinal0" [model]="sinal" (modelChange)="sinal = $event" [properties]="{value: true, label: 'modelo22.modal.rosto.contasdef.positivo'}"> </pl-edit>
        <pl-edit type="radio" attrName="sinal1" [model]="sinal" (modelChange)="sinal = $event" [properties]="{value: false, label: 'modelo22.modal.rosto.contasdef.negativo'}"> </pl-edit>
      </edit>
    </pl-group>

    <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="contasdef" [dataSource]="dataGridDefinition.dataSource">
      <div *dxTemplate="let item of 'cellTemplateBtnContasDef'">
        <button type="button" class="btn btn-danger btn-xs action-remove-line" (click)="removeLine(item.data)" plPromise>
          <i class="fa fa-fw fa-minus"></i>
        </button>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span> </pl-button>

    <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
