<div class="acrescimosencargosferias-multiempresadetail-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'acrescimosencargosferias.modals.multiempresadetail.title'"></h5>
  </div>

  <div class="modal-body">
    <acrescimos-enc-ferias-single [anoEncargo]="anoEncargo" [nEmpresa]="nEmpresa" [fromMultiEmpresa]="true" (evtChangedDataSource)="dataSourceChanged($event)"></acrescimos-enc-ferias-single>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light btn-sm action-dismiss" (evtClicked)="close()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
  </div>
</div>
