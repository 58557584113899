<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'debDiretosSEPA.strings.ddsepaEmissaoPorContaCorrente'"></h4>
</div>

<div class="modal-body">
  <pl-nav-wizard [onFinalize]="fnFinalize" [beforeStepChange]="fnBeforeStepChange" [callback]="plWizardCallback" (evtStepChange)="wizardStepChange($event)">
    <pl-nav-wizard-step
      [stepId]="wizGeraFicheiroDDStepId"
      icon="fa-magic fa-fw"
      [caption]="'debDiretosSEPA.strings.gerarFicheiro'"
      [captionBtnGoForward]="'debDiretosSEPA.strings.gerar'"
      [validator]="fnValidateStepRecords"
      [complete]="false">
      <div *plNavWizardStepContent>
        <div class="d-flex align-items-center gap-1">
          <label [translate]="'debDiretosSEPA.strings.dataVencimentoAte'"></label>
          <pl-edit style="max-width: 150px" type="date" [(model)]="model.vencimentoAte"></pl-edit>
          <pl-button type="button" klass="btn-sm btn-primary" [onClick]="fnSearch"><i class="fa fa-fw fa-search me-1" plPromise></i><span [translate]="'global.btn.search'"></span></pl-button>
        </div>
        <hr />
        <pl-split-view [initialSizeRight]="25" (evtSizeValuesChanged)="splitSizeValuesChanged()">
          <div *plSplitViewLeftSide>
            <h5 class="ddsepa-title" [translate]="'debDiretosSEPA.strings.registos'"></h5>
            <dx-data-grid
              [cgDxDataGrid]="dataGridListaAIntervirDefinition"
              [dataSource]="dataGridListaAIntervirDefinition.dataSource"
              cgDxDataGridInstanceName="dataGridListaAIntervir"
              (onContentReady)="onDataGridListaAIntervirContentReady()"
              (onSelectionChanged)="onDataGridListaAIntervirSelectionChanged()"
              (onInitialized)="onDataGridListaAIntervirDefinitionInitialized($event)">
            </dx-data-grid>
          </div>

          <div *plSplitViewRightSide>
            <h5 class="ddsepa-title" [translate]="'debDiretosSEPA.strings.resumoPorContaCorrente'"></h5>
            <dx-data-grid
              [cgDxDataGrid]="dataGridResumoProcessaDefinition"
              [dataSource]="dataGridResumoProcessaDefinition.dataSource"
              cgDxDataGridInstanceName="dataGridResumoProcessa"
              (onInitialized)="onDataGridResumoProcessaDefinitionInitialized($event)">
            </dx-data-grid>
          </div>
        </pl-split-view>
        <hr />
        <div class="text-end">
          <div class="align-items-center gap-1" style="display: inline-flex">
            <label [translate]="'debDiretosSEPA.strings.dataLiquidacao'"></label>
            <pl-edit style="max-width: 150px" type="date" [model]="model.dataLiquidacaoFRST" (modelChange)="dataLiquidacaoFRSTChanged($event)"></pl-edit>
            <label [translate]="'debDiretosSEPA.strings.dataLiquidacaoFirst'"></label>
            <pl-edit style="max-width: 150px" type="date" [model]="model.dataLiquidacaoRCUR" (modelChange)="dataLiquidacaoRCURChanged($event)"></pl-edit>
          </div>
        </div>
      </div>
    </pl-nav-wizard-step>
    <pl-nav-wizard-step [stepId]="wizResumoFicheirosGeradosStepId" icon="fa-file-text-o fa-fw" [caption]="'debDiretosSEPA.strings.resumo'" [hidePrevious]="true">
      <div *plNavWizardStepContent>
        <pl-tabs [(activeId)]="resumoFicheirosGeradosActiveTabId">
          <pl-tab [id]="resumoFicheirosGeradosTab" caption="debDiretosSEPA.strings.ficheirosGerados">
            <div *plTabContent>
              <dx-data-grid [cgDxDataGrid]="dataGridFicheirosGeradosDefinition" [dataSource]="dataGridFicheirosGeradosDefinition.dataSource" cgDxDataGridInstanceName="dataGridFicheirosGerados">
                <div *dxTemplate="let item of 'cellTemplateActions'">
                  <pl-button *ngIf="item.data.fileName?.length" klass="btn btn-xs btn-primary" [onClick]="fnDownloadFicheiro(item.data.nLoteFicheiro)">
                    <i class="fa fa-fw fa-download" aria-hidden="true"></i>
                  </pl-button>
                </div>
              </dx-data-grid>
            </div>
          </pl-tab>
          <pl-tab [id]="resumoFicheirosGeradosErrosTab" caption="">
            <div *plTabTitle>
              <i class="fa fa-fw fa-times-circle me-1"></i><span [translate]="'debDiretosSEPA.strings.errosEAvisos'"></span>
              <span class="badge ms-2" [translate]="listaErrosEAvisos.length.toString()"></span>
            </div>
            <div *plTabContent>
              <dx-data-grid [cgDxDataGrid]="dataGridErrosEAvisosDefinition" [dataSource]="dataGridErrosEAvisosDefinition.dataSource" cgDxDataGridInstanceName="dataGridErrosEAvisos"></dx-data-grid>
            </div>
          </pl-tab>
        </pl-tabs>
      </div>
    </pl-nav-wizard-step>
    <pl-nav-wizard-step stepId="notificao" icon="fa-bell fa-fw" [caption]="'debDiretosSEPA.strings.notificacao'">
      <div *plNavWizardStepContent>
        <h5 class="ddsepa-title" [translate]="'debDiretosSEPA.strings.notificarDevedores'"></h5>
        <dx-data-grid
          [cgDxDataGrid]="dataGridNotificaoDefinition"
          [dataSource]="dataGridNotificaoDefinition.dataSource"
          cgDxDataGridInstanceName="dataGridNotificao"
          (onContentReady)="onDataGridNotificaoContentReady()"
          (onInitialized)="onDataGridNotificaoInitialized($event)">
        </dx-data-grid>
      </div>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
