import {Directive, Input, TemplateRef} from '@angular/core';
import {ICGTableItemTemplateContext, TPlTableAlign} from './table.interface';

@Directive({
  selector: 'ng-template[itemActions]'
})
export class PlTableItemActionsDirective {
  private _actionsAlign: TPlTableAlign;

  constructor(public readonly templateRef: TemplateRef<ICGTableItemTemplateContext>) {}

  public get actionsAlign(): TPlTableAlign {
    return this._actionsAlign;
  }

  @Input()
  public set itemActionsAlign(value: TPlTableAlign) {
    if (value === 'left' || value === 'right') {
      this._actionsAlign = value;
    }
  }
}
