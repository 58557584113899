import type dxDataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import {LoadOptions} from 'devextreme/data';
import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {copy, PlAlertService} from 'pl-comps-angular';
import {AuthService} from '../../../../services/auth/auth.service';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ChooseRolesModalComponent} from '../modals/chooserolesmodal/chooseRoles.modal.component';
import {devExpressDataGridFiltersToQueryFilter, devExpressDataGridSortToOrder} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {DevExpressDatagridPaging} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.paging';
import {ENTITY_NAME_DGEMPS, IDGEMPSEntityService} from '../../../../entities/dgemps/dgemps.entity.interface';
import {ENTITY_NAME_USERS, IUtilizadoresUserRole} from '../../../utilizadores/utilizadores.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IDevExpressDataGrid, IDevExpressDataGridLoadResult} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IEmpregado, IJsonDGEMP} from '../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonErpUser, IJsonUser, IJsonUserEmpresa, IJsonUserNew, IJsonUserRole} from '../../../../services/account/jsonUserApi.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';

@Component({
  selector: 'colaboradores-empregados',
  templateUrl: './empregados.module.component.html'
})
export class EmpregadosComponent extends ModuloComponent implements OnInit {
  public readonly dataGridDefinition: IDevExpressDataGrid;

  private readonly _serviceUsers: IEntityService<IJsonUserEmpresa>;
  private readonly _serviceDGEMPS: IDGEMPSEntityService;
  private _dataGridInstance: dxDataGrid<IEmpregado, number>;
  private _dataGridPaging: DevExpressDatagridPaging<IEmpregado, number>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _authService: AuthService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columns: [
        {dataField: 'codEmp', dataType: 'number', caption: 'dgemps.fields.codEmp'},
        {dataField: 'nome', dataType: 'string', caption: 'dgemps.fields.nome'},
        {dataField: 'apelido', dataType: 'string', caption: 'dgemps.fields.apelido'},
        {dataField: 'rua', dataType: 'string', caption: 'dgemps.fields.rua', visible: false},
        {dataField: 'morada', dataType: 'string', caption: 'dgemps.fields.morada', visible: false},
        {dataField: 'codPostal', dataType: 'string', caption: 'dgemps.fields.codPostal', visible: false},
        {dataField: 'nomeLocalida', dataType: 'string', caption: 'dgemps.fields.nomeLocalida', visible: false},
        {dataField: 'email', dataType: 'string', caption: 'dgemps.fields.email'},
        {dataField: 'nTelefone', dataType: 'string', caption: 'dgemps.fields.nTelefone', visible: false},
        {dataField: 'telemovelPart1', dataType: 'string', caption: 'dgemps.fields.telemovelPart1', visible: false},
        {dataField: 'ativo', dataType: 'boolean', caption: 'dgemps.fields.ativo', filterValue: true},
        {dataField: 'actions', type: 'actions', cellTemplate: 'actions', width: '50px'}
      ],
      dataSource: new CustomStore({
        load: (options: LoadOptions<IEmpregado>) => this._dataGridSource(options),
        key: 'codEmp'
      }),
      remoteOperations: {
        filtering: true,
        grouping: false,
        groupPaging: false,
        paging: true,
        sorting: true,
        summary: false
      },
      export: {filename: 'Colaboradores'}
    };
    this._serviceUsers = this._entityServiceBuilder.build(ENTITY_NAME_USERS);
    this._serviceDGEMPS = this._entityServiceBuilder.build(ENTITY_NAME_DGEMPS);
  }

  public onDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized<IEmpregado, number>): void {
    this._dataGridInstance = component;
  }

  public readonly fnCreateUser: (empregado: IEmpregado) => () => Promise<void> = (empregado: IEmpregado) => (): Promise<void> => this._createUser(empregado);

  private _dataGridSource(loadOptions: LoadOptions): Promise<IDevExpressDataGridLoadResult<IEmpregado>> {
    let search: string;
    if (loadOptions.filter) {
      search = devExpressDataGridFiltersToQueryFilter(this._dataGridInstance, loadOptions.filter);
    }
    const order: string = loadOptions.sort ? devExpressDataGridSortToOrder(loadOptions.sort) : undefined;
    if (!this._dataGridPaging) {
      this._dataGridPaging = new DevExpressDatagridPaging(this._dataGridInstance);
    }
    const {page, perPage} = this._dataGridPaging.paginate(loadOptions);
    return this._serviceDGEMPS.query({pesquisa: search, ordena: order, pagina: page, porpagina: perPage}).then((response: THttpQueryResponse<IJsonDGEMP>) => {
      const result: IDevExpressDataGridLoadResult<IEmpregado> = this._dataGridPaging.processResult(response);
      const filterUsers: Array<string> = [];
      for (const dgemp of result.data) {
        if (!dgemp.ativo) {
          dgemp.ativo = false;
        }
        filterUsers.push(`email=${dgemp.email}`);
      }
      const searchUsers: string = filterUsers.join('|');
      if (searchUsers) {
        return this._serviceUsers.query({pesquisa: searchUsers}).then((responseUsers: THttpQueryResponse<IJsonUser>) => {
          const usersEmails: Array<string> = responseUsers.body.list.map((user: IJsonUser) => user.email);
          if (usersEmails.length) {
            for (const item of result.data) {
              if (usersEmails.includes(item.email)) {
                item.haveUser = true;
              }
            }
          }
          return result;
        });
      }
      return result;
    });
  }

  private _createUser(empregado: IEmpregado): Promise<void> {
    return this._serviceDGEMPS.getLastPortaisReg().then((response: HttpResponse<string>) => {
      const modalInstance = this._cgModalService.showVanilla(ChooseRolesModalComponent);
      modalInstance.componentInstance.selectedDefaultRoles = response.body.split(',');
      return modalInstance.result.then((roles: Array<IUtilizadoresUserRole>) => {
        const newUser: Partial<IJsonUserNew> = this._generateNewUser(empregado, roles);
        return this._serviceUsers
          .post({body: newUser})
          .then(() => {
            const portais: Array<string> = roles.map((role) => role.role.valueOf());
            this._serviceDGEMPS.saveLastPortaisReg(portais);
            empregado.haveUser = true;
            this._authService
              .sendInstructionsNewUser(newUser.email)
              .then(() => {
                this._plAlertService.success('users.instrucoes.email');
              })
              .catch((reason: unknown) => {
                this._logger.error(reason);
                this._plAlertService.error('users.instrucoes.emailerro');
              });
          })
          .catch((reason: unknown) => {
            this._logger.error(reason);
            this._plAlertService.error('users.instrucoes.emailerro');
          });
      });
    });
  }

  private _generateNewUser(empregado: IEmpregado, roles: Array<IJsonUserRole>): Partial<IJsonUserNew> {
    const erp: IJsonErpUser = copy(this.session.erp);
    erp.cgID = undefined;
    erp.codEmp = empregado.codEmp;
    erp.nomeEmpregado = empregado.nome;
    erp.rolesAcess = roles;
    erp.rolesNotAcess = [];
    return {
      username: empregado.email,
      firstName: empregado.nome,
      lastName: empregado.apelido,
      email: empregado.email,
      active: true,
      erp: erp,
      erps: [erp],
      roles: roles
    };
  }
}
