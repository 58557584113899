<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'importadorEfatura.modal.createNewContaCorrente.title'"></h4>
</div>

<div class="modal-body entity-detail-form">
  <pl-form>
    <pl-group>
      <pl-group>
        <label [translate]="'importadorEfatura.modal.createNewContaCorrente.body.contrib'"></label>
        <edit>
          <div class="form-control-align">{{ model.nContribuint }}</div>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'importadorEfatura.modal.createNewContaCorrente.body.nome'"></label>
        <edit>
          <div class="form-control-align">{{ model.nome }}</div>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'importadorEfatura.modal.createNewContaCorrente.body.radical'"></label>
        <edit>
          <entity-autocomplete entity="naturezasContab" [model]="model.naturezaContab" (modelChange)="model.naturezaContab = $event; naturezaContabChange($event)"> </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'importadorEfatura.modal.createNewContaCorrente.body.ncontacriar'"></label>
        <edit>
          <div class="form-control-align">{{ model.nConta }}</div>
        </edit>
      </pl-group>
    </pl-group>
  </pl-form>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-success" [onClick]="close"> <i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'global.btn.save'"></span> </pl-button>
  <pl-button klass="btn-light" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
</div>
