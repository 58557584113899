<div class="saldoscontasinventario-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'apuramentoresultados.modal.saldoscontinv.title'"></h5>
  </div>

  <div class="modal-body" [plPromise]="promise">
    <div class="d-flex me-2 gap-3">
      <div>
        <label [translate]="'global.text.year'"></label>
        <pl-autocomplete [source]="anosSource" [model]="selectedAno" (modelChange)="selectedAno = $event; anoChanged()" [allowInvalid]="false"> </pl-autocomplete>
      </div>
      <div>
        <label [translate]="'apuramentoresultados.modal.saldoscontinv.periodo'"></label>
        <pl-autocomplete [source]="periodosSource" [model]="saldosContasInventario.periodo" (modelChange)="saldosContasInventario.periodo = $event; periodoChanged()" [allowInvalid]="false">
        </pl-autocomplete>
      </div>
    </div>

    <hr />

    <dx-data-grid [cgDxDataGrid]="definitionCompras" [dataSource]="definitionCompras.dataSource" (onInitialized)="onInitializedCompras($event)" (onCellPrepared)="onCellPreparedCompras($event)">
    </dx-data-grid>

    <hr />

    <dx-data-grid [cgDxDataGrid]="definitionProdutos" [dataSource]="definitionProdutos.dataSource" (onInitialized)="onInitializedProdutos($event)" (onCellPrepared)="onCellPreparedProdutos($event)">
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
