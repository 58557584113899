<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'arquivodigital.clientconnect.deletedocsmodal.title'"></h4>
</div>

<div class="modal-body" [translate]="'arquivodigital.clientconnect.deletedocsmodal.message'" [translateParams]="{path: path}"></div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-danger action-submit" plAutoFocus (evtClicked)="close()"> <i class="fa fa-fw fa-trash"></i>&nbsp;<span [translate]="'global.btn.delete'"></span> </pl-button>

  <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
</div>
