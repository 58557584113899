import {Injectable} from '@angular/core';
import {
  IPlDynamicVisualsSecondaryClickAction,
  IPlDynamicVisualsSecondaryClickReferenceOptions,
  isArray,
  isEmpty,
  isObject,
  PlDynamicVisualsReference,
  PlDynamicVisualsSecondaryClickService,
  TNgClassSupportedTypes
} from 'pl-comps-angular';
import {DevExpressCentralGestContextMenuComponent} from '../../../components/devexpress/centralgest/contextmenu/devexpress.centralgest.contextmenu.component';

const DEV_EXPRESS_DATA_GRID_UI_CONTEXT_MENU_CSS_CLASS = 'cg-dev-express-data-grid-context-menu';

@Injectable({
  providedIn: 'root'
})
export class DevExpressDataGridUIService {
  constructor(private readonly _plDynamicVisualsSecondaryClickService: PlDynamicVisualsSecondaryClickService) {}

  public openContextMenuVanilla(
    hostElement: HTMLElement,
    actions: Array<IPlDynamicVisualsSecondaryClickAction>,
    options?: IPlDynamicVisualsSecondaryClickReferenceOptions
  ): PlDynamicVisualsReference<void> {
    const cssClass: TNgClassSupportedTypes = isObject(options)
      ? isArray(options.cssClass)
        ? [...options.cssClass.slice(), DEV_EXPRESS_DATA_GRID_UI_CONTEXT_MENU_CSS_CLASS]
        : options.cssClass instanceof Set
        ? new Set<string>([...Array.from(options.cssClass.values()), DEV_EXPRESS_DATA_GRID_UI_CONTEXT_MENU_CSS_CLASS])
        : !isEmpty(options.cssClass)
        ? `${String(options.cssClass)} ${DEV_EXPRESS_DATA_GRID_UI_CONTEXT_MENU_CSS_CLASS}`
        : DEV_EXPRESS_DATA_GRID_UI_CONTEXT_MENU_CSS_CLASS
      : DEV_EXPRESS_DATA_GRID_UI_CONTEXT_MENU_CSS_CLASS;
    return this._plDynamicVisualsSecondaryClickService.openMenuVanilla(
      hostElement,
      actions,
      {
        ...options,
        cssClass: cssClass
      },
      DevExpressCentralGestContextMenuComponent
    );
  }

  public openContextMenu(hostElement: HTMLElement, actions: Array<IPlDynamicVisualsSecondaryClickAction>, options?: IPlDynamicVisualsSecondaryClickReferenceOptions): Promise<void> {
    return this.openContextMenuVanilla(hostElement, actions, options).result;
  }
}
