<div class="acrescimosencargosferias-print-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'acrescimosencargosferias.modals.print.title'"></h5>
  </div>

  <div class="modal-body">
    <cg-card-panel #cardPanelCubo="cgCardPanel">
      <div *cgCardPanelContent>
        <pl-group>
          <pl-group>
            <label [translate]="'global.text.listing'"></label>
            <edit>
              <pl-edit type="reports_acrescimos_encargos_ferias" attrName="listagem" [(model)]="report"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'acrescimosencargosferias.modals.print.anoEncargo'"></label>
            <edit>
              <pl-edit type="cginteger" attrName="anoEncargo" [(model)]="header.anoEncargo"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'acrescimosencargosferias.modals.print.deCodEmp'"></label>
            <edit>
              <entity-autocomplete entity="dgempsfull" attrName="deCodEmp" [model]="header" (selectedKeyChange)="header.deCodEmp = $event" [fieldsMap]="{codEmp: 'deCodEmp'}" output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'acrescimosencargosferias.modals.print.ateCodEmp'"></label>
            <edit>
              <entity-autocomplete entity="dgempsfull" attrName="ateCodEmp" [model]="header.ateCodEmp" (selectedKeyChange)="header.ateCodEmp = $event" [fieldsMap]="{codEmp: 'ateCodEmp'}" output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'acrescimosencargosferias.modals.print.deCCusto'"></label>
            <edit>
              <entity-autocomplete
                entity="ccustos"
                attrName="deCCusto"
                [model]="header"
                (selectedKeyChange)="header.deCCusto = $event"
                [fieldsMap]="{nCCusto: 'deCCusto'}"
                [filter]="'encerrado=0'"
                output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'acrescimosencargosferias.modals.print.ateCCusto'"></label>
            <edit>
              <entity-autocomplete
                entity="ccustos"
                attrName="ateCCusto"
                [model]="header"
                (selectedKeyChange)="header.ateCCusto = $event"
                [fieldsMap]="{nCCusto: 'ateCCusto'}"
                [filter]="'encerrado=0'"
                output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'acrescimosencargosferias.modals.print.deReparticao'"></label>
            <edit>
              <pl-edit type="cginteger" attrName="deReparticao" [(model)]="header.deReparticao" [properties]="{validators: {required: {value: false}, min: {value: 0}}}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'acrescimosencargosferias.modals.print.ateReparticao'"></label>
            <edit>
              <pl-edit type="cginteger" attrName="ateReparticao" [(model)]="header.ateReparticao"> </pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-button klass="btn-primary btn-sm action-search" [onClick]="previsualizar"><i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span></pl-button>
      </div>
    </cg-card-panel>

    <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light btn-sm action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
