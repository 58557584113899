import {APP_INITIALIZER, EnvironmentProviders, Injector, makeEnvironmentProviders} from '@angular/core';
import {StateRegistry} from '@uirouter/core';
import {DOCUMENT} from '@angular/common';
import {Logger, PlEditRegistryService} from 'pl-comps-angular';
import {AppService} from '../core/services/app/app.service';
import {CacheBusterService} from '../core/services/cachebuster.service';
import {configApplication} from './app.config';
import {configCacheBuster} from './cachebuster.config';
import {configDataSources} from './datasources.config';
import {configEdits} from './edits.config';
import {configEntitiesMaps} from './entities.maps.config';
import {configEntities} from './entities.config';
import {configModules} from './modules.config';
import {configNgBootstrapModalConfig, configNgBootstrapTooltipConfig} from './ng-bootstrap.config';
import {configReportsMaps} from './reports.maps.config';
import {configReports} from './reports.config';
import {configRuntime} from './runtime.config';
import {configTests} from './tests.config';
import {configUIRouterApp} from './uirouter.config';
import {DataSourcesRegistryService} from '../core/components/datasource/datasources.registry.service';
import {EntityRegistryService} from '../core/components/entity/entity.registry.service';
import {isTest} from './constants';
import {ModuleRegistryService} from '../core/components/module/module.registry.service';
import {NgbModalConfig, NgbTooltipConfig} from '@ng-bootstrap/ng-bootstrap';
import {PortalsService} from '../core/services/portals/portals.service';
import {ReportsRegistryService} from '../core/components/reports/reports.registry.service';
import {TestsService} from '../core/services/tests/tests.service';

async function init(injector: Injector): Promise<void> {
  const ngbModalConfig: NgbModalConfig = injector.get<NgbModalConfig>(NgbModalConfig);
  const ngbTooltipConfig: NgbTooltipConfig = injector.get<NgbTooltipConfig>(NgbTooltipConfig);
  const document: Document = injector.get<Document>(DOCUMENT, null, {optional: true});
  const logger: Logger = injector.get<Logger>(Logger);
  const plEditRegistryService: PlEditRegistryService = injector.get<PlEditRegistryService>(PlEditRegistryService);
  const appService: AppService = injector.get<AppService>(AppService);
  const cacheBusterService: CacheBusterService = injector.get<CacheBusterService>(CacheBusterService);
  const dataSourcesRegistryService: DataSourcesRegistryService = injector.get<DataSourcesRegistryService>(DataSourcesRegistryService);
  const entityRegistryService: EntityRegistryService = injector.get<EntityRegistryService>(EntityRegistryService);
  const moduleService: ModuleRegistryService = injector.get<ModuleRegistryService>(ModuleRegistryService);
  const portalsService: PortalsService = injector.get<PortalsService>(PortalsService);
  const reportsRegistryService: ReportsRegistryService = injector.get<ReportsRegistryService>(ReportsRegistryService);
  const stateRegistry: StateRegistry = injector.get<StateRegistry>(StateRegistry);
  const testsService: TestsService = injector.get<TestsService>(TestsService);

  configNgBootstrapModalConfig(ngbModalConfig);
  configNgBootstrapTooltipConfig(ngbTooltipConfig);
  configCacheBuster(cacheBusterService);
  configEdits(plEditRegistryService);
  configModules(moduleService, logger);
  configEntities(entityRegistryService, logger);
  configEntitiesMaps(plEditRegistryService);
  configDataSources(dataSourcesRegistryService);
  configReports(reportsRegistryService);
  configReportsMaps(plEditRegistryService);
  configUIRouterApp(stateRegistry, portalsService);

  await configApplication(appService, document?.defaultView);
  await configRuntime(injector);

  if (isTest()) {
    configTests(testsService);
  }
}

export function provideAppInitialization(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [Injector],
      useFactory: (injector: Injector) => () => init(injector)
    }
  ]);
}
