<pl-form (evtSubmitted)="close()">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'crneg.modalCRNEGTitle'"></h4>
  </div>

  <div class="modal-body">
    <pl-group>
      <edit>
        <pl-multiselect [(model)]="excecoesList" [source]="allDGEMPS" key="nCodEmp" [template]="dgempsTemplate"></pl-multiselect>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button type="submit" klass="btn-primary action-save"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.apply'"></span> </pl-button>
    <pl-button type="button" klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</pl-form>
