<div class="card documento-faturacao">
  <div class="card-header">
    <div class="card-title">
      <span class="fatura-title"><strong>Nº: </strong>{{ recibo.cab.nDocAsStr }}</span>
      <button type="button" class="btn btn-primary btn-sm action-pdf" (click)="pdf()"><i class="fa fa-fw fa-file-pdf-o"></i>PDF</button>

      <div class="d-inline-block ms-1">
        <pl-tooltip [config]="configTooltip">
          <button type="button" class="btn btn-danger btn-sm action-anular" [disabled]="recibo.cab.origem !== typeDocContabilidadeOrigem.ERPCloud" (click)="anularRecibo()" *plTooltipContent plPromise>
            <i class="fa fa-fw fa-scissors" aria-hidden="true"></i>Anular
          </button>
        </pl-tooltip>
      </div>

      <span class="fatura-data"> <strong>Data: </strong>{{ recibo.cab.data | cgcDate }}</span>
    </div>
  </div>

  <div class="card-body card-documento">
    <div class="resumo documento-faturacao-resumo-header">
      <dl class="page-documento-header">
        <dt [translate]="'recibos.recibo.cab.nConta'">:</dt>
        <dd>{{ recibo.cab.nConta }}</dd>

        <dt [translate]="'recibos.recibo.cab.nome'">:</dt>
        <dd>{{ recibo.cab.nome }}</dd>

        <dt *ngIf="recibo.cab.nif" [translate]="'recibos.recibo.cab.nif'">:</dt>
        <dd *ngIf="recibo.cab.nif">{{ recibo.cab.nif }}</dd>

        <dt *ngIf="recibo.cab.descricao" [translate]="'recibos.recibo.cab.descricao'">:</dt>
        <dd *ngIf="recibo.cab.descricao">{{ recibo.cab.descricao }}</dd>
      </dl>
    </div>

    <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="tableDocsRecibos" [dataSource]="recibo.linhas"></dx-data-grid>

    <br />

    <div class="resumo-wrapper">
      <div class="resumo">
        <h4 class="page-header-cg" [translate]="'recibos.recibo.cab.resumo'"></h4>
        <dl class="page-invoice-amount">
          <dt [translate]="'recibos.recibo.cab.totalRecebido'">:</dt>
          <dd>{{ recibo.cab.totalReceber | cgcCurrency: config.valor : recibo.cab.abreviaturaMoeda : 'code' }}</dd>

          <dt [translate]="'recibos.recibo.cab.totalDescontos'">:</dt>
          <dd>{{ recibo.cab.totalDescontos | cgcCurrency: config.valor : recibo.cab.abreviaturaMoeda : 'code' }}</dd>

          <dt *ngIf="recibo.cab.valorEntregueCredito" [translate]="'recibos.recibo.cab.valorEntregueCredito'">:</dt>
          <dd *ngIf="recibo.cab.valorEntregueCredito">{{ recibo.cab.valorEntregueCredito | cgcCurrency: config.valor : recibo.cab.abreviaturaMoeda : 'code' }}</dd>
        </dl>
        <dl class="total-doc">
          <dt [translate]="'recibos.recibo.cab.total'">:</dt>
          <dd>{{ recibo.cab.total | cgcCurrency: config.valor : recibo.cab.abreviaturaMoeda : 'code' }}</dd>
        </dl>
      </div>
    </div>
  </div>
</div>
