<div class="container">
  <div class="row mb-2">
    <div class="col-md-12">
      <pl-group>
        <label [translate]="'recibosvencimentos.listagem'"></label>
        <edit>
          <pl-edit type="reports_recibossal" attrName="listagem" [model]="reportModel" (modelChange)="changedReport($event)"></pl-edit>
        </edit>
      </pl-group>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-md-12">
      <div class="d-flex align-items-center" style="gap: 15px">
        <div *ngIf="!isEmbed()">
          <pl-group>
            <label [translate]="'recibosvencimentos.todos'"></label>
            <edit>
              <pl-edit type="boolean" [model]="todosColaboradores" (modelChange)="changedTodos($event)"></pl-edit>
            </edit>
          </pl-group>
        </div>
        <div *ngIf="!isEmbed() && !todosColaboradores">
          <pl-group>
            <label [translate]="'recibosvencimentos.colaborador'"></label>
            <edit>
              <entity-autocomplete entity="dgempsfull" [model]="dgemp" (modelChange)="changedDGEMP($event)"></entity-autocomplete>
            </edit>
          </pl-group>
        </div>
        <div *ngIf="showMostraGrafico">
          <pl-group>
            <label [translate]="'recibosvencimentos.mostraGrafico'"></label>
            <edit>
              <pl-edit type="boolean" [model]="mostraGrafico" (modelChange)="changedMostraGrafico($event)"></pl-edit>
            </edit>
          </pl-group>
        </div>
      </div>
    </div>
  </div>
</div>

<cg-pdf-viewer [pdfSrc]="pdfUrl" [toolbarInstanceId]="instanceName" [hideToolbar]="true"></cg-pdf-viewer>
