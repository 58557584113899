<div class="listagens entity-detail-form">
  <cg-card-panel *ngIf="!temInputNartigo" #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-xl-3">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.datade'"></label>
              <edit>
                <pl-edit-datepicker [(model)]="filters.datade" (modelChange)="fnEmitFilter()" plAutoFocus></pl-edit-datepicker>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3">
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <pl-edit-datepicker [(model)]="filters.dataate" (modelChange)="fnEmitFilter()"></pl-edit-datepicker>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterConta">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.ncontade'"></label>
              <edit>
                <entity-autocomplete
                  entity="clifos"
                  [filter]="nContaFilter"
                  attrName="ncontade"
                  [selectedKey]="filters.ncontade"
                  (selectedKeyChange)="ncontadeChanged($event)"
                  output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterConta">
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete
                  entity="clifos"
                  [filter]="nContaFilter"
                  attrName="ncontaate"
                  [selectedKey]="filters.ncontaate"
                  (selectedKeyChange)="ncontaateChanged($event)"
                  output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.nartigode'"></label>
              <edit>
                <entity-autocomplete entity="artigos" attrName="nartigode" [selectedKey]="filters.nartigode" (selectedKeyChange)="nartigodeChanged($event)" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3">
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete entity="artigos" attrName="nartigoate" [selectedKey]="filters.nartigoate" (selectedKeyChange)="nartigoateChanged($event)" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterTipoArtigo">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.tipoartigode'"></label>
              <edit>
                <entity-autocomplete
                  entity="tiposartigo"
                  attrName="tipoartigode"
                  [selectedKey]="filters.tipoartigode"
                  (selectedKeyChange)="tipoartigodeChanged($event)"
                  output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterTipoArtigo">
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete
                  entity="tiposartigo"
                  attrName="tipoartigoate"
                  [selectedKey]="filters.tipoartigoate"
                  (selectedKeyChange)="tipoartigoateChanged($event)"
                  output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterGrFamilia">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.ngrfamiliade'"></label>
              <edit>
                <entity-autocomplete
                  entity="grandesfamilias"
                  attrName="ngrfamiliade"
                  [selectedKey]="filters.ngrfamiliade"
                  (selectedKeyChange)="ngrfamiliadeChanged($event)"
                  output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterGrFamilia">
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete
                  entity="grandesfamilias"
                  attrName="ngrfamiliaate"
                  [selectedKey]="filters.ngrfamiliaate"
                  (selectedKeyChange)="ngrfamiliaateChanged($event)"
                  output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterFamilia">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.nfamiliade'"></label>
              <edit>
                <entity-autocomplete entity="familias" attrName="nfamiliade" [selectedKey]="filters.nfamiliade" (selectedKeyChange)="nfamiliadeChanged($event)" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterFamilia">
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete entity="familias" attrName="nfamiliaate" [selectedKey]="filters.nfamiliaate" (selectedKeyChange)="nfamiliaateChanged($event)" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterSubFamilia">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.nsubfamiliade'"></label>
              <edit>
                <entity-autocomplete
                  entity="subfamilias"
                  attrName="nsubfamiliade"
                  [selectedKey]="filters.nsubfamiliade"
                  (selectedKeyChange)="nsubfamiliadeChanged($event)"
                  output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterSubFamilia">
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete
                  entity="subfamilias"
                  attrName="nsubfamiliaate"
                  [selectedKey]="filters.nsubfamiliaate"
                  (selectedKeyChange)="nsubfamiliaateChanged($event)"
                  output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterClasseArtigo">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.classede'"></label>
              <edit>
                <entity-autocomplete entity="artigoclasses" attrName="classede" [selectedKey]="filters.classede" (selectedKeyChange)="classedeChanged($event)" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterClasseArtigo">
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete entity="artigoclasses" attrName="classeate" [selectedKey]="filters.classeate" (selectedKeyChange)="classeateChanged($event)" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterCategoriaArtigo">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.categoriade'"></label>
              <edit>
                <entity-autocomplete
                  entity="artigoclasses"
                  attrName="categoriade"
                  [selectedKey]="filters.categoriade"
                  (selectedKeyChange)="categoriadeChanged($event)"
                  output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterCategoriaArtigo">
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete
                  entity="categoriaartigo"
                  attrName="categoriaate"
                  [selectedKey]="filters.categoriaate"
                  (selectedKeyChange)="categoriaateChanged($event)"
                  output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterVendedor">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.nvendedorde'"></label>
              <edit>
                <entity-autocomplete entity="vendedores" attrName="nvendedorde" [selectedKey]="filters.nvendedorde" (selectedKeyChange)="nvendedordeChanged($event)" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterVendedor">
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete
                  entity="vendedores"
                  attrName="nvendedorate"
                  [selectedKey]="filters.nvendedorate"
                  (selectedKeyChange)="nvendedorateChanged($event)"
                  output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterZona">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.codzonade'"></label>
              <edit>
                <entity-autocomplete entity="zonas" attrName="codzonade" [selectedKey]="filters.codzonade" (selectedKeyChange)="codzonadeChanged($event)" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterZona">
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete entity="zonas" attrName="codzonaate" [selectedKey]="filters.codzonaate" (selectedKeyChange)="codzonaateChanged($event)" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterCentroCusto">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.ccustode'"></label>
              <edit>
                <entity-autocomplete entity="ccustos" attrName="ccustode" [selectedKey]="filters.ccustode" (selectedKeyChange)="ccustodeChanged($event)" output="key" [filter]="'encerrado=0'">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterCentroCusto">
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete entity="ccustos" attrName="ccustoate" [selectedKey]="filters.ccustoate" (selectedKeyChange)="ccustoateChanged($event)" output="key" [filter]="'encerrado=0'">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterProcesso">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.nrefprocessode'"></label>
              <edit>
                <entity-autocomplete
                  entity="processos"
                  attrName="nrefprocessode"
                  [selectedKey]="filters.nrefprocessode"
                  (selectedKeyChange)="nrefprocessodeChanged($event)"
                  output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterProcesso">
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete
                  entity="processos"
                  attrName="nrefprocessoate"
                  [selectedKey]="filters.nrefprocessoate"
                  (selectedKeyChange)="nrefprocessoateChanged($event)"
                  output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterDocfaList">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.docFa'"></label>
              <edit>
                <pl-select
                  [model]="docfalist"
                  [multiple]="true"
                  [source]="docFaSource"
                  [multipleCheckbox]="true"
                  [rowTemplate]="docFaTemplate"
                  [valueField]="'nDocFa'"
                  [output]="docFaOutput"
                  [selectableGroup]="true"
                  (modelChange)="changedDocfaList($event)"
                  appendTo="body"></pl-select>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterSoComMovimStock">
            <pl-group>
              <edit class="mt-3">
                <pl-edit type="checkbox" [(model)]="filters.socommovimstock" [properties]="{label: 'docsComerciaisEstatisticas.caption.socommovimstock'}"></pl-edit>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterGrupoConta">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.ididechavde'"></label>
              <edit>
                <entity-autocomplete entity="idide" attrName="ididechavde" [selectedKey]="filters.ididechavde" (selectedKeyChange)="idIdeChavDeChanged($event)" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterGrupoConta">
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete entity="idide" attrName="ididechavate" [selectedKey]="filters.ididechavate" (selectedKeyChange)="idIdeChavAteChanged($event)" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterClasse">
            <pl-group>
              <label [translate]="'docsComerciaisEstatisticas.caption.cliclde'"></label>
              <edit>
                <entity-autocomplete entity="clicls" attrName="cliclde" [selectedKey]="filters.cliclde" (selectedKeyChange)="cliclDeChanged($event)" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="optionShowFilterClasse">
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete entity="clicls" attrName="cliclAte" [selectedKey]="filters.cliclate" (selectedKeyChange)="cliclAteChanged($event)" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <pl-button klass="btn-sm btn-primary" id="btnPesquisar" [onClick]="fnPesquisar" data-focus>
              <i class="fa fa-search fa-fw"></i>&nbsp;<span [translate]="'global.btn.search'"></span>
            </pl-button>
          </div>
        </div>
      </pl-form>
    </div>
  </cg-card-panel>

  <br />

  <pl-tabs>
    <pl-tab id="geral" caption="docsComerciaisEstatisticas.tabs.geral">
      <div *plTabContent>
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinition"
          cgDxDataGridInstanceName="docsComerciaisEstatisticas"
          [dataSource]="dataGridDefinition.dataSource"
          (onInitialized)="onInitialized($event)"
          (onContentReady)="onContentReady()">
        </dx-data-grid>
      </div>
    </pl-tab>

    <pl-tab id="cubos" caption="docsComerciaisEstatisticas.tabs.cubo">
      <div *plTabContent>
        <dx-pivot-grid
          id="pivotgridEstComArtigo"
          [fieldChooser]="pivotGridDefinition.fieldChooser"
          [scrolling]="pivotGridDefinition.scrolling"
          [stateStoring]="pivotGridDefinition.stateStoring"
          [allowSortingBySummary]="pivotGridDefinition.allowSortingBySummary"
          [allowFiltering]="pivotGridDefinition.allowFiltering"
          [showBorders]="pivotGridDefinition.showBorders"
          [showRowGrandTotals]="pivotGridDefinition.showRowGrandTotals"
          [showRowTotals]="pivotGridDefinition.showRowTotals"
          [showColumnGrandTotals]="pivotGridDefinition.showColumnGrandTotals"
          [showColumnTotals]="pivotGridDefinition.showColumnTotals"
          [texts]="pivotGridDefinition.texts"
          [export]="pivotGridDefinition.export"
          [dataSource]="pivotGridDataSource"
          (onExporting)="onExporting($event)">
        </dx-pivot-grid>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
