import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {HttpResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {ENTITY_NAME_DOC_FAS, IDocFasEntityService} from '../../../docfas/docFas.entity.interface';
import {IDocumentosService} from '../../service/documentos.entity.service.interface';
import {EFACCBEmitidoDocDigital, IJsonDocComercial, IJsonDocComercialClifo} from '../../jsonDocComercial.entity.interface';
import {IJsonDocfa, IJsonDocfaReport} from '../../../docfas/jsonDocFa.entity.interface';
import {DefinicaoEmailService} from '../../../clifos/components/component/definicaoemails/definicaoEmail.service';
import {IJsonClifoExtraValue} from '../../../clifoextravalue/jsonClifoExtraValue.entity.interface';
import {IApiQueryResponse} from '../../../../services/api/api.service.interface';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {ETipoCertificadoFaturaEletronica} from '../../../../modules/faturacaoeletronicaconfiguracoes/jsonFaturacaoEletronicaConfiguracoes.module.interface';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {MODULE_NAME_FATURACAO_PUBLICA} from '../../../../modules/faturacaopublica/faturacaoPublica.module.interface';

@Component({
  selector: 'impdoc-modal',
  templateUrl: './impDoc.modal.component.html'
})
export class ImpdocModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public doc: IJsonDocComercial;
  @Input() public documentosService: IDocumentosService;
  @Input() public preview: boolean;

  public view: string;
  public url: string;
  public urlPreview: string;
  public form: FormGroupDirective;
  public nvias: number;
  public segundaVia: boolean;
  public email: string;
  public clifo: string;
  public rptName: string;
  public rptDescription: string;
  public reports: Array<IJsonDocfaReport>;
  public emitirNovaFaturaEletronica: boolean;
  public messageEmissaoFaturaEletronica: string;
  public semConfiguracao: boolean;
  public tipoDocEDeFaturaEletronica: boolean;
  public hintSemConfiguracao: string;
  private _nomeCertificado: string;

  private _docfas: IDocFasEntityService;
  private readonly _maintenanceFaturacaoPublica: IModuleMaintenanceInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _translateService: TranslateService,
    private readonly _definicaoEmailService: DefinicaoEmailService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService
  ) {
    super(_injector);
    this._maintenanceFaturacaoPublica = this._moduleMaintenanceService.build(MODULE_NAME_FATURACAO_PUBLICA);
  }

  public ngOnInit(): void {
    this._docfas = this._entityServiceBuilder.build<IJsonDocfa, IDocFasEntityService>(ENTITY_NAME_DOC_FAS);
    this.view = this.preview ? 'pdf' : 'vias';
    this.url = '';
    this.urlPreview = '';
    this.nvias = 1;
    this.reports = [];
    this.segundaVia = false;
    this.clifo = this.doc.clifo.nome;
    this._fillReports();
    if (this.preview) {
      this._getPdfUrl();
    } else {
      this._loadEmailConta(this.doc.clifo);
    }

    this.emitirNovaFaturaEletronica = false;

    this._nomeCertificado = this._getNomeCertificado(this._configService.configurations.gestaoComercial.faturaDigital.tipoCertificado);

    const tipoDocSaft =
      this.doc.cab.saftTipoCodigo && (this.doc.cab.saftTipoCodigo === 'FT' || this.doc.cab.saftTipoCodigo === 'FR' || this.doc.cab.saftTipoCodigo === 'ND' || this.doc.cab.saftTipoCodigo === 'NC');

    this.messageEmissaoFaturaEletronica =
      this.doc.cab.tipoDocSujeitoFaturaDigital &&
      this.doc.clifo.temFaturacaoElectronica &&
      (this.doc.cab.emitidoDocDigital === EFACCBEmitidoDocDigital.NAN || this.doc.cab.emitidoDocDigital === EFACCBEmitidoDocDigital.NaoEmitido)
        ? this._translateService.instant('impdoc.faturaDigital.seraEmitidaPor', {certificado: this._nomeCertificado})
        : this.doc.cab.tipoDocSujeitoFaturaDigital &&
          this.doc.clifo.temFaturacaoElectronica &&
          (this.doc.cab.emitidoDocDigital === EFACCBEmitidoDocDigital.Emitido || this.doc.cab.emitidoDocDigital === EFACCBEmitidoDocDigital.Enviado)
        ? this._translateService.instant('impdoc.faturaDigital.emitida')
        : this.doc.cab.tipoDocSujeitoFaturaDigital && !this.doc.clifo.temFaturacaoElectronica
        ? this._translateService.instant('impdoc.faturaDigital.clienteSemFatDigital')
        : this._configService.configurations.gestaoComercial.faturaDigital.ativa && this._nomeCertificado !== '' && !this.doc.cab.tipoDocSujeitoFaturaDigital && !tipoDocSaft
        ? ''
        : this._translateService.instant('impdoc.faturaDigital.semConfiguracao');

    this.semConfiguracao = this.messageEmissaoFaturaEletronica === this._translateService.instant('impdoc.faturaDigital.semConfiguracao');
    this.tipoDocEDeFaturaEletronica = this.messageEmissaoFaturaEletronica !== '';
    this.hintSemConfiguracao =
      this._configService.configurations.gestaoComercial.faturaDigital.ativa && this._nomeCertificado !== '' && !this.doc.cab.tipoDocSujeitoFaturaDigital && tipoDocSaft
        ? this._translateService.instant('impdoc.faturaDigital.tipoDocSaftNaoConfigurada')
        : this._translateService.instant('impdoc.faturaDigital.hintSemConfiguracao');
  }

  public switchView(): void {
    if (this.view === 'vias') {
      this.view = 'pdf';
      this._getPdfUrl();
    } else {
      this.view = 'vias';
    }
  }

  public callFaturacaoPublica(): Promise<void> {
    const options = {params: {faccbID: this.doc.cab.faccbId}};
    return this._maintenanceFaturacaoPublica.maintenance(options);
  }

  public readonly fnSendEmail = (): Promise<void> => this._sendEmail();

  private _getPdfUrl(): void {
    this.documentosService.getPdfUrl(this.doc.cab.faccbId, this.nvias, this.rptName, this.segundaVia, false, !this.segundaVia, false, this.emitirNovaFaturaEletronica).subscribe((pdfUrl: string) => {
      this.url = pdfUrl;
    });
  }

  private _getRptDescription(rptName: string): string {
    let description = '';
    for (const report of this.reports) {
      if (report.nome === rptName) {
        description = report.descricao;
        break;
      }
    }
    return description;
  }

  private _fillReports(): Promise<void> {
    return this._docfas.reports(this.doc.cab.nDocFa).then((response) => {
      this.reports = response.body.list;
      this.nvias = response.body.nviasDefault;
      this.rptName = response.body.reportDefault;
      this.rptDescription = this._getRptDescription(response.body.reportDefault);
    });
  }

  private _sendEmail(): Promise<void> {
    return this.documentosService
      .enviaPdfPorEmail(this.doc.cab.faccbId, this.nvias, this.rptName, this.segundaVia, this.email, !this.segundaVia, this.emitirNovaFaturaEletronica)
      .then(() => {
        this._plAlertService.success(this._translateService.instant('impdoc.email.emailEnviado', {email: this.email}));
      })
      .catch((reason) => {
        const cgException = this._cgExceptionService.get(reason);
        this._plAlertService.error(this._translateService.instant('impdoc.email.emailErro', {email: this.email, erro: cgException.message}));
      });
  }

  private _loadEmailConta(clifo: IJsonDocComercialClifo): Promise<void> {
    return this._definicaoEmailService
      .query(`NCONTA=${clifo.nConta}`)
      .then((response: HttpResponse<IApiQueryResponse<IJsonClifoExtraValue>>) => {
        const definicoesExistentes = response.body.list;
        const definicaoEmail = definicoesExistentes.find((definicao) => definicao.codigo === 1);
        if (definicaoEmail) {
          if (definicaoEmail.valor !== '') {
            this.email = definicaoEmail.valor;
          }
        }
      })
      .finally(() => {
        if (!this.email || this.email === '') {
          this.email = clifo.email;
        }
      });
  }

  private _getNomeCertificado(tipoCertificado: number): string {
    return tipoCertificado === ETipoCertificadoFaturaEletronica.DigitalSign
      ? this._translateService.instant('faturacaoeletronicaconfiguracoes.certificadoAvailable.digitalSign')
      : tipoCertificado === ETipoCertificadoFaturaEletronica.DigitalSignTestes
      ? this._translateService.instant('faturacaoeletronicaconfiguracoes.certificadoAvailable.digitalSignTestes')
      : tipoCertificado === ETipoCertificadoFaturaEletronica.SAFE
      ? this._translateService.instant('faturacaoeletronicaconfiguracoes.certificadoAvailable.safe')
      : tipoCertificado === ETipoCertificadoFaturaEletronica.SAFETestes
      ? this._translateService.instant('faturacaoeletronicaconfiguracoes.certificadoAvailable.safeTestes')
      : '';
  }
}
