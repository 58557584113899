<div class="entity-detail-form gestao-ficheiros-sepa">
  <pl-tabs [(activeId)]="activeTab" (evtSelected)="changeTab($event.nextId)" [theme]="'style3'">
    <pl-tab [id]="tabPorEnviarID" caption="gestaoFicheirosSepa.tabs.title.porEnviar">
      <div *plTabContent>
        <pl-form [formInstance]="formInstance" [submit]="fnPesquisar" plPromise [properties]="{validators: {required: {value: true}}}">
          <pl-group>
            <label [translate]="'gestaoFicheirosSepa.grid.toolbar.meioPagamento'"></label>
            <edit>
              <entity-autocomplete
                entity="meiosPagamento"
                [model]="meioPagamento"
                [(selectedKey)]="meioPagamento.nCaixa"
                (evtSelectedDescriptionChanged)="meioPagamento.nome = $event"
                [filter]="filterMeiosPagamento"
                [properties]="{validators: {required: {value: true}}}"
                plAutoFocus>
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <edit>
              <pl-button klass="btn-primary btn-sm" type="submit" data-focus> <i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span> </pl-button>
            </edit>
          </pl-group>
          <dx-data-grid
            [cgDxDataGrid]="dataGridDefinitionPorEnviar"
            cgDxDataGridInstanceName="gridPorEnviar"
            [dataSource]="dataGridDefinitionPorEnviar.dataSource"
            (onInitialized)="onInitializedPorEnviar($event)"
            (onContentReady)="onContentReadyPorEnviar()"
            (selectedRowKeysChange)="selectedRowKeys = $event; onSelectedRowKeysChange()"
            [selectedRowKeys]="selectedRowKeys">
          </dx-data-grid>
        </pl-form>
      </div>
    </pl-tab>
    <pl-tab [id]="tabEnviadosID" caption="gestaoFicheirosSepa.tabs.title.enviados">
      <div *plTabContent>
        <pl-form [formInstance]="formInstance" [submit]="fnPesquisar" [properties]="{validators: {required: {value: true}}}" plPromise>
          <pl-group>
            <label [translate]="'gestaoFicheirosSepa.grid.toolbar.meioPagamento'"></label>
            <edit>
              <entity-autocomplete
                entity="meiosPagamento"
                [model]="meioPagamento"
                [(selectedKey)]="meioPagamento.nCaixa"
                (evtSelectedDescriptionChanged)="meioPagamento.nome = $event"
                [filter]="filterMeiosPagamento"
                [properties]="{validators: {required: {value: true}}}"
                plAutoFocus>
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <pl-group>
              <label [translate]="'gestaoFicheirosSepa.grid.toolbar.dataDe'"></label>
              <edit>
                <pl-edit-datepicker [(model)]="dataPagamDe" [properties]="{validators: {required: {value: true}}}"></pl-edit-datepicker>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'gestaoFicheirosSepa.grid.toolbar.dataAte'"></label>
              <edit>
                <pl-edit-datepicker [(model)]="dataPagamAte" [properties]="{validators: {required: {value: true}}}"></pl-edit-datepicker>
              </edit>
            </pl-group>
          </pl-group>
          <pl-group>
            <edit>
              <pl-button type="submit" klass="btn-primary btn-sm" data-focus> <i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span> </pl-button>
            </edit>
          </pl-group>
          <dx-data-grid
            [cgDxDataGrid]="dataGridDefinitionEnviados"
            cgDxDataGridInstanceName="gridEnviados"
            [dataSource]="dataGridDefinitionEnviados.dataSource"
            (onInitialized)="onInitializedEnviados($event)"
            (onContentReady)="onContentReadyEnviados()">
            <div *dxTemplate="let item of 'actions'">
              <pl-tooltip [config]="{text: 'gestaoFicheirosSepa.grid.actions.imprimirNotificar', container: 'body'}">
                <button type="button" *plTooltipContent class="btn btn-xs btn-success" (click)="imprimirNotificar(item.data)">
                  <i class="fa fa-print"></i>
                </button>
              </pl-tooltip>
            </div>
            <div *dxTemplate="let group of 'nomeFicheiroGroupRowTemplate'">
              <span>Nome ficheiro: {{ group.value }}</span>
              <div class="float-end">
                <pl-tooltip [config]="{text: 'gestaoFicheirosSepa.grid.actions.download', container: 'body'}">
                  <button type="button" *plTooltipContent class="btn btn-xs btn-primary" (click)="transferirFicheiro(group.value)">
                    <i class="fa fa-download"></i>
                  </button>
                </pl-tooltip>
                &nbsp;
                <pl-tooltip [config]="{text: 'gestaoFicheirosSepa.grid.actions.retrocederPorEnviar', container: 'body'}">
                  <button type="button" *plTooltipContent class="btn btn-xs btn-danger" (click)="retrocederPorEnviar(group.value)">
                    <i class="fa fa-rotate-left"></i>
                  </button>
                </pl-tooltip>
              </div>
            </div>
          </dx-data-grid>
        </pl-form>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
