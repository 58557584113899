import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonAtivosDepreciacoesDetalhadas} from '../../interfaces/jsonAtivosDepreciacoesDetalhadas.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnContentReady} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';

@Component({
  selector: 'modal-ativos-depreciacoes-detalhadas',
  templateUrl: './ativosDepreciacoesDetalhadas.modal.component.html'
})
export class AtivosDeprecriacoesDetalhadasModalComponent extends CGModalComponent<void> {
  @Input() public ativoDepreciacaoDetalhadas: Array<IJsonAtivosDepreciacoesDetalhadas>;
  public readonly dataGridDefinition: IDevExpressDataGrid;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.ativoDepreciacaoDetalhadas = [];
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {
          columns: [
            {
              dataField: 'ano',
              dataType: 'number',
              caption: 'ativos.table.body.depreciacoes.ano',
              groupIndex: 0,
              allowGrouping: false,
              showInColumnChooser: false,
              groupCellTemplate: 'groupCellTemplateAno'
            },
            {dataField: 'mes', dataType: 'number', caption: 'ativos.table.body.depreciacoes.mes'},
            {dataField: 'contabTaxaMedia', dataType: 'double', caption: 'ativos.table.body.depreciacoes.contabtaxamedia'},
            {dataField: 'fiscalTaxaMedia', dataType: 'double', caption: 'ativos.table.body.depreciacoes.fiscaltaxamedia'},
            {dataField: 'Periodo', dataType: 'number', caption: 'ativos.table.body.depreciacoes.periodo'},
            {dataField: 'periodoAno', dataType: 'number', caption: 'ativos.table.body.depreciacoes.periodoano'},
            {dataField: 'periodoMes', dataType: 'number', caption: 'ativos.table.body.depreciacoes.periodomes'}
          ]
        },
        {
          caption: 'ativos.table.depreciacaocontabilistica',
          alignment: 'center',
          columns: [
            {dataField: 'depContabMes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'},
            {dataField: 'depContabMesAquisic', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmesaquisicao'},
            {dataField: 'depContabMesReavali', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmesreavalicao'},
            {dataField: 'depContabAcumAno', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumano'},
            {dataField: 'depContabAcumAnt', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumant'},
            {dataField: 'depContabAcumTot', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumtot'}
          ]
        },
        {
          caption: 'ativos.table.depreciacaofiscalaceite',
          alignment: 'center',
          columns: [
            {dataField: 'depFiscalMes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'},
            {dataField: 'depFiscalMesAquisic', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmesaquisicao'},
            {dataField: 'depFiscalMesReavali', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmesreavalicao'},
            {dataField: 'depFiscalAcumAno', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumano'},
            {dataField: 'depFiscalAcumAnt', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumant'},
            {dataField: 'depFiscalAcumTot', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumtot'}
          ]
        },
        {
          caption: 'ativos.table.taxascontab',
          alignment: 'center',
          columns: [
            {dataField: 'contabTaxaAmortiza', dataType: 'double', caption: 'ativos.table.body.depreciacoes.taxa'},
            {dataField: 'contabPercReducao', dataType: 'double', caption: 'depreciacoes.percreducao'}
          ]
        },
        {
          caption: 'ativos.table.taxasfiscal',
          alignment: 'center',
          columns: [
            {dataField: 'fiscalTaxaAmortiza', dataType: 'double', caption: 'ativos.table.body.depreciacoes.taxa'},
            {dataField: 'fiscalPercReducao', dataType: 'double', caption: 'depreciacoes.percreducao'}
          ]
        },
        {
          caption: 'ativos.table.limiteminimofiscal',
          alignment: 'center',
          columns: [
            {dataField: 'depLimMinFisMes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'},
            {dataField: 'depLimMinFisAcumAno', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumano'},
            {dataField: 'depLimMinFisAcumAnt', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumant'},
            {dataField: 'depLimMinFisAcumTot', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumtot'}
          ]
        },
        {
          caption: 'ativos.table.limitemaximofiscal',
          alignment: 'center',
          columns: [{dataField: 'depLimMaxFisMes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'}]
        },
        {
          caption: 'ativos.table.perdasimparidadecontabilisticas',
          alignment: 'center',
          columns: [
            {dataField: 'perImpContabMes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'},
            {dataField: 'perImpContabAcumAno', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumano'},
            {dataField: 'perImpContabAcumAnt', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumant'},
            {dataField: 'perImpContabAcumTot', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumtot'}
          ]
        },
        {
          caption: 'ativos.table.perdasimparidadefiscais',
          alignment: 'center',
          columns: [
            {dataField: 'perImpFiscalMes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'},
            {dataField: 'perImpFiscalAcumAno', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumano'},
            {dataField: 'perImpFiscalAcumAnt', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumant'},
            {dataField: 'perImpFiscalAcumTot', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumtot'}
          ]
        },
        {
          caption: 'ativos.table.valoresperdidosfiscais',
          alignment: 'center',
          columns: [
            {dataField: 'depPerdFisMes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'},
            {dataField: 'depPerdFisAcumAno', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumano'},
            {dataField: 'depPerdFisAcumAnt', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumant'},
            {dataField: 'depPerdFisAcumTot', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumtot'}
          ]
        },
        {
          caption: 'ativos.table.taxasperdidasfiscais',
          alignment: 'center',
          columns: [
            {dataField: 'taxPerdFisMes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'},
            {dataField: 'taxPerdFisAcumAno', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumano'},
            {dataField: 'taxPerdFisAcumAnt', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumant'},
            {dataField: 'taxPerdFisAcumTot', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumtot'}
          ]
        },
        {
          caption: 'ativos.table.depreciacoesnaoaceites',
          alignment: 'center',
          columns: [
            {dataField: 'depNaoAceiteMes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'},
            {dataField: 'depNaoAceiteAcumAno', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumano'},
            {dataField: 'depNaoAceiteAcumAnt', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumant'},
            {dataField: 'depNaoAceiteAcumTot', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumtot'}
          ]
        },
        {
          caption: 'ativos.table.imparidadesnaoaceites',
          alignment: 'center',
          columns: [
            {dataField: 'impNaoAceiteMes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'},
            {dataField: 'impNaoAceiteAcumAno', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumano'},
            {dataField: 'impNaoAceiteAcumAnt', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumant'},
            {dataField: 'impNaoAceiteAcumTot', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumtot'}
          ]
        },
        {
          caption: 'ativos.table.depreciacoesarecuperar',
          alignment: 'center',
          columns: [
            {dataField: 'depARecuperarMes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'},
            {dataField: 'depARecuperarAcuAno', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumano'},
            {dataField: 'depARecuperarAcuAnt', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumant'},
            {dataField: 'depARecuperarAcuTot', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumtot'}
          ]
        },
        {
          caption: 'ativos.table.depreciacoesrecuperadas',
          alignment: 'center',
          columns: [
            {dataField: 'depRecuperadMes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'},
            {dataField: 'depRecuperadAcumAno', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumano'},
            {dataField: 'depRecuperadAcumAnt', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumant'},
            {dataField: 'depRecuperadAcumTot', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumtot'}
          ]
        },
        {
          caption: 'ativos.table.imparidadesrecuperadasnoperiodo',
          alignment: 'center',
          columns: [
            {dataField: 'impRecuperadMes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'},
            {dataField: 'impRecuperadAcumAno', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumano'},
            {dataField: 'impRecuperadAcumAnt', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumant'},
            {dataField: 'impRecuperadAcumTot', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumtot'}
          ]
        },
        {
          caption: 'ativos.table.acrescemodelo22',
          alignment: 'center',
          columns: [
            {dataField: 'acresceM22Mes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'},
            {dataField: 'acresceM22AcumAno', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumano'},
            {dataField: 'acresceM22AcumAnt', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumant'},
            {dataField: 'acresceM22AcumTot', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumtot'}
          ]
        },
        {
          caption: 'ativos.table.deduzmodelo22',
          alignment: 'center',
          columns: [
            {dataField: 'deduzM22Mes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'},
            {dataField: 'deduzM22AcumAno', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumano'},
            {dataField: 'deduzM22AcumAnt', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumant'},
            {dataField: 'deduzM22AcumTot', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumtot'}
          ]
        },
        {
          caption: 'ativos.table.valortributavelativosimpostosdiferidos',
          alignment: 'center',
          columns: [
            {dataField: 'vTAtivoImpDifMes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'},
            {dataField: 'vTAtivoImpDifAcuAno', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumano'},
            {dataField: 'vTAtivoImpDifAcuAnt', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumant'},
            {dataField: 'vTAtivoImpDifAcuTot', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumtot'}
          ]
        },
        {
          caption: 'ativos.table.valortributaveldopassivoimpostosdiferidos',
          alignment: 'center',
          columns: [
            {dataField: 'vTPassiImpDifReforc', dataType: 'double', caption: 'ativos.table.body.depreciacoes.reforco'},
            {dataField: 'vTPassiImpDifMes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'},
            {dataField: 'vTPassiImpDifAcuAno', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumano'},
            {dataField: 'vTPassiImpDifAcuAnt', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumant'},
            {dataField: 'vTPassiImpDifAcuTot', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumtot'}
          ]
        },
        {
          caption: 'ativos.table.valornaoaceitefiscalmenteporreavaliacoes',
          alignment: 'center',
          columns: [
            {dataField: 'reavNaoAceiteMes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'},
            {dataField: 'reavNaoAceiteAcumAno', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumano'},
            {dataField: 'reavNaoAceiteAcumAnt', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumant'},
            {dataField: 'reavNaoAceiteAcumTot', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumtot'}
          ]
        },
        {
          caption: 'ativos.table.impostosdiferidos',
          alignment: 'center',
          columns: [
            {dataField: 'impostosDifTaxaIRC', dataType: 'double', caption: 'ativos.table.body.depreciacoes.taxairc'},
            {dataField: 'impostosDifMes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'},
            {dataField: 'impostosDifAcumAno', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumano'},
            {dataField: 'impostosDifAcumAnt', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumant'},
            {dataField: 'impostosDifAcumTot', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumtot'}
          ]
        },
        {
          caption: 'ativos.table.rendimentos',
          alignment: 'center',
          columns: [
            {dataField: 'rendimentosMes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'},
            {dataField: 'rendimentosAcumAno', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumano'},
            {dataField: 'rendimentosAcumAnt', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumant'},
            {dataField: 'rendimentosAcumTot', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumtot'}
          ]
        },
        {
          caption: 'ativos.table.impostosdifSubsidios',
          alignment: 'center',
          columns: [
            {dataField: 'impostosDifSubsidiosTaxaIRC', dataType: 'double', caption: 'ativos.table.body.depreciacoes.taxairc'},
            {dataField: 'impostosDifSubsidiosMes', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depmes'},
            {dataField: 'impostosDifSubsidiosAcumAno', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumano'},
            {dataField: 'impostosDifSubsidiosAcumAnt', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumant'},
            {dataField: 'impostosDifSubsidiosAcumTot', dataType: 'double', caption: 'ativos.table.body.depreciacoes.depacumtot'}
          ]
        }
      ],
      dataSource: [],
      export: {filename: 'ativos.steps.ativoDepreciacoesDetalhadas'},
      height: '60vh',
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      remoteOperations: false,
      scrolling: {
        columnRenderingMode: 'virtual',
        rowRenderingMode: 'virtual'
      },
      summary: {
        totalItems: [
          {column: 'depContabMes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'depFiscalMes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'depLimMinFisMes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'perImpContabMes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'perImpFiscalMes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'depPerdFisMes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'taxPerdFisMes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'depNaoAceiteMes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'impNaoAceiteMes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'depARecuperarMes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'depRecuperadMes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'impRecuperadMes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'acresceM22Mes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'deduzM22Mes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'vTAtivoImpDifMes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'vTPassiImpDifMes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'reavNaoAceiteMes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'impostosDifMes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'rendimentosMes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'impostosDifSubsidiosMes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'}
        ]
      }
    };
  }

  public onContentReady({component}: IDevExpressDataGridEventOnContentReady): void {
    component.updateDimensions();
  }
}
