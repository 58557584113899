<div
	(mousedown)="handleMousedown($event)"
	[class.ng-appearance-outline]="appearance === 'outline'"
	[class.ng-has-value]="hasValue"
	class="ng-select-container">
	<div class="ng-value-container">
		@if(selectedItems.length === 0 && !searchTerm) {
        <ng-template #defaultPlaceholderTemplate>
            <div class="ng-placeholder">{{ placeholder }}</div>
        </ng-template>
        <ng-template
			[ngTemplateOutlet]="placeholderTemplate || defaultPlaceholderTemplate">
        </ng-template>
		}
		
		@if ((!multiLabelTemplate || !multiple) && selectedItems.length > 0) {
			@for (item of selectedItems; track trackByOption($index, item)) {
				<div [class.ng-value-disabled]="item.disabled" class="ng-value">
					<ng-template #defaultLabelTemplate>
						<span class="ng-value-icon left" (click)="unselect(item)" aria-hidden="true">×</span>
						<span class="ng-value-label" [ngItemLabel]="item.label" [escape]="escapeHTML"></span>
					</ng-template>
					<ng-template
						[ngTemplateOutlet]="labelTemplate || defaultLabelTemplate"
						[ngTemplateOutletContext]="{ item: item.value, clear: clearItem, label: item.label }">
					</ng-template>
				</div>
			}
		}

        @if (multiple && multiLabelTemplate && selectedValues.length > 0) {
            <ng-template [ngTemplateOutlet]="multiLabelTemplate"
                         [ngTemplateOutletContext]="{ items: selectedValues, clear: clearItem }">
            </ng-template>
        }

        <div
            [attr.aria-expanded]="isOpen"
            [attr.aria-owns]="isOpen ? dropdownId : null"
            aria-haspopup="listbox"
            class="ng-input"
            role="combobox">
            <input
                #searchInput
                (blur)="onInputBlur($event)"
                (change)="$event.stopPropagation()"
                (compositionend)="onCompositionEnd(searchInput.value)"
                (compositionstart)="onCompositionStart()"
                (focus)="onInputFocus($event)"
                (input)="filter(searchInput.value)"
                [attr.aria-activedescendant]="isOpen ? itemsList?.markedItem?.htmlId : null"
                [attr.aria-controls]="isOpen ? dropdownId : null"
                [attr.id]="labelForId"
                [attr.tabindex]="tabIndex"
                [disabled]="disabled"
                [readOnly]="!searchable || itemsList.maxItemsSelected"
                [value]="searchTerm ? searchTerm : ''"
                aria-autocomplete="list" />
        </div>
    </div>

    @if (loading) {
        <ng-template #defaultLoadingSpinnerTemplate>
            <div class="ng-spinner-loader"></div>
        </ng-template>
        <ng-template [ngTemplateOutlet]="loadingSpinnerTemplate || defaultLoadingSpinnerTemplate"></ng-template>
    }

    @if (showClear()) {
        <span class="ng-clear-wrapper" tabindex="0" title="{{ clearAllText }}" #clearButton>
			<span class="ng-clear" aria-hidden="true">×</span>
		</span>
    }

    <span class="ng-arrow-wrapper">
		<span class="ng-arrow"></span>
	</span>
</div>

@if (isOpen) {
    <ng-dropdown-panel
        class="ng-dropdown-panel"
        [virtualScroll]="virtualScroll"
        [bufferAmount]="bufferAmount"
        [appendTo]="appendTo"
        [position]="dropdownPosition"
        [headerTemplate]="headerTemplate"
        [footerTemplate]="footerTemplate"
        [filterValue]="searchTerm"
        [items]="itemsList.filteredItems"
        [markedItem]="itemsList.markedItem"
        (update)="viewPortItems = $event"
        (scroll)="scroll.emit($event)"
        (scrollToEnd)="scrollToEnd.emit($event)"
        (outsideClick)="close()"
        [class.ng-select-multiple]="multiple"
        [ngClass]="appendTo ? (ngClass ? ngClass : classes) : null"
        [id]="dropdownId"
        role="listbox"
        aria-label="Options list">
        <ng-container>
            @for (item of viewPortItems; track trackByOption($index, item)) {
                <div
                    class="ng-option"
                    [attr.role]="item.children ? 'group' : 'option'"
                    (click)="toggleItem(item)"
                    (mouseover)="onItemHover(item)"
                    [class.ng-option-disabled]="item.disabled"
                    [class.ng-option-selected]="item.selected"
                    [class.ng-optgroup]="item.children"
                    [class.ng-option]="!item.children"
                    [class.ng-option-child]="!!item.parent"
                    [class.ng-option-marked]="item === itemsList.markedItem"
                    [attr.aria-selected]="item.selected"
                    [attr.id]="item?.htmlId">
                    <ng-template #defaultOptionTemplate>
                        <span class="ng-option-label" [ngItemLabel]="item.label" [escape]="escapeHTML"></span>
                    </ng-template>
                    <ng-template
                        [ngTemplateOutlet]="
							item.children ? optgroupTemplate || defaultOptionTemplate : optionTemplate || defaultOptionTemplate
						"
                        [ngTemplateOutletContext]="{ item: item.value, item$: item, index: item.index, searchTerm: searchTerm }">
                    </ng-template>
                </div>
            }
            @if (showAddTag) {
                <div
                    class="ng-option"
                    [class.ng-option-marked]="!itemsList.markedItem"
                    (mouseover)="itemsList.unmarkItem()"
                    role="option"
                    (click)="selectTag()">
                    <ng-template #defaultTagTemplate>
						<span
                        ><span class="ng-tag-label">{{ addTagText }}</span
                        >"{{ searchTerm }}"</span
                        >
                    </ng-template>
                    <ng-template
                        [ngTemplateOutlet]="tagTemplate || defaultTagTemplate"
                        [ngTemplateOutletContext]="{ searchTerm: searchTerm }">
                    </ng-template>
                </div>
            }
        </ng-container>
        @if (showNoItemsFound()) {
            <ng-template #defaultNotFoundTemplate>
                <div class="ng-option ng-option-disabled">{{ notFoundText }}</div>
            </ng-template>
            <ng-template
                [ngTemplateOutlet]="notFoundTemplate || defaultNotFoundTemplate"
                [ngTemplateOutletContext]="{ searchTerm: searchTerm }">
            </ng-template>
        }
        @if (showTypeToSearch()) {
            <ng-template #defaultTypeToSearchTemplate>
                <div class="ng-option ng-option-disabled">{{ typeToSearchText }}</div>
            </ng-template>
            <ng-template [ngTemplateOutlet]="typeToSearchTemplate || defaultTypeToSearchTemplate"></ng-template>
        }
        @if (loading && itemsList.filteredItems.length === 0) {
            <ng-template #defaultLoadingTextTemplate>
                <div class="ng-option ng-option-disabled">{{ loadingText }}</div>
            </ng-template>
            <ng-template
                [ngTemplateOutlet]="loadingTextTemplate || defaultLoadingTextTemplate"
                [ngTemplateOutletContext]="{ searchTerm: searchTerm }">
            </ng-template>
        }
    </ng-dropdown-panel>
}
