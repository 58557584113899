import {ITree} from '../../components/treeviewer/treeviewer.interface';

export interface ITiposRegistoCadastro {
  value: string;
  name: string;
  items?: Array<ITiposRegistoCadastro>;
  nivel?: string;
  colapsed?: boolean;
}

export const ENTITY_NAME_TIPOS_REGISTO_CADASTRO = 'tiposregistocadastro';

export const VALORES_DE_MOTIVOQPESSPOAL: Array<string> = [
  '1,1,1,0,0,0',
  '1,1,2,0,0,0',
  '1,1,3,0,0,0',
  '1,2,1,1,0,0',
  '1,2,1,2,0,0',
  '1,2,1,3,0,0',
  '1,2,1,4,0,0',
  '1,2,1,5,1,0',
  '1,2,1,5,2,0',
  '1,2,1,6,0,0',
  '1,2,1,7,0,0',
  '1,2,1,8,0,0',
  '1,2,1,9,0,0',
  '1,2,1,10,0,0',
  '1,2,1,11,0,0'
];

export const TIPOS_REGISTO_CADASTRO_TREE: ITree = {
  nodes: [
    {
      nodeText: 'Movimento de pessoas',
      collapsed: false,
      selectable: false,
      nodeClass: 'disabled',
      childNodes: [
        {
          nodeText: 'Entradas',
          collapsed: false,
          selectable: false,
          nodeClass: 'disabled',
          childNodes: [
            {
              nodeText: 'Contrato SEM Termo (Efetivo)',
              nodeId: '1,1,1,0,0,0',
              selectable: true
            },
            {
              nodeText: 'Contrato COM Termo Certo',
              nodeId: '1,1,2,0,0,0',
              selectable: true
            },
            {
              nodeText: 'Contrato COM Termo Incerto',
              nodeId: '1,1,3,0,0,0',
              selectable: true
            }
          ]
        },
        {
          nodeText: 'Saidas',
          collapsed: false,
          selectable: false,
          nodeClass: 'disabled',
          childNodes: [
            {
              nodeText: 'Contrato SEM Termo (Efetivo)',
              collapsed: false,
              selectable: false,
              nodeClass: 'disabled',
              childNodes: [
                {
                  nodeText: 'Iniciativa do Trabalhador',
                  nodeId: '1,2,1,1,0,0',
                  selectable: true
                },
                {
                  nodeText: 'Mutuo Acordo',
                  nodeId: '1,2,1,2,0,0',
                  selectable: true
                },
                {
                  nodeText: 'Iniciativa Empresa',
                  nodeId: '1,2,1,3,0,0',
                  selectable: true
                },
                {
                  nodeText: 'Despedimento Coletivo',
                  nodeId: '1,2,1,4,0,0',
                  selectable: true
                },
                {
                  nodeText: 'Despedimento',
                  collapsed: false,
                  selectable: false,
                  nodeClass: 'disabled',
                  childNodes: [
                    {
                      nodeText: 'Com Justa Causa',
                      nodeId: '1,2,1,5,1,0',
                      selectable: true
                    },
                    {
                      nodeText: 'Sem Justa Causa',
                      nodeId: '1,2,1,5,2,0',
                      selectable: true
                    }
                  ]
                },
                {
                  nodeText: 'Reforma Por Invalidez',
                  nodeId: '1,2,1,6,0,0',
                  selectable: true
                },
                {
                  nodeText: 'Reforma Por Velhice',
                  nodeId: '1,2,1,7,0,0',
                  selectable: true
                },
                {
                  nodeText: 'Reforma Antecipada',
                  nodeId: '1,2,1,8,0,0',
                  selectable: true
                },
                {
                  nodeText: 'Pre-Reforma',
                  nodeId: '1,2,1,9,0,0',
                  selectable: true
                },
                {
                  nodeText: 'Falecimento',
                  nodeId: '1,2,1,10,0,0',
                  selectable: true
                },
                {
                  nodeText: 'Impedimento_Prolongado',
                  nodeId: '1,2,1,11,0,0',
                  selectable: true
                }
              ]
            },
            {
              nodeText: 'Contrato COM Termo',
              collapsed: false,
              selectable: false,
              nodeClass: 'disabled',
              childNodes: [
                {
                  nodeText: 'Termo Certo',
                  collapsed: false,
                  selectable: false,
                  nodeClass: 'disabled',
                  childNodes: [
                    {
                      nodeText: 'Cessação',
                      nodeId: '1,2,2,1,1,0',
                      selectable: true
                    },
                    {
                      nodeText: 'Antecipação da Cessação',
                      nodeId: '1,2,2,1,2,0',
                      selectable: true
                    },
                    {
                      nodeText: 'Impedimento Prolongado',
                      nodeId: '1,2,2,1,3,0',
                      selectable: true
                    }
                  ]
                },
                {
                  nodeText: 'Termo Incerto',
                  collapsed: false,
                  selectable: false,
                  nodeClass: 'disabled',
                  childNodes: [
                    {
                      nodeText: 'Cessação',
                      nodeId: '1,2,2,2,1,0',
                      selectable: true
                    },
                    {
                      nodeText: 'Antecipação da Cessação',
                      nodeId: '1,2,2,2,2,0',
                      selectable: true
                    },
                    {
                      nodeText: 'Impedimento Prolongado',
                      nodeId: '1,2,2,2,3,0',
                      selectable: true
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      nodeText: 'Alteração do Regime de Contrato',
      collapsed: false,
      selectable: false,
      nodeClass: 'disabled',
      childNodes: [
        {
          nodeText: 'Contrato COM Termo',
          collapsed: false,
          selectable: false,
          nodeClass: 'disabled',
          childNodes: [
            {
              nodeText: 'Passou a SEM Termo',
              nodeId: '2,1,1,0,0,0',
              selectable: true
            }
          ]
        }
      ]
    },
    {
      nodeText: 'Promoções',
      collapsed: false,
      selectable: false,
      nodeClass: 'disabled',
      childNodes: [
        {
          nodeText: 'Por Antiguidade',
          nodeId: '3,1,0,0,0,0',
          selectable: true
        },
        {
          nodeText: 'Por Mérito',
          nodeId: '3,2,0,0,0,0',
          selectable: true
        },
        {
          nodeText: 'Por Outro Motivo',
          nodeId: '3,3,0,0,0,0',
          selectable: true
        }
      ]
    },
    {
      nodeText: 'Outros',
      collapsed: false,
      selectable: false,
      nodeClass: 'disabled',
      childNodes: [
        {
          nodeText: 'Reconversões/Reclassificação',
          nodeId: '4,0,0,0,0,0',
          selectable: true
        },
        {
          nodeText: 'Aumento de Vencimento',
          nodeId: '5,0,0,0,0,0',
          selectable: true
        },
        {
          nodeText: 'Outros',
          nodeId: '6,0,0,0,0,0',
          selectable: true
        }
      ]
    }
  ]
};
