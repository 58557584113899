import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {EGenericImporterType} from '../../../../components/genericimporter/generic.importer.component.interface';
import {GenericImporterService} from '../../../../components/genericimporter/generic.importer.service';
import {IArtigosEntityService} from '../../artigos.entity.interface';
import {IJsonArtigo, IJsonArtigosProcessParams, IJsonArtigoStockArmazens} from '../../jsonArtigo.entity.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {EConfigOptionsInstanceName, IArtigosListConfigOptions, TConfigOptions} from '../../../../services/config/options/config.options.service.interface';
import {ConfigOptionsService} from '../../../../services/config/options/config.options.service';
import {EGroupName} from '../../../../../config/constants';
import {Subscription} from 'rxjs';
import {IConfigOptionsInstance} from '../../../../services/config/options/config.options.instance.interface';
import {TEntityListAfterRequestFn, TEntityListBeforeRequestFn} from '../../../../components/entity/list/entity.list.component.interface';
import {IApiQueryResponse, THttpQueryResponse} from '../../../../services/api/api.service.interface';
import {HttpResponse} from '@angular/common/http';
import {DocumentoArtigosAdvancedSearchModalComponent} from '../../../docscomerciais/modals/advancedsearch/documento.artigosAdvancedSearch.modal.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import dxDataGrid from 'devextreme/ui/data_grid';
import {IEntityServiceQueryRequestConfig} from '../../../../services/entity/entity.service.interface';
import {IPlToolbarMenuItem} from 'pl-comps-angular';
import {ChangeArtigosFieldsInSerieModalComponent} from '../../../docscomerciais/modals/changeArtigosFieldsInSerie/changeArtigosFieldsInSerie.modal.component';
import {ENTITY_NAME_TXTAR} from '../../../txtar/txtar.entity.interface';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';

@Component({
  selector: 'artigos-list',
  templateUrl: './artigos.entity.list.component.html'
})
export class ArtigosListComponent extends ModuloEntityListComponent<IJsonArtigo, IArtigosEntityService> implements OnInit, OnDestroy {
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;

  public optionShowApenasDesativado: boolean;
  public optionShowApenasBloqueado: boolean;

  private _configOptionsInstance: IConfigOptionsInstance<boolean, IArtigosListConfigOptions>;
  private _subscriptionConfigOptions: Subscription;
  private _filter: string;

  private _dataGridInstance: dxDataGrid;
  private readonly _btnChangeFieldsInSerieDocumentos: IPlToolbarMenuItem;
  private readonly _btnTxtAr: IPlToolbarMenuItem;
  private readonly _maintenanceTxtAr: IEntityMaintenanceInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _genericImporterService: GenericImporterService,
    private readonly _configOptionsService: ConfigOptionsService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this._filter = 'artDesactivado=false&artBloqueado=false';
    this._maintenanceTxtAr = this._entityMaintenanceService.build(ENTITY_NAME_TXTAR);
    this.configOptionsInstanceName = EConfigOptionsInstanceName.ARTIGOS_LIST;
    this.configOptionsGroupName = EGroupName.ERP;
    this._btnChangeFieldsInSerieDocumentos = {
      caption: 'changeArtigosFieldsInSerie.modalTitle',
      iconLeft: '<i class="fa fa-fw fa-magic" aria-hidden="true"></i>',
      visible: true,
      click: () => this._showChangeFieldsInSerieModal()
    };
    this._btnTxtAr = {
      caption: 'artigos.texto_livre',
      iconLeft: '<i class="fa fa-fw fa-font" aria-hidden="true"></i>',
      click: () => this._showTxtArModal()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._genericImporterService.moduleToolbarButton(EGenericImporterType.Artigos, this)).addButton({
      id: 'configPreDefinidosTbBtn',
      type: 'button',
      order: this.btnNovo.order + 1,
      iconLeft: '<i class="fa fa-fw fa-search"></i>&nbsp;',
      class: 'btn-primary',
      caption: 'global.text.advancedSearch',
      click: () => this._openArtigosAdvancedSearch()
    });

    this.dropdownActions.visible = true;
    this.dropdownActions.menu.push(this._btnChangeFieldsInSerieDocumentos);
    this.dropdownActions.menu.push(this._btnTxtAr);

    this._configOptionsInstance = this._configOptionsService.getOptionsErp().get(this.configOptionsInstanceName);
    this._subscriptionConfigOptions = this._configOptionsInstance.options().subscribe((configOptions: TConfigOptions<boolean, IArtigosListConfigOptions>) => {
      this.optionShowApenasDesativado = configOptions.get('showApenasDesativado').value;
      this.optionShowApenasBloqueado = configOptions.get('showApenasBloqueado').value;

      this._filter =
        !this.optionShowApenasDesativado && !this.optionShowApenasBloqueado
          ? 'artDesactivado=false&artBloqueado=false'
          : this.optionShowApenasDesativado && this.optionShowApenasBloqueado
          ? 'artDesactivado=true|artBloqueado=true'
          : !this.optionShowApenasDesativado
          ? 'artBloqueado=true'
          : 'artDesactivado=true';
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionConfigOptions.unsubscribe();
    this._configOptionsInstance.setDefaultOptions();
  }

  public onConfigChange(): Promise<void> {
    return this.refreshList();
  }

  public onDataGridOnInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public readonly fnBeforeRequest: TEntityListBeforeRequestFn = (queryRequestConfig: IEntityServiceQueryRequestConfig) => this._beforeRequest(queryRequestConfig);
  public readonly fnAfterRequest: TEntityListAfterRequestFn<IJsonArtigo> = (response: THttpQueryResponse<IJsonArtigo>) => this._afterRequest(response);

  private async _afterRequest(response: THttpQueryResponse<IJsonArtigo>): Promise<IApiQueryResponse<IJsonArtigo>> {
    const artigosList = [];

    for (const artigo of response.body.list) {
      if (artigo.qtdTotal === 0) {
        artigosList.push(artigo.nArtigo);
      }
    }

    if (artigosList.length === 0) {
      return response.body;
    }

    const artigosProcessParams: IJsonArtigosProcessParams = {nArtigoList: artigosList.toString()};

    await this._service.processaStockArtigosNosArmazens(artigosProcessParams, true).then((resp: HttpResponse<Array<IJsonArtigoStockArmazens>>) => {
      if (resp) {
        for (const stock of resp.body) {
          const idx = response.body.list.findIndex((item) => item.nArtigo === stock.nArtigo);
          if (idx !== -1) {
            response.body.list[idx] = {...response.body.list[idx], qtdTotal: stock.qtdTotal, stockArmazens: stock.list};
          }
        }
      }
    });

    return response.body;
  }

  private _openArtigosAdvancedSearch(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DocumentoArtigosAdvancedSearchModalComponent, {size: 'fullscreen'});
    const componentInstance: DocumentoArtigosAdvancedSearchModalComponent = modalInstance.componentInstance;
    componentInstance.maintenanceMode = this.maintenanceMode;
    componentInstance.serviceMethodsOverride = this.serviceMethodsOverride;
    return modalInstance.result
      .then((result: string) => {
        return this._cgStateService.redirectToState({stateOrName: this.entityName, stateType: EEntityStateDetailType.DETAIL, params: {id: result}});
      })
      .finally(() => {
        this._dataGridInstance.option('state', null);
        this._dataGridInstance.clearGrouping();
      });
  }

  private _beforeRequest(queryRequestConfig: IEntityServiceQueryRequestConfig): IEntityServiceQueryRequestConfig {
    if (!queryRequestConfig.pesquisa) {
      queryRequestConfig.pesquisa = '';
    } else {
      queryRequestConfig.pesquisa += '&';
    }
    queryRequestConfig.pesquisa += this._filter;
    return queryRequestConfig;
  }

  private _showChangeFieldsInSerieModal(): Promise<void> {
    return this._cgModalService.showVanilla(ChangeArtigosFieldsInSerieModalComponent, {size: 'xxl', backdrop: 'static'}).result;
  }

  private _showTxtArModal(): Promise<unknown> {
    return this._maintenanceTxtAr.maintenanceSelectAndEdit();
  }
}
