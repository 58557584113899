import {ENTITY_NAME_USERS} from './utilizadores.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonUserEmpresa} from '../../services/account/jsonUserApi.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_UTILIZADORES: IEntityDefinition<IJsonUserEmpresa> = {
  name: ENTITY_NAME_USERS,
  url: 'manager/users',
  asModule: false,
  roles: [ROLE.ADMIN],
  searchPlaceholder: 'users.pesquisa',
  pageTitle: 'global.menu.users',
  icon: 'fa-user',
  metadata: {
    keyName: 'userId',
    descriptionName: 'username',
    fields: [
      {name: 'userId', caption: 'users.fields.userId', visible: false, validators: {required: true}},
      {name: 'username', caption: 'users.fields.username', validators: {required: true}},
      {name: 'password', type: 'password', caption: 'users.fields.password', validators: {required: true}},
      {name: 'firstName', caption: 'users.fields.firstName', validators: {required: true, maxlength: 40}},
      {name: 'lastName', caption: 'users.fields.lastName', validators: {maxlength: 40}},
      {name: 'email', type: 'email', caption: 'users.fields.email', validators: {required: true, minlength: 5, maxlength: 80}},
      {name: 'active', type: 'boolean', caption: 'users.fields.active'},
      {name: 'sendEmail', type: 'boolean', caption: 'users.fields.sendEmail'}
    ],
    order: 'username',
    searchFields: 'username,firstName,lastName,email,active',
    listFields: 'username,firstName,lastName,email,active',
    detailFields: 'username,firstName,lastName,email,active',
    newFields: 'username,firstName,lastName,email,active,sendEmail',
    editFields: 'username,firstName,lastName,email,active'
  },
  autocomplete: {
    rowTemplate: '{{username}}',
    output: 'username',
    searchFields: 'username,firstName,lastName,email'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true
  }
};
