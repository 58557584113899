import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  EFctPedidoReembolsoEstadoContrato,
  IFctCredentials,
  IFctPedidoReembolso,
  IFctPedidoReembolsoDataGridItem,
  IFctPedidoReembolsoFilters,
  IFctPedidoReembolsoItem,
  IFctValueName
} from '../../fct.module.interface';
import {FctService} from '../../fct.module.service';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {HttpResponse} from '@angular/common/http';
import {isDefinedNotNull, isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import {ICGExceptionError} from '../../../../components/exceptions/exceptions.service.interface';
import {EXCEPTION_AT_SERVICE, EXCEPTION_FCT_CREDENTIALS, FCT_INCORRECT_CREDENTIALS_MESSAGE, HTTP_STATUS_BAD_REQUEST} from '../../components/fct.module.component';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {TranslateService} from '@ngx-translate/core';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {FctCredentialsModalComponent} from '../credentials/fct.credentials.modal.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import moment from 'moment';
import {FctPedidosReembolsoEfetuadosModalComponent} from '../pedidosreembolsoefetuados/fct.pedidosReembolsoEfetuados.modal.component';

@Component({
  selector: 'fct-pedido-reembolso-modal',
  templateUrl: './fct.pedidoReembolso.modal.component.html'
})
export class FctPedidoReembolsoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public source: Array<IFctPedidoReembolsoDataGridItem>;

  public dataGridDefinition: IDevExpressDataGrid;
  public dataGridDefinitionDetails: IDevExpressDataGrid;
  public filters: IFctPedidoReembolsoFilters;
  public estadosContratoSource: Array<IFctValueName>;
  public promise: Promise<unknown>;
  public efetuarPedidosBtnDisabled: boolean;
  private _previousFilters: string;
  private _dataGridInstance: dxDataGrid;
  private _insuccessCounter: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _fctService: FctService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _translateService: TranslateService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.source = [];
    this.efetuarPedidosBtnDisabled = true;
    this.filters = {
      nissTrabalhador: '',
      dataCessFinal: undefined,
      dataCessInicial: undefined,
      estadoContrato: EFctPedidoReembolsoEstadoContrato.TODOS
    };
    this._previousFilters = JSON.stringify(this.filters);
    this.estadosContratoSource = [
      {value: EFctPedidoReembolsoEstadoContrato.TODOS, name: 'fct.strings.todos'},
      {value: EFctPedidoReembolsoEstadoContrato.CDCI, name: 'fct.strings.CDCI'},
      {value: EFctPedidoReembolsoEstadoContrato.CDSI, name: 'fct.strings.CDSI'},
      {value: EFctPedidoReembolsoEstadoContrato.CRV, name: 'fct.strings.CRV'}
    ];
    this.dataGridDefinition = {
      keyExpr: 'nempresa',
      columns: [
        {dataField: 'nempresa', caption: 'fct.tableDefinition.nempresa', width: 150},
        {dataField: 'nomeEmpresa', caption: 'fct.tableDefinition.nome', width: 300},
        {dataField: 'numTrabalhadores', caption: 'fct.tableDefinition.numTrabalhadores', alignment: 'center', width: 200},
        {dataField: 'errorMessage', caption: 'fct.tableDefinition.mensagem'},
        {
          width: 120,
          dataField: 'actions',
          caption: '',
          fixed: true,
          fixedPosition: 'right',
          cellTemplate: 'actions',
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowFixing: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowHiding: true,
          allowReordering: false,
          allowResizing: false,
          allowSearch: false,
          allowSorting: false,
          showInColumnChooser: false
        }
      ],
      masterDetail: {
        enabled: true,
        template: 'detail'
      },
      paging: {pageSize: 25},
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbarEmpresas',
            locateInMenu: 'auto'
          }
        ]
      }
    };

    this.dataGridDefinitionDetails = {
      columns: [
        {dataField: 'niss', caption: 'fct.tableDefinition.niss'},
        {dataField: 'nome', caption: 'fct.tableDefinition.nomeEmp'},
        {dataField: 'dataCessacaoContrato', caption: 'fct.tableDefinition.dataCessacaoContrato', dataType: 'date'},
        {dataField: 'motivo', caption: 'fct.tableDefinition.motivo'},
        {dataField: 'entregasPagas', caption: 'fct.tableDefinition.entregasPagas'},
        {dataField: 'numeroUPs', caption: 'fct.tableDefinition.numeroUPs'},
        {dataField: 'valorEstimadoReembolso', caption: 'fct.tableDefinition.valorEstimadoReembolso'},
        {dataField: 'errorMessage', caption: 'fct.tableDefinition.mensagem'}
      ],
      selection: {
        mode: 'multiple',
        selectAllMode: 'page',
        showCheckBoxesMode: 'always'
      },
      paging: {pageSize: 25},
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbarEfetuarPedido',
            locateInMenu: 'auto'
          }
        ]
      }
    };
    this._insuccessCounter = 0;
  }

  public ngOnInit(): void {
    this.promise = this._fctService.getAllEmpresas().then((response) => {
      response.body.forEach((item) => {
        this.source.push({
          trabalhadores: [],
          numTrabalhadores: '-',
          nempresa: item.nEmpresa,
          nomeEmpresa: item.nome,
          haveCredentials: true,
          errorMessage: '',
          pedidosReembolso: item.pedidosReembolso,
          _detailsDataGridInstance: undefined
        });
      });
      this.efetuarPedidosBtnDisabled = !this.source.length;
    });
  }

  public readonly fnSearchTrabalhadores = (): Promise<void> => this._searchTrabalhadores();
  public readonly fnEfetuarPedidos = (): Promise<void> => this._efetuarPedidos();
  public readonly fnEfetuarPedido = (item: IFctPedidoReembolsoDataGridItem) => (): Promise<void> => this._efetuarPedido(item);
  public readonly fnSelectAll = (): void => {
    this._selectAll();
  };
  public readonly fnUnselectAll = (): void => {
    this._unselectAll();
  };
  public readonly fnExpandAll = (): void => {
    this._expandAll();
  };
  public readonly fnCollapseAll = (): void => {
    this._collapseAll();
  };
  public readonly fnConfigCredentials = (item: IFctPedidoReembolsoDataGridItem) => (): Promise<unknown> => this._configCredentials(item);
  public readonly fnViewPedidosEfetuados = (item: IFctPedidoReembolsoDataGridItem) => (): Promise<unknown> => this._viewPedidosEfetuados(item);

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IFctPedidoReembolsoDataGridItem>): void {
    if (event.column.dataField !== 'actions') {
      devExpressDataGridExpandDetailHandler(event);
    }
  }

  public checkFilters(): void {
    if (JSON.stringify(this.filters) !== this._previousFilters) {
      this._dataGridInstance.collapseAll();
      this.source.forEach((item) => {
        item.trabalhadores = [];
        if (item.haveCredentials) {
          item.errorMessage = '';
        }
      });
    }
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onDataGridDetailInitialized({component}: IDevExpressDataGridEventOnInitialized, item: IFctPedidoReembolsoDataGridItem): void {
    item._detailsDataGridInstance = component;
  }

  private _efetuarPedidos(): Promise<void> {
    const promises: Array<Promise<void>> = [];
    this.source.forEach((empresaItem) => {
      if (empresaItem.haveCredentials && empresaItem.trabalhadores.length) {
        if (isDefinedNotNull(empresaItem._detailsDataGridInstance) && empresaItem._detailsDataGridInstance.getSelectedRowsData().length) {
          promises.push(this._efetuarPedido(empresaItem, true));
        }
      }
    });
    if (promises.length === 0) {
      this._plAlertService.error('fct.messages.selectAtLeastOneRecord');
      return Promise.resolve();
    }
    return Promise.all(promises).then(() => {
      this._plAlertService.info('fct.messages.operacaoConcluidaVerifiqueMsgs');
    });
  }

  private _searchTrabalhadores(): Promise<void> {
    if (!this._validateFilters()) {
      return Promise.resolve();
    }

    this._clearMessages();
    this.checkFilters();
    const promises: Array<Promise<void>> = [];
    this._insuccessCounter = 0;
    this.source.forEach((item) => {
      if (item.haveCredentials) {
        const promise = this._fctService
          .getListTrabalhadoresPedidoReembolso(item.nempresa, this.filters.nissTrabalhador, this.filters.dataCessInicial, this.filters.dataCessFinal, this.filters.estadoContrato)
          .then((response: HttpResponse<IFctPedidoReembolso>) => {
            if (response.body.success) {
              item.trabalhadores = response.body.data;
              item.numTrabalhadores = item.trabalhadores.length.toString();
            } else {
              item.errorMessage = response.body.errorMessage;
            }
          })
          .catch((reason) => {
            const exception: ICGExceptionError = this._cgExceptionService.get(reason);
            if (exception.class === EXCEPTION_FCT_CREDENTIALS || exception.message === FCT_INCORRECT_CREDENTIALS_MESSAGE) {
              item.haveCredentials = false;
              item.errorMessage = exception.message;
            } else if (exception.class !== EXCEPTION_AT_SERVICE && exception.status === HTTP_STATUS_BAD_REQUEST) {
              item.errorMessage = this._translateService.instant('fct.messages.httpErrorTenteNovamente');
            } else {
              item.errorMessage = exception.message;
            }
          });
        promises.push(promise);
      }
    });
    return Promise.all(promises).then(() => {
      this._previousFilters = JSON.stringify(this.filters);
      if (this._insuccessCounter > 0) {
        this._plAlertService.warning('fct.messages.naoFoiPossivelPedirReembolsoParaAlgunsTrabalhadores');
      }
    });
  }

  private _clearMessages(): void {
    this.source.forEach((item) => {
      if (item.haveCredentials) {
        item.numTrabalhadores = '-';
        item.errorMessage = '';
      }
    });
  }

  private _validateFilters(): boolean {
    if (
      this.filters.nissTrabalhador.length === 0 &&
      isUndefinedOrNull(this.filters.dataCessInicial) &&
      isUndefinedOrNull(this.filters.dataCessFinal) &&
      this.filters.estadoContrato === EFctPedidoReembolsoEstadoContrato.TODOS
    ) {
      this._plAlertService.error('fct.messages.selecionarCriterio');
      return false;
    }

    if (isUndefinedOrNull(this.filters.dataCessInicial) && isDefinedNotNull(this.filters.dataCessFinal)) {
      this._plAlertService.error('fct.messages.dataCessInicialEmpty');
      return false;
    } else if (isDefinedNotNull(this.filters.dataCessInicial) && isUndefinedOrNull(this.filters.dataCessFinal)) {
      this._plAlertService.error('fct.messages.dataCessFinalEmpty');
      return false;
    }

    if (isDefinedNotNull(this.filters.dataCessInicial) && isDefinedNotNull(this.filters.dataCessFinal)) {
      if (moment(this.filters.dataCessFinal).isBefore(this.filters.dataCessInicial)) {
        this._plAlertService.error('fct.messages.dataFimIgualPosteriorDataInicio');
        return false;
      }
    }

    return true;
  }

  private _efetuarPedido(item: IFctPedidoReembolsoDataGridItem, isInMultiple: boolean = false): Promise<void> {
    const selDataArray: Array<IFctPedidoReembolsoItem> = item._detailsDataGridInstance.getSelectedRowsData();

    if (!isInMultiple && selDataArray.length === 0) {
      this._plAlertService.error('fct.messages.selectAtLeastOneRecord');
      return Promise.resolve();
    }

    const selRowKeys: Array<string> = selDataArray.map((selItem: IFctPedidoReembolsoItem) => {
      return selItem.rkey;
    });

    if (!isInMultiple) {
      this._insuccessCounter = 0;
    }

    return this._fctService
      .efetuarPedidoReembolso(
        item.nempresa,
        selRowKeys,
        selDataArray[0].ibanReembolso,
        this.filters.nissTrabalhador,
        this.filters.dataCessInicial,
        this.filters.dataCessFinal,
        this.filters.estadoContrato
      )
      .then((response) => {
        if (response.body.success) {
          this._insuccessCounter += response.body.data.insuccessList.length;
          if (response.body.data.insuccessList.length > 0) {
            response.body.data.insuccessList.forEach((insuccessItem) => {
              const index = item.trabalhadores.findIndex((trabalhador) => {
                return trabalhador.niss === insuccessItem.niss;
              });
              item.trabalhadores[index].errorMessage = insuccessItem.motivo;
            });
          }
          const successList = response.body.data.successList.map((successItem) => {
            return successItem.niss;
          });
          item.trabalhadores = item.trabalhadores.filter((trabalhador) => {
            return !successList.includes(trabalhador.niss);
          });
          if (!isInMultiple) {
            if (this._insuccessCounter > 0) {
              this._plAlertService.warning('fct.messages.naoFoiPossivelPedirReembolsoParaAlgunsTrabalhadores');
            } else {
              this._plAlertService.warning('fct.messages.pedidoReembolsoEfetuadoComSucesso');
            }
          }
          if (!item.trabalhadores.length) {
            this._dataGridInstance.collapseRow(item.nempresa);
          }
        } else if (!isInMultiple) {
          this._plAlertService.error(response.body.errorMessage);
        } else {
          item.errorMessage = response.body.errorMessage;
        }
      });
  }

  private _selectAll(): void {
    this.source.forEach((item) => {
      if (item.trabalhadores.length > 0) {
        item._detailsDataGridInstance.selectAll();
      }
    });
  }

  private _unselectAll(): void {
    this.source.forEach((item) => {
      if (item.trabalhadores.length > 0) {
        item._detailsDataGridInstance.deselectAll();
      }
    });
  }

  private _expandAll(): void {
    if (isDefinedNotNull(this._dataGridInstance)) {
      this.source.forEach((item) => {
        if (item.trabalhadores.length) {
          this._dataGridInstance.expandRow(item.nempresa);
        }
      });
    }
  }

  private _collapseAll(): void {
    if (isDefinedNotNull(this._dataGridInstance)) {
      this._dataGridInstance.collapseAll(-1);
    }
  }

  private _configCredentials(item: IFctPedidoReembolsoDataGridItem): Promise<void> {
    return new Promise((resolve, reject) => {
      const modalInstance = this._cgModalService.showVanilla(FctCredentialsModalComponent, {size: 'sm', backdrop: 'static'});
      const componentInstance: FctCredentialsModalComponent = modalInstance.componentInstance;
      componentInstance.nomeEmpresa = `${item.nempresa} - ${item.nomeEmpresa}`;
      modalInstance.result
        .then((model: IFctCredentials) => {
          if (model.password.length && model.password.length) {
            this._fctService
              .setFCTCredentials(item.nempresa, model.niss, model.password)
              .then(() => {
                item.haveCredentials = true;
                item.errorMessage = '';
                resolve();
              })
              .catch(reject);
          }
        })
        .catch(reject);
    });
  }

  private _viewPedidosEfetuados(item: IFctPedidoReembolsoDataGridItem): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(FctPedidosReembolsoEfetuadosModalComponent, {backdrop: 'static'});
    const componentInstance: FctPedidosReembolsoEfetuadosModalComponent = modalInstance.componentInstance;
    componentInstance.source = item.pedidosReembolso;
    componentInstance.modalTitle = this._translateService.instant('fct.strings.pedidosReembolsosEfetuados', {nEmpresa: item.nempresa});
    return modalInstance.result;
  }
}
