import {merge, orderBy} from 'lodash-es';
import type {Subscription} from 'rxjs';
import {Component, EventEmitter, Host, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges, SkipSelf, ViewChild} from '@angular/core';
import {AbstractControl, FormGroupDirective, UntypedFormGroup} from '@angular/forms';
import {copy, getPathValue, isArray, isBoolean, isDefined, isDefinedNotNull, isFunction, isObject, isString, isUndefinedOrNull} from '../../../common/utilities/utilities';
import {
  EPlFormInternalOrientationClasses,
  EPlFormInternalPropertiesToEvaluate,
  IPlFormInternalDefaults,
  IPlFormInternalFieldChangeEvents,
  IPlFormInternalHandledTemplateItem,
  IPlFormInternalTemplateFieldGroup,
  IPlFormInternalTemplateFieldItem,
  IPlFormInternalTemplateFieldToEvaluate,
  PL_FORM_DEFAULTS,
  TPlFormInternalTemplateField
} from './form.internal.interface';
import {IPlCompsServiceConfig, IPlCompsServiceConfigEditForm} from '../../../common/interface';
import type {IPlEditGroup} from '../group/edit.group.interface';
import {
  IPlFormDefinition,
  IPlFormDefinitionField,
  IPlFormFieldValueChangedEvent,
  IPlFormOptions,
  IPlFormSubmitEvent,
  IPlFormTemplate,
  IPlFormTemplateField,
  IPlFormTemplateFieldGroup,
  IPlFormTemplateFieldItem,
  IPlFormTemplateFieldItemField,
  TPlFormEvaluatedProperty
} from './form.interface';
import {Logger} from '../../../logger/logger';
import {PlCompsService} from '../../../common/service/comps.service';
import {PlEditFormToken} from './form.token';
import {PlEditRegistryService} from '../../edit.registry.service';
import {PlNavWizardStepDirective} from '../../../navwizard/navwizard.step.component';
import {PlTranslateService} from '../../../translate/translate.service';

@Component({
  selector: 'pl-form',
  templateUrl: './form.component.html',
  providers: [{provide: PlEditFormToken, useExisting: PlEditFormComponent}]
})
export class PlEditFormComponent extends PlEditFormToken implements OnInit, OnChanges, OnDestroy {
  @Input() public model: object;
  @Input() public class: string | Array<string>;
  @Input() public formInstance: UntypedFormGroup;
  @Input() public formInstanceName: string;
  @Input() public ngForm: FormGroupDirective;
  @Input() public definition: IPlFormDefinition;
  @Input() public template: IPlFormTemplate;
  @Input() public properties: IPlFormOptions | any;
  @Output() public readonly formInstanceChange: EventEmitter<UntypedFormGroup>;
  @Output() public readonly ngFormChange: EventEmitter<FormGroupDirective>;
  @Output() public readonly evtFieldValueChanged: EventEmitter<IPlFormFieldValueChangedEvent<any>>;
  @Output() public readonly evtSubmitted: EventEmitter<IPlFormSubmitEvent<any>>;

  public formClass: Array<string>;
  public isNested: boolean;
  public builtFields: Array<TPlFormInternalTemplateField>;
  public autoFocusField: string;

  private readonly _subscriptionPlCompsConfig: Subscription;
  private readonly _groups: Array<IPlEditGroup>;
  private _defaults: IPlFormInternalDefaults;
  private _plCompsConfig: IPlCompsServiceConfigEditForm;
  private _formEvents: any;
  private _originalDefinition: IPlFormDefinition;
  private _fieldsToEvaluate: Array<IPlFormInternalTemplateFieldToEvaluate>;
  private _fieldChangeEvents: IPlFormInternalFieldChangeEvents<any>;

  constructor(
    @SkipSelf() @Optional() private readonly _plForm: PlEditFormToken,
    @Host() @Optional() private readonly _plNavWizardStep: PlNavWizardStepDirective,
    private readonly _logger: Logger,
    private readonly _plEditRegistryService: PlEditRegistryService,
    private readonly _plCompsService: PlCompsService,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super();
    this.formInstanceChange = new EventEmitter<UntypedFormGroup>();
    this.ngFormChange = new EventEmitter<FormGroupDirective>();
    this.evtFieldValueChanged = new EventEmitter<IPlFormFieldValueChangedEvent<any>>();
    this.evtSubmitted = new EventEmitter<IPlFormSubmitEvent<any>>();
    this.formClass = [];
    this.isNested = false;
    this.builtFields = [];
    this._groups = [];
    this._fieldsToEvaluate = [];
    this._subscriptionPlCompsConfig = this._plCompsService.config().subscribe((config: IPlCompsServiceConfig) => {
      this._plCompsConfig = config.plEditForm;
    });
  }

  public ngOnInit(): void {
    if (!this.formInstance || !(this.formInstance instanceof UntypedFormGroup)) {
      this.formInstance = new UntypedFormGroup({});
      this.formInstanceChange.emit(this.formInstance);
    }
    this.properties = this.properties || {};
    if (!this.properties.orientation) {
      this.properties.orientation = this._plCompsConfig.orientation;
    }
    if (this.properties.orientation && this._plNavWizardStep) {
      this._plNavWizardStep.setOrientation(this.properties.orientation);
    }

    this._handleClassChanged();

    this._defaults = copy(PL_FORM_DEFAULTS);
    this._defaults.template.orientation = this.properties.orientation || this._plCompsConfig.orientation || this._defaults.template.orientation;

    if (this._plForm) {
      this.isNested = true;
      if (!this.formInstanceName) {
        throw new TypeError('When using nested forms, an "instanceName" must be provided to the nested forms.');
      }
      this._plForm.addControl(this.formInstanceName, this.formInstance);
    }

    if (this.model || this.definition) {
      if (!this.model || !this.definition) {
        throw new Error('If either model or definition properties are defined, both must be defined');
      }
      this._buildForm();
    }
  }

  public ngOnChanges(form: SimpleChanges): void {
    const {definition, properties, template} = form;
    if (form.class && !form.class.isFirstChange()) {
      this._handleClassChanged(form.class.currentValue);
    }
    if (definition || template) {
      if (definition && !definition.isFirstChange()) {
        this._buildDefinition(definition.currentValue);
      } else if (template && !template.isFirstChange()) {
        this._buildTemplate(template.currentValue);
      }
    } else if (properties && !properties.isFirstChange()) {
      for (const group of this._groups) {
        group.updateGroup(properties.currentValue);
      }
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionPlCompsConfig.unsubscribe();
    if (this._plForm) {
      this._plForm.removeControl(this.formInstanceName);
    }
  }

  public addGroup(group: IPlEditGroup): void {
    if (!this._groups.includes(group)) {
      this._groups.push(group);
    }
  }

  public removeGroup(group: IPlEditGroup): void {
    const groupIndex: number = this._groups.indexOf(group);
    if (groupIndex > -1) {
      this._groups.splice(groupIndex, 1);
    }
  }

  public valueChanged(value: unknown, field: string): void {
    if (this._plNavWizardStep) {
      this._plNavWizardStep.setIncomplete();
    }
    if (this.builtFields.length) {
      this._doEvaluateFields();
    }
    if (isObject(this._fieldChangeEvents)) {
      const changeObject = this._fieldChangeEvents[field];
      if (changeObject) {
        changeObject.event(value, this.model, changeObject.field);
      }
    }
    this.evtFieldValueChanged.emit({value: value, field: field});
    if (this._plForm) {
      this._plForm.valueChanged(this.model, this.formInstanceName);
    }
  }

  public getModelValue(buildField: TPlFormInternalTemplateField): any {
    const field: IPlFormTemplateFieldItemField = buildField.field;
    const model: object = getPathValue(this.model, field.name);
    return field.objectMode ? model : model[field.modelName];
  }

  public setModelValue(buildField: TPlFormInternalTemplateField, value: any): void {
    const field: IPlFormTemplateFieldItemField = buildField.field;
    const model: object = getPathValue(this.model, field.name);
    model[field.modelName] = value;
  }

  public submit(event: Event): void {
    if (this.formInstance.invalid) {
      return;
    }
    if (this._formEvents) {
      const events = this._formEvents;
      if (isFunction(events.beforeSubmit)) {
        events.beforeSubmit(this.formInstance, this.model, event);
      }
      setTimeout(() => {
        if (isFunction(events.afterSubmit)) {
          events.afterSubmit(this.formInstance, this.model, event);
        }
      });
    }
    this.evtSubmitted.emit({instance: this.formInstance, model: this.model, event: event});
  }

  public addControl(name: string, control: AbstractControl): void {
    this.formInstance.registerControl(name, control);
  }

  public removeControl(name: string): void {
    this.formInstance.removeControl(name);
  }

  public get ngFormInstance(): FormGroupDirective {
    if (this.ngForm) {
      return this.ngForm;
    }
    if (this._plForm) {
      return this._plForm.ngFormInstance;
    }
    return undefined;
  }

  @ViewChild('form', {static: false})
  public set ngFormInstance(form: FormGroupDirective) {
    if (form) {
      this.ngForm = form;
      this.ngFormChange.emit(form);
      if (this._plNavWizardStep) {
        this._plNavWizardStep.formGroup = this.ngForm;
      }
    }
  }

  private _handleClassChanged(value: string | Array<string> = this.class): void {
    if (isString(value)) {
      this.formClass = value.split(' ');
    } else if (!isArray(value)) {
      this.formClass = isDefinedNotNull(value) ? [value] : [];
    } else {
      this.formClass = value;
    }
    if (!this.properties.orientation) {
      this.properties.orientation = this._plCompsConfig.orientation;
    }
    this.formClass.push(this.properties.orientation !== 'vertical' ? EPlFormInternalOrientationClasses.Horizontal : EPlFormInternalOrientationClasses.Vertical);
  }

  private _buildForm(): void {
    if (!isObject(this.definition)) {
      throw new TypeError('Definition object must be of type Object');
    }
    const definition: IPlFormDefinition = merge({}, this._defaults.definition, this.definition);
    this._buildDefinition(definition);
  }

  private _buildDefinition(definition: IPlFormDefinition): void {
    if (!isArray(definition.fields)) {
      throw new TypeError('Definition ``fields`` property must be of type Array');
    }

    for (let index = 0; index < definition.fields.length; index++) {
      const field: IPlFormDefinitionField = merge({}, copy(this._defaults.definitionField), definition.fields[index]);
      if (field.validators) {
        field.properties.validators = field.validators;
      }
      if (field.events) {
        field.properties.events = field.events;
      }
      if (isDefinedNotNull(field.visible)) {
        field.properties.visible = field.visible;
      }
      if (isDefinedNotNull(field.value) && isObject(this.model) && isUndefinedOrNull(this.model[field.formName || field.name])) {
        this.model[field.formName || field.name] = field.value;
      }
      if (field.properties.placeholder) {
        field.properties.placeholder = this._plTranslateService.translate(field.properties.placeholder);
      }

      // Adding ``modelName`` property for later use in field get ngModel function
      if (!field.modelName) {
        this._initFormNameModel(field);
      }
      definition.fields[index] = field;
    }

    if (!this.template?.items?.length) {
      if (!this.template) {
        this.template = copy(this._defaults.template);
      }
      this.template.addMissingFields = Boolean(this.template.addMissingFields);
      if (!this.template.addMissingFields) {
        this._logger.warn('No template items are defined but definition rendering is off! Are you sure this is intentional?');
      }
    }

    this.definition = definition;
    this._originalDefinition = copy(this.definition);
    this._buildTemplate(this.template);
  }

  private _initFormNameModel(field: IPlFormDefinitionField): void {
    let index = 0;
    if (!field.name || field.name.trim() === '') {
      throw new TypeError('Invalid field name');
    }
    if (field.name.includes('.')) {
      index = field.name.lastIndexOf('.') + 1;
    }
    field.modelName = field.name.substring(index, field.name.length);
  }

  private _addField(definitionField: IPlFormDefinitionField): void {
    function findField(fields: Array<TPlFormInternalTemplateField>): TPlFormInternalTemplateField {
      for (const myField of fields) {
        if (isArray(myField.fields)) {
          const fieldFromGroup = findField(myField.fields);
          if (fieldFromGroup) {
            return fieldFromGroup;
          }
        } else if (myField.field.name === definitionField.name) {
          return myField;
        }
      }
      return undefined;
    }

    const field: TPlFormInternalTemplateField = findField(this.builtFields);
    if (!field) {
      this.builtFields.push(this._generateTemplateItem({}, definitionField));
    }
  }

  private _buildTemplate(template: IPlFormTemplate): void {
    this.template = merge({}, this._defaults.template, template);
    this.definition = copy(this._originalDefinition);
    this.autoFocusField = undefined;

    let index: number;
    if (this.template.orientation === 'vertical') {
      index = this.formClass.indexOf(EPlFormInternalOrientationClasses.Horizontal);
      if (index !== -1) {
        this.formClass.splice(this.formClass.indexOf(EPlFormInternalOrientationClasses.Horizontal), 1);
      }
    } else {
      index = this.formClass.indexOf(EPlFormInternalOrientationClasses.Vertical);
      if (index !== -1) {
        this.formClass.splice(this.formClass.indexOf(EPlFormInternalOrientationClasses.Vertical), 1);
      }
    }

    // Keeping template events for posterior use
    this._formEvents = copy(this.template.events);

    // Emptying builtFields array in case we're rebuilding our template
    this._fieldsToEvaluate = [];
    this._fieldChangeEvents = undefined;
    this.builtFields = [];
    let previousLength = this.definition.fields.length;
    let i = -1;
    while (i < this.definition.fields.length) {
      i++;
      const definitionField = this.definition.fields[i];
      if (!definitionField) {
        break;
      }

      // Mapping template items with definition fields
      const returnObject: IPlFormInternalHandledTemplateItem = {
        newItem: undefined,
        found: false
      };
      for (const templateItem of this.template.items) {
        Object.assign(returnObject, this._handleTemplateItem(templateItem, definitionField));
        if (returnObject.found) {
          this.builtFields.push(returnObject.newItem);
        }
      }

      // If no templateItem found and addMissingFields is enabled, we use definition field instead
      if (!returnObject.found && this.template.addMissingFields) {
        this._addField(definitionField);
      }

      if (previousLength !== this.definition.fields.length) {
        i = -1;
        previousLength = this.definition.fields.length;
      }
    }
    this.builtFields = this._orderFieldsList(this.builtFields);
  }

  private _handleTemplateItem(templateItem: IPlFormTemplateField, definitionField: IPlFormDefinitionField): IPlFormInternalHandledTemplateItem {
    let newItem: Partial<TPlFormInternalTemplateField> = {};
    let found = false;
    if (templateItem) {
      // If templateItem isn't a group, treat it as a templateField
      if (templateItem.type !== 'group') {
        if (!templateItem.field) {
          throw new TypeError('A template item must have an associated field');
        }
        if (definitionField.name === templateItem.field) {
          found = true;
          this._filterDefinitionFields(templateItem);
          newItem = this._generateTemplateItem(<IPlFormTemplateFieldItem>templateItem, definitionField);

          const fieldItem = <IPlFormTemplateFieldItemField>(<IPlFormTemplateFieldItem>newItem).field;
          let propertyToEvaluate;
          if (isFunction(fieldItem.visible)) {
            propertyToEvaluate = EPlFormInternalPropertiesToEvaluate.VISIBLE;
          }
          if (isFunction(fieldItem.readonly)) {
            propertyToEvaluate = EPlFormInternalPropertiesToEvaluate.READONLY;
          }
          if (isFunction(fieldItem.disabled)) {
            propertyToEvaluate = EPlFormInternalPropertiesToEvaluate.DISABLED;
          }
          if (propertyToEvaluate) {
            this._addFieldToEvaluate(fieldItem, propertyToEvaluate);
          }
          if (isFunction(fieldItem.change)) {
            if (!isObject(this._fieldChangeEvents)) {
              this._fieldChangeEvents = {};
            }
            this._fieldChangeEvents[fieldItem.formName] = {
              field: fieldItem,
              event: fieldItem.change
            };
          }
        }
      }
      // When templateItem is a group
      else if (templateItem.type === 'group') {
        // Builds group object
        newItem = <IPlFormInternalTemplateFieldGroup>merge({}, copy(this._defaults.templateGroup), templateItem);
        for (let i = 0; i < templateItem.fields.length; i++) {
          const templateField = templateItem.fields[i];

          // Get corresponding definition field, based on templateField
          let filteredDefinitionField = this._filterDefinitionFields(templateField);

          /* Checking for length because when a field is found it gets spliced from the ``definition.fields`` list
           * thus avoiding adding the same field in the overall fields list + in the group list */
          if (!filteredDefinitionField.length) {
            filteredDefinitionField = [definitionField];
          }

          // Set templateField's parentGroup
          templateField.parentGroup = <IPlFormTemplateFieldGroup>newItem;

          // Use of recursive method to handle the recently found definition field
          const returnObject = this._handleTemplateItem(templateField, filteredDefinitionField[0]);

          // Setting group object fields based on index
          newItem.fields[i] = <IPlFormInternalTemplateFieldItem>copy(returnObject.newItem);
          if (!found) {
            found = returnObject.found;
          }
        }
        newItem.fields = this._orderFieldsList(newItem.fields);
      }
    }

    // returnObject
    return {
      newItem: <TPlFormInternalTemplateField>newItem,
      found: found
    };
  }

  private _generateTemplateItem(item: Partial<IPlFormTemplateFieldItem>, definition: IPlFormDefinitionField): TPlFormInternalTemplateField {
    /* This is to avoid template rewriting definition type (such as when template as type: 'field' and thus
     * overwriting definition's type) */
    const fieldType = definition.type;

    /* Builds a templateField object based on defaults and merges any properties defined in both
     * the corresponding definition and template */
    const newItem: IPlFormTemplateFieldItem = merge({}, copy(this._defaults.templateField), item);
    newItem.field = merge({}, copy(this._defaults.templateField.field), definition, item);
    const newItemField: IPlFormTemplateFieldItemField = <IPlFormTemplateFieldItemField>newItem.field;
    newItemField.properties.validators = newItemField.validators;
    newItemField.caption = this._plTranslateService.translate(newItemField.caption);
    newItemField.type = fieldType;

    // Assign default formName is it isn't defined
    if (!newItemField.formName) {
      newItemField.formName = newItemField.name;
    }

    if (!isBoolean(newItemField.objectMode)) {
      let editType: string = newItemField.type;
      newItemField.objectMode = this._plCompsConfig.modelValueEditTypes.includes(editType);
      if (!newItemField.objectMode) {
        editType = this._plEditRegistryService.getEditNameFromAlias(editType);
        newItemField.objectMode = this._plCompsConfig.modelValueEditTypes.includes(editType);
      }
    }

    // Item orientation evaluation
    if (
      this.template.orientation === 'vertical' ||
      (newItem.parentGroup && newItem.parentGroup.orientation === 'vertical') ||
      newItem.orientation === 'vertical' ||
      newItemField.orientation === 'vertical'
    ) {
      newItemField.properties.orientation = 'vertical';
      (<string>newItemField.class) += ' form-vertical';
    } else {
      (<string>newItemField.class) += ' form-horizontal';
    }

    // Autofocus field
    if (
      !this.autoFocusField &&
      newItemField.properties.disabled !== true &&
      newItemField.properties.readonly !== true &&
      newItemField.properties.disallowInput !== true &&
      newItemField.properties.raw !== true
    ) {
      this.autoFocusField = newItemField.name;
    }

    return <TPlFormInternalTemplateField>newItem;
  }

  private _filterDefinitionFields(templateItem: IPlFormTemplateField): Array<IPlFormDefinitionField> {
    return this.definition.fields.filter((field, index, source) => {
      if (field.name === templateItem.field) {
        source.splice(index, 1);
      }
      return field.name === templateItem.field;
    });
  }

  private _orderFieldsList<T extends {order?: unknown}>(fields: Array<T>): Array<T> {
    return orderBy<T>(fields, 'order');
  }

  private _addFieldToEvaluate(fieldItem: IPlFormTemplateFieldItemField, propertyToEvaluate: EPlFormInternalPropertiesToEvaluate): void {
    this._fieldsToEvaluate.push({
      property: propertyToEvaluate,
      field: fieldItem
    });
    this._doEvaluateField(fieldItem, propertyToEvaluate);
  }

  private _doEvaluateFields(): void {
    for (const fieldToEvaluate of this._fieldsToEvaluate) {
      const field = fieldToEvaluate.field;
      const property = fieldToEvaluate.property;
      this._doEvaluateField(field, property);
    }
  }

  private _doEvaluateField(field: IPlFormTemplateFieldItemField, property: EPlFormInternalPropertiesToEvaluate): void {
    const evaluation = Boolean((<TPlFormEvaluatedProperty>field[property])(this.model, field));
    const properties = {...field.properties};
    properties[property] = evaluation;
    field.properties = properties;
    if (!evaluation && property === EPlFormInternalPropertiesToEvaluate.VISIBLE && isDefined(this.model[field.modelName])) {
      delete this.model[field.modelName];
    }
  }
}
