<pl-form [submit]="close" plPromise>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'fimperiodoenc.changePeriodo'"></h4>
  </div>

  <div class="modal-body">
    <div class="entity-detail-form">
      <pl-group>
        <label [translate]="'fimperiodoenc.fields.periodos'"></label>
        <edit>
          <entity-autocomplete entity="periodos" attrName="periodos" [(selectedKey)]="periodo" [output]="'periodo'"> </entity-autocomplete>
        </edit>
      </pl-group>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button type="submit" id="aplicar" klass="btn-primary"> <i class="fa fa-fw fa-clone"></i>&nbsp;<span [translate]="'fimperiodoenc.btn.applyperio'"></span> </pl-button>

    <pl-button klass="btn-light" id="cancelar" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-ban"></i>&nbsp;<span [translate]="'fimperiodoenc.btn.wontchangeperio'"></span> </pl-button>
  </div>
</pl-form>
