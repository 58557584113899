<div class="retencoes entity-detail-form">
  <pl-form [properties]="{orientation: 'horizontal'}">
    <div class="retencoes-input-form">
      <pl-group>
        <label [translate]="'retencoes.header.nifDe'"></label>
        <edit>
          <pl-edit type="nifs" attrName="nifDe" [(model)]="nifDe" [properties]="{placeholder: 'retencoes.header.todos', inlineMode: true}"> </pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'retencoes.header.dataDe'"></label>
          <edit>
            <pl-edit type="date" attrName="dataDe" [(model)]="dataDe"> </pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'retencoes.header.dataAte'"></label>
          <edit>
            <pl-edit type="date" attrName="dataAte" [(model)]="dataAte"> </pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'retencoes.header.tipRetServNif'"></label>
          <edit>
            <pl-edit type="tipoRetServNIF" attrName="tipRetServNif" [(model)]="tipRetServNif"> </pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'retencoes.header.origemContas'"></label>
          <edit>
            <pl-edit type="origemContasRetencao" attrName="origemContas" [(model)]="origemContas"> </pl-edit>
          </edit>
        </pl-group>
      </pl-group>
    </div>

    <div class="mt-4">
      <pl-group>
        <label [translate]="'retencoes.header.tipoVisualizacao'"></label>
        <edit>
          <pl-edit type="radiogroup" attrName="tipoVisualizacao" [(model)]="tipoVisualizacao" [properties]="{groupItems: tipoVisualizacaoSource}"> </pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'retencoes.header.estadoRet'"></label>
        <edit>
          <pl-edit type="radiogroup" attrName="estadoRet" [(model)]="estadoRet" [properties]="{groupItems: estadoRetSource}"> </pl-edit>
        </edit>
      </pl-group>
    </div>

    <dx-data-grid
      class="mt-3"
      [cgDxDataGrid]="dataGridDefinition"
      cgDxDataGridInstanceName="retencoesDataGrid"
      [dataSource]="dataGridDefinition.dataSource"
      (onInitialized)="onInitialized($event)"
      (onContentReady)="onContentReady()"
      (onContextMenuPreparing)="onContextMenuPreparing($event)"
      (onCellClick)="onCellClick($event)">
      <div *dxTemplate="let detailInfo of 'templateMasterDetail'">
        <dx-data-grid [cgDxDataGrid]="dataGridDefinitionDetail" [dataSource]="detailInfo.data.pagamentosList" (onContextMenuPreparing)="onContextMenuPreparing($event)"> </dx-data-grid>
      </div>
    </dx-data-grid>
  </pl-form>
</div>
