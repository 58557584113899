<div class="impfferpoc entity-detail-form">
  <pl-form>
    <pl-group>
      <label [translate]="'impfferpoc.fields.listagem'"></label>
      <edit>
        <pl-edit type="reports_imp_fferpoc" attrName="listagem" [(model)]="report"></pl-edit>
      </edit>
    </pl-group>

    <div class="card mt-2">
      <div class="card-filters fw-bold pesquisa-avançada" (click)="collapsedPesqAvancada = !collapsedPesqAvancada">
        <button type="button" class="btn btn-link" [translate]="'impfferpoc.filtropesq'"></button>
        <i class="fa fa-caret-right" aria-hidden="true" [plAnimationRotate]="!collapsedPesqAvancada"></i>
      </div>
      <pl-animation-collapse [collapsed]="collapsedPesqAvancada">
        <div class="card-body">
          <pl-group>
            <label [translate]="'impfferpoc.fields.dataProcessamento'"></label>
            <edit>
              <pl-select [source]="datasProcessamentos" [model]="dataProcessamento" (modelChange)="changeDataProcessamento($event)"> </pl-select>
            </edit>
          </pl-group>
        </div>
      </pl-animation-collapse>
    </div>
  </pl-form>

  <hr />

  <cg-pdf-viewer *ngIf="showPdfViewer" [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>
