<div class="saftcontab entity-detail-form">
  <div *ngIf="blockedByUser" class="blocked-by-other-user-container">
    <div class="block-inline">
      <i class="fa fa-lock" aria-hidden="true"></i>
    </div>
    <div class="block-inline">
      <h5 [translate]="'importadorSaftDirect.messages.importInProcessModalMessageByOtherUser'"></h5>
      <div class="blocked-by-username">{{ currentStatus.userStarted }}</div>
    </div>
  </div>

  <pl-nav-wizard
    *ngIf="!blockedByUser"
    [definition]="definitionNavWizard"
    [onFinalize]="finalize"
    [properties]="propertiesNavWizard"
    [callback]="plWizardCallback"
    (modelChange)="changedStep($event)"
    [beforeStepChange]="fnBeforeStepChange">
    <pl-nav-wizard-step icon="fa-cog" caption="saftcontab.steps.importtype.step" [validator]="validateImportType" [valid]="wizSteps.importType.valid" [stepId]="'wizStepImportType'">
      <div *plNavWizardStepContent>
        <p [translate]="'saftcontab.steps.welcome.desc'"></p>
        <p class="text-danger" *ngIf="licencaStoreMode === storeModes.Public"><strong [translate]="'importadorSaftDirect.steps.welcome.contentText4'"></strong></p>
        <pl-group>
          <label [translate]="'saftcontab.steps.importtype.importtypes'"></label>
          <edit>
            <pl-edit
              type="radio"
              attrName="importarExtratoComImportacaoSaldosIniciais"
              [(model)]="wizSteps.importType.tipoImport"
              [properties]="{
                value: tipoImportacaoSAFTContab.ImportarExtratoComImportacaoSaldosIniciais,
                label: 'saftcontab.steps.importtype.fields.importextratocomsi',
                events: {beforeChange: fnImportTypeChanged}
              }">
            </pl-edit>
            <pl-edit
              type="radio"
              attrName="importarExtratoSemImportacaoSaldosIniciais"
              [(model)]="wizSteps.importType.tipoImport"
              [properties]="{
                value: tipoImportacaoSAFTContab.ImportarExtratoSemImportacaoSaldosIniciais,
                label: 'saftcontab.steps.importtype.fields.importextratosemsi',
                events: {beforeChange: fnImportTypeChanged}
              }">
            </pl-edit>
            <pl-edit
              type="radio"
              attrName="importarSoSaldosIniciais"
              [(model)]="wizSteps.importType.tipoImport"
              [properties]="{value: tipoImportacaoSAFTContab.ImportarSoSaldosIniciais, label: 'saftcontab.steps.importtype.fields.importsaldosiniciais', events: {beforeChange: fnImportTypeChanged}}">
            </pl-edit>
            <pl-edit
              type="radio"
              attrName="importarApenasContasCorrentes"
              [(model)]="wizSteps.importType.tipoImport"
              [properties]="{value: tipoImportacaoSAFTContab.ImportarApenasContasCorrentes, label: 'saftcontab.steps.importtype.fields.importterceiros', events: {beforeChange: fnImportTypeChanged}}">
            </pl-edit>
          </edit>
        </pl-group>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-file-text-o" caption="saftcontab.steps.upload.step" [validator]="validateUpload" [stepId]="'wizStepUploadFile'" [valid]="wizSteps.upload.valid">
      <div *plNavWizardStepContent>
        <h4 [translate]="'importadorSaftDirect.steps.upload.title'"></h4>

        <hr />

        <div class="mb-3" *ngIf="licencaStoreMode === storeModes.Public">
          <p class="text-info mb-3">
            <strong [translate]="'importadorSaftDirect.steps.welcome.contentText4'"></strong>
          </p>
          <p class="text-info mb-1">
            <strong
              [translate]="
                !hasRoleContabilidadePremium
                  ? 'importadorSaftDirect.steps.welcome.uploadLimit'
                  : isHoursBeforeSix
                    ? 'importadorSaftDirect.steps.welcome.uploadLimitPremium'
                    : 'importadorSaftDirect.steps.welcome.uploadLimitPremiumExtended'
              "></strong>
          </p>
          <p class="m-0" *ngIf="!hasRoleContabilidadePremium">* <small [translate]="'importadorSaftDirect.steps.welcome.uploadLimitInfo'"></small></p>
        </div>

        <div class="current-file-in-process-container" *ngIf="processing">
          <div>
            <i class="fa fa-file-code-o" aria-hidden="true"></i>
          </div>
          <div class="current-file-in-process-filename" [translate]="processingFilename"></div>
          <button type="button" class="btn btn-danger btn-sm" (click)="cancelProcess(1)" [translate]="'global.btn.cancel'"></button>
        </div>

        <ng-container *ngIf="!processing">
          <pl-upload
            [url]="uploadUrl"
            [autoProcessQueue]="false"
            [maxFiles]="1"
            [maxFileSize]="importacaoSAFTMaxFileSize"
            [callback]="plUploadCallback"
            [hideActionCancel]="true"
            [hideActionRemoveAll]="true"
            [hideActionRetry]="true"
            [hideActionUploadAll]="true"
            [hideActionUpload]="true"
            [params]="{tipoImportacao: wizSteps.importType.tipoImport.toString()}"
            [zip]="true"
            [uploadMultiple]="false"
            acceptedFiles=".xml">
          </pl-upload>

          <div class="uploadProcessarFicheiro-container" *ngIf="wizSteps.upload.showProcessFileProgressBar">
            <div class="uploadProcessarFicheiroLabel" [translate]="'saftcontab.messages.processarFicheiroLabel'"></div>
            <div class="progress">
              <div
                class="progress-bar bg-info progress-bar-striped progress-bar-animated"
                role="progressbar"
                [attr.aria-valuenow]="100"
                [attr.aria-valuemin]="0"
                [attr.aria-valuemax]="100"
                style="width: 100%"></div>
            </div>
          </div>
        </ng-container>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step
      icon="fa-cog"
      caption="saftcontab.steps.associacaoDiarios.step"
      [validator]="getPlanoContas"
      [valid]="wizSteps.associacaoDiarios.valid"
      [stepId]="'wizStepAssociacaoDiarios'"
      [visible]="wizSteps.importType.tipoImport !== tipoImportacaoSAFTContab.ImportarApenasContasCorrentes && wizSteps.importType.tipoImport !== tipoImportacaoSAFTContab.ImportarSoSaldosIniciais">
      <div *plNavWizardStepContent>
        <h4 [translate]="'importadorSaftDirect.steps.cfgTiposDocs.title'"></h4>
        <p [translate]="'saftcontab.steps.associacaoDiarios.desc'"></p>

        <dx-data-grid cgDxDataGridInstanceName="associaoDiarios" [cgDxDataGrid]="dataGridDefinitionAssociaoDiarios" [dataSource]="wizSteps.associacaoDiarios.list">
          <div *dxTemplate="let item of 'cellTemplateDiarioDestino'">
            <entity-autocomplete
              entity="diarios"
              attrName="diarioDestino"
              [model]="item.data"
              (modelChange)="diarioDestinoChanged($event, item.data, item.component)"
              [fieldsMap]="{nDiario: 'nDiarioDestino'}"
              output="key">
            </entity-autocomplete>
          </div>
        </dx-data-grid>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step
      icon="fa-cog"
      caption="saftcontab.steps.planoContas.step"
      [validator]="validatePlanoContas"
      [valid]="wizSteps.planoContas.valid"
      [stepId]="'wizStepPlanoContas'"
      [visible]="wizSteps.importType.tipoImport !== tipoImportacaoSAFTContab.ImportarApenasContasCorrentes">
      <div *plNavWizardStepContent>
        <h4 [translate]="'saftcontab.steps.planoContas.title'"></h4>

        <dx-data-grid
          cgDxDataGridInstanceName="planoContas"
          [cgDxDataGrid]="dataGridDefinitionPlanoContas"
          [dataSource]="wizSteps.planoContas.list"
          (onInitialized)="onInitializedPlanoContas($event)"
          (onEditingStart)="onEditorPreparingPlanoContas($event)"
          (onCellPrepared)="onCellPrepared($event)">
          <div *dxTemplate="let itemDetail of 'toolbarTemplateBeforePlanoContas'">
            <div class="d-flex align-items-baseline">
              <label class="me-1" [translate]="'saftcontab.steps.planoContas.filters.caption'"></label>
              <pl-autocomplete
                attrName="filtersPlanoContas"
                [source]="filtersSource"
                [model]="filterPlanoContas.nameMetodo"
                (modelChange)="setPlanoContas($event)"
                rowTemplate="nameMetodo"
                output="nameMetodo">
              </pl-autocomplete>
            </div>
          </div>

          <div *dxTemplate="let item of 'editCellTemplateNContaDestino'">
            <div class="d-flex align-items-baseline">
              <edit>
                <entity-autocomplete
                  entity="pocs"
                  attrName="nContaDestino"
                  [model]="item.data"
                  (modelChange)="nContaDestinoChanged($event, item.data, item.component)"
                  [fieldsMap]="{nConta: 'nContaDestino'}"
                  [filter]="filterMovimento"
                  [properties]="propertiesAutocompletePlanoContasNConta"
                  output="key"
                  plAutoFocus>
                </entity-autocomplete>
              </edit>
            </div>
          </div>

          <!-- begin convertContas-->
          <div *dxTemplate="let itemDetail of 'toolbarTemplateAfterPlanoContas'">
            <pl-tooltip [config]="{text: 'saftcontab.steps.planoContas.tooltip.importMatriz', placement: 'left'}">
              <pl-button *plTooltipContent klass="btn btn-primary btn-xs" [title]="'global.btn.import' | translate" [onClick]="importConfigContas">
                <i class="fa fa-files-o me-1" aria-hidden="true"></i><span [translate]="'saftcontab.steps.planoContas.btn.importMatriz'"></span>
              </pl-button>
            </pl-tooltip>
          </div>
          <!-- end convertContas-->

          <!-- begin contasSemConvert-->
          <div *dxTemplate="let item of 'headerCellTemplateCriaConta'">
            <pl-edit-checkbox
              [model]="criaContaAll"
              (modelChange)="criaContaAll = $event; criaContaAllChanged(item.component)"
              [properties]="{label: 'saftcontab.steps.fields.criaConta'}"></pl-edit-checkbox>
          </div>

          <div *dxTemplate="let item of 'cellTemplateCriaConta'">
            <div class="d-flex justify-content-center">
              <pl-edit-checkbox [model]="item.data.criaConta" (modelChange)="item.data.criaConta = $event; updateContaSemConvert(item.data, item.component)"></pl-edit-checkbox>
            </div>
          </div>

          <div *dxTemplate="let item of 'cellTemplateControlIvaDeb'">
            <entity-autocomplete
              entity="controloIVA"
              attrName="controloIVADebito"
              [model]="item.data"
              (modelChange)="controloIVAChanged($event, item.data, true, item.component)"
              [fieldsMap]="{codControloIVA: 'codControlIvaDebito'}"
              output="key">
            </entity-autocomplete>
          </div>

          <div *dxTemplate="let item of 'cellTemplateControlIvaCred'">
            <entity-autocomplete
              entity="controloIVA"
              attrName="controloIVADebito"
              [model]="item.data"
              (modelChange)="controloIVAChanged($event, item.data, false, item.component)"
              [fieldsMap]="{codControloIVA: 'codControlIvaCredito'}"
              output="key">
            </entity-autocomplete>
          </div>
          <!-- end contasSemConvert-->

          <div *dxTemplate="let itemDetail of 'cellTemplateBtnsPlanoContas'">
            <!--            convertContas-->
            <ng-container *ngIf="!!itemDetail.data.nContaOrigem && !!itemDetail.data.nContaDestino && itemDetail.data.nContaOrigem !== itemDetail.data.nContaDestino">
              <dx-button
                class="dx-button-icon-fontawesome"
                [hint]="'global.btn.edit' | translate"
                (click)="conversaoConta('edit', itemDetail.data)"
                icon="fa fa-fw fa-pencil"
                [stylingMode]="'text'"></dx-button>
              <dx-button class="dx-button-icon-devexpress" [hint]="'global.btn.delete' | translate" (click)="conversaoConta('delete', itemDetail.data)" icon="trash" [stylingMode]="'text'"></dx-button>
            </ng-container>

            <!--            contasExistentes-->
            <ng-container *ngIf="!!itemDetail.data.nContaOrigem && !!itemDetail.data.nContaDestino && itemDetail.data.nContaOrigem === itemDetail.data.nContaDestino">
              <dx-button
                class="dx-button-icon-fontawesome"
                [hint]="'global.btn.edit' | translate"
                (click)="replaceContaExistent(itemDetail.data)"
                icon="fa fa-fw fa-pencil"
                [stylingMode]="'text'"></dx-button>
            </ng-container>

            <!--            contasSemConvert-->
            <ng-container *ngIf="!!itemDetail.data.nContaOrigem && !itemDetail.data.nContaDestino">
              <dx-button
                class="dx-button-icon-devexpress"
                [hint]="'global.btn.add' | translate"
                (click)="addContasSemConvert(itemDetail.data, itemDetail.component)"
                icon="add"
                [stylingMode]="'text'"></dx-button>
            </ng-container>
          </div>
        </dx-data-grid>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step
      icon="fa-link"
      caption="saftcontab.steps.contasCorrentes.step"
      [validator]="validateContasCorrentes"
      [valid]="wizSteps.contasCorrentes.valid"
      [stepId]="'wizStepContasCorrentes'">
      <div *plNavWizardStepContent>
        <h4 [translate]="'saftcontab.steps.contasCorrentes.title'"></h4>

        <dx-data-grid
          cgDxDataGridInstanceName="contasCorrentes"
          [cgDxDataGrid]="dataGridDefinitionContasCorrentes"
          [dataSource]="wizSteps.contasCorrentes.list"
          (onCellPrepared)="onCellPrepared($event)"
          (onSaved)="onSavedContasCorrentes($event)">
          <div *dxTemplate="let item of 'toolbarTemplateApenasContasMov'">
            <div class="d-md-flex gap-3">
              <pl-edit-checkbox attrName="apenasContasMovimentadas" [(model)]="wizSteps.contasCorrentes.apenasContasMovimentadas" label="saftcontab.steps.contasCorrentes.apenasContasMovimentadas">
              </pl-edit-checkbox>

              <pl-edit-checkbox
                attrName="efetuaConversaoContasCorrentes"
                [(model)]="wizSteps.contasCorrentes.efetuaConversaoContasCorrentes"
                label="saftcontab.steps.contasCorrentes.efetuaConversaoContasCorrentes">
              </pl-edit-checkbox>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step
      icon="fa-files-o"
      caption="saftcontab.steps.configImport.step"
      [validator]="validateConfigImport"
      [valid]="wizSteps.configImport.valid"
      [stepId]="'wizStepConfigImport'"
      [visible]="wizSteps.importType.tipoImport !== tipoImportacaoSAFTContab.ImportarApenasContasCorrentes">
      <div *plNavWizardStepContent>
        <h4 [translate]="'saftcontab.steps.configImport.title'"></h4>
        <hr />

        <pl-group>
          <label [translate]="'saftcontab.steps.configImport.fields.ndescritivo'"></label>
          <edit>
            <entity-autocomplete
              entity="descritivos"
              attrName="nDescritivo"
              [model]="wizSteps.configImport"
              (selectedKeyChange)="wizSteps.configImport.nDescritivo = $event"
              (evtSelectedDescriptionChanged)="wizSteps.configImport.nomeDescritivo = $event"
              [properties]="{validators: {required: {value: false}}}"
              [fieldsMap]="{nDescrit: 'nDescritivo', nome: 'nomeDescritivo'}">
            </entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group
          *ngIf="
            wizSteps.importType.tipoImport === tipoImportacaoSAFTContab.ImportarExtratoComImportacaoSaldosIniciais ||
            wizSteps.importType.tipoImport === tipoImportacaoSAFTContab.ImportarExtratoSemImportacaoSaldosIniciais
          ">
          <label>
            <span class="me-1" [translate]="'saftcontab.steps.configImport.fields.justificaContasCorrenteMovAb'"></span>
            <pl-tooltip [config]="{text: 'saftcontab.steps.configImport.messages.justificaContasCorrenteMovAbInfo', placement: 'bottom', container: 'body'}">
              <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
            </pl-tooltip>
          </label>
          <edit>
            <pl-edit-checkbox attrName="justificaContasCorrenteMovAb" [(model)]="wizSteps.configImport.justificaContasCorrenteMovAb"></pl-edit-checkbox>
          </edit>
        </pl-group>

        <div class="confg-import">
          <fieldset
            *ngIf="
              wizSteps.importType.tipoImport === tipoImportacaoSAFTContab.ImportarExtratoComImportacaoSaldosIniciais ||
              wizSteps.importType.tipoImport === tipoImportacaoSAFTContab.ImportarSoSaldosIniciais
            ">
            <legend [translate]="'saftcontab.steps.configImport.fields.saldosiniciais'"></legend>
            <pl-group>
              <label [translate]="'saftcontab.steps.configImport.fields.periodo'"></label>
              <edit>
                <entity-autocomplete
                  entity="periodos"
                  [model]="wizSteps.configImport"
                  [(selectedKey)]="wizSteps.configImport.periodoSaldosIniciais"
                  [properties]="{validators: {required: {value: true}}}"
                  [fieldsMap]="{periodo: 'periodoSaldosIniciais'}"
                  output="key">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'saftcontab.steps.configImport.fields.diario'"></label>
              <edit>
                <entity-autocomplete
                  entity="diarios"
                  attrName="nDiarioSaldosIniciais"
                  [model]="wizSteps.configImport"
                  (selectedKeyChange)="wizSteps.configImport.nDiarioSaldosIniciais = $event"
                  (evtSelectedDescriptionChanged)="wizSteps.configImport.nomeDiarioSaldosIniciais = $event"
                  [properties]="{validators: {required: {value: true}}}"
                  [fieldsMap]="{nDiario: 'nDiarioSaldosIniciais', nome: 'nomeDiarioSaldosIniciais'}">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'saftcontab.steps.configImport.fields.descritivosaldosiniciais'"></label>
              <edit>
                <entity-autocomplete
                  entity="descritivos"
                  attrName="nDescritivoSaldosIniciais"
                  [model]="wizSteps.configImport"
                  (selectedKeyChange)="wizSteps.configImport.nDescritivoSaldosIniciais = $event"
                  (evtSelectedDescriptionChanged)="wizSteps.configImport.nomeDescritivoSaldosIniciais = $event"
                  [properties]="{validators: {required: {value: true}}}"
                  [fieldsMap]="{nDescrit: 'nDescritivoSaldosIniciais', nome: 'nomeDescritivoSaldosIniciais'}">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </fieldset>

          <fieldset>
            <legend [translate]="'saftcontab.steps.configImport.fields.diarioapura'"></legend>
            <pl-group>
              <label [translate]="'saftcontab.steps.configImport.fields.diario'"></label>
              <edit>
                <entity-autocomplete
                  entity="diarios"
                  attrName="nDiarioApuramento"
                  [model]="wizSteps.configImport"
                  (selectedKeyChange)="wizSteps.configImport.nDiarioApuramento = $event"
                  (evtSelectedDescriptionChanged)="wizSteps.configImport.nomeDiarioApuramento = $event"
                  [fieldsMap]="{nDiario: 'nDiarioApuramento', nome: 'nomeDiarioApuramento'}">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </fieldset>
        </div>

        <pl-group>
          <edit>
            <pl-edit-checkbox
              attrName="trocaGLPostingDateComTransactionDate"
              [(model)]="wizSteps.configImport.trocaGLPostingDateComTransactionDate"
              label="saftcontab.steps.configImport.fields.transactiondate">
            </pl-edit-checkbox>
          </edit>
        </pl-group>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-cog" caption="saftcontab.steps.finalized.step" [stepId]="'wizStepFinalized'">
      <div *plNavWizardStepContent>
        <h4 [translate]="'saftcontab.steps.finalized.successfully'"></h4>
      </div>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
