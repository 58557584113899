import {StateRegistry} from '@uirouter/core';
import {IPortalSidebarMenu, IPortalStateDeclaration} from '../../services/portals/portals.service.interface';
import {isDev} from '../../../config/constants';
import {NOTIFICATION_CENTER_ENABLED} from '../../services/notificationcenter/notificationcenter.service.interface';
import {PORTAL_ADMIN_ID, PORTAL_ADMIN_URL, STATE_NAME_ADMIN} from './admin.portal.interface';
import {PortalsService} from '../../services/portals/portals.service';
import {ROLE} from '../../services/role.const';

import {ENTITY_NAME_AUDIT_LOGIN} from '../../entities/auditlogin/auditLogin.entity.interface';
import {ENTITY_NAME_PORTALS} from '../../entities/portal/portal.entity.interface';
import {ENTITY_NAME_ROLES} from '../../entities/roles/roles.entity.interface';
import {ENTITY_NAME_TEMPLATES} from '../../entities/templates/templates.entity.interface';
import {ENTITY_NAME_TOKENS} from '../../entities/tokens/tokens.entity.interface';
import {ENTITY_NAME_USERS} from '../../modules/utilizadores/utilizadores.interface';
import {ENTITY_NAME_UTILIZADORES_BASICO} from '../../modules/utilizadores/basic/utilizadores.basic.interface';
import {MODULE_NAME_ANALITICA_CONFIG} from '../../modules/analiticaconfig/analiticaConfig.module.interface';
import {MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_EMPREGADO} from '../../modules/configs/assistenteconfigportais/portais/portalclientaccounts/empregado/assistConfigPortais.empregado.module.interface';
import {MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_EMPRESA} from '../../modules/configs/assistenteconfigportais/portais/portalclientaccounts/empresa/assistConfigPortais.empresa.module.interface';
import {MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_GERAL_EMAIL} from '../../modules/configs/assistenteconfigportais/portais/geral/email/assistConfigPortais.geral.email.module.interface';
import {MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_PORTAL_CLIENT_CONNECT_UTILIZADOR_EXISTENTE} from '../../modules/configs/assistenteconfigportais/portais/portalclientconnect/utilizadorexistente/assistConfigPortais.portalClientConnect.utilizadorExistente.module.interface';
import {MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_PORTAL_CLIENT_CONNECT_UTILIZADOR} from '../../modules/configs/assistenteconfigportais/portais/portalclientconnect/utilizador/assistConfigPortais.portalClientConnect.utilizador.module.interface';
import {MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_UTILIZADOR} from '../../modules/configs/assistenteconfigportais/portais/portalclientaccounts/utilizador/assistConfigPortais.utilizador.module.interface';
import {MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS} from '../../modules/configs/assistenteconfigportais/assistente.configPortais.module.service.interface';
import {MODULE_NAME_BLOCKED_PLUGIN} from '../../modules/blockedplugin/blockedPlugin.module.interface';
import {MODULE_NAME_CONFIGS_CLIFOS} from '../../modules/configs/erp/clifos/configs.clifos.module.interface';
import {MODULE_NAME_CONFIGS_COMERCIAL} from '../../modules/configs/erp/comercial/configs.comercial.module.interface';
import {MODULE_NAME_CONFIGS_CONTABILIDADE} from '../../modules/configs/erp/contabilidade/configs.contabilidade.module.interface';
import {MODULE_NAME_CONFIGS_EMPRESA} from '../../modules/configs/erp/empresa/configs.empresa.module.interface';
import {MODULE_NAME_CONFIGS_ERP_CHECK} from '../../modules/configs/erpcheck/erp.check.module.interface';
import {MODULE_NAME_CONFIGS_LICENCA} from '../../modules/configs/erp/licenca/configs.licenca.module.interface';
import {MODULE_NAME_CONFIGS_PCA} from '../../modules/configs/erp/pca/configs.pca.module.interface';
import {MODULE_NAME_CONFIGS_RH} from '../../modules/configs/erp/rh/configs.rh.module.interface';
import {MODULE_NAME_CONFIGS_SITE} from '../../modules/configs/site/configsSite.module.interface';
import {MODULE_NAME_EMPRESAS} from '../../modules/empresas/empresas.module.interface';
import {MODULE_NAME_MODULES} from '../../modules/modules/modules.module.interface';
import {MODULE_NAME_NOTIFICATION_CENTER} from '../../modules/notificationcenter/notificationCenter.module.interface';
import {MODULE_NAME_CONFIGS_PORTAL_COLABORADOR} from '../../modules/configs/erp/portalcolaborador/configs.portalcolaborador.module.interface';
import {ENTITY_NAME_PRHTIPOCOM} from '../../entities/prhtipocom/prhtipocom.entity.interface';

export function configUIRouterStateAdmin(stateRegistry: StateRegistry, portalsService: PortalsService): void {
  const menu: Array<IPortalSidebarMenu> = [];
  const configsErpSidebarMenu: Array<IPortalSidebarMenu> = [];

  const users = portalsService.registerEntity(STATE_NAME_ADMIN, ENTITY_NAME_UTILIZADORES_BASICO);
  const usersAdvanced = portalsService.registerEntity(STATE_NAME_ADMIN, ENTITY_NAME_USERS, false);
  const configsSite = portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_CONFIGS_SITE);
  const configsClifos = portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_CONFIGS_CLIFOS);
  const configsContabilidade = portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_CONFIGS_CONTABILIDADE);
  const configsComercial = portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_CONFIGS_COMERCIAL);
  const configsEmpresa = portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_CONFIGS_EMPRESA, false);
  const configsLicenca = portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_CONFIGS_LICENCA, false);
  const configsRh = portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_CONFIGS_RH);
  const configsPCA = portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_CONFIGS_PCA);
  const configsPortalColaborador = portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_CONFIGS_PORTAL_COLABORADOR);
  const templates = portalsService.registerEntity(STATE_NAME_ADMIN, ENTITY_NAME_TEMPLATES);
  const auditlogin = portalsService.registerEntity(STATE_NAME_ADMIN, ENTITY_NAME_AUDIT_LOGIN);
  const configERPCheck = portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_CONFIGS_ERP_CHECK);
  const portal = portalsService.registerEntity(STATE_NAME_ADMIN, ENTITY_NAME_PORTALS);
  const assistenteConfigPortais = portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS);
  const empresa = portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_EMPRESAS);

  // Required modules
  portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_EMPRESA);
  portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_UTILIZADOR);
  portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_EMPREGADO);
  portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_GERAL_EMAIL);
  portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_PORTAL_CLIENT_CONNECT_UTILIZADOR);
  portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_PORTAL_CLIENT_CONNECT_UTILIZADOR_EXISTENTE);
  portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_ANALITICA_CONFIG, false);
  portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_BLOCKED_PLUGIN, false);
  portalsService.registerEntity(STATE_NAME_ADMIN, ENTITY_NAME_PRHTIPOCOM, false);
  if (NOTIFICATION_CENTER_ENABLED) {
    portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_NOTIFICATION_CENTER, false);
  }

  menu.push(users.menu);
  menu.push(usersAdvanced.menu);
  menu.push(empresa.menu);
  menu.push(configsSite.menu);
  menu.push(assistenteConfigPortais.menu);
  menu.push({title: 'global.menu.configsErp', icon: 'fa-cogs', menu: configsErpSidebarMenu});
  menu.push(configERPCheck.menu);
  menu.push(templates.menu);
  menu.push(portal.menu);
  menu.push(auditlogin.menu);

  configsErpSidebarMenu.push(configsPCA.menu);
  configsErpSidebarMenu.push(configsClifos.menu);
  configsErpSidebarMenu.push(configsContabilidade.menu);
  configsErpSidebarMenu.push(configsComercial.menu);
  configsErpSidebarMenu.push(configsEmpresa.menu);
  configsErpSidebarMenu.push(configsLicenca.menu);
  configsErpSidebarMenu.push(configsRh.menu);
  configsErpSidebarMenu.push(configsPortalColaborador.menu);

  if (isDev()) {
    const dev: Array<IPortalSidebarMenu> = [];
    const tokens = portalsService.registerEntity(STATE_NAME_ADMIN, ENTITY_NAME_TOKENS);
    const modules = portalsService.registerModule(STATE_NAME_ADMIN, MODULE_NAME_MODULES);
    const roles = portalsService.registerEntity(STATE_NAME_ADMIN, ENTITY_NAME_ROLES);

    dev.push({title: 'global.menu.home', state: 'admin_home', icon: 'fa-home'});
    dev.push(tokens.menu);
    dev.push(modules.menu);
    dev.push(roles.menu);

    menu.push({title: 'global.menu.developer', icon: 'fa-terminal', menu: dev});
  }

  const state: IPortalStateDeclaration = portalsService.portalState();
  state.name = STATE_NAME_ADMIN;
  state.url = `/${PORTAL_ADMIN_URL}`;
  state.data.menu = menu;
  state.data.roles = [ROLE.ADMIN];
  state.data.portalId = PORTAL_ADMIN_ID;
  state.data.portalUrl = PORTAL_ADMIN_URL;
  state.data.pageTitle = 'global.states.admin.title';
  state.redirectTo = users.state.name;
  stateRegistry.register(state);
}
