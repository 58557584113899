<div class="modelo22-anexoe-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'modelo22.modal.anexoe.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-form>
      <pl-group>
        <label [translate]="'modelo22.modal.anexoe.rendimento'"></label>
        <edit>
          <pl-edit type="number" attrName="rendimento" [(model)]="rendimento"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label>t</label>
        <edit>
          <pl-edit type="number" attrName="distrito" [(model)]="t"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'modelo22.modal.anexoe.materiacol'"></label>
        <edit>
          <pl-edit type="number" attrName="materiacol" [(model)]="materiacol"></pl-edit>
        </edit>
      </pl-group>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span> </pl-button>

    <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
