import {Component, Injector, Input, OnInit} from '@angular/core';
import {copy, PlAlertService} from 'pl-comps-angular';
import {AssistenteConfigPortaisService} from '../../../../assistente.configPortais.module.service';
import {EContratoLockedReason} from '../../../../../../../../common/enums/contratolockedreason.enum';
import {ENTITY_NAME_EMPRESAS, IEmpresa} from '../../../../../../../entities/empresas/empresas.entity.interface';
import {EntityServiceBuilder} from '../../../../../../../services/entity/entity.service.builder';
import {FORM_INVALID_CANNOT_SUBMIT} from '../../../../../../../../config/constants';
import {IEntityService} from '../../../../../../../services/entity/entity.service.interface';
import {IJsonDGEMP} from '../../../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonErpUser, IJsonUser, IJsonUserNew} from '../../../../../../../services/account/jsonUserApi.interface';
import {IJsonErp} from '../../../../../../../interfaces/jsonUserManager.interface';
import {MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS} from '../../../../assistente.configPortais.module.service.interface';
import {ModuloComponent} from '../../../../../../../components/module/module.component';
import {ROLE} from '../../../../../../../services/role.const';
import {THttpQueryResponse, TServiceQueryResponse} from '../../../../../../../services/api/api.service.interface';

@Component({
  selector: 'module-assist-config-portais-empresa',
  templateUrl: './assistConfigPortais.empresa.module.component.html'
})
export class AssistConfigPortaisEmpresaModuleComponent extends ModuloComponent implements OnInit {
  @Input() public portalRolePCA: ROLE;
  @Input() public instalacoes: Array<IJsonErp>;

  public readonly filterGabinetes: string;
  public readonly user: Partial<IJsonUser>;
  public centralGestId: number;
  public gabinete: IEmpresa;
  public empresa: IEmpresa;
  public novoUtilizador: boolean;
  public enviaEmail: boolean;
  public associarEmpregado: boolean;
  public empregado: IJsonDGEMP;
  public filterEmpresas: string;
  public filterEmpregados: string;

  private readonly _serviceEmpresas: IEntityService<IEmpresa>;
  private _utilizadorCriado: boolean;
  private _nEmpresaCliente: string;
  private _nEmpresaGabinete: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _assistenteConfigPortaisService: AssistenteConfigPortaisService
  ) {
    super(_injector);
    this.filterGabinetes = 'EmpresaGabinete=1';
    this.user = {active: true};
    this.novoUtilizador = true;
    this.enviaEmail = false;
    this.associarEmpregado = false;
    this._utilizadorCriado = false;
    this._serviceEmpresas = this._entityServiceBuilder.build<IEmpresa>(ENTITY_NAME_EMPRESAS);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnBack.visible = true;
    this.centralGestId = this.session.erp.centralGestId;
    if (this.instalacoes.length) {
      this.changedInstalacao(this.instalacoes[0].centralGestId);
    }
  }

  public back(): Promise<void> {
    const moduleName: string = this._assistenteConfigPortaisService.getModuleSelectPortal();
    return this._cgStateService.redirectToState({stateOrName: moduleName});
  }

  public changedInstalacao(centralGestId: number): void {
    this.centralGestId = centralGestId;
    this.gabinete = undefined;
    this.empresa = undefined;
    this.empregado = undefined;
    this._getEmpresasGabinete().then((responseEmpresasGabinete: THttpQueryResponse<IEmpresa>) => {
      for (const empresa of responseEmpresasGabinete.body.list) {
        if (empresa.nempresa === this.session.erp.nEmpresa) {
          this.changedGabinete(empresa);
          return;
        }
      }
      this.changedGabinete(undefined);
    });
  }

  public changedGabinete(gabinete: IEmpresa): void {
    this.gabinete = gabinete;
    this._nEmpresaGabinete = this.gabinete ? this.gabinete.nempresa : undefined;
    this.filterEmpresas = this._nEmpresaGabinete ? this._assistenteConfigPortaisService.generateFilterEmpresas(this.centralGestId, this._nEmpresaGabinete, this.session.erps) : undefined;
    if (this.filterEmpresas) {
      this._getEmpresasClientes().then((responseEmpresasClientes: THttpQueryResponse<IEmpresa>) => {
        if (responseEmpresasClientes.body.list.length) {
          this.changedEmpresa(responseEmpresasClientes.body.list[0]);
        } else {
          this.changedEmpresa(undefined);
        }
      });
    } else {
      this.changedEmpresa(undefined);
    }
  }

  public changedEmpresa(empresa: IEmpresa): void {
    this.empresa = empresa;
    this._nEmpresaCliente = this.empresa ? this.empresa.nempresa : undefined;
    this.filterEmpregados = this._nEmpresaCliente ? `nempresa=${this._nEmpresaCliente}` : undefined;
    if (this.empregado) {
      this.empregado = undefined;
    }
  }

  public readonly fnFinalize: () => Promise<void> = () => this._finalize();

  private _getEmpresasGabinete(): TServiceQueryResponse<IEmpresa> {
    return this._getEmpresas(this.filterGabinetes, undefined);
  }

  private _getEmpresasClientes(): TServiceQueryResponse<IEmpresa> {
    return this._getEmpresas(this.filterEmpresas, 'nempresagabinete desc');
  }

  private _getEmpresas(search: string, order: string): TServiceQueryResponse<IEmpresa> {
    return this._serviceEmpresas.query({pesquisa: search, ordena: order, params: {centralGestId: this.centralGestId}});
  }

  private async _finalize(): Promise<void> {
    if (!this._nEmpresaGabinete) {
      this._plAlertService.error('assistenteconfigportais.info.empresagabinetenaoselec');
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }
    if (!this._nEmpresaCliente) {
      this._plAlertService.error('assistenteconfigportais.info.empresaclientenaoselec');
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }
    if (this.novoUtilizador && !this._utilizadorCriado) {
      const newUser = this._generateNewUser();
      await this._assistenteConfigPortaisService.setNewConfigurationUserEmpresaPortal(newUser, this.session.erp.name, this._nEmpresaGabinete, this._nEmpresaCliente).then(() => {
        this._utilizadorCriado = true;
        this._plAlertService.success('assistenteconfigportais.info.savesucess');
      });
    }
    return this._assistenteConfigPortaisService.setEmpresaClienteToEmpresaGabinete(this.session.erp.centralGestId, this.session.erp.name, this._nEmpresaGabinete, this._nEmpresaCliente).then(() => {
      this._plAlertService.success('assistenteconfigportais.info.empresaClienteConfigurada');
      return this._cgStateService.redirectToState({stateOrName: MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS});
    });
  }

  private _generateNewUser(): IJsonUserNew {
    const erp: IJsonErpUser = {
      cgID: undefined,
      centralGestId: this.session.erp.centralGestId,
      name: this.session.erp.name,
      apiUrl: this.session.erp.apiUrl,
      nEmpresa: this._nEmpresaCliente,
      nUtilizador: this.session.erp.nUtilizador,
      ativo: true,
      nomeEmpresa: this.session.erp.nomeEmpresa,
      nomeUtilizadorCG: this.session.erp.nomeUtilizadorCG,
      codEmp: this.associarEmpregado && this.empregado ? this.empregado.codEmp : undefined,
      nomeEmpregado: this.associarEmpregado && this.empregado ? this.empregado.nome : undefined,
      rolesAcess: [
        {role: ROLE.PCA}, // role API
        {role: copy(this.portalRolePCA)}
      ],
      rolesNotAcess: []
    };
    return {
      userId: undefined,
      username: this.user.username,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email,
      langKey: undefined,
      active: this.user.active,
      idContratoPai: undefined,
      lockedReason: EContratoLockedReason.None,
      termsAccepted: undefined,
      erps: [erp],
      lasterps: [],
      erp: erp,
      numEmpresas: undefined,
      roles: erp.rolesAcess,
      portais: [],
      password: undefined,
      sendEmail: this.enviaEmail
    };
  }
}
