import {Pipe, PipeTransform} from '@angular/core';
import {isNumber, normalizeAccents} from '../common/utilities/utilities';

const LETTERS_ACCENTED: {[key: string]: string} = {
  a: '[a|\xE0-\xE6]',
  e: '[e|\xE8-\xEB]',
  i: '[i|\xEC-\xEF]',
  o: '[o|\xF2-\xF6]',
  u: '[u|\xF9-\xFC]',
  c: '[c|\xE7]',
  n: '[n|\xF1]'
};

function stringEscapeSafe(val: string): string {
  return String(val).replace(/[\\/^$*+?.()|{}<>-]/g, '\\$&');
}

function replaceFn(argOffset: number, highlightClass: string = 'cgc-highlight'): () => string {
  return function (...args: Array<any>): string {
    const match: string = args[0];
    const offset: number = args[argOffset + 1];
    const original: string = args[argOffset + 2];
    return original.includes('<', offset) || original.includes('>', offset) ? match : `<span class="${highlightClass}">${match}</span>`;
  };
}

export function cgcHighlight(value: string, search: string, shouldHighlight: boolean = true, wordsOnly: boolean = false, highlightClass?: string): string {
  if (shouldHighlight === false || !value || (!search && !isNumber(search))) {
    return value;
  }

  value = value.toString();
  search = normalizeAccents(search.toString());
  if (!search) {
    return value;
  }

  const patterns: Array<string> = [];
  const words: Array<string> = search.split(' ');
  for (const wordItem of words) {
    const wordPatterns: Array<string> = [];
    const letters: Array<string> = wordItem.split('');
    for (const letter of letters) {
      const accentedLetter = LETTERS_ACCENTED[letter];
      if (accentedLetter) {
        wordPatterns.push(accentedLetter);
      } else {
        wordPatterns.push(stringEscapeSafe(letter));
      }
    }
    patterns.push(`(${wordPatterns.join('')})`);
  }
  search = patterns.join('|');

  const replace: () => string = replaceFn(patterns.length, highlightClass);

  if (!wordsOnly) {
    return value.replace(new RegExp(search, 'gi'), replace);
  }

  const regexp = new RegExp(search, 'gi');
  let segmentedText: Array<string> = value.split(' ');
  segmentedText = segmentedText.map((segment: string) => {
    return segment.replace(regexp, replace);
  });
  return segmentedText.join(' ');
}

@Pipe({
  name: 'cgcHighlight'
})
export class CGCHighlightPipe implements PipeTransform {
  public transform(value: string, search: string, shouldHighlight: boolean = true, wordsOnly: boolean = false, highlightClass?: string): string {
    return cgcHighlight(value, search, shouldHighlight, wordsOnly, highlightClass);
  }
}
