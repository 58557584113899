import type {Subscription} from 'rxjs';
import {Component, HostListener, OnDestroy} from '@angular/core';
import {PlPageWrapperService} from './pagewrapper.service';
import {isMobile} from '../common/constants';

@Component({
  selector: 'pl-page-wrapper',
  templateUrl: './pagewrapper.component.html'
})
export class PlPageWrapperComponent implements OnDestroy {
  public toggled: boolean;
  public showing: boolean;

  private readonly _subscriptionToggled: Subscription;
  private readonly _subscriptionShowingSideBar: Subscription;

  constructor(private readonly _plPageWrapperService: PlPageWrapperService) {
    this.toggled = false;
    this.showing = false;
    this._subscriptionToggled = this._plPageWrapperService.toggled().subscribe((value: boolean) => {
      this.toggled = value;
    });
    this._subscriptionShowingSideBar = this._plPageWrapperService.showingSideBar().subscribe((value: boolean) => {
      this.showing = value;
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionToggled.unsubscribe();
    this._subscriptionShowingSideBar.unsubscribe();
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    if (isMobile() && this.toggled) {
      this._documentEvent(event);
    }
  }

  private _documentEvent(event: MouseEvent): void {
    const target: Element = <Element>event.target;
    if (!target.closest('#cg-navbar-toggle-sidebar') && !target.closest('pl-sidebar #pl-sidebar-wrapper')) {
      this._setToggled(false);
    } else {
      event.stopPropagation();
    }
  }

  private _setToggled(value: boolean): void {
    this._plPageWrapperService.setToggled(value);
  }
}
