import {firstValueFrom} from 'rxjs';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {EDelphiNumberTypes} from 'pl-comps-angular';
import {EDGEMPSTipoExportacao, ENTITY_NAME_DGEMPS_FULL, IDGEMPSFullEntityService, IDGEMPSFullGetListagemMapasFichaParams} from '../../../portalrh/dgempsfull/dgempsFull.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IJsonReport} from '../../../../entities/reports/jsonReport.interface';
import {maxDateCG, minDateCG} from '../../../../../common/utils/utils';
import {ModuloComponent} from '../../../../components/module/module.component';

@Component({
  selector: 'module-impdadosemp',
  templateUrl: './impDadosEmp.module.component.html'
})
export class ImpDadosEmpModuleComponent extends ModuloComponent implements OnInit {
  @Input() public report: IJsonReport;

  public header: IDGEMPSFullGetListagemMapasFichaParams;
  public pdfUrl: string;
  public collapsedPesqAvancada: boolean;
  public showPdfViewer: boolean;

  private readonly _serviceDGEMPS: IDGEMPSFullEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.header = {
      deCod: 0,
      atecod: EDelphiNumberTypes.MaxSmallInt,
      deNome: '',
      ateNome: 'ZZZZ',
      deIdade: 16,
      ateIdade: 100,
      deSituacao: '0001',
      ateSituacao: '9999',
      deCategoria: '',
      ateCategoria: 'ZZZZ',
      deDepartamento: 0,
      ateDepartamento: EDelphiNumberTypes.MaxSmallInt,
      deDtNasc: minDateCG(),
      deDtValCt: minDateCG(),
      deDtValBI: minDateCG(),
      ateDtNasc: maxDateCG(),
      ateDtValCt: maxDateCG(),
      ateDtValBI: maxDateCG(),
      reportName: undefined,
      tipoExportacao: EDGEMPSTipoExportacao.PDF
    };
    this.collapsedPesqAvancada = false;
    this._serviceDGEMPS = this._entityServiceBuilder.build(ENTITY_NAME_DGEMPS_FULL);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'previsualizar',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-search fa-fw"></i>',
      class: 'btn-primary',
      caption: 'impdadosemp.previsualizar',
      click: () => this._previsualizar()
    });
    this.showPdfViewer = false;
  }

  private async _previsualizar(): Promise<void> {
    this.header.reportName = this.report?.name;
    this.pdfUrl = await firstValueFrom(this._serviceDGEMPS.getListagemMapasFichaParamsUrl(this.header));
    this.showPdfViewer = true;
    this.collapsedPesqAvancada = true;
  }
}
