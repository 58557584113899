<div class="proceDMRAT">
  <div *ngIf="isBlocked" class="blocked-by-other-user-container">
    <div class="block-inline">
      <i class="fa fa-lock" aria-hidden="true"></i>
    </div>
    <div class="block-inline">
      <h5 [translate]="'importadorSaftDirect.messages.importInProcessModalMessageByOtherUser'"></h5>
      <div class="blocked-by-username">{{ currentStatus.userStarted }}</div>
    </div>
  </div>

  <pl-nav-wizard *ngIf="!isBlocked" [definition]="definitionNavWizard" [onFinalize]="fnFinalize" [properties]="propertiesNavWizard" [callback]="plWizardCallback">
    <pl-nav-wizard-step icon="fa-home" caption="proceDMRAT.tabIntroCaption" [stepId]="proceDMRATSteps.INTRO">
      <div *plNavWizardStepContent>
        <h4 [translate]="'proceDMRAT.tabIntroTitle'"></h4>
        <p [translate]="'proceDMRAT.tabIntroSubTitle'"></p>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-th-list" caption="proceDMRAT.tabEmpresasCaption" [stepId]="proceDMRATSteps.EMPRESAS_SEL" [validator]="fnValidateStepEmpresasSel">
      <div *plNavWizardStepContent>
        <div class="item-group-dropdown" tabindex="-1" ngbDropdown container="body" [placement]="['right-bottom', 'bottom-right', 'right', 'auto']">
          <button type="button" class="btn btn-sm btn-info dropdown-toggle" ngbDropdownToggle>
            <span [translate]="'proceDMRAT.btn.ordenar'" [translateParams]="{orderby: orderBySelected}" class="caret"></span>
          </button>

          <div class="dropdown-menu" ngbDropdownMenu>
            <button type="button" class="dropdown-item" [translate]="'proceDMRAT.btn.dropdown.sortEmpresasByNEmpresa'" (click)="sortEmpresasByNEmpresa()"></button>
            <button type="button" class="dropdown-item" [translate]="'proceDMRAT.btn.dropdown.sortEmpresasByName'" (click)="sortEmpresasByName()"></button>
          </div>
        </div>
        <pl-multiselect class="tab-empresas" [model]="empresasSel" [source]="empresasSource" key="nEmpresa" [template]="msEmpresasTemplate" (evtChanged)="empresasChanged()"> </pl-multiselect>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-cog" caption="proceDMRAT.tabConfigCaption" [stepId]="proceDMRATSteps.PROC_CONFIG" [validator]="fnValidateStepProcConfig">
      <div *plNavWizardStepContent class="row">
        <div class="col-md-3">
          <div class="panel panel-warning">
            <div class="year-navi-container">
              <button type="button" class="btn btn-light btn-sm left" (click)="onDecYearClick()"><i class="fa fa-angle-left" aria-hidden="true"></i></button>
              <input class="form-control year-input" type="number" [ngModel]="selectedYear" (ngModelChange)="onMultiYearChange($event)" />
              <button type="button" class="btn btn-light btn-sm right" (click)="onIncYearClick()"><i class="fa fa-angle-right" aria-hidden="true"></i></button>
            </div>
          </div>

          <pl-table
            [definition]="multiDatasTableDef"
            [source]="fnMultiDatasTableSource"
            [callback]="multiDatasTableCallback"
            [properties]="multiDatasTableOptions"
            (evtSelect)="onSelectMultiDatasTable($event)">
          </pl-table>
        </div>

        <div class="col-md-9">
          <div class="alert alert-warning">
            <div [translate]="'proceDMRAT.procAlertMsg1'"></div>
            <div [translate]="'proceDMRAT.procAlertMsg2'"></div>
          </div>

          <dx-data-grid
            [cgDxDataGrid]="dataGridDefinitionProcExistentes"
            cgDxDataGridInstanceName="procExistentesTable"
            [dataSource]="dataGridDefinitionProcExistentes.dataSource"
            [selectedRowKeys]="selectedRowKeys"
            (selectedRowKeysChange)="selectedRowKeys = $event; onSelectProcExistentes()"
            (onInitialized)="onInitializedProcExistentes($event)">
            <div *dxTemplate="let item of 'toolbarTemplateProcExistentes'">
              <pl-edit type="checkbox" [model]="vizProcExistentes" (modelChange)="onVizProcExistentesChange($event)" [properties]="{label: 'proceDMRAT.vizProcExistentes'}"> </pl-edit>
            </div>
          </dx-data-grid>

          <hr />

          <pl-edit type="checkbox" [(model)]="decSubstituicao" [properties]="{label: 'proceDMRAT.decSubstituicao'}"></pl-edit>
        </div>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-bolt" caption="proceDMRAT.tabProcCaption" [stepId]="proceDMRATSteps.PROC" [hideNext]="true" [hidePrevious]="true">
      <div *plNavWizardStepContent class="proc-container">
        <div class="progress">
          <div class="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" [attr.aria-valuemin]="0" [attr.aria-valuemax]="100" style="width: 100%"></div>
        </div>
        <div class="procLabel" [translate]="pbProcLabel"></div>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-flag-checkered" caption="proceDMRAT.tabResultsCaption" [stepId]="proceDMRATSteps.PROC_RESULTS">
      <pl-tabs *plNavWizardStepContent [callback]="procResultsTabsCallback">
        <pl-tab caption="proceDMRAT.geracaoImpressao" [id]="tabIdRrocResults">
          <div *plTabContent>
            <dx-data-grid
              [cgDxDataGrid]="dataGridDefinitionProcResults"
              cgDxDataGridInstanceName="procResultsTable"
              [dataSource]="dataGridDefinitionProcResults.dataSource"
              (onInitialized)="onInitializedProcResults($event)"
              (onCellPrepared)="onCellPreparedProcResults($event)">
              <div *dxTemplate="let item of 'toolbarTemplateProcResults'">
                <pl-toolbar [instanceId]="toolbarInstIdProcResults"></pl-toolbar>
              </div>

              <div *dxTemplate="let item of 'cellTemplateStatusImage'">
                <i aria-hidden="true" [ngClass]="item.data.statusImage"></i>
              </div>

              <div *dxTemplate="let item of 'cellTemplateBtnProcResults'">
                <pl-button type="button" klass="btn btn-info btn-xs" (evtClicked)="dmratViewInfoClick(item.data)"><i class="fa fa-info-circle" aria-hidden="true"></i></pl-button>
              </div>

              <div *dxTemplate="let item of 'masterDetailProcResults'">
                <pl-alert type="error"> <i class="fa fa-times-circle" aria-hidden="true"></i>&nbsp;<span [translate]="item.data.wsMensagemErro"></span> </pl-alert>
              </div>
            </dx-data-grid>
          </div>
        </pl-tab>

        <pl-tab caption="proceDMRAT.erros" [id]="tabIdRrocResultsErrors">
          <div *plTabContent>
            <dx-data-grid
              [cgDxDataGrid]="dataGridDefinitionProcResultsErrors"
              cgDxDataGridInstanceName="procResultsErrorsTable"
              [dataSource]="dataGridDefinitionProcResultsErrors.dataSource"
              (onInitialized)="onInitializedProcResultsErrors($event)">
            </dx-data-grid>
          </div>
        </pl-tab>
      </pl-tabs>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
