import {Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {KEYCODES} from '../common/constants';
import {parseExpressionToString, toInteger} from '../common/utilities/utilities';
import {TCGCExpression} from '../common/interface';

const FOCUSABLE_QUERY = 'input,select,textarea,button,input:disabled,select:disabled,textarea:disabled';

@Directive({
  selector: '[plNavigation]'
})
export class PlNavigationDirective implements OnInit, OnChanges, OnDestroy {
  @Input() public stepOver: TCGCExpression;
  @Input() public selector: string;
  @Input() public parent: string;

  private readonly _element: JQuery;
  private _elementsToSkip: Array<string>;

  constructor(
    private readonly _elementRef: ElementRef
  ) {
    this._elementsToSkip = [];
    this._element = $(this._elementRef.nativeElement);
  }

  public ngOnInit(): void {
    if (this.stepOver) {
      this._elementsToSkip = parseExpressionToString(this.stepOver).split(',');
    }
    this._element.on('keydown', this._eventKeydownHandler);
  }

  public ngOnChanges({stepOver}: SimpleChanges): void {
    if (stepOver && !stepOver.isFirstChange()) {
      this._elementsToSkip = parseExpressionToString(this.stepOver).split(',');
    }
  }

  public ngOnDestroy(): void {
    this._element.off('keydown', this._eventKeydownHandler);
  }

  private readonly _eventKeydownHandler: (event: any) => void = (event: KeyboardEvent) => {
    this._eventKeydown(event);
  };

  private _eventKeydown(event: KeyboardEvent): void {
    const $active: any = $('input:focus,select:focus,textarea:focus,button:focus', this._element);
    const element = this.selector
      ? $active.closest(this.selector)
      : $active;
    const position = toInteger(element.index()) + 1;
    let $next;

    switch (event.key) {
      case KEYCODES.LEFT:
        $next = this._findHorizontal($active, 'prev');
        break;
      case KEYCODES.UP:
        $next = this._findVertical($active, position, 'prev');
        break;
      case KEYCODES.RIGHT:
        $next = this._findHorizontal($active, 'next');
        break;
      case KEYCODES.DOWN:
        $next = this._findVertical($active, position, 'next');
        break;
    }

    if ($next?.length) {
      event.preventDefault();
      $next.trigger('focus');
      $next.select();
    }
  }

  private _shouldStepOver(element: JQuery): boolean {
    if (this._elementsToSkip.includes(element.attr('name'))) {
      for (const elementToSkip of this._elementsToSkip) {
        if (this.parent) {
          element = element.closest(this.parent);
        }
        const $input = element.find(`input[name="${elementToSkip}"]`);
        if (!$input.length) {
          throw new Error(`Found an invalid element to skip with the name: ${elementToSkip}`);
        }
        if ($input.val()
          .toString()
          .trim()) {
          return true;
        }
      }
    }
    return false;
  }

  private _findHorizontal($active: JQuery, type: 'prev' | 'next'): JQuery {
    if (type !== 'prev' && type !== 'next') {
      return undefined;
    }
    if (this.selector) {
      $active = $active.closest(this.selector);
    }
    let element: JQuery = $active[type]();
    if (!element.is(FOCUSABLE_QUERY)) {
      element = element.find(FOCUSABLE_QUERY);
    }
    while (element.is(':disabled') || !element.is(':visible') || this._shouldStepOver(element)) {
      if (this.selector) {
        element = element.closest(this.selector);
      }
      element = element[type]().find(FOCUSABLE_QUERY);
      if ((element.is(':enabled') && element.is(':visible') || !element.length) && !this._shouldStepOver(element)) {
        break;
      }
    }
    return element;
  }

  private _findVertical($active: JQuery, position: number, type: 'prev' | 'next'): JQuery {
    if (type !== 'prev' && type !== 'next') {
      return undefined;
    }
    if (this.parent) {
      $active = $active.closest(this.parent);
    }
    const selector = this.selector
      ? `${this.selector}:nth-child(${position})`
      : this._focusableQueryWithPosition(position);
    let element: JQuery = $active[type]().find(selector);
    if (!element.is(FOCUSABLE_QUERY)) {
      element = element.find(FOCUSABLE_QUERY);
    }
    while (element.is(':disabled') || !element.is(':visible') || this._shouldStepOver(element)) {
      element = element.closest(this.parent)[type]()
        .find(selector);
      if (!element.is(FOCUSABLE_QUERY)) {
        element = element.find(FOCUSABLE_QUERY);
      }
      if ((element.is(':enabled') && element.is(':visible') || !element.length) && !this._shouldStepOver(element)) {
        break;
      }
    }
    return element;
  }

  private _focusableQueryWithPosition(position: number): string {
    return FOCUSABLE_QUERY
      .split(',')
      .map((query: string) => `${query}:nth-child(${position})`)
      .join(',');
  }
}
