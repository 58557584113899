<div class="entity-detail-form">
  <pl-form [plPromise]="promise">
    <pl-group>
      <label [translate]="'prheventosconfig.fields.tipoEventoPlaceholder'"></label>
      <edit>
        <pl-autocomplete [source]="tiposEventos" [model]="tipoEvento.nome" (evtSelected)="evtTipoEventoChanged($event)" [allowInvalid]="false" [rowTemplate]="'nome'" [output]="'nome'">
        </pl-autocomplete>
      </edit>
    </pl-group>

    <br />

    <dx-data-grid [cgDxDataGrid]="definitionConfigs" [dataSource]="definitionConfigs.dataSource" cgDxDataGridInstanceName="configEventos" (onInitialized)="onDataGridInitializedConfigEventos($event)">
      <div *dxTemplate="let item of 'templateToolbarActions'">
        <pl-button klass="btn btn-sm btn-primary action-ok" (evtClicked)="createConfig()"> <i class="fa fa-fw fa-plus-square-o"></i>&nbsp;<span [translate]="'global.btn.add'"></span> </pl-button>
      </div>

      <div *dxTemplate="let itemAction of 'actions'" class="text-center">
        <button type="button" class="btn btn-xs btn-warning" (click)="edit(itemAction.data)">
          <i class="fa fa-fw fa-pencil"></i>
        </button>
        <button type="button" class="btn btn-xs btn-danger" (click)="delete(itemAction.data.codPRHEventosConfig)">
          <i class="fa fa-fw fa-trash"></i>
        </button>
      </div>
    </dx-data-grid>
  </pl-form>
</div>
