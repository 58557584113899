<div class="modelo22-contasdef-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'modelo22.modal.rosto.contasdefall.title'"></h5>
  </div>

  <div class="modal-body">
    <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="contasdefall" [dataSource]="dataGridDefinition.dataSource"></dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-submit" id="copiarparaempresas" [onClick]="fnOpenContasDefCopy">
      <i class="fa fa-fw fa-copy"></i>&nbsp;<span [translate]="'modelo22.modal.rosto.contasdefall.btn.copiarparaempresas'"></span>
    </pl-button>
    <pl-button klass="btn btn-sm btn-secondary" id="reporcontas" [onClick]="fnReporConfig">
      <i class="fa fa-fw fa-refresh"></i>&nbsp;<span [translate]="'modelo22.modal.rosto.contasdefall.btn.reporcontas'"></span>
    </pl-button>
    <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
