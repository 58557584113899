<div class="consultenvios-impresumo-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'rhprocsegsocialconsultenvios.modal.title'"></h5>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-7">
        <pl-group>
          <label [translate]="'proceSegSocial.listagem'"></label>
          <edit>
            <pl-edit [type]="reportType" [model]="reportModel" (modelChange)="onReportChange($event)" [properties]="{entity: {outputKey: 'name', outputDescription: 'title'}}"></pl-edit>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-5">
        <pl-group>
          <label [translate]="'proceSegSocial.nibADebitar'"></label>
          <edit>
            <pl-edit type="text" [(model)]="nibADebitar"></pl-edit>
          </edit>
          <actions>
            <pl-button type="button" klass="btn btn-success" (evtClicked)="loadPdf()">
              <i class="fa fa-file-pdf-o" aria-hidden="true"></i>&nbsp;<span [translate]="'proceSegSocial.obterPdf'"></span>
            </pl-button>
          </actions>
        </pl-group>
      </div>
    </div>

    <cg-pdf-viewer [pdfSrc]="pdfBlob"></cg-pdf-viewer>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
  </div>
</div>
