<pl-form class="modal-docsdigitais-export-contab-digital">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'arquivodigital.gestaodocsdigitalizados.messages.modal.export.title'"></h5>
  </div>

  <div class="modal-body" [plPromise]="modalPromise">
    <pl-group>
      <label [translate]="'arquivodigital.gestaodocsdigitalizados.messages.modal.export.ano'"></label>
      <edit>
        <pl-select
          [model]="filterModel.ano"
          (modelChange)="onYearChange($event)"
          [source]="anosSource"
          [rowTemplate]="anosRowTemplate"
          [output]="anosOutput"
          [clearable]="false"
          [disabled]="filterModel.exportaNaoClassificados">
        </pl-select>
      </edit>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.gestaodocsdigitalizados.messages.modal.export.periodoDe'"></label>
        <edit>
          <pl-autocomplete
            [source]="periodosSource"
            [model]="filterModel.periodoDe"
            (modelChange)="onPeriodoDeChange($event)"
            [rowTemplate]="periodosRowTemplate"
            [validateFn]="validatePeriodo"
            [allowEmpty]="false"
            [allowInvalid]="false"
            [output]="periodosOutput"
            [properties]="{disabled: filterModel.exportaNaoClassificados}"
            plAutoFocus></pl-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.gestaodocsdigitalizados.messages.modal.export.periodoAte'"></label>
        <edit>
          <pl-autocomplete
            [source]="periodosSource"
            [model]="filterModel.periodoAte"
            (modelChange)="onPeriodoAteChange($event)"
            [rowTemplate]="periodosRowTemplate"
            [validateFn]="validatePeriodo"
            [allowEmpty]="false"
            [allowInvalid]="false"
            [output]="periodosOutput"
            [properties]="{disabled: filterModel.exportaNaoClassificados}">
          </pl-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.gestaodocsdigitalizados.messages.modal.export.diarioDe'"></label>
        <edit>
          <entity-autocomplete
            entity="diarios"
            attrName="diarioDe"
            [model]="filterModel"
            [(selectedKey)]="filterModel.diarioDe"
            [fieldsMap]="{nDiario: 'diarioDe'}"
            output="key"
            [properties]="{disabled: filterModel.exportaNaoClassificados}">
          </entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.gestaodocsdigitalizados.messages.modal.export.diariosAte'"></label>
        <edit>
          <entity-autocomplete
            entity="diarios"
            attrName="diarioAte"
            [model]="filterModel"
            [(selectedKey)]="filterModel.diarioAte"
            [fieldsMap]="{nDiario: 'diarioAte'}"
            output="key"
            [properties]="{disabled: filterModel.exportaNaoClassificados}">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'arquivodigital.gestaodocsdigitalizados.messages.modal.export.nifDe'"></label>
        <edit>
          <entity-autocomplete
            entity="nifs"
            attrName="nifDe"
            [model]="filterModel"
            [(selectedKey)]="filterModel.nifDe"
            [fieldsMap]="{nContribuinte: 'nifDe'}"
            output="key"
            [properties]="{disabled: filterModel.exportaNaoClassificados}">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'arquivodigital.gestaodocsdigitalizados.messages.modal.export.nifAte'"></label>
        <edit>
          <entity-autocomplete
            entity="nifs"
            attrName="nifAte"
            [model]="filterModel"
            [(selectedKey)]="filterModel.nifAte"
            [fieldsMap]="{nContribuinte: 'nifAte'}"
            output="key"
            [properties]="{disabled: filterModel.exportaNaoClassificados}">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <edit>
          <pl-edit
            type="checkbox"
            [model]="filterModel.exportaNaoClassificados"
            (modelChange)="changeNaoClassificadosCheckBox($event)"
            [properties]="{label: 'arquivodigital.gestaodocsdigitalizados.messages.modal.export.exportaNaoClassificados'}">
          </pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <edit>
          <pl-edit
            type="checkbox"
            [(model)]="filterModel.exportaApenasRelevantes"
            [properties]="{label: 'arquivodigital.gestaodocsdigitalizados.messages.modal.export.exportaApenasRelevantes', disabled: filterModel.exportaNaoClassificados}">
          </pl-edit> </edit
      ></pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <button type="button" class="btn btn-sm btn-light" (click)="selectFolderModal()">
          <i class="fa fa-fw fa-folder-open-o"></i>&nbsp;<span [translate]="'arquivodigital.gestaodocsdigitalizados.messages.modal.export.searchExportFolder'"></span>
        </button>
      </pl-group>
    </pl-group>

    <pl-group *ngIf="filterModel.folderId !== ''">
      <label [translate]="'arquivodigital.gestaodocsdigitalizados.messages.modal.export.folder'"></label>
      <edit>
        <pl-edit type="text" [model]="folderPath" [properties]="{disabled: true}">
          <div *inputGroup="{klass: 'hollow-form docs-digitais-export-reset-folder'}">
            <pl-tooltip [config]="{text: 'arquivodigital.gestaodocsdigitalizados.messages.modal.export.searchAllFolders', placement: 'left top-left', container: 'body'}">
              <i *plTooltipContent class="fa fa-fw fa-undo" aria-hidden="true" (click)="resetFolder()"></i>
            </pl-tooltip>
          </div>
        </pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="button">
    <button type="button" class="btn btn-sm btn-primary" data-focus (click)="close()">
      <i class="fa fa-download" aria-hidden="true"></i>&nbsp;<span [translate]="'arquivodigital.gestaodocsdigitalizados.messages.modal.export.exportar'"></span>
    </button>

    <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</pl-form>
