<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'docfasNums.modal.comunicacao.finalizar.title'"></h4>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <pl-group>
        <label [translate]="'docfasNums.modal.comunicacao.fields.serie'"></label>
        <edit>
          <pl-edit type="text" [model]="serie" [properties]="{readonly: true}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label class="control-label" [translate]="'docfasNums.modal.comunicacao.fields.seqUltimoDocEmitido'"></label>
        <edit>
          <pl-edit type="text" [model]="seqUltimoDocEmitido" [properties]="{readonly: true}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label class="control-label" [translate]="'docfasNums.modal.comunicacao.finalizar.justificacao'"></label>
        <edit>
          <textarea [rows]="5" class="form-control" [(ngModel)]="justificacao" required></textarea>
        </edit>
      </pl-group>
    </div>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-success btn-sm" name="btnFinalizarOk" [disabled]="closeDisabled" [click]="close" plPromise>
      <i class="fa fa-sucess"></i>&nbsp;<span [translate]="'global.btn.ok'"></span>
    </button>

    <button type="button" class="btn btn-light btn-sm action-dismiss" [disabled]="closeDisabled" (click)="dismiss()">
      <i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span>
    </button>
  </div>
</pl-form>
