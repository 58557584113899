import {ModuloComponent} from '../../../../components/module/module.component';
import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IPlToolbarItem, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {TDate} from '../../../../../common/dates';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowExpanding
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {IJsonAnaliseMargemLucro, IJsonAnaliseMargemLucroDetail} from '../jsonAnalisemargemlucro.module.interface';
import {AnaliseMargemLucroService} from '../analisemargemlucro.module.service';
import {EModoFuncionamentoAnaliseMargemLucro} from '../../../grupoDocfaConfiguracoes/grupoDocfaConfiguracoes.module.interface';
import moment from 'moment';
import {GrupoDocfaConfiguracoesService} from '../../../grupoDocfaConfiguracoes/grupoDocfaConfiguracoes.module.service';
import {AnaliseMargemLucroPrintModalComponent} from '../modal/analisemargemlucro.print.modal';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';
import {GrupoDocfaConfiguracoesModalComponent} from '../../../grupoDocfaConfiguracoes/modals/grupoDocfaConfiguracoes.modal.component';
import {focusElement} from '../../../../../common/utils/element.utils';
import {HttpResponse} from '@angular/common/http';
import {IApiQueryResponse} from '../../../../services/api/api.service.interface';
import {IJsonGrupoDocfaConfiguracoes} from '../../../grupoDocfaConfiguracoes/jsonGrupoDocfaConfiguracoes.module.interface';
import {DocumentoDetailModalComponent} from '../../../../entities/docscomerciais/modals/detalhe/documento.detail.modal.component';
import {DocumentosService} from '../../../../entities/docscomerciais/service/documentos.entity.service';

@Component({
  selector: 'module-analise-margem-lucro',
  templateUrl: './analisemargemlucro.module.component.html'
})
export class AnaliseMargemLucroModuleComponent extends ModuloComponent implements OnInit {
  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonAnaliseMargemLucro, IJsonAnaliseMargemLucro>;
  public readonly dataGridDetailDefinition: IDevExpressDataGrid<IJsonAnaliseMargemLucroDetail, IJsonAnaliseMargemLucroDetail>;

  public estados: Array<string>;
  public estadoSelecionado: string;
  public modo: number;
  public dataDe: TDate;
  public dataAte: TDate;
  public nRefProcesso: string;
  public nCCusto: string;

  private readonly _btnPrintToolbar: IPlToolbarItem;
  private readonly _btnConfigsToolbar: IPlToolbarItem;

  private _dataGridInstance: dxDataGrid<IJsonAnaliseMargemLucro, IJsonAnaliseMargemLucro>;
  private _dataGridInstanceDetail: dxDataGrid<IJsonAnaliseMargemLucroDetail, IJsonAnaliseMargemLucroDetail>;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _analiseMargemLucroService: AnaliseMargemLucroService,
    private readonly _grupoDocfaConfiguracoesService: GrupoDocfaConfiguracoesService,
    private readonly _documentosService: DocumentosService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);

    this.estados = [
      this._plTranslateService.translate('analisemargemlucro.estado.aberto'),
      this._plTranslateService.translate('analisemargemlucro.estado.terminado'),
      this._plTranslateService.translate('analisemargemlucro.estado.ambos')
    ];

    this.estadoSelecionado = this.estados[0];
    this.nRefProcesso = '';
    this.nCCusto = '';
    this.dataDe = moment().startOf('year');
    this.dataAte = moment();

    this.dataGridDefinition = {
      columns: [
        {
          caption: '',
          fixed: true,
          fixedPosition: 'left',
          allowHeaderFiltering: false,
          allowFiltering: false,
          allowSearch: false,
          columns: [
            {dataField: 'nRefProcesso', dataType: 'string', caption: 'analisemargemlucro.fields.nrefprocesso', visible: true, showInColumnChooser: true},
            {dataField: 'nomeProcesso', dataType: 'string', caption: 'analisemargemlucro.fields.nomeprocesso', visible: true, showInColumnChooser: true},
            {dataField: 'nCCusto', dataType: 'string', caption: 'analisemargemlucro.fields.nccusto', visible: false, showInColumnChooser: false},
            {dataField: 'nomeCCusto', dataType: 'string', caption: 'analisemargemlucro.fields.nomeccusto', visible: false, showInColumnChooser: false},
            {dataField: 'nConta', dataType: 'string', caption: 'analisemargemlucro.fields.nconta'},
            {dataField: 'nomeConta', dataType: 'string', caption: 'analisemargemlucro.fields.nomeconta'}
          ]
        },
        {
          caption: 'analisemargemlucro.bands.gastos',
          fixed: true,
          allowHeaderFiltering: false,
          allowFiltering: false,
          allowSearch: false,
          columns: [
            {dataField: 'custoEstimado', dataType: 'double', caption: 'analisemargemlucro.fields.estimado', allowHeaderFiltering: false, allowFiltering: false, allowSearch: false},
            {dataField: 'custoReal', dataType: 'double', caption: 'analisemargemlucro.fields.real', allowHeaderFiltering: false, allowFiltering: false, allowSearch: false}
          ]
        },
        {
          caption: 'analisemargemlucro.bands.rendimentos',
          fixed: true,
          allowHeaderFiltering: false,
          allowFiltering: false,
          allowSearch: false,
          columns: [
            {dataField: 'proveitoEstimado', dataType: 'double', caption: 'analisemargemlucro.fields.estimado', allowHeaderFiltering: false, allowFiltering: false, allowSearch: false},
            {dataField: 'proveitoReal', dataType: 'double', caption: 'analisemargemlucro.fields.real', allowHeaderFiltering: false, allowFiltering: false, allowSearch: false}
          ]
        },
        {
          caption: 'analisemargemlucro.bands.resultado',
          fixed: true,
          allowHeaderFiltering: false,
          allowFiltering: false,
          allowSearch: false,
          columns: [
            {dataField: 'resultadoEstimado', dataType: 'double', caption: 'analisemargemlucro.fields.estimado', allowHeaderFiltering: false, allowFiltering: false, allowSearch: false},
            {dataField: 'resultadoReal', dataType: 'double', caption: 'analisemargemlucro.fields.real', allowHeaderFiltering: false, allowFiltering: false, allowSearch: false}
          ]
        },
        {
          caption: 'analisemargemlucro.bands.margem',
          fixed: true,
          allowHeaderFiltering: false,
          allowFiltering: false,
          allowSearch: false,
          columns: [
            {dataField: 'margemEstimado', dataType: 'double', caption: 'analisemargemlucro.fields.estimado', allowHeaderFiltering: false, allowFiltering: false, allowSearch: false},
            {dataField: 'margemReal', dataType: 'double', caption: 'analisemargemlucro.fields.real', allowHeaderFiltering: false, allowFiltering: false, allowSearch: false}
          ]
        }
      ],
      dataSource: [],
      masterDetail: {enabled: true, template: 'templateMasterDetail'},
      export: {filename: 'analiseMargemLucro'},
      columnHidingEnabled: false,
      remoteOperations: false
    };

    this.dataGridDetailDefinition = {
      columns: [
        {dataField: 'tipologia', dataType: 'string', caption: 'analisemargemlucro.detail.fields.tipologia'},
        {dataField: 'nDocfa', dataType: 'number', caption: 'analisemargemlucro.detail.fields.ndocfa', visible: false},
        {dataField: 'nomeDocfa', dataType: 'string', caption: 'analisemargemlucro.detail.fields.nomedocfa'},
        {dataField: 'nNumer', dataType: 'string', caption: 'analisemargemlucro.detail.fields.nnumer', visible: false},
        {dataField: 'nDocumento', dataType: 'string', caption: 'analisemargemlucro.detail.fields.ndocumento', visible: false},
        {dataField: 'nDocAsString', dataType: 'string', caption: 'analisemargemlucro.detail.fields.ndocasstring'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'analisemargemlucro.detail.fields.datadoc'},
        {dataField: 'nConta', dataType: 'string', caption: 'analisemargemlucro.detail.fields.nconta'},
        {dataField: 'nomeConta', dataType: 'string', caption: 'analisemargemlucro.detail.fields.nomeconta'},
        {dataField: 'nArtigo', dataType: 'string', caption: 'analisemargemlucro.detail.fields.nartigo'},
        {dataField: 'nomeArtigo', dataType: 'string', caption: 'analisemargemlucro.detail.fields.nomeartigo'},
        {dataField: 'nFamilia', dataType: 'number', caption: 'analisemargemlucro.detail.fields.nFamilia', visible: false},
        {dataField: 'nomeFamilia', dataType: 'string', caption: 'analisemargemlucro.detail.fields.nomeFamilia', visible: false},
        {dataField: 'nTpArt', dataType: 'number', caption: 'analisemargemlucro.detail.fields.nTpArt', visible: false},
        {dataField: 'nomeTpArt', dataType: 'string', caption: 'analisemargemlucro.detail.fields.nomeTpArt', visible: false},
        {dataField: 'nGrFamilia', dataType: 'number', caption: 'analisemargemlucro.detail.fields.nGrFamilia', visible: false},
        {dataField: 'nomeGrFamilia', dataType: 'string', caption: 'analisemargemlucro.detail.fields.nomeGrFamilia', visible: false},
        {dataField: 'nSubFa', dataType: 'number', caption: 'analisemargemlucro.detail.fields.nSubFa', visible: false},
        {dataField: 'nomeSubFa', dataType: 'string', caption: 'analisemargemlucro.detail.fields.nomeSubFa', visible: false},
        {dataField: 'qtd1', dataType: 'double', caption: 'analisemargemlucro.detail.fields.qtd1'},
        {dataField: 'unidade', dataType: 'string', caption: 'analisemargemlucro.detail.fields.unidade'},
        {dataField: 'prVendaQtd1', dataType: 'double', caption: 'analisemargemlucro.detail.fields.prvendaqtd1'},
        {dataField: 'liquido', dataType: 'double', caption: 'analisemargemlucro.detail.fields.liquido'},
        {dataField: 'valorIva', dataType: 'double', caption: 'analisemargemlucro.detail.fields.valoriva'},
        {dataField: 'prVendIvaInc', dataType: 'double', caption: 'analisemargemlucro.detail.fields.prvendivainc'},
        {
          dataField: 'vf_actions',
          type: 'buttons',
          caption: ' ',
          cellTemplate: 'actions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false,
          showInColumnChooser: false,
          visible: true
        }
      ],
      dataSource: [],
      filterRow: {visible: false},
      headerFilter: {visible: false},
      searchPanel: {visible: false},
      export: {filename: 'analiseMargemLucroDetail'},
      remoteOperations: false
    };

    this._btnPrintToolbar = {
      id: 'docsComerciaisEstatisticas-print-toolbar',
      order: 1,
      type: 'button',
      class: 'btn-light',
      iconLeft: '<i class="fa fa-fw fa-print"></i>',
      caption: 'global.btn.print',
      click: () => this._printModal()
    };

    this._btnConfigsToolbar = {
      id: 'config',
      order: this._btnPrintToolbar.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-cog fa-fw"></i>',
      caption: 'global.btn.config',
      class: 'btn-light',
      click: () => this._configsModal()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnPrintToolbar);
    this.toolbar.addButton(this._btnConfigsToolbar);

    this._refreshConfiguracoes();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonAnaliseMargemLucro, IJsonAnaliseMargemLucro>): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public async onCellClick(event: IDevExpressDataGridEventOnCellClick<IJsonAnaliseMargemLucro, IJsonAnaliseMargemLucro>): Promise<void> {
    await devExpressDataGridExpandDetailHandler(event);
  }

  public onInitializedDetail({component}: IDevExpressDataGridEventOnInitialized<IJsonAnaliseMargemLucroDetail, IJsonAnaliseMargemLucroDetail>): void {
    this._dataGridInstanceDetail = component;
  }

  public onContentReadyDetail(): void {
    this._dataGridInstanceDetail.endCustomLoading();
  }

  public onRowExpand(event: IDevExpressDataGridEventOnRowExpanding<IJsonAnaliseMargemLucro, IJsonAnaliseMargemLucro>): void {
    const item: IJsonAnaliseMargemLucro = event.key;
    if (!item) {
      return;
    }
    item.detalhe = [];
    const itemCodigo: string = this.modo === EModoFuncionamentoAnaliseMargemLucro.Processo ? item.nRefProcesso : item.nCCusto;

    if (itemCodigo) {
      this._analiseMargemLucroService.getDetalhe(this.modo, itemCodigo, this.dataDe, this.dataAte).then((response: HttpResponse<IApiQueryResponse<IJsonAnaliseMargemLucroDetail>>) => {
        if (response?.body) {
          item.detalhe = response?.body?.list;
        }
      });
    }
  }

  public verDocumento(faccbId: number): Promise<void> {
    return this._documentosService.getDoc(faccbId).then((response) => {
      const modalRef = this._cgModalService.showVanilla(DocumentoDetailModalComponent);
      const componentInstance: DocumentoDetailModalComponent = modalRef.componentInstance;
      componentInstance.doc = response.body;
      return modalRef.result;
    });
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonAnaliseMargemLucro>): void {
    if (event.rowType !== 'data') {
      return;
    }
    if (event.column.dataField === 'custoEstimado' && event.data.custoEstimado) {
      event.cellElement.classList.add('text-danger');
    }
    if (event.column.dataField === 'custoReal' && event.data.custoReal) {
      event.cellElement.classList.add('text-danger');
    }
    if (event.column.dataField === 'proveitoEstimado' && event.data.proveitoEstimado) {
      event.cellElement.classList.add('text-success');
    }
    if (event.column.dataField === 'proveitoReal' && event.data.proveitoReal) {
      event.cellElement.classList.add('text-success');
    }
    if (event.column.dataField === 'resultadoEstimado' && event.data.resultadoEstimado) {
      event.cellElement.classList.add('fw-bold');
    }
    if (event.column.dataField === 'resultadoReal' && event.data.resultadoReal) {
      event.cellElement.classList.add('fw-bold');
    }
    if (event.column.dataField === 'margemEstimado' && event.data.margemEstimado) {
      event.cellElement.classList.add('fw-bold');
    }
    if (event.column.dataField === 'margemReal' && event.data.margemReal) {
      event.cellElement.classList.add('fw-bold');
    }
  }

  public fnPesquisar = (): Promise<void> => this._pesquisar();

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _pesquisar(): Promise<void> {
    const estado: number = this.estados.indexOf(this.estadoSelecionado);
    const codigo: string = this.modo === EModoFuncionamentoAnaliseMargemLucro.Processo ? this.nRefProcesso : this.nCCusto;

    if (this.dataAte < this.dataDe) {
      const error: string = this._plTranslateService.translate('analisemargemlucro.messages.dataAteMenorDataDe');
      this._setFocusAteDataDoc();
      this._plAlertService.error(error);
      return Promise.reject(new Error(error));
    }

    return this._analiseMargemLucroService.get(this.modo, this.dataDe, this.dataAte, estado, codigo).then((response: HttpResponse<IApiQueryResponse<IJsonAnaliseMargemLucro>>) => {
      if (response?.body) {
        this.dataGridDefinition.dataSource = response?.body?.list;
        if (response?.body?.list?.length > 0) {
          this._cardPanel.collapse();
          return;
        }
        this._plAlertService.info(this._translateService.instant('global.text.searchNoData'));
      } else {
        this.dataGridDefinition.dataSource = [];
      }
    });
  }

  private _setGridColumnsVisibleOrNot(dataGridInstance: dxDataGrid, modoProcesso: boolean): void {
    // Processo
    dataGridInstance.columnOption('nRefProcesso', 'visible', modoProcesso);
    dataGridInstance.columnOption('nRefProcesso', 'showInColumnChooser', modoProcesso);
    dataGridInstance.columnOption('nomeProcesso', 'visible', modoProcesso);
    dataGridInstance.columnOption('nomeProcesso', 'showInColumnChooser', modoProcesso);
    // Centro de Custo
    dataGridInstance.columnOption('nCCusto', 'visible', !modoProcesso);
    dataGridInstance.columnOption('nCCusto', 'showInColumnChooser', !modoProcesso);
    dataGridInstance.columnOption('nomeCCusto', 'visible', !modoProcesso);
    dataGridInstance.columnOption('nomeCCusto', 'showInColumnChooser', !modoProcesso);
    // Conta
    dataGridInstance.columnOption('nConta', 'visible', modoProcesso);
    dataGridInstance.columnOption('nConta', 'showInColumnChooser', modoProcesso);
    dataGridInstance.columnOption('nomeConta', 'visible', modoProcesso);
    dataGridInstance.columnOption('nomeConta', 'showInColumnChooser', modoProcesso);
  }

  private _printModal(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(AnaliseMargemLucroPrintModalComponent, {size: 'xl'});
    const componentInstance: AnaliseMargemLucroPrintModalComponent = modalInstance.componentInstance;
    componentInstance.modo = this.modo;
    componentInstance.dataDe = this.dataDe;
    componentInstance.dataAte = this.dataAte;
    componentInstance.estado = this.estados.indexOf(this.estadoSelecionado);
    componentInstance.codigo = this.modo === EModoFuncionamentoAnaliseMargemLucro.Processo ? this.nRefProcesso : this.nCCusto;
    return modalInstance.result;
  }

  private _configsModal(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(GrupoDocfaConfiguracoesModalComponent, {size: 'xl'});
    return modalInstance.result.then(() => {
      this._refreshConfiguracoes(true);
    });
  }

  private _setFocusAteDataDoc(): void {
    focusElement(this._element.querySelector<HTMLInputElement>('.listagens input[name="dataate"]'));
  }

  private _refreshConfiguracoes(onChangeRefresh: boolean = false): void {
    this._grupoDocfaConfiguracoesService.getConfiguracoes().then((response: HttpResponse<IJsonGrupoDocfaConfiguracoes>) => {
      if (response) {
        const modoChanged: boolean = this.modo !== response.body.modoFuncionamento;
        this.modo = response.body.modoFuncionamento;
        this._setGridColumnsVisibleOrNot(this._dataGridInstance, this.modo === EModoFuncionamentoAnaliseMargemLucro.Processo);
        if (onChangeRefresh && modoChanged) {
          if (this._cardPanel.collapsed) {
            this._cardPanel.expand();
          }
          this.dataGridDefinition.dataSource = [];
          this.dataGridDetailDefinition.dataSource = [];
        }
      }
    });
  }
}
