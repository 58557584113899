import {merge} from 'lodash-es';
import {combineLatest, Subscription} from 'rxjs';
import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, ValidatorFn} from '@angular/forms';
import {EVENT_SUPPORTED_BEFORE_INPUT} from '../../../keyboard/keyboard.interface';
import {IPlEditComponentOptionsInputNumber} from './edit.number.component.interface';
import {IPlFormatConfig} from '../../../common/format/format.service.interface';
import {IPlI18nNumberFormat} from '../../../i18n';
import {isDefinedNotNull, isFunction, isUndefinedOrNull, removeAllNotLast} from '../../../common/utilities/utilities';
import {KEYCODES} from '../../../common/constants';
import {PlCompsService} from '../../../common/service/comps.service';
import {PlEditInputTypeComponent} from '../../generic/input/edit.input.type.component';
import {PlFormatService} from '../../../common/format/format.service';
import {PlI18nPlNumberService} from '../../../i18n/i18n.plNumber.service';
import {PlKeyboardService} from '../../../keyboard/keyboard.service';

@Component({
  selector: 'pl-edit-number',
  templateUrl: './edit.number.component.html'
})
export class PlEditNumberComponent extends PlEditInputTypeComponent<number, IPlEditComponentOptionsInputNumber> implements OnInit, OnDestroy {
  private readonly _subscriptionFormat: Subscription;
  private _format: IPlI18nNumberFormat & IPlFormatConfig;
  private _isEditing: boolean;
  private _previousViewValue: string;
  private _defaultDecimalsValue: string;
  private _preventModelChangeHandler: boolean;
  private _preventBeforeInput: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plKeyboardService: PlKeyboardService,
    private readonly _plFormatService: PlFormatService,
    private readonly _plCompsService: PlCompsService,
    private readonly _plI18nPlNumberService: PlI18nPlNumberService
  ) {
    super('text', _injector);
    this._subscriptionFormat = combineLatest([this._plFormatService.format, this._plI18nPlNumberService.format]).subscribe(([format, numberFormat]: [IPlFormatConfig, IPlI18nNumberFormat]) => {
      this._format = {...numberFormat, ...format};
    });
    this._defaultOptions = Object.freeze<IPlEditComponentOptionsInputNumber>(
      merge({}, this._defaultOptions, this._format, {
        alignRight: false
      })
    );
    this._isEditing = false;
    this._previousViewValue = '';
    this._defaultDecimalsValue = '';
    this._preventModelChangeHandler = false;
    this._preventBeforeInput = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._defaultDecimalsValue = this._defaultDecimals();
    this.setValidators([this._requiredValidator(), this._minValidator(), this._maxValidator()]);
    if (this.value || this.value === 0) {
      this._modelChangeHandler(this.value);
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionFormat.unsubscribe();
  }

  public updateValue(value: number): void {
    this._modelChangeHandler(value);
    this.value = value;
  }

  public render(value: any = this.viewValue): Promise<void> {
    this._preventModelChangeHandler = true;
    if (this.formControl.updateOn === 'blur') {
      this._isEditing = false;
    }
    if (!value) {
      this.value = undefined;
      return super.render();
    }

    // Clipboard paste + max number fix
    // ^(?!.*ab).*$
    const regex = new RegExp(`[^\\d\\${this.options.decimalsSeparator}\\-]`);
    if (value.toString().match(regex) || Math.abs(this._plCompsService.parseNumber(value)) > this.options.maxNumber) {
      this.viewValue = this._previousViewValue;
      value = this.viewValue;
    }

    let parsedValue = this._plCompsService.parseNumber(value);
    if (!parsedValue && parsedValue !== 0) {
      parsedValue = this._plCompsService.parseNumber(this._previousViewValue) || 0;
    }
    this.value = parsedValue;
    this._previousViewValue = value;
    return super.render();
  }

  public onInputKeyDown(event: KeyboardEvent): void {
    if (!EVENT_SUPPORTED_BEFORE_INPUT || (event.key?.length > 1 && event.key !== KEYCODES.UNIDENTIFIED)) {
      this._preventBeforeInput = true;
      this._plKeyboardService.numericOnly(event, this.options);
    }
    if (this.options.events && isFunction(this.options.events.keydown)) {
      this.options.events.keydown(this.value, event);
    }
  }

  public onInputBeforeInput(event: InputEvent): void {
    if (this._preventBeforeInput) {
      this._preventBeforeInput = false;
    } else if (EVENT_SUPPORTED_BEFORE_INPUT) {
      this._plKeyboardService.numericOnly(event, this.options);
    }
    if (this.options.events && isFunction(this.options.events.beforeinput)) {
      this.options.events.beforeinput(this.value, event);
    }
  }

  public onInputFocus(event: FocusEvent): void {
    this._isEditing = true;
    let value = this._plCompsService.stripCharacters(this.viewValue || '');
    value = removeAllNotLast(value, this.options.decimalsSeparator);
    if (this._defaultDecimalsValue !== '' && value.includes(this._defaultDecimalsValue)) {
      value = value.substring(0, value.indexOf(this._defaultDecimalsValue));
    }
    this.preventValueChanges();
    this.viewValue = value;
    this.allowValueChanges();
    super.onInputFocus(event);
  }

  public onInputBlur(event: FocusEvent): void {
    super.onInputBlur(event);
    this._isEditing = false;
    this._formatValue();

    // Callback function
    if (this.options.events && isFunction(this.options.events.blur)) {
      this.options.events.blur(this.value, event);
    }
  }

  public updateComponent(properties: IPlEditComponentOptionsInputNumber): void {
    const options = {...this.options};
    super.updateComponent(properties);
    if (
      properties !== this.options &&
      !this._isEditing &&
      ((isDefinedNotNull(properties.maxNumber) && properties.maxNumber !== options.maxNumber) ||
        (properties.decimalsSeparator && properties.decimalsSeparator !== options.decimalsSeparator) ||
        (isDefinedNotNull(properties.decimalsLimit) && properties.decimalsLimit !== options.decimalsLimit) ||
        (properties.thousandsSeparator && properties.thousandsSeparator !== options.thousandsSeparator))
    ) {
      this.render(this._formatValue(this.viewValue || ''));
    }
  }

  private _requiredValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (this.validate && this.options.validators.required?.value) {
        const parsedValue = this._plCompsService.parseNumber(control.value || 0);
        return !parsedValue && parsedValue !== 0 ? {required: true} : undefined;
      }
      return undefined;
    };
  }

  private _minValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!this.validate || isUndefinedOrNull(this.options.validators.min?.value) || control.value === '-') {
        return null;
      }
      const min: number = parseFloat(this.options.validators.min.value);
      if (Number.isNaN(min)) {
        return null;
      }
      const modelValue: number = this._plCompsService.parseNumber(control.value || 0);
      return modelValue >= min ? null : {min: true};
    };
  }

  private _maxValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!this.validate || isUndefinedOrNull(this.options.validators.max?.value) || control.value === '-') {
        return null;
      }
      const max: number = parseFloat(this.options.validators.max.value);
      if (Number.isNaN(max)) {
        return null;
      }
      const modelValue: number = this._plCompsService.parseNumber(control.value || 0);
      return modelValue <= max ? null : {max: true};
    };
  }

  private _modelChangeHandler(modelValue: string | number): void {
    if (this._preventModelChangeHandler) {
      this._preventModelChangeHandler = false;
      if (modelValue === this.value) {
        return;
      }
    }
    const selecting = this._isEditing && this.options.selectOnFocus && !window.getSelection().toString();
    const value = this._plCompsService.parseNumber(modelValue);
    this.preventValueChanges();
    this.viewValue = this._previousViewValue = this._plI18nPlNumberService.formatPlNumber(value, this.options);
    this.allowValueChanges();
    if (selecting && this._inputField) {
      this._inputField.select();
    }
  }

  private _defaultDecimals(): string {
    if (this.options.decimalsLimit > 0) {
      let decimals = '';
      for (let i = 0; i < this.options.decimalsLimit; i++) {
        decimals += '0';
      }
      return this.options.decimalsSeparator + decimals;
    }
    return '';
  }

  private _formatValue(value: string = this.viewValue): string {
    const formattedValue = this._plI18nPlNumberService.formatPlNumber(value, this.options);
    this.formControl.setValue(<any>formattedValue, {emitEvent: false});
    return formattedValue;
  }
}
