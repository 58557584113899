import type {Subscription} from 'rxjs';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {NgbDropdown} from '@ng-bootstrap/ng-bootstrap';
import {EScreenSize} from '../common/constants';
import type {IPlLocale} from '../common/locale/locales.interface';
import {IPlNavbarItemEvent, IPlNavbarMenu, TPlNavbarFnItemActive, TPlNavbarFnItemClicked} from './navbar.interface';
import {isArray, isFunction} from '../common/utilities/utilities';
import {PlDocumentService} from '../common/document/document.service';
import {PlLocaleService} from '../common/locale/locale.service';
import {PlPageWrapperService} from '../pagewrapper/pagewrapper.service';

@Component({
  selector: 'pl-navbar',
  templateUrl: './navbar.component.html'
})
export class PlNavbarComponent implements OnChanges, OnDestroy {
  @Input() public subBrand: string;
  @Input() public menus: Array<IPlNavbarMenu>;
  @Input() public itemActive: TPlNavbarFnItemActive;
  @Input() public itemClicked: TPlNavbarFnItemClicked;
  @Output() public readonly evtItemClicked: EventEmitter<IPlNavbarItemEvent>;

  public locale: IPlLocale;
  public collapsed: boolean;
  public activeSideBar: boolean;
  public showStickyMenus: boolean;
  public regularMenus: Array<IPlNavbarMenu>;
  public stickyMenus: Array<IPlNavbarMenu>;

  private readonly _subscriptionToggled: Subscription;
  private readonly _subscriptionShowing: Subscription;
  private readonly _subscriptionLocale: Subscription;
  private readonly _subscriptionIsMobile: Subscription;
  private _mouseInNavBar: boolean;
  private _showingSideBar: boolean;
  private _previousActive: IPlNavbarMenu;
  private _previousActiveParent: IPlNavbarMenu;

  constructor(
    private readonly _plLocaleService: PlLocaleService,
    private readonly _plPageWrapperService: PlPageWrapperService,
    private readonly _plDocumentService: PlDocumentService
  ) {
    this.evtItemClicked = new EventEmitter<IPlNavbarItemEvent>();
    this.collapsed = true;
    this.activeSideBar = false;
    this._subscriptionToggled = this._plPageWrapperService.toggled().subscribe((value: boolean) => {
      this.activeSideBar = value;
    });
    this._subscriptionShowing = this._plPageWrapperService.showingSideBar().subscribe((value: boolean) => {
      this._showingSideBar = value;
    });
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this.locale = locale;
    });
    this._subscriptionIsMobile = this._plDocumentService.windowWidth().subscribe((windowWidth: number) => {
      this.showStickyMenus = windowWidth < EScreenSize.MEDIUM;
    });
    this._mouseInNavBar = false;
    this._showingSideBar = false;
  }

  public ngOnChanges({menus}: SimpleChanges): void {
    if (menus) {
      this._initMenu();
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionToggled.unsubscribe();
    this._subscriptionShowing.unsubscribe();
    this._subscriptionLocale.unsubscribe();
    this._subscriptionIsMobile.unsubscribe();
  }

  public toggleSidebar(): void {
    if (!this.activeSideBar) {
      this._plPageWrapperService.cancelSetShowingSidebar();
    } else {
      this._plPageWrapperService.setShowingSideBar(false);
    }
    this._plPageWrapperService.toggleSidebar();
  }

  public toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

  public async navAction(navbarMenu: IPlNavbarMenu, event: MouseEvent, parentNavbarMenu?: IPlNavbarMenu, dropdown?: NgbDropdown): Promise<void> {
    const itemEvent: IPlNavbarItemEvent = {
      item: navbarMenu,
      parent: parentNavbarMenu,
      dropdownRef: dropdown,
      event: event
    };
    if (isFunction(navbarMenu.action)) {
      await navbarMenu.action(itemEvent);
    }
    if (isFunction(this.itemClicked)) {
      await this.itemClicked(itemEvent);
    }
    this.evtItemClicked.emit(itemEvent);
    if (dropdown?.isOpen()) {
      dropdown.close();
    }
    this.collapsed = true;
    this._evaluateActive(navbarMenu, parentNavbarMenu);
  }

  public mouseEnterSidebar(): void {
    this._mouseInNavBar = true;
    if (this._showingSideBar) {
      this._plPageWrapperService.cancelSetShowingSidebar();
    }
  }

  public mouseLeaveSidebar(): void {
    this._mouseInNavBar = false;
    if (this._showingSideBar) {
      this._plPageWrapperService.hideSidebarWithDebounce();
    }
  }

  public mouseEnterSidebarToggler(): void {
    if (!this.activeSideBar) {
      this._plPageWrapperService.showSidebarWithDebounce();
    }
  }

  public mouseLeaveSidebarToggler(): void {
    if (this.activeSideBar) {
      return;
    }
    if (!this._showingSideBar) {
      this._plPageWrapperService.cancelSetShowingSidebar();
    } else if (!this._mouseInNavBar) {
      this._plPageWrapperService.hideSidebarWithDebounce();
    }
  }

  private _initMenu(): void {
    this.regularMenus = [];
    this.stickyMenus = [];
    if (!isArray(this.menus)) {
      return;
    }
    for (const menu of this.menus) {
      this._evaluateActive(menu);
      if (!menu.sticky) {
        this.regularMenus.push(menu);
      } else {
        this.stickyMenus.push(menu);
      }
    }
  }

  private _evaluateActive(navbarMenu: IPlNavbarMenu, parentNavbarMenu?: IPlNavbarMenu): void {
    let active = false;
    if (isFunction(this.itemActive)) {
      active = this.itemActive(navbarMenu, parentNavbarMenu);
    }
    if (active) {
      if (this._previousActive) {
        this._previousActive.active = false;
      }
      if (this._previousActiveParent) {
        this._previousActiveParent.active = false;
        this._previousActiveParent = undefined;
      }
      navbarMenu.active = true;
      if (parentNavbarMenu && parentNavbarMenu !== this._previousActive) {
        parentNavbarMenu.active = true;
        this._previousActiveParent = parentNavbarMenu;
      }
      this._previousActive = navbarMenu;
    } else if (isArray(navbarMenu.menu)) {
      for (const item of navbarMenu.menu) {
        this._evaluateActive(item, navbarMenu);
      }
    }
  }
}
