<div class="faturacao-eletronica-configuracoes-guide-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'faturacaoeletronicaconfiguracoes.guide.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-nav-wizard [definition]="navWizardDefinition" [properties]="{hideFinalize: true}">
      <pl-nav-wizard-step *ngFor="let item of faturacaoEletronicaGuia" [caption]="item.caption" icon="fa-check">
        <div *plNavWizardStepContent>
          <pl-alert type="info" [closeable]="false">
            <div [innerHTML]="item.info | translate"></div>
          </pl-alert>
          <div class="text-center">
            <img alt="guia-nova-conta" [src]="item.url" />
          </div>
        </div>
      </pl-nav-wizard-step>
    </pl-nav-wizard>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
  </div>
</div>
