import {Component, Injector} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {AtivosService} from '../../services/ativos.service';
import {EAtivosComponentState} from '../component/ativos.component.interface';
import {ENTITY_NAME_ATIVOS, IAtivosNewStateParams} from '../../interfaces/ativos.entity.interface';
import {IJsonAtivos, IJsonAtivosError} from '../../interfaces/jsonAtivos.entity.interface';
import {MODULE_NAME_ATIVOS_AQUISICAO_CONTAB} from '../../../../modules/ativos/ativosaquisicaocontab/ativosAquisicaoContab.module.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';

@Component({
  selector: 'ativos-new',
  templateUrl: './ativos.entity.new.component.html'
})
export class AtivosNewComponent extends ModuloEntityDetailComponent<IJsonAtivos> {
  public readonly ativosStateTypes: typeof EAtivosComponentState;
  public readonly: boolean;
  public contabValorAquisicao: number;
  public ativoContab: boolean;
  public ativoInvestimentosEmCurso: boolean;
  public docExtPocCabId: string;
  public valorInvEmCursoDisponivelAnoN: number;
  public valorInvEmCursoDisponivelAnoN1: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosService: AtivosService,
    private readonly _plAlertSerivce: PlAlertService
  ) {
    super(_injector);
    const params: IAtivosNewStateParams = <IAtivosNewStateParams>this._transition.params();
    this.contabValorAquisicao = Number(params.contabValorAquisicao);
    this.ativoContab = this.contabValorAquisicao > 0 && !params.fromInvestimentosEmCurso;
    this.ativoInvestimentosEmCurso = params.fromInvestimentosEmCurso;
    this.docExtPocCabId = params.docExtPocCabId;
    this.ativosStateTypes = EAtivosComponentState;
    this.valorInvEmCursoDisponivelAnoN = params.fromInvestimentosEmCurso ? Number(params.valorInvEmCursoDisponivelAnoN) : 0;
    this.valorInvEmCursoDisponivelAnoN1 = params.fromInvestimentosEmCurso ? Number(params.valorInvEmCursoDisponivelAnoN1) : 0;
    this.finalize = this.finalize.bind(this);
    this._validateAquisicao();
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    return super.onUpdate(stateType).then(() => {
      this.btnSave.disabled = true;
      if (stateType === EEntityStateDetailType.DETAIL && !this.ativoContab && !this.ativoInvestimentosEmCurso) {
        this._plAlertSerivce.success('ativos.configuracoes.messages.ativoguardadosuccess');
        return this._cgStateService.redirectToState({stateOrName: ENTITY_NAME_ATIVOS, params: {id: this.model.codAtivo}});
      }
      return Promise.resolve();
    });
  }

  public canSaveNewAtivoChanged(value: boolean): void {
    this.btnSave.disabled = !value;
  }

  public finalize(): Promise<void> {
    return this.save().then(() => undefined);
  }

  private async _validateAquisicao(): Promise<void> {
    if (this.ativoContab || this.ativoInvestimentosEmCurso) {
      return;
    }
    const response: HttpResponse<Array<IJsonAtivosError>> = await this._ativosService.getVerificacaoDeConsistenciaAquisicao();
    if (!response.body.length) {
      return;
    }
    let changedMessage = '';
    for (const responseElement of response.body) {
      if (changedMessage) {
        changedMessage += '<br>';
      }
      changedMessage += responseElement.error;
    }
    try {
      await this._cgModalService.showOkCancel('ativos.errotitle', changedMessage);
      await this._cgStateService.redirectToState({stateOrName: MODULE_NAME_ATIVOS_AQUISICAO_CONTAB});
    } catch (e) {
      await this._cgStateService.redirectToState({stateOrName: ENTITY_NAME_ATIVOS, exactMatch: true});
    }
  }
}
