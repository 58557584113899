<div class="faturacao-contratos-log-module-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'faturacaoContratosLog.title'"></h4>
  </div>

  <div class="modal-body">
    <div class="erro-contratos-faturados" *ngIf="this.contratosFaturados?.erros?.length > 0">
      <div *ngIf="this.contratosFaturados?.docsGerados?.length === 0">
        <i class="fa fa-exclamation-circle fa-fw"></i>&nbsp;<span class="d-none d-sm-inline" [translate]="'faturacaoContratosLog.tabs.erros.warningMsg.naoFaturados'"></span>
      </div>

      <div *ngIf="this.contratosFaturados?.docsGerados?.length > 0">
        <i class="fa fa-exclamation-circle fa-fw"></i>&nbsp;<span class="d-none d-sm-inline" [translate]="'faturacaoContratosLog.tabs.erros.warningMsg.consulteNaoFaturados'"></span>
      </div>

      <br />
    </div>

    <pl-tabs [activeId]="activeTabID" (activeIdChange)="onTabChange($event)">
      <pl-tab id="tabContratosFaturados" *ngIf="this.contratosFaturados?.docsGerados?.length > 0">
        <div *plTabTitle><i class="fa fa-check-circle fa-fw"></i>&nbsp;<span class="d-none d-sm-inline" [translate]="'faturacaoContratosLog.tabs.faturados.title'"></span></div>

        <div *plTabContent>
          <div class="card-footer estatisticasComercial">
            <dx-data-grid
              [cgDxDataGrid]="dataGridDefinition"
              cgDxDataGridInstanceName="contratosFaturados"
              [dataSource]="contratosFaturados.docsGerados"
              (onInitialized)="onInitialized($event)"
              (onContentReady)="onContentReady()"
              [selectedRowKeys]="selectedRowKeys"
              (selectedRowKeysChange)="selectedRowKeys = $event; onSelectedRowKeysChange()">
              <div *dxTemplate="let item of 'actions'">
                <pl-tooltip [config]="{text: 'faturacaoContratosLog.tabs.faturados.btn.previsualizar', container: 'body'}">
                  <button type="button" *plTooltipContent class="btn btn-xs btn-primary" (click)="previsualizarDoc(item.data)"><i class="fa fa-eye"></i></button>
                </pl-tooltip>
                &nbsp;
                <pl-tooltip [config]="{text: 'faturacaoContratosLog.tabs.faturados.btn.encerrarImprimir', container: 'body'}">
                  <button type="button" *plTooltipContent class="btn btn-xs btn-success" (click)="encerrarImprimirDoc(item.data)"><i class="fa fa-print"></i></button>
                </pl-tooltip>

                &nbsp;
                <pl-tooltip *ngIf="item.data.nDocumento === 0" [config]="{text: 'faturacaoContratosLog.tabs.faturados.btn.editarDocumento', container: 'body'}">
                  <button type="button" *plTooltipContent class="btn btn-xs btn-warning" (click)="editarDocumento(item.data)"><i class="fa fa-pencil-square-o"></i></button>
                </pl-tooltip>

                <pl-tooltip *ngIf="item.data.nDocumento !== 0" [config]="{text: 'faturacaoContratosLog.tabs.faturados.btn.visualizarDocumento', container: 'body'}">
                  <button type="button" *plTooltipContent class="btn btn-xs btn-info" (click)="visualizarDocumento(item.data)"><i class="fa fa-file-text"></i></button>
                </pl-tooltip>
              </div>
              <dxo-editing mode="cell" startEditAction="click" [selectTextOnEditStart]="true" [allowUpdating]="true"> </dxo-editing>
            </dx-data-grid>
          </div>
        </div>
      </pl-tab>
      <pl-tab id="tabContratosErros" *ngIf="this.contratosFaturados?.erros?.length > 0">
        <div *plTabTitle><i class="fa fa-exclamation-circle fa-fw"></i>&nbsp;<span class="d-none d-sm-inline" [translate]="'faturacaoContratosLog.tabs.erros.title'"></span></div>

        <div *plTabContent>
          <div class="card-footer estatisticasComercial">
            <dx-data-grid
              [cgDxDataGrid]="dataGridErrosDefinition"
              cgDxDataGridInstanceName="contratosErros"
              [dataSource]="dataGridErrosDefinition.dataSource"
              (onInitialized)="onGridErrosInitialized($event)"
              (onContentReady)="onGridErrosContentReady()">
            </dx-data-grid>
          </div>
        </div>
      </pl-tab>
    </pl-tabs>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button *ngIf="activeTabID !== 'tabContratosErros' && contratosFaturados?.docsGerados?.length > 0" klass="btn-success" (evtClicked)="encerrarSelecionados()" [disabled]="onClickAction">
      <i class="fa fa-fw fa-file-archive-o"></i>&nbsp;<span [translate]="'faturacaoContratosLog.tabs.faturados.btn.encerrarTudo'"></span>
    </pl-button>

    <pl-button
      *ngIf="activeTabID !== 'tabContratosErros' && contratosFaturados?.docsGerados?.length > 0"
      klass="btn-primary"
      (evtClicked)="enviarFatEletronicaSelecionadas()"
      [disabled]="onClickAction || disabledEnvioFaturas">
      <i class="fa fa-fw fa-envelope-o"></i>&nbsp;<span [translate]="'faturacaoContratosLog.tabs.faturados.btn.enviarFatEletronica'"></span>
    </pl-button>

    <pl-button klass="btn-light action-close" (evtClicked)="close()" [disabled]="onClickAction"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
  </div>
</div>
