import moment, {Moment} from 'moment';
import {Subscription} from 'rxjs';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {StateService, Transition} from '@uirouter/core';
import {IPlFormatConfig, isNumber, isObject, Logger, PlTranslateService} from 'pl-comps-angular';
import {AppService} from '../../../services/app/app.service';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {CGStateService} from '../../../components/state/cg.state.service';
import {COMPANY_STATUS_INSTANCES, ECompanyStatusInstance, IApiJobStatus, ICompanyStatusMessage, ICompanyStatusStateParams} from './companystatus.interface';
import {CompanyStatusRegistryService} from './companystatus.registry.service';
import {ConfigService} from '../../../services/config/config.service';
import {IStatusService} from '../../../services/generic/status.service.interface';
import {PortalChangeFirmModalComponent} from '../../../components/portal/modals/changefirm/portal.changefirm.modal.component';
import {STATE_NAME_LOGIN} from '../login/login.state.interface';
import {TUserSession} from '../../../services/account/jsonUserApi.interface';

const INTERVAL_TIMEOUT = 10000;
const TIME_FORMAT = 'HH:mm';
const YEAR = moment().year();

@Component({
  selector: 'module-company-status',
  templateUrl: './companystatus.component.html'
})
export class CompanyStatusComponent implements OnInit, OnDestroy {
  @Input() public session: TUserSession;
  public readonly taskName: string;
  public readonly statusMessage: ICompanyStatusMessage;
  public header: string;
  public currentStatus: IApiJobStatus<string | number>;
  public textDescription: string;
  public textScheduled: string;
  public scheduled: boolean;
  public cancelable: boolean;
  public running: boolean;
  public finished: boolean;
  public promise: Promise<void>;

  private readonly _params: ICompanyStatusStateParams;
  private readonly _statusInstance: ECompanyStatusInstance;
  private readonly _service: IStatusService<string | number, IApiJobStatus<string | number>>;
  private readonly _subscriptionFormat: Subscription;
  private _dateFormat: string;
  private _intervalId: number;

  constructor(
    private readonly _stateService: StateService,
    private readonly _transition: Transition,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _companyStatusRegistryService: CompanyStatusRegistryService,
    private readonly _logger: Logger,
    private readonly _appService: AppService,
    private readonly _cgStateService: CGStateService,
    private readonly _configService: ConfigService,
    private readonly _cgModalService: CGModalService
  ) {
    this.goBack = this.goBack.bind(this);
    this.changeEmpresa = this.changeEmpresa.bind(this);
    this.changeUser = this.changeUser.bind(this);
    this.cancel = this.cancel.bind(this);
    this._cgModalService.dismissAll('Company status');
    this._params = <ICompanyStatusStateParams>this._transition.params();
    this._statusInstance = this._params.statusInstance;
    if (!this._statusInstance || !COMPANY_STATUS_INSTANCES.has(this._statusInstance)) {
      this._logger.error(`Provided company status instance [${this._statusInstance}] is invalid.`);
      this._cgStateService.goHome();
      return;
    }
    this.taskName = this._plTranslateService.translate(`companystatus.tasks.${this._statusInstance}.title`);
    this.statusMessage = {timestamp: moment(), timestampFormatted: '', content: ''};
    this.cancelable = false;
    this._service = this._companyStatusRegistryService.get(this._statusInstance);
    this._tick();
    this._intervalId = window.setInterval(() => {
      this._tick();
    }, INTERVAL_TIMEOUT);
    this._subscriptionFormat = this._appService.format().subscribe((format: IPlFormatConfig) => {
      this._dateFormat = format.momentDatetime;
    });
  }

  public ngOnInit(): void {
    this.header = this._plTranslateService.translate('companystatus.header', {
      nEmpresa: this.session.erp.nEmpresa,
      nomeEmpresa: this.session.erp.nomeEmpresa
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionFormat.unsubscribe();
    this._clearInterval();
  }

  public goBack(): Promise<void> {
    return this._cgStateService.goBack();
  }

  public async changeEmpresa(): Promise<void> {
    await this._cgModalService.show(PortalChangeFirmModalComponent);
    await this._cgStateService.goHome();
  }

  public changeUser(): Promise<void> {
    return this._stateService.go(STATE_NAME_LOGIN).then(() => undefined);
  }

  public cancel(): Promise<void> {
    if (!this.cancelable) {
      return Promise.resolve();
    }
    const message: string = this._plTranslateService.translate('companystatus.cancel.message', {taskName: this.taskName});
    return this._cgModalService
      .showOkCancel('companystatus.cancel.title', message)
      .then(() => this._service.cancel())
      .then(() => this.goBack());
  }

  private _tick(): void {
    if (this.currentStatus) {
      const now: Moment = moment();
      const plannedStartDate: Moment = moment(this.currentStatus.plannedStartDate);
      if (plannedStartDate.isValid() && plannedStartDate.year() === YEAR && (now.hour() < plannedStartDate.hour() || now.minutes() < plannedStartDate.minutes())) {
        return;
      }
    }
    this.promise = this._fetchStatus()
      .then((currentStatus: IApiJobStatus<string | number>) => {
        this._setCurrentStatus(currentStatus);
        this._evaluateStatusMessage();
        if (this.finished) {
          this._clearInterval();
          return this._configService.refresh().then(() => undefined);
        }
        return Promise.resolve();
      })
      .finally(() => {
        this.promise = undefined;
      });
  }

  private _clearInterval(): void {
    if (isNumber(this._intervalId)) {
      window.clearInterval(this._intervalId);
      this._intervalId = undefined;
    }
  }

  private _fetchStatus(): Promise<IApiJobStatus<string | number>> {
    return this._service.status().then((response: HttpResponse<IApiJobStatus<string | number>>) => response.body);
  }

  private _setCurrentStatus(status: IApiJobStatus<string | number>): void {
    this.currentStatus = status;
    this.scheduled = this._service.scheduled(this.currentStatus);
    this.cancelable = this._cancelable();
    this.running = this._service.running(this.currentStatus);
    this.finished = this._service.finished(this.currentStatus);
  }

  private _evaluateStatusMessage(): void {
    this.textDescription = '';
    this.textScheduled = '';
    this._setCurrentStatus(this.currentStatus);
    this.textDescription = this._plTranslateService.translate('companystatus.text.description', {
      requested: moment(this.currentStatus.requestDate).format(this._dateFormat),
      user: this.currentStatus.startedByUser,
      company: this.currentStatus.companyId
    });
    const plannedStartDate: Moment = moment(this.currentStatus.plannedStartDate);
    if (plannedStartDate.isValid() && plannedStartDate.year() === YEAR) {
      this.textScheduled = this._plTranslateService.translate('companystatus.text.scheduled', {
        scheduled: plannedStartDate.format(this._dateFormat)
      });
    }
    const timestamp: Moment = moment();
    this.statusMessage.timestamp = timestamp;
    this.statusMessage.timestampFormatted = timestamp.format(TIME_FORMAT);
    this.statusMessage.content = this._plTranslateService.translate(`companystatus.tasks.${this._statusInstance}.state.${String(this.currentStatus.state)}`);
    if (this.currentStatus.description) {
      this.statusMessage.content += ` (${this.currentStatus.description})`;
    }
  }

  private _cancelable(): boolean {
    return this.scheduled && this._service.cancelable() && isObject(this.currentStatus) && this.currentStatus.startedByUserId === this.session.userId;
  }
}
