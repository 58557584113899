import {TDate} from '../../../common/dates';
import {EGrupoDoc} from '../../datasources/grupodoc/grupoDoc.datasource.interface';
import {IJsonAdiantamentoConfig, IJsonAdiantamentoReg, IJsonAdiantamentosRegisto} from './jsonAdiantamentos.module.interface';
import {IRadioGroup} from '../../../common/interfaces/interfaces';
import {deepFreeze} from 'pl-comps-angular';
import {IJsonDocComercial} from '../../entities/docscomerciais/jsonDocComercial.entity.interface';

export enum EAdiantamentosTabID {
  PorRegularizar = 'porRegularizar',
  Regularizado = 'regularizado'
}

export enum EAdiantamentosModoFunc {
  Intervalo,
  DataReferencia
}

export type TAdiantamentosType = 'clientes' | 'fornecedores';

export interface IHeaderAdiantamentos {
  clifoDe: string;
  clifoAte: string;
  nomeClifo: string;
  dataDe: TDate;
  dataAte: TDate;
  modeFunc: EAdiantamentosModoFunc;
  dataRef: TDate;
}

export interface IAdiantamentosParams {
  clifo: string;
  nomeClifo: string;
  faccbId: number;
  ndocfa: number;
  nnumer: number;
  doc?: IJsonDocComercial;
}

export interface IAdiantamentosRegisto extends IJsonAdiantamentosRegisto {
  nomeDocFa: string;
  nomeNConta: string;
  nomeCodIva: string;
  grupoDocFa: EGrupoDoc;
  nDoc: string;
}

export interface IAdiantamentosRegistoResult {
  adiantamento: IAdiantamentosRegisto;
  config: IJsonAdiantamentoConfig;
  faccbID: number;
}
export interface IAdiantamentoReg extends IJsonAdiantamentoReg {
  _doc?: IJsonDocComercial;
}

export const RADIO_GROUP_ADIANTAMENTOS_MODO_FUNC: IRadioGroup<EAdiantamentosModoFunc> = deepFreeze<IRadioGroup<EAdiantamentosModoFunc>>({
  groupItems: [
    {value: EAdiantamentosModoFunc.Intervalo, label: 'adiantamentos.tabs.fields.intervalo'},
    {value: EAdiantamentosModoFunc.DataReferencia, label: 'adiantamentos.tabs.fields.datarefradio'}
  ]
});

export const MODULE_NAME_ADIANTAMENTOS_CLIENTES = 'adiantamentosclientes';
export const MODULE_NAME_ADIANTAMENTOS_FORNECEDORES = 'adiantamentosfornecedores';
