import {Subscription} from 'rxjs';
import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {isArray, isString} from 'pl-comps-angular';
import {AuthService} from '../../../services/auth/auth.service';
import {ConfigService} from '../../../services/config/config.service';
import {ConfigSiteService} from '../../../services/configsite.service';
import {EErpUserRoleMotivo, IJsonUserRole, TUserSession} from '../../../services/account/jsonUserApi.interface';
import {IActivePortal} from '../../../services/portals/portals.service.interface';
import {ICGConfigurations} from '../../../services/config/config.service.interface';
import {PortalService} from '../../../services/portal/portal.service';
import {ROLE} from '../../../services/role.const';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'cg-blocked-plugin',
  templateUrl: './blockedplugin.component.html',
  exportAs: 'cgBlockedPlugin'
})
export class CGBlockedPluginComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public pluginRoles: ROLE | Array<ROLE> | ReadonlyArray<ROLE>;
  @Input() public requiredRoles: ROLE | Array<ROLE> | ReadonlyArray<ROLE>;

  public captionRequiredRoles: string;

  private readonly _subscriptionActivePortal: Subscription;
  private readonly _subscriptionIdentity: Subscription;
  private readonly _subscriptionConfig: Subscription;
  private _roles: Array<ROLE>;
  private _rolesAccess: Array<IJsonUserRole>;
  private _rolesNotAccess: Array<IJsonUserRole>;
  private _licencaModoCGOn: boolean;
  private _hasAccess: boolean;
  private _expired: boolean;
  private _storeUrl: string;
  private _portal: IActivePortal;
  private _portalTitle: string;

  constructor(
    private readonly _authService: AuthService,
    private readonly _configService: ConfigService,
    private readonly _configSiteService: ConfigSiteService,
    private readonly _portalService: PortalService,
    private readonly _translateService: TranslateService
  ) {
    this._hasAccess = true;
    this._expired = false;
    this._roles = [];
    this.captionRequiredRoles = '';

    this._subscriptionActivePortal = this._portalService.getAppPortal().subscribe((portal: IActivePortal) => {
      this._portal = portal;
      this._portalTitle = this._portal.portalTitle.toLowerCase();
    });

    this._subscriptionIdentity = this._authService.identityAsObservable().subscribe((session: TUserSession) => {
      this._rolesAccess = session?.roles;
      this._rolesNotAccess = session?.erp.rolesNotAcess;
      this._evaluateState();
    });

    this._subscriptionConfig = this._configService.configurationsAsObservable().subscribe((configurations: ICGConfigurations) => {
      this._licencaModoCGOn = configurations.licenca.modoCGOn;
      this._evaluateState();
    });

    this._configSiteService.cgStoreUrlBackOffice().then((cgStoreUrlBackOffice: string) => {
      this._storeUrl = cgStoreUrlBackOffice;
      this._evaluateState();
    });
  }

  public ngOnInit(): void {
    this._changedPluginRoles();
    this._changedRequiredRoles();
  }

  public ngOnChanges({pluginRoles, requiredRoles}: SimpleChanges): void {
    if (pluginRoles && !pluginRoles.isFirstChange()) {
      this._changedPluginRoles();
    }
    if (requiredRoles && !requiredRoles.isFirstChange()) {
      this._changedRequiredRoles();
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionActivePortal.unsubscribe();
    this._subscriptionIdentity.unsubscribe();
    this._subscriptionConfig.unsubscribe();
  }

  public get licencaStoreModePublic(): boolean {
    return this._licencaModoCGOn;
  }

  public get hasAccess(): boolean {
    return this._hasAccess;
  }

  public get expired(): boolean {
    return this._expired;
  }

  public get storeUrl(): string {
    return this._storeUrl;
  }

  public get portal(): IActivePortal {
    return this._portal;
  }

  public get portalTitle(): string {
    return this._portalTitle;
  }

  private _evaluateState(): void {
    this._hasAccess = false;
    this._expired = false;

    if (!this._roles.length) {
      return;
    }

    if (this._rolesAccess?.length) {
      for (const userRole of this._rolesAccess) {
        if (this._roles.includes(userRole.role)) {
          this._hasAccess = true;
          break;
        }
      }
    }

    if (this._rolesNotAccess?.length && this.licencaStoreModePublic && this.storeUrl) {
      for (const userRole of this._rolesNotAccess) {
        if (this._roles.includes(userRole.role) && userRole.motivo === EErpUserRoleMotivo.Expired) {
          this._expired = true;
          break;
        }
      }
    }
  }

  private _changedPluginRoles(): void {
    this._roles = [];
    if (isString(this.pluginRoles)) {
      if (this.pluginRoles) {
        this._roles = [this.pluginRoles];
      }
    } else if (isArray(this.pluginRoles)) {
      this._roles = this.pluginRoles.slice();
    }
    this._evaluateState();
  }

  private _changedRequiredRoles(): void {
    if (!this.requiredRoles) {
      return;
    }
    this._roles = !this._roles ? [] : this._roles;

    const captionRoles: Array<string> = [];
    if (isString(this.requiredRoles)) {
      if (this.requiredRoles) {
        if (!this._roles.includes(this.requiredRoles)) {
          this._roles.push(this.requiredRoles);
        }
        captionRoles.push(this._translateService.instant(`portals.items.${this.requiredRoles.toLowerCase()}`));
      }
    } else if (isArray(this.requiredRoles)) {
      for (const requiredRole of this.requiredRoles) {
        if (!this._roles.includes(requiredRole)) {
          this._roles.push(requiredRole);
        }
        captionRoles.push(this._translateService.instant(`portals.items.${requiredRole.toLowerCase()}`));
      }

      this.captionRequiredRoles =
        this.requiredRoles.length === 1
          ? this._translateService.instant('blockedPlugin.text.requiredRolesSingle', {portals: captionRoles[0]})
          : this._translateService.instant('blockedPlugin.text.requiredRolesMulti', {portals: captionRoles.join(',')});
    }
    this._evaluateState();
  }
}
