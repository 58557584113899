export interface IDataSourceItemModelo22 {
  value: string;
  name?: string;
}
export const DATA_SOURCE_NAME_MODELO22_PAISES = 'modelo22paises';
export const DATA_SOURCE_NAME_MODELO22_TIPO_REND = 'modelo22tiporend';
export const DATA_SOURCE_NAME_MODELO22_DIST_MUNICI = 'modelo22distmuni';
export const DATA_SOURCE_NAME_MODELO22_TIPO_DONATIVO = 'modelo22tipodonativo';
export const DATA_SOURCE_NAME_MODELO22_FACTO_IMPEDIMENTO = 'modelo22factoimpedimento';

export const DATA_SOURCE_NAME_MODELO22_TIPO_IDENT_MATRICIAL = 'modelo22tipoidentmatricial';
