<div class="contabilidadedigital-check-folders-created-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'arquivodigital.checkfolderscreatedmodal.title'"></h5>
  </div>

  <div class="modal-body">
    <p class="contabilidadedigital-check-folders-created-modal-message" [translate]="'arquivodigital.checkfolderscreatedmodal.message'"></p>
    <div class="contabilidadedigital-check-folders-created-modal-loader">
      <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
