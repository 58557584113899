import {merge} from 'lodash-es';
import {firstValueFrom} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {copy, isBoolean, isObject} from 'pl-comps-angular';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';
import {DGEMPSFullEditComponent} from './components/edit/dgempsFull.edit.component';
import {DgempsFullListComponent} from './components/list/dgempsFull.list.component';
import {ENTITY_DGEMPS} from '../../../entities/dgemps/dgemps.entity';
import {ENTITY_NAME_DGEMPS_FULL, IDGEMPSFullEntity, IDGEMPSFullGetListagemMapasFichaParams, IDGEMPSFullTaxaMarginalSimulador} from './dgempsFull.interface';
import {IApiRequestConfig, IApiRequestConfigWithBody} from '../../../services/api/api.service.interface';
import {IDGEMPSEntity} from '../../../entities/dgemps/dgemps.entity.interface';
import {IJsonBoolean} from '../../../../common/interfaces/json';
import {IJsonConfigRHDadosDGEMP} from '../../rhconfiguracoes/jsonRHConfiguracoes.module.interface';
import {IJsonDGEMPComentario, IJsonDGEMPFull} from './jsonDGEMPFull.interface';
import {momentDefaultValue} from '../../../../common/utils/moment.utils';
import {RHConfiguracoesService} from '../../rhconfiguracoes/rhConfiguracoes.module.service';
import {ROLE} from '../../../services/role.const';
import {TEntityServiceRequestData, TEntityServiceRequestDataWithBody} from '../../../services/entity/entity.service.interface';
import {UI_ROUTER_URL_TYPE_NAME_CG_DATE} from '../../../../config/uirouter/uirouter.configs.interface';
import {TDate} from '../../../../common/dates';

const suffix = 'full';
const entityDGEMPS: IDGEMPSEntity = copy<IDGEMPSEntity>(ENTITY_DGEMPS);
const entityServiceDGEMPS = entityDGEMPS.service;

export const ENTITY_DGEMPS_FULL: IDGEMPSFullEntity = merge<unknown, IDGEMPSEntity, IDGEMPSFullEntity>({}, entityDGEMPS, {
  name: ENTITY_NAME_DGEMPS_FULL,
  url: 'colaboradores',
  entityUrl: 'dgemps',
  icon: 'fa-users',
  roles: [ROLE.RH],
  searchPlaceholder: 'dgempsfull.pesquisa',
  pageTitle: 'global.menu.dgempsfull',
  serviceMethodsOverride: {
    get: 'getFull',
    post: 'postFull',
    put: 'putFull',
    delete: 'deleteFull'
  },
  metadata: {
    keyName: undefined,
    fields: undefined,
    searchFields: 'nome,apelido,ativo,numContrib',
    listFields: 'codEmp,nome,codPostal,nomeLocalida,email,numContrib,nomeServicoPRH,ativo'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      component: DgempsFullListComponent
    }
  },
  detail: {
    state: {
      component: DGEMPSFullEditComponent,
      urlSuffix: '?data',
      params: {
        data: {
          type: UI_ROUTER_URL_TYPE_NAME_CG_DATE,
          value: momentDefaultValue(),
          squash: true
        }
      },
      resolve: [
        {
          provide: 'hasRequiredFields',
          deps: [RHConfiguracoesService],
          useFactory: (rhConfiguracoesService: RHConfiguracoesService) => {
            return rhConfiguracoesService.getHasRequiredFields().then((response: HttpResponse<IJsonBoolean>) => response.body.value);
          }
        }
      ]
    }
  },
  new: {
    state: {
      component: DGEMPSFullEditComponent,
      resolve: [
        {
          provide: 'defaultData',
          deps: [RHConfiguracoesService],
          useFactory: (rhConfiguracoesService: RHConfiguracoesService) => {
            return rhConfiguracoesService.getDadosDGEMP().then((response: HttpResponse<IJsonConfigRHDadosDGEMP>) => response.body);
          }
        },
        {
          provide: 'hasRequiredFields',
          deps: ['defaultData'],
          useFactory: (defaultData: IJsonConfigRHDadosDGEMP) => {
            return isObject(defaultData) && isBoolean(defaultData.hasRequiredFields) ? defaultData.hasRequiredFields : false;
          }
        }
      ]
    }
  },
  service: function () {
    // Inherit IDGEMPSEntityService
    this.invoke(entityServiceDGEMPS, this);

    // Implement methods
    this.getFull = (config: TEntityServiceRequestData) => {
      if (!isObject(config)) {
        config = {};
      }
      config.id += `/${suffix}`;
      return this.get(config);
    };

    this.postFull = (config: IApiRequestConfigWithBody<IJsonDGEMPFull>) => {
      if (!isObject(config)) {
        config = {};
      }
      config.url = config.url ? `${config.url}/${suffix}` : suffix;
      return this.post(config);
    };

    this.postComentarioDGEMP = (config: IApiRequestConfigWithBody<IJsonDGEMPComentario>) => {
      if (!isObject(config)) {
        config = {};
      }
      config.url = config.url ? `${config.url}/${suffix}/comentario` : `${suffix}/comentario`;
      return this.post(config);
    };

    this.putFull = (config: TEntityServiceRequestDataWithBody<IJsonDGEMPFull>) => {
      if (!isObject(config)) {
        config = {};
      }
      if (config.url) {
        config.url += `/${suffix}`;
      }
      if (config.id || config.id === 0) {
        config.id += `/${suffix}`;
      }
      return this.put(config);
    };

    this.putComentarioDGEMP = (config: TEntityServiceRequestDataWithBody<IJsonDGEMPComentario>) => {
      if (!isObject(config)) {
        config = {};
      }
      if (config.url) {
        config.url += `/${suffix}/comentario`;
      }
      if (config.id || config.id === 0) {
        config.id += `/${suffix}/comentario`;
      }
      return this.put(config);
    };

    this.deleteFull = (config: TEntityServiceRequestData) => {
      if (!isObject(config)) {
        config = {};
      }
      if (config.url) {
        config.url += `/${suffix}`;
      }
      if (config.id || config.id === 0) {
        config.id += `/${suffix}`;
      }
      return this.delete<void | IJsonDGEMPFull>(config);
    };

    this.deleteComentarioDGEMP = (config: TEntityServiceRequestData) => {
      if (!isObject(config)) {
        config = {};
      }
      if (config.url) {
        config.url += `/${suffix}/comentario`;
      }
      if (config.id || config.id === 0) {
        config.id += `/${suffix}/comentario`;
      }
      return this.delete(config);
    };

    this.simulateValorVencimento = (id: number, config?: IApiRequestConfig) => {
      return this.get({url: `${id}/simulavencimento`, ...config});
    };
    this.simulateTaxaMarginal = (codEmp: number, taxa: number, dataSimulacao: TDate, config?: IApiRequestConfig) => {
      return this.apiService.get<IDGEMPSFullTaxaMarginalSimulador>({
        url: `${this.entityUrl()}/simulartaxamarginal`,
        params: {
          codemp: codEmp,
          taxaefetiva: taxa,
          datasimulacao: dataSimulacao
        },
        ...config
      });
    };

    this.getEmpregadoByNISS = (niss: string) => {
      return this.get({id: `${suffix}/empregado/${niss}`});
    };

    this.getListagemMapasFichaParamsUrl = (params: IDGEMPSFullGetListagemMapasFichaParams) => {
      return buildSessionUrlWithParams(`${this.entityUrl()}/${suffix}/report`, {
        decod: params.deCod,
        atecod: params.atecod,
        denome: params.deNome,
        atenome: params.ateNome,
        deidade: params.deIdade,
        ateidade: params.ateIdade,
        desituacao: params.deSituacao,
        atesituacao: params.ateSituacao,
        decategoria: params.deCategoria,
        atecategoria: params.ateCategoria,
        dedepartamento: params.deDepartamento,
        atedepartamento: params.ateDepartamento,
        dedtnasc: params.deDtNasc,
        dedtvalct: params.deDtValCt,
        dedtvalbi: params.deDtValBI,
        atedtnasc: params.ateDtNasc,
        atedtvalct: params.ateDtValCt,
        atedtvalbi: params.ateDtValBI,
        rptname: params.reportName,
        tipoexportacao: params.tipoExportacao
      });
    };

    this.getListagemMapasFichaParams = (params: IDGEMPSFullGetListagemMapasFichaParams) => {
      return firstValueFrom(this.getListagemMapasFichaParamsUrl(params)).then((url: string) => this.get<Blob>({url: url, responseType: 'blob'}));
    };
  }
});
