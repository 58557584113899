<div class="vendas-ano-mes-module" [plPromise]="promise">
  <div class="row">
    <div class="col-sm-6">
      <div class="fix-checkbox-height">
        <pl-edit-checkbox
          [(model)]="anoAnteriorFiltered"
          (modelChange)="updateInfo()"
          attrName="showlastyearcomplete"
          [properties]="{label: 'vendasanomes.campos.checkboxAnoAnterior'}"></pl-edit-checkbox>
      </div>
      <div class="card panel-chart-vendas">
        <div class="card-body">
          <div class="chart-container">
            <dx-chart [cgDxChart]="chartVendasMes" [dataSource]="chartVendasMesData" (onPointHoverChanged)="onChartPointHoverChanged($event)" (onLegendClick)="onChartLegendClick($event)">
              <div *dxTemplate="let item of 'customTooltip'">
                <div>
                  <b>{{ item.argument }}</b>
                </div>
                <div *ngFor="let itemPoint of item.points">
                  <span>{{ itemPoint.seriesName }}: </span>
                  <span>{{ itemPoint.originalValue | cgcNumber }}</span>
                </div>
              </div>
            </dx-chart>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <dx-data-grid [cgDxDataGrid]="definition" [dataSource]="tableVendasMesData" cgDxDataGridInstanceName="vendasAnoMesTable"> </dx-data-grid>
    </div>
  </div>
</div>
