<div class="rhmanutencaocampos-container">
  <pl-nav-wizard [definition]="definitionNavWizard" [callback]="plWizardCallback" [onFinalize]="fnFinalize">
    <pl-nav-wizard-step [stepId]="wizStepOneId" icon="fa-home" caption="rhmanutencaocampos.wizard.stepOne.wizardCaption">
      <div *plNavWizardStepContent>
        <p [translate]="'rhmanutencaocampos.wizard.stepOne.text1'"></p>
        <p class="mb-3" [translate]="'rhmanutencaocampos.wizard.stepOne.text2'"></p>
        <p [translate]="'rhmanutencaocampos.wizard.stepOne.text3'"></p>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-pencil" caption="rhmanutencaocampos.wizard.stepTwo.wizardCaption" [validator]="fnValidateNewFields">
      <div *plNavWizardStepContent>
        <div [translate]="'rhmanutencaocampos.wizard.stepTwo.text1'"></div>
        <div class="mb-3" [translate]="'rhmanutencaocampos.wizard.stepTwo.text2'"></div>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-wrap" style="gap: 8px">
              <div class="rhmanutencaocampos-d-flex-item">
                <label><span [translate]="'rhmanutencaocampos.fields.dateProc'"></span>:</label>
                <pl-group style="max-width: 150px">
                  <edit>
                    <pl-edit-datepicker [(model)]="model.dataDe" [properties]="{disallowClear: true}"></pl-edit-datepicker>
                  </edit>
                </pl-group>
              </div>

              <div class="rhmanutencaocampos-d-flex-item">
                <label><span [translate]="'rhmanutencaocampos.fields.ate'"></span>:</label>
                <pl-group style="max-width: 150px">
                  <edit>
                    <pl-edit-datepicker [(model)]="model.dataAte" [properties]="{disallowClear: true}"></pl-edit-datepicker>
                  </edit>
                </pl-group>
              </div>

              <div class="rhmanutencaocampos-d-flex-item">
                <pl-button type="button" klass="btn-sm btn-primary" [onClick]="fnSearchProcessamentos">
                  <i class="fa fa-search"></i>&nbsp;<span [translate]="'rhmanutencaocampos.pesquisar'"></span>
                </pl-button>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-md-12">
            <div class="rhmanutencaocampos-field-fix-container">
              <div class="rhmanutencaocampos-field-fix-col-grid">
                <h4 class="rhmanutencaocampos-title"><i class="fa fa-list" aria-hidden="true"></i>&nbsp;<span [translate]="'rhmanutencaocampos.processamentos'"></span></h4>
                <dx-data-grid [cgDxDataGrid]="proceDataGridDefinition" [dataSource]="proceDataSource" (onInitialized)="onProceInitialized($event)"> </dx-data-grid>
              </div>
              <div class="rhmanutencaocampos-field-fix-col-fixed">
                <h4 class="rhmanutencaocampos-title"><i class="fa fa-pencil-square-o" aria-hidden="true"></i>&nbsp;<span [translate]="'rhmanutencaocampos.camposModificar'"></span></h4>
                <div *ngFor="let item of modelFields | keyvalue">
                  <div class="rhmanutencaocampos-field-row">
                    <pl-edit type="checkbox" [model]="modelFields[item.key].checked" (modelChange)="onModelChangeCheckbox($event, item.key)"></pl-edit>
                    <label><span [translate]="modelFields[item.key].entityLabel"></span>:</label>
                    <entity-autocomplete
                      [entity]="modelFields[item.key].entityName"
                      [properties]="{disabled: !modelFields[item.key].checked, allowInvalid: false}"
                      inputClass="input-sm"
                      [(model)]="modelFields[item.key].entityModel">
                    </entity-autocomplete>
                  </div>
                </div>
                <pl-button *ngIf="false" type="button" klass="btn-sm btn-success mt-2" [onClick]="fnAtualizarProcessamentos">
                  <i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;<span [translate]="'rhmanutencaocampos.atualizarLista'"></span>
                </pl-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-newspaper-o" caption="rhmanutencaocampos.wizard.stepThree.wizardCaption" [hidePrevious]="true">
      <div *plNavWizardStepContent>
        <dx-data-grid [cgDxDataGrid]="relatorioDataGridDefinition" [dataSource]="relatorioDataSource">
          <div *dxTemplate="let item of 'relatorioIconTmpl'">
            <ng-container *ngIf="!item.data.ico">
              <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>
            </ng-container>

            <ng-container *ngIf="item.data.ico">
              <i class="fa fa-check text-success" aria-hidden="true"></i>
            </ng-container>
          </div>
        </dx-data-grid>
      </div>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
