<div class="pocs-list">
  <entity-list
    [entityName]="entityName"
    [maintenanceMode]="maintenanceMode"
    [filterCollapsed]="false"
    [dataReceived]="fnOnDataReceived"
    [toolbarInstanceId]="instanceName"
    [dataGridState]="dataGridState"
    [searchValue]="searchValue"
    [filter]="filterValue"
    [initialFilter]="initialFilterValue"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [properties]="listOptions"
    (evtOnRefresh)="onRefreshList()"
    (evtDataGridPreparing)="onDataGridPreparing($event)"
    (evtDataGridCellClick)="onDataGridCellClick($event)"
    (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
    (evtDataGridRowPrepared)="onDataGridRowPrepared($event)"
    (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
    (evtDataGridStateChanged)="onDataGridStateChanged($event)"
    (evtDataGridStateCleared)="onDataGridStateCleared()">
  </entity-list>

  <ul class="table-legend mt-3">
    <li *ngFor="let key of keysTipoContaContab">
      <div class="table-legend-badge" [ngClass]="classLegend + '-' + tipoContaContab[key]"></div>
      <div class="table-legend-caption" [translate]="'pocs.' + classLegend + '.' + tipoContaContab[key]"></div>
    </li>
  </ul>
</div>
