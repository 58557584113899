<div class="prev-tes-obras entity-detail-form">
  <pl-group>
    <pl-group>
      <label [translate]="'prevtesobras.codTipoPlaneamentoDe'"></label>
      <edit>
        <pl-edit type="integer" attrName="codTipoPlaneamentoDe" [(model)]="codTipoPlaneamentoDe" [properties]="{validators: {required: {value: false}}}"></pl-edit>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'prevtesobras.ate'"></label>
      <edit>
        <pl-edit type="integer" attrName="codTipoPlaneamentoAte" [(model)]="codTipoPlaneamentoAte"></pl-edit>
      </edit>
    </pl-group>
  </pl-group>

  <pl-group>
    <pl-group>
      <label [translate]="'prevtesobras.codEstadoObraDe'"></label>
      <edit>
        <pl-edit type="text" attrName="codEstadoObraDe" [(model)]="codEstadoObraDe"></pl-edit>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'prevtesobras.ate'"></label>
      <edit>
        <pl-edit type="text" attrName="nomeIRCT" [(model)]="codEstadoObraAte"></pl-edit>
      </edit>
    </pl-group>
  </pl-group>

  <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="prevTesObras" [dataSource]="dataGridDefinition.dataSource" (onInitialized)="onInitialized($event)"> </dx-data-grid>
</div>
