import {PlEditRegistryService} from 'pl-comps-angular';

export function configEntitiesMaps(plEditRegistryService: PlEditRegistryService): void {
  plEditRegistryService.map('clifos', 'ClientesFornecedores');
  plEditRegistryService.map('descritivos', 'DescritivoContabilidade');
  plEditRegistryService.map('estados', 'EstadosDocumentos');
  plEditRegistryService.map('diarios', 'Diario');
  plEditRegistryService.map('meiosPagamento', 'MeiosPagamento');
  plEditRegistryService.map('ivas', 'Iva');
  plEditRegistryService.map('periodos', 'Periodos');
  plEditRegistryService.map('pocs', 'PlanoOficialContabilidade');
  plEditRegistryService.map('qivaas', 'QIVAA');
  plEditRegistryService.map('qivaps', 'QIVAP');
}
