import {Directive, ElementRef, HostListener} from '@angular/core';
import {isFunction, nodeForEach} from '../../common/utilities/utilities';
import {ATTR_DATA_DOWNWARDS_CLICK} from './delegate.event.downwards.click.directive.interface';

@Directive({
  selector: '[plDelegateEventDownwards]'
})
export class PlDelegateEventDownwardsDirective {
  private readonly _element: HTMLElement;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>
  ) {
    this._element = this._elementRef.nativeElement;
  }

  @HostListener('click', ['$event'])
  public click(event: MouseEvent): void {
    const elements = this._element.querySelectorAll<HTMLElement>(`[${ATTR_DATA_DOWNWARDS_CLICK}]`);
    if (elements.length) {
      const target: HTMLElement = <HTMLElement>event.target;
      nodeForEach(elements, (element: HTMLElement) => {
        if (element && isFunction(element.click) &&
          element !== target && !element.contains(target) &&
          (!Object.prototype.hasOwnProperty.call(element, 'disabled') || !(<HTMLInputElement | HTMLButtonElement>element).disabled)) {
          element.click();
        }
      });
    }
  }
}
