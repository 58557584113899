<div class="modelo25 entity-detail-form">
  <div *ngIf="errors.erros.length > 0" [plAnimationShake]="errors.erros.length > 0">
    <pl-alert type="danger" *ngIf="errors.erros.length > 0" (evtClosed)="errors.erros.length = 0">
      <div [translate]="errors.tipoValidacao !== eTiposValidar.DEFAULT ? errors.mensagemTipoHeader : 'modelo25.errors.errorHeader'"></div>
      <hr class="mt-1 mb-1" />
      <div *ngFor="let message of errors.erros">
        <ng-container [ngSwitch]="!!message.mensagem">
          <ng-container *ngSwitchCase="true">
            <strong [innerHTML]="message.mensagem + ': ' + message.mensagemOriginal"></strong>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <strong [innerHTML]="message.mensagemOriginal"></strong>
          </ng-container>
        </ng-container>
      </div>
    </pl-alert>
  </div>

  <div *ngIf="!modeloConfig.contaAssociadaDonativoNumerarioList.length && !modeloConfig.contaAssociadaDonativoEspecieList.length">
    <pl-alert [type]="'warning'" [closeable]="false">
      <span [translate]="'modelo25.helpers.naoExistemContasConfiguradas'"></span>
      <span class="hyperlink" [innerHTML]="'modelo25.helpers.clickConfig' | translate" (click)="openConfigModal()"></span>
    </pl-alert>
  </div>

  <pl-form>
    <pl-navigator contentMaxHeight="80vh">
      <pl-navigator-item caption="modelo25.groups.q0.title" [order]="0" [id]="0">
        <div *plNavigatorItemCaption><span class="number-title-box">00</span>&nbsp;<span [translate]="'modelo25.groups.q0.title'"></span></div>
        <div *plNavigatorItemContent>
          <p></p>
          <p>
            <b><span [translate]="'modelo25.groups.q0.p2'"></span></b>
          </p>
          <p>
            <span [translate]="'modelo25.groups.q0.p3'"></span>
            <span [translate]="'modelo25.groups.q0.p4'"></span>
          </p>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo25.groups.q1.title" [order]="1" [id]="1">
        <div *plNavigatorItemCaption><span class="number-title-box">01</span>&nbsp;<span class="nav-item-title" [translate]="'modelo25.groups.q1.title'"></span></div>
        <div *plNavigatorItemContent>
          <div class="row">
            <div class="col-md-6 col-12">
              <label [translate]="'modelo25.groups.q1.campoNomeDeclarante'"></label>
              <pl-edit type="text" attrName="nomeDeclarante" [model]="modelo25.nomeDeclarante" [properties]="{disabled: true}"></pl-edit>
            </div>
            <div class="col-md-6 col-12 mt-3 mt-md-0">
              <label [translate]="'modelo25.groups.q1.campoNifDeclarante'"></label>
              <div class="d-flex">
                <div class="input-box-numbers">1</div>
                <pl-edit type="text" attrName="nifDeclarante" [model]="modelo25.nifDeclarante" [properties]="{disabled: true}"></pl-edit>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo25.groups.q2.title" [order]="2" [id]="2">
        <div *plNavigatorItemCaption><span class="number-title-box">02</span>&nbsp;<span class="nav-item-title" [translate]="'modelo25.groups.q2.title'"></span></div>
        <div *plNavigatorItemContent>
          <div>
            <label [translate]="'modelo25.groups.q2.ano'"></label>
            <div class="d-flex">
              <div class="input-box-numbers">2</div>
              <pl-edit type="text" attrName="ano" [model]="modelo25.ano" [properties]="{disabled: true}"></pl-edit>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo25.groups.q3.title" [order]="3" [id]="3">
        <div *plNavigatorItemCaption><span class="number-title-box">03</span>&nbsp;<span class="nav-item-title" [translate]="'modelo25.groups.q3.title'"></span></div>
        <div *plNavigatorItemContent>
          <div>
            <label [translate]="'modelo25.groups.q3.codDeServico'"></label>
            <div class="d-flex">
              <div class="input-box-numbers">3</div>
              <pl-edit type="text" attrName="codReparticaoFinancas" [model]="modelo25.codReparticaoFinancas" [properties]="{disabled: true}"></pl-edit>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo25.groups.q4.title" [order]="4" [id]="4">
        <div *plNavigatorItemCaption><span class="number-title-box">04</span>&nbsp;<span class="nav-item-title" [translate]="'modelo25.groups.q4.title'"></span></div>
        <div *plNavigatorItemContent>
          <div>
            <label [translate]="'modelo25.groups.q4.title'"></label>
            <pl-group>
              <edit>
                <pl-edit type="radiogroup" [(model)]="modelo25.tipoDeclaracao" [properties]="tiposDeclaracao"></pl-edit>
              </edit>
            </pl-group>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo25.groups.q5.title" [order]="5" [id]="5">
        <div *plNavigatorItemCaption><span class="number-title-box">05</span>&nbsp;<span class="nav-item-title" [translate]="'modelo25.groups.q5.title'"></span></div>
        <div *plNavigatorItemContent>
          <div *ngIf="!modelo25.registoList.length">
            <pl-alert [plAnimationShake]="!modelo25.registoList.length" [closeable]="false" [type]="'warning'" (evtClosed)="modelo25.registoList.length = 0">
              <div [innerHTML]="'modelo25.errors.errorNoDataList' | translate"></div>
            </pl-alert>
          </div>

          <dx-data-grid
            [cgDxDataGrid]="dataGridRegistoList"
            cgDxDataGridInstanceName="modelo25RegistoList"
            [dataSource]="modelo25.registoList"
            (onInitialized)="onInitialized($event)"
            (onEditCanceled)="onEditCanceled($event)"
            (onRowInserted)="onRowInserted()"
            (onRowRemoved)="onRowRemoved($event)"
            (onRowValidating)="onRowValidating($event)">
            <div *dxTemplate="let item of 'detailTemplateLinhas'">
              <dx-data-grid [cgDxDataGrid]="dataGridRegistoLinhaDetail" [dataSource]="item.data.linhas"></dx-data-grid>
            </div>
          </dx-data-grid>
        </div>
      </pl-navigator-item>
    </pl-navigator>
  </pl-form>
</div>
