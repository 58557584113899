<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'gto.copyTasksModalTitle'"></h4>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-md-3">
      <pl-group>
        <label [translate]="'gto.ano'"></label>
        <edit>
          <pl-select [source]="anosSource" [model]="model.ano" (modelChange)="onYearChange($event)" [clearable]="false"></pl-select>
        </edit>
      </pl-group>
    </div>
    <div class="col-md-6">
      <pl-group>
        <label [translate]="'gto.empresa'"></label>
        <edit>
          <pl-autocomplete
            [model]="model"
            [source]="nEmpresaSource"
            (modelChange)="nEmpresaChanged($event)"
            [allowEmpty]="false"
            [allowInvalid]="false"
            [properties]="{disallowClear: true}"
            [rowTemplate]="empresasTemplate"
            [output]="empresasTemplate">
          </pl-autocomplete>
        </edit>
      </pl-group>
    </div>
    <div class="col-md-3">
      <pl-group>
        <label [translate]="'gto.copyFor'"></label>
        <edit>
          <pl-edit type="radiogroup" [(model)]="model.copyFor" [properties]="{groupItems: copyForSource, validators: {required: {value: true}}}"> </pl-edit>
        </edit>
      </pl-group>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <h4 class="gto-copy-title" [translate]="'gto.tarefas'"></h4>
      <dx-data-grid [cgDxDataGrid]="dataGridDefinitionTasks" cgDxDataGridInstanceName="gtoTasks" [dataSource]="dataGridDefinitionTasks.dataSource" [(selectedRowKeys)]="selectedRowKeysTasks">
      </dx-data-grid>
    </div>

    <div class="col-md-8">
      <ng-container *ngIf="model.copyFor === copyForEnum.EMPRESA">
        <h4 class="gto-copy-title" [translate]="'gto.paraEmpresas'"></h4>
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinitionCompanies"
          cgDxDataGridInstanceName="gtoCompanies"
          [dataSource]="dataGridDefinitionCompanies.dataSource"
          (selectedRowKeysChange)="selectedRowKeysCompanies = $event"
          (onInitialized)="onInitialized($event)">
        </dx-data-grid>
      </ng-container>

      <ng-container *ngIf="model.copyFor === copyForEnum.ANO">
        <h4 class="gto-copy-title" [translate]="'gto.paraAno'"></h4>
        <div class="col-md-4">
          <pl-group>
            <label [translate]="'gto.ano'"></label>
            <edit>
              <pl-select [source]="anosSource" [(model)]="model.copyAno" [clearable]="false"></pl-select>
            </edit>
          </pl-group>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-sm btn-primary" [click]="fnCopyTasks" plPromise><i class="fa fa-fw fa-copy"></i>&nbsp;<span [translate]="'gto.copiar'"></span></button>
  <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
