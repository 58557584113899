import {ModuloComponent} from '../../../../components/module/module.component';
import {Component, Injector, OnInit} from '@angular/core';
import {EDelphiNumberTypes, isDefinedNotNull} from 'pl-comps-angular';
import {IJsonErpUser} from '../../../../services/account/jsonUserApi.interface';
import {ReportsRegistryService} from '../../../../components/reports/reports.registry.service';
import {ImpCtrlIrsService} from '../impCtrIrs.module.service';
import {IAnosCalculo} from '../../../ativos/ativosdepreciacao/ativosDepreciacao.module.interface';
import {IJsonReport} from '../../../../entities/reports/jsonReport.interface';
import {EReport} from '../../../../entities/reports/reports.interface';
import {IJsonImpCtrIrs} from '../jsonImpCtrIrs.module.interface';
import moment from 'moment';

@Component({
  selector: 'imp-ctr-irs',
  templateUrl: './impCtrIrs.module.component.html'
})
export class ImpCtrIrsModuleComponent extends ModuloComponent implements OnInit {
  public readonly metodoSource: Array<IAnosCalculo>;
  public readonly empresasTemplate: string;

  public empresas: Array<IJsonErpUser>;
  public empresasSource: Array<IJsonErpUser>;
  public report: IJsonReport;
  public pdfUrl: string;
  public collapsedPesqAvancada: boolean;
  public filters: IJsonImpCtrIrs;
  public showPdfViewer: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _impCtrIrsService: ImpCtrlIrsService,
    private readonly _reportsRegistryService: ReportsRegistryService
  ) {
    super(_injector);
    this.empresasTemplate = '{{nEmpresa}} - {{nomeEmpresa}}';
    this.empresas = [];
    this.collapsedPesqAvancada = true;

    this.filters = {
      deData: moment().startOf('month'),
      ateData: moment().endOf('month'),
      deCodEmp: 0,
      ateCodEmp: EDelphiNumberTypes.MaxSmallInt,
      nCopia: 0
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.showPdfViewer = false;
    this.toolbar.addButton({
      id: 'previsualizar',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-search fa-fw"></i>',
      class: 'btn-success',
      caption: 'impctrirs.btn.previsualizar',
      click: () => {
        this._previsualizar();
      }
    });
    this._loadEmpresas();
    this._loadDefaultReport();
  }

  private _previsualizar(): void {
    const listEmpresas = !this.empresas.length ? this.session.erp.nEmpresa : this.empresas.map((value: IJsonErpUser) => value.nEmpresa).join('-');
    this._impCtrIrsService.getListagemImpCtrlIrsUrl(this.filters, listEmpresas, this.report.name).subscribe((url: string) => {
      this.pdfUrl = url;
      this.showPdfViewer = true;
    });
  }

  private _loadEmpresas(): boolean {
    const empresassel: Array<IJsonErpUser> = [...this.session.erps];
    let idx = -1;
    const foundItem = empresassel.find((item: IJsonErpUser, index: number) => {
      if (item.nEmpresa === this.session.erp.nEmpresa) {
        idx = index;
        return true;
      }
      return false;
    });
    if (idx > -1 && isDefinedNotNull(foundItem)) {
      empresassel.splice(idx, 1);
      this.empresas = [foundItem];
    }
    this.empresasSource = empresassel;
    return true;
  }

  private _loadDefaultReport(): void {
    this._reportsRegistryService
      .get(EReport.ImpCtrIrs)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          this.report = reports[0];
        }
      });
  }
}
