<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title">{{ modalTitle }}</h4>
</div>

<div class="modal-body">
  <pl-form>
    <pl-group>
      <label>Listagem</label>
      <edit>
        <pl-edit type="reports_svat" [model]="reportModel" (modelChange)="onReportChange($event)" [properties]="{entity: {outputKey: 'name', outputDescription: 'title'}}"> </pl-edit>
      </edit>
    </pl-group>
  </pl-form>

  <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
