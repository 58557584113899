import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {UntypedFormGroup} from '@angular/forms';
import {copy, IPlNavWizardDefinition, IPlNavWizardEventBeforeChange, IPlNavWizardOptions, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {EditarCruzamentosPerfilModalComponent} from '../editarcruzamentosperfil/editarCruzamentosPerfil.modal.component';
import {ENTITY_NAME_PERFIL_CATEGORIA_ABONO, ENTITY_NAME_PERFIL_CATEGORIA_DESCONTO} from '../../../../modules/perfilcategoriaabdes/perfilCategoriaAbDes.module.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {ENTITY_NAME_ABDES, ETipoAbonoDesconto, ETipoCategoriaABDES, IABDESEntityService, ICRABDPerfil} from '../../abdes.entity.interface';
import {ETipoCategoriaDesc} from '../../../../datasources/tipocategoriadesc/tipoCategoriaDesc.datasource.interface';
import {ETipoCategoria} from '../../../../datasources/tipocategoria/tipoCategoria.datasource.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IAbDesCrabd, IJsonABDES, IJsonPerfilSistemaAbdes} from '../../jsonABDES.entity.interface';
import {IJsonPerfilCategoriaAbono, IJsonPerfilCategoriaDesconto} from '../../../../modules/perfilcategoriaabdes/jsonPerfilCategoriaAbDes.module.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import ArrayStore from 'devextreme/data/array_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonCRABD} from '../../../crabd/jsonCrAbD.entity.interface';
import {ETipoExcecaoCRABD} from '../../../../datasources/tipoexcecaocrabd/tipoExcecaoCRABD.datasource.interface';

const STEP_INDEX_CATEGORIA = 0;
const STEP_INDEX_PERFIL = 1;

@Component({
  selector: 'generate-abdes',
  templateUrl: './generateAbdes.modal.component.html'
})
export class GenerateAbdesModalComponent extends CGModalComponent<IAbDesCrabd> implements OnInit {
  @Input() public abono: boolean;

  public readonly definition: IPlNavWizardDefinition;
  public readonly propertiesNavWizard: IPlNavWizardOptions;
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly perfilSistemaAbdesTemplate: string;

  public abdesGeneratedModel: IAbDesCrabd;
  public formCategoria: UntypedFormGroup;
  public formPerfil: UntypedFormGroup;
  public categoria: ETipoCategoria | ETipoCategoriaDesc;
  public perfilAbDes: IJsonPerfilCategoriaAbono & IJsonPerfilCategoriaDesconto;
  public listaPerfilSistemaAbdes: Array<IJsonPerfilSistemaAbdes>;
  public perfilSistemaAbdes: IJsonPerfilSistemaAbdes;
  public ausencia: number;
  public nome: string;
  public descricao: string;
  public titleAbnDesc: string;
  public firstStepCompleted: boolean;
  public percAumentHorExtraVisible: boolean;
  public valorLimiteIncVisible: boolean;
  public valorUnit: number;
  public percAumentHorExtra: number;
  public valorUnitVisible: boolean;
  public valorLimiteInc: number;
  public ausenciasVisible: boolean;
  public disableBtnGerar: boolean;
  public isPerfilSistema: boolean;

  private readonly _perfilAbonoService: IEntityService<IJsonPerfilCategoriaAbono>;
  private readonly _perfilDescontoService: IEntityService<IJsonPerfilCategoriaDesconto>;
  private readonly _perfilABDESService: IABDESEntityService;

  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    protected readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this._perfilAbonoService = this._entityServiceBuilder.build(ENTITY_NAME_PERFIL_CATEGORIA_ABONO);
    this._perfilDescontoService = this._entityServiceBuilder.build(ENTITY_NAME_PERFIL_CATEGORIA_DESCONTO);
    this._perfilABDESService = this._entityServiceBuilder.build<IJsonABDES, IABDESEntityService>(ENTITY_NAME_ABDES);
    this.perfilSistemaAbdesTemplate = '{{designaBreve}}';
    this.definition = {
      items: [],
      force: false
    };
    this.propertiesNavWizard = {
      disableFinalize: true
    };
    this.perfilAbDes = {
      nCategoriaAbDes: 0,
      tipoArredondamento: 0,
      tipoRecolha: 0,
      unidadeDefeito: 0,
      nomeUnidadeDefeito: '',
      tipoRendimento: '',
      codRem: '',
      nomeCodRem: '',
      nContaDebitoEmp: '',
      nContaCreditoEmp: '',
      nContaDebitoPatronato: '',
      nContaCreditoPatronato: '',
      cruzamentos: [],
      grupo: 0,
      nomeGrupo: '',
      nomeTipoRendimento: '',
      nHorasDiaProvDesc: 0,
      contribuiCalculoCustoPrecoH: false
    };
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {
          caption: 'generateAbdes.crabdTable.fields.categoria',
          columns: [{dataField: 'nomeCategoria', dataType: 'string', caption: 'generateAbdes.crabdTable.fields.nomeCategoria'}]
        },
        {
          caption: 'generateAbdes.crabdTable.fields.empregado',
          columns: [
            {dataField: 'entSuporteEmpregado', dataType: 'boolean', caption: 'generateAbdes.crabdTable.fields.temIncidenciaEmpregado'},
            {dataField: 'entSuporteEmpregadoIncidencia', dataType: 'number', caption: 'generateAbdes.crabdTable.fields.incidenciaEmpregado'}
          ]
        },
        {
          caption: 'generateAbdes.crabdTable.fields.patronato',
          columns: [
            {dataField: 'entSuportePatronato', dataType: 'boolean', caption: 'generateAbdes.crabdTable.fields.temIncidenciaPatronato'},
            {dataField: 'entSuportePatronatoIncidencia', dataType: 'number', caption: 'generateAbdes.crabdTable.fields.incidenciaPatronato'}
          ]
        },
        {
          caption: 'generateAbdes.crabdTable.fields.desconto',
          columns: [
            {dataField: 'nCodDesconto', dataType: 'number', caption: 'generateAbdes.crabdTable.fields.nDesconto'},
            {dataField: 'descricao', dataType: 'string', caption: 'generateAbdes.crabdTable.fields.nomeDesconto'}
          ]
        },
        {type: 'buttons', cellTemplate: 'cellTemplateBtnsEdit'}
      ],
      dataSource: [],
      export: {filename: 'abdes.tabIntegracaoCruzamento'},
      remoteOperations: false
    };
    this.ausencia = 0;
    this.valorUnit = 0;
    this.valorLimiteInc = 0;
    this.percAumentHorExtra = 0;
    this.disableBtnGerar = true;
    this.listaPerfilSistemaAbdes = [this._emptyPerfilSistemaAbdes()];
    this.perfilSistemaAbdes = this._emptyPerfilSistemaAbdes();
  }

  public ngOnInit(): void {
    this.nome = '';
    this.descricao = '';
    this.firstStepCompleted = false;
    this.titleAbnDesc = this.abono ? this._plTranslateService.translate('generateAbdes.abono') : this._plTranslateService.translate('generateAbdes.desconto');
  }

  public readonly fnBeforeStepChange = (event: IPlNavWizardEventBeforeChange): Promise<boolean> => this._beforeStepChange(event);

  public readonly fnGenerateABDES = (): Promise<void> => this._generateABDES();

  public async categoriaChange(value: ETipoCategoria | ETipoCategoriaDesc): Promise<void> {
    this.categoria = value;
    this.percAumentHorExtraVisible = false;
    this.valorLimiteIncVisible = false;
    this.ausenciasVisible = false;
    this.firstStepCompleted = Boolean(value);
    if (this.abono) {
      if (value === ETipoCategoria.HORA_EXTRA) {
        this.percAumentHorExtraVisible = true;
      } else if (value === ETipoCategoria.AJUDA_CUSTO) {
        this.valorLimiteIncVisible = true;
        this.valorUnitVisible = true;
      } else if (value === ETipoCategoria.SUB_REFEICAO || value === ETipoCategoria.TicketValesRefeicao || value === ETipoCategoria.DespesasTeletrabalho) {
        this.valorLimiteIncVisible = true;
      }
    } else if (value === ETipoCategoriaDesc.Falta) {
      this.ausenciasVisible = true;
    }

    this.listaPerfilSistemaAbdes = isNaN(this.categoria)
      ? []
      : await this._perfilABDESService.listaPerfilSistema(this.abono, value).then((response: HttpResponse<Array<IJsonPerfilSistemaAbdes>>) => response.body);
    this.listaPerfilSistemaAbdes.unshift(this._emptyPerfilSistemaAbdes());

    this.perfilSistemaAbdesChanged({inputValue: '', item: this._emptyPerfilSistemaAbdes()});
  }

  public editarCruzamento(cruzamento: ICRABDPerfil): void {
    const modalInstance = this._cgModalService.showVanilla(EditarCruzamentosPerfilModalComponent, {size: 'lg'});
    const componentInstance: EditarCruzamentosPerfilModalComponent = modalInstance.componentInstance;
    componentInstance.cruzamento = copy(cruzamento);
    modalInstance.result.then((result: ICRABDPerfil) => {
      cruzamento.tipoCategoria = result.tipoCategoria;
      cruzamento.entSuportePatronatoIncidencia = result.entSuportePatronatoIncidencia;
      cruzamento.entSuporteEmpregadoIncidencia = result.entSuporteEmpregadoIncidencia;
      cruzamento.entSuporteEmpregado = result.entSuporteEmpregado;
      cruzamento.entSuportePatronato = result.entSuportePatronato;
      cruzamento.descricao = result.descricao;
      cruzamento.nCodDesconto = result.nCodDesconto;
      cruzamento.nCategoria = result.nCategoria;
      cruzamento.nomeCategoria = result.nomeCategoria;

      return this._dataGridInstance.getDataSource().store().update(cruzamento._index, cruzamento);
    });
  }

  public clearCruzamento(cruzamento: ICRABDPerfil): void {
    cruzamento.nCodDesconto = '';
    cruzamento.descricao = '';
    cruzamento.entSuporteEmpregado = false;
    cruzamento.entSuportePatronato = false;
    cruzamento.entSuporteEmpregadoIncidencia = 0;
    cruzamento.entSuportePatronatoIncidencia = 0;
    this._dataGridInstance.getDataSource().store().update(cruzamento._index, cruzamento);
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public perfilSistemaAbdesChanged(value: {inputValue: string; item: IJsonPerfilSistemaAbdes}): void {
    if (value?.item) {
      this.perfilSistemaAbdes = value.item;
    }

    this.isPerfilSistema = this.perfilSistemaAbdes?.perfilSistema && this.perfilSistemaAbdes?.perfilSistema > 0;
  }

  private _generateABDES(): Promise<void> {
    return this._dataGridInstance
      .getDataSource()
      .store()
      .load()
      .then((list: Array<ICRABDPerfil>) => {
        this.perfilAbDes.cruzamentos = list;
        const abdesc: IJsonABDES = {
          codSSCol: this.perfilAbDes.codRem,
          nCodABDESC: '0',
          codAbDesc: '0',
          abonoDesc: this.abono ? ETipoAbonoDesconto.Abono : ETipoAbonoDesconto.Desconto,
          tipoRendimento: this.perfilAbDes.tipoRendimento,
          grupoAbono: this.perfilAbDes.grupo,
          grupoDesconto: this.perfilAbDes.grupo,
          contaEncCRE: this.perfilAbDes.nContaCreditoPatronato,
          contaEncDEB: this.perfilAbDes.nContaDebitoPatronato,
          contaMovCred: this.perfilAbDes.nContaCreditoEmp,
          contaMovDeb: this.perfilAbDes.nContaDebitoEmp,
          grupoAbonoNome: this.perfilAbDes.nomeGrupo,
          codUnidade: this.perfilAbDes.unidadeDefeito,
          tipoValorUnitRecolha: this.perfilAbDes.tipoRecolha,
          calcCustHora: (<IJsonPerfilCategoriaAbono>this.perfilAbDes).contribuiCalculoCustoPrecoH,
          codCGASit: '',
          procNatal: this.isPerfilSistema ? this.perfilSistemaAbdes.procNatal : 0,
          procFerias: this.isPerfilSistema ? this.perfilSistemaAbdes.procFerias : 0,
          mapaSindic: 0,
          mapaSeguro: 0,
          horaDiaValor: 0,
          nHorasDiaProvDesc: 0,
          grupoDescontoNome: '',
          valorLimIncidencia: this.valorLimiteInc,
          naoImpDecIRS114: this.isPerfilSistema ? this.perfilSistemaAbdes.naoImpDecIRS114 : 0,
          tipoArredondamento: this.perfilAbDes.tipoArredondamento,
          valorFixo: this.valorUnit,
          designacaoCompleta: this.descricao,
          designacaoBreve: this.nome,
          abonoDescDesativado: false,
          codRubrOrcAbon: '',
          codRubrOrcEnc: '',
          coefHExtra: 0,
          colMapaCX: 0,
          colQuadroP: 0,
          diaHoraExtra: 0,
          entiSuporte: 1,
          nHorasDescontoProporcional: 0,
          percInciSegSocial: this.isPerfilSistema ? this.perfilSistemaAbdes.percInciSegSocial : 0,
          percLimValorHExtra: this.percAumentHorExtra,
          perctDescSeguro: 0,
          tipoDescontoProporcional: 0,
          tipoHoraExtr: 0,
          usaSPReducaoRemuneratoria: false,
          perfilSistema: this.isPerfilSistema ? this.perfilSistemaAbdes.perfilSistema : 0
        };
        this.abdesGeneratedModel = {
          abdesc: abdesc,
          cruzamentos: [],
          perfilSistemaAbdes: this.perfilSistemaAbdes
        };

        if (this.abono) {
          return this._perfilAbonoService.get<string>({id: `${-1}/generatecodabono`}).then((response: HttpResponse<string>) => {
            this.abdesGeneratedModel.abdesc.calcCustHora = (<IJsonPerfilCategoriaAbono>this.perfilAbDes).contribuiCalculoCustoPrecoH;
            this.abdesGeneratedModel.abdesc.codAbDesc = response.body;
            this.abdesGeneratedModel.abdesc.nCodABDESC = String(ETipoAbonoDesconto.Abono) + response.body;
            this.abdesGeneratedModel.cruzamentos = this._crabdPerfilToCrabd(this.perfilAbDes.cruzamentos, this.abdesGeneratedModel.abdesc.nCodABDESC, this.abdesGeneratedModel.abdesc.codAbDesc);
            this.close(this.abdesGeneratedModel);
          });
        }
        return this._perfilDescontoService.get<string>({id: `${-1}/generatecoddesconto`}).then((response: HttpResponse<string>) => {
          this.abdesGeneratedModel.abdesc.nHorasDiaProvDesc = (<IJsonPerfilCategoriaDesconto>this.perfilAbDes).nHorasDiaProvDesc;
          this.abdesGeneratedModel.abdesc.codAbDesc = response.body;
          this.abdesGeneratedModel.abdesc.nCodABDESC = String(ETipoAbonoDesconto.Desconto) + response.body;
          this.abdesGeneratedModel.cruzamentos = this._crabdPerfilToCrabd(this.perfilAbDes.cruzamentos, this.abdesGeneratedModel.abdesc.nCodABDESC, this.abdesGeneratedModel.abdesc.codAbDesc);
          this.close(this.abdesGeneratedModel);
        });
      });
  }

  private async _beforeStepChange({nextStep, currentStep, type}: IPlNavWizardEventBeforeChange): Promise<boolean> {
    if (currentStep === this.definition.items[STEP_INDEX_CATEGORIA] && (type === 'next' || (nextStep && nextStep === this.definition.items[STEP_INDEX_PERFIL]))) {
      if (!this.formCategoria.valid || isNaN(this.categoria)) {
        this._plAlertService.error('generateAbdes.errorcateg');
        return false;
      }
      if (this.abono) {
        const perfilAbono: HttpResponse<IJsonPerfilCategoriaAbono> = await this._perfilAbonoService.get({id: this.categoria});
        this.perfilAbDes = <IJsonPerfilCategoriaAbono & IJsonPerfilCategoriaDesconto>perfilAbono.body;
      } else {
        const perfilDesconto: HttpResponse<IJsonPerfilCategoriaDesconto> = await this._perfilDescontoService.get({id: `${this.categoria}/${this.ausencia}`});
        this.perfilAbDes = <IJsonPerfilCategoriaAbono & IJsonPerfilCategoriaDesconto>perfilDesconto.body;
      }
      if (this.isPerfilSistema) {
        this.perfilAbDes.tipoRendimento = this.perfilSistemaAbdes.tipRendimento;
        this.perfilAbDes.nomeTipoRendimento = this.perfilSistemaAbdes.nomeTipoRendimento;

        this.perfilAbDes.codRem = this.perfilSistemaAbdes.codSSCol;
        this.perfilAbDes.nomeCodRem = this.perfilSistemaAbdes.nomeCodRem;

        this.perfilAbDes.tipoArredondamento = this.perfilSistemaAbdes.tipArredond;

        if (this.abono) {
          for (const cruzamento of this.perfilAbDes.cruzamentos) {
            if (cruzamento.tipoCategoria === ETipoCategoriaABDES.IRS) {
              cruzamento.nCodDesconto = this.perfilSistemaAbdes.crabdIRS ? this.perfilSistemaAbdes.nCodDescontoIrs : '';
              cruzamento.entSuporteEmpregado = this.perfilSistemaAbdes.crabdIRS ? this.perfilSistemaAbdes.crabdIRS : false;
            }

            if (cruzamento.tipoCategoria === ETipoCategoriaABDES.SegSocial) {
              cruzamento.nCodDesconto = this.perfilSistemaAbdes.crabdSSEmpregado || this.perfilSistemaAbdes.crabdSSPatronato ? this.perfilSistemaAbdes.nCodDescontoSS : '';
              cruzamento.entSuporteEmpregado = this.perfilSistemaAbdes.crabdSSEmpregado || this.perfilSistemaAbdes.crabdSSPatronato ? this.perfilSistemaAbdes.crabdSSEmpregado : false;
              cruzamento.entSuportePatronato = this.perfilSistemaAbdes.crabdSSEmpregado || this.perfilSistemaAbdes.crabdSSPatronato ? this.perfilSistemaAbdes.crabdSSPatronato : false;
            }
          }
        }
      }
      this.disableBtnGerar = false;
      this._applyDataSource();
    }
    return true;
  }

  private _applyDataSource(): void {
    const cRABDPerfilList: Array<ICRABDPerfil> = this.perfilAbDes.cruzamentos;
    let index = 0;
    for (const icrabdPerfil of cRABDPerfilList) {
      icrabdPerfil._index = index;
      index++;
    }
    this.dataGridDefinition.dataSource = new ArrayStore({
      key: '_index',
      data: cRABDPerfilList
    });
  }

  private _crabdPerfilToCrabd(crabdPerfilList: Array<ICRABDPerfil>, nCodOrigem: string, codOrigem: string): Array<IJsonCRABD> {
    const crabdList: Array<IJsonCRABD> = [];
    const tipoAbdescDestino = this.abono ? ETipoAbonoDesconto.Desconto : ETipoAbonoDesconto.Abono;
    for (const crabdPerfil of crabdPerfilList) {
      crabdList.push({
        tipoAbDescOrigem: this.categoria,
        codOrigem: codOrigem,
        nCodOrigem: nCodOrigem,
        tipoAbDescDestino: tipoAbdescDestino,
        codDestino: crabdPerfil.nCodDesconto,
        nCodDestino: crabdPerfil.nCodDesconto,
        entSuporteEmpregado: crabdPerfil.entSuporteEmpregado,
        entSuportePatronato: crabdPerfil.entSuportePatronato,
        incidenciaEmpregado: crabdPerfil.entSuporteEmpregadoIncidencia,
        incidenciaPatronato: crabdPerfil.entSuportePatronatoIncidencia,
        tipoExcecao: ETipoExcecaoCRABD.SemExcecoes,
        categoriaAbDescDestino: crabdPerfil.nCategoria,
        siglaCategoriaAbDescDestino: '',
        siglaTipoAbdesDestino: '',
        nomeExcecao: '',
        nomeABDESDestino: crabdPerfil.descricao,
        nomeCategoria: crabdPerfil.nomeCategoria
      });
    }
    return crabdList;
  }

  private _emptyPerfilSistemaAbdes(): IJsonPerfilSistemaAbdes {
    return {
      perfilSistema: 0,
      abonoDESC: this.abono ? ETipoAbonoDesconto.Abono : ETipoAbonoDesconto.Abono,
      nCodAbDesc: '',
      designaBreve: this._plTranslateService.translate('generateAbdes.fields.semPerfil'),
      grupoAbono: 0,
      grupoDesconto: 0,
      tipRendimento: '',
      tipArredond: undefined,
      procNatal: 0,
      procFerias: 0,
      naoImpDecIRS114: undefined,
      codSSCol: '',
      percInciSegSocial: 0,
      crabdIRS: false,
      crabdSSEmpregado: false,
      crabdSSPatronato: false,
      nCodDescontoIrs: '',
      nCodDescontoSS: '',
      categoriaAbono: undefined,
      categoriaDesconto: undefined,
      bloqueiaLimitesIncidencia: false,
      nomeCodRem: '',
      nomeTipoRendimento: ''
    };
  }
}
