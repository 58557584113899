<div class="calculo-diferimentos">
  <pl-form [(ngForm)]="form" [submit]="calculaDiferimentos" [plPromise]="promise">
    <pl-alert *ngIf="messageFieldValor" type="error" [closeable]="false"><i class="fa fa-times-circle" aria-hidden="true"></i>&nbsp;<span [translate]="messageFieldValor"></span></pl-alert>

    <cg-card-panel #cardPanel="cgCardPanel" [caption]="'calculodiferimentos.filtrosCalculo'">
      <div *cgCardPanelContent>
        <div class="px-2 py-1">
          <pl-group>
            <pl-group>
              <label [translate]="'calculodiferimentos.fields.valor'"></label>
              <edit>
                <pl-edit type="number" attrName="valor" [model]="header.valor" (modelChange)="header.valor = $event; emitHeader()" [properties]="{validators: {required: {value: true}}}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'calculodiferimentos.fields.dataini'"></label>
              <edit>
                <pl-edit type="date" attrName="dataini" [model]="header.dataini" (modelChange)="header.dataini = $event; emitHeader()" plAutoFocus></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'calculodiferimentos.fields.datafim'"></label>
              <edit>
                <pl-edit type="date" attrName="datafim" [model]="header.datafim" (modelChange)="dataFimChanged($event)" [properties]="{events: {keydown: keydownDataFim}}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group class="form-group-tipo-diferimento">
              <label [translate]="'calculodiferimentos.fields.tipodiferimento'"></label>
              <edit>
                <pl-autocomplete
                  attrName="tipodiferimento"
                  [source]="sourceMetodos"
                  [model]="header.nomeTipodiferimento"
                  (modelChange)="setTipoDiferimento($event)"
                  [allowEmpty]="false"
                  [allowInvalid]="false"
                  rowTemplate="label"
                  output="label">
                </pl-autocomplete>
              </edit>
            </pl-group>

            <pl-group *ngIf="diferimentosParams.fromContabilidade">
              <label [translate]="'calculodiferimentos.fields.tipoDataRefDiferimento'"></label>
              <edit>
                <pl-autocomplete
                  attrName="tipoDataRefDiferimento"
                  [source]="sourceTiposDataRef"
                  [model]="header.nomeTipoDataRefDiferimento"
                  (modelChange)="setTipoDataRefDiferimento($event)"
                  [allowEmpty]="false"
                  [allowInvalid]="false"
                  rowTemplate="label"
                  output="label">
                </pl-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group class="align-self-end">
              <edit>
                <pl-button type="submit" klass="btn-primary btn-sm" [disabled]="!header.valor"><i class="fa fa-fw fa-calculator"></i><span [translate]="'global.btn.calculate'"></span></pl-button>
              </edit>
            </pl-group>
          </pl-group>
        </div>
      </div>
    </cg-card-panel>
  </pl-form>

  <dx-data-grid [cgDxDataGrid]="dataGridDefinition" [dataSource]="dataGridDefinition.dataSource" (onInitialized)="onInitialized($event)"></dx-data-grid>
</div>
