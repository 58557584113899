import {BehaviorSubject, fromEvent, Observable, Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {Inject, Injectable, OnDestroy} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {getScreenWidth, isMobile as cgcIsMobile} from '../constants';

const DEBOUNCE_TIMEOUT = 50;

@Injectable({
  providedIn: 'root'
})
export class PlDocumentService implements OnDestroy {
  private readonly _document: Document;
  private readonly _subjectDragging: BehaviorSubject<boolean>;
  private readonly _subjectWindowResize: Subject<Event>;
  private readonly _subjectWindowWidth: BehaviorSubject<number>;
  private readonly _subjectIsMobile: BehaviorSubject<boolean>;
  private readonly _subscriptionWindowResize: Subscription;
  private _observableDragging: Observable<boolean>;
  private _observableWindowResize: Observable<Event>;
  private _observableWindowWidth: Observable<number>;
  private _observableIsMobile: Observable<boolean>;

  constructor(@Inject(DOCUMENT) document: unknown) {
    this._document = <Document>document;
    this._subjectDragging = new BehaviorSubject<boolean>(false);
    this._subjectWindowResize = new Subject<Event>();
    this._subjectWindowWidth = new BehaviorSubject<number>(this._getScreenWidth());
    this._subjectIsMobile = new BehaviorSubject<boolean>(this._getIsMobile());
    if (this._document?.defaultView) {
      const window: Window = this._document.defaultView;
      this._subscriptionWindowResize = fromEvent<Event>(window, 'resize', {passive: true}).subscribe((event: Event) => {
        this._subjectWindowResize.next(event);
        this._subjectWindowWidth.next(this._getScreenWidth());
        this._subjectIsMobile.next(this._getIsMobile());
      });
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionWindowResize.unsubscribe();
    this._subjectDragging.complete();
    this._subjectWindowResize.complete();
    this._subjectWindowWidth.complete();
    this._subjectIsMobile.complete();
  }

  public dragging(): Observable<boolean> {
    if (!this._observableDragging) {
      this._observableDragging = this._subjectDragging.asObservable();
    }
    return this._observableDragging;
  }

  public setDragging(dragging: boolean): void {
    this._subjectDragging.next(dragging);
  }

  public windowResize(): Observable<Event> {
    if (!this._observableWindowResize) {
      this._observableWindowResize = this._subjectWindowResize.asObservable();
    }
    return this._observableWindowResize;
  }

  public windowResizeDebounced(dueTime: number = DEBOUNCE_TIMEOUT): Observable<Event> {
    return this.windowResize().pipe(debounceTime(dueTime));
  }

  public windowWidth(): Observable<number> {
    if (!this._observableWindowWidth) {
      this._observableWindowWidth = this._subjectWindowWidth.asObservable();
    }
    return this._observableWindowWidth;
  }

  public isMobile(): Observable<boolean> {
    if (!this._observableIsMobile) {
      this._observableIsMobile = this._subjectIsMobile.asObservable();
    }
    return this._observableIsMobile;
  }

  private _getScreenWidth(): number {
    return getScreenWidth();
  }

  private _getIsMobile(): boolean {
    return cgcIsMobile();
  }
}
