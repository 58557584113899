<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'debDiretosSEPA.strings.impressaoEmissoesModalTitle'"></h4>
</div>

<div class="modal-body">
  <pl-form>
    <pl-group>
      <label [translate]="'components.contabilidade.listagens.title'"></label>
      <edit>
        <pl-edit type="reports_ddsepa" attrName="listagem" [(model)]="reportModel" plAutoFocus></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'debDiretosSEPA.fields.nLoteFicheiro'"></label>
      <edit>
        <pl-autocomplete
          #ficheirosEmitidosAc
          [model]="modelFicheirosEmitidos"
          (modelChange)="ficheirosEmitidosChanged($event)"
          [rowTemplate]="rowTemplateFicheirosEmitidos"
          [properties]="propertiesAutocompleteFicheirosEmitidos"
          [output]="outputFicheirosEmitidos">
          <ng-template plAutocompleteDropdownContent let-autocomplete="component">
            <div class="pl-autocomplete-dropdown ddsepa-ficheiros-emitidos-dropdown ddsepa-ficheiros-emitidos-dropdown-all">
              <dx-data-grid
                [cgDxDataGrid]="dataGridFicheirosEmitidos"
                [cgDxDataGridInstanceName]="dataGridFicheirosEmitidosInstanceName"
                [dataSource]="dataGridFicheirosEmitidos.dataSource"
                (onInitialized)="onDataGridFicheirosEmitidosInitialized($event)"
                (onFocusedRowChanged)="onDataGridFicheirosEmitidosFocusedRowChanged($event)">
              </dx-data-grid>
            </div>
          </ng-template>
        </pl-autocomplete>
      </edit>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'debDiretosSEPA.strings.dataLiquidacao'"></label>
        <edit>
          <pl-edit type="date" [(model)]="filters.dataLiquidacaoDe"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'debDiretosSEPA.strings.ate'"></label>
        <edit>
          <pl-edit type="date" [(model)]="filters.dataLiquidacaoAte" [properties]="{events: {keydown: fnKeydownDataLiquidacaoAte}}"></pl-edit>
        </edit>
        <actions>
          <pl-button type="button" klass="btn-primary btn-action-pesquisar" (evtClicked)="listar()"><i class="fa fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span></pl-button>
        </actions>
      </pl-group>
    </pl-group>
  </pl-form>

  <br />

  <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
</div>
