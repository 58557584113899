<div class="configuracoes-eventos-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'prheventosconfig.title_small'"></h5>
  </div>

  <div class="modal-body">
    <pl-form>
      <pl-group>
        <pl-group>
          <label [translate]="'prheventosconfig.fields.tipoEventoPlaceholder'"></label>
          <edit>
            <div class="form-control-align">{{ tipoEvento.nome }}</div>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'prheventosconfig.fields.descricao'"></label>
          <edit>
            <pl-edit type="text" attrName="descricao" [model]="cfgEvent?.descricao" (modelChange)="cfgEvent.descricao = $event" [properties]="{validators: {required: {value: true}}}"> </pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="tipoEvento.cod === tipos.Faltas">
        <label [translate]="'prheventosconfig.fields.tipofalta'"></label>
        <edit>
          <pl-autocomplete
            [source]="listaConfig.listaTipo"
            [model]="cfgEvent?.tipoFalta"
            (evtSelected)="evtTipoFaltaChanged($event)"
            [allowInvalid]="false"
            [rowTemplate]="'descricaoTipo'"
            [output]="'descricaoTipo'">
          </pl-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'prheventosconfig.fields.tipoProcessamento'"></label>
        <edit>
          <pl-autocomplete
            [source]="listaConfig.listaTipoProcessamento"
            [model]="cfgEvent?.processamento"
            (evtSelected)="evtTipoProcessamentoChanged($event)"
            [allowInvalid]="false"
            [rowTemplate]="'descricaoTipoProcessamento'"
            [output]="'descricaoTipoProcessamento'">
          </pl-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label *ngIf="tipoEvento.cod === tipos.Faltas" [translate]="'prheventosconfig.fields.desconto'"></label>
        <label *ngIf="tipoEvento.cod !== tipos.Faltas" [translate]="'prheventosconfig.fields.abono'"></label>
        <edit>
          <pl-autocomplete
            [source]="listaConfig.listaAbDes"
            [model]="cfgEvent?.abdes"
            (evtSelected)="evtABDESChanged($event)"
            [allowInvalid]="false"
            [rowTemplate]="'designacao'"
            [output]="'designacao'">
          </pl-autocomplete>
        </edit>
      </pl-group>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span> </pl-button>

    <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
