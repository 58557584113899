import moment from 'moment';
import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {EMonth, isObject, isUndefined, isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import {AuthService} from '../../../../services/auth/auth.service';
import {DATA_SOURCE_NAME_PERIOCIDADE_CONTRATO_TIPO} from '../../../../datasources/periocidadecontratotipo/periocidadeContratoTipo.datasource.interface';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {EProcAutoTipo, IJsonClasseContrato} from '../../../classescontratos/jsonClasseContrato.entity.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonContratoTipo} from '../../../contratostipos/jsonContratoTipo.entity.interface';
import {IJsonDocfaNum} from '../../../docfasnums/jsonDocfaNum.entity.interface';
import {IJsonDocfa} from '../../../docfas/jsonDocFa.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {ROLE} from '../../../../services/role.const';
import {ENTITY_NAME_DOCFAS_NUMS} from '../../../docfasnums/docfasNums.entity.interface';

const NUMBER_OF_MONTHS = 12;
const NUMBER_OF_MONTHS_NEGATIVE = -12;

@Component({
  selector: 'contratos-tipo-avencas-edit',
  templateUrl: './contratosTipoAvencas.entity.edit.component.html'
})
export class ContratosTipoAvencasEditComponent extends ModuloEntityDetailComponent<IJsonContratoTipo> implements OnInit {
  public readonly rolesComercialAdvanced: ROLE;
  public readonly dataSourceNamePeriocidadeContratoTipo: string = DATA_SOURCE_NAME_PERIOCIDADE_CONTRATO_TIPO;
  public readonly docfasOutput: string;
  public readonly variablesTxtContratoSource: Array<string>;
  public readonly procAutoTipoEnum: typeof EProcAutoTipo;

  public docFa: IJsonDocfa;
  public docfaNumFilter: string;
  public infoFaturacaoMes: string;
  public nTipoContrato: number;
  public comercialAdvanced: boolean;

  private readonly _serviceDocFaNum: IEntityService<IJsonDocfaNum>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _authService: AuthService
  ) {
    super(_injector);
    this.rolesComercialAdvanced = ROLE.ERPADVANCED;
    this._serviceDocFaNum = this._entityServiceBuilder.build<IJsonDocfaNum>(ENTITY_NAME_DOCFAS_NUMS);
    this.docfasOutput = '{{nDocFa}} - {{nome}}';

    this._authService.hasAuthority(this.rolesComercialAdvanced).then((hasAuthority: boolean) => {
      this.comercialAdvanced = hasAuthority;
    });
    this.variablesTxtContratoSource = ['@Descriçao', '@DtIni', '@DtFim', '@NContrato', '@PeriodoServico', '@AnoPeriodoServico'];
    this.procAutoTipoEnum = EProcAutoTipo;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.docfaNumFilter = isObject(this.model) && this.model.nome ? `nDocFa=${this.model.nDocfa}&encerrado=0&visivelERPCloud=1` : 'nDocFa=0&encerrado=0&visivelERPCloud=1';
    this.nTipoContrato = this.type !== this.stateTypes.NEW ? Number(this.model.nTipoContrato) : undefined;
    if (!this.model.faturacaoNoMes) {
      this.model.faturacaoNoMes = 0;
    }
    this._evaluateInfoFaturacaoMes();
  }

  public onClasseContratoChanged(classeContrato: IJsonClasseContrato): void {
    if (!classeContrato || isUndefined(classeContrato.nClasseCtr)) {
      return;
    }

    this.model.nClasseCtr = classeContrato.nClasseCtr;
    this.model.classeTipo = classeContrato.classeTipo;
  }

  public onDocFaChanged(docFa: IJsonDocfa): void {
    if (!docFa || isUndefined(docFa.nDocFa)) {
      this._plAlertService.error(this._translateService.instant('docfas.errors.tipoDocNotExist'));
      this.docfaNumFilter = 'nDocFa=0&encerrado=0&visivelERPCloud=1';
      return;
    }

    this.docfaNumFilter = `nDocFa=${docFa.nDocFa}&encerrado=0&visivelERPCloud=1`;

    this.model.nNumer = null;
    this.model.nDocfa = docFa.nDocFa;
    this.model.nomeDocfa = docFa.nome;
    this.docFa = docFa;
  }

  public addNumer(): Promise<void> {
    const nnumer: IJsonDocfaNum = {
      idDocfaNum: 0,
      nDocfa: this.model.nDocfa ? this.model.nDocfa : 1,
      nNumer: null,
      num: 0,
      descNumer: 'Nova Série',
      data: moment(),
      numDraft: 0,
      nConta: '',
      encerrado: false,
      atcud: '',
      visivelERPCloud: true
    };

    return this._serviceDocFaNum.post({body: nnumer}).then((response: HttpResponse<IJsonDocfaNum>) => {
      if (response.body) {
        this.model.nNumer = response.body.nNumer;
      }
    });
  }

  public changedNTipoContrato(nTipoContrato: number): void {
    let nTipoContratoStr: string;
    if (isUndefinedOrNull(nTipoContrato)) {
      nTipoContratoStr = '';
    } else {
      nTipoContratoStr = String(nTipoContrato);
    }

    this.model.nTipoContrato = nTipoContratoStr;
  }

  public faturacaoNoMesChanged(value: EMonth): void {
    if (value > 0 && value > NUMBER_OF_MONTHS) {
      value = <EMonth>NUMBER_OF_MONTHS;
    } else if (value < 0 && value < NUMBER_OF_MONTHS_NEGATIVE) {
      value = <EMonth>NUMBER_OF_MONTHS_NEGATIVE;
    }
    this.model.faturacaoNoMes = value;
    this._evaluateInfoFaturacaoMes();
  }

  public decMes(): void {
    const month: EMonth = this.model.faturacaoNoMes - 1;
    if (month < NUMBER_OF_MONTHS_NEGATIVE) {
      return;
    }
    this.model.faturacaoNoMes = month;
    this._evaluateInfoFaturacaoMes();
  }

  public incMes(): void {
    const month: EMonth = this.model.faturacaoNoMes + 1;
    if (month > NUMBER_OF_MONTHS) {
      return;
    }
    this.model.faturacaoNoMes = month;
    this._evaluateInfoFaturacaoMes();
  }

  public applyVariable(variable: string): void {
    if (this.type === EEntityStateDetailType.DETAIL) {
      return;
    }
    if (this.model.txtContrato) {
      this.model.txtContrato += variable;
    } else {
      this.model.txtContrato = variable;
    }
  }

  private _evaluateInfoFaturacaoMes(): void {
    if (this.model.faturacaoNoMes === 0) {
      this.infoFaturacaoMes = this._translateService.instant('contratostiposervicos.infoFaturacaoMes.info');
    } else {
      let operation: 'add' | 'subtract';
      let label: string;
      if (this.model.faturacaoNoMes < 0) {
        operation = 'add';
        label = 'contratostiposervicos.infoFaturacaoMes.infoNegativeValue';
      } else {
        operation = 'subtract';
        label = 'contratostiposervicos.infoFaturacaoMes.infoPositiveValue';
      }
      const month: EMonth = Math.abs(this.model.faturacaoNoMes);
      const monthName: string = moment({month: EMonth.August})[operation](month, 'month').format('MMMM');
      this.infoFaturacaoMes = this._translateService.instant(label, {month: monthName});
    }
  }
}
