import {merge} from 'lodash-es';
import {isObservable, lastValueFrom, Subject, Subscription} from 'rxjs';
import {AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, Renderer2, SimpleChanges} from '@angular/core';
import {isDefinedNotNull, isFunction, isNumber, nodeForEach, timeout} from '../common/utilities/utilities';
import type {IPlPromisesServiceConfiguration, IPlPromisesTick, TPlPromisesEvt} from './promises.service.interface';
import {PlPromiseService} from './promises.service';

const SELECTOR_CG_FORM = 'pl-form';
const SELECTOR_CG_BTN = 'pl-button';
const SELECTOR_FORM = 'form';
const SELECTOR_BTN = 'button[type="button"], input[type="button"]';
const SELECTOR_BTN_SUBMIT = 'button[type="submit"], input[type="submit"]';

@Directive({
  selector: '[plPromise]'
})
export class PlPromisesDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input() public plPromise: void | '' | Promise<any>;
  @Input() public plPromiseProperties: Partial<IPlPromisesServiceConfiguration>;
  @Input() public click: TPlPromisesEvt;
  @Input() public submit: TPlPromisesEvt;

  private readonly _element: HTMLElement;
  private readonly _subject: Subject<IPlPromisesTick>;
  private readonly _subscriptionConfig: Subscription;
  private readonly _subscriptionTick: Subscription;
  private readonly _elementsToReEnable: Array<Element>;
  private readonly _elementTemplatePromises: WeakMap<Element, Node>;
  private readonly _elementTemplateOverlay: WeakMap<Element, Node>;
  private _viewInit: boolean;
  private _destroyed: boolean;
  private _serviceConfiguration: IPlPromisesServiceConfiguration;
  private _configuration: IPlPromisesServiceConfiguration;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _renderer2: Renderer2,
    private readonly _plPromiseService: PlPromiseService
  ) {
    this._element = this._elementRef.nativeElement;
    this._subject = new Subject<IPlPromisesTick>();
    this._subscriptionConfig = this._plPromiseService.get().subscribe((config: IPlPromisesServiceConfiguration) => {
      this._serviceConfiguration = config;
      if (this._viewInit) {
        this._changedConfiguration();
      }
    });
    this._subscriptionTick = this._subject.asObservable().subscribe((tick: IPlPromisesTick) => {
      this._tick(tick);
    });
    this._viewInit = false;
    this._destroyed = false;
    this._elementsToReEnable = [];
    this._elementTemplatePromises = new WeakMap<Element, Node>();
    this._elementTemplateOverlay = new WeakMap<Element, Node>();
  }

  public ngOnChanges({plPromise, plPromiseProperties}: SimpleChanges): void {
    if (plPromiseProperties) {
      this._changedConfiguration(plPromiseProperties.currentValue);
    }
    if (plPromise && !plPromise.isFirstChange() && plPromise.currentValue && this._viewInit) {
      this._subject.next({target: this._element, value: plPromise.currentValue});
    }
  }

  public ngAfterViewInit(): void {
    this._changedConfiguration();
    this._viewInit = true;
    if (this.plPromise) {
      this._subject.next({target: this._element, value: this.plPromise});
    }
  }

  public ngOnDestroy(): void {
    this._destroyed = true;
    this._subscriptionConfig.unsubscribe();
    this._subscriptionTick.unsubscribe();
    this._subject.complete();
  }

  @HostListener('click')
  public clickEventHandler(): void {
    if (this.click) {
      const btn: boolean = isCGBtn(this._element);
      if (btn || isBtn(this._element)) {
        let target: Element = this._element;
        if (btn) {
          target = target.querySelector(SELECTOR_BTN);
        }
        this._subject.next({target: target, value: this.click});
      }
    }
  }

  @HostListener('submit')
  public submitEventHandler(): void {
    if (this.submit) {
      const form: boolean = isCGForm(this._element);
      if (form || isForm(this._element)) {
        let target: Element = this._element;
        if (form) {
          target = target.querySelector(SELECTOR_FORM);
        }
        this._subject.next({target: target, value: this.submit});
      }
    }
  }

  private _changedConfiguration(value: Partial<IPlPromisesServiceConfiguration> = this.plPromiseProperties): void {
    this._configuration = merge({}, this._serviceConfiguration, this._configuration, value);
  }

  private _tick(tick: IPlPromisesTick): void {
    let tickValue: TPlPromisesEvt = tick.value;
    if (tickValue) {
      const promises: Array<Promise<any>> = [];

      if (isFunction(tickValue)) {
        tickValue = tickValue();
      }
      if (isObservable(tickValue)) {
        tickValue = lastValueFrom(tickValue);
      }

      // Start loading
      this._startLoading(tick.target);
      promises.push(Promise.resolve(tickValue));

      // Create minimum duration timeout if option is set
      if (isNumber(this._configuration.minDuration) && this._configuration.minDuration > 0) {
        promises.push(timeout(this._configuration.minDuration));
      }

      Promise.all(promises).finally(() => {
        if (!this._destroyed) {
          this._endLoading(tick.target);
        }
      });
    }
  }

  private _startLoading(target: Element): void {
    if (!target) {
      return;
    }
    // Check if it's a button
    if (isBtn(target)) {
      this._startLoadingBtn(target);
      return;
    }

    // Check if it's a form
    if (!this._configuration.addClassToTargetOnly && isForm(target)) {
      this._startLoadingForm(target);
      return;
    }

    // All other elements
    this._appendTemplate(target);
    if (this._configuration.loadingClass) {
      this._renderer2.addClass(target, this._configuration.loadingClass);
    }
    if (this._configuration.dim && this._configuration.dimClass) {
      this._renderer2.addClass(target, this._configuration.dimClass);
    }
  }

  private _endLoading(target: Element): void {
    if (!target) {
      return;
    }
    // Check if it's a button
    if (isBtn(target)) {
      this._endLoadingBtn(target);
      return;
    }

    // Check if it's a form
    if (!this._configuration.addClassToTargetOnly && isForm(target)) {
      this._endLoadingForm(target);
      return;
    }

    // All other elements
    this._renderer2.removeClass(target, this._configuration.loadingClass);
    if (this._configuration.dimClass) {
      this._renderer2.removeClass(target, this._configuration.dimClass);
    }
  }

  private _startLoadingBtn(target: HTMLButtonElement | HTMLInputElement, disableOnly: boolean = false): void {
    if (!disableOnly) {
      this._appendTemplate(target);
      if (this._configuration.loadingClass) {
        this._renderer2.addClass(target, this._configuration.loadingClass);
      }
    }
    if (this._configuration.disableBtn && !target.disabled) {
      if (!this._elementsToReEnable.includes(target)) {
        this._elementsToReEnable.push(target);
      }
      target.disabled = true;
    }
  }

  private _startLoadingForm(target: HTMLFormElement): void {
    const submitElement: HTMLButtonElement | HTMLInputElement = target.querySelector<HTMLButtonElement | HTMLInputElement>(SELECTOR_BTN_SUBMIT);
    const disableOnly: boolean = isDefinedNotNull(submitElement);
    if (disableOnly) {
      this._startLoadingBtn(submitElement);
    }
    const targetElements: NodeListOf<HTMLButtonElement | HTMLInputElement> = target.querySelectorAll(SELECTOR_BTN);
    if (targetElements.length) {
      nodeForEach(targetElements, (targetElement: HTMLButtonElement | HTMLInputElement) => {
        this._startLoadingBtn(targetElement, disableOnly);
      });
    }
  }

  private _endLoadingBtn(target: HTMLButtonElement | HTMLInputElement, enableOnly: boolean = false): void {
    if (!enableOnly && this._configuration.loadingClass) {
      this._renderer2.removeClass(target, this._configuration.loadingClass);
    }
    if (target.disabled) {
      const elementToReEnableIndex: number = this._elementsToReEnable.indexOf(target);
      if (elementToReEnableIndex > -1) {
        target.disabled = false;
        this._elementsToReEnable.splice(elementToReEnableIndex, 1);
      }
    }
  }

  private _endLoadingForm(target: HTMLFormElement): void {
    const submitElement: HTMLButtonElement | HTMLInputElement = target.querySelector<HTMLButtonElement | HTMLInputElement>(SELECTOR_BTN_SUBMIT);
    const enableOnly: boolean = isDefinedNotNull(submitElement);
    if (enableOnly) {
      this._endLoadingBtn(submitElement);
    }
    const targetElements: NodeListOf<HTMLButtonElement | HTMLInputElement> = target.querySelectorAll(SELECTOR_BTN);
    if (targetElements.length) {
      nodeForEach(targetElements, (targetElement: HTMLButtonElement | HTMLInputElement) => {
        this._endLoadingBtn(targetElement, enableOnly);
      });
    }
  }

  private _appendTemplate(target: Element): void {
    if (this._configuration.templates.promises && !this._elementTemplatePromises.has(target)) {
      const templatePromises: Node = this._configuration.templates.promises.cloneNode(true);
      this._elementTemplatePromises.set(target, templatePromises);
      this._renderer2.appendChild(target, templatePromises);
    }
    if (this._configuration.overlay && this._configuration.templates.overlay && !this._elementTemplateOverlay.has(target)) {
      const templateOverlay: Node = this._configuration.templates.overlay.cloneNode(true);
      this._elementTemplateOverlay.set(target, templateOverlay);
      this._renderer2.appendChild(target, templateOverlay);
    }
  }
}

function isCGBtn(target: Element): boolean {
  return target.matches(SELECTOR_CG_BTN);
}

function isCGForm(target: Element): boolean {
  return target.matches(SELECTOR_CG_FORM);
}

function isBtn(target: Element): target is HTMLButtonElement | HTMLInputElement {
  return target.matches(SELECTOR_BTN);
}

function isForm(target: Element): target is HTMLFormElement {
  return target.matches(SELECTOR_FORM);
}
