import {Subscription, timer} from 'rxjs';
import {Component, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModuloComponent} from '../../../components/module/module.component';
import {IPlDynamicVisualsSecondaryClickAction, IPlEditComponentOptionsInputAutocomplete, IPlToolbarItem, isObject, PlEditAutocompleteComponent} from 'pl-comps-angular';
import {DebDiretosSEPAModuleService} from '../debDiretosSEPA.module.service';
import {DebDiretosSEPAConfiguracaoModalComponent} from '../modals/debDiretosSEPA.configuracao.modal.component';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {
  EDebDiretosSEPAFicheiroEmitidosType,
  EDebDiretosSEPAOpenType,
  IDebDiretosSEPAAcessos,
  IJsonDebDiretosSEPAAcesso,
  IJsonDebDiretosSEPAEmissaoListaAIntervirItem,
  IJsonDebDiretosSEPAErroLiquidacaoRecord,
  IJsonDebDiretosSEPAFicheirosEmitidosRecord,
  IJsonDebDiretosSEPAFicheirosEmitidosRecordUI,
  IJsonDebDiretosSEPARecord
} from '../debDiretosSEPA.module.interface';
import {DebDiretosSEPAClientesModalComponent} from '../modals/debDiretosSEPA.clientes.modal.component';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import {
  IDevExpressDataGridEventOnContextMenuPreparing,
  IDevExpressDataGridEventOnFocusedRowChanged,
  IDevExpressDataGridEventOnInitialized
} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import dxDataGrid from 'devextreme/ui/data_grid';
import {EGrupoDoc} from '../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {EEntityMaintenanceEditMode, IEntityMaintenanceInstance} from '../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {IJsonDocComercial} from '../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {docsComerciaisGrupoDocToEntityName} from '../../../entities/docscomerciais/docsComerciais.entity.interface';
import {EntityMaintenanceService} from '../../../components/entity/maintenance/entity/entity.maintenance.service';
import {ENTITY_NAME_CLIENTES} from '../../../entities/clifos/clifos.entity.interface';
import {DebDiretosSEPALogModalComponent} from '../modals/debDiretosSEPA.log.modal.component';
import {DebDiretosSEPAEmissaoModalComponent} from '../modals/debDiretosSEPA.emissao.modal.component';
import {DebDiretosSEPAImpressaoModalComponent} from '../modals/debDiretosSEPA.impressao.modal.component';
import {DebDiretosSEPAImportadorModalComponent} from '../modals/debDiretosSEPA.importador.modal.component';
import moment from 'moment';
import DataSource from 'devextreme/data/data_source';
import {IJsonBankAccount} from '../../../interfaces/jsonBankAccount.interface';
import {DebDiretosSEPAPorNotificarModalComponent} from '../modals/debDiretosSEPA.porNotificar.modal.component';
import {ENTITY_NAME_RECIBOS} from '../../../entities/recibos/recibos.entity.interface';
import {IJsonRecibo} from '../../../entities/recibos/jsonRecibo.entity.interface';
import {HttpResponse} from '@angular/common/http';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {DebDiretosSEPAReciboModalComponent} from '../modals/debDiretosSEPA.recibo.modal.component';
import {DevExpressDataGridUIService} from '../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';

const TIMEOUT_AC_SEARCH = 400;
const TOOLBAR_GROUP_RESPONSIVE = 'module-btns-responsive';

@Component({
  selector: 'ddsepa',
  templateUrl: './debDiretosSEPA.module.component.html'
})
export class DebDiretosSEPAModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public acessoContabilidade: boolean;
  @ViewChild('ficheirosEmitidosAc') public readonly ficheirosEmitidosAc: PlEditAutocompleteComponent;

  public readonly debDiretosSEPAOpenType: typeof EDebDiretosSEPAOpenType;
  public readonly rowTemplateFicheirosEmitidos: string = '{{loteFicheiro}}';
  public readonly outputFicheirosEmitidos: string = 'loteFicheiro';
  public readonly tabPorLiquidarId: string = 'porLiquidarTab';
  public readonly tabLiquidadoId: string = 'liquidadoTab';
  public readonly tabErrosLiquidacaoId: string = 'errosLiquidacaoTab';
  public readonly dataGridFicheirosEmitidosInstanceName: string = 'dataGridFicheirosEmitidosInstName';
  public readonly propertiesAutocompleteFicheirosEmitidos: IPlEditComponentOptionsInputAutocomplete;

  public modelFicheirosEmitidos: IJsonDebDiretosSEPAFicheirosEmitidosRecordUI;
  public dataGridDefinition: IDevExpressDataGrid<IJsonDebDiretosSEPARecord, number>;
  public dataGridErroLiquidacaoDefinition: IDevExpressDataGrid<IJsonDebDiretosSEPAErroLiquidacaoRecord, number>;
  public dataGridFicheirosEmitidos: IDevExpressDataGrid<IJsonDebDiretosSEPAFicheirosEmitidosRecord, number>;
  public activeTabId: string;
  public docListEmissao: Array<IJsonDebDiretosSEPAEmissaoListaAIntervirItem>;
  public errosLiquidacaoCount: string;

  private readonly _btnEmissao: IPlToolbarItem;
  private readonly _btnImportar: IPlToolbarItem;
  private readonly _btnClientesAderentes: IPlToolbarItem;
  private readonly _btnImprimir: IPlToolbarItem;
  private readonly _userAccess: IDebDiretosSEPAAcessos;
  private readonly _serviceRecibos: IEntityService<IJsonRecibo>;

  private _tableSource: Array<IJsonDebDiretosSEPARecord>;
  private _isFirstLoad: boolean;
  private _dataGridInstance: dxDataGrid<IJsonDebDiretosSEPARecord, number>;
  private _dataGridErrosLiquidacaoInstance: dxDataGrid<IJsonDebDiretosSEPAErroLiquidacaoRecord, number>;
  private _dataGridFicheirosEmitidosInstance: dxDataGrid<IJsonDebDiretosSEPAFicheirosEmitidosRecord, number>;
  private _maintenanceDocsComerciais: Map<EGrupoDoc, IEntityMaintenanceInstance<IJsonDocComercial>>;
  private _maintenanceClifos: IEntityMaintenanceInstance;
  private _errosLiquidacaoList: Array<IJsonDebDiretosSEPAErroLiquidacaoRecord>;
  private _autocompleteSearchTimer: Subscription;

  constructor(
    protected readonly _injector: Injector,
    private readonly _debDiretosSEPAModuleService: DebDiretosSEPAModuleService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService,
    private readonly _cgModalService: CGModalService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.activeTabId = this.tabPorLiquidarId;
    this.debDiretosSEPAOpenType = EDebDiretosSEPAOpenType;
    this.docListEmissao = [];
    this._errosLiquidacaoList = [];
    this.errosLiquidacaoCount = '0';
    this._userAccess = {
      acessoContabilidade: false,
      bancos: false,
      clientes: false,
      geral: false,
      tiposDocumento: false
    };
    this._btnEmissao = {
      id: 'emissao',
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      order: this.btnRefresh.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-cloud-upload"></i>',
      class: 'btn-primary',
      caption: 'debDiretosSEPA.buttons.toolbarEmissao',
      visible: true,
      click: () => this._showModalEmissao()
    };
    this._btnImportar = {
      id: 'importar',
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      order: this._btnEmissao.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-cloud-download"></i>',
      class: 'btn-primary',
      caption: 'debDiretosSEPA.buttons.toolbarImportar',
      visible: true,
      click: () => this._showModalImportacao()
    };
    this._btnClientesAderentes = {
      id: 'clientesAderentes',
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      order: this._btnImportar.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-user"></i>',
      caption: 'debDiretosSEPA.buttons.clientesAderentes',
      click: () => this._clientesAderentesModal()
    };
    this._btnImprimir = {
      id: 'imprimir',
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      order: this._btnClientesAderentes.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-print"></i>',
      caption: 'debDiretosSEPA.buttons.imprimir',
      click: () => this._showPrintModal()
    };
    this._tableSource = [];
    this._isFirstLoad = true;
    this._resetModel();
    this.dataGridDefinition = {
      columns: [
        {dataField: 'nDocAsStringSAFT', dataType: 'string', caption: 'debDiretosSEPA.fields.docFaturacao'},
        {dataField: 'nReciboSAFTAsSTR', dataType: 'string', caption: 'debDiretosSEPA.fields.recibo', visible: false, showInColumnChooser: false},
        {dataField: 'dataDoc', dataType: 'date', caption: 'debDiretosSEPA.fields.dataDoc'},
        {dataField: 'nConta', dataType: 'string', caption: 'debDiretosSEPA.fields.nConta'},
        {dataField: 'nContaNome', dataType: 'string', caption: 'debDiretosSEPA.fields.nome'},
        {dataField: 'valorDD', dataType: 'double', caption: 'debDiretosSEPA.fields.valorDD'},
        {dataField: 'enviadoEmail', dataType: 'boolean', caption: 'debDiretosSEPA.fields.notificado'},
        {
          dataField: 'vf_actions',
          width: 50,
          fixed: true,
          fixedPosition: 'right',
          caption: ' ',
          cellTemplate: 'cellTemplateActions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false
        }
      ],
      dataSource: new CustomStore({
        key: 'faccbId',
        load: () => this._dataGridTableSource()
      })
    };
    this.dataGridErroLiquidacaoDefinition = {
      columns: [
        {dataField: 'nLoteFicheiro', dataType: 'string', caption: 'debDiretosSEPA.fields.nLoteFicheiro', groupIndex: 0},
        {dataField: 'nDocAsStringSAFT', dataType: 'string', caption: 'debDiretosSEPA.fields.docFaturacao'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'debDiretosSEPA.fields.dataDoc'},
        {dataField: 'nConta', dataType: 'string', caption: 'debDiretosSEPA.fields.nConta'},
        {dataField: 'nContaNome', dataType: 'string', caption: 'debDiretosSEPA.fields.nome'},
        {dataField: 'valorDD', dataType: 'double', caption: 'debDiretosSEPA.fields.valorDD'},
        {dataField: 'enviadoEmail', dataType: 'boolean', caption: 'debDiretosSEPA.fields.notificado'},
        {dataField: 'erro', cssClass: 'text-danger', dataType: 'string', caption: 'debDiretosSEPA.strings.erroLiquidacao'},
        {
          dataField: 'vf_actions',
          fixed: true,
          fixedPosition: 'right',
          caption: ' ',
          cellTemplate: 'cellTemplateActions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false
        }
      ],
      paging: {
        pageSize: 25
      },
      dataSource: new CustomStore({
        key: 'faccbId',
        load: () => {
          return this._errosLiquidacaoList;
        }
      })
    };

    this.dataGridFicheirosEmitidos = {
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      filterPanel: {visible: false},
      focusedRowEnabled: true,
      grouping: {contextMenuEnabled: false},
      groupPanel: {visible: false},
      headerFilter: {visible: false},
      height: 400,
      paging: {
        pageSize: 10
      },
      scrolling: {rowRenderingMode: 'virtual'},
      filterRow: {visible: false},
      toolbar: {visible: false},
      columns: [
        {dataField: 'loteFicheiro', dataType: 'string', caption: 'debDiretosSEPA.fields.nLoteFicheiro'},
        {dataField: 'nDocumentos', dataType: 'number', caption: 'debDiretosSEPA.strings.nDocumentos'},
        {dataField: 'valor', dataType: 'double', caption: 'debDiretosSEPA.fields.valor'},
        {dataField: 'dataEmissao', dataType: 'date', caption: 'debDiretosSEPA.strings.dataEmissao'},
        {dataField: 'dataLiquidacao', dataType: 'date', caption: 'debDiretosSEPA.fields.dataLiquidacao'},
        {dataField: 'enviadoEmail', dataType: 'boolean', caption: 'debDiretosSEPA.fields.notificado'}
      ],
      dataSource: new DataSource({
        store: new CustomStore({
          key: 'loteFicheiro',
          loadMode: 'processed',
          load: () => {
            return this._debDiretosSEPAModuleService
              .getFicheirosEmitidos(
                this.modelFicheirosEmitidos.ficheiroEmitidosType,
                this.ficheirosEmitidosAc.search,
                this._dataGridFicheirosEmitidosInstance.pageIndex() + 1,
                this._dataGridFicheirosEmitidosInstance.pageSize()
              )
              .then((response) => {
                return response.body.list;
              });
          }
        })
      })
    };

    this.propertiesAutocompleteFicheirosEmitidos = {
      showFilter: false,
      allowEmpty: false,
      disallowClear: true,
      allowInvalid: false,
      cssClassDropdownWrapper: 'ddsepa-ficheiros-emitidos-dropdown-wrapper',
      events: {
        input: () => {
          if (this._autocompleteSearchTimer) {
            this._autocompleteSearchTimer.unsubscribe();
          }
          this._autocompleteSearchTimer = timer(TIMEOUT_AC_SEARCH).subscribe(() => {
            this._dataGridFicheirosEmitidosInstance?.refresh();
          });
        }
      }
    };

    this._serviceRecibos = this._entityServiceBuilder.build<IJsonRecibo>(ENTITY_NAME_RECIBOS);
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this._userAccess.acessoContabilidade = this.acessoContabilidade;
    this._buildToolbar();
    this._buildToolbarResponsive(this.isMobile);
    await this._refreshDocListEmissao();
    await this._refreshErrosLiquidacao();
  }

  public async ngOnDestroy(): Promise<void> {
    super.ngOnDestroy();
    if (this._autocompleteSearchTimer) {
      this._autocompleteSearchTimer.unsubscribe();
    }
    await this._debDiretosSEPAModuleService.stopJob();
  }

  public setIsMobile(value: boolean): void {
    super.setIsMobile(value);
    if (this.toolbar) {
      this._buildToolbarResponsive(value);
    }
  }

  public async ficheirosEmitidosChanged(item: IJsonDebDiretosSEPAFicheirosEmitidosRecord): Promise<void> {
    this._resetModel();
    if (isObject(item)) {
      this.modelFicheirosEmitidos.loteFicheiro = item.loteFicheiro;
      this.modelFicheirosEmitidos.dataEmissao = item.dataEmissao;
      this.modelFicheirosEmitidos.enviadoEmail = item.enviadoEmail;
      this.modelFicheirosEmitidos.valor = item.valor;
      this.modelFicheirosEmitidos.temDocsPorNotificar = !item.enviadoEmail;
    }
    await this._atualizar();
  }

  public onDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonDebDiretosSEPARecord, number>): void {
    this._dataGridInstance = component;
    this._dataGridInstance.columnOption('nReciboSAFTAsSTR', 'visible', this.activeTabId === this.tabLiquidadoId);
    this._dataGridInstance.columnOption('nReciboSAFTAsSTR', 'showInColumnChooser', this.activeTabId === this.tabLiquidadoId);
  }

  public onDataGridErroLiquidacaoInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonDebDiretosSEPAErroLiquidacaoRecord, number>): void {
    this._dataGridErrosLiquidacaoInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.columnOption('nReciboSAFTAsSTR', 'visible', this.activeTabId === this.tabLiquidadoId);
    this._dataGridInstance.columnOption('nReciboSAFTAsSTR', 'showInColumnChooser', this.activeTabId === this.tabLiquidadoId);
  }

  public onDataGridFicheirosEmitidosContentReady(): void {
    this._dataGridFicheirosEmitidosInstance.columnOption('enviadoEmail', 'visible', this.activeTabId === this.tabPorLiquidarId);
  }

  public onDataGridFicheirosEmitidosInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonDebDiretosSEPAFicheirosEmitidosRecord, number>): void {
    this._dataGridFicheirosEmitidosInstance = component;
    this._dataGridFicheirosEmitidosInstance.columnOption('enviadoEmail', 'visible', this.activeTabId === this.tabPorLiquidarId);
  }

  public async onDataGridContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IJsonDebDiretosSEPARecord, number>): Promise<void> {
    if (event.target === 'content' && event.row?.rowType === 'data' && isObject(event.row.data)) {
      event.event.preventDefault();
      const itemData: IJsonDebDiretosSEPARecord = this._tableSource.find((item: IJsonDebDiretosSEPARecord) => item.faccbId === event.row.data.faccbId);
      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActions(itemData);
      await this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions);
    }
  }

  public async onDataGridErroLiquidacaoContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IJsonDebDiretosSEPAErroLiquidacaoRecord, number>): Promise<void> {
    if (event.target === 'content' && event.row?.rowType === 'data' && isObject(event.row.data)) {
      event.event.preventDefault();
      const itemData: IJsonDebDiretosSEPAErroLiquidacaoRecord = this._errosLiquidacaoList.find((item: IJsonDebDiretosSEPAErroLiquidacaoRecord) => item.nLoteFicheiro === event.row.data.nLoteFicheiro);
      const data: IJsonDebDiretosSEPARecord = {...this._emptyDebDiretosSEPARecord(), ...itemData};
      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActionsErroLiquidacao(data);
      await this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions);
    }
  }

  public async disponivelParaNovaEmissao(data: IJsonDebDiretosSEPARecord): Promise<void> {
    await this._prompt('debDiretosSEPA.messages.temCertezaMarcarRegistoDisponiveisParaEmissao');
    await this._debDiretosSEPAModuleService.disponivelParaNovaEmissao([data.faccbId]);
    await this._atualizar(false);
    await this._dataGridErrosLiquidacaoInstance.refresh();
  }

  // eslint-disable-next-line consistent-return
  public openRecordModal(type: EDebDiretosSEPAOpenType, data: IJsonDebDiretosSEPARecord): Promise<unknown> {
    switch (type) {
      case EDebDiretosSEPAOpenType.FATURACAO:
        if (!this._maintenanceDocsComerciais) {
          this._maintenanceDocsComerciais = new Map<EGrupoDoc, IEntityMaintenanceInstance<IJsonDocComercial>>();
        }
        const grupoDocfa: EGrupoDoc = data.grupoDocfa;
        let maintenanceDocsComerciais: IEntityMaintenanceInstance<IJsonDocComercial> = this._maintenanceDocsComerciais.get(grupoDocfa);
        if (!maintenanceDocsComerciais) {
          const entityName: string = docsComerciaisGrupoDocToEntityName(grupoDocfa);
          maintenanceDocsComerciais = this._entityMaintenanceService.build<IJsonDocComercial>(entityName);
          this._maintenanceDocsComerciais.set(grupoDocfa, maintenanceDocsComerciais);
        }
        return maintenanceDocsComerciais.maintenanceEdit(data.faccbId, {mode: EEntityMaintenanceEditMode.ReadOnly});
      case EDebDiretosSEPAOpenType.RECIBO_LIQUIDACAO:
        return this._serviceRecibos.get<IJsonRecibo>({id: data.extPocCabID, params: {nconta: data.nConta}}).then((result: HttpResponse<IJsonRecibo>) => {
          const modalInstance = this._cgModalService.showVanilla(DebDiretosSEPAReciboModalComponent, {size: 'xl', backdrop: 'static'});
          const componentInstance: DebDiretosSEPAReciboModalComponent = modalInstance.componentInstance;
          componentInstance.recibo = result.body;
          return modalInstance.result;
        });
      case EDebDiretosSEPAOpenType.CLIENTE:
        if (!this._maintenanceClifos) {
          this._maintenanceClifos = this._entityMaintenanceService.build(ENTITY_NAME_CLIENTES);
        }
        return this._maintenanceClifos.maintenanceEdit(data.nConta, {mode: EEntityMaintenanceEditMode.ReadOnly});
    }
  }

  public openLogModal(data: IJsonDebDiretosSEPARecord): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DebDiretosSEPALogModalComponent, {size: 'xl', backdrop: 'static'});
    const componentInstance: DebDiretosSEPALogModalComponent = modalInstance.componentInstance;
    componentInstance.record = data;
    return modalInstance.result;
  }

  public async changedTab(id: string): Promise<void> {
    this.activeTabId = id;
    if (this.activeTabId === this.tabLiquidadoId || this.activeTabId === this.tabPorLiquidarId) {
      this.modelFicheirosEmitidos = {
        ficheiroEmitidosType: this.activeTabId === this.tabLiquidadoId ? EDebDiretosSEPAFicheiroEmitidosType.Liquidado : EDebDiretosSEPAFicheiroEmitidosType.PorLiquidar,
        loteFicheiro: '',
        dataEmissao: undefined,
        enviadoEmail: false,
        valor: undefined,
        dataLiquidacao: undefined,
        nDocumentos: undefined,
        temDocsPorNotificar: false
      };
      this._dataGridInstance.columnOption('nReciboSAFTAsSTR', 'visible', this.activeTabId === this.tabLiquidadoId);
      this._dataGridInstance.columnOption('nReciboSAFTAsSTR', 'showInColumnChooser', this.activeTabId === this.tabLiquidadoId);
      this._dataGridFicheirosEmitidosInstance.columnOption('enviadoEmail', 'visible', this.activeTabId === this.tabPorLiquidarId);

      await this._dataGridFicheirosEmitidosInstance?.refresh();
      await this._dataGridInstance.refresh();
    }
  }

  public async onDataGridFicheirosEmitidosFocusedRowChanged(event: IDevExpressDataGridEventOnFocusedRowChanged<IJsonBankAccount, string>): Promise<void> {
    if (event.row?.rowType === 'data') {
      await this.ficheirosEmitidosAc.selectItem(event.row.data);
    }
  }

  public readonly fnNotificar: () => Promise<void> = () => this._notificar();

  public readonly fnAnular: () => Promise<void> = () => this._anular();

  private _prompt(message: string): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', message, {
      size: 'md',
      backdrop: 'static',
      showCloseBtn: false,
      keyboard: false
    });
  }

  private async _atualizar(onlyGrid: boolean = true): Promise<void> {
    this._isFirstLoad = false;
    if (!onlyGrid) {
      await this._refreshDocListEmissao();
      await this._refreshErrosLiquidacao();
    }
    await this._dataGridInstance.refresh();
  }

  private async _showConfigModal(): Promise<void> {
    const userAcessosResponse: HttpResponse<IJsonDebDiretosSEPAAcesso> = await this._debDiretosSEPAModuleService.getUserAccess();
    this._userAccess.geral = userAcessosResponse.body.geral;
    this._userAccess.bancos = userAcessosResponse.body.bancos;
    this._userAccess.tiposDocumento = userAcessosResponse.body.tiposDocumento;
    const modalInstance = this._cgModalService.showVanilla(DebDiretosSEPAConfiguracaoModalComponent, {size: 'xl', backdrop: 'static'});
    const componentInstance: DebDiretosSEPAConfiguracaoModalComponent = modalInstance.componentInstance;
    componentInstance.userAcessos = this._userAccess;
    await modalInstance.result.finally(() => {
      this._refreshDocListEmissao();
    });
  }

  private async _clientesAderentesModal(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DebDiretosSEPAClientesModalComponent, {size: 'xxl', backdrop: 'static'});
    const componentInstance: DebDiretosSEPAClientesModalComponent = modalInstance.componentInstance;
    componentInstance.userAcessos = this._userAccess;
    return modalInstance.result.finally(() => {
      this._refreshDocListEmissao();
    });
  }

  private async _dataGridTableSource(): Promise<Array<IJsonDebDiretosSEPARecord>> {
    if (this._isFirstLoad || !this.modelFicheirosEmitidos.loteFicheiro?.length) {
      return Promise.resolve([]);
    }
    const response: HttpResponse<Array<IJsonDebDiretosSEPARecord>> = await this._debDiretosSEPAModuleService.getDebDiretosSEPARecords(
      this.modelFicheirosEmitidos.loteFicheiro,
      this.modelFicheirosEmitidos.ficheiroEmitidosType === EDebDiretosSEPAFicheiroEmitidosType.Liquidado
    );
    this._isFirstLoad = false;
    this._tableSource = response.body.filter((item: IJsonDebDiretosSEPARecord) => !item.liquidadoComErro);
    return this._tableSource;
  }

  private async _refreshErrosLiquidacao(): Promise<Array<IJsonDebDiretosSEPAErroLiquidacaoRecord>> {
    const response: HttpResponse<Array<IJsonDebDiretosSEPAErroLiquidacaoRecord>> = await this._debDiretosSEPAModuleService.getDebDiretosSEPAErrosLiquidacao();
    this._errosLiquidacaoList = response.body;
    this.errosLiquidacaoCount = this._errosLiquidacaoList.length.toString();
    return this._errosLiquidacaoList;
  }

  private async _showModalEmissao(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DebDiretosSEPAEmissaoModalComponent, {size: 'xxl', backdrop: 'static'});
    const componentInstance: DebDiretosSEPAEmissaoModalComponent = modalInstance.componentInstance;
    componentInstance.listaAIntervir = this.docListEmissao;
    return modalInstance.result.finally(() => {
      this._refreshDocListEmissao();
    });
  }

  private _showModalImportacao(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DebDiretosSEPAImportadorModalComponent, {size: 'xxl', backdrop: 'static'});
    return modalInstance.result;
  }

  private _showPrintModal(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DebDiretosSEPAImpressaoModalComponent, {size: 'xl', backdrop: 'static'});
    return modalInstance.result;
  }

  private _resetModel(): void {
    this.modelFicheirosEmitidos = {
      ficheiroEmitidosType: this.activeTabId === this.tabLiquidadoId ? EDebDiretosSEPAFicheiroEmitidosType.Liquidado : EDebDiretosSEPAFicheiroEmitidosType.PorLiquidar,
      loteFicheiro: '',
      dataEmissao: undefined,
      enviadoEmail: false,
      valor: undefined,
      nDocumentos: undefined,
      dataLiquidacao: undefined,
      temDocsPorNotificar: false
    };
  }

  private async _refreshDocListEmissao(): Promise<void> {
    this.docListEmissao = [];
    const response: HttpResponse<Array<IJsonDebDiretosSEPAEmissaoListaAIntervirItem>> = await this._debDiretosSEPAModuleService.getDebDiretosSEPARecordsEmissao(moment().endOf('month'));
    this.docListEmissao = response.body;
  }

  private _notificar(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DebDiretosSEPAPorNotificarModalComponent, {size: 'xl', backdrop: 'static'});
    const componentInstance: DebDiretosSEPAPorNotificarModalComponent = modalInstance.componentInstance;
    componentInstance.loteFicheiro = this.modelFicheirosEmitidos.loteFicheiro;
    return modalInstance.result;
  }

  private async _anular(): Promise<void> {
    await this._cgModalService.showOkCancel(
      'global.text.confirmation',
      this._translateService.instant('debDiretosSEPA.messages.temACertezaQuePertendeAnularFichEmitido', {loteFicheiro: this.modelFicheirosEmitidos.loteFicheiro}),
      {size: 'md'}
    );
    await this._debDiretosSEPAModuleService.anularLoteFicheiro(this.modelFicheirosEmitidos.loteFicheiro);
    this._resetModel();
    await this._dataGridInstance?.refresh();
    await this._atualizar(false);
  }

  private _buildToolbar(): void {
    this.btnRefresh.visible = this.btnConfig.visible = true;
    this.btnRefresh.order = 1;
    this.btnRefresh.click = () => this._atualizar(false);
    this.btnConfig.order = this.dropdownActions.order + 1;
    this.btnConfig.click = () => this._showConfigModal();
  }

  private _buildToolbarResponsive(isMobile: boolean): void {
    this.toolbar.removeGroupId(TOOLBAR_GROUP_RESPONSIVE, false);
    this.dropdownActions.visible = isMobile;
    if (!isMobile) {
      this._btnEmissao.class = this._btnImportar.class = this._btnClientesAderentes.class = this._btnImprimir.class = 'btn-primary';
      this.toolbar.addButton(this._btnEmissao).addButton(this._btnImportar).addButton(this._btnClientesAderentes).addButton(this._btnImprimir);
    } else {
      this.dropdownActions.menu = [this._btnEmissao, this._btnImportar, this._btnClientesAderentes, this._btnImprimir];
      this._btnEmissao.class = this._btnImportar.class = this._btnClientesAderentes.class = this._btnImprimir.class = undefined;
      this.toolbar.addButton(this.dropdownActions);
    }
  }

  private _emptyDebDiretosSEPARecord(): IJsonDebDiretosSEPARecord {
    return {
      nDocAsString: '',
      dataDoc: moment(),
      docContabilidadeAsSTR: '',
      docContabilidadeImportAsSTR: '',
      nConta: '',
      dataEmissaoFicheiro: moment(),
      dataLiquidacao: moment(),
      enviadoEmail: false,
      faccbId: 0,
      grupoDocfa: 0,
      liquidadoComErro: false,
      nContaNome: '',
      nDocAsStringSAFT: '',
      nReciboSAFTAsSTR: '',
      valorDD: 0,
      idMontante: '',
      importPeriodo: '',
      liquidado: false,
      loteFicheiro: '',
      lotePagamento: '',
      tipoDD: 0,
      xmlGerado: false,
      extPocCabID: ''
    };
  }

  private _generateSecondaryClickActionsErroLiquidacao(item: IJsonDebDiretosSEPARecord): Array<IPlDynamicVisualsSecondaryClickAction> {
    return [
      {
        caption: 'debDiretosSEPA.strings.disponivelParaNovaEmissao',
        click: () => {
          this.disponivelParaNovaEmissao(item).then(() => undefined);
        }
      },
      {
        caption: 'debDiretosSEPA.strings.documentoFaturacao',
        click: () => {
          this.openRecordModal(EDebDiretosSEPAOpenType.FATURACAO, item).then(() => undefined);
        }
      },
      {
        caption: 'debDiretosSEPA.strings.cliente',
        click: () => {
          this.openRecordModal(EDebDiretosSEPAOpenType.CLIENTE, item).then(() => undefined);
        }
      },
      {
        caption: 'debDiretosSEPA.strings.historico',
        click: () => {
          this.openLogModal(item).then(() => undefined);
        }
      }
    ];
  }

  private _generateSecondaryClickActions(item: IJsonDebDiretosSEPARecord): Array<IPlDynamicVisualsSecondaryClickAction> {
    return [
      {
        caption: 'debDiretosSEPA.strings.documentoFaturacao',
        click: () => {
          this.openRecordModal(EDebDiretosSEPAOpenType.FATURACAO, item).then(() => undefined);
        }
      },
      {
        caption: 'debDiretosSEPA.strings.reciboLiquidacao',
        disabled: !item.importPeriodo?.length,
        click: () => {
          this.openRecordModal(EDebDiretosSEPAOpenType.RECIBO_LIQUIDACAO, item).then(() => undefined);
        }
      },
      {
        caption: 'debDiretosSEPA.strings.cliente',
        click: () => {
          this.openRecordModal(EDebDiretosSEPAOpenType.CLIENTE, item).then(() => undefined);
        }
      },
      {
        caption: 'debDiretosSEPA.strings.historico',
        click: () => {
          this.openLogModal(item).then(() => undefined);
        }
      }
    ];
  }
}
