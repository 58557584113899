import type {Subscription} from 'rxjs';
import moment, {Moment, MomentInput} from 'moment';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef} from '@angular/core';
import {DATE_MAXIMUM_YEAR, DATE_MINIMUM_YEAR, EMonth, EWeekDay} from '../../common/constants';
import {
  IPlCalendarYearViewDay,
  IPlCalendarYearViewDayDetailTemplateContext,
  IPlCalendarYearViewDayMeta,
  IPlCalendarYearViewDayTemplateContext,
  IPlCalendarYearViewEvent,
  IPlCalendarYearViewEventDay,
  IPlCalendarYearViewEvtDayClick,
  IPlCalendarYearViewEvtRangeSelect,
  IPlCalendarYearViewEvtSelectionChanged,
  IPlCalendarYearViewHeader,
  IPlCalendarYearViewHeaderTemplateContext,
  IPlCalendarYearViewMonth,
  IPlCalendarYearViewMonthHeaderTemplateContext,
  IPlCalendarYearViewMonthMeta,
  IPlCalendarYearViewMonthTemplateContext,
  IPlCalendarYearViewRange,
  IPlCalendarYearViewScheduler
} from './calendar.year.view.component.interface';
import type {IPlLocale} from '../../common/locale/locales.interface';
import {
  IPlSchedulerEvtCellClick,
  IPlSchedulerEvtRangeSelect,
  IPlSchedulerEvtSelectionChanged,
  IPlSchedulerRange,
  SCHEDULER_CSS_CLASS_HOLIDAY,
  SCHEDULER_CSS_CLASS_TODAY,
  SCHEDULER_DEFAULT_RANGE,
  SCHEDULER_GRANULARITY
} from '../../scheduler/scheduler.component.interface';
import {isArray, isBoolean, isNumber, isObject} from '../../common/utilities/utilities';
import {ngClassAdd} from '../../common/utilities/angular.utilities';
import {normalizeDate} from '../../common/dates/moment.utils';
import {PlLocaleService} from '../../common/locale/locale.service';

const TODAY: Moment = normalizeDate();
const EVENTS_BY_DATE_KEY = 'DDMM';
const WEEKDAYS = 7;
const GRANULARITY = SCHEDULER_GRANULARITY;

@Component({
  selector: 'pl-calendar-year-view',
  templateUrl: './calendar.year.view.component.html'
})
export class PlCalendarYearViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public events: Array<IPlCalendarYearViewEvent<any>>;
  @Input() public viewDate: MomentInput;
  @Input() public holidays: boolean | Array<EWeekDay>;
  @Input() public holidaysDates: Array<MomentInput>;
  @Input() public allowSelectHolidays: boolean;
  @Input() public selectableDates: boolean | Array<MomentInput>;
  @Input() public activeDates: boolean | Array<MomentInput>;
  @Input() public rangeSelect: boolean;
  @Input() public rangeSelectMultiline: boolean;
  @Input() public datasetDetail: boolean;
  @Input() public datasetDetailOnSelect: boolean;
  @Input() public datasetDetailActive: boolean;
  @Input() public showTitle: boolean;
  @Input() public showLabel: boolean;
  @Input() public selectedRange: IPlCalendarYearViewRange;
  @Input() public templateTitle: TemplateRef<void>;
  @Input() public templateLabel: TemplateRef<void>;
  @Input() public templateHeader: TemplateRef<IPlCalendarYearViewHeaderTemplateContext>;
  @Input() public templateMonth: TemplateRef<IPlCalendarYearViewMonthTemplateContext<any>>;
  @Input() public templateDay: TemplateRef<IPlCalendarYearViewDayTemplateContext<any>>;
  @Input() public templateMonthHeader: TemplateRef<IPlCalendarYearViewMonthHeaderTemplateContext<any>>;
  @Input() public templateDayDetail: TemplateRef<IPlCalendarYearViewDayDetailTemplateContext<any>>;
  @Output() public readonly datasetDetailActiveChange: EventEmitter<boolean>;
  @Output() public readonly selectedRangeChange: EventEmitter<IPlCalendarYearViewRange>;
  @Output() public readonly evtBeforeViewRender: EventEmitter<IPlCalendarYearViewScheduler<any>>;
  @Output() public readonly evtDayClick: EventEmitter<IPlCalendarYearViewEvtDayClick<any>>;
  @Output() public readonly evtRangeSelect: EventEmitter<IPlCalendarYearViewEvtRangeSelect<any>>;
  @Output() public readonly evtSelectionChanged: EventEmitter<IPlCalendarYearViewEvtSelectionChanged<any>>;

  public schedulerData: IPlCalendarYearViewScheduler<unknown>;
  public schedulerRange: IPlSchedulerRange;
  public locale: IPlLocale;

  private readonly _monthItemStartIndex: Map<EMonth, number>;
  private readonly _eventsByDate: Map<string, Array<IPlCalendarYearViewEvent<unknown>>>;
  private readonly _holidaysDates: Set<string>;
  private readonly _selectableDates: Set<string>;
  private readonly _activeDates: Set<string>;
  private readonly _subscriptionLocale: Subscription;
  private _momentLocale: string;
  private _monthsNames: Array<string>;
  private _monthsNamesShort: Array<string>;
  private _dayNames: Array<string>;
  private _dayNamesShort: Array<string>;
  private _dayNamesMin: Array<string>;
  private _viewDate: Moment;
  private _holidays: Array<EWeekDay>;
  private _allDatesSelectable: boolean;
  private _allDatesActive: boolean;
  private _daysPerMonth: number;

  constructor(private readonly _plLocaleService: PlLocaleService) {
    this.datasetDetailActiveChange = new EventEmitter<boolean>();
    this.selectedRangeChange = new EventEmitter<IPlCalendarYearViewRange>();
    this.evtBeforeViewRender = new EventEmitter<IPlCalendarYearViewScheduler<any>>();
    this.evtDayClick = new EventEmitter<IPlCalendarYearViewEvtDayClick<any>>();
    this.evtRangeSelect = new EventEmitter<IPlCalendarYearViewEvtRangeSelect<any>>();
    this.evtSelectionChanged = new EventEmitter<IPlCalendarYearViewEvtSelectionChanged<any>>();
    this.schedulerData = {
      header: [],
      datasets: [],
      title: undefined,
      label: undefined
    };
    this._monthItemStartIndex = new Map<EMonth, number>();
    this._eventsByDate = new Map<string, Array<IPlCalendarYearViewEvent<unknown>>>();
    this._holidaysDates = new Set<string>();
    this._selectableDates = new Set<string>();
    this._activeDates = new Set<string>();
    this._allDatesSelectable = false;
    this._allDatesActive = false;
    let firstTime = true;
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this.locale = locale;
      this._monthsNames = moment.months();
      this._monthsNamesShort = moment.monthsShort();
      this._dayNames = moment.weekdays(true);
      this._dayNamesShort = moment.weekdaysShort(true);
      this._dayNamesMin = moment.weekdaysMin(true);
      if (firstTime) {
        firstTime = false;
        return;
      }
      const momentLocale = moment.locale();
      if (this._momentLocale !== momentLocale) {
        this._momentLocale = momentLocale;
        this._refreshSchedulerData();
      }
    });
  }

  public ngOnInit(): void {
    this._handleChanges();
    this._refreshSchedulerData();
  }

  public ngOnChanges({events, viewDate, holidays, holidaysDates, allowSelectHolidays, selectableDates, activeDates, rangeSelect, rangeSelectMultiline, selectedRange}: SimpleChanges): void {
    const changedEvents: boolean = events && !events.isFirstChange();
    const changedViewDate: boolean = viewDate && !viewDate.isFirstChange() && !moment(viewDate.currentValue).isSame(this._viewDate, 'year');
    const changedHolidays: boolean = holidays && !holidays.isFirstChange();
    const changedHolidaysDates: boolean = holidaysDates && !holidaysDates.isFirstChange();
    const changedAllowSelectHolidays: boolean = allowSelectHolidays && !allowSelectHolidays.isFirstChange();
    const changedSelectableDates: boolean = selectableDates && !selectableDates.isFirstChange();
    const changedActiveDates: boolean = activeDates && !activeDates.isFirstChange();
    const changedRangeSelect: boolean = rangeSelect && !rangeSelect.isFirstChange();
    if (changedEvents || changedViewDate || changedHolidays || changedHolidaysDates || changedAllowSelectHolidays || changedSelectableDates || changedActiveDates || changedRangeSelect) {
      if (changedEvents || changedViewDate) {
        this._monthItemStartIndex.clear();
        this._eventsByDate.clear();
      }
      if (changedEvents) {
        this._changedEvents(events.currentValue);
      }
      let updateSets = false;
      if (changedViewDate) {
        const previousViewDate = this._viewDate.clone();
        this._changedViewDate(viewDate.currentValue);
        updateSets = !this._viewDate.isSame(previousViewDate, 'year');
      }
      if (changedHolidays) {
        this._changedHolidays(holidays.currentValue);
      }
      if (updateSets) {
        this._changedHolidaysDates();
        this._changedAllowSelectHolidays();
        this._changedSelectableDates();
        this._changedActiveDates();
      } else {
        if (changedHolidaysDates) {
          this._changedHolidaysDates(holidaysDates.currentValue);
        }
        if (changedAllowSelectHolidays) {
          this._changedAllowSelectHolidays(allowSelectHolidays.currentValue);
        }
        if (changedSelectableDates) {
          this._changedSelectableDates(selectableDates.currentValue);
        }
        if (changedActiveDates) {
          this._changedActiveDates(activeDates.currentValue);
        }
      }
      if (changedRangeSelect) {
        this._changedRangeSelect(rangeSelect.currentValue);
      }
      this._refreshSchedulerData();
    }
    if (rangeSelectMultiline && !rangeSelectMultiline.isFirstChange()) {
      this._changedRangeSelectMultiline(rangeSelectMultiline.currentValue);
    }
    if (selectedRange && !selectedRange.isFirstChange()) {
      this._changedSelectedRange(selectedRange.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionLocale.unsubscribe();
  }

  public onCellClick(event: IPlSchedulerEvtCellClick<IPlCalendarYearViewMonthMeta, IPlCalendarYearViewDayMeta<unknown>>): void {
    const datasetItem: IPlCalendarYearViewDay<unknown> = event.datasetItem;
    const dateKey: string = moment(datasetItem.date).format(EVENTS_BY_DATE_KEY);
    if (!this.allowSelectHolidays && (this._holidays.includes(moment(datasetItem.date).day()) || this._holidaysDates.has(dateKey))) {
      event.preventDefault();
      return;
    }
    const day: IPlCalendarYearViewEvtDayClick<unknown> = {
      ...this._datasetItemToDay(datasetItem),
      preventDefault: event.preventDefault,
      preventDetail: event.preventDetail
    };
    this.evtDayClick.emit(day);
  }

  public onRangeSelect(event: IPlSchedulerEvtRangeSelect<IPlCalendarYearViewDayMeta<unknown>>): void {
    const {start, end}: IPlCalendarYearViewRange = this._schedulerRangeToYearRange(event);
    if (!start || !end) {
      return;
    }
    if (!this.allowSelectHolidays && (this._holidays.length || this._holidaysDates.size)) {
      const setRange: IPlSchedulerRange = {
        datasetStart: event.datasetStart,
        datasetEnd: event.datasetEnd,
        datasetItemStart: event.datasetItemStart,
        datasetItemEnd: event.datasetItemEnd
      };

      // Exclude holidays from range start
      let startKey: string = start.format(EVENTS_BY_DATE_KEY);
      while (this._holidays.includes(start.day()) || this._holidaysDates.has(startKey)) {
        start.add(1, 'day');
        if (start.isSameOrAfter(end, GRANULARITY)) {
          return;
        }
        startKey = start.format(EVENTS_BY_DATE_KEY);
        setRange.datasetItemStart++;
      }

      // Exclude holidays from range end
      let endKey: string = end.format(EVENTS_BY_DATE_KEY);
      while (this._holidays.includes(end.day()) || this._holidaysDates.has(endKey)) {
        end.subtract(1, 'day');
        if (end.isSameOrBefore(start, GRANULARITY)) {
          return;
        }
        endKey = end.format(EVENTS_BY_DATE_KEY);
        setRange.datasetItemEnd--;
      }

      event.setRange = setRange;

      event.datasetItems = event.datasetItems.filter((day: IPlCalendarYearViewDay<unknown>) => {
        return moment(day.date).isBetween(start, end, GRANULARITY, '[]') && !day.meta.holiday;
      });
    }
    const days: Array<IPlCalendarYearViewEventDay<unknown>> = event.datasetItems.map<IPlCalendarYearViewEventDay<unknown>>((datasetItem: IPlCalendarYearViewDay<unknown>) => {
      return this._datasetItemToDay(datasetItem);
    });
    const events: Array<IPlCalendarYearViewEvent<unknown>> = this._getEventsByDate(start, end);
    this.evtRangeSelect.emit({
      start: start,
      end: end,
      days: days,
      events: events,
      preventDefault: event.preventDefault,
      preventDetail: event.preventDetail
    });
  }

  public onDatasetDetailActiveChange(value: boolean): void {
    this.datasetDetailActive = value;
    this.datasetDetailActiveChange.emit(this.datasetDetailActive);
  }

  public onSelectedRangeChange(event: IPlSchedulerRange): void {
    this.schedulerRange = event;
    this.selectedRange = this._schedulerRangeToYearRange(event);
    this.selectedRangeChange.emit(this.selectedRange);
  }

  public onSelectionChanged(event: IPlSchedulerEvtSelectionChanged<IPlCalendarYearViewMonthMeta, IPlCalendarYearViewDayMeta<unknown>>): void {
    const range: IPlCalendarYearViewRange = this._schedulerRangeToYearRange(event);
    const events: Array<IPlCalendarYearViewEvent<unknown>> = [];
    for (const datasetItem of event.datasetsItems) {
      const dateEvents: Array<IPlCalendarYearViewEvent<unknown>> = this._eventsByDate.get(moment(datasetItem.date).format(EVENTS_BY_DATE_KEY));
      if (dateEvents) {
        events.push(...dateEvents);
      }
    }
    this.evtSelectionChanged.emit({
      ...range,
      months: event.datasets,
      month: event.dataset,
      days: event.datasetsItems,
      day: event.datasetItem,
      events: events
    });
  }

  private _handleChanges(): void {
    this._changedEvents();
    this._changedViewDate();
    this._changedHolidays();
    this._changedHolidaysDates();
    this._changedAllowSelectHolidays();
    this._changedSelectableDates();
    this._changedActiveDates();
    this._changedRangeSelect();
    this._changedRangeSelectMultiline();
    this._changedSelectedRange();
  }

  private _changedEvents(value: Array<IPlCalendarYearViewEvent<unknown>> = this.events): void {
    this.events = isArray(value) ? value : [];
  }

  private _changedViewDate(value: MomentInput = this.viewDate): void {
    let val: Moment = moment(value);
    if (!val.isValid()) {
      val = TODAY.clone();
    }
    if (val.year() < DATE_MINIMUM_YEAR) {
      val.year(DATE_MINIMUM_YEAR);
    } else if (val.year() > DATE_MAXIMUM_YEAR) {
      val.year(DATE_MAXIMUM_YEAR);
    }
    this._viewDate = val;
    // Calculate maximum days of year
    this._daysPerMonth = 0;
    for (let i = 0; i < this._monthsNames.length; i++) {
      const context: Moment = this._viewDate.clone().month(i).startOf('month');
      const monthFirstDay: number = 1 - context.weekday();
      const monthLastDay: number = context.clone().endOf('month').date();
      const daysInMonth: number = monthLastDay - monthFirstDay + 1;
      if (daysInMonth > this._daysPerMonth) {
        this._daysPerMonth = daysInMonth;
      }
    }
  }

  private _changedHolidays(value: boolean | Array<EWeekDay> = this.holidays): void {
    this._holidays = isArray(value)
      ? value.filter((holiday: EWeekDay) => isNumber(holiday) && holiday >= EWeekDay.Sunday && holiday <= EWeekDay.Saturday)
      : value !== false
      ? [EWeekDay.Sunday, EWeekDay.Saturday]
      : [];
  }

  private _changedHolidaysDates(value: Array<MomentInput> = this.holidaysDates): void {
    this._holidaysDates.clear();
    this._datesToSet(value, this._holidaysDates);
  }

  private _changedAllowSelectHolidays(value: boolean = this.allowSelectHolidays): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = false;
    }
    this.allowSelectHolidays = val;
  }

  private _changedSelectableDates(value: boolean | Array<MomentInput> = this.selectableDates): void {
    this._selectableDates.clear();
    if (!isBoolean(value)) {
      this._allDatesSelectable = false;
      this._datesToSet(value, this._selectableDates);
    } else {
      this._allDatesSelectable = value;
    }
  }

  private _changedActiveDates(value: boolean | Array<MomentInput> = this.activeDates): void {
    this._activeDates.clear();
    if (!isBoolean(value)) {
      this._allDatesActive = false;
      this._datesToSet(value, this._activeDates);
    } else {
      this._allDatesActive = value;
    }
  }

  private _changedRangeSelect(value: boolean = this.rangeSelect): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = true;
    }
    this.rangeSelect = val;
  }

  private _changedRangeSelectMultiline(value: boolean = this.rangeSelectMultiline): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = true;
    }
    this.rangeSelectMultiline = val;
  }

  private _changedSelectedRange(value: IPlCalendarYearViewRange = this.selectedRange): void {
    this.selectedRange = isObject(value) ? value : undefined;
    this.schedulerRange = this._yearRangeToSchedulerRange(this.selectedRange);
  }

  private _datesToSet(dates: Array<MomentInput>, set: Set<string>): void {
    if (isArray(dates)) {
      for (const input of dates) {
        const date: Moment = moment(input);
        if (date.isValid() && date.isSame(this._viewDate, 'year')) {
          const dateKey: string = date.format(EVENTS_BY_DATE_KEY);
          set.add(dateKey);
        }
      }
    }
  }

  private _refreshSchedulerData(): void {
    this.schedulerData = {
      header: this._evaluateHeader(),
      datasets: this._evaluateDatasets(),
      title: this._evaluateTitle(),
      label: this.locale.text.months
    };
    this.evtBeforeViewRender.emit(this.schedulerData);
  }

  private _evaluateHeader(): Array<IPlCalendarYearViewHeader> {
    const header: Array<IPlCalendarYearViewHeader> = [];
    const maximumWeekdays = WEEKDAYS - 1;
    let weekday = 0;
    let week = 0;
    for (let i = 0; i < this._daysPerMonth; i++) {
      const weekdayName: string = this._dayNames[weekday];
      header.push({
        label: weekdayName,
        meta: {
          weekday: weekday,
          weekdayName: weekdayName,
          weekdayNameShort: this._dayNamesShort[weekday],
          weekdayNameMin: this._dayNamesMin[weekday],
          week: week
        }
      });
      if (weekday < maximumWeekdays) {
        weekday++;
      } else {
        weekday = 0;
        week++;
      }
    }
    return header;
  }

  private _evaluateDatasets(): Array<IPlCalendarYearViewMonth<unknown>> {
    for (const event of this.events) {
      const date: Moment = moment(event.date);
      if (!date.isSame(this._viewDate, 'year')) {
        continue;
      }
      const eventKey: string = date.format(EVENTS_BY_DATE_KEY);
      let events: Array<IPlCalendarYearViewEvent<unknown>> = this._eventsByDate.get(eventKey);
      if (!events) {
        events = [];
        this._eventsByDate.set(eventKey, events);
      }
      events.push(event);
    }
    return this._monthsNames.map<IPlCalendarYearViewMonth<unknown>>((monthName: string, month: EMonth) => {
      const context: Moment = this._viewDate.clone().month(month);
      const datasetItems: Array<IPlCalendarYearViewDay<unknown>> = [];
      const dataset: IPlCalendarYearViewMonth<unknown> = {
        items: datasetItems,
        label: context.format('MM[ - ]MMMM'),
        meta: {
          month: month,
          monthName: monthName,
          monthNameShort: this._monthsNamesShort[month]
        }
      };
      const monthFirstDay: number = 1 - context.clone().startOf('month').weekday();
      const monthLastDay: number = context.clone().endOf('month').date();
      this._monthItemStartIndex.set(month, monthFirstDay);
      const days: number = monthFirstDay < 1 ? this._daysPerMonth - Math.abs(monthFirstDay) - 1 : this._daysPerMonth;
      for (let day = monthFirstDay; day <= days; day++) {
        const datasetItem: IPlCalendarYearViewDay<unknown> = {
          date: undefined,
          label: '',
          cssClass: [],
          selectable: false
        };
        let active = false;
        let holiday = false;
        let today = false;
        if (day > 0 && day <= monthLastDay) {
          datasetItem.date = context.clone().date(day);
          datasetItem.label = String(day);
          const dateKey: string = datasetItem.date.format(EVENTS_BY_DATE_KEY);
          const events: Array<IPlCalendarYearViewEvent<unknown>> = this._eventsByDate.get(dateKey) || [];
          const hasEvents: boolean = events.length > 0;
          active = hasEvents || this._allDatesActive || this._activeDates.has(dateKey);
          datasetItem.cssClass = hasEvents ? events[0].cssClass : '';
          holiday =
            this._holidays.includes(datasetItem.date.day()) || this._holidaysDates.has(dateKey) || (hasEvents && events.findIndex((event: IPlCalendarYearViewEvent<unknown>) => event.holiday) > -1);
          today = datasetItem.date.isSame(TODAY, 'date');
          datasetItem.selectable = (active || this.rangeSelect || this._allDatesSelectable || this._selectableDates.has(dateKey)) && (!holiday || this.allowSelectHolidays);
          datasetItem.meta = {
            month: dataset.meta.month,
            monthName: dataset.meta.monthName,
            monthNameShort: dataset.meta.monthNameShort,
            events: events,
            today: today,
            holiday: holiday
          };
        }
        datasetItem.active = active;
        if (holiday) {
          datasetItem.cssClass = ngClassAdd(datasetItem.cssClass, SCHEDULER_CSS_CLASS_HOLIDAY);
        }
        if (today) {
          datasetItem.cssClass = ngClassAdd(datasetItem.cssClass, SCHEDULER_CSS_CLASS_TODAY);
        }
        datasetItems.push(datasetItem);
      }
      return dataset;
    });
  }

  private _evaluateTitle(): string {
    return this._viewDate.format('YYYY');
  }

  private _datasetItemToDay(datasetItem: IPlCalendarYearViewDay<unknown>): IPlCalendarYearViewEventDay<unknown> {
    const date: Moment = moment(datasetItem.date);
    const events: Array<IPlCalendarYearViewEvent<unknown>> = this._getEventsByDate(date);
    return {
      date: date,
      day: date.date(),
      month: datasetItem.meta.month,
      monthName: datasetItem.meta.monthName,
      monthNameShort: datasetItem.meta.monthNameShort,
      holiday: datasetItem.meta.holiday,
      events: events
    };
  }

  private _getEventsByDate(start: Moment, end?: Moment): Array<IPlCalendarYearViewEvent<unknown>> {
    if (!end || start.isSame(end, GRANULARITY)) {
      return this._eventsByDate.get(start.format(EVENTS_BY_DATE_KEY)) || [];
    }
    const events: Array<IPlCalendarYearViewEvent<unknown>> = [];
    for (let day = start.clone(); day.isSameOrBefore(end, GRANULARITY); day.add(1, 'day')) {
      const dayEvents: Array<IPlCalendarYearViewEvent<unknown>> = this._eventsByDate.get(day.format(EVENTS_BY_DATE_KEY));
      if (dayEvents?.length) {
        events.push(...dayEvents);
      }
    }
    return events;
  }

  private _schedulerRangeToYearRange(range: IPlSchedulerRange): IPlCalendarYearViewRange {
    let start: Moment;
    let end: Moment;
    if (range.datasetStart > -1 && range.datasetStart < this.schedulerData.datasets.length) {
      const month: IPlCalendarYearViewMonth<unknown> = this.schedulerData.datasets[range.datasetStart];
      if (range.datasetItemStart > -1 && range.datasetItemStart < month.items.length) {
        const day: IPlCalendarYearViewDay<unknown> = month.items[range.datasetItemStart];
        start = moment(day.date);
      }
    }
    if (range.datasetEnd > -1 && range.datasetEnd < this.schedulerData.datasets.length) {
      const month: IPlCalendarYearViewMonth<unknown> = this.schedulerData.datasets[range.datasetEnd];
      if (range.datasetItemEnd > -1 && range.datasetItemEnd < month.items.length) {
        const day: IPlCalendarYearViewDay<unknown> = month.items[range.datasetItemEnd];
        end = moment(day.date);
      }
    }
    return {start, end};
  }

  private _yearRangeToSchedulerRange(range: IPlCalendarYearViewRange): IPlSchedulerRange {
    if (!range || !moment.isMoment(range.start) || !moment.isMoment(range.end)) {
      return {...SCHEDULER_DEFAULT_RANGE};
    }
    const datasetStartIndex: number = this._monthItemStartIndex.get(range.start.month());
    const datasetEndIndex: number = this._monthItemStartIndex.get(range.end.month());
    return {
      datasetStart: range.start.month(),
      datasetEnd: range.end.month(),
      datasetItemStart: Math.max(0, Math.abs(datasetStartIndex - range.start.date())),
      datasetItemEnd: Math.max(0, Math.abs(datasetEndIndex - range.end.date()))
    };
  }
}
