import CustomStore from 'devextreme/data/custom_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {LoadOptions} from 'devextreme/data';
import {Component, Injector} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {DevExpressDatagridPaging} from '../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.paging';
import {devExpressDataGridSortToOrder} from '../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IDevExpressDataGrid, IDevExpressDataGridLoadResult} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonExtrato} from '../../../../../interfaces/jsonContabilidade.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {PortalClientAccountService} from '../../../../../services/portalclientaccount/portalclientaccount.service';
import {IApiQueryResponse} from '../../../../../services/api/api.service.interface';

const ORDER = 'dataDoc desc';

@Component({
  selector: 'extratos-conta',
  templateUrl: './extratosConta.module.component.html'
})
export class ExtratosContaComponent extends ModuloComponent {
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly dataSource: CustomStore;

  private _dataGridInstance: dxDataGrid;
  private _dataGridPaging: DevExpressDatagridPaging;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _entityServiceBuilder: EntityServiceBuilder,
    protected readonly _cgModalService: CGModalService,
    private readonly _portalClientAccountsService: PortalClientAccountService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columns: [
        {dataField: 'nome', caption: 'pca.empresagabinete.extratosconta.fields.nome'},
        {dataField: 'nomeDiario', caption: 'pca.empresagabinete.extratosconta.fields.nomeDiario'},
        {dataField: 'nDocumento', caption: 'pca.empresagabinete.extratosconta.fields.nDocumento'},
        {dataField: 'valor', alignment: 'right', dataType: 'double', caption: 'pca.empresagabinete.extratosconta.fields.valor'},
        {dataField: 'nomeDescritivo', caption: 'pca.empresagabinete.extratosconta.fields.nomeDescritivo'},
        {dataField: 'nDocExterno', caption: 'pca.empresagabinete.extratosconta.fields.nDocExterno'},
        {dataField: 'dataLancamento', dataType: 'date', caption: 'pca.empresagabinete.extratosconta.fields.dataLancamento'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'pca.empresagabinete.extratosconta.fields.dataDoc'},
        {dataField: 'valorDebito', alignment: 'right', dataType: 'double', caption: 'pca.empresagabinete.extratosconta.fields.valorDebito'},
        {dataField: 'valorCredito', alignment: 'right', dataType: 'double', caption: 'pca.empresagabinete.extratosconta.fields.valorCredito'},
        {dataField: 'saldo', alignment: 'right', dataType: 'double', caption: 'pca.empresagabinete.extratosconta.fields.saldo'}
      ],
      headerFilter: {visible: false},
      filterRow: {visible: false},
      export: {filename: 'extratos_conta'},
      remoteOperations: {
        filtering: false,
        grouping: false,
        groupPaging: false,
        paging: true,
        sorting: true,
        summary: false
      }
    };
    this.dataSource = new CustomStore({
      load: this._onLoad.bind(this)
    });
  }

  public onDataGridInitialized(event: IDevExpressDataGridEventOnInitialized<IJsonExtrato>): void {
    this._dataGridInstance = event.component;
  }

  private _onLoad(loadOptions: LoadOptions): Promise<IDevExpressDataGridLoadResult<IJsonExtrato>> {
    const order: string = loadOptions.sort ? devExpressDataGridSortToOrder(loadOptions.sort) : ORDER;
    if (!this._dataGridPaging) {
      this._dataGridPaging = new DevExpressDatagridPaging(this._dataGridInstance);
    }
    const {page, perPage} = this._dataGridPaging.paginate(loadOptions);
    return this._portalClientAccountsService.getExtratoConta('', page, perPage, order).then((response: HttpResponse<IApiQueryResponse<IJsonExtrato>>) => {
      return this._dataGridPaging.processResult(response);
    });
  }
}
