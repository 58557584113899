<div class="entity-detail-form mapaexploracao">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <pl-group>
              <label [translate]="'mapaexploracao.fields.periodoDe'"></label>
              <edit>
                <entity-autocomplete
                  entity="periodos"
                  attrName="doPeriodo"
                  [model]="filters"
                  (selectedKeyChange)="changedDePeriodo($event)"
                  [filter]="'loadAllAnos=1' + filters.dePeriodoAno"
                  (evtInputValueChanged)="filters.dePeriodoAno = ''"
                  [fieldsMap]="{nome: 'dePeriodoNome', periodo: 'dePeriodo'}"
                  plAutoFocus>
                </entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-4">
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete
                  entity="periodos"
                  attrName="atePeriodo"
                  [model]="filters"
                  (selectedKeyChange)="changedAtePeriodo($event)"
                  [filter]="'loadAllAnos=' + filters.loadAllAnos + '&loadOnlyAno=' + filters.loadOnlyAno"
                  [fieldsMap]="{nome: 'atePeriodoNome', periodo: 'atePeriodo'}">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-4 align-self-end">
            <pl-group>
              <edit>
                <button type="button" class="btn btn-sm btn-primary" name="pesquisar" id="btnPesquisar" [click]="fnPesquisar" plPromise data-focus>
                  <i class="fa fa-search fa-fw"></i>&nbsp;<span [translate]="'global.btn.search'"></span>
                </button>
              </edit>
            </pl-group>
          </div>
        </div>
      </pl-form>
    </div>
  </cg-card-panel>

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="contabMapaExploracao"
    [dataSource]="dataGridDefinition.dataSource"
    (onInitialized)="onInitialized($event)"
    (onContextMenuPreparing)="onDataGridContextMenuPreparing($event)">
    <div *dxTemplate="let cell of 'cellNContaTemplate'" class="mapaexploracao-dropdown-cell-nconta">
      <div class="item-group-dropdown ms-1" tabindex="-1" ngbDropdown container="body" [placement]="['right', 'auto']">
        {{ cell.text }}
        <pl-button klass="btn-xs btn-link" ngbDropdownToggle></pl-button>
        <div class="dropdown-menu" ngbDropdownMenu>
          <button type="button" class="dropdown-item" [translate]="'mapaexploracao.btn.dropdown.balancete'" (click)="openMaintenanceInstanceBalanceteGrid(cell.data.conta)"></button>
        </div>
      </div>
    </div>
  </dx-data-grid>
</div>
