<div [ngClass]="entityClassName">
  <entity-detail
    [entityName]="entityName"
    [service]="service"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [formOrientation]="formOrientation"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailPartialContent>
      <pl-form [properties]="{disabled: type === stateTypes.DETAIL}">
        <pl-group [orientation]="'horizontal'">
          <fieldset class="mt-3">
            <legend [translate]="'taxasretencao.tipoRendimento.title'"></legend>
            <pl-group>
              <label [translate]="'taxasretencao.tipoRendimento.fields.declaracao'"></label>
              <edit>
                <pl-edit type="tiposDeclaracao" attrName="tipoDeclaracao" [model]="model.tipoDeclaracao" (modelChange)="model.tipoDeclaracao = $event; changedTipoDeclaracao()"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'taxasretencao.tipoRendimento.fields.tipoRendimento'"></label>
              <edit>
                <entity-autocomplete
                  entity="rendis"
                  attrName="tipoRendimento"
                  [model]="model"
                  (selectedKeyChange)="model.tipRendiment = $event"
                  (evtSelectedDescriptionChanged)="model.nomeTipRendiment = $event"
                  [fieldsMap]="{nome: 'nomeTipRendiment'}"
                  [output]="'{{tipRendiment}} - {{nome}}'"
                  [filter]="filterTiposRendimento"
                  outputKey="tipRendiment">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group *ngIf="showRegTrib">
              <label [translate]="'taxasretencao.tipoRendimento.fields.regTrib'"></label>
              <edit>
                <pl-autocomplete
                  [source]="regimesTributacao"
                  attrName="regTrib"
                  [model]="regTrib"
                  (modelChange)="changedRegTrib($event)"
                  [rowTemplate]="'{{cod}} - {{descricao}}'"
                  [output]="'{{cod}} - {{descricao}}'"
                  [allowInvalid]="false"
                  [allowEmpty]="true">
                </pl-autocomplete>
              </edit>
            </pl-group>
          </fieldset>

          <pl-group class="mt-3">
            <label [translate]="'taxasretencao.fields.nomeContaRetencao'"></label>
            <edit>
              <entity-autocomplete
                entity="pocs"
                attrName="pocs"
                [model]="model"
                (selectedKeyChange)="model.nContaRetencao = $event"
                (evtSelectedDescriptionChanged)="model.nomeContaRetencao = $event"
                [fieldsMap]="{nConta: 'nContaRetencao', nome: 'nomeContaRetencao'}"
                [filter]="'registaRetencao=1'"
                [output]="'{{nConta}} - {{nome}}'"
                [properties]="{disabled: type === stateTypes.DETAIL}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <fieldset class="mt-3">
            <legend [translate]="'taxasretencao.declaracaoRetFonte.title'"></legend>
            <pl-group>
              <label [translate]="'taxasretencao.declaracaoRetFonte.fields.zona'"></label>
              <edit>
                <pl-edit type="dmrfzona" [(model)]="model.zona"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'taxasretencao.declaracaoRetFonte.fields.rubrica'"></label>
              <edit>
                <pl-edit type="dmrfrubrica" [(model)]="model.rubrica"></pl-edit>
              </edit>
            </pl-group>
          </fieldset>
        </pl-group>
      </pl-form>
    </div>
  </entity-detail>
</div>
