import {Observable, Subject, Subscription} from 'rxjs';
import {ComponentRef, Directive, Injector, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ProviderLike, StateDeclaration, Transition, UIInjector} from '@uirouter/core';
import {isArray, isObject, PlKeyboardService} from 'pl-comps-angular';
import {AuthService} from '../../../../services/auth/auth.service';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';
import {CGStateService} from '../../../state/cg.state.service';
import {CSS_CLASS_MODAL_FULLSCREEN} from '../../../../../config/constants';
import {hasAnyAuthority, hasAuthority} from '../../../../../common/utils/roles.utils';
import {IEntityMaintenanceInstance} from '../entity/entity.maintenance.interface';
import {IMaintenanceModal} from './maintenance.modal.interface';
import {MergeInjector} from '../../../../../common/injectors/mergeInjector';
import {MODULE_MAINTENANCE_IGNORED_RESOLVE_PROVIDERS} from '../module/module.maintenance.interface';
import {MODULE_NAME_BLOCKED_PLUGIN} from '../../../../modules/blockedplugin/blockedPlugin.module.interface';
import {ModuloComponent} from '../../../module/module.component';
import {TUserSession} from '../../../../services/account/jsonUserApi.interface';

@Directive()
export abstract class EntityMaintenanceModalComponent<T, S extends ModuloComponent> extends CGModalComponent<T> implements OnInit, OnDestroy, IMaintenanceModal {
  @Input() public toolbarInstanceId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public entityMaintenanceInstance: IEntityMaintenanceInstance<any>;
  @Input() public parentMaintenanceModal: IMaintenanceModal;
  @Input() public params: object;
  @Input() public preventDocumentKeyup: boolean;
  @ViewChild('templateRef', {read: ViewContainerRef}) public readonly anchor: ViewContainerRef;

  public title: string;
  public toolbarTitle: string;
  public promise: Promise<void>;

  protected readonly _plKeyboardService: PlKeyboardService;
  protected readonly _cgStateService: CGStateService;
  protected readonly _authService: AuthService;

  protected _componentInjector: Injector;
  protected _transition: Transition;
  protected _uiInjector: UIInjector;
  protected _componentRef: ComponentRef<S>;
  protected _componentRefInstance: S;
  protected _state: StateDeclaration;
  protected _preventedDocumentKeyup: boolean;

  private readonly _subjectOnReady: Subject<S>;
  private _observableOnReady: Observable<S>;
  private _subscriptionMaintenanceModeFullscreen: Subscription;

  protected constructor(protected readonly _injector: Injector) {
    super(_injector);
    this._plKeyboardService = this._injector.get<PlKeyboardService>(PlKeyboardService);
    this._cgStateService = this._injector.get<CGStateService>(CGStateService);
    this._authService = this._injector.get<AuthService>(AuthService);
    this._subjectOnReady = new Subject<S>();
    this._preventedDocumentKeyup = false;
  }

  public ngOnInit(): void {
    this._state = this.entityMaintenanceInstance.stateMaintenance;
    if (!this._state) {
      this._logger.error(`Entity with name [${this.entityMaintenanceInstance.entityName}] is invalid or is not added to the current portal menu.`);
      return;
    }
    this._authService.identity().then((session: TUserSession) => {
      if (
        (isArray(this._state.data.pluginsRoles) && !hasAnyAuthority(session, this._state.data.pluginsRoles)) ||
        (isArray(this._state.data.requiredRoles) && !hasAuthority(session, this._state.data.requiredRoles))
      ) {
        this._state = this._cgStateService.getRedirectState({stateOrName: MODULE_NAME_BLOCKED_PLUGIN});
        if (!this._state) {
          this._logger.error(`Module with name [${MODULE_NAME_BLOCKED_PLUGIN}] is invalid or is not added to the current portal menu.`);
          return;
        }
      }
      this._transition = this._cgStateService.buildTransition(this._state, this._transitionParams());
      this._componentInjector = Injector.create({parent: this._injector, providers: [{provide: Transition, useValue: this._transition}]});
      this._uiInjector = this._transition.injector();
      this.title = this.entityMaintenanceInstance.maintenanceHeader;
      this.promise = this._initModule();
      if (this.preventDocumentKeyup !== false) {
        this._preventedDocumentKeyup = true;
        this._plKeyboardService.preventDocumentKeyup();
      } else if (this.parentMaintenanceModal?.preventedDocumentKeyup()) {
        this._plKeyboardService.allowDocumentKeyup();
      }
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subjectOnReady.complete();
    if (!this.parentMaintenanceModal) {
      if (this._preventedDocumentKeyup) {
        this._plKeyboardService.allowDocumentKeyup();
      }
    } else if (this._preventedDocumentKeyup && !this.parentMaintenanceModal.preventedDocumentKeyup()) {
      this._plKeyboardService.allowDocumentKeyup();
    } else {
      this._plKeyboardService.preventDocumentKeyup();
    }
    if (this._subscriptionMaintenanceModeFullscreen) {
      this._subscriptionMaintenanceModeFullscreen.unsubscribe();
    }
  }

  public preventedDocumentKeyup(): boolean {
    return this._preventedDocumentKeyup;
  }

  public onReady(): Observable<ModuloComponent> {
    if (!this._observableOnReady) {
      this._observableOnReady = this._subjectOnReady.asObservable();
    }
    return this._observableOnReady;
  }

  public get componentRefInstance(): S {
    return this._componentRefInstance;
  }

  protected abstract _observe(): void;

  protected abstract _clearSubscription(): void;

  protected _transitionParams(): object {
    return isObject(this.params) ? {...this.params} : undefined;
  }

  protected _initModule(): Promise<void> {
    return this._initComponent()
      .then((resolvedValues: Map<string, unknown>) => {
        this._buildTemplate(resolvedValues);
      })
      .catch((reason: unknown) => {
        this._logger.error(reason);
      });
  }

  protected _initComponent(): Promise<Map<string, unknown>> {
    const resolvedValues: Map<string, unknown> = new Map<string, unknown>();
    if (!isArray(this._state.resolve)) {
      return Promise.resolve(resolvedValues);
    }
    return Promise.all(
      this._state.resolve.map<Promise<void>>((provider: ProviderLike) => {
        const token: string = provider.provide;
        return this._uiInjector.getAsync(token).then((resolvedValue: unknown) => {
          resolvedValues.set(token, resolvedValue);
        });
      })
    ).then(() => resolvedValues);
  }

  protected _buildTemplate(resolvedValues: Map<string, unknown>): void {
    this.anchor.clear();
    const componentInjector: MergeInjector = new MergeInjector(this._componentInjector, this._uiInjector);
    this._componentRef = this.anchor.createComponent<S>(this._state.data._component, {injector: componentInjector});
    this._componentRefInstance = this._componentRef.instance;
    this._componentRefInstance.setInstanceName(this.toolbarInstanceId);
    this._componentRefInstance.setMaintenanceMode(true);
    for (const [key, value] of resolvedValues.entries()) {
      if (MODULE_MAINTENANCE_IGNORED_RESOLVE_PROVIDERS.includes(key)) {
        continue;
      }
      try {
        // This assignment might error (for example, if a given property only has a getter and no setter)
        this._componentRefInstance[key] = value;
      } catch (reason: unknown) {
        this._logger.error(reason);
      }
    }
    this._subscriptionMaintenanceModeFullscreen = this._componentRefInstance.maintenanceModeFullscreen().subscribe((maintenanceModeFullscreen: boolean) => {
      const elementModalDialog: HTMLElement = this._element.closest<HTMLElement>('.modal-dialog');
      if (elementModalDialog) {
        if (maintenanceModeFullscreen) {
          this._renderer.addClass(elementModalDialog, CSS_CLASS_MODAL_FULLSCREEN);
        } else {
          this._renderer.removeClass(elementModalDialog, CSS_CLASS_MODAL_FULLSCREEN);
        }
      }
    });
    this._observe();
    setTimeout(() => {
      this._configToolbar();
      this._changeDetectorRef.detectChanges();
      this._subjectOnReady.next(this._componentRef.instance);
    });
  }

  protected _configToolbar(): void {
    this._componentRefInstance.toolTitle.visible = false;
    this._componentRefInstance.btnBack.visible = false;
    this._componentRefInstance.btnRefresh.visible = false;
    this._componentRefInstance.btnNovo.visible = false;
    this._componentRefInstance.btnEdit.visible = false;
    this._componentRefInstance.btnSave.visible = false;
    this._componentRefInstance.btnCancel.visible = false;
    this._componentRefInstance.btnDelete.visible = false;
    this._componentRefInstance.btnDuplicate.visible = false;
    this._componentRefInstance.toolSearch.visible = false;
  }
}
