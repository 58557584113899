import {Component, Injector, Input, OnInit} from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IPlToolbarItem, PlAlertService} from 'pl-comps-angular';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnSelectionChanged,
  IDevExpressDataGridEventRowDraggingOnAdd
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGridStoreChange} from '../../../../components/devexpress/datagrid/store/devexpress.datagrid.store.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {IAtivoAbate, TAtivoAbateStatus} from '../ativosAbate.module.interface';
import {AtivosAbateService} from '../ativosAbate.module.service';
import {IJsonAtivoAbate, IJsonAtivosAbate} from '../jsonAtivosAbate.module.interface';

@Component({
  selector: 'module-ativos-abate',
  templateUrl: './ativosAbate.module.component.html'
})
export class AtivosAbateModuleComponent extends ModuloComponent implements OnInit {
  @Input() public ativosabate: IJsonAtivosAbate;

  public readonly pocsOutput: string;
  public readonly dataGridDefinition: IDevExpressDataGrid<IAtivoAbate, number>;

  public selectedAtivoPorAbater: IAtivoAbate;
  public selectedAtivoAAbater: IAtivoAbate;
  public emptyAtivosPorAbaterTable: boolean;
  public emptyAtivosAAbaterTable: boolean;

  private readonly _btnRealizaAbates: IPlToolbarItem;
  private _dataGridInstancePorAbater: dxDataGrid<IAtivoAbate, number>;
  private _dataGridInstanceAAbater: dxDataGrid<IAtivoAbate, number>;
  private _dataGridStore: ArrayStore<IAtivoAbate, number>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosAbateService: AtivosAbateService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columns: [
        {dataField: 'codAtivo', dataType: 'number', caption: 'ativosabate.table.codAtivo'},
        {dataField: 'designacao', caption: 'ativosabate.table.designacao', width: 150},
        {dataField: 'refExterna', caption: 'ativosabate.table.refExterna'},
        {dataField: 'dataAquisicao', dataType: 'date', caption: 'ativosabate.table.dataAquisicao'},
        {dataField: 'valorAtivoBrutoContab', dataType: 'double', caption: 'ativosabate.table.valorAtivoBrutoContab'},
        {dataField: 'valorDepreciacoesAcumuladasContab', dataType: 'double', caption: 'ativosabate.table.valorDepreciacoesAcumuladasContab'},
        {dataField: 'status', dataType: 'string', visible: false, showInColumnChooser: false, allowSearch: false}
      ],
      export: {filename: 'global.menu.ativosabate'},
      height: '60vh',
      headerFilter: {visible: false},
      columnHidingEnabled: false,
      paging: {enabled: false},
      pager: {visible: false},
      repaintChangesOnly: true,
      remoteOperations: false,
      columnAutoWidth: true,
      selection: {
        mode: 'single'
      },
      scrolling: {rowRenderingMode: 'virtual'},
      summary: {
        totalItems: [
          {column: 'valorAtivoBrutoContab', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorDepreciacoesAcumuladasContab', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'}
        ]
      }
    };
    this.emptyAtivosAAbaterTable = true;
    this.pocsOutput = '{{nConta}} - {{nome}}';
    this._btnRealizaAbates = {
      id: 'realizarabates',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-check fa-fw"></i>',
      class: 'btn-success',
      caption: 'ativosabate.btn.realizarabates',
      disabled: true,
      click: () => this._realizarAbates()
    };
    this.onDataGridAddAbate = this.onDataGridAddAbate.bind(this);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!this.ativosabate) {
      this.ativosabate = {
        dataAbate: undefined,
        listaAtivos: [],
        ncontaAbate: '',
        nomecontaAbate: ''
      };
    }
    this.emptyAtivosPorAbaterTable = !this.ativosabate.listaAtivos.length;
    this.toolbar.addButton(this._btnRealizaAbates);
    this._initAtivosAbate();
  }

  public allRight(): void {
    this._dataGridInstancePorAbater.clearSelection();
    this._dataGridInstancePorAbater.beginCustomLoading(undefined);
    this._dataGridInstanceAAbater.beginCustomLoading(undefined);
    this._dataGridStore.load().then((porAbater: Array<IAtivoAbate>) => {
      this._dataGridStore.push(
        porAbater.map<IDevExpressDataGridStoreChange<IAtivoAbate, number>>((ativo: IAtivoAbate) => {
          return {
            type: 'update',
            key: ativo.codAtivo,
            data: {status: 'aAbater'}
          };
        })
      );
    });
  }

  public selectedRight(): void {
    if (!this.selectedAtivoPorAbater) {
      return;
    }
    this._dataGridStore.update(this.selectedAtivoPorAbater.codAtivo, {status: 'aAbater'});
    this._dataGridInstancePorAbater.clearSelection();
    this._refreshDataGrids();
  }

  public allLeft(): void {
    this._dataGridInstanceAAbater.clearSelection();
    this._dataGridInstancePorAbater.beginCustomLoading(undefined);
    this._dataGridInstanceAAbater.beginCustomLoading(undefined);
    this._dataGridStore.load().then((porAbater: Array<IAtivoAbate>) => {
      this._dataGridStore.push(
        porAbater.map<IDevExpressDataGridStoreChange<IAtivoAbate, number>>((ativo: IAtivoAbate) => {
          return {
            type: 'update',
            key: ativo.codAtivo,
            data: {status: 'porAbater'}
          };
        })
      );
    });
  }

  public selectedLeft(): void {
    if (!this.selectedAtivoAAbater) {
      return;
    }
    this._dataGridStore.update(this.selectedAtivoAAbater.codAtivo, {status: 'porAbater'});
    this._dataGridInstanceAAbater.clearSelection();
    this._refreshDataGrids();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IAtivoAbate, number>, porAbater: boolean): void {
    if (porAbater) {
      this._dataGridInstancePorAbater = component;
    } else {
      this._dataGridInstanceAAbater = component;
    }
  }

  public onCellClick({data}: IDevExpressDataGridEventOnCellClick<IAtivoAbate, number>): void {
    if (data.status === 'porAbater') {
      this.selectedAtivoPorAbater = data;
    } else {
      this.selectedAtivoAAbater = data;
    }
  }

  public onSelectionChanged({selectedRowsData}: IDevExpressDataGridEventOnSelectionChanged<IAtivoAbate, number>, porAbater: boolean): void {
    const ativo: IAtivoAbate = selectedRowsData[0];
    if (porAbater) {
      this.selectedAtivoPorAbater = ativo;
    } else {
      this.selectedAtivoAAbater = ativo;
    }
  }

  public onCellDblClick(porAbater: boolean): void {
    if (porAbater) {
      this.selectedRight();
    } else {
      this.selectedLeft();
    }
  }

  public onContentReady(): void {
    this.emptyAtivosPorAbaterTable = this._dataGridInstancePorAbater.getDataSource().items().length === 0;
    this._btnRealizaAbates.disabled = this.emptyAtivosAAbaterTable = this._dataGridInstanceAAbater.getDataSource().items().length === 0;
    this._dataGridInstancePorAbater.endCustomLoading();
    this._dataGridInstanceAAbater.endCustomLoading();
  }

  public onDataGridAddAbate(event: IDevExpressDataGridEventRowDraggingOnAdd<TAtivoAbateStatus, IAtivoAbate>): void {
    const key: number = event.itemData.codAtivo;
    const values: Partial<IAtivoAbate> = {status: event.toData};
    this._dataGridStore.update(key, values);
    if (event.toData === 'aAbater' && event.itemData === this.selectedAtivoPorAbater) {
      this._dataGridInstancePorAbater.clearSelection();
    }
    if (event.toData === 'porAbater' && event.itemData === this.selectedAtivoAAbater) {
      this._dataGridInstanceAAbater.clearSelection();
    }
    this._refreshDataGrids();
  }

  private _initAtivosAbate(): void {
    const ativoAbates: Array<IAtivoAbate> = this.ativosabate.listaAtivos.map<IAtivoAbate>((ativoAbate: IJsonAtivoAbate) => {
      return {
        ...ativoAbate,
        status: 'porAbater'
      };
    });
    this._dataGridStore = new ArrayStore({
      key: 'codAtivo',
      data: ativoAbates
    });
    // Do not use `new DataSource()` as the datagrids must have different data source references
    this.dataGridDefinition.dataSource = {
      store: this._dataGridStore,
      reshapeOnPush: true
    };
  }

  private _refreshDataGrids(): void {
    this._dataGridInstancePorAbater.refresh();
    this._dataGridInstanceAAbater.refresh();
  }

  private async _realizarAbates(): Promise<void> {
    const listaAtivos: Array<IAtivoAbate> = await this._dataGridStore.load();
    const listaAAbater: Array<IAtivoAbate> = listaAtivos.filter((item: IAtivoAbate) => item.status === 'aAbater');
    const abate: IJsonAtivosAbate = {
      dataAbate: this.ativosabate.dataAbate,
      nomecontaAbate: this.ativosabate.nomecontaAbate,
      ncontaAbate: this.ativosabate.ncontaAbate,
      listaAtivos: listaAAbater
    };
    this._dataGridInstanceAAbater.beginCustomLoading(undefined);
    const promise: Promise<unknown> = this._ativosAbateService.postRegistaAbate(abate);
    this._btnRealizaAbates.promise = promise;
    try {
      await promise;
      this._plAlertService.success('ativosabate.message.success');
      for (const iAtivoAbate of listaAAbater) {
        this._dataGridStore.remove(iAtivoAbate.codAtivo);
        this._dataGridInstanceAAbater.refresh();
      }
    } catch (error: unknown) {
      this._logger.error(error);
    }
    this._dataGridInstanceAAbater.endCustomLoading();
  }
}
