<div class="change-password">
  <div class="account-title">
    <h3 [translate]="'changepassword.message'"></h3>
  </div>

  <form class="account-form account-panel" rel="noopener noreferrer" novalidate [formGroup]="form" [submit]="fnChangePassword" plPromise>
    <div class="account-panel-body">
      <div class="account-logo">
        <a uiSref="login" rel="noopener noreferrer">
          <img src="../../../../../assets/images/logo.webp" alt="CentralGest ERPCloud Logo" />
        </a>
      </div>

      <input
        type="password"
        class="form-control account-empty"
        formControlName="password"
        autocapitalize="off"
        autocomplete="off"
        [attr.placeholder]="'&#xF084; ' + ('changepassword.placeholder.password' | translate)"
        plAutoFocus />
      <div class="error-form" *ngIf="hasError('password', 'minlength')" [translate]="'changepassword.erro.minlength'"></div>
      <div class="error-form" *ngIf="passwordSecureMode && hasError('password', 'securePassword')" [translate]="'account.messages.error.invalidSecurePassword'"></div>

      <input
        type="password"
        class="form-control account-empty"
        formControlName="confirmPassword"
        autocapitalize="off"
        autocomplete="off"
        [attr.placeholder]="'&#xF084; ' + ('changepassword.placeholder.confirmPassword' | translate)" />
      <div class="error-form" *ngIf="hasError('confirmPassword', 'equals')" [translate]="'changepassword.erro.repetirinvalido'"></div>

      <pl-recaptcha *ngIf="recaptchaActive" formControlName="recaptcha"></pl-recaptcha>

      <button type="submit" class="btn btn-primary btn-block action-submit"><i class="fa fa-fw fa-key"></i> <span [translate]="'changepassword.action.changepassword'"></span></button>

      <p class="text-center">
        <small>
          <a uiSref="login" rel="noopener noreferrer"> <i class="fa fa-fw fa-angle-left"></i>&nbsp;<span [translate]="'entity.action.back'"></span> </a>
        </small>
      </p>
    </div>
  </form>
</div>
