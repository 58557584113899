<div class="impdadosemp entity-detail-form">
  <pl-form>
    <pl-group>
      <label [translate]="'impdadosemp.fields.listagem'"></label>
      <edit>
        <pl-edit type="reports_imp_dados_emp" attrName="listagem" [(model)]="report"></pl-edit>
      </edit>
    </pl-group>

    <div class="card mt-2">
      <div class="card-header fw-bold pesquisa-avançada" (click)="collapsedPesqAvancada = !collapsedPesqAvancada">
        <button type="button" class="btn btn-link" [translate]="'impdadosemp.filtropesq'"></button>
        <i class="fa fa-caret-right" aria-hidden="true" [plAnimationRotate]="!collapsedPesqAvancada"></i>
      </div>
      <pl-animation-collapse [collapsed]="collapsedPesqAvancada">
        <div class="card-body">
          <pl-group>
            <pl-group>
              <label [translate]="'impdadosemp.fields.deCod'"></label>
              <edit>
                <entity-autocomplete
                  entity="dgempsfull"
                  attrName="deCod"
                  [model]="header"
                  (selectedKeyChange)="header.deCod = $event"
                  [fieldsMap]="{codEmp: 'deCod'}"
                  [filter]="'ativo=true'"
                  output="key">
                </entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'impdadosemp.fields.ate'"></label>
              <edit>
                <entity-autocomplete
                  entity="dgempsfull"
                  attrName="atecod"
                  [model]="header"
                  (selectedKeyChange)="header.atecod = $event"
                  [fieldsMap]="{codEmp: 'atecod'}"
                  [filter]="'ativo=true'"
                  output="key">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'impdadosemp.fields.deNome'"></label>
              <edit>
                <pl-edit type="text" attrName="deNome" [(model)]="header.deNome"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'impdadosemp.fields.ate'"></label>
              <edit>
                <pl-edit type="text" attrName="ateNome" [(model)]="header.ateNome"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'impdadosemp.fields.deSituacao'"></label>
              <edit>
                <pl-edit type="text" attrName="deSituacao" [(model)]="header.deSituacao"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'impdadosemp.fields.ate'"></label>
              <edit>
                <pl-edit type="text" attrName="ateSituacao" [(model)]="header.ateSituacao"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'impdadosemp.fields.deCategoria'"></label>
              <edit>
                <entity-autocomplete entity="categorias" attrName="deCategoria" [model]="header" (selectedKeyChange)="header.deCategoria = $event" [fieldsMap]="{nCateg: 'deCategoria'}" output="key">
                </entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'impdadosemp.fields.ate'"></label>
              <edit>
                <entity-autocomplete
                  entity="categorias"
                  attrName="ateCategoria"
                  [model]="header"
                  (selectedKeyChange)="header.ateCategoria = $event"
                  [fieldsMap]="{nCateg: 'ateCategoria'}"
                  output="key">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'impdadosemp.fields.deDepartamento'"></label>
              <edit>
                <entity-autocomplete
                  entity="depto"
                  attrName="deDepartamento"
                  [model]="header"
                  (selectedKeyChange)="header.deDepartamento = $event"
                  [fieldsMap]="{nDepto: 'deDepartamento'}"
                  output="key">
                </entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'impdadosemp.fields.ate'"></label>
              <edit>
                <entity-autocomplete
                  entity="depto"
                  attrName="ateDepartamento"
                  [model]="header"
                  (selectedKeyChange)="header.ateDepartamento = $event"
                  [fieldsMap]="{nDepto: 'ateDepartamento'}"
                  output="key">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'impdadosemp.fields.deDtNasc'"></label>
              <edit>
                <pl-edit type="date" attrName="deDtNasc" [(model)]="header.deDtNasc"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'impdadosemp.fields.ate'"></label>
              <edit>
                <pl-edit type="date" attrName="ateDtNasc" [(model)]="header.ateDtNasc"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'impdadosemp.fields.deIdade'"></label>
              <edit>
                <pl-edit type="number" attrName="deIdade" [(model)]="header.deIdade"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'impdadosemp.fields.ate'"></label>
              <edit>
                <pl-edit type="number" attrName="ateIdade" [(model)]="header.ateIdade"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'impdadosemp.fields.deDtValCt'"></label>
              <edit>
                <pl-edit type="date" attrName="deDtValCt" [(model)]="header.deDtValCt"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'impdadosemp.fields.ate'"></label>
              <edit>
                <pl-edit type="date" attrName="ateDtValCt" [(model)]="header.ateDtValCt"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'impdadosemp.fields.deDtValBI'"></label>
              <edit>
                <pl-edit type="date" attrName="deDtValBI" [(model)]="header.deDtValBI"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'impdadosemp.fields.ate'"></label>
              <edit>
                <pl-edit type="date" attrName="ateDtValBI" [(model)]="header.ateDtValBI"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>
        </div>
      </pl-animation-collapse>
    </div>
  </pl-form>

  <hr />

  <cg-pdf-viewer *ngIf="showPdfViewer" [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>
