import CustomStore from 'devextreme/data/custom_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {isMobile, isObject, KEYCODES, PlAlertService, toInteger} from 'pl-comps-angular';
import {CGCardPanelComponent} from '../../../../../components/cg/cardpanel/cardpanel.component';
import {ContabilidadeDocsNaoSaldadosService} from '../contabilidade.docsNaoSaldados.module.service';
import {EEntityStateDetailType} from '../../../../../../common/utils/entity.state.utils';
import {ENTITY_NAME_DOCS_CONTABILIDADE} from '../../../docscontabilidade/docsContabilidade.interface';
import {IContabilidadeDocsNaoSaldadosStateParams, IDocsNaoSaldadosModel} from '../contabilidade.docsNaoSaldados.module.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonErro, IJsonErroList} from '../../../../../interfaces/jsonErro.interface';
import {IJsonPeriodo} from '../../../../../entities/periodos/jsonPeriodo.entity.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';

const BTN_PESQUISAR_ID = 'pesquisar';
const PERIODO_ANO_LENGTH = 4;
const ACTIONS_WIDTH_MOBILE = 100;
const ACTIONS_WIDTH = 155;

@Component({
  selector: 'contabilidade-docsnaosaldados',
  templateUrl: './contabilidade.docsNaoSaldados.module.component.html'
})
export class ContabilidadeDocsNaoSaldadosComponent extends ModuloComponent implements OnInit {
  @Input() public periodos: Array<IJsonPeriodo>;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public loadOnlyAno: number;
  public loadAllAnos: number;
  public model: IDocsNaoSaldadosModel;

  @ViewChild(CGCardPanelComponent) private readonly _cardPanel: CGCardPanelComponent;
  private _dataGridInstance: dxDataGrid;
  private _documentos: Array<IJsonErro>;
  private _shouldFetch: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _contabilidadeDocsNaoSaldadosService: ContabilidadeDocsNaoSaldadosService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columns: [
        {dataField: 'periodo', dataType: 'string', caption: 'reembolsosiva.fields.periodo'},
        {dataField: 'nDiario', dataType: 'number', caption: 'reembolsosiva.fields.nDiario'},
        {dataField: 'nDocInterno', dataType: 'number', caption: 'reembolsosiva.fields.nDocInterno'},
        {dataField: 'valorDebito', dataType: 'currency', caption: 'docscontabilidade.doc.linhas.dcDebito'},
        {dataField: 'valorCredito', dataType: 'currency', caption: 'docscontabilidade.doc.linhas.dcCredito'},
        {
          dataField: 'actions',
          caption: '',
          width: isMobile() ? ACTIONS_WIDTH_MOBILE : ACTIONS_WIDTH,
          cellTemplate: 'actions',
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowFixing: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowHiding: false,
          allowReordering: false,
          allowResizing: false,
          allowSearch: false,
          allowSorting: false,
          showInColumnChooser: false
        }
      ],
      dataSource: new CustomStore({
        key: 'extPocCabID',
        load: () => this._getDocumentos()
      })
    };
    this.loadOnlyAno = 0;
    this.loadAllAnos = 1;
    this.model = {
      dePeriodo: '',
      dePeriodoNome: '',
      atePeriodo: 'ZZZZZZZ',
      atePeriodoNome: '',
      deConta: '1',
      ateConta: '89999999999'
    };
    this._shouldFetch = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.toolbar.addButton({
      id: BTN_PESQUISAR_ID,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-search"></i>',
      class: 'btn-primary',
      caption: 'global.btn.search',
      click: () => {
        this._cardPanel.collapse();
        return this._pesquisar();
      }
    });

    if (this.periodos.length) {
      this.model = {
        ...this.model,
        dePeriodo: this.periodos[0].periodo,
        dePeriodoNome: this.periodos[0].nome,
        atePeriodo: this.periodos[this.periodos.length - 1].periodo,
        atePeriodoNome: this.periodos[this.periodos.length - 1].nome
      };
    }

    const params: IContabilidadeDocsNaoSaldadosStateParams = <IContabilidadeDocsNaoSaldadosStateParams>this._transition.params();
    if (params.model) {
      this.model = {
        ...this.model,
        ...params.model
      };
    }
    this._documentos = params.documentos || [];
  }

  public changedDePeriodo(periodo: string | IJsonPeriodo): void {
    if (isObject(periodo)) {
      this.model.dePeriodo = (<IJsonPeriodo>periodo)?.periodo;
      this.model.dePeriodoNome = (<IJsonPeriodo>periodo)?.nome;
    } else {
      this.model.dePeriodo = <string>periodo;
      this.model.dePeriodoNome = undefined;
    }
    if (periodo) {
      this.model.atePeriodo = `${this.model.dePeriodo.substring(0, PERIODO_ANO_LENGTH)}123`;
      this.model.atePeriodoNome = this._getPeriodoNome(this.model.atePeriodo);
      this.model = {...this.model};

      const ano = toInteger(this.model.dePeriodo.substring(0, PERIODO_ANO_LENGTH));
      if (ano === this._configService.configurations.empresa.anoEmCursoIRC || ano === this._configService.configurations.empresa.anoEmCursoIRC + 1) {
        this.loadOnlyAno = 0;
        this.loadAllAnos = 0;
      } else {
        this.loadOnlyAno = ano;
        this.loadAllAnos = 1;
      }
    }
  }

  public changedAtePeriodo(periodo: string | IJsonPeriodo): void {
    if (isObject(periodo)) {
      this.model.atePeriodo = (<IJsonPeriodo>periodo)?.periodo;
      this.model.atePeriodoNome = (<IJsonPeriodo>periodo)?.nome;
    } else {
      this.model.atePeriodo = <string>periodo;
      this.model.atePeriodoNome = undefined;
    }
  }

  public onDataGridInitialized(event: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = event.component;
  }

  public readonly fnEditDocument = (extPocCabID: string) => (): Promise<void> => this._editDocument(extPocCabID);

  public readonly fnKeydownPesquisar = (value: string, event: KeyboardEvent): void => {
    this._keydownPesquisar(event);
  };

  private _pesquisar(): Promise<void> {
    this._shouldFetch = true;
    return this._dataGridInstance.refresh();
  }

  private _getDocumentos(): Promise<Array<IJsonErro>> {
    if (!this._shouldFetch) {
      return Promise.resolve(this._documentos);
    }
    return this._contabilidadeDocsNaoSaldadosService.getDocs(this.model.dePeriodo, this.model.atePeriodo, this.model.deConta, this.model.ateConta).then((response: HttpResponse<IJsonErroList>) => {
      this._documentos = response.body.erros;
      if (!this._documentos.length) {
        this._plAlertService.info('docsnaosaldados.alertNaoHaDocs');
      }
      return this._documentos;
    });
  }

  private _editDocument(extPocCabID: string): Promise<void> {
    return this._cgStateService.redirectToState({
      stateOrName: ENTITY_NAME_DOCS_CONTABILIDADE,
      stateType: EEntityStateDetailType.EDIT,
      params: {
        id: extPocCabID,
        returnState: this._uiRouterGlobals.current.name,
        returnStateParams: {
          model: this.model,
          documentos: this._documentos
        }
      }
    });
  }

  private _getPeriodoNome(periodo: string): string {
    return periodo ? this.periodos.find((periodoItem: IJsonPeriodo) => periodoItem.periodo === periodo)?.nome : '';
  }

  private _keydownPesquisar(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.preventDefault();
      event.stopPropagation();
      this.toolbar.focusItem(BTN_PESQUISAR_ID);
    }
  }
}
