import type {DxChartComponent} from 'devextreme-angular';
import type {default as DataSource, Options} from 'devextreme/data/data_source';
import type {Store} from 'devextreme/data';
import type {BaseChartLegendItem} from 'devextreme/viz/chart_components/base_chart';
import type {Font} from 'devextreme/viz/core/base_widget';
import type {chartPointAggregationInfoObject, chartPointObject, chartSeriesObject, dxChartAnnotationConfig, dxChartCommonSeriesSettings, dxChartPanes, dxChartValueAxis} from 'devextreme/viz/chart';
import type {Format} from 'devextreme/localization';
import type {SafeHtml} from '@angular/platform-browser';
import {
  TDevExpressChartAlignment,
  TDevExpressChartAnimationEasing,
  TDevExpressChartAnnotationType,
  TDevExpressChartArgumentAxisArgumentType,
  TDevExpressChartArgumentAxisHoverMode,
  TDevExpressChartArgumentAxisType,
  TDevExpressChartArgumentAxisVisualRangeUpdateMode,
  TDevExpressChartCommonAxisSettingsBreakStyleLine,
  TDevExpressChartCommonAxisSettingsConstantLineStyleLabelPosition,
  TDevExpressChartCommonAxisSettingsStripStyleLabelVerticalAlignment,
  TDevExpressChartCommonSeriesSettingsAggregationMethod,
  TDevExpressChartCommonSeriesSettingsHoverMode,
  TDevExpressChartCommonSeriesSettingsHoverStyleHatchingDirection,
  TDevExpressChartDashStyle,
  TDevExpressChartDisplayMode,
  TDevExpressChartDivisionMode,
  TDevExpressChartExportFormats,
  TDevExpressChartInterval,
  TDevExpressChartLabelPosition,
  TDevExpressChartLegendHoverMode,
  TDevExpressChartLegendOrientation,
  TDevExpressChartLegendPosition,
  TDevExpressChartOverlappingBehavior,
  TDevExpressChartPalette,
  TDevExpressChartPaletteExtensionMode,
  TDevExpressChartPosition,
  TDevExpressChartResolveLabelOverlapping,
  TDevExpressChartSelectionMode,
  TDevExpressChartSeries,
  TDevExpressChartSeriesType,
  TDevExpressChartSettingTypes,
  TDevExpressChartTextOverflow,
  TDevExpressChartTheme,
  TDevExpressChartTooltipLocation,
  TDevExpressChartVerticalAlignment,
  TDevExpressChartWordWrap,
  TDevExpressChartZoomAndPanAxis,
  TDevExpressChartZoomAndPanPanKey
} from './devexpress.charts.types.interface';

export type TDevExpressChartArgumentAxisLabel = DxChartComponent['argumentAxis']['label'] & {
  alignment?: TDevExpressChartAlignment;
  displayMode?: TDevExpressChartDisplayMode;
  overlappingBehavior?: TDevExpressChartOverlappingBehavior;
  position?: TDevExpressChartLabelPosition;
  textOverflow?: TDevExpressChartTextOverflow;
  wordWrap?: TDevExpressChartWordWrap;
};

export type TDevExpressChartArgumentAxisTitle = DxChartComponent['argumentAxis']['title'] & {
  alignment?: TDevExpressChartAlignment;
  textOverflow?: TDevExpressChartTextOverflow;
  wordWrap?: TDevExpressChartWordWrap;
};

export type TDevExpressChartArgumentAxisVisualRange = DxChartComponent['argumentAxis']['visualRange'] & {
  length?: TDevExpressChartInterval;
};

export type TDevExpressChartArgumentAxisWholeRange = DxChartComponent['argumentAxis']['wholeRange'] & {
  length?: TDevExpressChartInterval;
};

export type TDevExpressChartAnimation = DxChartComponent['animation'] & {
  easing?: TDevExpressChartAnimationEasing;
};

export type TDevExpressChartArgumentAxis = DxChartComponent['argumentAxis'] & {
  aggregationInterval?: TDevExpressChartInterval;
  argumentType?: TDevExpressChartArgumentAxisArgumentType;
  discreteAxisDivisionMode?: TDevExpressChartDivisionMode;
  hoverMode?: TDevExpressChartArgumentAxisHoverMode;
  label?: TDevExpressChartArgumentAxisLabel;
  minorTickInterval?: TDevExpressChartInterval;
  minVisualRangeLength?: TDevExpressChartInterval;
  position?: TDevExpressChartPosition;
  tickInterval?: TDevExpressChartInterval;
  title?: string | TDevExpressChartArgumentAxisTitle;
  type?: TDevExpressChartArgumentAxisType;
  // eslint-disable-next-line @typescript-eslint/no-restricted-types
  visualRange?: TDevExpressChartArgumentAxisVisualRange | Array<number | string | Date>;
  visualRangeUpdateMode?: TDevExpressChartArgumentAxisVisualRangeUpdateMode;
  // eslint-disable-next-line @typescript-eslint/no-restricted-types
  wholeRange?: TDevExpressChartArgumentAxisWholeRange | Array<number | string | Date>;
};

export type TDevExpressChartCommonAnnotationSettings = DxChartComponent['commonAnnotationSettings'] & {
  textOverflow?: TDevExpressChartTextOverflow;
  wordWrap?: TDevExpressChartWordWrap;
};

export type TDevExpressChartCommonAxisSettingsBreakStyle = DxChartComponent['commonAxisSettings']['breakStyle'] & {
  line?: TDevExpressChartCommonAxisSettingsBreakStyleLine;
};

export type TDevExpressChartCommonAxisSettingsConstantLineStyleLabel = DxChartComponent['commonAxisSettings']['constantLineStyle']['label'] & {
  position?: TDevExpressChartCommonAxisSettingsConstantLineStyleLabelPosition;
};

export type TDevExpressChartCommonAxisSettingsConstantLineStyle = DxChartComponent['commonAxisSettings']['constantLineStyle'] & {
  dashStyle?: TDevExpressChartDashStyle;
  label?: TDevExpressChartCommonAxisSettingsConstantLineStyleLabel;
};

export type TDevExpressChartCommonAxisSettingsLabel = DxChartComponent['commonAxisSettings']['label'] & {
  alignment?: TDevExpressChartAlignment;
  displayMode?: TDevExpressChartDisplayMode;
  overlappingBehavior?: TDevExpressChartOverlappingBehavior;
  position?: TDevExpressChartLabelPosition;
  textOverflow?: TDevExpressChartTextOverflow;
  wordWrap?: TDevExpressChartWordWrap;
};

export type TDevExpressChartCommonAxisSettingsStripStyleLabel = DxChartComponent['commonAxisSettings']['stripStyle']['label'] & {
  horizontalAlignment?: TDevExpressChartAlignment;
  verticalAlignment?: TDevExpressChartCommonAxisSettingsStripStyleLabelVerticalAlignment;
};

export type TDevExpressChartCommonAxisSettingsStripStyle = DxChartComponent['commonAxisSettings']['stripStyle'] & {
  label?: TDevExpressChartCommonAxisSettingsStripStyleLabel;
};

export type TDevExpressChartCommonAxisSettingsTitle = DxChartComponent['commonAxisSettings']['title'] & {
  alignment?: TDevExpressChartAlignment;
  textOverflow?: TDevExpressChartTextOverflow;
  wordWrap?: TDevExpressChartWordWrap;
};

export type TDevExpressChartCommonAxisSettings = DxChartComponent['commonAxisSettings'] & {
  breakStyle?: TDevExpressChartCommonAxisSettingsBreakStyle;
  constantLineStyle?: TDevExpressChartCommonAxisSettingsConstantLineStyle;
  discreteAxisDivisionMode?: TDevExpressChartDivisionMode;
  label?: TDevExpressChartCommonAxisSettingsLabel;
  stripStyle?: TDevExpressChartCommonAxisSettingsStripStyle;
  title?: TDevExpressChartCommonAxisSettingsTitle;
};

export type TDevExpressChartCommonPaneSettingsBorder = DxChartComponent['commonPaneSettings']['border'] & {
  dashStyle?: TDevExpressChartDashStyle;
};

export type TDevExpressChartCommonPaneSettings = DxChartComponent['commonPaneSettings'] & {
  backgroundColor?: string;
  border?: TDevExpressChartCommonPaneSettingsBorder;
};

export type TDevExpressChartCommonPaneSettingsAggregationCalculateFn<TData = unknown> = (aggregationInfo: chartPointAggregationInfoObject, series: chartSeriesObject) => TData | Array<TData>;

export type TDevExpressChartCommonSeriesSettingsAggregation<TData = unknown> = dxChartCommonSeriesSettings['aggregation'] & {
  calculate?: TDevExpressChartCommonPaneSettingsAggregationCalculateFn<TData>;
  method?: TDevExpressChartCommonSeriesSettingsAggregationMethod;
};

export type TDevExpressChartCommonSeriesSettingsBorder = dxChartCommonSeriesSettings['border'] & {
  dashStyle?: TDevExpressChartDashStyle;
};

export type TDevExpressChartCommonSeriesSettingsHoverStyleBorder = dxChartCommonSeriesSettings['hoverStyle']['border'] & {
  dashStyle?: TDevExpressChartDashStyle;
};

export type TDevExpressChartCommonSeriesSettingsHoverStyleHatching = dxChartCommonSeriesSettings['hoverStyle']['hatching'] & {
  direction?: TDevExpressChartCommonSeriesSettingsHoverStyleHatchingDirection;
};

export type TDevExpressChartCommonSeriesSettingsHoverStyle = dxChartCommonSeriesSettings['hoverStyle'] & {
  border?: TDevExpressChartCommonSeriesSettingsHoverStyleBorder;
  dashStyle?: TDevExpressChartDashStyle;
  hatching?: TDevExpressChartCommonSeriesSettingsHoverStyleHatching;
};

export type TDevExpressChartCommonSeriesSettings<TData = unknown> = Partial<dxChartCommonSeriesSettings> & {
  aggregation?: TDevExpressChartCommonSeriesSettingsAggregation<TData>;
  border?: TDevExpressChartCommonSeriesSettingsBorder;
  dashStyle?: TDevExpressChartDashStyle;
  hoverMode?: TDevExpressChartCommonSeriesSettingsHoverMode;
  hoverStyle?: TDevExpressChartCommonSeriesSettingsHoverStyle;
  type?: TDevExpressChartSettingTypes;
};

export type TDevExpressChartCrosshairLabelCustomizeTextFn = (this: IDevExpressChartCrosshairLabelCustomizeTextInfo, info: IDevExpressChartCrosshairLabelCustomizeTextInfo) => string;

export type TDevExpressChartCrosshairHorizontalLine = DxChartComponent['crosshair']['horizontalLine'] & {
  dashStyle?: TDevExpressChartDashStyle;
  label?: IDevExpressChartCrosshairLabel;
};

export type TDevExpressChartCrosshairVerticalLine = DxChartComponent['crosshair']['verticalLine'] & {
  dashStyle?: TDevExpressChartDashStyle;
  label?: IDevExpressChartCrosshairLabel;
};

export type TDevExpressChartCrosshair = DxChartComponent['crosshair'] & {
  dashStyle?: TDevExpressChartDashStyle;
  horizontalLine?: TDevExpressChartCrosshairHorizontalLine;
  label?: IDevExpressChartCrosshairLabel;
  verticalLine?: TDevExpressChartCrosshairVerticalLine;
};

export type TDevExpressChartCustomizeAnnotationFn = (annotation: IDevExpressChartAnnotation) => IDevExpressChartAnnotation;

export type TDevExpressChartCustomizeLabelFn<TSeriesType extends TDevExpressChartSeriesType = TDevExpressChartSeriesType> = (
  pointInfo: TDevExpressChartCustomizeLabelPointInfo<TSeriesType>
) => dxChartCommonSeriesSettings['label'];

export type TDevExpressChartCustomizeLabelPointInfo<TSeriesType extends TDevExpressChartSeriesType = TDevExpressChartSeriesType> = TSeriesType extends 'RangeAreaSeries' | 'RangeBarSeries'
  ? IDevExpressChartCustomizeLabelPointInfoRange
  : TSeriesType extends 'CandleStickSeries' | 'StockSeries'
    ? IDevExpressChartCustomizeLabelPointInfoFinancial
    : IDevExpressChartCustomizeLabelPointInfo;

export type TDevExpressChartCustomizePointlFn<TSeriesType extends TDevExpressChartSeriesType = TDevExpressChartSeriesType> = (
  pointInfo: TDevExpressChartCustomizePointPointInfo<TSeriesType>
) => dxChartCommonSeriesSettings['point'];

export type TDevExpressChartCustomizePointPointInfo<TSeriesType extends TDevExpressChartSeriesType = TDevExpressChartSeriesType> = TSeriesType extends 'RangeAreaSeries' | 'RangeBarSeries'
  ? IDevExpressChartCustomizePointPointInfoRange
  : TSeriesType extends 'CandleStickSeries' | 'StockSeries'
    ? IDevExpressChartCustomizePointPointInfoFinancial
    : IDevExpressChartCustomizePointPointInfo;

export type TDevExpressChartCustomizeTooltipFn<TSeriesType extends TDevExpressChartSeriesType = TDevExpressChartSeriesType> = (
  pointInfo: TDevExpressChartCustomizeTooltipPointInfo<TSeriesType>
) => IDevExpressChartCustomizeTooltipReturnValue;

export type TDevExpressChartCustomizeTooltipPointInfo<TSeriesType extends TDevExpressChartSeriesType = TDevExpressChartSeriesType> = TSeriesType extends
  | 'FullStackedBarSeries'
  | 'FullStackedAreaSeries'
  ? IDevExpressChartCustomizeTooltipPointInfoFullStackedBarAndFullStackArea
  : TSeriesType extends 'RangeAreaSeries' | 'RangeBarSeries'
    ? IDevExpressChartCustomizeTooltipPointInfoRangeAreaAndRangeBar
    : TSeriesType extends 'CandleStickSeries' | 'StockSeries'
      ? IDevExpressChartCustomizeTooltipPointInfoCandleStickAndStock
      : IDevExpressChartCustomizeTooltipPointInfoBase;

export type TDevExpressChartDataPrepareSettingsSortingMethodFn = (a: TDevExpressChartDataSource, b: TDevExpressChartDataSource) => number;

export type TDevExpressChartDataPrepareSettings = DxChartComponent['dataPrepareSettings'] & {
  sortingMethod?: boolean | TDevExpressChartDataPrepareSettingsSortingMethodFn;
};

export type TDevExpressChartDataSource = DxChartComponent['dataSource'] | DataSource | Options | Store;

export type TDevExpressChartExportSvgToCanvasFn = (svg: SVGElement, canvas: HTMLCanvasElement) => Promise<void>;

export type TDevExpressChartExport = DxChartComponent['export'] & {
  formats?: Array<TDevExpressChartExportFormats>;
  svgToCanvas?: TDevExpressChartExportSvgToCanvasFn;
};

export type TDevExpressChartLegendCustomizeHintFn = (seriesInfo: IDevExpressChartLegendCustomizeHintSeriesInfo) => string;

export type TDevExpressChartLegendCustomizeItemsFn = (seriesInfo: Array<BaseChartLegendItem>) => Array<BaseChartLegendItem>;

export type TDevExpressChartLegendCustomizeTextFn = (seriesInfo: IDevExpressChartLegendCustomizeHintSeriesInfo) => string;

export type TDevExpressChartLegendTitle = DxChartComponent['legend']['title'] & {
  horizontalAlignment?: TDevExpressChartAlignment;
  verticalAlignment?: TDevExpressChartVerticalAlignment;
};

export type TDevExpressChartLegend = DxChartComponent['legend'] & {
  customizeHint?: TDevExpressChartLegendCustomizeHintFn;
  customizeItems?: TDevExpressChartLegendCustomizeItemsFn;
  customizeText?: TDevExpressChartLegendCustomizeTextFn;
  horizontalAlignment?: TDevExpressChartAlignment;
  hoverMode?: TDevExpressChartLegendHoverMode;
  itemsAlignmen?: TDevExpressChartAlignment;
  itemTextPosition?: TDevExpressChartPosition;
  markerTemplate?: string;
  orientation?: TDevExpressChartLegendOrientation;
  position?: TDevExpressChartLegendPosition;
  title?: TDevExpressChartLegendTitle;
  verticalAlignment?: TDevExpressChartVerticalAlignment;
};

export type TDevExpressChartScrollBar = DxChartComponent['scrollBar'] & {
  position?: TDevExpressChartPosition;
};

export type TDevExpressChartTitle = DxChartComponent['title'] & {
  horizontalAlignment?: TDevExpressChartAlignment;
  textOverflow?: TDevExpressChartTextOverflow;
  verticalAlignment?: TDevExpressChartVerticalAlignment;
  wordWrap?: TDevExpressChartWordWrap;
};

export type TDevExpressChartTooltip<TSeriesType extends TDevExpressChartSeriesType = TDevExpressChartSeriesType> = DxChartComponent['tooltip'] & {
  contentTemplate?: string;
  customizeTooltip?: TDevExpressChartCustomizeTooltipFn<TSeriesType>;
  location?: TDevExpressChartTooltipLocation;
};

export type TDevExpressChartZoomAndPan = DxChartComponent['zoomAndPan'] & {
  argumentAxis?: TDevExpressChartZoomAndPanAxis;
  panKey?: TDevExpressChartZoomAndPanPanKey;
  valueAxis?: TDevExpressChartZoomAndPanAxis;
};

export interface IDevExpressChartCustomizeTooltipReturnValue {
  text?: string;
  html?: SafeHtml;
  color?: string;
  fontColor?: string;
  borderColor?: string;
}

export interface IDevExpressChartCustomizeTooltipPointInfoBase {
  originalValue: unknown;
  value: unknown;
  valueText: string;
  originalArgument: string;
  argument: string;
  argumentText: string;
  seriesName: string;
  point: chartPointObject;
  points: Array<chartPointObject>;
}

export interface IDevExpressChartCustomizeTooltipPointInfoFullStackedBarAndFullStackArea extends IDevExpressChartCustomizeTooltipPointInfoBase {
  percent: number;
  percentText: string;
  total: number;
  totalTex: string;
}

export interface IDevExpressChartCustomizeTooltipPointInfoRangeAreaAndRangeBar extends IDevExpressChartCustomizeTooltipPointInfoBase {
  originalMinValue: number;
  rangeValue1: number;
  rangeValue1Text: string;
  originalValue: number;
  rangeValue2: number;
  rangeValue2Text: string;
  valueText: string;
}

export interface IDevExpressChartCustomizeTooltipPointInfoCandleStickAndStock extends IDevExpressChartCustomizeTooltipPointInfoBase {
  originalOpenValue: number;
  valueText: string;
  openValue: number;
  openValueText: string;
  originalCloseValue: number;
  closeValue: number;
  closeValueText: string;
  originalHighValue: number;
  highValue: number;
  highValueText: string;
  originalLowValue: number;
  lowValue: number;
  lowValueText: string;
  reductionValue: number;
  reductionValueText: string;
}

export interface IDevExpressChart<TSeriesType extends TDevExpressChartSeriesType = TDevExpressChartSeriesType, TData = unknown> {
  adaptiveLayout?: DxChartComponent['adaptiveLayout'];
  adjustOnZoom?: boolean;
  animation?: TDevExpressChartAnimation;
  annotations?: Array<IDevExpressChartAnnotation>;
  argumentAxis?: TDevExpressChartArgumentAxis;
  autoHidePointMarkers?: boolean;
  barGroupPadding?: number;
  barGroupWidth?: number;
  commonAnnotationSettings?: TDevExpressChartCommonAnnotationSettings;
  commonAxisSettings?: TDevExpressChartCommonAxisSettings;
  commonPaneSettings?: TDevExpressChartCommonPaneSettings;
  commonSeriesSettings?: TDevExpressChartCommonSeriesSettings<TData>;
  containerBackgroundColor?: string;
  crosshair?: TDevExpressChartCrosshair;
  customizeAnnotation?: TDevExpressChartCustomizeAnnotationFn;
  customizeLabel?: TDevExpressChartCustomizeLabelFn<TSeriesType>;
  customizePoint?: TDevExpressChartCustomizePointlFn<TSeriesType>;
  dataPrepareSettings?: TDevExpressChartDataPrepareSettings;
  dataSource?: TDevExpressChartDataSource;
  defaultPane?: string;
  disabled?: boolean;
  elementAttr?: object;
  export?: TDevExpressChartExport;
  legend?: TDevExpressChartLegend;
  loadingIndicator?: DxChartComponent['loadingIndicator'];
  margin?: DxChartComponent['margin'];
  maxBubbleSize?: number;
  minBubbleSize?: number;
  negativesAsZeroes?: boolean;
  palette?: TDevExpressChartPalette | Array<TDevExpressChartPalette>;
  paletteExtensionMode?: TDevExpressChartPaletteExtensionMode;
  panes?: dxChartPanes | Array<dxChartPanes>;
  pathModified?: boolean;
  pointSelectionMode?: TDevExpressChartSelectionMode;
  redrawOnResize?: boolean;
  resizePanesOnZoom?: boolean;
  resolveLabelOverlapping?: TDevExpressChartResolveLabelOverlapping;
  rotated?: boolean;
  rtlEnabled?: boolean;
  scrollBar?: TDevExpressChartScrollBar;
  series?: TDevExpressChartSeries<TSeriesType> | Array<TDevExpressChartSeries<TSeriesType>>;
  seriesSelectionMode?: TDevExpressChartSelectionMode;
  seriesTemplate?: DxChartComponent['seriesTemplate'];
  size?: DxChartComponent['size'];
  stickyHovering?: boolean;
  synchronizeMultiAxes?: boolean;
  theme?: TDevExpressChartTheme;
  title?: TDevExpressChartTitle | string;
  tooltip?: TDevExpressChartTooltip<TSeriesType>;
  valueAxis?: dxChartValueAxis | Array<dxChartValueAxis>;
  zoomAndPan?: TDevExpressChartZoomAndPan;
}

export interface IDevExpressChartAnnotation extends dxChartAnnotationConfig {
  textOverflow?: TDevExpressChartTextOverflow;
  type?: TDevExpressChartAnnotationType;
  wordWrap?: TDevExpressChartWordWrap;
}

export interface IDevExpressChartCrosshairLabel {
  backgroundColor?: string;
  customizeText?: TDevExpressChartCrosshairLabelCustomizeTextFn;
  font?: Font;
  format?: Format | string;
  visible?: boolean;
}

export interface IDevExpressChartCrosshairLabelCustomizeTextInfo {
  point: chartPointObject;
  // eslint-disable-next-line @typescript-eslint/no-restricted-types
  value: Date | number | string;
  valueText: string;
}

export interface IDevExpressChartCustomizePointPointInfoBase {
  argument: unknown;
  tag: string;
  seriesName: unknown;
  index: number;
  data: unknown;
}

export interface IDevExpressChartCustomizePointPointInfo extends IDevExpressChartCustomizePointPointInfoBase {
  value: unknown;
}

export interface IDevExpressChartCustomizePointPointInfoRange extends IDevExpressChartCustomizePointPointInfoBase {
  rangeValue1: string;
  rangeValue2: string;
}

export interface IDevExpressChartCustomizePointPointInfoFinancial extends IDevExpressChartCustomizePointPointInfoBase {
  openValue: number;
  closeValue: number;
  lowValue: number;
  highValue: number;
  reductionValue: number;
}

export interface IDevExpressChartCustomizeLabelPointInfoBase {
  argument: unknown;
  tag: string;
  series: unknown;
  index: number;
  data: unknown;
}

export interface IDevExpressChartCustomizeLabelPointInfo extends IDevExpressChartCustomizeLabelPointInfoBase {
  value: unknown;
}

export interface IDevExpressChartCustomizeLabelPointInfoRange extends IDevExpressChartCustomizeLabelPointInfoBase {
  rangeValue1: string;
  rangeValue2: string;
}

export interface IDevExpressChartCustomizeLabelPointInfoFinancial extends IDevExpressChartCustomizeLabelPointInfoBase {
  openValue: number;
  closeValue: number;
  lowValue: number;
  highValue: number;
  reductionValue: number;
}

export interface IDevExpressChartLegendCustomizeHintSeriesInfo {
  seriesColor: string;
  seriesIndex: number;
  seriesName: string;
}

export interface IDevExpressChartLabelCustomizeText {
  originalValue: string;
  value: string;
  valueText: string;
  originalArgument: string;
  argument: string;
  argumentText: string;
  point: chartPointObject;
  seriesName: string;
  percent: number;
  percentText: string;
}

export const DEVEXPRESS_CHART_FONT_FAMILY = 'Nunito Sans';
export const DEVEXPRESS_CHART_BLUE_COLOR = '#7ab8eb';
export const DEVEXPRESS_CHART_GREEN_COLOR = '#97da97';
export const DEVEXPRESS_CHART_YELLOW_COLOR = '#facb86';
export const DEVEXPRESS_CHART_RED_COLOR = '#e78683';
