<div class="modal-subrefeicao">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'rhrecolhadados.modal.title'"></h5>
  </div>

  <div class="modal-body">
    <h5 [translate]="'rhrecolhadados.modal.labelnhoras'" [translateParams]="{abonodesc: remun.nomeAbonoDesconto}"></h5>
    <pl-group>
      <label [translate]="'rhrecolhadados.modal.nhoras'"></label>
      <edit>
        <pl-edit type="number" attrName="nhoras" [model]="remun.quantidade" [properties]="{readonly: true}"></pl-edit>
      </edit>
    </pl-group>

    <br />

    <h5 [translate]="'rhrecolhadados.modal.labelndias'"></h5>
    <pl-group>
      <label [translate]="'rhrecolhadados.modal.ndias'"></label>
      <edit>
        <pl-edit type="number" attrName="ndias" [(model)]="remun.ndiasSubAlimentacao"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-dismiss" (evtClicked)="close()"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span> </pl-button>

    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
