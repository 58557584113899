<div class="cg-blocked-plugin" *ngIf="!hasAccess">
  <pl-alert type="error" theme="m-0" [closeable]="false">
    <span *ngIf="!licencaStoreModePublic" [translate]="'blockedPlugin.text.noLicense'" [translateParams]="{portal: portalTitle}"></span>
    <span *ngIf="licencaStoreModePublic && !expired" [innerHTML]="'blockedPlugin.text.noLicenseStoreMode' | translate: {href: storeUrl, portal: portalTitle}"></span>
    <span *ngIf="licencaStoreModePublic && expired" [innerHTML]="'blockedPlugin.text.noLicenseStoreMode' | translate: {href: storeUrl, portal: portalTitle}"></span>
  </pl-alert>
</div>

<pl-alert type="info" theme="m-0" [closeable]="false" *ngIf="requiredRoles && requiredRoles.length > 0">
  <span [translate]="captionRequiredRoles"></span>
</pl-alert>
