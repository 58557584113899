import {InjectFlags, InjectOptions, Injector, ProviderToken} from '@angular/core';

const MINIMUM_NUMBER_OF_INJECTORS = 2;

export class MergeInjector implements Injector {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
  private static readonly NOT_FOUND_VALUE: any = {};
  private readonly _injectors: Array<Injector>;

  constructor(...injectors: Array<Injector>) {
    if (injectors.length < MINIMUM_NUMBER_OF_INJECTORS) {
      throw new Error('pass at least two injectors');
    }
    this._injectors = injectors;
  }

  public get<T>(token: ProviderToken<T>, notFoundValue: T = MergeInjector.NOT_FOUND_VALUE, options?: InjectOptions | InjectFlags): T {
    for (const injector of this._injectors) {
      try {
        const value: T = injector.get<T>(token, notFoundValue, options);
        if (value !== notFoundValue) {
          return value;
        }
      } catch (e: unknown) {
        // ignore errors
      }
    }

    if (arguments.length >= MINIMUM_NUMBER_OF_INJECTORS) {
      return notFoundValue;
    }

    // This will throw the DI Injector error
    this._injectors[0].get<T>(token);
    return undefined;
  }
}
