<div>
  <pl-alert *ngIf="model.perfilSistema > 0" type="warning" [closeable]="false">
    <div [innerHTML]="'abdes.messages.abdescSystem' | translate"></div>
  </pl-alert>

  <entity-detail [entityName]="entityName" [(model)]="model" [type]="type" [maintenanceMode]="maintenanceMode" [callback]="callback" (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL}">
        <pl-group>
          <label [translate]="'abdes.fields.codAbDesc'"></label>
          <edit>
            <pl-edit-text attrName="codAbDesc" [model]="model.codAbDesc" (modelChange)="onCodAbDescChange($event)"></pl-edit-text>
          </edit>
        </pl-group>
        <pl-group *ngIf="isAbono">
          <label [translate]="'abdes.fields.grupoAbono'"></label>
          <edit>
            <entity-autocomplete
              entity="grabonos"
              attrName="grupoAbono"
              [model]="model"
              [selectedKey]="model.grupoAbono"
              (selectedKeyChange)="grupoAbonoChange($event)"
              (evtSelectedDescriptionChanged)="model.grupoAbonoNome = $event"
              [outputKey]="'codGrAbono'"
              [outputDescription]="'descricao'"
              [fieldsMap]="{codGrAbono: 'grupoAbono', descricao: 'grupoAbonoNome'}"
              plAutoFocus
              [plAutoFocusDisabled]="!isAbono">
            </entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group *ngIf="!isAbono">
          <label [translate]="'abdes.fields.grupoDesconto'"></label>
          <edit>
            <entity-autocomplete
              entity="grdescontos"
              attrName="grupoDesconto"
              [model]="model"
              [selectedKey]="model.grupoDesconto"
              (selectedKeyChange)="grupoDescontoChange($event)"
              (evtSelectedDescriptionChanged)="model.grupoDescontoNome = $event"
              [outputKey]="'codGrDesconto'"
              [outputDescription]="'descricao'"
              [fieldsMap]="{codGrDesconto: 'grupoDesconto', descricao: 'grupoDescontoNome'}"
              plAutoFocus
              [plAutoFocusDisabled]="isAbono">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'abdes.fields.designacaoBreve'"></label>
            <edit>
              <pl-edit-text attrName="designacaoBreve" [(model)]="model.designacaoBreve"></pl-edit-text>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'abdes.fields.designacaoCompleta'"></label>
            <edit>
              <pl-edit-text attrName="designacaoCompleta" [(model)]="model.designacaoCompleta"></pl-edit-text>
            </edit>
          </pl-group>
        </pl-group>

        <fieldset>
          <legend [translate]="'abdes.fields.irs'"></legend>
          <pl-group>
            <label [translate]="'abdes.fields.tipoRendimento'"></label>
            <edit>
              <entity-autocomplete
                entity="rendis"
                attrName="tipoRendimento"
                [model]="model"
                (selectedKeyChange)="model.tipoRendimento = $event"
                [fieldsMap]="{tipRendiment: 'tipoRendimento'}"
                output="key"
                outputKey="tipRendiment"
                [rowTemplate]="rendisRowTemplate"
                [filter]="rendisFilter"
                [properties]="{showMaintenanceEditAction: false, disabled: isPerfilSistema}"
                [maintenanceProperties]="{inheritFilter: true}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'abdes.fields.taxaRetencao'"></label>
              <edit>
                <pl-edit attrName="procNatal" [type]="'tipoTaxaRetencao'" [model]="taxaRetencao" (modelChange)="taxaRetencaoChange($event)" [properties]="{disabled: isPerfilSistema}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'abdes.virtualFields.imprimeDec119'"></label>
                <edit>
                  <pl-edit-checkbox attrName="imprimeDec119" [(model)]="modelDec119.imprimeDec119" [properties]="{disabled: type === stateTypes.DETAIL || isPerfilSistema}"></pl-edit-checkbox>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'abdes.virtualFields.enviaAT'"></label>
                <edit>
                  <pl-edit-checkbox attrName="enviaAT" [(model)]="modelDec119.enviaAT" [properties]="{disabled: type === stateTypes.DETAIL || isPerfilSistema}"></pl-edit-checkbox>
                </edit>
              </pl-group>
            </pl-group>
          </pl-group>
        </fieldset>

        <fieldset>
          <legend [translate]="'abdes.fields.segsocial'"></legend>
          <pl-group>
            <pl-group>
              <label [translate]="'abdes.fields.codSSCol'"></label>
              <edit>
                <entity-autocomplete entity="sscol" attrName="codSSCol" [(selectedKey)]="model.codSSCol" [properties]="{disabled: isPerfilSistema}" output="key"> </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'abdes.fields.percInciSegSocial'"></label>
              <edit>
                <pl-edit-number attrName="percInciSegSocial" [(model)]="model.percInciSegSocial" [properties]="{disabled: isPerfilSistema}"></pl-edit-number>
              </edit>
            </pl-group>
          </pl-group>
        </fieldset>

        <pl-group>
          <pl-group>
            <label [translate]="'abdes.fields.valorFixo'"></label>
            <edit>
              <pl-edit-number attrName="valorFixo" [(model)]="model.valorFixo"></pl-edit-number>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'abdes.fields.valorLimIncidencia'"></label>
            <edit>
              <pl-edit-number attrName="valorLimIncidencia" [(model)]="model.valorLimIncidencia" [properties]="{disabled: perfilSistemaAbdes?.bloqueiaLimitesIncidencia}"></pl-edit-number>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <label [translate]="'abdes.fields.tipoArredondamento'"></label>
          <edit>
            <pl-edit attrName="tipoArredondamento" type="tipoArrendodamentoABDES" [(model)]="model.tipoArredondamento" [properties]="{disabled: isPerfilSistema}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'abdes.fields.tipoValorUnitRecolha'"></label>
            <edit>
              <pl-edit attrName="tipoValorUnitRecolha" type="tipoValorUnitarioRecolher" [(model)]="model.tipoValorUnitRecolha"></pl-edit>
            </edit>
          </pl-group>
          <pl-group *ngIf="isAbono">
            <label [translate]="'abdes.fields.calcCustHora'"></label>
            <edit>
              <pl-edit-checkbox attrName="calcCustHora" [(model)]="model.calcCustHora" [properties]="{disabled: type === stateTypes.DETAIL}"></pl-edit-checkbox>
            </edit>
          </pl-group>
          <pl-group *ngIf="!isAbono">
            <label [translate]="'abdes.fields.entiSuporte'"></label>
            <edit>
              <pl-edit attrName="entiSuporte" type="tipoEntSuporteABDES" [(model)]="model.entiSuporte"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <label [translate]="'abdes.fields.codUnidade'"></label>
          <edit>
            <entity-autocomplete entity="rhunidades" attrName="codUnidade" [model]="model" [(selectedKey)]="model.codUnidade" [fieldsMap]="{codUnidade: 'codUnidade'}" output="key">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-tabs>
          <pl-tab id="integracao" [caption]="'abdes.tabIntegracaoCruzamento'">
            <div *plTabContent>
              <pl-group>
                <label [translate]="'abdes.fields.contaMovDeb'"></label>
                <edit>
                  <entity-autocomplete entity="pocs" attrName="contaMovDeb" [model]="model" [(selectedKey)]="model.contaMovDeb" [fieldsMap]="{nConta: 'contaMovDeb'}" output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'abdes.fields.contaMovCred'"></label>
                <edit>
                  <entity-autocomplete entity="pocs" attrName="contaMovCred" [model]="model" [(selectedKey)]="model.contaMovCred" [fieldsMap]="{nConta: 'contaMovCred'}" output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'abdes.fields.contaEncDEB'"></label>
                <edit>
                  <entity-autocomplete entity="pocs" attrName="contaEncDEB" [model]="model" [(selectedKey)]="model.contaEncDEB" [fieldsMap]="{nConta: 'contaEncDEB'}" output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'abdes.fields.contaEncCRE'"></label>
                <edit>
                  <entity-autocomplete entity="pocs" attrName="contaEncCRE" [model]="model" [(selectedKey)]="model.contaEncCRE" [fieldsMap]="{nConta: 'contaEncCRE'}" output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="abdesGrid" [dataSource]="dataGridDefinition.dataSource">
                <div *dxTemplate="let item of 'headerCellTemplateBtnsEdit'">
                  <pl-button *ngIf="type !== stateTypes.NEW" klass="btn-xs btn-primary float-end" [disabled]="type === stateTypes.DETAIL || !canEditCruzamentos" [onClick]="newCrabd">
                    <i class="fa fa-fw fa-plus-circle"></i>
                  </pl-button>
                </div>

                <div *dxTemplate="let item of 'cellTemplateBtnsEdit'">
                  <pl-button klass="btn-xs btn-warning float-end" [disabled]="type === stateTypes.DETAIL || !canEditCruzamentos" (evtClicked)="editCrabd(item.data)">
                    <i class="fa fa-fw fa-pencil"></i>
                  </pl-button>
                  <pl-button klass="btn-xs btn-danger float-end" [disabled]="type === stateTypes.DETAIL || !canEditCruzamentos" (evtClicked)="deleteCrabd(item.data)">
                    <i class="fa fa-fw fa-trash"></i>
                  </pl-button>
                </div>
              </dx-data-grid>
            </div>
          </pl-tab>

          <pl-tab id="outrosdados" [caption]="'abdes.tabOutrosDados'">
            <div *plTabContent>
              <pl-group>
                <label [translate]="'abdes.fields.nHorasDiaProvDesc'"></label>
                <edit>
                  <pl-edit-number attrName="nHorasDiaProvDesc" [(model)]="model.nHorasDiaProvDesc" [properties]="{disabled: !isNHorasDiaProvDescEnabled}"></pl-edit-number>
                </edit>
              </pl-group>
              <pl-group>
                <pl-group>
                  <label [translate]="'abdes.fields.tipoDescontoProporcionalABDES'"></label>
                  <edit>
                    <pl-edit attrName="tipoDescontoProporcionalABDES" [type]="'tipoDescontoProporcionalABDES'" [(model)]="model.tipoDescontoProporcional"></pl-edit>
                  </edit>
                </pl-group>
                <pl-group *ngIf="model.tipoDescontoProporcional === 2">
                  <label [translate]="'abdes.fields.nHorasDescontoProporcional'"></label>
                  <edit>
                    <pl-edit-number attrName="tipoDescontoProporcional" [(model)]="model.nHorasDescontoProporcional"></pl-edit-number>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'abdes.fields.mapaSeguro'"></label>
                  <edit>
                    <pl-edit type="boolean" attrName="mapaSeguro" [(model)]="model.mapaSeguro" [properties]="{trueValue: 1, falseValue: 0}"></pl-edit>
                  </edit>
                </pl-group>
                <pl-group>
                  <label [translate]="'abdes.fields.mapaSindic'"></label>
                  <edit>
                    <pl-edit type="boolean" attrName="mapaSindic" [(model)]="model.mapaSindic" [properties]="{trueValue: 1, falseValue: 0}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>
              <pl-group>
                <pl-group>
                  <label [translate]="'abdes.fields.coefHExtra'"></label>
                  <edit>
                    <pl-edit-number attrName="coefHExtra" [(model)]="model.coefHExtra"></pl-edit-number>
                  </edit>
                </pl-group>
                <pl-group>
                  <label [translate]="'abdes.fields.percLimValorHExtra'"></label>
                  <edit>
                    <pl-edit-number attrName="percLimValorHExtra" [(model)]="model.percLimValorHExtra"></pl-edit-number>
                  </edit>
                </pl-group>
              </pl-group>
              <pl-group>
                <pl-group>
                  <label [translate]="'abdes.fields.usaSPReducaoRemuneratoria'"></label>
                  <edit>
                    <pl-edit-checkbox attrName="usaSPReducaoRemuneratoria" [(model)]="model.usaSPReducaoRemuneratoria" [properties]="{disabled: type === stateTypes.DETAIL}"></pl-edit-checkbox>
                  </edit>
                </pl-group>
                <pl-group>
                  <label [translate]="'abdes.fields.perctDescSeguro'"></label>
                  <edit>
                    <pl-edit-number attrName="perctDescSeguro" [(model)]="model.perctDescSeguro"></pl-edit-number>
                  </edit>
                </pl-group>
              </pl-group>
              <pl-group>
                <label [translate]="'abdes.fields.codCGASit'"></label>
                <edit>
                  <entity-autocomplete entity="cgasi" attrName="codCGASit" [model]="model" [(selectedKey)]="model.codCGASit" [fieldsMap]="{codCGASit: 'codCGASit'}" output="key"></entity-autocomplete>
                </edit>
              </pl-group>
            </div>
          </pl-tab>
        </pl-tabs>
      </pl-form>
    </div>
  </entity-detail>
</div>
