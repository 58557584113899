<div class="ativosaquisicaocontab-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'ativosaquisicaocontab.modal.ativoaquisicaocontabmodaltitle'"></h5>
    <pl-button klass="btn-primary btn-sm" id="processar" [disabled]="closeDisabled" [onClick]="searchAquisicaoContab">
      <i class="fa fa-fw fa-refresh"></i>&nbsp;<span [translate]="'global.btn.refresh'"></span>
    </pl-button>
  </div>

  <div class="modal-body">
    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinition"
      cgDxDataGridInstanceName="ativosAquisicaoContabModal"
      [dataSource]="aquisicaoContabList"
      [(selectedRowKeys)]="nLancsList"
      (onInitialized)="onInitialized($event)"
      (onCellClick)="onCellClick($event)"
      (onSelectionChanged)="onSelectionChanged($event)">
      <div *dxTemplate="let item of 'detailTemplateDocContabilidade'">
        <split-viewer-doccontab-digital
          [(docContabilidade)]="item.data._docContabilidade"
          [contabDigitalDoc]="item.data._contabDigitalDoc"
          [initialSizeLeft]="60"
          [showToolbar]="true"
          [maintenanceMode]="true"
          [readonlyDigital]="true">
        </split-viewer-doccontab-digital>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-dismiss" [disabled]="closeDisabled || nLancsList?.length <= 0" [onClick]="close" [promise]="promise">
      <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span>
    </pl-button>

    <pl-button klass="btn-light action-dismiss" [disabled]="closeDisabled" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
