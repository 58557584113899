import {isBoolean} from 'pl-comps-angular';
import {AppService} from '../core/services/app/app.service';
import {APP_LOCALE, LOCAL_STORAGE_REFRESHED} from './constants';

export async function configApplication(appService: AppService, window: Window): Promise<void> {
  if (window?.sessionStorage) {
    let refreshed: boolean = JSON.parse(window.sessionStorage.getItem(LOCAL_STORAGE_REFRESHED)) || false;
    if (!isBoolean(refreshed)) {
      refreshed = false;
    }
    if (refreshed === true) {
      appService.setStatus({refreshed: refreshed});
    }
    window.sessionStorage.setItem(LOCAL_STORAGE_REFRESHED, JSON.stringify(false));
  }
  await appService.loadLocale(APP_LOCALE.value);
}
