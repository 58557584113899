<div class="exportacao-compras-module entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-xl-3">
            <pl-group>
              <label [translate]="'exportacaoCompras.fields.dataDe'"></label>
              <edit>
                <pl-edit type="date" attrName="dataDe" [(model)]="filters.datade"></pl-edit>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3">
            <pl-group>
              <label [translate]="'exportacaoCompras.fields.ate'"></label>
              <edit>
                <pl-edit type="date" attrName="dataAte" [(model)]="filters.dataate"></pl-edit>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-xl-6 align-self-end">
            <pl-group>
              <edit>
                <pl-edit type="checkbox" attrName="documentosPorExportar" [(model)]="filters.documentosporexportar" [properties]="{label: 'exportacaoCompras.fields.documentosPorExportar'}"> </pl-edit>
              </edit>
            </pl-group>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <pl-group>
              <edit>
                <button type="button" class="btn btn-sm btn-primary" name="pesquisar" id="btnPesquisar" [click]="fnPesquisar" plPromise data-focus>
                  <i class="fa fa-search fa-fw"></i>&nbsp;<span [translate]="'global.btn.search'"></span>
                </button>
              </edit>
            </pl-group>
          </div>
        </div>
      </pl-form>
    </div>
  </cg-card-panel>

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="exportacaocomprasgeofolia"
    [dataSource]="this.compras"
    [(selectedRowKeys)]="selectedRowKeys"
    (onInitialized)="onInitialized($event)"
    (onSelectionChanged)="onSelectionChanged()"
    (onContextMenuPreparing)="onContextMenuPreparing($event)">
  </dx-data-grid>
</div>
