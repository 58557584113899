import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Directive, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {isNumber} from '../../../common/utilities/utilities';

const DEFAULT_DEBOUNCE_AFTER = 500;

@Directive({
  selector: '[plDebounce]'
})
export class PlFormDebounceDirective<T> implements OnInit, OnChanges, OnDestroy {
  @Input() public plDebounce: T;
  @Input() public plDebounceAfter: number;
  @Output() public readonly evtDebounced: EventEmitter<T>;

  private readonly _subjectDebounce: BehaviorSubject<T>;
  private _subscriptionDebounce: Subscription;

  constructor() {
    this.plDebounceAfter = DEFAULT_DEBOUNCE_AFTER;
    this.evtDebounced = new EventEmitter<T>();
    this._subjectDebounce = new BehaviorSubject<T>(undefined);
  }

  public ngOnInit(): void {
    this._handleChanges();
    this._buildSubject();
    this._render();
  }

  public ngOnChanges({plDebounce, plDebounceAfter}: SimpleChanges): void {
    if (plDebounce && !plDebounce.isFirstChange()) {
      this._render();
    }
    if (plDebounceAfter && !plDebounceAfter.isFirstChange()) {
      this._buildSubject();
    }
  }

  public ngOnDestroy(): void {
    this._clearSubscription();
    this._subjectDebounce.complete();
  }

  private _handleChanges(): void {
    this._changedDebounce();
    this._changedDebounceAfter();
  }

  private _changedDebounce(value: T = this.plDebounce): void {
    this.plDebounce = value;
  }

  private _changedDebounceAfter(value: number = this.plDebounceAfter): void {
    let val = value;
    if (!isNumber(DEFAULT_DEBOUNCE_AFTER)) {
      val = DEFAULT_DEBOUNCE_AFTER;
    }
    this.plDebounceAfter = val;
  }

  private _buildSubject(): void {
    this._clearSubscription();
    this._subscriptionDebounce = this._subjectDebounce
      .asObservable()
      .pipe(debounceTime(this.plDebounceAfter))
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.evtDebounced.emit(value);
      });
  }

  private _render(): void {
    this._subjectDebounce.next(this.plDebounce);
  }

  private _clearSubscription(): void {
    if (this._subscriptionDebounce) {
      this._subscriptionDebounce.unsubscribe();
      this._subscriptionDebounce = undefined;
    }
  }
}
