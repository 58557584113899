import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {IDevExpressDataGrid} from '../../devexpress/datagrid/devexpress.datagrid.interface';
import moment from 'moment';
import {EncomendasProcessamentoModalComponent} from './modals/processamento/encomendas.processamento.modal.component';
import {CGModalService} from '../../cg/modal/cgmodal.service';
import {EGrupoDoc} from '../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {EDocsComerciaisListTab, IDocComercial, IDocsComerciaisEntityService} from '../../../entities/docscomerciais/docsComerciais.entity.interface';
import {IJsonDocSatEncom, IJsonDocSatEncomLin, IJsonEncomenda} from '../../../modules/encomendas/jsonEncomenda.module.interface';
import {EEncomendasEstadosTableLegendColors, EEstadoEncomendas, IClifosSourceItem, IHeaderGestaoEncomendas} from '../../../modules/encomendas/encomendas.module.interface';
import {THttpQueryResponse} from '../../../services/api/api.service.interface';
import {
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnEditingStart,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnKeyDown,
  IDevExpressDataGridEventOnRowPrepared
} from '../../devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {EncomendasService} from '../../../modules/encomendas/service/encomendas.service';
import {copy, IPlToolbarInstance, IPlToolbarItem, isDefinedNotNull, isEmpty, isObject, KEYCODES, PlAlertService, PlToolbarService} from 'pl-comps-angular';
import {MAX_DATE_CG, MIN_DATE_CG} from '../../../../common/utils/utils';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {EncomendasDocumentoModalComponent} from './modals/documento/encomendas.documento.modal.component';
import {EncomendasImpressaoModalComponent} from './modals/impressao/encomendas.impressao.modal.component';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IJsonDocComercial} from '../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {focusElement} from '../../../../common/utils/element.utils';
import {isNull, uniqBy} from 'lodash-es';
import {CGCardPanelComponent} from '../../cg/cardpanel/cardpanel.component';
import {EGroupName} from '../../../../config/constants';
import {EConfigOptionsInstanceName, IEncomendasConfigOptions, TConfigOptions} from '../../../services/config/options/config.options.service.interface';
import {Subscription} from 'rxjs';
import {ConfigOptionsService} from '../../../services/config/options/config.options.service';
import {IJsonArmazem} from '../../../entities/armazens/jsonArmazem.entity.interface';
import {IJsonDocfa} from '../../../entities/docfas/jsonDocFa.entity.interface';
import {ENTITY_NAME_DOC_FAS, IDocFasEntityService} from '../../../entities/docfas/docFas.entity.interface';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {ENTITY_NAME_ARMAZENS} from '../../../entities/armazens/armazens.entity.interface';
import {temLinhasComLotes} from '../../../modules/encomendas/encomendas.module.utils';
import {EncomendasLotesModalComponent} from './modals/lotes/encomendas.lotes.modal.component';
import {GrupoDocService} from '../../../services/grupodoc/grupo.doc.service';
import {CGExceptionService} from '../../exceptions/exceptions.service';
import {IDevExpressDataGridState, IDevExpressDataGridStateColumn} from '../../devexpress/datagrid/state/devexpress.datagrid.state.interface';
import {ConfigService} from '../../../services/config/config.service';
import {TClifos} from '../../../entities/clifos/clifos.entity.interface';

@Component({
  selector: 'encomendas',
  templateUrl: './encomendas.component.html'
})
export class EncomendasComponent implements OnInit, OnDestroy {
  @Input() public grupo: EGrupoDoc;
  @Input() public grupoClifos: TClifos;
  @Input() public nConta: string;
  @Input() public nomeConta: string;
  @Input() public modalClifo: boolean;
  @Input() public processado: boolean;
  @Input() public toolbarInstanceName: string;
  @Input() public callFromFaturacao: boolean;
  @Input() public doc: IJsonDocComercial;

  @Output() public readonly evtProcessarFaccbIdSucess: EventEmitter<IJsonDocComercial>;
  @Output() public readonly evtStartProcessarLotes: EventEmitter<void>;
  @Output() public readonly evtErrorProcessarLotes: EventEmitter<void>;

  public readonly docFaTemplate: string;
  public readonly armazemTemplate: string;
  public readonly templateArtigosSelected: string;
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public readonly outputClifos: string;

  public dataGridDefinition: IDevExpressDataGrid<IJsonEncomenda, number>;
  public header: IHeaderGestaoEncomendas;
  public armazensSource: Array<IJsonArmazem>;
  public docFaListSource: Array<IJsonDocfa>;
  public docFaSource: Array<IJsonDocfa>;
  public armazemSource: Array<IJsonArmazem>;
  public clifosSource: Array<IClifosSourceItem>;
  public clifoSource: IClifosSourceItem;
  public clifoDescription: string;
  public captionSatifClifo: string;
  public captionSelecionarClifo: string;
  public captionVerDadosClifo: string;
  public infoProcessamentoClifo: string;
  public editMode: boolean;
  public clifoMode: boolean;
  public showArtigos: boolean;
  public showArmazens: boolean;
  public showDocFas: boolean;
  public showTextoLivre: boolean;
  public btnProcessar: IPlToolbarItem;
  public btnImprimir: IPlToolbarItem;
  public gridBtnProcessarEnabled: boolean;

  private readonly _docsComerciaisService: IDocsComerciaisEntityService;
  private readonly _subscriptionConfigOptions: Subscription;
  private readonly _docFaService: IDocFasEntityService;
  private readonly _armazemService: IEntityService<IJsonArmazem>;

  private _cardPanel: CGCardPanelComponent;
  private _dataGridInstance: dxDataGrid<IJsonEncomenda, number>;
  private _btnActionSearchElement: HTMLElement;
  private _toolbarInstance: IPlToolbarInstance;
  private _pesquisou: boolean;

  constructor(
    protected readonly _configService: ConfigService,
    private readonly _cgModalService: CGModalService,
    private readonly _encomendasService: EncomendasService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _configOptionsService: ConfigOptionsService,
    private readonly _translateService: TranslateService,
    private readonly _plToolbarService: PlToolbarService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _grupoDoc: GrupoDocService,
    private readonly _renderer: Renderer2
  ) {
    this.configOptionsInstanceName = EConfigOptionsInstanceName.ENCOMENDAS;
    this.configOptionsGroupName = EGroupName.ERP;
    this._docsComerciaisService = this._entityServiceBuilder.build<IJsonDocComercial, IDocsComerciaisEntityService>('docscomerciais');
    this._docFaService = this._entityServiceBuilder.build(ENTITY_NAME_DOC_FAS);
    this._armazemService = this._entityServiceBuilder.build(ENTITY_NAME_ARMAZENS);
    this.evtProcessarFaccbIdSucess = new EventEmitter<IJsonDocComercial>();
    this.evtStartProcessarLotes = new EventEmitter<void>();
    this.evtErrorProcessarLotes = new EventEmitter<void>();
    this.docFaTemplate = '{{nDocFa}} - {{nome}}';
    this.armazemTemplate = '{{nArmazem}} - {{nome}}';
    this.docFaListSource = [];
    this.docFaSource = [];
    this.armazemSource = [];
    this.templateArtigosSelected = '{{codEmp}} - {{nome}}';
    this.outputClifos = '{{nConta}} - {{nome}}';
    this.grupoClifos = 'clifos';
    this.nConta = '';
    this.nomeConta = '';
    this.processado = false;
    this.editMode = false;
    this.clifoMode = false;
    this.clifosSource = [];
    this.clifoSource = {nConta: '', nome: ''};
    this._subscriptionConfigOptions = this._configOptionsService
      .getOptionsErp()
      .get(this.configOptionsInstanceName)
      .options()
      .subscribe((configOptions: TConfigOptions<boolean, IEncomendasConfigOptions>) => {
        this.showArtigos = configOptions.get('showArtigos').value;
        this.showArmazens = configOptions.get('showArmazens').value;
        this.showDocFas = configOptions.get('showDocFas').value;
        const textoLivre = configOptions.get('showTextoLivre').value;
        if (this.showTextoLivre !== textoLivre) {
          this.showTextoLivre = textoLivre;
          this.onContentReady();
        }
      });

    this.searchEncomendas = this.searchEncomendas.bind(this);
    this.processaEncomendas = this.processaEncomendas.bind(this);
  }

  public ngOnInit(): void {
    if (!isEmpty(this.nConta)) {
      this.editMode = !isEmpty(this.nConta);
      this.clifoMode = !isEmpty(this.nConta);
    }
    if (this.toolbarInstanceName && (this.toolbarInstanceName.endsWith(EDocsComerciaisListTab.PorProcessar) || this.toolbarInstanceName.endsWith(EDocsComerciaisListTab.Processado))) {
      this._toolbarInstance = this._plToolbarService.getInstance(this.toolbarInstanceName);
      if (!this.processado) {
        this.btnProcessar = {
          id: 'btn-processar-encomendas-toolbar',
          type: 'button',
          class: 'btn-success',
          iconLeft: '<i class="fa fa-cog"></i>&nbsp;',
          caption: 'global.btn.process',
          disabled: true,
          order: 132,
          click: () => this.processaEncomendas()
        };
        this._toolbarInstance.addButton(this.btnProcessar);
      }
      this.btnImprimir = {
        id: 'btn-print-encomendas-pendentes-toolbar',
        type: 'button',
        class: 'btn-light',
        iconLeft: '<i class="fa fa-print"></i>&nbsp;',
        caption: 'global.btn.print',
        disabled: true,
        order: 133,
        click: () => this.printEncPendentes()
      };
      this._toolbarInstance.addButton(this.btnImprimir);
    }

    this.gridBtnProcessarEnabled = false;

    const tipoclifo: string =
      this.grupoClifos === 'clientes'
        ? this._translateService.instant('encomendas.modal.processamento.fields.cliente')
        : this.grupoClifos === 'fornecedores'
          ? this._translateService.instant('encomendas.modal.processamento.fields.fornecedor')
          : this._translateService.instant('encomendas.modal.processamento.fields.clifos');

    this.captionSatifClifo = this._translateService.instant('encomendas.actions.satisfClifo', {clifo: tipoclifo.toLowerCase()});
    this.captionSelecionarClifo = this._translateService.instant('encomendas.actions.selecionarClifo', {clifo: tipoclifo.toLowerCase()});
    this.captionVerDadosClifo = this._translateService.instant('encomendas.actions.verDadosClifo', {clifo: tipoclifo.toLowerCase()});
    this.infoProcessamentoClifo = this._translateService.instant('encomendas.messages.infoProcessamentoClifo', {clifo: tipoclifo.toLowerCase()});

    this.header = {
      dataDocDe: !this.processado ? MIN_DATE_CG : moment().startOf('month').subtract(1, 'month'),
      dataDocAte: !this.processado ? MAX_DATE_CG : moment(),
      dataPrevEntregaDe: !this.processado ? MIN_DATE_CG : moment().startOf('year'),
      dataPrevEntregaAte: !this.processado ? MAX_DATE_CG : moment(),

      nContaDe:
        this.grupoClifos === 'fornecedores' ? this._configService.configurations.clientesFornecedores.radicalFornecedores : this._configService.configurations.clientesFornecedores.radicalClientes,
      nContaAte:
        this.grupoClifos === 'clientes'
          ? `${this._configService.configurations.clientesFornecedores.radicalClientes}9999999`
          : `${this._configService.configurations.clientesFornecedores.radicalFornecedores}9999999`,
      nConta: this.nConta,
      nomeConta: this.nomeConta,
      nArtigo: '',
      estadoEncomendas: this.processado ? EEstadoEncomendas.SoSatisfeitas : EEstadoEncomendas.SoPendentes,
      armazens: [],
      docfaList: []
    };
    this.dataGridDefinition = {
      keyExpr: 'facliId',
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nDoc', dataType: 'string', caption: 'encomendas.table.nDoc', allowEditing: false},
        {dataField: 'dataDoc', dataType: 'date', caption: 'encomendas.table.dataDoc', allowEditing: false},
        {dataField: 'nArtigo', dataType: 'string', caption: 'encomendas.table.nArtigo', allowEditing: false},
        {dataField: 'nomeArtigo', dataType: 'string', caption: 'encomendas.table.nomeArtigo', allowEditing: false},
        {dataField: 'nClifo', dataType: 'string', caption: 'encomendas.table.nClifo', visible: !this.editMode, allowEditing: false, showInColumnChooser: !this.editMode},
        {dataField: 'nomeClifo', dataType: 'string', caption: 'encomendas.table.nomeClifo', visible: !this.editMode, allowEditing: false, showInColumnChooser: !this.editMode},
        {dataField: 'nArmaz', dataType: 'string', caption: 'encomendas.table.nArmaz', allowEditing: false},
        {dataField: 'nomeArmazem', dataType: 'string', caption: 'encomendas.table.nomeArmaz', visible: false, allowEditing: false},
        {dataField: 'prVendaQtd1', dataType: 'double', caption: 'encomendas.table.prVendaQtd1', allowEditing: false},
        {dataField: 'qtdStockNoArmazemOrigem', dataType: 'number', caption: 'encomendas.table.qtdStockNoArmazemOrigem', allowEditing: false},
        {dataField: 'qtd1', dataType: 'number', caption: 'encomendas.table.qtd1', allowEditing: false},
        {dataField: 'qtd1Fact', dataType: 'number', caption: 'encomendas.table.qtd1Fact', allowEditing: false},
        {
          dataField: 'ligacaoQtd',
          dataType: 'number',
          caption: 'encomendas.table.ligacaoQtd',
          allowEditing: true,
          visible: this.editMode,
          showInColumnChooser: this.editMode,
          validationRules: [
            {
              type: 'range',
              min: 0,
              ignoreEmptyValue: false,
              message: this._translateService.instant('encomendas.errors.valorAProcessarNulo')
            }
          ]
        },
        {dataField: 'dataEntregaPrev', dataType: 'date', caption: 'encomendas.table.dataEntregaPrev', visible: false, allowEditing: false},
        {dataField: 'nRequisicao', dataType: 'string', caption: 'encomendas.table.nRequisicao', visible: false, allowEditing: false},
        {dataField: 'nRefProcesso', dataType: 'string', caption: 'encomendas.table.nRefProcesso', visible: false, allowEditing: false},
        {dataField: 'nCCusto', dataType: 'string', caption: 'encomendas.table.nCCusto', visible: false, allowEditing: false},
        {type: 'buttons', cellTemplate: 'cellTemplateActions', showInColumnChooser: false}
      ],
      dataSource: [],
      editing: {
        allowUpdating: true,
        mode: 'cell',
        selectTextOnEditStart: true,
        startEditAction: 'click'
      },
      export: {filename: 'encomendas.title'},
      height: '52vh',
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateBtnToolbar',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      },
      masterDetail: {enabled: false, template: 'dataGridTemplateDetail'}
    };

    this.clifoDescription =
      this.grupoClifos === 'clientes'
        ? 'encomendas.modal.processamento.fields.cliente'
        : this.grupoClifos === 'fornecedores'
          ? 'encomendas.modal.processamento.fields.fornecedor'
          : 'encomendas.modal.processamento.fields.clifos';

    this._getListSources();
    this._pesquisou = false;
    if (this.callFromFaturacao) {
      this._getListEncomendas();
    }
  }

  public ngOnDestroy(): void {
    if (this._toolbarInstance) {
      if (this.btnProcessar) {
        this._toolbarInstance.removeButton(this.btnProcessar);
      }
      if (this.btnImprimir) {
        this._toolbarInstance.removeButton(this.btnImprimir);
      }
    }

    if (this._subscriptionConfigOptions) {
      this._subscriptionConfigOptions.unsubscribe();
    }
  }

  public searchEncomendas(): Promise<void> {
    this._pesquisou = true;
    return this._getListEncomendas();
  }

  public processaEncomendas(): Promise<void | IJsonDocComercial> {
    let linhas: Array<IJsonDocSatEncomLin> = [];
    this._dataGridInstance
      .getDataSource()
      .store()
      .load()
      .then((items: Array<IJsonDocSatEncomLin>) => {
        linhas = items;
      });

    const index = linhas.findIndex((value: IJsonDocSatEncomLin) => value.ligacaoQtd !== 0);
    if (index === -1) {
      this._plAlertService.error('encomendas.messages.semLinhasSati');
      return Promise.reject(new Error('encomendas.messages.semLinhasSati'));
    }

    const idx = linhas.findIndex((value: IJsonDocSatEncomLin) => isNull(value.ligacaoQtd));
    if (idx > -1) {
      this._plAlertService.error('encomendas.messages.linhasComValorNulo');
      return Promise.reject(new Error('encomendas.messages.linhasComValorNulo'));
    }

    if (this.callFromFaturacao) {
      const isCompra = this._grupoDoc.isCompra(this.grupo);
      if (temLinhasComLotes(linhas, isCompra)) {
        const modalInstance = this._cgModalService.showVanilla(EncomendasLotesModalComponent);
        const componentInstance: EncomendasLotesModalComponent = modalInstance.componentInstance;
        componentInstance.linhas = linhas;
        return modalInstance.result
          .then((response: Array<IJsonDocSatEncomLin>) => {
            if (response) {
              this.evtStartProcessarLotes.emit();
              return this._processaEncomendasNoDocumento(this.doc, response);
            }
            this.evtErrorProcessarLotes.emit();
            return Promise.reject(new Error('encomendas.messages.atribuicaoDeLotesCancelada'));
          })
          .catch((error: unknown) => {
            this.evtErrorProcessarLotes.emit();
            if (error instanceof HttpErrorResponse) {
              const exception = this._cgExceptionService.get(error);
              if (exception?.message) {
                this._plAlertService.error(exception.message);
              }
            }
            return Promise.reject(new Error('encomendas.messages.processamentoLotesEncomendaFalhou'));
          });
      }
      return this._processaEncomendasNoDocumento(this.doc, linhas);
    }

    const linhasAux = [];

    for (const item of linhas) {
      if (item.ligacaoQtd !== 0) {
        const itemAux = copy(item);
        linhasAux.push(itemAux);
      }
    }

    const modalInstance = this._cgModalService.showVanilla(EncomendasProcessamentoModalComponent);
    const componentInstance: EncomendasProcessamentoModalComponent = modalInstance.componentInstance;
    componentInstance.grupo = this.grupo;
    componentInstance.grupoClifos = this.grupoClifos;
    componentInstance.nConta = this.header.nConta;
    componentInstance.nomeConta = this.header.nomeConta;
    componentInstance.linhas = linhasAux;

    return modalInstance.result.then(() => {
      return this._getListEncomendas();
    });
  }

  public printEncPendentes(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(EncomendasImpressaoModalComponent);
    const componentInstance: EncomendasImpressaoModalComponent = modalInstance.componentInstance;
    componentInstance.grupo = this.grupo;
    componentInstance.grupoClifos = this.grupoClifos;
    componentInstance.clifoDescription = this.clifoDescription;
    componentInstance.header = copy(this.header);
    componentInstance.clifosSource = this.clifosSource;
    return modalInstance.result;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonEncomenda, number>): void {
    this._dataGridInstance = component;
    this._setGridColumnsVisibleOrNot(this._dataGridInstance);
  }

  public onContentReady(): void {
    const encomendas: Array<IJsonEncomenda> = this._dataGridInstance?.getDataSource()?.items();
    if (encomendas) {
      for (const item of encomendas) {
        if (this.showTextoLivre && item.textoLivre) {
          this._dataGridInstance.expandRow(item.facliId);
        } else if (this._dataGridInstance.isRowExpanded(item.facliId)) {
          this._dataGridInstance.collapseRow(item.facliId);
        }
      }
    }
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonEncomenda, number>): void {
    if (event.rowType === 'data' && event.column.dataField === 'ligacaoQtd') {
      if (event.cellElement.classList.contains(EEncomendasEstadosTableLegendColors.EncomendaParcialProcessada)) {
        event.cellElement.classList.remove(EEncomendasEstadosTableLegendColors.EncomendaParcialProcessada);
      }
      if (event.cellElement.classList.contains(EEncomendasEstadosTableLegendColors.EncomendaTotalProcessada)) {
        event.cellElement.classList.remove(EEncomendasEstadosTableLegendColors.EncomendaTotalProcessada);
      }
      if (event.cellElement.classList.contains(EEncomendasEstadosTableLegendColors.EncomendaSatisfMaisProcessada)) {
        event.cellElement.classList.remove(EEncomendasEstadosTableLegendColors.EncomendaSatisfMaisProcessada);
      }

      const qtdDisponivel = event.data.qtd1 - event.data.qtd1Fact;

      if (event.data.ligacaoQtd !== 0 && event.data.ligacaoQtd === qtdDisponivel) {
        event.cellElement.classList.add(EEncomendasEstadosTableLegendColors.EncomendaTotalProcessada);
        this._setBtnProcessarEnabled(true);
      }
      if (event.data.ligacaoQtd !== 0 && event.data.ligacaoQtd > qtdDisponivel) {
        event.cellElement.classList.add(EEncomendasEstadosTableLegendColors.EncomendaSatisfMaisProcessada);
        this._setBtnProcessarEnabled(true);
      }
      if (event.data.ligacaoQtd !== 0 && event.data.ligacaoQtd < qtdDisponivel) {
        event.cellElement.classList.add(EEncomendasEstadosTableLegendColors.EncomendaParcialProcessada);
        this._setBtnProcessarEnabled(true);
      }

      if (event.data.ligacaoQtd === 0) {
        if (this.processado && (!this.editMode || !this.header.nConta)) {
          event.cellElement.classList.add(EEncomendasEstadosTableLegendColors.EncomendaTableNotEditableCell);
        } else {
          event.cellElement.classList.add(EEncomendasEstadosTableLegendColors.EncomendaTableEditableCell);
          this._setBtnProcessarDisabledByLinesValues();
        }
      }
    }
  }

  public onRowPrepared({data, rowElement, rowType}: IDevExpressDataGridEventOnRowPrepared<IJsonEncomenda>): void {
    if (rowType === 'data') {
      let cssClass: string;
      if (data.textoLivre) {
        cssClass = EEncomendasEstadosTableLegendColors.EncomendaTextoLivre;
      }

      if (cssClass) {
        this._renderer.addClass(rowElement, cssClass);
      } else if (rowElement.style.backgroundColor) {
        this._renderer.removeClass(rowElement, cssClass);
      }
    } else if (rowType === 'detail') {
      this._renderer.addClass(rowElement, EEncomendasEstadosTableLegendColors.EncomendaTextoLivre);
    }
  }

  public onEditorStart(event: IDevExpressDataGridEventOnEditingStart<IJsonEncomenda, number>): void {
    event.component.selectRows([event.key], false);
  }

  public async onKeyDown({event, component}: IDevExpressDataGridEventOnKeyDown<IJsonEncomenda, number>): Promise<void> {
    if (this.processado || !this.editMode) {
      await Promise.resolve();
    }

    const eventKey: string = event.key;
    const keyF9: boolean = eventKey === KEYCODES.F9;
    const keyF7: boolean = eventKey === KEYCODES.F7;
    if (keyF7 || keyF9) {
      event.preventDefault();

      const encomendas: Array<IJsonEncomenda> = component.getSelectedRowsData();
      if (encomendas.length) {
        const encomenda: IJsonEncomenda = encomendas[0];

        if (keyF9) {
          this.satTotalmente(encomenda);
        }

        if (keyF7) {
          this.satLinhaSemFat(encomenda);
        }

        const itemsKeys: Array<number> = component.getSelectedRowKeys();
        const itemKey: number = itemsKeys[0];

        component
          .getDataSource()
          .store()
          .update(itemKey, encomenda)
          .then(() => {
            component.getDataSource().reload();
          });
      }
    }
  }

  public onDataGridStateLoad(event: IDevExpressDataGridState): void {
    const showColumnsConta = isEmpty(this.header?.nConta);
    this._setColumnVisible(event?.columns, 'ligacaoQtd', this.editMode);
    this._setColumnVisible(event?.columns, 'nClifo', showColumnsConta);
    this._setColumnVisible(event?.columns, 'nomeClifo', showColumnsConta);
  }

  public satTotalmente(item: IJsonEncomenda): void {
    if (this.processado || this.header.nConta === '') {
      return;
    }
    item.ligacaoQtd = item.qtd1 - item.qtd1Fact;
    this._setBtnProcessarEnabled(true);
  }

  public satTotLinhasArtigo(item: IJsonEncomenda): void {
    if (this.processado || this.header.nConta === '') {
      return;
    }
    const encomendas: Array<IJsonEncomenda> = this._dataGridInstance.getDataSource().items();
    for (const encomenda of encomendas) {
      if (encomenda.nArtigo === item.nArtigo) {
        encomenda.ligacaoQtd = encomenda.qtd1 - encomenda.qtd1Fact;
      }
    }
    this._setBtnProcessarEnabled(true);
  }

  public satTotLinhasDoc(item: IJsonEncomenda): void {
    if (this.processado || this.header.nConta === '') {
      return;
    }
    const encomendas: Array<IJsonEncomenda> = this._dataGridInstance.getDataSource().items();
    for (const encomenda of encomendas) {
      if (encomenda.nDocfa === item.nDocfa && encomenda.nNumer === item.nNumer && encomenda.nDocumento === item.nDocumento) {
        encomenda.ligacaoQtd = encomenda.qtd1 - encomenda.qtd1Fact;
      }
    }
    this._setBtnProcessarEnabled(true);
  }

  public satLinhaSemFat(item: IJsonEncomenda): void {
    if (this.processado || this.header.nConta === '') {
      return;
    }
    this._encomendasService.satisfazEncomendaSemFacturar(item.facliId.toString()).then(() => {
      this._getListEncomendas();
      this._setBtnProcessarEnabled(false);
    });
  }

  public limparQtdSat(item: IJsonEncomenda): void {
    if (this.processado || this.header.nConta === '') {
      return;
    }
    item.ligacaoQtd = 0;
    this._setBtnProcessarDisabledByLinesValues();
  }

  public limparQtdSatLinhas(): void {
    if (this.processado || this.header.nConta === '') {
      return;
    }
    const encomendas: Array<IJsonEncomenda> = this._dataGridInstance.getDataSource().items();
    for (const encomenda of encomendas) {
      encomenda.ligacaoQtd = 0;
    }
    this._setBtnProcessarEnabled(false);
  }

  public armazensChanged(armazens: Array<IJsonArmazem | string>): void {
    if (!armazens && armazens.length === 0) {
      this.header.armazens = [];
      return;
    }

    for (const armazem of armazens) {
      if (isObject(armazem)) {
        const armazemItem: IJsonArmazem = this.header.armazens.find((item: IJsonArmazem) => item.nArmazem === (<IJsonArmazem>armazem).nArmazem);
        if (!armazemItem) {
          this.header.armazens.push(<IJsonArmazem>armazem);
        }
      }
    }
  }

  public docfaListChanged(docfaList: Array<IJsonDocfa | string>): void {
    if (!docfaList || docfaList.length === 0) {
      this.header.docfaList = [];
      return;
    }

    for (const docfa of docfaList) {
      if (isObject(docfa)) {
        const docfaItem: IJsonDocfa = this.header.docfaList.find((item: IJsonDocfa) => item.nDocFa === (<IJsonDocfa>docfa).nDocFa);
        if (!docfaItem) {
          this.header.docfaList.push(<IJsonDocfa>docfa);
        }
      }
    }
  }

  public clifoChanged(item: IClifosSourceItem): void {
    if (!this._pesquisou) {
      return;
    }
    this.header.nConta = !item ? '' : item.nConta;
    this.header.nomeConta = !item ? '' : item.nome;
    this.editMode = !isEmpty(item?.nConta) && !this.processado;
    if (this.btnProcessar) {
      this.btnProcessar.disabled = true;
    }
    this._setGridColumnsVisibleOrNot(this._dataGridInstance);
    this._getListEncomendas();
  }

  public readonly fnKeydownDataPrevEntregaAte = (value: string, event: KeyboardEvent): void => {
    this._focusBtnSearch(event);
  };

  public readonly fnVerDocumento = (item: IJsonEncomenda): Promise<void> => this._verDocumento(item);

  public readonly fnSelecionarCliente = (item: IJsonEncomenda): Promise<void> => this._selecionarCliente(item);

  @ViewChild('btnActionSearch', {read: ElementRef})
  public set btnActionSearch(value: ElementRef<HTMLElement>) {
    this._btnActionSearchElement = value?.nativeElement;
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _focusBtnSearch(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      setTimeout(() => {
        focusElement(this._btnActionSearchElement);
      });
    }
  }

  private _getListEncomendas(): Promise<void> {
    let search = '';
    search += this.editMode
      ? 'nfardo=0'
      : this.header.estadoEncomendas === EEstadoEncomendas.SoPendentes
        ? 'nfardo=0'
        : this.header.estadoEncomendas === EEstadoEncomendas.SoSatisfeitas
          ? 'nfardo=-1'
          : '';

    if (this.grupoClifos) {
      if (!isEmpty(search)) {
        search += '&';
      }
      search += this.grupoClifos === 'clientes' ? `grupoDocfa=${EGrupoDoc.EncomendasClientes}` : this.grupoClifos === 'fornecedores' ? `grupoDocfa=${EGrupoDoc.EncomendasFornecedores}` : '';
    }

    if (!isEmpty(this.header.nConta)) {
      if (!isEmpty(search)) {
        search += '&';
      }
      search += `nClifo=${this.header.nConta}`;
    }

    if (isDefinedNotNull(this.header.dataDocDe)) {
      if (!isEmpty(search)) {
        search += '&';
      }
      search += `dataDoc>=${moment(this.header.dataDocDe).toISOString()}`;
    }

    if (!isDefinedNotNull(this.header.dataDocAte)) {
      if (!isEmpty(search)) {
        search += '&';
      }
      search += `dataDoc<=${moment(this.header.dataDocAte).toISOString()}`;
    }

    if (!isDefinedNotNull(this.header.dataPrevEntregaDe)) {
      if (!isEmpty(search)) {
        search += '&';
      }
      search += `dataEntregaPrev>=${moment(this.header.dataPrevEntregaDe).toISOString()}`;
    }

    if (!isDefinedNotNull(this.header.dataPrevEntregaAte)) {
      if (!isEmpty(search)) {
        search += '&';
      }
      search += `dataEntregaPrev<=${moment(this.header.dataPrevEntregaAte).toISOString()}`;
    }

    if (this.header.docfaList.length > 0) {
      let first = true;
      for (const docfa of this.header.docfaList) {
        search += first ? '&' : '|';
        search += `nDocFa=${docfa.nDocFa}`;
        first = false;
      }
    }

    if (this.header.armazens.length > 0) {
      let first = true;
      for (const armazem of this.header.armazens) {
        search += first ? '&' : '|';
        search += `nArmaz=${armazem.nArmazem}`;
        first = false;
      }
    }

    if (!isEmpty(this.header.nArtigo)) {
      if (!isEmpty(search)) {
        search += '&';
      }
      search += `nArtigo=${this.header.nArtigo}`;
    }

    const order = 'dataDoc';
    const searchfields = '';

    return this._encomendasService.pesquisar(search, order, -1, -1, searchfields).then((response: THttpQueryResponse<IJsonEncomenda>) => {
      this.dataGridDefinition.dataSource = response.body.list;
      if (isEmpty(this.header.nConta)) {
        const clifosSource: Array<IClifosSourceItem> = response.body.list.map((value: IJsonEncomenda) => {
          return {nConta: value.nClifo, nome: value.nomeClifo};
        });
        this.clifosSource = uniqBy(clifosSource, 'nConta');
      }
      this._setBtnImprimirEnabled(true);
      this._cardPanel.collapse();
    });
  }

  private _processarSatEncomNoDoc(doc: IJsonDocComercial, linhas: Array<IJsonDocSatEncomLin>, simular: boolean = false): Promise<void | IJsonDocComercial> {
    const docSatEncom: IJsonDocSatEncom = {
      cab: {
        nDocfa: doc.cab.nDocFa,
        nNumer: doc.cab.nNumer,
        nConta: doc.cab.nConta,
        dataDoc: doc.cab.dataDoc
      },
      linhas: []
    };

    for (const item of linhas) {
      if (item.ligacaoQtd !== 0) {
        docSatEncom.linhas.push(item);
      }
    }

    return this._encomendasService
      .processarSatEncomNoDoc(doc.cab.faccbId, docSatEncom, simular)
      .then((response: HttpResponse<IJsonDocComercial>) => {
        if (response.body) {
          if (!simular) {
            this.evtProcessarFaccbIdSucess.emit(response.body);
          }
          return Promise.resolve(response.body);
        }
        this.evtErrorProcessarLotes.emit();
        return Promise.reject(new Error('encomendas.messages.processamentoLotesEncomendaFalhou'));
      })
      .catch((error: unknown) => {
        this.evtErrorProcessarLotes.emit();
        if (error instanceof HttpErrorResponse) {
          const exception = this._cgExceptionService.get(error);
          if (exception?.message) {
            this._plAlertService.error(exception.message);
          }
        }
      });
  }

  private _setGridColumnsVisibleOrNot(dataGridInstance: dxDataGrid<IJsonEncomenda, number>): void {
    const showColumnsConta = isEmpty(this.header?.nConta);
    dataGridInstance.columnOption('ligacaoQtd', 'visible', this.editMode);
    dataGridInstance.columnOption('nClifo', 'visible', showColumnsConta);
    dataGridInstance.columnOption('nClifo', 'showInColumnChooser', showColumnsConta);
    dataGridInstance.columnOption('nomeClifo', 'visible', showColumnsConta);
    dataGridInstance.columnOption('nomeClifo', 'showInColumnChooser', showColumnsConta);
  }

  private _setBtnProcessarEnabled(enabled: boolean): void {
    const disabled = !enabled;

    this.gridBtnProcessarEnabled = enabled;

    if (!this.btnProcessar) {
      return;
    }
    if (this.btnProcessar.disabled !== disabled) {
      this.btnProcessar.disabled = disabled;
    }
  }

  private _setBtnProcessarDisabledByLinesValues(): void {
    const encomendas: Array<IJsonEncomenda> = this._dataGridInstance.getDataSource().items();
    const idx = encomendas.findIndex((item: IJsonEncomenda) => item.ligacaoQtd > 0);
    this.gridBtnProcessarEnabled = idx > -1;

    if (this.btnProcessar) {
      this.btnProcessar.disabled = !this.gridBtnProcessarEnabled;
    }
  }

  private _setBtnImprimirEnabled(enabled: boolean): void {
    const disabled = !enabled;
    if (!this.btnImprimir) {
      return;
    }
    if (this.btnImprimir.disabled !== disabled) {
      this.btnImprimir.disabled = disabled;
    }
  }

  private async _getListSources(): Promise<void> {
    const search =
      this.grupo === EGrupoDoc.EncomendasClientes
        ? `grupoDocfa=${EGrupoDoc.EncomendasClientes}`
        : this.grupo === EGrupoDoc.EncomendasFornecedores
          ? `grupoDocfa=${EGrupoDoc.EncomendasFornecedores}`
          : `grupoDocfa=${EGrupoDoc.EncomendasClientes}|grupoDocfa=${EGrupoDoc.EncomendasFornecedores}`;

    await this._docFaService.query({pesquisa: search}).then((response: THttpQueryResponse<IJsonDocfa>) => {
      this.docFaListSource = response.body.list;
    });

    await this._armazemService.query().then((response: THttpQueryResponse<IJsonArmazem>) => {
      this.armazensSource = response.body.list;
    });
  }

  private _processaEncomendasNoDocumento(doc: IJsonDocComercial, linhas: Array<IJsonDocSatEncomLin>): Promise<void | IJsonDocComercial> {
    if (doc.cab.faccbId === 0) {
      return this._processarSatEncomNoDoc(doc, linhas, true)
        .then((response) => {
          if (response) {
            return this._docsComerciaisService
              .post({params: {memoria: 0, terminadoc: 0, novodocsemlinhas: true}, body: doc})
              .then((responseAux: HttpResponse<IDocComercial>) => {
                if (response) {
                  this.doc = copy(responseAux.body);
                  return this._processarSatEncomNoDoc(responseAux.body, linhas);
                }
                this.evtErrorProcessarLotes.emit();
                return Promise.reject(new Error('encomendas.messages.processamentoLotesEncomendaFalhou'));
              })
              .catch((error: unknown) => {
                this.evtErrorProcessarLotes.emit();
                if (error instanceof HttpErrorResponse) {
                  const exception = this._cgExceptionService.get(error);
                  if (exception?.message) {
                    this._plAlertService.error(exception.message);
                  }
                }
                return Promise.reject(new Error('encomendas.messages.processamentoLotesEncomendaFalhou'));
              });
          }
          return Promise.reject(new Error('encomendas.messages.processamentoLotesEncomendaFalhou'));
        })
        .catch((error: unknown) => {
          this.evtErrorProcessarLotes.emit();
          if (error instanceof HttpErrorResponse) {
            const exception = this._cgExceptionService.get(error);
            if (exception?.message) {
              this._plAlertService.error(exception.message);
            }
          }
          return Promise.reject(new Error('encomendas.messages.processamentoLotesEncomendaFalhou'));
        });
    }
    return this._docsComerciaisService
      .put({id: doc.cab.faccbId, params: {terminadoc: 0}, body: doc})
      .then((response: HttpResponse<IDocComercial>) => {
        if (response) {
          this.doc = copy(response.body);
          return this._processarSatEncomNoDoc(response.body, linhas);
        }
        this.evtErrorProcessarLotes.emit();
        return Promise.reject(new Error('encomendas.messages.processamentoLotesEncomendaFalhou'));
      })
      .catch((error: unknown) => {
        this.evtErrorProcessarLotes.emit();
        if (error instanceof HttpErrorResponse) {
          const exception = this._cgExceptionService.get(error);
          if (exception?.message) {
            this._plAlertService.error(exception.message);
          }
        }
        return Promise.reject(new Error('encomendas.messages.processamentoLotesEncomendaFalhou'));
      });
  }

  private _setColumnVisible(columns: Array<IDevExpressDataGridStateColumn>, fieldName: string, visible: boolean): void {
    if (columns) {
      const idx = columns?.findIndex((column: IDevExpressDataGridStateColumn) => column.dataField === fieldName);
      if (idx > -1) {
        columns[idx].visible = visible;
      }
    }
  }

  private _selecionarCliente(item: IJsonEncomenda): Promise<void> {
    this.clifoSource = {nConta: item.nClifo, nome: item.nomeClifo};
    this.clifoChanged(this.clifoSource);
    return Promise.resolve();
  }

  private _verDocumento(item: IJsonEncomenda): Promise<void> {
    return this._docsComerciaisService.get({id: item.faccbId}).then((responseGet: HttpResponse<IJsonDocComercial>) => {
      const modalInstance = this._cgModalService.showVanilla(EncomendasDocumentoModalComponent);
      const componentInstance: EncomendasDocumentoModalComponent = modalInstance.componentInstance;
      componentInstance.doc = responseGet.body;
    });
  }
}
