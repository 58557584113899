<div class="modelo22-rosto">
  <pl-form>
    <pl-navigator contentMaxHeight="80vh" [plPromise]="promise">
      <pl-navigator-item caption="modelo22.rosto.quadro01.title">
        <div *plNavigatorItemCaption><span class="number-title-box">01</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.rosto.quadro01.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="row">
            <div class="col-md-6">
              <label [translate]="'modelo22.comum.de'" class="remove-margin-bottom"></label>
              <div class="d-flex">
                <div class="input-box-numbers">1</div>
                <pl-edit type="date" attrName="rostoQuadro1Campo1_De" [model]="rosto.quadro01.campo1_De" (modelChange)="rosto.quadro01.campo1_De = $event; emitModelo22()"></pl-edit>
              </div>
            </div>
            <div class="col-md-6">
              <label [translate]="'modelo22.comum.a'" class="remove-margin-bottom"></label>
              <div class="d-flex">
                <pl-edit type="date" attrName="rostoQuadro1Campo1_Ate" [model]="rosto.quadro01.campo1_Ate" (modelChange)="rosto.quadro01.campo1_Ate = $event; emitModelo22()"></pl-edit>
              </div>
            </div>
          </div>

          <section>
            <label [translate]="'modelo22.comum.periodo'" class="remove-margin-bottom"></label>
            <div class="d-flex">
              <div class="input-box-numbers">2</div>
              <pl-edit type="cginteger" attrName="rostoQuadro1Campo2" [model]="rosto.quadro01.campo2" (modelChange)="rosto.quadro01.campo2 = $event; emitModelo22()" [properties]="{validate: false}">
              </pl-edit>
            </div>
          </section>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.rosto.quadro02.title">
        <div *plNavigatorItemCaption><span class="number-title-box">02</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.rosto.quadro02.title'"></span></div>

        <div *plNavigatorItemContent>
          <section>
            <label [translate]="'modelo22.rosto.quadro02.campo01'" class="remove-margin-bottom"></label>
            <div class="row">
              <div class="col">
                <pl-edit type="text" attrName="rostoQuadro2sede" [model]="rosto.quadro02.cgM22IRCServFinanSede" [properties]="{disabled: true}"></pl-edit>
              </div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">1</div>
                  <pl-edit
                    type="cginteger"
                    attrName="rostoQuadro2Campo1"
                    [model]="rosto.quadro02.campo1"
                    (modelChange)="rosto.quadro02.campo1 = $event; emitModelo22()"
                    [properties]="{disabled: true}">
                  </pl-edit>
                </div>
              </div>
            </div>
          </section>

          <section>
            <label [translate]="'modelo22.rosto.quadro02.campo02'" class="remove-margin-bottom"></label>
            <div class="row">
              <div class="col">
                <pl-edit type="text" attrName="rostoQuadro2direcao" [model]="rosto.quadro02.cgM22IRCServFinanDirecao" [properties]="{disabled: true}"></pl-edit>
              </div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">2</div>
                  <pl-edit
                    type="cginteger"
                    attrName="rostoQuadro2Campo2"
                    [model]="rosto.quadro02.campo2"
                    (modelChange)="rosto.quadro02.campo2 = $event; emitModelo22()"
                    [properties]="{disabled: true}">
                  </pl-edit>
                </div>
              </div>
            </div>
          </section>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.rosto.quadro03.title">
        <div *plNavigatorItemCaption><span class="number-title-box">03</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.rosto.quadro03.title'"></span></div>

        <div *plNavigatorItemContent>
          <section class="mt-2">
            <div class="row">
              <div class="col">
                <label [translate]="'modelo22.rosto.quadro03.sujPass'" class="remove-margin-bottom"></label>
                <div class="d-flex">
                  <div class="input-box-numbers">1</div>
                  <div class="flex-grow-1">
                    <pl-edit type="text" attrName="rostoQuadro3SujPass" [model]="rosto.quadro03.cgM22IRCNomeSujPass" [properties]="{disabled: true}"></pl-edit>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <label [translate]="'modelo22.rosto.quadro03.campo02'" class="remove-margin-bottom"></label>
                <div class="d-flex">
                  <div class="input-box-numbers">2</div>
                  <pl-edit type="cginteger" attrName="rostoQuadro3Campo2" [model]="rosto.quadro03.campo2" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div class="box-title">
              <div class="input-box-numbers-title">3</div>
              <label [translate]="'modelo22.rosto.quadro03.campo03.title'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <div class="d-flex">
                <div class="input-box-numbers me-1">1</div>
                <pl-edit
                  type="radio"
                  attrName="rostoQuadro03Campo03Radio1"
                  [model]="rosto.quadro03.campo3"
                  (modelChange)="rosto.quadro03.campo3 = $event; emitModelo22()"
                  [properties]="{value: 1, label: 'modelo22.rosto.quadro03.campo03.radio1'}">
                </pl-edit>
              </div>
              <div class="d-flex">
                <div class="input-box-numbers me-1">2</div>
                <pl-edit
                  type="radio"
                  attrName="rostoQuadro03Campo03Radio2"
                  [model]="rosto.quadro03.campo3"
                  (modelChange)="rosto.quadro03.campo3 = $event; emitModelo22()"
                  [properties]="{value: 2, label: 'modelo22.rosto.quadro03.campo03.radio2'}">
                </pl-edit>
              </div>
              <div class="d-flex">
                <div class="input-box-numbers me-1">3</div>
                <pl-edit
                  type="radio"
                  attrName="rostoQuadro03Campo03Radio3"
                  [model]="rosto.quadro03.campo3"
                  (modelChange)="rosto.quadro03.campo3 = $event; emitModelo22()"
                  [properties]="{value: 3, label: 'modelo22.rosto.quadro03.campo03.radio3'}">
                </pl-edit>
              </div>
              <div class="d-flex">
                <div class="input-box-numbers me-1">4</div>
                <pl-edit
                  type="radio"
                  attrName="rostoQuadro03Campo03Radio4"
                  [model]="rosto.quadro03.campo3"
                  (modelChange)="rosto.quadro03.campo3 = $event; emitModelo22()"
                  [properties]="{value: 4, label: 'modelo22.rosto.quadro03.campo03.radio4'}">
                </pl-edit>
              </div>
            </div>
          </section>

          <section>
            <div class="box-title">
              <div class="input-box-numbers-title">3-A</div>
              <label [translate]="'modelo22.rosto.quadro03.campo3A.title'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <span [translate]="'modelo22.rosto.quadro03.campo3A.desc'" class="mb-2 subtitle-desc"></span>
              <div class="d-flex">
                <div class="input-box-numbers me-1">3</div>
                <pl-edit
                  type="radio"
                  attrName="rostoQuadro03Campo3ARadio3"
                  [model]="rosto.quadro03.campo3A"
                  (modelChange)="rosto.quadro03.campo3A = $event; emitModelo22()"
                  [properties]="{value: 3, label: 'modelo22.rosto.quadro03.campo3A.radio3'}">
                </pl-edit>
              </div>
              <div class="d-flex">
                <div class="input-box-numbers me-1">4</div>
                <pl-edit
                  type="radio"
                  attrName="rostoQuadro03Campo3ARadio4"
                  [model]="rosto.quadro03.campo3A"
                  (modelChange)="rosto.quadro03.campo3A = $event; emitModelo22()"
                  [properties]="{value: 4, label: 'modelo22.rosto.quadro03.campo3A.radio4'}">
                </pl-edit>
              </div>
              <div class="d-flex">
                <div class="input-box-numbers me-1">1</div>
                <pl-edit
                  type="radio"
                  attrName="rostoQuadro03Campo3ARadio1"
                  [model]="rosto.quadro03.campo3A"
                  (modelChange)="rosto.quadro03.campo3A = $event; emitModelo22()"
                  [properties]="{value: 1, label: 'modelo22.rosto.quadro03.campo3A.radio1'}">
                </pl-edit>
              </div>
              <div class="d-flex">
                <div class="input-box-numbers me-1">2</div>
                <pl-edit
                  type="radio"
                  attrName="rostoQuadro03Campo3ARadio2"
                  [model]="rosto.quadro03.campo3A"
                  (modelChange)="rosto.quadro03.campo3A = $event; emitModelo22()"
                  [properties]="{value: 2, label: 'modelo22.rosto.quadro03.campo3A.radio2'}">
                </pl-edit>
              </div>
              <div class="d-flex">
                <div class="input-box-numbers me-1">5</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro03Campo3ARadio5"
                  [model]="rosto.quadro03.campo3A5"
                  (modelChange)="rosto.quadro03.campo3A5 = $event; emitModelo22()"
                  [properties]="{label: 'modelo22.rosto.quadro03.campo3A.check5'}">
                </pl-edit>
              </div>
              <div class="d-flex">
                <div class="input-box-numbers me-1">6</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro03Campo3ARadio6"
                  [model]="rosto.quadro03.campo3A6"
                  (modelChange)="rosto.quadro03.campo3A6 = $event; emitModelo22()"
                  [properties]="{label: 'modelo22.rosto.quadro03.campo3A.check6'}">
                </pl-edit>
              </div>
            </div>
          </section>

          <section>
            <div class="box-title">
              <div class="input-box-numbers-title">3-B</div>
              <label [translate]="'modelo22.rosto.quadro03.campo3B.title'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <div class="d-flex">
                <div class="input-box-numbers me-1">1</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro03Campo3BField1"
                  [model]="rosto.quadro03.campo3B_1"
                  (modelChange)="rosto.quadro03.campo3B_1 = $event; emitModelo22()"
                  [properties]="{label: 'modelo22.rosto.quadro03.campo3B.field1'}">
                </pl-edit>
              </div>
            </div>
          </section>

          <section>
            <div class="box-title">
              <div class="input-box-numbers-title">3-C</div>
              <label [translate]="'modelo22.rosto.quadro03.campo3C.title'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <span [translate]="'modelo22.rosto.quadro03.campo3C.desc'" class="mb-2"></span>
              <div class="d-flex">
                <div class="input-box-numbers me-1">1</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro03Campo3C_1"
                  [model]="rosto.quadro03.campo3C_1"
                  (modelChange)="rosto.quadro03.campo3C_1 = $event; emitModelo22()"
                  [properties]="{label: 'modelo22.rosto.quadro03.campo3C.field1'}">
                </pl-edit>
              </div>
            </div>
          </section>

          <section>
            <div class="box-title">
              <div class="input-box-numbers-title">4</div>
              <label [translate]="'modelo22.rosto.quadro03.campo4.title'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 input-box-number-seperator">
              <div class="d-flex">
                <div class="input-box-numbers me-1">1</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro03Campo4Field1"
                  [model]="rostoQuadro3Campo4Check1"
                  (modelChange)="rostoQuadro3Campo4CheckChanged(1, $event)"
                  [properties]="{label: 'modelo22.rosto.quadro03.campo4.field1'}">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">3</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro03Campo4Field3"
                  [model]="rostoQuadro3Campo4Check3"
                  (modelChange)="rostoQuadro3Campo4CheckChanged(3, $event)"
                  [properties]="{label: 'modelo22.rosto.quadro03.campo4.field3'}">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">4</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro03Campo4Field4"
                  [model]="rostoQuadro3Campo4Check4"
                  (modelChange)="rostoQuadro3Campo4CheckChanged(4, $event)"
                  [properties]="{label: 'modelo22.rosto.quadro03.campo4.field4'}">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">5</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro03Campo4Field5"
                  [model]="rostoQuadro3Campo4Check5"
                  (modelChange)="rostoQuadro3Campo4CheckChanged(5, $event)"
                  [properties]="{label: 'modelo22.rosto.quadro03.campo4.field5'}">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">6</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro03Campo4Field6"
                  [model]="rostoQuadro3Campo4Check6"
                  (modelChange)="rostoQuadro3Campo4CheckChanged(6, $event)"
                  [properties]="{label: 'modelo22.rosto.quadro03.campo4.field6'}">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">7</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro03Campo4Field7"
                  [model]="rostoQuadro3Campo4Check7"
                  (modelChange)="rostoQuadro3Campo4CheckChanged(7, $event)"
                  [properties]="{label: 'modelo22.rosto.quadro03.campo4.field7'}">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">8</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro03Campo4Field8"
                  [model]="rostoQuadro3Campo4Check8"
                  (modelChange)="rostoQuadro3Campo4CheckChanged(8, $event)"
                  [properties]="{label: 'modelo22.rosto.quadro03.campo4.field8'}">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">9</div>
                <pl-edit
                  type="cginteger"
                  attrName="rostoQuadro03Campo4Field9"
                  [model]="rosto.quadro03.campo4_9"
                  (modelChange)="rosto.quadro03.campo4_9 = $event; emitModelo22()"
                  [properties]="{validate: false}">
                </pl-edit>
                <span [translate]="'modelo22.rosto.quadro03.campo4.field9'" class="input-inline-text"></span>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">10</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro03Campo4Field10"
                  [model]="rosto.quadro03.campo4_10"
                  (modelChange)="rosto.quadro03.campo4_10 = $event; emitModelo22()"
                  [properties]="{label: 'modelo22.rosto.quadro03.campo4.field10'}">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">11</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro03Campo4Field11"
                  [model]="rosto.quadro03.campo4_11"
                  (modelChange)="rosto.quadro03.campo4_11 = $event; emitModelo22()"
                  [properties]="{label: 'modelo22.rosto.quadro03.campo4.field11'}">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">12</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro03Campo4Field12"
                  [model]="rosto.quadro03.campo4_12"
                  (modelChange)="rosto.quadro03.campo4_12 = $event; emitModelo22()"
                  [properties]="{label: 'modelo22.rosto.quadro03.campo4.field12'}">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">13</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro03Campo4Field13"
                  [model]="rosto.quadro03.campo4_13"
                  (modelChange)="rosto.quadro03.campo4_13 = $event; emitModelo22()"
                  [properties]="{label: 'modelo22.rosto.quadro03.campo4.field13'}">
                </pl-edit>
              </div>
            </div>
          </section>

          <section>
            <div class="box-title">
              <div class="input-box-numbers-title">4-A</div>
              <label [translate]="'modelo22.rosto.quadro03.campo4A.title'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <span [translate]="'modelo22.rosto.quadro03.campo4A.desc'" class="mb-2 subtitle-desc"></span>
              <div class="d-flex">
                <div class="input-box-numbers me-1">1</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro03Campo4AField1"
                  [model]="rostoQuadro3Campo4ACheck1"
                  (modelChange)="rostoQuadro3Campo4ACheckChanged(1, $event)"
                  [properties]="{label: 'modelo22.rosto.quadro03.campo4A.field1'}">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">2</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro03Campo4AField2"
                  [model]="rostoQuadro3Campo4ACheck2"
                  (modelChange)="rostoQuadro3Campo4ACheckChanged(2, $event)"
                  [properties]="{label: 'modelo22.rosto.quadro03.campo4A.field2'}">
                </pl-edit>
              </div>
            </div>
          </section>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.rosto.quadro04.title">
        <div *plNavigatorItemCaption><span class="number-title-box">04</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.rosto.quadro04.title'"></span></div>

        <div *plNavigatorItemContent>
          <section>
            <div class="box-title">
              <div class="input-box-numbers-title">1</div>
              <label [translate]="'modelo22.rosto.quadro04.campo1.title'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <div class="d-flex">
                <div class="input-box-numbers me-1">1</div>
                <pl-edit
                  type="radio"
                  attrName="rostoQuadro04campo1Radio1"
                  [model]="rosto.quadro04.campo1"
                  (modelChange)="rosto.quadro04.campo1 = $event; emitModelo22()"
                  [properties]="{value: 1, label: 'modelo22.rosto.quadro04.campo1.radio1'}">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">2</div>
                <pl-edit
                  type="radio"
                  attrName="rostoQuadro04campo1Radio2"
                  [model]="rosto.quadro04.campo1"
                  (modelChange)="rosto.quadro04.campo1 = $event; emitModelo22()"
                  [properties]="{value: 2, label: 'modelo22.rosto.quadro04.campo1.radio2'}">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">3</div>
                <pl-edit
                  type="radio"
                  attrName="rostoQuadro04campo1Radio3"
                  [model]="rosto.quadro04.campo1"
                  (modelChange)="rosto.quadro04.campo1 = $event; emitModelo22()"
                  [properties]="{value: 3, label: 'modelo22.rosto.quadro04.campo1.radio3'}">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">4</div>
                <pl-edit
                  type="radio"
                  attrName="rostoQuadro04campo1Radio4"
                  [model]="rosto.quadro04.campo1"
                  (modelChange)="rosto.quadro04.campo1 = $event; emitModelo22()"
                  [properties]="{value: 4, label: 'modelo22.rosto.quadro04.campo1.radio4'}">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">5</div>
                <pl-edit
                  type="radio"
                  attrName="rostoQuadro04campo1Radio5"
                  [model]="rosto.quadro04.campo1"
                  (modelChange)="rosto.quadro04.campo1 = $event; emitModelo22()"
                  [properties]="{value: 5, label: 'modelo22.rosto.quadro04.campo1.radio5'}">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="flex-grow-1">
                  <div class="d-flex">
                    <div class="input-box-numbers me-1">6</div>
                    <pl-edit
                      type="radio"
                      attrName="rostoQuadro04campo1Radio6"
                      [model]="rosto.quadro04.campo1"
                      (modelChange)="rosto.quadro04.campo1 = $event; emitModelo22()"
                      [properties]="{value: 6, label: 'modelo22.rosto.quadro04.campo1.radio6'}">
                    </pl-edit>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <label [translate]="'modelo22.rosto.quadro04.campo1.data'"></label>
                  <pl-edit
                    type="date"
                    attrName="rostoQuadro04campo1Data"
                    [model]="rosto.quadro04.campo1_Data"
                    (modelChange)="rosto.quadro04.campo1_Data = $event; emitModelo22()"
                    [properties]="{disabled: rosto.quadro04.campo1 !== 6}">
                  </pl-edit>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div class="box-title">
              <div class="input-box-numbers-title">2</div>
              <label [translate]="'modelo22.rosto.quadro04.campo2.title'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 input-box-number-seperator">
              <div class="d-flex">
                <div class="input-box-numbers me-1">1</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro04campo2Check1"
                  [model]="rostoQuadro4Campo2Check1"
                  (modelChange)="rostoQuadro4Campo2Check1a7Changed(1, $event)"
                  [properties]="{label: 'modelo22.rosto.quadro04.campo2.check1', disabled: rostoQuadro4Campo2Check2}">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">2</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro04campo2Check2"
                  [model]="rostoQuadro4Campo2Check2"
                  (modelChange)="rostoQuadro4Campo2Check1a7Changed(2, $event)"
                  [properties]="{
                    label: 'modelo22.rosto.quadro04.campo2.check2',
                    disabled: rostoQuadro4Campo2Check1 || rostoQuadro4Campo2Check3 || rostoQuadro4Campo2Check4 || rostoQuadro4Campo2Check5 || rostoQuadro4Campo2Check7
                  }">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">3</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro04campo2Check3"
                  [model]="rostoQuadro4Campo2Check3"
                  (modelChange)="rostoQuadro4Campo2Check1a7Changed(3, $event)"
                  [properties]="{label: 'modelo22.rosto.quadro04.campo2.check3', disabled: rostoQuadro4Campo2Check2}">
                </pl-edit>
              </div>

              <label [translate]="'modelo22.rosto.quadro04.campo2.data6'"></label>
              <div class="d-flex">
                <div class="input-box-numbers me-1">6</div>
                <pl-edit
                  type="date"
                  attrName="rostoQuadro04campo2Data6"
                  [model]="rosto.quadro04.campo2_6"
                  (modelChange)="rosto.quadro04.campo2_6 = $event; emitModelo22()"
                  [properties]="{disabled: !rostoQuadro4Campo2Check3}">
                </pl-edit>
              </div>

              <label [translate]="'modelo22.rosto.quadro04.campo2.subtitle1'"></label>
              <div class="d-flex">
                <div class="input-box-numbers me-1">4</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro04campo2Check4"
                  [model]="rostoQuadro4Campo2Check4"
                  (modelChange)="rostoQuadro4Campo2Check1a7Changed(4, $event)"
                  [properties]="{label: 'modelo22.rosto.quadro04.campo2.check4', disabled: rostoQuadro4Campo2Check2 || rostoQuadro4Campo2Check5}">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">5</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro04campo2Check5"
                  [model]="rostoQuadro4Campo2Check5"
                  (modelChange)="rostoQuadro4Campo2Check1a7Changed(5, $event)"
                  [properties]="{label: 'modelo22.rosto.quadro04.campo2.check5', disabled: rostoQuadro4Campo2Check2 || rostoQuadro4Campo2Check4}">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">7</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro04campo2Check7"
                  [model]="rostoQuadro4Campo2Check7"
                  (modelChange)="rostoQuadro4Campo2Check1a7Changed(7, $event)"
                  [properties]="{label: 'modelo22.rosto.quadro04.campo2.check7', disabled: rostoQuadro4Campo2Check2}">
                </pl-edit>
              </div>

              <label [translate]="'modelo22.rosto.quadro04.campo2.data8'"></label>
              <div class="d-flex">
                <div class="input-box-numbers me-1">8</div>
                <pl-edit type="date" attrName="rostoQuadro04campo2Data8" [model]="rosto.quadro04.campo2_8" (modelChange)="rosto.quadro04.campo2_8 = $event; emitModelo22()"></pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">9</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro04campo2Check9"
                  [model]="rostoQuadro4Campo2Check9"
                  (modelChange)="rostoQuadro4Campo2Check9e10Changed($event, 9)"
                  [properties]="{label: 'modelo22.rosto.quadro04.campo2.check9', disabled: rostoQuadro4Campo2Check10}">
                </pl-edit>
              </div>

              <div class="d-flex">
                <div class="input-box-numbers me-1">10</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro04campo2Check10"
                  [model]="rostoQuadro4Campo2Check10"
                  (modelChange)="rostoQuadro4Campo2Check9e10Changed($event, 10)"
                  [properties]="{label: 'modelo22.rosto.quadro04.campo2.check10', disabled: rostoQuadro4Campo2Check9}">
                </pl-edit>
              </div>

              <label [translate]="'modelo22.rosto.quadro04.campo2.data11'"></label>
              <div class="d-flex">
                <div class="input-box-numbers me-1">11</div>
                <pl-edit type="date" attrName="rostoQuadro04campo2Data11" [model]="rosto.quadro04.campo2_11" (modelChange)="rosto.quadro04.campo2_11 = $event; emitModelo22()"></pl-edit>
              </div>
            </div>
          </section>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.rosto.quadro05.title">
        <div *plNavigatorItemCaption><span class="number-title-box">05</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.rosto.quadro05.title'"></span></div>

        <div *plNavigatorItemContent>
          <section>
            <label [translate]="'modelo22.rosto.quadro05.campo1'" class="remove-margin-bottom"></label>
            <div class="d-flex">
              <div class="input-box-numbers">1</div>
              <pl-edit type="cginteger" attrName="rostoQuadro05Campo1" [model]="rosto.quadro05.campo1" (modelChange)="rosto.quadro05.campo1 = $event; emitModelo22()" [properties]="{validate: false}">
              </pl-edit>
            </div>
          </section>

          <section>
            <label [translate]="'modelo22.rosto.quadro05.campo2'" class="remove-margin-bottom"></label>
            <div class="d-flex">
              <div class="input-box-numbers">2</div>
              <pl-edit type="cginteger" attrName="rostoQuadro05Campo2" [model]="rosto.quadro05.campo2" (modelChange)="rosto.quadro05.campo2 = $event; emitModelo22()" [properties]="{validate: false}">
              </pl-edit>
            </div>
          </section>

          <span [translate]="'modelo22.rosto.quadro05.desc'" class="mb-2 mt-2 subtitle-desc"></span>
          <section>
            <label [translate]="'modelo22.rosto.quadro05.campo4'" class="remove-margin-bottom"></label>
            <div class="d-flex pledit-half-with">
              <div class="input-box-numbers">4</div>
              <pl-edit type="modelo22factoimpedimento" attrName="rostoQuadro05Campo4" [model]="rosto.quadro05.campo4" (modelChange)="rosto.quadro05.campo4 = $event; emitModelo22()"></pl-edit>
            </div>
          </section>

          <section>
            <label [translate]="'modelo22.rosto.quadro05.campo5'" class="remove-margin-bottom"></label>
            <div class="d-flex">
              <div class="input-box-numbers">5</div>
              <pl-edit type="date" attrName="rostoQuadro05Campo5" [model]="rosto.quadro05.campo5" (modelChange)="rosto.quadro05.campo5 = $event; emitModelo22()"></pl-edit>
            </div>
          </section>

          <section>
            <label [translate]="'modelo22.rosto.quadro05.campo6'" class="remove-margin-bottom"></label>
            <div class="d-flex">
              <div class="input-box-numbers">6</div>
              <pl-edit type="date" attrName="rostoQuadro05Campo6" [model]="rosto.quadro05.campo6" (modelChange)="rosto.quadro05.campo6 = $event; emitModelo22()"></pl-edit>
            </div>
          </section>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.rosto.quadro07.title">
        <div *plNavigatorItemCaption><span class="number-title-box">07</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.rosto.quadro07.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline">
            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo701'"
              [mod22Field]="'701'"
              [mod22AttrName]="'rostoQuadro07Campo701'"
              [mod22FieldValue]="rosto.quadro07.campo701"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo701 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo702'"
              [mod22Field]="'702'"
              [mod22AttrName]="'rostoQuadro07Campo702'"
              [mod22FieldValue]="rosto.quadro07.campo702"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo702 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo703'"
              [mod22Field]="'703'"
              [mod22AttrName]="'rostoQuadro07Campo703'"
              [mod22FieldValue]="rosto.quadro07.campo703"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo703 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo704'"
              [mod22Field]="'704'"
              [mod22AttrName]="'rostoQuadro07Campo704'"
              [mod22FieldValue]="rosto.quadro07.campo704"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo704 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo705'"
              [mod22Field]="'705'"
              [mod22AttrName]="'rostoQuadro07Campo705'"
              [mod22FieldValue]="rosto.quadro07.campo705"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo705 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo706'"
              [mod22Field]="'706'"
              [mod22AttrName]="'rostoQuadro07Campo706'"
              [mod22FieldValue]="rosto.quadro07.campo706"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo706 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo707'"
              [mod22Field]="'707'"
              [mod22AttrName]="'rostoQuadro07Campo707'"
              [mod22FieldValue]="rosto.quadro07.campo707"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo707 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro07.campo708'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">708</div>
                  <pl-edit type="currency" attrName="rostoQuadro07Campo708" [model]="rosto.quadro07.campo708" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.rosto.quadro07.acrescer'"></span></div>
            <hr />

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo709'"
              [mod22Field]="'709'"
              [mod22AttrName]="'rostoQuadro07Campo709'"
              [mod22FieldValue]="rosto.quadro07.campo709"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo709 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo710'"
              [mod22Field]="'710'"
              [mod22AttrName]="'rostoQuadro07Campo710'"
              [mod22FieldValue]="rosto.quadro07.campo710"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo710 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo711'"
              [mod22Field]="'711'"
              [mod22AttrName]="'rostoQuadro07Campo711'"
              [mod22FieldValue]="rosto.quadro07.campo711"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo711 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo782'"
              [mod22Field]="'782'"
              [mod22AttrName]="'rostoQuadro07Campo782'"
              [mod22FieldValue]="rosto.quadro07.campo782"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo782 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo712'"
              [mod22Field]="'712'"
              [mod22AttrName]="'rostoQuadro07Campo712'"
              [mod22FieldValue]="rosto.quadro07.campo712"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo712 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo713'"
              [mod22Field]="'713'"
              [mod22AttrName]="'rostoQuadro07Campo713'"
              [mod22FieldValue]="rosto.quadro07.campo713"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo713 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo714'"
              [mod22Field]="'714'"
              [mod22AttrName]="'rostoQuadro07Campo714'"
              [mod22FieldValue]="rosto.quadro07.campo714"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo714 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo715'"
              [mod22Field]="'715'"
              [mod22AttrName]="'rostoQuadro07Campo715'"
              [mod22FieldValue]="rosto.quadro07.campo715"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo715 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo717'"
              [mod22Field]="'717'"
              [mod22AttrName]="'rostoQuadro07Campo717'"
              [mod22FieldValue]="rosto.quadro07.campo717"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo717 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo721'"
              [mod22Field]="'721'"
              [mod22AttrName]="'rostoQuadro07Campo721'"
              [mod22FieldValue]="rosto.quadro07.campo721"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo721 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo724'"
              [mod22Field]="'724'"
              [mod22AttrName]="'rostoQuadro07Campo724'"
              [mod22FieldValue]="rosto.quadro07.campo724"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo724 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo725'"
              [mod22Field]="'725'"
              [mod22AttrName]="'rostoQuadro07Campo725'"
              [mod22FieldValue]="rosto.quadro07.campo725"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo725 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo716'"
              [mod22Field]="'716'"
              [mod22AttrName]="'rostoQuadro07Campo716'"
              [mod22FieldValue]="rosto.quadro07.campo716"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo716 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo731'"
              [mod22Field]="'731'"
              [mod22AttrName]="'rostoQuadro07Campo731'"
              [mod22FieldValue]="rosto.quadro07.campo731"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo731 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo726'"
              [mod22Field]="'726'"
              [mod22AttrName]="'rostoQuadro07Campo726'"
              [mod22FieldValue]="rosto.quadro07.campo726"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo726 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo783'"
              [mod22Field]="'783'"
              [mod22AttrName]="'rostoQuadro07Campo783'"
              [mod22FieldValue]="rosto.quadro07.campo783"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo783 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo728'"
              [mod22Field]="'728'"
              [mod22AttrName]="'rostoQuadro07Campo728'"
              [mod22FieldValue]="rosto.quadro07.campo728"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo728 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo727'"
              [mod22Field]="'727'"
              [mod22AttrName]="'rostoQuadro07Campo727'"
              [mod22FieldValue]="rosto.quadro07.campo727"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo727 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo729'"
              [mod22Field]="'729'"
              [mod22AttrName]="'rostoQuadro07Campo729'"
              [mod22FieldValue]="rosto.quadro07.campo729"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo729 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo730'"
              [mod22Field]="'730'"
              [mod22AttrName]="'rostoQuadro07Campo730'"
              [mod22FieldValue]="rosto.quadro07.campo730"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo730 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo732'"
              [mod22Field]="'732'"
              [mod22AttrName]="'rostoQuadro07Campo732'"
              [mod22FieldValue]="rosto.quadro07.campo732"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo732 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo733'"
              [mod22Field]="'733'"
              [mod22AttrName]="'rostoQuadro07Campo733'"
              [mod22FieldValue]="rosto.quadro07.campo733"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo733 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo784'"
              [mod22Field]="'784'"
              [mod22AttrName]="'rostoQuadro07Campo784'"
              [mod22FieldValue]="rosto.quadro07.campo784"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo784 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo734'"
              [mod22Field]="'734'"
              [mod22AttrName]="'rostoQuadro07Campo734'"
              [mod22FieldValue]="rosto.quadro07.campo734"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo734 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo735'"
              [mod22Field]="'735'"
              [mod22AttrName]="'rostoQuadro07Campo735'"
              [mod22FieldValue]="rosto.quadro07.campo735"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo735 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo780'"
              [mod22Field]="'780'"
              [mod22AttrName]="'rostoQuadro07Campo780'"
              [mod22FieldValue]="rosto.quadro07.campo780"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo780 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo785'"
              [mod22Field]="'785'"
              [mod22AttrName]="'rostoQuadro07Campo785'"
              [mod22FieldValue]="rosto.quadro07.campo785"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo785 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo802'"
              [mod22Field]="'802'"
              [mod22AttrName]="'rostoQuadro07Campo802'"
              [mod22FieldValue]="rosto.quadro07.campo802"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo802 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo746'"
              [mod22Field]="'746'"
              [mod22AttrName]="'rostoQuadro07Campo746'"
              [mod22FieldValue]="rosto.quadro07.campo746"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo746 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo737'"
              [mod22Field]="'737'"
              [mod22AttrName]="'rostoQuadro07Campo737'"
              [mod22FieldValue]="rosto.quadro07.campo737"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo737 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo786'"
              [mod22Field]="'786'"
              [mod22AttrName]="'rostoQuadro07Campo786'"
              [mod22FieldValue]="rosto.quadro07.campo786"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo786 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo718'"
              [mod22Field]="'718'"
              [mod22AttrName]="'rostoQuadro07Campo718'"
              [mod22FieldValue]="rosto.quadro07.campo718"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo718 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo719'"
              [mod22Field]="'719'"
              [mod22AttrName]="'rostoQuadro07Campo719'"
              [mod22FieldValue]="rosto.quadro07.campo719"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo719 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo720'"
              [mod22Field]="'720'"
              [mod22AttrName]="'rostoQuadro07Campo720'"
              [mod22FieldValue]="rosto.quadro07.campo720"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo720 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo722'"
              [mod22Field]="'722'"
              [mod22AttrName]="'rostoQuadro07Campo722'"
              [mod22FieldValue]="rosto.quadro07.campo722"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo722 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo723'"
              [mod22Field]="'723'"
              [mod22AttrName]="'rostoQuadro07Campo723'"
              [mod22FieldValue]="rosto.quadro07.campo723"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo723 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo736'"
              [mod22Field]="'736'"
              [mod22AttrName]="'rostoQuadro07Campo736'"
              [mod22FieldValue]="rosto.quadro07.campo736"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo736 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo738'"
              [mod22Field]="'738'"
              [mod22AttrName]="'rostoQuadro07Campo738'"
              [mod22FieldValue]="rosto.quadro07.campo738"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo738 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo739'"
              [mod22Field]="'739'"
              [mod22AttrName]="'rostoQuadro07Campo739'"
              [mod22FieldValue]="rosto.quadro07.campo739"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo739 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo740'"
              [mod22Field]="'740'"
              [mod22AttrName]="'rostoQuadro07Campo740'"
              [mod22FieldValue]="rosto.quadro07.campo740"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo740 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo741'"
              [mod22Field]="'741'"
              [mod22AttrName]="'rostoQuadro07Campo741'"
              [mod22FieldValue]="rosto.quadro07.campo741"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo741 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo742'"
              [mod22Field]="'742'"
              [mod22AttrName]="'rostoQuadro07Campo742'"
              [mod22FieldValue]="rosto.quadro07.campo742"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo742 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo743'"
              [mod22Field]="'743'"
              [mod22AttrName]="'rostoQuadro07Campo743'"
              [mod22FieldValue]="rosto.quadro07.campo743"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo743 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo787'"
              [mod22Field]="'787'"
              [mod22AttrName]="'rostoQuadro07Campo787'"
              [mod22FieldValue]="rosto.quadro07.campo787"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo787 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo744'"
              [mod22Field]="'744'"
              [mod22AttrName]="'rostoQuadro07Campo744'"
              [mod22FieldValue]="rosto.quadro07.campo744"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo744 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo745'"
              [mod22Field]="'745'"
              [mod22AttrName]="'rostoQuadro07Campo745'"
              [mod22FieldValue]="rosto.quadro07.campo745"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo745 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo747'"
              [mod22Field]="'747'"
              [mod22AttrName]="'rostoQuadro07Campo747'"
              [mod22FieldValue]="rosto.quadro07.campo747"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo747 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo748'"
              [mod22Field]="'748'"
              [mod22AttrName]="'rostoQuadro07Campo748'"
              [mod22FieldValue]="rosto.quadro07.campo748"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo748 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo749'"
              [mod22Field]="'749'"
              [mod22AttrName]="'rostoQuadro07Campo749'"
              [mod22FieldValue]="rosto.quadro07.campo749"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo749 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo788'"
              [mod22Field]="'788'"
              [mod22AttrName]="'rostoQuadro07Campo788'"
              [mod22FieldValue]="rosto.quadro07.campo788"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo788 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo750'"
              [mod22Field]="'750'"
              [mod22AttrName]="'rostoQuadro07Campo750'"
              [mod22FieldValue]="rosto.quadro07.campo750"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo750 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo789'"
              [mod22Field]="'789'"
              [mod22AttrName]="'rostoQuadro07Campo789'"
              [mod22FieldValue]="rosto.quadro07.campo789"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo789 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo790'"
              [mod22Field]="'790'"
              [mod22AttrName]="'rostoQuadro07Campo790'"
              [mod22FieldValue]="rosto.quadro07.campo790"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo790 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo751'"
              [mod22Field]="'751'"
              [mod22AttrName]="'rostoQuadro07Campo751'"
              [mod22FieldValue]="rosto.quadro07.campo751"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo751 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo803'"
              [mod22Field]="'803'"
              [mod22AttrName]="'rostoQuadro07Campo803'"
              [mod22FieldValue]="rosto.quadro07.campo803"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo803 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo779'"
              [mod22Field]="'779'"
              [mod22AttrName]="'rostoQuadro07Campo779'"
              [mod22FieldValue]="rosto.quadro07.campo779"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo779 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo797'"
              [mod22Field]="'797'"
              [mod22AttrName]="'rostoQuadro07Campo797'"
              [mod22FieldValue]="rosto.quadro07.campo797"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo797 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo799'"
              [mod22Field]="'799'"
              [mod22AttrName]="'rostoQuadro07Campo799'"
              [mod22FieldValue]="rosto.quadro07.campo799"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo799 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo804'"
              [mod22Field]="'804'"
              [mod22AttrName]="'rostoQuadro07Campo804'"
              [mod22FieldValue]="rosto.quadro07.campo804"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo804 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo752'"
              [mod22Field]="'752'"
              [mod22AttrName]="'rostoQuadro07Campo752'"
              [mod22FieldValue]="rosto.quadro07.campo752"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo752 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro07.campo753'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">753</div>
                  <pl-edit type="currency" attrName="rostoQuadro07Campo753" [model]="rosto.quadro07.campo753" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.rosto.quadro07.deduzir'"></span></div>
            <hr />

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo754'"
              [mod22Field]="'754'"
              [mod22AttrName]="'rostoQuadro07Campo754'"
              [mod22FieldValue]="rosto.quadro07.campo754"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo754 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo755'"
              [mod22Field]="'755'"
              [mod22AttrName]="'rostoQuadro07Campo755'"
              [mod22FieldValue]="rosto.quadro07.campo755"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo755 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo756'"
              [mod22Field]="'756'"
              [mod22AttrName]="'rostoQuadro07Campo756'"
              [mod22FieldValue]="rosto.quadro07.campo756"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo756 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo757'"
              [mod22Field]="'757'"
              [mod22AttrName]="'rostoQuadro07Campo757'"
              [mod22FieldValue]="rosto.quadro07.campo757"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo757 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo791'"
              [mod22Field]="'791'"
              [mod22AttrName]="'rostoQuadro07Campo791'"
              [mod22FieldValue]="rosto.quadro07.campo791"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo791 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo758'"
              [mod22Field]="'758'"
              [mod22AttrName]="'rostoQuadro07Campo758'"
              [mod22FieldValue]="rosto.quadro07.campo758"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo758 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo759'"
              [mod22Field]="'759'"
              [mod22AttrName]="'rostoQuadro07Campo759'"
              [mod22FieldValue]="rosto.quadro07.campo759"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo759 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo760'"
              [mod22Field]="'760'"
              [mod22AttrName]="'rostoQuadro07Campo760'"
              [mod22FieldValue]="rosto.quadro07.campo760"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo760 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo761'"
              [mod22Field]="'761'"
              [mod22AttrName]="'rostoQuadro07Campo761'"
              [mod22FieldValue]="rosto.quadro07.campo761"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo761 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo762'"
              [mod22Field]="'762'"
              [mod22AttrName]="'rostoQuadro07Campo762'"
              [mod22FieldValue]="rosto.quadro07.campo762"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo762 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo763'"
              [mod22Field]="'763'"
              [mod22AttrName]="'rostoQuadro07Campo763'"
              [mod22FieldValue]="rosto.quadro07.campo763"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo763 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo781'"
              [mod22Field]="'781'"
              [mod22AttrName]="'rostoQuadro07Campo781'"
              [mod22FieldValue]="rosto.quadro07.campo781"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo781 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo764'"
              [mod22Field]="'764'"
              [mod22AttrName]="'rostoQuadro07Campo764'"
              [mod22FieldValue]="rosto.quadro07.campo764"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo764 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo765'"
              [mod22Field]="'765'"
              [mod22AttrName]="'rostoQuadro07Campo765'"
              [mod22FieldValue]="rosto.quadro07.campo765"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo765 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo766'"
              [mod22Field]="'766'"
              [mod22AttrName]="'rostoQuadro07Campo766'"
              [mod22FieldValue]="rosto.quadro07.campo766"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo766 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo792'"
              [mod22Field]="'792'"
              [mod22AttrName]="'rostoQuadro07Campo792'"
              [mod22FieldValue]="rosto.quadro07.campo792"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo792 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo767'"
              [mod22Field]="'767'"
              [mod22AttrName]="'rostoQuadro07Campo767'"
              [mod22FieldValue]="rosto.quadro07.campo767"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo767 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo768'"
              [mod22Field]="'768'"
              [mod22AttrName]="'rostoQuadro07Campo768'"
              [mod22FieldValue]="rosto.quadro07.campo768"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo768 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo769'"
              [mod22Field]="'769'"
              [mod22AttrName]="'rostoQuadro07Campo769'"
              [mod22FieldValue]="rosto.quadro07.campo769"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo769 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo770'"
              [mod22Field]="'770'"
              [mod22AttrName]="'rostoQuadro07Campo770'"
              [mod22FieldValue]="rosto.quadro07.campo770"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo770 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo793'"
              [mod22Field]="'793'"
              [mod22AttrName]="'rostoQuadro07Campo793'"
              [mod22FieldValue]="rosto.quadro07.campo793"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo793 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo771'"
              [mod22Field]="'771'"
              [mod22AttrName]="'rostoQuadro07Campo771'"
              [mod22FieldValue]="rosto.quadro07.campo771"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo771 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo794'"
              [mod22Field]="'794'"
              [mod22AttrName]="'rostoQuadro07Campo794'"
              [mod22FieldValue]="rosto.quadro07.campo794"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo794 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo772'"
              [mod22Field]="'772'"
              [mod22AttrName]="'rostoQuadro07Campo772'"
              [mod22FieldValue]="rosto.quadro07.campo772"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo772 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo795'"
              [mod22Field]="'795'"
              [mod22AttrName]="'rostoQuadro07Campo795'"
              [mod22FieldValue]="rosto.quadro07.campo795"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo795 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo773'"
              [mod22Field]="'773'"
              [mod22AttrName]="'rostoQuadro07Campo773'"
              [mod22FieldValue]="rosto.quadro07.campo773"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo773 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo796'"
              [mod22Field]="'796'"
              [mod22AttrName]="'rostoQuadro07Campo796'"
              [mod22FieldValue]="rosto.quadro07.campo796"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo796 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo774'"
              [mod22Field]="'774'"
              [mod22AttrName]="'rostoQuadro07Campo774'"
              [mod22FieldValue]="rosto.quadro07.campo774"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo774 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo800'"
              [mod22Field]="'800'"
              [mod22AttrName]="'rostoQuadro07Campo800'"
              [mod22FieldValue]="rosto.quadro07.campo800"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo800 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo801'"
              [mod22Field]="'801'"
              [mod22AttrName]="'rostoQuadro07Campo801'"
              [mod22FieldValue]="rosto.quadro07.campo801"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo801 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo798'"
              [mod22Field]="'798'"
              [mod22AttrName]="'rostoQuadro07Campo798'"
              [mod22FieldValue]="rosto.quadro07.campo798"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo798 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <modelo22-inputs-currency-inline
              [mod22Translate]="'modelo22.rosto.quadro07.campo775'"
              [mod22Field]="'775'"
              [mod22AttrName]="'rostoQuadro07Campo775'"
              [mod22FieldValue]="rosto.quadro07.campo775"
              [titleContasDef]="titleContasDef"
              [classBoxNumbers]="'input-box-numbers-hundred'"
              (evtChangedFieldValue)="rosto.quadro07.campo775 = $event; emitModelo22(true)"
              (evtOpenContasDef)="openContasDef($event)">
            </modelo22-inputs-currency-inline>

            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro07.campo776'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">776</div>
                  <pl-edit type="currency" attrName="rostoQuadro07Campo776" [model]="rosto.quadro07.campo776" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro07.campo777'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">777</div>
                  <pl-edit type="currency" attrName="rostoQuadro07Campo777" [model]="rosto.quadro07.campo777" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro07.campo778'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">778</div>
                  <pl-edit type="currency" attrName="rostoQuadro07Campo778" [model]="rosto.quadro07.campo778" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.rosto.quadro08.title">
        <div *plNavigatorItemCaption><span class="number-title-box">08</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.rosto.quadro08.title'"></span></div>

        <div *plNavigatorItemContent>
          <section>
            <div class="row">
              <div class="col-md-9">
                <div class="box-title">
                  <div class="input-box-numbers-title">08.1</div>
                  <label [translate]="'modelo22.rosto.quadro08.sect81.title'" class="input-box-numbers-label"></label>
                </div>
              </div>
              <div class="col-md-3">
                <label [translate]="'modelo22.rosto.quadro08.sect81.taxas'" class="input-box-numbers-label"></label>
              </div>
            </div>

            <div class="ms-3 input-box-number-seperator">
              <div class="row">
                <div class="col-md-9">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred me-1">242</div>
                    <pl-edit
                      type="checkbox"
                      attrName="rostoQuadro08Campo242"
                      [model]="rosto.quadro08.campo242"
                      (modelChange)="rosto.quadro08.campo242 = $event; emitModelo22()"
                      [properties]="{label: 'modelo22.rosto.quadro08.sect81.check242'}">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-md-3">
                  <label>20%</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-9">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred me-1">245</div>
                    <pl-edit
                      type="checkbox"
                      attrName="rostoQuadro08Campo245"
                      [model]="rosto.quadro08.campo245"
                      (modelChange)="rosto.quadro08.campo245 = $event; emitModelo22()"
                      [properties]="{label: 'modelo22.rosto.quadro08.sect81.check245'}">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-md-3">
                  <label>12,5% / 21%</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-9">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred me-1">269</div>
                    <pl-edit
                      type="checkbox"
                      attrName="rostoQuadro08Campo269"
                      [model]="rosto.quadro08.campo269"
                      (modelChange)="rosto.quadro08.campo269 = $event; emitModelo22()"
                      [properties]="{label: 'modelo22.rosto.quadro08.sect81.check269'}">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-md-3">
                  <label>8,75% / 14,7%</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-9">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred me-1">270</div>
                    <pl-edit
                      type="checkbox"
                      attrName="rostoQuadro08Campo270"
                      [model]="rosto.quadro08.campo270"
                      (modelChange)="rosto.quadro08.campo270 = $event; emitModelo22()"
                      [properties]="{label: 'modelo22.rosto.quadro08.sect81.check270'}">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-md-3">
                  <label>8,75% / 14,7%</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-9">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred me-1">248</div>
                    <pl-edit
                      type="checkbox"
                      attrName="rostoQuadro08Campo248"
                      [model]="rosto.quadro08.campo248"
                      (modelChange)="rosto.quadro08.campo248 = $event; emitModelo22()"
                      [properties]="{label: 'modelo22.rosto.quadro08.sect81.check248'}">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-md-3">
                  <label>20%</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-9">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred me-1">260</div>
                    <pl-edit
                      type="checkbox"
                      attrName="rostoQuadro08Campo260"
                      [model]="rosto.quadro08.campo260"
                      (modelChange)="rosto.quadro08.campo260 = $event; emitModelo22()"
                      [properties]="{label: 'modelo22.rosto.quadro08.sect81.check260'}">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-md-3">
                  <label>3%</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-9">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred me-1">265</div>
                    <pl-edit
                      type="checkbox"
                      attrName="rostoQuadro08Campo265"
                      [model]="rosto.quadro08.campo265"
                      (modelChange)="rosto.quadro08.campo265 = $event; emitModelo22()"
                      [properties]="{label: 'modelo22.rosto.quadro08.sect81.check265'}">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-md-3">
                  <label>5%</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-9">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred me-1">247</div>
                    <pl-edit type="checkbox" attrName="rostoQuadro08Campo247" [model]="rosto.quadro08.campo247" (modelChange)="rosto.quadro08.campo247 = $event; emitModelo22()"></pl-edit>
                  </div>
                </div>
                <div class="col-md-3">
                  <label></label>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div class="row">
              <div class="col-md-9">
                <div class="box-title">
                  <div class="input-box-numbers-title">08.2</div>
                  <label [translate]="'modelo22.rosto.quadro08.sect82.title'" class="input-box-numbers-label"></label>
                </div>
              </div>
              <div class="col-md-3">
                <label [translate]="'modelo22.rosto.quadro08.sect82.taxas'" class="input-box-numbers-label"></label>
              </div>
            </div>

            <div class="ms-3 input-box-number-seperator">
              <div class="row">
                <div class="col-md-9">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred me-1">246</div>
                    <pl-edit
                      type="checkbox"
                      attrName="rostoQuadro08Campo246"
                      [model]="rosto.quadro08.campo246"
                      (modelChange)="rosto.quadro08.campo246 = $event; emitModelo22()"
                      [properties]="{label: 'modelo22.rosto.quadro08.sect82.check246'}">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-md-3">
                  <label>11,9% / 14,7%</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-9">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred me-1">249</div>
                    <pl-edit
                      type="checkbox"
                      attrName="rostoQuadro08Campo249"
                      [model]="rosto.quadro08.campo249"
                      (modelChange)="rosto.quadro08.campo249 = $event; emitModelo22()"
                      [properties]="{label: 'modelo22.rosto.quadro08.sect82.check249'}">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-md-3">
                  <label>11,9% / 14,7%</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-9">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred me-1">262</div>
                    <pl-edit
                      type="checkbox"
                      attrName="rostoQuadro08Campo262"
                      [model]="rosto.quadro08.campo262"
                      (modelChange)="rosto.quadro08.campo262 = $event; emitModelo22()"
                      [properties]="{label: 'modelo22.rosto.quadro08.sect82.check262'}">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-md-3">
                  <label>25%</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-9">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred me-1">263</div>
                    <pl-edit
                      type="checkbox"
                      attrName="rostoQuadro08Campo263"
                      [model]="rosto.quadro08.campo263"
                      (modelChange)="rosto.quadro08.campo263 = $event; emitModelo22()"
                      [properties]="{label: 'modelo22.rosto.quadro08.sect82.check263'}">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-md-3">
                  <label>25%</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-9">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred me-1">266</div>
                    <pl-edit
                      type="checkbox"
                      attrName="rostoQuadro08Campo266"
                      [model]="rosto.quadro08.campo266"
                      (modelChange)="rosto.quadro08.campo266 = $event; emitModelo22()"
                      [properties]="{label: 'modelo22.rosto.quadro08.sect82.check266'}">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-md-3">
                  <label>25%</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-9">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred me-1">267</div>
                    <pl-edit
                      type="checkbox"
                      attrName="rostoQuadro08Campo267"
                      [model]="rosto.quadro08.campo267"
                      (modelChange)="rosto.quadro08.campo267 = $event; emitModelo22()"
                      [properties]="{label: 'modelo22.rosto.quadro08.sect82.check267'}">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-md-3">
                  <label>10%</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-9">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred me-1">268</div>
                    <pl-edit
                      type="checkbox"
                      attrName="rostoQuadro08Campo268"
                      [model]="rosto.quadro08.campo268"
                      (modelChange)="rosto.quadro08.campo268 = $event; emitModelo22()"
                      [properties]="{label: 'modelo22.rosto.quadro08.sect82.check268'}">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-md-3">
                  <label>5% / 10% / 12% / 15% / 25% / 35%</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-9">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred me-1">264</div>
                    <pl-edit
                      type="checkbox"
                      attrName="rostoQuadro08Campo264"
                      [model]="rosto.quadro08.campo264"
                      (modelChange)="rosto.quadro08.campo264 = $event; emitModelo22()"
                      [properties]="{label: 'modelo22.rosto.quadro08.sect82.check264'}">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-md-3">
                  <label>5% / 10% / 12% / 15% / 25%</label>
                </div>
              </div>
            </div>
          </section>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.rosto.quadro09.title">
        <div *plNavigatorItemCaption><span class="number-title-box">09</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.rosto.quadro09.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline quadro9-inline">
            <div class="row">
              <div class="col-lg-3"><label [translate]="'modelo22.rosto.quadro09.transq7'"></label></div>
              <div class="col-lg-2"><label [translate]="'modelo22.rosto.quadro09.reggeral'"></label></div>
              <div class="col-lg-2"><label [translate]="'modelo22.rosto.quadro09.redtaxa'"></label></div>
              <div class="col-lg-2"><label [translate]="'modelo22.rosto.quadro09.isencao'"></label></div>
              <div class="col-lg-2"><label [translate]="'modelo22.rosto.quadro09.regsimp'"></label></div>
            </div>

            <div class="row">
              <div class="col-lg-3"><label [translate]="'modelo22.rosto.quadro09.prejFiscal'"></label></div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">301</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo301"
                    [model]="rosto.quadro09.campo301"
                    (modelChange)="rosto.quadro09.campo301 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">312</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo312"
                    [model]="rosto.quadro09.campo312"
                    (modelChange)="rosto.quadro09.campo312 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">323</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo323"
                    [model]="rosto.quadro09.campo323"
                    (modelChange)="rosto.quadro09.campo323 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col"></div>
            </div>

            <div class="row">
              <div class="col-lg-3"><label [translate]="'modelo22.rosto.quadro09.lucrotrib'"></label></div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">302</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo302"
                    [model]="rosto.quadro09.campo302"
                    (modelChange)="rosto.quadro09.campo302 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">313</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo313"
                    [model]="rosto.quadro09.campo313"
                    (modelChange)="rosto.quadro09.campo313 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">324</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo324"
                    [model]="rosto.quadro09.campo324"
                    (modelChange)="rosto.quadro09.campo324 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">400</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo400"
                    [model]="rosto.quadro09.campo400"
                    (modelChange)="rosto.quadro09.campo400 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div class="text-center"><span class="fw-bold" [translate]="'modelo22.rosto.quadro09.regespecial'"></span></div>
          <hr />

          <div class="inputs-currency-inline">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro09.field380'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">380</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo380"
                    [model]="rosto.quadro09.campo380"
                    (modelChange)="rosto.quadro09.campo380 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro09.field381'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">381</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo381"
                    [model]="rosto.quadro09.campo381"
                    (modelChange)="rosto.quadro09.campo381 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro09.field395'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">395</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo395"
                    [model]="rosto.quadro09.campo395"
                    (modelChange)="rosto.quadro09.campo395 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro09.field500'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">500</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo500"
                    [model]="rosto.quadro09.campo500"
                    (modelChange)="rosto.quadro09.campo500 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro09.field376'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">376</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo376"
                    [model]="rosto.quadro09.campo376"
                    (modelChange)="rosto.quadro09.campo376 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro09.field382'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">382</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo382"
                    [model]="rosto.quadro09.campo382"
                    (modelChange)="rosto.quadro09.campo382 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-2">
            <div class="box-title">
              <div class="input-box-numbers-title">396</div>
              <label [translate]="'modelo22.rosto.quadro09.table396.title'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-2">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinitionTable396" cgDxDataGridInstanceName="quadro09Table396" [dataSource]="dataGridDefinitionTable396.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtnTable396'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineRostoQuadro9(item.data, '396')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>
            </div>
          </div>

          <div class="mt-2">
            <div class="box-title">
              <div class="input-box-numbers-title">398</div>
              <label [translate]="'modelo22.rosto.quadro09.table398.title'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-2">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinitionTable398" cgDxDataGridInstanceName="quadro09Table398" [dataSource]="dataGridDefinitionTable398.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtnTable398'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineRostoQuadro9(item.data, '398')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>
            </div>
          </div>

          <div class="inputs-currency-inline quadro9-inline mt-2">
            <div class="row">
              <div class="col-lg-3"><label [translate]="'modelo22.rosto.quadro09.prejfiscded'"></label></div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">303</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo303"
                    [model]="rosto.quadro09.campo303"
                    (modelChange)="rosto.quadro09.campo303 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">314</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo314"
                    [model]="rosto.quadro09.campo314"
                    (modelChange)="rosto.quadro09.campo314 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">325</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo325"
                    [model]="rosto.quadro09.campo325"
                    (modelChange)="rosto.quadro09.campo325 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">401</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo401"
                    [model]="rosto.quadro09.campo401"
                    (modelChange)="rosto.quadro09.campo401 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3"><label [translate]="'modelo22.rosto.quadro09.prejfiscaut'"></label></div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">383</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo383"
                    [model]="rosto.quadro09.campo383"
                    (modelChange)="rosto.quadro09.campo383 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">386</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo386"
                    [model]="rosto.quadro09.campo386"
                    (modelChange)="rosto.quadro09.campo386 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">389</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo389"
                    [model]="rosto.quadro09.campo389"
                    (modelChange)="rosto.quadro09.campo389 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">392</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo392"
                    [model]="rosto.quadro09.campo392"
                    (modelChange)="rosto.quadro09.campo392 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3"><label [translate]="'modelo22.rosto.quadro09.prejfiscaut2'"></label></div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">384</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo384"
                    [model]="rosto.quadro09.campo384"
                    (modelChange)="rosto.quadro09.campo384 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">387</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo387"
                    [model]="rosto.quadro09.campo387"
                    (modelChange)="rosto.quadro09.campo387 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">390</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo390"
                    [model]="rosto.quadro09.campo390"
                    (modelChange)="rosto.quadro09.campo390 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">393</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo393"
                    [model]="rosto.quadro09.campo393"
                    (modelChange)="rosto.quadro09.campo393 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3"><label [translate]="'modelo22.rosto.quadro09.alttitularidade'"></label></div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">385</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo385"
                    [model]="rosto.quadro09.campo385"
                    (modelChange)="rosto.quadro09.campo385 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">388</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo388"
                    [model]="rosto.quadro09.campo388"
                    (modelChange)="rosto.quadro09.campo388 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">391</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo391"
                    [model]="rosto.quadro09.campo391"
                    (modelChange)="rosto.quadro09.campo391 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">394</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo394"
                    [model]="rosto.quadro09.campo394"
                    (modelChange)="rosto.quadro09.campo394 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div class="text-center"><span class="fw-bold" [translate]="'modelo22.rosto.quadro09.deducoes'"></span></div>
          <hr />

          <div class="inputs-currency-inline quadro9-inline">
            <div class="row">
              <div class="col-lg-3"><label [translate]="'modelo22.rosto.quadro09.prejfiscded'"></label></div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">309</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo309"
                    [model]="rosto.quadro09.campo309"
                    (modelChange)="rosto.quadro09.campo309 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">320</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo320"
                    [model]="rosto.quadro09.campo320"
                    (modelChange)="rosto.quadro09.campo320 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">331</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo331"
                    [model]="rosto.quadro09.campo331"
                    (modelChange)="rosto.quadro09.campo331 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">407</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo407"
                    [model]="rosto.quadro09.campo407"
                    (modelChange)="rosto.quadro09.campo407 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>

          <div class="box-title">
            <label [translate]="'modelo22.rosto.quadro09.subtitledesc'" class="input-box-numbers-label"></label>
          </div>

          <div class="ms-2">
            <div class="mt-1">
              <div class="box-title">
                <label [translate]="'modelo22.rosto.quadro09.reggeral'" class="input-box-numbers-label"></label>
              </div>

              <div class="ms-2">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinitionTable309" cgDxDataGridInstanceName="quadro09Table309" [dataSource]="dataGridDefinitionTable309.dataSource">
                  <div *dxTemplate="let item of 'cellTemplateBtnTable309'">
                    <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineRostoQuadro9(item.data, '309')">
                      <i class="fa fa-fw fa-trash"></i>
                    </button>
                  </div>
                </dx-data-grid>
              </div>
            </div>

            <div class="mt-2">
              <div class="box-title">
                <label [translate]="'modelo22.rosto.quadro09.redtaxa'" class="input-box-numbers-label"></label>
              </div>

              <div class="ms-2">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinitionTable320" cgDxDataGridInstanceName="quadro09Table320" [dataSource]="dataGridDefinitionTable320.dataSource">
                  <div *dxTemplate="let item of 'cellTemplateBtnTable320'">
                    <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineRostoQuadro9(item.data, '320')">
                      <i class="fa fa-fw fa-trash"></i>
                    </button>
                  </div>
                </dx-data-grid>
              </div>
            </div>

            <div class="mt-2">
              <div class="box-title">
                <label [translate]="'modelo22.rosto.quadro09.isencao'" class="input-box-numbers-label"></label>
              </div>

              <div class="ms-2">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinitionTable331" cgDxDataGridInstanceName="quadro09Table331" [dataSource]="dataGridDefinitionTable331.dataSource">
                  <div *dxTemplate="let item of 'cellTemplateBtnTable331'">
                    <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineRostoQuadro9(item.data, '331')">
                      <i class="fa fa-fw fa-trash"></i>
                    </button>
                  </div>
                </dx-data-grid>
              </div>
            </div>
          </div>

          <div class="box-title">
            <label [translate]="'modelo22.rosto.quadro09.subtitleregesp'" class="input-box-numbers-label"></label>
          </div>

          <div class="ms-2 mt-1">
            <div class="box-title">
              <label [translate]="'modelo22.rosto.quadro09.reggeral'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-2">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinitionTable309B" cgDxDataGridInstanceName="quadro09Table309B" [dataSource]="dataGridDefinitionTable309B.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtnTable309B'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineRostoQuadro9(item.data, '309B')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>
            </div>
          </div>

          <div class="inputs-currency-inline quadro9-inline mt-2">
            <div class="row">
              <div class="col-lg-3">
                <label [translate]="'modelo22.rosto.quadro09.benfisc'"></label>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">310</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo310"
                    [model]="rosto.quadro09.campo310"
                    (modelChange)="rosto.quadro09.campo310 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">321</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo321"
                    [model]="rosto.quadro09.campo321"
                    (modelChange)="rosto.quadro09.campo321 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">332</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo332"
                    [model]="rosto.quadro09.campo332"
                    (modelChange)="rosto.quadro09.campo332 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">408</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo408"
                    [model]="rosto.quadro09.campo408"
                    (modelChange)="rosto.quadro09.campo408 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div class="inputs-currency-inline quadro9-inline">
            <div class="row">
              <div class="col-lg-3"><label [translate]="'modelo22.rosto.quadro09.metcoletavel'"></label></div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">311</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo311"
                    [model]="rosto.quadro09.campo311"
                    (modelChange)="rosto.quadro09.campo311 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">322</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo322"
                    [model]="rosto.quadro09.campo322"
                    (modelChange)="rosto.quadro09.campo322 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">333</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo333"
                    [model]="rosto.quadro09.campo333"
                    (modelChange)="rosto.quadro09.campo333 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>

              <div class="col-lg-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">409</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo409"
                    [model]="rosto.quadro09.campo409"
                    (modelChange)="rosto.quadro09.campo409 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>

          <div class="inputs-currency-inline">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro09.field336'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">336</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo336"
                    [model]="rosto.quadro09.campo336"
                    (modelChange)="rosto.quadro09.campo336 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro09.field399'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">399</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo399"
                    [model]="rosto.quadro09.campo399"
                    (modelChange)="rosto.quadro09.campo399 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="col"><label [translate]="'modelo22.rosto.quadro09.existprej'"></label></div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro09.field397'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">397</div>
                  <pl-edit type="currency" attrName="rostoQuadro09Campo397" [model]="rosto.quadro09.campo397" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-2">
            <div class="box-title">
              <div class="input-box-numbers-title">397-A</div>
              <label [translate]="'modelo22.rosto.quadro09.table397a.title'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-2">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinitionTable397A" cgDxDataGridInstanceName="quadro09Table397A" [dataSource]="dataGridDefinitionTable397A.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtnTable397A'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineRostoQuadro9(item.data, '397A')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>
            </div>
          </div>

          <div class="mt-2">
            <div class="box-title">
              <div class="input-box-numbers-title">397-B</div>
              <label [translate]="'modelo22.rosto.quadro09.table397b.title'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-2">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinitionTable397B" cgDxDataGridInstanceName="quadro09Table397B" [dataSource]="dataGridDefinitionTable397B.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtnTable397B'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineRostoQuadro9(item.data, '397B')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>
            </div>
          </div>

          <div class="inputs-currency-inline mt-2">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro09.field300'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">300</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo300"
                    [model]="rosto.quadro09.campo300"
                    (modelChange)="rosto.quadro09.campo300 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro09.field346'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">346</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro09Campo346"
                    [model]="rosto.quadro09.campo346"
                    (modelChange)="rosto.quadro09.campo346 = $event; emitModelo22(true)"
                    [inputClass]="'text-right'"
                    [properties]="{validators: {campo346: validatorCampo346}, modelOptions: {updateOn: 'blur'}}">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.rosto.quadro10.title">
        <div *plNavigatorItemCaption><span class="number-title-box">10</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.rosto.quadro10.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field347A'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">347-A</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo347A"
                    [model]="rosto.quadro10.campo347A"
                    (modelChange)="rosto.quadro10.campo347A = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('347A')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field347B'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">347-B</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo347B"
                    [model]="rosto.quadro10.campo347B"
                    (modelChange)="rosto.quadro10.campo347B = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('347B')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.impoutrataxas'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">348</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo348"
                    [model]="rosto.quadro10.campo348"
                    (modelChange)="rosto.quadro10.campo348 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">349</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo349"
                    [model]="rosto.quadro10.campo349"
                    (modelChange)="rosto.quadro10.campo349 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('349')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field350'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">350</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo350"
                    [model]="rosto.quadro10.campo350"
                    (modelChange)="rosto.quadro10.campo350 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('350')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field370'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">370</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo370"
                    [model]="rosto.quadro10.campo370"
                    (modelChange)="rosto.quadro10.campo370 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('370')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field351'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">351</div>
                  <pl-edit type="currency" attrName="rostoQuadro10Campo351" [model]="rosto.quadro10.campo351" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field373'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">373</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo373"
                    [model]="rosto.quadro10.campo373"
                    (modelChange)="rosto.quadro10.campo373 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('373')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field378'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">378</div>
                  <pl-edit type="currency" attrName="rostoQuadro10Campo378" [model]="rosto.quadro10.campo378" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.rosto.quadro10.deducoes'"></span></div>
            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field353'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">353</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo353"
                    [model]="rosto.quadro10.campo353"
                    (modelChange)="rosto.quadro10.campo353 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('353')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field375'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">375</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo375"
                    [model]="rosto.quadro10.campo375"
                    (modelChange)="rosto.quadro10.campo375 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('375')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field355'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">355</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo355"
                    [model]="rosto.quadro10.campo355"
                    (modelChange)="rosto.quadro10.campo355 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('355')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field470'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">470</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo470"
                    [model]="rosto.quadro10.campo470"
                    (modelChange)="rosto.quadro10.campo470 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('470')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field356'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">356</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo356"
                    [model]="rosto.quadro10.campo356"
                    (modelChange)="rosto.quadro10.campo356 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('356')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field357'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">357</div>
                  <pl-edit type="currency" attrName="rostoQuadro10Campo357" [model]="rosto.quadro10.campo357" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field358'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">358</div>
                  <pl-edit type="currency" attrName="rostoQuadro10Campo358" [model]="rosto.quadro10.campo358" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field371'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">371</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo371"
                    [model]="rosto.quadro10.campo371"
                    (modelChange)="rosto.quadro10.campo371 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('371')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field359'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">359</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo359"
                    [model]="rosto.quadro10.campo359"
                    (modelChange)="rosto.quadro10.campo359 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('359')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field360'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">360</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo360"
                    [model]="rosto.quadro10.campo360"
                    (modelChange)="rosto.quadro10.campo360 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('360')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field374'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">374</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo374"
                    [model]="rosto.quadro10.campo374"
                    (modelChange)="rosto.quadro10.campo374 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('374')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field361'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">361</div>
                  <pl-edit type="currency" attrName="rostoQuadro10Campo361" [model]="rosto.quadro10.campo361" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field362'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">362</div>
                  <pl-edit type="currency" attrName="rostoQuadro10Campo362" [model]="rosto.quadro10.campo362" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field363'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">363</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo363"
                    [model]="rosto.quadro10.campo363"
                    (modelChange)="rosto.quadro10.campo363 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('363')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field372'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">372</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo372"
                    [model]="rosto.quadro10.campo372"
                    (modelChange)="rosto.quadro10.campo372 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('372')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field364'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">364</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo364"
                    [model]="rosto.quadro10.campo364"
                    (modelChange)="rosto.quadro10.campo364 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i
                        [title]="'modelo22.rosto.quadro10.modal.newtaxa.tooltip' | translate"
                        class="fa fa-percent text-info"
                        aria-hidden="true"
                        plDelegateEventDownwardsClick
                        (click)="openNewTaxaDerrama()"></i>
                    </div>
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('364')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field379'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">379</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo379"
                    [model]="rosto.quadro10.campo379"
                    (modelChange)="rosto.quadro10.campo379 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('379')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col d-flex">
                <label [translate]="'modelo22.rosto.quadro10.field365'"></label>
                <div class="ms-auto" *ngIf="(rosto.quadro09.campo301 ?? 0) + (rosto.quadro09.campo312 ?? 0) + (rosto.quadro09.campo323 ?? 0) > 0">
                  <pl-edit
                    type="checkbox"
                    attrName="rostoQuadro10Campo365PrejFiscal"
                    [model]="rosto.quadro10.consideraPrejuizoFiscal"
                    (modelChange)="rosto.quadro10.consideraPrejuizoFiscal = $event; consideraPrejuizoFiscalChanged()"
                    [properties]="{label: 'modelo22.rosto.quadro10.consideraPrejuizoFiscal'}">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">365</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo365"
                    [model]="rosto.quadro10.campo365"
                    (modelChange)="rosto.quadro10.campo365 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i
                        [title]="'modelo22.rosto.quadro10.tributAutonomas' | translate"
                        class="fa fa-percent text-info"
                        aria-hidden="true"
                        plDelegateEventDownwardsClick
                        (click)="openTributacoesAutonomas()"></i>
                    </div>
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('365')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field366'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">366</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo366"
                    [model]="rosto.quadro10.campo366"
                    (modelChange)="rosto.quadro10.campo366 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('366')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field369'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">369</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro10Campo369"
                    [model]="rosto.quadro10.campo369"
                    (modelChange)="rosto.quadro10.campo369 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('369')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field367'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">367</div>
                  <pl-edit type="currency" attrName="rostoQuadro10Campo367" [model]="rosto.quadro10.campo367" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro10.field368'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">368</div>
                  <pl-edit type="currency" attrName="rostoQuadro10Campo368" [model]="rosto.quadro10.campo368" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <section>
              <div class="box-title">
                <div class="input-box-numbers-title">10-A</div>
                <label [translate]="'modelo22.rosto.quadro10.campo10A.title'" class="input-box-numbers-label"></label>
              </div>

              <div class="ms-3">
                <div class="col ps-0 mb-1"><span class="fw-bold" [translate]="'modelo22.rosto.quadro10.campo10A.desc'"></span></div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.rosto.quadro10.campo10A.field366A'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred-letter">366-A</div>
                      <pl-edit
                        type="currency"
                        attrName="rostoQuadro10Campo366A"
                        [model]="rosto.quadro10A.campo366A"
                        (modelChange)="rosto.quadro10A.campo366A = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.rosto.quadro10.campo10A.field366B'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred-letter">366-B</div>
                      <pl-edit
                        type="currency"
                        attrName="rostoQuadro10Campo366B"
                        [model]="rosto.quadro10A.campo366B"
                        (modelChange)="rosto.quadro10A.campo366B = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div class="box-title">
                <div class="input-box-numbers-title">10-B</div>
                <label [translate]="'modelo22.rosto.quadro10.campo10b.title'" class="input-box-numbers-label"></label>
              </div>

              <div class="ms-3">
                <label [translate]="'modelo22.rosto.quadro10.campo10b.data4'"></label>
                <div class="d-flex">
                  <div class="input-box-numbers me-1">4</div>
                  <pl-edit type="date" attrName="rostoQuadro04campo1Data" [model]="rosto.quadro10B.campo4" (modelChange)="rosto.quadro10B.campo4 = $event; emitModelo22()"></pl-edit>
                </div>

                <div class="col mt-2 ps-0"><span class="fw-bold" [translate]="'modelo22.rosto.quadro10.campo10b.desc'"></span></div>

                <div class="d-flex">
                  <div class="input-box-numbers me-1">1</div>
                  <pl-edit
                    type="radio"
                    attrName="rostoQuadro10Campo10BRadio1"
                    [model]="rosto.quadro10B.campo1a3"
                    (modelChange)="rosto.quadro10B.campo1a3 = $event; emitModelo22()"
                    [properties]="{value: 1, label: 'modelo22.rosto.quadro10.campo10b.radio1'}">
                  </pl-edit>
                </div>

                <div class="d-flex">
                  <div class="input-box-numbers me-1">2</div>
                  <pl-edit
                    type="radio"
                    attrName="rostoQuadro10Campo10BRadio2"
                    [model]="rosto.quadro10B.campo1a3"
                    (modelChange)="rosto.quadro10B.campo1a3 = $event; emitModelo22()"
                    [properties]="{value: 2, label: 'modelo22.rosto.quadro10.campo10b.radio2'}">
                  </pl-edit>
                </div>

                <div class="d-flex">
                  <div class="input-box-numbers me-1">3</div>
                  <pl-edit
                    type="radio"
                    attrName="rostoQuadro10Campo10BRadio3"
                    [model]="rosto.quadro10B.campo1a3"
                    (modelChange)="rosto.quadro10B.campo1a3 = $event; emitModelo22()"
                    [properties]="{value: 3, label: 'modelo22.rosto.quadro10.campo10b.radio3'}">
                  </pl-edit>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.rosto.quadro10.campo10b.valpagdiffracio'"></label></div>
                  <div class="col-lg-3">
                    <label [translate]="'modelo22.rosto.quadro10.campo10b.field377A'"></label>
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred-letter">377-A</div>
                      <pl-edit
                        type="currency"
                        attrName="rostoQuadro10Campo366A"
                        [model]="rosto.quadro10B.campo377A"
                        (modelChange)="rosto.quadro10B.campo377A = $event; emitModelo22(true)"
                        [properties]="{modelOptions: {updateOn: 'blur'}}"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <label [translate]="'modelo22.rosto.quadro10.campo10b.field377B'"></label>
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred-letter">377-B</div>
                      <pl-edit
                        type="currency"
                        attrName="rostoQuadro10Campo377B"
                        [model]="rosto.quadro10B.campo377B"
                        (modelChange)="rosto.quadro10B.campo377B = $event; emitModelo22(true)"
                        [properties]="{modelOptions: {updateOn: 'blur'}}"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col"><label [translate]="'modelo22.rosto.quadro10.field377'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">377</div>
                      <pl-edit type="currency" attrName="rostoQuadro10Campo377" [model]="rosto.quadro10B.campo377" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.rosto.quadro10.field430'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">430</div>
                      <pl-edit type="currency" attrName="rostoQuadro10Campo430" [model]="rosto.quadro10B.campo430" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.rosto.quadro10.field431'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">431</div>
                      <pl-edit type="currency" attrName="rostoQuadro10Campo431" [model]="rosto.quadro10B.campo431" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.rosto.quadro11.title">
        <div *plNavigatorItemCaption><span class="number-title-box">11</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.rosto.quadro11.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro11.field410'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">410</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro11Campo410"
                    [model]="rosto.quadro11.campo410"
                    (modelChange)="rosto.quadro11.campo410 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('410')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro11.field411'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">411</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro11Campo411"
                    [model]="rosto.quadro11.campo411"
                    (modelChange)="rosto.quadro11.campo411 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('410')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro11.field416'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">416</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro11Campo416"
                    [model]="rosto.quadro11.campo416"
                    (modelChange)="rosto.quadro11.campo416 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}">
                    <div *inputGroup="{klass: 'hollow-form'}">
                      <i [title]="titleContasDef" class="fa fa-cogs text-info" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef('410')"></i>
                    </div>
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro11.data418'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">418</div>
                  <pl-edit type="date" attrName="rostoQuadro11Campo418" [(model)]="rosto.quadro11.campo418" (modelChange)="rosto.quadro11.campo418 = $event; emitModelo22()"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro11.check423'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">423</div>
                  <pl-edit
                    type="checkbox"
                    attrName="rostoQuadro11Campo423"
                    [model]="rosto.quadro11.campo423"
                    (modelChange)="rosto.quadro11.campo423 = $event; emitModelo22()"
                    [properties]="{label: 'modelo22.rosto.quadro11.sim'}">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro11.check429'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">429</div>
                  <pl-edit
                    type="checkbox"
                    attrName="rostoQuadro10Campo429"
                    [model]="rosto.quadro11.campo429"
                    (modelChange)="rosto.quadro11.campo429 = $event; emitModelo22()"
                    [properties]="{label: 'modelo22.rosto.quadro11.sim'}">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro11.check455'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">455</div>
                  <pl-edit
                    type="checkbox"
                    attrName="rostoQuadro10Campo455"
                    [model]="rosto.quadro11.campo455"
                    (modelChange)="rosto.quadro11.campo455 = $event; emitModelo22()"
                    [properties]="{label: 'modelo22.rosto.quadro11.sim'}">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.rosto.quadro11A.title">
        <div *plNavigatorItemCaption><span class="number-title-box">11-A</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.rosto.quadro11A.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline">
            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.rosto.quadro11A.AID'"></span></div>
            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro11A.field460'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">460</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro11ACampo460"
                    [model]="rosto.quadro11A.campo460"
                    (modelChange)="rosto.quadro11A.campo460 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro11A.field461'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">461</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro11ACampo461"
                    [model]="rosto.quadro11A.campo461"
                    (modelChange)="rosto.quadro11A.campo461 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro11A.field462'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">462</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro11ACampo462"
                    [model]="rosto.quadro11A.campo462"
                    (modelChange)="rosto.quadro11A.campo462 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.rosto.quadro11A.infoadd'"></span></div>
            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro11A.field463'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">463</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro11ACampo463"
                    [model]="rosto.quadro11A.campo463"
                    (modelChange)="rosto.quadro11A.campo463 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro11A.field464'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">464</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro11ACampo464"
                    [model]="rosto.quadro11A.campo464"
                    (modelChange)="rosto.quadro11A.campo464 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro11A.data465'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">465</div>
                  <pl-edit type="date" attrName="rostoQuadro11ACampo465" [model]="rosto.quadro11A.campo465" (modelChange)="rosto.quadro11A.campo465 = $event; emitModelo22()"></pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.rosto.quadro11B.title">
        <div *plNavigatorItemCaption><span class="number-title-box">11-B</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.rosto.quadro11B.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class=""><label [translate]="'modelo22.rosto.quadro11B.desc'"></label></div>
          <div class="d-flex ms-2">
            <div class="d-flex">
              <div class="input-box-numbers ms-1">6</div>
              <pl-edit
                type="radio"
                attrName="rostoQuadro11BCampo6"
                [model]="rosto.quadro11B.campo67"
                (modelChange)="rosto.quadro11B.campo67 = $event; emitModelo22()"
                [properties]="{value: 'S', label: 'modelo22.rosto.quadro11B.radio6'}">
              </pl-edit>
            </div>

            <div class="d-flex ms-2">
              <div class="input-box-numbers ms-1">7</div>
              <pl-edit
                type="radio"
                attrName="rostoQuadro11BCampo7"
                [model]="rosto.quadro11B.campo67"
                (modelChange)="rosto.quadro11B.campo67 = $event; rosto.quadro11B.campo8 = undefined; emitModelo22()"
                [properties]="{value: 'N', label: 'modelo22.rosto.quadro11B.radio7'}">
              </pl-edit>
            </div>
          </div>

          <div class=""><label [translate]="'modelo22.rosto.quadro11B.desc2'"></label></div>
          <div class="d-flex ms-2">
            <div class="input-box-numbers ms-1">8</div>
            <pl-edit
              type="radio"
              attrName="rostoQuadro11BCampo8"
              [model]="rosto.quadro11B.campo8"
              (modelChange)="rosto.quadro11B.campo8 = $event; emitModelo22()"
              [properties]="{value: 8, disabled: rosto.quadro11B.campo67 !== '6', label: 'modelo22.rosto.quadro11B.radio8'}">
            </pl-edit>
          </div>

          <div class="d-flex ms-2">
            <div class="input-box-numbers ms-1">9</div>
            <pl-edit
              type="radio"
              attrName="rostoQuadro11BCampo9"
              [model]="rosto.quadro11B.campo9"
              (modelChange)="rosto.quadro11B.campo9 = $event; emitModelo22()"
              [properties]="{value: 9, disabled: rosto.quadro11B.campo67 !== '6', label: 'modelo22.rosto.quadro11B.radio9'}">
            </pl-edit>
          </div>

          <div class="d-flex ms-2">
            <div class="input-box-numbers ms-1">10</div>
            <pl-edit
              type="radio"
              attrName="rostoQuadro11BCampo10"
              [model]="rosto.quadro11B.campo10"
              (modelChange)="rosto.quadro11B.campo10 = $event; emitModelo22()"
              [properties]="{value: 10, disabled: rosto.quadro11B.campo67 !== '6', label: 'modelo22.rosto.quadro11B.radio10'}">
            </pl-edit>
          </div>

          <div class="inputs-currency-inline mt-2">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro11B.field1'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">1</div>
                  <pl-edit type="currency" attrName="rostoQuadro11BCampo1" [model]="rosto.quadro11B.campo1" (modelChange)="rosto.quadro11B.campo1 = $event; emitModelo22()" [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro11B.field2'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">2</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro11BCampo2"
                    [model]="rosto.quadro11B.campo2"
                    (modelChange)="rosto.quadro11B.campo2 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro11B.field3'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">3</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro11BCampo3"
                    [model]="rosto.quadro11B.campo3"
                    (modelChange)="rosto.quadro11B.campo3 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro11B.field4'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">4</div>
                  <pl-edit type="currency" attrName="rostoQuadro11BCampo4" [model]="rosto.quadro11B.campo4" [inputClass]="'text-right'" [properties]="{disabled: true, decimalsLimit: 7}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro11B.field5'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">5</div>
                  <pl-edit type="currency" attrName="rostoQuadro11BCampo5" [model]="rosto.quadro11B.campo5" [inputClass]="'text-right'" [properties]="{disabled: true, decimalsLimit: 7}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro11B.field22'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">22</div>
                  <pl-edit type="currency" attrName="rostoQuadro11BCampo22" [model]="rosto.quadro11B.campo22" [inputClass]="'text-right'" [properties]="{disabled: true, decimalsLimit: 7}"></pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.rosto.quadro12.title">
        <div *plNavigatorItemCaption><span class="number-title-box">12</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.rosto.quadro12.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="ms-2">
            <dx-data-grid [cgDxDataGrid]="dataGridDefinitionTableQuadro12" cgDxDataGridInstanceName="quadro12Table" [dataSource]="dataGridDefinitionTableQuadro12.dataSource">
              <div *dxTemplate="let item of 'cellTemplateBtnTableQuadro12'">
                <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineRostoQuadro12(item.data)">
                  <i class="fa fa-fw fa-trash"></i>
                </button>
              </div>
            </dx-data-grid>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.rosto.quadro13.title">
        <div *plNavigatorItemCaption><span class="number-title-box">13</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.rosto.quadro13.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline mt-2">
            <div class=""><label [translate]="'modelo22.rosto.quadro13.campoIniciouAtividade'"></label></div>
            <div class="d-flex">
              <div class="d-flex">
                <div class="input-box-numbers">13.1</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro13InitAtvYes"
                  [model]="rostoQuadro13CampoInitAtvYes"
                  (modelChange)="rostoQuadro13CampoInitAtvYes = $event; rostoQuadro13InitAtvChanged(true)"
                  [properties]="{label: 'modelo22.comum.sim'}">
                </pl-edit>
              </div>
              <div class="d-flex">
                <div class="input-box-numbers">13.2</div>
                <pl-edit
                  type="checkbox"
                  attrName="rostoQuadro13InitAtvNo"
                  [model]="rostoQuadro13CampoInitAtvNo"
                  (modelChange)="rostoQuadro13CampoInitAtvNo = $event; rostoQuadro13InitAtvChanged(false)"
                  [properties]="{label: 'modelo22.comum.nao'}">
                </pl-edit>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field13e3'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">13.3</div>
                  <pl-edit
                    type="date"
                    attrName="rostoQuadro13Campo13e3"
                    [model]="rosto.quadro13.campo13e3"
                    (modelChange)="rosto.quadro13.campo13e3 = $event; emitModelo22()"
                    [properties]="{disabled: !rostoQuadro13CampoInitAtvYes}">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col text-center"><label [translate]="'modelo22.rosto.quadro13.desc'"></label></div>
              <div class="col-lg-3">
                <div class="text-center"><label [translate]="'modelo22.rosto.quadro13.basetrib'"></label></div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field414'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">414</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13Campo414"
                    [model]="rosto.quadro13.campo414"
                    (modelChange)="rosto.quadro13.campo414 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field415'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">415</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13Campo415"
                    [model]="rosto.quadro13.campo415"
                    (modelChange)="rosto.quadro13.campo415 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field417'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">417</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13Campo417"
                    [model]="rosto.quadro13.campo417"
                    (modelChange)="rosto.quadro13.campo417 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field420'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">420</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13Campo420"
                    [model]="rosto.quadro13.campo420"
                    (modelChange)="rosto.quadro13.campo420 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field421'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">421</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13Campo421"
                    [model]="rosto.quadro13.campo421"
                    (modelChange)="rosto.quadro13.campo421 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field422'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">422</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13Campo422"
                    [model]="rosto.quadro13.campo422"
                    (modelChange)="rosto.quadro13.campo422 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field424'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">424</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13Campo424"
                    [model]="rosto.quadro13.campo424"
                    (modelChange)="rosto.quadro13.campo424 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field425'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">425</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13Campo425"
                    [model]="rosto.quadro13.campo425"
                    (modelChange)="rosto.quadro13.campo425 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field426'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">426</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13Campo426"
                    [model]="rosto.quadro13.campo426"
                    (modelChange)="rosto.quadro13.campo426 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field427'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">427</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13Campo427"
                    [model]="rosto.quadro13.campo427"
                    (modelChange)="rosto.quadro13.campo427 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field428'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">428</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13Campo428"
                    [model]="rosto.quadro13.campo428"
                    (modelChange)="rosto.quadro13.campo428 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field432'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">432</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13Campo432"
                    [model]="rosto.quadro13.campo432"
                    (modelChange)="rosto.quadro13.campo432 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field433'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">433</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13Campo433"
                    [model]="rosto.quadro13.campo433"
                    (modelChange)="rosto.quadro13.campo433 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field434'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">434</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13Campo434"
                    [model]="rosto.quadro13.campo434"
                    (modelChange)="rosto.quadro13.campo434 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field435'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">435</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13Campo435"
                    [model]="rosto.quadro13.campo435"
                    (modelChange)="rosto.quadro13.campo435 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field436'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">436</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13Campo436"
                    [model]="rosto.quadro13.campo436"
                    (modelChange)="rosto.quadro13.campo436 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field437'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">437</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13Campo437"
                    [model]="rosto.quadro13.campo437"
                    (modelChange)="rosto.quadro13.campo437 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field438'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">438</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13Campo438"
                    [model]="rosto.quadro13.campo438"
                    (modelChange)="rosto.quadro13.campo438 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field439'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">439</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13Campo439"
                    [model]="rosto.quadro13.campo439"
                    (modelChange)="rosto.quadro13.campo439 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13.field456'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">456</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13Campo456"
                    [model]="rosto.quadro13.campo456"
                    (modelChange)="rosto.quadro13.campo456 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.rosto.quadro13A.title">
        <div *plNavigatorItemCaption><span class="number-title-box">13-A</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.rosto.quadro13A.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline mt-2">
            <div class="row">
              <div class="col text-center"><label [translate]="'modelo22.rosto.quadro13A.desc'"></label></div>
              <div class="col-lg-3">
                <div class="text-center"><label [translate]="'modelo22.rosto.quadro13A.basetrib'"></label></div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13A.field440'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">440</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13ACampo440"
                    [model]="rosto.quadro13A.campo440"
                    (modelChange)="rosto.quadro13A.campo440 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13A.field441'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">441</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13ACampo441"
                    [model]="rosto.quadro13A.campo441"
                    (modelChange)="rosto.quadro13A.campo441 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13A.field442'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">442</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13ACampo442"
                    [model]="rosto.quadro13A.campo442"
                    (modelChange)="rosto.quadro13A.campo442 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13A.field443'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">443</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13ACampo443"
                    [model]="rosto.quadro13A.campo443"
                    (modelChange)="rosto.quadro13A.campo443 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13A.field444'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">444</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13ACampo444"
                    [model]="rosto.quadro13A.campo444"
                    (modelChange)="rosto.quadro13A.campo444 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13A.field445'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">445</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13ACampo445"
                    [model]="rosto.quadro13A.campo445"
                    (modelChange)="rosto.quadro13A.campo445 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13A.field446'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">446</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13ACampo446"
                    [model]="rosto.quadro13A.campo446"
                    (modelChange)="rosto.quadro13A.campo446 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13A.field447'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">447</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13ACampo447"
                    [model]="rosto.quadro13A.campo447"
                    (modelChange)="rosto.quadro13A.campo447 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13A.field448'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">448</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13ACampo448"
                    [model]="rosto.quadro13A.campo448"
                    (modelChange)="rosto.quadro13A.campo448 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13A.field449'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">449</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13ACampo449"
                    [model]="rosto.quadro13A.campo449"
                    (modelChange)="rosto.quadro13A.campo449 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13A.field450'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">450</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13ACampo450"
                    [model]="rosto.quadro13A.campo450"
                    (modelChange)="rosto.quadro13A.campo450 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13A.field451'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">451</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13ACampo451"
                    [model]="rosto.quadro13A.campo451"
                    (modelChange)="rosto.quadro13A.campo451 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13A.field452'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">452</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13ACampo452"
                    [model]="rosto.quadro13A.campo452"
                    (modelChange)="rosto.quadro13A.campo452 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13A.field453'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">453</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13ACampo453"
                    [model]="rosto.quadro13A.campo453"
                    (modelChange)="rosto.quadro13A.campo453 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13A.field454'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">454</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13ACampo454"
                    [model]="rosto.quadro13A.campo454"
                    (modelChange)="rosto.quadro13A.campo454 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.rosto.quadro13A.field457'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">457</div>
                  <pl-edit
                    type="currency"
                    attrName="rostoQuadro13ACampo457"
                    [model]="rosto.quadro13A.campo457"
                    (modelChange)="rosto.quadro13A.campo457 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.rosto.quadro14.title">
        <div *plNavigatorItemCaption><span class="number-title-box">14</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.rosto.quadro14.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="ms-2">
            <dx-data-grid [cgDxDataGrid]="dataGridDefinitionTableQuadro14" cgDxDataGridInstanceName="quadro14Table" [dataSource]="dataGridDefinitionTableQuadro14.dataSource">
              <div *dxTemplate="let item of 'cellTemplateBtnTableQuadro14'">
                <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineRostoQuadro14(item.data)">
                  <i class="fa fa-fw fa-trash"></i>
                </button>
              </div>
            </dx-data-grid>
          </div>

          <div class="inputs-currency-inline quadro14-inline mt-2">
            <div class="row">
              <div class="col-lg-2"></div>

              <div class="col-lg-2"><label [translate]="'modelo22.rosto.quadro14.table.fields.salcaducado'"></label></div>

              <div class="col-lg-2"><label [translate]="'modelo22.rosto.quadro14.table.fields.saldnaored'"></label></div>

              <div class="col-lg-2"><label [translate]="'modelo22.rosto.quadro14.table.fields.credpreio'"></label></div>

              <div class="col-lg-2"><label [translate]="'modelo22.rosto.quadro14.table.fields.dedperio'"></label></div>

              <div class="col-lg-2"><label [translate]="'modelo22.rosto.quadro14.table.fields.saldotrans'"></label></div>
            </div>

            <div class="row">
              <div class="col-lg-2"><label [translate]="'modelo22.rosto.quadro14.totCIDTJIcomCDT'"></label></div>

              <div class="col-lg-2">
                <pl-edit
                  type="currency"
                  attrName="rostoQuadro14TotalComCDTCampo10"
                  [model]="rosto.quadro14.totalComCDTCampo10"
                  (modelChange)="rosto.quadro14.totalComCDTCampo10 = $event; emitModelo22()"
                  [inputClass]="'text-right'">
                </pl-edit>
              </div>

              <div class="col-lg-2">
                <pl-edit
                  type="currency"
                  attrName="rostoQuadro14TotalComCDTCampo3"
                  [model]="rosto.quadro14.totalComCDTCampo3"
                  (modelChange)="rosto.quadro14.totalComCDTCampo3 = $event; emitModelo22()"
                  [inputClass]="'text-right'">
                </pl-edit>
              </div>

              <div class="col-lg-2">
                <pl-edit
                  type="currency"
                  attrName="rostoQuadro14TotalComCDTCampo6"
                  [model]="rosto.quadro14.totalComCDTCampo6"
                  (modelChange)="rosto.quadro14.totalComCDTCampo6 = $event; emitModelo22()"
                  [inputClass]="'text-right'">
                </pl-edit>
              </div>

              <div class="col-lg-2">
                <pl-edit
                  type="currency"
                  attrName="rostoQuadro14TotalComCDTCampo7"
                  [model]="rosto.quadro14.totalComCDTCampo7"
                  (modelChange)="rosto.quadro14.totalComCDTCampo7 = $event; emitModelo22()"
                  [inputClass]="'text-right'">
                </pl-edit>
              </div>

              <div class="col-lg-2">
                <pl-edit
                  type="currency"
                  attrName="rostoQuadro14TotalComCDTCampo8"
                  [model]="rosto.quadro14.totalComCDTCampo8"
                  (modelChange)="rosto.quadro14.totalComCDTCampo8 = $event; emitModelo22()"
                  [inputClass]="'text-right'">
                </pl-edit>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-2"><label [translate]="'modelo22.rosto.quadro14.totCIDTJIsemCDT'"></label></div>

              <div class="col-lg-2">
                <pl-edit
                  type="currency"
                  attrName="rostoQuadro14TotalSemCDTCampo10"
                  [model]="rosto.quadro14.totalSemCDTCampo10"
                  (modelChange)="rosto.quadro14.totalSemCDTCampo10 = $event; emitModelo22()"
                  [inputClass]="'text-right'">
                </pl-edit>
              </div>

              <div class="col-lg-2">
                <pl-edit
                  type="currency"
                  attrName="rostoQuadro14TotalSemCDTCampo3"
                  [model]="rosto.quadro14.totalSemCDTCampo3"
                  (modelChange)="rosto.quadro14.totalSemCDTCampo3 = $event; emitModelo22()"
                  [inputClass]="'text-right'">
                </pl-edit>
              </div>

              <div class="col-lg-2">
                <pl-edit
                  type="currency"
                  attrName="rostoQuadro14TotalSemCDTCampo6"
                  [model]="rosto.quadro14.totalSemCDTCampo6"
                  (modelChange)="rosto.quadro14.totalSemCDTCampo6 = $event; emitModelo22()"
                  [inputClass]="'text-right'">
                </pl-edit>
              </div>

              <div class="col-lg-2">
                <pl-edit
                  type="currency"
                  attrName="rostoQuadro14TotalSemCDTCampo7"
                  [model]="rosto.quadro14.totalSemCDTCampo7"
                  (modelChange)="rosto.quadro14.totalSemCDTCampo7 = $event; emitModelo22()"
                  [inputClass]="'text-right'">
                </pl-edit>
              </div>

              <div class="col-lg-2">
                <pl-edit
                  type="currency"
                  attrName="rostoQuadro14TotalSemCDTCampo8"
                  [model]="rosto.quadro14.totalSemCDTCampo8"
                  (modelChange)="rosto.quadro14.totalSemCDTCampo8 = $event; emitModelo22()"
                  [inputClass]="'text-right'">
                </pl-edit>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-2"><label [translate]="'modelo22.rosto.quadro14.totCIDTJIJI'"></label></div>

              <div class="col-lg-2">
                <pl-edit
                  type="currency"
                  attrName="rostoQuadro14TotalCampo10"
                  [model]="rosto.quadro14.totalCampo10"
                  (modelChange)="rosto.quadro14.totalCampo10 = $event; emitModelo22()"
                  [inputClass]="'text-right'">
                </pl-edit>
              </div>

              <div class="col-lg-2">
                <pl-edit
                  type="currency"
                  attrName="rostoQuadro14TotalCampo3"
                  [model]="rosto.quadro14.totalCampo3"
                  (modelChange)="rosto.quadro14.totalCampo3 = $event; emitModelo22()"
                  [inputClass]="'text-right'">
                </pl-edit>
              </div>

              <div class="col-lg-2">
                <pl-edit
                  type="currency"
                  attrName="rostoQuadro14TotalCampo6"
                  [model]="rosto.quadro14.totalCampo6"
                  (modelChange)="rosto.quadro14.totalCampo6 = $event; emitModelo22()"
                  [inputClass]="'text-right'">
                </pl-edit>
              </div>

              <div class="col-lg-2">
                <pl-edit
                  type="currency"
                  attrName="rostoQuadro14TotalCampo7"
                  [model]="rosto.quadro14.totalCampo7"
                  (modelChange)="rosto.quadro14.totalCampo7 = $event; emitModelo22()"
                  [inputClass]="'text-right'">
                </pl-edit>
              </div>

              <div class="col-lg-2">
                <pl-edit
                  type="currency"
                  attrName="rostoQuadro14TotalCampo8"
                  [model]="rosto.quadro14.totalCampo8"
                  (modelChange)="rosto.quadro14.totalCampo8 = $event; emitModelo22()"
                  [inputClass]="'text-right'">
                </pl-edit>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>
    </pl-navigator>
  </pl-form>
</div>

<!--<ng-template #templateInputsCurrencyInline let-translate="translate" let-field="field" let-attrName="attrName" let-fieldValue="fieldValue">-->
<!--  <div class="row">-->
<!--    <div class="col"><label [translate]="translate"></label></div>-->
<!--    <div class="col-lg-3">-->
<!--      <div class="d-flex">-->
<!--        <div class="input-box-numbers-hundred">{{ field }}</div>-->
<!--        <pl-edit-->
<!--          type="currency"-->
<!--          [attrName]="attrName"-->
<!--          [model]="fieldValue"-->
<!--&lt;!&ndash;          (modelChange)="fieldValue = $event; emitRosto(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"&ndash;&gt;-->
<!--          [inputClass]="'text-right'">-->
<!--          <div *inputGroup="{klass: 'hollow-form'}">-->
<!--            <i class="fa fa-cogs text-info" [title]="titleContasDef" aria-hidden="true" plDelegateEventDownwardsClick (click)="openContasDef(field)"></i>-->
<!--          </div>-->
<!--        </pl-edit>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->
