import moment from 'moment';
import {Component, Injector, OnInit} from '@angular/core';
import {ModuloComponent} from '../../../../components/module/module.component';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonRHEstatisticasMultiItem, IRHEstatisticasMultiFilters} from '../rhEstatisticasMulti.module.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import CustomStore from 'devextreme/data/custom_store';
import {RhEstatisticasModuleService} from '../../rhestatisticas/rhEstatisticas.module.service';
import {EDateMonth, IPlToolbarItem, IPlToolbarMenuItem} from 'pl-comps-angular';
import {DATA_SOURCE_MESES} from '../../../../datasources/meses/meses.datasource';
import {IDataSourceItem} from '../../../../components/datasource/datasources.interface';
import {RhEstatisticasMultiConfigModalComponent} from '../modals/rhEstatisticasMulti.config.modal';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {IRHEstatisticaParams, MODULE_NAME_RH_ESTATISTICAS} from '../../rhestatisticas/rhEstatisticas.module.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';

@Component({
  selector: 'module-rh-estatisticas-multi',
  templateUrl: './rhEstatisticasMulti.module.component.html'
})
export class RhEstatisticasMultiModuleComponent extends ModuloComponent implements OnInit {
  public dataGrid: dxDataGrid;
  public dataGridDefinition: IDevExpressDataGrid<IJsonRHEstatisticasMultiItem, string>;

  private readonly _filters: IRHEstatisticasMultiFilters;
  private readonly _mnuAnos: IPlToolbarItem;
  private readonly _mnuMes: IPlToolbarItem;
  private readonly _btnPesquisar: IPlToolbarItem;
  private readonly _btnConfig: IPlToolbarItem;
  private readonly _meses: Array<IPlToolbarMenuItem>;
  private readonly _sourceAnos: Array<number>;

  private _isFirstLoad: boolean;
  private _selectedAno: IPlToolbarMenuItem;
  private _selectedMes: IPlToolbarMenuItem;
  private _dataGridSource: Array<IJsonRHEstatisticasMultiItem>;
  private _maintenanceSingleRHEstatisticas: IModuleMaintenanceInstance;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _rhEstatisticasModuleService: RhEstatisticasModuleService,
    private readonly _cgModalService: CGModalService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService
  ) {
    super(_injector);
    this._isFirstLoad = true;

    this._filters = {
      ano: moment().year(),
      mes: moment().month() + 1
    };

    this._sourceAnos = [];
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    for (let i = moment().subtract(5, 'year').year(); i <= moment().year(); i++) {
      this._sourceAnos.push(i);
    }

    const anos: Array<IPlToolbarMenuItem> = this._sourceAnos.map<IPlToolbarMenuItem>((value: number) => {
      const itemAno: IPlToolbarMenuItem = {
        caption: String(value),
        active: value === this._filters.ano,
        click: (menuItem: IPlToolbarMenuItem) => {
          this._changedAno(menuItem);
        }
      };
      if (itemAno.active) {
        this._selectedAno = itemAno;
      }
      return itemAno;
    });

    this._meses = DATA_SOURCE_MESES.data.map<IPlToolbarMenuItem>(({value, name}: IDataSourceItem<EDateMonth>) => {
      const itemMes: IPlToolbarMenuItem = {
        id: String(value),
        caption: this._translateService.instant(name),
        active: value === this._filters.mes,
        click: (menuItem: IPlToolbarMenuItem) => {
          this._changedMes(menuItem);
        }
      };
      if (itemMes.active) {
        this._selectedMes = itemMes;
      }
      return itemMes;
    });

    this._mnuAnos = {
      order: 1,
      id: 'toolbarAnos',
      caption: this._getCaptionAno(),
      type: 'dropdown',
      menu: anos
    };

    this._mnuMes = {
      order: this._mnuAnos.order + 1,
      id: 'toolbarMesses',
      caption: this._getCaptionMes(),
      type: 'dropdown',
      menu: this._meses
    };

    this._btnPesquisar = {
      order: this._mnuMes.order + 1,
      id: 'pesquisar',
      caption: 'rhEstatisticas.string.pesquisar',
      class: 'btn-success',
      iconLeft: '<i class="fa fa-fw fa-search" aria-hidden="true"></i>',
      type: 'button',
      click: () => this._pesquisar()
    };

    this._btnConfig = {
      order: this._btnPesquisar.order + 1,
      id: 'config',
      caption: 'rhEstatisticas.string.config',
      class: 'btn-light',
      iconLeft: '<i class="fa fa-fw fa-cog" aria-hidden="true"></i>',
      type: 'button',
      click: () => this._showConfigModal()
    };

    this.dataGridDefinition = {
      columns: [
        {
          dataField: 'vf_actions',
          fixed: true,
          fixedPosition: 'left',
          caption: ' ',
          cellTemplate: 'cellTemplateActions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false,
          width: 100,
          alignment: 'center'
        },
        {dataField: 'nEmpresa', caption: 'rhEstatisticas.fields.nEmpresa', dataType: 'string', allowEditing: false, fixed: true, fixedPosition: 'left'},
        {dataField: 'nome', caption: 'rhEstatisticas.fields.nome', dataType: 'string', allowEditing: false},
        {dataField: 'totalAbonos', caption: 'rhEstatisticas.fields.abonos', dataType: 'currency', allowEditing: false, format: {type: 'currency', precision: 2}},
        {dataField: 'totalDescontos', caption: 'rhEstatisticas.fields.descontos', dataType: 'currency', allowEditing: false, format: {type: 'currency', precision: 2}},
        {
          dataField: 'totalBruto',
          caption: 'rhEstatisticas.fields.totalBruto',
          dataType: 'currency',
          allowEditing: false,
          format: {type: 'currency', precision: 2}
        },
        {
          dataField: 'totalImpostosColaborador',
          caption: 'rhEstatisticas.string.impostosColaborador',
          dataType: 'currency',
          allowEditing: false,
          format: {type: 'currency', precision: 2}
        },
        {dataField: 'totalSalarios', caption: 'rhEstatisticas.fields.totalLiquido', dataType: 'currency', allowEditing: false, format: {type: 'currency', precision: 2}},
        {dataField: 'totalEncargos', caption: 'rhEstatisticas.fields.encargosEmpresa', dataType: 'currency', allowEditing: false, format: {type: 'currency', precision: 2}},
        {dataField: 'totalCustos', caption: 'rhEstatisticas.fields.custoEmpresa', dataType: 'currency', allowEditing: false, format: {type: 'currency', precision: 2}}
      ],
      dataSource: new CustomStore({
        key: 'nEmpresa',
        load: () => {
          if (this._isFirstLoad) {
            return [];
          }
          return this._dataGridSource;
        }
      })
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._mnuAnos).addButton(this._mnuMes).addButton(this._btnPesquisar).addButton(this._btnConfig);
  }

  public readonly fnViewEmpresa = (nEmpresa: string) => (): Promise<unknown> => this._viewEmpresa(nEmpresa);

  public onDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this.dataGrid = component;
  }

  private _getDataGridSource(): Promise<void> {
    return this._rhEstatisticasModuleService.getDataMultiEmpresas(this._filters.ano, this._filters.mes).then((response) => {
      this._dataGridSource = response.body;
    });
  }

  private _getCaptionAno(): string {
    return this._translateService.instant('toolbar.year', {value: this._filters.ano});
  }

  private _getCaptionMes(): string {
    return this._translateService.instant('toolbar.month', {value: this._meses[this._filters.mes - 1].caption});
  }

  private _changedMes(menuItem: IPlToolbarMenuItem): void {
    if (menuItem.id !== String(this._filters.mes)) {
      if (this._selectedMes) {
        this._selectedMes.active = false;
      }
      this._selectedMes = menuItem;
      this._selectedMes.active = true;
      this._filters.mes = Number(this._selectedMes.id);
      this._mnuMes.caption = this._getCaptionMes();
      this._clearGrid();
    }
  }

  private _changedAno(menuItem: IPlToolbarMenuItem): void {
    if (menuItem.caption !== String(this._filters.ano)) {
      if (this._selectedAno) {
        this._selectedAno.active = false;
      }
      this._selectedAno = menuItem;
      this._selectedAno.active = true;
      this._filters.ano = Number(this._selectedAno.caption);
      this._mnuAnos.caption = this._getCaptionAno();
      this._clearGrid();
    }
  }

  private _clearGrid(): void {
    this._dataGridSource = [];
    this.dataGrid?.refresh();
  }

  private _pesquisar(): Promise<void> {
    this._isFirstLoad = false;
    return this._getDataGridSource().then(() => {
      this.dataGrid?.refresh();
    });
  }

  private _showConfigModal(): Promise<void> {
    return this._rhEstatisticasModuleService.getMultiConfig().then((response) => {
      const modalInstance = this._cgModalService.showVanilla(RhEstatisticasMultiConfigModalComponent, {size: 'lg', backdrop: 'static'});
      const componentInstance: RhEstatisticasMultiConfigModalComponent = modalInstance.componentInstance;
      componentInstance.config = response.body;
      return modalInstance.result;
    });
  }

  private _viewEmpresa(nEmpresa: string): Promise<void> {
    if (!this._maintenanceSingleRHEstatisticas) {
      this._maintenanceSingleRHEstatisticas = this._moduleMaintenanceService.build(MODULE_NAME_RH_ESTATISTICAS);
    }
    const moduleParams: IRHEstatisticaParams = {
      nEmpresa: nEmpresa,
      ano: this._filters.ano,
      mes: this._filters.mes
    };
    return this._maintenanceSingleRHEstatisticas.maintenance({params: moduleParams});
  }
}
