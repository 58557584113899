export enum EEstadoNotificacaoSEPA {
  None,
  NaoNotificado,
  Notificado
}
export type TEstadosNotificacaoPTStr = {[estado in EEstadoNotificacaoSEPA]: string};

export const ESTADOS_NOTIFICACAO_PT_STR: TEstadosNotificacaoPTStr = {
  [EEstadoNotificacaoSEPA.None]: '',
  [EEstadoNotificacaoSEPA.NaoNotificado]: 'Não notificado',
  [EEstadoNotificacaoSEPA.Notificado]: 'Notificado'
};

export const MODULE_NAME_GESTAO_FICHEIROS_SEPA = 'gestaoficheirossepa';
