import moment from 'moment';
import {BehaviorSubject, firstValueFrom, Observable, Subscription} from 'rxjs';
import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {StateObject, StateService, Transition, UIRouterGlobals} from '@uirouter/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {
  copy,
  IPlNavbarItemEvent,
  IPlNavbarMenu,
  IPlNavbarMenuDefinition,
  IPlSidebarEventItemClicked,
  IPlSidebarMenu,
  isArray,
  isDefined,
  isMobile,
  isNumber,
  isObject,
  Logger,
  PlAlertService,
  PlDynamicVisualsReference,
  PlDynamicVisualsService,
  PlGlobalEventsService,
  skipIf,
  timeout
} from 'pl-comps-angular';
import {AmaliaWindowService} from '../../services/amalia/window/amalia.window.service';
import {AppService} from '../../services/app/app.service';
import {AuthService} from '../../services/auth/auth.service';
import {CG_COMPANY_URL, CG_SUPPORT_URL, DEFAULT_TOOLBAR_ID, generatePortalId, GLOBAL_EVENT_LOGO_CHANGED, GLOBAL_EVENT_USER_CHANGED} from '../../../config/constants';
import {CG_ON_COMPANY_URL, generateSupportUrl} from '../../interfaces/store.interface';
import {CGModalService} from '../cg/modal/cgmodal.service';
import {CGStateService} from '../state/cg.state.service';
import {CGTermsModalComponent} from '../cg/modal/terms/terms.modal.component';
import {ConfigSiteService} from '../../services/configsite.service';
import {DI_PORTAL_COMPONENT, IPortalComponent} from './portal.component.interface';
import {EAppLaunchMode} from '../../../common/site';
import {EmpresasService} from '../../services/empresas/empresas.service';
import {entityStateNameDetail, entityStateNameNew} from '../../../common/utils/entity.state.utils';
import {evaluatePortalTitle} from '../../entities/portal/portal.entity.interface';
import {hasAuthority as sessionHasAuthority} from '../../../common/utils/roles.utils';
import {
  IActivePortal,
  ICGStateDeclaration,
  IPortalNavbarMenu,
  IPortalSidebarMenu,
  IPortalStateDeclarationData,
  NAVBAR_ITEM_ID_CHANGE_PORTAL,
  STATE_NAME_PORTAL
} from '../../services/portals/portals.service.interface';
import {IAuthChangeEmpresaParams} from '../../services/auth/auth.service.interface';
import {IJsonPortal} from '../../entities/portal/jsonPortal.entity.interface';
import {IJsonUserRole, TUserSession} from '../../services/account/jsonUserApi.interface';
import {NOTIFICATION_CENTER_ENABLED} from '../../services/notificationcenter/notificationcenter.service.interface';
import {NotificationCenterComponent} from '../notificationcenter/notificationcenter.component';
import {NotificationCenterService} from '../../services/notificationcenter/notificationcenter.service';
import {PORTAL_ADMIN_ID, STATE_NAME_ADMIN} from '../../states/admin/admin.portal.interface';
import {PortalAboutModalComponent} from './modals/about/portal.about.modal.component';
import {PortalService} from '../../services/portal/portal.service';
import {ROLE} from '../../services/role.const';
import {STATE_NAME_LOGIN} from '../../states/account/login/login.state.interface';
import {VERSION} from '../../../../environments/constants';

const CLASS_NAME_LOCKED = 'portal-menu-locked';
const TRANSLATE_KEY_LOCKED = 'locked-portal';
const TRANSLATE_KEY_LOCKED_CG_STORE = 'locked-portal-cgstore';
const TRANSLATE_KEY_LOCKED_EXPIRED = 'locked-portal-expired';
const MAX_PORTALS = 10;
const NAVBAR_NOTIFICATION_CENTER = '.toolbar-notification-center';

@Component({
  selector: 'portal',
  templateUrl: './portal.component.html',
  providers: [{provide: DI_PORTAL_COMPONENT, useExisting: PortalComponent}]
})
export class PortalComponent implements OnInit, OnDestroy, IPortalComponent {
  @Input() public session: TUserSession;
  @Input() public portals: Array<IJsonPortal>;
  @Input() public launchMode: EAppLaunchMode;

  public readonly launchModes: typeof EAppLaunchMode;
  public readonly version: string;
  public readonly currentYear: number;
  public isAdmin: boolean;
  public sidebarMenu: Array<IPlSidebarMenu>;
  public navBarMenu: Array<IPortalNavbarMenu>;
  public hasStickyPortals: boolean;
  public logotipoUrl: string;
  public nomeEmpresa: string;
  public companyUrl: string;
  public cgStoreUrl: string;
  public cgSupportUrl: string;
  public globalLoading: boolean;
  public notificationsCount: number;
  public isMobile: boolean;
  public amaliaWindowOpen: boolean;

  private readonly _subjectToolbarInstanceId: BehaviorSubject<string>;
  private readonly _navBarStickyPortals: Set<IPlNavbarMenuDefinition>;
  private readonly _navBarItemPortal: IPortalNavbarMenu;
  private readonly _navBarItemUser: IPortalNavbarMenu;
  private readonly _navBarNotificationCenter: IPortalNavbarMenu;
  private readonly _subscriptionAppMenu: Subscription;
  private readonly _subscriptionAppPortal: Subscription;
  private readonly _subscriptionGlobalLoading: Subscription;
  private readonly _subscriptionRouterTransitionOnSuccess: Subscription;
  private readonly _subscriptionAmaliaWindowOpen: Subscription;
  private readonly _subscriptionNotificationsCount: Subscription;
  private _subscriptionAppFaqsUrl: Subscription;
  private _selectedNavbarMenu: IPortalNavbarMenu;
  private _activePortal: string;
  private _removingNavBarPortals: boolean;
  private _observableToolbarInstanceId: Observable<string>;

  constructor(
    private readonly _uiRouterGlobals: UIRouterGlobals,
    private readonly _transition: Transition,
    private readonly _stateService: StateService,
    private readonly _logger: Logger,
    private readonly _plAlertService: PlAlertService,
    private readonly _plGlobalEventsService: PlGlobalEventsService,
    private readonly _plDynamicVisualsService: PlDynamicVisualsService,
    private readonly _appService: AppService,
    private readonly _authService: AuthService,
    private readonly _cgModalService: CGModalService,
    private readonly _cgStateService: CGStateService,
    private readonly _configSiteService: ConfigSiteService,
    private readonly _empresasService: EmpresasService,
    private readonly _portalService: PortalService,
    private readonly _amaliaWindowService: AmaliaWindowService,
    private readonly _notificationCenterService: NotificationCenterService,
    private readonly _translateService: TranslateService
  ) {
    this.sideBarActive = this.sideBarActive.bind(this);
    this.sidebarClick = this.sidebarClick.bind(this);
    this.navBarActive = this.navBarActive.bind(this);

    this.portals = [];
    this.launchMode = EAppLaunchMode.Default;
    this.launchModes = EAppLaunchMode;
    this.version = VERSION;
    this.currentYear = moment().year();
    this.isAdmin = false;
    this.hasStickyPortals = false;
    this.companyUrl = CG_COMPANY_URL;
    this.globalLoading = false;
    this.notificationsCount = 0;
    this.isMobile = isMobile();
    this.amaliaWindowOpen = false;

    this._plGlobalEventsService.on(GLOBAL_EVENT_USER_CHANGED, this._fnOnAppUserChanged);
    this._plGlobalEventsService.on(GLOBAL_EVENT_LOGO_CHANGED, this._fnOnAppLogoChanged);

    this._subjectToolbarInstanceId = new BehaviorSubject<string>(DEFAULT_TOOLBAR_ID);
    this._navBarStickyPortals = new Set<IPlNavbarMenuDefinition>();

    this._navBarItemPortal = {
      id: NAVBAR_ITEM_ID_CHANGE_PORTAL,
      title: this._portalService.appMenuTitle,
      icon: 'fa-th-large',
      menu: []
    };
    this._navBarItemUser = {
      id: 'user',
      customClass: 'navbar-item-user'
    };
    this._navBarNotificationCenter = {
      id: 'notificationcenter',
      icon: 'fa-bell',
      sticky: true,
      action: (event: IPlNavbarItemEvent) => {
        this._openNotificationCenter(<HTMLElement>event.event.target);
      }
    };

    this._removingNavBarPortals = false;

    this._portalService.addAppMenu(this._navBarItemPortal);
    this._portalService.addAppMenu(this._navBarItemUser);

    if (this._transition) {
      this._setPortalMenuTitle(this._transition.$to());
    }

    this._subscriptionAppMenu = this._portalService
      .getAppMenu()
      .pipe(skipIf(() => this._removingNavBarPortals))
      .subscribe((menu: Array<IPortalNavbarMenu>) => {
        this.navBarMenu = menu;
      });

    this._subscriptionAppPortal = this._portalService.getAppPortal().subscribe(({portalId}: IActivePortal) => {
      this._activePortal = portalId;
      if (this.navBarMenu) {
        this.navBarMenu = this.navBarMenu.slice();
      }
    });

    this._subscriptionGlobalLoading = this._appService.globalLoading().subscribe((value: boolean) => {
      this.globalLoading = value;
    });

    this._subscriptionRouterTransitionOnSuccess = this._cgStateService.routerTransitionOnSuccess().subscribe((transition: Transition) => {
      const toState: StateObject = transition.$to();
      const portalState: StateObject = toState.parent;
      const portalId: string = (<IPortalStateDeclarationData>portalState.data)?.portalId;
      if (portalId && (!this._activePortal || portalId !== this._activePortal)) {
        this._setPortalMenuTitle(toState);
      }
      const toStateDeclaration: ICGStateDeclaration = <ICGStateDeclaration>transition.to();
      if (isArray(toStateDeclaration.data?.menu)) {
        this._setSidebarMenu(toStateDeclaration.data.menu);
      }
    });

    this._subscriptionAmaliaWindowOpen = this._amaliaWindowService.windowOpen().subscribe((windowOpen: boolean) => {
      this.amaliaWindowOpen = windowOpen;
    });

    if (NOTIFICATION_CENTER_ENABLED) {
      this._portalService.addAppMenu(this._navBarNotificationCenter);
      this._subscriptionNotificationsCount = this._notificationCenterService.newNotificationsCount().subscribe((notificationsCount: number) => {
        this.notificationsCount = notificationsCount;
      });
    }
  }

  public ngOnInit(): void {
    if (!isArray(this.portals)) {
      this.portals = [];
    }
    if (!isNumber(this.launchMode)) {
      this.launchMode = EAppLaunchMode.Default;
    }
    this._evaluateSession();
    if (isObject(this.session) && isObject(this.session.erp)) {
      this._onAppLogoChanged();
      this._setNomeEmpresa();
    }
    this._updateConfig();
    this._setSidebarMenu(this._uiRouterGlobals.current.data.menu);
  }

  public ngOnDestroy(): void {
    this._subscriptionAppMenu.unsubscribe();
    this._subscriptionAppPortal.unsubscribe();
    this._subscriptionGlobalLoading.unsubscribe();
    this._subscriptionRouterTransitionOnSuccess.unsubscribe();
    this._subscriptionAmaliaWindowOpen.unsubscribe();
    if (this._subscriptionNotificationsCount) {
      this._subscriptionNotificationsCount.unsubscribe();
    }
    if (this._subscriptionAppFaqsUrl) {
      this._subscriptionAppFaqsUrl.unsubscribe();
    }
    this._plGlobalEventsService.off(GLOBAL_EVENT_USER_CHANGED, this._fnOnAppUserChanged);
    this._plGlobalEventsService.off(GLOBAL_EVENT_USER_CHANGED, this._fnOnAppLogoChanged);
  }

  public async callChangeEmpresa(): Promise<void> {
    await this._cgModalService.showChangeEmpresa();
  }

  public callAbout(): Promise<void> {
    return this._cgModalService.show(PortalAboutModalComponent);
  }

  public callTerms(): Promise<void> {
    return this._authService.identity().then((session: TUserSession) => {
      const modalInstance: NgbModalRef = this._cgModalService.showVanilla(CGTermsModalComponent);
      const componentInstance: CGTermsModalComponent = modalInstance.componentInstance;
      componentInstance.session = copy(session);
      componentInstance.viewOnly = true;
      return modalInstance.result;
    });
  }

  public openAmaliaWindow(): void {
    this._amaliaWindowService.openWindow();
  }

  public sideBarActive(menu: IPlSidebarMenu & {state?: string}): boolean {
    const currentState = this._uiRouterGlobals.current.name;
    return currentState === menu.state || currentState === entityStateNameNew(menu.state) || currentState === entityStateNameDetail(menu.state);
  }

  public sidebarClick({menu}: IPlSidebarEventItemClicked): Promise<void> {
    if (menu.href) {
      return Promise.resolve();
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._stateService.go((<any>menu).state).then(() => undefined);
  }

  public navBarActive(navBarMenu: IPlNavbarMenu): boolean {
    return !this._activePortal ? false : navBarMenu.id === this._activePortal;
  }

  public toolbarInstanceId$(): Observable<string> {
    if (!this._observableToolbarInstanceId) {
      this._observableToolbarInstanceId = this._subjectToolbarInstanceId.asObservable();
    }
    return this._observableToolbarInstanceId;
  }

  public setToolbarInstanceId(toolbarInstanceId: string): void {
    this._subjectToolbarInstanceId.next(toolbarInstanceId);
  }

  public get toolbarInstanceId(): string {
    return this._subjectToolbarInstanceId.value;
  }

  @ViewChild('templateNavbarItemUserTitle', {static: true})
  public set templateNavbarItemUserTitle(templateRef: TemplateRef<void>) {
    this._navBarItemUser.titleTemplateRef = templateRef;
  }

  @ViewChild('templateNavbarItemUserMenu', {static: true})
  public set templateNavbarItemUserMenu(templateRef: TemplateRef<void>) {
    this._navBarItemUser.templateRef = templateRef;
  }

  @ViewChild('templateNavbarNotificationCenter', {static: true})
  public set templateNavbarNotificationCenter(templateRef: TemplateRef<void>) {
    this._navBarNotificationCenter.templateRef = templateRef;
  }

  private _evaluateSession(): void {
    this.isAdmin = false;
    this._authService.hasAuthority(ROLE.ADMIN).then((value: boolean) => {
      this.isAdmin = value;
    });
  }

  private _setNomeEmpresa(): void {
    this.nomeEmpresa = this.session.erp.nEmpresa.toString();
    if (this.session.erp.nomeEmpresa) {
      this.nomeEmpresa += ` - ${this.session.erp.nomeEmpresa}`;
    }
  }

  private _updateConfig(): void {
    this._loadMenu();
    this._loadPortais();
    this._loadCGStoreUrl();
    if (this._activePortal) {
      const navBarMenu: IPortalNavbarMenu = this.navBarMenu.find((portalNavbarMenu: IPortalNavbarMenu) => portalNavbarMenu.id === this._activePortal);
      if (navBarMenu) {
        this._evaluateNavBarMenu(navBarMenu);
      }
    }
  }

  private _loadMenu(): void {
    this._navBarItemUser.menu = [];
    for (const value of this.session.lasterps) {
      if (this._navBarItemUser.menu.length === MAX_PORTALS) {
        break;
      }
      if (!value.ativo || value.nEmpresa === this.session.erp.nEmpresa) {
        continue;
      }
      const changeEmpresaParams: IAuthChangeEmpresaParams = {
        cgId: value.cgID,
        nEmpresa: value.nEmpresa
      };
      this._navBarItemUser.menu.push({
        id: undefined,
        title: value.nomeEmpresa,
        icon: 'fa-exchange',
        data: changeEmpresaParams,
        action: (event: IPlNavbarItemEvent & {item: IPortalNavbarMenu<IAuthChangeEmpresaParams>}) => this._mudaEmpresa(event.item)
      });
    }
    this._navBarItemUser.menu.push({
      id: undefined,
      title: 'global.menu.account.changeEmpresa',
      icon: 'fa-institution',
      action: () => this.callChangeEmpresa()
    });
    this._navBarItemUser.menu.push({
      id: undefined,
      title: 'global.menu.account.logout',
      icon: 'fa-sign-out',
      href: this._stateService.href(STATE_NAME_LOGIN)
    });
  }

  private _loadPortais(): void {
    this._removingNavBarPortals = true;
    for (const portal of this._navBarStickyPortals.values()) {
      this._portalService.removeAppMenu(portal);
    }
    this._navBarStickyPortals.clear();
    this._removingNavBarPortals = false;

    this._navBarItemPortal.menu = [];

    // Portais do utilizador
    for (let i = this.portals.length - 1; i >= 0; i--) {
      const portal: IJsonPortal = this.portals[i];
      const role = generatePortalId(portal.id);
      const newItem: IPortalNavbarMenu = {
        id: role,
        title: evaluatePortalTitle(portal, this._translateService),
        icon: portal.icon ? portal.icon : 'fa-window-maximize',
        stickyPortal: portal.sticky,
        action: (event: IPlNavbarItemEvent) => this._openPortal(event.item),
        portal: portal
      };
      const hasAuthority: boolean = sessionHasAuthority(this.session, role);
      let isRoleNotAccess = false;
      if (!hasAuthority) {
        const roleNotAccess: IJsonUserRole = this.session.erp.rolesNotAcess.find((userRole: IJsonUserRole) => userRole.role === role);
        isRoleNotAccess = isDefined(roleNotAccess);
      }
      if (portal.sticky || hasAuthority || isRoleNotAccess) {
        if (portal.sticky || isRoleNotAccess) {
          const launchModeAddPortal: boolean = (this.launchMode !== EAppLaunchMode.Hybrid && this.launchMode !== EAppLaunchMode.HybridPartial) || (hasAuthority && !isRoleNotAccess);
          if (portal.sticky) {
            this.hasStickyPortals = true;
          }
          if (!hasAuthority || isRoleNotAccess) {
            newItem.icon = 'fa-lock';
            let content: string = TRANSLATE_KEY_LOCKED;
            if (isRoleNotAccess) {
              content = TRANSLATE_KEY_LOCKED_EXPIRED;
            } else if (this.cgStoreUrl) {
              content = TRANSLATE_KEY_LOCKED_CG_STORE;
            }
            newItem.customClass = CLASS_NAME_LOCKED;
            newItem.content = this._translateService.instant(`scss.${content}`);
          }
          if (portal.sticky && launchModeAddPortal) {
            this._portalService.addAppMenu(newItem, 0);
            this._navBarStickyPortals.add(newItem);
          } else if (launchModeAddPortal) {
            this._navBarItemPortal.menu.unshift(newItem);
          }
        } else if (hasAuthority) {
          this._navBarItemPortal.menu.unshift(newItem);
        }
      }
    }

    if (sessionHasAuthority(this.session, ROLE.ADMIN)) {
      this._navBarItemPortal.menu.unshift({
        id: PORTAL_ADMIN_ID,
        title: 'global.states.admin.title',
        icon: 'fa-users',
        stickyPortal: false,
        action: (event: IPlNavbarItemEvent) => this._openPortal(event.item),
        state: STATE_NAME_ADMIN
      });
    }
  }

  private _loadCGStoreUrl(): void {
    this._configSiteService
      .cgStoreUrl(false)
      .then((value: string) => {
        if (!value) {
          this.cgSupportUrl = CG_SUPPORT_URL;
          return;
        }
        this.companyUrl = CG_ON_COMPANY_URL;
        this.cgStoreUrl = value;
        for (const menuItem of this.navBarMenu) {
          this._evaluateNavBarMenuLocked(menuItem);
        }
        this._subscriptionAppFaqsUrl = this._portalService.getAppFaqsUrl().subscribe((faqsUrl: string) => {
          this.cgSupportUrl = generateSupportUrl(this.cgStoreUrl, this.session.idContratoPai, this.session.erp.nEmpresa, faqsUrl);
        });
      })
      .catch((reason: unknown) => {
        this._logger.error(reason);
      });
  }

  private _mudaEmpresa(item: IPortalNavbarMenu<IAuthChangeEmpresaParams>): Promise<void> {
    const promise: Promise<void> = this._authService.changeEmpresa(item.data).then(() => undefined);
    this._appService.setGlobalLoading(promise);
    return promise;
  }

  private _openPortal(item: IPortalNavbarMenu): Promise<void> {
    if (!sessionHasAuthority(this.session, item.id)) {
      if (isMobile()) {
        this._plAlertService.warning('portals.text.noAccess');
      }
      return Promise.reject(new Error('User does not have access to this portal.'));
    }
    return new Promise<void>((resolve, reject) => {
      const promise: Promise<StateObject> = !item.portal ? this._stateService.go(item.state) : this._stateService.go(`${STATE_NAME_PORTAL}.${item.portal.url}`);
      Promise.resolve(promise)
        .then((portalStateObject: StateObject) => {
          this._setPortalMenuTitle(this._uiRouterGlobals.$current);
          const portalData: IPortalStateDeclarationData = portalStateObject.data;
          this._setSidebarMenu(portalData.menu, item);
          resolve();
        })
        .catch(reject);
    });
  }

  private _setPortalMenuTitle(stateObject: StateObject): void {
    const portalStateObject: StateObject = stateObject.parent;
    if (portalStateObject && isObject(portalStateObject.data)) {
      const portalStateData: IPortalStateDeclarationData = portalStateObject.data;
      this._portalService.setAppMenuTitle(portalStateData.pageTitle);
      this._portalService.setAppPortal({
        portal: portalStateData.portalUrl,
        portalId: portalStateData.portalId,
        portalTitle: portalStateData.pageTitle,
        stateName: portalStateObject.name
      });
    }
  }

  private _setSidebarMenu(menu: Array<IPortalSidebarMenu>, item?: IPortalNavbarMenu): void {
    if (item) {
      this._evaluateNavBarMenu(item);
    }
    this.sidebarMenu = menu.filter((menuItem: IPortalSidebarMenu) => {
      // Filter out parent
      if (menuItem.visible === false) {
        return false;
      }
      if (!isArray(menuItem.menu)) {
        menuItem.href = this._stateService.href(menuItem.state);
      }
      // Filter out childs
      if (isArray(menuItem.menu)) {
        menuItem.menu = menuItem.menu.filter((menuSubItem) => {
          if (menuSubItem.visible === false) {
            return false;
          }
          if (!isArray(menuSubItem.menu)) {
            menuSubItem.href = this._stateService.href(menuSubItem.state);
          }
          return true;
        });
      }
      return true;
    });
  }

  private _evaluateNavBarMenu(navBarMenu: IPortalNavbarMenu): void {
    if (this._selectedNavbarMenu) {
      this._selectedNavbarMenu.menu = [];
      this._selectedNavbarMenu = undefined;
    }
    this._navBarItemPortal.visible = !navBarMenu.stickyPortal;
    if (!this._navBarItemPortal.visible) {
      navBarMenu.menu = this._navBarItemPortal.menu;
      this._selectedNavbarMenu = navBarMenu;
      this._selectedNavbarMenu.active = true;
    }
    this._navBarItemPortal.active = true;
  }

  private _evaluateNavBarMenuLocked(navBarMenu: IPlNavbarMenu): void {
    if (navBarMenu.customClass === CLASS_NAME_LOCKED) {
      navBarMenu.customClass += ` ${TRANSLATE_KEY_LOCKED_CG_STORE}`;
    }
    if (navBarMenu.menu) {
      for (const item of navBarMenu.menu) {
        this._evaluateNavBarMenuLocked(item);
      }
    }
  }

  private _onAppUserChanged(): Promise<void> {
    return this._authService.identity().then((session: TUserSession) => {
      this.session = session;
      this._setNomeEmpresa();
      this._loadPortais();
      this._evaluateSession();
    });
  }

  private _onAppLogoChanged(): Promise<void> {
    this.logotipoUrl = '';
    return timeout().then(() => {
      return firstValueFrom(this._empresasService.getLogotipoUrl(this.session.erp.nEmpresa)).then((value: string) => {
        this.logotipoUrl = value;
      });
    });
  }

  private _openNotificationCenter(hostElement: HTMLElement): void {
    if (!hostElement.matches(NAVBAR_NOTIFICATION_CENTER)) {
      hostElement = hostElement.closest(NAVBAR_NOTIFICATION_CENTER);
    }
    const reference: PlDynamicVisualsReference<void> = this._plDynamicVisualsService.openVanilla(NotificationCenterComponent);
    const componentInstance: NotificationCenterComponent = reference.componentInstance;
    componentInstance.hostElement = hostElement;
    reference.result.catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }

  private readonly _fnOnAppUserChanged: () => Promise<void> = () => this._onAppUserChanged();

  private readonly _fnOnAppLogoChanged: () => Promise<void> = () => this._onAppLogoChanged();
}
