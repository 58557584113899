import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
  selector: 'ng-template[plTooltipContent]'
})
export class PlTooltipContentDirective {
  @Input() public plTooltipContent: any;

  constructor(
    public readonly templateRef: TemplateRef<void>
  ) {
  }
}
