import {Component, Injector, OnInit} from '@angular/core';
import {PlEditInputTypeComponent} from '../../generic/input/edit.input.type.component';

@Component({
  selector: 'pl-edit-text',
  templateUrl: '../../generic/input/edit.input.type.component.html'
})
export class PlEditTextComponent extends PlEditInputTypeComponent<string> implements OnInit {
  constructor(protected readonly _injector: Injector) {
    super('text', _injector);
  }
}
