import {HttpResponse} from '@angular/common/http';
import {ProviderLike} from '@uirouter/core';
import {isEmpty, isObject, PlTranslateService} from 'pl-comps-angular';
import {CGVatService} from '../../components/vat/cgvat.service';
import {ClientesEditComponent} from './components/clientes/edit/clientes.entity.edit.component';
import {ClifosEditComponent} from './components/edit/clifos.entity.edit.component';
import {ClifosListComponent} from './components/list/clifos.entity.list.component';
import {CONSTANT_ABREV_FISCAL_PAIS_NACIONAL, CONSTANT_COD_PAIS_NACIONAL, CONSTANT_NOME_PAIS_NACIONAL} from '../../../config/constants';
import {EEntityStateDetailType} from '../../../common/utils/entity.state.utils';
import {EGenericImporterType} from '../../components/genericimporter/generic.importer.component.interface';
import {ENTITY_NAME_CLIENTES, ENTITY_NAME_CLIFOS, ENTITY_NAME_FORNECEDORES, ENTITY_NAME_OUTROS_DEVEDORES_CREDORES, IClifo, IClifoGrupo, IClifosEntity} from './clifos.entity.interface';
import {ENTITY_NAME_NATUREZAS_CONTAB} from '../naturezascontab/naturezasContab.entity.interface';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {ExternalCodPostaisService} from '../../services/external.cod.postais.service';
import {ExternalNifsService} from '../../services/externalnifs/externalnifs.service';
import {FornecedoresEditComponent} from './components/fornecedores/edit/fornecedores.entity.edit.component';
import {IJsonNaturezaContab} from '../naturezascontab/jsonNaturezaContab.entity.interface';
import {IJsonPais} from '../paises/jsonPais.entity.interface';
import {OutrosDevedoresCredoresEditComponent} from './components/outrosdevedorescredores/edit/outrosDevedoresCredores.entity.edit.component';
import {ROLE} from '../../services/role.const';
import {DATA_SOURCE_NAME_TIPO_FAC_PUBLICA_MODELO} from '../../datasources/tipofacpublicamodelo/tipoFacPublicaModelo.datasource.interface';
import {ENTITY_NAME_IDIDE} from '../idide/idide.entity.interface';
import {ConfigService} from '../../services/config/config.service';
import {CG_DEFAULT_AT_NIF} from '../../services/config/config.service.interface';
import {ENTITY_NAME_SOLIC} from '../solic/solic.entity.interface';
import {IJsonClifo, IJsonClifoBasic} from './jsonClifo.entity.interface';
import {IApiQueryResponse, IApiRequestConfig, THttpQueryResponse} from '../../services/api/api.service.interface';
import {IJsonMeioPagamento} from '../meiospagamento/jsonMeioPagamento.entity.interface';
import {IJsonNIFs} from '../nifs/jsonNifs.entity.interface';
import {IJsonCodPostal} from '../codpostais/jsonCodPostal.entity.interface';
import {SITUACAO_ATUAL_ATIVA, SITUACAO_VIES_NOT_OK, SITUACAO_VIES_OK} from '../../services/externalnifs/externalnifs.interface';
import {IDevExpressDataGridColumnCustomizeTextCellInfo} from '../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {DATA_SOURCE_TIPO_FAC_PUBLICA_MODELO} from '../../datasources/tipofacpublicamodelo/tipoFacPublicaModelo.datasource';
import {IDataSourceItem} from '../../components/datasource/datasources.interface';
import {ENTITY_NAME_CONDICOES_COMERCIAIS} from '../condicoescomerciais/condicoesComerciais.entity.interface';
import {ENTITY_NAME_PAISES} from '../paises/paises.entity.interface';
import {ENTITY_NAME_VENDEDORES} from '../vendedores/vendedores.entity.interface';
import {ENTITY_NAME_MOEDA} from '../../modules/portalcontabilidade/manutencao/moeda/moeda.entity.interface';
import {ENTITY_NAME_SECTORES} from '../sectores/sectores.entity.interface';

const fieldsNew =
  'nomePais,nContribuint,nConta,idSubGrContaCorrente,naturezaContab,nome,rua,lugar,codPostal,localidade,telefone,fax,email,observacoes,observacoes2,iban,swift,nomeRet,facPublicaModelo,' +
  'tituloTextoCab,tituloTextoRod,desactivado,naoPodeFacturar';
const fieldsEdit =
  'nome,nConta,nContribuint,nIdAltern,situacaoActual,nomePais,rua,lugar,codPostal,localidade,telefone,fax,email,observacoes,observacoes2,iban,swift,nomeRet,facPublicaModelo,' +
  'tituloTextoCab,tituloTextoRod,desactivado,naoPodeFacturar';
const fieldsList =
  'nConta,nome,telefone,email,nContribuint,observacoes,observacoes2,nomeZona,nomeVendedor,iban,swift,facPublicaModelo,nomePagamento,nomeCondComerciais,idIdeDescricao,rua,codPostal,localidade,nomeSector';
const fieldsClientesNew = `${fieldsNew},nomePagamento,nomeCondComerciais,idIdeDescricao,nomeZona,nomeVendedor`;
const fieldsClientesEdit = `${fieldsEdit},nomePagamento,nomeCondComerciais,idIdeDescricao,nomeZona,nomeVendedor`;
const fieldsFornecedoresNew = `${fieldsNew},nomePagamento,nomeCondComerciais,idIdeDescricao`;
const fieldsFornecedoresEdit = `${fieldsEdit},nomePagamento,nomeCondComerciais,idIdeDescricao`;
const fieldsSearch = 'nConta,nome,telefone,email,nContribuint,nomeZona,nomeVendedor';

const codPostaisTimeOut = 1000;

export const ENTITY_CLIFOS = [
  PlTranslateService,
  (plTranslateService: PlTranslateService): IClifosEntity => {
    return generateEntity(
      {
        name: ENTITY_NAME_CLIFOS,
        searchPlaceholder: `${ENTITY_NAME_CLIFOS}.pesquisa`,
        metadata: {
          searchFields: fieldsSearch,
          listFields: fieldsList,
          detailFields: fieldsEdit,
          newFields: fieldsNew
        },
        listComponent: ClifosListComponent,
        detailComponent: ClifosEditComponent,
        importerType: undefined
      },
      plTranslateService
    );
  }
];

export const ENTITY_CLIENTES = [
  PlTranslateService,
  (plTranslateService: PlTranslateService): IClifosEntity => {
    return generateEntity(
      {
        name: ENTITY_NAME_CLIENTES,
        searchPlaceholder: `${ENTITY_NAME_CLIENTES}.pesquisa`,
        metadata: {
          searchFields: fieldsSearch,
          listFields: fieldsList,
          detailFields: fieldsClientesEdit,
          newFields: fieldsClientesNew
        },
        listComponent: ClifosListComponent,
        detailComponent: ClientesEditComponent,
        importerType: EGenericImporterType.Clientes
      },
      plTranslateService
    );
  }
];

export const ENTITY_FORNECEDORES = [
  PlTranslateService,
  (plTranslateService: PlTranslateService): IClifosEntity => {
    return generateEntity(
      {
        name: ENTITY_NAME_FORNECEDORES,
        searchPlaceholder: `${ENTITY_NAME_FORNECEDORES}.pesquisa`,
        metadata: {
          searchFields: fieldsSearch,
          listFields: fieldsList,
          detailFields: fieldsFornecedoresEdit,
          newFields: fieldsFornecedoresNew
        },
        listComponent: ClifosListComponent,
        detailComponent: FornecedoresEditComponent,
        importerType: EGenericImporterType.Fornecedores
      },
      plTranslateService
    );
  }
];

export const ENTITY_OUTROS_DEVEDORES_CREDORES = [
  PlTranslateService,
  (plTranslateService: PlTranslateService): IClifosEntity => {
    return generateEntity(
      {
        name: ENTITY_NAME_OUTROS_DEVEDORES_CREDORES,
        searchPlaceholder: `${ENTITY_NAME_OUTROS_DEVEDORES_CREDORES}.pesquisa`,
        metadata: {
          searchFields: fieldsSearch,
          listFields: fieldsList,
          detailFields: fieldsEdit,
          newFields: fieldsNew
        },
        listComponent: ClifosListComponent,
        detailComponent: OutrosDevedoresCredoresEditComponent,
        importerType: EGenericImporterType.OutrosDevedoresCredores
      },
      plTranslateService
    );
  }
];

function generateEntity(grupo: IClifoGrupo, plTranslateService: PlTranslateService): IClifosEntity {
  return {
    name: grupo.name,
    roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.CLIENTCONNECT, ROLE.ATIVOS, ROLE.FINANCEIRO],
    searchPlaceholder: grupo.searchPlaceholder,
    metadata: {
      keyName: 'id',
      fields: [
        {name: 'id', caption: 'clifos.fields.id', visible: false, properties: {disabled: true}},
        {
          name: 'nome',
          caption: 'clifos.fields.nome',
          placeholder: 'clifos.fields.nomePlaceholder',
          validators: {required: true, maxlength: 100}
        },
        {
          name: 'nContribuint',
          caption: 'clifos.fields.nContribuint',
          placeholder: 'clifos.fields.nContribuintPlaceholder',
          entity: {name: 'nifs', keyTarget: 'nContribuint', fieldsMap: {nContribuinte: 'nContribuint'}},
          validators: {required: true, maxlength: 25}
        },
        {
          name: 'nConta',
          width: '120px',
          caption: 'clifos.fields.nConta',
          placeholder: 'clifos.fields.nContaPlaceholder',
          properties: {disabled: true},
          validators: {maxlength: 75}
        },
        {
          name: 'situacaoActual',
          caption: 'clifos.fields.situacaoActual',
          properties: {disabled: true}
        },
        {
          name: 'nIdAltern',
          caption: 'clifos.fields.nIdAltern',
          placeholder: 'clifos.fields.nIdAlternPlaceholder',
          properties: {disabled: true}
        },
        {
          name: 'rua',
          caption: 'clifos.fields.rua',
          placeholder: 'clifos.fields.ruaPlaceholder',
          validators: {maxlength: 90}
        },
        {
          name: 'lugar',
          caption: 'Rua (Cont.)',
          placeholder: 'clifos.fields.ruaPlaceholder',
          validators: {maxlength: 50}
        },
        {
          name: 'codPostal',
          caption: 'clifos.fields.codPostal',
          placeholder: 'clifos.fields.codPostalPlaceholder',
          entity: {name: 'codpostais', keyTarget: 'codPostal'},
          properties: {validators: {maxlength: 20}}
        },
        {
          name: 'localidade',
          caption: 'clifos.fields.localidade',
          placeholder: 'clifos.fields.localidadePlaceholder',
          validators: {maxlength: 50}
        },
        {name: 'codPais', visible: false},
        {
          name: 'nomePais',
          caption: 'clifos.fields.nomePais',
          placeholder: 'clifos.fields.nomePaisPlaceholder',
          entity: {name: ENTITY_NAME_PAISES, keyTarget: 'codPais', outputTarget: 'nomePais'}
        },
        {name: 'codMoeda', visible: false},
        {
          name: 'nomeMoeda',
          caption: 'clifos.fields.nomeMoeda',
          placeholder: 'clifos.fields.nomeMoedaPlaceholder',
          entity: {name: ENTITY_NAME_MOEDA, keyTarget: 'codMoeda', outputTarget: 'nomeMoeda'}
        },
        {
          name: 'telefone',
          type: 'mask',
          caption: 'clifos.fields.telefone',
          placeholder: 'clifos.fields.telefonePlaceholder',
          properties: {mask: '[\\+][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9]'},
          validators: {minlength: 9, maxlength: 20}
        },
        {
          name: 'fax',
          caption: 'clifos.fields.fax',
          placeholder: 'clifos.fields.faxPlaceholder',
          validators: {maxlength: 20}
        },
        {name: 'email', type: 'email', caption: 'clifos.fields.email', placeholder: 'clifos.fields.emailPlaceholder'},
        {
          name: 'codRet',
          visible: false
        },
        {
          name: 'nomeRet',
          caption: 'clifos.fields.codRet',
          placeholder: 'clifos.fields.codRetPlaceholder',
          entity: {name: 'taxasretencao', keyTarget: 'codRet', outputTarget: 'nomeRet'},
          visible: grupo.name === 'clientes' || grupo.name === 'fornecedores' || grupo.name === 'outrosdevedorescredores'
        },
        {name: 'codCondComerciais', visible: false},
        {
          name: 'nomeCondComerciais',
          caption: 'clifos.fields.codCondComerciais',
          placeholder: 'clifos.fields.codCondComerciaisPlaceholder',
          entity: {name: ENTITY_NAME_CONDICOES_COMERCIAIS, keyTarget: 'codCondComerciais', outputTarget: 'nomeCondComerciais'},
          visible: grupo.name === 'clientes' || grupo.name === 'fornecedores',
          properties: {devExpress: {dataGrid: {visible: false}}}
        },
        {name: 'codPagamento', visible: false},
        {
          name: 'nomePagamento',
          caption: 'clifos.fields.codPagamento',
          placeholder: 'clifos.fields.codPagamentoPlaceholder',
          entity: {name: 'condicoespagamento', keyTarget: 'codPagamento', outputTarget: 'nomePagamento'},
          visible: grupo.name === 'clientes' || grupo.name === 'fornecedores',
          properties: {devExpress: {dataGrid: {visible: false}}}
        },
        {name: 'codZona', visible: false},
        {
          name: 'nomeZona',
          caption: 'clifos.fields.codZona',
          placeholder: 'clifos.fields.codZonaPlaceholder',
          entity: {name: 'zonas', keyTarget: 'codZona', outputTarget: 'nomeZona'},
          visible: false
        },
        {name: 'codVendedor', visible: false},
        {
          name: 'nomeVendedor',
          caption: 'clifos.fields.codVendedor',
          placeholder: 'clifos.fields.codVendedorPlaceholder',
          entity: {name: ENTITY_NAME_VENDEDORES, keyTarget: 'codVendedor', outputTarget: 'nomeVendedor'},
          visible: false
        },
        {
          name: 'observacoes',
          type: 'textarea',
          caption: 'clifos.fields.observacoes',
          placeholder: 'clifos.fields.observacoes',
          properties: {devExpress: {dataGrid: {visible: false, showInColumnChooser: true, allowSearch: false, allowFiltering: false}}}
        },
        {
          name: 'observacoes2',
          type: 'textarea',
          caption: 'clifos.fields.observacoes2',
          placeholder: 'clifos.fields.observacoes',
          properties: {devExpress: {dataGrid: {visible: false, showInColumnChooser: true, allowSearch: false, allowFiltering: false}}}
        },
        {name: 'desactivado', type: 'boolean', caption: 'clifos.fields.desactivado'},
        {name: 'naoPodeFacturar', type: 'boolean', caption: 'clifos.fields.naoPodeFacturar'},
        {
          name: 'naturezaContab',
          caption: 'clifos.fields.idSubGrContaCorrente',
          placeholder: 'clifos.fields.idSubGrContaCorrentePlaceholder',
          entity: {name: ENTITY_NAME_NATUREZAS_CONTAB},
          properties: {filter: `clifoType=${grupo.name}`},
          validators: {required: true}
        },
        {name: 'iban', caption: 'clifos.fields.iban', placeholder: 'clifos.fields.ibanPlaceholder', properties: {devExpress: {dataGrid: {visible: false, allowSearch: false, allowFiltering: false}}}},
        {
          name: 'swift',
          caption: 'clifos.fields.swift',
          placeholder: 'clifos.fields.swiftPlaceholder',
          properties: {devExpress: {dataGrid: {visible: false, allowSearch: false, allowFiltering: false}}}
        },
        {
          name: 'facPublicaModelo',
          type: DATA_SOURCE_NAME_TIPO_FAC_PUBLICA_MODELO,
          caption: 'clifos.fields.facPublicaModelo',
          placeholder: 'clifos.fields.facPublicaModelo',
          visible: grupo.name === 'clientes' || grupo.name === 'fornecedores' || grupo.name === 'outrosdevedorescredores',
          properties: {
            devExpress: {
              dataGrid: {
                visible: false,
                allowSearch: false,
                allowFiltering: false,
                customizeText: (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string => {
                  if (cellInfo.target === 'row') {
                    const index = DATA_SOURCE_TIPO_FAC_PUBLICA_MODELO.data.findIndex((item: IDataSourceItem<number>) => item.value === <number>cellInfo.value);
                    if (index !== -1) {
                      return plTranslateService.translate(DATA_SOURCE_TIPO_FAC_PUBLICA_MODELO.data[index].name);
                    }
                    return cellInfo.valueText;
                  }
                  return cellInfo.valueText;
                }
              }
            }
          }
        },
        {name: 'idIdeChav', visible: false},
        {
          name: 'idIdeDescricao',
          caption: 'clifos.fields.idideChav',
          placeholder: 'clifos.fields.idideChavPlacholder',
          entity: {name: ENTITY_NAME_IDIDE, keyTarget: 'idIdeChav', outputTarget: 'idIdeDescricao'},
          visible: grupo.name === 'clientes' || grupo.name === 'fornecedores',
          properties: {devExpress: {dataGrid: {visible: false}}}
        },
        {name: 'nTxtCabeca', visible: false},
        {
          name: 'tituloTextoCab',
          caption: 'clifos.fields.nTxtCabeca',
          placeholder: 'clifos.fields.nTxtCabecaPlacholder',
          entity: {name: ENTITY_NAME_SOLIC, keyTarget: 'nTxtCabeca', outputTarget: 'tituloTextoCab', filter: 'tipoTexto=0'},
          visible: grupo.name === 'clientes' || grupo.name === 'fornecedores' || grupo.name === 'outrosdevedorescredores'
        },
        {name: 'nTxtPE', visible: false},
        {
          name: 'tituloTextoRod',
          caption: 'clifos.fields.nTxtPE',
          placeholder: 'clifos.fields.nTxtPEPlacholder',
          entity: {name: ENTITY_NAME_SOLIC, keyTarget: 'nTxtPE', outputTarget: 'tituloTextoRod', filter: 'tipoTexto=1'},
          visible: grupo.name === 'clientes' || grupo.name === 'fornecedores' || grupo.name === 'outrosdevedorescredores'
        },
        {name: 'sectorId', visible: false},
        {
          name: 'nomeSector',
          caption: 'clifos.fields.sector',
          placeholder: 'clifos.fields.sector',
          entity: {name: ENTITY_NAME_SECTORES, keyTarget: 'sectorId', outputTarget: 'nomeSector'},
          visible: false
        }
      ],
      order: 'nome',
      searchFields: grupo.metadata.searchFields,
      detailFields: grupo.metadata.detailFields,
      listFields: grupo.metadata.listFields,
      newFields: grupo.metadata.newFields
    },
    autocomplete: {
      rowTemplate: '{{nConta}} - {{nome}}, {{nContribuint}}',
      output: 'nome',
      searchFields: 'nConta,nome,nContribuint',
      cacheValues: true
    },
    actions: {
      new: true,
      detail: true,
      edit: true,
      delete: true,
      search: true,
      filter: true,
      batchAdd: true
    },
    list: {
      state: {
        component: grupo.listComponent,
        resolve: [resolverGrupo(grupo)],
        params: {
          query: {
            type: 'string',
            value: ''
          },
          nConta: {
            type: 'string',
            value: ''
          }
        }
      }
    },
    detail: {
      state: {
        component: grupo.detailComponent,
        resolve: [resolverGrupo(grupo)]
      },
      template: {
        items: [
          {type: 'field', order: 1, field: 'nome'},
          {type: 'group', order: 2, fields: [{field: 'nomePais'}, {field: 'nContribuint'}]},
          {type: 'field', order: 3, field: 'nomeMoeda'},
          {type: 'group', order: 4, fields: [{field: 'nConta'}, {field: 'nIdAltern'}]},
          {type: 'field', order: 5, field: 'situacaoActual'},
          {type: 'field', order: 6, field: 'rua'},
          {type: 'field', order: 7, field: 'lugar'},
          {type: 'group', order: 8, fields: [{field: 'codPostal'}, {field: 'localidade'}]},
          {type: 'group', order: 9, fields: [{field: 'telefone'}, {field: 'fax'}]},
          {type: 'field', order: 10, field: 'email'},
          {type: 'group', order: 11, fields: [{field: 'iban'}, {field: 'swift'}]},
          {type: 'field', order: 12, field: 'nomeRet'},
          {type: 'group', order: 13, fields: [{field: 'nomeCondComerciais'}, {field: 'nomePagamento'}]},
          {type: 'field', order: 14, field: 'idIdeDescricao'},
          {type: 'field', order: 15, field: 'observacoes'},
          {type: 'field', order: 16, field: 'observacoes2'},
          {type: 'field', order: 17, field: 'facPublicaModelo'},
          {type: 'group', order: 18, fields: [{field: 'tituloTextoCab'}, {field: 'tituloTextoRod'}]},
          {type: 'group', order: 19, fields: [{field: 'desactivado'}, {field: 'naoPodeFacturar'}]}
        ]
      }
    },
    new: {
      state: {
        component: grupo.detailComponent,
        resolve: [resolverGrupo(grupo)]
      },
      template: {
        items: [
          {type: 'group', order: 1, fields: [{field: 'nomePais'}, {field: 'nContribuint'}]},
          {type: 'field', order: 2, field: 'nome'},
          {type: 'group', order: 3, fields: [{field: 'naturezaContab'}, {field: 'nConta'}]},
          {type: 'field', order: 4, field: 'rua'},
          {type: 'field', order: 5, field: 'lugar'},
          {type: 'group', order: 6, fields: [{field: 'codPostal'}, {field: 'localidade'}]},
          {type: 'group', order: 7, fields: [{field: 'telefone'}, {field: 'fax'}]},
          {type: 'field', order: 8, field: 'email'},
          {type: 'group', order: 9, fields: [{field: 'iban'}, {field: 'swift'}]},
          {type: 'field', order: 10, field: 'nomeRet'},
          {type: 'group', order: 11, fields: [{field: 'nomeCondComerciais'}, {field: 'nomePagamento'}]},
          {type: 'field', order: 12, field: 'idIdeDescricao'},
          {type: 'field', order: 13, field: 'observacoes'},
          {type: 'field', order: 14, field: 'observacoes2'},
          {type: 'field', order: 15, field: 'facPublicaModelo'},
          {type: 'group', order: 16, fields: [{field: 'tituloTextoCab'}, {field: 'tituloTextoRod'}]},
          {type: 'group', order: 17, fields: [{field: 'desactivado'}, {field: 'naoPodeFacturar'}]}
        ]
      }
    },
    serviceInjectable: [
      EntityServiceBuilder,
      ExternalCodPostaisService,
      ExternalNifsService,
      CGVatService,
      ConfigService,
      function (
        entityServiceBuilder: EntityServiceBuilder,
        externalCodPostaisService: ExternalCodPostaisService,
        externalNifsService: ExternalNifsService,
        cgVatService: CGVatService,
        configService: ConfigService
      ) {
        const serviceNaturezasContab = entityServiceBuilder.build<IJsonNaturezaContab>(ENTITY_NAME_NATUREZAS_CONTAB);
        const servicePaises = entityServiceBuilder.build<IJsonPais>(ENTITY_NAME_PAISES);
        const serviceMeiosPagamento = entityServiceBuilder.build<IJsonMeioPagamento>('meiosPagamento');

        let abrevFiscalEmpresa: string;

        this.onInit = (model, type: EEntityStateDetailType) => {
          if (type === EEntityStateDetailType.NEW) {
            model.codPais = configService.configurations.empresa.codPais ? configService.configurations.empresa.codPais : CONSTANT_COD_PAIS_NACIONAL;
            model.nomePais = configService.configurations.empresa.nomePais ? configService.configurations.empresa.nomePais : CONSTANT_NOME_PAIS_NACIONAL;
            model.nContribuint = CG_DEFAULT_AT_NIF;
            abrevFiscalEmpresa = configService.configurations.empresa.abrevFiscal ? configService.configurations.empresa.abrevFiscal : CONSTANT_ABREV_FISCAL_PAIS_NACIONAL;

            serviceNaturezasContab.query({pesquisa: `clifoType=${grupo.name}`}).then((response: THttpQueryResponse<IJsonNaturezaContab>) => {
              if (response.body.list.length) {
                let targetRadical: string;
                if (grupo.name === 'clientes') {
                  targetRadical = configService.configurations.clientesFornecedores.radicalClientes;
                } else if (grupo.name === 'fornecedores') {
                  targetRadical = configService.configurations.clientesFornecedores.radicalFornecedores;
                } else if (grupo.name === 'outrosdevedorescredores') {
                  targetRadical = configService.configurations.clientesFornecedores.radicalOutrosDevedoresCredores;
                }

                if (isEmpty(targetRadical)) {
                  model.naturezaContab = response.body.list[0];
                } else {
                  for (const item of response.body.list) {
                    if (item.radical.startsWith(targetRadical)) {
                      model.naturezaContab = item;
                      break;
                    }
                  }
                }

                this.proximaconta(model.naturezaContab.radical, model.naturezaContab.nDigitosSequencia).then((responseProximaConta: HttpResponse<string>) => {
                  model.nConta = responseProximaConta.body;
                });
              }
            });
          } else if (model?.nConta && model?.nContribuint) {
            let nContribuinte;
            if (model.abrevFiscal && model.nContribuint.startsWith(model.abrevFiscal)) {
              nContribuinte = model.nContribuint;
            } else {
              const abrevPais = model.abrevFiscal && model.abrevFiscal !== '' ? model.abrevFiscal : 'PT';
              nContribuinte = abrevPais.concat(model.nContribuint);
            }

            if (!cgVatService.checkVAT(nContribuinte).isValid) {
              return;
            }
            externalNifsService.get(model.nContribuint).then((response) => {
              if (response) {
                model.situacaoActual = response.situacaoActual;
                model.nifSituacaoViesDescricao =
                  response.situacaoActual === SITUACAO_ATUAL_ATIVA || response.situacaoActual === ''
                    ? SITUACAO_VIES_OK
                    : model.nContribuint === CG_DEFAULT_AT_NIF || nContribuinte === model.abrevFiscal.concat(CG_DEFAULT_AT_NIF)
                      ? ''
                      : SITUACAO_VIES_NOT_OK;
              } else {
                model.situacaoActual = '';
                model.nifSituacaoViesDescricao = model.nContribuint === CG_DEFAULT_AT_NIF || nContribuinte === model.abrevFiscal.concat(CG_DEFAULT_AT_NIF) ? '' : SITUACAO_VIES_NOT_OK;
              }
            });
          }
        };

        this.proximaconta = (radical: string, ndigitosseq: number) => {
          return this.get({id: `${radical}/proximaconta`, params: {ndigitosseq: ndigitosseq}});
        };

        this.getNomeNifDuplicado = (nome: string, ncontribuint: string) => {
          return this.get<IApiQueryResponse<IJsonClifoBasic>>({id: 'nomenifduplicado', params: {nome: nome, ncontribuint: ncontribuint}});
        };

        this.getFirst = () => {
          return this.get<IJsonClifo>({id: 'first'});
        };

        this.getNext = (id: string) => {
          return this.get<IJsonClifo>({id: 'next', params: {id: id}});
        };

        this.getPrev = (id: string) => {
          return this.get<IJsonClifo>({id: 'previous', params: {id: id}});
        };

        this.getLast = () => {
          return this.get<IJsonClifo>({id: 'last'});
        };

        this.getSaldoEmAberto = (id: string) => {
          return this.get<number>({id: `${id}/saldoemaberto`});
        };

        this.deleteFoto = (nConta: string) => {
          return this.delete({url: `${nConta}/foto`});
        };

        this.getFoto = (nConta: string, config?: IApiRequestConfig) => {
          return this.get<Blob>({id: `${nConta}/foto`, responseType: 'blob', ...config});
        };

        this.setFoto = (nConta: string, file: File, config?: IApiRequestConfig) => {
          const formData: FormData = new FormData();
          formData.append(file.name, file);
          return this.upload<void>({url: `${nConta}/foto`, body: formData, ...config});
        };

        this.getModeloImportacao = () => this.get<Blob>({id: 'import/modelo/xls', responseType: 'blob'});

        this.getExecuteImportacaoUrl = () => `${this.entityUrl()}/import/execute/xls`;

        this.executeImportacao = () => this.apiService.post<Blob, void>({url: this.getExecuteImportacaoUrl(), responseType: 'blob'});

        this.observe('nContribuint', (newValue: string | IJsonNIFs, model: IClifo) => {
          if (!newValue) {
            return Promise.resolve();
          }

          const nContribuinteNew: string = isObject(newValue) ? (<IJsonNIFs>newValue).nContribuinte : String(newValue);

          return new Promise<void>((resolve, reject) => {
            let nContribuinte;
            let abrevPais;
            if (model.abrevFiscal && nContribuinteNew.startsWith(model.abrevFiscal)) {
              abrevPais = model.abrevFiscal;
              nContribuinte = nContribuinteNew;
            } else {
              abrevPais = model.abrevFiscal && model.abrevFiscal !== '' ? model.abrevFiscal : 'PT';
              nContribuinte = abrevPais.concat(nContribuinteNew);
            }

            if (!cgVatService.checkVAT(nContribuinte).isValid) {
              return;
            }

            externalNifsService
              .get(nContribuinteNew)
              .then((response) => {
                if (response) {
                  model.nome = response.entidade.trim();
                  model.codPostal = response.codigoPostal.trim();
                  model.rua = response.morada.trim();
                  model.localidade = response.localidade.trim();
                  model.fax = response.fax.trim();
                  model.telefone = response.telefone.trim();
                  model.email = response.email.trim();
                  model.situacaoActual = response.situacaoActual.trim();
                  model.nifSituacaoViesDescricao =
                    response.situacaoActual === SITUACAO_ATUAL_ATIVA || response.situacaoActual === ''
                      ? SITUACAO_VIES_OK
                      : nContribuinteNew === CG_DEFAULT_AT_NIF || nContribuinte === abrevPais.concat(CG_DEFAULT_AT_NIF)
                        ? ''
                        : SITUACAO_VIES_NOT_OK;
                }
                resolve();
              })
              .catch(reject);
          });
        });

        this.observe('codPostal', (newValue: string | IJsonCodPostal, model: IClifo) => {
          if (!newValue) {
            return;
          }

          const codPostal: string = isObject(newValue) ? (<IJsonCodPostal>newValue).codPostal : String(newValue);

          setTimeout(() => {
            externalCodPostaisService.get(codPostal).then((response) => {
              if (!response?.length) {
                return;
              }
              const helperCodPostal = response[0];
              if (helperCodPostal.rua && !model.rua) {
                model.rua = helperCodPostal.rua;
              }
              if (helperCodPostal.designacao) {
                model.localidade = helperCodPostal.designacao;
              }
            });
          }, codPostaisTimeOut);
        });

        this.observe('codPais', (newValue: number | IJsonPais, model: IClifo) => {
          if (!newValue) {
            return Promise.resolve();
          }

          const codPais: number = isObject(newValue) ? (<IJsonPais>newValue).codPais : Number(newValue);

          return new Promise<void>((resolve, reject) => {
            servicePaises
              .get({id: codPais})
              .then((response: HttpResponse<IJsonPais>) => {
                if (response.body) {
                  model.abrevFiscal = response.body.abrevFiscal;
                  model.nContribuint = response.body.abrevFiscal === abrevFiscalEmpresa ? CG_DEFAULT_AT_NIF : response.body.abrevFiscal.concat(CG_DEFAULT_AT_NIF);
                  resolve();
                }
              })
              .catch(reject);
          });
        });

        this.observe('naturezaContab', (newValue: string | IJsonNaturezaContab, model: IClifo) => {
          if (isEmpty(newValue)) {
            return Promise.resolve();
          }

          const idSubGrContaCorrente: string = isObject(newValue) ? (<IJsonNaturezaContab>newValue).idSubGrContaCorrente : String(newValue);

          return new Promise<void>((resolve, reject) => {
            serviceNaturezasContab
              .get({id: idSubGrContaCorrente})
              .then((response) => {
                if (!response.body) {
                  resolve();
                  return;
                }
                this.proximaconta(response.body.radical, response.body.nDigitosSequencia)
                  .then((responseProximaConta: HttpResponse<string>) => {
                    model.nConta = responseProximaConta.body;
                    resolve();
                  })
                  .catch(reject);
              })
              .catch(reject);
          });
        });

        this.observe('iban', (newValue: string, model: IClifo) => {
          if (isEmpty(newValue)) {
            return Promise.resolve();
          }
          return new Promise<void>((resolve, reject) => {
            serviceMeiosPagamento
              .get<string>({url: `trfsepa/iban/${newValue}/swift`})
              .then((response: HttpResponse<string>) => {
                model.swift = response.body ? response.body : '';
                resolve();
              })
              .catch(reject);
          });
        });
      }
    ]
  };
}

export function resolverGrupo(grupo: IClifoGrupo): ProviderLike {
  return {provide: 'grupo', useValue: grupo};
}
