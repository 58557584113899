import {ENTITY_NAME_RECIBOS, IRecibosEntity} from './recibos.entity.interface';
import {RecibosEditComponent} from './components/edit/recibos.entity.edit.component';
import {RecibosListComponent} from './components/list/recibos.entity.list.component';
import {ROLE} from '../../services/role.const';

export const ENTITY_RECIBOS: IRecibosEntity = {
  name: ENTITY_NAME_RECIBOS,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.FINANCEIRO],
  searchPlaceholder: 'recibos.pesquisa',
  icon: 'fa-chevron-circle-down',
  metadata: {
    keyName: 'extPocCabID',
    fields: [
      {name: 'nDocAsStr', width: '180px', caption: 'recibos.fields.numero'},
      {name: 'numero', width: '180px', caption: 'recibos.fields.numero', visible: false},
      {name: 'nConta', width: '100px', caption: 'recibos.fields.nconta'},
      {name: 'nome', caption: 'recibos.fields.nome'},
      {name: 'data', width: '100px', type: 'date', caption: 'recibos.fields.data'},
      {name: 'valor', width: '100px', align: 'right', type: 'double', caption: 'recibos.fields.valor'},
      {name: 'abreviaturaMoeda', align: 'center', caption: 'recibos.fields.abreviaturaMoeda'},
      {name: 'stampUpdate', type: 'datetime', caption: 'recibos.fields.stampUpdate'},
      {name: 'data>', width: '100px', type: 'date', caption: 'recibos.fields.deData'}, // campo adicionado apenas com o propósito de filtrar a listagem
      {name: 'data<', width: '100px', type: 'date', caption: 'recibos.fields.ateData'} // campo adicionado apenas com o propósito de filtrar a listagem
    ],
    listFields: 'nDocAsStr,numero,nDocExterno,nConta,nome,data,valor,abreviaturaMoeda,stampUpdate',
    searchFields: 'numero,nConta,nome,data,valor,abreviaturaMoeda,stampUpdate,data>,data<',
    order: 'stampUpdate desc'
  },
  actions: {
    new: true,
    detail: false,
    edit: true,
    delete: false,
    search: true,
    filter: true,
    duplicate: false
  },
  list: {
    state: {
      component: RecibosListComponent
    }
  },
  detail: {
    state: {
      component: RecibosEditComponent,
      params: {
        nConta: {
          type: 'string',
          value: ''
        },
        nome: {
          type: 'string',
          value: ''
        }
      }
    }
  },
  service: function () {
    this.sendEmail = (extpoccabID, nconta, email, ncopias) => this.get({id: `${extpoccabID}/email`, params: {email: email, nconta: nconta, ncopias: ncopias}});
  }
};
