<div class="ativosdepreciacao entity-detail-form">
  <pl-group>
    <pl-group>
      <label [translate]="'ativosdepreciacao.header.anoDeCalculo'"></label>
      <edit>
        <pl-autocomplete [source]="metodoSource" [model]="header.anoDeCalculo" [rowTemplate]="anoDeCalculoTemplate" (evtSelected)="anoDeCalculoChanged($event)" [allowInvalid]="false" output="ano">
        </pl-autocomplete>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'ativosdepreciacao.header.pesquisaAnosAnteriores'"></label>
      <edit>
        <pl-edit type="boolean" attrName="pesquisaAnosAnteriores" [model]="header.pesquisaAnosAnteriores" (modelChange)="pesquisaAnosAnterioresChanged($event)"></pl-edit>
      </edit>
    </pl-group>
  </pl-group>

  <div class="card mt-2">
    <div class="card-header fw-bold pesquisa-avançada" (click)="collapsedPesqAvancada = !collapsedPesqAvancada">
      <button type="button" class="btn btn-link" [translate]="'global.text.advancedSearch'"></button>
      <i class="fa fa-caret-right" aria-hidden="true" [plAnimationRotate]="!collapsedPesqAvancada"></i>
    </div>
    <pl-animation-collapse [collapsed]="collapsedPesqAvancada">
      <div class="card-body">
        <pl-group>
          <pl-group>
            <label [translate]="'ativosdepreciacao.header.codAtivoDe'"></label>
            <edit>
              <pl-edit type="cginteger" [(model)]="header.codAtivoDe" plAutoFocus></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'ativosdepreciacao.header.codAtivoAte'"></label>
            <edit>
              <pl-edit type="cginteger" [(model)]="header.codAtivoAte"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'ativosdepreciacao.header.dataAquisicaoDe'"></label>
            <edit>
              <pl-edit type="date" [(model)]="header.dataAquisicaoDe" [properties]="{validators: {required: {value: true}}}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'ativosdepreciacao.header.dataAquisicaoAte'"></label>
            <edit>
              <pl-edit type="date" [(model)]="header.dataAquisicaoAte" [properties]="{validators: {required: {value: true}}}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>
      </div>
    </pl-animation-collapse>
  </div>

  <div class="mt-3">
    <pl-alert *ngIf="model.comDataUtilEmAnosPost.length > 0" type="warning" [closeable]="false">
      <div [innerHTML]="'ativosdepreciacao.header.alertativosdataposterior' | translate"></div>
      <div class="hyperlink" [translate]="'ativosdepreciacao.btn.consultar'" (click)="openAtivosDataPosterior()"></div>
    </pl-alert>

    <pl-tabs [activeId]="activeTab" (activeIdChange)="changedTab($event)">
      <pl-tab [id]="tabsIds.PorDepreciar">
        <div *plTabTitle><i class="fa fa-fw fa-list-alt" aria-hidden="true"></i>&nbsp;<span [translate]="'ativosdepreciacao.tab.ativosPorDepreciarNoAno'"></span></div>

        <div *plTabContent>
          <ng-container *ngTemplateOutlet="templateCGDxDataGridAtivosDep; context: {instanceName: 'ativosPorDepreciarNoAno'}"></ng-container>
        </div>
      </pl-tab>

      <pl-tab [id]="tabsIds.Depreciados">
        <div *plTabTitle><i class="fa fa-fw fa-check-circle" aria-hidden="true"></i>&nbsp;<span [translate]="'ativosdepreciacao.tab.ativosDepreciadosNoAno'"></span></div>

        <div *plTabContent>
          <ng-container *ngTemplateOutlet="templateCGDxDataGridAtivosDep; context: {instanceName: 'ativosDepreciadosNoAno'}"></ng-container>
        </div>
      </pl-tab>

      <pl-tab [id]="tabsIds.DepreciadosAnoAnterior" [disabled]="!header.pesquisaAnosAnteriores">
        <div *plTabTitle><i class="fa fa-fw fa-calendar-check-o" aria-hidden="true"></i>&nbsp;<span [translate]="'ativosdepreciacao.tab.ativosDepreciadosEmAnoAnterior'"></span></div>

        <div *plTabContent>
          <ng-container *ngTemplateOutlet="templateCGDxDataGridAtivosDep; context: {instanceName: 'ativosDepreciadosEmAnoAnterior'}"></ng-container>
        </div>
      </pl-tab>
    </pl-tabs>
  </div>
</div>

<ng-template #templateCGDxDataGridAtivosDep let-instanceName="instanceName">
  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    [cgDxDataGridInstanceName]="instanceName"
    [dataSource]="dataGridDefinition.dataSource"
    [(selectedRowKeys)]="selectedKeys"
    (onInitialized)="onInitialized($event)"
    (onContentReady)="onContentReady()"
    (onCellPrepared)="onCellPrepared($event)"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onSelectionChanged)="onSelectionChanged($event)">
    <div *dxTemplate="let cell of 'templateToolbarRegistoMes'">
      <div class="dx-toolbar-autocomplete-inline">
        <label [translate]="'ativosdepreciacao.tab.registoMes'"></label>
        <pl-edit type="meses" [model]="mesCalculo" (modelChange)="mesCalculohanged($event)"></pl-edit>
      </div>
    </div>

    <div *dxTemplate="let cell of 'templateToolbarRegistarDeps'">
      <pl-button
        type="button"
        klass="btn-primary btn-sm"
        [disabled]="!selectedKeys.length"
        [tooltip]="{text: 'ativosdepreciacao.tooltip.selecionarumadep', disabled: selectedKeys.length > 0}"
        [onClick]="fnPutAtivosDepreciacao">
        <i class="fa fa-fw fa-calculator"></i><span [translate]="'ativosdepreciacao.btn.calcular'"></span>
      </pl-button>
    </div>

    <div *dxTemplate="let cell of 'templateToolbarApagarDeps'">
      <pl-button
        type="button"
        klass="btn-primary btn-sm"
        [disabled]="!selectedKeys.length"
        [tooltip]="{text: 'ativosdepreciacao.tooltip.selecionarumadep', disabled: selectedKeys.length > 0}"
        [onClick]="fnDeleteAtivosDepreciacao">
        <i class="fa fa-fw fa-times"></i><span [translate]="'ativosdepreciacao.btn.anulaCalculo'"></span>
      </pl-button>
    </div>

    <div *dxTemplate="let cell of 'editCellTemplateContabPercReducao'">
      <pl-edit type="number" attrName="contabPercReducao" [model]="cell.data.contabPercReducao" (modelChange)="contabPercReducaoChanged(cell.data, $event)" [properties]="propertiesInput"></pl-edit>
    </div>
  </dx-data-grid>
</ng-template>
