import {Component, Injector, OnInit} from '@angular/core';
import {IPlNavPillEventSelected, IPlTableOptions, IPlToolbarItem, PlAlertService, TPlTableItem} from 'pl-comps-angular';
import {ContabilidadeToolsService} from '../../../../../services/contabilidade.tools.service';
import {IJsonClifoAnalyzer} from '../../../../../interfaces/jsonAcumulados.interface';
import {
  IVerificacaoAcumulados,
  IVerificacaoAcumuladosFieldsMaps,
  IVerificacaoAcumuladosForms,
  IVerificacaoAcumuladosPanel,
  IVerificacaoAcumuladosTableDefinitions
} from '../verificacaoAcumulados.module.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {IEntityAutocompleteOptions} from '../../../../../components/entity/entity.autocomplete.definition.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnSelectionChanged
} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';

@Component({
  selector: 'verificacao-acumulados',
  templateUrl: './verificacaoAcumulados.module.component.html'
})
export class VerificacaoAcumuladosComponent extends ModuloComponent implements OnInit {
  public readonly fieldsMaps: IVerificacaoAcumuladosFieldsMaps;
  public readonly propertiesPeriodo: Partial<IEntityAutocompleteOptions>;
  public tableDefinitions: IVerificacaoAcumuladosTableDefinitions;
  public tableOptions: IPlTableOptions;
  public forms: IVerificacaoAcumuladosForms;
  public panels: Array<IVerificacaoAcumuladosPanel>;
  public allSelected: boolean;
  public model: IVerificacaoAcumulados;
  public nomePeriodo: string;
  public source: Array<TPlTableItem<IJsonClifoAnalyzer>>;
  public selectedPanel: string;
  public promise: Promise<void>;

  private readonly _btnCorrigir: IPlToolbarItem;
  private readonly _btnVerificar: IPlToolbarItem;

  constructor(
    protected readonly _injector: Injector,
    private readonly _contabilidadeToolsService: ContabilidadeToolsService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.fieldsMaps = {
      deNconta: {nConta: 'deNconta'},
      ateNconta: {nConta: 'ateNconta'}
    };
    this.propertiesPeriodo = {validators: {required: {value: true}}};

    this._btnVerificar = {
      id: 'btnVerificar',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-play"></i>',
      class: 'btn-primary',
      caption: 'verificacaoacumulados.btn.verificar',
      click: () => this._execute()
    };

    this._btnCorrigir = {
      id: 'btnCorrigir',
      type: 'button',
      caption: 'verificacaoacumulados.btn.corrigir',
      iconLeft: '<i class="fa fa-fw fa-check"></i>',
      class: 'btn-success',
      visible: false,
      disabled: true,
      click: () => this._corrigirConsistencias(),
      tooltip: {
        text: 'verificacaoacumulados.tooltip.corrigir',
        placement: 'bottom'
      }
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.tableDefinitions = {
      acumuladosPorPeriodo: {
        columns: [
          {dataField: 'nConta', caption: 'verificacaoacumulados.tables.nConta', dataType: 'string'},
          {dataField: 'nome', caption: 'verificacaoacumulados.tables.nome', dataType: 'string'},
          {dataField: 'saldo1', caption: 'verificacaoacumulados.tables.saldoAcumulado', dataType: 'double'},
          {dataField: 'saldo2', caption: 'verificacaoacumulados.tables.saldoMovAberto', dataType: 'double'},
          {dataField: 'infoKinds', caption: 'verificacaoacumulados.tables.tipo', dataType: 'string'}
        ],
        masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplate'}
      },
      acumuladosComMovimentos: {
        columns: [
          {dataField: 'nConta', caption: 'verificacaoacumulados.tables.nConta', dataType: 'string'},
          {dataField: 'nome', caption: 'verificacaoacumulados.tables.nome', dataType: 'string'},
          {dataField: 'saldo1', caption: 'verificacaoacumulados.tables.saldoAcumuladoAte', dataType: 'double'},
          {dataField: 'saldo2', caption: 'verificacaoacumulados.tables.saldoExtratoAte', dataType: 'double'},
          {dataField: 'infoKinds', caption: 'verificacaoacumulados.tables.tipo', dataType: 'string'}
        ],
        masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplate'}
      },
      acumuladosComExtratos: {
        columns: [
          {dataField: 'nConta', caption: 'verificacaoacumulados.tables.nConta', dataType: 'string'},
          {dataField: 'nome', caption: 'verificacaoacumulados.tables.nome', dataType: 'string'},
          {dataField: 'saldo1', caption: 'verificacaoacumulados.tables.saldoAcumulado', dataType: 'double'},
          {dataField: 'saldo2', caption: 'verificacaoacumulados.tables.saldoExtrato', dataType: 'double'},
          {dataField: 'infoKinds', caption: 'verificacaoacumulados.tables.tipo', dataType: 'string'},
          {
            dataField: 'actions',
            caption: '',
            cellTemplate: 'actions',
            allowEditing: false,
            allowExporting: false,
            allowFiltering: false,
            allowFixing: false,
            allowGrouping: false,
            allowHeaderFiltering: false,
            allowHiding: false,
            allowReordering: false,
            allowResizing: false,
            allowSearch: false,
            allowSorting: false,
            showInColumnChooser: false,
            width: 100
          }
        ],
        masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplate'}
      },
      inconsistencias: {
        columns: [
          {dataField: 'tipo', caption: 'verificacaoacumulados.tables.tipo', dataType: 'string'},
          {dataField: 'faseVerificacao', caption: 'verificacaoacumulados.tables.fase', dataType: 'string'},
          {dataField: 'nConta', caption: 'verificacaoacumulados.tables.conta', dataType: 'string'},
          {dataField: 'periodo', caption: 'verificacaoacumulados.tables.periodo', dataType: 'string'},
          {dataField: 'diario', caption: 'verificacaoacumulados.tables.diario', dataType: 'string'},
          {dataField: 'ndocInterno', caption: 'verificacaoacumulados.tables.docInterno', dataType: 'string'}
        ],
        masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplate'}
      },
      consistencias: {
        columns: [
          {dataField: 'nConta', caption: 'verificacaoacumulados.tables.nConta', dataType: 'string'},
          {dataField: 'nome', caption: 'verificacaoacumulados.tables.nome', dataType: 'string'},
          {dataField: 'tipoVerificacaoStr', caption: 'verificacaoacumulados.tables.origem', dataType: 'string'},
          {dataField: 'informacao', caption: 'verificacaoacumulados.tables.informacao', dataType: 'string'}
        ],
        selection: {allowSelectAll: true, mode: 'multiple'}
      }
    };
    this.tableOptions = {
      hideDetail: true,
      hideActions: true
    };
    this.forms = {};
    this.panels = [
      {
        name: 'acumuladosPorPeriodo',
        caption: 'verificacaoacumulados.periodo',
        tableDefinition: this.tableDefinitions.acumuladosPorPeriodo,
        tableInstance: undefined
      },
      {
        name: 'acumuladosComMovimentos',
        caption: 'verificacaoacumulados.movimentos',
        tableDefinition: this.tableDefinitions.acumuladosComMovimentos,
        tableInstance: undefined
      },
      {
        name: 'acumuladosComExtratos',
        caption: 'verificacaoacumulados.extratos',
        tableDefinition: this.tableDefinitions.acumuladosComExtratos,
        tableInstance: undefined
      },
      {
        name: 'inconsistencias',
        caption: 'verificacaoacumulados.inconsistencias',
        tableDefinition: this.tableDefinitions.inconsistencias,
        tableInstance: undefined
      },
      {
        name: 'consistencias',
        caption: 'verificacaoacumulados.consistencias',
        tableDefinition: this.tableDefinitions.consistencias,
        tableInstance: undefined
      }
    ];
    this.model = {
      deNconta: '0',
      ateNconta: '9999999999',
      periodo: ''
    };
    this.nomePeriodo = '';
    this.source = [];
    this.selectedPanel = this.panels[0].name;

    this.toolbar.addButton(this._btnVerificar);
    this.toolbar.addButton(this._btnCorrigir);
  }

  public changedPanel(event: IPlNavPillEventSelected): void {
    this.selectedPanel = event.nextId;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const value = this.selectedPanel !== this.panels[4].name;
    this.tableOptions = {
      hideDetail: !value,
      hideActions: value
    };
    this._btnCorrigir.visible = !value;
    this.source = [];
  }

  public fnReconstruirAcumulados(nConta: string): () => Promise<void> {
    return () => this._reconstruirAcumulados(nConta);
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized, panel: IVerificacaoAcumuladosPanel): void {
    panel.tableInstance = component;
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick): void {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (this.selectedPanel !== this.panels[4].name) {
      devExpressDataGridExpandDetailHandler(event);
    }
  }

  public onSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged): void {
    if (this.selectedPanel !== 'consistencias') {
      return;
    }
    const hasItems: boolean = event.selectedRowsData.length > 0;
    this._btnCorrigir.disabled = !hasItems;
    this._btnCorrigir.tooltip.disabled = hasItems;
  }

  private _execute(): Promise<void> {
    const panel: IVerificacaoAcumuladosPanel = this._getSelectedPanel();

    this.allSelected = false;
    this._btnCorrigir.disabled = true;
    this._btnCorrigir.tooltip.disabled = false;

    let fn;
    switch (this.selectedPanel) {
      case this.panels[0].name:
        fn = this._contabilidadeToolsService.verificarAcumuladosMovAberto(this.model.deNconta, this.model.ateNconta);
        break;
      case this.panels[1].name:
        if (!this.model.periodo.length) {
          this.forms[panel.name].onSubmit(undefined);
          return Promise.resolve();
        }
        fn = this._contabilidadeToolsService.verificarAcumuladosMovAbertoPeriodo(this.model.deNconta, this.model.ateNconta, this.model.periodo);
        break;

      case this.panels[2].name:
        fn = this._contabilidadeToolsService.verificarAcumuladosComExtratos(this.model.deNconta, this.model.ateNconta);
        break;
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      case this.panels[3].name:
        fn = this._contabilidadeToolsService.inconsistenciasContabilidade();
        break;
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      case this.panels[4].name:
        fn = this._contabilidadeToolsService.consistenciasContaCorrente(this.model.deNconta, this.model.ateNconta);
        break;
    }

    return Promise.resolve(fn).then((response) => {
      this.source = response.body.list;
    });
  }

  private _reconstruirAcumulados(nConta: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._contabilidadeToolsService
        .reconstruirAcumulados({nConta: nConta})
        .then(() => {
          this._plAlertService.success('verificacaoacumulados.alerts.reconstrucaoSucesso');
          this._execute().finally(resolve);
        })
        .catch((error: unknown) => {
          this._plAlertService.success('verificacaoacumulados.alerts.reconstrucaoErro');
          reject(error);
        });
    });
  }

  private _corrigirConsistencias(): Promise<void> {
    let consistencias: Array<TPlTableItem<IJsonClifoAnalyzer>> = [];

    const index = this.panels.findIndex((item: IVerificacaoAcumuladosPanel) => item.name === 'consistencias');
    if (index !== -1) {
      consistencias = this.panels[index].tableInstance.getSelectedRowsData();
      for (const consistencia of consistencias) {
        consistencia._selected = true;
        consistencia.selected = true;
      }
    }

    return new Promise<void>((resolve, reject) => {
      this._contabilidadeToolsService.corrigirConsistContaCorrente(consistencias).then(
        () => {
          this._plAlertService.success('verificacaoacumulados.alerts.correcaoContasCorrentesSucesso');
          this._btnCorrigir.disabled = true;
          this._execute().finally(resolve);
        },
        (error) => {
          this._plAlertService.error('verificacaoacumulados.alerts.correcaoContasCorrentesErro');
          reject(error);
        }
      );
    });
  }

  private _getSelectedPanel(): IVerificacaoAcumuladosPanel {
    return this.panels.find((value) => value.name === this.selectedPanel);
  }
}
