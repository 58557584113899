import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {IPlLifeCycleEvent, IPlNavPillCallback, IPlNavPillEventSelected, IPlToolbarInstance, IPlToolbarItem, IPlTooltipConfig, isEmpty, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {DocContabilidadeService} from '../../portalcontabilidade/docscontabilidade/components/doccontabilidade/docContabilidade.service';
import {EFACCBStatus} from '../../../../common/enums/erp.enums';
import {EGrupoDoc} from '../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService} from '../../portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IDocumFac, IIntFac, IListInteg} from '../intFac.module.interface';
import {EDocContabilidadeOrigem, IJsonDocContabilidade} from '../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {IntegracaoDocumentosService} from '../intFac.module.sevice';
import {ModuloComponent} from '../../../components/module/module.component';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import {
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnContentReady,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowExpanding
} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonIntFac} from '../jsonIntFac.module.interface';
import {EMPTY_GUID} from '../../../../config/constants';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {IntegracaoDocumentosModalComponent} from '../modal/intFac.modal.componen';
import {CGExceptionService} from '../../../components/exceptions/exceptions.service';

const SAVE_PROMPT_IDENTIFIER = 'intfac-module';
const TOOLBAR_ITEM_ID_DOC_TITLE = 'saveDoc';
const TOOLBAR_ITEM_ID_DOC_SAVE = 'saveDoc';

@Component({
  selector: 'module-integracao-documentos',
  templateUrl: './intFac.module.component.html'
})
export class IntegracaoDocumentosModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public header: Array<IDocumFac>;

  public readonly dataGridDefinition: IDevExpressDataGrid<IIntFac, string>;
  public readonly dataGridDefinitionListaInteg: IDevExpressDataGrid<IListInteg, number>;
  public readonly rowTemplate: string;
  public readonly navPillCallback: IPlNavPillCallback;
  public readonly tooltipError: IPlTooltipConfig;
  public readonly faccbStatuses: typeof EFACCBStatus;

  public readonly: boolean;
  public grupoVenda: Array<IDocumFac>;
  public grupoCompra: Array<IDocumFac>;
  public grupoOutros: Array<IDocumFac>;
  public grupoSelected: Array<number>;
  public intFac: Array<IIntFac>;
  public selectedKeys: Array<string>;
  public selectedAllVendas: boolean;
  public selectedAllCompras: boolean;
  public selectedAllOutros: boolean;

  private readonly _btnCanEncerrar: IPlToolbarItem;
  private readonly _btnCanPesquisar: IPlToolbarItem;
  private readonly _btnSimularEncerrar: IPlToolbarItem;
  private readonly _docsContabilidadeEntityService: IDocsContabilidadeEntityService;
  private readonly _toolbarInstances: Array<IPlToolbarInstance>;

  private _dataGridInstance: dxDataGrid<IIntFac, string>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _integracaoDocumentosService: IntegracaoDocumentosService,
    private readonly _docContabilidadeService: DocContabilidadeService,
    private readonly _cgModalService: CGModalService,
    private readonly _cgExceptionService: CGExceptionService
  ) {
    super(_injector);
    this.rowTemplate = '{{_label}} - {{_selected}}';
    this.navPillCallback = {};
    this.selectedAllVendas = false;
    this.selectedAllCompras = false;
    this.selectedAllOutros = false;
    this.selectedKeys = [];
    this.grupoVenda = [];
    this.grupoCompra = [];
    this.grupoOutros = [];
    this.grupoSelected = [];
    this.intFac = [];
    this.tooltipError = {text: 'intfac.tooltip.doccomerro', placement: 'bottom', tooltipClass: 'tooltip-danger'};
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nDoc', dataType: 'string', caption: 'intfac.table.nDoc'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'intfac.table.dataDoc'},
        {dataField: 'dataVenci', dataType: 'date', caption: 'intfac.table.dataVenci'},
        {dataField: 'estado', dataType: 'string', caption: 'intfac.table.estado'},
        {dataField: 'nConta', dataType: 'string', caption: 'intfac.table.nConta'},
        {dataField: 'nomeConta', dataType: 'string', caption: 'intfac.table.nomeConta'},
        {dataField: 'nDocExt', dataType: 'string', caption: 'intfac.table.nDocExt'},
        {dataField: 'erro', dataType: 'string', caption: 'intfac.table.erro', cssClass: 'text-danger'}
      ],
      dataSource: new CustomStore({
        key: 'nDoc',
        load: () => this._source()
      }),
      masterDetail: {enabled: true, template: 'templateMasterDetail'},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplate',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.dataGridDefinitionListaInteg = {
      columns: [
        {dataField: 'nDocumentoFacturacao', dataType: 'string', caption: 'intfac.table.nDocumentoFacturacao'},
        {dataField: 'periodo', dataType: 'string', caption: 'intfac.table.periodo', visible: false},
        {dataField: 'nDiario', dataType: 'number', caption: 'intfac.table.nDiario', visible: false},
        {dataField: 'nDocInterno', dataType: 'string', caption: 'intfac.table.nDocInterno', visible: false},
        {dataField: 'dataDoc', dataType: 'date', caption: 'intfac.table.dataDoc'},
        {dataField: 'nContaDebito', dataType: 'string', caption: 'intfac.table.nContaDebito'},
        {dataField: 'nContaCredito', dataType: 'string', caption: 'intfac.table.nContaCredito'},
        {dataField: 'valor', dataType: 'double', caption: 'intfac.table.valor', visible: false},
        {dataField: 'nomeConta', dataType: 'string', caption: 'intfac.table.nomeConta'},
        {dataField: 'codMoeda', dataType: 'number', caption: 'intfac.table.codMoeda', visible: false},
        {dataField: 'valorME', dataType: 'double', caption: 'intfac.table.valorME', visible: false},
        {dataField: 'valorTaxa', dataType: 'double', caption: 'intfac.table.valorTaxa'},
        {dataField: 'valorTaxaME', dataType: 'double', caption: 'intfac.table.valorTaxaME', visible: false},
        {dataField: 'codIva', dataType: 'number', caption: 'intfac.table.codIva'},
        {dataField: 'valorRetencao', dataType: 'double', caption: 'intfac.table.valorRetencao', visible: false},
        {dataField: 'nContrib', dataType: 'string', caption: 'intfac.table.nContrib', visible: false},
        {dataField: 'dataVencimento', dataType: 'date', caption: 'intfac.table.dataVenci', visible: false},
        {dataField: 'dataDocExterno', dataType: 'date', caption: 'intfac.table.dataDocExterno', visible: false},
        {dataField: 'codIntegra', dataType: 'string', caption: 'intfac.table.codIntegra', visible: false},
        {dataField: 'descricao', dataType: 'string', caption: 'intfac.table.descricao', visible: false}
      ],
      keyExpr: '_index',
      masterDetail: {enabled: true, template: 'templateMasterDetailListInteg'},
      remoteOperations: false,
      toolbar: {visible: false}
    };
    this.faccbStatuses = EFACCBStatus;
    this._btnCanPesquisar = {
      id: 'pesquisar',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-search fa-fw"></i>',
      class: 'btn-success',
      caption: 'intfac.btn.pesquisar',
      disabled: true,
      tooltip: {text: 'intfac.tooltips.cantBtnPesquisar', placement: 'bottom'},
      click: () => this._pesquisar()
    };
    this._btnCanEncerrar = {
      id: 'encerrar',
      order: 3,
      type: 'button',
      iconLeft: '<i class="fa fa-lock fa-fw"></i>',
      class: 'btn-primary',
      caption: 'intfac.btn.encerrar',
      disabled: true,
      tooltip: {text: 'intfac.tooltips.cantBtnEncerrar', placement: 'bottom'},
      click: () => this._encerrar()
    };
    this._btnSimularEncerrar = {
      id: 'simula',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-lock fa-fw"></i>',
      class: 'btn-warning',
      disabled: true,
      caption: 'intfac.btn.simular',
      click: () => this._simularEncerrar()
    };
    this._docsContabilidadeEntityService = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._toolbarInstances = [];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnCanPesquisar);
    this.toolbar.addButton(this._btnCanEncerrar);
    this.toolbar.addButton(this._btnSimularEncerrar);
    if (this.header.length) {
      this._generateGrupos();
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    for (const toolbarInstance of this._toolbarInstances) {
      this._plToolbarService.unRegisterInstance(toolbarInstance);
    }
  }

  public changedNavPill(event: IPlNavPillEventSelected): void {
    if (event.activeId === event.nextId) {
      return;
    }
    let items: Array<IDocumFac>;
    switch (event.activeId) {
      case 'vendas':
        items = this.grupoVenda;
        this.selectedAllVendas = false;
        break;
      case 'compras':
        items = this.grupoCompra;
        this.selectedAllCompras = false;
        break;
      case 'outros':
        items = this.grupoOutros;
        this.selectedAllOutros = false;
        break;
    }
    for (const item of items) {
      item._selected = false;
    }
    this.grupoSelected = [];
    this._refreshBtnPesquisar();
  }

  public changedGrupoSelected(value: boolean, grupo: IDocumFac): void {
    grupo._selected = value;
    if (grupo._selected === true) {
      this.grupoSelected.push(grupo.nDocFa);
    } else {
      const index = this.grupoSelected.findIndex((nDocFa: number) => nDocFa === grupo.nDocFa);
      if (index !== -1) {
        this.grupoSelected.splice(index, 1);
      }
    }
    this._evaluateSelectedAllHeader();
    this._refreshBtnPesquisar();
  }

  public selectedAllGoupSelectChange(value: boolean, source: Array<IDocumFac>): void {
    switch (value) {
      case true:
        this.grupoSelected = [];
        for (const item of source) {
          item._selected = true;
          this.grupoSelected.push(item.nDocFa);
        }
        this._refreshBtnPesquisar();
        break;
      case false:
        this.grupoSelected = [];
        for (const item of source) {
          item._selected = false;
        }
        this._refreshBtnPesquisar();
        break;
      default:
        break;
    }
    this._evaluateSelectedAllHeader();
  }

  public onDocContabilidadeInit(item: IIntFac, {element}: IPlLifeCycleEvent): void {
    this._docContabilidadeService.listenForSaveEvent({
      identifier: `${SAVE_PROMPT_IDENTIFIER}-${item.extPocCabID}`,
      callbackGetDoc: () => item._docContab,
      callbackOnSave: () => this._saveItemDoc(item),
      simulation: false,
      contabilidadeDigital: false,
      whenNode: element
    });
  }

  public onDocContabilidadeDestroy(item: IIntFac): void {
    this._docContabilidadeService.clearForSaveEventListener(`${SAVE_PROMPT_IDENTIFIER}-${item.extPocCabID}`);
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IIntFac, string>): void {
    this._dataGridInstance = component;
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IIntFac, string>): void {
    if (event.rowType !== 'data') {
      return;
    }
    if (!event.data._hasDetail) {
      if (event.column.command === 'expand') {
        (<HTMLElement>event.cellElement.childNodes[0]).classList.remove('dx-datagrid-group-closed');
        event.cellElement.classList.remove('dx-datagrid-expand');
      }
      if (event.cellElement.classList.contains('text-error')) {
        event.cellElement.classList.remove('text-error');
      }
    } else if (event.data._errorList && event.data._errorList.length > 0) {
      event.cellElement.classList.add('text-error');
    }
  }

  public onRowExpanding(event: IDevExpressDataGridEventOnRowExpanding<IIntFac, string>): Promise<void> {
    return event.component
      .getDataSource()
      .store()
      .load()
      .then((result: Array<IIntFac>) => {
        const item: IIntFac = result.find((intfac: IIntFac) => intfac.nDoc === event.key);
        if (!isEmpty(item?.extPocCabID) && !item?._docContab) {
          return this._getItemDoc(item).then((response: HttpResponse<IJsonDocContabilidade>) => {
            item._docContab = response.body;
            // event.component.getDataSource().store().update(item.nDoc, item);
            this._generateItemDocToolbarItem(item);
          });
        }
        return Promise.resolve();
      });
  }

  public onContentReadyListaInteg(event: IDevExpressDataGridEventOnContentReady<IListInteg, number>): void {
    event.component
      .getDataSource()
      .store()
      .load()
      .then((result: Array<IListInteg>) => {
        for (const item of result) {
          if (item._hasDetail) {
            event.component.expandRow(item._index);
          }
        }
      });
  }

  public onSelectionChanged(): void {
    this._refreshBtnEncerrar();
  }

  private _generateGrupos(): void {
    for (const grupo of this.header) {
      grupo._selected = false;
      grupo._label = `${grupo.nDocFa} - ${grupo.nome}`;
      switch (grupo.grupoDocFa) {
        case EGrupoDoc.ComprasEfectivas:
          this.grupoCompra.push(grupo);
          break;
        case EGrupoDoc.VendasEfectivas:
          this.grupoVenda.push(grupo);
          break;
        default:
          this.grupoOutros.push(grupo);
          break;
      }
    }
  }

  private _pesquisar(): Promise<void> {
    if (this._dataGridInstance) {
      for (const item of this.intFac) {
        if (this._dataGridInstance.isRowExpanded(item.nDoc)) {
          this._dataGridInstance.collapseRow(item.nDoc);
        }
      }
    }
    this.intFac = [];
    this.selectedKeys = [];
    this._clearErrorLists();
    const tiposDocumentosList = this.grupoSelected.join(',');
    return this._integracaoDocumentosService
      .getDocumentos(tiposDocumentosList)
      .then((response: HttpResponse<Array<IIntFac>>) => {
        if (response.body.length === 0) {
          this._plAlertService.info('intfac.messages.dataNotFound');
        }
        return this._applyIntFac(response.body).then(undefined);
      })
      .finally(() => {
        this._refreshBtnEncerrar();
      });
  }

  private _simularEncerrar(): Promise<void> {
    const intFacSelected: Array<IJsonIntFac> = this._dataGridInstance.getSelectedRowsData();
    return this._integracaoDocumentosService
      .integraDocumentos(intFacSelected, true)
      .then((response: HttpResponse<Array<IListInteg>>) => {
        if (response.body) {
          const resultados = response.body;
          let index = 0;
          for (const linha of resultados) {
            linha._index = index;
            index++;
          }
          const modalInstance = this._cgModalService.showVanilla(IntegracaoDocumentosModalComponent, {size: 'xxl'});
          const componentInstance: IntegracaoDocumentosModalComponent = modalInstance.componentInstance;
          componentInstance.resultados = resultados;
        }
      })
      .catch((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          const exception = this._cgExceptionService.get(error);
          if (exception?.message) {
            this._plAlertService.error(exception.message);
          }
        } else {
          this._plAlertService.error('intfac.messages.simErro');
        }
      });
  }

  private _encerrar(): Promise<void> {
    this._clearErrorLists();
    const intFacSelected: Array<IJsonIntFac> = this._dataGridInstance.getSelectedRowsData();
    return this._integracaoDocumentosService.integraDocumentos(intFacSelected, false).then((response: HttpResponse<Array<IListInteg>>) => {
      const listaInteg: Array<IListInteg> = response.body;
      this.intFac = this.intFac.filter((item: IIntFac) => {
        // If intFac item is selected and isn't in errors list
        const selected: boolean = intFacSelected.findIndex((selectedItem: IIntFac) => item.nDoc === selectedItem.nDoc) !== -1;
        return !selected || (selected && listaInteg.findIndex((itemInteg: IListInteg) => item.nDoc === itemInteg.nDocumentoFacturacao) !== -1);
      });
      if (!listaInteg.length) {
        this._plAlertService.success('intfac.messages.successfullySaved');
        this.selectedKeys = [];
      } else {
        this._plAlertService.error('intfac.messages.docscomerro');
        // Remove selected items that don't have errors
        this.selectedKeys = this.selectedKeys.filter((nDoc: string) => {
          return listaInteg.findIndex((itemInteg: IListInteg) => nDoc === itemInteg.nDocumentoFacturacao) !== -1;
        });
        // For every item that has error, fill respective error list
        let index = 0;
        for (const item of listaInteg) {
          item._hasDetail = !isEmpty(item.error);
          item._index = index;
          index++;
          const intFac: IIntFac = this.intFac.find((itemIntFac: IIntFac) => item.nDocumentoFacturacao === itemIntFac.nDoc);
          if (intFac) {
            intFac._errorList.push(item);
            intFac._hasDetail = true;
          }
        }
      }
      this._refreshBtnEncerrar();
      return this._applyIntFac(this.intFac).then(undefined);
    });
  }

  private _applyIntFac(value: Array<IIntFac>): Promise<void> {
    this.intFac = value;
    for (const item of this.intFac) {
      this._generateItemDocToolbar(item);
      if (item.faccbStatus === EFACCBStatus.TerminadoComErro) {
        item._hasDetail = true;
        if (!item.erro) {
          item.erro = this._plTranslateService.translate('intfac.errors.integradoMasComErro');
        }
      }
    }
    return this._dataGridInstance.refresh();
  }

  private _source(): Array<IIntFac> {
    for (const iIntFac of this.intFac) {
      if (iIntFac.extPocCabID === EMPTY_GUID) {
        iIntFac.extPocCabID = '';
      }
    }
    return this.intFac.slice();
  }

  private _clearErrorLists(): void {
    for (const intFac of this.intFac) {
      intFac._errorList = [];
    }
  }

  private _refreshBtnPesquisar(): void {
    const disabled: boolean = this.grupoSelected.length === 0;
    this._btnCanPesquisar.disabled = disabled;
    this._btnCanPesquisar.tooltip.disabled = !disabled;
  }

  private _refreshBtnEncerrar(): void {
    const disabled: boolean = this.selectedKeys.length === 0;
    this._btnCanEncerrar.disabled = disabled;
    this._btnCanEncerrar.tooltip.disabled = !disabled;
    this._btnSimularEncerrar.disabled = disabled;
  }

  private _generateItemDocToolbar(item: IIntFac): void {
    if (item.extPocCabID && !item._docToolbarId) {
      item._docToolbarId = `${SAVE_PROMPT_IDENTIFIER}-${this.instanceName}-toolbar-${item.nDoc}`;
      if (!this._plToolbarService.isRegistered(item._docToolbarId)) {
        const toolbarInstance: IPlToolbarInstance = this._plToolbarService.registerInstance(item._docToolbarId, {
          items: [
            {
              id: TOOLBAR_ITEM_ID_DOC_TITLE,
              order: 1,
              type: 'title',
              caption: ''
            },
            {
              id: TOOLBAR_ITEM_ID_DOC_SAVE,
              order: 2,
              type: 'button',
              class: 'btn-success',
              iconLeft: '<i class="fa fa-fw fa-floppy-o"></i>&nbsp;',
              caption: 'global.btn.save',
              click: () => this._saveItemDoc(item)
            }
          ]
        });
        if (!this._toolbarInstances.includes(toolbarInstance)) {
          this._toolbarInstances.push(toolbarInstance);
        }
      }
    }
  }

  private _generateItemDocToolbarItem(item: IIntFac): void {
    if (item._docContab) {
      const toolbarInstance: IPlToolbarInstance = this._plToolbarService.getInstance(item._docToolbarId);
      const toolbarTitle: IPlToolbarItem = toolbarInstance.find(TOOLBAR_ITEM_ID_DOC_TITLE);
      toolbarTitle.caption = this._plTranslateService.translate('docscontabilidade.title_edit', {id: `${item._docContab.periodo}.${item._docContab.nDiario}.${item._docContab.nDocInterno.trim()}`});
    }
  }

  private _getItemDoc(item: IIntFac): TServiceResponse<IJsonDocContabilidade> {
    return this._docsContabilidadeEntityService.get({id: item.extPocCabID});
  }

  private _saveItemDoc(item: IIntFac): TServiceResponse<IJsonDocContabilidade> {
    if (!item._docContab) {
      return Promise.reject(new Error(`\`DocContabilidade\` with \`extPocCabID\` "${item.extPocCabID}" not found.`));
    }
    return this._docsContabilidadeEntityService.put({id: item._docContab.extPocCabID, body: item._docContab}).then((response: HttpResponse<IJsonDocContabilidade>) => {
      item._docContab = response.body;
      if (item._docContab.origem !== EDocContabilidadeOrigem.IntegracaoComErro) {
        const itemIndex = this.intFac.findIndex((intFacItem: IIntFac) => intFacItem.nDoc === item.nDoc);
        if (itemIndex > -1) {
          this.intFac.splice(itemIndex, 1);
          return this._dataGridInstance.refresh().then(() => response);
        }
      }
      return response;
    });
  }

  private _evaluateSelectedAllHeader(): void {
    this.selectedAllVendas = this.grupoVenda.findIndex((item: IDocumFac) => !item._selected) === -1;
    this.selectedAllCompras = this.grupoCompra.findIndex((item: IDocumFac) => !item._selected) === -1;
    this.selectedAllOutros = this.grupoOutros.findIndex((item: IDocumFac) => !item._selected) === -1;
  }
}
