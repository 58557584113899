import {AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, Directive, DoCheck, ElementRef, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import type {IPlLifeCycleEvent} from './lifecycle.interface';

@Directive({
  selector: '[plLifeCycle]'
})
export class PlLifecycleDirective implements OnInit, DoCheck, OnDestroy, AfterContentInit, AfterContentChecked, AfterViewInit, AfterViewChecked {
  @Output() public readonly evtCreated: EventEmitter<IPlLifeCycleEvent>;
  @Output() public readonly evtInit: EventEmitter<IPlLifeCycleEvent>;
  @Output() public readonly evtDoCheck: EventEmitter<IPlLifeCycleEvent>;
  @Output() public readonly evtDestroy: EventEmitter<IPlLifeCycleEvent>;
  @Output() public readonly evtAfterContentInit: EventEmitter<IPlLifeCycleEvent>;
  @Output() public readonly evtAfterContentChecked: EventEmitter<IPlLifeCycleEvent>;
  @Output() public readonly evtAfterViewInit: EventEmitter<IPlLifeCycleEvent>;
  @Output() public readonly evtAfterViewChecked: EventEmitter<IPlLifeCycleEvent>;

  constructor(private readonly _elementRef: ElementRef) {
    this.evtCreated = new EventEmitter<IPlLifeCycleEvent>();
    this.evtInit = new EventEmitter<IPlLifeCycleEvent>();
    this.evtDoCheck = new EventEmitter<IPlLifeCycleEvent>();
    this.evtDestroy = new EventEmitter<IPlLifeCycleEvent>();
    this.evtAfterContentInit = new EventEmitter<IPlLifeCycleEvent>();
    this.evtAfterContentChecked = new EventEmitter<IPlLifeCycleEvent>();
    this.evtAfterViewInit = new EventEmitter<IPlLifeCycleEvent>();
    this.evtAfterViewChecked = new EventEmitter<IPlLifeCycleEvent>();
    this.evtCreated.emit(this._event());
  }

  public ngOnInit(): void {
    this.evtInit.emit(this._event());
  }

  public ngDoCheck(): void {
    this.evtDoCheck.emit(this._event());
  }

  public ngOnDestroy(): void {
    this.evtDestroy.emit(this._event());
  }

  public ngAfterContentInit(): void {
    this.evtAfterContentInit.emit(this._event());
  }

  public ngAfterContentChecked(): void {
    this.evtAfterContentChecked.emit(this._event());
  }

  public ngAfterViewInit(): void {
    this.evtAfterViewInit.emit(this._event());
  }

  public ngAfterViewChecked(): void {
    this.evtAfterViewChecked.emit(this._event());
  }

  private _event(): IPlLifeCycleEvent {
    return {element: this._elementRef.nativeElement};
  }
}
