<div class="utilizadores-edit entity-detail-form">
  <pl-form>
    <pl-nav-wizard [definition]="navWizardDefinition" [beforeStepChange]="fnBeforeChangedStep" [onFinalize]="fnFinalize">
      <!--UTILIZADOR-->
      <pl-nav-wizard-step caption="assistenteconfigportais.steps.stepUtilizador" icon="fa-user">
        <pl-form *plNavWizardStepContent formInstanceName="utilizador" [properties]="{validators: {required: {value: true}}}">
          <pl-group>
            <pl-group *ngIf="erps.length > 1">
              <label for="centralGestId" [translate]="'erputilizadores.fields.erp'"></label>
              <edit>
                <select id="centralGestId" class="form-select" [(ngModel)]="centralGestId">
                  <option [ngValue]="erp.centralGestId" *ngFor="let erp of erps">{{ erp.name }}</option>
                </select>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'assistenteconfigportais.outros.utilizador'"></label>
              <edit>
                <entity-autocomplete entity="users" [model]="utilizadorSel" (modelChange)="onUtilizadorChanged($event)" [getDataConfig]="{params: {centralGestId: centralGestId}}">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
        </pl-form>
      </pl-nav-wizard-step>

      <!--EMPRESA-->
      <pl-nav-wizard-step caption="assistenteconfigportais.steps.stepEmpresa" icon="fa-building-o">
        <pl-form *plNavWizardStepContent formInstanceName="empresa" [properties]="{validators: {required: {value: true}}}">
          <pl-group>
            <label for="nEmpresa" [translate]="'assistenteconfigportais.outros.empresa'"></label>
            <edit>
              <select id="nEmpresa" class="form-select" [ngModel]="nEmpresa" (ngModelChange)="onEmpresaChange($event)">
                <option [ngValue]="empresa.nEmpresa" *ngFor="let empresa of empresas">{{ empresa.nomeEmpresa }} ({{ empresa.nEmpresa }})</option>
              </select>
            </edit>
          </pl-group>
        </pl-form>
      </pl-nav-wizard-step>

      <!--EMPREGADO-->
      <pl-nav-wizard-step caption="assistenteconfigportais.steps.stepEmpregado" icon="fa-male">
        <pl-form *plNavWizardStepContent formInstanceName="empregado" [properties]="{validators: {required: {value: true}}}">
          <pl-group>
            <label [translate]="'assistenteconfigportais.fields.codempregado'"></label>
            <edit>
              <entity-autocomplete entity="dgemps" [model]="empregadoSel" (modelChange)="onEmpregadoChanged($event)" [filter]="nEmpresaEmpregadoFilter"> </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-form>
      </pl-nav-wizard-step>
    </pl-nav-wizard>
  </pl-form>
</div>
