import {MomentInput} from 'moment';
import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {CGCDatePipe} from './date.pipe';
import {ECGCDateType} from '../common/locale/locale.interface';
import {PlI18nService} from '../i18n/i18n.service';

@Pipe({
  name: 'cgcTime'
})
export class CGCTimePipe extends CGCDatePipe implements PipeTransform {
  constructor(
    protected readonly _plI18nService: PlI18nService,
    @Inject(LOCALE_ID) locale: string
  ) {
    super(_plI18nService, locale);
  }

  /* eslint-disable @typescript-eslint/no-restricted-types */
  public transform(value: MomentInput | Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  public transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  public transform(value: MomentInput | Date | string | number | null | undefined, format = ECGCDateType.TIME, timezone?: string, locale?: string): string | null {
    /* eslint-enable @typescript-eslint/no-restricted-types */
    return super.transform(value, format, timezone, locale);
  }
}
