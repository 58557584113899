<div class="gestao-documentos-comerciais-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'gestaoDocsComerciais.title'"></h4>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <div class="entity-detail-form">
        <pl-form>
          <pl-group class="docfa-edit">
            <label [translate]="'gestaoDocsComerciais.filters.nDocFa'"></label>
            <edit>
              <entity-autocomplete
                attrName="docfa"
                entity="docfas"
                [model]="docFa"
                (modelChange)="onDocFaChanged($event)"
                [filter]="docFilter"
                [output]="docfasOutput"
                [outputKey]="'nDocFa'"
                outputDescription="nome"
                plAutoFocus>
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'gestaoDocsComerciais.filters.deDataDoc'"></label>
              <edit>
                <pl-edit-datepicker [model]="deDataDoc" (modelChange)="onDataDocDeChange($event)"></pl-edit-datepicker>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'gestaoDocsComerciais.filters.ateDataDoc'"></label>
              <edit>
                <pl-edit-datepicker [(model)]="ateDataDoc"></pl-edit-datepicker>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <edit>
              <pl-button klass="btn-primary btn-sm" [onClick]="fnPesquisar" data-focus><i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span></pl-button>
            </edit>
          </pl-group>
        </pl-form>
      </div>

      <br />

      <div class="card-footer">
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinition"
          cgDxDataGridInstanceName="docsComerciais"
          [dataSource]="docsComerciais"
          (onInitialized)="onInitialized($event)"
          (onContentReady)="onContentReady()"
          (onRowPrepared)="onDataGridRowPrepared($event)"
          [selectedRowKeys]="selectedRowKeys"
          (onCellClick)="onCellClick($event)">
          <div *dxTemplate="let item of 'detailTemplateDocFaturacao'">
            <documento-faturacao [doc]="item.data._thedoc" [actionPdf]="fnGetPdf"></documento-faturacao>
          </div>
        </dx-data-grid>
        <br />
        <div class="bancos-extrato-module-legend">
          <cg-table-legend [legend]="tableLegend"></cg-table-legend>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <ng-container *ngIf="docsComerciais?.length > 0">
      <pl-button klass="btn-success" [disabled]="onClickAction" (evtClicked)="encerrarDocsSelecionados()">
        <i class="fa fa-fw fa-file-archive-o"></i>&nbsp;<span [translate]="'docscomerciais.btn.finalizar'"></span>
      </pl-button>

      <pl-button klass="btn-danger" [disabled]="onClickAction" (evtClicked)="anularDocsSelecionados()">
        <i class="fa fa-fw fa-scissors"></i>&nbsp;<span [translate]="'docscomerciais.btn.anular'"></span>
      </pl-button>
    </ng-container>

    <pl-button klass="btn-light action-close" [disabled]="onClickAction" (evtClicked)="close()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
  </div>
</div>
