<div class="rhqpirct-import-file-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'rhqpirct.btn.importlistacateg'"></h5>
  </div>

  <div class="modal-body">
    <pl-upload [url]="uploadUrl" [maxFiles]="1" acceptedFiles=".xls,.csv" [maxFileSize]="20" (evtUploadedFile)="onFileUpload($event)"> </pl-upload>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</div>
