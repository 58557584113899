<div class="entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1">
        <div class="row">
          <div class="col-sm-12">
            <pl-group>
              <label [translate]="'impdecpe.listagem'"></label>
              <edit>
                <pl-edit type="reports_impdecpe" attrName="listagem" [(model)]="reportModel" plAutoFocus [properties]="{entity: {outputKey: 'name', outputDescription: 'title'}}"></pl-edit>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3">
            <pl-group>
              <label [translate]="'impdecpe.doPeriodo'"></label>
              <edit>
                <entity-autocomplete
                  entity="periodos"
                  attrName="doPeriodo"
                  [model]="header"
                  (selectedKeyChange)="doPeriodoSelected($event)"
                  [fieldsMap]="{nome: 'doPeriodoNome', periodo: 'doPeriodo'}"
                  [output]="outputPeriodo"
                  outputDescription="nome">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3">
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete
                  entity="periodos"
                  attrName="atePeriodo"
                  [model]="header"
                  (selectedKeyChange)="atePeriodoSelected($event)"
                  [fieldsMap]="{nome: 'atePeriodoNome', periodo: 'atePeriodo'}"
                  [output]="outputPeriodo"
                  outputDescription="nome">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3">
            <pl-group>
              <label [translate]="'impdecpe.doNCampo'"></label>
              <edit>
                <entity-autocomplete entity="qivaps" attrName="doNCampo" [(selectedKey)]="deNCampo" output="key"> </entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3">
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete entity="qivaps" attrName="ateNCampo" [(selectedKey)]="ateNCampo" output="key" [properties]="{events: {keydown: fnKeydownProcessar}}"> </entity-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-md-6 col-xl-3">
            <pl-group>
              <edit>
                <pl-edit type="boolean" [(model)]="imprimirCriterios" [properties]="{label: 'impdecpe.labelCriterios'}"></pl-edit>
              </edit>
            </pl-group>
          </div>
        </div>
      </pl-form>
    </div>
  </cg-card-panel>
</div>

<hr />

<cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
