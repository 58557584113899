import type {Subscription} from 'rxjs';
import {merge} from 'lodash-es';
import moment, {Moment, MomentInput} from 'moment';
import {Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ValidatorFn} from '@angular/forms';
import {EPlDatepickerKind, EPlDatepickerState, TPlDatepickerEvaluateDateFn, TPlDatepickerEvaluatedDates} from '../../../datepicker/datepicker.component.interface';
import {interpolate, isBoolean, isFunction, isUndefined} from '../../../common/utilities/utilities';
import type {IPlEditComponentOptionsInputDatepicker} from './edit.datepicker.component.interface';
import type {IPlLocale} from '../../../common/locale/locales.interface';
import type {IPlValidatorValidateParams, TPlValidatorMessageFn} from '../../../validate/validate.interface';
import {KEYCODES} from '../../../common/constants';
import {normalizeDate} from '../../../common/dates/moment.utils';
import {PlDatepickerToken} from '../../../datepicker/datepicker.token';
import {PlEditInputDropdownComponent} from '../../generic/input/edit.input.dropdown.component';
import {PlLocaleService} from '../../../common/locale/locale.service';

@Component({
  selector: 'pl-edit-datepicker',
  templateUrl: './edit.datepicker.component.html'
})
export class PlEditDatepickerComponent extends PlEditInputDropdownComponent<MomentInput, IPlEditComponentOptionsInputDatepicker> implements OnInit, OnChanges, OnDestroy {
  @Input() public kind: EPlDatepickerKind;
  @Input() public state: EPlDatepickerState;
  @Input() public disabledDates: TPlDatepickerEvaluatedDates;
  @Input() public markedDates: TPlDatepickerEvaluatedDates;
  @Input() public minimumDate: MomentInput;
  @Input() public maximumDate: MomentInput;
  @Input() public dateDisabled: TPlDatepickerEvaluateDateFn;
  @Input() public dateMarked: TPlDatepickerEvaluateDateFn;
  @Input() public showActions: boolean;
  @Input() public showActionToday: boolean;
  @Input() public viewFormat: string;
  @Output() public readonly stateChange: EventEmitter<EPlDatepickerState>;

  public formattedValue: string;

  @ViewChild(PlDatepickerToken, {static: false}) private readonly _plDatepickerComponent: PlDatepickerToken;
  private readonly _subscriptionLocale: Subscription;
  private _locale: IPlLocale;
  private _evaluatedViewFormat: string;
  private _previousViewValue: string;
  private _validatorMessageMinimumDate: TPlValidatorMessageFn<string, MomentInput>;
  private _validatorMessageMaximumDate: TPlValidatorMessageFn<string, MomentInput>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plLocaleService: PlLocaleService
  ) {
    super(_injector);
    this.stateChange = new EventEmitter<EPlDatepickerState>();
    this._defaultOptions = Object.freeze<IPlEditComponentOptionsInputDatepicker>(
      merge({}, this._defaultOptions, {
        appendToBody: true,
        kind: undefined,
        state: undefined,
        disabledDates: undefined,
        markedDates: undefined,
        minimumDate: undefined,
        maximumDate: undefined,
        dateDisabled: undefined,
        dateMarked: undefined,
        showActions: undefined,
        showActionToday: undefined,
        viewFormat: undefined
      })
    );
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this._locale = locale;
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._handleChanges();
    this.setValidators([this._requiredValidator(), this._minimumDateValidator(), this._maximumDateValidator()]);
    this._handleModelChange();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const {properties, kind, state, disabledDates, markedDates, minimumDate, maximumDate, dateDisabled, dateMarked, showActions, showActionToday, viewFormat} = changes;
    if (!properties || properties.isFirstChange()) {
      const changedKind: boolean = kind && !kind.isFirstChange();
      const changedViewFormat: boolean = viewFormat && !viewFormat.isFirstChange();
      if (changedKind) {
        this._changedKind(kind.currentValue);
      }
      if (state && !state.isFirstChange()) {
        this._changedState(state.currentValue);
      }
      if (disabledDates && !disabledDates.isFirstChange()) {
        this._changedDisabledDates(disabledDates.currentValue);
      }
      if (markedDates && !markedDates.isFirstChange()) {
        this._changedMarkedDates(markedDates.currentValue);
      }
      if (minimumDate && !minimumDate.isFirstChange()) {
        this._changedMinimumDate(minimumDate.currentValue);
      }
      if (maximumDate && !maximumDate.isFirstChange()) {
        this._changedMaximumDate(maximumDate.currentValue);
      }
      if (dateDisabled && !dateDisabled.isFirstChange()) {
        this._changedDateDisabled(dateDisabled.currentValue);
      }
      if (dateMarked && !dateMarked.isFirstChange()) {
        this._changedDateMarked(dateMarked.currentValue);
      }
      if (showActions && !showActions.isFirstChange()) {
        this._changedShowActions(showActions.currentValue);
      }
      if (showActionToday && !showActionToday.isFirstChange()) {
        this._changedShowActionToday(showActionToday.currentValue);
      }
      if (changedKind || changedViewFormat) {
        this._changedViewFormat(viewFormat?.currentValue);
      }
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionLocale.unsubscribe();
  }

  public updateComponent(properties: IPlEditComponentOptionsInputDatepicker): void {
    super.updateComponent(properties);
    this._handleChanges();
  }

  public updateValue(value: string): void {
    this.value = value;
    this._handleModelChange();
  }

  public inputValueChanged(value: string): void {
    const date: Moment = this._parseDateValue(value);
    if (date.isValid() && this._plDatepickerComponent) {
      this._plDatepickerComponent.generateCalendar(date);
    }
  }

  public showDropdown(): void {
    if (this.dropdownOpen || this.options.disabled || this.options.readonly) {
      return;
    }
    this.dropdownOpen = true;
    this.inputFocus();
  }

  public closeDropdown(): void {
    if (this.dropdownOpen) {
      this.dropdownOpen = false;
      switch (this.kind) {
        case EPlDatepickerKind.Year:
          this.changedState(EPlDatepickerState.Year);
          break;
        case EPlDatepickerKind.Month:
          this.changedState(EPlDatepickerState.Month);
          break;
        case EPlDatepickerKind.Date:
          this.changedState(EPlDatepickerState.Day);
          break;
      }
    }
  }

  public changedDate(value: MomentInput): void {
    const date: Moment = this._moment(value);
    this.value = date.toISOString();
    const newViewValue: string = date.format(this._evaluatedViewFormat);
    this.viewValue = this._previousViewValue = newViewValue;
    this.closeDropdown();
    this.inputSelectAll();
    this.render();
  }

  public changedState(state: EPlDatepickerState): void {
    this.state = state;
    this.stateChange.emit(this.state);
  }

  public onInputKeyDown(event: KeyboardEvent): void {
    if (this.options.modelOptions?.updateOn !== 'blur' && event.key === KEYCODES.ENTER && !this.dropdownOpen && this.viewValue !== this._previousViewValue) {
      this._applyDateFromView();
    }
    if (isFunction(this.options.events?.keydown)) {
      this.options.events.keydown(this.value, event);
    }
  }

  public onInputBlur(event: FocusEvent): void {
    super.onInputBlur(event);
    if (!this.isMouseIn) {
      this.closeDropdown();
    }
    if (!this.dropdownOpen && this.viewValue !== this._previousViewValue) {
      this._applyDateFromView();
    }
    if (isFunction(this.options.events?.blur)) {
      this.options.events.blur(this.value, event);
    }
  }

  private _handleChanges(): void {
    this._changedKind();
    this._changedState();
    this._changedDisabledDates();
    this._changedMarkedDates();
    this._changedMinimumDate();
    this._changedMaximumDate();
    this._changedDateDisabled();
    this._changedDateMarked();
    this._changedShowActions();
    this._changedShowActionToday();
    this._changedViewFormat();
    if (this.options.validators.minimumDate && isUndefined(this.options.validators.minimumDate.message)) {
      if (!this._validatorMessageMinimumDate) {
        this._validatorMessageMinimumDate = this._validatorMessage(this._locale.datepicker.validators.minimumDate);
      }
      this.options.validators.minimumDate.message = this._validatorMessageMinimumDate;
    }
    if (this.options.validators.maximumDate && isUndefined(this.options.validators.maximumDate.message)) {
      if (!this._validatorMessageMaximumDate) {
        this._validatorMessageMaximumDate = this._validatorMessage(this._locale.datepicker.validators.maximumDate);
      }
      this.options.validators.maximumDate.message = this._validatorMessageMaximumDate;
    }
  }

  private _changedKind(value: EPlDatepickerKind = this.kind): void {
    this.kind = value || this.options.kind || EPlDatepickerKind.Date;
  }

  private _changedState(value: EPlDatepickerState = this.state): void {
    this.state = value || this.options.state || EPlDatepickerState.Day;
  }

  private _changedDisabledDates(value: TPlDatepickerEvaluatedDates = this.disabledDates): void {
    let val: TPlDatepickerEvaluatedDates = value;
    if (isUndefined(val)) {
      val = this.options.disabledDates;
    }
    this.disabledDates = val;
  }

  private _changedMarkedDates(value: TPlDatepickerEvaluatedDates = this.markedDates): void {
    let val: TPlDatepickerEvaluatedDates = value;
    if (isUndefined(val)) {
      val = this.options.markedDates;
    }
    this.markedDates = val;
  }

  private _changedMinimumDate(value: MomentInput = this.minimumDate): void {
    let val: MomentInput = value;
    if (isUndefined(val)) {
      val = this.options.minimumDate;
    }
    if (isUndefined(val)) {
      val = this.options.validators.minimumDate?.value;
    }
    this.minimumDate = val;
  }

  private _changedMaximumDate(value: MomentInput = this.maximumDate): void {
    let val: MomentInput = value;
    if (isUndefined(val)) {
      val = this.options.maximumDate;
    }
    if (isUndefined(val)) {
      val = this.options.validators.maximumDate?.value;
    }
    this.maximumDate = val;
  }

  private _changedDateDisabled(value: TPlDatepickerEvaluateDateFn = this.dateDisabled): void {
    this.dateDisabled = value || this.options.dateDisabled;
  }

  private _changedDateMarked(value: TPlDatepickerEvaluateDateFn = this.dateMarked): void {
    this.dateMarked = value || this.options.dateMarked;
  }

  private _changedShowActions(value: boolean = this.showActions): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.showActions;
    }
    if (!isBoolean(val)) {
      val = true;
    }
    this.showActions = val;
  }

  private _changedShowActionToday(value: boolean = this.showActionToday): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.showActionToday;
    }
    if (!isBoolean(val)) {
      val = true;
    }
    this.showActionToday = val;
  }

  private _changedViewFormat(value: string = this.viewFormat): void {
    const previousViewFormat: string = this._evaluatedViewFormat;
    let val: string = value || this.options.viewFormat;
    if (!val) {
      switch (this.kind) {
        case EPlDatepickerKind.Year:
          val = 'YYYY';
          break;
        case EPlDatepickerKind.Month:
          val = 'MM-YYYY';
          break;
        case EPlDatepickerKind.Date:
          val = 'DD-MM-YYYY';
          break;
      }
    }
    this._evaluatedViewFormat = val;
    if (previousViewFormat !== this._evaluatedViewFormat) {
      this._handleModelChange();
    }
  }

  private _handleModelChange(): void {
    let newViewValue: string;
    if (this.value || this.value === 0) {
      const date: Moment = this._moment(this.value);
      newViewValue = date.format(this._evaluatedViewFormat);
      this.formattedValue = date.format(this._evaluatedViewFormat);
    } else {
      newViewValue = '';
      this.formattedValue = newViewValue;
    }
    this.viewValue = this._previousViewValue = newViewValue;
  }

  private _applyDateFromView(): void {
    const date: Moment = this._dateFromView();
    if (date.isValid()) {
      this.value = date;
    } else {
      this.value = this.viewValue = undefined;
    }
    this.render();
  }

  private _dateFromView(): Moment {
    return this._parseDateValue(this.viewValue);
  }

  private _parseDateValue(value: string): Moment {
    let format: Array<string>;
    switch (this.kind) {
      case EPlDatepickerKind.Year:
        format = [this._evaluatedViewFormat];
        break;
      case EPlDatepickerKind.Month:
        format = [this._evaluatedViewFormat, 'MM-YYYY', 'MM/YYYY', 'MMYYYY', 'YYYY-MM', 'YYYY/MM', 'YYYYMM'];
        break;
      case EPlDatepickerKind.Date:
        format = [this._evaluatedViewFormat, 'DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY', 'YYYY-MM-DD', 'YYYY/MM/DD', 'YYYYMMDD'];
        break;
    }
    return this._moment(moment(value, format));
  }

  private _requiredValidator(): ValidatorFn {
    return () => {
      if (this.validate && this.options.validators.required?.value) {
        return (this.value || this.value === 0) && this._moment(this.value).isValid ? undefined : {required: true};
      }
      return undefined;
    };
  }

  private _minimumDateValidator(): ValidatorFn {
    return () => {
      if (this.validate && (this.options.validators.minimumDate?.value || this.options.validators.minimumDate?.value === 0) && (this.value || this.value === 0)) {
        const date = this._moment(this.value);
        if (!date.isValid() || date.isBefore(this.options.validators.minimumDate.value)) {
          return {minimumDate: true};
        }
      }
      return undefined;
    };
  }

  private _maximumDateValidator(): ValidatorFn {
    return () => {
      if (this.validate && (this.options.validators.maximumDate?.value || this.options.validators.maximumDate?.value === 0) && (this.value || this.value === 0)) {
        const date = this._moment(this.value);
        if (!date.isValid() || date.isAfter(this.options.validators.maximumDate.value)) {
          return {maximumDate: true};
        }
      }
      return undefined;
    };
  }

  private _validatorMessage(message: string): TPlValidatorMessageFn<string, MomentInput> {
    return (params: IPlValidatorValidateParams<string, MomentInput>) => {
      return interpolate(message)({
        value: params.plValidatorValue || params.plValidatorValue === 0 ? this._moment(params.plValidatorValue).format(this._evaluatedViewFormat) : ''
      });
    };
  }

  private _moment(date?: MomentInput): Moment {
    return normalizeDate(date);
  }
}
