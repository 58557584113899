import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {isEmpty, PlAlertService, PlUploadErrorHandler} from 'pl-comps-angular';
import {CGExceptionService} from '../../../components/exceptions/exceptions.service';
import {ICGExceptionError} from '../../../components/exceptions/exceptions.service.interface';

@Injectable()
export class ImportadorSaftDirectUploadErrorHandler extends PlUploadErrorHandler {
  constructor(
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService
  ) {
    super();
  }

  public parseError(httpErrorResponse: HttpErrorResponse): string {
    const exception: ICGExceptionError = this._cgExceptionService.get(httpErrorResponse);
    const errorMessage = !isEmpty(exception.message) ? exception.message : 'importadorSaftDirect.messages.errorProcessFile';
    this._plAlertService.error(errorMessage);
    return errorMessage;
  }
}
