import {isObject, PlGlobalEventsService} from 'pl-comps-angular';
import {ENTITY_NAME_UTILIZADORES_BASICO, IUtilizadoresBasicEntity} from './utilizadores.basic.interface';
import {GLOBAL_EVENT_USER_CHANGED} from '../../../../config/constants';
import {TEntityServiceRequestData} from '../../../services/entity/entity.service.interface';
import {IJsonUserEmpresa} from '../../../services/account/jsonUserApi.interface';
import {RESOLVER_USER_IS_ADMINISTRATOR} from '../../../../config/uirouter/uirouter.resolvers';
import {ROLE} from '../../../services/role.const';
import {UtilizadoresBasicEditComponent} from './edit/utilizadores.basic.edit.component';
import {UtilizadoresBasicListComponent} from './list/utilizadores.basic.list.component';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {UtilizadoresInstallationsService} from '../installations/utilizadores.installations.service';

export const ENTITY_UTILIZADORES_BASICO: IUtilizadoresBasicEntity = {
  name: ENTITY_NAME_UTILIZADORES_BASICO,
  url: 'manager/userswithaccesses',
  redirectKey: 'userId',
  searchPlaceholder: 'users.pesquisa',
  pageTitle: 'global.menu.users',
  icon: 'fa-users',
  roles: [ROLE.ADMIN, ROLE.ADMINBASIC],
  metadata: {
    keyName: 'userId',
    successMessageId: 'email',
    descriptionName: 'username',
    fields: [
      {name: 'userId', type: 'cginteger', caption: 'users.fields.userId', validators: {required: true}},
      {name: 'username', caption: 'users.fields.username', validators: {required: true, maxlength: 120}},
      {
        name: 'password',
        type: 'password',
        caption: 'users.fields.password',
        validators: {required: true, maxlength: 50},
        properties: {devExpress: {dataGrid: {allowSearch: false, showInColumnChooser: false}}}
      },
      {name: 'firstName', caption: 'users.fields.firstName', validators: {required: true, maxlength: 120}},
      {name: 'lastName', caption: 'users.fields.lastName', validators: {maxlength: 120}},
      {name: 'email', type: 'email', caption: 'users.fields.email', validators: {required: true, minlength: 5, maxlength: 80}},
      {name: 'active', type: 'boolean', caption: 'users.fields.active'},
      {name: 'idContratoPai', type: 'cginteger'}
    ],
    order: 'username',
    listFields: 'username,firstName,lastName,email,active',
    searchFields: 'username,firstName,lastName,email,active'
  },
  autocomplete: {
    rowTemplate: '{{username}} ({{email}})',
    output: 'username'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    duplicate: true,
    batchAdd: true
  },
  serviceMethodsOverride: {
    get: 'getWithoutAccesses'
  },
  list: {
    state: {
      component: UtilizadoresBasicListComponent
    }
  },
  detail: {
    state: {
      component: UtilizadoresBasicEditComponent,
      resolve: [
        RESOLVER_USER_IS_ADMINISTRATOR,
        {
          provide: 'erps',
          deps: [UtilizadoresInstallationsService],
          useFactory: (utilizadoresInstallationsService: UtilizadoresInstallationsService) => utilizadoresInstallationsService.getInstallations()
        }
      ]
    }
  },
  service: function () {
    this.getWithoutAccesses = (config?: number | TEntityServiceRequestData): TServiceResponse<IJsonUserEmpresa> => {
      const id: string | number = isObject(config) ? (<TEntityServiceRequestData>config).id ?? (<TEntityServiceRequestData>config).url : <number>config;
      return this.apiService.get({url: `${this.apiService.path.restapi}/manager/users/${id}`});
    };
  },
  eventsInjectable: [
    PlGlobalEventsService,
    function (plGlobalEventsService: PlGlobalEventsService): void {
      this.onAfterSave = () => {
        plGlobalEventsService.broadcast(GLOBAL_EVENT_USER_CHANGED);
      };
    }
  ]
};
