import {Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[plAnimationRotate]'
})
export class PlAnimationRotateDirective implements OnInit, OnChanges {
  @Input() public plAnimationRotate: boolean;

  private readonly _element: HTMLElement;

  constructor(
    private readonly _elementRef: ElementRef
  ) {
    this._element = this._elementRef.nativeElement;
  }

  public ngOnInit(): void {
    this._element.classList.add(this.plAnimationRotate
      ? 'pl-animation-rotate-on'
      : 'pl-animation-rotate-off');
  }

  public ngOnChanges({plAnimationRotate}: SimpleChanges): void {
    if (plAnimationRotate && !plAnimationRotate.isFirstChange()) {
      this._element.classList.toggle('pl-animation-rotate-off');
      this._element.classList.toggle('pl-animation-rotate-on');
    }
  }
}
