import {ENTITY_NAME_TIRS, ITIRSEntity} from './tirs.entity.interface';
import {ROLE} from '../../services/role.const';
import {TDate} from '../../../common/dates';
import {TIRSEditComponent} from './components/edit/tirs.entity.edit.component';
import {TIRSListComponent} from './components/list/tirs.entity.list.component';

export const ENTITY_TIRS: ITIRSEntity = {
  name: ENTITY_NAME_TIRS,
  roles: [ROLE.RH],
  searchPlaceholder: 'tirs.pesquisa',
  metadata: {
    keyName: 'nTabIrs',
    fields: [
      {name: 'anoVigor', type: 'string', caption: 'tirs.fields.anoVigor', properties: {devExpress: {dataGrid: {visible: false}}}},
      {name: 'nTabIrs', caption: 'tirs.fields.nTabIrs', validators: {required: true, maxlength: 4}},
      {name: 'designaBreve', caption: 'tirs.fields.designaBreve', validators: {required: true}},
      {name: 'designaCompl', caption: 'tirs.fields.designaCompl', validators: {required: true}},
      {
        name: 'dataVigor',
        type: 'date',
        caption: 'tirs.fields.dtulTabela',
        validators: {required: true}
      }
    ],
    order: 'nTabIrs',
    searchFields: 'nTabIrs,designaBreve,designaCompl,dataVigor',
    filterFields: 'anoVigor,nTabIrs,designaBreve,designaCompl,dataVigor'
  },
  autocomplete: {
    rowTemplate: '{{nTabIrs}} - {{designaCompl}}',
    output: 'designaBreve',
    searchFields: 'nTabIrs,designaBreve'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      component: TIRSListComponent,
      data: {
        pageTitle: 'global.menu.tirs'
      }
    }
  },
  detail: {
    state: {
      component: TIRSEditComponent,
      data: {
        pageTitle: 'global.menu.tirs'
      }
    }
  },
  service: function () {
    this.distinctDatasEscaloes = (nTabIrs: string) => {
      return this.get({id: `${nTabIrs}/distinctdatasescaloes`});
    };

    this.tableIRSEscaloesByDate = (nTabIrs: string, date: TDate) => {
      return this.get({id: `${nTabIrs}/escaloespordata`, params: {date: date}});
    };
  }
};
