import {firstValueFrom} from 'rxjs';
import {IMutableContext, UnleashClient} from 'unleash-proxy-client';
import {Injectable, OnDestroy} from '@angular/core';
import {isNumber} from 'pl-comps-angular';
import {APP_RUNTIME_PROPERTIES} from '../../../common/app';
import {CGLocalStorageService} from '../storage/localstorage.service';
import {FEATURE_FLAGS_API_TOKEN} from '../../../../environments/constants';
import {FeatureFlagsRepository} from './featureflag.flags.repository';
import {IFeatureFlagsContext} from './featureflag.service.interface';
import {isProduction} from '../../../config/constants';

const STORAGE_KEY = 'featureflags';
const ENABLED = !isProduction();

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService implements OnDestroy {
  private readonly _client: UnleashClient;
  private readonly _flags: FeatureFlagsRepository;
  private _context: IFeatureFlagsContext;

  constructor(private readonly _localStorageService: CGLocalStorageService) {
    this._getFlag = this._getFlag.bind(this);
    this._saveFlag = this._saveFlag.bind(this);

    if (ENABLED) {
      this._client = new UnleashClient({
        url: APP_RUNTIME_PROPERTIES.value.featureFlagsAddress,
        clientKey: FEATURE_FLAGS_API_TOKEN,
        appName: 'erpcloud-front-end',
        storageProvider: {
          get: this._getFlag,
          save: this._saveFlag
        }
      });

      this._flags = new FeatureFlagsRepository(this._client);

      this._client.start();
    }
  }

  public ngOnDestroy(): void {
    if (ENABLED) {
      this._client.stop();
    }
  }

  public updateContext(context: IFeatureFlagsContext): void {
    if (!ENABLED) {
      return;
    }

    this._context = {...this._context, ...context};

    const userId: string | undefined = isNumber(this._context.licId) && isNumber(this._context.userId) ? `${this._context.licId}.${this._context.userId}` : undefined;
    const sessionId = this._context.sessionId || undefined;

    const newContext: IMutableContext = {userId: userId};
    if (sessionId) {
      newContext.sessionId = sessionId;
    }

    this._client.updateContext(newContext);
  }

  public get flags(): FeatureFlagsRepository {
    return this._flags;
  }

  private _getFlag(name: string): Promise<unknown> {
    return firstValueFrom(this._localStorageService.getItem(`${STORAGE_KEY}.${name}`, undefined));
  }

  private _saveFlag(name: string, data: unknown): Promise<void> {
    return firstValueFrom(this._localStorageService.setItem(`${STORAGE_KEY}.${name}`, data, undefined));
  }
}
