<pl-form [(formInstance)]="form" [submit]="close" plPromise>
  <div class="predefinidocontabformula-dialog">
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="dismiss()"></button>
      <h4 class="modal-title" [translate]="'predefinidocontabformula.title'" [translateParams]="{field: params.field, line: params.line}"></h4>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-sm-5 col-md-4 predefinidocontabformula-dialog-operators-wrapper" *ngIf="complex">
          <div class="row">
            <div class="col-sm-12">
              <h5 class="text-center"><strong [translate]="'predefinidocontabformula.operators'"></strong></h5>
            </div>
          </div>

          <div class="predefinidocontabformula-dialog-operators">
            <div class="predefinidocontabformula-dialog-operator" *ngFor="let operator of operators">
              <button type="button" class="btn btn-light" (click)="operator.click(operator)">{{ operator.caption }}</button>
            </div>
          </div>
        </div>

        <div [ngClass]="variablesClass">
          <div class="row">
            <div class="col-sm-12">
              <h5 class="text-center"><strong [translate]="'predefinidocontabformula.variables'"></strong></h5>
            </div>
          </div>

          <div class="predefinidocontabformula-dialog-variables">
            <div class="predefinidocontabformula-dialog-variable" *ngFor="let variable of variables">
              <button type="button" class="btn btn-light" (click)="variable.click(variable)">{{ variable.caption }}</button>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <pl-group>
        <label [translate]="'predefinidocontabformula.formula'"></label>
        <edit>
          <pl-edit
            type="text"
            attrName="formula"
            [(model)]="formula"
            [properties]="{
              disallowInput: complex,
              validators: {required: {value: true}},
              events: {blur: fnBlurFormula}
            }">
          </pl-edit>
        </edit>
      </pl-group>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button type="submit" klass="btn-success action-save"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span> </pl-button>

      <pl-button klass="btn-light" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
    </div>
  </div>
</pl-form>
