import {orderBy} from 'lodash-es';
import CustomStore from 'devextreme/data/custom_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {UntypedFormGroup} from '@angular/forms';
import {
  copy,
  EDateMonth,
  ICGTableOnSelect,
  IPlNavWizardCallback,
  IPlNavWizardDefinition,
  IPlNavWizardEventBeforeChange,
  IPlNavWizardEventValidator,
  IPlNavWizardOptions,
  IPlTableCallback,
  IPlTableDefinition,
  IPlTableOptions,
  IPlTabsCallback,
  isDefined,
  isDefinedNotNull,
  isFunction,
  PlAlertService
} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {DATA_SOURCE_MESES} from '../../../../datasources/meses/meses.datasource';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {ECalendarioTipoDia, IJsonCalendarioCab, IJsonCalendarioNDiasUteis} from '../../../../entities/calendarios/jsonCalendarios.entity.interface';
import {ENTITY_NAME_EMPRESAS, IEmpresaExtended} from '../../../../entities/empresas/empresas.entity.interface';
import {ENTITY_NAME_RH_TIPOS_PROCESSAMENTO} from '../../../../entities/rhTiposProcessamento/rhTiposProcessamento.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {
  ERHEmpregadoReProcValidationResult,
  ERHProceSalariosProceType,
  ERHProceSalariosSteps,
  IRHCacheHelper,
  IRHProceSalariosDgEmp,
  IRHProceSalariosMEmpData,
  IRHProceSalariosMEmpErro,
  IRHProceSalariosProcExistente,
  IRHProceSalariosProcExistenteEmpregado,
  IRHProceSalariosProcMultiData,
  IRHProceSalariosProcResultItem,
  IRHProceSalariosProcSingleData,
  IRHProceSalariosReProcResult,
  IRHProceSalariosResultSingleProcItem
} from '../proceSalarios.module.interface';
import {ERHTipoProcessamentoTipoPeriocidade, IJsonRHTipoProcessamento} from '../../../../entities/rhTiposProcessamento/jsonRhTiposProcessamento.entity.interface';
import {IDataSourceItem} from '../../../../components/datasource/datasources.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnInitialized
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonErpUser, IJsonUserRole} from '../../../../services/account/jsonUserApi.interface';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {IRecibosVencimentoParams, MODULE_NAME_PCA_RH_RECIBOS_VENCIMENTOS} from '../../../portalclientaccounts/rh/recibosVencimentos/recibosVencimentos.module.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {ModuloComponent} from '../../../../components/module/module.component';
import moment from 'moment';
import {ProceSalariosEmpregadosModalComponent} from '../modals/empregados/proceSalarios.empregados.modal.component';
import {ProceSalariosService} from '../proceSalarios.module.service';
import {ROLE} from '../../../../services/role.const';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';
import {ENTITY_NAME_CALENDARIOS, ICalendariosEntityService} from '../../../../entities/calendarios/calendarios.entity.interface';
import {TDate} from '../../../../../common/dates';
import {Writeable} from '../../../../../common/interfaces/interfaces';
import {ProceSalariosProgressModalComponent} from '../modals/progress/proceSalarios.progress.modal.component';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ICGExceptionError} from '../../../../components/exceptions/exceptions.service.interface';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {ProceSalariosProcErrorsModalComponent} from '../modals/procerrors/proceSalarios.procErrors.modal.component';
import {normalizeDate} from '../../../../../common/utils/moment.utils';
import {ICGOkCancelModalOptions} from '../../../../components/cg/modal/cgmodal.interface';

const STEP_INTRO_INDEX = 0;
const STEP_EMPRESAS_SEL_INDEX = 1;
const STEP_PROC_CONFIG_INDEX = 2;
const STEP_PROC_INDEX = 3;
const STEP_PROC_RESULTS_INDEX = 4;
const TAB_ID_EMPREGADOS_PROC_EXISTENTES = 'proc-existentes-tab';
const TAB_ID_EMPREGADOS_NEW_PROC = 'novo-proc-tab';
const TAB_ID_EMPREGADOS_LIST = 'empregados-tab';

const PERIOCIDADE_SEMPRE = 30;
const PERIOCIDADE_MENSAL = 30;
const PERIOCIDADE_ANUAL = 360;
const PERIOCIDADE_QUINZENAL = 15;
const PERIOCIDADE_SEMANAL = 7.5;
const TOTAL_MONTHS = 12;
const SUBSTRACT_YEARS = 10;
const TIPO_PROC_PAGAMENTO_MENSAL_ID = 1;

@Component({
  selector: 'proceSalarios',
  templateUrl: './proceSalarios.module.component.html'
})
export class ProceSalariosComponent extends ModuloComponent implements OnInit {
  public readonly mesesSource: Array<Writeable<IDataSourceItem<EDateMonth>>>;
  public readonly yearsSource: Array<number>;
  public readonly dataGridDefinitionProceExist: IDevExpressDataGrid<IRHProceSalariosProcExistente, number>;
  public readonly dataGridDefinitionProceExistEmpregados: IDevExpressDataGrid<IRHProceSalariosProcExistenteEmpregado, number>;
  public readonly dataGridDefinitionDGEmp: IDevExpressDataGrid<IRHProceSalariosDgEmp, number>;
  public readonly dataGridDefinitionProcResultsSingle: IDevExpressDataGrid<IRHProceSalariosResultSingleProcItem, number>;
  public readonly dataGridDefinitionProcResults: IDevExpressDataGrid<IRHProceSalariosProcResultItem, string>;
  public readonly dataGridDefinitionprocResultsError: IDevExpressDataGrid<IRHProceSalariosMEmpErro>;
  public readonly definitionNavWizard: IPlNavWizardDefinition;
  public readonly dgEmpTabCallback: IPlTabsCallback;
  public readonly proceSalariosSteps: typeof ERHProceSalariosSteps;
  public readonly proceTypes: typeof ERHProceSalariosProceType;
  public readonly tabIdEmpregadosProcExistentes: string;
  public readonly tabIdEmpregadosNewProc: string;
  public readonly tabIdEmpregadosList: string;
  public readonly msEmpresasTemplate: string;

  public empresasSource: Array<IJsonErpUser>;
  public empresasSel: Array<IJsonErpUser>;
  public selectedKeysDGEmp: Array<number>;
  public propertiesNavWizard: IPlNavWizardOptions;
  public plWizardCallback: IPlNavWizardCallback;
  public multiDatasTableDef: IPlTableDefinition;
  public multiDatasTableCallback: IPlTableCallback;
  public multiDatasTableOptions: IPlTableOptions;
  public modelSingleEmp: IRHProceSalariosProcSingleData;
  public modelMultiEmp: IRHProceSalariosProcMultiData;
  public resultsProcType: ERHProceSalariosProceType;
  public procExistingMonth: EDateMonth;
  public newProcForm: UntypedFormGroup;
  public tipoProcessamentoOutput: string;
  public singleEmpresaCaption: string;
  public calendarioOutput: string;
  public orderbyselected: string;
  public pbProcLabel: string;
  public activeTab: string;
  public pbProcPos: number;
  public procExistingYear: number;
  public procResultsOnlyWithErrors: boolean;
  public isBlocked: boolean;

  private readonly _multiDatasSourceCache: {[year: number]: Array<IRHProceSalariosMEmpData>};
  private readonly _serviceEmpresas: IEntityService<IEmpresaExtended>;
  private readonly _maintenanceRecibos: IModuleMaintenanceInstance;
  private readonly _rhTipoProcessEntity: IEntityService<IJsonRHTipoProcessamento>;
  private readonly _currentMonth: number;
  private readonly _calendariosService: ICalendariosEntityService;
  private _multiDatasTableSource: Array<IRHProceSalariosMEmpData>;
  private _procResultsTableSource: Array<IRHProceSalariosProcResultItem>;
  private _dgEmpTableSource: Array<IRHProceSalariosDgEmp>;
  private _procResultsSingleTableSource: Array<IRHProceSalariosResultSingleProcItem>;
  private _procExistentesTableSource: Array<IRHProceSalariosProcExistente>;
  private _dataGridInstanceProceExist: dxDataGrid<IRHProceSalariosProcExistente, number>;
  private _dataGridInstanceProcResultsSingle: dxDataGrid<IRHProceSalariosResultSingleProcItem, number>;
  private _dataGridInstanceProcResults: dxDataGrid<IRHProceSalariosProcResultItem, string>;
  private _cacheHelper: IRHCacheHelper;
  private _isFirstTime: boolean;
  private _processModalInstance: NgbModalRef;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _proceSalariosService: ProceSalariosService,
    private readonly _cgExceptionService: CGExceptionService
  ) {
    super(_injector);
    this._maintenanceRecibos = this._moduleMaintenanceService.build(MODULE_NAME_PCA_RH_RECIBOS_VENCIMENTOS);
    this._serviceEmpresas = this._entityServiceBuilder.build<IEmpresaExtended>(ENTITY_NAME_EMPRESAS);
    this._rhTipoProcessEntity = this._entityServiceBuilder.build<IJsonRHTipoProcessamento>(ENTITY_NAME_RH_TIPOS_PROCESSAMENTO);
    this.msEmpresasTemplate = '{{nEmpresa}} - {{nomeEmpresa}}';
    this.tipoProcessamentoOutput = '{{tipoProcessamento}} - {{descricao}}';
    this.calendarioOutput = '{{calenCabID}} - {{nome}}';
    this.tabIdEmpregadosProcExistentes = TAB_ID_EMPREGADOS_PROC_EXISTENTES;
    this.tabIdEmpregadosNewProc = TAB_ID_EMPREGADOS_NEW_PROC;
    this.tabIdEmpregadosList = TAB_ID_EMPREGADOS_LIST;
    this._currentMonth = moment().month() + 1;
    this._calendariosService = this._entityServiceBuilder.build<IJsonCalendarioCab, ICalendariosEntityService>(ENTITY_NAME_CALENDARIOS);
    this.procExistingYear = moment().year();
    this.procExistingMonth = this._currentMonth;
    this.activeTab = this.tabIdEmpregadosNewProc;
    this._cacheHelper = {
      lastTipoProcessamento: undefined,
      lastSelectedSingleNEmpresa: undefined,
      lastDataInicio: undefined,
      lastDataFim: undefined
    };
    this.selectedKeysDGEmp = [];
    const minYear = moment().subtract(SUBSTRACT_YEARS, 'years').year();
    const maxYear = moment().add(1, 'years').year();
    this.yearsSource = [];
    for (let i = minYear; i <= maxYear; i++) {
      this.yearsSource.push(i);
    }
    this.mesesSource = copy(<Array<Writeable<IDataSourceItem<EDateMonth>>>>DATA_SOURCE_MESES.data);
    for (const mes of this.mesesSource) {
      mes.name = this._translateService.instant(mes.name);
    }
    this.dgEmpTabCallback = {};
    this.definitionNavWizard = {
      items: []
    };
    this.proceSalariosSteps = ERHProceSalariosSteps;
    this.proceTypes = ERHProceSalariosProceType;
    this.propertiesNavWizard = {
      disableNavigation: false,
      disablePreviousStep: false,
      disableNextStep: false
    };
    this.plWizardCallback = {};
    this._multiDatasSourceCache = {};
    this._multiDatasTableSource = [];
    this.multiDatasTableCallback = {};
    this.empresasSel = [];
    this.multiDatasTableDef = {
      fields: [
        {name: 'selected', caption: 'proceSalarios.fields.selected', type: 'boolean'},
        {name: 'descricao', caption: 'proceSalarios.fields.mesAno'}
      ]
    };
    this.multiDatasTableOptions = {perPage: 12, suppressEmptyLines: true, hidePagination: true};
    this._procResultsSingleTableSource = [];
    this._dgEmpTableSource = [];
    this.dataGridDefinitionProceExist = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nProcessamento', dataType: 'number', caption: 'proceSalarios.fields.nProcessamento'},
        {dataField: 'dataProcessamento', dataType: 'date', caption: 'proceSalarios.fields.dataProcessamento'},
        {dataField: 'tipoProcessamento', dataType: 'number', caption: 'proceSalarios.fields.tipoProcessamento'},
        {dataField: 'descricao', dataType: 'string', caption: 'proceSalarios.fields.descricao'},
        {dataField: 'dataInicoProc', dataType: 'date', caption: 'proceSalarios.fields.dataInicoProc'},
        {dataField: 'dataFimProce', dataType: 'date', caption: 'proceSalarios.fields.dataFimProce'},
        {dataField: 'calenCabID', dataType: 'number', caption: 'proceSalarios.fields.calendar'},
        {dataField: 'nDiasUteis', dataType: 'number', caption: 'proceSalarios.fields.nDiasUteis'},
        {dataField: 'nDiasParaProcessamen', dataType: 'number', caption: 'proceSalarios.fields.nDiasParaProcessamen'},
        {dataField: 'nDiasTotalMes', dataType: 'number', caption: 'proceSalarios.fields.nDiasTotalMes'},
        {dataField: 'anoParaSegSocial', dataType: 'number', caption: 'proceSalarios.fields.anoParaSegSocial'},
        {dataField: 'mesParaSegSocial', dataType: 'number', caption: 'proceSalarios.fields.mesParaSegSocial'},
        {dataField: 'proceImportado', dataType: 'boolean', caption: 'proceSalarios.fields.proceImportado'},
        {dataField: 'tipoProcessamentoDescricao', dataType: 'string', caption: 'proceSalarios.fields.tipoProcessamentoDescricao'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnProceExist'}
      ],
      dataSource: new CustomStore({
        load: () => this._proceExistTableSource()
      }),
      export: {filename: 'proceSalarios.procExistentesTitle'},
      masterDetail: {enabled: true, autoExpandAll: false, template: 'masterDetailProceExist'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplateProceExist',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.dataGridDefinitionProceExistEmpregados = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nProcessamento', dataType: 'number', caption: 'proceSalarios.fields.nProcessamento'},
        {dataField: 'codEmpregado', dataType: 'number', caption: 'proceSalarios.fields.codEmp'},
        {dataField: 'nomeEmpregado', dataType: 'string', caption: 'proceSalarios.fields.nome'},
        {dataField: 'nRecibo', dataType: 'number', caption: 'proceSalarios.fields.nRecibo'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnProceExistEmpregados'}
      ],
      export: {filename: 'proceSalarios.procExistentesTitle'},
      keyExpr: 'codEmpregado',
      remoteOperations: false,
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplateProceExistEmpregados',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.dataGridDefinitionDGEmp = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'codEmp', dataType: 'number', caption: 'proceSalarios.fields.codEmp'},
        {dataField: 'nome', dataType: 'string', caption: 'proceSalarios.fields.nome'},
        {dataField: 'nBIdentidade', dataType: 'string', caption: 'proceSalarios.fields.nBIdentidade'},
        {dataField: 'nContribuinte', dataType: 'string', caption: 'proceSalarios.fields.nContribuinte'},
        {dataField: 'dataNascimento', dataType: 'date', caption: 'proceSalarios.fields.dataNascimento'}
      ],
      dataSource: new CustomStore({
        key: 'codEmp',
        cacheRawData: false,
        load: () => this._getDGEMPTableDefTableSource()
      }),
      export: {filename: 'proceSalarios.empregadosTitle'},
      remoteOperations: false,
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'}
    };
    this.dataGridDefinitionProcResultsSingle = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'codEmp', dataType: 'number', caption: 'proceSalarios.fields.codEmp'},
        {dataField: 'nome', dataType: 'string', caption: 'proceSalarios.fields.nome'},
        {dataField: 'nProcessamento', dataType: 'number', caption: 'proceSalarios.fields.nProcessamento'},
        {dataField: 'nRecibo', dataType: 'string', caption: 'proceSalarios.fields.nRecibo'},
        {dataField: 'dataProcessamento', dataType: 'date', caption: 'proceSalarios.fields.dataProcessamento'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnProcResultsSingle'}
      ],
      dataSource: new CustomStore({
        key: 'codEmp',
        load: () => this._getProcResultsSingleTableSource()
      }),
      export: {filename: 'proceSalarios.tabResultsCaption'},
      remoteOperations: false
    };
    this.dataGridDefinitionProcResults = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'psNEmpresa', dataType: 'string', caption: 'proceSalarios.fields.nEmpresa'},
        {dataField: 'psNomeEmpresa', dataType: 'string', caption: 'proceSalarios.fields.nomeEmpresa'},
        {dataField: 'mes', dataType: 'number', caption: 'proceSalarios.fields.mes'},
        {dataField: 'ano', dataType: 'number', caption: 'proceSalarios.fields.ano'},
        {dataField: 'dataProce', dataType: 'date', caption: 'proceSalarios.fields.dataProce'},
        {dataField: 'dADataProc', dataType: 'date', caption: 'proceSalarios.fields.dADataProc'},
        {dataField: 'ateDataProc', dataType: 'date', caption: 'proceSalarios.fields.ateDataProc'},
        {dataField: 'nDiasUteis', dataType: 'number', caption: 'proceSalarios.fields.nDiasUteis'},
        {dataField: 'descricao', dataType: 'string', caption: 'proceSalarios.fields.descricao'}
      ],
      dataSource: new CustomStore({
        key: 'psNEmpresa',
        load: () => this._getProcResultsTableSource()
      }),
      export: {filename: 'proceSalarios.tabResultsCaption'},
      masterDetail: {enabled: true, autoExpandAll: false, template: 'masterDetailProcResults'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplateProcResults',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.dataGridDefinitionprocResultsError = {
      columnChooser: {enabled: false},
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nEmpresa', dataType: 'string', caption: 'proceSalarios.fields.nEmpresa'},
        {dataField: 'mesAno', dataType: 'string', caption: 'proceSalarios.fields.mesAno'},
        {dataField: 'erro', dataType: 'string', caption: 'proceSalarios.fields.erro'}
      ],
      export: {enabled: false},
      filterRow: {visible: false},
      remoteOperations: false
    };
    this._init();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._isFirstTime = true;
    this._loadEmpresas();
    this._resetWizard();
    this.orderbyselected = this._translateService.instant('proceSalarios.btn.dropdown.sortEmpresasByNEmpresa');
  }

  public onMultiYearChange(year: number): void {
    this.modelMultiEmp.ano = year;
    this.modelMultiEmp.mes = -1;
  }

  public onDecYearClick(): void {
    this.modelMultiEmp.ano -= 1;
    if (isFunction(this.multiDatasTableCallback.refresh)) {
      this.multiDatasTableCallback.refresh();
    }
  }

  public onIncYearClick(): void {
    this.modelMultiEmp.ano += 1;
    if (isFunction(this.multiDatasTableCallback.refresh)) {
      this.multiDatasTableCallback.refresh();
    }
  }

  public isMultiProc(): boolean {
    return this.empresasSel.length > 1;
  }

  public changedProcResultsOnlyWithErrors(value: boolean): void {
    this.procResultsOnlyWithErrors = value;
    this._refreshProcResultsTable();
  }

  public onDataInicioChange(value: TDate): void {
    this.modelSingleEmp.dataInicio = normalizeDate(value);
    this.modelSingleEmp.dataFim = normalizeDate(moment(this.modelSingleEmp.dataInicio).clone().endOf('month'));
    this.modelSingleEmp.dataProcessamento = normalizeDate(moment(this.modelSingleEmp.dataFim).clone().endOf('month'));
    this._fillAdditionalFields();
  }

  public onDataFimChange(value: TDate): void {
    this.modelSingleEmp.dataFim = normalizeDate(value);
    this.modelSingleEmp.dataProcessamento = normalizeDate(moment(this.modelSingleEmp.dataFim).clone().endOf('month'));
    this._fillAdditionalFields();
  }

  public onDataProcessamentoChange(value: TDate): void {
    this.modelSingleEmp.dataProcessamento = normalizeDate(value);
    this._fillAdditionalFields();
  }

  public fnCalendarioChange(value: number): void {
    this.modelSingleEmp.calendario = value;
    this._getNDias();
  }

  public fnTipoProcessamentoChange(data: IJsonRHTipoProcessamento): void {
    this.modelSingleEmp.tipoProcessamento = data.tipoProcessamento;
    this.modelSingleEmp.descricaoTipoProc = data.descricao;
    this.modelSingleEmp.nDiasTotalMes = this._getNDiasTotalMes(data.tipoPeriodicidade);
  }

  public fnEmpresasChanged(): void {
    this.definitionNavWizard.items[STEP_EMPRESAS_SEL_INDEX].setIncomplete();
  }

  public onYearChange(year: number): void {
    this.procExistingYear = year;
    if (this._dataGridInstanceProceExist) {
      this._dataGridInstanceProceExist.refresh();
    }
  }

  public onMonthChange(month: EDateMonth): void {
    this.procExistingMonth = month;
    if (this._dataGridInstanceProceExist) {
      this._dataGridInstanceProceExist.refresh();
    }
  }

  public async reProcEmp(item: IRHProceSalariosProcExistenteEmpregado, errorList: Array<string> = undefined): Promise<void> {
    let message;
    let showDefaultMessage = !item.existeTransfBancaria && !item.fichCriadoSegSocial;
    let modalTitle = 'proceSalarios.modalConfirmTitle';
    let modalOptions: ICGOkCancelModalOptions;

    if (item.existeTransfBancaria) {
      showDefaultMessage = false;
      if (item.rhPodeProcessarSalarioscomTransfBancariaGerado) {
        modalOptions = {btnOkText: 'global.btn.yes', btnCancelText: 'global.btn.no'};
        message = this._translateService.instant('proceSalarios.empregadoTemTransfBancariaPrompt', {codEmp: item.codEmpregado, nome: item.nomeEmpregado});
      } else {
        modalTitle = 'proceSalarios.erro';
        modalOptions = {showOkBtn: false, btnCancelText: 'global.btn.close'};
        message = this._translateService.instant('proceSalarios.naoPodeReprocExisteTransfBancaria', {codEmp: item.codEmpregado, nome: item.nomeEmpregado});
      }
      await this._cgModalService.showOkCancel(modalTitle, message, modalOptions);
      if (!item.rhPodeProcessarSalarioscomTransfBancariaGerado) {
        return;
      }
    }
    if (item.fichCriadoSegSocial) {
      if (item.rhPodeProcessarSalarioscomSegSocialGerado) {
        modalOptions = {btnOkText: 'global.btn.yes', btnCancelText: 'global.btn.no'};
        modalTitle = 'proceSalarios.modalConfirmTitle';
        message = this._translateService.instant('proceSalarios.empregadoTemFichSegSocialPrompt', {codEmp: item.codEmpregado, nome: item.nomeEmpregado});
      } else {
        modalTitle = 'proceSalarios.erro';
        modalOptions = {showOkBtn: false, btnCancelText: 'global.btn.close'};
        message = this._translateService.instant('proceSalarios.naoPodeReprocExisteFichSegSocial', {codEmp: item.codEmpregado, nome: item.nomeEmpregado});
      }
      await this._cgModalService.showOkCancel(modalTitle, message, modalOptions);
      if (!item.rhPodeProcessarSalarioscomSegSocialGerado) {
        return;
      }
    }

    if (showDefaultMessage) {
      modalTitle = 'proceSalarios.modalConfirmTitle';
      message = this._translateService.instant('proceSalarios.confirmReProcEncargoMsg', {codEmp: item.codEmpregado, nome: item.nomeEmpregado});
      await this._cgModalService.showOkCancel(modalTitle, message);
    }

    const response = await this._proceSalariosService.reProcEmpregado(item.nProcessamento, item.codEmpregado, this.modelSingleEmp.nEmpresa);
    if (response.errors.length) {
      for (const error of response.errors) {
        const msg = `${error.descTipoErro} : ${error.descricaoErro}`;
        if (isDefinedNotNull(errorList)) {
          errorList.push(msg);
        } else {
          this._plAlertService.error(msg);
        }
      }
    } else {
      this._plAlertService.success(this._translateService.instant('proceSalarios.empregadoReprocessado', {codEmp: item.codEmpregado}));
    }
  }

  public reProcEncargos(item: IRHProceSalariosProcExistenteEmpregado): Promise<void> {
    const message: string = this._translateService.instant('proceSalarios.confirmReProcEncargoMsg', {codEmp: item.codEmpregado, nome: item.nomeEmpregado});
    return this._cgModalService.showOkCancel('proceSalarios.modalConfirmTitle', message).then(() => {
      this._proceSalariosService.reProcEncargos(item.nProcessamento, item.codEmpregado, this.modelSingleEmp.nEmpresa).then((response) => {
        if (response.errors.length) {
          for (const error of response.errors) {
            this._plAlertService.error(`${error.descTipoErro} : ${error.descricaoErro}`);
          }
        } else {
          this._plAlertService.success(this._translateService.instant('proceSalarios.encargosReprocessados', {codEmp: item.codEmpregado}));
        }
      });
    });
  }

  public sortEmpresasByNEmpresa(): void {
    this.empresasSource = orderBy(this.empresasSource, ['nEmpresa', 'asc']);
    this.empresasSel = orderBy(this.empresasSel, ['nEmpresa', 'asc']);
    this.orderbyselected = this._translateService.instant('proceSalarios.btn.dropdown.sortEmpresasByNEmpresa');
  }

  public sortEmpresasByName(): void {
    this.empresasSource = orderBy(this.empresasSource, ['nomeEmpresa', 'asc']);
    this.empresasSel = orderBy(this.empresasSel, ['nomeEmpresa', 'asc']);
    this.orderbyselected = this._translateService.instant('proceSalarios.btn.dropdown.sortEmpresasByName');
  }

  public changedTab(nextId: string): void {
    this.propertiesNavWizard = {...this.propertiesNavWizard, hideFooter: nextId === this.tabIdEmpregadosProcExistentes};
    this.activeTab = nextId;
  }

  public consultarRecibos(nProcessamento: number, codEmpregado: number): void {
    const params: IRecibosVencimentoParams = {
      codEmp: codEmpregado,
      nProcessamento: nProcessamento
    };
    this._maintenanceRecibos.maintenance({
      params: params,
      modalOptions: {
        size: 'xl'
      }
    });
  }

  public onSelectMultiDatasTable(data: ICGTableOnSelect<IRHProceSalariosMEmpData>): void {
    for (const item of this._multiDatasTableSource) {
      item.selected = false;
    }
    data.item.selected = !data.item.selected;
    if (data.item.selected) {
      this.modelMultiEmp.mes = data.item.mes;
      this.modelMultiEmp.descricao = data.item.descricao;
    }
    this.multiDatasTableCallback.rePaintBody();
  }

  public onInitializedProceExist({component}: IDevExpressDataGridEventOnInitialized<IRHProceSalariosProcExistente, number>): void {
    this._dataGridInstanceProceExist = component;
  }

  public onCellClickProceExist(event: IDevExpressDataGridEventOnCellClick<IRHProceSalariosProcExistente, number>): void {
    devExpressDataGridExpandDetailHandler(event, () => {
      return this._proceSalariosService.openProce(event.data.nProcessamento, this.modelSingleEmp.nEmpresa).then((response: Array<IRHProceSalariosProcExistenteEmpregado>) => {
        event.data.listEmpregados = response;
      });
    }).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }

  public onInitializedProceExistEmpregados(itemExistente: IRHProceSalariosProcExistente, {component}: IDevExpressDataGridEventOnInitialized<IRHProceSalariosProcExistenteEmpregado, number>): void {
    itemExistente.listEmpregadosTableInstance = component;
  }

  public onInitializedProcResultsSingle({component}: IDevExpressDataGridEventOnInitialized<IRHProceSalariosResultSingleProcItem, number>): void {
    this._dataGridInstanceProcResultsSingle = component;
  }

  public onInitializedProcResults({component}: IDevExpressDataGridEventOnInitialized<IRHProceSalariosProcResultItem, string>): void {
    this._dataGridInstanceProcResults = component;
  }

  public onCellPreparedProcResults(event: IDevExpressDataGridEventOnCellPrepared<IRHProceSalariosProcResultItem, string>): void {
    if (event.rowType === 'data') {
      if (event.column.command === 'expand' && !event.data.temErro) {
        (<HTMLElement>event.cellElement.childNodes[0]).classList.remove('dx-datagrid-group-closed');
        event.cellElement.classList.remove('dx-datagrid-expand');
      }
      if (event.data.temErro) {
        event.cellElement.classList.add('text-danger');
      }
    }
  }

  public readonly fnValidateStepEmpresasSel: (event: IPlNavWizardEventValidator) => boolean | Promise<void | boolean> = () => this._validateStepEmpresasSel();

  public readonly fnValidateStepProcConfig: (event: IPlNavWizardEventValidator) => boolean | Promise<void | boolean> = () => this._validateStepProcConfig();

  public readonly fnFinalize: () => void = () => {
    this._finalize();
  };

  public readonly fnBeforeStepChange = (event: IPlNavWizardEventBeforeChange): boolean => this._beforeStepChange(event);

  public readonly fnMultiDatasTableSource = (): Array<IRHProceSalariosMEmpData> => this._getMultiDatasTableSource();

  public readonly fnDeleteProcess = (item: IRHProceSalariosProcExistente) => (): Promise<void> => this._deleteProcess(item);

  private _getNDiasTotalMes(tipo: ERHTipoProcessamentoTipoPeriocidade): number {
    switch (tipo) {
      case ERHTipoProcessamentoTipoPeriocidade.Sempre:
        return PERIOCIDADE_SEMPRE;
      case ERHTipoProcessamentoTipoPeriocidade.Mensal:
        return PERIOCIDADE_MENSAL;
      case ERHTipoProcessamentoTipoPeriocidade.Anual:
        return PERIOCIDADE_ANUAL;
      case ERHTipoProcessamentoTipoPeriocidade.Quinzenal:
        return PERIOCIDADE_QUINZENAL;
      case ERHTipoProcessamentoTipoPeriocidade.Semanal:
        return PERIOCIDADE_SEMANAL;
      default:
        return PERIOCIDADE_SEMPRE;
    }
  }

  private _init(): void {
    this.pbProcLabel = '';
    this.pbProcPos = 0;
    this.procResultsOnlyWithErrors = false;
    this._procResultsTableSource = [];
    this._procExistentesTableSource = [];
    this.singleEmpresaCaption = '';

    this.modelMultiEmp = {
      ano: moment().year(),
      tipoProceMensal: true,
      tipoProceNatal: false,
      tipoProceFerias: false,
      podeReprocessarSalarios: false,
      mes: this._currentMonth,
      descricao: '',
      empresas: []
    };

    this.modelSingleEmp = {
      empList: [],
      tipoProcessamento: undefined,
      dataProcessamento: normalizeDate(moment().endOf('month')),
      dataInicio: normalizeDate(moment().startOf('month')),
      dataFim: normalizeDate(moment().endOf('month')),
      calendario: undefined,
      nDiasUteis: 0,
      descricao: '',
      anoParaSegSocial: moment().year(),
      mesParaSegSocial: this._currentMonth,
      nDiasTotalMes: 0,
      nEmpresa: ''
    };
    this._fillAdditionalFields();

    this.pbProcLabel = 'A iniciar o processamento...';
  }

  private _loadEmpresas(): Promise<void> {
    const erps: Array<string> = this.session.erps.map((erp: IJsonErpUser) => erp.nEmpresa);
    const filter = `NEMPRESA=${erps.join(',')}`;
    return this._serviceEmpresas.query({pesquisa: filter}).then((response: THttpQueryResponse<IEmpresaExtended>) => {
      const empresasComNaoProcessaSalarios: Array<string> = response.body.list
        .filter((empresa: IEmpresaExtended) => empresa.naoProcSalarios)
        .map<string>((empresa: IEmpresaExtended) => empresa.nempresa);
      const empresas: Array<IJsonErpUser> = this.session.erps.filter((erp: IJsonErpUser) => {
        if (empresasComNaoProcessaSalarios.includes(erp.nEmpresa)) {
          return false;
        }
        const role: IJsonUserRole = erp.rolesAcess.find((erpRole: IJsonUserRole) => erpRole.role === ROLE.RH);
        return isDefined(role);
      });
      let idx = -1;
      const foundItem = empresas.find((item: IJsonErpUser, index: number) => {
        if (item.nEmpresa === this.session.erp.nEmpresa) {
          idx = index;
          return true;
        }
        return false;
      });
      if (idx > -1 && isDefinedNotNull(foundItem)) {
        empresas.splice(idx, 1);
        this.empresasSel = [foundItem];
      }
      this.empresasSource = empresas;
      this.sortEmpresasByNEmpresa();
    });
  }

  private _finalize(): void {
    this._wizNavControl(true, false);
    this._init();
    this._isFirstTime = true;
    this._invalidateSteps();
    this._setWizActiveStep(ERHProceSalariosSteps.INTRO);
    this.definitionNavWizard.items[STEP_INTRO_INDEX].setIncomplete();
    this.dgEmpTabCallback.select(TAB_ID_EMPREGADOS_NEW_PROC);
  }

  private async _validateStepEmpresasSel(): Promise<void> {
    if (!this.empresasSel.length) {
      const error = this._translateService.instant('proceSalarios.temSeleccionarUmaEmpresa');
      this._plAlertService.error(error);
      throw new Error(error);
    }
    if (!this.isMultiProc()) {
      this.modelSingleEmp.nEmpresa = this.empresasSel[0].nEmpresa;
      this.singleEmpresaCaption = `${this.empresasSel[0].nEmpresa} - ${this.empresasSel[0].nomeEmpresa}`;

      let calendarioCab: IJsonCalendarioCab;
      try {
        calendarioCab = (await this._calendariosService.getDefaultCabecalho()).body;
      } catch (reason: unknown) {
        this._logger.error(reason);
      }

      const rhTipoProcessResponse: THttpQueryResponse<IJsonRHTipoProcessamento> = await this._rhTipoProcessEntity.query({pagina: 1, porpagina: 1});
      const defaults: Partial<IRHProceSalariosProcSingleData> = {};

      if (rhTipoProcessResponse.body.list.length) {
        defaults.tipoProcessamento = rhTipoProcessResponse.body.list[0].tipoProcessamento;
        defaults.descricaoTipoProc = rhTipoProcessResponse.body.list[0].descricao;
        defaults.nDiasTotalMes = this._getNDiasTotalMes(rhTipoProcessResponse.body.list[0].tipoPeriodicidade);
        this._cacheHelper.lastTipoProcessamento = rhTipoProcessResponse.body.list[0].tipoProcessamento;
      }

      if (calendarioCab) {
        defaults.calendario = calendarioCab.calenCabID;
        defaults.calenCabDesc = calendarioCab.nome;
      }

      this.modelSingleEmp = {...this.modelSingleEmp, ...defaults};

      if (calendarioCab) {
        this.fnCalendarioChange(this.modelSingleEmp.calendario);
      }
    }
  }

  private _validateStepProcConfig(): Promise<boolean> {
    return this._processar();
  }

  private _getMultiDatasTableSource(): Array<IRHProceSalariosMEmpData> {
    this._multiDatasTableSource = this._multiDatasSourceCache[this.modelMultiEmp.ano];
    if (this._multiDatasTableSource) {
      if (this._isFirstTime) {
        for (const item of this._multiDatasTableSource) {
          item.selected = false;
        }
        this._selectMultiDatasCurrentMonth();
        this._isFirstTime = false;
      }
      return this._multiDatasTableSource;
    }
    this._multiDatasTableSource = this._buildDataSource(this.modelMultiEmp.ano);
    if (this._isFirstTime) {
      this._selectMultiDatasCurrentMonth();
      this._isFirstTime = false;
    }
    this._multiDatasSourceCache[this.modelMultiEmp.ano] = this._multiDatasTableSource;
    return this._multiDatasTableSource;
  }

  private _getProcResultsTableSource(): Array<IRHProceSalariosProcResultItem> {
    return this.procResultsOnlyWithErrors ? this._procResultsTableSource.filter((item: IRHProceSalariosProcResultItem) => item.temErro) : this._procResultsTableSource;
  }

  private _getProcResultsSingleTableSource(): Array<IRHProceSalariosResultSingleProcItem> {
    return this._procResultsSingleTableSource;
  }

  private _getDGEMPTableDefTableSource(): Promise<Array<IRHProceSalariosDgEmp>> {
    if (!this._mustReloadDGEMPTable()) {
      return Promise.resolve(this._dgEmpTableSource);
    }
    this.selectedKeysDGEmp = [];
    this._cacheHelper = {
      lastTipoProcessamento: this.modelSingleEmp.tipoProcessamento,
      lastSelectedSingleNEmpresa: this.modelSingleEmp.nEmpresa,
      lastDataInicio: this.modelSingleEmp.dataInicio,
      lastDataFim: this.modelSingleEmp.dataFim
    };
    return this._proceSalariosService
      .getEmpregados(this.modelSingleEmp.nEmpresa, this.modelSingleEmp.tipoProcessamento, this.modelSingleEmp.dataInicio, this.modelSingleEmp.dataFim)
      .then((response: Array<IRHProceSalariosDgEmp>) => {
        this._dgEmpTableSource = response;
        for (const item of this._dgEmpTableSource) {
          if (this.modelSingleEmp.tipoProcessamento === TIPO_PROC_PAGAMENTO_MENSAL_ID) {
            this.selectedKeysDGEmp.push(item.codEmp);
          }
        }
        return this._dgEmpTableSource;
      });
  }

  private _resetWizard(): void {
    this._wizNavControl(true, true);
    if (this.definitionNavWizard.items.length) {
      this._invalidateSteps();
      this._setWizActiveStep(ERHProceSalariosSteps.INTRO);
      this.definitionNavWizard.items[STEP_INTRO_INDEX].setIncomplete();
    }
  }

  private _toggleLoader(show: boolean, numEmpresas: number = 1): void {
    if (show) {
      this._processModalInstance = this._cgModalService.showVanilla(ProceSalariosProgressModalComponent, {
        size: 'md',
        backdrop: 'static',
        keyboard: false
      });
      const componentInstance: ProceSalariosProgressModalComponent = this._processModalInstance.componentInstance;
      componentInstance.numEmpresas = numEmpresas;
    } else if (isDefinedNotNull(this._processModalInstance)) {
      this._processModalInstance.close();
    }
  }

  private _processar(): Promise<boolean> {
    if (this.isMultiProc()) {
      if (this.modelMultiEmp.mes < 1) {
        this._plAlertService.error(this._translateService.instant('proceSalarios.temSeleccionarUmMesParaProcessar'));
        return Promise.resolve(false);
      }

      if (!this.modelMultiEmp.tipoProceMensal && this.modelMultiEmp.tipoProceFerias && this.modelMultiEmp.tipoProceNatal) {
        this._plAlertService.error(this._translateService.instant('proceSalarios.temSeleccionarPeloMenosTipoProcessamento'));
        return Promise.resolve(false);
      }
      this._wizNavControl(false, false);
      this.modelMultiEmp.empresas = this.empresasSel.map((item: IJsonErpUser) => item.nEmpresa);
      this._toggleLoader(true, this.modelMultiEmp.empresas.length);
      this._proceSalariosService
        .processarMulti(this.modelMultiEmp)
        .then((response) => {
          this._setWizActiveStep(ERHProceSalariosSteps.PROC_RESULTS, true);
          this._wizNavControl(false, true, true);
          this.resultsProcType = ERHProceSalariosProceType.Multi;
          this._procResultsTableSource = response.body;
          this._refreshProcResultsTable();
        })
        .catch((reason: HttpErrorResponse) => {
          if (reason instanceof HttpErrorResponse) {
            const exception: ICGExceptionError = this._cgExceptionService.get(reason);
            this._plAlertService.error(exception.message);
          } else {
            this._logger.error(reason);
          }
          this._wizNavControl(true, false);
          this.definitionNavWizard.items[STEP_PROC_CONFIG_INDEX].setIncomplete();
          this._setWizActiveStep(ERHProceSalariosSteps.PROC_CONFIG);
        })
        .finally(() => {
          this._toggleLoader(false);
        });
      return Promise.resolve(true);
    }

    if (this.newProcForm.invalid) {
      this._plAlertService.error(this._translateService.instant('proceSalarios.existemCamposComErros'));
      this.dgEmpTabCallback.select(TAB_ID_EMPREGADOS_NEW_PROC);
      return Promise.resolve(false);
    }

    this.modelSingleEmp.empList = this.selectedKeysDGEmp;

    if (this.modelSingleEmp.empList.length === 0) {
      this._plAlertService.error(this._translateService.instant('proceSalarios.temSeleccionarUmEmpregado'));
      this.dgEmpTabCallback.select(TAB_ID_EMPREGADOS_LIST);
      return Promise.resolve(false);
    }

    this._wizNavControl(false, false);
    this.modelSingleEmp.nEmpresa = this.empresasSel[0].nEmpresa;

    this._toggleLoader(true);
    this._proceSalariosService
      .processarSingle(this.modelSingleEmp)
      .then((response) => {
        this._setWizActiveStep(ERHProceSalariosSteps.PROC_RESULTS, true);
        this._wizNavControl(false, true, true);
        this.resultsProcType = ERHProceSalariosProceType.Single;
        this._procResultsSingleTableSource = response.body.data;
        if (this._dataGridInstanceProcResultsSingle) {
          this._dataGridInstanceProcResultsSingle.refresh();
        }
        if (response.body.errorList.length) {
          const modalRef = this._cgModalService.showVanilla(ProceSalariosProcErrorsModalComponent, {
            backdrop: 'static',
            keyboard: false
          });
          const componentInstance: ProceSalariosProcErrorsModalComponent = modalRef.componentInstance;
          componentInstance.list = response.body.errorList;
        }
      })
      .catch((reason: HttpErrorResponse) => {
        if (reason instanceof HttpErrorResponse) {
          const exception: ICGExceptionError = this._cgExceptionService.get(reason);
          this._plAlertService.error(exception.message);
        } else {
          this._logger.error(reason);
        }
        this._wizNavControl(true, false);
        this.definitionNavWizard.items[STEP_PROC_CONFIG_INDEX].setIncomplete();
        this._setWizActiveStep(ERHProceSalariosSteps.PROC_CONFIG);
      })
      .finally(() => {
        this._toggleLoader(false);
      });
    return Promise.resolve(true);
  }

  private _getWizStepIndex(wizStepId: ERHProceSalariosSteps): number {
    switch (wizStepId) {
      case ERHProceSalariosSteps.INTRO:
        return STEP_INTRO_INDEX;
      case ERHProceSalariosSteps.EMPRESAS_SEL:
        return STEP_EMPRESAS_SEL_INDEX;
      case ERHProceSalariosSteps.PROC_CONFIG:
        return STEP_PROC_CONFIG_INDEX;
      case ERHProceSalariosSteps.PROC:
        return STEP_PROC_INDEX;
      case ERHProceSalariosSteps.PROC_RESULTS:
        return STEP_PROC_RESULTS_INDEX;
      default:
        return STEP_INTRO_INDEX;
    }
  }

  private _setWizActiveStep(wizStepId: ERHProceSalariosSteps, markPrevAsVisited: boolean = false): void {
    const stepIndex = this._getWizStepIndex(wizStepId);
    if (markPrevAsVisited) {
      for (let i = 0; i < stepIndex; i++) {
        this.definitionNavWizard.items[i].visited = true;
        this.definitionNavWizard.items[i].setComplete();
      }
    }
    this.definitionNavWizard.items[stepIndex].visited = true;
    this.definitionNavWizard.items[stepIndex].setIncomplete();
    this.plWizardCallback.setStep(this.definitionNavWizard.items[stepIndex], true);
  }

  private _wizNavControl(nav: boolean, finilize: boolean = true, hidePrev: boolean = false): void {
    this.propertiesNavWizard = {disableNavigation: !nav, disableFinalize: !finilize, hidePrevious: hidePrev};
  }

  private _invalidateSteps(): void {
    for (const step of this.definitionNavWizard.items) {
      step.visited = false;
      step.setIncomplete();
    }
  }

  private _fillAdditionalFields(): void {
    const date = moment(this.modelSingleEmp.dataProcessamento).clone();
    this.modelSingleEmp.anoParaSegSocial = date.year();
    this.modelSingleEmp.mesParaSegSocial = date.month() + 1;
    this.modelSingleEmp.descricao = this._translateService.instant('proceSalarios.singleProcDescDef', {
      date: date.format('DD MMMM [de] YYYY')
    });
    this._getNDias();
  }

  private _refreshProcResultsTable(): void {
    if (this._dataGridInstanceProcResults) {
      this._dataGridInstanceProcResults.refresh();
    }
  }

  private _selectMultiDatasCurrentMonth(): void {
    const item = this._multiDatasTableSource.find((data: IRHProceSalariosMEmpData) => data.mes === this._currentMonth && data.ano === moment().year());
    if (item) {
      item.selected = true;
    }
  }

  private _buildDataSource(year: number): Array<IRHProceSalariosMEmpData> {
    const source: Array<IRHProceSalariosMEmpData> = [];
    for (let i = 1; i <= TOTAL_MONTHS; i++) {
      source.push({
        selected: false,
        mes: i,
        ano: year,
        descricao: moment()
          .year(year)
          .month(i - 1)
          .format('MMMM [de] YYYY')
      });
    }
    return source;
  }

  private _proceExistTableSource(): Promise<Array<IRHProceSalariosProcExistente>> {
    return this._proceSalariosService.getProceExistentes(this.procExistingYear, this.procExistingMonth, this.modelSingleEmp.nEmpresa).then((response: Array<IRHProceSalariosProcExistente>) => {
      this._procExistentesTableSource = response;
      for (const item of this._procExistentesTableSource) {
        item.listEmpregados = [];
        const toolbarInstance = this._plToolbarService.registerInstance(`${item.nProcessamento}-toolbar`);
        toolbarInstance.setItems([
          {
            id: 'itemToolbarReprocSel',
            order: 2,
            type: 'button',
            class: 'btn-primary',
            iconLeft: '<i class="fa fa-fw fa-bolt"></i>&nbsp;',
            caption: 'proceSalarios.reprocSelected',
            click: async () => {
              if (!item.selectedKeysProcExistente?.length) {
                this._plAlertService.error('proceSalarios.messages.naoContemEmpSelecionado');
                return;
              }

              await this._cgModalService.showOkCancel('proceSalarios.modalConfirmTitle', 'proceSalarios.confirmReProcSelMsg');

              const selectedRowsData: Array<IRHProceSalariosProcExistenteEmpregado> = item.listEmpregados.filter((row) => item.selectedKeysProcExistente.includes(row.codEmpregado));

              selectedRowsData.forEach((row) => {
                this._reProcEmpregadoValidate(row);
              });

              const invalidRows = selectedRowsData.filter((data) => data.reProcValidationResult.result === ERHEmpregadoReProcValidationResult.INVALID);
              if (invalidRows.length) {
                const message = this._translateService.instant('proceSalarios.existemEmpregadosQueNaoPodemSerReProc', {
                  content: invalidRows.map((row) => row.reProcValidationResult.errorMessage).join('<br/>')
                });
                await this._cgModalService.showOkCancel('proceSalarios.modalConfirmTitle', message);
              }

              const promises = [];
              for (const row of selectedRowsData) {
                if (row.reProcValidationResult.result !== ERHEmpregadoReProcValidationResult.INVALID) {
                  promises.push(this._proceSalariosService.reProcEmpregado(item.nProcessamento, row.codEmpregado, this.modelSingleEmp.nEmpresa));
                }
              }

              await Promise.allSettled(promises).then((results) => {
                const errors: Array<string> = [];
                results.forEach((result) => {
                  if (result.status === 'rejected') {
                    this._logger.error(result.reason);
                  } else {
                    const promiseResponse = <IRHProceSalariosReProcResult>result.value;
                    for (const error of promiseResponse.errors) {
                      errors.push(`Código Empregado: ${error.codEmpregado} - ${error.descTipoErro} : ${error.descricaoErro}`);
                    }
                  }
                });

                if (errors.length > 0) {
                  this._plAlertService.warning(errors.join('<br/>'));
                } else {
                  this._plAlertService.success(this._translateService.instant('proceSalarios.empregadoSelReprocessado'));
                }
              });
            }
          },
          {
            id: 'itemToolbarAddEmp',
            order: 3,
            type: 'button',
            class: 'btn-primary',
            iconLeft: '<i class="fa fa-fw fa-plus"></i>&nbsp;',
            caption: 'proceSalarios.addEmpregado',
            click: () => {
              const modalRef = this._cgModalService.showVanilla(ProceSalariosEmpregadosModalComponent, {size: 'lg'});
              const componentInstance: ProceSalariosEmpregadosModalComponent = modalRef.componentInstance;
              componentInstance.nEmpresa = this.modelSingleEmp.nEmpresa;
              componentInstance.nProc = item.nProcessamento;
              componentInstance.tipoProcessamento = item.tipoProcessamento;
              componentInstance.dataInicoProc = item.dataInicoProc;
              componentInstance.dataFimProce = item.dataFimProce;
              componentInstance.list = item.listEmpregados;
              return modalRef.result.then((newEmpList: Array<IRHProceSalariosProcExistenteEmpregado>) => {
                item.listEmpregados = [...newEmpList];
                if (item.listEmpregadosTableInstance) {
                  return item.listEmpregadosTableInstance.refresh();
                }
                return Promise.resolve();
              });
            }
          },
          {
            id: 'itemToolbarRemoveEmp',
            order: 4,
            type: 'button',
            class: 'btn-primary',
            iconLeft: '<i class="fa fa-fw fa-times"></i>&nbsp;',
            caption: 'proceSalarios.removeSelEmpregado',
            click: () => {
              return new Promise<void>((resolve, reject) => {
                if (item.selectedKeysProcExistente.length > 0) {
                  this._cgModalService
                    .showOkCancel('proceSalarios.modalConfirmTitle', 'proceSalarios.modalRemoveSelEmpConfirmMsg')
                    .then(() => {
                      this._proceSalariosService
                        .removeEmpregadoProc(item.nProcessamento, item.selectedKeysProcExistente, this.modelSingleEmp.nEmpresa)
                        .then(() => {
                          const listEmpregados: Array<IRHProceSalariosProcExistenteEmpregado> = item.listEmpregados.filter((value: IRHProceSalariosProcExistenteEmpregado) => {
                            return !item.selectedKeysProcExistente.includes(value.codEmpregado);
                          });
                          item.listEmpregados = [...listEmpregados];
                          if (item.listEmpregadosTableInstance) {
                            return item.listEmpregadosTableInstance.refresh();
                          }
                          this._plAlertService.success('proceSalarios.messages.empEliminadoSucesso');
                          resolve();
                          return Promise.resolve();
                        })
                        .catch(reject);
                    })
                    .catch(reject);
                } else {
                  this._plAlertService.error('proceSalarios.messages.naoContemEmpSelecionado');
                  resolve();
                }
              });
            }
          },
          {
            id: 'itemToolbarConsultarRecibos',
            order: 5,
            type: 'button',
            class: 'btn-primary',
            iconLeft: '<i class="fa fa-fw fa-id-card-o"></i>&nbsp;',
            caption: 'proceSalarios.consultarRecibo',
            click: () => {
              return this._maintenanceRecibos.maintenance({
                params: {
                  nProcessamento: item.nProcessamento
                },
                modalOptions: {
                  size: 'xl'
                }
              });
            }
          }
        ]);
      }
      return this._procExistentesTableSource;
    });
  }

  private _deleteProcess(item: IRHProceSalariosProcExistente): Promise<void> {
    return this._cgModalService
      .showOkCancel('proceSalarios.promptDeleteProcTitle', 'proceSalarios.promptDeleteProcMessage', {
        size: 'md',
        btnCancelText: 'global.btn.no',
        btnOkText: 'global.btn.yes',
        backdrop: 'static',
        showCloseBtn: false,
        keyboard: false
      })
      .then(() => {
        return this._proceSalariosService.deleteProcess(item.nProcessamento, this.modelSingleEmp.nEmpresa).then(() => {
          return this._dataGridInstanceProceExist.refresh();
        });
      });
  }

  private _beforeStepChange({nextStep, currentStep, type}: IPlNavWizardEventBeforeChange): boolean {
    if (!this.isMultiProc()) {
      if (
        currentStep === this.definitionNavWizard.items[STEP_PROC_CONFIG_INDEX] &&
        (type === 'next' || (nextStep && nextStep === this.definitionNavWizard.items[STEP_PROC_INDEX])) &&
        this.activeTab !== this.tabIdEmpregadosList
      ) {
        if (this.newProcForm.invalid) {
          this._plAlertService.error(this._translateService.instant('proceSalarios.existemCamposComErros'));
          this.dgEmpTabCallback.select(TAB_ID_EMPREGADOS_NEW_PROC);
          return false;
        }
        this.dgEmpTabCallback.select(TAB_ID_EMPREGADOS_LIST);
        return false;
      } else if (
        currentStep === this.definitionNavWizard.items[STEP_PROC_CONFIG_INDEX] &&
        (nextStep === this.definitionNavWizard.items[STEP_EMPRESAS_SEL_INDEX] || nextStep === this.definitionNavWizard.items[STEP_INTRO_INDEX]) &&
        this.activeTab === this.tabIdEmpregadosProcExistentes
      ) {
        this.propertiesNavWizard = {...this.propertiesNavWizard, hideFooter: false};
      } else if (nextStep === this.definitionNavWizard.items[STEP_PROC_CONFIG_INDEX] && this.activeTab === this.tabIdEmpregadosProcExistentes) {
        this.propertiesNavWizard = {...this.propertiesNavWizard, hideFooter: true};
      }
    }
    return true;
  }

  private _getNDias(): void {
    if (this.modelSingleEmp.calendario) {
      this._calendariosService.gerarCalendarioParaAno(this.modelSingleEmp.calendario, moment(this.modelSingleEmp.dataProcessamento).year()).then(() => {
        this._calendariosService
          .getNDiasCalendarioPorTipo(this.modelSingleEmp.calendario, this.modelSingleEmp.dataInicio, this.modelSingleEmp.dataFim, ECalendarioTipoDia.DiaUtil, true)
          .then((response: HttpResponse<IJsonCalendarioNDiasUteis>) => {
            this.modelSingleEmp.nDiasUteis = response.body.nDiasUteis;
          });
      });
    }
  }

  private _mustReloadDGEMPTable(): boolean {
    const compareData: IRHCacheHelper = {
      lastSelectedSingleNEmpresa: this.modelSingleEmp.nEmpresa,
      lastTipoProcessamento: this.modelSingleEmp.tipoProcessamento,
      lastDataInicio: this.modelSingleEmp.dataInicio,
      lastDataFim: this.modelSingleEmp.dataFim
    };
    return this._dgEmpTableSource.length === 0 || JSON.stringify(compareData) !== JSON.stringify(this._cacheHelper);
  }

  private _reProcEmpregadoValidate(data: IRHProceSalariosProcExistenteEmpregado): ERHEmpregadoReProcValidationResult {
    if (data.integradoContab) {
      data.reProcValidationResult = {
        result: ERHEmpregadoReProcValidationResult.INVALID,
        errorMessage: this._translateService.instant('proceSalarios.naoPodeReprocIntegContab', {codEmp: data.codEmpregado, nome: data.nomeEmpregado})
      };
      return ERHEmpregadoReProcValidationResult.INVALID;
    }

    if (!data.existeTransfBancaria && !data.fichCriadoSegSocial) {
      data.reProcValidationResult = {result: ERHEmpregadoReProcValidationResult.VALID, errorMessage: ''};
      return ERHEmpregadoReProcValidationResult.VALID;
    }
    if (data.existeTransfBancaria) {
      if (!data.rhPodeProcessarSalarioscomTransfBancariaGerado) {
        data.reProcValidationResult = {
          result: ERHEmpregadoReProcValidationResult.INVALID,
          errorMessage: this._translateService.instant('proceSalarios.naoPodeReprocExisteTransfBancaria', {codEmp: data.codEmpregado, nome: data.nomeEmpregado})
        };
        return ERHEmpregadoReProcValidationResult.INVALID;
      }
    }

    if (data.fichCriadoSegSocial) {
      if (!data.rhPodeProcessarSalarioscomSegSocialGerado) {
        data.reProcValidationResult = {
          result: ERHEmpregadoReProcValidationResult.INVALID,
          errorMessage: this._translateService.instant('proceSalarios.naoPodeReprocExisteFichSegSocial', {codEmp: data.codEmpregado, nome: data.nomeEmpregado})
        };
        return ERHEmpregadoReProcValidationResult.INVALID;
      }
    }

    data.reProcValidationResult = {result: ERHEmpregadoReProcValidationResult.VALID_PROMPT, errorMessage: ''};
    return ERHEmpregadoReProcValidationResult.VALID_PROMPT;
  }
}
