<div class="demfinsnc-obs-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'demfinsnc.modal.titleobs'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <edit>
        <pl-edit type="text" attrName="obs" [(model)]="obs" plAutoFocus [properties]="{events: {keydown: keyDownObs}}"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer">
    <pl-button klass="btn-primary action-close" [onClick]="close" attrName="ok"> <i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.ok'"></span> </pl-button>
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
