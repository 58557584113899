import {Component, Injector, Input, OnInit} from '@angular/core';
import {
  EDebDiretosSEPAConfigTabs,
  EDebDiretosSEPADataLiquidacao,
  EDebDiretosSEPAEstadoItem,
  EDebDiretosSEPATipoChave,
  IDebDiretosSEPAAcessos,
  IJsonDebDiretosSEPAConfiguracao,
  IJsonDebDiretosSEPAMeiosPagamento,
  IJsonDebDiretosSEPATipoDocumento
} from '../debDiretosSEPA.module.interface';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {DebDiretosSEPAModuleService} from '../debDiretosSEPA.module.service';
import {IDevExpressDataGrid, IDevExpressDataGridColumnButtonOnClickEvent} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import {IDevExpressDataGridEventOnInitialized, IDevExpressDataGridEventOnRowRemoving} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import dxDataGrid from 'devextreme/ui/data_grid';
import {ISelectKeyPair} from '../../../entities/docfas/docFas.entity.interface';
import {DebDiretosSEPAContaCredoraModalComponent} from './debDiretosSEPA.contaCredora.modal.component';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {isUndefinedOrNull, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {HttpResponse} from '@angular/common/http';
import {Properties} from 'devextreme/ui/button';

@Component({
  selector: 'ddsepa-configuracao-modal',
  templateUrl: './debDiretosSEPA.configuracao.modal.component.html'
})
export class DebDiretosSEPAConfiguracaoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public userAcessos: IDebDiretosSEPAAcessos;

  public readonly tipoSource: Array<ISelectKeyPair>;
  public readonly templateMultiSelectTiposDocumento: string = '{{nDocFa}} - {{nome}}';

  public debDiretosSEPAConfigTabs: typeof EDebDiretosSEPAConfigTabs;
  public debDiretosSEPADataLiquidacao: typeof EDebDiretosSEPADataLiquidacao;
  public debDiretosSEPATipoChave: typeof EDebDiretosSEPATipoChave;
  public debDiretosSEPAEstadoItem: typeof EDebDiretosSEPAEstadoItem;
  public model: IJsonDebDiretosSEPAConfiguracao;
  public dataGridContasCredorasDefinition: IDevExpressDataGrid<IJsonDebDiretosSEPAMeiosPagamento, string>;
  public listaTiposDocumentoDisponiveis: Array<IJsonDebDiretosSEPATipoDocumento>;
  public exemploChave: string;

  private _dataGridContasCredorasInstance: dxDataGrid<IJsonDebDiretosSEPAMeiosPagamento, string>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService,
    protected readonly _debDiretosSEPAModuleService: DebDiretosSEPAModuleService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.debDiretosSEPAConfigTabs = EDebDiretosSEPAConfigTabs;
    this.debDiretosSEPADataLiquidacao = EDebDiretosSEPADataLiquidacao;
    this.debDiretosSEPATipoChave = EDebDiretosSEPATipoChave;
    this.debDiretosSEPAEstadoItem = EDebDiretosSEPAEstadoItem;
    this.listaTiposDocumentoDisponiveis = [];

    this.model = {
      dataLiquidacaoNDiasFRST: 0,
      dataLiquidacaoNDiasRCUR: 0,
      listaContasCredoras: [],
      listaTiposDocumento: [],
      listaTiposDocumentoNaoSelecionados: [],
      nContaIntermedia: '',
      nContaIntermediaNome: '',
      tipoDataLiquidacao: EDebDiretosSEPADataLiquidacao.NumeroDias
    };
    this.setDataLiquidacao(EDebDiretosSEPADataLiquidacao.DataVencimento);
    this.dataGridContasCredorasDefinition = {
      columns: [
        {dataField: 'estado', dataType: 'number', caption: 'debDiretosSEPA.fields.estado', cellTemplate: 'cellTemplateEstado', alignment: 'center', visible: false},
        {dataField: 'nomeMeioPagamento', dataType: 'string', caption: 'debDiretosSEPA.fields.meioPagamento'},
        {dataField: 'iBAN', dataType: 'string', caption: 'debDiretosSEPA.fields.iban'},
        {dataField: 'idDebitoDireto', dataType: 'string', caption: 'debDiretosSEPA.fields.idDebitoDireto'},
        {dataField: 'nContaBanco', dataType: 'string', caption: 'debDiretosSEPA.fields.nConta'},
        {dataField: 'nomePOC', dataType: 'string', caption: 'debDiretosSEPA.fields.contaBanco'},
        {dataField: 'nDiario', dataType: 'number', caption: 'debDiretosSEPA.fields.nDiario', visible: false},
        {dataField: 'nomeDiario', dataType: 'string', caption: 'debDiretosSEPA.fields.diario', visible: false},
        {dataField: 'nDescritivo', dataType: 'number', caption: 'debDiretosSEPA.fields.nDescritivo', visible: false},
        {dataField: 'nomeDescritivo', dataType: 'string', caption: 'debDiretosSEPA.fields.descritivo', visible: false},
        {
          dataField: 'vf_actions',
          fixed: true,
          fixedPosition: 'right',
          caption: ' ',
          cellTemplate: 'cellTemplateActions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false,
          alignment: 'center',
          showInColumnChooser: false
        }
      ],
      dataSource: new CustomStore({
        key: 'codMeioPagamento',
        load: () => this._contasCredorasTableSource()
      }),
      toolbar: {
        items: [
          {
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'add',
              hint: this._plTranslateService.translate('global.btn.addLine'),
              onClick: () => this._addNewContaCredora()
            } satisfies Properties
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };

    this.tipoSource = [
      {value: EDebDiretosSEPATipoChave.Texto, name: this._getTipoString(EDebDiretosSEPATipoChave.Texto)},
      {value: EDebDiretosSEPATipoChave.Conta, name: this._getTipoString(EDebDiretosSEPATipoChave.Conta)},
      {value: EDebDiretosSEPATipoChave.ClasseContrato, name: this._getTipoString(EDebDiretosSEPATipoChave.ClasseContrato)},
      {value: EDebDiretosSEPATipoChave.TipoContrato, name: this._getTipoString(EDebDiretosSEPATipoChave.TipoContrato)},
      {value: EDebDiretosSEPATipoChave.NContrato, name: this._getTipoString(EDebDiretosSEPATipoChave.NContrato)},
      {value: EDebDiretosSEPATipoChave.IBANCredor, name: this._getTipoString(EDebDiretosSEPATipoChave.IBANCredor)},
      {value: EDebDiretosSEPATipoChave.IBANCliente, name: this._getTipoString(EDebDiretosSEPATipoChave.IBANCliente)},
      {value: EDebDiretosSEPATipoChave.Ano, name: this._getTipoString(EDebDiretosSEPATipoChave.Ano)},
      {value: EDebDiretosSEPATipoChave.Mes, name: this._getTipoString(EDebDiretosSEPATipoChave.Mes)},
      {value: EDebDiretosSEPATipoChave.Dia, name: this._getTipoString(EDebDiretosSEPATipoChave.Dia)},
      {value: EDebDiretosSEPATipoChave.Hora, name: this._getTipoString(EDebDiretosSEPATipoChave.Hora)},
      {value: EDebDiretosSEPATipoChave.Minutos, name: this._getTipoString(EDebDiretosSEPATipoChave.Minutos)},
      {value: EDebDiretosSEPATipoChave.Sequencial, name: this._getTipoString(EDebDiretosSEPATipoChave.Sequencial)}
    ];
  }

  public ngOnInit(): void {
    this._debDiretosSEPAModuleService.getConfiguracaoGeral().then((response: HttpResponse<IJsonDebDiretosSEPAConfiguracao>) => {
      this.model = response.body;
      this.listaTiposDocumentoDisponiveis = [...this.model.listaTiposDocumento, ...this.model.listaTiposDocumentoNaoSelecionados];
    });
  }

  public getConfigGeraisDataLiquidacaoMessage(): string {
    return this.model.tipoDataLiquidacao === EDebDiretosSEPADataLiquidacao.DataVencimento
      ? this._plTranslateService.translate('debDiretosSEPA.strings.dataParaLiquidacaoSeraADataVencDocumento')
      : this._plTranslateService.translate('debDiretosSEPA.strings.dataParaLiquidacaoSeraCalcPelosDiasConfig');
  }

  public getTabCaption(tab: EDebDiretosSEPAConfigTabs): string {
    let caption = '';
    switch (tab) {
      case EDebDiretosSEPAConfigTabs.ConfigGerais: {
        caption = this._plTranslateService.translate('debDiretosSEPA.strings.tabConfigGerais');
        if (!this.userAcessos.geral) {
          caption = `<i class="fa fa-fw fa-lock me-1" aria-hidden="true"></i>${caption}`;
        }
        break;
      }
      case EDebDiretosSEPAConfigTabs.ContasCredoras: {
        caption = this._plTranslateService.translate('debDiretosSEPA.strings.tabContasCredoras');
        if (!this.userAcessos.bancos) {
          caption = `<i class="fa fa-fw fa-lock me-1" aria-hidden="true"></i>${caption}`;
        }
        break;
      }
      case EDebDiretosSEPAConfigTabs.TiposDocumento: {
        caption = this._plTranslateService.translate('debDiretosSEPA.strings.tabTiposDocumento');
        if (!this.userAcessos.tiposDocumento) {
          caption = `<i class="fa fa-fw fa-lock me-1" aria-hidden="true"></i>${caption}`;
        }
        break;
      }
      case EDebDiretosSEPAConfigTabs.Clientes: {
        caption = this._plTranslateService.translate('debDiretosSEPA.strings.tabClientes');
        if (!this.userAcessos.clientes) {
          caption = `<i class="fa fa-fw fa-lock me-1" aria-hidden="true"></i>${caption}`;
        }
        break;
      }
    }

    return caption;
  }

  public setDataLiquidacao(value: EDebDiretosSEPADataLiquidacao): void {
    this.model.tipoDataLiquidacao = value;
  }

  public onContasCredorasInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonDebDiretosSEPAMeiosPagamento, string>): void {
    this._dataGridContasCredorasInstance = component;
  }

  public async onRowRemoving(event: IDevExpressDataGridEventOnRowRemoving<IJsonDebDiretosSEPAMeiosPagamento, string>): Promise<void> {
    event.cancel = true;
    await this._deleteContaCredora(event.data.idDebitoDireto);
  }

  public async editContaCredora(data: IJsonDebDiretosSEPAMeiosPagamento): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DebDiretosSEPAContaCredoraModalComponent, {size: 'md', backdrop: 'static'});
    const componentInstance: DebDiretosSEPAContaCredoraModalComponent = modalInstance.componentInstance;
    componentInstance.model = data;
    componentInstance.userAcessos = this.userAcessos;
    const result: IJsonDebDiretosSEPAMeiosPagamento = await modalInstance.result;
    const index: number = this.model.listaContasCredoras.findIndex((item: IJsonDebDiretosSEPAMeiosPagamento) => item.codMeioPagamento === result.codMeioPagamento);
    if (index > -1) {
      this.model.listaContasCredoras[index] = result;
    }
    await this._dataGridContasCredorasInstance.refresh();
  }

  public deleteContaCredora(event: IDevExpressDataGridColumnButtonOnClickEvent<IJsonDebDiretosSEPAMeiosPagamento, string>): void {
    this._dataGridContasCredorasInstance.deleteRow(event.row.rowIndex); // calls function onRowRemoving
  }

  public readonly fnFinalize = (): Promise<void> => this._finalize();

  private _contasCredorasTableSource(): Array<IJsonDebDiretosSEPAMeiosPagamento> {
    return this.model.listaContasCredoras;
  }

  // eslint-disable-next-line consistent-return
  private _getTipoString(tipo: EDebDiretosSEPATipoChave): string {
    switch (tipo) {
      case EDebDiretosSEPATipoChave.Texto:
        return this._plTranslateService.translate('debDiretosSEPA.tipoChaveAutorizacao.texto');
      case EDebDiretosSEPATipoChave.Conta:
        return this._plTranslateService.translate('debDiretosSEPA.tipoChaveAutorizacao.conta');
      case EDebDiretosSEPATipoChave.ClasseContrato:
        return this._plTranslateService.translate('debDiretosSEPA.tipoChaveAutorizacao.classeContrato');
      case EDebDiretosSEPATipoChave.TipoContrato:
        return this._plTranslateService.translate('debDiretosSEPA.tipoChaveAutorizacao.tipoContrato');
      case EDebDiretosSEPATipoChave.NContrato:
        return this._plTranslateService.translate('debDiretosSEPA.tipoChaveAutorizacao.nContrato');
      case EDebDiretosSEPATipoChave.IBANCredor:
        return this._plTranslateService.translate('debDiretosSEPA.tipoChaveAutorizacao.ibanCredor');
      case EDebDiretosSEPATipoChave.IBANCliente:
        return this._plTranslateService.translate('debDiretosSEPA.tipoChaveAutorizacao.ibanCliente');
      case EDebDiretosSEPATipoChave.Ano:
        return this._plTranslateService.translate('debDiretosSEPA.tipoChaveAutorizacao.ano');
      case EDebDiretosSEPATipoChave.Mes:
        return this._plTranslateService.translate('debDiretosSEPA.tipoChaveAutorizacao.mes');
      case EDebDiretosSEPATipoChave.Dia:
        return this._plTranslateService.translate('debDiretosSEPA.tipoChaveAutorizacao.dia');
      case EDebDiretosSEPATipoChave.Hora:
        return this._plTranslateService.translate('debDiretosSEPA.tipoChaveAutorizacao.hora');
      case EDebDiretosSEPATipoChave.Minutos:
        return this._plTranslateService.translate('debDiretosSEPA.tipoChaveAutorizacao.minutos');
      case EDebDiretosSEPATipoChave.Sequencial:
        return this._plTranslateService.translate('debDiretosSEPA.tipoChaveAutorizacao.sequencial');
    }
  }

  private async _addNewContaCredora(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DebDiretosSEPAContaCredoraModalComponent, {size: 'md', backdrop: 'static'});
    const componentInstance: DebDiretosSEPAContaCredoraModalComponent = modalInstance.componentInstance;
    componentInstance.userAcessos = this.userAcessos;
    const result: IJsonDebDiretosSEPAMeiosPagamento = await modalInstance.result;
    this.model.listaContasCredoras.push(result);
    await this._dataGridContasCredorasInstance.refresh();
  }

  private async _finalize(): Promise<void> {
    this.model.listaTiposDocumentoNaoSelecionados = this.listaTiposDocumentoDisponiveis.filter((item: IJsonDebDiretosSEPATipoDocumento) => {
      return isUndefinedOrNull(this.model.listaTiposDocumento.find((tipoSelecionado: IJsonDebDiretosSEPATipoDocumento) => tipoSelecionado.nDocFa === item.nDocFa));
    });
    await this._debDiretosSEPAModuleService.saveConfiguracaoGeral(this.model);
    this._plAlertService.success('debDiretosSEPA.strings.configSaved');
    this.close();
  }

  private async _deleteContaCredora(idDebitoDireto: string): Promise<void> {
    this.model.listaContasCredoras = this.model.listaContasCredoras.filter((item: IJsonDebDiretosSEPAMeiosPagamento) => item.idDebitoDireto !== idDebitoDireto);
    await this._dataGridContasCredorasInstance.refresh();
  }
}
