import {lastValueFrom} from 'rxjs';
import {Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Logger} from 'pl-comps-angular';
import {apiActivateRuntimeProperties} from '../common/api';
import {APP_RUNTIME_PROPERTIES} from '../common/app';
import {DOCKER} from '../../environments/constants';
import {IAppRuntimeProperties} from '../common/interfaces/interfaces';

export async function configRuntime(injector: Injector): Promise<void> {
  if (!DOCKER || !window || !window.location) {
    return;
  }

  const httpClient: HttpClient = injector.get(HttpClient);
  const url = `${window.location.origin}/config/properties.json`;
  try {
    const properties: IAppRuntimeProperties = await lastValueFrom(httpClient.request<IAppRuntimeProperties>('GET', url, {responseType: 'json'}));

    APP_RUNTIME_PROPERTIES.next(Object.freeze({...APP_RUNTIME_PROPERTIES.value, ...properties}));

    apiActivateRuntimeProperties(properties);
  } catch (reason: unknown) {
    const logger = injector.get(Logger, undefined, {optional: true});
    if (logger) {
      logger.error(reason);
    }
  }
}
