<div class="modal-header">
  <button type="button" class="btn-close" (click)="close()"></button>
  <h4 class="modal-title" [translate]="'colaboradores.docsplata.modal.title'"></h4>
</div>

<div class="modal-body">
  <pl-nav-wizard [onFinalize]="fnFinalize" [destroyOnHide]="false">
    <pl-nav-wizard-step caption="prhdocsplata.steps.documento" icon="fa-calculator">
      <div *plNavWizardStepContent>
        <pl-form [properties]="{validators: {required: {value: true}}}">
          <pl-group>
            <label [translate]="'colaboradores.docsplata.modal.titulo'"></label>
            <edit>
              <pl-edit type="text" attrName="titulo" [(model)]="docsPlata.titulo"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'colaboradores.docsplata.modal.dataValidade'"></label>
            <edit>
              <pl-edit type="date" attrName="dataValidade" [(model)]="docsPlata.dataValidade"></pl-edit>
            </edit>
          </pl-group>

          <pl-upload [hideGlobalActions]="true" [hideActions]="true" [autoProcessQueue]="false" [maxFiles]="1" [callback]="callback" acceptedFiles="image/*,.pdf,.docx,.doc,.csv,.xlsx,.xls">
          </pl-upload>
        </pl-form>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step caption="prhdocsplata.steps.servicos" icon="fa-calculator">
      <div *plNavWizardStepContent>
        <pl-form>
          <pl-group>
            <label [translate]="'colaboradores.docsplata.text.servicos'"></label>
            <edit>
              <pl-multiselect class="servicosModal" key="codServico" [model]="docsPlata.servicosSelected" [source]="servicos" [template]="templateServicosSelected"> </pl-multiselect>
            </edit>
          </pl-group>
        </pl-form>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step caption="prhdocsplata.steps.papeis" icon="fa-calculator">
      <div *plNavWizardStepContent>
        <pl-form>
          <pl-group>
            <label [translate]="'colaboradores.docsplata.text.papeis'"></label>
            <edit>
              <pl-multiselect class="papeisModal" key="codPapel" [model]="docsPlata.papeisSelected" [source]="papeis" [template]="templatePapeisSelected"> </pl-multiselect>
            </edit>
          </pl-group>
        </pl-form>
      </div>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
