import {IPlEditComponentOptionsInputGroup, IPlEditComponentOptionsInputRadio, THyperlinkTarget as TPlHyperlinkTarget} from 'pl-comps-angular';

export enum EAbstractControlStatus {
  Valid = 'VALID',
  Invalid = 'INVALID',
  Pending = 'PENDING',
  Disabled = 'DISABLED'
}

export type THyperlinkTarget = TPlHyperlinkTarget | 'self' | 'blank' | 'parent' | 'top';

// eslint-disable-next-line @typescript-eslint/naming-convention
export type NgClassSupportedTypes = string | Array<string> | Set<string> | {[klass: string]: unknown};

// eslint-disable-next-line @typescript-eslint/naming-convention
export type Writeable<T> = {-readonly [P in keyof T]: T[P]};

// eslint-disable-next-line @typescript-eslint/naming-convention
export type DeepWriteable<T> = {-readonly [P in keyof T]: DeepWriteable<T[P]>};

// eslint-disable-next-line @typescript-eslint/naming-convention
export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export interface IAppRuntimeProperties {
  readonly origin?: string;
  readonly featureFlagsAddress?: string;
}

export interface IStack<T> {
  push(instance: T): number;

  pop(): T;

  peek(): T;
}

export interface IDictionary<T> {
  [key: string]: T;
}

export interface ISemanticVersion {
  major: number;
  minor: number;
  patch: number;
}

export interface ISemanticVersionComparison {
  equal(): boolean;

  bigger(): boolean;

  biggerOrEqual(): boolean;

  less(): boolean;

  lessOrEqual(): boolean;
}

export interface ICGEnumeratorMap<T, S> {
  values: Map<T, S>;
  keys: Array<T>;
}

export interface ICGEnumeratorMapWithFilter<T, S> extends ICGEnumeratorMap<T, S> {
  filteredKeys: Array<T>;
}

export interface IRadioGroup<T> extends IPlEditComponentOptionsInputGroup<T, IPlEditComponentOptionsInputRadio<T>> {}
