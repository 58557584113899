<div class="ativosabate-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'ativos.modal.registodevendaativo'"></h5>
  </div>

  <div class="modal-body">
    <pl-nav-wizard [definition]="definition" [onFinalize]="close" [beforeStepChange]="fnBeforeStepChange" (modelChange)="selectedStep = $event">
      <pl-nav-wizard-step caption="ativosvenda.steps.tabeladocumentos" icon="fa-search">
        <dx-data-grid
          *plNavWizardStepContent
          [cgDxDataGrid]="dataGridDefinition"
          cgDxDataGridInstanceName="ativosAbateModalTabelaDocumentos"
          [dataSource]="dataGridDefinition.dataSource"
          (onInitialized)="onInitialized($event)"
          (onContentReady)="onContentReady()"
          (onCellClick)="onCellClick($event)"
          (onRowExpanding)="onRowExpanding($event)">
          <div *dxTemplate="let item of 'detailTemplateDocContabilidade'">
            <documento-contabilidade [(doc)]="item.data._docContabilidade" [maintenanceMode]="true"> </documento-contabilidade>
          </div>
        </dx-data-grid>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step caption="ativosvenda.steps.detalhesparavenda" icon="fa-truck">
        <div *plNavWizardStepContent>
          <pl-group>
            <label [translate]="'ativosvenda.datavenda'"></label>
            <edit>
              <pl-edit type="date" attrName="dataAbate" [(model)]="ativosvenda.dataVenda"> </pl-edit>
            </edit>
          </pl-group>

          <div class="card">
            <div class="card-header" [translate]="'ativosvenda.card.valores'"></div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-4 text-end fw-bolder">
                  <span [translate]="'ativosvenda.card.valorsemiva'"></span>
                </div>
                <div class="col-sm-8 text-default">
                  {{ ativosvenda.valorSemIva | cgcNumber }}
                </div>

                <div class="col-sm-4 text-end fw-bolder">
                  <span [translate]="'ativosvenda.card.valordoiva'"></span>
                </div>
                <div class="col-sm-2 text-default">
                  {{ ativosvenda.valorDoIva | cgcNumber }}
                </div>

                <div class="col-sm-2 text-end fw-bolder">
                  <span [translate]="'ativosvenda.card.total'"></span>
                </div>
                <div class="col-sm-4 text-default">
                  {{ valorTotal | cgcNumber }}
                </div>

                <div class="col-sm-4 text-end fw-bolder">
                  <span [translate]="'ativosvenda.card.coef'"></span>
                </div>
                <div class="col-sm-8 text-default">
                  {{ ativosvenda.coefCorrMonet | cgcNumber }}
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header" [translate]="'ativosvenda.card.contas'"></div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-4 text-end fw-bolder">
                  <span [translate]="'ativosvenda.card.docliente'"></span>
                </div>
                <div class="col-sm-8 text-default">
                  <span>{{ contaDoCliente }}</span>
                </div>

                <div class="col-sm-4 text-end fw-bolder">
                  <span [translate]="'ativosvenda.card.maismenosvalia'"></span>
                </div>
                <div class="col-sm-8 text-default">
                  <span *ngFor="let item of listContaMaisMenosVal">{{ item.nConta }} - {{ item.nomeConta }}</span>
                </div>

                <div class="col-sm-4 text-end fw-bolder">
                  <span [translate]="'ativosvenda.card.iva'"></span>
                </div>
                <div class="col-sm-8 text-default">
                  <span *ngFor="let item of listContaDoIva">{{ item.nConta }} - {{ item.nomeConta }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-nav-wizard-step>
    </pl-nav-wizard>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
