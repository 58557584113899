<div class="modal-header">
  <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'gestaodgemps.abonos.marcarmodal.title'"></h4>
</div>

<div class="modal-body">
  <p>{{ textMarcarDate }}</p>

  <pl-form [properties]="{validators: {required: {value: true}}}" [plPromise]="promise">
    <pl-group>
      <label><span [translate]="'gestaodgemps.fields.tipo'"></span>:</label>
      <edit>
        <select class="form-select" [ngModel]="tipoAbono" (ngModelChange)="onTipoAbonoChange($event)">
          <option *ngFor="let tipoAbono of marcarData.configEvents" [ngValue]="tipoAbono">{{ tipoAbono.descricao }}</option>
        </select>
      </edit>
    </pl-group>

    <pl-group>
      <label><span [translate]="'gestaodgemps.fields.quantidade'"></span>:</label>
      <edit>
        <pl-edit type="number" [model]="quantidade" (modelChange)="onQuantidadeChange($event)" attrName="quantidade"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label><span [translate]="'gestaodgemps.fields.valorUnitario'"></span>:</label>
      <edit>
        <pl-edit type="number" [(model)]="valorUnitario" attrName="valorunitario" [properties]="{disabled: true}"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label><span [translate]="'gestaodgemps.fields.valor'"></span>:</label>
      <edit>
        <pl-edit type="number" [(model)]="valor" (modelChange)="onValorChange($event)" attrName="valor"></pl-edit>
      </edit>
    </pl-group>
  </pl-form>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary action-submit" [disabled]="closeDisabled" [onClick]="close"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.mark'"></span> </pl-button>

  <pl-button klass="btn-light action-cancel" [disabled]="closeDisabled" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
</div>
