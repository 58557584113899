import {Provider} from '@angular/core';
import {HTTP_INTERCEPTORS as DI_HTTP_INTERCEPTORS, HttpInterceptorFn} from '@angular/common/http';
import {ErrorHandlerInterceptor, interceptorErrorHandler} from '../interceptor/errorhandler.interceptor';
import {interceptorNotification, NotificationInterceptor} from '../interceptor/notification.interceptor';
import {interceptorRequest, RequestInterceptor} from '../interceptor/request.interceptor';
import {interceptorResponse, ResponseInterceptor} from '../interceptor/response.interceptor';
import {interceptorTestsHelper, TestsHelperInterceptor} from '../interceptor/tests.helper.interceptor';

export const HTTP_INTERCEPTORS: Array<HttpInterceptorFn> = [
  interceptorRequest,
  /*interceptorCacheBuster,*/
  interceptorTestsHelper,
  interceptorErrorHandler,
  interceptorResponse,
  interceptorNotification
];

export const HTTP_INTERCEPTOR_PROVIDERS: Array<Provider> = [
  {provide: DI_HTTP_INTERCEPTORS, multi: true, useClass: RequestInterceptor},
  // {provide: HTTP_INTERCEPTORS, multi: true, useClass: CacheBusterInterceptor},
  {provide: DI_HTTP_INTERCEPTORS, multi: true, useClass: TestsHelperInterceptor},
  {provide: DI_HTTP_INTERCEPTORS, multi: true, useClass: ErrorHandlerInterceptor},
  {provide: DI_HTTP_INTERCEPTORS, multi: true, useClass: ResponseInterceptor},
  {provide: DI_HTTP_INTERCEPTORS, multi: true, useClass: NotificationInterceptor}
];
