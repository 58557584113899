import {race} from 'rxjs';
import {Injectable, Type} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {isBoolean, isDefined, isEmpty, isFunction, isObject, isString} from 'pl-comps-angular';
import {CGHelpTopicsModalComponent} from './helptopics/helptopics.modal.component';
import {CGModalChooseCountryComponent} from './choosecountry/choosecountry.modal.component';
import {CGModalComponent} from './cgmodal.component';
import {CGModalOkCancelComponent} from './okcancel/okcancel.modal.component';
import {CGPdfModalComponent} from './pdf/cg.pdf.modal.component';
import {ICGHelpTopic} from './helptopics/helptopics.modal.component.interface';
import {ICGModalOptions, ICGOkCancelModalOptions, TCGModalResult} from './cgmodal.interface';
import {IPaisesISO3166} from '../../../datasources/paisesiso3166/paisesISO3166.datasource.interface';
import {PortalChangeFirmModalComponent} from '../../portal/modals/changefirm/portal.changefirm.modal.component';
import {TUserSession} from '../../../services/account/jsonUserApi.interface';

export const CG_MODAL_CLASS = 'cg-modal';

@Injectable({
  providedIn: 'root'
})
export class CGModalService {
  private readonly _defaultOptions: Partial<ICGModalOptions<unknown>>;

  constructor(private readonly _ngbModal: NgbModal) {
    this._defaultOptions = {
      size: 'xl'
    };
  }

  public showVanilla<T = unknown>(content: Type<CGModalComponent<T>>, options?: ICGModalOptions<T>): TCGModalResult<T> {
    const properties: ICGModalOptions<T> = {...(<ICGModalOptions<T>>this._defaultOptions), ...options};

    const windowClass = isString(properties.windowClass) ? properties.windowClass.split(' ') : [];
    windowClass.push('disable-animations');
    windowClass.push(CG_MODAL_CLASS);
    if (properties.size) {
      windowClass.push(`${CG_MODAL_CLASS}-${properties.size}`);
    }
    properties.windowClass = windowClass.join(' ');

    const modalRef = this._ngbModal.open(
      content,
      (function () {
        properties.beforeDismiss = async () => {
          const instance: CGModalComponent<unknown> = modalRef.componentInstance;
          let result: boolean = await instance.beforeDismiss();
          if (result === false) {
            return false;
          }
          if (isDefined(options) && isFunction(options.beforeDismiss)) {
            result = await options.beforeDismiss();
          }
          return result === false ? false : !instance.closeDisabled;
        };
        return properties;
      })()
    );
    const componentInstance: CGModalComponent<T> = modalRef.componentInstance;
    componentInstance.modalOptions = properties;
    modalRef.result
      .then((result: T) => {
        if (properties?.evtClosed) {
          properties.evtClosed.emit(result);
        }
      })
      .catch((reason: unknown) => {
        if (properties?.evtDismissed) {
          properties.evtDismissed.emit(reason);
        }
      });
    if (properties.ownerViewRef) {
      let closedModal = false;
      race(modalRef.closed, modalRef.dismissed).subscribe(() => {
        closedModal = true;
      });
      properties.ownerViewRef.onDestroy(() => {
        if (!closedModal) {
          modalRef.dismiss('Owner view reference was destroyed before dismissing modal.');
        }
      });
    }
    return modalRef;
  }

  public show<T = unknown>(content: Type<CGModalComponent<T>>, options?: ICGModalOptions<T>): Promise<T> {
    return this.showVanilla(content, options).result;
  }

  public dismissAll(reason?: unknown): void {
    this._ngbModal.dismissAll(reason);
  }

  public hasOpenModals(): boolean {
    return this._ngbModal.hasOpenModals();
  }

  public showChooseCountryVanilla(options?: ICGModalOptions<IPaisesISO3166>): NgbModalRef {
    return this.showVanilla(CGModalChooseCountryComponent, options);
  }

  public showChooseCountry(options?: ICGModalOptions<IPaisesISO3166>): Promise<IPaisesISO3166> {
    return this.showChooseCountryVanilla(options).result;
  }

  public showOkCancelVanilla(title: string, message: string, options?: ICGOkCancelModalOptions): NgbModalRef {
    const modalRef = this.showVanilla(CGModalOkCancelComponent, {size: 'lg', ...options});
    const componentInstance: CGModalOkCancelComponent = modalRef.componentInstance;
    componentInstance.title = title;
    componentInstance.message = message;
    if (isObject(options)) {
      if (!isEmpty(options.type)) {
        componentInstance.type = options.type;
      }
      if (!isEmpty(options.icon)) {
        componentInstance.icon = options.icon;
      }
      if (isBoolean(options.showCloseBtn)) {
        componentInstance.showCloseBtn = options.showCloseBtn;
      }
      if (isBoolean(options.showOkBtn)) {
        componentInstance.showOkBtn = options.showOkBtn;
      }
      if (isBoolean(options.showCancelBtn)) {
        componentInstance.showCancelBtn = options.showCancelBtn;
      }
      if (!isEmpty(options.btnOkIcon)) {
        componentInstance.btnOkIcon = options.btnOkIcon;
      }
      if (!isEmpty(options.btnOkText)) {
        componentInstance.btnOkText = options.btnOkText;
      }
      if (!isEmpty(options.btnCancelIcon)) {
        componentInstance.btnCancelIcon = options.btnCancelIcon;
      }
      if (!isEmpty(options.btnCancelText)) {
        componentInstance.btnCancelText = options.btnCancelText;
      }
    }
    return modalRef;
  }

  public showOkCancel(title: string, message: string, options?: ICGOkCancelModalOptions): Promise<void> {
    return this.showOkCancelVanilla(title, message, options).result;
  }

  public showPdfVanilla(title: string, pdfUrl: string, options?: ICGModalOptions<void>): NgbModalRef {
    const modalRef: NgbModalRef = this.showVanilla(CGPdfModalComponent, options);
    const componentInstance: CGPdfModalComponent = modalRef.componentInstance;
    componentInstance.title = title;
    componentInstance.pdfUrl = pdfUrl;
    return modalRef;
  }

  public showPdf(title: string, pdfUrl: string, options?: ICGModalOptions<void>): Promise<void> {
    return this.showPdfVanilla(title, pdfUrl, options).result;
  }

  public showHelpTopicsVanilla(options?: ICGModalOptions<void>): NgbModalRef {
    return this.showVanilla(CGHelpTopicsModalComponent, options);
  }

  public showHelpTopics(topics: Array<ICGHelpTopic>, title?: string, options?: ICGModalOptions<void>): Promise<void> {
    const modalRef: NgbModalRef = this.showHelpTopicsVanilla(options);
    const componentInstance: CGHelpTopicsModalComponent = modalRef.componentInstance;
    componentInstance.title = title;
    componentInstance.topics = topics;
    return modalRef.result;
  }

  public showChangeEmpresa(): Promise<TUserSession> {
    return this.show(PortalChangeFirmModalComponent);
  }
}
