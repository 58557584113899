<div class="artigos">
  <config-options [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="instanceName" [evaluateInvisibleFn]="fnEvaluateConfigOptionInvisible">
  </config-options>

  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-tabs>
        <pl-tab [caption]="'artigos.navigator.tabs.ficha'">
          <div *plTabContent>
            <pl-form [properties]="{readonly: type === stateTypes.DETAIL}">
              <pl-navigator contentMaxHeight="70vh">
                <pl-navigator-item caption="artigos.navigator.groups.indentificacao" [order]="-1">
                  <div *plNavigatorItemContent>
                    <div class="d-flex align-items-center" style="gap: 15px">
                      <div style="flex: 1">
                        <pl-group>
                          <pl-group>
                            <label [translate]="'artigos.fields.nArtigo'"></label>
                            <edit>
                              <pl-edit-text attrName="numArt" [(model)]="model.nArtigo" [placeholder]="'artigos.fields.nArtigoPlaceholder'" plAutoFocus></pl-edit-text>
                            </edit>
                          </pl-group>

                          <pl-group>
                            <label [translate]="'artigos.fields.nome'"></label>
                            <edit>
                              <pl-edit-text
                                attrName="nomeArt"
                                [(model)]="model.nome"
                                [placeholder]="'artigos.fields.nomePlaceholder'"
                                [properties]="{validators: {required: {value: true}, maxlength: {value: 100}}}">
                              </pl-edit-text>
                            </edit>
                          </pl-group>
                        </pl-group>

                        <pl-group>
                          <pl-group>
                            <label>
                              <span [translate]="'artigos.fields.segNArtigo'"></span>
                              <pl-tooltip class="tooltip-info-label" [config]="{text: 'artigos.text.codBarras', placement: ['bottom', 'left']}">
                                <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                              </pl-tooltip>
                            </label>
                            <edit>
                              <pl-edit-text attrName="segNArtigo" [(model)]="model.segNArtigo" [placeholder]="'artigos.fields.segNArtigo'"></pl-edit-text>
                            </edit>
                          </pl-group>

                          <pl-group>
                            <label [translate]="'artigos.fields.segNome'"></label>
                            <edit>
                              <pl-edit-text attrName="segNome" [(model)]="model.segNome" [placeholder]="'artigos.fields.segNome'" [properties]="{validators: {maxlength: {value: 50}}}"></pl-edit-text>
                            </edit>
                          </pl-group>
                        </pl-group>

                        <pl-group>
                          <pl-group>
                            <label>
                              <span [translate]="'artigos.fields.artDesactivado'"></span>
                              <pl-tooltip class="tooltip-info-label" [config]="{text: 'artigos.text.artigoDesativado', placement: ['bottom', 'left']}">
                                <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                              </pl-tooltip>
                            </label>
                            <edit>
                              <pl-edit type="boolean" attrName="artDesactivado" [(model)]="model.artDesactivado"></pl-edit>
                            </edit>
                          </pl-group>

                          <pl-group>
                            <label>
                              <span [translate]="'artigos.fields.artBloqueado'"></span>
                              <pl-tooltip class="tooltip-info-label" [config]="{text: 'artigos.text.artigoBloqueado', placement: ['bottom', 'left']}">
                                <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                              </pl-tooltip>
                            </label>
                            <edit>
                              <pl-edit type="boolean" attrName="artBloqueado" [(model)]="model.artBloqueado"></pl-edit>
                            </edit>
                          </pl-group>
                        </pl-group>
                      </div>

                      <div class="d-flex flex-column" [plPromise]="promiseArtigoPicture">
                        <pl-media-devices-camera-capture-image
                          [model]="artigoPicture"
                          (modelChange)="changedArtigoPicture($event)"
                          [disabled]="type === stateTypes.DETAIL"
                          icon="fa fa-image"
                          [properties]="propertiesCaptureImage">
                        </pl-media-devices-camera-capture-image>
                      </div>
                    </div>
                  </div>
                </pl-navigator-item>

                <pl-navigator-item caption="artigos.navigator.groups.classificadores" [order]="0">
                  <div *plNavigatorItemContent>
                    <pl-group>
                      <pl-group>
                        <label>
                          <span [translate]="'artigos.fields.nomeFamilia'"></span>
                          <pl-tooltip class="tooltip-info-label" [config]="{text: 'artigos.text.familia', placement: ['bottom', 'left']}">
                            <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                          </pl-tooltip>
                        </label>
                        <edit>
                          <entity-autocomplete
                            entity="familias"
                            attrName="familias"
                            [model]="model"
                            (selectedKeyChange)="changedFamilia($event, false)"
                            (evtSelectedDescriptionChanged)="model.nomeFamilia = $event"
                            [properties]="{validators: {required: {value: true}}}"
                            [fieldsMap]="{nFamilia: 'nFamilia', nome: 'nomeFamilia'}">
                          </entity-autocomplete>
                        </edit>
                      </pl-group>
                      <pl-group>
                        <label>
                          <span [translate]="'artigos.fields.nomeTpArt'"></span>
                          <pl-tooltip class="tooltip-info-label" [config]="{text: tooltipTipoProdInvt, placement: ['bottom', 'left']}">
                            <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                          </pl-tooltip>
                        </label>

                        <edit>
                          <entity-autocomplete
                            entity="tiposartigo"
                            attrName="tiposartigo"
                            [model]="model"
                            (modelChange)="tiposArtigoChanged($event)"
                            [fieldsMap]="{codTpArt: 'nTpArt', nome: 'nomeTpArt'}"
                            [output]="'nome'">
                          </entity-autocomplete>
                        </edit>
                      </pl-group>
                    </pl-group>

                    <pl-group>
                      <pl-group>
                        <label [translate]="'artigos.fields.nomeGrFamilia'"></label>
                        <edit>
                          <entity-autocomplete
                            entity="grandesfamilias"
                            attrName="grandesfamilias"
                            [model]="model"
                            (selectedKeyChange)="model.nGrFamilia = $event"
                            (evtSelectedDescriptionChanged)="model.nomeGrFamilia = $event"
                            [fieldsMap]="{nDepart: 'nGrFamilia', nome: 'nomeGrFamilia'}">
                          </entity-autocomplete>
                        </edit>
                      </pl-group>
                      <pl-group>
                        <label [translate]="'artigos.fields.nomeSubFa'"></label>
                        <edit>
                          <entity-autocomplete
                            entity="subfamilias"
                            attrName="subfamilias"
                            [model]="model"
                            (selectedKeyChange)="model.nSubFa = $event"
                            (evtSelectedDescriptionChanged)="model.nomeSubFa = $event"
                            [fieldsMap]="{nSubFa: 'nSubFa', nome: 'nomeSubFa'}">
                          </entity-autocomplete>
                        </edit>
                      </pl-group>
                    </pl-group>

                    <pl-group>
                      <pl-group *ngIf="optionShowCategoria">
                        <label [translate]="'artigos.fields.categoria'"></label>
                        <edit>
                          <entity-autocomplete
                            entity="categoriaartigo"
                            attrName="categoria"
                            [model]="model"
                            (selectedKeyChange)="model.categoria = $event"
                            (evtSelectedDescriptionChanged)="model.descricaoCategoria = $event"
                            [fieldsMap]="{descricao: 'descricaoCategoria'}">
                          </entity-autocomplete>
                        </edit>
                      </pl-group>

                      <pl-group *ngIf="optionShowPropriedade">
                        <label [translate]="'artigos.fields.grupoartigo'"></label>
                        <edit>
                          <pl-edit attrName="grupoartigo" type="grupoartigo" [(model)]="model.grupoArtigo"></pl-edit>
                        </edit>
                      </pl-group>
                    </pl-group>

                    <pl-group>
                      <pl-group>
                        <label [translate]="'artigos.fields.nomeUnidadeMov'"></label>
                        <edit>
                          <entity-autocomplete
                            entity="unidadesmovimento"
                            attrName="unidadesmovimento"
                            [model]="model"
                            (selectedKeyChange)="model.unidadeMov = $event"
                            (evtSelectedDescriptionChanged)="model.nomeUnidadeMov = $event"
                            [fieldsMap]="{codUni: 'unidadeMov', nome: 'nomeUnidadeMov'}">
                          </entity-autocomplete>
                        </edit>
                      </pl-group>
                      <pl-group>
                        <label [translate]="'artigos.fields.qtdPorUnid1'"></label>
                        <edit>
                          <pl-edit-number
                            attrName="qtdPorUnid1"
                            [(model)]="model.qtdPorUnid1"
                            [placeholder]="'artigos.fields.qtdPorUnid1Placeholder'"
                            [properties]="{decimalsLimit: 3}"></pl-edit-number>
                        </edit>
                      </pl-group>
                    </pl-group>
                  </div>
                </pl-navigator-item>

                <pl-navigator-item caption="artigos.navigator.groups.precos" [order]="1">
                  <div *plNavigatorItemContent>
                    <pl-group>
                      <pl-group>
                        <label [translate]="'artigos.fields.precoSemIva'"></label>
                        <edit>
                          <pl-edit-number
                            attrName="precoSemIva"
                            [model]="model.precoSemIva"
                            (modelChange)="calcPrecoSemIva($event)"
                            [placeholder]="'artigos.fields.precoSemIvaPlaceholder'"
                            [properties]="propertiesEditDecimalsPrecoLimit">
                          </pl-edit-number>
                        </edit>
                      </pl-group>

                      <pl-group>
                        <label [translate]="'artigos.fields.precoCompUlt'"></label>
                        <edit>
                          <pl-edit-number
                            attrName="precoCompUlt"
                            [model]="model.precoCompUlt"
                            (modelChange)="model.precoCompUlt = $event"
                            [placeholder]="'artigos.fields.precoCompUltPlaceholder'"
                            [properties]="propertiesEditDecimalsPrecoLimit">
                          </pl-edit-number>
                        </edit>
                      </pl-group>

                      <pl-group>
                        <label [translate]="'artigos.fields.prVendaUlti'"></label>
                        <edit>
                          <pl-edit-number
                            attrName="prVendaUlti"
                            [model]="model.prVendaUlti"
                            (modelChange)="model.prVendaUlti = $event"
                            [placeholder]="'artigos.fields.prVendaUltiPlaceholder'"
                            [properties]="propertiesEditDecimalsPrecoLimit">
                          </pl-edit-number>
                        </edit>
                      </pl-group>
                    </pl-group>

                    <pl-group>
                      <pl-group>
                        <label [translate]="'artigos.fields.precoComIva'"></label>
                        <edit>
                          <pl-edit-number
                            attrName="precoComIva"
                            [model]="model.precoComIva"
                            (modelChange)="calcPrecoComIva($event)"
                            [placeholder]="'artigos.fields.precoComIvaPlaceholder'"
                            [properties]="propertiesEditDecimalsPrecoLimit">
                          </pl-edit-number>
                        </edit>
                      </pl-group>

                      <pl-group>
                        <label [translate]="'artigos.fields.dataPrCompra'"></label>
                        <edit>
                          <pl-edit type="date" attrName="dataPrCompra" [(model)]="model.dataPrCompra" [properties]="{disabled: true}"></pl-edit>
                        </edit>
                      </pl-group>

                      <pl-group>
                        <label [translate]="'artigos.fields.dataPrVendUl'"></label>
                        <edit>
                          <pl-edit type="date" attrName="dataPrVendUl" [(model)]="model.dataPrVendUl" [properties]="{disabled: true}"></pl-edit>
                        </edit>
                      </pl-group>
                    </pl-group>

                    <pl-group *ngIf="optionShowPrecoBaseCusto">
                      <label [translate]="'artigos.fields.precoBaseCusto'"></label>
                      <edit>
                        <pl-edit-number
                          attrName="precoBaseCusto"
                          [(model)]="model.precoBaseCusto"
                          [placeholder]="'artigos.fields.precoBaseCustoPlaceholder'"
                          [properties]="{readonly: false, disabled: type === stateTypes.DETAIL, decimalsLimit: 2}">
                          <div *inputGroup="{klass: 'hollow-form'}">
                            <i class="fa fa-info-circle fa-fw" aria-hidden="true" [title]="'artigos.message.infofield' | translate"></i>
                          </div>
                        </pl-edit-number>
                      </edit>
                    </pl-group>
                  </div>
                </pl-navigator-item>

                <pl-navigator-item caption="artigos.navigator.groups.stocks" [order]="2">
                  <div *plNavigatorItemContent>
                    <pl-group>
                      <pl-group>
                        <label [translate]="'artigos.fields.movimStock'"></label>
                        <edit>
                          <pl-edit type="boolean" attrName="movimStock" [(model)]="model.movimStock"></pl-edit>
                        </edit>
                      </pl-group>

                      <pl-group>
                        <label [translate]="'artigos.fields.naoPermiteExistNeg'"></label>
                        <edit>
                          <pl-edit type="boolean" attrName="naoPermiteExistNeg" [(model)]="model.naoPermiteExistNeg" [properties]="{disabled: !model.movimStock}"></pl-edit>
                        </edit>
                      </pl-group>

                      <pl-group>
                        <label [translate]="'artigos.fields.codvaloriz'"></label>
                        <edit>
                          <pl-edit attrName="codValoriz" type="criterioDisponiveis" [(model)]="model.codvaloriz" [properties]="{disabled: !model.movimStock}"></pl-edit>
                        </edit>
                      </pl-group>
                    </pl-group>

                    <pl-group>
                      <pl-group>
                        <label>
                          <span [translate]="'artigos.fields.temLote'"></span>
                          <pl-tooltip
                            class="tooltip-info-label"
                            *ngIf="!comercialAdvanced || !model.movimStock"
                            [config]="{
                              text:
                                comercialAdvanced && !model.movimStock
                                  ? 'artigos.text.lotesBloqueadosMovStock'
                                  : licencaStoreModePublic
                                    ? 'blockedPlugin.text.noLicense'
                                    : ('blockedPlugin.text.noLicenseStoreMode' | translate: {href: cgStoreUrlBackOffice, portal: currentPortal.portalTitle}),
                              placement: ['bottom', 'left']
                            }">
                            <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                          </pl-tooltip>
                        </label>
                        <edit>
                          <pl-edit type="boolean" attrName="temLote" [(model)]="model.temLote" [properties]="{disabled: !comercialAdvanced || !model.movimStock}"></pl-edit>
                        </edit>
                      </pl-group>

                      <pl-group>
                        <label [translate]="'artigos.fields.temDataValidade'"></label>
                        <edit>
                          <pl-edit
                            type="boolean"
                            attrName="temDataValidade"
                            [(model)]="model.temDataValidade"
                            [properties]="{disabled: !comercialAdvanced || !model.movimStock || !model.temLote}"></pl-edit>
                        </edit>
                      </pl-group>

                      <pl-group>
                        <label [translate]="'artigos.fields.diasValidade'"></label>
                        <edit>
                          <pl-edit-number
                            attrName="diasValidade"
                            [(model)]="model.diasValidade"
                            [placeholder]="'artigos.fields.diasValidadePlaceholder'"
                            [properties]="{disabled: !comercialAdvanced || !model.movimStock || !model.temLote}"></pl-edit-number>
                        </edit>
                      </pl-group>
                    </pl-group>

                    <pl-group *ngIf="type !== stateTypes.NEW && model.movimStock">
                      <pl-group>
                        <label [translate]="'artigos.fields.qtdTotal'"></label>
                        <edit>
                          <pl-edit-number
                            attrName="qtdTotal"
                            [(model)]="model.qtdTotal"
                            [placeholder]="'artigos.fields.qtdTotal'"
                            [properties]="{disabled: true, decimalsLimit: decimalsQTD}"></pl-edit-number>
                        </edit>
                      </pl-group>

                      <pl-group>
                        <label>
                          <span [translate]="'artigos.fields.qtdTotalStockPotencial'"></span>
                          <pl-tooltip class="tooltip-info-label" [config]="{text: 'artigos.text.stockPotencial', placement: ['bottom', 'left']}">
                            <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                          </pl-tooltip>
                        </label>
                        <edit>
                          <pl-edit-number attrName="qtdTotalStockPotencial" [(model)]="model.qtdTotalStockPotencial" [properties]="{disabled: true, decimalsLimit: decimalsQTD}"></pl-edit-number>
                        </edit>
                      </pl-group>

                      <pl-group>
                        <label [translate]="'artigos.fields.qtdTotalStockEncCliente'"></label>
                        <edit>
                          <pl-edit-number attrName="qtdTotalStockEncCliente" [(model)]="model.qtdTotalStockEncCliente" [properties]="{disabled: true, decimalsLimit: decimalsQTD}"></pl-edit-number>
                        </edit>
                      </pl-group>

                      <pl-group>
                        <label [translate]="'artigos.fields.qtdTotalStockEncForn'"></label>
                        <edit>
                          <pl-edit-number attrName="qtdTotalStockEncForn" [(model)]="model.qtdTotalStockEncForn" [properties]="{disabled: true, decimalsLimit: decimalsQTD}"></pl-edit-number>
                        </edit>
                      </pl-group>
                    </pl-group>

                    <hr />

                    <dx-data-grid
                      *ngIf="type !== stateTypes.NEW && model.movimStock"
                      cgDxDataGridInstanceName="detalhesPorArmazem"
                      [cgDxDataGrid]="dataGridDefinitionArmazem"
                      [dataSource]="model.stockArmazens"
                      (onInitialized)="onInitializedDetalhesPorArmazem($event)"
                      (onCellPrepared)="onCellPreparedDetalhesPorArmazem($event)"
                      (onSaved)="onSavedMovStock($event)">
                      <div *dxTemplate="let toolbarItem of 'templateToolbarDetArmazens'">
                        <label class="mb-0" [translate]="'artigos.text.detalheArmazem'"></label>
                      </div>
                    </dx-data-grid>
                  </div>
                </pl-navigator-item>

                <pl-navigator-item caption="artigos.navigator.groups.classificacao" [order]="3">
                  <div *plNavigatorItemContent>
                    <pl-group>
                      <label [translate]="'artigos.fields.temRetencaoNaFonte'"></label>
                      <edit>
                        <pl-edit type="boolean" attrName="temRetencaoNaFonte" [(model)]="model.temRetencaoNaFonte"></pl-edit>
                      </edit>
                    </pl-group>

                    <pl-group>
                      <pl-group>
                        <label>
                          <span [translate]="'artigos.fields.nomeIvaVenda'"></span>
                          <pl-tooltip class="tooltip-info-label" [config]="{text: tooltipTaxaIvaVenda, placement: ['bottom', 'left']}">
                            <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                          </pl-tooltip>
                        </label>
                        <edit>
                          <entity-autocomplete entity="ivas" attrName="ivasVenda" [model]="model" (modelChange)="changedIVAVenda($event)" [fieldsMap]="{codIva: 'codIvaVenda', nome: 'nomeIvaVenda'}">
                          </entity-autocomplete>
                        </edit>
                      </pl-group>

                      <pl-group>
                        <label>
                          <span [translate]="'artigos.fields.nomeIvaCompra'"></span>
                          <pl-tooltip class="tooltip-info-label" [config]="{text: tooltipTaxaIvaCompra, placement: ['bottom', 'left']}">
                            <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                          </pl-tooltip>
                        </label>
                        <edit>
                          <entity-autocomplete
                            entity="ivas"
                            attrName="ivasCompra"
                            [model]="model"
                            (modelChange)="changedIVACompra($event)"
                            [fieldsMap]="{codIva: 'codIvaCompra', nome: 'nomeIvaCompra'}">
                          </entity-autocomplete>
                        </edit>
                      </pl-group>
                    </pl-group>
                  </div>
                </pl-navigator-item>

                <pl-navigator-item caption="artigos.navigator.groups.contab" [order]="5" *ngIf="hasRoleContabilidade && tipoClassificador === classArtTemplate.Familia">
                  <div *plNavigatorItemContent>
                    <pl-group>
                      <pl-group>
                        <label [translate]="'artigos.fields.ncontapoc'"></label>
                        <edit>
                          <entity-autocomplete
                            entity="pocs"
                            attrName="ncontapoc"
                            [model]="model"
                            (modelChange)="changedNContaPocVenda($event)"
                            [filter]="filterNContaPocVenda"
                            [customActions]="customActionsConta"
                            [properties]="{disabled: !canEditContaArtigoFamilia}"
                            [fieldsMap]="{nConta: 'nContaPoc', nome: 'nomeContaPoc'}">
                            <ng-container *inputGroup="{klass: mostraTodasContasVenda ? 'btn-primary' : 'btn-light'}">
                              <pl-tooltip *ngIf="sujeitaAsLeisVigoramEmPortugal" [config]="tooltipMostraTodasContas">
                                <div *plTooltipContent plDelegateEventDownwardsClick (click)="mostraTodasContasVenda = !mostraTodasContasVenda; evaluateFilterNContaPocVenda()">
                                  <i class="fa fa-plus" aria-hidden="true"></i>
                                </div>
                              </pl-tooltip>
                            </ng-container>
                          </entity-autocomplete>

                          <div class="form-control-info" *ngIf="messageContaPocFormadaVenda">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="btn">{{ messageContaPocFormadaVenda }}</span>
                          </div>

                          <div class="form-control-info" *ngIf="!!model.nContaPoc && contaVendaOriginalFamilia !== model.nContaPoc">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <button type="button" class="btn btn-link" [translate]="'artigos.message.diferefamilia'" (click)="openFamiliasMaintenance()"></button>
                          </div>
                        </edit>
                      </pl-group>

                      <pl-group>
                        <label [translate]="'artigos.fields.nContaPocCompra'"></label>
                        <edit>
                          <entity-autocomplete
                            entity="pocs"
                            attrName="nContaPocCompra"
                            [model]="model"
                            (modelChange)="changedNContaPocCompra($event)"
                            [filter]="filterNContaPocCompra"
                            [customActions]="customActionsConta"
                            [properties]="{disabled: !canEditContaArtigoFamilia}"
                            [fieldsMap]="{nConta: 'nContaPocCompra', nome: 'nomeContaPocCompra'}">
                            <ng-container *inputGroup="{klass: mostraTodasContasCompra ? 'btn-primary' : 'btn-light'}">
                              <pl-tooltip *ngIf="sujeitaAsLeisVigoramEmPortugal" [config]="tooltipMostraTodasContas">
                                <div *plTooltipContent plDelegateEventDownwardsClick (click)="mostraTodasContasCompra = !mostraTodasContasCompra; evaluateFilterNContaPocCompra()">
                                  <i class="fa fa-plus" aria-hidden="true"></i>
                                </div>
                              </pl-tooltip>
                            </ng-container>
                          </entity-autocomplete>

                          <div class="form-control-info" *ngIf="messageContaPocFormadaCompra">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="btn">{{ messageContaPocFormadaCompra }}</span>
                          </div>

                          <div class="form-control-info" *ngIf="!!model.nContaPocCompra && contaCompraOriginalFamilia !== model.nContaPocCompra">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <button type="button" class="btn btn-link" [translate]="'artigos.message.diferefamilia'" (click)="openFamiliasMaintenance()"></button>
                          </div>
                        </edit>
                      </pl-group>
                    </pl-group>
                  </div>
                </pl-navigator-item>
              </pl-navigator>
            </pl-form>
          </div>
        </pl-tab>

        <pl-tab [caption]="'artigos.navigator.tabs.lotes'" *ngIf="model.temLote && type !== stateTypes.NEW">
          <div *plTabContent>
            <cg-blocked-plugin [pluginRoles]="rolesComercialAdvanced"></cg-blocked-plugin>

            <entity-list
              entityName="lotes"
              [maintenanceMode]="maintenanceMode"
              *ngIf="comercialAdvanced"
              filter="{{ lotesfilter }}"
              [serviceMethodsOverride]="serviceMethodsOverride"
              (evtDataGridOnInitialized)="onDataGridInitializedLotes($event)"
              (evtDataGridColumnsPreparing)="onDataGridLotesColumnsPreparing($event)"
              (evtDataGridCellClick)="onDataGridLotesCellClick($event)">
              <div *entityListDataGridToolbarItem="let toolbarItem; item: {name: 'addLote'}">
                <button type="button" id="lotes-save-2" class="btn btn-primary btn-sm ng-star-inserted" [disabled]="!comercialAdvanced" (click)="novoLote()">
                  <i class="fa fa-plus-circle"></i>&nbsp;<span [translate]="'lotes.btn.new'"></span>
                </button>
              </div>

              <div *entityListHeaderActions class="text-center">
                <pl-button
                  klass="btn-info btn-xs action-edit-line me-1"
                  [attr.title]="(lotesAtivos ? 'lotes.list.actions.verSoLotesTerminados' : 'lotes.list.actions.verSoLotesAtivos') | translate"
                  [disabled]="!comercialAdvanced"
                  [onClick]="fnVerSoLotesTerminados()">
                  <i class="fa fa-fw fa-eye"></i>
                </pl-button>
              </div>

              <div *entityListItemActions="let itemAction" class="text-center">
                <pl-button
                  klass="btn-warning btn-xs action-edit-line me-1"
                  [attr.title]="'lotes.list.actions.editar' | translate"
                  [disabled]="!comercialAdvanced"
                  [onClick]="fnEditarLote(itemAction.data)">
                  <i class="fa fa-fw fa-pencil-square-o"></i>
                </pl-button>
                &nbsp;
                <pl-button
                  *ngIf="!itemAction.data.terminado"
                  klass="btn-danger btn-xs action-delete"
                  [attr.title]="'lotes.list.actions.terminar' | translate"
                  [disabled]="!comercialAdvanced"
                  [onClick]="fnTerminarLote(itemAction.data)">
                  <i class="fa fa-fw fa-lock"></i>
                </pl-button>
              </div>
            </entity-list>
          </div>
        </pl-tab>

        <pl-tab [caption]="'artigos.navigator.tabs.extrato'" *ngIf="type !== stateTypes.NEW && model.movimStock">
          <div *plTabContent>
            <extratos-artigos [nArtigo]="model.nArtigo" [showFilterArtigo]="false"></extratos-artigos>
          </div>
        </pl-tab>

        <pl-tab [caption]="'artigos.navigator.tabs.estatistica'" *ngIf="type !== stateTypes.NEW">
          <div *plTabContent>
            <docs-comerciais-estatisticas [nArtigo]="model.nArtigo" [moduleType]="tiposEstatistica.TODOS"></docs-comerciais-estatisticas>
          </div>
        </pl-tab>

        <pl-tab [caption]="'artigos.navigator.tabs.anexos'" *ngIf="type !== stateTypes.NEW">
          <div *plTabContent>
            <artigos-gdoc [nArtigo]="model.nArtigo"></artigos-gdoc>
          </div>
        </pl-tab>
      </pl-tabs>
    </div>
  </entity-detail>
</div>
