import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import moment, {Duration, Moment} from 'moment';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {HookMatchCriteria, StateDeclaration, StateObject, Transition} from '@uirouter/core';
import {Properties} from 'devextreme/ui/button';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {isEqual, merge} from 'lodash-es';
import {
  copy,
  debounce,
  IPlMediaDevicesCameraCaptureProperties,
  IPlToolbarItemDefinition,
  IPlToolbarMenuItem,
  IPlTooltipConfig,
  IPlValidator,
  IPlValidatorValidateParams,
  isBoolean,
  isDefinedNotNull,
  isEmpty,
  isNumber,
  isObject,
  isString,
  PlAlertService,
  PlI18nService,
  timeout,
  toInteger
} from 'pl-comps-angular';
import {Subscription} from 'rxjs';
import {TDate} from '../../../../../../common/dates';
import {EEntityStateDetailType} from '../../../../../../common/utils/entity.state.utils';
import {maxDateCG, minDateCG} from '../../../../../../common/utils/utils';
import {IDataSourceItem} from '../../../../../components/datasource/datasources.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IModuleMaintenanceInstance} from '../../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../../components/entity/maintenance/module/module.maintenance.service';
import {CGExceptionService} from '../../../../../components/exceptions/exceptions.service';
import {ModuloEntityDetailComponent} from '../../../../../components/module/entitydetail/module.entitydetail.component';
import {EStatusCode, isTest} from '../../../../../../config/constants';
import {IJsonCodPostalHelper} from '../../../../../interfaces/jsonCodPostalHelper.interface';
import {IApiRequestConfig, IApiRequestConfigWithBody} from '../../../../../services/api/api.service.interface';
import {ExternalCodPostaisService} from '../../../../../services/external.cod.postais.service';
import {STATE_NAME_DISCONNECTED} from '../../../../../states/account/disconnected/disconnected.state.interface';
import {MODULE_NAME_PCA_RH_SIMULACAO_VENCIMENTO} from '../../../../colaboradores/simulavalorvencimento/simulaValorVencimento.module.interface';
import {DATA_SOURCE_ESTADO_CIVIL} from '../../../../../datasources/dhemp/estadocivil/estadoCivil.datasource';
import {DATA_SOURCE_METODO_CALC_SALARIO} from '../../../../../datasources/dhemp/metodocalcsalario/metodoCalcSalario.datasource';
import {DATA_SOURCE_REGIME_TRABALHO} from '../../../../../datasources/dhemp/regimetrabalho/regimeTrabalho.datasource';
import {DATA_SOURCE_RESIDENCIA_FISCAL} from '../../../../../datasources/dhemp/residenciafiscal/residenciaFiscal.datasource';
import {DATA_SOURCE_TIPO_CALC_DUODECIMOS} from '../../../../../datasources/dhemp/tipocalcduodecimos/tipoCalcDuodecimos.datasource';
import {DATA_SOURCE_SEXOS} from '../../../../../datasources/sexos/sexos.datasource';
import {ETipoCategoria} from '../../../../../datasources/tipocategoria/tipoCategoria.datasource.interface';
import {ETipoCategoriaDesc} from '../../../../../datasources/tipocategoriadesc/tipoCategoriaDesc.datasource.interface';
import {ETipoContaContabilidade} from '../../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {IJsonCodPostal} from '../../../../../entities/codpostais/jsonCodPostal.entity.interface';
import {IJsonConcelho} from '../../../../../entities/concelhos/jsonConcelho.entity.interface';
import {DGEMPSService} from '../../../../../entities/dgemps/dgemps.entity.service';
import {IJsonConfigRHDadosDGEMP, IJsonProcessamentoSalariosConfig} from '../../../../rhconfiguracoes/jsonRHConfiguracoes.module.interface';
import {MODULE_NAME_RH_CONFIGURACOES_DADOS_CONFIG_FICHA} from '../../../../rhconfiguracoes/rhConfiguracoes.module.interface';
import {RHConfiguracoesService} from '../../../../rhconfiguracoes/rhConfiguracoes.module.service';
import {ETipoContratoQuadroPessoal, IJsonTiposContrato} from '../../../../../entities/tiposcontrato/jsonTiposContrato.entity.interface';
import {ERHRegistarEmpregadosSSEmpSegSocialVinculo, MODULE_NAME_RH_REGISTAR_EMP_SS} from '../../../rhregistarempregadosss/rhRegistarEmpregadosSS.module.interface';
import {RHRegistarEmpregadosSSService} from '../../../rhregistarempregadosss/rhRegistarEmpregadosSS.module.service';
import {EDGEMPSSaveCadastroMode, IDGEMPSFullEntityService, IDGEMPSFullEstadoSSModel} from '../../dgempsFull.interface';
import {
  EDHEmpMetodoCalcSalario,
  EDHEmpRegimeTrabalho,
  EDHEmpTipoCalcDuodecimos,
  EDHEmpValoresDuodecimos,
  EEstadoCivil,
  EResidenciaFiscal,
  ESexo,
  IJsonDGEMPComentario,
  IJsonDGEMPFull,
  IJsonDHEMP,
  IJsonDHEMPCalcularCustoHoraEmpregado,
  IJsonDHEMPCustoHoraEmpregado
} from '../../jsonDGEMPFull.interface';
import {DGEMPSFullCommentModalComponent} from '../../modals/comment/dgempsFull.comment.modal.component';
import {DGEMPSFullEditDeletePromptModalComponent} from '../../modals/deleteprompt/dgempsFull.edit.deletePrompt.modal.component';
import {DGEMPSFullSaveCadastroModalComponent} from '../../modals/savecadastro/dgempsFull.saveCadastro.modal.component';
import {ConfigLoginWsSSModalComponent} from '../../../../segsocial/modals/loginWsSS.modal.component';
import {DgempsfullSimulaTaxaFixaIrsModalComponent} from '../../modals/simulartaxafixairs/dgempsFull.simulaTaxaFixaIrs.modal.component';
import {DATA_SOURCE_NAME_CONTINENTES} from '../../../../../datasources/continentes/continentes.datasource.interface';
import {IJsonCateg} from '../../../../../entities/categorias/jsonCateg.entity.interface';

const MAX_WIDTH = 500;
const RESIZE_WIDTH = 150;
const RESIZE_HEIGHT = 150;
const DEFAULT_TIMEOUT = 200;
const DEFAULT_WORK_HOURS = 8;
const DEFAULT_WORK_DAYS = 5;
const DEFAULT_WORK_WEEKS = 52;
const DEFAULT_WORK_MONTHS = 12;
const DEFAULT_MAX_DIAS_UTEIS = 30;
const DEFAULT_DIAS_UTEIS = 22;
const DEFAULT_MIN_AGE = 15;
const DEFAULT_MAX_AGE = 100;
const DURATION_INDEX_HOURS = 0;
const DURATION_INDEX_MINUTES = 1;
const DURATION_INDEX_SECONDS = 2;
const DEBOUNCE_ID_CALCULAR_CUSTO_HORA_EMPREGADO = 'dgemps-full-edit-component-debounce-calcular-custo-hora-empregado';
const DEBOUNCE_TIME_CALCULAR_CUSTO_HORA_EMPREGADO = 500;
let debounceIdCalcularCustoHoraEmpregadoCount = 0;
const WSSEGSOCIALCREDENTIALS_ERROR_CLASS = 'EObjRHRegistarConsultarEmpregadosWSSegSocialCredentials';

@Component({
  selector: 'dgemps-full-edit-component',
  templateUrl: './dgempsFull.edit.component.html'
})
export class DGEMPSFullEditComponent extends ModuloEntityDetailComponent<IJsonDGEMPFull, IDGEMPSFullEntityService> implements OnInit, OnDestroy {
  @Input() public hasRequiredFields: boolean;
  @Input() public defaultData: IJsonConfigRHDadosDGEMP;

  public readonly dataSourceNameContinentes: string = DATA_SOURCE_NAME_CONTINENTES;
  public readonly groupEstadoCivil: ReadonlyArray<IDataSourceItem<EEstadoCivil>>;
  public readonly groupSexos: ReadonlyArray<IDataSourceItem<ESexo>>;
  public readonly groupMetodosCalcSalario: ReadonlyArray<IDataSourceItem<EDHEmpMetodoCalcSalario>>;
  public readonly groupRegimeTrabalho: ReadonlyArray<IDataSourceItem<EDHEmpRegimeTrabalho>>;
  public readonly groupTipoCalcDuodecimos: ReadonlyArray<IDataSourceItem<EDHEmpTipoCalcDuodecimos>>;
  public readonly groupResidenciaFiscal: ReadonlyArray<IDataSourceItem<EResidenciaFiscal>>;
  public readonly valoresDuoDecimos: Array<number>;
  public readonly filterPocs: string;
  public readonly tooltipNumDependDef: IPlTooltipConfig;
  public readonly tooltipFormulaCustoHoraEmpregado: IPlTooltipConfig;
  public readonly tooltipCalcularCustoHoraEmpregado: IPlTooltipConfig;
  public readonly validatorNumSegSoc: IPlValidator<string>;
  public readonly validatorDtFimContrato: IPlValidator<string, TDate>;
  public readonly validatorDTNascimento: IPlValidator<string, TDate>;
  public readonly maskHorasDia: unknown;
  public readonly maskHorasSemana: string;
  public readonly maskHorasMes: string;
  public readonly outputAbdes: string;
  public readonly filterVencimento: string;
  public readonly filterSubsAlimentacao: string;
  public readonly filterDiuturnidades: string;
  public readonly filterSubsFerias: string;
  public readonly filterSubsNatal: string;
  public readonly filterFerias: string;
  public readonly filterIRS: string;
  public readonly filterCRL: string;
  public readonly dataGridDefinition: IDevExpressDataGrid;

  public formInstance: UntypedFormGroup;
  public requiredCategoria: boolean;
  public requiredCentroCusto: boolean;
  public requiredClasseTrab: boolean;
  public requiredCCT: boolean;
  public requiredZona: boolean;
  public requiredDepartamento: boolean;
  public requiredFuncao: boolean;
  public requiredIntegraRU: boolean;
  public requiredIntegraSalarios: boolean;
  public requiredNivelQualif: boolean;
  public requiredOperacaoSeccao: boolean;
  public requiredPocRemuLiq: boolean;
  public requiredProfissao: boolean;
  public requiredReparticaoCentroCusto: boolean;
  public requiredSituacaoProfissao: boolean;
  public requiredSubDepartamento: boolean;
  public propertiesCaptureImage: IPlMediaDevicesCameraCaptureProperties;
  public dhempPicture: File;
  public hasDadosFicha: boolean;
  public horasDia: string;
  public horasSemana: string;
  public horasMes: string;
  public showOutdatedWarning: boolean;
  public promiseDHEMPPicture: Promise<void>;
  public promiseBtnCalc: Promise<void>;
  public suggestRemunHora: boolean;
  public appliedSuggestedRemunHora: boolean;
  public custoHoraEmpregado: IJsonDHEMPCustoHoraEmpregado;
  public tipoContratoQuadroPessoal: typeof ETipoContratoQuadroPessoal;
  public disabledValoresDuodecimos: boolean;
  public empresaDuodecimosSubNatal: number;
  public empresaDuodecimosSubFerias: number;
  public modelEstadoSS: IDGEMPSFullEstadoSSModel;
  public promiseEstadoSS: Promise<void>;
  public filterNTabIrs: string;

  private readonly _subscriptionOnCancel: Subscription;
  private readonly _historicoDados: Map<string, IJsonDGEMPFull>;
  private readonly _toolbarItemDatasHistoricoDados: IPlToolbarItemDefinition<Moment>;
  private readonly _debounceIdCalcularCustoHoraEmpregado: string;
  private readonly _maintenanceSimulaVenc: IModuleMaintenanceInstance;
  private readonly _maintenanceRHRegistarEmp: IModuleMaintenanceInstance;

  private _latestDGEMP: IJsonDGEMPFull;
  private _latestHistoricoDadosKey: string;
  private _originalDHEMP: IJsonDHEMP;
  private _originalDHEMPPicture: File;
  private _changedDHEMPPicture: boolean;
  private _deletedDHEMPPicture: boolean;
  private _selectedItemHistoricoDados: IPlToolbarMenuItem<Moment>;
  private _datasDadosHistoricos: Array<string>;
  private _changedHorasSemana: boolean;
  private _changedHorasMes: boolean;
  private _dataGridInstance: dxDataGrid;
  private _deRegisterOnStartFn: Function;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plI18nService: PlI18nService,
    private readonly _dgempsService: DGEMPSService,
    private readonly _externalCodPostaisService: ExternalCodPostaisService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _rhConfiguracoesService: RHConfiguracoesService,
    private readonly _rhRegistarEmpregadosSSService: RHRegistarEmpregadosSSService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'titulo', dataType: 'string', caption: 'dgempsfull.comments.title'},
        {dataField: 'texto', dataType: 'string', caption: 'dgempsfull.comments.text'},
        {dataField: 'data', dataType: 'date', caption: 'dgempsfull.comments.date'},
        {dataField: 'utilizador', dataType: 'string', caption: 'dgempsfull.comments.user'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtns'}
      ],
      dataSource: [],
      headerFilter: {visible: false},
      height: '50vh',
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      remoteOperations: false,
      scrolling: {rowRenderingMode: 'virtual'},
      toolbar: {
        items: [
          {
            location: 'after',
            widget: 'dxButton',
            name: 'addComment',
            disabled: this.type !== this.stateTypes.EDIT,
            options: {
              icon: 'add',
              hint: this._translateService.instant('global.btn.addLine'),
              onClick: this.fnAddComment
            } satisfies Properties
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this._maintenanceSimulaVenc = this._moduleMaintenanceService.build(MODULE_NAME_PCA_RH_SIMULACAO_VENCIMENTO);
    this._maintenanceRHRegistarEmp = this._moduleMaintenanceService.build(MODULE_NAME_RH_REGISTAR_EMP_SS);
    this.groupEstadoCivil = DATA_SOURCE_ESTADO_CIVIL.data.slice(1);
    this.groupSexos = DATA_SOURCE_SEXOS.data.slice(0, DATA_SOURCE_SEXOS.data.length - 1);
    this.groupMetodosCalcSalario = DATA_SOURCE_METODO_CALC_SALARIO.data.slice(1);
    this.groupRegimeTrabalho = DATA_SOURCE_REGIME_TRABALHO.data;
    this.groupTipoCalcDuodecimos = DATA_SOURCE_TIPO_CALC_DUODECIMOS.data;
    this.groupResidenciaFiscal = DATA_SOURCE_RESIDENCIA_FISCAL.data;
    this.filterPocs = `tipo=${ETipoContaContabilidade.Movimento}&bloqueada=false`;
    this.tooltipNumDependDef = {text: 'dgempsfull.fields.numDependDefLabel', placement: ['bottom', 'left']};
    this.tooltipFormulaCustoHoraEmpregado = {placement: ['left', 'bottom-left', 'bottom']};
    this.tooltipCalcularCustoHoraEmpregado = {text: 'dgempsfull.actions.calculateRemunHora', placement: ['left', 'bottom-left', 'bottom']};
    this.validatorNumSegSoc = {
      message: 'dgempsfull.errors.invalidNumSegSoc',
      validate: this._validateNumSegSoc.bind(this)
    };
    this.validatorDtFimContrato = {
      message: 'dgempsfull.errors.invalidDtFimContrat',
      validate: this._validateFimContrato.bind(this)
    };
    this.validatorDTNascimento = {
      message: 'dgempsfull.errors.invalidDTNascimento',
      validate: this._validateDataNascimento.bind(this)
    };
    this.maskHorasDia = {alias: 'datetime', inputFormat: 'HH:MM:ss'};
    this.maskHorasSemana = '999:99:99';
    this.maskHorasMes = '999:99:99';
    this.outputAbdes = '{{nCodABDESC}} - {{designacaoBreve}}';
    this.filterVencimento = `grupoAbonoCategoria=${ETipoCategoria.VENCIMENTO}`;
    this.filterSubsAlimentacao = `grupoAbonoCategoria=${ETipoCategoria.SUB_REFEICAO}|grupoAbonoCategoria=${ETipoCategoria.TicketValesRefeicao}`;
    this.filterDiuturnidades = `grupoAbonoCategoria=${ETipoCategoria.DIUTURNIDADE}`;
    this.filterSubsFerias = `grupoAbonoCategoria=${ETipoCategoria.SUB_FERIAS}`;
    this.filterSubsNatal = `grupoAbonoCategoria=${ETipoCategoria.SUB_NATAL}`;
    this.filterFerias = `grupoAbonoCategoria=${ETipoCategoria.FERIAS_PAGAS}`;
    this.filterIRS = `grupoDescontoCategoria=${ETipoCategoriaDesc.IRS}`;
    this.filterCRL = `AbonoDescDesativado=false&AbonoDesc=0`;
    this.hasDadosFicha = true;
    this.showOutdatedWarning = false;
    this.suggestRemunHora = false;
    this.appliedSuggestedRemunHora = false;
    this.custoHoraEmpregado = {
      meses: 0,
      semanas: 0,
      horasSemana: 0,
      abonos: [],
      total: 0
    };
    this._changedDHEMPPicture = false;
    this._deletedDHEMPPicture = false;
    this._datasDadosHistoricos = [];
    this._changedHorasSemana = false;
    this._changedHorasMes = false;
    this._subscriptionOnCancel = this.evtOnCancel().subscribe(() => {
      if (this._originalDHEMPPicture) {
        this.dhempPicture = this._originalDHEMPPicture;
        this._changedDHEMPPicture = false;
        this._deletedDHEMPPicture = false;
      }
    });
    this._historicoDados = new Map<string, IJsonDGEMPFull>();
    this._toolbarItemDatasHistoricoDados = {
      id: 'datashistoricodados',
      type: 'dropdown',
      order: this.btnDuplicate.order + 1,
      iconLeft: '<i class="fa fa-fw fa-calendar"></i>',
      class: 'btn-primary',
      caption: 'dgempsfull.text.datasHistoricoDadosCaption',
      visible: false,
      menu: []
    };
    this._debounceIdCalcularCustoHoraEmpregado = `${DEBOUNCE_ID_CALCULAR_CUSTO_HORA_EMPREGADO}-${debounceIdCalcularCustoHoraEmpregadoCount++}`;
    this.tipoContratoQuadroPessoal = ETipoContratoQuadroPessoal;
    setTimeout(() => {
      this._registerOnStart();
    });
    this.disabledValoresDuodecimos = true;
    this.valoresDuoDecimos = <Array<number>>Object.values(EDHEmpValoresDuodecimos).filter((value) => typeof value === 'number');
    this.promiseEstadoSS = undefined;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.propertiesCaptureImage = {
      resizeWidth: RESIZE_WIDTH,
      resizeHeight: RESIZE_HEIGHT,
      resizeMethod: 'crop',
      defaultFileName: this._translateService.instant('dgempsfull.text.fileNamePicture', {codEmp: this.model.codEmp}),
      videoWidth: MAX_WIDTH,
      uploadProperties: {maxFileSize: 2},
      resizeOverlay: true
    };
    this.modelEstadoSS = {
      infoEstadoSegSocial: ERHRegistarEmpregadosSSEmpSegSocialVinculo.NaoVerificado,
      infoEmpregadoRegistado: this._translateService.instant('dgempsfull.groups.estadoSS.naoVerificado')
    };
    this.model = merge(
      {},
      {
        apelido: undefined,
        biLocalEmissao: undefined,
        categoriaActual: undefined,
        codEmp: undefined,
        codPostal: undefined,
        codServicoPRH: undefined,
        concelho: undefined,
        designacaoBreveNacionalidad: undefined,
        distrito: undefined,
        dtAdmiSegSoc: undefined,
        dtBiValidade: undefined,
        dtNascimento: undefined,
        email: undefined,
        estadoCivil: undefined,
        freguesia: undefined,
        morada: undefined,
        nacionalidad: undefined,
        nbIdentidade: undefined,
        nCartaoTicketRef: undefined,
        nome: undefined,
        nomeCategoria: undefined,
        nomeConcelho: undefined,
        nomeDistrito: undefined,
        nomeFreguesia: undefined,
        nomeLocalida: undefined,
        nomeServicoPRH: undefined,
        nTelefone: undefined,
        numContrib: undefined,
        numSegSoc: undefined,
        residenciaFiscal: undefined,
        rua: undefined,
        sexo: undefined,
        sitEmpreg: undefined,
        situacao: undefined,
        telemovelPart1: undefined,
        ultRegisto: undefined,
        dhemp: {
          banco: undefined,
          caixaPrev: undefined,
          calcDuodecVariavel: false,
          categoria: undefined,
          ccusto: undefined,
          classeCaixa: undefined,
          classeTrab: undefined,
          codcct: undefined,
          codEmp: undefined,
          codNHABQPessoal: undefined,
          departamento: undefined,
          descricaoCodcct: undefined,
          descricaoCodRepCC: undefined,
          designaBreveCategoria: undefined,
          designaBreveClasseTrab: undefined,
          designaBreveDivisaoTrab: undefined,
          designaBreveFuncao: undefined,
          designaBreveNivQualific: undefined,
          designaBreveProfissao: undefined,
          designaBreveSeccao: undefined,
          designaBreveSitProfissao: undefined,
          designaBreveTipoContrato: undefined,
          designaComplNTabIrs: undefined,
          dhempId: undefined,
          divisaoTrab: undefined,
          dtAdmEmpresa: undefined,
          dtFimContrat: maxDateCG(),
          dataEfetividade: undefined,
          dtIniContrat: undefined,
          dtMotMov: undefined,
          eExResidente: false,
          funcao: undefined,
          horasDia: undefined,
          horasSemana: undefined,
          horasMes: undefined,
          iban: undefined,
          metodPaga: undefined,
          nCodRepCC: undefined,
          nDiuturnidad: 0,
          nivQualific: undefined,
          nomeBanco: undefined,
          nomeCaixaPrev: undefined,
          nomeCCusto: undefined,
          designaBreveClasseCaixa: undefined,
          nomeDepartamento: undefined,
          nomeNHABQPessoal: undefined,
          nomeNPocRemuLiq: undefined,
          nomeNSeguradAcTr: undefined,
          nomeSubDe: undefined,
          nomeZona: undefined,
          nPocRemuLiq: undefined,
          nSeguradAcTr: undefined,
          nSubDe: undefined,
          nTabIrs: undefined,
          numDepend: undefined,
          numDependDef: undefined,
          nZona: undefined,
          profissao: undefined,
          regimeTrabalho: EDHEmpRegimeTrabalho.Privado,
          registoNum: undefined,
          remunHora: undefined,
          seccao: undefined,
          sitProfissao: undefined,
          swift: undefined,
          temPreProcessamentoSubAlimen: false,
          temSegurAcTr: this.type === EEntityStateDetailType.NEW,
          temSubAlim: false,
          tipoCalcDuodecimos: EDHEmpTipoCalcDuodecimos.NaoEfectuaProcessamento,
          tipocontrato: undefined,
          valorSFerias: undefined,
          valorSNatal: undefined,
          valorSubAlim: undefined,
          valorUnDiutu: 0,
          vencimBase: undefined,
          conjugeIncapacidad60: false,
          deficienteForcasArma: false,
          temDescIRSTxFixa: false,
          percDescTaxaFixaIRS: undefined,
          outrosDados: {
            designaBreveABDiuturnidades: undefined,
            designaBreveABSubAlimentacao: undefined,
            designaBreveABSubFerias: undefined,
            designaBreveABSubNatal: undefined,
            designaBreveABVencimento: undefined,
            designaBreveDescIRS: undefined,
            designaBreveMotivoRegisto: undefined,
            designaBreveSituacaoEmprego: undefined,
            designaBreveTipoHorarioTrabalho: undefined,
            localTrabalho: undefined,
            motivoRegisto: undefined,
            nCodABDiuturnidades: undefined,
            nCodABSubAlimentacao: undefined,
            nCodABSubFerias: undefined,
            nCodABSubNatal: undefined,
            nCodABVencimento: undefined,
            nCodDescIRS: undefined,
            nDiasTemDireitoFerias: undefined,
            nDiasTemDireitoNatal: undefined,
            nMaxDiasUteisFerias: undefined,
            nMaxDiasUteisNatal: undefined,
            nomeLocalTrabalho: undefined,
            residenciaFiscal: undefined,
            situacaoEmprego: undefined,
            tipoHorarioTrabalho: undefined,
            nCodABFerias: undefined,
            designaBreveABFerias: undefined,
            temRendJovensTrab: false,
            dtIniRendJovensTrab: minDateCG(),
            temRendResNaoHabit: false,
            dtIniRendResNaoHabit: minDateCG()
          },
          usaDuodecimosPorEmp: false,
          duodecimosSubNatal: 0,
          duodecimosSubFerias: 0,
          temRedIrsCredHabitac: false,
          temRedCtrArrendameHP: false,
          datasDadosHistoricos: []
        }
      },
      this.model
    );

    this.model.sindicato = this.model.sindicato === 0 ? undefined : this.model.sindicato;

    if (isObject(this.defaultData)) {
      if (isObject(this.defaultData.defaultOutrosDados)) {
        this.model.dhemp.outrosDados = merge({}, this.defaultData.defaultOutrosDados, this.model.dhemp.outrosDados);
      }
      if (isBoolean(this.defaultData.hasDadosFicha)) {
        this.hasDadosFicha = this.defaultData.hasDadosFicha;
      }
      if (isBoolean(this.defaultData.hasRequiredFields)) {
        this.hasRequiredFields = this.defaultData.hasRequiredFields;
      }
      this.requiredCategoria = this.defaultData.requiredFields.categoria;
      this.requiredCentroCusto = this.defaultData.requiredFields.centroCusto;
      this.requiredClasseTrab = this.defaultData.requiredFields.classeTrab;
      this.requiredCCT = this.defaultData.requiredFields.codCCT;
      this.requiredZona = this.defaultData.requiredFields.codZona;
      this.requiredDepartamento = this.defaultData.requiredFields.departamento;
      this.requiredFuncao = this.defaultData.requiredFields.funcao;
      this.requiredIntegraRU = this.defaultData.requiredFields.integraRU;
      this.requiredIntegraSalarios = this.defaultData.requiredFields.integraSalarios;
      this.requiredNivelQualif = this.defaultData.requiredFields.nivelQualif;
      this.requiredOperacaoSeccao = this.defaultData.requiredFields.operacaoSeccao;
      this.requiredPocRemuLiq = this.defaultData.requiredFields.pocRemuLiq;
      this.requiredProfissao = this.defaultData.requiredFields.profissao;
      this.requiredReparticaoCentroCusto = this.defaultData.requiredFields.reparticaoCentroCusto;
      this.requiredSituacaoProfissao = this.defaultData.requiredFields.situacaoProfissao;
      this.requiredSubDepartamento = this.defaultData.requiredFields.subDepartamento;
    }

    if (!this.model.dhemp.dtMotMov) {
      this.model.dhemp.dtMotMov = moment().startOf('day');
    }
    if (!isNumber(this.model.dhemp.horasDia)) {
      this.model.dhemp.horasDia = 0;
    }
    if (!isNumber(this.model.dhemp.horasSemana)) {
      this.model.dhemp.horasDia = 0;
    }
    if (!isNumber(this.model.dhemp.horasMes)) {
      this.model.dhemp.horasDia = 0;
    }
    if (!isNumber(this.model.dhemp.numDepend)) {
      this.model.dhemp.numDepend = 0;
    }
    if (!isNumber(this.model.dhemp.numDependDef)) {
      this.model.dhemp.numDependDef = 0;
    }
    if (!isNumber(this.model.dhemp.valorSFerias)) {
      this.model.dhemp.valorSFerias = 0;
    }
    if (!isNumber(this.model.dhemp.valorSNatal)) {
      this.model.dhemp.valorSNatal = 0;
    }
    if (!isNumber(this.model.dhemp.valorSubAlim)) {
      this.model.dhemp.valorSubAlim = 0;
    }
    if (!isNumber(this.model.dhemp.valorUnDiutu)) {
      this.model.dhemp.valorUnDiutu = 0;
    }
    if (!isNumber(this.model.dhemp.metodPaga)) {
      this.model.dhemp.metodPaga = EDHEmpMetodoCalcSalario.Vencimento;
    }
    if (!isNumber(this.model.dhemp.outrosDados.nMaxDiasUteisFerias) || this.model.dhemp.outrosDados.nMaxDiasUteisFerias === 0) {
      this.model.dhemp.outrosDados.nMaxDiasUteisFerias = DEFAULT_DIAS_UTEIS;
    }
    if (!isNumber(this.model.dhemp.outrosDados.nDiasTemDireitoFerias)) {
      this.model.dhemp.outrosDados.nDiasTemDireitoFerias = 0;
    }
    if (!isNumber(this.model.dhemp.outrosDados.nMaxDiasUteisNatal) || this.model.dhemp.outrosDados.nMaxDiasUteisNatal === 0) {
      this.model.dhemp.outrosDados.nMaxDiasUteisNatal = DEFAULT_MAX_DIAS_UTEIS;
    }
    if (!isNumber(this.model.dhemp.outrosDados.nDiasTemDireitoNatal)) {
      this.model.dhemp.outrosDados.nDiasTemDireitoNatal = 0;
    }
    if (!isNumber(this.model.dhemp.outrosDados.residenciaFiscal)) {
      this.model.dhemp.outrosDados.residenciaFiscal = EResidenciaFiscal.ResidePT;
    }
    if (!isBoolean(this.model.dhemp.usaDuodecimosPorEmp) || !isDefinedNotNull(this.model.dhemp.usaDuodecimosPorEmp)) {
      this.model.dhemp.usaDuodecimosPorEmp = false;
    }
    if (!isNumber(this.model.dhemp.duodecimosSubNatal)) {
      this.model.dhemp.duodecimosSubNatal = 0;
    }
    if (!isNumber(this.model.dhemp.duodecimosSubFerias)) {
      this.model.dhemp.duodecimosSubFerias = 0;
    }

    if (this.type === EEntityStateDetailType.NEW) {
      this.model.dhemp.horasDia = this._durationToModel(moment.duration(DEFAULT_WORK_HOURS, 'hours'));
      this.model.dhemp.horasSemana = this._durationToModel(moment.duration(Math.floor(DEFAULT_WORK_HOURS * DEFAULT_WORK_DAYS), 'hours'));
      this.model.dhemp.horasMes = this._durationToModel(moment.duration(Math.floor((DEFAULT_WORK_HOURS * DEFAULT_WORK_DAYS * DEFAULT_WORK_WEEKS) / DEFAULT_WORK_MONTHS), 'hours'));
    }

    if (this.model.dhemp.horasDia) {
      this.horasDia = this._modelToHoras(this.model.dhemp.horasDia, false);
    }
    if (this.model.dhemp.horasSemana) {
      this.horasSemana = this._modelToHoras(this.model.dhemp.horasSemana);
    }
    if (this.model.dhemp.horasMes) {
      this.horasMes = this._modelToHoras(this.model.dhemp.horasMes);
    }

    this._getValoresDuoDecimosEmpresa();

    if (this.type !== EEntityStateDetailType.NEW) {
      if (!isNumber(this.model.sexo)) {
        this.model.sexo = ESexo.Feminino;
      }

      this._initHistoricoDados(this.model);

      // Load picture
      this.promiseDHEMPPicture = this._getDHEMPPicture(this.model.codEmp).then((value: File) => {
        this.dhempPicture = value;
        this._changedDHEMPPicture = false;
        this._deletedDHEMPPicture = false;
        if (!this._originalDHEMPPicture) {
          this._originalDHEMPPicture = this.dhempPicture;
        }
      });

      this._estadoSSVerificar(true).then();
    }
    this.disabledValoresDuodecimos = !this.model.dhemp.usaDuodecimosPorEmp;

    this.toolbar.addButton(this._toolbarItemDatasHistoricoDados);
    this.toolbar.addButton({
      id: 'toolbar-config-ws-button',
      type: 'button',
      order: this._toolbarItemDatasHistoricoDados.order + 1,
      iconLeft: '<i class="fa fa-fw fa-cog"></i>',
      class: 'btn-secondary',
      caption: 'dgempsfull.btn.configWSS',
      tooltip: {
        text: 'dgempsfull.text.configWSS'
      },
      visible: this.type !== EEntityStateDetailType.NEW,
      click: () => this._configWSButtonClick()
    });
    this._evaluateFilterTirs();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionOnCancel.unsubscribe();
    this._deRegisterOnStart();
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    if (!this._dataGridInstance) {
      this.dataGridDefinition.toolbar.items = [
        {
          location: 'after',
          widget: 'dxButton',
          name: 'addComment',
          disabled: stateType !== EEntityStateDetailType.EDIT,
          options: {
            icon: 'add',
            hint: this._translateService.instant('global.btn.addLine'),
            onClick: this.fnAddComment
          } satisfies Properties
        },
        'exportButton',
        'columnChooserButton'
      ];
    } else {
      this._dataGridInstance.option('toolbar.items[0].disabled', stateType !== EEntityStateDetailType.EDIT);
    }

    if (stateType === EEntityStateDetailType.EDIT) {
      this.showOutdatedWarning = false;
      this._selectLatestItemHistoricoDados();
      if (this._latestDGEMP) {
        this.model = copy<IJsonDGEMPFull>(this._latestDGEMP);
      }
    }
    return super.onUpdate(stateType).then(() => {
      this._toolbarItemDatasHistoricoDados.visible = stateType === EEntityStateDetailType.DETAIL && this._toolbarItemDatasHistoricoDados.menu.length > 1;
    });
  }

  public save(): Promise<IJsonDGEMPFull> {
    this._prepareModel(this.model);
    let updateMode: EDGEMPSSaveCadastroMode = EDGEMPSSaveCadastroMode.None;
    // Check if DHEMP changed
    let promise: Promise<EDGEMPSSaveCadastroMode>;
    if (this.type === EEntityStateDetailType.EDIT && !isEqual(this.model.dhemp, this._originalDHEMP)) {
      const modalInstance: NgbModalRef = this._cgModalService.showVanilla(DGEMPSFullSaveCadastroModalComponent);
      const componentInstance: DGEMPSFullSaveCadastroModalComponent = modalInstance.componentInstance;
      componentInstance.dhemp = this.model.dhemp;
      componentInstance.latestHistoricoDadosKey = this._latestHistoricoDadosKey;
      promise = modalInstance.result;
    }
    return new Promise<IJsonDGEMPFull>((resolve, reject) => {
      Promise.resolve(promise)
        .then((saveCadastroMode: EDGEMPSSaveCadastroMode) => {
          updateMode = saveCadastroMode || EDGEMPSSaveCadastroMode.UpdateLatestCadastro;
          const config: IApiRequestConfigWithBody<IJsonDGEMPFull> = {params: {updatemode: updateMode}};
          super
            .save(config)
            .then((response: IJsonDGEMPFull) => {
              this.horasDia = this._modelToHoras(response.dhemp.horasDia, false);
              this.horasSemana = this._modelToHoras(response.dhemp.horasSemana);
              this.horasMes = this._modelToHoras(response.dhemp.horasMes);
              this._initHistoricoDados(response);
              if (this._previousType === EEntityStateDetailType.EDIT && this._deletedDHEMPPicture) {
                this.service.deleteFoto(response.codEmp).finally(() => {
                  resolve(response);
                });
              } else if (this._changedDHEMPPicture && this.dhempPicture) {
                this.service.setFoto(response.codEmp, this.dhempPicture).finally(() => {
                  resolve(response);
                });
              } else {
                resolve(response);
              }
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  public beforeDelete(): Promise<void | IApiRequestConfig> {
    if (!this._originalDHEMP || this._originalDHEMP.datasDadosHistoricos.length <= 1) {
      return super.beforeDelete();
    }
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(DGEMPSFullEditDeletePromptModalComponent);
    const componentInstance: DGEMPSFullEditDeletePromptModalComponent = modalInstance.componentInstance;
    const dgemp: IJsonDGEMPFull = this._latestDGEMP ? this._latestDGEMP : this.model;
    componentInstance.codEmp = dgemp.codEmp;
    return modalInstance.result.then((apenasUltimoHistorico: boolean) => {
      return {params: {apenasultimohistorico: apenasUltimoHistorico}};
    });
  }

  public afterDelete(response: HttpResponse<IJsonDGEMPFull>): Promise<void> {
    if (!isObject(response.body)) {
      return super.afterDelete(response);
    }
    this.model = response.body;
    this._initHistoricoDados(this.model);
    return Promise.resolve();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public configureOutrosDados(): Promise<void> {
    if (this.hasDadosFicha) {
      return Promise.resolve();
    }
    return this._cgStateService.redirectToState({stateOrName: MODULE_NAME_RH_CONFIGURACOES_DADOS_CONFIG_FICHA});
  }

  public changedNome(value: string): void {
    this.model.nome = value.trim();
    if (!this.model.nome) {
      this.model.apelido = undefined;
    } else {
      const nomes: Array<string> = this.model.nome.split(' ');
      this.model.apelido = nomes[nomes.length - 1];
    }
  }

  public dtMotMovChanged(value: TDate): void {
    this.model.dhemp.dtMotMov = value;
    if (value) {
      this._evaluateFilterTirs();
    }
  }

  public changedPicture(value: File): void {
    this.dhempPicture = value;
    this._changedDHEMPPicture = true;
    if (!this.dhempPicture && this._originalDHEMPPicture) {
      this._deletedDHEMPPicture = true;
    }
  }

  public changedVencimBase(value: number): Promise<void> {
    this.model.dhemp.vencimBase = value;
    this.model.dhemp.valorSFerias = this.model.dhemp.vencimBase;
    this.model.dhemp.valorSNatal = this.model.dhemp.vencimBase;
    this.promiseBtnCalc = this.calcularCustoHoraEmpregado();
    return this.promiseBtnCalc;
  }

  public simulaValorVencimento(): void {
    this._maintenanceSimulaVenc.maintenance({params: {dgemp: this.model}});
  }

  public changedHorasDia(value: string): void {
    this.horasDia = value;
    const durationHorasDia: Duration = this._horasToModel(this.horasDia);
    this.model.dhemp.horasDia = this._durationToModel(durationHorasDia);
    if (this.model.dhemp.horasDia && (!this.model.dhemp.horasSemana || !this._changedHorasSemana)) {
      const dailyHours: number = durationHorasDia.asHours();
      const weeklySeconds: number = this._durationToModel(moment.duration(Math.floor(dailyHours * DEFAULT_WORK_DAYS), 'hours'));
      this.changedHorasSemana(this._modelToHoras(weeklySeconds), false);
    }
    this._calcularCustoHoraEmpregadoDebounced();
  }

  public changedHorasSemana(value: string, asUser: boolean = true): void {
    this.horasSemana = value;
    const durationHorasSemana: Duration = this._horasToModel(this.horasSemana);
    this.model.dhemp.horasSemana = this._durationToModel(durationHorasSemana);
    if (!this.model.dhemp.horasSemana) {
      this._changedHorasSemana = false;
    } else if (!this.model.dhemp.horasMes || !this._changedHorasMes) {
      const weeklyHours: number = durationHorasSemana.asHours();
      const monthlySeconds: number = this._durationToModel(moment.duration(Math.floor((weeklyHours * DEFAULT_WORK_WEEKS) / DEFAULT_WORK_MONTHS), 'hours'));
      this.changedHorasMes(this._modelToHoras(monthlySeconds), false);
    }
    if (this.model.dhemp.horasSemana && asUser) {
      this._changedHorasSemana = true;
    }
    this._calcularCustoHoraEmpregadoDebounced();
  }

  public changedHorasMes(value: string, asUser: boolean = true): void {
    this.horasMes = value;
    const durationHorasMes: Duration = this._horasToModel(this.horasMes);
    this.model.dhemp.horasMes = this._durationToModel(durationHorasMes);
    if (!this.model.dhemp.horasMes) {
      this._changedHorasMes = false;
    } else if (asUser) {
      this._changedHorasMes = true;
    }
    this._calcularCustoHoraEmpregadoDebounced();
  }

  public changedRemunHora(value: number): void {
    this.model.dhemp.remunHora = value;
    this.suggestRemunHora = Boolean(isDefinedNotNull(this.custoHoraEmpregado.total) && this.model.dhemp.remunHora !== this.custoHoraEmpregado.total);
  }

  public changedCodigoPostal(value: string | IJsonCodPostal): void {
    this.model.codPostal = isString(value) ? value : value.codPostal;
    this._externalCodPostaisService.get(this.model.codPostal).then((response: Array<IJsonCodPostalHelper>) => {
      if (response?.length) {
        const morada: IJsonCodPostalHelper = response[0];
        this.model.morada = morada.rua;
        this.model.nomeLocalida = morada.designacao;
      }
    });
  }

  public changedConcelho(value: string | IJsonConcelho): void {
    if (isObject(value)) {
      const concelho = <IJsonConcelho>value;
      this.model.distrito = concelho.codDistrito;
      this.model.nomeDistrito = concelho.nomeDistrito;
    }
  }

  public changedCategoria(value: IJsonCateg): void {
    if (isObject(value)) {
      this.model.dhemp.categoria = value.nCateg;
    } else {
      this.model.dhemp = {...this.model.dhemp};
    }
  }

  public changedTipoContrato(value: IJsonTiposContrato): void {
    this.model.dhemp = {
      ...this.model.dhemp,
      tipocontrato: value.nTipoContrato,
      designaBreveTipoContrato: value.designacaoBreve,
      tipocontratoCtQPessoal: value.codTipCtQPessoal
    };
    switch (value.codTipCtQPessoal) {
      case ETipoContratoQuadroPessoal.TermoCerto:
      case ETipoContratoQuadroPessoal.PrestacaoSubordinadaTeletrabalhoTermoCerto:
      case ETipoContratoQuadroPessoal.ComissaoServicoTermoCerto:
      case ETipoContratoQuadroPessoal.TemporarioTermoCerto:
        if (moment(this.model.dhemp.dtFimContrat).isSame(maxDateCG(), 'day')) {
          this.model.dhemp.dtFimContrat = undefined;
        }
        break;
      case ETipoContratoQuadroPessoal.SemTermo:
      case ETipoContratoQuadroPessoal.PrestacapoSubordinadaTeletrabalhoSemTermo:
      case ETipoContratoQuadroPessoal.ComissaoServicoSemTermo:
      case ETipoContratoQuadroPessoal.IntermitenteSemTermo:
      case ETipoContratoQuadroPessoal.TempoIndeterminadoCedenciaTemporaria:
      case ETipoContratoQuadroPessoal.TermoIncerto:
      case ETipoContratoQuadroPessoal.PrestacaoSubordinadaTeletrabalhoTermoIncerto:
      case ETipoContratoQuadroPessoal.ComissaoServicoTermoIncerto:
      case ETipoContratoQuadroPessoal.TemporarioTermoIncerto:
      case ETipoContratoQuadroPessoal.OutraSituacao:
        if (isEmpty(this.model.dhemp.dtFimContrat)) {
          this.model.dhemp.dtFimContrat = maxDateCG();
        }
        break;
    }
  }

  public changedDtIniContrat(value: TDate): void {
    this.model.dhemp.dtIniContrat = value;
    const formControlDtFimContrat: AbstractControl = this.formInstance.controls.dtFimContrat;
    if (formControlDtFimContrat) {
      formControlDtFimContrat.updateValueAndValidity();
    }
  }

  public changeUsaDuodecimosPorEmp(value: boolean): void {
    this.model.dhemp.usaDuodecimosPorEmp = value;
    this.disabledValoresDuodecimos = !value;
  }

  public changedDuoDecimosSubNatal(value: number): void {
    if (!isNumber(value)) {
      this.model.dhemp.duodecimosSubNatal = 0;
    } else {
      this.model.dhemp.duodecimosSubNatal = value;
    }
  }

  public changedDuoDecimosSubFerias(value: number): void {
    if (!isNumber(value)) {
      this.model.dhemp.duodecimosSubFerias = 0;
    } else {
      this.model.dhemp.duodecimosSubFerias = value;
    }
  }

  public applySuggestedRemunHora(): void {
    this.appliedSuggestedRemunHora = false;
    if (this.suggestRemunHora && this.custoHoraEmpregado.total) {
      this.model.dhemp.remunHora = this.custoHoraEmpregado.total;
      this.suggestRemunHora = false;
      this.appliedSuggestedRemunHora = true;
    }
  }

  public valuesForCalcCustoHoraEmpChanged(): Promise<void> {
    this.promiseBtnCalc = this.calcularCustoHoraEmpregado();
    return this.promiseBtnCalc;
  }

  public calcularCustoHoraEmpregado(): Promise<void> {
    const calcularCustoHoraEmpregado: IJsonDHEMPCalcularCustoHoraEmpregado = {
      horasSemana: this._horasToModel(this.horasSemana).asHours(),
      nCodABVencimento: this.model.dhemp.outrosDados.nCodABVencimento,
      nCodABDiuturnidades: this.model.dhemp.outrosDados.nCodABDiuturnidades,
      vencimBase: this.model.dhemp.vencimBase,
      nDiuturnidad: this.model.dhemp.nDiuturnidad,
      valorUnDiutu: this.model.dhemp.valorUnDiutu,
      dtMotMov: this.model.dhemp.dtMotMov
    };
    return this.service.calcularCustoHoraEmpregado(this.model.dhemp.codEmp || 0, calcularCustoHoraEmpregado, false).then((response: HttpResponse<IJsonDHEMPCustoHoraEmpregado>) => {
      this.suggestRemunHora = Boolean(isDefinedNotNull(this.custoHoraEmpregado.total) && this.model.dhemp.remunHora !== response.body.total);
      this.custoHoraEmpregado = response.body;
      this.applySuggestedRemunHora();
    });
  }

  public temDescIRSTxFixaChanged(value: boolean): void {
    this.model.dhemp.temDescIRSTxFixa = value;
    if (!value) {
      this.model.dhemp.percDescTaxaFixaIRS = 0;
    }
  }

  public onIbanChanged(value: string): Promise<void> {
    return this.service.get<string>({id: `trfsepa/iban/${value}/swift`}).then((response: HttpResponse<string>) => {
      this.model.dhemp.swift = response.body;
      this.model.dhemp.iban = value;
    });
  }

  public getEstadoSSCssClass(): string {
    if (this.modelEstadoSS.infoEstadoSegSocial === ERHRegistarEmpregadosSSEmpSegSocialVinculo.SemVinculo) {
      return 'text-danger';
    } else if (this.modelEstadoSS.infoEstadoSegSocial === ERHRegistarEmpregadosSSEmpSegSocialVinculo.Admitido) {
      return 'text-success';
    } else if (this.modelEstadoSS.infoEstadoSegSocial === ERHRegistarEmpregadosSSEmpSegSocialVinculo.Cessado) {
      return 'text-warning';
    }
    return 'text-info';
  }

  public getEstadoSSInfoIcon(): string {
    if (this.modelEstadoSS.infoEstadoSegSocial === ERHRegistarEmpregadosSSEmpSegSocialVinculo.SemVinculo) {
      return 'fa-circle';
    } else if (this.modelEstadoSS.infoEstadoSegSocial === ERHRegistarEmpregadosSSEmpSegSocialVinculo.Admitido) {
      return 'fa-check-circle';
    } else if (this.modelEstadoSS.infoEstadoSegSocial === ERHRegistarEmpregadosSSEmpSegSocialVinculo.Cessado) {
      return 'fa-ban';
    }
    return 'fa-exclamation-triangle';
  }

  public getEstadoSSInfoText(): string {
    return this.modelEstadoSS.infoEmpregadoRegistado.length
      ? this.modelEstadoSS.infoEmpregadoRegistado.replace(/\r\n|\r|\n/g, ' ')
      : this._translateService.instant('rhregistarempregadosss.strings.naoVerificado');
  }

  public async openSimuladorTaxaFixaIRS(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DgempsfullSimulaTaxaFixaIrsModalComponent, {size: 'lg'});
    const componentInstance: DgempsfullSimulaTaxaFixaIrsModalComponent = modalInstance.componentInstance;
    componentInstance.codEmp = this.model.dhemp.codEmp;
    this.model.dhemp.percDescTaxaFixaIRS = await modalInstance.result;
    return Promise.resolve();
  }

  public readonly fnIsDateDisabled: (date: Moment) => boolean = (date: Moment) => this._isDateDisabled(date);

  public readonly fnIsDateMarked: (date: Moment) => boolean = (date: Moment) => this._isDateMarked(date);

  public readonly fnIsDtFimContratDateDisabled: (dateToCheck: Moment) => boolean = (dateToCheck: Moment) => this._isDtFimContratDateDisabled(dateToCheck);

  public readonly fnAddComment = (): Promise<void> => this._addComment();

  public readonly fnEditComment = (item: IJsonDGEMPComentario) => (): Promise<void> => this._editComment(item);

  public readonly fnRemoveComment = (item: IJsonDGEMPComentario) => (): Promise<void> => this._removeComment(item);

  public readonly fnEstadoSSVerificar = (): Promise<void> => this._estadoSSVerificar();

  public readonly fnEstadoSSRegistar = (): Promise<void> => this._estadoSSRegistar();

  public readonly fnEstadoSSConsultar = (): Promise<void> => this._estadoSSConsultar();

  public readonly fnDeleteFoto = (): Promise<void> => this._deleteFoto();

  private async _deleteFoto(): Promise<void> {
    await this.service.deleteFoto(this.model.codEmp).then(() => {
      this.changedPicture(undefined);
    });
  }

  private _registerOnStart(): void {
    this._deRegisterOnStart();
    const criteria: HookMatchCriteria = {
      to: (state: StateObject, transition: Transition) => {
        const toState: StateDeclaration = transition.to();
        return transition.from() !== toState && toState.name !== STATE_NAME_DISCONNECTED;
      }
    };
    this._deRegisterOnStartFn = this._transitionService.onStart(criteria, () => this._navigationSafeGuard());
  }

  private _deRegisterOnStart(): void {
    if (this._deRegisterOnStartFn) {
      this._deRegisterOnStartFn();
      this._deRegisterOnStartFn = undefined;
    }
  }

  private _navigationSafeGuard(): Promise<void> {
    if (!isTest() && !isEqual(this.model.dhemp, this._originalDHEMP)) {
      return this._cgModalService.showOkCancel('dgempsfull.prompt.exitTitle', 'dgempsfull.prompt.exitMessage');
    }
    return Promise.resolve();
  }

  private _initHistoricoDados(modelValue: IJsonDGEMPFull): void {
    this._prepareModel(modelValue);
    this._latestDGEMP = copy<IJsonDGEMPFull>(modelValue);
    this._originalDHEMP = this._latestDGEMP.dhemp;
    this._latestHistoricoDadosKey = this._dgempsService.normalizeDataCadastro(this._originalDHEMP.dtMotMov).toISOString();

    const fnOnClickItemHistoricoDados = this._onClickItemHistoricoDados.bind(this);
    this._datasDadosHistoricos = [];
    this._toolbarItemDatasHistoricoDados.menu = this._originalDHEMP.datasDadosHistoricos.map<IPlToolbarMenuItem<Moment>>((value: TDate, index: number) => {
      const momentValue: Moment = this._dgempsService.normalizeDataCadastro(value);
      this._datasDadosHistoricos.push(momentValue.toISOString());
      let formattedValue: string = this._plI18nService.formatDate(momentValue);
      if (index === 0) {
        formattedValue += ` (${<string>this._translateService.instant('dgempsfull.text.datasHistoricoDadosCurrent').toLowerCase()})`;
      }
      return {
        groupId: 'groupdatashistoricodados',
        type: 'button',
        iconLeft: '<i class="fa fa-fw fa-calendar"></i>',
        caption: formattedValue,
        data: momentValue,
        click: fnOnClickItemHistoricoDados
      };
    });
    if (this._toolbarItemDatasHistoricoDados.menu.length > 1) {
      if (this.type === EEntityStateDetailType.DETAIL) {
        this._toolbarItemDatasHistoricoDados.visible = true;
      }
      this._selectLatestItemHistoricoDados();
    } else if (this._toolbarItemDatasHistoricoDados.visible) {
      this._toolbarItemDatasHistoricoDados.visible = false;
    }
  }

  private _prepareModel(value: IJsonDGEMPFull): void {
    if (!value.dhemp.temSubAlim) {
      delete value.dhemp.valorSubAlim;
      delete value.dhemp.temPreProcessamentoSubAlimen;
    }
    if (!value.dhemp.temSegurAcTr) {
      delete value.dhemp.nSeguradAcTr;
      delete value.dhemp.nomeNSeguradAcTr;
    }
  }

  private _modelToHoras(value: number, long: boolean = true): string {
    if (isNumber(value) && value > 0) {
      const duration: Duration = this._modelToDuration(value);
      let hours: string = Math.floor(duration.asHours()).toString();
      if (long) {
        if (hours.length === 1) {
          hours = `00${hours}`;
        } else if (hours.length === 2) {
          hours = `0${hours}`;
        }
      } else if (hours.length === 1) {
        hours = `0${hours}`;
      }
      let minutes: string = duration.minutes().toString();
      if (minutes.length === 1) {
        minutes = `0${minutes}`;
      }
      let seconds: string = duration.seconds().toString();
      if (seconds.length === 1) {
        seconds = `0${seconds}`;
      }
      return `${hours}:${minutes}:${seconds}`;
    }
    return undefined;
  }

  private _horasToModel(value: string): Duration {
    if (isEmpty(value)) {
      return moment.duration();
    }
    const durations: Array<string> = value.split(':');
    const hours: number = durations.length > DURATION_INDEX_HOURS + 1 ? toInteger(durations[DURATION_INDEX_HOURS]) || 0 : 0;
    const minutes: number = durations.length > DURATION_INDEX_MINUTES + 1 ? toInteger(durations[DURATION_INDEX_MINUTES]) || 0 : 0;
    const seconds: number = durations.length > DURATION_INDEX_SECONDS + 1 ? toInteger(durations[DURATION_INDEX_SECONDS]) || 0 : 0;
    return moment.duration({
      hours: hours,
      minutes: minutes,
      seconds: seconds
    });
  }

  private _modelToDuration(value: number): Duration {
    return moment.duration(value, 'seconds');
  }

  private _durationToModel(value: Duration): number {
    return value.asSeconds();
  }

  private _getDHEMPPicture(codEmp: number): Promise<File> {
    return this.service.getFoto(codEmp, {reportExceptions: false}).then((response: HttpResponse<File>) => {
      if (response.status === EStatusCode.OK) {
        return response.body;
      }
      return undefined;
    });
  }

  private _getValoresDuoDecimosEmpresa(): Promise<void> {
    return this._rhConfiguracoesService.getProcessamentoSalarios().then((response: HttpResponse<IJsonProcessamentoSalariosConfig>) => {
      this.empresaDuodecimosSubNatal = response.body.processamentoSalariosDuodecimos.percentagemEmSubNatal;
      this.empresaDuodecimosSubFerias = response.body.processamentoSalariosDuodecimos.percentagemEmSubFerias;
    });
  }

  private _onClickItemHistoricoDados(item: IPlToolbarMenuItem<Moment>): void {
    if (this.type !== EEntityStateDetailType.DETAIL || item === this._selectedItemHistoricoDados) {
      return;
    }
    this._changedHistoricoDados(item.data)
      .then(() => {
        this._selectItemHistoricoDados(item);
      })
      .catch((reason: HttpErrorResponse) => {
        this._logger.error(reason);
      });
  }

  private _changedHistoricoDados(dataCadastro: Moment): Promise<void> {
    // Default timeout as promise so the users notice the model change
    let promise: Promise<void> = timeout(DEFAULT_TIMEOUT);
    const dataCadastroKey = dataCadastro.toISOString();
    this.showOutdatedWarning = dataCadastroKey !== this._latestHistoricoDadosKey;
    if (dataCadastroKey === this._latestHistoricoDadosKey) {
      this.model = copy<IJsonDGEMPFull>(this._latestDGEMP);
    } else if (this._historicoDados.has(dataCadastroKey)) {
      const cachedDGEMP: IJsonDGEMPFull = this._historicoDados.get(dataCadastroKey);
      this.model = copy<IJsonDGEMPFull>(cachedDGEMP);
    } else {
      promise = this.service.getFull({id: this.model.codEmp, params: {datacadastro: dataCadastro}}).then((response: HttpResponse<IJsonDGEMPFull>) => {
        const dgempToCache = response.body;
        this._historicoDados.set(dataCadastroKey, dgempToCache);
        this.model = copy<IJsonDGEMPFull>(dgempToCache);
      });
    }
    this._appService.setGlobalLoading(promise);
    return promise;
  }

  private _selectItemHistoricoDados(item: IPlToolbarMenuItem<Moment>): void {
    if (this.type !== EEntityStateDetailType.DETAIL || item === this._selectedItemHistoricoDados) {
      return;
    }
    if (this._selectedItemHistoricoDados) {
      this._selectedItemHistoricoDados.active = false;
      this._selectedItemHistoricoDados = undefined;
    }
    this._selectedItemHistoricoDados = item;
    this._selectedItemHistoricoDados.active = true;
  }

  private _selectLatestItemHistoricoDados(): void {
    if (this.type === EEntityStateDetailType.DETAIL && this._toolbarItemDatasHistoricoDados.menu.length) {
      this._selectItemHistoricoDados(this._toolbarItemDatasHistoricoDados.menu[0]);
    }
  }

  private _isDateDisabled(date: Moment): boolean {
    if (this.type === EEntityStateDetailType.NEW || this._datasDadosHistoricos.length === 1) {
      return false;
    }

    return this._dgempsService.isDateDisabled(date, this._datasDadosHistoricos[1]);
  }

  private _isDateMarked(date: Moment): boolean {
    if (this.type === EEntityStateDetailType.NEW) {
      return false;
    }
    return this._dgempsService.isDateMarked(date, this._datasDadosHistoricos);
  }

  private _isDtFimContratDateDisabled(dateToCheck: Moment): boolean {
    return !isEmpty(this.model.dhemp.dtIniContrat) && !isEmpty(dateToCheck) && moment(dateToCheck).isBefore(this.model.dhemp.dtIniContrat, 'day');
  }

  private _validateNumSegSoc({modelValue}: IPlValidatorValidateParams<string>): boolean {
    return isEmpty(modelValue) || new RegExp('^[0-9]+$').test(modelValue);
  }

  private _validateFimContrato({modelValue}: IPlValidatorValidateParams<string, TDate>): boolean {
    return isEmpty(this.model.dhemp.dtIniContrat) || (!isEmpty(modelValue) && moment(this.model.dhemp.dtIniContrat).isSameOrBefore(modelValue, 'day'));
  }

  private _validateDataNascimento({modelValue}: IPlValidatorValidateParams<string, TDate>): boolean {
    let isDateValid = true;
    if (!isEmpty(modelValue)) {
      const years = moment().diff(modelValue, 'years');
      isDateValid = !(years < DEFAULT_MIN_AGE || years > DEFAULT_MAX_AGE);
    }
    return isDateValid;
  }

  private _calcularCustoHoraEmpregadoDebounced(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      debounce(
        () => {
          this.calcularCustoHoraEmpregado().then(resolve).catch(reject);
        },
        DEBOUNCE_TIME_CALCULAR_CUSTO_HORA_EMPREGADO,
        this._debounceIdCalcularCustoHoraEmpregado
      );
    });
  }

  private _addComment(): Promise<void> {
    const commentInstance: NgbModalRef = this._cgModalService.showVanilla(DGEMPSFullCommentModalComponent);
    return commentInstance.result.then((comment: IJsonDGEMPComentario) => {
      this.service.postComentarioDGEMP({body: comment, params: {codemp: this.model.codEmp}}).then((response: HttpResponse<Array<IJsonDGEMPComentario>>) => {
        this.model.comentarios = response.body;
      });
    });
  }

  private _editComment(item: IJsonDGEMPComentario): Promise<void> {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(DGEMPSFullCommentModalComponent);
    const componentInstance: DGEMPSFullCommentModalComponent = modalInstance.componentInstance;
    componentInstance.comentario = copy(item);
    return modalInstance.result.then((comment: IJsonDGEMPComentario) => {
      this.service.putComentarioDGEMP({id: this.model.codEmp, body: comment}).then((response: HttpResponse<Array<IJsonDGEMPComentario>>) => {
        this.model.comentarios = response.body;
      });
    });
  }

  private _removeComment(item: IJsonDGEMPComentario): Promise<void> {
    return this.service.deleteComentarioDGEMP({id: this.model.codEmp, params: {commentID: item.comentarioID}}).then(() => {
      const index = this.model.comentarios.findIndex((value: IJsonDGEMPComentario) => value.comentarioID === item.comentarioID);
      if (index !== -1) {
        this.model.comentarios.splice(index, 1);
      }
    });
  }

  private _estadoSSVerificar(silent: boolean = false): Promise<void> {
    this.promiseEstadoSS = this._rhRegistarEmpregadosSSService
      .getEmpregadoInfo(this.model.codEmp, false)
      .then((response) => {
        this.modelEstadoSS.infoEstadoSegSocial = response.body.infoEstadoSegSocial;
        this.modelEstadoSS.infoEmpregadoRegistado = response.body.infoEmpregadoRegistado;
      })
      .catch((reason: HttpErrorResponse) => {
        if (!silent) {
          const cgException = this._cgExceptionService.get(reason);
          if (cgException.class === WSSEGSOCIALCREDENTIALS_ERROR_CLASS) {
            this._configWSButtonClick();
          } else {
            this._plAlertService.error(cgException.message);
          }
        }
      });
    return this.promiseEstadoSS;
  }

  private _estadoSSRegistar(): Promise<void> {
    return this._maintenanceRHRegistarEmp.maintenance({params: {dgemp: this.model, registerTabActive: true}});
  }

  private _estadoSSConsultar(): Promise<void> {
    return this._maintenanceRHRegistarEmp.maintenance({params: {dgemp: this.model}});
  }

  private _configWSButtonClick(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ConfigLoginWsSSModalComponent, {size: 'md'});
    const componentInstance: ConfigLoginWsSSModalComponent = modalInstance.componentInstance;
    componentInstance.nEmpresa = this.configurations.empresa.nEmpresa;
    return modalInstance.result;
  }

  private _evaluateFilterTirs(): void {
    if (this.model.dhemp.dtMotMov) {
      this.filterNTabIrs = `anoVigor=${moment(this.model.dhemp.dtMotMov).year()}`;
    }
  }
}
