import {EnvironmentProviders, makeEnvironmentProviders} from '@angular/core';
import {
  CGLoggerService,
  FILE_TRANSFORMERS,
  IPlRecaptchaParameters,
  Logger,
  PlFilterPanelAdapter,
  PlPdfErrorHandler,
  PlPdfFetchService,
  PlUploadErrorHandler,
  PlUploadResizeTransformer,
  PlUploadZipTransformer,
  provideEditComponents,
  provideEditMaps,
  provideIconPrefixes,
  provideNgSelectSelectionModelFactory,
  provideUploadFileListPipeline,
  RECAPTCHA_PARAMETERS
} from 'pl-comps-angular';
import {APP_LOCALE} from './constants';
import {CGEntityFilterPanelUrlAdapter} from '../core/components/entity/filter/entity.filter.panel.adapter';
import {ConfigSiteService} from '../core/services/configsite.service';
import {FileUploaderErrorHandler} from './upload.config';
import {IJsonRecaptchaConfig} from '../core/interfaces/jsonConfigValue.interface';
import {PdfErrorHandler, PdfFetchHandler} from './pdf.config';

export function provideCGComponentsConfiguration(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {provide: Logger, useExisting: CGLoggerService},
    provideNgSelectSelectionModelFactory(),
    provideIconPrefixes([
      ['fa-', 'fa'],
      ['pl-icon-', 'pl-icon'],
      ['icofont-', 'icofont']
    ]),
    provideEditComponents(),
    provideEditMaps(),
    provideUploadFileListPipeline(),
    {provide: PlFilterPanelAdapter, useClass: CGEntityFilterPanelUrlAdapter},
    {provide: PlPdfFetchService, useClass: PdfFetchHandler},
    {provide: PlPdfErrorHandler, useClass: PdfErrorHandler},
    {
      provide: RECAPTCHA_PARAMETERS,
      deps: [ConfigSiteService],
      useFactory: async (configSiteService: ConfigSiteService): Promise<IPlRecaptchaParameters> => {
        const recaptcha: IJsonRecaptchaConfig = await configSiteService.recaptchaConfig();
        return {
          enterprise: recaptcha.enterprise,
          sitekey: recaptcha.publicKey,
          sitekeyV3: recaptcha.publicKeyV3,
          language: APP_LOCALE.value
        };
      }
    },
    {provide: PlUploadErrorHandler, useClass: FileUploaderErrorHandler},
    {provide: FILE_TRANSFORMERS, multi: true, useClass: PlUploadResizeTransformer},
    {provide: FILE_TRANSFORMERS, multi: true, useClass: PlUploadZipTransformer}
  ]);
}
