import {IImTabEntity} from './imtab.entity.interface';
import {IJsonDataValor} from '../datavalor/jsonDataValor.entity.interface';
import {ImtabEditComponent} from './components/edit/imtab.entity.edit.component';
import {ROLE} from '../../services/role.const';

export const ENTITY_AMORTIZACAO: IImTabEntity = {
  name: 'amortizacao',
  roles: [ROLE.ATIVOS],
  searchPlaceholder: 'amortizacao.pesquisa',
  metadata: {
    keyName: 'nImtab',
    fields: [
      {
        name: 'nImtab',
        caption: 'amortizacao.fields.nImtab',
        validators: {required: {value: true}, maxlength: {value: 10}}
      },
      {
        name: 'nome',
        caption: 'amortizacao.fields.nome',
        placeholder: 'amortizacao.fields.nomePlaceholder',
        validators: {required: {value: true}, maxlength: {value: 150}}
      },
      {
        name: 'tabela',
        caption: 'amortizacao.fields.tabela',
        placeholder: 'amortizacao.fields.tabela',
        validators: {maxlength: {value: 2}}
      },
      {
        name: 'divisao',
        caption: 'amortizacao.fields.divisao',
        placeholder: 'amortizacao.fields.divisao',
        validators: {maxlength: {value: 5}}
      },
      {
        name: 'grupo',
        caption: 'amortizacao.fields.grupo',
        placeholder: 'amortizacao.fields.grupo',
        validators: {maxlength: {value: 2}}
      },
      {
        name: 'alinea',
        caption: 'amortizacao.fields.alinea',
        placeholder: 'amortizacao.fields.alinea',
        validators: {maxlength: {value: 1}}
      },
      {
        name: 'artigo',
        caption: 'amortizacao.fields.artigo',
        placeholder: 'amortizacao.fields.artigo',
        validators: {maxlength: {value: 2}}
      }
    ],
    order: 'nImtab',
    listFields: 'nImtab,nome',
    detailFields: 'nImtab,nome,tabela,divisao,grupo,alinea,artigo',
    newFields: 'nImtab,nome,tabela,divisao,grupo,alinea,artigo',
    searchFields: 'nImtab,nome'
  },
  autocomplete: {
    rowTemplate: '{{nImtab}} - {{nome}}',
    output: 'nome',
    searchFields: 'nImtab,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.amortizacao'
      }
    }
  },
  detail: {
    state: {
      component: ImtabEditComponent,
      data: {
        pageTitle: 'global.menu.amortizacao',
        formTemplate: {
          addMissingFields: false,
          items: [{type: 'group', fields: [{field: 'nImtab'}, {field: 'nome'}]}, {field: 'tabela'}, {field: 'divisao'}, {field: 'grupo'}, {field: 'alinea'}, {field: 'artigo'}]
        }
      }
    }
  },
  service: function () {
    this.adicionarLinha = (nimtab: string, linhaValor: IJsonDataValor) => {
      return this.post({url: buildPath(nimtab), body: linhaValor});
    };

    this.editarLinha = (nimtab: string, linhaValor: IJsonDataValor) => {
      return this.put({url: buildPath(nimtab, linhaValor.dataValorID), body: linhaValor});
    };

    this.eliminarLinha = (nimtab: string, dataValorID: string) => {
      return this.delete({url: buildPath(nimtab, dataValorID)});
    };

    function buildPath(nimtab: string, path?: string): string {
      let value = `${nimtab}/linha`;
      if (path) {
        value += `/${path}`;
      }
      return value;
    }
  }
};
