<div class="cg-contab-extratosgrid entity-detail-form">
  <pl-form>
    <pl-group>
      <label [translate]="'extratosClifos.radical'"></label>
      <edit>
        <pl-edit type="text" [model]="radical"></pl-edit>
      </edit>
    </pl-group>
  </pl-form>

  <hr />

  <dx-data-grid [cgDxDataGrid]="definition" [dataSource]="definition.dataSource" (onInitialized)="onInitialized($event)" (onContentReady)="onContentReady()" (onCellClick)="onCellClick($event)">
    <div *dxTemplate="let item of 'detailTemplate'">
      <documento-faturacao [doc]="item.data._thedoc" [actionPdf]="fnGetPdf"> </documento-faturacao>
    </div>
  </dx-data-grid>
</div>
