import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_DGEMPS_FULL, IDGEMPSFullEntityService, IDGEMPSFullTaxaMarginalSimulador} from '../../dgempsFull.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {TDate} from '../../../../../../common/dates';
import moment from 'moment';

@Component({
  selector: 'modal-dgempsfull-comment',
  templateUrl: './dgempsFull.simulaTaxaFixaIrs.modal.component.html'
})
export class DgempsfullSimulaTaxaFixaIrsModalComponent extends CGModalComponent<number> implements OnInit {
  @Input() public codEmp: number;

  public taxaFixa: number;
  public promise: Promise<void>;
  public taxaMarginalSimuladorDados: IDGEMPSFullTaxaMarginalSimulador;
  public dataSimulacao: TDate;

  private readonly _serviceDGEMPSFull: IDGEMPSFullEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._serviceDGEMPSFull = this._entityServiceBuilder.build(ENTITY_NAME_DGEMPS_FULL);
  }

  public ngOnInit(): void {
    this.taxaFixa = 0;
    this.dataSimulacao = moment();
    this.taxaMarginalSimuladorDados = {
      infoCalculos: this._plTranslateService.translate('dgempsfull.modalSimularTaxaFixaIrs.semDados'),
      taxaMarginal: 0
    };
  }

  public close(): void {
    super.close(this.taxaMarginalSimuladorDados.taxaMarginal);
  }

  public simularTaxa(): Promise<void> {
    if (!this.codEmp || !this.taxaFixa) {
      return Promise.resolve();
    }

    if (this.taxaFixa < 0 || this.taxaFixa > 100) {
      this._plAlertService.warning('dgempsfull.modalSimularTaxaFixaIrs.taxaNaoValida');
      return Promise.resolve();
    }

    this.promise = this._serviceDGEMPSFull
      .simulateTaxaMarginal(this.codEmp, this.taxaFixa, this.dataSimulacao)
      .then((response) => {
        this.taxaMarginalSimuladorDados = response.body;
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }
}
