import {Component, Injector, OnInit} from '@angular/core';
import {PlAlertService, TPlTableItem} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {DATA_SOURCE_TIPO_EXCECAO_CRABD} from '../../../../datasources/tipoexcecaocrabd/tipoExcecaoCRABD.datasource';
import {ENTITY_NAME_ABDES, ENTITY_NAME_ABONOS, ENTITY_NAME_DESCONTOS, ESiglaTipoAbonoDesconto} from '../../../abdes/abdes.entity.interface';
import {ENTITY_NAME_CRUZAMENTOS_ABONOS, ESiglasCategAbonos, ESiglasCategDescontos, ICRABDEditStateParams} from '../../crabd.entity.interface';
import {IJsonABDES} from '../../../abdes/jsonABDES.entity.interface';
import {IJsonCRABD} from '../../jsonCrAbD.entity.interface';
import {IModuleEntityListOnDataGridCellClick} from '../../../../components/module/entitylist/module.entitylist.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {IApiQueryResponse} from '../../../../services/api/api.service.interface';
import {IDevExpressDataGrid, IDevExpressDataGridColumnCustomizeTextCellInfo, TDevExpressDataGridColumnCustomizeTextFn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnCellDblClick} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';

export type TPlTableItemCruzamentos = TPlTableItem<IJsonABDES> & {_crabd: Array<IJsonCRABD>};

@Component({
  selector: 'crabd-list',
  templateUrl: './crabd.entity.list.component.html'
})
export class CruzamentosABDESListComponent extends ModuloEntityListComponent<IJsonABDES> implements OnInit {
  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonCRABD, string>;

  public entityABDES: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {
          caption: 'crabd.tableFields.cruzamento',
          columns: [
            {dataField: 'siglaTipoAbdesDestino', dataType: 'string', caption: 'crabd.tableFields.abonoDesconto'},
            {dataField: 'codDestino', dataType: 'string', caption: 'crabd.tableFields.cod'},
            {dataField: 'nomeABDESDestino', dataType: 'string', caption: 'crabd.tableFields.nome'},
            {dataField: 'siglaCategoriaAbDescDestino', dataType: 'string', caption: 'crabd.tableFields.cat'}
          ]
        },
        {
          caption: 'crabd.tableFields.empregado',
          columns: [
            {dataField: 'incidenciaEmpregado', dataType: 'string', caption: 'crabd.fields.incidenciaEmpregado', customizeText: this._fnCustomizeText},
            {dataField: 'nomeExcecao', dataType: 'string', caption: 'crabd.tableFields.excecao'}
          ]
        },
        {
          caption: 'crabd.tableFields.patronato',
          columns: [{dataField: 'incidenciaPatronato', dataType: 'string', caption: 'crabd.fields.incidenciaPatronato', customizeText: this._fnCustomizeText}]
        },
        {type: 'buttons', cellTemplate: 'cellTemplateBtns'}
      ],
      filterRow: {visible: false},
      headerFilter: {visible: false},
      dataSource: [],
      keyExpr: 'codDestino',
      remoteOperations: false,
      toolbar: {visible: false}
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnNovo.visible = false;
    this.entityABDES = this.entityName === ENTITY_NAME_CRUZAMENTOS_ABONOS ? ENTITY_NAME_ABONOS : ENTITY_NAME_DESCONTOS;
    if (!this.entityABDES) {
      this.entityABDES = ENTITY_NAME_ABDES;
    }
  }

  public onDetail(event: IModuleEntityListOnDataGridCellClick<IJsonABDES>): Promise<void> {
    const {data} = event;
    return this.service.get<IApiQueryResponse<IJsonCRABD>>({id: data.nCodABDESC}).then((result) => {
      for (const crabd of result.body.list) {
        crabd.siglaCategoriaAbDescDestino = crabd.tipoAbDescDestino === 1 ? ESiglasCategDescontos[crabd.categoriaAbDescDestino] : ESiglasCategAbonos[crabd.categoriaAbDescDestino];
        crabd.siglaTipoAbdesDestino = ESiglaTipoAbonoDesconto[crabd.tipoAbDescDestino];
        crabd.nomeExcecao = this._translateService.instant(DATA_SOURCE_TIPO_EXCECAO_CRABD.data[crabd.tipoExcecao].name);
      }
      (<TPlTableItemCruzamentos>data)._crabd = result.body.list;
      return super.onDetail(event);
    });
  }

  public edit(item: IJsonCRABD): Promise<void> {
    const params: ICRABDEditStateParams = {
      id: item.nCodOrigem,
      abdes: item.nCodDestino
    };
    return this._stateService.go(this.states.detail.name, params).then(() => undefined);
  }

  public delete(item: IJsonCRABD, list: Array<IJsonCRABD>): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'crabd.removeCruzamento').then(() => {
      return this.service.delete<IApiQueryResponse<void>>({id: item.nCodOrigem, params: {nCodDestino: item.nCodDestino}}).then(() => {
        this._plAlertService.success('crabd.cruzdelsuccess');
        const index = list.findIndex((value: IJsonCRABD) => value.nCodDestino === item.nCodDestino && value.nCodOrigem === item.nCodOrigem);
        if (index !== -1) {
          list.splice(index, 1);
          return Promise.resolve();
        }
        return this.refreshList();
      });
    });
  }

  public new(item: IJsonABDES): Promise<void> {
    if (this._disableNavigation) {
      return Promise.resolve();
    }
    const params: ICRABDEditStateParams = {
      id: item.nCodABDESC,
      abdes: item.nCodABDESC,
      crabd: {
        tipoAbDescOrigem: item.abonoDesc,
        codOrigem: item.codAbDesc,
        nCodOrigem: item.nCodABDESC,
        tipoAbDescDestino: 1,
        codDestino: '',
        nCodDestino: '',
        entSuporteEmpregado: false,
        entSuportePatronato: false,
        incidenciaEmpregado: 0,
        incidenciaPatronato: 0,
        tipoExcecao: 0,
        excecoes: [],
        categoriaAbDescDestino: 0,
        nomeABDESDestino: '',
        siglaTipoAbdesDestino: '',
        siglaCategoriaAbDescDestino: '',
        nomeExcecao: ''
      }
    };
    return this._stateService.go(this.states.new.name, params).then(() => undefined);
  }

  public onCellDblClick(event: IDevExpressDataGridEventOnCellDblClick<IJsonCRABD, string>): Promise<void> {
    if (event.rowType === 'data') {
      return this.edit(event.data);
    }
    return Promise.resolve();
  }

  private _customizeText(cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string {
    if (cellInfo.target === 'row' && (<number>cellInfo.value) < 0) {
      return 'Não tem';
    }
    return cellInfo.valueText;
  }

  private readonly _fnCustomizeText: TDevExpressDataGridColumnCustomizeTextFn = (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => this._customizeText(cellInfo);
}
