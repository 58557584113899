<div class="faturacao-eletronica-configuracoes-client-modal">
  <pl-form>
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'faturacaoeletronicaconfiguracoes.configemailsclientemodal.title'"></h5>
    </div>

    <div class="modal-body">
      <pl-group>
        <pl-group>
          <label [translate]="'transferenciasarmazens.modal.fields.clientfor'"></label>
          <edit>
            <entity-autocomplete
              entity="clientes"
              attrName="ncontaDestino"
              [model]="fatEletronicaConfCliente"
              (modelChange)="clientesChanged($event)"
              [fieldsMap]="{nConta: 'nConta', nome: 'nome'}"
              plAutoFocus>
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'faturacaoeletronicaconfiguracoes.fields.emailgeral'"></label>
          <edit>
            <pl-edit type="text" attrName="emailGeral" [model]="fatEletronicaConfCliente.emailGeral" [properties]="{disabled: true}"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <dx-data-grid
        [cgDxDataGrid]="dataGridDefinition"
        cgDxDataGridInstanceName="faturacaoEletronicaClientes"
        [dataSource]="dataGridDefinition.dataSource"
        (onToolbarPreparing)="onToolbarPreparing($event)">
        <div *dxTemplate="let item of 'templateToolbar'">
          <pl-group>
            <edit>
              <pl-edit type="email" attrName="email" [(model)]="email"></pl-edit>
            </edit>
            <actions>
              <button type="button" class="btn btn-primary btn-sm action-add-line" [disabled]="!email" (click)="addLine()">
                <i class="fa fa-fw fa-plus"></i>
                <span [translate]="'global.btn.add'"></span>
              </button>
            </actions>
          </pl-group>
        </div>
        <div *dxTemplate="let item of 'cellTemplateBtnEmail'">
          <button type="button" class="btn btn-danger btn-xs action-remove-line" [disabled]="item.data.value === fatEletronicaConfCliente.emailGeral" (click)="removeLine(item.data.value)">
            <i class="fa fa-fw fa-minus"></i>
          </button>
        </div>
      </dx-data-grid>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button klass="btn-primary action-close" [onClick]="close" attrName="ok" data-focus> <i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.ok'"></span> </pl-button>
      <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
    </div>
  </pl-form>
</div>
