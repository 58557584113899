import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHandlerFn, HttpInterceptor, HttpRequest, HttpResponse, HttpResponseBase} from '@angular/common/http';
import {isObject, PlAlertService} from 'pl-comps-angular';

export function interceptorNotification(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const alertService: PlAlertService = inject(PlAlertService);

  function handleResponse(responseData: HttpResponseBase): void {
    // In case server doesn't return a JSON response
    if (isObject(responseData) && responseData.headers) {
      const alertKey: string | null = responseData.headers.get('app-alert');
      if (alertKey) {
        alertService.error(alertKey);
      }
    }
  }

  return next(request).pipe(
    tap({
      next: (response: HttpResponse<unknown>) => {
        handleResponse(response);
      },
      error: (responseError: HttpErrorResponse) => {
        handleResponse(responseError);
      }
    })
  );
}

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {
  constructor(private readonly _plAlertService: PlAlertService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        next: (response: HttpResponse<unknown>) => {
          this.handleResponse(response);
        },
        error: (responseError: HttpErrorResponse) => {
          this.handleResponse(responseError);
        }
      })
    );
  }

  public handleResponse(responseData: HttpResponseBase): void {
    // In case server doesn't return a JSON response
    if (isObject(responseData) && responseData.headers) {
      const alertKey: string | null = responseData.headers.get('app-alert');
      if (alertKey) {
        this._plAlertService.error(alertKey);
      }
    }
  }
}
