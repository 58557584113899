import {Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[plFallbackSrc]'
})
export class PlFallbackSrcDirective {
  @Input() public plFallbackSrc: string;

  private _lastSrc: string;

  @HostListener('error', ['$event'])
  public errorHandler(event: Event): void {
    const target: HTMLElement = <HTMLElement>event.target;
    const attribute: Attr = target.attributes.getNamedItem('src');
    if (attribute) {
      if (!this._lastSrc || attribute.value !== this._lastSrc) {
        attribute.value = this.plFallbackSrc;
        this._lastSrc = attribute.value;
      }
    }
  }
}
