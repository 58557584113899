import {Component, Injector, Input, OnInit} from '@angular/core';
import {isDefinedNotNull, isUndefinedOrNull} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {EGTOExecuteEstado, EGTOTarefasMarcacaoState, IJsonGTOTarefaExecuteData, IJsonGTOTarefaMarc} from '../../jsonGTO.module.interface';
import {GTO_MIN_DATE, IGTOExecuteItem, IGTOTarefaMultiItem} from '../../gto.module.interface';
import {GtoService} from '../../gto.module.service';
import moment from 'moment';

@Component({
  selector: 'gto-execute-modal',
  templateUrl: './gto.execute.modal.component.html'
})
export class GtoExecuteModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public sessionNUtilizador: number;
  @Input() public nResponsavel: number;
  @Input() public gtoTarefaMarcacao: IJsonGTOTarefaMarc | IGTOTarefaMultiItem;

  public executeStates: Array<IGTOExecuteItem>;
  public model: IJsonGTOTarefaExecuteData;
  public readonly states: typeof EGTOExecuteEstado;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _gtoService: GtoService
  ) {
    super(_injector);
    this.states = EGTOExecuteEstado;
    this.model = {
      execDate: undefined,
      gtoTarefasMarcacaoID: '',
      justificacao: '',
      state: EGTOExecuteEstado.Concluir,
      valor: '',
      nota: ''
    };
    this.executeStates = [
      {value: EGTOExecuteEstado.SemAlteracao, label: 'gto.estados.semAlteracao'},
      {value: EGTOExecuteEstado.Concluir, label: 'gto.estados.concluir'},
      {value: EGTOExecuteEstado.AdicionarJustificacao, label: 'gto.estados.adicionarJustificacao'},
      {value: EGTOExecuteEstado.NaoAplicavel, label: 'gto.estados.naoAplicavel'},
      {value: EGTOExecuteEstado.New, label: 'gto.estados.new'},
      {value: EGTOExecuteEstado.GuardarValidacao, label: 'gto.estados.guardarValidacao'}
    ];
  }

  public ngOnInit(): void {
    this.model = {
      ...this.model,
      gtoTarefasMarcacaoID: this._getGTOTarefasMarcID(),
      nota: this.gtoTarefaMarcacao.nota,
      justificacao: this.gtoTarefaMarcacao.justificacao,
      valor: this.gtoTarefaMarcacao.valor
    };
    switch (this.gtoTarefaMarcacao.state) {
      case EGTOTarefasMarcacaoState.PrazoADecorrer:
      case EGTOTarefasMarcacaoState.EmAtraso:
        this.onStateChange(EGTOExecuteEstado.Concluir);
        break;
      case EGTOTarefasMarcacaoState.EmAtrasoJustificado:
        this.onStateChange(EGTOExecuteEstado.AdicionarJustificacao);
        break;
      case EGTOTarefasMarcacaoState.NaoAplicavel:
        this.onStateChange(EGTOExecuteEstado.NaoAplicavel);
        break;
      case EGTOTarefasMarcacaoState.GuardarValidacao:
        this.onStateChange(EGTOExecuteEstado.GuardarValidacao);
        break;
      default:
        break;
    }
    if (isDefinedNotNull(this.gtoTarefaMarcacao.dataExec) && this.gtoTarefaMarcacao.dataExec !== GTO_MIN_DATE) {
      this.model.execDate = this.gtoTarefaMarcacao.dataExec;
    }
  }

  public readonly fnExecuteTask: () => Promise<void> = () => this._executeTask();

  public onStateChange(state: EGTOExecuteEstado): void {
    this.model.state = state;
    if (isUndefinedOrNull(this.gtoTarefaMarcacao.dataExec) || this.gtoTarefaMarcacao.dataExec === GTO_MIN_DATE) {
      if (state === EGTOExecuteEstado.AdicionarJustificacao) {
        this.model.execDate = undefined;
      } else if (state === EGTOExecuteEstado.Concluir) {
        this.model.execDate = moment();
      }
    }
  }

  private async _executeTask(): Promise<void> {
    if (this.nResponsavel !== this.sessionNUtilizador) {
      await this._cgModalService.showOkCancel('gto.confirmationModalTitle', 'gto.estaTarefaPertenceAoOutroResponsavel', {
        size: 'md',
        btnOkText: 'global.btn.yes',
        btnCancelText: 'global.btn.no',
        backdrop: 'static',
        showCloseBtn: false,
        keyboard: false
      });
    }

    if (this.model.state === EGTOExecuteEstado.Concluir && (isUndefinedOrNull(this.model.execDate) || this.model.execDate === GTO_MIN_DATE)) {
      this.model.execDate = moment();
    }

    if (isDefinedNotNull(this.gtoTarefaMarcacao.dataExec) && this.gtoTarefaMarcacao.dataExec !== GTO_MIN_DATE) {
      this.model.execDate = this.gtoTarefaMarcacao.dataExec;
    }

    await this._gtoService.executeTask(this.model);

    this.close();
  }

  private _getGTOTarefasMarcID(): string {
    return (<IGTOTarefaMultiItem>this.gtoTarefaMarcacao).gtoTarefasMarcacaoID
      ? (<IGTOTarefaMultiItem>this.gtoTarefaMarcacao).gtoTarefasMarcacaoID
      : (<IJsonGTOTarefaMarc>this.gtoTarefaMarcacao).gtoTarefasMarcID;
  }
}
