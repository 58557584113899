<div class="prevtesobras-rubrica-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'prevtesobras.modaltitle'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'prevtesobras.rubrica'"></label>
      <edit>
        <pl-edit type="integer" attrName="rubrica" [(model)]="rubrica" plAutoFocus></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer">
    <pl-button klass="btn-primary action-close" [onClick]="close" attrName="ok"> <i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.ok'"></span> </pl-button>
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
