import {Component, Injector, OnDestroy} from '@angular/core';
import {ModuloComponent} from '../../../components/module/module.component';
import {EPlDashboardType, IPlDashboard, PlI18nService, PlTranslateService} from 'pl-comps-angular';
import {DashboardRhService} from '../dashboardRh.module.service';
import {
  IJsonDashboardRhCustosFuncionarios,
  IJsonDashboardRhFuncionario,
  IJsonDashboardRhFuncionariosDepartamento,
  IJsonDashboardRhFuncionariosTipoContrato,
  IJsonDashboardRhTotal
} from '../jsonDashboardRh.module.interface';
import {EDashboardRhChartType, IDashboardRhPie, IDashboardRhPieItem} from '../dashboardRh.module.interface';
import dxChartPie, {InitializedEvent as PieChartInitializedEvent, Legend} from 'devextreme/viz/pie_chart';
import {ESexo} from '../../portalrh/dgempsfull/jsonDGEMPFull.interface';
import {DATA_SOURCE_SEXOS} from '../../../datasources/sexos/sexos.datasource';
import {IDataSourceItem} from '../../../components/datasource/datasources.interface';
import {EDivisTipo} from '../../../datasources/divistipo/divistipo.datasource.interface';
import {IDevExpressPieChart} from '../../../components/devexpress/charts/pie/devexpress.pie.chart.interface';
import {
  DEVEXPRESS_CHART_BLUE_COLOR,
  DEVEXPRESS_CHART_FONT_FAMILY,
  DEVEXPRESS_CHART_GREEN_COLOR,
  DEVEXPRESS_CHART_RED_COLOR,
  DEVEXPRESS_CHART_YELLOW_COLOR,
  IDevExpressChart,
  IDevExpressChartLabelCustomizeText,
  TDevExpressChartLegend
} from '../../../components/devexpress/charts/devexpress.charts.interface';
import {chartCombineSeriesTooltip, chartLabelWithPercent, chartToggleSeriesVisibility} from '../../../components/devexpress/charts/utilities/devexpress.chart.utilities';
import {HttpResponse} from '@angular/common/http';
import moment, {Moment} from 'moment';
import dxChart, {InitializedEvent, LegendClickEvent, PointHoverChangedEvent} from 'devextreme/viz/chart';
import {orderBy, upperFirst} from 'lodash-es';

/* eslint-disable @typescript-eslint/no-magic-numbers */
const INTERVALOS_IDADES = [
  [0, 17],
  [18, 25],
  [26, 35],
  [36, 45],
  [46, 55],
  [56, 65],
  [65, 200]
];
/* eslint-enable @typescript-eslint/no-magic-numbers */
const ULTIMOS_DOZE_MESES = 12;

@Component({
  selector: 'module-dashboard-rh',
  templateUrl: './dashboardRh.module.component.html'
})
export class DashboardRhModuleComponent extends ModuloComponent implements OnDestroy {
  public readonly chartPieFuncionariosPorSexo: IDevExpressPieChart<'DoughnutSeries'>;
  public readonly chartPieFuncionariosPorIdade: IDevExpressPieChart<'DoughnutSeries'>;
  public readonly chartPieFuncionariosPorDepartamento: IDevExpressPieChart<'DoughnutSeries'>;
  public readonly chartPieFuncionariosPorTipoContrato: IDevExpressPieChart<'DoughnutSeries'>;
  public readonly chartBarCustosFuncionarios: IDevExpressChart<'BarSeries'>;

  public countFuncionariosAtivos: Promise<IPlDashboard<EPlDashboardType.Tile>>;
  public countEntradasUltimosMeses: Promise<IPlDashboard<EPlDashboardType.Tile>>;
  public countSaidasUltimosMeses: Promise<IPlDashboard<EPlDashboardType.Tile>>;
  public countFuncionariosTempoInteiro: Promise<IPlDashboard<EPlDashboardType.Tile>>;
  public countFuncionariosTempoParcial: Promise<IPlDashboard<EPlDashboardType.Tile>>;
  public countFuncionariosOutros: Promise<IPlDashboard<EPlDashboardType.Tile>>;
  public chartPieDataFuncionariosPorSexo: Array<IDashboardRhPie>;
  public chartPieDataFuncionariosPorIdade: Array<IDashboardRhPie>;
  public chartPieDataFuncionariosPorDepartamento: Array<IDashboardRhPie>;
  public chartPieDataFuncionariosPorTipoContrato: Array<IDashboardRhPie>;
  public chartBarDataCustosFuncionarios: Array<IJsonDashboardRhCustosFuncionarios>;

  private readonly _currentDate: Moment;

  private _chartPieInstanceFuncionariosPorSexo: dxChartPie;
  private _chartPieInstanceFuncionariosPorIdade: dxChartPie;
  private _chartPieInstanceFuncionariosPorDepartamento: dxChartPie;
  private _chartPieInstanceFuncionariosPorTipoContrato: dxChartPie;
  private _chartBarInstanceCustosFuncionarios: dxChart;
  private _destroyed: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _dashboardRhService: DashboardRhService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plI18nService: PlI18nService
  ) {
    super(_injector);
    this._currentDate = moment();
    this._destroyed = false;

    const chartLegend: Legend | TDevExpressChartLegend = {
      verticalAlignment: 'top',
      horizontalAlignment: 'center',
      itemTextPosition: 'right',
      font: {
        weight: 700,
        family: DEVEXPRESS_CHART_FONT_FAMILY
      },
      margin: {
        top: 0,
        bottom: 15
      }
    };
    this.chartPieFuncionariosPorSexo = {
      type: 'doughnut',
      palette: 'Soft Blue',
      resolveLabelOverlapping: 'shift',
      title: {
        horizontalAlignment: 'center',
        text: this._translateService.instant('dashboards.rh.graficos.funcionariosPorGenero'),
        font: {
          size: 18,
          weight: 800,
          family: DEVEXPRESS_CHART_FONT_FAMILY
        }
      },
      series: [
        {
          argumentField: 'name',
          valueField: 'value',
          label: {
            visible: true,
            customizeText: this.customizeLabelWithPercentFn,
            connector: {
              visible: true
            }
          }
        }
      ],
      tooltip: {
        enabled: true,
        contentTemplate: 'customTooltip'
      },
      legend: <Legend>chartLegend
    };

    this.chartPieFuncionariosPorIdade = {
      type: 'doughnut',
      palette: 'Soft Blue',
      resolveLabelOverlapping: 'shift',
      title: {
        horizontalAlignment: 'center',
        text: this._translateService.instant('dashboards.rh.graficos.funcionariosPorIdade'),
        font: {
          size: 18,
          weight: 800,
          family: DEVEXPRESS_CHART_FONT_FAMILY
        }
      },
      series: [
        {
          argumentField: 'name',
          valueField: 'value',
          label: {
            visible: true,
            customizeText: this.customizeLabelWithPercentFn,
            connector: {
              visible: true
            }
          }
        }
      ],
      tooltip: {
        enabled: true,
        contentTemplate: 'customTooltip'
      },
      legend: <Legend>chartLegend
    };

    this.chartPieFuncionariosPorDepartamento = {
      type: 'doughnut',
      palette: 'Soft Blue',
      resolveLabelOverlapping: 'shift',
      title: {
        horizontalAlignment: 'center',
        text: this._translateService.instant('dashboards.rh.graficos.funcionariosPorDepartamento'),
        font: {
          size: 18,
          weight: 800,
          family: DEVEXPRESS_CHART_FONT_FAMILY
        }
      },
      series: [
        {
          argumentField: 'name',
          valueField: 'value',
          label: {
            visible: true,
            customizeText: this.customizeLabelWithPercentFn,
            connector: {
              visible: true
            }
          }
        }
      ],
      tooltip: {
        enabled: true,
        contentTemplate: 'customTooltip'
      },
      legend: <Legend>chartLegend
    };

    this.chartPieFuncionariosPorTipoContrato = {
      type: 'doughnut',
      palette: 'Soft Blue',
      resolveLabelOverlapping: 'shift',
      title: {
        horizontalAlignment: 'center',
        text: this._translateService.instant('dashboards.rh.graficos.funcionariosPorTipoContrato'),
        font: {
          size: 18,
          weight: 800,
          family: DEVEXPRESS_CHART_FONT_FAMILY
        }
      },
      series: [
        {
          argumentField: 'name',
          valueField: 'value',
          label: {
            visible: true,
            customizeText: this.customizeLabelWithPercentFn,
            connector: {
              visible: true
            }
          }
        }
      ],
      tooltip: {
        enabled: true,
        contentTemplate: 'customTooltip'
      },
      legend: <Legend>chartLegend
    };

    this.chartBarCustosFuncionarios = {
      commonSeriesSettings: {
        argumentField: 'mesNome',
        type: 'bar',
        ignoreEmptyPoints: true,
        hoverMode: 'allArgumentPoints'
      },
      title: {
        text: this._translateService.instant('dashboards.rh.graficos.custosFuncionarios.title'),
        horizontalAlignment: 'center',
        font: {
          size: 18,
          weight: 800,
          family: DEVEXPRESS_CHART_FONT_FAMILY
        }
      },
      series: [
        {valueField: 'vencimentosFaltas', color: DEVEXPRESS_CHART_BLUE_COLOR, name: this._translateService.instant('dashboards.rh.graficos.custosFuncionarios.vencimentosFaltas')},
        {valueField: 'refeicoesTickets', color: DEVEXPRESS_CHART_GREEN_COLOR, name: this._translateService.instant('dashboards.rh.graficos.custosFuncionarios.subRefeicao')},
        {valueField: 'impostos', color: DEVEXPRESS_CHART_RED_COLOR, name: this._translateService.instant('dashboards.rh.graficos.custosFuncionarios.impostos')},
        {valueField: 'outros', color: DEVEXPRESS_CHART_YELLOW_COLOR, name: this._translateService.instant('dashboards.rh.graficos.custosFuncionarios.outros')}
      ],
      tooltip: {
        enabled: true,
        location: 'edge',
        paddingLeftRight: 15,
        paddingTopBottom: 10,
        contentTemplate: 'customTooltip',
        shared: true
      },
      legend: <TDevExpressChartLegend>chartLegend,
      stickyHovering: true
    };

    this._loadData(false).then(() => {
      this.btnRefresh.visible = true;
      this.btnRefresh.click = () => this._loadData(true);
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._destroyed = true;
  }

  public onInitializedChartPieFuncionariosSexo(event: PieChartInitializedEvent): void {
    this._chartPieInstanceFuncionariosPorSexo = event.component;
    this._chartPieInstanceFuncionariosPorSexo.showLoadingIndicator();
  }

  public onInitializedChartPieFuncionariosIdade(event: PieChartInitializedEvent): void {
    this._chartPieInstanceFuncionariosPorIdade = event.component;
    this._chartPieInstanceFuncionariosPorIdade.showLoadingIndicator();
  }

  public onInitializedChartPieFuncionariosPorDepartamento(event: PieChartInitializedEvent): void {
    this._chartPieInstanceFuncionariosPorDepartamento = event.component;
    this._chartPieInstanceFuncionariosPorDepartamento.showLoadingIndicator();
  }

  public onInitializedChartPieFuncionariosPorTipoContrato(event: PieChartInitializedEvent): void {
    this._chartPieInstanceFuncionariosPorTipoContrato = event.component;
    this._chartPieInstanceFuncionariosPorTipoContrato.showLoadingIndicator();
  }

  public onInitializedChartBarCustosFuncionarios(event: InitializedEvent): void {
    this._chartBarInstanceCustosFuncionarios = event.component;
    this._chartBarInstanceCustosFuncionarios.showLoadingIndicator();
  }

  public onChartPointHoverChanged(event: PointHoverChangedEvent): void {
    chartCombineSeriesTooltip(event);
  }

  public onChartLegendClick(event: LegendClickEvent): void {
    chartToggleSeriesVisibility(event);
  }

  public readonly customizeLabelWithPercentFn = (pointInfo: IDevExpressChartLabelCustomizeText): string => chartLabelWithPercent(pointInfo, this._plI18nService, true, false, true);

  private async _loadData(force: boolean): Promise<void> {
    const meses: number = ULTIMOS_DOZE_MESES;
    const funcionariosAtivosList: Array<IJsonDashboardRhFuncionario> = (await this._dashboardRhService.getTotalFuncionariosAtivos(undefined, force)).body;
    this.countFuncionariosAtivos = this._refreshFuncionariosAtivos(funcionariosAtivosList);
    this.countEntradasUltimosMeses = this._refreshEntradasUltimosMeses(meses, force);
    this.countSaidasUltimosMeses = this._refreshSaidasUltimosMeses(meses, force);
    this.countFuncionariosTempoInteiro = this._refreshFuncionariosTempoInteiro(funcionariosAtivosList.filter((item: IJsonDashboardRhFuncionario) => item.tipoDivisao === EDivisTipo.TempoCompleto));
    this.countFuncionariosTempoParcial = this._refreshFuncionariosTempoParcial(funcionariosAtivosList.filter((item: IJsonDashboardRhFuncionario) => item.tipoDivisao === EDivisTipo.TempoParcial));
    this.countFuncionariosOutros = this._refreshFuncionariosOutros();
    await Promise.all([
      this.countFuncionariosAtivos,
      this.countEntradasUltimosMeses,
      this.countSaidasUltimosMeses,
      this.countFuncionariosTempoInteiro,
      this.countFuncionariosTempoParcial,
      this.countFuncionariosOutros,
      this._refreshFuncionariosPorSexo(funcionariosAtivosList),
      this._refreshFuncionariosPorIdade(funcionariosAtivosList),
      this._refreshFuncionariosPorDepartamento(force),
      this._refreshFuncionariosPorTipoContrato(force),
      this._refreshCustosFuncionarios(force)
    ]);
    return undefined;
  }

  private _refreshFuncionariosAtivos(funcionariosList: Array<IJsonDashboardRhFuncionario>): Promise<IPlDashboard<EPlDashboardType.Tile>> {
    return Promise.resolve({
      type: EPlDashboardType.Tile,
      title: this._plTranslateService.translate('dashboards.rh.tiles.funcionariosAtivos'),
      icon: 'fa-users',
      style: 'primary',
      data: funcionariosList.length
    });
  }

  private async _refreshEntradasUltimosMeses(meses: number, force: boolean): Promise<IPlDashboard<EPlDashboardType.Tile>> {
    const response: HttpResponse<IJsonDashboardRhTotal> = await this._dashboardRhService.getTotalEntradasUltimosMeses(meses, force);
    return {
      type: EPlDashboardType.Tile,
      title: this._plTranslateService.translate('dashboards.rh.tiles.entradas'),
      subtitle: this._plTranslateService.translate('dashboards.rh.tiles.subtitleMeses', {meses: meses}),
      icon: 'fa-users',
      style: 'primary',
      data: response.body.total
    };
  }

  private async _refreshSaidasUltimosMeses(meses: number, force: boolean): Promise<IPlDashboard<EPlDashboardType.Tile>> {
    const response: HttpResponse<IJsonDashboardRhTotal> = await this._dashboardRhService.getTotalSaidasUltimosMeses(meses, force);
    return {
      type: EPlDashboardType.Tile,
      title: this._plTranslateService.translate('dashboards.rh.tiles.saidas'),
      subtitle: this._plTranslateService.translate('dashboards.rh.tiles.subtitleMeses', {meses: meses}),
      icon: 'fa-users',
      style: 'primary',
      data: response.body.total
    };
  }

  private _refreshFuncionariosTempoInteiro(funcionariosList: Array<IJsonDashboardRhFuncionario>): Promise<IPlDashboard<EPlDashboardType.Tile>> {
    return Promise.resolve({
      type: EPlDashboardType.Tile,
      title: this._plTranslateService.translate('dashboards.rh.tiles.tempoInteiro'),
      icon: 'fa-users',
      style: 'primary',
      data: funcionariosList.length
    });
  }

  private _refreshFuncionariosTempoParcial(funcionariosList: Array<IJsonDashboardRhFuncionario>): Promise<IPlDashboard<EPlDashboardType.Tile>> {
    return Promise.resolve({
      type: EPlDashboardType.Tile,
      title: this._plTranslateService.translate('dashboards.rh.tiles.tempoParcial'),
      icon: 'fa-users',
      style: 'primary',
      data: funcionariosList.length
    });
  }

  private _refreshFuncionariosOutros(): Promise<IPlDashboard<EPlDashboardType.Tile>> {
    return Promise.resolve({
      type: EPlDashboardType.Tile,
      title: this._plTranslateService.translate('dashboards.rh.tiles.outros'),
      icon: 'fa-users',
      style: 'primary',
      data: 0 // alterar para tipo outros quando existir
    });
  }

  private _refreshFuncionariosPorSexo(funcionariosList: Array<IJsonDashboardRhFuncionario>): Promise<void> {
    if (this._destroyed) {
      return Promise.resolve();
    }
    try {
      const funcionariosData: Array<IDashboardRhPieItem> = this._buildDataFuncionariosPorSexo(funcionariosList);
      const removedFuncionariosData: Array<IDashboardRhPieItem> = this._removePieItemsWithNoValue(funcionariosData);
      this._formatChartData(EDashboardRhChartType.FuncionariosPorSexo, removedFuncionariosData);
      return Promise.resolve();
    } finally {
      if (!this._destroyed) {
        this._chartPieInstanceFuncionariosPorSexo.hideLoadingIndicator();
      }
    }
  }

  private _refreshFuncionariosPorIdade(funcionariosList: Array<IJsonDashboardRhFuncionario>): Promise<void> {
    if (this._destroyed) {
      return Promise.resolve();
    }
    try {
      const funcionariosData: Array<IDashboardRhPieItem> = this._buildDataFuncionariosPorIdade(funcionariosList);
      const removedFuncionariosData: Array<IDashboardRhPieItem> = this._removePieItemsWithNoValue(funcionariosData);
      this._formatChartData(EDashboardRhChartType.FuncionariosPorIdade, removedFuncionariosData);
      return Promise.resolve();
    } finally {
      if (!this._destroyed) {
        this._chartPieInstanceFuncionariosPorIdade.hideLoadingIndicator();
      }
    }
  }

  private async _refreshFuncionariosPorDepartamento(force: boolean): Promise<void> {
    if (this._destroyed) {
      return;
    }
    try {
      const response: HttpResponse<Array<IJsonDashboardRhFuncionariosDepartamento>> = await this._dashboardRhService.getFuncionariosPorDepartamento(force);
      const funcionariosData: Array<IDashboardRhPieItem> = this._buildDataFuncionariosPorDepartamento(response.body);
      const removedFuncionariosData: Array<IDashboardRhPieItem> = this._removePieItemsWithNoValue(funcionariosData);
      this._formatChartData(EDashboardRhChartType.FuncionariosPorDepartamento, removedFuncionariosData);
    } finally {
      if (!this._destroyed) {
        this._chartPieInstanceFuncionariosPorDepartamento.hideLoadingIndicator();
      }
    }
  }

  private async _refreshFuncionariosPorTipoContrato(force: boolean): Promise<void> {
    if (this._destroyed) {
      return;
    }
    try {
      const response: HttpResponse<Array<IJsonDashboardRhFuncionariosTipoContrato>> = await this._dashboardRhService.getFuncionariosPorTipoContrato(force);
      const funcionariosData: Array<IDashboardRhPieItem> = this._buildDataFuncionariosPorTipoContrato(response.body);
      const removedFuncionariosData: Array<IDashboardRhPieItem> = this._removePieItemsWithNoValue(funcionariosData);
      this._formatChartData(EDashboardRhChartType.FuncionariosPorTipoContrato, removedFuncionariosData);
    } finally {
      if (!this._destroyed) {
        this._chartPieInstanceFuncionariosPorTipoContrato.hideLoadingIndicator();
      }
    }
  }

  private async _refreshCustosFuncionarios(force: boolean): Promise<void> {
    if (this._destroyed) {
      return;
    }
    try {
      const response: HttpResponse<Array<IJsonDashboardRhCustosFuncionarios>> = await this._dashboardRhService.getCustosFuncionariosMensalmente(force);
      this.chartBarDataCustosFuncionarios = this._buildDataCustosFuncionarios(response.body);
    } finally {
      if (!this._destroyed) {
        this._chartBarInstanceCustosFuncionarios.hideLoadingIndicator();
      }
    }
  }

  private _buildDataFuncionariosPorSexo(list: Array<IJsonDashboardRhFuncionario>): Array<IDashboardRhPieItem> {
    return DATA_SOURCE_SEXOS.data.map((sexo: IDataSourceItem<ESexo>) => ({
      codigo: sexo.value,
      nome: this._plTranslateService.translate(sexo.name),
      valor: list.filter((funcionario: IJsonDashboardRhFuncionario) => funcionario.sexo === sexo.value).length
    }));
  }

  private _buildDataFuncionariosPorIdade(list: Array<IJsonDashboardRhFuncionario>): Array<IDashboardRhPieItem> {
    const data: Array<IDashboardRhPieItem> = [];

    // Initialize counters for each age range
    INTERVALOS_IDADES.forEach((range: Array<number>, index: number) => {
      if (index === 0) {
        data.push({codigo: index, nome: '-18', valor: 0});
      } else if (index === INTERVALOS_IDADES.length - 1) {
        data.push({codigo: index, nome: `${range[0]}+`, valor: 0});
      } else {
        data.push({codigo: index, nome: `${range[0]} - ${range[1]}`, valor: 0});
      }
    });

    list.forEach((funcionario: IJsonDashboardRhFuncionario) => {
      // Calculate age
      const birthDate: Moment = moment(funcionario.dataNascimento);
      const currentAge: number = this._currentDate.diff(birthDate, 'years');

      // Check which age range the funcionario falls into
      for (let i = 0; i < INTERVALOS_IDADES.length; i++) {
        if (currentAge >= INTERVALOS_IDADES[i][0] && currentAge <= INTERVALOS_IDADES[i][1]) {
          data[i].valor++;
          break;
        }
      }
    });
    return data;
  }

  private _buildDataFuncionariosPorDepartamento(list: Array<IJsonDashboardRhFuncionariosDepartamento>): Array<IDashboardRhPieItem> {
    return list.map<IDashboardRhPieItem>((item: IJsonDashboardRhFuncionariosDepartamento) => {
      return {
        codigo: item.departamentoId,
        nome: item.departamentoNome,
        valor: item.countFuncionarios
      };
    });
  }

  private _buildDataFuncionariosPorTipoContrato(list: Array<IJsonDashboardRhFuncionariosTipoContrato>): Array<IDashboardRhPieItem> {
    return list.map<IDashboardRhPieItem>((item: IJsonDashboardRhFuncionariosTipoContrato) => {
      return {
        codigo: item.tipoContrato,
        nome: item.designacaoCompleta,
        valor: item.countFuncionarios
      };
    });
  }

  private _buildDataCustosFuncionarios(list: Array<IJsonDashboardRhCustosFuncionarios>): Array<IJsonDashboardRhCustosFuncionarios> {
    const returnList: Array<IJsonDashboardRhCustosFuncionarios> = list.map<IJsonDashboardRhCustosFuncionarios>((item: IJsonDashboardRhCustosFuncionarios) => {
      return {
        ...item,
        mesNome: upperFirst(item.mesNome)
      };
    });

    // add missing months
    for (let i = 0; i < ULTIMOS_DOZE_MESES; i++) {
      const index: number = returnList.findIndex((item: IJsonDashboardRhCustosFuncionarios) => item.mes === i + 1);
      if (index === -1) {
        returnList.push({mes: i, mesNome: upperFirst(moment.monthsShort(i)), outros: 0, impostos: 0, refeicoesTickets: 0, vencimentosFaltas: 0});
      }
    }

    return orderBy(returnList, (item: IJsonDashboardRhCustosFuncionarios) => item.mes, 'asc');
  }

  private _formatChartData(type: EDashboardRhChartType, data: Array<IDashboardRhPieItem>): void {
    if (type === EDashboardRhChartType.FuncionariosPorSexo) {
      this.chartPieDataFuncionariosPorSexo = data.map<IDashboardRhPie>((dataItem: IDashboardRhPieItem) => {
        return {
          name: dataItem.nome,
          value: dataItem.valor,
          percent: dataItem.percentagem
        };
      });
    } else if (type === EDashboardRhChartType.FuncionariosPorIdade) {
      this.chartPieDataFuncionariosPorIdade = data.map<IDashboardRhPie>((dataItem: IDashboardRhPieItem) => {
        return {
          name: dataItem.nome,
          value: dataItem.valor,
          percent: dataItem.percentagem
        };
      });
    } else if (type === EDashboardRhChartType.FuncionariosPorDepartamento) {
      this.chartPieDataFuncionariosPorDepartamento = data.map<IDashboardRhPie>((dataItem: IDashboardRhPieItem) => {
        return {
          name: dataItem.nome,
          value: dataItem.valor,
          percent: dataItem.percentagem
        };
      });
    } else if (type === EDashboardRhChartType.FuncionariosPorTipoContrato) {
      this.chartPieDataFuncionariosPorTipoContrato = data.map<IDashboardRhPie>((dataItem: IDashboardRhPieItem) => {
        return {
          name: dataItem.nome,
          value: dataItem.valor,
          percent: dataItem.percentagem
        };
      });
    }
  }

  private _removePieItemsWithNoValue(dataList: Array<IDashboardRhPieItem>): Array<IDashboardRhPieItem> {
    return dataList.filter((item: IDashboardRhPieItem) => item.valor !== 0);
  }
}
