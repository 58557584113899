import {ENTITY_NAME_PAGAMENTOS, IPagamentosEntity} from './pagamentos.entity.interface';
import {PagamentosEditComponent} from './components/edit/pagamentos.entity.edit.component';
import {PagamentosListComponent} from './components/list/pagamentos.entity.list.component';
import {ROLE} from '../../services/role.const';
import {RESOLVER_CG_STORE_URL_BACKOFFICE} from '../../../config/uirouter/uirouter.resolvers';

export const ENTITY_PAGAMENTOS: IPagamentosEntity = {
  name: ENTITY_NAME_PAGAMENTOS,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.FINANCEIRO],
  searchPlaceholder: 'pagamentos.pesquisa',
  icon: 'fa-chevron-circle-up',
  metadata: {
    keyName: 'extPocCabID',
    fields: [
      {name: 'numero', width: '160px', caption: 'pagamentos.fields.numero'},
      {name: 'nConta', width: '100px', caption: 'pagamentos.fields.nconta'},
      {name: 'nome', caption: 'pagamentos.fields.nome'},
      {name: 'data', width: '100px', type: 'date', caption: 'pagamentos.fields.data'},
      {name: 'valor', width: '75px', align: 'right', type: 'double', caption: 'pagamentos.fields.valor'},
      {name: 'abreviaturaMoeda', align: 'center', caption: 'pagamentos.fields.abreviaturaMoeda'},
      {name: 'stampUpdate', type: 'datetime', caption: 'pagamentos.fields.stampUpdate'}
    ],
    order: 'stampUpdate desc'
  },
  actions: {
    new: true,
    detail: false,
    edit: false,
    delete: false,
    search: true,
    filter: true
  },
  list: {
    state: {
      component: PagamentosListComponent,
      resolve: [RESOLVER_CG_STORE_URL_BACKOFFICE]
    }
  },
  detail: {
    state: {
      component: PagamentosEditComponent,
      params: {
        nConta: {
          type: 'string',
          value: ''
        },
        nome: {
          type: 'string',
          value: ''
        }
      }
    }
  },
  service: function () {
    this.sendEmail = (extpoccabID, nconta, email) => {
      return this.get({id: `${extpoccabID}/email`, params: {email: email, nconta: nconta}});
    };
  }
};
