import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_INVOICE_TYPE, EInvoiceType} from './invoiceType.datasource.interface';

export const DATA_SOURCE_INVOICE_TYPE: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_INVOICE_TYPE,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EInvoiceType.None, name: 'invoiceType.fields.None'},
    {value: EInvoiceType.Fatura, name: 'invoiceType.fields.Fatura'},
    {value: EInvoiceType.FaturaSimplificada, name: 'invoiceType.fields.FaturaSimplificada'},
    {value: EInvoiceType.FaturaRecibo, name: 'invoiceType.fields.FaturaRecibo'},
    {value: EInvoiceType.NotaDebito, name: 'invoiceType.fields.NotaDebito'},
    {value: EInvoiceType.NotaCredito, name: 'invoiceType.fields.NotaCredito'},
    {value: EInvoiceType.VendaDinheiro, name: 'invoiceType.fields.VendaDinheiro'},
    {value: EInvoiceType.TalaoVenda, name: 'invoiceType.fields.TalaoVenda'},
    {value: EInvoiceType.TalaoDevolucao, name: 'invoiceType.fields.TalaoDevolucao'},
    {value: EInvoiceType.AlienacaoAtivos, name: 'invoiceType.fields.AlienacaoAtivos'},
    {value: EInvoiceType.DevolucaoAtivos, name: 'invoiceType.fields.DevolucaoAtivos'},
    {value: EInvoiceType.Premio, name: 'invoiceType.fields.Premio'},
    {value: EInvoiceType.Estorno, name: 'invoiceType.fields.Estorno'},
    {value: EInvoiceType.ImputacaoCoSeguradoras, name: 'invoiceType.fields.ImputacaoCoSeguradoras'},
    {value: EInvoiceType.ImputacaoCoSeguradoraLider, name: 'invoiceType.fields.ImputacaoCoSeguradoraLider'},
    {value: EInvoiceType.ResseguroAceite, name: 'invoiceType.fields.ResseguroAceite'},
    {value: EInvoiceType.Recibo, name: 'invoiceType.fields.Recibo'},
    {value: EInvoiceType.NotaLancamento, name: 'invoiceType.fields.NotaLancamento'},
    {value: EInvoiceType.ExtratoBancario, name: 'invoiceType.fields.ExtratoBancario'},
    {value: EInvoiceType.PagamentoAoEstado, name: 'invoiceType.fields.PagamentoAoEstado'},
    {value: EInvoiceType.Levantamento, name: 'invoiceType.fields.Levantamento'},
    {value: EInvoiceType.Pagamento, name: 'invoiceType.fields.Pagamento'},
    {value: EInvoiceType.Deposito, name: 'invoiceType.fields.Deposito'},
    {value: EInvoiceType.Cheque, name: 'invoiceType.fields.Cheque'},
    {value: EInvoiceType.Transferencia, name: 'invoiceType.fields.Transferencia'}
  ]
});
