<div class="declaracao-iva-recapitulativa">
  <pl-alert *ngIf="decIvaRecapitulativa && !!decIvaRecapitulativa.decIvaRecapErro" type="warning" [closeable]="true">
    <div [innerHTML]="decIvaRecapitulativa.decIvaRecapErro | translate"></div>
  </pl-alert>

  <div>
    <div class="card">
      <h5 class="card-header"><span class="number-title-box">1</span>&nbsp;<span class="nav-item-title" [translate]="'declaracaoiva.recapitulativa.quadros.quadro1.title'"></span></h5>
      <div class="card-body">
        <div class="inputs-currency-inline">
          <section class="mt-2">
            <div class="box-title">
              <div class="input-box-numbers-title">01</div>
              <label [translate]="'declaracaoiva.recapitulativa.quadros.quadro1.desc1'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-1">
              <pl-group>
                <label [translate]="'declaracaoiva.recapitulativa.quadros.quadro1.nif'"></label>
                <edit>
                  <pl-edit type="text" attrName="nif" [(model)]="decIvaRecapitulativa.nifEmpresa" [properties]="{disabled: true}"></pl-edit>
                </edit>
              </pl-group>
            </div>
          </section>
        </div>
      </div>
    </div>

    <div class="card">
      <h5 class="card-header"><span class="number-title-box">2</span>&nbsp;<span class="nav-item-title" [translate]="'declaracaoiva.recapitulativa.quadros.quadro2.title'"></span></h5>
      <div class="card-body">
        <div class="inputs-currency-inline">
          <div class="row">
            <div class="col-md-6">
              <div class="d-flex">
                <div class="input-box-numbers me-1">1</div>
                <pl-edit
                  type="radio"
                  attrName="tipoDeclaracaoPrimeiraDeclaracao1"
                  [model]="decIvaRecapitulativa.tipoDeclaracaoPrimeiraDeclaracao"
                  (modelChange)="decIvaRecapitulativa.tipoDeclaracaoPrimeiraDeclaracao = $event; changedQ2Cx('tipoDeclaracaoPrimeiraDeclaracao', '1')"
                  [properties]="{value: true, label: 'declaracaoiva.recapitulativa.quadros.quadro2.primeira'}">
                </pl-edit>
              </div>
            </div>

            <div class="col-md-6">
              <div class="d-flex">
                <div class="input-box-numbers me-1">2</div>
                <pl-edit
                  type="radio"
                  attrName="tipoDeclaracaoPrimeiraDeclaracao2"
                  [model]="decIvaRecapitulativa.tipoDeclaracaoPrimeiraDeclaracao"
                  (modelChange)="decIvaRecapitulativa.tipoDeclaracaoPrimeiraDeclaracao = $event; changedQ2Cx('tipoDeclaracaoPrimeiraDeclaracao', '2')"
                  [properties]="{value: false, label: 'declaracaoiva.recapitulativa.quadros.quadro2.substituicao'}">
                </pl-edit>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="ms-3 mt-2">
                <label [translate]="'declaracaoiva.recapitulativa.quadros.quadro2.desc1'"></label>
                <div class="d-flex">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred me-1">1A</div>
                    <pl-edit
                      type="radio"
                      attrName="emPrimeiraDecHouveAltTrimestralParaMensal1"
                      [model]="decIvaRecapitulativa.emPrimeiraDecHouveAltTrimestralParaMensal"
                      (modelChange)="decIvaRecapitulativa.emPrimeiraDecHouveAltTrimestralParaMensal = $event"
                      [properties]="{
                        value: 1,
                        label: 'declaracaoiva.anexos.comum.sim',
                        disabled: decIvaRecapitulativa.tipoDeclaracaoPrimeiraDeclaracao === undefined || !decIvaRecapitulativa.tipoDeclaracaoPrimeiraDeclaracao
                      }">
                    </pl-edit>
                  </div>
                  <div class="d-flex ms-2">
                    <div class="input-box-numbers-hundred me-1">1B</div>
                    <pl-edit
                      type="radio"
                      attrName="emPrimeiraDecHouveAltTrimestralParaMensal2"
                      [model]="decIvaRecapitulativa.emPrimeiraDecHouveAltTrimestralParaMensal"
                      (modelChange)="decIvaRecapitulativa.emPrimeiraDecHouveAltTrimestralParaMensal = $event"
                      [properties]="{
                        value: 2,
                        label: 'declaracaoiva.anexos.comum.nao',
                        disabled: decIvaRecapitulativa.tipoDeclaracaoPrimeiraDeclaracao === undefined || !decIvaRecapitulativa.tipoDeclaracaoPrimeiraDeclaracao
                      }">
                    </pl-edit>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="ms-3 mt-2">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred me-1">2.1</div>
                  <pl-edit
                    type="checkbox"
                    attrName="emSubstituicaoDecHouveAltTrimestralParaMensal"
                    [model]="decIvaRecapitulativa.emSubstituicaoDecHouveAltTrimestralParaMensal"
                    (modelChange)="changedQ2Cx('emSubstituicaoDecHouveAltTrimestralParaMensal', '2x')"
                    [properties]="{
                      label: 'declaracaoiva.recapitulativa.quadros.quadro2.field21',
                      disabled: decIvaRecapitulativa.tipoDeclaracaoPrimeiraDeclaracao === undefined || decIvaRecapitulativa.tipoDeclaracaoPrimeiraDeclaracao
                    }">
                  </pl-edit>
                </div>
                <div class="ms-3 mt-2 mb-2">
                  <label [translate]="'declaracaoiva.recapitulativa.quadros.quadro2.desc21'"></label>
                  <div class="d-flex">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred-letter me-1">2.1A</div>
                      <pl-edit
                        type="radio"
                        attrName="emSubstituicaoValorMensalSubstituiTotalmenteValorTrimestral1"
                        [model]="decIvaRecapitulativa.emSubstituicaoValorMensalSubstituiTotalmenteValorTrimestral"
                        (modelChange)="decIvaRecapitulativa.emSubstituicaoValorMensalSubstituiTotalmenteValorTrimestral = $event"
                        [properties]="{value: true, label: 'declaracaoiva.anexos.comum.sim', disabled: !decIvaRecapitulativa.emSubstituicaoDecHouveAltTrimestralParaMensal}">
                      </pl-edit>
                    </div>
                    <div class="d-flex ms-2">
                      <div class="input-box-numbers-hundred-letter me-1">2.1B</div>
                      <pl-edit
                        type="radio"
                        attrName="emSubstituicaoValorMensalSubstituiTotalmenteValorTrimestral2"
                        [model]="decIvaRecapitulativa.emSubstituicaoValorMensalSubstituiTotalmenteValorTrimestral"
                        (modelChange)="decIvaRecapitulativa.emSubstituicaoValorMensalSubstituiTotalmenteValorTrimestral = $event"
                        [properties]="{value: false, label: 'declaracaoiva.anexos.comum.nao', disabled: !decIvaRecapitulativa.emSubstituicaoDecHouveAltTrimestralParaMensal}">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="d-flex mt-1">
                  <div class="input-box-numbers-hundred me-1">2.2</div>
                  <pl-edit
                    type="checkbox"
                    attrName="emSubstituicaoInexistenciaDeOperacoes"
                    [model]="decIvaRecapitulativa.emSubstituicaoInexistenciaDeOperacoes"
                    (modelChange)="changedQ2Cx('emSubstituicaoInexistenciaDeOperacoes', '2x')"
                    [properties]="{
                      label: 'declaracaoiva.recapitulativa.quadros.quadro2.field22',
                      disabled: decIvaRecapitulativa.tipoDeclaracaoPrimeiraDeclaracao === undefined || decIvaRecapitulativa.tipoDeclaracaoPrimeiraDeclaracao
                    }">
                  </pl-edit>
                </div>

                <div class="d-flex mt-1">
                  <div class="input-box-numbers-hundred me-1">2.3</div>
                  <pl-edit
                    type="checkbox"
                    attrName="emSubstituicaoOutrosMotivos"
                    [model]="decIvaRecapitulativa.emSubstituicaoOutrosMotivos"
                    (modelChange)="changedQ2Cx('emSubstituicaoOutrosMotivos', '2x')"
                    [properties]="{
                      label: 'declaracaoiva.recapitulativa.quadros.quadro2.field23',
                      disabled: decIvaRecapitulativa.tipoDeclaracaoPrimeiraDeclaracao === undefined || decIvaRecapitulativa.tipoDeclaracaoPrimeiraDeclaracao
                    }">
                  </pl-edit>
                </div>

                <div class="d-flex mt-1">
                  <div class="input-box-numbers-hundred me-1">2.4</div>
                  <pl-edit
                    type="checkbox"
                    attrName="q2c24"
                    [model]="decIvaRecapitulativa.q2c24"
                    (modelChange)="changedQ2Cx('q2c24', '24')"
                    [properties]="{
                      label: 'declaracaoiva.recapitulativa.quadros.quadro2.field24',
                      disabled: decIvaRecapitulativa.tipoDeclaracaoPrimeiraDeclaracao === undefined || decIvaRecapitulativa.tipoDeclaracaoPrimeiraDeclaracao
                    }">
                  </pl-edit>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <div class="ms-3 mt-1">
                      <div class="d-flex">
                        <div class="input-box-numbers-hundred me-1">2.4.1</div>
                        <pl-edit
                          type="checkbox"
                          attrName="q2c241"
                          [model]="decIvaRecapitulativa.q2c241"
                          (modelChange)="changedQ2Cx('q2c241', '24x')"
                          [properties]="{label: 'declaracaoiva.recapitulativa.quadros.quadro2.field241', disabled: !decIvaRecapitulativa.q2c24}">
                        </pl-edit>
                      </div>

                      <div class="row">
                        <div class="col-sm-12">
                          <div class="ms-3 mt-1">
                            <div class="d-flex">
                              <div class="input-box-numbers-hundred me-1">2.4.1.1</div>
                              <pl-edit
                                type="checkbox"
                                attrName="q2c2411"
                                [model]="decIvaRecapitulativa.q2c2411"
                                (modelChange)="changedQ2Cx('q2c2411', '241x')"
                                [properties]="{label: 'declaracaoiva.recapitulativa.quadros.quadro2.field2411', disabled: !decIvaRecapitulativa.q2c241}">
                              </pl-edit>
                            </div>

                            <div class="d-flex mt-1">
                              <div class="input-box-numbers-hundred me-1">2.4.1.2</div>
                              <pl-edit
                                type="checkbox"
                                attrName="q2c2412"
                                [model]="decIvaRecapitulativa.q2c2412"
                                (modelChange)="changedQ2Cx('q2c2412', '241x')"
                                [properties]="{label: 'declaracaoiva.recapitulativa.quadros.quadro2.field2412', disabled: !decIvaRecapitulativa.q2c241}">
                              </pl-edit>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex mt-1">
                        <div class="input-box-numbers-hundred me-1">2.4.2</div>
                        <pl-edit
                          type="checkbox"
                          attrName="q2c242"
                          [model]="decIvaRecapitulativa.q2c242"
                          (modelChange)="changedQ2Cx('q2c242', '24x')"
                          [properties]="{label: 'declaracaoiva.recapitulativa.quadros.quadro2.field242', disabled: !decIvaRecapitulativa.q2c24}">
                        </pl-edit>
                      </div>

                      <div class="d-flex mt-1">
                        <div class="input-box-numbers-hundred me-1">2.4.3</div>
                        <pl-edit
                          type="checkbox"
                          attrName="q2c243"
                          [model]="decIvaRecapitulativa.q2c243"
                          (modelChange)="changedQ2Cx('q2c243', '24x')"
                          [properties]="{label: 'declaracaoiva.recapitulativa.quadros.quadro2.field243', disabled: !decIvaRecapitulativa.q2c24}">
                        </pl-edit>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <h5 class="card-header"><span class="number-title-box">3</span>&nbsp;<span class="nav-item-title" [translate]="'declaracaoiva.recapitulativa.quadros.quadro3.title'"></span></h5>
      <div class="card-body">
        <div class="inputs-currency-inline">
          <section class="mt-2">
            <div class="box-title">
              <div class="input-box-numbers-title">03</div>
              <label [translate]="'declaracaoiva.recapitulativa.quadros.quadro3.desc'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-1">
              <div class="row">
                <div class="col-lg-4"><label [translate]="'declaracaoiva.recapitulativa.quadros.quadro3.ano'"></label></div>
                <div class="col-lg-4"><label [translate]="'declaracaoiva.recapitulativa.quadros.quadro3.mensal'"></label></div>
                <div class="col-lg-4"><label [translate]="'declaracaoiva.recapitulativa.quadros.quadro3.trimestral'"></label></div>
              </div>

              <div class="row">
                <div class="col-lg-4">
                  <div class="d-flex">
                    <div class="input-box-numbers">1</div>
                    <pl-edit type="text" attrName="anoAQueRespeita" [model]="decIvaRecapitulativa.anoAQueRespeita" (modelChange)="decIvaRecapitulativa.anoAQueRespeita = $event"> </pl-edit>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="d-flex">
                    <div class="input-box-numbers">2</div>
                    <pl-edit type="text" attrName="anexorQuadro6Campo1" [model]="mensal" (modelChange)="mensal = $event"></pl-edit>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="d-flex">
                    <div class="input-box-numbers">3</div>
                    <pl-edit type="text" attrName="anexorQuadro6Campo1" [(model)]="trimensal"></pl-edit>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-4"></div>
                <div class="col-lg-4"></div>
                <div class="col-lg-4"><label [translate]="'declaracaoiva.recapitulativa.quadros.quadro3.mesincltrimestre'"></label></div>
              </div>

              <div class="row">
                <div class="col-lg-4"></div>
                <div class="col-lg-4"></div>
                <div class="col-lg-4">
                  <div class="d-flex">
                    <div class="input-box-numbers">4</div>
                    <pl-edit
                      type="text"
                      attrName="mesIncluidoTrimestreMes1"
                      [model]="decIvaRecapitulativa.mesIncluidoTrimestreMes1"
                      (modelChange)="decIvaRecapitulativa.mesIncluidoTrimestreMes1 = $event">
                    </pl-edit>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-4"></div>
                <div class="col-lg-4"></div>
                <div class="col-lg-4">
                  <div class="d-flex">
                    <div class="input-box-numbers">5</div>
                    <pl-edit
                      type="text"
                      attrName="mesIncluidoTrimestreMes2"
                      [model]="decIvaRecapitulativa.mesIncluidoTrimestreMes2"
                      (modelChange)="decIvaRecapitulativa.mesIncluidoTrimestreMes2 = $event">
                    </pl-edit>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

    <div class="card">
      <h5 class="card-header"><span class="number-title-box">4</span>&nbsp;<span class="nav-item-title" [translate]="'declaracaoiva.recapitulativa.quadros.quadro45.title'"></span></h5>

      <div class="card-body">
        <div class="inputs-currency-inline">
          <section class="mt-2">
            <div class="box-title">
              <div class="input-box-numbers-title">04</div>
              <label [translate]="'declaracaoiva.recapitulativa.quadros.quadro45.desc4'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-1">
              <dx-data-grid
                [cgDxDataGrid]="dataGridListQ04"
                cgDxDataGridInstanceName="recapitulatIvaQ04List"
                [dataSource]="decIvaRecapitulativa.listaOpIntracomunitarias"
                (onInitialized)="onInitializedQ04($event)"
                (onSaved)="onSavedQ04($event)"
                (onRowValidating)="onRowValidatingQ04($event)">
              </dx-data-grid>
            </div>
          </section>

          <section class="mt-4 mb-2">
            <div class="box-title">
              <div class="input-box-numbers-title">05</div>
              <label [translate]="'declaracaoiva.recapitulativa.quadros.quadro45.desc5'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-2">
              <div>
                <label [translate]="'declaracaoiva.recapitulativa.quadros.quadro45.field10'"></label>
                <div class="d-flex">
                  <div class="input-box-numbers me-1">10</div>
                  <pl-edit type="number" attrName="DRIVA090510" [model]="DRIVA090510" (modelChange)="DRIVA090510 = $event" [properties]="{disabled: true}"> </pl-edit>
                </div>
              </div>

              <div>
                <label [translate]="'declaracaoiva.recapitulativa.quadros.quadro45.field11'"></label>
                <div class="d-flex">
                  <div class="input-box-numbers me-1">11</div>
                  <pl-edit
                    type="number"
                    attrName="totalDasVendasDeMeiosDeTransporteNovosAParticularesDaUE"
                    [model]="decIvaRecapitulativa.totalDasVendasDeMeiosDeTransporteNovosAParticularesDaUE"
                    (modelChange)="decIvaRecapitulativa.totalDasVendasDeMeiosDeTransporteNovosAParticularesDaUE = $event">
                  </pl-edit>
                </div>
              </div>

              <div>
                <label [translate]="'declaracaoiva.recapitulativa.quadros.quadro45.field17'"></label>
                <div class="d-flex">
                  <div class="input-box-numbers me-1">17</div>
                  <pl-edit type="number" attrName="DRIVA090517" [model]="DRIVA090517" (modelChange)="DRIVA090517 = $event" [properties]="{disabled: true}"> </pl-edit>
                </div>
              </div>

              <div>
                <label [translate]="'declaracaoiva.recapitulativa.quadros.quadro45.field18'"></label>
                <div class="d-flex">
                  <div class="input-box-numbers me-1">18</div>
                  <pl-edit type="number" attrName="DRIVA090518" [model]="DRIVA090518" (modelChange)="DRIVA090518 = $event" [properties]="{disabled: true}"> </pl-edit>
                </div>
              </div>

              <div>
                <label [translate]="'declaracaoiva.recapitulativa.quadros.quadro45.field19'"></label>
                <div class="d-flex">
                  <div class="input-box-numbers me-1">19</div>
                  <pl-edit type="number" attrName="DRIVA090519" [model]="DRIVA090519" (modelChange)="DRIVA090519 = $event" [properties]="{disabled: true}"> </pl-edit>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

    <div class="card">
      <h5 class="card-header"><span class="number-title-box">5</span>&nbsp;<span class="nav-item-title" [translate]="'declaracaoiva.recapitulativa.quadros.quadro6.title'"></span></h5>

      <div class="card-body">
        <div class="inputs-currency-inline">
          <section class="mt-2 mb-2">
            <div class="box-title">
              <div class="input-box-numbers-title">06</div>
              <label [translate]="'declaracaoiva.recapitulativa.quadros.quadro6.desc6'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-1">
              <dx-data-grid
                [cgDxDataGrid]="dataGridListQ06"
                cgDxDataGridInstanceName="recapitulatIvaQ06List"
                [dataSource]="decIvaRecapitulativa.q06Lista"
                (onSaved)="onSavedQ06($event)"
                (onRowValidating)="onRowValidatingQ06($event)">
              </dx-data-grid>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>
