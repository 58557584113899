import {Directive, TemplateRef} from '@angular/core';

@Directive({
  selector: 'ng-template[plSplitViewRightSide]'
})
export class PlSplitViewRightSideDirective {
  constructor(
    public readonly templateRef: TemplateRef<void>
  ) {
  }
}
