<div class="bancos-extrato-module-edit-bank-account-modal">
  <pl-form [submit]="close" plPromise>
    <div class="modal-header">
      <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'bancosextrato.editbankaccountmodal.title'" [translateParams]="{nomeBanco: model.nomeBanco}"></h5>
    </div>

    <div class="modal-body entity-detail-form">
      <pl-group>
        <label [translate]="'bancosextrato.editbankaccountmodal.fields.descricao'"></label>
        <edit>
          <pl-edit-text attrName="descricao" [(model)]="model.descricao" plAutoFocus></pl-edit-text>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'bancosextrato.editbankaccountmodal.fields.conta'"></label>
        <edit>
          <entity-autocomplete
            entity="pocs"
            attrName="conta"
            [model]="model"
            (selectedKeyChange)="model.nConta = $event"
            (evtSelectedDescriptionChanged)="model.nomeConta = $event"
            [filter]="filterConta"
            [fieldsMap]="{nome: 'nomeConta'}">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'bancosextrato.editbankaccountmodal.fields.nomeBanco'"></label>
        <edit>
          <div class="form-control-align">{{ model.nomeBanco }}</div>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'bancosextrato.editbankaccountmodal.fields.iban'"></label>
        <edit>
          <div class="form-control-align">{{ model.iban }}</div>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'bancosextrato.fields.diarioRecebimentos'"></label>
        <edit>
          <entity-autocomplete
            entity="diarios"
            attrName="diariorecebimentos"
            [model]="model"
            (selectedKeyChange)="model.nDiarioRecebimentos = $event"
            (evtSelectedDescriptionChanged)="model.nomeDiarioRecebimentos = $event"
            [fieldsMap]="{nDiario: 'nDiarioRecebimentos', nome: 'nomeDiarioRecebimentos'}"
            [properties]="{placeholder: placeholderDiarioRecebimentos}">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'bancosextrato.fields.diarioPagamentos'"></label>
        <edit>
          <entity-autocomplete
            entity="diarios"
            attrName="diariopagamentos"
            [model]="model"
            (selectedKeyChange)="model.nDiarioPagamentos = $event"
            (evtSelectedDescriptionChanged)="model.nomeDiarioPagamentos = $event"
            [fieldsMap]="{nDiario: 'nDiarioPagamentos', nome: 'nomeDiarioPagamentos'}"
            [properties]="{placeholder: placeholderDiarioPagamentos}">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <edit>
          <span [translate]="'bancosextrato.text.infoEditBankAccountDiarioInheritance'"></span>
        </edit>
      </pl-group>

      <fieldset>
        <legend [translate]="'bancosextrato.editbankaccountmodal.text.saldo'"></legend>
        <div>
          <pl-group>
            <label [translate]="'bancosextrato.editbankaccountmodal.fields.saldoData'"></label>
            <edit>
              <pl-edit type="date" attrName="saldoData" [(model)]="model.saldoData"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'bancosextrato.editbankaccountmodal.fields.saldo'"></label>
            <edit>
              <pl-edit type="number" attrName="saldoValor" [(model)]="model.saldoValor"></pl-edit>
            </edit>
          </pl-group>
        </div>
      </fieldset>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button type="submit" klass="btn-primary action-submit" [disabled]="closeDisabled"><i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

      <pl-button type="button" klass="btn-danger action-delete" [disabled]="closeDisabled" [onClick]="deleteAccount">
        <i class="fa fa-fw fa-trash-o"></i>&nbsp;<span [translate]="'global.btn.delete'"></span>
      </pl-button>

      <pl-button klass="btn-light action-cancel" [disabled]="closeDisabled" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
    </div>
  </pl-form>
</div>
