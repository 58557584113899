<div class="ativosaquisicaoinvestemcurso entity-detail-form">
  <div *ngIf="aquisicaoInvestEmCursoPorDocDocumentos.listaDocumentos.length > 0 && aquisicaoInvestEmCursoPorDocDocumentos.listaAtivos.length <= 0">
    <pl-alert type="info" theme="m-0" [closeable]="false">
      <span [translate]="'ativosaquisicaoinvestemcursopordoc.messages.nextStepAtivosList'"></span>
    </pl-alert>
  </div>

  <fieldset class="mt-3">
    <legend [translate]="'ativosaquisicaoinvestemcursopordoc.documentos'"></legend>
    <div class="row">
      <pl-group>
        <pl-group>
          <label [translate]="'ativosaquisicaoinvestemcursopordoc.fields.radical'"></label>
          <edit>
            <div class="form-control-align">{{ aquisicaoInvestEmCursoPorDocDocumentos.resumo.radical }}</div>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'ativosaquisicaoinvestemcursopordoc.fields.valor'"></label>
          <edit>
            <div class="form-control-align">{{ aquisicaoInvestEmCursoPorDocDocumentos.resumo.valorRadical | cgcDecimal: 2 }}</div>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'ativosaquisicaoinvestemcursopordoc.fields.transferido'"></label>
          <edit>
            <div class="form-control-align">{{ aquisicaoInvestEmCursoPorDocDocumentos.resumo.jaAssociado | cgcDecimal: 2 }}</div>
          </edit>
        </pl-group>
      </pl-group>
    </div>

    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinitionDocumentos"
      cgDxDataGridInstanceName="documentosLista"
      [dataSource]="aquisicaoInvestEmCursoPorDocDocumentos.listaDocumentos"
      (onInitialized)="onInitializedDocumentos($event)"
      (onCellClick)="onCellClickDocumentos($event)"
      (onRowRemoved)="onRowRemovedDocumentos($event)">
      <div *dxTemplate="let item of 'detailTemplateDocContabilidade'">
        <documento-contabilidade [(doc)]="item.data._docContabilidade" (evtActionDeleted)="actionDocumentoDeletedDocumentos($event)" [maintenanceMode]="true"></documento-contabilidade>
      </div>
    </dx-data-grid>
  </fieldset>

  <fieldset class="mt-4">
    <legend [translate]="'ativosaquisicaoinvestemcursopordoc.ativosFieldset'"></legend>
    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinitionAtivosDocumentos"
      cgDxDataGridInstanceName="ativosaquisicaoinvestemcursopordoc"
      [dataSource]="aquisicaoInvestEmCursoPorDocDocumentos.listaAtivos"
      (onInitialized)="onInitializedAtivosDocumentos($event)"
      (onRowRemoved)="onRowRemovedAtivosDocumentos()">
    </dx-data-grid>
  </fieldset>
</div>
