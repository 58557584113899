import {HttpResponse} from '@angular/common/http';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {EDateMonth, IPlToolbarItemDefinition, IPlToolbarMenuItem} from 'pl-comps-angular';
import {DATA_SOURCE_MESES} from '../../../../datasources/meses/meses.datasource';
import {ENTITY_NAME_ANOS_FATURACAO} from '../../../../entities/anosfaturacao/anosFaturacao.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IDataSourceItem} from '../../../../components/datasource/datasources.interface';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IIvaListagemDocumento, TIvaListagemDocumentoKeyValor} from '../resumoIva.module.interface';
import {IJsonAnosFaturacao} from '../../../../entities/anosfaturacao/jsonAnosFaturacao.entity.interface';
import {IJsonIvaListagem, IJsonIvaListagemDocumento} from '../../../../entities/ivas/jsonIva.entity.interface';
import {IApiQueryResponse} from '../../../../services/api/api.service.interface';
import {ListagensService} from '../../../../services/listagens.service';
import {ModuloComponent} from '../../../../components/module/module.component';
import moment, {Moment} from 'moment';

const currentYear: number = moment().year();
const PAGE = 1;
const PER_PAGE = 10;
const ORDER = 'ano desc';
const PRIMEIRO_TRIMESTRE = 13;
const SEGUNDO_TRIMESTRE = 14;
const TERCEIRO_TRIMESTRE = 15;
const QUARTO_TRIMESTRE = 16;

@Component({
  selector: 'module-resumo-iva',
  templateUrl: './resumoIva.module.component.html'
})
export class ResumoIvaModuleComponent extends ModuloComponent implements OnInit {
  @Input() public listagensIva: IJsonIvaListagem;

  public dataGrid: IDevExpressDataGrid;
  public listagemDocumentos: Array<IIvaListagemDocumento>;

  private readonly _mnuAnos: IPlToolbarItemDefinition;
  private readonly _mnuMeses: IPlToolbarItemDefinition;
  private readonly _anosFaturacaoService: IEntityService<IJsonAnosFaturacao>;
  private _meses: Array<IPlToolbarMenuItem>;
  private _selectedAno: IPlToolbarMenuItem;
  private _selectedMes: IPlToolbarMenuItem;
  private _promise: Promise<void>;
  private _anoEmCurso: number;
  private _mesEmCurso: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _listagensFactory: ListagensService
  ) {
    super(_injector);
    this._anosFaturacaoService = this._entityServiceBuilder.build(ENTITY_NAME_ANOS_FATURACAO);
    this._mnuAnos = {
      id: 'anos',
      order: 1,
      caption: 'resumoiva.btn.ano',
      type: 'dropdown',
      class: 'btn-primary',
      menu: []
    };
    this._mnuMeses = {
      id: 'meses',
      order: 2,
      caption: 'resumoiva.btn.mes',
      type: 'dropdown',
      class: 'btn-primary',
      menu: []
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._loadMenus();
    this._evaluateResumoIva();
  }

  public get promise(): Promise<void> {
    return this._promise;
  }

  public set promise(value: Promise<void>) {
    this._promise = value;
    this._promise.finally(() => {
      this._promise = undefined;
    });
  }

  private _loadMenus(): void {
    this._anoEmCurso = currentYear;
    this._mesEmCurso = -1;
    const anos: Array<IPlToolbarMenuItem> = [];
    let addedCurrentYear = false;
    this._anosFaturacaoService
      .query({ordena: ORDER, pagina: PAGE, porpagina: PER_PAGE})
      .then((response: HttpResponse<IApiQueryResponse<IJsonAnosFaturacao>>) => {
        for (const item of response.body.list) {
          if (item.ano === currentYear) {
            addedCurrentYear = true;
          }
          const itemAno: IPlToolbarMenuItem = {
            caption: String(item.ano),
            active: item.ano === this._anoEmCurso,
            click: (menuItem: IPlToolbarMenuItem) => {
              this._anoChanged(menuItem);
            }
          };
          if (itemAno.active) {
            this._selectedAno = itemAno;
          }
          anos.push(itemAno);
        }
      })
      .finally(() => {
        if (!addedCurrentYear) {
          anos.unshift({
            caption: String(currentYear),
            active: currentYear === this._anoEmCurso,
            click: (menuItem: IPlToolbarMenuItem) => {
              this._anoChanged(menuItem);
            }
          });
          const itemAno: IPlToolbarMenuItem = anos[0];
          if (itemAno.active) {
            this._selectedAno = itemAno;
          }
        }
        this._mnuAnos.caption = this._getAnoCaption();
        this._mnuAnos.menu = anos;

        this._meses = DATA_SOURCE_MESES.data.map<IPlToolbarMenuItem>((item: IDataSourceItem<EDateMonth>) => {
          const month: number = item.value - 1;
          const itemMes: IPlToolbarMenuItem = {
            id: String(month),
            caption: this._translateService.instant(item.name),
            active: month === this._mesEmCurso,
            click: (menuItem: IPlToolbarMenuItem) => {
              this._mesChanged(menuItem);
            }
          };
          return itemMes;
        });
        const todosMeses: IPlToolbarMenuItem = {
          id: String(-1),
          caption: this._translateService.instant('resumoiva.btn.todosmeses'),
          active: true,
          click: (menuItem: IPlToolbarMenuItem) => {
            this._mesChanged(menuItem);
          }
        };
        if (this._configService.configurations.empresa.periodoIvaTrimestral) {
          const primeiroTrimestre: IPlToolbarMenuItem = {
            id: String(PRIMEIRO_TRIMESTRE),
            caption: this._translateService.instant('resumoiva.btn.primeiroTrimestre'),
            active: false,
            click: (menuItem: IPlToolbarMenuItem) => {
              this._mesChanged(menuItem);
            }
          };
          const segundoTrimestre: IPlToolbarMenuItem = {
            id: String(SEGUNDO_TRIMESTRE),
            caption: this._translateService.instant('resumoiva.btn.segundoTrimestre'),
            active: false,
            click: (menuItem: IPlToolbarMenuItem) => {
              this._mesChanged(menuItem);
            }
          };
          const terceiroTrimestre: IPlToolbarMenuItem = {
            id: String(TERCEIRO_TRIMESTRE),
            caption: this._translateService.instant('resumoiva.btn.terceiroTrimestre'),
            active: false,
            click: (menuItem: IPlToolbarMenuItem) => {
              this._mesChanged(menuItem);
            }
          };
          const quartoTrimestre: IPlToolbarMenuItem = {
            id: String(QUARTO_TRIMESTRE),
            caption: this._translateService.instant('resumoiva.btn.quartoTrimestre'),
            active: false,
            click: (menuItem: IPlToolbarMenuItem) => {
              this._mesChanged(menuItem);
            }
          };
          this._meses.unshift(primeiroTrimestre, segundoTrimestre, terceiroTrimestre, quartoTrimestre);
        }
        this._selectedMes = todosMeses;
        this._meses.unshift(todosMeses);
        this._mnuMeses.caption = this._getMesCaption();
        this._mnuMeses.menu = this._meses;

        this.toolbar.addButton(this._mnuAnos);
        this.toolbar.addButton(this._mnuMeses);
      });
  }

  private _anoChanged(menuItem: IPlToolbarMenuItem): void {
    if (menuItem.caption !== String(this._anoEmCurso)) {
      if (this._selectedAno) {
        this._selectedAno.active = false;
      }
      this._selectedAno = menuItem;
      this._selectedAno.active = true;
      this._anoEmCurso = Number(menuItem.caption);
      this._mnuAnos.caption = this._getAnoCaption();
      this._loadListagemArtigos();
    }
  }

  private _mesChanged(menuItem: IPlToolbarMenuItem): void {
    if (menuItem.id !== String(this._mesEmCurso)) {
      if (this._selectedMes) {
        this._selectedMes.active = false;
      }
      this._selectedMes = menuItem;
      this._selectedMes.active = true;
      this._mesEmCurso = Number(menuItem.id);
      this._mnuMeses.caption = this._getMesCaption();
      this._loadListagemArtigos();
    }
  }

  private _getAnoCaption(): string {
    return this._translateService.instant('toolbar.year', {value: this._anoEmCurso});
  }

  private _getMesCaption(): string {
    const idx = this._meses.findIndex((value: IPlToolbarMenuItem) => value.id === String(this._mesEmCurso));
    if (idx !== -1) {
      const nomeMesEmCurso = this._meses[idx].caption;
      return this._translateService.instant('resumoiva.toolbar.month', {nameMonth: nomeMesEmCurso});
    }
    return '';
  }

  private _loadListagemArtigos(): Promise<void> {
    const date: {start: Moment; end: Moment} = this._listagensFactory.convertAnoMesToDate(Number(this._selectedAno.caption), Number(this._selectedMes.id));
    this.promise = this._listagensFactory.listagensIva(date.start, date.end).then((response: HttpResponse<IJsonIvaListagem>) => {
      this.listagensIva = response.body;
      this._evaluateResumoIva();
    });
    return this.promise;
  }

  private _evaluateResumoIva(): void {
    this.dataGrid = {
      columns: [
        {
          caption: '',
          columns: [{dataField: 'tipoDoc', dataType: 'string', caption: 'resumoiva.fields.tipodocumento'}]
        },
        {
          caption: 'resumoiva.fields.total',
          columns: [
            {dataField: 'totalLiquido', dataType: 'double', caption: 'resumoiva.fields.basetributavel'},
            {dataField: 'totalIVA', dataType: 'double', caption: 'resumoiva.fields.iva'}
          ]
        }
      ],
      summary: {
        totalItems: [
          {
            name: 'tipoDocSummary',
            showInColumn: 'tipoDoc',
            summaryType: 'custom',
            alignment: 'right'
          }
        ],
        calculateCustomSummary: (options) => {
          if (options.summaryProcess === 'finalize') {
            if (options.name === 'tipoDocSummary') {
              options.totalValue = this._translateService.instant('resumoiva.fields.totais');
            }
          }
        }
      },
      pager: {visible: false},
      paging: {enabled: false},
      scrolling: {rowRenderingMode: 'virtual'},
      height: '80vh',
      remoteOperations: false
    };

    const valoresDataFields = new Map<number, Map<TIvaListagemDocumentoKeyValor, string>>();
    for (const taxaItem of this.listagensIva.resumo) {
      const dataFieldValorLiquido = `iva_${taxaItem.taxa}_valorLiquido`;
      const dataFieldValorIVA = `iva_${taxaItem.taxa}_valorIVA`;
      const itemCol: IDevExpressDataGridColumn = {
        caption: String(this._translateService.instant('resumoiva.fields.iva')) + taxaItem.nome,
        columns: [
          {dataField: dataFieldValorLiquido, dataType: 'double', caption: 'resumoiva.fields.basetributavel'},
          {dataField: dataFieldValorIVA, dataType: 'double', caption: 'resumoiva.fields.iva'}
        ]
      };

      const dataFields: Map<TIvaListagemDocumentoKeyValor, string> = new Map<'valorLiquido' | 'valorIVA', string>([
        ['valorLiquido', dataFieldValorLiquido],
        ['valorIVA', dataFieldValorIVA]
      ]);
      valoresDataFields.set(taxaItem.taxa, dataFields);

      this.dataGrid.columns.splice(this.dataGrid.columns.length - 1, 0, itemCol);
    }

    const documentos: Array<IJsonIvaListagemDocumento> = this.listagensIva.documentos.slice();
    this.listagemDocumentos = documentos.map<IIvaListagemDocumento>((documento: IJsonIvaListagemDocumento) => {
      const listagemDocumento: IIvaListagemDocumento = {
        ...documento,
        tipoDoc: documento.nDocfa || documento.nDocfa === 0 ? `${documento.nome} (${documento.nDocfa})` : documento.nome
      };

      // Set dataField values for existing `taxas`
      const taxasExistentes: Set<number> = new Set<number>();
      for (const taxaDocumento of documento.valores) {
        taxasExistentes.add(taxaDocumento.taxa);
        const dataFields: Map<TIvaListagemDocumentoKeyValor, string> = valoresDataFields.get(taxaDocumento.taxa);
        const dataFieldValorLiquido: string = dataFields.get('valorLiquido');
        const dataFieldValorIVA: string = dataFields.get('valorIVA');
        listagemDocumento[dataFieldValorLiquido] = taxaDocumento.valorLiquido;
        listagemDocumento[dataFieldValorIVA] = taxaDocumento.valorIVA;
      }

      // Set default dataField values for non existing `taxas`
      for (const taxaItem of this.listagensIva.resumo) {
        if (!taxasExistentes.has(taxaItem.taxa)) {
          const dataFields: Map<TIvaListagemDocumentoKeyValor, string> = valoresDataFields.get(taxaItem.taxa);
          const dataFieldValorLiquido: string = dataFields.get('valorLiquido');
          const dataFieldValorIVA: string = dataFields.get('valorIVA');
          listagemDocumento[dataFieldValorLiquido] = 0;
          listagemDocumento[dataFieldValorIVA] = 0;
        }
      }

      return listagemDocumento;
    });

    for (const column of this.dataGrid.columns) {
      if (column.caption === '') {
        continue;
      }
      for (const dataColumn of column.columns) {
        this.dataGrid.summary.totalItems.push({
          column: dataColumn.dataField,
          summaryType: 'sum',
          valueFormat: 'double',
          displayFormat: '{0}'
        });
      }
    }
  }
}
