<div class="utilizadores-edit entity-detail-form">
  <pl-form [properties]="{validators: {required: {value: true}}}">
    <pl-group>
      <pl-group>
        <label [translate]="'assistenteconfigportais.fields.nomeutilizador'"></label>
        <edit>
          <pl-edit type="string" attrName="username" [(model)]="user.username"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'assistenteconfigportais.fields.email'"></label>
        <edit>
          <pl-edit type="email" attrName="email" [(model)]="user.email"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'assistenteconfigportais.fields.nome'"></label>
        <edit>
          <pl-edit type="string" attrName="firstName" [(model)]="user.firstName"></pl-edit>
        </edit>
      </pl-group>

      <pl-group [properties]="{validators: {required: {value: false}}}">
        <label [translate]="'assistenteconfigportais.fields.apelido'"></label>
        <edit>
          <pl-edit type="string" attrName="lastName" [(model)]="user.lastName"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <label [translate]="'assistenteconfigportais.fields.activo'"></label>
      <edit>
        <pl-edit type="boolean" attrName="activo" [(model)]="user.active"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'assistenteconfigportais.fields.enviaEmail'"></label>
      <edit>
        <pl-edit type="boolean" attrName="enviaEmail" [(model)]="enviaEmail"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'assistenteconfigportais.outros.empresa'"></label>
        <edit>
          <entity-autocomplete
            entity="empresas"
            attrName="empresa"
            [model]="empresa"
            (modelChange)="changedEmpresa($event)"
            [filter]="filterEmpresas"
            [getDataConfig]="{params: {centralGestId: centralGestId}}"
            [properties]="{allowInvalid: false}">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'assistenteconfigportais.fields.addempregado'"></label>
        <edit>
          <pl-edit type="boolean" attrName="associarEmpregado" [(model)]="associarEmpregado" [properties]="{validators: {required: {value: false}}}"> </pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group *ngIf="associarEmpregado">
      <pl-group>
        <label [translate]="'assistenteconfigportais.fields.codempregado'"></label>
        <edit>
          <entity-autocomplete entity="dgemps" attrName="codEmpregado" [(model)]="empregado" [filter]="filterEmpregados" [properties]="{allowInvalid: false}"> </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>
  </pl-form>
</div>
