<pl-form [submit]="close" plPromise>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'nifs.registar'"></h4>
  </div>

  <div class="modal-body">
    <nifs [model]="nif" [type]="type"></nifs>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button type="submit" klass="btn-primary"> <i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span> </pl-button>
    <pl-button klass="btn-light" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</pl-form>
