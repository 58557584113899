import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonAtivos} from '../../interfaces/jsonAtivos.entity.interface';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {AtivosMudarContaService} from '../../services/ativosMudarConta.service';

@Component({
  selector: 'modal-ativos-mudar-taxas-dep',
  templateUrl: './ativosMudarTaxasDep.modal.component.html'
})
export class AtivosMudarTaxasDepModalComponent extends CGModalComponent<IJsonAtivos> {
  @Input() public ativo: IJsonAtivos;
  public contabTaxaAmortiza: number;
  public fiscalTaxaAmortiza: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosMudarContaService: AtivosMudarContaService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.contabTaxaAmortiza = 0;
    this.fiscalTaxaAmortiza = 0;
  }

  public close(): Promise<void> {
    return this._cgModalService.showOkCancel('ativosmudartaxasdep.confirmacao', 'ativosmudartaxasdep.temcerteza').then(() => {
      return this._ativosMudarContaService.postRegistaAlteracaoTaxasDepreciacao(this.ativo, this.contabTaxaAmortiza, this.fiscalTaxaAmortiza).then((response: HttpResponse<IJsonAtivos>) => {
        this._plAlertService.success('ativosmudartaxasdep.message.success');
        super.close(response.body);
      });
    });
  }
}
