<div class="intfac-module entity-detail-form">
  <pl-navpill attrName="tiposDocumento" class="tipos-documento" [callback]="navPillCallback" (evtSelected)="changedNavPill($event)">
    <pl-navpill-panel id="vendas">
      <div *plNavPillPanelTitle [translate]="'intfac.title.vendas'"></div>

      <div *plNavPillPanelContent>
        <ng-container *ngTemplateOutlet="templateHeaderContent; context: {source: grupoVenda, selectedAllHeader: selectedAllVendas}"></ng-container>
      </div>
    </pl-navpill-panel>

    <pl-navpill-panel id="compras">
      <div *plNavPillPanelTitle [translate]="'intfac.title.compras'"></div>

      <div *plNavPillPanelContent>
        <ng-container *ngTemplateOutlet="templateHeaderContent; context: {source: grupoCompra, selectedAllHeader: selectedAllCompras}"></ng-container>
      </div>
    </pl-navpill-panel>

    <pl-navpill-panel id="outros">
      <div *plNavPillPanelTitle [translate]="'intfac.title.outros'"></div>

      <div *plNavPillPanelContent>
        <ng-container *ngTemplateOutlet="templateHeaderContent; context: {source: grupoOutros, selectedAllHeader: selectedAllOutros}"></ng-container>
      </div>
    </pl-navpill-panel>
  </pl-navpill>

  <hr />

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="intFac"
    [dataSource]="dataGridDefinition.dataSource"
    [(selectedRowKeys)]="selectedKeys"
    (onInitialized)="onInitialized($event)"
    (onCellPrepared)="onCellPrepared($event)"
    (onRowExpanding)="onRowExpanding($event)"
    (onSelectionChanged)="onSelectionChanged()">
    <div *dxTemplate="let item of 'toolbarTemplate'">
      <label [translate]="'intfac.title.docnaointcontab'"></label>
    </div>

    <div *dxTemplate="let itemDetail of 'templateMasterDetail'">
      <dx-data-grid
        *ngIf="itemDetail.data._errorList && itemDetail.data._errorList.length > 0"
        [cgDxDataGrid]="dataGridDefinitionListaInteg"
        cgDxDataGridInstanceName="intFacListaInteg"
        [dataSource]="itemDetail.data._errorList"
        (onContentReady)="onContentReadyListaInteg($event)">
        <div *dxTemplate="let itemListError of 'templateMasterDetailListInteg'">
          <div class="text-error">{{ itemListError.data.error }}</div>
        </div>
      </dx-data-grid>

      <div class="intfac-doc-contabilidade" *ngIf="itemDetail.data.faccbStatus === faccbStatuses.TerminadoComErro && itemDetail.data.extPocCabID">
        <hr *ngIf="itemDetail.data._errorList && itemDetail.data._errorList.length > 0" />

        <div class="intfac-doc-contabilidade-toolbar">
          <pl-toolbar [instanceId]="itemDetail.data._docToolbarId"></pl-toolbar>
        </div>

        <doccontabilidade
          [model]="itemDetail.data._docContab"
          (modelChange)="itemDetail.data._docContab = $event"
          [editing]="true"
          [toolbarInstanceName]="itemDetail.data._docToolbarId"
          plLifeCycle
          (evtInit)="onDocContabilidadeInit(itemDetail.data, $event)"
          (evtDestroy)="onDocContabilidadeDestroy(itemDetail.data)">
        </doccontabilidade>
      </div>
    </div>
  </dx-data-grid>
</div>

<ng-template #templateHeaderContent let-source="source" let-selectedAllHeader="selectedAllHeader">
  <pl-edit type="boolean" [model]="selectedAllHeader" (modelChange)="selectedAllGoupSelectChange($event, source)" [properties]="{label: 'intfac.title.selectAll'}"></pl-edit>

  <hr class="intfac-module-linerow" />

  <div class="intfac-module-group-items">
    <div class="intfac-module-group-item" *ngFor="let item of source; index as i">
      <pl-edit type="boolean" [model]="item._selected" (modelChange)="changedGrupoSelected($event, item)" [properties]="{label: item._label}"></pl-edit>
    </div>
  </div>
</ng-template>
