<div class="entity-detail-form">
  <pl-form>
    <pl-group>
      <pl-group>
        <label>Serviço Atual:</label>
        <edit>
          <entity-autocomplete
            entity="prhservicos"
            attrName="servico"
            [model]="servicoFilter"
            (modelChange)="selectServicoFilter($event)"
            (selectedKeyChange)="servicoFilter.codServico = $event"
            (evtSelectedDescriptionChanged)="servicoFilter.nome = $event">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label>Serviço de Destino:</label>
        <edit>
          <entity-autocomplete entity="prhservicos" attrName="servicoDestino" [model]="servico" [(selectedKey)]="servico.codServico" (evtSelectedDescriptionChanged)="servico.nome = $event">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <br />

    <pl-group>
      <edit>
        <pl-multiselect [model]="colaboradoresSelecionados" [source]="colaboradoresDisponiveis" key="codEmp" [template]="multiselectTemplate"> </pl-multiselect>
      </edit>
    </pl-group>
  </pl-form>
</div>
