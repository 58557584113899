import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {AtivosService} from '../../services/ativos.service';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService} from '../../../../modules/portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IAtivosPesquisaContabilidade} from '../../../../modules/ativos/ativospesquisacontabilidade/ativosPesquisaContabilidade.module.interface';
import {IJsonAtivos} from '../../interfaces/jsonAtivos.entity.interface';
import {IJsonDocContabilidade} from '../../../../modules/portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized, IDevExpressDataGridEventOnRowExpanding} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';

@Component({
  selector: 'modal-ativos-pesquisa-contabilidade',
  templateUrl: './ativosPesquisaContabilidade.modal.component.html'
})
export class AtivosPesquisaContabilidadeModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public ativo: IJsonAtivos;
  public readonly dataGridDefinition: IDevExpressDataGrid;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private _dataGridInstance: dxDataGrid;
  private _superModalInstanceDismiss: Function;
  private _needsUpdate: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosService: AtivosService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'tipoOperacao', dataType: 'string', caption: 'ativos.table.tipoOperacao'},
        {dataField: 'periodo', dataType: 'string', caption: 'ativos.table.periodo'},
        {dataField: 'ndiario', dataType: 'number', caption: 'ativos.table.ndiario'},
        {dataField: 'ndocInterno', dataType: 'number', caption: 'ativos.table.ndocInterno'},
        {dataField: 'datadoc', dataType: 'date', caption: 'ativos.table.dataDoc'},
        {dataField: 'datadocexterno', dataType: 'date', caption: 'ativos.table.dataDocExterno'}
      ],
      dataSource: [],
      export: {filename: 'ativos.actions.regcontab'},
      keyExpr: 'extPocCabId',
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateDocContabilidade'},
      remoteOperations: false
    };
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._needsUpdate = false;
  }

  public ngOnInit(): void {
    this._superModalInstanceDismiss = this._modalInstance.dismiss.bind(this._modalInstance);
    this._modalInstance.dismiss = (reason?: unknown) => {
      this._modalInstanceDismiss(reason);
    };
    this._getListaAtivosPesquisaContabilidade();
  }

  public close(): void {
    if (!this._needsUpdate) {
      this._superModalInstanceDismiss();
    } else {
      super.close();
    }
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public onRowExpanding(event: IDevExpressDataGridEventOnRowExpanding<IAtivosPesquisaContabilidade, string>): void {
    const items: Array<IAtivosPesquisaContabilidade> = this._dataGridInstance.getDataSource().items();
    const index: number = items.findIndex((item: IAtivosPesquisaContabilidade) => item.extPocCabId === event.key);
    if (index !== -1 && !this.dataGridDefinition.dataSource[index]._docContabilidade) {
      this._serviceDocsContabilidade.get({id: (<IAtivosPesquisaContabilidade>this.dataGridDefinition.dataSource[index]).extPocCabId}).then((response: HttpResponse<IJsonDocContabilidade>) => {
        this.dataGridDefinition.dataSource[index]._docContabilidade = response.body;
      });
    }
  }

  public readonly fnDocumentoContabilidadeAction: () => Promise<void> = () => this._onDocumentoContabilidadeAction();

  private _getListaAtivosPesquisaContabilidade(): Promise<void> {
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    return this._ativosService
      .getAtivosPesquisaContabilidade(this.ativo.codAtivo)
      .then((response: HttpResponse<Array<IAtivosPesquisaContabilidade>>) => {
        this.dataGridDefinition.dataSource = response.body;
      })
      .finally(() => {
        if (this._dataGridInstance) {
          this._dataGridInstance.endCustomLoading();
        }
      });
  }

  private _onDocumentoContabilidadeAction(): Promise<void> {
    this._needsUpdate = true;
    return this._getListaAtivosPesquisaContabilidade();
  }

  private _modalInstanceDismiss(reason?: unknown): void {
    if (!this._needsUpdate) {
      this._superModalInstanceDismiss(reason);
    } else {
      this.close();
    }
  }
}
