import {AfterContentChecked, Component, ContentChildren, ElementRef, Input, NgZone, OnChanges, OnDestroy, QueryList, SimpleChanges, ViewChild} from '@angular/core';
import {isArray, isNumber, isObject} from '../common/utilities/utilities';
import {PlVirtualScrollingContentDirective} from './virtual.scrolling.content.directive';
import type {IPlVirtualScrolling, IPlVirtualScrollingCallback} from './virtual.scrolling.interface';

const DEFAULT_MIN_BUFFER_PX = 200;
const DEFAULT_MAX_BUFFER_PX = DEFAULT_MIN_BUFFER_PX * 2;

@Component({
  selector: 'pl-virtual-scrolling',
  templateUrl: './virtual.scrolling.component.html'
})
export class PlVirtualScrollingComponent<T> implements OnChanges, OnDestroy, AfterContentChecked, IPlVirtualScrolling<T> {
  @Input() public items: Array<T>;
  @Input() public rowHeight: number;
  @Input() public minBufferPx: number;
  @Input() public maxBufferPx: number;
  @Input() public scaleOffset: number;
  @Input() public disabled: boolean;
  @Input() public callback: IPlVirtualScrollingCallback<T>;

  public itemsInView: Array<T>;
  public startIndex: number;
  public endIndex: number;
  public templateContent: PlVirtualScrollingContentDirective<T>;

  @ContentChildren(PlVirtualScrollingContentDirective, {descendants: false}) private readonly _templateContent: QueryList<PlVirtualScrollingContentDirective<T>>;
  private _container: HTMLElement;
  private _elementListView: HTMLElement;
  private _elementListSpacer: HTMLElement;
  private _animationFrameListView: number;
  private _animationFrameListSpacer: number;

  constructor(private readonly _ngZone: NgZone) {
    this.rowHeight = 0;
    this.minBufferPx = DEFAULT_MIN_BUFFER_PX;
    this.maxBufferPx = DEFAULT_MAX_BUFFER_PX;
    this.itemsInView = [];
    this.startIndex = 0;
    this.endIndex = 0;
    this.scaleOffset = 0;
    this.disabled = false;
  }

  public ngOnChanges({items, rowHeight, minBufferPx, maxBufferPx, callback}: SimpleChanges): void {
    const itemsChanged: boolean = items && !items.isFirstChange();
    const rowHeightChanged: boolean = rowHeight && !rowHeight.isFirstChange();
    const bufferPxChanged: boolean = (minBufferPx && !minBufferPx.isFirstChange()) || (maxBufferPx && !maxBufferPx.isFirstChange());
    if (rowHeightChanged) {
      this._evaluateListSpacer();
    } else if (bufferPxChanged) {
      this._evaluateListView();
    }
    if (itemsChanged) {
      if (!rowHeightChanged) {
        this._evaluateListSpacer();
      }
      this._scrolled();
    }
    if (callback) {
      const cb: IPlVirtualScrollingCallback<T> = callback.currentValue;
      if (isObject(cb)) {
        cb.startIndex = () => this.startIndex;
        cb.endIndex = () => this.endIndex;
        cb.calculateRealIndex = (index: number, startIndex: number = this.startIndex) => startIndex + index;
        cb.itemsInView = () => this.itemsInView.slice();
        cb.scrollTop = () => this._scrollTop();
        cb.scrollBottom = () => this._scrollBottom();
        cb.scrollTo = (top: number) => this._scrollTo(top);
      }
    }
  }

  public ngOnDestroy(): void {
    this._clearScrollListener();
    this._clearListViewAnimationFrame();
    this._clearListSpacerAnimationFrame();
  }

  public ngAfterContentChecked(): void {
    this.templateContent = this._templateContent.first;
  }

  @ViewChild('elementList')
  public set elementList(value: ElementRef<HTMLElement>) {
    const wasDefined = Boolean(this._container);
    const element = value?.nativeElement;
    if (element !== this._container) {
      this._clearScrollListener();
    }
    this._container = element;
    if (this._container) {
      this._attachScrollListener();
      if (!wasDefined) {
        this._scrolled();
      }
    }
  }

  @ViewChild('elementListView')
  public set elementListView(value: ElementRef<HTMLElement>) {
    const wasDefined = Boolean(this._elementListView);
    this._elementListView = value?.nativeElement;
    if (this._elementListView && !wasDefined) {
      this._evaluateListSpacer();
      this._evaluateListView();
    }
  }

  @ViewChild('elementListSpacer')
  public set elementListSpacer(value: ElementRef<HTMLElement>) {
    const wasDefined = Boolean(this._elementListSpacer);
    this._elementListSpacer = value?.nativeElement;
    if (this._elementListSpacer && !wasDefined) {
      this._evaluateListSpacer();
    }
  }

  private _attachScrollListener(): void {
    this._clearScrollListener();
    if (!this.disabled) {
      this._container.addEventListener<'scroll'>('scroll', this._fnScrolled, {passive: true});
    }
  }

  private _clearScrollListener(): void {
    if (this._container) {
      this._container.removeEventListener<'scroll'>('scroll', this._fnScrolled);
    }
  }

  private _scrolled(): void {
    if (this.disabled) {
      this.itemsInView = this.items;
      return;
    }
    const notArray = !isArray(this.items);
    if (notArray || !this._container) {
      if (notArray) {
        this.itemsInView = [];
      }
      return;
    }

    const viewportSize: number = this._container.clientHeight;
    const dataLength: number = this.items.length;

    let scrollOffset: number = this._container.scrollTop;
    let firstVisibleIndex: number = scrollOffset / this.rowHeight;

    // If user scrolls to the bottom of the list and data changes to a smaller list
    if (this.endIndex > dataLength) {
      // We have to recalculate the first visible index based on new data length and viewport size.
      const maxVisibleItems = Math.ceil(viewportSize / this.rowHeight);
      const newVisibleIndex = Math.max(0, Math.min(firstVisibleIndex, dataLength - maxVisibleItems));

      // If first visible index changed we must update scroll offset to handle start/end buffers
      // Current range must also be adjusted to cover the new position (bottom of new list).
      if (firstVisibleIndex !== newVisibleIndex) {
        firstVisibleIndex = newVisibleIndex;
        scrollOffset = newVisibleIndex * this.rowHeight;
        this.startIndex = Math.floor(firstVisibleIndex);
      }

      this.endIndex = Math.max(0, Math.min(dataLength, this.startIndex + maxVisibleItems));
    }

    const startBuffer: number = scrollOffset - this.startIndex * this.rowHeight;
    if (startBuffer < this.minBufferPx && this.startIndex !== 0) {
      const expandStart = Math.ceil((this.maxBufferPx - startBuffer) / this.rowHeight);
      this.startIndex = Math.max(0, this.startIndex - expandStart);
      this.endIndex = Math.min(dataLength, Math.ceil(firstVisibleIndex + (viewportSize + this.minBufferPx) / this.rowHeight));
    } else {
      const endBuffer = this.endIndex * this.rowHeight - (scrollOffset + viewportSize);
      if (endBuffer < this.minBufferPx && this.endIndex !== dataLength) {
        const expandEnd = Math.ceil((this.maxBufferPx - endBuffer) / this.rowHeight);
        if (expandEnd > 0) {
          this.endIndex = Math.min(dataLength, this.endIndex + expandEnd);
          this.startIndex = Math.max(0, Math.floor(firstVisibleIndex - this.minBufferPx / this.rowHeight));
        }
      }
    }

    this.itemsInView = this.items.slice(this.startIndex, this.endIndex);
    this._evaluateListView();
  }

  private _evaluateListView(): void {
    this._clearListViewAnimationFrame();
    if (this.disabled) {
      return;
    }
    if (this._elementListView) {
      this._ngZone.runOutsideAngular(() => {
        this._animationFrameListView = window.requestAnimationFrame(() => {
          this._elementListView.style.maxHeight = `${this.minBufferPx}px`;
          const offset: number = this.startIndex * this.rowHeight;
          this._elementListView.style.transform = `translate3d(0, ${offset}px, 0)`;
        });
      });
    }
  }

  private _clearListViewAnimationFrame(): void {
    if (this._animationFrameListView) {
      this._ngZone.runOutsideAngular(() => {
        window.cancelAnimationFrame(this._animationFrameListView);
      });
      this._animationFrameListView = undefined;
    }
  }

  private _evaluateListSpacer(): void {
    this._clearListSpacerAnimationFrame();
    if (this.disabled) {
      return;
    }
    if (this._elementListSpacer) {
      this._ngZone.runOutsideAngular(() => {
        this._animationFrameListSpacer = window.requestAnimationFrame(() => {
          const length: number = isArray(this.items) ? this.items.length : 0;
          let size: number = length * this.rowHeight;
          if (size < this.minBufferPx) {
            size = 0;
          } else {
            if (this._elementListView) {
              size += this._elementListView.offsetTop;
            }
            if (isNumber(this.scaleOffset)) {
              size += this.scaleOffset;
            }
          }
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          this._elementListSpacer.style.height = size + (size > 0 ? 'px' : '');
        });
      });
    }
  }

  private _clearListSpacerAnimationFrame(): void {
    if (this._animationFrameListSpacer) {
      this._ngZone.runOutsideAngular(() => {
        window.cancelAnimationFrame(this._animationFrameListSpacer);
      });
      this._animationFrameListSpacer = undefined;
    }
  }

  private _scrollTop(): boolean {
    return this._scrollTo(0);
  }

  private _scrollBottom(): boolean {
    if (this._container) {
      return this._scrollTo(this._container.scrollHeight);
    }
    return false;
  }

  private _scrollTo(top: number): boolean {
    if (this._container) {
      this._container.scrollTo(0, top);
      return true;
    }
    return false;
  }

  private readonly _fnScrolled: () => void = () => {
    this._scrolled();
  };
}
