import moment from 'moment';
import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {copy, IPlToolbarItem, isEmpty, KEYCODES, PlAlertService} from 'pl-comps-angular';
import {ModuloComponent} from '../../../components/module/module.component';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnSaved
} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {AdiantamentosAutoConfigModalComponent} from '../modals/autoconfig/adiantamentos.autoConfig.modal.component';
import {IJsonAdiantamentoConfig} from '../jsonAdiantamentos.module.interface';
import {AdiantamentosConfigsModalComponent} from '../modals/configs/adiantamentos.configs.modal.component';
import {CGCardPanelComponent} from '../../../components/cg/cardpanel/cardpanel.component';
import {
  EAdiantamentosModoFunc,
  EAdiantamentosTabID,
  IAdiantamentoReg,
  IAdiantamentosParams,
  IAdiantamentosRegistoResult,
  IHeaderAdiantamentos,
  RADIO_GROUP_ADIANTAMENTOS_MODO_FUNC,
  TAdiantamentosType
} from '../adiantamentos.module.interface';
import {AdiantamentosService} from '../adiantamentos.module.service';
import {IJsonNumber} from '../../../../common/interfaces/json';
import {IRadioGroup} from '../../../../common/interfaces/interfaces';
import {IJsonDocComercial} from '../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {IDocComercial, IDocsComerciaisEntity, IDocsComerciaisEntityService} from '../../../entities/docscomerciais/docsComerciais.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {devExpressDataGridExpandDetailHandler} from '../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {AdiantamentosRegistarModalComponent} from '../modals/registar/adiantamentos.registar.modal.component';
import {ENTITY_DOCS_COMERCIAIS_COMPRAS, ENTITY_DOCS_COMERCIAIS_VENDAS} from '../../../entities/docscomerciais/docsComerciais.entity';
import {ENTITY_NAME_CLIENTES, TClifos} from '../../../entities/clifos/clifos.entity.interface';
import {AdiantamentosPrintModalComponent} from '../modals/print/adiantamentos.print.modal.component';
import {IJsonRastreabilidadeFaturacao} from '../../../entities/docscomerciais/modals/rastreabilidade/jsonRastreabilidade.interface';
import {DocumentoRastreabilidadeModalComponent} from '../../../entities/docscomerciais/modals/rastreabilidade/documento.rastreabilidade.modal.component';
import {DocumentoRastreabilidadeService} from '../../../entities/docscomerciais/modals/rastreabilidade/documento.rastreabilidade.service';
import {minDateCG} from '../../../../common/utils/utils';
import {EEntityStateDetailType} from '../../../../common/utils/entity.state.utils';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {IJsonDocfa} from '../../../entities/docfas/jsonDocFa.entity.interface';
import {ENTITY_NAME_DOC_FAS} from '../../../entities/docfas/docFas.entity.interface';
import {IJsonDocfaNum} from '../../../entities/docfasnums/jsonDocfaNum.entity.interface';

@Component({
  selector: 'module-adiantamenos',
  templateUrl: './adiantamentos.module.component.html'
})
export class AdiantamentosModuleComponent extends ModuloComponent implements OnInit {
  @Input() public configAdiantamentos: IJsonAdiantamentoConfig;
  @Input() public type: TAdiantamentosType;

  public dataGridDefinition: IDevExpressDataGrid;
  public readonly adiantamentosTabID: typeof EAdiantamentosTabID;
  public readonly clifosOutput: string;
  public readonly modoFuncionamento: typeof EAdiantamentosModoFunc;
  public readonly radioGroupTemplateModoFunc: IRadioGroup<EAdiantamentosModoFunc>;

  public headerAdiantamentos: IHeaderAdiantamentos;
  public moduleClientes: boolean;
  public activeTabId: string;
  public clifoCaption: string;
  public dataSourcePorRegularizar: Array<IAdiantamentoReg>;
  public dataSourceRegularizado: Array<IAdiantamentoReg>;
  public modoRegularizacao: boolean;

  private readonly _serviceDocsComerciais: IDocsComerciaisEntityService;
  private readonly _adiantamentosParams: IAdiantamentosParams;
  private readonly _btnPrint: IPlToolbarItem;
  private readonly _faccbIdOrigem: number;

  private _dataGridInstance: dxDataGrid;
  private _cardPanel: CGCardPanelComponent;
  private _lastHeaderAdiantamentos: IHeaderAdiantamentos;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _adiantamentosService: AdiantamentosService,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _documentoRastreabilidadeService: DocumentoRastreabilidadeService
  ) {
    super(_injector);
    this._serviceDocsComerciais = this._entityServiceBuilder.build<IJsonDocComercial, IDocsComerciaisEntityService>('docscomerciais');
    this.clifosOutput = '{{nConta}} - {{nome}}';
    this.dataSourcePorRegularizar = [];
    this.dataSourceRegularizado = [];
    this.adiantamentosTabID = EAdiantamentosTabID;
    this.modoFuncionamento = EAdiantamentosModoFunc;
    this.activeTabId = EAdiantamentosTabID.PorRegularizar;
    this._adiantamentosParams = copy(<IAdiantamentosParams>this._transition.params());
    this._faccbIdOrigem = this._adiantamentosParams.faccbId;
    this.modoRegularizacao = !isEmpty(this._adiantamentosParams.clifo) && !isEmpty(this._faccbIdOrigem);
    this.radioGroupTemplateModoFunc = RADIO_GROUP_ADIANTAMENTOS_MODO_FUNC;
    this._btnPrint = {
      id: 'print',
      order: this.btnNovo.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-file-pdf-o fa-fw"></i>',
      class: 'btn-light',
      caption: 'global.btn.print',
      disabled: true,
      click: () => {
        this._openPrintModal();
      }
    };
    this.pesquisar = this.pesquisar.bind(this);
    this._adiantRegistaDoc = this._adiantRegistaDoc.bind(this);
    this._regularizarAdiantamentos = this._regularizarAdiantamentos.bind(this);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.headerAdiantamentos = {
      clifoDe: this.modoRegularizacao ? this._adiantamentosParams.clifo : this.type === 'clientes' ? '21' : '22',
      clifoAte: this.modoRegularizacao ? this._adiantamentosParams.clifo : this.type === 'clientes' ? '2199999' : '2299999',
      nomeClifo: this._adiantamentosParams.nomeClifo,
      dataDe: this.modoRegularizacao ? minDateCG() : moment().startOf('year'),
      dataAte: moment().endOf('month'),
      dataRef: moment(),
      modeFunc: EAdiantamentosModoFunc.Intervalo
    };
    this.btnNovo.click = this._adiantRegistaDoc;
    this.btnNovo.visible = !this.modoRegularizacao;
    this.toolbar.addButton(this._btnPrint).addButton({
      id: 'config',
      order: this._btnPrint.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-cog fa-fw"></i>',
      class: 'btn-light',
      caption: 'global.btn.config',
      click: () => this._openConfigModal()
    });
    this.btnSave.click = this._regularizarAdiantamentos;
    this.btnSave.caption = 'adiantamentos.btns.regularizar';
    this.btnSave.visible = this.modoRegularizacao;
    this.moduleClientes = this.type === 'clientes';
    this.clifoCaption = this.moduleClientes ? 'adiantamentos.cliente' : 'adiantamentos.fornecedor';
    this.evaluateDatagridDefinition();
    if (this.modoRegularizacao) {
      this.pesquisar();
    }
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
    this._dataGridInstance.repaint();
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IAdiantamentoReg>): void {
    if (event.rowType === 'data') {
      if (event.column.dataField === 'valorRegularizar') {
        event.cellElement.classList.add('datagrid-editable-cell');
      }
    }
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IAdiantamentoReg>): void {
    devExpressDataGridExpandDetailHandler(event, () => this._onDocDetail(event));
  }

  public onSaved(event: IDevExpressDataGridEventOnSaved<IAdiantamentoReg>): void {
    this._dataGridInstance.selectRows([event.changes[0].key], true);
  }

  public autoConfigModal(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(AdiantamentosAutoConfigModalComponent);
    const componentInstance: AdiantamentosAutoConfigModalComponent = modalInstance.componentInstance;
    componentInstance.configAdiantamentos = this.configAdiantamentos ? copy(this.configAdiantamentos) : undefined;
    return modalInstance.result.then((result: IJsonAdiantamentoConfig) => {
      this.configAdiantamentos = result;
    });
  }

  public pesquisar(): Promise<void> {
    return this._adiantamentosService
      .loadAdiantamentos(this.headerAdiantamentos, this.activeTabId === EAdiantamentosTabID.PorRegularizar, this.modoRegularizacao)
      .then((response: HttpResponse<Array<IAdiantamentoReg>>) => {
        this._lastHeaderAdiantamentos = copy(this.headerAdiantamentos);
        this._btnPrint.disabled = response.body.length < 1;
        this.evaluateDatagridDefinition();
        if (this.activeTabId === EAdiantamentosTabID.PorRegularizar) {
          this.dataSourcePorRegularizar = response.body;
        } else {
          this.dataSourceRegularizado = response.body;
        }
        this._cardPanel.collapse();
      });
  }

  public evaluateDatagridDefinition(): void {
    if (this.activeTabId === EAdiantamentosTabID.Regularizado) {
      this.headerAdiantamentos.modeFunc = EAdiantamentosModoFunc.Intervalo;
    }

    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [],
      dataSource: [],
      export: {filename: 'global.menu.adiantamentos'},
      keyExpr: 'facliId',
      remoteOperations: false,
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateDocComercial'}
    };

    const columns: Array<IDevExpressDataGridColumn> = [
      {dataField: 'documentoStr', dataType: 'string', caption: 'adiantamentos.tabs.fields.documentoStr', allowEditing: false},
      {dataField: 'data', dataType: 'date', caption: 'adiantamentos.tabs.fields.data', allowEditing: false},
      {dataField: 'origemDocStr', dataType: 'string', caption: 'adiantamentos.tabs.fields.origemDocStr', allowEditing: false},
      {dataField: 'nClifo', dataType: 'string', caption: 'adiantamentos.tabs.fields.nClifo', allowEditing: false},
      {dataField: 'nomeClifo', dataType: 'string', caption: 'adiantamentos.tabs.fields.nomeClifo', allowEditing: false},
      {dataField: 'liquido', dataType: 'double', caption: 'adiantamentos.tabs.fields.liquido', allowEditing: false},
      {dataField: 'valorIva', dataType: 'double', caption: 'adiantamentos.tabs.fields.valorIva', allowEditing: false},
      {dataField: 'total', dataType: 'double', caption: 'adiantamentos.tabs.fields.total', allowEditing: false}
    ];

    if (this.modoRegularizacao && this.activeTabId === EAdiantamentosTabID.PorRegularizar) {
      columns.push({
        dataField: 'valorRegularizar',
        dataType: 'double',
        caption: 'adiantamentos.tabs.fields.valorRegularizar',
        allowEditing: true
      });
      this.dataGridDefinition.editing = {mode: 'cell', startEditAction: 'click', selectTextOnEditStart: true, allowUpdating: true, refreshMode: 'repaint'};
      this.dataGridDefinition.selection = {mode: 'multiple', showCheckBoxesMode: 'always'};
    } else {
      columns.push({
        dataField: 'porRegularizar',
        dataType: 'double',
        caption: 'adiantamentos.tabs.fields.porRegularizar',
        allowEditing: false
      });
    }

    columns.push({dataField: 'estadoStr', dataType: 'string', caption: 'adiantamentos.tabs.fields.estadoStr', allowEditing: false});

    if (this.headerAdiantamentos.modeFunc === EAdiantamentosModoFunc.DataReferencia) {
      columns.push({dataField: 'valorRegularizadoAData', dataType: 'double', caption: 'adiantamentos.tabs.fields.valorRegularizadoAData', allowEditing: false, width: 150});
    }

    columns.push({dataField: 'dataUltRegular', dataType: 'date', caption: 'adiantamentos.tabs.fields.dataUltRegular', allowEditing: false, width: 150});

    if (this.headerAdiantamentos.modeFunc === EAdiantamentosModoFunc.DataReferencia) {
      columns.push({dataField: 'estadoNaDataRefStr', dataType: 'string', caption: 'adiantamentos.tabs.fields.estadoNaDataRefStr', allowEditing: false, width: 150});
    }

    columns.push({type: 'buttons', cellTemplate: 'cellTemplateActions'});

    this.dataGridDefinition.columns = columns;
  }

  public openRastreabilidade(item: IAdiantamentoReg): Promise<void> {
    return this._documentoRastreabilidadeService.getRastreabilidade(item.nDOCFADraft, item.nNUMERDraft, item.nDOCUMENTODraft).then((response: HttpResponse<Array<IJsonRastreabilidadeFaturacao>>) => {
      const modalRef = this._cgModalService.showVanilla(DocumentoRastreabilidadeModalComponent, {size: 'fullscreen'});
      const componentInstance: DocumentoRastreabilidadeModalComponent = modalRef.componentInstance;
      componentInstance.rastreabilidade = response.body;
      componentInstance.faccbId = item.faccbId;
    });
  }

  public readonly fnKeydownLastDate = (value: string, event: KeyboardEvent): void => {
    this._keydownLastDate(event);
  };

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _openConfigModal(): Promise<void> {
    let promise: Promise<void>;
    if (!this.configAdiantamentos?.nArtigoAdiantamento) {
      const modalInstance = this._cgModalService.showVanilla(AdiantamentosAutoConfigModalComponent);
      const componentInstance: AdiantamentosAutoConfigModalComponent = modalInstance.componentInstance;
      componentInstance.configAdiantamentos = undefined;
      promise = modalInstance.result.then((result: IJsonAdiantamentoConfig) => {
        this.configAdiantamentos = result;
      });
    }

    return Promise.resolve(promise).then(() => {
      const modalInstance = this._cgModalService.showVanilla(AdiantamentosConfigsModalComponent);
      const componentInstance: AdiantamentosConfigsModalComponent = modalInstance.componentInstance;
      componentInstance.configAdiantamentos = this.configAdiantamentos ? copy(this.configAdiantamentos) : undefined;
      return modalInstance.result.then((result: IJsonAdiantamentoConfig) => {
        this.configAdiantamentos = result;
      });
    });
  }

  private _keydownLastDate(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      const button: HTMLElement = this._element.querySelector('button.action-search');
      if (button) {
        setTimeout(() => {
          button.focus();
        });
      }
    }
  }

  private async _regularizarAdiantamentos(): Promise<void> {
    const adiantamentos: Array<IAdiantamentoReg> = !this._dataGridInstance ? [] : this._dataGridInstance.getSelectedRowsData();
    if (adiantamentos.length < 1) {
      this._plAlertService.error('adiantamentos.messages.noDocSel');
      return;
    }

    if (this._adiantamentosParams.doc?.cab?.nDocFa) {
      const response: HttpResponse<IDocComercial> = await this._serviceDocsComerciais.post({params: {memoria: 0, terminadoc: 0, novodocsemlinhas: true}, body: this._adiantamentosParams.doc});
      this._adiantamentosParams.faccbId = response.body.cab.faccbId;
      this._adiantamentosParams.doc = response.body;
    }

    const response: HttpResponse<IJsonNumber> = await this._adiantamentosService.regularizarAdiantamentos(this._lastHeaderAdiantamentos, this._adiantamentosParams, adiantamentos);
    this._subjectOnSave.next(Promise.resolve(response.body.value));
  }

  private async _adiantRegistaDoc(): Promise<void> {
    const clifoEntName: TClifos = this.moduleClientes ? ENTITY_NAME_CLIENTES : 'fornecedores';
    const docDefeito =
      clifoEntName === ENTITY_NAME_CLIENTES
        ? this._configService.configurations.documentosComerciais.tipos.vendasEfectivas.documentoPorDefeito ?? undefined
        : this._configService.configurations.documentosComerciais.tipos.comprasEfectivas.documentoPorDefeito ?? undefined;
    let nomeDocFa = '';
    let nNumer: number;
    if (docDefeito) {
      const docFaService: IEntityService<IJsonDocfa> = this._entityServiceBuilder.build(ENTITY_NAME_DOC_FAS);
      await docFaService.get({id: docDefeito}).then((responseDocFa: HttpResponse<IJsonDocfa>) => {
        nomeDocFa = responseDocFa.body.nome;
        nNumer = this._getNNumerDefeitoDocfa(responseDocFa.body.docfanumList);
      });
    }
    const modalInstance = this._cgModalService.showVanilla(AdiantamentosRegistarModalComponent);
    const componentInstance: AdiantamentosRegistarModalComponent = modalInstance.componentInstance;
    componentInstance.clifoEntName = clifoEntName;
    componentInstance.atualDoc = false;
    componentInstance.adiantamentoConfig = this.configAdiantamentos;
    componentInstance.adiantRegisto = {
      nDocFa: docDefeito ?? undefined,
      nomeDocFa: nomeDocFa,
      nNumer: nNumer ?? undefined,
      nConta: '',
      nomeNConta: '',
      codIva: clifoEntName === ENTITY_NAME_CLIENTES ? this.configAdiantamentos.codIvaVenda : this.configAdiantamentos.codIvaCompra,
      nomeCodIva: clifoEntName === ENTITY_NAME_CLIENTES ? this.configAdiantamentos.nomeCodIvaVenda : this.configAdiantamentos.nomeCodIvaCompra,
      taxaIva: clifoEntName === ENTITY_NAME_CLIENTES ? this.configAdiantamentos.taxaIvaVenda : this.configAdiantamentos.taxaIvaCompra,
      valorSemIva: 0,
      valorComIva: 0,
      grupoDocFa: undefined,
      nDoc: '',
      faccbId: undefined
    };
    return modalInstance.result.then((result: IAdiantamentosRegistoResult) => {
      const docsComerciaisEntity: IDocsComerciaisEntity = clifoEntName === ENTITY_NAME_CLIENTES ? ENTITY_DOCS_COMERCIAIS_VENDAS : ENTITY_DOCS_COMERCIAIS_COMPRAS;
      if (result.faccbID > 0) {
        return this._cgStateService.redirectToState({stateOrName: docsComerciaisEntity.name, stateType: EEntityStateDetailType.EDIT, params: {id: result.faccbID}});
      }
      return this._cgStateService.redirectToState({stateOrName: docsComerciaisEntity.name, stateType: EEntityStateDetailType.NEW, params: {adiantamentos: result}});
    });
  }

  private _onDocDetail({data}: IDevExpressDataGridEventOnCellClick<IAdiantamentoReg>): Promise<void> {
    return this._serviceDocsComerciais.get({id: data.faccbId}).then((response: HttpResponse<IJsonDocComercial>) => {
      data._doc = response.body;
    });
  }

  private _openPrintModal(): void {
    const modalInstance = this._cgModalService.showVanilla(AdiantamentosPrintModalComponent);
    const componentInstance: AdiantamentosPrintModalComponent = modalInstance.componentInstance;
    componentInstance.headerAdiantamentos = this._lastHeaderAdiantamentos;
    componentInstance.porRegularizar = this.activeTabId === EAdiantamentosTabID.PorRegularizar;
    componentInstance.modoRegularizacao = this.modoRegularizacao;
  }

  private _getNNumerDefeitoDocfa(docfanumList: Array<IJsonDocfaNum>): number {
    for (const docfanum of docfanumList) {
      if (!docfanum.encerrado && docfanum.visivelERPCloud) {
        return docfanum.nNumer;
      }
    }
    return 1;
  }
}
