<div class="ativos-import-atvaloranualman-modal" [plPromise]="promise">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'ativosimport.modal.atvaloranualman'"></h5>
  </div>

  <div class="modal-body">
    <div class="col-md-12">
      <div class="row">
        <label [translate]="'ativosimport.modal.fields.valoraquisicao'" class="pe-1"></label>
        <p class="card-text">{{ ativoimport.valorAquisicao }}</p>
      </div>
    </div>
    <div class="card-deck">
      <div class="card border-info">
        <div class="card-header bg-transparent border-info" [translate]="'ativosimport.modal.dadoscontab'"></div>
        <div class="card-body">
          <div class="col-md-12">
            <div class="row">
              <label [translate]="'ativosimport.modal.fields.taxaamort'" class="pe-1"></label>
              <p class="card-text">{{ ativoimport.taxaAmortizacao }}</p>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <label [translate]="'ativosimport.modal.fields.amortacumu'" class="pe-1"></label>
              <span class="card-text pe-1">{{ ativoimport.depreciacaoAcumulada }}</span>
              <span [translate]="'ativosimport.modal.fields.inseridomanual'"></span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <label [translate]="'ativosimport.modal.fields.percred'" class="pe-1"></label>
              <span class="card-text pe-2">{{ ativoimport.percReducao }}</span>
              <button
                type="button"
                class="btn btn-sm btn-outline-info"
                [translate]="'ativosimport.modal.btn.aplicarpercred'"
                [disabled]="ativoimport.percReducao === 0"
                (click)="aplicarPerc()"
                [plPromise]="promise"></button>
            </div>
          </div>
        </div>
      </div>
      <div class="card border-info">
        <div class="card-header bg-transparent border-info" [translate]="'ativosimport.modal.dadosfiscais'"></div>
        <div class="card-body">
          <div class="col-md-12">
            <div class="row">
              <label [translate]="'ativosimport.modal.fields.taxaamort'" class="pe-1"></label>
              <span class="card-text">{{ ativoimport.fiscalTaxaAmortiza }}</span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <label [translate]="'ativosimport.modal.fields.amortacumu'" class="pe-1"></label>
              <span class="card-text pe-1">{{ ativoimport.fiscalDepAcumulada }}</span>
              <span [translate]="'ativosimport.modal.fields.inseridomanual'"></span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <label [translate]="'ativosimport.modal.fields.percred'" class="pe-1"></label>
              <span class="card-text pe-2">{{ ativoimport.fiscalPercReducao }}</span>
              <button
                type="button"
                class="btn btn-sm btn-outline-info"
                [translate]="'ativosimport.modal.btn.aplicarpercred'"
                [disabled]="ativoimport.fiscalPercReducao === 0"
                (click)="aplicarPercFiscal()"
                [plPromise]="promise"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mt-2 mb-2">
      <pl-edit
        type="checkbox"
        [model]="dadosiguaiscontab"
        (modelChange)="dadosiguaiscontab = $event; dadosIguaisContabChange()"
        [properties]="{label: 'ativosimport.modal.fields.dadosfiscaisiguais'}"></pl-edit>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="ativosimport-table-modal text-nowrap table-responsive">
          <dx-data-grid [cgDxDataGrid]="definition" [dataSource]="listcalc" (onRowUpdated)="onRowUpdatedValoresCadaAno($event)" (onEditingStart)="onEditingStartValoresCadaAno($event)"> </dx-data-grid>
        </div>
      </div>
    </div>

    <hr />

    <pl-alert class="mb-2" type="warning" [closeable]="true" *ngIf="temQueTerValorAtribuido">
      <i class="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
      <span [translate]="'ativosimport.messages.temQueTerValorAtribuido'" [translateParams]="{ultAno: ultAno}"></span>
    </pl-alert>

    <div class="d-flex">
      <div class="me-4 d-flex">
        <label [translate]="'ativosimport.modal.difcontab'" class="pe-1"></label>
        <p class="card-text">{{ difContab }}</p>
      </div>
      <div class="d-flex">
        <label [translate]="'ativosimport.modal.diffiscal'" class="pe-1"></label>
        <p class="card-text">{{ difFiscal }}</p>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success action-dismiss" [onClick]="close" [plPromise]="promise"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span> </pl-button>

    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()" [plPromise]="promise"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
