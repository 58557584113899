import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {
  IPlDynamicVisualsSecondaryClickAction,
  IPlTabsCallback,
  IPlTabsEventSelected,
  IPlToolbarMenuItem,
  isDefinedNotNull,
  isNumber,
  isObject,
  isUndefinedOrNull,
  PlAlertService
} from 'pl-comps-angular';
import {AtivosImportAjustesNasContasModalComponent} from '../../modals/ajustesnascontas/ativosImport.ajustesNasContas.modal.component';
import {AtivosImportAtPercReducaoModalComponent} from '../../modals/atpercreducao/ativosImport.atPercReducao.modal.component';
import {AtivosImportAtValorAnualManModalComponent} from '../../modals/atvaloranualman/ativosImport.atValorAnualMan.modal.component';
import {AtivosImportConsistenciasContasModalComponent} from '../../modals/ativosconsistenciacontas/ativosImport.consistenciasContas.modal.component';
import {AtivosImportFolhaImportModalComponent} from '../../modals/folhaimport/ativosImport.folhaImport.modal.component';
import {AtivosImportImportarFicheiroModalComponent} from '../../modals/importarficheiro/ativosImport.importarFicheiro.modal.component';
import {AtivosImportProgressBarModalComponent} from '../../modals/progressbar/ativosImport.progressBar.modal.component';
import {AtivosImportService} from '../../ativosImport.service';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {EAtivosImportStatus, ENavTabsAtivosImport, ENTITY_NAME_ATIVOS_IMPORT, IAtivosImport, IAtivosImportDataGrid, IAtivosImportStatus} from '../../ativosImport.entity.interface';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {IJsonAtivosImport, IJsonAtivosImportAjustes, IJsonAtivosImportAndListCalc, IJsonAtivosImportConsistenciasContas} from '../../jsonAtivosImport.entity.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import moment from 'moment';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnContextMenuPreparing,
  IDevExpressDataGridEventOnInitialized
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

const INTERVAL_TIMEOUT = 1000;
const ESTADO_NORMAL = 0;

@Component({
  selector: 'ativosimport-list',
  templateUrl: './ativosImport.entity.list.component.html'
})
export class AtivosImportListComponent extends ModuloEntityListComponent<IJsonAtivosImport> implements OnInit, OnDestroy {
  public readonly tabCallback: IPlTabsCallback;
  public readonly dataGridDefinition: IDevExpressDataGrid;

  public ativosPorImportar: Array<IAtivosImport>;
  public ativosImportados: Array<IAtivosImport>;
  public ativosImportGridBand: Array<IAtivosImportDataGrid>;
  public hasSelectedAtivos: boolean;
  public activeTab: ENavTabsAtivosImport;
  public typeNavTabsAtivosImport: typeof ENavTabsAtivosImport;
  public onlyToImport: boolean;
  public selectAllPorImportar: boolean;
  public selectAllImportados: boolean;
  public criaFicheiroUrl: string;
  public loadPanelEnabled: boolean;

  private _promise: Promise<void>;
  private _btnExcel: IPlToolbarMenuItem;
  private _btnAddCriaFolhaImportar: IPlToolbarMenuItem;
  private _btnAddImportarFicheiro: IPlToolbarMenuItem;
  private _needRefresh: boolean;
  private _intervalId: number;
  private _currentImportStatus: IAtivosImportStatus;
  private _importProgressModalRef: NgbModalRef;
  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosImportService: AtivosImportService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    window.addEventListener<'beforeunload'>('beforeunload', this._fnBeforeUnload, {passive: true});
    this.dataGridDefinition = {
      filterRow: {visible: false},
      headerFilter: {visible: false},
      columnHidingEnabled: false,
      export: {filename: 'ativosimport.tabs.porimportar'},
      height: '64vh',
      hoverStateEnabled: true,
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      searchPanel: {visible: true},
      showBorders: true,
      showColumnLines: true,
      scrolling: {rowRenderingMode: 'virtual', columnRenderingMode: 'virtual'},
      keyExpr: 'ativosImportID',
      remoteOperations: false,
      repaintChangesOnly: true,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateImportAtivos',
            locateInMenu: 'auto'
          },
          {
            location: 'after',
            template: 'templateDeleteAtivosSel',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton',
          'searchPanel'
        ]
      }
    };
    this.onlyToImport = true;
    this.hasSelectedAtivos = false;
    this.tabCallback = {};
    this.ativosPorImportar = [];
    this.ativosImportados = [];
    this.ativosImportGridBand = [];
    this.criaFicheiroUrl = '';
    this._needRefresh = false;
    this.selectAllPorImportar = false;
    this.selectAllImportados = false;
    this.activeTab = ENavTabsAtivosImport.PorImportar;
    this.typeNavTabsAtivosImport = ENavTabsAtivosImport;
    this.loadPanelEnabled = true;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._btnAddCriaFolhaImportar = {
      id: 'criafolhaimportar',
      caption: 'ativosimport.btn.actions.criafolhaimportar',
      click: () => {
        this._openModalFolhaImportar();
      }
    };
    this._btnAddImportarFicheiro = {
      id: 'importarficheiro',
      caption: 'ativosimport.btn.actions.importarficheiro',
      click: () => this._openModalImportarFicheiro()
    };
    this._btnExcel = {
      id: 'dropdownexcel',
      order: 200,
      type: 'dropdown',
      iconLeft: '<i class="fa fa-file-excel-o fa-fw"></i>',
      caption: 'ativosimport.btn.excel',
      class: 'btn-secondary',
      menu: []
    };
    this.toolbar.config.search.active = false;
    this.toolbar.addButton(this._btnExcel);
    this._setDropdownExcel();
    this.getAtivosPorImportar();
    this._setAtivosImporGridFields();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._ativosImportService.stopJobAtivosImport();
  }

  public onlyByImportarChange(value: boolean): Promise<void> {
    this.onlyToImport = value;
    this.tabCallback.select(ENavTabsAtivosImport.PorImportar);
    this.activeTab = ENavTabsAtivosImport.PorImportar;
    return this.getAtivosPorImportar();
  }

  public redirectToEdit(item: IAtivosImport): Promise<void> {
    return this._cgStateService.redirectToState({
      stateOrName: ENTITY_NAME_ATIVOS_IMPORT,
      stateType: EEntityStateDetailType.EDIT,
      params: {id: item.ativosImportID}
    });
  }

  public aceitarCalculos(item: IAtivosImport): Promise<void> {
    this._dataGridInstance.beginCustomLoading(undefined);
    this.promise = this._ativosImportService
      .postAceitarCalculos([item])
      .then((response: HttpResponse<Array<IJsonAtivosImport>>) => {
        this._plAlertService.success('ativosimport.calculoaceite');
        for (const ativoImport of response.body) {
          const index = this.ativosPorImportar.findIndex((value: IJsonAtivosImport) => value.ativosImportID === ativoImport.ativosImportID);
          if (index !== -1) {
            this.ativosPorImportar[index] = ativoImport;
          }
        }
        return this._dataGridInstance.refresh();
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
    return this.promise;
  }

  public alterarMetodoCalculoAnual(item: IAtivosImport): Promise<void> {
    this._dataGridInstance.beginCustomLoading(undefined);
    this.promise = this._ativosImportService
      .postCalculoBaseAnual([item])
      .then((response: HttpResponse<Array<IJsonAtivosImport>>) => {
        this._plAlertService.success('ativosimport.calculobaseanual');
        for (const ativoImport of response.body) {
          const index = this.ativosPorImportar.findIndex((value: IJsonAtivosImport) => value.ativosImportID === ativoImport.ativosImportID);
          if (index !== -1) {
            this.ativosPorImportar[index] = ativoImport;
          }
        }
        return this._dataGridInstance.refresh();
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
    return this.promise;
  }

  public alterarMetodoCalculoInicio(item: IAtivosImport): Promise<void> {
    this._dataGridInstance.beginCustomLoading(undefined);
    this.promise = this._ativosImportService
      .postCalculoInicioUtilizacao([item])
      .then((response: HttpResponse<Array<IJsonAtivosImport>>) => {
        this._plAlertService.success('ativosimport.calculoinicioutilizacao');
        for (const ativoImport of response.body) {
          const index = this.ativosPorImportar.findIndex((value: IJsonAtivosImport) => value.ativosImportID === ativoImport.ativosImportID);
          if (index !== -1) {
            this.ativosPorImportar[index] = ativoImport;
          }
        }
        return this._dataGridInstance.refresh();
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
    return this.promise;
  }

  public limpaValorAtribuid(item: IAtivosImport): Promise<void> {
    this._dataGridInstance.beginCustomLoading(undefined);
    this.promise = this._ativosImportService
      .deleteLimpaValoresAtribuidosManualmente(item)
      .then((response: HttpResponse<IJsonAtivosImport>) => {
        this._plAlertService.success('ativosimport.valoreslimposcomsucesso');
        const index = this.ativosPorImportar.findIndex((value: IJsonAtivosImport) => value.ativosImportID === response.body.ativosImportID);
        if (index !== -1) {
          this.ativosPorImportar[index] = response.body;
          this._dataGridInstance.refresh();
        }
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
    return this.promise;
  }

  public apagaAtivo(isMulti: boolean = false, item: IAtivosImport = undefined): Promise<void> {
    const ativosimportlist: Array<IAtivosImport> = this.activeTab === ENavTabsAtivosImport.PorImportar ? this.ativosPorImportar : this.ativosImportados;
    if (isMulti && ativosimportlist.findIndex((value: IAtivosImport) => value._selected) === -1) {
      this._plAlertService.error('ativosimport.messages.temqueselecionarativo');
      return Promise.resolve();
    }
    const ativosIDs: Array<string> = isMulti ? ativosimportlist.filter((value: IAtivosImport) => value._selected).map((value: IAtivosImport) => value.ativosImportID) : [item.ativosImportID];
    this._dataGridInstance.beginCustomLoading(undefined);
    this.promise = this._ativosImportService
      .apagaAtivo(ativosIDs)
      .then(() => {
        this._plAlertService.success('ativosimport.deletedsuccess');
        if (this.activeTab === ENavTabsAtivosImport.PorImportar) {
          this.ativosPorImportar = this.ativosPorImportar.filter((ativo) => !ativosIDs.includes(ativo.ativosImportID));
          return this._dataGridInstance.refresh();
        }
        return this.getAtivosPorImportar();
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
    return this.promise;
  }

  public distribuiValorSemArredond(item: IAtivosImport): Promise<void> {
    this._dataGridInstance.beginCustomLoading(undefined);
    this.promise = this._ativosImportService
      .postAtribuirValoresManualmenteSemArredondamento([item])
      .then((response: HttpResponse<Array<IJsonAtivosImport>>) => {
        this._plAlertService.success('ativosimport.valsemarredondanual');
        this.ativosPorImportar = response.body;
        this._setAtivosImporGridFields();
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });

    return this.promise;
  }

  public distribuiValorAnosAnterior(item: IAtivosImport): Promise<void> {
    this._dataGridInstance.beginCustomLoading(undefined);
    this.promise = this._ativosImportService
      .postDistribuirValorAtribuidoPelosAnosAnteriores([item])
      .then((response: HttpResponse<Array<IJsonAtivosImport>>) => {
        this._plAlertService.success('ativosimport.valoranosanterior');
        this.ativosPorImportar = response.body;
        this._setAtivosImporGridFields();
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });

    return this.promise;
  }

  public atribuiPercRedu(item: IAtivosImport): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(AtivosImportAtPercReducaoModalComponent);
    const componentInstance: AtivosImportAtPercReducaoModalComponent = modalInstance.componentInstance;
    componentInstance.ativosimport = [item];
    componentInstance.onlyToImport = this.onlyToImport;
    this._dataGridInstance.beginCustomLoading(undefined);
    this.promise = modalInstance.result
      .then((result: Array<IJsonAtivosImport>) => {
        this.ativosPorImportar = result;
        this._setAtivosImporGridFields();
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });

    return this.promise;
  }

  public atribuiValorAnualManual(item: IAtivosImport): Promise<void> {
    return this._ativosImportService.postLoadAtribuirValoresManualmente([item]).then((response: HttpResponse<IJsonAtivosImportAndListCalc>) => {
      const modalInstance = this._cgModalService.showVanilla(AtivosImportAtValorAnualManModalComponent);
      const componentInstance: AtivosImportAtValorAnualManModalComponent = modalInstance.componentInstance;
      componentInstance.ativosImportAndListCalc = response.body;
      return modalInstance.result.then((result: IJsonAtivosImport) => {
        const index = this.ativosPorImportar.findIndex((value: IAtivosImport) => value.ativosImportID === result.ativosImportID);
        if (index !== -1) {
          this.ativosPorImportar[index] = result;
          this._dataGridInstance.refresh();
        }
      });
    });
  }

  public getAtivosPorImportar(): Promise<void> {
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    this.promise = this._ativosImportService
      .getAtivosPorImportar(this.onlyToImport)
      .then((response: HttpResponse<Array<IJsonAtivosImport>>) => {
        this.ativosPorImportar = response.body.filter((value: IJsonAtivosImport) => !value.importado);
        this.ativosImportados = response.body.filter((value: IJsonAtivosImport) => value.importado);
      })
      .finally(() => {
        if (this._dataGridInstance) {
          this._dataGridInstance.endCustomLoading();
        }
      });

    return this.promise;
  }

  public changedTab(event: IPlTabsEventSelected): void {
    this.hasSelectedAtivos = false;
    this.activeTab = <ENavTabsAtivosImport>event.nextId;
    this.dataGridDefinition.export.filename = this.activeTab === ENavTabsAtivosImport.Importados ? 'ativosimport.tabs.importados' : 'ativosimport.tabs.porimportar';
  }

  public selectAllChanged(value: boolean): void {
    this.hasSelectedAtivos = value;
    if (this.activeTab === this.typeNavTabsAtivosImport.PorImportar) {
      this.selectAllPorImportar = value;
      if (this.ativosPorImportar && this.ativosPorImportar.length > 0) {
        for (const porImportar of this.ativosPorImportar) {
          porImportar._selected = value;
        }
      }
    }
    if (this.activeTab === this.typeNavTabsAtivosImport.Importados) {
      this.selectAllImportados = value;
      if (this.ativosImportados && this.ativosImportados.length > 0) {
        for (const importado of this.ativosImportados) {
          importado._selected = value;
        }
      }
    }
    this._dataGridInstance.refresh(true);
  }

  public setSelectAll(cell: IAtivosImport, value: boolean): void {
    if (this.activeTab === ENavTabsAtivosImport.PorImportar) {
      const index = this.ativosPorImportar.findIndex((item: IAtivosImport) => item.ativosImportID === cell.ativosImportID);
      if (index !== -1) {
        this.ativosPorImportar[index]._selected = value;
      }
      this.selectAllPorImportar = this.ativosPorImportar.findIndex((porImportar: IAtivosImport) => !porImportar._selected) === -1;
      this.hasSelectedAtivos = this.ativosPorImportar.findIndex((porImportar: IAtivosImport) => porImportar._selected) !== -1;
    }
    if (this.activeTab === ENavTabsAtivosImport.Importados) {
      const index = this.ativosImportados.findIndex((item: IAtivosImport) => item.ativosImportID === cell.ativosImportID);
      if (index !== -1) {
        this.ativosImportados[index]._selected = value;
      }
      this.selectAllImportados = this.ativosImportados.findIndex((importado: IAtivosImport) => !importado._selected) === -1;
      this.hasSelectedAtivos = this.ativosImportados.findIndex((importado: IAtivosImport) => importado._selected) !== -1;
    }
  }

  public importarAtivosSave(): Promise<void> {
    let message = '';
    const hasAnoCurso = this.ativosPorImportar.find((value: IAtivosImport) => moment(value.dataAquisicao).year() === this._configService.configurations.empresa.anoEmCursoIRC);
    const hasError = this.ativosPorImportar.find((value: IAtivosImport) => value.erro);
    message += hasAnoCurso ? this._translateService.instant('ativosimport.modal.msg.haativosdataaquisicao') : '';
    message += hasError ? this._translateService.instant('ativosimport.modal.msg.haativoserro') : '';
    message += this._translateService.instant('ativosimport.modal.msg.desejaimportar');
    this._dataGridInstance.beginCustomLoading(undefined);
    this.promise = this._cgModalService
      .showOkCancel('ativosimport.modal.promptCancelProcessTitle', message)
      .then(() => {
        return this._ativosImportService.postCriarAtivosGuardar(this.ativosPorImportar).then(() => {
          this._startImportChecker();
          this._importProgressModal('ativosimport.modal.msg.aprepararimportacao');
        });
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
    return this.promise;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
    // When rendering table for the first time we must start loading manually
    this._dataGridInstance.beginCustomLoading(undefined);
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IAtivosImport>): void {
    if (event.rowType === 'data') {
      if (event.row.data.erro) {
        event.cellElement.classList.add('text-danger');
      }
      if (event.column.dataField === 'dataAbate' && event.data.estado === ESTADO_NORMAL) {
        event.cellElement.classList.add('hide-date-abate');
      }
    }
  }

  public onDataGridContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IAtivosImport>): void {
    if (event.target === 'content' && event.row?.rowType === 'data' && isObject(event.row.data)) {
      event.event.preventDefault();
      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActionsLocal(event.row.data);
      this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions);
    }
  }

  public get promise(): Promise<void> {
    return this._promise;
  }

  public set promise(value: Promise<void>) {
    this._promise = value;
    this._promise.finally(() => {
      this._promise = undefined;
    });
  }

  private readonly _fnBeforeUnload: () => void = () => {
    navigator.sendBeacon(this._ativosImportService.stopJobAtivosImportUrl());
  };

  private _setDropdownExcel(): void {
    this._btnExcel.menu = [];
    this._btnExcel.menu.push(this._btnAddCriaFolhaImportar);
    this._btnExcel.menu.push(this._btnAddImportarFicheiro);
  }

  private _openModalImportarFicheiro(): Promise<void> {
    const instance = this._cgModalService.showVanilla(AtivosImportImportarFicheiroModalComponent, {size: 'md'});
    instance.result.then(() => {
      return this.getAtivosPorImportar();
    });
    return Promise.resolve();
  }

  private _openModalFolhaImportar(): void {
    const instance = this._cgModalService.showVanilla(AtivosImportFolhaImportModalComponent);
    instance.result.then(() => {
      this._plAlertService.success('ativosimport.modal.filesuccess');
    });
  }

  private _generateSecondaryClickActionsLocal(line: IAtivosImport): Array<IPlDynamicVisualsSecondaryClickAction> {
    if (this.activeTab === ENavTabsAtivosImport.Importados) {
      return [{caption: 'ativosimport.btn.dropdown.apagaativoimport', click: () => this.apagaAtivo(false, line)}];
    }
    return [
      {
        caption: 'ativosimport.btn.dropdown.editar',
        click: () => this.redirectToEdit(line)
      },
      {
        divider: true
      },
      {
        caption: 'ativosimport.btn.dropdown.aceitarcalculos',
        click: () => this.aceitarCalculos(line)
      },
      {
        caption: 'ativosimport.btn.dropdown.alterarmetodocalculoanual',
        click: () => this.alterarMetodoCalculoAnual(line)
      },
      {
        caption: 'ativosimport.btn.dropdown.alterarmetodocalculoinicio',
        click: () => this.alterarMetodoCalculoInicio(line)
      },
      {
        caption: 'ativosimport.btn.dropdown.atribuivaloranualmanual',
        click: () => this.atribuiValorAnualManual(line)
      },
      {
        caption: 'ativosimport.btn.dropdown.distribuivalorsemarredond',
        click: () => this.distribuiValorSemArredond(line)
      },
      {
        caption: 'ativosimport.btn.dropdown.distribuivaloranosanterior',
        click: () => this.distribuiValorAnosAnterior(line)
      },
      {
        caption: 'ativosimport.btn.dropdown.atribuipercredu',
        click: () => this.atribuiPercRedu(line)
      },
      {
        caption: 'ativosimport.btn.dropdown.limpavaloratribuid',
        disabled: !line.valoresDepManuais,
        click: () => this.limpaValorAtribuid(line)
      },
      {
        divider: true
      },
      {
        caption: 'ativosimport.btn.dropdown.apagaativo',
        click: () => this.apagaAtivo(false, line)
      }
    ];
  }

  private _startImportChecker(): void {
    this._clearImportChecker();
    this._intervalId = window.setInterval(() => {
      this._ativosImportService.getAtivosImportStatus().then((response: HttpResponse<IAtivosImportStatus>) => {
        this._currentImportStatus = response.body;
        if (this._currentImportStatus.state === EAtivosImportStatus.Timeout) {
          this._clearImportChecker();
          this._showTimeoutModal();
        } else if (this._currentImportStatus.state === EAtivosImportStatus.Error) {
          this._clearImportChecker();
          this._plAlertService.error(this._currentImportStatus.description);
        } else if (this._currentImportStatus.state === EAtivosImportStatus.Ended) {
          this._clearImportChecker(false);
          this.promise = this._postProcessAtivosImport();
        } else {
          this._importProgressModal(this._currentImportStatus.description);
        }
      });
    }, INTERVAL_TIMEOUT);
  }

  private _clearImportChecker(closeModal: boolean = true): void {
    if (isNumber(this._intervalId)) {
      window.clearTimeout(this._intervalId);
      this._intervalId = undefined;
    }
    if (closeModal) {
      this._closeImportProgressModal();
    }
  }

  private _showTimeoutModal(): void {
    this._cgModalService.showOkCancel('ativosimport.jobImportTimeoutModalTitle', 'ativosimport.jobImportTimeoutModalMessage', {
      size: 'md',
      showCancelBtn: false,
      backdrop: 'static',
      keyboard: false
    });
  }

  private _importProgressModal(description: string): void {
    if (isUndefinedOrNull(this._importProgressModalRef)) {
      this._importProgressModalRef = this._cgModalService.showVanilla(AtivosImportProgressBarModalComponent, {
        size: 'md',
        backdrop: 'static',
        keyboard: false
      });
    }
    const componentInstance: AtivosImportProgressBarModalComponent = this._importProgressModalRef.componentInstance;
    componentInstance.pbProcLabel = description;
  }

  private _postProcessAtivosImport(): Promise<void> {
    this._dataGridInstance.beginCustomLoading(undefined);
    return new Promise<void>((resolve, reject) => {
      this._ativosImportService
        .getAtivosImportResults()
        .then((response: HttpResponse<Array<IJsonAtivosImportConsistenciasContas>>) => {
          this._closeImportProgressModal();
          this._needRefresh = true;
          this._plAlertService.success('ativosimport.modal.msg.ativosimportados');
          if (response.body.length > 0) {
            const modalInstance = this._cgModalService.showVanilla(AtivosImportConsistenciasContasModalComponent);
            const componentInstance: AtivosImportConsistenciasContasModalComponent = modalInstance.componentInstance;
            componentInstance.ativosconsistencias = response.body;
            modalInstance.result
              .then(() => {
                this._ativosImportService
                  .getAjustesNasContas()
                  .then((responseAjuste: HttpResponse<Array<IJsonAtivosImportAjustes>>) => {
                    this._plAlertService.success('ativosimport.modal.msg.ajustesnascontas');
                    if (responseAjuste.body.length > 0) {
                      const modalInstanceAjusteNasContas = this._cgModalService.showVanilla(AtivosImportAjustesNasContasModalComponent);
                      const componentInstanceAjustesNasContas: AtivosImportAjustesNasContasModalComponent = modalInstanceAjusteNasContas.componentInstance;
                      componentInstanceAjustesNasContas.ajustesnascontas = responseAjuste.body;
                      modalInstanceAjusteNasContas.result.finally(resolve);
                    } else {
                      resolve();
                    }
                  })
                  .catch(reject);
              })
              .catch(reject);
          }
        })
        .finally(() => {
          if (this._needRefresh) {
            this._needRefresh = false;
            this.getAtivosPorImportar();
          }
        });
    }).finally(() => {
      this._dataGridInstance.endCustomLoading();
    });
  }

  private _closeImportProgressModal(): void {
    if (isDefinedNotNull(this._importProgressModalRef)) {
      this._importProgressModalRef.dismiss();
      this._importProgressModalRef = undefined;
    }
  }

  private _setAtivosImporGridFields(): void {
    const selection: IAtivosImportDataGrid = {
      caption: '',
      alignment: 'center',
      fields: [
        {
          caption: 'ativosimport.table.header.sel',
          dataField: '_selected',
          dataType: 'boolean',
          alignment: 'center',
          cellTemplate: 'cellTemplateSelected',
          headerTemplate: 'headerTemplateSelected',
          allowHeaderFiltering: false,
          allowSearch: false,
          allowFiltering: false,
          allowSorting: false
        }
      ]
    };
    const ativo: IAtivosImportDataGrid = {
      caption: 'ativosimport.table.header.ativo',
      alignment: 'center',
      fields: [
        {
          caption: 'ativosimport.fields.importado',
          dataField: 'importado',
          dataType: 'boolean',
          alignment: 'center',
          width: 80
        },
        {
          caption: 'ativosimport.fields.codAtivo',
          dataField: 'codAtivoGerado',
          dataType: 'number',
          alignment: 'left'
        },
        {
          caption: 'ativosimport.fields.designacao',
          dataField: 'designacao',
          dataType: 'string',
          width: 250,
          alignment: 'left'
        },
        {
          caption: 'ativosimport.fields.refExterna',
          dataField: 'refExterna',
          dataType: 'string',
          alignment: 'left'
        },
        {
          caption: 'ativosimport.fields.usado',
          dataField: 'usado',
          dataType: 'boolean',
          alignment: 'center',
          width: 80
        },
        {
          caption: 'ativosimport.fields.estado',
          dataField: 'estado',
          dataType: 'string',
          alignment: 'left',
          cellTemplate: 'cellTemplateEstado'
        },
        {
          caption: 'ativosimport.fields.dataAbate',
          dataField: 'dataAbate',
          dataType: 'date',
          alignment: 'left',
          cellTemplate: 'cellTemplateDataAbate'
        },
        {
          caption: 'ativosimport.fields.valorVenda',
          dataField: 'valorVenda',
          dataType: 'number',
          format: '#,##0.00',
          alignment: 'right'
        },
        {
          caption: 'ativosimport.fields.quantidade',
          dataField: 'quantidade',
          dataType: 'number',
          format: '#,##0.00',
          alignment: 'right'
        }
      ]
    };
    const aquisicao: IAtivosImportDataGrid = {
      caption: 'ativosimport.table.header.aquisicao',
      alignment: 'center',
      fields: [
        {
          caption: 'ativosimport.fields.dataAquisicao',
          dataField: 'dataAquisicao',
          dataType: 'date',
          alignment: 'left'
        },
        {
          caption: 'ativosimport.fields.valorAquisicao',
          dataField: 'valorAquisicao',
          dataType: 'number',
          alignment: 'right',
          format: '#,##0.00'
        },
        {
          caption: 'ativosimport.fields.valorAquisicaoFiscal',
          dataField: 'valorAquisicaoFiscal',
          dataType: 'number',
          alignment: 'right',
          format: '#,##0.00'
        },
        {
          caption: 'ativosimport.fields.mapa',
          dataField: 'nMapaAmortizacao',
          dataType: 'string',
          alignment: 'left'
        },
        {
          caption: 'ativosimport.fields.dtInicioUtilizacao',
          dataField: 'dataInicioUtiliza',
          dataType: 'date',
          alignment: 'left'
        }
      ]
    };
    const depacumucontab: IAtivosImportDataGrid = {
      caption: 'ativosimport.table.header.depacumucontab',
      alignment: 'center',
      fields: [
        {
          caption: 'ativosimport.fields.baseParaCalculo',
          dataField: 'baseCalculo',
          dataType: 'number',
          alignment: 'left',
          width: 170,
          cellTemplate: 'cellTemplateBaseCalculo'
        },
        {
          caption: 'ativosimport.fields.metodoParaCalculo',
          dataField: 'metodoCalculo',
          dataType: 'number',
          alignment: 'left',
          width: 170,
          cellTemplate: 'cellTemplateMetodoCalculo'
        },
        {
          caption: 'ativosimport.fields.dtUltProcessamento',
          dataField: 'dataUltProcessamento',
          dataType: 'date',
          alignment: 'left'
        },
        {
          caption: 'ativosimport.fields.depAcumulada',
          dataField: 'depreciacaoAcumulada',
          dataType: 'number',
          alignment: 'right',
          format: '#,##0.00'
        },
        {
          caption: 'ativosimport.fields.depExercicio',
          dataField: 'depreciacaoExercicio',
          dataType: 'number',
          alignment: 'right',
          format: '#,##0.00'
        },
        {
          caption: 'ativosimport.fields.percReducao',
          dataField: 'percReducao',
          dataType: 'number',
          alignment: 'right',
          format: '#,##0.00'
        },
        {
          caption: 'ativosimport.fields.depCalculada',
          dataField: 'depreciacaoAcumuladaCalculada',
          dataType: 'number',
          alignment: 'right',
          format: '#,##0.00'
        },
        {
          caption: 'ativosimport.fields.diferenca',
          dataField: 'depreciacaoDiferenca',
          dataType: 'number',
          alignment: 'right',
          format: '#,##0.00'
        },
        {
          caption: 'ativosimport.fields.valoresManuais',
          dataField: 'valoresDepManuais',
          dataType: 'boolean',
          alignment: 'center',
          width: 80
        }
      ]
    };
    const depacumufiscais: IAtivosImportDataGrid = {
      caption: 'ativosimport.table.header.depacumufiscais',
      alignment: 'center',
      fields: [
        {
          caption: 'ativosimport.fields.depCalculada',
          dataField: 'fiscalDepAcumulada',
          dataType: 'number',
          alignment: 'right',
          format: '#,##0.00'
        },
        {
          caption: 'ativosimport.fields.depExercicio',
          dataField: 'fiscalDepExercicio',
          dataType: 'number',
          alignment: 'right',
          format: '#,##0.00'
        },
        {
          caption: 'ativosimport.fields.percReducao',
          dataField: 'fiscalPercReducao',
          dataType: 'number',
          alignment: 'right',
          format: '#,##0.00'
        },
        {
          caption: 'ativosimport.fields.depCalculada',
          dataField: 'depreciacaoFiscalAcumuladaCalculada',
          dataType: 'number',
          alignment: 'right',
          format: '#,##0.00'
        },
        {
          caption: 'ativosimport.fields.diferenca',
          dataField: 'depreciacaoFiscalDiferenca',
          dataType: 'number',
          alignment: 'right',
          format: '#,##0.00'
        }
      ]
    };
    const decregulamentar: IAtivosImportDataGrid = {
      caption: 'ativosimport.table.header.decregulamentar',
      alignment: 'center',
      fields: [
        {
          caption: 'ativosimport.fields.codDR',
          dataField: 'codAmortizacao',
          dataType: 'string',
          alignment: 'left'
        },
        {
          caption: 'ativosimport.fields.taxaContab',
          dataField: 'taxaAmortizacao',
          dataType: 'number',
          alignment: 'right',
          format: '#,##0.00'
        },
        {
          caption: 'ativosimport.fields.taxaFiscal',
          dataField: 'fiscalTaxaAmortiza',
          dataType: 'number',
          alignment: 'right',
          format: '#,##0.00'
        }
      ]
    };
    const contas: IAtivosImportDataGrid = {
      caption: 'ativosimport.table.header.contas',
      alignment: 'center',
      fields: [
        {
          caption: 'ativosimport.fields.ncontaAtivo',
          dataField: 'nContaDoAtivo',
          dataType: 'string',
          alignment: 'left'
        },
        {
          caption: 'ativosimport.fields.nomeDaContaDoAtivo',
          dataField: 'nomeContaDoAtivo',
          dataType: 'string',
          alignment: 'left'
        },
        {
          caption: 'ativosimport.fields.ncontaFornecedor',
          dataField: 'nContaFornecedor',
          dataType: 'string',
          alignment: 'left'
        },
        {
          caption: 'ativosimport.fields.nomeDaContaDoFornecedor',
          dataField: 'nomeContaFornecedor',
          dataType: 'string',
          alignment: 'left'
        }
      ]
    };
    const associacaocontab: IAtivosImportDataGrid = {
      caption: 'ativosimport.table.header.associacaocontab',
      alignment: 'center',
      fields: [
        {
          caption: 'ativosimport.fields.periodo',
          dataField: 'periodo',
          dataType: 'string',
          alignment: 'left'
        },
        {
          caption: 'ativosimport.fields.diario',
          dataField: 'ndiario',
          dataType: 'number',
          alignment: 'left'
        },
        {
          caption: 'ativosimport.fields.nDocInterno',
          dataField: 'ndocInterno',
          dataType: 'string',
          alignment: 'left'
        }
      ]
    };

    this.ativosImportGridBand.push(selection, ativo, aquisicao, depacumucontab, depacumufiscais, decregulamentar, contas, associacaocontab);
  }
}
