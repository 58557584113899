import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {NewPreviTesModalComponent} from '../modals/newprevites/newpreviTes.modal.component';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {IJsonPreviTes} from '../../../../../entities/tesrubrica/jsonTesRubrica.entity.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {PreviTesService} from '../../previTes.module.service';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowRemoving,
  IDevExpressDataGridEventOnRowUpdated,
  IDevExpressDataGridEventOnToolbarPreparing
} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {copy} from 'pl-comps-angular';
import {Properties as ButtonProperties} from 'devextreme/ui/button';

@Component({
  selector: 'module-previ-tes',
  templateUrl: './previTes.module.component.html'
})
export class PreviTesModuleComponent extends ModuloComponent implements OnInit {
  @Input() public previTesList: Array<IJsonPreviTes>;

  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonPreviTes, string>;
  public selectedKeys: Array<string>;

  private _dataGridInstance: dxDataGrid<IJsonPreviTes, string>;
  private _previTesListCopy: Array<IJsonPreviTes>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _previTesService: PreviTesService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.selectedKeys = [];
    const btnDeleteMultiplePreviTes: Partial<ButtonProperties> = {
      icon: 'trash',
      hint: this._translateService.instant('prevites.manual.tooltip.btnDeleteMultiple'),
      onClick: () => this._deletePreviTes(this.selectedKeys)
    };
    this.dataGridDefinition = {
      keyExpr: 'tesLancoID',
      columns: [
        {dataField: 'rubricaStr', dataType: 'string', caption: 'prevites.manual.new.rubrica'},
        {dataField: 'descricao', dataType: 'string', caption: 'prevites.manual.new.descricao', width: 200},
        {dataField: 'data', dataType: 'date', caption: 'prevites.manual.new.data'},
        {dataField: 'valor', dataType: 'double', caption: 'prevites.manual.new.valor'},
        {dataField: 'nConta', dataType: 'string', caption: 'prevites.manual.new.nConta'},
        {dataField: 'nomeConta', dataType: 'string', caption: 'prevites.manual.new.nomeConta'}
      ],
      toolbar: {
        items: [
          {name: 'btnDeleteMultiplePreviTes', location: 'after', widget: 'dxButton', disabled: !this.selectedKeys.length, options: btnDeleteMultiplePreviTes},
          'exportButton',
          'columnChooserButton'
        ]
      },
      editing: {allowUpdating: true, allowDeleting: true, mode: 'cell', confirmDelete: false},
      columnHidingEnabled: false,
      export: {filename: 'prevites.manual.new.title'},
      height: '80vh',
      pager: {visible: false},
      paging: {enabled: false, pageSize: 100},
      scrolling: {columnRenderingMode: 'virtual', rowRenderingMode: 'virtual'},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'pesquisar',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-search"></i>',
      class: 'btn-primary',
      caption: 'global.btn.search',
      click: () => this._pesquisar()
    });
    this.btnNovo.visible = true;
    this.btnNovo.click = () => this._modalNewPreviTes();

    this.btnSave.visible = true;
    this.btnSave.disabled = true;
    this.btnSave.click = () => this._savePreviTes();

    this._previTesListCopy = copy(this.previTesList);
    this._evaluateSaveBtn();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonPreviTes, string>): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this.dataGridInstance.endCustomLoading();
  }

  public onRowUpdated(event: IDevExpressDataGridEventOnRowUpdated<IJsonPreviTes, string>): void {
    event.data._edited = true;
    this._evaluateSaveBtn();
  }

  public async onRowRemoving(event: IDevExpressDataGridEventOnRowRemoving<IJsonPreviTes, string>): Promise<void> {
    event.cancel = true;
    await this._deletePreviTes([event.data.tesLancoID]);
  }

  public onSelectionChanged(): void {
    this._dataGridInstance.repaint();
  }

  public onToolbarPreparing(event: IDevExpressDataGridEventOnToolbarPreparing<IJsonPreviTes, string>): void {
    event.toolbarOptions.items.find((item) => item.name === 'btnDeleteMultiplePreviTes').disabled = !this.selectedKeys.length;
  }

  public get dataGridInstance(): dxDataGrid {
    return this._dataGridInstance;
  }

  private async _pesquisar(): Promise<void> {
    if (this.dataGridInstance) {
      this.dataGridInstance.beginCustomLoading(undefined);
    }
    try {
      const response: HttpResponse<Array<IJsonPreviTes>> = await this._previTesService.getPesquisaPrevTesManual();
      this.previTesList = response.body;
      this.selectedKeys = [];
    } finally {
      if (this.dataGridInstance) {
        this.dataGridInstance.endCustomLoading();
      }
    }
  }

  private async _modalNewPreviTes(): Promise<void> {
    const instance = this._cgModalService.showVanilla(NewPreviTesModalComponent);
    this.previTesList = await instance.result;
    this.selectedKeys = [];
  }

  private _evaluateSaveBtn(): void {
    this.btnSave.disabled = JSON.stringify(this.previTesList) === JSON.stringify(this._previTesListCopy);
  }

  private async _savePreviTes(): Promise<void> {
    const response: HttpResponse<Array<IJsonPreviTes>> = await this._previTesService.saveAllPrevTesManual(this._getEditedPreviTesList());
    this.previTesList = response.body;
    this._previTesListCopy = copy(response.body);
    if (response.ok) {
      this._resetLinhasEdited();
    }
    this._evaluateSaveBtn();
  }

  private _resetLinhasEdited(): void {
    this.previTesList.forEach((linha: IJsonPreviTes) => {
      if (linha._edited) {
        linha._edited = undefined;
      }
    });
  }

  private _getEditedPreviTesList(): Array<IJsonPreviTes> {
    return this.previTesList.filter((item: IJsonPreviTes) => item._edited);
  }

  private async _deletePreviTes(tesLancoIDs: Array<string>): Promise<void> {
    await this._cgModalService.showOkCancel('global.btn.confirm', 'prevites.manual.messages.certezaeliminar').then(async () => {
      this.dataGridInstance.beginCustomLoading(undefined);
      try {
        await this._previTesService.deletePrevTesManual(tesLancoIDs);
        for (const tesLancoID of tesLancoIDs) {
          const rowIndex: number = this.previTesList.findIndex((item: IJsonPreviTes) => item.tesLancoID === tesLancoID);
          if (rowIndex !== -1) {
            this.previTesList.splice(rowIndex, 1);
          }
        }
        this.selectedKeys = [];
      } finally {
        this.dataGridInstance.endCustomLoading();
      }
    });
  }
}
