import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonAtivosDepreciacoes} from '../../interfaces/jsonAtivosDepreciacoes.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'modal-ativos-depreciacoes',
  templateUrl: './ativosDepreciacoes.modal.component.html'
})
export class AtivosDeprecriacoesModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public ativoDepreciacao: Array<IJsonAtivosDepreciacoes>;
  public readonly dataGridDefinition: IDevExpressDataGrid;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {
          columns: [
            {dataField: 'ano', dataType: 'number', caption: 'ativos.table.body.anoEconomico'},
            {dataField: 'dataDePeriodoAno', dataType: 'date', caption: 'ativos.table.body.dataDe'},
            {dataField: 'dataAtePeriodoAno', dataType: 'date', caption: 'ativos.table.body.dataAte'}
          ]
        },
        {
          caption: 'ativos.table.dadosContabilisticos',
          alignment: 'center',
          columns: [
            {dataField: 'contabValorAquisicao', dataType: 'double', caption: 'ativos.table.body.valorAquisicao'},
            {dataField: 'contabValorResidual', dataType: 'double', caption: 'ativos.table.body.valorResidual'},
            {dataField: 'contabTaxaAmortiza', dataType: 'double', caption: 'ativos.table.body.taxa'},
            {dataField: 'contabPercReducao', dataType: 'double', caption: 'ativos.table.body.redTaxa'},
            {dataField: 'depContabAcumAno', dataType: 'double', caption: 'ativos.table.body.depreciacao'}
          ]
        },
        {
          caption: 'ativos.table.dadosFiscais',
          alignment: 'center',
          columns: [
            {dataField: 'fiscalValorAquisicao', dataType: 'double', caption: 'ativos.table.body.valorAquisicao'},
            {dataField: 'fiscalValorResidual', dataType: 'double', caption: 'ativos.table.body.valorResidual'},
            {dataField: 'fiscalTaxaAmortiza', dataType: 'double', caption: 'ativos.table.body.taxa'},
            {dataField: 'fiscalPercReducao', dataType: 'double', caption: 'ativos.table.body.redTaxa'},
            {dataField: 'depFiscalAcumAno', dataType: 'double', caption: 'ativos.table.body.depreciacao'}
          ]
        }
      ],
      dataSource: [],
      export: {filename: 'ativos.steps.ativoDepreciacoes'},
      height: '60vh',
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      remoteOperations: false,
      scrolling: {columnRenderingMode: 'virtual', rowRenderingMode: 'virtual'},
      summary: {
        totalItems: [
          {column: 'depContabAcumAno', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'depFiscalAcumAno', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'}
        ]
      }
    };
  }

  public ngOnInit(): void {
    this.dataGridDefinition.dataSource = this.ativoDepreciacao;
  }
}
