<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'analisadorTes.strings.configModalTitle'"></h4>
</div>

<div class="modal-body" [plPromise]="promise">
  <pl-tabs>
    <pl-tab id="tabSaldoInicial" caption="analisadorTes.strings.tabSaldoInicialTitle">
      <div *plTabContent>
        <div class="row">
          <div class="col-md-12">
            <pl-group>
              <label [translate]="'analisadorTes.strings.origemSaldoInicial'"></label>
              <edit>
                <pl-edit type="select" [(model)]="model.origemSaldoInicial" [properties]="{select: {list: origemSaldoInicialSource}, disallowClear: true}"></pl-edit>
              </edit>
            </pl-group>
          </div>

          <ng-container *ngIf="model.origemSaldoInicial === origemSaldoInicialEnum.CONTABILIDADE">
            <div class="col-md-12">
              <div class="card mb-3">
                <div class="card-header" [translate]="'analisadorTes.strings.intervaloContas'"></div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <pl-group>
                        <label [translate]="'analisadorTes.strings.contaDe'"></label>
                        <edit>
                          <pl-edit type="text" [(model)]="model.origemSaldoInicialContabContaDe"></pl-edit>
                        </edit>
                      </pl-group>
                    </div>
                    <div class="col-md-6">
                      <pl-group>
                        <label [translate]="'analisadorTes.strings.contaAte'"></label>
                        <edit>
                          <pl-edit type="text" [(model)]="model.origemSaldoInicialContabContaAte"></pl-edit>
                        </edit>
                      </pl-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="model.origemSaldoInicial === origemSaldoInicialEnum.CGBANKING">
            <div class="col-md-12">
              <div class="card mb-3">
                <div class="card-header" [translate]="'analisadorTes.strings.intervaloContasBancarias'"></div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <pl-group>
                        <label [translate]="'analisadorTes.strings.contaDe'"></label>
                        <edit>
                          <pl-edit type="text" [(model)]="model.origemSaldoInicialCGBankingContaDe"></pl-edit>
                        </edit>
                      </pl-group>
                    </div>
                    <div class="col-md-6">
                      <pl-group>
                        <label [translate]="'analisadorTes.strings.contaAte'"></label>
                        <edit>
                          <pl-edit type="text" [(model)]="model.origemSaldoInicialCGBankingContaAte"></pl-edit>
                        </edit>
                      </pl-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </pl-tab>
    <pl-tab id="tabPagLotes" caption="analisadorTes.strings.tabLotesTitle">
      <div *plTabContent>
        <div class="row">
          <div class="col-md-12">
            <pl-group>
              <edit>
                <pl-edit type="checkbox" [(model)]="model.importLotes" [properties]="{label: 'analisadorTes.strings.importaAutomaticamente'}"></pl-edit>
              </edit>
            </pl-group>
          </div>
          <div class="col-md-6">
            <pl-group>
              <label [translate]="'analisadorTes.strings.rubricaParaRecEmLote'"></label>
              <edit>
                <entity-autocomplete
                  entity="tesrubrica"
                  attrName="codRubrica"
                  [model]="model"
                  (selectedKeyChange)="model.lotesRubricaRec = $event"
                  [properties]="{validators: {required: {value: model.importLotes}}, disabled: !model.importLotes}"
                  [fieldsMap]="{tesRubricaID: 'lotesRubricaRec'}">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </div>
          <div class="col-md-6">
            <pl-group>
              <label [translate]="'analisadorTes.strings.rubricaParaPagEmLote'"></label>
              <edit>
                <entity-autocomplete
                  entity="tesrubrica"
                  attrName="codRubrica"
                  [model]="model"
                  (selectedKeyChange)="model.lotesRubricaPag = $event"
                  [properties]="{validators: {required: {value: model.importLotes}}, disabled: !model.importLotes}"
                  [fieldsMap]="{tesRubricaID: 'lotesRubricaPag'}">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </div>
        </div>
      </div>
    </pl-tab>
  </pl-tabs>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-sm btn-primary" [onClick]="close" [disabled]="closeDisabled"><i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
  <pl-button klass="btn-sm btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
</div>
