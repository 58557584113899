import {merge, sortBy, upperFirst} from 'lodash-es';
import dxDataGrid, {Row} from 'devextreme/ui/data_grid';
import {Component, Injector, OnInit} from '@angular/core';
import {copy, ICGTableOnSelect, IPlNavPillEventSelected, isArray, isDefined, isNumber, isObject, PlAlertService, TPlTableItem} from 'pl-comps-angular';
import {DATA_SOURCE_DEBITO_CREDITO} from '../../../../datasources/debitocredito/debitoCredito.datasource';
import {DATA_SOURCE_MERCADOS} from '../../../../datasources/mercados/mercados.datasource';
import {EDebitoCredito} from '../../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {ENTITY_NAME_DOCS_CONTABILIDADE} from '../../../../modules/portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {
  EReembolsosIvaPill,
  EReembolsosIvaTab,
  IReembolsosIvaDocsWithError,
  IReembolsosIvaPanel,
  IReembolsosIvaTab,
  REEMBOLSOS_IVA_DEFAULT_VALUE,
  TReembolsosIvaDocumentoSourceItem,
  TReembolsosIvaDocumentoSourceTableItem,
  TReembolsosIvaRelacaoSourceItem
} from './reembolsosIva.entity.detail.component.interface';
import {IDataSourceItem} from '../../../../components/datasource/datasources.interface';
import {IDevExpressDataGridEventOnContentReady, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid, IDevExpressDataGridColumnCustomizeTextCellInfo, TDevExpressDataGridColumnCustomizeTextFn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonReembolsoIva, IJsonReembolsoIvaDocumento, IJsonReembolsoIvaItem, IJsonReembolsoIvaRelacao} from '../../jsonReembolsoIva.entity.interface';
import {EReembolsosIvaTipoCampo, IReembolsosIvaEntityService, IReembolsosIvaPeriodoRelacao} from '../../reembolsosIva.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {TEntityServiceRequestData} from '../../../../services/entity/entity.service.interface';
import {monthName} from '../../../../../common/dates';
import {DATA_SOURCE_PAISES_ISO_3166} from '../../../../datasources/paisesiso3166/paisesISO3166.datasource';
import {IPaisesISO3166} from '../../../../datasources/paisesiso3166/paisesISO3166.datasource.interface';
import {CONSTANT_ABREV_FISCAL_PAIS_NACIONAL, CONSTANT_COD_PAIS_NACIONAL} from '../../../../../config/constants';

@Component({
  selector: 'reembolsosiva-detail',
  templateUrl: './reembolsosIva.entity.detail.component.html'
})
export class ReembolsosIvaDetailComponent extends ModuloEntityDetailComponent<IJsonReembolsoIva, IReembolsosIvaEntityService> implements OnInit {
  public readonly reembolsosIvaPill: typeof EReembolsosIvaPill;
  public readonly reembolsosIvaTab: typeof EReembolsosIvaTab;
  public readonly tabs: Array<IReembolsosIvaTab>;
  public readonly pills: Array<IReembolsosIvaPanel>;
  public readonly dataGridDefinitionDocuments: IDevExpressDataGrid;
  public readonly dataGridDefinitionRelations: IDevExpressDataGrid;

  public dataGridClientCampo1: IDevExpressDataGrid<IJsonReembolsoIvaRelacao, IJsonReembolsoIvaRelacao>;
  public dataGridClientCampo3: IDevExpressDataGrid<IJsonReembolsoIvaRelacao, IJsonReembolsoIvaRelacao>;
  public dataGridCampos: IDevExpressDataGrid<IJsonReembolsoIvaRelacao, IJsonReembolsoIvaRelacao>;
  public dataGridClientCampo1Source: Array<IJsonReembolsoIvaRelacao>;
  public dataGridClientCampo3Source: Array<IJsonReembolsoIvaRelacao>;
  public dataGridCampo20Source: Array<IJsonReembolsoIvaRelacao>;
  public dataGridCampo21Source: Array<IJsonReembolsoIvaRelacao>;
  public dataGridCampo22Source: Array<IJsonReembolsoIvaRelacao>;
  public dataGridCampo23Source: Array<IJsonReembolsoIvaRelacao>;
  public dataGridCampo24Source: Array<IJsonReembolsoIvaRelacao>;
  public temErros: boolean;
  public periodoRelacao: string;
  public selectOptions: Array<IReembolsosIvaPeriodoRelacao>;
  public idTab: EReembolsosIvaTab;
  public idPill: EReembolsosIvaPill;
  public showDocsWithError: IReembolsosIvaDocsWithError;
  public promise: Promise<IJsonReembolsoIva>;
  public totalClientCampo4: number;
  public totalClientCampo5: number;
  public totalClientCampo6: number;
  public totalValorAquisicoes: number;
  public totalIvaDeduzido: number;

  private _dataGridInstanceClientCampo1: dxDataGrid<IJsonReembolsoIvaRelacao, IJsonReembolsoIvaRelacao>;
  private _dataGridInstanceClientCampo3: dxDataGrid<IJsonReembolsoIvaRelacao, IJsonReembolsoIvaRelacao>;
  private _dataGridInstanceCampo20: dxDataGrid<IJsonReembolsoIvaRelacao, IJsonReembolsoIvaRelacao>;
  private _dataGridInstanceCampo21: dxDataGrid<IJsonReembolsoIvaRelacao, IJsonReembolsoIvaRelacao>;
  private _dataGridInstanceCampo22: dxDataGrid<IJsonReembolsoIvaRelacao, IJsonReembolsoIvaRelacao>;
  private _dataGridInstanceCampo23: dxDataGrid<IJsonReembolsoIvaRelacao, IJsonReembolsoIvaRelacao>;
  private _dataGridInstanceCampo24: dxDataGrid<IJsonReembolsoIvaRelacao, IJsonReembolsoIvaRelacao>;
  private _cacheManager: unknown;
  private _firstTime: boolean;
  private _fromCache: boolean;
  private _isDocuments: boolean;
  private _shouldFetch: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.reembolsosIvaPill = EReembolsosIvaPill;
    this.reembolsosIvaTab = EReembolsosIvaTab;
    this.dataGridClientCampo1Source = [];
    this.dataGridClientCampo3Source = [];
    this.dataGridCampo20Source = [];
    this.dataGridCampo21Source = [];
    this.dataGridCampo22Source = [];
    this.dataGridCampo23Source = [];
    this.dataGridCampo24Source = [];
    this.dataGridDefinitionDocuments = {
      columns: [
        {caption: '', cellTemplate: 'cellTemplateError', showInColumnChooser: false},
        {dataField: 'periodoDeclaracao', dataType: 'string', caption: 'reembolsosiva.fields.periodoDeclaracao'},
        {dataField: 'periodoRelacao', dataType: 'string', caption: 'reembolsosiva.fields.periodoRelacao'},
        {dataField: 'nCampoDeducao', dataType: 'string', caption: 'reembolsosiva.fields.nCampoDeducao'},
        {
          dataField: 'mercado',
          dataType: 'string',
          caption: 'reembolsosiva.fields.mercado',
          calculateCellValue: (rowData: IJsonReembolsoIvaDocumento): string => {
            const mercado = DATA_SOURCE_MERCADOS.data.find((mercadoItem) => mercadoItem.value === rowData.mercado);
            return this._translateService.instant(mercado.name ? mercado.name : '');
          }
        },
        {dataField: 'periodo', dataType: 'string', caption: 'reembolsosiva.fields.periodo'},
        {dataField: 'nDiario', dataType: 'number', caption: 'reembolsosiva.fields.nDiario'},
        {dataField: 'nDocInterno', dataType: 'string', caption: 'reembolsosiva.fields.nDocInterno'},
        {dataField: 'codPais', dataType: 'number', caption: 'reembolsosiva.fields.codPais'},
        {dataField: 'abrevFiscal', dataType: 'string', caption: 'reembolsosiva.fields.abrevFiscal'},
        {dataField: 'nContribuinte', dataType: 'string', caption: 'reembolsosiva.fields.nContribuinte'},
        {
          dataField: 'dC',
          dataType: 'string',
          caption: 'reembolsosiva.fields.dC',
          calculateCellValue: (rowData: IJsonReembolsoIvaDocumento): string => {
            const dC = DATA_SOURCE_DEBITO_CREDITO.data.find((debitoCredito: IDataSourceItem<EDebitoCredito>) => debitoCredito.value === rowData.dC);
            return this._translateService.instant(dC.label ? dC.label : '');
          }
        },
        {dataField: 'valorTransacaoAbsoluto', dataType: 'double', caption: 'reembolsosiva.fields.valorTransacaoAbsoluto'},
        {dataField: 'valorIvaDeduzidoAbsoluto', dataType: 'double', caption: 'reembolsosiva.fields.valorIvaDeduzidoAbsoluto'},
        {dataField: 'nDocExterno', dataType: 'string', caption: 'reembolsosiva.fields.nDocExterno'},
        {dataField: 'dataDocExterno', dataType: 'date', caption: 'reembolsosiva.fields.dataDocExterno'},
        {dataField: 'nDeclarImportExport', dataType: 'string', caption: 'reembolsosiva.fields.nDeclarImportExport'},
        {dataField: 'nConta', dataType: 'string', caption: 'reembolsosiva.fields.nConta'},
        {dataField: 'nomeConta', dataType: 'string', caption: 'reembolsosiva.fields.nomeConta'}
      ],
      keyExpr: '_index',
      dataSource: [],
      columnHidingEnabled: false,
      export: {
        filename: () => {
          return this.idTab === EReembolsosIvaTab.Clients
            ? this._translateService.instant('reembolsosiva.tabs.clientDoc', {nreembolso: this.model.periodoDeclaracao})
            : this._translateService.instant('reembolsosiva.tabs.suppliersDoc', {nreembolso: this.model.periodoDeclaracao});
        }
      },
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateDocuments'},
      summary: {
        groupItems: [
          {column: 'valorTransacaoAbsoluto', displayFormat: '{0}', showInGroupFooter: true, skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorIvaDeduzidoAbsoluto', displayFormat: '{0}', showInGroupFooter: true, skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'}
        ]
      },
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbarDocuments',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.dataGridDefinitionRelations = {
      columnHidingEnabled: false,
      columns: [
        {caption: '', cellTemplate: 'cellTemplateError'},
        {dataField: 'periodoDeclaracao', dataType: 'string', caption: 'reembolsosiva.fields.periodoDeclaracao'},
        {dataField: 'periodoRelacao', dataType: 'string', caption: 'reembolsosiva.fields.periodoRelacao'},
        {dataField: 'nCampoDeducao', dataType: 'string', caption: 'reembolsosiva.fields.nCampoDeducao'},
        {
          dataField: 'mercado',
          dataType: 'string',
          caption: 'reembolsosiva.fields.mercado',
          calculateCellValue: (rowData: IJsonReembolsoIvaDocumento): string => {
            const mercado = DATA_SOURCE_MERCADOS.data.find((mercadoItem: IDataSourceItem<number>) => mercadoItem.value === rowData.mercado);
            return this._translateService.instant(mercado.name);
          }
        },
        {dataField: 'codPais', dataType: 'number', caption: 'reembolsosiva.fields.codPais'},
        {dataField: 'abrevFiscal', dataType: 'string', caption: 'reembolsosiva.fields.abrevFiscal'},
        {dataField: 'nContribuinte', dataType: 'string', caption: 'reembolsosiva.fields.nContribuinte'},
        {dataField: 'valorTransacao', dataType: 'double', caption: 'reembolsosiva.fields.valorTransacaoAbsoluto'},
        {dataField: 'valorIvaDeduzido', dataType: 'double', caption: 'reembolsosiva.fields.valorIvaDeduzidoAbsoluto'},
        {dataField: 'anoDocExterno', dataType: 'number', caption: 'reembolsosiva.fields.anoDocExterno'},
        {dataField: 'mesDocExterno', dataType: 'number', caption: 'reembolsosiva.fields.mesDocExterno'},
        {dataField: 'nDeclarImportExport', dataType: 'number', caption: 'reembolsosiva.fields.nDeclarImportExport'}
      ],
      dataSource: [],
      keyExpr: 'ivaReRelacoesID',
      summary: {
        groupItems: [
          {column: 'valorTransacao', displayFormat: '{0}', showInGroupFooter: true, skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorIvaDeduzido', displayFormat: '{0}', showInGroupFooter: true, skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'}
        ]
      },
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbarDocuments',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };

    this.dataGridClientCampo1 = {
      columns: [
        {dataField: 'index', dataType: 'number', caption: '', cellTemplate: 'dataGridTemplateIndex', allowEditing: false, allowFiltering: false},
        {
          dataField: 'nContribuinte',
          dataType: 'string',
          caption: 'reembolsosiva.clientes.1.datagrid.nif',
          allowFiltering: false
        },
        {
          dataField: 'valorTransacao',
          dataType: 'double',
          caption: 'reembolsosiva.clientes.1.datagrid.valor',
          allowFiltering: false
        }
      ],
      summary: {
        totalItems: [
          {
            column: 'valorTransacao',
            summaryType: 'sum',
            valueFormat: 'double',
            displayFormat: `${<string>this._translateService.instant('global.text.total')}: {0}`
          }
        ]
      },
      columnHidingEnabled: false,
      export: {enabled: false},
      columnChooser: {enabled: false},
      remoteOperations: false,
      headerFilter: {visible: false}
    };
    this.dataGridClientCampo3 = {
      columns: [
        {dataField: 'index', dataType: 'number', caption: '', cellTemplate: 'dataGridTemplateIndex', allowEditing: false, allowFiltering: false},
        {
          dataField: 'nDeclarImportExport',
          dataType: 'string',
          caption: 'reembolsosiva.clientes.3.datagrid.nIdnDeclaracaoExportacao',
          allowFiltering: false
        },
        {
          dataField: 'valorTransacao',
          dataType: 'double',
          caption: 'reembolsosiva.clientes.3.datagrid.valor',
          allowFiltering: false
        }
      ],
      summary: {
        totalItems: [
          {
            column: 'valorTransacao',
            summaryType: 'sum',
            valueFormat: 'double',
            displayFormat: `${<string>this._translateService.instant('global.text.total')}: {0}`
          }
        ]
      },
      columnHidingEnabled: false,
      export: {enabled: false},
      columnChooser: {enabled: false},
      remoteOperations: false,
      headerFilter: {visible: false}
    };
    this.dataGridCampos = {
      columns: [
        {dataField: 'index', dataType: 'number', caption: '', cellTemplate: 'dataGridTemplateIndex', allowEditing: false, allowFiltering: false},
        {
          caption: 'reembolsosiva.fornecedores.datagrid.identificacao',
          columns: [
            {
              dataField: 'abrevFiscal',
              dataType: 'string',
              caption: 'reembolsosiva.fornecedores.datagrid.prefixo',
              customizeText: this._fnCustomizeTextPais
            },
            {
              dataField: 'nContribuinte',
              dataType: 'number',
              caption: 'reembolsosiva.fornecedores.datagrid.nif'
            }
          ]
        },
        {
          caption: 'reembolsosiva.fornecedores.datagrid.periodoEmissao',
          columns: [
            {
              dataField: 'anoDocExterno',
              dataType: 'number',
              caption: 'reembolsosiva.fornecedores.datagrid.ano'
            },
            {
              dataField: 'mesDocExterno',
              dataType: 'number',
              caption: 'reembolsosiva.fornecedores.datagrid.mes',
              customizeText: this._fnCustomizeTextMonth
            }
          ]
        },
        {dataField: 'valorTransacao', dataType: 'double', caption: 'reembolsosiva.fornecedores.datagrid.valorAquisicoes', allowFiltering: false},
        {dataField: 'valorIvaDeduzido', dataType: 'double', caption: 'reembolsosiva.fornecedores.datagrid.ivaDeduzido', allowFiltering: false}
      ],
      summary: {
        totalItems: [
          {
            column: 'valorTransacao',
            summaryType: 'sum',
            valueFormat: 'double',
            displayFormat: `${<string>this._translateService.instant('global.text.total')}: {0}`
          },
          {
            column: 'valorIvaDeduzido',
            summaryType: 'sum',
            valueFormat: 'double',
            displayFormat: `${<string>this._translateService.instant('global.text.total')}: {0}`
          }
        ]
      },
      columnHidingEnabled: false,
      export: {enabled: false},
      columnChooser: {enabled: false},
      remoteOperations: false,
      headerFilter: {visible: false}
    };

    this.tabs = [
      {icon: 'fa-users', title: 'reembolsosiva.tabs.clients', name: EReembolsosIvaTab.Clients},
      {icon: 'fa-truck', title: 'reembolsosiva.tabs.suppliers', name: EReembolsosIvaTab.Suppliers}
    ];
    this.pills = [
      {icon: 'fa-file-o', title: 'reembolsosiva.tabs.documents'},
      {icon: 'fa-files-o', title: 'reembolsosiva.tabs.relations'}
    ];
    this._isDocuments = true;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.temErros = false;
    this._shouldFetch = false;
    this.promise = undefined;
    this._fromCache = false;
    this._cacheManager = {
      clients: {
        documents: [],
        relations: []
      },
      suppliers: {
        documents: [],
        relations: []
      }
    };
    this.showDocsWithError = {
      clients: {
        documents: false,
        relations: false
      },
      suppliers: {
        documents: false,
        relations: false
      }
    };

    this.periodoRelacao = undefined;
    this.selectOptions = [];
    this._firstTime = true;

    if (!this.model) {
      this._stateService.go(this.states.list.name);
      return;
    }

    // separar relações dos documentos
    this._separarDocumentosRelacoes();

    this.toolbar.addButton({
      id: 'btnProcess',
      type: 'button',
      class: 'btn-primary',
      iconLeft: '<i class="fa fa-fw fa-cogs"></i>',
      caption: 'reembolsosiva.reProcess',
      order: 103,
      click: () => this._processar()
    });

    const keysPeriodosRelacao: Array<string> = [];
    let periodosRelacao: Array<IReembolsosIvaPeriodoRelacao> = [];
    for (const property of Object.keys(this.model)) {
      const data: Array<IJsonReembolsoIvaItem> = <Array<IJsonReembolsoIvaItem>>this.model[property];
      if (!isArray(data)) {
        continue;
      }
      for (const item of data) {
        if (!isObject(item)) {
          continue;
        }
        if (!keysPeriodosRelacao.includes(item.periodoRelacao)) {
          keysPeriodosRelacao.push(item.periodoRelacao);
          periodosRelacao.push({
            value: item.periodoRelacao,
            name: item.periodoRelacao
          });
        }
      }
    }
    periodosRelacao = sortBy(periodosRelacao, ['name']);
    periodosRelacao.unshift({value: REEMBOLSOS_IVA_DEFAULT_VALUE, name: 'reembolsosiva.fields.all'});
    this.selectOptions = periodosRelacao;

    this.periodoRelacao = this.params.periodoRelacao;
    if (this.periodoRelacao !== REEMBOLSOS_IVA_DEFAULT_VALUE) {
      this.changedPeriodoRelacao();
    }

    this.idTab = this.params.typeTab;
    this.idPill = this.params.typePill;

    this.temErros = this.model.temErros;
    if (!this.temErros) {
      this.periodoRelacao = this.selectOptions[0].value;
    }
  }

  public delete(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const periodoDeclaracao = this.model.periodoDeclaracao;
      const data = {id: periodoDeclaracao, params: {}};

      const title = this._translateService.instant('reembolsosiva.deleteReembolsoTitle');
      let message = this._translateService.instant('reembolsosiva.deleteReembolsoMessage', {declaracao: periodoDeclaracao});

      const periodoRelacao = this.model.periodoRelacao;
      if (periodoRelacao && periodoRelacao !== periodoDeclaracao) {
        message = this._translateService.instant('reembolsosiva.deleteReembolsoComplexMessage', {declaracao: periodoDeclaracao, relacao: periodoRelacao});
        data.params = {
          periodoRelacao: periodoRelacao
        };
      }

      this._cgModalService
        .showOkCancel(title, message)
        .then(() => {
          this.service
            .delete(data)
            .then(() => {
              this._plAlertService.success('reembolsosiva.messages.successDeleteDocument');
              this._stateService.go(this.states.list.name);
            })
            .finally(() => {
              resolve();
            });
        })
        .catch(reject);
    });
  }

  public changeTab(id: string): void {
    this.idTab = <EReembolsosIvaTab>id;
    this._refreshTables();
    this._stateService.go(this._uiRouterGlobals.current, {typeTab: this.idTab});
  }

  public changePanel(event: IPlNavPillEventSelected): void {
    this.idPill = <EReembolsosIvaPill>event.nextId;
    this._isDocuments = this.idPill === 'documents';
    this._refreshTables();
    this._stateService.go(this._uiRouterGlobals.current, {typePill: this.idPill});
  }

  public shouldDisable(): boolean {
    return this.temErros || isDefined(this.promise);
  }

  public async changedPeriodoRelacao(periodoRelacao: string = this.periodoRelacao): Promise<void> {
    this.periodoRelacao = periodoRelacao;
    this._shouldFetch = true;
    await this._stateService.go(this._uiRouterGlobals.current, {periodoRelacao: this.periodoRelacao});
    await this._refreshTables();
    this._separarDocumentosRelacoes();
  }

  public showDocsWithErrorSwitchChange(value: boolean, tabId: string, navTabId: string): void {
    this.showDocsWithError[tabId][navTabId] = value;
    this._fromCache = true;
    this._refreshTables();
  }

  public onSelect({item, columnIndex}: ICGTableOnSelect<IJsonReembolsoIva>): void {
    if (isNumber(columnIndex) && columnIndex !== -1) {
      item._detailOpen = !item._detailOpen;
    }
  }

  public onInitialized(): Promise<void> {
    if (this.idPill === this.reembolsosIvaPill.Documents) {
      return this._applyDataSourceDocuments();
    }
    return this._applyDataSourceRelations();
  }

  public onInitializedClientCampo1({component}: IDevExpressDataGridEventOnInitialized<IJsonReembolsoIvaRelacao, IJsonReembolsoIvaRelacao>): void {
    this._dataGridInstanceClientCampo1 = component;
  }

  public onInitializedClientCampo3({component}: IDevExpressDataGridEventOnInitialized<IJsonReembolsoIvaRelacao, IJsonReembolsoIvaRelacao>): void {
    this._dataGridInstanceClientCampo3 = component;
  }

  public onInitializedCampo20({component}: IDevExpressDataGridEventOnInitialized<IJsonReembolsoIvaRelacao, IJsonReembolsoIvaRelacao>): void {
    this._dataGridInstanceCampo20 = component;
  }

  public onInitializedCampo21({component}: IDevExpressDataGridEventOnInitialized<IJsonReembolsoIvaRelacao, IJsonReembolsoIvaRelacao>): void {
    this._dataGridInstanceCampo21 = component;
  }

  public onInitializedCampo22({component}: IDevExpressDataGridEventOnInitialized<IJsonReembolsoIvaRelacao, IJsonReembolsoIvaRelacao>): void {
    this._dataGridInstanceCampo22 = component;
  }

  public onInitializedCampo23({component}: IDevExpressDataGridEventOnInitialized<IJsonReembolsoIvaRelacao, IJsonReembolsoIvaRelacao>): void {
    this._dataGridInstanceCampo23 = component;
  }

  public onInitializedCampo24({component}: IDevExpressDataGridEventOnInitialized<IJsonReembolsoIvaRelacao, IJsonReembolsoIvaRelacao>): void {
    this._dataGridInstanceCampo24 = component;
  }

  public onContentReadyCamposClient(): void {
    this._calculateClientesTotais();
  }

  public onContentReadyCampos(): void {
    this._calculateFornecedoresTotais();
  }

  public onContentReady(event: IDevExpressDataGridEventOnContentReady): void {
    let doExpandRow = false;
    let docindex: number;
    const documentIndex: number = this._transition.params().documentIndex;
    if (this._firstTime && (documentIndex !== null || this.model.temErros)) {
      this._firstTime = false;
      if (documentIndex !== null) {
        doExpandRow = true;
        docindex = documentIndex;
      } else if (this.model.temErros) {
        const source: Array<IJsonReembolsoIvaDocumento> = this.idTab === EReembolsosIvaTab.Clients ? this.model.documentosClientes : this.model.documentosFornecedores;
        const index = source.findIndex((itemData: IJsonReembolsoIvaDocumento) => Boolean(itemData.error));
        if (index !== -1) {
          doExpandRow = true;
          docindex = index;
        }
      }

      if (doExpandRow) {
        if (this.idPill === EReembolsosIvaPill.Documents && documentIndex !== null) {
          event.component.expandRow(documentIndex);
        } else {
          const visibleData: Array<Row> = event.component.getVisibleRows();
          const item: Row = visibleData[docindex];
          if (item) {
            event.component.expandRow(item.key);
          }
        }
      }
    }
  }

  public readonly fnEditDocument = (document: TPlTableItem<IJsonReembolsoIvaDocumento>) => (): Promise<void> => this._editDocument(document);

  public readonly fnDeleteDocument = (document: TPlTableItem<IJsonReembolsoIvaDocumento>) => (): Promise<void> => this._deleteDocument(document);

  private _source(): Promise<IJsonReembolsoIva> {
    let resolve: IJsonReembolsoIva | Promise<IJsonReembolsoIva> = this.model;
    if (this._shouldFetch) {
      this._shouldFetch = false;
      const request: TEntityServiceRequestData = {
        id: this.model.periodoDeclaracao,
        params: {
          periodoRelacao: this.periodoRelacao !== REEMBOLSOS_IVA_DEFAULT_VALUE ? this.periodoRelacao : undefined
        }
      };
      resolve = this.service
        .get(request)
        .then((response) => {
          this.model = response.body;
          return this.model;
        })
        .finally(() => {
          this.promise = undefined;
        });
      this.promise = resolve;
    }
    return Promise.resolve(resolve);
  }

  private _editDocument(document: TReembolsosIvaDocumentoSourceTableItem): Promise<void> {
    return this._cgStateService.redirectToState({
      stateOrName: ENTITY_NAME_DOCS_CONTABILIDADE,
      stateType: EEntityStateDetailType.EDIT,
      params: {
        id: document.extPocCabID,
        returnState: this.states.detail.name,
        returnStateParams: {
          ...this._transition.params(),
          documentIndex: document._index,
          typeTab: this.idTab,
          typePill: this.idPill
        }
      }
    });
  }

  private _deleteDocument(document: TReembolsosIvaDocumentoSourceTableItem): Promise<void> {
    if (!document.ivaReDocsID) {
      return undefined;
    }
    return new Promise<void>((resolve, reject) => {
      const message = this._translateService.instant('reembolsosiva.deleteDocumentMessage', {declaracao: document.periodoDeclaracao, relacao: document.periodoRelacao});
      this._cgModalService
        .showOkCancel('reembolsosiva.deleteDocumentTitle', message)
        .then(() => {
          this.service
            .deleteDocument(document.ivaReDocsID)
            .then(() => {
              this._shouldFetch = true;
              this._refreshTables();
              this._plAlertService.success('reembolsosiva.messages.successDeleteDocument');
            })
            .finally(() => {
              resolve();
            });
        })
        .catch(reject);
    });
  }

  private _processar(): Promise<void> {
    const periodosRelacao = this.selectOptions.slice(1).map((periodo) => periodo.value);
    const myModel: IJsonReembolsoIva = copy(this.model);
    myModel.periodosRelacao = periodosRelacao;
    return this.service.put({id: myModel.periodoDeclaracao, body: myModel}).then((response) => {
      this.temErros = response.body.temErros;
      let message: string;
      if (!this.temErros) {
        message = this._translateService.instant('reembolsosiva.savedAlt', {id: response.body.periodoDeclaracao});
        this._plAlertService.success(message);
      } else {
        message = this._translateService.instant('reembolsosiva.error', {id: response.body.periodoDeclaracao});
        this._plAlertService.warning(message);
      }

      const params = merge({}, this.params, {model: response.body, typeTab: this.idTab, typePill: this.idPill});
      this._stateService.go(this._uiRouterGlobals.current, params, {reload: true, inherit: false});
    });
  }

  private _refreshTables(): Promise<void> {
    if (this._isDocuments) {
      return this._applyDataSourceDocuments();
    }
    return this._applyDataSourceRelations();
  }

  private async _applyDataSourceDocuments(): Promise<void> {
    if (this._fromCache) {
      this._fromCache = false;
      let cacheSource: Array<TReembolsosIvaDocumentoSourceItem> = this._cacheManager[this.idTab].documents;
      if (cacheSource.length && this.showDocsWithError[this.idTab].documents) {
        cacheSource = cacheSource.filter((item) => item.error?.length);
      }
      this.dataGridDefinitionDocuments.dataSource = cacheSource;
      return;
    }

    const source: IJsonReembolsoIva = await this._source();
    let tableSource: Array<TReembolsosIvaDocumentoSourceItem>;
    switch (this.idTab) {
      case 'clients':
        tableSource = source.documentosClientes;
        break;
      case 'suppliers':
        tableSource = source.documentosFornecedores;
        break;
      default:
        break;
    }
    for (let index = 0; index < tableSource.length; index++) {
      const item = tableSource[index];
      item._index = index;
    }
    if (source.temErros) {
      tableSource = sortBy(tableSource, (item: TReembolsosIvaDocumentoSourceItem) => !item.error);
    }
    this._cacheManager[this.idTab].documents = tableSource;
    this.dataGridDefinitionDocuments.dataSource = tableSource;
  }

  private async _applyDataSourceRelations(): Promise<void> {
    const filename: string =
      this.idTab === EReembolsosIvaTab.Clients
        ? this._translateService.instant('reembolsosiva.tabs.clientRel', {nreembolso: this.model.periodoDeclaracao})
        : this._translateService.instant('reembolsosiva.tabs.suppliersRel', {nreembolso: this.model.periodoDeclaracao});
    this.dataGridDefinitionRelations.export = {filename: filename};

    if (this._fromCache) {
      this._fromCache = false;
      let cacheSource: Array<IJsonReembolsoIvaRelacao> = this._cacheManager[this.idTab].relations;
      if (cacheSource.length && this.showDocsWithError[this.idTab].relations) {
        cacheSource = cacheSource.filter((item) => item.error?.length);
      }
      this.dataGridDefinitionRelations.dataSource = cacheSource;
      return;
    }

    const source: IJsonReembolsoIva = await this._source();
    let tableSource: Array<TReembolsosIvaRelacaoSourceItem>;
    switch (this.idTab) {
      case 'clients':
        tableSource = source.relacoesClientes;
        break;
      case 'suppliers':
        tableSource = source.relacoesFornecedores;
        break;
      default:
        tableSource = [];
        break;
    }
    if (source.temErros) {
      tableSource = sortBy(tableSource, (item: TReembolsosIvaRelacaoSourceItem) => !item.error);
    }
    this._cacheManager[this.idTab].relations = tableSource;
    this.dataGridDefinitionRelations.dataSource = tableSource;
  }

  private _customizeTextPais(cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string {
    if (cellInfo.target === 'row') {
      const paisData: IPaisesISO3166 = DATA_SOURCE_PAISES_ISO_3166.data.find((pais: IPaisesISO3166) => pais.codigoAlpha2 === cellInfo.valueText);
      if (paisData) {
        return `${cellInfo.valueText} - ${paisData.designacaoPT}`;
      }
      return cellInfo.valueText;
    }
    return '';
  }

  private _customizeTextMonth(cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string {
    if (cellInfo.target === 'row') {
      return upperFirst(monthName(<number>cellInfo.value - 1));
    }
    return '';
  }

  private _calculateClientesTotais(): void {
    this.totalClientCampo6 =
      <number>this._dataGridInstanceClientCampo1.getTotalSummaryValue('valorTransacao') +
      <number>this._dataGridInstanceClientCampo3.getTotalSummaryValue('valorTransacao') +
      this.totalClientCampo4 +
      this.totalClientCampo5;
  }

  private _calculateFornecedoresTotais(): void {
    this.totalValorAquisicoes =
      <number>this._dataGridInstanceCampo20.getTotalSummaryValue('valorTransacao') +
      <number>this._dataGridInstanceCampo21.getTotalSummaryValue('valorTransacao') +
      <number>this._dataGridInstanceCampo22.getTotalSummaryValue('valorTransacao') +
      <number>this._dataGridInstanceCampo23.getTotalSummaryValue('valorTransacao') +
      <number>this._dataGridInstanceCampo24.getTotalSummaryValue('valorTransacao');

    this.totalIvaDeduzido =
      <number>this._dataGridInstanceCampo20.getTotalSummaryValue('valorIvaDeduzido') +
      <number>this._dataGridInstanceCampo21.getTotalSummaryValue('valorIvaDeduzido') +
      <number>this._dataGridInstanceCampo22.getTotalSummaryValue('valorIvaDeduzido') +
      <number>this._dataGridInstanceCampo23.getTotalSummaryValue('valorIvaDeduzido') +
      <number>this._dataGridInstanceCampo24.getTotalSummaryValue('valorIvaDeduzido');
  }

  private _separarDocumentosRelacoes(): void {
    // clientes
    this.dataGridClientCampo1Source = [];
    this.dataGridClientCampo3Source = [];
    this.totalClientCampo4 = 0;
    this.totalClientCampo5 = 0;
    for (const relacao of this.model.relacoesClientes) {
      switch (relacao.tipoCampo) {
        case EReembolsosIvaTipoCampo.Normal:
          if (relacao.abrevFiscal === CONSTANT_ABREV_FISCAL_PAIS_NACIONAL && relacao.codPais === CONSTANT_COD_PAIS_NACIONAL) {
            this.dataGridClientCampo1Source.push(relacao);
          } else if (relacao.abrevFiscal !== CONSTANT_ABREV_FISCAL_PAIS_NACIONAL && relacao.codPais !== CONSTANT_COD_PAIS_NACIONAL) {
            this.dataGridClientCampo3Source.push(relacao);
          }
          break;
        case EReembolsosIvaTipoCampo.OperacoesEfetuadasNoEstrangeiro:
          this.totalClientCampo4 += relacao.valorTransacao;
          break;
        case EReembolsosIvaTipoCampo.OutrasOperacoesIsentas:
          this.totalClientCampo5 += relacao.valorTransacao;
          break;
      }
    }

    // fornecedores
    this.dataGridCampo20Source = this.model.relacoesFornecedores.filter((relacao: IJsonReembolsoIvaRelacao) => relacao.nCampoDeducao === '20');
    this.dataGridCampo21Source = this.model.relacoesFornecedores.filter((relacao: IJsonReembolsoIvaRelacao) => relacao.nCampoDeducao === '21');
    this.dataGridCampo22Source = this.model.relacoesFornecedores.filter((relacao: IJsonReembolsoIvaRelacao) => relacao.nCampoDeducao === '22');
    this.dataGridCampo23Source = this.model.relacoesFornecedores.filter((relacao: IJsonReembolsoIvaRelacao) => relacao.nCampoDeducao === '23');
    this.dataGridCampo24Source = this.model.relacoesFornecedores.filter((relacao: IJsonReembolsoIvaRelacao) => relacao.nCampoDeducao === '24');
  }

  private readonly _fnCustomizeTextPais: TDevExpressDataGridColumnCustomizeTextFn = (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => this._customizeTextPais(cellInfo);

  private readonly _fnCustomizeTextMonth: TDevExpressDataGridColumnCustomizeTextFn = (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => this._customizeTextMonth(cellInfo);
}
