<div class="ativosconsistencia entity-detail-form">
  <pl-tabs [callback]="tabCallback" (evtSelected)="changedTab($event.nextId)" [activeId]="activeTab">
    <pl-tab [id]="ativosConsistTableIDContab" *ngIf="temContab" caption="ativosconsistencia.tab.ativosconsistenciaContab">
      <div *plTabContent>
        <ng-container
          *ngTemplateOutlet="
            templateAtivosConsistDataGrid;
            context: {
              id: ativosConsistTableIDContab,
              keyExpr: 'extPocCabId',
              dataSource: listaContab,
              summary: summaryContab,
              dataGridFields: dataGridFieldsContab,
              selectedRowKeys: selectedKeysContab
            }
          ">
        </ng-container>
      </div>
    </pl-tab>

    <pl-tab [id]="ativosConsistTableIDResumo" caption="ativosconsistencia.tab.ativosconsistenciaResumo">
      <div *plTabContent>
        <ng-container
          *ngTemplateOutlet="
            templateAtivosConsistDataGrid;
            context: {id: ativosConsistTableIDResumo, keyExpr: 'nConta', dataSource: listaResumo, summary: summaryResumo, dataGridFields: dataGridFieldsResumo, selectedRowKeys: undefined}
          ">
        </ng-container>
      </div>
    </pl-tab>

    <pl-tab [id]="ativosConsistTableIDAnaliseConta" caption="ativosconsistencia.tab.ativosconsistenciaAnaliseConta">
      <div *plTabContent>
        <pl-form>
          <pl-group>
            <pl-group>
              <label [translate]="'ativosconsistencia.analiseconta.ano'"></label>
              <edit>
                <pl-autocomplete [source]="anosSource" [model]="ano" [rowTemplate]="anoTemplate" (evtSelected)="anoChanged($event)" [allowInvalid]="false" output="ano"> </pl-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'ativosconsistencia.analiseconta.registoMes'"></label>
              <edit>
                <pl-edit type="meses" [model]="mes" (modelChange)="mesChanged($event)"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>
        </pl-form>

        <ng-container
          *ngTemplateOutlet="
            templateAtivosConsistDataGrid;
            context: {
              id: ativosConsistTableIDAnaliseConta,
              keyExpr: 'nConta',
              dataSource: listaAnaliseConta,
              summary: summaryAnalise,
              dataGridFields: dataGridFieldsAnalise,
              selectedRowKeys: undefined
            }
          ">
        </ng-container>
      </div>
    </pl-tab>

    <pl-tab [id]="ativosConsistTableIDPeriodoDiferente" *ngIf="temPeriodoDiferente" caption="ativosconsistencia.tab.ativosconsistenciaPeriodoDiferente">
      <div *plTabContent>
        <ng-container
          *ngTemplateOutlet="
            templateAtivosConsistDataGrid;
            context: {
              id: ativosConsistTableIDPeriodoDiferente,
              keyExpr: 'codAtivo',
              dataSource: listaPeriodoDiferente,
              summary: summaryPerioDif,
              dataGridFields: dataGridFieldsPerioDif,
              selectedRowKeys: undefined
            }
          ">
        </ng-container>
      </div>
    </pl-tab>

    <pl-tab [id]="ativosConsistTableIDAnosErrados" *ngIf="temAnosErrados" caption="ativosconsistencia.tab.ativosconsistenciaAnosErrados">
      <div *plTabContent>
        <ng-container
          *ngTemplateOutlet="
            templateAtivosConsistDataGrid;
            context: {
              id: ativosConsistTableIDAnosErrados,
              keyExpr: 'codAtivo',
              dataSource: listaAnosErrados,
              summary: dataGridDefinition.summary,
              dataGridFields: dataGridFieldsAnosErrados,
              selectedRowKeys: undefined
            }
          ">
        </ng-container>
      </div>
    </pl-tab>

    <pl-tab [id]="ativosConsistTableIDVendaDiferente" *ngIf="temVendaDiferente" caption="ativosconsistencia.tab.ativosconsistenciaVendaDiferente">
      <div *plTabContent>
        <ng-container
          *ngTemplateOutlet="
            templateAtivosConsistDataGrid;
            context: {
              id: ativosConsistTableIDVendaDiferente,
              keyExpr: 'codAtivo',
              dataSource: listaVendaDiferente,
              summary: summaryVendaDif,
              dataGridFields: dataGridFieldsVendaDif,
              selectedRowKeys: undefined
            }
          ">
        </ng-container>
      </div>
    </pl-tab>

    <pl-tab [id]="ativosConsistTableIDIgnorados" *ngIf="temIgnorados" caption="ativosconsistencia.tab.ativosconsistenciaIgnorados">
      <div *plTabContent>
        <ng-container
          *ngTemplateOutlet="
            templateAtivosConsistDataGrid;
            context: {
              id: ativosConsistTableIDIgnorados,
              keyExpr: 'extPocCabId',
              dataSource: listaIgnorados,
              summary: summaryIgnorados,
              dataGridFields: dataGridFieldsIgnorados,
              selectedRowKeys: selectedKeysIgnorados
            }
          ">
        </ng-container>
      </div>
    </pl-tab>

    <pl-tab [id]="ativosConsistTableIDAjustes" *ngIf="temAjustes" caption="ativosconsistencia.tab.ativosajustesgerados">
      <div *plTabContent>
        <ng-container
          *ngTemplateOutlet="
            templateAtivosConsistDataGrid;
            context: {
              id: ativosConsistTableIDAjustes,
              keyExpr: 'extPocCabId',
              dataSource: listaAjustes,
              summary: dataGridDefinition.summary,
              dataGridFields: dataGridFieldsAjustes,
              selectedRowKeys: undefined
            }
          ">
        </ng-container>
      </div>
    </pl-tab>

    <pl-tab [id]="'ativosconsistenciaErros'">
      <div
        *plTabTitle
        [ngClass]="{'text-error': listaResumoErros?.length > 0 && activeTab !== 'ativosconsistenciaErros', 'text-success': !listaResumoErros?.length && activeTab !== 'ativosconsistenciaErros'}"
        [title]="resumoErrosHint">
        <i class="fa fa-fw" [ngClass]="{'fa-times': listaResumoErros?.length > 0, 'fa-check': !listaResumoErros?.length}" aria-hidden="true"></i>&nbsp;
        <span [translate]="'ativosconsistencia.tab.resumoerros'"></span>
      </div>
      <div *plTabContent>
        <ng-container
          *ngTemplateOutlet="
            templateAtivosConsistDataGrid;
            context: {
              id: 'ativosconsistenciaErros',
              keyExpr: 'codErro',
              dataSource: listaResumoErros,
              summary: dataGridDefinition.summary,
              dataGridFields: dataGridFieldsResumoErros,
              selectedRowKeys: undefined
            }
          ">
        </ng-container>
      </div>
    </pl-tab>
  </pl-tabs>
</div>

<ng-template
  #templateAtivosConsistDataGrid
  let-id="id"
  let-keyExpr="keyExpr"
  let-dataSource="dataSource"
  let-summary="summary"
  let-dataGridFields="dataGridFields"
  let-selectedRowKeys="selectedRowKeys">
  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    [cgDxDataGridColumns]="dataGridFields"
    [cgDxDataGridInstanceName]="'grid' + id"
    [dataSource]="dataSource"
    [keyExpr]="keyExpr"
    [summary]="summary"
    [selectedRowKeys]="selectedRowKeys"
    (selectedRowKeysChange)="selectedRowKeysChanged($event)"
    (onInitialized)="onInitialized($event)"
    (onCellPrepared)="onCellPrepared($event)"
    (onCellClick)="onCellClick($event)"
    (onContextMenuPreparing)="onDataGridContextMenuPreparing($event)">
    <div *dxTemplate="let cellInfo of 'groupCellTemplateTipo'">
      <div [translate]="'ativosconsistencia.table.tipo' + cellInfo.value"></div>
    </div>

    <div *dxTemplate="let cell of 'templateResumoAtivosLancDifAjustes'">
      <div>
        <button type="button" class="btn btn-primary btn-sm" [translate]="'ativosconsistencia.btn.lancadifajustes'" [disabled]="!resumoLancaAjustes" (click)="lancaAjustes()"></button>
      </div>
    </div>

    <div *dxTemplate="let cell of 'templateAtivosConsistContabToolbar'">
      <div>
        <button
          type="button"
          class="btn btn-outline-primary btn-sm"
          [translate]="'ativosconsistencia.btn.ignorardocumentossel'"
          [disabled]="!selectedKeysContab.length"
          (click)="ignorarDocumento(false)"></button>
      </div>
    </div>

    <div *dxTemplate="let cell of 'templateAtivosConsistIDIgnoradosToolbar'">
      <div>
        <button
          type="button"
          class="btn btn-outline-primary btn-sm"
          [translate]="'ativosconsistencia.btn.retiradocignoradossel'"
          [disabled]="!selectedKeysIgnorados.length"
          (click)="apagarIgnorados(false)"></button>
      </div>
    </div>

    <div *dxTemplate="let item of 'detailTemplateDocContabilidade'">
      <documento-contabilidade [(doc)]="item.data._docContabilidade" [onActionEdited]="onDocumentoContabilidadeAction" [onActionDeleted]="onDocumentoContabilidadeAction" [maintenanceMode]="true">
      </documento-contabilidade>
    </div>

    <div *dxTemplate="let item of 'detailTemplateAjustes'">
      <dx-data-grid cgDxDataGrid [cgDxDataGridInstanceName]="'detailajustes'" [dataSource]="item.data.ajustesDetalhe">
        <dxo-paging [pageIndex]="0" [pageSize]="10"></dxo-paging>
      </dx-data-grid>
    </div>
  </dx-data-grid>
</ng-template>
