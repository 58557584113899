<pl-form [(formInstance)]="form" [(ngForm)]="ngForm" [properties]="{orientation: 'horizontal'}" [submit]="close" plPromise>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <div class="modal-title">
      <h4 [translate]="'docscontabilidade.changeCCustoTitle'"></h4>
      <span [translate]="'docscontabilidade.text.conta'" [translateParams]="{nConta: linha.poc.nConta, nome: linha.poc.nome}"></span>
    </div>
  </div>

  <div class="modal-body" [plPromise]="promise">
    <pl-group>
      <label [translate]="'mascarasanalitica.fields.mascara'"></label>
      <edit>
        <entity-autocomplete
          attrName="mascarasanalitica"
          entity="mascarasanalitica"
          [model]="model"
          (modelChange)="loadMascara($event)"
          (selectedKeyChange)="model.manalID = $event"
          output="description"
          [outputDescription]="outputMascarasAnalitica"
          [fieldsMap]="{descricao: 'descricaoMascara'}"
          [properties]="{validators: {required: {value: true}}}"
          (evtSelectPromiseChanged)="addPromise($event)">
        </entity-autocomplete>
      </edit>
    </pl-group>

    <ng-container *ngFor="let type of types">
      <pl-group *ngIf="model[type.condition]">
        <label [translate]="type.label"></label>
        <edit>
          <entity-autocomplete
            [attrName]="type.entity"
            [entity]="type.entity"
            [model]="model"
            (selectedKeyChange)="model[type.keyTarget] = $event"
            (evtSelectedDescriptionChanged)="model[type.description] = $event"
            [fieldsMap]="type.fieldsMap"
            [properties]="{allowInvalid: false, validators: {required: {value: true}}}"
            (evtSelectPromiseChanged)="addPromise($event)">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </ng-container>

    <pl-group>
      <label [translate]="'entity.detail.value'"></label>
      <edit>
        <pl-edit type="cgnumber" attrName="valor" [(model)]="model.valor" [properties]="{validators: {min: {value: 0}, max: {value: maxValue}}}"></pl-edit>
        <div class="text-error" *ngIf="ngForm?.submitted && !model.linhasCCusto.length" [translate]="'docscontabilidade.changeTitleInvalidLines'"></div>
      </edit>
      <actions>
        <pl-button klass="btn-primary action-register-value" [onClick]="fnRegistarValor" data-focus>
          <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.register'"></span>
        </pl-button>
      </actions>
    </pl-group>

    <div class="row" *ngIf="model.linhasCCusto.length">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-sm-8">
            <div class="table-responsive">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <td [translate]="'docscontabilidade.moedaestrangeira.conta'"></td>
                    <td [translate]="'entity.detail.value'"></td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let linha of model.linhasCCusto; index as i">
                    <td>{{ linha.conta }}</td>
                    <td>{{ linha.valor | cgcNumber }}</td>
                    <td class="text-right">
                      <pl-button klass="btn-xs btn-danger action-remove-line" (evtClicked)="removeLine(i)">
                        <i class="fa fa-fw fa-trash-o"></i>
                      </pl-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-sm-2"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <div class="total-imputar">
      <span [translate]="'docscontabilidade.movab.totalImputar'"></span>:
      <pl-inline-edit type="cgnumber" attrName="totalimputar" [(model)]="totalValue" [properties]="{validators: {min: {value: 0}}}"></pl-inline-edit>
    </div>

    <div class="total-imputado"><span [translate]="'docscontabilidade.movab.totalJaImputado'"></span>: {{ model.valorRegistado }}</div>

    <pl-button type="submit" klass="btn-primary action-save" [disabled]="disableApply" data-focus #elementSubmit>
      <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.apply'"></span>
    </pl-button>

    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</pl-form>
