import type {Subscription} from 'rxjs';
import type {Editor} from 'tinymce';
import {AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Inject, Input, NgZone, OnChanges, OnDestroy, OnInit, Optional, PLATFORM_ID, Renderer2, SimpleChanges} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {generateUniqueID, isArray, isBoolean, isEmpty, isFunction, isString, isUndefinedOrNull, noop} from '../../common/utilities/utilities';
import {getTinyMCE, isTextarea, mergePlugins, normalizeModelEvents, parseStringProperty} from '../utils/tinymce.utils';
import type {IPlTinyMCEConfig, TPlTinyMCEModelEvents, TPlTinyMCEOutputFormat} from '../tinymce.interface';
import {TINY_MCE_SCRIPT_SRC} from '../tinymce.di';
import {loadScript} from '../../common/scriptloader/scriptloader';
import {Logger} from '../../logger/logger';
import {PlTinyMCEConfigService} from '../config/tinymce.config.service';
import {PlTinyMCEEvents, TINY_MCE_VALID_EVENTS} from '../events/tinymce.events';

const EDITOR_COMPONENT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CGCTinyMCEComponent),
  multi: true
};

const EVENT_NAME_PREFIX_LENGTH = 3;

@Component({
  selector: 'pl-tiny-mce-editor',
  template: '<ng-template></ng-template>',
  styles: [':host { display: block; }'],
  providers: [EDITOR_COMPONENT_VALUE_ACCESSOR],
  exportAs: 'editor'
})
export class CGCTinyMCEComponent extends PlTinyMCEEvents implements IPlTinyMCEConfig, OnInit, OnChanges, OnDestroy, AfterViewInit, ControlValueAccessor {
  @Input() public cloudChannel: string | number;
  @Input() public apiKey: string;
  @Input() public init: Record<string, any>;
  @Input() public id: string;
  @Input() public initialValue: string;
  @Input() public outputFormat: TPlTinyMCEOutputFormat;
  @Input() public inline: boolean;
  @Input() public tagName: string;
  @Input() public plugins: string;
  @Input() public toolbar: string | Array<string>;
  @Input() public modelEvents: TPlTinyMCEModelEvents;
  @Input() public allowedEvents: keyof PlTinyMCEEvents | Array<keyof PlTinyMCEEvents>;
  @Input() public ignoreEvents: keyof PlTinyMCEEvents | Array<keyof PlTinyMCEEvents>;
  @Input() public scriptSrc: string;
  @Input() public disabled: boolean;

  public editor: Editor;

  private readonly _document: Document;
  private readonly _element: HTMLElement;
  private readonly _subscriptionConfig: Subscription;
  private _config: IPlTinyMCEConfig;
  private _onTouchedCallback: (...args: Array<any>) => void = noop;
  private _onChangeCallback: (...args: Array<any>) => void;
  private _elementEditor: HTMLElement;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _ngZone: NgZone,
    private readonly _renderer: Renderer2,
    @Inject(DOCUMENT) document: any,
    @Inject(PLATFORM_ID) private readonly _platformId: object,
    @Inject(TINY_MCE_SCRIPT_SRC) @Optional() private readonly _tinymceScriptSrc: string,
    private readonly _logger: Logger,
    private readonly _plTinyMCEConfigService: PlTinyMCEConfigService
  ) {
    super();
    this._document = document;
    this._element = this._elementRef.nativeElement;
    this._onTouchedCallback = noop;
    this._subscriptionConfig = this._plTinyMCEConfigService.config().subscribe((config: IPlTinyMCEConfig) => {
      this._config = config;
    });
  }

  public ngOnInit(): void {
    this._handleChanges();
  }

  public ngOnChanges({
    cloudChannel,
    apiKey,
    init,
    id,
    initialValue,
    outputFormat,
    inline,
    tagName,
    plugins,
    toolbar,
    modelEvents,
    allowedEvents,
    ignoreEvents,
    scriptSrc,
    disabled
  }: SimpleChanges): void {
    if (cloudChannel && !cloudChannel.isFirstChange()) {
      this._changedCloudChannel(cloudChannel.currentValue);
    }
    if (apiKey && !apiKey.isFirstChange()) {
      this._changedApiKey(apiKey.currentValue);
    }
    if (init && !init.isFirstChange()) {
      this._changedInit(init.currentValue);
    }
    if (id && !id.isFirstChange()) {
      this._changedId(id.currentValue);
    }
    if (initialValue && !initialValue.isFirstChange()) {
      this._changedInitialValue(initialValue.currentValue);
    }
    if (outputFormat && !outputFormat.isFirstChange()) {
      this._changedOutputFormat(outputFormat.currentValue);
    }
    if (inline && !inline.isFirstChange()) {
      this._changedInline(inline.currentValue);
    }
    if (tagName && !tagName.isFirstChange()) {
      this._changedTagName(tagName.currentValue);
    }
    if (plugins && !plugins.isFirstChange()) {
      this._changedPlugins(plugins.currentValue);
    }
    if (toolbar && !toolbar.isFirstChange()) {
      this._changedToolbar(toolbar.currentValue);
    }
    if (modelEvents && !modelEvents.isFirstChange()) {
      this._changedModelEvents(modelEvents.currentValue);
    }
    if (allowedEvents && !allowedEvents.isFirstChange()) {
      this._changedAllowedEvents(allowedEvents.currentValue);
    }
    if (ignoreEvents && !ignoreEvents.isFirstChange()) {
      this._changedIgnoreEvents(ignoreEvents.currentValue);
    }
    if (scriptSrc && !scriptSrc.isFirstChange()) {
      this._changedScriptSrc(scriptSrc.currentValue);
    }
    if (disabled && !disabled.isFirstChange()) {
      this._changedDisabled(disabled.currentValue);
    }
  }

  public ngAfterViewInit(): void {
    if (isPlatformBrowser(this._platformId)) {
      this.id = this.id || generateUniqueID('cgc-tinymce-angular');
      this.inline = this.inline !== undefined ? this.inline !== false : Boolean(this.init?.inline);
      this.createElement();
      if (getTinyMCE()) {
        this.initialise();
      } else if (this._elementEditor?.ownerDocument) {
        loadScript({
          src: this._getScriptSrc(),
          document: this._elementEditor.ownerDocument
        }).then(() => {
          this.initialise();
        });
      }
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionConfig.unsubscribe();
    const tinyMCE: any = getTinyMCE();
    if (tinyMCE) {
      tinyMCE.remove(this.editor);
    }
  }

  public writeValue(value: string): void {
    if (this.editor?.initialized) {
      this.editor.setContent(isUndefinedOrNull(value) ? '' : value);
    } else {
      this.initialValue = value ?? undefined;
    }
  }

  public registerOnChange(fn: any): void {
    this._onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouchedCallback = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (this.editor) {
      this.editor.mode.set(isDisabled ? 'readonly' : 'design');
    } else if (isDisabled) {
      this.init = {...this.init, readonly: true};
    }
  }

  public createElement(): void {
    const tagName: string = isString(this.tagName) ? this.tagName : 'div';
    this._elementEditor = this._renderer.createElement(this.inline ? tagName : 'textarea');
    if (this._elementEditor) {
      if (this._document?.getElementById(this.id)) {
        this._logger.warn(`TinyMCE-Angular: an element with id [${this.id}] already exists. Editors with duplicate Id will not be able to mount`);
      }
      this._elementEditor.id = this.id;
      if (isTextarea(this._elementEditor)) {
        this._elementEditor.style.visibility = 'hidden';
      }
      this._renderer.appendChild(this._element, this._elementEditor);
    }
  }

  public initialise(): void {
    const finalInit: any = {
      ...this.init,
      target: this._elementEditor,
      inline: this.inline,
      readonly: this.disabled === true,
      plugins: mergePlugins(this.init?.plugins, this.plugins),
      toolbar: this.toolbar || this.init?.toolbar,
      setup: (editor: any) => {
        this.editor = editor;

        editor.on('init', () => {
          this._initEditor(editor);
        });

        this._bindHandlers(this, editor);

        if (this.init && isFunction(this.init.setup)) {
          this.init.setup(editor);
        }
      }
    };

    if (isTextarea(this._elementEditor)) {
      this._elementEditor.style.visibility = '';
    }

    this._ngZone.runOutsideAngular(() => {
      getTinyMCE().init(finalInit);
    });
  }

  private _handleChanges(): void {
    this._changedCloudChannel();
    this._changedApiKey();
    this._changedInit();
    this._changedId();
    this._changedInitialValue();
    this._changedOutputFormat();
    this._changedInline();
    this._changedTagName();
    this._changedPlugins();
    this._changedToolbar();
    this._changedModelEvents();
    this._changedAllowedEvents();
    this._changedIgnoreEvents();
    this._changedScriptSrc();
    this._changedDisabled();
  }

  private _changedCloudChannel(value: string | number = this.cloudChannel): void {
    let val: string | number = value;
    if (isEmpty(val)) {
      val = this._config.cloudChannel;
    }
    if (isEmpty(val)) {
      val = '7';
    }
    this.cloudChannel = val;
  }

  private _changedApiKey(value: string = this.apiKey): void {
    let val: string = value;
    if (isEmpty(val)) {
      val = this._config.apiKey;
    }
    if (isEmpty(val)) {
      val = 'no-api-key';
    }
    this.apiKey = val;
  }

  private _changedInit(value: Record<string, any> = this.init): void {
    this.init = {...this._config.init, ...value};
  }

  private _changedId(value: string = this.id): void {
    let val: string = value;
    if (isEmpty(val)) {
      val = this._config.id;
    }
    this.id = val;
  }

  private _changedInitialValue(value: string = this.initialValue): void {
    let val: string = value;
    if (isEmpty(val)) {
      val = this._config.initialValue;
    }
    this.initialValue = val;
  }

  private _changedOutputFormat(value: TPlTinyMCEOutputFormat = this.outputFormat): void {
    let val: TPlTinyMCEOutputFormat = value;
    if (isEmpty(val)) {
      val = this._config.outputFormat;
    }
    if (isEmpty(val)) {
      val = 'html';
    }
    this.outputFormat = val;
  }

  private _changedInline(value: boolean = this.inline): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this._config.inline;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.inline = val;
  }

  private _changedTagName(value: string = this.tagName): void {
    let val: string = value;
    if (isEmpty(val)) {
      val = this._config.tagName;
    }
    if (isEmpty(val)) {
      val = 'div';
    }
    this.tagName = val;
  }

  private _changedPlugins(value: string = this.plugins): void {
    let val: string = value;
    if (isEmpty(val)) {
      val = this._config.plugins;
    }
    this.plugins = val;
  }

  private _changedToolbar(value: string | Array<string> = this.toolbar): void {
    let val: string | Array<string> = value;
    if (!isArray(value) && isEmpty(val)) {
      val = this._config.toolbar;
    }
    this.toolbar = val;
  }

  private _changedModelEvents(value: TPlTinyMCEModelEvents = this.modelEvents): void {
    let val: TPlTinyMCEModelEvents = value;
    if (!isArray(val) && isEmpty(val)) {
      val = this._config.modelEvents;
    }
    if (!isArray(val) && isEmpty(val)) {
      val = 'change input undo redo';
    }
    this.modelEvents = val;
  }

  private _changedAllowedEvents(value: keyof PlTinyMCEEvents | Array<keyof PlTinyMCEEvents> = this.allowedEvents): void {
    let val: keyof PlTinyMCEEvents | Array<keyof PlTinyMCEEvents> = value;
    if (!isArray(value) && isEmpty(val)) {
      val = this._config.allowedEvents;
    }
    this.allowedEvents = val;
  }

  private _changedIgnoreEvents(value: keyof PlTinyMCEEvents | Array<keyof PlTinyMCEEvents> = this.ignoreEvents): void {
    let val: keyof PlTinyMCEEvents | Array<keyof PlTinyMCEEvents> = value;
    if (!isArray(value) && isEmpty(val)) {
      val = this._config.ignoreEvents;
    }
    this.ignoreEvents = val;
  }

  private _changedScriptSrc(value: string = this.scriptSrc): void {
    let val: string = value;
    if (isEmpty(val)) {
      val = this._config.scriptSrc;
    }
    this.scriptSrc = val;
  }

  private _changedDisabled(value: boolean = this.disabled): void {
    this.disabled = value === true;
    if (this.editor?.initialized) {
      this.editor.mode.set(this.disabled ? 'readonly' : 'design');
    }
  }

  private _getScriptSrc(): string {
    return !isEmpty(this.scriptSrc)
      ? this.scriptSrc
      : !isEmpty(this._tinymceScriptSrc)
        ? this._tinymceScriptSrc
        : `https://cdn.tiny.cloud/1/${this.apiKey}/tinymce/${this.cloudChannel}/tinymce.min.js`;
  }

  private _initEditor(editor: any): void {
    editor.on('blur', () => {
      this._ngZone.run(() => {
        if (isFunction(this._onTouchedCallback)) {
          this._onTouchedCallback();
        }
      });
    });

    const modelEvents: string = normalizeModelEvents(this.modelEvents);
    editor.on(modelEvents, () => {
      this._ngZone.run(() => {
        this._emitOnChange(editor);
      });
    });

    if (isString(this.initialValue)) {
      this._ngZone.run(() => {
        editor.setContent(this.initialValue);
        if (editor.getContent() !== this.initialValue) {
          this._emitOnChange(editor);
        }
        if (this.evtInitNgModel instanceof EventEmitter) {
          this.evtInitNgModel.emit(editor);
        }
      });
    }
  }

  private _emitOnChange(editor: any): void {
    if (isFunction(this._onChangeCallback)) {
      this._onChangeCallback(editor.getContent({format: this.outputFormat}));
    }
  }

  private _bindHandlers(context: CGCTinyMCEComponent, editor: any): void {
    const allowedEvents: Array<keyof PlTinyMCEEvents> = this._getValidEvents(context);
    for (const eventName of allowedEvents) {
      const eventEmitter: EventEmitter<any> = context[eventName];

      // We have to remove the prefix "evt" from the event name
      const normalizedEventName = eventName.substring(EVENT_NAME_PREFIX_LENGTH);

      editor.on(normalizedEventName, (event: unknown) => {
        context._ngZone.run(() => {
          eventEmitter.emit({event, editor});
        });
      });
    }
  }

  private _getValidEvents(context: CGCTinyMCEComponent): Array<keyof PlTinyMCEEvents> {
    const ignoredEvents: Array<keyof PlTinyMCEEvents> = parseStringProperty(context.ignoreEvents, []);
    return parseStringProperty(context.allowedEvents, TINY_MCE_VALID_EVENTS).filter((event: keyof PlTinyMCEEvents) => TINY_MCE_VALID_EVENTS.includes(event) && !ignoredEvents.includes(event));
  }
}
