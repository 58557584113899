<entity-list
  [entityName]="entityName"
  [maintenanceMode]="maintenanceMode"
  [dataGridState]="dataGridState"
  [searchValue]="searchValue"
  [filter]="filterValue"
  [initialFilter]="initialFilterValue"
  [filterCollapsed]="false"
  [toolbarInstanceId]="instanceName"
  [properties]="listOptions"
  [onFiltered]="fnOnFiltered"
  (evtOnRefresh)="onRefreshList()"
  (evtDataGridPreparing)="onDataGridPreparing($event)"
  (evtDataGridCellClick)="onDataGridCellClick($event)"
  (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
  (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
  (evtDataGridStateChanged)="onDataGridStateChanged($event)"
  (evtDataGridStateCleared)="onDataGridStateCleared()">
</entity-list>
