import {merge} from 'lodash-es';
import {from, Observable, Subscription, timer} from 'rxjs';
import {Component, ContentChild, ElementRef, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ValidatorFn} from '@angular/forms';
import {ActionQueue} from '../../../common/queues/action/action.queue';
import {cgcFilter} from '../../../pipes/filter';
import {debounce, interpolate, isArray, isBoolean, isDefinedNotNull, isEmpty, isFunction, isNumber, isObject, isString, timeout} from '../../../common/utilities/utilities';
import {DEFAULT_PER_PAGE, EMouseEventButton, KEYCODES} from '../../../common/constants';
import type {
  IPlEditAutocompleteEvtSelected,
  IPlEditAutocompleteSetViewValueParams,
  IPlEditComponentOptionsInputAutocomplete,
  TPlEditAutocompleteLoadFn,
  TPlEditAutocompleteLoadResult,
  TPlEditAutocompleteRowTemplateFn,
  TPlEditAutocompleteSource,
  TPlEditAutocompleteValidateFn
} from './edit.autocomplete.component.interface';
import type {IPlFilterPanelEvtFiltered} from '../../../filterpanel/component/filter.panel.component.interface';
import type {IPlFilterPanelField} from '../../../filterpanel/filter.panel.interface';
import type {IPlLocale} from '../../../common/locale/locales.interface';
import {PlAutocompleteCache} from '../../../common/cache/autocomplete/autocomplete.cache';
import {PlEditAutocompleteDropdownContentDirective} from './edit.autocomplete.dropdown.content.directive';
import {PlEditInputDropdownComponent} from '../../generic/input/edit.input.dropdown.component';
import {PlLocaleService} from '../../../common/locale/locale.service';
import {TValueOrPromise} from '../../../common/utilities/utilities.interface';

type TItem = any;

const DEFAULT_DEBOUNCE_DURATION_LOAD_MORE = 100;
const DEFAULT_DEBOUNCE_DURATION_INPUT_VALUE_CHANGED = 200;
const DEFAULT_DELAY_DURATION = 1000;
const REGEX_VALID_CHARACTERS = new RegExp('[a-z]|[0-9]', 'i');

let DEBOUNCE_ID = 0;

@Component({
  selector: 'pl-autocomplete',
  templateUrl: './edit.autocomplete.component.html'
})
export class PlEditAutocompleteComponent extends PlEditInputDropdownComponent<any, IPlEditComponentOptionsInputAutocomplete> implements OnInit, OnChanges, OnDestroy {
  @Input() public allowInvalid: boolean;
  @Input() public allowEmpty: boolean;
  @Input() public filterFields: Array<IPlFilterPanelField>;
  @Input() public filterModel: unknown;
  @Input() public output: string;
  @Input() public rowTemplate: string | TPlEditAutocompleteRowTemplateFn;
  @Input() public showFilter: boolean;
  @Input() public source: TPlEditAutocompleteSource;
  @Input() public cacheValues: boolean;
  @Input() public cacheValuesInstanceId: string;
  @Input() public cacheValuesAutoReload: boolean;
  @Input() public loadingDelayDuration: number;
  @Input() public validateFn: TPlEditAutocompleteValidateFn;
  @Output() public readonly evtSelected: EventEmitter<IPlEditAutocompleteEvtSelected>;

  @ContentChild(PlEditAutocompleteDropdownContentDirective) public readonly templateDropdownContent: PlEditAutocompleteDropdownContentDirective;
  public readonly rows: Map<TItem, string>;
  public textEmpty: string;
  public textSearching: string;
  public search: string;
  public currentIndex: number;
  public isFilterCollapsed: boolean;
  public items: Array<TItem>;
  public searching: boolean;
  public validating: boolean;
  public promise: Promise<void>;

  private readonly _debounceId: number;
  private readonly _subscriptionLocale: Subscription;
  private _locale: IPlLocale;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
  private _actionQueue: ActionQueue<TPlEditAutocompleteLoadResult<TItem>>;
  private _filter: string;
  private _dropdown: HTMLInputElement;
  private _isLocalSource: boolean;
  private _page: number;
  private _responseResultSize: number;
  private _previous: {model: TItem; search: string};
  private _preventBlurRender: boolean;
  private _subscriptionSearching: Subscription;
  private _timeoutInputValueChanged: number;
  private _timeoutLoadMore: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plLocaleService: PlLocaleService,
    private readonly _autocompleteCache: PlAutocompleteCache
  ) {
    super(_injector);
    this.evtSelected = new EventEmitter<IPlEditAutocompleteEvtSelected>();
    this.rows = new Map<TItem, string>();
    this.items = [];
    this.searching = false;
    this.validating = false;
    this._defaultOptions = Object.freeze<IPlEditComponentOptionsInputAutocomplete>(
      merge({}, this._defaultOptions, {
        appendToBody: true,
        allowInvalid: true,
        allowEmpty: true
      })
    );
    this._debounceId = ++DEBOUNCE_ID;
    this._page = 0;
    this._preventBlurRender = false;
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      const changedLocale = Boolean(this._locale);
      this._locale = locale;
      this.textEmpty = this._locale.autocomplete.textEmpty;
      this.textSearching = this._locale.autocomplete.textSearching;
      if (changedLocale) {
        this._setPlaceholder();
      }
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._handleChanges();
    this.setValidators(this._requiredValidator());
    if (isBoolean(this.allowInvalid)) {
      this.options.allowInvalid = this.allowInvalid;
    }
    if (isBoolean(this.allowEmpty)) {
      this.options.allowEmpty = this.allowEmpty;
    }
    this._handlePropertiesChanged();
    if (this.value) {
      this.setViewValue();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const {allowInvalid, allowEmpty, filterFields, filterModel, output, placeholder, showFilter, source, cacheValues, cacheValuesInstanceId, cacheValuesAutoReload, loadingDelayDuration} = changes;
    if (
      (allowInvalid && !allowInvalid.isFirstChange()) ||
      (allowEmpty && !allowEmpty.isFirstChange()) ||
      (filterFields && !filterFields.isFirstChange()) ||
      (filterModel && !filterModel.isFirstChange()) ||
      (output && !output.isFirstChange()) ||
      (placeholder && !placeholder.isFirstChange()) ||
      (showFilter && !showFilter.isFirstChange()) ||
      (source && !source.isFirstChange()) ||
      (cacheValues && !cacheValues.isFirstChange()) ||
      (cacheValuesInstanceId && !cacheValuesInstanceId.isFirstChange()) ||
      (cacheValuesAutoReload && !cacheValuesAutoReload.isFirstChange()) ||
      (loadingDelayDuration && !loadingDelayDuration.isFirstChange())
    ) {
      this._handleChanges();
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.rows.clear();
    this._subscriptionLocale.unsubscribe();
    this._clearPendingSubscriptions();
  }

  public updateComponent(properties: IPlEditComponentOptionsInputAutocomplete): void {
    super.updateComponent(properties);
    this._handlePropertiesChanged();
  }

  public inputValueChanged(value: string): void {
    this.evtInputValueChanged.emit(value);
    if (isEmpty(value)) {
      if (this.options.filterModel) {
        this.options.filterModel = undefined;
        this._filter = undefined;
      }
      value = undefined;
    }
    if (!this.dropdownOpen) {
      this.showDropdown(false);
    }
    this._reset();
    this.search = value;
    if (!this._isLocalSource) {
      this._timeoutInputValueChanged = debounce(
        () => {
          this._timeoutInputValueChanged = undefined;
          this._queryRemoteSource(true);
        },
        DEFAULT_DEBOUNCE_DURATION_INPUT_VALUE_CHANGED,
        `PL_EDIT_AUTOCOMPLETE_INPUT_VALUE_CHANGED_${this._debounceId}`
      );
    } else {
      this._queryLocalSource();
    }
  }

  public selectItem(item: TItem): Promise<void> {
    return this.select(item).then(() => {
      return timeout().then(() => {
        this.inputSelectAll();
      });
    });
  }

  public async select(item: TItem, preventClose: boolean = false): Promise<void> {
    this._clearPendingSubscriptions();
    this._preventBlurRender = true;
    const validationResult: TItem | undefined = await this._validateSelection(this.search, item);
    if (!isEmpty(validationResult)) {
      item = validationResult;
    }
    this.value = item;
    this.setViewValue();
    await this.render();
    this.evtSelected.emit({inputValue: this.search, item: item});
    let selectedPromise: TValueOrPromise<void>;
    if (isFunction(this.options.onSelect)) {
      selectedPromise = this.options.onSelect(this.search, item);
    }
    if (!preventClose) {
      await Promise.resolve(selectedPromise).finally(() => {
        this.closeDropdown();
      });
    }
  }

  public initDropdown(hostElement: HTMLElement, targetElement: HTMLElement): void {
    if (!this.options.appendToBody) {
      return;
    }
    targetElement.style.width = `${hostElement.offsetWidth}px`;
    super.initDropdown(hostElement, targetElement);
  }

  public showDropdown(resetAndQuery: boolean = true): void {
    if (this.dropdownOpen) {
      return;
    }
    this.isFilterCollapsed = !this.options.filterModel;
    this.search = undefined;
    this.searching = !this._isLocalSource;
    this.dropdownOpen = true;
    if (resetAndQuery) {
      this._resetAndQuery(true);
    }
  }

  public closeDropdown(): void {
    if (!this.dropdownOpen) {
      return;
    }
    this._clearPendingSubscriptions();
    this._reset();
    this.rows.clear();
    this.items = [];
    this.searching = false;
    this.options.filterModel = undefined;
    this._filter = undefined;
    if (!this.value && !this.options.allowInvalid) {
      this.value = this.search;
    }
    this.dropdownOpen = false;
  }

  public updateValue(value: any): void {
    this.value = value;
    this._handleModelChanged();
  }

  public clearViewValue(): Promise<void> {
    this.search = undefined;
    return super.clearViewValue().then(() => {
      return this.select(undefined);
    });
  }

  public onItemMousedown(item: TItem, event: MouseEvent): void {
    if (!isNumber(event.button) || event.button === EMouseEventButton.Main) {
      this.selectItem(item).catch((reason: unknown) => {
        this._logger.error(reason);
      });
    }
  }

  public onInputKeyDown(value: string, event: KeyboardEvent): void {
    const which: string = event.key;
    if (this.dropdownOpen || which === KEYCODES.SPACEBAR) {
      switch (which) {
        case KEYCODES.ENTER:
          event.preventDefault();
          this._preventBlurRender = true;
          if (this.currentIndex >= 0) {
            if (this.items.length > 0 && this.currentIndex < this.items.length) {
              this.select(this.items[this.currentIndex]);
            }
          } else {
            this.select(this.formControl.value);
          }
          this.closeDropdown();
          break;
        case KEYCODES.ESC:
          this.value = this._previous?.model ?? undefined;
          this.setViewValue(this._previous?.search ?? '');
          this.render();
          this.closeDropdown();
          break;
        case KEYCODES.DOWN:
          event.preventDefault();
          event.stopPropagation();
          const nextIndex = this.currentIndex + 1;
          this.currentIndex = nextIndex < this.items.length ? nextIndex : this.items.length - 1;
          this._checkVisibility();
          break;
        case KEYCODES.UP:
          event.preventDefault();
          event.stopPropagation();
          const previous = this.currentIndex - 1;
          this.currentIndex = previous < 0 ? 0 : previous;
          this._checkVisibility();
          break;
        case KEYCODES.LEFT:
        case KEYCODES.RIGHT:
          this.showDropdown(false);
          this.search = this.formControl.value;
          this._resetAndQuery(true);
          break;
        case KEYCODES.HOME:
          event.preventDefault();
          event.stopPropagation();
          this.currentIndex = 0;
          this._checkVisibility();
          break;
        case KEYCODES.END:
          event.preventDefault();
          event.stopPropagation();
          this.currentIndex = this.items.length ? this.items.length - 1 : 0;
          this._checkVisibility();
          break;
        case KEYCODES.SPACEBAR:
          if (event.ctrlKey && !this.dropdownOpen) {
            event.preventDefault();
            event.stopPropagation();
            this.showDropdown();
          }
          break;
      }
    }
    if (isFunction(this.options.events.keydown)) {
      this.options.events.keydown(value, event);
    }
  }

  public onInputFocus(event: FocusEvent): void {
    this._preventBlurRender = false;
    super.onInputFocus(event);
  }

  public onInputBlur(event: FocusEvent): void {
    super.onInputBlur(event);
    if (!this._preventBlurRender) {
      if (isEmpty(this.formControl.value)) {
        // Implicit conversion `!=` is intended
        // eslint-disable-next-line eqeqeq
        if (this._previous || this.formControl.value != this.value) {
          this.value = undefined;
          this.select(this.value, true);
        }
        // Implicit conversion `!=` is intended
        // eslint-disable-next-line eqeqeq
      } else if (!this._previous || this.formControl.value != this._previous.search) {
        this.select(this.formControl.value, true);
      }
    } else {
      this._preventBlurRender = false;
    }
    if (this.dropdownOpen && !this.isMouseIn) {
      this.closeDropdown();
    }
    if (isFunction(this.options.events.blur)) {
      this.options.events.blur(this.value, event);
    }
  }

  public getOutput(item: string | TItem): string {
    if (!isObject(item)) {
      return <string>item;
    }
    const output = this.output || this.options.output;
    if (!isString(output)) {
      return item;
    }
    let result: string = output.includes('{{') ? interpolate(output)(item) : item[output];
    /* This is hacky and should not be repeated. When a complex output is used
     * this avoids user unfriendly outputs, such as ' - '.
     */
    if (isString(result) && !REGEX_VALID_CHARACTERS.test(result)) {
      result = '';
    }
    return result;
  }

  public loadMore(): void {
    if (this._responseResultSize === 0 || this._isLocalSource || !this.items.length) {
      return;
    }
    this._timeoutLoadMore = debounce(
      () => {
        this._timeoutLoadMore = undefined;
        this._page++;
        this._query(false);
      },
      DEFAULT_DEBOUNCE_DURATION_LOAD_MORE,
      `PL_EDIT_AUTOCOMPLETE_LOAD_MORE_${this._debounceId}`
    );
  }

  public doFilter({serializedFilters}: IPlFilterPanelEvtFiltered<unknown | string>): void {
    if (isDefinedNotNull(serializedFilters) && !isString(serializedFilters)) {
      throw new TypeError('Event parameter "serializedFilters" should be a string.');
    }
    this._filter = <string>serializedFilters;
    this._page = 0;
    this._query(true);
  }

  public trackByFn(index: number): number {
    return index;
  }

  public setViewValue(item: string | TItem = this.value, params?: IPlEditAutocompleteSetViewValueParams): void {
    const viewValue: string = item || item === 0 ? this.getOutput(item) : '';
    this._previous = {
      model: item,
      search: viewValue
    };
    this.formControl.setValue(viewValue, {emitEvent: params?.triggerValueChange === true});
    this._checkShowClear(viewValue);
  }

  @ViewChild('dropdown')
  public set dropdownInit(value: ElementRef<HTMLInputElement>) {
    this._dropdown = value?.nativeElement;
  }

  protected _setPlaceholder(): void {
    super._setPlaceholder();
    if (!this.options.raw) {
      const placeholder: string = this.placeholder ? this.placeholder : this.options.placeholder ? this.options.placeholder : this._locale.btn.search.toLowerCase();
      if (placeholder !== this.placeholder) {
        this.placeholder = `\uf002 ${placeholder}`;
      }
    }
  }

  private _handleChanges(): void {
    if (isBoolean(this.allowInvalid)) {
      this.options.allowInvalid = this.allowInvalid;
    }
    if (isBoolean(this.allowEmpty)) {
      this.options.allowEmpty = this.allowEmpty;
    }
    if (this.filterFields) {
      this.options.filterFields = this.filterFields;
    }
    if (this.filterModel) {
      this.options.filterModel = this.filterModel;
    }
    if (this.output) {
      this.options.output = this.output;
    }
    if (this.placeholder) {
      this.options.placeholder = this.placeholder;
    }
    if (isDefinedNotNull(this.showFilter)) {
      this.options.showFilter = this.showFilter;
    }
    if (this.source) {
      this.options.source = this.source;
    }
    if (isBoolean(this.cacheValues)) {
      this.options.cacheValues = this.cacheValues;
    }
    if (!isEmpty(this.cacheValuesInstanceId)) {
      this.options.cacheValuesInstanceId = this.cacheValuesInstanceId;
    }
    if (isBoolean(this.cacheValuesAutoReload)) {
      this.options.cacheValuesAutoReload = this.cacheValuesAutoReload;
    }
    if (isNumber(this.loadingDelayDuration)) {
      this.options.loadingDelayDuration = this.loadingDelayDuration;
    }
  }

  private _handleModelChanged(): void {
    this.setViewValue();
    this.runValidators();
  }

  private _handlePropertiesChanged(): void {
    if (isArray(this.options.source)) {
      this.items = this.options.source;
      this._isLocalSource = true;
      for (const item of this.items) {
        for (const propName of Object.keys(item)) {
          const propValue = item[propName];
          if (isString(propValue)) {
            item[propName] = this._plTranslateService.translate(item[propName]);
          }
        }
      }
    }
  }

  private _query(ignoreCurrentItems: boolean): void {
    if (isFunction(this.options.source)) {
      this._queryRemoteSource(ignoreCurrentItems);
    } else if (isArray(this.options.source)) {
      this._queryLocalSource();
    }
  }

  private _queryRemoteSource(ignoreCurrentItems: boolean): void {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
    const remoteSource: TPlEditAutocompleteLoadFn<TItem> = <TPlEditAutocompleteLoadFn<TItem>>this.options.source;

    if (this._page === 0) {
      this._page = 1;
    }

    const search: string = this.search;
    const page: number = this._page;
    const perPage: number = DEFAULT_PER_PAGE;
    const filter: string = this._filter;

    let loadedFromCache = false;
    let cachingStart = -1;
    let cachingEnd = -1;

    if (this.options.cacheValues) {
      if (!this.options.cacheValuesInstanceId) {
        this._logger.warn('Caching was enabled but `cacheValuesInstanceId` was not provided. Cache ignored.');
      } else {
        const cachedValues: Array<TItem> = this._autocompleteCache.getQuery<TItem>(this.options.cacheValuesInstanceId, {search, page, perPage, filter});
        if (cachedValues) {
          loadedFromCache = true;
          this._generateRows(cachedValues, ignoreCurrentItems);
          this._responseResultSize = cachedValues.length;
          if (ignoreCurrentItems) {
            this.items = cachedValues.slice();
          } else {
            cachingStart = this.items.length;
            cachingEnd = cachingStart + cachedValues.length;
            this.items.splice(cachingStart, cachingEnd, ...cachedValues);
          }
        }
      }
    }

    if (loadedFromCache && (cachingStart === cachingEnd || this.options.cacheValuesAutoReload === false)) {
      return;
    }

    if (!this.searching) {
      this.searching = true;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
    const result$: Observable<TPlEditAutocompleteLoadResult<TItem>> = from(Promise.resolve(remoteSource(search, page, perPage, filter)));

    if (!this._actionQueue) {
      this._actionQueue = new ActionQueue(this._logger);
    }

    const finalize = (): void => {
      this.searching = this._actionQueue.pendingExecutions() > 0;
      if (!this.searching) {
        this._clearSubscriptionSearching();
      }
    };

    /**
     * We use a FIFO queue because we have to handle the responses in the same order as the requests
     */
    this._actionQueue.queueResult(result$, {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
      next: (response: TPlEditAutocompleteLoadResult<TItem>) => {
        const items: Array<TItem> = isArray(response) ? response : response.list;
        if (this.dropdownOpen) {
          this._responseResultSize = items.length;
          this._generateRows(items, ignoreCurrentItems);
          if (!loadedFromCache) {
            this._setRows(items, ignoreCurrentItems);
          } else {
            this.items.splice(cachingStart, cachingEnd, ...items);
          }
        }
        if (this.options.cacheValues && this.options.cacheValuesInstanceId) {
          this._autocompleteCache.setQuery(this.options.cacheValuesInstanceId, {search, page, perPage, filter}, items.slice());
        }
        finalize();
      },
      error: (reason: unknown) => {
        this._logger.error(reason);
        finalize();
      }
    });

    if (!this._subscriptionSearching && this._window) {
      const delayDuration: number = isNumber(this.options.loadingDelayDuration) && this.options.loadingDelayDuration >= 0 ? this.options.loadingDelayDuration : DEFAULT_DELAY_DURATION;
      this._subscriptionSearching = timer(delayDuration).subscribe(() => {
        if (this._actionQueue.executing()) {
          this.promise = this._actionQueue.executionPromise();
        }
      });
    }
  }

  private _queryLocalSource(): void {
    const localSource: Array<TItem> = <Array<TItem>>this.options.source;
    this.items = this.search ? cgcFilter(localSource, this.search) : localSource;
    this._generateRows(this.items, true);
  }

  private _resetAndQuery(ignoreCurrentItems: boolean): void {
    this._reset();
    this._query(ignoreCurrentItems);
  }

  private _reset(): void {
    this.currentIndex = -1;
    this._page = 0;
  }

  private _setRows(items: Array<TItem>, ignoreCurrentItems: boolean): void {
    this.items = ignoreCurrentItems ? items : this.items.concat(items);
  }

  private _generateRows(items: Array<TItem>, ignoreCurrentItems: boolean): void {
    if (ignoreCurrentItems) {
      this.rows.clear();
    }
    if (!isArray(items) || !items.length) {
      return;
    }
    for (const item of items) {
      this.rows.set(item, isObject(item) ? this._generateRow(item) : item);
    }
  }

  private _generateRow(item: TItem): string {
    if (!isObject(item)) {
      return item;
    }
    let rowTemplate = this.rowTemplate || this.options.rowTemplate;
    if (!isString(rowTemplate) && !isFunction(rowTemplate)) {
      return item;
    }
    if (isFunction(rowTemplate)) {
      rowTemplate = rowTemplate(item);
    }
    return rowTemplate.includes('{{') ? interpolate(rowTemplate)(item) : item[rowTemplate];
  }

  private _requiredValidator(): ValidatorFn {
    return () => {
      if (this.validate && (this.options.validators.required?.value || (!this.options.allowInvalid && !this.options.allowEmpty))) {
        return isEmpty(this.value) ? {required: true} : undefined;
      }
      return undefined;
    };
  }

  private _checkVisibility(): void {
    Promise.resolve(this.promise).finally(() => {
      setTimeout(() => {
        if (this._dropdown) {
          const selected = this._dropdown.querySelector<HTMLElement>('.pl-autocomplete-selected-row');
          if (selected) {
            this._scrollToView(selected);
          }
        }
      });
    });
  }

  private _scrollToView(element: HTMLElement): void {
    const parent: HTMLElement = element.closest('.pl-autocomplete-dropdown');
    if (!parent) {
      return;
    }
    const elementHeight: number = element.clientHeight;
    let nextOffset = element.offsetTop + elementHeight - parent.offsetHeight;
    // scroll down
    if (nextOffset > parent.scrollTop) {
      parent.scrollTop = nextOffset + elementHeight;
      return;
    }
    // scroll up
    nextOffset = element.offsetTop;
    if (nextOffset < parent.scrollTop) {
      parent.scrollTop = element.offsetTop - elementHeight;
    }
  }

  private _validateSelection(search: string, item: TItem): Promise<TItem | undefined> {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
    let validateFn: TPlEditAutocompleteValidateFn<TItem> = this.validateFn;
    if (!isFunction(validateFn)) {
      validateFn = this.options.validateFn;
      if (!isFunction(validateFn)) {
        return Promise.resolve();
      }
    }
    this.validating = true;
    return new Promise<void>((resolve, reject) => {
      Promise.resolve(validateFn(search, item))
        .then((result: TItem | undefined) => {
          if (!isEmpty(result)) {
            resolve(result);
          } else {
            resolve(undefined);
          }
        })
        .catch((error: unknown) => {
          if (!this.options.allowInvalid) {
            this.value = undefined;
            this.render().finally(() => {
              this.preventValueChanges();
              this.runValidators();
              this.allowValueChanges();
              // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
              reject(error);
            });
          } else {
            resolve(undefined);
          }
        })
        .finally(() => {
          this.validating = false;
        });
    });
  }

  private _clearPendingSubscriptions(): void {
    if (isNumber(this._timeoutInputValueChanged) && this._window) {
      this._window.clearTimeout(this._timeoutInputValueChanged);
      this._timeoutInputValueChanged = undefined;
    }
    if (isNumber(this._timeoutLoadMore) && this._window) {
      this._window.clearTimeout(this._timeoutLoadMore);
      this._timeoutLoadMore = undefined;
    }
    this._clearSubscriptionSearching();
  }

  private _clearSubscriptionSearching(): void {
    if (this._subscriptionSearching) {
      this._subscriptionSearching.unsubscribe();
      this._subscriptionSearching = undefined;
    }
  }
}
