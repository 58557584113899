<div class="modal-header">
  <h4 class="modal-title" [translate]="'importadorSaftDirect.strings.correspCCAssociarModalTitle'"></h4>
</div>

<div class="modal-body">
  <pl-form [properties]="{validators: {required: {value: true}}}">
    <pl-group *ngIf="field === fieldType.NCCUSTO">
      <label [translate]="'importadorSaftDirect.fields.nCCusto'"></label>
      <edit>
        <entity-autocomplete entity="ccustos" [model]="model" [(selectedKey)]="model.nCCusto" [output]="'key'" [filter]="'encerrado=0'" [properties]="{allowInvalid: false}"></entity-autocomplete>
      </edit>
    </pl-group>
    <pl-group *ngIf="field === fieldType.RepCC">
      <label [translate]="'importadorSaftDirect.fields.nCodRepCC'"></label>
      <edit>
        <entity-autocomplete entity="reparticoesccustosfaturacao" [model]="model" [(selectedKey)]="model.nCodRepCC" [output]="'key'" [properties]="{allowInvalid: false}"></entity-autocomplete>
      </edit>
    </pl-group>
  </pl-form>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary action-close" (evtClicked)="close(model)" plAutoFocus>
    <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'importadorSaftDirect.strings.modalAttribuir'"></span>
  </pl-button>

  <pl-button klass="btn-light" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'importadorSaftDirect.strings.modalCancel'"></span> </pl-button>
</div>
