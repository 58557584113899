<div class="ativos-import-ajustesnascontas-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'ativosimport.modal.ajustescontastitle'"></h5>
  </div>

  <div class="modal-body">
    <dx-data-grid [cgDxDataGrid]="definition" [dataSource]="ajustesnascontas" (onCellClick)="onDataGridCellClick($event)">
      <div *dxTemplate="let item of 'detail'">
        <documento-contabilidade [(doc)]="item.data._docContabilidade"></documento-contabilidade>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'ativosimport.modal.btn.fechar'"></span> </pl-button>
  </div>
</div>
