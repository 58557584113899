<div class="ativosajustedepantigas-modal">
  <pl-form>
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'ativosajustedepantigas.title'"></h5>
    </div>

    <div class="modal-body">
      <pl-alert type="info" [closeable]="false">
        <div [innerHTML]="'ativosajustedepantigas.infoajustes' | translate"></div>
      </pl-alert>
      <section class="d-flex">
        <div>
          <label class="me-2" [translate]="'ativosajustedepantigas.fields.valoremfalta'"></label>
          <span>{{ ajusteDepAntigas.valorEmFalta }}</span>
        </div>
      </section>

      <pl-group>
        <label [translate]="'ativosajustedepantigas.fields.periodo'"></label>
        <edit>
          <entity-autocomplete
            entity="periodos"
            attrName="periodo"
            [model]="ajusteDepAntigas"
            (selectedKeyChange)="ajusteDepAntigas.periodo = $event"
            [fieldsMap]="{nome: 'nomeMesCivil'}"
            [filter]="'loadAllAnos=1'"
            plAutoFocus>
          </entity-autocomplete>
        </edit>
      </pl-group>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button
        klass="btn btn-success action-dismiss"
        [tooltip]="{disabled: ajusteDepAntigas.haveDepEmFalta, text: 'ativosajustedepantigas.message.haveDepEmFalta'}"
        [disabled]="!ajusteDepAntigas.haveDepEmFalta"
        [onClick]="close"
        data-focus>
        <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span>
      </pl-button>

      <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
    </div>
  </pl-form>
</div>
