<div class="avisos-cobranca-config-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'avisoscobranca.modal.emissora.title'"></h5>
  </div>

  <div class="modal-body">
    <div [plPromise]="promiseEmissora">
      <div *ngIf="!hasEasyPay">
        <h6 [translate]="'avisoscobranca.modal.emissora.naoExisteConfig'"></h6>
        <hr />
      </div>
      <div *ngIf="hasEasyPay">
        <h6 [translate]="'avisoscobranca.modal.emissora.configuradaEasyPay'"></h6>
        <hr />
        <pl-group>
          <label [translate]="'avisoscobranca.modal.emissora.emitirAvisosCobranca'"></label>
          <edit>
            <pl-edit type="radiogroup" attrName="headerEstadoUso" [(model)]="config.emissoraAtiva" [properties]="radioGroupEmissora"> </pl-edit>
          </edit>
        </pl-group>
        <hr />
      </div>
    </div>
    <pl-group>
      <label [translate]="'avisoscobranca.modal.emissora.defaultReport'"></label>
      <edit>
        <pl-edit type="reports_avisos_cobranca" attrName="listagem" [(model)]="modelReport" (modelChange)="changedReport($event)"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-success action-ok" [disabled]="closeDisabled" [onClick]="close"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span> </pl-button>
    <pl-button klass="btn-light action-dismiss" [disabled]="closeDisabled" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
