<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'mascarasanalitica.textPrompt.title'"></h4>
  </div>

  <div class="modal-body">
    <pl-form [properties]="{orientation: 'horizontal'}">
      <pl-group>
        <label [translate]="'mascarasanalitica.table.text'"></label>
        <edit>
          <pl-edit type="text" [(model)]="value" plAutoFocus></pl-edit>
        </edit>
      </pl-group>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success btModelOK" (evtClicked)="close(value)" data-focus> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span> </pl-button>

    <pl-button klass="btn-light btModalCancel" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</pl-form>
