import {Observable} from 'rxjs';
import {ERHRegistarEmpregadosSSEmpSegSocialVinculo} from '../rhregistarempregadosss/rhRegistarEmpregadosSS.module.interface';
import {IApiRequestConfig, IApiRequestConfigWithBody, TServiceResponse} from '../../../services/api/api.service.interface';
import {IDGEMPSEntityService} from '../../../entities/dgemps/dgemps.entity.interface';
import {IEntityDefinition} from '../../../components/entity/entity.definition.interface';
import {IJsonDGEMPComentario, IJsonDGEMPFull} from './jsonDGEMPFull.interface';
import {TDate} from '../../../../common/dates';
import {TEntityServiceRequestData, TEntityServiceRequestDataWithBody} from '../../../services/entity/entity.service.interface';
import {IJsonDGEMPVencimentoSimulacao} from '../../colaboradores/simulavalorvencimento/simulaValorVencimento.module.interface';

export enum EDGEMPSSaveCadastroMode {
  None,
  NewCadastro,
  UpdateLatestCadastro
}

export enum EDGEMPSTipoExportacao {
  PDF,
  Excel
}

export interface IDGEMPSFullEntityService extends IDGEMPSEntityService<IJsonDGEMPFull> {
  getFull(config: TEntityServiceRequestData): TServiceResponse<IJsonDGEMPFull>;

  postFull(config: IApiRequestConfigWithBody<IJsonDGEMPFull>): TServiceResponse<IJsonDGEMPFull>;

  postComentarioDGEMP(config: IApiRequestConfigWithBody<IJsonDGEMPComentario>): TServiceResponse<Array<IJsonDGEMPComentario>>;

  putFull(config: TEntityServiceRequestDataWithBody<IJsonDGEMPFull>): TServiceResponse<IJsonDGEMPFull>;

  putComentarioDGEMP(config: TEntityServiceRequestDataWithBody<IJsonDGEMPComentario>): TServiceResponse<Array<IJsonDGEMPComentario>>;

  deleteFull(config: TEntityServiceRequestData): TServiceResponse<void | IJsonDGEMPFull>;

  deleteComentarioDGEMP(config: TEntityServiceRequestData): TServiceResponse<void | IJsonDGEMPFull>;

  simulateValorVencimento(id: string | number, config?: IApiRequestConfig): TServiceResponse<IJsonDGEMPVencimentoSimulacao>;

  simulateTaxaMarginal(codEmp: string | number, taxa: number, dataSimulacao: TDate, config?: IApiRequestConfig): TServiceResponse<IDGEMPSFullTaxaMarginalSimulador>;

  getEmpregadoByNISS(niss: string): TServiceResponse<IJsonDGEMPFull>;

  getListagemMapasFichaParamsUrl(params: IDGEMPSFullGetListagemMapasFichaParams): Observable<string>;

  getListagemMapasFichaParams(params: IDGEMPSFullGetListagemMapasFichaParams): TServiceResponse<Blob>;
}

export interface IDGEMPSFullEntity extends IEntityDefinition<IJsonDGEMPFull, IDGEMPSFullEntityService> {}

export interface IDGEMPSFullEstadoSSModel {
  infoEstadoSegSocial: ERHRegistarEmpregadosSSEmpSegSocialVinculo;
  infoEmpregadoRegistado: string;
}

export interface IDGEMPSFullTaxaMarginalSimulador {
  infoCalculos: string;
  taxaMarginal: number;
}

export interface IDGEMPSFullGetListagemMapasFichaParams {
  deCod?: number;
  atecod?: number;
  deNome?: string;
  ateNome?: string;
  deIdade?: number;
  ateIdade?: number;
  deSituacao?: string;
  ateSituacao?: string;
  deCategoria?: string;
  ateCategoria?: string;
  deDepartamento?: number;
  ateDepartamento?: number;
  deDtNasc?: TDate;
  deDtValCt?: TDate;
  deDtValBI?: TDate;
  ateDtNasc?: TDate;
  ateDtValCt?: TDate;
  ateDtValBI?: TDate;
  reportName?: string;
  tipoExportacao?: EDGEMPSTipoExportacao;
}

export const ENTITY_NAME_DGEMPS_FULL = 'dgempsfull';
