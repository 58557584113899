<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'docscontabilidade.regularizacaoCampo40.title'"></h4>
</div>

<div class="modal-body">
  <div class="form-group">
    <div [translate]="'docscontabilidade.regularizacaoCampo40.message'"></div>
  </div>

  <div class="form-group" [ngClass]="{'has-error': hasError}">
    <pl-edit type="boolean" attrName="confirmed" [model]="confirmed" (modelChange)="changed($event)" [properties]="{label: 'docscontabilidade.regularizacaoCampo40.label'}"> </pl-edit>
    <div class="text-error" *ngIf="hasError" [translate]="'docscontabilidade.regularizacaoCampo40.validateMessage'"></div>
  </div>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary btn-sm action-close" (evtClicked)="close()"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span> </pl-button>
  <pl-button klass="btn-light btn-sm action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
</div>
