import {from, Observable, throwError} from 'rxjs';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHandlerFn, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {StateDeclaration, StateService, Transition, UIRouterGlobals} from '@uirouter/core';
import {isError, isFunction, isObject, isString, Logger, PlAlertService, PlGlobalEventsService} from 'pl-comps-angular';
import {ApiService} from '../core/services/api/api.service';
import {AppService} from '../core/services/app/app.service';
import {AuthService} from '../core/services/auth/auth.service';
import {CGExceptionService} from '../core/components/exceptions/exceptions.service';
import {COMPANY_STATUS_INSTANCES, STATE_NAME_COMPANY_STATUS} from '../core/states/account/companystatus/companystatus.interface';
import {ConfigService} from '../core/services/config/config.service';
import {CONTRATO_LOCKED_REASON_OF_STR} from '../common/enums/contratolockedreason.enum';
import {ERROS} from '../core/services/erros';
import {EStatusCode, GLOBAL_EVENT_HTTP_ERROR, isDev, isTest} from '../config/constants';
import {IAppStatus} from '../core/services/app/app.service.interface';
import {IAuthRestoreStateData} from '../core/services/auth/auth.service.interface';
import {STATE_NAME_EMPRESA_BLOQUEADA} from '../core/states/account/empresabloqueada/empresabloqueada.state.interface';
import {STATE_NAME_LOCKED_CONTRACT} from '../core/states/account/lockedcontract/lockedcontract.state.interface';
import {STATE_NAME_NO_AUTHORITY} from '../core/states/account/noauthority/noauthority.state.interface';
import {STATE_NAME_PORTAL} from '../core/services/portals/portals.service.interface';
import {Writeable} from '../common/interfaces/interfaces';

const companyStatusInstances: ReadonlySet<string> = COMPANY_STATUS_INSTANCES;
let disconnected = false;
let maintenance = false;

export function interceptorErrorHandler(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const logger: Logger = inject(Logger);
  const uiRouterGlobals: UIRouterGlobals = inject(UIRouterGlobals);
  const stateService: StateService = inject(StateService);
  const configService: ConfigService = inject(ConfigService);
  const appService: AppService = inject(AppService);
  const apiService: ApiService = inject(ApiService);
  const authService: AuthService = inject(AuthService);
  const cgExceptionService: CGExceptionService = inject(CGExceptionService);
  const plAlertService: PlAlertService = inject(PlAlertService);
  const globalEventsService: PlGlobalEventsService = inject(PlGlobalEventsService);

  function handleResponse(response: HttpResponse<unknown>): void {
    if (!validOrigin(response.url)) {
      return;
    }

    if (response.ok) {
      if (disconnected || maintenance) {
        const status: Writeable<Partial<IAppStatus>> = {};
        if (disconnected) {
          status.disconnected = false;
        }
        if (maintenance) {
          status.maintenance = false;
        }
        appService.setStatus(status);

        disconnected = false;
        maintenance = false;
      }
    }
  }

  async function handleResponseError(rejection: HttpErrorResponse): Promise<void> {
    // In case server doesn't return a JSON response (usually 401 status error)
    if (!isObject(rejection)) {
      await stateService.go(STATE_NAME_NO_AUTHORITY);
      return;
    }
    if ((<Error>rejection).stack) {
      // JavaScript error
      return;
    }

    if (!validOrigin(rejection.url)) {
      return;
    }

    switch (rejection.status) {
      case EStatusCode.NoResponse:
        disconnected = true;
        appService.setStatus({disconnected: true});
        break;
      case EStatusCode.ServiceUnavailable:
        maintenance = true;
        appService.setStatus({maintenance: true});
        break;
      /*case EStatusCode.BadGateway:
        const cgStoreUrl: string = await configSiteService.cgStoreUrl();
        if (cgStoreUrl) {
          // Modo cloud pública
          return stateService.go(STATE_NAME_MAINTENANCE).then(() => undefined);
        }
        break;*/
    }

    const exception = cgExceptionService.get(rejection);
    if (exception && exception.status === ERROS.empresaBloqueada) {
      configService.lockEmpresa(exception.message);
      await stateService.go(STATE_NAME_EMPRESA_BLOQUEADA);
      return;
    }

    if (isTest()) {
      logger.error(exception.message);
      if (isFunction(rejection.headers)) {
        logger.error(cgExceptionService.parseHeaders(rejection));
      }
    }

    // Server busy
    if (companyStatusInstances.has(exception.class)) {
      await stateService.go(STATE_NAME_COMPANY_STATUS, {statusInstance: exception.class});
    }

    // Not authenticated
    if (rejection.status === EStatusCode.Unauthorized || rejection.status === EStatusCode.Forbidden) {
      const restoreStateData: IAuthRestoreStateData<unknown> = {stateName: undefined, params: undefined, data: undefined};
      const lastTransaction: Transition = uiRouterGlobals.successfulTransitions.peekHead();
      if (lastTransaction) {
        const {name, data}: StateDeclaration = lastTransaction.to();
        restoreStateData.stateName = name;
        restoreStateData.params = lastTransaction.params();
        restoreStateData.data = data;
      }
      if (!uiRouterGlobals.transition?.isActive() || uiRouterGlobals.transition.to().name !== STATE_NAME_PORTAL) {
        // Do not await this promise as it might cause a race condition and never redirect to unauthorized state
        if (rejection.status === EStatusCode.Unauthorized) {
          if (exception.class === 'EContratoLocked') {
            stateService.go(STATE_NAME_LOCKED_CONTRACT, {lockedReason: CONTRATO_LOCKED_REASON_OF_STR.get(exception.message)});
          } else {
            authService.goLogin(restoreStateData).catch((reason: unknown) => {
              logger.error(reason);
            });
          }
        } else {
          stateService.go(STATE_NAME_NO_AUTHORITY).catch((reason: unknown) => {
            logger.error(reason);
          });
        }
      }
    } else if (rejection.status !== EStatusCode.BadGateway) {
      const originalUrl = cgExceptionService.parseUrl(decodeURIComponent(rejection.url));
      cgExceptionService.executeExceptionReporting(originalUrl, (excluded: boolean) => {
        if (excluded) {
          return;
        }

        if (isDev()) {
          globalEventsService.broadcast(GLOBAL_EVENT_HTTP_ERROR, rejection);
        }

        if (cgExceptionService.isFatal(exception)) {
          plAlertService.error('error.server.internalServerError');
        } else if (exception.message) {
          plAlertService.error(exception.message);
        }
      });
    }
  }

  function validOrigin(url: string): boolean {
    const origin = apiService.path.host || `${location.protocol}//${location.host}`;
    return isString(url) && url.startsWith(origin);
  }

  return next(request)
    .pipe(
      tap((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          handleResponse(event);
        }
      })
    )
    .pipe(
      catchError((error: HttpErrorResponse) => {
        return from(handleResponseError(error))
          .pipe(mergeMap(() => throwError(() => error)))
          .pipe(
            catchError((reason: unknown) => {
              if (isError(reason)) {
                logger.error(reason);
              }
              return throwError(() => error);
            })
          );
      })
    );
}

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private readonly _companyStatusInstances: ReadonlySet<string>;
  private _disconnected: boolean;
  private _maintenance: boolean;

  constructor(
    private readonly _logger: Logger,
    private readonly _uiRouterGlobals: UIRouterGlobals,
    private readonly _stateService: StateService,
    private readonly _configService: ConfigService,
    private readonly _appService: AppService,
    private readonly _apiService: ApiService,
    private readonly _authService: AuthService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _plAlertService: PlAlertService,
    private readonly _globalEventsService: PlGlobalEventsService
  ) {
    this._companyStatusInstances = COMPANY_STATUS_INSTANCES;
    this._disconnected = false;
    this._maintenance = false;
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next
      .handle(request)
      .pipe(
        tap((event: HttpEvent<unknown>) => {
          if (event instanceof HttpResponse) {
            this.handleResponse(event);
          }
        })
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return from(this.handleResponseError(error))
            .pipe(mergeMap(() => throwError(() => error)))
            .pipe(
              catchError((reason: unknown) => {
                if (isError(reason)) {
                  this._logger.error(reason);
                }
                return throwError(() => error);
              })
            );
        })
      );
  }

  public handleResponse(response: HttpResponse<unknown>): void {
    if (!this._validOrigin(response.url)) {
      return;
    }

    if (response.ok) {
      if (this._disconnected || this._maintenance) {
        const status: Writeable<Partial<IAppStatus>> = {};
        if (this._disconnected) {
          status.disconnected = false;
        }
        if (this._maintenance) {
          status.maintenance = false;
        }
        this._appService.setStatus(status);

        this._disconnected = false;
        this._maintenance = false;
      }
    }
  }

  public async handleResponseError(rejection: HttpErrorResponse): Promise<void> {
    // In case server doesn't return a JSON response (usually 401 status error)
    if (!isObject(rejection)) {
      await this._stateService.go(STATE_NAME_NO_AUTHORITY);
      return;
    }
    if ((<Error>rejection).stack) {
      // JavaScript error
      return;
    }

    if (!this._validOrigin(rejection.url)) {
      return;
    }

    switch (rejection.status) {
      case EStatusCode.NoResponse:
        this._disconnected = true;
        this._appService.setStatus({disconnected: true});
        break;
      case EStatusCode.ServiceUnavailable:
        this._maintenance = true;
        this._appService.setStatus({maintenance: true});
        break;
      /*case EStatusCode.BadGateway:
        const cgStoreUrl: string = await this._configSiteService.cgStoreUrl();
        if (cgStoreUrl) {
          // Modo cloud pública
          return this._stateService.go(STATE_NAME_MAINTENANCE).then(() => undefined);
        }
        break;*/
    }

    const exception = this._cgExceptionService.get(rejection);
    if (exception && exception.status === ERROS.empresaBloqueada) {
      this._configService.lockEmpresa(exception.message);
      await this._stateService.go(STATE_NAME_EMPRESA_BLOQUEADA);
      return;
    }

    if (isTest()) {
      this._logger.error(exception.message);
      if (isFunction(rejection.headers)) {
        this._logger.error(this._cgExceptionService.parseHeaders(rejection));
      }
    }

    // Server busy
    if (this._companyStatusInstances.has(exception.class)) {
      await this._stateService.go(STATE_NAME_COMPANY_STATUS, {statusInstance: exception.class});
    }

    // Not authenticated
    if (rejection.status === EStatusCode.Unauthorized || rejection.status === EStatusCode.Forbidden) {
      const restoreStateData: IAuthRestoreStateData<unknown> = {stateName: undefined, params: undefined, data: undefined};
      const lastTransaction: Transition = this._uiRouterGlobals.successfulTransitions.peekHead();
      if (lastTransaction) {
        const {name, data}: StateDeclaration = lastTransaction.to();
        restoreStateData.stateName = name;
        restoreStateData.params = lastTransaction.params();
        restoreStateData.data = data;
      }
      if (!this._uiRouterGlobals.transition?.isActive() || this._uiRouterGlobals.transition.to().name !== STATE_NAME_PORTAL) {
        // Do not await this promise as it might cause a race condition and never redirect to unauthorized state
        if (rejection.status === EStatusCode.Unauthorized) {
          if (exception.class === 'EContratoLocked') {
            this._stateService.go(STATE_NAME_LOCKED_CONTRACT, {lockedReason: CONTRATO_LOCKED_REASON_OF_STR.get(exception.message)});
          } else {
            this._authService.goLogin(restoreStateData).catch((reason: unknown) => {
              this._logger.error(reason);
            });
          }
        } else {
          this._stateService.go(STATE_NAME_NO_AUTHORITY).catch((reason: unknown) => {
            this._logger.error(reason);
          });
        }
      }
    } else if (rejection.status !== EStatusCode.BadGateway) {
      const originalUrl = this._cgExceptionService.parseUrl(decodeURIComponent(rejection.url));
      this._cgExceptionService.executeExceptionReporting(originalUrl, (excluded: boolean) => {
        if (excluded) {
          return;
        }

        if (isDev()) {
          this._globalEventsService.broadcast(GLOBAL_EVENT_HTTP_ERROR, rejection);
        }

        if (this._cgExceptionService.isFatal(exception)) {
          this._plAlertService.error('error.server.internalServerError');
        } else if (exception.message) {
          this._plAlertService.error(exception.message);
        }
      });
    }
  }

  private _validOrigin(url: string): boolean {
    const origin = this._apiService.path.host || `${location.protocol}//${location.host}`;
    return isString(url) && url.startsWith(origin);
  }
}
