<div class="contabilidade-predefinidos">
  <pl-nav-wizard
    [(model)]="step"
    [definition]="definition"
    [callback]="plNavWizard"
    [beforeStepChange]="fnBeforeChangedStep"
    [onFinalize]="fnFinalize"
    [destroyOnHide]="false"
    (evtStepChange)="changedStep($event.currentStep)">
    <pl-nav-wizard-step [stepId]="steps.Operation" caption="predefinidoscontabilidade.steps.operation" icon="fa-home" [required]="true">
      <div *plNavWizardStepContent>
        <div class="contabilidade-predefinidos-operation">
          <h5 class="mb-3" [translate]="'predefinidoscontabilidade.titles.chooseOperation'"></h5>
          <pl-form [(ngForm)]="formStep1" [properties]="{editClass: 'col-sm-5 col-lg-4', validators: {required: {value: true, message: ''}}}">
            <pl-group>
              <edit>
                <div class="operations">
                  <pl-edit type="radiogroup" attrName="operation" [(model)]="operation" [properties]="{disabled: disableForm, groupItems: groupOperations}"> </pl-edit>
                </div>
              </edit>
            </pl-group>
          </pl-form>
        </div>

        <div
          class="row"
          *ngIf="(formStep1?.submitted || formStep1?.control.controls.operation?.touched || !formStep1?.control.controls.operation?.pristine) && formStep1?.control.controls.operation?.invalid">
          <div class="col-sm-12">
            <div class="text-error text-xs operations-validator">{{ textValidatorRequired }}</div>
          </div>
        </div>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step [stepId]="steps.Basic" caption="predefinidoscontabilidade.steps.basic" icon="fa-cogs" [required]="true" [validator]="fnValidatorStep2">
      <div *plNavWizardStepContent class="row">
        <div class="col-12 col-sm-5 col-lg-4">
          <div class="entity-detail-form contabilidade-predefinidos-operation-basic">
            <pl-form [properties]="{disabled: disableForm, validators: {required: {value: true}}}" (formInstanceChange)="formInstanceBasic = $event">
              <pl-group *ngIf="operation !== operations.EDIT && operation !== operations.DELETE">
                <label [translate]="'predefinidoscontabilidade.fields.preDefinidosID'"></label>
                <edit>
                  <pl-edit type="cginteger" attrName="codigo" [(model)]="model.preDefinidosID" plAutoFocus></pl-edit>
                </edit>
                <actions>
                  <pl-button
                    klass="btn-primary btn-sm"
                    attrName="sugerirCodigo"
                    text="predefinidoscontabilidade.titles.suggestCodigo"
                    [tooltip]="{text: 'predefinidoscontabilidade.titles.suggestCodigoToolTip'}"
                    [onClick]="fnSuggestCodeMultiEmpresa">
                  </pl-button>
                </actions>
              </pl-group>

              <pl-group *ngIf="operation === operations.EDIT || operation === operations.DELETE">
                <label [translate]="'predefinidoscontabilidade.fields.preDefinidosID'"></label>
                <edit>
                  <entity-autocomplete
                    entity="predefinidoscontabilidade"
                    attrName="codigo"
                    [selectedKey]="model.preDefinidosID"
                    (selectedKeyChange)="model.preDefinidosID = $event"
                    (evtSelectedDescriptionChanged)="model.descricao = $event"
                    [properties]="{readonly: hardEditing}"
                    output="preDefinidosID"
                    outputDescription="descricao"
                    plAutoFocus
                    [plAutoFocusDisabled]="operation !== operations.EDIT && operation !== operations.DELETE">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group *ngIf="operation !== operations.DELETE">
                <label [translate]="'predefinidoscontabilidade.fields.description'"></label>
                <edit>
                  <pl-edit type="text" attrName="descricao" [(model)]="model.descricao" plAutoFocus [plAutoFocusDisabled]="operation === operations.EDIT"> </pl-edit>
                </edit>
              </pl-group>

              <pl-group *ngIf="operation === operations.NEW_BASE_DOC">
                <label [translate]="'predefinidoscontabilidade.fields.document'"></label>
                <edit>
                  <entity-autocomplete entity="docscontabilidade" attrName="documento" [(model)]="docContabilidade" [selectOnFocus]="false"></entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group *ngIf="operation === operations.NEW_BASE_PREDEFINIDO">
                <label [translate]="'predefinidoscontabilidade.fields.copyExisting'"></label>
                <edit>
                  <entity-autocomplete entity="predefinidoscontabilidade" attrName="preDefinidoCopiar" (selectedKeyChange)="preDefinidosIDToCopy = $event"> </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'predefinidoscontabilidade.fields.cgBanking'"></label>
                <edit>
                  <pl-edit type="boolean" attrName="cgBanking" [(model)]="model.cgBanking" [properties]="{readonly: operation === operations.DELETE}"></pl-edit>
                </edit>
              </pl-group>
            </pl-form>
          </div>
        </div>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step [stepId]="steps.Header" caption="predefinidoscontabilidade.steps.header" icon="fa-list-alt" [required]="true" [visible]="false" [validator]="fnValidatorStep3">
      <div *plNavWizardStepContent class="row">
        <div class="col-12">
          <predefinidocontabcab [predefinido]="model" [(valid)]="isStep3Valid" [callback]="preDefinidoContabCab"></predefinidocontabcab>
        </div>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step [stepId]="steps.Lines" caption="predefinidoscontabilidade.steps.lines" icon="fa-list-ul" [required]="false" [visible]="false" [validator]="fnValidatorStep4">
      <div *plNavWizardStepContent class="row">
        <div class="col-12">
          <predefinidocontablinhas [predefinido]="model" [(valid)]="isStep4Valid"></predefinidocontablinhas>
        </div>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step [stepId]="steps.Preview" caption="predefinidoscontabilidade.steps.preview" icon="fa-eye" [required]="true" [visible]="false">
      <div *plNavWizardStepContent>
        <doccontabilidade
          *ngIf="currentStepId === steps.Preview"
          [model]="docContabilidade"
          (modelChange)="changedDocContabPreview($event)"
          [predefinido]="model"
          [onLoadFocusField]="'periodo'"
          [callback]="docCallback"
          [simulation]="true">
        </doccontabilidade>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step [stepId]="steps.Companies" caption="predefinidoscontabilidade.steps.companies" icon="fa-exchange" [required]="true" [visible]="false">
      <pl-form *plNavWizardStepContent>
        <pl-group>
          <label class="h4" [translate]="titleCompanies"></label>
          <edit>
            <pl-multiselect
              [model]="empresasSelecionadas"
              (evtChanged)="changedEmpresasSelecionadas()"
              [source]="listaEmpresas"
              key="nEmpresa"
              [template]="templateListaEmpresas"
              [properties]="{
                leftCaption: 'predefinidoscontabilidade.titles.availableCompanies',
                rightCaption: titleCompaniesRight,
                validators: {
                  required: {value: true},
                  minselected: {value: 1}
                }
              }">
            </pl-multiselect>
          </edit>
        </pl-group>
      </pl-form>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
