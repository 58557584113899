import {timer, Unsubscribable} from 'rxjs';
import {Component, ElementRef, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {NgClass, NgIf, NgTemplateOutlet} from '@angular/common';
import {animationShake} from '../../../common/animations/animation.shake';
import {CGCIconDirective} from '../../../icon';
import {ICGCToast, TCGCToastType} from '../../toasts.interface';
import {isBoolean} from '../../../common/utilities/utilities';
import {newLine} from '../../../pipes/newline.pipe';
import {PlTranslateModule} from '../../../translate/translate.module';
import {toastAnimation} from './toast.component.interface';

const DEFAULT_TOAST_TYPE: TCGCToastType = 'info';

const ALERT_CLASSES: ReadonlyMap<TCGCToastType, string> = Object.freeze(
  new Map<TCGCToastType, string>([
    ['success', 'alert-success'],
    ['error', 'alert-danger'],
    ['info', 'alert-info'],
    ['warning', 'alert-warning']
  ])
);

const ICON_CLASSES: ReadonlyMap<TCGCToastType, string> = Object.freeze(
  new Map<TCGCToastType, string>([
    ['success', 'fa-check-circle'],
    ['error', 'fa-exclamation-triangle'],
    ['info', 'fa-info-circle'],
    ['warning', 'fa-exclamation-circle']
  ])
);

@Component({
  selector: 'cgc-toast',
  templateUrl: './toast.component.html',
  imports: [NgIf, NgTemplateOutlet, CGCIconDirective, PlTranslateModule, NgClass],
  standalone: true,
  animations: [toastAnimation('300ms', '200ms'), animationShake]
})
export class CGCToastComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public toast: ICGCToast;
  @Input() public gap: number;

  public showToast: boolean;
  public text: string;
  public icon: string;
  public alertClass: string;
  public attentionSeeker: number;
  public toastSize: string;
  public test: string;

  private readonly _observerToast: ResizeObserver;
  private _subcriptionQueuedClose: Unsubscribable;
  private _subcriptionCanceledClose: Unsubscribable;
  private _subcriptionAttentioned: Unsubscribable;
  private _subscriptionAutoClose: Unsubscribable;
  private _elementToast: HTMLElement;

  constructor(private readonly _ngZone: NgZone) {
    this._evaluateToastSize = this._evaluateToastSize.bind(this);
    this.attentionSeeker = 0;
    this._observerToast = new ResizeObserver(() => {
      this._ngZone.run(this._evaluateToastSize);
    });
  }

  public ngOnInit(): void {
    this.showToast = isBoolean(this.toast.autoOpen) ? this.toast.autoOpen : true;

    this.text = this.toast.text || '';
    if (this.text) {
      this.text = newLine(this.text);
    }

    this.icon = this.toast.icon || '';
    if (!this.icon) {
      this.icon = ICON_CLASSES.get(this.toast.type || DEFAULT_TOAST_TYPE);
    }

    this.alertClass = ALERT_CLASSES.get(this.toast.type || DEFAULT_TOAST_TYPE);

    this._subcriptionQueuedClose = this.toast.queueClose.subscribe({
      next: () => {
        this.startOrResetAutoClose();
      }
    });

    this._subcriptionCanceledClose = this.toast.cancelClose.subscribe({
      next: () => {
        this.cancelAutoClose();
      }
    });

    this._subcriptionAttentioned = this.toast.attention.subscribe({
      next: () => {
        this.attentionSeeker++;
      }
    });

    this.startOrResetAutoClose();
  }

  public ngOnChanges({gap}: SimpleChanges): void {
    if (gap && !gap.isFirstChange()) {
      this._evaluateToastSize();
    }
  }

  public ngOnDestroy(): void {
    this._observerToast.disconnect();
    if (this._subcriptionQueuedClose) {
      this._subcriptionQueuedClose.unsubscribe();
    }
    if (this._subcriptionCanceledClose) {
      this._subcriptionCanceledClose.unsubscribe();
    }
    if (this._subcriptionAttentioned) {
      this._subcriptionAttentioned.unsubscribe();
    }
    this.cancelAutoClose();
    this.toast.destroy();
  }

  public close(): void {
    this.showToast = false;
  }

  public animationDone(): void {
    if (!this.showToast) {
      this.toast.close.next();
    }
  }

  public startOrResetAutoClose(): void {
    if (this._subscriptionAutoClose) {
      this._subscriptionAutoClose.unsubscribe();
    }
    if (!this.toast.autoClose || !this.toast.autoCloseDelay) {
      return;
    }
    this._subscriptionAutoClose = timer(this.toast.autoCloseDelay).subscribe(() => {
      this._subcriptionAttentioned = undefined;
      this.close();
    });
  }

  public cancelAutoClose(): void {
    if (this._subscriptionAutoClose) {
      this._subscriptionAutoClose.unsubscribe();
      this._subscriptionAutoClose = undefined;
    }
  }

  @HostListener('mouseover')
  public mouseoverToast(): void {
    if (this.toast.autoClose && this.toast.autoCloseMouseReset) {
      this.startOrResetAutoClose();
    }
  }

  @HostListener('click')
  public clickedToast(): void {
    if (this.toast.closeOnClick) {
      this.close();
    }
  }

  @ViewChild('elementToast', {static: false})
  public set elementToast(elementRef: ElementRef<HTMLElement>) {
    if (this._elementToast) {
      this._observerToast.unobserve(this._elementToast);
      this._elementToast = undefined;
    }

    this._elementToast = elementRef?.nativeElement;

    if (this._elementToast) {
      this._observerToast.observe(this._elementToast);
    }
  }

  private _evaluateToastSize(): void {
    if (!this._elementToast) {
      return;
    }

    let gap = this.gap + this._elementToast.offsetHeight;
    if (Number.isNaN(gap)) {
      gap = 0;
    }

    this.toastSize = `${gap}px`;
  }
}
