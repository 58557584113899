import {Observable, firstValueFrom} from 'rxjs';
import type dxDataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {copy, ERadix, isDefinedNotNull, isNumber} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {EGestaoDGEMPSColors} from '../../../../components/gestaodgemps/gestaodgemps.interface';
import {ENTITY_NAME_DGEMPS, IDGEMPSEntityService} from '../../../../entities/dgemps/dgemps.entity.interface';
import {ENTITY_NAME_PRH_DOCS_PLATA, IPRHDocsPlataEntityService} from '../../../../entities/prhdocsplata/pRHDocsPlata.entity.interface';
import {ENTITY_NAME_PRH_ENT_ALTERS} from '../../../../entities/prhentalters/pRHEntAlers.entity.interface';
import {ENTITY_NAME_PRH_SERVICO_EMPREGADOS} from '../../../../entities/prhservicoempregados/pRHServicoEmpregados.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {EPRHFluxoIndice} from '../../../../entities/prhfluxos/jsonPRHFluxo.entity.interface';
import {HomeComunicacoesInternasModalComponent} from '../modals/comunicacaointerna/home.comunicacaoInterna.modal.component';
import {
  IColaboradoresGestaoDGEMPSModuleStateParams,
  MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_ABONOS_GESTOR,
  MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_FALTAS_GESTOR,
  MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_FERIAS_GESTOR
} from '../../gestaodgemps/colaboradores.gestaodgemps.module.interface';
import {IColaboradoresHome, IDatas, IHomePRHDocsPlata, ITipoComunicacaoHome} from '../home.module.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressPieChart, IDevExpressPieChartDefaultItem} from '../../../../components/devexpress/charts/pie/devexpress.pie.chart.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonDGEMP, IJsonDGEMPDetail, IJsonDGEMPMarcacoes, IJsonDGEmpPorAprovarServico, IJsonProcessamentoEmpregadoSalarios} from '../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHComunicacaoInterna, IJsonPRHNumber} from '../../../../entities/prhcomunicacoesinternas/jsonPRHComunicacaoInterna.entity.interface';
import {IJsonPRHDocsPlata} from '../../../../entities/prhdocsplata/jsonPRHDocsPlata.entity.interface';
import {IJsonPRHEntAlter} from '../../../../entities/prhentalters/jsonPRHEntAlter.entity.interface';
import {IJsonPRHServicoEmpregado} from '../../../../entities/prhservicoempregados/jsonPRHServicoEmpregado.entity.interface';
import {maxDateCG, minDateCG} from '../../../../../common/utils/utils';
import {MODULE_NAME_ALTERACOES_ENTIDADES} from '../../alteracoesentidades/alteracoesEntidades.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import moment, {Moment} from 'moment';
import {TDate} from '../../../../../common/dates';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';
import {gestaoDGEMPSPrettyFeriasMarcadas} from '../../../../components/gestaodgemps/gestaodgemps.utilities';
import {TDevExpressPieChartSeries} from '../../../../components/devexpress/charts/pie/devexpress.pie.chart.types.interface';
import {IJsonPRHTipoCom} from '../../../../entities/prhtipocom/jsonrhtipocom.entity.interface';
import {ENTITY_NAME_PRHTIPOCOM} from '../../../../entities/prhtipocom/prhtipocom.entity.interface';
import {EComunicacaoInternaSystem} from '../../comunicacoesinternas/comunicacoesInternas.module.interface';

const DEFAULT_NUM_FERIAS = 25;

@Component({
  selector: 'colaboradores-home',
  templateUrl: './home.module.component.html'
})
export class ColaboradoresHomeComponent extends ModuloComponent implements OnInit {
  public readonly iColHome: IColaboradoresHome;
  public readonly chartFeriasIntegradas: IDevExpressPieChart<'DoughnutSeries'>;
  public readonly chartFeriasGlobais: IDevExpressPieChart<'DoughnutSeries'>;
  public readonly chartFaltas: IDevExpressPieChart<'DoughnutSeries'>;
  public readonly stateNameFerias: string;
  public readonly stateNameFaltas: string;
  public readonly stateNameAbonos: string;
  public readonly stateNameAlteracaoEntidades: string;
  public readonly stateFixFerias: string;
  public readonly stateFixFaltas: string;
  public readonly stateFixAbonos: string;
  public readonly stateFixAlteracoesEntidades: string;
  public readonly comunicacaoInternaSystem: typeof EComunicacaoInternaSystem;
  public chartDataFeriasIntegradas: Array<IDevExpressPieChartDefaultItem>;
  public chartDataFeriasGlobais: Array<IDevExpressPieChartDefaultItem>;
  public chartDataFaltas: Array<IDevExpressPieChartDefaultItem>;
  public dataDeFilter: TDate;
  public dataAteFilter: TDate;
  public prettyFeriasMarcadas: string;
  public comunicacaoActiveId: string;
  public comunicacaoSelectAllCheckbox: boolean;

  private readonly _serviceDGEMPS: IDGEMPSEntityService;
  private readonly _serviceColaboradoresServicosHome: IEntityService<IJsonPRHServicoEmpregado>;
  private readonly _servicePRHEntAlter: IEntityService<IJsonPRHEntAlter>;
  private readonly _servicePRHDocsPlata: IPRHDocsPlataEntityService;
  private readonly _servicePRHTipoCom: IEntityService<IJsonPRHTipoCom>;
  private _dataGridInstanceRecibos: dxDataGrid<IJsonProcessamentoEmpregadoSalarios>;
  private _dataGridInstanceFeriasMarcadas: dxDataGrid<IDatas>;
  private _dataGridInstanceFeriasRejeitadas: dxDataGrid<IDatas>;
  private _dataGridInstanceFeriasIntegradas: dxDataGrid<IDatas>;
  private _dataGridInstanceFaltasMarcadas: dxDataGrid<IDatas>;
  private _dataGridInstanceFaltasRejeitadas: dxDataGrid<IDatas>;
  private _dataGridInstanceFaltasIntegradas: dxDataGrid<IDatas>;
  private _promiseListaMarcacoes: Promise<IJsonDGEMPMarcacoes>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.comunicacaoInternaSystem = EComunicacaoInternaSystem;
    this._getListFeriasMarcadas = this._getListFeriasMarcadas.bind(this);
    this._getListFeriasIntegradas = this._getListFeriasIntegradas.bind(this);
    this._getListFeriasRejeitadas = this._getListFeriasRejeitadas.bind(this);
    this._getListFaltasMarcadas = this._getListFaltasMarcadas.bind(this);
    this._getListFaltasIntegradas = this._getListFaltasIntegradas.bind(this);
    this._getListFaltasRejeitadas = this._getListFaltasRejeitadas.bind(this);
    this.stateNameFerias = MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_FERIAS_GESTOR;
    this.stateNameFaltas = MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_FALTAS_GESTOR;
    this.stateNameAbonos = MODULE_NAME_COLABORADORES_GESTAO_DGEMPS_ABONOS_GESTOR;
    this.stateNameAlteracaoEntidades = MODULE_NAME_ALTERACOES_ENTIDADES;
    this.iColHome = {};
    this.iColHome.newComunicationsCount = 0;
    this.iColHome.temAlteracoes = false;
    this.iColHome.temFeriasPorAprovar = false;
    this.iColHome.temFaltasPorAprovar = false;
    this.iColHome.temAbonosPorAprovar = false;
    this.iColHome.servicosDocs = [];
    this.iColHome.papeisDocs = [];
    this.iColHome.servicoPrincipal = -1;
    this.iColHome.servico = {codServico: -1, nome: ''};
    this.iColHome.servicos = [];
    this.iColHome.gestorRH = false;
    this.iColHome.listaServicosPendentes = {servicosFerias: [], servicosAbonos: [], servicosFaltas: []};
    this.iColHome.countRecibosPorLer = 0;
    this.iColHome.rptName = '';
    this.iColHome.templateComunicacaointernarecursiva = '';
    this.iColHome.relservicospapeis = [];
    this.iColHome.listaProcessamentos = [];
    this.iColHome.feriasMarcadas = [];
    this.iColHome.feriasRejeitadas = [];
    this.iColHome.feriasIntegradas = [];
    this.iColHome.faltasMarcadas = [];
    this.iColHome.faltasRejeitadas = [];
    this.iColHome.faltasIntegradas = [];
    this.iColHome.colaboradorDetail = {
      codEmp: undefined,
      nome: '',
      numDiasFerias: -1,
      saldoFerias: -1,
      numHorasSemanais: -1,
      codLocalTrabalho: -1,
      localTrabalho: '',
      codTipoHorario: '',
      tipoHorario: '',
      codTipoContrato: '',
      tipocontrato: '',
      validadeContrato: -1,
      nCCusto: '',
      centroCusto: '',
      codReparticao: -1,
      reparticao: '',
      servicoPRH: ''
    };
    this.iColHome.numFerias = DEFAULT_NUM_FERIAS;
    this.iColHome.numDiasFerias = -1;
    this.iColHome.events = [];
    this.iColHome.listMarcacoes = {
      dgEmp: {
        codEmp: -1,
        nome: '',
        apelido: '',
        morada: '',
        codPostal: '',
        nomeLocalida: '',
        email: '',
        nTelefone: '',
        telemovelPart1: '',
        codServicoPRH: -1,
        nomeServicoPRH: '',
        categoriaActual: '',
        nomeCategoria: '',
        rua: '',
        ativo: true,
        horasDia: -1,
        nDiasSFerias: -1,
        ndiasFeriasMarcados: -1,
        nDiasSFeriasTotal: -1,
        nDiasSFeriasIntegradas: -1,
        nDiasFeriasMarcadasAprovadas: -1,
        numContrib: ''
      },
      listaFeriasMarcadas: [],
      listaFaltasMarcadas: [],
      listaAbonosMarcadas: [],
      listaFeriasJaIntegradas: [],
      listaFaltasJaIntegradas: [],
      listaAbonosJaIntegradas: [],
      listaCalendario: [],
      numFeriasPendentes: -1,
      numFaltasPendentes: -1,
      numAbonosPendentes: -1,
      mesesPendentes: []
    };
    this.iColHome.listDocsPlata = [];
    this.iColHome.tpComunicacao = [];
    this.iColHome.defRecibos = {
      columns: [
        {dataField: 'nRecibo', dataType: 'string', caption: 'dgemps.recibos.fields.nrecibo', sortOrder: 'desc'},
        {dataField: 'dataProcessamento', dataType: 'date', caption: 'dgemps.recibos.fields.datarecibo'},
        {dataField: 'lido', dataType: 'boolean', caption: 'Lido'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnRecibos'}
      ],
      dataSource: new CustomStore({
        load: () => this._getListRecibos()
      }),
      remoteOperations: false
    };
    this.iColHome.defFeriasMarcadas = this._generateTableDefinition('Férias Marcadas', this._getListFeriasMarcadas);
    this.iColHome.defFeriasIntegradas = this._generateTableDefinition('Férias Aprovadas', this._getListFeriasIntegradas);
    this.iColHome.defFeriasRejeitadas = this._generateTableDefinition('Férias Rejeitadas', this._getListFeriasRejeitadas);
    this.iColHome.defFaltasMarcadas = this._generateTableDefinition('Faltas Marcadas', this._getListFaltasMarcadas);
    this.iColHome.defFaltasIntegradas = this._generateTableDefinition('Faltas Aprovadas', this._getListFaltasIntegradas);
    this.iColHome.defFaltasRejeitadas = this._generateTableDefinition('Faltas Rejeitadas', this._getListFaltasRejeitadas);

    this.iColHome.defDocsPlata = {
      columns: [
        {dataField: 'titulo', dataType: 'string', caption: 'prhdocsplata.fields.cab.titulo'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnDocsPlata'}
      ],
      dataSource: new CustomStore({
        load: () => this._getListDocsPlata()
      }),
      remoteOperations: false
    };
    const series: TDevExpressPieChartSeries<'DoughnutSeries'> = {
      argumentField: 'arg',
      valueField: 'val',
      label: {
        visible: true
      }
    };
    this.chartFeriasIntegradas = {
      type: 'doughnut',
      title: {
        text: 'Férias Aprovadas'
      },
      series: series,
      palette: [EGestaoDGEMPSColors.Integradas, EGestaoDGEMPSColors.Weekend]
    };
    this.chartFeriasGlobais = {
      type: 'doughnut',
      title: {
        text: 'Férias'
      },
      series: series,
      palette: [EGestaoDGEMPSColors.Marcadas, EGestaoDGEMPSColors.Rejeitadas, EGestaoDGEMPSColors.Integradas]
    };
    this.chartFaltas = {
      type: 'doughnut',
      title: {
        text: 'Faltas'
      },
      series: series,
      palette: [EGestaoDGEMPSColors.Marcadas, EGestaoDGEMPSColors.Rejeitadas, EGestaoDGEMPSColors.Integradas]
    };
    this.chartDataFeriasIntegradas = [];
    this.chartDataFeriasGlobais = [];
    this.chartDataFaltas = [];
    this.stateFixFerias = this._cgStateService.getRedirectState({stateOrName: this.stateNameFerias})?.name;
    this.stateFixFaltas = this._cgStateService.getRedirectState({stateOrName: this.stateNameFaltas})?.name;
    this.stateFixAbonos = this._cgStateService.getRedirectState({stateOrName: this.stateNameAbonos})?.name;
    this.stateFixAlteracoesEntidades = this._cgStateService.getRedirectState({stateOrName: this.stateNameAlteracaoEntidades})?.name;
    this.dataDeFilter = moment().startOf('year');
    this.dataAteFilter = moment().endOf('year');
    this._serviceDGEMPS = this._entityServiceBuilder.build<IJsonDGEMP, IDGEMPSEntityService>(ENTITY_NAME_DGEMPS);
    this._serviceColaboradoresServicosHome = this._entityServiceBuilder.build<IJsonPRHServicoEmpregado>(ENTITY_NAME_PRH_SERVICO_EMPREGADOS);
    this._servicePRHEntAlter = this._entityServiceBuilder.build<IJsonPRHEntAlter>(ENTITY_NAME_PRH_ENT_ALTERS);
    this._servicePRHDocsPlata = this._entityServiceBuilder.build<IJsonPRHDocsPlata, IPRHDocsPlataEntityService>(ENTITY_NAME_PRH_DOCS_PLATA);
    this._servicePRHTipoCom = this._entityServiceBuilder.build(ENTITY_NAME_PRHTIPOCOM);
    this.comunicacaoActiveId = `comunicacaoNav-${EComunicacaoInternaSystem.COMUNICACAO_INTERNA}`;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.iColHome.codEmp = this.session.erp.codEmp;
    this._servicePRHTipoCom.query().then((response) => {
      this.iColHome.tpComunicacao = response.body.list.map((tipo) => {
        return {...tipo, cabecalhosComInt: [], count: 0, tipoComInt: tipo.codPRHTipoCom};
      });
      this._refreshAndGetListComunicacoes();
    });
    this._serviceColaboradoresServicosHome.query({pesquisa: `codEmp=${this.iColHome.codEmp}`}).then((response: THttpQueryResponse<IJsonPRHServicoEmpregado>) => {
      if (response.body.list.length) {
        for (const servico of response.body.list) {
          this.iColHome.servicosDocs.push(servico.codServico);
          this.iColHome.papeisDocs.push(servico.codPapel);

          if (servico.papel.unicoServico) {
            this.iColHome.servicoPrincipal = servico.codServico;
          }

          let existe = false;
          for (const homeServico of this.iColHome.servicos) {
            existe = false;
            if (homeServico.codServico === servico.codServico) {
              existe = true;
              break;
            }
          }
          if (!existe) {
            this.iColHome.servicos.push(servico);
          }

          // Este código é temporário e pode falhar caso se altere os dados da tabela prhpapel.
          // Ficou assim porque não se pode alterar AGORA(16/10/2018) as Roles. Ass: Gonçalo Serrano
          if (servico.papel.nome === 'Gestor RH') {
            this.iColHome.gestorRH = true;
          }

          this.iColHome.relservicospapeis.push(servico);
        }

        if (this.iColHome.servicos.length > 0) {
          this.iColHome.servico = this.iColHome.servicos[0].servico;
        }

        this.alteracoesEntidades();
        this.pendentesPorTratar();
        this.fillDashboard();
      }
    });
  }

  public goToAprova(state: string, servico?: IJsonDGEmpPorAprovarServico): Promise<unknown> {
    let toState: string;
    let params: IColaboradoresGestaoDGEMPSModuleStateParams;
    switch (state) {
      case this.stateNameFaltas:
        toState = this.stateFixFaltas;
        params = {servico: servico.codServico, colaboradoresPendentes: servico.colaboradores};
        break;
      case this.stateNameFerias:
        toState = this.stateFixFerias;
        params = {servico: servico.codServico, colaboradoresPendentes: servico.colaboradores};
        break;
      case this.stateNameAbonos:
        toState = this.stateFixAbonos;
        params = {servico: servico.codServico, colaboradoresPendentes: servico.colaboradores};
        break;
      case this.stateNameAlteracaoEntidades:
        toState = this.stateFixAlteracoesEntidades;
        break;
      default:
        return Promise.resolve();
    }
    return this._stateService.go(toState, params);
  }

  public alteracoesEntidades(): Promise<void> {
    return this._servicePRHEntAlter.query({pesquisa: 'estado=0'}).then((data) => {
      if (data.body.list.length > 0) {
        this.iColHome.temAlteracoes = true;
      }
    });
  }

  public pendentesPorTratar(): void {
    this._serviceDGEMPS.getListaPendentesPorTratar(this.iColHome.codEmp).then((data) => {
      this.iColHome.temFeriasPorAprovar = data.body.listaServicosFerias.length > 0;
      this.iColHome.temFaltasPorAprovar = data.body.listaServicosFaltas.length > 0;
      this.iColHome.temAbonosPorAprovar = data.body.listaServicosAbonos.length > 0;

      this.iColHome.listaServicosPendentes.servicosFerias = copy(data.body.listaServicosFerias);
      this.iColHome.listaServicosPendentes.servicosFaltas = copy(data.body.listaServicosFaltas);
      this.iColHome.listaServicosPendentes.servicosAbonos = copy(data.body.listaServicosAbonos);
    });
  }

  public fillDashboard(): void {
    this.fillRecibos();
    this.fillFerias();
    this.fillFaltas();
    this._getListDocsPlata();
  }

  // Recibos
  public refreshRecibos(): void {
    if (this._dataGridInstanceRecibos) {
      this._dataGridInstanceRecibos.refresh();
    }
  }

  public pdfModalRecibos(item: IJsonProcessamentoEmpregadoSalarios): Promise<void> | void {
    return new Promise<void>((resolve) => {
      const reportName = this._configService.configurations.portalcolaborador.reportrecibosal;
      this.getPdfRecibosUrl(this.iColHome.codEmp, item.nProcessamento, 1, reportName).subscribe((pdfUrl) => {
        this._cgModalService.showPdf('dgemps.recibos.title', pdfUrl).finally(() => {
          if (!item.lido) {
            item.lido = true;
            this.iColHome.countRecibosPorLer--;
          }
          resolve();
        });
      });
    });
  }

  public getPdfRecibosUrl(codEmp: number, nProcessamento: number, nVias: number, reportName: string): Observable<string> {
    return this._serviceDGEMPS.exportReciboVencimentoToPdfUrl(codEmp, nProcessamento, nVias, reportName);
  }

  public fillRecibos(): Promise<void> {
    return this._serviceDGEMPS.getListaProcessamentos(this.iColHome.codEmp, minDateCG(), maxDateCG(), true).then((response: HttpResponse<Array<IJsonProcessamentoEmpregadoSalarios>>) => {
      this.iColHome.listaProcessamentos = response.body;
      for (const processamento of this.iColHome.listaProcessamentos) {
        if (!processamento.lido) {
          this.iColHome.countRecibosPorLer++;
        }
      }
      this._getListRecibos();
      this.refreshRecibos();
    });
  }

  // Ferias
  public refreshFerias(): void {
    if (this._dataGridInstanceFeriasMarcadas) {
      this._dataGridInstanceFeriasMarcadas.refresh();
    }
    if (this._dataGridInstanceFeriasIntegradas) {
      this._dataGridInstanceFeriasIntegradas.refresh();
    }
    if (this._dataGridInstanceFeriasRejeitadas) {
      this._dataGridInstanceFeriasRejeitadas.refresh();
    }
  }

  public pesqFerias(): void {
    this.fillFerias();
  }

  public fillFerias(): void {
    this.iColHome.feriasMarcadas = [];
    this.iColHome.feriasRejeitadas = [];
    this.iColHome.feriasIntegradas = [];
    this._getListaMarcacoes().then((marcacoes: IJsonDGEMPMarcacoes) => {
      if (marcacoes.dgEmp.nDiasSFerias > 0) {
        this.iColHome.numDiasFerias = marcacoes.dgEmp.nDiasSFerias;
      }

      this.iColHome.listMarcacoes = marcacoes;
      this.iColHome.events = [];

      for (const myFeria of this.iColHome.listMarcacoes.listaFeriasMarcadas) {
        for (const linha of myFeria.linhas) {
          if (myFeria.cab.fluxo.indice === EPRHFluxoIndice.FeriasRejeitar) {
            this.iColHome.feriasRejeitadas.push({data: linha.data});
          } else {
            this.iColHome.feriasMarcadas.push({data: linha.data});
          }
        }
      }

      for (const feriasJaIntegradas of this.iColHome.listMarcacoes.listaFeriasJaIntegradas) {
        this.iColHome.feriasIntegradas.push({data: feriasJaIntegradas.data});
      }

      this._getListFeriasMarcadas();
      this._getListFeriasIntegradas();
      this._getListFeriasRejeitadas();

      // FERIAS INTEGRADAS / RESTANTES DOUGHNUT
      const feriasIntegradasValue = this.iColHome.feriasIntegradas.length;
      const feriasRestantes = this.iColHome.numDiasFerias - feriasIntegradasValue;
      this.chartDataFeriasIntegradas = [
        {arg: 'Aprovadas', val: feriasIntegradasValue},
        {arg: 'Restantes', val: feriasRestantes}
      ];

      // FERIAS GLOBAL DOUGHNUT
      const feriasMarcadasValue = this.iColHome.feriasMarcadas.length;
      const feriasRejeitadasValue = this.iColHome.feriasRejeitadas.length;
      this.chartDataFeriasGlobais = [
        {arg: 'Marcadas', val: feriasMarcadasValue},
        {arg: 'Rejeitadas', val: feriasRejeitadasValue},
        {arg: 'Aprovadas', val: feriasIntegradasValue}
      ];

      this.prettyFeriasMarcadas = gestaoDGEMPSPrettyFeriasMarcadas(this._translateService, this.iColHome.listMarcacoes.dgEmp);
      this.refreshFerias();
    });
  }

  // Faltas
  public refreshFaltas(): void {
    if (this._dataGridInstanceFaltasMarcadas) {
      this._dataGridInstanceFaltasMarcadas.refresh();
    }
    if (this._dataGridInstanceFaltasIntegradas) {
      this._dataGridInstanceFaltasIntegradas.refresh();
    }
    if (this._dataGridInstanceFaltasRejeitadas) {
      this._dataGridInstanceFaltasRejeitadas.refresh();
    }
  }

  public pesqFaltas(): void {
    this.fillFaltas();
  }

  public fillFaltas(): void {
    this.iColHome.faltasMarcadas = [];
    this.iColHome.faltasRejeitadas = [];
    this.iColHome.faltasIntegradas = [];
    this._getListaMarcacoes().then((marcacoes: IJsonDGEMPMarcacoes) => {
      this.iColHome.listMarcacoes = marcacoes;
      this.iColHome.events = [];

      const myFaltas = this.iColHome.listMarcacoes.listaFaltasMarcadas;
      for (const myFalta of myFaltas) {
        for (const linha of myFalta.linhas) {
          if (myFalta.cab.fluxo.indice === EPRHFluxoIndice.FaltasRejeitar) {
            this.iColHome.faltasRejeitadas.push({data: linha.data});
          } else {
            this.iColHome.faltasMarcadas.push({data: linha.data});
          }
        }
      }

      for (const faltasJaIntegradas of this.iColHome.listMarcacoes.listaFaltasJaIntegradas) {
        this.iColHome.faltasIntegradas.push({data: faltasJaIntegradas.data});
      }

      this._getListFaltasMarcadas();
      this._getListFaltasIntegradas();
      this._getListFaltasRejeitadas();

      const faltasIntegradasValue = this.iColHome.faltasIntegradas.length;
      const faltasMarcadasValue = this.iColHome.faltasMarcadas.length;
      const faltasRejeitadasValue = this.iColHome.faltasRejeitadas.length;

      this.chartDataFaltas = [
        {arg: 'Marcadas', val: faltasMarcadasValue},
        {arg: 'Rejeitadas', val: faltasRejeitadasValue},
        {arg: 'Aprovadas', val: faltasIntegradasValue}
      ];

      this.refreshFaltas();
    });
  }

  // Comunicações Internas

  public novaComInt(item: ITipoComunicacaoHome): void {
    const newItem = copy(this._getEmptyComunicacaoInterna());
    newItem.cab.tipoComInt = item.codPRHTipoCom;
    newItem.cab.isPrincipal = true;
    newItem.cab.data = moment();

    this.answerComInt(newItem, item, false);
  }

  public answerComInt(item: IJsonPRHComunicacaoInterna, tableItem: ITipoComunicacaoHome, answer: boolean): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(HomeComunicacoesInternasModalComponent);
    const componentInstance: HomeComunicacoesInternasModalComponent = modalInstance.componentInstance;
    componentInstance.item = item;
    componentInstance.answer = answer;
    componentInstance.servicoPrincipal = this.iColHome.servicoPrincipal;
    componentInstance.codEmp = this.iColHome.codEmp;
    componentInstance.tiposComunicacao = this._refreshAndGetListComunicacoes();
    return modalInstance.result.then(() => {
      this.fillComunicacoes(tableItem);
      this._refreshAndGetListComunicacoes();
    });
  }

  public verificaSeTemParaLer(item: IJsonPRHComunicacaoInterna): boolean {
    if (!item.estadoLido && item.cab.codEmp !== this.iColHome.codEmp) {
      return true;
    }
    return item.filhos.length > 0 ? this.verificaSeTemParaLer(item.filhos[0]) : false;
  }

  public comunicacaoActiveIdChange(id: string): void {
    this.comunicacaoActiveId = id;
    const codTipo = parseInt(id.split('-').pop().trim(), ERadix.Decimal);
    const tpComunicacaoItem = this.iColHome.tpComunicacao.find((item) => item.codPRHTipoCom === codTipo);
    if (isDefinedNotNull(tpComunicacaoItem)) {
      this.fillComunicacoes(tpComunicacaoItem);
    }
  }

  public fillComunicacoes(tableItem: ITipoComunicacaoHome): Promise<void> {
    const aData: Moment = moment();
    const dataDe: Moment = minDateCG();
    const dataAte: Moment = aData.clone().add(1, 'month').startOf('month');
    return this._serviceDGEMPS.getListaComunicacoes(this.iColHome.codEmp, dataDe, dataAte, tableItem.codPRHTipoCom, false).then((response: HttpResponse<Array<IJsonPRHComunicacaoInterna>>) => {
      tableItem.cabecalhosComInt = [];
      for (const comunicacao of response.body) {
        const comAuxNew: IJsonPRHComunicacaoInterna = copy(this._getEmptyComunicacaoInterna());
        comAuxNew.cab.comIntCabID = comunicacao.cab.comIntCabID;
        comAuxNew.cab.docID = comunicacao.cab.docID;
        comAuxNew.cab.titulo = comunicacao.cab.titulo;
        comAuxNew.cab.tipoComInt = comunicacao.cab.tipoComInt;
        comAuxNew.cab.nomeTipoComInt = comunicacao.cab.nomeTipoComInt;
        comAuxNew.cab.texto = comunicacao.cab.texto;
        comAuxNew.cab.data = comunicacao.cab.data;
        comAuxNew.estadoLido = comunicacao.estadoLido;
        comAuxNew.comIntLinID = comunicacao.comIntLinID;
        comAuxNew.codEmpComIntLIn = comunicacao.codEmpComIntLIn;
        comAuxNew.cab.dgemp = comunicacao.cab.dgemp;
        comAuxNew.cab.isPrincipal = true;
        comAuxNew.isSameCodEmp = comunicacao.codEmpComIntLIn !== this.iColHome.codEmp;
        comAuxNew.cab.stampInsert = comunicacao.cab.stampInsert;
        comAuxNew.filhos = copy(comunicacao.filhos);
        this._expandAllChilds(comAuxNew.filhos);
        comAuxNew.temFilhosPorLer = this.verificaSeTemParaLer(comunicacao);
        tableItem.cabecalhosComInt.push(comAuxNew);
      }
    });
  }

  public onInitialized(event: IDevExpressDataGridEventOnInitialized<ITipoComunicacaoHome | IJsonProcessamentoEmpregadoSalarios | IDatas>, tableName: string): void {
    if (tableName === 'recibos') {
      this._dataGridInstanceRecibos = (<IDevExpressDataGridEventOnInitialized<IJsonProcessamentoEmpregadoSalarios>>event).component;
    }
    if (tableName === 'feriasMarcadas') {
      this._dataGridInstanceFeriasMarcadas = (<IDevExpressDataGridEventOnInitialized<IDatas>>event).component;
    }
    if (tableName === 'feriasRejeitadas') {
      this._dataGridInstanceFeriasRejeitadas = (<IDevExpressDataGridEventOnInitialized<IDatas>>event).component;
    }
    if (tableName === 'feriasIntegradas') {
      this._dataGridInstanceFeriasIntegradas = (<IDevExpressDataGridEventOnInitialized<IDatas>>event).component;
    }
    if (tableName === 'faltasMarcadas') {
      this._dataGridInstanceFaltasMarcadas = (<IDevExpressDataGridEventOnInitialized<IDatas>>event).component;
    }
    if (tableName === 'faltasRejeitadas') {
      this._dataGridInstanceFaltasRejeitadas = (<IDevExpressDataGridEventOnInitialized<IDatas>>event).component;
    }
    if (tableName === 'faltasIntegradas') {
      this._dataGridInstanceFaltasIntegradas = (<IDevExpressDataGridEventOnInitialized<IDatas>>event).component;
    }
  }

  public onComunicacaoSent(): void {
    this.comunicacaoActiveIdChange(this.comunicacaoActiveId);
  }

  private _getListRecibos(): Array<IJsonProcessamentoEmpregadoSalarios> {
    if (!this.iColHome.listaProcessamentos) {
      return [];
    }
    return this.iColHome.listaProcessamentos;
  }

  private _generateTableDefinition(caption: string, source: () => Array<IDatas>): IDevExpressDataGrid<IDatas> {
    return {
      columns: [{dataField: 'data', dataType: 'date', format: 'longDate', caption: caption, sortOrder: 'asc'}],
      dataSource: new CustomStore({
        load: source
      }),
      remoteOperations: false
    };
  }

  private _getListFeriasMarcadas(): Array<IDatas> {
    if (!this.iColHome.feriasMarcadas) {
      return [];
    }
    return this.iColHome.feriasMarcadas;
  }

  private _getListFeriasIntegradas(): Array<IDatas> {
    if (!this.iColHome.feriasIntegradas) {
      return [];
    }
    return this.iColHome.feriasIntegradas;
  }

  private _getListFeriasRejeitadas(): Array<IDatas> {
    if (!this.iColHome.feriasRejeitadas) {
      return [];
    }
    return this.iColHome.feriasRejeitadas;
  }

  private _refreshAndGetListComunicacoes(): Array<ITipoComunicacaoHome> {
    if (!this.iColHome.tpComunicacao) {
      return [];
    }
    const aData = moment();
    const dataDe = minDateCG();
    const dataAte = aData.clone().add(1, 'month').date(1);
    this.iColHome.tpComunicacao.forEach((item: ITipoComunicacaoHome) => {
      this.iColHome.newComunicationsCount = 0;
      this._serviceDGEMPS.getCountComunicacoesPorLer(this.iColHome.codEmp, dataDe, dataAte, item.codPRHTipoCom).then((data: HttpResponse<IJsonPRHNumber>) => {
        item.count = data.body.value;
        this.iColHome.newComunicationsCount += item.count;
      });
    });
    this.comunicacaoActiveIdChange(this.comunicacaoActiveId);
    return this.iColHome.tpComunicacao;
  }

  private _getListFaltasMarcadas(): Array<IDatas> {
    if (!this.iColHome.faltasMarcadas) {
      return [];
    }
    return this.iColHome.faltasMarcadas;
  }

  private _getListFaltasIntegradas(): Array<IDatas> {
    if (!this.iColHome.faltasIntegradas) {
      return [];
    }
    return this.iColHome.faltasIntegradas;
  }

  private _getListFaltasRejeitadas(): Array<IDatas> {
    if (!this.iColHome.faltasRejeitadas) {
      return [];
    }
    return this.iColHome.faltasRejeitadas;
  }

  private async _getListDocsPlata(): Promise<Array<IHomePRHDocsPlata>> {
    const responseDocsPlata: HttpResponse<Array<IJsonPRHDocsPlata>> = await this._serviceDGEMPS.getListaDocPlata(this.iColHome.codEmp, moment());
    this.iColHome.listDocsPlata = await Promise.all(
      responseDocsPlata.body.map(async (doc: IJsonPRHDocsPlata): Promise<IHomePRHDocsPlata> => {
        const url: string = await firstValueFrom(this._servicePRHDocsPlata.downloadDocUrl(doc.codPrhDocsPlata));
        return {...doc, url: url};
      })
    );
    return this.iColHome.listDocsPlata;
  }

  private _getListaMarcacoes(): Promise<IJsonDGEMPMarcacoes> {
    if (!this._promiseListaMarcacoes) {
      let promiseDetail: Promise<void>;
      if (!isNumber(this.iColHome.colaboradorDetail.codEmp)) {
        promiseDetail = this._serviceDGEMPS.getDetail(this.iColHome.codEmp).then((response: HttpResponse<IJsonDGEMPDetail>) => {
          this.iColHome.colaboradorDetail = {...response.body, nome: '', servicoPRH: ''};
        });
      }
      this._promiseListaMarcacoes = Promise.resolve(promiseDetail)
        .then(() => this._serviceDGEMPS.getListaMarcacoes(this.iColHome.codEmp, moment(this.dataDeFilter), moment(this.dataAteFilter)))
        .then((response: HttpResponse<IJsonDGEMPMarcacoes>) => response.body)
        .finally(() => {
          this._promiseListaMarcacoes = undefined;
        });
    }
    return this._promiseListaMarcacoes;
  }

  private _getEmptyComunicacaoInterna(): IJsonPRHComunicacaoInterna {
    return {
      cab: {
        comIntCabID: 0,
        titulo: '',
        texto: '',
        data: undefined,
        estadoLido: undefined,
        childrenVisible: false,
        comIntLinId: 0,
        dgemp: undefined,
        docID: '',
        tipoComInt: EComunicacaoInternaSystem.COMUNICACAO_INTERNA,
        nomeTipoComInt: undefined,
        codEmp: undefined,
        stampInsert: undefined,
        apelidoEmpregado: undefined,
        comIntCabIDPai: undefined,
        nomeEmpregado: undefined,
        isPrincipal: false
      },
      codEmpComIntLIn: 0,
      comIntLinID: 0,
      estadoLido: false,
      filhos: undefined,
      linhas: undefined,
      temFilhosPorLer: false,
      isSameCodEmp: false
    };
  }

  private _expandAllChilds(filhos: Array<IJsonPRHComunicacaoInterna>): void {
    filhos.forEach((filho) => {
      filho.cab.childrenVisible = true;
      if (filho.filhos?.length) {
        this._expandAllChilds(filho.filhos);
      }
    });
  }
}
