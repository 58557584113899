import moment from 'moment';
import {orderBy} from 'lodash-es';
import {from, mergeMap, Observable, of, Subscription, timer} from 'rxjs';
import {catchError} from 'rxjs/operators';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {
  downloadStream,
  IPlEditComponentOptionsInputAutocomplete,
  IPlNavWizardCallback,
  IPlNavWizardDefinition,
  IPlNavWizardEventBeforeChange,
  IPlNavWizardOptions,
  IPlNavWizardStep,
  IPlToolbarItem,
  IPlUploadCallback,
  isDefinedNotNull,
  isEmpty,
  isFunction,
  isNumber,
  isObject,
  normalizeAccents,
  PlAlertService
} from 'pl-comps-angular';
import {CGExceptionService} from '../../../components/exceptions/exceptions.service';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {CLOUD_PUBLICA_HORAS} from '../../../../common/resolvers/saft.resolvers';
import {
  EFilterPlanoContas,
  ETipoImportacaoSAFTContab,
  IFilterPlanoContas,
  IRestCmdSaftContabAddConvertConta,
  IRestCmdSaftContabGetAssociacaoDiarioList,
  IRestCmdSaftContabGetIsBalanceteSaldado,
  IRestCmdSaftContabLoadContasCorrentes,
  IRestCmdSaftContabLoadPlanoContasList,
  IRestCmdSaftContabLoadState,
  IRestCmdSaftContabProcessFile,
  IRestCmdSaftContabReplaceConvertConta,
  ISAFTContabConta,
  ISAFTContabPlanoContas,
  ISaftContabWizStepData,
  ISaftContabWizSteps,
  JOB_NOT_FOUND_ERROR
} from '../saftContab.module.interface';
import {
  ESAFTContabProgressOperation,
  ESAFTContabProgressState,
  IJsonSAFTContabContaCorrente,
  IJsonSAFTContabDiario,
  IJsonSAFTContabObjFullModel,
  IJsonSAFTContabPlanoContas,
  IJsonSAFTContabStatus
} from '../jsonSaftContab.module.interface';
import {EStatusCode, isTest} from '../../../../config/constants';
import {EStoreMode} from '../../../../common/enums/licenca.enums';
import {ETipoContaContabilidade} from '../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {hasAuthority} from '../../../../common/utils/roles.utils';
import {HookMatchCriteria, StateDeclaration, StateObject, Transition, TransitionService} from '@uirouter/core';
import {ICGExceptionError} from '../../../components/exceptions/exceptions.service.interface';
import {IControloIVA} from '../../../entities/controloiva/controloIVA.entity.interface';
import {IDevExpressDataGrid, type IDevExpressDataGridColumn} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnEditingStart,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnSaved
} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonDiario} from '../../../entities/diarios/jsonDiario.entity.interface';
import {IJsonPOC} from '../../../entities/pocs/jsonPOC.entity.interface';
import {ModuloComponent} from '../../../components/module/module.component';
import {ROLE} from '../../../services/role.const';
import {SaftContabConvertContasModalComponent} from '../modals/convertcontas/saftContab.convertContas.modal.component';
import {SaftContabImportConfigsModalComponent} from '../modals/importconfigs/saftContab.importConfigs.modal.component';
import {SaftContabImportModalComponent} from '../modals/import/saftContab.import.modal.component';
import {SaftContabProgressBarModalComponent} from '../modals/progressbar/saftContab.progressBar.modal.component';
import {SaftContabService} from '../saftContab.module.service';
import {STATE_NAME_DISCONNECTED} from '../../../states/account/disconnected/disconnected.state.interface';
import {STATE_NAME_LOGIN} from '../../../states/account/login/login.state.interface';
import {TDevExpressComparisonOperator, TDevExpressFilterExpression} from '../../../components/devexpress/datalayer/filtering/devexpress.datalayer.filtering.interface';
import {TDevExpressDataGridColumnCalculateFilterExpressionTarget} from '../../../components/devexpress/datagrid/devexpress.datagrid.types.interface';

const STEP_INDEX_IMPORT_TYPE = 0;
const STEP_INDEX_IMPORT_UPLOAD = 1;
const STEP_INDEX_ASSOCIACAO_DIARIOS = 2;
const STEP_INDEX_PLANO_CONTAS = 3;
const STEP_INDEX_CONTAS_CORRENTES = 4;
const STEP_INDEX_CONFIG_IMPORT = 5;

const TIMEOUT_JOB_STATUS = 3000;
const DIARIO_ABERTURA = 999;
const DESCRITIVO_SALDO_INICIAL = 13;

@Component({
  selector: 'module-saft-contab',
  templateUrl: './saftContab.module.component.html'
})
export class SaftContabModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public licencaStoreMode: EStoreMode;
  @Input() public importacaoSAFTMaxFileSize: number;
  @Input() public currentStatus: IJsonSAFTContabStatus;
  @Input() public balanceteSaldadoMessage: string;

  public readonly storeModes: typeof EStoreMode;
  public readonly isHoursBeforeSix: boolean;
  public readonly plUploadCallback: IPlUploadCallback;
  public readonly definitionNavWizard: IPlNavWizardDefinition;
  public readonly plWizardCallback: IPlNavWizardCallback;
  public readonly tipoImportacaoSAFTContab: typeof ETipoImportacaoSAFTContab;
  public readonly dataGridDefinitionAssociaoDiarios: IDevExpressDataGrid<IJsonSAFTContabDiario>;
  public readonly dataGridDefinitionPlanoContas: IDevExpressDataGrid<IJsonSAFTContabPlanoContas>;
  public readonly dataGridDefinitionContasCorrentes: IDevExpressDataGrid<IJsonSAFTContabContaCorrente>;
  public readonly filtersSource: Array<IFilterPlanoContas>;
  public readonly propertiesAutocompletePlanoContasNConta: IPlEditComponentOptionsInputAutocomplete;
  public readonly filterMovimento: string;
  public hasRoleContabilidadePremium: boolean;
  public filterPlanoContas: IFilterPlanoContas;
  public propertiesNavWizard: IPlNavWizardOptions;
  public wizSteps: ISaftContabWizSteps;
  public blockedByUser: boolean;
  public criaContaAll: boolean;
  public uploadUrl: string;
  public processing: boolean;
  public processingFilename: string;

  private readonly _btnImport: IPlToolbarItem;
  private readonly _btnExport: IPlToolbarItem;
  private _deRegisterOnStartFn: Function;
  private _importProgressModalRef: NgbModalRef;
  private _subscriptionImportChecker: Subscription;
  private _promiseImportChecker: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _saftContabService: SaftContabService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _cgExceptionService: CGExceptionService,
    protected readonly _transitionService: TransitionService
  ) {
    super(_injector);
    this.storeModes = EStoreMode;
    this.isHoursBeforeSix = moment().hours() < CLOUD_PUBLICA_HORAS;
    this.filterMovimento = `tipo=${ETipoContaContabilidade.Movimento}`;
    this.tipoImportacaoSAFTContab = ETipoImportacaoSAFTContab;
    this.filterPlanoContas = {valueMetodo: undefined, nameMetodo: ''};
    this.filtersSource = [
      {
        valueMetodo: EFilterPlanoContas.ContasComErro,
        nameMetodo: 'saftcontab.steps.planoContas.filters.contasComErro'
      },
      {
        valueMetodo: EFilterPlanoContas.ContasComConversao,
        nameMetodo: 'saftcontab.steps.planoContas.filters.contasComConversao'
      },
      {
        valueMetodo: EFilterPlanoContas.ContasSemConversao,
        nameMetodo: 'saftcontab.steps.planoContas.filters.contasSemConversao'
      }
    ];
    const dataGridDefinition: IDevExpressDataGrid = {
      dataSource: [],
      height: '60vh',
      columnHidingEnabled: false,
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      remoteOperations: false,
      scrolling: {rowRenderingMode: 'virtual'},
      filterRow: {visible: false},
      searchPanel: {visible: true}
    };
    this.dataGridDefinitionAssociaoDiarios = {
      ...dataGridDefinition,
      columns: [
        {
          caption: 'saftcontab.steps.fields.diarioOriem',
          allowFiltering: false,
          allowHeaderFiltering: false,
          columns: [
            {dataField: 'nDiario', caption: 'saftcontab.steps.fields.nDiario', dataType: 'number', allowEditing: false, alignment: 'left'},
            {dataField: 'nomeDiario', caption: 'saftcontab.steps.fields.nome', dataType: 'string', allowEditing: false}
          ]
        },
        {
          caption: 'saftcontab.steps.fields.diarioDestino',
          allowFiltering: false,
          allowHeaderFiltering: false,
          columns: [
            {dataField: 'nDiarioDestino', caption: 'saftcontab.steps.fields.nDiario', dataType: 'string', allowEditing: true, editCellTemplate: 'cellTemplateDiarioDestino'},
            {dataField: 'nomeDiarioDestino', caption: 'saftcontab.steps.fields.nome', dataType: 'string', allowEditing: false}
          ]
        }
      ],
      export: {filename: 'saftcontab.steps.associacaoDiarios.step'},
      editing: {
        allowDeleting: false,
        allowUpdating: true,
        mode: 'cell',
        selectTextOnEditStart: true,
        startEditAction: 'click'
      }
    };
    const self: SaftContabModuleComponent = this;
    this.dataGridDefinitionPlanoContas = {
      ...dataGridDefinition,
      columns: [
        {
          caption: 'saftcontab.steps.fields.contaOrigem',
          allowFiltering: false,
          allowHeaderFiltering: false,
          columns: [
            {dataField: 'nContaOrigem', caption: 'saftcontab.steps.fields.nConta', dataType: 'string', allowEditing: false},
            {dataField: 'nomeContaOrigem', caption: 'saftcontab.steps.fields.nome', dataType: 'string', allowEditing: false, width: 400}
          ]
        },
        {
          caption: 'saftcontab.steps.fields.contaDestino',
          allowFiltering: false,
          allowHeaderFiltering: false,
          columns: [
            {
              dataField: 'nContaDestino',
              caption: 'saftcontab.steps.fields.nConta',
              dataType: 'string',
              allowEditing: true,
              editCellTemplate: 'editCellTemplateNContaDestino',
              calculateFilterExpression: function (filterValue, selectedFilterOperation, target) {
                return self._calculateFilterExpression(this, <string>filterValue, <TDevExpressComparisonOperator>selectedFilterOperation, target);
              }
            },
            {
              dataField: 'nomeContaDestino',
              caption: 'saftcontab.steps.fields.nome',
              dataType: 'string',
              allowEditing: false,
              width: 400,
              calculateFilterExpression: function (filterValue, selectedFilterOperation, target) {
                return self._calculateFilterExpression(this, <string>filterValue, <TDevExpressComparisonOperator>selectedFilterOperation, target);
              }
            }
          ]
        },
        {
          dataField: 'criaConta',
          dataType: 'boolean',
          caption: 'saftcontab.steps.fields.criaConta',
          headerCellTemplate: 'headerCellTemplateCriaConta',
          editCellTemplate: 'cellTemplateCriaConta',
          alignment: 'center',
          allowEditing: true,
          showEditorAlways: true,
          allowHeaderFiltering: false,
          allowReordering: false,
          allowSorting: false
        },
        {
          dataField: 'codControlIvaDebito',
          dataType: 'string',
          caption: 'saftcontab.steps.fields.codControlIvaDebito',
          allowEditing: true,
          editCellTemplate: 'cellTemplateControlIvaDeb',
          calculateFilterExpression: function (filterValue, selectedFilterOperation, target) {
            return self._calculateFilterExpression(this, <string>filterValue, <TDevExpressComparisonOperator>selectedFilterOperation, target);
          }
        },
        {
          dataField: 'codControlIvaCredito',
          dataType: 'string',
          caption: 'saftcontab.steps.fields.codControlIvaCredito',
          allowEditing: true,
          editCellTemplate: 'cellTemplateControlIvaCred',
          calculateFilterExpression: function (filterValue, selectedFilterOperation, target) {
            return self._calculateFilterExpression(this, <string>filterValue, <TDevExpressComparisonOperator>selectedFilterOperation, target);
          }
        },
        {dataField: 'erro', caption: 'saftcontab.steps.fields.erro', dataType: 'string', allowEditing: false},
        {dataField: 'contaExistente', caption: 'saftcontab.steps.fields.contaExistente', dataType: 'boolean', allowEditing: false, visible: false},
        {dataField: 'convertConta', caption: 'saftcontab.steps.fields.convertConta', dataType: 'boolean', allowEditing: false, visible: false},
        {dataField: 'contasSemConversao', caption: 'saftcontab.steps.fields.contasSemConversao', dataType: 'boolean', allowEditing: false, visible: false},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnsPlanoContas', allowReordering: false, showInColumnChooser: false, width: 70}
      ],
      export: {filename: 'saftcontab.steps.planoContas.step'},
      editing: {allowUpdating: true, mode: 'cell', startEditAction: 'click'},
      filterPanel: {visible: true},
      height: '60vh',
      toolbar: {
        items: [
          {location: 'before', template: 'toolbarTemplateBeforePlanoContas', locateInMenu: 'auto'},
          {location: 'after', template: 'toolbarTemplateAfterPlanoContas', locateInMenu: 'auto'},
          'exportButton',
          'columnChooserButton',
          'searchPanel'
        ]
      }
    };
    this.dataGridDefinitionContasCorrentes = {
      ...dataGridDefinition,
      columns: [
        {dataField: 'nConta', caption: 'saftcontab.steps.fields.nConta', dataType: 'string', allowEditing: false},
        {dataField: 'nome', caption: 'saftcontab.steps.fields.nome', dataType: 'string', width: 450},
        {dataField: 'nContaDestino', caption: 'saftcontab.steps.fields.nContaDestino', dataType: 'string', allowEditing: false},
        {dataField: 'nif', caption: 'saftcontab.steps.fields.nif', dataType: 'string'},
        {dataField: 'mercado', caption: 'saftcontab.steps.fields.mercado', dataType: 'string', allowEditing: false},
        {dataField: 'erro', caption: 'saftcontab.steps.fields.erro', dataType: 'string', allowEditing: false}
      ],
      export: {filename: 'saftcontab.steps.contasCorrentes.step'},
      editing: {
        allowUpdating: true,
        mode: 'cell',
        startEditAction: 'click'
      },
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplateApenasContasMov',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton',
          'searchPanel'
        ]
      }
    };
    this.wizSteps = {
      importType: {
        valid: true,
        _needLoadNext: true,
        _index: STEP_INDEX_IMPORT_TYPE,
        tipoImport: ETipoImportacaoSAFTContab.ImportarExtratoComImportacaoSaldosIniciais
      },
      upload: {
        showUploader: true,
        showProcessFileProgressBar: false,
        processFileIntervalId: -1,
        valid: true,
        _needLoadNext: true,
        _index: STEP_INDEX_IMPORT_UPLOAD
      },
      associacaoDiarios: {
        valid: true,
        _needLoadNext: true,
        _index: STEP_INDEX_ASSOCIACAO_DIARIOS,
        list: []
      },
      planoContas: {
        valid: true,
        _needLoadNext: true,
        _index: STEP_INDEX_PLANO_CONTAS,
        list: []
      },
      contasCorrentes: {
        valid: true,
        _needLoadNext: true,
        _index: STEP_INDEX_CONTAS_CORRENTES,
        list: [],
        apenasContasMovimentadas: true,
        efetuaConversaoContasCorrentes: true
      },
      configImport: {
        valid: true,
        _needLoadNext: true,
        _index: STEP_INDEX_CONFIG_IMPORT,
        nDescritivo: undefined,
        nomeDescritivo: undefined,
        nDiarioApuramento: undefined,
        nomeDiarioApuramento: undefined,
        trocaGLPostingDateComTransactionDate: false,
        periodoSaldosIniciais: undefined,
        nDiarioSaldosIniciais: undefined,
        nomeDiarioSaldosIniciais: undefined,
        nDescritivoSaldosIniciais: undefined,
        nomeDescritivoSaldosIniciais: undefined,
        verificaBalancete: false,
        justificaContasCorrenteMovAb: false
      }
    };
    this.definitionNavWizard = {items: []};
    this.plWizardCallback = {};
    this.plUploadCallback = {};
    this.criaContaAll = false;
    this.propertiesNavWizard = {disableNavigation: false, disablePreviousStep: false, disableNextStep: false, disableFinalize: true};
    this._saftContabService.generateSaftContabUploadUrl().subscribe((url: string) => {
      this.uploadUrl = url;
    });
    this.processingFilename = '';
    this.processing = false;
    this._btnImport = {
      id: 'importConfig',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-download fa-fw"></i>',
      class: 'btn-primary',
      caption: 'global.btn.import',
      tooltip: {text: 'saftcontab.steps.upload.import', placement: 'bottom'},
      disabled: true,
      click: () => this._importConfig()
    };
    this._btnExport = {
      id: 'exportConfig',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-upload fa-fw"></i>',
      class: 'btn-success',
      caption: 'global.btn.export',
      tooltip: {text: 'saftcontab.steps.upload.export', placement: 'bottom', tooltipClass: 'tooltip-success'},
      disabled: true,
      click: () => this._exportConfig()
    };
    this.propertiesAutocompletePlanoContasNConta = {
      cssClassDropdownWrapper: 'saftcontab-autocomplete-dropdown'
    };

    this.validateImportType = this.validateImportType.bind(this);
    this.validateUpload = this.validateUpload.bind(this);
    this.getPlanoContas = this.getPlanoContas.bind(this);
    this.validatePlanoContas = this.validatePlanoContas.bind(this);
    this.validateContasCorrentes = this.validateContasCorrentes.bind(this);
    this.validateConfigImport = this.validateConfigImport.bind(this);
    this.importConfigContas = this.importConfigContas.bind(this);
    this.finalize = this.finalize.bind(this);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.hasRoleContabilidadePremium = hasAuthority(this.session, ROLE.CONTABILIDADEPREMIUM);
    if (this.currentStatus.currentOp !== ESAFTContabProgressOperation.Idle) {
      this.blockedByUser = this.currentStatus.userStartedId !== this.session.userId;
      if (!this.blockedByUser) {
        this._cgModalService
          .showOkCancel('saftcontab.modals.processing.title', 'saftcontab.messages.importProcessModalMessageCancelJob', {
            size: 'md',
            btnCancelText: 'saftcontab.modals.processing.processModalCancel',
            btnOkText: 'saftcontab.modals.processing.processModalLoad'
          })
          .then(() => {
            this._saftContabService
              .loadState({tipoImportacao: ETipoImportacaoSAFTContab.ImportarExtratoComImportacaoSaldosIniciais, associacaoDiarioList: [], planoContasList: []})
              .then((response: IRestCmdSaftContabLoadState) => {
                this.currentStatus.objModel = response.model;
                this._loadImportProcess(this.currentStatus);
              })
              .catch((reason) => {
                this._handleError(reason);
              });
          })
          .catch(() => {
            this.cancelProcess(STEP_INDEX_IMPORT_TYPE);
            this._uiReset(STEP_INDEX_IMPORT_TYPE);
            this._plAlertService.success(this._translateService.instant('importadorSaftDirect.messages.jobCancelled'));
          });
      }
    } else if (this.currentStatus.state === ESAFTContabProgressState.Error) {
      this._showCriticalError(this.currentStatus.description);
    }
    this.toolbar.addButton(this._btnImport).addButton(this._btnExport);
    setTimeout(() => {
      this._registerOnStart();
    });
  }

  public ngOnDestroy(): void {
    this._saftContabService.stopJob();
    window.clearInterval(this.wizSteps.upload.processFileIntervalId);
    this._clearImportChecker();
    this._deRegisterOnStart();
  }

  public cancelProcess(
    stepIndex: number,
    tipoImportacao: ETipoImportacaoSAFTContab = ETipoImportacaoSAFTContab.ImportarExtratoComImportacaoSaldosIniciais,
    showMessageJobCancelled: boolean = true
  ): void {
    this._uiReset(stepIndex, tipoImportacao);
    this._saftContabService.stopJob();
    if (tipoImportacao === ETipoImportacaoSAFTContab.ImportarExtratoComImportacaoSaldosIniciais && showMessageJobCancelled) {
      this._plAlertService.success(this._translateService.instant('saftcontab.messages.jobCancelled'));
    }
  }

  public diarioDestinoChanged(diario: IJsonDiario, item: IJsonSAFTContabDiario, component: dxDataGrid): Promise<boolean> {
    item.nDiarioDestino = isObject(diario) ? String(diario.nDiario) : '';
    item.nomeDiarioDestino = isObject(diario) ? diario.nome : '';

    return this._checkJobStatus(() => {
      return this._saftContabService
        .setAssociacaoDiarioList([item])
        .then(() => true)
        .catch((reason: HttpErrorResponse) => {
          item.nDiarioDestino = '';
          item.nomeDiarioDestino = '';
          this._handleError(reason);
          return Promise.resolve(false);
        });
    }, component);
  }

  public conversaoConta(type: 'add' | 'edit' | 'delete', item?: ISAFTContabPlanoContas): Promise<boolean> {
    return this._checkJobStatus(async () => {
      const listSemConv = this.wizSteps.planoContas.list.filter(
        (semConv: IJsonSAFTContabPlanoContas) => semConv.criaConta || !isEmpty(semConv.codControlIvaCredito) || !isEmpty(semConv.codControlIvaDebito)
      );
      if (type === 'edit' || type === 'add') {
        try {
          const responseTree = await this._saftContabService.getTreeDetail(undefined, item.nContaOrigem);
          const treeCG: Array<ISAFTContabConta> = this._generateTreeList(responseTree.treeDetail.treeCG);
          const treeSaft: Array<ISAFTContabConta> = this._generateTreeList(responseTree.treeDetail.treeSaft);
          item.treeDetail = {
            contasBase: responseTree.treeDetail.contasBase,
            treeCG: treeCG,
            treeSaft: treeSaft
          };
          const modalInstance = this._cgModalService.showVanilla(SaftContabConvertContasModalComponent);
          const componentInstance: SaftContabConvertContasModalComponent = modalInstance.componentInstance;
          const contaSemConvert = item?.nContaOrigem && type === 'add';
          componentInstance.treeDetail = item.treeDetail;
          componentInstance.nConta = item.nContaOrigem;
          componentInstance.contaSemConvert = contaSemConvert;
          componentInstance.listSemConv = listSemConv;
          componentInstance.convertConta = {
            nContaDestino: type === 'edit' ? item.nContaDestino : '',
            nomeContaDestino: type === 'edit' ? item.nomeContaDestino : '',
            nContaOrigem: type === 'edit' || contaSemConvert ? item.nContaOrigem : '',
            nomeContaOrigem: type === 'edit' || contaSemConvert ? item.nomeContaOrigem : '',
            erro: ''
          };
          try {
            const value = await modalInstance.result;
            if (isDefinedNotNull(value)) {
              this._applyPlanoContas(value.slice());
            }
            return true;
          } catch (reason: unknown) {
            this._logger.error(reason);
            return false;
          }
        } catch (reason) {
          this._handleError(reason);
          return Promise.resolve(false);
        }
      } else if (type === 'delete') {
        try {
          const value1 = await this._saftContabService.deleteConvertConta([], listSemConv, item.nContaOrigem);
          if (isDefinedNotNull(value1.list)) {
            this._applyPlanoContas(value1.list.slice());
          }
          return true;
        } catch (reason1) {
          this._handleError(reason1);
          return false;
        }
      }
      return false;
    });
  }

  public replaceContaExistent(item: ISAFTContabPlanoContas): Promise<boolean> {
    return this._checkJobStatus(async () => {
      const listSemConv = this.wizSteps.planoContas.list.filter(
        (semConv: IJsonSAFTContabPlanoContas) => semConv.criaConta || !isEmpty(semConv.codControlIvaCredito) || !isEmpty(semConv.codControlIvaDebito)
      );
      const modalInstance = this._cgModalService.showVanilla(SaftContabConvertContasModalComponent);
      const componentInstance: SaftContabConvertContasModalComponent = modalInstance.componentInstance;
      componentInstance.contaExistente = true;
      componentInstance.listSemConv = listSemConv;
      componentInstance.convertConta = {
        nContaDestino: item.nContaDestino,
        nomeContaDestino: item.nomeContaDestino,
        nContaOrigem: item.nContaOrigem,
        nomeContaOrigem: item.nomeContaOrigem,
        erro: ''
      };
      try {
        const value: Array<IJsonSAFTContabPlanoContas> = await modalInstance.result;
        if (value) {
          this._applyPlanoContas(value);
        }
        return true;
      } catch {
        return false;
      }
    });
  }

  public async addContasSemConvert(item: ISAFTContabPlanoContas, component: dxDataGrid): Promise<void> {
    component.beginCustomLoading(undefined);
    try {
      await this.conversaoConta('add', item);
    } finally {
      component.endCustomLoading();
    }
  }

  public async controloIVAChanged(controloIva: IControloIVA, item: ISAFTContabPlanoContas, debito: boolean, component: dxDataGrid): Promise<void> {
    if (debito) {
      item.codControlIvaDebito = isObject(controloIva) ? controloIva.codControloIVA : '';
    } else {
      item.codControlIvaCredito = isObject(controloIva) ? controloIva.codControloIVA : '';
    }

    try {
      await this.updateContaSemConvert(item, component);
    } catch (reason: unknown) {
      this._logger.error(reason);
      if (debito) {
        item.codControlIvaDebito = '';
      } else {
        item.codControlIvaCredito = '';
      }
    }
  }

  public updateContaSemConvert(item: ISAFTContabPlanoContas, component: dxDataGrid): Promise<boolean> {
    return this._checkJobStatus(async () => {
      try {
        await this._saftContabService.addSemConvertConta([], item.nContaOrigem, item.nomeContaOrigem, item.criaConta, item.erro, item.codControlIvaDebito, item.codControlIvaCredito);
        return true;
      } catch (reason) {
        this._handleError(reason);
        return false;
      }
    }, component);
  }

  public criaContaAllChanged(component: dxDataGrid): Promise<boolean> {
    return this._checkJobStatus(async () => {
      try {
        const responseList = await this._saftContabService.marcarTodosParaCriar([], this.criaContaAll);
        this._applyPlanoContas(responseList.list);
        return true;
      } catch (reason) {
        this._handleError(reason);
        return false;
      }
    }, component);
  }

  public onInitializedPlanoContas({component}: IDevExpressDataGridEventOnInitialized): void {
    this.wizSteps.planoContas._dataGridInstance = component;
    this.wizSteps.planoContas._dataGridInstance.filter(['contaExistente', '=', true]);
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<ISAFTContabPlanoContas>): void {
    if (event.rowType === 'data') {
      if (!isEmpty(event.data.erro)) {
        event.cellElement.classList.add('text-danger');
      }

      if (event.column.dataField === 'criaConta') {
        if (!isEmpty(event.data.erro) || (!isEmpty(event.data.nContaOrigem) && !isEmpty(event.data.nContaDestino) && !event.data.contasSemConversao)) {
          event.cellElement.classList.add('hide-element');
        }
      }
    }
  }

  public onEditorPreparingPlanoContas(event: IDevExpressDataGridEventOnEditingStart<ISAFTContabPlanoContas>): void {
    if ((event.column.dataField === 'codControlIvaDebito' || event.column.dataField === 'codControlIvaCredito') && !event.data.contasSemConversao) {
      event.cancel = true;
    }
  }

  public async onSavedContasCorrentes(event: IDevExpressDataGridEventOnSaved<IJsonSAFTContabContaCorrente>): Promise<boolean> {
    event.component.beginCustomLoading(undefined);
    try {
      return await this._checkJobStatus(async () => {
        try {
          const responseCorrentes = await this._saftContabService.updateContasCorrentes([], event.changes[0].data.nConta, event.changes[0].data.nif);
          this.wizSteps.contasCorrentes.list = responseCorrentes.list;
          return true;
        } catch (reason) {
          this._handleError(reason);
          return false;
        }
      });
    } finally {
      event.component.endCustomLoading();
    }
  }

  public finalize(): Promise<void> {
    this._wizNavControl(true, true);
    // this._uiReset(STEP_INDEX_IMPORT_TYPE);
    this.cancelProcess(STEP_INDEX_IMPORT_TYPE, ETipoImportacaoSAFTContab.ImportarExtratoComImportacaoSaldosIniciais, false);
    return Promise.resolve();
  }

  public validateImportType(): Promise<boolean> {
    this.wizSteps.importType.valid = isNumber(this.wizSteps.importType.tipoImport);
    if (!this.wizSteps.importType.valid) {
      this._plAlertService.error('saftcontab.messages.noTypeImport');
      return Promise.resolve(false);
    }

    return Promise.resolve(true);
  }

  public async validateUpload(): Promise<boolean> {
    this._wizNavControl(false);
    try {
      return await new Promise<boolean>((resolve) => {
        if (!this.processing) {
          this.wizSteps.upload.valid = false;

          if (!this.plUploadCallback.getAcceptedFiles().length) {
            this._plAlertService.error('saftcontab.messages.noFiles');
            this._wizNavControl(true);
            resolve(false);
            return;
          }

          this.plUploadCallback
            .uploadAll()
            .then(() => {
              this._saftContabService
                .processFile('')
                .then((processFileResponse: IRestCmdSaftContabProcessFile) => {
                  this.processingFilename = processFileResponse.filename;
                  this._runProcessFileChecker()
                    .then(() => {
                      resolve(true);
                    })
                    .catch((reason: unknown) => {
                      this._saftContabService.stopJob();
                      this._logger.error(reason);
                      resolve(false);
                    })
                    .finally(() => {
                      this._wizNavControl(true);
                    });
                })
                .catch((reason: HttpErrorResponse) => {
                  this._handleError(reason);
                  resolve(false);
                })
                .finally(() => {
                  this._wizNavControl(true);
                });
            })
            .catch((reason: unknown) => {
              this._logger.error(reason);
              this.wizSteps.upload.showProcessFileProgressBar = false;
              resolve(false);
            })
            .finally(() => {
              this._wizNavControl(true);
            });
        } else {
          this.wizSteps.upload.valid = true;
          resolve(true);
        }
      });
    } finally {
      this._wizNavControl(true);
    }
  }

  public getPlanoContas(): Promise<boolean> {
    return this._checkJobStatus(() => {
      return this._saftContabService
        .getPlanoContas([])
        .then((responseContas: IRestCmdSaftContabLoadPlanoContasList) => {
          this._applyPlanoContas(responseContas.list);
          return Promise.resolve(true);
        })
        .catch((reason: HttpErrorResponse) => {
          this._handleError(reason);
          return Promise.resolve(false);
        });
    });
  }

  public validatePlanoContas(): Promise<boolean> {
    this.wizSteps.planoContas.valid = true;
    for (const item of this.wizSteps.planoContas.list) {
      if (!isEmpty(item.erro)) {
        this.wizSteps.planoContas.valid = false;
        // convertContas
        if (!isEmpty(item.nContaOrigem) && !isEmpty(item.nContaDestino) && item.nContaOrigem !== item.nContaDestino) {
          this._plAlertService.error('saftcontab.messages.accountsWithError');
          return Promise.resolve(false);
        }

        // contasExistentes
        if (!isEmpty(item.nContaOrigem) && !isEmpty(item.nContaDestino) && item.nContaOrigem === item.nContaDestino) {
          this._plAlertService.error('saftcontab.messages.accountsWithError');
          return Promise.resolve(false);
        }

        // contasSemConvert
        if (!isEmpty(item.nContaOrigem) && isEmpty(item.nContaDestino)) {
          this._plAlertService.error('saftcontab.messages.accountsWithError');
          return Promise.resolve(false);
        }
      }

      // contasSemConvert
      if (!isEmpty(item.nContaOrigem) && isEmpty(item.nContaDestino) && !item.criaConta) {
        this.wizSteps.planoContas.valid = false;
        this._plAlertService.error('saftcontab.messages.accountsWithoutSel');
        return Promise.resolve(false);
      }
    }

    return this._checkJobStatus(() => {
      return this._saftContabService
        .loadContasCorrentes([])
        .then((responseCorrentes: IRestCmdSaftContabLoadContasCorrentes) => {
          this.wizSteps.contasCorrentes.list = responseCorrentes.list;
          return Promise.resolve(true);
        })
        .catch((reason: HttpErrorResponse) => {
          this._handleError(reason);
          return Promise.resolve(false);
        });
    });
  }

  public async validateContasCorrentes(): Promise<boolean> {
    if (this.wizSteps.contasCorrentes.list.find((item: IJsonSAFTContabContaCorrente) => !isEmpty(item.erro))) {
      this._plAlertService.error('saftcontab.messages.accountsWithError');
      return false;
    }

    const canContinue =
      this.wizSteps.importType.tipoImport === ETipoImportacaoSAFTContab.ImportarApenasContasCorrentes
        ? await this._doImport(
            this.wizSteps.contasCorrentes.apenasContasMovimentadas,
            this.wizSteps.configImport.verificaBalancete,
            false,
            0,
            0,
            0,
            '',
            0,
            false,
            this.wizSteps.contasCorrentes.efetuaConversaoContasCorrentes
          )
        : true;

    if (this.wizSteps.importType.tipoImport === ETipoImportacaoSAFTContab.ImportarApenasContasCorrentes && canContinue) {
      this.propertiesNavWizard = {disableNavigation: true, disableFinalize: false, vertical: false};
      this.definitionNavWizard.items[STEP_INDEX_CONFIG_IMPORT].hidePrevious = true;
    }
    return canContinue;
  }

  public async validateConfigImport(): Promise<boolean> {
    let wizValid = true;
    if (
      this.wizSteps.importType.tipoImport === ETipoImportacaoSAFTContab.ImportarExtratoComImportacaoSaldosIniciais ||
      this.wizSteps.importType.tipoImport === ETipoImportacaoSAFTContab.ImportarSoSaldosIniciais
    ) {
      if (!this.wizSteps.configImport.periodoSaldosIniciais) {
        wizValid = false;
        this._plAlertService.error('saftcontab.steps.configImport.messages.periodoSaldosIniciaisError');
      }
      if (!this.wizSteps.configImport.nDiarioSaldosIniciais) {
        wizValid = false;
        this._plAlertService.error('saftcontab.steps.configImport.messages.nDiarioSaldosIniciaisError');
      }
      if (!this.wizSteps.configImport.nDescritivoSaldosIniciais) {
        wizValid = false;
        this._plAlertService.error('saftcontab.steps.configImport.messages.nDescritivoSaldosIniciaisError');
      }
    }
    if (!wizValid) {
      this.wizSteps.configImport.valid = false;
      return false;
    }

    this.wizSteps.configImport.valid = true;
    const canContinue = await this._doImport(
      this.wizSteps.contasCorrentes.apenasContasMovimentadas,
      this.wizSteps.configImport.verificaBalancete,
      this.wizSteps.configImport.trocaGLPostingDateComTransactionDate,
      this.wizSteps.configImport.nDiarioApuramento,
      this.wizSteps.configImport.nDescritivo,
      this.wizSteps.configImport.nDescritivoSaldosIniciais,
      this.wizSteps.configImport.periodoSaldosIniciais,
      this.wizSteps.configImport.nDiarioSaldosIniciais,
      this.wizSteps.configImport.justificaContasCorrenteMovAb,
      this.wizSteps.contasCorrentes.efetuaConversaoContasCorrentes
    );
    if (canContinue) {
      this.propertiesNavWizard = {disableNavigation: true, disableFinalize: false, vertical: false};
      this.definitionNavWizard.items[STEP_INDEX_CONFIG_IMPORT].hidePrevious = true;
    }
    return canContinue;
  }

  public async importConfigContas(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(SaftContabImportModalComponent, {size: 'lg'});
    const componentInstance: SaftContabImportModalComponent = modalInstance.componentInstance;
    componentInstance.planoContas = this.wizSteps.planoContas._dataGridInstance.getDataSource().items();
    await modalInstance.result;
    await this.getPlanoContas();
  }

  public async importTypeChanged(importType: ETipoImportacaoSAFTContab): Promise<void> {
    if (this.wizSteps.importType.tipoImport === importType) {
      return Promise.resolve();
    }
    if (this.processing) {
      await this._cgModalService.showOkCancel('saftcontab.leavePromptTitle', 'saftcontab.importTypePromptMessage', {
        size: 'md',
        backdrop: 'static',
        keyboard: false,
        btnOkText: 'global.btn.yes',
        btnCancelText: 'global.btn.no'
      });
      this.cancelProcess(STEP_INDEX_IMPORT_TYPE, importType);
    }
    this.wizSteps.importType.tipoImport = importType;
    return Promise.resolve();
  }

  public changedStep(currentStep: IPlNavWizardStep): void {
    this._btnImport.disabled = currentStep.stepId !== 'wizStepAssociacaoDiarios';
    this._btnExport.disabled = currentStep.stepId === 'wizStepImportType' || currentStep.stepId === 'wizStepImportType';
    if (currentStep.stepId === 'wizStepContasCorrentes') {
      if (
        this.wizSteps.importType.tipoImport === ETipoImportacaoSAFTContab.ImportarExtratoComImportacaoSaldosIniciais ||
        this.wizSteps.importType.tipoImport === ETipoImportacaoSAFTContab.ImportarSoSaldosIniciais
      ) {
        this.wizSteps.configImport.periodoSaldosIniciais = !this.wizSteps.configImport.periodoSaldosIniciais
          ? `${this.configurations.empresa.anoEmCursoIRC}010`
          : this.wizSteps.configImport.periodoSaldosIniciais;
        this.wizSteps.configImport.nDiarioSaldosIniciais = !this.wizSteps.configImport.nDiarioSaldosIniciais ? DIARIO_ABERTURA : this.wizSteps.configImport.nDiarioSaldosIniciais;
        this.wizSteps.configImport.nomeDiarioSaldosIniciais = !this.wizSteps.configImport.nomeDiarioSaldosIniciais
          ? this._translateService.instant('saftcontab.steps.configImport.fields.diaroAbertura')
          : this.wizSteps.configImport.nomeDiarioSaldosIniciais;
        this.wizSteps.configImport.nDescritivoSaldosIniciais = !this.wizSteps.configImport.nDescritivoSaldosIniciais ? DESCRITIVO_SALDO_INICIAL : this.wizSteps.configImport.nDescritivoSaldosIniciais;
        this.wizSteps.configImport.nomeDescritivoSaldosIniciais = !this.wizSteps.configImport.nomeDescritivoSaldosIniciais
          ? this._translateService.instant('saftcontab.steps.configImport.fields.descritivoSaldosIniciais')
          : this.wizSteps.configImport.nomeDescritivoSaldosIniciais;
      }

      this.wizSteps.configImport.justificaContasCorrenteMovAb =
        this.wizSteps.importType.tipoImport === ETipoImportacaoSAFTContab.ImportarExtratoComImportacaoSaldosIniciais ||
        this.wizSteps.importType.tipoImport === ETipoImportacaoSAFTContab.ImportarExtratoSemImportacaoSaldosIniciais;
    }
  }

  public setPlanoContas(item: IFilterPlanoContas): void {
    this.filterPlanoContas = item ? item : {valueMetodo: undefined, nameMetodo: ''};
    let filterValue: TDevExpressFilterExpression;
    switch (this.filterPlanoContas.valueMetodo) {
      case EFilterPlanoContas.ContasComErro:
        filterValue = ['erro', '<>', ''];
        break;
      case EFilterPlanoContas.ContasComConversao:
        filterValue = [['contaExistente', '=', true], 'or', ['convertConta', '=', true]];
        break;
      case EFilterPlanoContas.ContasSemConversao:
        filterValue = ['contasSemConversao', '=', true];
        break;
      default:
        filterValue = [];
        break;
    }

    this.wizSteps.planoContas._dataGridInstance.option('filterValue', filterValue);
  }

  public nContaDestinoChanged(poc: IJsonPOC, item: ISAFTContabPlanoContas, component: dxDataGrid): Promise<boolean> {
    item.nContaDestino = poc.nConta;
    item.nomeContaDestino = poc.nome;
    const listSemConv = this.wizSteps.planoContas.list.filter(
      (semConv: IJsonSAFTContabPlanoContas) => semConv.criaConta || !isEmpty(semConv.codControlIvaCredito) || !isEmpty(semConv.codControlIvaDebito)
    );
    return this._checkJobStatus(async () => {
      if (item.contaExistente) {
        try {
          const responseConvertExistent: IRestCmdSaftContabAddConvertConta = await this._saftContabService.addConvertConta([], listSemConv, item.nContaOrigem, item.nContaDestino);
          this._applyPlanoContas(responseConvertExistent.list.slice());
          return true;
        } catch (reason) {
          this._handleError(reason);
          return false;
        }
      }
      if (!isEmpty(item.nContaOrigem) && !item.contasSemConversao) {
        try {
          const responseReplaceConvertConta: IRestCmdSaftContabReplaceConvertConta = await this._saftContabService.replaceConvertConta([], listSemConv, item.nContaOrigem, item.nContaDestino);
          this._applyPlanoContas(responseReplaceConvertConta.list.slice());
          return true;
        } catch (reason1) {
          this._handleError(reason1);
          return false;
        }
      }
      try {
        const response2: IRestCmdSaftContabAddConvertConta = await this._saftContabService.addConvertConta([], listSemConv, item.nContaOrigem, item.nContaDestino);
        this._applyPlanoContas(response2.list.slice());
        return true;
      } catch (reason2) {
        this._handleError(reason2);
        return false;
      }
    }, component);
  }

  public readonly fnImportTypeChanged = (importType: ETipoImportacaoSAFTContab): Promise<void> => this.importTypeChanged(importType);

  public readonly fnBeforeStepChange = (event: IPlNavWizardEventBeforeChange): boolean => this._beforeStepChange(event);

  protected _onPageUnload(): void {
    super._onPageUnload();
    this._saftContabService.stopJob();
  }

  private _loadFullModel(model: IJsonSAFTContabObjFullModel): void {
    this.wizSteps.importType.valid = true;
    this.wizSteps.importType.tipoImport = model.tipoImportacao;

    this.wizSteps.associacaoDiarios.valid = true;
    this.wizSteps.associacaoDiarios.list = orderBy(model.associacaoDiarioList, 'nDiario', 'asc');

    this.wizSteps.planoContas.valid = true;
    this._applyPlanoContas(model.planoContasList);
  }

  private _loadImportProcess(status: IJsonSAFTContabStatus): void {
    this._loadFullModel(status.objModel);
    let stepIndex = this._getStepIndexOfCurrentOp(status.currentOp, status.state);
    for (let i = 0; i <= stepIndex; i++) {
      const obj: ISaftContabWizStepData = this._getWizStepsByIndex(i);
      if (i < stepIndex) {
        this.definitionNavWizard.items[i].visited = true;
        this.definitionNavWizard.items[i].setComplete();
      }
      if (obj) {
        obj._needLoadNext = i === stepIndex;
      }
    }

    if (status.currentOp === ESAFTContabProgressOperation.SaveConfiguracoesDiarios || status.currentOp === ESAFTContabProgressOperation.SaveCorrespondenciaContas) {
      if (status.currentOp === ESAFTContabProgressOperation.SaveConfiguracoesDiarios) {
        stepIndex = STEP_INDEX_ASSOCIACAO_DIARIOS;
      } else if (status.currentOp === ESAFTContabProgressOperation.SaveCorrespondenciaContas) {
        stepIndex = STEP_INDEX_PLANO_CONTAS;
      }
    } else if (status.currentOp === ESAFTContabProgressOperation.ProcessFile) {
      this._runProcessFileChecker();
    }
    this.plWizardCallback.setStep(this.definitionNavWizard.items[stepIndex], true);
  }

  private _getStepIndexOfCurrentOp(currentOp: ESAFTContabProgressOperation, state: ESAFTContabProgressState): number {
    switch (currentOp) {
      case ESAFTContabProgressOperation.ProcessFile:
        return state === ESAFTContabProgressState.Started ? STEP_INDEX_IMPORT_UPLOAD : STEP_INDEX_ASSOCIACAO_DIARIOS;
      case ESAFTContabProgressOperation.SaveConfiguracoesDiarios:
        return STEP_INDEX_ASSOCIACAO_DIARIOS;
      case ESAFTContabProgressOperation.SaveCorrespondenciaContas:
        return STEP_INDEX_PLANO_CONTAS;
      default:
        return STEP_INDEX_IMPORT_UPLOAD;
    }
  }

  private _getWizStepsByIndex(stepIndex: number): ISaftContabWizStepData {
    for (const prop of Object.keys(this.wizSteps)) {
      if (this.wizSteps[prop]._index === stepIndex) {
        return this.wizSteps[prop];
      }
    }
    return undefined;
  }

  private _uiReset(activeWizStepIndex: number, tipoImportacao: ETipoImportacaoSAFTContab = ETipoImportacaoSAFTContab.ImportarExtratoComImportacaoSaldosIniciais): void {
    this.processing = false;
    window.clearInterval(this.wizSteps.upload.processFileIntervalId);
    this.wizSteps.upload.showUploader = true;
    this.wizSteps.upload.showProcessFileProgressBar = false;
    if (isFunction(this.plUploadCallback.removeAllFiles)) {
      this.plUploadCallback.removeAllFiles();
    }
    this.definitionNavWizard.items.forEach((item: IPlNavWizardStep) => {
      item.visited = false;
      item.setIncomplete();
    });
    const model: IJsonSAFTContabObjFullModel = {
      tipoImportacao: tipoImportacao,
      associacaoDiarioList: [],
      planoContasList: []
    };
    this._loadFullModel(model);
    if (activeWizStepIndex > 0) {
      for (let i = 0; i < activeWizStepIndex; i++) {
        this.definitionNavWizard.items[i].visited = true;
        this.definitionNavWizard.items[i].setComplete();
      }
    }

    this.definitionNavWizard.items[activeWizStepIndex].visited = true;
    this.plWizardCallback.setStep(this.definitionNavWizard.items[activeWizStepIndex], true);
  }

  private _handleError(reason: HttpErrorResponse, configImport: boolean = false): void {
    if (!reason) {
      return;
    }
    const exception: ICGExceptionError = this._cgExceptionService.get(reason);
    if (exception.status === EStatusCode.InternalServerError && exception.message === JOB_NOT_FOUND_ERROR) {
      this._cgModalService
        .showOkCancel('global.text.error', 'saftcontab.messages.timeoutModalMessage', {
          size: 'md',
          showCancelBtn: false,
          btnOkText: 'Ok',
          backdrop: 'static',
          keyboard: false
        })
        .then(() => {
          this._uiReset(STEP_INDEX_IMPORT_TYPE);
        });
    } else if (configImport) {
      this._cgModalService.showOkCancel('global.text.error', exception.message, {
        size: 'md',
        showCancelBtn: false,
        btnOkText: 'Ok',
        backdrop: 'static',
        keyboard: false
      });
    } else {
      this._plAlertService.error(exception.message);
    }
  }

  private _showCriticalError(erro: string): void {
    this._cgModalService
      .showOkCancel('global.text.error', erro, {
        size: 'md',
        showCancelBtn: false,
        btnOkText: 'Ok',
        backdrop: 'static',
        keyboard: false
      })
      .then(() => {
        this._uiReset(STEP_INDEX_IMPORT_TYPE);
      });
  }

  private _wizNavControl(nav: boolean, disableFinalize: boolean = true): void {
    this.propertiesNavWizard = {disableNavigation: !nav, disableFinalize: disableFinalize};
  }

  private _runProcessFileChecker(): Promise<void> {
    this.wizSteps.upload.showUploader = false;
    this.wizSteps.upload.showProcessFileProgressBar = true;
    return new Promise<void>((resolve, reject) => {
      this.wizSteps.upload.processFileIntervalId = window.setInterval(() => {
        this._saftContabService
          .getJobStatus()
          .then((response: IJsonSAFTContabStatus) => {
            if (this._isAlive(response.state)) {
              if (response.currentOp === ESAFTContabProgressOperation.ProcessFile || response.currentOp === ESAFTContabProgressOperation.Idle) {
                this._processFileCheck(response, resolve, reject);
              }
            } else {
              window.clearInterval(this.wizSteps.upload.processFileIntervalId);
            }
          })
          .catch((reason: HttpErrorResponse) => {
            reject(reason);
            this._handleError(reason);
            window.clearInterval(this.wizSteps.upload.processFileIntervalId);
            this.wizSteps.upload.processFileIntervalId = undefined;
          });
      }, TIMEOUT_JOB_STATUS);
    });
  }

  private _isAlive(state: ESAFTContabProgressState): boolean {
    if (state === ESAFTContabProgressState.Timeout) {
      this._cgModalService.showOkCancel('saftcontab.strings.jobTimeoutModalTitle', 'saftcontab.messages.jobTimeoutModalMessage', {
        size: 'md',
        showCancelBtn: false,
        backdrop: 'static',
        keyboard: false,
        showCloseBtn: false
      });
      this._uiReset(STEP_INDEX_IMPORT_TYPE);
      return false;
    }
    return true;
  }

  private _processFileCheck(status: IJsonSAFTContabStatus, resolve: Function, reject: Function): void {
    if (status.state === ESAFTContabProgressState.Error) {
      this._showCriticalError(status.description);
      this.wizSteps.upload.showUploader = true;
      this.wizSteps.upload.showProcessFileProgressBar = false;
      this._plAlertService.error(status.description);
      window.clearInterval(this.wizSteps.upload.processFileIntervalId);
      reject();
    } else if (status.position === status.max) {
      window.clearInterval(this.wizSteps.upload.processFileIntervalId);
      this.processing = true;
      this.processingFilename = status.filename;
      this.wizSteps.upload.valid = true;

      if (!this.wizSteps.upload._needLoadNext) {
        resolve();
        return;
      }

      if (this.wizSteps.importType.tipoImport === ETipoImportacaoSAFTContab.ImportarApenasContasCorrentes) {
        this._saftContabService
          .loadContasCorrentes([])
          .then((response: IRestCmdSaftContabLoadContasCorrentes) => {
            if (this._isAlive(response.jobState)) {
              this.processing = true;
              this.wizSteps.contasCorrentes.list = response.list;
              this.plWizardCallback.setStep('wizStepContasCorrentes', true);
              resolve();
            } else {
              reject();
            }
          })
          .catch((reason: HttpErrorResponse) => {
            this._handleError(reason);
            reject(reason);
          });
      } else if (this.wizSteps.importType.tipoImport === ETipoImportacaoSAFTContab.ImportarSoSaldosIniciais) {
        this._saftContabService
          .getPlanoContas([])
          .then((responseContas: IRestCmdSaftContabLoadPlanoContasList) => {
            if (this._isAlive(responseContas.jobState)) {
              this.processing = true;
              this._applyPlanoContas(responseContas.list);
              this.plWizardCallback.setStep('wizStepPlanoContas', true);
              resolve();
            } else {
              reject();
            }
          })
          .catch((reason: HttpErrorResponse) => {
            this._handleError(reason);
            reject(reason);
          });
      } else {
        this._saftContabService
          .getAssociacaoDiarioList([])
          .then((response: IRestCmdSaftContabGetAssociacaoDiarioList) => {
            if (this._isAlive(response.jobState)) {
              this.processing = true;
              this.wizSteps.associacaoDiarios.list = orderBy(response.list, 'nDiario', 'asc');
              this.plWizardCallback.setStep(this.definitionNavWizard.items[STEP_INDEX_ASSOCIACAO_DIARIOS], true);

              this._saftContabService.getIsBalanceteSaldado().then((responseSaldado: IRestCmdSaftContabGetIsBalanceteSaldado) => {
                if (!responseSaldado.balanceteSaldado.balanceteSaldado) {
                  const message = this._translateService.instant('saftcontab.modals.balanceteNaoSaldado', {
                    debito: responseSaldado.balanceteSaldado.totalDebito,
                    credito: responseSaldado.balanceteSaldado.totalCredito
                  });
                  this._cgModalService
                    .showOkCancel('global.text.warning', message)
                    .then(() => {
                      this.wizSteps.configImport.verificaBalancete = false;
                    })
                    .catch(() => {
                      this._uiReset(STEP_INDEX_IMPORT_TYPE);
                    });
                } else {
                  this.wizSteps.configImport.verificaBalancete = responseSaldado.balanceteSaldado.balanceteSaldado;
                }
              });
              resolve();
            } else {
              reject();
            }
          })
          .catch((reason: HttpErrorResponse) => {
            this._handleError(reason);
            reject(reason);
          });
      }
    }
  }

  private _generateTreeList(list: Array<ISAFTContabConta>): Array<ISAFTContabConta> {
    let lastLevel = 0;
    let lastNConta = '0';
    for (let i = 0; i <= list.length - 1; i++) {
      if (lastLevel !== list[i].level) {
        list[i].parent = lastNConta;
        lastNConta = list[i].nconta;
        lastLevel = list[i].level;
      } else {
        list[i].parent = list[i - 1].parent;
      }
    }
    return list;
  }

  private async _checkJobStatus(callback: () => Promise<boolean>, component?: dxDataGrid): Promise<boolean> {
    if (component) {
      component.beginCustomLoading(undefined);
    }
    this._wizNavControl(false);
    try {
      const response = await this._saftContabService.getJobStatus();
      if (this._isAlive(response.state)) {
        return await callback();
      }
      return false;
    } catch (reason: unknown) {
      this._logger.error(reason);
      if (reason instanceof HttpErrorResponse) {
        this._handleError(reason);
      }
      return false;
    } finally {
      this._wizNavControl(true);
      if (component) {
        component.endCustomLoading();
      }
    }
  }

  private async _importConfig(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(SaftContabImportConfigsModalComponent);
    const componentInstance: SaftContabImportConfigsModalComponent = modalInstance.componentInstance;
    componentInstance.convertContas = false;
    await modalInstance.result;
    this._saftContabService.getAssociacaoDiarioList([]).then((response: IRestCmdSaftContabGetAssociacaoDiarioList) => {
      if (this._isAlive(response.jobState)) {
        this.wizSteps.associacaoDiarios.list = orderBy(response.list, 'nDiario', 'asc');
      }
    });
  }

  private _exportConfig(): Promise<unknown> {
    this._btnExport.promise = this._saftContabService
      .saftContabExportConfig()
      .then((blob: HttpResponse<Blob>) => {
        if (!blob) {
          this._plAlertService.error(this._translateService.instant('saftcontab.messages.erroDownload'));
        } else {
          downloadStream(blob);
        }
      })
      .finally(() => {
        return undefined;
      });
    return this._btnExport.promise;
  }

  private _applyPlanoContas(planoContasList: Array<IJsonSAFTContabPlanoContas>): void {
    this.wizSteps.planoContas.list = orderBy(planoContasList, 'nContaOrigem', 'asc');
  }

  private _calculateFilterExpression(
    column: IDevExpressDataGridColumn,
    filterValue: string,
    selectedFilterOperation: TDevExpressComparisonOperator,
    target: TDevExpressDataGridColumnCalculateFilterExpressionTarget
  ): TDevExpressFilterExpression {
    if (target === 'filterBuilder' && (filterValue === null || filterValue === '')) {
      return [[column.dataField, '=', ''], 'or', [column.dataField, '=', null]];
    }

    const normalizedColumnValue = (columnData: unknown): string => (columnData ? normalizeAccents(columnData[column.dataField]) || '' : '');
    const normalizedFilterValue = normalizeAccents(String(filterValue));
    return [normalizedColumnValue, selectedFilterOperation || 'contains', normalizedFilterValue];
  }

  private async _startImportChecker(): Promise<void> {
    if (this._promiseImportChecker) {
      return this._promiseImportChecker;
    }

    this._promiseImportChecker = new Promise<void>((resolve, reject) => {
      let resolved = false;
      let errored = false;

      const fetchJobStatus = (): Observable<void> => {
        return from(this._saftContabService.getJobStatus())
          .pipe(
            catchError((reason: unknown) => {
              this._logger.error(reason);
              // Retry on connection errors
              return of({state: ESAFTContabProgressState.Started});
            })
          )
          .pipe(
            mergeMap((status: Partial<IJsonSAFTContabStatus>) => {
              if (status.state === ESAFTContabProgressState.Scheduled || status.state === ESAFTContabProgressState.Started) {
                const message: string = isEmpty(status.description) ? 'saftcontab.steps.configImport.messages.importarSaftContab' : status.description;
                this._saftContabProgressModal(message);

                // Retry after timeout
                return timer(TIMEOUT_JOB_STATUS).pipe(mergeMap(() => fetchJobStatus()));
              }

              if ([ESAFTContabProgressState.Inactive, ESAFTContabProgressState.Timeout, ESAFTContabProgressState.Error].includes(status.state)) {
                if (status.state !== ESAFTContabProgressState.Inactive) {
                  let title: string;
                  let message: string;
                  if (status.state === ESAFTContabProgressState.Timeout) {
                    title = 'saftcontab.messages.jobTimeoutModalTitle';
                    message = 'saftcontab.messages.jobTimeoutModalMessage';
                  } else {
                    title = 'global.text.error';
                    message = status.description;
                  }
                  this._cgModalService.showOkCancel(title, message, {
                    size: 'md',
                    showCancelBtn: false,
                    btnOkText: 'Ok',
                    backdrop: 'static',
                    keyboard: false
                  });
                }

                throw new Error(status.state);
              }

              if (status.state === ESAFTContabProgressState.Ended) {
                this._plAlertService.success('saftcontab.messages.operationsuccess');
              }

              // Must return an observable
              return of();
            })
          );
      };

      this._subscriptionImportChecker = fetchJobStatus().subscribe({
        complete: () => {
          resolve();
          resolved = true;
          this._clearImportChecker();
        },
        error: (reason: unknown) => {
          reject(reason);
          errored = true;
          console.error(reason);
          this._clearImportChecker();
        }
      });

      this._subscriptionImportChecker.add(() => {
        if (!resolved && !errored) {
          reject();
        }
      });
    });

    this._promiseImportChecker.finally(() => {
      this._promiseImportChecker = undefined;
    });

    return this._promiseImportChecker;
  }

  private _clearImportChecker(closeModal: boolean = true): void {
    if (this._subscriptionImportChecker) {
      this._subscriptionImportChecker.unsubscribe();
      this._subscriptionImportChecker = undefined;
    }
    if (closeModal) {
      this._closeImportProgressModal();
    }
  }

  private _closeImportProgressModal(): void {
    if (this._importProgressModalRef) {
      this._importProgressModalRef.dismiss();
      this._importProgressModalRef = undefined;
    }
  }

  private _saftContabProgressModal(description: string): void {
    if (this._importProgressModalRef) {
      return;
    }
    this._importProgressModalRef = this._cgModalService.showVanilla(SaftContabProgressBarModalComponent, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
    const componentInstance: SaftContabProgressBarModalComponent = this._importProgressModalRef.componentInstance;
    componentInstance.pbProcLabel = description;
  }

  private _beforeStepChange({nextStep, currentStep, type}: IPlNavWizardEventBeforeChange): boolean {
    if (currentStep === this.definitionNavWizard.items[STEP_INDEX_IMPORT_TYPE] && (type === 'next' || (nextStep && nextStep === this.definitionNavWizard.items[STEP_INDEX_IMPORT_UPLOAD]))) {
      if (!isEmpty(this.balanceteSaldadoMessage) && this.wizSteps.importType.tipoImport !== ETipoImportacaoSAFTContab.ImportarApenasContasCorrentes) {
        this._plAlertService.error(this.balanceteSaldadoMessage);
        return false;
      }
    }
    return true;
  }

  private _registerOnStart(): void {
    this._deRegisterOnStart();
    const criteria: HookMatchCriteria = {
      to: (state: StateObject, transition: Transition) => {
        const toState: StateDeclaration = transition.to();
        return transition.from() !== toState && toState.name !== STATE_NAME_LOGIN && toState.name !== STATE_NAME_DISCONNECTED;
      }
    };
    this._deRegisterOnStartFn = this._transitionService.onStart(criteria, () => this._navigationSafeGuard());
  }

  private _deRegisterOnStart(): void {
    if (this._deRegisterOnStartFn) {
      this._deRegisterOnStartFn();
      this._deRegisterOnStartFn = undefined;
    }
  }

  private _navigationSafeGuard(): Promise<void> {
    if (!isTest()) {
      if (this.processing) {
        return this._cgModalService.showOkCancel('saftcontab.leavePromptTitle', 'saftcontab.leavePromptMessage', {
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          btnOkText: 'global.btn.yes',
          btnCancelText: 'global.btn.no'
        });
      }
    }
    return Promise.resolve();
  }

  private async _doImport(
    apenasContasCorrentesComMovimento: boolean,
    verificaBalancete: boolean,
    trocaGLPostingDateComTransactionDate: boolean,
    nDiarioApuramento: number,
    nDescritivo: number,
    nDescritivoSaldosIniciais: number,
    periodoSaldosIniciais: string,
    nDiarioSaldosIniciais: number,
    justificaContasCorrenteMovAb: boolean,
    efetuaConversaoContasCorrentes: boolean
  ): Promise<boolean> {
    let canContinue = false;
    await this._checkJobStatus(async () => {
      try {
        await this._saftContabService.import(
          apenasContasCorrentesComMovimento,
          verificaBalancete,
          trocaGLPostingDateComTransactionDate,
          nDiarioApuramento,
          nDescritivo,
          nDescritivoSaldosIniciais,
          periodoSaldosIniciais,
          nDiarioSaldosIniciais,
          justificaContasCorrenteMovAb,
          efetuaConversaoContasCorrentes
        );
        this._saftContabProgressModal('saftcontab.messages.aprepararImportSaft');
        await this._startImportChecker();
        canContinue = true;
      } catch (reason: unknown) {
        if (reason instanceof HttpErrorResponse) {
          this._handleError(reason, true);
        }
        this.wizSteps.configImport.valid = false;
      }
      return canContinue;
    });

    return canContinue;
  }
}
