import {HttpResponse} from '@angular/common/http';
import {Injectable, Injector, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IPlToolbarInstance, IPlToolbarItem, IPlToolbarMenuItem} from 'pl-comps-angular';
import {Observable, Subject, Subscription} from 'rxjs';
import {monthName} from '../../../../common/dates';
import {EGroupName, TOOLBAR_GROUP_ID_PCA} from '../../../../config/constants';
import moment from 'moment';
import {ConfigService} from '../../../services/config/config.service';
import {ICGConfigurations} from '../../../services/config/config.service.interface';
import {PortalClientAccountService} from '../../../services/portalclientaccount/portalclientaccount.service';
import {
  EPcaContabilidadeServiceOptionsDefaultPeriod,
  EPcaContabilidadeServiceOptionsDefaultYear,
  EPCAPeriodo,
  IPcaContabilidadePeriodo,
  IPcaContabilidadeServiceOptions,
  IPcaContabilidadeServiceRefreshedEvent
} from './pca.contabilidade.module.service.interface';
import {ConfigOptionsInstanceService} from '../../../services/config/options/config.options.instance.service';
import {EConfigOptionsInstanceName, IConfigOptionsGroupErp, IMovimentosAbertosConfigOptions} from '../../../services/config/options/config.options.service.interface';
import {IJsonPerioMudaPrimeiroMesContab} from '../../../entities/empresas/empresas.entity.interface';
import {EmpresasService} from '../../../services/empresas/empresas.service';
import {IJsonBalanceteAno} from '../../portalcontabilidade/estatistica/balancetes/jsonContabilidade.balancetes.module.interface';

const NUMBER_10 = 10;
const NUMBER_12 = 12;

@Injectable({
  providedIn: 'root'
})
export class PcaContabilidadeService extends ConfigOptionsInstanceService<boolean, IMovimentosAbertosConfigOptions, IConfigOptionsGroupErp> implements OnDestroy {
  private readonly _subjectOnRefresh: Subject<IPcaContabilidadeServiceRefreshedEvent>;
  private readonly _dropdownAnos: IPlToolbarItem<IJsonBalanceteAno>;
  private readonly _dropdownPeriodos: IPlToolbarItem<IPcaContabilidadePeriodo>;
  private readonly _subscriptionConfigurations: Subscription;
  private _anos: Array<IJsonBalanceteAno>;
  private _periodos: Array<IPcaContabilidadePeriodo>;
  private _ano: IJsonBalanceteAno;
  private _periodo: IPcaContabilidadePeriodo;
  private _periodoLastYear: IPcaContabilidadePeriodo;
  private _configurations: ICGConfigurations;
  private _observableOnRefresh: Observable<IPcaContabilidadeServiceRefreshedEvent>;

  constructor(
    private readonly _translateService: TranslateService,
    private readonly _configService: ConfigService,
    private readonly _portalClientAccountService: PortalClientAccountService,
    private readonly _empresasService: EmpresasService,
    protected readonly _injector: Injector
  ) {
    super(_injector, EGroupName.ERP, EConfigOptionsInstanceName.MOVIMENTOS_ABERTOS);
    this._subjectOnRefresh = new Subject<IPcaContabilidadeServiceRefreshedEvent>();
    this._dropdownAnos = {
      groupId: TOOLBAR_GROUP_ID_PCA,
      id: 'pcaContabMnuAnos',
      order: 0,
      caption: 'pca.contabilidade.anos',
      type: 'dropdown',
      class: 'btn-primary',
      visible: true,
      menu: []
    };
    this._dropdownPeriodos = {
      groupId: TOOLBAR_GROUP_ID_PCA,
      id: 'pcaContabMnuPeriodos',
      order: 1,
      caption: 'pca.contabilidade.periodo',
      type: 'dropdown',
      class: 'btn-primary',
      visible: true,
      menu: []
    };
    this._anos = [];
    this._periodos = [];
    this._subscriptionConfigurations = this._configService.configurationsAsObservable().subscribe((configurations: ICGConfigurations) => {
      this._configurations = configurations;
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionConfigurations.unsubscribe();
    this._subjectOnRefresh.complete();
  }

  public init(toolbarInstance: IPlToolbarInstance, options?: IPcaContabilidadeServiceOptions): Promise<void> {
    this._initToolbar(toolbarInstance);
    options = {
      showDropdownAnos: true,
      showDropdownPeriodos: true,
      showCaptionMes: false,
      showCaptionPeriodoMes: true,
      showCaptionEncerrado: false,
      defaultYear: EPcaContabilidadeServiceOptionsDefaultYear.AnoAtual,
      defaultPeriod: EPcaContabilidadeServiceOptionsDefaultPeriod.First,
      ...options
    };
    this._dropdownAnos.visible = options.showDropdownAnos !== false;
    this._dropdownPeriodos.visible = options.showDropdownPeriodos !== false;
    return this._initAnos(options);
  }

  public onRefresh(): Observable<IPcaContabilidadeServiceRefreshedEvent> {
    if (!this._observableOnRefresh) {
      this._observableOnRefresh = this._subjectOnRefresh.asObservable();
    }
    return this._observableOnRefresh;
  }

  public showDropdownAnos(): void {
    this._dropdownAnos.visible = true;
  }

  public hideDropdownAnos(): void {
    this._dropdownAnos.visible = false;
  }

  public showDropdownPeriodos(): void {
    this._dropdownPeriodos.visible = true;
  }

  public hideDropdownPeriodos(): void {
    this._dropdownPeriodos.visible = false;
  }

  public get anos(): Array<IJsonBalanceteAno> {
    return this._anos.slice();
  }

  public get ano(): IJsonBalanceteAno {
    return this._ano;
  }

  public get periodos(): Array<IPcaContabilidadePeriodo> {
    return this._periodos.slice();
  }

  public get periodo(): IPcaContabilidadePeriodo {
    return this._periodo;
  }

  public get periodoLastYear(): IPcaContabilidadePeriodo {
    return this._periodoLastYear;
  }

  private _initToolbar(toolbarInstance: IPlToolbarInstance): void {
    // toolbarInstance.removeGroupId(TOOLBAR_GROUP_ID_PCA);
    toolbarInstance.addButton(this._dropdownAnos);
    toolbarInstance.addButton(this._dropdownPeriodos);
  }

  private _initAnos(options: IPcaContabilidadeServiceOptions): Promise<void> {
    this._anos = [];
    return this._portalClientAccountService.getAnosContabilidade().then((response: HttpResponse<Array<IJsonBalanceteAno>>) => {
      this._anos = response.body;
      this._dropdownAnos.menu = [];
      if (this._anos.length) {
        // config dropdown menu
        for (const ano of this._anos) {
          let caption = ano.descricao;
          if (options.showCaptionEncerrado && ano.encerrado) {
            caption += ` (${<string>this._translateService.instant('pca.contabilidade.encerrado')})`;
          }
          this._dropdownAnos.menu.push({
            caption: caption,
            data: ano,
            click: (menuItem: IPlToolbarMenuItem<IJsonBalanceteAno>) => {
              this._mudaAno(menuItem.data, options);
            }
          });
        }
        const defaultYear: IJsonBalanceteAno = this._getYearByOptionsDefaultYear(options.defaultYear);
        if (defaultYear) {
          this._mudaAno(defaultYear, options);
        }
      }
    });
  }

  private async _initPeriodos({ano}: IJsonBalanceteAno, options: IPcaContabilidadeServiceOptions): Promise<boolean> {
    const addLastPeriodoToDropdown = (): void => {
      this._dropdownPeriodos.menu.push({
        caption: options.showCaptionMes
          ? this._periodos[this._periodos.length - 1].nome
          : options.showCaptionPeriodoMes
          ? `${this._periodos[this._periodos.length - 1].periodo} - ${this._periodos[this._periodos.length - 1].nome}`
          : this._periodos[this._periodos.length - 1].periodo,
        data: this._periodos[this._periodos.length - 1],
        click: (menuItem: IPlToolbarMenuItem<IPcaContabilidadePeriodo>) => {
          this._mudaPeriodo(menuItem.data, options.showCaptionMes, options.showCaptionPeriodoMes);
        }
      });
    };

    const perioMudaPrimeiroMesContab: IJsonPerioMudaPrimeiroMesContab = await this._empresasService
      .getPerioMudaPrimeiroMesContab()
      .then((response: HttpResponse<IJsonPerioMudaPrimeiroMesContab>) => response.body);

    let initPerioMonth = perioMudaPrimeiroMesContab.seMesCivilDifPerioComecaAnoSeg ? perioMudaPrimeiroMesContab.primeiroMesContab : 1;
    this._periodos = [];
    this._dropdownPeriodos.menu = [];
    let lastMonthName = '';
    for (let i = 1; i <= NUMBER_12; i++) {
      const last = i < NUMBER_10;
      const periodoSimples = last ? `0${i}1` : `${i}1`;
      const periodo = last ? `${ano}0${i}1` : `${ano}${i}1`;
      if (this._isPeriodoVisible(ano, periodoSimples)) {
        this._periodos.push({
          periodo: periodo,
          nome: monthName(initPerioMonth - 1),
          ano: ano,
          periodoSimples: periodoSimples
        });
        addLastPeriodoToDropdown();
      }
      lastMonthName = i === NUMBER_12 ? monthName(initPerioMonth - 1) : '';
      initPerioMonth = initPerioMonth === NUMBER_12 ? 1 : initPerioMonth + 1;
    }

    if (this._isPeriodoVisible(ano, '122')) {
      this._periodos.push({
        periodo: `${ano}122`,
        nome: `${lastMonthName} - 1º encerramento`,
        ano: ano,
        periodoSimples: '122'
      });
      addLastPeriodoToDropdown();
    }
    if (this._isPeriodoVisible(ano, '123')) {
      this._periodos.push({
        periodo: `${ano}123`,
        nome: `${lastMonthName} - 2º encerramento`,
        ano: ano,
        periodoSimples: '123'
      });
      addLastPeriodoToDropdown();
    }
    if (!this._periodo && this._periodos.length) {
      const defaultPeriod = this._getPeriodByOptionsDefaultPeriod(options.defaultPeriod);
      if (defaultPeriod) {
        this._mudaPeriodo(defaultPeriod, options.showCaptionMes, options.showCaptionPeriodoMes);
      }
      return true;
    }
    return false;
  }

  private _mudaAno(value: IJsonBalanceteAno, options: IPcaContabilidadeServiceOptions): void {
    let shouldRefreshByPeriodo = false;
    this._ano = value;
    if (this._ano !== this._anos[0]) {
      if (!this._periodoLastYear?.periodo) {
        this._periodoLastYear = this._periodo;
      }
      this._periodo = this._periodos.find(({periodoSimples}: IPcaContabilidadePeriodo) => periodoSimples === '123');
      shouldRefreshByPeriodo = true;
    } else if (this._periodoLastYear && this._periodo !== this._periodoLastYear) {
      this._periodo = this._periodoLastYear;
      shouldRefreshByPeriodo = true;
    }

    this._dropdownAnos.caption = this._captionAno(this._ano?.ano);
    let shouldRefresh = true;
    if (!this._periodos.length || shouldRefreshByPeriodo) {
      shouldRefresh = !this._initPeriodos(this._ano, options);
    }
    if (shouldRefresh && this._periodos.length) {
      const defaultPeriod = this._getPeriodByOptionsDefaultPeriod(options.defaultPeriod);
      if (defaultPeriod) {
        this._mudaPeriodo(defaultPeriod, options.showCaptionMes, options.showCaptionPeriodoMes);
      }
    } else {
      this._refresh();
    }
  }

  private _mudaPeriodo(value: IPcaContabilidadePeriodo, showCaptionMonth: boolean = false, showCaptionPeriodoMonth: boolean = false): void {
    this._periodo = value;
    const captionPeriodo: string = this._captionPeriodo(value.periodo);
    this._dropdownPeriodos.caption = showCaptionMonth ? this._captionMonth(value.nome) : showCaptionPeriodoMonth ? `${captionPeriodo} - ${value.nome}` : captionPeriodo;
    this._refresh();
  }

  private _isPeriodoVisible(ano: number, mes: string): boolean {
    if (this._configurations.pca.periodo === EPCAPeriodo.TODOS) {
      return true;
    }
    const periodo: string = String(ano) + mes;
    if (this._configurations.pca.periodo === EPCAPeriodo.ATE_EMPRESA) {
      return periodo <= this._configurations.empresa.periodo;
    }
    if (this._configurations.pca.periodo === EPCAPeriodo.ENCERRADO) {
      if (this._configurations.empresa.periodoEncerrado === '') {
        return true;
      }
      return periodo <= this._configurations.empresa.periodoEncerrado;
    }
    return true;
  }

  private _captionAno(value: number): string {
    return this._translateService.instant('toolbar.year', {value: value});
  }

  private _captionPeriodo(value: string): string {
    return this._translateService.instant('toolbar.period', {value: value});
  }

  private _captionMonth(value: string): string {
    return this._translateService.instant('toolbar.month', {value: value});
  }

  private _refresh(): void {
    this._subjectOnRefresh.next({
      ano: this.ano,
      periodo: this.periodo,
      anos: this.anos,
      periodos: this.periodos
    });
  }

  private _getYearByOptionsDefaultYear(defaultYear: EPcaContabilidadeServiceOptionsDefaultYear): IJsonBalanceteAno {
    if (this._anos.length) {
      switch (defaultYear) {
        case EPcaContabilidadeServiceOptionsDefaultYear.First:
          return this._anos[0];
        case EPcaContabilidadeServiceOptionsDefaultYear.Last:
          return this._anos[this._anos.length - 1];
        case EPcaContabilidadeServiceOptionsDefaultYear.AnoAtual:
          const currentYear: number = moment().year();
          return this._anos.find(({ano}: IJsonBalanceteAno) => ano === currentYear);
        case EPcaContabilidadeServiceOptionsDefaultYear.AnoCursoIRC:
          return this._anos.find(({ano}: IJsonBalanceteAno) => ano === this._configurations.empresa.anoEmCursoIRC);
      }
    }
    return undefined;
  }

  private _getPeriodByOptionsDefaultPeriod(defaultPeriod: EPcaContabilidadeServiceOptionsDefaultPeriod): IPcaContabilidadePeriodo {
    if (this._periodos.length) {
      switch (defaultPeriod) {
        case EPcaContabilidadeServiceOptionsDefaultPeriod.First:
          return this._periodos[0];
        case EPcaContabilidadeServiceOptionsDefaultPeriod.Last:
          return this._periodos[this._periodos.length - 1];
        case EPcaContabilidadeServiceOptionsDefaultPeriod.AccountingPeriod:
          return this._periodos.find(({periodo}: IPcaContabilidadePeriodo) => periodo === this._configurations.empresa.periodo);
        case EPcaContabilidadeServiceOptionsDefaultPeriod.Current:
          return this._periodos.find(({periodo}: IPcaContabilidadePeriodo) => periodo === this._configurations.empresa.periodoDaData);
      }
    }
    return undefined;
  }
}
