<div class="rhrecolhaseriesubsidios-subssaved-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title me-1" [translate]="'rhrecolhaseriesubsidios.modal.subssavedtitle' + titleName"></h5>
    <pl-toolbar [instanceId]="toolbarInstanceName"></pl-toolbar>
  </div>

  <div class="modal-body">
    <dx-data-grid [cgDxDataGrid]="dataGridSubsidio" [dataSource]="dataGridSubsidio.dataSource" (onInitialized)="onInitializedSubsidio($event)" (onRowUpdating)="onRowUpdatingSubsidio($event)">
      <div *dxTemplate="let item of 'cellTemplateInfo'">
        <div class="text-center">
          <pl-tooltip *ngIf="!!item.data.erros" [config]="{text: item.data.erros, placement: 'left', container: 'body', disabled: !item.data.erros}">
            <i *plTooltipContent class="fa fa-fw fa-info-circle text-info" aria-hidden="true"></i>
          </pl-tooltip>
        </div>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button" plPromise>
    <pl-button klass="btn-success action-save" (evtClicked)="close()"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span> </pl-button>
    <pl-button klass="btn-light" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span> </pl-button>
  </div>
</div>
