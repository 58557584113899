<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title"><i class="fa fa-fw fa-exclamation-triangle text-warning" aria-hidden="true"></i><span [innerHTML]="title | translate"></span></h5>
  <ng-container *ngTemplateOutlet="templateHeader; context: modalOptions.templateRefHeaderContext"></ng-container>
</div>

<div class="modal-body">
  <div [innerHTML]="message | translate"></div>
  <ng-container *ngTemplateOutlet="templateContent; context: modalOptions.templateRefContentContext"></ng-container>

  <div class="card-footer estatisticasComercial">
    <dx-data-grid
      [cgDxDataGrid]="dataGridPagamentosLoteDefinition"
      cgDxDataGridInstanceName="pagamentosLote"
      [dataSource]="dataGridPagamentosLoteDefinition.dataSource"
      (onInitialized)="onGridPagamentosLoteInitialized($event)"
      (onContentReady)="onGridPagamentosLoteContentReady()">
    </dx-data-grid>
  </div>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-sm btn-primary action-close" (evtClicked)="close()" plAutoFocus> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span> </pl-button>

  <pl-button klass="btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
</div>
