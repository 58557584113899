import {Component, Injector, OnInit} from '@angular/core';
import {IPlFilterPanelEvtFiltered} from 'pl-comps-angular';
import {EGenericImporterType} from '../../../../../components/genericimporter/generic.importer.component.interface';
import {GenericImporterService} from '../../../../../components/genericimporter/generic.importer.service';
import {IJsonDGEMP} from '../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {ModuloEntityListComponent} from '../../../../../components/module/entitylist/module.entitylist.component';

const FILTER = 'ativo=true';

@Component({
  selector: 'dgemps-full-list',
  templateUrl: './dgempsFull.list.component.html'
})
export class DgempsFullListComponent extends ModuloEntityListComponent<IJsonDGEMP> implements OnInit {
  constructor(
    protected readonly _injector: Injector,
    private readonly _genericImporterService: GenericImporterService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.filterValue = FILTER;
    this.toolbar.addButton(this._genericImporterService.moduleToolbarButton(EGenericImporterType.Dgemps, this));
  }

  public readonly fnOnFiltered: (event: IPlFilterPanelEvtFiltered<string>) => IPlFilterPanelEvtFiltered<string> = (event: IPlFilterPanelEvtFiltered<string>) => this._onFiltered(event);

  private _onFiltered(event: IPlFilterPanelEvtFiltered<string>): IPlFilterPanelEvtFiltered<string> {
    if (!event.serializedFilters.includes(FILTER)) {
      this.filterValue = '';
    }
    return {...event};
  }
}
