import {IJsonAtivoVenda} from './jsonAtivosVenda.module.interface';
import {IRadioGroup} from '../../../../common/interfaces/interfaces';
import {deepFreeze} from 'pl-comps-angular';
import {IJsonDocContabilidade} from '../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {IJsonAquisicaoPesquisaContab} from '../ativosaquisicaocontab/jsonAtivosAquisicaoContab.module.interface';
import {IContabDigitalGDocViewerRecolhaDoc} from '../../../services/contabilidadedigital/contabilidadedigital.interface';

export enum EDistribuiValorAtivosVenda {
  PropLiquido,
  PropBruto,
  Manualmente
}

export type TAtivoVendaStatus = 'porVender' | 'aVender';

export interface IAtivoVenda extends IJsonAtivoVenda {
  status: TAtivoVendaStatus;
}

export interface IAtivoVendaContab extends IJsonAquisicaoPesquisaContab {
  _docContabilidade?: IJsonDocContabilidade;
  _contabDigitalDoc?: IContabDigitalGDocViewerRecolhaDoc;
}
export interface IAtivosVendaParams {
  isfromativos: boolean;
}

export const RADIO_GROUP_ATIVO_VENDA_DIST_VALOR: IRadioGroup<EDistribuiValorAtivosVenda> = deepFreeze<IRadioGroup<EDistribuiValorAtivosVenda>>({
  groupItems: [
    {value: EDistribuiValorAtivosVenda.PropLiquido, label: 'ativosvenda.radiooptions.liquido'},
    {value: EDistribuiValorAtivosVenda.PropBruto, label: 'ativosvenda.radiooptions.bruto'},
    {value: EDistribuiValorAtivosVenda.Manualmente, label: 'ativosvenda.radiooptions.manual'}
  ]
});

export const MODULE_NAME_ATIVOS_VENDA = 'ativosvenda';
