import {EnvironmentProviders, InjectionToken, makeEnvironmentProviders} from '@angular/core';
import {deepFreeze} from 'pl-comps-angular';
import type {IDevExpressDataGridConfiguration} from './devexpress.datagrid.configuration.interface';
import {DATAGRID_ALLOWED_PAGE_SIZES} from '../../../../../config/constants';

export enum EDataGridLegendColors {
  BlueLegendColor = 'blue-legend-color',
  IndigoLegendColor = 'indigo-legend-color',
  PurpleLegendColor = 'purple-legend-color',
  PinkLegendColor = 'pink-legend-color',
  RedLegendColor = 'red-legend-color',
  OrangeLegendColor = 'orange-legend-color',
  YellowLegendColor = 'yellow-legend-color',
  GreenLegendColor = 'green-legend-color',
  TealLegendColor = 'teal-legend-color',
  CyanLegendColor = 'cyan-legend-color',
  GrayLegendColor = 'gray-legend-color'
}

const DEFAULT_DEV_EXPRESS_DATA_GRID_CONFIGURATION: IDevExpressDataGridConfiguration = deepFreeze({
  dataGrid: {
    allowColumnReordering: true,
    allowColumnResizing: true,
    columnAutoWidth: true,
    columnChooser: {
      enabled: true,
      mode: 'select'
    },
    columnFixing: {
      enabled: true
    },
    columnHidingEnabled: true,
    columnResizingMode: 'widget',
    errorRowEnabled: false,
    export: {
      enabled: true,
      enabledExcel: true,
      enabledPdf: true
    },
    filterRow: {
      visible: true
    },
    grouping: {
      contextMenuEnabled: true
    },
    groupPanel: {
      allowColumnDragging: true,
      emptyPanelText: '',
      visible: true
    },
    headerFilter: {
      visible: true
    },
    hoverStateEnabled: true,
    masterDetail: {
      enabled: false
    },
    pager: {
      allowedPageSizes: DATAGRID_ALLOWED_PAGE_SIZES.slice(),
      showInfo: true,
      showNavigationButtons: true,
      showPageSizeSelector: true,
      visible: 'auto'
    },
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: 10
    },
    scrolling: {
      mode: 'standard'
    },
    searchPanel: {
      highlightCaseSensitive: false,
      highlightSearchText: true,
      placeholder: 'entity.placeholder.search',
      searchVisibleColumnsOnly: false,
      visible: false
    },
    selection: {
      mode: 'single'
    },
    showColumnLines: true,
    sorting: {
      mode: 'single'
    },
    stateStoring: {
      enabled: true,
      savingTimeout: 500,
      type: 'custom'
    },
    summary: {
      groupItems: [],
      totalItems: []
    }
  },
  columns: {
    dataType: 'string',
    headerAlignment: 'center',
    editorOptions: {
      step: 0
    }
  }
});

export const DEV_EXPRESS_DATA_GRID_CONFIGURATION = new InjectionToken<IDevExpressDataGridConfiguration>('CG_DEFAULT_DEV_EXPRESS_DATAGRID_CONFIGURATION');

export function provideDevExpressDataGridConfiguration(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: DEV_EXPRESS_DATA_GRID_CONFIGURATION,
      useValue: DEFAULT_DEV_EXPRESS_DATA_GRID_CONFIGURATION
    }
  ]);
}
