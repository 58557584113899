<div class="ativosnotacredito-submodal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'ativosnotacredito.submodal.title'"></h5>
    <pl-button klass="btn-primary btn-sm" id="processar" [onClick]="fnGetContaPesq"> <i class="fa fa-fw fa-refresh"></i>&nbsp;<span [translate]="'global.btn.update'"></span> </pl-button>
  </div>

  <div class="modal-body">
    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinition"
      cgDxDataGridInstanceName="ativosNotaCreditoSubModal"
      [dataSource]="dataGridDefinition.dataSource"
      (onInitialized)="onInitialized($event)"
      (onContentReady)="onContentReady()"
      (onCellClick)="onCellClick($event)"
      (onCellDblClick)="onCellDblClick($event)"
      (onRowExpanding)="onRowExpanding($event)">
      <div *dxTemplate="let item of 'detailTemplateDocContabilidade'">
        <documento-contabilidade [(doc)]="item.data._docContabilidade" [maintenanceMode]="true"> </documento-contabilidade>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-sm btn-success action-submit" [onClick]="close" [promise]="promise"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span> </pl-button>

    <pl-button klass="btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
